/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author :Sandeep Dhawale
*@fileName :rfpCampaignSpecification.js
 *Desc: UI for rfp campaign specification
// Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/



import React,{ Component } from 'react'
//import Navigation from '../layouts/navPage';  //Chaitanya-2898-Removed Console warning
import Footer from '../layouts/footer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
import { FaArrowAltCircleLeft } from "react-icons/fa";
import PublisherNavigation from "../layouts/publisherNavPage";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3766-specification VAPT api call

// import RfpCampaignSpecificationDetails from './rfpCampaignSpecificationDetails';
import PublisherRfpCampaignSpecificationDetails from './publisherRfpCampaignSpecificationDetails';
//const queryString = require('query-string');  //Chaitanya-2898-Removed Console warning
//var greeting="";  //Chaitanya-2898-Removed Console warning
class CampaignDashboard extends Component{

    constructor() {
       
        super();
        this.state = {
              campID: '',
              parentCampID:'',
              reallocationID:'',
              agencyID:'',
              rfpCampID:''
        }
        this.handleBackButtonToDashboard=  this.handleBackButtonToDashboard.bind(this);
        this.handleFileDownload = this.handleFileDownload.bind(this);
        this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
        this.handlePDFFileShowDownload = this.handlePDFFileShowDownload.bind(this);
        
    }

    componentDidMount() { 
      if(!this.props.auth.isAuthenticated) {
        this.props.history.push('/userLogin');
      }
      else{
        //Somnath Task-3935, fetch url data from history obj
        if(this.props.location.state!==undefined){
          var parsed= this.props.location.state;
          var campID=parsed.proposalID;

      //var parentCampID=parsed.parentCampID;
      //const {isAuthenticated, user} = this.props.auth;//Chaitanya-2898-Removed Console warning
      const {user} = this.props.auth;
      var agencyID=user.id;
      this.setState({ agencyID: agencyID,campID:campID})
        }
    //   fetch("campaign/CampaignDetails?campID=" + campID)
    //   .then(res => res.json())
    //   .then(campaignDetails =>{
    //     this.state.campaignName=campaignDetails[0].campaignName;
    //     }).catch(function (err) {
    //     console.log(err)
    //   });
    //Somnath Task-3935 Comment Below unused call
    // fetch("agencyDashboard/getpublisherRfpCampaignDetails?campID="+campID)
    // .then(res=>res.json())
    // .then(campaignDetails=>{
    //   this.setState({campaignDetails:campaignDetails,rfpCampID:campaignDetails[0].campID})
    // }).catch(function(err){console.log(err)});
    } 
      
    }
    
    handleBackButtonToDashboard(e){
      e.preventDefault();
      //var parsed = queryString.parse(this.props.location.search);
      //var step=parsed.step;
      //alert("Step Value=="+step);
      //Somnath Task-3935, Push url in history obj
        this.props.history.push('/requestForProposalPublisherNew',{campID:this.state.campID})
        // window.location.href = '/requestForProposalPublisherNew?campID='+this.state.campID;
      
    
          
      
      }
    
      createRfpCampaign(e){
        var campID=e.target.id;
        window.location.href='/agencyCampaignSetupInDetail?rfpCampID='+campID;
      }
  


    handleFileDownload(e) {
        var campID=this.state.campID;
        //var parentCampID=this.state.parentCampID; //Chaitanya-2898-Removed Console warning
         /**
       * @author Narendra Phadke
       * @param  Description handle the Campaign related Files
       * @return Description return All The File Download in Zip
       */
          // console.warn("campID===>"+campID);
        
        var formData=new FormData();
        formData.append("campID",campID);
        //const {isAuthenticated, user} = this.props.auth;  //Chaitanya-2898-Removed Console warning
      //var userID=user.id; //Chaitanya-2898-Removed Console warning
       // alert("Agency ID=="+userID);
      //This api is used for dynamic pdf genration
      //snehal-task-3766-specification VAPT api call
       fetch("agency/downloadDynamicPDFCampaignDetailsforAgency?campID=" + campID + "&campName="+this.state.campaignName ).then(r => r.blob())
          .then(downloadFiles =>
            this.setState({ downloadFiles: downloadFiles })
            )
            .then(this.handlePDFFileShowDownload);

          //snehal-task-3766-specification VAPT api call
          fetch("agency/downloadCampaignDetailsforAgency?campID=" + campID + "&campName="+this.state.campaignName ).then(r => r.blob())
          .then(downloadFiles =>
            this.setState({ downloadFiles: downloadFiles })
            )
            .then(this.handleFileShowDownload);
        }/** End of handle File Download */

        handleFileShowDownload(blob){
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            let zipFileDownloadName=this.state.parentCampID+'-'+this.state.campaignName+".zip";
          
            var newBlob = new Blob([this.state.downloadFiles], {type: "application/zip"})
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            } 
           
            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download=zipFileDownloadName;
            link.click();
          
          // ));
        }

        handlePDFFileShowDownload(blob){
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            let zipFileDownloadName=this.state.parentCampID+'-'+this.state.campaignName+"-CampaignSpecification.pdf";
             var newBlob = new Blob([this.state.downloadFiles], {type: "application/pdf"})
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            } 
           
            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download=zipFileDownloadName;
            link.click();
          
          // ));
  
          }
           
           

    render()
    {

      if(this.props.location.state!==undefined){
        var parsed= this.props.location.state;
        this.state.campID=parsed.proposalID;
      }
    // this.state.parentCampID=parsed.parentCampID
    // this.state.reallocationID=parsed.reallocationID;
  //  alert( this.state.campID)
  //  alert( this.state.parentCampID)
  //  alert(this.state.reallocationID);
  //  alert(this.state.campaignName)
    


        return(
            <div>
           
           <PublisherNavigation />
           <div style={{paddingTop:'110px'}}>
          
             <div class="container-fluid">
             <div class="row" style={{paddingBottom:'10px'}}>
            
             <div class="col-lg-2"><a href="#/" //Chaitanya-2898-Removed Console warning
             > <FaArrowAltCircleLeft size={32} style={{float:'left',color:'#056eb8'}} title="Back" onClick={this.handleBackButtonToDashboard} /></a></div>
             <div class="col-lg-2"><label></label></div>
              <div class="col-lg-4"><label style={{color:'#056eb8',fontSize:'20px',paddingLeft:'120px'}}>RFP Details</label></div>
             <div class="col-lg-2"><label></label></div>
             {/* <div class="col-lg-2"  style={{paddingLeft:'120px'}}><button type="button"class="btn add-button" id="cmd"
                onClick={this.handleFileDownload}>Download</button></div> */}
             
             
             </div>
           </div>
          {/* <div>  */}
              <PublisherRfpCampaignSpecificationDetails  greeting={this.state.campID}/> 
          {/* </div> */}
                
                 

               <div class="container-fluid" style={{paddingBottom:'70px'}}>
                 <div class="row">
                   {/* <div class="col-lg-4"></div>
                   <div class="col-lg-3"><label></label></div> */}
                   {/* <div class="col-lg-5" style={{paddingTop:'20px'}}><button type="button"class="btn add-button" id="cmd" style={{float:'left'}}
                onClick={this.handleFileDownload}>Download</button></div> */}
                 </div>
               </div>
           </div>
           <Footer/>
            </div>





               
           
        )
    }
};

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      CampaignDashboard.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(CampaignDashboard)); 
