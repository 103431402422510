/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
//import axios from 'axios';
// fetch from 'fetch';
const setAuthToken = token => {
    let headers = {"Content-Type": "application/json"};
    if(token) {
        //fetch.defaults.headers.common['Authorization'] = token;
        //fetch.headers.common['Authorization']= token;
        //headers["Authorization"] = token;
    }
    else {
        delete fetch.defaults.headers.common['Authorization'];
    }
}

export default setAuthToken;