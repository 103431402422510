/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanobar Golandaj
@Description:UI for finish campaign under create campaign
*/

import React from "react";
import { form } from "mdbreact";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { format } from "morgan";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3719-vapt header and query params
const Swal = require("sweetalert2");
/*@author Narendra phadke
 *use for fetch url value
 */
const queryString = require("query-string");

class FinishCampaign extends React.Component {
	/*@author Narendra Phadke
	 * Desc Display current Adding campaign basic details
	 */
	constructor(props) {
		super(props);
		// console.log(props)
		this.state = {
			campaignDetails: [],
			campID: "",
			linkingCampaign: [],
			linkCampaign: [],
			loader: "",
			rfpCampaigns: [],
			rfpCampaignID: "",
			btnSubmitDisable: true,
			updateLeadError: {},

			// updateLeadArray:[];
		};
		this.pendingAllocation = this.pendingAllocation.bind(this);
		this.logChange = this.logChange.bind(this);
		this.handleChangeCheck = this.handleChangeCheck.bind(this);
		this.saveLinkCampaign = this.saveLinkCampaign.bind(this);
		this.getRFPDetails = this.getRFPDetails.bind(this);
		this.updateLeads = this.updateLeads.bind(this);
		this.submitLeads = this.submitLeads.bind(this);
	}

	updateLeads(i, e) {
		var { name, value } = e.target;
		// var leads=e.target.value;
		// alert(value)
		var updateLeadError = {};

		let rfpCampaigns = [...this.state.rfpCampaigns];
		rfpCampaigns[i] = { ...rfpCampaigns[i], [name]: value };
		//  alert("data in==>"+JSON.stringify(rfpCampaigns));
		this.setState({ rfpCampaigns: rfpCampaigns });
		let temp = rfpCampaigns.filter((a) => {
			if (
				a.noOfLeads == "" ||
				a.noOfLeads == "null" ||
				a.noOfLeads == null ||
				a.noOfLeads == undefined
			) {
			} else {
				return a;
			}
		});
		if (temp.length == 0) {
			this.setState({ btnSubmitDisable: true });
			this.setState({
				updateLeadError: updateLeadError,
				alertBorder: "0px solid red",
			});
		} else {
			this.setState({ btnSubmitDisable: false });
		}
	}

	validateForm() {
		let updateLeadError = {};
		var updateLeadValidate = false;
		var rfpcampaignArray = this.state.rfpCampaigns;
		let lenCampArray = rfpcampaignArray.length;
		var formIsValid = false;
		var count = 0;
		// alert("lenCampArray lenghth==>"+lenCampArray);
		for (let i = 0; i < lenCampArray; i++) {
			var noOfLeads = rfpcampaignArray[i].noOfLeads;
			// alert("noOfLeads===>"+noOfLeads);
			if (noOfLeads == "" || noOfLeads == null || noOfLeads == undefined) {
				//  alert("Please Enter Leads===="+i);
				if (count > 0 || count == 0) {
				} else {
					updateLeadValidate = false;
					updateLeadError["one"] =
						"Please Enter Leads  " + rfpcampaignArray[i].country;
					this.setState({
						updateLeadError: updateLeadError,
						alertBorder: "1px solid red",
						//  btnSubmitDisable:true
					});
					// alert("Count=="+count);

					return updateLeadValidate;
				}
			} else {
				//  alert("Lead Present plz chk for regex")
				var pattern = new RegExp(/^[1-9][0-9]*$/);
				if (!pattern.test(noOfLeads)) {
					// alert("Please Enter Valid Leads");
					updateLeadValidate = false;
					//  alert("Please Enter Valid Leads");
					updateLeadError["noOfLead"] =
						" Please Enter Valid Leads For  " + rfpcampaignArray[i].country;
					this.setState({
						updateLeadError: updateLeadError,
						alertBorder: "1px solid red",
						//  btnSubmitDisable:false
					});
					return updateLeadValidate;
				} else {
					// alert("Lead in valid format");
					updateLeadValidate = true;
					updateLeadError["noOfLead"] = "";
					this.setState({
						updateLeadError: updateLeadError,
						alertBorder: "0px solid red",
					});
					count++;
					//  return updateLeadValidate
				}
			}
		} //end of for loop
		if (updateLeadValidate === false) {
			formIsValid = false;
			this.setState({
				updateLeadError: updateLeadError,
				alertBorder: "1px solid red",
			});
		} else {
			formIsValid = true;
		}

		// alert("formIsValid"+formIsValid);
		return formIsValid;
	}

	submitLeads(e) {
		if (this.validateForm() === false) {
			// alert("form is false");
		} else {
			// alert("Submit leads");
			let data = {
				rfpCampaigns: this.state.rfpCampaigns,
			};
			// alert(JSON.stringify(this.state.rfpCampaigns.noOfLead))
			fetch("/campaign/updateRFPCampaignLeads", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.success === "success") {
						let message = "Leads Updated Successfully";
						Swal.fire({
							text: message,
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								const { isAuthenticated, user } = this.props.auth;
								var agencyID = user.id;
								this.setState({ btnSubmitDisable: true });
								//document.getElementsByName('noOfLeads').value = '';
								//window.location.href = '/dashboard?agencyID='+agencyID;
								this.getRFPDetails();
							},
						});
					}
				});
		}
	} //end of submitleads
	pendingAllocation(e) {
		const { isAuthenticated, user } = this.props.auth;
		var agencyID = user.id;
		window.location.href = "/dashboard?agencyID=" + agencyID;
	}

	handleChangeCheck(e) {
		var campID = e.target.getAttribute("campID");
		var parentCampID = e.target.getAttribute("parentCampID");

		// var linkCampaign=[];
		const { name, value } = e.target;
		let linkCampaign = [...this.state.linkCampaign];
		if (e.target.checked === true) {
			linkCampaign.push({
				campID: campID,
				parentCampID: parentCampID,
				checked: "Yes",
			});
		} else {
			//linkCampaign[0] = { ...linkCampaign[0], [name]: 'No' };
		}

		this.setState({ linkCampaign });
	}
	getRFPDetails() {
		if (
			this.state.rfpCampaignID == "undefined" ||
			this.state.rfpCampaignID == "null" ||
			this.state.rfpCampaignID == null ||
			this.state.rfpCampaignID == undefined ||
			this.state.rfpCampaignID == ""
		) {
		} else {
			let data1 = { rfpCampaignID: this.state.rfpCampaignID };
			fetch("campaign/rfpCampaignList", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data1),
			})
				.then((res) => res.json())
				.then((rfpCampaigns) => {
					this.setState({ rfpCampaigns: rfpCampaigns });
				});
		}
	}
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			/*@author Narendra phadke
			 *Desc Fetch campID from Url
			 * dependencies query-string
			 */
			var parsed = queryString.parse(this.props.location.search);
			var campID = parsed.campID;
			var rfpCampaignID = parsed.rfpCampaignID;
			this.setState({ campID, rfpCampaignID: rfpCampaignID });
			console.log("Control on react page");
			console.log(campID);

			let self = this;

			let data = {
				campID: campID,
			};

			fetch("campaign/finishCampaign?campID=" + campID + "")
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.getRFPDetails();
					self.setState({ campaignDetails: campaignDetails });
				});

			// ,function(){this.setState({loader:"whirly-loader"})}
			fetch(
				"campaign/linkingCampaign",
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				},
				function () {
					alert("called");
				}
			)
				.then((res) => res.json())
				.then((linkingCampaign) => {
					this.setState({ linkingCampaign: linkingCampaign });
				});
		}
	}
	logChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	saveLinkCampaign(e) {
		const { isAuthenticated, user } = this.props.auth;
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		var parentCampID = parsed.parentCampID;
		let data = {
			linkCampaign: this.state.linkCampaign,
			campID: campID,
			parentCampID: parentCampID,
		}; // karan-task-3719-vapt header and query params

		fetch("campaign/setLinkingCampaign", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((setLinkCampaign) => {
				Swal.fire({
					text: "Campaign Link Successfully",
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
					preConfirm: () => {
						const { isAuthenticated, user } = this.props.auth;
						var agencyID = user.id;
						window.location.href = "/dashboard?agencyID=" + agencyID;
					},
				});
			});
	}

	render() {
		/**
		 * @author Narendra Phadke
		 * @param  Description handle the login authentication
		 * @return Description return All details of authentication
		 */
		const { isAuthenticated, user } = this.props.auth;
		const linkStyle = {
			fontSize: "15px",
			color: "red",
		};

		/*@author Narendra phadke
		 *Desc Fetch campID from Url
		 * dependencies query-string
		 */
		var parsed = queryString.parse(this.props.location.search);

		return (
			<div>
				<Navigation />

				<div class="col" align="center" style={{ paddingTop: "90px" }}>
					<label id="labelsuccessheading">
						Campaign created successfully Campaign ID :&nbsp;{parsed.campID}{" "}
					</label>
				</div>
				{/* <div class="col" align="center"></div> */}
				<div class="col" align="center">
					<label id="labelsuccessheading" style={{ color: "orange" }}>
						Please{" "}
						<a href="#" onClick={this.pendingAllocation}>
							click here
						</a>{" "}
						to allocate.
					</label>
				</div>

				<div class="container" style={{ marginBottom: "60px" }}>
					<div align="right">
						{/* <a href="./campaignSetup" style={linkStyle}>Click here for more setup</a>  */}
					</div>
					<div class="card card-signin">
						<div class="card-body">
							{this.state.campaignDetails.map(
								(campaignDetail) => (
									<div class="row">
										<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
											<div class="col-sm-8 col-lg-10">
												<label style={{ fontSize: "16px" }}>
													Basic Details
												</label>
											</div>
											<div class="col-sm-4 col-lg-2" style={{ float: "right" }}>
												<button
													class="btn add-button"
													onClick={this.pendingAllocation}>
													Edit Campaign
												</button>
											</div>
											<br />
											<hr className="legend labelsubheading" />
											{/* <button class="btn"onClick={this.pendingAllocation}>Edit Campaign</button> */}

											<div class=" col-md-6 col-lg-4">
												<label id="label">Campaign ID:&nbsp;</label>
												{campaignDetail.campID}
											</div>

											<div class="col-md-6  col-lg-4">
												<label id="label">Campaign name:&nbsp;</label>
												{campaignDetail.campaignName}
											</div>

											<div class="col-md-6 col-lg-4">
												<label id="label">Client name:&nbsp;</label>
												{campaignDetail.clientName}
											</div>

											<div class="col-md-6 col-lg-4">
												<label id="label">Agency campaign ID:&nbsp;</label>
												{campaignDetail.clientCampID}
											</div>

											<div class="col-md-6 col-lg-4">
												<label id="label">CPL:&nbsp;</label>
												{campaignDetail.CPL}
												{campaignDetail.currency}
											</div>

											<div class="col-md-6 col-lg-4">
												<label id="label">Budget:&nbsp;</label>
												{campaignDetail.budget}
											</div>

											<div class="col-md-6 col-lg-4">
												<label id="label">Start date:&nbsp;</label>
												{campaignDetail.startDate}
											</div>

											<div class=" col-md-6 col-lg-4">
												<label id="label">End date:&nbsp;</label>
												{campaignDetail.endDate}
											</div>

											<div class="col-md-6 col-lg-4">
												<label id="label">First lead delivery date&nbsp;</label>
												{campaignDetail.firstLeadDeliveryDate}
											</div>

											<div class="col-md-6 col-lg-4">
												<label id="label">Campaign status:&nbsp;</label>
												{campaignDetail.campaignStatus}
											</div>

											<div class="col-md-6 col-lg-4">
												<label id="label">Lead allocation:&nbsp;</label>
												{campaignDetail.leadAllocation}
											</div>

											<div class=" col-md-6 col-lg-4">
												<label id="label">Created by:&nbsp;</label>
												{campaignDetail.createdByCompanyName}
											</div>
										</div>
									</div>
								)
								// close of row
							)}
						</div>
					</div>

					<br />

					<div
						class={this.state.loader}
						style={{ top: "22px", left: "528px" }}></div>
					{this.state.rfpCampaigns.length > 0 ? (
						//this.state.rfpCampaigns[0].biddingType=='Geowise'?
						<div>
							<label>Below RFP Campaigns are created:</label>
							<br />

							<div
								class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
								style={{ border: this.state.alertBorder }}>
								<div class="errorMessage">
									{this.state.updateLeadError.one ? (
										<span>
											{this.state.updateLeadError.one}
											<br />
										</span>
									) : (
										""
									)}
									{this.state.updateLeadError.noOfLead ? (
										<span>
											{this.state.updateLeadError.noOfLead}
											<br />
										</span>
									) : (
										""
									)}
								</div>
							</div>

							<div class="table-responsive-lg">
								<table
									id="myTable"
									class="table"
									className=" table table-bordered table-striped "
									style={{ display: "inline-block" }}>
									<thead class="thead1">
										<tr class="custom-class">
											<th
												class="th table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "103px",
												}}>
												Campaign ID
											</th>

											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "134px",
												}}>
												Campaign Name
											</th>

											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "118px",
												}}>
												Start Date
											</th>

											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "119px",
												}}>
												End Date
											</th>

											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "122px",
												}}>
												Region
											</th>

											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "142px",
												}}>
												Country
											</th>
											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "61px",
												}}>
												CPL
											</th>
											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "66px",
												}}>
												Budget
											</th>
											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "81px",
												}}>
												Lead Allocation
											</th>
											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "100px",
												}}>
												Update Lead Allocation
											</th>
										</tr>
									</thead>
									<tbody className="tbody1" style={{ height: "auto" }}>
										{this.state.rfpCampaigns.map((rfpCampaigns, i) => {
											return (
												<tr>
													<td
														style={{
															wordWrap: "break-word",
															width: "118px",
															textAlign: "center",
														}}>
														{rfpCampaigns.campID}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "147px",
															textAlign: "center",
														}}>
														{rfpCampaigns.campaignName}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "138px",
															textAlign: "center",
														}}>
														{rfpCampaigns.startDate}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "134px",
															textAlign: "center",
														}}>
														{rfpCampaigns.endDate}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "141px",
															textAlign: "center",
														}}>
														{rfpCampaigns.region}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "158px",
															textAlign: "center",
														}}>
														{rfpCampaigns.country}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "71px",
															textAlign: "center",
														}}>
														{rfpCampaigns.CPL}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "71px",
															textAlign: "center",
														}}>
														{rfpCampaigns.budget}
													</td>

													<td
														style={{
															wordWrap: "break-word",
															width: "90px",
															textAlign: "center",
														}}>
														{rfpCampaigns.leadAllocation}
													</td>

													<td>
														<input
															type="text"
															style={{ width: "88px", float: "right" }}
															name="noOfLeads"
															id={rfpCampaigns.campID}
															onChange={this.updateLeads.bind(this, i)}
														/>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>

								<button
									class="btn add-button"
									style={{ float: "right" }}
									onClick={this.submitLeads}
									disabled={this.state.btnSubmitDisable}>
									Update Leads
								</button>
								<br />
							</div>
						</div>
					) : (
						//:''
						""
					)}

					{this.state.linkingCampaign.length > 0 ? (
						<div>
							<label>Linking Campaigns:</label>
							<div class="table-responsive-lg">
								<table
									id="myTable"
									class="table"
									className=" table table-bordered table-striped ">
									<thead class="thead1">
										<tr class="custom-class">
											<th
												class="th table-header"
												style={{ textAlign: "center" }}>
												Campaign ID
											</th>

											<th class="table-header" style={{ textAlign: "center" }}>
												Campaign Name
											</th>

											<th class="table-header" style={{ textAlign: "center" }}>
												Start Date
											</th>

											<th class="table-header" style={{ textAlign: "center" }}>
												End Date
											</th>

											<th class="table-header" style={{ textAlign: "center" }}>
												Action
											</th>
										</tr>
									</thead>
									<tbody className="tbody1" style={{ height: "auto" }}>
										{this.state.linkingCampaign.map((linkingCampaign) => (
											<tr className="tr1">
												<td class="campId">{linkingCampaign.campID}</td>
												<td class="campaignName">
													{linkingCampaign.campaignName}
												</td>
												<td class="startDate">{linkingCampaign.startDate}</td>
												<td class="endDate">{linkingCampaign.endDate}</td>
												<td>
													<input
														type="checkbox"
														id="linkCheckbox"
														name="linkCheckbox"
														campID={linkingCampaign.campID}
														parentCampID={linkingCampaign.parentCampID}
														onChange={this.handleChangeCheck}></input>
													&nbsp;
													<label style={{ fontWeight: "400" }}>
														Select to Link
													</label>
												</td>
											</tr>
										))}
										<tr className="tr1" style={{ backgroundColor: "#ffffff" }}>
											<button
												class="btn add-button"
												style={{ float: "right" }}
												onClick={this.saveLinkCampaign}>
												Save
											</button>
										</tr>
										{/* <div class="col-sm-4 col-lg-2" style={{float:'right'}}>
                  <button class="btn add-button" onClick={this.saveLinkCampaign}>Save</button>
                 </div> */}
									</tbody>
								</table>
							</div>
						</div>
					) : (
						""
					)}
				</div>

				<Footer />
			</div>
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
FinishCampaign.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(FinishCampaign)
);
