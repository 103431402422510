/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Sanobar Golandaj
*@fileName :edit.js
 *Desc: edit Campaign direct  steps
*/


import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import Footer from '../layouts/footer';
import Navigation from '../layouts/navPage';
import { FaArrowAltCircleLeft } from "react-icons/fa";

const queryString = require('query-string');

class Edit extends React.Component {
  constructor() {
    super();
    this.state = {
      campID:'',
      agencyID:'',
      Step:'', //if visits the page from dashboard then will be back redirected to dashboard
      stepEdit:'',
      toEdit:'',
      fromActive:'',
      fromPending:''

     
    }
    this.handleeditCampaign=this.handleeditCampaign.bind(this);
    this.handleeditSupportingDocument=this.handleeditSupportingDocument.bind(this);
    this.handleeditDeliveryFormat=this.handleeditDeliveryFormat.bind(this);

}
    


    handleeditCampaign(e){
        var parsed = queryString.parse(this.props.location.search);
        var campID = parsed.campID;
        var parentCampID=parsed.parentCampID;
        var reallocationID=parsed.reallocationID;
        if(this.state.Step==true)
        {
          window.location.href = '/editCampaign?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&step='+1;

        }
        else if(this.state.stepEdit==true){
          window.location.href = '/editCampaign?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&stepEdit='+1;

        }
        else if(this.state.toEdit==true){
          window.location.href = '/editCampaign?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&toEdit='+1;

        }
        else if(this.state.fromActive==true){
          window.location.href = '/editCampaign?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&fromActive='+1;

        }
        else if(this.state.fromPending==true){
          window.location.href = '/editCampaign?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&fromPending='+1;

        }
        else
        {
    window.location.href = '/editCampaign?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID;
        }// alert(parsed.campID);
  }
    handleeditSupportingDocument(e){
        var parsed = queryString.parse(this.props.location.search);
        var campID = parsed.campID;
        var parentCampID=parsed.parentCampID;
        var reallocationID=parsed.reallocationID;
        if(this.state.Step==true)
      {
        window.location.href = '/editSupportingDocument?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&step='+1;

      }
      else if(this.state.stepEdit==true){
        window.location.href = '/editSupportingDocument?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&stepEdit='+1;

      }
      else if(this.state.toEdit==true){
        window.location.href = '/editSupportingDocument?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&toEdit='+1;

      }
      else if(this.state.fromActive==true){
        window.location.href = '/editSupportingDocument?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&fromActive='+1;

      }
      else if(this.state.fromPending==true){
        window.location.href = '/editCampaign?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&fromPending='+1;

      }
      else
      {
        window.location.href = '/editSupportingDocument?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID;
        // alert(campID);
      }
    }

    handleeditDeliveryFormat(e){
        var parsed = queryString.parse(this.props.location.search);
        var campID = parsed.campID;
        window.location.href = '/editDeliveryFormat?campID='+campID+'';
        // alert(campID);
    }


    componentWillMount(){
      if(!this.props.auth.isAuthenticated) {
        this.props.history.push('/userLogin');
      }
      else{
      var parsed = queryString.parse(this.props.location.search);
      var campID = parsed.campID;
    // alert(parsed.campID);
     this.state.Step=parsed.step;
     this.state.stepEdit=parsed.stepEdit;
     this.state.toEdit=parsed.toEdit;
     this.state.fromActive=parsed.fromActive;
     this.state.fromPending=parsed.fromPending;
     
  
     const {isAuthenticated, user} = this.props.auth;
     var agencyID=user.id;
     this.setState({ agencyID: agencyID,campID:campID})
    }
  }
  render() {
      /**
       * @author Narendra Phadke
       * @param  Description handle the log
       * in authentication
       * @return Description return All details of authentication
       */
     
        const {isAuthenticated, user} = this.props.auth;    
        const asterisk =
        {

          color: 'red',

        }
        var parsed = queryString.parse(this.props.location.search);
        var parentCampID=parsed.parentCampID;
        var reallocationID=parsed.reallocationID;
        // alert("campID"+parentCampID);
       
    return (
    <div>
        <Navigation />
        
       <div class="container-fluid" style={{paddingTop:'90px'}}>
        <div class="row">
          <div  class=" col-lg-4">
          { this.state.Step=='1'?
          <a href={'dashboard?agencyID='+this.state.agencyID}   style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Dashboard"/></a>
          :
          (this.state.stepEdit=='1'?
          <a href={'allocatingCampaign#4'}   style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Allocated Campaign tab"/></a>
          :
          this.state.toEdit=='1'?
          <a href={'allocatedCampaignTab?campID='+this.state.campID}   style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Allocated campaign"/></a>
          :
          this.state.fromActive=='1'?
          <a href={'allocatingCampaign#5'}   style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Active campaign"/></a>
          :
          this.state.fromPending=='1'?
          <a href={'allocatingCampaign#8'}   style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Pending campaign"/></a>
          :
          
          "")
          
          }
          

          </div>
          <div class="col-lg-4">
                <label  style={{color:'#056eb8',fontSize:'20px'}}>Edit Campaign &nbsp;(Campaign ID:{parentCampID}&nbsp;)</label>
                {/* |&nbsp;Allocation ID:{reallocationID})  */}
                </div>
           
        </div>
        </div>
        
        
        <div class="container-fluid">
                 <div class="card card-signin my-1">
                <div class="card-body">
                
                <div class="row">
                    <div class="col-lg-12" >
                    <div style={{color:'orange',fontSize:'15px'}}>Campaign <span style={{fontSize:'12px'}}>(Below details can be edited)
                        </span><button class="btn add-button"  style={{float:'right'}}>< a href="#" style={{color:'white'}} onClick={this.handleeditCampaign}>EDIT</a></button></div>
                        <hr/>
                        </div>
                </div>
{/*                 
                <div class="row">
                <div class="col-lg-12">
                <span style={{color:'orange'}}>(Below details can be edited)</span>
                </div>
                </div> */}

                <div class="row">
                    <div class="col-lg-12">
                   {/* <span style={{color:'orange',fontSize:'15px'}}>Basic Details : </span> */}
                  <span> Campaign name,
                   Client name,
                   Agency campaign ID,
                  
                   Campaign status,
                   Start date,
                   End date,
                   First lead delivery date,
                   
                   No. of lead per domain,
                   Lead allocation,
                   
                   Campaign budget,
                   CPL,
                   Campaign currency,
                   Pacing,
                    Pacing count,
                    Marketing channel,
                    Campaign reporting day,
                    Campaign type,
                    Creatives approval required,
                    Creatives approval timeline,
                    
                    {/* Lead Delivery Option,
                    Upload Lead Delivery Format,  */}
                     Region,
                    Country, State, City, Zipcode,
                   
                    
                    Industry, 
                    {/* Upload Industry, */}
                    Employee size,
                    Company revenue,
                    Job level,
                    Job function,
                    Job title,
                    Other specs,
                    Custom questions, Answers,Dis-allowed answers.
                    {/* Custom Employee Size,
                    Custom Company Revenue. */}
                   </span>
                 </div>
                 </div>
                
                     </div> 
                   </div>

            
{/* second card */}

           <div class="card card-signin my-1">
                <div class="card-body">
                
                <div class="row">
                    <div class="col-lg-12">
                    <div style={{color:'orange',fontSize:'15px'}}>Supporting Document <span style={{fontSize:'12px'}}>(Below details can be edited)</span><button class="btn add-button" style={{float:'right'}}><a href="#" style={{color:'white'}}onClick={this.handleeditSupportingDocument
                    
                    } >EDIT</a></button></div>
                        <hr/>
                        <div class="row">
                            <div class="col-lg-12">
                            {/* <span style={{color:'orange',fontSize:'15px'}}>Upload Supporting Document : </span> */}
                            Publisher ID, Campaign ID, Allocation ID, Lead interaction date, First name, Last name, Company name,
                                Email, Work phone, Job title, Job level, Job function, Address, Country, Street, City,
                                State, Zip code, Company employee size, Company revenue, IP,Industry, Asset ID,
                                Asset name, Extra 1, Extra 2, Extra 3, Extra 4, Extra 5, Domain, Alternate phone no, Comments, LinkedIn,
                            ABM,
                            Suppression,
                            Exclusion,
                            <br/>
                            Asset upload,
                            Asset link upload,Asset wise leads. 
                            {/* Other, Campaign Budget,
                            Campaign Currency, 
                            LP approval timeline,
                            LP approval required, */}
                            
                            </div>
                        </div>
                
                    </div>
                  </div>
                  </div>
                </div>


                {/* third card */}

              

            </div>
         



            
  
       
             



        

        <Footer />
        </div>
    );
  }

  }

 /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      Edit.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })

export default connect(mapStateToProps, { logoutUser })(withRouter(Edit));