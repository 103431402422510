/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sunita Landge
@file Name:rfpAnalysis.js
@Description: RFP Analysis
* @Date:
* @Console error warning handled by: Sandeep Dhawale
*/

import React, { Component } from "react";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { withRouter } from "react-router-dom";

import { logoutUser } from "../login/authentication";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Somnath Task-3936,vapt files auth header
import "./rfpAnalysis.css";
import { Table } from "antd";
import { css } from "emotion";
import "antd/dist/antd.css";
import { saveAs } from "file-saver"; //shivani-3508-added to download excel file

const queryString = require("query-string");
const xlsx = require("xlsx"); //shivani-3508-added to download excel file
//shivani-3508-changed table css as per table design requirement.
const tableCSS1 = css({
	backgroundColor: "white",
	fontFamily: "sans-serif",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "13px",
		textAlign: "center !important",
		fontWeight: "500",
	},
	"& thead > tr >th": {
		borderRight: "1px solid #e8e8e8",
		textAlign: "center !important",
		color: "white",
		fontSize: "12px",
	},
});
class RfpAnalysis extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agencyID: "",
			existingRFP: [],
			existingRFPCampDetails: [],
			rfpPublisherDetails: [],
			id: "",
			campID: " ",
			existingRFPsearch: [],
			tableSearchText: "",
			tableState: { size: "small" }, //Array for table properties
			loading: false,
			rfpPublisherDataSearch: [],
			tableSearchText1: "",
			selectedCountry: "",
			pID: "",
			cpl: 0,
			leads: 0,
			rfpPublisherDetailsFilterResponse: [],
			clearValue: 0,
			splitCountryArray: [],
			publisherArray: [],
			noRFP: "",
			noData: "",
			token: "", // shivani-task 2993-declare token to set token for API authentication to pass in every fetch header.
			newList1: [],
			pageSize: 50, // shivani - 3508 - added page Size for pagination functionality.
			loader: "", // shivani - 3508 - declared loader , which is used to display loader .
			key:"rfp"//shivani - 3781 - passed this ID in url .
		}; //end of state
		this.rfpDetails = this.rfpDetails.bind(this);
		this.handleChangeSearch = this.handleChangeSearch.bind(this);
		this.selectCountry = this.selectCountry.bind(this);
		this.selectPublisher = this.selectPublisher.bind(this); //shivani-3508-bind handlechange for publisher dropdown .
		this.downloadRfpDetails = this.downloadRfpDetails.bind(this); //shivani-3508-bind handlechange for download file.
		this.filterResponsePublisher = this.filterResponsePublisher.bind(this);
		this.incrementItem = this.incrementItem.bind(this);
		this.decrementItem = this.decrementItem.bind(this);
		this.clearFilter = this.clearFilter.bind(this);
		this.incrementLeads = this.incrementLeads.bind(this);
		this.decrementLeads = this.decrementLeads.bind(this);
		this.handleChange_leads = this.handleChange_leads.bind(this);
		this.handleChange_CPL = this.handleChange_CPL.bind(this);
		this.handleChangePageSize = this.handleChangePageSize.bind(this); //shivani-3508-bind handlechange for pagination .
			//shivani-3781-binded below handlechange for accept rfp .
		this.acceptRFP=this.acceptRFP.bind(this);
	} //end of constructor
			//shivani-3781-added handlechange for accept rfp .

	acceptRFP(e){
		e.preventDefault()//Sonali-3716-added for removing # from url
	
		let campID = e.target.id;
		let key = this.state.key;
		//window.location.href = "/requestProposalAgency?campID="+ campID +"&key="+ key;
		this.props.history.push("/requestProposalAgency",{campID,key})

	}
	/**
	 * @author : Shivani Pathak-task 3508
	 * @description :  Added handlechange for pagination.
	 */
	handleChangePageSize(e) {
		var pageSize = e.target.value;
		this.setState({ pageSize: pageSize });
	}
	/**
	 * @author : Shivani Pathak-task 3508
	 * @description :  Added handlechange for download rfp analysis details.
	 */
	downloadRfpDetails(e) {
		let downloadFiles = this.state.rfpPublisherDetails;

		downloadFiles = downloadFiles.map((item) => {
			return {
				"Proposal ID": item.campID,
				"Publisher ID": item.pID,
				Region: item.region,
				Country: item.country,
				"Monthly Lead": item.monthlyLead,
				"Total Lead": item.totalLead,
				CPL: item.CPL,
			};
		});

		//const { user } = this.props.auth; //rutuja 2832 commented user as never used in the file
		//       /* make the worksheet */

		if (downloadFiles.length > 0) {
			var ws = xlsx.utils.json_to_sheet(downloadFiles);
			/* add to workbook */
			var wb = xlsx.utils.book_new();
			xlsx.utils.book_append_sheet(wb, ws, "Lead");

			// /* write workbook */
			var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
			//var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" }); //rutuja 2832 commented str as nver used in the file
			/* generate a download */
			function s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				//rutuja 2832 replace != with !== for removing console warning
				for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
				return buf;
			}

			//sunita-task-2996- as per campaign status it will download the report for active and completed tab

			var fileName = "RFP_agency_analysis.xlsx";

			saveAs(
				new Blob([s2ab(buf)], { type: "application/octet-stream" }),
				fileName
			);
		}
	}
	rfpDetails(e) {
		e.preventDefault();
		let campID = e.target.id;
		let data = { campID: campID };
		// let self = this;
		const { user } = this.props.auth;
		var agencyID = user.id;
		this.setState({
			agencyID: agencyID,
			selectedCountry: "",
			campID: campID,
			loader: "loader_rfp",
			rfpPublisherDetails: [],
			pID: "",
		});

		fetch("requestForProposal/existingRFPCampaignDetails", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				//authorization: this.state.token,-sonali-3716-VAPT-remove token
			}, //shivani-task 2993-added code to send the token to BE through header to authenticate the api.
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((existingRFPCampaignDetails) => {
				//snehal-task-3509-Project's(DI) - Create RFP through XLS - FE

				this.setState(
					{ existingRFPCampDetails: existingRFPCampaignDetails },
					function () {
						if (this.state.existingRFPCampDetails[0].biddingType === "Geowise") { //rutuja 2832
							let biddingType = "Geo Viz";
							this.setState({ biddingType });
						} else {
							this.setState({
								biddingType: this.state.existingRFPCampDetails[0].biddingType,
							});
						}
					},
					
				);
			});
		fetch("requestForProposal/rfpPublisherResponseDetails", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				//authorization: this.state.token,//Sonali-3716-remove token-VAPT
			}, //shivani-task 2993-added code to send the token to BE through header to authenticate the api.
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((rfpPublisherResponseDetails) => {
				//shivani- 3508 - added below code for unique pID and publisher name .
				let publisherArray = rfpPublisherResponseDetails;
				const resultforpID = Array.from(
					new Set(publisherArray.map((p) => p.pID))
				).map((pID) => {
					return {
						publisherName: publisherArray.find((p) => p.pID === pID)
							.publisherName,
						pID: pID,
					};
				});

				// , publisherArray:resultforpID
				let splitCountryArray = [];
				for (var i = 0; i < rfpPublisherResponseDetails.length; i++) {
					let str = rfpPublisherResponseDetails[i].country;
					var str_array = str.split(",");
					for (var j = 0; j < str_array.length; j++) {
						splitCountryArray.push({ country: str_array[j] });
					}
				}
				const result = Array.from(
					new Set(splitCountryArray.map((p) => p.country))
				).map((country) => {
					return {
						country: country,
					};
				});
				//shivani- 3508 - added below code for unique region and iterate it...

				let splitRegionArray = [];
				//rutuja 2832- changed var i to let i for removing console warning
				for (let i = 0; i < rfpPublisherResponseDetails.length; i++) {
					let str1 = rfpPublisherResponseDetails[i].region;
					//shivani-3600-for old rfp's
					if (
						str1 === "" ||
						str1 === "null" ||
						str1 === null ||
						str1 === "undefined" ||
						str1 === undefined
					) {
					} else {
						var str_array1 = str1.split(",");
						//rutuja 2832- changed var j to let j for removing console warning
						for (let j = 0; j < str_array1.length; j++) {
							splitRegionArray.push({ region: str_array1[j] });
						}
					}
				}

				const result1 = Array.from(
					new Set(splitRegionArray.map((p) => p.region))
				).map((region) => {
					return {
						region: region,
					};
				});

				this.setState({
					splitCountryArray: result,
					splitRegionArray: result1,
					publisherArray: resultforpID,
				});
				//shivani- 3508 - added code to setState arrays used in above code...
				this.setState({
					rfpPublisherDetails: rfpPublisherResponseDetails,
					rfpPublisherDetailsFilterResponse: rfpPublisherResponseDetails,
					loader: "",
					selectedCountry: "",
					pID: "",
					cpl: 0,
					leads: 0,
					clearValue: 0,
				});
			});
		//shivani- 3602- set filter values to blank...
	} //end of rfpDetails

	handleChangeSearch(e) {
		var noData = this.state;

		e.preventDefault();
		let currentList = [];
		noData = currentList.length;

		// Variable to hold the filtered list before putting into state
		let newList = [];
		let filterData = e.target.value;
		let newList1 = [];

		if (e.target.value === "") {
			newList = this.state.existingRFP;
			newList1 = this.state.existingRFP.length;

			if (newList1 === undefined) {
				return newList1;
			}
		} else if (noData === undefined) {
			newList = "";
			filterData = "";
			this.setState({ noData: noData, filterData: filterData });
		} else {
			// Assign the original list to currentList
			currentList = this.state.existingRFP;

			noData = currentList.length;

			if (currentList === "No Data Available" || noData === undefined) {
				filterData = "";
				this.setState({ filterData: filterData });
			} else {
				newList = currentList.filter((item) => {
					//     // change current item to lowercase
					const lc = item;
					// change search term to lowercase
					const filter = e.target.value.toLowerCase();

					//   check to see if the current list item includes the search termn
					//   If it does, it will be added to newList. Using lowercase eliminates
					//   issues with capitalization in search terms and search content

					var data = Object.values(lc);
					var dataFinal = data.toString();
					dataFinal = dataFinal.replace(",", " ");
					var dataToLower = dataFinal.toLowerCase();
					return dataToLower.includes(filter);
				});
			}
		}
		// Set the filtered state based on what our rules added to newList
		this.setState({
			existingRFPsearch: [...newList],
			tableSearchText: filterData,
			tableSearchText1: filterData,
		});
	}

	selectCountry(e) {
		var selectedCountry = e.target.value;
		this.setState({ selectedCountry: selectedCountry });

		setTimeout(
			function () {
				this.filterResponsePublisher();
			}.bind(this),
			200
		);
	}

	/**
	 * @author : Shivani Pathak-task 3508
	 * @description :  Added handlechange for select publisher (for publisher dropdown).
	 */
	selectPublisher(e) {
		var pID = e.target.value;

		this.setState({ pID });

		setTimeout(
			function () {
				this.filterResponsePublisher();
			}.bind(this),
			200
		);
	}

	/**
	 * @author : Shivani Pathak-task 3508
	 * @description :  Added conditions only for publisher in below handlechange.
	 */
	filterResponsePublisher() {
		///shivani - 3602 - added some missing filter conditions.
		var selectedCountry = this.state.selectedCountry;
		var pID = this.state.pID;
		var cpl = this.state.cpl;
		var leads = this.state.leads;

		var rfpPublisherDetailsFilterResponse =
			this.state.rfpPublisherDetailsFilterResponse;
		/////for 4 ////
		if (selectedCountry == "" && cpl == 0 && leads == 0 && pID == "") {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			this.setState({ clearValue: 0 });
		} else if (selectedCountry != "" && cpl != 0 && leads != 0 && pID != "") {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return (
						a.country.includes(selectedCountry) &&
						a.CPL == cpl &&
						(a.totalLead == leads || a.monthlyLead == leads) &&
						a.pID == pID
					); //sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
				}
			);

			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 4,
			});
		}
		/////for 4/////////
		//////for 3////////
		else if (selectedCountry != "" && cpl != 0 && pID != "") {
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return (
						a.country.includes(selectedCountry) && a.CPL == cpl && a.pID == pID
					); //sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
				}
			);
			//  let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
			//   return a.pID.includes(pID) && a.CPL==cpl //sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			//  })
			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 3,
			});
		} else if (selectedCountry != "" && leads != 0 && pID != "") {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return (
						a.country.includes(selectedCountry) &&
						(a.totalLead == leads || a.monthlyLead == leads) &&
						a.pID == pID
					);
				}
			);
			//  let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
			//   return a.pID.includes(pID)  && (a.totalLead==leads || a.monthlyLead==leads)
			//  })
			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 3,
			});
		} else if (cpl != "" && leads != 0 && pID != "") {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return (
						a.CPL == cpl &&
						(a.totalLead == leads || a.monthlyLead == leads) &&
						a.pID == pID
					);
				}
			);
			//  let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
			//   return a.pID.includes(pID)  && (a.totalLead==leads || a.monthlyLead==leads)
			//  })
			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 3,
			});
		} else if (selectedCountry != "" && cpl != 0 && pID != "") {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return (
						a.country.includes(selectedCountry) && a.CPL == cpl && a.pID == pID
					);
				}
			);
			//  let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
			//   return a.pID.includes(pID)  && (a.totalLead==leads || a.monthlyLead==leads)
			//  })
			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 3,
			});
		} else if (selectedCountry != "" && leads != 0 && cpl != "") {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return (
						a.country.includes(selectedCountry) &&
						(a.totalLead == leads || a.monthlyLead == leads) &&
						a.CPL == cpl
					);
				}
			);
			//  let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
			//   return a.pID.includes(pID)  && (a.totalLead==leads || a.monthlyLead==leads)
			//  })
			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 3,
			});
		}
		//////for 3//////
		///////for 2 ////////////
		else if (selectedCountry != "" && leads != 0) {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return (
						a.country.includes(selectedCountry) &&
						(a.totalLead == leads || a.monthlyLead == leads)
					); //sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
				}
			);

			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 2,
			});
		} else if (pID != "" && leads != 0) {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return (
						a.pID == pID && (a.totalLead == leads || a.monthlyLead == leads)
					); //sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
				}
			);

			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 2,
			});
		} else if (selectedCountry != "" && cpl != 0) {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return (
						a.CPL == cpl && (a.totalLead == leads || a.monthlyLead == leads)
					); //sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
				}
			);

			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 2,
			});
		} else if (pID != "" && cpl != 0) {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return a.pID == pID && a.CPL == cpl; //sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
				}
			);

			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 2,
			});
		} else if (selectedCountry != "" && pID != "") {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.

			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return a.country.includes(selectedCountry) && a.pID == pID;
				}
			);

			//  let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{

			//   return a.pID.includes(pID)
			//  })
			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 2,
			});
		} else if (cpl != 0 && leads != 0) {
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return (
						a.CPL == cpl && (a.totalLead == leads || a.monthlyLead == leads)
					); //sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
				}
			);
			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 2,
			});
		}
		//////////////for 2////////

		/////for 1//////
		//shivani - 3508 - added for country and publisher dropdwon .
		else if (selectedCountry != "") {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.

			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return a.country.includes(selectedCountry);
				}
			);

			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 1,
			});
		} else if (pID != "") {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.

			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return a.pID == pID;
				}
			);

			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 1,
			});
		}

		//added for country and publisher dropdwon .
		else if (cpl != 0) {
			//sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.

			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return a.CPL == cpl;
				}
			);

			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 1,
			});
		} else if (leads != 0) {
			let filterRfpPublisherDetails = rfpPublisherDetailsFilterResponse.filter(
				(a) => {
					return a.totalLead == leads || a.monthlyLead == leads; //sunita-task-3378-changed from === to == as due to this changes it's impacted the whole functionality.
				}
			);
			this.setState({
				rfpPublisherDetails: filterRfpPublisherDetails,
				clearValue: 1,
			});
		}
		////////for 1////
	}

	decrementItem(e) {
		var cpl = parseInt(e.target.value);
		cpl = cpl - 1;
		var leads = this.state.leads;
		if (cpl < 0 || cpl == 0) {
			//sunita-task-3378-changed from === to == as due to this changes some functionality not working as i cross checked.
			this.setState({
				cpl: 0,
			});
		} else {
			this.setState({
				cpl: cpl,
			});
		}

		setTimeout(
			function () {
				this.filterResponsePublisher();
			}.bind(this),
			200
		);

		if (leads == 0 && cpl == 0) {
			//sunita-task-3378-changed from === to == as due to this changes some functionality not working as i cross checked.

			this.clearFilter();
		}
	}

	incrementItem(e) {
		var cpl = parseInt(e.target.value) + 1;

		this.setState({
			cpl: cpl,
		});

		setTimeout(
			function () {
				this.filterResponsePublisher();
			}.bind(this),
			200
		);
	}

	handleChange_leads(e) {
		const {  value } = e.target; //sunita-task-3378-added as it's taking which value we are targeting accordingly.
		//rutuja -2832 removed name from above {} as its never used in the file
		//sunita-task-3378 - commented below code as it's compulsory showing 0 value and when am giving any value then new value will be adding after that 0.
		// let {value}=e.target
		// value=+(value) //sandeep-task-2943 added code to handle the input value directly for lead

		this.setState({ leads: value });
		setTimeout(
			function () {
				this.filterResponsePublisher();
			}.bind(this),
			200
		);
	}
	handleChange_CPL(e) {
		const { value } = e.target; //sunita-task-3378-added as it's taking which value we are targeting accordingly.
		//rutuja -2832 removed name from above {} as its never used in the file
		//sunita-task-3378 - commented below code as it's compulsory showing 0 value and when am giving any value then new value will be adding after that 0.
		// let {value}=e.target
		// value=+(value) //sandeep-task-2943 added code to handle the input value directly for CPL

		this.setState({ cpl: value });
		setTimeout(
			function () {
				this.filterResponsePublisher();
			}.bind(this),
			200
		);
	}

	incrementLeads(e) {
		var leads = parseInt(e.target.value) + 1;
		this.setState({
			leads: leads,
		});

		setTimeout(
			function () {
				this.filterResponsePublisher();
			}.bind(this),
			200
		);
	}

	decrementLeads(e) {
		var leads = parseInt(e.target.value);
		leads = leads - 1;
		var cpl = this.state.cpl;
		if (leads < 0 || leads == 0) {
			//sunita-task-3378-changed from === to == as due to this changes some functionality not working .
			this.setState({
				leads: 0,
			});
		} else {
			this.setState({
				leads: leads,
			});
		}
		setTimeout(
			function () {
				this.filterResponsePublisher();
			}.bind(this),
			200
		);

		if (leads == 0 && cpl == 0) {
			//sunita-task-3378-changed from === to == as due to this changes some functionality not working.
			this.clearFilter();
		}
	}

	/**
	 * @author : Shivani Pathak-task 3508
	 * @description :  Added conditions only for publisher in below handlechange.
	 */
	clearFilter() {
		var filterRfpPublisherDetails = [];

		var selectedCountry = "";

		var pID = "";

		var cpl = 0;

		var leads = 0;

		var clearValue = 0;

		this.setState(
			{
				selectedCountry: selectedCountry,
				pID: pID,
				cpl: cpl,
				leads: leads,
				clearValue: clearValue,
				rfpPublisherDetails: filterRfpPublisherDetails,
			},
			function () {
				if (!this.props.auth.isAuthenticated) {
					this.props.history.push("/userLogin");
				} else {
					//for displaying particular campaign id data..

					let campID = this.state.campID;
					let data = { campID: campID };
					const { user } = this.props.auth;
					var agencyID = user.id;
					this.setState({
						agencyID: agencyID,
						selectedCountry: "",
						campID: campID,
						pID: "",
					});

					fetch("requestForProposal/existingRFPCampaignDetails", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							authorization: this.state.token,
						}, //shivani-task 2993-added code to send the token to BE through header to authenticate the api.
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((existingRFPCampaignDetails) => {
							this.setState({
								existingRFPCampDetails: existingRFPCampaignDetails,
							});
						});

					fetch("requestForProposal/rfpPublisherResponseDetails", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							authorization: this.state.token,
						}, //shivani-task 2993-added code to send the token to BE through header to authenticate the api.
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((rfpPublisherResponseDetails) => {
							//shivani- 3508 - added below code for unique pID and publisher name .

							let publisherArray = rfpPublisherResponseDetails;
							const resultforpID = Array.from(
								new Set(publisherArray.map((p) => p.pID))
							).map((pID) => {
								return {
									publisherName: publisherArray.find((p) => p.pID === pID)
										.publisherName,
									pID: pID,
								};
							});
							//   // [this.state.publisherArray] = [...resultforpID]

							let splitCountryArray = [];
							for (var i = 0; i < rfpPublisherResponseDetails.length; i++) {
								let str = rfpPublisherResponseDetails[i].country;
								var str_array = str.split(",");

								for (var j = 0; j < str_array.length; j++) {
									splitCountryArray.push({ country: str_array[j] });
								}
							}
							const result = Array.from(
								new Set(splitCountryArray.map((p) => p.country))
							).map((country) => {
								return {
									country: country,
								};
							});
							//shivani- 3508 - added below code for unique region and iterate it...
							let splitRegionArray = [];
							//rutuja 2832- changed var i to let i for removing console warning
							for (let i = 0; i < rfpPublisherResponseDetails.length; i++) {
								let str1 = rfpPublisherResponseDetails[i].region;
								//shivani-3600-for old rfp's
								if (
									str1 === "" ||
									str1 === "null" ||
									str1 === null ||
									str1 === "undefined" ||
									str1 === undefined
								) {
								} else {
									var str_array1 = str1.split(",");
									//rutuja 2832- changed var j to let j for removing console warning
									for (let j = 0; j < str_array1.length; j++) {
										splitRegionArray.push({ region: str_array1[j] });
									}
								}
							}
							const result1 = Array.from(
								new Set(splitRegionArray.map((p) => p.region))
							).map((region) => {
								return {
									region: region,
								};
							});

							this.setState({
								splitCountryArray: result,
								splitRegionArray: result1,
								publisherArray: resultforpID,
							});
							//shivani- 3508 - added code to setState arrays used in above code...

							this.setState({
								rfpPublisherDetails: rfpPublisherResponseDetails,
								rfpPublisherDetailsFilterResponse: rfpPublisherResponseDetails,
							});
						});
				}
			}
		);
	}

	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			//const { user } = this.props.auth; //rutuja 2832 commented user as never used in the file

			//Sonali-3716-VAPT
			if (this.props.location.state !== undefined) {
				const {
				agencyID,campID
				} = this.props.location.state;
				this.setState({agencyID,campID})
			}

			//const token = user.token; //shivani-task 2993- added code to declare token and using this token to authenticate the api.
			//var agencyID = user.id;
			//var agencyID=this.props.location.state.agencyID //rutja -2832 commented agencyID as it is never used in the file

			var parsed;//Somnath Task-3936, Get History Data
			if (this.props.location.state !== undefined) {
				parsed = this.props.location.state;
			} else {
				//shivani-3781-to display the same campaign while back from requestProposalAgency .
				parsed = queryString.parse(this.props.location.search);
			}
		
			// var parsed = this.props.location.state;
			var campID = parsed.campID;
		
			//let data = { agencyID: agencyID };
			//this.setState({ agencyID: agencyID}); //shivani-task 2993- added code to setState the token to increase api security.
			var noRFP = this.state;
			//sonali-3716-removing token
			fetch("requestForProposal/existingRFPCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json"}, //shivani-task 2993-added code to send the token to BE through header to authenticate the api.
				//body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((existingRFPCampaign) => {
					
					noRFP = existingRFPCampaign.length;

					if (noRFP === undefined || noRFP === null || noRFP === "") {
					}

					this.setState({ existingRFP: existingRFPCampaign, noRFP: noRFP });
					if (noRFP === undefined || noRFP === null || noRFP === "") {
					}
					if (noRFP > 0) {
			//shivani-3781-to display the same campaign while back from requestProposalAgency .

						if(campID === undefined || campID === null || campID === ""){
						campID = existingRFPCampaign[0].campID;
						}
						// let campID = existingRFPCampaign[0].campID;
						
					
						//for displaying by default id data of RFP details and RFP response from publisher
						//sonali-3716-remove token
						let data1 = { campID: campID };
						fetch("requestForProposal/existingRFPCampaignDetails", {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							
							}, //shivani-task 2993-added code to send the token to BE through header to authenticate the api.
							body: JSON.stringify(data1),
						})
							.then((res) => res.json())
							.then((existingRFPCampaignDetails) => {
								//snehal-task-3509-Project's(DI) - Create RFP through XLS - FE
							
								this.setState(
									{
										existingRFPCampDetails: existingRFPCampaignDetails,
										campID: campID,
										noRFP: noRFP,
									},
									function () {
										if (
											this.state.existingRFPCampDetails[0].biddingType ==
											"Geowise"
										) {
											let biddingType = "Geo Viz";
											this.setState({ biddingType });
										} else {
											this.setState({
												biddingType:
													this.state.existingRFPCampDetails[0].biddingType,
											});
										}
										
										
									}

								);
							});

						fetch("requestForProposal/rfpPublisherResponseDetails", {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								//authorization: token,//Sonali-3716-VAPT-removing token
							}, //shivani-task 2993-added code to send the token to BE through header to authenticate the api.
							body: JSON.stringify(data1),
						})
							.then((res) => res.json())
							.then((rfpPublisherResponseDetails) => {
								//shivani- 3508 - added below code for unique pID and publisher name .

								let publisherArray = rfpPublisherResponseDetails;
								const resultforpID = Array.from(
									new Set(publisherArray.map((p) => p.pID))
								).map((pID) => {
									return {
										publisherName: publisherArray.find((p) => p.pID === pID)
											.publisherName,
										pID: pID,
									};
								});

								let splitCountryArray = [];
								for (var i = 0; i < rfpPublisherResponseDetails.length; i++) {
									let str = rfpPublisherResponseDetails[i].country;
									var str_array = str.split(",");
									for (var j = 0; j < str_array.length; j++) {
										splitCountryArray.push({ country: str_array[j] });
									}
								}
								const result = Array.from(
									new Set(splitCountryArray.map((p) => p.country))
								).map((country) => {
									return {
										country: country,
									};
								});
								//shivani- 3508 - added below code for unique region and iterate it...
								let splitRegionArray = [];
								//rutuja 2832- changed var i to let i for removing console warning
								for (let i = 0; i < rfpPublisherResponseDetails.length; i++) {
									let str1 = rfpPublisherResponseDetails[i].region;
									//shivani-3600-for old rfp's
									if (
										str1 === "" ||
										str1 === "null" ||
										str1 === null ||
										str1 === "undefined" ||
										str1 === undefined
									) {
									} else {
										var str_array1 = str1.split(",");
										//rutuja 2832- changed var j to let j for removing console warning
										for (let j = 0; j < str_array1.length; j++) {
											splitRegionArray.push({ region: str_array1[j] });
										}
									}
								}
								const result1 = Array.from(
									new Set(splitRegionArray.map((p) => p.region))
								).map((region) => {
									return {
										region: region,
									};
								});

								// ,publisherArray:resultforpID
								this.setState({
									splitCountryArray: result,
									splitRegionArray: result1,
									publisherArray: resultforpID,
								});
								//shivani- 3508 - added code to setState arrays used in above code...

								this.setState({
									rfpPublisherDetails: rfpPublisherResponseDetails,
									rfpPublisherDetailsFilterResponse:
										rfpPublisherResponseDetails,
								});
							});
					}
				});
		}
	}
	/**
	 * @author : Shivani Pathak-task 3508
	 * @description :  changed render code as per design requirement...
	 */
	render() {
		function onChange(pagination, filters, sorter) {
			console.log("params", pagination, filters, sorter);
		}

		const columns = [
			{
				title: (
					<span>
						Publisher&nbsp;&nbsp;&nbsp;
						<span>
							<i class="fas fa-sort"></i>
						</span>
					</span>
				),
				dataIndex: "publisherName",
				key: "publisherName",
				align: "left",
				width: "165px",
				// fontSize:'13px !important',
				sorter: (a, b) => {
					const asc = "asc";

					if (a.publisherName < b.publisherName) {
						return asc ? -1 : 1;
					} else if (a.publisherName > b.publisherName) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
			},
			{
				title: (
					<span>
						Region&nbsp;&nbsp;&nbsp;
						<span>
							<i class="fas fa-sort"></i>
						</span>
					</span>
				),
				dataIndex: "region",
				key: "region",
				align: "left",
				width: "165px",
				sorter: (a, b) => {
					const asc = "asc";

					if (a.region < b.region) {
						return asc ? -1 : 1;
					} else if (a.region > b.region) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div
						class="countrylist"
						style={{ textAlign: "center", marginLeft: "-3px" }}>
						<span className="countrylist-country" style={{ fontSize: "13px" }}>
							{record.region}
						</span>
					</div>
				),
			},
			{
				title: (
					<span>
						Countries&nbsp;&nbsp;&nbsp;
						<span>
							<i class="fas fa-sort"></i>
						</span>
					</span>
				),
				dataIndex: "country",
				key: "country",
				align: "left",
				width: "135px",
				sorter: (a, b) => {
					const asc = "asc";

					if (a.country < b.country) {
						return asc ? -1 : 1;
					} else if (a.country > b.country) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div
						class="countrylist"
						style={{ textAlign: "center", marginLeft: "-3px" }}>
						<span className="countrylist-country" style={{ fontSize: "13px" }}>
							{record.country}
						</span>
					</div>
				),
			},

			{
				title: (
					<span>
						Lead Monthly&nbsp;&nbsp;&nbsp;
						<span>
							<i class="fas fa-sort"></i>
						</span>
					</span>
				),
				dataIndex: "monthlyLead",
				key: "monthlyLead",
				align: "left",
				fontSize: "13px !important",
				width: "135px",

				// sorter: (a, b) => a.monthlyLead - b.monthlyLead,
				sorter: (a, b) => {
					const asc = "asc";

					if (a.monthlyLead < b.monthlyLead) {
						return asc ? -1 : 1;
					} else if (a.monthlyLead > b.monthlyLead) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
			},
			{
				title: (
					<span>
						Lead Total&nbsp;&nbsp;&nbsp;
						<span>
							<i class="fas fa-sort"></i>
						</span>
					</span>
				),
				dataIndex: "totalLead",
				key: "totalLead",
				align: "left",
				fontSize: "13px !important",
				width: "135px",

				// sorter: (a, b) => a.totalLead - b.totalLead,
				sorter: (a, b) => {
					const asc = "asc";

					if (a.totalLead < b.totalLead) {
						return asc ? -1 : 1;
					} else if (a.totalLead > b.totalLead) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
			},
			{
				title: (
					<span>
						CPL&nbsp;&nbsp;&nbsp;
						<span>
							<i class="fas fa-sort"></i>
						</span>
					</span>
				),
				dataIndex: "CPL",
				key: "CPL",
				align: "left",
				fontSize: "13px !important",
				width: "135px",

				// sorter: (a, b) => a.CPL - b.CPL,
				sorter: (a, b) => {
					const asc = "asc";

					if (a.CPL < b.CPL) {
						return asc ? -1 : 1;
					} else if (a.CPL > b.CPL) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
			},
		];

		function onChange(pagination, filters, sorter) {}
		return (
			<div>
				<Navigation />

				<div
					class="container-fluid rfpmainhead"
					style={{
						paddingTop: "125px",
						paddingRight: "40px",
						paddingLeft: "40px",
					}}>
						<div class="row" style={{ marginBottom: "2px" }}>
						<div
							class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3"
							style={{ paddingLeft: "43px" }}>
							<a
							onClick={() =>
									this.props.history.push("/newdashboard", {
										agencyID: this.state.agencyID,
									})
									
								} //karan-task-3684-replace query params
								style={{ color: "#056eb8" }}
								href="newdashboard" //rutuja 2832 added href for removing console warning
								>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back to Dashboard"
								/>
							</a>
						</div>

						<div className=" col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 rfpanalysishead ">
							{/* //shivani-3284-passed ID for DI label consistency */}
							<label
								className="heading1"
								id="labelDI"
								style={{ marginLeft: "257px" }}>
								RFP Response Analysis
							</label>
						</div>
					</div>
					<div class="card card-signin my-1">
						<div class="card-body" style={{ padding: "5px" }}>
							<div
								class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 campaignslists "
								style={{
									background: "#FFFFFF",
									borderRight: "2px solid #eee",
									padding: "0px",
									height: "90px",
								}}>
								<div id="rfpexthead">
									{/* //shivani - 3601 - changed alignment of label  */}
									<span style={{ marginLeft: "10px" }}>
										<label
											style={{
												fontSize: "17px",
												color: "#14254A",
												marginTop: "10px",
												fontWeight: "500",
												fontFamily: "roboto",
											}}>
											Existing RFP &nbsp;:&nbsp;
										</label>
									</span>
								</div>
								<div class="row">
									<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										{/* <span class="form-control"> */}
										<input
											type="text"
											class="form-control"
											className="inputstylesearch"
											name="search"
											placeholder="Search RFP"
											style={{
												backgroundImage: "url(searchIcon.png)",
												backgroundPosition: "15px 6px",
												backgroundSize: "15px 15px",
												backgroundRepeat: "no-repeat",
												width: "280px",
												height: "28px",
												fontSize: "12px",
												marginLeft: "10px",
												border: "1px solid #CCCCCC",
												padding: "12px 20px 15px 36px",
												backgroundColor: "#FFFFFF",
												fontFamily: "sans-serif",
												borderRadius: "2px",
											}}
											onChange={this.handleChangeSearch}
											onPressEnter={this.handleChangeSearch}></input>
										{/* </span> */}
									</div>
								</div>
								<br />
								<hr />
								<div
									style={{
										overflowY: "scroll",
										height: "1350px",
										background: "#FFFFFF",
										padding: "0px",
										borderRight: "2px solid #eee",
										marginTop: "-9px",
									}}>
									{" "}
									{/*sunita-task-3270-added col and it's data as per requirement */}
									{this.state.noRFP === undefined ? (
										<center>
											<label
												style={{
													color: "red",
													fontSize: "medium",
													marginTop: "34px",
												}}>
												No RFP Created
											</label>
										</center>
									) : this.state.tableSearchText === "" ? (
										<div>
											{this.state.existingRFP.map((existingRFP) => (
												<div
													style={{
														borderTop: "1px solid #eee",
														padding: "5px 15px",
													}}
													key={existingRFP.campID}
													className="campName">
													<a
														class="camp-details"
														href="#/"
														id={existingRFP.campID}
														onClick={this.rfpDetails}>
														{existingRFP.campID} - {existingRFP.campaignName}
													</a>
												</div>
											))}
										</div>
									) : this.state.existingRFPsearch.length > 0 ? (
										<div>
											{this.state.existingRFPsearch.map((existingRFPsearch) => (
												<div
													style={{
														borderTop: "1px solid #eee",
														padding: "5px 15px",
													}}>
													<a
														href="#/"
														id={existingRFPsearch.campID}
														onClick={this.rfpDetails}
														className="campName">
														{existingRFPsearch.campID} -{" "}
														{existingRFPsearch.campaignName}
													</a>
												</div>
											))}
										</div>
									) : (
										<center>
											<label
												style={{
													color: "red",
													fontSize: "medium",
													marginTop: "34px",
												}}>
												No Data Exist
											</label>
										</center>
									)}
								</div>
							</div>

							<div
								className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9"
								id="campaignData1"
								style={{ background: "#FFFFFF", paddingTop: "20px" }}>
								{/*sunita-task-3270-added before whole data in this column as per requirement */}

								<div
									className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 maindivrfp-data"
									style={{ background: "#FFFFFF", height: "850px" }}>
									{this.state.noRFP === undefined ? (
										<center>
											<label
												style={{
													color: "red",
													fontSize: "medium",
													marginTop: "34px",
													marginRight: "30px",
												}}>
												No RFP Created
											</label>
										</center>
									) : (
										<div>
											<h5
												style={{
													color: "#193D8F",
													paddingTop: "15px",
													fontSize: "16px",
													paddingLeft: "18px",
												}}>
												RFP Details
											</h5>

											<div class="row">
												{this.state.existingRFPCampDetails.map(
													(existingRFPCampDetails) => (
														<div
															className="rfpdetailsinwebkit"
															style={{
																display: "inlineBlock",
																height: "130px",
																flex: "0 0 125%",
																width: "-webkit-fill-available",
																marginLeft: "33px",
																backgroundColor: "#FFFFFF",
																border: "1px solid #CCCCCC",
																borderRadius: "2px",
															}}>
															<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6  ">
																<label
																	className=" rfpdetails1"
																	style={{
																		fontSize: "14px",
																		paddingTop: "4px",
																		color: "#414141",
																	}}>
																	{" "}
																	RFP ID &nbsp;:&nbsp;
																	{existingRFPCampDetails.campID}{" "}
																</label>
															</div>
															<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-6  ">
																{/* {existingRFPCampDetails.biddingType == "Geowise" ?
                                                             <label className="rfpdetails1" style={{fontSize:'14px',paddingTop:'4px',color:'#414141'}}> RFP Type &nbsp;:&nbsp;Geo Viz</label>    
                                                       : */}
																{/* //snehal-task-3509-Project's(DI) - Create RFP through XLS - FE */}

																<label
																	className="rfpdetails1"
																	style={{
																		fontSize: "14px",
																		paddingTop: "4px",
																		color: "#414141",
																	}}>
																	{" "}
																	RFP Type &nbsp;:&nbsp;{
																		this.state.biddingType
																	}{" "}
																</label>
																{/* } */}
															</div>

															<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
																<label
																	className="rfpdetails1"
																	style={{
																		fontSize: "14px",
																		paddingBottom: "8px",
																		color: "#414141",
																	}}>
																	{" "}
																	RFP Name &nbsp;&nbsp;:&nbsp;
																	{existingRFPCampDetails.campaignName}{" "}
																</label>
															</div>
															{/* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE */}
															<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  ">
																<span
																	className="rfpdetails2"
																	style={{
																		fontSize: "13px",
																		color: "#4F4F4F",
																	}}>
																	Advertiser Name
																</span>
																&nbsp;:&nbsp;
																<span className="rfpdetails2">
																	{existingRFPCampDetails.clientName}
																</span>{" "}
																&nbsp;
															</div>

															<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  ">
																<span
																	className="rfpdetails2"
																	style={{
																		fontSize: "13px",
																		color: "#4F4F4F",
																	}}>
																	Campaign Type
																</span>
																&nbsp;:&nbsp;
																<span className="rfpdetails2">
																	{existingRFPCampDetails.campaignType}
																</span>{" "}
																&nbsp;
															</div>
															<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
																<span
																	className="rfpdetails2"
																	style={{
																		width: "39%",
																		fontSize: "13px",
																		color: "#4F4F4F",
																	}}>
																	Start Date{" "}
																</span>
																:&nbsp;
																<span className="rfpdetails2">
																	{existingRFPCampDetails.startDate}
																</span>
																&nbsp;
															</div>
															<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  ">
																<span
																	className="rfpdetails2"
																	style={{
																		fontSize: "13px",
																		color: "#4F4F4F",
																	}}>
																	End Date
																</span>
																&nbsp;:&nbsp;
																<span className="rfpdetails2">
																	{existingRFPCampDetails.endDate}
																</span>
																&nbsp;
															</div>

															<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  ">
																<span
																	className="rfpdetails2"
																	style={{
																		fontSize: "13px",
																		color: "#4F4F4F",
																	}}>
																	Target CPL
																</span>
																&nbsp;:&nbsp;
																<span className="rfpdetails2">
																	{existingRFPCampDetails.CPL}
																</span>{" "}
																&nbsp;
															</div>
															<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
																<span
																	className="rfpdetails2"
																	style={{
																		width: "39%",
																		fontSize: "13px",
																		color: "#4F4F4F",
																	}}>
																	Net CPL
																</span>
																:&nbsp;
																<span className="rfpdetails2">
																	{existingRFPCampDetails.netCPL}
																</span>
																&nbsp;
															</div>
															<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  ">
																<span
																	className="rfpdetails2"
																	style={{
																		fontSize: "13px",
																		color: "#4F4F4F",
																	}}>
																	Rate Card CPL
																</span>
																&nbsp;:&nbsp;
																<span className="rfpdetails2">
																	{existingRFPCampDetails.rateCardCPL}
																</span>
																&nbsp;
															</div>
															<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  ">
																<span
																	className="rfpdetails2"
																	style={{
																		fontSize: "13px",
																		color: "#4F4F4F",
																	}}>
																	Currency
																</span>
																&nbsp;:&nbsp;
																<span className="rfpdetails2">
																	{existingRFPCampDetails.currency}
																</span>{" "}
																&nbsp;
															</div>
														</div>
													)
												)}
											</div>
											<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<div>
													<h5
														className="rfpResponse"
														style={{
															color: "#193D8F",
															paddingTop: "18px",
															fontSize: "16px",
															paddingLeft: "3px",
														}}>
														RFP Response From Publisher
													</h5>
												</div>
												<div class="row" style={{ paddingLeft: "19px" }}>
													<h4 style={{ color: " #F28C0F", fontSize: "14px" }}>
														Filters
													</h4>
												</div>

												<div
													class="row"
													style={{ paddingLeft: "4px", paddingBottom: "10px" }}>
													{/* // for region  */}
													<div
														className="col-xs-12 col-sm-6 col-md-4 col-lg-2 col-xl-2"
														id="filter_block1">
														<div>
															<div>
																<span
																	id="p_lbl"
																	style={{
																		color: "#193D8F",
																		fontSize: "small",
																		fontWeight: "500",
																	}}>
																	Publisher
																</span>
															</div>
															{/* {JSON.stringify(this.state.publisherArray)} */}

															<select
																class="form-control inputStyle"
																style={{
																	width: "148px",
																	height: "34px",
																	border: " 1px solid #CCCCCC",
																	borderRadius: " 3px",
																	backgroundColor: "#FFFFFF",
																	fontFamily: "sans-serif",
																	color: " #555555",
																}}
																value={this.state.pID}
																required
																onChange={this.selectPublisher}>
																<option
																	style={{
																		fontSize: "14px",
																		fontFamily: "sans-serif",
																	}}
																	value=""
																	disabled
																	selected>
																	Select Publisher
																</option>

																{this.state.publisherArray.map(
																	(publisherArray, i) => {
																		return (
																			<option
																				value={publisherArray.pID}
																				key={i}>
																				{publisherArray.publisherName}
																			</option>
																		);
																		// })
																	}
																)}
															</select>
														</div>
													</div>

													<div
														className="col-xs-12 col-sm-6 offset-md-2 col-md-4 col-lg-2 col-xl-2"
														id="filter_block2"
														style={{ marginLeft: "55px" }}>
														<div>
															<div>
																<span
																	id="p_lbl"
																	style={{
																		color: "#193D8F",
																		fontSize: "small",
																		fontWeight: "500",
																	}}>
																	Countries
																</span>
															</div>

															<select
																class="form-control inputStyle"
																style={{
																	width: "148px",
																	height: "34px",
																	border: " 1px solid #CCCCCC",
																	borderRadius: " 3px",
																	backgroundColor: "#FFFFFF",
																	fontFamily: "sans-serif",
																	color: " #555555",
																}}
																value={this.state.selectedCountry}
																required
																onChange={this.selectCountry}>
																<option value="" disabled selected>
																	Select Country
																</option>
																{this.state.splitCountryArray.map(
																	(splitCountryArray) => {
																		return (
																			<option value={splitCountryArray.country}>
																				{splitCountryArray.country}
																			</option>
																		);
																	}
																)}
															</select>
														</div>
													</div>

													<div
														class="col-xs-12 col-sm-6 col-md-4 col-lg-2 col-xl-2"
														id="filter_block3"
														style={{ marginLeft: "55px" }}>
														<div>
															<label
																style={{
																	color: "#193D8F",
																	fontSize: "small",
																	fontWeight: "500",
																}}>
																CPL
															</label>

															<div class="number-input md-number-input">
																<button
																	onClick={this.decrementItem}
																	class="minus"
																	value={this.state.cpl}></button>
																<input
																	min="0"
																	name="count"
																	onChange={this.handleChange_CPL}
																	value={this.state.cpl}
																	style={{
																		backgroundColor: "white",
																		fontSize: "15px",
																	}}
																	type="number"
																/>
																<button
																	onClick={this.incrementItem}
																	class="plus"
																	value={this.state.cpl}></button>
															</div>
														</div>
													</div>
													{/* //shivani - 3601 - added css to new class for resposiveness  */}

													<div
														class="col-xs-12 col-sm-6 offset-md-2 col-md-4 col-lg-2 col-xl-2 filter_block_lead_Filter"
														id=" filter_block4"
														style={{ marginLeft: "25px" }}>
														<div>
															<label
																style={{
																	color: "#193D8F",
																	fontSize: "small",
																	fontWeight: "500",
																}}>
																Leads
															</label>

															<div class="number-input md-number-input">
																<button
																	onClick={this.decrementLeads}
																	class="minus"
																	value={this.state.leads}></button>
																<input
																	min="0"
																	name="count"
																	onChange={this.handleChange_leads}
																	value={this.state.leads}
																	style={{
																		backgroundColor: "white",
																		fontSize: "15px",
																	}}
																	type="number"
																/>
																<button
																	onClick={this.incrementLeads}
																	class="plus"
																	value={this.state.leads}></button>
															</div>
														</div>
													</div>
													<div
														id="filter_block5"
														className="col-xs-12 col-sm-12 offset-md-3 col-md-4 offset-lg-11 col-lg-3 col-xl-3"
														style={{ marginTop: "-53px" }}>
														<div>
															<label
																onClick={this.clearFilter}
																value={this.state.clearValue}
																className="clear"
																style={{
																	color: "#193D8F",
																	fontSize: "small",
																	paddingTop: "27px",
																	marginLeft: "-12px",
																}}>
																Clear Filter
															</label>
															<button
																class="button"
																style={{
																	padding: "-9px",
																	marginLeft: "5px",
																	borderRadius: "10px",
																	color: "#193D8F",
																}}
																onClick={this.clearFilter}
																value={this.state.clearValue}>
																{this.state.clearValue}
															</button>
														</div>
													</div>
												</div>
												<div
													class="row"
													id="dwnld_rfp"
													style={{
														flex: "auto",
														width: "-webkit-fill-available",
														marginLeft: "-350px",
													}}>
													<div class=" col-sm-4 offset-md-8 col-md-8 offset-lg-11 col-lg-4 dwnld_rfp1">
														{/* <div class="offset-lg-8 col-lg-4"> */}
														{/* rutuja 2832- added alt tag for removing console warning */}
														<span>
															<a>
																<img
																	src="download1.png"
																	onClick={this.downloadRfpDetails}
																	style={{
																		width: "18px",
																		height: "18px",
																		color: "##1890FF",
																		float: "right",
																		marginRight: "10px",
																		fontSize: "14px",
																	}}
																	alt=""></img>
																	
															</a>
														</span>
														<label
															class="float-right"
															style={{ color: "#1890FF", fontSize: "14px" }}>
															Download RFP Analysis
														</label>
													</div>
												</div>
												<div id={this.state.loader}></div>
												{/* rutuja 2832 className="ant-table-thead" to class="ant-table-thead" for removing console warning */}
												<div class="row">
													<div
														class="tablewidth"
														style={{
															flex: "0 0 135%",
															width: "-webkit-fill-available",
														}}>
														<Table
															{...this.state.tableState}
															id="myTable"
															dataSource={this.state.rfpPublisherDetails}
															loading={this.state.loading}
															onChange={onChange}
															columns={columns}
															class="ant-table-thead"
															className={tableCSS1}
															scroll={{ x: 500, y: 240 }}
															pagination={{
																pageSize: this.state.pageSize,
																position: "bottom",
															}}></Table>
														<div class="row">
															<div
																class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
																style={{
																	marginTop: "-52px",
																	marginLeft: "5px",
																	fontSize: "13px",
																	color: "#4F4F4F",
																	fontFamily: "sans-serif",
																}}>
																&nbsp;Records per page:&nbsp;
																<select
																	defaultValue={this.state.pageSize}
																	onChange={this.handleChangePageSize}
																	id="pacing1"
																	class="input-small"
																	className="form-control"
																	name="pacing"
																	style={{
																		width: "71px",
																		height: "30px",
																		display: "initial",
																		fontSize: "13px",
																		fontFamily: "sans-serif",
																		color: "#4F4F4F",
																	}}>
																	<option value="50" selected>
																		50
																	</option>
																	<option value="100">100</option>
																	<option value="150">150</option>
																</select>
															</div>
														</div>
													</div>
													
			{/* //shivani-3781-added button for accept rfp . */}
													
													<div class="col" id="acceptRFP_btn" align="right" style={{marginTop:'15px',marginLeft:'244px',
												flex: "0 0 200%",
												width: "-webkit-fill-available",}}>
                    <button 
                      class="btn add-button"
                      type=""                 
           style={{backgroundColor:"#144999",color:"white"}}         
		   onClick={this.acceptRFP}
		   id={this.state.campID}
                    >
					
                      Accept RFP
                    </button>
                  </div>
				  <br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
													<br />
												</div>
												
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					
				</div>
				{/*end of container*/}

				<Footer />
			</div> //end of main div
		); //end of return
	} //end of render
} //end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
RfpAnalysis.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(RfpAnalysis)
);
