/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author: Snehal More
*@fileName :clientSetup.js
 *Desc: hubspot client set up for hubspot lead
*/

import React from 'react';
import {Component} from 'react';
import AdminNavpage from  '../layouts/adminNavpage';
import Footer from '../layouts/footer';
import './clientSetup.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
///import {Tooltip,Icon} from 'antd'; //Rutuja TAsk 4796 commented as never used
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3927-VAPT URL changes
const Swal = require('sweetalert2')
 class HubspotClientSetup extends Component {
    constructor(){ 
        super() 
        this.state={
            clientID:'',
            mappingFile:'',
            mappingFileName:'',
            fileName:'',
            app:'',
            api:'',
            clientNameDisplay:"none",
            agencyNameDisplay:'none',
            emailDisplay:"none",
            emailDisplay1:"none",            
            mappingFileDisplay:"none",
            mappingFileDisplay1:'none',
            mappingnameDisplay:'none',
            apiKeyDisplay:'none',
            apiKeyDisplay1:'none',
            appIDDisplay:'none',
            appIDDisplay1:'none',
            //Supriya- Task 4283 - added variable for Lead Delivery to hubspot through Forms
            formIDDisplay:'none',
            formIDDisplay1:'none',
            portalIDDisplay:'none',
            portalIDDisplay1:'none',
            hubspotClientIDDisplay:'none',
            hubspotClientIDDisplay1:'none',
            scopesDisplay:'none',
            scopesDisplay1:'none',
            redirectURIDisplay:'none',
            redirectURIDisplay1:'none',
            clientSecretDisplay:'none',
            clientSecretDisplay1:'none',
            allClientNameDetails:[],
            allAgencyNameDetails:[],
            ClientSetupArray:[{clientName:"",agencyName:'',email:"",apiKey:'',appID:'',hubspotClientID:'',scopes:'',redirectURI:'',clientSecret:"",app:'',api:''}],
        }
        this.commonhandleChange=this.commonhandleChange.bind(this);
        this.commonhandleFormChange=this.commonhandleFormChange.bind(this);
        this.submitForm=this.submitForm.bind(this);
        this.validateForm=this.validateForm.bind(this);
        this.validateHubspotForm=this.validateHubspotForm.bind(this);
        this.handleChangeCheck=this.handleChangeCheck.bind(this);
        this.handleChangeFormCheck=this.handleChangeFormCheck.bind(this);
    }
componentWillMount(){ 
    if(!this.props.auth.isAuthenticated) {
        this.props.history.push('/userLogin');
      }
      else
      {      
       // const {user} = this.props.auth//Rutuja TAsk 4796 commented as never used
          fetch("/hubspotClient/allClientNameDetailsForHubspot")
          .then(res => res.json())
          .then((allClientNameDetails=>{
          this.setState({allClientNameDetails: allClientNameDetails})
          }))
        fetch("/hubspotClient/allAgencyNameDetailsForHubspot")
        .then(res => res.json())
            .then((allAgencyNameDetails=>{
        this.setState({allAgencyNameDetails: allAgencyNameDetails})
        }))
    }
}
downloadmappingFile(e) { //kiran-4881-function for download mapping file 
    e.preventDefault();
    let { mapping, mappingFileName } = this.state.ClientSetupArray[0];
    const buf = Buffer.from(mapping);
    var newBlob = new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = mappingFileName;
    link.click();
}
clientSetupBackButton(e){
    //const {user} = this.props.auth //Rutuja TAsk 4796 commented as never used
  //var adminID= user.id;//Rutuja TAsk 4796 commented as never used
 this.props.history.push("/adminDashboard");//Akash-Bug-4131-removed user Id
 e.preventDefault()//Akash-Bug-4131
  }
commonhandleChange(e){ 
    e.preventDefault()
    const{name,value}=e.target
    if(name==="clientName"){
        document.getElementById('clientName').style.border='1px solid lightgray';
        document.getElementById('agencyName').style.border='1px solid lightgray';
        document.getElementById('email').style.border='1px solid lightgray';
        document.getElementById('apiKey').style.border='1px solid lightgray';
        document.getElementById('appID').style.border='1px solid lightgray';
        document.getElementById('hubspotClientID').style.border='1px solid lightgray';
        document.getElementById('scopes').style.border='1px solid lightgray';
        document.getElementById('redirectURI').style.border='1px solid lightgray';
        document.getElementById('clientSecret').style.border='1px solid lightgray';
        document.getElementById('mappingFile').value="";
        this.setState({clientNameDisplay:"none",mappingFileName:'',agencyNameDisplay:'none',
        emailDisplay:'none',emailDisplay1:'none',
        apiKeyDisplay:'none',apiKeyDisplay1:'none',
        appIDDisplay:'none',appIDDisplay1:'none',
        hubspotClientIDDisplay:'none',hubspotClientIDDisplay1:'none',
        scopesDisplay:'none',scopesDisplay1:'none',
        redirectURIDisplay:'none',redirectURIDisplay1:'none',
        mappingFileDisplay:'none',mappingFileDisplay1:'none',
        clientSecretDisplay:'none',clientSecretDisplay1:'none'});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "clientName": value };//Rutuja 4796 console warning
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });  
        if(this.state.ClientSetupArray[0].clientName.length>0 && this.state.ClientSetupArray[0].agencyName.length>0){
            let data={
                clientSetupDetails:this.state.ClientSetupArray
            }
            fetch("/hubspotClient/hubspotClientSetupDetails", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
                }).then(res => res.json()).then(hubspotClientSetupDetails=> { 
                    if(hubspotClientSetupDetails.length>0){
                        
                    this.setState({ ClientSetupArray:hubspotClientSetupDetails, 
                        mappingFileName:hubspotClientSetupDetails[0].mappingFileName,
                        mappingFile:hubspotClientSetupDetails[0].mapping,mappingnameDisplay:'none'})
                    Swal.fire({              
                        text: ("Hubspot client Setup is already exists"),
                        type: 'question',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,                    
                      })
                }  
               
                    else{
                       var ClientSetupArray=[{clientName:this.state.ClientSetupArray[0].clientName,
                        agencyName:this.state.ClientSetupArray[0].agencyName,email:"",mappingFile:null,mappingFileName:'',apiKey:'',appID:'',hubspotClientID:'',scopes:'',redirectURI:'',app:'No',api:'No'}]
                       this.setState({ ClientSetupArray:ClientSetupArray,fileName:'',mappingnameDisplay:'none'})
                       Swal.fire({              
                        text: ("Hubspot client setup not exists"),
                        type: 'question',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,                   
                      })
                    }              
                }) 
            }        
    }    
    if(name==="agencyName"){
        document.getElementById('clientName').style.border='1px solid lightgray';
        document.getElementById('agencyName').style.border='1px solid lightgray';
        document.getElementById('email').style.border='1px solid lightgray';
        document.getElementById('apiKey').style.border='1px solid lightgray';
        document.getElementById('appID').style.border='1px solid lightgray';
        document.getElementById('hubspotClientID').style.border='1px solid lightgray';
        document.getElementById('scopes').style.border='1px solid lightgray';
        document.getElementById('redirectURI').style.border='1px solid lightgray';
        document.getElementById('clientSecret').style.border='1px solid lightgray';
        document.getElementById('mappingFile').value="";
        this.setState({clientNameDisplay:"none",mappingFileName:'',agencyNameDisplay:'none',
        emailDisplay:'none',emailDisplay1:'none',
        apiKeyDisplay:'none',apiKeyDisplay1:'none',
        appIDDisplay:'none',appIDDisplay1:'none',
        hubspotClientIDDisplay:'none',hubspotClientIDDisplay1:'none',
        scopesDisplay:'none',scopesDisplay1:'none',
        redirectURIDisplay:'none',redirectURIDisplay1:'none',
        mappingFileDisplay:'none',mappingFileDisplay1:'none',
        clientSecretDisplay:'none',clientSecretDisplay1:'none'});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "agencyName": value };//Rutuja 4796 console warning
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray  }); 
        
        if(this.state.ClientSetupArray[0].clientName.length>0 && this.state.ClientSetupArray[0].agencyName.length>0){
        let data={
            clientSetupDetails:this.state.ClientSetupArray
        }
        fetch("/hubspotClient/hubspotClientSetupDetails", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
            }).then(res => res.json()).then(hubspotClientSetupDetails=> { 
                if(hubspotClientSetupDetails.length>0){
                    this.setState({ ClientSetupArray:hubspotClientSetupDetails,
                        mappingFileName:hubspotClientSetupDetails[0].mappingFileName,
                        mappingFile:hubspotClientSetupDetails[0].mapping,mappingnameDisplay:'none'})
                Swal.fire({              
                    text: ("Hubspot client setup is already exists"),
                    type: 'question',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,                  
                  })
            }  
                else{
                   var ClientSetupArray=[{clientName:this.state.ClientSetupArray[0].clientName,
                    agencyName:this.state.ClientSetupArray[0].agencyName,email:"",mappingFile:null,mappingFileName:'',apiKey:'',appID:'',hubspotClientID:'',scopes:'',redirectURI:'',clientSecret:'',app:'No',api:'No'}]
                   this.setState({ ClientSetupArray:ClientSetupArray,fileName:'',mappingnameDisplay:'none'})
                   Swal.fire({              
                    text: ("Hubspot client setup not exists"),
                    type: 'question',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,                   
                  })
                }              
            }) 
        }       
    }   
    if(name==="email"){
        document.getElementById('email').style.border='1px solid lightgray';
        this.setState({emailDisplay:"none",emailDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "email": value };//Rutuja 4796 console warning
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }  
    
    if(name==="mappingFile"){
        let files=e.target.files;
        let fileData=e.target.files[0];
        var fileName=e.target.files[0].name;
        var HTML = "<b>";
        HTML += 'Uploaded File : ' + fileName ;
        HTML += "</b>";
        document.getElementById("mappingName").innerHTML = HTML;
       
        if(files.length>0)
            {
                this.setState({mappingFile:fileData,mappingFileDisplay:'none',mappingFileDisplay1:'none',mappingFileName:"",mappingnameDisplay:'block'})
            }
        } 

        if(name==="apiKey"){
            document.getElementById('apiKey').style.border='1px solid lightgray';
            this.setState({apiKeyDisplay:"none",apiKeyDisplay1:"none"});
            let ClientSetupArray=[...this.state.ClientSetupArray]
            ClientSetupArray[0] = { ...ClientSetupArray[0], "apiKey": value }; //Rutuja 4796 console warning
            this.state.ClientSetupArray=[...ClientSetupArray];
            this.setState({ ClientSetupArray:ClientSetupArray });    
        }
        if(name==="appID"){
            document.getElementById('appID').style.border='1px solid lightgray';
            this.setState({appIDDisplay:"none",appIDDisplay1:"none"});
            let ClientSetupArray=[...this.state.ClientSetupArray]
            ClientSetupArray[0] = { ...ClientSetupArray[0], "appID": value };//Rutuja 4796 console warning
            this.state.ClientSetupArray=[...ClientSetupArray];
            this.setState({ ClientSetupArray:ClientSetupArray });    
        }
        if(name==="hubspotClientID"){
            document.getElementById('hubspotClientID').style.border='1px solid lightgray';
            this.setState({hubspotClientIDDisplay:"none",hubspotClientIDDisplay1:"none"});
            let ClientSetupArray=[...this.state.ClientSetupArray]
            ClientSetupArray[0] = { ...ClientSetupArray[0], "hubspotClientID": value }; //Rutuja 4796 console warning
            this.state.ClientSetupArray=[...ClientSetupArray];
            this.setState({ ClientSetupArray:ClientSetupArray });    
        }
        if(name==="scopes"){
            document.getElementById('scopes').style.border='1px solid lightgray';
            this.setState({scopesDisplay:"none",scopesDisplay1:"none"});
            let ClientSetupArray=[...this.state.ClientSetupArray]
            ClientSetupArray[0] = { ...ClientSetupArray[0], "scopes": value }; //Rutuja 4796 console warning
            this.state.ClientSetupArray=[...ClientSetupArray];
            this.setState({ ClientSetupArray:ClientSetupArray });    
        }
        if(name==="redirectURI"){
            document.getElementById('redirectURI').style.border='1px solid lightgray';
            this.setState({redirectURIDisplay:"none",redirectURIDisplay1:"none"});
            let ClientSetupArray=[...this.state.ClientSetupArray]
            ClientSetupArray[0] = { ...ClientSetupArray[0], "redirectURI": value }; //Rutuja 4796 console warning
            this.state.ClientSetupArray=[...ClientSetupArray];
            this.setState({ ClientSetupArray:ClientSetupArray });    
        }
        if(name==="clientSecret"){
            document.getElementById('clientSecret').style.border='1px solid lightgray';
            this.setState({clientSecretDisplay:"none",clientSecretDisplay1:"none"});
            let ClientSetupArray=[...this.state.ClientSetupArray]
            ClientSetupArray[0] = { ...ClientSetupArray[0], "clientSecret": value }; //Rutuja 4796 console warning
            this.state.ClientSetupArray=[...ClientSetupArray];
            this.setState({ ClientSetupArray:ClientSetupArray });    
        }
}

//Supriya- Task 4283 -Added this function to add changed value in client set up array
commonhandleFormChange(e){ 
    e.preventDefault()
    const{name,value}=e.target
    if(name==="clientName"){
        document.getElementById('clientName').style.border='1px solid lightgray';
        document.getElementById('agencyName').style.border='1px solid lightgray';
        document.getElementById('email').style.border='1px solid lightgray';
        document.getElementById('apiKey').style.border='1px solid lightgray';
        document.getElementById('formID').style.border='1px solid lightgray';
        document.getElementById('portalID').style.border='1px solid lightgray';
      
        document.getElementById('mappingFile').value="";
        this.setState({clientNameDisplay:"none",mappingFileName:'',agencyNameDisplay:'none',
        emailDisplay:'none',emailDisplay1:'none',
        apiKeyDisplay:'none',apiKeyDisplay1:'none',
        formIDDisplay:'none',formIDDisplay1:'none',
        portalIDDisplay:'none',portalIDDisplay1:'none',});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "clientName": value }; //Rutuja 4796 console warning
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });  
        if(this.state.ClientSetupArray[0].clientName.length>0 && this.state.ClientSetupArray[0].agencyName.length>0){
            let data={
                clientSetupDetails:this.state.ClientSetupArray
            }
            fetch("/hubspotClient/hubspotClientSetupDetails", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
                }).then(res => res.json()).then(hubspotClientSetupDetails=> { 
                    if(hubspotClientSetupDetails.length>0){
                        
                    this.setState({ ClientSetupArray:hubspotClientSetupDetails, 
                        mappingFileName:hubspotClientSetupDetails[0].mappingFileName,
                        mappingFile:hubspotClientSetupDetails[0].mapping,mappingnameDisplay:'none'})
                    Swal.fire({              
                        text: ("Hubspot client Setup is already exists"),
                        type: 'question',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,                    
                      })
                }  
               
                    else{
                       var ClientSetupArray=[{clientName:this.state.ClientSetupArray[0].clientName,
                        agencyName:this.state.ClientSetupArray[0].agencyName,email:"",mappingFile:null,mappingFileName:'',apiKey:'',appID:'',hubspotClientID:'',scopes:'',redirectURI:'',app:'No',api:'No'}]
                       this.setState({ ClientSetupArray:ClientSetupArray,fileName:'',mappingnameDisplay:'none'})
                       Swal.fire({              
                        text: ("Hubspot client setup not exists"),
                        type: 'question',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,                   
                      })
                    }              
                }) 
            }        
    }    
    if(name==="agencyName"){
        document.getElementById('clientName').style.border='1px solid lightgray';
        document.getElementById('agencyName').style.border='1px solid lightgray';
        document.getElementById('email').style.border='1px solid lightgray';
        document.getElementById('apiKey').style.border='1px solid lightgray';
        document.getElementById('formID').style.border='1px solid lightgray';
        document.getElementById('portalID').style.border='1px solid lightgray';

        document.getElementById('mappingFile').value="";
        this.setState({clientNameDisplay:"none",mappingFileName:'',agencyNameDisplay:'none',
        emailDisplay:'none',emailDisplay1:'none',
        apiKeyDisplay:'none',apiKeyDisplay1:'none',
        formIDDisplay:'none',formIDDisplay1:'none',
        portalIDDisplay:'none',portalIDDisplay1:'none',});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "agencyName": value }; //Rutuja 4796 console warning
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray  }); 
        
        if(this.state.ClientSetupArray[0].clientName.length>0 && this.state.ClientSetupArray[0].agencyName.length>0){
        let data={
            clientSetupDetails:this.state.ClientSetupArray
        }
        fetch("/hubspotClient/hubspotClientSetupDetails", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
            }).then(res => res.json()).then(hubspotClientSetupDetails=> { 
                if(hubspotClientSetupDetails.length>0){
                    this.setState({ ClientSetupArray:hubspotClientSetupDetails,
                        mappingFileName:hubspotClientSetupDetails[0].mappingFileName,
                        mappingFile:hubspotClientSetupDetails[0].mapping,mappingnameDisplay:'none'})
                Swal.fire({              
                    text: ("Hubspot client setup is already exists"),
                    type: 'question',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,                  
                  })
            }  
                else{
                   var ClientSetupArray=[{clientName:this.state.ClientSetupArray[0].clientName,
                    agencyName:this.state.ClientSetupArray[0].agencyName,email:"",mappingFile:null,mappingFileName:'',apiKey:'',appID:'',hubspotClientID:'',scopes:'',redirectURI:'',clientSecret:'',app:'No',api:'No'}]
                   this.setState({ ClientSetupArray:ClientSetupArray,fileName:'',mappingnameDisplay:'none'})
                   Swal.fire({              
                    text: ("Hubspot client setup not exists"),
                    type: 'question',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,                   
                  })
                }              
            }) 
        }       
    }   
    if(name==="email"){
        document.getElementById('email').style.border='1px solid lightgray';
        this.setState({emailDisplay:"none",emailDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "email": value }; //Rutuja 4796 console warning
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }  
    
    if(name==="mappingFile"){
        let files=e.target.files;
        let fileData=e.target.files[0];
        var fileName=e.target.files[0].name;
        var HTML = "<b>";
        HTML += 'Uploaded File : ' + fileName ;
        HTML += "</b>";
        document.getElementById("mappingName").innerHTML = HTML;
       
        if(files.length>0)
            {
                this.setState({mappingFile:fileData,mappingFileDisplay:'none',mappingFileDisplay1:'none',mappingFileName:"",mappingnameDisplay:'block'})
            }
        } 

        if(name==="apiKey"){
            document.getElementById('apiKey').style.border='1px solid lightgray';
            this.setState({apiKeyDisplay:"none",apiKeyDisplay1:"none"});
            let ClientSetupArray=[...this.state.ClientSetupArray]
            ClientSetupArray[0] = { ...ClientSetupArray[0], "apiKey": value }; //Rutuja 4796 console warning
            this.state.ClientSetupArray=[...ClientSetupArray];
            this.setState({ ClientSetupArray:ClientSetupArray });    
        }
        if(name==="formID"){
            document.getElementById('formID').style.border='1px solid lightgray';
            this.setState({formIDDisplay:"none",formIDDisplay1:"none"});
            let ClientSetupArray=[...this.state.ClientSetupArray]
            ClientSetupArray[0] = { ...ClientSetupArray[0], "formID": value }; //Rutuja 4796 console warning
            this.state.ClientSetupArray=[...ClientSetupArray];
            this.setState({ ClientSetupArray:ClientSetupArray });    
        }
        if(name==="portalID"){
            document.getElementById('portalID').style.border='1px solid lightgray';
            this.setState({portalIDDisplay:"none",portalIDDisplay1:"none"});
            let ClientSetupArray=[...this.state.ClientSetupArray]
            ClientSetupArray[0] = { ...ClientSetupArray[0], "portalID": value };//Rutuja 4796 console warning
            this.state.ClientSetupArray=[...ClientSetupArray];
            this.setState({ ClientSetupArray:ClientSetupArray });    
        }
}


   
validateForm(){
    let formIsvalid=true;
    const {clientName,email,agencyName,apiKey,appID,hubspotClientID,scopes,redirectURI,clientSecret} =this.state.ClientSetupArray[0];//Rutuja 4796 removed app and api as never used
    if(clientName==="" ) 
    {
        document.getElementById('clientName').style.border='1px solid red';
       this.setState({clientNameDisplay:"block"});
        formIsvalid=false;
    }
    if(agencyName==="" ) 
    {
        document.getElementById('agencyName').style.border='1px solid red';
       this.setState({agencyNameDisplay:"block"});
        formIsvalid=false;
    }
    if(email==="" )
    {
        document.getElementById('email').style.border='1px solid red';
       this.setState({emailDisplay:"block"});
        formIsvalid=false;        
    }
    if(email!=="" )
    {
        //Saurabh – Task- 3701 – Email Validation added new regular expression
        var pattern1 = new RegExp(/^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i);
            if (!pattern1.test(email)) 
            {
                document.getElementById('email').style.border='1px solid red';
                this.setState({emailDisplay1:"block"});
                formIsvalid=false;  
            }
    }
    
    if(this.state.mappingFile==="")
        {   
            if(this.state.mappingFileName==="" )
            {       
            this.setState({mappingFileDisplay:"block"});
            formIsvalid=false;    
            }
        }
       
    var allowedFiles = [".xlsx"];
    //var mappingFile1 = document.getElementById("mappingFile");
    var mappingFile1="";
    if(this.state.mappingFileName===""){
            mappingFile1= document.getElementById("mappingFile");
            var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");
                        if(this.state.mappingFile!=="" )
                        {
                            if (!regex.test(mappingFile1.value.toLowerCase()))
                            {
                                this.setState({mappingFileDisplay1:"block"});
                                formIsvalid=false;
                            }
                        }
    }else{
        mappingFile1= this.state.mappingFileName;
        let regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$"); //Rutuja 4796 canged to let
                        if(this.state.mappingFile!=="" )
                        {
                            if (!regex.test(mappingFile1.toLowerCase()))
                            {
                                this.setState({mappingFileDisplay1:"block"});
                                formIsvalid=false;
                            }
    }    
    }  
    if(this.state.app==="No" || this.state.ClientSetupArray[0].app==="No"){
    if(apiKey==="" ||apiKey===null )
    {
        document.getElementById('apiKey').style.border='1px solid red';
       this.setState({apiKeyDisplay:"block"});
        formIsvalid=false;        
    }
    
    if(apiKey!=="" )
    {
            let pattern1 = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z0-9\-\n]+$/); //Rutuja 4796 canged to let
            if (!pattern1.test(apiKey)) 
            {
                document.getElementById('apiKey').style.border='1px solid red';
                this.setState({apiKeyDisplay1:"block"});
                formIsvalid=false;  
            }
    } 
    } 
    if(this.state.app==="Yes" || this.state.ClientSetupArray[0].app==="Yes"){
    if(appID==="" || appID===null)
    {
        document.getElementById('appID').style.border='1px solid red';
       this.setState({appIDDisplay:"block",appIDDisplay1:"none"});
        formIsvalid=false;        
    }
    if(appID!=="" && appID!==null )
    {
            let  pattern1 = new RegExp(/^[0-9]+$/); //Rutuja 4796 canged to let
            if (!pattern1.test(appID)) 
            {
                document.getElementById('appID').style.border='1px solid red';
                this.setState({appIDDisplay1:"block"});
                formIsvalid=false;  
            }
    }  
    if(hubspotClientID==="" || hubspotClientID===null )
    {
        document.getElementById('hubspotClientID').style.border='1px solid red';
       this.setState({hubspotClientIDDisplay:"block"});
        formIsvalid=false;        
    }
    if(hubspotClientID!=="" )
    {
            let pattern1 = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z0-9-\n]+$/);//Rutuja 4796 canged to let
            if (!pattern1.test(hubspotClientID)) 
            {
                document.getElementById('hubspotClientID').style.border='1px solid red';
                this.setState({hubspotClientIDDisplay1:"block"});
                formIsvalid=false;  
            }
    }  
    if(scopes==="" || scopes===null )
    {
        document.getElementById('scopes').style.border='1px solid red';
       this.setState({scopesDisplay:"block"});
        formIsvalid=false;        
    }
    if(scopes!=="" )
    {
            let pattern1 = new RegExp(/^[A-Za-z]+$/); //Rutuja 4796 canged to let
            if (!pattern1.test(scopes)) 
            {
                document.getElementById('scopes').style.border='1px solid red';
                this.setState({scopesDisplay1:"block"});
                formIsvalid=false;  
            }
    }  
    if(redirectURI==="" || redirectURI===null )
    {
        document.getElementById('redirectURI').style.border='1px solid red';
       this.setState({redirectURIDisplay:"block",redirectURIDisplay1:"none"});
        formIsvalid=false;        
    }
   
    if(clientSecret==="" || clientSecret===null)
    {
        document.getElementById('clientSecret').style.border='1px solid red';
       this.setState({clientSecretDisplay:"block"});
        formIsvalid=false;
    }
    if(clientSecret!=="" )
    {      
            let pattern1 = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z0-9\-\n]+$/);//Rutuja 4796 canged to let
            if (!pattern1.test(clientSecret)) 
            {
                document.getElementById('clientSecret').style.border='1px solid red';
                this.setState({clientSecretDisplay1:"block"});
                formIsvalid=false; 
            }
    } 
    } 
    return formIsvalid;
}

//Supriya- Task 4283 - Validations for fields of set up
validateHubspotForm(){
    let formIsvalid=true;
    const {clientName,email,agencyName,apiKey,formID,portalID} =this.state.ClientSetupArray[0]; //Rutuja 4796 removed app and api as never used
    if(clientName==="" ) 
    {
        document.getElementById('clientName').style.border='1px solid red';
       this.setState({clientNameDisplay:"block"});
        formIsvalid=false;
    }
    if(agencyName==="" ) 
    {
        document.getElementById('agencyName').style.border='1px solid red';
       this.setState({agencyNameDisplay:"block"});
        formIsvalid=false;
    }
    if(email==="" )
    {
        document.getElementById('email').style.border='1px solid red';
       this.setState({emailDisplay:"block"});
        formIsvalid=false;        
    }
    if(email!=="" )
    {
        //Saurabh – Task- 3701 – Email Validation added new regular expression
        var pattern1 = new RegExp(/^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i);
            if (!pattern1.test(email)) 
            {
                document.getElementById('email').style.border='1px solid red';
                this.setState({emailDisplay1:"block"});
                formIsvalid=false;  
            }
    }
    
    if(this.state.mappingFile==="")
        {   
            if(this.state.mappingFileName==="" )
            {       
            this.setState({mappingFileDisplay:"block"});
            formIsvalid=false;    
            }
        }
       
    var allowedFiles = [".xlsx"];
    //var mappingFile1 = document.getElementById("mappingFile");
    var mappingFile1="";
    if(this.state.mappingFileName===""){
            mappingFile1= document.getElementById("mappingFile");
            var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");
                        if(this.state.mappingFile!=="" )
                        {
                            if (!regex.test(mappingFile1.value.toLowerCase()))
                            {
                                this.setState({mappingFileDisplay1:"block"});
                                formIsvalid=false;
                            }
                        }
    }else{
        mappingFile1= this.state.mappingFileName;
        let regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");//Rutuja 4796 canged to let
                        if(this.state.mappingFile!=="" )
                        {
                            if (!regex.test(mappingFile1.toLowerCase()))
                            {
                                this.setState({mappingFileDisplay1:"block"});
                                formIsvalid=false;
                            }
    }    
    }  
    if(this.state.app==="No" || this.state.ClientSetupArray[0].app==="No"){
    if(apiKey==="" ||apiKey===null )
    {
        document.getElementById('apiKey').style.border='1px solid red';
       this.setState({apiKeyDisplay:"block"});
        formIsvalid=false;        
    }
    
    if(apiKey!=="" )
    {
            let pattern1 = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z0-9\-\n]+$/); //Rutuja 4796 canged to let
            if (!pattern1.test(apiKey)) 
            {
                document.getElementById('apiKey').style.border='1px solid red';
                this.setState({apiKeyDisplay1:"block"});
                formIsvalid=false;  
            }
    } 
    } 
    if(this.state.app==="Yes" || this.state.ClientSetupArray[0].app==="Yes"){
    if(formID==="" || formID===null)
    {
        document.getElementById('formID').style.border='1px solid red';
       this.setState({formIDDisplay:"block",formIDDisplay1:"none"});
        formIsvalid=false;        
    }
    if(formID!=="" && formID!==null )
    {
        let pattern1 = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z0-9-\n]+$/); //Rutuja 4796 canged to let
            if (!pattern1.test(formID)) 
            {
                document.getElementById('formID').style.border='1px solid red';
                this.setState({formIDDisplay1:"block"});
                formIsvalid=false;  
            }
    }  
    if(portalID==="" || portalID===null )
    {
        document.getElementById('portalID').style.border='1px solid red';
       this.setState({portalIDDisplay:"block"});
        formIsvalid=false;        
    }
    if(portalID!=="" )
    {
        let pattern1 = new RegExp(/^[0-9]+$/); //Rutuja 4796 canged to let
            if (!pattern1.test(portalID)) 
            {
                document.getElementById('portalID').style.border='1px solid red';
                this.setState({portalIDDisplay1:"block"});
                formIsvalid=false;  
            }
    }  
  
    } 
    return formIsvalid;
}
handleChangeCheck(e){
    
    let fields = this.state;
   // const {name, value } = e.target; //Rutuja 4796 commented as never used
    fields[e.target.name] = e.target.value;
   //  var api="No";//Rutuja 4796 commented as never used
     var app="No";
     document.getElementById('apiKey').style.border='1px solid lightgray';
     document.getElementById('appID').style.border='1px solid lightgray';
     document.getElementById('hubspotClientID').style.border='1px solid lightgray';
     document.getElementById('scopes').style.border='1px solid lightgray';
     document.getElementById('redirectURI').style.border='1px solid lightgray';  
     this.setState({apiKeyDisplay:'none',apiKeyDisplay1:'none',
        appIDDisplay:'none',appIDDisplay1:'none',
        hubspotClientIDDisplay:'none',hubspotClientIDDisplay1:'none',
        scopesDisplay:'none',scopesDisplay1:'none',
        redirectURIDisplay:'none',redirectURIDisplay1:'none',
        });  
     if (e.target.value==="app") {
             
            if (e.target.checked===true) {
    
                app = 'Yes'
            } else {
                
            }
     }
     else {
        app = 'No';
        //api=""; //Rutuja 4796 commented as never used
    }
     if (e.target.value==="api") {             
            if (e.target.checked===false) {
    
             // api=''; //Rutuja 4796 commented as never used
              app="Yes"
            }
        }
            let ClientSetupArray=[...this.state.ClientSetupArray]
            ClientSetupArray[0] = { ...ClientSetupArray[0], "app": app }; //Rutuja 4796 console warning
            this.state.ClientSetupArray=[...ClientSetupArray];
            this.setState({ ClientSetupArray:ClientSetupArray,isChecked: !this.state.isChecked ,checkboxDisplay:'none'});
    
    }

    //Supriya- Task 4283 -For checking which app we need like through Forms/API Key
    handleChangeFormCheck(e){
    
        let fields = this.state;
        //const {name, value } = e.target; //Rutuja 4796 console warning
        fields[e.target.name] = e.target.value;
        // var api="No"; //Rutuja 4796 console warning
         var form="No";
         document.getElementById('apiKey').style.border='1px solid lightgray';
         document.getElementById('formID').style.border='1px solid lightgray';
         document.getElementById('portalID').style.border='1px solid lightgray';
         this.setState({apiKeyDisplay:'none',apiKeyDisplay1:'none',
            formIDisplay:'none',formIDDisplay1:'none',
            portalIDDisplay:'none',portalIDDisplay1:'none',
            });  
         if (e.target.value==="form") {
                 
                if (e.target.checked===true) {
        
                    form = 'Yes'
                } else {
                    
                }
         }
         else {
            form = 'No';
            //api="";//Rutuja 4796 console warning
        }
         if (e.target.value==="api") {             
                if (e.target.checked===false) {
        
                //  api='';//Rutuja 4796 console warning
                  form="Yes"
                }
            }
                let ClientSetupArray=[...this.state.ClientSetupArray]
                ClientSetupArray[0] = { ...ClientSetupArray[0], "app": form }; //Rutuja 4796 console warning
                this.state.ClientSetupArray=[...ClientSetupArray];
                this.setState({ ClientSetupArray:ClientSetupArray,isChecked: !this.state.isChecked ,checkboxDisplay:'none'});
        
        }
submitForm(e){
    e.preventDefault();
    //Supriya- Task 4283 - just foem function changed
    if(this.validateHubspotForm()=== false)
            {
            }
    else{
    var data = new FormData();
    data.append("clientSetupDetails",JSON.stringify(this.state.ClientSetupArray));
    data.append("mappingFileNameIfExist",this.state.mappingFileName);
    data.append("mappingFile",this.state.mappingFile);
    fetch("/hubspotClient/hubspotClientSetup", {
    method: "POST",
    body: data
    }).then(res => res.json()).then(response=> {
    Swal.fire({
              
        text: ("Hubspot client setup added successfully"),
        type: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        preConfirm: () => {
          window.location.reload();
        }
      })
    })
    }
}
render(){
return(
<div className="main" style={{overflowX: "hidden"}}>
        <AdminNavpage/> 
<div  style={{ paddingTop: '100px' }}>
<div class="row" >
                        <div class="col-lg-1 col-md-1 col-sm-1"  style={{float:'left',marginLeft:'40px'}}>
                        {/* //Rutuja 4796 changed href value - console warning */}
                        < a class="col" href='backtodashboard'  style={{color: '#056eb8',right:'33%',marginLeft:'80px'}}onClick={this.clientSetupBackButton.bind(this)} > 
                        <FaArrowAltCircleLeft size={32} title="" className="backFrom_salesforce"/>
                        </a>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9" align="center">
                            <label style={{fontSize:"22px",fontWeight:"bold",color:'#056eb8'}} > Hubspot Client Setup </label>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1">
                        {/* <button class="btn add-button"  type="submit" value="help"  style={{float:'right'}}>
                            <a href="#" style={{color:'white'}}>  Help</a>  
                        </button>  */}
                        </div>
                    </div>  
</div>  
{/*Supriya- Task 4283 - just common function name changed as per updated function */}
<div class="container"  style={{marginTop: '10px'}}> {/* Akash-Bug-4131*/}
            <div class="card" className="cardStep1">        
            <form method="POST" id="clientSetup" name="clientSetup">              
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>Client name</label>
                            <span className="asterisk">*</span>
                            <select id="clientName" name="clientName" value={this.state.ClientSetupArray[0].clientName}
                            class="form-control inputStyle" required onChange={this.commonhandleFormChange}>
                            <option value='' selected>Select Client</option>                          
                            {this.state.allClientNameDetails.map(clientname=>{return(
                            <option value={clientname} >{clientname}</option>
                            )})}
                            </select>
                                <span style={{display:this.state.clientNameDisplay}}className="errorMessage">
                                Please enter client name
                                </span>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>Agency name</label>
                            <span className="asterisk">*</span>
                            <select id="agencyName" name="agencyName" value={this.state.ClientSetupArray[0].agencyName}
                            class="form-control inputStyle" required onChange={this.commonhandleFormChange}
                            style={{height:'calc(2.25rem + 12px)', borderRadius:'4px',width:'180px'}}>
                            <option value='' selected>Select Agency</option>                          
                            {this.state.allAgencyNameDetails.map(agencyID=>{return(
                            <option value={agencyID} >{agencyID}</option>
                            )})}
                            </select>
                            <span style={{display:this.state.agencyNameDisplay}}className="errorMessage">
                                Please enter agency name
                                </span>
                            </div>  

                            
                            <div class="col-lg-4 col-md-6 col-sm-12">                            
                            <label className='labelStyle'>Email</label>
                            <span className="asterisk">*</span>
                            <input type="email" id="email"
                                className="form-control inputStyle"
                                name="email" required onChange={this.commonhandleFormChange}
                                value={this.state.ClientSetupArray[0].email}>
                                </input>
                                <span style={{display:this.state.emailDisplay}}className="errorMessage">
                                Please enter email
                                </span>
                                <span style={{display:this.state.emailDisplay1}}className="errorMessage">
                                Please enter valid email
                                </span>
                        </div>                        
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                            <label className='labelStyle'>Mapping file</label>
                            <span className="asterisk">*</span>
                            <input type="file" id="mappingFile" style={{color:'transparent'}}
                                name="mappingFile" required onChange={this.commonhandleFormChange}
                                defaultValue={this.state.mappingFile}>
                                </input>
                               {this.state.mappingFileName!==''? ( //Rutuja 4796 console warning
                           <a href="#/"> <span>
                            {/* Uploaded file: */}
                            
                            <b onClick={this.downloadmappingFile.bind(this)}>&nbsp;{this.state.mappingFileName}<i class="fa fa-download"></i></b></span> </a>
                            ):(
                                ""
                            )}  
                            {/* kiran-4881-added code for download mapping file */}
                            <span id={"mappingName"} className="word-wrap" style={{display:this.state.mappingnameDisplay}}>
                             </span>
                                <span style={{display:this.state.mappingFileDisplay}}className="errorMessage">
                                Please upload mapping file
                                </span>
                                <span style={{display:this.state.mappingFileDisplay1}}className="errorMessage">
                                Please upload mapping file having extensions: xlsx
                                </span>
                        </div>   
                </div>
                <hr/>
                    
                        <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                         {this.state.app==="Yes" || this.state.ClientSetupArray[0].app==="Yes" ?  
                         <input type="checkbox" id="api" name="api" value='api' 
                         onChange={this.handleChangeFormCheck} disabled />                         
                        : this.state.ClientSetupArray.length>0 && this.state.ClientSetupArray[0].app==="No" ?
                        <input type="checkbox" id="api" name="api" value='api' 
                         onChange={this.handleChangeFormCheck} checked={this.state.ClientSetupArray[0].app==="No"?"Yes":"No"}/>
                        :<input type="checkbox" id="api" name="api" value='api' 
                        onChange={this.handleChangeFormCheck}/>}
                        &nbsp;&nbsp;
                        <label className='labelStyle' >API Key Through :</label>
                        </div>
                        
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                            <label className='labelStyle'>API Key</label>
                            <span className="asterisk">*</span>
                            {this.state.app==="Yes" || this.state.ClientSetupArray[0].app==="Yes" ?
                            <input type="text" id="apiKey"
                            className="form-control inputStyle"
                            name="apiKey" required onChange={this.commonhandleFormChange}
                            value='' disabled>
                            </input>
                            :<input type="text" id="apiKey"
                                className="form-control inputStyle"
                                name="apiKey" required onChange={this.commonhandleFormChange}
                                value={this.state.ClientSetupArray[0].apiKey}>
                                </input>}
                                <span style={{display:this.state.apiKeyDisplay}}className="errorMessage">
                                Please enter API key
                                </span>
                                <span style={{display:this.state.apiKeyDisplay1}}className="errorMessage">
                                Please enter valid API key
                                </span>
                            </div>
                     </div>   

                     <hr/>
                    
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                        {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                        <input type="checkbox" id="form" name="form" value='form' 
                        onChange={this.handleChangeFormCheck} disabled />
                        : this.state.ClientSetupArray.length>0 && this.state.ClientSetupArray[0].app==="Yes" ?
                        <input type="checkbox" id="form" name="form" value='form' 
                        onChange={this.handleChangeFormCheck} checked={this.state.ClientSetupArray[0].app==="Yes"?"Yes":"No"}/>
                        :<input type="checkbox" id="form" name="form" value='form' 
                        onChange={this.handleChangeFormCheck}/>}
                        &nbsp;&nbsp;
                        {/* <label className='labelStyle' >Application Through :</label> */}
                        <label className='labelStyle' >Forms Through :</label>
                        </div>
                    </div>
                    <div class="row">
                    {/*Supriya- Task 4283 - Added form Set up field*/}
                    <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>Form ID</label>
                        <span className="asterisk">*</span>
                        {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                        <input type="text" id="formID"
                        className="form-control inputStyle"
                        name="formID" required onChange={this.commonhandleFormChange}
                        value='' disabled>
                        </input>
                        :<input type="text" id="formID"
                            className="form-control inputStyle"
                            name="formID" required onChange={this.commonhandleFormChange}
                            value={this.state.ClientSetupArray[0].formID}>
                            </input>}
                            <span style={{display:this.state.formIDDisplay}}className="errorMessage">
                            Please enter form ID
                            </span>
                            <span style={{display:this.state.formIDDisplay1}}className="errorMessage">
                            Please enter valid form ID 
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>Portal ID</label>
                        <span className="asterisk">*</span>
                        {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                       <input type="text" id="portalID"
                       className="form-control inputStyle"
                       name="portalID" required onChange={this.commonhandleFormChange}
                       value='' disabled>
                       </input>
                       :<input type="text" id="portalID"
                           className="form-control inputStyle"
                           name="portalID" required onChange={this.commonhandleFormChange}
                           value={this.state.ClientSetupArray[0].portalID}>
                           </input>}
                           <span style={{display:this.state.portalIDDisplay}}className="errorMessage">
                           Please enter portal ID
                           </span>
                           <span style={{display:this.state.portalIDDisplay1}}className="errorMessage">
                           Please enter valid portal ID
                           </span>
                        </div>
                 </div>   
                    
                    {/* <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>App ID</label>
                        <span className="asterisk">*</span>
                        {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                        <input type="text" id="appID"
                        className="form-control inputStyle"
                        name="appID" required onChange={this.commonhandleChange}
                        value='' disabled>
                        </input>
                        :<input type="text" id="appID"
                            className="form-control inputStyle"
                            name="appID" required onChange={this.commonhandleChange}
                            value={this.state.ClientSetupArray[0].appID}>
                            </input>}
                            <span style={{display:this.state.appIDDisplay}}className="errorMessage">
                            Please enter app ID
                            </span>
                            <span style={{display:this.state.appIDDisplay1}}className="errorMessage">
                            Please enter valid app ID
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>Client ID</label>
                        <span className="asterisk">*</span>
                        {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                        <input type="text" id="hubspotClientID"
                        className="form-control inputStyle"
                        name="hubspotClientID" required onChange={this.commonhandleChange}
                        value='' disabled>
                        </input>
                        :<input type="text" id="hubspotClientID"
                            className="form-control inputStyle"
                            name="hubspotClientID" required onChange={this.commonhandleChange}
                            value={this.state.ClientSetupArray[0].hubspotClientID}>
                            </input>}
                            <span style={{display:this.state.hubspotClientIDDisplay}}className="errorMessage">
                            Please enter client ID
                            </span>
                            <span style={{display:this.state.hubspotClientIDDisplay1}}className="errorMessage">
                            Please enter valid client ID
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>Scopes</label>
                        <span className="asterisk">*</span>
                        {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                            <input type="text" id="scopes"
                            className="form-control inputStyle"
                            name="scopes" required onChange={this.commonhandleChange}
                            value='' disabled>
                            </input>
                        :<input type="text" id="scopes"
                            className="form-control inputStyle"
                            name="scopes" required onChange={this.commonhandleChange}
                            value={this.state.ClientSetupArray[0].scopes}>
                            </input>}
                            <span style={{display:this.state.scopesDisplay}}className="errorMessage">
                            Please enter Scopes
                            </span>
                            <span style={{display:this.state.scopesDisplay1}}className="errorMessage">
                            Please enter valid Scopes
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>Redirect URI</label>
                        <span className="asterisk">*</span>
                        {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                        <input type="text" id="redirectURI"
                        className="form-control inputStyle"
                        name="redirectURI" required onChange={this.commonhandleChange}
                        value='' disabled>
                        </input>
                        :<input type="text" id="redirectURI"
                            className="form-control inputStyle"
                            name="redirectURI" required onChange={this.commonhandleChange}
                            value={this.state.ClientSetupArray[0].redirectURI}>
                            </input>}
                            <span style={{display:this.state.redirectURIDisplay}}className="errorMessage">
                            Please enter redirect URI
                            </span>
                            <span style={{display:this.state.redirectURIDisplay1}}className="errorMessage">
                            Please enter valid redirect URI
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>Client Secret</label>
                        <span className="asterisk">*</span>
                        {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                        <input type="text" id="clientSecret"
                        className="form-control inputStyle"
                        name="clientSecret" required onChange={this.commonhandleChange}
                        value='' disabled>
                        </input>
                        :<input type="text" id="clientSecret"
                            className="form-control inputStyle"
                            name="clientSecret" required onChange={this.commonhandleChange}
                            value={this.state.ClientSetupArray[0].clientSecret}>
                            </input>}
                            <span style={{display:this.state.clientSecretDisplay}}className="errorMessage">
                            Please enter client secret
                            </span>
                            <span style={{display:this.state.clientSecretDisplay1}}className="errorMessage">
                            Please enter valid client secret 
                            </span>
                        </div>
                 </div>   
                         */}
            </form>
            <div class="float-right" style={{marginRight:'50px'}}>
                <button className="btn add-button" value="submit" onClick={this.submitForm} 
                >Submit</button>
            </div><br/><br/>
   </div>
 </div> 
 <Footer/>
</div>
);
}
}
/**
       * @author snehal more
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      HubspotClientSetup.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })

export default connect(mapStateToProps, { logoutUser })(withRouter(HubspotClientSetup));