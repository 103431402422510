/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Sanjana Godbole
@Creation Date:02-06-2019
@Description:New UI for PublisherFrontPage(Dashboard)
*/

import React from "react";
import * as $ from "jquery";
import { Radio, Tooltip, Avatar, Checkbox, Badge, Button, Table } from "antd";
import PublisherNavigation from "../../layouts/publisherNavPage";
import "../dashboardPublisher.css";

import { css } from "emotion";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../authentication";
import { withRouter } from "react-router-dom";
import "react-chat-widget/lib/styles.css";
import { Chart } from "react-google-charts";
import CanvasJSReact from "../../../assets/canvasjs.react";
import BiddingSection from "./BiddingSection";
import { fetch_custom as fetch } from "../../../configration/fetch_default_headers"; //karan-task-3717-added default header
import Footer from '../../layouts/footer';//4440-Nilesh


var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const Swal = require("sweetalert2");
const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",
		color: "white",
		fontSize: "12px",
		fontWeight: "500",
	},
	"& thead > tr >th": {
		border: "1px solid black",
		height: "20%",
		color: "white",
	},
});

const MONTHS = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

class NewPublisherDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			biddingArray: [],
			reviewArray: [],
			pacing: "All",
			linkActive1: "active",
			linkActive2: "",
			loading1: true,
			loading2: true,
			pageSize1: 5,
			pageSize2: 5,
			monthCount: "",
			headExampleNewCampaign: "NewCampaign",
			headExampleCounter: "CounterG",
			newCampaignDetailID: [],
			errors: {},
			fields: {},
			alertDisplay: "none",
			alertBorder: "",
			tab1: "",
			pID: "",
			feedBackLength: "",
			leadsLength: "",
			newCampaignLength: "",
			counterLength: "",
			btnDisable: "btn add-button",
			userlogger: "",
			pacing: "All",
			ReviewTab: "",
			headExample2: "head-example2",
			headExample3: "head-exampleGeneral3",
			headExample4: "head-exampleGeneral4",
			headExample5: "head-exampleGeneral5",
			inputClass: "btn add-button",
			reviewTabl1: "reviewCreatives",
			tabReview: "newsLetter",
			othersLength: "",
			unreadMessages: [],
			unreadMessagesLength: 0,
			unreadAlerts: [],
			showRFPTBL: false,
			RFPLength: "",
			pieChartArray: [],
			barChartArray: [],
			byCampaign: true,
			byLead: false,
			dateDisplay: "none",
			pacing: "Monthly",
			startDate: "",
			endDate: "",
			dataExist: true,
			activeAcceptedArray: [],
			pausedQaArray: [],
			completedDeleveredArray: [],
			cancelRejectedArray: [],
			agencyList: [],
			agencyID: "All Agency",
			agencyName: "",
			selectedOption: "Campaign",
			label: "Ending This Month",
			isSubContractingCheckboxVisible: false, // User Story : 3188 : Karan Jagtap : To controll checkbox visibility
			subContractingChecked: false, // User Story : 3188 : Karan Jagtap : Checked value to send to db in 'yes'/'no' format
			pacingFromDropDown: "", //Sonali-3219-added this pacing to use in acceptCampaign function
			loadingCharts: true,
			// loading: "",
			alertShows: "" //Nilesh-5068- Added alertShows for checking alerts data
		}; // end of state
		this.openViewAllCreativesReview =
			this.openViewAllCreativesReview.bind(this); //Sandeep-task-3653-added handle change for creativesReview
		this.openViewAllLeadsReview = this.openViewAllLeadsReview.bind(this); //Sandeep-task-3653-added handle change for leadsReview
	}
	//end of constructor

	/**
	 * @author Sandeep Dhawale
	 * @param  Description handle the creatives data
	 * @return Description return to go on publisherCreativeUploads page
	 */
	openViewAllCreativesReview(e) {
		e.preventDefault();

		const { user } = this.props.auth;
		let pID = user.id;
		let campID = this.state.reviewArray[0].campID;

		let parentCampID = this.state.reviewArray[0].parentCampID;
		let reallocationID = this.state.reviewArray[0].reallocationID;
		let backKey = "Dash";
		this.props.history.push("/publisherCreativesReview", { pID, campID, parentCampID, reallocationID, backKey });
		//kiran-4173-added file on click of view all creative
	}
	/**
	 * @author Sandeep Dhawale
	 * @param  Description handle the lead data
	 * @return Description return to go on publisherInternalReviewLead page
	 */
	openViewAllLeadsReview(e) {
		e.preventDefault();
		const { user } = this.props.auth;
		let pID = user.id;

		let campID = this.state.reviewArray[0].campID;

		let parentCampID = this.state.reviewArray[0].parentCampID;
		var reallocationID = this.state.reviewArray[0].reallocationID;

		/*	window.location.href =
				"/publisherInternalReviewLead?campID=" +
				campID +
				"&parentCampID=" +
				parentCampID +
				"&reallocationID=" +
				reallocationID +
				"&pID=" +
				pID;		*/

		this.props.history.push("/publisherInternalReviewLead", {
			pID: pID,
			campID: campID,
			parentCampID: parentCampID,
			reallocationID: reallocationID
		})

	}

	// only 1 state change
	commonhandleChange = (e) => {
		const { name, value } = e.target;
		let startDate,
			endDate,
			info = {};

		const agencyID = this.state.agencyID;
		//Nilesh-4242-Removing console warnings
		if (name === "StartDate") {
			startDate = value;
			info = {
				agencyID,
				byCampaign: this.state.byCampaign,
				byPublisher: this.state.byPublisher,
				clientName: this.state.clientName,
				pacing: this.state.pacing,
				startDate,
				endDate: this.state.endDate,
			};
			this.setState({ startDate, loadingCharts: true });
		} else {
			endDate = value;
			info = {
				agencyID,
				byCampaign: this.state.byCampaign,
				byPublisher: this.state.byPublisher,
				clientName: this.state.clientName,
				pacing: this.state.pacing,
				startDate: this.state.startDate,
				endDate,
			};
			this.setState({ endDate, loadingCharts: true });
		}
		this.fetchNewPublisherDashboard(info); // karan-task-3635-replaced with a function
	};

	// only 1 state change
	dataFilter = (e) => {
		e.preventDefault();

		if (e.target.id === "byCampaign") {
			this.setState({
				byCampaign: true,
				byLead: false,
				linkActive1: "active",
				linkActive2: "",
				loadingCharts: true,
			});
			let info = {
				agencyID: this.state.agencyID,
				byCampaign: true,
				clientName: this.state.clientName,
				pacing: this.state.pacing,
				startDate: this.state.startDate,
				endDate: this.state.endDate,
			};

			this.fetchNewPublisherDashboard(info); // karan-task-3635-replaced with a function
		} else {
			this.setState({
				byCampaign: false,
				byLead: true,
				linkActive1: "",
				linkActive2: "active",
				loadingCharts: true,
			});
			let info = {
				agencyID: this.state.agencyID,
				byCampaign: false,
				clientName: this.state.clientName,
				pacing: this.state.pacing,
				startDate: this.state.startDate,
				endDate: this.state.endDate,
			};

			this.fetchNewPublisherDashboard(info); // karan-task-3635-replaced with a function
		} // end of byLead
	}; // end of dataFilter

	// only 1 state change
	handleAgencyName = (e) => {
		let agencyID = e.target.value;
		let agenName = this.state.agencyList.filter((a) => a.agencyID === Number(agencyID)); //Nilesh-4242-Removing console warnings
		let agencyName = "";
		if (agenName.length > 0) {
			agencyName = agenName[0].agencyName;
		}
		this.setState({ agencyID, agencyName, loadingCharts: true });
		let info = {
			agencyID: agencyID,
			byCampaign: this.state.byCampaign,
			clientName: this.state.clientName,
			pacing: this.state.pacing,
			startDate: this.state.startDate,
			endDate: this.state.endDate,
		};

		this.fetchNewPublisherDashboard(info); // karan-task-3635-replaced with a function
	};

	// changed to only 1 state change
	handlePacing = (e) => {
		let pacing = e.target.value;
		//Sandeep-task-2998 added code to get the label from selector  to  inside handlePacing
		let index = e.nativeEvent.target.selectedIndex;
		// let label = e.nativeEvent.target[index].text;  //Nilesh-4242-Removing console warnings

		if (pacing === "dateRange") {
			this.setState({
				dateDisplay: "block",
				startDate: "",
				endDate: "",
				pacing: pacing,
				label: e.nativeEvent.target[index].text,
			});
		} else {
			this.setState({
				dateDisplay: "none",
				pacing: pacing,
				label: e.nativeEvent.target[index].text,
				loadingCharts: true,
			});
			let info = {
				agencyID: this.state.agencyID,
				byCampaign: this.state.byCampaign,
				byLead: this.state.byLead,
				clientName: this.state.clientName,
				pacing,
				startDate: this.state.startDate,
				endDate: this.state.endDate,
			};
			this.fetchNewPublisherDashboard(info); // karan-task-3635-replaced with a function
		} // end of else
	}; // end of handlePacing

	// 0 state change
	getagencyLink = (e) => {
		e.preventDefault();
		var agencyID = e.target.id;
		//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
		this.props.history.push("/reviewLinkAgency", { agencyID: agencyID })
	};

	// only 1 state change
	handleChangePageSize1 = (e) => {
		var pageSize1 = e.target.value;
		this.setState({ pageSize1: pageSize1 });
	};

	// only 1 state change
	handleChangePageSize2 = (e) => {
		var pageSize2 = e.target.value;
		this.setState({ pageSize2: pageSize2 });
	};

	// only 1 state change
	rejectCampaign = (e) => {
		this.setState({ alertDisplay: "none" });
		e.preventDefault();
		// let spanDisplay = "none";  //Nilesh-4242-Removing console warnings
		// var allocatedLead = this.state.newCampaignDetailID[0].allocatedLead; //Nilesh-4242-Removing console warnings

		// const { isAuthenticated, user } = this.props.auth;  //Nilesh-4242-Removing console warnings

		let data = {
			allocationID: this.state.newCampaignDetailID[0].allocationID,
			reason: this.state.fields.rejectionReason,
			allocatedLead: this.state.newCampaignDetailID[0].allocatedLead,
			campaignDetail: this.state.newCampaignDetailID,
		};

		fetch("publisher/rejectCampaign", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.success === true) {
					// var a = res.message;  //Nilesh-4242-Removing console warnings
					Swal.fire({
						text: res.message,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
				} else {
					Swal.fire({
						text: "Some error occur during rejecting campaign...Please try again",
						type: "error",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// 1 sync, 1 async setState called
	handleCampaignIDOnClickAccept = (e, parentCampID, reallocationID) => {
		e.preventDefault();  //Nilesh-4242-Removing console warnings
		var allocationID = e.target.id;
		/* User Story : 3188 : Karan Jagtap : Sub-Contracting display value */
		const { agencySubcontracting, subContracting } =
			this.state.biddingArray.filter(			
				 (item) => (item.allocationID === Number(allocationID))  //Nilesh-4242-Removing console warnings
			)[0];
		if (
			agencySubcontracting === undefined ||
			agencySubcontracting === null ||
			agencySubcontracting === "undefined" ||
			agencySubcontracting === "null" ||
			agencySubcontracting === "" ||
			agencySubcontracting === "No" ||
			subContracting === undefined ||
			subContracting === null ||
			subContracting === "undefined" ||
			subContracting === "null" ||
			subContracting === "" ||
			subContracting === "No"
		) {
			this.setState({
				isSubContractingCheckboxVisible: true,
				parentCampID,
				reallocationID,
			});
		} else {
			this.setState({
				isSubContractingCheckboxVisible: false,
				parentCampID,
				reallocationID,
			});
		}
		/* End - User Story : 3188 : Karan Jagtap : Sub-Contracting display value */
		fetch("/publisher/newCampaignID?allocationID=" + allocationID + "")
			.then((res) => res.json())
			.then((newCampaignDetailID) => {
				this.setState({
					newCampaignDetailID: newCampaignDetailID,
					parentCampID: parentCampID,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	};

	// only 1 set state
	handleCampaignIDOnClickCounter = (e, parentCampID, reallocationID) => {
		e.preventDefault();  //Nilesh-4242-Removing console warnings
		var campID = e.target.id;
		this.setState({ parentCampID, reallocationID });
		fetch("publisherDashboard/dashCounterCampaign")
			.then((res) => res.json())
			.then((biddingArray) => {
				biddingArray = biddingArray.filter((item) => {
					return item.campID === Number(campID);  //Nilesh-4242-Removing console warnings
				});
				this.setState({
					showRFPTBL: false,
					biddingArray: biddingArray,
					loading1: false,
					parentCampID: parentCampID,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	};

	// 1 sync, 1 async setState called
	acceptCampaign = (e) => {
		e.preventDefault();
		this.setState({ alertDisplay: "none" });
		// let spanDisplay = "none";  //Nilesh-4242-Removing console warnings
		var allocatedLead = document.getElementById("allocatedLead").value;
		var allocationID = this.state.newCampaignDetailID[0].allocationID;
		// const { user } = this.props.auth;  //Nilesh-4242-Removing console warnings
		let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		let timestamp = new Date();
		let data = {
			allocationID: allocationID,
			allocatedLead: allocatedLead,
			campaignDetail: [
				{
					...this.state.newCampaignDetailID[0],
					// add subContracting:'Yes'/'No' here
					subContracting: this.state.subContractingChecked ? "Yes" : "No",
					// Old Comment - Here it is directly 'Yes' because unless and until it is checked the campaign will not be accepted, hence default value is set
					// As per sonali, the checkbox checked value should be registered and not subContracting value
				},
			],
			timestamp,
			timezone,
		};
		fetch("publisher/acceptNewCampaign", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.success === true) { //Nilesh-4242-Removing console warnings
					Swal.fire({
						text: res.message,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							var tab1 = "NewCampaign";
							fetch(
								"publisherDashboard/pubDashNewCampaign?pacing=" +
								this.state.pacingFromDropDown
							)
								.then((res) => res.json())
								.then((biddingArray) => {
									this.setState({
										biddingArray: biddingArray,
										loading1: false,
										tab1: tab1,
										headExampleNewCampaign: "NewCampaign",
										headExampleCounter: "CounterG",
										headExampleRFP: "RFPG",
										newCampaignLength: biddingArray.length,
										subContractingChecked: false, // User Story : 3188 : Karan Jagtap - added this due to 3219 update
									});
								})
								.catch(function (err) {
									console.log(err);
								});
							// window.location.reload(); //shivani-task 3219-changed function call .
							// this.handleAcceptCamp();
						},
					});
				} else {
					this.setState({
						subContractingChecked: false, // User Story : 3188 : Karan Jagtap - added this due to 3219 update
					});
					Swal.fire({
						text: "Some error occur during accepting campaign...Please try again",
						type: "error",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//Aman - 4595 - (created New function for Accept all Campaign)PC- Accept Campaign - Select box /Select All option required
	/**
	* @author Aman Shaikh
	* @param  Description handle the All Accept Campaign from publisher 
	* @return Description return - Accept All Campaign At a time from PC side 
	*/
	acceptAllCampaign = (e, biddingArray) => {
		e.preventDefault();
		this.setState({ alertDisplay: "none" });
		// let spanDisplay = "none";  //Nilesh-4242-Removing console warnings
		biddingArray = this.state.biddingArray;
		// const { user } = this.props.auth;  //Nilesh-4242-Removing console warnings
		let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		let timestamp = new Date();
		let data = {
			biddingArray,
			campaignDetail: [
				{
					...this.state.newCampaignDetailID[0],
					// Old comment for ref - add subContracting:'Yes'/'No' here
					subContracting: this.state.subContractingChecked ? "Yes" : "No",
					// Old comment for ref - Here it is directly 'Yes' because unless and until it is checked the campaign will not be accepted, hence default value is set
					// Old comment for ref - As per sonali, the checkbox checked value should be registered and not subContracting value
				},
			],
			timestamp,
			timezone,
		};
		Swal.fire({
			onOpen: () => {
				Swal.fire({
					allowEscapeKey: false,
					allowOutsideClick: false,
					timer: 2000,
					onOpen: () => {
						Swal.showLoading();
					}
				})
				fetch(`publisher/acceptAllNewCampaign`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						if (res.success === true) {
							Swal.fire({
								text: "All Campaign accepted successfully and Insertion Order document generated",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									var tab1 = "NewCampaign";
									fetch(
										"publisherDashboard/pubDashNewCampaign?pacing=" +
										this.state.pacingFromDropDown
									)
										.then((res) => res.json())
										.then((biddingArray) => {
											this.setState({
												biddingArray: biddingArray,
												loading1: false,
												tab1: tab1,
												headExampleNewCampaign: "NewCampaign",
												headExampleCounter: "CounterG",
												headExampleRFP: "RFPG",
												newCampaignLength: biddingArray.length,
												subContractingChecked: false, // Old comment for ref - User Story : 3188 : Karan Jagtap - added this due to 3219 update
											});
										})
										.catch(function (err) {
											console.log(err);
										});
								},
							});
						} else {
							this.setState({
								subContractingChecked: false, //Old comment for ref - User Story : 3188 : Karan Jagtap - added this due to 3219 update
							});
							Swal.fire({
								text: "Some error occur during accepting campaign...Please try again",
								type: "error",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
		});
	};

	// 0 state change
	handleRefreshPage = (e) => {
		var pID =
			window.location.href.split("=")[
			window.location.href.split("?").length - 1
			];
		window.location.href = "/dashboardPublisher?pID=" + pID + "";
		window.location.reload();
	}; //end of handle refresh page

	// 1 state changed
	handleChange = (e) => {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
	};

	// 1 sync, 1 async setState called
	submitCounterProposal = (e) => {
		e.preventDefault();

		/**
		 * @author Narendra Phadke
		 * @param  Description handle the publisher Login Details
		 * @return Description return All publisher Details
		 */
		// const { isAuthenticated, user } = this.props.auth;  //Nilesh-4242-Removing console warnings
		// var pID = user.id;  //Nilesh-4242-Removing console warnings
		let data = {
			allocationID: this.state.newCampaignDetailID[0].allocationID,
			proposedLead: this.state.fields.proposedLead,
			proposedCPL: this.state.fields.proposedCPL,
			campaignDetail: this.state.newCampaignDetailID,
		};

		if (this.validateForm()) {
			this.setState({ alertDisplay: "none", alertBorder: "0px solid white" });

			fetch("publisherDashboard/counterCampaignNew", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.success === true) {
						this.setState({
							inputClass: "disabled",
						});

						Swal.fire({
							text: res.message,
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								window.location.reload();
							},
						});
					} else {
						Swal.fire({
							text: "Some error occur during countering campaign...Please try again",
							type: "error",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								window.location.reload();
							},
						});
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} //end of if
	}; //submitcounter()

	// only 1 state changed
	validateForm() {
		let fields = this.state.fields;
		var proposedLead = this.state.fields.proposedLead;
		var allocatedLead = this.state.newCampaignDetailID[0].allocatedLead;
		var oldCPL = this.state.newCampaignDetailID[0].CPL;
		let errors = {};
		let formIsValid = true;
		let borderFlag = false;

		if (!fields["proposedLead"]) {
			formIsValid = false;
			errors["proposedLead"] = <li>Please enter proposed lead</li>;
		}

		//regular expression for entering only numbers validation
		if (typeof fields["proposedLead"] !== "undefined") {
			var pattern = new RegExp(/^[1-9]+[0-9]*$/); //numbers except zero

			if (!pattern.test(fields["proposedLead"])) {
				formIsValid = false;
				errors["proposedLead"] = (
					<li>Please enter proposed lead only in numeric </li>
				);
			}
		}

		if (!fields["proposedCPL"]) {
			formIsValid = false;
			errors["proposedCPL"] = <li>Please enter proposed CPL</li>;
		}

		//regular expression for entering  numbers  and decimal only validation
		if (typeof fields["proposedCPL"] !== "undefined") {
		   pattern = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);  //Nilesh-4242-Removing console warnings
			
			if (
				!pattern.test(fields["proposedCPL"]) ||
				fields["proposedCPL"] === "0"    //Nilesh-4242-Removing console warnings
			) {
				formIsValid = false;
				errors["proposedCPL"] = "Please check Proposed CPL value";
				errors["proposedCPL1"] = <li>CPL should be only in numeric.</li>;
				errors["proposedCPL2"] = <li>CPL should be greater than zero.</li>;
				errors["proposedCPL3"] = (
					<li>CPL value allowed only 2 decimal places.</li>
				);
			}
		}
		if (proposedLead > allocatedLead) {
			formIsValid = false;
			errors["GraterProposedLead"] = (
				<li>Proposed leads cannot be greater than allocated leads</li>
			);
			borderFlag = true;
		}
		// alert("proposedLead" + typeof(proposedLead))
		// alert("allocatedLead" + typeof(allocatedLead))
		// alert("oldCPL" + typeof(oldCPL))
		// alert("fields.proposedCPL" + typeof(fields.proposedCPL))
		if (proposedLead == allocatedLead && oldCPL == fields.proposedCPL) {
			formIsValid = false;
			errors["equalsToCPL"] = (
				<li>Proposed CPL cannot be equals to allocated CPL</li>
			);
		}
		if (fields.proposedCPL < oldCPL) {
			formIsValid = false;
			errors["lessProposedCPL"] = (
				<li>Proposed CPL cannot be less than allocated CPL</li>
			);
		}

		if (borderFlag) {
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertDisplay: "block",
				border: "1px solid red",
			});
		} else {
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertDisplay: "block",
			});
		}
		return formIsValid;
	}

	// only 1 state changed
	counter = (e, record) => {
		e.preventDefault(); //Nilesh-4242-Removing console warnings
		var allocationID = record.allocationID; //karan-task-3686-changed id
		var campID = record.campID;

		fetch(
			"/publisherDashboard/counterDetails?allocationID=" + allocationID + ""
		)
			.then((res) => res.json())
			.then((newCampaignDetailID) => {
				this.setState({
					newCampaignDetailID: newCampaignDetailID,
					campID: campID,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	};

	// 0 state change
	displayCampaignSpecification = (e, record) => {
		e.preventDefault();
		var campID = record.campID;
		var cancelTab = record.cancelTab;
		var parentCampID = record.parentCampID;
		var reallocationID = record.reallocationID;
		var status = record.status;
		if (cancelTab === "Cancel") {
			window.location.href =
				"/campaignSpecificationPublisherDashboard?campID=" +
				campID +
				"&parentCampID=" +
				parentCampID +
				"&reallocationID=" +
				reallocationID +
				"&key=" +
				status +
				"&cancelKey=" +
				cancelTab;
		} else {
			//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
			this.props.history.push("/campaignSpecificationPublisherDashboard", { campID: campID, parentCampID: parentCampID, reallocationID: reallocationID, key: status })

		}
	};

	// 0 state change
	displayCampaignSpecificationMsg = (e, undreadMessages) => {
		e.preventDefault();
		//alert("displayCampaignSpecificationMsg")
		var campID = undreadMessages.campID;
		var parentCampID = undreadMessages.parentCampID;
		var reallocationID = undreadMessages.reallocationId;
		var type = undreadMessages.campaignType; //Sonali-3216-get campaign type
		//{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(added a key as dashboardMsg) */}
		//Sonali 3216-putting below if condition in comment
		if (type === "RFP") {
			//alert("page3")//Somnath Task-3935, Redirect URL
			this.props.history.push("/publisherRfpCampaignSpecification", { proposalID: campID, key: "dashboardMsg" })
			// window.location.href =
			// 	"/publisherRfpCampaignSpecification?proposalID=" +
			// 	campID +
			// 	"&key=" +
			// 	"dashboardMsg";
		} else {
            //alert("else part ----->")
			//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
			this.props.history.push("/campaignSpecificationPublisherDashboard", { campID: campID, parentCampID: parentCampID, reallocationID: reallocationID, key: "dashboardMsg" })

			// window.location.href =
			// 	"/campaignSpecificationPublisherDashboard?campID=" +
			// 	campID +
			// 	"&parentCampID=" +
			// 	parentCampID +
			// 	"&reallocationID=" +
			// 	reallocationID +
			// 	"&key=" +
			// 	"dashboardMsg";
		}
	};

	// 0 state change
	//Sandeep-task-3710-Pat/local--publisher side-Dashboard--unread alerts--page issue
	displayCampaignSpecificationAlerts = (e, unreadAlerts) => {
		e.preventDefault();
		var campID = unreadAlerts.campID;
		var alertID = unreadAlerts.alertID;
		// const { user } = this.props.auth;  //Nilesh-4242-Removing console warnings
		let data = { alertID: alertID, clickedcampaignID: campID };

		fetch("/notification/AgencyAlertsToAgecncyStatusChanged", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.catch(function (err) {
				console.log(err);
			});

		e.preventDefault();
		//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
		this.props.history.push("/publisherUnreadAlerts", { campID: campID })

	};

	// 0 state change
	uploadLeds = (e) => {
		var campID = e.target.getAttribute("id");
		var parentCampID = e.target.getAttribute("parentcampid");
		var reallocationID = e.target.getAttribute("reallocationid");
		window.location.href =
			"/publisherDeliverLead?campID=" +
			campID +
			"&parentCampID=" +
			parentCampID +
			"&reallocationID=" +
			reallocationID;
	}; // end of uploadLeds

	// 1 sync, 1 async setState called
	leadLink = (e) => {
		//getting Campaign ID which PNC upload lead
		// const { user } = this.props.auth;  //Nilesh-4242-Removing console warnings
		// var pID = user.id;  //Nilesh-4242-Removing console warnings
		var ReviewTab = "ReviewLeads";
		var tabReview = e.target.id;

		this.setState({
			tabReview: tabReview,
			reviewTabl1: "reviewCreatives",
			loading2: true,
			ReviewTab: ReviewTab,
			headExample3: "head-example3",
			headExample2: "head-exampleGeneral2",
			headExample5: "head-exampleGeneral5",
			headExample4: "head-exampleGeneral4",
		});
		fetch("publisherDashboard/leadReviewDashboard")
			.then((res) => res.json())
			.then((leadReviewDashboard) => {
				this.setState({
					reviewArray: leadReviewDashboard,
					leadsLength: leadReviewDashboard.length,
					loading2: false,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	};

	// 1 sync, 1 async setState called
	linkOthers = (e) => {
		var tabReview = e.target.id;
		var ReviewTab = "ReviewCreatives";
		this.setState({
			tabReview: tabReview,
			reviewTabl1: "invoiceTab",
			ReviewTab: ReviewTab,
			headExample2: "head-exampleGeneral2",
			headExample3: "head-exampleGeneral3",
			headExample4: "head-exampleGeneral4",
			headExample5: "head-example5",
			loading2: true,
		});

		if (e.target.id === "others") {
			fetch("/publisher/agencyLinkReview", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((agencyLinkReview) => {
					this.setState({
						reviewArray: agencyLinkReview,
						othersLength: agencyLinkReview.length,
						loading2: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	};

	// 1 sync, 1 async setState called
	invoiceLink = (e) => {
		const { user } = this.props.auth;
		var ReviewTab = "ReviewInvoice";
		this.setState({
			pID: user.id, //karan-task-3717-removed body param and added user token
			userlogger: user.role,
			reviewTabl1: "invoiceTab",
			tabReview: "invoice",
			ReviewTab: ReviewTab,
			headExample4: "head-example4",
			headExample3: "head-exampleGeneral3",
			headExample2: "head-exampleGeneral2",
			headExample5: "head-exampleGeneral5",
			loading2: true,
		});
		// this.setState({ reviewTabl1: "invoiceTab" });
		//getting Campaign ID which PNC upload lead
		fetch("/publisherDashboard/getMonthList", {
			//insert data in publisher_assignment table
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((monthArray) => {
				this.setState({
					monthCount: monthArray.length,
					reviewArray: monthArray,
					loading2: false,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	};

	// 1 sync, 1 async setState called
	creativeLink = (e) => {
		e.preventDefault();
		var ReviewTab = "ReviewCreatives";
        //Nilesh-4242-Removing console warnings
		// var pacing = "All"; // pacing from dromdown end this month-Monthly, end this weekly-Weekly & All

		var tabReview = e.target.id;

		this.setState({
			tabReview: tabReview,
			reviewTabl1: "reviewCreatives",
			ReviewTab: ReviewTab,
			loading2: true,
			headExample4: "head-exampleGeneral4",
			headExample5: "head-exampleGeneral5",
			headExample2: "head-example2",
			headExample3: "head-exampleGeneral3",
		});
		//Nilesh-4242-Removing console warnings
		// var pacing = "All"; // pacing from dropdown end this month-Monthly, end this weekly-Weekly & All
		// const { isAuthenticated, user } = this.props.auth;  //Nilesh-4242-Removing console warnings
		// var agencyID = user.id;  //Nilesh-4242-Removing console warnings

		if (e.target.id === "landingPage") {
			// Getting Publisher LP Campaign Details
			fetch("publisherDashboard/lpInCreative")
				.then((res) => res.json())
				.then((reviewArray) => {
					this.setState({
						reviewArray: reviewArray,
						ReviewTab: ReviewTab,
						loading2: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "callScript") {
			// Getting Publisher callScript Campaign Details
			fetch("publisherDashboard/callScriptInCreative")
				.then((res) => res.json())
				.then((reviewArray) => {
					this.setState({
						reviewArray: reviewArray,
						loading2: false,
						headExample2: "head-example2",
						headExample3: "head-exampleGeneral3",
						ReviewTab: ReviewTab,
						headExample4: "head-exampleGeneral4",
						headExample5: "head-exampleGeneral5",
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			// Getting Publisher NewsLetter Campaign Details
			fetch("publisherDashboard/newsLetterInCreative")
				.then((res) => res.json())
				.then((reviewArray) => {
					this.setState({
						reviewArray: reviewArray,
						loading2: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}; // end of creativeLink

	/**
	 * @author Shivani pathak
	 * @param  Description task 2919 - After click on OK table data should reload .
	 * @return Description – task 2919-After click on OK table data should reload with countered campaigns .
	 */
	// only 1 state changed
	reloadtable = (e) => {
		e.preventDefault();
		var tab1 = "Counter";
		fetch("publisherDashboard/dashCounterCampaign")
			.then((res) => res.json())
			.then((biddingArray) => {
				this.setState({
					showRFPTBL: false,
					biddingArray: biddingArray,
					counterLength: biddingArray.length,
					headExampleNewCampaign: "NewCampaignG",
					headExampleCounter: "Counter",
					headExampleRFP: "RFPG",
					loading1: false,
					tab1: tab1,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	}; // end of reloadtable

	// only 1 state changed
	biddingLink = (e) => {
		e.preventDefault();
		var pacing = "All"; // pacing from dromdown end this month-Monthly, end this weekly-Weekly & All
		// const { isAuthenticated, user } = this.props.auth;  //Nilesh-4242-Removing console warnings
		// var agencyID = user.id;  //Nilesh-4242-Removing console warnings
		if (e.target.id === "Counter") {
			var tab1 = "Counter";
			// Getting Publisher Counter Campaign Details
			fetch("publisherDashboard/dashCounterCampaign")
				.then((res) => res.json())
				.then((biddingArray) => {
					// alert("########### counterCampaign "+JSON.stringify(biddingArray))
					this.setState({
						showRFPTBL: false,
						biddingArray: biddingArray,
						counterLength: biddingArray.length,
						headExampleNewCampaign: "NewCampaignG",
						headExampleCounter: "Counter",
						headExampleRFP: "RFPG",
						loading1: false,
						tab1: tab1,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "RFP") {
			 tab1 = "RFP";  //Nilesh-4242-Removing console warnings
			// Getting Publisher RFP Campaign Details
			fetch("publisherDashboard/getRFPAllocation")
				.then((res) => res.json())
				.then((biddingArray) => {
					// alert("########### RFPCampaign "+JSON.stringify(biddingArray))
					this.setState({
						showRFPTBL: true,
						biddingArray: biddingArray,
						RFPLength: biddingArray.length,
						headExampleNewCampaign: "NewCampaignG",
						headExampleCounter: "CounterG",
						headExampleRFP: "RFP",
						loading1: false,
						tab1: tab1,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			tab1 = "NewCampaign"; //Nilesh-4242-Removing console warnings

			// Getting Publisher New Campaign Details
			fetch("publisherDashboard/pubDashNewCampaign?pacing=" + pacing)
				.then((res) => res.json())
				.then((biddingArray) => {
					this.setState({
						showRFPTBL: false,
						biddingArray: biddingArray, //check this value, 'Yes/No/null/undefined'
						newCampaignLength: biddingArray.length,
						headExampleNewCampaign: "NewCampaign",
						headExampleCounter: "CounterG",
						headExampleRFP: "RFPG",
						loading1: false,
						tab1: tab1,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}; //end of biddingLink

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the lead
	 * @return Description return to go on lead review page
	 */
	// 0 state change
	leadReview = (e, record) => {
		e.preventDefault();
		const { user } = this.props.auth;
		var pID = user.id;

		var campID = record.campID;

		var parentCampID = record.parentCampID;
		var reallocationID = record.reallocationID;


		//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
		this.props.history.push("/publisherInternalReviewLead", { campID: campID, parentCampID: parentCampID, reallocationID: reallocationID, pID: pID })

	};

	/**
	 * @author : Karan Jagtap
	 * @description : task-3635-as this api is called 7 time, hence converted into function
	 * @route : /publisherDashboard/newPublisherDashboard
	 */
	// only 1 state changed
	fetchNewPublisherDashboard = (info) => {
		fetch("/publisherDashboard/newPublisherDashboard", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(info),
		})
			.then((res) => res.json())
			.then((chartInfo) => {
				let barchart = chartInfo[1];
				let activeAcceptedArray = barchart[0];
				let completedDeleveredArray = barchart[1];
				let pausedQaArray = barchart[2];
				let cancelRejectedArray = barchart[3];
				let active = activeAcceptedArray.reduce(function (total, arr) {
					return total + arr.y;
				}, 0);
				let completed = completedDeleveredArray.reduce(function (total, arr) {
					return total + arr.y;
				}, 0);
				let paused = pausedQaArray.reduce(function (total, arr) {
					return total + arr.y;
				}, 0);
				let cancel = cancelRejectedArray.reduce(function (total, arr) {
					return total + arr.y;
				}, 0);
				let total = active + completed + paused + cancel;
				let dataExist = true;
				if (total === 0) {
					dataExist = false;
				}
				this.setState({
					pieChartArray: chartInfo[0],
					barChartArray: chartInfo[1],
					activeAcceptedArray,
					completedDeleveredArray,
					pausedQaArray,
					cancelRejectedArray,
					dataExist,
					loadingCharts: false,
				});
			})
			.catch(function (err) {
				console.log(err);
				this.setState({ loadingCharts: false });
			});
	};

	// 1 sync, 7 async state changes
	// changed from will mount to did mount
	componentDidMount() {
		//Dashboard data
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			//Nilesh-5068- Added loader
			this.setState({
				loading2: true,
			   });
			//  {/* Sandeep-task-3221-added glyphicon-arrow-down and glyphicon-arrow-up*/}
			$(document).on("click", ".panel-heading span.icon_minim", function (e) {
				var $this = $(this);
				if (!$this.hasClass("panel-collapsed")) {
					$this.parents(".panel").find(".panel-body").slideUp();
					$this.addClass("panel-collapsed");
					$this
						.removeClass("glyphicon-arrow-down")
						.addClass("glyphicon-arrow-up");
				} else {
					$this.parents(".panel").find(".panel-body").slideDown();
					$this.removeClass("panel-collapsed");
					$this
						.removeClass("glyphicon-arrow-up")
						.addClass("glyphicon-arrow-down");
				}
			});
			$(document).on("focus", ".panel-footer input.chat_input", function (e) {
				var $this = $(this);
				if ($("#minim_chat_window").hasClass("panel-collapsed")) {
					$this.parents(".panel").find(".panel-body").slideDown();
					$("#minim_chat_window").removeClass("panel-collapsed");
					$("#minim_chat_window")
						.removeClass("glyphicon-arrow-up")
						.addClass("glyphicon-arrow-down");
				}
			});
			$(document).on("click", "#new_chat", function (e) {
				var size = $(".chat-window:last-child").css("margin-left");
				var size_total = parseInt(size) + 400;
				//  alert(size_total);
				var clone = $("#chat_window_1").clone().appendTo(".container");
				clone.css("margin-left", size_total);
			});
			$(document).on("click", ".icon_close", function (e) {
				//$(this).parent().parent().parent().parent().remove();
				$("#chatbox").hide();
			});

			const { user } = this.props.auth;
			//Nilesh-4242-Removing console warnings
			// const token = user.token; //shivani-task 2993- added code to declare token and using this token to authenticate the api.
			var pacing = this.state.pacing; // pacing from dromdown end this month-Monthly, end this weekly-Weekly & All
			// this.setState({ pacingFromDropDown: pacing }); //sonali-3219-added this pacing for acceptCampaign function
			this.setState({
				pID: user.id, //karan-task-3717-removed body param and added user token
				userlogger: user.role,
				pacingFromDropDown: pacing,
			}); //shivani-task 2993- added code to setState the token to increase api security.

			// Getting Publisher Campaign Count of All Tab
			fetch("/publisherDashboard/getMonthList", {
				//insert data in publisher_assignment table
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((monthArray) => {
					this.setState({ monthCount: monthArray.length });
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("/publisher/agencyLinkReview", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((agencyLinkReview) => {
					this.setState({ othersLength: agencyLinkReview.length });
				})
				.catch(function (err) {
					console.log(err);
				});

			var tab1 = "NewCampaign";
			fetch("publisherDashboard/pubDashNewCampaign?pacing=" + pacing)
				.then((res) => res.json())
				.then((biddingArray) => {
					this.setState({
						biddingArray: biddingArray,
						loading1: false,
						tab1: tab1,
						headExampleNewCampaign: "NewCampaign",
						headExampleCounter: "CounterG",
						headExampleRFP: "RFPG",
						newCampaignLength: biddingArray.length,
					});
				})
				.catch(function (err) {
					console.log(err);
				});

			// Get RFP Bidding Campaign Details.
			fetch("publisherDashboard/getRFPAllocation")
				.then((res) => res.json())
				.then((biddingArray) => {
					this.setState({ RFPLength: biddingArray.length });
				})
				.catch(function (err) {
					console.log(err);
				});

			// Getting Publisher Counter Campaign Details
			fetch("publisherDashboard/dashCounterCampaign")
				.then((res) => res.json())
				.then((counterCampaign) => {
					this.setState({ counterLength: counterCampaign.length });
				})
				.catch(function (err) {
					console.log(err);
				});

			var ReviewTab = "ReviewCreatives";
			// Getting Publisher NewsLetter Campaign Details (Common for all-Newsletter,CallScript and Landing Page)
			fetch("publisherDashboard/newsLetterInCreative")
				.then((res) => res.json())
				.then((reviewArray) => {
					this.setState({
						reviewArray: reviewArray,
						loading2: false,
						feedBackLength: reviewArray.length,
						ReviewTab: ReviewTab,
						headExample2: "head-example2",
						headExample3: "head-exampleGeneral3",
						headExample4: "head-exampleGeneral4",
					});
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("publisherDashboard/leadReviewDashboard")
				.then((res) => res.json())
				.then((leadReviewDashboard) => {
					this.setState({ leadsLength: leadReviewDashboard.length });
				})
				.catch(function (err) {
					console.log(err);
				});

			// var userID = user.userID;  //Nilesh-4242-Removing console warnings
			// var firstName = user.firstName;  //Nilesh-4242-Removing console warnings
			// var lastName = user.lastName;  //Nilesh-4242-Removing console warnings
			fetch("/publisherDashboard/publisherCompleteOnEndDate")
				.then((res) => res.json())
				.catch(function (err) {
					console.log(err);
				});

			fetch("/publisher/updateLeadsPermissionTimeFlag", {
				//insert data in publisher_assignment table
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((updateLeadsPermissionTimeFlag) => { })
				.catch(function (err) {
					console.log(err);
				});
		}

		/* @author Narendra Phadke
		 * @param  Description Unread message on dashboard
		 * @return Description get all unread message
		 */
		// const { user } = this.props.auth;  //Nilesh-4242-Removing console warnings
		// var pID = user.id;  //Nilesh-4242-Removing console warnings
		//alert("agencyID==>"+agencyID);
		let dataForUnread = {
			page: "Dashboard", //Somnath Task-3639, Add page key
		};
		fetch("/notification/publisherDashboardUnreadMessages", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(dataForUnread),
		})
			.then((res) => res.json())
			.then((unreadMessages) => {
				this.setState({
					unreadMessages: unreadMessages,
					unreadMessagesLength: unreadMessages.length,
				});
			})
			.catch(function (err) {
				console.log(err);
			});

		fetch("/notification/publisherDashboardUnreadAlerts", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(dataForUnread),
		})
			.then((res) => res.json())
			.then((unreadAlerts) => {
                 this.setState({
					alertShows: true
				 })
				//  alert("loading --------->" + JSON.stringify(this.state.loading))
				//sandeep-task-2998 added code to split date from datetime
				for (var i = 0; i < unreadAlerts.length; i++) {
					var datetime = unreadAlerts[i].datetime; //extract datetime form unreadAlerts
					var arr1 = datetime.split(" ");
					var arr2 = arr1[0].split("-");
					var year = arr2[0];
					var yr = year.slice(2, 4);
					var mnth = arr2[1];
					var dte = arr2[2];
					const d = new Date(mnth);
					datetime = dte + "-" + MONTHS[d.getMonth()] + "-" + yr; //Nilesh-4242-Removing console warnings
					unreadAlerts[i].datetime = datetime; //added datetime in unreadAlerts array
				}

				if (unreadAlerts && unreadAlerts.length > 0) {
					var value;
					let unreadAlertsTemp = [...unreadAlerts];
					for (let i = 0; i < unreadAlerts.length; i++) {  //Nilesh-4242-Removing console warnings
						value = unreadAlerts[i].alertID;
						unreadAlertsTemp.push({ ...unreadAlerts[i], key: value });
					}
					this.setState({ unreadAlerts: unreadAlertsTemp });
				} else {
					this.setState({
						unreadAlerts: unreadAlerts,
					});
				}
			})
			.catch(function (err) {
				console.log(err);
			});

		let info = {
			byCampaign: this.state.byCampaign,
			clientName: this.state.clientName,
			pacing: this.state.pacing,
			startDate: this.state.startDate,
			endDate: this.state.endDate,
		};

		this.fetchNewPublisherDashboard(info); // karan-task-3635-replaced with a function

		fetch("publisherDashboard/selectAgency")
			.then((res) => res.json())
			.then((agencyList) =>
				this.setState({ agencyList: agencyList }, function () { })
			)
			.catch(function (err) {
				console.log(err);
			});
	} // end of componentDidMount

	// 0 state changes
	uploadCreative = (e, record) => {
		e.preventDefault();
		// var pID = this.props.auth.user.id;  //Nilesh-4242-Removing console warnings
		var campID = e.target.id;
		//Sonali-4138-adding parentCampaign ID
		var parentCampID = record.parentCampID;
		//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
		this.props.history.push("/publisherCreativesReview", { campID: campID, pID: this.props.auth.user.id, parentCampID: parentCampID })
	};
	//kiran-4173-added publisherCreativesReview on click of campaign id

	// 0 state changes
	getInvoiceLink = (e, month, year) => {
		e.preventDefault();
		//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
		this.props.history.push("/publisherInvoice", { month: month, year: year })


	};

	// 0 state changes
	RFPReview = (e) => {
		e.preventDefault();
		var campID = e.target.id;
		//window.location.href = "/requestForProposalPublisherNew?campID=" + campID;
		//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
		this.props.history.push("/requestForProposalPublisherNew", { campID: campID, pID: this.props.auth.user.id })


	};

	/**
	 * @author Supriya Gore
	 * @param  Description  handle mark all read and clear alerts
	 * @return Description return successfully response with updated alerts
	 */
	// only 1 state changed
	handleMarkAllReadAndClear = (e) => {
		e.preventDefault();  //Nilesh-4242-Removing console warnings
		// const { user } = this.props.auth;  //Nilesh-4242-Removing console warnings
		let data = {
			unreadAlerts: this.state.unreadAlerts,
		};

		fetch("/notification/markAllReadAndClear", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((markAllReadAndClear) => {
				if (markAllReadAndClear.affectedRows > 0) {
					let dataForUnread = {
						page: "Dashboard", //Somnath Task-3639, Add page key
					};

					fetch("/notification/publisherDashboardUnreadAlerts", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(dataForUnread),
					})
						.then((res) => res.json())
						.then((unreadAlerts) => {
							for (var i = 0; i < unreadAlerts.length; i++) {
								var datetime = unreadAlerts[i].datetime;
								var arr1 = datetime.split(" ");
								var arr2 = arr1[0].split("-");
								var year = arr2[0];
								var yr = year.slice(2, 4);
								var mnth = arr2[1];
								var dte = arr2[2];
								const d = new Date(mnth);
								datetime = dte + "-" + MONTHS[d.getMonth()] + "-" + yr;  //Nilesh-4242-Removing console warnings
								unreadAlerts[i].datetime = datetime; //added datetime in unreadAlerts array
							}

							if (unreadAlerts && unreadAlerts.length > 0) {
								// var name = "key";  //Nilesh-4242-Removing console warnings
								var value;
								let unreadAlertsTemp = [...unreadAlerts];
								for (let i = 0; i < unreadAlerts.length; i++) {  //Nilesh-4242-Removing console warnings
									value = unreadAlerts[i].alertID;
									unreadAlertsTemp.push({ ...unreadAlerts[i], key: value });
								}
								this.setState({ unreadAlerts: unreadAlertsTemp });
							} else {
								this.setState({
									unreadAlerts: unreadAlerts,
								});
							}
						})
						.catch(function (err) {
							console.log(err);
						});
				}
			});
	};

	/**
	 * User Story : 3188
	 * @author: Karan Jagtap
	 * @description: on change handler for sub-contracting radio buttons
	 */
	// only 1 state changed
	onChangeSubContractingCheckbox = (e) => {
		this.setState({
			subContractingChecked: e.target.checked,
		});
	};

	render() {
		const asterisk = {
			color: "red",
		};

		const { user } = this.props.auth;

		const columns3 = [
			{
				title: "Agency Name",
				dataIndex: "agencyName",
				key: "agencyName",
				width: "30%",
				//  ...this.getColumnSearchProps('campID'),
				align: "center",
				sorter: (a, b) => a.agencyName - b.agencyName,
				render: (text, record) => <div>{record.agencyName}</div>,
			},
			{
				title: "Review ",
				dataIndex: "reviewDetails",
				key: "reviewDetails",
				width: "30%",
				//  ...this.getColumnSearchProps('campID'),
				align: "center",
				sorter: (a, b) => a.reviewDetails - b.reviewDetails,
				render: (text, record) => (
					<div>
						<a href="#/"  //Nilesh-4242-Removing console warnings
						 id={record.agencyID} onClick={this.getagencyLink}>
							Click here
						</a>
					</div>
				),
			},
		];

		const invoiceData = [
			{
				title: "ID",
				dataIndex: "id",
				key: "id",
				width: "10.8%",
				align: "right",
				sorter: (a, b) => a.id - b.id,
				render: (text, record) => (
					<div>
						<span>{record.id}</span>
					</div>
				),
			},
			{
				title: "Months",
				dataIndex: "month",
				key: "month",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";
					if (a.month.toUpperCase() < b.month.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (a.month.toUpperCase() > b.month.toUpperCase()) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.month}</span>
					</div>
				),
			},
			{
				title: "Year",
				dataIndex: "year",
				key: "year",
				width: "10.8%",
				align: "right",
				sorter: (a, b) => a.year - b.year,
				render: (text, record) => (
					<div>
						<span>{record.year}</span>
					</div>
				),
			},
			{
				title: "Invoice Link",
				dataIndex: "invoiceLink",
				key: "invoiceLink",
				width: "40%",
				align: "center",
				sorter: (a, b) => a.invoiceLink - b.invoiceLink,
				render: (text, record) => (
					<div>
						<span>
							<a
								href="#/" //Nilesh-4242-Removing console warnings
								onClick={(e) =>
									this.getInvoiceLink(e, record.month, record.year)
								}
								style={{ fontSize: "12px" }}>
								Get Invoice
							</a>
						</span>
					</div>
				),
			},
		];
		//    Sandeep-task-3221-removed _ from camp id
		const columns23 = [
			{
				title: "Camp ID",
				dataIndex: "campID",
				key: "campID",
				align: "center",

				sorter: (a, b) => a.campID - b.campID,
				width: "30.9%",
				render: (text, record) => (
					<div>
						{ }
						<span style={{ fontSize: "12px" }}>
							{this.state.ReviewTab === "ReviewCreatives" ? (
								<a
									href="#/" //Nilesh-4242-Removing console warnings
									id={record.campID}
									style={{ fontSize: "12px" }}
									onClick={(e) => this.uploadCreative(e, record)}>
									{record.parentCampID ? record.parentCampID : record.campID}
								</a>
							) : (
								<a
									href="#/"  //Nilesh-4242-Removing console warnings
									onClick={(e) => this.leadReview(e, record)}
									style={{ fontSize: "12px" }}>
									{record.parentCampID ? record.parentCampID : record.campID}
								</a>
							)}
						</span>
					</div>
				),
			},

			{
				title: "Camp Name",
				dataIndex: "campaignName",
				key: "campaignName",
				align: "center",
				width: "30.2%",

				sorter: (a, b) => {
					const asc = "asc";
					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.campaignName}</span>
					</div>
				),
			},
		];
		//    Sandeep-task-3221-removed _ from camp id
		const columns2 = [
			{
				title: "Camp ID",
				dataIndex: "campID",
				key: "campID",
				align: "right",

				sorter: (a, b) => a.campID - b.campID,
				width: "20.9%",
				render: (text, record) => (
					// Sandeep-task-3178-added code for camp id alignment in center
					<div style={{ textAlign: "center" }}>
						{ }
						<span style={{ fontSize: "12px" }}>
							{this.state.ReviewTab === "ReviewCreatives" ? (
								<a
									href="#/"  //Nilesh-4242-Removing console warnings
									id={record.campID}
									style={{ fontSize: "12px" }}
									onClick={(e) => this.uploadCreative(e, record)}>
									{record.parentCampID ? record.parentCampID : record.campID}
								</a>
							) : (
								<a
									href="#/"  //Nilesh-4242-Removing console warnings
									onClick={(e) => this.leadReview(e, record)}
									style={{ fontSize: "12px" }}>
									{record.parentCampID ? record.parentCampID : record.campID}
								</a>
							)}
						</span>
					</div>
				),
			},
			//    Sandeep-task-3221-removed _ from camp Name

			{
				title: "Camp Name",
				dataIndex: "campaignName",
				key: "campaignName",
				align: "center",
				width: "40.2%",

				sorter: (a, b) => {
					const asc = "asc";
					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.campaignName}</span>
					</div>
				),
			},
			// Sandeep-task-3221-removed _ from doc type
			{
				title: "Doc Type",
				dataIndex: "id",
				key: "id",
				width: "20%",
				align: "center",
				render: (text, record) => (
					<div>
						{record.csDocType}&nbsp;{record.lpDocType}&nbsp;{record.pocDocType}
					</div>
				),
			},

			{
				title: "Status",
				dataIndex: "status",
				key: "status",

				align: "center",

				render: (text, record) => (
					<div>
						{record.status === "Rejected" ||
							record.status === "ClientRejected" ? (
							<span style={{ fontSize: "12px", color: "red" }}>
								{record.status}{" "}
							</span>
						) : (
							<span style={{ fontSize: "12px", color: "green" }}>
								{record.status}{" "}
							</span>
						)}
					</div>
				),
			},
		];

		const RFPColumn = [
			{
				title: "Proposal ID",
				dataIndex: "campID",
				key: "campID",
				width: "14%",
				align: "center",
				sorter: (a, b) => a.id - b.id,
				render: (text, record) => (
					<div>
						<span>
							<a
								href="#/"  //Nilesh-4242-Removing console warnings
								onClick={this.RFPReview}
								id={record.campID}
								style={{ fontSize: "12px" }}>
								{record.campID}
							</a>
						</span>
					</div>
				),
			},
			//    Sandeep-task-3221-removed _ from camp name
			{
				title: "Camp Name",
				dataIndex: "campaignName",
				key: "campaignName",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";
					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.campaignName}</span>
					</div>
				),
			},
		];
		//    Sandeep-task-3221-removed _ from camp id
		const columns1 = [
			{
				title: "Camp ID",
				dataIndex: "campID",
				key: "campID",
				align: "center",
				sorter: (a, b) => a.campID - b.campID,
				width: "14%",
				render: (text, record) => (
					<div>
						<span>
							<span style={{ fontSize: "12px" }}>
								{record.parentCampID ? record.parentCampID : record.campID}
							</span>
						</span>
					</div>
				),
			},
			//    Sandeep-task-3221-removed _ from camp name
			{
				title: "Camp Name",
				dataIndex: "campaignName",
				key: "campaignName",
				align: "center",
				width: "37%",
				sorter: (a, b) => {
					const asc = "asc";
					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<span class="dot" style={{ fontSize: "12px" }}>
							<a
								href="#/"  //Nilesh-4242-Removing console warnings
								onClick={(e) => this.displayCampaignSpecification(e, record)}
								style={{ fontSize: "12px", wordBreak: "break-all" }}>
								{record.campaignName}
							</a>
						</span>
						<br />
						{record.startDate} To {record.endDate}
						<br />
						{this.state.userlogger === "PC" ? (   //Nilesh-4242-Removing console warnings
							<span style={{ fontSize: "10px" }}>
								CPL:{" "}
								<span style={{ fontWeight: "1000" }}>
									{record.CPL}
									{record.currency}
								</span>{" "}
							</span>
						) : (
							""
						)}
						<span style={{ fontSize: "10px" }}></span>
					</div>
				),
			},
			{
				title: "Allocation Date",
				dataIndex: "created",
				key: "created",
				align: "center",
				width: "15%",
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.created}</span>
					</div>
				),
			},

			{
				title: "Allocated Leads",
				dataIndex: "allocatedLead",
				key: "allocatedLead",
				width: "15%",
				align: "center",

				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.allocatedLead}</span>
					</div>
				),
			},
			
            //Nilesh-4242-Removing console warnings
			this.state.tab1 === "Counter"
				? // this.state.countered==true?
				{
					title: "Counter-ed Offer",
					dataIndex: "Counter-ed Offer",
					key: "Counter-ed Offer",
					width: "15%",
					align: "center",

					render: (text, record) => (
						<div>
							<div
								class="modal fade"
								id="counter"
								role="dialog"
								data-backdrop="static"
								data-keyboard="false"
								style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
								{/* //shivani-task 3237-made background blur */}
								<div class="modal-dialog cust-class">
									{/*Model Content*/}
									<div
										className="modal-content  modal-countered "
										style={{ marginLeft: "-184px", width: "330px" }}>
										<form method="POST" name="acceptPub">
											<div class="modal-header custom-class">
												<h4 class="modal-title" style={{ color: "white" }}>
													<center>
														Countered Offer (Campaign ID:
														{this.state.parentCampID})
													</center>
												</h4>
												<button
													type="button"
													class="cancel-button"
													style={{ fontSize: 15, float: "right" }}
													data-dismiss="modal"
													onClick={this.handleRefreshPage}>
													&times;
												</button>
											</div>

											{/* message */}
											<div
												className=""
												style={{ display: this.state.alertDisplay }}>
												<div class="container-fluid">
													<div class="card card-signin my-1">
														<div class="card-body">
															<div class="row">
																{/* <div class="col-lg-12" style={{display:this.state.alertDisplay}}> */}
																<div
																	style={{
																		fontSize: "14px",
																		color: "#008000",
																		fontWeight: "600",
																		paddingLeft: "30px",
																	}}>
																	{this.state.newsuccess}
																</div>

																{/* </div> */}
															</div>
														</div>
													</div>
												</div>
											</div>
											{this.state.biddingArray.map((biddingArray) => (
												<div class="modal-body">
													<div class="row">
														<p>
															<div class="col-12 ">
																<label id="label" style={{ float: "left" }}>
																	<b>Allocated CPL:&nbsp;</b>
																</label>
																&nbsp;&nbsp;&nbsp;
																<label id="label" style={{ color: "black" }}>
																	&nbsp;&nbsp;{biddingArray.CPL} &nbsp;
																	{biddingArray.currency}
																</label>
															</div>
															<div class="col-12 ">
																<label id="label" style={{ float: "left" }}>
																	<b>Allocated Leads:&nbsp;&nbsp;</b>
																</label>
																&nbsp;
																<label
																	id="label"
																	style={{
																		color: "black",
																		marginLeft: "-25px",
																	}}>
																	{biddingArray.allocatedLead}
																</label>
															</div>
															<div class="col-12 ">
																<label id="label" style={{ float: "left" }}>
																	<b>Countered CPL:&nbsp;</b>
																</label>
																&nbsp;&nbsp;&nbsp;
																<label id="label" style={{ color: "black" }}>
																	&nbsp;&nbsp;{biddingArray.counterCPL} &nbsp;
																	{biddingArray.currency}
																</label>
															</div>
															<div class="col-12 ">
																<label id="label" style={{ float: "left" }}>
																	<b>Countered Leads:&nbsp;&nbsp;</b>
																</label>
																&nbsp;
																<label
																	id="label"
																	style={{
																		color: "black",
																		marginLeft: "-27px",
																	}}>
																	{biddingArray.counterLead}
																</label>
															</div>
														</p>
													</div>
												</div>
											))}
											<div class="modal-footer">
												<button
													data-dismiss="modal"
													type="submit"
													className={this.state.btnDisable}
													onClick={this.reloadtable}
													style={{
														backgroundColor: "#0000b3",
														color: "white",
														borderRadius: "3px",
													}}>
													{/* shivani-task 2919-added onClick event to reload data in the table */}
													OK
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div></div>

							{/* end of countered campaign    */}

							<span style={{ fontSize: "12px" }}>
								<a
									href="#/"  //Nilesh-4242-Removing console warnings
									id={record.campID}
									data-toggle="modal"
									data-target="#counter"
									onClick={(e) =>
										this.handleCampaignIDOnClickCounter(
											e,
											record.parentCampID,
											record.allocationID
										)
									}>
									Offer
								</a>
							</span>
						</div>
					),
				}
				: {
					title: "Hrs Left",
					dataIndex: "Hrsleft",
					key: "hrsleft",
					width: "15%",
					align: "center",

					render: (text, record) => (
						<div>
							<span style={{ fontSize: "12px" }}>{record.hourLeft}</span>
						</div>
					),
				},

			{
				title: "Action",
				dataIndex: "",
				key: "",
				width: "20%",
				align: "center",
				render: (text, record) => (
					<div>
						<div
							class="modal fade"
							id="accept"
							role="dialog"
							data-backdrop="static"
							data-keyboard="false"
							style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
							{/* //shivani-task 3237-made background blur */}
							<div class="modal-dialog cust-class">
								{/*Model Content*/}
								<div class="modal-content" style={{ marginTop: "75px" }}>
									{/* shivani-task 3237-modal shifted down by applying margin */}
									<form
										method="POST"
										name="acceptPub"
										id="modal1" //shivani-task 3219 -added id which is used in jquery function to hide popUp.
									// onSubmit={this.submitCounterProposal}
									// onSubmit={this.acceptCampaign}
									>
										<div class="modal-header custom-class">
											<h4
												class="modal-title"
												style={{ color: "white", paddingTop: "7px" }}>
												<center>
													Accept Campaign (Campaign ID:
													{this.state.parentCampID})
												</center>
											</h4>
											<button
												type="button"
												class="cancel-button"
												style={{ fontSize: 20, float: "right" }}
												data-dismiss="modal"
												onClick={() => {
													this.setState({
														subContractingChecked: false, // User Story : 3188 : Karan Jagtap - added this due to 3219 update
													});
												}}>
												&times;
											</button>
											{/* shivani-task 3237-changed the size of cross button */}
										</div>

										{/* message */}
										<div
											className=""
											style={{ display: this.state.alertDisplay }}>
											<div class="container-fluid">
												<div class="card card-signin my-1">
													<div class="card-body">
														<div class="row">
															{/* <div class="col-lg-12" style={{display:this.state.alertDisplay}}> */}
															<div
																style={{
																	fontSize: "14px",
																	color: "#008000",
																	fontWeight: "600",
																	paddingLeft: "30px",
																}}>
																{this.state.newsuccess}
															</div>

															{/* </div> */}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="modal-body">
											<label id="label" style={{ float: "left" }}>
												<b>
													Are you sure you want to accept below Campaign with
													listed CPL?
												</b>
											</label>
											<br />
											<br />
											{this.state.newCampaignDetailID.map(
												(newCampaignDetailID) => (
													<p>
														<label id="label" style={{ float: "left" }}>
															<b>Allocated CPL:</b>&nbsp;&nbsp;
														</label>
														&nbsp;
														<label
															id="label"
															style={{ float: "left", color: "black" }}>
															{newCampaignDetailID.CPL} &nbsp;
															{newCampaignDetailID.currency}
														</label>
													</p>
												)
											)}
											<br />
											{/* User Story : 3188 : Karan Jagtap : Sub-Contracting display value */}
											{this.state.isSubContractingCheckboxVisible ? (
												<Checkbox
													style={{
														fontSize: 11,
														textAlign: "left",
													}}
													checked={this.state.subContractingChecked}
													onChange={this.onChangeSubContractingCheckbox}>
													By submitting a proposal to Agency, you are agreeing
													that there will be{" "}
													<span style={{ color: "#1890ff" }}>
														no sub-contracting for this campaign.
													</span>
												</Checkbox>
											) : null}
										</div>
										<div class="modal-footer">
											<button
												type="submit"
												data-dismiss="modal"
												className={this.state.btnDisable}
												disabled={
													this.state.isSubContractingCheckboxVisible
														? this.state.subContractingChecked
															? false
															: true
														: false
												}
												style={{
													backgroundColor: "#0000b3",
													color: "white",
													borderRadius: "3px",
												}}
												onClick={this.acceptCampaign}>
												Accept
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>

						{/* Aman - 4595 - (Accept all Campaign popop added)PC- Accept Campaign - Select box /Select All option required */}
						<div
							class="modal fade"
							id="acceptAll"
							role="dialog"
							data-backdrop="static"
							data-keyboard="false"
							style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
							<div class="modal-dialog cust-class">
								{/*Model Content*/}
								<div class="modal-content" style={{ marginTop: "75px" }}>
									<form method="POST" name="acceptPub" id="modal1">
										<div class="modal-header custom-class">
											<h4
												class="modal-title"
												style={{ color: "white", paddingTop: "7px" }}>
												<center>
													Accept Campaign (Total Count: - {this.state.newCampaignLength})
												</center>
											</h4>
											<button
												type="button"
												class="cancel-button"
												style={{ fontSize: 20, float: "right" }}
												data-dismiss="modal"
												onClick={() => {
													this.setState({
														subContractingChecked: false,
													});
												}}>
												&times;
											</button>
										</div>
										{/* message */}
										<div
											style={{ display: this.state.alertDisplay }}>
											<div class="container-fluid">
												<div class="card card-signin my-1">
													<div class="card-body">
														<div class="row">
															<div
																style={{
																	fontSize: "14px",
																	color: "#008000",
																	fontWeight: "600",
																	paddingLeft: "30px",
																}}>
																{this.state.newsuccess}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="modal-body">
											<label id="label" style={{ float: "left" }}>
												<b>
													This action will accept all campaigns considering allocation and CPL has been reviewed and agreed.
												</b>
											</label>
											<br />
											<br />
											<p style={{ marginTop: "20px" }}>
												<label id="label">
													<b>Are you sure, you want to accept all campaigns?</b>&nbsp;&nbsp;
												</label>
											</p>
										</div>
										<div class="modal-footer">
											<button
												type="submit"
												data-dismiss="modal"
												className={this.state.btnDisable}
												style={{
													backgroundColor: "#0000b3",
													color: "white",
													borderRadius: "3px",
												}}
												onClick={this.acceptAllCampaign}>
												Accept All
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						{/* Accept All Campaign Model end*/}
						<div>
							{/* Reject Model */}
							<div
								class="modal fade"
								id="reject"
								role="dialog"
								data-backdrop="static"
								data-keyboard="false"
								style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
								{/* //shivani-task 3237-made background blur */}
								<div class="modal-dialog">
									{/*Model Content*/}
									<div class="modal-content" style={{ marginTop: "75px" }}>
										{/* shivani-task 3237-modal shifted down by applying margin */}
										<form
											method="POST"
											name="rejectPub"
											onSubmit={this.rejectCampaign}>
											<div class="modal-header custom-class">
												<h4
													class="modal-title"
													style={{ color: "#144c9b", paddingTop: "7px" }}>
													<font color="white">
														Rejection (Campaign ID:{this.state.parentCampID})
													</font>
												</h4>
												<button
													type="button"
													class="cancel-button"
													style={{ fontSize: 20, float: "right" }}
													data-dismiss="modal"
													onClick={this.handleRefreshPage}>
													&times;
												</button>
												{/* shivani-task 3237-changed the size of cross button */}
											</div>
											<div
												className=""
												style={{ display: this.state.alertDisplay }}>
												<div class="container-fluid">
													<div class="card card-signin my-1">
														<div class="card-body">
															<div class="row">
																<div
																	style={{
																		fontSize: "14px",
																		color: "#008000",
																		fontWeight: "600",
																		paddingLeft: "30px",
																	}}>
																	{this.state.newsuccess}
																</div>

																{/* </div> */}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="modal-body">
												<label id="label" style={{ float: "left" }}>
													<b>
														<center>
															{" "}
															Are you sure you want to reject below Campaign
															with listed CPL?
														</center>
													</b>
												</label>
												<br />
												<br />
												{this.state.newCampaignDetailID.map(
													(newCampaignDetailID) => (
														<div>
															<label id="label" style={{ float: "left" }}>
																<b> Allocated CPL:</b>&nbsp;
															</label>
															<label
																id="label"
																style={{ float: "left", color: "black" }}>
																{newCampaignDetailID.CPL} &nbsp;
																{newCampaignDetailID.currency}
															</label>
															<br />
															<br />
															<label id="label" style={{ float: "left" }}>
																<b>Rejection Reason:</b>&nbsp;
															</label>
															<textarea
																style={{ height: "200px", resize: "none" }}
																id="rejectionReason"
																name="rejectionReason"
																value={this.state.rejectionReason}
																onChange={this.handleChange}
																class="form-control"
															/>
															<input
																type="hidden"
																id="allocatedLead"
																name="allocatedLead"
																value={newCampaignDetailID.allocatedLead}
															/>
														</div>
													)
												)}
											</div>
											<div class="modal-footer">
												<button
													type="submit"
													className={this.state.btnDisable}
													style={{
														backgroundColor: "#056eb8",
														color: "white",
														width: "min-content",
														height: "31px",
														fontSize: "14px",
													}}>
													Reject
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
							{/*end of reject modal */}

							{this.state.userlogger === "PC" ? ( //Nilesh-4242-Removing console warnings
								<span style={{ display: "inline-flex" }}>
									<a href="#/"  //Nilesh-4242-Removing console warnings
									 id={record.campID}>
										<Tooltip placement="top" title="Accept">
											<img
											alt=""  //Nilesh-4242-Removing console warnings
												src="accept.svg"
												id={record.allocationID}
												data-toggle="modal"
												data-target="#accept"
												onClick={(e) =>
													this.handleCampaignIDOnClickAccept(
														e,
														record.parentCampID,
														record.reallocationID
													)
												}
												width="20"
												height="20"></img>
										</Tooltip>
									</a>
									&nbsp;&nbsp;
									<a href="#/"  //Nilesh-4242-Removing console warnings
									id={record.campID}>
										<Tooltip placement="top" title="Reject">
											<img
											    alt=""  //Nilesh-4242-Removing console warnings
												src="Reject.png"
												id={record.allocationID}
												data-toggle="modal"
												data-target="#reject"
												onClick={(e) =>
													this.handleCampaignIDOnClickAccept(
														e,
														record.parentCampID
															? record.parentCampID
															: record.campID,
														record.reallocationID
													)
												}
												width="20"
												height="20"></img>
										</Tooltip>
									</a>
									&nbsp;&nbsp;
									{this.state.tab1 === "NewCampaign" ? (  //Nilesh-4242-Removing console warnings
										<a
											href="#/"  //Nilesh-4242-Removing console warnings
											id={record.campID}
											data-toggle="modal"
											data-target="#counterproposal11">
											<Tooltip placement="top" title="Counter">
												<img
												    alt=""  //Nilesh-4242-Removing console warnings
													src="Counter.png"
													id={record.allocationID}
													onClick={(e) => this.counter(e, record)}
													width="20"
													height="20"></img>
											</Tooltip>
										</a>
									) : (
										<Tooltip
											placement="top"
											overlayStyle={{
												color: "white",
												backgroundColor: "orange",
											}}
											title="Counter Proposal Submitted">
											<img
											    alt=""  //Nilesh-4242-Removing console warnings
												src="Counter_Disabled.png"
												width="20"
												height="20"></img>
										</Tooltip>
									)}
								</span>
							) : (
								<span style={{ display: "inline-flex" }}>
									<Tooltip placement="top" title="Accept">
										<img alt=""  //Nilesh-4242-Removing console warnings
										src="Accept_Disabled.jpg" width="20" height="20"></img>
									</Tooltip>
									&nbsp;&nbsp;
									<Tooltip placement="top" title="Reject">
										<img alt=""  //Nilesh-4242-Removing console warnings
										 src="Reject_Disabled.png" width="20" height="20"></img>
									</Tooltip>
									&nbsp;&nbsp;
									{this.state.tab1 === "NewCampaign" ? (  //Nilesh-4242-Removing console warnings
										<Tooltip placement="top" title="Counter">
											<img
											    alt=""  //Nilesh-4242-Removing console warnings
												src="Counter_Disabled.png"
												width="20"
												height="20"></img>
										</Tooltip>
									) : (
										<Tooltip placement="top" title="Counter">
											<img
											    alt=""  //Nilesh-4242-Removing console warnings
												src="Counter_Disabled.png"
												width="20"
												height="20"></img>
										</Tooltip>
									)}
								</span>
							)}

							<div
								class="modal fade"
								id="counterproposal11"
								role="dialog"
								data-backdrop="static"
								data-keyboard="false"
								style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
								{/* //shivani-task 3237-made background blur */}
								<div class="modal-dialog cust-class">
									{/*Model Content*/}
									<div class="modal-content">
										<form
											method="POST"
											name="counterproposal"
											onSubmit={this.submitCounterProposal}>
											<div class="modal-header custom-class">
												<h4
													class="modal-title"
													style={{ color: "white", paddingTop: "7px" }}>
													<center>
														Counter Proposal&nbsp;(Campaign ID:
														{this.state.campID})
													</center>
												</h4>

												<button
													type="button"
													class="cancel-button"
													style={{ fontSize: 20, float: "right" }}
													data-dismiss="modal"
													onClick={this.handleRefreshPage}>
													&times;
												</button>
												{/* shivani-task 3237-changed the size of cross button */}
											</div>

											{this.state.newCampaignDetailID.map(
												(newCampaignDetailID) => (
													<div class="modal-body">
														<div class="container-fluid">
															<div class="row">
																<div class="col-sm-12"> </div>
															</div>

															<br />
															<div
																className=""
																style={{ display: this.state.alertDisplay }}>
																<div class="container-fluid">
																	<div class="card-body">
																		<div class="row">
																			<div
																				class="col-lg-12"
																				style={{
																					display: this.state.alertDisplay,
																					border: this.state.alertBorder
																				}}
																				//Nilesh-4242-Removing console warnings
																				// style={{
																				// 	border: this.state.alertBorder,
																				// }}
																				>
																				<div class="errorMessage">
																					{this.state.errors.proposedLead
																						? this.state.errors.proposedLead
																						: ""}

																					{this.state.errors.proposedCPL
																						? this.state.errors.proposedCPL
																						: ""}
																					{this.state.errors.proposedCPL1
																						? this.state.errors.proposedCPL1
																						: ""}
																					{this.state.errors.proposedCPL2
																						? this.state.errors.proposedCPL2
																						: ""}
																					{this.state.errors.proposedCPL3
																						? this.state.errors.proposedCPL3
																						: ""}
																					{this.state.errors.GraterProposedLead
																						? this.state.errors
																							.GraterProposedLead
																						: ""}
																					{this.state.errors.lessProposedCPL
																						? this.state.errors.lessProposedCPL
																						: ""}
																					{this.state.errors.equalsToCPL
																						? this.state.errors.equalsToCPL
																						: ""}
																				</div>
																				<div class="successMessage">
																					{this.state.newsuccess}
																				</div>
																			</div>
																		</div>
																		{/* </div> */}
																	</div>
																</div>
															</div>
															{/* <br/> */}
															<div class="row">
																<div class="col-sm-6">
																	<label id="label">Allocated Leads:</label>
																	<br />
																	{newCampaignDetailID.allocatedLead}
																</div>
																<div class="col-sm-6">
																	<label id="label">
																		Proposed Leads:
																		<span style={asterisk}>*</span>
																	</label>
																	<input
																		type="text"
																		id="proposedLead"
																		name="proposedLead"
																		value={this.state.proposedLead}
																		onChange={this.handleChange}
																		style={{
																			width: "87%",

																			borderRadius: "2px",
																		}}
																		class="form-control"
																	/>
																</div>

																<div class="col-sm-6">
																	<label id="label">Allocated CPL:</label>
																	<br />
																	{newCampaignDetailID.CPL}&nbsp;
																	{newCampaignDetailID.currency}
																</div>
																<div class="col-sm-6">
																	<label id="label">
																		Proposed CPL:{" "}
																		<span style={asterisk}>*</span>
																	</label>
																	&nbsp;
																	<input
																		type="text"
																		id="proposedCPL"
																		name="proposedCPL"
																		value={this.state.proposedCPL}
																		onChange={this.handleChange}
																		style={{
																			width: "87%",

																			borderRadius: "2px",
																		}}
																		class="form-control"
																	/>
																</div>
															</div>
															{/**End of row */}
														</div>
														{/*container-fluid*/}
													</div>
												)
											)}
											<div class="modal-footer">
												<button
													type="submit"
													class="btn btn-info "
													className={this.state.inputClass}>
													Submit
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
							{/*End of  Counter Proposal Model */}
						</div>
					</div>
				),
			},
		];

		var options1;

		var agencyName = this.state.agencyName;
		var text;
		var temptimeline = this.state.pacing;
		var sd, ed;
		if (this.state.startDate === "") {   //Nilesh-4242-Removing console warnings
			sd = "Start Date";
		} else {
			sd = this.state.startDate;
		}
        
		if (this.state.endDate === "") { //Nilesh-4242-Removing console warnings
			ed = "End Date";
		} else {
			ed = this.state.endDate;
		}

		var timeline;
		var currentMonth;
		var PrevMonth;
		var NextMonth;
		if (temptimeline === "Monthly") { //Nilesh-4242-Removing console warnings
			var date = new Date();
			timeline = MONTHS[date.getMonth()];
		} else if (temptimeline === "Quarterly") {   //Nilesh-4242-Removing console warnings
		    date = new Date(); //Nilesh-4242-Removing console warnings
			PrevMonth = MONTHS[date.getMonth() - 2];
			currentMonth = MONTHS[date.getMonth()];
			NextMonth = MONTHS[date.getMonth() - 1];

			let m = date.getMonth();
			m += 1;
			if (m < 4) {
				timeline = "Jan,Feb,Mar";
			} else if (m > 3 && m < 7) {
				timeline = "Apr,May,Jun";
			} else if (m > 6 && m < 10) {
				timeline = "Jul,Aug,Sep";
			} else {
				timeline = "Oct,Nov,Dec";
			}
		} else if (temptimeline === "Yearly") {  //Nilesh-4242-Removing console warnings
			date = new Date();  //Nilesh-4242-Removing console warnings
			timeline = date.getFullYear();
		} else if (temptimeline === "PreMonth") {  //Nilesh-4242-Removing console warnings
			date = new Date();  //Nilesh-4242-Removing console warnings
			timeline = MONTHS[date.getMonth() - 1];
		} else if (temptimeline === "PreQuarter") {  //Nilesh-4242-Removing console warnings
			date = new Date(); //Nilesh-4242-Removing console warnings
			PrevMonth = MONTHS[date.getMonth() - 5];
			currentMonth = MONTHS[date.getMonth() - 4];
			NextMonth = MONTHS[date.getMonth() - 3];

			let m = date.getMonth();
			m += 1;
			let Q;
			if (m < 4) {
				Q = 1;
			} else if (m > 3 && m < 7) {
				Q = 2;
			} else if (m > 6 && m < 10) {
				Q = 3;
			} else {
				Q = 4;
			}
			if (Q == 1) {
				Q = 4;
			} else {
				Q = Q - 1;
			}
			if (Q == 1) {
				timeline = "Jan,Feb,Mar";
			} else if (Q == 2) {
				timeline = "Apr,May,Jun";
			} else if (Q == 3) {
				timeline = "Jul,Aug,Sep";
			} else {
				timeline = "Oct,Nov,Dec";
			}
		} else if (temptimeline === "PreYear") { //Nilesh-4242-Removing console warnings
			date = new Date(); //Nilesh-4242-Removing console warnings
			timeline = date.getFullYear() - 1;
		} else if ((temptimeline = "dateRange")) {
			timeline = sd + " To " + ed;  //Nilesh-4242-Removing console warnings
		}

		text = agencyName
			? "Delivery Status-" + agencyName + "(" + timeline + ")"
			: "Delivery Status-All Agency(" + timeline + ")";

		// var cancelled = JSON.stringify(this.state.barChartArray[0]);  //Nilesh-4242-Removing console warnings 
		var from;
		// var byPublisher = this.state.byPublisher;  //Nilesh-4242-Removing console warnings
		var agencyID = this.state.agencyID;
		var pID = this.props.auth.user.id; //karan-task-3717-removed body param and added user token
		const self = this; //karan-task-3717-class instance
		if (this.state.byCampaign) {
			from = "byCampaign";
			options1 = {
				animationEnabled: true,
				exportEnabled: false,
				title: {
					text: text,
					horizontalAlign: "left",
					fontSize: 15,
					fontColor: "#1c1b19",

					fontWeight: "bold",
					fontFamily: "roboto",
				},
				axisY: {
					title: "Campaign",
					gridThickness: 0,
				},
				toolTip: {
					shared: true,
					reversed: true,
					enabled: true, //disable here
					animationEnabled: true,
					content: toolTipContent1,
				},
				legend: {
					verticalAlign: "center",
					horizontalAlign: "right",
					reversed: true,
					cursor: "pointer",
					itemclick: this.toggleDataSeries,
				},
				height: 260,
				data: [
					{
						// cursor: "pointer",   //Nilesh-4242-Removing console warnings
						click: function (e) {
							self.props.history.push("/publisherCampaignList", {
								agencyID: agencyID,
								fromDate: e.dataPoint.fromDate,
								toDate: e.dataPoint.toDate,
								from: from,
								status: "Active",
							}); //karan-task-3717-replace query params
						},
						indexLabel: "{l}",
						indexLabelFontColor: "black",
						indexLabelPlacement: "inside",
						type: "stackedColumn",
						name: "Active",
						showInLegend: false,

						color: "#39B54A",
						cursor: "pointer",
						dataPoints: this.state.activeAcceptedArray,
					},
					{
						click: function (e) {
							self.props.history.push("/publisherCampaignList", {
								agencyID: agencyID,
								fromDate: e.dataPoint.fromDate,
								toDate: e.dataPoint.toDate,
								from: from,
								status: "Cancel",
							}); //karan-task-3717-replace query params
						},
						indexLabel: "{l}",
						indexLabelFontColor: "black",
						indexLabelPlacement: "inside",
						type: "stackedColumn",
						name: "Cancel",
						showInLegend: false,

						color: "#ED1C24",
						cursor: "pointer",
						dataPoints: this.state.cancelRejectedArray,
					},
					{
						click: function (e) {
							self.props.history.push("/publisherCampaignList", {
								agencyID: agencyID,
								fromDate: e.dataPoint.fromDate,
								toDate: e.dataPoint.toDate,
								from: from,
								status: "Completed",
							}); //karan-task-3717-replace query params
						},
						indexLabel: "{l}",
						indexLabelFontColor: "black",
						indexLabelPlacement: "inside",
						type: "stackedColumn",
						name: "Completed",
						showInLegend: false,

						color: "#00A5F0",
						cursor: "pointer",
						dataPoints: this.state.completedDeleveredArray,
					},
					{
						click: function (e) {
							self.props.history.push("/publisherCampaignList", {
								agencyID: agencyID,
								fromDate: e.dataPoint.fromDate,
								toDate: e.dataPoint.toDate,
								from: from,
								status: "Paused",
							}); //karan-task-3717-replace query params
						},
						indexLabel: "{l}",
						indexLabelFontColor: "black",
						indexLabelPlacement: "inside",
						type: "stackedColumn",
						name: "Paused",
						showInLegend: false,

						color: "#FF931E",
						cursor: "pointer",
						dataPoints: this.state.pausedQaArray,
					},
				],
			};
			function toolTipContent1(e) {
				var str5;
				for (var i = 0; i < e.entries.length; i++) {
					//sandeep-task-2964- added code to align tooltip content properly
					var str1 =
						'<span style= "color:#39B54A' +
						'"> ' +
						e.entries[0].dataSeries.name +
						'</span>:<strong style="float:right">' +
						e.entries[0].dataPoint.y +
						"</strong><br/>";
					var str2 =
						'<span style= "color:#ED1C24' +
						'"> ' +
						e.entries[1].dataSeries.name +
						'</span>:<strong style="float:right">' +
						e.entries[1].dataPoint.y +
						"</strong><br/>";
					var str3 =
						'<span style= "color:#00A5F0' +
						'"> ' +
						e.entries[2].dataSeries.name +
						'</span>:<strong style="float:right">' +
						e.entries[2].dataPoint.y +
						"</strong><br/>";
					var str4 =
						'<span style= "color:#FF931E' +
						'"> ' +
						e.entries[3].dataSeries.name +
						'</span>:<strong style="float:right">' +
						e.entries[3].dataPoint.y +
						"</strong><br/>";
					var str12 = str2.concat(str1);
					var str123 = str3.concat(str12);
					var str1234 = str4.concat(str123);
				}

				str5 =
					'<span style = "color:Black"></span><strong>' +
					e.entries[0].dataPoint.label +
					"</strong><br/>";

				return str5.concat(str1234);
			}
		} else {
			from = "byLead";
			options1 = {
				animationEnabled: true,
				exportEnabled: false,
				title: {
					text: text,
					horizontalAlign: "left",
					fontSize: 15,
					fontColor: "#1c1b19",

					fontWeight: "bold",
					fontFamily: "roboto",
				},
				axisY: {
					title: "Lead",
					gridThickness: 0,
				},
				toolTip: {
					shared: true,
					reversed: true,
					enabled: true, //disable here
					animationEnabled: true,
					content: toolTipContent,
				},
				legend: {
					verticalAlign: "center",
					horizontalAlign: "right",
					reversed: true,
					cursor: "pointer",
					itemclick: this.toggleDataSeries,
				},
				height: 260,
				data: [
					{
						indexLabel: "{l}",
						indexLabelFontColor: "black",
						indexLabelPlacement: "inside",
						type: "stackedColumn",
						name: "To be Delivered",
						showInLegend: false,

						color: "#39B54A",
						dataPoints: this.state.barChartArray[0],
					},
					{
						indexLabel: "{l}",
						indexLabelFontColor: "black",
						indexLabelPlacement: "inside",
						type: "stackedColumn",
						name: "Rejected",
						showInLegend: false,

						color: "#ED1C24",
						dataPoints: this.state.barChartArray[3],
					},
					{
						indexLabel: "{l}",
						indexLabelFontColor: "black",
						indexLabelPlacement: "inside",
						type: "stackedColumn",
						name: "Accepted",
						showInLegend: false,

						color: "#00A5F0",
						dataPoints: this.state.barChartArray[1],
					},
					{
						indexLabel: "{l}",
						indexLabelFontColor: "black",
						indexLabelPlacement: "inside",
						type: "stackedColumn",
						name: "In QA",
						showInLegend: false,
						color: "#FF931E",
						dataPoints: this.state.barChartArray[2],
					},
				],
			};
			function toolTipContent(e) {
				// var str = "";    //Nilesh-4242-Removing console warnings
				var str5, str6;
				for (var i = 0; i < e.entries.length; i++) {
					//sandeep-task-2964- added code to align tooltip content properly
					var str1 =
						'<span style= "color:#39B54A' +
						'"> ' +
						e.entries[0].dataSeries.name +
						'</span>:<strong style="float:right">' +
						e.entries[0].dataPoint.y +
						"</strong><br/>";
					var str2 =
						'<span style= "color:#ED1C24' +
						'"> ' +
						e.entries[1].dataSeries.name +
						'</span>:<strong style="float:right">' +
						e.entries[1].dataPoint.y +
						"</strong><br/>";
					var str3 =
						'<span style= "color:#00A5F0' +
						'"> ' +
						e.entries[2].dataSeries.name +
						'</span>:<strong style="float:right">' +
						e.entries[2].dataPoint.y +
						"</strong><br/>";
					var str4 =
						'<span style= "color:#FF931E' +
						'"> ' +
						e.entries[3].dataSeries.name +
						'</span>:<strong style="float:right">' +
						e.entries[3].dataPoint.y +
						"</strong><br/>";
					var str12 = str2.concat(str1);
					var str123 = str3.concat(str12);
					var str1234 = str4.concat(str123);
				}

				str5 =
					'<span style = "color:#7400B8;"><strong>' +
					"Allocated" +
					'</span>: <strong style="float:right">' +
					e.entries[0].dataPoint.allocatedLead +
					"</strong></span><br/>";
				//sandeep-task-2964- added code to align tooltip content properly
				str6 =
					'<span style = "color:Black"></span><strong>' +
					e.entries[0].dataPoint.label +
					"</strong><br/>";

				return str6.concat(str5.concat(str1234));
			}
		}

		return (
			<div>
				<PublisherNavigation />
				<div
					class="container-fluid"
					style={{ paddingTop: "74px", paddingBottom: "80px" }}>
					<div class="row">
						<div
							class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9"
							style={{ paddingTop: "35px" }}>
							{/* sandeep-task-3221-added color to delivery Dashboard label */}
							<span
								style={{
									fontFamily: "Roboto",
									fontSize: "22px",
									fontWeight: "450",
									borderBottom: "3px solid #144c9b",
									paddingBottom: "10px",
									color: "rgba(0, 0, 0, 0.85)",
								}}>
								Delivery Dashboard
							</span>

							<hr />
						</div>
						{/* sandeep-task-2998 added vertical line */}
						{/*<div class="vl"></div>*/}	{/*Chaitanya-4292-R29-Prod issue-Publisher login-dashboard vertical line removed*/}
					</div>
					<br></br>
					<div class="row" /*style={{ marginTop: "-23px" }}*/>	{/*Chaitanya-4292-R29-Prod issue-Publisher login-dashboard horizontal lines issue*/}
						<div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
							<select
								id="agencyName"
								name="agencyName"
								class="form-control"
								className="agencyNameStyle"
								onChange={this.handleAgencyName}>
								<option value="All Agency">Select Agency</option>
								{this.state.agencyList.map((agencyList, i) => (
									<option value={agencyList.agencyID} key={i}>
										{agencyList.agencyName}
									</option>
								))}
								{/***Somnath Task-3253, Add index key in array */}
							</select>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></div>
						<div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
							<div class="float-right topnav color">
								{this.state.selectedOption === "Lead" ? (
									<Radio.Group
										className="lead-radio"
										style={{ backgroundColor: this.state.color2 }}
										onChange={this.dataFilter}
										name="filter"
										class={this.state.linkActive2}
										defaultValue="Lead">
										<Radio value="Lead" id="byLead">
											Lead
										</Radio>
										<Radio value="Campaign" id="byCampaign">
											Campaign
										</Radio>
									</Radio.Group>
								) : (
									""
								)}
								{this.state.selectedOption === "Campaign" ? (
									<Radio.Group
										className="camp-radio"
										style={{ backgroundColor: this.state.color1 }}
										onChange={this.dataFilter}
										name="filter"
										class={this.state.linkActive1}
										defaultValue="Campaign">
										<Radio value="Lead" id="byLead">
											Lead
										</Radio>
										<Radio value="Campaign" id="byCampaign">
											Campaign
										</Radio>
									</Radio.Group>
								) : (
									""
								)}
								&nbsp;&nbsp;&nbsp;
							</div>
						</div>
					</div>
					{/* kiran-4149-changes div id names for resolving conflicts    */}
					<div class="row">
						<div
							id="cardmonthPub"
							class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
							<div
								class="card"
								style={{ border: "2px solid rgba(12, 12, 12, 0.22)" }}>
								<div class="card-body" style={{ height: "410px" }}>
									{/* sandeep-task-2998 -added code to show the result of dropdown action on screen */}
									<div class="row">
										<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
											<div style={{ width: "300px" }}>
												<label
													style={{
														fontFamily: "Roboto",
														color: "#71717199",
														fontSize: "13px",
													}}>
													Showing result for &nbsp;
												</label>
												<span
													style={{
														color: "#FFA500",
														fontFamily: "Roboto",
														fontSize: "12px",
													}}>
													{" "}
													"{this.state.label}"
												</span>
											</div>
										</div>

										{/* sandeep-task-2998 code to place filter inside baar chart */}

										<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
											<div class="float-right">
												<select
													id="publisherPacing"
													name="publisherPacing"
													class="form-control duration_dropdown" // Musbir-Task-4389 class added "duretion_dropdown" 
													onChange={this.handlePacing}
													style={{
														//width: "128px", // Musbir-Task-4389 comment width not in used 
														height: "30px",
														fontSize: "11px",
														fontFamily: "roboto",
														border: "1.4px solid rgb(20, 76, 155)",
													}}>
													<option value="Monthly" label="Ending This Month">
														Ending This Month
													</option>
													<option value="Quarterly" label="Ending This Quarter">
														Ending This Quarter
													</option>
													<option value="Yearly" label="Ending This Year">
														Ending This Year
													</option>
													<option
														value="PreMonth"
														label="Ending Previous Month">
														Ending Previous Month
													</option>
													<option
														value="PreQuarter"
														label="Ending Previous Quarter">
														Ending Previous Quarter
													</option>
													<option value="PreYear" label="Ending Previous Year">
														Ending Previous Year
													</option>
													<option value="dateRange" label="Date Range">
														Date Range
													</option>
												</select>
											</div>
										</div>
									</div>
									<hr class="breakline" style={{ color: "lightgrey" }}></hr>	{/*Chaitanya-4292-R29-Prod issue-Publisher login-dashboard vertical and horizontal lines issue*/}

									<div class="row">
										<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<span style={{ display: this.state.dateDisplay }}>
												From&nbsp;
												<input
													type="date"
													id="StartDate"
													name="StartDate"
													style={{ height: "32px" }}
													value={this.state.startDate}
													onChange={this.commonhandleChange}
												/>
												<br />
												To&nbsp;&nbsp;&nbsp;
												<input
													type="date"
													id="EndDate"
													name="EndDate"
													style={{ height: "32px", marginLeft: "10px" }}
													value={this.state.endDate}
													onChange={this.commonhandleChange}
												/>
											</span>
										</div>
									</div>

									<div class="row" className="rowStyle">
										{this.state.dataExist ? (
											!this.state.loadingCharts ? (
												this.state.byCampaign ? (
													<Chart
														width={"415px"}
														height={"252px"}
														chartType="PieChart"
														loader={<div>Loading Chart</div>}
														data={this.state.pieChartArray}
														options={{
															width: 550,
															height: 250,
															title: text,
															titleTextStyle: {
																color: "#1a1715",
																fontName: "roboto",
																fontSize: 15,
															},
															is3D: true,
															pieSliceText: "none",
															pieSliceTextStyle: {
																color: "black",
																fontSize: "30px",
																fontWeight: "800",
															},
															chartArea: {
																left: 10,
																width: "60%",
																height: "75%",
																backgroundColor: "transparent",
															},
															slices: {
																0: { color: "#39B54A" },
																1: { color: "#00A5F0" },
																2: { color: "#FF931E" },
																3: { color: "#ED1C24" },
															},
															backgroundColor: "transparent",
															legend: {
																position: "labeled",
															},
														}}
														rootProps={{ "data-testid": "2" }}
													/>
												) : (
													<Chart
														width={"415px"}
														height={"252px"}
														chartType="PieChart"
														loader={<div>Loading Chart</div>}
														data={this.state.pieChartArray}
														options={{
															width: 550,
															height: 250,
															title: text,
															titleTextStyle: {
																color: "#1a1715",
																fontName: "roboto",
																fontSize: 15,
															},
															is3D: true,
															pieSliceText: "none",
															pieSliceTextStyle: {
																color: "black",
																fontSize: "30px",
																fontWeight: "800",
															},
															chartArea: {
																left: 10,
																width: "60%",
																height: "75%",
																backgroundColor: "transparent",
															},
															slices: {
																0: { color: "#39B54A" },
																1: { color: "#00A5F0" },
																2: { color: "#FF931E" },
																3: { color: "#ED1C24" },
															},
															backgroundColor: "transparent",
															legend: {
																position: "labeled",
															},
														}}
														rootProps={{ "data-testid": "2" }}
													/>
												)
											) : (
												<div>Loading Chart</div>
											)
										) : (
											<img
											    alt=""  //Nilesh-4242-Removing console warnings
												src="piechart1.png"
												className="piechart1"
												style={{
													width: "400px",
													display: "block",
													marginLeft: "45px",
												}}></img>
										)}
									</div>
								</div>
							</div>
						</div>
						{/* end of card month */}
						<div
							id="cardclientPub"
							class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
							<div
								class="card"
								style={{ border: "2px solid rgba(12, 12, 12, 0.22)" }}>
								<div class="card-body" style={{ height: "410px" }}>
									{/* sandeep-task-2998 -added code to show the result of dropdown action on screen */}
									<div class="row">
										<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
											<div style={{ width: "300px" }}>
												<label
													style={{
														fontFamily: "Roboto",
														color: "#71717199",
														fontSize: "13px",
													}}>
													Showing result for &nbsp;
												</label>
												<span
													style={{
														color: "#FFA500",
														fontFamily: "Roboto",
														fontSize: "12px",
													}}>
													{" "}
													"{this.state.label}"
												</span>
											</div>
										</div>
									</div>
									<hr class="breakline" style={{ color: "lightgrey" }}></hr>	{/*Chaitanya-4292-R29-Prod issue-Publisher login-dashboard vertical and horizontal lines issue*/}
									{this.state.dataExist ? (
										!this.state.loadingCharts ? (
											<CanvasJSChart
												options={options1}
												onRef={(ref) => (this.chart = ref)}
											/>
										) : (
											<div>Loading Graph</div>
										)
									) : (
										<img
										    alt=""  //Nilesh-4242-Removing console warnings
											src="barchart1.png"
											className="barchart1"
											style={{
												width: "395px",
												display: "block",
												marginLeft: "1px",
											}}></img>
									)}
								</div>
							</div>
						</div>

						{/* end of cardClient */}
						<div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 demoPub ">
							{/* Sandeep-task-3221-added color and font-weight for Alert label */}
							<span
								style={{
									fontFamily: "Roboto",
									fontSize: "22px",
									fontWeight: "450",
									borderBottom: "3px solid #144c9b",
									paddingBottom: "2px",
									color: "rgba(0, 0, 0, 0.85)",
								}}>
								Alerts
							</span>
							{/* Sandeep-task-3221-added color and font-family for mark all read and clear label */}
							<span
								class="float-right"
								style={{
									fontFamily: "Roboto",
									fontSize: "12px",
									fontWeight: "450",
									marginTop: "10px",
									color: "#144c9b",
								}}>
								<a href="#/"  //Nilesh-4242-Removing console warnings
								onClick={this.handleMarkAllReadAndClear}>
									Mark All Read & Clear
								</a>
							</span>
							{/* Sandeep-task-2998- to handle mark all read & clear functionality */}
							<hr />
							{this.state.unreadAlerts.length > 0 ? (
								<div
									className="unreadalertsPub"
									style={{
										overflowY: "scroll",
										maxHeight: "350px",
										// position: "absolute",
									}}>
									{this.state.unreadAlerts.map((unreadAlerts, i) => {
										return (
											<span key={i} className="cardUnreadAlerts">
												<div
													class="card"
													style={{ boxShadow: "5px 5px 5px  #888888" }}>
													<div class="card-body">
														<b>
															<a
																href="#/" //Nilesh-4242-Removing console warnings
																onClick={(e) =>
																	this.displayCampaignSpecificationAlerts(
																		e,
																		unreadAlerts
																	)
																}>
																{unreadAlerts.campID}
															</a>{" "}
														</b>
														&nbsp;&nbsp;|&nbsp;&nbsp;
														<span
															style={{
																fontFamily: "Roboto",
																fontSize: "12px",
															}}>
															<b>{unreadAlerts.campaignName}</b>
															&nbsp;&nbsp;|&nbsp;&nbsp;
                                                            {/* Nilesh-4242-Removing console warnings */}
															{unreadAlerts.content ===
																"New Campaign Allocated" ? (
																<span
																	style={{
																		color: "#524373",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===  
																"Publisher Accept Campaign" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#0AB710",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Publisher Upload Creatives" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#192BCF",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Creatives review is completed" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#310273",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Campaign end date is updated" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#4285F4",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Allocation cancelled after 48 hrs" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#D11124",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Publisher Upload Lead" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#19BDCF",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Publisher Counter Campaign" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#F2860F",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content === "Campaign Edited" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#4285F4",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Publisher Reject Campaign" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#D11124",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Allocate campaign to publisher for bidding" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#F2860F",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Publisher Submited Bidding" ? (   //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#F2860F",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Publisher Bidding is reviewed by Agency" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#310273",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Campaign is cancel" ? (   //Nilesh-4242-Removing console warnings
																<span
																	style={{
																		color: "#D11124",
																		fontWeight: "600",
																	}}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Campaign lead is decremented" ? (   //Nilesh-4242-Removing console warnings
																<span
																	style={{ color: "green", fontWeight: "600" }}>
																	{unreadAlerts.content}
																</span>
															) : unreadAlerts.content ===
																"Publisher Accept Campaign and IO Generated" ? (  //Nilesh-4242-Removing console warnings
																<span
																	style={{ color: "green", fontWeight: "600" }}>
																	{unreadAlerts.content}
																</span>
															)
															//Rutuja 4948 added orange color for No Leads found for Asset
															: unreadAlerts.content && unreadAlerts.content.startsWith("No Leads found for Asset") ? (  
																<span
																	style={{ color: "#FFA500", fontWeight: "500" }}>
																	{unreadAlerts.content}
																</span>
															)  : (
																<span
																	style={{ color: "black", fontWeight: "600" }}>
																	{unreadAlerts.content}
																</span>
															)}
															&nbsp;&nbsp; |&nbsp;&nbsp;{unreadAlerts.datetime}
														</span>
													</div>
												</div>
												<br />
											</span>
										);
									})}
								</div>
							) : (
								//Nilesh-5068- Add condition for shows no alerts
								(this.state.alertShows === true) ?
								 // Sandeep-task-2998 added bellIcon
								<span style={{ marginLeft: "110px" }}>
								<img
									alt=""  //Nilesh-4242-Removing console warnings
									className="alertImg"
									src="bellIcon.png"
									width="100"
									height="80"></img>
								<span
									className="noAlert"
									style={{
										// color: "black", //Nilesh-4242-Removing console warnings
										fontWeight: "600",
										color: "orange",
										fontSize: "15px",
										marginLeft: "85px",
									}}>
									No Alerts Available!!
								</span>
							</span>
							 : 
							//Nilesh-5068- Add condition for shows no alerts
							 <Table
							   id="myTable"
							   loading={this.state.loading2}
							   className={`${tableCSS} "ant-table-thead"`}  
							   size="small"
							 >						
							</Table>		
							)}

							{/* end of scroll div*/}
						</div>
					</div>
					{/* end of 2nd row */}

					<br />
					<div class="row">
						{this.state.userlogger === "PQA" ? (
							""
						) : (
							// karan-task-3635-replaced the code with component for this section
							<BiddingSection
								tableCSS={tableCSS}
								columns1={columns1}
								RFPColumn={RFPColumn}
								role={user.role}
								newCampaignLength={this.state.newCampaignLength}
								headExampleNewCampaign={this.state.headExampleNewCampaign}
								counterLength={this.state.counterLength}
								headExampleCounter={this.state.headExampleCounter}
								RFPLength={this.state.RFPLength}
								headExampleRFP={this.state.headExampleRFP}
								loading1={this.state.loading1}
								showRFPTBL={this.state.showRFPTBL}
								pageSize1={this.state.pageSize1}
								biddingArray={this.state.biddingArray}
								handleChangePageSize1={this.handleChangePageSize1}
								biddingLink={this.biddingLink}
							/>
						)}
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<div
								style={{
									background: "#ECECEC",
									padding: "10px 15px 10px 15px",
									height: "430px",
								}}
								className="biddingDivPub">
								<p>
									<h3 style={{ fontFamily: "roboto" }}>Review</h3>
								</p>
								<br />
								<p>
									<span class="badgelink">
										<Badge
											count={this.state.feedBackLength}
											overflowCount={10}
											offset={[1, -5]}>
											<Button
												size="default"
												className={this.state.headExample2}
												id="newsLetter"
												onClick={this.creativeLink}>
												Creatives
											</Button>
										</Badge>
									</span>
									{this.state.userlogger === "PC" ||
										this.state.userlogger === "PQA" ? (
										<span class="badgelink">
											<Badge
												count={this.state.leadsLength}
												overflowCount={10}
												offset={[1, -5]}>
												<Button
													size="default"
													id="leads"
													onClick={this.leadLink}
													className={this.state.headExample3}>
													Leads
												</Button>
											</Badge>{" "}
										</span>
									) : (
										""
									)}
									{this.state.userlogger === "PC" ? (  //Nilesh-4242-Removing console warnings
										<span class="badgelink">
											<Badge
												count={this.state.monthCount}
												overflowCount={10}
												offset={[1, -5]}>
												<Button
													size="default"
													id="invoice"
													onClick={this.invoiceLink}
													className={this.state.headExample4}>
													Invoice
												</Button>
											</Badge>{" "}
										</span>
									) : (
										""
									)}

									{this.state.userlogger === "PC" ? (  //Nilesh-4242-Removing console warnings
										<span class="badgelink">
											<Badge
												count={this.state.othersLength}
												overflowCount={10}
												offset={[1, -5]}>
												<Button
													size="default"
													id="others"
													onClick={this.linkOthers}
													className={this.state.headExample5}>
													Others
												</Button>
											</Badge>{" "}
										</span>
									) : (
										""
									)}
								</p>
								{this.state.reviewTabl1 === "reviewCreatives" ? (   //Nilesh-4242-Removing console warnings
									<span>
										<p>
											{" "}
											<Table
												{...this.state.tableState}
												id="myTable"
												bordered
												dataSource={this.state.reviewArray}
												loading={this.state.loading2}
												columns={
													this.state.tabReview === "newsLetter"
														? columns2
														: "" || this.state.tabReview === "leads"
															? columns23
															: ""
												}
												// className="ant-table-thead"
												// className={tableCSS}    
												className={`${tableCSS} "ant-table-thead"`}  //Nilesh-4242-Removing console warnings
												size="small"
												scroll={{ y: 200 }}
												pagination={{ pageSize: this.state.pageSize2 }}></Table>
										</p>
										{/* Sandeep-task-3653-Review section(Creatives and Leads)--View all link added(Agency, pub, Adv) */}
										<div style={{ marginTop: "-56px" }}>
											{this.state.tabReview === "newsLetter" ? (  //Nilesh-4242-Removing console warnings
												this.state.feedBackLength > 10 ? (
													<span style={{ marginLeft: "250px" }}>
														<a
															href="#/"  //Nilesh-4242-Removing console warnings
															onClick={this.openViewAllCreativesReview}>
															View all creatives
															<img
															    alt=""  //Nilesh-4242-Removing console warnings
																style={{
																	marginTop: "-18px",
																	marginLeft: "367px",
																}}
																src="Iconfeather.png"
																width="20"
																height="15"></img>
														</a>
													</span>
												) : (
													""
												)
											) : (
												""
											)}
										</div>
										{/* Sandeep-task-3653-Review section(Creatives and Leads)--View all link added(Agency, pub, Adv) */}
										<div style={{ marginTop: "-56px" }}>
											{this.state.tabReview === "leads" ? ( //Nilesh-4242-Removing console warnings
												this.state.leadsLength > 10 ? (
													<span style={{ marginLeft: "265px" }}>
														<a href="#/"  //Nilesh-4242-Removing console warnings 
														onClick={this.openViewAllLeadsReview}>
															View all leads
															<img
															    alt=""  //Nilesh-4242-Removing console warnings
																style={{
																	marginTop: "-18px",
																	marginLeft: "365px",
																}}
																src="Iconfeather.png"
																width="20"
																height="15"></img>
														</a>
													</span>
												) : (
													""
												)
											) : (
												""
											)}
										</div>
										{/* Sandeep-task-3653-Review section(Creatives and Leads)--View all link added(Agency, pub, Adv) */}
										
								       {/* Nilesh-4242-Removing console warnings */}
										{this.state.tabReview === "leads" ||
											this.state.tabReview === "newsLetter" ? (
											this.state.feedBackLength > 10 ||
												this.state.leadsLength > 10 ? (
												""
											) : (
												<p>
													<div class="row">
														<div
															class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
															style={{ marginTop: "-7px", marginLeft: "5px" }}>
															Records per page:&nbsp;
															<select
																defaultValue={this.state.pageSize2}
																onChange={this.handleChangePageSize2}
																id="pacing1"
																class="input-small"
																className="form-control"
																name="pacing"
																style={{
																	width: "65px",
																	height: "30px",
																	display: "initial",
																}}>
																<option value="5" selected>
																	5
																</option>
																<option value="10">10</option>
																<option value="15">15</option>
																<option value="20">20</option>
															</select>
														</div>
													</div>
												</p>
											)
										) : (
											<p>
												<div class="row">
													<div
														class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
														style={{ marginTop: "-18px", marginLeft: "5px" }}>
														Records per page:&nbsp;
														<select
															defaultValue={this.state.pageSize2}
															onChange={this.handleChangePageSize2}
															id="pacing1"
															class="input-small"
															className="form-control"
															name="pacing"
															style={{
																width: "65px",
																height: "30px",
																display: "initial",
															}}>
															<option value="5" selected>
																5
															</option>
															<option value="10">10</option>
															<option value="15">15</option>
															<option value="20">20</option>
														</select>
													</div>
												</div>
											</p>
										)}
									</span>
								) : (
									""
								)}
								{this.state.reviewTabl1 === "invoiceTab" ? ( //Nilesh-4242-Removing console warnings
									<span>
										<p>
											{" "}
											<Table
												{...this.state.tableState}
												id="myTable1"
												bordered
												dataSource={this.state.reviewArray}
												loading={this.state.loading2}
												columns={
													this.state.tabReview === "invoice"
														? invoiceData
														: "" || this.state.tabReview === "others"
															? columns3
															: ""
												}
												// className="ant-table-thead"
												// className={tableCSS}
												className={`${tableCSS} "ant-table-thead"`}  //Nilesh-4242-Removing console warnings
												size="small"
												scroll={{ y: 200 }}
												pagination={{ pageSize: this.state.pageSize2 }}></Table>
										</p>

										<p>
											<div class="row">
												<div
													class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
													style={{ marginTop: "-60px", marginLeft: "5px" }}>
													Records per page:&nbsp;
													<select
														defaultValue={this.state.pageSize2}
														onChange={this.handleChangePageSize2}
														id="pacing1"
														class="input-small"
														className="form-control"
														name="pacing"
														style={{
															width: "65px",
															height: "30px",
															display: "initial",
														}}>
														<option value="5" selected>
															5
														</option>
														<option value="10">10</option>
														<option value="15">15</option>
														<option value="20">20</option>
													</select>
												</div>
											</div>
										</p>
									</span>
								) : (
									""
								)}
							</div>
						</div>
					</div>
					{/* end of bidding and review table */}
				</div>
				{/* end of container-fluid */}
				<div class="container pull-right" id="chatbox">
					<div
						class="row chat-window col-xs-6 col-md-5 pull-right"
						id="chat_window_1">
						<div class="col-xs-12 col-md-12">
							<div
								class="panel panel-default"
								style={{ display: "initial", border: "0px solid transparent" }}>
								<div class="panel-heading top-bar">
									<div class="col-md-8 col-xs-8">
										<h3 class="panel-title">
											<span class="glyphicon glyphicon-comment"></span>
											&nbsp;&nbsp;Messages
										</h3>
									</div>
									<div class="col-md-4 col-xs-4" style={{ textAlign: "right" }}>
										<a>
											<span
												id="minim_chat_window"
												style={{ color: "white" }}
												class="glyphicon glyphicon-arrow-down icon_minim"></span>
										</a>
									</div>
								</div>
								{this.state.unreadMessagesLength == "0" ? (
									<div
										id="messagebody"
										class="panel-body msg_container_base panel-collapsed">
										<div class="row msg_container base_receive">
											<div class="col-md-12 col-xs-12">
												<div class="messages msg_receive">
													<p style={{ fontSize: "larger", color: "orange" }}>
														No Message From Agency
													</p>
												</div>
											</div>
										</div>
									</div>
								) : (
									<div
										id="messagebody"
										class="panel-body msg_container_base panel-collapsed">
										{this.state.unreadMessages.map((unreadMessages, i) => {
											return (
												<div class="row msg_container base_receive">
													<div class="col-md-3 col-xs-3 avatar">
														<Avatar
															style={{
																backgroundColor: "#007cc3",
																verticalAlign: "middle",
															}}
															size="large">
															{unreadMessages.author.match(/\b\w/g).join("")}
														</Avatar>
													</div>
													<div class="col-md-9 col-xs-9">
														<div class="messages msg_receive">
															<p key={i}
																className="panelCampInfo"
																style={{ fontSize: "10px" }}>
																(Campaign Id:
																<a
																	href="#/"  //Nilesh-4242-Removing console warnings
																	onClick={(e) =>
																		this.displayCampaignSpecificationMsg(
																			e,
																			unreadMessages
																		)
																	}>
																	{unreadMessages.parentCampID
																		? unreadMessages.parentCampID
																		: unreadMessages.campID}
																</a>
																|Name:{unreadMessages.campaignName}

																{console.log(JSON.stringify(unreadMessages))})
															</p>
															{/* kiran-4657-added overflowWrap property */}

															{/* {this.state.unreadMessages.map((msg, i) => (
																<p key={i}
																className="panelCampContent"
																style={{ fontSize: "9px", overflowWrap: "break-word" }}>
																<b>{msg.content}</b>
															</p>
															))} */}
															<p key={i}
																className="panelCampContent"
																style={{ fontSize: "9px", overflowWrap: "break-word" }}>
																<b>{unreadMessages.content}</b>
															</p>
															<div
																className="panelCampDate"
																style={{ fontSize: "9px", float: "right" }}>
																<p>{unreadMessages.datetime}</p>
															</div>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								)}
								{/* //end of msg  */}
							</div>
						</div>
					</div>
				</div>
				{/* 4440-Footer */}
				<Footer />
			</div>
			//end of first div
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
NewPublisherDashboard.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(NewPublisherDashboard)
);
