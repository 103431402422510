/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Somnath Keswad
@Creation Date:06-12-2019
@Description:Display Landing page tracked Data
*/

import React from "react";
import Navigation from "../layouts/navPage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { css } from "emotion";
import Footer from "../layouts/footer";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Table } from "antd";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Somnath Task-3942, added default header

const Swal = require("sweetalert2"); //Somnath Task-3203, get sweetalert from sweetalert2
const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "12px",
		fontWeight: "500",
	},
	"& thead > tr >th": {
		border: "1px solid black",
		color: "white",
	},
});
class LPDataTracking extends React.Component {
	constructor() {
		super();
		this.state = {
			liveLead: [],
			searchArray: [],
			agencyID: "",
			pageSize2: 5,
			downloadFiles: [], //Somnath Task-3203, Declare array to download file data
			tableState: { size: "small" },
		};
		this.handleSearch = this.handleSearch.bind(this);
		this.searchCampID = this.searchCampID.bind(this);
		this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
		this.handleFileDownload = this.handleFileDownload.bind(this); //Somnath Task-3203, bind handleFileDownload function
	} // end of constrcutor
	/**
	 * @author Somnath Keswad
	 * @param  Description Search campID
	 * @return Description return searched object.
	 */
	searchCampID(campID) {
		//kiran-2834-added toString() and changed == to ===
		if (campID === "" || campID === undefined || campID == null) {
			this.setState({ liveLead: this.state.searchArray });
		} else {
			var searchElement = this.state.searchArray.filter(function (a) {
				return a.campID === campID;
			});
			this.setState({ liveLead: searchElement });
		}
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description Search Element from array
	 * @return Description return searched object.
	 */
	handleSearch(e) {
		var search = e.target.value;
		var searchElement = this.state.searchArray.filter(function (ele) {
			var element = Object.values(ele);
			element = element.toString();
			element = element.toLowerCase();
			element = element.replace(",", " ");
			search = search.toLowerCase();
			var searchResult = element.includes(search);
			return searchResult;
		});
		this.setState({ liveLead: searchElement });
	} // End of handleSearch

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			const { user } = this.props.auth;
			this.setState({ agencyID: user.id });
			fetch("leadAPI/getLiveLead")//Somnath Task-3942, Remove agencyID
				.then((res) => res.json())
				.then((liveLead) =>
					this.setState({ liveLead: liveLead, searchArray: liveLead })
				)
				.catch(function (err) {
					console.log(err);
				});
		} // End of Else of authentication
	} // end of componentDidMount()

	handleChangePageSize2(e) {
		var pageSize2 = e.target.value;
		this.setState({ pageSize2: pageSize2 });
	}
	/**
	 * @author Somnath Keswad
	 * @param  Description Task-3203, download captured document in pdf format
	 * @return return buffered data.
	 */
	downloadCapturedDocument(e) {
		e.preventDefault();
		let leadInfoID = e.target.id;
		let lpCaptureDocName = e.target.name;
		this.setState({ lpCaptureDocName });
		// const { user } = this.props.auth; //Somnath Task-3203, Get user from login
		let data = { leadInfoID }; //Somnath Task-3942, Remove user
		fetch("/leadAPI/getCapturedDocument", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((r) => r.blob())
			.then((downloadFiles) => {
				this.setState({ downloadFiles });
				Swal.fire({
					text: "Email sent successfully with GDPR certificate.",
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
				});
			})
			.then(this.handleFileDownload);
	} // End of downloadCapturedDocument

	/**
	 * @author Somnath Keswad
	 * @param  Description Task-3203, download captured document
	 * @return Download pdf file.
	 */
	handleFileDownload(blob) {
		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/pdf",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}
		const data = window.URL.createObjectURL(newBlob);
		let link = document.createElement("a");
		link.href = data;
		link.download = this.state.lpCaptureDocName;
		link.click();
	} // End of handleFileDownload

	render() {
		const LeadData = [
			{
				title: "Lead Info ID",
				dataIndex: "leadInfoID",
				key: "leadInfoID",
				width: "8%",
				align: "center",
				render: (text, record) => (
					<div>
						<span>{record.leadInfoID}</span>
					</div>
				),
			},
			{
				title: "Pub_ID",
				dataIndex: "pID",
				key: "pID",
				width: "6%",
				align: "center",
				render: (text, record) => (
					<div>
						<span>{record.pID}</span>
					</div>
				),
			},
			{
				title: "Camp_ID",
				dataIndex: "campID",
				key: "campID",
				width: "8%",
				align: "center",

				render: (text, record) => (
					<div>
						<span>{record.campID}</span>
					</div>
				),
			},
			{
				title: "Lead Interaction Date",
				dataIndex: "leadInteractionDate",
				key: "leadInteractionDate",
				align: "center",
				width: "12%",
				render: (text, record) => (
					<div>
						<span>{record.leadInteractionDate}</span>
					</div>
				),
			},
			{
				title: "First Name",
				dataIndex: "firstName",
				key: "firstName",
				align: "center",
				width: "12%",
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.firstName}</span>
					</div>
				),
			},
			{
				title: "Last Name",
				dataIndex: "lastName",
				key: "lastName",
				align: "center",
				width: "12%",
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.lastName}</span>
					</div>
				),
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email",
				align: "center",
				width: "15%",

				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.email}</span>
					</div>
				),
			},
			{
				title: "Job Title",
				dataIndex: "jobTitle",
				key: "jobTitle",
				align: "center",
				width: "9%",
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.jobTitle}</span>
					</div>
				),
			},
			// {
			//   title: 'City',
			//   dataIndex: 'city',
			//   key: 'city',
			//   align:'center',
			//   width:'9%',

			//   render: (text,record) =>
			//   <div >
			//       <span style={{fontSize:'12px'}}>{record.city}</span>
			//   </div>
			//   },
			// {
			//   title: 'State',
			//   dataIndex: 'state',
			//   key: 'state',
			//   align:'center',
			//   width:'9%',

			//   render: (text,record) =>
			//   <div >
			//       <span style={{fontSize:'12px'}}>{record.state}</span>
			//   </div>
			//   },
			{
				title: "Country",
				dataIndex: "country",
				key: "country",
				align: "center",
				width: "9%",

				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.country}</span>
					</div>
				),
			},
			{
				title: "Asset Name",
				dataIndex: "assetName",
				key: "assetName",
				align: "center",
				width: "15%",

				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.assetName}</span>
					</div>
				),
			},
			{
				title: "IP",
				dataIndex: "IP",
				key: "IP",
				width: "10%",
				align: "center",

				render: (text, record) => (
					<div>
						<span>{record.IP}</span>
					</div>
				),
			},
			{
				title: "LP Capture Document", //Somnath Task-3203, Add LP Capture Document Column
				dataIndex: "lpCaptureDocName",
				key: "lpCaptureDocName",
				width: "10%",
				align: "center",
				render: (text, record) => (
					<div style={{ width: "111px" }} class="dot">
						<span
							style={{
								fontSize: "12px",
								wordWrap: "break-word",
								height: "5%",
							}}>
								{/* kiran-2834-removing console warning-added "#/" */}
							<a
								href="#/"
								onClick={this.downloadCapturedDocument.bind(this)}
								id={record.leadInfoID}
								name={record.lpCaptureDocName}
								style={{ fontSize: "12px" }}>
								{record.lpCaptureDocName}
							</a>
						</span>
					</div>
				),
			},
			//   {
			//     title: 'Zone',
			//     dataIndex: 'zone',
			//     key: 'zone',
			//     width: '15%',
			//     align:'center',
			//  render: (text,record) =>
			// <div><span>{record.zone}</span></div>
			// },
			//   {
			//     title: 'Timestamp',
			//     dataIndex: 'timestamp',
			//     key: 'timestamp',
			//     width: '15%',
			//     align:'center',
			//  render: (text,record) =>
			// <div><span>{record.timestamp}</span></div>
			// },
			//     {
			//       title: 'Longitude',
			//       dataIndex: 'longitude',
			//       key: 'longitude',
			//       width: '15%',
			//       align:'center',
			//    render: (text,record) =>
			//   <div><span>{record.longitude}</span></div>
			//   },
			//   {
			//     title: 'Latitude',
			//     dataIndex: 'latitude',
			//     key: 'latitude',
			//     width: '15%',
			//     align:'center',
			//  render: (text,record) =>
			// <div><span>{record.latitude}</span></div>
			// },
		];

		function onChange(pagination, filters, sorter) {
			// console.log("params", pagination, filters, sorter);
		}
		return (
			<div>
				<Navigation />
                       {/* kiran -task 3873- increased padding top for spacing */}
				<div
					class="container-fluid"
					style={{ paddingTop: "110px", marginBottom: "60px" }}>
					<div class="row">
						<div class="col-sm-12" align="center">
						{/* kiran-2834-removing console warning-added href="newdashboard" */}
							<a
							href="newdashboard"
								onClick={() =>
									this.props.history.push("/newdashboard", {
										agencyID: this.state.agencyID,
									})
								} //karan-task-3684-replace query params
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back to Dashboard"
								/>
							</a>
							<label
								align="center"
								id="labelheading"
								style={{ color: "#056eb8", fontSize: "20px" }}>
								Landing Page Data
							</label>
							{/* <Input placeholder="Search here" onChange={this.handleSearch} id='Search' name='Search' style={{width:'140px',float:'right'}} /> */}
						</div>
					</div>
					<p></p>
					<div class="row" style={{ paddingLeft: "18px" }}>
						{/* <Search placeholder="Search campID here" onSearch={value => this.searchCampID(value)} enterButton="Search"
      size="small" style={{width:'200px',float:'left'}}/> */}

						<span>
							<input
								className="search"
								id="search"
								type="text"
								name="search"
								placeholder="Search by any keyword."
								style={{
									backgroundImage: "url(searchIcon.png)",
									backgroundPosition: "15px 6px",
									backgroundSize: "15px 15px",
									backgroundRepeat: "no-repeat",
									width: "280px",
									height: "30px",
									marginTop: "35px",
									fontSize: "10px",
									border: "1px solid lightGray",
									padding: "12px 20px 12px 34px",
									borderRadius: "5px",
								}}
								onChange={this.handleSearch}
								onPressEnter={this.handleSearch}></input>
						</span>
						{/* <Input placeholder="Search here" onChange={this.handleSearch} id='Search' name='Search' style={{width:'200px',float:'left'}} /> */}
					</div>
					<br />
					<div className="card card-signin my-1">
						<div class="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div class="table-responsive">
									<p>
									{/* kiran-2834-removing console warning-changed classname */}
										<Table
											{...this.state.tableState}
											id="myTable"
											bordered
											dataSource={this.state.liveLead}
											loading={this.state.loading2}
											onChange={onChange}
											columns={LeadData}
											// className="ant-table-thead"
											// className={tableCSS}
											className={`${tableCSS} "ant-table-thead"`}
											scroll={{ x: 1750, y: 280 }}
											pagination={{ pageSize: this.state.pageSize2 }}></Table>
									</p>
									<p>
										<div class="row">
											<div
												class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
												style={{ marginTop: "-60px" }}>
												&nbsp;&nbsp;&nbsp;Records per page:&nbsp;
												<select
													defaultValue={this.state.pageSize2}
													onChange={this.handleChangePageSize2}
													id="pacing1"
													class="input-small"
													className="form-control"
													name="pacing"
													style={{
														width: "65px",
														height: "30px",
														display: "initial",
													}}>
													<option value="5" selected>
														5
													</option>
													<option value="10">10</option>
													<option value="15">15</option>
													<option value="20">20</option>
												</select>
											</div>
										</div>
									</p>
								</div>
								{/*end of row*/}
							</div>
							{/*end of card-body*/}
						</div>
						{/*Card-body */}
					</div>
					{/*Card */}
				</div>
				{/*Container */}

				<Footer />
			</div>
		);
	} // end of renders
} // end of class

LPDataTracking.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(LPDataTracking)
);
