/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanjana Shende
@Creation Date:17-07-2019
@Description:UI for lead download 
*/
import React from "react";
import Navigation from "../layouts/navPage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { Table } from "mdbreact";
import { saveAs } from "file-saver";
import Footer from "../layouts/footer";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3767-added default header

const xlsx = require("xlsx");

//const clientNameArray = [{id:"1",name:"MachBizz"},{id:"2",name:"APSSMedia"},,{id:"3",name:"CloudPaper"},{id:"4",name:"IBM"}];
class LeadDownload extends React.Component {
	constructor() {
		super();
		this.state = {
			errors: {},
			value: null,
			clientName: "",
			clientNameDetails: [],
			clientNameArray: [],
			downloadFiles: [],
			failureMsg: "",
		};

		this.handleChange = this.handleChange.bind(this);
		this.selectClientName = this.selectClientName.bind(this);
		this.showDownloadLeads = this.showDownloadLeads.bind(this);
		//this.handleDownloadClientLeadFile=this.handleDownloadClientLeadFile.bind(this);
	} // end of constrcutor

	/**
	 * @author Narendra Phadke
	 * @param  Description handle to get client details of agency
	 * @return Description return successfully get details
	 */
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			const { user } = this.props.auth;
			var userID = user.id;
			//Client Name from db-need for client list
			fetch("/campaign/clientNameDetails") // karan-task-3767-added default header
				.then((res) => res.json())
				.then((clientNameDetails) => {
					this.setState({ clientNameDetails: clientNameDetails });
					// alert(JSON.stringify(clientNameDetails));
					// var clientSelect = [];
					//create array in id and name format
					var i;
					for (i = 0; i < clientNameDetails.length; i++) {
						this.state.clientNameArray.push({
							id: i,
							name: clientNameDetails[i],
						});
					}
					// alert("Client select="+JSON.stringify(this.state.clientNameArray));
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}
	handleChange(e) {
		let fields = this.state;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
	} //handleChange()

	/**
	 * @author Narendra Phadke
	 * @param  Description handle to select client name
	 * @return Description return successfully select details
	 */
	selectClientName(value) {
		console.log("Vals", value);
		var clientNamevalue = [];
		clientNamevalue.push(value.name);
		//alert(JSON.stringify(clientNamevalue));
		this.setState({ value, clientName: clientNamevalue });
		// let clientName = e.target.name;
		// alert("Client name="+clientName);
		// this.setState({
		//   clientName: clientName
		// })
	} //selectOption()

	showDownloadLeads(e) {
		e.preventDefault();

		if (this.validateForm() === false) {
			//alert("Form Not Valid");
			return;
		} else {
			//alert("Form is Valid");
			const { user } = this.props.auth;
			var campID = this.state.clientCampID;
			var clientName = this.state.clientName;
			//Download client wise excel file
			let data = {
				campID: campID,
				clientName: clientName,
				userID: user.id,
			};
			fetch(
				"agency/downloadClientFormatLead?campID=" +
					campID +
					"&clientName=" +
					clientName +
					"&userID=" +
					user.id
			)
				// fetch("agency/downloadClientFormatLead",{
				//   method: 'POST',
				//   headers: { "Content-Type": "application/json" },
				//   body:JSON.stringify(data)
				//   })
				.then((response) => response.json())
				.then((downloadFiles) => {
					this.setState({ downloadFiles: downloadFiles });

					if (downloadFiles.success === true) {
						// alert("No Data Exists");

						this.setState({ failureMsg: "No Data Exists" });
					} else {
						this.setState({ failureMsg: "" });
						//
						/* make the worksheet */
						var ws = xlsx.utils.json_to_sheet(downloadFiles);
						/* add to workbook */
						var wb = xlsx.utils.book_new();
						xlsx.utils.book_append_sheet(wb, ws, "Lead");

						// /* write workbook */
						var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
						var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" });
						/* generate a download */
						function s2ab(s) {
							var buf = new ArrayBuffer(s.length);
							var view = new Uint8Array(buf);
							for (var i = 0; i != s.length; ++i)
								view[i] = s.charCodeAt(i) & 0xff;
							return buf;
						}
						var fileName = campID + "-" + clientName + ".xlsx";
						saveAs(
							new Blob([s2ab(buf)], { type: "application/octet-stream" }),
							fileName
						);
					}
				})
				.catch(function (err) {
					console.log(err);
				});

			/** End of handle File Download */
		}
	} // showDownloadLeads()

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let IsFormValid = true;

		let clientCampIDValidate = true;
		let clientNameValidate = true;

		//clientCampID validation
		if (this.state.clientCampID == undefined) {
			//alert("No value:"+this.state.clientCampID);
			errors["commonError"] = <li>Please Enter Advertiser Campaign ID</li>;
			clientCampIDValidate = false;
		} else {
			if (this.state.clientCampID == "") {
				//alert("has a value but null:"+this.state.leadAllocation);
				errors["commonError"] = <li>Please Enter Advertiser Campaign ID</li>;
				clientCampIDValidate = false;
			}
			if (
				isNaN(this.state.clientCampID) ||
				this.state.clientCampID.includes(".")
			) {
				//alert("has a value but null:"+this.state.leadAllocation);
				errors["commonError"] = <li>Please enter only numerical values</li>;
				clientCampIDValidate = false;
			}
		}

		// Client Name
		if (this.state.clientName.length === 0) {
			clientNameValidate = false;
			//alert("Please Select clientName ");

			errors["commonError1"] = <li>Please Select clientName </li>;
			// alert("required");
		} else {
			clientNameValidate = true;
			// formIsValid = true;
			//alert("Has clientName")
		}

		if (clientCampIDValidate == false || clientNameValidate == false) {
			IsFormValid = false;
			this.setState({ errors: errors });
		} else {
			IsFormValid = true;
			this.setState({ errors: "" });
		}

		//alert("IsFormValid===>"+IsFormValid);

		return IsFormValid;
	} // end of validate

	render() {
		const asterisk = {
			color: "red",
		};
		return (
			<div>
				<Navigation />
				<h1 style={{ color: "#056eb8" }}>Download Leads Advertiser Wise</h1>
				<form method="" name="" onSubmit={this.showDownloadLeads}>
					<div
						class="container-fluid"
						style={{ paddingTop: "90px", marginBottom: "60px" }}>
						<div className="card card-signin my-1">
							<div className="card-body">
								<span>
									<center>
										<h3 style={{ color: "#056eb8" }}>
											Download Leads Advertiser wise
										</h3>
									</center>
								</span>

								<div
									className="successMsg"
									style={{ fontSize: "20px", color: "Red" }}>
									<center>
										{" "}
										<h5 style={{ color: "red" }}>
											{this.state.failureMsg}
										</h5>{" "}
									</center>
								</div>
								<div
									className="errorMsg"
									style={{ fontSize: "14px", color: "red" }}>
									{this.state.errors.commonError}
									{this.state.errors.commonError1}
								</div>
								<div class="row">
									<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ">
										<label id="label">Advertiser Campaign ID</label>
										<span style={asterisk}>*</span>
										<input
											value={this.state.clientCampID}
											onChange={this.handleChange}
											type="text"
											id="clientCampID"
											name="clientCampID"
											className="form-control"
											class={this.state.inputClassClientCampID}
										/>
									</div>

									<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ">
										<label id="label">Advertiser Name</label>
										<span style={asterisk}>*</span>
										<Picky
											value={this.state.value}
											options={this.state.clientNameArray}
											onChange={this.selectClientName}
											open={false}
											valueKey="id"
											labelKey="name"
											multiple={false}
											includeSelectAll={true}
											includeFilter={true}
											dropdownHeight={600}
											id="clientName"
											name="clientName"
											class={this.state.inputClass}
										/>
									</div>

									<div
										class="col-xs-12 col-sm-12 col-md-4 col-lg-4 "
										style={{ paddingTop: "25px" }}>
										<button
											class="btn text-uppercase add-button"
											style={{ backgroundColor: "#2196F3", color: "white" }}
											disabled={this.state.disableButton}
											type="submit">
											Download Leads
										</button>
									</div>
								</div>
								{/* end of row */}
							</div>
							{/*Card-body */}
						</div>
						{/*Card */}
					</div>
					{/*Container */}
				</form>
				<Footer />
			</div>
		);
	} // end of renders
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
LeadDownload.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(LeadDownload)
);
