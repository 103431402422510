/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Raunak Thakker,shivani pathak
 *@fileName :RfpCampaignAllocation.js
 *Desc:  Rfp allocating to publisher
 */

 import React, { Component } from "react";
 import PropTypes from "prop-types";
 import { connect } from "react-redux";
 import { logoutUser } from "../login/authentication";
 import { withRouter } from "react-router-dom";
 import Navigation from "../layouts/navPage";
 import "./rfpCampaignAllocation.css";
 import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //sonali-task-3716-added default header

 import { FaArrowAltCircleLeft } from "react-icons/fa";
 import { Input, Checkbox, Icon, Progress, DatePicker } from "antd";
 const europeRegion = [
	 "Eastern Europe",
	 "Northern Europe",
	 "Southern Europe",
	 "Western Europe",
 ];
 const queryString = require("query-string");
 const Swal = require("sweetalert2");
 var publisherCount = 0;
 let result = [];
 var displayresult = [];
 var displaycpl = [];
 class RfpCampaignAllocation extends Component {
	 constructor() {
		 super();
		 this.state = {
			 campaignDetail: [],
			 publisherDetail: [],
			 finalPublisherList: [],
			 publisherInfoArray:[],//4120-Sonali-added array
			 region: false,
			 gdprStatus: false,
			 campID: "",
			 displayAllocation: true,
			 divDisplay: "none",
			 AllocatedLeads: true,
			 Allocation: true,
			 CPL: true,
			 errors: {},
			 alertDisplay: "none",
			 show: false,
			 success: "",
			 puberror: "",
			 allocerrors: "",
			 reerrors: "",
			 dateerrors: "",
			 showModel: false,
		 };
		 this.isGDPRPUB = this.isGDPRPUB.bind(this);
		 this.myFunction = this.myFunction.bind(this);
	 }
 
	 /**
	  * @author Somnath Keswad
	  * @param  Description handle the Get campaign & publisher data for allocation
	  * @return Description return response in json array
	  */
	 componentDidMount() {
		 if (!this.props.auth.isAuthenticated) {
			 this.props.history.push("/userLogin");
		 } else {
			  //sonali-3716-VAPT changes
			  if (this.props.location.state !== undefined) {
				const {
				campID,proposalID
				} = this.props.location.state;
				this.setState({campID,proposalID})
			}
			this.setState({campID:this.props.location.state.campID,proposalID:this.props.location.state.proposalID})
			
			//  var parsed = queryString.parse(this.props.location.search);
			//  var campID = parsed.campID;
			//  var proposalID = parsed.proposalID;
			 // Get Campaign Info from campaign Table
			 fetch("requestForProposal/getCampaignInfo?campID=" + this.props.location.state.campID)
				 .then((res) => res.json())
				 .then((campaignDetail) => {
					 this.setState({ campID: this.props.location.state.campID, campaignDetail: campaignDetail });
					 if (campaignDetail.length > 0) {
						 let region = campaignDetail[0].region;
						 let regionArray = [];
						 var str_array = region.split(",");
						 for (let i = 0; i < str_array.length; i++) {
							 regionArray.push(str_array[i]);
						 }
						 for (let j = 0; j < regionArray.length; j++) {
							 if (
								 regionArray[j] == "Europe" ||
								 europeRegion.includes(regionArray[j])
							 ) {
								 this.setState({
									 region: true,
									 displayAllocation: false,
									 gdprStatus: true,
								 });
							 }
						 }
					 }
				 })
				 .catch(function (err) {
					 console.log(err);
				 });
			 // Get Publisher Details for allocation.
 
			 fetch(
				 "requestForProposal/biddingPublisherForAllocation?campID=" +
				 this.props.location.state.campID +
					 "&proposalID=" +
					 this.props.location.state.proposalID
			 )
				 .then((res) => res.json())
				// 4120-Sonali-added publisherInfoArray
				 .then((publisherDetail) => {
					 this.setState({
						 publisherDetail,
						 finalPublisherList: publisherDetail,publisherInfoArray:publisherDetail
					 });
				 })
				 .catch(function (err) {
					 console.log(err);
				 });
		 }
	 } // End of componentDidMount
 
	 /**
	  * @author Somnath Keswad
	  * @param  Description handle the mearge data in array on handlechange
	  * @return Description return updated array
	  */
	 dynamicAllocation(i, e) {
		 const { name, value } = e.target;
		 let publisherDetail = [...this.state.publisherDetail];
		 //Saurabh-task-3792-Added IF Else Statment for PO Validation
		 if(e.target.value===""){
			 publisherDetail[i] = {
				 ...publisherDetail[i],
				 [name]: value,
				 isAllocate: "No",
			 };
		 }
		 else{
		 
		 publisherDetail[i] = {
			 ...publisherDetail[i],
			 [name]: value,
			 isAllocate: "Yes",
		 };
	 }
		 //Sandeep-task-3530-added new key for poNumber
		 this.setState({ publisherDetail	});
	 } // End of dynamicAllocation
 
	 /**
	  * @author Somnath Keswad
	  * @param  Description handle the single campaign multiple publisher allocatation
	  * @return Description return successfully allocation to publisher message
	  */
 
	 
	 submitAllocation(e) {
		 e.preventDefault();
		 let errors = {},publisherCount=0;
		 this.setState({errors})//Somnath Task-3829, setState blank error obj to avoid refresh page
		 let spanDisplay = "none";
		 // let fields = this.state.fields;
		 let formIsValid = false;
		 let date = false;
		 let povalidate = false;
		 let cpl = false;
		 let lead = false;
		 // let success = {};
		 let puberror = {};
		 let valid = false;
		 let rebudget = false;
		 var checkAllocation = false;
		 let allocerrors = {};
		 let reerrors = {};
		 let dateerrors = {};
		 var displayallocatedLead = [];//Sonali-4120-added displayallocatedLead in local scope
		 let campaignDetail = this.state.campaignDetail;
		 result = Array.from(
			 new Set(this.state.publisherDetail.map((p) => p.pID))
		 ).map((pID) => {
			 return {
				 pID: pID,
				 publisherName: this.state.publisherDetail.find((p) => p.pID === pID)
					 .publisherName,
				 startDate: this.state.publisherDetail.find((p) => p.pID === pID)
					 .startDate,
				 endDate: this.state.publisherDetail.find((p) => p.pID === pID).endDate,
				 allocatedLead: this.state.publisherDetail.find((p) => p.pID === pID)
					 .allocatedLead,
				 cpl: this.state.publisherDetail.find((p) => p.pID === pID).CPL != null //Saurabh - 3813 -Added Condtion for CPL
				 ? this.state.publisherDetail.find((p) => p.pID === pID).CPL
				 : this.state.publisherDetail.find((p) => p.pID === pID).campCPL,
				 firstLeadDeliveryDate: this.state.publisherDetail.find(
					 (p) => p.pID === pID
				 ).firstLeadDeliveryDate,
				 poNumber: this.state.publisherDetail.find((p) => p.pID === pID)
					 .poNumber,
				 //Sandeep-task-3530-added code for poNumber
			 };
		 });
		 // console.warn("New Array of the created after ====>"+JSON.stringify(result));
		 var totalLead = campaignDetail[0].allocatedLead;
 
		 if (
			 totalLead === "" ||
			 totalLead === undefined ||
			 totalLead === "null" ||
			 totalLead === null
		 ) {
			 totalLead = 0;
		 }
 
		 var totalAllocate = campaignDetail[0].leadAllocation;
 
		 var totalBudget = campaignDetail[0].budget;
 
		 var totalRemainingBudget = campaignDetail[0].remainingBudget;
		 if (
			 totalRemainingBudget === "" ||
			 totalRemainingBudget === undefined ||
			 totalRemainingBudget === "null" ||
			 totalRemainingBudget === null
		 ) {
			 totalRemainingBudget = totalBudget;
		 }
 
		 var totalstartDate = campaignDetail[0].startDate;
 
		 var totalendDate = campaignDetail[0].endDate;
 
		 var totalPending = campaignDetail[0].pendingLead;
		 var pendingAllocation = parseInt(totalPending);
		 // var RejectedLead = document.getElementById("RejectedLead").value;
		 var sum = 0;
		 var num;
		 var multiply = 0;
		 var num1;
		 var num2;
		 var addmulti = 0;
		 var add = totalLead - totalAllocate;
		 var dynamiclength = result.length;

		 for (let i = 0; i < dynamiclength; i++) {
			 if (
				 result[i].allocatedLead == "" ||
				 result[i].allocatedLead == undefined ||
				 result[i].allocatedLead == null
			 ) {
				 if (publisherCount == 0) {
					 puberror["one"] = (
						 <li>
							 Please enter valid lead allocation for atleast one publisher
						 </li>
					 );
					 this.state.publisherDetail[i].isAllocate=false;//Task-4120-Sonali-making isAllocate false
					 this.setState({
						 puberror: puberror,
						 allocerrors: "",
						 errors: errors,
						 newsuccess: "",
						 alertWidth: "1000px",
						 alertBorder: "1px solid red",
						 alertAlign: "center",
						 alertDisplay: "block",
						 spanSpaceDisplay: spanDisplay,
					 });
					 document.body.scrollTop = 0;
					 document.documentElement.scrollTop = 0;
				 } // End of if
			 } // End of first if
			 else {
				 publisherCount++;
				 puberror["one"] = "";
				 this.setState({ puberror: "", alertBorder: "0px solid white" });
				 if (result[i].startDate <= result[i].endDate) {
					 date = true;
					 displayresult.splice(i, 1);
				 } else {
					 date = false;
					 displayresult[i] = result[i].publisherName;
 
					 errors["endDate"] =
						 "Please enter valid end date for " + displayresult;
					 this.setState({
						 newsuccess: "",
						 puberror: "",
						 dateerrors: "",
						 errors: errors,
						 alertBorder: "1px solid red",
						 alertWidth: "1000px",
						 alertAlign: "center",
						 alertDisplay: "block",
						 spanSpaceDisplay: spanDisplay,
					 });
					 return;
				 }
				 if (result[i].endDate > totalendDate) {
					 date = false;
					 displayresult[i] = result[i].publisherName;
 
					 dateerrors["date"] =
						 "Allocation end date cannot be greater than campaign end  date for " +
						 displayresult[i];
					 this.setState({
						 dateerrors: dateerrors,
						 newsuccess: "",
						 puberror: "",
						 errors: "",
						 alertBorder: "1px solid red",
						 alertWidth: "1000px",
						 alertAlign: "center",
						 alertDisplay: "block",
						 spanSpaceDisplay: spanDisplay,
					 });
					 return;
				 } else {
					 date = true;
				 }
				 if (result[i].startDate < totalstartDate) {
					 date = false;
					 displayresult[i] = result[i].publisherName;
					 dateerrors["date"] =
						 "Allocation start date cannot be less than campaign start date for " +
						 displayresult[i];
 
					 this.setState({
						 dateerrors: dateerrors,
						 newsuccess: "",
						 puberror: "",
						 errors: "",
						 alertBorder: "1px solid red",
						 alertWidth: "1000px",
						 alertAlign: "center",
						 alertDisplay: "block",
						 spanSpaceDisplay: spanDisplay,
					 });
					 return;
				 } else {
					 date = true;
				 }
				 if (result[i].firstLeadDeliveryDate > result[i].endDate) {
					 date = false;
					 displayresult[i] = result[i].publisherName;
					 dateerrors["date"] =
						 "First lead delivery date can't be greater than end date " +
						 displayresult[i];
					 this.setState({
						 dateerrors: dateerrors,
						 newsuccess: "",
						 puberror: "",
						 errors: "",
						 alertBorder: "1px solid red",
						 alertWidth: "1000px",
						 alertAlign: "center",
						 alertDisplay: "block",
						 spanSpaceDisplay: spanDisplay,
					 });
					 return;
				 } else {
					 date = true;
				 }
				 if (result[i].firstLeadDeliveryDate < result[i].startDate) {
					 date = false;
					 displayresult[i] = result[i].publisherName;
					 dateerrors["date"] =
						 "First lead delivery date can't be less than start date " +
						 displayresult[i];
					 this.setState({
						 dateerrors: dateerrors,
						 newsuccess: "",
						 puberror: "",
						 errors: "",
						 alertBorder: "1px solid red",
						 alertWidth: "1000px",
						 alertAlign: "center",
						 alertDisplay: "block",
						 spanSpaceDisplay: spanDisplay,
					 });
					 return;
				 } else {
					 date = true;
				 }
				 var pattern = new RegExp(/^[1-9]+[0-9]*$/);
				 if (!pattern.test(result[i].allocatedLead)) {
					 lead = false;
					 displayallocatedLead[i] = result[i].publisherName + "  ";
					 errors["allocatedLead"] = (
						 <li>
							 Please enter only numerical value for {displayallocatedLead}{" "}
						 </li>
					 );
					 displayallocatedLead = [displayallocatedLead[i]];
					 var a = displayallocatedLead.includes("displayallocatedLeads[i]");
					 this.setState({
						 newsuccess: "",
						 puberror: "",
						 errors: errors,
						 dateerrors: "",
						 allocerrors: "",
						 alertBorder: "1px solid red",
						 alertWidth: "1000px",
						 alertAlign: "center",
						 alertDisplay: "block",
						 spanSpaceDisplay: spanDisplay,
					 });
					 return;
				 } else {
					 lead = true;
					 num = parseInt(result[i].allocatedLead);
					 sum += num;
					 displayallocatedLead.splice(i, 1);
				 }
				 /**validations for cpl */
				 // cpl validations
				 var patterndecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/); //decimal
				 var patternnonzero = new RegExp(/^[1-9]+[0-9]*$/); //numbers except zero
				 var testval1 = result[i].cpl;
 
				 var testval = parseInt(testval1);
 
				 if (testval1 == "" || testval1 == null || testval1 == undefined) {
					 cpl = false;
					 displaycpl[i] = result[i].publisherName;
 
					 errors["cpl"] =
						 "Please check CPL value. CPL should follow below criteria.";
					 errors["cpl1"] = <li>CPL should be only number.</li>;
					 errors["cpl2"] = <li>CPL should be greater than zero.</li>;
					 errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;
					 this.setState({
						 dateerrors: "",
						 newsuccess: "",
						 puberror: "",
						 allocerrors: "",
						 errors: errors,
						 alertBorder: "1px solid red",
						 alertWidth: "1000px",
						 alertAlign: "center",
						 alertDisplay: "block",
						 spanSpaceDisplay: spanDisplay,
					 });
					 return;
				 } else {
					 if (testval1 == 0) {
						 cpl = false;
						 displaycpl[i] = result[i].publisherName;
 
						 errors["cpl"] =
							 "Please check CPL value. CPL should follow below criteria.";
						 errors["cpl1"] = <li>CPL should be only number.</li>;
						 errors["cpl2"] = <li>CPL should be greater than zero.</li>;
						 errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;
						 this.setState({
							 dateerrors: "",
							 newsuccess: "",
							 puberror: "",
							 allocerrors: "",
							 errors: errors,
							 alertBorder: "1px solid red",
							 alertWidth: "1000px",
							 alertAlign: "center",
							 alertDisplay: "block",
							 spanSpaceDisplay: spanDisplay,
						 });
						 return;
					 } else {
						 if (patterndecimal.test(testval1)) {
							 cpl = true;
						 } else {
							 cpl = false;
							 displaycpl[i] = result[i].publisherName;
 
							 errors["cpl"] =
								 "Please check CPL value. CPL should follow below criteria.";
							 errors["cpl1"] = <li>CPL should be only number.</li>;
							 errors["cpl2"] = <li>CPL should be greater than zero.</li>;
							 errors["cpl3"] = (
								 <li>CPL value allowed only 2 decimal places.</li>
							 );
 
							 this.setState({
								 dateerrors: "",
								 newsuccess: "",
								 puberror: "",
								 allocerrors: "",
								 errors: errors,
								 alertBorder: "1px solid red",
								 alertWidth: "1000px",
								 alertAlign: "center",
								 alertDisplay: "block",
								 spanSpaceDisplay: spanDisplay,
							 });
							 return;
						 }
					 }
				 }
				 // end of cpl validations
				 //validation for budget
				 num1 = parseInt(result[i].allocatedLead);
				 num2 = parseFloat(result[i].cpl);
				 multiply = num1 * num2;
 
				 addmulti += multiply;
 
				 if (totalRemainingBudget == "") {
					 if (addmulti > totalBudget) {
						 rebudget = false;
 
						 reerrors["remaining"] = (
							 <li>Allocated budget cannot be greater than total budget</li>
						 );
						 this.setState({
							 puberror: "",
							 allocerrors: "",
							 reerrors: reerrors,
							 errors: errors,
							 newsuccess: "",
							 alertBorder: "1px solid red",
							 alertWidth: "1000px",
							 alertAlign: "center",
							 alertDisplay: "block",
							 spanSpaceDisplay: spanDisplay,
						 });
						 return;
					 } else {
						 rebudget = true;
					 }
				 } else {
					 if (addmulti > totalRemainingBudget) {
						 rebudget = false;
 
						 reerrors["remaining"] = (
							 <li>Allocated budget cannot be greater than total budget</li>
						 );
						 this.setState({
							 puberror: "",
							 allocerrors: "",
							 reerrors: reerrors,
							 errors: errors,
							 alertBorder: "1px solid red",
							 alertWidth: "1000px",
							 alertAlign: "center",
							 alertDisplay: "block",
							 spanSpaceDisplay: spanDisplay,
						 });
						 return;
					 } else {
						 rebudget = true;
					 }
				 }
				 add = totalAllocate - totalLead;
				 if (sum > add) {
					 valid = false;
 
					 allocerrors["allocate"] = (
						 <li>Allocated leads cannot be greater than total leads</li>
					 );
					 this.setState({
						 puberror: "",
						 allocerrors: allocerrors,
						 reerrors: "",
						 newsuccess: "",
						 errors: errors,
						 alertBorder: "1px solid red",
						 alertWidth: "1000px",
						 alertAlign: "center",
						 alertDisplay: "block",
						 spanSpaceDisplay: spanDisplay,
					 });
				 } else {
					 valid = true;
				 }
			 } // end of else
		 } // end of for
		 var al = 0;
 
		 for (var i = 0; i < result.length; i++) {
			 if (result[i].allocatedLead != undefined) {
				 al = al + parseInt(result[i].allocatedLead);
			 }
		 }
 
		 //Sandeep-task-3530-added code for poNumber with validation and alert message
		 let poArray;
		 poArray = Array.from(
			 new Set(this.state.publisherDetail.map((p) => p.pID))
		 ).map((pID) => {
			 return {
				 pID: pID,
 
				 poNumber: this.state.publisherDetail.find((p) => p.pID === pID)
					 .poNumber,
				 isAllocate: this.state.publisherDetail.find((p) => p.pID === pID)
					 .isAllocate,
				 //Sandeep-task-3530-added code for poNumber
			 };
		 });
 
		 for (var k = 0; k < poArray.length; k++) {			
			 if (
				  poArray[k].isAllocate == "Yes" &&
				 (poArray[k].poNumber == "" ||
					 poArray[k].poNumber == undefined ||
					 poArray[k].poNumber == null )
			 ) {
				 checkAllocation = true;
			 }else{
				 
				 //checkAllocation = false;
			 }
		 }
		 for(let i=0;i<result.length;i++){
			let cpl1=result[i].cpl;
			let patterndecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/); //decimal
			if (cpl1 && !patterndecimal.test(cpl1)) {
			   cpl = false;
							displaycpl[i] = result[i].publisherName;

							errors["cpl"] =
								"Please check CPL value. CPL should follow below criteria.";
							errors["cpl1"] = <li>CPL should be only number.</li>;
							errors["cpl2"] = <li>CPL should be greater than zero.</li>;
							errors["cpl3"] = (
								<li>CPL value allowed only 2 decimal places.</li>
							);

							this.setState({
								dateerrors: "",
								newsuccess: "",
								puberror: "",
								allocerrors: "",
								errors: errors,
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});
							return;
			}
		}
		 //Saurabh-task-3792-PO Number validation
		 for (var m = 0; m < result.length; m++) {
			 if (
				 result[m].poNumber != "" &&
				 result[m].poNumber != null &&
				 result[m].poNumber != undefined
			 ) {
				 var pattern1 = new RegExp(/^[a-zA-Z\d*]{8,}$/);
	 
				 if (!pattern1.test(result[m].poNumber)) {
					 povalidate = false;
					 puberror["po"] = (
						 <li>Please enter minimum eight digit valid po number.</li>
					 );
					 this.setState({
						 puberror: puberror,
						 allocerrors: "",
						 errors: errors,
						 newsuccess: "",
						 alertWidth: "1000px",
						 alertBorder: "1px solid red",
						 alertAlign: "center",
						 alertDisplay: "block",
						 spanSpaceDisplay: spanDisplay,
					 });
					 document.body.scrollTop = 0;
					 document.documentElement.scrollTop = 0;
					 return;
				 } else {
					 povalidate = true;
				 }
			 } //end of  if
			 //povalidate = true;
		 } //end of for
 
		 if (checkAllocation === true || checkAllocation === "true") {
			 Swal.fire({
				 text: "Please provide PO number (if available) for this campaign",
				 icon: "warning",
				 type: "warning",
				 // timer:"6000" ,
				 showCancelButton: true,
				 confirmButtonColor: "#193D8F",
				 cancelButtonColor: "#193D8F",
				 confirmButtonText: "Yes",
				 cancelButtonText: "No",
				 showCloseButton: true,
				 allowOutsideClick: false,
			 }).then((result) => {
				 if (result.value === true) {
					 window.location.href = "#";
				 } else {
					 formIsValid = true;
					 if (formIsValid === true) {
						 this.setState({ alertDisplay: "none" });
						 //var campID = campaignDetail[0].campID;
						 const { user } = this.props.auth;
					
						 var campID = this.state.campID;//4034-accessing campID from state
						 var proposalID = this.state.proposalID;//4034-accessing proposalID from state
						 let data = {
							 campID: campID,
							 proposalID: proposalID,
							// user: user,//3716-remove user
							 publisherDetail: this.state.publisherDetail,
							 campaignDetail: this.state.campaignDetail,
						 };
						 fetch("requestForProposal/RFPCampaignAllocation", {
							 method: "POST",
							 headers: { "Content-Type": "application/json" },
							 body: JSON.stringify(data),
						 })
							 .then((res) => res.json())
							 .then((res) => {
								 if (res.success === true) {
									 this.setState({ inputClass: "disabled" });
									 Swal.fire({
										 text: res.message,
										 type: "success",
										 confirmButtonText: "Ok",
										 allowOutsideClick: false,
										 preConfirm: () => {
											 this.props.history.push("/campaignList", {
												 agencyID: user.id,
											 }); //karan-task-3684-replace query params
										 },
									 });
								 }
							 })
							 .catch((err) => {
								 console.log(err);
							 });
					 }
				 }
			 });
 
			 return;
		 } //end of  if
 
		 if (
			 date === true &&
			 cpl === true &&
			 lead === true &&
			 valid === true &&
			 rebudget === true &&
			 povalidate === true
		 ) {
			 formIsValid = true;
			 this.setState({ alertBorder: "0px solid white" });
		 }
		 if (formIsValid === true) {
			 this.setState({ alertDisplay: "none" });
			 //var campID = campaignDetail[0].campID;
			 const { user } = this.props.auth;
			 var campID = this.state.campID;//4034-accessing campID from state
			 var proposalID = this.state.proposalID;//4034-accessing proposalID from state
			 let data = {
				 campID: campID,
				 proposalID: proposalID,
				 //Task-Akash-4169- removed user 
				 publisherDetail: this.state.publisherDetail,
				 campaignDetail: this.state.campaignDetail,
			 };
			 fetch("requestForProposal/RFPCampaignAllocation", {
				 method: "POST",
				 headers: { "Content-Type": "application/json" },
				 body: JSON.stringify(data),
			 })
				 .then((res) => res.json())
				 .then((res) => {
					 if (res.success === true) {
						 this.setState({ inputClass: "disabled" });
						 Swal.fire({
							 text: res.message,
							 type: "success",
							 confirmButtonText: "Ok",
							 allowOutsideClick: false,
							 preConfirm: () => {
								 this.props.history.push("/campaignList", { agencyID: user.id }); //karan-task-3684-replace query params
							 },
							 //shivani-task 3071- changed link for after allocation is should redirect to campaign List page .
						 });
					 }
				 })
				 .catch((err) => {
					 console.log(err);
				 });
		 }
	 } // End of SubmitPublisher
	 isGDPRPUB(e) {
		 let self = this;
		 let gdprValue = e.target.value;
		 let campID = e.target.id;
		 let finalPublisherList = [...self.state.publisherInfoArray];//Sonali-4120-making copy of array
		 let tempArray = finalPublisherList.filter((a) => {
			 return a.campID == campID && a.gdprCompliance == gdprValue;
		 });
		 //Somnath Task4120, set error object blank
		 self.setState({ displayAllocation: true,errors:{},alertBorder: "0px solid white",alertDisplay: "none",publisherDetail:tempArray,
			  finalPublisherList: tempArray //Somnath Task-3829, Add setstate change
			 });
	 } // End of isGDPRPUB
 
	 rfpCampBackButton() {
		 const { user } = this.props.auth;
		 var agencyID = user.id;
		 this.props.history.push("/campaignList", { agencyID: user.id }); //karan-task-3684-replace query params
	 }
 
	 myFunction() {
		 if (this.state.divDisplay == "none") {
			 this.setState({ divDisplay: "block" });
		 }
		 if (this.state.divDisplay == "block") {
			 this.setState({ divDisplay: "none" });
		 }
	 }
 
	 render() {
		 const { user } = this.props.auth;
		 return (
			 <div>
				 <Navigation />
				 <div
					 class="container-fluid"
					 style={{ paddingTop: "90px", marginBottom: "10px" }}>
					 <div class="row">
						 <div class="col-md-1">
							 <div>
								 {/* IoIosArrowDropleftCircle */}
								 <a
									 href="#"
									 style={{ color: "#056eb8" }}
									 onClick={this.rfpCampBackButton.bind(this)}>
									 <FaArrowAltCircleLeft
										 size={32}
										 style={{ float: "left" }}
										 title=""
									 />
								 </a>
							 </div>
						 </div>
						 <div class="col-md-6 col-lg-6 col-sm-6 offset-lg-4 offset-md-4  offset-sm-4">
							 <label
								 id="label"
								 style={{
									 fontSize: "20px",
									 fontWeight: "bold",
									 color: "#056eb8",
									 overflow:"visible",
								 }}>
								 RFP Campaign Allocation
							 </label>
							 {/* kiran-4336-added overflow for repsponsiveness */}
						 </div>
					 </div>
				 </div>
				 <div className="" style={{ display: this.state.alertDisplay }}>
					 <div class="container-fluid">
						 {/* {/* <div class="card card-signin my-1"> */}
						 <div class="card-body">
							 <div class="row">
								 <div
									 class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
									 style={{ border: this.state.alertBorder }}>
									 <div class="errorMessage">
										 {this.state.errors.endDate ? this.state.errors.endDate : ""}
										 {this.state.errors.allocatedLead
											 ? this.state.errors.allocatedLead
											 : ""}
										 {this.state.errors.reject ? this.state.errors.reject : ""}
										 {this.state.puberror.one ? this.state.puberror.one : ""}
										 {this.state.errors.lead ? this.state.errors.lead : ""}
										 {this.state.allocerrors.allocate
											 ? this.state.allocerrors.allocate
											 : ""}
										 {this.state.reerrors.remaining
											 ? this.state.reerrors.remaining
											 : ""}
										 {this.state.dateerrors.date
											 ? this.state.dateerrors.date
											 : ""}
										 {this.state.errors.cpl ? this.state.errors.cpl : ""}
										 {this.state.errors.cpl1 ? this.state.errors.cpl1 : ""}
										 {this.state.errors.cpl2 ? this.state.errors.cpl2 : ""}
										 {this.state.errors.cpl3 ? this.state.errors.cpl3 : ""}
										 {this.state.puberror.po ? this.state.puberror.po : ""}
									 </div>
 
									 <div class="successMessage">{this.state.newsuccess} </div>
								 </div>
							 </div>
						 </div>
						 {/* </div> */}
					 </div>
				 </div>
				 <div
					 style={{
						 MozBoxShadow: "3px 3px 5px 0px #e6e6ff",
						 WebkitBoxShadow: "3px 3px 5px 1px #e6e6ff",
						 boxShadow: "3px 3px 5px 0px #e6e6ff",
					 }}>
					 <div
						 style={{
							 paddingLeft: "10px",
							 paddingTop: "10px",
							 paddingBottom: "10px",
						 }}>
						 <div>
							 {this.state.campaignDetail.map((campaignDetail) => (
								 <div class="row">
									 <div class=" col-sm-12 col-md-4 col-lg-4">
										 <label
											 id="label"
											 className="camp-id"
											 style={{ color: "#000080" ,display:"inline-flex"}}>
											 Campaign ID
										 </label>
										:{campaignDetail.parentCampID}
										 {/* Sandeep-task-3377-removed span from camp id */}
										 {/* kiran-bug-4336-added dispaly for responsiveness */}
									 </div>
									 <div class="col-sm-12 col-md-4 col-lg-4">
										 <label
											 id="label"
											 className="camp-name"
											 style={{ color: "#000080" ,display:"inline-flex" }}>
											 Campaign Name
										 </label>
										 :{campaignDetail.campaignName}
										 {/* Sandeep-task-3377-removed span from camp name */}
									 </div>
								 </div>
							 ))}
						 </div>
						 {this.state.campaignDetail.map((campaignDetail) => (
							 <div class="row">
								 <div class="col-sm-12 col-md-4 col-lg-4">
									 <label
										 id="label"
										 className="camp-type"
										 style={{ color: "#000080" ,display:"inline-flex"}}>
										 Campaign Type{" "}
									 </label>
									 :{campaignDetail.ABM}
								 </div>
								 <div class="col-sm-12 col-md-4 col-lg-4">
									 <label
										 id="label"
										 className="camp-region"
										 style={{ color: "#000080",display:"inline-flex" }}>
										 Campaign Region{" "}
									 </label>
									 :{campaignDetail.region}
								 </div>
								 <div class="col-sm-4"></div>
								 <div class="col-sm-12 col-md-4 col-lg-4">
									 <label
										 id="label"
										 className="start-date"
										 style={{ color: "#000080" ,display:"inline-flex" }}>
										 Start Date{" "}
									 </label>
									 :{campaignDetail.startDate}
								 </div>
 
								 <div class="col-sm-12 col-md-4 col-lg-4">
									 <label
										 id="label"
										 className="end-date"
										 style={{ color: "#000080" ,display:"inline-flex"}}>
										 End Date{" "}
									 </label>
									 :{campaignDetail.endDate}
								 </div>
								 <div class="col-sm-12 col-md-4 col-lg-4">
									 <label
										 id="label"
										 className="lead-delivery"
										 style={{ color: "#000080" ,display:"inline-flex" }}>
										 First Lead Delivery Date
									 </label>
									 :{campaignDetail.firstLeadDeliveryDate}
								 </div>
								 {user.role === "AC" ? (
									 <div class="col-sm-12 col-md-4 col-lg-4">
										 <label
											 id="label"
											 className="total-budget"
											 style={{ color: "#000080" ,display:"inline-flex"}}>
											 Total Budget{" "}
										 </label>
										 :{campaignDetail.budget}
										 &nbsp;{campaignDetail.currency}
									 </div>
								 ) : (
									 ""
								 )}
								 {user.role === "AC" ? (
									 <div class="col-sm-12 col-md-4 col-lg-4">
										 <label
											 id="label"
											 className="remaining-budget"
											 style={{ color: "#000080",display:"inline-flex" }}>
											 Remaining Budget{" "}
										 </label>
										 :
										 {campaignDetail.remainingBudget
											 ? Math.round(campaignDetail.remainingBudget * 100) / 100
											 : campaignDetail.remainingBudget === 0
											 ? "0"
											 : Math.round(campaignDetail.budget * 100) / 100}
										 &nbsp;{campaignDetail.currency}
									 </div>
								 ) : (
									 ""
								 )}
								 <div class="col-sm-12 col-md-4 col-lg-4">
									 <label
										 id="label"
										 className="camp-cpl"
										 style={{ color: "#000080" ,display:"inline-flex"}}>
										 Campaign CPL
									 </label>
									 :{campaignDetail.CPL ? campaignDetail.CPL : "0"}&nbsp;
									 {campaignDetail.currency}
								 </div>
								 <div class="col-sm-12 col-md-4 col-lg-4">
									 <label
										 id="label"
										 className="total-lead"
										 style={{ color: "#000080" ,display:"inline-flex"}}>
										 Total Leads
									 </label>
									 :{campaignDetail.leadAllocation}
								 </div>
								 <div class="col-sm-12 col-md-4 col-lg-4">
									 <label
										 id="label"
										 className="allocated-lead"
										 style={{ color: "#000080" ,display:"inline-flex"}}>
										 Allocated Leads
									 </label>
									 :
									 {campaignDetail.allocatedLead
										 ? campaignDetail.allocatedLead
										 : "0"}
								 </div>
 
								 <div class="col-sm-12 col-md-4 col-lg-4">
									 <label
										 id="label"
										 className="pending-lead"
										 style={{ color: "#000080",display:"inline-flex" }}>
										 Pending Leads{" "}
									 </label>
									 :
									 {campaignDetail.pendingLead
										 ? campaignDetail.pendingLead
										 : campaignDetail.pendingLead === 0
										 ? "0"
										 : campaignDetail.leadAllocation}
								 </div>
							 </div>
						 ))}
					 </div>
				 </div>
				 <div class="col-md-12 col-lg-12">
					 <center>
						 <br />
						 {this.state.region ? (
							 <span style={{ fontWeight: "bold" }}>
								 Targeted Country is GDPR Compliance.Do you want to allocate GDPR
								 Compliance Publisher ?&nbsp;
								 <button
									 type="submit"
									 onClick={this.isGDPRPUB}
									 id={this.state.campID}
									 value="Yes"
									 class="btn btn-info btn-sm">
									 Yes
								 </button>
								 &nbsp;
								 <button
									 type="submit"
									 onClick={this.isGDPRPUB}
									 id={this.state.campID}
									 value="No"
									 class="btn btn-danger btn-xs">
									 No
								 </button>
							 </span>
						 ) : (
							 ""
						 )}
					 </center>
				 </div>
				 {this.state.displayAllocation === true ? (
					 <div>
						 {/** Start of Allocation */}
 
						 <div class="table-responsive-lg">
							 <table class=" table table-striped  table-bordered">
								 <thead>
									 <tr class="info">
										 <th class="table-header" style={{ width: "30%" }}>
											 Publisher Details
										 </th>
										 <th class="table-header" style={{ width: "70%" }}>
											 New Allocation{" "}
										 </th>
									 </tr>
								 </thead>
 
								 <tbody>
									 {/* Sandeep-task-3530-change in arrayName publisherDetail bcz of poNumber issue */}
									 {this.state.finalPublisherList.map((publisherDetail, i) => {
										 return (
											 <tr
												 style={{ backgroundColor: "white", border: "hidden" }}>
												 <td>
													 <div>
														 {/* <table style={{width:'100%',borderCollapse:'collapse'}}>
				 <tr style={{backgroundColor:'white',borderCollapse:'collapse',borderStyle:'hidden'}}>                                  
				   <td style={{border:'none'}}><label id="label" style={{float:'left',borderCollapse:'collapse',border:'hidden'}}>Name</label></td> 
				   <td><label style={{float:'left',fontWeight:'400'}}>{publisherDetail.publisherName}</label></td>                                 
				 </tr>
				 <tr>                               
				   <td><label id="label" style={{float:'left'}}>Id</label></td>
				   <td><label style={{float:'left',fontWeight:'400'}}> {publisherDetail.pID}</label> </td>
				 </tr>
				 <tr style={{backgroundColor:'white',borderCollapse:'collapse',borderStyle:'hidden'}}>                        
				   <td><label id="label" style={{float:'left'}}>Rating</label></td><td> <label style={{float:'left',fontWeight:'400'}}>{publisherDetail.rating}</label><br/></td>
				
				 </tr>
				 <tr style={{backgroundColor:'white',borderCollapse:'collapse',borderStyle:'hidden'}}>                        
				   <td><label id="label" style={{float:'left'}}>GDPR</label></td><td> <label style={{float:'left',fontWeight:'400'}}>{publisherDetail.gdprCompliance}</label><br/></td>
				
				 </tr>
				 
			  </table> */}
 
														 <div>
															 <label
																 id="label"
																 style={{
																	 color: "#000080",
																	 marginLeft: "10px",
																 }}>
																 Name&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
															 </label>
															 <span
																 style={{
																	 color: "#000000",
																	 fontWeight: "lighter",
																 }}>
																 {publisherDetail.publisherName}&nbsp;
															 </span>
														 </div>
														 <br />
														 <div>
															 <label
																 id="label"
																 style={{
																	 color: "#000080",
																	 marginLeft: "10px",
																 }}>
																 ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
															 </label>
															 <span
																 style={{
																	 color: "#000000",
																	 fontWeight: "lighter",
																 }}>
																 {publisherDetail.pID}&nbsp;
															 </span>
														 </div>
														 <br />
														 <div>
															 <label
																 id="label"
																 style={{
																	 color: "#000080",
																	 marginLeft: "10px",
																 }}>
																 Rating&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
															 </label>
															 <span
																 style={{
																	 color: "#000000",
																	 fontWeight: "lighter",
																 }}>
																 {publisherDetail.rating}&nbsp;
															 </span>
														 </div>
														 <br />
														 <div>
															 <label
																 id="label"
																 style={{
																	 color: "#000080",
																	 marginLeft: "10px",
																 }}>
																 GDPR&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
															 </label>
															 <span
																 style={{
																	 color: "#000000",
																	 fontWeight: "lighter",
																 }}>
																 {publisherDetail.gdprCompliance}&nbsp;
															 </span>
														 </div>
													 </div>
 
													 <br />
 
													 <hr style={{ border: "0.8px solid #708090" }}></hr>
												 </td>
 
												 <tr
													 style={{
														 backgroundColor: "white",
														 border: "hidden",
													 }}>
													 <td style={{ border: "hidden" }}>Start Date </td>
													 <td style={{ border: "hidden" }}>End date</td>
													 <td style={{ border: "hidden" }}>
														 First Lead Delivery date
													 </td>
													 <td style={{ border: "hidden" }}>Allocated Leads</td>
													 <td style={{ border: "hidden" }}>Allocation</td>
													 <td style={{ border: "hidden" }}>CPL</td>
													 <td style={{ border: "hidden" }}>PO Number</td>
												 </tr>
 
												 <tr
													 style={{
														 backgroundColor: "white",
														 border: "hidden",
													 }}>
													 <td style={{ border: "hidden" }}>
														 <input
															 style={{ height: "30px" }}
															 id="startDate"
															 name="startDate"
															 onChange={this.dynamicAllocation.bind(this, i)}
															 defaultValue={publisherDetail.startDate}
															 type="date"
														 />
													 </td>
													 <td style={{ border: "hidden" }}>
														 <input
															 style={{ height: "30px" }}
															 onChange={this.dynamicAllocation.bind(this, i)}
															 defaultValue={publisherDetail.endDate}
															 id="endDate"
															 name="endDate"
															 type="date"
														 />
													 </td>
													 <td style={{ border: "hidden" }}>
														 <input
															 id="firstLeadDeliveryDate"
															 name="firstLeadDeliveryDate"
															 style={{ height: "30px" }}
															 onChange={this.dynamicAllocation.bind(this, i)}
															 defaultValue={
																 publisherDetail.firstLeadDeliveryDate
															 }
															 type="date"
														 />
													 </td>
													 <td style={{ border: "hidden" }}>
														 <label id="label" style={{ float: "left" }}>
															 {publisherDetail.oldAllocation}
														 </label>
													 </td>
													 <td style={{ border: "hidden" }}>
														 <input
															 style={{ width: "100px", height: "30px" }}
															 id="allocatedLead"
															 name="allocatedLead"
															 key={publisherDetail.pID}//Somnath Task4120, Add Key
															 onChange={this.dynamicAllocation.bind(this, i)}
															 defaultValue={publisherDetail.allocatedLead}
															//  value={publisherDetail.allocatedLead}
														 />
														 {/* kiran-4336-comment value for rfp correct flow-due to this allocation field is uneditable */}
													 </td>
													 {/* abc{publisherDetail.status} */}
													 {/* Saurabh-Task-3813 - Added conditional rendering For CPL Value  */}
													 <td style={{ border: "hidden" }}>
																	 {publisherDetail.status == "Accept" ? (
																		 <input
																		 style={{ width: "100px", height: "30px" }}
																		 class="form-control"
																		 id="CPL"
																		 name="CPL"
																		 disabled
																		 value={publisherDetail.CPL}//Somnath Task4120, Add Key
																		 onChange={this.dynamicAllocation.bind(this, i)}
																	 />
																	 ) : publisherDetail.status ==
																	   "Assign" ? (
																		 <input
																		 style={{ width: "100px", height: "30px" }}
																		 class="form-control"
																		 id="CPL"
																		 name="CPL"
																		 key={publisherDetail.pID}//Somnath Task4120, Add Key
																		 defaultValue={publisherDetail.CPL}
																		 onChange={this.dynamicAllocation.bind(this, i)}
																		 />
																	 ) : publisherDetail.status == null ? (
																		 <input
																		 style={{ width: "100px", height: "30px" }}
																		 class="form-control"
																		 id="CPL"
																		 name="CPL"
																		 key={publisherDetail.pID}//Somnath Task4120, Add Key
																		 defaultValue={publisherDetail.campCPL}
																		 onChange={this.dynamicAllocation.bind(this, i)}
																		 />
																	 ) : (
																		 <input
																		 style={{ width: "100px", height: "30px" }}
																		 class="form-control"
																		 id="CPL"
																		 name="CPL"
																		 key={publisherDetail.pID}//Somnath Task4120, Add Key
																		 defaultValue={publisherDetail.CPL}
																		 onChange={this.dynamicAllocation.bind(this, i)}
																		 />
																	 )}
													 </td>
 
													 {/* Sandeep-task-3530-added code for poNumber */}
													 <td style={{ border: "hidden" }}>
														 {publisherDetail.poNumber ? (
															 <input
																 style={{ width: "100px", height: "30px" }}
																 class="form-control"
																 id="poNumber"
																 name="poNumber"
																 value={publisherDetail.poNumber}
																 disabled
																 onChange={this.dynamicAllocation.bind(
																	 this,
																	 i
																 )}></input>
														 ) : (
															 <input
																 style={{ width: "100px", height: "30px" }}
																 class="form-control"
																 id="poNumber"
																 name="poNumber"
																 key={publisherDetail.pID}
																 defaultValue={publisherDetail.poNumber}
																 onChange={this.dynamicAllocation.bind(
																	 this,
																	 i
																 )}></input>
														 )}
													 </td>
												 </tr>
 
												 <hr />
												 <div style={{ margin: "10px" }}>
													 <h5 style={{ color: "gray" }}>RFP Details</h5>
												 </div>
												 <div
													 style={{
														 MozBoxShadow: "3px 3px 5px 0px #e6e6ff",
														 WebkitBoxShadow: "3px 3px 5px 1px #e6e6ff",
														 boxShadow: "3px 3px 5px 0px #e6e6ff",
														 marginBottom: "30px",
													 }}>
													 <div
														 style={{ paddingLeft: "10px", paddingTop: "10px" }}>
														 <div>
															 {/* {this.state.publisherDetail.map(publisherDetail => (  */}
															 <div class="row">
																 <div class="col-sm-3">
																	 <label
																		 id="label"
																		 style={{ color: "#000080" }}>
																		 Monthly Lead :&nbsp;&nbsp;&nbsp;&nbsp;
																	 </label>
																	 <span
																		 style={{
																			 color: "#000000",
																			 fontWeight: "lighter",
																		 }}>
																		 {publisherDetail.monthlyLead}&nbsp;
																	 </span>
																 </div>
																 <div class="col-sm-3">
																	 <label
																		 id="label"
																		 style={{ color: "#000080" }}>
																		 Daily Lead&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;
																	 </label>
																	 <span
																		 style={{
																			 color: "#000000",
																			 fontWeight: "lighter",
																		 }}>
																		 {publisherDetail.dailyLead}
																	 </span>
																 </div>
 
																 <div class="col-sm-3">
																	 <label
																		 id="label"
																		 style={{ color: "#000080" }}>
																		 Total lead&nbsp;:&nbsp;&nbsp; &nbsp;{" "}
																	 </label>
																	 &nbsp;{publisherDetail.totalLead}
																 </div>
 
																 <div class="col-sm-3">
																	 <label
																		 id="label"
																		 style={{ color: "#000080" }}>
																		 RFP CPL&nbsp;:&nbsp;&nbsp; &nbsp;{" "}
																	 </label>
																	 {publisherDetail.rfpCPL}&nbsp;
																	 {publisherDetail.currency}
																 </div>
															 </div>
															 {/* ))} */}
														 </div>
													 </div>
												 </div>
												 <hr className="lineNewAllocation" style={{ border: "0.8px solid #708090" }}></hr>
											 </tr>
										 );
									 })}
								 </tbody>
							 </table>
						 </div>
 
						 <button
							 style={{ float: "right" }}
							 className="btn add-button"
							 onClick={this.submitAllocation.bind(this)}>
							 Submit
						 </button>
					 </div>
				 ) : (
					 ""
				 )}
				 {/* End of Allocation*/}
			 </div>
		 );
	 }
 }
 
 RfpCampaignAllocation.propTypes = {
	 logoutUser: PropTypes.func.isRequired,
	 auth: PropTypes.object.isRequired,
 };
 const mapStateToProps = (state) => ({
	 auth: state.auth,
 });
 export default connect(mapStateToProps, { logoutUser })(
	 withRouter(RfpCampaignAllocation)
 );
 