/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file ProtectedRoute.js
 * @author   Aman Shaikh - 5084
 * @version 1.0
 * @section DESCRIPTION Added to ProtectedRoute with chaking its current role
 */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import UnauthorizedPage from "./unauthorized";

const ProtectedRoute = ({ component: Component, allowedRoles, user, ...rest }) => {
  const isAuthenticated = user && user.role && allowedRoles.includes(user.role);

  if (user.loading) {
    //Aman-5084-If user's role information is loading, display a loading indicator or other UI
    return <div>Loading...</div>;
  }

  //Aman-5084-Redirect to the home page if user tries to access the protected route again after being redirected to UnauthorizedPage
  if (!isAuthenticated && user.role === "unauthorized") {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <UnauthorizedPage />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ProtectedRoute);
