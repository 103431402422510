/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Godbole
 *@fileName :agencyInfo.js
 *Desc: Agency Info
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import "./agencyInformation.css";
//import { ThemeConsumer } from "styled-components";    //Chaitanya-4750-Removed Console warning
//import { MDBBtn, CardBody } from "mdbreact";          //Chaitanya-4750-Removed Console warning
import { Tooltip } from "antd";     //Chaitanya-4750-Removed Console warning
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params

//const Swal = require("sweetalert2");    //Chaitanya-4750-Removed Console warning

class AgencyInformation extends React.Component {
  constructor() {
    super();
    this.state = {
      agencyDetails: [],
      agencyContactDetails: [],
      agencyInfo: [],
      AgencyID: "",
      AgencyName: "",
      agencyID: "",
      salesforceClientDetails: [],
      marketoClientInfo: [],
      hubspotClientInfo: [],
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
  } //end of constructor
  handleEdit(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;    //Chaitanya-4750-Removed Console warning
    //var agencyID = user.id;   //Chaitanya-4750-Removed Console warning
    this.props.history.push("/agencyInformationEdit"); //Priyanka--4062--removed params
  }
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth;

      var agencyID = user.id;

      this.setState({ agencyID: agencyID });

      fetch("/agency/agencyDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }) // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((agencyInfo) => {
          this.setState({
            agencyInfo: agencyInfo,
            AgencyName: agencyInfo[0].agencyName,
            AgencyID: agencyInfo[0].agencyID,
          });
        })
        .catch(function(err) {
          console.log(err);
        });

      //agencyContact Details

      fetch("/agency/agencyContactDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }) // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((response) => {
          this.setState({ agencyContactDetails: response });
        });

      fetch("/agencyDashboard/salesforceClientDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }) // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((salesforceClientDetails) => {
          this.setState({ salesforceClientDetails: salesforceClientDetails });
        })
        .catch(function(err) {
          console.log(err);
        });
      /**
       * @author Narendra Phadke
       * @param  Description  Get thea details of marketo clients
       * @return Description return response with marketo client details
       */
      fetch("/marketoClient/marketoClientDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }) // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((marketoClientInfo) => {
          this.setState({ marketoClientInfo: marketoClientInfo });
        })
        .catch(function(err) {
          console.log(err);
        });

      fetch("/hubspotClient/hubspotClientDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }) // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((hubspotClientInfo) => {
          this.setState({ hubspotClientInfo: hubspotClientInfo });
        })
        .catch(function(err) {
          console.log(err);
        });
    } //end of else
  } // end of componentDidMount
  handleBackButton(e) { //Chaitanya-4750-Removed Console warning
    e.preventDefault();   
    this.props.history.push("/newdashboard", {
      agencyID: this.state.agencyID,
    })
  }

  render() {
    const { user } = this.props.auth;

    return (
      <div>
        <Navigation />
        <div
          className="container-fluid"
          id="AgiContainer"
          style={{ paddingTop: "103px", paddingBottom: "100px" }}
        >
          <div class="row">
            <div class="col-md-3">
              <a  href="#/">   {/* Chaitanya-4750-Removed Console warning */}
                {" "}
                <FaArrowAltCircleLeft
                  size={32}
                  style={{ float: "left",color: "#056eb8"}}
                  title="Back to Dashboard"
                  onClick={this.handleBackButton}
                />
              </a>
            </div>
            <div className="col-md-9 ">
              <label
                id="labelDI" //  Mufiz-Bug-4111  Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) */
                className="page-title"
                style={{
                  textAlign: "center",
                }}
              >
                {/* //shivani-3285-passed ID for DI label consistency */}
                {this.state.AgencyName}(Agency ID:{this.state.AgencyID})
              </label>
            </div>
          </div>
          <br />

          <div
            style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}//mufiz-khan -4193 titleResposive-Agency-Setting option-Agency Information issue
          >
            Agency Information: 
            {user.role === "AC" ? (
              <a href="#/"  //Chaitanya-4750-Removed Console warning
              >
                <Tooltip title="Edit">
                  <img
                    id="edit-logo"
                    class="float-right"
                    src="NewEdit.png"
                    onClick={this.handleEdit}
                    alt=""    //Chaitanya-4750-Removed Console warning
                  />
                </Tooltip>
              </a>
            ) : (
              ""
            )}
          </div>
          <br />
          {this.state.agencyInfo.map((agencyInfo) => {
            return (
              <div className="row" style={{ width: "100%" }}>
                <div class="col-xs-12  col-sm-12 col-md-12 col-lg-3">
                  <label>Email</label>:&nbsp;&nbsp;{agencyInfo.email}
                </div>
                <div class="col-xs-12  col-sm-12 col-md-12 col-lg-3">
                  <label>Website</label>:&nbsp;&nbsp;{agencyInfo.website}
                </div>
                <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
                  <label>Country Code</label>:&nbsp;&nbsp;
                  {agencyInfo.countryCode}
                </div>
                <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
                  <label>Phone</label>:&nbsp;&nbsp;{agencyInfo.phone}
                </div>
              </div>
            );
          })}
          {/* end of 1st row */}
          <br />

          {this.state.agencyInfo.map((agencyInfo) => {
            return (
              <div className="row">
                <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
                  <label>Country</label>:&nbsp;&nbsp;{agencyInfo.country}
                </div>
                <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
                  <label>State</label>:&nbsp;&nbsp;{agencyInfo.state}
                </div>
                <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
                  <label>City</label>:&nbsp;&nbsp;{agencyInfo.city}
                </div>
                <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
                  <label>Zip Code</label>:&nbsp;&nbsp;{agencyInfo.zipcode}
                </div>
              </div>
            );
          })}
          {/* end of 2st row*/}
          <br />

          {this.state.agencyInfo.map((agencyInfo) => {
            return (
              <div className="row">
                <div class="col-xs-12  col-sm-12 col-md-12 col-lg-3">
                  <label>Time Zone</label>:&nbsp;&nbsp;{agencyInfo.timezone}
                </div>

                <div class="col-xs-12  col-sm-12 col-md-9 col-lg-9">
                  <label>Address</label>:&nbsp;&nbsp;{agencyInfo.address}
                </div>
              </div>
            );
          })}
          {/* end of 3st row */}
          <br />

          {this.state.agencyInfo.map((agencyInfo) => {
            return (
              <div className="row">
                <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
                  <label>Rating</label>:&nbsp;&nbsp;{agencyInfo.rating}
                </div>
                <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
                  <label>GDPR Compliance</label>:&nbsp;&nbsp;
                  {agencyInfo.gdprCompliance}
                </div>
                {/* User Story : 3188 : Karan Jagtap : Sub-Contracting value display */}
                {/* Added this here instead of 3rd row because "Address" field might need more space */}
                <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
                  <label>Sub-Contracting</label>:&nbsp;&nbsp;
                  {agencyInfo.subContracting === undefined ||
                  agencyInfo.subContracting === null ||
                  agencyInfo.subContracting === "undefined" ||
                  agencyInfo.subContracting === "null" ||
                  agencyInfo.subContracting === ""
                    ? ""
                    : agencyInfo.subContracting}
                </div>
              </div>
            );
          })}
          {/* end of 4th row */}
          <br />
          <hr />

          <div
            style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}
          >
            Delivery Channel:
          </div>
          <div class="scrollmenu">
            <table class="table table-bordered" id="myTable3">
              <thead>
                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Email
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Telemarketing{" "}
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Display
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Programmatic
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Socail{" "}
                  </th>
                </tr>
              </thead>

              <tbody>
                {this.state.agencyInfo.map((agencyInfo) => {
                  return (
                    <tr>
                      <td>{agencyInfo.dcEmail}</td>
                      <td>{agencyInfo.dcTelemarketing}</td>
                      <td>{agencyInfo.dcDisplay}</td>
                      <td>{agencyInfo.dcProgrammatic}</td>
                      <td>{agencyInfo.dcSocial}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* end of 5th row */}
          {user.role === "ANC" ? (
            ""
          ) : (
            <div>
              <br />
              <hr />
              <div
                className="agencyBankInfo"
                style={{
                  fontSize: "large",
                  color: "#124E9C",
                  fontWeight: "bold",
                }}
              >
                Agency Bank Information:
              </div>
              <br />

              {this.state.agencyInfo.map((agencyInfo) => {
                return (
                  <div className="row">
                    <div class="col-xs-12  col-sm-12 col-md-6 col-lg-3">
                      <label>Bank Number</label>:&nbsp;&nbsp;
                      {agencyInfo.bankName}
                    </div>
                    <div class="col-xs-12  col-sm-12 col-md-6 col-lg-3">
                      <label>Account Number</label>:&nbsp;&nbsp;
                      {agencyInfo.accountNumber}
                    </div>
                    <div class="col-xs-12  col-sm-12 col-md-6 col-lg-3">
                      <label>Account Holder Name</label>:&nbsp;&nbsp;
                      {agencyInfo.accountHolderName}
                    </div>
                    <div class="col-xs-12  col-sm-12 col-md-6 col-lg-3">
                      <label>IFSC Code</label>:&nbsp;&nbsp;{agencyInfo.ifscCode}
                    </div>
                  </div>
                );
              })}

              {/* end of 6th row */}
              <br />

              {this.state.agencyInfo.map((agencyInfo) => {
                return (
                  <div className="row">
                    <div class="col-xs-12  col-sm-12 col-md-6 col-lg-3">
                      <label>Pan Number</label>:&nbsp;&nbsp;
                      {agencyInfo.panNumber}
                    </div>
                    <div class="col-xs-12  col-sm-12 col-md-6 col-lg-3">
                      <label>GST Number</label>:&nbsp;&nbsp;
                      {agencyInfo.gstNumber}
                    </div>
                  </div>
                );
              })}
              {/* end of 6th row */}
              <br />

              <hr />
            </div>
          )}

          <div
            className="agencyContactInfo"
            style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}
          >
            Agency Contact Information:
          </div>
          <br />
          <div class="scrollmenu">
            {" "}
            {/* //  Mufiz-Bug-4111  Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) */}
            <table class="table table-bordered" id="myTable3">
              <thead>
                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Contact Id
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;First Name{" "}
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;LastName
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Designation
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Email{" "}
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Country Phone Code
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Phone number
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Role
                  </th>
                </tr>
              </thead>

              <tbody>
                {this.state.agencyContactDetails.map((agencyContactDetails) => {
                  return (
                    <tr>
                      <td>{agencyContactDetails.contactID}</td>
                      <td>{agencyContactDetails.firstName}</td>
                      <td>{agencyContactDetails.lastName}</td>
                      <td>{agencyContactDetails.designation}</td>
                      <td>{agencyContactDetails.email}</td>
                      <td>{agencyContactDetails.countryPhoneCode}</td>
                      <td>{agencyContactDetails.phoneNo}</td>
                      <td>{agencyContactDetails.role}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <br />

          <hr />
          <div
            className="clientSalesforceSetup"
            style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}
          >
            Client Salesforce Setup:
          </div>
          <br />

          {this.state.salesforceClientDetails.length !== 0 ? (    //Chaitanya-4750-Removed Console warning
            <table class="table table-bordered" id="myTable3">
              <thead>
                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Client ID
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Client Name{" "}
                  </th>
                  {/*Task -Akash-3890 Display only Client ID and Name on for Client Salesforce Setup,Client Marketo Setup,Client Hubspot Setup under Agency Info-FE Only*/}
                </tr>
              </thead>

              <tbody>
                {this.state.salesforceClientDetails.map(
                  (salesforceClientDetails) => {
                    return (
                      <tr>
                        <td>{salesforceClientDetails.clientID}</td>
                        <td>{salesforceClientDetails.clientName}</td>
                        {/*Task -Akash-3890 Display only Client ID and Name on for Client Salesforce Setup,Client Marketo Setup,Client Hubspot Setup under Agency Info-FE Only*/}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          ) : (
            <h4 id="salesforce" align="center">
              salesforce clients are not available
            </h4>
          )}
          <hr />
          <div
            className="clientMarketoSetup"
            style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}
          >
            Client Marketo Setup:
          </div>
          <br />
          {this.state.marketoClientInfo.length !== 0 ? (      //Chaitanya-4750-Removed Console warning
            <table class="table table-bordered" id="myTable3">
              <thead>
                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                      width: "200px",
                    }}
                  >
                    &nbsp;Client ID
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                      width: "300px",
                    }}
                  >
                    &nbsp;Client Name{" "}
                  </th>
                  {/*Task -Akash-3890 Display only Client ID and Name on for Client Salesforce Setup,Client Marketo Setup,Client Hubspot Setup under Agency Info-FE Only*/}
                </tr>
              </thead>

              <tbody>
                {this.state.marketoClientInfo.map((marketoClientInfo) => {
                  return (
                    <tr>
                      <td>{marketoClientInfo.clientID}</td>
                      <td>{marketoClientInfo.clientName}</td>
                      {/*Task -Akash-3890 Display only Client ID and Name on for Client Salesforce Setup,Client Marketo Setup,Client Hubspot Setup under Agency Info-FE Only*/}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h4 id="marketo" align="center">
              Marketo clients are not available
            </h4>
          )}
          <hr />
          <div
            className="clientSalesforceSetup"
            style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}
          >
            Client Hubspot Setup:
          </div>
          <br />

          {this.state.hubspotClientInfo.length !== 0 ? (      //Chaitanya-4750-Removed Console warning
            <table class="table table-bordered" id="myTable3">
              <thead>
                <tr style={{ height: "35px" }}>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Client ID
                  </th>
                  <th
                    style={{
                      color: "#707070",
                      backgroundColor: " rgba(126, 127, 129, 0.22)",
                    }}
                  >
                    &nbsp;Client Name{" "}
                  </th>
                  {/*Task -Akash-3890 Display only Client ID and Name on for Client Salesforce Setup,Client Marketo Setup,Client Hubspot Setup under Agency Info-FE Only*/}
                </tr>
              </thead>

              <tbody>
                {this.state.hubspotClientInfo.map((hubspotClientInfo) => {
                  return (
                    <tr>
                      <td>{hubspotClientInfo.clientID}</td>
                      <td>{hubspotClientInfo.clientName}</td>
                      {/*Task -Akash-3890 Display only Client ID and Name on for Client Salesforce Setup,Client Marketo Setup,Client Hubspot Setup under Agency Info-FE Only*/}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h4 id="salesforce" align="center">
              Hubspot clients are not available
            </h4>
          )}
          <hr />
        </div>
        {/* end of container */}

        <Footer />
      </div>
    );
  }
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AgencyInformation.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(AgencyInformation));
