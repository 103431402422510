/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Snehal More
@Creation Date:08-04-2021
@Description:UI for Publisher MyReport under publisher view(under story 3357)
*/
import React from "react";
import PublisherNavigation from "../layouts/publisherNavPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table} from "antd";//kiran-2836-removing console warning-not used
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { css } from 'emotion';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Sandeep-task-3730-added default header
import './publisherMyReport.css';//snehal-task-3460-publisher side--my report--responsiveness issue
const Swal = require("sweetalert2");
// const queryString = require("query-string");//kiran-2836-removing console warning-not used
const tableCSS = css({
  backgroundColor: 'white',
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontFamily: "sans-serif",  //snehal-task-3444-publisher side--report--create/edit report name--validation issue
    fontSize: '12px !important',  //snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue
    fontWeight: '500',
  },
  '& thead > tr >th': {
    textAlign: "center !important", //snehal-task-3460-publisher side--my report--responsiveness issue
    color: 'white',
  }
});


class AgencyDeliveryTrackingReport extends React.Component {
  constructor() {
    super();
    this.state = {
      agencyName: '',//snehal-for agency name
      campaignStatus: '',//snehal-for lead status
      timeline: '',//snehal-for timeline
      agencyDetails: [],//snehal-for agency detail array
      agencyID: '',//snehal-for agency id
      stackHolder: 'Publisher',//snehal-for stackholder
      agencyDeliveryTrackingDetails: [],//snehal-for main data came after applying all filters
      agencyDeliveryDetails: [],//snehal-for arrat for delivery summery
      loader: "",//snehal-for loader when data is fatching from BE
      loader1: "",//snehal-for loader while generating report
      pageSize2: 100,////snehal-for records per page
      tableState: { size: 'small' }//snehal-for table size
    }
    this.commonhandleChange = this.commonhandleChange.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.trackingBackButton = this.trackingBackButton.bind(this);
    this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);

  }// end of constrcutor

      /**
       * @author Snehal More
       * @param  Description ComponentWillMount
       * @return Description render data when we go on page
       */
  //start of componentWillMount
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
      if (this.props.location.state !== undefined) {
      
			let reportID = this.props.location.state.reportID;
      this.state.reportID=reportID;
      reportName=this.props.location.state.reportName;
      this.state.reportName=reportName; //kiran-2836-not resolved giving issue
      }
      // const { isAuthenticated } = this.props.auth;//kiran-2836-removing console warning-not used
       // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
      // const token = user.token;
      //authorization: token
      var reportName = this.state.reportName;
      let data = {  }
      fetch("/publisherReports/getAgencyListForATR", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(agencyDetails => {
          this.setState({ agencyDetails: agencyDetails });
        }).catch(function (err) { console.log(err) });
      //API call for agency
      // var parsed = queryString.parse(this.props.location.search); //kiran-2836-removing console warning-not used
      // var reportID = parsed.reportID;
      // this.setState({ reportID: reportID })
      //user: user,
      if (this.state.reportID) {
        let data = {  reportID: this.state.reportID }
        // let data = { user: user, reportID: reportID }
       // authorization: token
        fetch("/publisherReports/getInfoAgencyTrackingReport", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            
          },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(agencyDeliveryTrackingDetails => {
            this.setState({
              agencyDeliveryTrackingDetails: agencyDeliveryTrackingDetails[0].agencyDeliveryTrackingReportDetails, campaignStatus: agencyDeliveryTrackingDetails[0].campaignStatus, agencyID: agencyDeliveryTrackingDetails[0].agencyID,
              timeline: agencyDeliveryTrackingDetails[0].deadline, agencyName: agencyDeliveryTrackingDetails[0].agencyName, agencyDeliveryDetails: agencyDeliveryTrackingDetails[0].agencyDeliverySummaryDetails
            })
          }).catch(function (err) { console.log(err) });
        //API call for old report
      }
    }
  }

        /**
       * @author Snehal More
       * @param  Description handle trackingBackButton
       * @return Description back to publisherMyReports
       */
  trackingBackButton() {
      // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
    // const { user } = this.props.auth
    // var pID = user.id;
    if (Number(this.state.agencyDeliveryTrackingDetails.length) === 0 || this.state.reportID) { 
      //kiran-2836-removing console warning-changed == to ===
      window.location.href = "publisherMyReport";
    }
    else {
      Swal.fire({
        //text: "Basic Details Saved",
        title: 'Are you sure you want to discard this report?',
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonText: "Discard",
      }).then((result) => {
        if (result.value) {
          window.location.href = "publisherMyReport";
        } else if (result.dismiss === "close") {
          window.location.href = "#";
        } else {
          window.location.href = "#";
        }
      });
    }
  }//handel change for pagination
  //handel change for pagination
          /**
       * @author Snehal More
       * @param  Description handle generateReport
       * @return Description Generate report when data is present
       */
  generateReport(e) {
    e.preventDefault();
    // const { isAuthenticated } = this.props.auth; //kiran-2836-removing console warning-not used
      // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
    // var pID = user.id;
    // const token = user.token;
    // var parsed = queryString.parse(this.props.location.search);//kiran-2836-removing console warning-not used
    var agencyIDName = this.state.agencyIDName;
    // var reportName = parsed.reportName;
    // var reportType = parsed.reportType;
      // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
    if (this.props.location.state !== undefined) {
			let	reportName = this.props.location.state.reportName;
      let	reportType = this.props.location.state.reportType;
      //  this.setState({reportID})
      this.setState({reportName:reportName,reportType:reportType}); //kiran-2836-removing console warning-use setState
      }
    var campaignStatus = this.state.campaignStatus;
    var timeline = this.state.timeline;
    var stackHolder = this.state.stackHolder;
    this.setState({ loader1: "gen_loader" })
    //user: user,
    let data = {
     
      agencyIDName: agencyIDName,
      reportName: this.state.reportName,
      type: this.state.reportType,
      campaignStatus: campaignStatus,
      deadline: timeline,
      stackHolder: stackHolder,
      agencyTrackingDetails: this.state.agencyDeliveryTrackingDetails,
      agencyDeliveryDetails: this.state.agencyDeliveryDetails
    }
    //authorization: token 
    fetch("/publisherReports/generateAgencyTrackingReport", {
      method: "POST",
      headers: { "Content-Type": "application/json",},
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(response => {
        this.setState({ loader1: "" })
        Swal.fire({
          html: "<h4><h4>Report generated successfully</h4><h4><a href='#'>Please </a><a href='publisherMyReport'><span style='color:#F28C0F'>click here</span></a><a href='#'> to check report</a></h4></h4>",
          icon: "Success",
          type: "success",
          confirmButtonColor: "#144999",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            window.location.href = "publisherMyReport";
          } else if (result.dismiss === "close") {
            window.location.href = "publisherMyReport";
          } else {
            // var parsed = queryString.parse(this.props.location.search);//kiran-2836-removing console warning-not used
            // var reportID = parsed.reportID;
          }
        });
        //this.setState({agencyDeliveryTrackingDetails:agencyDeliveryTrackingDetails})
      }).catch(function (err) { console.log(err) });

  }//handle change generate report
          /**
       * @author Snehal More
       * @param  Description handle common handle change
       * @return Description to set filters 
       */
  commonhandleChange(e) {
    const { name} = e.target
    // const { isAuthenticated } = this.props.auth;//kiran-2836-removing console warning-not used
      // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
    // var pID = user.id;
    // const token = user.token;
    //user: user
    //authorization: token
    // var parsed = queryString.parse(this.props.location.search); //kiran-2836-removing console warning-not used
     // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE 
    if (this.props.location.state !== undefined) {
			let	reportName = this.props.location.state.reportName;
      let	reportType = this.props.location.state.reportType;
      //  this.setState({reportID})
      this.setState({reportName: reportName,reportType: reportType});//kiran-2836-removing console warning-use setState
      }
    // var reportType = this.state.reportType;
    if (name.toString() === "agencyIDName") { //kiran-2836-removing console warning-changed == to ===
      this.setState({ agencyIDName: e.target.value });
      let data = { agencyIDName: e.target.value, campaignStatus: this.state.campaignStatus, deadline: this.state.timeline, type: this.state.reportType, }
      if (e.target.value !== "" && this.state.campaignStatus !== "" && this.state.timeline !== "") { //kiran-2836-removing console warning-changed != to !==
        this.setState({ loader: "loader" })
        fetch("/publisherReports/getDataForAgencyDeliveryTrackingReport", {
          method: "POST",
          headers: { "Content-Type": "application/json",  },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(agencyDeliveryTrackingDetails => {
            this.setState({ agencyDeliveryTrackingDetails: agencyDeliveryTrackingDetails.reportTrackingDetails, agencyDeliveryDetails: agencyDeliveryTrackingDetails.agencyDeliveryDetails, loader: "" })
          }).catch(function (err) { console.log(err) });
      }
      else {
        this.setState({ agencyDeliveryTrackingDetails: [], agencyDeliveryDetails: [] })
      }
      var agency_id_name = e.target.value.split("-");
      let agencyID = agency_id_name[0]
      let agencyName = agency_id_name[1]
      this.setState({ agencyID: agencyID, agencyName: agencyName })
    }
    //user: user
    //authorization: token
    if (name.toString()=== "campaignStatus") { //kiran-2836-removing console warning-changed == to ===
      this.setState({ campaignStatus: e.target.value });
      let data = { campaignStatus: e.target.value, agencyIDName: this.state.agencyIDName, deadline: this.state.timeline, type: this.state.reportType, }
      if ((this.state.agencyIDName !== "" && this.state.agencyIDName !== undefined) && e.target.value !== "" && this.state.timeline !== "") {
        this.setState({ loader: "loader" })
        fetch("/publisherReports/getDataForAgencyDeliveryTrackingReport", {
          method: "POST",
          headers: { "Content-Type": "application/json",  },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(agencyDeliveryTrackingDetails => {
            this.setState({ agencyDeliveryTrackingDetails: agencyDeliveryTrackingDetails.reportTrackingDetails, agencyDeliveryDetails: agencyDeliveryTrackingDetails.agencyDeliveryDetails, loader: "" })
          }).catch(function (err) { console.log(err) });
      }
      else {
        this.setState({ agencyDeliveryTrackingDetails: [], agencyDeliveryDetails: [] })
      }
    }
   // user: user
  // authorization: token
    if (name.toString() === "timeline") { //kiran-2836-removing console warning-changed == to ===
      this.setState({ timeline: e.target.value });
      let data = { agencyIDName: this.state.agencyIDName, campaignStatus: this.state.campaignStatus, deadline: e.target.value, type: this.state.reportType }
      if ((this.state.agencyIDName !== "" && this.state.agencyIDName!== undefined) && this.state.campaignStatus !== "" && e.target.value!== "") {
        this.setState({ loader: "loader" })
        fetch("/publisherReports/getDataForAgencyDeliveryTrackingReport", {
          method: "POST",
          headers: { "Content-Type": "application/json",  },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(agencyDeliveryTrackingDetails => {
            this.setState({ agencyDeliveryTrackingDetails: agencyDeliveryTrackingDetails.reportTrackingDetails, agencyDeliveryDetails: agencyDeliveryTrackingDetails.agencyDeliveryDetails, loader: "" })

          }).catch(function (err) { console.log(err) });
      }
      else {
        this.setState({ agencyDeliveryTrackingDetails: [], agencyDeliveryDetails: [] })
      }
    }
  }//handle search box
           /**
       * @author Snehal More
       * @param  Description handle handleChangePageSize2
       * @return Description render records per page 
       */
  handleChangePageSize2(e) {
    var pageSize2 = e.target.value;
    this.setState({ pageSize2: pageSize2 });
  }
 //snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue(data font size changes)
  render() {
    // const { isAuthenticated } = this.props.auth;
    const Column = [
      {
        title: <span>Camp ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'campID',
        key: 'campID',
        width: '8%',
        align: 'left',
        sorter: (a, b) => a.campID - b.campID,
        render: (text, record) =>
          <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.campID}</span></div>
      },
      {
        title: <span>Agency Camp ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'clientCampID',
        key: 'clientCampID',
        width: '8%',
        align: 'left',
        sorter: (a, b) => a.clientCampID - b.clientCampID,
        render: (text, record) =>
          <div>
            <span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.clientCampID}</span>
          </div>
      },
      {
        title: <span>Advertiser&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'clientName',
        key: 'clientName',
        width: '12%',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.clientName.toUpperCase() < b.clientName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.clientName.toUpperCase() > b.clientName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          <div id="to_truncate1"><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.clientName}</span></div>
      },
      {
        title: <span>Camp Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'campaignName',
        key: 'campaignName',
        width: '18%',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          <div id="to_truncate1"><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.campaignName}</span></div>
      },
      {
        title: <span>Start Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'startDate',
        key: 'startDate',
        width: '10%',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.startDate.toUpperCase() < b.startDate.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.startDate.toUpperCase() > b.startDate.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.startDate}</span></div>
      },
      {
        title: <span>End Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'endDate',
        key: 'endDate',
        width: '10%',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.endDate.toUpperCase() < b.endDate.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.endDate.toUpperCase() > b.endDate.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.endDate}</span></div>
      },
      {
        title: <span>Allocated Leads&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'allocatedLead',
        key: 'allocatedLead',
        width: '8%',
        align: 'left',
        sorter: (a, b) => a.allocatedLead - b.allocatedLead,
        render: (text, record) =>
          <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.allocatedLead}</span></div>
      },
      {
        title: "Delivery Status",
        children: [
          {
            title: <span>Delivered&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: "deliveredLead",
            key: "deliveredLead",
            align: 'left',
            width: "7%",
            sorter: (a, b) => a.deliveredLead - b.deliveredLead,
            render: (text, record) => (
              <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.deliveredLead}</span></div>
            )
          },
          {
            title: <span>Review Pending&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: "qaReviewLead",
            key: "qaReviewLead",
            align: 'left',
            width: "7%",
            sorter: (a, b) => a.qaReviewLead - b.qaReviewLead,
            render: (text, record) => (
              <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.qaReviewLead}</span></div>
            )
          },
          {
            title: <span>Accepted&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: "acceptedLead",
            key: "acceptedLead",
            align: 'left',
            width: "7%",
            sorter: (a, b) => a.acceptedLead - b.acceptedLead,
            render: (text, record) => (
              <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.acceptedLead}</span></div>
            )
          },
          {
            title: <span>Rejected&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: "rejectedLead",
            key: "rejectedLead",
            align: 'left',
            width: "7%",
            sorter: (a, b) => a.rejectedLead - b.rejectedLead,
            render: (text, record) => (
              <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.rejectedLead}</span></div>
            )
          },
          {
            title: <span>Balance&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: "balancedLead",
            key: "balancedLead",
            align: 'left',
            width: "7%",
            sorter: (a, b) => a.balancedLead - b.balancedLead,
            render: (text, record) => (
              <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.balancedLead}</span></div>
            )
          },
        ]
      },

    ];
    function onChange(pagination, filters, sorter) {
    }
    return (
      <div >
        <PublisherNavigation />
        <div style={{ margin: '20px' }}>
          <div class="container-fluid" style={{ paddingTop: '80px', paddingLeft: '0px', paddingRight: '0px' }}>
            <div class="row">
              {/* kiran-2836-added #/ */}
              <div class="col-lg-2 col-md-1 col-sm-1" >
                < a class="col" href='#/' style={{ color: '#056eb8', float: 'left' }} onClick={this.trackingBackButton.bind(this)} >
                  <FaArrowAltCircleLeft size={32} title="" className="backFrom_myReport" />
                </a>
              </div>
              {/* //snehal-task-3444-publisher side--report--create/edit report name--validation issue(changes related to UI) */}
              <div class=" col-lg-8 col-md-8 col-sm-8" align="center">
                <center><label id="labelDI" style={{ fontSize: '28px', fontStyle: 'roboto', fontWeight: '500', color: '#193D8F' }} align="center">Agency Delivery Tracking Report</label></center>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-12" >
                {/* //snehal-task-3461-Publisher side--My report UI issue-regarding design(popup will close on click on edit) */}
                {this.state.reportID ? "" :
                  this.state.agencyDeliveryTrackingDetails.length === 0 ?
                    <button class="btn add-button" disabled type="submit" value="submit1" style={{ margin: '5px', float: 'right' }} onClick={this.generateReport}>
                      Generate Report
                    </button>
                    : <button class="btn add-button" type="submit" value="submit1" style={{ margin: '5px', float: 'right' }} onClick={this.generateReport}>
                      Generate Report
                      </button>}
              </div>
            </div><br />
            {this.state.reportID ?
              <div class="row" style={{ marginLeft: '10px' }}>
                <div class="col-lg-4 col-md-4 col-sm-12" >
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Agency : <span className="asterisk">*</span>&nbsp;</label>
                    <select disabled
                      id="agencyIDName"
                      name="agencyIDName"
                      value={this.state.agencyIDName}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value={this.state.agencyName}>{this.state.agencyName}</option>

                    </select>
                  </div>
                </div>
                <div class=" col-lg-4 col-md-4 col-sm-12">
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Campaign Status : <span className="asterisk">*</span>&nbsp;</label>
                    <select disabled
                      id="campaignStatus"
                      name="campaignStatus"
                      value={this.state.campaignStatus}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value="" selected>Select Campaign Status</option> {/* //snehal-task-3460-publisher side--my report--responsiveness issue */}
                      <option value="Active" >Active</option>
                      <option value="Pause" >Paused</option>
                      <option value="Completed" >Completed</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Timeline : <span className="asterisk">*</span>&nbsp;</label>
                    <select disabled
                      id="timeline"
                      name="timeline"
                      value={this.state.timeline}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value="" selected>Select Timeline</option>
                      <option value="All" >All</option>
                      <option value="NoLead" >No Lead Delivered</option>
                      <option value="3Days">Ending 3 Days</option>
                      <option value="Weekly">Ending This Week</option>
                      <option value="Monthly">Ending This Month</option>
                      <option value="Quarterly">Ending This Quarter</option>
                      <option value="Yearly">Ending this Year</option>
                      <option value="PreMonth">Ending Previous Month</option>
                      <option value="PreQuarter">Ending Previous Quarter</option>
                      <option value="PreYear">Ending Previous Year</option>
                    </select>
                  </div>
                </div>
              </div>
              : <div class="row" style={{ marginLeft: '10px' }}>
                <div class="col-lg-4 col-md-4 col-sm-12" >
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Agency : <span className="asterisk">*</span>&nbsp;</label>
                    <select
                      id="agencyIDName"
                      name="agencyIDName"
                      value={this.state.agencyIDName}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value="">Select Agency</option>
                      {this.state.agencyDetails.map((agencyDetails) => {
                        return <option value={agencyDetails.agencyIDName}>{agencyDetails.agencyName}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div class=" col-lg-4 col-md-4 col-sm-12">
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Campaign Status : <span className="asterisk">*</span>&nbsp;</label>
                    <select
                      id="campaignStatus"
                      name="campaignStatus"
                      value={this.state.campaignStatus}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value="" selected>Select Campaign Status</option> {/* //snehal-task-3460-publisher side--my report--responsiveness issue */}
                      <option value="Active" >Active</option>
                      <option value="Pause" >Paused</option>
                      <option value="Completed" >Completed</option>
                    </select>
                  </div>
                </div>
                <div class=" col-lg-4 col-md-4 col-sm-12">
                  <div class="row">
                    <label style={{ marginTop: '5px' }}>Timeline : <span className="asterisk">*</span>&nbsp;</label>
                    <select
                      id="timeline"
                      name="timeline"
                      value={this.state.timeline}
                      class="form-control inputStyle"
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px' }}
                      onChange={this.commonhandleChange}>
                      <option value="" selected>Select Timeline</option>
                      <option value="All">All</option>
                      <option value="NoLead" >No Lead Delivered</option>
                      <option value="3Days">Ending 3 Days</option>
                      <option value="Weekly">Ending This Week</option>
                      <option value="Monthly">Ending This Month</option>
                      <option value="Quarterly">Ending This Quarter</option>
                      <option value="Yearly">Ending this Year</option>
                      <option value="PreMonth">Ending Previous Month</option>
                      <option value="PreQuarter">Ending Previous Quarter</option>
                      <option value="PreYear">Ending Previous Year</option>
                    </select>
                  </div>
                </div>
              </div>}
          </div>
          <div class="row" align="center" id={this.state.loader1}></div>
          <div class="row" align="center" id={this.state.loader}></div>
          {/* //snehal-task-3460-publisher side--my report--responsiveness issue(changed done for color of text) */}
          {this.state.agencyDeliveryDetails.length > 0 ?
            <div style={{ border: '1px solid #CCCCCC', margin: '5px', padding: '10px', backgroundColor: '#FFFFFF', borderRadius: '3px' }}>{/* //snehal-task-3461-Publisher side--My report UI issue-regarding design(popup will close on click on edit) */}
              <div class="row" style={{ marginBottom: "-17px" }}>
              {/* kiran-2836-removing console warning-changed != to !== */}
                <p class="col-lg-12 col-md-12 col-sm-12" style={{ color: '#F28C0F', fontSize: '16px' }}><b>Delivery Summary : &nbsp;<label style={{ color: '#14254a', fontSize: '14px' }}>{this.state.agencyDeliveryDetails.length > 0 && this.state.agencyDeliveryDetails[0].deliveryTimeline !== "" ? <b>{this.state.agencyDeliveryDetails[0].deliveryTimeline}</b> : <b>{this.state.timeline}</b>}</label></b></p>
              </div> <hr />
              <div class="row">
                <p class="col-lg-2 col-md-6 col-sm-12"><label ><span style={{ color: '#14254a', fontSize: '14px' }}>Agency ID : &nbsp; </span>{this.state.agencyID}</label></p>
                <p class="col-lg-4 col-md-6 col-sm-12"><label ><span style={{ color: '#14254a', fontSize: '14px' }}>Agency Name : &nbsp; </span>{this.state.agencyName}</label></p>
                {/* */}
                <p class="col-lg-2 col-md-6 col-sm-12"><label ><span style={{ color: '#14254a', fontSize: '14px' }}>Report ID : &nbsp; </span>{this.state.reportID}</label></p>
                <p class="col-lg-4 col-md-6 col-sm-12"><label ><span style={{ color: '#14254a', fontSize: '14px' }}>Report Name : &nbsp; </span>{this.state.reportName}</label></p>
                {/*Chaitanya-4099-Reportname and ReportID- not getting display once click on the report name link  */}
              </div><hr/> {/* //snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue */}
              <div class="row" style={{ marginBottom: "-8px" }}>
                <p class="col-lg-12 col-md-12 col-sm-12" ><label style={{ color: '#14254a', fontSize: '14px' }}>Total Count : &nbsp;</label></p>
              </div>
              <div class="row" style={{ marginBottom: "-10px" }}>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Allocated Lead : &nbsp; <span style={{ color: '#414141' }}>{this.state.agencyDeliveryDetails[0].agencyAllocatedLead != null ? this.state.agencyDeliveryDetails[0].agencyAllocatedLead : 0}</span></p>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Delivered : &nbsp; <span style={{ color: '#193D8F' }}>{this.state.agencyDeliveryDetails[0].agencyDeliveredLead != null ? this.state.agencyDeliveryDetails[0].agencyDeliveredLead : 0}</span></p>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Review Pending : &nbsp;<span style={{ color: '#F28C0F' }}>{this.state.agencyDeliveryDetails[0].agencyQAReviewLead}</span></p>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Accepted : &nbsp;<span style={{ color: '#008000' }}>{this.state.agencyDeliveryDetails[0].agencyAcceptedLead != null ? this.state.agencyDeliveryDetails[0].agencyAcceptedLead : 0}</span></p>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Rejected : &nbsp;<span style={{ color: '#FF0000' }}>{this.state.agencyDeliveryDetails[0].agencyRejectedLead != null ? this.state.agencyDeliveryDetails[0].agencyRejectedLead : 0}</span></p>
                <p class="col-lg-2 col-md-6 col-sm-12" style={{ color: '#4F4F4F' }}>Balance : &nbsp; <span style={{ color: '#4F4F4F' }}>{this.state.agencyDeliveryDetails[0].agencyBalancedLead != null ? this.state.agencyDeliveryDetails[0].agencyBalancedLead : 0}</span></p>

              </div>

            </div>
            : ''}
          {/* //snehal-task-3461-Publisher side--My report UI issue-regarding design(popup will close on click on edit) */}
          {this.state.agencyDeliveryDetails.length > 0 ?
            <p style={{ color: '#717171', marginBottom: '0px' }}>&nbsp;&nbsp;Showing results  <label style={{ color: '#414141' }}>{this.state.agencyDeliveryTrackingDetails.length}</label></p> : ""}
          <div class="table-responsive" >
            {/*kiran-2836-changed classname*/}
            <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.agencyDeliveryTrackingDetails}
              onChange={onChange} columns={Column}  className={`${tableCSS} "ant-table-thead"`}  scroll={{ y: 700, x: 1000, }}
              pagination={{ pageSize: this.state.pageSize2 }}></Table>
            {this.state.agencyDeliveryDetails.length > 0 ?
              <p>
                <div class="row" style={{ paddingBottom: '120px' }}>
                {/* //snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue */}
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{ marginTop: '-50px',fontFamily:'sans-serif',fontSize:'13px',color:'#4F4F4F' }}>
                    &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                  <select
                      defaultValue={this.state.pageSize2}
                      onChange={this.handleChangePageSize2}
                      id="pacing1"
                      class="input-small"
                      className="form-control"
                      name="pacing" style={{ width: '80px', height: '30px', display: 'initial' }}>
                      <option value="100" selected>100</option>
                      <option value="150" >150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                  </div>
                </div>
              </p> : ""}

          </div>
        </div>
        <Footer />
      </div>
    );
  }// end of renders
}// end of class

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
AgencyDeliveryTrackingReport.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(AgencyDeliveryTrackingReport));

