/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author: Snehal More
 *@fileName :clientSetup.js
 *Desc: marketo client set up for marketo lead
 */

import React from "react";
import { Component } from "react";
import AdminNavpage from "../layouts/adminNavpage";
import Footer from "../layouts/footer";
import "./clientSetup.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3927-VAPT URL changes
const Swal = require("sweetalert2");
class MarketoClientSetup extends Component {
  constructor() {
    super();
    this.state = {
      clientID: "",
      mappingFile: "",
      mappingFileObject: [], //Akash Task-3887 Created this array to store mapping object from BE
      mappingFileName: "",
      fileName: "",
      clientNameDisplay: "none",
      agencyNameDisplay: "none",
      marketoClientIDDisplay: "none",
      marketoClientIDDisplay1: "none",
      marketoClientSecretDisplay: "none",
      marketoClientSecretDisplay1: "none",
      emailDisplay: "none",
      emailDisplay1: "none",
      endPointUrlDisplay: "none",
      endPointUrlDisplay1: "none",
      identityUrlDisplay: "none",
      identityUrlDisplay1: "none",
      mappingFileDisplay: "none",
      mappingFileDisplay1: "none",
      mappingnameDisplay: "none",
      allClientNameDetails: [],
      allAgencyNameDetails: [],
      //Priyanka-4641-Added Form ID
      //Priyanka-4663-Added formCheck
      ClientSetupArray: [
        {
          clientName: "",
          agencyName: "",
          marketoClientID: "",
          marketoClientSecret: "",
          email: "",
          endPointURL: "",
          identityURL: "",
          formID: "",
          formCheck: "No",
          programCheck: "No",
        },
      ],
      formIDDisplay: "none",
      formIDDisplay1: "none",
      formCheck: "No",
      //Priyanka-4658-Added formCheckFlag
      formCheckFlag: false,
      //4739-Added programCheckFlag
      programCheck: "No",
      programCheckFlag: false,
    };
    this.commonhandleChange = this.commonhandleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleChangeFormCheck = this.handleChangeFormCheck.bind(this);
    this.handleChangeProgramCheck = this.handleChangeProgramCheck.bind(this);
  }
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      //const { user } = this.props.auth;//Rutuja 4796 commented as never used
      fetch("/marketoClient/allClientNameDetailsForMarketo")
        .then((res) => res.json())
        .then((allClientNameDetails) => {
          this.setState({ allClientNameDetails: allClientNameDetails });
        });
      fetch("/marketoClient/allAgencyNameDetailsForMarketo")
        .then((res) => res.json())
        .then((allAgencyNameDetails) => {
          this.setState({ allAgencyNameDetails: allAgencyNameDetails });
        });
    }
  }
  clientSetupBackButton(e) {
    e.preventDefault()//Rutuja task 4825 console warrning
   // const { user } = this.props.auth;//Rutuja 4796 commented as never used
   // var adminID = user.id;//Rutuja 4796 commented as never used
    window.location.href = "adminDashboard"; //kiran - task 3889 - remove admin Id
  }
  commonhandleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "clientName") {
      document.getElementById("marketoClientName").style.border =
        "1px solid lightgray";
      document.getElementById("agencyName").style.border =
        "1px solid lightgray";
      document.getElementById("marketoClientID").style.border =
        "1px solid lightgray";
      document.getElementById("marketoClientSecret").style.border =
        "1px solid lightgray";
      document.getElementById("email").style.border = "1px solid lightgray";
      document.getElementById("endPointURL").style.border =
        "1px solid lightgray";
      document.getElementById("identityURL").style.border =
        "1px solid lightgray";
      document.getElementById("mappingFile").value = "";
      this.setState({
        clientNameDisplay: "none",
        mappingFileName: "",
        agencyNameDisplay: "none",
        marketoClientIDDisplay: "none",
        marketoClientIDDisplay1: "none",
        marketoClientSecretDisplay: "none",
        marketoClientSecretDisplay1: "none",
        emailDisplay: "none",
        emailDisplay1: "none",
        endPointUrlDisplay: "none",
        endPointUrlDisplay1: "none",
        identityUrlDisplay: "none",
        identityUrlDisplay1: "none",
        mappingFileDisplay: "none",
        mappingFileDisplay1: "none",
        formIDDisplay: "none",
        formIDDisplay1: "none",
      });
      let ClientSetupArray = [...this.state.ClientSetupArray];
      ClientSetupArray[0] = { ...ClientSetupArray[0], "clientName": value }; //Rutuja 4796 removed [] from ["client "] for console warrning
      this.state.ClientSetupArray = [...ClientSetupArray];
      //Priyanka-4658-enabling form check box after selecting client and agency
      this.setState({
        ClientSetupArray: ClientSetupArray,
        formCheckFlag: true,
        programCheckFlag: true,
      });
      if (
        this.state.ClientSetupArray[0].clientName.length > 0 &&
        this.state.ClientSetupArray[0].agencyName.length > 0
      ) {
        let data = {
          clientSetupDetails: this.state.ClientSetupArray,
        };
        fetch("/marketoClient/marketoClientSetupDetails", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((marketoClientSetupDetails) => {
            if (marketoClientSetupDetails.length > 0) {
              this.setState({
                ClientSetupArray: marketoClientSetupDetails,
                mappingFileName: marketoClientSetupDetails[0].mappingFileName,
                mappingFile: marketoClientSetupDetails[0].mapping,
                mappingnameDisplay: "none",
              });
              Swal.fire({
                text: "Marketo client Setup is already exists",
                type: "question",
                confirmButtonText: "OK",
                allowOutsideClick: false,
              });
            } else {
              var ClientSetupArray = [
                {
                  clientName: this.state.ClientSetupArray[0].clientName,
                  agencyName: this.state.ClientSetupArray[0].agencyName,
                  email: "",
                  marketoClientID: "",
                  marketoClientSecret: "",
                  endPointURL: "",
                  identityURL: "",
                  mappingFile: null,
                  mappingFileName: "",
                  formID: "",
                },
              ];
              this.setState({
                ClientSetupArray: ClientSetupArray,
                fileName: "",
                mappingnameDisplay: "none",
              });

              Swal.fire({
                text: "Marketo client setup not exists",
                type: "question",
                confirmButtonText: "OK",
                allowOutsideClick: false,
              });
            }
          });
      }
    }
    if (name === "agencyName") {
      document.getElementById("marketoClientName").style.border =
        "1px solid lightgray";
      document.getElementById("agencyName").style.border =
        "1px solid lightgray";
      document.getElementById("marketoClientID").style.border =
        "1px solid lightgray";
      document.getElementById("marketoClientSecret").style.border =
        "1px solid lightgray";
      document.getElementById("email").style.border = "1px solid lightgray";
      document.getElementById("endPointURL").style.border =
        "1px solid lightgray";
      document.getElementById("identityURL").style.border =
        "1px solid lightgray";
      document.getElementById("mappingFile").value = "";
      this.setState({
        agencyNameDisplay: "none",
        mappingFileName: "",
        marketoClientIDDisplay: "none",
        marketoClientIDDisplay1: "none",
        marketoClientSecretDisplay: "none",
        marketoClientSecretDisplay1: "none",
        emailDisplay: "none",
        emailDisplay1: "none",
        endPointUrlDisplay: "none",
        endPointUrlDisplay1: "none",
        identityUrlDisplay: "none",
        identityUrlDisplay1: "none",
        mappingFileDisplay: "none",
        mappingFileDisplay1: "none",
        formIDDisplay: "none",
        formIDDisplay1: "none",
      });

      let ClientSetupArray = [...this.state.ClientSetupArray];
      ClientSetupArray[0] = { ...ClientSetupArray[0], "agencyName": value }; //Rutuja task 4796 chabged ["agencyName"] 
      this.state.ClientSetupArray = [...ClientSetupArray];
      //Priyanka-4658-enabling form check box after selecting client and agency
      this.setState({
        ClientSetupArray: ClientSetupArray,
        formCheckFlag: true,
        programCheckFlag: true,
      });

      if (
        this.state.ClientSetupArray[0].clientName.length > 0 &&
        this.state.ClientSetupArray[0].agencyName.length > 0
      ) {
        let data = {
          clientSetupDetails: this.state.ClientSetupArray,
        };
        fetch("/marketoClient/marketoClientSetupDetails", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((marketoClientSetupDetails) => {
            if (marketoClientSetupDetails.length > 0) {
              this.setState({
                ClientSetupArray: marketoClientSetupDetails,
                mappingFileName: marketoClientSetupDetails[0].mappingFileName,
                mappingFile: marketoClientSetupDetails[0].mapping,
                mappingnameDisplay: "none",
              });
              Swal.fire({
                text: "Marketo client setup is already exists",
                type: "question",
                confirmButtonText: "OK",
                allowOutsideClick: false,
              });
            } else {
              var ClientSetupArray = [
                {
                  clientName: this.state.ClientSetupArray[0].clientName,
                  agencyName: this.state.ClientSetupArray[0].agencyName,
                  email: "",
                  marketoClientID: "",
                  endPointURL: "",
                  identityURL: "",
                  marketoClientSecret: "",
                  mappingFile: null,
                  mappingFileName: "",
                  formID: "",
                },
              ];
              this.setState({
                ClientSetupArray: ClientSetupArray,
                fileName: "",
                mappingnameDisplay: "none",
              });
              Swal.fire({
                text: "Marketo client setup not exists",
                type: "question",
                confirmButtonText: "OK",
                allowOutsideClick: false,
              });
            }
          });
      }
    }
    if (name === "marketoClientID") {
      document.getElementById("marketoClientID").style.border =
        "1px solid lightgray";
      this.setState({
        marketoClientIDDisplay: "none",
        marketoClientIDDisplay1: "none",
      });
      let ClientSetupArray = [...this.state.ClientSetupArray];
      ClientSetupArray[0] = {
        ...ClientSetupArray[0],
        "marketoClientID": value, //Rutuja task 4796 chabged ["marketoClientID"] 
      };
      this.state.ClientSetupArray = [...ClientSetupArray];
      this.setState({ ClientSetupArray: ClientSetupArray });
    }
    if (name === "marketoClientSecret") {
      document.getElementById("marketoClientSecret").style.border =
        "1px solid lightgray";
      this.setState({
        marketoClientSecretDisplay: "none",
        marketoClientSecretDisplay1: "none",
      });
      let ClientSetupArray = [...this.state.ClientSetupArray];
      ClientSetupArray[0] = {
        ...ClientSetupArray[0],
        "marketoClientSecret": value, //Rutuja task 4796 chabged ["marketoClientSecret"]
      };
      this.state.ClientSetupArray = [...ClientSetupArray];
      this.setState({ ClientSetupArray: ClientSetupArray });
    }
    if (name === "email") {
      document.getElementById("email").style.border = "1px solid lightgray";
      this.setState({ emailDisplay: "none", emailDisplay1: "none" });
      let ClientSetupArray = [...this.state.ClientSetupArray];
      ClientSetupArray[0] = { ...ClientSetupArray[0], "email": value }; //Rutuja task 4796 chabged ["email"]:
      this.state.ClientSetupArray = [...ClientSetupArray];
      this.setState({ ClientSetupArray: ClientSetupArray });
    }
    if (name === "endPointURL") {
      document.getElementById("endPointURL").style.border =
        "1px solid lightgray";
      this.setState({
        endPointUrlDisplay: "none",
        endPointUrlDisplay1: "none",
      });
      let ClientSetupArray = [...this.state.ClientSetupArray];
      ClientSetupArray[0] = { ...ClientSetupArray[0], "endPointURL": value }; //Rutuja task 4796 chabged ["endPointURL"]
      this.state.ClientSetupArray = [...ClientSetupArray];
      this.setState({ ClientSetupArray: ClientSetupArray });
    }
    if (name === "identityURL") {
      document.getElementById("identityURL").style.border =
        "1px solid lightgray";
      this.setState({
        identityUrlDisplay: "none",
        identityUrlDisplay1: "none",
      });
      let ClientSetupArray = [...this.state.ClientSetupArray];
      ClientSetupArray[0] = { ...ClientSetupArray[0], "identityURL": value }; //Rutuja task 4796 chabged ["identityURL"]
      this.state.ClientSetupArray = [...ClientSetupArray];
      this.setState({ ClientSetupArray: ClientSetupArray });
    }

    if (name === "mappingFile") {
      let files = e.target.files;
      let fileData = e.target.files[0];
      var fileName = e.target.files[0].name;
      var HTML = "<b>";
      HTML += "Uploaded File : " + fileName;
      HTML += "</b>";
      document.getElementById("mappingName").innerHTML = HTML;
      if (files.length > 0) {
        this.setState({
          mappingFile: fileData,
          mappingFileDisplay: "none",
          mappingFileDisplay1: "none",
          mappingFileName: "",
          mappingnameDisplay: "block",
        });
      }
    }

    //Priyanka-4641-getting form ID
    if (name === "formID") {
      document.getElementById("formID").style.border = "1px solid lightgray";
      this.setState({ formIDDisplay: "none", formIDDisplay1: "none" });
      let ClientSetupArray = [...this.state.ClientSetupArray];
      ClientSetupArray[0] = { ...ClientSetupArray[0], "formID": value }; //Rutuja task 4796 chabged ["formID"]:
      this.state.ClientSetupArray = [...ClientSetupArray];
      this.setState({ ClientSetupArray: ClientSetupArray });
    }
  }

  validateForm() {
    let formIsvalid = true;
    //Priyanka-4658-adding formCheck
    const {
      clientName,
      email,
      agencyName,
      marketoClientID,
      marketoClientSecret,
      endPointURL,
      identityURL,
      formID,
      //formCheck, //Rutuja 4796 commented as never used
      //programCheck, //Rutuja 4796 commented as never used
    } = this.state.ClientSetupArray[0];
    //alert(JSON.stringify(this.state.ClientSetupArray))
    if (clientName === "") {
      document.getElementById("marketoClientName").style.border =
        "1px solid red";
      this.setState({ clientNameDisplay: "block" });
      formIsvalid = false;
    }
    if (agencyName === "") {
      document.getElementById("agencyName").style.border = "1px solid red";
      this.setState({ agencyNameDisplay: "block" });
      formIsvalid = false;
    }

    if (marketoClientID === "") {
      document.getElementById("marketoClientID").style.border = "1px solid red";
      this.setState({ marketoClientIDDisplay: "block" });
      formIsvalid = false;
    }
    if (marketoClientID !== "") {
      var pattern1 = new RegExp(/^[0-9A-Za-z\s\-]+$/);
      if (!pattern1.test(marketoClientID)) {
        document.getElementById("marketoClientID").style.border =
          "1px solid red";
        this.setState({ marketoClientIDDisplay1: "block" });
        formIsvalid = false;
      }
    }
    if (marketoClientSecret === "") {
      document.getElementById("marketoClientSecret").style.border =
        "1px solid red";
      this.setState({ marketoClientSecretDisplay: "block" });
      formIsvalid = false;
    }
    if (marketoClientSecret !== "") {
      let pattern1 = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z0-9]+$/); //Rutuja 4796 changed var to let
      if (!pattern1.test(marketoClientSecret)) {
        document.getElementById("marketoClientSecret").style.border =
          "1px solid red";
        this.setState({ marketoClientSecretDisplay1: "block" });
        formIsvalid = false;
      }
    }
    if (email === "") {
      document.getElementById("email").style.border = "1px solid red";
      this.setState({ emailDisplay: "block" });
      formIsvalid = false;
    }
    if (email !== "") {
      //Saurabh – Task- 3701 – Email Validation added new regular expression
      let pattern1 = new RegExp( //Rutuja 4796 changed var to let
        /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i
      );
      if (!pattern1.test(email)) {
        document.getElementById("email").style.border = "1px solid red";
        this.setState({ emailDisplay1: "block" });
        formIsvalid = false;
      }
    }
    if (endPointURL === "") {
      document.getElementById("endPointURL").style.border = "1px solid red";
      this.setState({ endPointUrlDisplay: "block" });
      formIsvalid = false;
    }
    if (identityURL === "") {
      document.getElementById("identityURL").style.border = "1px solid red";
      this.setState({ identityUrlDisplay: "block" });
      formIsvalid = false;
    }
    if (identityURL !== "") {
      let pattern1 = new RegExp( //Rutuja 4796 changed var to let
        /(http(s)?:)?([\w-]+\.)+[\w-]+([\w- ;,.?%&=]*)?/
      );
      if (!pattern1.test(identityURL)) {
        document.getElementById("identityURL").style.border = "1px solid red";
        this.setState({ identityUrlDisplay1: "block" });
        formIsvalid = false;
      }
    }
    if (endPointURL !== "") {
      let pattern1 = new RegExp( //Rutuja 4796 changed var to let
        /(http(s)?:)?([\w-]+\.)+[\w-]+([\w- ;,.?%&=]*)?/
      );
      if (!pattern1.test(endPointURL)) {
        document.getElementById("endPointURL").style.border = "1px solid red";
        this.setState({ endPointUrlDisplay1: "block" });
        formIsvalid = false;
      }
    }

    if (this.state.mappingFile === "") {
      if (this.state.mappingFileName === "") {
        this.setState({ mappingFileDisplay: "block" });
        formIsvalid = false;
      }
    }

    var allowedFiles = [".xlsx"];
    var mappingFile1 = "";
    if (this.state.mappingFileName === "") {
      mappingFile1 = document.getElementById("mappingFile");
      var regex = new RegExp(
        "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
      );
      if (this.state.mappingFile !== "") { //Rutuja Rask 4796 changed != to !==
        if (!regex.test(mappingFile1.value.toLowerCase())) {
          this.setState({ mappingFileDisplay1: "block" });
          formIsvalid = false;
        }
      }
    } else {
      mappingFile1 = this.state.mappingFileName;
      let regex = new RegExp( //Rutuja 4796 changed var to let
        "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
      );

      if (this.state.mappingFile !== "") {//Rutuja Rask 4796 changed != to !==
        if (!regex.test(mappingFile1.toLowerCase())) {
          this.setState({ mappingFileDisplay1: "block" });
          formIsvalid = false;
        }
      }
    }

    //Priyanka-4658-checking formCheck and formID
    if (
      this.state.formCheck === "Yes" ||
      this.state.ClientSetupArray[0].formCheck === "Yes"
    ) {
      //alert("if-->"+formID+"--")
      if (formID === "" || formID === null) {
        //alert("if if")
        document.getElementById("formID").style.border = "1px solid red";
        this.setState({ formIDDisplay: "block", formIDDisplay1: "none" });
        formIsvalid = false;
      }
    }

    return formIsvalid;
  }

  //Akash -Task-3887 This function created for download excel file
  downloadmappingFile() {
    let { mapping, mappingFileName } = this.state.ClientSetupArray[0];
    const buf = Buffer.from(mapping);
    var newBlob = new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = mappingFileName;
    link.click();
  }

  //Priyanka -Task-4658 This function created for download excel file
  handleChangeFormCheck(e) {
    if (e.target.value === "No") { //Rutuja Rask 4796 changed == to ===
      let ClientSetupArray = this.state.ClientSetupArray;
      ClientSetupArray[0].formCheck = "Yes";
      ClientSetupArray[0].programCheck = "No";
      //Chaitanya-4783-Added condition for if Form check is ON then program check should be disable
      this.setState({
        formCheck: "Yes",
        programCheck: "No",
        ClientSetupArray: ClientSetupArray,
      });
      document.getElementById("programCheck").disabled = true;
      //Chaitanya-4783-Added condition for if Form check is ON then program check should be disable
    } else {
      let ClientSetupArray = this.state.ClientSetupArray;
      ClientSetupArray[0].formCheck = "No";
      this.setState({
        formCheck: "No",
        ClientSetupArray: ClientSetupArray,
      });
      document.getElementById("programCheck").disabled = false;
      //Chaitanya-4783-Added condition for if Form check is ON then program check should be disable
    }
  }

  //Chaitanya-4739-handleChangeProgramCheck function
  handleChangeProgramCheck(e) {
    
    if (e.target.value === "No") { //Rutuja Rask 4796 changed == to ===
      let ClientSetupArray = this.state.ClientSetupArray;
      ClientSetupArray[0].programCheck = "Yes";
      ClientSetupArray[0].formCheck = "No";
      //Chaitanya-4783-Added condition for if program is ON then Form check check should be disable
      this.setState({
        programCheck: "Yes",
        formCheck: "No",
        ClientSetupArray: ClientSetupArray,
      });
      document.getElementById("formCheck").disabled = true;
      //Chaitanya-4783-Added condition for if program is ON then Form check check should be disable
    } else {
      
      let ClientSetupArray = this.state.ClientSetupArray;
      ClientSetupArray[0].programCheck = "No";
      this.setState({
        programCheck: "No",
        ClientSetupArray: ClientSetupArray,
      });
      document.getElementById("formCheck").disabled = false;
      //Chaitanya-4783-Added condition for if program is ON then Form check check should be disable
    }
  }

  submitForm(e) {
    e.preventDefault();
    if (this.validateForm() === false) {
    } else {
      var data = new FormData();
      data.append(
        "clientSetupDetails",
        JSON.stringify(this.state.ClientSetupArray)
      );
      data.append("mappingFileNameIfExist", this.state.mappingFileName);
      data.append("mappingFile", this.state.mappingFile);

      fetch("/marketoClient/marketoClientSetup", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          console.log("the response is recived==" + JSON.stringify(response));
          Swal.fire({
            text: "Marketo client setup added successfully",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        });
    }
  }

  render() {
    return (
      <div className="main" style={{ overflowX: "hidden" }}>
        <AdminNavpage />
        <div style={{ paddingTop: "100px" }}>
          <div class="row">
            <div
              class="col-lg-1 col-md-2 col-sm-1"
              style={{ float: "left", marginLeft: "40px" }}
            >
              <a
                class="col"
                href="#/"  //Rutja 4796 console warrning
                style={{ color: "#056eb8", right: "33%", marginLeft: "80px" }}
                onClick={this.clientSetupBackButton.bind(this)}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  title=""
                  className="backFrom_salesforce"
                />
              </a>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-9" align="center">
              <label
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  color: "#056eb8",
                }}
              >
                {" "}
                Marketo Client Setup{" "}
              </label>
            </div>
            {/* <div class="col-lg-1 col-md-1 col-sm-1">
                        </div> */}
          </div>
        </div>
        <div class="container">
          <div class="card" className="cardStep1">
            <form method="POST" id="clientSetup" name="clientSetup">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 ">
                  <label className="labelStyle_marketo">Client name</label>
                  <span className="asterisk">*</span>
                  {/* kiran - task 3889- changes made for required new screen   */}
                  <select
                    id="marketoClientName"
                    name="clientName"
                    value={this.state.ClientSetupArray[0].clientName}
                    class="form-control inputStyle_marketo"
                    required
                    onChange={this.commonhandleChange}
                  >
                    <option value="" selected>
                      Select Client
                    </option>
                    {this.state.allClientNameDetails.map((clientname) => {
                      return <option value={clientname}>{clientname}</option>;
                    })}
                  </select>
                  <span
                    style={{ display: this.state.clientNameDisplay }}
                    className="errorMessage"
                  >
                    Please enter client name
                  </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 ">
                  <label className="labelStyle_marketo">Agency name</label>
                  <span className="asterisk">*</span>
                  <select
                    id="agencyName"
                    name="agencyName"
                    value={this.state.ClientSetupArray[0].agencyName}
                    class="form-control inputStyle_marketo"
                    required
                    onChange={this.commonhandleChange}
                    style={{
                      height: "calc(2.25rem + 12px)",
                      borderRadius: "4px",
                      width: "180px",
                    }}
                  >
                    <option value="" selected>
                      Select Agency
                    </option>
                    {this.state.allAgencyNameDetails.map((agencyID) => {
                      return <option value={agencyID}>{agencyID}</option>;
                    })}
                  </select>
                  <span
                    style={{ display: this.state.agencyNameDisplay }}
                    className="errorMessage"
                  >
                    Please enter agency name
                  </span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 ">
                  <label className="labelStyle_marketo">Client ID</label>
                  <span className="asterisk">*</span>
                  <input
                    type="text"
                    id="marketoClientID"
                    className="form-control inputStyle_marketo"
                    name="marketoClientID"
                    required
                    onChange={this.commonhandleChange}
                    value={this.state.ClientSetupArray[0].marketoClientID}
                  ></input>
                  <span
                    style={{ display: this.state.marketoClientIDDisplay }}
                    className="errorMessage"
                  >
                    Please enter client ID
                  </span>
                  <span
                    style={{ display: this.state.marketoClientIDDisplay1 }}
                    className="errorMessage"
                  >
                    Please enter valid client ID
                  </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 ">
                  <label className="labelStyle_marketo">Client secret</label>
                  <span className="asterisk">*</span>
                  <input
                    type="text"
                    id="marketoClientSecret"
                    className="form-control inputStyle_marketo"
                    name="marketoClientSecret"
                    required
                    onChange={this.commonhandleChange}
                    value={this.state.ClientSetupArray[0].marketoClientSecret}
                  ></input>
                  <span
                    style={{ display: this.state.marketoClientSecretDisplay }}
                    className="errorMessage"
                  >
                    Please enter client secret
                  </span>
                  <span
                    style={{ display: this.state.marketoClientSecretDisplay1 }}
                    className="errorMessage"
                  >
                    Please enter valid client secret
                  </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <label className="labelStyle_marketo">Email</label>
                  <span className="asterisk">*</span>
                  <input
                    type="email"
                    id="email"
                    className="form-control inputStyle_marketo"
                    name="email"
                    required
                    onChange={this.commonhandleChange}
                    value={this.state.ClientSetupArray[0].email}
                  ></input>
                  <span
                    style={{ display: this.state.emailDisplay }}
                    className="errorMessage"
                  >
                    Please enter email
                  </span>
                  <span
                    style={{ display: this.state.emailDisplay1 }}
                    className="errorMessage"
                  >
                    Please enter valid email
                  </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 ">
                  <label className="labelStyle_marketo">EndPoint url</label>
                  <span className="asterisk">*</span>
                  <input
                    type="text"
                    id="endPointURL"
                    className="form-control inputStyle_marketo"
                    name="endPointURL"
                    required
                    onChange={this.commonhandleChange}
                    value={this.state.ClientSetupArray[0].endPointURL}
                  ></input>
                  <span
                    style={{ display: this.state.endPointUrlDisplay }}
                    className="errorMessage"
                  >
                    Please enter endPoint url
                  </span>
                  <span
                    style={{ display: this.state.endPointUrlDisplay1 }}
                    className="errorMessage"
                  >
                    Please enter valid endPoint url
                  </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 ">
                  <label className="labelStyle_marketo">Identity url</label>
                  <span className="asterisk">*</span>
                  <input
                    type="text"
                    id="identityURL"
                    className="form-control inputStyle_marketo"
                    name="identityURL"
                    required
                    onChange={this.commonhandleChange}
                    value={this.state.ClientSetupArray[0].identityURL}
                  ></input>
                  <span
                    style={{ display: this.state.identityUrlDisplay }}
                    className="errorMessage"
                  >
                    Please enter identity url
                  </span>
                  <span
                    style={{ display: this.state.identityUrlDisplay1 }}
                    className="errorMessage"
                  >
                    Please enter valid identity url
                  </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 ">
                  <label className="labelStyle_marketo">Mapping file</label>
                  <span className="asterisk">*</span>
                  <input
                    type="file"
                    id="mappingFile"
                    style={{ color: "transparent" }}
                    name="mappingFile"
                    required
                    onChange={this.commonhandleChange}
                    defaultValue={this.state.mappingFile}
                  ></input>
                  {this.state.mappingFileName !== "" ? (//Rutuja Rask 4796 changed != to !==
                    //Akash -Task-3887 added this code for give call to downloadmappingFile fucntion
                    <a href="#/">
                      <span>
                        <b onClick={this.downloadmappingFile.bind(this)}>
                          {this.state.mappingFileName} &nbsp;
                          <i class="fa fa-download"></i>
                        </b>
                      </span>
                      <br />
                    </a>
                  ) : (
                    ""
                  )}
                  <span
                    id={"mappingName"}
                    className="word-wrap"
                    style={{ display: this.state.mappingnameDisplay }}
                  ></span>
                  <span
                    style={{ display: this.state.mappingFileDisplay }}
                    className="errorMessage"
                  >
                    Please upload mapping file
                  </span>
                  <span
                    style={{ display: this.state.mappingFileDisplay1 }}
                    className="errorMessage"
                  >
                    Please upload mapping file having extensions: xlsx
                  </span>
                </div>
                {/* Priyanka-4641-Added Form ID field */}

                <div class="col-lg-6 col-md-6 col-sm-12 ">
                  {/* Priyanka-4658-Added Form Check box*/}
                  {/* //Rutuja Rask 4796 changed == to === */}
                  {this.state.formCheckFlag === true ? (
                    <input
                      type="checkbox"
                      id="formCheck"
                      name="formCheck"
                      value={this.state.formCheck}
                      onChange={this.handleChangeFormCheck}
                      checked={
                        this.state.ClientSetupArray[0].formCheck === "Yes" //Rutuja Rask 4796 changed == to ===
                          ? true
                          : false
                      }
                    />
                  ) : (
                    <input
                      type="checkbox"
                      id="formCheck"
                      name="formCheck"
                      value="formCheck"
                      disabled
                    />
                  )}
                  &nbsp;&nbsp;
                  {/* <label className='labelStyle' >Application Through :</label> */}
                  <label className="labelStyle_marketo">
                    Form ID (Optional) :
                  </label>
                  {this.state.ClientSetupArray[0].formCheck === "Yes" ? ( //Rutuja Rask 4796 changed == to ===
                    <input
                      type="text"
                      id="formID"
                      className="form-control inputStyle_marketo"
                      name="formID"
                      required
                      onChange={this.commonhandleChange}
                      value={this.state.ClientSetupArray[0].formID}
                    ></input>
                  ) : (
                    <input
                      type="text"
                      id="formID"
                      className="form-control inputStyle_marketo"
                      name="formID"
                      required
                      value={this.state.ClientSetupArray[0].formID}
                      disabled
                    ></input>
                  )}
                  <span
                    style={{ display: this.state.formIDDisplay }}
                    className="errorMessage"
                  >
                    Please enter form ID
                  </span>
                  <span
                    style={{ display: this.state.formIDDisplay1 }}
                    className="errorMessage"
                  >
                    Please enter valid form ID
                  </span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 ">
                  {/* Chaitanya-4739-Added Url box*/}
                  {this.state.programCheckFlag === true ? ( //Rutuja Rask 4796 changed == to ===
                    <input
                      type="checkbox"
                      id="programCheck"
                      name="programCheck"
                      value={this.state.programCheck}
                      onChange={this.handleChangeProgramCheck}
                      checked={
                        this.state.ClientSetupArray[0].programCheck === "Yes" //Rutuja Rask 4796 changed == to ===
                          ? true
                          : false
                      }
                    />
                  ) : (
                    <input
                      type="checkbox"
                      id="programCheck"
                      name="programCheck"
                      value="programCheck"
                      disabled
                    />
                  )}
                  &nbsp;&nbsp;
                  <label className="labelStyle_marketo">
                    Through Program (Optional) :
                  </label>
                  {this.state.ClientSetupArray[0].programCheck === "Yes" ? ( //Rutuja Rask 4796 changed == to ===
                    <input
                      type="text"
                      id="formID"
                      className="form-control inputStyle_marketo"
                      name="formID"
                      required
                      value={"/rest/v1/leads/push.json"}
                      disabled
                    ></input>
                  ) : (
                    <input
                      type="text"
                      id="formID"
                      className="form-control inputStyle_marketo"
                      value={"/rest/v1/leads/push.json"}
                      disabled
                    ></input>
                  )}
                </div>
              </div>
            </form>
            <div class="float-right" style={{ marginRight: "50px" }}>
              <button
                className="btn add-button"
                value="submit"
                onClick={this.submitForm}
              >
                Submit
              </button>
            </div>
            <br />
            <br />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
/**
 * @author snehal more
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
MarketoClientSetup.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(MarketoClientSetup)
);
