/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*@author Sanobar Golandaj
*@fileName :voicelogsUpload.js
 *Desc: added validations 
*/
import React from 'react';
import Footer from '../layouts/footer'; 
import PublisherNavigation from '../layouts/publisherNavPage';
// import { MDBRow, MDBCol, MDBBtn } from "mdbreact"; //Nilesh-4240-Removing console warnings
import { MDBRow, MDBCol } from "mdbreact";
// import LeadTable from  './table';  //Nilesh-4240-Removing console warnings
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
// import fs from 'fs';  //Nilesh-4240-Removing console warnings
import { saveAs } from 'file-saver';
// import { Table, TableBody, TableHead  } from 'mdbreact'; //Nilesh-4240-Removing console warnings
import { Table } from 'mdbreact';
import './loader.css';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //sonali-task-3945-added default header

// const queryString = require('query-string');  //Nilesh-4240-Removing console warnings
const xlsx =require('xlsx');
const Swal = require('sweetalert2');
// var url = require("url"); //Nilesh-4240-Removing console warnings
// var path = require("path");  //Nilesh-4240-Removing console warnings

// var leadLength; //Nilesh-4240-Removing console warnings
// var leadFileExt;   //Nilesh-4240-Removing console warnings
class  VoiceLogsUpload extends React.Component
        {
            constructor() {

                super();
                this.state = { 
                    campID:'',
                    parentCampID:'',
                    reallocationID:'',
                    selectedFile: null, 
                    filesData:[],
                     fields: {}, 
                     leadDetails:[],
                     leadDetailsData:[],
                     getExt:[],
                     leadDetailsData1:[],
                     leadDetailsDataTest:[],
                     errors: {} ,
                     fileerrors:{},
                     formaterrors:{},
                     inputClass:"disabled",
                     loading:" ",
                     alertBorder:"",
                     
                   };
                     this.submitVoiceLogLink = this.submitVoiceLogLink.bind(this);
                     this.submitLinkdetails=this.submitLinkdetails.bind(this);
                     this.downloadAuditReport=this.downloadAuditReport.bind(this);
                     this.Backbutton=this.Backbutton.bind(this);//shivani-task 3183- added this line to bind back button function.
                    
                
            } 
            //shivani-task 3183-added link on back button through this function.
            Backbutton(e){
              e.preventDefault();
              // var parsed = queryString.parse(this.props.location.search);  //Nilesh-4240-Removing console warnings
              //Sonali-3945-accessing parameters from props
              var campID=this.props.location.state.campID;
              var parentCampID=this.props.location.state.parentCampID;                
              var reallocationID=this.props.location.state.reallocationID;
              this.setState({reallocationID,campID});  
              this.props.history.push("/publisherCampaignList",{campID:campID,parentCampID:parentCampID,reallocationID:reallocationID}); //sonali-task-3945-replace query params
              // Akash-bug-4167- path changed 
            }

            downloadAuditReport(e){
              e.preventDefault();
              var campID = e.target.id;
            
             //Sonali-3945-accessing parameters from props
              var parentCampID=this.props.location.state.parentCampID;            
              var reAllocationID=this.props.location.state.reallocationID;
              // const {isAuthenticated, user} = this.props.auth;  //Nilesh-4240-Removing console warnings
              
              let data={
                campID:campID,
                parentCampID:parentCampID,
                reallocationID:reAllocationID,
                listLeadInfoID:this.state.leadDetailsData,
               // user: user//sonali-3945-removing user from the following call
              }
              
              fetch("publisher/downloadAuditReport",{
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
                }).then(res => res.json())
              .then(downloadFiles =>{
                  this.setState({ downloadFiles: downloadFiles })
            //       /* make the worksheet */
           
            if(downloadFiles.length>0){
            var ws = xlsx.utils.json_to_sheet(downloadFiles);
            /* add to workbook */
            var wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, ws, "Lead");
            
            // /* write workbook */
            var buf = xlsx.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
            // var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});  //Nilesh-4240-Removing console warnings
            /* generate a download */
            function s2ab(s) {
              var buf = new ArrayBuffer(s.length);
              var view = new Uint8Array(buf);
              for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
              return buf;
            }
            var fileName="Audit_Report"+parentCampID+"-"+reAllocationID+".xlsx";
            saveAs(new Blob([s2ab(buf)],{type:"application/octet-stream"}), fileName);
          }else{
            console.log("Data Not Exists");
          }
              }
                )
              
                // window.location.href = '/publisherView?pID='+user.id;
  } /**  End of downloadAuditReport */
 

 submitLinkdetails(i,e){
      const { name, value } = e.target;
  
  var dynamicArray=this.state.leadDetailsData;
    var leadInfoID=dynamicArray[i].leadInfoID;
    
  

       let leadDetailsData=[...this.state.leadDetailsData];
       leadDetailsData[i] = { ...leadDetailsData[i], [name]:value };
       this.setState({ leadDetailsData });
       var number=document.getElementById("errorMessage"+leadInfoID);  
       number.innerHTML="";
 
     
       }

  submitVoiceLogLink(e){
    e.preventDefault();
    let errors={};
    // const {isAuthenticated, user} = this.props.auth;  //Nilesh-4240-Removing console warnings
    var linkInvalid;
    var voiceValidate=false;
    var dynamicArray=this.state.leadDetailsData;

 
    if(dynamicArray==""||dynamicArray==null||dynamicArray==undefined)
    {
      Swal.fire({
              
        text:"No Data Exists",
        type: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
      
    }
   
   
   
  

    for(var i=0;i<dynamicArray.length;i++)
    {

  
      if(dynamicArray[i].voiceLogLink=="" || dynamicArray[i].voiceLogLink==null || dynamicArray[i].voiceLogLink==undefined)
      {
         errors["voicelogValidate"]="Please enter voice log link";
         this.setState({errors:errors,
          alertBorder:'1px solid red'})
      }
      else{
       
          voiceValidate=true;
          this.setState({errors:'',alertBorder:''})

     
       
      }
      
      
      if(voiceValidate==true)
      {
       
        this.setState({errors:'',alertBorder:''})
        var leadInfoID=dynamicArray[i].leadInfoID;
        if(dynamicArray[i].voiceLogLink){
          var link=dynamicArray[i].voiceLogLink;
          var file=link.split('\\').pop().split('/').pop();
          var emailId=dynamicArray[i].email;
        

         if(file===emailId) //Nilesh-4240-Removing console warnings
         {
         }
         else
         {
           linkInvalid=false;
            var errorMsg="This file is not valid,filename should be in email format";
            var message = document.getElementById('errorMessage'+leadInfoID);
            var number=document.getElementById("errorMessage"+leadInfoID).innerHTML;  
            if(number=="")
            {
              message.innerHTML += errorMsg;
            }
             
            

        
         }
            
        
        }

      }

     
    }

  

    if(linkInvalid==false){}
    else{
    let data={dynamicArray:dynamicArray,
     // user:user,//sonali-3945-removing user from the following call
      campID:this.state.campID};

    fetch("/leadDownloadDetails/submitVoiceLink",{
      method:'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    }) 
    .then(res => res.json()).then(res => {
    
      if (res.success === true) {
        Swal.fire({           
          text: "Link Submitted Successfully",
          type: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,  
          preConfirm: () => {
            window.location.reload();
          
          }
        })
    
        
      }
    })
  }//else
  }
        
                componentDidMount() {
                        if(!this.props.auth.isAuthenticated) {
                        this.props.history.push('/userLogin');
                  }
                  else {    
                    	//Sonali-3945-VAPT-accessing parameters from location.state
                  if (this.props.location.state !== undefined) {
                    const {
                    campID,parentCampID,reallocationID
                    } = this.props.location.state;
                    this.setState({campID,parentCampID,reallocationID})
			}            
                //  var parsed = queryString.parse(this.props.location.search);
                //Sonali-3945-accessing parameters from props
                  // var campID=this.props.location.state.campID; //Nilesh-4240-Removing console warnings

                  var parentCampID=this.props.location.state.parentCampID;//sunita-task-3184-taken id as per requirement getting as undefined.
                  var reallocationID=this.props.location.state.reallocationID;//sunita-task-3184-taken id as per requirement getting as undefined.
                 this.setState({parentCampID,reallocationID});
                
                // const {isAuthenticated, user} = this.props.auth; //Nilesh-4240-Removing console warnings
               
                       this.setState({campID:this.props.location.state.campID});
                        let data={
                          campID:this.props.location.state.campID,
                          //parentCampID:parentCampID,
                          //reallocationID:reallocationID,
                          //user:user//sonali-3945-removing user from the following call
                        }
                        fetch("/leadDownloadDetails/voiceLogData",{
                          method: 'POST',
                           headers: {'Content-Type': 'application/json'},
                           body: JSON.stringify(data)
                        }).then(res => res.json())
                        .then(leadDetailsData =>{
                          this.setState({ leadDetailsData: leadDetailsData })
                        });
                }
              }
               
             
             
render()
    {
      // const {isAuthenticated, user} = this.props.auth; //Nilesh-4240-Removing console warnings
        
        return(
      <div>
          <PublisherNavigation/>
          <form method="POST" name="submitVoiceLogLink" onSubmit={this.submitVoiceLogLink}>

          <br/>
          <div class="container-fluid" style={{paddingTop:'90px',marginBottom:'60px'}}>
          
          <div class="row">

       <div class="col-lg-1">
   
                              {/* IoIosArrowDropleftCircle */}
                              {/* <a href={"dashboardPublisher?pID="+user.id}  style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Dashboard"/></a> */}
                            {/* //shivani-task 3183-added onclick function on back button to redirecton the same page. */}
                              <a href="#/" onClick={this.Backbutton} style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to publisherCampaignList"/></a>
                                </div>
                                <div class="col-lg-10" style={{textAlign:'center'}}> 
                                {/* Akash-Bug-4205 */}
                                <label id="label" style={{fontSize:"19px",fontWeight:"bold",color:"#056eb8"}}>Voice Log Details</label>
      </div>

<div class="col-lg-1">
     
          <div class="tip">
          <span> 
             <a href="#/"  //Nilesh-4240-Removing console warnings
             >
             {/* Sonali-3945-accessing parameters from props */}
          <img alt='' //Nilesh-4240-Removing console warnings
          src="download.jpg" onClick={this.downloadAuditReport} id={this.props.location.state.campID} reallocationID={this.props.location.state.reallocationID} style={{width:'30px',height:'30px',color:'#17278E'}}>
          </img>
          </a>
          </span>
           <span class="load">Download Audit Report</span>
         </div>
        </div>
       
       </div>

      
                                <br/>
                                <div class="errorMessage" style={{border:this.state.alertBorder}}>
                                  {this.state.errors.voicelogValidate}
                                  </div>
                                 
          <MDBRow>
                                          
                                          <MDBCol lg="12" className="mb-12">
                                                           <div>
                                                            
                   
                              
                    <Table scrollY  className=" table table-bordered table-striped "  style={{fontSize:'12px'}} >
                      <thead >
                      <tr class="info" style={{verticalAlign:'top'}}>
                          <th class="table-header" style={{width:'10%',verticalAlign:'top'}}>Lead ID</th>
                          <th  class="table-header" style={{width:'10%',verticalAlign:'top'}}>Campaign ID</th>
                          <th  class="table-header"style={{width:'20%',verticalAlign:'top'}}>Email</th>
                           <th  class="table-header" style={{verticalAlign:'top'}}>Status</th>
                           <th class="table-header" style={{verticalAlign:'top'}}>Voice Log Link</th>
                      
                        </tr>
                    </thead>
                    {this.state.leadDetailsData.map(
                                                    (leadDetailsData,i) => {
                                                        return (
                                                                       
                                  <tbody>
                     
                                  <tr>
                                    
                                  <td>{leadDetailsData.leadInfoID}</td>
                                 
                                  <td>{leadDetailsData.parentCampID}</td>
                              <td>{leadDetailsData.email}</td>
                                  
                                  <td>{leadDetailsData.status}</td>
                                  <th><input id="voiceLogLink"  name='voiceLogLink' emailID={leadDetailsData.email} leadInfoID={leadDetailsData.leadInfoID} onChange={this.submitLinkdetails.bind(this,i)} class="form-control"  defaultValue={leadDetailsData.voiceLogLink}/> 
                                  <div id={"errorMessage"+leadDetailsData.leadInfoID} style={{color:'red'}}></div>
                                 
                                  </th>
                                
                                  </tr>
                                                   
                              </tbody>
                              )})}
                              </Table>
                              {/* //shivani-3183- added css and aligned submit button . */}
                              <div style={{textAlign:'right',marginBottom:'60px',marginTop:'15px'}}> <button class="btn add-button" type="HTMLsubmit">Submit</button></div>
                             
                          </div>

                               </MDBCol>
                                                      </MDBRow> 
                              
                                                      </div>
               

          
               
                 
            
</form>
          <Footer/>
          
      </div>
      );
        
    }
}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      VoiceLogsUpload.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
 export default connect(mapStateToProps, { logoutUser })(withRouter(VoiceLogsUpload)); 
