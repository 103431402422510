/* Copyright(c) 2023 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/**
 * @file BypassEmailDomainValidationReport 
 * @author  Rutuja Jagtap - 4823
 * @section DESCRIPTION bypassEmail Domain validation report
 */

import React from "react";
import Navigation from "../layouts/MBAdminNavPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import "./LeadFileTrackingReport.css"
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import { saveAs } from "file-saver";
import {  Table} from 'antd';
import { css } from "emotion";

const xlsx = require("xlsx");
const Swal = require('sweetalert2');

const tableCSS = css({
  backgroundColor: 'white',
  fontFamily: "sans-serif",
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize: '12px !important',
    fontWeight: '500',
  },
  '& thead > tr >th': {
    textAlign: "center !important",
    color: 'white',
  }
});

class BypassEmailDomainValidationReport extends React.Component {
  constructor() {
    super();
    this.state = {
      reportDetails: [],
      EmailDomainValidationDetails:[],
      loader_progress: "",
      startDate:'',
      endDate:'',  
      tableState: { size: "small" },
      isDownloadDisabled:false,
      endDateDisplay: "none", //4834
      

    }
    this.dateHandleChange = this.dateHandleChange.bind(this);
    this.handledownloadeport = this.handledownloadeport.bind(this);
    this.searchHandleChange = this.searchHandleChange.bind(this);

  }
 
  dateHandleChange(e){
    e.preventDefault();
    this.setState({ endDateDisplay: "none" }); //Rutuja 4834
    this.setState({ EmailDomainValidationDetails : "" , reportDetails:""});
    
   
       if(e.target.name ==="startDate")
       {
        this.setState({
            startDate: e.target.value
        })
       }
       if(e.target.name ==="endDate")
       {
        this.setState({
            endDate: e.target.value
        })
       }
       
  }
  searchHandleChange(e) {
    let startDate = this.state.startDate;
      let endDate = this.state.endDate;  
      //   Rutuja Task 4832 added parse condition when end date is less than start date 
    if (Date.parse(this.state.startDate) > Date.parse(this.state.endDate)) {
     
      this.setState({ endDateDisplay: "block" });
     
    }
      
        else if((startDate && startDate !== "")&& (endDate && endDate !== "")){ 
        
        this.setState({ loader_progress: "loader_campaign_list" });
        let data = {
            startDate: startDate,
            endDate: endDate,
        }
        fetch("/admin/emailDomainValidationDetails", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(data)
        }).then(res => res.json())
        .then(EmailDomainValidationDetails => {
          if(EmailDomainValidationDetails.length>0){   
            this.setState({ EmailDomainValidationDetails: EmailDomainValidationDetails, })
            fetch("/admin/bypassEmailDomainValidation", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(data)
            }).then(res => res.json())
            .then(reportDetails => {
              this.setState({ loader_progress: "" });
              this.setState({ reportDetails: reportDetails, })
          }) 
          }else{
              Swal.fire({
                  type: "warning",
                  title: "No records Found",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  preConfirm: () => {
                    window.location.reload();
                  },
                  
            });
            this.setState({ loader_progress: "" });
          }
        }).catch(function (err) { console.log(err) });
      }else{
        Swal.fire({
          type: "warning",
          title: "Please Select All Mandatory Fields",
          confirmButtonText: "Ok",
          allowOutsideClick: false
        });
        this.setState({ loader_progress: "" });
      }
      }

      
  
   handledownloadeport(e) {
      
            var ws = xlsx.utils.json_to_sheet(this.state.reportDetails);
            var wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, ws, "Lead");
            var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); 
    
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (let i = 0; i !== s.length; ++i)
                    view[i] = s.charCodeAt(i) & 0xff;
                return buf;
            }
    
            var fileName = "Bypass Email Domain Validation Report-.xlsx";
            saveAs(
                new Blob([s2ab(buf)], { type: "application/octet-stream" }),
                fileName
            );
            this.setState({ loader_progress: "" });
            Swal.fire({
                type: "success",
                title: "File Downloaded successfully",
                confirmButtonText: "Ok",
                icon: "success",
                allowOutsideClick: false,
                preConfirm: () => {
                  window.location.reload();
                },
          });
          
       
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
   
  }
  
  render() {
    const asterisk = { color: 'red' }

    const columnsReport = [
     
      {
          title: <span>DI Camp ID&nbsp;&nbsp;&nbsp;</span>,
          dataIndex: "campID",
          key: "campID",
          width: "15%",
          align: "left",
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                      {record.campID}
                  </span>
                  <br />
              </div>
          ),
      },
      {
          title: <span>First Name&nbsp;&nbsp;&nbsp;</span>,
          dataIndex: "firstName",
          key: "firstName",
          width: "15%%",
          align: "left",
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                      {record.firstName}
                  </span>
                  <br />
              </div>
          ),
      },
      {
        title: <span>Last Name&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "lastName",
        key: "lastName",
        width: "15%%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.LastName}
                </span>
                <br />
            </div>
        ),
      },
      {
        title: <span>Created Date&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "created",
        key: "created",
        width: "25%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.created}
                </span>
                <br />
            </div>
        ),
    },
      {
        title: <span>Lead Count&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "leadCount",
        key: "leadCount",
        width: "15%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.leadCount}
                </span>
                <br />
            </div>

        ),
      },
    ]

    return (
    <div>
      <Navigation />
        <div class="container-fluid" style={{ paddingTop: '100px' }}>

        {/* heading section */}
          <div class="row">
               
                  
            <div class="offset-lg-2 offset-sm-2 col-lg-8 col-md-8 col-sm-8" align="center">
              <center><label id="labelDI" style={{ fontSize: '28px', fontStyle: 'roboto', fontWeight: '500', color: '#193D8F' }} align="center">Bypass Email Domain Validation Report</label></center>
            </div>
          </div>
          
        <div className="Main-body">  
          <div class="container-fluid" style={{ paddingTop: '10px', marginBottom: '20px' }}>
            <div className="card card-signin my-1">
              <div className="card-body">
             
                {/* Filter section */}          
                <div className="row">
                
                <div className="col-12 col-sm-6 col-md-6 col-lg-5 cl-LeadTracking" id="AD_ALTD">
                      <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Select Start Date&nbsp;<span style={asterisk}>*</span>&nbsp;</label>
                      <br />
                      <input
                            id="startDate" name="startDate" 
                          style={{ width: '220px', border: "1px solid #CCCCCC", borderRadius: "3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: "#555555" }}
                          type="date" pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                              value={this.state.startDate}
                              className={this.state.inputClassSdate}
                              onChange={this.dateHandleChange}
                              required
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-5 cl-LeadTracking" id="AD_ALTD">
                    
                      <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Select End Date&nbsp;<span style={asterisk}>*</span>&nbsp;</label>
                      <br />
                      <input
                            id="endDate" name="endDate" 
                          style={{ width: '220px', border: "1px solid #CCCCCC", borderRadius: "3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: "#555555" }}
                          type="date" pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                              value={this.state.endDate}
                              className={this.state.inputClassSdate}
                              onChange={this.dateHandleChange}
                              required
                        />
                        {/* Rutuja Task 4832 added error message when end date is less than start date */}
                        <span
                        style={{ display: this.state.endDateDisplay }}
                        className="errorMessage"
                      >
                        Please enter End Date greater than start
                        date
                      </span>
                    </div>
                    <div className="col-lg-2" id="AD_ALTD"> 
                    <br/>           
                          <button
                            type="button"
                            class="btn add-button"
                            onClick={this.searchHandleChange}>
                            Search
                          </button>
                    </div>  
                    <div align="center"  id={this.state.loader_progress}></div>   
                    
                  {this.state.EmailDomainValidationDetails.length > 0  && this.state.reportDetails.length > 0? 
                      <div className="col-lg-12"> 
                       <hr /> 
                       <p> <span className="errorMessage">Note: Lead count column considers "System Rejected" leads also.</span></p>
                        <p style={{ marginBottom: "10px" }}>
                          <br />
                              <Table
                                {...this.state.tableState}
                                bordered
                                dataSource={this.state.EmailDomainValidationDetails}
                                columns={columnsReport}
                                className={`${tableCSS} "ant-table-thead table-responsive"`}
                                scroll={{ y: 250, x: 800 }}
                                pagination={false} 
                                >
                              </Table>
                        </p>
                            <button class="btn add-button" 
                            style={{float: "right" }} 
                            title="Export the lead data in excel file"
                            onClick={this.handledownloadeport}
                            >Download Lead Details</button>
                      </div> 
                : " "} 
                </div> 

                
              </div>
            </div>
          </div>
        </div>
                        
      </div>
      <Footer />
    </div>
    );
  }
}


BypassEmailDomainValidationReport.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(BypassEmailDomainValidationReport));

