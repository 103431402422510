/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Shivani pathak
 *@fileName :
 *Desc: Agency GDPR Certificate Tab
 */

import React from "react";
import * as $ from "jquery";
import Footer from "../layouts/footer";
import "antd/dist/antd.css";

import {
  Form,
  //Select,   //Chaitanya-4750-Removed Console warning
  Tooltip,
  Radio,
  Button,
  Icon,
  Checkbox,
  Row,
  Col,
} from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
//import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Akash-task-4046
//import {  read_cookie, } from "sfcookies";    //Chaitanya-4750-Removed Console warning
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Priyanka--4062--added default header

//const cookie_key = "agencyID";    //Chaitanya-4750-Removed Console warning
const Swal = require("sweetalert2");
//const { Option } = Select;    //Chaitanya-4750-Removed Console warning
// var assetLength;             //Chaitanya-4750-Removed Console warning
// var gdrpResultArray = [];    //Chaitanya-4750-Removed Console warning
// var caslResultArray = [];    //Chaitanya-4750-Removed Console warning
// var caslDynamicArray = [];   //Chaitanya-4750-Removed Console warning
var gdprnames = [];
var gdprnames1 = [];
//var gdprDynamicArray = [];    //Chaitanya-4750-Removed Console warning
var gdprnames3 = [];
var gdprnames6 = [];
// var gdprInfo = [];           //Chaitanya-4750-Removed Console warning
// var key = 0;                 //Chaitanya-4750-Removed Console warning
// var inputType = [];          //Chaitanya-4750-Removed Console warning

class AgencyGDPRCertificateEdit extends React.Component {
  state = { newsuccess: "" };
  constructor() {
    super();
    this.state = {
      selected: "gdpr-2",
      selected1: "casl-2",
      checked: false,
      checked1: false,
      checked2: false,
      checked3: false,
      displayChk1: "",
      displayChk2: "",
      buttonDisplay: "disabled",
      gdprquestionList: [],
      caslquestionList: [],
      caslquestionListForEdit: [],
      complianceNameList: [],
      agencyInfo: [],
      assetsFile: [],
      asseterrors: {},
      gdpr1: {},
      agencyID: "",
      gdprInfo: [],
      gdprDynamicArray: [],
      gdrpResultArray: [],
      caslDynamicArray: [],
      caslResultArray: [],
      complianceQuestion: [],
    };
    this.handleChangeGdprRadio = this.handleChangeGdprRadio.bind(this);
    this.handleChangeGdpr = this.handleChangeGdpr.bind(this);
    this.handleChangeCasl = this.handleChangeCasl.bind(this);
    this.fungetCASL = this.fungetCASL.bind(this);
    this.funGetGDPR = this.funGetGDPR.bind(this);
  }

  
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth;
      const agencyID = user.id;
      // alert("agencyId=="+agencyID) //Akash-task-4046
      this.setState({ agencyID: agencyID });
      //var data = { compilanceValue: "GDPR" }; //Priyanka--4062--removed params  //Chaitanya-4750-Removed Console warning
      let data1 = { compilanceValue: "GDPR" };
      fetch("agencyOnBoardDetails/getGdprQuestions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data1),
      })
        .then((res) => res.json())
        .then((gdprquestionListNew) => {
          let gdprquestionList = gdprquestionListNew.gdprQuestion;
          let gdprInfo = gdprquestionListNew.gdprInfo;
          this.setState({ gdprquestionList, gdprInfo });
        });
      //kiran - bug 4116- added question list
      if (agencyID == "") {
        this.setState({
          buttonDisplay: "disabled",
          displayChk1: "disabled",
          displayChk2: "disabled",
          infoMsg: "Please Fill Company Information First",
        });
      } else {
        this.setState({
          displayChk1: "",
          displayChk2: "",
          buttonDisplay: "button",
          infoMsg: "",
        });
      }

      fetch("/agencyOnboardDetails/getcomplianceName", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((complianceNameList) => {
          this.setState({ complianceNameList });
          let chkGDPR = complianceNameList.filter(
            (a) => a.complianceName.toString() === "GDPR"   //Chaitanya-4750-Removed Console warning
          );
          if (chkGDPR.length > 0) {
            this.setState({ checked: true });
          }
          let chkCASL = complianceNameList.filter(
            (a) => a.complianceName.toString() === "CASL"   //Chaitanya-4750-Removed Console warning
          );
          if (chkCASL.length > 0) {
            this.setState({ checked1: false }); //kiran- bug 4201- CASL checkbox need to be unchecked
          }
        });
    }
  } 

  handleChangeGdprRadio(i, e) {
    const { value } = e.target;   //Chaitanya-4750-Removed Console warning
    let gdprInfo = [...this.state.gdprInfo];
    gdprInfo[i] = { ...gdprInfo[i], answer: value };
    this.setState({ selected: e.target.value, gdprInfo });
  }

  /*@author Sanobar Golandaj
   *@fileName :
   *Desc: handle change for casl radio button default selected NO.
   */
  handleChangeCaslRadio(i, e) {
    const { value } = e.target;   //Chaitanya-4750-Removed Console warning
    let caslquestionList = [...this.state.caslquestionList];

    caslquestionList[i] = { ...caslquestionList[i], answer: value };
    this.setState({ selected: e.target.value, caslquestionList });
  }

  /*@author Sonali Kalke
   *@fileName :
   *Desc:Fetch GDPR questions from backend.
   */

  /*@author Sanobar Golandaj
   *@fileName :
   *Desc:handle change for gdpr.
   */

  handleChangeGdpr = (e) => {
    let checked = e.target.checked;
    this.setState({ checked });
    let self = this;
    this.props.form.validateFieldsAndScroll((error, values) => {
      if (!error) {
        let data = {
          compilanceValue: "GDPR", //kiran - bug 4116-gdpr checkbox randomly unchecked issue
        };
        if (e.target.checked === true && e.target.value === "GDPR") {
          self.funGetGDPR();
          fetch("agencyOnBoardDetails/getGdprQuestions", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())

            .then((gdprquestionListNew) => {
              let gdprquestionList = gdprquestionListNew.gdprQuestion;
              let gdprInfo = gdprquestionListNew.gdprInfo;
              this.setState({ gdprquestionList, gdprInfo });
            });
          //kiran - bug 4116- sent array of gdpr on handlechange
        }
      }
    });
  };

  /*@author Sonali Kalke
   *@fileName :
   *Desc:store GDPR answers to database.
   */

  handleSubmit = (e) => {
    //const { user } = this.props.auth;   //Chaitanya-4750-Removed Console warning
    //const agencyID = user.id;   //Chaitanya-4750-Removed Console warning
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        //const { user } = this.props.auth;   //Chaitanya-4750-Removed Console warning
        //var agencyID = user.id; //Akash-task-4046   //Chaitanya-4750-Removed Console warning
        let data = {
          gdprArray: this.state.gdprInfo,
          caslArray: this.state.caslquestionList,
          //orgID: agencyID,  // Priyanka--4062--removed params
          checked: this.state.checked,
          checked1: this.state.checked1,
        };
        fetch("/agencyOnBoardDetails/submitGDPRInfo", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success == true) {
              //var a = res.message;    //Chaitanya-4750-Removed Console warning
              Swal.fire({
                type: "success",
                title: "Compliance details submitted successfully !",
              });
              this.setState({
                buttonDisplay: "disabled",
              });
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        console.log(err);
      }
    });
  };

  /*@author Sanobar Golandaj
   *@fileName :
   *Desc:handle change for Casl.
   */
  handleChangeCasl = (e) => {
    let checked1 = e.target.checked;
    this.setState({ checked1 });
    this.props.form.validateFieldsAndScroll((error, values) => {
      if (!error) {
        if (e.target.checked === true && e.target.value === "CASL") {
          this.fungetCASL();
        }
      }
    });
  };
  funGetGDPR = () => {
    //const { user } = this.props.auth;   //Chaitanya-4750-Removed Console warning
    let data = { compilanceValue: "GDPR" }; //Priyanka--4062--removed params
    fetch("/agencyOnboardDetails/getGDPRInfoForEdit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((gdprInfo) => {
        if (gdprInfo.length > 0) {
          this.setState({ gdprInfo: gdprInfo });
        } else {
          let gdprInfo = [
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "1",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "2",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "3",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "4",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "5",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "6",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "7",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "8",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "9",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "10",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
            {
              docId: "",
              orgID: "",
              complianceID: "1",
              qID: "11",
              answer: "No",
              documentName: "",
              document: "",
              created: "",
              lastUpdated: "",
            },
          ];
          this.setState({ gdprInfo: gdprInfo });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  fungetCASL = () => {
    //const { user } = this.props.auth;   //Chaitanya-4750-Removed Console warning
    let data = {
      compilanceValue: "CASL",
      //	agencyID: user.id,		//Priyanka--4062--removed params
      //alert("agencyID==="+agencyID);//Akash-task-4046
    };
    fetch("/agencyOnboardDetails/getCaslQuestionsForEdit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((caslquestionListForEdit) => {
        if (caslquestionListForEdit.length > 0) {
          this.setState({ caslquestionList: caslquestionListForEdit });
        } else {
          let caslquestionListForEdit = [
            {
              qID: "12",
              questions: "CASL question 1 here",
              complianceName: "CASL",
              complianceID: 2,
              answer: "No",
            },
            {
              qID: "13",
              questions: "CASL question 2 here",
              complianceName: "CASL",
              complianceID: 2,
              answer: "No",
            },
          ];
          this.setState({ caslquestionList: caslquestionListForEdit });
        }
      });
  };

  /*@author Sanobar Golandaj
   *@fileName :
   *Desc:handle change for gdpr file,displaying file list on front end.
   */
  handleChangeGdprFile(i, e) {
    //var agencyID = read_cookie(cookie_key);   //Chaitanya-4750-Removed Console warning
    e.preventDefault();
    //Chaitanya-4750-Removed Console warning
    var name;
    var qID;
    var complianceID;
    var gdprFilesDataNew;
    var duplicategdprList;
    var link;
    var filename;
    var gdprarrayLength;
    var nooutputgdpr;
    var HTML;
    var msg;
    var message;
    var number;
    var data;
    if (i === 0) {    //Chaitanya-4750-Removed Console warning
      name = e.target.name;
      name.split("#");
      qID = name[0];
      complianceID = name[2];
      let gdprFilesData = e.target.files;
      gdprFilesDataNew = [];
      duplicategdprList = document.getElementById(
        "duplicategdprList" + qID
      );

      for (let i = 0, l = gdprFilesData.length; i < l; i++) {   //Chaitanya-4750-Removed Console warning
        var newItem = gdprFilesData[i].name;
        if (gdprnames.indexOf(newItem) === -1) {
          gdprnames.push(newItem);
          gdprFilesDataNew.push(gdprFilesData[i]);
        } else {
          duplicategdprList.innerHTML =
            newItem + " File has already uploaded";   //Chaitanya-4750-Removed Console warning
        }
      }

      //var link;
      filename = "";
      gdprarrayLength = gdprnames.length;
      nooutputgdpr = document.getElementById("nogdprList" + qID);
      if (gdprarrayLength > 0) {
        nooutputgdpr.innerHTML = gdprarrayLength + " Files Selected";
      } else {
        nooutputgdpr.innerHTML = "0 Files Selected";
      }
      HTML = "<table>";
      for (var j = 0; j < gdprnames.length; j++) {
        // User Story - 3427 - Karan Jagtap - changed remove icon
        link =
          '<a class="removeGdprFile" href="#" data-fileid=' +
          '"' +
          gdprnames[j] +
          '"' +
          '><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></i></a>';
        HTML +=
          "<tr><td style='display:flex;align-items:center;'>" +
          link +
          gdprnames[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("gdprList" + qID).innerHTML = HTML;
      //var orgID = this.state.agencyID;    //Chaitanya-4750-Removed Console warning
      $(document).on("click", ".removeGdprFile", function (e) {
        e.preventDefault();
        var clickedFile = $(this).closest("a").data("fileid");
        var j = 0;
        for (j = 0; j < gdprnames.length; j++) {
          var temp = j;
          if (gdprnames[temp] === clickedFile) {
            filename = gdprnames[temp];
            gdprnames.splice(j, 1);
            $(this).parent().remove();
            gdprarrayLength = gdprnames.length;
            /**
             * @author Narendra Phadke
             * @param Delete File From Database
             */
            //var typeOfSuppDoc = "Gdpr";   //Chaitanya-4750-Removed Console warning
            let data = {
              //orgID: orgID,		Priyanka--4062--removed params
              qID: qID,
              names: filename,
              complianceID: complianceID,
            };
            /*@author Sonali Kalke
             *@fileName :
             *Desc:delete files from database.
             */
            fetch("agencyOnBoardDetails/deleteGDPRDocument", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            });
            document.getElementById("gdprfile" + qID).value = "";
            if (gdprarrayLength > 0) {
              nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
            } else {
              nooutputgdpr.innerHTML = "0 File Selected";
              $("#gdpr").val("");
              var message = document.getElementById("gdprMessage" + qID);
              message.innerHTML = "";
            }
          } // end of if(names[temp] === clickedFile)
        } // end of for
      }); //$(document).on
      data = new FormData();  //Chaitanya-4750-Removed Console warning

      data.append("qID", qID);
      //data.append("orgID", this.state.agencyID);		Priyanka--4062--removed params
      //  alert("orgID=="+this.state.agencyID) //Akash-task-4046
      data.append("complianceID", complianceID);
      for (let i = 0, l = gdprFilesDataNew.length; i < l; i++) {    //Chaitanya-4750-Removed Console warning
        data.append("file", gdprFilesDataNew[i]);
      }
      fetch("/agencyOnBoardDetails/insertGDPRDocument", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {
          console.log("Return with response");
        })
        .catch(function (err) {
          console.log(err);
        });
      this.setState({
        files: data,
      });
      msg = "Please click on submit button to save data";
      message = document.getElementById("gdprMessage" + qID);
      number = document.getElementById("gdprMessage" + qID).innerHTML;
      if (number == "") {
        message.innerHTML += msg;
      }
    } else if (i === 1) {   //Chaitanya-4750-Removed Console warning
      name = e.target.name;
      name.split("#");
      qID = name[0];
      complianceID = name[2];
      let gdprFilesData = e.target.files;
      gdprFilesDataNew = [];
      duplicategdprList = document.getElementById(
        "duplicategdprList" + qID
      );
      for (let i = 0, l = gdprFilesData.length; i < l; i++) {   //Chaitanya-4750-Removed Console warning
        let newItem = gdprFilesData[i].name;    //Chaitanya-4750-Removed Console warning

        if (gdprnames1.indexOf(newItem) === -1) {
          gdprnames1.push(newItem);
          gdprFilesDataNew.push(gdprFilesData[i]);
        } else {
          duplicategdprList.innerHTML =
            newItem + " File has already uploaded";   //Chaitanya-4750-Removed Console warning
        }
      }
      //var link;   //Chaitanya-4750-Removed Console warning
      filename = "";
      gdprarrayLength = gdprnames1.length;
      nooutputgdpr = document.getElementById("nogdprList" + qID);
      if (gdprarrayLength > 0) {
        nooutputgdpr.innerHTML = gdprarrayLength + " Files Selected";
      } else {
        nooutputgdpr.innerHTML = "0 Files Selected";
      }
      HTML = "<table>";
      for (let j = 0; j < gdprnames1.length; j++) {   //Chaitanya-4750-Removed Console warning
        // User Story - 3427 - Karan Jagtap - changed remove icon
        link =
          '<a class="removeGdprFile1" href="#" data-fileid=' +
          '"' +
          gdprnames1[j] +
          '"' +
          '><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
        HTML +=
          "<tr><td style='display:flex;align-items:center;'>" +
          link +
          gdprnames1[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("gdprList" + qID).innerHTML = HTML;
      //var orgID = this.state.agencyID;    //Chaitanya-4750-Removed Console warning
      $(document).on("click", ".removeGdprFile1", function (e) {
        e.preventDefault();
        var clickedFile = $(this).closest("a").data("fileid");
        var j = 0;
        for (j = 0; j < gdprnames1.length; j++) {
          var temp = j;
          if (gdprnames1[temp] === clickedFile) {
            filename = gdprnames1[temp];
            gdprnames1.splice(j, 1);
            $(this).parent().remove();
            gdprarrayLength = gdprnames1.length;
            /**
             * @author Narendra Phadke
             * @param Delete File From Database
             */
            //var typeOfSuppDoc = "Gdpr";   //Chaitanya-4750-Removed Console warning
            let data = {
              //orgID: orgID,		Priyanka--4062--removed params
              qID: qID,
              names: filename,
              complianceID: complianceID,
            }; //Akash-task-4046
            /*@author Sonali Kalke
             *@fileName :
             *Desc:delete files from database.
             */
            fetch("agencyOnBoardDetails/deleteGDPRDocument", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            });
            document.getElementById("gdprfile" + qID).value = "";
            if (gdprarrayLength > 0) {
              nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
            } else {
              nooutputgdpr.innerHTML = "0 File Selected";
              $("#gdpr").val("");
              var message = document.getElementById("gdprMessage" + qID);
              message.innerHTML = "";
            }
          } 
        }
      }); 
      data = new FormData();
      data.append("qID", qID);
      //data.append("orgID", this.state.agencyID);		Priyanka--4062--removed params
      //  alert("orgID=="+this.state.agencyID)//Akash-task-4046
      data.append("complianceID", complianceID);
      for (let i = 0, l = gdprFilesDataNew.length; i < l; i++) {    //Chaitanya-4750-Removed Console warning
        data.append("file", gdprFilesDataNew[i]);
      }
      fetch("/agencyOnBoardDetails/insertGDPRDocument", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {
          console.log("Return with response");
        })
        .catch(function (err) {
          console.log(err);
        });
      this.setState({
        files: data,
      });
      msg = "Please click on submit button to save data";
      message = document.getElementById("gdprMessage" + qID);
      number = document.getElementById("gdprMessage" + qID).innerHTML;
      if (number == "") {
        message.innerHTML += msg;
      }
    } else if (i === 3) {   //Chaitanya-4750-Removed Console warning
      name = e.target.name;
      name.split("#");
      qID = name[0];
      complianceID = name[2];
      let gdprFilesData = e.target.files;
      gdprFilesDataNew = [];
      duplicategdprList = document.getElementById(
        "duplicategdprList" + qID
      );

      for (let i = 0, l = gdprFilesData.length; i < l; i++) {   //Chaitanya-4750-Removed Console warning
        let newItem = gdprFilesData[i].name;    //Chaitanya-4750-Removed Console warning

        if (gdprnames3.indexOf(newItem) === -1) {
          gdprnames3.push(newItem);
          gdprFilesDataNew.push(gdprFilesData[i]);
        } else {
          duplicategdprList.innerHTML =
            newItem + " File has already uploaded";   //Chaitanya-4750-Removed Console warning
        }
      }

      //var link;   //Chaitanya-4750-Removed Console warning
      filename = "";
      gdprarrayLength = gdprnames3.length;
      nooutputgdpr = document.getElementById("nogdprList" + qID);
      if (gdprarrayLength > 0) {
        nooutputgdpr.innerHTML = gdprarrayLength + " Files Selected";
      } else {
        nooutputgdpr.innerHTML = "0 Files Selected";
      }
      HTML = "<table>";
      for (let j = 0; j < gdprnames3.length; j++) {   //Chaitanya-4750-Removed Console warning
        // User Story - 3427 - Karan Jagtap - changed remove icon
        link =
          '<a class="removeGdprFile2" href="#" data-fileid=' +
          '"' +
          gdprnames3[j] +
          '"' +
          '><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
        HTML +=
          "<tr><td style='display:flex;align-items:center;'>" +
          link +
          gdprnames3[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("gdprList" + qID).innerHTML = HTML;
      //var orgID = this.state.agencyID;    //Chaitanya-4750-Removed Console warning
      $(document).on("click", ".removeGdprFile2", function (e) {
        e.preventDefault();
        var clickedFile = $(this).closest("a").data("fileid");
        var j = 0;
        for (j = 0; j < gdprnames3.length; j++) {
          var temp = j;
          if (gdprnames3[temp] === clickedFile) {
            filename = gdprnames3[temp];
            gdprnames3.splice(j, 1);
            $(this).parent().remove();
            gdprarrayLength = gdprnames3.length;
            /**
             * @author Narendra Phadke
             * @param Delete File From Database
             */
            //var typeOfSuppDoc = "Gdpr";   //Chaitanya-4750-Removed Console warning
            let data = {
              //orgID: orgID,		Priyanka--4062--removed params
              qID: qID,
              names: filename,
              complianceID: complianceID,
            }; //Akash-task-4046
            /*@author Sonali Kalke
             *@fileName :
             *Desc:delete files from database.
             */
            fetch("agencyOnBoardDetails/deleteGDPRDocument", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            });
            document.getElementById("gdprfile" + qID).value = "";
            if (gdprarrayLength > 0) {
              nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
            } else {
              nooutputgdpr.innerHTML = "0 File Selected";
              $("#gdpr").val("");
              var message = document.getElementById("gdprMessage" + qID);
              message.innerHTML = "";
            }
          } 
        } 
      }); 
      data = new FormData();
      data.append("qID", qID);
      //data.append("orgID", this.state.agencyID);		Priyanka--4062--removed params
      data.append("complianceID", complianceID);
      for (let i = 0, l = gdprFilesDataNew.length; i < l; i++) {    //Chaitanya-4750-Removed Console warning
        data.append("file", gdprFilesDataNew[i]);
      }
      console.warn("newData", data);
      fetch("/agencyOnBoardDetails/insertGDPRDocument", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {
          console.log("Return with response");
        })
        .catch(function (err) {
          console.log(err);
        });
      this.setState({
        files: data,
      });

      msg = "Please click on submit button to save data";
      message = document.getElementById("gdprMessage" + qID);
      number = document.getElementById("gdprMessage" + qID).innerHTML;
      if (number == "") {
        message.innerHTML += msg;
      }
    } else if (i === 6) {   //Chaitanya-4750-Removed Console warning
      name = e.target.name;
      name.split("#");
      qID = name[0];
      complianceID = name[2];
      let gdprFilesData = e.target.files;
      gdprFilesDataNew = [];
      duplicategdprList = document.getElementById(
        "duplicategdprList" + qID
      );
      for (let i = 0, l = gdprFilesData.length; i < l; i++) {   //Chaitanya-4750-Removed Console warning
        let newItem = gdprFilesData[i].name;    //Chaitanya-4750-Removed Console warning
        if (gdprnames6.indexOf(newItem) === -1) {
          gdprnames6.push(newItem);
          gdprFilesDataNew.push(gdprFilesData[i]);
        } else {
          duplicategdprList.innerHTML =
            newItem + " File has already uploaded";   //Chaitanya-4750-Removed Console warning
        }
      }
      //var link;   //Chaitanya-4750-Removed Console warning
      filename = "";
      gdprarrayLength = gdprnames6.length;
      nooutputgdpr = document.getElementById("nogdprList" + qID);
      if (gdprarrayLength > 0) {
        nooutputgdpr.innerHTML = gdprarrayLength + " Files Selected";
      } else {
        nooutputgdpr.innerHTML = "0 Files Selected";
      }
      HTML = "<table>";
      for (let j = 0; j < gdprnames6.length; j++) {   //Chaitanya-4750-Removed Console warning
        // User Story - 3427 - Karan Jagtap - changed remove icon
        link =
          '<a class="removeGdprFile3" href="#" data-fileid=' +
          '"' +
          gdprnames6[j] +
          '"' +
          '><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
        HTML +=
          "<tr><td style='display:flex;align-items:center;'>" +
          link +
          gdprnames6[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("gdprList" + qID).innerHTML = HTML;
      //var orgID = this.state.agencyID;    //Chaitanya-4750-Removed Console warning
      $(document).on("click", ".removeGdprFile3", function (e) {
        e.preventDefault();
        var clickedFile = $(this).closest("a").data("fileid");
        var j = 0;
        for (j = 0; j < gdprnames6.length; j++) {
          var temp = j;
          if (gdprnames6[temp] === clickedFile) {
            filename = gdprnames6[temp];
            gdprnames6.splice(j, 1);
            $(this).parent().remove();
            gdprarrayLength = gdprnames6.length;
            /**
             * @author Narendra Phadke
             * @param Delete File From Database
             */
            //var typeOfSuppDoc = "Gdpr";   //Chaitanya-4750-Removed Console warning
            let data = {
              //orgID: orgID,		Priyanka--4062--removed params
              qID: qID,
              names: filename,
              complianceID: complianceID,
              //typeOfSuppDoc:typeOfSuppDoc
            };
            /*@author Sonali Kalke
             *@fileName :
             *Desc:delete files from database.
             */
            fetch("agencyOnBoardDetails/deleteGDPRDocument", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            });
            document.getElementById("gdprfile" + qID).value = "";
            if (gdprarrayLength > 0) {
              nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
            } else {
              nooutputgdpr.innerHTML = "0 File Selected";
              $("#gdpr").val("");
              var message = document.getElementById("gdprMessage" + qID);
              message.innerHTML = "";
            }
          } 
        } 
      }); 
      data = new FormData();
      data.append("qID", qID);
      //data.append("orgID", this.state.agencyID);	Priyanka--4062--removed params
      data.append("complianceID", complianceID);
      for (let i = 0, l = gdprFilesDataNew.length; i < l; i++) {    //Chaitanya-4750-Removed Console warning
        data.append("file", gdprFilesDataNew[i]);
      }
      console.warn("newData", data);
      fetch("/agencyOnBoardDetails/insertGDPRDocument", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {
          console.log("Return with response");
        })
        .catch(function (err) {
          console.log(err);
        });
      this.setState({
        files: data,
      });
      msg = "Please click on submit button to save data";
      message = document.getElementById("gdprMessage" + qID);
      number = document.getElementById("gdprMessage" + qID).innerHTML;
      if (number == "") {
        message.innerHTML += msg;
      }
    }
  }
  render() {
    const { user } = this.props.auth;
    const agencyID = user.id;
    if (agencyID == "") {
      //this.state.buttonDisplay = "disabled";  //Chaitanya-4750-Removed Console warning
      this.setState({buttonDisplay:"disabled",displayChk1:"disabled",displayChk2:"disabled"});
      
      this.state.infoMsg = "Please Fill Company Information First";
    } else {
      this.state.buttonDisplay = "";
      this.state.displayChk1 = "";
      this.state.displayChk2 = "";
      this.state.infoMsg = "";
    }
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
    /*@author Sanobar Golandaj
     *@fileName :
     *Desc:display table for Gdpr on gdpr handle change
     */
    const content = this.state.checked ? (
      <div class="table-responsive-lg">
        <table id="myTable" className=" table table-bordered table-striped">
          <thead>
            <tr style={{ backgroundColor: "#909394", color: "white" }}>
              <th>GDPR Requirement Questionnaire:</th>
              <th>Details</th>
              <th>Associated Document</th>
            </tr>
          </thead>
          <tbody>
            {/* kiran - bug 4116 - checked with question length*/}

            {this.state.gdprquestionList.length > 0 &&
            this.state.gdprInfo.length > 0
              ? this.state.gdprquestionList.map((gdprquestionList, i) => {
                  if (
                    (this.state.gdprquestionList[0].complianceName = "true")
                  ) {
                    return (
                      <tr>
                        <td>
                          <label class="word-wrap">
                            {gdprquestionList.questions}
                            {Number(gdprquestionList.qID) === 3 ? (   //Chaitanya-4750-Removed Console warning
                              <Tooltip title="Opt-In-process">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            ) : (
                              " "
                            )}

                            {Number(gdprquestionList.qID) === 4 ? (   //Chaitanya-4750-Removed Console warning
                              <Tooltip title="Provide the link of privacy policy">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            ) : (
                              " "
                            )}

                            {Number(gdprquestionList.qID) === 7 ? (   //Chaitanya-4750-Removed Console warning
                              <Tooltip title="Training types and frequency">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            ) : (
                              " "
                            )}

                            {Number(gdprquestionList.qID) === 8 ? (   //Chaitanya-4750-Removed Console warning
                              <Tooltip title="Email id of DPO">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            ) : (
                              " "
                            )}
                          </label>
                        </td>

                        <td>
                          <Radio.Group>
                            <Radio
                              value="Yes"
                              id="gdpr-1"
                              checked={
                                (this.state.gdprInfo[i]
                                  ? this.state.gdprInfo[i].answer
                                  : "No") === "Yes"
                              } //kiran -bug 4116-checked radiobox value
                              onChange={this.handleChangeGdprRadio.bind(
                                this,
                                i
                              )}
                            >
                              Yes
                            </Radio>

                            <Radio
                              value="No"
                              id="gdpr-1"
                              checked={
                                (this.state.gdprInfo[i]
                                  ? this.state.gdprInfo[i].answer
                                  : "No") === "No"
                              } //kiran -bug 4116-checked radiobox value
                              onChange={this.handleChangeGdprRadio.bind(
                                this,
                                i
                              )}
                            >
                              No
                            </Radio>
                          </Radio.Group>
                        </td>

                        {/* kiran - bug 4116- done changes as per responsiveness */}
                        <td style={{ width: "380px" }}>
                          {gdprquestionList.suppDoc.toString() === "Yes" ? (    //Chaitanya-4750-Removed Console warning
                            <div>
                              <input
                                type="file"
                                style={{ color: "transparent", border: "none" }}
                                id={"gdprfile" + gdprquestionList.qID}
                                name={
                                  gdprquestionList.qID +
                                  "#" +
                                  gdprquestionList.complianceID
                                }
                                onChange={this.handleChangeGdprFile.bind(
                                  this,
                                  i
                                )}
                                multiple
                              ></input>
                              <div>
                                <div>
                                  {this.state.gdprInfo[i].documentName ===
                                    null ||
                                  this.state.gdprInfo[i].documentName === "" ||
                                  this.state.gdprInfo[i].documentName ===
                                    undefined ? (
                                    <div
                                      id={"nogdprList" + gdprquestionList.qID}
                                    >
                                      0 file Selected{" "}
                                    </div>
                                  ) : (
                                    <div
                                      id={"nogdprList" + gdprquestionList.qID}
                                    >
                                      Uploaded file:
                                      {this.state.gdprInfo[i].documentName}
                                    </div>
                                  )}
                                  <div
                                    id={
                                      "duplicategdprList" + gdprquestionList.qID
                                    }
                                    style={{ color: "red" }}
                                    className="word-wrap"
                                  ></div>
                                  <div
                                    id={"gdprList" + gdprquestionList.qID}
                                    className="word-wrap"
                                  ></div>

                                  <div
                                    id={"gdprMessage" + gdprquestionList.qID}
                                    style={{ color: "green" }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            " "
                          )}
                        </td>
                      </tr>
                    );
                  }
                })
              : ""}
          </tbody>
        </table>
      </div>
    ) : null;

    /*@author Sanobar Golandaj
     *@fileName :
     *Desc:display table for Casl on casl handle change
     */
    const content1 = this.state.checked1 ? (
      <div class="table-responsive-lg">
        <table id="myTable" className=" table table-bordered table-striped">
          <thead>
            <tr style={{ backgroundColor: "#909394", color: "white" }}>
              <th>CASL Requirement Questioanire:</th>
              <th style={{ width: "425px" }}>Details</th>
            </tr>
          </thead>
          <tbody>
            {this.state.caslquestionList.map((caslquestionList, i) => {
              if ((this.state.caslquestionList[0].complianceName = "true")) {
                return (
                  <tr>
                    <td>
                      <label class="word-wrap">
                        {/*caslquestionList.questions*/}
                        {unescape(caslquestionList.questions)/*vrushabh-task-4142* remove %20 from UI string */}

                      </label>
                    </td>
                    <td>
                      <Radio.Group>
                        <Radio
                          value="Yes"
                          id="gdpr-2"
                          checked={
                            this.state.caslquestionList[i].answer === "Yes"
                          }
                          onChange={this.handleChangeCaslRadio.bind(this, i)}
                        >
                          Yes
                        </Radio>
                        <Radio
                          value="No"
                          id="gdpr-2"
                          checked={
                            this.state.caslquestionList[i].answer === "No"
                          }
                          onChange={this.handleChangeCaslRadio.bind(this, i)}
                        >
                          No
                        </Radio>
                      </Radio.Group>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    ) : null;

    return (
      <div>
        <div class="container-fluid" style={{ paddingBottom: "60px" }}>
          <div
            style={{ fontSize: "22px", color: "green", paddingLeft: "490px" }}
          >
            {this.state.newsuccess}
          </div>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label="Compliance">
              {getFieldDecorator("checkbox-group", {
                initialValue: ["A", "B"],
              })(
                <Row>
                  <Col span={8}>
                    <Checkbox
                      id="GDPR"
                      name="GDPR"
                      value="GDPR"
                      disabled={this.state.displayChk1}
                      checked={this.state.checked}
                      onChange={this.handleChangeGdpr}
                    >
                      GDPR
                    </Checkbox>
                  </Col>

                  <Col span={8}>
                    <Checkbox
                      id="CASL"
                      name="CASL"
                      value="CASL"
                      disabled={this.state.displayChk2}
                      checked={this.state.checked1}
                      onChange={this.handleChangeCasl}
                    >
                      CASL
                    </Checkbox>
                  </Col>
                </Row>
              )}
            </Form.Item>

            {content}
            {content1}
            {/* kiran-bug 4116- aligned button for responsiveness */}
            <div class="row" style={{ float: "right" }}>
              <div
                class="col-xs-12  col-sm-12 col-md-6 col-lg-6"
                style={{ paddingRight: "40px", paddingBottom: "20px" }}
              >
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={this.state.buttonDisplay}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
}

AgencyGDPRCertificateEdit.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const agencyRegistrationForm3 = Form.create({ name: "register" })(
  AgencyGDPRCertificateEdit
);
export default connect(mapStateToProps, { logoutUser })(
  withRouter(agencyRegistrationForm3)
);
