/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sandeep Dhawale 
@file Name:publisherAllocationHelp.js
@Description: UI for publisherAllocationHelp
*/

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../login/authentication";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { Button, Badge } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import "./publisherAllocationHelp.css";
// const Swal = require('sweetalert2');
// const queryString = require('query-string');
// var region;
class publisherAllocationHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  } //end of state
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      console.log("Log");
    }
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    return (
      <div>
        <Navigation />
        <div className="container-fluid"  style={{ paddingTop: "115px" }}>
          <div id="helpoptiontop" className="text-right">
            {" "}
            <button
              style={{
                border: "2px solid #14254A",
                borderRadius: "40px",
                width: "14rem",
                height: "5rem", 
                backgroundColor: "white",
              }}
              type="button"
              class="btn-btn-default"
              id="btnForPubAl"
            >
              <span style={{ color: "#14254A", fontWeight: "bold" }}>
                <a href="helpOption" style={{ color: "#000000" }}>
                  Help Main Option
                </a>
              </span>
            </button>
          </div>
<div id="ContainerPubAl">
          <p
            class="createCampPub"
            style={{
              fontWeight: "bold",
              color: "black",

              fontSize: "20px",
            }}
          >
            Campaign Allocation Process:
          </p>
          <div class="createCampPub" style={{ marginBottom: "10px" }}>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                style={{ border: "1px solid lightgrey" }}
                class="embed-responsive-item"
                src="newallocation.mp4"
              />
            </div>
          </div>

          <div class="createCampPub" style={{ marginBottom: "10px" }}>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                style={{ border: "1px solid lightgrey" }}
                class="embed-responsive-item"
                src="allocationNewDash.mp4"
              />
            </div>
          </div>
          <div class="createCampPub" style={{ marginBottom: "10px" }}>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                style={{ border: "1px solid lightgrey" }}
                class="embed-responsive-item"
                src="nonGDPRDash.mp4"
              />
            </div>
          </div>
          <div class="createCampPub">
            <p class="createCampPub">
              1. Click on{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                Campaign to Allocate
              </span>{" "}
              option from Dashboard.
            </p>
            <img
              style={{
                marginLeft: "0px",
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid grey",
              }}
              className="img-responsive"
              src="newdashpub.png"
              width="90%"
              height="500px"
            />

            <p class="createCampPub">
              2. Here you can see a List of campaign which are ready to allocate
              or partial Allocated. Go to action column and click on{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                Allocate
              </span>{" "}
              option.{" "}
            </p>
            <img
              style={{
                marginLeft: "0px",
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid grey",
              }}
              className="img-responsive"
              src="partialcompletedcamp.png"
              width="90%"
              height="500px"
            />

            <p class="createCampPub">
              3. Here you can see Allocation page with campaign details and
              Publisher list. If Campaign have country which comes under GDPR
              compliance, then we see a <br /> message{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {" "}
                Targeted Country is GDPR Compliance. Do you want to allocate
                GDPR Compliance Publisher?
              </span>{" "}
            </p>
            <img
              style={{
                marginLeft: "0px",
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid grey",
              }}
              className="img-responsive"
              src="campallocationpub.png"
              width="90%"
              height="500px"
            />

            <p class="createCampPub">
              4. If you select Yes, then it shows GDPR base publishers list and
              if you select No then it will show Non-GDPR publisher.{" "}
            </p>
            <img
              style={{
                marginLeft: "0px",
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid grey",
              }}
              className="img-responsive"
              src="campallocationGDPR.png"
              width="90%"
              height="500px"
            />

            <p class="createCampPub">
              5. Now enter required values like lead allocation, CPL to selected
              publisher and just click on Allocate button.
            </p>
            <img
              style={{
                marginLeft: "0px",
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid grey",
              }}
              className="img-responsive"
              src="selectcpl.png"
              width="90%"
              height="500px"
            />
            <img
              style={{
                marginLeft: "0px",
                marginTop: "10px",
                marginBottom: "19px",
                border: "1px solid grey",
              }}
              className="img-responsive"
              src="selectcpl2.png"
              width="90%"
              height="500px"
            />
            </div>
          </div>

          <hr style={{ border: "1px solid grey" }} />
          <div class="row" id="rowwPub">
            <h3
              class="createCampPub"
              id="artical"
              className="text-center"
              style={{
                marginTop: "10px",

                color: " #14254A",
              }}
            >
              Was this artical helpful?
            </h3>
            <button
              style={{
                border: "2px solid #14254A",
                borderRadius: "40px",
                width: "100px",
                backgroundColor: "white",
              }}
              type="button"
              class="btn-btn-default"
              id="btnYes"
            >
              <span style={{ color: "#14254A", fontWeight: "bold" }}>Yes</span>
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              style={{
                border: "2px solid #14254A",
                borderRadius: "40px",
                width: "100px",
                backgroundColor: "white",
              }}
              type="button"
              className="btn-btn-default"
            >
              <span style={{ color: "#14254A", fontWeight: "bold" }}>No</span>
            </button>
          </div>

          <hr style={{ border: "1px solid grey" }} />
          <div id="helpmainoption" className="text-center">
            {" "}
            <button
              style={{
                border: "2px solid #14254A ",
                borderRadius: "40px",
                width: "14rem", // mufiz 3910 responsive
                height: "5rem",
                backgroundColor: "white",
              }}
              type="button"
              class="btn-btn-default"
            >
              <span style={{ color: "#14254A", fontWeight: "bold" }}>
                <a href="helpOption" style={{ color: "#000000" }}>
                  Help Main Option
                </a>
              </span>
            </button>
          </div>
        </div>
        <br />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
} //end of class
/**
 * @author Sandeep Dhawale
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
publisherAllocationHelp.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(publisherAllocationHelp));
