      /* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Shivani Pathak
*@fileName :updateInvoice.js
 @Creation Date:
 *Desc: invoice
*/

import React,{Component} from 'react';
import {withRouter} from 'react-router-dom';
import {logoutUser} from '../login/authentication';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Navigation from '../layouts/navPage';
import Footer from '../layouts/footer';

import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
import {Input} from 'antd';
import { Tooltip} from 'antd';
import { Icon} from 'antd';

import './updateInvoice.css';


const queryString = require('query-string');
const Swal = require('sweetalert2');

  
var count=0;

class InvoiceNew extends Component{
    constructor(props){
    super(props)
    this.state={
        invoiceDetail:[],
        invoiceDetail1:[],
        columnInvoiceSearch:[],
        invoiceInfo:[],
        errors: {},
        alertDisplay:'none',
        alertBorder: '',
        alertWidth:'',
        alertHeight:'',
        alertAlign:'',
        prevcpl:'',
        newsuccess:"",
        spanSpaceDisplay:'none',
        tableSearchText:"",
        tempInvoiceArray:[],
         }

    this.handleChangeSearch=this.handleChangeSearch.bind(this);

}

    componentDidMount()
    {
        if (!this.props.auth.isAuthenticated)
        {
            this.props.history.push('/userLogin');
        }
        else 
        {
            var parsed = this.props.location.state;
            let invoiceID = parsed.invoiceID;//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
          
          
           fetch("invoicePDF/getLeadForUpdateInvoice?invoiceID=" + invoiceID)
            
                .then(res => res.json())
                .then(invoiceDetail => {
                    this.setState({ invoiceDetail: invoiceDetail, tempInvoiceArray: invoiceDetail })
                }).catch(function (err) { console.log(err) });

            fetch("invoicePDF/getInvoiceInfo?invoiceID=" + invoiceID )//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
                .then(res => res.json())
                .then(invoiceInfo => {
                    this.setState({ invoiceDetail1: invoiceInfo })
                }).catch(function (err) { console.log(err) });
        }
    }// End of componentDidMount

    validateForm ()
    {
      
        let cplValidate=false;
        let formIsValid =false;
        let errors = {};
        let spanDisplay = 'none';
        let billableLeadValidate = false;
 
      
var invoiceDetail2 = this.state.invoiceDetail;
var dynamiclength=invoiceDetail2.length;

for(var i=0;i<dynamiclength;i++)
{
if (invoiceDetail2[i].CPL === undefined||invoiceDetail2[i].CPL===""||invoiceDetail2[i].CPL===null) 
{
   
        cplValidate=false;
        errors["commonError"] ="Please fill below required fields.";
        this.setState({
            errors: errors,
            alertBorder: '1px solid red',
            newsuccess:'',
            alertHeight:'50px',
            alertWidth:'1000px',
            alertAlign:'center',
            alertDisplay:'block',
            spanSpaceDisplay:spanDisplay,
                      });
                      
                      return cplValidate;
}
     else
    { 
                        //atleast one entry present which means CPL present   
               var pattern = new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/); 
                 if (!pattern.test(invoiceDetail2[i].CPL)) 
                 {
                    cplValidate=false;
                   errors["CPL"] = "Please enter valid number for Cpl.";
                   this.setState({
                       errors: errors,
                       alertBorder: '1px solid red',
                       newsuccess:'',
                       alertWidth:'1000px',
                       alertHeight:'50px',
                       alertAlign:'center',
                       alertDisplay:'block',
                       spanSpaceDisplay:spanDisplay,
                                 });
                                 return cplValidate;
                 }
                 else
                 {
                    cplValidate = true;   
                    count++;
                    errors["CPL"] ="";
                 }
                  
   }
// end of cpl validation

//billableLead validation

if (!pattern.test(invoiceDetail2[i].billableLead)) 
{
    billableLeadValidate=false;
   errors["billableLead"] ="Please enter valid number for Billable Lead.";
   this.setState({
       errors: errors,
       alertBorder: '1px solid red',
       newsuccess:'',
       alertWidth:'1000px',
       alertHeight:'50px',
       alertAlign:'center',
       alertDisplay:'block',
       spanSpaceDisplay:spanDisplay,
                 });
                 return billableLeadValidate;
}
                
else
{
   
    if (invoiceDetail2[i].billableLead>invoiceDetail2[i].acceptedLead)
     {
    billableLeadValidate=false;
    errors["billableLead"]="Billable Lead can't be greater than Accepeted Lead."  ;
    this.setState({
        errors: errors,
        alertBorder: '1px solid red',
        newsuccess:'',
        alertWidth:'1000px',
        alertHeight:'50px',
        alertAlign:'center',           
        alertDisplay:'block',
        spanSpaceDisplay:spanDisplay,
                  });
                
                  return billableLeadValidate;
    }
  else{
    billableLeadValidate = true;    
      count++;
       errors["billableLead"] ="";
     }

}
// end of billableLead validation
}
if
(cplValidate===false || billableLeadValidate===false)
{
    formIsValid=false;
    this.setState({
        errors: errors,
        alertBorder: '1px solid red',
        alertWidth: '1000px',
        alertAlign: 'center',
      alertDisplay: 'block',
       spanSpaceDisplay: spanDisplay
  
      });
  
}

else{
   formIsValid=true;                                    
   } 
   return formIsValid;
    }

    // backbutton function
InvoicetBackButton(e)
{
  e.preventDefault();
    var parsed = this.props.location.state;//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
    var invoiceID = parsed.invoiceID;


 this.props.history.push("/agencyInvoicesList",{invoiceID:invoiceID});
  
}
//end of backbutton function

    handleChange(i,e)
    {
        
        const { name, value } = e.target;
        let invoiceDetail= [...this.state.invoiceDetail];
        invoiceDetail[i] = { ...invoiceDetail[i], [name]: value};
        
       
        if(name==='billableLead')
        {
            var a= value*invoiceDetail[i].CPL ;
            let total=parseInt(a);
            invoiceDetail[i].Total=total;
        }
        if(name==="CPL")
        {
            var b=value*invoiceDetail[i].billableLead;
            let total=parseInt(b);
            invoiceDetail[i].Total=total;
        }
     this.setState({invoiceDetail:invoiceDetail});
    }// End of handleChange

    handleChangeSearch(e)
     {
    let currentList = [];
    let newList = [];
    let filterData = e.target.value;
if (e.target.value === "") 
{
    newList = this.state.invoiceDetail;
} 
else 
{
  
    currentList = this.state.invoiceDetail;
    newList = currentList.filter(item => {
             
      const lc = item;
   
      
      const filter = e.target.value.toLowerCase();
    
      var data=Object.values(lc);
      var dataFinal=data.toString();
      dataFinal = dataFinal.replace(",", " ");
      var dataToLower=dataFinal.toLowerCase();
     return dataToLower.includes(filter);
});
}
this.setState({
    columnInvoiceSearch:[...newList],tableSearchText:filterData
});
}
 //end of handleChangeSearch   

    updateInvoiceDocument(e) 
    {
        e.preventDefault();
        e.target.className += " was-validated";
        if (this.validateForm() === false) 
        {
            return;
        }
        else 
        {
           
            let tempInvoiceArray = this.state.tempInvoiceArray;
            let invoicelength = tempInvoiceArray.length, updateCPL = false;
            for (let i = 0; i < invoicelength; i++) 
            {
                let { campID, CPL } = tempInvoiceArray[i];
                let temp = this.state.invoiceDetail.filter((a) => { return a.campID == campID && a.CPL == CPL });
                if (temp.length === 0)
                {
                    updateCPL = true;
                }
            }
            var parsed = this.props.location.state;//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
            let invoiceID = parsed.invoiceID;
            
            const { user } = this.props.auth;
            let info = {
               invoiceID: invoiceID,
                invoiceDetail: this.state.invoiceDetail,
               //Akash-bug-4057
            }
            if (!updateCPL)
            {
                fetch("invoicePDF/updateInvoice", {
                    method: 'POST',
                    headers: {
                        "Content-Type":
                            "application/json"
                    },
                    body: JSON.stringify(info)
                }).then(res => res.json())
                    .then(res => {
                        if (res.success === true)
                        {
                            Swal.fire({
                                text: res.msg,
                                type: 'success',
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                preConfirm: () => {
                                    window.location.reload();
                                }
                            })
                        }
                         else 
                         {
                            Swal.fire({
                                text: res.msg,
                                type: 'error',
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                            })
                        }
                    });
            }
            else 
            {
                Swal.fire({
                    title: 'Are you sure you want to change current CPL?',
                    type: 'question',
                    showCancelButton: true,
                    cancelButtonColor: '#d33',
                    confirmButtonColor: '#3085d6',
                    allowOutsideClick: false,
                    confirmButtonText: 'Yes',
                }).then((results) => {
                    if (results.value) 
                    {
                        Swal.fire({
                            title: 'Do you want to apply this new CPL for all future invoices?',
                            type: 'question',
                            showCancelButton: true,
                            cancelButtonColor: '#d33',
                            confirmButtonColor: '#3085d6',
                            allowOutsideClick: false,
                            confirmButtonText: 'Yes',
                        }).then((result) => {
                                if (result.value)
                                {
                                    fetch("invoicePDF/updateInvoice", {
                                        method: 'POST',
                                        headers: {
                                            "Content-Type":
                                                "application/json"
                                        },
                                        body: JSON.stringify(info)
                                    }).then(res => res.json())
                                        .then(res => {
                                            if (res.success === true)
                                            {
                                                fetch("invoicePDF/updatePublisherCPL", {
                                                    method: 'POST',
                                                    headers: {
                                                        "Content-Type":
                                                        "application/json"
                                                    },
                                                    body: JSON.stringify(info)
                                                }).catch(function (err) { console.log(err) });
                                               Swal.fire({
                                                    text: res.msg,
                                                    type: 'success',
                                                    confirmButtonText: 'Ok',
                                                    allowOutsideClick: false,
                                                    preConfirm: () => {
                                                        window.location.reload();
                                                    }
                                                })
                                            } 
                                            else 
                                            {
                                                Swal.fire({
                                                    text: res.msg,
                                                    type: 'error',
                                                    confirmButtonText: 'Ok',
                                                    allowOutsideClick: false,
                                                })
                                            }

                                        });
                                } 
                                else 
                                {
                                    fetch("invoicePDF/updateInvoice", {
                                        method: 'POST',
                                        headers: {
                                            "Content-Type":
                                                "application/json"
                                        },
                                        body: JSON.stringify(info)
                                    }).then(res => res.json())
                                        .then(res => {
                                            if (res.success === true)
                                            {
                                                Swal.fire({
                                                    text: res.msg,
                                                    type: 'success',
                                                    confirmButtonText: 'Ok',
                                                    allowOutsideClick: false,
                                                    preConfirm: () => {
                                                        window.location.reload();
                                                    }
                                                })
                                            } 
                                            else 
                                            {
                                                Swal.fire({
                                                    text: res.msg,
                                                    type: 'error',
                                                    confirmButtonText: 'Ok',
                                                    allowOutsideClick: false,
                                                })
                                            }
                                        });

                                }
                            })
                    }
                })
            }
        }
    }// End of updateInvoiceDocument

    render()
    {
       
        return (

            <div style={{ overflowX: 'hidden' }}>
                <Navigation />
                <div class="container-fluid" style={{ paddingTop: '90px', marginBottom: '10px' }}>
                    <div class="row">
                        <div class="col-md-1 ">
                            <div>
                                <a href='#/' style={{ color: '#056eb8' }} onClick={this.InvoicetBackButton.bind(this)}><FaArrowAltCircleLeft size={32} style={{ float: 'left' }} title="" /></a>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 offset-lg-4 offset-md-4  offset-sm-4">
                            <label id="label" style={{ fontSize: "20px", fontWeight: "bold", color: "#056eb8" }}>Invoice Correction</label>
                        </div>
                    </div> {/*end of first row*/}
                    <div class="col-lg-12" className="displayerrorinvoice" style={{ display: this.state.alertDisplay }}>
                        <div class="container-fluid" style={{ paddingBottom: '20px', alertWidth: '1000px', alertHeight: '50px', alertBorder: '1px solid red', alertAlign: 'center', alertDisplay: 'block' }}>
                            <div class="card-body" >
                                <div class="col-lg-12" style={{ border: this.state.alertBorder }}>
                                    <div className="errorMessage">
                                        {this.state.errors.commonError ? this.state.errors.commonError : ''}
                                        {this.state.errors.billableLead ? this.state.errors.billableLead : ''}
                                        {this.state.errors.CPL ? this.state.errors.CPL : ''}
                                    </div>
                                </div>
                                <div class="successMessage">
                                {this.state.newsuccess} 
                                </div>
                            </div>{/*end of card body*/}
                        </div>
                    </div>
                    <div style={{ MozBoxShadow: '2px 2px 3px 0px #e6e6ff', WebkitBoxShadow: '2px 2px 3px 1px #e6e6ff', boxShadow: '2px 2px 3px 0px #e6e6ff' }}>
                        <div style={{ padding: '15px', height: 'auto', marginBottom: '15px' }}>

                            {this.state.invoiceDetail1.map(invoiceDetail1 => (
                                <div class="row">
                                    <div class="col-sm-4 col-md-6 col-lg-4">
                                        <label id="label" style={{ color: '#000080', width: '172px' }}>
                                            Publisher Name
                </label><span style={{ color: '#000000', fontWeight: 'lighter' }}>:{invoiceDetail1.publisherName}</span>
                                    </div>
                                    <div class="col-sm-4 col-md-6 col-lg-4">
                                        <label id="label" style={{ color: '#000080', width: '172px' }}>
                                            Invoice ID
                </label><span style={{ color: '#000000', fontWeight: 'lighter' }} >:{invoiceDetail1.invoiceID}</span>
                                    </div>
                                    <div class="col-sm-4 col-md-6 col-lg-4">
                                        <label id="label" style={{ color: '#000080', width: '172px' }}>Invoice Month</label>
                :{invoiceDetail1.month}
                                    </div>
                                    <div class="col-sm-4 col-md-6 col-lg-4">
                                        <label id="label" style={{ color: '#000080', width: '172px' }}> Invoice Currency</label>
             :{invoiceDetail1.currency}</div>

                                    <div class="col-sm-4 col-md-6 col-lg-4">
                                        <label id="label" style={{ color: '#000080', width: '172px' }}>Invoice Generated Date </label>

                                     :{invoiceDetail1.created}
                                    </div>
                               </div>
                            )
                            )}
                        </div>
                    </div> {/*end of iteration div*/}

                    <div class="search_bar" class="col-sm-12 col-md-4 col-lg-12 offset-md-7 offset-lg-9">
                        <span><Tooltip title="Search by campaign ID OR any campaign keyword.">
                            <Icon style={{ marginRight: '5px' }} type="question-circle-o" />
                        </Tooltip>
                            <input type="text" name="search" placeholder="Search........"
                                style={{
                                    backgroundImage: 'url(searchIcon.png)',
                                    backgroundPosition: '15px 6px',
                                    backgroundSize: '15px 15px',
                                    backgroundRepeat: "no-repeat",
                                    width: '280px',
                                    height: '30px',
                                    fontSize: '10px',
                                    border: '1px solid lightGray',
                                    padding: '12px 20px 12px 34px',
                                    borderRadius: "5px"
                                }}
                                onChange={this.handleChangeSearch} onPressEnter={this.handleChangeSearch}
                            >
                            </input>
                        </span>
                    </div>{/*end of search bar div*/}


                    <div class=" col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="card card-signin my-1" style={{ overflowY: 'scroll', height: '300px' }}>
                            <table class="table table-bordered "
                                style={{ paddingTop: '80px', borderCollapse: 'collapse', border: '1px solid #D3D3D3', overflowX: 'hidden' }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '180px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Campaign ID</th>
                                        <th style={{ width: '180px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Campaign Name</th>
                                        <th style={{ width: '180px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Allocated Leads</th>
                                        <th style={{ width: '180px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Accepted Leads</th>
                                        <th style={{ width: '180px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Billable Leads</th>
                                        <th style={{ width: '180px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>CPL</th>
                                        <th style={{ width: '180px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Total Amount</th>
                                        <th style={{ width: '180px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Comment</th>
                                    </tr>
                                </thead>

                                {this.state.tableSearchText === "" ?
                                    this.state.invoiceDetail.map((item, i) => (
                                        <tbody style={{ textAlign: "center", marginTop: '10px' }}>
                                            <tr>
                                                <td>{item.campID}</td>
                                                <td>{item.campaignName}</td>
                                                <td>{item.allocatedLead}</td>
                                                <td>{item.acceptedLead}</td>
                                                <td><Input id="billableLead" name="billableLead" style={{ width: '100px' }} defaultValue={item.billableLead} value={item.billableLead} onChange={this.handleChange.bind(this, i)} />

                                                    <br />
                                                </td>
                                                <td><Input id="CPL" name="CPL" style={{ width: '100px' }} defaultValue={item.CPL} value={item.CPL} onChange={this.handleChange.bind(this, i)} />
                                                </td>
                                                <td>{item.Total}&nbsp;{item.currency}</td>
                                                <td>{item.comment}</td>
                                            </tr>
                                        </tbody>
                                    ))
                                    :
                                    this.state.columnInvoiceSearch.map((item, i) => (
                                        <tbody style={{ textAlign: "center", marginTop: '10px' }}>
                                            <tr>
                                                <td>{item.campID}</td>
                                                <td>{item.campaignName}</td>
                                                <td>{item.allocatedLead}</td>
                                                <td>{item.acceptedLead}</td>
                                                <td><Input id="billableLead" name="billableLead" style={{ width: '100px' }} defaultValue={item.billableLead} value={item.billableLead} onChange={this.handleChange.bind(this, i)} />
                                                    <div style={{ fontSize: '14px', color: 'red' }}>{this.state.errors.commonError}</div>
                                                    <div className="errorMsg" style={{ fontSize: '14px', color: 'red', marginBottom: '-12px' }}>{this.state.errors.billableLead}</div>
                                                    <br />
                                                </td>
                                                <td><Input id="CPL" name="CPL" style={{ width: '100px' }} defaultValue={item.CPL} value={item.CPL} onChange={this.handleChange.bind(this, i)} />
                                                    <div style={{ fontSize: '14px', color: 'red' }}>{this.state.errors.commonError}</div>
                                                    <div className="errorMsg" style={{ fontSize: '14px', color: 'red', marginBottom: '-12px' }}>{this.state.errors.cpl}</div>
                                                </td>

                                                <td>{item.Total}&nbsp;{item.currency}</td>
                                                <td>{item.comment}</td>
                                            </tr>
                                        </tbody>
                                    ))
                                }
                            </table>{/*end of table*/}
                        </div>{/*end of card card-signin my-1*/}

                        <div class="float-md-right ">
                            <button type="button" class="btn add-button" style={{ backgroundColor: "#2196F3", color: "white", marginBottom: '60px' }} onClick={this.updateInvoiceDocument.bind(this)}>Update Invoice</button>
                        </div>
                    </div>{/*end of main table div*/}
                </div>
                <Footer />
            </div> 
        );
    }
}

 /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
InvoiceNew.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})
export default  connect(mapStateToProps, { logoutUser })(withRouter(InvoiceNew)); 

