/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Snehal More
@Creation Date:26-03-2019
@Description:UI for Marketo Lead  report  under Agency view
*/
import React from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { Table } from "antd";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3940-VAPT URL changes
import { css } from "emotion";
const Swal = require("sweetalert2");  //kiran- task 4289-added swal for popup
const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "14px !important",
		fontWeight: "500",
	},
	"& thead > tr >th": {
		border: "1px solid black",
		color: "white",
	},
});

class MarketoLead extends React.Component {
	constructor() {
		super();
		this.state = {
			marketoClientCampaignDetails: [],
			marketoLeadDetailsCampId: [],
			marketoClientNameDetails: [],
			marketoClientLeadDetails: [],
			marketoLeadDetails: [],
			marketoCampaignLeadDetails: [],
			clientName: "",
			campaignID: "",
			campID: "",
			// pacing: "Monthly",
			clientNameDisplay: "none",
			campaignIDDisplay: "none",
			campaignIDDisplay2: "none",
			pacingDisplay: "none",
			pageSize2: 100,
			tableState: { size: "small" },
			pacing: "Select Pacing",//Kiran-4836-added pacing
		};
		this.handleChange = this.handleChange.bind(this);
		this.uploadLeadToMarketoSubmit = this.uploadLeadToMarketoSubmit.bind(this);
		this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
		this.displaySuccessfulLeads = this.displaySuccessfulLeads.bind(this);
		this.displayFailureLeads = this.displayFailureLeads.bind(this);
	} // end of constrcutor
	//handleChange for client name and campaign id
	handleChange(e) {
		const { name, value } = e.target;
		// const { isAuthenticated, user } = this.props.auth;
		const { user } = this.props.auth; //Nilesh-4800-Removing console warnings
		var agencyID = user.id;

		if (name === "clientName") { //Nilesh-4800-Removing console warnings
			this.setState({ clientName: value });

			if (value === "Select Client Name") {
				let data1 = {
					pacing: e.target.value,
					pacing: this.state.pacing,
				};
				//snehal-task-3940-VAPT URL changes
				fetch("/leadMarketo/marketoLeadDetails", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data1),
				})
					.then((res) => res.json())
					.then((marketoLeadDetails) => {
						this.setState({
							marketoLeadDetails: marketoLeadDetails,
							marketoLeadDetailsCampId: [],
						});
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				let data = {
					clientName: value,
					pacing: this.state.pacing,
				};
				//snehal-task-3940-VAPT URL changes
				fetch("/leadMarketo/marketoClientCampaignDetails", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((marketoClientCampaignDetails) => {
						document.getElementById("clientName").style.border =
							"1px solid lightgray";
						this.setState({
							marketoLeadDetails: marketoClientCampaignDetails,
							clientName: value,
							marketoLeadDetailsCampId: marketoClientCampaignDetails,
							campaignIDDisplay2: "none",
							campaignID: "",
							table: false,
						});
					});
			}
		}
		if (name === "campaignID") { //Nilesh-4800-Removing console warnings
			this.setState({ campaignID: value });
			if (value === "Select campaign ID") {
				let data = {
					clientName: this.state.clientName,
					pacing: this.state.pacing,
				};
				//snehal-task-3940-VAPT URL changes
				fetch("/leadMarketo/marketoClientCampaignDetails", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((marketoClientCampaignDetails) => {
						this.setState({
							marketoLeadDetails: marketoClientCampaignDetails,
							clientName: this.state.clientName,
							marketoLeadDetailsCampId: marketoClientCampaignDetails,
							campaignIDDisplay2: "none",
							campaignID: "",
						});
					});
			} else {
				let data1 = {
					clientName: this.state.clientName,
					campID: e.target.value,
					pacing: this.state.pacing,
				};
				fetch("/leadMarketo/marketoCampaignLeadDetails", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data1),
				})
					.then((res) => res.json())
					.then((marketoCampaignLeadDetails) => {
						this.setState({ marketoLeadDetails: marketoCampaignLeadDetails });
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		}
		if (name === "pacing") { //Nilesh-4800-Removing console warnings
			// let data = e.target.value; //Nilesh-4800-Removing console warnings
			this.setState({
				pacing: e.target.value,
				pacingDisplay: "none",
				campaignIDDisplay2: "none",
			});
            
			//snehal-task-3940-VAPT URL changes
			let data2 = {  pacing: e.target.value };
			this.setState({ agencyID: agencyID });
			fetch("/leadMarketo/marketoClientNameDetails", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data2),
			})
				.then((res) => res.json())
				.then((marketoClientNameDetails) => {
					this.setState({ marketoClientNameDetails: marketoClientNameDetails });
				})
				.catch(function (err) {
					console.log(err);
				});
            //Nilesh-4800-Removing console warnings
			if (
				this.state.clientName !== "" &&
				this.state.campaignID !== "" &&
				this.state.clientName !== "Select Client Name" &&
				this.state.campaignID !== "Select Campaign ID"
			) {
				let data1 = {
					clientName: this.state.clientName,
					campID: this.state.campaignID,
					pacing: e.target.value,
				};
				fetch("/leadMarketo/marketoCampaignLeadDetails", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data1),
				})
					.then((res) => res.json())
					.then((marketoCampaignLeadDetails) => {
						this.setState({
							marketoLeadDetails: marketoCampaignLeadDetails,
							marketoLeadDetailsCampId: marketoCampaignLeadDetails,
						});
					})
					.catch(function (err) {
						console.log(err);
					});
			} else if (this.state.clientName !== "") { //Nilesh-4800-Removing console warnings
				let data = {
					clientName: value,
					pacing: e.target.value,
					clientName: this.state.clientName,
				};
				//snehal-task-3940-VAPT URL changes
				fetch("/leadMarketo/marketoClientCampaignDetails", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((marketoClientCampaignDetails) => {
						//alert("marketoClientCampaignDetails====>"+JSON.stringify(marketoClientCampaignDetails))
						this.setState({
							marketoLeadDetails: marketoClientCampaignDetails,
							marketoLeadDetailsCampId: marketoClientCampaignDetails,
							clientNameDisplay: "none",
							campaignIDDisplay2: "none",
						});
					});
			} else {
				let data1 = { pacing: e.target.value,};
				fetch("/leadMarketo/marketoLeadDetails", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data1),
				})
					.then((res) => res.json())
					.then((marketoLeadDetails) => {
						this.setState({ marketoLeadDetails: marketoLeadDetails });
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		}
	} // handleChange(e)
	//Function call when form is submitted for upload Lead To Salesforce button
	uploadLeadToMarketoSubmit(e) {
		e.preventDefault();
		fetch("/leadMarketo/leadMarketoIntegration", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
		}).catch((e)=>console.log(e));
		Swal.fire({
			html: "Request submitted to Marketo successfully.<br>You will receive mail after lead submitted successfully to Marketo.",
			type: "success",
			confirmButtonText: "Ok",
			allowOutsideClick: false,
			preConfirm:()=>
						{
							window.location.reload();
						}
		}); 
		//kiran- task 4289 - added popup instead on loader on button click
	}
	//Function call for successful Leads
	displaySuccessfulLeads(e) {
		e.preventDefault()
		var campID = e.target.getAttribute("campID");
		var clientName = e.target.getAttribute("clientName");
		var successLeadCount = e.target.getAttribute("successLeadCount");
		var pacing = this.state.pacing;
		this.props.history.push("/marketoLeadView", { campID:campID,clientName:clientName,successLeadCount:successLeadCount,pacing:pacing});//snehal-task-3940-VAPT URL changes
	}
	//Function call for failure Leads
	displayFailureLeads(e) {
		e.preventDefault()
		var campID = e.target.getAttribute("campID");
		var clientName = e.target.getAttribute("clientName");
		var failureLeadCount = e.target.getAttribute("failureLeadCount");
		var pacing = this.state.pacing;
		this.props.history.push("/marketoLeadView", { campID:campID,clientName:clientName,failureLeadCount:failureLeadCount,pacing:pacing});//snehal-task-3940-VAPT URL changes
	}

	//start of componentWillMount
	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
          //kiran-4836-added condition if pacing equal to Select Pacing do nothing for preventing error
			let SelectPacing=this.state.pacing;
             if(SelectPacing==="Select Pacing"){

			 }
			 else{
			// const { isAuthenticated, user } = this.props.auth;
			const { user } = this.props.auth; //Nilesh-4800-Removing console warnings
			var agencyID = user.id;
			let data = {pacing: this.state.pacing };//snehal-task-3940-VAPT URL changes
			this.setState({ agencyID: agencyID });
			
			fetch("/leadMarketo/marketoClientNameDetails", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((marketoClientNameDetails) => {
					this.setState({ marketoClientNameDetails: marketoClientNameDetails });
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("/leadMarketo/marketoLeadDetails", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((marketoLeadDetails) => {
					this.setState({ marketoLeadDetails: marketoLeadDetails });
				})
				.catch(function (err) {
					console.log(err);
				});
			}
		}
	}
	marketoLeadViewBackButton(e) {
		e.preventDefault(); //Nilesh-4800-Removing console warnings
		const { user } = this.props.auth;
		// var agencyID = user.id; //Nilesh-4800-Removing console warnings
		this.props.history.push("/newdashboard", { agencyID: user.id }); //karan-task-3684-replace query params
	}
	//handel change for pagination
	handleChangePageSize2(e) {
		var pageSize2 = e.target.value;
		this.setState({ pageSize2: pageSize2 });
	}

	render() {
		// const { isAuthenticated, user } = this.props.auth; //Nilesh-4800-Removing console warnings
		const Column = [
			{
				title: "Advertiser Name",
				dataIndex: "clientName",
				key: "clientName",
				width: "150px",
				align: "center",
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.clientName}</span>
					</div>
				),
			},
			{
				title: " Campaign ID",
				dataIndex: "campID",
				key: "campID",
				width: "150px",
				align: "center",

				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.campID}</span>
					</div>
				),
			},
			{
				title: "Successful Leads",
				dataIndex: "successLeadCount",
				key: "successLeadCount",
				align: "center",
				width: "150px",
				render: (text, record) => (
					<div>
						<span>
							<a
								href="displaySuccessfulLeads"
								onClick={this.displaySuccessfulLeads}
								style={{ fontSize: "12px", color: "green" }}
								campID={record.campID}
								clientName={record.clientName}
								successLeadCount={record.successLeadCount}
								pacing={record.pacing}>
								{record.successLeadCount}
							</a>
						</span>
					</div>
				),
			},
			{
				title: "Failure Leads",
				dataIndex: "failureLeadCount",
				key: "failureLeadCount",
				align: "center",
				width: "150px",
				render: (text, record) => (
					<div>
						<span>
							<a
								href="displayFailureLeads"
								onClick={this.displayFailureLeads}
								style={{ fontSize: "12px", color: "red" }}
								campID={record.campID}
								clientName={record.clientName}
								failureLeadCount={record.failureLeadCount}
								pacing={record.pacing}>
								{record.failureLeadCount}
							</a>
						</span>
					</div>
				),
			},
		];
		function onChange(pagination, filters, sorter) {
			console.log("params", pagination, filters, sorter);
		}
		return (
			<div>
				<Navigation />
				<div class="container-fluid" style={{ paddingTop: "110px" }}>
					<div class="row">
						<div class="col-lg-3 col-md-1 col-sm-1 col-xs-1">
							<a
								class="col"
								href="#/"  //Nilesh-4800-Removing console warnings
								style={{ color: "#056eb8" }}
								onClick={this.marketoLeadViewBackButton.bind(this)}>
								<FaArrowAltCircleLeft
									size={32}
									title=""
									className="backFrom_marketo"
								/>
							</a>
						</div>
						<div class="col-lg-6 col-md-8 col-sm-8 col-xs-8" align="center">
							<div
								class="col"
								align="center"
								style={{ fontSize: "20px", fontWeight: "bold" }}>
								<label id="labelheading" style={{ color: "#056eb8" }}>
									Marketo lead upload status
								</label>
							</div>
						</div>
						{/* kiran- task 3873- button right aligned */}
						{/* Chaitanya-task-4500-Marketo--Disable old workflow from reports section "upload leads to marketo" button */}
						{/* <div class="col-lg-3 col-md-3 col-sm-3">
							<button
								class="btn add-button"
								type="submit"
								value="submit1"
								onClick={this.uploadLeadToMarketoSubmit}
								style={{ margin: "5px",float:"right" }}>
								Upload Lead To Marketo
							</button>
						</div> */}
					</div>
				</div>
				<form method="POST" name="" id="">
					<div class="container-fluid">
						<div className="card-body">
							<div class="row">
								{/* kiran-4836-changed position of Duration dropdown */}
							<div class="col-lg-3 col-md-3 col-sm-12">
									<div>
										<label id="label" className="labelStyle">
											Duration :{" "}
										</label>
										<span className="asterisk"> *</span>
									</div>
									<div>
										<select
											required
											id="pacing"
											name="pacing"
											value={this.state.pacing}
											style={{
												height: "calc(2.25rem + 12px)",
												borderRadius: "4px",
												width: "180px",
											}}
											onChange={this.handleChange}
											class="form-control"
											>
											<option value="Select Pacing" disabled>
												Select duration
											</option>
											<option value="Monthly">This month</option>
											<option value="Yearly">This year</option>
											<option value="Quarterly">This quarter</option>
											<option value="Weekly">This Week</option>  
											<option value="Daily">This Day</option>
										</select>
										{/* added for day and week filter */}
										<span
											style={{ display: this.state.pacingDisplay }}
											className="errorMessage">
											Please select pacing
										</span>
									</div>
								</div>
								<div class="col-lg-3 col-md-3 col-sm-12">
									<div>
										<label id="label" className="labelStyle">
											Advertiser Name :
										</label>
									</div>
									<div>
										<select
											required
											id="clientName"
											name="clientName"
											value={this.state.clientName}
											onChange={this.handleChange}
											class="form-control">
											<option value="Select Client Name">
												Select Advertiser Name
											</option>
											{this.state.marketoClientNameDetails.map(
												(marketoClientNameDetails) => {
													return (
														<option value={marketoClientNameDetails.clientName}>
															{marketoClientNameDetails.clientName}
														</option>
													);
												}
											)}
										</select>
										<span
											style={{ display: this.state.clientNameDisplay }}
											className="errorMessage">
											Please enter advertiser name
										</span>
									</div>
								</div>
								<div class="col-lg-3 col-md-3 col-sm-12">
									<div>
										<label id="label" className="labelStyle">
											Campaign ID :
										</label>
									</div>
									<div>
										<select
											required
											id="campaignID"
											name="campaignID"
											style={{
												height: "calc(2.25rem + 12px)",
												borderRadius: "4px",
												width: "180px",
											}}
											value={this.state.campaignID}
											onChange={this.handleChange}
											class="form-control">
											<option value="Select campaign ID" selected>
												Select Campaign ID
											</option>

											{this.state.marketoLeadDetailsCampId.map((campID) => {
												return (
													<option value={campID.campID}>{campID.campID}</option>
												);
											})}
										</select>
										<span
											style={{ display: this.state.campaignIDDisplay }}
											className="errorMessage">
											Please select campaign ID
										</span>
									</div>
								</div>
								
							</div>
							<br />
						</div>
					</div>
				</form>
				<h4 align="center">
					<span
						style={{ display: this.state.campaignIDDisplay2 }}
						className="errorMessage">
						No campaigns are exists for advertiser : {this.state.clientName}
					</span>
				</h4>
				<div className="card card-signin my-1">
					<div class="row" style={{ marginBottom: "120px" }}>
						{this.state.marketoLeadDetails.length > 0 ? (
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div class="table-responsive">
									<Table
										{...this.state.tableState}
										id="myTable"
										bordered
										dataSource={this.state.marketoLeadDetails}
										onChange={onChange}
										columns={Column}
										// className="ant-table-thead"
										className={`${tableCSS} "ant-table-thead"`} //Nilesh-4800-Removing console warnings
										scroll={{ y: 700, x: 1000 }}
										pagination={{ pageSize: this.state.pageSize2 }}></Table>
									<p>
										<div class="row">
											<div
												class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
												style={{ marginTop: "-50px" }}>
												&nbsp;&nbsp;&nbsp;Records per page:&nbsp;
												<select
													defaultValue={this.state.pageSize2}
													onChange={this.handleChangePageSize2}
													id="pacing1"
													class="input-small"
													className="form-control"
													name="pacing"
													style={{
														width: "80px",
														height: "30px",
														display: "initial",
													}}>
													<option value="100" selected>
														100
													</option>
													<option value="150">150</option>
													<option value="200">200</option>
													<option value="250">250</option>
												</select>
											</div>
										</div>
									</p>
								</div>
							</div>
						) : (
							<div style={{padding:"10px"}}>
								{/* kiran-task 3873- added padding to no data exist for advertiser */}
								<h4 align="center">
									<span
										className="errorMessage"
										style={{
											marginLeft: "20px",
											backgroundColor: "transparent",
										}}>
											{/* kiran-4836-added condition  for showing message if pacing equal to Select Pacing and also used <> </> react fragment to allow  group multiple elements */}
											{this.state.pacing==="Select Pacing" ? ( <> &nbsp;No data exists for advertiser </>):(
												<> 
												{this.state.pacing}&nbsp;no data exists for advertiser
												:&nbsp; {this.state.clientName}
												</>
		
											)}
										{/* {this.state.pacing}&nbsp;no data exists for advertiser
										:&nbsp; {this.state.clientName} */}
									</span>
								</h4>
							</div>
						)}{" "}
						{/* snehal-task-2910--Salesforce lead--Message format issue */}
					</div>
					{/*Card-body */}
				</div>
				{/*Card */}

				<Footer />
			</div>
		);
	} // end of renders
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
MarketoLead.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(MarketoLead)
);
