/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Sandeep Dhawale
@file Name:pulisherRFPAnalysis.js
@Description: publisher RFP Analysis
*/

import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../login/authentication';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PublisherNavigation from "../layouts/publisherNavPage";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import Footer from '../layouts/footer'; 
import './publisherRFPAnalysis.css';
import { Table} from 'antd'; 
import { css } from 'emotion';
import 'antd/dist/antd.css';
import { saveAs } from "file-saver";//Sonali-3511-added to download excel file
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Sandeep-task-3729-added default header
//const queryString = require('query-string'); //rutuja 2832 - commented as never used in the file
const Swal = require("sweetalert2"); //sunita-task-3522-added for sweet alert
const xlsx = require("xlsx");//Sonali-3511-added to download excel file
//shivani-3508-changed table css as per table design requirement. 
const tableCSS1 = css({
    backgroundColor: "white",
    fontFamily: "sans-serif",
    borderStyle: "bold",
    "& thead  > tr": {
        backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
        color: "white",
        fontSize: "13px",
        textAlign: "center !important",
        fontWeight: "500",
    },
    "& thead > tr >th": {
      borderRight: "1px solid #e8e8e8",
        textAlign: "center !important",
        color: "white",
        fontSize: "12px",
  
    },
    });
class RfpAnalysis extends Component{
    constructor(props){
        super(props);
        this.state={
            agencyID:'',
            //existingRFP:[],
            RFPList:[],
            existingRFPCampDetails:[],
            rfpBasicDetails:[],
            rfpPublisherDetails:[],
            id:'',
            campID:" ",
            existingRFPsearch:[],
            tableSearchText:"",
            tableState:{size:'small'}, //Array for table properties 
            loading:false,
            rfpPublisherDataSearch:[],
            tableSearchText1:"",
            selectedCountry:"",
            cpl:0,
            leads:0,
            rfpPublisherDetailsFilterResponse:[],
            clearValue:0,
            splitCountryArray:[],
            selectedOption:"Campaign",
            byCampaign: true,
            byLead: false,
            byClient: true,
            byPublisher: false,
            pID:'', //sunita-task-3522-added pid as per requirement
            isCampaignAllocated:'',  //sunita-task-3522-added isCampaignAllocated variable for checking the follow up button enable or disable.
            // rfpDetails1:[],
               loader:'', // shivani - 3508 - declared loader , which is used to display loader .
               pageSize:50, // shivani - 3508 - added page Size for pagination functionality.
         }//end of state
        this.rfpDetails=this.rfpDetails.bind(this);
        this.handleChangeSearch=this.handleChangeSearch.bind(this);
        this.selectCountry=this.selectCountry.bind(this);
        this.filterResponsePublisher=this.filterResponsePublisher.bind(this);
        this.incrementItem=this.incrementItem.bind(this);
        this.decrementItem=this.decrementItem.bind(this);
        this.clearFilter=this.clearFilter.bind(this);
        this.incrementLeads=this.incrementLeads.bind(this);
        this.decrementLeads=this.decrementLeads.bind(this);
        this.handleChange_leads=this.handleChange_leads.bind(this);    
        this.handleChange_CPL=this.handleChange_CPL.bind(this);
        this.handleChangeReport = this.handleChangeReport.bind(this);  //sunita-task-3522-added handle chandge for send follow up button
        this.downloadAnalysis=this.downloadAnalysis.bind(this);//shivani-3508-bind handlechange for download file.
    this.handleChangePageSize = this.handleChangePageSize.bind(this);//shivani-3508-bind handlechange for pagination .

    }//end of constructor


   /**
  * @author : Shivani Pathak-task 3508
  * @description : handlechange for pageSize .
  */
    handleChangePageSize(e) {
        var pageSize = e.target.value;
        this.setState({ pageSize: pageSize });
      }
    /**
   * @author : Sunita Landge-task 3511
   * @description : download publisher analysis details
   */

    downloadAnalysis(e) {
       
        e.preventDefault();
		let downloadFiles = this.state.rfpPublisherDetails;
        downloadFiles = downloadFiles.map(item => {
            return {"Proposal ID":item.campID,"Region": item.region, "Country": item.country,"Monthly Lead":item.monthlyLead,"Daily Lead":item.dailyLead,"Total Lead":item.totalLead,"CPL":item.CPL};
          });
          
		//       /* make the worksheet */
		if (downloadFiles.length > 0) {
			var ws = xlsx.utils.json_to_sheet(downloadFiles);
			/* add to workbook */
			var wb = xlsx.utils.book_new();
			xlsx.utils.book_append_sheet(wb, ws, "Lead");

			// /* write workbook */
			var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
			//var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" }); //rutuja 2832 commented never used in the file
			/* generate a download */
			function s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;      //Chaitanya-2898-Removed Console warning
				return buf;
			}

			//sunita-task-2996- as per campaign status it will download the report for active and completed tab
		
				var fileName = "RFP_publisher_analysis.xlsx";
			
			saveAs(
				new Blob([s2ab(buf)], { type: "application/octet-stream" }),
				fileName
			);

		}
	}
    /**
   * @author : Sunita Landge-task 3522
   * @description : handle change for chandge for send follow up button
   */


    handleChangeReport(e)
    { 
        const { user } = this.props.auth;
        let pID = user.id;
        this.setState({pID})
        let rfpBasicDetails=[...this.state.rfpBasicDetails];
        let campID = rfpBasicDetails[0].campID;
        let campaignName= rfpBasicDetails[0].campaignName;
        let marketingChannel =  rfpBasicDetails[0].marketingChannel;
      //  let rfpType=rfpBasicDetails[0].biddingType; rutuja 2832 commeneted never used in the file
        let campaignType=rfpBasicDetails[0].campaignType;
        let biddingDate=rfpBasicDetails[0].biddingEndDate;
        let startDate=rfpBasicDetails[0].startDate;
        let endDate=rfpBasicDetails[0].endDate;
        this.setState({pID})

		let data = { 
            //pID: pID,//Sonali-4064-removing pID from array
            rfpCampaignID:campID,
            rfpCampaignName:campaignName,
            rfpCampaignType:campaignType,
            marketingChannel:marketingChannel,
            biddingDate:biddingDate,
            startDate:startDate,
            endDate:endDate,
        
        
        };


		fetch("/requestForProposal/sendRFPDetailsFollowup",{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data)
		})
			.then((res) => res.json())
			.then((res) => {
              
				 if (res.success === true) {
                
					var successMessage = res.message;
					Swal.fire({
						text: successMessage,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				
				}
				if (res.failed === true) {
					var failMessage = res.message;
					Swal.fire({
						text: failMessage,
						type: "error",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
					});
				}
			}
            )
			.catch(function (err) {
				console.log(err);
			});
        
    }


    rfpDetails(e){
        e.preventDefault();
        //const {isAuthenticated} = this.props.auth; rutuja 2832 commented as never used in the file
         //let Self=this; rutuja 2832 commented as never used in the file
        //  var pID=user.id;
        //Sandeep-task-3729-VAPT-Publisher side--campaign--all sub menues--URL links & API-FE
        var campID=e.target.id;     
        this.setState({selectedCountry:'',campID:campID,loader:'loader_rfp_pub',rfpPublisherDetails:[]});
        //Sandeep-task-3729
        fetch("publisherDashboard/getSubmittedRFPDetails?campID="+campID) 
        
        .then(res => res.json())
        .then(rfpBasicDetails =>{

            //snehal-task-3509-Project's(DI) - Create RFP through XLS - FE

          this.setState({rfpBasicDetails:rfpBasicDetails,isCampaignAllocated:rfpBasicDetails[0].isCampaignAllocated}
            ,  function(){
                if(this.state.rfpBasicDetails[0].biddingType.toString()==="Geowise")        //Chaitanya-2898-Removed Console warning
                {
                    let biddingType='Geo Viz';
                    this.setState({biddingType})
                }
                else{
                this.setState({biddingType:this.state.rfpBasicDetails[0].biddingType})
                }
            }
            )//sunita-task-3522-added isCampaignAllocated variable for checking the follow up button 
        }).catch(function (err) {
        console.log(err)
        });
         //Sandeep-task-3729
        let data = {campID:campID}
              fetch("publisherDashboard/getSubmittedRFPAllocationDetails",{
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
                
              }).then(res => res.json())
              .then(rfpAllocationDetails =>{
            
                let splitCountryArray=[];
               
                for(var i=0; i<rfpAllocationDetails.length;i++){
                
                    let str=rfpAllocationDetails[i].country;
                     var str_array = str.split(',');
                    for (var j = 0; j< str_array.length; j++) {
    
                        splitCountryArray.push({"country":str_array[j]});
                    }    
          }
          const result = Array.from(new Set(splitCountryArray.map(p => p.country))).map(country => {
            return {
                country: country
            };
          });
                      
          this.setState({splitCountryArray:result});
                  this.setState({rfpPublisherDetails:rfpAllocationDetails,rfpPublisherDetailsFilterResponse:rfpAllocationDetails,loader:'',selectedCountry:"",leads:0,clearValue:0});
             
       
              })
//shivani- 3602- set filter values to blank...
            
    }//end of rfpDetails

    handleChangeSearch(e){
        e.preventDefault();
        let currentList=[];
        // Variable to hold the filtered list before putting into state
        let newList=[];
        
        let filterData=e.target.value;
        // If the search bar isn't empty
        if(e.target.value ===""){
            newList=this.state.RFPList;
        }else{
             // Assign the original list to currentList
             currentList=this.state.RFPList;
             newList= currentList.filter(item =>{
                let obj={};//Somnath Task-3972, Add specific Keys in search criteria
				obj[`campID`]=item.campID;
				obj[`campaignName`]=item.campaignName;
                const lc= obj;
                 //const lc = item.toLowerCase();
                // change search term to lowercase
                const filter = e.target.value.toLowerCase();
                
           
        //   check to see if the current list item includes the search termn
        //   If it does, it will be added to newList. Using lowercase eliminates
        //   issues with capitalization in search terms and search content

          var data=Object.values(lc);
          var dataFinal=data.toString();
          dataFinal = dataFinal.replace(",", " ");
          var dataToLower=dataFinal.toLowerCase();
         return dataToLower.includes(filter);


             });
  }
  // Set the filtered state based on what our rules added to newList
    this.setState({
    existingRFPsearch:[...newList], tableSearchText:filterData
    });
    }


    selectCountry(e){
         var selectedCountry=e.target.value;
         this.setState({selectedCountry:selectedCountry});
        setTimeout(function(){
            this.filterResponsePublisher()
        }.bind(this),200)
    }


    filterResponsePublisher()
{
     var selectedCountry=this.state.selectedCountry; 
   // var cpl=this.state.cpl; rutuja 2832 commented as never used in the file
    var leads=this.state.leads;
    //var clearValue=this.state.clearValue; rutuja 2832 commented as never used in the file
    var rfpPublisherDetailsFilterResponse=this.state.rfpPublisherDetailsFilterResponse;
   
    if(selectedCountry.toString() === ""  && Number(leads)===0) //sunita-task-3386-changed the conditions as per requirement as while filtering data it's showing wrong.    //Chaitanya-2898-Removed Console warning
    {
         this.setState({clearValue:0})

    }
    else if(selectedCountry.toString() !== "" && Number(leads)!==0) //sunita-task-3386-changed the conditions as per requirement as while filtering data it's showing wrong.    //Chaitanya-2898-Removed Console warning

    {
        let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
            return a.country.includes(selectedCountry)  && (Number(a.totalLead)===leads || Number(a.monthlyLead)===leads||Number(a.dailyLead)===leads)  //sunita-task-3386-changed the conditions as per requirement as while filtering data it's showing wrong.//Chaitanya-2898-Removed Console warning
           })
           this.setState({rfpPublisherDetails:filterRfpPublisherDetails,clearValue:2})

    }
   
    // else if(selectedCountry!=""&& cpl!=0)
    // {
    //     let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
    //         return a.country.includes(selectedCountry) && a.CPL==cpl 
    //        })
    //        this.setState({rfpPublisherDetails:filterRfpPublisherDetails,clearValue:2})

    // }

    else if(selectedCountry.toString() !== "" && Number(leads)!==0)//sunita-task-3386-changed the conditions as per requirement as while filtering data it's showing wrong. //Chaitanya-2898-Removed Console warning
    {
        let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
            return a.country.includes(selectedCountry) && (Number(a.totalLead)===leads || Number(a.monthlyLead)===leads||a.dailyLead)   //Chaitanya-2898-Removed Console warning
           })
           this.setState({rfpPublisherDetails:filterRfpPublisherDetails,clearValue:2}) //sunita-task-3386-added as per requirement.

    }

    //sunita-task-3386-commented the below condition as it's not needed.

    // else if(cpl!=0 && leads!=0)
    // {
    //     let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
    //         return a.CPL==cpl && (a.totalLead==leads || a.monthlyLead==leads||a.dailyLead==leads)  
    //        })
    //        this.setState({rfpPublisherDetails:filterRfpPublisherDetails,clearValue:2})


    // }
    
    else if(selectedCountry.toString() !== "" )     //Chaitanya-2898-Removed Console warning
    {     
        let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
           
            return a.country.includes(selectedCountry)
           })
           this.setState({rfpPublisherDetails:filterRfpPublisherDetails,clearValue:1})


    }
    
    //sunita-task-3386-commented the below condition as it's not needed.
    // else if(cpl!=0)
    // {
    //     let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
              
    //         return a.CPL== cpl
    //        })
       
    //        this.setState({rfpPublisherDetails:filterRfpPublisherDetails,clearValue:1})

    // }
    else if(Number(leads)!==0)  //Chaitanya-2898-Removed Console warning
    {
        let filterRfpPublisherDetails=rfpPublisherDetailsFilterResponse.filter((a)=>{
            return (Number(a.totalLead)===leads || Number(a.monthlyLead)===leads||Number(a.dailyLead)===leads)  //Chaitanya-2898-Removed Console warning
           })
           this.setState({rfpPublisherDetails:filterRfpPublisherDetails,clearValue:1})

    }

}




    

    decrementItem(e){
        
        var cpl= parseInt(e.target.value);
        cpl=cpl-1
        var leads=this.state.leads;
        if(cpl < 0 || Number(cpl) === 0){   //Chaitanya-2898-Removed Console warning
            this.setState({
                cpl:0
            });
          }else {
            this.setState({
                cpl: cpl 
               
            });
          }

         

          setTimeout(function(){
            this.filterResponsePublisher()
        }.bind(this),200)
        
        if(Number(leads)=== 0 && Number(cpl)=== 0){   //Chaitanya-2898-Removed Console warning
            
           
            this.clearFilter()
 
         }

      }

      incrementItem(e){

          var cpl = parseInt(e.target.value) + 1;
        
          this.setState({
            cpl:cpl
          });
       
          setTimeout(function(){
            this.filterResponsePublisher()
        }.bind(this),200)
      }

      handleChange_leads(e){
        const{value}=e.target //rutuja 2832 - removed name as never used in the file
        this.setState({leads:value});
        setTimeout(function(){
            this.filterResponsePublisher()
        }.bind(this),200)
      }
      handleChange_CPL(e){
        const{value}=e.target //rutuja 2832 - removed name as never used in the file
        this.setState({cpl:value});
        setTimeout(function(){
            this.filterResponsePublisher()
        }.bind(this),200)
      }

      incrementLeads(e){
        var leads = parseInt(e.target.value) + 1;
       
        this.setState({
          leads:leads
        });
     
        setTimeout(function(){
          this.filterResponsePublisher()
      }.bind(this),200)

      }

      decrementLeads(e){
        var leads=parseInt(e.target.value);
        leads= leads-1
        var cpl=this.state.cpl;
        if(leads < 0 || Number(leads) === 0){   //Chaitanya-2898-Removed Console warning
            this.setState({
                leads:0
            });
          }else {
            this.setState({
                leads: leads
                
            });
          }
          setTimeout(function(){
            this.filterResponsePublisher()
        }.bind(this),200)

        if(Number(leads)=== 0 && Number(cpl)=== 0){   //Chaitanya-2898-Removed Console warning
           this.clearFilter()

        }


       } 
    
      
     clearFilter(){
             
           
        // var rfpPublisherDetailsFilterResponse=[];
        //var rfpPublisherDetails=this.state.rfpPublisherDetails; rutuja 2832 commented as never used in the file
        var filterRfpPublisherDetails=[];
        // var rfpAllocationDetails=[];
        
           var selectedCountry="" ;
        
         var cpl=0;
        
         var leads=0;
        
         var clearValue=0;
       
        this.setState({selectedCountry:selectedCountry,cpl:cpl,leads:leads,clearValue:clearValue,rfpPublisherDetails:filterRfpPublisherDetails},function(){

            if(!this.props.auth.isAuthenticated) {
                this.props.history.push('/userLogin');
            }
            else{
                   //for displaying particular campaign id data..
               // const {isAuthenticated, user} = this.props.auth; rutuja 2832 commented as never used in the file
                //  var pID=user.id;
            let campID=this.state.campID;
            let data = {campID:campID}
            
            // var agencyID=user.id;
            this.setState({selectedCountry:'',campID:campID});
            
             
              fetch("publisherDashboard/getSubmittedRFPAllocationDetails",{
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
              }).then(res => res.json())
              .then(rfpAllocationDetails =>{
                let splitCountryArray=[];
                for(var i=0; i<rfpAllocationDetails.length;i++){
                
                    let str=rfpAllocationDetails[i].country;
                   
                    
                    var str_array = str.split(',');
                   
                    
                    for (var j = 0; j< str_array.length; j++) {
    
                        splitCountryArray.push({"country":str_array[j]});
                    }    
          }
          const result = Array.from(new Set(splitCountryArray.map(p => p.country))).map(country => {
            return {
                country: country
            };
          });
                      
          this.setState({splitCountryArray:result});
                  this.setState({rfpPublisherDetails:rfpAllocationDetails,rfpPublisherDetailsFilterResponse:rfpAllocationDetails});
              })
    

              
            }
            
            
        
            
        
     })
    }
    

    


    componentWillMount(){
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
        }
        else{
              
            //const {isAuthenticated, user} = this.props.auth; rutuja 2832 commented as never used in the file
            // var pID=user.id;
            if (this.props.location.state !== undefined) {
				var	pID = this.props.location.state.pID;
                var campID=this.props.location.state.campID;
		  //  this.setState({reportID})
		      this.setState({pID,campID});
		  }
            
        // let data = {pID:pID}
        this.setState({pID:pID});
        // let existingID;
        //sonali-3978-VAPT-get campID from location.state
        // let parsed = queryString.parse(this.props.location.search); //rutuja 2832 commented as never used in the file
       // let campID=this.props.location.state.campID;//rutuja 2832 commented as already declared 
         //Sandeep-task-3729
        fetch("publisherDashboard/getSubmittedRFPAllocation")
      .then(res => res.json())
      .then(RFPList =>{  
       this.setState({RFPList:RFPList,campID:campID})
       
           
              if(RFPList.length>0){
                  //Sonali-3369-added this condition to display rfp details if url has campID
                
               if(campID===undefined||campID==="undefined"||campID==="null"||campID===null||campID===""){
                campID =RFPList[0].campID;
           
               }
           
            
            //for displaying by default id data of RFP details and RFP response from publisher
        
                let data1={campID:campID}  
                 //Sandeep-task-3729
                fetch("publisherDashboard/getSubmittedRFPDetails?campID="+campID) //sunita-task-3522-passed this pid as per requirement        
                .then(res => res.json())
                .then(rfpBasicDetails =>{
                    //sunita-task-3522-added below condition as per requirement for button enable or disabel       
                 let isCampaignAllocated;
                 if(rfpBasicDetails.length>0)
                 {
                    isCampaignAllocated = rfpBasicDetails[0].isCampaignAllocated;
                 }
                    //snehal-task-3509-Project's(DI) - Create RFP through XLS - FE

                  this.setState({rfpBasicDetails:rfpBasicDetails,campID:campID,isCampaignAllocated}
                    ,
                    function(){
                        if(this.state.rfpBasicDetails[0].biddingType.toString() === "Geowise")  //Chaitanya-2898-Removed Console warning
                        {
                            let biddingType='Geo Viz';
                            this.setState({biddingType})
                        }
                        else{
                        this.setState({biddingType:this.state.rfpBasicDetails[0].biddingType})
                        }
                    });
                }).catch(function (err) {
                console.log(err)
                });
             
      
               
              //  var filterRfpPublisherDetails=[]; //rutuja 2832 commented never used in the file
                fetch("publisherDashboard/getSubmittedRFPAllocationDetails",{
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(data1)
                }).then(res => res.json())
                .then(rfpAllocationDetails =>{
                   
                    let splitCountryArray=[];
                    for(var i=0; i<rfpAllocationDetails.length;i++){
                    
                        let str=rfpAllocationDetails[i].country;
                       
                        
                        var str_array = str.split(',');
                        
                        
                        for (var j = 0; j< str_array.length; j++) {

                            splitCountryArray.push({"country":str_array[j]});
                        }    
              }
              const result = Array.from(new Set(splitCountryArray.map(p => p.country))).map(country => {
                return {
                    country: country
                };
              });
                          
              this.setState({splitCountryArray:result});
                    this.setState({rfpPublisherDetails:rfpAllocationDetails,rfpPublisherDetailsFilterResponse:rfpAllocationDetails});
           
                    
                })
      
    

              }
           
          })


    
      .catch(function (err) {
      console.log(err)
      });
       
              
         }
        
        }
 /**
* @author : Shivani Pathak-task 3508
* @description :  changed render code as per design requirement...
*/
    render(){
        // const {isAuthenticated, user} = this.props.auth; //rutuja 2832 commented as never used in the file
       
       
        
        const columns=[
        //     {
        //     title: 'Publisher',
        //     dataIndex: 'publisherName',
        //     key: 'publisherName',
        //     align: 'center',
        //     fontSize:'13px !important',
           
            
           
        // },
        {
            title: <span>Region&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: 'region',
            key: 'region',
             align: "left",
            width:'165px',
                sorter: (a, b) => {
                const asc = "asc";
    
                if (a.region < b.region) {
                    return asc ? -1 : 1;
                } else if (a.region > b.region) {
                    return asc ? 1 : -1;
                } else {
                    return 0;
                }
            },
            render: (text,record) => 
            <div class="countrylist" style={{textAlign:'center',marginLeft:'-3px'}}>
            <span  className="countrylist-country"style={{fontSize:'13px'}}>{record.region}</span>
                 </div>
           
        },
        {
           
            title: <span>Countries&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: 'country',
            key: 'country',
            align: 'center',
            fontSize:'13px !important',
            width:'135px',
            // responsive: ['sm'],
            // marginLeft :'95px',
            sorter: (a, b) => {
                const asc = "asc";
    
                if (a.country < b.country) {
                    return asc ? -1 : 1;
                } else if (a.country > b.country) {
                    return asc ? 1 : -1;
                } else {
                    return 0;
                }
            },
            render: (text,record) => 
            <div class="countrylist" style={{textAlign:'center',marginLeft:'-3px'}}>
       <span  className="countrylist1"style={{fontSize:'13px'}}>{record.country}</span>
       
      
            </div>
           
            // width:'300px',

        },

        {
            title: <span>Daily Lead&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,

            dataIndex: 'dailyLead',
            key: 'dailyLead',
            align: 'center',
            fontSize:'13px !important',

            sorter: (a, b) => a.dailyLead - b.dailyLead,
            width:'135px',
            // responsive: ['sm'],
            // marginLeft :'95px',
    //         render: (text,record) => 
    //         <div class="countrylist" style={{textAlign:'center'}}>
    //    <span  className="countrylist1"style={{fontSize:'13px'}}>{record.dailyLead}</span>
       
      
    //         </div>
           
            // width:'300px',

        },

        {
           
            title: <span>Lead Monthly&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: 'monthlyLead',
            key: 'monthlyLead',
            align: 'center',
            fontSize:'13px !important',
            width:'135px',
              sorter: (a, b) => a.monthlyLead - b.monthlyLead,

        },
        {
            title: <span>Lead Total&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: 'totalLead',
            key: 'totalLead',
            align: 'center',
            fontSize:'13px !important',
            width:'135px',
              sorter: (a, b) => a.totalLead - b.totalLead,
             
        },
        {
      
            title: <span>CPL&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            dataIndex: 'CPL',
            key: 'CPL',
            align: 'center',
            fontSize:'13px !important',
            width:'135px',
            
              sorter: (a, b) => a.CPL - b.CPL,
            
        }

    ]
    
    function onChange(pagination, filters, sorter) {
        //  debugger
        // return(pagination);
        console.log('params', pagination, filters, sorter );
      }
        return(
            //start of main div
            <div>
                <PublisherNavigation/>

                <div class="container-fluid rfpmainhead" style={{paddingTop:'125px', paddingRight:'75px'}}>
                    


                        

                     <div class="row"  style={{marginBottom:'2px'}}>
                                <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" style={{paddingLeft:'43px'}}>
                                
                                <a href="#/"    //Chaitanya-2898-Removed Console warning
                                onClick={() =>
									this.props.history.push("/newPublisherDashboard", {
                                        pID: this.state.pID,
									})
								} //Sanjana-task-3728-replace query params
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back to Dashboard"
								/>
			    </a>
                               </div>  

                                 <div className=" col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 rfpanalysishead "style={{paddingTop:'20px'}}>
                                    {/* <label className="heading1" style={{ fontSize: "17px", color: "#124E9C", marginLeft:"240px",fontWeight: "bold" }}> RFP Details</label> */}
                                    <label className="heading1" id="labelDI" style={{marginLeft:'275px'}}> RFP Details</label>{/*sunita-task-3373-given label ID for DI label consistency */}
                            </div>

                   </div> 


                               <div class="row">
                               <div  class="col-xs-6 col-sm-6 col-md-3 offset-1 col-lg-3 col-xl-3" style={{marginBottom:'0px'}}>
                                       <h5 className="existingRFPHeading" style={{paddingBottom:'12px',marginBottom:'0px',
                                    fontSize: '17px', color:'#14254A', marginTop: '5px', fontWeight: '500', fontFamily: 'roboto' }}>Existing RFP</h5>
                                 </div> 

                                 
                  
                                 </div>
                                        <div class="row">
                                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"> 
                                        <span >
                                            <input type="text"  className="inputstyle1"name="search" placeholder="Search RFP" 
                                                    style={{backgroundImage:'url(searchIcon.png)',
                                                    backgroundPosition:'15px 6px',
                                                    backgroundSize:'15px 15px',
                                                    backgroundRepeat:"no-repeat",
                                                    //  width:'111%',
                                                    width:'100%',
                                                    height:'30px',
                                                    // marginTop:'35px',
                                                    fontSize:'12px',
                                                     float:'left',
                                                     marginLeft:'29px',
                                                     //rutuja 2832 removed border none 
                                                    border: '1px solid #CCCCCC',
                                                    padding: '12px 20px 18px 36px',
                                                    backgroundColor: "#FFFFFF",
                                                    fontFamily: 'sans-serif',
                                                    borderRadius: '2px',
                                                       marginBottom:'5px',
                                                     borderBottom:'1px solid #eee'
                                                }}
                                             
                                                onChange={this.handleChangeSearch}  onPressEnter={this.handleChangeSearch}>
       
                                            
                                            </input>
                                            </span>
                                             </div> 
                                             </div>
                                        
                                                                 
                        <div class="row " style={{border:'1px solid #eee',marginLeft:'28px'}}>
                             
                            
                                    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 maindivrfp" style={{background: '#FFFFFF',borderRight:'2px solid #eee',padding:'0px',overflowY:'scroll',minHeight:'892px'}}>
                                        {/* <div style={{borderBottom:'2px solid #eee'}}>
                                            <h5 style={{color:'#124E9C',paddingTop:'15px',fontSize:'medium',paddingBottom:'15px',marginLeft:'15px',marginBottom:'0px'}}>Existing RFP</h5>
                                        </div> */}
                                        {/* <hr/> */}
                                       {/* <span >
                                            <input type="text"  className="inputstyle1"name="search" placeholder="Search RFP" 
                                                    style={{backgroundImage:'url(searchIcon.png)',
                                                    backgroundPosition:'15px 6px',
                                                    backgroundSize:'15px 15px',
                                                    backgroundRepeat:"no-repeat",
                                                     width:'100%',
                                                    height:'30px',
                                                    // marginTop:'35px',
                                                    fontSize:'small',
                                                     float:'left',
                                                    // marginLeft:'15px',
                                                    border: 'none',
                                                    // padding: '12px 20px 12px 34px',
                                                    padding: '12px 20px 18px 36px',
                                                    // borderRadius:"5px"
                                                       backgroundColor: "#FFFFFF",
                                                       marginBottom:'5px',
                                                     borderBottom:'1px solid #eee'
                                                }}
                                                onChange={this.handleChangeSearch}  onPressEnter={this.handleChangeSearch}>
       
                                            
                                            </input>
                                            </span> */}
                                           {this.state.tableSearchText===""?
                                              
                                            <div  >
                                             
                                                {this.state.RFPList.map(RFPList =>(
                                                 <div  style={{borderTop:'1px solid #eee',padding:'5px 15px'}} key= {RFPList.campID} className="campName">
                                                     
                                                        <a class="campDetail-link" href="#/"    //Chaitanya-2898-Removed Console warning
                                                        id={RFPList.campID} onClick={this.rfpDetails}> 
                                                        {RFPList.campID} - {RFPList.campaignName}
                                                        </a>
                                                    
                                                </div>
                                                 ))}  
                                                  
                                             </div> 
                                             
                                               :
                                               this.state.existingRFPsearch.length>0?
                                              <div  >

                                              {this.state.existingRFPsearch.map(existingRFPsearch=>(
                                               <div  style={{borderTop:'1px solid #eee',padding:'5px 15px'}} >
                                                   
                                                      <a href="#/"  //Chaitanya-2898-Removed Console warning
                                                      id={existingRFPsearch.campID} onClick={this.rfpDetails} className="campName">
                                                      {existingRFPsearch.campID} - {existingRFPsearch.campaignName}
                                                      </a>
                                                  
                                              </div>
                                              
                                              ))} 
                                       
                                             </div>
                                             :<center><label style={{color:'red',fontSize:'14px',marginTop:'34px'}}>No Data Exist</label></center> }  
                                              
                                           
                                            

                                    </div> 
                                   
                
                                                   
                                    <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 maindivrfp" style={{background: '#FFFFFF',minHeight:'892px'}}>
                                    {/* height:'564px' */}
                                         <h5 style={{color:'#193D8F',paddingTop:'15px',fontSize:'16px',paddingLeft:'18px'}}>RFP Details</h5>

                                         {/* <span style={{float: 'left',marginTop:'-35px',marginRight:'17px',height: '32px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px',  color: '#193D8F', border: '1px solid #193D8F',paddingTop:'5px'}}
                                                        type="button"
                                                        className="btn btn-outline-primary btn-sm sendFollowupBtn2"
                                                        // className="btn btn-outline-primary btn-sm"
                                                        onClick={this.downloadAnalysis}
                                                        id="TDRreport">
                                                       download
                                                </span> */}

                                         {/* //sunita-task-3522-added below condition as per requirement for button enable or disabel        */}
                                                {this.state.isCampaignAllocated === "Yes"?
                                                <span style={{float: 'right',marginTop:'-35px',marginRight:'17px',height: '32px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px',  color: '#193D8F', border: '1px solid #193D8F',paddingTop:'5px'}}
                                                        type="button"
                                                        className="btn btn-outline-primary btn-sm sendFollowupBtn2"
                                                        // className="btn btn-outline-primary btn-sm"
                                                        onClick={this.handleChangeReport}
                                                        id="TDRreport">
                                                        Send Follow up
                                                </span>
                                                 : 
                                                 <span style={{float: 'right',marginTop:'-35px',marginRight:'17px',height: '32px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px',  color: '#193D8F', border: '1px solid #193D8F',paddingTop:'5px'}}
                                                        type="button" disabled
                                                        className="btn btn-outline-primary btn-sm sendFollowupBtn2"
                                                        id="TDRreport">
                                                        Send Follow up
                                                </span> }
                                                {/* } */}

                                            {/* </div>  */}
                                         
                                             <div class="row">
                                             {this.state.rfpBasicDetails.map(rfpBasicDetails=>(
                                                <div className="col-12 rfpdetails "style={{display:'inlineBlock',height:'130px', flex:'0 0 93%', width:'-webkit-fill-available',marginLeft:'33px',backgroundColor:'#FFFFFF',border:'1px solid #CCCCCC',borderRadius:'2px'}}>
                                                
                                                    
                                                        {/* <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" > */}
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                                            <label  className=" rfpdetails1" style={{fontSize:'14px',paddingTop:'4px',color:'#414141'}}> RFP ID &nbsp;:&nbsp;{rfpBasicDetails.campID} </label>    
                                                        </div>
                                                       
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                                            {/* {rfpBasicDetails.biddingType == "Geowise" ?
                                                             <label className="rfpdetails1" style={{fontSize:'14px',paddingTop:'4px',color:'#414141'}}> RFP Type &nbsp;:&nbsp;Geo Viz</label>    
                                                        : */}
                                                        {/* //snehal-task-3509-Project's(DI) - Create RFP through XLS - FE */}
                                                        <label className="rfpdetails1" style={{fontSize:'14px',paddingTop:'4px',color:'#414141'}}> RFP Type &nbsp;:&nbsp;{this.state.biddingType}</label>    
                                                        </div>  
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                                            <label  className=" rfpdetails1" style={{fontSize:'14px',paddingTop:'4px',color:'#414141'}}> RFP Name &nbsp;:&nbsp;{rfpBasicDetails.campaignName} </label>    
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                             <label className="rfpdetails1" style={{fontSize:'14px',paddingTop:'4px',color:'#414141'}}> Bidding Date &nbsp;:&nbsp;{rfpBasicDetails.biddingEndDate} </label>  {/*Akash-Task-3990-Ref-(3729 & 3860)- Publisherside- Submitted RFP improper alignment */}
                                                        </div> 
                                      
                                                    
                                                       
                                                     
                                                        {/* <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                                                            <label className="rfpdetails1" style={{fontSize:'13px',paddingBottom:'8px'}}> RFP Name &nbsp;&nbsp;:&nbsp;{rfpBasicDetails.campaignName} </label>    
                                                        </div> */}

                                                        {/* <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 " >
                                                            <label className="rfpdetails1" style={{fontSize:'13px',paddingBottom:'8px'}}> Marketing Channel &nbsp;&nbsp;:&nbsp;{rfpBasicDetails.marketingChannel} </label>    
                                                        </div> */}

                                                        {/* <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  ">
                                                             <span className="rfpdetails2">Campaign Type</span>&nbsp;:&nbsp;<span className="rfpdetails2">{rfpBasicDetails.campaignType}</span> &nbsp;
                                                        </div> */}
                                                        {/* <div class="row"> */}
                                                        <div class="row" >
                                                                          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 "id="align_row_forLg">
                                                                          {/* <div class=" col-sm-12 col-md-6 col-lg-6 "> */}
                                                                          <label className="rfpdetails1" style={{fontSize:'14px',paddingTop:'4px',color:'#414141',marginLeft:'14px'}}> Campaign Type &nbsp;:&nbsp;{rfpBasicDetails.campaignType}</label>  {/*Akash-Task-3990-Ref-(3729 & 3860)- Publisherside- Submitted RFP improper alignment */}         
                                                         
                                                      </div> 

                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        {/* <div  class=" col-sm-12 col-md-6 col-lg-6 "> */}
                                                        <label className="rfpdetails1" style={{fontSize:'14px',paddingTop:'4px',color:'#414141',marginLeft:'0px'}}>Marketing Channel &nbsp;:&nbsp;{rfpBasicDetails.marketingChannel} </label>  {/*Akash-Task-3990-Ref-(3729 & 3860)- Publisherside- Submitted RFP improper alignment */}  
                                                         
                                                        </div> 
                                                        {/* </div> */}
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                                        <label className="rfpdetails1" style={{fontSize:'14px',paddingTop:'4px',color:'#414141',marginLeft:'0px'}}> Start Date &nbsp;:&nbsp;{rfpBasicDetails.startDate}</label>   {/*Akash-Task-3990-Ref-(3729 & 3860)- Publisherside- Submitted RFP improper alignment */}
                                                         
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6  ">
                                                             <label className="rfpdetails1" style={{fontSize:'14px',paddingTop:'4px',color:'#414141',marginLeft:'0px'}}> End Date &nbsp;:&nbsp;{rfpBasicDetails.endDate}</label> {/*Akash-Task-3990-Ref-(3729 & 3860)- Publisherside- Submitted RFP improper alignment */}
                                                           
                                                        </div>
                                             
                                                       

                                                </div>
                                                   ))}

                                            </div>
                                      
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                 <div >
                                                     <h5 className="rfpResponse"style={{color:'#193D8F',paddingTop:'20px',fontSize:'16px',paddingLeft:'3px'}}>RFP Response</h5>
                                                 </div>
                                                 <div class="row" style={{ paddingLeft: '19px' }}>
                          <h4 style={{ color:" #F28C0F", fontSize: '14px' }}>Filters</h4>
                        </div>
                                           
                                                <div class="row" style={{paddingLeft:'4px',paddingBottom:'10px'}}>
                                                    {/* <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 "> */}

                                                    <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 select-container ">
                                                    <div>
                              <label style={{ color: '#193D8F', fontSize: 'small', fontWeight: '500' }}>Countries</label>
                            </div>

                            <select class="form-control inputStyle" style={{ width: '148px', height: '34px' , border:" 1px solid #CCCCCC",  borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555"}}
                            value={this.state.selectedCountry} required onChange={this.selectCountry} >

                                                  

                                                                <option value=""   disabled selected>Select Country</option>                          
                                                                {this.state.splitCountryArray.map(splitCountryArray=>{return(
                                                                  
                                                                <option value={splitCountryArray.country} >{splitCountryArray.country}</option>
                                                                )})}
                                                     </select>
                                                     

                                                       
                                                    </div>

                                                     {/* <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 region ">
                                                         <label style={{color:'#555555d4',fontSize:'small',fontWeight:'500'}}>CPL</label>

                                                            <div class="number-input md-number-input">
                                                            
                                                            <button onClick = {this.decrementItem}  class="minus" value={this.state.cpl}  ></button>
                                                            <input class="quantity" min="0" name="count"  onChange={this.handleChange_CPL}  value={this.state.cpl}style={{backgroundColor: 'white'}} type="number"/> 
                                                            <button onClick = {this.incrementItem}  class="plus" value={this.state.cpl}   ></button>
                                                            
                                                            </div>
                
                                                    </div> */}

                                                     <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 region ">
                                                        <label style={{ color: '#193D8F', fontSize: 'small', fontWeight: '500' }}>Leads</label>

                                                         <div class="number-input md-number-input">
                                                            
                                                         <button onClick = {this.decrementLeads}  class="minus" value={this.state.leads} ></button>
                                                            <input  class="quantity" min="0" name="count"  onChange={this.handleChange_leads}  value={this.state.leads}style={{backgroundColor: 'white',fontSize:'12px'}} type="number"/> 
                                                            <button onClick = {this.incrementLeads}  class="plus" value={this.state.leads} ></button>

                                                           
                                                        </div> 
                                                        
                                                    </div>

                                                    {/* <div className="col-xs-12 col-sm-12 col-md-1 col-lg-2 col-xl-3 clear" style={{paddingTop:'27px',marginLeft:'-80px'}} >
                                                    
                                                       <label style={{color:"#1267b5",fontSize:'small'}}>Clear</label>
                                                        <button class="button" className="clrbtn" style={{padding:'-9px',marginLeft:'5px',borderRadius:'10px',color:"#1267b5"}} onClick={this.clearFilter} value={this.state.clearValue}>{this.state.clearValue}</button>
                                                   
                                                    </div> */}


                                                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-3 clr1 "   >

                                                    
                                                    <label className="clear" id="mid_clr" style={{ color: '#193D8F', fontSize: 'small', fontWeight: '500',paddingTop:'27px',marginLeft:'-80px'}} onClick={this.clearFilter} value={this.state.clearValue}>Clear Filter</label>
                                                     {/* <button class="button" className="clrbtn" style={{padding:'-9px',marginLeft:'5px',borderRadius:'10px',color: '#fafafa',backgroundColor:'#1267b5',borderColor:'#1267b5'}} onClick={this.clearFilter} value={this.state.clearValue}>{this.state.clearValue}</button> */}
                                                     <button class="button" style={{padding:'-9px',marginLeft:'5px',borderRadius:'10px',color:"#1267b5"}} onClick={this.clearFilter} value={this.state.clearValue}>{this.state.clearValue}</button>
                                                
                                                 </div>
                                                
                                                    
                                                 </div>
   
                                           <div class="row">    
      {/* <div class="row" style={{flex:'auto', width:'-webkit-fill-available',marginLeft:'-350px'}}> */}
                          <div class="offset-lg-8 col-lg-4">
                          <span >
                            <a href="#/" //Chaitanya-2898-Removed Console warning
                            >
                            {/* //rutuja 2832 added alt tag */}
          <img src="download1.png" alt="" onClick={this.downloadAnalysis} style={{width:'18px',height:'18px',color:'##1890FF',float:'right',marginRight:'10px',fontSize:'14px'}}>
          </img>
          </a></span>
                            <label class="float-right"style={{color:'#1890FF',fontSize:'14px'}}>Download RFP Analysis</label>
                            
                            {/* </div> */}
                          </div>
                                           </div>
                                                <div>


                                             
                                             {/* {this.state.tableSearchText1===""? */}
                                             {/* <p>
                                                 <Table {...this.state.tableState} id="myTable" dataSource={this.state.rfpPublisherDetails} loading={this.state.loading}
                                                     onChange={onChange} columns={columns} className="ant-table-thead table1 table-responsive" className={tableCSS1} scroll={{ y: 240 }}
                                                     pagination={{ pageSize: this.state.pageSize1,position:'bottom' }}></Table>
                                             </p> : */}
                                         <div id={this.state.loader}></div>
                                              <div>
                                                   {/* {this.state.pageSize1 }   */}
                                                   {/* {this.state.rfpPublisherDetails.length>0 ?  */}
                                              <Table
                                               {...this.state.tableState} id="myTable" dataSource={this.state.rfpPublisherDetails} loading={this.state.loading}
                                                  onChange={onChange}  columns={columns} class="ant-table-thead myDefined" className={tableCSS1} scroll={{ x:500 , y: 240}} 
                                                  pagination={{
                                                    pageSize: this.state.pageSize,
                                                    position: "bottom",
                          
                                                  }}
                                                  ></Table>
                                                 {/* :""}  */}
                                                 {/* pagination={{ pageSize:this.state.pageSize1 }} */}
                                                 <div class="row">
                        <div
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                          style={{ marginTop: "-52px", marginLeft: "5px", fontSize: '13px', color: '#4F4F4F', fontFamily: 'sans-serif' }}>
                          &nbsp;Records per page:&nbsp;
                                <select
                            defaultValue={this.state.pageSize}
                            onChange={this.handleChangePageSize}
                            id="pacing1"
                            class="input-small"
                            className="form-control"
                            name="pacing"
                            style={{ width: "71px", height: "30px", display: "initial", fontSize: '13px', fontFamily: 'sans-serif', color: '#4F4F4F' }}>
                            <option value="50" selected>
                              50
                                  </option>
                            <option value="100">100</option>
                            <option value="150">150</option>
                          </select>
                        </div>
                      </div>
                                          </div> 
                                            

{/*                                              
                                               <p >
                                                  
                                            
                                                 <div class="row">
                                                             <div class="col-xs-12 col-sm-12 col-md-9 col-lg-11 col-xl-11 className='records'" style={{marginTop:'-56px',marginLeft:'25px',padding:'0px'}}>
                                                 Records per page:&nbsp;
                                                 <select  
                                              
                                                          defaultValue={this.state.pageSize1}
                                                          onChange={this.handleChangePageSize1}
                                                        //   value={this.state.pageSize1}
                                                         id="pacing1"
                                                         class="input-small"
                                                         className='recordpage'
                                                         name="pacing" style={{width:'40px',height:'27px',display: 'initial'}}>
                                                         <option  value="5" selected>5</option>
                                                         <option value="10" >10</option>
                                                         <option value="15">15</option>
                                                         <option value="20">20</option>
                                                         </select>
                                                         </div>
                                                        
 
                                                  </div>
                                            </p>  */}


                                                        
                                             <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                             </div>  
                                                
                                            </div>

                                            
                                          
                                    </div>
                                   

                                 {/* </div> */}
                             {/* </div> */}
                             
                        </div>

                        </div>{/*end of container*/}


                <Footer/>

            </div>//end of main div

        );//end of return

    }//end of render

}//end of class

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      RfpAnalysis.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(RfpAnalysis)); 
