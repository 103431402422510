/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Sandeep Dhawale
*@fileName :
 *Desc: contain array of Country Region
*/




module.exports. BeneluxOptions = ["Belgium", "Netherlands", "Luxembourg"];
module.exports. DACHOptions = ["Austria", "Germany", "Switzerland"];
module.exports. NordicOptions = ["Denmark", "Finland", "Iceland", "Norway", "Sweden"];
module.exports. APACOptions = [
	"Australia",
	"Afghanistan",
	"Bhutan",
	"Kazakhstan",
	"China",
	"Kyrgyzstan",
	"Hong Kong",
	"Tajikistan",
	"India",
	"Turkmenistan",
	"Indonesia",
	"Uzbekistan",
	"Japan",
	"Pakistan",
	"Malaysia",
	"Bangladesh",
	"Nepal",
	"Sri Lanka",
	"New Zealand",
	"Singapore",
	"South Korea",
	"Taiwan",
	"Thailand",
	"Tibet",
];
module.exports. NorthernAfricaCountriesOptions = [
	"Algeria",
	"Egypt",
	"Libya",
	"Morocco",
	"Sudan",
	"South Sudan",
	"Tunisia",
	"Western Sahara",
	"Libyan Arab Jamahiriya",//Sandeep-task-3425-addeed new countries
   // "Libya",//Sandeep-task-3425-addeed new countries
];
module.exports. ANZOptions = ["Australia", "New Zealand"];

module.exports. CentralOrMiddleAfricanOptions = [
	"Angola",
	"Cameroon",
	"Central African Republic",
	"Chad",
	"Congo Republic Brazzaville",
	"Democratic Republic of Congo",
	"Equatorial Guinea",
	"Gabon",
	"Sao Tome & Principe",
	"Republic of the Congo",
];

module.exports. SouthernAfricaCountriesOptions = [
	"Botswana",
	"Lesotho",
	"Namibia",
	"South Africa",
	"Swaziland",
];

module.exports. EastAfricanCountriesOptions = [
	"Burundi",
	"Comoros",
	"Djibouti",
	"Ethiopia",
	"Eritrea",
	"Kenya",
	"Madagascar",
	"Malawi",
	"Mauritius",
	"Mozambique",
	"Reunion",
	"Rwanda",
	"Seychelles",
	"Somalia",
	"Somaliland",
	"Tanzania",
	"Uganda",
	"Zambia",
	"Zimbabwe",
	"Mayotte",
];

module.exports. WesternAfricaOptions = [
	"Benin",
	"Burkina Faso",
	"Cape Verde",
	"Cote DIvoire",
	"Gambia",
	"Ghana",
	"Guinea",
	"Guinea-Bissau",
	"Liberia",
	"Mali",
	"Mauritania",
	"Niger",
	"Saint Helena",
	"Senegal",
	"Sierra Leone",
	"Togo",
	"Nigeria",
	"Ivory Coast",
];

module.exports. MiddleEastOptions = ["United Arab Emirates", "Yemen", "Oman"];

module.exports. EasternEuropeOptions = [
	"Belarus",
	"Bulgaria",
	"Czech Republic",
	"Hungary",
	"Poland",
	"Moldova",
	"Romania",
	"Russia",
	"Slovakia",
	"Ukraine",
	"British Indian Ocean Territory",//snehal-task-3778-added new country
];

module.exports. NorthernEuropeOptions = [
	"Aland Islands",
	"Denmark",
	"Estonia",
	"Faroe Islands",
	"Finland",
	"Guernsey",
	"Iceland",
	"Ireland",
	"Isle of Man",
	"Jersey",
	"Latvia",
	"Lithuania",
	"Norway",
	"Sark",
	"Svalbard and Jan Mayen",
	"Sweden",
	"United Kingdom",
	"Montserrat",//Sandeep-task-3309-added new country for NorthernEurope Region
	"Anguilla",//Sandeep-task-3351-added new country
     "Bermuda",//Sandeep-task-3351-added new country
	 "Greenland",//Sandeep-task-3351-added new country
	 "Cayman Islands",//Sandeep-task-3351-added new country
	 "British Virgin Islands",//Sandeep-task-3351-added new country
];

module.exports. SouthernEuropeOptions = [
	"Albania",
	"Andorra",
	"Bosnia and Herzegovina",
	"Croatia",
	"Gibraltar",
	"Greece",
	"Italy",
	"Republic of Macedonia",
	"Malta",
	"Montenegro",
	"Portugal",
	"San Marino",
	"Serbia",
	"Slovenia",
	"Spain",
	"Vatican City",//Sandeep-task-3351-added new country
	"Vatican City State",//Sandeep-task-3351-added new country
	"Kosovo",//snehal-task-3778-added new country
];

module.exports. WesternEuropeOptions = [
	"Austria",
	"Belgium",
	"France",
	"Germany",
	"Liechtenstein",
	"Luxembourg",
	"Monaco",
	"Netherlands",
	"Switzerland",
	"Netherlands Antilles",//Sandeep-task-3309-added new country for WesternEuropeRegion
	"Aruba",//Sandeep-task-3351-added new country
	"Guadeloupe",//Sandeep-task-3351-added new country
	"Martinique",//Sandeep-task-3351-added new country
	"St. Pierre and Miquelon",//Sandeep-task-3351-added new country
	"French Polynesia",//Sandeep-task-3351-added new country
	"French Guiana" 
];
//snehal-task-3676-(added country french Guiana)
module.exports. CentralAsiaOptions = [
	"Kazakhstan",
	"Kyrgyzstan",
	"Tajikistan",
	"Turkmenistan",
	"Uzbekistan",
];

module.exports. EastAsiaOptions = [
	"China",
	"Mongolia",
	"North Korea",
	"South Korea",
	"Japan",
	"Hong Kong",
	"Taiwan",
	"Macau",
];

module.exports. SouthAsiaOptions = [
	"Sri Lanka",
	"Bangladesh",
	"India",
	"Afghanistan",
	"Pakistan",
	"Bhutan",
	"Nepal",
	"The Maldives",
	"Australia",
	"New Zealand",
];

module.exports. SoutheastAsiaOptions = [
	"Brunei",
	"Cambodia",
	"Indonesia",
	"Laos",
	"Malaysia",
	"Myanmar",
	"Philippines",
	"Singapore",
	"Thailand",
	"Tibet",
	"Timor Lester",
	"Vietnam",
	"Christmas Island",
	"Cocos Islands",
];

module.exports. WesternAsiaOptions = [
	"Georgia",
	"Armenia",
	"Azerbaijan",
	"Turkey",
	"Cyprus",
	"Syria",
	"Lebanon",
	"Israel",
	"Palestine",
	"Jordan",
	"Iraq",
	"Iran",
	"Kuwait",
	"Bahrain",
	"Qatar",
	"Saudi Arabia",
	"Palestinian Territory",//Sandeep-task-3351-added new country
];

module.exports. SouthAmericaOptions = [
	"Argentina",
	"Ecuador",
	"Suriname",
	"Bolivia",
	"Brazil",
	"Guyana",
	"Uruguay",
	"Peru",
	"Venezuela",
	"Chile",
	"Colombia",
	"Paraguay",
	"Falkland Islands",//snehal-task-3778-added new country
];
//snehal-task-3676-(added country Paraguay)
module.exports. AntarcticOptions = ["French southern territories"];

module.exports. NorthAmericaOptions = [
	"Antigua and Barbuda",
	"Bahamas",
	"Barbados",
	"Belize",
	"Canada",
	"Costa Rica",
	"Cuba",
	"Dominica",
	"Dominican Republic",
	"El Salvador",
	"Grenada",
	"Guatemala",
	"Haiti",
	"Honduras",
	"Jamaica",
	"Mexico",
	"Nicaragua",
	"Panama",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Vincent and the Grenadines",
	"Trinidad and Tobago",
	"United States",//Somnath Task-4016, Update Country from United States of America to United States
	"Puerto Rico",//Sandeep-task-3351-added new country
	"United States Minor Outlying Islands",//Sandeep-task-3351-added new country
	"United States Virgin Islands",//Sandeep-task-3351-added new country
	"Bonaire and Saint Eustatius and Saba",//snehal-task-3778-added new country
	"Sint Maarten",//snehal-task-3778-added new country
	"Turks and Caicos Islands"//snehal-task-3778-added new country
];

module.exports. MelanesiaOptions = [
	"Papua New Guinea",
	"Fiji",
	"Solomon Islands",
	"Vanuatu",
	"New Caledonia"//snehal-task-3778-added new country
];

module.exports. PolynesiaOptions = ["Samoa",
	"Tuvalu", 
	"Tonga",
	"American Samoa",//snehal-task-3778-added new country
	"Cook Islands",//snehal-task-3778-added new country
	"Niue",//snehal-task-3778-added new country
	"Wallis and Futuna",//snehal-task-3778-added new country
	"Tokelau"//snehal-task-3778-added new country
];

module.exports. MicronesiaOptions = [
	"Kiribati",
	"Micronesia",
	"Marshall Islands",
	"Palau",
	"Narur",
	"Guam",//snehal-task-3778-added new country
	"Northern Mariana Islands"//snehal-task-3778-added new country
];

module.exports. SouthernAtlanticOceanOptions = [
	"Bouvet Island",
	"South Georgia and the South Sandwich Islands",
];

module.exports. RegionOption = [
	"benelux",//Sandeep-task-3419-region issue  
	"dach",//Sandeep-task-3419-region issue
	"nordic",//Sandeep-task-3419-region issue
	"Northern Africa Countries",
	"Central or Middle Africa",
	"Southern Africa Countries",
	"East African Countries",
	"Western Africa",
	"Middle East",
	"Eastern Europe",
	"Northern Europe",
	"Southern Europe",
	"Western Europe",
	"Central Asia",
	"East Asia",
	"South Asia",
	"Southeast Asia",
	"Western Asia",
	"South America",
	"North America",
	"Antarctic",
	"Australia and New Zealand",
	"Melanesia",
	"Polynesia",
	"Micronesia",
	"Southern Atlantic Ocean",
];


