/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Nikita
@Description:Email Config
date: 18-12-2019 
*/

import React from "react";//Aman-4749-(removed '{ Component }' not in use)-Console Warnings - Advertiser - Settings
import {Checkbox } from "antd"; //Aman-4749-(removed 'Form,Input,button,Card,Icon,Tooltip,Table,Row,Col' not in use)-Console Warnings - Advertiser - Settings
import { connect } from "react-redux";
import Navigation from "../layouts/navPage";
import PropTypes from "prop-types";
import Footer from "../layouts/footer";
//import { css } from "emotion"; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
// import "./agencyEmailConfig.css";		//Priyanka--3874
import "./advertiserEmailConfig.css";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
const Swal = require("sweetalert2");

// const campActivity = [
//   { id: 'Campaign Allocation', name: 'campaignAllocation', value: 'No' },
// ];

const leadActivity = [
  // { id: 'Lead Upload', name: 'leadUpload', value: 'No' },
  { id: "Lead Review", name: "leadReview", value: "No" },
];

const advActivity = [
  { id: "Agency Link Advertiser", name: "linkAgencyAdvertiser", value: "No" },
  { id: " Salesforce Client Setup", name: "clientSetup", value: "No" },
  { id: " Marketo Client Setup", name: "marketoClientSetup", value: "No" },
  { id: " Hubspot Client Setup", name: "hubspotClientSetup", value: "No" },
  { id: " RFP Reminder", name: "rfpSetupReminder", value: "No" }, //karan-task-3515-added rfp reminder
];

// const pubActivity = [
//   { id: 'Landing Page Submit', name: 'landingPageSubmit', value: 'No' },
//   { id: 'CS Submit', name: 'csSubmit', value: 'No' },
//   { id: 'POC Submit', name: 'pocSubmit', value: 'No' },
//   { id: 'Creative Review', name: 'creativeReview', value: 'No' },
// ];

//const campPlain = campActivity.map(function (a) { return a.name });
//const pubPlain = pubActivity.map(function (a) { return a.name });
const advPlain = advActivity.map(function(a) {
  return a.name;
});
const leadPlain = leadActivity.map(function(a) {
  return a.name;
});

class advertiserEmailConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campIndeterminate: true,
      pubIndeterminate: true,
      leadIndeterminate: true,
      //deadlineIndeterminate: true,
      //othersIndeterminate: true,

      campCheckAll: false,
      pubCheckAll: false,
      leadCheckAll: false,
      advCheckAll: false,
      //deadlineCheckAll: false,
      //othersCheckAll: false,
      emailConfigData: [],
    };
  }

  // campCheckedList
  //   campOnChange = campCheckedList => {
  //     this.setState({
  //       campCheckedList,
  //       campIndeterminate: !!campCheckedList.length && campCheckedList.length < campActivity.length,
  //       campCheckAll: campCheckedList.length === campActivity.length,
  // });
  //   };

  // campOnCheckAllChange = e => {
  //   this.setState({
  //     campCheckedList: e.target.checked ? campPlain : [],
  //     campIndeterminate: false,
  //     campCheckAll: e.target.checked,
  //   });
  // };

  // pubCheckedList
  // pubOnChange = pubCheckedList => {
  //   this.setState({
  //     pubCheckedList,
  //     pubIndeterminate: !!pubCheckedList.length && pubCheckedList.length < pubActivity.length,
  //     pubCheckAll: pubCheckedList.length === pubActivity.length,
  //   });
  // };
  // pubOnCheckAllChange = e => {
  //   this.setState({
  //     pubCheckedList: e.target.checked ? pubPlain : [],
  //     pubIndeterminate: false,
  //     pubCheckAll: e.target.checked,
  //   });
  // };

  advOnChange = (advCheckedList) => {
    this.setState({
      advCheckedList,
      advIndeterminate:
        !!advCheckedList.length && advCheckedList.length < advActivity.length,
      advCheckAll: advCheckedList.length === advActivity.length,
    });
  };

  advOnCheckAllChange = (e) => {
    this.setState({
      advCheckedList: e.target.checked ? advPlain : [],
      advIndeterminate: false,
      advCheckAll: e.target.checked,
    });
  };

  // leadCheckedList
  leadOnChange = (leadCheckedList) => {
    this.setState({
      leadCheckedList,
      leadIndeterminate:
        !!leadCheckedList.length &&
        leadCheckedList.length < leadActivity.length,
      leadCheckAll: leadCheckedList.length === leadActivity.length,
    });
  };

  leadOnCheckAllChange = (e) => {
    this.setState({
      leadCheckedList: e.target.checked ? leadPlain : [],
      leadIndeterminate: false,
      leadCheckAll: e.target.checked,
    });
  };

  // deadlineCheckedList
  // deadlineOnChange = deadlineCheckedList => {
  //   this.setState({
  //     deadlineCheckedList,
  //     deadlineIndeterminate: !!deadlineCheckedList.length && deadlineCheckedList.length < deadline.length,
  //     deadlineCheckAll: deadlineCheckedList.length === deadline.length,
  //   });
  // };

  // deadlineOnCheckAllChange = e => {
  //   this.setState({
  //     deadlineCheckedList: e.target.checked ? defaultDeadlineChecked : [],
  //     deadlineIndeterminate: false,
  //     deadlineCheckAll: e.target.checked,
  //   });
  // };

  // othersCheckedList
  // othersOnChange = othersCheckedList => {
  //   this.setState({
  //     othersCheckedList,
  //     othersIndeterminate: !!othersCheckedList.length && othersCheckedList.length < others.length,
  //     othersCheckAll: othersCheckedList.length === others.length,
  //   });
  // };

  // othersOnCheckAllChange = e => {
  //   this.setState({
  //     othersCheckedList: e.target.checked ? others : [],
  //     othersIndeterminate: false,
  //     othersCheckAll: e.target.checked,
  //   });
  // };

  /**
   * @author Priyanka Patil
   * @param  Description Adeed function to Refresh the page under task 3874
   */
  handleRefreshPage(e) {
    Swal.fire({
      title: "Are you sure you want to discard the changes?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      allowOutsideClick: false,
      confirmButtonText: "Discard",
    }).then((result) => {
      if (result.value) {
        window.location.href = "/advertiserDashboard";          //Chaitanya-4291-diverted path of Discard button to Advertiser dashboard
      } else if (result.dismiss === "close") {
        window.location.href = "#";
      } else {
        window.location.href = "#";
      }
    });
  }

  handleSubmit = (e) => {
    //camp
    e.preventDefault();

    var finalCampCheckedList = [];
    var data = {};
    // var campCheckedList = this.state.campCheckedList;
    // var result2 = campActivity.filter(function (b) {
    //   var name = (b.name).toString();
    //   return !campCheckedList.includes(name)
    // })

    // for (var i = 0; i < this.state.campCheckedList.length; i++) {
    //   var temp = this.state.campCheckedList[i];
    //   data[temp] = 'Yes';
    // }

    // for (var i = 0; i < result2.length; i++) {
    //   var temp = result2[i].name;
    //   data[temp] = 'No';
    // }

    //pub
    // var pubCheckedList = this.state.pubCheckedList;
    // var result2 = pubActivity.filter(function (b) {
    //   var name = (b.name).toString();
    //   return !pubCheckedList.includes(name)
    // })

    // for (var i = 0; i < this.state.pubCheckedList.length; i++) {
    //   var temp = this.state.pubCheckedList[i];
    //   data[temp] = 'Yes';
    // }

    // for (var i = 0; i < result2.length; i++) {
    //   var temp = result2[i].name;
    //   data[temp] = 'No';
    // }

    var advCheckedList = this.state.advCheckedList;
    var result11 = advActivity.filter(function(b) {
      var name = b.name.toString();
      return !advCheckedList.includes(name);
    });

    for (var i = 0; i < this.state.advCheckedList.length; i++) {
      var temp = this.state.advCheckedList[i];
      data[temp] = "Yes";
    }

    for (let i = 0; i < result11.length; i++) { //Aman-4749-(change var to let to resolve duplicate var issue)-Console Warnings - Advertiser - Settings
      let temp = result11[i].name; //Aman-4749-(change var to let to resolve duplicate var issue)-Console Warnings - Advertiser - Settings
      data[temp] = "No";
    }

    var leadCheckedList = this.state.leadCheckedList;
    var result2 = leadActivity.filter(function(b) {
      var name = b.name.toString();
      return !leadCheckedList.includes(name);
    });

    for (let i = 0; i < this.state.leadCheckedList.length; i++) { //Aman-4749-(change var to let to resolve duplicate var issue)-Console Warnings - Advertiser - Settings
      let temp = this.state.leadCheckedList[i]; //Aman-4749-(change var to let to resolve duplicate var issue)-Console Warnings - Advertiser - Settings
      data[temp] = "Yes";
    }

    for (let i = 0; i < result2.length; i++) { //Aman-4749-(change var to let to resolve duplicate var issue)-Console Warnings - Advertiser - Settings
      let temp = result2[i].name; //Aman-4749-(change var to let to resolve duplicate var issue)-Console Warnings - Advertiser - Settings
      data[temp] = "No";
    }
    finalCampCheckedList.push(data);
    //const { user } = this.props.auth; //Aman-4749-(change var to let to resolve duplicate var issue)-Console Warnings - Advertiser - Settings

    let configData = {
      activity: finalCampCheckedList,
    }; // karan-task-3723-vapt header and query params
    fetch("/userConfiguration/emailConfigurationInfo", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(configData),
    })
      .then((res) => res.json())
      .then((response) => {
        Swal.fire({
          text: "Email Configuration Done Successfully",
          type: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          preConfirm: () => {
            window.location.reload();
          },
        });
      });
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
    //Aman-4749-(commented parsed and user , advertiserID  not in use)-Console Warnings - Advertiser - Settings
     // var parsed = this.props.location.state; //kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE
     // const { user } = this.props.auth;
     // var advertiserID = user.id; //kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE

      fetch("/userConfiguration/getEmailConfigurationInfo", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }) // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((emailConfigData) => {
          this.setState({ emailConfigData: emailConfigData });

          var tempArray = [];
          var temp = this.state.emailConfigData.filter(function(obj) { //Aman-4749-(not removing this warning because getting issue in DI)
            let keys = Object.keys(obj);
            let values = keys.map(function(key) { //Aman-4749-(not removing this warning because getting issue in DI)
              var a = obj[key];
              if (a === "Yes") { //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
                tempArray.push(key);
              }
              return [] //Aman-4749-(added return [] to avoid Expected to return a value in function error)-Console Warnings - Advertiser - Settings
            });
           return []  //Aman-4749-(added return [] to avoid Expected to return a value in function error)-Console Warnings - Advertiser - Settings
          });

          // var resultCamp = campActivity.filter(function (b) {
          //   var name = (b.name).toString();
          //   return tempArray.includes(name)
          // })

          // var resultPub = pubActivity.filter(function (b) {
          //   var name = (b.name).toString();
          //   return tempArray.includes(name)
          // })
          var resultadv = advActivity.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultLead = leadActivity.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          // const campData = resultCamp.map(function (a) { return a.name });
          // const pubData = resultPub.map(function (a) { return a.name });
          const leadData = resultLead.map(function(a) {
            return a.name;
          });
          const advData = resultadv.map(function(a) {
            return a.name;
          });

          this.setState({
            // campCheckedList: campData,
            // pubCheckedList: pubData,
            advCheckedList: advData,
            leadCheckedList: leadData,
          });
          if (
            this.state.emailConfigData &&
            this.state.emailConfigData.length > 0
          ) {
            for (var i = 0; i < this.state.emailConfigData.length; i++) {
              // if(this.state.emailConfigData[i].campaignAllocation==="Yes"){
              //   this.setState({campCheckAll:true})
              // }
              // if(this.state.emailConfigData[i].landingPageSubmit==="Yes"&&
              //   this.state.emailConfigData[i].csSubmit==="Yes"&&
              //   this.state.emailConfigData[i].pocSubmit==="Yes"&&
              //   this.state.emailConfigData[i].creativeReview==="Yes"){this.setState({pubCheckAll:true})}

              if (this.state.emailConfigData[i].leadReview === "Yes") {
                this.setState({ leadCheckAll: true });
              }

              if (
                this.state.emailConfigData[i].linkAgencyAdvertiser === "Yes" &&
                this.state.emailConfigData[i].clientSetup === "Yes" &&
                this.state.emailConfigData[i].marketoClientSetup === "Yes" &&
                this.state.emailConfigData[i].hubspotClientSetup === "Yes" &&
                this.state.emailConfigData[i].rfpSetupReminder === "Yes" //karan-task-3515-added rfp reminder
              ) {
                this.setState({ advCheckAll: true });
              }
            }
          }
        });
    }
  }

  render() {
    return (
      <div /*style={{ backgroundColor: "rgb(241, 241, 241)" }}*/>   {/*Chaitanya- -R29-Prod issue-Advertiser-Setting option-Email Notifications-page horizontal line issue*/}
        <Navigation />
        <div
          className="container-fluid"
          style={{ paddingTop: "85px", paddingBottom: "60px" }}
        >
          <p style={{ paddingTop: "20px" }}>
            {/* //shivani-3285-passed ID for DI label consistency */}
            <h3 align="center" id="labelDIADV">
              Email Notification
            </h3>
            <hr style={{ border: "1px solid #e0e0e0" }} />
          </p>

          {/*Priyanka--3874--Changed design of the Page as per new Design */}
          <div class="col-xs-10 col-sm-10 col-md-10 col-lg-12  container-ADVMC">
            {/* Mufiz-Bug-4122 */}
            <form
              className="emailformADV"
              id="emilform"
              onSubmit={this.handleSubmit}
            >
              <div className="row">
                {/* <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                <p>
                  <h4 style={{ fontFamily: 'roboto' }}>Campaign Related Activity Notification</h4>
                </p>
                <div style={{ paddingBottom: '8px' }}>
                  <Checkbox
                    // indeterminate={this.state.campIndeterminate}
                    onChange={this.campOnCheckAllChange}
                    checked={this.state.campCheckAll}
                  >
                    Select All
                </Checkbox>
                </div>

                <div style={{ background: '#fff', padding: '10px 15px 10px 15px', border: '1px solid #E9E9E9' }}>
                  <Checkbox.Group
                    options={campActivity.map(campActivity => ({ label: campActivity.id, value: campActivity.name }))}
                    value={this.state.campCheckedList}
                    onChange={this.campOnChange}
                  />
                </div>
              </div> */}
                {/* 
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                <p>
                  <h4 style={{ fontFamily: 'roboto' }}>Publisher Related Activity Notification</h4>
                </p>
                <div style={{ paddingBottom: '8px' }}>
                  <Checkbox
                    // indeterminate={this.state.pubIndeterminate}
                    onChange={this.pubOnCheckAllChange}
                    checked={this.state.pubCheckAll}
                  >
                    Select All          </Checkbox>
                </div>

                <div style={{ background: '#fff', padding: '10px 15px 10px 15px', lineHeight: '20px', border: '1px solid #E9E9E9' }}>
                  <Checkbox.Group
                    options={pubActivity.map(pubActivity => ({ label: pubActivity.id, value: pubActivity.name }))}
                    value={this.state.pubCheckedList}
                    onChange={this.pubOnChange}
                  />
                </div>
              </div> */}
              </div>
              {/* end of 1st Row */}

              <br />
              <br />

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                  <p>
                    <h4 class="hFourAD" style={{ fontFamily: "roboto" }}>
                      {/* //mufiz-bug-4111-Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) */}
                      Lead Related Activity Notification
                      <div className="selectAllADV">
                        <Checkbox
                          // indeterminate={this.state.leadIndeterminate}
                          onChange={this.leadOnCheckAllChange}
                          checked={this.state.leadCheckAll}
                        >
                          Select All
                        </Checkbox>
                      </div>
                    </h4>
                  </p>

                  <div
                    className="background"
                    id="lead_adv_back"
                    style={{
                      height: "50px",
                    }}
                  >
                    <Checkbox.Group
                      className="lead_adv"
                      id="single"
                      options={leadActivity.map((leadActivity) => ({
                        label: leadActivity.id,
                        value: leadActivity.name,
                      }))}
                      value={this.state.leadCheckedList}
                      onChange={this.leadOnChange}
                    />
                  </div>
                </div>
              </div>

              <br />
              <br />

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                  <p>
                    <h4
                      class="hFourAD"
                      style={{ fontFamily: "roboto", marginBottom: "28px" }}
                    >
                      Advertiser Related Activity Notification
                      <div className="selectAllADV">
                        <Checkbox
                          // indeterminate={this.state.leadIndeterminate}
                          onChange={this.advOnCheckAllChange}
                          checked={this.state.advCheckAll}
                        >
                          Select All
                        </Checkbox>
                      </div>
                    </h4>
                  </p>

                  <div className="background" id="adv_adv_back1">
                    <Checkbox.Group
                      className="adv"
                      id="non_single"
                      options={advActivity.map((advActivity) => ({
                        label: advActivity.id,
                        value: advActivity.name,
                      }))}
                      value={this.state.advCheckedList}
                      onChange={this.advOnChange}
                    />
                  </div>
                </div>
              </div>

              {/* end od 2nd row */}
              <br />
              <br />
              <div class="row">
                <div className="col-xs-12 col-sm-10 col-md-12 col-lg-12 col-xl-10">
                  <button
                    type="primary"
                    id="submitBtnAdv"
                    htmltype="submit"
                    class="btn add-button"
                    style={{ float: "right" }}
                  >
                    Submit
                  </button>

                  <button
                    type="button"
                    data-dismiss="modal"
                    class="can-btn-email-adv"
                    style={{
                      float: "right",
                      marginRight: "8px",
                      border: "solid #4893d4",
                    }}
                    onClick={this.handleRefreshPage}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* end of container */}
        <Footer />
      </div>
    );
  }
}

// export default AdevertiserEmailConfig;
advertiserEmailConfig.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(advertiserEmailConfig));
