/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Snehal More
@Creation Date:08-04-2021
@Description:UI for Publisher MyReport under publisher view(under story 3357)
*/
import React from "react";
import PublisherNavigation from "../layouts/publisherNavPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Tooltip } from "antd";//kiran-2836-Removing console warnings-no use
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { css } from 'emotion';
import './publisherMyReport.css'; /* //snehal-task-3460-publisher side--my report--responsiveness issue */ 
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Sandeep-task-3730-added default header
import { saveAs } from "file-saver"; //rutuja -task 4497 added for downloading report
const xlsx = require("xlsx"); //rutuja -task 4497 added for downloading report
var dateTime = require("node-datetime"); //rutuja -task 4497 added for taking current date
const Swal = require("sweetalert2");
// const queryString = require("query-string");//kiran-2836-Removing console warnings-no use
const tableCSS = css({
  backgroundColor: 'white',
  fontFamily: "sans-serif", //snehal-task-3444-publisher side--report--create/edit report name--validation issue
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize: '12px !important', //snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue
    fontWeight: '500',
  },
  '& thead > tr >th': {
    textAlign: "center !important", //snehal-task-3460-publisher side--my report--responsiveness issue */}
    color: 'white',
  }
});


class PublisherMyReport extends React.Component {
  constructor() {
    super();
    this.state = {
      agency: '',//snehal-for agency 
      reportName: '',//snehal-for report name
      reportNameDisplay: 'none',//snehal-for error msg
      reportTypeDisplay: 'none',//snehal-for error msg
      tableSearchText: "",//snehal-for search 
      reportType: 'Agency Campaign Delivery Tracking',//snehal-task-3568-pub side---my reaport---publisher lead tracking issue 
      reportTypeShow:'Agency Campaign Delivery Tracking',//snehal-task-3568-pub side---my reaport---publisher lead tracking issue
      myReportDetails: [],//snehal-for all report data array
      reportDetailsSearch: [],//snehal-for search data array
      pageSize2: 100,//snehal-for records per page
      loader: "",//snehal-for loader
      token: '',//snehal-for fot authentication
      tableState: { size: 'small' }//snehal-for table size
    }
    this.commonhandleChange = this.commonhandleChange.bind(this);
    this.validateReportData = this.validateReportData.bind(this);
    this.createReportButton = this.createReportButton.bind(this);
    this.editReportButton = this.editReportButton.bind(this);
    this.myReportBackButton = this.myReportBackButton.bind(this);
    this.handleEditReport = this.handleEditReport.bind(this);
    this.handleChangeReportType = this.handleChangeReportType.bind(this);
    this.onClickReportName = this.onClickReportName.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.newhandleChange=this.newhandleChange.bind(this);//snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue(added fun)
    this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);   //Chaitanya-4187-My report-create new report
    this.deleteReport = this.deleteReport.bind(this); //rutuja task-4496 added handlechange to delete report
  }// end of constrcutor

      /**
       * @author Snehal More
       * @param  Description ComponentWillMount
       * @return Description render data when we go on page
       */
  //start of componentWillMount
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
      // const { isAuthenticated } = this.props.auth; //kiran-2836-Removing console warnings-no use
        // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE //kiran-2836-Removing console warnings
      // var pID = user.id;
      var reportType = this.state.reportType;
       // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE //kiran-2836-Removing console warnings-no use
      // const token = user.token;
      this.setState({ loader: "loader_report" })
      // user: user,
      // authorization: token,
      let data = {
       
        type: reportType
      };
      fetch("/publisherReports/getAllAgencyTrackingReport ", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
         
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(myReportDetails => {
          this.setState({ myReportDetails: myReportDetails, loader: "" }, function () {

          })
        }).catch(function (err) { console.log(err) });
    }
  }
        /**
       * @author Snehal More
       * @param  Description Validate form
       * @return Description to validate report name 
       */
  validateReportData() {
    let formIsvalid = true;
    var reportName1 = this.state.reportName.replace(/ /g, "")
    if (reportName1.toString() === "") { //kiran-2836-Removing console warnings-no use
      this.setState({ reportNameDisplay: 'block', })
      formIsvalid = false;
      return formIsvalid;
    }
    //snehal-task-3444-publisher side--report--create/edit report name--validation issue(changed regex)
    var pattern = RegExp(/^(?=.*?[A-Za-z]).+/)
    // eslint-disable-next-line no-useless-escape 
    var pattern2 = new RegExp(/^[_()\/+&@\-\. ]$/);
    if (!pattern.test(reportName1) || pattern2.test(reportName1)) {
      this.setState({ reportNameDisplay: 'block' })
      formIsvalid = false;
      return formIsvalid;
    }
    if (this.state.reportType.toString() === "") { //kiran-2836-Removing console warnings
      this.setState({ reportTypeDisplay: 'block' })
      formIsvalid = false;
      return formIsvalid;
    }
    return formIsvalid;
  }
        /**
       * @author Snehal More
       * @param  Description handle change myReportBackButton
       * @return Description go back to the newPublisherDashboard
       */
  // myReportBackButton() { //kiran-2836-Removing console warnings-duplicate function found
  //   const { user } = this.props.auth
  //   var pID = user.id;
  //   window.location.href = "newPublisherDashboard?pID=" + pID;
  // }//handel change for back button

        /**
       * @author Snehal More
       * @param  Description handle change createReportButtton
       * @return Description create a new report and redirect page to that specified path
       */
       //Chaitanya-4099-Back button required on My reports screen - Agency //
  myReportBackButton(e) 
  {
  e.preventDefault();
  this.props.history.push("/newPublisherDashboard")
  }//handel change for back button

  createReportButton(e) {
    e.preventDefault();
    // const { user } = this.props.auth
    // var pID = user.id;
    var reportName = this.state.reportName;
    var reportType = this.state.reportType;
    if (this.validateReportData() === false) { }
    else {
      //snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE
      if(reportType.toString()==="Agency Campaign Delivery Tracking") //kiran-2836-Removing console warnings
      {
        e.preventDefault();
          // window.location.href = "agencyDeliveryTrackingReport?reportName=" + reportName + "&reportType=" + reportType;
          this.props.history.push("/agencyDeliveryTrackingReport",{reportName,reportType});
      }
      else if(reportType.toString()==="Agency Lead Tracking") //kiran-2836-Removing console warnings
      {
          // window.location.href = "agencyLeadTrackingReport?reportName=" + reportName + "&reportType=" + reportType;
          this.props.history.push("/agencyLeadTrackingReport",{reportName,reportType});
      }
      else{}
  }
  }//handel change for report create button
          /**
       * @author Snehal More
       * @param  Description handle change editReportButtton
       * @return Description edit report Name
       */
  editReportButton() {
     // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE //kiran-2836-Removing console warnings
    // const { user } = this.props.auth
    // const token = user.token;
    if (this.validateReportData() === false) { }
    else {
      // user: user,
      // authorization: token,
      let data = {
       
        reportID: this.state.reportID,
        reportName: this.state.reportName,
      };
      fetch("/publisherReports/editAgencyTrackingReport", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
         
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          Swal.fire({
            text: "Report name edited successfully",
            //snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue(change in sweet alert ui)
            icon: "Success",
            type: "success",
            confirmButtonColor: "#144999",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            } else if (result.dismiss === "close") {
              window.location.reload();
            } else {
              window.location.reload();
            }
          });
        });
    }
  }//handel edit report
          /**
       * @author Snehal More
       * @param  Description handle change commonhandleChange
       * @return Description handle chage of report name and report type 
       */
  commonhandleChange(e) {
    const { name, value } = e.target
    if (name.toString()=== "reportName") {//kiran-2836-Removing console warnings
      this.setState({ reportName: value, reportNameDisplay: 'none' });
    }
    if (name.toString()=== "reportType") {//kiran-2836-Removing console warnings
      this.setState({ reportType: value, reportNameDisplay: 'none' });
    }
  }
          /**
       * @author Snehal More
       * @param  Description handle change newHandleChange
       * @return Description to blank the report name fileld whenever we click on +create new report
       */
  //snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue(added function)
  newhandleChange(e){
    e.preventDefault()
    this.setState({reportName:'',reportType:"Agency Campaign Delivery Tracking"})
  }
         /**
       * @author Snehal More
       * @param  Description handle change handleEditReport
       * @return Description when i click on edit symbol then all data will setState 
       */
      //snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE
  handleEditReport(e) {
    // const { isAuthenticated } = this.props.auth;//kiran-2836-Removing console warnings-no use
      /* Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE */
    // var pID = user.id;
    var reportID = e.target.getAttribute("reportID");
    var reportName = e.target.getAttribute("reportName");
    var reportType = e.target.getAttribute("reportType");
    this.setState({ reportID: reportID, reportName: reportName ,reportType:reportType})
  }//Function handle change for edit report
           /**
       * @author Snehal More
       * @param  Description handle change handleChangeReportType
       * @return Description to set dropdown value to sort reports according to type 
       */
  handleChangeReportType(e) {
    const { name, value } = e.target
    // const { isAuthenticated, } = this.props.auth; //kiran-2836-Removing console warnings -no use
      /* Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE */
    // var pID = user.id;
    if (name.toString() === "reportType") { //kiran-2836-Removing console warnings
      this.setState({ reportType: value ,reportTypeShow:value,filterData:'',tableSearchText:''}); /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */ 
      //snehal-task-3574-Publisher side--my report-- filter issue(set stated tableData and tableSearchText)
      // const { isAuthenticated } = this.props.auth; //kiran-2836-Removing console warnings
      // var pID = user.id;
      var reportType = value;
      // const token = user.token;
      this.setState({ loader: "loader_report" })
      // user: user,
      //authorization: token,
      let data = {
       
        type: reportType
      };
      fetch("/publisherReports/getAllAgencyTrackingReport ", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(myReportDetails => {
          this.setState({ myReportDetails: myReportDetails, loader: "" }, function () {

          })
        }).catch(function (err) { console.log(err) });
    }
    
  }//handle change for ReportType
           /**
       * @author Snehal More
       * @param  Description handle change onClickReportName
       * @return Description when i click on report name it redirects to next page to display that report in details 
       */
      //snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE
  onClickReportName(e) {
    e.preventDefault();
    // const { isAuthenticated } = this.props.auth;//kiran-2836-Removing console warnings-no use
     // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE //kiran-2836-Removing console warnings
    // var pID = user.id;
    // const token = user.token;
    var reportID = e.target.getAttribute("reportID");
    var reportType = e.target.getAttribute("reportType");
    var reportName = e.target.getAttribute("reportName");
   
    if(reportType ==="Agency Campaign Delivery Tracking"){
    // window.location.href =
    //   "/agencyDeliveryTrackingReport?reportID=" + reportID;
      // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE //kiran-2836-Removing console warnings
      this.props.history.push("/agencyDeliveryTrackingReport",{reportID,reportName});
    }
    else if(reportType==="Agency Lead Tracking"){
      // window.location.href =
      // "/agencyLeadTrackingReport?reportID=" + reportID;
        // Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE //kiran-2836-Removing console warnings
      this.props.history.push("/agencyLeadTrackingReport",{reportID,reportName});
    }
    
  } //end of displayCampaignSpecification
           /**
       * @author Snehal More
       * @param  Description handle change handleChangeSearch
       * @return Description function is used to search the perticular record from multiple records
       */
  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    this.setState({filterData})//snehal-task-3574-Publisher side--my report-- filter issue
    // If the search bar isn't empty
    if (e.target.value.toString() === "") { //kiran-2836-Removing console warnings
      newList = this.state.myReportDetails;
    } else {
      // Assign the original list to currentList
      currentList = this.state.myReportDetails
      newList = currentList.filter((item) => {
        let obj={};//Somnath Task-3972, Add required comumn in search fields
        obj[`reportID`]=item.reportID;
        obj[`reportName`]=item.reportName;
        obj[`createdBy`]=item.createdBy;
        obj[`created`]=item.created;
        obj[`lastUpdated`]=item.lastUpdated;
        // change current item to lowercase
        const lc = obj;
        //const lc = item.toLowerCase();
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });
    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      reportDetailsSearch: [...newList],
      tableSearchText: filterData,
    });
  }//handle search box
           /**
       * @author Snehal More
       * @param  Description handle change handleChangePageSize2
       * @return Description to change records per page 
       */
  handleChangePageSize2(e) {
    var pageSize2 = e.target.value;
    this.setState({ pageSize2: pageSize2 });
  }

  
 /**
* @author : Rutuja Jagtap-task 4496
* @description :  Added api in this function for deleting Report.
*/

deleteReport(e) {
 
  // const { user } = this.props.auth;//kiran-2836-Removing console warnings-no use
  var reportID = e.target.getAttribute('reportID')
  // const pID = user.id; //kiran-2836-Removing console warnings-no use
  var type = this.state.reportType;

  //sunita-task-3406-added for advertiser type
   let data;
    //sunita-task-3406-passed below data to BE as per tab key.

    
      data = {
        
        reportID: reportID,
        //pID: pID,
        type: type,
      }

   fetch("/publisherReports/deleteReport", {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
    .then(res => {
      if (res.success === true) {

        
        Swal.fire({
          text: "Report Deleted successfully.",
          type: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: true,
          preConfirm: () => {
           
            window.location.reload();
          },
        });
      }
      else{
        Swal.fire({
          text: "Something went wrong while deleting report",
          type: "Failure",
          confirmButtonText: "Ok",
          allowOutsideClick: true,
          preConfirm: () => {
           
            window.location.reload();
          },
        });

      }
    }
    ).catch(function (err) {
      console.log(err)
    });

}


/**
* @author : Rutuja Jagtap-task 4497
* @description :  Added api in this function for downoading Report.
*/
downloadReport(e) {
  e.preventDefault();
  var reportID = e.target.getAttribute('reportID')
 let reportName = e.target.getAttribute('reportName')
 var dt = dateTime.create();
   var curdate = dt.format("Y-m-d_H:M:S");
 let data = {
   reportID: reportID,
 }
 fetch("/publisherReports/getInfoAgencyTrackingReport", {
   method: 'POST',
   headers: {
     "Content-Type": "application/json",
   },
   body: JSON.stringify(data)
 }).then(res => res.json())
 .then(publisherDeliveryTrackingDetails => {
 
   this.setState({
    publisherDeliveryTrackingDetails: publisherDeliveryTrackingDetails[0].agencyDeliveryTrackingReportDetails
  })
 publisherDeliveryTrackingDetails = this.state.publisherDeliveryTrackingDetails;
   if (publisherDeliveryTrackingDetails.length > 0) {
     var ws = xlsx.utils.json_to_sheet(publisherDeliveryTrackingDetails);
     
     var wb = xlsx.utils.book_new();
     xlsx.utils.book_append_sheet(wb, ws, "Lead");
     var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
     function s2ab(s) {
         var buf = new ArrayBuffer(s.length);
         var view = new Uint8Array(buf);
         for (let i = 0; i !== s.length; ++i)
             view[i] = s.charCodeAt(i) & 0xff;
         return buf;
     }

    
     var fileName = reportID  + "_" + reportName  + "_" + curdate +".xlsx";
     saveAs(
         new Blob([s2ab(buf)], { type: "application/octet-stream" }),
         fileName
     );
     Swal.fire({
      html: "Your Report has been successfully Downloaded!",
      type: "success",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
  });
          } else {
            Swal.fire({
              html: "This report data Does not exists!",
              type: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
          });
          
 }


}).catch(function (err) { console.log(err) });
}

 //snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue(data font size is changes)
  render() {
    // const { isAuthenticated } = this.props.auth; //kiran-2836-Removing console warnings-no use
    const Column = [
      {
        title: <span>Report ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'reportID',
        key: 'reportID',
        width: '100px',
        align: 'left',
        sorter: (a, b) => a.reportID - b.reportID,
        render: (text, record) =>
          <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.reportID}</span></div>
      },
      {
        title: <span>Report Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'reportName',
        key: 'reportName',
        width: '200px',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.reportName.toUpperCase() < b.reportName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.reportName.toUpperCase() > b.reportName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          <div id="to_truncate_new" title={record.reportName}>   {/*chaitanya-4187-Publisher-My reports- Report title not showing full name on report list page*/}
             {/* /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */ }
             {/* kiran-2836-Removing console warnings */}
            <span ><a href="#/" onClick={this.onClickReportName} style={{ fontSize: '13px' }}
              reportID={record.reportID} reportType={record.type} reportName={record.reportName}
            >
              {record.reportName}</a></span>
          </div>
      },
      {
        title: <span>Report Type&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'type',
        key: 'type',
        width: '200px',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.type.toUpperCase() < b.type.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.type.toUpperCase() > b.type.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.type}</span></div>
      },
      {
        title: <span>Created On&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'created',
        key: 'created',
        width: '120px',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.created.toUpperCase() < b.created.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.created.toUpperCase() > b.created.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.created}</span></div>
      },
      {
        title: <span>Created By&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: '150px',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.createdBy.toUpperCase() < b.createdBy.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.createdBy.toUpperCase() > b.createdBy.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.createdBy}</span></div>
      },
      {
        title: <span>Edited On&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'lastUpdated',
        key: 'lastUpdated',
        width: '120px',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.lastUpdated.toUpperCase() < b.lastUpdated.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.lastUpdated.toUpperCase() > b.lastUpdated.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          <div><span style={{ fontSize: '13px',color:'#4F4F4F' }}>{record.lastUpdated}</span></div>
      },
      {
        title: 'Action',
        dataIndex: 'clientName',
        key: 'clientName',
        width: '160px',
        align: 'left',
        render: (text, record) =>
          <div>
             {/* /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */ }
             {/* Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE */}
             <span style={{ display: "inline-flex" }}>
            <a href="#/" style={{ float: "left" }} data-toggle="modal" data-target="#editReportModal" data-backdrop="false" data-keyboard="false">
              <Tooltip placement="top" title="Edit">
                <img
                  src="edit_report.png"
                  alt=""
                  reportID={record.reportID} reportName={record.reportName} reportType={record.type}
                  onClick={this.handleEditReport}
                  width="20"
                  height="20"></img>
              </Tooltip>
            </a>
              {/* rutuja task-4497 added download report functionality  */}
              {/* kiran-2836-Removing console warnings */}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   <a href="#/"
                  reportID={record.reportID}
                  reportName={record.reportName}
                  type={record.type}
                    onClick={this.downloadReport.bind(this)}>
                  <Tooltip placement="top" title="Download">
                    <img
                     reportid={record.reportID}
                     reportName={record.reportName}
                     type={record.type}
                      src="downloadreport.png"
                      data-toggle="modal"
                      data-target="#delete_report"
                      width="20"
                      height="20"
                      alt=""></img>
                  </Tooltip>
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Tooltip placement="top" title="Delete">
                <img
                 reportID={record.reportID} 
                  reportType={record.type}
                 onClick ={this.deleteReport.bind(this)}
                  src="file_upload_2_blue.png"
                  alt=""
                  width="20"
                  height="20"></img>
              </Tooltip>
              </span>
          </div>
      },

    ];
    function onChange(pagination, filters, sorter) {
    }
    return (
      <div >
        <PublisherNavigation />
        <div style={{ margin: '20px' }}>
          <div class="container-fluid" style={{ paddingTop: '80px' }}>
            <div class="row">
              {/* //snehal-task-3461-Publisher side--My report UI issue-regarding design(back button removed) */}
              {/* //snehal-task-3444-publisher side--report--create/edit report name--validation issue(changes regarding UI) */}
              
              <div class="col-lg- col-md-3 col-sm-3">
                 {/*Chaitanya-4099-Back button required on My reports screen - Publisher*/}
                 {/* kiran-2836-Removing console warnings */}
                < a class="col" href='#/' style={{ color: '#056eb8', float: 'left', marginBottom: "-37px", marginLeft: "-20px" }} onClick={this.myReportBackButton.bind(this)} >
                  <FaArrowAltCircleLeft size={32} title="Back to Publisher Dashboard" className="backFrom_myReport" />
                </a>
              </div>
               
              <div class="offset-lg-2 offset-sm-2 col-lg-8 col-md-8 col-sm-8" align="center">
                <center><label id="labelDI" style={{ fontSize: '28px', fontStyle: 'roboto', fontWeight: '500', color: '#193D8F' }} align="center">My Reports</label></center>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12">{/* //snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue */}
                {/* Sandeep-task-3730-VAPT--Publisher side--Reports---URL links & API-FE */}
                <button class="btn add-button" onClick={this.newhandleChange} type="submit" value="submit1" data-toggle="modal" data-target="#createReportModal" data-backdrop="false" data-keyboard="false" style={{ marginBottom: '5px', float: 'right' }}>
                  + Create New Report
                    </button>
                     {/* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/}
                <div className="modal fade" id="createReportModal" role="dialog" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                  <div className="modal-dialog modal-sm  createReportModal" >
                    <div className="modal-content modal-content_publisher" style={{ marginTop:'152px',float:'right',marginRight:'168%'}}>       {/* Chaitanya-4181-Publisher-My reports-Responsiveness*/}
                      <div class="modal-header" style={{ backgroundColor: "#144999", height: '50px' }}>
                        <p style={{ color: '#FFFFFF', fontSize: '17px', fontWeight: '400 ', fontFamily: 'Roboto' }}>Create New Report
                           <button
                            type="button"
                            class="close"
                            style={{ fontColor: '#FFFFFF', fontSize: '18px', marginTop: '-5px', fontWeight: '400' }}
                            data-dismiss="modal">
                            &times;
                                                </button></p>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 ">
                        <label className="labelStyle" style={{ fontSize: '14px' }}>Report Name : </label>
                        <span className="asterisk">*</span>
                        <input
                          type="text"
                          id="reportName"
                          className="form-control reportNamePublisher"       /*chaitanya-4187-Publisher-My reports-Responsiveness*/
                          name="reportName"
                          required
                          style={{ width: "100%" }}
                          onChange={this.commonhandleChange}
                          value={this.state.reportName}></input>
                        <ul style={{ display: this.state.reportNameDisplay }} className="errorMessage"><li style={{ listStyle: 'disc outside none', display: 'list-item', marginLeft: '2em' }}>Please enter valid report name</li></ul>
                        <p></p>
                        <label className="labelStyle" style={{ fontSize: '14px' }}>Report Type : </label>
                        {/* <span className="asterisk">*</span> */}
                        <select
                          id="reportType"           
                          name="reportType"
                          value={this.state.reportType}
                          class="form-control reportTypePublisher"  //chaitanya-4187-Publisher-My reports- Responsiveness
                          style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px', width: "100%" }}
                          onChange={this.commonhandleChange}>
                          <option value="Agency Campaign Delivery Tracking" selected>Agency Campaign Delivery Tracking</option>
                          <option value="Agency Lead Tracking" >Agency Lead Tracking</option>  {/* /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */ }
                          <option value="Financial" disabled>Financial</option>
                        </select>
                        <ul style={{ display: this.state.reportTypeDisplay }} className="errorMessage"><li style={{ listStyle: 'disc outside none', display: 'list-item', marginLeft: '2em' }}>Please select report type</li></ul>
                      </div>
                      <br />
                      <div class="row">
                        <button id="cancleButton_pub" class="btn offset-lg-1 col-lg-4 offset-md-2 col-md-3 offset-sm-2 col-sm-6 col-sm-6 offset-1 col-4" style={{ color: "#144999", float: 'left', borderColor: '#144999', backgroundColor: '#FFFFFF', border: '2px solid #144999' }} type="button" value="cancel" data-dismiss="modal"> Cancel </button> {/* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/}
                        <button id="createButton_pub" class="btn offset-lg-1 col-lg-4 offset-md-2 col-md-3 offset-sm-2 col-sm-6 offset-2 col-4" style={{backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"}} type="cancel" value="cancel" onClick={this.createReportButton.bind(this)}> Create </button>  {/*4314-R-29-Prod issue-My Report-create new report-cancel button-Publisher-(Removed add.button)*/}
                      </div>
                      <br /> <br />
                    </div>
                  </div>
                </div>
                {/* <button class="btn add-button"  type="submit" value="help" style={{margin:'5px'}} >
                            <a href="#" style={{color:'white'}} >  Help</a>  
                        </button>    */}
              </div>
            </div>
             {/* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/}
            <div className="modal fade" id="editReportModal" role="dialog" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
              <div className="modal-dialog modal-sm editReportModal" >
                <div className="modal-content modal-content_publisher" style={{ marginTop:'152px',float:'right',marginRight: '168%'}}>                           {/* Chaitanya-4181-Publisher-My reports-Responsiveness*/}
                  <div class="modal-header" style={{ backgroundColor: "#144999", height: '50px', fontFamily: 'Roboto' }}>
                    <p style={{ color: '#FFFFFF', fontSize: '17px', fontWeight: '400' }}>Edit Report Name
                                                <button
                        type="button"
                        class="close"
                        style={{ fontColor: '#FFFFFF', fontSize: '18px', marginTop: '-5px', fontWeight: '400' }}
                        data-dismiss="modal">
                        &times;
                                                </button>
                    </p>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <label className="labelStyle" style={{ fontSize: '14px' }}>Report Name : </label>
                    <span className="asterisk">*</span>
                    <input
                      type="text"
                      id="reportName"
                      className="form-control reportNamePublisher"       /*chaitanya-4187-Publisher-My reports-Responsiveness*/
                      name="reportName"
                      required
                      style={{ width: "100%" }}
                      onChange={this.commonhandleChange}
                      value={this.state.reportName}></input>
                    <ul style={{ display: this.state.reportNameDisplay }} className="errorMessage"><li style={{ listStyle: 'disc outside none', display: 'list-item', marginLeft: '2em' }}>Please enter valid report name</li></ul>
                    <p></p>
                    <label className="labelStyle" style={{ fontSize: '14px' }}>Report Type : </label>
                    {/* <span className="asterisk">*</span> */}
                    <select
                      id="reportType"        
                      name="reportType"
                      value={this.state.reportType}
                      className="form-control reportTypeEditReportPublisher"    //chaitanya-4187-Publisher-My reports-Responsiveness
                      disabled
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px', width: "100%" }}
                      onChange={this.commonhandleChange}>
                      <option value="Agency Campaign Delivery Tracking" selected>Agency Campaign Delivery Tracking</option>
                      <option value="Agency Lead Tracking" >Agency Lead Tracking</option>  {/* /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */ }
                      <option value="Financial" disabled>Financial</option>
                    </select>
                    <ul style={{ display: this.state.reportTypeDisplay }} className="errorMessage"><li style={{ listStyle: 'disc outside none', display: 'list-item', marginLeft: '2em' }}>Please select report type</li></ul>
                  </div>
                  <br />
                  <div class="row">
                    <button class="btn offset-lg-1 col-lg-4 offset-md-2 col-md-3 offset-sm-2 col-sm-6 col-sm-6 offset-1 col-4" style={{marginLeft:'34px', color: "#144999", float: 'left', borderColor: '#144999', backgroundColor: '#FFFFFF', border: '2px solid #144999' }} type="button" value="cancel" data-dismiss="modal"> Cancel </button> {/* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/}
                    <button id="editButton_pub" class="btn offset-lg-1 col-lg-4 offset-md-2 col-md-3 offset-sm-2 col-sm-6 offset-2 col-4" style={{marginLeft:'43px',backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"}} type="cancel" value="cancel" data-dismiss="modal" onClick={this.editReportButton.bind(this)}> Edit </button> {/* //snehal-task-3461-Publisher side--My report UI issue-regarding design(popup will close on click on edit)*/}
                     {/* Chaitanya-4181-Publisher-My reports-Responsiveness*/}
                     {/*4314-R-29-Prod issue-My Report-create new report-cancel button-Publisher-(Removed add.button)*/}   
                  </div>
                  <br /> <br />
                </div>
              </div>
            </div>
            <div class="row">
              {/* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/}
              {/* kiran-2836-Removing console warnings */}
              <div class="col-lg-4 col-md-12 col-sm-12">{this.state.reportType.toString() !== "" ?
                <p style={{ color: '#717171' }}>Showing results for <label style={{ color: '#414141' }}>{this.state.reportTypeShow}</label></p> : ""}</div> {/* /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */ }
              <div class="offset-lg-3 col-lg-3 col-md-6 col-sm-6" >
                <select

                  id="reportType"     
                  name="reportType"
                  value={this.state.reportType}
                  className="form-control reportTypeMyreportPublisher"  //chaitanya-4187-Publisher-My reports-Responsiveness
                  style={{
                    height: 'calc(2.25rem + 12px)'
                    , width: '270px'
                    , borderRadius: '4px'
                  }}
                  onChange={this.handleChangeReportType}>
                     {/* snehal-task-3568-pub side---my reaport---publisher lead tracking issue */}
                      {/* /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */ }
                  <option value="Agency Campaign Delivery Tracking" selected>Agency Campaign Delivery Tracking</option>
                  <option value="Agency Lead Tracking">Agency Lead Tracking</option>
                  <option value="Financial" disabled>Financial</option>
                </select>
              </div>
              <div class=" col-lg-2 col-md-5 col-sm-3" style={{}}>
                <input
                  id="search_pub_myreport"
                  type="text"
                  value={this.state.filterData} //snehal-task-3574-Publisher side--my report-- filter issue
                  className="form-control search_pub_myreport"
                  style={{
                    backgroundImage: "url(searchIcon.png)",
                    backgroundPosition: "15px 6px",
                    backgroundSize: "15px 15px",
                    backgroundRepeat: "no-repeat",
                    //width: "100%",
                    height: "20px",
                    fontSize: "12px",
                    padding: "16px 20px 16px 34px",
                    borderRadius: "4px",
                    border: '1px solid rgb(221, 221, 221)'
                  }}
                  onChange={this.handleChangeSearch}
                  placeholder="Search..."
                  title="Search"
                />
              </div>
            </div>
          </div>

          <div align="center" id={this.state.loader}></div>
          <div class="table-responsive" style={{ marginBottom: '120px' }}>
          {/* kiran-2836-Removing console warnings-change className */}
            {this.state.tableSearchText === "" ?
              <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.myReportDetails}
                onChange={onChange} columns={Column}  className={`${tableCSS} "ant-table-thead"`} scroll={{ y: 700, x: 1000, }}
                pagination={{ pageSize: this.state.pageSize2 }}></Table>
              : <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.reportDetailsSearch}
                onChange={onChange} columns={Column} className={`${tableCSS} "ant-table-thead"`} scroll={{ y: 700, x: 1000, }}
                pagination={{ pageSize: this.state.pageSize2 }}></Table>
            }
            <p>
              <div class="row" style={{ paddingBottom: '120px' }}>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{ marginTop: '-50px',fontFamily:'sans-serif',fontSize:'13px',color:'#4F4F4F' }}>{/* //snetal-task-3483-publisher side--My report--edit report--Sweet alert message size issue */}
                  &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                  <select
                    defaultValue={this.state.pageSize2}
                    onChange={this.handleChangePageSize2}
                    id="pacing1"
                    class="input-small"
                    className="form-control RecordsPublisher"       //chaitanya-4187-Publisher-My reports-Responsiveness
                    name="pacing" style={{ width: '80px', height: '30px', display: 'initial' }}>
                    <option value="100" selected>100</option>
                    <option value="150" >150</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                  </select>
                </div>
              </div>
            </p>

          </div>
        </div>
        <Footer />
      </div>
    );
  }// end of renders
}// end of class

      /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
PublisherMyReport.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(PublisherMyReport));

