/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/**
* @file approvedPublisher
* @author Sanjana Godbole
* @version 1.0
* @section use for admin to approve publisher
**/

import React from 'react';
import AdminNavpage from '../layouts/adminNavpage.js';
import Footer from '../layouts/footer';

import { FaArrowAltCircleLeft } from "react-icons/fa";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
const queryString = require('query-string');

class ApprovedPublisher extends React.Component {
    constructor(props) {
        super(props);
        
    this.state = { newsuccess:'',
            newfailure:''};

    }
    handleBackButton(e){
        window.location.href = '/reviewPublisher';
    
        }

    componentDidMount() {
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
          }
        else{
        var parsed = queryString.parse(this.props.location.search);
        var orgID=parsed.orgID;
        var newsuccess=parsed.successMessage;
        var email=parsed.email;
        var password=parsed.password;
        //alert("org id is==>"+orgID)
        //alert("sucess message is==>"+newsuccess)
        this.setState({ orgID:orgID,newsuccess:newsuccess,email:email});
    }  
}


    render() {
        return (
           <div>
<AdminNavpage/>
<div className="container-fluid" style={{paddingTop:"90px",paddingBottom:'60px'}}>

    
<div class="row">
    <div class=" col-lg-4">
    <div class="float-left">
    <FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back To Review Publisher" onClick={this.handleBackButton} style={{color: '#056eb8'}}/>
    </div>
    </div>

</div>
<div class="row">
<div class=" col-lg-12">
<center><div style={{ fontSize: '25px', color: 'green'}}>{this.state.newsuccess}(publisher ID:{this.state.orgID}).</div><br/>
<div style={{ fontSize: '14px'}}>Login details are sent to : {this.state.email}</div><br/>


</center>
</div>

</div>

</div>

{/* end of container */}
<Footer/>
              
               </div>
        )}

}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      ApprovedPublisher.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
 export default connect(mapStateToProps, { logoutUser })(withRouter(ApprovedPublisher)); 

