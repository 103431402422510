/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanjana Godbole
@Creation Date:07-01-2019
@Description:

*/
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import * as $ from "jquery";
import { withRouter } from "react-router-dom";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3714-vapt header and query params
import "./agencyCreativesReview.css";


const Swal = require("sweetalert2");


var formIsValid = false;

class AgencyCreativesReview extends React.Component {
	constructor() {
		super();
		this.state = {
			newsuccess: "",
			btnDisable: "button",
			dynamic: [],
			stateCopy: [{ status: "", feedback: "" }],
			newDynamicArray: [],
			campID: "",
			campaignName: "",
			pID: "",
			publisherName: "",
			parentCampID: "",
			reallocationID: "",
			errors: {},
			reerrors: {},
			lpReviewPendingListCampIDOnNextPage: [],
			lpReviewPendingList: [],
			creativeReview: [],
			tableStatus: "false",
			pubListForLPReview: [],
			creativeReviewNotification: [],
			pIDForCreativeReview: [],
			landingPageList: [],
			pocList: [],
			alertDisplay: "none",
			alertBorder: "",
			alertWidth: "",
			alertAlign: "",
			spanSpaceDisplay: "none",
			lperror: {},
			cserror: {},
			pocerror: {},
			marketingChannel: "",
			count: 0,
			agencyID: "",
			backKey: "",
			tempArray: [],
			creativesMessage: "",
			ext: "",
			submitButtonAction: false, //snehal-task-2878-Allocated lead links--creatives --submit button issue
			approvalFor: "Agency",
			creativeCampList: [],//Somnath Task-3832, declare array in state for getting campIDlist
			listCampID: [],
			searchData: ``,
		}; // end of this.state
		this.submitLpReviewFeedback = this.submitLpReviewFeedback.bind(this);
		this.handleChangeLpFeedback = this.handleChangeLpFeedback.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.campIdHandleChange = this.campIdHandleChange.bind(this);
		this.handleChangeStatus = this.handleChangeStatus.bind(this);
		this.handleChangeStatus1 = this.handleChangeStatus1.bind(this);
		this.handleChangeStatus2 = this.handleChangeStatus2.bind(this);
		this.downloadPOCFile = this.downloadPOCFile.bind(this);
		this.apiCall = this.apiCall.bind(this);
		this.UpdateAPI = this.UpdateAPI.bind(this);//Somnath Task-3832, Bind the function with this
	} // end of constructor

	handleChangeStatus2(e) {
		e.preventDefault();//Somnath Task-3832, Add PreventDefault method
		var timeline = document.getElementById("timeline2");
		if (e.target.value === "Rejected") {//kiran-4745-removing console warning-changed == to ===
			timeline.style.display = "block";
		} else {
			timeline.style.display = "none";
		}
	}
	handleChangeStatus1(e) {
		var timeline = document.getElementById("timeline1");
		
		if (e.target.value === "Rejected") {//kiran-4745-removing console warning-changed == to ===
			timeline.style.display = "block";
		} else {
			timeline.style.display = "none";
		}
	}

	handleChangeStatus(i, e) {
		e.preventDefault();//Somnath Task-3832, Add PreventDefault method
		var timeline = document.getElementById("timeline");
		if (e.target.value === "Rejected") {//kiran-4745-removing console warning-changed == to ===
			timeline.style.display = "block";
		} else {
			timeline.style.display = "none";
		}
	}

	/**
	 * @author Sonali Kalke
	 * @param  Description download CS files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	handleCSFileDownload(e) {
		e.preventDefault();//Somnath Task-3832, Add PreventDefault method
		var campID = e.target.id;
		var supportDocID = e.target.getAttribute("supportDocID");
		var pID = e.target.getAttribute("pID");
		var csFileName = e.target.getAttribute("csFileName");
		var ext = csFileName.split(".").pop();
		this.setState({ ext: ext, filename: csFileName });
		fetch(
			"/publisher/csFileDownload?campID=" +
			campID +
			"&pID=" +
			pID +
			"&supportDocID=" +
			supportDocID
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.downloadPOCFile);
	}
	/**
	 * @author Somnath Keswad
	 * @param  Description download POC files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	handleFileDownload(e) {
		e.preventDefault();//Somnath Task-3832, Add PreventDefault method
		var campID = e.target.id;
		var supportDocID = e.target.getAttribute("supportDocID");
		var pID = e.target.getAttribute("pID");
		var pocFileName = e.target.getAttribute("pocFileName");
		var ext = pocFileName.split(".").pop();
		this.setState({ ext: ext, filename: pocFileName });
		fetch(
			"/publisher/pocFileDownload?campID=" +
			campID +
			"&pID=" +
			pID +
			"&supportDocID=" +
			supportDocID
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.downloadPOCFile);
	} /*** End of handleFileDownload */

	/**
	 * @author Somnath Keswad
	 * @param  Description download Feedback files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	FeedbackFileDownload(e) {
		e.preventDefault();//Somnath Task-3832, Add PreventDefault method
		var campID = e.target.id;
		var supportDocID = e.target.getAttribute("supportDocID");
		var pID = e.target.getAttribute("pID");
		var feedbackFileName = e.target.getAttribute("feedbackFileName");
		var type = e.target.getAttribute("type");
		var ext = feedbackFileName.split(".").pop();
		this.setState({ ext: ext, filename: feedbackFileName });
		fetch(
			"/publisher/feedbackFileDownload?campID=" +
			campID +
			"&pID=" +
			pID +
			"&supportDocID=" +
			supportDocID +
			"&type=" +
			type
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.downloadPOCFile);
	} /*** End of FeedbackFileDownload */

	/**
	 * @author Somnath Keswad
	 * @param  Description download POC files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	downloadPOCFile(blob) {
		/***** If POC File is PDF */
		if (this.state.ext === "pdf") {//kiran-4745-removing console warning-changed == to ===
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pdfFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/pdf",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = pdfFileDownloadName;
			link.click();
		}
		if (this.state.ext === "ppt" || this.state.ext === "pptx") {//kiran-4745-removing console warning-changed == to ===
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pptFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/vnd.ms-powerpoint",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = pptFileDownloadName;
			link.click();
		}

		if (this.state.ext === "txt") {//kiran-4745-removing console warning-changed == to ===
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let textFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "text/plain",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = textFileDownloadName;
			link.click();
		}

		if (this.state.ext === "docx") {//kiran-4745-removing console warning-changed == to ===
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/msword",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
//kiran-4745-removing console warning-changed == to ===
		if (
			this.state.ext === "png" ||
			this.state.ext === "jpeg" ||
			this.state.ext === "jpg" ||
			this.state.ext === "PNG" ||
			this.state.ext === "JPEG" ||
			this.state.ext === "JPG"
		) {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/Image",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
	} /**** End of downloadPOCFie */

	/**
	 * @author Somnath Keswad
	 * @param  Description handle the dynamic value of landing page or POC file
	 * @return Description return dynamic array
	 */
	handleChangeLpFeedback(i, e) {
		//kiran-4745-removing console warning-changed == to ===
		if (e.target.value === "Approved" || e.target.value === "Rejected") {
			this.setState((prevstate) => ({
				count: prevstate.count + 1,
				submitButtonAction: true,
			})); //snehal-task-2878-Allocated lead links--creatives --submit button issue
		}
		if (e.target.value === "Select") {
			//kiran-4745-removing console warning-changed == to ===
			this.setState((prevstate) => ({
				count: prevstate.count - 1,
				submitButtonAction: true,
			})); //snehal-task-2878-Allocated lead links--creatives --submit button issue
		}
		const { name, value } = e.target;

		var pocflag = "pocflag";
		var lpflag = "lpflag";
		var csflag = "csflag";
		var value2 = "true";
		let lpReviewPendingList = [...this.state.lpReviewPendingList];
		lpReviewPendingList[i] = { ...lpReviewPendingList[i], [name]: value };
		lpReviewPendingList[i] = { ...lpReviewPendingList[i], [pocflag]: value2 };
		lpReviewPendingList[i] = { ...lpReviewPendingList[i], [lpflag]: value2 };
		lpReviewPendingList[i] = { ...lpReviewPendingList[i], [csflag]: value2 };
		this.setState({ lpReviewPendingList });
		var supportDocID = e.target.getAttribute("supportDocID");
		var pID = e.target.getAttribute("pID");
		var id = e.target.getAttribute("id");
		var timeline = document.getElementById(
			"timeline" + supportDocID + pID + id
		);
		if (
			e.target.name === "lpStatus" ||
			e.target.name === "pocStatus" ||
			e.target.name === "csStatus"
		) {
			if (e.target.value === "Rejected") {//kiran-4745-removing console warning-changed == to ===
				timeline.style.display = "block";
			} else {
				timeline.style.display = "none";
			}
		}
	}

	/**
	 * @author Sanjana Godbole
	 * @param  Description Handle change function gets called when change in value of CampaignId dropdown
	 * @return Description return newly selected campaign id from dropdown
	 */
	campIdHandleChange(e) {
		e.preventDefault();//Somnath Task-3832, Add PreventDefault method
		var pID = "All";

		var campValue = e.target.value;
		var res = campValue.split("-");
		var campID = res[0];
		var campaignName = res[1];
		this.setState({ campID: campID, campaignName: campaignName, pID: pID });

		this.apiCall(campID, pID);
	} // end of campIdHandleChange
	//Select Publisher Handle Change
	handleChange(e) {
		e.preventDefault();
		this.setState({
			alertDisplay: "none",
			btnDisable: "button",
			tableStatus: "false",
		});

		var pID = e.target.value;
		//karan-task-3715-replace query params
		let campID;
		if (this.props.location.state !== undefined) {
			campID = this.props.location.state.campID;
		}
		if (
			this.state.campID === null ||
			this.state.campID === undefined ||
			this.state.campID === ""
		) {
		} else {
			campID = this.state.campID;
		}
		this.setState({ campID, pID });
		this.apiCall(campID, pID);
	} //end of handleChange

	/**
	 * @author Sanjana Godbole,Somnath Keswad
	 * @param  Description API call to show publisher name,CampaignId and creatives info which are waiting for creatives review
	 * @return Description return publisher name ,CampaignId and creatives info which are waiting for creatives review
	 */
	apiCall(campID, pID) {
		let self = this;
		const { user } = this.props.auth;
		const agencyID = user.id;
		//karan-task-3715-replace query params
		let parentCampID, reallocationID, backKey;
		if (this.props.location.state !== undefined) {
			parentCampID = this.props.location.state.parentCampID;
			reallocationID = this.props.location.state.reallocationID;
			backKey = this.props.location.state.backKey;
		}
		var submitButtonAction;
		let apiData = { campID, pID }; // karan-task-3714-vapt header and query params

		this.setState({ parentCampID, reallocationID, agencyID, campID, backKey, pID, });
		//Get list of campign Id
		fetch("agencyDashboard/lpPOCCSReviewNotifyDash")
			.then((res) => res.json())

			.then(creativeCampList => this.setState({ creativeCampList, listCampID: creativeCampList }))
			.catch(e => console.log(e));

		//Get Campaign Info
		fetch("publisher/lpReviewPendingForCampID?campID=" + campID)
			.then((res) => res.json())
			.then((lpReviewPendingListCampIDOnNextPage) => {
				let marketingChannel =
					lpReviewPendingListCampIDOnNextPage[0].marketingChannel;
				let approvalFor =
					lpReviewPendingListCampIDOnNextPage[0].creativeApprovalRequiredFor;
				let campaignName = lpReviewPendingListCampIDOnNextPage[0].campaignName;
				self.setState({
					lpReviewPendingListCampIDOnNextPage,
					marketingChannel,
					approvalFor,
					campaignName,
				});
			}).catch(e => console.log(e));
		///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		//getting Publisher
		fetch("/agencyCreativeReviewBE/pIDForCreativeReview", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(apiData),
		}).then((res) => res.json())
			.then(pubListForLPReview => self.setState({ pubListForLPReview }))
			.catch(e => console.log(e));

		//Get Creatives Data
		fetch("/agencyCreativeReviewBE/getCreativeInfo", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(apiData),
		})
			.then((res) => res.json())
			.then((lpReviewPendingList) => {
				//kiran-4745-removing console warning-changed == to ===
				if (lpReviewPendingList.length === 0) {
					let message = "Creatives Not Available";
					self.setState({
						creativesMessage: message,
						submitButton: "disabled",
					});
				} else {
					let message = " ";
					self.setState({ creativesMessage: message });
				}

				//Narendra- Bug-2878-Change code structure because some time array set to setState but not excute the next code so feedback details not display some time
              //kiran-4745-removing console warning-changed == to ===
				self.setState(
					{ lpReviewPendingList: lpReviewPendingList, tableStatus: "true" },
					() => {
						if (lpReviewPendingList.length > 0) {
							for (var i = 0; i < lpReviewPendingList.length; i++) {
								if (
									lpReviewPendingList[i].lpStatus === "AgencyReviewPending" ||
									lpReviewPendingList[i].lpStatus === "Rejected" ||
									lpReviewPendingList[i].pocStatus === "AgencyReviewPending" ||
									lpReviewPendingList[i].pocStatus === "Rejected" ||
									lpReviewPendingList[i].csStatus === "AgencyReviewPending" ||
									lpReviewPendingList[i].csStatus === "Rejected"
								) {
									submitButtonAction = false;
								}
								//Narendra-Bug-2878 - Adding hasownproperty because in some creatives landing page not present
								if (lpReviewPendingList[i].hasOwnProperty("lpStatus")) {
									if (
										lpReviewPendingList[i].lpStatus === "Rejected" ||
										lpReviewPendingList[i].lpStatus === "ClientRejected"
									) {
										var timeline = document.getElementById(
											"timeline" +
											lpReviewPendingList[i].supportDocID +
											lpReviewPendingList[i].pID +
											"lpStatus"
										);
										if (
											timeline == null ||
											timeline === "null" ||
											timeline === undefined
										) {
										} else {
											timeline.style.display = "block";
										}
									}
								}
								//Narendra-Bug-2878 - Adding hasownproperty because in some creatives poc not present
								if (lpReviewPendingList[i].hasOwnProperty("pocStatus")) {
									if (
										lpReviewPendingList[i].pocStatus === "Rejected" ||
										lpReviewPendingList[i].pocStatus === "ClientRejected"
									) {
										var pocTimeline = document.getElementById(
											"timeline" +
											lpReviewPendingList[i].supportDocID +
											lpReviewPendingList[i].pID +
											"pocStatus"
										);
										if (pocTimeline && pocTimeline.style && pocTimeline.style.display) {
											pocTimeline.style.display = "block";
										}

									}
								}
								//Narendra-Bug-2878 - Adding hasownproperty because in some creatives callscript not present
								if (lpReviewPendingList[i].hasOwnProperty("csStatus")) {
									if (
										lpReviewPendingList[i].csStatus === "Rejected" ||
										lpReviewPendingList[i].csStatus === "ClientRejected"
									) {
										var csTimeline = document.getElementById(
											"timeline" +
											lpReviewPendingList[i].supportDocID +
											lpReviewPendingList[i].pID +
											"csStatus"
										);
										if (csTimeline && csTimeline.style && csTimeline.style.display) {
											csTimeline.style.display = "block";
										}
										// csTimeline.style.display = "block";
									}
								}
							}
							this.setState({ submitButtonAction: submitButtonAction });
						}
					}
				);
			}).catch(e => console.log(e));
	}

	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		}
		//karan-task-3715-replace query params
		let campID, pID;
		if (this.props.location.state !== undefined) {
			campID = this.props.location.state.campID;
			pID = this.props.location.state.pID;
		}
		this.setState({ campID })
		pID = pID || "All";
		this.apiCall(campID, pID);
		//Status Update Only
		this.UpdateAPI(campID);
	}


	//Validate Form
	validateForm(lpReviewPendingList) {
		let errors = {};
		let spanDisplay = "none";

		if (lpReviewPendingList === undefined || lpReviewPendingList === null) {
			formIsValid = false;
			errors["noDataFeedback"] = "No Data Exists";
			this.setState({
				errors: errors,
				reerrors: "",

				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		} else {
			for (var i = 0; i < lpReviewPendingList.length; i++) {
				if (lpReviewPendingList[i].hasOwnProperty("csStatus")) {
					if (lpReviewPendingList[i].csStatus === "Rejected") {
						if (
							(lpReviewPendingList[i].csFeedback !== "" &&
								lpReviewPendingList[i].csFeedback !== null) ||
							(lpReviewPendingList[i].csFeedbackFileName !== "" &&
								lpReviewPendingList[i].csFeedbackFileName !== null)
						) {
							lpReviewPendingList[i].csflag = "true";
						} else {
							lpReviewPendingList[i].csflag = "false";
						}
					} else {
						if (lpReviewPendingList[i].csStatus === "Approved") {
							lpReviewPendingList[i].csflag = "true";
						}
					}
				}

				if (lpReviewPendingList[i].hasOwnProperty("lpStatus")) {
					if (lpReviewPendingList[i].lpStatus === "Rejected") {
						if (
							(lpReviewPendingList[i].lpFeedback !== "" &&
								lpReviewPendingList[i].lpFeedback !== null) ||
							(lpReviewPendingList[i].lpFeedbackFileName !== "" &&
								lpReviewPendingList[i].lpFeedbackFileName !== null)
						) {
							lpReviewPendingList[i].lpflag = "true";
						} else {
							lpReviewPendingList[i].lpflag = "false";
						}
					} else {
						if (lpReviewPendingList[i].lpStatus === "Approved") {
							lpReviewPendingList[i].lpflag = "true";
						}
					}
				}

				if (lpReviewPendingList[i].hasOwnProperty("pocStatus")) {
					if (lpReviewPendingList[i].pocStatus === "Rejected") {
						if (
							(lpReviewPendingList[i].pocFeedback !== "" &&
								lpReviewPendingList[i].pocFeedback !== null) ||
							(lpReviewPendingList[i].pocFeedbackFileName !== "" &&
								lpReviewPendingList[i].pocFeedbackFileName !== null)
						) {
							lpReviewPendingList[i].pocflag = "true";
						} else {
							lpReviewPendingList[i].pocflag = "false";
						}
					} else {
						if (lpReviewPendingList[i].pocStatus === "Approved") {
							lpReviewPendingList[i].pocflag = "true";
						}
					}
				}
			}
		}
		//kiran-4745-removing console warning-changed == to ===
		if (this.state.count === 0) {
			formIsValid = false;
			errors["errorfeedback"] = (
				<li>Please Select Minimum One Status For Any Publisher</li>
			);
			this.setState({
				errors: errors,
				reerrors: "",
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		} else {
			//kiran-4745-removing console warning-changed var to let
			for (let i = 0; i < lpReviewPendingList.length; i++) {
				if (
					lpReviewPendingList[i].pocflag === "false" ||
					lpReviewPendingList[i].lpflag === "false" ||
					lpReviewPendingList[i].csflag === "false"
				) {
					formIsValid = false;
					errors["rejectedfeedback"] = (
						<li>Please Fill Feedback Or Upload a Feedback File</li>
					);
					this.setState({
						errors: errors,
						reerrors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					break;
				} else {
					formIsValid = true;
					this.setState({ alertBorder: "0px solid white" });
				}
			}
		}

		return formIsValid;
	} // end of validateForm
	/**
	 * @author Somnath Keswad
	 * @param  Description Task-3832, Create UpdateAPI Function
	 * @return response for success
	 */
	UpdateAPI(campID) {
		//Status Update Only
		fetch("/publisher/agencyLPAndPOCApproved?campID=" + campID)
			.then((res) => res.json())
			.then((response) => { })
			.catch(e => console.log(e));
	}
		/**
	 * @author Somnath Keswad
	 * @param  Description Submit Lp
	 * @return response for success
	 */
	submitLpReviewFeedback(e) {
		e.preventDefault();
		if (this.validateForm(this.state.lpReviewPendingList) === false) {
			return;
		} else {
			//karan-task-3715-replace query params
			let parentCampID, reallocationID;
			if (this.props.location.state !== undefined) {
				parentCampID = this.props.location.state.parentCampID;
				reallocationID = this.props.location.state.reallocationID;
			}


			let data = {
				campID: this.state.campID,
				parentCampID: parentCampID,
				reallocationID: reallocationID,
				status: this.state.status,
				clientCampID:
					this.state.lpReviewPendingListCampIDOnNextPage[0].clientCampID,
				dynamicArray: this.state.lpReviewPendingList,
				approvalFor: this.state.approvalFor,
			}; // karan-task-3714-vapt header and query params

			fetch("/publisher/submitLandingPageFeedback", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			}).then((res) => res.json())
				.then((res) => {

					if (res.success === true) {
						this.setState({ btnDisable: "disabled1" });
						this.setState({ alertDisplay: "none" });
						Swal.fire({
							text: res.message,
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
									this.apiCall(this.state.campID, this.state.pID);
								    this.UpdateAPI(this.state.campID)
							},
						 });
					}
				}).catch(e => console.log(e))
		}
	}



	submitLPFeedbackFile(i, e) {
		e.preventDefault();
		//karan-task-3715-replace query params
		let campID;
		if (this.props.location.state !== undefined) {
			campID = this.props.location.state.campID;
		}
		var supportDocID = e.target.id;
		var pID = e.target.getAttribute("pID");
		if (
			document.getElementById("fileName" + supportDocID + pID) != null ||
			document.getElementById("fileName" + supportDocID + pID) != undefined
		) {
			document.getElementById("fileName" + supportDocID + pID).innerHTML =
				""; /**** this is making empty for hide the message No File Chosen because of in dynamic delete function it assign value No File Chosen */
		}

		var names = [];
		let filesData = e.target.files;
		if (filesData.length > 0) {
			var fileLP = filesData[0].name;
//kiran-4745-added .toString() and Number
			for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
				if (
					this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
					Number(pID) === this.state.lpReviewPendingList[i].pID
				) {
					this.state.lpReviewPendingList[i].lpFeedbackFileName = fileLP;
				}
			}
			this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });

			var FileExt;
			let file = e.target.files[0];
//kiran-4745-removing console warning-changed var to let
			for (let i = 0, l = filesData.length; i < l; i++) {
				names[i] = filesData[i].name;
				FileExt = names[i].split(".").pop();
				if (
					document.getElementById("lpFileError" + supportDocID + pID) !== null
				) {
					document.getElementById(
						"lpFileError" + supportDocID + pID
					).innerHTML = "";
				}
			}
			var arrayLength = names.length;
			var nooutput = document.getElementById(
				"lpFeedbackList" + supportDocID + pID
			);
			if (arrayLength > 0) {
				var allowedFiles = [
					".ppt",
					".doc",
					".pdf",
					".png",
					".jpeg",
					".pptx",
					".docx",
					".jpg",
				];
				//kiran-4745-removing console warning-changed == to ===
				if (
					FileExt === "doc" ||
					FileExt === "DOC" ||
					FileExt === "ppt" ||
					FileExt === "PPT" ||
					FileExt === "pdf" ||
					FileExt === "PDF" ||
					FileExt === "png" ||
					FileExt === "jpeg" ||
					FileExt === "pptx" ||
					FileExt === "PPTX" ||
					FileExt === "docx" ||
					FileExt === "DOCX" ||
					FileExt === "jpg" ||
					FileExt === "JPG" ||
					FileExt === "JPEG" ||
					FileExt === "PNG"
				) {
					nooutput.innerHTML = arrayLength + "  File Selected";
					var data = new FormData();
					data.append("campID", campID);
					data.append("file", file);
					data.append("pID", pID);
					data.append("supportDocID", supportDocID);

					fetch("agency/uploadLPFeedbackFile", {
						method: "POST",
						body: data,
					})
						.then(function (response) {
							if (response.status >= 400) {
								throw new Error("Bad response from server");
							}
							return response.json();
						})
						.then(function (response) {
							var msg = "Landing Page Feedback File Uploaded Successfully";
							var message = document.getElementById(
								"lpFileMessage" + supportDocID + pID
							);
							if (arrayLength > 0) {
								message.innerHTML = msg;
							} else {
								message.innerHTML = "";
							}

							document.getElementById(
								"lpFileError" + supportDocID + pID
							).innerHTML = "";
						})
						.catch(function (err) {
							console.log(err);
						});
					this.setState({
						files: data,
					});
				} else {
					var msg3 =
						"Please upload files having extensions: " +
						allowedFiles.join(", ") +
						" only";
					var lpmessageError = document.getElementById(
						"lpFileError" + supportDocID + pID
					);
					lpmessageError.innerHTML += msg3;
					//kiran-4745-added .toString and Number and changed == to ===
					for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
						if (
							this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
							Number(pID) === this.state.lpReviewPendingList[i].pID
						) {
							this.state.lpReviewPendingList[i].lpFeedbackFileName = "";
						}
					}
				}
			} else {
				nooutput.innerHTML = " No File Chosen";
			}

			$(document).on("click", ".removeFile", function (e) {
				e.preventDefault();

				var clickedFile = $(this).parent().children("a").data("fileid");

				var j = 0;
				for (j; j < names.length; j++) {
					var temp = j;

					if (names[temp] === clickedFile) {
						names.splice(j, 1);
						$(this).parent().remove();
						arrayLength = names.length;

						let data = {
							campID: campID,
							supportDocID: supportDocID,
							pID: pID,
						};

						document.getElementById(supportDocID).value = "";
						fetch("agency/LPFeedbackFileDelete", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						}).catch(function (err) {
							console.log(err);
						});

						if (arrayLength > 0) {
							nooutput.innerHTML = arrayLength + "  File Selected";
						} else {
							nooutput.innerHTML = " No File Chosen";
							$("#abm1").val("");
							var message = document.getElementById(
								"lpFileMessage" + supportDocID + pID
							);
							message.innerHTML = "";
						}
					} // end of  if(names[temp] === clickedFile)
				} // end of for
			}); //$(document).on
		}
		this.setState({ submitButtonAction: true }); //snehal-task-2878-Allocated lead links--creatives --submit button issue
	} // End of Submit LP_FeedbackFile

	deleteLPFeedback(e) {
		e.preventDefault();
		//karan-task-3715-replace query params
		let campID;
		if (this.props.location.state !== undefined) {
			campID = this.props.location.state.campID;
		}
		var supportDocID = e.target.id;
		var pID = e.target.getAttribute("pID");
		document.getElementById("fileName" + supportDocID + pID).innerHTML = "";
		let data = {
			campID: campID,
			supportDocID: supportDocID,
			pID: pID,
		};
		//kiran-4745-added .toString and Number and changed == to ===
		for (var i = 0; i < this.state.lpReviewPendingList.length; i++) {
			if (
				Number(supportDocID) === this.state.lpReviewPendingList[i].supportDocID &&
				Number(pID) === this.state.lpReviewPendingList[i].pID
			) {
				this.state.lpReviewPendingList[i].lpFeedbackFileName = "";
			}
		}
		this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });
		fetch("agency/LPFeedbackFileDelete", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}).catch(function (err) {
			console.log(err);
		});
		this.forceUpdate();
		var nooutput = document.getElementById(
			"lpFileMessage" + supportDocID + pID
		);
		nooutput.innerHTML = "";
		var nooutput1 = document.getElementById("fileName" + supportDocID + pID);
		nooutput1.innerHTML = "No File Chosen";
		this.setState({ submitButtonAction: true }); //snehal-task-2878-Allocated lead links--creatives --submit button issue
	} /****** End OF Delete LandingPAGE FeedBack File */

	submitPOCFeedbackFile(i, e) {
		e.preventDefault();
		// let pocerror = {};

		//karan-task-3715-replace query params
		let campID;
		if (this.props.location.state !== undefined) {
			campID = this.props.location.state.campID;
		}
		var supportDocID = e.target.id;

		var pID = e.target.getAttribute("pID");

		if (
			document.getElementById("POCFeedbackFileName" + supportDocID + pID) !=
			null ||
			document.getElementById("POCFeedbackFileName" + supportDocID + pID) !=
			undefined
		) {
			document.getElementById(
				"POCFeedbackFileName" + supportDocID + pID
			).innerHTML =
				""; /**** this is making empty for hide the message No File Chosen because of in dynamic delete function it assign value No File Chosen */
		}

		var names = [];
		var FileExt;
		let filesData = e.target.files;
		if (filesData.length > 0) {
			var filePoc = filesData[0].name;
			//kiran-4745-added .toString and Number and changed == to ===
			for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
				if (
					this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
					Number(pID) === this.state.lpReviewPendingList[i].pID
				) {
					this.state.lpReviewPendingList[i].pocFeedbackFileName = filePoc;
				}
			}
			this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });

			let file = e.target.files[0];
			for (let i = 0, l = filesData.length; i < l; i++) {
				names[i] = filesData[i].name;
				if (
					document.getElementById("pocFileError" + supportDocID + pID) !== null
				) {
					document.getElementById(
						"pocFileError" + supportDocID + pID
					).innerHTML = "";
				}
				FileExt = names[i].split(".").pop();
			}

			// var link;
			var filename = "";
			var arrayLength = names.length;
			var nooutput = document.getElementById(
				"POCFeedbackList" + supportDocID + pID
			);

			if (arrayLength > 0) {
				var allowedFiles = [
					".ppt",
					".doc",
					".pdf",
					".png",
					".jpeg",
					".pptx",
					".docx",
					".jpg",
				];
				//kiran-4745-removing console warning-changed == to ===
				if (
					FileExt === "doc" ||
					FileExt === "DOC" ||
					FileExt === "ppt" ||
					FileExt === "PPT" ||
					FileExt === "pdf" ||
					FileExt === "PDF" ||
					FileExt === "png" ||
					FileExt === "jpeg" ||
					FileExt === "pptx" ||
					FileExt === "PPTX" ||
					FileExt === "docx" ||
					FileExt === "DOCX" ||
					FileExt === "jpg" ||
					FileExt === "JPG" ||
					FileExt === "JPEG" ||
					FileExt === "PNG"
				) {
					nooutput.innerHTML = arrayLength + "  File Selected";
					var data = new FormData();
					data.append("campID", campID);
					data.append("file", file);
					data.append("pID", pID);
					data.append("supportDocID", supportDocID);

					fetch("agency/uploadPOCFeedbackFile", {
						method: "POST",
						body: data,
					})
						.then(function (response) {
							if (response.status >= 400) {
								throw new Error("Bad response from server");
							}
							return response.json();
						})
						.then(function (response) {
							var msg = "Newsletter Feedback File Uploaded Successfully";
							var message = document.getElementById(
								"pocFileMessage" + supportDocID + pID
							);

							if (arrayLength > 0) {
								message.innerHTML = msg;
							} else {
								message.innerHTML = "";
							}

							document.getElementById(
								"pocFileError" + supportDocID + pID
							).innerHTML = "";
						})
						.catch(function (err) {
							console.log(err);
						});
					this.setState({
						files: data,
						pocerror: "",
					});
				} else {
					var msg1 =
						"Please upload files having extensions: " +
						allowedFiles.join(", ") +
						" only";
					var messageError = document.getElementById(
						"pocFileError" + supportDocID + pID
					);
					messageError.innerHTML += msg1;
					//kiran-4745-added .toString and Number and changed == to ===
					for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
						if (
							this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
							Number(pID) === this.state.lpReviewPendingList[i].pID
						) {
							this.state.lpReviewPendingList[i].pocFeedbackFileName = "";
						}
					}
				}
			} else {
				nooutput.innerHTML = " No File Chosen";
			}

			$(document).on("click", ".removeFile", function (e) {
				e.preventDefault();
				var clickedFile = $(this).parent().children("a").data("fileid");
				var j = 0;
				for (j; j < names.length; j++) {
					var temp = j;

					if (names[temp] === clickedFile) {
						filename = names[temp];
						names.splice(j, 1);
						$(this).parent().remove();
						arrayLength = names.length;

						let data = {
							campID: campID,
							supportDocID: supportDocID,
							pID: pID,
						};

						document.getElementById(supportDocID).value = "";
						fetch("agency/POCFeedbackFileDelete", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						}).catch(function (err) {
							console.log(err);
						});

						if (arrayLength > 0) {
							nooutput.innerHTML = arrayLength + "  File Selected";
						} else {
							nooutput.innerHTML = " No File Chosen";
							$("#abm1").val("");
							var message = document.getElementById(
								"pocFileMessage" + supportDocID + pID
							);
							message.innerHTML = "";
						}
					} // end of  if(names[temp] === clickedFile)
				} // end of for
			}); //$(document).on
		}
	} // End of Submit POC_FeedbackFile

	submitCSFeedbackFile(i, e) {
		// let cserror = {};
		e.preventDefault();

		//karan-task-3715-replace query params
		let campID;
		if (this.props.location.state !== undefined) {
			campID = this.props.location.state.campID;
		}
		var supportDocID = e.target.id;
		var pID = e.target.getAttribute("pID");
		if (
			document.getElementById("CSFeedbackFileName" + supportDocID + pID) !=
			null ||
			document.getElementById("CSFeedbackFileName" + supportDocID + pID) !=
			undefined
		) {
			document.getElementById(
				"CSFeedbackFileName" + supportDocID + pID
			).innerHTML =
				""; /**** this is making empty for hide the message No File Chosen because of in dynamic delete function it assign value No File Chosen */
		}

		var names = [];
		let filesData = e.target.files;
		if (filesData.length > 0) {
			var fileCs = filesData[0].name;
			//kiran-4745-added .toString and Number and changed == to ===
			for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
				if (
					this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
					Number(pID) === this.state.lpReviewPendingList[i].pID
				) {
					this.state.lpReviewPendingList[i].csFeedbackFileName = fileCs;
				}
			}
			this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });

			let file = e.target.files[0];
			var FileExt;

			for (let i = 0, l = filesData.length; i < l; i++) {
				names[i] = filesData[i].name;
				if (
					document.getElementById("csFileError" + supportDocID + pID) !== null
				) {
					document.getElementById(
						"csFileError" + supportDocID + pID
					).innerHTML = "";
				}
				FileExt = names[i].split(".").pop();
			}

			// var link;
			var filename = "";
			var arrayLength = names.length;
			var nooutput = document.getElementById(
				"CSFeedbackList" + supportDocID + pID
			);

			if (arrayLength > 0) {
				var allowedFiles = [
					".ppt",
					".doc",
					".pdf",
					".png",
					".jpeg",
					".pptx",
					".docx",
					".jpg",
				];
				//kiran-4745-removing console warning-changed == to ===
				if (
					FileExt === "doc" ||
					FileExt === "DOC" ||
					FileExt === "ppt" ||
					FileExt === "PPT" ||
					FileExt === "pdf" ||
					FileExt === "PDF" ||
					FileExt === "png" ||
					FileExt === "jpeg" ||
					FileExt === "pptx" ||
					FileExt === "PPTX" ||
					FileExt === "docx" ||
					FileExt === "DOCX" ||
					FileExt === "jpg" ||
					FileExt === "JPG" ||
					FileExt === "JPEG" ||
					FileExt === "PNG"
				) {
					nooutput.innerHTML = arrayLength + "  File Selected";
					var data = new FormData();
					data.append("campID", campID);
					data.append("file", file);
					data.append("pID", pID);
					data.append("supportDocID", supportDocID);

					fetch("agency/uploadCSFeedbackFile", {
						method: "POST",
						body: data,
					})
						.then(function (response) {
							if (response.status >= 400) {
								throw new Error("Bad response from server");
							}
							return response.json();
						})
						.then(function (response) {

							var msg = "Call Script File Feedback File Uploaded Successfully";
							var message = document.getElementById(
								"csFileMessage" + supportDocID + pID
							);
							if (arrayLength > 0) {
								message.innerHTML = msg;
							} else {
								message.innerHTML = "";
							}

							document.getElementById(
								"csFileError" + supportDocID + pID
							).innerHTML = "";
						})
						.catch(function (err) {
							console.log(err);
						});
					this.setState({
						files: data,
						cserror: "",
					});
				} else {
					var msg2 =
						"Please upload files having extensions: " +
						allowedFiles.join(", ") +
						" only";
					var csmessageError = document.getElementById(
						"csFileError" + supportDocID + pID
					);
					csmessageError.innerHTML += msg2;
					//kiran-4745-added .toString and Number and changed == to ===
					for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
						if (
							this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
							Number(pID) === this.state.lpReviewPendingList[i].pID
						) {
							this.state.lpReviewPendingList[i].csFeedbackFileName = "";
						}
					}
				}
			} else {
				nooutput.innerHTML = " No File Chosen";
			}

			$(document).on("click", ".removeFile", function (e) {
				e.preventDefault();
				var clickedFile = $(this).parent().children("a").data("fileid");
				var j = 0;
				for (j; j < names.length; j++) {
					var temp = j;

					if (names[temp] === clickedFile) {
						filename = names[temp];
						names.splice(j, 1);
						$(this).parent().remove();
						arrayLength = names.length;

						let data = {
							campID: campID,
							supportDocID: supportDocID,
							pID: pID,
						};

						document.getElementById(supportDocID).value = "";
						fetch("agency/CSFeedbackFileDelete", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						}).catch(function (err) {
							console.log(err);
						});

						if (arrayLength > 0) {
							nooutput.innerHTML = arrayLength + "  File Selected";
						} else {
							nooutput.innerHTML = " No File Chosen";
							$("#abm1").val("");
							var message = document.getElementById(
								"csFileMessage" + supportDocID + pID
							);
							message.innerHTML = "";
						}
					} // end of  if(names[temp] === clickedFile)
				} // end of for
			}); //$(document).on
		}
	} // End of Submit CS_FeedbackFile

	deletePOCFeedback(e) {
		e.preventDefault();
		//karan-task-3715-replace query params
		let campID;
		if (this.props.location.state !== undefined) {
			campID = this.props.location.state.campID;
		}
		var supportDocID = e.target.id;
		var pID = e.target.getAttribute("pID");
		document.getElementById(
			"POCFeedbackFileName" + supportDocID + pID
		).innerHTML = "";
		let data = {
			campID: campID,
			supportDocID: supportDocID,
			pID: pID,
		};
		for (var i = 0; i < this.state.lpReviewPendingList.length; i++) {
			//kiran-4745-added .toString and Number and changed == to ===
			if (
				Number(supportDocID) === this.state.lpReviewPendingList[i].supportDocID &&
				Number(pID) === this.state.lpReviewPendingList[i].pID
			) {
				this.state.lpReviewPendingList[i].pocFeedbackFileName = "";
			}
		}
		this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });

		fetch("agency/POCFeedbackFileDelete", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}).catch(function (err) {
			console.log(err);
		});
		this.forceUpdate();

		document.getElementById("pocFileMessage" + supportDocID + pID).innerHTML =
			"";
		var nooutput1 = document.getElementById(
			"POCFeedbackFileName" + supportDocID + pID
		);
		nooutput1.innerHTML = "No File Chosen";
	} /****** End OF Delete LandingPAGE FeedBack File */

	deleteCSFeedback(e) {
		e.preventDefault();
		//karan-task-3715-replace query params
		let campID;
		if (this.props.location.state !== undefined) {
			campID = this.props.location.state.campID;
		}
		var supportDocID = e.target.id;
		var pID = e.target.getAttribute("pID");
		document.getElementById(
			"CSFeedbackFileName" + supportDocID + pID
		).innerHTML = "";
		let data = {
			campID: campID,
			supportDocID: supportDocID,
			pID: pID,
		};
		for (var i = 0; i < this.state.lpReviewPendingList.length; i++) {
			//kiran-4745-added  Number and changed == to ===
			if (
				Number(supportDocID) === this.state.lpReviewPendingList[i].supportDocID &&
				Number(pID) === this.state.lpReviewPendingList[i].pID
			) {
				this.state.lpReviewPendingList[i].csFeedbackFileName = "";
			}
		}
		this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });

		fetch("agency/CSFeedbackFileDelete", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}).catch(function (err) {
			console.log(err);
		});
		this.forceUpdate();

		document.getElementById("csFileMessage" + supportDocID + pID).innerHTML =
			"";
		var nooutput1 = document.getElementById(
			"CSFeedbackFileName" + supportDocID + pID
		);
		nooutput1.innerHTML = "No File Chosen";
	} /****** End OF Delete CS FeedBack File */

	/**
	 * @author Sandeep Dhawale
	 * @param  Description  get  Link of lpLink
	 * @return Description return open Link when i click on link
	 */
	lpReviewPendingList(e) {
		e.preventDefault();//Somnath Task-3832, Add PreventDefault method
		var lpLink = e.target.getAttribute("lpLink"); //Sandeep-task-2469-added code to get the link
		let assethttps = lpLink.includes("https://"); //Sandeep-task-2469-added code to check https content present or not
		let assethttp = lpLink.includes("http://"); //Sandeep-task-2469-added code to check http content present or not
		if (assethttps === true || assethttp === true) {
			window.open(lpLink); //Sandeep-task-2469-added code to open link
		} else {
			window.open("http://" + lpLink); //Sandeep-task-2469-added code to open link with http
		}
	} //end of lpReviewPendingList function
	/**
 * @author Somnath Keswad
 * @param  Description Task-3832 Create handleCampID function
 * @return get selected campId data.
 */
	handleCampID(e) {
		e.preventDefault()
		const campID = e.target.id;
		const pID = "All";
		this.setState({ campID })
		this.apiCall(campID, pID);
	}
	/**
	 * @author Somnath Keswad
	 * @param  Description Create HandleChangeSearch function
	 * @return maching data return.
	 */
	handleChangeSearch(e) {
		e.preventDefault();
		let currentList = [], campaignDetails = [];
		let searchData = e.target.value;
		// If the search bar isn't empty
		if (searchData === "") {
			campaignDetails = [...this.state.listCampID];
		} else {
			// Assign the original list to currentList
			currentList = [...this.state.listCampID];
			campaignDetails = currentList.filter((item) => {
				let obj = {};//Somnath Task-3972, Add specific Keys in search criteria
				obj[`campID`] = item.campID;
				// obj[`parentCampID`]=item.parentCampID;
				obj[`clientCampID`] = item.clientCampID;
				obj[`campaignName`] = item.campaignName;
				const filter = searchData.toLowerCase();
				var data = Object.values(obj);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
		}
		this.setState({ creativeCampList: [...campaignDetails], searchData });
	}//End of handleChangeSearch

	render() {



		return (
			<div>
				<Navigation />
				<div
					style={{ paddingBottom: "60px", paddingTop: "90px" }}
					class="container-fluid ">
					<div
						class="col-lg-12"
						style={{ display: this.state.alertDisplay }}></div>

					{/* Sandeep-task-3315-added marginTop property */}
					<div class="row" style={{ marginTop: "12px" }}>
						<div class="col-md-1">
							{this.state.backKey === "Dash" ? (
								//kiran-4745-removing console warning-added "#/"
								<a
								href="#/"
									onClick={(e) =>
										{
											e.preventDefault();
										this.props.history.push("/newdashboard", {
											agencyID: this.state.agencyID,
										})
	}} //karan-task-3684-replace query params
									style={{ color: "#056eb8" }}>
									<FaArrowAltCircleLeft
										size={32}
										style={{ float: "left" }}
										title="Back to Dashboard"
									/>
								</a>
							) : (
									<a
									href="#/"
										onClick={(e) =>
											{e.preventDefault();

											this.props.history.push("/agencyAllocatedLeads", {
												campID: this.state.campID,
												parentCampID: this.state.parentCampID,
												reallocationID: this.state.reallocationID,
												step: "1"
											})
										}}

										style={{ color: "#056eb8" }}>
										<FaArrowAltCircleLeft
											size={32}
											style={{ float: "left" }}
											title="Back to Dashboard"
										/>
									</a>
								)}
						</div>
						{/* //shivani-3285-passed ID for DI label consistency */}
						<div class="col-md-6" align="right">
							<label id="labelDI" className="CreativesReviewAgency">Creatives Review</label>			{/*Chaitanya-4107-Prod Issue-Agencyside-Creatives review-status shows wrong for call script */}
						</div>

						<div class="col-md-5">
							<select
								id="publisherName"
								name="publisherName"
								defaultValue={this.state.publisherName}
								value={this.state.pID}
								onChange={this.handleChange}
								class="form-control float-right AllAgency">				{/*Chaitanya-4107-Prod Issue-Agencyside-Creatives review-status shows wrong for call script */}
								{/* <option disabled>Select Publisher</option> */}
								<option value="All">All</option>
								{this.state.pubListForLPReview.map(
									(pubListForLPReview) => (
										<option value={pubListForLPReview.pID}>
											{pubListForLPReview.publisherName}{" "}
										</option>
									)
								)}
								;
												</select>
						</div>
					</div>
					<br />


					<div className="" style={{ display: this.state.alertDisplay }}>
						<div class="container-fluid">
							<div class="card-body">
								<div class="row" style={{ border: this.state.alertBorder }}>
									<div class="errorMessage">
										{this.state.errors.errorfeedback
											? this.state.errors.errorfeedback
											: ""}
										{this.state.errors.rejectedfeedback
											? this.state.errors.rejectedfeedback
											: ""}
										{this.state.errors.noDataFeedback
											? this.state.errors.noDataFeedback
											: ""}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-3" style={{ paddingRight: '0px', borderRadius: '0px' }}>
							<div style={{ border: '1px solid gray', backgroundColor: '#ffff' }}>
								<div  >
									<div class="card-body" style={{ borderRadius: '0px' }}>
									<div >
									{/* kiran - bug 4135- adjust width for responsiveness */}
										<label id="label" style={{ fontWeight: "500", fontSize: '16px',width:'235px' }}>  
											Campaign for Creatives Review :
										</label>
										</div>
										{/* <div class="row">
									<span >
											<input
												type="text"
												className=""
												name="search"
												placeholder="Search Campaign"
												style={{
													backgroundImage: "url(searchIcon.png)",
													backgroundPosition: "15px 6px",
													backgroundSize: "15px 15px",
													backgroundRepeat: "no-repeat",
													width: "280px",
													height: "28px",
													fontSize: "12px",
													// float: 'left',
													// marginLeft: '17px',
													border: "1px solid #CCCCCC",
													padding: "12px 20px 15px 36px",
													backgroundColor: "#FFFFFF",
													// marginBottom: '5px',
													// borderBottom: '1px solid #eee',
													fontFamily: "sans-serif",
													borderRadius: "2px",
												}}
												onChange={this.handleChangeSearch.bind(this)}
												onPressEnter={this.handleChangeSearch.bind(this)}
											></input>
										</span>
									</div> */}
									<div class="row">
										{/* kiran - bug 4135- added bootstrap class for responsiveness */}
									<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style={{paddingLeft:"1px"}}>
										{/* <span class="form-control"> */}
										<input
											type="text"
											class="form-control"
											className="inputstylesearch"
											name="search"
											placeholder="Search Campaign"
											style={{
												backgroundImage: "url(searchIcon.png)",
												backgroundPosition: "15px 6px",
												backgroundSize: "15px 15px",
												backgroundRepeat: "no-repeat",
												width: "280px",
												height: "28px",
												fontSize: "12px",
												marginLeft: "10px",
												border: "1px solid #CCCCCC",
												padding: "12px 10px 15px 36px",
												backgroundColor: "#FFFFFF",
												fontFamily: "sans-serif",
												borderRadius: "2px",
											}}
											onChange={this.handleChangeSearch.bind(this)}
												onPressEnter={this.handleChangeSearch.bind(this)}></input>
										{/* </span> */}
									</div>
								</div>
									</div>
									<div style={{ height: '351px', overflowY: "scroll" }}>
										{this.state.creativeCampList.length > 0 ?
											<div>

												{this.state.creativeCampList.map((creativeCampList) => (
													<table style={{ borderCollapse: "collapse", width: "100%" }}>
														<tbody>
														{/* kiran-4745-removing console warning-added "#/" and Number */}
															{Number(this.state.campID) === creativeCampList.campID ?
																<tr style={{ borderBottom: "2px solid #ccc", borderTop: "1px solid #ccc", backgroundColor: '#F1F2F8' }}>
																	<td style={{ height: "39px", borderLeft: "3px solid blue" }}> 	<a
																		style={{ padding: "5px 0px" }}
																		href="#/"
																		className="getcampId"
																		id={creativeCampList.campID}
																		onClick={this.handleCampID.bind(this)}>
																		&nbsp;&nbsp;{creativeCampList.campID} -{" "}
																		{/* {creativeCampList.clientCampID ? creativeCampList.clientCampID : 0} -{" "} */}
																		{creativeCampList.campaignName}{" "}
																	</a></td>
																</tr>
																: <tr style={{ borderBottom: "1px solid #ccc", borderTop: "1px solid #ccc" }}>
																	<td style={{ height: "39px" }}> 	<a
																		style={{ padding: "5px 0px" }}
																		href="#/"
																		className="getcampId"
																		id={creativeCampList.campID}
																		onClick={this.handleCampID.bind(this)}>
																		&nbsp;&nbsp;&nbsp;{creativeCampList.campID} -{" "}
																		{/* {creativeCampList.clientCampID ? creativeCampList.clientCampID : 0} -{" "} */}
																		{creativeCampList.campaignName}{" "}
																	</a></td>
																</tr>}
														</tbody>
													</table>
													// <span 
													// // style={{borderLeft:"thick solid blue",height:"90px",left:"0%",padding: "12px 0px"}}
													// 	//id={"BGCOLOR"+creativeCampList.campID}
													// 	// class="vertical"
													// 	>
													// 	<a
													// 		style={{padding: "5px 0px"}}
													// 		href=""
													// 		className="reviewcampdata"
													// 		id={creativeCampList.campID}
													// 		onClick={this.handleCampID.bind(this)}>
													// 		{creativeCampList.campID} -{" "}
													// 		{creativeCampList.clientCampID ? creativeCampList.clientCampID : 0} -{" "}
													// 		{creativeCampList.campaignName}{" "}
													// 	</a><hr/>
													// </span>
												))}
											</div>
											: (
												<center>
													{this.state.searchData.length > 0 ?
														<label
															style={{
																color: "red",
																fontSize: "medium",
																marginTop: "34px",
															}}>
															No Data Exist
															</label>
														:
														<label></label>}
												</center>
											)}
									</div>

								</div>
							</div>
						</div>
						{/* kiran -bug 4135- adjust padding for responsiveness */}
						<div class="col-lg-9" style={{ paddingRight: "0px" }}>  
							<div style={{ border: '1px solid gray', backgroundColor: '#ffff' }}>
								<div class="card-body">
									<form
										method="POST"
										name="lpReview"
										onSubmit={this.submitLpReviewFeedback}>
										<div class="row">
											<div
												class="col-sm-12 col-md-6 col-lg-6"
												style={{ fontWeight: "500" }}>
												<label style={{ fontWeight: "500" }}>
													Campaign ID :{" "}
												</label>
												&nbsp;
												{this.state.campID}
											</div>

											<div class="col-sm-12 col-md-6 col-lg-6 ">

											</div>

											<div
												class="col-sm-12 col-md-12 col-lg-12 "
												style={{ fontWeight: "500" }}>
												<label style={{ fontWeight: "500" }}>
													Campaign Name :{" "}
												</label>
												&nbsp;
												{this.state.campaignName
													? this.state.campaignName
													: this.state.lpReviewPendingListCampIDOnNextPage.map(
														(lpReviewPendingListCampIDOnNextPage) => (
															<span>
																{
																	lpReviewPendingListCampIDOnNextPage.campaignName
																}
															</span>
														)
													)}
											</div>

											<div
												class="col"
												align="center"
												style={{ fontSize: "12px" }}>
												<label id="labelheading" style={{ color: "red" }}>
													{this.state.creativesMessage}
												</label>
											</div>
																
											<div class="table-responsive" style={{ height: "303px", overflowY: "scroll", marginRight: "3px", marginLeft: "3px", }}>
                                            
											{this.state.creativeCampList.length > 0 ?  //kiran-4485-added condition for if creativedetails present or not
												<div>
													{/* kiran-4745-removing console warning-changed == to === */}
												{this.state.tableStatus === "true" ? (
													<table 
													 class="table table-bordered table-striped"
													>
														<thead>
															<tr class="info">
																<th
																	class="table-header"
																	style={{ width: "10%", position: 'sticky', top: '0' }}>
																	Publisher Name
																</th>
																<th
																	class="table-header"
																	style={{ width: "20%", position: 'sticky', top: '0' }}>
																	Asset Name
																</th>
																{this.state.marketingChannel ===
																	"Email/Telemarketing" ||
																	this.state.marketingChannel ===
																	"EmailTelemarketing" ||
																	this.state.marketingChannel === "Email" ? (
																		<th
																			class="table-header"
																			style={{ width: "25%", position: 'sticky', top: '0' }}>
																			Landing Page
																	</th>																															
																	) : (
																		""
																	)}
																{this.state.marketingChannel ===
																	"Email/Telemarketing" ||
																	this.state.marketingChannel ===
																	"EmailTelemarketing" ||
																	this.state.marketingChannel === "Email" ? (
																		<th
																			class="table-header"
																			style={{ width: "25%", position: 'sticky', top: '0' }}>
																			Newsletter File
																	</th>
																	) : (
																		""
																	)}
																{this.state.marketingChannel ===
																	"Email/Telemarketing" ||
																	this.state.marketingChannel ===
																	"EmailTelemarketing" ||
																	this.state.marketingChannel ===
																	"TeleMarketing" ? (
																		<th
																			class="table-header"
																			style={{ width: "30%", position: 'sticky', top: '0' }}>
																			Call Script
																	</th>
																	) : (
																		""
																	)}
															</tr>
														</thead>

														<tbody>
															{this.state.lpReviewPendingList.map(
																(lpReviewPendingList, i) => {
																	return (
																		<tr>
																			{this.state.lpReviewPendingListCampIDOnNextPage.map(
																				(
																					lpReviewPendingListCampIDOnNextPage
																				) => (
																						<input
																							type="hidden"
																							id="campID"
																							name="campID"
																							value={
																								lpReviewPendingListCampIDOnNextPage.campID
																							}
																						/>
																					)
																			)}
																			<input
																				type="hidden"
																				id="supportDocID"
																				value={lpReviewPendingList.supportDocID}
																				name="supportDocID"
																			/>
																			<input
																				type="hidden"
																				id="pID"
																				value={lpReviewPendingList.pID}
																				name="pID"
																			/>

																			<td style={{ textAlign: "center" }}>
																				{lpReviewPendingList.publisherName}
																			</td>
																			<td
																				style={{
																					wordWrap: "break-word",
																					wordBreak: "break-all",
																					whiteSpace: "normal",
																				}}>
																				{lpReviewPendingList.assetStatus ===
																					"Removed" ? (
																						<span
																							style={{
																								color: "red",
																								textDecoration: "line-through",
																							}}>
																							{lpReviewPendingList.suppDocName}
																						</span>
																					) : (
																						lpReviewPendingList.suppDocName
																					)}
																			</td>
																			{this.state.marketingChannel ===
																				"Email/Telemarketing" ||
																				this.state.marketingChannel ===
																				"EmailTelemarketing" ||
																				this.state.marketingChannel === "Email" ? (
																					lpReviewPendingList.assetStatus ===
																						"Removed" ? (
																							<td>
																								<span
																									style={{
																										color: "red",
																										textDecoration: "line-through",
																									}}>
																									{lpReviewPendingList.lpLink}
																								</span>{" "}
																							</td>
																						) : (
																							<td
																								style={{
																									wordWrap: "break-word",
																									wordBreak: "break-all",
																									whiteSpace: "normal",
																								}}>
																								{lpReviewPendingList.lpLink ? (
																									<div className="form-group"
																										style={{
																											paddingBottom: "14px", color: "#1890ff"
																										}}>
																										{/* Sandeep-task-2469-added code to open link when user click on link */}
																										{/* kiran-4745-removing console warning-added "#/" */}
																										<a
																										href="#/"
																											target="#"
																											onClick={this.lpReviewPendingList.bind(
																												this
																											)}
																											lpLink={
																												lpReviewPendingList.lpLink
																											}>
																											{lpReviewPendingList.lpLink}{" "}
																										</a>
																									</div>
																								) : (
																										""
																									)}
																								{lpReviewPendingList.lpLink ? (
																									<div>
																										{" "}
																										<div className="form-group">
																											{lpReviewPendingList.lp_status ===
																												"AgencyApproved" ||
																												lpReviewPendingList.lp_status ===
																												"AdvertiserApproved" ||
																												lpReviewPendingList.lp_status ===
																												"AdvertiserRejected" ? (
																													<span>
																														<label>Status :</label>
																														&nbsp;
																											{this.state.approvalFor ===
																															"Agency" &&
																															lpReviewPendingList.lp_status ===
																															"AgencyApproved"
																															? "Approved"
																															: this.state
																																.approvalFor ===
																																"Agency" &&
																																lpReviewPendingList.lp_status ===
																																"AdvertiserApproved"
																																? "Approved"
																																: this.state
																																	.approvalFor ===
																																	"Advertiser" &&
																																	lpReviewPendingList.lp_status ===
																																	"AgencyApproved"
																																	? "AdvertiserReviewPending"
																																	: this.state
																																		.approvalFor ===
																																		"Advertiser" &&
																																		lpReviewPendingList.lp_status ===
																																		"AdvertiserApproved"
																																		? "Approved"
																																		: lpReviewPendingList.lp_status}
																														<div
																															id={
																																"timeline" +
																																lpReviewPendingList.supportDocID +
																																lpReviewPendingList.pID +
																																"lpStatus"
																															}
																															style={{
																																display: "none",
																															}}>
																															Enter Feedback:
																												<textarea
																																id="lpFeedback"
																																name="lpFeedback"
																																defaultValue={
																																	lpReviewPendingList.lpFeedback
																																}
																																onChange={this.handleChangeLpFeedback.bind(
																																	this,
																																	i
																																)}
																																class="form-control"
																															/>
																															Upload a file:
																												<br />
																															<label class="btn chooseFile">
																																Choose File
																													<input
																																	type="file"
																																	id={
																																		lpReviewPendingList.supportDocID
																																	}
																																	pID={
																																		lpReviewPendingList.pID
																																	}
																																	style={{
																																		color:
																																			"transparent",
																																		display: "none",
																																	}}
																																	name="uploadLPFeedBack"
																																	class="fileupload"
																																	onChange={this.submitLPFeedbackFile.bind(
																																		this,
																																		i
																																	)}
																																/>
																															</label>
																															<div
																																id={
																																	"lpFeedbackList" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}>
																																{lpReviewPendingList.lpFeedbackFileName ? (
																																	<div
																																		id={
																																			"fileName" +
																																			lpReviewPendingList.supportDocID +
																																			lpReviewPendingList.pID
																																		}>
																																		1 File Chosen
																														</div>
																																) : (
																																		"No File Chosen"
																																	)}
																															</div>
																															<div
																																id={
																																	"lpFileList" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}
																																className="word-wrap"></div>
																															{lpReviewPendingList.lpFeedbackFileName ? (
																																<div
																																	id={
																																		"fileName" +
																																		lpReviewPendingList.supportDocID +
																																		lpReviewPendingList.pID
																																	}>
																																	<a
																																		href="#/"
																																		data-fileid={
																																			lpReviewPendingList.supportDocID +
																																			lpReviewPendingList.pID
																																		}
																																		class="removeFilePOC">
																																		<i
																																			class="fa fa-times"
																																			onClick={this.deleteLPFeedback.bind(
																																				this
																																			)}
																																			id={
																																				lpReviewPendingList.supportDocID
																																			}
																																			pID={
																																				lpReviewPendingList.pID
																																			}
																																			name={
																																				lpReviewPendingList.lpFeedbackFileName
																																			}
																																		/>
																																	</a>{" "}
																																	{/* kiran-4745-removing console warning-added "#/" */}
																																	{lpReviewPendingList.lpFeedbackFileName ? (
																																		<a
																																			href="#/"
																																			id={
																																				lpReviewPendingList.campID
																																			}
																																			supportDocID={
																																				lpReviewPendingList.supportDocID
																																			}
																																			pID={
																																				lpReviewPendingList.pID
																																			}
																																			feedbackFileName={
																																				lpReviewPendingList.lpFeedbackFileName
																																			}
																																			type="LP"
																																			onClick={this.FeedbackFileDownload.bind(
																																				this
																																			)}>
																																			{
																																				lpReviewPendingList.lpFeedbackFileName
																																			}
																																		</a>
																																	) : (
																																			""
																																		)}
																																</div>
																															) : (
																																	""
																																)}
																															<div
																																id={
																																	"lpFileError" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}
																																style={{
																																	color: "red",
																																}}></div>
																															<div
																																id={
																																	"lpFileMessage" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}
																																style={{
																																	color: "green",
																																}}></div>
																														</div>
																													</span>
																												) : lpReviewPendingList.lp_status ===
																													"AdvertiserReviewPending" ? (
																														<span>
																															<label>Status :</label>	
																															&nbsp;AdvertiserReviewPending
																										</span>
																													) : (
																														<select
																															style={{ height: "60%" }}
																															id="lpStatus"
																															name="lpStatus"
																															supportDocID={
																																lpReviewPendingList.supportDocID
																															}
																															pID={
																																lpReviewPendingList.pID
																															}
																															defaultValue={
																																lpReviewPendingList.lpStatus
																															}
																															onChange={this.handleChangeLpFeedback.bind(
																																this,
																																i
																															)}>
																															<option value="Select">
																																--Select--
																											</option>
																															<option value="Approved">
																																Approved
																											</option>
																															<option value="Rejected">
																																Rejected
																											</option>
																														</select>
																													)}
																										</div>
																										<div
																											id={
																												"timeline" +
																												lpReviewPendingList.supportDocID +
																												lpReviewPendingList.pID +
																												"lpStatus"
																											}
																											style={{ display: "none" }}>
																											Enter Feedback:
																									<textarea
																												id="lpFeedback"
																												name="lpFeedback"
																												defaultValue={
																													lpReviewPendingList.lpFeedback
																												}
																												onChange={this.handleChangeLpFeedback.bind(
																													this,
																													i
																												)}
																												class="form-control"
																											/>
																											Upload a file:
																									<br />
																											<label class="btn chooseFile">
																												Choose File
																										<input
																													type="file"
																													id={
																														lpReviewPendingList.supportDocID
																													}
																													pID={
																														lpReviewPendingList.pID
																													}
																													style={{
																														color: "transparent",
																														display: "none",
																													}}
																													name="uploadLPFeedBack"
																													class="fileupload"
																													onChange={this.submitLPFeedbackFile.bind(
																														this,
																														i
																													)}
																												/>
																											</label>
																											<div
																												id={
																													"lpFeedbackList" +
																													lpReviewPendingList.supportDocID +
																													lpReviewPendingList.pID
																												}>
																												{lpReviewPendingList.lpFeedbackFileName ? (
																													<div
																														id={
																															"fileName" +
																															lpReviewPendingList.supportDocID +
																															lpReviewPendingList.pID
																														}>
																														1 File Chosen
																											</div>
																												) : (
																														"No File Chosen"
																													)}
																											</div>
																											<div
																												id={
																													"lpFileList" +
																													lpReviewPendingList.supportDocID +
																													lpReviewPendingList.pID
																												}
																												className="word-wrap"></div>
																											{lpReviewPendingList.lpFeedbackFileName ? (
																												<div
																													id={
																														"fileName" +
																														lpReviewPendingList.supportDocID +
																														lpReviewPendingList.pID
																													}>
																													<a
																														href="#/"
																														data-fileid={
																															lpReviewPendingList.supportDocID +
																															lpReviewPendingList.pID
																														}
																														class="removeFilePOC">
																														<i
																															class="fa fa-times"
																															onClick={this.deleteLPFeedback.bind(
																																this
																															)}
																															id={
																																lpReviewPendingList.supportDocID
																															}
																															pID={
																																lpReviewPendingList.pID
																															}
																															name={
																																lpReviewPendingList.lpFeedbackFileName
																															}
																														/>
																													</a>{" "}
																													{lpReviewPendingList.lpFeedbackFileName
																														? lpReviewPendingList.lpFeedbackFileName
																														: ""}
																												</div>
																											) : (
																													""
																												)}
																											<div
																												id={
																													"lpFileError" +
																													lpReviewPendingList.supportDocID +
																													lpReviewPendingList.pID
																												}
																												style={{
																													color: "red",
																												}}></div>
																											<div
																												id={
																													"lpFileMessage" +
																													lpReviewPendingList.supportDocID +
																													lpReviewPendingList.pID
																												}
																												style={{
																													color: "green",
																												}}></div>
																										</div>
																									</div>
																								) : (
																										""
																									)}
																							</td>
																						)
																				) : (
																					""
																				)}
																				{/* kiran-4745-removing console warning-changed == to === and added #/ */}
																			{this.state.marketingChannel ===
																				"Email/Telemarketing" ||
																				this.state.marketingChannel ===
																				"EmailTelemarketing" ||
																				this.state.marketingChannel === "Email" ? (
																					lpReviewPendingList.assetStatus ===
																						"Removed" ? (
																							<td>
																								<span
																									style={{
																										color: "red",
																										textDecoration: "line-through",
																									}}>
																									{lpReviewPendingList.pocFileName}
																								</span>{" "}
																							</td>
																						) : (
																							<td>
																								{lpReviewPendingList.pocFileName ? (
																									<div className="form-group">
																										<a
																											href="#/"
																											id={
																												lpReviewPendingList.campID
																											}
																											supportDocID={
																												lpReviewPendingList.supportDocID
																											}
																											pID={lpReviewPendingList.pID}
																											pocFileName={
																												lpReviewPendingList.pocFileName
																											}
																											onClick={this.handleFileDownload.bind(
																												this
																											)}>
																											{
																												lpReviewPendingList.pocFileName
																											}
																										</a>
																									</div>
																								) : (
																										""
																									)}
																								{lpReviewPendingList.pocFileName ? (
																									<div>
																										<div className="form-group">
																											{lpReviewPendingList.poc_status ===
																												"AgencyApproved" ||
																												lpReviewPendingList.poc_status ===
																												"AdvertiserApproved" ||
																												lpReviewPendingList.poc_status ===
																												"AdvertiserRejected" ? (
																													<span>
																														<label>Status :</label>
																														&nbsp;
																											{this.state
																															.approvalFor ===
																															"Agency" &&
																															lpReviewPendingList.poc_status ===
																															"AgencyApproved"
																															? "Approved"
																															: this.state
																																.approvalFor ===
																																"Agency" &&
																																lpReviewPendingList.poc_status ===
																																"AdvertiserApproved"
																																? "Approved"
																																: this.state
																																	.approvalFor ===
																																	"Advertiser" &&
																																	lpReviewPendingList.poc_status ===
																																	"AgencyApproved"
																																	? "AdvertiserReviewPending"
																																	: this.state
																																		.approvalFor ===
																																		"Advertiser" &&
																																		lpReviewPendingList.poc_status ===
																																		"AdvertiserApproved"
																																		? "Approved"
																																		: lpReviewPendingList.poc_status}
																														<div
																															id={
																																"timeline" +
																																lpReviewPendingList.supportDocID +
																																lpReviewPendingList.pID +
																																"pocStatus"
																															}
																															style={{
																																display: "none",
																															}}>
																															Enter Feedback:
																												<textarea
																																id="pocFeedback"
																																name="pocFeedback"
																																defaultValue={
																																	lpReviewPendingList.pocFeedback
																																}
																																onChange={this.handleChangeLpFeedback.bind(
																																	this,
																																	i
																																)}
																																class="form-control"
																															/>
																															Upload a file:
																												<br />
																															<label class="btn chooseFile">
																																Choose File
																													<input
																																	type="file"
																																	id={
																																		lpReviewPendingList.supportDocID
																																	}
																																	pID={
																																		lpReviewPendingList.pID
																																	}
																																	style={{
																																		color:
																																			"transparent",
																																		display: "none",
																																	}}
																																	name="uploadLPFeedBack"
																																	class="fileupload"
																																	onChange={this.submitPOCFeedbackFile.bind(
																																		this,
																																		i
																																	)}
																																/>
																															</label>
																															<div
																																id={
																																	"POCFeedbackList" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}>
																																{lpReviewPendingList.pocFeedbackFileName ? (
																																	<div
																																		id={
																																			"POCFeedbackFileName" +
																																			lpReviewPendingList.supportDocID +
																																			lpReviewPendingList.pID
																																		}>
																																		1 File Chosen
																														</div>
																																) : (
																																		"No File Chosen"
																																	)}
																															</div>
																															<div
																																id={
																																	"pocFileList" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}
																																className="word-wrap"></div>
																															{lpReviewPendingList.pocFeedbackFileName ? (
																																<div
																																	id={
																																		"POCFeedbackFileName" +
																																		lpReviewPendingList.supportDocID +
																																		lpReviewPendingList.pID
																																	}>
																																	<a
																																		href="#/"
																																		class="removeFilePOC">
																																		<i
																																			class="fa fa-times"
																																			onClick={this.deletePOCFeedback.bind(
																																				this
																																			)}
																																			id={
																																				lpReviewPendingList.supportDocID
																																			}
																																			pID={
																																				lpReviewPendingList.pID
																																			}
																																			name={
																																				lpReviewPendingList.pocFeedbackFileName
																																			}
																																		/>
																																	</a>{" "}
																																	{lpReviewPendingList.pocFeedbackFileName ? (
																																		<a
																																			href="#/"
																																			id={
																																				lpReviewPendingList.campID
																																			}
																																			supportDocID={
																																				lpReviewPendingList.supportDocID
																																			}
																																			pID={
																																				lpReviewPendingList.pID
																																			}
																																			feedbackFileName={
																																				lpReviewPendingList.pocFeedbackFileName
																																			}
																																			type="POC"
																																			onClick={this.FeedbackFileDownload.bind(
																																				this
																																			)}>
																																			{
																																				lpReviewPendingList.pocFeedbackFileName
																																			}
																																		</a>
																																	) : (
																																			""
																																		)}
																																</div>
																															) : (
																																	""
																																)}
																															<div
																																id={
																																	"pocFileError" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}
																																style={{
																																	color: "red",
																																}}></div>
																															<div
																																id={
																																	"pocFileMessage" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}
																																style={{
																																	color: "green",
																																}}></div>
																														</div>
																													</span>
																												) : lpReviewPendingList.poc_status ===
																													"AdvertiserReviewPending" ? (
																														<span>
																															<label>Status :</label>
																															&nbsp;AdvertiserReviewPending
																										</span>
																													) : (
																														<select
																															style={{ height: "60%" }}
																															id="pocStatus"
																															name="pocStatus"
																															supportDocID={
																																lpReviewPendingList.supportDocID
																															}
																															pID={
																																lpReviewPendingList.pID
																															}
																															defaultValue={
																																lpReviewPendingList.pocStatus
																															}
																															onChange={this.handleChangeLpFeedback.bind(
																																this,
																																i
																															)}>
																															<option value="Select">
																																--Select--
																											</option>
																															<option value="Approved">
																																Approved
																											</option>
																															<option value="Rejected">
																																Rejected
																											</option>
																														</select>
																													)}
																											<div
																												id={
																													"timeline" +
																													lpReviewPendingList.supportDocID +
																													lpReviewPendingList.pID +
																													"pocStatus"
																												}
																												style={{ display: "none" }}>
																												Enter Feedback:
																										<textarea
																													id="pocFeedback"
																													name="pocFeedback"
																													defaultValue={
																														lpReviewPendingList.pocFeedback
																													}
																													onChange={this.handleChangeLpFeedback.bind(
																														this,
																														i
																													)}
																													class="form-control"
																												/>
																												Upload a file:
																										<br />
																												<label class="btn chooseFile">
																													Choose File
																											<input
																														type="file"
																														id={
																															lpReviewPendingList.supportDocID
																														}
																														pID={
																															lpReviewPendingList.pID
																														}
																														style={{
																															color: "transparent",
																															display: "none",
																														}}
																														name="uploadLPFeedBack"
																														class="fileupload"
																														onChange={this.submitPOCFeedbackFile.bind(
																															this,
																															i
																														)}
																													/>
																												</label>
																												<div
																													id={
																														"POCFeedbackList" +
																														lpReviewPendingList.supportDocID +
																														lpReviewPendingList.pID
																													}>
																													{lpReviewPendingList.pocFeedbackFileName ? (
																														<div
																															id={
																																"POCFeedbackFileName" +
																																lpReviewPendingList.supportDocID +
																																lpReviewPendingList.pID
																															}>
																															1 File Chosen
																												</div>
																													) : (
																															"No File Chosen"
																														)}
																												</div>
																												<div
																													id={
																														"pocFileList" +
																														lpReviewPendingList.supportDocID +
																														lpReviewPendingList.pID
																													}
																													className="word-wrap"></div>
																												{lpReviewPendingList.pocFeedbackFileName ? (
																													<div
																														id={
																															"POCFeedbackFileName" +
																															lpReviewPendingList.supportDocID +
																															lpReviewPendingList.pID
																														}>
																														<a
																															href="#/"
																															class="removeFilePOC">
																															<i
																																class="fa fa-times"
																																onClick={this.deletePOCFeedback.bind(
																																	this
																																)}
																																id={
																																	lpReviewPendingList.supportDocID
																																}
																																pID={
																																	lpReviewPendingList.pID
																																}
																																name={
																																	lpReviewPendingList.pocFeedbackFileName
																																}
																															/>
																														</a>{" "}
																														{lpReviewPendingList.pocFeedbackFileName
																															? lpReviewPendingList.pocFeedbackFileName
																															: ""}
																													</div>
																												) : (
																														""
																													)}
																												<div
																													id={
																														"pocFileError" +
																														lpReviewPendingList.supportDocID +
																														lpReviewPendingList.pID
																													}
																													style={{
																														color: "red",
																													}}></div>
																												<div
																													id={
																														"pocFileMessage" +
																														lpReviewPendingList.supportDocID +
																														lpReviewPendingList.pID
																													}
																													style={{
																														color: "green",
																													}}></div>
																											</div>
																										</div>
																									</div>
																								) : (
																										""
																									)}
																							</td>
																						)
																				) : (
																					""
																				)}
																				{/* kiran-4745-removing console warning-changed == to ===  and #/*/}

																			{this.state.marketingChannel ===
																				"Email/Telemarketing" ||
																				this.state.marketingChannel ===
																				"EmailTelemarketing" ||
																				this.state.marketingChannel ===
																				"TeleMarketing" ? (
																					lpReviewPendingList.assetStatus ===
																						"Removed" ? (
																							<td>
																								<span
																									style={{
																										color: "red",
																										textDecoration: "line-through",
																									}}>
																									{lpReviewPendingList.csFileName}
																								</span>{" "}
																							</td>
																						) : (
																							<td>
																								{lpReviewPendingList.csFileName ? (
																									<div className="form-group">
																										<a
																											href="#/"
																											id={
																												lpReviewPendingList.campID
																											}
																											supportDocID={
																												lpReviewPendingList.supportDocID
																											}
																											pID={lpReviewPendingList.pID}
																											csFileName={
																												lpReviewPendingList.csFileName
																											}
																											onClick={this.handleCSFileDownload.bind(
																												this
																											)}>
																											{
																												lpReviewPendingList.csFileName
																											}
																										</a>
																									</div>
																								) : (
																										""
																									)}
																								{lpReviewPendingList.csFileName ? (
																									<div>
																										<div className="form-group">
																											{lpReviewPendingList.cs_status ===
																												"AgencyApproved" ||
																												lpReviewPendingList.cs_status ===
																												"AdvertiserApproved" ||
																												lpReviewPendingList.cs_status ===
																												"AdvertiserRejected" ? (
																													<span>
																														<label>Status :</label>
																														&nbsp;
																											{this.state
																															.approvalFor ===
																															"Agency" &&
																															lpReviewPendingList.cs_status ===
																															"AgencyApproved"
																															? "Approved"
																															: this.state
																																.approvalFor ===
																																"Agency" &&
																																lpReviewPendingList.cs_status ===
																																"AdvertiserApproved"
																																? "Approved"
																																: this.state
																																	.approvalFor ===
																																	"Advertiser" &&
																																	lpReviewPendingList.cs_status ===
																																	"AgencyApproved"
																																	? "AdvertiserReviewPending"
																																	: this.state
																																		.approvalFor ===
																																		"Advertiser" &&
																																		lpReviewPendingList.cs_status ===
																																		"AdvertiserApproved"
																																		? "Approved"
																																		: lpReviewPendingList.cs_status}
																														<div
																															id={
																																"timeline" +
																																lpReviewPendingList.supportDocID +
																																lpReviewPendingList.pID +
																																"csStatus"
																															}
																															style={{
																																display: "none",
																															}}>
																															Enter Feedback:
																												<textarea
																																id="csFeedback"
																																name="csFeedback"
																																defaultValue={
																																	lpReviewPendingList.csFeedback
																																}
																																onChange={this.handleChangeLpFeedback.bind(
																																	this,
																																	i
																																)}
																																class="form-control"
																															/>
																															Upload a file:
																												<br />
																															<label class="btn chooseFile">
																																Choose File
																													<input
																																	type="file"
																																	id={
																																		lpReviewPendingList.supportDocID
																																	}
																																	pID={
																																		lpReviewPendingList.pID
																																	}
																																	style={{
																																		color:
																																			"transparent",
																																		display: "none",
																																	}}
																																	name="uploadCSFeedBack"
																																	class="fileupload"
																																	onChange={this.submitCSFeedbackFile.bind(
																																		this,
																																		i
																																	)}
																																/>
																															</label>
																															<div
																																id={
																																	"CSFeedbackList" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}>
																																{lpReviewPendingList.csFeedbackFileName ? (
																																	<div
																																		id={
																																			"CSFeedbackFileName" +
																																			lpReviewPendingList.supportDocID +
																																			lpReviewPendingList.pID
																																		}>
																																		1 File Chosen
																														</div>
																																) : (
																																		"No File Chosen"
																																	)}
																															</div>
																															<div
																																id={
																																	"csFileList" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}
																																className="word-wrap"></div>
																															{lpReviewPendingList.csFeedbackFileName ? (
																																<div
																																	id={
																																		"CSFeedbackFileName" +
																																		lpReviewPendingList.supportDocID +
																																		lpReviewPendingList.pID
																																	}>
																																	<a
																																		href="#/"
																																		class="removeFileCS">
																																		<i
																																			class="fa fa-times"
																																			onClick={this.deleteCSFeedback.bind(
																																				this
																																			)}
																																			id={
																																				lpReviewPendingList.supportDocID
																																			}
																																			pID={
																																				lpReviewPendingList.pID
																																			}
																																			name={
																																				lpReviewPendingList.csFeedbackFileName
																																			}
																																		/>
																																	</a>{" "}
																																	{lpReviewPendingList.csFeedbackFileName ? (
																																		<a
																																			href="#/"
																																			id={
																																				lpReviewPendingList.campID
																																			}
																																			supportDocID={
																																				lpReviewPendingList.supportDocID
																																			}
																																			pID={
																																				lpReviewPendingList.pID
																																			}
																																			feedbackFileName={
																																				lpReviewPendingList.csFeedbackFileName
																																			}
																																			type="CS"
																																			onClick={this.FeedbackFileDownload.bind(
																																				this
																																			)}>
																																			{
																																				lpReviewPendingList.csFeedbackFileName
																																			}
																																		</a>
																																	) : (
																																			""
																																		)}
																																</div>
																															) : (
																																	""
																																)}
																															<div
																																id={
																																	"csFileError" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}
																																style={{
																																	color: "red",
																																}}></div>
																															<div
																																id={
																																	"csFileMessage" +
																																	lpReviewPendingList.supportDocID +
																																	lpReviewPendingList.pID
																																}
																																style={{
																																	color: "green",
																																}}></div>
																														</div>
																													</span>
																												) : lpReviewPendingList.cs_status ===
																													"AdvertiserReviewPending" ? (
																														<span>
																															<label>Status :</label>
																															&nbsp;AdvertiserReviewPending
																										</span>
																													) : (
																														<select
																															style={{ height: "60%" }}
																															id="csStatus"
																															name="csStatus"
																															supportDocID={
																																lpReviewPendingList.supportDocID
																															}
																															pID={
																																lpReviewPendingList.pID
																															}
																															defaultValue={
																																lpReviewPendingList.csStatus
																															}
																															onChange={this.handleChangeLpFeedback.bind(
																																this,
																																i
																															)}>
																															<option value="Select">
																																--Select--
																											</option>
																															<option value="Approved">
																																Approved
																											</option>
																															<option value="Rejected">
																																Rejected
																											</option>
																														</select>
																													)}
																											<div
																												id={
																													"timeline" +
																													lpReviewPendingList.supportDocID +
																													lpReviewPendingList.pID +
																													"csStatus"
																												}
																												style={{ display: "none" }}>
																												Enter Feedback:
																										<textarea
																													id="csFeedback"
																													name="csFeedback"
																													defaultValue={
																														lpReviewPendingList.csFeedback
																													}
																													onChange={this.handleChangeLpFeedback.bind(
																														this,
																														i
																													)}
																													class="form-control"
																												/>
																												Upload a file: <br />
																												<label class="btn chooseFile">
																													Choose File
																											<input
																														type="file"
																														id={
																															lpReviewPendingList.supportDocID
																														}
																														pID={
																															lpReviewPendingList.pID
																														}
																														style={{
																															color: "transparent",
																															display: "none",
																														}}
																														name="uploadCSFeedBack"
																														class="fileupload"
																														onChange={this.submitCSFeedbackFile.bind(
																															this,
																															i
																														)}
																													/>
																												</label>
																												<div
																													id={
																														"CSFeedbackList" +
																														lpReviewPendingList.supportDocID +
																														lpReviewPendingList.pID
																													}>
																													{lpReviewPendingList.csFeedbackFileName ? (
																														<div
																															id={
																																"CSFeedbackFileName" +
																																lpReviewPendingList.supportDocID +
																																lpReviewPendingList.pID
																															}>
																															1 File Chosen
																												</div>
																													) : (
																															"No File Chosen"
																														)}
																												</div>
																												<div
																													id={
																														"csFileList" +
																														lpReviewPendingList.supportDocID +
																														lpReviewPendingList.pID
																													}
																													className="word-wrap"></div>
																												{lpReviewPendingList.csFeedbackFileName ? (
																													<div
																														id={
																															"CSFeedbackFileName" +
																															lpReviewPendingList.supportDocID +
																															lpReviewPendingList.pID
																														}>
																														<a
																															href="#/"
																															class="removeFileCS">
																															<i
																																class="fa fa-times"
																																onClick={this.deleteCSFeedback.bind(
																																	this
																																)}
																																id={
																																	lpReviewPendingList.supportDocID
																																}
																																pID={
																																	lpReviewPendingList.pID
																																}
																																name={
																																	lpReviewPendingList.csFeedbackFileName
																																}
																															/>
																														</a>{" "}
																														{lpReviewPendingList.csFeedbackFileName
																															? lpReviewPendingList.csFeedbackFileName
																															: ""}
																													</div>
																												) : (
																														""
																													)}
																												<div
																													id={
																														"csFileError" +
																														lpReviewPendingList.supportDocID +
																														lpReviewPendingList.pID
																													}
																													style={{
																														color: "red",
																													}}></div>
																												<div
																													id={
																														"csFileMessage" +
																														lpReviewPendingList.supportDocID +
																														lpReviewPendingList.pID
																													}
																													style={{
																														color: "green",
																													}}></div>
																											</div>
																										</div>
																									</div>
																								) : (
																										""
																									)}
																							</td>
																						)
																				) : (
																					""
																				)}
																		</tr>
																	);
																}
															)}
														</tbody>
													</table>
												) : (
													//  ""
													<table 
													class="table table-bordered table-striped">
														<thead>
															<tr class="info">
																<th
																	class="table-header"
																	style={{ width: "10%", position: 'sticky', top: '0' }}>
																	Publisher Name
																</th>
																<th
																	class="table-header"
																	style={{ width: "20%", position: 'sticky', top: '0' }}>
																	Asset Name
																</th>
															</tr>
														</thead>
													</table>
													
													)} 
													</div>

												    :(
												   ""
												   )}           
												

											</div>
											<div></div>
											{/* <label style={{color:"Red"}}>{this.state.tableStatus}&nbsp;&nbsp;</label>
                   <label style={{color:"Green"}}>{this.state.submitButtonAction}</label>  */}

				   {/* kiran-4485-added condition for if creativedetails present or not*/}
				   {this.state.creativeCampList.length > 0 ?
				   <div class="col" align="right">
				   {/* kiran-4745-removing console warning-changed == to === */}
											{this.state.tableStatus === "true" ? (
												this.state.submitButtonAction === false ? (
													<div class="col" align="right">
														<button
															class="btn add-button"
															type="submit"
															disabled
															style={{
																backgroundColor: "#144999",
																color: "white",
															}}
															className={this.state.submitButton}>
															{/* //snehal-task-2878-Allocated lead links--creatives --submit button issue */}
															Submit
														</button>
													</div>
												) : (
														<div class="col" align="right">
															<button
																class="btn add-button"
																type="submit"
																style={{
																	backgroundColor: "#144999",
																	color: "white",
																}}
																className={this.state.submitButton}>
																Submit
														</button>
														</div>
													)
											) : (
													""
													
												)}
												 </div>
												  :( "" )}  
										</div>{" "}
										{/*end of row*/}
									</form>
								</div>
							</div>
						</div>
						{/*end of card card-signin my-1*/}
					</div>
					{/*end of card-body*/}
					<Footer />
				</div>{" "}
				{/*end of outter container*/}
				<Footer />
			</div>
		); // end of return div // end of return()
	} // end of render()
} // end of agencyCreativesReview class
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AgencyCreativesReview.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(AgencyCreativesReview)
);
