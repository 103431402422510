/* Copyright(c) 2023 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/**
 * @file MBAdminNavPage 
 * @author  Aman Shaikh - 4660
 * @version 1.0
 * @section DESCRIPTION Navigation bar for multiple role users login switch Page 
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Akash-bug-4139

class Navigation extends React.Component {
  onLogout(e) {
    e.preventDefault();
    //Sonali-3809-logout from LiByAi page--placing removeCookies function befor userLogout
    fetch("/removeCookie", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => { })
      .catch((e) => console.log(e)); //Somnath Task-3936, Add catch block to catch error

    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    let data = {
      //user: user, //Akash-bug-4139 removed user
    };
    fetch("users/userLogout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => { })
      .catch((e) => console.log(e)); //Somnath Task-3936, Add catch block to catch error

    this.props.logoutUser(this.props.history);
    localStorage.removeItem("activeKey");
  }

  /**
	 * 
	 * @param  Description 5258-handles Add Country menu click
	 * @return Description– re-directs to new route /addCountry
	 */
	onAddCountry(e) {
		e.preventDefault();
		this.props.history.push("/addCountryMbAdmin")
		localStorage.removeItem("activeKey");
	} // end of onAddCountry function

  render() {
    const { isAuthenticated, user } = this.props.auth;
    return (
      <div>
        <nav
          className="navbar navbar-expand-md navbar-dark navbar-fixed-top shadow"
          id="navbar_navpage_font_css"
        >
          <img
            src="DemandIntegrateLogo_White.png"
            alt="Demand Integrate"
            height="50px"
            width="200px"
            id="navbar_navpage_logo_css"
          ></img>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Aman Task - 4660 -(Role & Name after login) PC/PNC-Login - Client Switch option */}
            {isAuthenticated ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;&nbsp;{" "}
                <b style={{ color: "#00FF00" }}>
                  {" "}
                  {user.firstName}&nbsp;{user.lastName}
                </b>
                &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;
                {user.role === "MBAdmin" ? "MBAdmin" : ""}
              </h5>
            )
            : (
              ""
            )}

            {/* Aman Task - 4660 -(LogOut Button) PC/PNC-Login - Client Switch option */}
            <div className="navbar-nav navbar-right">
                {user.role === "MBAdmin" ? (
                <>
                  <li class="nav-item dropdown">
                    <a class="abc nav-link dropdown-toggle dropbtn" id="navbardrop">
                    {/* Chaitanya-4751-Removed Console Warning   */}
                      {/* data-toggle="dropdown" */}
                      REPORT
                    </a>
                    <div class="dropdown-content dropdown-menu">
                      <a className="dropdown-item" href="./LeadFileTrackingReport">
                      Lead File Tracking Report
                      </a>
                      {/* Rutuja 4823  added drop down for bypassemaildomin validation*/}
                      <a className="dropdown-item" href="./bypassEmailDomainValidationReport">
                      Bypass Email Domain Validation Report
                      </a>
                    </div>           
                  </li>
                  {/* chaitanya-5258-Added Settings and 'Add City' Submenu */}
                  <li class="nav-item dropdown">
                    <a
                      href=" nav-link "
                      class="abc nav-link dropdown-toggle dropbtn"
                      style={{ color: "aliceblue" }}
                      id="navbardrop"
                      data-toggle="dropdown">
                      <span
                        class="	glyphicon glyphicon-cog"
                        style={{ color: "aliceblue" }}></span>
                    </a>
                    <div class="dropdown-content dropdown-menu">
                      <div>
                        <a
                          class="dropdown-item"
                          href="onAddCountry"
                          onClick={this.onAddCountry.bind(this)}>
                          Add City 
                        </a>
                      </div>
                    </div>
                  </li>
                </>
              ) : (
                ""
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;
                <li class="nav-item" style={{listStyle: "none", marginRight: "20px"}}>
                    <a
                        class="abc nav-link"
                        style={{ color: "aliceblue" }}
                        href="#/" //Chaitanya-4751-Removed Console Warning
                        onClick={this.onLogout.bind(this)}
                    >
                        {" "}
                        <span
                        class="glyphicon glyphicon-log-out"
                        style={{ color: "aliceblue" }}
                        ></span>
                    </a>
                </li>
            </div>
          </div>
        </nav>
     <Footer/> 
      </div>
    );
  }
}
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
Navigation.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(withRouter(Navigation));
