/* Copyright(c) 2022 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*@author Nilesh Zinzurao
*@fileName : linkingCampaign.js
@Creation Date:17-11-2022
 *Desc: This is page used for find duplicate lead for all campaign against same client. 
*/
import React from 'react';
import { Component } from 'react';
import Navigation from "../layouts/navPage";
import Footer from '../layouts/footer';
import '../advertiser/clientSetup.css';
import '../Reports/agencyMyReports.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { css } from "emotion";
import { Table, Tooltip, Icon } from 'antd';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3927-VAPT URL changes
import { json } from 'body-parser';
import { data } from 'jquery';
import $ from "jquery";//4535-Nilesh import jquery
import { Modal } from "react-bootstrap";
const Swal = require('sweetalert2');

const tableCSS = css({
	backgroundColor: "white",
	fontFamily: "sans-serif",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "13px",
		textAlign: "center !important",
		fontWeight: "500",
	},
	"& thead > tr >th": {
		textAlign: "center !important",
		color: "white",
		fontSize: "12px",
	},
});

class linkCampaign extends Component {
	constructor() {
		super()
		this.state = {
			errors: {},
			clientName: "",
			record: [],
			allClientNameDetails: [],
			validation_flag: 'Yes',

			no_of_days: "",
			ClientSetupArray: [{ clientName: "" }],
			alertDisplay: 'none',
			tableState: { size: "small" },
			reportNameValueEdit: ""
		}
		this.commonhandleFormChange = this.commonhandleFormChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.editForm = this.editForm.bind(this);
		this.handleValidateFlag = this.handleValidateFlag.bind(this);
		this.handleEnterDays = this.handleEnterDays.bind(this);
		this.editLinkCampaign = this.editLinkCampaign.bind(this);
		this.deleteLinkCampaign = this.deleteLinkCampaign.bind(this);
	}
	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push('/userLogin');
		}
		else {
			//rutuja task-4527 client list only linked with agency
			const { user } = this.props.auth
			fetch("/agencyLinkCampaign/allClientNameDetails")
				.then(res => res.json())
				.then((allClientNameDetails => {
					this.setState({ allClientNameDetails: allClientNameDetails })
				}))

			fetch("/agencyLinkCampaign/getLinkingCampaign")
				.then((res) => res.json())
				.then((linkCampaign) => {
					this.setState({ linkCampaign: linkCampaign });

				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	clientSetupBackButton(e) {
		const { user } = this.props.auth
		var adminID = user.id;
		this.props.history.push("/newdashboard");//4525-Nilesh Back to Admin dashbord
		e.preventDefault()
	}



	commonhandleFormChange(e) {
		e.preventDefault();
		const { name, value } = e.target
		if (name === "clientName") {
			document.getElementById('clientName').style.border = '1px solid lightgray';

			let ClientSetupArray = [...this.state.ClientSetupArray]
			ClientSetupArray[0] = { ...ClientSetupArray[0], ["clientName"]: value };
			this.state.ClientSetupArray = [...ClientSetupArray];
			this.setState({ ClientSetupArray: ClientSetupArray });
			this.setState({ clientName: value });
			this.setState({
				alertDisplay: 'none'
			})
		}
	}


	editLinkCampaign(e) {
		e.preventDefault();
		var clientName, no_of_days, validation_flag;
		clientName = e.target.getAttribute('clientName');
		no_of_days = e.target.getAttribute('no_of_days');
		validation_flag = e.target.getAttribute('validation_flag');
		this.setState({ clientName, no_of_days, validation_flag })
	}


	validateForm() {

		let errors = {};
		let formIsValid = true;
		let clientValidate = true;
		let daysTextValidate = true;
		let daysTextValidate1 = true;


		if (this.state.clientName == undefined || this.state.clientName == "" || this.state.clientName == null) {
			clientValidate = false;
			this.setState({
				inputclassClinet: 'invalid'
			})

		}
		else {
			clientValidate = true;
			this.setState({
				inputclassClinet: 'valid'
			})
		}



		if (this.state.no_of_days == "" || this.state.no_of_days == null || this.state.no_of_days == undefined) {
			daysTextValidate = false;
			this.setState({
				inputclasstax: 'invalid'
			})
		} else {


			var pattern = new RegExp(/^\s*(?=.*[0-9])\d*(?:\d{1,2})?\s*$/);
			if (!pattern.test(this.state.no_of_days)) {
				daysTextValidate1 = false;
				errors["Decimal"] = <li>Please Do Not Enter Decimal Value</li>
				this.setState({
					errors: errors,
					alertBorder: '1px solid red',
					alertDisplay: 'block'
				})
			}
			else {

				daysTextValidate1 = true;
				errors["common"] = "";
				this.setState({
					errors: errors,
					alertBorder: '0px solid white',
					alertDisplay: 'none'
				})

			}


		}


		if (clientValidate == false || daysTextValidate == false) {
			formIsValid = false;
			errors["common"] = <li>Please Fill all Required Fields</li>
			this.setState({
				errors: errors,
				alertBorder: '1px solid red',
				alertDisplay: 'block'
			})

		}
		else if (daysTextValidate1 == false) {
			formIsValid = false;
			errors["Decimal"] = <li>Please Do Not Enter Decimal Value</li>
			this.setState({
				errors: errors,
				alertBorder: '1px solid red',
				alertDisplay: 'block'
			})
		}
		else {
			formIsValid = true
			errors["common"] = "";
			this.setState({
				errors: errors,
				alertBorder: '0px solid white',
				alertDisplay: 'none'
			})

		}

		return formIsValid;
	}


	editValidateForm() {
		let errors = {};
		let formIsValid = true;
		let daysTextValidate = true;
		let daysTextValidate1 = true;

		if (this.state.no_of_days == "" || this.state.no_of_days == null || this.state.no_of_days == undefined) {
			daysTextValidate = false;
			this.setState({
				inputclasstax: 'invalid'
			})
		} else {


			var pattern = new RegExp(/^\s*(?=.*[0-9])\d*(?:\\d{1,2})?\s*$/);
			if (!pattern.test(this.state.no_of_days)) {
				daysTextValidate1 = false;
				errors["Decimal1"] = <li>Please Do Not Enter Decimal Value</li>
				this.setState({
					errors: errors,
					alertBorder: '1px solid red',
					alertDisplay: 'block'
				})
			}
			else {

			}


		}
      
		if (daysTextValidate == false) {
			formIsValid = false;
			errors["common1"] = <li>Please Fill all Required Fields</li>
			this.setState({
				errors: errors,
				alertBorder: '1px solid red',
				alertDisplay: 'block'
			})

		}
		else if (daysTextValidate1 == false) {
			formIsValid = false;
			errors["Decimal1"] = <li>Please Do Not Enter Decimal Value</li>
			this.setState({
				errors: errors,
				alertBorder: '1px solid red',
				alertDisplay: 'block'
			})
		}
		else {
			formIsValid = true
			errors["common1"] = "";
			this.setState({
				errors: errors,
				alertBorder: '0px solid white',
				alertDisplay: 'none'
			})

		}

		let no_of_days = this.state.no_of_days;

		this.setState({ no_of_days })
		if (no_of_days <= 0) {
			formIsValid = false;
			errors["common3"] = <li>Please enter no of days greater than 0</li>
			this.setState({
				errors: errors,
				alertBorder: '1px solid red',
				alertDisplay: 'block',
			})
		}else{}

			return formIsValid;
		}


		submitForm(e) {
			e.preventDefault();
			let errors = {};
			let formIsValid = true;
			if (this.validateForm() == false) {

				return;
			}
			else {

				let clientName = this.state.clientName;
				let validation_flag = this.state.validation_flag;
				let no_of_days = this.state.no_of_days;
				let data = {
					clientName: clientName,
					validateFlag: validation_flag,
					enterDays: no_of_days,

				}
				this.setState({ validation_flag, no_of_days, clientName })
				//rutuja task-4527 displaying error if 0 no of days selected
				if (no_of_days <= 0) {
					formIsValid = false;
					errors["common2"] = <li>Please enter no of days greater than 0</li>
					this.setState({
						errors: errors,
						alertBorder: '1px solid red',
						alertDisplay: 'block'
					})

				}
				else {
					fetch("/agencyLinkCampaign/linkCampaign", {
						method: "POST",
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then((res) => res.json())
						.then((res) => {


							if (res.success === true) {
								Swal.fire({
									text: ("Linking Campaign successfully"),
									type: "success",
									confirmButtonText: "Ok",
									allowOutsideClick: false,
									preConfirm: () => {
										window.location.reload();
									},
								});

							}
							if (res.failed === true) {
								Swal.fire({
									text: res.message,
									type: "error",
									confirmButtonText: "Ok",
									preConfirm: () => {
										window.location.reload();
									},
								});

							}
						})
				}
			}
		}

		handleValidateFlag(e) {
			var validation_flag = e.target.value;
			this.setState({ validation_flag: validation_flag });
		}


		handleEnterDays(e) {
			var no_of_days = e.target.value;
			var reportNameValueEdit = e.target.value
			this.setState({ reportNameValueEdit, no_of_days: no_of_days });
			this.setState({
				alertDisplay: 'none'
			})
		}


		editForm(e) {
			e.preventDefault();
			e.target.className += " was-validated";
			let errors = {};
			let formIsValid = true;
			if (this.editValidateForm() == false) {
				$("#incrementalModal1").modal("show");//4535-Nilesh used jquery for show modal
				return;
			}
			else {
				$("#incrementalModal1").modal("hide");//4535-Nilesh used jquery for show modal

				let clientName = this.state.clientName;
				let validation_flag = this.state.validation_flag;
				let no_of_days = this.state.no_of_days;


				let data = {

					validateFlag: validation_flag,
					enterDays: no_of_days,
					clientName: clientName,
				}

				this.setState({ validation_flag, no_of_days, clientName })

				//rutuja task-4527 displaying error if 0 no of days selected
				// if (no_of_days <= 0) {
				// 	formIsValid = false;
				// 	errors["common3"] = <li>Please enter no of days greater than 0</li>
				// 	this.setState({
				// 		errors: errors,
				// 		alertBorder: '1px solid red',
				// 		alertDisplay: 'block',
				// 	})

				// }
				// else {
					fetch("/agencyLinkCampaign/updateLinkCampaign", {
						method: "POST",
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then((res) => res.json())
						.then((res) => {

							if (res.success === true) {
								Swal.fire({
									text: ("Update Campaign successfully"),
									type: "success",
									confirmButtonText: "Ok",
									allowOutsideClick: false,
									dataDismiss: 'modal',
									preConfirm: () => {
										window.location.reload();
									},

								});

							}
							if (res.failed === true) {
								Swal.fire({
									text: res.message,
									type: "error",
									confirmButtonText: "Ok",
									preConfirm: () => {
										window.location.reload();
									},
								});

							}
						})
				}
			}
		//}


		deleteLinkCampaign(e) {
			const { user } = this.props.auth;
			var clientName = e.target.getAttribute('clientName');
			let data;

			data = {
				clientName: clientName,
			}

			fetch("/agencyLinkCampaign/deleteLinkingCampaign", {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data)
			}).then(res => res.json())
				.then(res => {
					if (res.success === true) {


						Swal.fire({
							text: "Deleted successfully.",
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: true,
							preConfirm: () => {

								window.location.reload();
							},
						});
					}
					else {
						Swal.fire({
							text: "Something went wrong while deleting report",
							type: "Failure",
							confirmButtonText: "Ok",
							allowOutsideClick: true,
							preConfirm: () => {

								window.location.reload();
							},
						});

					}
				}
				).catch(function (err) {
					console.log(err)
				});

		}

		

		render() {

			const asterisk =
			{
				color: 'red',
			}

			const columns1 = [
				{

					title: <span>Sr. No</span>,
					dataIndex: 'reportID',
					key: 'reportID',
					width: 6,

					align: 'center',
					render: (text, record) =>
						<div >
							<span style={{ fontSize: '13px', color: '#4F4F4F' }}>
								{record.srNO}
							</span>

						</div>
				},
				{
					title: <span>Client Name</span>,
					dataIndex: 'reportName',
					key: 'reportName',

					width: 30,
					align: 'center',

					render: (text, record) =>

						<div id="to_truncate_new1" style={{ fontSize: '13px', color: '#4F4F4F' }} >

							<a
								href={void (0)} style={{ color: "#1B81D8" }}
							>{record.clientName}</a>
						</div>
				},


				{
					title: <span>Validate</span>,
					dataIndex: 'type',
					key: 'type',
					width: 10,
					align: 'center',

					render: (text, record) =>
						<div >
							<span style={{ fontSize: '13px', color: '#4F4F4F' }}>
								{record.validation_flag}
							</span>
						</div>
				},
				{
					title: <span>Number Of Days</span>,
					dataIndex: 'created',
					key: 'created',
					width: 6,
					align: 'center',

					render: (text, record, i) =>
						<div style={{ fontSize: '13px', color: '#4F4F4F' }} >
							{record.no_of_days}
						</div>
				},

				{
					title: 'Action',
					dataIndex: 'action',
					key: 'action',
					width: 10,
					align: 'center',
					render: (text, record, i) =>

						<div >
							<span style={{ display: "inline-flex" }}>
								<a
									href="#/"
									// clientName={record.clientName}
									// no_of_days={record.no_of_days}
									// validation_flag={record.validation_flag}
									//onClick={this.editLinkCampaign.bind(this)}
									data-toggle="modal"
									data-target="#edit_report"
								//</span>onClick={this.editLinkCampaign.bind(this)}
								>

									<Tooltip placement="bottom" title="Edit">
										<img
											src="edit_report.png"
											alt=""
											width="20"
											height="20"
											clientName={record.clientName}
											no_of_days={record.no_of_days}
											validation_flag={record.validation_flag}
											onClick={this.editLinkCampaign}
										></img>
									</Tooltip>
								</a>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<a
									clientName={record.clientName}
									onClick={this.deleteLinkCampaign.bind(this)}
								>
									<Tooltip placement="bottom" title="Delete">
										<img
											src="file_upload_2_blue.png"
											data-toggle="modal"
											data-target="#delete_report"
											clientName={record.clientName}
											width="20"
											height="20"
											alt=""></img>
									</Tooltip>

								</a>

							</span>

						</div>


				}
			]

			return (

				<div className="main" style={{ overflowX: "hidden" }}>
					<Navigation />
					<div style={{ paddingTop: '100px' }}>
						<div class="row" >
							<div class="col-lg-1 col-md-1 col-sm-1" style={{ float: 'left', marginLeft: '40px' }}>
								< a class="col" href='#' style={{ color: '#056eb8', right: '33%', marginLeft: '80px' }} onClick={this.clientSetupBackButton.bind(this)} >
									<FaArrowAltCircleLeft size={32} title="Back to Dashboard" className="backFrom_salesforce" />
								</a>
							</div>
							<div class="col-lg-9 col-md-9 col-sm-9" align="center">
								<label style={{ fontSize: "22px", fontWeight: "bold", color: '#056eb8' }} >Link Client's Campaign</label>
							</div>
							<div class="col-lg-1 col-md-1 col-sm-1">
							</div>
						</div>
					</div>
					<div class="container" style={{ marginTop: '10px' }}>
						<div class="card" className="cardStep1">
							<form method="POST" id="clientSetup" name="clientSetup">
								<div class="row">
									<div class="col-lg-4 col-md-6 col-sm-12 ">
										<label className='labelStyle'>Client Name</label>
										<span className="asterisk"> *</span>
										<select id="clientName" style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px', width: '200px' }} name="clientName" value={this.state.ClientSetupArray[0].clientName}
											class="form-control inputStyle" required onChange={this.commonhandleFormChange}>
											<option value='' selected>Select Client</option>
											{this.state.allClientNameDetails.map(clientname => {
												return (
													<option value={clientname} >{clientname}</option>
												)
											})}
										</select>
									</div>
									<div class="col-lg-4 col-md-6 col-sm-12 ">
										<label className='labelStyle'>Number Of Days </label>
										<span className="asterisk"> *</span>
										<input type="number"
											className="form-control inputStyle"
											onChange={this.handleEnterDays}
											required
										>
										</input>

									</div>


									<div class="col-lg-4 col-md-6 col-sm-12">
										<label className='labelStyle'>Linking Campaign  </label>
										<span className="asterisk"> *</span>
										<select id="agencyName" name="agencyName"
											onChange={this.handleValidateFlag}
											class="form-control inputStyle" required
											style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px', width: '180px' }}>
											<option value='Yes' >Yes</option>
											<option value='No' >No</option>
										</select>

									</div>

								</div>
								<hr />
							</form>
							<div class="float-left">
								<div class="col-lg-12" style={{ display: this.state.alertDisplay }}>
									<div class="errorMessage">

										{this.state.errors.common}
										{/* rutuja task-4522 */}
										{this.state.errors.common2}
										{this.state.errors.Decimal}


									</div>
								</div>
							</div>
							<div class="float-right" style={{ marginRight: '50px' }}>

								<button className="btn add-button" value="submit" onClick={this.submitForm}
								>Submit</button>
							</div><br /><br />
						</div>
					</div>


					<Table {...this.state.tableState}
						// id="myTable1"
						bordered
						dataSource=
						{this.state.linkCampaign}
						columns={columns1}
						style={{ width: "83%", margin: "auto", marginBottom: "12px" }}


						className="ant-table-thead"
						className={tableCSS}
						scroll={{ x: 1200, y: 1333 }}
						pagination={{
							pageSize: this.state.pageSize,
							position: "bottom",
						}}>

					</Table>


					{/* 4535-Nilesh add id for hide or show modal */}
					<div id='incrementalModal1'> 
					<div class="modal fade" data-toggle="modal" id="edit_report" role="dialog" data-backdrop="static"
						data-keyboard="false" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
						<div class="modal-dialog modal-sm">
							<div class="modal-content" className="modal-content EditReportAgency" style={{ marginTop: '152px' }}>
								<div class="modal-header custom-class" style={{ backgroundColor: "#144999", height: '50px' }} >
									<div class="col-sm-12 head_crediv" style={{ color: '#FFFFFF', fontSize: '17px', fontWeight: '400 ', fontFamily: 'Roboto' }}>
										Edit Campaign Linking
										<button type="button" class="cancel-button" id="cancel-btnMr" style={{ fontColor: '#FFFFFF', float: 'right', fontSize: '18px', marginTop: '-5px', fontWeight: '400' }} data-dismiss="modal" onClick={() => window.location.reload()} >&times;</button>
									</div>
								</div>
		
								<div class="col-lg-12" style={{ display: this.state.alertDisplay }}>
									<div class="errorMessage">
										{this.state.errors.common1}
										{this.state.errors.common3}
										{this.state.errors.Decimal1}
										{/* {alert(this.state.errors.common1)} */}
									</div>
								</div>
								<form method="POST" id="clientSetup" name="clientSetup">
									<div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
										<label style={{ fontSize: '14px', color: "#193D8F" }}>Client Name : <span className='labelStyle'> {this.state.clientName}</span></label><br />
									</div>

									<div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
										<label style={{ fontSize: '14px', color: "#193D8F" }}>Linking Campaign <span className="asterisk"> *</span></label><br />
										<select id="agencyName" name="agencyName"
											value={this.state.validation_flag}
											onChange={this.handleValidateFlag}
											class="form-control inputStyle" required
											style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px', width: '180px' }}>
											<option value='Yes' selected>Yes</option>
											<option value='No' selected>No</option>
										</select>
									</div>

									<div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
										<label style={{ fontSize: '14px', color: "#193D8F" }}>Number Of Days <span className="asterisk"> * </span>
										</label><br />
										<input type="number"
											className="form-control inputStyle"
											value={this.state.no_of_days}
											onChange={this.handleEnterDays}
											required
										/>
									</div>
								</form>
								{/* {this.state.errors.common1} */}
								<div style={{ paddingTop: '20px', paddingLeft: '20px', paddingBottom: '30px' }}>
									<button type="button" data-dismiss="modal" class="btnMr infoMr" style={{ width: '116px', height: '34px', borderRadius: '2px' }} onClick={() => window.location.reload()} >Cancel</button>
									<span style={{ paddingLeft: '25px' }}><button type="button" class="btn" style={{
										width: "116px", height: "34px", borderRadius: '2px', backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"
										, color: " white"
									}}
										id="Edit_btnAgency"
										data-toggle="modal"
                                        data-target="#incrementalModal1"//4535-Nilesh add bootstrap property
										onClick={this.editForm}>Edit</button></span>
								</div>

							</div>

						</div>
					</div>
					</div>
					<Footer />
				</div>
			);
		}
	}
/**
	   * @author Nilesh Zinzurao
	   * @param  Description handle the login authentication
	   * @return Description return All details of authentication
	   */
linkCampaign.propTypes = {
		logoutUser: PropTypes.func.isRequired,
		auth: PropTypes.object.isRequired
	}

	const mapStateToProps = (state) => ({
		auth: state.auth
	})

export default connect(mapStateToProps, { logoutUser })(withRouter(linkCampaign));