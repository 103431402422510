/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*@author Sandeep Dhawale
*@fileName : AdvertiserDeliveryTrackingReport
@Creation Date:10-05-2021
 *Desc: 
*/
import React from "react";
// import { Tabs, Tab } from 'react-bootstrap';//kiran-4748-removing console warning-no used
import Navigation from "../layouts/navPage";
// import * as $ from "jquery";//kiran-4748-removing console warning-no used
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import './advertiserMyReports.css';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //kiran-task-3867-added default header
// import Loader from "../Reports/report_Loader";


import { css } from "emotion";
import { Table,} from 'antd';
// import { type } from "jquery";

const Swal = require('sweetalert2');

const tableCSS = css({
    backgroundColor: "white",
    fontFamily: "sans-serif",
    borderStyle: "bold",
    "& thead  > tr": {
        backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
        color: "white",
        fontSize: "12px",
        textAlign: "center !important",
        fontWeight: "500",
    },
    "& thead > tr >th": {
        textAlign: "center !important",
        color: "white",
    },
});
const queryString = require('query-string');


/**
* @author : Sandeep Dhawale-task 3448
* @description : created class component named as AdvertiserDeliveryTrackingReport
*/
class AdvertiserDeliveryTrackingReport extends React.Component {
    constructor() {
        super();
        this.state = {
            tableState: { size: "small" }, 
            agencyMyReportCampaignInfo: [], 
            agencyMyReportCampaignInfo1: [],
            agencyMyReportsDataNew1: [],
            agencyMyReportsData: [],
            reportSummary: [],
            agencyList: [],
            publisherList1: [],
            agencyID: "",
            agencyID1: "None Selected",
            campaignStatus: "None Selected",
            timeline: "None Selected",
            label: "", 
            reportKey: "",
            // reportKey1: "",
            // publisherName: "",  
            reportType: "Agency Campaign Delivery Tracking",//Chaitanya-4181Ref-(4099)-Advertiser-My report-report type not showing on listing page
            reportName: "",
            generateBtnFlag: false,
            token: "",
            loader: "",
            loader1: "",
            userType: "Adevetiser",
            agencyName:"",
            pageSize:100,//Sandeep-task-3500-added pageSize

        }
        /**
* @author : Sandeep Dhawale-task 3448
* @description : binded all handlechange here .
*/
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.onAgencyNameHandleChange = this.onAgencyNameHandleChange.bind(this);
        this.onCampaignStatusHandleChange = this.onCampaignStatusHandleChange.bind(this);
        this.ondeadlineHandleChange = this.ondeadlineHandleChange.bind(this);
        this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
        this.commonHandleChange = this.commonHandleChange.bind(this);
        this.generateHandleChange = this.generateHandleChange.bind(this);


     
    }
    /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange for pageSize .
*/
    handleChangePageSize(e) {
        var pageSize = e.target.value;
        this.setState({ pageSize: pageSize });
    }

    /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange for backbutton to navigate on same page from where user came .
*/
    backButtonHandleChange() {
        if (this.state.reportKey !== "report") {
            Swal.fire({
                title: 'Are you sure you want to discard this report?',
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                allowOutsideClick: false,
                confirmButtonText: "Discard",
            }).then((result) => {
                if (result.value) {
                    window.location.href = "/advertiserMyReports";
                }
                else if (result.dismiss === "close") {
                    window.location.href = "#";
                }
            })
       
     }
        else {
            window.location.href = "/advertiserMyReports";
        }//end of else for this.state.reportKey1

     
    }
    /**
* @author :Sandeep Dhawale-task 3448
* @description : handlechange to load data after selecting all three filters agency, campaign Status and timeline .
*/
    commonHandleChange(agencyID1, campaignStatus, timeline) {
        let reportSummary = [{ "agencyID": agencyID1, "agencyName": "", "deliveryLabel": timeline, "totalAllocation": 0, "totalAccepted": 0, "deliveredLead": 0, "rejectedLead": 0, "qaReviewLead": 0, "balanceLead": 0 }]
        this.setState({ agencyMyReportCampaignInfo: [], reportSummary, loader: "loader", generateBtnFlag: true })
        // this.loadingRef.current.click(); 
        // const { user } = this.props.auth;
        // const agencyID = user.id;
        // var advertiserID = user.id;
        // var agencyID = this.state.agencyID;
        // var agencyName=this.state.agencyName;//kiran-4748-removing console warning-no used
        
        // this.setState({ agencyID })
        //   let self=this;
        let data = {
            agencyID: this.state.agencyID,
            agencyName:this.state.agencyName,
            // advertiserID:advertiserID,
            campaignStatus: campaignStatus,
            timeLine: timeline,
            reportSummary:reportSummary
        }
        fetch("/advertiserReportEngine/getAgencyCampDetails", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                // authorization: this.state.token,
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(result => {

                // self.closeLoadingRef.current.click();
                this.setState({ agencyMyReportCampaignInfo: result.reportDetail, reportSummary: result.reportSummary, loader: "" })//
            }).catch(function (err) { console.log(err) });
    }
    /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange to target selected publisher .
*/
    onAgencyNameHandleChange(e) {

        var agencyID1 = e.target.value;

        this.setState({ agencyID1 })
        let campaignStatus = this.state.campaignStatus;
        let timeline = this.state.timeline;
        if ((agencyID1 && agencyID1 !== "None Selected") && (campaignStatus && campaignStatus !== "None Selected") && (timeline && timeline !== "None Selected")) {
            this.commonHandleChange(agencyID1, campaignStatus, timeline);
        }
        else {
            this.setState({ generateBtnFlag: false,agencyMyReportCampaignInfo:[]})

        }
    }
    /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange to target selected status .
*/
    onCampaignStatusHandleChange(e) {
        var campaignStatus = e.target.value;
        this.setState({ campaignStatus })
        let agencyID1 = this.state.agencyID1;
        let timeline = this.state.timeline;
        if ((campaignStatus && campaignStatus !== "None Selected") && (agencyID1 && agencyID1 !== "None Selected") && (timeline && timeline !== "None Selected")) {
            this.commonHandleChange(agencyID1, campaignStatus, timeline);
        }
        else {
            this.setState({ generateBtnFlag: false,agencyMyReportCampaignInfo:[]})
        }
    }
    /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange to target selected deadline .
*/
    ondeadlineHandleChange(e) {

        var timeline = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        //kiran-4748-removing console warning-changed == to === and use setState
        if (timeline === "All") {
            this.setState({label:"All"}); //this.state.label = "All"
        }
        else if (timeline === "No Lead Delivered") {
            this.setState({label:"No Lead Delivered"}); //this.state.label = "No Lead Delivered"
        }
        else if (timeline === "Ending Three Days") {
            this.setState({label:"Ending 3 days"}); // this.state.label = "Ending 3 days"
        }
        else if (timeline === "Weekly") {
            this.setState({label:"Ending This Week"}); //this.state.label = "Ending This Week"
        }
        else if (timeline === "Monthly") {
            this.setState({label:"Ending This Month"});//this.state.label = "Ending This Month"
        }
        else if (timeline === "Quarterly") {
            this.setState({label:"Ending This Quarter"}); //this.state.label = "Ending This Quarter"
        }
        else if (timeline === "Yearly") {
            this.setState({label:"Ending This Year"}); //this.state.label = "Ending This Year"
        }
        else if (timeline === "PreMonth") {
            this.setState({label:"Ending Previous Month"}); //this.state.label = "Ending Previous Month"
        }
        else if (timeline === "PreQuarter") {
            this.setState({label:"Ending Previous Quarter"}); // this.state.label = "Ending Previous Quarter"
        }
        else if (timeline === "PreYear") {
            this.setState({label:"Ending Previous Year"});// this.state.label = "Ending Previous Year"
        }
        this.setState({ timeline, label: label})
        this.setState({ timeline, label: e.nativeEvent.target[index].text})

        let { agencyID1, campaignStatus } = this.state;

        if ((timeline && timeline !== "None Selected") && (campaignStatus && campaignStatus !== "None Selected") && (agencyID1 && agencyID1 !== "None Selected")) {

            this.commonHandleChange(agencyID1, campaignStatus, timeline);
        }
        else {
            this.setState({ generateBtnFlag: false,agencyMyReportCampaignInfo:[]})
        }
    }
    /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange to generate report successfully .
*/
    generateHandleChange(e) {
        const { user } = this.props.auth;
        //const agencyID = user.id;
        // let advertiserID = user.id;//kiran-4748-removing console warning-no used
        // var pID =e.target.value;
        let agencyID1 = this.state.agencyID1;
        let timeline = this.state.timeline;
        let campaignStatus = this.state.campaignStatus;
        let reportDetail = [...this.state.agencyMyReportCampaignInfo]
        let reportSummary = [...this.state.reportSummary]
        let reportName = this.state.reportName;
        let type = this.state.reportType;                                   //Chaitanya-4181Ref-(4099)-Advertiser-My report-report type not showing on listing page
        let userType = this.state.userType;
        this.setState({  campaignStatus, timeline, user,  reportDetail, reportSummary, reportName, type, userType, loader1: "gen_loader" })
        if ((agencyID1 !== "None Selected") && (campaignStatus !== "None Selected") && (timeline !== "None Selected")) {
            let data = {
                // user: user,
               
                agencyID: agencyID1,
                deadline: timeline,
                campaignStatus: campaignStatus,
                type: type,
                reportName: reportName,
                reportSummary: reportSummary,
                reportDetail: reportDetail,
                userType: this.state.userType,
                // advertiserID:advertiserID,
            }
            // Sandeep-task-3479-change in API call as per BE changes
            fetch("/advertiserReportEngine/generateDeliveryReportForADV", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    // authorization: this.state.token,
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then((res) => {
                    this.setState({ loader1: "" })
                    if (res.success === true) {
                        Swal.fire({
                            title: '<span id="success_popUp">Report generated successfully.</span>',
                            html: "<span id='for_popUp_generate'>Please <a href='/advertiserMyReports'><span id='for_popUp_generate1'>click here</span></a> to check report.</span>",
                            type: "success",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                            preConfirm: () => {
                                window.location.href = "/advertiserMyReports";
                            },
                        });
                    }
                    if (res.success === false) {
                        Swal.fire({
                            title: 'Report data empty.',
                            type: "error",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                            preConfirm: () => {
                                // window.location.reload();
                            },
                        });
                    }

                }).catch(function (err) { console.log(err) });
        }
    }
    componentWillMount(e) {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
        }
        else {
            let reportID,reportKey,reportName;
            if (this.props.location.state !== undefined) {
                	 reportID = this.props.location.state.reportID; 
                
                // this.state.reportID=reportID;
                this.setState({reportID:reportID}); //kiran-4748-removing console warning-used setState
                 reportKey=this.props.location.state.reportKey
                //this.state.reportKey=reportKey
                	reportName = this.props.location.state.reportName; 
                //this.state.reportName=reportName
                 this.setState({reportKey:reportKey,reportName:reportName});//kiran-4748-removing console warning-used setState
                }

            var parsed = queryString.parse(this.props.location.search);
            // const { isAuthenticated } = this.props.auth;//kiran-4748-removing console warning-no used
            const { user } = this.props.auth;
            const token = user.token;
            // var reportKey = this.state.reportKey;//kiran-4748-removing console warning-no used
            // var reportKey1 = parsed.reportKey;
            // var reportID = parsed.reportID;//kiran-4748-removing console warning-no used

            var stackHolder="Advertiser";
            // let agencyID=parsed.agencyID;
            // const agencyID = user.id; //kiran-4748-removing console warning-no used
            // var pID = parsed.pID;
            var advertiserID=user.id;
            // var reportName = parsed.reportName;//kiran-4748-removing console warning-no use
            // var reportName = this.state.reportName;//kiran-4748-removing console warning-no used
            var type = parsed.reportType;
            this.setState({
                advertiserID, reportKey, generateBtnFlag: false,
                reportName, type, token
            })
            // alert("*****reportKey"+ this.state.reportKey)
            // if(this.state.reportKey==="report"){
           
            //     this.setState({ drpdisabled_report: "drpdisable_report"})
            // }
            // else{
            //  this.setState({ drpdisabled_report: ""})
            // }
            let data = {
                // advertiserID:advertiserID,
                reportID: reportID,
                stackHolder:stackHolder,
                // agencyID: agencyID, //kiran-4523-removed id
                
            }
            // if (reportID) {
                if (reportID) {
              fetch("/advertiserReportEngine/reportDetailForADV", {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        // authorization: token,
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json())
                    .then(result => {
                        let timeline, campaignStatus, agencyID1
                        if (result) {
                            agencyID1 = result.agencyID;
                            campaignStatus = result.campaignStatus;
                            timeline = result.deadline;
                        }

                    //   Sandeep-task-3485-added code for timeline issue
                    //kiran-4748-removing console warning-changed == to === and used setState
                        if (result.deadline !== "") {
                            if (result.deadline === "All") {
                                this.setState({label:"All"}); // this.state.label = "All"
                                 
                            }
                            else if (result.deadline === "No Lead Delivered") {
                                this.setState({label:"No Lead Delivered"}); // this.state.label = "No Lead Delivered"
                                
                            }
                            else if (result.deadline === "Ending Three Days") {
                                this.setState({label:"Ending 3 days"}); // this.state.label = "Ending 3 days"
                                
                            }
                            else if (result.deadline === "Weekly") {
                                this.setState({label:"Ending This Week"}); // this.state.label = "Ending This Week"
                                
                            }
                            else if (result.deadline === "Monthly") {
                                this.setState({label:"Ending This Month"});// this.state.label = "Ending This Month"
                                
                            }
                            else if (result.deadline === "Quarterly") {
                                this.setState({label:"Ending This Quarter"});// this.state.label = "Ending This Quarter"
                                
                            }
                            else if (result.deadline === "Yearly") {
                                this.setState({label:"Ending This Year"});// this.state.label = "Ending This Year"
                                
                            }
                            else if (result.deadline === "PreMonth") {
                                this.setState({label:"Ending Previous Month"});// this.state.label = "Ending Previous Month"
                               
                            }
                            else if (result.deadline === "PreQuarter") {
                                this.setState({label:"Ending Previous Quarter"});// this.state.label = "Ending Previous Quarter"
                                
                            }
                            else if (result.deadline === "PreYear") {
                                this.setState({label:"Ending Previous Year"}); // this.state.label = "Ending Previous Year"
                               
                            }
                        }

                        this.setState({ agencyMyReportCampaignInfo: result.reportDetail, reportSummary: result.reportSummary, agencyID1, timeline, campaignStatus, label: this.state.label, })
                    }).catch(function (err) { console.log(err) });
            }

            fetch("/advertiserReportEngine/getAgencyList", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    // authorization: token,
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
           
                .then(agencyList => {
                    this.setState({ agencyList: agencyList,agencyID:agencyList[0].agencyID,agencyName:agencyList[0].agencyName })
                }).catch(function (err) { console.log(err) });
        }
    }
    /**
* @author : Sandeep Dhawale-task 3448
* @description :Render part to display complete screen with functionality as per given screen .
*/
    render() {
        const asterisk =
        {
            color: 'red',
        }
        // let locale = {
        //     emptyText:"wait a moment while we are loading your data....",
        //  }; 

        const columnsReport = [
            {
                title: <span>Camp ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campID",
                key: "campID",
                width: "7.5%",

                align: "left",
                sorter: (a, b) => a.campID - b.campID,
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                         {/* Sandeep-task-3485-added font size */}
                        <span style={{ fontSize: "13px",color: '#4F4F4F' }}>
                            {record.campID}
                        </span>
                        <br />
                    </div>

                ),

            },
            {
                title: <span>Agency Camp ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "agencyID",
                key: "agencyID",
                width: "7%",
                align: "left",
                sorter: (a, b) => a.agencyID - b.agencyID,
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                         {/* Sandeep-task-3485-added font size */}
                        <span style={{ fontSize: "13px",color: '#4F4F4F' }}>
                            {record.agencyID}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Advertiser&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "clientName",
                key: "clientName",
                width: "8.5%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.clientName.toUpperCase() < b.clientName.toUpperCase()) {
                        return asc ? -1 : 1;
                    } else if (
                        a.clientName.toUpperCase() > b.clientName.toUpperCase()
                    ) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div id="to_truncate" style={{ width: "75px" }}>
                         {/* Sandeep-task-3485-added font size */}
                        <span style={{ fontSize: "13px",color: '#4F4F4F' }}>
                            {record.clientName}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Camp Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campaignName",
                key: "campaignName",
                width: "11%",

                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
                        return asc ? -1 : 1;
                    } else if (
                        a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
                    ) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div id="to_truncate1">
                         {/* Sandeep-task-3485-added font size */}
                        <span style={{ fontSize: "13px",color: '#4F4F4F' }}>
                            {record.campaignName}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Start Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "startDate",
                key: "startDate",
                width: "7%",

                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.startDate < b.startDate) {
                        return asc ? -1 : 1;
                    } else if (a.startDate > b.startDate) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                         {/* Sandeep-task-3485-added font size */}
                        <span style={{ fontSize: "13px",color: '#4F4F4F' }}>
                            {record.startDate}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>End Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "endDate",
                key: "endDate",
                width: "7%",

                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.endDate < b.endDate) {
                        return asc ? -1 : 1;
                    } else if (a.endDate > b.endDate) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                         {/* Sandeep-task-3485-added font size */}
                        <span style={{ fontSize: "13px",color: '#4F4F4F' }}>
                            {record.endDate}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Allocated Lead&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "allocatedLead",
                key: "allocatedLead",
                width: "7%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.allocatedLead < b.allocatedLead) {
                        return asc ? -1 : 1;
                    } else if (a.allocatedLead > b.allocatedLead) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                         {/* Sandeep-task-3485-added font size */}
                        <span style={{ fontSize: "13px",color: '#4F4F4F' }}>
                            {record.allocatedLead}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: "Delivery Status",
                width: "70%",
                // align: "center",
                children: [
                    {
                        title: <span>Delivered&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "deliveredLead",
                        key: "deliveredLead",
                        align: "left",
                        width: "6%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.deliveredLead < b.deliveredLead) {
                                return asc ? -1 : 1;
                            } else if (a.deliveredLead > b.deliveredLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                            // Sandeep-task-3485-added font size
                            <div style={{ fontSize: "13px" ,color: '#4F4F4F'}}>
                                {record.deliveredLead}
                                {/* {record.deliveredLead > 0 ? ( */}
                                <a
                                    href="#/"

                                >

                                </a>
                                {/* ) : (
                                                    0
                                                )} */}
                            </div>
                        ),

                    },
                    {
                        title: <span>Review Pending&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "qaReviewLead",
                        key: "qaReviewLead",
                        align: "left",
                        width: "7%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.qaReviewLead < b.qaReviewLead) {
                                return asc ? -1 : 1;
                            } else if (a.qaReviewLead > b.qaReviewLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                             // Sandeep-task-3485-added font size
                            <div style={{ fontSize: "13px",color: '#4F4F4F' }}>
                                {record.qaReviewLead}
                                {/* {record.qaReviewLead > 0 ? ( */}
                                <a
                                    href="#/">

                                </a>
                                {/* ) : (
                                                    0
                                                )} */}
                            </div>
                        ),
                    },
                    {
                        title: <span>Accepted&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "acceptedLead",
                        key: "acceptedLead",
                        align: "left",
                        width: "6%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.acceptedLead < b.acceptedLead) {
                                return asc ? -1 : 1;
                            } else if (a.acceptedLead > b.acceptedLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                             // Sandeep-task-3485-added font size
                            <div style={{ fontSize: "13px",color: '#4F4F4F' }}>
                                {record.acceptedLead}
                            </div>
                        ),
                    },
                    {
                        title: <span>Rejected&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "rejectedLead",
                        key: "rejectedLead",
                        align: "left",
                        width: "6%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.rejectedLead < b.rejectedLead) {
                                return asc ? -1 : 1;
                            } else if (a.rejectedLead > b.rejectedLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                             // Sandeep-task-3485-added font size
                            <div style={{ fontSize: "13px",color: '#4F4F4F' }}>
                                {record.rejectedLead}
                            </div>
                        ),
                    },
                    {
                        title: <span>Balance&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "balancedLead",
                        key: "balancedLead",
                        align: "left",
                        width: "6%",
                        //defaultSortOrder: 'dscend',
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.balancedLead < b.balancedLead) {
                                return asc ? -1 : 1;
                            } else if (a.balancedLead > b.balancedLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render(text, record) {

                            return {
                                props: {
                                    style: { wordWrap: "break-word", height: "5%", width: "6%" },
                                },
                                children: (
                                     // Sandeep-task-3485-added font size
                                    <div style={{ fontSize: "13px" ,color: '#4F4F4F'}}>
                                        {/* {" "} */}{record.balancedLead}
                                        {/* {record.balancedLead ? record.balancedLead : 0} */}
                                    </div>
                                ),
                            };
                        },
                    },
                ],

            },


        ]


        function onChange(pagination, filters, sorter) {
            console.log("params", pagination, filters, sorter);
        }

        return (
            <div>
                <Navigation />
                <div style={{ paddingBottom: '60px', paddingTop: '100px' }} class="container-fluid ">
                    <div class="row">
                        <div class="col-sm-12 col-md-1 col-lg-2" >
                        {/* kiran-4748-removing console warning-added "#/" */}
                            <a href="#/" onClick={this.backButtonHandleChange} style={{ color: '#056eb8' }}><FaArrowAltCircleLeft size={32} title="Back to advertiserMyReport" /></a>

                        </div>
                        <div class=" col-sm-12 col-md-8 col-lg-8" align="center">
                            <label id="labelDI" >Agency Delivery Tracking Report</label>
                        </div>
                        {/* Sandeep-task-3485-added code for alignment issue */}
                        <div class="col-sm-12 col-md-3  col-lg-2 generateButtonAdv" id="deliveryTracking_generateReport">       {/*Chaitanya-4181Ref-(4099)-Advertiser-My report-deliveryTracking responsiveness*/}
                            {this.state.reportKey === "report" ?
                                "" :
                                this.state.generateBtnFlag === true ?
                                    <button class="btn add-button" type="button" data-toggle="modal" data-target="#generateReport"
                                        onClick={this.generateHandleChange} >Generate Report</button>
                                    :
                                    <button disabled class="btn add-button" type="button" data-toggle="modal" data-target="#generateReport">Generate Report</button>

                            }

                        </div>
                    </div>
                    <div class="row" style={{ paddingTop: '15px', paddingLeft: '25px', paddingBottom: '5px' }}>
                        <div class="col-sm-12 col-md-4 col-lg-3" id="deliveryTracking_Agency">                                          {/*Chaitanya-4181Ref-(4099)-Advertiser-My report-deliveryTracking responsiveness*/}
                            {/* Sandeep-task-3500-added font size ,color and cursor */}
                            <label style={{ fontSize: '14px',color:"#14254A" }}>Agency&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;</label>
                            {/* kiran-4748-added toString() and changed == to === */}
                            {this.state.reportKey === "report" ?
                            // Sandeep-task-3485-dropdown changes
                                <select disabled   value={this.state.agencyID} name="agencyName" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',backgroundColor:'#EEEEEE',fontSize:'14px',cursor: "not-allowed" }}  onChange={this.onAgencyNameHandleChange}>
                                    {/* Sandeep-task-3500 -added background color when dropdwon disable */}
                                    <option value={"None Selected"} >None Selected</option>
                                    {this.state.agencyList.map((agencyList, i) => (
                                        <option value={agencyList.agencyID} key={i}>{agencyList.agencyName}</option>))}
                                </select>
                                :
                                 // Sandeep-task-3485-dropdown changes
                                <select value={this.state.agencyID1} name="agencyName" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555',fontSize:'14px' }}  onChange={this.onAgencyNameHandleChange}>
                                    <option value={"None Selected"} >None Selected</option>
                                    {this.state.agencyList.map((agencyList, i) => (
                                        <option value={agencyList.agencyID} key={i}>{agencyList.agencyName}</option>))}
                                </select>

                            }
                        </div>&nbsp;
                        <div class="col-sm-12 col-md-4 col-lg-4" id="fr_mdl" style={{ marginLeft: "50px" }}>
                               {/* Sandeep-task-3500-added font size, color and cursor*/}
                            <label style={{ fontSize: '14px',color:"#14254A" }}>Campaign Status&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;</label>
                            {/* kiran-4748-added toString() and changed == to === */}
                            {this.state.reportKey === "report" ?
                             // Sandeep-task-3485-dropdown changes
                                <select disabled   name="CampaignStatus" id="CampStatDrop_Adv" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',backgroundColor:'#EEEEEE',fontSize:'14px', cursor: "not-allowed"}}     /*Chaitanya-4181 Ref-(4099)-Advertiser-My report-deliveryTracking responsiveness*/
                                //Sandeep-task-3500 -added background color when dropdwon disable
                                    value={this.state.campaignStatus} required onChange={this.onCampaignStatusHandleChange} >
                                    <option value={"None Selected"} >None Selected</option>
                                    <option value="Active">Active</option>
                                    <option value="Paused">Paused</option>
                                    <option value="Completed">Completed</option>
                                </select>
                                :
                                 // Sandeep-task-3485-dropdown changes
                                <select name="CampaignStatus" id="CampStatDrop_Adv" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555',fontSize:'14px' }}                                                /*Chaitanya-4181 Ref-(4099)-Advertiser-My report-deliveryTracking responsiveness*/
                                    value={this.state.campaignStatus} required onChange={this.onCampaignStatusHandleChange} >
                                    <option value={"None Selected"} >None Selected</option>
                                    <option value="Active">Active</option>
                                    <option value="Paused">Paused</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            }
                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div class=" col-sm-12 col-md-3 col-lg-3" id="deliveryTracking_Timeline">       {/*Chaitanya-4181Ref-(4099)-Advertiser-My report-deliveryTracking responsiveness*/}
                               {/* Sandeep-task-3500-added font size , color and cursor */}
                            <label style={{ fontSize: '14px',color:"#14254A" }}>Timeline&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;</label>
                            {this.state.reportKey === "report" ?
                             // Sandeep-task-3485-dropdown changes
                                <select disabled    name="Timeline" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',backgroundColor:'#EEEEEE',fontSize:'14px',cursor: "not-allowed" }} 
                                //Sandeep-task-3500 -added background color when dropdwon disable
                                    value={this.state.timeline} required onChange={this.ondeadlineHandleChange} >


                                    <option value={"None Selected"} >None Selected</option>

                                    <option value="All" lable="All">All</option>
                                    <option value="No Lead Delivered" label="No Lead Delivered">No Lead Delivered</option>
                                    <option value="Ending Three Days" label="Ending 3 days">Ending 3 days</option>
                                    <option value="Weekly" label="Ending This Week">Ending This Week</option>
                                    <option value="Monthly" label="Ending This Month">Ending This Month</option>
                                    <option value="Quarterly" label="Ending This Quarter">Ending This Quarter</option>
                                    <option value="Yearly" label="Ending This Year">Ending This Year</option>
                                    <option value="PreMonth" label="Ending Previous Month">Ending Previous Month</option>
                                    <option value="PreQuarter" label="Ending Previous Quarter">Ending Previous Quarter</option>
                                    <option value="PreYear" label="Ending Previous Year">Ending Previous Year</option>

                                </select>
                                :
                                 // Sandeep-task-3485-dropdown changes
                                <select style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555',fontSize:'14px' }}  name="Timeline"
                                    value={this.state.timeline} required onChange={this.ondeadlineHandleChange} >

                                    <option value={"None Selected"} >None Selected</option>

                                    <option value="All" lable="All">All</option>
                                    <option value="No Lead Delivered" label="No Lead Delivered">No Lead Delivered</option>
                                    <option value="Ending Three Days" label="Ending 3 days">Ending 3 days</option>
                                    <option value="Weekly" label="Ending This Week">Ending This Week</option>
                                    <option value="Monthly" label="Ending This Month">Ending This Month</option>
                                    <option value="Quarterly" label="Ending This Quarter">Ending This Quarter</option>
                                    <option value="Yearly" label="Ending This Year">Ending This Year</option>
                                    <option value="PreMonth" label="Ending Previous Month">Ending Previous Month</option>
                                    <option value="PreQuarter" label="Ending Previous Quarter">Ending Previous Quarter</option>
                                    <option value="PreYear" label="Ending Previous Year">Ending Previous Year</option>

                                </select>
                            }
                        </div>
                    </div>
                    {/* <br/> */}
                    <hr />
                    {/* Sandeep-task-3485-added color  for box*/}
                    <div style={{ backgroundColor: '#FFFFFF ', marginBottom: '30px',border:"1px solid #CCCCCC" ,borderRadius:"3px"}}>
                        <div style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: '10px' }}
                        >

                            <div>

                                <div>
                                    <label style={{ fontFamily: 'Roboto', fontWeight: '600px', fontSize: '16px', color: '#F28C0F' }}>Delivery Summary :
                                    {/* kiran-4748-added toString() and changed == to === */}
        {this.state.timeline !== "None Selected" || this.state.reportKey === "report" ?
                                            <span style={{ color: "black", fontSize: "14px" }}>&nbsp;&nbsp;&nbsp;{this.state.label}
                                              
                                                {this.state.label === "All" || this.state.label === "No Lead Delivered" ? ""
                                                    : this.state.reportSummary && this.state.reportSummary.map(reportSummary => (
                                                        <span style={{ color: "black", fontSize: "14px" }}>({reportSummary.deliveryLabel})</span>
                                                    ))
                                                }
                                            </span>
                                            : ""}

                                    </label>
                                </div>
                                <hr></hr>
                                {/* kiran-4748-added toString() and changed == to === */}
                                {this.state.reportKey === "report" || this.state.generateBtnFlag === true ?
                                    <div>
                                        {this.state.reportSummary && this.state.reportSummary.map(reportSummary => (
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                        Agency ID&nbsp;:&nbsp;
                </label>
                                                    <label style={{color:"#14254A"}}>{reportSummary.agencyID}&nbsp;</label>
                                                      {/* Sandeep-task-3485-added color */}
                                                </div>
                                                <div class="col-sm-3 col-lg-4" style={{ marginBottom: "10px" }}>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                        Agency Name&nbsp;:&nbsp;
                </label>
                                                    <label style={{color:"#14254A"}}>{reportSummary.agencyName}</label>
                                                      {/* Sandeep-task-3485-added color */}
                                                {/* */}
                                                </div>
                                                <div class="col-sm-2">
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>
                                                        Report ID&nbsp;:&nbsp;
                                                     </label>
                                                    <label style={{color:" #14254A", fontFamily: "Roboto", fontSize: "14px"}}>{this.state.reportID}&nbsp;</label>
                                                </div>
                                                <div class="col-sm-3 col-lg-4" style={{ marginBottom: "10px" }}>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>
                                                        Report Name&nbsp;:&nbsp;
                                                     </label>
                                                    <label style={{color:" #14254A", fontFamily: "Roboto", fontSize: "14px"}}>{this.state.reportName}</label>
                                                </div>                            
                                                {/* Chaitanya-4099-Reportname and ReportID- not getting display once click on the report name link */}

                                                <div class="col-sm-7 col-lg-6"></div>


                                                <div class="col-sm-12 col-md-12 col-lg-12">
                                                    <hr></hr>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}><b>Total count :</b></label>
                                                </div>

                                                <div class="col-sm-2">
                                                    <label id="label"  style={{color:"#4F4F4F"}}>
                                                        Allocated Lead&nbsp;:&nbsp;</label>
                                                     {/* Sandeep-task-3485-added color */}
                                                    <span style={{color:"#414141"}}>
                                                        {reportSummary.totalAllocation}
                                                    </span>
                                                </div>

                                                <div class="col-sm-2">
                                                    <label id="label"  style={{color:"#4F4F4F"}}>Delivered&nbsp;:&nbsp;</label>
                                                    <span style={{ color: "#193D8F" }}>{reportSummary.deliveredLead}</span>
                                                   {/* Sandeep-task-3485-added color */}
                                                </div>
                                                <div class="col-sm-2">
                                                    <label id="label"  style={{color:"#4F4F4F"}}>Review Pending&nbsp;:&nbsp;</label>
                                                    <span style={{color:"#F28C0F"}}>
                                                        {reportSummary.qaReviewLead}</span>
                                                  {/* Sandeep-task-3485-added color */}
                                                </div>
                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Accepted&nbsp;:&nbsp;</label>
                                                    <span style={{ color: "#008000" }}>{reportSummary.totalAccepted}</span>
                                                     {/* Sandeep-task-3485-added color */}
                                                </div>
                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Rejected&nbsp;:&nbsp;</label>
                                                    <span style={{ color: "#FF0000" }}>{reportSummary.rejectedLead}</span>
                                                     {/* Sandeep-task-3485-added color */}
                                                </div>
                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Balance&nbsp;:&nbsp;</label>
                                                    <span style={{color:"#4F4F4F"}}>
                                                        {/* Sandeep-task-3485-added color */}
                                                        {reportSummary.balanceLead}</span>

                                                </div>


                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <div>
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>
                                 {/* Sandeep-task-3500-agency ID name issue */}
                                                    Agency ID&nbsp;:&nbsp;
                </label>
                                              
                                                <label style={{color:"#14254A"}}>{0}&nbsp;</label>
                                                  {/* Sandeep-task-3485-added color */}

                                            </div>
                                            <div class="col-sm-3 col-lg-4" style={{ marginBottom: "10px" }}>
                                                <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                    Agency Name&nbsp;:&nbsp;
                </label>

                                                <label style={{color:"#14254A"}}>{"NA"}</label>
                                                  {/* Sandeep-task-3485-added color */}


                                            </div>
                                            <div class="col-sm-7 col-lg-6"></div>

                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <hr></hr>
                                                <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}><b>Total count :</b></label>
                                            </div>

                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}} >
                                                    Allocated Lead&nbsp;:&nbsp;</label>
                                                   {/* Sandeep-task-3485-added color */}
                                                <span style={{color:"#414141"}}>
                                                    {0}
                                                </span>

                                            </div>

                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Delivered&nbsp;:&nbsp;</label>
                                                <span style={{ color: "#193D8F" }}>{0}</span>
                                              {/* Sandeep-task-3485-added color */}
                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Review Pending&nbsp;:&nbsp;</label>
                                                <span style={{color:"#F28C0F"}}>{0}</span>
                                              {/* Sandeep-task-3485-added color */}

                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Accepted&nbsp;:&nbsp;</label>
                                                <span style={{ color: "#008000" }}>{0}</span>
                                              {/* Sandeep-task-3485-added color */}
                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Rejected&nbsp;:&nbsp;</label>
                                                <span style={{ color: "#FF0000" }}>{0}</span>
                                             {/* Sandeep-task-3485-added color */}
                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Balance&nbsp;:&nbsp;</label>
                                                <span style={{color:"#4F4F4F"}}>
                                                    {0}</span>
                                                  {/* Sandeep-task-3485-added color */}
                                            </div>


                                        </div>
                                        {/* ))}  */}
                                    </div>}

                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3" style={{ marginTop: '16px', marginLeft: '-21px' }}>
                              {/* Sandeep-task-3500-showing result font issue */}
                                <span >Showing results <b>  
                                    {this.state.agencyMyReportCampaignInfo.length}</b> 
                                    </span>

                            </div>
                        </div>

                    </div>
                    {/*          
            <Loader

					loadingRef={this.loadingRef}
					closeLoadingRef={this.closeLoadingRef}
					message="Wait a moment while we are loading your data...."
				/>
    */}
                    <div align="center" id={this.state.loader1}></div>
                    <div align="center" id={this.state.loader}></div>
                    {/* kiran-4748-removing console warning-changed classname */}
                    <Table {...this.state.tableState}
                        id="myTable tr"
                        bordered
                        // locale={locale}
                        //    rowKey={this.state.reportID}

                        dataSource={this.state.agencyMyReportCampaignInfo}
                        columns={columnsReport}
                        onChange={onChange}
                        className={`${tableCSS} "ant-table-thead"`}
                        scroll={{ x: 1200, y: 1333 }}
                        pagination={{
                            pageSize: this.state.pageSize,
                            position: "bottom",

                        }}>

                    </Table>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6" className="pacing_deliveryTracking" /*Chaitanya-4181-My report-create new report*/ 
                        style={{ marginTop: "-53px", marginLeft: "5px", fontSize: '13px', color: '#4F4F4F', fontFamily: 'sans-serif' }}
                    >
                        Records per page:&nbsp;
												<select
                            defaultValue={this.state.pageSize}
                            onChange={this.handleChangePageSize}
                            id="pacing11"
                            class="input-small"
                            className="form-control"                         
                            name="pacing"
                            style={{ width: "71px", height: "30px", display: "initial", fontSize: '13px', fontFamily: 'sans-serif', color: '#4F4F4F' }}>
                            <option value="100" selected>
                                100
													</option>
                            <option value="200">200</option>
                            <option value="300">300</option>

                        </select>

                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}
/**
   * @author Sandeep Dhawale-task 3448
   * @param  Description handle the login authentication
   * @return Description return All details of authentication
   */
 AdvertiserDeliveryTrackingReport.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(AdvertiserDeliveryTrackingReport));

