/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

import React, { Component } from 'react';
import CanvasJSReact from '../../assets/canvasjs.react';
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class AgencyReport1 extends Component
{

    constructor() {
        super();
        this.state = {

             dataPointsArray: [
                { name: "North America", y: 5 },
                { name: "Europe", y: 31 },
                { name: "South America", y: 40 },
                { name: "Countries of Australia  and Oceania(Island)", y: 17 },
                { name: "Asia", y: 7 },
                {name:"Africa",y:9}
            ],

            dataPointsArray1: [
                { name: "Still to be allocated", y: 20 },
                { name: "Allocated", y: 3},
                { name: "Partially Allocated", y: 4 },
               
                
            ]
        }
        this.toggleDataSeries = this.toggleDataSeries.bind(this);

        
    }

	toggleDataSeries(e){

        //alert("toggleDataSeries");
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
        //alert("e.dataSeries.visible = false;");
        }
		else{
            e.dataSeries.visible = true;
         //   alert(" e.dataSeries.visible = true;");
		}
		this.chart1.render();
	}

    
    
                render() {
        const options = {
			theme: "light2",
			animationEnabled: true,
			title: {
				text: "Regionwise Publisher"
			},
			subtitles: [{
				// text: "71% Positive",
				verticalAlign: "center",
				fontSize: 20,
				dockInsidePlotArea: true
            }],
            height:260,
			data: [{
                click: function(e)
                {
                    alert(  e.dataSeries.type+ ", dataPoint { x:" + e.dataPoint.name + ", y: "+ e.dataPoint.y + " }" );
                document.getElementById('unsatisfied').innerHTML=e.dataPoint.name+":"+e.dataPoint.y;
             },
				type: "doughnut",
				showInLegend: true,
				indexLabel: "{name}: {y}",
				yValueFormatString: "#,###'%'",
				dataPoints: this.state.dataPointsArray
			}]
		}
		const options1 = {
			theme: "light2",
			animationEnabled: true,
			title: {
				text: "Client wise campaign status"
			},
			subtitles: [{
				// text: "71% Positive",
				verticalAlign: "center",
				fontSize: 20,
				dockInsidePlotArea: true
            }],
            height:260,
			data: [{
                click: function(e)
                {
                    alert(  e.dataSeries.type+ ", dataPoint { x:" + e.dataPoint.name + ", y: "+ e.dataPoint.y + " }" );
                document.getElementById('unsatisfied').innerHTML=e.dataPoint.name+":"+e.dataPoint.y;
             },
				type: "doughnut",
				showInLegend: true,
				indexLabel: "{name}: {y}",
				yValueFormatString: "#,###'%'",
				dataPoints: this.state.dataPointsArray1
			}]
        }
        const options2 = {
			title: {
				text: "Basic Column Chart"
            },
            height:260,
			data: [
			{
				// Change type to "doughnut", "line", "splineArea", etc.
				type: "column",
				dataPoints: [
					{ label: "Apple",  y: 10 ,z:20 },
					{ label: "Orange", y: 15  },
					{ label: "Banana", y: 25  },
					{ label: "Mango",  y: 30  },
					{ label: "Grape",  y: 28  }
				]
			}
			]
        }
        


        const options3 = {
			theme: "light2",
			animationEnabled: true,
			title:{
				text: "Publisher Quality Performance"
			},
			// subtitles: [{
			// 	text: "Click Legend to Hide or Unhide Data Series"
			// }],
			axisX: {
				title: "Publishers"
			},
			axisY: {
            interval: 100
		// 		title: "Billions of Barrels",
		// titleFontColor: "#4F81BC",
		// lineColor: "#4F81BC",
		// labelFontColor: "#4F81BC",
        // tickColor: "#4F81BC"
    	// 		},
		// 	axisY2: {
		// 		title: "Millions of Barrels/day",
		// titleFontColor: "#C0504E",
		// lineColor: "#C0504E",
		// labelFontColor: "#C0504E",
		// tickColor: "#C0504E"
		 	},
			toolTip: {
				shared: true
			},
			legend: {
				cursor: "pointer",
				itemclick: this.toggleDataSeries
            },
            height:260,
            dataPointWidth: 20,
			data: [{
                
                    click: function(e)
                    {
                        alert(  e.dataSeries.type+ ", dataPoint { x:" + e.dataPoint.name + ", y: "+ e.dataPoint.y + " }" );
                    document.getElementById('twobar').innerHTML=e.dataPoint.name+":"+e.dataPoint.y;
                 },
				type: "column",
		name: "Accepted",
		legendText: "Accepted",
		showInLegend: true, 
		dataPoints:[
			{ label: "Publisher-1", y: 266.21 },
			{ label: "Publisher-2", y: 302.25 },
			{ label: "Publisher-3", y: 157.20 },
			{ label: "Publisher-4", y: 148.77 },
			{ label: "Publisher-5", y: 101.50 },
		
				]
			},
			{
				type: "column",	
		name: "Rejected",
		legendText: "Rejected",
		axisYType: "secondary",
		showInLegend: true,
		dataPoints:[
			{ label: "Publisher-1", y: 10.46 },
			{ label: "Publisher-2", y: 2.27 },
			{ label: "Publisher-3", y: 3.99 },
			{ label: "Publisher-4", y: 4.45 },
			{ label: "Publisher-5", y: 2.92 },
			
		]
			}]
        }
        
        const options4= {
			theme: "light2",
			animationEnabled: true,
			title:{
				text: "Publisher Delivery Performance"
			},
			// subtitles: [{
			// 	text: "Click Legend to Hide or Unhide Data Series"
			// }],
			axisX: {
				title: "Publishers"
			},
			axisY: {
            interval: 100
		// 		title: "Billions of Barrels",
		// titleFontColor: "#4F81BC",
		// lineColor: "#4F81BC",
		// labelFontColor: "#4F81BC",
        // tickColor: "#4F81BC"
    	// 		},
		// 	axisY2: {
		// 		title: "Millions of Barrels/day",
		// titleFontColor: "#C0504E",
		// lineColor: "#C0504E",
		// labelFontColor: "#C0504E",
		// tickColor: "#C0504E"
		 	},
			toolTip: {
				shared: true
			},
			legend: {
				cursor: "pointer",
				itemclick: this.toggleDataSeries
            },
            height:260,
            dataPointWidth: 20,
			data: [{
                
                    click: function(e)
                    {
                        alert(  e.dataSeries.type+ ", dataPoint { x:" + e.dataPoint.name + ", y: "+ e.dataPoint.y + " }" );
                    document.getElementById('twobar').innerHTML=e.dataPoint.name+":"+e.dataPoint.y;
                 },
				type: "column",
		name: "Last Month",
		legendText: "Last Month",
		showInLegend: true, 
		dataPoints:[
			{ label: "Publisher-1", y: 266.21 },
			{ label: "Publisher-2", y: 302.25 },
			{ label: "Publisher-3", y: 157.20 },
			{ label: "Publisher-4", y: 148.77 },
			{ label: "Publisher-5", y: 101.50 },
		
				]
			},
			{
				type: "column",	
		name: "Last Qarter",
		legendText: "Last Qarter",
		axisYType: "secondary",
		showInLegend: true,
		dataPoints:[
			{ label: "Publisher-1", y: 10.46 },
			{ label: "Publisher-2", y: 2.27 },
			{ label: "Publisher-3", y: 3.99 },
			{ label: "Publisher-4", y: 4.45 },
			{ label: "Publisher-5", y: 2.92 },
			
		]
            },
            {
				type: "column",	
		name: "Current Date",
		legendText: "Current Date",
		axisYType: "secondary",
		showInLegend: true,
		dataPoints:[
			{ label: "Publisher-1", y: 1.46 },
			{ label: "Publisher-2", y: 6.27 },
			{ label: "Publisher-3", y: 6.99 },
			{ label: "Publisher-4", y: 8.45 },
			{ label: "Publisher-5", y: 1.92 },
			
		]
            }
        
        
        ]
		}
        return(
			
        <div class="container-fluid">
		 <Navigation />
		 <div class="row" style={{paddingTop:"90px"}}>
		 <div class=" col-lg-offset-3 col-xs-6 col-sm-6 col-md-6 col-lg6 col-xl-6"  >
		 
		 <h1><center>Weekly Campaign Analysis Dashboard</center></h1>
		 </div>
		 </div>
	<br/>
             <div class="row" >
			
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg6 col-xl-6"  >
                    <CanvasJSChart style={{height: "300px", width: "100%"}} options = {options1}
                     onRef={ref => this.chart = ref} 
                    />
                    
                </div>
				<div class="vl"></div>
			    <div class="col-xs-6 col-sm-6 col-md-6 col-lg6 col-xl-6" >

               
                    {/* <CanvasJSChart options = {options1}
                                onRef={ref => this.chart = ref} 
                            /> */}
                             Data:
                      <div id="unsatisfied">
                     
                    </div>       
                </div>
            </div>
			<hr style={{borderTop:"1px solid #2196f3"}}/>
            <div class="row">
               
             <div class="col-xs-6 col-sm-6 col-md-6 col-lg6 col-xl-6" >

               
                    <CanvasJSChart options = {options3}
                                onRef={ref => this.chart1 = ref}
                            />
                             Data:
                      <div id="twobar">
                     
                    </div>       
                </div>
				<div class="vl1"></div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg6 col-xl-6" >
                
                <CanvasJSChart options = {options4}
                                onRef={ref => this.chart = ref}
                            />
                    </div>
            </div>
			<Footer />
        </div>
          
      )
                }
}

export default AgencyReport1;