/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanjana Godbole
@Creation Date:04-10-2019
@Description:UI for Advertiser-FrontPage(Advertiser_Dashboard)
*/

import React from "react";
// import { Provider } from "react-redux";
// import { BrowserRouter as Router, Route } from "react-router-dom";


import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";

import "./advertiserDashboard.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3768-vAPT URL changes
// import { Tag } from "antd"; //kiran-4747-removing console warning-no used
import {
	Table,
	Button,
	Tooltip,
	Badge,
	Progress,
} from "antd";

import { css } from "emotion";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { saveAs } from "file-saver";

import configration from "./../../configration/configration";
// import { width } from "pdfkit/js/page";

const xlsx = require("xlsx");
// const Swal = require("sweetalert2");
// const queryString = require("query-string");
// var dateTime = require("node-datetime");//kiran-4747-removing console warning-no used
const tableCSS = css({
	backgroundColor: "white", 
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",
		color: "white",
	},
	"& thead > tr >th": {
		border: "1px solid black",
		color: "white",
	},
});

class Advertiserdashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			advertiserID: "",
			tabReview: "",
			tableSearchText: "",
			linkActive: "active",
			linkActive1: "",
			pacing: "All",
			pageSize: 5,
			pageSize2: 5,
			dashboardCampaignCount: [],
			tabName: "Active",
			listLeadCampID: [],
			listCreativeCampID: [],
			creativeLength: "",
			leadsLength: "",
			othersLength: "",
			ReviewTab: "ReviewCreatives",
			headExample2: "head-example2",
			headExample3: "head-exampleGeneral3",
			headExample4: "head-exampleGeneral4",
			tableState: { size: "small" },
			label: "All", //sunita-task-3012- using this varibale we are displaying  on front end side the pacing value
			noChangeDashbordCampaignCount: [], //sunita-task-3012- this array used for storing the actual count of tab.
			tabNameKey: "", //sunita-task-3012- when we are changing the filter value for particular tab  it will store that tab name only.
			isprogressBtnDisable: false, // sunita-task-3012-added this variable for disabling the progressreport button as per data .
		};
		this.handleChangeSearch = this.handleChangeSearch.bind(this);
		this.campaignLink = this.campaignLink.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangePageSize = this.handleChangePageSize.bind(this);
		this.displayCampaignSpecification = this.displayCampaignSpecification.bind(
			this
		);
		this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
		this.reviewLink = this.reviewLink.bind(this);
		this.handleRefreshData = this.handleRefreshData.bind(this);
		this.downloadCampaignExcel = this.downloadCampaignExcel.bind(this);
		this.downloadExcel = this.downloadExcel.bind(this);
		//Sandeep-task-3693-Review---creatives and lead link issues---advertiser side
		this.openViewAllCreativesReview = this.openViewAllCreativesReview.bind(this);//Sandeep-task-3653-added handle change for creativesReview
		this.openViewAllLeadsReview = this.openViewAllLeadsReview.bind(this);//Sandeep-task-3653-added handle change for leadsReview
		this.getAgencyLink = this.getAgencyLink.bind(this);//snehal-task-3724-vAPT URL changes
	} // constructor
		
	/**
	 * @author Sandeep Dhawale
	 * @param  Description handle the creatives data
	 * @return Description return to go on advertiserCreativesReview page
	 */

	openViewAllCreativesReview(e) {
		e.preventDefault();
		const { user } = this.props.auth;
		let advertiserID = user.id;
		let parentCampID = this.state.listCreativeCampID[0].parentCampID;
		let reallocationID = this.state.listCreativeCampID[0].reallocationID;
		let backKey = "Dash";
        //kiran-4174-added parentCampID,reallocationID 
		let campID=this.state.listCreativeCampID[0].campID;
			// this.props.history.push("/advertiserCreativesReview",{campID:campID})//snehal-task-3724-VAPT URL changes
		    this.props.history.push("/advertiserCreativesReview", {
				campID,
				parentCampID,
				reallocationID,
				advertiserID,
				backKey,
			});
	}

/**
	 * @author Sandeep Dhawale
	 * @param  Description handle the lead data
	 * @return Description return to go on advertiserReviewLead page
	 */
	openViewAllLeadsReview(e) {
		e.preventDefault();
		let campID=this.state.listCreativeCampID[0].campID;
		this.props.history.push("/advertiserReviewLead",{campID:campID})//snehal-task-3724-VAPT URL changes
	}
	/**
	 * @author Somnath Keswad
	 * @param  Description Get uploaded lead from backend based on lead type
	 * @return generate excel file based on response
	 */
	handledownloadLead(e) {
		e.preventDefault();//kiran-4747-removing console warning-added e.preventDefault();
		let campID = e.target.id;
		let parentCampID = e.target.getAttribute("parentcampid");
		let leadType = e.target.getAttribute("leadtype");
		let data = { campID, leadType };
		let campaignDetails = [...this.state.campaignDetails];
		fetch("dashboardAdvertiser/advertiserDownloadLead", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((downloadFiles) => {
				if (downloadFiles.length > 0) {
					var ws = xlsx.utils.json_to_sheet(downloadFiles);
					var wb = xlsx.utils.book_new();
					xlsx.utils.book_append_sheet(wb, ws, "Lead");
					var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
					function s2ab(s) {
						var buf = new ArrayBuffer(s.length);
						var view = new Uint8Array(buf);
						for (let i = 0; i != s.length; ++i)  
							view[i] = s.charCodeAt(i) & 0xff;
						return buf;
					}
					var fileName = leadType + "_" + parentCampID + ".xlsx";
					saveAs(
						new Blob([s2ab(buf)], { type: "application/octet-stream" }),
						fileName
					);
				}
				this.setState({ campaignDetails }); // when user click on lead number table data is empty so i store this data in temperary array and again set back this array
			});
	} // End of handledownloadLead

	/**
  @author Sunita
  * This seperate function is created and called below to download excel file from active tab and completed tab depending on the filter on FE
  * Dowloaded file will contain campaign details 
 */

	downloadExcel() {
		let downloadFiles = this.state.downloadFiles;
		const { user } = this.props.auth;
		var advertiserID = user.id;
		if (downloadFiles.length > 0) {
			var ws = xlsx.utils.json_to_sheet(downloadFiles);
			/* add to workbook */
			var wb = xlsx.utils.book_new();
			xlsx.utils.book_append_sheet(wb, ws, "Lead");
			// /* write workbook */
			var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
			var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" }); 
			/* generate a download */
			function s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
				return buf;
			}
			var fileName;
			if (this.state.tabName === "Active") {
				//sunita-task-3012- as per tabname it will download the report for active and completed tab
				 fileName = advertiserID + "_campaign_Progress_Report.xlsx";
			} else {
				 fileName = advertiserID + "_campaign_Progress_Report.xlsx";
			}

			saveAs(
				new Blob([s2ab(buf)], { type: "application/octet-stream" }),
				fileName
			);
		} else {
		}
	}

	/**
	 * @author Sonali
	 * Task 2988-This function is added to download excel file from active tab and completed tab depending on the filter on FE
	 * Dowloaded file will contain campaign details
	 */
	downloadCampaignExcel(e) {
		e.preventDefault();//kiran-4747-removing console warning-added e.preventDefault();
		const { user } = this.props.auth;
		var advertiserID = user.id;
		this.setState({ advertiserID: advertiserID, user: user });
		var isprogressBtnDisable = this.state.isprogressBtnDisable; // sunita-task-3012-added this variable for disabling the progressreport button as per data .
		var data;
		let campaignDetails = [...this.state.campaignDetails];

		if (campaignDetails.length === 0) {
			isprogressBtnDisable = true; //sunita-task-3012-added this code when data is not present in table as per condition it will disbal or enable the download button
		} else {
			isprogressBtnDisable = false; //sunita-task-3012-added this code when data is not present in table as per condition it will disbal or enable the download button
		}
		this.setState({ isprogressBtnDisable }); //sunita-task-3012- gives the updated value isprogressBtnDisable.
		if (this.state.tabName === "Completed") {
			if (this.state.label === "Ending This Month") {
				data = {
					pacing: "Monthly",
					tab: "All",
					byPacing: true,
				}; //sunita-task-3012- this data is used when tabname is completed.
				// this.state.label = "Ending This Month";
				this.setState({label:"Ending This Month"}) //kiran-4747-removing console warning-use setState
			} else {
				data = {
					pacing: this.state.pacing,
					tab: "All",
					byPacing: true,
				}; //sunita-task-3012- this data is used when we are changing filter in  completed tab only.
			}
		} else {
			data = {
				pacing: this.state.pacing,
				tab: "All",
				byPacing: true,
			}; //sunita-task-3012-this data is used when other tabname will come i.e except completed
		}

		if (this.state.tabName === "Active") {
			//sunita-task-3012-these conditions are given when we are doing actions like tab change or filter change.

			//Sonali -Task 2988-Download report for active campaigns under active tab
			fetch("/reports/activeCampaignsADVForExcel", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((downloadFiles) => {
					this.setState({ downloadFiles: downloadFiles });
					this.downloadExcel();
					this.setState({ campaignDetails });
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			//Sonali -Task 2988-Download report for completed campaigns under completed tab

			fetch("/reports/completedCampaignADVForExcel", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((downloadFiles) => {
					this.setState({ downloadFiles: downloadFiles });
					this.downloadExcel();
					this.setState({ campaignDetails });
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	getAgencyLink(e) {
		e.preventDefault();
		const self = this;
		var agencyID = e.target.id;
		// var name = e.target.getAttribute("name");
		self.props.history.push("/reviewAgencyByAdvertiser",{agencyID:agencyID})//snehal-task-3724-VAPT URL changes
	}

	reviewLink(e) {
		e.preventDefault();
		// const { user } = this.props.auth;
		// var advertiserID = user.id;//kiran-4747-removing console warning-no used
		var tabReview = e.target.id;
		this.setState({ tabReview: tabReview });
		if (e.target.id === "creatives") { //kiran-4747-removing console warning-changed == to ===
			//Sandeep-task-3653-added code bcz of creatives issue
			var ReviewTab = "ReviewCreatives";
			fetch("dashboardAdvertiser/creativeReviewCampID", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
			})
				.then((res) => res.json())
				.then((listCreativeCampID) => {
					this.setState({
						listCreativeCampID: listCreativeCampID,
						creativeLength: listCreativeCampID.length,
						ReviewTab: ReviewTab,
						headExample2: "head-example2",
						headExample3: "head-exampleGeneral3",
						headExample4: "head-exampleGeneral4",
						loading2: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
		if (e.target.id === "leads") {
			let ReviewTab = "ReviewLeads";
			fetch("/dashboardAdvertiser/leadReviewCampID", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
			})
				.then((res) => res.json())
				.then((listLeadCampID) => {
					this.setState(
						{
							listCreativeCampID: listLeadCampID,
							leadsLength: listLeadCampID.length,
							ReviewTab: ReviewTab,
							headExample2: "head-exampleGeneral2",
							headExample3: "head-example3",
							headExample4: "head-exampleGeneral4",
							loading2: false,
						},
						function () {}
					);
				})
				.catch(function (err) {});
		}
		if (e.target.id === "others") {
			let ReviewTab = "ReviewOthers";
			fetch("/dashboardAdvertiser/agencyLinkReview", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
			})
				.then((res) => res.json())
				.then((agencyLinkReview) => {
					this.setState({
						listCreativeCampID: agencyLinkReview,
						othersLength: agencyLinkReview.length,
						ReviewTab: ReviewTab,
						headExample2: "head-exampleGeneral2",
						headExample3: "head-exampleGeneral3",
						headExample4: "head-example4",
						loading2: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} // end of reviewLink

	displayCampaignSpecification(e) {
		e.preventDefault();//kiran-4747-removing console warning-added e.preventDefault();
		var campID = e.target.getAttribute("campID");
		// var campaignName = e.target.getAttribute("campaignName");
		var parentCampID = e.target.getAttribute("parentCampId");
		// var reallocationID = e.target.getAttribute("reallocationId");//kiran-4747-removing console warning-no used

		 this.props.history.push("/campaignSpecificationAdvertiserDashboard",{campID:campID,parentCampID:parentCampID})//snehal-task-3766-vAPT URL changes
	}

	handleChange(e) {
		e.preventDefault(); //kiran bug 4255-added for remove # from url
		var pacing = e.target.id;
		var label = e.target.getAttribute("label"); //sunita-task-3012-this variable added for displaying the pacing value on FE and as per requirement i have setstate the variables below.
		document.getElementById("myText").innerHTML = label; //sunita-task-3012-code change done bcz label value not getting properly.		
		this.setState({
			pacing: pacing,
			label: label,
			loading: true, // User Story : 3193 : Karan Jagtap : Changed loading state
		});
		var tabName = this.state.tabName;
		var tabNameKey = tabName; //sunita-task-3012-tabnamekey is added for BE when we are changing the filter value for particular tab  it will store that tab name.

		var data = {
			pacing: pacing,
			byPacing: true,
			tabNameKey,
			noChangeDashbordCampaignCount: this.state.noChangeDashbordCampaignCount,
		}; //sunita-task-3012-noChangeDashbordCampaignCount,tabNameKey-sent to BE data as per requirement

		fetch("/dashboardAdvertiser/advDashboardCampaignCount", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((dashboardCampaignCount) => {
				this.setState({
					dashboardCampaignCount: dashboardCampaignCount,
					tabNameKey: tabNameKey,
				}); //sunita-task-3012-getting updated values.
			})
			.catch(function (err) {
				console.log(err);
			});
		if (tabName === "Active") {
			fetch("/dashboardAdvertiser/activeCampaignsADV", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loading: false,
						isprogressBtnDisable: false,
					}); //sunita- task-3012-isprogressBtnDisable its value  will change as per data present in table for disabling the download button.
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			fetch("/dashboardAdvertiser/completedCampaignADV", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loading: false,
						isprogressBtnDisable: false,
					}); //sunita- task-3012-isprogressBtnDisable its value  will change as per data present in table for disabling the download button.
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	handleChangePageSize(e) {
		var pageSize = e.target.value;
		this.setState({ pageSize: pageSize });
	}

	handleChangePageSize2(e) {
		var pageSize2 = e.target.value;
		this.setState({ pageSize2: pageSize2 });
	}
	/**
	 * @author Narendra
	 * @param  Description  Searching table information
	 */
	handleChangeSearch(e) {
		e.preventDefault();
		let currentList = [];
		// Variable to hold the filtered list before putting into state
		let newList = [];
		let filterData = e.target.value;
		// If the search bar isn't empty
		if (e.target.value === "") {
			newList = this.state.campaignDetails;
		} else {
			// Assign the original list to currentList
			currentList = this.state.campaignDetails;
			newList = currentList.filter((item) => {
				let obj={};//Somnath Task-3972, Add specific Keys in search criteria
				obj[`campID`]=item.campID;
				obj[`parentCampID`]=item.parentCampID;
				obj[`campaignName`]=item.campaignName;
				// obj[`startDate`]=item.startDate;
				// obj[`endDate`]=item.endDate;
				obj[`leadAllocation`]=item.leadAllocation;
				// obj[`allocatedLead`]=item.allocatedLead;
				obj[`clientCampID`]=item.clientCampID;
				obj[`pendingLead`]=item.pendingLead;
				obj[`deliveredLead`]=item.deliveredLead;
				obj[`qaReviewLead`]=item.qaReviewLead;
				obj[`acceptedLead`]=item.acceptedLead;
				obj[`rejectedLead`]=item.rejectedLead;
				obj[`balancedLead`]=item.balancedLead;
				// change search term to lowercase
				const filter = e.target.value.toLowerCase();
				//const filter = e.target.value.toLowerCase();
				// check to see if the current list item includes the search term
				// If it does, it will be added to newList. Using lowercase eliminates
				// issues with capitalization in search terms and search content
				var data = Object.values(obj);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
		}
		// Set the filtered state based on what our rules added to newList
		this.setState({
			campaignDetailsSearch: [...newList],
			tableSearchText: filterData,
		});
	} //handleSearch

	campaignLink(e) {
		e.preventDefault();
		const {  user } = this.props.auth;
		var advertiserID = user.id;
		let data;
		if (e.target.id === "Completed") {
			data = {
				advertiserID: advertiserID,
				pacing: "Monthly",
				tab: "All",
				tabNameKey: "All",
			}; //sunita-task-3012-used this data as per requirement.
			// this.state.label = "Ending This Month";
			this.setState({label:"Ending This Month"}) //kiran-4747-removing console warning-use setState
		} else {
			data = {
				pacing: "All",
				tab: "All",
				tabNameKey: "All",
			}; //sunita-task-3012- data is sent as per changing the tabName.
			// this.state.label = "All";
			this.setState({label:"All"})//kiran-4747-removing console warning-use setState
		}
		this.setState({
			tabName: e.target.id,
			label: this.state.label,
			pacing: "All",
			dashboardCampaignCount: this.state.noChangeDashbordCampaignCount,
			loading: true, // User Story : Karan Jagtap : Changed loading to true
		}); //sunita-task-3012-for getting updated value.

		if (e.target.id === "Active") {
			fetch("/dashboardAdvertiser/activeCampaignsADV", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						tab: "Active",
						linkActive: "active",
						linkActive1: "",
						loading: false,
						isprogressBtnDisable: false,
						tableSearchText: "", //kiran-bug 4255- added code to blank tableSearchText on tab
					}); //sunita-task-3012-progressBtnDisable its value  will change as per data present in table for disabling the download button.
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			fetch("/dashboardAdvertiser/completedCampaignADV", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						tab: "Completed",
						linkActive: "",
						linkActive1: "active",
						loading: false,
						isprogressBtnDisable: false,
						tableSearchText: "", //kiran-bug 4255- added code to blank tableSearchText on tab
					}); //sunita-task-3012-progressBtnDisable its value  will change as per data present in table for disabling the download button.
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} 

	handleRefreshData(e) {
		e.preventDefault();
		// const {  user } = this.props.auth;
		// var advertiserID = user.id;//kiran-4747-removing console warning-no used
		var data = { pacing: this.state.pacing };//snehal-task-3768-vAPT URL changes

		fetch("/dashboardAdvertiser/advDashboardCampaignCount", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((dashboardCampaignCount) => {
				this.setState({ dashboardCampaignCount: dashboardCampaignCount });
			})
			.catch(function (err) {
				console.log(err);
			});
		if (this.state.tabName === "Active") {//kiran-4747-removing console warning-changed == to ===
			var tab = "Active";
			fetch("/dashboardAdvertiser/activeCampaignsADV", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						tab: tab,
						linkActive: "active",
						linkActive1: "",
						loading: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			let tab = "Completed";//kiran-4747-removing console warning-changed var to let
			fetch("/dashboardAdvertiser/completedCampaignADV", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						tab: tab,
						linkActive: "",
						linkActive1: "active",
						loading: false,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} //end of linkClicked

	/**
	 * @author Supriya Gore
	 * @param  Description  handle libyAI url with post method
	 * @return Description return successfully response with url
	 */
	getLIbyAIURL(e) {
		e.preventDefault();
		const { user } = this.props.auth;
		var campID = e.target.id;
		let libyAIURL = configration.libyAIURL;
		libyAIURL = libyAIURL + "?campID=" + campID + "&userID=" + user.id;
		let data = {
			user: user,
			campID: campID,
		};

		fetch("/setCookie", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				this.handleRefreshData(e);
				if (response.message === "cookiesSet") {
					window.open(libyAIURL, "_blank");
				}
			});
	}
	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			const {  user } = this.props.auth;
			var advertiserID = user.id;
			this.setState({ advertiserID: advertiserID, user: user });

			let data = {
				pacing: this.state.pacing,
				tabNameKey: "All",
				tab: "All",
			}; //sunita-task-3012-sent data to BE as per requirement

			fetch("/dashboardAdvertiser/advDashboardCampaignCount", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((dashboardCampaignCount) => {
					this.setState({
						dashboardCampaignCount: dashboardCampaignCount,
						noChangeDashbordCampaignCount: dashboardCampaignCount,
					}); //sunita-task-3012-setstate the array nochangecampaigncount for getting the updated value.
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("/dashboardAdvertiser/activeCampaignsADV", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({ campaignDetails: campaignDetails, loading: false });
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("dashboardAdvertiser/leadReviewCampID", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((listLeadCampID) => {
					this.setState({
						listLeadCampID: listLeadCampID,
						leadsLength: listLeadCampID.length,
					});
				})
				.catch(function (err) {
					console.log(err);
				});

			// Display campID in Lead Section which lead submited by agency
			//Sonali-making post request for below route
			//kiran-4523-removed adv id
			fetch(
					"dashboardAdvertiser/creativeReviewCampID",{
					method: "POST",
				headers: { "Content-Type": "application/json" },

				}
			)
				.then((res) => res.json())
				.then((listCreativeCampID) => {
					this.setState({
						listCreativeCampID: listCreativeCampID,
						headExample3: "head-exampleGeneral3",
						headExample2: "head-example2",
						creativeLength: listCreativeCampID.length,
					});
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("/dashboardAdvertiser/agencyLinkReview", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
			})
				.then((res) => res.json())
				.then((agencyLinkReview) => {
					this.setState({ othersLength: agencyLinkReview.length });
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} 
	reviewLead(e) {
		e.preventDefault();
		var campID = e.target.id;
		if (this.state.ReviewTab === "ReviewCreatives") {
			this.props.history.push("/advertiserCreativesReview",{campID:campID})//snehal-task-3724-VAPT URL changes
		} else {
			this.props.history.push("/advertiserReviewLead",{campID:campID})//snehal-task-3724-VAPT URL changes
		}
	}

	render() {
		const { user } = this.props.auth;

		const columns3 = [
			{
				title: "Agency Name",
				dataIndex: "agencyName",
				key: "agencyName",
				width: "30%",
				align: "center",
				sorter: (a, b) => a.agencyName - b.agencyName,
				render: (text, record) => <div>{record.agencyName}</div>,
			},
			{
				title: "Review ",
				dataIndex: "reviewDetails",
				key: "reviewDetails",
				width: "30%",
				align: "center",
				sorter: (a, b) => a.reviewDetails - b.reviewDetails,
				render: (text, record) => (
					<div>
						{/* kiran-4747-removing console warning-added "#/" */}
						<a
							href="#/"
							id={record.agencyID}
							name="advertiser"
							onClick={this.getAgencyLink}>
							Click here
						</a>
					</div>
				),
			},
		];
		const columns2 = [
			{
				title: "Camp ID",//sandeep-task-3653-removed _ from camp ID
				dataIndex: "campID",
				key: "campID",
				width: "30%",
				align: "center",
				sorter: (a, b) => a.campID - b.campID,
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>
							<a
								href="#/"
								onClick={this.reviewLead.bind(this)}
								id={record.campID}
								style={{ fontSize: "12px" }}>
								{record.parentCampID ? record.parentCampID : record.campID}
							</a>
						</span>
					</div>
				),
			},
			{
				title: "Camp Name",  
				dataIndex: "campaignName",
				key: "campaignName",
				align: "Center",
	            width:"18%",

				class:"campnameHeder",//4150
				
				
				
				

				sorter: (a, b) => {
					const asc = "asc";
					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<div style={{ fontSize: "12px", wordBreak: "break-all" }}>
							{record.campaignName}
						</div>
					</div>
				),
			},
		];

		const columns = [
			{
				title: "Camp ID",
				dataIndex: "campID",
				key: "campID",
				width: "10%",
				align: "center",
				sorter: (a, b) => a.campID - b.campID,
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>
							{record.parentCampID ? record.parentCampID : text}
						</span>
						<br />
					</div>
				),
			},
         // Sandeep-task-3275-added new column for agency camp ID 
			{
				title: "Agency Camp ID",
				dataIndex: "agencycampID",
				key: "agencycampID",
				width: "10%",
				align: "center",
				sorter: (a, b) => a.agencycampID - b.agencycampID,
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>
							{record.clientCampID ? record.clientCampID : text}
						</span>
						<br />
					</div>
				),
			},





			{
				title: "Camp Name",
				dataIndex: "campaignName",
				key: "campaignName",
				width: "20%",
				align: "Center",
				sorter: (a, b) => {
					const asc = "asc";
					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<div class="adv_dot">
							{/*sunita-task-3012-added css for proper alignment*/}
							<span>
								{/* kiran-4747-removing console warning-added "#/" */}
								<a 
									href="#/"
									onClick={this.displayCampaignSpecification}
									campID={record.campID}
									campaignName={record.campaignName}
									parentCampID={record.parentCampID}
									reallocationID={record.reallocationID}
									style={{ fontSize: "11px" , color:"#1890FF"}}>
									{record.campaignName}
								</a>
							</span>
						</div>
					
					</div>
				),
			},
			{
				title: "Progress",
				width: "30%",
				children: [
					{
						title: (
							<Tooltip placement="top" title="Allocation Status">
								Allocation
							</Tooltip>
						),
						dataIndex: "allocationStatus",
						key: "allocationStatus",
						align: "center",
						width: "8%",
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>
										<Progress
											type="circle"
											percent={Math.round(
												(record.allocatedLead * 100) / record.leadAllocation
											)}
											width={28}
										/>
									</div>
								),
							};
						},
					},
					{
						title: (
							<Tooltip placement="top" title="Creatives Status">
								Creatives
							</Tooltip>
						),
						dataIndex: "creativeApproval",
						key: "creativeApproval",
						align: "center",
						width: "8%",
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>
										
										{text === "Yes" ? (
											<Progress
												type="circle"
												strokeColor="Red"
												percent={100}
												width={30}
												format={() => "0%"}
											/>
										) : (
											<Progress type="circle" percent={100} width={30} />
										)}
									</div>
								),
							};
						},
					},
					{
						title: (
							<Tooltip placement="top" title="Delivery Status">
								Delivery
							</Tooltip>
						),
						dataIndex: "deliveryStatus",
						key: "deliveryStatus",
						align: "center",
						width: "8%",
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>
										{record.requiredLeadPerAsset === "Yes" ? (
											<Progress
												type="circle"
												percent={Math.round(
													(record.totAcceptedLead / record.leadAllocation) * 100
												)}
												width={28}
											/>
										) : (
											<Progress
												type="circle"
												percent={Math.round(
													(record.acceptedLead / record.leadAllocation) * 100
												)}
												width={28}
											/>
										)}
									</div>
								),
							};
						},
					},
				],
			},
			{
				title: "Total Leads",
				dataIndex: "leadAllocation",
				key: "leadAllocation",
				align: "right",
				width: "10%",
				render: (text, record) => <div>{text}</div>,

				
			},
			{
				title: "Delivery Status",
				width: "50%",
				children: [
					{
						title: "Delivered",
						dataIndex: "deliveredLead",
						key: "deliveredLead",
						align: "right",
						width: "8%",
						render: (text, record) => (
							<div>
								{/* kiran-4747-removing console warning-added "#/" */}
								{record.deliveredLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										leadtype={"Delivered"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.deliveredLead}
									</a>
								) : (
									0
								)}
							</div>
						),
						
					},
					
					{
						title: "Accepted",
						dataIndex: "acceptedLead",
						key: "acceptedLead",
						align: "right",
						width: "8%",
						render: (text, record) => (
							<div>
								{record.acceptedLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										leadtype={"Accepted"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.acceptedLead}
									</a>
								) : (
									0
								)}
							</div>
						),
						
					},
					{
						title: "Rejected",
						dataIndex: "rejectedLead",
						key: "rejectedLead",
						align: "right",
						width: "8%",
						render: (text, record) => (
							<div>
								{record.rejectedLead > 0 ? (
									<a
										href="#/"
										id={record.campID}
										parentcampid={record.parentCampID}
										leadtype={"Rejected"}
										onClick={this.handledownloadLead.bind(this)}
										style={{ fontSize: "12px" }}>
										{record.rejectedLead}
									</a>
								) : (
									0
								)}
							</div>
						),
						
					},
					{
						title: "Balanced",
						dataIndex: "balancedLead",
						key: "balancedLead",
						align: "right",
						width: "8%",
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: <div style={{ fontSize: "12px" }}>{text}</div>,
							};
						},
					},
				],
			},
		]; //end of columns

		function onChange(pagination, filters, sorter) {
			console.log("params", pagination, filters, sorter);
		}

		return (
			<div>
				<Navigation />
				<div class="advDashHomePage">
				<div
					class="container-fluid"
					style={{ paddingTop: "85px", paddingBottom: "80px" }}>
					<div
						style={{
							backgroundColor: "rgb(241, 241, 241)",
							paddingTop: "20px",
							borderBottom: "1px solid #a9a9a97d",
						}}>
						{/*sunita-task-3012-added css for proper alignment*/}
						<div class="row">
							<div
								class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
								style={{ marginTop: "5px" }}>
								{/*sunita-task-3012-added css and col as per requirement*/}
								<h3
									style={{
										fontFamily: "roboto",
										color: "black",
										fontSize: "26px",
										fontWeight: "500",
									}}>
									Campaign Overview
								</h3>
							</div>
						</div>
						{/*sunita-task- 3012-removed the code of campaign progress report and filter as per requirement and added below*/}
						{/* kiran-bug 4255-added visible property for responsiveness */}
						<div class="row">
							<div class="col-xs-7 col-sm-12 col-md-8 col-lg-9 col-xl-10">
								{/*sunita-task-3012-changed col as per device wise */}
								{this.state.dashboardCampaignCount.map(
									(dashboardCampaignCount) => (
										<div class="float-center topnav" style={{ height: "37px" ,overflow:"visible"}}>
											<a
												href="campaignLink" 
												class={this.state.linkActive + " tabcountClass"}
												onClick={this.campaignLink}
												id="Active"
												style={{ color: "black" }}>
												Active&nbsp;
												<span
													style={{
														height: "22px",
														paddingLeft: "5px",
														paddingRight: "5px",
														backgroundColor: "green",
														borderRadius: "18%",
														fontSize: "16px",
														color: "white",
														textAlign: "center",
														display: "inline-block",
													}}>
														{/* kiran-4747-removing console warning-remove duplicate attribute */}
													{dashboardCampaignCount.activeCount}
												</span>
											</a>
											{/*sunita-task-3012-added css and classname as per requirement for alignment */}
											&nbsp;&nbsp;&nbsp;&nbsp;
											<a
												href="#/"
												class={this.state.linkActive1 + " tabcountClass"}
												onClick={this.campaignLink}
												id="Completed"
												style={{ color: "black" }}>
												Completed&nbsp;
												<span
													style={{
														height: "22px",
														paddingLeft: "5px",
														paddingRight: "5px",
														backgroundColor: "Red",
														borderRadius: "18%",
														fontSize: "16px",
														color: "white",
														textAlign: "center",
														display: "inline-block",
													}}>
														{/* kiran-4747-removing console warning-remove duplicate attribute */}
													{dashboardCampaignCount.completedCount}
												</span>
											</a>
											{/*sunita-task-3012-added css and classname as per requirement for alignment */}
											&nbsp;&nbsp;&nbsp;&nbsp;
										</div>
									)
								)}
							</div>

							<div
								class="col-xs-12 col-sm-12  col-md-4 col-lg-3 col-xl-2"
								style={{ marginTop: "5px" }}>
								{/*sunita-task-3012-changed col as per device wise */}
								<span class="float-right" style={{ marginRight: "10px" }}>
									{/* kiran-4747-removing console warning-remove duplicate attribute */}
									<input
										type="text"
										onkeyup="myFunction()"
										style={{
											backgroundImage: "url(Group_294.png)",
											backgroundPosition: "15px 6px",
											backgroundSize: "15px 15px",
											backgroundRepeat: "no-repeat",
											width: "100%",
											height: "20px",
											fontSize: "10px",
											border: "1px solid #ddd",
											padding: "12px 20px 12px 34px",
											borderRadius: "25px",
											backgroundColor: "rgb(221, 221, 221)",
										}}
										
										onChange={this.handleChangeSearch}
										onPressEnter={this.handleChangeSearch}
										placeholder="Search..."
										title="Type in a name"
									/>
								</span>
							</div>
						</div>
					</div>

					<div class="row" style={{ marginTop: "50px" }/*margin devlope task 4150*/}>
						{/*sunita-task-3012-added new row in that above campaignprogressreport and filter code added */}
						<div class="col-xs-12 col-sm-12 col-md-5 col-lg-7 col-xl-8">
							<label
								style={{
									color: "#71717199",
									fontFamily: "Roboto",
									marginLeft: "14px",
								}}>
								Showing results for &nbsp;{" "}
							</label>
							<span
								style={{
									color: "#000",
									fontFamily: "Roboto",
									fontWeight: "500",
								}}>
								{" "}
								"{this.state.label}"
							</span>
							{/*sunita-task-3012-instead of pacing given label value for printing filter value */}
						</div>

						<div class="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-4">
							<div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
								<span>
									{/* kiran - bug 4255-change classname to dropdownAdv for conflicts resolved in responsiveness */}
									<div
										class="dropdownAdv float-right"
										style={{
											border: "1px solid #d9d9d9",
											backgroundColor: "white",
											padding: "2px 7px", 
											marginBottom: "1px",/*vrushabh 4150 add marginbottom:1px*/
									
										}}>
										<div
											className="float-left"
											style={{
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
												width: "80px",

											}}>
											<span id="myText">{this.state.label}</span>
										</div>
										<img
											alt=""
											className="float-right filterImg1"
											src="Filter.png"
											height="14px"
											width="14px"></img>
										{/* Sandeep-task-2911 to handle filter size increment issue  */}
										{/* kiran - bug 4255-change classname to dropdown-contentAdv for conflicts resolved in responsiveness */}
										<div class="dropdown-contentAdv">
											<a
												href="#/"
												value="All"
												name="All"
												onClick={this.handleChange}
												id="All"
												label="All">
												All
											</a>
											{/*sunita-task-3012-in each field i have added label in this line as well as below also on FE for showing pacing value */}
											<a
												href="#/"
												value="Ending this week"
												name="Ending this week"
												onClick={this.handleChange}
												id="Weekly"
												label="Ending This Week">
												Ending This Week
											</a>
											<a
												href="#/"
												value="Ending this month"
												name="Ending this month"
												onClick={this.handleChange}
												id="Monthly"
												label="Ending This Month">
												Ending This Month
											</a>
											<a
												href="#/"
												value="Ending this quarter"
												onClick={this.handleChange}
												name="Ending this quarter"
												id="Quarterly"
												label="Ending This Quarter">
												Ending This Quarter
											</a>
											<a
												href="#/"
												value="Ending this year"
												onClick={this.handleChange}
												name="Ending this year"
												id="Yearly"
												label="Ending This Year">
												Ending This Year
											</a>
											<a
												href="#/"
												value="Ending previous month"
												onClick={this.handleChange}
												name="Ending previous month"
												id="PreMonth"
												label="Ending Previous Month">
												Ending Previous Month
											</a>
											<a
												href="#/"
												value="Ending previous quarter"
												onClick={this.handleChange}
												name="Ending previous quarter"
												id="PreQuarter"
												label="Ending Previous Quarter">
												Ending Previous Quarter
											</a>
											<a
												href="#/"
												value="Ending previous year"
												onClick={this.handleChange}
												name="Ending previous year"
												id="PreYear"
												label="Ending Previous Year">
												Ending Previous Year
											</a>
										</div>
									</div>
								</span>
							</div>

							<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
								<label style={{ color: "#1890ff" }} className="progressRport1">
									Campaign Progress Report{" "}
									{/*sunita-task-3012-added label campaign progress report */}
									<span
										className="downloadImage"
										style={{
											float: "right",
											marginTop: "0px",
											marginLeft: "27px",//4417 vrushabh margin set
										}}>
										{this.state.isprogressBtnDisable === false ? ( // sunita-task-3012-as per value get in isprogressBtnDisable it will disable or enable the download button
											<a href="#/">
												<i
													class="fa fa-download"
													onClick={this.downloadCampaignExcel}></i>
											</a>
										) : (
											<a href="#/">
												<i
													class="fa fa-download"
													disabled={this.state.isprogressBtnDisable}></i>
											</a>
										)}
									</span>
								</label>
							</div>
						</div>
					</div>

					<div class="row">
						<div
							class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
							style={{ paddingTop: "5px" }}>
							{this.state.tableSearchText === "" ? (
								<Table
									{...this.state.tableState}
									id="myTable"
									bordered
									dataSource={this.state.campaignDetails}
									onChange={onChange}
									columns={columns}
									size="small"
									loading={this.state.loading}
									// className="ant-table-thead"
									// className={tableCSS}
									className={`${tableCSS} "ant-table-thead"`} //kiran-4747-removing console warning-changed classname
									scroll={{ x: 1250 }}			//4150 vrushabh

									expandedRowRender={(record) => (
										<div class="container-fluid">
											<table
												border="1"
												style={{
													tableLayout: "auto",
													borderCollapse: "collapse",
												}}>
												<tr>
													<td rowSpan="2">
														&nbsp; Agency CampaignID :&nbsp;&nbsp;
														<b>{record.clientCampID}</b>
													</td>
													<th style={{ fontWeight: "400" }}>
														&nbsp;Campaign Name:&nbsp;
													</th>
													<td>
														<b>&nbsp;{record.campaignName}</b>
													</td>

													<th style={{ fontWeight: "400" }}>
														&nbsp;Start Date:&nbsp;
													</th>
													<td>
														<b>&nbsp;{record.startDate}</b>
													</td>

													{user.role === "ADV" ? (
														<td rowSpan="2">
															&nbsp;Insight:&nbsp;&nbsp;
															{/* kiran-4747-removing console warning-added alt="" and #/ */}
															<a
																href="#/"
																id={record.campID}
																onClick={this.getLIbyAIURL.bind(this)}>
																<img
																    alt=""
																	style={{ display: "inherit" }}
																	id={record.campID}
																	src="1.png"
																	width="40px"
																	height="20px"></img>
															</a>
														</td>
													) : (
														""
													)}
												</tr>
												<tr>
													<th style={{ fontWeight: "400" }}>
														&nbsp;Campaign Status:&nbsp;
													</th>
													<td>
														<b>
															&nbsp;
															{/* kiran-4747-removing console warning-changed == to === */}
															{record.campaignStatus === "Active" ? (
																<span
																	style={{
																		color: "#5fba7d",
																		fontSize: "12px",
																	}}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus === "Live_Incomplete" ? (
																<span
																	style={{ color: "orange", fontSize: "12px" }}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus === "Paused" ? (
																<span
																	style={{ color: "red", fontSize: "12px" }}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus ===
															  "PausedIncomplete" ? (
																<span
																	style={{ color: "orange", fontSize: "12px" }}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus === "Completed" ? (
																<span
																	style={{
																		color: "skyblue",
																		fontSize: "12px",
																		
																	}}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : (
																""
															)}
														</b>
													</td>
													<th style={{ fontWeight: "400" }}>
														&nbsp;End Date:&nbsp;
													</th>
													<td>
														<b>&nbsp;{record.endDate}</b>
													</td>
												</tr>
											</table>
										</div>
									)}
									pagination={{
										pageSize: this.state.pageSize,
										position: "bottom",
									}}></Table>
							) : (
								<Table
									{...this.state.tableState}
									id="myTable"
									bordered
									dataSource={this.state.campaignDetailsSearch}
									onChange={onChange}
									columns={columns}
									// className="ant-table-thead"
									loading={this.state.loading}
									// className={tableCSS}
									className={`${tableCSS} "ant-table-thead"`} //kiran-4747-removing console warning-changed classname
									scroll={{ x: 500, y: 345 }} //sunita-task-3012-added css as per requirement
									expandedRowRender={(record) => (
										<div class="container-fluid">
											<table
												border="1"
												style={{
													tableLayout: "auto",
													borderCollapse: "collapse",
												}}>
												<tr>
													<td rowSpan="2">
														&nbsp; Agency CampaignID :&nbsp;&nbsp;
														<b>{record.clientCampID}</b>
													</td>
													<th style={{ fontWeight: "400" }}>
														&nbsp;Campaign Name:&nbsp;
													</th>
													<td>
														<b>&nbsp;{record.campaignName}</b>
													</td>

													<th style={{ fontWeight: "400" }}>
														&nbsp;Start Date:&nbsp;
													</th>
													<td>
														<b>&nbsp;{record.startDate}</b>
													</td>
													{user.role === "ADV" ? (
														<td rowSpan="2">
															&nbsp;Insight:&nbsp;&nbsp;
															{/* kiran-4747-removing console warning-added alt="" and #/ */}
															<a
																href="#/"
																id={record.campID}
																onClick={this.getLIbyAIURL.bind(this)}>
																<img
																	alt=""
																	style={{ display: "inherit" }}
																	id={record.campID}
																	src="1.png"
																	width="40px"
																	height="20px"></img>
															</a>
														</td>
													) : (
														""
													)}
												</tr>
												<tr>
													<th style={{ fontWeight: "400" }}>
														&nbsp;Campaign Status:&nbsp;
													</th>
													<td>
														<b>
															&nbsp;
															{record.campaignStatus === "Active" ? (
																<span
																	style={{
																		color: "#5fba7d",
																		fontSize: "12px",
																	}}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus === "Live_Incomplete" ? (
																<span
																	style={{ color: "orange", fontSize: "12px" }}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus === "Paused" ? (
																<span
																	style={{ color: "red", fontSize: "12px" }}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus ===
															  "PausedIncomplete" ? (
																<span
																	style={{ color: "orange", fontSize: "12px" }}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : record.campaignStatus === "Completed" ? (
																<span
																	style={{
																		color: "skyblue",
																		fontSize: "12px",
																	}}>
																	{" "}
																	{record.campaignStatus}
																</span>
															) : (
																""
															)}
														</b>
													</td>
													<th style={{ fontWeight: "400" }}>
														&nbsp;End Date:&nbsp;
													</th>
													<td>
														<b>&nbsp;{record.endDate}</b>
													</td>
												</tr>
											</table>
										</div>
									)}
									pagination={{
										pageSize: this.state.pageSize,
										position: "bottom",
									}}></Table>
							)}
						</div>
					</div>
					<br />
					<div class="row">
						<div
						     id="RecordsPerPage"
							class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
							style={{ marginTop: "-70px", marginLeft: "5px" }/*4150 vrushabh*/}>
							{/*sunita-task-3012-added css as per requirement */}
							Records per page:&nbsp;
							<select
								defaultValue={this.state.pageSize}
								onChange={this.handleChangePageSize}
								id="pacing1"
								class="input-small"
								className="form-control"
								name="pacing"
								style={{ width: "65px", height: "30px", display: "initial" }}>
								<option value="5" selected>
									5
								</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
							</select>
						</div>
					</div>


					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
							{/*sunita-task-3012-changed col size as per devices */}
							<div
							class="reviewDetailsData"
								style={{
									background: "#ECECEC",
									padding: "10px 15px 10px 15px",
									
									height: "430px",
								}}>
								<p>
									<h3 style={{ fontFamily: "roboto" }}>Review</h3>
								</p>
								<br />
								<p>
									<span class="badgelink">
										<Badge
											count={this.state.creativeLength}
											overflowCount={10}
											offset={[1, -5]}>
											<Button
												size="default"
												className={this.state.headExample2}
												id="creatives"
												onClick={this.reviewLink}>
												Creatives
											</Button>
										</Badge>
									</span>

									<span class="badgelink">
										<Badge
											count={this.state.leadsLength}
											overflowCount={10}
											offset={[1, -5]}>
											<Button
												size="default"
												className={this.state.headExample3}
												id="leads"
												onClick={this.reviewLink}>
												Leads
											</Button>
										</Badge>
									</span>

									<span class="badgelink">
										<Badge
											count={this.state.othersLength}
											overflowCount={10}
											offset={[1, -5]}>
											<Button
												size="default"
												className={this.state.headExample4}
												id="others"
												onClick={this.reviewLink}>
												Others
											</Button>
										</Badge>
									</span>
								</p>
								<p>
									{" "}
									<Table
									
										{...this.state.tableState}
										id="myTable"
										bordered
										dataSource={this.state.listCreativeCampID}
										loading={this.state.loading2}
										onChange={onChange}
										columns={ 
											this.state.tabReview === "others" ? columns3 : columns2
										}
										// className="ant-table-thead"
										// className={tableCSS}
										className={`${tableCSS} "ant-table-thead"`} //kiran-4747-removing console warning-changed classname
										scroll={{ x: 250,}}//y: 200
										
										
										pagination={{ pageSize: this.state.pageSize2 }}></Table>
								
								</p>
	



{/* Sandeep-task-3653-Review section(Creatives and Leads)--View all link added(Agency, pub, Adv) */}
<div>
{/* Sandeep-task-3693-Review---creatives and lead link issues---advertiser side */}
{this.state.tabReview==='creatives'||this.state.tabReview===""?
this.state.creativeLength>10?
  <span style={{marginLeft:"182px"}}> 
  {/* Mufiz-Task-4266-R29-Advertiser login-dashboard -view all creatives alignment issue */}
  {/* kiran-4747-removing console warning-added alt and #? */}
 <a className="ankerTagForVAC" href="#/" onClick={this.openViewAllCreativesReview} >View all creatives 
 <img  class="imgforNoOfListCreative" src="Iconfeather.png" width="20" height="15" alt=""></img>
 </a> 
  </span>:
  ""
  :
  ""
  }
</div>
{/* Sandeep-task-3653-Review section(Creatives and Leads)--View all link added(Agency, pub, Adv) */}
<div style={{marginTop:"7px",float:'left'}}>
	{/* Sandeep-task-3693--Review-- lead link issues(only lead)---advertiser side */}
{this.state.tabReview==='leads'?
this.state.leadsLength>10?
  <span >
	  {/* Sandeep-task-3693--Review-- lead link issues(only lead)---advertiser side */}
 <a class="ankerTagForLead" style={{marginLeft:"10px"}} href="#/" onClick={this.openViewAllLeadsReview} >
	 View all leads 
	 <img class="imgforNoOfList" src="Iconfeather.png" alt="" ></img>
	 </a> 
	 {/* Sandeep-task-3693--Review-- lead link issues(only lead)---advertiser side */}
  </span>:
  ""
  :
  "" }
</div>			
{/* Sandeep-task-3653-Review section(Creatives and Leads)--View all link added(Agency, pub, Adv) */}
{/* Sandeep-task-3693-Review---creatives and lead link issues---advertiser side */}
{this.state.tabReview === "leads" || this.state.tabReview === "creatives"||this.state.tabReview==="" ?
this.state.creativeLength>10||this.state.leadsLength>10?"":
								<p>
									<div class="row">
										<div
										id="recordPerPageOthers"
											class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
											style={{ marginTop: "-15px", marginLeft: "5px" }/*patil-task-4150 top margine change*/}>
											{/*sunita-task-3012-added css as per requirement */}
											Records per page:&nbsp;
											<select
												defaultValue={this.state.pageSize2}
												onChange={this.handleChangePageSize2}
												id="pacing1"
												class="input-small"
												className="form-control"
												name="pacing"
												style={{
													width: "65px",
													height: "30px",
													display: "initial",
												}}>
												<option value="5" selected>
													5
												</option>
												<option value="10">10</option>
												<option value="15">15</option>
												<option value="20">20</option>
											</select>
										</div>
									</div>
								</p>

						:
						<p>
									<div class="row">
										<div
											class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
											style={{ marginTop: "-12px", marginLeft: "5px" }}>
											{/*sunita-task-3012-added css as per requirement */}
											Records per page:&nbsp;
											<select
												defaultValue={this.state.pageSize2}
												onChange={this.handleChangePageSize2}
												id="pacing1"
												class="input-small"
												className="form-control"
												name="pacing"
												style={{
													width: "65px",
													height: "30px",
													display: "initial",
												}}>
												<option value="5" selected>
													5
												</option>
												<option value="10">10</option>
												<option value="15">15</option>
												<option value="20">20</option>
											</select>
										</div>
									</div>
								</p>
	}
							</div>
						</div>
					</div>
				</div>
				
				<Footer />
			</div>
			</div>
		);
	}
}

Advertiserdashboard.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(Advertiserdashboard)
);
