/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/**
* @file adminDashboard
* @author Raunak Thakkar
* @version 1.0
* @section use for admin 
*/
import React from 'react';
// import { Container, Row, Col, Input, Button, Fa, Card, CardBody, ModalFooter,form } from 'mdbreact';
import Header from '../layouts/header1';
import Footer from '../layouts/footer';
import AdminNavpage from '../layouts/adminNavpage';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { Card, Col, Row, Badge, Icon, Button } from 'antd';
import './adminDashboard.css';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Somnath Task-3930-added default header
class Admin extends React.Component {

  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      agencyDetails: [],
      advertiserDetails: [],
      publisherDetails: [],
      agencyCount: '',
      advertiserCount: '',
      publisherCount: 0,
      agencyReviewDetails: [],
      agencyReviewCount: '',
      publisherReviewDetails: [],
      publisherReviewCount: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);


  };
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });

  }
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    } else {
      fetch("/admin/advertiserInfo", {
        method: "POST",
        headers: { "Content-Type": "application/json" }
      }).then(res => res.json())
        .then(advertiserInfo => {
          this.setState({ advertiserDetails: advertiserInfo, advertiserCount: advertiserInfo.length })
        }).catch((e) => console.log(e));

      fetch("/admin/agencyInfo", {
        method: "POST",
        headers: { "Content-Type": "application/json" }
      }).then(res => res.json())
        .then(agencyInfo => {
          this.setState({ agencyDetails: agencyInfo, agencyCount: agencyInfo.length })
        }).catch((e) => console.log(e));

      fetch("/admin/publisherInfo", {
        method: "POST",
        headers: { "Content-Type": "application/json" }
      }).then(res => res.json())
        .then(publisherInfo => {
          this.setState({ publisherCount: publisherInfo.length, publisherDetails: publisherInfo })
        }).catch((e) => console.log(e));

      fetch("/admin/agencyDetails")
        .then(res => res.json())
        .then(agencyReviewDetails => {
          if (agencyReviewDetails.success === true) {
            /*alert("agencyReviewDetails="+JSON.stringify(agencyReviewDetails))
           alert("agencyReviewCount="+agencyReviewDetails.length)*/
            var message = agencyReviewDetails.message;
            this.setState({ message: message, agencyReviewCount: 0 })
          } else {
            this.setState({ agencyReviewDetails: agencyReviewDetails, agencyReviewCount: agencyReviewDetails.length })
          }
        }).catch((e) => console.log(e));


      fetch("/admin/advertiserDetails")
        .then(res => res.json())
        .then(advertiserReviewDetails => {
          if (advertiserReviewDetails.success === true) {
            var message = advertiserReviewDetails.message;
            this.setState({ message: message, advertiserReviewCount: 0 })
          } else {
            this.setState({ advertiserReviewDetails: advertiserReviewDetails, advertiserReviewCount: advertiserReviewDetails.length })
          }
        }).catch((e) => console.log(e));

      fetch("/admin/publisherDetails")
        .then(res => res.json())
        .then(publisherReviewDetails => {
          if (publisherReviewDetails.success === true) {
            var message = publisherReviewDetails.message;
            this.setState({ message: message, publisherReviewCount: 0 })
          } else {
            /*alert("publisherReviewDetails="+JSON.stringify(publisherReviewDetails))
            alert("publisherReviewCount="+publisherReviewDetails.length)*/
            this.setState({ publisherReviewDetails: publisherReviewDetails, publisherReviewCount: publisherReviewDetails.length })
          }
        }).catch((e) => console.log(e));
    }
  }

  submituserRegistrationForm(e) {

  }

  reviewAdvertiserLink(e) {
    window.location.href = "/reviewAdvertiser";
  }
  reviewAgencyLink(e) {
    window.location.href = "/reviewAgency";

  }
  reviewPublisherLink(e) {
    window.location.href = "/reviewPublisher";

  }
  linkPublisher(e) {
    window.location.href = "/linkPublisher";
  }
/**
* @author Somnath Keswad
* @param  Description Task-3930, Link to Advertiser
* @return Page redirect to linkAdvertiser
*/
  linkAdvertiser(e){
    e.preventDefault();
    this.props.history.push("/linkAdvertiser");
  }


  render() {
    return (
      <div>

        <AdminNavpage />

        <div class="container-fluid" style={{ marginTop: "70px" }}>
          <br /><br /><br />
          <h3 class="text-primary pl-2">Overview</h3>
          <div class="row">
            <div class="col-xs-3  col-md-3 col-sm-3 col-lg-3">
              <div class="card card1 ">
                <div class="card-body text-left">
                  <img class="img-fluid float-left" src="advertiser.png" style={{ width: '100px' }} ></img>
                  <h4 class="card-title text-light text-right">Advertiser</h4>
                  <p style={{ fontSize: '20px' }} class="card-text text-light text-right">{this.state.advertiserCount}</p>
                </div>
              </div>
            </div>
            <div class="col-xs-3 col-md-3 col-sm-3 col-lg-3">
              <div class="card card2  ">
                <div class="card-body text-left ">
                  <img class="img-fluid float-left" src="agency.png" style={{ width: '100px' }} ></img>
                  <h4 class="card-title text-light text-right">Agency</h4>
                  <p style={{ fontSize: '20px' }} class="card-text text-light text-right">{this.state.agencyCount}</p>
                </div>
              </div>
            </div>
            <div class="col-xs-3 col-md-3 col-sm-3 col-lg-3">
              <div class="card card3 ">
                <div class="card-body text-left">
                  <img class="img-fluid float-left" src="publisher.png" style={{ width: '100px' }} ></img>
                  <h4 class="card-title text-light text-right">Publisher </h4>
                  <p style={{ fontSize: '20px' }} class="card-text text-light text-right">{this.state.publisherCount}</p>
                </div>
              </div>
            </div>

          </div><br /><br /><br />


          <div class="row">
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">

              <h3 class="text-primary">Review</h3>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 div1 ">
                <Badge count={this.state.advertiserReviewCount}>
                  <a href="#" onClick={this.reviewAdvertiserLink.bind(this)}><Button className="btn1 " style={{ width: '300px', height: '100px', backgroundImage: 'none' }}>
                    <img class="img-fluid float-left" src="advertiser.png" style={{ width: '100px' }} ></img><p className="adv"> Advertiser</p></Button></a>
                </Badge>
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 div1">
                <Badge count={this.state.agencyReviewCount}>
                  <a href="#" onClick={this.reviewAgencyLink.bind(this)}><Button className="btn2 " style={{ width: '300px', height: '100px', backgroundImage: 'none' }}>
                    <img class="img-fluid float-left" src="agency.png" style={{ width: '100px' }} ></img><p className="agency">Agency</p></Button></a>
                </Badge>
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 div1">
                <Badge count={this.state.publisherReviewCount}>
                  <a href="#" onClick={this.reviewPublisherLink.bind(this)}><Button className="btn3 " style={{ width: '300px', height: '100px', backgroundImage: 'none', fontStyle: 'bold' }}>
                    <img class="img-fluid float-left" src="publisher.png" style={{ width: '100px' }} ></img><p className="publisher">Publisher</p></Button></a>
                </Badge>
              </div>
            </div>


            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 link bg-light">
              <h3 class="text-left text-primary pt-3">Link</h3>
              <a href="#" onClick={this.linkPublisher.bind(this)} class="btn btnlink1  btn-lg active btn-center" role="button" ><i class="fas fa-paperclip"></i>Link Publisher With Agency</a>
              <br />
              <a href="#" onClick={this.linkAdvertiser.bind(this)} style={{ marginTop: '12px' }} class="btn btnlink2  btn-lg active btn-center" role="button" ><i class="fas fa-paperclip"></i>Link Agency With Advertiser</a>
            </div>
          </div>
        </div>
        <br /><br /><br /><br /><br />

        <Footer />

      </div>

    );
  }


}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
Admin.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(Admin)); 