/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Somnath Keswad
@Creation Date:28-03-2019
@Description:Adding New Publisher
*/
import React from "react";
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Navigation from "../layouts/navPage";
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
class AddPublisher extends React.Component
 {

    constructor() {
        super();
        this.state = 
        { 

        };
    }//end Of Constructer
        render() {
            // const {isAuthenticated, user} = this.props.auth;

            return(
<div> <Navigation />
<img src='underConstruction.jpg' /></div>
            );
            }
}


AddPublisher.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(AddPublisher)); 