/* Copyright(c) 2023 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Aman Shaikh - 5060
*@fileName :emailStatusChecker.js
*Desc:
*@Date of creation: 08/04/2024

*/
import React from "react";
import * as $ from "jquery";
import PropTypes from "prop-types";
import { MDBRow, MDBCol } from "mdbreact";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/publisherNavPage";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Tooltip, Icon } from "antd";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
const Swal = require("sweetalert2");

var suppValidationArray = [];
var suppDMAValidationArray = [];

var suppressionnames = [];
var DMAsuppressionnames = [];

var newsuppressionfilenames = [];
var newDMAsuppressionfilenames = [];

var successSuppressionCount = [];
var DMAsuccessSuppressionCount = [];
class emailStatusChecker extends React.Component {
  constructor() {
    super();
    this.state = {
      loader_progress: "", //Aman-5060 - set the loader state
      comparisonResult: [], //FinalfileData
      matchedCount: "", //Total file match count
      notMatchedCount: "", //Total file not match count
      compression: false, //compression success toggle to show dwonload button

      displayTablesuppression: "none",
      displayDMATablesuppression: "none",

      suppressionerror: {},
      suppressionDMAerror: {},

      suppressionData: [], // suppressionfileData
      DMAsuppressionData: [], // DMAsuppressionData

      suppressionFlag: false,
      suppressionDMAFlag: false,

      suppressionFilesSelectedDocuments: [],
      DMAsuppressionFilesSelectedDocuments: [],

      uploadedFile: false,
      uploadedDMAFile: false,
    };
    this.compareFiles = this.compareFiles.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.uploadSuppressionFile = this.uploadSuppressionFile.bind(this);
    this.uploadDMASuppressionFile = this.uploadDMASuppressionFile.bind(this);
  } /*** End of constructor */

  /**
   * @author Aman Shaikh
   * @param  Description Task-5060 Add Back Button
   * @return return on Dashboard Page
   */
  backButtonFunction(e) {
    e.preventDefault();
    this.props.history.push("/newPublisherDashboard");
  }

  /**
   * @author Aman Shaikh
   * @param  Description Task-5060 Added to get result
   * @return return on Output zip file data
   */
  handleDownload = () => {
    const { comparisonResult } = this.state;

    // Show loading spinner manually
    Swal.showLoading();

    if (comparisonResult.length !== 0 || "" || null) {
      // Convert base64 encoded ZIP file to Uint8Array
      const uint8Array = new Uint8Array(
        atob(comparisonResult)
          .split("")
          .map((char) => char.charCodeAt(0))
      );

      const currentDate = new Date().toISOString().replace(/[-T:.]/g, "_"); // Replace '-', 'T', ':' and '.' with '_' to make the string compatible with filenames
      const formattedDate = currentDate.slice(0, 19).replace(/_/g, "-"); // Keep only the date and time part and replace '_' with '-'
      // Create a Blob from the Uint8Array
      const blob = new Blob([uint8Array], { type: "application/zip" });

      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `EmailStatus_Data_Checker_${formattedDate}.zip`; //Aman-5108 chnaged BriteStatus Verifier to EmailStatusChecker //Aman-5111-PC/PNC-Settings-Email Status Checker-Change Labels
      document.body.appendChild(link);

      // Trigger a click on the link to initiate download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Close Swal after the file is downloaded
      Swal.close();

      Swal.fire({
        text: "File Downloaded successfully.",
        type: "success",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        preConfirm: () => {
          window.location.reload();
        },
      });
    } else {
      Swal.fire({
        type: "warning",
        title: "No data to Download",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        preConfirm: () => {
          window.location.reload();
        },
      });
    }
  };

  /**
   * @author Aman Shaikh
   * @param  Description Task-5060 Added to send uploaded file data
   * @return return the data in zip frm from BE
   */
  compareFiles(e) {
    e.preventDefault();
    const { user } = this.props.auth;
    var UserName = user.firstName + " " + user.lastName;
    //Task-5060 - this condition check client and DMA file data is present or not
    if (
      newsuppressionfilenames.length === 0 &&
      newDMAsuppressionfilenames.length === 0
    ) {
      let alertmessage = "Please Upload Files";

      Swal.fire({
        text: alertmessage,
        type: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    } else if (newsuppressionfilenames.length === 0) {
      var alertmessage = "Please Upload Validated Data File"; //Aman-5111-PC/PNC-Settings-Email Status Checker-Change Labels

      Swal.fire({
        text: alertmessage,
        type: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    } else if (newDMAsuppressionfilenames.length === 0) {
      let alertmessage = "Please Upload DMA Data File"; //Aman-5111-PC/PNC-Settings-Email Status Checker-Change Labels

      Swal.fire({
        text: alertmessage,
        type: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    } else {
      // Show the SweetAlert with a progress bar and loader icon
      Swal.fire({
        title: "Please wait processing your file",
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false, // Prevent closing by clicking outside
        showConfirmButton: false, // Remove the "OK" button
        didOpen: () => {
          // Create a progress bar element
          Swal.getHtmlContainer().appendChild(document.createElement("div"));
        },
      });
      let suppressionData = [];
      let filnalClientData = [];

      let DMAsuppressionData = [];
      let filnalDMAData = [];

      suppressionData = this.state.suppressionData;
      //let length = this.state.suppressionData.length;

      DMAsuppressionData = this.state.DMAsuppressionData;

      // Iterate over DMAsuppressionData array
      for (let i = 0; i < DMAsuppressionData.length; i++) {
        // Check if fileName exists in newDMAsuppressionfilenames array
        if (
          newDMAsuppressionfilenames.includes(DMAsuppressionData[i].fileName)
        ) {
          // If matched, push the object to matchedData array
          filnalDMAData.push(DMAsuppressionData[i]);
        }
      }

      //Aman-5060-Iterate over suppressionData array
      for (let i = 0; i < suppressionData.length; i++) {
        // Check if fileName exists in newsuppressionfilenames array
        if (newsuppressionfilenames.includes(suppressionData[i].fileName)) {
          // If matched, push the object to matchedData array
          filnalClientData.push(suppressionData[i]);
        }
      }

      const formData = new FormData();
      formData.append("UserName", UserName); //Aman-5060-passing the Uploaded userName
      formData.append("supFileData", filnalDMAData[0].fileData); //passing fileData instead of whole record
      for (let i = 0; i < filnalClientData.length; i++) {
        //Aman-5060-changed length to filnalClientData.length
        let fileName = filnalClientData[i].fileName; // Access fileName property
        if (newsuppressionfilenames.includes(fileName)) {
          formData.append("suppressionData", filnalClientData[i].fileData); // Append fileData
        }
      }
      fetch("/emailStatusChecker/emailStatusChecker", { //Aman-5108 chnaged BriteStatus Verifier to EmailStatusChecker
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          // Close the SweetAlert
          Swal.close();

          let msg;
          let message = document.getElementById("suppresionDMAMessage");

          let Clientmsg;
          let ClientMessage = document.getElementById("suppresionMessage");

          var suppDocName = []; //Task-5060 - client supp file Name
          var DMAsuppDocName = []; //Task-5060 - DMA supp file Name

          //Task-5060 - if file compression is done then it will go in if condtion
          if (data.success === true) {
            const { compression, matchedResults, notMatchedResults, zipFile } =
              data;
            this.setState({
              compression: compression,
              matchedCount: matchedResults,
              notMatchedCount: notMatchedResults,
              comparisonResult: zipFile,
            });
            // Close the SweetAlert
            Swal.close();
          } else {
            //Task-5060 - checking total client uploaded file count
            function countClientFiles(data) {
              if (
                !data ||
                !data.ClientFileValidation ||
                !Array.isArray(data.ClientFileValidation)
              ) {
                return 0; // Return 0 if the data or ClientFileValidation is undefined or not an array
              }
              return data.ClientFileValidation.filter(
                (item) => item.fileType === "Client"
              ).length;
            }
            var ClientFileValidationCount = countClientFiles(data);

            //Task-5060 - if client uploaded file count is more then 1 then it will go un if otherwise else
            if (ClientFileValidationCount > 1) {
              if (data.ClientFileValidation[0].fileType === "Client") {
                for (var i = 0; i < data.ClientFileValidation.length; i++) {
                  if (data.ClientFileValidation[i].success === true) {
                    suppDocName.push(data.ClientFileValidation[i].filenames);
                    successSuppressionCount.push(
                      data.ClientFileValidation[i].filenames
                    );
                  }
                }
                suppDocName = [...new Set(suppDocName)];

                let RejectedCount = 0;
                var HTML = "<table style='width:100%'>";

                let suppressionFilesArrayUI =
                  this.state.suppressionFilesSelectedDocuments;

                for (let j = 0; j < newsuppressionfilenames.length; j++) {
                  var isExist = suppDocName.includes(
                    newsuppressionfilenames[j]
                  );

                  let toBeState;
                  if (isExist === true) {
                    toBeState = {
                      success: true,
                      suppDocName: newsuppressionfilenames[j],
                      typeOfSuppDoc: "Suppression",
                      //loading: false,
                    };
                  } else {
                    RejectedCount = RejectedCount + 1;

                    toBeState = {
                      success: false,
                      suppDocName: newsuppressionfilenames[j],
                      typeOfSuppDoc: "Suppression",
                      //loading: false,
                    };
                  }
                  suppressionFilesArrayUI = [
                    ...suppressionFilesArrayUI.filter(
                      (item) => item.suppDocName !== toBeState.suppDocName
                    ),
                    toBeState,
                  ];
                }
                this.setState({
                  suppressionFilesSelectedDocuments: suppressionFilesArrayUI,
                });

                HTML += "</table>";
                document.getElementById("suppressionList").innerHTML = HTML;
                const hasHeaderFile = data.ClientFileValidation.some(
                  (data) => data.ValidationMsg === "inValidHeader"
                ); //Task-5060 - checking file header error
                const hasEmptyFile = data.ClientFileValidation.some(
                  (data) => data.ValidationMsg === "FileEmpty"
                ); //Task-5060 - checking file Empty error

                if (RejectedCount > 0) {
                  if (hasHeaderFile === true && hasEmptyFile === true) {
                    Clientmsg =
                      "<font color='Red'>Please Upload Valid Header File or Valid Data File</font>";
                  } else if (hasHeaderFile === true && hasEmptyFile === false) {
                    Clientmsg =
                      "<font color='Red'>Please Upload Valid Header File or Valid Data File</font>"; //Aman-5060- chnaged the message
                  } else {
                    Clientmsg =
                      "<font color='Red'>Uploaded File is Empty</font>";
                  }
                } else {
                  Clientmsg =
                    "<font color = 'Green'>Suppression File Uploaded Successfully</font>";
                }
                if (ClientMessage === "") {
                  ClientMessage.innerHTML += Clientmsg;
                } else {
                  ClientMessage.innerHTML = Clientmsg;
                }
              }
            } else {
              //Task-5060 - if client uploaded file count is only  1 then it will go run if
              if (data.ClientFileValidation.success === false || true) {
                //Aman-5060-added === false || true to handle success status to get file name
                suppDocName.push(data.ClientFileValidation.filenames);
                successSuppressionCount.push(
                  data.ClientFileValidation.filenames
                );
              }
              suppDocName = [...new Set(suppDocName)];

              let RejectedCount = 0;
              let HTML = "<table style='width:100%'>";

              let suppressionFilesArrayUI =
                this.state.suppressionFilesSelectedDocuments;

              for (let j = 0; j < newsuppressionfilenames.length; j++) {
                let isExist = suppDocName.includes(newsuppressionfilenames[j]);
                let toBeState;
                if (
                  isExist === true &&
                  data.ClientFileValidation.success === false
                ) {
                  //Aman-5060-added ClientFileValidation to check file success false
                  RejectedCount = RejectedCount + 1; //Aman-5060-added RejectedCount here instead of else to get set file status
                  toBeState = {
                    success: false,
                    suppDocName: newsuppressionfilenames[j],
                    typeOfSuppDoc: "Suppression",
                    //loading: false,
                  };
                } else {
                  toBeState = {
                    success: true, //Aman-5060-change status to true if file is valid
                    suppDocName: newsuppressionfilenames[j],
                    typeOfSuppDoc: "Suppression",
                    //loading: false,
                  };
                }
                suppressionFilesArrayUI = [
                  ...suppressionFilesArrayUI.filter(
                    (item) => item.suppDocName !== toBeState.suppDocName
                  ),
                  toBeState,
                ];
              }

              this.setState({
                suppressionFilesSelectedDocuments: suppressionFilesArrayUI,
              });

              HTML += "</table>";
              document.getElementById("suppressionList").innerHTML = HTML;
              const hasHeaderFile =
                data.ClientFileValidation.ValidationMsg === "inValidHeader";
              const hasEmptyFile =
                data.ClientFileValidation.ValidationMsg === "FileEmpty";

              if (RejectedCount > 0) {
                //Aman-5060-chenaged RejectedCount === 0  to > 0 condition
                if (hasHeaderFile === true && hasEmptyFile === true) {
                  Clientmsg =
                    "<font color='Red'>Please Upload Valid Header File or Valid Data File</font>";
                } else if (hasHeaderFile === true && hasEmptyFile === false) {
                  Clientmsg =
                    "<font color='Red'>Please Upload Valid Header File or Valid Data File</font>"; //Aman-5060- chnaged the message
                } else {
                  Clientmsg = "<font color='Red'>Uploaded File is Empty</font>";
                }
              } else {
                Clientmsg = "";
              }

              if (ClientMessage === "") {
                ClientMessage.innerHTML += Clientmsg;
              } else {
                ClientMessage.innerHTML = Clientmsg;
              }
            }

            //Task-5060 - if uploaded file is DMA then it will go in if
            if (data.DMAFileValidation.fileType === "DMA") {
              if (data.DMAFileValidation.success === false) {
                DMAsuppDocName.push(data.DMAFileValidation.filenames);
                DMAsuccessSuppressionCount.push(
                  data.DMAFileValidation.filenames
                );
              }
              DMAsuppDocName = [...new Set(DMAsuppDocName)];

              let RejectedCount = 0;
              let HTML = "<table style='width:100%'>";

              let suppressionFilesArrayUI =
                this.state.DMAsuppressionFilesSelectedDocuments;

              for (let j = 0; j < newDMAsuppressionfilenames.length; j++) {
                let isExist = DMAsuppDocName.includes(
                  newDMAsuppressionfilenames[j]
                );

                let toBeState;
                if (isExist === true) {
                  toBeState = {
                    success: false,
                    DMAsuppDocName: newDMAsuppressionfilenames[j],
                    typeOfSuppDoc: "Suppression",
                    //loading: false,
                  };
                } else {
                  RejectedCount = RejectedCount + 1;

                  toBeState = {
                    success: true,
                    DMAsuppDocName: newDMAsuppressionfilenames[j],
                    typeOfSuppDoc: "Suppression",
                    //loading: false,
                  };
                }
                suppressionFilesArrayUI = [
                  ...suppressionFilesArrayUI.filter(
                    (item) => item.DMAsuppDocName !== toBeState.DMAsuppDocName
                  ),
                  toBeState,
                ];
              }

              this.setState({
                DMAsuppressionFilesSelectedDocuments: suppressionFilesArrayUI,
              });

              HTML += "</table>";
              document.getElementById("suppressionList").innerHTML = HTML;

              if (RejectedCount === 0) {
                if (
                  data.DMAFileValidation.ValidationMsg === "inValidHeader" &&
                  data.DMAFileValidation.ValidationMsg === "FileEmpty"
                ) {
                  msg =
                    "<font color='Red'>Please Upload Valid Header File or Valid Data File</font>";
                } else if (
                  data.DMAFileValidation.ValidationMsg === "inValidHeader" &&
                  data.DMAFileValidation.ValidationMsg !== "FileEmpty"
                ) {
                  msg =
                    "<font color='Red'>Please Upload Valid Header File or Valid Data File</font>"; //Aman-5060- chnaged the message
                } else {
                  msg = "<font color='Red'>Uploaded File is Empty</font>";
                }
              } else {
                msg = "";
              }
              if (message === "") {
                message.innerHTML += msg;
              } else {
                message.innerHTML = msg;
              }
            }
          }
        })
        .catch((error) => {
          // console.error("Error comparing files:", error);
          // Show an error SweetAlert
          Swal.fire({
            icon: "error",
            title: `<font color='red'><b>Error</b></font>`,
            html: `An error occurred while processing your file. <br> Please Upload valid file`, //Aman-5060- chnaged the message
            allowOutsideClick: false, // Prevent closing by clicking outside
            showConfirmButton: true, // Show the "OK" button
            preConfirm: () => {
              window.location.reload();
            },
          });
        });
    }
  }

  /**
   * @author Aman Shaikh
   * @param  Description Task-5060 added to handle Client Suppression File
   * @return return it accept the multiple client file
   */
  uploadSuppressionFile(e) {
    e.preventDefault();
    suppressionnames = [];
    let suppressionerror = {};
    let self = this;
    e = document.createElement("input"); //sunita-task-3101-creating a input element but not binding it to body
    e.setAttribute("type", "file"); //sunita-task-3101-setting a attribute for type file
    e.setAttribute("multiple", true); //sunita-task-3101-setting a attribute for multiple
    e.click(); //sunita-task-3101-to open file dialouge
    e.addEventListener(
      "change",
      function () {
        //sunita-task-3101-calling an eventListener at change event
        self.setState({ displayTablesuppression: "block" });
        let message = document.getElementById("suppresionMessage");
        let msg = "";
        message.innerHTML = msg;
        let suppressionfilesData = e.files; //sunita-task-3101-getting filelist object.
        let suppressionData = this.state.suppressionData;
        //if(suppressionObject.length>0){
        for (let p = 0; p < suppressionfilesData.length; p++) {
          let file = suppressionfilesData[p];

          let found = suppressionData.findIndex(
            (item) => item.fileName === file.name
          );

          if (found === -1) {
            let obj = {};
            obj.fileName = file.name;
            obj.typeOfSuppDoc = "Suppression";
            obj.fileData = file;
            suppressionData.push(obj);
          } else {
            let obj = suppressionData[found];
            obj.fileData = file;
            suppressionData[found] = obj;
          }
        }
        for (var i = 0, l = suppressionfilesData.length; i < l; i++) {
          suppressionnames[i] = suppressionfilesData[i].name;
        }

        var suppressionarrayLength = suppressionnames.length;
        var nosuppressionoutput = document.getElementById("nosuppressionList");
        var valuenosuppressionoutput =
          document.getElementById("nosuppressionList").innerHTML;
        var suppressionres = [];
        suppressionres = valuenosuppressionoutput.split(" ");
        var backsuppressionarraylength;
        for (i = 0; i < suppressionres.length; i++) {
          backsuppressionarraylength = suppressionres[0];
        }
        suppressionarrayLength = parseInt(suppressionarrayLength);

        var suppressionarrayLength1;
        suppressionarrayLength1 = suppressionarrayLength;

        if (suppressionarrayLength1 >= 1) {
          var allowedFiles = [".xls", ".xlsx", ".csv"];
          var suppression = suppressionfilesData;
          var regex = new RegExp(
            "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
          );
          if (!regex.test(suppression[0].name.toLowerCase())) {
            //sunita-task-3101-get the uploaded filename
            suppressionerror["suppressionerr"] =
              "Please upload files having extensions: " +
              allowedFiles.join(", ") +
              " only";
            this.setState({ suppressionerror: suppressionerror });
          } else {
            for (var j = 0; j < suppressionarrayLength; j++) {
              newsuppressionfilenames.push(suppressionnames[j]);
            }
            var FileExt;
            var HTML = "<table style='width:100%'>";
            var countSuppressison = 0;
            let suppressionFilesArrayUI =
              self.state.suppressionFilesSelectedDocuments;
            for (let j = 0; j < newsuppressionfilenames.length; j++) {
              if (document.getElementById("suppressionError") !== null) {
                document.getElementById("suppressionError").innerHTML = "";
              }
              FileExt = newsuppressionfilenames[j].split(".").pop();
              if (
                FileExt.toString() === "csv" ||
                FileExt.toString() === "xlsx" ||
                FileExt.toString() === "xls"
              ) {
              } else {
                countSuppressison = countSuppressison + 1;
              }
              var isExist = suppValidationArray.includes(
                newsuppressionfilenames[j]
              );

              let loading = true;
              let index = suppressionFilesArrayUI.findIndex(
                (item) =>
                  item.suppDocName === newsuppressionfilenames[j] &&
                  item.loading === false
              );
              if (index > -1) {
                loading = false;
              }

              let toBeState;
              if (isExist === true) {
                toBeState = {
                  success: false,
                  suppDocName: newsuppressionfilenames[j],
                  typeOfSuppDoc: "Suppression",
                  //loading,
                };
              } else {
                toBeState = {
                  success: true,
                  suppDocName: newsuppressionfilenames[j],
                  typeOfSuppDoc: "Suppression",
                  //loading,
                };
              }

              suppressionFilesArrayUI = [
                ...suppressionFilesArrayUI.filter(
                  (item) => item.suppDocName !== toBeState.suppDocName
                ),
                toBeState,
              ];
            }

            self.setState({
              suppressionFilesSelectedDocuments: suppressionFilesArrayUI,
            });

            HTML += "</table>";

            document.getElementById("suppressionList").innerHTML = HTML;
            if (countSuppressison > 0) {
              var msg3 =
                "Please upload files having extensions: " +
                allowedFiles.join(", ") +
                " only";
              var suppressionErrorFile =
                document.getElementById("suppressionError");
              suppressionErrorFile.innerHTML += msg3;
            }

            $(document).on("click", ".removeSuppressionFile", function (e) {
              e.preventDefault();

              var clickedFile = $(this).parent().children("a").data("fileid");
              var j = 0;
              for (j; j < newsuppressionfilenames.length; j++) {
                var temp = j;
                if (newsuppressionfilenames[temp] === clickedFile) {
                  var isExist = suppValidationArray.includes(
                    newsuppressionfilenames[j]
                  );
                  if (isExist === true) {
                    var index = suppValidationArray.indexOf(
                      newsuppressionfilenames[j]
                    );
                    suppValidationArray.splice(index, 1);
                  }

                  newsuppressionfilenames.splice(j, 1);
                  $(this).closest("td").remove();
                  suppressionarrayLength = newsuppressionfilenames.length;
                  if (newsuppressionfilenames.length === 0) {
                    suppValidationArray = [];
                  }

                  var msg1 = "";
                  var message1 = document.getElementById("suppresionMessage");
                  if ($("#suppresionMessage").text() === "") {
                    message1.innerHTML += msg1;
                  } else {
                    message1.innerHTML = msg1;
                  }

                  if (newsuppressionfilenames.length === 0) {
                    self.setState({
                      displayTablesuppression: "none",
                      disabled: "",
                    });
                  }

                  /*** Remove File Code */
                  var suppressionfilesData = self.state.suppressionData;
                  let newSuppressionFileData = [];

                  for (let i = 0; i < suppressionfilesData.length; i++) {
                    if (
                      suppressionfilesData[i].name.toString() !==
                      clickedFile.toString()
                    ) {
                      newSuppressionFileData.push(suppressionfilesData[i]);
                    }
                  }

                  self.setState({ suppressionData: newSuppressionFileData });
                  suppressionarrayLength = parseInt(suppressionarrayLength);
                  backsuppressionarraylength = parseInt(
                    backsuppressionarraylength
                  );
                  suppressionarrayLength1 =
                    suppressionarrayLength + backsuppressionarraylength;

                  if (suppressionarrayLength1 > 0) {
                    nosuppressionoutput.innerHTML =
                      suppressionarrayLength1 + " Suppression  Files Selected";
                  } else {
                    nosuppressionoutput.innerHTML = "";
                    $("#suppression").val("");
                    var message = document.getElementById("suppresionMessage");
                    message.innerHTML = "";
                  }
                } // end of  if(names[temp] === clickedFile)
              } // end of for
            }); //$(document).on

            /** Add File Code*/
            suppressionerror["suppressionerr"] = "";
            document.getElementById("submitButton").disabled = false;
            this.setState({
              uploadedFile: true,
              suppressionFlag: true,
              suppressionData: suppressionData,
              disabled: "drpdisable",
              suppressionerror: suppressionerror,
            });
          }
        } else {
          nosuppressionoutput.innerHTML = "";
        }
      }.bind(this)
    );
  }

  /**
   * @author Aman Shaikh
   * @param  Description Task-5060 added to handle DMA Suppression File
   * @return return it accept the One DMA file
   */
  uploadDMASuppressionFile(e) {
    e.preventDefault();
    if (newDMAsuppressionfilenames.length === 0) {
      DMAsuppressionnames = [];
      let suppressionDMAerror = {};
      let self = this;
      e = document.createElement("input"); //sunita-task-3101-creating a input element but not binding it to body
      e.setAttribute("type", "file"); //sunita-task-3101-setting a attribute for type file
      // e.setAttribute("multiple", true); //sunita-task-3101-setting a attribute for multiple
      e.click(); //sunita-task-3101-to open file dialouge
      e.addEventListener(
        "change",
        function () {
          //sunita-task-3101-calling an eventListener at change event
          self.setState({ displayDMATablesuppression: "block" });
          let message = document.getElementById("suppresionDMAMessage");
          let msg = "";
          message.innerHTML = msg;
          let suppressionfilesData = e.files; //sunita-task-3101-getting filelist object.
          let DMAsuppressionData = this.state.DMAsuppressionData;

          // Aman-5060 - upload Client Suppression File Div
          let file = suppressionfilesData[0];

          let found = DMAsuppressionData.findIndex(
            (item) => item.fileName === file.name
          );

          if (found === -1) {
            let obj = {};
            obj.fileName = file.name;
            obj.typeOfSuppDoc = "Suppression";
            obj.fileData = file;
            DMAsuppressionData.push(obj);
          } else {
            let obj = DMAsuppressionData[found];
            obj.fileData = file;
            DMAsuppressionData[found] = obj;
          }

          DMAsuppressionnames[0] = file.name;

          for (var i = 0, l = suppressionfilesData.length; i < l; i++) {
            DMAsuppressionnames[i] = suppressionfilesData[i].name;
          }

          var suppressionarrayLength = DMAsuppressionnames.length;
          var nosuppressionoutput =
            document.getElementById("nosuppressionList");
          var valuenosuppressionoutput =
            document.getElementById("nosuppressionList").innerHTML;
          var suppressionres = [];
          suppressionres = valuenosuppressionoutput.split(" ");
          var backsuppressionarraylength;
          for (i = 0; i < suppressionres.length; i++) {
            backsuppressionarraylength = suppressionres[0];
          }
          suppressionarrayLength = parseInt(suppressionarrayLength);

          var suppressionarrayLength1;
          suppressionarrayLength1 = suppressionarrayLength;

          if (suppressionarrayLength1 >= 1) {
            var allowedFiles = [".xls", ".xlsx", ".csv"];
            var suppression = suppressionfilesData;
            var regex = new RegExp(
              "([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
            );
            if (!regex.test(suppression[0].name.toLowerCase())) {
              //sunita-task-3101-get the uploaded filename
              suppressionDMAerror["suppressionerr"] =
                "Please upload files having extensions: " +
                allowedFiles.join(", ") +
                " only";
              this.setState({ suppressionDMAerror: suppressionDMAerror });
            } else {
              for (var j = 0; j < suppressionarrayLength; j++) {
                newDMAsuppressionfilenames.push(DMAsuppressionnames[j]);
              }
              var FileExt;
              var HTML = "<table style='width:100%'>";
              var countSuppressison = 0;
              let suppressionFilesArrayUI =
                self.state.DMAsuppressionFilesSelectedDocuments;
              for (let j = 0; j < newDMAsuppressionfilenames.length; j++) {
                if (document.getElementById("suppressionDMAerror") !== null) {
                  document.getElementById("suppressionDMAerror").innerHTML = "";
                }
                FileExt = newDMAsuppressionfilenames[j].split(".").pop();
                if (
                  FileExt.toString() === "csv" ||
                  FileExt.toString() === "xlsx" ||
                  FileExt.toString() === "xls"
                ) {
                } else {
                  countSuppressison = countSuppressison + 1;
                }
                var isExist = suppDMAValidationArray.includes(
                  newDMAsuppressionfilenames[j]
                );

                let loading = true;
                let index = suppressionFilesArrayUI.findIndex(
                  (item) =>
                    item.DMAsuppDocName === newDMAsuppressionfilenames[j] &&
                    item.loading === false
                );
                if (index > -1) {
                  loading = false;
                }

                let toBeState;
                if (isExist === true) {
                  toBeState = {
                    success: false,
                    DMAsuppDocName: newDMAsuppressionfilenames[j],
                    typeOfSuppDoc: "Suppression",
                    //loading,
                  };
                } else {
                  toBeState = {
                    success: true,
                    DMAsuppDocName: newDMAsuppressionfilenames[j],
                    typeOfSuppDoc: "Suppression",
                    //loading,
                  };
                }

                suppressionFilesArrayUI = [
                  ...suppressionFilesArrayUI.filter(
                    (item) => item.DMAsuppDocName !== toBeState.DMAsuppDocName
                  ),
                  toBeState,
                ];
              }

              self.setState({
                DMAsuppressionFilesSelectedDocuments: suppressionFilesArrayUI,
              });

              HTML += "</table>";

              document.getElementById("suppressionDMAList").innerHTML = HTML;
              if (countSuppressison > 0) {
                var msg3 =
                  "Please upload files having extensions: " +
                  allowedFiles.join(", ") +
                  " only";
                var suppressionErrorFile = document.getElementById(
                  "suppressionDMAerror"
                );
                suppressionErrorFile.innerHTML += msg3;
              }

              $(document).on(
                "click",
                ".removeDMASuppressionFile",
                function (e) {
                  e.preventDefault();

                  var clickedFile = $(this)
                    .parent()
                    .children("a")
                    .data("fileid");
                  var j = 0;
                  for (j; j < newDMAsuppressionfilenames.length; j++) {
                    var temp = j;
                    if (newDMAsuppressionfilenames[temp] === clickedFile) {
                      var isExist = suppDMAValidationArray.includes(
                        newDMAsuppressionfilenames[j]
                      );
                      if (isExist === true) {
                        var index = suppDMAValidationArray.indexOf(
                          newDMAsuppressionfilenames[j]
                        );
                        suppDMAValidationArray.splice(index, 1);
                      }

                      newDMAsuppressionfilenames.splice(j, 1);
                      $(this).closest("td").remove();
                      suppressionarrayLength =
                        newDMAsuppressionfilenames.length;
                      if (newDMAsuppressionfilenames.length === 0) {
                        suppDMAValidationArray = [];
                      }

                      var msg1 = "";
                      var message1 = document.getElementById(
                        "suppresionDMAMessage"
                      );
                      if ($("#suppresionDMAMessage").text() === "") {
                        message1.innerHTML += msg1;
                      } else {
                        message1.innerHTML = msg1;
                      }

                      if (newDMAsuppressionfilenames.length === 0) {
                        self.setState({
                          displayDMATablesuppression: "none",
                          disabled: "",
                        });
                      }
                      /*** Remove File Code */
                      var suppressionfilesData = self.state.DMAsuppressionData;
                      let newSuppressionFileData = [];

                      for (let i = 0; i < suppressionfilesData.length; i++) {
                        if (
                          suppressionfilesData[i].name.toString() !==
                          clickedFile.toString()
                        ) {
                          newSuppressionFileData.push(suppressionfilesData[i]);
                        }
                      }

                      self.setState({
                        DMAsuppressionData: newSuppressionFileData,
                      });
                      suppressionarrayLength = parseInt(suppressionarrayLength);
                      backsuppressionarraylength = parseInt(
                        backsuppressionarraylength
                      );
                      suppressionarrayLength1 =
                        suppressionarrayLength + backsuppressionarraylength;

                      if (suppressionarrayLength1 > 0) {
                        nosuppressionoutput.innerHTML =
                          suppressionarrayLength1 +
                          " Suppression  Files Selected";
                      } else {
                        nosuppressionoutput.innerHTML = "";
                        $("#suppression").val("");
                        var message = document.getElementById(
                          "suppresionDMAMessage"
                        );
                        message.innerHTML = "";
                      }
                    } // end of  if(names[temp] === clickedFile)
                  } // end of for
                }
              ); //$(document).on

              /** Add File Code*/
              suppressionDMAerror["suppressionerr"] = "";
              document.getElementById("submitButton").disabled = false;
              this.setState({
                uploadedDMAFile: true,
                suppressionDMAFlag: true,
                DMAsuppressionData: DMAsuppressionData,
                disabled: "drpdisable",
                suppressionDMAerror: suppressionDMAerror,
              });
            }
          } else {
            nosuppressionoutput.innerHTML = "";
          }
        }.bind(this)
      );
    } else {
      var alertmessage = "Only One DMA file accept at a time";

      Swal.fire({
        text: alertmessage,
        type: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  }

  /**
   * @author : Aman Shaikh 5060
   * @description : This is used delete Client file function
   */
  deleteClientFile(e, item) {
    e.preventDefault();
    let clickedFile = item.suppDocName;
    if (item.typeOfSuppDoc === "Suppression") {
      let suppressionFilesSelectedDocuments =
        this.state.suppressionFilesSelectedDocuments;

      const revisedSuppressionFilesSelectedDocuments =
        suppressionFilesSelectedDocuments.filter(
          (item) => item.suppDocName !== clickedFile
        );

      for (var k = 0; k < newsuppressionfilenames.length; k++) {
        if (newsuppressionfilenames[k] === clickedFile) {
          newsuppressionfilenames.splice(k, 1);
        }
      }
      document.getElementById("submitButton").disabled = false;
      this.setState({
        suppressionFilesSelectedDocuments:
          revisedSuppressionFilesSelectedDocuments,
      });
      let message = document.getElementById("suppresionMessage");
      let msg = "";
      message.innerHTML = msg;
    }
  }

  /**
   * @author : Aman Shaikh 5060
   * @description : This is used delete DMA file function
   */
  deleteDMAFile(e, item) {
    e.preventDefault();
    let clickedFile = item.DMAsuppDocName;
    if (item.typeOfSuppDoc === "Suppression") {
      let DMAsuppressionFilesSelectedDocuments =
        this.state.DMAsuppressionFilesSelectedDocuments;

      const revisedSuppressionFilesSelectedDocuments =
        DMAsuppressionFilesSelectedDocuments.filter(
          (item) => item.DMAsuppDocName !== clickedFile
        );

      for (var k = 0; k < newDMAsuppressionfilenames.length; k++) {
        if (newDMAsuppressionfilenames[k] === clickedFile) {
          newDMAsuppressionfilenames.splice(k, 1);
        }
      }
      document.getElementById("submitButton").disabled = false;
      this.setState({
        DMAsuppressionFilesSelectedDocuments:
          revisedSuppressionFilesSelectedDocuments,
      });
      let message = document.getElementById("suppresionDMAMessage");
      let msg = "";
      message.innerHTML = msg;
    }
  }

  //Aman-5060-added handle emailStatusChecker Suppression compare-Data refresh issue
  refreshPage(e) {
    window.location.reload();
  }

  render() {
    return (
      <div>
        <Navigation />
        <div
          class="container-fluid"
          id="containerUCS"
          style={{ paddingTop: "112px", paddingBottom: "70px" }}
        >
          {/*Aman-5060 responsiveness issue*/}
          <div class="row">
            <div
              class="col-lg-12"
              align="center"
              style={{ marginBottom: "30px" }}
            >
              {/* Aman-5060 back Button and page title*/}
              <div class="row">
                <div class="col-md-1">
                  {" "}
                  <a
                    href={"#/"}
                    style={{ color: "#056eb8" }}
                    onClick={this.backButtonFunction.bind(this)}
                  >
                    <FaArrowAltCircleLeft size={32} title="Back to Dashboard" />
                  </a>
                </div>
                <div class="col-md-8 reviewLead1">
                  {" "}
                  <center>
                    <label
                      id="labelDI"
                      class="PubReviewLead"
                      style={{ marginLeft: "135px" }}
                    >
                      {" "}
                      Email Status Checker {/*Aman-5108 chnaged Brite Status Verifier to Email Status Checker */}
                    </label>
                  </center>
                </div>

                {/*Aman-5060-added handle BriteStatus Suppression compare-Data refresh issue*/}
                <div
                  class="col-sm-12 col-md-3 col-lg-3 specificationCamp"
                  style={{ textAlign: "right" }}
                >
                  <button
                    class="btn add-button"
                    type="primary"
                    onClick={this.refreshPage}
                    style={{
                      backgroundColor: "#056eb8",
                      color: "#fff",
                      width: "min-content",
                      height: "31px",
                      fontSize: "14px",
                      borderColor: "#056eb8",
                      marginRight: "30px",
                      float: "right",
                    }}
                  >
                    <Tooltip placement="left" title="Refresh Page">
                      <span
                        className="fas fa-sync-alt"
                        style={{ color: "aliceblue" }}
                      ></span>
                    </Tooltip>
                  </button>
                  &nbsp;
                </div>
              </div>

              {/* Aman-5060 - upload Client Suppression File Div*/}
              <div class=" col-sm-12 col-lg-5 col-md-5">
                <br />
                <div class="card">
                  <div className="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <label
                          style={{
                            fontWeight: 500,
                            color: "black",
                            marginBottom: 0,
                          }}
                        >
                          Validated Data File:&nbsp; {/* Aman-5111-PC/PNC-Settings-Email Status Checker-Change Labels */}
                        </label>
                        <Tooltip
                          placement="right"
                          title="Upload One or multiple Validated Data 'Excel' files and each file Header contain only 2 columns as 'Email' and 'EmailStatus'" //Aman-5108 chnaged BriteStatus to EmailStatus
                        >
                          <Icon type="question-circle" />
                        </Tooltip>
                      </div>

                      <div class="col">
                        <div
                          class="upload-btn-wrapper"
                          style={{ float: "right" }}
                        >
                          <img
                            src="upload.png"
                            alt="upload"
                            onClick={this.uploadSuppressionFile}
                            style={{
                              height: "25px",
                              width: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div id="nosuppressionList" style={{ display: "none" }}>
                      0 Suppression Files Selected
                    </div>
                    <div
                      class="table-responsive-lg"
                      style={{
                        width: "100%",
                        display: this.state.displayTablesuppression,
                      }}
                    >
                      <br />
                      <div
                        id="suppresionMessage"
                        style={{ color: "green" }}
                      ></div>
                      <div style={{ color: "red" }}>
                        {this.state.suppressionerror.suppressionerr}
                      </div>
                      <div
                        id={"suppressionError"}
                        style={{ color: "red" }}
                      ></div>
                      <span class="errorMessage">
                        {this.state.suppressionerror.headerError}
                      </span>

                      <div
                        className="mt-2"
                        style={
                          this.state.suppressionFilesSelectedDocuments.length >
                          5
                            ? {
                                maxHeight: 280,
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {this.state.suppressionFilesSelectedDocuments &&
                          this.state.suppressionFilesSelectedDocuments.map(
                            (item) => {
                              return (
                                <div
                                  className="row align-items-center mb-2"
                                  style={{
                                    backgroundColor: item.success
                                      ? "#DAECDA"
                                      : "#FFEEF0",
                                    paddingLeft: 12,
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingRight: 12,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    borderRadius: 2,
                                  }}
                                >
                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col-xs-2 col-md-auto"
                                  >
                                    {
                                      <img
                                        alt=""
                                        style={{
                                          height: 26,
                                          width: 26,
                                        }}
                                        src={
                                          item.success
                                            ? "success.png"
                                            : "failed.png"
                                        }
                                      />
                                    }
                                  </div>

                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col overflow-hidden"
                                  >
                                    <label
                                      className="mb-0 ml-2 overflow-hidden align-middle"
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 13,
                                      }}
                                    >
                                      {item.suppDocName}
                                    </label>
                                  </div>
                                  <a
                                    href="#/"
                                    className="col-xs-2 col-md-auto justify-content-center"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      width: "auto",
                                    }}
                                    onClick={(e) =>
                                      this.deleteClientFile(e, item)
                                    }
                                  >
                                    <img
                                      alt=""
                                      className="mb-0 mx-2"
                                      style={{
                                        height: 18,
                                        width: 18,
                                      }}
                                      src="file_upload_2_blue.png"
                                    />
                                  </a>
                                </div>
                              );
                            }
                          )}
                      </div>
                      <table
                        id="myTable"
                        className=" table table-bordered table-striped "
                        style={{
                          width: "100%",
                          border: "0px solid white",
                          margin: 0,
                        }}
                      >
                        <tbody class="tbody" style={{ height: "auto" }}>
                          <span
                            id="suppressionList"
                            className="word-wrap"
                          ></span>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* Aman-5060 upload DMA Suppression File Div*/}
              <div class=" col-sm-12 col-lg-5 col-md-5">
                <br />
                <div class="card">
                  <div className="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <label
                          style={{
                            fontWeight: 500,
                            color: "black",
                            marginBottom: 0,
                          }}
                        >
                          DMA Data File:&nbsp; {/* Aman-5111-PC/PNC-Settings-Email Status Checker-Change Labels */}
                        </label>
                        <Tooltip
                          placement="right"
                          title="Upload one DMA Data 'Excel' file and file Header contain only 1 column 'Email'" //Aman-5060- chnaged the message
                        >
                          <Icon type="question-circle" />
                        </Tooltip>
                      </div>

                      <div class="col">
                        <div
                          class="upload-btn-wrapper"
                          style={{ float: "right" }}
                        >
                          <img
                            src="upload.png"
                            alt="upload"
                            onClick={this.uploadDMASuppressionFile}
                            style={{
                              height: "25px",
                              width: "25px",
                              cursor: "pointer",
                            }}
                          />
                          {/* sunita-task-3101-removed input type file*/}
                        </div>
                      </div>
                    </div>
                    <div id="nosuppressionList" style={{ display: "none" }}>
                      0 Suppression Files Selected
                    </div>
                    <div
                      class="table-responsive-lg"
                      style={{
                        width: "100%",
                        display: this.state.displayDMATablesuppression,
                      }}
                    >
                      <br />
                      <div
                        id="suppresionDMAMessage"
                        style={{ color: "green" }}
                      ></div>
                      <div style={{ color: "red" }}>
                        {this.state.suppressionDMAerror.suppressionerr}
                      </div>
                      <div
                        id={"suppressionDMAerror"}
                        style={{ color: "red" }}
                      ></div>
                      <span class="errorMessage">
                        {this.state.suppressionDMAerror.headerError}
                      </span>

                      <div
                        className="mt-2"
                        style={
                          this.state.DMAsuppressionFilesSelectedDocuments
                            .length > 5
                            ? {
                                maxHeight: 280,
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {this.state.DMAsuppressionFilesSelectedDocuments &&
                          this.state.DMAsuppressionFilesSelectedDocuments.map(
                            (item) => {
                              return (
                                <div
                                  className="row align-items-center mb-2"
                                  style={{
                                    backgroundColor: item.success
                                      ? "#DAECDA"
                                      : "#FFEEF0",
                                    paddingLeft: 12,
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingRight: 12,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    borderRadius: 2,
                                  }}
                                >
                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col-xs-2 col-md-auto"
                                  >
                                    {
                                      <img
                                        alt=""
                                        style={{
                                          height: 26,
                                          width: 26,
                                        }}
                                        src={
                                          item.success
                                            ? "success.png"
                                            : "failed.png"
                                        }
                                      />
                                    }
                                  </div>

                                  <div
                                    style={{ padding: 0, margin: 0 }}
                                    className="col overflow-hidden"
                                  >
                                    <label
                                      className="mb-0 ml-2 overflow-hidden align-middle"
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 13,
                                      }}
                                    >
                                      {item.DMAsuppDocName}
                                    </label>
                                  </div>
                                  <a
                                    href="#/"
                                    className="col-xs-2 col-md-auto justify-content-center"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      width: "auto",
                                    }}
                                    onClick={(e) => this.deleteDMAFile(e, item)}
                                  >
                                    <img
                                      alt=""
                                      className="mb-0 mx-2"
                                      style={{
                                        height: 18,
                                        width: 18,
                                      }}
                                      src="file_upload_2_blue.png"
                                    />
                                  </a>
                                </div>
                              );
                            }
                          )}
                      </div>
                      <table
                        id="myTable"
                        className=" table table-bordered table-striped "
                        style={{
                          width: "100%",
                          border: "0px solid white",
                          margin: 0,
                        }}
                      >
                        <tbody class="tbody" style={{ height: "auto" }}>
                          <span
                            id="suppressionDMAList"
                            className="word-wrap"
                          ></span>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body" style={{ marginTop: "20px" }}>
                <MDBRow>
                  <MDBCol lg="3">
                    <div class="row" style={{ marginLeft: "10px" }}>
                      <div>
                        <button
                          class="btn add-button"
                          id="submitButton"
                          style={{ float: "right" }}
                          onClick={this.compareFiles}
                        >
                          Compare
                        </button>
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </div>

              {/* Aman-5060 -Added loader*/}
              <div align="center" id={this.state.loader_progress}></div>
            </div>
          </div>

          {/* Aman-5060 -Added to get result and match/ not match count on UI*/}
          {this.state.compression === true ? (
            <div style={{ marginTop: "20px" }}>
              <hr />
              <div
                className="card"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold" }}>
                  <p>
                    Found Count:{" "}
                    <b
                      style={{
                        marginLeft: "5px",
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      {this.state.matchedCount}
                    </b>
                  </p>
                </div>

                <div style={{ fontWeight: "bold" }}>
                  <p>
                    Not Found Count:{" "}
                    <b
                      style={{
                        marginLeft: "5px",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {this.state.notMatchedCount}
                    </b>
                  </p>
                </div>

                <div style={{ marginTop: "15px" }}>
                  <button
                    class="btn add-button"
                    id="submitButton"
                    style={{ float: "right" }}
                    onClick={this.handleDownload}
                  >
                    Download File
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <br />
          <Footer />
        </div>
      </div>
    ); /*** End of return */
  } /** End of render */
} /*** End of Class */

emailStatusChecker.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(emailStatusChecker)
);
