/* Copyright(c) 2012 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Kiran Mate
@Creation Date:24-08-2022
@Description:UI for Marketo,hubspot,salesforce lead delivery
*/
import React from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import "./deliveryLeadCRM.css"
//import { saveAs } from "file-saver";  // nilesh

import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { FaShippingFast ,FaRedoAlt} from "react-icons/fa";

import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import { css } from "emotion";
import { CardBody } from "mdbreact";

import "datatables.net";
import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";
import { LeadReviewLoader } from "../loaders/LeadReviewLoader";//Priyanka-4422-Added Loader
import styled from "styled-components";
import { Steps, Radio, Icon, Checkbox, Input } from "antd";

const Swal = require("sweetalert2");

const tableCSS = css({
  backgroundColor: "white",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",
    color: "white",
  },
  "& thead > tr >th": {
    border: "1px solid black",
    color: "white",
  },
});


class leadDeliverSf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campID: "",
      sfClient: "",
      campaignBriefDetails: [],
      sfClientInfo: [],
      tableState: { size: "small" },
      crmLeadDetail: [],
      latestRecordID: "",  //4624-Nilesh-Highlight latest CSV generated record
      leadStartSelectedDate: "",
      leadEndSelectedDate: ""
    };

    this.downloadCsvFile = this.downloadCsvFile.bind(this);

    //Priyanka-4421-added below code to display loader and leads details	
    this.displaySuccessfulLeads = this.displaySuccessfulLeads.bind(this);
    this.displayFailureLeads = this.displayFailureLeads.bind(this);
    this.loadingRef = React.createRef();
    this.closeLoadingRef = React.createRef();
    this.startDatedateHandleChange = this.startDatedateHandleChange.bind(this); //Nilesh-5080-added startDatedateHandleChange to handle the startSelectedDate date
    this.endDatedateHandleChange = this.endDatedateHandleChange.bind(this); //Nilesh-5080-added endDatedateHandleChange to handle the endSelectedDate date
  }

  //nilesh 4412 download Excel file from database
  downloadCsvFile(e, record) {
    e.preventDefault();
    if (record.file !== null) {
      const buf = Buffer.from(record.file);
      var newBlob = new Blob([buf], {
        type: "application/csv",
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = record.fileName;
      link.click();

      Swal.fire({
        html: "Your File has been successfully Downloaded!",
        type: "success",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
    else {
      Swal.fire({
        html: "This File Does not exists!",
        type: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  }


  //nilesh 4412-delete Excel file from FE side & BE side in database file status is Invalid
  deleteCsvFile(e, record) {
    e.preventDefault();   //Priyanka-4422-added code to remove # from URL
    // var campID= record.campID;
    var fileName = record.fileName;

    Swal.fire({
      title:
        `Are you sure you want to delete ${record.fileName}`,
      type: "question",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#3085d6",
      allowOutsideClick: false,
      confirmButtonText: "Yes",
    }).then((record) => {
      if (record.value) {
        let data = {
          fileName: fileName

        };
        // alert(fileName)
        fetch("/leadIntegration/deleteCSV", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });

        Swal.fire({
          html: "Your File has been successfully Deleted",
          type: "success",
          allowOutsideClick: false,
          confirmButtonText: "Ok",
          preConfirm: () => {
            window.location.reload();
          },
        }
        );

      }
    });
  }

  downloadmappingFile = (e) => {
    e.preventDefault();
    let { mapping, mappingFileName } = this.state.sfClientInfo[0];
    //alert("marketoClientInfo" + JSON.stringify(this.state.marketoClientInfo))
    const buf = Buffer.from(mapping);
    var newBlob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = mappingFileName;
    link.click();
  }

  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    }
    else {
      var parsed = this.props.location.state;

    }
    let campID, sfClient;
    if (this.props.location.state !== undefined) {
      campID = this.props.location.state.campID;
      sfClient = parsed.sfClient;
      this.setState({
        sfClient: sfClient,
        campID: campID
      });
    }
    fetch("/agency/campaignBriefDetailsForAgency?campID=" + campID)
      .then((res) => res.json())
      .then((campaignBriefDetails) => {
        if (campaignBriefDetails.length > 0) {
          this.setState({
            campaignBriefDetails: campaignBriefDetails
          });
        }

      })
      .catch(function (err) {
        console.log(err);
      });

    //marketoclient details for displaying marketo details
    if (sfClient !== null) {

      let data = {
        sfClient: sfClient,
      }

      fetch("/leadIntegration/getSalesforceSetupDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then((sfClientInfo) => {
          this.setState({ sfClientInfo: sfClientInfo });
        })
        .catch(function (err) {
          console.log(err);
        }); //marketoclient details for displaying marketo details
    } else { }

    fetch("leadIntegration/crmLeadDetails?campID=" + campID)
      .then((res) => res.json())
      .then((crmLeadDetail) => {
        this.setState({ crmLeadDetail: crmLeadDetail });
        this.setState({ latestRecordID: crmLeadDetail[0].id });  //4624-Nilesh-Highlight latest CSV generated record
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  //Priyanka-4422-Function to deliver leads to Salesforce	
  sendLeadsToSalesforce(e, record) {
    e.preventDefault();
    this.loadingRef.current.click();
    let crmLeadArray = record;
    let sfClient = this.state.sfClient;
    let data = {
      crmLeadArray: crmLeadArray,
      sfClient: sfClient,
    };

    fetch("/deliverLeadsSf/deliverLeadstoSf", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        this.closeLoadingRef.current.click();

        if (response === "success") {
          Swal.fire({
            text: "Leads Delivered Successfully",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        } else {
          Swal.fire({
            type: "error",
            title: "Leads are not Delivered",
            text: JSON.stringify(response),
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /**	
  * @author Priyanka Patil	
  * @return Description 4422-redirect to leadViewSf page to display success leads details	
  */
  displaySuccessfulLeads(e) {
    e.preventDefault();
    var campID = e.target.getAttribute("CampId");
    var fileID = e.target.getAttribute("FileId");
    var succLeadCount = e.target.getAttribute("SuccessLeadCount");
    var sfClient = this.state.sfClient;
    this.props.history.push("/leadViewSf", {
      campID: campID,
      fileID: fileID,
      succLeadCount: succLeadCount,
      client: sfClient
    });
  }

  /**	
  * @author Priyanka Patil	
  * @return Description 4422-redirect to leadViewSf page to display failed leads details	
  */
  displayFailureLeads(e, record) {
    e.preventDefault();
    // var campID = e.target.getAttribute("CampId");
    // var fileID = e.target.getAttribute("FileId");
    // var failLeadCount = e.target.getAttribute("FailLeadCount");
    var campID = record.campID;   // 4662-Nilesh-Add record
    var fileID = record.id;
    var failLeadCount = record.failLeadCount;
    var sfClient = this.state.sfClient;
    this.props.history.push("/leadViewSf", {
      campID: campID,
      fileID: fileID,
      failLeadCount: failLeadCount,
      client: sfClient
    });
  }

  BackButton(e) {
    e.preventDefault(); //Priyanka-4422-to prevent # in URL
    const { user } = this.props.auth;
    var agencyID = user.id;
    this.props.history.push("/campaignList", { agencyID: agencyID });
  }

  //Nilesh-5080-added startDatedateHandleChange to handle the startSelectedDate date
  startDatedateHandleChange(e) {
    e.preventDefault();
    var startSelectedDate = e.target.value;
    this.setState({ leadStartSelectedDate: startSelectedDate }, () => {
      this.filterleadByStartAndEndDate();
    });

    const reversedDate = this.reverseDateFormat(startSelectedDate); //Output: "17-01-2024"
    this.setState({
      stectedStartDateforDisplay: reversedDate,
      selectedDateStart: startSelectedDate, //Update state variable for input value
    });
  }

  //Nilesh-5080-added endDatedateHandleChange to handle the endSelectedDate date
    endDatedateHandleChange(e) {
      e.preventDefault();
      var endSelectedDate = e.target.value;
      this.setState({ leadEndSelectedDate: endSelectedDate }, () => {
        this.filterleadByStartAndEndDate();
      });
  
      const reversedDate = this.reverseDateFormat(endSelectedDate); //Output: "04-04-2024"
      this.setState({
        stectedEndDateforDisplay: reversedDate,
        selectedDateEnd: endSelectedDate, //Update state variable for input value
      });
    }

    reverseDateFormat(originalDate) {
      //Added to Split the original date string
      const dateParts = originalDate.split("-");
      //Added to Rearrange the parts in the desired order
      const reversedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      return reversedDate;
    }

    //Nilesh-5080- Added this function fetch CRM details
    filterleadByStartAndEndDate(e) {
      if (
        this.state.leadStartSelectedDate !== "" &&
        this.state.leadEndSelectedDate !== ""
      ) {
        let data = {
          campID: this.state.campID,
          startDate: this.state.leadStartSelectedDate,
          endDate: this.state.leadEndSelectedDate,
        };
        fetch("/leadIntegration/crmLeadDetailsByDate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((crmLeadDetail) => {
            // alert(JSON.stringify(crmLeadDetail));
            this.setState({ crmLeadDetail: crmLeadDetail });
            this.setState({ latestRecordID: crmLeadDetail[0].id });
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
      }
  }

  render() {
    function onChange(pagination, filters, sorter) {
      console.log("params", pagination, filters, sorter);
    }
    //Nilesh-5080- Add tooltip and asterisk
    const asterisk = { color: "red" };
    const HtmlTooltip = styled(Tooltip)`
	   backgroundColor: '#f5f5f9',
	   color: 'rgba(0, 0, 0, 0.87)',
	   maxWidth: 220,
	   fontSize: '12px',
	   border: '1px solid #dadde9',
	   `;
    const columns = [
      {
        title: <span>Date (YYYY-MM-DD HH:MM:SS)&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "genDate",
        key: "genDate",
        width: "6%",
        fontSize: "12px",
        align: "center",
        sorter: (a, b) => {
          const asc = "asc";

          if (a.genDate < b.genDate) {
            return asc ? -1 : 1;
          } else if (a.genDate > b.genDate) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div>
            <span>{record.genDate}</span>
          </div>
        ),
      },

      {
        title: "CSV File Name",
        dataIndex: "file Name", //File Name
        key: "fileName",
        // width: "7%,12",
        width: "15%",
        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.fileName.toUpperCase() < b.fileName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (a.fileName.toUpperCase() > b.fileName.toUpperCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div class="dot1" style={{ width: "230px"}}>
            <span style={{ fontSize: "12px" }}>
              <a
                class="col"
                title={record.fileName} //4662-Nilesh File title
                href="#/"
                style={{ color: "#056eb8" }}
                onClick={(e) => this.downloadCsvFile(e, record)}
              >
                {Number(record.id) === Number(this.state.latestRecordID) ?   //Rutuja 2978 added number and changed == to ===          //4624-Nilesh-Highlight latest CSV generated record
                  <b style={{ color: "blue" }}> {record.fileName}</b>
                  :
                  <b style={{ color: "black" }}> {record.fileName}</b>}
              </a>
            </span>
          </div>
        ),
      },
      {
        title: "Upload Lead Count",
        dataIndex: "leadCount",
        key: "leadCount",
        width: "7%",
        align: "center",
      },
      {
        title: "Delivered By",
        dataIndex: "agencyUserName",
        key: "agencyUserName",
        width: "8%",
        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.agencyUserName.toUpperCase() < b.agencyUserName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.agencyUserName.toUpperCase() > b.agencyUserName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "12px" }}>{record.agencyUserName}</span>
          </div>
        ),
      },
      {
        title: "Delivery Status",
        align: "center",
        width: "10%",
        children: [
          {
            title: "Success",
            dataIndex: "sucessLeadCount",
            key: "sucessLeadCount",
            align: "center",
            width: "5%",
            sorter: (a, b) =>
              parseInt(a.sucessLeadCount) - parseInt(b.sucessLeadCount),
            render: (text, record) => (
              <div style={{ fontSize: "12px" }}>
                {/*Priyanka-4422-Caliing function to display success leads */}
                {text > 0 ? (
                  <a
                    href="#/"
                    CampId={record.campID}
                    FileId={record.id}
                    SuccessLeadCount={record.sucessLeadCount}
                    onClick={this.displaySuccessfulLeads}
                    style={{ color: "green", textDecoration: "underline" }}   //4624-Nilesh-Highlight latest CSV generated record
                  >
                    {text}
                  </a>
                ) : (
                  <div>0</div>
                )}
              </div>
            ),
          },
          {
            title: "Failure",
            dataIndex: "failLeadCount",
            key: "failLeadCount",
            align: "center",
            width: "5%",
            sorter: (a, b) =>
              parseInt(a.failLeadCount) - parseInt(b.failLeadCount),
            render: (text, record) => (
              <div>
                {/*Priyanka-4422-Caliing function to display failure leads */}
                {/* {text > 0 ? (

                  <a
                    href="#/"
                    CampId={record.campID}
                    FileId={record.id}
                    FailLeadCount={record.failLeadCount}
                    onClick={this.displayFailureLeads}
                    style={{ color: "red", textDecoration: "underline" }}    //4624-Nilesh-Highlight latest CSV generated record
                  >
                    {text}
                  </a>
                ) : (
                  <div>0</div>
                )} */}
                {/*Nilesh-4662-Removed function calling */}
                {record.failLeadCount > 0 ? (
                  <div>
                    <span style={{ fontSize: "12px", color: "red" }}>{record.failLeadCount}</span>
                  </div>
                ) : (
                  <div>
                    <span style={{ fontSize: "12px" }}>{record.failLeadCount}</span>
                  </div>
                )}
              </div>
            ),
          },
          {
            title: "Status",
            dataIndex: "fileStatus",
            key: "fileStatus",
            align: "center",
            width: "5%",
            sorter: (a, b) => parseInt(a.fileStatus) - parseInt(b.fileStatus),
            render(text, record) {
              return {
                props: {
                  style: { wordWrap: "break-word", height: "5%" },
                },
                children: (
                  <div>
                    {/* 4624-Nilesh-Highlight latest CSV generated record */}
                    {/* //Rutuja 2978 changed == to ===  */}
                    {record.fileStatus === "Pending" ? (<b style={{ color: "orange" }}>Pending</b>) : (<b style={{ color: "green" }}>Delivered</b>)}
                    {/* {record.fileStatus ? record.fileStatus : record.fileStatus} */}
                  </div>
                ),
              };
            },
          },
        ],
      },
      {
        title: "Action",
        dataIndex: "",
        key: "",
        width: "6%",
        align: "center",
        render: (text, record, i) => (
          <div >
            {/* 4624-add flexDirection and paddingLeft */}
            <div style={{ display: "inline-flex", flexDirection: "row-reverse" }}>
              <Tooltip placement="top" title="Delete CSV File" >
                <a href="#/" style={{ color: "#056eb8", marginRight: "10px", paddingLeft: "12px" }}>
                  <FaTrashAlt
                    size={20}
                    title=""
                    onClick={(e) => this.deleteCsvFile(e, record)}

                  />
                </a>
              </Tooltip>
              {/* 4662-Nilesh  */}
              {record.failLeadCount > 0 ? (
                <Tooltip placement="top" title="Redeliver To Salesforce">
                  <a href="#/" style={{ color: "#056eb8", marginRight: "10px", paddingLeft: "23px" }}
                    onClick={(e) => this.displayFailureLeads(e, record)}>
                    <FaRedoAlt
                      size={20}
                      title=""
                    />
                  </a>
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Redeliver To Salesforce">
                  <a href="#/" style={{ color: "#666666", marginRight: "10px", paddingLeft: "23px" }}>
                    <FaRedoAlt
                      size={20}
                      title=""
                    />
                  </a>
                </Tooltip>
              )}
              {/*Priyanka-4422-enabling deliver button on basis of fileStatus*/}
              {record.fileStatus === 'Pending' ? (
                <Tooltip placement="top" title="Send To Salesforce">
                  <a href="#/" style={{ color: "#056eb8" }}
                    onClick={(e) => this.sendLeadsToSalesforce(e, record)}>
                    <FaShippingFast
                      size={20}
                      title=""
                    />
                  </a>
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Send To Salesforce">
                  <a href="#/" style={{ color: "#666666" }}>
                    <FaShippingFast
                      size={20}
                      title=""
                    />
                  </a>
                </Tooltip>
              )}
              {/* </span> */}
            </div>
          </div>
        ),
      },
    ];

    return (
      <div>
        {/* Priyanka-4422-Added Loader */}
        <LeadReviewLoader
          loadingRef={this.loadingRef}
          closeLoadingRef={this.closeLoadingRef}
          message="Leads are delivering...Please do not Press Refresh or Back Button..."
        />
        <Navigation />
        <div class="container-fluid" style={{ paddingTop: "110px" }}>
          <div class="row">
            <div class="col-lg-3 col-md-1 col-sm-1">
              <a
                class="col"
                href="#/"
                style={{ color: "#056eb8" }}
                onClick={this.BackButton.bind(this)}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  title="Back to Dashboard" //Nilesh-5080- added tooltip title
                  className="backFrom_LeadDelivery"
                />
              </a>
            </div>
            <div class=" col-lg-6 col-md-8 col-sm-8" align="center">
              <div
                class="col"
                align="center"
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                <label id="labelheading" style={{ color: "rgb(25, 61, 143)", fontSize: "20px", fontWeight: "bold", fontFamily: "roboto" }}>
                  Send Leads to SalesForce{" "}
                </label>
              </div>
            </div>
          </div>
          <div id="campaignDetails" class="row">
            {/* <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" > */}
            <div class="col-lg-12">
              <div style={{ border: "white" }}>
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                >
                  Campaign Details :{" "}
                </div>
                <div
                  class="card"
                  style={{
                    backgroundColor: "#f8f8f8",
                    borderColor: "#707070",
                    borderWidth: "0.3px",
                    // padding: "12.5px"
                  }}
                >
                  <CardBody>
                    {this.state.campaignBriefDetails.map(
                      (campaignBriefDetails) => (
                        <div class="row">
                          <div class="col-lg-12">
                            <table class="col-lg-6">
                              <tr class="col-lg-6">
                                <td style={{ width: "100px" }}>
                                  <label id="label">&nbsp;Campaign ID</label>
                                </td>
                                <td style={{ width: "280px" }}>
                                  <span>
                                    :&nbsp;{campaignBriefDetails.campID}
                                  </span>
                                </td>
                              </tr>
                              <tr class="col-lg-6">
                                <td style={{ width: "120px" }}>
                                  <label id="label">&nbsp;Campaign Name</label>
                                </td>
                                <td>
                                  <span>
                                    :&nbsp;{campaignBriefDetails.campaignName}
                                  </span>
                                </td>
                              </tr>
                              <tr class="col-lg-6">
                                <td style={{ width: "110px" }}>
                                  &nbsp;<label id="label"> Start Date</label>
                                </td>
                                <td style={{ width: "280px" }}>
                                  :&nbsp;{campaignBriefDetails.startDate}
                                </td>
                              </tr>

                              <tr class="col-lg-6">
                                <td style={{ width: "120px" }}>
                                  &nbsp;<label id="label">End Date</label>
                                </td>
                                <td>

                                  :&nbsp;{campaignBriefDetails.endDate}

                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      ))}
                  </CardBody>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div style={{ border: "white" }}>
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                ><br />
                  Salesforce Client Setup Details :{" "}
                </div>
                <div
                  class="card"
                  style={{
                    backgroundColor: "#f8f8f8",
                    borderColor: "#707070",
                    borderWidth: "0.3px",
                  }}
                >
                  <CardBody>
                    {this.state.sfClientInfo.map((sfClientInfo) => (
                      <div class="row">
                        <div class="col-lg-12">
                          <table style={{ display: "block" }}>
                            <tr class="col-lg-6">
                              <td style={{ width: "100px" }}>
                                <label id="label">&nbsp;Client Name</label>
                              </td>
                              <td style={{ width: "250px" }}>
                                <span>:&nbsp;{sfClientInfo.clientName}</span>
                              </td>
                            </tr>
                            <tr class="col-lg-6">
                              <td style={{ width: "100px" }}>
                                <label id="label">&nbsp;Client ID</label>
                              </td>
                              <td style={{ width: "190px" }}>
                                <span>:&nbsp;{sfClientInfo.clientID}</span>
                              </td>
                            </tr>
                            <tr class="col-lg-6">
                              <td style={{ width: "100px" }}>
                                <label id="label">&nbsp;Agency Name</label>
                              </td>
                              <td>
                                <span>:&nbsp;{sfClientInfo.agencyName}</span>
                              </td>
                            </tr>
                            <tr class="col-lg-6">
                              <td style={{ width: "100px" }}>
                                <label id="label">&nbsp;Email</label>
                              </td>
                              <td style={{ width: "190px" }}>
                                <span>:&nbsp;{sfClientInfo.email}</span>
                              </td>
                            </tr>
                            <tr class="col-lg-6">
                              <td style={{ width: "100px" }}>
                                <label id="label">&nbsp;API version</label>
                              </td>
                              <td>
                                <span>:&nbsp;{sfClientInfo.apiVersion}</span>
                              </td>
                            </tr>

                            <tr class="col-lg-6">
                              <td style={{ width: "100px" }}>
                                <label id="label">&nbsp;Mapping File</label>
                              </td>
                              <td style={{ width: "190px" }}>
                                <a href="#/">
                                  <span>
                                    :
                                    <b
                                      onClick={this.downloadmappingFile.bind(
                                        this
                                      )}
                                    >
                                      {sfClientInfo.mappingFileName}&nbsp;
                                      <i class="fa fa-download"></i>
                                    </b>
                                  </span>
                                </a>
                              </td>
                            </tr>

                            {this.state.sfClientInfo[0].app === "No" ? (

                              <div class="row">
                                <div class="col">
                                  <table>
                                    <tr class="col-lg-6">
                                      <td style={{ width: "100px" }}>
                                        <label id="label" class="label1">&nbsp;Token</label>
                                      </td>
                                      <td style={{ width: "190px" }}>
                                        <span id="sfDetails">:&nbsp;{sfClientInfo.token}</span>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>

                            ) : (
                              <CardBody>

                                <div class="row">
                                  <div class="col">
                                    <table>
                                      <tr >
                                        <td style={{ width: "100px" }}>
                                          <label id="label" class="label1">&nbsp;JWT consumer key</label>
                                        </td>
                                        <td >
                                          <span id="sfDetails">
                                            :&nbsp;{sfClientInfo.jwtConsumerKey}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr >
                                        <td style={{ width: "120px" }}>
                                          <label id="label" class="label1">&nbsp;Consumer secret</label>
                                        </td>
                                        <td>
                                          <span id="sfDetails">
                                            :&nbsp;{sfClientInfo.consumerSecret}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr >
                                        <td style={{ width: "115px" }}>
                                          &nbsp;<label id="label" class="label1"> JWT AUD</label>
                                        </td>
                                        <td style={{ width: "280px" }} id="sfDetails">
                                          :&nbsp;{sfClientInfo.jwtAud}
                                        </td>
                                      </tr>

                                      <tr >
                                        <td style={{ width: "120px" }}>
                                          &nbsp;<label id="label" class="label1">Token URL</label>
                                        </td>
                                        <td id="sfDetails">

                                          :&nbsp;{sfClientInfo.tokenURL}

                                        </td>
                                      </tr>

                                      <tr >
                                        <td style={{ width: "120px" }}>
                                          <label id="label" class="label1">&nbsp;Private key path</label>
                                        </td>
                                        <td>
                                          <span id="sfDetails">
                                            :&nbsp;{sfClientInfo.privateKeyPathName}
                                          </span>
                                        </td>
                                      </tr>

                                      <tr >
                                        <td style={{ width: "120px" }}>
                                          <label id="label" class="label1">&nbsp;Public certificate path</label>
                                        </td>
                                        <td>
                                          <span id="sfDetails">
                                            :&nbsp;{sfClientInfo.publicCertificatePathName}
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>

                              </CardBody>
                            )}

                            {/* <tr class="col-lg-6">
                              <td style={{ width: "100px" }}>
                                <label id="label">&nbsp;Mapping File</label>
                              </td>
                              <td style={{ width: "190px" }}>
                                <a href="#/">
                                  <span>
                                    :
                                    <b
                                      onClick={this.downloadmappingFile.bind(
                                        this
                                      )}
                                    >
                                      {sfClientInfo.mappingFileName}&nbsp;
                                      <i class="fa fa-download"></i>
                                    </b>
                                  </span>
                                </a>
                              </td>
                            </tr> */}
                            <tr class="col-lg-6">
                              <td>
                                <label id="label" style={{ width: "117px" }}>User Name</label>
                              </td>
                              <td>
                                <span id="sfDetails">:&nbsp;{sfClientInfo.userName}</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    ))}
                  </CardBody>
                </div>
              </div>
            </div>
          </div>

           {/* Nilesh-5080-added Title And tooltip*/}
           <div class="row" style={{ marginTop: "2%" }}>
            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "20px",
                  color: "#193d8f",
                  fontWeight: "600",
                }}
              >
                CSV File Search{" "}
                <HtmlTooltip
                  placement="right"
                  title={
                    <React.Fragment>
                      {/* <ol> */}
                      {
                        "Select Start Date and End Date to filter below CSV list"
                      }
                      {/* </ol> */}
                    </React.Fragment>
                  }
                >
                  <Icon type="question-circle" style={{ fontSize: "medium" }} />
                </HtmlTooltip>{" "}
                :{" "}
              </div>
            </div>
          </div>

          {/* Nilesh-5080-added Start date And End date section*/}
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
              <label
                id="label"
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                <b>
                  Start date : <span style={asterisk}>*</span>
                </b>
                <span style={{ color: "#337ab7", fontSize: "12px" }}>
                  (dd-mm-yyyy){" "}
                </span>
              </label>
              <input
                style={{ width: "100%" }}
                type="date"
                id="startDate"
                name="startDate"
                ref={(input) => {
                  this.nameInput = input;
                }}
                min=""
                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                value={this.state.selectedDateStart}
                //className={this.state.inputClassSdate}
                onChange={this.startDatedateHandleChange}
                class="form-control"
                required
              />
            </div>

            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2">
              <label
                id="label"
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                <b>
                  End date : <span style={asterisk}>*</span>
                </b>
                <span style={{ color: "#337ab7", fontSize: "12px" }}>
                  (dd-mm-yyyy){" "}
                </span>
              </label>
              <input
                style={{ width: "100%" }}
                type="date"
                id="endDate"
                name="endDate"
                ref={(input) => {
                  this.nameInput = input;
                }}
                min=""
                pattern="[0-9]{2}-[0-9]{}-[0-9]{4}"
                value={this.state.selectedDateEnd}
                onChange={this.endDatedateHandleChange}
                className="form-control"
                required
              />
            </div>

            {/* <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2"></div>
            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2"></div>
            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2"></div>
            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2"></div> */}
          </div>
          <p id="myTable" style={{ marginBottom: "30px " }}>
            <br />
            <Table
              {...this.state.tableState}
              bordered
              dataSource={this.state.crmLeadDetail}
              loading={this.state.loading1}
              onChange={onChange}
              columns={columns}
              class="ant-table-thead"
              className={tableCSS}
              scroll={{ y: 800 }}
              pagination={{ pageSize: this.state.pageSize1 }}
            >
            </Table>
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(leadDeliverSf)
);
