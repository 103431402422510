/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanobar Golandaj
@Description:UI for supporting document under create campaign
*/
import React from "react";
import * as $ from "jquery";
import { form } from "mdbreact";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import axios from "axios";
import { MDBCol, MDBRow } from "mdbreact";
import { Input, Tooltip, Icon, Switch } from "antd";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3719-vapt header and query params
import "./supportingDocumentNew.css";
var dateTime = require("node-datetime");

const Swal = require("sweetalert2");

//use for fetch url value
const queryString = require("query-string");
// var errorFile=false;
var assetLength;
var allabms = true;
var allassetLength = false;
var allabmLength = false;
var linkflagBack = false;
var assetflagBack = false;
var assetLinkLength;
var abmnames = [];
var newabmfilenames = [];
var suppressionnames = [];
var newsuppressionfilenames = [];
var execlusionnames = [];
var newexclusionfilenames = [];
var othernames = [];
var newotherfilenames = [];
var assetnames = [];
var newassetfilenames = [];
var inputType = [];
var oldABMFileArray = [];
var deletedABMFileArray = [];
var key = 0;
var key1 = 0;
var count = 0;
var linkSuppCount = 0;
var dt = dateTime.create();
var editButtonTime = dt.format("Y-m-d H:M:S");
var resultBack = [];
let chkABMExist = [],
	duplicateABMFile = []; // this is used for check duplicate file name
let chkExclusionExist = [],
	duplicateExclusionFile = [];
let chkSuppressionExist = [],
	duplicateSuppressionFile = [];
let chkOtherExist = [],
	duplicateOtherFile = [];
var successAbmCount = [];
var rejectAbmCount = [];
// var result=[];
var assetValidationArray = [];
var exclusionValidationArray = [];
var otherValidationArray = [];
var suppValidationArray = [];
var successAssetCount = [];
var successExclusionCount = [];
var rejectExclusionCount = [];
var rejectAssetCount = [];
var successSuppressionCount = [];
var rejectSuppressionCount = [];
var successOtherCount = [],
	rejectOtherCount = [];
var abmCountArray = [];

class EditSupportingDocument extends React.Component {
	/**
	 * @author Narendra Phadke
	 * @param  Description handle the multiple file uploads
	 */
	constructor() {
		super();

		this.state = {
			multiTouchEdit: "",
			Step: "",
			newsuccess1: "",
			leaderrors: {},
			backerror: {},
			fileerrors: {},
			fields: {},
			campID: "",
			errors: {},
			abmerror: {},
			checkedValueMultiTouch: false,
			checkedValue: false,
			leadPerAssetChk: "No",
			suppressionerror: {},
			exclusionerror: {},
			selectedFile: "",
			files: {},

			sdEditDetail: [],
			abmBackArray: [],
			dynamicInput: [],
			dynamicInputOldArray: [],
			sdEditDetailABM: [],
			totalLeadAllocation: 0,
			sdEditDetailSuppression: [],
			sdEditDetailExclusion: [],
			dynamicAssetName: [],
			assetLinkArray: [{ linkAssetFileName: "", assetLink: "" }],
			sdEditDetailOther: [],
			editDeliveryFormat: [],
			inputType: [],
			inputType1: [],
			sdEditDetailAsset: [],
			resultBack: [],
			resultLink: [],
			suppressionLink: [],

			linknameerror: {},

			sdBudget: [],
			alertDisplay: "none",
			displayTableabm: "none",
			displayTableexclusion: "none",
			displayTablesuppression: "none",
			displayTableother: "none",
			spanSpaceDisplay: "none",
			currency: "USD - US Dollar",
			lpTimeline: "12",
			campaignType: "",
			companyName: "Yes",
			linkedInCompanyName: "Yes",
			campaignID: "Yes",
			assetName: "Yes",
			pID: "Yes",
			assetId: "Yes",
			leadInteractionDate: "Yes",
			firstName: "Yes",
			lastName: "Yes",
			jobTitleDelivery: "Yes",
			linkedInJobTitle: "Yes",
			jobTitleValidation: "",
			jobLevelDelivery: "",
			customJobLevelDelivery: "",
			jobFunctionDelivery: "",
			assetName: "Yes",
			address: "Yes",
			email: "Yes",
			workPhone: "Yes",
			street: "Yes",
			city: "Yes",
			state: "Yes",
			countryDelivery: "Yes",
			zipCode: "Yes",
			companyEmployeeSize: "",
			companyRevenue: "",
			industry: "",
			ip: "Yes",
			optInDate: "Yes",
			optInTime: "Yes",
			optInTimeZone: "Yes",
			linkedIn: "No",
			domain: "No",
			comments: "No",
			alternatePhoneNo: "No",
			allocationID: "Yes",
			campaignName: "No",
			extra1: "Yes",
			extra2: "Yes",
			extra3: "Yes",
			extra4: "Yes",
			extra5: "Yes",

			extra6: "Yes",
			extra7: "Yes",
			extra8: "Yes",
			extra9: "Yes",
			extra10: "Yes",
			extra11: "Yes",
			extra12: "Yes",
			extra13: "Yes",
			extra14: "Yes",
			extra15: "Yes",
			extra16: "Yes",
			extra17: "Yes",
			extra18: "Yes",
			extra19: "Yes",
			extra20: "Yes", //Sandeep-task-3158-added code for extra 15 fields from 6 to 20
			customizeMapping: "",
			customizeMappingFormat: "Format 1",
			editDeliveryFormat: [],
			deleteAssetLink: [],
			backDynamicSuppressionInput: [],
			checkAllocation: [],
			supportDocID: "",
			suppDocName: "",
			index: "",
			assetRemovalMsg: "",
			leadStatus: "",
			linkFileName: "",
			agencyCustomizeMapping: [],
			assetNoChangeArray: [],
			switchValue: "ON",
			isAbmFileValid: false,
			abmJson: [],
			campaignStatus: "", //Sonali-3257=added campaigStatus to send at BE
			isEdited: "", //check whether di qa flag is edited
			multiTouchEdited: "", //Sonali-3257-flag for multitouch edited or not
			assetvizLeadsEdited: "",
		};

		this.handleChange = this.handleChange.bind(this);
		this.submitSupportingDocument = this.submitSupportingDocument.bind(this);
		this.handleChangeMultipleFile = this.handleChangeMultipleFile.bind(this);
		this.handleChangeMultipleFileOfOther =
			this.handleChangeMultipleFileOfOther.bind(this);
		this.handleChangeMultipleFileOfABM =
			this.handleChangeMultipleFileOfABM.bind(this);
		this.handleChangeMultipleFileOfExclusion =
			this.handleChangeMultipleFileOfExclusion.bind(this);
		this.handleChangeMultipleFileOfSuppression =
			this.handleChangeMultipleFileOfSuppression.bind(this);
		this.dynamicABMFile = this.dynamicABMFile.bind(this);
		this.handleBackButton = this.handleBackButton.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.deleteABMFile = this.deleteABMFile.bind(this);
		this.leadPerAssethandleChange = this.leadPerAssethandleChange.bind(this);
		this.multiTouchHandleChange = this.multiTouchHandleChange.bind(this);
		this.deleteAssetFile = this.deleteAssetFile.bind(this);
		this.removeLinkNew = this.removeLinkNew.bind(this);
		this.switchHandlechange = this.switchHandlechange.bind(this);
		this.handleFormatChange = this.handleFormatChange.bind(this);
		this.customizeMappingFormatHandleChange =
			this.customizeMappingFormatHandleChange.bind(this);
		this.customizeMappingCancleHandleChange =
			this.customizeMappingCancleHandleChange.bind(this);
	}

	/**
	 * @author Raunak Thakkar
	 * @param  Description Handle change of switch*/
	switchHandlechange(value) {
		value == true
			? this.setState({ switchValue: "ON" })
			: this.setState({ switchValue: "OFF" });
		this.setState({ isEdited: true }); //Sonali-3257-make this value true only if handle change function is called
	}
	/**

     /**
        * @author Sanobar Golandaj
        * @param  Description handle add  and remove the dynamic text fields*/

	addSuppressionLink() {
		linkSuppCount = this.state.suppressionLink.length;
		linkSuppCount = linkSuppCount + 1;
		var tempSuppArray = [
			{ suppressionFileName: "", suppressionLink: "", count: linkSuppCount },
		];
		let finalData = this.state.suppressionLink.concat(tempSuppArray);
		this.setState({ suppressionLink: finalData });
	}
	/**
	 * @author Somnath Keswad
	 * @param  Description handle remove the dynamic text fields of Suppression
	 * @return Description return remaining text field
	 */
	removeSuppressionLink(e, i) {
		var supportDocID = e.target.getAttribute("name");
		this.state.suppressionLink.splice(i, 1);
		this.setState({ suppressionLink: this.state.suppressionLink });
		if (supportDocID !== undefined || supportDocID != "") {
			fetch("campaign/deleteSuppressionLink?supportDocID=" + supportDocID)
				.then((res) => res.json())
				.then((deleteAssetLink) => {
					this.setState({ deleteAssetLink: deleteAssetLink });
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	/**
	 * @author Sanobar Golandaj
	 * @param  Description handle the Dynamic TextBox for AssetLink
	 */
	handleSuppressionLink(i, e) {
		const { name, value } = e.target;
		let suppressionLink = [...this.state.suppressionLink];
		suppressionLink[i] = { ...suppressionLink[i], [name]: value };
		this.setState({ suppressionLink: suppressionLink });
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description handle add the dynamic text fields
	 * @return Description return all text field
	 */

	//Raunak-3032-Edit Flow -Validation issues and revision of functionality code revision
	addLink(e) {
		var count = this.state.dynamicInput.length;
		count = count + 1;
		var assetLinkArray;
		if (
			this.state.leadPerAssetChk === "Yes" &&
			this.state.multiTouchEdit === "Yes"
		) {
			assetLinkArray = [
				{
					linkAssetFileName: "",
					assetLink: "",
					leadPerAsset: "0",
					status: "New",
					leadPercentage: "0",
					count: count,
					leadStatus: "",
					multiTouch: "1st Touch",
				},
			];
		} else {
			if (this.state.leadPerAssetChk === "Yes") {
				assetLinkArray = [
					{
						linkAssetFileName: "",
						assetLink: "",
						leadPerAsset: "0",
						status: "New",
						leadPercentage: "0",
						count: count,
						leadStatus: "",
					},
				];
			} else if (this.state.multiTouchEdit === "Yes") {
				assetLinkArray = [
					{
						linkAssetFileName: "",
						assetLink: "",
						count: count,
						status: "New",
						leadStatus: "",
						multiTouch: "1st Touch",
					},
				];
			} else {
				assetLinkArray = [
					{
						linkAssetFileName: "",
						assetLink: "",
						count: count,
						status: "New",
						leadStatus: "",
					},
				];
			}
		}
		let finalData = this.state.dynamicInput.concat(assetLinkArray);
		this.setState({ dynamicInput: finalData });
	}
	/**
	 * @author Somnath Keswad
	 * @param  Description handle remove the dynamic text fields
	 * @return Description return remaining text field
	 */
	removeLinkNew(e, i) {
		var supportDocID = e.target.getAttribute("name");
		var leadStatus = e.target.getAttribute("leadStatus");
		console.log("karan - removeLinkNew() - leadStatus:", leadStatus);
		var linkFileName = e.target.getAttribute("linkFileName");
		const { user } = this.props.auth; //Sonali-3257-send details at BE

		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		// var leadStatus=e.target.getAttribute('leadStatus');
		if (leadStatus === "New") {
			this.state.dynamicInput.splice(i, 1);
			this.setState({ dynamicInput: this.state.dynamicInput });
		}
		// var supportDocID=e.target.getAttribute('name');
		let self = this;
		if (supportDocID !== "NULL") {
			// var linkFileName=e.target.getAttribute('linkFileName');
			//Sonali-3257-added this data to sent at BE
			let deleteData = {
				supportDocID: supportDocID,
				campID: campID,
				fileName: linkFileName,
				campaignStatus: this.state.campaignStatus,
			}; // karan-task-3719-vapt header and query params

			fetch("campaign/deleteAssetLink", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(deleteData),
			})
				.then((res) => res.json())
				.then((deleteAssetLink) => {
					if (leadStatus !== "New") {
						var newArray = this.state.dynamicInput.filter(function (a) {
							return a.status === "New";
						}); // this is for if first add one or more text box then remove old response append newly added as it is
						fetch("campaign/getAssetLink?campID=" + campID)
							.then((res) => res.json())
							.then((dynamicInput) => {
								var assetLink = dynamicInput.concat(newArray);
								self.setState({ dynamicInput: assetLink });
								if (
									dynamicInput !== undefined ||
									dynamicInput !== "" ||
									dynamicInput !== null
								) {
									var linkArray = dynamicInput.filter(function (a) {
										return a.status !== "Removed";
									});
									if (linkArray.length > 0) {
										linkflagBack = true;
										allassetLength = true;
									}
								}
								let message = "This asset Link is no longer used";
								Swal.fire({
									text: message,
									type: "success",
									confirmButtonText: "Ok",
									allowOutsideClick: false,
									heightAuto: false,
								});
							})
							.catch(function (err) {
								console.log(err);
							});
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			var status = e.target.getAttribute("status");
			var index = "";
			if (status === "New") {
				index = i;
			} else {
				index = this.state.index;
			}
			this.state.dynamicInput.splice(index, 1);
			this.setState({ dynamicInput: this.state.dynamicInput });
		}
	}
	removeLink(e, i) {
		const { user } = this.props.auth; //Sonali-3257-send details at BE

		var supportDocID = e.target.getAttribute("name");
		var leadStatus = e.target.getAttribute("leadStatus");
		console.log("karan - removeLink() - leadStatus:", leadStatus);
		var linkFileName = e.target.getAttribute("linkFileName");
		var message = "";
		if (leadStatus === "Client Accepted") {
			message =
				"Leads are accepted by client, Are you sure you want Delete this Asset?";
		} else if (leadStatus === "Not Accepted") {
			message =
				"Leads are submitted, are you sure you want to delete this Asset ? if deleted then the leads will also be rejected .";
		} else {
			message = "Are you sure you want to remove this asset?";
		}

		Swal.fire({
			title: message,
			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#3085d6",
			confirmButtonColor: "#d33",
			allowOutsideClick: false,
			confirmButtonText: "Yes, Remove it!",
			heightAuto: false,
		}).then((result) => {
			if (result.value) {
				var parsed = queryString.parse(this.props.location.search);
				var campID = parsed.campID;
				// var leadStatus=e.target.getAttribute('leadStatus');
				if (leadStatus === "New") {
					this.state.dynamicInput.splice(i, 1);
					this.setState({ dynamicInput: this.state.dynamicInput });
				}
				// var supportDocID=e.target.getAttribute('name');
				let self = this;
				if (supportDocID !== "NULL") {
					// var linkFileName=e.target.getAttribute('linkFileName');

					//Sonali-3257-added this data to sent at BE
					let deleteData = {
						supportDocID: supportDocID,
						campID: campID,
						fileName: linkFileName,
						campaignStatus: this.state.campaignStatus,
					}; // karan-task-3719-vapt header and query params

					fetch("campaign/deleteAssetLink", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(deleteData),
					})
						.then((res) => res.json())
						.then((deleteAssetLink) => {
							if (leadStatus !== "New") {
								var newArray = this.state.dynamicInput.filter(function (a) {
									return a.status === "New";
								}); // this is for if first add one or more text box then remove old response append newly added as it is
								fetch("campaign/getAssetLink?campID=" + campID)
									.then((res) => res.json())
									.then((dynamicInput) => {
										var assetLink = dynamicInput.concat(newArray);
										self.setState({ dynamicInput: assetLink });
										if (
											dynamicInput !== undefined ||
											dynamicInput !== "" ||
											dynamicInput !== null
										) {
											var linkArray = dynamicInput.filter(function (a) {
												return a.status !== "Removed";
											});
											if (linkArray.length > 0) {
												linkflagBack = true;
												allassetLength = true;
											}
										}
										let message = "This asset Link is no longer used";
										Swal.fire({
											text: message,
											type: "success",
											confirmButtonText: "Ok",
											allowOutsideClick: false,
											heightAuto: false,
										});
									})
									.catch(function (err) {
										console.log(err);
									});
							}
						})
						.catch(function (err) {
							console.log(err);
						});
				} else {
					var status = e.target.getAttribute("status");
					var index = "";
					if (status === "New") {
						index = i;
					} else {
						index = this.state.index;
					}
					this.state.dynamicInput.splice(index, 1);
					this.setState({ dynamicInput: this.state.dynamicInput });
				}
			}
		});
	}
	openLinkPopup(e) {
		var supportDocID = e.target.getAttribute("name");
		var leadStatus = e.target.getAttribute("leadStatus");
		var linkFileName = e.target.getAttribute("linkFileName");
		var message = "";
		if (leadStatus === "Client Accepted") {
			message =
				"This asset lead is accepted by client. Are you sure you want remove this Asset?";
		} else if (leadStatus === "Not Accepted") {
			message =
				"This asset lead is submitted by publisher but lead is not accepted.If you remove this asset lead will be rejected. Are you sure you want remove this Asset? ";
		} else {
			message = "Are you sure you want to remove this asset?";
		}
		var index = e.target.getAttribute("index");
		this.setState({
			supportDocID: supportDocID,
			index: index,
			assetRemovalMsg: message,
			leadStatus: leadStatus,
			linkFileName: linkFileName,
		});
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description handle the Dynamic TextBox for AssetLink
	 * @return Description return Number of text field
	 */
	handleAssetLink(i, e) {
		const { name, value } = e.target;
		if (name === "leadPercentage") {
			linkflagBack = true;
			var leadAllocation = this.state.totalLeadAllocation;
			var assetLead1 = (value * leadAllocation) / 100;
			assetLead1 = Math.round(assetLead1);
			var assetWiseLead = 0;
			var name1 = "leadPerAsset";
			let dynamicInput = [...this.state.dynamicInput];
			dynamicInput[i] = { ...dynamicInput[i], [name]: value };
			dynamicInput[i] = { ...dynamicInput[i], [name1]: assetLead1 };
			// this.setState({dynamicInput});
			this.state.dynamicInput = [...dynamicInput];
			this.forceUpdate();
			this.render();
		} else {
			let dynamicInput = [...this.state.dynamicInput];
			dynamicInput[i] = { ...dynamicInput[i], [name]: value };
			this.state.dynamicInput = [...dynamicInput];

			var assetName_touch1 = "";
			var assetName_touch2 = "";
			var assetName_touch3 = "";
			var assetTimestamp_touch1 = "";
			var assetTimestamp_touch2 = "";
			var assetTimestamp_touch3 = "";
			for (var i = 0; i < dynamicInput.length; i++) {
				if (dynamicInput[i].multiTouch == "1st Touch") {
					assetName_touch1 = "1st Touch";
					assetTimestamp_touch1 = "Yes";
					dynamicInput[i] = {
						...dynamicInput[i],
						["assetName_touch"]: assetName_touch1,
						["assetTimestamp_touch"]: assetTimestamp_touch1,
					};
				}
				if (dynamicInput[i].multiTouch == "2nd Touch") {
					assetName_touch2 = "2nd Touch";
					assetTimestamp_touch2 = "Yes";
					dynamicInput[i] = {
						...dynamicInput[i],
						["assetName_touch"]: assetName_touch2,
						["assetTimestamp_touch"]: assetTimestamp_touch2,
					};
				}
				if (dynamicInput[i].multiTouch == "3rd Touch") {
					assetName_touch3 = "3rd Touch";
					assetTimestamp_touch3 = "Yes";
					dynamicInput[i] = {
						...dynamicInput[i],
						["assetName_touch"]: assetName_touch3,
						["assetTimestamp_touch"]: assetTimestamp_touch3,
					};
				}
			}

			this.setState({ dynamicInput: dynamicInput });

			this.forceUpdate();
			this.render();
		}
	}

	/**
	 * @author Supriya Gore
	 * @param  Description handle the Dynamic TextBox for Asset Link Lead
	 * @return Description return Number of text field
	 */
	// handleLeadOfAssetLink(i,e) {
	//   const { name, value } = e.target;
	//     let dynamicInput= [...this.state.dynamicInput];
	//     dynamicInput[i] = { ...dynamicInput[i], [name]: value};

	//     this.state.dynamicInput=[...dynamicInput];
	//    // this.setState({backDynamicInput});
	//     assetLinkLength=dynamicInput.length;

	// }

	deleteABMFile(e) {}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle edit budget
	 * @return Description return budget function
	 */
	handleChange(e) {
		// const { name, value } = e.target;
		// let sdBudget = [...this.state.sdBudget];
		// sdBudget[0] = { ...sdBudget[0], [name]: value };
		// this.setState({ sdBudget });
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the multiple asset uploads
	 * @return Description return successfully files upload message
	 */

	handleChangeMultipleFile(e) {
		e.preventDefault();

		var MultipleFileCounter = 0;
		let self = this;
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		assetnames = [];
		this.setState({ displayTable: "block" });
		let assetfilesData = e.target.files;
		if (self.state.dynamicAssetName === 0) {
			var size = self.state.inputType.length + 13;
			size = size + 13;
		} else {
			var len = self.state.dynamicAssetName.length - 1;

			if (len > 0) {
				var num = Math.floor(Math.random() * 137);
				var size1 = self.state.dynamicAssetName[len].key;
				var size = size1 + self.state.inputType.length + num; // hardcoded because incremental kay
			} else {
				var num = Math.floor(Math.random() * 100);
				var size = self.state.inputType.length + num;
			}
		}
		key = size;
		var filenew = [];
		var j = 0;
		var newArray = [];
		var assetNameDuplicateFileCheck = [];
		for (let j = 0; j < self.state.inputType.length; j++) {
			assetNameDuplicateFileCheck.push(self.state.inputType[j].suppDocName);
		}
		let fileerrors = {};

		if ($.isEmptyObject(assetnames)) {
			for (var i = 0, l = assetfilesData.length; i < l; i++) {
				var assetNameTemp = assetfilesData[i].name;
				var assetCheckDuplicateLength = 0;
				var assetCheckDuplicate = assetNameDuplicateFileCheck.filter(function (
					word
				) {
					return assetNameTemp.includes(word);
				});
				assetCheckDuplicateLength = assetCheckDuplicate.length;
				if (assetCheckDuplicateLength > 0) {
					fileerrors["FileError"] =
						"Please select file with another name   " +
						assetNameTemp +
						"  already exists";
					this.setState({ fileerrors: fileerrors });
				} else {
					this.setState({ fileerrors: "" });
					assetnames.push(assetfilesData[i].name);
					var assetFileCheck = assetnames.MultipleFileCounter++;
					key = key + 3;
					self.state.inputType.push({
						suppDocName: assetfilesData[i].name,
						key: key,
					});
				}
			}
			self.setState({
				inputType: self.state.inputType,
				assetsFile: assetnames,
			});
		} else {
			for (var j = 0, m = assetfilesData.length; j < m; j++) {
				key = key + 3;
				self.state.inputType.push({
					suppDocName: assetfilesData[j].name,
					key: key,
				});
				assetnames.push(assetfilesData[j].name);
				MultipleFileCounter++;
			}
			self.setState({
				inputType: self.state.inputType,
				assetsFile: assetnames,
			});
		}
		assetLength = assetnames.length;
		if (assetLength > 0) {
			this.setState({
				asseterrors: "",
			});
		}

		var link;
		var filename = "";
		var arrayLength = assetnames.length;
		var nooutput = document.getElementById("noassetList");
		var assetarrayLength = assetnames.length;
		var noassetoutput = document.getElementById("noassetList");
		var valuenoassetoutput = document.getElementById("noassetList").innerHTML;

		var assetres = new Array();
		assetres = valuenoassetoutput.split(" ");

		var backassetarraylength;
		for (i = 0; i < assetres.length; i++) {
			backassetarraylength = assetres[0];
		}
		assetarrayLength = parseInt(assetarrayLength);
		backassetarraylength = parseInt(backassetarraylength);
		MultipleFileCounter = parseInt(MultipleFileCounter);
		var assetarrayLength1;
		assetarrayLength1 =
			MultipleFileCounter + backassetarraylength + assetLength;

		if (assetarrayLength1 > 0) {
			noassetoutput.innerHTML = assetarrayLength1 + " Asset  Files Selected";
		} else {
			noassetoutput.innerHTML = "0 Asset File Selected";
		}

		for (var j = 0; j < assetarrayLength; j++) {
			newassetfilenames.push(assetnames[j]);
		}

		var HTML = "<table style='width:100%'>";
		var imgsuccess;
		for (var j = 0; j < newassetfilenames.length; j++) {
			var isExist = assetValidationArray.includes(newassetfilenames[j]);
			if (isExist === true) {
				imgsuccess =
					'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';

				link =
					'<a class="removeAssetFile" href="#" data-fileid=' +
					'"' +
					newassetfilenames[j] +
					'"' +
					'><span style="font-family:roboto;font-size:13px;font-weight:500;float:right">Remove</span></a>';
				HTML +=
					"<tr style='background-color:#fbd5db'><td style='color:red;border: 1px solid #ffffff;padding-bottom:0px'>" +
					imgsuccess +
					"&nbsp;" +
					"<span  style ='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" +
					newassetfilenames[j] +
					"</span>" +
					link +
					"<br><br></td></tr>";
			} else {
				imgsuccess =
					'<img src="success.png" alt="sucess" height="18" width="18" style=:"float:left"/>';
				link =
					'<a class="removeAssetFile" href="#" data-fileid=' +
					'"' +
					newassetfilenames[j] +
					'"' +
					'><span style="font-family:roboto;font-size:13px;font-weight:500;float:right">Remove</span></a>';
				HTML +=
					"<tr style='background-color:#daecda'><td style='color:green;border: 1px solid #ffffff;padding-bottom:0px'>" +
					imgsuccess +
					"&nbsp;" +
					"<span style='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" +
					newassetfilenames[j] +
					"</span>" +
					link +
					"<br><br></td></tr>";
			}
		}
		HTML += "</table>";

		document.getElementById("assetList").innerHTML = HTML;
		$(document).on("click", ".removeAssetFile", function (e) {
			e.preventDefault();

			// var clickedFile = $(this).parent().children("a").data("fileid");
			var clickedFile = $(this).closest("a").data("fileid");

			var j = 0;

			for (j; j < newassetfilenames.length; j++) {
				var temp = j;

				if (newassetfilenames[temp] === clickedFile) {
					var isExist = assetValidationArray.includes(newassetfilenames[j]);

					if (isExist === true) {
						var index = assetValidationArray.indexOf(newassetfilenames[j]);
						assetValidationArray.splice(index, 1);
					}
					filename = newassetfilenames[temp];

					newassetfilenames.splice(j, 1);
					// filesData.splice(j,1);
					$(this).parent().remove();
					assetarrayLength = newassetfilenames.length;
					if (newassetfilenames.length == 0) {
						assetValidationArray = [];
					}

					var result = [];
					result = Array.from(
						new Set(self.state.dynamicAssetName.map((p) => p.key))
					).map((key) => {
						return {
							key: key,
							suppDocName: self.state.dynamicAssetName.find(
								(p) => p.key === key
							).suppDocName,
						};
					});
					for (var k = 0; k < rejectAssetCount.length; k++) {
						if (rejectAssetCount[k] === clickedFile) {
							rejectAssetCount.splice(k, 1);
						}
					}
					for (var k = 0; k < successAssetCount.length; k++) {
						if (successAssetCount[k] === clickedFile) {
							successAssetCount.splice(k, 1);
						}
					}
					var rejectedCount =
						newassetfilenames.length - successAssetCount.length;
					if (rejectedCount < 0) {
						rejectedCount = 0;
					}
					var succcount = successAssetCount.length;
					var rejeccount = rejectAssetCount.length;

					var totalcount = succcount + rejeccount;
					var msg1 =
						"<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" +
						totalcount +
						"</span>&nbsp;(Successfully Uploaded :" +
						successAssetCount.length +
						", <font color='red'> Upload Failed :" +
						rejectAssetCount.length +
						"</font>)";
					var message1 = document.getElementById("assetMessage");
					if ($("#assetMessage").text() === "") {
						message1.innerHTML += msg1;
					} else {
						message1.innerHTML = msg1;
					}

					for (var i = 0; i < self.state.inputType.length; i++) {
						if (filename === self.state.inputType[i].suppDocName) {
							self.state.inputType.splice(i, 1);
							result.splice(i, 1);
							self.state.dynamicAssetName.splice(i, 1);
						}
					}

					self.setState({
						inputType: self.state.inputType,
						dynamicAssetName: self.state.dynamicAssetName,
						displayTable: "block",
					});

					if (self.state.inputType.length == 0) {
						self.setState({ displayTable: "none" });
					}
					var typeOfSuppDoc = "Asset";
					let data = {
						campID: campID,
						names: filename,
						typeOfSuppDoc: typeOfSuppDoc,
					};

					fetch("campaign/supportingDocumentDeleteFile", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						//mode:'no-cors',
						//body: data
						body: JSON.stringify(data),
					});

					assetarrayLength = parseInt(assetarrayLength);
					backassetarraylength = parseInt(backassetarraylength);
					assetarrayLength1 = assetarrayLength + backassetarraylength;

					if (assetarrayLength1 > 0) {
						noassetoutput.innerHTML = assetarrayLength1;
					} else {
						noassetoutput.innerHTML = "0 Asset File Selected";
						$("#asset").val("");
						var message = document.getElementById("assetMessage");
						message.innerHTML = "";
					}
				} // end of  if(names[temp] === clickedFile)
			} // end of for
		}); //$(document).on

		const { user } = this.props.auth;
		var userID = user.id;
		var data = new FormData();
		var campaignStatus = this.state.campaignStatus; //Sonali-3257-need these details at BE to insert into campaignLog
		data.append("campID", campID);
		data.append("userID", userID);
		data.append("user", JSON.stringify(user)); //Sonali-3257-need these details at BE to insert into campaignLog
		data.append("campaignStatus", campaignStatus); //Sonali-3257-need these details at BE to insert into campaignLog
		var assetNameFlag;
		for (var i = 0, l = assetfilesData.length; i < l; i++) {
			var assetNameTemp = assetfilesData[i].name;
			var assetCheckDuplicateLength = 0;
			var assetCheckDuplicate = assetNameDuplicateFileCheck.filter(function (
				word
			) {
				return assetNameTemp.includes(word);
			});
			assetCheckDuplicateLength = assetCheckDuplicate.length;
			if (assetCheckDuplicateLength > 0) {
			} else {
				data.append("file", assetfilesData[i]);
				assetNameFlag = true;
				this.setState({ fileerrors: "" });
			}
		}

		//campaign/supportingDocument
		if (assetNameFlag === true) {
			Swal.fire({
				heightAuto: false,
				allowOutsideClick: false,
				onOpen: () => {
					Swal.showLoading();
					fetch("campaign/editSupportingDocumentAsset", {
						method: "POST",
						// headers: {'Content-Type': 'application/json'},
						//mode:'no-cors',
						// body: data
						body: data,
					})
						.then((res) => res.json())
						.then((assetList) => {
							Swal.fire({
								text: "File Upload Complete",
								type: "success",
								heightAuto: false,
							});
							if (assetList[0].success === true) {
								var suppDocName = [];
								for (var i = 0; i < assetList.length; i++) {
									suppDocName.push(assetList[i].successList);
									successAssetCount.push(assetList[i].successList);
								}
								for (var i = 0; i < suppDocName.length; i++) {
									var count = 0;
									for (var j = 0; j < newassetfilenames.length; j++) {
										if (suppDocName[i] == newassetfilenames[j]) {
											count = count + 1;
										}
									}
								}

								if (count > 1) {
									newassetfilenames.pop();
									assetValidationArray.pop();
								}
								var rejectCount = 0;
								var imgsuccess;
								var HTML2 = "<table style='width:100%'>";
								for (var j = 0; j < newassetfilenames.length; j++) {
									var isExist = assetValidationArray.includes(
										newassetfilenames[j]
									);
									if (isExist === true) {
										/**** If uploaded file is wrong then it displays as in Red color */
										rejectCount = rejectCount + 1;
										imgsuccess =
											'<img src="failed.png" alt="failed" height="18 width="18" style="float:left"/>';

										link =
											'<a class="removeAssetFile" href="#" data-fileid=' +
											'"' +
											newassetfilenames[j] +
											'"' +
											'><span style="font-family:roboto;font-size:13px;font-weight:500;float:right">Remove</span></a>';
										HTML2 +=
											"<tr style='background-color:#fbd5db'><td style='color:red;border: 1px solid #ffffff;padding-bottom:0px'>" +
											imgsuccess +
											"&nbsp;" +
											"<span  style ='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" +
											newassetfilenames[j] +
											"</span>" +
											link +
											"<br><br></td></tr>";
									} else {
										imgsuccess =
											'<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>';
										link =
											'<a class="removeAssetFile" href="#" data-fileid=' +
											'"' +
											newassetfilenames[j] +
											'"' +
											'><span style="font-family:roboto;font-size:13px;font-weight:500;float:right">Remove</span></a>';
										HTML2 +=
											"<tr style='background-color:#daecda'><td style='color:green;border: 1px solid #ffffff;padding-bottom:0px'>" +
											imgsuccess +
											"&nbsp;" +
											"<span style='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" +
											newassetfilenames[j] +
											"</span>" +
											link +
											"<br><br></td></tr>";
									}
								}
								HTML2 += "</table>";
								var imgsuccess;
								document.getElementById("assetList").innerHTML = HTML2;
								var succcount = successAssetCount.length;
								var rejeccount = rejectAssetCount.length;

								var totalcount = succcount + rejeccount;
								var msg =
									"<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" +
									totalcount +
									"</span>&nbsp;( Successfully Uploaded:" +
									successAssetCount.length +
									", <font color='red'> Upload Failed :" +
									rejectAssetCount.length +
									"</font>)";

								var message = document.getElementById("assetMessage");
								if ($("#assetMessage").text() === "") {
									message.innerHTML += msg;
								} else {
									message.innerHTML = msg;
								}
							} else {
								for (var i = 0; i < assetList.length; i++) {
									assetValidationArray.push(assetList[i].rejectedFile);
								}

								var HTML = "<table style='width:100%'>";

								for (var j = 0; j < newassetfilenames.length; j++) {
									var isValid = assetValidationArray.includes(
										newassetfilenames[j]
									);
									if (isValid === true) {
										rejectAssetCount.push(newassetfilenames[j]);
										imgsuccess =
											'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';

										link =
											'<a class="removeAssetFile" href="#" data-fileid=' +
											'"' +
											newassetfilenames[j] +
											'"' +
											'><span style="font-family:roboto;font-size:13px;font-weight:500;float:right">Remove</span></a>';
										HTML +=
											"<tr style='background-color:#fbd5db'><td style='color:red;border: 1px solid #ffffff;padding-bottom:0px'>" +
											imgsuccess +
											"&nbsp;" +
											"<span  style ='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" +
											newassetfilenames[j] +
											"</span>" +
											link +
											"<br><br></td></tr>";
									} else {
										let isTrue = successAssetCount.includes(
											newassetfilenames[j]
										);
										if (isTrue === false) {
											successAssetCount.push(newassetfilenames[j]);
										}
										imgsuccess =
											'<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>';
										link =
											'<a class="removeAssetFile" href="#" data-fileid=' +
											'"' +
											newassetfilenames[j] +
											'"' +
											'><span style="font-family:roboto;font-size:13px;font-weight:500;float:right">Remove</span></a>';
										HTML +=
											"<tr style='background-color:#daecda'><td style='color:green;border: 1px solid #ffffff;padding-bottom:0px'>" +
											imgsuccess +
											"&nbsp;" +
											"<span style='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left>" +
											newassetfilenames[j] +
											"</span>" +
											link +
											"<br><br></td></tr>";
									}
								}
								HTML += "</table>";

								var rejectedCount =
									newassetfilenames.length - successAssetCount.length;
								if (rejectedCount < 0) {
									rejectedCount = 0;
								}
								document.getElementById("assetList").innerHTML = HTML;
								var succcount = successAssetCount.length;
								var rejeccount = rejectAssetCount.length;

								var totalcount = succcount + rejeccount;
								var msg1 =
									"<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" +
									totalcount +
									"</span>&nbsp;(Successfully Uploaded  :" +
									successAssetCount.length +
									", <font color='red'> Upload Failed  :" +
									rejectAssetCount.length +
									"</font>)";
								var message1 = document.getElementById("assetMessage");
								if ($("#assetMessage").text() === "") {
									message1.innerHTML += msg1;
								} else {
									message1.innerHTML = msg1;
								}
							}
						})
						.catch(function (err) {
							console.log(err);
							Swal.fire({
								text: "Error Occured Please Try Again",
								type: "error",
								heightAuto: false,
							});
						});
				},
			});
			this.setState({
				files: data,
			});
		}
	}
	/**
	 * @author Raunak Thakkar
	 * @param  Description handle the multi touch
	 * @return Description return successfully multi touch
	 */
	//Sanjana-Godbole-3038-Edit Flow -Validation issues and revision of functionality code revision
	multiTouchHandleChange(e) {
		let multiTouchEdit = e.target.value;
		var checkedValue = false;
		var assetLinkFile = [];
		let multiTouchKey = "multiTouch";
		let multiTouchValue = "1st Touch";
		this.setState({ multiTouchEdited: true }); //Sonali-3257-flag for multitouch edited or not

		if (e.target.checked === true) {
			multiTouchEdit = "Yes";
			checkedValue = true;

			for (var i = 0; i < this.state.inputType.length; i++) {
				if (this.state.inputType[i] === undefined) {
				} else {
					if (this.state.inputType[i].hasOwnProperty("multiTouch")) {
						let inputType = [...this.state.inputType];
						inputType[i] = {
							...inputType[i],
							[multiTouchKey]: this.state.inputType[i].multiTouch,
						};
						this.state.inputType = [...inputType];
						this.setState({
							inputType: inputType,
							dynamicAssetName: inputType,
						});
					} else {
						let inputType = [...this.state.inputType]; //Snehal-2849-create camp---error msg issue(PAT issue)
						inputType[i] = {
							...inputType[i],
							[multiTouchKey]: multiTouchValue,
						}; //Snehal-2849-create camp---error msg issue(PAT issue)
						this.state.inputType = [...inputType]; //Snehal-2849-create camp---error msg issue(PAT issue)
						this.setState({
							inputType: inputType,
							dynamicAssetName: inputType,
						}); //Snehal-2849-create camp---error msg issue(PAT issue)
					}
				}
			}

			//Asset Link
			for (var i = 0; i < this.state.dynamicInput.length; i++) {
				if (this.state.dynamicInput[i] === undefined) {
				} else {
					if (this.state.dynamicInput[i].hasOwnProperty("multiTouch")) {
						//Sanjana-3038-Edit Flow -Validation issues and revision of functionality-if block added to add bydefault "1-Touch " if user click Require Multitouch Campaign

						if (this.state.dynamicInput[i].multiTouch == "") {
							let dynamicInput = [...this.state.dynamicInput];
							dynamicInput[i] = {
								...dynamicInput[i],
								[multiTouchKey]: multiTouchValue,
							};
							this.state.dynamicInput = [...dynamicInput];
							this.setState({ dynamicInput: dynamicInput });
						} else {
							let dynamicInput = [...this.state.dynamicInput];
							dynamicInput[i] = {
								...dynamicInput[i],
								[multiTouchKey]: this.state.dynamicInput[i].multiTouch,
							};
							this.state.dynamicInput = [...dynamicInput];
							this.setState({ dynamicInput: dynamicInput });
						}
					} else {
						let dynamicInput = [...this.state.dynamicInput]; //Snehal-2849-create camp---error msg issue(PAT issue)
						dynamicInput[i] = {
							...dynamicInput[i],
							[multiTouchKey]: multiTouchValue,
						}; //Snehal-2849-create camp---error msg issue(PAT issue)
						this.state.dynamicInput = [...dynamicInput]; //Snehal-2849-create camp---error msg issue(PAT issue)
						this.setState({ dynamicInput: dynamicInput }); //Snehal-2849-create camp---error msg issue(PAT issue)
					}
				}
			}
		} else {
			multiTouchEdit = "No";
			checkedValue = false;
		}
		this.setState({
			multiTouchEdit: multiTouchEdit,
			checkedValueMultiTouch: checkedValue,
		});
	}

	/**
	 * @author Supriya Gore
	 * @param  Description handle the leads per asset
	 * @return Description return successfully leads per asset
	 */

	leadPerAssethandleChange(e) {
		let leadPerAssetChk = e.target.value;

		//var leadPerAsset=document.getElementById("leadPerAsset");
		this.setState({ assetvizLeadsEdited: true }); //Sonali-3257-added to check whether asset viz leads edited
		var leadlabel = document.getElementById("leadlabel");
		var checkedValue = false;
		if (e.target.checked === true) {
			//leadPerAsset.style.display="inline";
			leadlabel.style.display = "inline";
			leadPerAssetChk = "Yes";
			checkedValue = true;
			//Raunak-3032-Edit Flow -Validation issues and revision of functionality code revision
			for (let i = 0; i < this.state.inputType.length; i++) {
				this.state.inputType[i] = {
					...this.state.inputType[i],
					leadPercentage: 0,
					leadPerAsset: 0,
				};
			}

			for (let i = 0; i < this.state.dynamicInput.length; i++) {
				this.state.dynamicInput[i] = {
					...this.state.dynamicInput[i],
					leadPercentage: 0,
					leadPerAsset: 0,
				};
			}
		} else {
			// leadPerAsset.style.display="none";
			leadlabel.style.display = "none";
			leadPerAssetChk = "No";
			checkedValue = false;
		}

		// if (e.target.checked===true) {
		//   leadPerAssetChk = 'Yes'
		// } else {
		//   leadPerAssetChk = 'No'
		// }
		// this.state.leadPerAssetChk=leadPerAssetChk;
		////Raunak-3032-Edit Flow -Validation issues and revision of functionality code revision
		this.setState({
			leadPerAssetChk: leadPerAssetChk,
			checkedValue: checkedValue,
			inputType: this.state.inputType,
			dynamicInput: this.state.dynamicInput,
		});
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the multiple other files uploads
	 * @return Description return successfully files upload message
	 */
	handleChangeMultipleFileOfOther(e) {
		othernames = [];
		let self = this;

		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener(
			"change",
			function () {
				let otherfilesData = e.files;
				for (let i = 0, l = otherfilesData.length; i < l; i++) {
					let fileName = otherfilesData[i].name;
					if (chkOtherExist.includes(fileName)) {
						duplicateOtherFile.push(fileName);
					} else {
						othernames.push(otherfilesData[i].name);
					}
				}

				/**
				 * @author Sanjana Godbole
				 * @param  A FileList object that lists every selected file
				 * @return List of uploded file along with remove link
				 */

				var link;
				var filename = "";
				var arrayLength = othernames.length;
				var otherarrayLength = othernames.length;
				var nootheroutput = document.getElementById("nootherList");
				var valuenootheroutput =
					document.getElementById("nootherList").innerHTML;

				var otherres = new Array();
				otherres = valuenootheroutput.split(" ");

				var backotherarraylength;
				for (let i = 0; i < otherres.length; i++) {
					backotherarraylength = otherres[0];
				}

				otherarrayLength = parseInt(otherarrayLength);
				backotherarraylength = parseInt(backotherarraylength);

				var otherarrayLength1;
				otherarrayLength1 = otherarrayLength + backotherarraylength;

				if (otherarrayLength1 > 0) {
					nootheroutput.innerHTML = otherarrayLength1;
				} else {
					nootheroutput.innerHTML = "0 Other File Selected";
				}
				for (var j = 0; j < otherarrayLength; j++) {
					newotherfilenames.push(othernames[j]);
					chkOtherExist.push(othernames[j]);
				}

				var HTML = "<table>";
				var imgsuccess;
				for (var j = 0; j < newotherfilenames.length; j++) {
					var isExist = otherValidationArray.includes(newotherfilenames[j]);
					if (isExist === true) {
						imgsuccess =
							'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
						link =
							'<a class="removeOtherFile" href="#" data-fileid=' +
							'"' +
							newotherfilenames[j] +
							'"' +
							'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
						HTML +=
							"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
							imgsuccess +
							"&nbsp;" +
							"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
							newotherfilenames[j] +
							"</span>" +
							link +
							"<br><br></td></tr>";
					} else {
						imgsuccess =
							'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';
						link =
							'<a class="removeOtherFile" href="#" data-fileid=' +
							'"' +
							newotherfilenames[j] +
							'"' +
							'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
						HTML +=
							"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
							imgsuccess +
							"&nbsp;" +
							"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
							newotherfilenames[j] +
							"</span>" +
							link +
							"<br><br></td></tr>";
					}
				}
				HTML += "</table>";

				document.getElementById("otherList").innerHTML = HTML;
				$(document).on("click", ".removeOtherFile", function (e) {
					e.preventDefault();
					var clickedFile = $(this).parent().children("a").data("fileid");
					var j = 0;
					for (j; j < newotherfilenames.length; j++) {
						var temp = j;

						if (newotherfilenames[temp] === clickedFile) {
							var isExist = otherValidationArray.includes(newotherfilenames[j]);
							if (isExist === true) {
								var index = otherValidationArray.indexOf(newotherfilenames[j]);
								otherValidationArray.splice(index, 1);
							}
							filename = newotherfilenames[temp];

							newotherfilenames.splice(j, 1);
							chkOtherExist.splice(j, 1);
							$(this).parent().remove();
							otherarrayLength = newotherfilenames.length;
							if (newotherfilenames.length == 0) {
								otherValidationArray = [];
							}
							for (var k = 0; k < rejectOtherCount.length; k++) {
								if (rejectOtherCount[k] === clickedFile) {
									rejectOtherCount.splice(k, 1);
								}
							}
							for (var k = 0; k < successOtherCount.length; k++) {
								if (successOtherCount[k] === clickedFile) {
									successOtherCount.splice(k, 1);
								}
							}
							var rejectedCount =
								newotherfilenames.length - successOtherCount.length;
							if (rejectedCount < 0) {
								rejectedCount = 0;
							}

							var msg1 = "";
							var message1 = document.getElementById("otherMessage");

							if (otherValidationArray.length > 0) {
							} else {
								message1.innerHTML = msg1;
							}
							// if($("#otherMessage").text()==="")
							//   {message1.innerHTML += msg1;
							//   }else{message1.innerHTML= msg1;}

							var typeOfSuppDoc = "Other";
							let data = {
								campID: campID,
								names: filename,
								typeOfSuppDoc: typeOfSuppDoc,
							};

							fetch("campaign/supportingDocumentDeleteFile", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								//mode:'no-cors',
								//body: data
								body: JSON.stringify(data),
							});
							otherarrayLength = parseInt(otherarrayLength);
							backotherarraylength = parseInt(backotherarraylength);
							otherarrayLength1 = otherarrayLength + backotherarraylength;

							if (otherarrayLength1 > 0) {
								nootheroutput.innerHTML = otherarrayLength1;
							} else {
								nootheroutput.innerHTML = "0 Other File Selected";
								$("#other").val("");
								var message = document.getElementById("otherMessage");
								var number = document.getElementById("otherMessage").innerHTML;
							}
						} // end of  if(names[temp] === clickedFile)
					} // end of for
				}); //$(document).on
				const { user } = this.props.auth;
				var userID = user.id;

				var data = new FormData();
				data.append("campID", campID);
				data.append("userID", userID);
				data.append("user", JSON.stringify(user)); //Sonali-3257-need these details at BE to insert into campaignLog
				data.append("campaignStatus", this.state.campaignStatus); //Sonali-3257-need these details at BE to insert into campaignLog

				for (let i = 0, l = otherfilesData.length; i < l; i++) {
					let fileName = otherfilesData[i].name;
					if (duplicateOtherFile.includes(fileName)) {
					} else {
						data.append("file", otherfilesData[i]);
					}
				}
				//campaign/supportingDocument
				Swal.fire({
					heightAuto: false,
					allowOutsideClick: false,
					onOpen: () => {
						Swal.showLoading();
						fetch("campaign/editSupportingDocumentOther", {
							method: "POST",
							//mode:'no-cors',
							body: data,
						})
							.then((res) => res.json())
							.then((otherList) => {
								if (otherList[0].success === true) {
									//added by raunak message will be shown based on type of response
									Swal.fire({ text: "File Upload Complete", type: "success" });
								} else {
									Swal.fire({
										text: "Invalid format of the file",
										type: "error",
									});
								}
								if (otherList[0].success === true) {
									var suppDocName = [];
									for (var i = 0; i < otherList.length; i++) {
										suppDocName.push(otherList[i].successList);
										successOtherCount.push(otherList[i].successList); //only for count
									}
									for (var i = 0; i < suppDocName.length; i++) {
										var count = 0;
										for (var j = 0; j < newotherfilenames.length; j++) {
											if (suppDocName[i] == newotherfilenames[j]) {
												count = count + 1;
											}
										}
									}

									if (count > 1) {
										newotherfilenames.pop();
										otherValidationArray.pop();
									}
									var RejectCount = 0;
									var HTML2 = "<table>";
									var imgsuccess;
									for (var j = 0; j < newotherfilenames.length; j++) {
										var isExist = otherValidationArray.includes(
											newotherfilenames[j]
										);
										if (isExist === true) {
											/**** If uploaded file is wrong then it displays as in Red color */
											RejectCount = RejectCount + 1;
											imgsuccess =
												'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
											link =
												'<a class="removeOtherFile" href="#" data-fileid=' +
												'"' +
												newotherfilenames[j] +
												'"' +
												'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
											HTML2 +=
												"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
												imgsuccess +
												"&nbsp;" +
												"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
												newotherfilenames[j] +
												"</span>" +
												link +
												"<br><br></td></tr>";
										} else {
											imgsuccess =
												'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';
											link =
												'<a class="removeOtherFile" href="#" data-fileid=' +
												'"' +
												newotherfilenames[j] +
												'"' +
												'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
											HTML2 +=
												"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
												imgsuccess +
												"&nbsp;" +
												"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
												newotherfilenames[j] +
												"</span>" +
												link +
												"<br><br></td></tr>";
										}
									}
									HTML2 += "</table>";
									document.getElementById("otherList").innerHTML = HTML2;
									var msg = "";
									//"Successfully Uploads Other Files (Uploaded Files :"+(successOtherCount.length)+", <font color='red'> Failed Files :"+rejectOtherCount.length+"</font>)";
									var message = document.getElementById("otherMessage");
									if ($("#otherMessage").text() === "") {
										message.innerHTML += msg;
									} else {
										message.innerHTML = msg;
									}
								} else {
									for (var i = 0; i < otherList.length; i++) {
										otherValidationArray.push(otherList[i].rejectedFile);
									}

									var HTML = "<table>";
									var imgsuccess;
									for (var j = 0; j < newotherfilenames.length; j++) {
										var isValid = otherValidationArray.includes(
											newotherfilenames[j]
										);
										if (isValid === true) {
											rejectOtherCount.push(newotherfilenames[j]);
											imgsuccess =
												'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
											link =
												'<a class="removeOtherFile" href="#" data-fileid=' +
												'"' +
												newotherfilenames[j] +
												'"' +
												'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
											HTML +=
												"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
												imgsuccess +
												"&nbsp;" +
												"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
												newotherfilenames[j] +
												"</span>" +
												link +
												"<br><br></td></tr>";
										} else {
											let isTrue = successOtherCount.includes(
												newotherfilenames[j]
											);
											if (isTrue === false) {
												successOtherCount.push(newotherfilenames[j]);
											}
											imgsuccess =
												'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';
											link =
												'<a class="removeOtherFile" href="#" data-fileid=' +
												'"' +
												newotherfilenames[j] +
												'"' +
												'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
											HTML +=
												"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
												imgsuccess +
												"&nbsp;" +
												"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
												newotherfilenames[j] +
												"</span>" +
												link +
												"<br><br></td></tr>";
										}
									}
									HTML += "</table>";

									document.getElementById("otherList").innerHTML = HTML;

									var msg1 = "";
									//"Successfully Uploads Other Files (Uploaded Files :"+(successOtherCount.length)+", <font color='red'> Failed Files :"+rejectOtherCount.length+"</font>)";

									var message1 = document.getElementById("otherMessage");
									if ($("#otherMessage").text() === "") {
										message1.innerHTML += msg1;
									} else {
										message1.innerHTML = msg1;
									}
								}
							})
							.catch(function (err) {
								console.log(err);
								Swal.fire({
									heightAuto: false,
									text: "Error Occured Try Again",
									type: "error",
								});
							});
					},
				});
				this.setState({
					files: data,
				});
				// var msg="Successfully Uploads Other Files";
				// var message = document.getElementById('otherMessage');
				// var number=document.getElementById("otherMessage").innerHTML;
				// if(number=="")
				// {
				//   message.innerHTML += msg;
				// }
			}.bind(this)
		);
	}
	/**
	 * @author Narendra Phadke
	 * @param  Description handle the multiple ABM files uploads
	 * @return Description return successfully files upload message
	 */

	handleChangeMultipleFileOfABM(e) {
		let self = this;
		abmnames = [];
		let abmerror = {};
		var countABM = 0;
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener(
			"change",
			function () {
				let abmfilesData = e.files;

				for (let i = 0, l = abmfilesData.length; i < l; i++) {
					let fileName = abmfilesData[i].name;
					if (chkABMExist.includes(fileName)) {
						duplicateABMFile.push(fileName);
					} else {
						abmnames.push(fileName);
					}
				}

				/**
				 * @author Sanjana Godbole
				 * @param  A FileList object that lists every selected file
				 * @return List of uploded file along with remove link
				 */

				var link;
				var filename = "";
				var abmarrayLength = abmnames.length;
				var nooutput = document.getElementById("noabmList");
				var valuenooutput = document.getElementById("noabmList").innerHTML;
				var res = new Array();
				res = valuenooutput.split(" ");
				var backarraylength;
				for (let i = 0; i < res.length; i++) {
					backarraylength = res[0];
				}
				abmarrayLength = parseInt(abmarrayLength);
				backarraylength = parseInt(backarraylength);

				var arrayabmLength1;
				arrayabmLength1 = abmarrayLength + backarraylength;
				if (arrayabmLength1 > 0) {
					var allowedFiles = [".xls", ".xlsx", ".csv"];
					var regex = new RegExp(
						"([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
					);
					for (let i = 0; i < abmnames.length; i++) {
						if (!regex.test(abmnames[i].toLowerCase())) {
							abmerror["abmerr1"] =
								"Please upload files having extensions: " +
								allowedFiles.join(", ") +
								" only";
							this.setState({ abmerror: abmerror });
							return;
						}
					}
					for (let j = 0; j < abmarrayLength; j++) {
						newabmfilenames.push(abmnames[j]);

						chkABMExist.push(abmnames[j]);
					}
					var HTML = "<table>";
					var FileExt;
					var imgsuccess;
					for (var j = 0; j < newabmfilenames.length; j++) {
						if (document.getElementById("abmError") !== null) {
							document.getElementById("abmError").innerHTML = "";
						}
						FileExt = newabmfilenames[j].split(".").pop();
						var allowedFiles = [".xls", ".xlsx", ".csv"];
						if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {
						} else {
							countABM = countABM + 1;
						}
						var isExist = oldABMFileArray.includes(newabmfilenames[j]);
						if (isExist === true) {
							imgsuccess =
								'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
							link =
								'<a class="removeFile" href="#" data-fileid=' +
								'"' +
								newabmfilenames[j] +
								'"' +
								'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
							HTML +=
								"<tr  style='height:'20px'><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
								imgsuccess +
								"&nbsp;" +
								"<span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
								newabmfilenames[j] +
								"</span>" +
								link +
								"<br><br></td></tr>";
						} else {
							imgsuccess =
								'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';
							link =
								'<a class="removeFile" href="#" data-fileid=' +
								'"' +
								newabmfilenames[j] +
								'"' +
								'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
							HTML +=
								"<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
								imgsuccess +
								"&nbsp;" +
								"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
								newabmfilenames[j] +
								"</span>" +
								link +
								"<br><br></td></tr>";
						}
					}
					HTML += "</table>";
					document.getElementById("abmList").innerHTML = HTML;
					if (countABM > 0) {
						var msg2 =
							"Please upload files having extensions: " +
							allowedFiles.join(", ") +
							" only";
						var abmErrorFile = document.getElementById("abmError");
						abmErrorFile.innerHTML += msg2;
					}
					$(document).on("click", ".removeFile", function (e) {
						var clickedFile = $(this).parent().children("a").data("fileid");
						e.preventDefault();
						var supportDocID = $(this).attr("supportDocID");
						var j = 0;
						for (j; j < newabmfilenames.length; j++) {
							var temp = j;
							if (newabmfilenames[temp] === clickedFile) {
								var isExist = oldABMFileArray.includes(newabmfilenames[j]);
								if (isExist === true) {
									var index = oldABMFileArray.indexOf(newabmfilenames[j]);
									oldABMFileArray.splice(index, 1);

									// deletedABMFileArray.push(oldABMFileArray);
								}

								filename = newabmfilenames[temp];
								newabmfilenames.splice(j, 1);
								chkABMExist.splice(j, 1);

								//  abmJson.splice(j,1);

								abmCountArray.splice(j, 1);
								$(this).parent().remove();
								abmarrayLength = newabmfilenames.length;
								if (newabmfilenames.length == 0) {
									oldABMFileArray = [];
									rejectAbmCount = [];
								}
								for (let k = 0; k < rejectAbmCount.length; k++) {
									if (rejectAbmCount[k] == clickedFile) {
										rejectAbmCount.splice(k, 1);
									}
								}
								for (let k = 0; k < successAbmCount.length; k++) {
									if (successAbmCount[k] == clickedFile) {
										successAbmCount.splice(k, 1);
									}
								}
								var rejectedCount =
									newabmfilenames.length - successAbmCount.length;
								if (rejectedCount < 0) {
									rejectedCount = 0;
								}
								var msg1 = "";
								var message1 = document.getElementById("abmMessage");

								if (oldABMFileArray.length > 0) {
									// deletedABMFileArray.push(oldABMFileArray);
								} else {
									message1.innerHTML = msg1;
								}
								//  if($("#abmMessage").text()==="")
								//   {message1.innerHTML += msg1;
								//   }else{message1.innerHTML= msg1;}

								var typeOfSuppDoc = "ABM";
								let data = {
									campID: campID,
									names: filename,
									supportDocID,
									typeOfSuppDoc: typeOfSuppDoc,
								};

								fetch("campaign/supportingDocumentDeleteFile", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								});
								abmarrayLength = parseInt(abmarrayLength);
								backarraylength = parseInt(backarraylength);
								arrayabmLength1 = abmarrayLength + backarraylength;
								if (arrayabmLength1 > 0) {
									nooutput.innerHTML = arrayabmLength1 + "ABM  Files Selected";
								} else {
									nooutput.innerHTML = "0 ABM File Selected";
									// $("#abm1").val('');
									var message = document.getElementById("abmMessage");
									message.innerHTML = "";
								}
							} // end of  if(names[temp] === clickedFile)
						} // end of for

						let abmJson1 = [...self.state.abmJson];

						let abmJson = abmJson1.filter((a) => a.fileName != clickedFile);

						self.setState({ abmJson });
					}); //$(document).on

					const { user } = this.props.auth;
					var userID = user.id;
					var data = new FormData();

					data.append("campID", campID);
					data.append("userID", userID);
					data.append("user", JSON.stringify(user)); //Sonali-3257-need these details at BE to insert into campaignLog
					data.append("campaignStatus", this.state.campaignStatus); //Sonali-3257-need these details at BE to insert into campaignLog

					for (let i = 0, l = abmfilesData.length; i < l; i++) {
						let fileName = abmfilesData[i].name;
						if (duplicateABMFile.includes(fileName)) {
						} else {
							data.append("file", abmfilesData[i]);
						}
					}
					//campaign/supportingDocument
					Swal.fire({
						heightAuto: false,
						allowOutsideClick: false,
						onOpen: () => {
							Swal.showLoading();
							fetch("campaign/editSupportingDocumentABM", {
								method: "POST",
								body: data,
							})
								.then(function (response) {
									if (response.status >= 400) {
										throw new Error("Bad response from server");
									}
									return response.json();
								})
								.then(function (response) {
									// let abmJson2 = response.filter((a)=>a.success==false)

									// if(abmJson2.length>0){
									//   deletedABMFileArray.push(abmJson2);

									// }

									if (response[0].success == true) {
										Swal.fire({
											heightAuto: false,
											text: "File Upload Complete",
											type: "success",
										});
									} else {
										Swal.fire({
											heightAuto: false,
											text: "Invalid format of the file",
											type: "error",
										});
									}

									let abmJson1 = [...self.state.abmJson];

									let abmJson = [...abmJson1, ...response];
									// let abmJson=[...response];

									// abmJson.push()
									self.setState({ abmJson });

									if (response[0].success == true) {
										var suppDocName = [];
										//  var abmFileCount=[];
										//  var suppDocName=response.seccessFileList;
										for (var i = 0; i < response.length; i++) {
											suppDocName.push(response[i].successList);

											successAbmCount.push(response[i].successList);

											abmCountArray.push(response[i]);

											// abmFileCount.push(response[i].abmCount);

											//  this.setState({abmFileCount:abmFileCount});
										}
										for (var i = 0; i < suppDocName.length; i++) {
											var count = 0;
											for (var j = 0; j < newabmfilenames.length; j++) {
												if (suppDocName[i] == newabmfilenames[j]) {
													count = count + 1;
												}
											}
										}
										if (count > 1) {
											newabmfilenames.pop();
											oldABMFileArray.pop();
										}
										var RejectedCount = 0;
										var HTML2 = "<table>";
										var imgsuccess;
										for (var j = 0; j < newabmfilenames.length; j++) {
											let singleAbm = abmJson.filter(
												(a) => a.fileName == newabmfilenames[j]
											);

											// let supportDocID=singleAbm[0].supportDocID;

											var isExist = oldABMFileArray.includes(
												newabmfilenames[j]
											);

											var abmCount = abmCountArray.filter(
												(a) => a.fileName == newabmfilenames[j]
											);
											var cnt = abmCount[0].abmCount;

											if (isExist === true) {
												/**** If uploaded file is wrong then it displays as in Red color */

												RejectedCount = RejectedCount + 1;
												imgsuccess =
													'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';

												link =
													'<a class="removeFile" href="#"   data-fileid=' +
													'"' +
													newabmfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500;float:right;">Remove</span></a>';
												HTML2 +=
													"<tr  style='height:'20px'><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newabmfilenames[j] +
													"</span><span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>ABM Count :" +
													cnt +
													"</span>" +
													link +
													"<br><br></td></tr>";
											} else {
												imgsuccess =
													'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeFile" href="#"   data-fileid=' +
													'"' +
													newabmfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500;float:right;">Remove</span></a>';

												HTML2 +=
													"<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newabmfilenames[j] +
													"</span><span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>ABM Count :" +
													cnt +
													"</span>" +
													link +
													"<br><br></td></tr>";

												// HTML2 += "<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>"+imgsuccess+"&nbsp;"+"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>"+newabmfilenames[j]+"</span> </span><span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>ABM Count :"+abmFileCount+"  </span>"+link+"<br><br></td></tr>";
											}
										}
										HTML2 += "</table>";
										document.getElementById("abmList").innerHTML = HTML2;
										var msg = "";
										// "Successfully Uploads ABM Files ( Uploaded File :"+successAbmCount.length+", <font color='red'> Failed Files :"+RejectedCount+"</font>)";

										var message = document.getElementById("abmMessage");
										// document.getElementById("abmMessage").style.color="green";
										var number =
											document.getElementById("abmMessage").innerHTML;
										message.innerHTML = "";
										message.innerHTML += msg;
										nooutput.innerHTML = arrayabmLength1;
									} else {
										// var rejectDocName=[];
										var isError = response[0].Error;
										for (var i = 0; i < response.length; i++) {
											//  rejectDocName.push(response[i].rejectedFile);
											oldABMFileArray.push(response[i].rejectedFile);
											abmCountArray.push(response[i]);
										}
										var HTML1 = "<table>";
										for (var j = 0; j < newabmfilenames.length; j++) {
											let singleAbm = abmJson.filter(
												(a) => a.fileName == newabmfilenames[j]
											);
											// let supportDocID=singleAbm[0].supportDocID;

											let isExist = oldABMFileArray.includes(
												newabmfilenames[j]
											);

											var abmCount = abmCountArray.filter(
												(a) => a.fileName == newabmfilenames[j]
											);
											var cnt = abmCount[0].abmCount;

											if (isExist === true) {
												/**** If uploaded file is wrong then it displays as in Red color */
												imgsuccess =
													'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeFile" href="#"  data-fileid=' +
													'"' +
													newabmfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500;float:right;">Remove</span></a>';
												HTML1 +=
													"<tr  style='height:'20px'><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newabmfilenames[j] +
													"</span><span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>ABM Count :" +
													cnt +
													"</span>" +
													link +
													"<br><br></td></tr>";
											} else {
												let isTrue = successAbmCount.includes(
													newabmfilenames[j]
												);
												if (isTrue === false) {
													successAbmCount.push(newabmfilenames[j]);
												}
												imgsuccess =
													'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeFile" href="#"   data-fileid=' +
													'"' +
													newabmfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500;float:right;">Remove</span></a>';
												HTML1 +=
													"<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newabmfilenames[j] +
													"</span><span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>ABM Count :" +
													cnt +
													"</span>" +
													link +
													"<br><br></td></tr>";
											}
										}
										HTML1 += "</table>";
										document.getElementById("abmList").innerHTML = HTML1;
										if (isError === "Yes") {
											var rejectedCount =
												newabmfilenames.length - successAbmCount.length;
											if (rejectedCount < 0) {
												rejectedCount = 0;
											}
											var msg1 =
												"<font color='green'>Successfully Uploads Asset Files </font>(Uploaded Files :" +
												successAbmCount.length +
												", <font color='red'> Failed Files :" +
												rejectedCount +
												"</font>)";
										} else {
											var msg1 =
												"<font color='red'>Please Upload Valid Header File </font>";
										}
										var message1 =
											document.getElementById("abmMessage").innerHTML;

										if (message1 == "") {
											document.getElementById("abmMessage").innerHTML = msg1;
										} else {
											document.getElementById("abmMessage").innerHTML = msg1;
										}
									}
								})
								.catch(function (err) {
									console.log(err);
									Swal.fire({
										heightAuto: false,
										text: "Error Occured Try Again",
										type: "error",
									});
								});
						},
					});
					this.setState({ files: data, abmerror: "" });
					nooutput.innerHTML = arrayabmLength1;
				} else {
					nooutput.innerHTML = "Please select file in excel format";
				}
			}.bind(this)
		);
	}

	/**
	 * @author Narendra Phadke
	 * @param  A supportDoID sending to BE for delete file
	 * @return List of uploded file along with remove link
	 */

	dynamicABMFile(e) {
		var suppDocName = e.target.getAttribute("name");
		let self = this;
		e.preventDefault();

		let supportDocID;
		let supportDocType;

		var resValue = new Array();
		resValue = e.target.id.split(" ");
		for (i = 0; i < resValue.length; i++) {
			supportDocType = resValue[0];
			supportDocID = resValue[1];
		}

		if (supportDocType == "Exclusion") {
			var deleteExclusionCounter = 0;
			var nooutputexeclusion = document.getElementById("noexclusionList");
			var valuenooutputexeclusion =
				document.getElementById("noexclusionList").innerHTML;
			var resexeclusion = new Array();
			resexeclusion = valuenooutputexeclusion.split(" ");
			var backecelusionarraylength;
			for (var i = 0; i < resexeclusion.length; i++) {
				backecelusionarraylength = resexeclusion[0];
			}
			deleteExclusionCounter++;
			$(this).parent().remove();
			if (deleteExclusionCounter > 0) {
				backecelusionarraylength = parseInt(backecelusionarraylength);
				backecelusionarraylength =
					backecelusionarraylength - deleteExclusionCounter;
				nooutputexeclusion.innerHTML =
					backecelusionarraylength + " Exclusion  Files Selected";
			} else {
				nooutputexeclusion.innerHTML = "0 Exclusion File Selected";
			}
			for (var k = 0; k < rejectExclusionCount.length; k++) {
				if (rejectExclusionCount[k] === suppDocName) {
					rejectExclusionCount.splice(k, 1);
				}
			}
			for (var k = 0; k < successExclusionCount.length; k++) {
				if (successExclusionCount[k] === suppDocName) {
					successExclusionCount.splice(k, 1);
				}
			}
			var rejectedCount =
				newexclusionfilenames.length - successExclusionCount.length;
			if (rejectedCount < 0) {
				rejectedCount = 0;
			}
			var msg1 = "";
			// "Successfully Uploads Exclusion Files (Uploaded Files :"+successExclusionCount.length+", <font color='red'> Failed Files :"+rejectExclusionCount.length+"</font>)";
			var message1 = document.getElementById("exclusionMessage");
			if ($("#exclusionMessage").text() === "") {
				message1.innerHTML += msg1;
			} else {
				message1.innerHTML = msg1;
			}
			for (let k = 0; k < chkExclusionExist.length; k++) {
				if (chkExclusionExist[k] === suppDocName) {
					chkExclusionExist.splice(k, 1);
				}
			}
		} else if (supportDocType == "ABM") {
			var deleteABMCounter = 0;
			var nooutput = document.getElementById("noabmList");
			var valuenooutput = document.getElementById("noabmList").innerHTML;
			var res = new Array();
			res = valuenooutput.split(" ");
			var backarraylength;
			for (i = 0; i < res.length; i++) {
				backarraylength = res[0];
			}
			deleteABMCounter++;
			$(this).parent().remove();

			if (deleteABMCounter > 0) {
				backarraylength = parseInt(backarraylength);
				backarraylength = backarraylength - deleteABMCounter;
				nooutput.innerHTML = backarraylength;
			} else {
				nooutput.innerHTML = "0 ABM File Selected";
			}
			for (var k = 0; k < successAbmCount.length; k++) {
				if (successAbmCount[k] === suppDocName) {
					successAbmCount.splice(k, 1);
				}
			}
			var rejectedCount = newabmfilenames.length - successAbmCount.length;
			if (rejectedCount < 0) {
				rejectedCount = 0;
			}
			var msg1 = "";
			// "Successfully Uploads ABM Files (Uploaded Files :"+successAbmCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
			var message1 = document.getElementById("abmMessage");
			if ($("#abmMessage").text() === "") {
				message1.innerHTML += msg1;
			} else {
				message1.innerHTML = msg1;
			}
			for (let k = 0; k < chkABMExist.length; k++) {
				if (chkABMExist[k] === suppDocName) {
					chkABMExist.splice(k, 1);
				}
			}
		} else if (supportDocType == "Suppression") {
			var deleteSuppressionCounter = 0;
			var nosuppressionoutput = document.getElementById("nosuppressionList");
			var valuenosuppressionoutput =
				document.getElementById("nosuppressionList").innerHTML;
			var suppressionres = new Array();
			suppressionres = valuenosuppressionoutput.split(" ");

			var backsuppressionarraylength;
			for (i = 0; i < suppressionres.length; i++) {
				backsuppressionarraylength = suppressionres[0];
			}
			deleteSuppressionCounter++;
			$(this).parent().remove();

			if (deleteSuppressionCounter > 0) {
				backsuppressionarraylength = parseInt(backsuppressionarraylength);
				backsuppressionarraylength =
					backsuppressionarraylength - deleteSuppressionCounter;
				nosuppressionoutput.innerHTML = backsuppressionarraylength;
			} else {
				nosuppressionoutput.innerHTML = "0 Suppression File Selected";
			}
			for (var k = 0; k < rejectSuppressionCount.length; k++) {
				if (rejectSuppressionCount[k] === suppDocName) {
					rejectSuppressionCount.splice(k, 1);
				}
			}
			for (var k = 0; k < successSuppressionCount.length; k++) {
				if (successSuppressionCount[k] === suppDocName) {
					successSuppressionCount.splice(k, 1);
				}
			}
			// var rejectedCount=newsuppressionfilenames.length-successSuppressionCount.length;
			// if(rejectedCount<0){rejectedCount=0;}
			var msg1 = "";
			// "Successfully Uploads Suppression Files (Uploaded Files :"+successSuppressionCount.length+", <font color='red'> Failed Files :"+rejectSuppressionCount.length+"</font>)";
			var message1 = document.getElementById("suppresionMessage");
			if ($("#suppresionMessage").text() === "") {
				message1.innerHTML += msg1;
			} else {
				message1.innerHTML = msg1;
			}
			for (let k = 0; k < chkSuppressionExist.length; k++) {
				if (chkSuppressionExist[k] === suppDocName) {
					chkSuppressionExist.splice(k, 1);
				}
			}
		} else if (supportDocType == "Other") {
			var deleteOtherCounter = 0;
			var nootheroutput = document.getElementById("nootherList");
			var valuenootheroutput = document.getElementById("nootherList").innerHTML;

			var otherres = new Array();
			otherres = valuenootheroutput.split(" ");

			var backotherarraylength;
			for (i = 0; i < otherres.length; i++) {
				backotherarraylength = otherres[0];
			}
			deleteOtherCounter++;
			$(this).parent().remove();

			if (deleteOtherCounter > 0) {
				backotherarraylength = parseInt(backotherarraylength);
				backotherarraylength = backotherarraylength - deleteOtherCounter;
				nootheroutput.innerHTML = backotherarraylength;
			} else {
				nosuppressionoutput.innerHTML = "0 Other File Selected";
			}
			for (var k = 0; k < rejectOtherCount.length; k++) {
				if (rejectOtherCount[k] === suppDocName) {
					rejectOtherCount.splice(k, 1);
				}
			}
			for (var k = 0; k < successOtherCount.length; k++) {
				if (successOtherCount[k] === suppDocName) {
					successOtherCount.splice(k, 1);
				}
			}
			var rejectedCount = newotherfilenames.length - successOtherCount.length;
			if (rejectedCount < 0) {
				rejectedCount = 0;
			}
			var msg1 = "";
			//"Successfully Uploads Other Files (Uploaded Files :"+successOtherCount.length+", <font color='red'> Failed Files :"+rejectOtherCount.length+"</font>)";
			var message1 = document.getElementById("otherMessage");
			if ($("#otherMessage").text() === "") {
				message1.innerHTML += msg1;
			} else {
				message1.innerHTML = msg1;
			}
			for (let k = 0; k < chkOtherExist.length; k++) {
				if (chkOtherExist[k] === suppDocName) {
					chkOtherExist.splice(k, 1);
				}
			}
		} else if (supportDocType == "Asset") {
			var deleteAssetCounter = 0;
			var noassetoutput = document.getElementById("noassetList");
			var valuenoassetoutput = document.getElementById("noassetList").innerHTML;
			var assetres = new Array();
			assetres = valuenoassetoutput.split(" ");

			var backassetarraylength;
			for (i = 0; i < assetres.length; i++) {
				backassetarraylength = assetres[0];
			}

			deleteAssetCounter++;
			$(this).parent().remove();

			if (deleteAssetCounter > 0) {
				backassetarraylength = parseInt(backassetarraylength);
				backassetarraylength = backassetarraylength - deleteAssetCounter;
				noassetoutput.innerHTML = backassetarraylength;
			} else {
				noassetoutput.innerHTML = "0 Asset File Selected";
			}
			for (var k = 0; k < rejectAssetCount.length; k++) {
				if (rejectAssetCount[k] === suppDocName) {
					rejectAssetCount.splice(k, 1);
				}
			}
			for (var k = 0; k < successAssetCount.length; k++) {
				if (successAssetCount[k] === suppDocName) {
					successAssetCount.splice(k, 1);
				}
			}
			let rejectedCount = newassetfilenames.length - successAssetCount.length;
			if (rejectedCount < 0) {
				rejectedCount = 0;
			}
			var succcount = successAssetCount.length;
			var rejeccount = rejectAssetCount.length;
			var totalcount = succcount + rejeccount;
			var msg1 =
				"<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" +
				totalcount +
				"</span>&nbsp;(Successfully Uploaded :" +
				successAssetCount.length +
				", <font color='red'> Upload Failed :" +
				rejectAssetCount.length +
				"</font>)";
			var message1 = document.getElementById("assetMessage");
			if ($("#assetMessage").text() === "") {
				message1.innerHTML += msg1;
			} else {
				message1.innerHTML = msg1;
			}
		}

		let campaignDetail = this.state.sdEditDetail;

		campaignDetail = campaignDetail.filter(
			(el) => el.supportDocID != supportDocID
		);
		var index = campaignDetail.findIndex(
			(v) => v.supportDocID === supportDocID
		);
		this.setState({ sdEditDetail: campaignDetail });

		var result = [];
		result = Array.from(
			new Set(self.state.dynamicAssetName.map((p) => p.key))
		).map((key) => {
			return {
				key: key,
				suppDocName: self.state.dynamicAssetName.find((p) => p.key === key)
					.suppDocName,
				leadPerAsset: this.state.dynamicAssetName.find((p) => p.key === key)
					.leadPerAsset,
				leadPercentage: this.state.dynamicAssetName.find((p) => p.key === key)
					.leadPercentage,
			};
		});

		for (var i = 0; i < self.state.inputType.length; i++) {
			if (suppDocName === self.state.inputType[i].suppDocName) {
				self.state.inputType.splice(i, 1);
				result.splice(i, 1);
			}
		}

		if (assetLength > 0) {
			assetLength--;
		}

		self.setState({
			inputType: self.state.inputType,
			dynamicAssetName: result,
		});
		if (self.state.inputType.length == 0) {
			self.setState({ displayTable: "none" });
		}

		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;

		const { user } = this.props.auth;
		var userID = user.id;

		/**
		 * @author Narendra Phadke
		 * @param  Delete File From Database
		 */

		let data = {
			supportDocID: supportDocID,
			supportDocType: supportDocType,
			campID: campID,
			names: suppDocName,
			typeOfSuppDoc: supportDocType,
			campaignStatus: this.state.campaignStatus,
			fromEdit: true, //Sonali-3257-needed at BE to insert into campaignLog
		}; // karan-task-3719-vapt header and query params

		//campaign / deleteSupportingDocFile
		fetch("campaign/deleteSupportingDocFile", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		});
	}
	displayPopup(e) {
		var supportDocID = e.target.id;
		var suppDocName = e.target.getAttribute("name");
		var leadStatus = e.target.getAttribute("leadStatus");
		var message = "";

		if (leadStatus === "Client Accepted") {
			message =
				"This asset lead is accepted by client. Are you sure you want remove this Asset?";
		} else if (leadStatus === "Not Accepted") {
			message =
				"This asset lead is submitted by publisher but lead is not accepted.If you remove this asset lead will be rejected. Are you sure you want remove this Asset? ";
		} else {
			message = "Are you sure you want to remove this asset?";
			// Swal.fire({
			//   title: 'Are you sure you want to remove this asset?',
			//   type: 'question',
			//   showCancelButton: true,
			//   cancelButtonColor: '#3085d6',
			//   confirmButtonColor: '#d33',
			//   allowOutsideClick: false,
			//   confirmButtonText: 'Yes, Remove it!'
			// }).then((result)=>{
			//   if (result.value) {

			//     this.deleteAssetFile(e)
			//   }
			// })
		}
		this.setState({
			supportDocID: supportDocID,
			suppDocName: suppDocName,
			assetRemovalMsg: message,
			leadStatus: leadStatus,
		});
	}

	deleteAssetFile(e) {
		e.preventDefault();
		var suppDocName = e.target.getAttribute("name");
		var leadStatus = e.target.getAttribute("leadStatus");
		var message = "";
		if (leadStatus === "Client Accepted") {
			message =
				"Leads are accepted by client, Are you sure you want Delete this Asset?";
		} else if (leadStatus === "Not Accepted") {
			message =
				"Leads are submitted, are you sure you want to delete this Asset ? if deleted then the leads will also be rejected .";
		} else {
			message = "Are you sure you want to Delete this asset?";
		}
		Swal.fire({
			heightAuto: false,
			title: message,
			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#3085d6",
			confirmButtonColor: "#d33",
			allowOutsideClick: false,
			confirmButtonText: "Yes, Remove it!",
		}).then((result) => {
			if (result.value) {
				let self = this;

				if (leadStatus === "") {
					let supportDocID;
					let supportDocType;

					var resValue = new Array();
					resValue = e.target.id.split(" ");
					for (i = 0; i < resValue.length; i++) {
						supportDocType = resValue[0];
						supportDocID = resValue[1];
					}
					var deleteAssetCounter = 0;
					var noassetoutput = document.getElementById("noassetList");
					var valuenoassetoutput =
						document.getElementById("noassetList").innerHTML;
					var assetres = new Array();
					assetres = valuenoassetoutput.split(" ");
					var backassetarraylength;
					for (i = 0; i < assetres.length; i++) {
						backassetarraylength = assetres[0];
					}
					deleteAssetCounter++;
					$(this).parent().remove();
					if (deleteAssetCounter > 0) {
						backassetarraylength = parseInt(backassetarraylength);
						backassetarraylength = backassetarraylength - deleteAssetCounter;
						noassetoutput.innerHTML =
							backassetarraylength + " Asset Files Selected";
					} else {
						noassetoutput.innerHTML = "0 Asset File Selected";
					}
					for (var k = 0; k < rejectAssetCount.length; k++) {
						if (rejectAssetCount[k] === suppDocName) {
							rejectAssetCount.splice(k, 1);
						}
					}
					for (var k = 0; k < successAssetCount.length; k++) {
						if (successAssetCount[k] === suppDocName) {
							successAssetCount.splice(k, 1);
						}
					}
					let rejectedCount =
						newassetfilenames.length - successAssetCount.length;
					if (rejectedCount < 0) {
						rejectedCount = 0;
					}
					var msg1 =
						"Successfully Uploads Asset Files (Uploaded Files :" +
						successAssetCount.length +
						", <font color='red'> Failed Files :" +
						rejectAssetCount.length +
						"</font>)";
					var message1 = document.getElementById("assetMessage");
					if ($("#assetMessage").text() === "") {
						message1.innerHTML += msg1;
					} else {
						message1.innerHTML = msg1;
					}
					let campaignDetail = this.state.sdEditDetail;
					campaignDetail = campaignDetail.filter(
						(el) => el.supportDocID != supportDocID
					);
					var index = campaignDetail.findIndex(
						(v) => v.supportDocID === supportDocID
					);
					this.setState({ sdEditDetail: campaignDetail });
					var result = [];
					result = Array.from(
						new Set(self.state.dynamicAssetName.map((p) => p.key))
					).map((key) => {
						return {
							key: key,
							suppDocName: self.state.dynamicAssetName.find(
								(p) => p.key === key
							).suppDocName,
							leadPerAsset: this.state.dynamicAssetName.find(
								(p) => p.key === key
							).leadPerAsset,
							leadPercentage: this.state.dynamicAssetName.find(
								(p) => p.key === key
							).leadPercentage,
						};
					});
					for (var i = 0; i < self.state.inputType.length; i++) {
						if (suppDocName === self.state.inputType[i].suppDocName) {
							self.state.inputType.splice(i, 1);
							result.splice(i, 1);
						}
					}
					if (assetLength > 0) {
						assetLength--;
					}
					self.setState({
						inputType: self.state.inputType,
						dynamicAssetName: result,
					});
					if (self.state.inputType.length === 0) {
						self.setState({ displayTable: "none" });
					}
				} // check Length
				else {
					var supportDocID = this.state.supportDocID;
					var supportDocType = "Asset";
				}
				var parsed = queryString.parse(this.props.location.search);
				var campID = parsed.campID;
				const { user } = this.props.auth;
				var userID = user.id;

				let data = {
					supportDocID: supportDocID,
					userID: userID,
					campID: campID,
					names: suppDocName,
					typeOfSuppDoc: supportDocType,
					campaignStatus: this.state.campaignStatus, //Sonali-3257-need these details at BE to insert into campaignLog
					user: user, //Sonali-3257-to add in campaign log table
				};
				fetch("campaign/supportingDocumentDeleteAsset", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((response) => {
						if (leadStatus !== "") {
							var inputType = [];
							fetch("campaign/supportingDocumentForEdit?campID=" + campID)
								.then((res) => res.json())
								.then((sdEditDetail) => {
									self.setState({ sdEditDetail: sdEditDetail });
									if (
										this.state.sdEditDetail !== undefined ||
										this.state.sdEditDetail !== "" ||
										this.state.sdEditDetail != null
									) {
										for (var i = 0; i < this.state.sdEditDetail.length; i++) {
											if (
												this.state.sdEditDetail[i].typeOfSuppDoc === "Asset"
											) {
												key++;
												inputType.push({
													supportDocID: this.state.sdEditDetail[i].supportDocID,
													suppDocName: this.state.sdEditDetail[i].suppDocName,
													leadPerAsset: this.state.sdEditDetail[i].leadPerAsset,
													leadPercentage:
														this.state.sdEditDetail[i].leadPercentage,
													key: key,
													multiTouch: this.state.sdEditDetail[i].multiTouch,
													status: this.state.sdEditDetail[i].status,
												});
											}
										}
										this.setState({ inputType: inputType });
										let message = "This asset is no longer used";
										Swal.fire({
											text: message,
											type: "success",
											confirmButtonText: "Ok",
											allowOutsideClick: false,
											heightAuto: false,
										});
									}
								});
						} // End of IF Check Length
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
			}
		});
	} // End of deleteAssetFile

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the for Back Button Call
	 * @return Description return Back Button
	 */
	handleBackButton(e) {
		var linkArray = this.state.dynamicInput.filter(function (a) {
			return a.status !== "Removed";
		});

		let assetLinkBackLength = linkArray.length;
		let backValidatefile;
		let backValidatelink;
		let backValidate = false;

		if (this.state.inputType.length == 0) {
			backValidatefile = false;
		} else {
			backValidatefile = true;
		}

		if (this.state.dynamicInput.length == 0) {
			backValidatelink = false;
		} else {
			for (var i = 0; i < assetLinkBackLength; i++) {
				if (
					linkArray[i].linkAssetFileName == "" ||
					linkArray[i].linkAssetFileName == null ||
					linkArray[i].linkAssetFileName == undefined
				) {
					backValidatelink = false;
				} else {
					backValidatelink = true;
				}
			}
		}

		if (backValidatefile == true || backValidatelink == true) {
			backValidate = true;
		} else {
			backValidate = false;
			Swal.fire({
				text: "Please enter either asset or asset link and click on SAVE",
				type: "error",
				confirmButtonText: "Ok",
				allowOutsideClick: false,
				heightAuto: false,
			});
		}
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		var parentCampID = parsed.parentCampID;
		var reallocationID = parsed.reallocationID;

		if (backValidate == true) {
			if (this.state.Step == true) {
				// window.location.href = '/WelcomeEdit?campID='+campID +'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&step='+1;
				window.location.href =
					"/WelcomeEdit?campID=" +
					campID +
					"&parentCampID=" +
					parentCampID +
					"&step=" +
					1;
			} else {
				// window.location.href = '/WelcomeEdit?campID='+campID +'&parentCampID='+parentCampID+'&reallocationID='+reallocationID;
				window.location.href =
					"/WelcomeEdit?campID=" + campID + "&parentCampID=" + parentCampID;
			}
		}
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the for checkbox selected dynamic
	 * @return Description return All details of delivery format
	 */
	handleChecked(e) {
		const { name, value } = e.target;
		let editDeliveryFormat = [...this.state.editDeliveryFormat];
		if (e.target.checked === true) {
			editDeliveryFormat[0] = { ...editDeliveryFormat[0], [name]: "Yes" };
		} else {
			editDeliveryFormat[0] = { ...editDeliveryFormat[0], [name]: "No" };
		}
		this.setState({ editDeliveryFormat });
	}
	/**
	 * @author Snehal More
	 * @param  Description handle the for format of customize mapping
	 * @return Description format option for customize mapping
	 */
	handleFormatChange(e) {
		e.preventDefault();
		//this.state.editDeliveryFormat[0].customizeMappingFormat="";
		const { name, value } = e.target;
		let editDeliveryFormat = [...this.state.editDeliveryFormat];
		editDeliveryFormat[0] = {
			...editDeliveryFormat[0],
			["customizeMappingFormat"]: value,
		};
		editDeliveryFormat[0] = { ...editDeliveryFormat[0] };
		this.setState({ editDeliveryFormat: editDeliveryFormat });
	}
	/**
	 * @author Snehal More
	 * @param  Description onclick for format of customize mapping
	 * @return Description onclick function for poup of customize mapping
	 */
	customizeMappingFormatHandleChange(e) {
		if (
			this.state.editDeliveryFormat[0].customizeMappingFormat === "" ||
			this.state.editDeliveryFormat[0].customizeMappingFormat === null ||
			this.state.editDeliveryFormat[0].customizeMappingFormat === undefined
		) {
			this.state.editDeliveryFormat[0].customizeMappingFormat = "Format 1";
		}
		if (this.state.agencyCustomizeMapping.length > 0) {
			let customMapping = this.state.agencyCustomizeMapping.filter(
				(a) =>
					a.format == this.state.editDeliveryFormat[0].customizeMappingFormat
			);
			this.state.agencyCustomizeMapping.length = 0;
			this.setState({ agencyCustomizeMapping: customMapping });
		}
	}
	/**
	 * @author Snehal More
	 * @param  Description onclick for format of customize mapping
	 * @return Description onclick function for cancel poup of customize mapping
	 */
	customizeMappingCancleHandleChange(e) {
		let self = this;
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		fetch("campaign/getAgencyCustomizeMapping?campID=" + campID + "")
			.then((res) => res.json())
			.then((agencyCustomizeMapping) => {
				self.setState({ agencyCustomizeMapping: agencyCustomizeMapping });
			});
	}
	/**
	 * @author Narendra Phadke
	 * @param  Description handle the multiple exclusion files uploads
	 * @return Description return successfully files upload message
	 */
	handleChangeMultipleFileOfExclusion(e) {
		execlusionnames = [];
		let exclusionerror = {};
		var countExclusion = 0;
		let self = this;
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		var parentCampID = parsed.parentCampID;
		var reallocationID = parsed.reallocationID;
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener(
			"change",
			function () {
				let execlusionfilesData = e.files;
				for (var i = 0, l = execlusionfilesData.length; i < l; i++) {
					let fileName = execlusionfilesData[i].name;
					if (chkExclusionExist.includes(fileName)) {
						duplicateABMFile.push(fileName);
					} else {
						execlusionnames.push(execlusionfilesData[i].name);
					}
				}
				/**
				 * @author Sanjana Godbole
				 * @param  A FileList object that lists every selected file
				 * @return List of uploded file along with remove link
				 */
				var link;
				var filename = "";
				var execlusionarrayLength = execlusionnames.length;
				var nooutputexeclusion = document.getElementById("noexclusionList");
				var valuenooutputexeclusion =
					document.getElementById("noexclusionList").innerHTML;

				var resexeclusion = new Array();
				resexeclusion = valuenooutputexeclusion.split(" ");

				var backecelusionarraylength;
				for (i = 0; i < resexeclusion.length; i++) {
					backecelusionarraylength = resexeclusion[0];
				}

				execlusionarrayLength = parseInt(execlusionarrayLength);
				backecelusionarraylength = parseInt(backecelusionarraylength);

				var checkNum = isNaN(backecelusionarraylength);
				if (checkNum === true) {
					backecelusionarraylength = 0;
				}
				var arrayexeclusionLength1;

				var arrayexeclusionLength1 =
					execlusionarrayLength + backecelusionarraylength;
				if (arrayexeclusionLength1 > 0) {
					var allowedFiles = [".xls", ".xlsx", ".csv"];
					var regex = new RegExp(
						"([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
					);
					for (let i = 0; i < execlusionnames.length; i++) {
						if (!regex.test(execlusionnames[i].toLowerCase())) {
							exclusionerror["exclusionerr"] =
								"Please upload files having extensions: " +
								allowedFiles.join(", ") +
								" only";
							this.setState({
								exclusionerror: exclusionerror,
							});
							return;
						}
					}

					for (var j = 0; j < execlusionarrayLength; j++) {
						newexclusionfilenames.push(execlusionnames[j]);
						chkExclusionExist.push(execlusionnames[j]);
					}

					var HTML = "<table>";
					var FileExt;
					var imgsuccess;
					for (var j = 0; j < newexclusionfilenames.length; j++) {
						if (document.getElementById("exclusionError") !== null) {
							document.getElementById("exclusionError").innerHTML = "";
						}
						FileExt = newexclusionfilenames[j].split(".").pop();
						var allowedFiles = [".xls", ".xlsx", ".csv"];
						if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {
						} else {
							countExclusion = countExclusion + 1;
						}
						var isExist = exclusionValidationArray.includes(
							newexclusionfilenames[j]
						);
						if (isExist === true) {
							imgsuccess =
								'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
							link =
								'<a class="removeExclusionFile" href="#" data-fileid=' +
								'"' +
								newexclusionfilenames[j] +
								'"' +
								'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
							HTML +=
								"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
								imgsuccess +
								"&nbsp;" +
								"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
								newexclusionfilenames[j] +
								"</span>" +
								link +
								"<br><br></td></tr>";
						} else {
							imgsuccess =
								'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';
							link =
								'<a class="removeExclusionFile" href="#" data-fileid=' +
								'"' +
								newexclusionfilenames[j] +
								'"' +
								'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
							HTML +=
								"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
								imgsuccess +
								"&nbsp;" +
								"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
								newexclusionfilenames[j] +
								"</span>" +
								link +
								"<br><br></td></tr>";
						}
					}
					HTML += "</table>";

					document.getElementById("exclusionList").innerHTML = HTML;

					if (countExclusion > 0) {
						var msg2 =
							"Please upload files having extensions: " +
							allowedFiles.join(", ") +
							" only";
						var exclusionErrorFile = document.getElementById("exclusionError");

						exclusionErrorFile.innerHTML += msg2;
					}
					$(document).on("click", ".removeExclusionFile", function (e) {
						e.preventDefault();
						var clickedFile = $(this).closest("a").data("fileid");
						var j = 0;
						for (j = 0; j < newexclusionfilenames.length; j++) {
							var temp = j;
							if (newexclusionfilenames[temp] === clickedFile) {
								var isExist = exclusionValidationArray.includes(
									newexclusionfilenames[j]
								);
								if (isExist === true) {
									var index = exclusionValidationArray.indexOf(
										newexclusionfilenames[j]
									);
									exclusionValidationArray.splice(index, 1);
								}
								filename = newexclusionfilenames[temp];
								newexclusionfilenames.splice(j, 1);
								chkExclusionExist.splice(j, 1);
								$(this).parent().remove();
								execlusionarrayLength = newexclusionfilenames.length;
								if (newexclusionfilenames.length === 0) {
									exclusionValidationArray = [];
								}
								for (var k = 0; k < rejectExclusionCount.length; k++) {
									if (rejectExclusionCount[k] === clickedFile) {
										rejectExclusionCount.splice(k, 1);
									}
								}
								for (var k = 0; k < successExclusionCount.length; k++) {
									if (successExclusionCount[k] === clickedFile) {
										successExclusionCount.splice(k, 1);
									}
								}
								var rejectedCount =
									newexclusionfilenames.length - successExclusionCount.length;
								if (rejectedCount < 0) {
									rejectedCount = 0;
								}
								var msg1 = "";
								// "Successfully Uploads Exclusion Files (Uploaded Files :"+successExclusionCount.length+", <font color='red'> Failed Files :"+rejectExclusionCount.length+"</font>)";
								var message1 = document.getElementById("exclusionMessage");
								if (exclusionValidationArray.length > 0) {
								} else {
									message1.innerHTML = msg1;
								}

								// if($("#exclusionMessage").text()==="")
								//   {message1.innerHTML += msg1;
								//   }else{message1.innerHTML= msg1;}

								var typeOfSuppDoc = "Exclusion";
								let data = {
									campID: campID,
									names: filename,
									typeOfSuppDoc: typeOfSuppDoc,
								};

								fetch("campaign/supportingDocumentDeleteFile", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								});

								execlusionarrayLength = parseInt(execlusionarrayLength);
								backecelusionarraylength = parseInt(backecelusionarraylength);
								arrayexeclusionLength1 =
									execlusionarrayLength + backecelusionarraylength;
								if (arrayexeclusionLength1 > 0) {
									nooutputexeclusion.innerHTML = arrayexeclusionLength1;
								} else {
									nooutputexeclusion.innerHTML = "0 Exclusion File Selected";
									var message = document.getElementById("exclusionMessage");
									message.innerHTML = "";
								}
							} // end of  if(names[temp] === clickedFile)
						} // end of for
					}); //$(document).on
					const { user } = this.props.auth;
					var userID = user.id;

					var data = new FormData();
					data.append("campID", campID);
					data.append("userID", userID);
					data.append("user", JSON.stringify(user)); //Sonali-3257-need these details at BE to insert into campaignLog
					data.append("parentCampID", parentCampID);
					data.append("reallocationID", reallocationID);
					data.append("campaignStatus", this.state.campaignStatus); //Sonali-3257-needed these details at BE to insert into campaign log

					for (let i = 0, l = execlusionfilesData.length; i < l; i++) {
						let fileName = execlusionfilesData[i].name;
						if (duplicateExclusionFile.includes(fileName)) {
						} else {
							data.append("file", execlusionfilesData[i]);
						}
					}

					//campaign/supportingDocument
					Swal.fire({
						heightAuto: false,
						allowOutsideClick: false,
						onOpen: () => {
							Swal.showLoading();
							fetch("campaign/editSupportingDocumentExclusion", {
								method: "POST",
								body: data,
							})
								.then((res) => res.json())
								.then((exclusionList) => {
									if (exclusionList[0].success === true) {
										//added by raunak message will be shown based on type of response
										Swal.fire({
											text: "File Upload Complete",
											type: "success",
										});
									} else {
										Swal.fire({
											text: "Invalid format of the file",
											type: "error",
										});
									}
									if (exclusionList[0].success === true) {
										var suppDocName = [];
										for (var i = 0; i < exclusionList.length; i++) {
											suppDocName.push(exclusionList[i].successList);
											successExclusionCount.push(exclusionList[i].successList);
										}
										for (var i = 0; i < suppDocName.length; i++) {
											var count = 0;
											for (var j = 0; j < newexclusionfilenames.length; j++) {
												if (suppDocName[i] == newexclusionfilenames[j]) {
													count = count + 1;
												}
											}
										}
										if (count > 1) {
											newexclusionfilenames.pop();
											exclusionValidationArray.pop();
										}
										var RejectCount = 0;
										var HTML2 = "<table>";
										var imgsuccess;

										for (var j = 0; j < newexclusionfilenames.length; j++) {
											var isExist = exclusionValidationArray.includes(
												newexclusionfilenames[j]
											);
											if (isExist === true) {
												/**** If uploaded file is wrong then it displays as in Red color */
												RejectCount = RejectCount + 1;
												imgsuccess =
													'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeExclusionFile" href="#" data-fileid=' +
													'"' +
													newexclusionfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
												HTML2 +=
													"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newexclusionfilenames[j] +
													"</span>" +
													link +
													"<br><br></td></tr>";
											} else {
												imgsuccess =
													'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeExclusionFile" href="#" data-fileid=' +
													'"' +
													newexclusionfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
												HTML2 +=
													"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newexclusionfilenames[j] +
													"</span>" +
													link +
													"<br><br></td></tr>";
											}
										}
										HTML2 += "</table>";
										document.getElementById("exclusionList").innerHTML = HTML2;
										var msg = "";
										//  "Successfully Uploads Exclusion Files (Uploaded Files :"+(successExclusionCount.length)+", <font color='red'> Failed Files :"+rejectExclusionCount.length+"</font>)";
										var message = document.getElementById("exclusionMessage");
										document.getElementById("exclusionMessage").style.color =
											"green";
										var number =
											document.getElementById("exclusionMessage").innerHTML;
										message.innerHTML = "";
										message.innerHTML += msg;
										nooutputexeclusion.innerHTML = arrayexeclusionLength1;
									} else {
										var isError = exclusionList[0].Error;
										for (var i = 0; i < exclusionList.length; i++) {
											exclusionValidationArray.push(
												exclusionList[i].rejectedFile
											);
										}

										var HTML = "<table>";
										var imgsuccess;
										for (var j = 0; j < newexclusionfilenames.length; j++) {
											var isValid = exclusionValidationArray.includes(
												newexclusionfilenames[j]
											);
											if (isValid === true) {
												rejectExclusionCount.push(newexclusionfilenames[j]);
												imgsuccess =
													'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeExclusionFile" href="#" data-fileid=' +
													'"' +
													newexclusionfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
												HTML +=
													"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newexclusionfilenames[j] +
													"</span>" +
													link +
													"<br><br></td></tr>";
											} else {
												let isTrue = successExclusionCount.includes(
													newexclusionfilenames[j]
												);
												if (isTrue === false) {
													successExclusionCount.push(newexclusionfilenames[j]);
												}
												imgsuccess =
													'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeExclusionFile" href="#" data-fileid=' +
													'"' +
													newexclusionfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
												HTML +=
													"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newexclusionfilenames[j] +
													"</span>" +
													link +
													"<br><br></td></tr>";
											}
										}
										HTML += "</table>";
										var countReject =
											newexclusionfilenames.length -
											successExclusionCount.length;
										document.getElementById("exclusionList").innerHTML = HTML;
										if (isError == "Yes") {
											var msg1 = "";
											// "Successfully Uploads Exclusion Files (Uploaded Files :"+(successExclusionCount.length)+", <font color='red'>  Failed Files :"+rejectExclusionCount.length+"</font>)";
										} else {
											var msg1 =
												"<font color='Red'>Please Upload Valid Header File </font>";
										}
										var message1 =
											document.getElementById("exclusionMessage").innerHTML;
										if (message1 == "") {
											document.getElementById("exclusionMessage").innerHTML =
												msg1;
										} else {
											document.getElementById("exclusionMessage").innerHTML =
												msg1;
										}
										nooutputexeclusion.innerHTML = arrayexeclusionLength1;
									}
								})
								.catch(function (err) {
									console.log(err);
									Swal.fire({
										text: "Error Occured Try Again",
										type: "error",
										heightAuto: false,
									});
								});
						},
					});
					this.setState({
						files: data,
						exclusionerror: "",
					});
					//  var msg="Successfully Uploads Exclusion Files";
					//var message = document.getElementById('exclusionMessage');

					//var number=document.getElementById("exclusionMessage").innerHTML;
					//if(number=="")
					//{
					//message.innerHTML += msg;
					//}
					//    nooutputexeclusion.innerHTML = arrayexeclusionLength1+" Exclusion  Files Selected";
				} else {
					nooutputexeclusion.innerHTML = " 0 Exclusion File Selected";
				}
			}.bind(this)
		);
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the multiple supperssion files uploads
	 * @return Description return successfully files upload message
	 */
	handleChangeMultipleFileOfSuppression(e) {
		let self = this;
		suppressionnames = [];
		var countSuppressison = 0;
		let suppressionerror = {};
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		var parentCampID = parsed.parentCampID;
		var reallocationID = parsed.reallocationID;
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener(
			"change",
			function () {
				let suppressionfilesData = e.files;
				for (let i = 0, l = suppressionfilesData.length; i < l; i++) {
					let fileName = suppressionfilesData[i].name;
					if (chkSuppressionExist.includes(fileName)) {
						duplicateSuppressionFile.push(fileName);
					} else {
						suppressionnames.push(fileName);
					}
				}

				/**
				 * @author Sanjana Godbole
				 * @param  A FileList object that lists every selected file
				 * @return List of uploded file along with remove link
				 */
				var link;
				var filename = "";
				var suppressionarrayLength = suppressionnames.length;
				var nosuppressionoutput = document.getElementById("nosuppressionList");
				var valuenosuppressionoutput =
					document.getElementById("nosuppressionList").innerHTML;

				var suppressionres = new Array();
				suppressionres = valuenosuppressionoutput.split(" ");

				var backsuppressionarraylength;
				for (let i = 0; i < suppressionres.length; i++) {
					backsuppressionarraylength = suppressionres[0];
				}

				suppressionarrayLength = parseInt(suppressionarrayLength);
				backsuppressionarraylength = parseInt(backsuppressionarraylength);

				var checkNum = isNaN(backsuppressionarraylength);
				if (checkNum === true) {
					backsuppressionarraylength = 0;
				}
				var suppressionarrayLength1;

				var FileExt;
				suppressionarrayLength1 =
					suppressionarrayLength + backsuppressionarraylength;
				if (suppressionarrayLength1 >= 1) {
					var allowedFiles = [".xls", ".xlsx", ".csv"];
					var suppression = document.getElementById("suppression");
					var regex = new RegExp(
						"([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
					);
					for (let i = 0; i < suppressionnames.length; i++) {
						if (!regex.test(suppressionnames[i].toLowerCase())) {
							suppressionerror["suppressionerr"] =
								"Please upload files having extensions: " +
								allowedFiles.join(", ") +
								" only";
							this.setState({
								suppressionerror: suppressionerror,
							});
							return;
						}
					}
					for (let j = 0; j < suppressionarrayLength; j++) {
						newsuppressionfilenames.push(suppressionnames[j]);
						chkSuppressionExist.push(suppressionnames[j]);
					}

					var HTML = "<table>";
					var imgsuccess;

					for (let j = 0; j < newsuppressionfilenames.length; j++) {
						if (document.getElementById("suppressionError") !== null) {
							document.getElementById("suppressionError").innerHTML = "";
						}
						FileExt = newsuppressionfilenames[j].split(".").pop();
						var allowedFiles = [".xls", ".xlsx", ".csv"];
						if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {
						} else {
							countSuppressison = countSuppressison + 1;
						}
						var isExist = suppValidationArray.includes(
							newsuppressionfilenames[j]
						);
						if (isExist === true) {
							/**** If uploaded file is wrong then it displays as in Red color */
							imgsuccess =
								'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
							link =
								'<a class="removeSuppressionFile" href="#" data-fileid=' +
								'"' +
								newsuppressionfilenames[j] +
								'"' +
								'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
							HTML +=
								"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
								imgsuccess +
								"&nbsp;" +
								"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
								newsuppressionfilenames[j] +
								"</span>" +
								link +
								"<br><br></td></tr>";
						} else {
							imgsuccess =
								'<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>';
							link =
								'<a class="removeSuppressionFile" href="#" data-fileid=' +
								'"' +
								newsuppressionfilenames[j] +
								'"' +
								'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
							HTML +=
								"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
								imgsuccess +
								"&nbsp;" +
								"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
								newsuppressionfilenames[j] +
								"</span>" +
								link +
								"<br><br></td></tr>";
						}
					}
					HTML += "</table>";

					document.getElementById("suppressionList").innerHTML = HTML;
					if (countSuppressison > 0) {
						var msg3 =
							"Please upload files having extensions: " +
							allowedFiles.join(", ") +
							" only";
						var suppressionErrorFile =
							document.getElementById("suppressionError");
						suppressionErrorFile.innerHTML += msg3;
					}
					$(document).on("click", ".removeSuppressionFile", function (e) {
						e.preventDefault();
						var clickedFile = $(this).parent().children("a").data("fileid");
						var j = 0;
						for (j; j < newsuppressionfilenames.length; j++) {
							var temp = j;
							if (newsuppressionfilenames[temp] === clickedFile) {
								var isExist = suppValidationArray.includes(
									newsuppressionfilenames[j]
								);
								if (isExist === true) {
									var index = suppValidationArray.indexOf(
										newsuppressionfilenames[j]
									);
									suppValidationArray.splice(index, 1);
								}
								filename = newsuppressionfilenames[temp];
								newsuppressionfilenames.splice(j, 1);
								chkSuppressionExist.splice(j, 1);
								$(this).parent().remove();
								suppressionarrayLength = newsuppressionfilenames.length;

								for (var k = 0; k < rejectSuppressionCount.length; k++) {
									if (rejectSuppressionCount[k] === clickedFile) {
										rejectSuppressionCount.splice(k, 1);
									}
								}
								for (var k = 0; k < successSuppressionCount.length; k++) {
									if (successSuppressionCount[k] === clickedFile) {
										successSuppressionCount.splice(k, 1);
									}
								}
								var rejectedCount =
									newsuppressionfilenames.length -
									successSuppressionCount.length;
								if (rejectedCount < 0) {
									rejectedCount = 0;
								}
								var msg1 = "";
								// "Successfully Uploads Suppression Files (Uploaded Files :"+successSuppressionCount.length+", <font color='red'> Failed Files :"+rejectSuppressionCount.length+"</font>)";
								var message1 = document.getElementById("suppresionMessage");

								if (suppValidationArray.length > 0) {
								} else {
									message1.innerHTML = msg1;
								}
								// if($("#suppresionMessage").text()==="")
								//   {message1.innerHTML += msg1;
								//   }else{message1.innerHTML= msg1;}

								var typeOfSuppDoc = "Suppression";
								let data = {
									campID: campID,
									names: filename,
									typeOfSuppDoc: typeOfSuppDoc,
								};

								fetch("campaign/supportingDocumentDeleteFile", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								});

								suppressionarrayLength = parseInt(suppressionarrayLength);
								backsuppressionarraylength = parseInt(
									backsuppressionarraylength
								);
								suppressionarrayLength1 =
									suppressionarrayLength + backsuppressionarraylength;

								if (suppressionarrayLength1 > 0) {
									nosuppressionoutput.innerHTML =
										suppressionarrayLength1 + " Suppression  Files Selected";
								} else {
									nosuppressionoutput.innerHTML = "0 Suppression File Selected";
									var message = document.getElementById("suppresionMessage");
									message.innerHTML = "";
								}
							} // end of  if(names[temp] === clickedFile)
						} // end of for
					}); //$(document).on
					const { user } = this.props.auth;
					var userID = user.id;

					var data = new FormData();
					data.append("campID", campID);
					data.append("userID", userID);
					data.append("user", JSON.stringify(user)); //Sonali-3257-need these details at BE to insert into campaignLog
					data.append("parentCampID", parentCampID);
					data.append("reallocationID", reallocationID);
					data.append("campaignStatus", this.state.campaignStatus); //Sonali-3257-added to insert into campaign log t

					for (let i = 0, l = suppressionfilesData.length; i < l; i++) {
						let fileName = suppressionfilesData[i].name;
						if (duplicateSuppressionFile.includes(fileName)) {
						} else {
							data.append("file", suppressionfilesData[i]);
						}
					}

					//campaign/supportingDocument
					Swal.fire({
						heightAuto: false,
						allowOutsideClick: false,
						onOpen: () => {
							Swal.showLoading();
							fetch("campaign/editSupportingDocumentSuppression", {
								method: "POST",

								//mode:'no-cors',
								body: data,
							})
								.then((res) => res.json())
								.then((suppList) => {
									if (suppList[0].success === true) {
										//added by raunak message will be shown based on type of response
										Swal.fire({
											text: "File Upload Complete",
											type: "success",
										});
									} else {
										Swal.fire({
											text: "Invalid format of the file",
											type: "error",
										});
									}
									if (suppList[0].success === true) {
										//  var suppDocName=suppList.seccessFileList;
										//  suppDocName=JSON.parse(suppDocName);
										var suppDocName = [];
										for (var i = 0; i < suppList.length; i++) {
											suppDocName.push(suppList[i].successList);
											successSuppressionCount.push(suppList[i].successList);
										}
										for (var i = 0; i < suppDocName.length; i++) {
											var count = 0;
											for (var j = 0; j < newsuppressionfilenames.length; j++) {
												if (suppDocName[i] == newsuppressionfilenames[j]) {
													count = count + 1;
												}
											}
										}
										if (count > 1) {
											newsuppressionfilenames.pop();
											suppValidationArray.pop();
										}
										var RejectedCount = 0;
										var HTML2 = "<table>";
										var imgsuccess;
										for (var j = 0; j < newsuppressionfilenames.length; j++) {
											var isExist = suppValidationArray.includes(
												newsuppressionfilenames[j]
											);
											if (isExist === true) {
												/**** If uploaded file is wrong then it displays as in Red color */
												RejectedCount = RejectedCount + 1;
												imgsuccess =
													'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeSuppressionFile" href="#" data-fileid=' +
													'"' +
													newsuppressionfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
												HTML2 +=
													"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newsuppressionfilenames[j] +
													"</span>" +
													link +
													"<br><br></td></tr>";
											} else {
												imgsuccess =
													'<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeSuppressionFile" href="#" data-fileid=' +
													'"' +
													newsuppressionfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
												HTML2 +=
													"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newsuppressionfilenames[j] +
													"</span>" +
													link +
													"<br><br></td></tr>";
											}
										}
										HTML2 += "</table>";
										document.getElementById("suppressionList").innerHTML =
											HTML2;

										var msg = "";
										//"Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+",<font color='red'>  Failed Files :"+rejectSuppressionCount.length+"</font>)";
										var message = document.getElementById("suppresionMessage");

										document.getElementById("suppresionMessage").style.color =
											"green";
										var number =
											document.getElementById("suppresionMessage").innerHTML;
										message.innerHTML = "";
										message.innerHTML += msg;
										nosuppressionoutput.innerHTML = suppressionarrayLength1;
									} else {
										var isError = suppList[0].Error;
										for (var i = 0; i < suppList.length; i++) {
											suppValidationArray.push(suppList[i].rejectedFile);
										}
										var HTML = "<table>";
										var imgsuccess;

										for (var j = 0; j < newsuppressionfilenames.length; j++) {
											var isValid = suppValidationArray.includes(
												newsuppressionfilenames[j]
											);
											if (isValid === true) {
												rejectSuppressionCount.push(newsuppressionfilenames[j]);
												imgsuccess =
													'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeSuppressionFile" href="#" data-fileid=' +
													'"' +
													newsuppressionfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
												HTML +=
													"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newsuppressionfilenames[j] +
													"</span>" +
													link +
													"<br><br></td></tr>";
											} else {
												let isTrue = successSuppressionCount.includes(
													newsuppressionfilenames[j]
												);
												if (isTrue === false) {
													successSuppressionCount.push(
														newsuppressionfilenames[j]
													);
												}
												imgsuccess =
													'<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>';
												link =
													'<a class="removeSuppressionFile" href="#" data-fileid=' +
													'"' +
													newsuppressionfilenames[j] +
													'"' +
													'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
												HTML +=
													"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px'>" +
													imgsuccess +
													"&nbsp;" +
													"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 250px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
													newsuppressionfilenames[j] +
													"</span>" +
													link +
													"<br><br></td></tr>";
											}
										}
										HTML += "</table>";
										document.getElementById("suppressionList").innerHTML = HTML;
										let rejectedCount =
											newsuppressionfilenames.length -
											successSuppressionCount.length;
										if (isError === "Yes") {
											var msg1 = "";
											//"Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectSuppressionCount.length+"</font>)";
										} else {
											var msg1 =
												"<font color='Red'>Please Upload Valid Header File</font>";
										}
										var message1 = document.getElementById("suppresionMessage");
										if (message1 === "") {
											message1.innerHTML += msg1;
										} else {
											message1.innerHTML = msg1;
										}
										nosuppressionoutput.innerHTML =
											suppressionarrayLength1 + "Suppresion Files Selected";
									}
								})
								.catch(function (err) {
									console.log(err);
									Swal.fire({
										text: "Error Occured Try Again",
										type: "error",
										heightAuto: false,
									});
								});
						},
					});
					this.setState({
						files: data,
						suppressionerror: "",
					});
				}
				// nosuppressionoutput.innerHTML = suppressionarrayLength1+" Suppression Files Selected";
				else {
					nosuppressionoutput.innerHTML = "0 Suppression File Selected";
				}
			}.bind(this)
		);
	}
	handleChangeAssetFileName(i, e) {
		const { name, value } = e.target;
		if (name === "leadPercentage") {
			assetflagBack = true;
			var leadAllocation = this.state.totalLeadAllocation;
			var assetLead1 = (value * leadAllocation) / 100;
			assetLead1 = Math.round(assetLead1);
			var assetWiseLead = 0;
			var name1 = "leadPerAsset";
			let dynamicAssetName = [...this.state.dynamicAssetName];
			dynamicAssetName[i] = { ...dynamicAssetName[i], [name]: value };
			dynamicAssetName[i] = { ...dynamicAssetName[i], [name1]: assetLead1 };
			this.setState({ dynamicAssetName: dynamicAssetName });
			let inputType = [...this.state.inputType];
			inputType[i] = { ...inputType[i], [name]: value };
			inputType[i] = { ...inputType[i], [name1]: assetLead1 };

			this.state.inputType = [...inputType];
			//Sanjana-Godbole-3038-Edit Flow -Validation issues and revision of functionality code revision

			this.setState({ inputType: inputType });
		} else {
			let dynamicAssetName = [...this.state.dynamicAssetName];
			dynamicAssetName[i] = { ...dynamicAssetName[i], [name]: value };
			this.setState({ dynamicAssetName: dynamicAssetName }, function () {
				for (let i = 0; i < this.state.dynamicAssetName.length; i++) {
					if (
						this.state.dynamicAssetName[i].multiTouch == "" ||
						this.state.dynamicAssetName[i].multiTouch == undefined
					) {
						this.state.dynamicAssetName[i].multiTouch = "1st Touch";
					}
				}
			});
			resultBack = Array.from(new Set(dynamicAssetName.map((p) => p.key))).map(
				(key) => {
					return {
						key: key,
						suppDocName: dynamicAssetName.find((p) => p.key === key)
							.suppDocName,
						leadPerAsset: dynamicAssetName.find((p) => p.key === key)
							.leadPerAsset,
						leadPercentage: dynamicAssetName.find((p) => p.key === key)
							.leadPercentage,
						multiTouch: dynamicAssetName.find((p) => p.key === key).multiTouch,
					};
				}
			);

			var assetName_touch1 = "";
			var assetName_touch2 = "";
			var assetName_touch3 = "";
			var assetTimestamp_touch1 = "";
			var assetTimestamp_touch2 = "";
			var assetTimestamp_touch3 = "";
			for (var i = 0; i < resultBack.length; i++) {
				if (
					resultBack[i].multiTouch == "1st Touch" ||
					resultBack[i].multiTouch == ""
				) {
					assetName_touch1 = "1st Touch";
					assetTimestamp_touch1 = "Yes";
					resultBack[i] = {
						...resultBack[i],
						["assetName_touch"]: assetName_touch1,
						["assetTimestamp_touch"]: assetTimestamp_touch1,
						["multiTouch"]: assetName_touch1,
					};
				}
				if (resultBack[i].multiTouch == "2nd Touch") {
					assetName_touch2 = "2nd Touch";
					assetTimestamp_touch2 = "Yes";
					resultBack[i] = {
						...resultBack[i],
						["assetName_touch"]: assetName_touch2,
						["assetTimestamp_touch"]: assetTimestamp_touch2,
						["multiTouch"]: assetName_touch2,
					};
				}
				if (resultBack[i].multiTouch == "3rd Touch") {
					assetName_touch3 = "3rd Touch";
					assetTimestamp_touch3 = "Yes";
					resultBack[i] = {
						...resultBack[i],
						["assetName_touch"]: assetName_touch3,
						["assetTimestamp_touch"]: assetTimestamp_touch3,
						["multiTouch"]: assetName_touch3,
					};
				}
			}
			console.log("karan - resultBack - ", resultBack);
			this.setState({ resultBack: resultBack });
		}
	}

	/**
	 * @author Supriya Gore
	 * @param  Description handle the Dynamic TextBox for lead per asset
	 * @return Description return Number of text field
	 */
	// handleChangeAssetLead(i, e) {
	//   e.preventDefault();
	//   const { name, value } = e.target;

	//     let inputType= [...this.state.inputType];
	//     inputType[i] = { ...inputType[i], [name]: value};
	//     this.state.inputType=[...inputType];
	//   //this.setState({inputType});
	//   // console.warn("New dynamicAssetName ##################### ====>" + JSON.stringify(this.state.dynamicAssetName));

	// }

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the submit form event
	 * @return Description return confirm to go next page
	 */
	submitSupportingDocument(e) {
		var dt = dateTime.create();
		var saveButtonTime = dt.format("Y-m-d H:M:S");

		e.preventDefault();

		var inputType = [...this.state.inputType];
		var dynamicInput = [...this.state.dynamicInput];
		var assetTotalLead = 0;
		for (var i = 0; i < inputType.length; i++) {
			assetTotalLead = assetTotalLead + parseInt(inputType[i].leadPerAsset);
		}
		for (var i = 0; i < dynamicInput.length; i++) {
			assetTotalLead = assetTotalLead + parseInt(dynamicInput[i].leadPerAsset);
		}
		// console.warn("Validation return==>"+this.validateForm());
		//var result=[];
		e.target.className += " was-validated";

		// var result=[];

		resultBack = Array.from(
			new Set(this.state.dynamicAssetName.map((p) => p.key))
		).map((key) => {
			return {
				key: key,
				suppDocName: this.state.dynamicAssetName.find((p) => p.key === key)
					.suppDocName,
				leadPerAsset: this.state.dynamicAssetName.find((p) => p.key === key)
					.leadPerAsset,
				leadPercentage: this.state.dynamicAssetName.find((p) => p.key === key)
					.leadPercentage,
				status: this.state.dynamicAssetName.find((p) => p.key === key).status,
				multiTouch: this.state.dynamicAssetName.find((p) => p.key === key)
					.multiTouch,
			};
		});

		this.setState({ resultBack: resultBack });

		if (this.validateForm() === false) {
			return;
		} else {
			/*@author Narendra phadke
			 *Desc Fetch campID from Url
			 * dependencies query-string
			 */

			//Sonali-3257-added user info to store in campaign log
			const { user } = this.props.auth;
			var userID = user.id;
			// var data = new FormData();

			var parsed = queryString.parse(this.props.location.search);
			var campID = parsed.campID;

			var parentCampID = parsed.parentCampID;
			var reallocationID = parsed.reallocationID;

			let multiTouchEditArray = [];
			multiTouchEditArray = this.state.resultBack;
			let multiTouchEditArrayForLink = [];
			multiTouchEditArrayForLink = this.state.dynamicInput;

			let data1 = {
				campID: campID,
				userID: userID, //Sonali-3257-need these details at BE to insert into campaignLog
				user: user, //Sonali-3257-need these details at BE to insert into campaignLog
				multiTouchEditArray: multiTouchEditArray,
				multiTouchEditArrayForLink: multiTouchEditArrayForLink,
				multiTouchEdit: this.state.multiTouchEdit,
				campaignStatus: this.state.campaignStatus, //Sonali-3257-need these details at BE to insert into campaignLog
			};

			fetch("campaign/editDeliveryFormatFormultiTouch", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				//mode:'no-cors',
				body: JSON.stringify(data1),
			});

			this.setState({
				alertDisplay: "none",
			});

			let data11 = {
				campID: campID,
				switchValue: this.state.switchValue,
				campaignStatus: this.state.campaignStatus,
				fromEdit: "true",
				isEdited: this.state.isEdited, //Sonali-3257-store in campaign log
			}; // karan-task-3719-vapt header and query params

			fetch("campaign/updateSwitchValue", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				//mode:'no-cors',
				body: JSON.stringify(data11),
			}).catch(function (err) {
				console.log(err);
			});

			let data2 = {
				campID: campID,
				////  budget:this.state.campaignBudget,
				//  currency:this.state.currency,
				//  lpTimeline:this.state.lpTimeline,
				//  suppressionLink:this.state.suppressionLink,
				assetFileName: this.state.dynamicAssetName,
				assetsFile: this.state.inputType,
				// abmFile:this.state.inputType1,
				editButtonTime: editButtonTime,
				saveButtonTime: saveButtonTime,
				//user:user,
				dynamicInput: this.state.dynamicInput,
				dynamicInputOldArray: this.state.dynamicInputOldArray,
				sdEditDetailOld: this.state.sdEditDetail,
				parentCampID: parentCampID,
				reallocationID: reallocationID,
				requiredLeadPerAsset: this.state.leadPerAssetChk,
				multiTouchEdit: this.state.multiTouchEdit,
				requiredLeadPerAssetOld: this.state.requiredLeadPerAssetOld,
				assetNoChangeArray: this.state.assetNoChangeArray,
				campaignStatus: this.state.campaignStatus, //Sonali-3257-need these details at BE to insert into campaignLog
				multiTouchEdited: this.state.multiTouchEdited, //Sonali-3257-get multiTouch edited or not at BE
				assetvizLeadsEdited: this.state.assetvizLeadsEdited,
			}; // karan-task-3719-vapt header and query params
			fetch("campaign/editSupportingDocument", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				//mode:'no-cors',
				body: JSON.stringify(data2),
			})
				.then(function (response) {
					if (response.status >= 400) {
						throw new Error("Bad response from server");
					}
					return response.json();
				})
				.then(function (response) {
					//fetch insertId because we AutoIncrement ID so first fetch insertId is a our autocreated ID
					var campID = response.campID;

					if (response.success == true) {
						var a = response.message;
						var newSuccess2 = document.getElementById("newSuccessSupp");
						newSuccess2.innerHTML = a;
					} else {
					}
					// window.location.href = '/editDeliveryFormat?campID='+campID+'';
				})
				.catch(function (err) {
					console.log(err);
				});
		}

		/*@author Narendra phadke
		 *Desc Fetch campID from Url
		 * dependencies query-string
		 */
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		var reallocationID = this.state.detailsOfCampaign[0].reallocationID; //Sanjana-3100-re-allocation ID Undefined in campaign Edit Email
		const { user } = this.props.auth;
		let data = {
			editDeliveryFormat: this.state.editDeliveryFormat[0],
			editDeliveryFormatNoChange: this.state.editDeliveryFormatNoChange[0],
			user: user,
			parentCampID: parentCampID,
			reallocationID: this.state.detailsOfCampaign[0].reallocationID, //Sanjana-3100-re-allocation ID Undefined in campaign Edit Email
			editButtonTime: editButtonTime,
			campaignStatus: this.state.campaignStatus, //Sonali-3257-store in campaign log
		};
		fetch("/campaign/editDeliveryFormat", {
			method: "POST",
			headers: { "Content-Type": "application/json" },

			body: JSON.stringify(data),
		})
			.then(function (response) {
				if (response.status >= 400) {
					throw new Error("Bad response from server");
				}
				return response.json();
			})
			.then(function (response) {
				//var campID=response.body.campID;
				var campID = response.campID;
				if (response.success == true) {
					// var a=(response.message);
					// var newSuccess3=document.getElementById("newSuccessDelivery");
					// newSuccess3.innerHTML=a;
					Swal.fire({
						text: response.message,
						heightAuto: false,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							//  window.location.href="welcomeEdit?campID="+campID+"&parentCampID="+parentCampID+"&reallocationID="+reallocationID+"&step=1"
							window.location.href =
								"welcomeEdit?campID=" +
								campID +
								"&parentCampID=" +
								parentCampID +
								"&step=1";
						},
					});
				}
				//send campID to new page
				// window.location.href = '/editFinishCampaign?campID=' + campID + '';
				if (data === "success") {
					this.refs.msg.show("Some text or component", {
						time: 2000,
						type: "success",
						icon: <img src="path/to/some/img/32x32.png" />,
					});
				}
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	//Sanjana-Godbole-3038-Edit Flow -Validation issues and revision of functionality code revision
	//Sanjana-Godbole-3082-Edit Flow -asset wise lead percentage issue solved
	validateForm() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
		let leaderrors = {};
		let errors = {};
		let linknameerror = {};
		let asseterrors = {};
		let backerror = {};
		let backabmerror = {};
		let formIsValid = true;
		let spanDisplay = "none";
		let abmvalidate = false;
		let assetBackLength = this.state.sdEditDetail.length;
		let abmBackLength = this.state.abmBackArray.length;
		var abmLength = chkABMExist.length;
		let assetUpload = true; // used to check asset files and asset link is present or not
		var allabms = true; // used to check whether ABM file is present or not
		let assestinput = true; // used for asset file and asset link validation
		let assestLinkinput = true; // used for asset file and asset link validation
		let assetvalidate = true; //used for validation of lead per asset
		let multiTouchChkFlag = true; // used for validation of multitouch checkbox

		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		var linkArray = this.state.dynamicInput.filter(function (a) {
			return a.status !== "Removed";
		});

		var leadAssetArray = resultBack.filter(function (a) {
			return a.status !== "Removed";
		});

		let assetLinkBackLength = linkArray.length;
		let inputTypeLength = resultBack.length;

		/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		if (linkArray.length === 0 && leadAssetArray.length === 0) {
			backerror["assetUploadError"] = (
				<li>
					<b>Please upload either asset file or asset link</b>
				</li>
			);
			assetUpload = false;
			this.setState({ backerror: backerror });
		}

		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Start of Validation for campaignType === 'ABM', where ABM upload is mandatory -Sunita

		if (this.state.campaignType == "ABM") {
			let abmJson = [...this.state.abmJson];
			if (abmJson.length != 0 || abmLength != 0) {
				let checkData = abmJson.filter((a) => a.success == false);
				if (checkData.length > 0) {
					errors["properabmerrors"] = (
						<li>
							<b>Please upload valid ABM file</b>
						</li>
					);
					asseterrors["assetFiles"] = "";
					this.setState({
						alertBorder: "1px solid red",
						errors: errors,
						asseterrors: asseterrors,
					});
					return false;
				} else {
					//ABM file is valid and Asset File/Asset Link is present
					if (linkArray.length != 0 || leadAssetArray.length != 0) {
						errors["asseterrors"] = "";
						errors["abmerrors"] = "";
						this.setState({ alertBorder: "0px solid white" });

						allabms = true;
						assetvalidate = true;
						abmvalidate = true;
					} else {
						//ABM file is valid and Asset File/Asset Link is not present
						errors["asseterrors"] = (
							<li>
								<b>Please upload either asset link or asset file</b>
							</li>
						);
						errors["abmerrors"] = "";
						this.setState({
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});

						allabms = true;
						abmvalidate = true;
						assetvalidate = false;
						return assetvalidate;
					}
				}
			} // end of  if(abmJson.length!=0 || abmLength!=0)
			else {
				//ABM is not  present and Asset File/Asset Link also not present
				if (linkArray.length === 0 && leadAssetArray.length === 0) {
					errors["asseterrors"] = (
						<li>
							<b>Please upload either asset link or asset file</b>
						</li>
					);
					errors["abmerrors"] = (
						<li>
							<b>Please upload ABM file</b>{" "}
						</li>
					);
					this.setState({
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					allabms = false;
					assetvalidate = false;
					abmvalidate = false;
					allabmLength = false;
					allassetLength = false;
				} else {
					//ABM is not  present and Asset File/Asset Link  present
					errors["asseterrors"] = "";
					errors["abmerrors"] = (
						<li>
							<b>Please upload ABM file</b>
						</li>
					);
					backerror["assetFiles1"] = "";
					this.setState({
						errors: errors,
						backerror: backerror,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					assetvalidate = true;
					allabms = false;
					abmvalidate = false;
					return abmvalidate;
				}
			}
		}

		// End of Validation for campaignType === 'ABM', where ABM upload is mandatory -Sunita

		let assetlinkFlagBack = false;
		let errorFileValidate = true;
		let AssetLead = false;
		let LinkLead = false;
		let assetmultiTouch = false;
		let suppLinkValidateBack = true;

		for (var i = 0; i < this.state.suppressionLink.length; i++) {
			var suppLink = this.state.suppressionLink[i].suppressionLink;
			var suppFileName = this.state.suppressionLink[i].suppressionFileName;
			if (suppLink == undefined || suppLink == "" || suppLink == null) {
				suppLinkValidateBack = false;
				errors["suppLinkError"] = (
					<li>
						<b>Please enter suppression link </b>
					</li>
				);
				this.setState({ errors: errors });
			} else {
				if (
					suppFileName == undefined ||
					suppFileName == "" ||
					suppFileName == null
				) {
					suppLinkValidateBack = false;

					errors["suppFileNameError"] = (
						<li>
							<b>Please enter suppression file name</b>
						</li>
					);
					this.setState({ errors: errors });
				} else {
					suppLinkValidateBack = true;
				}
			}
		}
		// Need to ask about above  validation
		///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Need to ask about following validation//
		if (assetValidationArray.length == 0) {
		} else {
			errorFileValidate = false;
			errors["AssetWrongFile"] = (
				<li>
					<b>
						There are few asset upload failed please remove failed asset or
						upload the missing asset
					</b>
				</li>
			);
		}
		// Need to ask about above  validation

		//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		//Start of Asset File name Validation
		//let inputTypeLength = this.state.inputType.length; length of Assset Files uploaded

		for (let i = 0; i < leadAssetArray.length; i++) {
			if (
				leadAssetArray[i].suppDocName === undefined ||
				leadAssetArray[i].suppDocName === "" ||
				leadAssetArray[i].suppDocName === null
			) {
				assestinput = false;
				errors["suppDocName"] = (
					<li>
						<b>Please enter asset name for uploaded asset file</b>
					</li>
				);
				this.setState({
					errors: errors,
				});
				break;
			} else {
				assestinput = true;
			}
		}

		//End of Asset File name Validation

		// start validation for asset link and asset link Name

		for (var i = 0; i < linkArray.length; i++) {
			if (
				linkArray[i].assetLink != "" &&
				linkArray[i].linkAssetFileName != ""
			) {
				assestLinkinput = true;
			} else {
				if (
					linkArray[i].assetLink == "" &&
					linkArray[i].linkAssetFileName == ""
				) {
					assestLinkinput = false;
					formIsValid = false;
					linknameerror["linkname"] = (
						<li>
							<b>Please enter asset link and asset link name</b>
						</li>
					);
					break;
				}
				if (linkArray[i].assetLink == "") {
					assestLinkinput = false;
					formIsValid = false;
					linknameerror["linkname"] = (
						<li>
							<b>Please enter asset link for uploaded asset name</b>
						</li>
					);
					break;
				}
				if (linkArray[i].linkAssetFileName == "") {
					assestLinkinput = false;
					formIsValid = false;
					linknameerror["linkname"] = (
						<li>
							<b>Please enter asset link name for uploaded asset link</b>
						</li>
					);
					break;
				}
			}
		}
		//end validation for asset link and asset link name

		//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		//start validation for lead per asset

		if (this.state.leadPerAssetChk == "Yes") {
			let tempArray = [...linkArray, ...leadAssetArray];
			let leadPercentageTotal = 0; //to check the total of leadPercentage (asset+ assetLink)
			for (let i = 0; i < tempArray.length; i++) {
				if (
					tempArray[i].leadPercentage === undefined ||
					tempArray[i].leadPercentage === "" ||
					tempArray[i].leadPercentage === null
				) {
					leaderrors["leadPercentage"] = (
						<li>
							<b>Please enter lead percentage for individual uploaded asset </b>
						</li>
					);
					assetvalidate = false;
					break;
				} else {
					if (
						isNaN(tempArray[i].leadPercentage) == false &&
						parseFloat(tempArray[i].leadPercentage) ==
							parseInt(tempArray[i].leadPercentage)
					) {
						leadPercentageTotal += parseInt(tempArray[i].leadPercentage);
						assetvalidate = true;
					} else {
						leaderrors["AssetValidate"] = (
							<li>
								<b>
									Please enter only numerical (0-9) values for lead percentage
								</b>
							</li>
						);
						assetvalidate = false;
						break;
					}
				}
			}
			if (assetvalidate == true) {
				if (leadPercentageTotal < 100 || leadPercentageTotal > 100) {
					leaderrors["assetCount"] = (
						<li>
							<b>
								Leads percentage per asset can't be greater or less than hundred
								percentage
							</b>
						</li>
					);
					assetvalidate = false;
				} else {
					assetvalidate = true;
				}
			}
		}
		//end validation for asset link and asset link name
		//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		//start validation or multitouch
		if (this.state.multiTouchEdit === "Yes") {
			let commonTouchArray = [];
			let multiTouch1stCount = 0;
			let multiTouch2ndCount = 0;
			let multiTouch3rdCount = 0;
			//following the top-down approach
			for (let i = 0; i < leadAssetArray.length; i++) {
				commonTouchArray.push(leadAssetArray[i].multiTouch);
				if (leadAssetArray[i].multiTouch == "1st Touch") {
					multiTouch1stCount += 1;
				}
				if (leadAssetArray[i].multiTouch == "2nd Touch") {
					multiTouch2ndCount += 1;
				}
				if (leadAssetArray[i].multiTouch == "3rd Touch") {
					multiTouch3rdCount += 1;
				}
			}

			for (let i = 0; i < linkArray.length; i++) {
				commonTouchArray.push(linkArray[i].multiTouch);
				if (linkArray[i].multiTouch == "1st Touch") {
					multiTouch1stCount += 1;
				}
				if (linkArray[i].multiTouch == "2nd Touch") {
					multiTouch2ndCount += 1;
				}
				if (linkArray[i].multiTouch == "3rd Touch") {
					multiTouch3rdCount += 1;
				}
			}

			if (commonTouchArray.length == 1) {
				backerror["OneAsset"] = (
					<li>
						<b>Please add more assets</b>
					</li>
				);
				multiTouchChkFlag = false;
			}

			if (commonTouchArray.length == 2) {
				if (
					commonTouchArray.includes("1st Touch") &&
					commonTouchArray.includes("2nd Touch")
				) {
					multiTouchChkFlag = true; // true because there are only 2 assets are both the touch are present
				} else {
					//if condn for all same touch
					backerror["OneAsset"] = (
						<li>
							<b>
								Please change multi-touch options as all assets can't be of same
								touch
							</b>
						</li>
					);
					multiTouchChkFlag = false;
					//false when all the touch are same or 3rd touch is present
					if (commonTouchArray.includes("3rd Touch")) {
						//if condition for 3rd touch
						backerror["OneAsset"] = (
							<li>
								<b>Assets are not enough for 3rd touch</b>
							</li>
						);
						multiTouchChkFlag = false;
					}
				}
			}

			if (commonTouchArray.length >= 3) {
				if (
					multiTouch3rdCount === commonTouchArray.length ||
					multiTouch1stCount === commonTouchArray.length ||
					multiTouch2ndCount === commonTouchArray.length
				) {
					backerror["OneAsset"] = (
						<li>
							<b>
								Please change multi-touch options as all assets can't be of same
								touch{" "}
							</b>
						</li>
					);
					multiTouchChkFlag = false;
				} else {
					if (
						!commonTouchArray.includes("1st Touch") ||
						!commonTouchArray.includes("2nd Touch")
					) {
						//checking for 1st and 2nd touch
						backerror["OneAsset"] = (
							<li>
								<b>Either of 1st touch or 2nd touch not present</b>
							</li>
						);
						multiTouchChkFlag = false;
					}
				}
			}
		}
		/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		//final condition for validating the form flag

		if (
			assetUpload === true &&
			allabms === true &&
			assestinput === true &&
			assestLinkinput === true &&
			assetvalidate === true &&
			multiTouchChkFlag === true
		) {
			formIsValid = true;
			this.setState({
				backerror: "",
				backabmerror: "",
				linknameerror: "",
				errors: "",
				leaderrors: "",
				alertBorder: "0px",
				alertWidth: "0 px",
				alertAlign: "center",
				alertDisplay: "none",
				spanSpaceDisplay: spanDisplay,
			});
		} else {
			formIsValid = false;
			this.setState({
				backerror: backerror,
				backabmerror: backabmerror,
				linknameerror: linknameerror,
				leaderrors: leaderrors,
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
		}
		document.body.scrollTop = (0, 0);
		document.documentElement.scrollTop = (0, 0);
		return formIsValid;
	}
	//end of validateForm

	componentDidMount() {
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;

		fetch("campaign/CampaignDetails?campID=" + campID + "")
			.then((res) => res.json())
			.then((detailsOfCampaign) => {
				this.setState({
					detailsOfCampaign: detailsOfCampaign,
					totalLeadAllocation: detailsOfCampaign[0].leadAllocation,
					requiredLeadPerAsset: detailsOfCampaign[0].requiredLeadPerAsset,
					requiredLeadPerAssetOld: detailsOfCampaign[0].requiredLeadPerAsset,
					campaignType: detailsOfCampaign[0].ABM,
				});
				let editDeliveryFormat = [...this.state.editDeliveryFormat];
				// var leadPerAsset=document.getElementById("leadPerAsset");
				var leadlabel = document.getElementById("leadlabel");
				var checkedValue = false;
				var leadPerAssetChk = "";

				if (this.state.requiredLeadPerAsset === "Yes") {
					//leadPerAsset.style.display="inline";
					leadlabel.style.display = "inline";
					leadPerAssetChk = "Yes";
					checkedValue = true;
				} else {
					// leadPerAsset.style.display="none";
					// leadlabel.style.display="none";
					leadPerAssetChk = "No";
					checkedValue = false;
				}

				this.setState({
					leadPerAssetChk: leadPerAssetChk,
					checkedValue: checkedValue,
				});

				if (
					detailsOfCampaign[0].jobLevel === "" &&
					detailsOfCampaign[0].customJobLevel === ""
				) {
					this.setState({
						detailsOfCampaign: detailsOfCampaign,
						jobLevelDelivery: "No",
					});
				} else {
					this.setState({
						jobLevelDelivery: "Yes",
					});
				}

				if (
					detailsOfCampaign[0].jobFunction == "" &&
					detailsOfCampaign[0].customJobFunction === ""
				) {
					this.setState({
						jobFunctionDelivery: "No",
					});
				} else {
					this.setState({
						jobFunctionDelivery: "Yes",
					});
				}

				if (
					detailsOfCampaign[0].jobTitleValidation == "No" &&
					detailsOfCampaign[0].jobTitleValidation === "No"
				) {
					this.setState({
						jobTitleValidation: "No",
					});
				} else {
					this.setState({
						jobTitleValidation: "Yes",
					});
				}
				if (
					detailsOfCampaign[0].companyRevenue == "" &&
					detailsOfCampaign[0].customCompRevenue === ""
				) {
					this.setState({
						companyRevenue: "No",
					});
				} else {
					this.setState({
						companyRevenue: "Yes",
					});
				}

				if (
					(detailsOfCampaign[0].employeeSize === "" ||
						detailsOfCampaign[0].employeeSize === undefined) &&
					(detailsOfCampaign[0].customEmpSize === "" ||
						detailsOfCampaign[0].customEmpSize === undefined)
				) {
					editDeliveryFormat[0] = {
						...editDeliveryFormat[0],
						["companyEmployeeSize"]: "No",
					};
					this.setState({
						editDeliveryFormat: editDeliveryFormat,
						companyEmployeeSize: "No",
					});
				} else {
					editDeliveryFormat[0] = {
						...editDeliveryFormat[0],
						["companyEmployeeSize"]: "Yes",
					};
					this.setState({
						editDeliveryFormat: editDeliveryFormat,
						companyEmployeeSize: "Yes",
					});
				}

				if (
					detailsOfCampaign[0].industry === "" &&
					detailsOfCampaign[0].customIndustry === ""
				) {
					editDeliveryFormat[0] = {
						...editDeliveryFormat[0],
						["industry"]: "No",
					};

					// //detailsOfCampaign[0].industry="No";
					this.setState({
						editDeliveryFormat: editDeliveryFormat,
						industry: "No",
					});
				} else {
					editDeliveryFormat[0] = {
						...editDeliveryFormat[0],
						["industry"]: "Yes",
					};
					this.setState({
						editDeliveryFormat: editDeliveryFormat,
						industry: "Yes",
					});
				}
			});
	}

	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			let self = this;
			//  var campID = window.location.href.split("=")[window.location.href.split("?").length - 1];

			var parsed = queryString.parse(this.props.location.search);
			var campID = parsed.campID;
			var abmBackarray;
			this.state.Step = parsed.step;
			const { isAuthenticated, user } = this.props.auth;
			var userID = user.id;

			fetch("/diQARole/getSwitchValueForEdit?campID=" + campID + "")
				.then((res) => res.json())
				.then((switchDetails) => {
					if (switchDetails.length > 0) {
						if (switchDetails[0].campaignLevelSwitch == "") {
							switchDetails[0].campaignLevelSwitch = "OF";
						}
						this.setState({
							switchValue: switchDetails[0].campaignLevelSwitch,
						});
					}
				});

			fetch("campaign/supportingDocumentForEdit?campID=" + campID + "")
				.then((res) => res.json())
				.then((sdEditDetail) => {
					// var abmBackArray = sdEditDetail.filter((a)=>a.typeOfSuppDoc=='ABM');

					self.setState({
						sdEditDetail: sdEditDetail,
						assetNoChangeArray: sdEditDetail,
						campaignStatus: sdEditDetail[0].campaignStatus,
					}); //Sonali-3257-addedd campaignStatus
					//  self.setState({sdEditDetail: sdEditDetail,assetNoChangeArray:sdEditDetail,abmBackArray:abmBackArray})

					if (
						this.state.sdEditDetail != undefined ||
						this.state.sdEditDetail != "" ||
						this.state.sdEditDetail != null
					) {
						for (var i = 0; i < this.state.sdEditDetail.length; i++) {
							if (this.state.sdEditDetail[i].typeOfSuppDoc == "Asset") {
								key++;
								this.state.inputType.push({
									supportDocID: this.state.sdEditDetail[i].supportDocID,
									suppDocName: this.state.sdEditDetail[i].suppDocName,
									leadPerAsset: this.state.sdEditDetail[i].leadPerAsset,
									leadPercentage: this.state.sdEditDetail[i].leadPercentage,
									key: key,
									status: this.state.sdEditDetail[i].status,
									sdEditDetailOld: sdEditDetail,
									multiTouch: this.state.sdEditDetail[i].multiTouch,
								});
							} else if (this.state.sdEditDetail[i].typeOfSuppDoc == "ABM") {
								// key1++;
								chkABMExist.push(this.state.sdEditDetail[i].suppDocName);
							} else if (
								this.state.sdEditDetail[i].typeOfSuppDoc == "Exclusion"
							) {
								chkExclusionExist.push(this.state.sdEditDetail[i].suppDocName);
							} else if (
								this.state.sdEditDetail[i].typeOfSuppDoc == "Suppression"
							) {
								chkSuppressionExist.push(
									this.state.sdEditDetail[i].suppDocName
								);
							} else if (this.state.sdEditDetail[i].typeOfSuppDoc == "Other") {
								chkOtherExist.push(this.state.sdEditDetail[i].suppDocName);
							}
						}
						this.setState({ inputType: this.state.inputType });

						if (this.state.inputType.length > 0) {
							assetflagBack = true;
							allassetLength = true;
							allabmLength = true;

							this.setState({ displayTable: "block" });
						}

						//  for(let i=0;i<this.state.abmBackArray.length;i++){

						//       // if(this.state.sdEditDetail[i].typeOfSuppDoc=='ABM')
						//       // {
						//         key1++;
						//        this.state.inputType1.push({suppDocName:this.state.sdEditDetail[i].suppDocName,key1:key1});

						//       // }

						//     }this.setState({inputType1:this.state.inputType1});
						//     if(this.state.inputType1.length>0){
						//       allabmLength=true;
						//       this.setState({displayTable:'block'});
						//     }
					}
				});
			// Get Asset Link
			fetch("campaign/getAssetLink?campID=" + campID)
				.then((res) => res.json())
				.then((dynamicInput) => {
					self.setState({
						dynamicInput: dynamicInput,
						dynamicInputOldArray: dynamicInput,
					});
					if (
						dynamicInput != undefined ||
						dynamicInput != "" ||
						dynamicInput != null
					) {
						var linkArray = dynamicInput.filter(function (a) {
							return a.status !== "Removed";
						});
						if (linkArray.length > 0) {
							linkflagBack = true;
							allassetLength = true;
						}
					}
					var count = this.state.DynamicInput.length;
					count = count + 1;
				})
				.catch(function (err) {
					console.log(err);
				});
			// Get Suppression Link
			//  fetch("campaign/getSuppressionLink?campID="+campID)
			//     .then(res => res.json())
			//     .then(suppressionLink =>{
			//     self.setState({suppressionLink: suppressionLink})
			// }).catch(function (err) {console.log(err)});
			// this route for campaign is Allocated or not to Publisher.It is used for Validation
			fetch("campaign/checkAllocation?campID=" + campID)
				.then((res) => res.json())
				.then((checkAllocation) => {
					self.setState({ checkAllocation: checkAllocation });
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("campaign/supportingDocumentForEditABM?campID=" + campID + "")
				.then((res) => res.json())
				.then((sdEditDetailABM) => {
					self.setState({ sdEditDetailABM: sdEditDetailABM });

					for (var i = 0; i < sdEditDetailABM.length; i++) {
						successAbmCount.push(sdEditDetailABM[i].suppDocName);
					}
					// if(sdEditDetailABM.length>0){
					//    this.setState({displayTableabm:'block'});
					// }
				});

			fetch("campaign/supportingDocumentForEditAsset?campID=" + campID + "")
				.then((res) => res.json())
				.then((sdEditDetailAsset) => {
					self.setState({ sdEditDetailAsset: sdEditDetailAsset });
					for (var i = 0; i < sdEditDetailAsset.length; i++) {
						successAssetCount.push(unescape(sdEditDetailAsset[i].suppDocName));
					}
				});

			fetch("campaign/supportingDocumentForEditOther?campID=" + campID + "")
				.then((res) => res.json())
				.then((sdEditDetailOther) => {
					self.setState({ sdEditDetailOther: sdEditDetailOther });
					for (var i = 0; i < sdEditDetailOther.length; i++) {
						successOtherCount.push(sdEditDetailOther[i].suppDocName);
					}
					// if(sdEditDetailOther.length>0){
					//   this.setState({displayTableother:'block'});
					// }
				});

			fetch("campaign/getAgencyCustomizeMapping?campID=" + campID + "")
				.then((res) => res.json())
				.then((agencyCustomizeMapping) => {
					self.setState({ agencyCustomizeMapping: agencyCustomizeMapping });
				});
			if (this.state.agencyCustomizeMapping.length > 0) {
				let customMapping = this.state.agencyCustomizeMapping.filter(
					(a) => a.format == this.state.customizeMappingFormat
				);
				this.setState({ agencyCustomizeMapping: customMapping });
			}
			fetch(
				"campaign/supportingDocumentForEditSuppression?campID=" + campID + ""
			)
				.then((res) => res.json())
				.then((sdEditDetailSuppression) => {
					self.setState({ sdEditDetailSuppression: sdEditDetailSuppression });
					for (var i = 0; i < sdEditDetailSuppression.length; i++) {
						successSuppressionCount.push(
							unescape(sdEditDetailSuppression[i].suppDocName)
						);
					}
					// if(sdEditDetailSuppression.length>0){
					//   this.setState({displayTablesuppression:'block'});
					// }
				});

			fetch("campaign/supportingDocumentForEditExclusion?campID=" + campID + "")
				.then((res) => res.json())
				.then((sdEditDetailExclusion) => {
					self.setState({ sdEditDetailExclusion: sdEditDetailExclusion });
					for (var i = 0; i < sdEditDetailExclusion.length; i++) {
						successExclusionCount.push(
							unescape(sdEditDetailExclusion[i].suppDocName)
						);
					}
					// if(sdEditDetailExclusion.length>0){
					//   this.setState({displayTableexclusion:'block'});
					// }
				});

			fetch("campaign/sdBudgetForEdit?campID=" + campID + "")
				.then((res) => res.json())
				.then((sdBudget) => {
					self.setState({
						sdBudget: sdBudget,
					});
					if (this.state.sdBudget && this.state.sdBudget.length) {
						if (
							this.state.sdBudget[0].multiTouch === "Yes" ||
							this.state.sdBudget.multiTouch === "Yes"
						) {
							this.setState({
								multiTouchEdit: "Yes",
								checkedValueMultiTouch: "Yes",
							});
						}
					}
				});
			$(document).on("click", ".removeFile", function (e) {
				var clickedFile = $(this).parent().children("a").data("fileid");

				// deletedABMFileArray.push(clickedFile);
			});

			// var campID = window.location.href.split("=")[window.location.href.split("?").length - 1];

			fetch("campaign/deliveryFormatEdit?campID=" + campID + "")
				.then((res) => res.json())
				.then((editDeliveryFormat) => {
					if (editDeliveryFormat[0].customizeMapping == "Yes") {
						editDeliveryFormat[0].customizeMapping = "Yes";
					} else {
						editDeliveryFormat[0].customizeMapping = "No";
					}
					this.setState({
						editDeliveryFormat: editDeliveryFormat,
						editDeliveryFormatNoChange: editDeliveryFormat,
					});
					if (
						this.state.editDeliveryFormat[0].customizeMappingFormat === "" ||
						this.state.editDeliveryFormat[0].customizeMappingFormat === null ||
						this.state.editDeliveryFormat[0].customizeMappingFormat ===
							undefined
					) {
						this.state.editDeliveryFormat[0].customizeMappingFormat =
							"Format 1";
					}
				});
		}
	}
	render() {
		const asterisk = {
			color: "red",
		};

		// this.state.dynamicInput.forEach((itm, i) => {
		//   this.state.assetLinkArray.push(Object.assign({}, itm));
		// });

		this.state.dynamicAssetName = this.state.inputType.map((e) =>
			Object.assign(
				{},
				e,
				this.state.dynamicAssetName.find((e1) => e1.key === e.key) || {}
			)
		);
		/**
		 * @author Narendra Phadke
		 * @param  Description Fetch campID from Url
		 * @return Dependencies query-string
		 */
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		var parentCampID = parsed.parentCampID;
		var reallocationID = parsed.reallocationID;

		return (
			/*@author Sanobar Golandaj
    supporting document fields */
			<div>
				<Navigation />
				<div
					class="row"
					style={{
						paddingTop: "90px",
						paddingBottom: "6px",
						backgroundColor: "white",
						border: "1px solid",
						borderColor: "#bfc7cc",
						width: "110%",
						position: "fixed",
						zIndex: "1",
					}}>
					<div class=" col-lg-2" style={{ paddingLeft: "40px" }}>
						<a href="#">
							<FaArrowAltCircleLeft
								size={32}
								style={{ float: "left", color: "#164495" }}
								title="Back"
								onClick={this.handleBackButton}
							/>
						</a>
					</div>
					<div class="col-lg-6">
						<center>
							<label
								class="labelfont"
								style={{ color: "#164495", fontSize: "18px" }}>
								STEP 2 : Supporting Documents (Campaign ID:{parentCampID})
							</label>
							{/*sunita-task-3113-added campaign ID as per requirement */}
							{/* &nbsp;Allocation ID:{reallocationID})*/}
							{this.state.newsuccess1}
						</center>
					</div>
					<div class=" col-lg-2" class="createbutton">
						<button
							class="btn add-button"
							type="submit"
							form="supportingdocumentedit">
							Save
						</button>
					</div>
				</div>

				<div style={{ overflowX: "hidden" }}>
					{/* <div className="col" align="center" style={{paddingTop: '90px' }}><label id="labelheading" >STEP 2 : Supporting Documents(Campaign ID: {parsed.campID})</label></div> */}
					<div
						className="col-lg-12"
						className="displayerrorsupp"
						style={{ display: this.state.alertDisplay }}>
						<div class="container-fluid">
							{/* <div class="card card-signin my-1"> */}
							<div class="card-body">
								<div class="row">
									<div
										class="col-lg-12"
										style={{ border: this.state.alertBorder }}>
										{/* <li style={{fontSize:'16px',color:'red'}}>Please fill all below required fields</li> */}
										<div className="errorMessage" style={{ fontSize: "17px" }}>
											{/* Sanjana-Godbole-3038-Edit Flow -Validation issues and revision of functionality code revision */}
											{this.state.errors.suppDocName
												? this.state.errors.suppDocName
												: ""}
											{this.state.leaderrors.AssetValidate
												? this.state.leaderrors.AssetValidate
												: ""}
											{this.state.leaderrors.assetCount
												? this.state.leaderrors.assetCount
												: ""}
											{this.state.leaderrors.leadPercentage
												? this.state.leaderrors.leadPercentage
												: ""}
											{this.state.linknameerror.linkname
												? this.state.linknameerror.linkname
												: ""}
											{this.state.backerror.OneAsset
												? this.state.backerror.OneAsset
												: ""}

											{this.state.errors.assetFiles
												? this.state.errors.assetFiles
												: ""}
											{/* {this.state.errors.abmFiles?this.state.errors.abmFiles:''} */}
											{/* {this.state.abmerrors.abmFiles?this.state.abmerrors.abmFiles:''} */}
											{this.state.backerror.assetFiles1
												? this.state.backerror.assetFiles1
												: ""}
											{this.state.backerror.assetUploadError
												? this.state.backerror.assetUploadError
												: ""}
											{this.state.backerror.abmFiles1
												? this.state.backerror.abmFiles1
												: ""}
											{this.state.errors.TouchErrorBack
												? this.state.errors.TouchErrorBack
												: ""}
											{this.state.errors.assetLinkerror
												? this.state.errors.assetLinkerror
												: ""}
											{this.state.errors.assetsleadCount
												? this.state.errors.assetsleadCount
												: ""}
											{this.state.errors.asseterrors
												? this.state.errors.asseterrors
												: ""}
											{this.state.errors.abmerrors
												? this.state.errors.abmerrors
												: ""}
											{this.state.errors.properabmerrors
												? this.state.errors.properabmerrors
												: ""}
											{this.state.errors.AssetWrongFile
												? this.state.errors.AssetWrongFile
												: ""}
											{this.state.errors.suppFileNameError
												? this.state.errors.suppFileNameError
												: ""}
											{this.state.errors.suppLinkError
												? this.state.errors.suppLinkError
												: ""}
										</div>
									</div>
								</div>
							</div>
							{/* </div> */}
						</div>
					</div>

					<div
						className="container-fluid"
						style={{ paddingBottom: "70px", paddingBottom: "80px" }}>
						{/* <div id="newSuccessSupp" class="font-weight-bold" style={{color:'green',textAlign:'center',fontSize: '16px'}} ></div> */}
						<div
							id="newSuccessDelivery"
							class="font-weight-bold"
							style={{
								color: "green",
								textAlign: "center",
								fontSize: "16px",
							}}></div>

						<form
							method="POST"
							id="supportingdocumentedit"
							name="SupportingDocument"
							className="needs-validation"
							onSubmit={this.submitSupportingDocument}
							encType="multipart/form-data"
							noValidate>
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cont">
								<div class="card">
									<div className="card-body">
										<div className="row">
											<div class=" legend labelsubheading col-xs-12 col-sm-12 col-md-6 col-lg-7">
												<h4 style={{ color: "orange" }}>Delivery Format</h4>
											</div>
											<div class="col-xs-6 col-sm-6 col-md-6 col-lg-2  custMap">
												{" "}
												{/* style={{ marginLeft: '655px' }}  added by raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue */}
												{
													this.state.agencyCustomizeMapping.length > 0 ? (
														<span>
															{this.state.editDeliveryFormat.map(
																(editDeliveryFormat) => {
																	return editDeliveryFormat.customizeMapping ===
																		"Yes" ? (
																		<input
																			type="checkbox"
																			id=""
																			name="customizeMapping"
																			onClick={this.handleChecked}
																			defaultChecked
																		/>
																	) : (
																		// snehal-task-3098-edit camp--customize mapping issue
																		<input
																			type="checkbox"
																			id=""
																			name="customizeMapping"
																			onClick={this.handleChecked}
																		/>
																	);
																	// snehal-task-3098-edit camp--customize mapping issue
																}
															)}
														</span>
													) : (
														<input
															type="checkbox"
															id=""
															name="customizeMapping"
															onClick={this.handleChecked}
															disabled
														/>
													)
													// snehal-task-3098-edit camp--customize mapping issue
												}
												&nbsp;&nbsp;&nbsp;&nbsp;
												<label id="label">
													Customize Mapping:{" "}
													<Tooltip
														className="tooltip1"
														title=" To Add Customize Mapping : 
                        Go to Campaign Menu and select Customize Mapping Option">
														<Icon
															type="question-circle-o"
															style={{
																marginLeft: "-4px",
																marginBottom: "-2px",
																padding: "1px",
																marginTop: "4px",
															}}
														/>
													</Tooltip>
												</label>
											</div>
											{this.state.agencyCustomizeMapping.length > 0 ? (
												<label id="label">Format :</label>
											) : (
												""
											)}
											&nbsp;
											<span>
												{" "}
												{/*raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue  managing all the elements within the row */}
												{this.state.agencyCustomizeMapping.length > 0 ? (
													<div>
														<Tooltip
															placement="right"
															title="Customize Mapping">
															<Icon
																type="plus-circle"
																style={{ height: "15px", width: "15px" }}
																data-toggle="modal"
																data-target="#customizeMappingAdd"
																onClick={
																	this.customizeMappingFormatHandleChange
																}
															/>
														</Tooltip>
														<div
															class="modal fade"
															id="customizeMappingAdd"
															role="dialog"
															data-backdrop="static"
															data-keyboard="false">
															<div class="modal-dialog modal-md">
																<div class="modal-content">
																	<div class="modal-header custom-classPopup">
																		<h4 style={{ color: "#ffffff" }}>
																			Customize mapping
																			<button
																				type="button"
																				class="cancel-button"
																				data-dismiss="modal"
																				style={{
																					fontSize: 15,
																					color: "#ffffff",
																					float: "right",
																				}}
																				onClick={
																					this
																						.customizeMappingCancleHandleChange
																				}>
																				&times;
																			</button>
																		</h4>
																	</div>
																	<br />
																	<br />
																	<div
																		align="center"
																		style={{
																			overflowY: "scroll",
																			height: "350px",
																			position: " relative",
																		}}>
																		<table
																			class="table table-bordered"
																			id="myTable3"
																			style={{
																				width: "85%",
																				position: " relative",
																			}}>
																			<thead>
																				<tr>
																					<th
																						style={{
																							position: "sticky",
																							color: "#707070",
																							height: "35px",
																							backgroundColor:
																								" rgba(126, 127, 129, 0.22)",
																						}}>
																						&nbsp;System Field
																					</th>
																					<th
																						style={{
																							position: "sticky",
																							color: "#707070",
																							height: "35px",
																							backgroundColor:
																								" rgba(126, 127, 129, 0.22)",
																						}}>
																						&nbsp;Mapping Alias Field{" "}
																					</th>
																				</tr>
																			</thead>
																			{this.state.agencyCustomizeMapping.map(
																				(agencyCustomizeMapping) => (
																					<tbody>
																						<tr>
																							<td>&nbsp;Publisher ID :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.pID}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Campaign ID :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.campID}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Campaign Name :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.campaignName
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								&nbsp;Lead Interaction Date :
																							</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.leadInteractionDate
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;First Name :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.firstName
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Last Name :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :
																								{
																									agencyCustomizeMapping.lastName
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Company Name :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.companyName
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								&nbsp;LinkedIn Company Name :
																							</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.linkedInCompanyName
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Email :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.email}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Work Phone :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.workPhone
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Work Phone Format :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.workPhoneFormat
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Job Title :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.jobTitle
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Linked Job Title :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.linkedInJobTitle
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Address :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.address}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Country :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.country}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;City :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.city}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;State :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.state}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Zip Code :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.zipCode}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								&nbsp;Company Employee Size :
																							</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.companyEmployeeSize
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Company Revenue :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.companyRevenue
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Industry :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.industry
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Asset Name :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.assetName
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								&nbsp;Asset Name Touch 1 :
																							</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.assetNameTouch1
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								&nbsp;Asset Timestamp Touch 1 :
																							</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :
																								{
																									agencyCustomizeMapping.assetTimestampTouch1
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								&nbsp;Asset Name Touch 2 :
																							</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.assetNameTouch2
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								&nbsp;Asset Timestamp Touch 2 :
																							</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.assetTimestampTouch2
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								&nbsp;Asset Name Touch 3 :
																							</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.assetNameTouch3
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								&nbsp;Asset Timestamp Touch 3 :
																							</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.assetTimestampTouch3
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Street :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.street}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;IP :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.ip}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Asset ID :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.supportDocID
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Job Level :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.jobLevel
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Job Function :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.jobFunction
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Channel :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.channel}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 1 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra1}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 2 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra2}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 3 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra3}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 4 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra4}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 5 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra5}
																							</td>
																						</tr>
																						{/* Sandeep-task-3158-added code for extra 15 fields from 6 to 20*/}
																						<tr>
																							<td>&nbsp;Extra 6 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra6}
																							</td>
																						</tr>

																						<tr>
																							<td>&nbsp;Extra 7 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra7}
																							</td>
																						</tr>

																						<tr>
																							<td>&nbsp;Extra 8 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra8}
																							</td>
																						</tr>

																						<tr>
																							<td>&nbsp;Extra 9 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra9}
																							</td>
																						</tr>

																						<tr>
																							<td>&nbsp;Extra 10 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra10}
																							</td>
																						</tr>

																						<tr>
																							<td>&nbsp;Extra 11 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra11}
																							</td>
																						</tr>

																						<tr>
																							<td>&nbsp;Extra 12 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra12}
																							</td>
																						</tr>

																						<tr>
																							<td>&nbsp;Extra 13 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra13}
																							</td>
																						</tr>

																						<tr>
																							<td>&nbsp;Extra 14 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra14}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 15 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra15}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 16 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra16}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 17 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra17}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 18 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra18}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 19 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra19}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Extra 20 :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.extra20}
																							</td>
																						</tr>

																						<tr>
																							<td>&nbsp;Domain :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.domain}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								&nbsp;Alternate Phone No :
																							</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.alternatePhoneNo
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Comments :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.comments
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;LinkedIn :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{
																									agencyCustomizeMapping.linkedIn
																								}
																							</td>
																						</tr>
																						<tr>
																							<td>&nbsp;Channel :</td>
																							<td
																								style={{
																									paddingBottom: "10px",
																								}}>
																								&nbsp;Alias :{" "}
																								{agencyCustomizeMapping.channel}
																							</td>
																						</tr>
																					</tbody>
																				)
																			)}
																		</table>
																	</div>
																	<br />
																	<br />
																</div>
															</div>
														</div>
													</div>
												) : (
													" "
												)}
											</span>
											{this.state.agencyCustomizeMapping.length > 0 ? (
												<>
													{" "}
													{/*raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue  using react-fragment */}
													{this.state.editDeliveryFormat.map(
														(editDeliveryFormat) => {
															return (
																<>
																	{" "}
																	{/*raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue  using react-fragment */}
																	<select
																		id="customizeMappingFormat"
																		name="customizeMappingFormat"
																		class="form-control inputStyle frmt"
																		style={{
																			height: "34px",
																			width: "104px",
																			marginLeft: "11px",
																		}}
																		value={
																			this.state.editDeliveryFormat[0]
																				.customizeMappingFormat
																		}
																		onChange={this.handleFormatChange}>
																		{this.state.agencyCustomizeMapping.map(
																			(agencyCustomizeMapping) => {
																				return (
																					<option
																						value={
																							this.state.editDeliveryFormat
																								.customizeMappingFormat
																						}>
																						{agencyCustomizeMapping.format}
																					</option>
																				);
																			}
																		)}
																	</select>
																</>
															);
														}
													)}
												</>
											) : (
												""
											)}
										</div>
										(Following Checked Fields Will Be Mandatory For Lead
										Validation)&nbsp;&nbsp;
										<hr />
										{this.state.editDeliveryFormat.map((editDeliveryFormat) => {
											return (
												<div class="row">
													<div class="col-lg-2">
														<input
															type="checkbox"
															id="pID"
															name="pID"
															defaultChecked
															disabled
															onChange={this.handleChecked}
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Publisher ID</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="campaignID"
															name="campaignID"
															defaultChecked
															disabled
															onChange={this.handleChecked}
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Campaign ID</label>
													</div>

													<div class="col-lg-2">
														{/* snehal-task-3172-pat/local--Agency side--Edit campaign-Step2--delivery format--checkbox issue */}
														{editDeliveryFormat.reAllocationID == "Yes" ? (
															<input
																type="checkbox"
																id="reAllocationID"
																name="reAllocationID"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id="reAllocationID"
																name="reAllocationID"
																onChange={this.handleChecked}
															/>
														)}
														{/* 

      <input type="checkbox" id="reAllocationID" name="reAllocationID" 
               
             onChange={this.handleChecked}

              /> */}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Allocation ID</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.campaignName == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="campaignName"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="campaignName"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Campaign Name</label>
													</div>

													{/* <div class="col-lg-2">
      <input type="checkbox" id="" name="campaignName" 
               
             onChange={this.handleChecked}

              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label id="label">campaign Name</label>
     </div> */}

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="leadInteractionDate"
															name="leadInteractionDate"
															defaultChecked
															disabled
															onChange={this.handleChecked}
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Lead interaction date</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="countryDelivery"
															name="country"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Country</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="companyName"
															name="companyName"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Company name</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.linkedIn == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="linkedIn"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="linkedIn"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">LinkedIn</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="email"
															name="email"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Email</label>
													</div>

													{/* Sandeep-task-3182-bcz of getting double checkbox for IP  removed one checkbox */}
													<div class="col-lg-2">
														{/* snehal-task-3172-pat/local--Agency side--Edit campaign-Step2--delivery format--checkbox issue */}
														{editDeliveryFormat.ip == "Yes" ? (
															<input
																type="checkbox"
																id="ip"
																name="ip"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id="ip"
																name="ip"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">IP</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="workPhone"
															name="workPhone"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Work phone</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id=""
															name="state"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">State</label>
													</div>
													{/* Sandeep-task-3185----change in position for street  */}
													<div class="col-lg-2">
														{editDeliveryFormat.street == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="street"
																onChange={this.handleChecked}
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="street"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Street</label>
													</div>

													<div class="col-lg-2">
														{this.state.jobTitleValidation === "Yes" ? (
															<input
																type="checkbox"
																id="jobTitleValidation"
																name="jobTitleValidation"
																onChange={this.handleChecked}
																defaultChecked
																disabled
															/>
														) : (
															<input
																type="checkbox"
																id="jobTitleValidation"
																name="jobTitleValidation"
																onChange={this.handleChecked}
																disabled
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Job title validation</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="linkedInCompanyName"
															name="linkedInCompanyName"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">LinkedIn company name</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.supportDocID == "Yes" ? (
															<input
																type="checkbox"
																id="assetId"
																name="supportDocID"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id="assetId"
																name="supportDocID"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Asset ID</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.domain == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="domain"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="domain"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Domain</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.alternatePhoneNo == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="alternatePhoneNo"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="alternatePhoneNo"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Alternate phone no</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id=""
															name="zipCode"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Zip code</label>
													</div>

													<div class="col-lg-2">
														{this.state.companyEmployeeSize === "Yes" ? (
															<input
																type="checkbox"
																id="companyEmployeeSize"
																name="companyEmployeeSize"
																onChange={this.handleChecked}
																defaultChecked
																disabled
															/>
														) : (
															<input
																type="checkbox"
																id="companyEmployeeSize"
																name="companyEmployeeSize"
																onChange={this.handleChecked}
																disabled
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Company employee size</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="linkedInJobTitle"
															name="linkedInJobTitle"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">LinkedIn job title</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="assetName"
															name="assetName"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Asset name</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="firstName"
															name="firstName"
															defaultChecked
															disabled
															onChange={this.handleChecked}
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">First name</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="jobTitleDelivery"
															name="jobTitle"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Job title</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="address"
															name="address"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Address</label>
													</div>

													<div class="col-lg-2">
														{this.state.companyRevenue == "Yes" ? (
															<input
																type="checkbox"
																id="companyRevenue"
																name="companyRevenue"
																onChange={this.handleChecked}
																defaultChecked
																disabled
															/>
														) : (
															<input
																type="checkbox"
																id="companyRevenue"
																name="companyRevenue"
																onChange={this.handleChecked}
																disabled
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Company revenue</label>
													</div>

													<div class="col-lg-2">
														{this.state.industry === "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="industry"
																onChange={this.handleChecked}
																defaultChecked
																disabled
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="industry"
																onChange={this.handleChecked}
																disabled
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Industry</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.comments == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="comments"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="comments"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Comments</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="lastName"
															name="lastName"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Last name</label>
													</div>

													<div class="col-lg-2">
														{this.state.jobLevelDelivery == "Yes" ? (
															<input
																type="checkbox"
																id="jobLevelDelivery"
																name="jobLevelDelivery"
																onChange={this.handleChecked}
																// value={editDeliveryFormat.jobLevel=this.state.jobLevelDelivery}
																defaultChecked
																disabled
															/>
														) : (
															<input
																type="checkbox"
																id="jobLevelDelivery"
																name="jobLevelDelivery"
																onChange={this.handleChecked}
																disabled
																// value={editDeliveryFormat.jobLevel=this.state.jobLevelDelivery}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Job level</label>
													</div>

													{/* Sandeep-task-3185----change in position for job function */}
													<div class="col-lg-2">
														{this.state.jobFunctionDelivery == "Yes" ? (
															<input
																type="checkbox"
																id="jobFunctionDelivery"
																name="jobFunction"
																onChange={this.handleChecked}
																// value={editDeliveryFormat.jobFunction=this.state.jobFunctionDelivery}
																defaultChecked
																disabled
															/>
														) : (
															<input
																type="checkbox"
																id="jobFunctionDelivery"
																name="jobFunction"
																// value={editDeliveryFormat.jobFunction=this.state.jobFunctionDelivery}
																onChange={this.handleChecked}
																disabled
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Job function</label>
													</div>

													<div class="col-lg-2">
														<input
															type="checkbox"
															id="city"
															value={this.state.city}
															name="city"
															onChange={this.handleChecked}
															defaultChecked
															disabled
														/>
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">City</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra1 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra1"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra1"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 1</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra2 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra2"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra2"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 2</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra3 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra3"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra3"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 3</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra4 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra4"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra4"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 4</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra5 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra5"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra5"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 5</label>
													</div>

													<div class="col-lg-2">
														{/* Sandeep-task-3158-added code for extra 15 fields from 6 to 20 */}
														{editDeliveryFormat.extra6 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra6"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra6"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 6</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra7 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra7"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra7"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 7</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra8 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra8"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra8"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 8</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra9 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra9"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra9"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 9</label>
													</div>
													<div class="col-lg-2">
														{editDeliveryFormat.extra10 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra10"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra10"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 10</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra11 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra11"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra11"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 11</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra12 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra12"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra12"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 12</label>
													</div>
													<div class="col-lg-2">
														{editDeliveryFormat.extra13 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra13"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra13"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 13</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra14 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra14"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra14"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 14</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra15 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra15"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra15"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 15</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra16 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra16"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra16"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 16</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra17 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra17"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra17"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 17</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra18 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra18"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra18"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 18</label>
													</div>
													<div class="col-lg-2">
														{editDeliveryFormat.extra19 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra19"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra19"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 19</label>
													</div>

													<div class="col-lg-2">
														{editDeliveryFormat.extra20 == "Yes" ? (
															<input
																type="checkbox"
																id=""
																name="extra20"
																onChange={this.handleChecked}
																defaultChecked
															/>
														) : (
															<input
																type="checkbox"
																id=""
																name="extra20"
																onChange={this.handleChecked}
															/>
														)}
														&nbsp;&nbsp;&nbsp;&nbsp;
														<label id="label">Extra 20</label>
													</div>

													{/* <div class="col-lg-2">
        {(this.state.agencyCustomizeMapping).length>0?
        <div>
              {editDeliveryFormat.customizeMapping=='Yes' ?
              
              <input type="checkbox" id="" name="customizeMapping"
                 onChange={this.handleChecked}
                 defaultChecked
                />:
                <input type="checkbox" id="" name="customizeMapping"
                onChange={this.handleChecked}  />

               }
               </div>
                :
                <input type="checkbox" id="" name="customizeMapping"
                onChange={this.handleChecked} disabled />
                 
              } 
               &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">customizeMapping</label>
      </div> */}
												</div>
											);
										})}
										<br />
									</div>
									{/*end of cardbody*/}
								</div>
								<br />

								<div class="row">
									<div class="col-lg-12">
										<div
											class="card"
											style={{
												backgroundColor: "rgb(251, 249, 249)",
												border: "0px solid white",
											}}>
											<div className="card-body">
												<div
													className="legend labelsubheading col-xs-2 col-sm-2 col-md-2 col-lg-2"
													style={{ padding: 0 }}>
													<h4 style={{ color: "orange", fontFamily: "roboto" }}>
														Quality Assurance{" "}
													</h4>
													<hr />
												</div>

												<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
													<label id="label">DI QA</label>&nbsp;&nbsp;
													{/* sandeep-task-2933 added code to handle DI QA help Option */}
													<Tooltip
														placement="right"
														title="This option will divert all the leads to DI-QA for review after submission from Publisher. All the leads will be visible to Agency only after DI-QA review completion.">
														<Icon type="question-circle" />
													</Tooltip>
													&nbsp;&nbsp;
													<Switch
														size="small"
														onChange={this.switchHandlechange}
														checked={
															this.state.switchValue == "ON" ? true : false
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<br />

								<div class="row">
									<div class="col-lg-12">
										<div
											class="card"
											style={{
												backgroundColor: "rgb(251, 249, 249)",
												border: "0px solid white",
											}}>
											<div className="card-body">
												<div className="legend labelsubheading">
													<h4 style={{ color: "orange", fontFamily: "roboto" }}>
														Upload Supporting Documents
													</h4>
													<hr />
												</div>
												<div class="card col-lg-3">
													<div className="card-body">
														{this.state.campaignType == "ABM" ? (
															<div class="row">
																<div class="col-lg-6">
																	{/* <label><span style={asterisk}>*</span>&nbsp;ABM:</label> */}
																	<label>
																		ABM:<span style={asterisk}>* </span>
																	</label>{" "}
																	&nbsp;
																	<Tooltip
																		placement="right"
																		title="The ABM file must have first 2 columns with the column heading as “Account Name” & “Domain Name”">
																		<Icon type="question-circle" />
																	</Tooltip>
																</div>
																<div class="col-lg-6">
																	<div
																		class="upload-btn-wrapper"
																		style={{ float: "right" }}>
																		<img
																			src="upload.png"
																			alt="upload"
																			style={{ height: "25px", width: "25px" }}
																			onClick={
																				this.handleChangeMultipleFileOfABM
																			}
																		/>
																		{/*input type file removed by Raunak */}
																	</div>
																</div>
															</div>
														) : (
															<div class="row">
																<div class="col-lg-6">
																	<label>ABM:</label>
																	{/* sandeep-task-2933-tooltip title changed. */}
																	<Tooltip
																		placement="right"
																		title="The ABM file must have one column with header name as “Account Name” OR “Domain Name”">
																		<Icon type="question-circle" />
																	</Tooltip>
																</div>
																<div class="col-lg-6">
																	<div
																		class="upload-btn-wrapper"
																		style={{ float: "right" }}>
																		<img
																			src="upload.png"
																			alt="upload"
																			style={{ height: "25px", width: "25px" }}
																			onClick={
																				this.handleChangeMultipleFileOfABM
																			}
																		/>
																		{/*input type file removed by raunak  */}
																	</div>
																</div>
															</div>
														)}

														{this.state.sdEditDetailABM.length >= 1 ? (
															<div id="noabmList" style={{ display: "none" }}>
																{this.state.sdEditDetailABM.length} ABM Files
																Selected
															</div>
														) : (
															<div id="noabmList" style={{ display: "none" }}>
																0 ABM Files Selected
															</div>
														)}

														<div
															class="table-responsive-lg"
															style={{ width: "100%" }}
															//  style={{display:this.state.displayTableabm}}
														>
															<div
																id="abmMessage"
																style={{ color: "green" }}></div>
															<div style={{ color: "red" }}>
																{this.state.abmerror.abmerr1}
															</div>
															<div
																id={"abmError"}
																style={{ color: "red" }}></div>
															<table
																id="myTable"
																className=" table table-bordered table-striped "
																style={{
																	width: "100%",
																	border: "0px solid white",
																}}>
																<tbody class="tbody" style={{ height: "auto" }}>
																	<div id="abmList" className="word-wrap"></div>
																	<div id="files"></div>
																	{this.state.sdEditDetail.map(
																		(sdEditDetail) => {
																			if (sdEditDetail.typeOfSuppDoc == "ABM") {
																				return (
																					//       <div id="fileName"><a href="#"
																					//       ><i class="fa fa-times" onClick={this.dynamicABMFile} id={sdEditDetail.typeOfSuppDoc+" "+sdEditDetail.supportDocID} name={sdEditDetail.suppDocName} /></a>{sdEditDetail.suppDocName}
																					//       </div>
																					//       )
																					//   }
																					// })}
																					<table>
																						<tr>
																							<td
																								style={{
																									backgroundColor: "#daecda",
																									border: "1px solid #ffffff",
																									paddingBottom: "0px",
																								}}>
																								<img
																									src="success.png"
																									alt="sucess"
																									style={{
																										height: "18px",
																										width: "18px",
																										float: "left",
																									}}
																								/>
																								&nbsp;
																								<div
																									id="fileName"
																									style={{
																										color: "green",
																										display: "inline-block",
																										width: "250px",
																										fontSize: "13px",
																										whiteSpace: " nowrap",
																										overflow: "hidden",
																										textOverflow: "ellipsis",
																										fontFamily: "roboto",
																										fontWeight: "500",
																									}}>
																									{sdEditDetail.suppDocName}

																									{/* <br/><br/> */}
																								</div>
																								<div
																									id="fileName"
																									style={{
																										color: "green",
																										display: "inline-block",
																										width: "250px",
																										fontSize: "13px",
																										whiteSpace: " nowrap",
																										overflow: "hidden",
																										textOverflow: "ellipsis",
																										fontFamily: "roboto",
																										fontWeight: "500",
																									}}>
																									<span>
																										ABM Count:{" "}
																										{
																											sdEditDetail.fileRecordCount
																										}
																									</span>

																									{/* <br/><br/> */}
																								</div>
																								<a
																									href="#"
																									style={{
																										float: "right",
																										fontFamily: "roboto",
																										fontSize: "13px",
																										fontWeight: "500",
																									}}
																									class=""
																									onClick={this.dynamicABMFile}
																									id={
																										sdEditDetail.typeOfSuppDoc +
																										" " +
																										sdEditDetail.supportDocID
																									}
																									name={
																										sdEditDetail.suppDocName
																									}>
																									Remove
																								</a>
																							</td>
																						</tr>
																					</table>
																				);
																			}
																		}
																	)}
																</tbody>
															</table>
														</div>
													</div>
												</div>

												<div class="card col-lg-3">
													<div className="card-body">
														<div class="row">
															<div class="col-lg-6">
																<label>Exclusion:</label>
																<Tooltip
																	placement="right"
																	title="Exclusion file can contain 1 column Domain">
																	<Icon type="question-circle" />
																</Tooltip>
															</div>

															<div class="col-lg-6">
																<div
																	class="upload-btn-wrapper"
																	style={{ float: "right" }}>
																	<img
																		src="upload.png"
																		alt="upload"
																		style={{ height: "25px", width: "25px" }}
																		onClick={
																			this.handleChangeMultipleFileOfExclusion
																		}
																	/>
																	{/*input type removed by raunak */}
																</div>
															</div>
														</div>
														{this.state.sdEditDetailExclusion.length >= 1 ? (
															<div
																id="noexclusionList"
																style={{ display: "none" }}>
																{this.state.sdEditDetailExclusion.length}{" "}
																Exclusion Files Selected
															</div>
														) : (
															<div
																id="noexclusionList"
																style={{ display: "none" }}>
																0 Exclusion Files Selected
															</div>
														)}

														{/* <div id="exclusionList"></div> */}

														<div
															class="table-responsive-lg"
															style={{ width: "100%" }}
															//  style={{display:this.state.displayTableexclusion}}
														>
															<div
																id="exclusionMessage"
																style={{ color: "green" }}></div>
															{/* <div id="exclusionMessage"></div> */}
															<div style={{ color: "red" }}>
																{this.state.exclusionerror.exclusionerr}
															</div>
															<div
																id={"exclusionError"}
																style={{ color: "red" }}></div>
															<table
																id="myTable"
																className=" table table-bordered table-striped "
																style={{
																	width: "100%",
																	border: "0px solid white",
																}}>
																<tbody class="tbody" style={{ height: "auto" }}>
																	<div
																		id="exclusionList"
																		className="word-wrap"></div>
																	{this.state.sdEditDetail.map(
																		(sdEditDetail) => {
																			if (
																				sdEditDetail.typeOfSuppDoc ==
																				"Exclusion"
																			) {
																				return (
																					//       <div id="fileName"><a href="#"
																					//       ><i class="fa fa-times" onClick={this.dynamicABMFile} id={sdEditDetail.typeOfSuppDoc+" "+sdEditDetail.supportDocID} name={sdEditDetail.suppDocName}/></a>{sdEditDetail.suppDocName}
																					//       </div>)
																					//   }
																					// })}
																					<table>
																						<tr>
																							<td
																								style={{
																									backgroundColor: "#daecda",
																									border: "1px solid #ffffff",
																									paddingBottom: "0px",
																								}}>
																								<img
																									src="success.png"
																									alt="sucess"
																									style={{
																										height: "18px",
																										width: "18px",
																										float: "left",
																									}}
																								/>
																								&nbsp;
																								<div
																									id="fileName"
																									style={{
																										color: "green",
																										display: "inline-block",
																										width: "250px",
																										fontSize: "13px",
																										whiteSpace: " nowrap",
																										overflow: "hidden",
																										textOverflow: "ellipsis",
																										fontFamily: "roboto",
																										fontWeight: "500",
																									}}>
																									{sdEditDetail.suppDocName}

																									<br />
																									<br />
																								</div>
																								<a
																									href="#"
																									style={{
																										float: "right",
																										fontFamily: "roboto",
																										fontSize: "13px",
																										fontWeight: "500",
																									}}
																									class=""
																									onClick={this.dynamicABMFile}
																									id={
																										sdEditDetail.typeOfSuppDoc +
																										" " +
																										sdEditDetail.supportDocID
																									}
																									name={
																										sdEditDetail.suppDocName
																									}>
																									Remove
																								</a>
																							</td>
																						</tr>
																					</table>
																				);
																			}
																		}
																	)}

																	{/* <div id={"exclusionError"} style={{color:'red'}}></div> */}
																</tbody>
															</table>
														</div>
													</div>
												</div>

												<div class="card col-lg-3">
													<div className="card-body">
														<div class="row">
															<div class="col-lg-6">
																<label>Suppression:</label>
																<Tooltip
																	placement="right"
																	title="Suppersion file can contain 1 column Email">
																	<Icon type="question-circle" />
																</Tooltip>
															</div>

															<div class="col-lg-6">
																<div
																	class="upload-btn-wrapper"
																	style={{ float: "right" }}>
																	<img
																		src="upload.png"
																		alt="upload"
																		style={{ height: "25px", width: "25px" }}
																		onClick={
																			this.handleChangeMultipleFileOfSuppression
																		}
																	/>
																	{/*input type removed by Raunak */}
																</div>
															</div>
														</div>

														{this.state.sdEditDetailSuppression.length >= 1 ? (
															<div
																id="nosuppressionList"
																style={{ display: "none" }}>
																{this.state.sdEditDetailSuppression.length}{" "}
																Suppression Files Selected
															</div>
														) : (
															<div
																id="nosuppressionList"
																style={{ display: "none" }}>
																0 Suppression Files Selected
															</div>
														)}
														{/* <div id="nosuppressionList">0 Suppression Files Selected</div> */}
														<div
															class="table-responsive-lg"
															style={{ width: "100%" }}
															// style={{display:this.state.displayTablesuppression}}
														>
															<div
																id="suppresionMessage"
																style={{ color: "green" }}></div>
															<div style={{ color: "red" }}>
																{this.state.suppressionerror.suppressionerr}
															</div>
															<div
																id={"suppressionError"}
																style={{ color: "red" }}></div>
															<table
																id="myTable"
																className=" table table-bordered table-striped "
																style={{
																	width: "100%",
																	border: "0px solid white",
																}}>
																<tbody class="tbody" style={{ height: "auto" }}>
																	<div
																		id="suppressionList"
																		className="word-wrap"></div>

																	{this.state.sdEditDetail.map(
																		(sdEditDetail) => {
																			if (
																				sdEditDetail.typeOfSuppDoc ==
																				"Suppression"
																			) {
																				return (
																					//       <div id="fileName"><a href="#"
																					//       ><i class="fa fa-times" onClick={this.dynamicABMFile} id={sdEditDetail.typeOfSuppDoc+" "+sdEditDetail.supportDocID} name={sdEditDetail.suppDocName}/></a>{sdEditDetail.suppDocName}
																					//       </div>)
																					//   }
																					// })}
																					<table>
																						<tr>
																							<td
																								style={{
																									backgroundColor: "#daecda",
																									border: "1px solid #ffffff",
																									paddingBottom: "0px",
																								}}>
																								<img
																									src="success.png"
																									alt="sucess"
																									style={{
																										height: "18px",
																										width: "18px",
																										float: "left",
																									}}
																								/>
																								&nbsp;
																								<div
																									id="fileName"
																									style={{
																										color: "green",
																										display: "inline-block",
																										width: "250px",
																										fontSize: "13px",
																										whiteSpace: " nowrap",
																										overflow: "hidden",
																										textOverflow: "ellipsis",
																										fontFamily: "roboto",
																										fontWeight: "500",
																									}}>
																									{sdEditDetail.suppDocName}

																									<br />
																									<br />
																								</div>
																								<a
																									href="#"
																									style={{
																										float: "right",
																										fontFamily: "roboto",
																										fontSize: "13px",
																										fontWeight: "500",
																									}}
																									class=""
																									onClick={this.dynamicABMFile}
																									id={
																										sdEditDetail.typeOfSuppDoc +
																										" " +
																										sdEditDetail.supportDocID
																									}
																									name={
																										sdEditDetail.suppDocName
																									}>
																									Remove
																								</a>
																							</td>
																						</tr>
																					</table>
																				);
																			}
																		}
																	)}
																</tbody>
															</table>
														</div>
													</div>
												</div>

												<div class="card col-lg-3">
													<div className="card-body">
														<div class="row">
															<div class="col-lg-6">
																<label>Other:</label>
															</div>

															<div class="col-lg-6">
																<div
																	class="upload-btn-wrapper"
																	style={{ float: "right" }}>
																	<img
																		src="upload.png"
																		alt="upload"
																		style={{ height: "25px", width: "25px" }}
																		onClick={
																			this.handleChangeMultipleFileOfOther
																		}
																	/>
																	{/*input type removed by Raunak */}
																</div>
															</div>
														</div>

														{this.state.sdEditDetailOther.length >= 1 ? (
															<div id="nootherList" style={{ display: "none" }}>
																{this.state.sdEditDetailOther.length} Other
																Files Selected
															</div>
														) : (
															<div id="nootherList" style={{ display: "none" }}>
																0 Other Files Selected
															</div>
														)}
														{/* <div id="otherList">0 Other Files Selected</div> */}
														<div
															class="table-responsive-lg"
															style={{ width: "100%" }}
															// style={{display:this.state.displayTableother}}
														>
															<div
																id="otherMessage"
																style={{ color: "green" }}></div>
															<div id="otherMessage"></div>
															<table
																id="myTable"
																className=" table table-bordered table-striped "
																style={{
																	width: "100%",
																	border: "0px solid white",
																}}>
																<tbody class="tbody" style={{ height: "auto" }}>
																	<div
																		id="otherList"
																		className="word-wrap"></div>
																	{this.state.sdEditDetail.map(
																		(sdEditDetail) => {
																			if (
																				sdEditDetail.typeOfSuppDoc == "Other"
																			) {
																				return (
																					//       <div id="fileName">
																					//         <a  href="#"  > <i class="fa fa-times" onClick={this.dynamicABMFile} id={sdEditDetail.typeOfSuppDoc+" "+sdEditDetail.supportDocID} name={sdEditDetail.suppDocName} /></a> {sdEditDetail.suppDocName}
																					//       </div>)
																					//   }
																					// })}

																					<table>
																						<tr>
																							<td
																								style={{
																									backgroundColor: "#daecda",
																									border: "1px solid #ffffff",
																									paddingBottom: "0px",
																								}}>
																								<img
																									src="success.png"
																									alt="sucess"
																									style={{
																										height: "18px",
																										width: "18px",
																										float: "left",
																									}}
																								/>
																								&nbsp;
																								<div
																									id="fileName"
																									style={{
																										color: "green",
																										display: "inline-block",
																										width: "250px",
																										fontSize: "13px",
																										whiteSpace: " nowrap",
																										overflow: "hidden",
																										textOverflow: "ellipsis",
																										fontFamily: "roboto",
																										fontWeight: "500",
																									}}>
																									{sdEditDetail.suppDocName}

																									<br />
																									<br />
																								</div>
																								<a
																									href="#"
																									style={{
																										float: "right",
																										fontFamily: "roboto",
																										fontSize: "13px",
																										fontWeight: "500",
																									}}
																									class=""
																									onClick={this.dynamicABMFile}
																									id={
																										sdEditDetail.typeOfSuppDoc +
																										" " +
																										sdEditDetail.supportDocID
																									}
																									name={
																										sdEditDetail.suppDocName
																									}>
																									Remove
																								</a>
																							</td>
																						</tr>
																					</table>
																				);
																			}
																		}
																	)}
																</tbody>{" "}
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/*Asset File Remove Popup */}
									<div class="modal" id="myModal" style={{ paddingTop: "3%" }}>
										<div class="modal-dialog">
											<div class="modal-content">
												<div class="modal-header custom-class">
													<h4
														class="modal-title"
														style={{
															color: "white",
															fontSize: "20px",
															paddingRight: 216,
														}}></h4>
													<button
														type="button"
														class="cancel-button"
														data-dismiss="modal"
														style={{ fontSize: 15 }}
														onClick={this.handleRefreshPage}>
														&times;
													</button>
												</div>
												<div class="modal-body" style={{ color: "#2196f3" }}>
													<label id="label">{this.state.assetRemovalMsg}</label>
												</div>
												<div class="modal-footer">
													<div>
														<button
															type="button"
															class="btn add-button"
															data-dismiss="modal"
															name={this.state.suppDocName}
															leadStatus={this.state.leadStatus}
															onClick={this.deleteAssetFile}>
															Yes
														</button>
														<button
															type="button"
															class="btn add-button"
															data-dismiss="modal">
															No
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>{" "}
									{/*End of Asset File Remove Popup */}
									{/*Asset Remove Popup
 <div class="modal" id="myModalPopup" style={{paddingTop:'3%'}}>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header custom-class">
          <h4 class="modal-title"  style={{color:'white',fontSize:'20px',paddingRight:216}}></h4>
          <button type="button" class="cancel-button" data-dismiss="modal" style={{fontSize:15}} onClick={this.handleRefreshPage}>&times;</button>
        </div>
        <div class="modal-body" style={{color:"#2196f3"}}>
        <label id="label">mzdmv{this.state.assetRemovalMsg}</label>
        </div>
       <div class="modal-footer">
       <div>
      <button type="button" class="btn add-button" data-dismiss="modal" name={this.state.supportDocID} leadStatus={this.state.leadStatus} linkFileName={this.state.linkFileName} onClick={(e)=>this.removeLink(e,0)}>Yes</button>
      <button type="button" class="btn add-button"  data-dismiss="modal">No</button></div>
      </div>
      </div>
    </div>
  </div> {/*End of Asset Link Remove Popup */}
									<div class="col-lg-12">
										<div
											class="card"
											style={{
												backgroundColor: "rgb(251, 249, 249)",
												border: "0px solid white",
											}}>
											<div className="card-body">
												<div className="legend labelsubheading">
													<h4 style={{ color: "orange", fontFamily: "roboto" }}>
														Upload Campaign Asset &nbsp;
														<span style={asterisk}>*</span> &nbsp;
														<div
															style={{
																float: "right",
																color: "black",
																fontSize: "13px",
																fontWeight: "400",
															}}>
															<input
																type="checkbox"
																id="multiTouchEdit"
																name="multiTouchEdit"
																checked={this.state.checkedValueMultiTouch}
																value={this.state.multiTouchEdit}
																onChange={this.multiTouchHandleChange}
															/>{" "}
															Require Multitouch
															Campaign&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
															<input
																type="checkbox"
																id="leadPerAssetChk"
																name="leadPerAssetChk"
																checked={this.state.checkedValue}
																// value={this.state.leadPerAssetChk} onChange={this.leadPerAssethandleChange} /> Asset wise Lead Required</div></h4>
																value={this.state.leadPerAssetChk}
																onChange={this.leadPerAssethandleChange}
															/>{" "}
															Require Asset viz leads
														</div>
													</h4>

													<hr />
													<div class="row">
														<div class="col-lg-6">
															<label
																style={{
																	color: "black",
																	fontWeight: "500",
																	fontFamily: "roboto",
																}}>
																Asset :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
															</label>
														</div>
														<div class="col-lg-6">
															<div
																style={{
																	color: "black",
																	float: "right",
																	fontWeight: "500",
																	fontFamily: "roboto",
																}}>
																&nbsp;Total leads :
																{this.state.totalLeadAllocation}
															</div>
														</div>
													</div>
													<div class="row">
														<div class="col-lg-12">
															<div class="form-group files">
																<input
																	type="file"
																	class="pointer"
																	style={{
																		color: "transparent",
																		paddingLeft: "46%",
																	}}
																	id="asset"
																	name="assetFiles"
																	multiple
																	onChange={this.handleChangeMultipleFile}
																	required
																/>
															</div>
															{/* <label style={{color:'black',fontWeight:'500',fontFamily:'roboto'}}>Asset :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> */}
															{/* <div  class="upload-btn-wrapper" id="asset">
                             <img src="upload.png" alt="upload" style={{height:'25px',width:'25px'}}/>
                              <input type="file" style={{color:'transparent'}} id="asset" name="assetFiles" multiple 
                              onChange={this.handleChangeMultipleFile} 
                              required/>
                              </div> */}
															{this.state.sdEditDetailAsset.length >= 1 ? (
																<div
																	id="noassetList"
																	style={{ display: "none" }}>
																	{this.state.sdEditDetailAsset.length} Asset
																	Files Selected
																</div>
															) : (
																<div
																	id="noassetList"
																	style={{ display: "none" }}>
																	0 Asset Files Selected
																</div>
															)}
														</div>
													</div>

													<div
														class="table-responsive-lg"
														style={{ width: "100%" }}
														style={{ display: this.state.displayTable }}>
														<table
															id="myTable"
															className=" table table-bordered table-striped "
															style={{ width: "100%" }}>
															<thead class="thead">
																<tr class="info tr">
																	<th
																		style={{
																			backgroundColor: "white",
																			color: "#777",
																			fontWeight: "400",
																			fontFamily: "roboto",
																		}}>
																		<div
																			style={{
																				textAlign: "center",
																				fontWeight: "bold",
																			}}>
																			Selected Files
																		</div>
																	</th>

																	<th
																		style={{
																			backgroundColor: "white",
																			color: "#777",
																			fontWeight: "400",
																			fontFamily: "roboto",
																		}}>
																		<div
																			style={{
																				textAlign: "center",
																				fontWeight: "bold",
																			}}>
																			Asset Name
																		</div>
																	</th>
																	{this.state.leadPerAssetChk === "Yes" ? (
																		<th
																			style={{
																				backgroundColor: "white",
																				color: "#777",
																				fontWeight: "400",
																				width: "100px",
																				fontFamily: "roboto",
																			}}>
																			<div
																				style={{
																					textAlign: "center",
																					fontWeight: "bold",
																				}}>
																				Lead %
																			</div>
																		</th>
																	) : (
																		""
																	)}
																	{this.state.leadPerAssetChk === "Yes" ? (
																		<th
																			style={{
																				backgroundColor: "white",
																				color: "#777",
																				fontWeight: "400",
																				width: "100px",
																				fontFamily: "roboto",
																			}}>
																			<div
																				style={{
																					textAlign: "center",
																					fontWeight: "bold",
																				}}>
																				Lead/Asset
																			</div>
																		</th>
																	) : (
																		""
																	)}
																	{this.state.multiTouchEdit === "Yes" ? (
																		<th
																			style={{
																				backgroundColor: "white",
																				color: "#777",
																				fontWeight: "400",
																				width: "130px",
																				fontFamily: "roboto",
																			}}>
																			<div
																				style={{
																					textAlign: "center",
																					fontWeight: "bold",
																				}}>
																				Multi Touch
																			</div>
																		</th>
																	) : (
																		""
																	)}
																</tr>
															</thead>
															<tbody class="tbody" style={{ height: "auto" }}>
																<tr class="tr">
																	<td class="campId">
																		{this.state.sdEditDetail.map(
																			(sdEditDetail) => {
																				if (
																					sdEditDetail.typeOfSuppDoc == "Asset"
																				) {
																					return (
																						<table style={{ width: "100%" }}>
																							<tr>
																								{sdEditDetail.status ===
																								"Removed" ? (
																									<td
																										style={{
																											backgroundColor:
																												"#daecda",
																											border:
																												"1px solid #ffffff",
																											paddingBottom: "0px",
																										}}>
																										<img
																											src="success.png"
																											alt="sucess"
																											style={{
																												height: "18px",
																												width: "18px",
																												float: "left",
																											}}
																										/>{" "}
																										&nbsp;
																										<span
																											id="fileName"
																											style={{
																												textAlign: "left",
																												color: "green",
																												display: "inline-block",
																												width: "200px",
																												fontSize: "13px",
																												whiteSpace: " nowrap",
																												overflow: "hidden",
																												textOverflow:
																													"ellipsis",
																												fontFamily: "roboto",
																												fontWeight: "500",
																												float: "left",
																												height: "28px",
																											}}>
																											<span
																												style={{
																													color: "red",
																													textDecoration:
																														"line-through",
																												}}>
																												{" "}
																												{
																													sdEditDetail.suppDocName
																												}
																											</span>
																											<br />
																											<br />
																										</span>
																										<div
																											style={{
																												float: "right",
																												fontFamily: "roboto",
																												fontSize: "13px",
																												fontWeight: "500",
																											}}
																											class=""
																											id={
																												sdEditDetail.typeOfSuppDoc +
																												" " +
																												sdEditDetail.supportDocID
																											}
																											name={
																												sdEditDetail.suppDocName
																											}
																											status={
																												sdEditDetail.status
																											}>
																											<span
																												style={{
																													color: "red",
																													textDecoration:
																														"line-through",
																												}}>
																												Remove
																											</span>
																										</div>
																									</td>
																								) : /*this is for Lead Accepted by Client */
																								sdEditDetail.leadStatus ===
																										"Client Accepted" ||
																								  sdEditDetail.leadStatus ===
																										"Not Accepted" ||
																								  sdEditDetail.leadStatus ===
																										true ? (
																									<td
																										style={{
																											backgroundColor:
																												"#daecda",
																											border:
																												"1px solid #ffffff",
																										}}>
																										<img
																											src="success.png"
																											alt="sucess"
																											style={{
																												height: "18px",
																												width: "18px",
																												float: "left",
																											}}
																										/>
																										&nbsp;
																										<span
																											id="fileName"
																											style={{
																												textAlign: "left",
																												color: "green",
																												display: "inline-block",
																												width: "200px",
																												fontSize: "13px",
																												whiteSpace: " nowrap",
																												overflow: "hidden",
																												textOverflow:
																													"ellipsis",
																												fontFamily: "roboto",
																												fontWeight: "500",
																												float: "left",
																												height: "28px",
																											}}>
																											{sdEditDetail.suppDocName}
																											<br />
																											<br />
																										</span>
																										<a
																											href="#"
																											//  data-toggle="modal" data-target="#myModal"
																											data-backdrop="static"
																											data-keyboard="false"
																											style={{
																												float: "right",
																												fontFamily: "roboto",
																												fontSize: "13px",
																												fontWeight: "500",
																											}}
																											class=""
																											onClick={this.deleteAssetFile.bind(
																												this
																											)}
																											id={
																												sdEditDetail.supportDocID
																											}
																											name={
																												sdEditDetail.suppDocName
																											}
																											status={
																												sdEditDetail.status
																											}
																											leadStatus={
																												sdEditDetail.leadStatus
																											}>
																											Remove
																										</a>
																									</td>
																								) : (
																									<td
																										style={{
																											backgroundColor:
																												"#daecda",
																											border:
																												"1px solid #ffffff",
																										}}>
																										<img
																											src="success.png"
																											alt="sucess"
																											style={{
																												height: "18px",
																												width: "18px",
																												float: "left",
																											}}
																										/>{" "}
																										&nbsp;
																										<span
																											id="fileName"
																											style={{
																												textAlign: "left",
																												color: "green",
																												display: "inline-block",
																												width: "200px",
																												fontSize: "13px",
																												whiteSpace: " nowrap",
																												overflow: "hidden",
																												textOverflow:
																													"ellipsis",
																												fontFamily: "roboto",
																												fontWeight: "500",
																												float: "left",
																												height: "28px",
																											}}>
																											{sdEditDetail.suppDocName}
																											<br />
																											<br />
																										</span>
																										<a
																											href="#"
																											style={{
																												float: "right",
																												fontFamily: "roboto",
																												fontSize: "13px",
																												fontWeight: "500",
																											}}
																											class=""
																											onClick={
																												this.dynamicABMFile
																											}
																											id={
																												sdEditDetail.typeOfSuppDoc +
																												" " +
																												sdEditDetail.supportDocID
																											}
																											name={
																												sdEditDetail.suppDocName
																											}
																											leadStatus={
																												sdEditDetail.leadStatus
																													? sdEditDetail.leadStatus
																													: ""
																											}>
																											Remove
																										</a>
																									</td>
																								)}
																							</tr>
																						</table>
																					);
																				}
																			}
																		)}
																		<span
																			id="assetList"
																			className="word-wrap"
																			style={{ fontSize: "13px" }}
																		/>
																	</td>
																	<td class="campId">
																		{this.state.inputType.map(
																			(inputType, i) => {
																				return (
																					<span key={inputType.suppDocName}>
																						{/* <div id="assetNameDiv" style={{display:'block',height:'30px',margin:'1.5px'}}>  */}
																						{inputType.status === "Removed" ? (
																							<span
																								style={{
																									color: "red",
																									textDecoration:
																										"line-through",
																								}}>
																								<input
																									defaultValue={
																										inputType.suppDocName
																									}
																									placeholder="Asset Name"
																									onChange={this.handleChangeAssetFileName.bind(
																										this,
																										i
																									)}
																									type="text"
																									id="suppDocName"
																									class="form-control"
																									name="suppDocName"
																									required
																									style={{
																										width: "200px,",
																										borderRadius: "1px",
																										height: "42px",
																										margin: "1.5px",
																									}}
																								/>
																							</span>
																						) : (
																							<input
																								defaultValue={
																									inputType.suppDocName
																								}
																								placeholder="Asset Name"
																								onChange={this.handleChangeAssetFileName.bind(
																									this,
																									i
																								)}
																								type="text"
																								id="suppDocName"
																								class="form-control"
																								name="suppDocName"
																								required
																								style={{
																									width: "200px,",
																									borderRadius: "1px",
																									height: "42px",
																									margin: "1.5px",
																								}}
																							/>
																						)}
																						{/* <br/> */}
																						{/* </div>  */}
																					</span>
																				);
																			}
																		)}
																	</td>
																	{this.state.leadPerAssetChk === "Yes" ? (
																		<td
																			class="campId"
																			style={{ width: "100px" }}>
																			{this.state.inputType.map(
																				(inputType, i) => {
																					return (
																						<span key={inputType.suppDocName}>
																							<div
																								id="leadAssetdiv"
																								style={{ display: "block" }}>
																								{inputType.status ===
																								"Removed" ? (
																									<span
																										style={{
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}>
																										<input
																											defaultValue={
																												inputType.leadPercentage
																											}
																											placeholder="Lead %"
																											onChange={this.handleChangeAssetFileName.bind(
																												this,
																												i
																											)}
																											type="text"
																											id="leadPercentage"
																											class="form-control"
																											name="leadPercentage"
																											style={{
																												borderRadius: "1px",
																												margin: "1.5px",
																												height: "42px",
																											}}
																											required
																										/>
																									</span>
																								) : (
																									<input
																										defaultValue={
																											inputType.leadPercentage
																										}
																										placeholder="Lead %"
																										onChange={this.handleChangeAssetFileName.bind(
																											this,
																											i
																										)}
																										type="text"
																										id="leadPercentage"
																										class="form-control"
																										name="leadPercentage"
																										style={{
																											borderRadius: "1px",
																											margin: "1.5px",
																											height: "42px",
																										}}
																										required
																									/>
																								)}
																							</div>
																						</span>
																					);
																				}
																			)}
																		</td>
																	) : (
																		""
																	)}

																	{this.state.leadPerAssetChk === "Yes" ? (
																		<td
																			class="campId"
																			style={{ width: "100px" }}>
																			{this.state.inputType.map(
																				(inputType, i) => {
																					return (
																						<span key={inputType.suppDocName}>
																							{/* <div id="leadAssetdiv" style={{display:'block'}}>
                                  <input value={inputType.leadPerAsset}  
                                 type="text" id="leadPerAsset" class="form-control" name="leadPerAsset" style={{borderRadius:'1px'}} disabled  /><br/>
                                       </div> */}
																							<div
																								id="leadAssetdiv"
																								style={{ display: "block" }}>
																								{inputType.status ===
																								"Removed" ? (
																									<span
																										style={{
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}>
																										<input
																											value={
																												inputType.leadPerAsset
																											}
																											type="text"
																											id="leadPerAsset"
																											class="form-control"
																											name="leadPerAsset"
																											style={{
																												borderRadius: "1px",
																												margin: "1.5px",
																												height: "42px",
																											}}
																											disabled
																										/>{" "}
																									</span>
																								) : (
																									<input
																										value={
																											inputType.leadPerAsset
																										}
																										type="text"
																										id="leadPerAsset"
																										class="form-control"
																										name="leadPerAsset"
																										style={{
																											borderRadius: "1px",
																											margin: "1.5px",
																											height: "42px",
																										}}
																										disabled
																									/>
																								)}
																							</div>
																						</span>
																					);
																				}
																			)}
																		</td>
																	) : (
																		""
																	)}

																	{this.state.multiTouchEdit === "Yes" ? (
																		<td
																			class="campId"
																			style={{ width: "130px" }}>
																			{this.state.inputType.map(
																				(inputType, i) => {
																					return (
																						<span key={inputType.suppDocName}>
																							<div
																								id="leadAssetdiv"
																								style={{ display: "block" }}>
																								{inputType.status ===
																								"Removed" ? (
																									<span
																										style={{
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}>
																										<select
																											id={inputType.key}
																											defaultValue={
																												inputType.multiTouch
																											}
																											name="multiTouch"
																											class="form-control"
																											style={{
																												borderRadius: "1px",
																												margin: "1.5px",
																												height: 42,
																											}}
																											onChange={this.handleChangeAssetFileName.bind(
																												this,
																												i
																											)}
																											name="multiTouch">
																											<option value="1st Touch">
																												1st Touch
																											</option>
																											<option value="2nd Touch">
																												2nd Touch
																											</option>
																											<option value="3rd Touch">
																												3rd Touch
																											</option>
																										</select>
																									</span>
																								) : (
																									<select
																										id={inputType.key}
																										class="form-control"
																										style={{
																											borderRadius: "1px",
																											margin: "1.5px",
																											height: 42,
																										}}
																										defaultValue={
																											inputType.multiTouch
																										}
																										onChange={this.handleChangeAssetFileName.bind(
																											this,
																											i
																										)}
																										name="multiTouch">
																										<option value="1st Touch">
																											1st Touch
																										</option>
																										<option value="2nd Touch">
																											2nd Touch
																										</option>
																										<option value="3rd Touch">
																											3rd Touch
																										</option>
																									</select>
																								)}
																							</div>
																						</span>
																					);
																				}
																			)}
																		</td>
																	) : (
																		""
																	)}
																</tr>
															</tbody>
														</table>
														<div style={{ color: "red" }}>
															{this.state.fileerrors.FileError}
														</div>
														<div
															id="assetMessage"
															style={{
																color: "green",
																fontSize: "13px",
															}}></div>
													</div>

													<div>
														<hr />
													</div>

													<div>
														<img
															src="attached.png"
															alt="attached"
															height="23px"
															width="24px"
															style={{ float: "left" }}
														/>
														&nbsp;&nbsp;
														<label
															style={{
																color: "black",
																fontWeight: "500",
																fontFamily: "roboto",
															}}>
															{" "}
															Asset Link :&nbsp;
															<a
																data-toggle="tooltip"
																title="Add one more link">
																<span
																	class="glyphicon glyphicon-plus-sign"
																	onClick={(e) => this.addLink(e)}
																	style={{
																		color: "rgb(5, 110, 184)",
																		fontWeight: "bold",
																		fontSize: 25,
																	}}></span>
															</a>
														</label>
													</div>

													<div class="card">
														<div
															class="card"
															style={{ maxHeight: "300px", overflowY: "auto" }}>
															<div
																class="table-responsive-lg"
																style={{ width: "100%" }}>
																{/* {this.state.leadPerAssetChk}
                                 {this.state.multiTouchChk} */}
																<label
																	id="leadlabel"
																	style={{ display: "none", fontSize: "0px" }}>
																	Lead percentage(%)
																</label>
																{this.state.leadPerAssetChk === "Yes" ? (
																	<label
																		id="leadlabel"
																		style={{
																			display: "none",
																			fontSize: "0px",
																		}}>
																		Lead/Asset
																	</label>
																) : (
																	""
																)}

																<table
																	id="myTable"
																	className=" table table-bordered table-striped "
																	style={{
																		width: "100%",
																		border: "0px solid white",
																	}}>
																	<thead>
																		<tr class="info tr">
																			{this.state.leadPerAssetChk === "Yes" &&
																			this.state.multiTouchEdit === "No" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						fontFamily: "roboto",
																						width: "180px",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Asset Link Name
																					</div>
																				</th>
																			) : this.state.multiTouchEdit === "Yes" &&
																			  this.state.leadPerAssetChk === "No" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "20%",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Asset Link Name
																					</div>
																				</th>
																			) : this.state.multiTouchEdit === "Yes" &&
																			  this.state.leadPerAssetChk === "Yes" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						fontFamily: "roboto",
																						width: "10%",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Asset Link Name
																					</div>
																				</th>
																			) : (
																				// no one is checked
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Asset Link Name{" "}
																					</div>
																				</th>
																			)}
																			{/* end of Selected Files */}

																			{this.state.leadPerAssetChk === "Yes" &&
																			this.state.multiTouchEdit === "No" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "180px",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Asset Link
																					</div>
																				</th>
																			) : this.state.multiTouchEdit === "Yes" &&
																			  this.state.leadPerAssetChk === "No" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "20%",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Asset Link
																					</div>
																				</th>
																			) : this.state.multiTouchEdit === "Yes" &&
																			  this.state.leadPerAssetChk === "Yes" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						fontFamily: "roboto",
																						width: "10%",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Asset Link
																					</div>{" "}
																				</th>
																			) : (
																				//no one is checked
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Asset Link
																					</div>{" "}
																				</th>
																			)}

																			{/* end of Asset Name */}

																			{this.state.leadPerAssetChk === "Yes" &&
																			(this.state.multiTouchEdit === "No" ||
																				this.state.multiTouchEdit === "") ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "30%",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Lead %
																					</div>
																				</th>
																			) : this.state.multiTouchEdit === "Yes" &&
																			  this.state.leadPerAssetChk === "Yes" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "10%",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Lead %
																					</div>
																				</th>
																			) : (
																				""
																			)}
																			{/* end of Lead % */}

																			{this.state.leadPerAssetChk === "Yes" &&
																			(this.state.multiTouchEdit === "No" ||
																				this.state.multiTouchEdit === "") ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "30%",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Lead/Asset
																					</div>
																				</th>
																			) : this.state.multiTouchEdit === "Yes" &&
																			  this.state.leadPerAssetChk === "Yes" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "10%",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Lead/Asset
																					</div>
																				</th>
																			) : (
																				""
																			)}

																			{/* end of Lead/Asset */}

																			{this.state.multiTouchEdit === "Yes" &&
																			this.state.leadPerAssetChk === "No" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "20%",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Multitouch
																					</div>
																				</th>
																			) : this.state.multiTouchEdit === "Yes" &&
																			  this.state.leadPerAssetChk === "Yes" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "10%",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Multitouch
																					</div>
																				</th>
																			) : (
																				""
																			)}
																			{/* end of Multi Touch data */}

																			{this.state.leadPerAssetChk === "Yes" &&
																			(this.state.multiTouchEdit === "No" ||
																				this.state.multiTouchEdit === "") ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "138px",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Remove{" "}
																					</div>
																				</th>
																			) : this.state.multiTouchEdit === "Yes" &&
																			  this.state.leadPerAssetChk === "No" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "20%",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Remove
																					</div>
																				</th>
																			) : this.state.multiTouchEdit === "Yes" &&
																			  this.state.leadPerAssetChk === "Yes" ? (
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "10%",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Remove{" "}
																					</div>
																				</th>
																			) : (
																				//no one is clicked
																				<th
																					style={{
																						backgroundColor: "white",
																						color: "#777",
																						fontWeight: "400",
																						width: "138px",
																						fontFamily: "roboto",
																					}}>
																					<div
																						style={{
																							textAlign: "center",
																							fontWeight: "bold",
																						}}>
																						Remove
																					</div>
																				</th>
																			)}
																		</tr>
																	</thead>
																	<tbody
																		class="tbody"
																		style={{ height: "auto" }}>
																		{this.state.leadPerAssetChk === "Yes" &&
																		(this.state.multiTouchEdit === "No" ||
																			this.state.multiTouchEdit === "") ? (
																			<td
																				style={{
																					width:
																						this.state.leadPerAssetChk === "Yes"
																							? "180px"
																							: "280px",
																				}}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="linkAssetFileName"
																										name="linkAssetFileName"
																										defaultValue={
																											dynamicInput.linkAssetFileName
																										}
																										placeholder="Enter Asset Link Name"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "157px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																									/>
																								) : (
																									<Input
																										id="linkAssetFileName"
																										name="linkAssetFileName"
																										defaultValue={
																											dynamicInput.linkAssetFileName
																										}
																										placeholder="Enter Asset Link Name"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "157px",
																											borderRadius: "1px",
																										}}
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : this.state.multiTouchEdit === "Yes" &&
																		  this.state.leadPerAssetChk === "No" ? (
																			<td style={{ width: "317px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="linkAssetFileName"
																										name="linkAssetFileName"
																										defaultValue={
																											dynamicInput.linkAssetFileName
																										}
																										placeholder="Enter Asset Link Name"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "157px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																									/>
																								) : (
																									<Input
																										id="linkAssetFileName"
																										name="linkAssetFileName"
																										defaultValue={
																											dynamicInput.linkAssetFileName
																										}
																										placeholder="Enter Asset Link Name"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "157px",
																											borderRadius: "1px",
																										}}
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : this.state.multiTouchEdit === "Yes" &&
																		  this.state.leadPerAssetChk === "Yes" ? (
																			<td style={{ width: "206px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="linkAssetFileName"
																										name="linkAssetFileName"
																										defaultValue={
																											dynamicInput.linkAssetFileName
																										}
																										placeholder="Enter Asset Link Name"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "157px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																									/>
																								) : (
																									<Input
																										id="linkAssetFileName"
																										name="linkAssetFileName"
																										defaultValue={
																											dynamicInput.linkAssetFileName
																										}
																										placeholder="Enter Asset Link Name"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "157px",
																											borderRadius: "1px",
																										}}
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : (
																			//no one is checked
																			<td style={{ width: "559px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="linkAssetFileName"
																										name="linkAssetFileName"
																										defaultValue={
																											dynamicInput.linkAssetFileName
																										}
																										placeholder="Enter Asset Link Name"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "380px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																									/>
																								) : (
																									<Input
																										id="linkAssetFileName"
																										name="linkAssetFileName"
																										defaultValue={
																											dynamicInput.linkAssetFileName
																										}
																										placeholder="Enter Asset Link Name"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "380px",
																											borderRadius: "1px",
																										}}
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		)}

																		{/* end of asset File */}

																		{this.state.leadPerAssetChk === "Yes" &&
																		(this.state.multiTouchEdit === "No" ||
																			this.state.multiTouchEdit === "") ? (
																			<td
																				style={{
																					width:
																						this.state.leadPerAssetChk === "Yes"
																							? "180px"
																							: "280px",
																				}}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="assetLink"
																										name="assetLink"
																										defaultValue={
																											dynamicInput.assetLink
																										}
																										placeholder="Enter Asset Link"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "157px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																									/>
																								) : (
																									<Input
																										id="assetLink"
																										name="assetLink"
																										defaultValue={
																											dynamicInput.assetLink
																										}
																										placeholder="Enter Asset Link"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "157px",
																											borderRadius: "1px",
																										}}
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : this.state.multiTouchEdit === "Yes" &&
																		  this.state.leadPerAssetChk === "No" ? (
																			<td style={{ width: "317px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="assetLink"
																										name="assetLink"
																										defaultValue={
																											dynamicInput.assetLink
																										}
																										placeholder="Enter Asset Link"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "157px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																									/>
																								) : (
																									<Input
																										id="assetLink"
																										name="assetLink"
																										defaultValue={
																											dynamicInput.assetLink
																										}
																										placeholder="Enter Asset Link"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "157px",
																											borderRadius: "1px",
																										}}
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : this.state.multiTouchEdit === "Yes" &&
																		  this.state.leadPerAssetChk === "Yes" ? (
																			<td style={{ width: "207px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="assetLink"
																										name="assetLink"
																										defaultValue={
																											dynamicInput.assetLink
																										}
																										placeholder="Enter Asset Link"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "180px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																									/>
																								) : (
																									<Input
																										id="assetLink"
																										name="assetLink"
																										defaultValue={
																											dynamicInput.assetLink
																										}
																										placeholder="Enter Asset Link"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "180px",
																											borderRadius: "1px",
																										}}
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : (
																			//no one is checked
																			<td style={{ width: "559px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="assetLink"
																										name="assetLink"
																										defaultValue={
																											dynamicInput.assetLink
																										}
																										placeholder="Enter Asset Link"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "380px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																									/>
																								) : (
																									<Input
																										id="assetLink"
																										name="assetLink"
																										defaultValue={
																											dynamicInput.assetLink
																										}
																										placeholder="Enter Asset Link"
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "380px",
																											borderRadius: "1px",
																										}}
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		)}

																		{/* end of Asset Link */}

																		{this.state.leadPerAssetChk === "Yes" &&
																		(this.state.multiTouchEdit === "No" ||
																			this.state.multiTouchEdit === "") ? (
																			<td
																				style={{
																					width:
																						this.state.leadPerAssetChk === "Yes"
																							? "369px"
																							: "",
																				}}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="leadPercentage"
																										name="leadPercentage"
																										placeholder="Enter Lead %"
																										defaultValue={
																											dynamicInput.leadPercentage
																										}
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "120px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																									/>
																								) : (
																									<Input
																										id="leadPercentage"
																										name="leadPercentage"
																										placeholder="Enter Lead %"
																										defaultValue={
																											dynamicInput.leadPercentage
																										}
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "120px",
																											borderRadius: "1px",
																										}}
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : this.state.multiTouchEdit === "Yes" &&
																		  this.state.leadPerAssetChk === "Yes" ? (
																			<td style={{ width: "208px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="leadPercentage"
																										name="leadPercentage"
																										placeholder="Enter Lead %"
																										defaultValue={
																											dynamicInput.leadPercentage
																										}
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "120px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																									/>
																								) : (
																									<Input
																										id="leadPercentage"
																										name="leadPercentage"
																										placeholder="Enter Lead %"
																										defaultValue={
																											dynamicInput.leadPercentage
																										}
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											width: "120px",
																											borderRadius: "1px",
																										}}
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : (
																			//no one checked
																			""
																		)}

																		{/* end of leadPercentage */}

																		{this.state.leadPerAssetChk === "Yes" &&
																		(this.state.multiTouchEdit === "No" ||
																			this.state.multiTouchEdit === "") ? (
																			<td
																				style={{
																					width:
																						this.state.leadPerAssetChk === "Yes"
																							? "363px"
																							: "",
																				}}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="leadPerAsset"
																										name="leadPerAsset"
																										className={
																											this.state.linkValidation
																										}
																										value={
																											dynamicInput.leadPerAsset
																										}
																										style={{
																											width: "100px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																										disabled
																									/>
																								) : (
																									<Input
																										id="leadPerAsset"
																										name="leadPerAsset"
																										className={
																											this.state.linkValidation
																										}
																										value={
																											dynamicInput.leadPerAsset
																										}
																										style={{
																											width: "100px",
																											borderRadius: "1px",
																										}}
																										disabled
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : this.state.multiTouchEdit === "Yes" &&
																		  this.state.leadPerAssetChk === "Yes" ? (
																			<td style={{ width: "210px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<Input
																										id="leadPerAsset"
																										name="leadPerAsset"
																										className={
																											this.state.linkValidation
																										}
																										value={
																											dynamicInput.leadPerAsset
																										}
																										style={{
																											width: "100px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																										disabled
																									/>
																								) : (
																									<Input
																										id="leadPerAsset"
																										name="leadPerAsset"
																										className={
																											this.state.linkValidation
																										}
																										value={
																											dynamicInput.leadPerAsset
																										}
																										style={{
																											width: "100px",
																											borderRadius: "1px",
																										}}
																										disabled
																									/>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : (
																			""
																		)}

																		{/* end of leadPerAsset */}

																		{/*Sanjana-3038-Validation issues and revision of functionality-height:"29px" to adjust UI for Multi-touch droppdown*/}
																		{this.state.multiTouchEdit === "Yes" &&
																		this.state.leadPerAssetChk === "No" ? (
																			<td style={{ width: "317px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<select
																										className={
																											this.state.linkValidation
																										}
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										value={
																											dynamicInput.multiTouch
																										}
																										style={{
																											borderRadius: "1px",
																											margin: "1.5px",
																											height: "29px",
																											width: "100px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																										name="multiTouch"
																										disabled>
																										<option value="1st Touch">
																											1st Touch
																										</option>
																										<option value="2nd Touch">
																											2nd Touch
																										</option>
																										<option value="3rd Touch">
																											3rd Touch
																										</option>
																									</select>
																								) : (
																									<select
																										className={
																											this.state.linkValidation
																										}
																										name="multiTouch"
																										value={
																											dynamicInput.multiTouch
																										}
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											borderRadius: "1px",
																											margin: "1.5px",
																											height: "29px",
																											width: "100px",
																											borderRadius: "1px",
																											color: "#777676",
																										}}>
																										<option value="1st Touch">
																											1st Touch
																										</option>
																										<option value="2nd Touch">
																											2nd Touch
																										</option>
																										<option value="3rd Touch">
																											3rd Touch
																										</option>
																									</select>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : this.state.multiTouchEdit === "Yes" &&
																		  this.state.leadPerAssetChk === "Yes" ? (
																			<td style={{ width: "210px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<select
																										className={
																											this.state.linkValidation
																										}
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										value={
																											dynamicInput.multiTouch
																										}
																										style={{
																											borderRadius: "1px",
																											margin: "1.5px",
																											height: "29px",
																											width: "100px",
																											borderRadius: "1px",
																											color: "red",
																											textDecoration:
																												"line-through",
																										}}
																										name="multiTouch"
																										disabled>
																										<option value="1st Touch">
																											1st Touch
																										</option>
																										<option value="2nd Touch">
																											2nd Touch
																										</option>
																										<option value="3rd Touch">
																											3rd Touch
																										</option>
																									</select>
																								) : (
																									<select
																										className={
																											this.state.linkValidation
																										}
																										name="multiTouch"
																										value={
																											dynamicInput.multiTouch
																										}
																										onChange={this.handleAssetLink.bind(
																											this,
																											i
																										)}
																										style={{
																											borderRadius: "1px",
																											margin: "1.5px",
																											height: "29px",
																											width: "100px",
																											borderRadius: "1px",
																											color: "#777676",
																										}}>
																										<option value="1st Touch">
																											1st Touch
																										</option>
																										<option value="2nd Touch">
																											2nd Touch
																										</option>
																										<option value="3rd Touch">
																											3rd Touch
																										</option>
																									</select>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : (
																			""
																		)}

																		{/* end of multiTouch */}
																		{/*Sanjana-3038-Validation issues and revision of functionality-fontSize:"20px" to adjust UI for remove button*/}
																		{this.state.leadPerAssetChk === "Yes" &&
																		(this.state.multiTouchEdit === "No" ||
																			this.state.multiTouchEdit === "") ? (
																			<td style={{ width: "144px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<a>
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: 20,
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								) : (dynamicInput.leadStatus ===
																										"Client Accepted" &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ||
																								  (dynamicInput.leadStatus ===
																										"Not Accepted" &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ||
																								  (dynamicInput.leadStatus ===
																										true &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ? (
																									<a
																										data-toggle="modal"
																										data-target="#myModalPopup"
																										data-backdrop="static"
																										data-keyboard="false">
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											index={i}
																											onClick={
																												(e) =>
																													this.removeLink(e, i)
																												// (e)=>this.openLinkPopup(e,i)
																											}
																											leadStatus={
																												dynamicInput.leadStatus
																											}
																											status={
																												dynamicInput.status
																													? dynamicInput.status
																													: dynamicInput.leadStatus
																											}
																											linkFileName={
																												dynamicInput.linkAssetFileName
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: "20px",
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								) : (
																									<a>
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											status={
																												dynamicInput.status
																											}
																											leadStatus="New"
																											onClick={(e) =>
																												this.removeLinkNew(e, i)
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: "20px",
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : this.state.multiTouchEdit === "Yes" &&
																		  this.state.leadPerAssetChk === "No" ? (
																			<td style={{ width: "321px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<a>
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: 20,
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								) : (dynamicInput.leadStatus ===
																										"Client Accepted" &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ||
																								  (dynamicInput.leadStatus ===
																										"Not Accepted" &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ||
																								  (dynamicInput.leadStatus ===
																										true &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ? (
																									<a
																										data-toggle="modal"
																										data-target="#myModalPopup"
																										data-backdrop="static"
																										data-keyboard="false">
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											index={i}
																											onClick={
																												(e) =>
																													this.removeLink(e, i)
																												// (e)=>this.openLinkPopup(e,i)
																											}
																											leadStatus={
																												dynamicInput.leadStatus
																											}
																											status={
																												dynamicInput.status
																													? dynamicInput.status
																													: dynamicInput.leadStatus
																											}
																											linkFileName={
																												dynamicInput.linkAssetFileName
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: "20px",
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								) : (
																									<a>
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											status={
																												dynamicInput.status
																											}
																											leadStatus="New"
																											onClick={(e) =>
																												this.removeLinkNew(e, i)
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: "20px",
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : this.state.multiTouchEdit === "Yes" &&
																		  this.state.leadPerAssetChk === "Yes" ? (
																			<td style={{ width: "210px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<a>
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: 20,
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								) : (dynamicInput.leadStatus ===
																										"Client Accepted" &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ||
																								  (dynamicInput.leadStatus ===
																										"Not Accepted" &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ||
																								  (dynamicInput.leadStatus ===
																										true &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ? (
																									<a
																										data-toggle="modal"
																										data-target="#myModalPopup"
																										data-backdrop="static"
																										data-keyboard="false">
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											index={i}
																											onClick={
																												(e) =>
																													this.removeLink(e, i)
																												// (e)=>this.openLinkPopup(e,i)
																											}
																											leadStatus={
																												dynamicInput.leadStatus
																											}
																											status={
																												dynamicInput.status
																													? dynamicInput.status
																													: dynamicInput.leadStatus
																											}
																											linkFileName={
																												dynamicInput.linkAssetFileName
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: "20px",
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								) : (
																									<a>
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											status={
																												dynamicInput.status
																											}
																											leadStatus="New"
																											onClick={(e) =>
																												this.removeLinkNew(e, i)
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: "20px",
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		) : (
																			<td style={{ width: "144px" }}>
																				{this.state.dynamicInput.map(
																					(dynamicInput, i) => {
																						return (
																							<div
																								key={dynamicInput.count}
																								style={{ textAlign: "center" }}>
																								{dynamicInput.status ===
																								"Removed" ? (
																									<a>
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: 20,
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								) : (dynamicInput.leadStatus ===
																										"Client Accepted" &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ||
																								  (dynamicInput.leadStatus ===
																										"Not Accepted" &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ||
																								  (dynamicInput.leadStatus ===
																										true &&
																										dynamicInput.status !==
																											"Removed" &&
																										dynamicInput.status !==
																											"New") ? (
																									<a
																										data-toggle="modal"
																										data-target="#myModalPopup"
																										data-backdrop="static"
																										data-keyboard="false">
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											index={i}
																											onClick={
																												(e) =>
																													this.removeLink(e, i)
																												// (e)=>this.openLinkPopup(e,i)
																											}
																											leadStatus={
																												dynamicInput.leadStatus
																											}
																											status={
																												dynamicInput.status
																													? dynamicInput.status
																													: dynamicInput.leadStatus
																											}
																											linkFileName={
																												dynamicInput.linkAssetFileName
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: "20px",
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								) : (
																									<a>
																										<span
																											class="glyphicon glyphicon-minus-sign"
																											id={i}
																											name={
																												dynamicInput.supportDocID
																													? dynamicInput.supportDocID
																													: "NULL"
																											}
																											status={
																												dynamicInput.status
																											}
																											leadStatus="New"
																											onClick={(e) =>
																												this.removeLinkNew(e, i)
																											}
																											style={{
																												color: "Red",
																												fontWeight: "bold",
																												fontSize: "20px",
																												border:
																													"5px solid white",
																											}}></span>
																									</a>
																								)}
																							</div>
																						);
																					}
																				)}
																			</td>
																		)}
																		{/* end of remove */}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
EditSupportingDocument.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(EditSupportingDocument)
);
