/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

import React, { Component } from 'react';
import CanvasJSReact from '../../assets/canvasjs.react';
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class AgencyReport2 extends Component
{

    constructor() {
        super();
        this.state = {
			leadDetails:[],
			acceptedleadArray:[],
			rejectedLeadArray:[],
			pIDList:[],
			pID:'ALL',
			pacing:'Default',
             dataPointsArray: [
                { name: "North America", y: 5 },
                { name: "Europe", y: 31 },
                { name: "South America", y: 40 },
                { name: "Countries of Australia  and Oceania(Island)", y: 17 },
                { name: "Asia", y: 7 },
                {name:"Africa",y:9}
            ],

            dataPointsArray1: [
                { name: "Rating-1", y: 20 },
                { name: "Rating-2", y: 3},
                { name: "Rating-3", y: 4 },
                { name: "Rating-4", y: 17 },
                { name: "Rating-5", y: 7 }
                
            ]
        }
        this.toggleDataSeries = this.toggleDataSeries.bind(this);
		this.handleChange=this.handleChange.bind(this);
        this.getFilterBaseData=this.getFilterBaseData.bind(this);
	}
getFilterBaseData(){
	const {user}=this.props.auth;
	var pacing=this.state.pacing;
	var pID=this.state.pID;
	// Get agency Lead Details
	fetch("reports/leadCountByMonthOnGraph?agencyID="+user.id+'&pacing='+pacing+'&pID='+pID)
	.then(res => res.json())
	.then(leadDetails =>{
	
	let acceptedleadArray=[]; let rejectedLeadArray=[];
		for(var i=0;i<leadDetails.length;i++){
			var year=leadDetails[i].Year;
		if(year===undefined || year===null || year===''){
				var dt = new Date();
				  year=dt.getFullYear();
			  }
			  year='-'+year;
			  if(pacing==='Today' || pacing==='Last Week'){year='';}
			var month=leadDetails[i].Month+year;
			var rejectIndexLabel='0',acceptIndexLabel='0';
			if(leadDetails[i].TotalAccepted==null || leadDetails[i].TotalAccepted=='null' || leadDetails[i].TotalAccepted==undefined){
				leadDetails[i].TotalAccepted=0;
			}
			else{
				acceptIndexLabel=(leadDetails[i].TotalAccepted).toString();
			}
			if(leadDetails[i].TotalRejected==null || leadDetails[i].TotalRejected=='null' || leadDetails[i].TotalRejected==undefined){
				leadDetails[i].TotalRejected=0;
			}
			else{
				rejectIndexLabel=(leadDetails[i].TotalRejected).toString();
			}
			
			acceptedleadArray.push({label:month,y:leadDetails[i].TotalAccepted,indexLabel:acceptIndexLabel});
			rejectedLeadArray.push({label:month,y:leadDetails[i].TotalRejected,indexLabel:rejectIndexLabel});
		}
		
		this.setState({acceptedleadArray:acceptedleadArray,rejectedLeadArray:rejectedLeadArray})
	
	this.setState({ leadDetails: leadDetails,pacing:pacing})
	}).catch(function (err) {console.log(err)});
}
handleChange(e){

let fields = this.state;
let self=this;
fields[e.target.name] = e.target.value
self.setState({fields})
self.getFilterBaseData();
}
componentDidMount(){
	if(!this.props.auth.isAuthenticated) {
		this.props.history.push('/userLogin');
	}
	else{ 
		const {user}=this.props.auth;
		var pacing='Default';
		// Get agency Lead Details
		fetch("reports/leadCountByMonthOnGraph?agencyID="+user.id+'&pacing='+pacing)
		.then(res => res.json())
		.then(leadDetails =>{
		this.setState({ leadDetails: leadDetails})
		var acceptedleadArray=[]; var rejectedLeadArray=[];
			for(var i=0;i<leadDetails.length;i++){
				var month=leadDetails[i].Month+'-'+leadDetails[i].Year;
				var acceptIndexLabel=(leadDetails[i].TotalAccepted).toString();
				var rejectIndexLabel=(leadDetails[i].TotalRejected).toString();
				acceptedleadArray.push({label:month,y:leadDetails[i].TotalAccepted,indexLabel:acceptIndexLabel});
				rejectedLeadArray.push({label:month,y:leadDetails[i].TotalRejected,indexLabel:rejectIndexLabel});
			}
			this.setState({acceptedleadArray:acceptedleadArray,rejectedLeadArray:rejectedLeadArray})
		}).catch(function (err) {console.log(err)});

		// Get Publisher ID which publisher lead is accepted
		fetch("reports/leadAcceptedPID?agencyID="+user.id)
		.then(res => res.json())
		.then(pIDList =>{
		this.setState({ pIDList: pIDList})
		}).catch(function (err) {console.log(err)});
	}
}// End of ComponentDidMount
toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
        }
		else{
            e.dataSeries.visible = true;
		}
		this.chart1.render();
}// End of toggleDataSeries
render() {
        const deliveryChart = {
			theme: "light2",
			animationEnabled: true,
			title:{
				text: "Lead Delivery"
			},
			exportEnabled: true,
			axisX:{
				title:this.state.pacing,
			},
			axisY:{
					interval: 50,
					title: "No. of Accepted leads",
					titleFontColor: "#4F81BC",
					lineColor: "#4F81BC",
					labelFontColor: "#4F81BC",
					tickColor: "#4F81BC",
    			},
			axisY2: {
					title: "No. of Rejected leads",
					titleFontColor: "#C0504E",
					lineColor: "#C0504E",
					labelFontColor: "#C0504E",
					tickColor: "#C0504E"
		 		},
			toolTip:{
					shared: true
				},
			legend: {
					cursor: "pointer",
					itemclick: this.toggleDataSeries
            	},
            height:400,
            dataPointWidth: 20,
			data: [{
                    click: function(e)
                    {
                        // alert(  e.dataSeries.type+ ", dataPoint { x:" + e.dataPoint.name + ", y: "+ e.dataPoint.y + " }" );
                    document.getElementById('twobar').innerHTML=e.dataPoint.name+":"+e.dataPoint.y;
                 },
				type: "column",
				bevelEnabled: true,
		name: "Accepted",
		legendText: "Accepted",
		showInLegend: true, 
		dataPoints:this.state.acceptedleadArray
			},
			{
		type: "column",	
		bevelEnabled: true,
		name: "Rejected",
		legendText: "Rejected",
		axisYType: "secondary",
		showInLegend: true,
		// indexLabelFontColor: "red",
		dataPoints:this.state.rejectedLeadArray

			}]
        }
        
        return(
			
        <div class="container-fluid">
		 <Navigation />
		 <div class="row" style={{paddingTop:"90px"}}>
		 <div class=" col-lg-offset-3 col-xs-6 col-sm-6 col-md-6 col-lg6 col-xl-6"  >
         <h1><center>Delivery Analysis Dashboard</center></h1>
		 </div>
		 </div>
	<br/>
	<div class="row">
               
			   <div class="col-xs-12 col-sm-12 col-md-12 col-lg12 col-xl-12" >
				   
				  <div class="col-sm-2">
					  <select style={{ height: '60%'}} id="pacing" name="pacing"
						  onChange={this.handleChange}>
						  <option value="Default">Select Pacing</option>
						  <option value="Today">Today</option>
						  <option value="Weekly">This Week</option>
						  <option value="Monthly">This Month</option>
						  <option value="Quarterly">This Quarter</option>
						  <option value="Yearly">This Year</option>
						  <option value="Last Week">Last Week</option>
						  <option value="Last Month">Last Month</option>
						  <option value="Last Quarter">Last Quarter</option>
						  <option value="Last Year">Last Year</option>
						  
						  </select>
				  </div>
				  <div class="col-sm-2">
					  <select style={{ height: '60%'}} id="pID" name="pID"
						  onChange={this.handleChange}>
						  <option value="ALL">Select Publisher</option>
						  {this.state.pIDList.map(pIDList=>(
						  <option value={pIDList.pID}>{pIDList.publisherName}</option>))}
						  </select>
				  </div>
				  </div>
			  </div>
			  <br/>
             <div class="row" >
			
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg12 col-xl-12"  >
				<CanvasJSChart options = {deliveryChart}
                                onRef={ref => this.chart1 = ref}
                            />
                            
                    <div id="twobar">
                     
                    </div>  
                </div>
            </div>
			{/* <hr style={{borderTop:"1px solid #2196f3"}}/> */}
           
			<Footer />
        </div>
          
      )
                }
}
AgencyReport2.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default connect(mapStateToProps, { logoutUser })(withRouter(AgencyReport2));