
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Sanjana Godbole
*@fileName :
 *Desc: Agency On Board Form
*/

import React from 'react';
import 'antd/dist/antd.css';
import Footer from "../layouts/footer";
import {Form,Input,Tooltip,Icon,Cascader,Select,Row,Col,Checkbox,Button,AutoComplete,Card} from 'antd';

const { Option } = Select;

const AutoCompleteOption = AutoComplete.Option;
class AgencyContactInformation extends React.Component {
  state = {
    confirmDirty: false,
    newsuccess:[],
    autoCompleteResult: [],
    infoMsg:'',
    buttonDisplay:'disabled',
    displayContact1:'disabled',
    displayContact2:'disabled',
    displayDesignation1:'disabled',
    displayDesignation2:'disabled',
    displayEmail1:'disabled',
    displayEmail2:'disabled',
    displayPhn1:'disabled',
    displayPhn2:'disabled',
  };

  componentDidMount()
  {
    var pID=localStorage.getItem('pID');
  
    if(pID=="")
    
    {
     // alert("PublisherId from Local storage in if====>"+pID);
      this.setState({
        buttonDisplay:'disabled',
        displayContact1:'disabled',
        displayContact2:'disabled',
        displayDesignation1:'disabled',
        displayDesignation2:'disabled',
        displayEmail1:'disabled',
        displayEmail2:'disabled',
        displayPhn1:'disabled',
        displayPhn2:'disabled',
        infoMsg:'Please Fill Company Information First'
      });
    }
    else


    {
     // alert("PublisherId from Local storage in else ====>"+pID);
      this.setState({
        buttonDisplay: "button",
      
        displayContact1:'',
        displayContact2:'',
        displayDesignation1:'',
        displayDesignation2:'',
        displayEmail1:'',
        displayEmail2:'',
        displayPhn1:'',
        displayPhn2:'',
        infoMsg:''
      });
    }
  }
  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        // alert(JSON.stringify(values));
       // debugger;
        var agencyID=localStorage.getItem('agencyID');
        var data=[];
        data=[{'contactID':1,'userID':agencyID,'contactPerson':values.contactPerson,'lastName':values.contactPerson1LastName,'designation':values.designation,'email':values.email,countryPhoneCode:values.prefix,'phoneNo':values.phone},{'contactID':2,'userID':agencyID,'contactPerson':values.contactPerson2,
        'lastName':values.contactPerson2LastName,'designation':values.designation2,'email':values.email2,countryPhoneCode:values.prefix2,'phoneNo':values.phone2}]
       // alert("Data on submit===>"+JSON.stringify(data))
       

        fetch("/publisherOnBoardDetails/contactInfo", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }).then(res=>res.json()).then(res=>{
          // alert(JSON.stringify(res))
          console.warn("Response in res in=="+JSON.stringify(res));
            if(res.success==true)
            {
              var a=(res.message);
              // alert(a)
             
              this.setState({newsuccess:a});
              this.setState({
                buttonDisplay:'disabled'});
             }
        }).catch(function (err) {
          console.log(err)
        });

      }
    });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
    }
    this.setState({ autoCompleteResult });
  };


  handleChange=e => {

    let fields = this.state;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
   // alert("Value====>"+this.state.companyName);
    
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const prefixSelector2 = getFieldDecorator('prefix2', {
      initialValue: '91',
    })(
      <Select style={{ width: 110 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
        <Option value="7840">+7 840</Option>
        <Option value="7940">+7 940</Option>
        <Option value="93">+93</Option>
        <Option value="35818">+358 18</Option>
        <Option value="355">+355</Option>
        <Option value="213">+213</Option>
        <Option value="1684">+1 684</Option>
        <Option value="376">+376</Option>
        <Option value="244">+244</Option>
        <Option value="1264">+1 264</Option>
        <Option value="1268">+1 268</Option>
        <Option value="54">+54</Option>
        <Option value="374">+374</Option>
        <Option value="297">+297</Option>
        <Option value="247">+247</Option>
        <Option value="61">+61</Option>
        <Option value="6721">+672 1</Option>
        <Option value="672">+672</Option>
        <Option value="43">+43</Option>
        <Option value="994">+994</Option>
        <Option value="1242">+1 242</Option>
        <Option value="973">+973</Option>
        <Option value="880">+880</Option>
        <Option value="1246">+1246</Option>
        <Option value="1248">+1268</Option>
        <Option value="375">+375</Option>
        <Option value="32">+32</Option>
        <Option value="501">+501</Option>
        <Option value="229">+229</Option>
        <Option value="1441">+1441</Option>
        <Option value="975">+975</Option>
        <Option value="591">+591</Option>
        <Option value="5997">+5997</Option>
        <Option value="387">+387</Option>
        <Option value="267">+267</Option>
        <Option value="55">+55</Option>
        <Option value="246">+246</Option>
        <Option value="1284">+1284</Option>
        <Option value="673">+673</Option>
        <Option value="359">+359</Option>
        <Option value="226">+226</Option>
        <Option value="257">+257</Option>
        <Option value="855">+855</Option>
        <Option value="237">+237</Option>
        <Option value="1">+1</Option>
        <Option value="238">+238</Option>
        <Option value="5993">+599 3</Option>
        <Option value="5994">+5994</Option>
        <Option value="5997">+5997</Option>
        <Option value="1345">+1345</Option>
        <Option value="236">+236</Option>
        <Option value="235">+235</Option>
        <Option value="64">+64</Option>
        <Option value="56">+56</Option>
        <Option value="6189164">+6189164</Option>
        <Option value="6189162">+6189162</Option>
        <Option value="57">+57</Option>
        <Option value="269">+269</Option>
        <Option value="243">+243</Option>
        <Option value="242">+242</Option>
        <Option value="682">+682</Option>
        <Option value="506">+506</Option>
        <Option value="225">+225</Option>
        <Option value="385">+385</Option>
        <Option value="53">+53</Option>
        <Option value="5399">+5399</Option>
        <Option value="5999">+5999</Option>
        <Option value="357">+357</Option>
        <Option value="420">+420</Option>
        <Option value="45">+45</Option>
        <Option value="246">+246</Option>
        <Option value="243">+243</Option>
        <Option value="1809">+1809</Option>
        <Option value="1829">+1829</Option>
        <Option value="1849">+1849</Option>
        <Option value="670">+670</Option>
        <Option value="56">+56</Option>
        <Option value="20">+20</Option>
        <Option value="503">+503</Option>
        <Option value="8812">+8812</Option>
        <Option value="8813">+8813</Option>
        <Option value="88213">+88213</Option>
        <Option value="240">+240</Option>
        <Option value="291">+291</Option>
        <Option value="372">+372</Option>
        <Option value="251">+251</Option>
        <Option value="500">+500</Option>
        <Option value="298">+298</Option>
        <Option value="679">+679</Option>
        <Option value="358">+358</Option>
        <Option value="33">+33</Option>
        <Option value="596">+596</Option>
        <Option value="594">+594</Option>
        <Option value="689">+689</Option>
        <Option value="241">+241</Option>
        <Option value="220">+220</Option>
        <Option value="49">+49</Option>
        <Option value="995">+995</Option>
        <Option value="230">+230</Option>
        <Option value="49">+49</Option>
        <Option value="350">+350</Option>
        <Option value="881">+881</Option>
        <Option value="8819">+8819</Option>
        <Option value="8818">+8818</Option>
        <Option value="30">+30</Option>
        <Option value="1473">+1473</Option>
        <Option value="299">+299</Option>
        <Option value="590">+590</Option>
        <Option value="1671">+1671</Option>
        <Option value="502">+502</Option>
        <Option value="441481">+441481</Option>
        <Option value="447781">+447781</Option>
        <Option value="447839">+447839</Option>
        <Option value="447911">+447911</Option>
        <Option value="224">+224</Option>
        <Option value="245">+245</Option>
        <Option value="509">+509</Option>
        <Option value="592">+592</Option>
        <Option value="504">+504</Option>
        <Option value="852">+852</Option>
        <Option value="36">+36</Option>
        <Option value="354">+354</Option>
        <Option value="8810">+8810</Option>
        <Option value="8811">+8811</Option>
        <Option value="91">+91</Option>
        <Option value="62">+62</Option>
        <Option value="870">+870</Option>
        <Option value="800">+800</Option>
        <Option value="882">+882</Option>
        <Option value="883">+883</Option>
        <Option value="979">+979</Option>
        <Option value="98">+98</Option>
        <Option value="964">+964</Option>
        <Option value="353">+353</Option>
        <Option value="8816">+8816</Option>
        <Option value="8817">+8817</Option>
        <Option value="441624">+441624</Option>
        <Option value="447524">+447524</Option>
        <Option value="447624">+447624</Option>
        <Option value="447924">+447924</Option>
        <Option value="972">+972</Option>
        <Option value="39">+39</Option>
        <Option value="1876">+1876</Option>
        <Option value="47">+47</Option>
        <Option value="81">+81</Option>
        <Option value="441534">+441534</Option>
        <Option value="942">+942</Option>
        <Option value="77">+77</Option>
        <Option value="76">+76</Option>
        <Option value="254">+254</Option>
        <Option value="686">+686</Option>
        <Option value="850">+850</Option>
        <Option value="82">+82</Option>
        <Option value="383">+383</Option>
        <Option value="37744">+37744</Option>
        <Option value="37745">+37745</Option>
        
        <Option value="38643">+38643</Option>
        <Option value="38649">+38649</Option>
        <Option value="38128">+38128</Option>
        <Option value="38129">+38129</Option>
        <Option value="38138">+38138</Option>
        <Option value="38139">+38139</Option>
        <Option value="965">+965</Option>
        <Option value="996">+996</Option>
        <Option value="856">+856</Option>
        <Option value="371">+371</Option>
        <Option value="961">+961</Option>
        <Option value="266">+266</Option>
        <Option value="231">+231</Option>
        <Option value="218">+218</Option>
        <Option value="423">+423</Option>
        <Option value="370">+370</Option>
        <Option value="352">+352</Option>
        <Option value="853">+853</Option>
        <Option value="389">+389</Option>
        <Option value="261">+261</Option>
        <Option value="265">+265</Option>
        <Option value="60">+60</Option>
        <Option value="960">+960</Option>
        <Option value="223">+223</Option>
        <Option value="356">+356</Option>
        <Option value="692">+692</Option>
        <Option value="596">+596</Option>
        <Option value="222">+222</Option>
        <Option value="230">+230</Option>
        <Option value="262269">+262269</Option>
        <Option value="262639">+262639</Option>
        <Option value="52">+52</Option>
        <Option value="691">+691</Option>
        <Option value="1808">+1808</Option>
        <Option value="373">+373</Option>
        <Option value="377">+377</Option>
        <Option value="382">+382</Option>
        <Option value="976">+976</Option>
        <Option value="1664">+1664</Option>
        <Option value="258">+258</Option>
        <Option value="95">+95</Option>
        <Option value="212">+212</Option>
        <Option value="374">+374</Option>
        <Option value="95">+95</Option>
        <Option value="37447">+37447</Option>
        <Option value="37449">+37449</Option>
        <Option value="264">+264</Option>
        <Option value="674">+674</Option>
        <Option value="977">+31</Option>
        <Option value="1869">+1869</Option>
        <Option value="687">+687</Option>
        <Option value="64">+64</Option>
        <Option value="505">+505</Option>
        <Option value="227">+227</Option>
        <Option value="234">+234</Option>
        <Option value="683">+683</Option>
        <Option value="6723">+6273</Option>
        <Option value="90392">+90392</Option>
        <Option value="4428">+4428</Option>
        <Option value="1670">+1670</Option>
        <Option value="47">+47</Option>
        <Option value="968">+968</Option>
        <Option value="92">+92</Option>
        <Option value="680">+680</Option>
        <Option value="970">+970</Option>
        <Option value="507">+507</Option>
        <Option value="675">+675</Option>
        <Option value="595">+595</Option>
        <Option value="51">+51</Option>
        <Option value="63">+63</Option>
        <Option value="64">+64</Option>
        <Option value="48">+48</Option>
        <Option value="351">+351</Option>
        <Option value="1787">+1787</Option>
        <Option value="1939">+1939</Option>
        <Option value="974">+974</Option>
        <Option value="262">+262</Option>
        <Option value="40">+40</Option>
        <Option value="7">+7</Option>
        <Option value="250">+250</Option>
        <Option value="5994">+5994</Option>
        <Option value="590">+590</Option>
        <Option value="290">+290</Option>
        <Option value="1869">+1869</Option>
        <Option value="1758">+1758</Option>
        <Option value="590">+590</Option>
        <Option value="508">+508</Option>
        <Option value="1784">+1748</Option>
        <Option value="685">+685</Option>
        <Option value="378">+378</Option>
        <Option value="239">+239</Option>
        <Option value="966">+966</Option>
        <Option value="221">+221</Option>
        <Option value="381">+381</Option>
        <Option value="248">+248</Option>
        <Option value="232">+232</Option>
        <Option value="65">+65</Option>
        <Option value="5993">+593</Option>
        <Option value="1721">+1721</Option>
        <Option value="421">+421</Option>
        <Option value="677">+677</Option>
        <Option value="386">+386</Option>
        <Option value="252">+252</Option>
        <Option value="27">+27</Option>
        <Option value="500">+500</Option>
        <Option value="995">+995</Option>
        <Option value="211">+211</Option>
        <Option value="34">+34</Option>
        <Option value="94">+94</Option>
        <Option value="249">+249</Option>
        <Option value="597">+597</Option>
        <Option value="4779">+4779</Option>
        <Option value="268">+268</Option>
        <Option value="46">+46</Option>
        <Option value="41">+41</Option>
        <Option value="963">+963</Option>
        <Option value="886">+886</Option>
        <Option value="992">+992</Option>
        <Option value="255">+255</Option>
        <Option value="888">+888</Option>
        <Option value="66">+66</Option>
        <Option value="88216">+88216</Option>
        <Option value="228">+228</Option>
        <Option value="690">+690</Option>
        <Option value="676">+676</Option>
        <Option value="3732">+3732</Option>
        <Option value="3735">+3735</Option>
        <Option value="1868">+1868</Option>
        <Option value="2908">+2908</Option>
        <Option value="216">+216</Option>
        <Option value="90">+90</Option>
        <Option value="993">+993</Option>
        <Option value="1649">+1649</Option>
        <Option value="688">+688</Option>
        <Option value="256">+256</Option>
        <Option value="380">+380</Option>
        <Option value="971">+971</Option>
        <Option value="44">+44</Option>
        <Option value="1">+1</Option>
        <Option value="878">+878</Option>
        <Option value="598">+598</Option>
        <Option value="1340">+1340</Option>
        <Option value="998">+998</Option>
        <Option value="678">+678</Option>
        <Option value="58">+58</Option>
        <Option value="39">+39</Option>
        <Option value="84">+84</Option>
        <Option value="1808">+1808</Option>
        <Option value="967">+967</Option>
        <Option value="681">+681</Option>
        <Option value="260">+260</Option>
        <Option value="255">+255</Option>
        <Option value="263">+263</Option>
      
      
      </Select>,
    );
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '91',
    })(
      <Select style={{ width: 110 }}>
     <Option value="86">+86</Option>
        <Option value="87">+87</Option>
        <Option value="7840">+7 840</Option>
        <Option value="7940">+7 940</Option>
        <Option value="93">+93</Option>
        <Option value="35818">+358 18</Option>
        <Option value="355">+355</Option>
        <Option value="213">+213</Option>
        <Option value="1684">+1 684</Option>
        <Option value="376">+376</Option>
        <Option value="244">+244</Option>
        <Option value="1264">+1 264</Option>
        <Option value="1268">+1 268</Option>
        <Option value="54">+54</Option>
        <Option value="374">+374</Option>
        <Option value="297">+297</Option>
        <Option value="247">+247</Option>
        <Option value="61">+61</Option>
        <Option value="6721">+672 1</Option>
        <Option value="672">+672</Option>
        <Option value="43">+43</Option>
        <Option value="994">+994</Option>
        <Option value="1242">+1 242</Option>
        <Option value="973">+973</Option>
        <Option value="880">+880</Option>
        <Option value="1246">+1246</Option>
        <Option value="1248">+1268</Option>
        <Option value="375">+375</Option>
        <Option value="32">+32</Option>
        <Option value="501">+501</Option>
        <Option value="229">+229</Option>
        <Option value="1441">+1441</Option>
        <Option value="975">+975</Option>
        <Option value="591">+591</Option>
        <Option value="5997">+5997</Option>
        <Option value="387">+387</Option>
        <Option value="267">+267</Option>
        <Option value="55">+55</Option>
        <Option value="246">+246</Option>
        <Option value="1284">+1284</Option>
        <Option value="673">+673</Option>
        <Option value="359">+359</Option>
        <Option value="226">+226</Option>
        <Option value="257">+257</Option>
        <Option value="855">+855</Option>
        <Option value="237">+237</Option>
        <Option value="1">+1</Option>
        <Option value="238">+238</Option>
        <Option value="5993">+599 3</Option>
        <Option value="5994">+5994</Option>
        <Option value="5997">+5997</Option>
        <Option value="1345">+1345</Option>
        <Option value="236">+236</Option>
        <Option value="235">+235</Option>
        <Option value="64">+64</Option>
        <Option value="56">+56</Option>
        <Option value="6189164">+6189164</Option>
        <Option value="6189162">+6189162</Option>
        <Option value="57">+57</Option>
        <Option value="269">+269</Option>
        <Option value="243">+243</Option>
        <Option value="242">+242</Option>
        <Option value="682">+682</Option>
        <Option value="506">+506</Option>
        <Option value="225">+225</Option>
        <Option value="385">+385</Option>
        <Option value="53">+53</Option>
        <Option value="5399">+5399</Option>
        <Option value="5999">+5999</Option>
        <Option value="357">+357</Option>
        <Option value="420">+420</Option>
        <Option value="45">+45</Option>
        <Option value="246">+246</Option>
        <Option value="243">+243</Option>
        <Option value="1809">+1809</Option>
        <Option value="1829">+1829</Option>
        <Option value="1849">+1849</Option>
        <Option value="670">+670</Option>
        <Option value="56">+56</Option>
        <Option value="20">+20</Option>
        <Option value="503">+503</Option>
        <Option value="8812">+8812</Option>
        <Option value="8813">+8813</Option>
        <Option value="88213">+88213</Option>
        <Option value="240">+240</Option>
        <Option value="291">+291</Option>
        <Option value="372">+372</Option>
        <Option value="251">+251</Option>
        <Option value="500">+500</Option>
        <Option value="298">+298</Option>
        <Option value="679">+679</Option>
        <Option value="358">+358</Option>
        <Option value="33">+33</Option>
        <Option value="596">+596</Option>
        <Option value="594">+594</Option>
        <Option value="689">+689</Option>
        <Option value="241">+241</Option>
        <Option value="220">+220</Option>
        <Option value="49">+49</Option>
        <Option value="995">+995</Option>
        <Option value="230">+230</Option>
        <Option value="49">+49</Option>
        <Option value="350">+350</Option>
        <Option value="881">+881</Option>
        <Option value="8819">+8819</Option>
        <Option value="8818">+8818</Option>
        <Option value="30">+30</Option>
        <Option value="1473">+1473</Option>
        <Option value="299">+299</Option>
        <Option value="590">+590</Option>
        <Option value="1671">+1671</Option>
        <Option value="502">+502</Option>
        <Option value="441481">+441481</Option>
        <Option value="447781">+447781</Option>
        <Option value="447839">+447839</Option>
        <Option value="447911">+447911</Option>
        <Option value="224">+224</Option>
        <Option value="245">+245</Option>
        <Option value="509">+509</Option>
        <Option value="592">+592</Option>
        <Option value="504">+504</Option>
        <Option value="852">+852</Option>
        <Option value="36">+36</Option>
        <Option value="354">+354</Option>
        <Option value="8810">+8810</Option>
        <Option value="8811">+8811</Option>
        <Option value="91">+91</Option>
        <Option value="62">+62</Option>
        <Option value="870">+870</Option>
        <Option value="800">+800</Option>
        <Option value="882">+882</Option>
        <Option value="883">+883</Option>
        <Option value="979">+979</Option>
        <Option value="98">+98</Option>
        <Option value="964">+964</Option>
        <Option value="353">+353</Option>
        <Option value="8816">+8816</Option>
        <Option value="8817">+8817</Option>
        <Option value="441624">+441624</Option>
        <Option value="447524">+447524</Option>
        <Option value="447624">+447624</Option>
        <Option value="447924">+447924</Option>
        <Option value="972">+972</Option>
        <Option value="39">+39</Option>
        <Option value="1876">+1876</Option>
        <Option value="47">+47</Option>
        <Option value="81">+81</Option>
        <Option value="441534">+441534</Option>
        <Option value="942">+942</Option>
        <Option value="77">+77</Option>
        <Option value="76">+76</Option>
        <Option value="254">+254</Option>
        <Option value="686">+686</Option>
        <Option value="850">+850</Option>
        <Option value="82">+82</Option>
        <Option value="383">+383</Option>
        <Option value="37744">+37744</Option>
        <Option value="37745">+37745</Option>
        
        <Option value="38643">+38643</Option>
        <Option value="38649">+38649</Option>
        <Option value="38128">+38128</Option>
        <Option value="38129">+38129</Option>
        <Option value="38138">+38138</Option>
        <Option value="38139">+38139</Option>
        <Option value="965">+965</Option>
        <Option value="996">+996</Option>
        <Option value="856">+856</Option>
        <Option value="371">+371</Option>
        <Option value="961">+961</Option>
        <Option value="266">+266</Option>
        <Option value="231">+231</Option>
        <Option value="218">+218</Option>
        <Option value="423">+423</Option>
        <Option value="370">+370</Option>
        <Option value="352">+352</Option>
        <Option value="853">+853</Option>
        <Option value="389">+389</Option>
        <Option value="261">+261</Option>
        <Option value="265">+265</Option>
        <Option value="60">+60</Option>
        <Option value="960">+960</Option>
        <Option value="223">+223</Option>
        <Option value="356">+356</Option>
        <Option value="692">+692</Option>
        <Option value="596">+596</Option>
        <Option value="222">+222</Option>
        <Option value="230">+230</Option>
        <Option value="262269">+262269</Option>
        <Option value="262639">+262639</Option>
        <Option value="52">+52</Option>
        <Option value="691">+691</Option>
        
        <Option value="1808">+1808</Option>
        <Option value="373">+373</Option>
        <Option value="377">+377</Option>
        <Option value="382">+382</Option>
        <Option value="976">+976</Option>
        <Option value="1664">+1664</Option>
        <Option value="258">+258</Option>
        <Option value="95">+95</Option>
        <Option value="212">+212</Option>
        <Option value="374">+374</Option>
        <Option value="95">+95</Option>
        <Option value="37447">+37447</Option>
        <Option value="37449">+37449</Option>
        <Option value="264">+264</Option>
        <Option value="674">+674</Option>
        <Option value="977">+31</Option>
        <Option value="1869">+1869</Option>
        <Option value="687">+687</Option>
        <Option value="64">+64</Option>
        <Option value="505">+505</Option>
        <Option value="227">+227</Option>
        <Option value="234">+234</Option>
        <Option value="683">+683</Option>
        <Option value="6723">+6273</Option>
        <Option value="90392">+90392</Option>
        <Option value="4428">+4428</Option>
        <Option value="1670">+1670</Option>
        <Option value="47">+47</Option>
        <Option value="968">+968</Option>
        <Option value="92">+92</Option>
        <Option value="680">+680</Option>
        <Option value="970">+970</Option>
        <Option value="507">+507</Option>
        <Option value="675">+675</Option>
        <Option value="595">+595</Option>
        <Option value="51">+51</Option>
        <Option value="63">+63</Option>
        <Option value="64">+64</Option>
        <Option value="48">+48</Option>
        <Option value="351">+351</Option>
        <Option value="1787">+1787</Option>
        <Option value="1939">+1939</Option>
        <Option value="974">+974</Option>
        <Option value="262">+262</Option>
        <Option value="40">+40</Option>
        <Option value="7">+7</Option>
        <Option value="250">+250</Option>
        <Option value="5994">+5994</Option>
        <Option value="590">+590</Option>
        <Option value="290">+290</Option>
        <Option value="1869">+1869</Option>
        <Option value="1758">+1758</Option>
        <Option value="590">+590</Option>
        <Option value="508">+508</Option>
        <Option value="1784">+1748</Option>
        <Option value="685">+685</Option>
        <Option value="378">+378</Option>
        <Option value="239">+239</Option>
        <Option value="966">+966</Option>
        <Option value="221">+221</Option>
        <Option value="381">+381</Option>
        <Option value="248">+248</Option>
        <Option value="232">+232</Option>
        <Option value="65">+65</Option>
        <Option value="5993">+593</Option>
        <Option value="1721">+1721</Option>
        <Option value="421">+421</Option>
        <Option value="677">+677</Option>
        <Option value="386">+386</Option>
        <Option value="252">+252</Option>
        <Option value="27">+27</Option>
        <Option value="500">+500</Option>
        <Option value="995">+995</Option>
        <Option value="211">+211</Option>
        <Option value="34">+34</Option>
        <Option value="94">+94</Option>
        <Option value="249">+249</Option>
        <Option value="597">+597</Option>
        <Option value="4779">+4779</Option>
        <Option value="268">+268</Option>
        <Option value="46">+46</Option>
        <Option value="41">+41</Option>
        <Option value="963">+963</Option>
        <Option value="886">+886</Option>
        <Option value="992">+992</Option>
        <Option value="255">+255</Option>
        <Option value="888">+888</Option>
        <Option value="66">+66</Option>
        <Option value="88216">+88216</Option>
        <Option value="228">+228</Option>
        <Option value="690">+690</Option>
        <Option value="676">+676</Option>
        <Option value="3732">+3732</Option>
        <Option value="3735">+3735</Option>
        <Option value="1868">+1868</Option>
        <Option value="2908">+2908</Option>
        <Option value="216">+216</Option>
        <Option value="90">+90</Option>
        <Option value="993">+993</Option>
        <Option value="1649">+1649</Option>
        <Option value="688">+688</Option>
        <Option value="256">+256</Option>
        <Option value="380">+380</Option>
        <Option value="971">+971</Option>
        <Option value="44">+44</Option>
        <Option value="1">+1</Option>
        <Option value="878">+878</Option>
        <Option value="598">+598</Option>
        <Option value="1340">+1340</Option>
        <Option value="998">+998</Option>
        <Option value="678">+678</Option>
        <Option value="58">+58</Option>
        <Option value="39">+39</Option>
        <Option value="84">+84</Option>
        <Option value="1808">+1808</Option>
        <Option value="967">+967</Option>
        <Option value="681">+681</Option>
        <Option value="260">+260</Option>
        <Option value="255">+255</Option>
        <Option value="263">+263</Option>
      </Select>,
    );

    const websiteOptions = autoCompleteResult.map(website => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));

    return (
      <div>
      
      <div class="container-fluid">
          <br/><br/>
          <div style={{ fontSize: '22px', color: 'green',paddingLeft:'490px'}}>{this.state.newsuccess}</div>
          <div style={{ fontSize: '16px', color: '#1890ff',paddingLeft:'490px'}}>{this.state.infoMsg}</div>
          <br/>

  <Form {...formItemLayout} onSubmit={this.handleSubmit}>
<div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
  <Form.Item
          label={
            <span>
            Contact person 1&nbsp;
          
            </span>
          }
        >
          {getFieldDecorator('contactPerson', {
            rules: [{pattern:/^[A-Za-z\s]+$/,message: 'Please enter the name', whitespace: true, required: true}],
          })(<Input id="contactp" name="contactp" disabled={this.state.displayContact1} />)}
        </Form.Item>
        </div>
 
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
       
        <Form.Item label={<span>Contact person 2
          
        </span>
        }>
          {getFieldDecorator('contactPerson2', {
            rules: [{ pattern:/^[A-Za-z\s]+$/,required: true, message: 'Please enter the last name!' }],
          })(<Input id="contactp2" name="contactp2" disabled={this.state.displayContact2} />)}
        </Form.Item>
        </div>
 </div>  
 {/* End of 1st Row */}
 <div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
  <Form.Item
          label={
            <span>
            Lastname&nbsp;
          
            </span>
          }
        >
          {getFieldDecorator('contactPerson1LastName', {
            rules: [{ pattern:/^[A-Za-z\s]+$/,required: true, message: 'Please enter the name!' }],
          })(<Input id="contactp1lastname" name="contactp1lastname" disabled={this.state.displayContact1}/>)}
           </Form.Item>

        </div>
 
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
       
        <Form.Item label={<span>LastName
        </span>
        }>
          {getFieldDecorator('contactPerson2LastName', {
            rules: [{ pattern:/^[A-Za-z\s]+$/,required: true, message: 'Please enter the name!' }],
          })(<Input id="contactp2lastname" name="contactp2lastname" disabled={this.state.displayContact2} />)}
        </Form.Item>
        </div>
 </div>  


 <div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
  <Form.Item
          label={
            <span>
            Designation&nbsp;
           
            </span>
          }
        >
          {getFieldDecorator('designation', {
            rules: [{ pattern:/^[A-Za-z\s]+$/,required: true, message: 'Please input the designation!', whitespace: true }],
          })(<Input onChange={this.handleChange} id="designation" name="designation" disabled={this.state.displayDesignation1} />)}
        </Form.Item>
       
        </div>
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
  <Form.Item
          label={
            <span>
            Designation&nbsp;
          
            </span>
          }
        >
          {getFieldDecorator('designation2', {
            rules: [{pattern:/^[A-Za-z\s]+$/, required: true, message: 'Please input the designation!', whitespace: true }],
          })(<Input onChange={this.handleChange} id="designation2" name="designation2" disabled={this.state.displayDesignation2} />)}
        </Form.Item>
        </div>
 </div> 
{/* End of 2nd Row       */}

 <div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
<Form.Item label={<span>E-mail&nbsp;
    <Tooltip title="As per registration">
        <Icon type="question-circle-o" />
    </Tooltip>
    </span>
    }
    >
          {getFieldDecorator('email', {
            rules: [
              {
                pattern:  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ],
          })(<Input onChange={this.handleChange} disabled={this.state.displayEmail1} id="email" name="email" />)}
        </Form.Item>
       
        </div>
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
        <Form.Item label={<span>E-mail&nbsp;
    <Tooltip title="As per registration">
        <Icon type="question-circle-o" />
    </Tooltip>
    </span>
    }
    >
          {getFieldDecorator('email2', {
            rules: [
              {
                pattern:  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,
                message: 'The input is not valid E-mail!',
                
                           },
             
              {
               required: true,
                message: 'Please input your E-mail!',
              },
            ],
          })(<Input onChange={this.handleChange} disabled={this.state.displayEmail1} id="email" name="email" />)}
        </Form.Item>
        </div>
 </div>
{/* End of 2nd Row */}
       

       <div class="row">
       <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
      
       
       <Form.Item label={<span>Phone Number&nbsp;
        <Tooltip title="As per registration">
          <Icon type="question-circle-o" />
      </Tooltip>
   </span>
   }>
         {getFieldDecorator('phone', {
           rules: [{pattern:/^[0-9][0-9]{2,12}$/, required: true, message: 'Please enter a valid phone number!' }],
         })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} disabled={this.state.displayPhn1} onChange={this.handleChange} id="phone" name="phone" />)}
       </Form.Item>
       </div>

       <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
       
       <Form.Item label={<span>Phone Number&nbsp;
       <Tooltip title="As per registration">
          <Icon type="question-circle-o" />
      </Tooltip>
      </span>
       }>
         {getFieldDecorator('phone2', {
           rules: [{pattern:/^[0-9][0-9]{2,12}$/,required: true, message: 'Please enter a valid phone number!' }],
         })(<Input addonBefore={prefixSelector2} style={{ width: '100%' }} disabled={this.state.displayPhn2}  onChange={this.handleChange} id="phone2" name="phone2" />)}
       </Form.Item>
       </div>

 </div> 

 {/* End of 4th row */}
<div class="row" > 
<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
           
          </div>
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
          <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit"  className={this.state.buttonDisplay}>
            Save 
          </Button>
        </Form.Item> 
          </div>
        </div>

        {/* End of 6th row */}

 

 <div class="row">
        <div class="col-xs-4  col-sm-4 col-md-4 col-lg-4">
          </div>
         
          <div class="col-xs-4  col-sm-4 col-md-4 col-lg-4">
          </div>
     </div>





      </Form>  
</div>
<Footer/>
</div> 
// Final Div
     
    );
  }
}
const AgencyRegistrationForm = Form.create({ name: 'register' })(AgencyContactInformation);

export default AgencyRegistrationForm;
//ReactDOM.render(<WrappedRegistrationForm />, document.getElementById('container'));
          