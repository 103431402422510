/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Sanobar Golandaj
@Description:UI for delivery format under create campaign
*/

import React from 'react';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import Footer from '../layouts/footer'; 
import Navigation from '../layouts/navPage';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3766-specification VAPT api call
/*@author Narendra phadke
*use for fetch url value
*/
const queryString = require('query-string');

class EditDeliveryFormat extends React.Component
{

 
  constructor() {
    super();
    
    this.state = {
      fields: {},
      campID:'',
      companyName:'Yes',
      campaignID:'Yes',
      assetName:'Yes',
      pID:'Yes',
      assetId:'Yes',
      leadInteractionDate:'Yes',
      firstName:'Yes',
      lastName:'Yes',
      jobTitleDelivery:'Yes',
      jobLevelDelivery:'',
      jobFunctionDelivery:'',
      assetName:'Yes',
      address:'Yes',
      email:'Yes',
      workPhone:'Yes',
      street:'Yes',
      city:'Yes',
      state:'Yes',
      countryDelivery:'Yes',
      zipCode:'Yes',
      companyEmployeeSize:'Yes',
      companyRevenue:'Yes',
      industry:'Yes',
      ip:'Yes',
      optInDate:'Yes',
      optInTime:'Yes',
      optInTimeZone:'Yes',
      linkedIn:'Yes',
      extra1:'Yes',
      extra2:'Yes',
      extra3:'Yes',
      extra4:'Yes',
      extra5:'Yes',
      editDeliveryFormat:[],
     //isChecked: false,
      errors: {}
    }

   // this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleChangeCamp = this.handleChangeCamp.bind(this);
    this.submitDeliveryFormat = this.submitDeliveryFormat.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleBackButton=this.handleBackButton.bind(this);
    this.handleCheckedCustom=this.handleCheckedCustom.bind(this);
  };

 /**
       * @author Narendra Phadke
       * @param  Description handle the for Back Button Call
       * @return Description return Back Button
       */
      handleBackButton(e){

        var parsed = queryString.parse(this.props.location.search);
        var campID=parsed.campID;
        window.location.href = '/edit?campID='+campID +'';
        
        }

    /**
       * @author Narendra Phadke
       * @param  Description handle the for checkbox selected dynamic
       * @return Description return All details of delivery format
       */
      handleChecked (e) {
        const {name, value } = e.target;
      let editDeliveryFormat = [...this.state.editDeliveryFormat];
      if(e.target.checked=== true){
        editDeliveryFormat[0] = { ...editDeliveryFormat[0], [name]: 'Yes' };
        }else{
          editDeliveryFormat[0] = { ...editDeliveryFormat[0], [name]: 'No' };
        }
        this.setState({ editDeliveryFormat });
      }

       /**
   * @author Narendra Phadke
   * @param  Description handle the for checkbox selected dynamic
   * @return Description return All details of delivery format
   */
  handleCheckedCustom (e) {
    const { name, value } = e.target;
      
    let editDeliveryFormat = [...this.state.editDeliveryFormat];
    editDeliveryFormat[0] = { ...editDeliveryFormat[0], [name]: value };
    this.setState({ editDeliveryFormat });
        }
  
    handleChangeCamp(e) {
       this.setState({ campID: e.target.value });
     }

/**
* @author Narendra Phadke
* @param  Description handle edit handle check
* @return Description return handle check function
*/
   handleChangeCheck(e) {
   
  let fields = this.state;
  fields[e.target.name] = e.target.value;
  if (fields[e.target.name].isChecked) {
    fields[e.target.name] = 'Yes'
  } else {
    fields[e.target.name] = 'No'
  }

  this.setState({isChecked: !this.state.isChecked});
  alert(e.target.name+":"+fields[e.target.name]+":"+e.target.value+":"+fields[e.target.name].isChecked);
  }
 
  

  submitDeliveryFormat(e) {
    //debugger;
    e.preventDefault();
  
     /*@author Narendra phadke
   *Desc Fetch campID from Url
   * dependencies query-string
   */
    var parsed = queryString.parse(this.props.location.search);
  var campID=parsed.campID;
  let data = {
    editDeliveryFormat: this.state.editDeliveryFormat[0],
   }
  console.log("DynamiC Data : " + JSON.stringify(data));
 
  fetch("/campaign/editDeliveryFormat", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    
    body: JSON.stringify(data)
  }).then(function (response) {
    if (response.status >= 400) {
      throw new Error("Bad response from server");
    }
    return response.json();
  }).then(function (response) {
    console.log("Return with response");
    //var campID=response.body.campID;
    console.log(response);
    var campID = response.campID;
    console.log(campID);
    // alert("Successfully Campaign Delivery Format");
    if(response.success==true)
    {
      var a=(response.message);
      //  alert("Backend=====>"+response.message);
      var newSuccess3=document.getElementById("newSuccessDelivery");
      newSuccess3.innerHTML=a;
    
    }
    //send campID to new page
    // window.location.href = '/editFinishCampaign?campID=' + campID + '';
    if (data === "success") {
      console.log("Inside if loop");
      this.refs.msg.show('Some text or component', {
        time: 2000,
        type: 'success',
        icon: <img src="path/to/some/img/32x32.png" />
      })
    }
  }).catch(function (err) {
    console.log(err)
  });

}

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

   if (!fields["campaignName"]) {
      formIsValid = false;
      errors["campaignName"] = "*Required.";
    }
    if (!fields["leadAllocation"]) {
      formIsValid = false;
      errors["leadAllocation"] = "*Required.";
    }
    if (!fields[" publisherCampID"]) {
      formIsValid = false;
      errors[" publisherCampID"] = "*Required.";
    }



   
    this.setState({
    
      errors: errors
    });
    return formIsValid;


  }
  /**
 * @author Narendra Phadke
 * @param  Description handle edit campaign delivery format details
 * @return Description return deleivery campaign details function
 */
    componentDidMount() {
      if(!this.props.auth.isAuthenticated) {
        this.props.history.push('/userLogin');
      }else{
        let self = this;
        var campID = window.location.href.split("=")[window.location.href.split("?").length - 1];
        //alert(campID);
        fetch("campaign/deliveryFormatEdit?campID=" + campID + "")
            .then(res => res.json())
            .then(editDeliveryFormat =>
                self.setState({
                    editDeliveryFormat: editDeliveryFormat
                })
            );

            var parsed = queryString.parse(this.props.location.search);
            var campID = parsed.campID;
        // alert("Camp ID="+campID);
          fetch("campaign/CampaignDetails?campID="+campID+"")
          .then(res => res.json())
          .then(detailsOfCampaign => {
           //alert("Level="+JSON.stringify(detailsOfCampaign));
           if(detailsOfCampaign[0].jobLevel==="")
           {
            this.setState({
              detailsOfCampaign: detailsOfCampaign,
              jobLevelDelivery:"No"
            })
           }else{
            this.setState({
              jobLevelDelivery:"Yes"
            })
           }
           
           if(detailsOfCampaign[0].jobFunction=="")
           {
            this.setState({
              jobFunctionDelivery:"No"
            })
           }
           else{
            this.setState({
              jobFunctionDelivery:"Yes"
            })
           }
         //  alert("Level="+this.state.jobLevelDelivery+" Function="+this.state.jobFunctionDelivery);
            });
              }
    }

  render()
 {
   /*@author Narendra phadke
   *Desc Fetch campID from Url
   * dependencies query-string
   */
  var parsed = queryString.parse(this.props.location.search);
  var campID=parsed.campID;
 console.log("Control on react page");
 console.log(campID);



 return (
  <div>
    <Navigation />
    <div class="row"  style={{paddingTop:"90px",paddingBottom:'6px',backgroundColor:'white',border:'1px solid',borderColor:'#bfc7cc',width:'110%',position:'fixed',zIndex:'1'}}>
        <div class=" col-lg-4" style={{paddingLeft:'150px'}}><button className="btn  btn-primary btn-block" type="button" onClick={this.handleBackButton} style={{width:'min-content',height:'26px',fontSize:'11px'} }>Back to edit</button></div>
        <div class= "col-lg-4" ><label class="labelfont" >STEP 3 : Delivery Format(Campaign ID:{parsed.campID})</label></div>
        <div class=" col-lg-4" class="createbutton"><button class="btn  btn-primary btn-block" type="submit" form="deliveryformatedit" style={{width:'min-content',height:'26px',fontSize:'11px'} }>Save</button>
    </div>
  </div>

    {/* <div class="col" align="center" style={{paddingTop:"90px"}}><label id="labelheading" >STEP 3 : Delivery Format(Campaign ID:{parsed.campID})</label></div> */}
    <div class="container-fluid" style={{paddingBottom:'60px',paddingTop:'150px'}}>
    
    <div id="newSuccessDelivery" class="font-weight-bold" style={{color:'green',textAlign:'center',fontSize: '16px'}} ></div>
    <br/>
      <form method="POST" id="deliveryformatedit" name="deliveryFormat" onSubmit={this.submitDeliveryFormat} >
      {this.state.editDeliveryFormat.map(editDeliveryFormat =>{return (
  <div class="row">
    

    

      <div class="col-lg-2">
      <input type="checkbox" id="pID" name="pID" disabled
                defaultChecked= {editDeliveryFormat.pID=== 'Yes'} onChange={this.handleChecked}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Publisher ID</label>
     </div>

      <div class="col-lg-2">
      
      <input type="checkbox" id="campaignID" name="campaignID" disabled
               defaultChecked={editDeliveryFormat.campaignID === 'Yes'} onChange={this.handleChecked}

                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Campaign ID</label>
       </div>


      <div class="col-lg-2">
      <input type="checkbox" id="leadInteractionDate" name="leadInteractionDate" disabled
               defaultChecked={editDeliveryFormat.leadInteractionDate == 'Yes'} onChange={this.handleChecked}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Lead Interaction Date</label>
      </div>

      <div class="col-lg-2">
      <input type="checkbox" id="firstName" name="firstName" disabled
                  defaultChecked={editDeliveryFormat.firstName === 'Yes'} onChange={this.handleChecked}
                    />
                 &nbsp;&nbsp;&nbsp;&nbsp;
               <label id="label">First Name</label>
      </div>

        <div class="col-lg-2">
          <input type="checkbox" id="lastName" name="lastName" onChange={this.handleChecked} disabled
                    defaultChecked={editDeliveryFormat.lastName === 'Yes'}/>
                 
                &nbsp;&nbsp;&nbsp;&nbsp;
       <label id="label">Last Name</label>
        </div>

        


        <div class="col-lg-2">
        <input type="checkbox" id="companyName" name="companyName" onChange={this.handleChecked} disabled
         defaultChecked={editDeliveryFormat.companyName === 'Yes'}/>
               
               &nbsp;&nbsp;&nbsp;&nbsp;
     <label id="label">Company Name</label>
        </div>

        <div class="col-lg-2">
        <input type="checkbox" id="email" name="email"  onChange={this.handleChecked} disabled
                  defaultChecked={editDeliveryFormat.email === 'Yes'} />
                &nbsp;&nbsp;&nbsp;&nbsp;
      <label id="label">Email</label>
        </div>

        <div class="col-lg-2">
        <input type="checkbox" id="workPhone" name="workPhone" onChange={this.handleChecked} disabled
         defaultChecked={editDeliveryFormat.workPhone === 'Yes'} />
                &nbsp;&nbsp;&nbsp;&nbsp;
     <label id="label">Work Phone</label>
        </div>

        <div class="col-lg-2">
      
        <input type="checkbox" id="jobTitleDelivery" name="jobTitle" disabled
        onChange={this.handleChecked}
          defaultChecked={editDeliveryFormat.jobTitle === 'Yes'} /> 
                &nbsp;&nbsp;&nbsp;&nbsp;
      <label id="label">Job Title</label>
        </div>

        <div class="col-lg-2">
        <input type="checkbox" id="address" name="address"
        onChange={this.handleChecked} disabled
                  defaultChecked={editDeliveryFormat.address === 'Yes'}/>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Address</label>
        </div>

        <div class="col-lg-2">
        <input type="checkbox" id="countryDelivery" name="country" disabled
         onChange={this.handleChecked} defaultChecked={editDeliveryFormat.country === 'Yes'} />
                   
                   &nbsp;&nbsp;&nbsp;&nbsp;
         <label id="label">Country</label>
        </div>


        <div class="col-lg-2">
        <input type="checkbox" id="city" value={this.state.city} name="city"  disabled
        onChange={this.handleChecked} defaultChecked={editDeliveryFormat.city === 'Yes'}/>
              &nbsp;&nbsp;&nbsp;&nbsp;
      <label id="label">City</label>
        </div>

        <div class="col-lg-2">
        <input type="checkbox" id="" name="state" onChange={this.handleChecked}
         defaultChecked={editDeliveryFormat.state === 'Yes'} disabled />
                &nbsp;&nbsp;&nbsp;&nbsp;
      <label id="label">State</label>
        </div>

         <div class="col-lg-2">
        <input type="checkbox" id="" name="zipCode" onChange={this.handleChecked} defaultChecked={editDeliveryFormat.zipCode === 'Yes'} disabled />
                &nbsp;&nbsp;&nbsp;&nbsp;
      <label id="label">Zip Code</label>
        </div> 


        <div class="col-lg-2">
        <input type="checkbox" id="companyEmployeeSize" name="companyEmployeeSize"
         onChange={this.handleChecked} defaultChecked={editDeliveryFormat.companyEmployeeSize === 'Yes'} disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
      <label id="label">Company Employee Size</label>
        </div>


        <div class="col-lg-2">
        <input type="checkbox" id="" name="industry" onChange={this.handleChecked}
         defaultChecked={editDeliveryFormat.industry === 'Yes'}  disabled />
                     &nbsp;&nbsp;&nbsp;&nbsp;
      <label id="label">Industry</label>
        </div>


        <div class="col-lg-2">
        <input type="checkbox" id="assetName" name="assetName"
       onChange={this.handleChecked} defaultChecked={editDeliveryFormat.assetName === 'Yes'} disabled />
           &nbsp;&nbsp;&nbsp;&nbsp;
          <label id="label">Asset Name</label>
        </div>

        <div class="col-lg-2">
        {this.state.jobLevelDelivery=='Yes'?
        <input type="checkbox" id="jobLevelDelivery"  name="jobLevel"
        onChange={this.handleChecked}
        value={editDeliveryFormat.jobLevel=this.state.jobLevelDelivery}    defaultChecked
              />:
              <input type="checkbox" id="jobLevelDelivery"  name="jobLevel"
              onChange={this.handleChecked}
              value={editDeliveryFormat.jobLevel=this.state.jobLevelDelivery}    
                    />
           }
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label id="label">Job Level</label>
        </div>


        <div class="col-lg-2">
        {this.state.jobFunctionDelivery=='Yes'?
        <input type="checkbox" id="jobFunctionDelivery"  name="jobFunction"
        onChange={this.handleChecked}
        value={editDeliveryFormat.jobFunction=this.state.jobFunctionDelivery}  defaultChecked={this.state.jobFunctionDelivery}/>:
              <input type="checkbox" id="jobFunctionDelivery"  name="jobFunction"
              value={editDeliveryFormat.jobFunction=this.state.jobFunctionDelivery} onChange={this.handleChecked}
             />
      }
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label id="label">Job Function</label>
        </div>

       

        <div class="col-lg-2">
        <input type="checkbox" id="" name="street" onChange={this.handleChecked} 
        defaultChecked={editDeliveryFormat.street === 'Yes'} disabled />
               
               &nbsp;&nbsp;&nbsp;&nbsp;
      <label id="label">Street</label>
        </div>

       
        <div class="col-lg-2">
        <input type="checkbox" id="" name="companyRevenue" onChange={this.handleChecked} 
        defaultChecked={editDeliveryFormat.companyRevenue === 'Yes'} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
      <label id="label">Company Revenue</label>
        </div>


      

        <div class="col-lg-2">
        <input type="checkbox" id="ip" name="ip" onChange={this.handleChecked} 
        defaultChecked={editDeliveryFormat.ip === 'Yes'} />
                  &nbsp;&nbsp;&nbsp;&nbsp;
      <label id="label">IP</label>
        </div>


        <div class="col-lg-2">
        <input type="checkbox" id="assetId" name="supportDocID"
        onChange={this.handleChecked} defaultChecked={editDeliveryFormat.supportDocID === 'Yes'} 
       />
           &nbsp;&nbsp;&nbsp;&nbsp;
          <label id="label">Asset ID</label>
        </div>

       
        <div class="col-lg-2">
        <input type="checkbox" id="" name="extra1"
         onChange={this.handleChecked} defaultChecked={editDeliveryFormat.extra1 === 'Yes'} />
           &nbsp;&nbsp;&nbsp;&nbsp;
          <label id="label">Extra 1</label>
        </div>

        <div class="col-lg-2">
        <input type="checkbox" id="" name="extra2"
           onChange={this.handleChecked} defaultChecked={editDeliveryFormat.extra2 === 'Yes'}/>
           &nbsp;&nbsp;&nbsp;&nbsp;
          <label id="label">Extra 2</label>
        </div>

        <div class="col-lg-2">
        <input type="checkbox" id="" name="extra3"
          onChange={this.handleChecked} defaultChecked={editDeliveryFormat.extra3 === 'Yes'}/>
           &nbsp;&nbsp;&nbsp;&nbsp;
          <label id="label">Extra 3</label>
        </div>

        <div class="col-lg-2">
        <input type="checkbox" id="" name="extra4"
           onChange={this.handleChecked} defaultChecked={editDeliveryFormat.extra4 === 'Yes'}/>
           &nbsp;&nbsp;&nbsp;&nbsp;
          <label id="label">Extra 4</label>
        </div>

        <div class="col-lg-2">
        <input type="checkbox" id="" name="extra5"
          onChange={this.handleChecked} defaultChecked={editDeliveryFormat.extra5 === 'Yes'}/>
           &nbsp;&nbsp;&nbsp;&nbsp;
          <label id="label">Extra 5</label>
        </div>

        </div>
)})}



      
      <br/>
      
      {this.state.editDeliveryFormat.map((editDeliveryFormat) => {
            return (
      <div class="row">
     
       
        <div class="col-sm-3 col-md-3 col-lg-3">
        
        <label id="label" style={{color:'orange'}}>Custom Question 1</label>
                  <textarea id="customQuestion1" class="form-control" name="customQuestion1"
                    defaultValue={editDeliveryFormat.customQuestion1} onChange={this.handleCheckedCustom} />
        </div>

        <div class="col-sm-3 col-md-3 col-lg-3">
        <label id="label">Answer 1</label>
                <textarea  id="answer1" class="form-control" name="answer1"
                defaultValue={editDeliveryFormat.answer1} onChange={this.handleCheckedCustom}/>
        </div>

        <div class="col-sm-3 col-md-3 col-lg-3">
        <label id="label" style={{color:'orange'}}>Custom Question 2</label>
                  <textarea id="customQuestion2" name="customQuestion2" class="form-control"
                    defaultValue={editDeliveryFormat.customQuestion2} onChange={this.handleCheckedCustom} />
        </div>

        <div class="col-sm-3 col-md-3 col-lg-3">

        <label id="label" >Answer 2</label>
              <textarea  id="answer2" name="answer2" class="form-control"
               defaultValue={editDeliveryFormat.answer2} onChange={this.handleCheckedCustom}/>
        </div>
    
        </div>
            )})}
        
   



{this.state.editDeliveryFormat.map((editDeliveryFormat) => {
return (

            <div class="row">
      
  
        <div class="col-sm-3 col-md-3 col-lg-3">
        <label id="label" style={{color:'orange'}}>Custom Question 3</label>
                  <textarea id="customQuestion3" name="customQuestion3" class="form-control"
                    defaultValue={editDeliveryFormat.customQuestion3} onChange={this.handleCheckedCustom} />
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3">
        <label id="label">Answer 3</label>
                <textarea  id="answer3" name="answer3" class="form-control"
                 defaultValue={editDeliveryFormat.answer3} onChange={this.handleCheckedCustom}/>
        </div>


        <div class="col-sm-3 col-md-3 col-lg-3">
        <label id="label" style={{color:'orange'}}>Custom Question 4</label>
                  <textarea id="customQuestion4" name="customQuestion4" class="form-control"
                    defaultValue={editDeliveryFormat.customQuestion4} onChange={this.handleCheckedCustom} />
        </div>


        <div class="col-sm-3 col-md-3 col-lg-3">
        <label id="label">Answer 4</label>
              <textarea  id="answer4" name="answer4" class="form-control"
              defaultValue={editDeliveryFormat.answer4} onChange={this.handleCheckedCustom}/>
        </div>

        </div>
          
            
            )})}

            {this.state.editDeliveryFormat.map((editDeliveryFormat) => {
return (
            
        <div class="row">
      

       
        <div class="col-sm-3 col-md-3 col-lg-3">
        <label id="label" style={{color:'orange'}}>Custom Question 5</label>
                  <textarea id="customQuestion5" name="customQuestion5" class="form-control"
                    defaultValue={editDeliveryFormat.customQuestion5} onChange={this.handleCheckedCustom} />
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3">
        <label id="label">Answer 5</label>
                <textarea  id="answer5" name="answer5" class="form-control"
                defaultValue={editDeliveryFormat.answer5} onChange={this.handleCheckedCustom}/>
                </div>

                <div class="col-sm-3 col-md-3 col-lg-3">
                <label id="label"  style={{color:'orange'}}>Custom Question 6</label>
                  <textarea id="customQuestion6" name="customQuestion6" class="form-control" 
                    defaultValue={editDeliveryFormat.customQuestion6} onChange={this.handleCheckedCustom} />

                </div>


                <div class="col-sm-3 col-md-3 col-lg-3">
                <label id="label">Answer 6</label>
              <textarea  id="answer6" name="answer6" class="form-control" 
             defaultValue={editDeliveryFormat.answer6} onChange={this.handleCheckedCustom}/>
                </div>
                <div class="col-lg-12">
                  <label id="label">Additional Comments</label>
                                            <textarea id="additionalComments" name="additionalComments" class="form-control" style={{ width: '100%' }}
                                              defaultValue={editDeliveryFormat.additionalComments} onChange={this.handleCheckedCustom} />
                                        
                  </div>

        
         
            </div>
            )})}
<br/>


{/* 
<div class="row">
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
<div class="float-left">
<button className="btn  btn-primary btn-block text-uppercase" onClick={this.handleBackButton} >Back</button>
  </div>
  <div class="float-right">
  <button class="btn  btn-primary btn-block text-uppercase" type="submit">Save and Next</button>
  </div>
</div>
</div> */}

         
        <br></br>
      </form>
    </div>

    <Footer />
  </div>

);
 }
}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      EditDeliveryFormat.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })

export default connect(mapStateToProps, { logoutUser })(withRouter(EditDeliveryFormat));

