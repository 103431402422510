/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sonali Kalke
@Creation Date:10-09-2019
@Description:UI for  allocated campaigns to publisher
*/

import React from "react";
//import { Tabs, Tab } from "react-bootstrap"; Rutuja Task 2978
// import { Progress } from "mdbreact"; Rutuja Task 2978
// import * as $ from "jquery"; Rutuja Task 2978
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom"; Rutuja Task 2978
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import "./publisherAllocation.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3714-vapt header and query params

const europeRegion = [
	"Eastern Europe",
	"Northern Europe",
	"Southern Europe",
	"Western Europe",
];
var regionArray = [];
var tempCampID;
var result = [];
var displayresult = [];
var publisherCount = 0;
var displayallocatedLead = [];
var displaycpl = [];
let totalLeadDomain = 0;
// var total; //Rutuja Task 2978
var noOfLeadPerDomain;
// var abmCount; Rutuja Task 2978
const Swal = require("sweetalert2");

class PublisherAllocation extends React.Component {
	constructor() {
		super();
		this.state = {
			newsuccess: "",
			fields: {},
			dynamic: [],
			campCount: [],
			gdprPublisher: [],
			otherDetails: [],
			abmDetails: [],
			pendingCampaignCount: "",
			suppresionDetails: [],
			exclusionDetails: [],
			region: false,
			sumOfAllocatedLeads: [],
			assetLinkArray: [],
			campID: " ",
			campIDTemp: " ",
			parentCampID: "",
			reallocationID: "",
			allocatedLeadDomain: "",
			id: "",
			leadAllocation: 0,
			stateCopy: [
				{
					allocatedLead: "",
					cpl: "",
					campCPL: "",
				},
			],
			newDynamicArray: [],
			newGDPRPubArray: [],
			gdprStatus: "false",
			campaignName: "",
			requiredLeadPerAsset: "",
			btnStatus: "",
			pendingLead: 0,
			campaignVisibleTime: "",
			campAcceptReject: "",
			campaignDetailAllocates: [],
			campaignDetailAssign: [],
			campaignDetailID: [],
			liveCampaignList: [],
			onClickAllocatedLead: [],
			onClickAllocatedLeadPubList: [],
			assignDeliveredLead: [],
			errors: {},
			errors1: "",
			alertDisplay: "none",
			alertBorder: "",
			alertWidth: "",
			alertAlign: "",
			spanSpaceDisplay: "none",
			success: "",
			puberror: "",
			allocerrors: "",
			reerrors: "",
			dateerrors: "",
			agencyID: "",
			token: "", // shivani-task 2993-declare token to set token for API authentication to pass in every fetch header.
			show: false,
			campaignBriefDetails: [],
			campaignBriefDeliveryDetails: [],
			campaignBriefCustomQuestionDetails: [],
			campaignBriefSupportingDocumentAssetDetails: [],
			campaignBriefSupportingDocumentAbmDetails: [],
			campaignBriefSupportingDocumentSuppresionDetails: [],
			campaignBriefSupportingDocumentExclusionDetails: [],
			campaignBriefSupportingDocumentOtherDetails: [],
			publisherCampCPL: [],
			mergedCampaignDetailAssign: [],
			mergedGdprPublisher: [],
			noOfLeadPerDomain: "",
			abmCount: "",
		};
		this.isGDPRPUB = this.isGDPRPUB.bind(this);
		this.handleChangeDynamicPublisher =
			this.handleChangeDynamicPublisher.bind(this);
		this.handleRefreshPage = this.handleRefreshPage.bind(this);
		this.submitPublisher = this.submitPublisher.bind(this);
		this.submitGDPRPublisher = this.submitGDPRPublisher.bind(this);
	} // end of constructor

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			// karan-task-3714-vapt header and query params
			var parsed = this.props.location.state;
			var campID;
			if (parsed !== undefined) {
				campID = parsed.campID;
				const {  user } = this.props.auth; //Rutuja Task 2978 Removed isAuthenticated
				const token = user.token; //shivani-task 2993- added code to declare token and using this token to authenticate the api.
				var agencyID = user.id;
				this.setState({ agencyID: agencyID, token }); //shivani-task 2993- added code to setState the token to increase api security.

				tempCampID = campID;

				let data = {
					campID: campID,
				}; // karan-task-3714-vapt header and query params
				let self = this;

				fetch("/publisher/allocatingCampaignID?campID=" + campID)
					.then((res2) => res2.json())
					.then((campaignDetailID) => {
						var abmCount = 0;
						this.setState({ campaignDetailID: campaignDetailID });
						noOfLeadPerDomain =
							this.state.campaignDetailID[0].noOfLeadPerDomain;
						abmCount = this.state.campaignDetailID[0].abmCount;
						totalLeadDomain = noOfLeadPerDomain * abmCount;
						if (campaignDetailID && this.state.campaignDetailID.length) {
							var region = this.state.campaignDetailID[0].region;
							//  var str_array = "India"//Sandeep task-3530-removed old code as its getting issue for gdpr publisher
							var str_array = region.split(","); //Sandeep task-3530-added new code for GDPR pub issue
							for (var i = 0; i < str_array.length; i++) {
								regionArray.push(str_array[i]);
							}
							for (var j = 0; j < regionArray.length; j++) {
								if (
									regionArray[j].toString() === "Europe" || //Rutuja 2978 added tostring and changed == to === 
									europeRegion.includes(regionArray[j])
								) {
									this.setState({ gdprStatus: "true", region: true });
								}
							}
						}
					});

			//	total = this.state.campaignDetailID.leadAllocation; //Rutuja Task 2978

				/**
				 * @author Supriya Gore
				 * @param  Description  use in allocating leads to publisher for checking status and view CPL edditable or not
				 * @return Description return CPL and publisher ID and status
				 */

				fetch("agency/assignPublisher", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res1) => res1.json())
					.then((campaignDetailAssign) => {
						self.setState({ campaignDetailAssign: campaignDetailAssign });

						fetch("agency/publisherCampaignCPL", {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							}, // karan-task-3714-vapt header and query params
							body: JSON.stringify(data),
						})
							.then((res) => res.json())
							.then((publisherCampCPL) => {
								this.setState({ publisherCampCPL: publisherCampCPL });
								if (this.state.publisherCampCPL.length > 0) {
									//Rutuja Task -2978 declared temp var and then used this.setState
									//this.state.newDynamicArray =
										let temp = this.state.campaignDetailAssign.map((e) =>
											Object.assign(
												{},
												e,
												this.state.publisherCampCPL.find(
													(e1) => e1.pID === e.pID
												) || {}
											)
										);
										this.setState({newDynamicArray : temp});
								} else {
									 //Rutuja Task -2978 declared temp var and then used this.setState
									let temp = this.state.campaignDetailAssign.map((e) =>
											Object.assign(
												{},
												e,
												this.state.stateCopy.find((e1) => e1.pID === e.pID) ||
													{}
											)
										);
										this.setState({newDynamicArray : temp});
								}

								const result = Array.from(
									new Set(this.state.campaignDetailAssign.map((p) => p.pID))
								).map((pID) => {
									return {
										pID: pID,
										publisherName: this.state.campaignDetailAssign.find(
											(p) => p.pID === pID
										).publisherName,
										startDate: this.state.campaignDetailAssign.find(
											(p) => p.pID === pID
										).startDate,
										endDate: this.state.campaignDetailAssign.find(
											(p) => p.pID === pID
										).endDate,
										allocatedLead: this.state.campaignDetailAssign.find(
											(p) => p.pID === pID
										).allocatedLead,
										cpl:
											this.state.campaignDetailAssign.find((p) => p.pID === pID)
												.cpl != null
												? this.state.campaignDetailAssign.find(
														(p) => p.pID === pID
												  ).cpl
												: this.state.campaignDetailAssign.find(
														(p) => p.pID === pID
												  ).campCPL,
										firstLeadDeliveryDate: this.state.campaignDetailAssign.find(
											(p) => p.pID === pID
										).firstLeadDeliveryDate,
										rating: this.state.campaignDetailAssign.find(
											(p) => p.pID === pID
										).rating,
										gdprCompliance: this.state.campaignDetailAssign.find(
											(p) => p.pID === pID
										).gdprCompliance,
										poNumber:
											this.state.campaignDetailAssign.find((p) => p.pID === pID)
												.poNumber != null
												? this.state.campaignDetailAssign.find(
														(p) => p.pID === pID
												  ).poNumber
												: this.state.campaignDetailAssign.find(
														(p) => p.pID === pID
												  ).poNumber,
										// Sandeep-task-3530-added code for poNumber
									};
								});

								let mergedCampaignDetailAssign = [];
								// let liveDeliveryStatusListTemp = this.state.sumOfAllocatedLeads;
								mergedCampaignDetailAssign = result.map((e) =>
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
								);
								// this.state.mergedCampaignDetailAssign=mergedCampaignDetailAssign;
								this.setState({
									mergedCampaignDetailAssign: mergedCampaignDetailAssign,
								});
							})
							.catch(function (err) {
								console.log(err);
							});
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				this.props.history.push("/newDashboard");
			} // karan-task-3714-vapt header and query params
		}
	} // componentDidMount()

	submitGDPRPublisher(e) {
		e.preventDefault();
		let errors = {};
		let spanDisplay = "none";
		// let fields = this.state.fields; //Rutuja Task 2978
		let formIsValid = false;
		let date = false;
		let cpl = false;
		let lead = false;
		let povalidate = false;
		// let success = {};//Rutuja Task 2978
		let puberror = {};
		let valid = false;
		let rebudget = false;
		// let leadDomain = false; //Rutuja Task 2978
		var checkAllocation = false;
		let allocerrors = {};
		let reerrors = {};
		let dateerrors = {};
		let errors1 = {};

		// let r1 = []; //Rutuja Task 2978

		result = Array.from(
			new Set(this.state.newGDPRPubArray.map((p) => p.pID))
		).map((pID) => {
			return {
				pID: pID,
				publisherName: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.publisherName,
				startDate: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.startDate,
				endDate: this.state.newGDPRPubArray.find((p) => p.pID === pID).endDate,
				allocatedLead: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.allocatedLead,
				cpl:
					this.state.newGDPRPubArray.find((p) => p.pID === pID).cpl != null
						? this.state.newGDPRPubArray.find((p) => p.pID === pID).cpl
						: this.state.newGDPRPubArray.find((p) => p.pID === pID).campCPL,
				firstLeadDeliveryDate: this.state.newGDPRPubArray.find(
					(p) => p.pID === pID
				).firstLeadDeliveryDate,
				poNumber: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.poNumber,
				//Sandeep-task-3530-added code for poNumber
			};
		});

		console.warn(
			"New newGDPRPubArray Array of the created after ====>" +
				JSON.stringify(result)
		);

		var totalLead = document.getElementById("totalLead").value;

		var totalAllocate = document.getElementById("totalAllocate").value;
		if (totalAllocate === "") {
			totalAllocate = 0;
		}

		var totalBudget = document.getElementById("totalBudget").value;

		var totalRemainingBudget = document.getElementById(
			"totalRemainingBudget"
		).value;

		var totalstartDate = document.getElementById("totalstartDate").value;

		var totalendDate = document.getElementById("totalendDate").value;

		// var totalPending = document.getElementById("totalPending").value; //Rutuja Task 2978

		var sum = 0;
		var num;
		var multiply = 0;
		var num1;
		var num2;
		var addmulti = 0;
		var add2 = 0;

		var add = totalLead - totalAllocate;
		var dynamiclength = result.length;

		for (var i = 0; i < dynamiclength; i++) {
			if (
				result[i].allocatedLead === "" ||
				result[i].allocatedLead === undefined ||
				result[i].allocatedLead === null
			) {
				if (publisherCount === 0) {
					puberror["one"] =
						"Please enter valid lead allocation for atleast one publisher";

					this.setState({
						puberror: puberror,
						allocerrors: "",
						errors: errors,
						newsuccess: "",
						errors1: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				}
			} else {
				publisherCount++;
				puberror["one"] = "";
				this.setState({
					newsuccess: "",
					puberror: "",
					dateerrors: "",
					errors1: "",
					errors: errors,
					alertBorder: "0px solid white",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
					spanSpaceDisplay: spanDisplay,
				});
				if (result[i].startDate <= result[i].endDate) {
					date = true;
					displayresult.splice(i, 1);
				} else {
					date = false;
					displayresult[i] = result[i].publisherName;
					// Sandeep-task-2867-changes in message done for end date validation
					errors["endDate"] =
						"Please enter allocation end date greater than allocation start date for " +
						displayresult[i];
					this.setState({
						newsuccess: "",
						puberror: "",
						errors1: "",
						dateerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				}

				if (result[i].endDate > totalendDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"Allocation end date cannot be greater than campaign end  date for " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors1: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].startDate < totalstartDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					// formIsValid = false;
					// Sandeep-task-2867-changes in message done for start date validation
					dateerrors["date"] =
						"Please enter allocation start date can't be less than campaign start date for " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors1: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].firstLeadDeliveryDate > result[i].endDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"First lead delivery date can't be greater than end date " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors1: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].firstLeadDeliveryDate < result[i].startDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					// Sandeep-task-2867-changes in message done for lead delivery date validation
					dateerrors["date"] =
						"Please enter first lead delivery date greater than start date for " +
						displayresult[i];

					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						errors1: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				var pattern = new RegExp(/^[1-9]+[0-9]*$/);
				if (!pattern.test(result[i].allocatedLead)) {
					lead = false;
					displayallocatedLead[i] = result[i].publisherName;
					errors["allocatedLead"] =
						"Please enter allocation in number for  " + displayallocatedLead[i];

					displayallocatedLead = [displayallocatedLead[i]];
					// var a = displayallocatedLead.includes("displayallocatedLeads[i]");//Rutuja Task 2978

					this.setState({
						newsuccess: "",
						puberror: "",
						errors: errors,
						dateerrors: "",
						errors1: "",
						allocerrors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					return;
				} else {
					lead = true;
					num = parseInt(result[i].allocatedLead);

					sum += num;

					displayallocatedLead.splice(i, 1);
					add = totalLead - totalAllocate;
					var totalAllocate1 = parseInt(totalAllocate);
					add2 = totalAllocate1 + sum;
					//snehal-task-3020-Prod Issue-Upload ABM File Validation Issue When Campaign Type is "Open"
					if (
						totalLeadDomain > 0 &&
						this.state.campaignDetailID[0] === "ABM" //Rutuja 2978  changed == to === 
					) {
						if (add2 > totalLeadDomain) {
							// leadDomain = false;
							displayallocatedLead[i] = result[i].publisherName;
							errors1["allocatedLead1"] =
								"Count(No of lead per domain * domain count) of ABM is not matching with lead allocation ";

							this.setState({
								newsuccess: "",
								puberror: "",
								errors: errors,
								dateerrors: "",
								allocerrors: "",
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});

							return;
						} else {
							// leadDomain = true;
							this.setState({
								newsuccess: "",
								puberror: "",
								errors: "",
								errors1: "",
								dateerrors: "",
								allocerrors: "",
							});
						}
					}
					//  formIsValid = true;
				}

				// cpl validations
				var patterndecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/); //decimal
				// var patternnonzero = new RegExp(/^[1-9]+[0-9]*$/); //numbers except zero //Rutuja Task 2978
				var testval1 = result[i].cpl;

				// var testval = parseInt(testval1); //Rutuja Task 2978

				if (testval1 === "" || testval1 === null || testval1 === undefined) {
					cpl = false;
					displaycpl[i] = result[i].publisherName;

					errors["cpl"] =
						"Please check CPL value. CPL should follow below criteria for " +
						displaycpl[i];

					errors["cpl1"] = <li>CPL should be only number.</li>;
					errors["cpl2"] = <li>CPL should be greater than zero.</li>;
					errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

					this.setState({
						dateerrors: "",
						newsuccess: "",
						puberror: "",
						errors1: "",
						allocerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					if (testval1 === 0) {
						cpl = false;
						displaycpl[i] = result[i].publisherName;

						errors["cpl"] =
							"Please check CPL value. CPL should follow below criteria for " +
							displaycpl[i];

						errors["cpl1"] = <li>CPL should be only number.</li>;
						errors["cpl2"] = <li>CPL should be greater than zero.</li>;
						errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

						this.setState({
							dateerrors: "",
							newsuccess: "",
							puberror: "",
							errors1: "",
							allocerrors: "",
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						if (patterndecimal.test(testval1)) {
							cpl = true;
						} else {
							cpl = false;
							displaycpl[i] = result[i].publisherName;
							errors["cpl"] =
								"Please check CPL value. CPL should follow below criteria for " +
								displaycpl[i];
							errors["cpl1"] = <li>CPL should be only number.</li>;
							errors["cpl2"] = <li>CPL should be greater than zero.</li>;
							errors["cpl3"] = (
								<li>CPL value allowed only 2 decimal places.</li>
							);

							this.setState({
								dateerrors: "",
								newsuccess: "",
								puberror: "",
								errors1: "",
								allocerrors: "",
								errors: errors,
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});
							return;
						}
					}
				}

				// end of cpl validations

				//validation for budget
				num1 = parseInt(result[i].allocatedLead);
				num2 = parseFloat(result[i].cpl);
				multiply = num1 * num2;

				addmulti += multiply;

				// var totalCountOfLead = result[i].allocatedLead; //Rutuja Task 2978

				if (totalRemainingBudget === "") {
					if (addmulti > totalBudget) {
						rebudget = false;

						reerrors["remaining"] =
							"Allocated budget cannot be greater than total budget.\n";
						this.setState({
							puberror: "",
							allocerrors: "",
							errors1: "",
							reerrors: reerrors,
							errors: errors,
							newsuccess: "",
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						reerrors["remaining"] = "";
						this.setState({ reerrors: reerrors });
					}
				} else {
					if (addmulti > totalRemainingBudget) {
						rebudget = false;

						reerrors["remaining"] =
							"Allocated budget cannot be greater than total budget.\n";
						this.setState({
							puberror: "",
							allocerrors: "",
							errors1: "",
							reerrors: reerrors,
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						reerrors["remaining"] = "";
						this.setState({ reerrors: reerrors });
					}
				}
				add = totalLead - totalAllocate;
				if (sum > add) {
					valid = false;

					allocerrors["allocate"] =
						"Allocated leads cannot be greater than total leads";
					this.setState({
						puberror: "",
						allocerrors: allocerrors,
						reerrors: "",
						newsuccess: "",
						errors1: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
				} else {
					valid = true;
				}
			} // end of else
		} // end of for
		//snehal-task-3791-if allocation count is blank that time form will not submitted it gives error
		let filterTemp = result.filter((a) => a.allocatedLead > 0);
		if (filterTemp.length === 0) {
			puberror["one"] =
				"Please enter valid lead allocation for atleast one publisher";

			this.setState({
				puberror: puberror,
				allocerrors: "",
				errors: errors,
				newsuccess: "",
				errors1: "",
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			return;
		}
		//Sandeep-task-3530-added code for poNumber
		let poArray;
		poArray = Array.from(
			new Set(this.state.newGDPRPubArray.map((p) => p.pID))
		).map((pID) => {
			return {
				pID: pID,

				poNumber: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.poNumber,
				isAllocate: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.isAllocate,
			};
		});

		for (var k = 0; k < poArray.length; k++) {
			if (
				poArray[k].isAllocate === "Yes" && //Rutuja 2978 changed == to === 
				(poArray[k].poNumber === "" || //Rutuja 2978 changed == to === 
					poArray[k].poNumber == undefined ||
					poArray[k].poNumber == null)
			) {
				checkAllocation = true;
			} else {
				//checkAllocation = false; //snehal-task-3812-submitform will be false beacuse of this
			}
		}
		//Saurabh-task-3792-PO Number validation
		for (var m = 0; m < result.length; m++) {
			if (
				result[m].poNumber !== "" && //Rutuja 2978 
				result[m].poNumber != null &&
				result[m].poNumber !== undefined  //Rutuja 2978
			) {
				var pattern2 = new RegExp(/^[a-zA-Z\d*]{8,}$/);

				if (!pattern2.test(result[m].poNumber)) {
					povalidate = false;
					puberror["po"] = (
						<li>Please enter minimum eight digit valid po number.</li>
					);
					this.setState({
						puberror: puberror,
						allocerrors: "",
						errors: errors,
						newsuccess: "",
						alertWidth: "1000px",
						alertBorder: "1px solid red",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
					return;
				} else {
					povalidate = true;
				}
			} //end of  if
			//povalidate = true;
		} //end of for
		if (checkAllocation === true || checkAllocation === "true") {
			Swal.fire({
				text: "Please provide PO number (if available) for this campaign",
				icon: "warning",
				type: "warning",
				//timer:"6000" ,//Sandeep task-3530-removing timer from sweetalert
				showCancelButton: true,
				confirmButtonColor: "#193D8F",
				cancelButtonColor: "#193D8F",
				confirmButtonText: "Yes",
				cancelButtonText: "No",
				//showCloseButton: true,
				allowOutsideClick: false,
			}).then((result) => {
				if (result.value === true) {
					//window.location.href = "#";
					//Chaitnya-4564-Prod_Issue_Agency Login_Campaign Allocation to Publisher with PO Number Issue.
					this.setState({
						alertDisplay: "none",
						alertBorder: "0px solid white",
					});

					var campID = document.getElementById("campID").value;
					var leadAllocation = document.getElementById("leadAllocation").value;
				} else {
					formIsValid = true;

					if (formIsValid === true) {
						this.setState({
							alertDisplay: "none",
							alertBorder: "0px solid white",
						});
						let campID = document.getElementById("campID").value; //Rutuja Task 2978 removed decalration var and used let
						let leadAllocation =
							document.getElementById("leadAllocation").value; //Rutuja Task 2978 removed decalration var and used let

						/**
						 * @author Sandeep Dhawale
						 * @param  Description handle the publisher Login Details
						 * @return Description return All publisher Details
						 */
						// karan-task-3714-vapt header and query params
						var parsed = this.props.location.state;
						var key;
						if (parsed !== undefined) {
							key = parsed.key;

							let data = {
								campID: campID,
								clientCampID: this.state.campaignDetailID[0].clientCampID,
								leadAllocation: leadAllocation,
								dynamicArray: this.state.newGDPRPubArray,
							}; // karan-task-3714-vapt header and query params
							console.log("Data : " + JSON.stringify(data));

							//publisher/assignCampaign
							fetch("publisher/assignCampaign", {
								//insert data in publisher_assignment table
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							})
								.then((res) => res.json())
								.then((res) => {
									console.warn("Response in res in==" + JSON.stringify(res));
									if (res.success === true) { //Rutuja Task - 2978
										this.setState({ inputClass: "disabled" });
										console.warn("Response in res==" + JSON.stringify(res));
										Swal.fire({
											text: res.message,
											type: "success",
											confirmButtonText: "Ok",
											allowOutsideClick: false,
											preConfirm: () => {
												// window .location.reload();

												if (key === "active") {
													this.props.history.push("/campaignList", {
														key: "active",
													}); //karan-task-3684-replace query params
												} else {
													this.props.history.push("/campaignList", {
														agencyID: this.state.agencyID,
													}); //karan-task-3684-replace query params
												}
											},
										});
									} else {
									}
								})
								.catch((err) => {
									console.log(err);
								});
						} // karan-task-3714-vapt header and query params
					}
				}
			});

			return;
		} //end of  if

		if (
			date === true &&
			cpl === true &&
			lead === true &&
			valid === true &&
			rebudget === true &&
			povalidate === true
		) {
			formIsValid = true;
		}
		console.log("Errors ====>" + JSON.stringify(this.state.errors));

		if (formIsValid === true) {
			this.setState({ alertDisplay: "none", alertBorder: "0px solid white" });
			var campID = document.getElementById("campID").value;
			var leadAllocation = document.getElementById("leadAllocation").value;

			/**
			 * @author Narendra Phadke
			 * @param  Description handle the publisher Login Details
			 * @return Description return All publisher Details
			 */
			// karan-task-3714-vapt header and query params
			var parsed = this.props.location.state;
			var key;
			if (parsed !== undefined) {
				key = parsed.key;

				let data = {
					campID: campID,
					clientCampID: this.state.campaignDetailID[0].clientCampID,
					leadAllocation: leadAllocation,
					dynamicArray: this.state.newGDPRPubArray,
				}; // karan-task-3714-vapt header and query params
				console.log("Data : " + JSON.stringify(data));

				//publisher/assignCampaign
				fetch("publisher/assignCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						console.warn("Response in res in==" + JSON.stringify(res));
						if (res.success === true) { //Rutuja 2978  changed == to === 
							this.setState({ inputClass: "disabled" });
							console.warn("Response in res==" + JSON.stringify(res));
							Swal.fire({
								text: res.message,
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									// window .location.reload();

									if (key === "active") {
										this.props.history.push("/campaignList", { key: "active" }); //karan-task-3684-replace query params
									} else {
										this.props.history.push("/campaignList", {
											agencyID: this.state.agencyID,
										}); //karan-task-3684-replace query params
									}
								},
							});
						} else {
						}
					})
					.catch((err) => {
						console.log(err);
					});
			} // karan-task-3714-vapt header and query params
		} else {
		}
	}

	submitPublisher(e) {
		e.preventDefault();
		let errors = {};
		let spanDisplay = "none";
		// let fields = this.state.fields;//Rutuja Task 2978 
		let formIsValid = false;
		let date = false;
		let cpl = false;
		let lead = false;
		let povalidate = false;
		// let leadDomain = false;//Rutuja Task 2978 
		var checkAllocation = false;
		// let success = {}; //Rutuja Task 2978 
		let puberror = {};
		let valid = false;
		let rebudget = false;
		let allocerrors = {};
		let reerrors = {};
		let dateerrors = {};
		let errors1 = {};
		//let r1 = []; //Rutuja Task 2978 
		result = Array.from(
			new Set(this.state.newDynamicArray.map((p) => p.pID))
		).map((pID) => {
			return {
				pID: pID,
				publisherName: this.state.newDynamicArray.find((p) => p.pID === pID)
					.publisherName,
				startDate: this.state.newDynamicArray.find((p) => p.pID === pID)
					.startDate,
				endDate: this.state.newDynamicArray.find((p) => p.pID === pID).endDate,
				firstLeadDeliveryDate: this.state.newDynamicArray.find(
					(p) => p.pID === pID
				).firstLeadDeliveryDate,
				allocatedLead: this.state.newDynamicArray.find((p) => p.pID === pID)
					.allocatedLead,
				cpl:
					this.state.newDynamicArray.find((p) => p.pID === pID).cpl != null
						? this.state.newDynamicArray.find((p) => p.pID === pID).cpl
						: this.state.newDynamicArray.find((p) => p.pID === pID).campCPL,
				poNumber: this.state.newDynamicArray.find((p) => p.pID === pID)
					.poNumber,
				//Sandeep-task-3530-added code for poNumber
			};
		});

		var totalLead = document.getElementById("totalLead").value;

		var totalAllocate = document.getElementById("totalAllocate").value;
		if (totalAllocate === "") {
			totalAllocate = 0;
		}

		var totalBudget = document.getElementById("totalBudget").value;
		var totalRemainingBudget = document.getElementById(
			"totalRemainingBudget"
		).value;
		var totalstartDate = document.getElementById("totalstartDate").value;
		var totalendDate = document.getElementById("totalendDate").value;
		// var totalPending = document.getElementById("totalPending").value;//Rutuja Task 2978 
		var sum = 0;
		var num;
		var multiply = 0;
		var num1;
		var num2;
		var addmulti = 0;

		var add = totalLead - totalAllocate;
		var dynamiclength = result.length;
		var add2 = 0;

		for (var i = 0; i < dynamiclength; i++) {
			if (
				result[i].allocatedLead === "" || //Rutuja 2978  changed == to === 
				result[i].allocatedLead == undefined ||
				result[i].allocatedLead == null
			) {
				if (publisherCount === 0) { //Rutuja 2978 changed == to === 
					puberror["one"] =
						"Please enter valid lead allocation for atleast one publisher";

					this.setState({
						puberror: puberror,
						allocerrors: "",
						errors1: "",
						errors: errors,
						alertBorder: "1px solid red",
						newsuccess: "",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				}
			} else {
				publisherCount++;
				puberror["one"] = "";
				this.setState({
					newsuccess: "",
					puberror: "",
					dateerrors: "",
					errors1: "",
					errors: errors,
					alertBorder: "0px solid white",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
					spanSpaceDisplay: spanDisplay,
				});

				if (result[i].startDate <= result[i].endDate) {
					date = true;
					displayresult.splice(i, 1);
				} else {
					date = false;
					displayresult[i] = result[i].publisherName;
					// Sandeep-task-2867-changes in message done for start date validation
					errors["endDate"] =
						"Please enter allocation end date greater than allocation start date for " +
						displayresult[i];
					this.setState({
						newsuccess: "",
						puberror: "",
						dateerrors: "",
						errors1: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					return;
				}

				if (result[i].endDate > totalendDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"Allocation end date cannot be greater than campaign end  date for " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors1: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].startDate < totalstartDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					// Sandeep-task-2867-changes in message done for start date validation
					dateerrors["date"] =
						"Please enter allocation start date can't be less than campaign start date for " +
						displayresult[i];

					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors1: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].firstLeadDeliveryDate > result[i].endDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"First lead delivery date can't be greater than end date " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						errors1: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].firstLeadDeliveryDate < result[i].startDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					// Sandeep-task-2867-changes in message done for lead delivery date validation
					dateerrors["date"] =
						"Please enter first lead delivery date greater than start date for " +
						displayresult[i];

					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						alertBorder: "1px solid red",
						errors: "",
						errors1: "",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				var pattern = new RegExp(/^[1-9]+[0-9]*$/);
				if (!pattern.test(result[i].allocatedLead)) {
					lead = false;
					displayallocatedLead[i] = result[i].publisherName;
					errors["allocatedLead"] =
						"Please enter allocation in number for  " + displayallocatedLead[i];

					displayallocatedLead = [displayallocatedLead[i]];
					// var a = displayallocatedLead.includes("displayallocatedLeads[i]");//Rutuja Task 2978 

					this.setState({
						newsuccess: "",
						puberror: "",
						errors: errors,
						dateerrors: "",
						allocerrors: "",
						errors1: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					return;
				} else {
					lead = true;
					num = parseInt(result[i].allocatedLead);
					sum += num;

					displayallocatedLead.splice(i, 1);
					add = totalLead - totalAllocate;

					let totalAllocate1 = parseInt(totalAllocate);
					add2 = totalAllocate1 + sum;
					//snehal-task-3020-Prod Issue-Upload ABM File Validation Issue When Campaign Type is "Open"
					if (
						totalLeadDomain > 0 &&
						this.state.campaignDetailID[0].ABM.toString() === "ABM" //rutuja 2978 added toString() and changed == to ===
					) {
						if (add2 > totalLeadDomain) {
							// leadDomain = false; //Rutuja Task 2978 
							displayallocatedLead[i] = result[i].publisherName;
							errors1["allocatedLead1"] =
								"Count(No of lead per domain * domain count) of ABM is not matching with lead allocation ";

							this.setState({
								newsuccess: "",
								puberror: "",
								errors1: errors1,
								dateerrors: "",
								allocerrors: "",
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});

							return;
						} else {
							//leadDomain = true;//Rutuja Task 2978 
							this.setState({
								newsuccess: "",
								puberror: "",
								errors1: "",
								errors: "",
								dateerrors: "",
								allocerrors: "",
							});
						}
					}
				}

				// cpl validations
				var patterndecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/); //decimal
				//var patternnonzero = new RegExp(/^[1-9]+[0-9]*$/); //numbers except zero//Rutuja Task 2978 
				var testval1 = result[i].cpl;

				// var testval = parseInt(testval1);//Rutuja Task 2978 

				if (testval1 === "" || testval1 == null || testval1 == undefined) {
					cpl = false;
					displaycpl[i] = result[i].publisherName;
					errors["cpl"] =
						"Please check CPL value. CPL should follow below criteria for " +
						displaycpl[i];

					errors["cpl1"] = <li>CPL should be only number.</li>;
					errors["cpl2"] = <li>CPL should be greater than zero.</li>;
					errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

					this.setState({
						dateerrors: "",
						newsuccess: "",
						puberror: "",
						errors1: "",
						allocerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					if (Number(testval1) === 0) {  //Rutuja 2978 
						cpl = false;
						displaycpl[i] = result[i].publisherName;

						errors["cpl"] =
							"Please check CPL value. CPL should follow below criteria for " +
							displaycpl[i];

						errors["cpl1"] = <li>CPL should be only number.</li>;
						errors["cpl2"] = <li>CPL should be greater than zero.</li>;
						errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

						this.setState({
							dateerrors: "",
							newsuccess: "",
							puberror: "",
							allocerrors: "",
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						if (patterndecimal.test(testval1)) {
							cpl = true;
						} else {
							cpl = false;
							displaycpl[i] = result[i].publisherName;

							errors["cpl"] =
								"Please check CPL value. CPL should follow below criteria for " +
								displaycpl[i];

							errors["cpl1"] = <li>CPL should be only number.</li>;
							errors["cpl2"] = <li>CPL should be greater than zero.</li>;
							errors["cpl3"] = (
								<li>CPL value allowed only 2 decimal places.</li>
							);

							this.setState({
								dateerrors: "",
								newsuccess: "",
								puberror: "",
								allocerrors: "",
								errors: errors,
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});
							return;
						}
					}
				}

				// end of cpl validations

				//validation for budget
				num1 = parseInt(result[i].allocatedLead);
				num2 = parseFloat(result[i].cpl);

				multiply = num1 * num2;

				addmulti += multiply;

				if (totalRemainingBudget === "") {
					if (addmulti > totalBudget) {
						rebudget = false;
						reerrors["remaining"] =
							"Allocated budget cannot be greater than total budget.\n";
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							newsuccess: "",
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						reerrors["remaining"] = "";
						this.setState({ reerrors: reerrors });
					}
				} else {
					if (addmulti > totalRemainingBudget) {
						rebudget = false;
						reerrors["remaining"] =
							"Allocated budget cannot be greater than total budget.\n";
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						reerrors["remaining"] = "";
						this.setState({ reerrors: reerrors });
					}
				}
				add = totalLead - totalAllocate;
				if (sum > add) {
					valid = false;
					allocerrors["allocate"] =
						"Allocated leads cannot be greater than total leads";
					this.setState({
						puberror: "",
						allocerrors: allocerrors,
						reerrors: "",
						newsuccess: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
				} else {
					valid = true;
				}
			} // end of else
		} // end of for
		//snehal-task-3791-if allocation count is blank that time form will not submitted it gives error
		let filterTemp = result.filter((a) => a.allocatedLead > 0);
		if (filterTemp.length === 0) {
			puberror["one"] =
				"Please enter valid lead allocation for atleast one publisher";

			this.setState({
				puberror: puberror,
				allocerrors: "",
				errors1: "",
				errors: errors,
				alertBorder: "1px solid red",
				newsuccess: "",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			return;
		}
		//Sandeep-task-3530-added code for poNumber
		let poArray;
		poArray = Array.from(
			new Set(this.state.newDynamicArray.map((p) => p.pID))
		).map((pID) => {
			return {
				pID: pID,

				poNumber: this.state.newDynamicArray.find((p) => p.pID === pID)
					.poNumber,
				isAllocate: this.state.newDynamicArray.find((p) => p.pID === pID)
					.isAllocate,
				//Sandeep-task-3530-added code for poNumber
			};
		});

		for (var k = 0; k < poArray.length; k++) {
			if (
				poArray[k].isAllocate === "Yes" && //Rutuja 2978
				(poArray[k].poNumber === "" || //Rutuja 2978
					poArray[k].poNumber == undefined ||
					poArray[k].poNumber == null)
			) {
				checkAllocation = true;
			} else {
				//checkAllocation = false;//snehal-task-3812-submitform will be false beacuse of this
			}
		}
		//Saurabh-task-3792-PO Number validation
		for (var m = 0; m < result.length; m++) {
			if (
				result[m].poNumber !== "" && //Rutuja 2978
				result[m].poNumber != null &&
				result[m].poNumber !== undefined //Rutuja 2978
			) {
				var pattern1 = new RegExp(/^[a-zA-Z\d*]{8,}$/);

				if (!pattern1.test(result[m].poNumber)) {
					povalidate = false;
					puberror["po"] = (
						<li>Please enter minimum eight digit valid po number.</li>
					);
					this.setState({
						puberror: puberror,
						allocerrors: "",
						errors: errors,
						newsuccess: "",
						alertWidth: "1000px",
						alertBorder: "1px solid red",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
					return;
				} else {
					povalidate = true;
				}
			} //end of  if

			//povalidate = true;
		} //end of for
		if (checkAllocation === true || checkAllocation === "true") {
			// if(this.state.campaignDetailAssign[i].poNumber==""||this.state.campaignDetailAssign[i].poNumber==null||this.state.campaignDetailAssign[i].poNumber==undefined){

			Swal.fire({
				text: "Please provide PO number (if available) for this campaign",
				icon: "warning",
				type: "warning",
				//timer:"6000" ,//Sandeep task-3530-removing timer from sweetalert
				showCancelButton: true,
				confirmButtonColor: "#193D8F",
				cancelButtonColor: "#193D8F",
				confirmButtonText: "Yes",
				cancelButtonText: "No",
				//showCloseButton: true,
				allowOutsideClick: false,
			}).then((result) => {
				if (result.value === true) {
					//window.location.href = "#";
					//Chaitnya-4564-Prod_Issue_Agency Login_Campaign Allocation to Publisher with PO Number Issue.
					this.setState({
						alertDisplay: "none",
						alertBorder: "0px solid white",
					});

					var campID = document.getElementById("campID").value;
					var leadAllocation = document.getElementById("leadAllocation").value;
				} else {
					formIsValid = true;

					if (formIsValid === true) {
						this.setState({
							alertDisplay: "none",
							alertBorder: "0px solid white",
						});
						//Rutuja Task 2978 removed declration var 
						 campID = document.getElementById("campID").value;
						 leadAllocation =
							document.getElementById("leadAllocation").value;

						/**
						 * @author Sandeep Dhawale
						 * @param  Description handle the publisher Login Details
						 * @return Description return All publisher Details
						 */
						// karan-task-3714-vapt header and query params
						var parsed = this.props.location.state;
						var key;
						if (parsed !== undefined) {
							key = parsed.key;
							let data = {
								campID: campID,
								clientCampID: this.state.campaignDetailID[0].clientCampID,
								leadAllocation: leadAllocation,
								dynamicArray: this.state.newDynamicArray,
							}; // karan-task-3714-vapt header and query params
							console.log("Data : " + JSON.stringify(data));

							Swal.fire({
								onOpen: () => {
									Swal.showLoading();
									fetch("publisher/assignCampaign", {
										//insert data in publisher_assignment table
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((res) => {
											console.warn(
												"Response in res in==" + JSON.stringify(res)
											);
											if (res.success == true) {
												this.setState({ inputClass: "disabled" });
												console.warn("Response in res==" + JSON.stringify(res));
												Swal.fire({
													text: res.message,
													type: "success",
													confirmButtonText: "Ok",
													allowOutsideClick: false,
													preConfirm: () => {
														//  window.location.reload();

														if (key === "active") {
															this.props.history.push("/campaignList", {
																key: "active",
															}); //karan-task-3684-replace query params
														} else {
															this.props.history.push("/campaignList", {
																agencyID: this.state.agencyID,
															}); //karan-task-3684-replace query params
														}

														this.setState({ disable: false });
													},
												});
											}
										})
										.catch((err) => {
											console.log(err);
											Swal.fire({
												text: "Something went wrong please try again",
												type: "error",
												confirmButtonText: "Okay",
											});
										});
								},
							});
						} // karan-task-3714-vapt header and query params
					}
				}
			});

			return;
		} //end of  if po.length===0
		// }

		if (
			date === true &&
			cpl === true &&
			lead === true &&
			valid === true &&
			rebudget === true &&
			povalidate === true
		) {
			formIsValid = true;
		}

		console.log("Errors ====>" + JSON.stringify(this.state.errors));

		if (formIsValid === true) {
			this.setState({ alertDisplay: "none", alertBorder: "0px solid white" });

			var campID = document.getElementById("campID").value;
			var leadAllocation = document.getElementById("leadAllocation").value;

			/**
			 * @author Narendra Phadke
			 * @param  Description handle the publisher Login Details
			 * @return Description return All publisher Details
			 */
			// karan-task-3714-vapt header and query params
			var parsed = this.props.location.state;
			var key;
			if (parsed !== undefined) {
				key = parsed.key;
				let data = {
					campID: campID,
					clientCampID: this.state.campaignDetailID[0].clientCampID,
					leadAllocation: leadAllocation,
					dynamicArray: this.state.newDynamicArray,
				}; // karan-task-3714-vapt header and query params
				console.log("Data : " + JSON.stringify(data));

				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						fetch("publisher/assignCampaign", {
							//insert data in publisher_assignment table
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						})
							.then((res) => res.json())
							.then((res) => {
								console.warn("Response in res in==" + JSON.stringify(res));
								if (res.success == true) {
									this.setState({ inputClass: "disabled" });
									console.warn("Response in res==" + JSON.stringify(res));
									Swal.fire({
										text: res.message,
										type: "success",
										confirmButtonText: "Ok",
										allowOutsideClick: false,
										preConfirm: () => {
											//  window.location.reload();

											if (key === "active") {
												this.props.history.push("/campaignList", {
													key: "active",
												}); //karan-task-3684-replace query params
											} else {
												this.props.history.push("/campaignList", {
													agencyID: this.state.agencyID,
												}); //karan-task-3684-replace query params
											}

											this.setState({ disable: false });
										},
									});
								}
							})
							.catch((err) => {
								console.log(err);
								Swal.fire({
									text: "Something went wrong please try again",
									type: "error",
									confirmButtonText: "Okay",
								});
							});
					},
				});
			} // karan-task-3714-vapt header and query params
		} else {
		}
	}

	handleRefreshPage(e) {
		window.location.href = "/allocatingCampaign";
	}

	handleChangeDynamicPublisher(i, e) {
		const { name, value } = e.target;
		let newDynamicArray = [...this.state.newDynamicArray];
		//snehal-task-3812-allocated lead value is blank isallocate value should be no
		if (e.target.name === "allocatedLead" && e.target.value === "") {
			newDynamicArray[i] = {
				...newDynamicArray[i],
				[name]: value,
				isAllocate: "No",
			};
		} else {
			newDynamicArray[i] = {
				...newDynamicArray[i],
				[name]: value,
				isAllocate: "Yes",
			};
		}
		//this.state.newDynamicArray = [...newDynamicArray];
		this.setState({newDynamicArray: [...newDynamicArray]}) //RUtuja task -2978 changed this.state to setstate
	}

	handleChangeGDPRPublisher(i, e) {
		const { name, value } = e.target;
		let newGDPRPubArrayData = [...this.state.newGDPRPubArray];
		newGDPRPubArrayData[i] = {
			...newGDPRPubArrayData[i],
			[name]: value,
			isAllocate: "Yes",
		};

		//this.state.newGDPRPubArray = [...newGDPRPubArrayData];
		this.setState({newGDPRPubArray : [...newGDPRPubArrayData]}) //Rutuja Task - 2978 
		// console.log("Dynamic Objects===>"+JSON.stringify(this.state.newDynamicArray))
	} // end of  handleChangeDynamicPublisher

	isGDPRPUB(e) {
		//const {  isAuthenticated ,user } = this.props.auth; //Rutuja Task 2978 
		// var agencyID = user.id; //Rutuja Task 2978 
		var status = e.target.value;
		var campID = e.target.id;
		let data = { campID: campID }; // karan-task-3714-vapt header and query params

		var emptyArray = [];
		this.setState({
			publisherCampCPL: emptyArray,
			gdprPublisher: emptyArray,
			mergedGdprPublisher: emptyArray,
		});

		/**
		 * @author Supriya Gore
		 * @param  Description  use in allocating leads to publisher for checking status and view CPL edditable or not
		 * @return Description return CPL and publisher ID and status
		 */

		if (status === "Yes") { //Rutuja 2978 cchanged == to ===
			this.setState({ btnStatus: status, gdprStatus: "Yes" });

			fetch("agency/assignGDPRPublisher", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				}, // karan-task-3714-vapt header and query params
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((gdprPublisher) => {
					this.setState({ gdprPublisher: gdprPublisher });

					/**
					 * @author Supriya Gore
					 * @param  Description Merge two array
					 * @return Description one campaignDetailAssign and gdprPublisher array
					 */

					fetch("agency/publisherCampaignCPL", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						}, // karan-task-3714-vapt header and query params
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((publisherCampCPL) => {
							this.setState({ publisherCampCPL: publisherCampCPL });
							if (this.state.publisherCampCPL.length > 0) {
								// this.state.newDynamicArray = this.state.campaignDetailAssign.map((e) => Object.assign({}, e, this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) || {}));
								//Rutuja Task -2978 declared temp var and then used this.setState
								let Temp = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
								); 
								this.setState({newGDPRPubArray:Temp})
							} else {
								// this.state.newDynamicArray = this.state.campaignDetailAssign.map((e) => Object.assign({}, e, this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}));

								//Rutuja Task -2978 declared temp var and then used this.setState
								let Temp = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}
									)
								);
								this.setState({newGDPRPubArray:Temp})
							}
							
							

							const resultGDPR = Array.from(
								new Set(this.state.gdprPublisher.map((p) => p.pID))
							).map((pID) => {
								return {
									pID: pID,
									publisherName: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).publisherName,
									startDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.startDate,
									endDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.endDate,
									allocatedLead: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).allocatedLead,
									cpl:
										this.state.gdprPublisher.find((p) => p.pID === pID).cpl !=
										null
											? this.state.gdprPublisher.find((p) => p.pID === pID).cpl
											: this.state.gdprPublisher.find((p) => p.pID === pID)
													.campCPL,
									firstLeadDeliveryDate: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).firstLeadDeliveryDate,
									rating: this.state.gdprPublisher.find((p) => p.pID === pID)
										.rating,
									gdprCompliance: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).gdprCompliance,
									poNumber:
										this.state.gdprPublisher.find((p) => p.pID === pID)
											.poNumber != null
											? this.state.gdprPublisher.find((p) => p.pID === pID)
													.poNumber
											: this.state.gdprPublisher.find((p) => p.pID === pID)
													.poNumber,
									//Sandeep-task-3530-added code for poNumber
								};
							});

							let mergedGdprPublisher = [];
							mergedGdprPublisher = resultGDPR.map((e) =>
								Object.assign(
									{},
									e,
									this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) ||
										{}
								)
							);

							//this.state.mergedGdprPublisher=mergedGdprPublisher;
							this.setState({ mergedGdprPublisher: mergedGdprPublisher });
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		}

		if (status === "No") { //Rutuja 2978 changed == to === 
			this.setState({ btnStatus: status, gdprStatus: "No" });

			fetch("publisher/assignNoGDPRPublisher", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				}, // karan-task-3714-vapt header and query params
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((gdprPublisher) => {
					this.setState({ gdprPublisher: gdprPublisher });
					/**
					 * @author Supriya Gore
					 * @param  Description Merge two array
					 * @return Description one campaignDetailAssign and gdprPublisher array
					 */

					fetch("agency/publisherCampaignCPL", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						}, // karan-task-3714-vapt header and query params
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((publisherCampCPL) => {
							this.setState({ publisherCampCPL: publisherCampCPL });
							if (this.state.publisherCampCPL.length > 0) {
								// this.state.newDynamicArray = this.state.campaignDetailAssign.map((e) => Object.assign({}, e, this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) || {}));
								//Rutuja Task -2978 declared temp var and then used this.setState
								let Temp = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
								);
								this.setState({newGDPRPubArray:Temp})
								
							} else {
								// this.state.newDynamicArray = this.state.campaignDetailAssign.map((e) => Object.assign({}, e, this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}));

								let Temp = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}
									)
								); 
								this.setState({newGDPRPubArray:Temp})
							}

							const resultGDPR = Array.from(
								new Set(this.state.gdprPublisher.map((p) => p.pID))
							).map((pID) => {
								return {
									pID: pID,
									publisherName: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).publisherName,
									startDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.startDate,
									endDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.endDate,
									allocatedLead: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).allocatedLead,
									cpl:
										this.state.gdprPublisher.find((p) => p.pID === pID).cpl !=
										null
											? this.state.gdprPublisher.find((p) => p.pID === pID).cpl
											: this.state.gdprPublisher.find((p) => p.pID === pID)
													.campCPL,
									firstLeadDeliveryDate: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).firstLeadDeliveryDate,
									rating: this.state.gdprPublisher.find((p) => p.pID === pID)
										.rating,
									gdprCompliance: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).gdprCompliance,
									poNumber:
										this.state.gdprPublisher.find((p) => p.pID === pID)
											.poNumber != null
											? this.state.gdprPublisher.find((p) => p.pID === pID)
													.poNumber
											: this.state.gdprPublisher.find((p) => p.pID === pID)
													.poNumber,
									//Sandeep-task-3530-added code for poNumber
								};
							});

							let mergedGdprPublisher = [];
							mergedGdprPublisher = resultGDPR.map((e) =>
								Object.assign(
									{},
									e,
									this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) ||
										{}
								)
							);

							//this.state.mergedGdprPublisher=mergedGdprPublisher;
							this.setState({ mergedGdprPublisher: mergedGdprPublisher });
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (status === "false") { //Rutuja 2978 chahned == to === 
			this.setState({ gdprStatus: status, btnStatus: "No" });
		}
	}

	render() {
		/**
		 * @author Narendra Phadke
		 * @param  Description handle the login authentication
		 * @return Description return All details of authentication
		 */

		const { user } = this.props.auth; //Rutuja task 2978

		return (
			<div>
				<Navigation />

				<div
					class="container-fluid publisherAlloPage" //Prod issue--On Allocation page--ABM file count issue--Agency side 
					style={{ paddingTop: "45px", marginBottom: "60px" }}>
					{/*shivani-task 3039- reduced paddingTop to adjust allocate button at the bottom */}

					<div className="row campAllocation">
						<div class="col-md-1">
							<div>
								<a
									href="/campaignList" //Rutuja Task 2978
									onClick={() =>
										this.props.history.push("/campaignList", {
											agencyID: this.state.agencyID,
										})
									} //karan-task-3684-replace query params
									style={{ color: "#056eb8" }}>
									<FaArrowAltCircleLeft
										size={32}
										style={{ float: "left" }}
										title="Back to Dashboard"
									/>
								</a>
								{/*sunita-task-3393-prod issue- added code to render on proper page. as it's showing wrong page data. */}
							</div>
						</div>
						<div class="col-md-11 text-center">
							<label
								id="campallocation"
								style={{
									fontSize: "20px",
									fontWeight: "bold",
									color: "#056eb8",
								}}>
								Campaign Allocation
							</label>
						</div>
					</div>

					<div class="row">
						<div class="col-md-6"></div>
						<div class="col-md-6"></div>
					</div>

					<div class="row">
						<div class="container-fluid">
							<div
								class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
								style={{ border: this.state.alertBorder }}>
								<div class="errorMessage">
									{this.state.errors.lead ? this.state.puberror.one : ""}
									{this.state.reerrors.remaining ? (
										<span>
											{this.state.reerrors.remaining}
											<br />
										</span>
									) : (
										""
									)}
									{this.state.dateerrors.date ? this.state.dateerrors.date : ""}
									{this.state.errors.allocatedLead
										? this.state.errors.allocatedLead
										: ""}
									{this.state.errors1.allocatedLead1
										? this.state.errors1.allocatedLead1
										: ""}
									{this.state.allocerrors.allocate
										? this.state.allocerrors.allocate
										: ""}
									{this.state.puberror.one ? this.state.puberror.one : ""}
									{this.state.errors.endDate ? this.state.errors.endDate : ""}
									{this.state.errors.cpl ? this.state.errors.cpl : ""}
									{this.state.errors.cpl1 ? this.state.errors.cpl1 : ""}
									{this.state.errors.cpl2 ? this.state.errors.cpl2 : ""}
									{this.state.errors.cpl3 ? this.state.errors.cpl3 : ""}
									{this.state.puberror.po ? this.state.puberror.po : ""}
								</div>
							</div>
						</div>
					</div>
					<br />

					<div
						style={{
							MozBoxShadow: "3px 3px 5px 0px #e6e6ff",
							WebkitBoxShadow: "3px 3px 5px 1px #e6e6ff",
							boxShadow: "3px 3px 5px 0px #e6e6ff",
						}}>
						<div
							className="campdetails"
							style={{
								paddingLeft: "10px",
								paddingTop: "10px",
								paddingBottom: "10px",
								marginLeft: "30px",
							}}>
							<div>
								{this.state.campaignDetailID.map((campaignDetailID) => (
									<div class="row">
										<div class="col-sm-4 col-md-6 col-lg-4">
											<label
												id="label"
												style={{ color: "#000080", width: "172px" }}>
												Campaign ID
											</label>
											:{campaignDetailID.parentCampID}
										</div>
										{/* Sandeep-task-3377-removed span from camp id */}
										<div class="col-sm-4 col-md-6 col-lg-4">
											<label
												id="label"
												style={{ color: "#000080", width: "172px" }}>
												{" "}
												Campaign Name
											</label>
											:{campaignDetailID.campaignName}
											{/* Sandeep-task-3377-removed span from camp name */}
										</div>
									</div>
								))}
							</div>
							{this.state.campaignDetailID.map((campaignDetailID) => (
								<div class="row">
									<div class="col-sm-4 col-md-6 col-lg-4">
										{" "}
										<label
											id="label"
											style={{ color: "#000080", width: "172px" }}>
											Campaign Type
										</label>
										:{campaignDetailID.ABM}
									</div>
									<div class="col-sm-4 col-md-6 col-lg-4">
										<label
											id="label"
											style={{ color: "#000080", width: "172px" }}>
											Campaign Region
										</label>
										:{campaignDetailID.region}
									</div>
									<div class="col-sm-4 col-md-6 col-lg-4">{""}</div>
									<div class="col-sm-4 col-md-6 col-lg-4 startDateId" >
										<label
											id="label"
											class="startDateClass"
											style={{ color: "#000080", width: "172px" }}>
											Start Date
										</label>
										:{campaignDetailID.startDate}
									</div>

									<div class="col-sm-4 col-md-6 col-lg-4">
										<label
											id="label"
											class="startDateClass" // Mufiz-Task-4352-Prod issue--On Allocation page--ABM file count issue--Agency side  */
											style={{ color: "#000080", width: "172px" }}>
											End Date
										</label>
										:{campaignDetailID.endDate}
									</div>
									<div class="col-sm-4 col-md-6 col-lg-4">
										<label
											id="label"
											style={{ color: "#000080", width: "172px" }}>
											First Lead Delivery Date{" "}
										</label>
										:{campaignDetailID.firstLeadDeliveryDate}
									</div>
									{user.role === "AC" ? (
										<div class="col-sm-4 col-md-6 col-lg-4">
											<label
												id="label"
												style={{ color: "#000080", width: "172px" }}>
												Total Budget
											</label>
											:{campaignDetailID.budget}
											{campaignDetailID.currency}
										</div>
									) : (
										""
									)}
									{user.role === "AC" ? (
										<div class="col-sm-4 col-md-6 col-lg-4">
											<label
												id="label"
												style={{ color: "#000080", width: "172px" }}>
												Remaining Budget
											</label>
											:
											{campaignDetailID.remainingBudget
												? Math.round(campaignDetailID.remainingBudget * 100) /
												  100
												: campaignDetailID.remainingBudget === 0
												? "0"
												: Math.round(campaignDetailID.budget * 100) / 100}
											{campaignDetailID.currency}
										</div>
									) : (
										""
									)}
									<div class="col-sm-4 col-md-6 col-lg-4">
										<label
											id="label"
											style={{ color: "#000080", width: "172px" }}>
											Campaign CPL
										</label>
										:{campaignDetailID.CPL ? campaignDetailID.CPL : "0"}&nbsp;
										{campaignDetailID.currency}
									</div>
									<div class="col-sm-4 col-md-6 col-lg-4">
										<label
											id="label"
											style={{ color: "#000080", width: "172px" }}>
											Total Leads
										</label>
										:{campaignDetailID.leadAllocation}
									</div>
									<div class="col-sm-4 col-md-6 col-lg-4">
										<label
											id="label"
											style={{ color: "#000080", width: "172px" }}>
											Allocated Leads
										</label>
										:
										{campaignDetailID.allocatedLead
											? campaignDetailID.allocatedLead
											: "0"}
									</div>

									<div class="col-sm-4 col-md-6 col-lg-4">
										<label
											id="label"
											style={{ color: "#000080", width: "172px" }}>
											Pending Leads
										</label>
										:
										{campaignDetailID.pendingLead
											? campaignDetailID.pendingLead
											: campaignDetailID.pendingLead === 0
											? "0"
											: campaignDetailID.leadAllocation}
									</div>
									{campaignDetailID.noOfLeadPerDomain !== "" ? ( //Rutuja 2978 
										<div class="col-sm-4 col-md-6 col-lg-4">
											{" "}
											<label
												id="label"
												style={{ color: "#000080", width: "172px" }}>
												No of leads per domain
											</label>
											:{campaignDetailID.noOfLeadPerDomain}
										</div>
									) : (
										""
									)}

									{campaignDetailID.abmCount !== "" ? (//Rutuja 2978
										<div class="col-sm-4 col-md-6 col-lg-4">
											{" "}
											<label
												id="label"
												style={{ color: "#000080", width: "172px" }}>
												ABM count
											</label>
											:{campaignDetailID.abmCount}
										</div>
									) : (
										""
									)}
								</div>
							))}
						</div>
					</div>

					<div class="col-md-12 col-lg-12">
						<center>
							<br />
							{this.state.region ? (
								<span style={{ fontWeight: "bold" }}>
									Targeted Country is GDPR Compliance.Do you want to allocate
									GDPR Compliance Publisher ?&nbsp;
									<button
										type="submit"
										onClick={this.isGDPRPUB}
										id={tempCampID}
										value="Yes"
										class="btn btn-info btn-sm">
										Yes
									</button>
									&nbsp;
									<button
										type="submit"
										onClick={this.isGDPRPUB}
										id={tempCampID}
										value="No"
										class="btn btn-danger btn-xs">
										No
									</button>
								</span>
							) : (
								""
							)}
						</center>
					</div>
					<br />
					<br />

					{this.state.btnStatus.toString() === "Yes" ? (
						<span>
							<br />
							<br />
							<div class="row">
								<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
									<form
										method="POST"
										name="Publisher"
										onSubmit={this.submitGDPRPublisher}>
										<div class="table-responsive">
											<table class=" table table-striped">
												<thead>
													<tr class="" style={{ backgroundColor: "#f5f5f0" }}>
														<th class="table-header">Publisher Name</th>
														<th class="table-header">Rating</th>
														<th class="table-header">GDPR</th>

														<th class="table-header">Start Date</th>
														<th class="table-header">End Date</th>
														<th class="table-header">
															First Lead Delivery Date
														</th>
														<th class="table-header">Allocated Lead</th>
														<th class="table-header">Allocation</th>
														{user.role === "AC" ? (
															<th class="table-header">CPL</th>
														) : (
															""
														)}

														{user.role === "AC" ? (
															<th class="table-header">PO Number</th>
														) : (
															""
														)}
													</tr>
												</thead>
												<tbody>
													{/*
													 * @author Narendra Phadke
													 * @param  Description handle the multiple allocate campaign
													 * @return Description return successfully allocated message
													 */}

													{this.state.campaignDetailID.map(
														(campaignDetailID) => (
															<input
																type="hidden"
																id="campID"
																name="campID"
																//value={this.state.startDate}
																value={campaignDetailID.campID}
																style={{
																	width: "87%",
																	height: "100%",
																}}
																class="form-control"
															/>
														)
													)}

													{this.state.campaignDetailID.map(
														(campaignDetailID) => (
															<input
																type="hidden"
																id="totalLead"
																name="totalLead"
																//value={this.state.startDate}
																value={campaignDetailID.leadAllocation}
																style={{
																	width: "87%",
																	height: "100%",
																}}
																class="form-control"
															/>
														)
													)}

													{this.state.campaignDetailID.map(
														(campaignDetailID) => (
															<input
																type="hidden"
																id="totalAllocate"
																name="totalAllocate"
																//value={this.state.startDate}
																value={campaignDetailID.allocatedLead}
																style={{
																	width: "87%",
																	height: "100%",
																}}
																class="form-control"
															/>
														)
													)}

													{this.state.campaignDetailID.map(
														(campaignDetailID) => (
															<input
																type="hidden"
																id="leadAllocation"
																name="leadAllocation"
																//value={this.state.startDate}
																value={campaignDetailID.leadAllocation}
																style={{
																	width: "87%",
																	height: "100%",
																}}
																class="form-control"
															/>
														)
													)}

													{this.state.campaignDetailID.map(
														(campaignDetailID) => (
															<input
																type="hidden"
																id="totalBudget"
																name="totalBudget"
																//value={this.state.startDate}
																value={campaignDetailID.budget}
																style={{
																	width: "87%",
																	height: "100%",
																}}
																class="form-control"
															/>
														)
													)}
													{this.state.campaignDetailID.map(
														(campaignDetailID) => (
															<input
																type="hidden"
																id="totalRemainingBudget"
																name="totalRemainingBudget"
																//value={this.state.startDate}
																value={campaignDetailID.remainingBudget}
																style={{
																	width: "87%",
																	height: "100%",
																}}
																class="form-control"
															/>
														)
													)}
													{this.state.campaignDetailID.map(
														(campaignDetailID) => (
															<input
																type="hidden"
																id="totalendDate"
																name="totalendDate"
																//value={this.state.startDate}
																value={campaignDetailID.endDate}
																style={{
																	width: "87%",
																	height: "100%",
																}}
																class="form-control"
															/>
														)
													)}
													{this.state.campaignDetailID.map(
														(campaignDetailID) => (
															<input
																type="hidden"
																id="totalstartDate"
																name="otalstartDate"
																//value={this.state.startDate}
																value={campaignDetailID.startDate}
																style={{
																	width: "87%",
																	height: "100%",
																}}
																class="form-control"
															/>
														)
													)}
													{this.state.campaignDetailID.map(
														(campaignDetailID) => (
															<input
																type="hidden"
																id="totalPending"
																name="totalPending"
																//value={this.state.startDate}
																value={
																	campaignDetailID.allocatedLead
																		? campaignDetailID.leadAllocation -
																		  campaignDetailID.allocatedLead
																		: "0"
																}
																style={{
																	width: "87%",
																	height: "100%",
																}}
																class="form-control"
															/>
														)
													)}
													{this.state.mergedGdprPublisher.map(
														(gdprPublisher, i) => {
															//  {this.state.newDynamicArray.map((p,i) =>{
															return (
																<tr>
																	<td style={{ textAlign: "left" }}>
																		<label
																			id="label"
																			style={{ align: "left", color: "black" }}>
																			{gdprPublisher.publisherName}
																		</label>{" "}
																	</td>
																	<td>
																		<label id="label">
																			{gdprPublisher.rating}
																		</label>
																	</td>
																	<td style={{ textAlign: "center" }}>
																		<label
																			id="label"
																			style={{
																				align: "center",
																				color: "black",
																			}}>
																			{gdprPublisher.gdprCompliance}
																		</label>{" "}
																	</td>

																	<td>
																		<input
																			type="date"
																			id="startDate"
																			name="startDate"
																			value={this.state.startDate}
																			onChange={this.handleChangeGDPRPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "87%" }}
																			defaultValue={gdprPublisher.startDate}
																			class="form-control"
																		/>{" "}
																	</td>
																	<td>
																		<input
																			type="date"
																			id="endDate"
																			name="endDate"
																			value={this.state.endDate}
																			onChange={this.handleChangeGDPRPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "87%" }}
																			defaultValue={gdprPublisher.endDate}
																			class="form-control"
																		/>{" "}
																	</td>
																	<td>
																		<input
																			type="date"
																			id="firstLeadDeliveryDate"
																			name="firstLeadDeliveryDate"
																			value={this.state.firstLeadDeliveryDate}
																			onChange={this.handleChangeGDPRPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "87%" }}
																			defaultValue={
																				gdprPublisher.firstLeadDeliveryDate
																			}
																			class="form-control"
																		/>{" "}
																	</td>

																	<td>
																		{gdprPublisher.pubAllocatedLead == null
																			? 0
																			: gdprPublisher.pubAllocatedLead}
																	</td>

																	<td>
																		<input
																			type="textbox"
																			class="form-control"
																			id="allocatedLead"
																			name="allocatedLead"
																			value={this.state.allocatedLead}
																			onChange={this.handleChangeGDPRPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "80%", height: "100%" }}
																		/>
																	</td>
																	<td>
																		{gdprPublisher.status === "Accept" ? ( //Rutuja 2978 chahned == to ===
																			<input
																				type="textbox"
																				class="form-control"
																				id="cpl"
																				name="cpl"
																				defaultValue={gdprPublisher.cpl}
																				disabled
																				onChange={this.handleChangeGDPRPublisher.bind(
																					this,
																					i
																				)}
																				style={{
																					width: "100%",
																					height: "100%",
																				}}
																			/>
																		) : gdprPublisher.status === "Assign" ? ( //Rutuja 2978 chahned == to ===
																			<input
																				type="textbox"
																				class="form-control"
																				id="cpl"
																				name="cpl"
																				defaultValue={gdprPublisher.cpl}
																				onChange={this.handleChangeGDPRPublisher.bind(
																					this,
																					i
																				)}
																				style={{
																					width: "100%",
																					height: "100%",
																				}}
																			/>
																		) : gdprPublisher.status == null ? (
																			<input
																				type="textbox"
																				class="form-control"
																				id="cpl"
																				name="cpl"
																				defaultValue={gdprPublisher.cpl}
																				onChange={this.handleChangeGDPRPublisher.bind(
																					this,
																					i
																				)}
																				style={{
																					width: "100%",
																					height: "100%",
																				}}
																			/>
																		) : (
																			<input
																				type="textbox"
																				class="form-control"
																				id="cpl"
																				name="cpl"
																				defaultValue={gdprPublisher.campCPL}
																				onChange={this.handleChangeGDPRPublisher.bind(
																					this,
																					i
																				)}
																				style={{
																					width: "100%",
																					height: "100%",
																				}}
																			/>
																		)}
																	</td>

																	<td>
																		{/* Sandeep-task-3530-added code for poNumber */}
																		{gdprPublisher.poNumber ? (
																			<input
																				type="textbox"
																				class="form-control"
																				id="poNumber"
																				name="poNumber"
																				defaultValue={gdprPublisher.poNumber}
																				disabled
																				onChange={this.handleChangeGDPRPublisher.bind(
																					this,
																					i
																				)}
																				style={{
																					width: "100%",
																					height: "100%",
																				}}
																			/>
																		) : (
																			<input
																				type="textbox"
																				class="form-control"
																				id="poNumber"
																				name="poNumber"
																				defaultValue={gdprPublisher.poNumber}
																				onChange={this.handleChangeGDPRPublisher.bind(
																					this,
																					i
																				)}
																				style={{
																					width: "100%",
																					height: "100%",
																				}}
																			/>
																		)}
																	</td>
																</tr>
															);
														}
													)}
												</tbody>
											</table>
										</div>

										<div class="modal-footer">
											<button
												type="submit"
												className={this.state.inputClass}
												class="btn add-button"
												style={{
													backgroundColor: "#144999",
													color: "white",
													marginBottom: "50px",
												}}>
												Allocate
											</button>
											{/*shivani-task 3039-added margin bottom so that button will not be hide behind footer*/}
										</div>
									</form>
								</div>

								{/**End of col */}
							</div>
						</span>
					) : (
						""
					)}
					{/**End of 2nd row */}
					{/**If Agency Click On No Button*/
					/**
					 * @author Supriya Gore
					 * @param  Description handle the No GDPR Compliance
					 * @return Description return All details of Not GDPR compliance for pending allocation
					 */}

					{this.state.btnStatus === "No" ? ( //Rutuja 2978 chahned == to ===
						<div class="row">
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<span
									class="d-flex justify-content-center"
									style={{ fontWeight: "bold", color: "red" }}>
									Below List is for Non GDPR Compliance Publisher
								</span>
								<br />

								<form
									method="POST"
									name="Publisher"
									onSubmit={this.submitGDPRPublisher}>
									<div class="table-responsive-lg tblFirst">
										<table class=" table table-striped">
											<thead>
												<tr class="" style={{ backgroundColor: "#f5f5f0" }}>
													<th class="table-header">Publisher Name</th>
													<th class="table-header">Rating</th>
													<th class="table-header">GDPR</th>

													<th class="table-header">Start Date</th>
													<th class="table-header">End Date</th>
													<th class="table-header">First Lead Delivery Date</th>
													<th class="table-header">Allocated Lead</th>
													<th class="table-header">Allocation</th>
													{user.role === "AC" ? (
														<th class="table-header">CPL</th>
													) : (
														""
													)}
													{user.role === "AC" ? (
														<th class="table-header">PO Number</th>
													) : (
														""
													)}
												</tr>
											</thead>
											<tbody>
												{/*
												 * @author Narendra Phadke
												 * @param  Description handle the multiple allocate campaign
												 * @return Description return successfully allocated message
												 */}

												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="campID"
														name="campID"
														//value={this.state.startDate}
														value={campaignDetailID.campID}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}

												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalLead"
														name="totalLead"
														//value={this.state.startDate}
														value={campaignDetailID.leadAllocation}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}

												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalAllocate"
														name="totalAllocate"
														//value={this.state.startDate}
														value={campaignDetailID.allocatedLead}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}

												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="leadAllocation"
														name="leadAllocation"
														//value={this.state.startDate}
														value={campaignDetailID.leadAllocation}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}

												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalBudget"
														name="totalBudget"
														//value={this.state.startDate}
														value={campaignDetailID.budget}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}
												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalRemainingBudget"
														name="totalRemainingBudget"
														//value={this.state.startDate}
														value={campaignDetailID.remainingBudget}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}
												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalendDate"
														name="totalendDate"
														//value={this.state.startDate}
														value={campaignDetailID.endDate}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}
												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalstartDate"
														name="otalstartDate"
														//value={this.state.startDate}
														value={campaignDetailID.startDate}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}
												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalPending"
														name="totalPending"
														//value={this.state.startDate}
														value={
															campaignDetailID.allocatedLead
																? campaignDetailID.leadAllocation -
																  campaignDetailID.allocatedLead
																: "0"
														}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}
												{this.state.mergedGdprPublisher.map(
													(gdprPublisher, i) => {
														//  {this.state.newDynamicArray.map((p,i) =>{
														return (
															<tr>
																<td style={{ textAlign: "left" }}>
																	<label
																		id="label"
																		style={{ align: "left", color: "black" }}>
																		{gdprPublisher.publisherName}
																	</label>{" "}
																</td>

																<td>
																	<label id="label">
																		{gdprPublisher.rating}
																	</label>
																</td>
																<td style={{ textAlign: "center" }}>
																	<label
																		id="label"
																		style={{ align: "center", color: "black" }}>
																		{gdprPublisher.gdprCompliance}
																	</label>{" "}
																</td>

																<td>
																	<input
																		type="date"
																		id="startDate"
																		name="startDate"
																		value={this.state.startDate}
																		onChange={this.handleChangeGDPRPublisher.bind(
																			this,
																			i
																		)}
																		style={{ width: "87%" }}
																		defaultValue={gdprPublisher.startDate}
																		class="form-control"
																	/>{" "}
																</td>
																<td>
																	<input
																		type="date"
																		id="endDate"
																		name="endDate"
																		value={this.state.endDate}
																		onChange={this.handleChangeGDPRPublisher.bind(
																			this,
																			i
																		)}
																		style={{ width: "87%" }}
																		defaultValue={gdprPublisher.endDate}
																		class="form-control"
																	/>{" "}
																</td>

																<td>
																	<input
																		type="date"
																		id="firstLeadDeliveryDate"
																		name="firstLeadDeliveryDate"
																		value={this.state.firstLeadDeliveryDate}
																		onChange={this.handleChangeGDPRPublisher.bind(
																			this,
																			i
																		)}
																		style={{ width: "87%" }}
																		defaultValue={
																			gdprPublisher.firstLeadDeliveryDate
																		}
																		class="form-control"
																	/>{" "}
																</td>

																<td>
																	{gdprPublisher.pubAllocatedLead == null
																		? 0
																		: gdprPublisher.pubAllocatedLead}
																</td>

																<td>
																	<input
																		type="textbox"
																		class="form-control"
																		id="allocatedLead"
																		name="allocatedLead"
																		value={this.state.allocatedLead}
																		onChange={this.handleChangeGDPRPublisher.bind(
																			this,
																			i
																		)}
																		style={{ width: "80%", height: "100%" }}
																	/>
																</td>

																<td>
																	{gdprPublisher.status === "Accept" ? ( //Rutuja 2978 chahned == to ===
																		<input
																			type="textbox"
																			class="form-control"
																			id="cpl"
																			name="cpl"
																			defaultValue={gdprPublisher.cpl}
																			disabled
																			onChange={this.handleChangeGDPRPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	) : gdprPublisher.status === "Assign" ? ( //Rutuja 2978 chahned == to ===
																		<input
																			type="textbox"
																			class="form-control"
																			id="cpl"
																			name="cpl"
																			defaultValue={gdprPublisher.cpl}
																			onChange={this.handleChangeGDPRPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	) : gdprPublisher.status == null ? (
																		<input
																			type="textbox"
																			class="form-control"
																			id="cpl"
																			name="cpl"
																			defaultValue={gdprPublisher.cpl}
																			onChange={this.handleChangeGDPRPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	) : (
																		<input
																			type="textbox"
																			class="form-control"
																			id="cpl"
																			name="cpl"
																			defaultValue={gdprPublisher.campCPL}
																			onChange={this.handleChangeGDPRPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	)}
																</td>

																<td>
																	{/* Sandeep-task-3530-added code for poNumber */}
																	{gdprPublisher.poNumber ? (
																		<input
																			type="textbox"
																			class="form-control"
																			id="poNumber"
																			name="poNumber"
																			defaultValue={gdprPublisher.poNumber}
																			disabled
																			onChange={this.handleChangeGDPRPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	) : (
																		<input
																			type="textbox"
																			class="form-control"
																			id="poNumber"
																			name="poNumber"
																			defaultValue={gdprPublisher.poNumber}
																			onChange={this.handleChangeGDPRPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	)}
																</td>
															</tr>
														);
													}
												)}
											</tbody>
										</table>
									</div>

									<div class="modal-footer">
										<button
											type="submit"
											className={this.state.inputClass}
											class="btn add-button"
											style={{
												backgroundColor: "#144999",
												color: "white",
												marginBottom: "50px",
											}}>
											Allocate
										</button>
										{/*shivani-task 3039-added margin bottom so that button will not be hide behind footer*/}
									</div>
								</form>
							</div>

							{/**End of col */}
						</div>
					) : (
						""
					)}
					{/**End of 2nd row */}
					{/**If Agency Click Button*/}

					{this.state.gdprStatus === "false" ? ( //Rutuja 2978 chahned == to ===
						<div class="row">
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<form
									method="POST"
									name="Publisher"
									onSubmit={this.submitPublisher}>
									<div class="table-responsive-lg tblFirst">
										<table class=" table table-striped">
											<thead>
												<tr class="" style={{ backgroundColor: "#f5f5f0" }}>
													<th class="table-header">Publisher Name</th>
													<th class="table-header">Rating</th>
													<th class="table-header">GDPR</th>

													<th class="table-header">Start Date</th>
													<th class="table-header">End Date</th>
													<th class="table-header">First Lead Delivery Date</th>
													<th class="table-header">Allocated Leads</th>
													<th class="table-header">Allocation</th>
													{user.role === "AC" ? (
														<th class="table-header" style={{ width: "10%" }}>
															CPL
														</th>
													) : (
														""
													)}

													{user.role === "AC" ? (
														<th class="table-header" style={{ width: "10%" }}>
															PO Number
														</th>
													) : (
														""
													)}
												</tr>
											</thead>
											<tbody>
												{/*
												 * @author Narendra Phadke
												 * @param  Description handle the multiple allocate campaign
												 * @return Description return successfully allocated message
												 */}

												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="campID"
														name="campID"
														//value={this.state.startDate}
														value={campaignDetailID.campID}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}

												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalLead"
														name="totalLead"
														//value={this.state.startDate}
														value={campaignDetailID.leadAllocation}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}

												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalAllocate"
														name="totalAllocate"
														//value={this.state.startDate}
														value={campaignDetailID.allocatedLead}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}

												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="leadAllocation"
														name="leadAllocation"
														//value={this.state.startDate}
														value={campaignDetailID.leadAllocation}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}

												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalBudget"
														name="totalBudget"
														//value={this.state.startDate}
														value={campaignDetailID.budget}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}
												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalRemainingBudget"
														name="totalRemainingBudget"
														//value={this.state.startDate}
														value={campaignDetailID.remainingBudget}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}
												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalendDate"
														name="totalendDate"
														//value={this.state.startDate}
														value={campaignDetailID.endDate}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}
												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalstartDate"
														name="otalstartDate"
														//value={this.state.startDate}
														value={campaignDetailID.startDate}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}
												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalFirstLeadDeliveryDate"
														name="totalFirstLeadDeliveryDate"
														//value={this.state.startDate}
														value={campaignDetailID.firstLeadDeliveryDate}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}
												{this.state.campaignDetailID.map((campaignDetailID) => (
													<input
														type="hidden"
														id="totalPending"
														name="totalPending"
														//value={this.state.startDate}
														value={
															campaignDetailID.allocatedLead
																? campaignDetailID.leadAllocation -
																  campaignDetailID.allocatedLead
																: "0"
														}
														style={{
															width: "87%",
															height: "100%",
														}}
														class="form-control"
													/>
												))}

												{this.state.mergedCampaignDetailAssign.map(
													(campaignDetailAssign, i) => {
														//  {this.state.newDynamicArray.map((p,i) =>{
														return (
															<tr>
																<td style={{ textAlign: "left" }}>
																	<label
																		id="label"
																		style={{ align: "left", color: "black" }}>
																		{campaignDetailAssign.publisherName}
																	</label>{" "}
																</td>
																<td>
																	<label id="label">
																		{campaignDetailAssign.rating}
																	</label>
																</td>
																<td style={{ textAlign: "center" }}>
																	<label
																		id="label"
																		style={{ align: "center", color: "black" }}>
																		{campaignDetailAssign.gdprCompliance}
																	</label>{" "}
																</td>

																<td>
																	<input
																		type="date"
																		id="startDate"
																		name="startDate"
																		value={this.state.startDate}
																		onChange={this.handleChangeDynamicPublisher.bind(
																			this,
																			i
																		)}
																		style={{ width: "87%" }}
																		defaultValue={
																			campaignDetailAssign.startDate
																		}
																		class="form-control"
																	/>{" "}
																</td>
																<td>
																	<input
																		type="date"
																		id="endDate"
																		name="endDate"
																		value={this.state.endDate}
																		onChange={this.handleChangeDynamicPublisher.bind(
																			this,
																			i
																		)}
																		style={{ width: "87%" }}
																		defaultValue={campaignDetailAssign.endDate}
																		class="form-control"
																	/>{" "}
																</td>
																<td>
																	<input
																		type="date"
																		id="firstLeadDeliveryDate"
																		name="firstLeadDeliveryDate"
																		value={this.state.firstLeadDeliveryDate}
																		onChange={this.handleChangeDynamicPublisher.bind(
																			this,
																			i
																		)}
																		style={{ width: "87%" }}
																		defaultValue={
																			campaignDetailAssign.firstLeadDeliveryDate
																		}
																		class="form-control"
																	/>{" "}
																</td>

																<td>
																	{campaignDetailAssign.pubAllocatedLead == null
																		? 0
																		: campaignDetailAssign.pubAllocatedLead}
																</td>

																<td>
																	<input
																		type="textbox"
																		class="form-control"
																		id="allocatedLead"
																		name="allocatedLead"
																		value={this.state.allocatedLead}
																		onChange={this.handleChangeDynamicPublisher.bind(
																			this,
																			i
																		)}
																		style={{ width: "80%", height: "100%" }}
																	/>
																</td>
																<td>
																	{campaignDetailAssign.status === "Accept" ? ( 
																		<input
																			type="textbox"
																			class="form-control"
																			id="cpl11"
																			name="cpl"
																			defaultValue={campaignDetailAssign.cpl}
																			disabled
																			onChange={this.handleChangeDynamicPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	) : campaignDetailAssign.status === //Rutuja 2978
																	  "Assign" ? ( 
																		<input
																			type="textbox"
																			class="form-control"
																			id="cpl11"
																			name="cpl"
																			defaultValue={campaignDetailAssign.cpl}
																			onChange={this.handleChangeDynamicPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	) : campaignDetailAssign.status == null ? (
																		<input
																			type="textbox"
																			class="form-control"
																			id="cpl11"
																			name="cpl"
																			defaultValue={campaignDetailAssign.cpl}
																			onChange={this.handleChangeDynamicPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	) : (
																		<input
																			type="textbox"
																			class="form-control"
																			id="cpl11"
																			name="cpl"
																			defaultValue={
																				campaignDetailAssign.campCPL
																			}
																			onChange={this.handleChangeDynamicPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	)}
																</td>

																<td>
																	{/* Sandeep-task-3530-added code for poNumber */}
																	{campaignDetailAssign.poNumber ? (
																		<input
																			type="textbox"
																			class="form-control"
																			id="poNumber"
																			name="poNumber"
																			defaultValue={
																				campaignDetailAssign.poNumber
																			}
																			disabled
																			onChange={this.handleChangeDynamicPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	) : (
																		<input
																			type="textbox"
																			class="form-control"
																			id="poNumber"
																			name="poNumber"
																			defaultValue={
																				campaignDetailAssign.poNumber
																			}
																			onChange={this.handleChangeDynamicPublisher.bind(
																				this,
																				i
																			)}
																			style={{ width: "100%", height: "100%" }}
																		/>
																	)}
																</td>
															</tr>
														);
													}
												)}
											</tbody>
										</table>
									</div>
									<div class="modal-footer">
										<button
											type="submit"
											className={this.state.inputClass}
											class="btn add-button"
											style={{
												backgroundColor: "#144999",
												color: "white",
												marginBottom: "50px",
											}}>
											Allocate
										</button>
										{/*shivani-task 3039-added margin bottom so that button will not be hide behind footer*/}
									</div>
								</form>
							</div>

							{/**End of col */}
						</div>
					) : (
						""
					)}

					{/* </div>  */}
				</div>
				<Footer />
			</div> // first div
		); // end of return
	} // end of render
} // end of  class PublisherAllocation
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
PublisherAllocation.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(PublisherAllocation)
);
