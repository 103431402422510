
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Raunak Thakkar
*@fileName :
 *Desc: DI QA Front End
*/


import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../login/authentication';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navigation from '../layouts/navPage';
import Moment from 'react-moment';
import { Table, Select, Input } from 'antd';
import 'datatables.net';
import Footer from '../layouts/footer';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { css } from 'emotion'
import './qaloginCss.css'
import { LeadReviewLoader } from "../loaders/LeadReviewLoader"; // Sandeep-task-3441-added loader
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3724-vAPT URL changes
//import { width } from 'pdfkit/js/page';
const xlsx = require('xlsx');
const { Option } = Select
const queryString = require('query-string');
const tableCSS = css({
  backgroundColor: 'white',
  fontFamily: "sans-serif", //sunita-task-3271-added css as per requirement
  borderStyle: 'bold',
  // height: "40%", //sunita-task-3271-commneted the css as it's not required.
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize: '12px',
    textAlign: "center",
    fontWeight: '500',
  },
  '& thead > tr >th': {
    textAlign: "center",
    color: 'white',
  }
})
class AgencyUnreadAlerts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      agencyID: "",
      columnUnreadAlertsSearch: ['1', '2', '3'],
      tableSearchText: "", //sunita-task 3271-added this parameter for search functionality.
      unreadAlerts: [],
      unreadAlertsLength: 0,
      campaignList: [],
      loading: " ",
      leadDetails: [],
      DIQaLeadDetails: [],
      uploadedFile: [],
      campDetails: [],
      campID: "",
      finalArray: [],
      disable: false,
      diLeadDetails: [],
      agencyID: '',
      tableState: { size: 'small' },
      getDIQaLeadCounts: '',
      DIQaLeadDetailsCount: '',
      reviewFlag: false,
      pageSize2: 100, //sunita-task-3271-added code for pagination
      leadDetails: [], //sunita-task-3271-added this array for storing campID when we are coming from dashboard.
      leadDetailsSearch: [], /* sunita-task-3271- added this array for search functionality*/
      loading1: false, //sunita-task-3271-added code for loader
      defaultCampID: '', //sunita-task-3271-added code as per requirement
      filesData1: '',//sunita-task-3271-added code for file
      loader2: "",//sandeep--3441-used for loader.

    }
    this.downloadFile = this.downloadFile.bind(this)
    this.displayLeads = this.displayLeads.bind(this);
    this.fileupload = this.fileupload.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this); //sunita-task-3271-handle change for search functionality.
    this.openLinkInBrowser = this.openLinkInBrowser.bind(this); //sunita-task-3271-added this handle change for voicelog link.
    this.displayCampaignSpecification = this.displayCampaignSpecification.bind(this); // Rutuja Jagtap task-3879 Campaign Specification for DIQA role

    // User Story - 3441 - Sandeep - reference to control Loader
    this.loadingRef = React.createRef(); // shows the Loader
    this.closeLoadingRef = React.createRef(); // hides the Loader

  }

  /**
  * @author Rutuja Jagtap
  * @param  Description handle the campaignSpecification on DIQA review lead page 
  * @return Description redirect to campaignSpecification page
  */
  displayCampaignSpecification(e) {
    var campID = this.state.campID;;
    sessionStorage.setItem("campID", campID);
    window.open("/campaignSpecificationQADashboard");

  }


  /**
  * @author Sunita Landge
  * @param  Description for searching campaign id and it's data.
  * @return return the searched data
  */

  //sunita-task-3271-added this handle change for search functionality
  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    // If the search bar isn't empty
    if (e.target.value === "") {
      newList = this.state.leadDetails;
    }
    else {
      // Assign the original list to currentList
      currentList = this.state.leadDetails;
      newList = currentList.filter(item => {
        // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();  
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        // check to see if the current list item includes the search term
        // If it does, it will be added to newList. Using lowercase eliminates
        // issues with capitalization in search terms and search content
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });

    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      leadDetailsSearch: [...newList], tableSearchText: filterData
    });
  }


  /**
* @author Sunita Landge
* @param  Description for voicelog link.
* @return return the voicelog link data
*/

  //sunita-task-3271-added this handle change for voicelog link
  openLinkInBrowser(e) {
    var leadInfoID = e.target.id;
    fetch("lead/getVoiceLogLink?leadInfoID=" + leadInfoID + "")
      .then(res => res.json())
      .then(voiceLogDetails => {
        this.setState({ voiceLogDetails: voiceLogDetails })
        window.open(voiceLogDetails[0].voiceLogLink, '_blank');
      }

      ).catch(function (err) {
        console.log(err)
      });

  }



  handleChange(i, value, type) {
    if (type == "reason") {
      let finalArray = [...this.state.DIQaLeadDetails]
      finalArray[i] = { ...finalArray[i], ["reason"]: value.target.value };
      this.state.finalArray = [...finalArray];
      this.setState({ DIQaLeadDetails: finalArray }, function () {
      });
    }
    if (type == "status") {
      let finalArray = [...this.state.DIQaLeadDetails]
      finalArray[i] = { ...finalArray[i], ["status"]: value };
      this.state.finalArray = [...finalArray];
      this.setState({ DIQaLeadDetails: finalArray }, function () {
      });
    }
  }

  handleSubmit() {
    const { isAuthenticated, user } = this.props.auth;

    let data = {
      campaignLeadDetails: this.state.DIQaLeadDetails,
      campID: this.state.campID,
      //user: user, Priyanka-4580-removing user params
      agencyID: this.state.agencyID

    }
    this.loadingRef.current.click();//Sandeep-task-3441-added loader
    fetch("/diQARole/diReviewLeadSubmit", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then(res => res.json())

      .then(diLeadDetails => {
        this.closeLoadingRef.current.click();//Sandeep-task-3441-added loader
        if (diLeadDetails.length > 0) {

          Swal.fire({
            text: 'Lead reviewed successfully',
            type: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,

            preConfirm: () => {
              window.location.reload();
            }
          })


        } else {

        }
      }).catch(function (err) { console.log(err) });

  }
  fileupload(e) {
    e.preventDefault();
    let filesData1 = e.target.files[0];
    var ext
    if (filesData1 == null) {
      ext = "";
      var label = document.getElementById('fileName2');
      label.innerHTML = "No File Choosen"
    }
    else {
      ext = filesData1.name.split('.').pop();
      var label = document.getElementById('fileName2');
      label.innerHTML = filesData1.name
    }
    let message = "";
    const { isAuthenticated, user } = this.props.auth;
    var userID = user.id;
    var userName = user.name;
    var firstName = user.firstName;
    var lastName = user.lastName;
    var data = new FormData();
    if (ext == "xlsx" || ext == "XLSX") {
      data.append("campID", this.state.campID);

      data.append("message", message);
      data.append("file", filesData1);
      //data.append("userID", userID);    // Rutuja jagtap 4123 - User Details show in Payload Tab
      // data.append("userName", userName);
      //data.append("user", JSON.stringify(user));
      //data.append("firstName", firstName);
      //data.append("lastName", lastName);
      fetch("/diQARole/uploadReviewLeadDI", {
        method: 'POST',

        body: data
      }).then(res => res.json())

        .then(campaignLeadDetails => {
          if (campaignLeadDetails.length > 0) {
            var temp = []
            for (let i = 0; i < campaignLeadDetails.length; i++) {
              temp.push({ ...this.state.DIQaLeadDetails[i], 'leadInfoID': campaignLeadDetails[i].leadInfoID, 'email': campaignLeadDetails[i].email, 'jobTitle': campaignLeadDetails[i].jobTitle, 'status': campaignLeadDetails[i].status, 'reason': campaignLeadDetails[i].reason })
            }
            this.setState({ DIQaLeadDetails: temp })
            Swal.fire({
              text: "File uploaded and leads reviewed",
              type: "success",
              allowOutsideClick: false,
              heightAuto: false,
              onOpen: () => {
                this.setState({ reviewFlag: true })
                document.getElementById('submitButton').disabled = true;
                document.getElementById('submitButton1').disabled = true;//sunita-task-3422-added code here for disabling the submit button as only one button get disabled as per requirement done the changes.
              },

            })

          }

          //sunita-task-3271-added below api for removing particular campaign id from left side after file upload
          fetch("/diQARole/leadReviewDI", {
            method: "POST",
            headers: { "Content-Type": "application/json" },

          }).then(res => res.json())
            .then(leadDetails => {
              this.setState({ leadDetails: leadDetails })
            })

        }
        ).catch(function (err) {
          console.log(err)
          Swal.fire({
            text: "Error occured please try again",
            type: "error",
            allowOutsideClick: false,
            heightAuto: false
          })
        });
    }
    else {
      Swal.fire({
        text: "File Invalid Please Upload file with .xlsx format",
        type: "error",
        allowOutsideClick: false,
        heightAuto: false
      })
    }
  }

  downloadFile(e) {
    let errors = {};
    var parsed = queryString.parse(this.props.location.search);
    let data = {
      campID: this.state.campID,
    }
    let leadDetails = this.state.leadDetails; //4588-Nilesh File Naming convention change
    let campDetail = leadDetails.filter((el) => {
      return el.campID == this.state.campID;
    });
    
    Swal.fire({
      onOpen: () => {
        Swal.showLoading()
        fetch("/diQARole/downloadLeadDetails?campID=" + this.state.campID + "")
          .then(res => res.json())
          .then(downloadFiles => {
            this.setState({ downloadFiles: downloadFiles })

            if (downloadFiles.success === true) {
              Swal.fire({
                type: 'error',
                text: 'No Data Exists!',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
              })
              this.setState({ errors: errors });
            } else {

              var ws = xlsx.utils.json_to_sheet(downloadFiles);
              var wb = xlsx.utils.book_new();
              xlsx.utils.book_append_sheet(wb, ws, "Lead");
              var buf = xlsx.write(wb, { bookType: 'xlsx', type: 'buffer' }); // generate a nodejs buffer
              var str = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' });
              function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
              }
              //var fileName = this.state.campID + "_QA_Leads" + ".xlsx";
              var fileName = "QA_Leads_"+ campDetail[0].clientCampID+ "_"+ this.state.campID + ".xlsx"; //4588-Nilesh File Naming convention change
              saveAs(new Blob([s2ab(buf)], { type: "application/octet-stream" }), fileName);
              Swal.fire({
                text: "File Downloaded Successfully",
                type: 'success',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
              })
            }
          }
          )
      }
    })
  }
  displayLeads(e) {
    e.preventDefault(); //4617- Nilesh remove # in url
    this.setState({ loading1: true });  //sunita-task-3371-added code for loader
    var campID = e.target.id;
    this.setState({ campID: campID, defaultCampID: campID, loader2: 'loader_review' }) //sunita-ask-3271-setstate the values as per requirement
    let data = {
      campID: campID
    }

    fetch("/diQARole/getCampDetails", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(campDetails => {
        //sunita-task-3271-added code for voiceloglink column   
        if ((campDetails[0].marketingChannel == "TeleMarketing" && campDetails[0].callAudit == "Yes") || (campDetails[0].marketingChannel == "Email/Telemarketing" && campDetails[0].callAudit == "Yes")) {
          this.setState({ marketingChannelFlag: true })
        }
        else {
          this.setState({ marketingChannelFlag: false })
        }

        //sunita-task-3271-added below code to remove uploaded file when clicking on any other campaign id.
        document.getElementById('abm1').value = "";
        document.getElementById('fileName2').value = "";

        this.setState({ campDetails: campDetails, agencyID: campDetails[0].agencyID, reviewFlag: false, loader2: "" })
      })//Sandeep-task-3441-added loader
    document.getElementById('submitButton').disabled = false;
    document.getElementById('submitButton1').disabled = false; //sunita-task-3422-added code here for disabling the submit button as only one button get disabled as per requirement done the changes.
    fetch("/diQARole/getDIQaLeadCounts", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(getDIQaLeadCounts => {
        let cnt = getDIQaLeadCounts.length;
        if (cnt < 0) {
          cnt = 0;
        }
        this.setState({ getDIQaLeadCounts: cnt })
      }).catch(function (err) { console.log(err) });

    fetch("/diQARole/DIQaLeadDetails", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(DIQaLeadDetails => {

        let count = DIQaLeadDetails.length
        if (count < 0) {
          count = 0;
        }
        this.setState({ DIQaLeadDetails: DIQaLeadDetails, DIQaLeadDetailsCount: count, loading1: false, loader2: '' }, () => { document.getElementById('fileName2').innerHTML = "No file Choosen" })//Sandeep-task-2892-added code for to show lead upload file is absence

        //sunita-task-3271-added for removing uploaded file name
        var filesData1 = this.state.filesData1;
        if (filesData1 === undefined || filesData1 === "undefined" || filesData1 === "" || filesData1 === null) {
          var label = document.getElementById('fileName2');
          label.innerHTML = "No File Choosen"

        }



      }).catch(function (err) { console.log(err) });
  }


  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
      this.setState({ loader2: "loader_review" })//Sandeep-task-3441-added code for loader
      fetch("/diQARole/leadReviewDI", {
        method: "POST",
        headers: { "Content-Type": "application/json" },

      }).then(res => res.json())
        .then(leadDetails => {


          //sunita-task-3271-added below all api code for showing by default campaign id and it's data according to that as this api are alredy present in page jst accessed below as per requirement.
          if (leadDetails.length > 0) {
            let defaultCampID = leadDetails[0].campID; //sunita-task-3271-added as per requirement

            this.setState({ leadDetails: leadDetails, campID: defaultCampID })//sunita-task-3271-setstate for getting updated values

            var campID = leadDetails[0].campID;
            //this.setState({ campID })

            let data = {
              campID: campID
            }


            //sunita-task-3271-getting right side campaign detail info
            fetch("/diQARole/getCampDetails", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data)
            }).then(res => res.json())
              .then(campDetails => {

                //sunita-task-3271-added code for voiceloglink column 
                if ((campDetails[0].marketingChannel == "TeleMarketing" && campDetails[0].callAudit == "Yes") || (campDetails[0].marketingChannel == "Email/Telemarketing" && campDetails[0].callAudit == "Yes")) {
                  this.setState({ marketingChannelFlag: true })
                }
                else {
                  this.setState({ marketingChannelFlag: false })
                }

                this.setState({ campDetails: campDetails, agencyID: campDetails[0].agencyID, reviewFlag: false })
              })
            document.getElementById('submitButton').disabled = false;
            document.getElementById('submitButton1').disabled = false; //sunita-task-3422-added code here for disabling the submit button as only one button get disabled as per requirement done the changes.


            //sunita-task-3271-get downloaded lead count
            fetch("/diQARole/getDIQaLeadCounts", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data)
            }).then(res => res.json())
              .then(getDIQaLeadCounts => {
                if (getDIQaLeadCounts.length > 0) {
                  var campID = getDIQaLeadCounts[0].campID;

                }

                let cnt = getDIQaLeadCounts.length;
                if (cnt < 0) {
                  cnt = 0;
                }
                this.setState({ getDIQaLeadCounts: cnt })
              }).catch(function (err) { console.log(err) });

            //sunita-task-3271-display all diqa leads.
            fetch("/diQARole/DIQaLeadDetails", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data)
            }).then(res => res.json())
              .then(DIQaLeadDetails => {
                let count = DIQaLeadDetails.length
                if (count < 0) {
                  count = 0;
                }
                this.setState({ DIQaLeadDetails: DIQaLeadDetails, DIQaLeadDetailsCount: count, loading1: false, loader2: "" }, () => { document.getElementById('fileName2').innerHTML = "No file Choosen" })//Sandeep-task-2892-added code for to show lead upload file is absence

              }).catch(function (err) { console.log(err) });

          }
          else {
            this.setState({ loader2: "" })
          }

        }).catch(function (err) {
          console.log("err##############################################" + err)


          this.setState({ loading1: false, loader2: "" })  // rutuja Jagtap 4123- added for stoping loader and showing No Data if theier is no data on QA side
        });

    } // end of componentwillMount

  }



  handleChangePageSize2(e) {
    var pageSize2 = e.target.value;

    this.setState({ pageSize2: pageSize2 });
  }
  render() {
    // array data

    //sunita-task-3271- below all qaLeadDetails cloumns ,qaLeadDetails2 columns  are used for antd table 
    const qaLeadDetails = [
      {
        title: 'Lead ID',
        dataIndex: 'leadInfoID',
        key: 'leadInfoID',
        width: 60,
        align: 'left',//Sandeep-task-3441
        render: (text, record) => //sunita-task-3271-added below code as per requirement
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.leadInfoID}
            </span>

          </div>


      },
      {

        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 105,
        align: 'left',//Sandeep-task-3441

        render: (text, record) => //sunita-task-3271-added below code as per requirement
          <div >

            <span style={{ fontSize: '13px', color: '#4F4F4F', wordBreak: "break-all" }}>

              {record.email}
            </span>

          </div>
      },

      {

        title: 'Job Title',
        dataIndex: 'jobTitle',
        key: 'jobTitle',
        width: 90,
        align: 'left',//Sandeep-task-3441

        render: (text, record) =>//sunita-task-3271-added below code as per requirement
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.jobTitle}
            </span>

          </div>
      },
      {

        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 90,
        align: 'left',//Sandeep-task-3441
        render: (text, record, i) => //sunita-task-3271-this code is alredy present so not done that much changes here
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {this.state.reviewFlag === true ?
                <label>{text}</label> :
                <Select style={{ height: '60%' }} defaultValue="DI QA ACCEPTED" onChange={(value, e) => { this.handleChange(i, value, "status") }} className="mySelect">
                  <Option value="DI QA ACCEPTED">DI QA ACCEPTED</Option>
                  <Option value="DI QA REJECTED">DI QA REJECTED</Option>
                </Select>
              }
            </span>
          </div>
      },
      {
        title: 'Reason For Rejection',//Sandeep-task-3441
        dataIndex: 'reason',
        key: 'reason',
        width: 90,
        align: 'left',//Sandeep-task-3441
        render: (text, record, i) => //sunita-task-3271-this code is alredy present so not done that much changes here
          <div className="mywidth" style={{ fontSize: '13px', color: '#4F4F4F' }}>
            {this.state.reviewFlag === true ? <label>{text}</label>
              : <Input onChange={(e) => { this.handleChange(i, e, "reason") }} />}
          </div>

      },

    ]

    //sunita-task-3271- below all qaLeadDetails2 are used for antd table columns same as above only added one extra column for voicelog link
    const qaLeadDetails2 = [
      {
        title: 'Lead ID',
        dataIndex: 'leadInfoID',
        key: 'leadInfoID',
        width: 60,
        align: 'left',//Sandeep-task-3441
        render: (text, record) => //sunita-task-3271-added below code as per requirement
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.leadInfoID}
            </span>

          </div>

      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 105,
        align: 'left',//Sandeep-task-3441

        render: (text, record) =>//sunita-task-3271-added below code as per requirement
          <div >
            {/* <span style={{ fontSize: '11px',wordBreak: "break-all" }}> */}
            <span style={{ fontSize: '13px', color: '#4F4F4F', wordBreak: "break-all" }}>

              {record.email}
            </span>

          </div>
      },


      {

        //sunita-task-3271-added below code as per requirement
        title: 'Voice Log Link',
        dataIndex: 'voiceLogLink',
        key: 'voiceLogLink',
        width: 90,
        align: 'left',//Sandeep-tsk-3441

        render: (text, record) =>
          <div >
            <span style={{ fontSize: '13px', color: '#1890FF' }}>

              {this.state.marketingChannelFlag === true ?

                //sunita-task-3271-added condition as per channel for showing voicelog link column
                ((record.channel === "Telemarketing" || record.Channel === "Telemarketing") || (((record.channel === "Email/Telemarketing" || record.Channel === "Email/Telemarketing") || (record.channel === "Email" || record.Channel === "Email")) && record.callAudit === "Yes")) ?
                  <a href='#' onClick={this.openLinkInBrowser} id={record.leadInfoID}>Link</a>
                  :
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>No Link Available</span>
                : ''}

            </span>

          </div>
      },

      {
        title: 'Job Title',
        dataIndex: 'jobTitle',
        key: 'jobTitle',
        width: 90,
        align: 'left',//Sandeep-task-3441

        render: (text, record) =>//sunita-task-3271-added below code as per requirement
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.jobTitle}
            </span>

          </div>
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        align: 'left',//Sandeep-task-3441
        render: (text, record, i) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3271-added css  as per requirement*/}
              {this.state.reviewFlag === true ?
                <label>{text}</label> :
                <Select style={{ height: '60%' }} defaultValue="DI QA ACCEPTED" onChange={(value, e) => { this.handleChange(i, value, "status") }} className="mySelect">
                  <Option value="DI QA ACCEPTED">DI QA ACCEPTED</Option>
                  <Option value="DI QA REJECTED">DI QA REJECTED</Option>
                </Select>
              }
            </span>
          </div>
      },
      {
        title: 'Reason For Rejection',
        dataIndex: 'reason',
        key: 'reason',
        width: 90,
        align: 'left',//Sandeep-task-3441
        render: (text, record, i) =>
          <div className="mywidth" style={{ fontSize: '13px', color: '#4F4F4F' }}> {/*sunita-task-3271-added css  as per requirement*/}
            {this.state.reviewFlag === true ? <label>{text}</label>
              : <Input onChange={(e) => { this.handleChange(i, e, "reason") }} />}
          </div>

      },

    ]

    //sunita-task-3271-added below code as per requirement
    function onChange(pagination, filters, sorter) {

    }



    const asterisk =
    {
      color: 'red',
      fontWeight: "500"
    }
    const cardBody = {
      height: "350px"
    }


    return (
      <div>
        <LeadReviewLoader
          loadingRef={this.loadingRef}
          closeLoadingRef={this.closeLoadingRef}
          message="Please wait. Saving the data...."
        /> {/* Sandeep-task-3341-added code for loader */}

        <Navigation />

        <div class="container-fluid " style={{ fontFamily: "roboto" }}>
          <div class="row" style={{ paddingTop: '97px' }}>{/*sunita-task-3271-added code as per requirement */}
            {/* Rutuja Jagtap task-3879 added Campaign Specification button for DIQA role */}

            <div class="col-xs-12 col-sm-12  col-lg-4 col-xl-4"></div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <center><label id="labelDI" >DI Quality Analyst</label></center>{/* sunita-task-3271- added  code as per new screen requirement */}

            </div>
            <div
              class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"
              style={{ textAlign: "center" }}>
              <button
                class="btn add-button"
                onClick={this.displayCampaignSpecification}>
                {" "}
                Campaign Specification
              </button>
              &nbsp;
            </div>
          </div>

          {/* <div class="row"> */}
          {/* sunita-task-3271- added below code as per new screen requirement */}
          <div class="card card-signin my-1">
            <div class="card-body" style={{ padding: '5px' }} >
              {/* <div class="col-sm-12 col-md-4 col-lg-3"> */}
              <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 qacampaignDetailsDiv " style={{ background: '#FFFFFF', borderRight: '2px solid #eee', padding: '0px', height: 'auto' }}> {/*sunita-task-3271-added code as per requirement */}
                {/* <div><h4>Campaign List</h4> <hr style={{ marginBottom: "0px" }} /></div> */}
                <div>
                  <center><label className="campLeadHeading" style={{ fontSize: '17px', color: '#14254A', marginTop: '10px', fontWeight: '500', fontFamily: 'roboto' }}>Campaigns for Lead Review :</label></center>
                </div>

                {/* sunita-task-3271-added below code for  search */}
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <span >
                      <input type="text" className="inputstyle4" name="search" placeholder="Search Campaign"
                        style={{
                          backgroundImage: 'url(searchIcon.png)',
                          backgroundPosition: '15px 6px',
                          backgroundSize: '15px 15px',
                          backgroundRepeat: "no-repeat",
                          width: '280px',
                          height: '28px',
                          fontSize: '12px',
                          // float: 'left',
                          // marginLeft: '17px',
                          border: '1px solid #CCCCCC',
                          padding: '12px 20px 15px 36px',
                          backgroundColor: "#FFFFFF",
                          // marginBottom: '5px',
                          // borderBottom: '1px solid #eee',
                          fontFamily: 'sans-serif',
                          borderRadius: '2px',
                        }}
                        onChange={this.handleChangeSearch} onPressEnter={this.handleChangeSearch}
                      >


                      </input>
                    </span>
                  </div>
                </div>

                <br />
                <hr />
                {/* sunita-task-3271-added below condition as search table data */}
                <div style={{ overflowY: 'scroll', height: 'auto', background: '#FFFFFF', padding: '0px', borderRight: '2px solid #eee', marginTop: '-9px' }}> {/*sunita-task-3271-added col and it's data as per requirement */}


                  {this.state.tableSearchText === "" ?

                    <div  >

                      {this.state.leadDetails.map(leadDetails => (
                        <div style={{ borderBottom: '1px solid #eee', padding: '5px 15px' }} className="campNames">
                          {/* 4610-Nilesh Add agency camp ID i.e clientCampID */}
                          <a href="#" className="reviewcampdata" id={leadDetails.campID} onClick={this.displayLeads}>{leadDetails.campID} - {leadDetails.clientCampID} - {leadDetails.campaignName} </a>
                        </div>
                      ))}

                    </div>

                    :

                    this.state.leadDetailsSearch.length > 0 ?
                      <div >

                        {this.state.leadDetailsSearch.map(leadDetailsSearch => (
                          <div style={{ borderBottom: '1px solid #eee', padding: '5px 15px' }} className="campNames">
                            {/* 4610-Nilesh Add agency camp ID i.e clientCampID */}
                            <a href="#" className="reviewcampdata" id={leadDetailsSearch.campID} onClick={this.displayLeads}>{leadDetailsSearch.campID} - {leadDetailsSearch.clientCampID} - {leadDetailsSearch.campaignName} </a>
                          </div>
                        ))}

                      </div>

                      : <center><label style={{ color: 'red', fontSize: 'medium', marginTop: '34px' }}>No Data Exist</label></center>
                  }

                </div>
              </div>

              {/* <br /> */}
              {/* <div id="class one Div" class="col-sm-12 col-md-8 col-lg-9">
              <div class="card" >
                <div className="card-body card-body2"> 
                  {/* <div> */}
              {/*sunita-task-3271-added below code as per requirement  */}
              {/* Rutuja Jagtap task-3879 Responsive*/}
              <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 qacampaignDetails" style={{ background: '#FFFFFF', paddingTop: '20px' }}>{/*sunita-task-3271-added code as per requirement */}
                <div id="pleaseWait"
                  style={{ color: 'red', fontWeight: 'bold', fontSize: "13px", left: '400px', top: '200px' }}
                  className={this.state.loading}></div>
                <div>
                  {this.state.campDetails.map((details) => {
                    return (
                      <div>
                        {/*sunita-task-3271-added below label css changes for campID,campname,start date,end date and type as per new requirement for alignment*/}
                        <div className="row" style={{ marginTop: '-9px' }} >
                          {/* <div className="col-sm-12 col-md-6 col-lg-8"> */}
                          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                            <label style={{ fontSize: '14px', color: 'black', fontWeight: '600' }}>Campaign ID :</label> &nbsp;<span>{details.campID}</span>
                          </div>
                          {/* </div> */}
                          {/* <div className="row"> */}
                          {/* <div className="col-sm-12 col-md-8 col-lg-8"> */}
                          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-8">
                            <label style={{ fontSize: '14px', color: 'black', fontWeight: '600' }}>Campaign Name : </label>
                            &nbsp;<span className="reviewcampdata1"> {details.campaignName}</span>
                          </div>
                          {/* </div> */}

                          {/* <div className="row"> */}
                          {/* <div className="col-sm-12 col-md-6 col-lg-3"> */}
                          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                            <label style={{ fontSize: '14px', color: 'black', fontWeight: '600' }}>Campaign Type :</label>
                            &nbsp;<span> {details.ABM}</span>
                          </div>

                          {/* <div className="col-sm-12 col-md-6 col-lg-3"> */}
                          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                            <label style={{ fontSize: '14px', color: 'black', fontWeight: '600' }}>Start Date :</label>
                            &nbsp;<span> {details.startDate}</span>
                          </div>

                          {/* <div className="col-sm-12 col-md-6 col-lg-3"> */}
                          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                            <label style={{ fontSize: '14px', color: 'black', fontWeight: '600' }}>End Date :</label>
                            &nbsp;<span>{details.endDate}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </div>

                <br />

                <hr />

                <div className="row" style={{ marginLeft: '0px' }}>

                  <div>
                    {/*sunita-task-3271-added below changes as per new requirement screen for instructions*/}
                    <h5><span style={{ fontWeight: "bold", color: "#F28C0F" }}>Offline Lead Review Instructions :- </span><br /></h5>
                    <span>1. Click on <b>"Download Leads" </b> to get Lead's File.</span><br />

                    <span>2. After reviewing the lead, Please update status as <b> "DI QA ACCEPTED" OR "DI QA REJECTED" </b>
                      in Status column in Lead's File.</span>
                  </div>
                  <div>
                    <span>3. If you rejecting the lead then please <b> add reason of rejection </b> in reason column in Lead's File.</span><br />
                    <span>4. Click on <b>"Upload Lead"</b> to upload reviewed Lead's File.</span>
                  </div>
                </div>
                <br />

                <div className="row">

                  <div className="col-sm-12 col-md-6 col-lg-6">{/*sunita-task-3160-added column for proper alignment*/}
                    <button class="btn add-button" type="button" onClick={this.downloadFile} style={{ backgroundColor: "#056eb8", color: "white", height: '32px', fontSize: '14px', fontFamily: 'sans-serif', fontWeight: '400', borderRadius: '4px' }} disabled={this.state.DIQaLeadDetails.length > 0 ? false : true} >{/*sunita-task-3271-added  css as per requirement */}
                      <i class="fa fa-cloud-download" aria-hidden="true"></i>
                      &nbsp;Download Leads
                    </button></div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <span className="mobileCss" >Download Leads: {this.state.getDIQaLeadCounts > 0 ? this.state.getDIQaLeadCounts : 0} &nbsp; | &nbsp;  New Leads : {(this.state.DIQaLeadDetailsCount) - (this.state.getDIQaLeadCounts)}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6" id="lead_button1">{/*sunita-task-3160-added column as per requirement */}
                    {/*sunita-task-3271-added css as per requirement */}
                    <label class=" btn btn-defaulttab" style={{ cursor: this.state.DIQaLeadDetails.length > 0 ? "default" : "no-drop", height: '32px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px', backgroundColor: 'transparent', color: '#193D8F', border: '1px solid #193D8F' }} disabled={this.state.DIQaLeadDetails.length > 0 ? false : true} >Upload Lead
                      <input disabled={this.state.DIQaLeadDetails.length > 0 ? false : true} id="abm1" key={this.state.filesData1} style={{ display: "none" }} type="file" onChange={this.fileupload} /> {/*-sunita-task-3271-added for file*/}
                    </label>
                    <span id="fileName2" style={{ width: "150px", textOverflow: "ellipsis", display: "contents", cursor: this.state.DIQaLeadDetails.length > 0 ? "default" : "no-drop" }} disabled={this.state.DIQaLeadDetails.length > 0 ? false : true}>No File Choosen</span>{/*sunita-task-3271-added as per requirement */}
                  </div>
                </div>

                <br />
                <div class="row" style={{ marginBottom: '6px' }}>{/*sunita-task-3160-added row and column as per requirement */}
                  <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-10">
                    <span style={{ fontWeight: "bold", marginTop: '115px', color: "#056eb8" }}>Online Lead Review & Instant Status Update :-<br />
                    </span>
                    <span>Total Leads :{this.state.DIQaLeadDetails.length}</span>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 btn_Submit">{/*sunita-task-3160-added button as per requirement */}
                    <button className="btn add-button" id='submitButton' onClick={this.handleSubmit} disabled={this.state.DIQaLeadDetails.length > 0 ? false : true} style={{ align: 'right', paddingBottom: '6px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px' }}>Submit {/*sunita-task-3271-added css as per requirement */}
                    </button>
                  </div>

                </div>

                {/*sunita-task-3271-added some attributes as per requirement in table */}
                {/* Sandeep-task-3441-added loader */}
                <div id={this.state.loader2}></div>
                <Table  {...this.state.tableState}
                  columns={this.state.marketingChannelFlag === true ? qaLeadDetails2 : qaLeadDetails}
                  rowClassName={"qaLeadDetails"}
                  onChange={onChange}
                  class="ant-table-thead"
                  className={tableCSS}
                  dataSource={this.state.DIQaLeadDetails}
                  pagination={{ pageSize: this.state.pageSize2, position: "bottom" }}
                  //loading={this.state.loading1} 
                  scroll={{ x: 1300, y: 1333 }} bordered={true} >
                  {/*className={tableCSS + " " + 'myFont'} >{/*sunita-task-3180-removed css as not needed*/}

                </Table>
                {/* <p> */}
                {/* sunita-task-3271-added below code for paginaion */}
                <div class="row">
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{ marginTop: '-52px', marginLeft: "5px", fontSize: '13px', color: '#4F4F4F', fontFamily: 'sans-serif' }}>
                    &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                    <select
                      defaultValue={this.state.pageSize2}
                      onChange={this.handleChangePageSize2}
                      id="pacing1"
                      class="input-small"
                      className="form-control"
                      name="pacing" style={{ width: '71px', height: '30px', display: 'initial', fontSize: '13px', fontFamily: 'sans-serif', color: '#4F4F4F' }}> {/*sunita-task-3271-added css as per requirement */}
                      <option value="100" selected>100</option>
                      <option value="200" >200</option>
                      <option value="300">300</option>
                    </select>
                  </div>
                </div>
                {/* </p> */}

                <br />
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-8" style={{ textAlign: "left" }}>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4" style={{ textAlign: "end", marginBottom: '40px' }}>
                    {/*sunita-task-3271-added css as per requirement */}
                    {/*sunita-task-3422-changed the id as it's giving pblm bcz same id's are given. */}
                    <button className="btn  add-button" id='submitButton1' onClick={this.handleSubmit} disabled={this.state.DIQaLeadDetails.length > 0 ? false : true} style={{ align: "right", paddingBottom: '6px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px' }} >Submit
                    </button>
                  </div>
                </div>
              </div>
              {/* </div>{/*3rd */}
              {/* </div>2nd */}
              {/* </div> 1st */}
              {/* </div> */}
            </div>
          </div>

        </div>
        <Footer />
      </div>

    );
  }




}
AgencyUnreadAlerts.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(AgencyUnreadAlerts));

// export default  AgencyUnreadAlerts;