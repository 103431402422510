/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Somnath Keswad
@Creation Date:04-04-2019
@Description:UI for  Publisher wise campaign report  under Agency view
*/
import React from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
// import { Modal, Button } from 'react-bootstrap';//kiran-2834-removing console warning-no used
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import {  Table} from 'antd'; //Rutuja 4840 added antd table
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Priyanka-task-3937-added default header
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { CardBody } from "mdbreact";
import { css } from 'emotion' //Rutuj
// var publisherName = [];
// var campaignName = [];
// var campaignStatus = [];
// var startDate = [];
// var validCampaignID = []; //kiran-2834-removing console warning-no used
//Rutuja Task 4840 added antd table css
const tableCSS = css({
  backgroundColor: 'white',
  fontFamily: "sans-serif",
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize: '12px !important',
    fontWeight: '500',
  },
  '& thead > tr >th': {
    textAlign: "center !important",
    color: 'white',
  }
});
class CampaignTraceReport extends React.Component {
  constructor() {
    super();
    this.state = {
      campDetails: [],
      campaignTressReport: [],
      assignByPubName: [],
      campaignName: '',
      campaignStatus: '',
      startDate: '',
      endDate: '',
      newsuccess: "",
      currentDate: '',
      pID: '',
      tableStatus: '',
      pName: '',
      btnDisable: 'true',
      publisherName: [],
      validStatus: 'false',
      reportStatus: '',
      campDetailsStatus: '',
      publisher: '',
      showTable: 'false',
      pub1: 'true',
      disableButton: '',
      status: '',
      fields: {},
      errors: {},
      tableState: { size: "small" }, //4840
    }
    this.handleChange = this.handleChange.bind(this);

    this.tressCampaignDetail = this.tressCampaignDetail.bind(this);

  }// end of constrcutor

  handleChange(e) {

    let fields = this.state;
    fields[e.target.name] = e.target.value;
    // const tableStatus = this.state.showTable;//kiran-2834-removing console warning-no used
    // const doesShow = this.state.showTable;

    if (this.state.pub1 === 'false') {
      this.setState({
        inputClassPublisher: "valid",

      });
    }
    this.setState({ fields, tableStatus: true });
  }// handleChange(e)

  tressCampaignDetail(e) {
    e.preventDefault();
    // let doesShow = this.state.showTable;
    // const { isAuthenticated, user } = this.props.auth;//kiran-2834-removing console warning-no used
    var campID = this.state.fields.campID;
    let data = { campID: campID }     //Priyanka--3937--removed id from data
    if (this.validateForm() === false) { // alert("Form Not Valid");
      return;
    } else {
      //For Valid Campaign ID
      fetch("reports/validCampID", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json()).then(res => {
        //Somnath Task-4024, Remove console.
        if (res.error1 === true) { //kiran-2834-removing console warning-changed == to ===
          this.setState({ inputClass: "disabled" })

          //  alert(res.message);
          var a = (res.message);
          this.setState({ newsuccess: a, validStatus: false });
        }
      }).catch(function (err) {
        console.log(err)
      });


      fetch("reports/campaignTressReport", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(campaignTressReport => {
          //Somnath Task-3981, Manupulate array for getting agencyID and PublisherID
          for (let i = 0; i < campaignTressReport.length; i++) {
            let { pID, agencyID, publisherName, agencyName } = campaignTressReport[i];
            if (!pID) { pID = "" }
            if (!agencyID) { agencyID = "" }
            if (publisherName) { publisherName = `(${publisherName})` } else { publisherName = `` }
            if (agencyName) { agencyName = `(${agencyName})` } else { agencyName = `` }
            let orgName = pID ? pID + publisherName : agencyID ? agencyID + agencyName : "";
            campaignTressReport[i].orgName = orgName;
          }
          this.setState({ campaignTressReport: campaignTressReport })
          if (this.state.campaignTressReport !== undefined) {//kiran-2834-removing console warning-changed != to !==
            this.setState({ reportStatus: false })
          }
        }).catch(function (err) {
          console.log(err)
        });


      fetch("reports/campTress", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(campDetails => {
          this.setState({ campDetails: campDetails })
          if (this.state.campDetails === undefined || this.state.campDetails === '') { //kiran-2834-removing console warning-changed == to ===
            this.setState({ campDetailsStatus: false })
          }
        }).catch(function (err) {
          console.log(err)
        });

      fetch("reports/assignByPubName", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(assignByPubName => {
          this.setState({ assignByPubName: assignByPubName })
        }).catch(function (err) {
          console.log(err)
        });
      if (this.state.tableStatus === true) {
        this.setState({ showTable: true, errors: '' });
      }
    } // else

  }// end of showPubDetails


  validateForm() {
    let IsValid = true;
    // var count = 0;
    // var status = '';

    //alert(this.state.validCampID);
    // let fields = this.state.fields;
    var campID = this.state.fields.campID;
    // var validCampID = this.state.validCampID;//kiran-2834-removing console warning-no used
    let errors = {};
    if ((!campID)) {

      IsValid = false;
      errors["campID"] = 'Please Enter Campaign ID';

      // this.state.pub1 = 'false';
      this.setState({pub1:false})//kiran-2834-removing console warning-use setState
      this.setState({
        inputClassPublisher: "invalid"
      })
    }
    else {
      if (isNaN(campID) === true || campID.includes(".")) {
        IsValid = false;
        errors["campID"] = 'Please enter only numerical values ';

        // this.state.pub1 = 'false';
        this.setState({pub1:false}) //kiran-2834-removing console warning-use setState
        this.setState({
          inputClassPublisher: "invalid"
        })
      }
    }

    this.setState(
      { errors: errors, newsuccess: '', showTable: false });


    return IsValid;
  }// end of validate

  componentDidMount() {

    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }


  } // end of componentDidMount()
  render() {


//Rutuja 4840 changed the table added antd table
    const columnsReport = [
     
      {
          title: <span>Date&nbsp;&nbsp;&nbsp;</span>,
          dataIndex: "campID",
          key: "campID",
          width: "10%",
          align: "left",
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                      {record.created}
                  </span>
                  <br />
              </div>
          ),
      },
      {
          title: <span>Agency / Publisher ID&nbsp;&nbsp;&nbsp;</span>,
          dataIndex: "firstName",
          key: "firstName",
          width: "10%",
          align: "left",
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                      {record.orgName}
                  </span>
                  <br />
              </div>
          ),
      },
      {
        title: <span>Status&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "lastName",
        key: "lastName",
        width: "10%",
        align: "left",
        render: (text, record) => (
            <div>
                {record.status==='Accept' ?
            (
              <span style={{ color: 'green', fontWeight: 'bold' ,fontSize: "14px" }}> {record.status}</span>
            ):
            record.status=== 'Approved'  ?
            (
              <span style={{ color: 'green', fontWeight: 'bold' ,fontSize: "14px" }}> {record.status}</span>
            ):
            record.status=== 'AcceptedCounter'  ?
            (
              <span style={{ color: 'green', fontWeight: 'bold' ,fontSize: "14px" }}> {record.status}</span>
            ):
            record.status === 'Counter' ?
            (
              <span style={{ color: 'orange', fontWeight: 'bold' ,fontSize: "14px" }}> {record.status}</span>

            ):
            record.status === 'Assign' ?
            (
              <span style={{ color: '#007bff', fontWeight: 'bold' ,fontSize: "14px" }}> {record.status}</span>

            ):
            record.status === 'New' ?
            (
              <span style={{ color: 'Black',  fontWeight: 'bold' ,fontSize: "14px" }}> {record.status}</span>

            ):
            record.status === 'Reject'  ?
            (
              <span style={{ color: 'Red',  fontWeight: 'bold' ,fontSize: "14px" }}> {record.status}</span>

            ):
            record.status === 'RejectedCounter'  ?
            (
              <span style={{ color: 'Red',  fontWeight: 'bold' ,fontSize: "14px" }}> {record.status}</span>

            ):
            record.status === 'Rejected' ?
            (
              <span style={{ color: 'Red',  fontWeight: 'bold' ,fontSize: "14px" }}> {record.status}</span>

            ):
            (
              <span style={{ fontSize: "14px" }}> {record.status}</span>
            )
            }
                <br />
            </div>
        ),
      },
      {
        title: <span>Description&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "created",
        key: "created",
        width: "85%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                  {/* kiran-4849-added unescape for removing %20 */}
                    {unescape(record.description)}
                </span>
                <br />
            </div>
        ),
    },
      {
        title: <span>Action By&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "leadCount",
        key: "leadCount",
        width: "10%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.firstName}
                </span>
                <br />
            </div>

        ),
      },
    ]








    // const { isAuthenticated, user } = this.props.auth; //kiran-2834-removing console warning-no used
    const asterisk = { color: 'red' }
    return (
      <div>
        <Navigation />

        <form method="" name="" onSubmit={this.tressCampaignDetail}>
          <div class="container-fluid" style={{ paddingTop: '90px', marginBottom: '60px' }}>
            <div className="card card-signin my-1">
              <div className="card-body">
                {/* rutuja task-4178 */}
                <div class="row">
                  <div
                    class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                    style={{ paddingLeft: "43px", marginLeft: "-20px" }}>
                      
{/* kiran-2834-removing console warning-added href="newdashboard" */}
                    <a
                    href="newdashboard"
                      onClick={() =>
                        this.props.history.push("/newdashboard", {
                          agencyID: this.state.agencyID,
                        })
                      } //karan-task-3684-replace query params
                      style={{ color: "#056eb8" }}>
                      <FaArrowAltCircleLeft
                        size={32}
                        style={{ float: "left" }}
                        title="Back to Dashboard"
                      />
                    </a>
                  </div>
                  {/* rutuja task-4178 */}
                  <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
                  {/* <div style={{fontSize:'14px',color:'red'}}>{this.state.errors.commonError}</div>  */}

                  {/* <div class="col-lg-4"> */}
                  {/* rutuja task-4178 */}
                  <div class="col-xs-10 col-sm-10 col-md-3 col-lg-2 col-xl-2">

                    <label id="label" style={{ overflow: "visible", marginTop: '10px' }}>Enter Campaign ID :<span style={asterisk}>*</span></label>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
                     {/* 4553-Nilesh Add input box type */}
                    <input type='number' id='campID' name='campID' value={this.state.campID} placeholder={' Enter Campaign ID'} onChange={this.handleChange} style={{ width: '200px', marginTop: '10px'  }} />
                    <div>
                      <span style={asterisk}> {this.state.errors.campID} </span>
                      {/* <span style={asterisk}> {this.state.newsuccess} </span> */}
                    </div>
                  </div>
                  &nbsp; &nbsp; &nbsp;
                  <div class=" col-md-2 col-lg-3 col-xl-3" >

                    <button class="btn   text-uppercase add-button" disabled={this.state.disableButton} type="submit" style={{ backgroundColor: "#2196F3", color: "white", marginTop: '10px'  }} >
                      Trace
                    </button>

                  </div>

                </div>
                <br />

                {this.state.showTable === true ?

                  <div id="pubReport" class="row">


                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                      <div class="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                          <div       //4178-Nilesh Added container
                            class="card"
                            style={{
                              backgroundColor: "#f8f8f8",
                              borderColor: "#707070",
                              borderWidth: "0.3px",
                            }}
                          >
                            {/* 4178-Nilesh Added cardBody */}
                            <CardBody style={{padding: "5px"}}>
                              <div class="row">
                                <div class="col-lg-12">
                                  <table>
                                    <tr class="col-lg-4">
                                      <td style={{ width: "160px" }}>
                                        <label id="label">Campaign Name</label>
                                      </td>
                                      <td>
                                        <span style={{marginLeft: "-28px"}}>
                                          :&nbsp; {this.state.campDetails.map(campDetails => (
                                            <span>&nbsp;{campDetails.campaignName}</span>
                                          ))}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr class="col-lg-4">
                                      <td style={{ width: "160px" }}>
                                        <label id="label">Start Date</label>
                                      </td>
                                      <td>
                                        <span style={{marginLeft: "-28px"}}>:&nbsp; {this.state.campDetails.map(campDetails => (
                                          <span>&nbsp;{campDetails.startDate}&nbsp; &nbsp;</span>
                                        ))}</span>
                                      </td>
                                    </tr>
                                    <tr class="col-lg-4">
                                      <td style={{ width: "160px" }}>
                                        <label id="label">End date</label>
                                      </td>
                                      <td>
                                        <span style={{marginLeft: "-28px"}}>:&nbsp;{this.state.campDetails.map(campDetails => (
                                          <span>&nbsp;{campDetails.endDate} &nbsp; &nbsp;</span>
                                        ))}</span>
                                      </td>
                                    </tr>

                                    <tr class="col-lg-4">
                                      <td style={{ width: "160px" }}>
                                        <label id="label">Campaign Status</label>
                                      </td>
                                      <td>
                                        <span style={{marginLeft: "-28px"}}>
                                          :&nbsp;  {this.state.campDetails.map(campDetails => (
                                            <span>&nbsp;{campDetails.campaignStatus}</span>
                                          ))}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr class="col-lg-4">
                                      <td style={{ width: "160px" }}>
                                        <label id="label">Assigned To</label>
                                      </td>
                                      <td>
                                        <span style={{marginLeft: "-28px"}}>
                                          :&nbsp;{this.state.assignByPubName.map(assignByPubName => (
                                            <span>&nbsp;{assignByPubName.publisherName}&nbsp;|&nbsp;</span>
                                          ))}
                                        </span>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </CardBody>
                          </div>
                          {/* Rutuja 4840 changed the table added antd table */}

                          <br />
                          <div class="table-responsive">
                            <Table
                              {...this.state.tableState}
                              bordered
                              dataSource={this.state.campaignTressReport}
                              columns={columnsReport}
                              className={`${tableCSS} "ant-table-thead table-responsive"`}
                              scroll={{ y: 2000, x: 800 }}
                              pagination={false} 
                              >
                            
                              
                            </Table >
                          </div>{" "}
                          {/*/ enf og lg-12*/}
                        </div>{/*end of row*/}
                      </div>
                      {/* </div> */}
                      {/*end of card-body*/}
                      {/* </div> */}
                      {/*end of card card-signin my-1*/}
                    </div>
                  </div> : null}


              </div>{/*Card-body */}
            </div>{/*Card */}
          </div > {/*Container */}
        </form >
        <Footer />
      </div >
    );
  }// end of renders
}// end of class


/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
CampaignTraceReport.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(CampaignTraceReport));

