/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sandeep Dhawale
*@fileName :rfpCampaignSpecification.js
 *Desc: UI for rfp campaign specification
// Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

import React, { Component } from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import RfpCampaignSpecificationDetails from "./rfpCampaignSpecificationDetails";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3767-added default header

const queryString = require("query-string");
//var greeting = ""; //rutuja 2832 commented greeting as never used in the file
class CampaignDashboard extends Component {
	constructor() {
		super();
		this.state = {
			campID: "",
			parentCampID: "",
			reallocationID: "",
			agencyID: "",
			rfpCampID: "",
		};
		this.handleBackButtonToDashboard =
			this.handleBackButtonToDashboard.bind(this);
		this.handleFileDownload = this.handleFileDownload.bind(this);
		this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
		this.handlePDFFileShowDownload = this.handlePDFFileShowDownload.bind(this);
	}

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			//Sonali-3716-VAPT
			if (this.props.location.state !== undefined) {
				const {
				step,proposalID,status
				} = this.props.location.state;
				this.setState({step,campID:proposalID,status})
			}
			
			//var parsed = queryString.parse(this.props.location.search); //rutuja 2832 commented parsed as never used in the file
			//var campID = parsed.proposalID;//Sonali-3716-accecssing from location.state
			//var parentCampID=parsed.parentCampID;
			const { user } = this.props.auth; //rutuja 2832 removed isAuthenticated as never used in the file
			var agencyID = user.id;
			this.setState({ agencyID: agencyID, campID: this.props.location.proposalID });
			//   fetch("campaign/CampaignDetails?campID=" + campID)
			//   .then(res => res.json())
			//   .then(campaignDetails =>{
			//     this.state.campaignName=campaignDetails[0].campaignName;
			//     }).catch(function (err) {
			//     console.log(err)
			//   });
			fetch("agencyDashboard/getRfpCampaignDetails?campID=" + this.props.location.state.proposalID)
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						rfpCampID: campaignDetails[0].campID,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}
//kiran-4409-added history.push as campid is displayed in url
	handleBackButtonToDashboard(e) {
		//var parsed = queryString.parse(this.props.location.search); //rutuja 2832 commented parsed as never used in the file
		
		var step = this.props.location.state.step;//Sonali-3716-accesing step from location.state
		var campID = this.props.location.state.proposalID;//Sonali-3716-accesing proposalID,status from location.state
		var status = this.props.location.state.status; //snehal-task-3509-Project's(DI) - Create RFP through XLS - FE
		if (step == "1") {
		//Sonali-4030-link changes
				this.props.history.push("/requestForProposal",{agencyID:this.state.agencyID}); //sonali-task-3716-replace query params

		} else if (step == "2") {
				//Sonali-4030-link changes
			this.props.history.push("/requestForProposalExsisting",{agencyID:this.state.agencyID}); //sonali-task-3716-replace query params
		}
		//karan-task-3636- redirect to rfp progress page
		else if (step == "3") {
			this.props.history.push("/rfpProgress"); //karan-task-3803-replace query params
		}
		//snehal-task-3509-Project's(DI) - Create RFP through XLS - FE
		else if (status == "RFPXlsx") {
			this.props.history.push("/createXLSRfp",{agencyID:this.state.agencyID}); //sonali-task-3716-replace query params
		} else if (campID) {
			// window.location.href = "/requestForProposal?campID=" + campID;
			this.props.history.push("/rfpProgress");
		}
	}

	createRfpCampaign(e) {
		var campID = e.target.id;
		window.location.href = "/agencyCampaignSetupInDetail?rfpCampID=" + campID;
	}

	handleFileDownload(e) {
		var campID = this.state.campID;
		// var parentCampID = this.state.parentCampID; //rutuja 2832 commented parentCampID as never used in the file
		/**
		 * @author Narendra Phadke
		 * @param  Description handle the Campaign related Files
		 * @return Description return All The File Download in Zip
		 */
		console.warn("campID===>" + campID);

		var formData = new FormData();
		formData.append("campID", campID);
		//const { user } = this.props.auth; //rutuja 2832 commented user as never used in the file
		// var userID = user.id; //rutuja 2832 commented userID as never used in the file
		// alert("Agency ID=="+userID);
		//This api is used for dynamic pdf genration
		//snehal-task-3766-specification VAPT api call
		fetch(
			"agency/downloadDynamicPDFCampaignDetailsforAgency?campID=" +
				campID +
				"&campName=" +
				this.state.campaignName 
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.handlePDFFileShowDownload);
		//snehal-task-3766-specification VAPT api call
		fetch(
			"agency/downloadCampaignDetailsforAgency?campID=" +
				campID +
				"&campName=" +
				this.state.campaignName 
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.handleFileShowDownload);
	} /** End of handle File Download */

	handleFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		let zipFileDownloadName =
			this.state.parentCampID + "-" + this.state.campaignName + ".zip";

		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/zip",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}

	handlePDFFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		let zipFileDownloadName =
			this.state.parentCampID +
			"-" +
			this.state.campaignName +
			"-CampaignSpecification.pdf";
		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/pdf",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}

	render() {
		var parsed = queryString.parse(this.props.location.search);
		this.state.campID = parsed.proposalID;
		// this.state.parentCampID=parsed.parentCampID
		// this.state.reallocationID=parsed.reallocationID;
		//  alert( this.state.campID)
		//  alert( this.state.parentCampID)
		//  alert(this.state.reallocationID);
		//  alert(this.state.campaignName)

		return (
			<div>
				<Navigation />
				<div style={{ paddingTop: "110px" }}>
					<div class="container-fluid">
						<div class="row" style={{ paddingBottom: "10px" }}>
							<div class="col-lg-2">
								<a>
									{" "}
									<FaArrowAltCircleLeft
										size={32}
										style={{ float: "left", color: "#056eb8" }}
										title="Back"
										onClick={this.handleBackButtonToDashboard}
									/>
								</a>
							</div>
							<div class="col-lg-2">
								<label></label>
							</div>
							<div class="col-lg-4">
								<label
									style={{
										color: "#056eb8",
										fontSize: "20px",
										paddingLeft: "120px",
									}}>
									RFP Details
								</label>
							</div>
							<div class="col-lg-2">
								<label></label>
							</div>
							{/* <div class="col-lg-2"  style={{paddingLeft:'120px'}}><button type="button"class="btn add-button" id="cmd"
                onClick={this.handleFileDownload}>Download</button></div> */}
						</div>
					</div>
					{/* <div>  */}
					{/* sonali-3716-VAPT-passing proposalID form location.state */}
					<RfpCampaignSpecificationDetails greeting={this.props.location.state.proposalID} />
					{/* </div> */}

					<div class="container-fluid" style={{ paddingBottom: "70px" }}>
						<div class="row">
							{/* <div class="col-lg-4"></div>
                   <div class="col-lg-3"><label></label></div> */}
							{/* <div class="col-lg-5" style={{paddingTop:'20px'}}><button type="button"class="btn add-button" id="cmd" style={{float:'left'}}
                onClick={this.handleFileDownload}>Download</button></div> */}
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CampaignDashboard.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(CampaignDashboard)
);
