/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import jsPDF from "jspdf";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3850-vAPT URL changes
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author Sanobar  Golandaj
 * Campaign Specification
 @version 1.0
 */

// var abmFile = [];
// var suppressionFile = [];
// var execlusionFile = [];
// var otherFile = [];
// var assetFile = [];
// var countryArray = [];
// var jobLevelArray = [];
// var companyRevenueArray = [];
// var jobFunctionArray = [];
// var employeeSizeArray = [];
// var customJobFunctionArray = [];
// var customJobLevelArray = [];
// var regionArray = [];
// var greeting = "";

// const queryString = require("query-string");
class CampaignSetupSpecificationDetails extends React.Component {
	constructor() {
		super();
		this.state = {
			fields: {},
			campCount: [],
			advCampID: "",
			advCampID: "",
			campIDTemp: " ",
			id: "",
			leadAllocation: 0,
			campaignName: "",
			btnStatus: "",
			// allocatedLead: "",
			pendingLead: 0,
			campaignVisibleTime: "",
			campAcceptReject: "",
			campaignSetupDetails: [],
			campaignBriefSetupDetails: [],
			campaignSuppDocs: [],

			agencyID: "",
			show: false,
			downloadFilesData: [],
			supportingDocs: [],
			campaignSetupSupportingDocs: [],
			publisherReports: [],
			agencyAllocationID: "",
		};
		this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
		this.handleFileDownload = this.handleFileDownload.bind(this);
		this.handleBackButtonToDashboard =
			this.handleBackButtonToDashboard.bind(this);

		// this.handleCampaignSetupDetails=this.handleCampaignSetupDetails.bind(this);
	} //end of constructor

	handleBackButtonToDashboard(e) {
		this.props.history.push("/campaignList", { agencyID: this.state.agencyID }); //karan-task-3684-replace query params
	}
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			var parsed = this.props.location.state; //Somnath Task-4035, replace query params
			if (parsed === undefined) {
				this.props.history.replace("/campaignList");
				return;
			}
			var advCampID = parsed.advCampID;
			const { user } = this.props.auth;
			var agencyID = user.id;
			var agencyAllocationID = parsed.agencyAllocationID;
			this.setState({ agencyID, advCampID });

			fetch("agency/campaignSetupDetails")
				.then((res) => res.json())
				.then((campaignSetupDetails) => {
					// alert("data==>"+JSON.stringify(campaignSetupDetails))
					this.setState({
						campaignSetupDetails: campaignSetupDetails,
						agencyAllocationID: campaignSetupDetails[0].agencyAllocationID,
					});

					this.setState({ agencyAllocationID: this.state.agencyAllocationID });
				})
				.catch(function (err) {
					console.log(err);
				});

			let data = {
				campID: advCampID,
				// agencyID: user.id,
				agencyAllocationID: agencyAllocationID,
			};

			fetch("/agency/campaignSetupDetailsForAgency", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignBriefSetupDetails) => {
					// alert("data==>"+JSON.stringify(campaignBriefSetupDetails))
					this.setState({
						campaignBriefSetupDetails: campaignBriefSetupDetails,
					});
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("/agency/campaignSetupSuppDocs?advCampID=" + advCampID + "")
				.then((res) => res.json())
				.then((campaignSetupSupportingDocs) => {
					// alert("data 2==>"+JSON.stringify(campaignSetupSupportingDocs))
					this.setState({
						campaignSetupSupportingDocs: campaignSetupSupportingDocs,
					});
					if (
						this.state.campaignSetupSupportingDocs != undefined ||
						this.state.campaignSetupSupportingDocs != ""
					) {
						for (
							var i = 0;
							i < this.state.campaignSetupSupportingDocs.length;
							i++
						) {
							this.state.supportingDocs.push(
								"*  " +
									this.state.campaignSetupSupportingDocs[i].advSupportDocID +
									"  " +
									this.state.campaignSetupSupportingDocs[i].advSuppDocName,
								"\n"
							);
						}
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	/**
	 * @author Sonali Kalke
	 * @param
	 * @return Description return All details of perticular campaign from table on campaign setup
	 */

	//  handleCampaignSetupDetails(e){
	//     alert("inisde handle")
	//     var advCampID=e.target.id;
	//     var agencyAllocationID=e.target.getAttribute('name');

	//     this.setState({advCampID : advCampID})
	//     const { user} = this.props.auth;
	//     let data={
	//       c  ampID:advCampID,agencyID:user.id,agencyAllocationID:agencyAllocationID
	//     }
	//     fetch("/agency/campaignSetupDetailsForAgency", {
	//       method: "POST",
	//       headers: {"Content-Type": "application/json"},
	//       body: JSON.stringify(data)
	//     }).then(res => res.json())
	//     .then((campaignBriefSetupDetails =>{
	//      this.setState({campaignBriefSetupDetails:campaignBriefSetupDetails})

	//      })).catch(function(err){console.log(err);});

	//     fetch("/agency/campaignSetupSuppDocs?advCampID="+advCampID+"")
	//     .then(res=>res.json())
	//     .then((campaignSetupSupportingDocs=>{
	//       this.setState({campaignSetupSupportingDocs:campaignSetupSupportingDocs})
	//       if(this.state.campaignSetupSupportingDocs!=undefined||this.state.campaignSetupSupportingDocs!='')
	//       {
	//         for(var i=0;i<this.state.campaignSetupSupportingDocs.length;i++)
	//         {
	//           this.state.supportingDocs.push('*  '+this.state.campaignSetupSupportingDocs[i].advSupportDocID+'  '+this.state.campaignSetupSupportingDocs[i].advSuppDocName,'\n');
	//         }
	//       }
	//     })).catch(function(err){console.log(err);});
	//    }

	//handle file download
	handleFileDownload(e) {
		var advCampID = this.state.advCampID;

		let downloadFileName = advCampID + "-CampaignSetupSpecification.pdf";
		var supportDocNames = this.state.supportingDocs.join("");
		var campaignName = this.state.campaignBriefSetupDetails[0].advCampaignName;
		this.setState({ campaignName: campaignName });

		var doc = new jsPDF();
		// doc.setFontType("bold");
		doc.setFont("calibri");
		doc.setTextColor("Green");
		doc.setFontSize(20);
		doc.text(65, 15, "Campaign ID :" + advCampID);
		doc.setLineWidth(1.5);
		doc.setDrawColor(0, 128, 0);
		doc.line(65, 18, 132, 18);
		// doc.setFontType("normal");
		doc.setFontSize(16);
		doc.setTextColor("orange");
		doc.text(20, 24, "Basic Details");
		doc.setLineWidth(0.8);
		doc.setDrawColor(255, 165, 0);
		doc.line(20, 26, 50, 26);
		doc.setFontSize(12);
		doc.setTextColor("black");
		doc.text(20, 33, "Campaign Name");
		doc.setTextColor("Blue");
		doc.text(73, 33, ":");
		var strArr = doc.splitTextToSize(
			"" + this.state.campaignBriefSetupDetails[0].advCampaignName + "",
			130
		);
		doc.text(strArr, 75, 33);
		// doc.setTextColor('black');
		// doc.text(20, 45, 'Client Name');
		// doc.setTextColor('Blue');
		// doc.text(73, 45, ':');
		// doc.text(75, 45, ''+this.state.campaignBriefSetupDetails[0].clientName);
		doc.setTextColor("black");
		doc.text(20, 40, "Campaign Type");
		doc.setTextColor("Blue");
		doc.text(73, 40, ":");
		doc.text(75, 40, "" + this.state.campaignBriefSetupDetails[0].advABM);

		doc.setTextColor("black");
		doc.text(20, 45, "Start Date");
		doc.setTextColor("Blue");
		doc.text(73, 45, ":");
		doc.text(75, 45, "" + this.state.campaignBriefSetupDetails[0].advStartDate);

		doc.setTextColor("black");
		doc.text(20, 50, "End Date");
		doc.setTextColor("Blue");
		doc.text(73, 50, ":");
		doc.text(75, 50, "" + this.state.campaignBriefSetupDetails[0].advEndDate);

		doc.setTextColor("black");
		doc.text(20, 55, "Time Zone");
		doc.setTextColor("Blue");
		doc.text(73, 55, ":");
		doc.text(75, 55, "" + this.state.campaignBriefSetupDetails[0].advTimezone);

		doc.setTextColor("black");
		doc.text(20, 60, "Leads Allocation");
		doc.setTextColor("Blue");
		doc.text(73, 60, ":");
		doc.text(
			75,
			60,
			"" + this.state.campaignBriefSetupDetails[0].advAllocatedLead
		);

		doc.setTextColor("black");
		doc.text(20, 65, "Additional Comments");
		doc.setTextColor("Blue");
		doc.text(73, 65, ":");
		doc.text(
			75,
			75,
			"" 
				
		);
 //mufiz-khan-Bug-4360-title:-Prod Issue-Agency/Advertiser Side-View Campaign-Campaign name --Camp Setup Spec PDF -- Additional Long Comments not showing in PDF File */

		
 doc.setTextColor('black');
 doc.text(20, 65, 'Additional Comments');

 let addComments = unescape(this.state.campaignBriefSetupDetails[0].advAdditionalComment);
 var lenghtOfComment = addComments.length;
 var rows = lenghtOfComment/60;
 var addCommentsArray = [] ;
 var lineForBreak = 60;
 var newLinestart = 0
 var afterBreakLine= 0
 for (newLinestart=0; newLinestart< rows ; newLinestart++){

   addCommentsArray[newLinestart] = addComments.slice(afterBreakLine,lineForBreak);
   lineForBreak= lineForBreak+60
   afterBreakLine= afterBreakLine+60
   // console.log("addCommentsArray====>  "+addCommentsArray[newLinestart]);
 }


 doc.text(73, 65, ':');
 var align= 65;
 for (newLinestart=0; newLinestart< rows ; newLinestart++){
   doc.setTextColor('Blue');
   const stripped =  addCommentsArray[newLinestart].replace(/\s+/g, ' ')
   doc.text(75, align,""+stripped);
	 align=align +5;
   
	 
}

		// doc.setTextColor('black');
		// doc.text(20, 70, 'Currency');
		// doc.setTextColor('Blue');
		// doc.text(73, 70, ':');
		// var strArr = doc.splitTextToSize(""+this.state.campaignBriefSetupDetails[0].currency+"", 130)
		// doc.text(strArr, 75, 70);

		// doc.setTextColor('black');
		// doc.text(20, 80, 'Budget');
		// doc.setTextColor('Blue');
		// doc.text(73, 80, ':');
		// doc.text(75, 80, ''+this.state.campaignBriefSetupDetails[0].budget);

		
		doc.setFontSize(16);
		doc.setTextColor('orange');
		doc.text(20, align, 'Supporting Documents');
		doc.setLineWidth(0.8);
		doc.setDrawColor(255,165,0);
		doc.line(20, align+3,72, align+3);
		doc.setFontSize(12);
		doc.setTextColor('black');
		doc.setTextColor('Blue');
		doc.setTextColor('black');
		doc.text(22, align+10, 'ID');
		doc.text(32, align+10, 'Name');
		doc.setTextColor('Blue');
		doc.text(20, align+15, ""+supportDocNames+"");
		 //mufiz-khan-Bug-4360-title:-Prod Issue-Agency/Advertiser Side-View Campaign-Campaign name --Camp Setup Spec PDF -- Additional Long Comments not showing in PDF File */


		doc.save(downloadFileName);

		var formData = new FormData();
		formData.append("advCampID", advCampID);
		const { isAuthenticated, user } = this.props.auth;
		var userID = user.id;
		//alert("Agency ID=="+userID);
		fetch("/agency/zipdownloadCampaignSetupDetails?advCampID=" + advCampID)
			.then((r) => r.blob())
			.then((downloadFilesData) =>
				this.setState({ downloadFilesData: downloadFilesData })
			)
			.then(this.handleFileShowDownload);
	}
	/** End of handle File Download */

	handleFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		//alert(this.state.campID);
		let zipFileDownloadName =
			this.state.advCampID + "-" + this.state.campaignName + ".zip";

		var newBlob = new Blob([this.state.downloadFilesData], {
			type: "application/zip",
		});

		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}
		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}

	render() {
		return (
			<div>
				<div class="container-fluid">
					<div class="row" style={{ paddingBottom: "20px" }}>
						<div class="col-lg-2">
							<a>
								{" "}
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left", color: "#056eb8" }}
									title="Back to Dashboard"
									onClick={this.handleBackButtonToDashboard}
								/>
							</a>
						</div>
						<div class="col-lg-2">
							<label></label>
						</div>
						<div class="col-lg-4">
							<label
								style={{
									color: "#056eb8",
									fontSize: "20px",
									paddingLeft: "100px",
								}}>
								Campaign ID:{this.state.advCampID}
							</label>
						</div>
						<div class="col-lg-2">
							<label></label>
						</div>
						<div class="col-lg-2" style={{ paddingLeft: "120px" }}>
							<button
								type="button"
								class="btn add-button"
								id="cmd"
								onClick={this.handleFileDownload}>
								Download
							</button>
						</div>
					</div>
				</div>
				<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
					<div style={{ border: "1px solid #cdcdcd" }}>
						<div class="table-responsive">
							<table id="myTable" className=" table table-bordered">
								<thead>
									<tr class="info">
										<th class="table-header">Basic Details</th>
									</tr>
								</thead>
							</table>
						</div>
						{this.state.campaignBriefSetupDetails.map(
							(campaignBriefSetupDetails) => (
								<div class="row">
									<div class="col-sm-12">
										<div class="col-sm-6">
											<label id="label" style={{ width: "130px" }}>
												Campaign ID:
											</label>
											&nbsp;
											<span>{campaignBriefSetupDetails.advCampID}</span>
										</div>
										<div class="col-sm-6">
											<label id="label" style={{ width: "130px" }}>
												Campaign Name:
											</label>
											&nbsp;
											<span>{campaignBriefSetupDetails.advCampaignName}</span>
										</div>
									</div>
									<div class="col-sm-12">
										<div class="col-sm-6">
											<label id="label" style={{ width: "130px" }}>
												Campaign Type:
											</label>
											&nbsp;
											<span>{campaignBriefSetupDetails.advABM}</span>
										</div>

										<div class="col-sm-6">
											<label id="label" style={{ width: "130px" }}>
												Start Date:
											</label>
											&nbsp;
											<span>{campaignBriefSetupDetails.advStartDate}</span>
										</div>
									</div>
									<div class="col-sm-12">
										{/* <div class="col-sm-6">
      <label id="label" style={{width:'130px'}}>Timezone:</label>&nbsp;
      <span>
        {campaignBriefSetupDetails.advTimezone}
        </span>
      </div> */}
									</div>
									<div class="col-sm-12">
										<div class="col-sm-6">
											<label id="label" style={{ width: "130px" }}>
												End Date:
											</label>
											&nbsp;
											{campaignBriefSetupDetails.advEndDate}
										</div>

										<div class="col-sm-6">
											<label id="label" style={{ width: "130px" }}>
												Total leads:
											</label>
											&nbsp;
											<span>{campaignBriefSetupDetails.advAllocatedLead}</span>
										</div>

										<div class="col-sm-6">
											<label id="label" style={{ width: "130px" }}>
												Advertiser Name:
											</label>
											&nbsp;
											<span>{campaignBriefSetupDetails.clientName}</span>
										</div>

										<div class="col-sm-6">
											<label id="label" style={{ width: "114px" }}>
												Additional Comments:
											</label>
											&nbsp;
											<span style={{    overflowWrap: "anywhere"}}>
												{unescape(
													campaignBriefSetupDetails.advAdditionalComment
												)}
											</span>
										</div>
									</div>
									{/* <div class="col-sm-6">
      <label id="label" style={{width:'130px'}}>Budget:</label>
        &nbsp;
      {campaignBriefSetupDetails.budget}
      </div>
      <br/> */}
								</div>
							)
						)}

						<br />

						<table id="myTable" className=" table table-bordered">
							<thead>
								<tr class="info">
									<th class="table-header">Supporting Document</th>
								</tr>
							</thead>
						</table>

						<div class="row">
							<div class="col-sm-12">
								<div class="col-sm-6">
									<label id="label" style={{ width: "130px" }}>
										ID:
									</label>
									<br />
									{this.state.campaignSetupSupportingDocs.map(
										(campaignSetupSupportingDocs) => (
											<div>{campaignSetupSupportingDocs.advSupportDocID}</div>
										)
									)}
									{/* {campaignSetupSupportingDocs.supportDocID} */}
								</div>

								<div class="col-sm-6">
									{/* <label id="label" style={{width:'130px'}}>Name:</label>
      &nbsp; */}
									<label id="label" style={{ width: "130px" }}>
										Name:
									</label>

									<br />
									{this.state.campaignSetupSupportingDocs.map(
										(campaignSetupSupportingDocs) => (
											<div>{campaignSetupSupportingDocs.advSuppDocName}</div>
										)
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-fluid" style={{ paddingBottom: "70px" }}>
					<div class="row">
						<div class="col-lg-4"></div>
						<div class="col-lg-3">
							<label></label>
						</div>
						<div class="col-lg-5" style={{ paddingTop: "20px" }}>
							<button
								type="button"
								class="btn add-button"
								id="cmd"
								style={{
									float: "right",
									backgroundColor: "#056eb8",
									color: "white",
									width: "min-content",
									height: "31px",
									fontSize: "14px",
								}}
								onClick={this.handleFileDownload}>
								Download
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
} // end of class CampaignSpecificationDashboardDetails

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CampaignSetupSpecificationDetails.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(CampaignSetupSpecificationDetails)
);
