/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Sanobar Golandaj
@Description:UI for finish campaign under quick upload campaign
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import Footer from '../layouts/footer'; 
import Navigation from '../layouts/navPage';

// use for fetch url value
// const queryString = require('query-string');//kiran-4748-removing console warning-no used
class   QuickFinishQuickUpload extends React.Component
{
    
    constructor(props) {
        super(props)
        // console.log(props)
        this.state = {
            campaignDetails: [],
             campID:'',
          
        }
         this.advertiserTabs=this.advertiserTabs.bind(this);
        this.logChange = this.logChange.bind(this);
       
    }

    advertiserTabs(e){   
        //snehal-task-3850-VAPT URL changes
        e.preventDefault();
        this.props.history.push('/advertiserTabs')
      localStorage.removeItem('activeKey');
     }

    componentDidMount() {
      if(!this.props.auth.isAuthenticated) {
        this.props.history.push('/userLogin');
    }else{

    
//    *Desc Fetch campID from Url
 //snehal-task-3850-VAPT URL changes
if(this.props.location.state!==undefined){
    var advCampID = this.props.location.state.advCampID;
    }
  this.setState({advCampID});
//  console.log("Control on react page");
// alert(advCampID);


    // let self = this;
     
    //   fetch("campaign/finishCampaign?campID="+campID+"")
    //     .then(res => res.json())
    //     .then(campaignDetails => self.setState({ campaignDetails: campaignDetails }));
    }
}
    logChange(e) {
          this.setState({[e.target.name]: e.target.value});  
      }
    
    
     render()
 { 
    
    // Description return All details of authentication
       
        // const {user} = this.props.auth;
        // const linkStyle  = {fontSize: '15px',  color: 'red'};


//   Fetch campID from Url
//   var parsed = queryString.parse(this.props.location.search);//kiran-4748-removing console warning-no used
 


    return(
        <div>
        <Navigation/>
        
        <div class="container" style={{marginBottom:"60px",paddingTop:'110px'}}>
        {/* <div class="row">
            
        <div class="col">
             <button class="btn" onClick={this.advertiserTabs} style={{backgroundColor:'#2196f3',borderColor:'#2196f3',float:'right'}}>
             Click here to Edit</button>
                </div>
            
         </div>  */}
         {/* <br/> */}
                                        
            <div class="card card-signin">
                <div class="card-body">
                <div class="row">
                  
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="col" align="center"><label id="labelsuccessheading" >
                                        Quick Campaign Update Successfully !!!</label>
                                        </div>
                                        {/* //snehal-task-3850-VAPT URL changes */}
                                         <div class="col" align="center"><label id="labelsuccessheading" >Campaign ID :&nbsp;{ this.props.location.state.advCampID} </label></div>
                                         {/* kiran-4748-removing console warning-added "#/" */}
                                        <div class="col" align="center"><label id="labelsuccessheading"  style={{color:'orange'}}>Please <a href='#/' onClick={this.advertiserTabs}>
                                            click here</a> to allocate agency.</label></div>
                                    
                                    
                                       
                </div>
            </div> {/* close of row */}
           
         </div> {/* close of card-body */}
         </div> {/* close of card*/}
         </div> {/* close of container */}
        
    

<Footer/>
</div>
        

);
 }
}

 /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      QuickFinishQuickUpload.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })

export default connect(mapStateToProps, { logoutUser })(withRouter(QuickFinishQuickUpload));

