/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Shivani Pathak
 *@fileName :
 *Desc: AgencyInformationEdit
 */

import React from "react";
import "antd/dist/antd.css";
import { Tabs } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import AgencyCompanyInformationEdit from "./agencyCompanyInformationEdit";
//import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Akash-task-4046
import AgencyGDPRCertificateEdit from "./agencyGDPRCertificateEdit";
import AgencyBankDetailsEdit from "./agencyBankDetailsEdit";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Priyanka--4062--added default header
//const queryString = require("query-string");  //Chaitanya-4750-Removed Console warning

class AgencyInformationEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agencyInfo: [],
      agencyBankInfo: [],
    };
    this.callback = this.callback.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
  }
  callback(key) {
    console.log(key);
  }
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth;
      //var user;snehal-task-3353-Code Error- Front End Code Error after installing npm
      var agencyID = user.id;
      //var agencyID = user.id;//Akash-task-4046
      //let data = {agencyID: user.id,};		//Priyanka--4062--removed params
      this.setState({ agencyID: agencyID });
      //Akash-task-4046
      fetch("/agencyOnBoardDetails/getAgencyInfoForEdit") //Priyanka--4062--removed params
        .then((res) => res.json())
        .then((agencyInfo) => {
          this.setState({
            agencyInfo: agencyInfo,
            AgencyName: agencyInfo[0].AgencyName,
            AgencyID: agencyInfo[0].agencyID,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
      //Akash-task-4046
      fetch("/agencyOnBoardDetails/getBankInfoForEdit") //Priyanka--4062--removed params
        .then((res) => res.json())
        .then((agencyBankInfo) => {
          this.setState({
            agencyBankInfo: agencyBankInfo,
            AgencyName: agencyBankInfo[0].AgencyName,
            AgencyID: agencyBankInfo[0].agencyID,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  handleBackButton(e) {
    e.preventDefault();   //Chaitanya-4750-Removed Console warning
    //const { user } = this.props.auth; //Chaitanya-4750-Removed Console warning
    this.props.history.push("/agencyInformation"); // karan-task-3723-replace query params
  }
  render() {
    const { TabPane } = Tabs;
    return (
      <div>
        <Navigation />
        <div class="container-fluid" style={{ paddingTop: "100px" }}>
          <br />
          <div>
            <a href="#/"    //Chaitanya-4750-Removed Console warning
            >   
              {" "}
              <FaArrowAltCircleLeft
                size={32}
                style={{
                  float: "left",
                  color: "#056eb8",
                  paddingBottom: "3px",
                }}
                title="Back"
                onClick={this.handleBackButton}
              />
            </a>
          </div>
          <div class="row">
            <div class="col-md-4 col-lg-4"></div>

            <div class="col-xs-12  col-sm-12 col-md-6 col-lg-4">
              {/* kiran -bug 4116-changed bootstrap class for responsiveness */}

              <span>
                <h4
                  style={{
                    textAlign: "center",
                    color: "#056eb8",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Edit Agency Information
                </h4>
              </span>
            </div>

            <div class=" col-md-4 col-lg-4"></div>
          </div>
          {/* End of 1st Row */}
          <div class="row">
            <div class="col-xs-12  col-sm-12 col-md-12 col-lg-12">
              <Tabs onChange={this.callback} type="card">
                <TabPane tab="Agency Information" key="1-Company Information">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
                    <AgencyCompanyInformationEdit />
                  </div>
                </TabPane>
                <TabPane tab="Global Compliance" key="3-Global Compliance">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
                    <AgencyGDPRCertificateEdit />
                  </div>
                </TabPane>
                <TabPane tab="Bank Details" key="4-Bank Details">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
                    <AgencyBankDetailsEdit />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
          {/* End of 2nd Row */}
        </div>
        <Footer />
      </div>
    );
  }
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AgencyInformationEdit.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(AgencyInformationEdit)
);
