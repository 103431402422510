/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Shende
 *@fileName :AddUserAdvertiser.js
 *Desc: Add User
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { Input,Tooltip,Table,Icon } from "antd"; //Aman-4749-(removed 'Form,Input,Cascader,Select,Row,Col,Checkbox,Button,AutoComplete,Card,'  not in use)-Console Warnings - Advertiser - Settings
import { css } from "emotion"; //Sandeep-task-3447-added css file
import "./addUserAdvertiser.css"; //Sandeep-task-3447-added css file
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
const Swal = require("sweetalert2");
const tableCSS = css({
	backgroundColor: "white",
	"& thead > tr > th": {
		backgroundColor: "#144999", //rutuja task -4524 changed background color
		color: "white",
		fontWeight: "500",
		// wordWrap:"break-word",
		// width:'10%'
	},
	"& thead > tr": {
		borderWidth: "2px",
		borderStyle: "solid",
	},
}); //Sandeep-task-3447-added css file

class AddUserAdvertiser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inputClass11: "btn add-button",
			fields: {},
			errors: "",
			loader2: "",
			userDomain: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.submitUser = this.submitUser.bind(this);
	} // end of constructor
	componentDidMount() {
		//Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
		//var parsed=this.props.location.state;//kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE
		const { user } = this.props.auth; //Aman-4749-(commented isAuthenticated - not in use)-Console Warnings - Advertiser - Settings
		//var advertiserID=user.id;//kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE

		var userName = user.name;
		userName = userName.split("@");
		var userName1 = "@" + userName[1];
		this.setState({ userDomain: userName1 });
		/* @author Sandeep Dhawale
		 * @param  Description get user details
		 * @return Description get user details
		 */
		this.setState({ loader2: "loader_report1" });
		fetch("/users/getUserList", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
		}) // karan-task-3723-vapt header and query params
			.then((res) => res.json())
			.then((getUserList) => {
				this.setState({ getUserList: getUserList, loader2: "" });
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
		let spanDisplay = "none";
		let requiredFeildValidate = "true";
		let userNameValidate = "true";
		let firstNameValidate = "true";
		let lastNameValidate = "true";
		let roleValidate = "true";

		fields["userName"] = fields["userName"] + this.state.userDomain;
		this.setState({ fields });

		if (
			!fields["userName"] &&
			!fields["role"] &&
			!fields["firstName"] &&
			!fields["lastName"]
		) {
			requiredFeildValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({ errors: errors });
		} else {
			if (
				this.state.userName === "" ||
				this.state.userName == null ||
				this.state.userName === undefined
			) {
				userNameValidate = false;

				errors["userName"] = <li>Please enter user name</li>;
				this.setState({ errors: errors });
			} else {
				//shivani - 3599 - changed RegEx as per validation requirement for hyphen .
				//  var pattern = new RegExp(/^([a-zA-Z]+[\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$/)
				//sunita-task-3782-changed below condition as per requirement
				//Sandeep-task-3893-Production issue-Email format issue--All roles
				var pattern = new RegExp(
					// /^\s*$|^[a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/)
					// eslint-disable-next-line no-useless-escape
					/^\s*$|^[\.a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@(?!gmail.com)(?!gmail.co.in)(?!yahoo.com)(?!yahoo.co.in)(?!facebook.com)(?!facebook.co.in)[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/
				);//mufiz 4091
			// 	if (!pattern.test(fields["userName"])) {
			// 		userNameValidate = false;
			// 		errors["userName"] = <li>Please enter valid user name</li>;
			// 		this.setState({ errors: errors });
			// 	} else {
			// 		errors["userName"] = "";
			// 		this.setState({ errors: errors });
			// 	}
			// }
			if (this.state.userName.match(pattern)) {  // mufiz Task-3984 Settings - Add User - Username should accept EmailID as per tooltip
				userNameValidate = true;   
				// errors["userName"] = 
				// this.setState({ errors: errors });
			} else  {
				userNameValidate = false;
				errors["userName"] = <li>Please enter valid user name</li>;
				this.setState({ errors: errors });
			}// mufiz Task-3984 Settings - Add User - Username should accept EmailID as per tooltip
		}
			//Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
			if (
				this.state.firstName === "" ||
				this.state.firstName === null ||
				this.state.firstName === undefined
			) {
				firstNameValidate = false;
				errors["firstName"] = <li>Please enter first name</li>;
				this.setState({ errors: errors });
			} else {
				let pattern = new RegExp(/^[a-zA-Z]+$/); //Aman-4749-(change var to let to resolve duplicate var issue)-Console Warnings - Advertiser - Settings
				if (!pattern.test(fields["firstName"])) {
					userNameValidate = false;
					errors["firstName"] = <li>Please enter valid first name</li>;
					this.setState({ errors: errors });
				} else {
					errors["firstName"] = "";
					this.setState({ errors: errors });
				}
			}
			//Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
			if (
				this.state.lastName === "" ||
				this.state.lastName === null ||
				this.state.lastName === undefined
			) {
				lastNameValidate = false;
				errors["lastName"] = <li>Please enter last name</li>;
				this.setState({ errors: errors });
			} else {
				let pattern = new RegExp(/^[a-zA-Z]+$/); //Aman-4749-(change var to let to resolve duplicate var issue)-Console Warnings - Advertiser - Settings
				if (!pattern.test(fields["lastName"])) {
					userNameValidate = false;
					errors["lastName"] = <li>Please enter valid last name</li>; //Sandeep-task-2909-added code for validation message
					this.setState({ errors: errors });
				} else {
					errors["lastName"] = "";
					this.setState({ errors: errors });
				}
			}
			//Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
			if (
				this.state.role === "" ||
				this.state.role === null ||
				this.state.role === undefined
			) {
				roleValidate = false;
				errors["role"] = <li>Please enter role</li>;
				this.setState({ errors: errors });
			} else {
				errors["role"] = "";
				this.setState({ errors: errors });
			}
		}
		//Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
		if (
			requiredFeildValidate === false ||
			userNameValidate === false ||
			firstNameValidate === false ||
			lastNameValidate === false ||
			roleValidate === false
		) {
			formIsValid = false;
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		} else if 
		(requiredFeildValidate === true ||  //// mufiz Task-3984 Settings - Add User - Username should accept EmailID as per tooltip //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
			userNameValidate === true   ||
			firstNameValidate === true  ||
			lastNameValidate === true   ||
			roleValidate === true) 
			{
			formIsValid = true;
			// alert("success");
		    }
		return formIsValid;
	}


	handleChange(e) {
		let fields = this.state;

		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	}

	submitUser(e) {
		//const { isAuthenticated, user } = this.props.auth; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
		let data = {
			//  userName:this.state.userName+this.state.userDomain,
			//sunita-task-3782-userName send to BE which user will enter
			userName: this.state.userName, 
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			role: this.state.role,
		}; // karan-task-3723-vapt header and query params

		if (this.validateForm() === false) {
			return;
		} else {
     // const self = this;//snehal-task-3768-vAPT URL changes //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
			let errors = {};
			fetch("/users/addUserDetails", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((userDetails) => {
					if (userDetails.length > 0) {
						errors["sameUser"] = <li>User Already Exists</li>;
						this.setState({ errors: errors });
					} else {
						fetch("/users/addUser", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						})
							.then(function (response) {
								if (response.status >= 400) {
									throw new Error("Bad response from server");
								}
								return response.json();
							})
							.then(function (response) {
								// success message with response
								//Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
								if (response.success === true) {
									Swal.fire({
										text: "User Added Successfully",
										type: "success",
										confirmButtonText: "Ok",
										allowOutsideClick: false,
										preConfirm: () => {
                    //   self.props.history.push("/advertiserDashboard",{advertiserID:user.id})//snehal-task-3768-vAPT URL changes
				
						window.location.reload(); //Mufiz-3975-added code for refreshing the page.
					
                    }
									});
								} else {
								}
							});
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}
	 //Rutuja-5101-Added function for active status
	changeUserStatusActive(e, record) {
		var orgID = record.userID;
		let data = {
		  orgID: orgID,
		};
		fetch("/users/setUserStatusActiveDIQA", {
		  method: "POST",
		  headers: { "Content-Type": "application/json" },
		  body: JSON.stringify(data),
		})
		  .then((res) => res.json())
		  .then((response) => {
			if (response.success === true) {
			  Swal.fire({
				text: "User Activated",
				type: "success",
				confirmButtonText: "Ok",
				allowOutsideClick: false,
				preConfirm: () => {
				  window.location.reload();
				},
			  });
			}
			this.setState({ isDisabled: true });
		  });
	  }
//Rutuja-5101-Added function for Inactive status
	changeUserStatusInactive(e,record) {
		// const {name, value } = e.target; //Nilesh-2897-Removing console warnings
		// var orgID = record.userID
		var userID = record.userID
		  let data={
			userID:userID,
		  }
		  fetch("/users/setUserStatusInactivePub", {
			  method: "POST",
			  headers: { "Content-Type": "application/json" },
			  body: JSON.stringify(data),
		  })
			  .then((res) => res.json())
			  .then((response) => {
				  if (response.success === true) {
					  Swal.fire({
						  text: "User Deactivated",
						  type: "success",
						  confirmButtonText: "Ok",
						  allowOutsideClick: false,
						  preConfirm: () => {
							   window.location.reload();
						  },
					  });
				  }
				  this.setState({isDisabled: true,})
			  });
	  }

	render() {
		const asterisk = { color: "red" };
		//Sandeep-task-3447-added table to get user details
		const addUserAdvertiser = [
			{
				title: "ID",
				dataIndex: "ID",
				key: "ID",
				width: 60,
				align: "left",
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "14px" }}>{record.userID}</span>
					</div>
				),
			},
			{
				title: "First Name",
				dataIndex: "firstName",
				key: "firstName",
				width: 100,
				align: "left",
				render: (text, record) => (
					<div>
						<div style={{ fontSize: "14px", width: "110px" }}>
							{record.firstName}
						</div>
					</div>
				),
			},
			{
				title: "Last Name",
				dataIndex: "lastName",
				key: "lastName",
				width: 100,
				align: "left",
				render: (text, record) => (
					<div>
						<div class="word-wrap" style={{ fontSize: "14px" }}>
							{record.lastName}
						</div>
					</div>
				),
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email",
				width: 180,
				align: "left",
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "14px" }}>{record.userName}</span>
					</div>
				),
			},
			{
				title: "Role",
				dataIndex: "role",
				key: "role",
				width: 45,
				align: "left",
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "14px" }}>{record.role}</span>
					</div>
				),
			},
			//Rutuja -5101 added action and status column 
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				width: 55,
				align: "Center",
				render: (text, record) => (
				  <div>
					{record.status === "N" ? (
					  <span style={{ fontSize: "14px" }}>Deactivated</span>
					) : (
					  <span style={{ fontSize: "14px" }}>Active</span>
					)}
				  </div>
				),
			  },
			{
				title: "Action",
				dataIndex: "Action",
				key: "Action",
				width: 40,
				align: "Center",
				render: (text, record) => (
				  <div>
					{record.status === "Y" ||
					record.status === null ||
					record.status === "" ? (
					  <button
						style={{ padding: "7px 7px", margin: "auto" }}
						className={this.state.inputClass11}
						onClick={(e) => this.changeUserStatusInactive(e, record)}
						type="submit"
					  >
						Deactive{" "}
					  </button>
					) : (
					  <button
						style={{ padding: "7px 7px", margin: "auto" }}
						className={this.state.inputClass11}
						onClick={(e) => this.changeUserStatusActive(e, record)}
						type="submit"
					  >
						Activate{" "}
					  </button>
					)}
			</div>
				),
			},
		];

		return (
			<div>
				<Navigation />
				<div class="container-fluid" style={{ paddingTop: "100px" }}>
				<div class="row">{/*mufiz-task-397 add new back button*/}
                       <div class="col-lg-4" style={{paddingLeft:'20px'}}>
                       <a href="./advertiserDashboard"  style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}}/></a>
                       </div>
					   </div>
					   <h2 align="center" id="labelDI" style={{marginTop:"-31px"}} >Add User Details</h2>
					   {/* Umesh-4254-R28.8-Prod issue-Advertiser login-setting option-Add User main Page issue */}
					   <div class="card-body" 
					 className="cardBodyClass advcardBodyClass container-fluid"  
					   style={{
                    margin:"0px",
                    backgroundColor:"#fff",
                    borderRadius:"4px" ,
					boxShadow:"grey 1px 1px 6px 0px"//mufiz-task-3975 styling a box
					}}>
						{/* <legend className="legend labelsubheading">User Details</legend> */}
						{/* //shivani-3284-passed ID for DI label consistency */}
						<div style={{ fontSize: "14px", color: "red" }}>
							{this.state.errors.commonError}
						</div>
						{/* rutuja task-4524 - showing error */}
						<div
							className="errorMsg"
							style={{ fontSize: "14px", color: "red" }}
							>
							{this.state.errors.userName}
							{this.state.errors.role}
							{this.state.errors.firstName}
							{this.state.errors.lastName}
							{this.state.errors.sameUser}
							</div>
						
						<div class="row">
							<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
								<label id="label">
									User Name{" "}
									<span style={asterisk}>
										*&nbsp;
										<Tooltip title=" User name should be Valid Email Id.">
											<Icon
												type="question-circle-o"
												className="iconbackground"
											/>
											{/*sunita-task-3818-added classname as per requirement for removing white patches */}
										</Tooltip>
									</span>
								</label>
								<br />
								<Input
									// addonAfter={this.state.userDomain} //sunita-task-3782-commented this attribute as want to enable the domain field.
									value={this.state.userName}
									onChange={this.handleChange}
									type="text"
									id="PuserName"
									className="form-control"
									name="userName"
									style={{ width: "60%", /*height: "auto" */}}
									required></Input>
									<div
							className="errorMsg"
							style={{ fontSize: "14px", color: "red" }}>
							
							
						</div>
					
							</div>
							<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 Proleinputbox">
								{/*sunita-task-3782-added classname as per requirement */}
								<label id="label">
									Role <span style={asterisk}>*</span>
								</label>
								<select
									value={this.state.role}
									onChange={this.handleChange}
									type="text"
									id="role"
									className="form-control"
									name="role"
									style={{ width: "60%", height: "auto" }}
									required>
									<option value="None Selected" disabled selected>
										None Selected
									</option>
									<option value="ADV">advertiser</option>
								</select>
								<div
							className="errorMsg"
							style={{ fontSize: "14px", color: "red" }}>
							
							
							
						</div>
							</div>
						</div>
						<br />
						{/* end of 1st row */}
						<div class="row">
							<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
								<label id="label">
									First Name <span style={asterisk}>*</span>
								</label>
								<input
									value={this.state.firstName}
									onChange={this.handleChange}
									type="text"
									id="firstName"
									className="form-control"
									name="firstName"
									style={{ width: "60%", /*height: "60%"*/ }}
									required
								/>
										<div
							className="errorMsg"
							style={{ fontSize: "14px", color: "red" }}>
							
							
							
						</div>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 Proleinputbox">
								<label id="label">
									Last Name <span style={asterisk}>*</span>
								</label>
								<input
									value={this.state.lastName}
									onChange={this.handleChange}
									type="text"
									id="lastName"
									className="form-control"
									name="lastName"
									style={{ width: "60%", /*height: "60%"*/ }}
									required
								/>
										<div
							className="errorMsg"
							style={{ fontSize: "14px", color: "red" }}>
							
							
						</div>
							</div>
						</div>
						<br />
						{/* end of 2nd row */}
						<button
							
							style={{float: "right", marginTop:"-55px"}}//mufiz-task-3975 chang location from left to right
							class="btn add-button buttonSave"
							onClick={this.submitUser}
							type="submit">
							Save{" "}
						</button>
					</div>
					{/* end of card body */}
				</div>
				{/* end of container-fluid */}
				<br></br>
				<p>
					{" "}
					<Table
						{...this.state.tableState}
						//id="myTable"  //rutuja task-4524 table responsive issue
						bordered
						dataSource={this.state.getUserList}
						rowKey="campID"
						columns={addUserAdvertiser}
						className={`${tableCSS} "ant-table-thead"`}//Aman-4749-(changed 2 className in One)-Console Warnings - Advertiser - Settings
						//className="ant-table-thead"
						//className={tableCSS}
						scroll={{ x: 1200, y: 1333 }}></Table>
				</p>
				<br></br>
				<Footer />
			</div>
		);
	} // end of render
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AddUserAdvertiser.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(AddUserAdvertiser)
);
