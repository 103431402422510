/* Copyright(c) 2021 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Shivani pathak
@Creation Date:28-06-2021
@Description:UI for job level and job title mapping .
*/

import React from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Input, Button, Icon, Divider, Tooltip } from "antd";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { Checkbox } from "react-bootstrap";
import { css } from "emotion";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import styled from "styled-components";
import "./jobLevelMapping.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params

const Swal = require("sweetalert2");

//shivani-3517- imported arrays from jobTitleFile.
const jobTitleOptionsForVP =
	require("../JobTitleFile/jobTitlesArray").jobTitleOptionsForVP;
const jobTitleOptionsForManager =
	require("../JobTitleFile/jobTitlesArray").jobTitleOptionsForManager;
const jobTitleOptionsForHead =
	require("../JobTitleFile/jobTitlesArray").jobTitleOptionsForHead;
const jobTitleOptionsForDirector =
	require("../JobTitleFile/jobTitlesArray").jobTitleOptionsForDirector;
const jobTitleOptionsForStaff =
	require("../JobTitleFile/jobTitlesArray").jobTitleOptionsForStaff;
const jobTitleOptionsForClevel =
	require("../JobTitleFile/jobTitlesArray").jobTitleOptionsForClevel;
const jobTitleOptions =
	require("../JobTitleFile/jobTitlesArray").jobTitleOptions;

//shivani-3517- added table CSS.

const tableCSS2 = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "12px",
		textAlign: "center",
		fontWeight: "500",
	},
	"& thead > tr >th": {
		backgroundColor: "#28166f !important",
		textAlign: "center",
		color: "white",
	},
});
const queryString = require("query-string");
const PickyStyle = styled(Picky)`
	background-color: #bd141480 !important;
	border-radius: "4px !important" #-list.picky__dropdown {
		overflow: "hidden !important";
	}
	.picky {
		width: 148px !important;
		height: 32px !important;
	}
`;

class JobLevelMapping extends React.Component {
	constructor() {
		super();
		this.state = {
			pageSize: 5,
			generateMapping: [],
			token: "",
			agencyID: "",
			jobLevel: "Select Job Level",
			errors: {},
			jobLevelArray: [],
			jobTitleArray: [],
			getcustomJobTitle: [],
			getjobTitleMapping: [],
			getMappingDetails: [],
			jobTitle: [],
			customJobTitleArr: [],
			customJobTitle: "",
			selectedJobTitle: [],
			originalJobTitle: [],
			loader: "",
			jobLevelFlag: true,
			addBtnFlag: false,
			titleRecord: -1,
			singleRecordsArr: [],
			custArray: [],
			orgArray: [],
			jobTitlechk: false,
			mappedJobLevel: ["VP", "Manager", "Staff", "C-Level", "Head", "Director"],
			mappedJobLevel1: [],
			optionArray: [],
			jobLevel2: "",
			newData: [],
			jobLevelfordropdown: [],
		};
		//shivani-3517- bind all the functions here.

		this.handleChangePageSize = this.handleChangePageSize.bind(this);
		this.handleChangeforJobLevel = this.handleChangeforJobLevel.bind(this);
		this.addButtonHandleChange = this.addButtonHandleChange.bind(this);
		this.editJobTitle = this.editJobTitle.bind(this);
		this.customJobTitleHandleChange =
			this.customJobTitleHandleChange.bind(this);
		this.customJobTitleInputHandleChange =
			this.customJobTitleInputHandleChange.bind(this);
		this.onJobTitleHandleChange = this.onJobTitleHandleChange.bind(this);
		this.handleChangeForTableData = this.handleChangeForTableData.bind(this);
	}
	/**
	 * @author : Shivani Pathak-task 3517
	 * @description : handlechange for pageSize .
	 */
	handleChangePageSize(e) {
		var pageSize = e.target.value;
		this.setState({ pageSize: pageSize });
	}
	/**
	 * @author : Shivani Pathak-task 3517
	 * @description : handlechange for jobLevel .
	 */
	handleChangeforJobLevel(e) {
		let jobLevel = e.target.value;
		this.setState({ jobLevel, orgArray: [], custArray: [] });
	}
	/**
	 * @author : Shivani Pathak-task 3517
	 * @description : handlechange for validation .
	 */
	validateForm() {
		let errors = {};
		let formIsValid = true;
		let jobLevel = this.state.jobLevel;
		let jobLevelfordropdown = this.state.jobLevelfordropdown;
		let orgArray = this.state.orgArray;
		let custArray = this.state.custArray;
		let jobLevelValidate = true;
		let orgArrayValidate = true;
		let custJobTitleValidate = true;
		let custJobTitleValidate2 = true;
		if (this.state.jobLevelFlag == true) {
			if (jobLevel == "Select Job Level") {
				errors["jobLevel"] = <li>Please select job level.</li>;
				jobLevelValidate = false;
				this.setState({ errors: errors });
			} else {
				errors["jobLevel"] = "";
				this.setState({ errors: errors });
			}
		} else {
			if (
				jobLevelfordropdown == "" ||
				jobLevelfordropdown == undefined ||
				jobLevelfordropdown == null
			) {
				errors["jobLevel"] = <li>Please select job title.</li>;
				orgArrayValidate = false;
				this.setState({ errors: errors });
			} else {
				errors["jobLevel"] = "";
				this.setState({ errors: errors });
			}
		}

		{
			/* //shivani-3673-changed id for job Title feild . */
		}
		if (orgArray == "" || orgArray == undefined || orgArray == null) {
			errors["jobTitle_Mapping"] = <li>Please select job title.</li>;
			orgArrayValidate = false;
			this.setState({ errors: errors });
		} else {
			errors["jobTitle_Mapping"] = "";
			this.setState({ errors: errors });
		}

		var pattern = new RegExp(/^[A-Za-z\n ,|.\-&:()]+$/);
		if (document.getElementById("customjobTitle").checked) {
			if (custArray == "") {
				errors["customjobTitleInput"] = <li>Please add custom job title.</li>;
				this.setState({ errors: errors });

				custJobTitleValidate = false;
			} else {
				if (!pattern.test(custArray)) {
					errors["customjobTitleInput"] = (
						<li>Please add valid values custom job title.</li>
					);
					this.setState({ errors: errors });
					custJobTitleValidate2 = false;
				} else {
					errors["customjobTitleInput"] = "";
					this.setState({ errors: errors });
				}
			}
		}
		if (
			jobLevelValidate == false ||
			orgArrayValidate == false ||
			custJobTitleValidate == false ||
			custJobTitleValidate2 == false
		) {
			formIsValid = false;
			this.setState({
				errors: errors,
			});
		} else {
			formIsValid = true;
		}
		return formIsValid;
	}
	/**
	 * @author : Shivani Pathak-task 3517
	 * @description : handlechange for add button which is used for mapping .
	 */
	addButtonHandleChange() {
		const { user } = this.props.auth;
		const token = user.token;
		const agencyID = user.id;

		let combineJobTitles = this.state.orgArray.concat(this.state.custArray);
		var combineJobTitles_array = combineJobTitles.toString();
		var combineJobTitles_array1 = combineJobTitles_array.split(",").join("|");

		let jobLevel = this.state.jobLevel;

		let jobLevelfordropdown = this.state.jobLevelfordropdown;
		var jobLevel_array = jobLevelfordropdown.toString();

		if (this.state.jobLevelFlag === true) {
			this.setState({ combineJobTitles_array1, jobLevel });
		} else {
			this.setState({ combineJobTitles_array1, jobLevel_array });
		}
		if (this.validateForm() === false) {
			return;
		} else {
			let data = {
				jobLevel: jobLevel,
				jobTitle: combineJobTitles_array1,
			}; // karan-task-3723-vapt header and query params
			let data1 = {
				jobLevel: jobLevel_array,
				jobTitle: combineJobTitles_array1,
			}; // karan-task-3723-vapt header and query params

			if (this.state.jobLevelFlag === true) {
				fetch("/campaignNew/createMapping", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					}, // karan-task-3723-vapt header and query params
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						if (this.state.jobLevelFlag === true) {
							if (res.success === true) {
								Swal.fire({
									text: "Job level and job title mapping added successfully.",
									type: "success",
									confirmButtonText: "Ok",
									allowOutsideClick: true,
									preConfirm: () => {
										this.handleChangeForTableData();
										window.location.reload();
									},
								});
							}
						} else {
							if (res.success === true) {
								Swal.fire({
									text: "Job level and job title mapping edited successfully.",
									type: "success",
									confirmButtonText: "Ok",
									allowOutsideClick: true,
									preConfirm: () => {
										this.handleChangeForTableData();
										window.location.reload();
									},
								});
							}
						}
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				fetch("/campaignNew/createMapping", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					}, // karan-task-3723-vapt header and query params
					body: JSON.stringify(data1),
				})
					.then((res) => res.json())
					.then((res) => {
						if (this.state.jobLevelFlag === true) {
							if (res.success === true) {
								Swal.fire({
									text: "Job level and job title mapping added successfully.",
									type: "success",
									confirmButtonText: "Ok",
									allowOutsideClick: true,
									preConfirm: () => {
										this.handleChangeForTableData();
										window.location.reload();
									},
								});
							}
						} else {
							if (res.success === true) {
								Swal.fire({
									text: "Job level and job title mapping edited successfully.",
									type: "success",
									confirmButtonText: "Ok",
									allowOutsideClick: true,
									preConfirm: () => {
										this.handleChangeForTableData();
										window.location.reload();
									},
								});
							}
						}
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		}
	}
	/**
	 * @author : Shivani Pathak-task 3517
	 * @description : handlechange for edit the mapping .
	 */
	editJobTitle(e) {
		let jobLevelfordropdown = [];
		var jobLevelfordropdown1;
		var jobTitle;
		jobTitle = e.target.getAttribute("jobTitle");
		jobLevelfordropdown1 = e.target.getAttribute("jobLevelfordropdown");
		jobLevelfordropdown.push(jobLevelfordropdown1);

		let jobTitleArrayforpicky = [];
		jobTitleArrayforpicky = jobTitle.split(",");

		this.setState({ jobLevelfordropdown: jobLevelfordropdown });

		let getMappingDetails = [...this.state.getMappingDetails];
		let getcustomJobTitle = [];
		for (var i = 0; i < getMappingDetails.length; i++) {
			getcustomJobTitle.push(getMappingDetails[i].jobTitleForMapping);
		}

		let singleRecordsArr = [];
		let cust = [];
		let org = [];

		for (let i = 0; i < getMappingDetails.length; i++) {
			let originalJobTitle = [];
			let customJobTitleArr = [];
			let singleArr = getMappingDetails[i].jobTitleForMapping.split(",");

			for (let j = 0; j < singleArr.length; j++) {
				if (jobLevelfordropdown == getMappingDetails[i].jobLevelForMapping) {
					if (
						jobTitleOptionsForVP.includes(singleArr[j].trim()) ||
						jobTitleOptionsForManager.includes(singleArr[j].trim()) ||
						jobTitleOptionsForHead.includes(singleArr[j].trim()) ||
						jobTitleOptionsForDirector.includes(singleArr[j].trim()) ||
						jobTitleOptionsForStaff.includes(singleArr[j].trim()) ||
						jobTitleOptionsForClevel.includes(singleArr[j].trim())
					) {
						originalJobTitle.push(singleArr[j]);
					} else {
						customJobTitleArr.push(singleArr[j]);
					}
				}
			}
			if (jobLevelfordropdown == getMappingDetails[i].jobLevelForMapping) {
				singleRecordsArr.push(customJobTitleArr);
				singleRecordsArr.push(originalJobTitle);
			}
		}
		cust = singleRecordsArr[0];
		org = singleRecordsArr[1];

		if (
			document.getElementById("jobLevel") != "" &&
			(jobTitle !== undefined ||
				jobTitle !== "undefined" ||
				jobTitle !== "" ||
				jobTitle !== null) &&
			(singleRecordsArr[0] !== undefined ||
				singleRecordsArr[0] !== "undefined" ||
				singleRecordsArr[0] !== "" ||
				singleRecordsArr[0] !== null)
		) {
			this.state.errors.jobLevel = "";
			this.state.errors.jobTitle = "";
			this.state.errors.customjobTitleInput = "";
		}

		this.setState({
			jobTitle: jobTitleArrayforpicky,
			jobLevelFlag: false,
			jobLevelfordropdown,
			custArray: singleRecordsArr[0],
			orgArray: singleRecordsArr[1],
		});
		// this.setState({jobLevel2})

		if (singleRecordsArr[0].length > 0) {
			this.setState({ jobTitlechk: true });
			document.getElementById("customjobTitle").checked = true;
			document.getElementById("customjobTitleInput").style.display = "block";
		} else if (singleRecordsArr[0] == "") {
			this.setState({ jobTitlechk: false });
			document.getElementById("customjobTitle").checked = false;
			document.getElementById("customjobTitleInput").style.display = "none";
		}
	}
	/**
	 * @author : Shivani Pathak-task 3517
	 * @description : handlechange for checkbox .
	 */
	customJobTitleHandleChange(e) {
		if (document.getElementById("customjobTitle").checked) {
			document.getElementById("customjobTitleInput").style.display = "block";
		} else {
			document.getElementById("customjobTitleInput").style.display = "none";
		}
	}
	/**
	 * @author : Shivani Pathak-task 3517
	 * @description : handlechange for custom job title .
	 */
	customJobTitleInputHandleChange(e) {
		let custArray = e.target.value;
		this.setState({ custArray });
	}

	/**
	 * @author : Shivani Pathak-task 3517
	 * @description : handlechange for jobTitle .
	 */
	/* //shivani-3673-changed id for job Title feild . */
	onJobTitleHandleChange = (value) => {
		let jobTitleArray = [];
		const name = "jobTitle_Mapping";
		for (var j = 0; j < value.length; j++) {
			jobTitleArray.push(value[j].name);
		}
		this.setState({ orgArray: value });
	};
	/**
	 * @author : Shivani Pathak-task 3517
	 * @description : handlechange for table data (will mount) .
	 */
	handleChangeForTableData() {
		this.setState({ loader: "loader_mapping" });
		fetch("/campaignNew/getMapping", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		}) // karan-task-3723-vapt header and query params
			.then((res) => res.json())
			.then((getMappingDetails) => {
				document.getElementById("customjobTitleInput").style.display = "none";

				let mappedJobLevel1 = [...this.state.mappedJobLevel];
				var newData = [],
					jobLevelfordropdown = [];

				function arr_diff(a1, a2) {
					var a = [],
						diff = [];

					for (var i = 0; i < a1.length; i++) {
						a[a1[i]] = true;
					}

					for (var i = 0; i < a2.length; i++) {
						if (a[a2[i]]) {
							delete a[a2[i]];
						} else {
							a[a2[i]] = true;
						}
					}

					for (var k in a) {
						diff.push(k);
					}

					return diff;
				}
				let jobLevelFromDB = [];

				for (var i = 0; i < getMappingDetails.length; i++) {
					jobLevelFromDB.push(getMappingDetails[i].jobLevelForMapping);
				}
				jobLevelfordropdown = arr_diff(mappedJobLevel1, jobLevelFromDB);
				this.setState({ jobLevelfordropdown });

				let getcustomJobTitle = [];

				for (var i = 0; i < getMappingDetails.length; i++) {
					getcustomJobTitle.push(getMappingDetails[i].jobTitleForMapping);
				}

				let singleRecordsArr = [];
				for (let i = 0; i < getMappingDetails.length; i++) {
					let originalJobTitle = [];
					let customJobTitleArr = [];
					let singleArr = getMappingDetails[i].jobTitleForMapping.split(",");
					for (let j = 0; j < singleArr.length; j++) {
						if (
							jobTitleOptionsForVP.includes(singleArr[j].trim()) ||
							jobTitleOptionsForManager.includes(singleArr[j].trim()) ||
							jobTitleOptionsForHead.includes(singleArr[j].trim()) ||
							jobTitleOptionsForDirector.includes(singleArr[j].trim()) ||
							jobTitleOptionsForStaff.includes(singleArr[j].trim()) ||
							jobTitleOptionsForClevel.includes(singleArr[j].trim())
						) {
							originalJobTitle.push(singleArr[j]);
						} else {
							customJobTitleArr.push(singleArr[j]);
						}
					}
					singleRecordsArr.push({
						customJobTitleArr,
						originalJobTitle,
					});
				}

				this.setState({
					getMappingDetails,
					loader: "",
					getcustomJobTitle,
					singleRecordsArr,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			this.handleChangeForTableData();
		}
	}
	/**
	 * @author : Shivani Pathak-task 3517
	 * @description : Added code in render part as per given screens .
	 */
	render() {
		const asterisk = {
			color: "red",
		};

		const columns = [
			{
				title: (
					<span>
						Job Level&nbsp;&nbsp;&nbsp;
						<span>
							<i class="fas fa-sort"></i>
						</span>
					</span>
				),
				dataIndex: "jobLevelForMapping",
				key: "jobLevelForMapping",
				width: 20,
				align: "left",
				sorter: (a, b) => {
					const asc = "asc";

					if (a.jobLevelForMapping < b.jobLevelForMapping) {
						return asc ? -1 : 1;
					} else if (a.jobLevelForMapping > b.jobLevelForMapping) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "13px", color: "#4F4F4F" }}>
							{record.jobLevelForMapping}
						</span>
					</div>
				),
			},
			{
				title: (
					<span>
						Job Title&nbsp;&nbsp;&nbsp;
						<span>
							<i class="fas fa-sort"></i>
						</span>
					</span>
				),
				dataIndex: "jobTitleForMapping",
				key: "jobTitleForMapping",
				width: 80,
				align: "left",
				sorter: (a, b) => {
					const asc = "asc";

					if (a.jobTitleForMapping < b.jobTitleForMapping) {
						return asc ? -1 : 1;
					} else if (a.jobTitleForMapping > b.jobTitleForMapping) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},

				render: (text, record) => (
					<div>
						<span style={{ fontSize: "13px", color: "#4F4F4F" }}>
							{record.jobTitleForMapping}
						</span>
					</div>
				),
			},
			{
				title: (
					<span>
						Edit&nbsp;&nbsp;&nbsp;
						<span>
							<i class="fas fa-sort"></i>
						</span>
					</span>
				),
				dataIndex: "",
				key: "",
				width: 20,
				align: "left",
				render: (text, record) => (
					//   <div >
					<a
						href="#"
						jobTitle={record.jobTitleForMapping}
						jobLevelfordropdown={record.jobLevelForMapping}
						onClick={this.editJobTitle.bind(this)}>
						<Tooltip placement="top" title="Edit">
							<img
								src="edit_report.png"
								width="20"
								height="20"
								jobTitle={record.jobTitleForMapping}
								jobLevelfordropdown={record.jobLevelForMapping}></img>
						</Tooltip>
					</a>
				),

				//   </div>
			},
		];
		function onChange(pagination, filters, sorter) {}
		return (
			<div>
				<Navigation />
				<div
					class="container-fluid"
					id="container-fluidJblm" //mufiz-bug-4111-Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) 



					style={{
						paddingTop: "100px",
						paddingLeft: "50px",
						paddingRight: "50px",
					}}>
					<div class="row">
						<div class="col-sm-6 offset-md-3 col-md-8 offset-lg-4 col-lg-6">
							<span id="labelDIJlm">Job Level/ Job Title Mapping</span> 
							{/* //mufiz-bug-4111-Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority)  */}
						</div>
					</div>
					<div class="row">
						{this.state.jobLevelFlag === true ? (
							<span
								style={{
									color: "#F28C0F",
									fontFamily: "Roboto",
									fontSize: "20px",
									fontWeight: "600",
									paddingLeft: "25px",
									marginBottom: "10px",
								}}>
								Add Mapping :
							</span>
						) : (
							<span
								style={{
									color: "#F28C0F",
									fontFamily: "Roboto",
									fontSize: "20px",
									fontWeight: "600",
									paddingLeft: "25px",
									marginBottom: "10px",
								}}>
								Edit Mapping :
							</span>
						)}
					</div>
					<hr />
					<div class="row">
						<div style={{ padding: "25px" }}>
							<span style={{ color: "#14254A" }}>
								Job level
								<span style={asterisk}>
									{" "}
									&nbsp;&nbsp;*&nbsp;&nbsp;
									<Tooltip title="Job title will be filter according to selected job level.">
										<Icon type="question-circle-o" className="iconbackground" />
										{/*sunita-task-3818-added classname as per requirement for removing white patches */}
									</Tooltip>
								</span>
							</span>
							<br />
							{this.state.jobLevelFlag === true ? (
								<select
									className="form-control-1"    //mufiz-bug-4111-Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) 
									name="jobLevel"
									id="jobLevel"
									value={this.state.jobLevel}
									onChange={this.handleChangeforJobLevel}
									style={{
										width: "200px",
										height: "36px",
										border: " 1px solid #CCCCCC",
										borderRadius: " 3px",
										backgroundColor: "#FFFFFF",
										fontFamily: "sans-serif",
										color: " #555555",
									}}>
									<option value={"Select Job Level"} selected disabled>
										Select Job Level
									</option>
									{this.state.jobLevelfordropdown.map((jobLevelfordropdown) => (
										<option
											value={jobLevelfordropdown}
											style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
											{jobLevelfordropdown}
										</option>
									))}
								</select>
							) : (
								<select
									disabled
									className="form-control-1"
									name="jobLevel"
									id="jobLevel"
									value={this.state.jobLevelfordropdown}
									style={{
										width: "200px",
										height: "36px",
										border: " 1px solid #CCCCCC",
										borderRadius: " 3px",
										backgroundColor: "#FFFFFF",
										fontFamily: "sans-serif",
										color: " #555555",
										opacity: "0.5",
									}}>
									<option value={"Select Job Level"} selected>
										Select Job Level
									</option>
									{this.state.jobLevelfordropdown.map((jobLevelfordropdown) => (
										<option
											value={jobLevelfordropdown}
											style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
											{jobLevelfordropdown}
										</option>
									))}
								</select>
							)}

							<div style={{ color: "red" }}>{this.state.errors.jobLevel}</div>
						</div>
						<div style={{ padding: "25px" }}>
							<span style={{ color: "#14254A" }}>
								Job Title
								<span style={asterisk}> &nbsp;&nbsp;*&nbsp;&nbsp;</span>
							</span>
							<br />

							<div
								style={{
									width: "200px",
									height: "36px",
									border: " 1px solid #CCCCCC",
									borderRadius: " 3px",
									backgroundColor: "#FFFFFF",
									fontFamily: "sans-serif",
									color: " #555555",
								}}>
								{/* //shivani-3673-changed id for job Title feild . */}
								{this.state.jobLevelFlag === true ? (
									<Picky
										value={this.state.orgArray}
										options={
											this.state.jobLevel == "VP"
												? jobTitleOptionsForVP
												: this.state.jobLevel == "Manager"
												? jobTitleOptionsForManager
												: this.state.jobLevel == "Head"
												? jobTitleOptionsForHead
												: this.state.jobLevel == "Director"
												? jobTitleOptionsForDirector
												: this.state.jobLevel == "Staff"
												? jobTitleOptionsForStaff
												: this.state.jobLevel == "C-Level"
												? jobTitleOptionsForClevel
												: jobTitleOptions
										}
										onChange={this.onJobTitleHandleChange}
										open={false}
										valueKey="id"
										labelKey="name"
										multiple={true}
										includeSelectAll={
											this.state.jobLevel.length > 0 ? true : false
										}
										includeFilter={
											this.state.jobLevel.length > 0 ? true : false
										}
										numberDisplayed={1}
										placeholder={"Select Job Title"}
										id="jobTitle_Mapping"
										name="jobTitle"
										className={"PickyStyle"}
									/>
								) : (
									<Picky
										value={this.state.orgArray}
										options={
											this.state.jobLevelfordropdown == "VP"
												? jobTitleOptionsForVP
												: this.state.jobLevelfordropdown == "Manager"
												? jobTitleOptionsForManager
												: this.state.jobLevelfordropdown == "Head"
												? jobTitleOptionsForHead
												: this.state.jobLevelfordropdown == "Director"
												? jobTitleOptionsForDirector
												: this.state.jobLevelfordropdown == "Staff"
												? jobTitleOptionsForStaff
												: this.state.jobLevelfordropdown == "C-Level"
												? jobTitleOptionsForClevel
												: jobTitleOptions
										}
										onChange={this.onJobTitleHandleChange}
										open={false}
										valueKey="id"
										labelKey="name"
										multiple={true}
										includeSelectAll={
											this.state.jobLevelfordropdown.length > 0 ? true : false
										}
										includeFilter={
											this.state.jobLevelfordropdown.length > 0 ? true : false
										}
										numberDisplayed={1}
										placeholder={"Select Job Title"}
										id="jobTitle_Mapping"
										name="jobTitle"
										className={"PickyStyle"}
									/>
								)}
								<div style={{ color: "red" }}>
									{this.state.errors.jobTitle_Mapping}
								</div>
							</div>
						</div>
						<div>
							{/* <i style={{ paddingTop: '50px', fontSize: '25px' }} class="fa fa-exchange" aria-hidden="true"></i> */}
							<img
								src="Exchange.png"
								alt=""
								height="25px"
								width="25px"
								style={{ marginTop: "50px" }}></img>
						</div>
						<div style={{ padding: "25px" }}>
							<span style={{ color: "#14254A" }}>Selected job title</span>
							<br />
							<div>
								{this.state.jobLevelFlag === true ? (
									<textarea
										className="form-control-1"
										style={{
											width: "250px",
											height: "70px",
											resize: "none",
											overflowY: "scroll",
										}}
										value={this.state.orgArray}>
										{this.state.orgArray}
									</textarea>
								) : (
									<textarea
										className="form-control-1"
										style={{
											width: "250px",
											height: "70px",
											resize: "none",
											overflowY: "scroll",
										}}
										value={this.state.orgArray}>
										{this.state.orgArray}
									</textarea>
								)}
							</div>
						</div>
						<div style={{ padding: "25px" }}>
							<span style={{ color: "#14254A" }}>
								Add custom job title&nbsp;
								<Tooltip title="Add custom job title if not available in job title list.">
									<Icon type="question-circle-o" className="iconbackground" />
									{/*sunita-task-3818-added classname as per requirement for removing white patches */}
								</Tooltip>
								{this.state.jobTitlechk == true ? (
									<Checkbox
										id="customjobTitle"
										name="customjobTitle"
										type="checkbox"
										style={{ marginTop: "-20px", marginLeft: "150px" }}
										defaultChecked
										value={this.state.jobTitlechk}
										onClick={this.customJobTitleHandleChange}></Checkbox>
								) : (
									<Checkbox
										id="customjobTitle"
										name="customjobTitle"
										type="checkbox"
										style={{ marginTop: "-20px", marginLeft: "150px" }}
										onClick={this.customJobTitleHandleChange}></Checkbox>
								)}
								{/* //   } */}
							</span>
							{this.state.jobTitlechk == true ? (
								<textarea
									className="form-control-1"
									id="customjobTitleInput"
									name="customjobTitleInput"
									value={this.state.custArray}
									style={{
						
										width: "250px",
										height: "70px",
										resize: "none",
										marginTop: "-15px",
									}}
									onChange={this.customJobTitleInputHandleChange}>
									{this.state.custArray}
								</textarea>
							) : (
								<textarea
									className="form-control-1 "
									id="customjobTitleInput"
									name="customjobTitleInput"
									value={this.state.custArray}
									style={{
										width: "250px",
										height: "70px",
										resize: "none",
										marginTop: "-15px",
									}}
									onChange={this.customJobTitleInputHandleChange}></textarea>
							)}

							<div style={{ color: "red" }}>
								{this.state.errors.customjobTitleInput}
							</div>
						</div>
					</div>
					<br />
					<div>
						{this.state.jobLevelFlag === true ? (
							<div>
								<button
									class="btn add-button float-right"
									id="add_jobLevel_btn"
									type="button"
									style={{ marginTop: "-100px", width: "100px" }}
									onClick={this.addButtonHandleChange}>
									Add
								</button>
							</div>
						) : (
							<button
								class="btn add-button float-right"
								id="add_jobLevel_btn"
								type="button"
								style={{ marginTop: "-100px", width: "100px" }}
								onClick={this.addButtonHandleChange}>
								Edit
							</button>
						)}
					</div>
					<hr />

					<div class="row">
						<span
							style={{
								color: "#F28C0F",
								fontFamily: "Roboto",
								fontSize: "20px",
								fontWeight: "600",
								paddingLeft: "20px",
							}}>
							Existing Mapping :
						</span>
						&nbsp;&nbsp;
						<Tooltip title="This mapping applied to all clients and campaigns.">
							<Icon
								style={{ fontSize: "20px", marginTop: "5px" }}
								type="question-circle-o"
								className="iconbackground"
							/>
							{/*sunita-task-3818-added classname as per requirement for removing white patches */}
						</Tooltip>
					</div>
					<div id={this.state.loader}></div>
					<div style={{ marginBottom: "80px" }}>
						<Table
							{...this.state.tableState}
							id="myTable"
							bordered
							dataSource={this.state.getMappingDetails}
							columns={columns}
							onChange={onChange}
							className="ant-table-thead"
							className={tableCSS2}
							scroll={{ x: 1200, y: 1333 }}
							pagination={{
								pageSize: this.state.pageSize,
								position: "bottom",
							}}></Table>

						<div
							class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6"
							style={{
								marginTop: "-53px",
								marginLeft: "5px",
								fontSize: "13px",
								color: "#4F4F4F",
								fontFamily: "sans-serif",
							}}>
							Records per page:&nbsp;
							<select
								defaultValue={this.state.pageSize}
								onChange={this.handleChangePageSize}
								id="pacing11"
								class="input-small"
								className="form-control-1"
								name="pacing"
								style={{
									width: "64px",
									height: "30px",
									display: "initial",
									fontSize: "13px",
									fontFamily: "sans-serif",
									color: "#4F4F4F",
								}}>
								<option value="5" selected>
									5
								</option>
								<option value="10">10</option>
								<option value="20">20</option>
								<option value="30">30</option>
								<option value="40">40</option>
								<option value="50">50</option>
							</select>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}

/**
 * @author Shivani Pathak
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
JobLevelMapping.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(JobLevelMapping)
);
