/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Shivani Pathak
*@fileName :domainName.js
*Desc:
*@Date of creation: 22/02/2021

*/
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../login/authentication";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
// import { saveAs } from "file-saver"; 
// import { CardBody } from "mdbreact"; //Sonali-3902-added to display table
// import { FaArrowAltCircleDown } from "react-icons/fa"; //Sonali-3902-added to show download button
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //4492-nilesh hide payload userId
//import ReCAPTCHA from "react-google-recaptcha";
import { FaArrowAltCircleLeft } from "react-icons/fa"; //Somnath Task-3981, import Back Button
import { Tooltip, Icon, Table } from "antd";
import { css } from 'emotion'
const xlsx = require("xlsx"); //use for download the excel file
const Swal = require("sweetalert2");

const tableCSS = css({
  backgroundColor: 'white',
  fontFamily: "sans-serif",
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize: '12px !important',
    fontWeight: '500',
  },
  '& thead > tr >th': {
    textAlign: "center !important",
    color: 'white',
  }
});

class domainName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //declaration of parameters
      error: {},
      loading: "",
      // recaptchaResponse: "",
      tableFlag: "false", //Sonali-3902-added to show table
      abmDetails: [], //Sonali-3902-table display details in array
      fileName: "",
      abmAllDetails: [], //Aman-4679-added to store abmAllDetails
      UploadBtn: "false",  //Aman-4701-added to store Upload Button status
      TotalAbmCount: "",  //Aman-4701-added to store total Upload ABM count
      limit: "", //Aman-4701-added to store to get total ABM count
      remainingCount: "", //Aman-4701-added to store remaining ABM Count to fetch
      TotalCount: "",  //Aman-4701 - it well store count after limit exceeded
      loader_progress: "",  //Aman-4701 - set the loader state
      tableState: { size: "small" }, //Aman-4702 - added table size
      pageSize: 5, //Aman-4702 - set page count to display
      ABMPendingFileCount: "", //Aman-4702 - set the all DI ABM Pending file count
      CurrentUser : "", //Aman-4702 -added to get current user id
      PendingFileUser: "", //Aman-4702 -added to get uploaded file user id
      uploadButtonDisabled :"false" //Aman-4887-(added to set this initially)Prod issue-ABM Upgrade-ABM File not uploaded 
    }; //end of this.state

    this.uploadLeadFile = this.uploadLeadFile.bind(this);
    //this.downloadFile = this.downloadFile.bind(this);
    //this.physicalAddressLeadFile = this.physicalAddressLeadFile.bind(this);
    //this.handleCaptchaResponseChange=this.handleCaptchaResponseChange.bind(this);
    this.downloadUpgradeFile = this.downloadUpgradeFile.bind(this); //Sonali-3902
    this.handleFileShowDownload = this.handleFileShowDownload.bind(this); //Sonali-3902
    this.showDetails = this.showDetails.bind(this); //Sonali-3902
    this.refreshPage = this.refreshPage.bind(this); //Sonali-3902
    this.deleteFile = this.deleteFile.bind(this); //Aman-4679-added to bind with function
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle login cred
   * @return Description return
   */

  // handleCaptchaResponseChange(response) {

  //  // alert("inside handleCaptchaResponseChange==>"+JSON.stringify(response))
  //   this.setState({
  //     recaptchaResponse: response,
  //   });
  //   let data = { recaptcha: this.state.recaptchaResponse };
  //   fetch("/publisherOnBoardDetails/recaptcha", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(data),
  //   })
  //   .then((res) => res.json())
  // 			.then((res) => {
  // 				 if (res.success == true) {

  //            var data = new FormData();

  //            data.append("file", this.state.fileToUpload);
  //            fetch("abmUpgradeFile/googleSearchDomainDetailsSignal", {
  //                method: "POST",
  //                //headers: { "Content-Type": "application/json" },
  //                body: data
  //              }).then(res => res.json())
  //                .then(downloadFiles => {

  //                  if (downloadFiles.length > 0) {
  //                    var ws = xlsx.utils.json_to_sheet(downloadFiles);
  //                    var wb = xlsx.utils.book_new();
  //                    xlsx.utils.book_append_sheet(wb, ws, "Lead");
  //                    var buf = xlsx.write(wb, { bookType: 'xlsx', type: 'buffer' }); // generate a nodejs buffer
  //                    function s2ab(s) {
  //                      var buf = new ArrayBuffer(s.length);
  //                      var view = new Uint8Array(buf);
  //                      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  //                      return buf;
  //                    }
  //                    var fileName = "Account_File.xlsx";
  //                    saveAs(new Blob([s2ab(buf)], { type: "application/octet-stream" }), fileName);
  //                    this.setState({
  //                        loading: '',
  //                      });
  //                  }
  //                })
  // 				// 	this.setState({
  // 				// 		buttonDisplay: "btn add-button",
  // 				// 	});
  // 				 }
  // 			})
  // 			.catch(function (err) {
  // 				console.log(err);
  // 			});
  // };

  //Sonali-3902-download input and output file in the zip format

  handleFileShowDownload(blob) {
    let zipFileName =
      unescape(this.state.filename).replace("input_", "") + ".zip";
    var newBlob = new Blob([this.state.downloadFiles], {
      type: "application/zip",
    });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = zipFileName;
    link.click();
  }

  //Sonali-3902-get previous upload details to show in the table
  showDetails(e) {
    e.preventDefault();
    let uploadButtonDisabled = "true" 
    this.setState({ uploadButtonDisabled: uploadButtonDisabled}); //Aman-4887-(added to set status before call other route it will disable the button)Prod issue-ABM Upgrade-ABM File not uploaded 
    // const { isAuthenticated, user } = this.props.auth; //Aman-4701 - commented not in use
    var data = new FormData();
    this.setState({ tableFlag: "true" });
    this.setState({ loader_progress: "loader_campaign_list" }); //Aman-4701 - set the loader
    //data.append("userID", userID);//4492-nilesh hide payload userId
    fetch("accountNameDetails/getFileDetails", {
      method: "POST",
      //headers: { "Content-Type": "application/json" },
      body: data,
    })
      .then((res) => res.json())
      .then((abmDetails) => {
        this.setState({ loader_progress: "" }); //Aman-4701 - removed the loader
        this.setState({ abmDetails: abmDetails.data });
        if(abmDetails.data.length !== 0){//Aman-4842 -Ref(4701)-ABM upgrade -New user login issue
        //Aman-4701 - added below code to get ABM file count and to validate
        let ABMfileValue = abmDetails.data[0].status;
        var TotalCount = abmDetails.count;
        var TotalLimit = 10000;
        var remainingCount; 
        
        if (TotalCount === TotalLimit) {
          remainingCount = 0;
        } else {
            remainingCount = TotalCount - TotalLimit;
            remainingCount = Math.abs(remainingCount); // Remove the negative sign from count
        }
        this.setState({ remainingCount: remainingCount, TotalCount: TotalCount }); 
        localStorage.setItem("remainingCount", remainingCount)
        //Aman-4702 - added to handle the button condtion
        if(ABMfileValue === "Pending"){
          let butStatus = "true"
          this.setState({ UploadBtn: butStatus, TotalAbmCount: TotalCount});
        }else if(ABMfileValue === "Completed" && this.state.ABMPendingFileCount === 1){
          let butStatus = "true"
          this.setState({ UploadBtn: butStatus });
        }
        else{
          let butStatus = "false"
          this.setState({ UploadBtn: butStatus });
        }
       if(TotalCount === 10000){
        let butStatus = "true"
        this.setState({UploadBtn: butStatus });
      }
    }else if(TotalCount === 10000 && this.state.remainingCount === 0){
      let butStatus = "true"
      this.setState({UploadBtn: butStatus });
    }else{}

      let uploadButtonDisabled = "false"
      this.setState({ uploadButtonDisabled: uploadButtonDisabled}); //Aman-4887-(after route call added set status false then the button will be visible to upload)Prod issue-ABM Upgrade-ABM File not uploaded 
      });
  }

  //Sonali-3902-download input out files
  downloadUpgradeFile(e) {
    e.preventDefault();
    const { user } = this.props.auth; //Aman-4701 - removed not in use isAuthenticated
    var data = new FormData();
    var filename = e.target.getAttribute("name");
    var userID = user.userID;
    this.setState({ filename: filename });
    data.append("filename", filename);
    data.append("userID", userID); //Aman-4679 added to this to pass userID
    fetch("accountNameDetails/downloadFile", {
      method: "POST",
      body: data,
    })
      .then((r) => r.blob())
      .then((downloadFiles) => {
        this.setState({ downloadFiles: downloadFiles });
      })
      .then(this.handleFileShowDownload);
  }

  //Sonali-3902-refresh page on click of refresh button
  refreshPage(e) {
    window.location.reload();
  }

  uploadLeadFile(e) {
    // code for upload file
    //Aman - 4545 - ABM Upgrade - File validation implementation
    // const { isAuthenticated, user } = this.props.auth; //Aman-4701 -commented not in use
    // var userID = user.userID;
    var fileInput = document.getElementById("file");
    var filePath = fileInput.value;
    var fileName = fileInput.files[0].name;
    var abmAllDetails = this.state.abmAllDetails;
    let fileNameArray = [];
    this.setState({ fileName: fileName });
     //Aman-4679 Define for loop this to check file is present or not
    for (
      let i = 0;
      i < abmAllDetails.length;
      i++
    ) {
      fileNameArray.push(unescape(abmAllDetails[i].fileName));
    }
    if (fileNameArray.includes("input_" + fileName)) {
      Swal.fire({
        text: "Duplicates FileName Found, please Change the FileName and Try to Uplaod File Again",
        type: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        preConfirm: () => {
          window.location.reload();
        },
      });
      fileInput.value = "";
      var fileNameReset = "";
      this.setState({ fileName: fileNameReset });
      return false;
    } else {
      var allowedExtensions = /(\.csv|\.xlsx|\.xls)$/i;
      if (!allowedExtensions.exec(filePath)) {
        Swal.fire({
          text: "Please Upload only Excel file",
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          preConfirm: () => {
            window.location.reload();
          },
        });
        fileInput.value = "";
        return false;
      } else {
        var data = new FormData();
        this.setState({
          loading: "whirly-loader",
        });
        data.append("file", e.target.files[0]);
        //data.append("userID", userID);

        //Aman-4679 Define the expected header
        const expectedHeader = ["Account Name"];

        //Aman-4679 Get the file input element and the uploaded file
        const fileInput = document.getElementById("file");
        const file = fileInput.files[0];

        //Aman-4679- Create a FileReader object to read the file contents
        const reader = new FileReader();

        //Aman-4679 Set up an event listener to handle when the file has been read
        reader.addEventListener("load", function () {
          //Aman-4679 Read the contents of the file as a binary string
          const binaryString = reader.result;

          //Aman-4679 Parse the binary string as an XLSX workbook
          const workbook = xlsx.read(binaryString, { type: "binary" });

          //Aman-4679 Get the first worksheet in the workbook
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];

          //Aman-4679 Convert the worksheet to a JSON object
          const rows = xlsx.utils.sheet_to_json(worksheet, { header: 1 });

          //Aman-4679 Get the header row from the JSON object
          const headerRow = rows[0];

          //Aman-4679 Check if the header matches the expected format
          const isHeaderValid = headerRow.every(
            (value, index) => value === expectedHeader[index]
          );

          //Aman-4679 Added condtion to check if File Header is vaild or not
          if (isHeaderValid) {
            //Aman-4701 - added below code to get ABM file count
            fetch("accountNameDetails/checkABMUpgradeFile", {
              method: "POST",
              body: data,
            })
            .then((res) => res.json())
            .then((response) => {
              // console.log('Response : ', response);
              var FileCount = response.filecount;
              // console.log('FileCount', FileCount);
              var Remaining = localStorage.getItem("remainingCount")
              // console.log('Remaining', Remaining);
              //Aman-4701 - added below code to check file count and remaining count
              if (response.success === true) { //FileCount > Remaining
                    if (FileCount <= Remaining) {
                      fetch("accountNameDetails/googleSearchDomainDetailsSignal", {
                        method: "POST",
                        //headers: { "Content-Type": "application/json" },
                        body: data,
                      })
                      .then((res) => res.json())
                      .then((response) => {});
                        Swal.fire({
                          text: "Your request for ABM upgrade is been submitted, You will receive upgraded ABM file in email once it is ready",
                          type: "success",
                          confirmButtonText: "Ok",
                          allowOutsideClick: false,
                          preConfirm: () => {
                            window.location.reload();
                          },
                        });
                    }else{
                      Swal.fire({
                        html: "<b>You can Only upload "+Remaining+" Records</b>",
                        type: "warning",
                        confirmButtonText: "Ok",
                        allowOutsideClick: false,
                        preConfirm: () => {
                            window.location.reload();
                        },
                    });
                    }
                }else if(response.success === false) {
                  //Aman-4567 Added this condtion to check if lead file length is <= 1900
                  Swal.fire({
                    html: "<b>You can upload only 2000 records at a time</b>",
                    type: "warning",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    preConfirm: () => {
                      window.location.reload();
                    },
                  });       
                }
            });
          } else {
            Swal.fire({
              text: "File Header Name is Not Valid, it should be Account Name Only, please Change",
              type: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
          }
        });
        // Read the contents of the file as a binary string
        reader.readAsBinaryString(file);
      }
    }
    // this.state.fileName = '';
  } //end of uploadLeadFile

  // physicalAddressLeadFile(e) {
  //   // code for upload file

  //   var data = new FormData();
  //   this.setState({
  //       loading: 'whirly-loader',
  //     });
  //   data.append("file", e.target.files[0]);
  //   fetch("accountNameDetails/getDomainDetatailsInBrief", {
  //       method: "POST",
  //       //headers: { "Content-Type": "application/json" },
  //       body: data
  //     }).then(res => res.json())
  //       .then(downloadFiles => {

  //         if (downloadFiles.length > 0) {
  //           var ws = xlsx.utils.json_to_sheet(downloadFiles);
  //           var wb = xlsx.utils.book_new();
  //           xlsx.utils.book_append_sheet(wb, ws, "Lead");
  //           var buf = xlsx.write(wb, { bookType: 'xlsx', type: 'buffer' }); // generate a nodejs buffer
  //           function s2ab(s) {
  //             var buf = new ArrayBuffer(s.length);
  //             var view = new Uint8Array(buf);
  //             for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  //             return buf;
  //           }
  //           var fileName = "Account_File.xlsx";
  //           saveAs(new Blob([s2ab(buf)], { type: "application/octet-stream" }), fileName);
  //           this.setState({
  //               loading: '',
  //             });
  //         }
  //       })
  //     } //end of uploadLeadFile

  /**
   * @author Somnath Keswad
   * @param  Description Task-3981 Add Back Button
   * @return return on Dashboard Page
   */
  backButtonFunction(e) {
    e.preventDefault();
    this.props.history.push("/newDashboard");
  }

  /**
   * @author Aman Shaikh
   * @param  Description Task-4679 Add delete Button
   * @return return Add delete Button
   */
  deleteFile(e) {
    e.preventDefault();
    var fileName = e.target.getAttribute("deletename");
    let data;
    data = { fileName: fileName };
    fetch("/accountNameDetails/deleteFile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          Swal.fire({
            text: "File Deleted successfully.",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        } else {
          Swal.fire({
            text: "Something went wrong while deleting file",
            type: "Failure",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
            preConfirm: () => {
              window.location.reload();
            },
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }
  /**
   * @author Aman Shaikh
   * @param  Description Task-4679 Add to get data
   * @return return Add to get data
   */
  componentDidMount() {
    let uploadButtonDisabled = "true" 
    this.setState({ uploadButtonDisabled: uploadButtonDisabled}); //Aman-4887-(added to set status before call other route it will disable the button)Prod issue-ABM Upgrade-ABM File not uploaded 
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      this.getTodayFileStatus();
      var data = new FormData();
      fetch("accountNameDetails/getFileDetails", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((abmDetails) => {
          //Aman-4701 - added below code to get ABM file count 
          localStorage.setItem("AbmUserIPAddress", abmDetails.ip) //Aman-4701- added localStorage to get IP add
          localStorage.setItem("TotalFileCount", abmDetails.count) //Aman-4701- added localStorage to store todays total ABM  completed file count
          this.setState({ abmAllDetails: abmDetails.data });
          var TotalCount = abmDetails.count;
          var TotalLimit = 10000;
          var remainingCount; 
          
          if (TotalCount === TotalLimit) {
            remainingCount = 0;
          } else {
            remainingCount = TotalCount - TotalLimit;
            remainingCount = Math.abs(remainingCount); // Remove the negative sign from count
          }
          this.setState({ remainingCount: remainingCount, TotalCount: TotalCount });
          localStorage.setItem("remainingCount", remainingCount)
          if(abmDetails.data.length !== 0){//Aman-4842 -Ref(4701)-ABM upgrade -New user login issue
          let ABMfileValue = abmDetails.data[0].status;
          //Aman-4702 - added to handle the button condtion
          if(ABMfileValue === "Pending"){
            let butStatus = "true"
            this.setState({ UploadBtn: butStatus, TotalAbmCount: TotalCount});
          }else if(ABMfileValue === "Completed" && this.state.ABMPendingFileCount === 1){
            let butStatus = "true"
            this.setState({ UploadBtn: butStatus });
          }
          else{
            let butStatus = "false"
            this.setState({ UploadBtn: butStatus });
          }
          if(TotalCount === 10000){
            let butStatus = "true"
            this.setState({UploadBtn: butStatus });
          }
        }else if(TotalCount === 10000 && this.state.remainingCount === 0){
          let butStatus = "true"
          this.setState({UploadBtn: butStatus });
        }else{}

        let uploadButtonDisabled = "false"
        this.setState({ uploadButtonDisabled: uploadButtonDisabled}); //Aman-4887-(after route call added set status false then the button will be visible to upload)Prod issue-ABM Upgrade-ABM File not uploaded 
        });
      }
  }
  
//Aman-4702 - added to call ABM file status from DB
  getTodayFileStatus(){
    const { user } = this.props.auth;
    var data = new FormData();
    fetch("accountNameDetails/getFileStatus", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((abmDetailsStatus) => {
        var StatusCount = abmDetailsStatus.data; //Aman-4702 - to store ABM file count
        var FileUser = abmDetailsStatus.user; //Aman-4702 - to store uploaded file user id
        this.setState({ ABMPendingFileCount: StatusCount, CurrentUser: user.userID, PendingFileUser: FileUser});
        if(StatusCount === 1){
          let butStatus = "true"
          this.setState({ UploadBtn: butStatus});
        }
        // else{
        //   alert("HI ------- false" + user.userID)
        //   let butStatus = "false"
        //   this.setState({ UploadBtn: butStatus });
        // }
      });
  }

  render() {
    //Aman-4702/4701 - defined table columns
    const columnsReport = [
      {
          title: <span>File Name&nbsp;&nbsp;&nbsp;</span>,
          dataIndex: "campID",
          key: "campID",
          width: "60%",
          align: "left",
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {unescape(record.fileName).replace("input_", "")}
                  </span>
              </div>
          ),
      },
      {
        title: <span>Status&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "lastName",
        key: "lastName",
        width: "10%",
        align: "left",
        render: (text, record) => (
            <div>
            {record.status === "Pending" ? (
              <label
                style={{
                  color: "#056eb8",
                  fontWeight: "500",
                  }}
                >
                In Progress
              </label>
              ) : (
                <>
                  {record.status === "Completed" ? (
                    <b style={{ color: "green" }}>Completed</b>
                    ) : (
                    <b style={{ color: "red" }}>Failed</b>
                  )}
                </>
            )}
            </div>
        ),
      },
      {
        title: <span>Record Count&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "firstName",
        key: "firstName",
        width: "10%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                  {record.recordCount}
                </span>
            </div>
        ),
    },
      {
        title: <span>Date&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "created",
        key: "created",
        width: "10%",
        align: "left",
        render: (text, record) => (
            <div>
              {record.created}
            </div>
        ),
    },
      {
        title: <span>Action&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "leadCount",
        key: "leadCount",
        width: "10%",
        align: "left",
        render: (text, record) => (
              <div>
                 {record.status.toString() === "Pending" ? (   //Chaitanya-2898-Removed Console warning
                              <div className="row" style={{alignItems:"center", display: "flex"}}>
                              <div className="col">
                              <img
                               style={{
                                        height: "32px",
                                          width: "32px",
                                          transform: "rotateZ(270deg)",
                                          //float: "left",
                                          cursor: "pointer",
                                          marginBottom: "5px"
                                        }} 
                                        src="back_button_disable.png"
                                        alt=""
                                        width="20"
                                        height="20"
                                      ></img>  
                              </div>
                              <div className="col">
                               {/* Chaitanya-4680 Added this diable button to delete file */}
                               {/* <AiFillDelete
                                        align="center"
                                        size={32}
                                      /> */}
                                      <img
                                        style={{
                                          height: "26px",
                                          width: "26px",
                                          cursor: "pointer",
                                          marginBottom: "5px"
                                        }} 
                                        src="file_disable_2.png"
                                        alt=""
                                        width="20"
                                        height="20"
                                      ></img>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {/*Aman-4679 Note: - we have run corn job for Failed file on DB level it will run every 1 hr and check pending uploaded file after 24
                                    this will show you the running corn job(run this on sql workbench)------ SHOW EVENTS; 
                                    this will start the running corn job------- SET GLOBAL event_scheduler="ON";
                                    this will stop the running corn job------ SET GLOBAL event_scheduler = OFF;
                                    this sql query we have run corn job at DB level------ CREATE EVENT change_All_ABM_Status_24h ON SCHEDULE  EVERY 1 HOUR DO update abm_upgrade_data  set status="Failed"
                                        where created < date_sub(now(),interval 24 hour) and status="Pending"
                                    this will delete the running corn job------DROP EVENT change_ABM_Status_24h;
                                    */}
                                {record.status.toString() === "Failed" ? (    //Chaitanya-2898-Removed Console warning
                                  <div className="row" style={{ alignItems:"center", display: "flex"}}>
                                  <div className="col">
                                  <img
                                      style={{
                                        height: "32px",
                                          width: "32px",
                                          transform: "rotateZ(270deg)",
                                         // float: "left",
                                          cursor: "pointer",
                                          marginBottom: "5px"
                                        }} 
                                        src="back_button_disable.png"
                                        alt=""
                                        width="20"
                                        height="20"
                                      ></img>  
                                    </div>
                                    <div className="col">
                                    {/* Chaitanya-4680 Added this button to delete file */}
                                    <Tooltip placement="top" title="Delete">
                                      <img
                                        deletename={record.fileName}
                                        style={{
                                          height: "26px",
                                          width: "26px",
                                          cursor: "pointer",
                                          marginBottom: "5px"
                                        }}
                                        onClick={this.deleteFile.bind(this)}
                                        src="file_upload_2_blue.png"
                                        alt=""
                                        width="20"
                                        height="20"
                                      ></img>
                                    </Tooltip>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row" style={{ alignItems:"center", display: "flex", flexWrap:"wrap"}}>
                                  <div className="col-6">
                                    <Tooltip
                                      placement="top"
                                      title="Download Upgraded Files"
                                    >
                                      <img
                                        src="back_button.png"
                                        name={record.fileName}
                                        style={{
                                          height: "32px",
                                          width: "32px",
                                          transform: "rotateZ(270deg)",
                                          cursor: "pointer",
                                          marginBottom: "5px"
                                        }}
                                        alt=""    //Chaitanya-2898-Removed Console warning
                                        onClick={this.downloadUpgradeFile}
                                      ></img>
                                    </Tooltip>
                                    </div>
                                    <div className="col-6">
                                    {/* Chaitanya-4680 Added this button to delete file */}
                                    <Tooltip placement="top" title="Delete">
                                      <img
                                        deletename={record.fileName}
                                        style={{
                                          height: "26px",
                                          width: "26px",
                                          cursor: "pointer",
                                          marginBottom: "5px"
                                        }}
                                        onClick={this.deleteFile.bind(this)}
                                        src="file_upload_2_blue.png"
                                        alt=""
                                        width="20"
                                        height="20"
                                      ></img>
                                    </Tooltip>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
            </div>
        ),
      },
    ]
    
    return (
      <div>
        <Navigation />
        {/* Aman-4702 change some layouts */}
        <div
          style={{ paddingBottom: "20px"}}
          class="container-fluid"
        >

          <div
            class="row"
            style={{ paddingTop: "110px" }}
          >
            {/* <div class="col-md-2"> */}
            <div class="col-md-1">
              {" "}
              {/*sunita-task-3727-added css as per requirement*/}
              {/* IoIosArrowDropleftCircle */}
              <a
                href={"#/"} //Aman- 4701 - added "#/"
                style={{ color: "#056eb8" }}
                onClick={this.backButtonFunction.bind(this)}
              >
                <FaArrowAltCircleLeft size={32} title="Back to Dashboard" />
              </a>
            </div>
            <div class="col-md-8 reviewLead1">
              {" "}
              {/*sunita-task-3727-added column as per requirement*/}
              <center>
                <label
                  id="labelDI"
                  class="PubReviewLead"
                  style={{ marginLeft: "135px" }}
                >
                  {" "}
                  {/*Chaitanya-4246-R28.9-Prod issue-Publisher Login-Lead review issue*/}
                  ABM Upgrade
                </label>
              </center>
            </div>

            {/*sunita-task-3727-added below code as per new requirement for opening the specification page */}
            <div
              class="col-sm-12 col-md-3 col-lg-3 specificationCamp"
              style={{ textAlign: "right" }}
            >
                   {/* Sonali-3902-added refresh button */}
                   <button
                class="btn add-button"
                type="primary"
                onClick={this.refreshPage}
                style={{
                  backgroundColor: "#056eb8",
                  color: "#fff",
                  width: "min-content",
                  height: "31px",
                  fontSize: "14px",
                  borderColor: "#056eb8",
                  marginRight: "30px",
                  float: "right",
                }}
              >
                Refresh
              </button>
              &nbsp;
            </div>
          </div>

        {/* Aman-4702 added Instructions box*/}
          <div className="files-box">
          <dt style={{fontWeight: "bold", color: "#F28C0F"}}>ABM Upload Instructions:-</dt>
            <ol style={{display: "flex", flexDirection: "column", flexWrap:"wrap", justifyContent: "space-around"}}> 
              <li>One ABM file must have less than <b>2000</b> records</li>
              <li>Upload ABM file have limit of 10000 records for the day ('Per Day/Per IP')</li>
              <li>User will upload only 'One ABM File' at a time</li>
              <li>Status - Failed status indicates that file took beyond the time limit to retrieve data. 'Reupload ABM file' in case failed.</li>
              <li>Please note after deleting file, record count will not get change under "Used Record Count for the day"</li>
            </ol>
          </div>
          
          <div class="row" style={{ marginTop: "10px" }}>
          {/*Aman-4701 - added below code to show in progress file comment */}
            {this.state.ABMPendingFileCount === 1 ? (
               <p style={{ marginLeft: "30px", color: "red", fontWeight:"bold" }}>
               {/*Aman-4701 - added below code to show in progress file comment */}
                {this.state.PendingFileUser == this.props.auth.user.userID  ? 
                  ("(ABM File is already 'In Progress', please try after 'Complete' status of the file)") 
                : 
                  ("ABM file is already 'In Progress'(In use by other User), please try after 'Complete' status of the file")
                  }
              </p>
            ) : (
              <div>
              {/*Aman-4702 -this added to handle file condtion if it is false */}
              {this.state.UploadBtn === "false" ? ("") :(
              <p style={{ marginLeft: "30px", color: "red", fontWeight:"bold" }}>
                {this.state.UploadBtn === "true" && this.state.TotalCount !== 10000 ? ("(ABM File is already 'In Progress', please try after 'Complete' status of the file)") : ("")}
              </p>
              )}
              </div>
            )}

            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 "
              style={{ marginTop: "1px" }}
            >
              {/* <label>ABM- Google KG Search API</label><br/> */}

          {/*Aman-4701 - added some css value */}
            {/* //Aman-4887-(added this.state.uploadButtonDisabled === "true" to handel the button css)Prod issue-ABM Upgrade-ABM File not uploaded */}
              <label class={this.state.UploadBtn === "true" || this.state.uploadButtonDisabled === "true" ? "" : "chooseFile btn btn-default"}
                // title="Only Accept Excel file with Account Name as Header in Excel file and 2000 records at Time" // Aman-4679 Added title as a tooltip
               style={{ marginLeft: "12px",
               cursor: this.state.UploadBtn === "true" ? "not-allowed" : "pointer",
               background: this.state.UploadBtn || this.state.uploadButtonDisabled === "true" ? "gray" : "none",
               padding: this.state.UploadBtn || this.state.uploadButtonDisabled === "true" ? "5px" : "none",
               borderRadius: this.state.UploadBtn || this.state.uploadButtonDisabled === "true" ? "5px" : "none",
               }}>
                Upload ABM File
               
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="file"
                  onChange={this.uploadLeadFile}
                  className={this.state.loading}
                  disabled={this.state.UploadBtn === "true" || this.state.uploadButtonDisabled === "true"} //Aman-4701 - added to handle disabled button
                  //Aman - 4545 - ABM Upgrade - File validation implementation
                  accept=".xls, .xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />

              </label>
              
                <Tooltip
									placement="right"
									title="The ABM file must have one column with header name as “Account Name” with .xlsx or .csv file format Only">
									&nbsp;&nbsp;&nbsp;<Icon type="question-circle" />
								</Tooltip>
              
              {/* Aman-4679 Added this see uploaded file name*/}
              <label id="fileName">
                &nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.fileName === ""
                  ? "No File Choosen"
                  : this.state.fileName}
              </label>
            </div>
          </div>

          {/*Aman-4701 - added below code to show limit exceeded comment */}
          <p style={{ float: "right", color: "red", fontWeight:"bold", right: "10px" }}>
                {this.state.TotalCount === 10000 ? ("Today fetch : "+this.state.TotalCount+" domains, Daily Limit exceeded. Please try again after 24 hrs.") 
                : (
                  <p style={{ color: "black" }}>
                  {/*Aman-4842 -Ref(4701)-ABM upgrade -New user login issue */}
                  Per Day Record Limit - <b style={{ color: "red" }}>10000</b>,
                  Used Records for the day - <b style={{ color: "green" }}> {this.state.TotalCount === "" ? "0" : this.state.TotalCount}</b>,
                  Remaining Record count - <b style={{ color: "blue" }}> {this.state.remainingCount === "" ? "10000" : this.state.remainingCount} </b>
                  </p>
                  )}
              </p>

          <div className="col-12" style={{ marginTop: "10px" }}>
            <button
              class="btn add-button"
              type="primary"
              style={{
                backgroundColor: "#056eb8",
                color: "#fff",
                height: "31px",
                fontSize: "14px",
                borderColor: "#056eb8",
                marginLeft: "-2px"
              }}
              onClick={this.showDetails}
            >
              Previous Submitted Requests
            </button>
          </div>
          <div align="center"  id={this.state.loader_progress}></div> {/* Aman-4701 -Added loader*/}
          {this.state.tableFlag === "true" ? (
            <div>
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "18px",
                  color: "#193d8f",
                  fontWeight: "600",
                  marginTop: "5px"
                }}
              >
                &nbsp;ABM Upgrade File Details :
              </div>
              <div
                class="card"
                style={{
                  backgroundColor: "#f8f8f8",
                  borderColor: "#707070",
                  borderWidth: "0.3px",
                }}
              >
                {/* <CardBody style={{ height: "280px", overflow: "auto" }}> */}

                  {/* <table class="col-md-12 col-lg-12" style={{height: "300px"}}> */}
                  <div className="table-responsive">
                  {/* Aman- 4702/4701 -added table */}
                  <Table
                    {...this.state.tableState}
                      bordered
                      dataSource={this.state.abmDetails}
                      columns={columnsReport}
                      className={`${tableCSS} "ant-table-thead table-responsive"`}
                      scroll={{ y: 2000, x: 800 }}
                      pagination={{
                      pageSize: this.state.pageSize,
                      position: "bottom"
                      }} 
                  >    
                  </Table>
                  {/* <table
                    class="table table-bordered"
                    style={{ height: "300px", overflow: "auto" }}
                  >
                    <thead
                      style={{
                        backgroundImage:
                          "linear-gradient(to right,#28166f,#007cc3) !important",
                      }}
                    >
                      <tr class="info">
                        <th
                          style={{
                            color: "#fff",
                            backgroundColor: "rgb(14,86,164)",
                          }}
                        >
                          File Name
                        </th>
                        <th
                          style={{
                            color: "#fff",
                            backgroundColor: "rgb(14,86,164)",
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            color: "#fff",
                            backgroundColor: "rgb(14,86,164)",
                          }}
                        >
                          Record Count
                        </th>
                        <th
                          style={{
                            color: "#fff",
                            backgroundColor: "rgb(14,86,164)",
                          }}
                        >
                          Date
                        </th>
                        <th id="action-th"
                          style={{
                            color: "#fff",
                            backgroundColor: "rgb(14,86,164)",
                            width:"12%" // Aman-4679 Added to set the Action col width
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.abmDetails.map((record, id) => (
                        <tr key={id}>
                          <td>
                            {unescape(record.fileName).replace("input_", "")}
                          </td>

                          <td>
                            {" "}
                            {record.status === "Pending" ? (
                              <label
                                style={{
                                  color: "#056eb8",
                                  fontWeight: "500",
                                }}
                              >
                                In Progress
                              </label>
                            ) : (
                              <>
                                {record.status === "Completed" ? (
                                  <b style={{ color: "green" }}>Completed</b>
                                ) : (
                                  <b style={{ color: "red" }}>Failed</b>
                                )}
                              </>
                            )}
                          </td>
                          <td>
                            <label style={{ fontWeight: "500" }}>
                              {record.recordCount}
                            </label>
                          </td>
                          <td>{record.created}</td>
                          <td>
                            {record.status === "Pending" ? (
                              <div className="row" style={{alignItems:"center", display: "flex"}}>
                              <div className="col">
                              <img
                               style={{
                                        height: "32px",
                                          width: "32px",
                                          transform: "rotateZ(270deg)",
                                          //float: "left",
                                          // cursor: "pointer",
                                          marginBottom: "5px"
                                        }} 
                                        src="back_button_disable.png"
                                        alt=""
                                        width="20"
                                        height="20"
                                      ></img>  
                              </div>
                              <div className="col">
                               {/* Aman-4679 Added this diable button to delete file */}
                               {/* <AiFillDelete
                                        align="center"
                                        size={32}
                                      /> *
                                      <img
                                        style={{
                                          height: "26px",
                                          width: "26px",
                                          // cursor: "pointer",
                                          marginBottom: "5px"
                                        }} 
                                        src="file_disable_2.png"
                                        alt=""
                                        width="20"
                                        height="20"
                                      ></img>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {/*Aman-4679 Note: - we have run corn job for Failed file on DB level it will run every 1 hr and check pending uploaded file after 24
                                    this will show you the running corn job(run this on sql workbench)------ SHOW EVENTS; 
                                    this will start the running corn job------- SET GLOBAL event_scheduler="ON";
                                    this will stop the running corn job------ SET GLOBAL event_scheduler = OFF;
                                    this sql query we have run corn job at DB level------ CREATE EVENT change_All_ABM_Status_24h ON SCHEDULE  EVERY 1 HOUR DO update abm_upgrade_data  set status="Failed"
                                        where created < date_sub(now(),interval 24 hour) and status="Pending"
                                    this will delete the running corn job------DROP EVENT change_ABM_Status_24h;
                                    *
                                {record.status === "Failed" ? (
                                  <div className="row" style={{ alignItems:"center", display: "flex"}}>
                                  <div className="col">
                                  <img
                                      style={{
                                        height: "32px",
                                          width: "32px",
                                          transform: "rotateZ(270deg)",
                                         // float: "left",
                                          // cursor: "pointer",
                                          marginBottom: "5px"
                                        }} 
                                        src="back_button_disable.png"
                                        alt=""
                                        width="20"
                                        height="20"
                                      ></img>  
                                    </div>
                                    <div className="col">
                                    {/* Aman-4679 Added this button to delete file *
                                    <Tooltip placement="top" title="Delete">
                                      <img
                                        deletename={record.fileName}
                                        style={{
                                          height: "26px",
                                          width: "26px",
                                          cursor: "pointer",
                                          marginBottom: "5px"
                                        }}
                                        onClick={this.deleteFile.bind(this)}
                                        src="file_upload_2_blue.png"
                                        alt=""
                                        width="20"
                                        height="20"
                                      ></img>
                                    </Tooltip>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row" style={{ alignItems:"center", display: "flex", flexWrap:"wrap"}}>
                                  <div className="col-6">
                                    <Tooltip
                                      placement="top"
                                      title="Download Upgraded Files"
                                    >
                                      <img
                                        src="back_button.png"
                                        name={record.fileName}
                                        style={{
                                          height: "32px",
                                          width: "32px",
                                          transform: "rotateZ(270deg)",
                                          cursor: "pointer",
                                          marginBottom: "5px"
                                        }}
                                        onClick={this.downloadUpgradeFile}
                                      alt=""></img>
                                    </Tooltip>
                                    </div>
                                    <div className="col-6">
                                    {/* Aman-4679 Added this button to delete file *
                                    <Tooltip placement="top" title="Delete">
                                      <img
                                        deletename={record.fileName}
                                        style={{
                                          height: "26px",
                                          width: "26px",
                                          cursor: "pointer",
                                          marginBottom: "5px"
                                        }}
                                        onClick={this.deleteFile.bind(this)}
                                        src="file_upload_2_blue.png"
                                        alt=""
                                        width="20"
                                        height="20"
                                      ></img>
                                    </Tooltip>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                  </div>
                  {/* </table> */}
                  <br />
                {/* </CardBody> */}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <ReCAPTCHA
										//  style={{  paddingLeft: 0 }}
										class="captcha1"
										ref={(el) => {
											this.recaptcha = el;
										}}
										// sitekey="6LeMhqoUAAAAAD8zpqNy6XbKkXXh329D3ToY6Xwi"
										sitekey="6Le_--AUAAAAAMTOIH8A2kj-qN1XB0uLkusHewHX"
										onChange={this.handleCaptchaResponseChange}
									/> */}

          {/* 
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 " style={{ marginTop: '1px' }}>
                          <label>Physical Address</label>
                      <label class="chooseFile btn btn-default">Upload Lead
                                          <input style={{ display: 'none' }} type="file"
                              onChange={this.physicalAddressLeadFile} className={this.state.loading}
                          /></label><label id="fileName">&nbsp;No File Choosen</label>

                      </div> */}
        </div>

        <Footer />
      </div>
    );
  } //end of render
} // end of constructor

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */

domainName.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(withRouter(domainName));
