
/* Copyright(c) 2023 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/**
* @file userSwitchClientLogin
* @author   Aman Shaikh - 4619
* @version 1.0
* @section DESCRIPTION Switch Login Functionality
*
*/

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SwitchLogin  } from "../login/authentication";
import { Form, Card } from "antd";
import "./userLogin.css";
import Navigation from "../layouts/navSwitchClient";
import { encryption } from "../../configration/fetch_default_headers";
const Cryptr = require("cryptr");
const cryptr = new Cryptr(encryption.secretKey);

class userSwitchClientLogin extends React.Component {
    constructor() {
        super();
        this.state = { 
            errors: {},
            data: [],
            clients: "",
            agencies: "",
            systemIP:""
        };
        
        this.onChange = this.onChange.bind(this);
    }
 
    //Aman-Task-4619-(created to get the values from the dropdwon)-PC/PNC-Login - Client Switch option 
    onChange = (e, i) =>{
        this.setState({value : e.target.value});
        this.state.clients = this.state.data[e.target.value].clients;
        this.state.agencies = this.state.data[e.target.value].agencies || this.state.data[e.target.value].publisher; // Aman -Task-4724- (added this to get the publisher or agencies names)- AC/ANC/AQA-Login - Client Switch option
    }

    //Aman-Task-4619-(created to submite the slected info)-PC/PNC-Login - Client Switch option 
    onSwitch = (e)  =>{
        e.preventDefault();
        const data = this.state.data;
        let index = document.getElementById("otherInfo").value;
        let myHeading = document.getElementById('ErrorMsg');
        if(index === "" || null || undefined){
            myHeading.textContent='Please Select Login'; //Aman-4670-(Change the Client to login as per new requirement) ref(4667)--Error pop up message issue
        }else{
            var selectedvalue = data[index].orgID;
            const filterData = data.filter( (oi) => oi.orgID == selectedvalue)
            var encryptedOrgID = cryptr.encrypt(filterData[0].orgID);
            var encryptedUserName = cryptr.encrypt(filterData[0].userName);
            var encryptedData =  Buffer.from(JSON.stringify(data)).toString('base64');
            var encryptedUserRole = cryptr.encrypt(filterData[0].role);//Aman-4938-added role to pass that to server
            var encrypteduserID = cryptr.encrypt(filterData[0].userID); //Aman-4938-added userID to pass that to server
            const user = {
            orgID: encryptedOrgID,
            userName: encryptedUserName,
            data: encryptedData,
            role: encryptedUserRole, //Aman-4938-passing role to server
            ID: encrypteduserID, //Aman-4938-passing id to server
            systemIP: this.state.systemIP,
            loginFrom: "DI_web", //Sonali-3464-flag to identify login from web or mobile
            };
            this.props.form.validateFields((err, values) => {
            if (!err) {
                const { errors } = this.state;
                errors.agency = "";
                this.props.SwitchLogin(user);
            } else {
                return;
            }
            });
        }
    };
    
    //Aman-Task-4619-(Added to take the info from jwtToken for other logins)-PC/PNC-Login - Client Switch option 
    componentWillMount() {
        var x = localStorage.getItem("jwtToken"); //Aman-Task-4619 - Added to take info from jwtToken
        var y = localStorage.getItem('ClientSwitch'); //Aman-Task-4619 - Added to get one user info from ClientSwitch

        //Aman-Task-4619 - Added condtion to check userInfo is present ot not in localStorage
        if(x !== null && y == null ){
            //Aman-Task-4619 - this will decode the jwtToken info
            var base64Url = x.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            var oldData = JSON.parse(jsonPayload)

            var UserInfo = oldData.userDetails;
            var old_info_encryptedData = Buffer.from(JSON.stringify(UserInfo)).toString('base64');
            localStorage.setItem('ClientSwitch', old_info_encryptedData);

            //Aman-Task-4619 - this will Take the ClientSwitch info from the localStorage
            var userInfo_dec = localStorage.getItem('ClientSwitch');
            var userInfo_dec_data = new Buffer.from(userInfo_dec, 'base64').toString('ascii');  
            let old_info = JSON.parse(userInfo_dec_data);  

            let systemIP = old_info.ip;
            var data = old_info
            this.setState({ data: data, systemIP:systemIP }) 
        }else{
             //Aman-Task-4619 - if userInfo present in localStorage it will call this block
            var userInfo_dec = localStorage.getItem('ClientSwitch');
            var userInfo_dec_data = new Buffer.from(userInfo_dec, 'base64').toString('ascii');  
            var old_info_else = JSON.parse(userInfo_dec_data);  
        
            let systemIP = old_info_else.ip;
            var data = old_info_else
            this.setState({ data: data, systemIP:systemIP }) 
        }
        
    }

    //Aman-Task-4619-(this is to navgate the page after login)-PC/PNC-Login - Client Switch option 
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            const userID = nextProps.auth.user.id;
            const userRole = nextProps.auth.user.role;
            if (userRole !== undefined) {
                //Aman-Task -4619-(added this if condtion for checking multiple role with the same Username from switching) PC/PNC-Login - Client Switch option
                if (userRole === "PC" || userRole === "PNC") {     
                    this.props.history.push("/newPublisherDashboard"); //karan-task-3717-replace query params
                }
                // Aman -Task-4724-(added this else condtion to navigate AC/ANC/AQA role home page)- AC/ANC/AQA-Login - Client Switch option 
                else if(userRole === "AC" || userRole === "ANC" || userRole === "AQA"){
                    this.props.history.push("/newdashboard");
                }else{
                    this.props.history.push("/userLogin");
                }
            } else {
                this.props.history.push("/userLogin");
            }
        }
        if (nextProps.errors) {
        this.setState({
            errors: nextProps.errors,
        });
        //alert("Errors"+JSON.stringify(this.state.errors));
        }
    }

  render() {
    return (
        <div>
            <div
            className="container-fluid"
            style={{ position: "absolute", width: "100%", height: "100%",margin: "auto"}}
            >
                <div
                    className="row"
                    style={{ position: "absolute", width: "100%", height: "100%" }}
                >
                <Navigation/> {/* Aman-Task-4619-( navgate component)-PC/PNC-Login - Client Switch option */}
                    <div style={{ marginTop: "100px", height: "auto",margin: "auto" }}>
                        <h2>Select Login:</h2>  {/* Task-4667- ref(4619)--(added this for Additional requirement )Showing "," in Linked Client list for particular client */}
                        <Card
                            id="SwitchClient"
                            style={{
                            width: 500,
                            height: "auto",
                            margin: "auto",
                            display: "block",
                            padding: "auto 20px",
                            border: "2px solid transparent",
                            borderRadius: "10px",
                            backgroundColor: "rgba(20,72,152,0.1)",
                            }}>
                            <form onSubmit={this.onSwitch}>
                                <div
                                    direction="vertical"
                                    style={{
                                    width: '100%',
                                    }}>
                                    <p id="ErrorMsg" style={{color: "red"}}></p> {/* this tag for displaying error msg when user not select anything from dropdown*/}
                                    {/* Aman-Task-4619-(Select list of userInfo)-PC/PNC-Login - Client Switch option */}
                                    <select
                                    status="error"
                                    style={{
                                        width: '100%',height: "30px", outline: "none", border: "1px solid #ddd", 
                                        borderRadius: "5px", cursor: "pointer"
                                    }}
                                    id="otherInfo"
                                    onChange={this.onChange} 
                                    >
                                    {/* Aman-Task-4619-(option to select from list)-PC/PNC-Login - Client Switch option */}
                                    <option hidden></option>
                                       {this.state.data.map((val, index) =>{
                                        return (
                                            <option id="dropdownList" key={index} value={index}>
                                            {val.role} - {val.orgID} - {val.firstName} {val.lastName}
                                            </option>
                                        )
                                        })}
                                    </select>
                                    <br /><br /> 
                                    {/* Aman-Task-4619-(this will display all Linked Client)-PC/PNC-Login - Client Switch option */}
                                    <div>
                                        {this.state.clients === "" ? ("") :(
                                            <>
                                                <h5 style={{display: "flex", flexWrap: 'wrap'}}>Linked Client: &nbsp;&nbsp;&nbsp;</h5>
                                                <p> {this.state.clients}</p>
                                            </>
                                        )}
                                    </div> 
                                    {/* Aman-Task-4619-(this will display all Linked Agencies)-PC/PNC-Login - Client Switch option */}
                                    <div>
                                            {this.state.agencies === "" ? ("") :(
                                                <>
                                                {/*Aman -Task-4724-(h5 added ternary operator to show Publisher/Agencies name list dropdown page)- AC/ANC/AQA-Login - Client Switch option */}
                                                <h5 style={{display: "flex", flexWrap: 'wrap'}}>
                                                    { this.state.data[0].role === "PC" || this.state.data[0].role === "PNC" ? 
                                                        ("Linked Agencies: ") : ("Linked Publisher: ")}&nbsp;&nbsp;&nbsp;
                                                </h5>
                                                <p> {this.state.agencies}</p>
                                                </>
                                            )}
                                    </div>
                                </div>    
                                <button
                                    type="submit"
                                    style={{
                                        float: 'right',
                                        backgroundImage: "linear-gradient(to left,#0777cb, #103784)",
                                        color: "white",
                                        }}
                                        className="btn"
                                    >
                                    Continue to Login {/* Task-4667- ref(4619)--(added this for Additional requirement )Showing "," in Linked Client list for particular client */}
                                </button>
                            </form>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the login authentication
   * @return Description return All details of authentication
   */
  propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  };
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(userSwitchClientLogin);
export default connect(mapStateToProps, { SwitchLogin })(WrappedNormalLoginForm);