/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Sanjana Godbole
*@fileName :
 *Desc: Publisher On Board Form
*/

import React from 'react';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';    
import Header from '../layouts/header1';
import PublisherCompanyInformation from "./publisherCompanyInformation";
import PublisherContactInformation from "./publisherContactInformation";
import PublisherGDPRCertificate from "./publisherGDPRCertificate";
import PublisherBankDetails from "./publisherBankDetails";
import Footer from '../layouts/footer';


class PublisherOnBoard extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
         //  data: 'Initial data...'
        }
        this.callback = this.callback.bind(this);
     };
     callback(key) {
        console.log(key);
        //alert("Active Tab:"+key);
      }
    render() {
        const { TabPane } = Tabs;

        return(
                <div>
                     <Header/> 
                     
                    <div class="container-fluid" style={{paddingTop:"60px"}}>
                        <br/>
                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                        </div>
                        <div class="col-xs-12  col-sm-12 col-md-4 col-lg-4">
                        {/* </div></div><label id="label" style={{ ,marginLeft:"450px"}}>Campaign Allocation</label> */}

                        <span ><h4 style={{textAlign:"center",color:"#056eb8",fontSize:"20px",fontWeight:"bold"}}>Publisher On-Board Form</h4></span>
                        </div>
                        <div class=" col-md-4 col-lg-4">
                        </div>
                    </div>
                    {/* End of 1st Row */}
                    <div class="row">
                    <div class="col-xs-12  col-sm-12 col-md-12 col-lg-12">
                        <Tabs onChange={this.callback} type="card">
                        <TabPane tab="Publisher Information" key="1-Company Information">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
                         <PublisherCompanyInformation /> 
                        </div>
                        </TabPane>
                        {/* <TabPane tab="Contact Information" key="2-Contact Information">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
                         <PublisherContactInformation /> 
                        </div>
                        </TabPane> */}
                        <TabPane tab="Global Compliance" key="3-Global Compliance">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
                        <PublisherGDPRCertificate/>
                        </div>
                        </TabPane>
                        <TabPane tab="Bank Details" key="4-Bank Details">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
                        <PublisherBankDetails/>
                        </div>
                        </TabPane>
                        {/* <TabPane tab="Privacy Policy" key="5-Privacy Policy">
                        Content of Tab Pane 5
                        </TabPane> */}
                        </Tabs>
                        </div>
                    </div>
                    {/* End of 2nd Row */}
                    </div>    
               <Footer/>
                </div>
                //Last div
            )}
}

export default PublisherOnBoard;