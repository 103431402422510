/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*@author Sunita Landge
*@fileName : agencyAdvertiserDeliverytracking.js
@Creation Date:21-04-2021
 *Desc: UI for agency view advertiser tab(tracking page) for story 3406
*/
import React from "react";
//import { Tabs, Tab } from 'react-bootstrap'; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import Navigation from "../layouts/navPage";
//import * as $ from "jquery"; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import './agencyMyReports.css';
import { css } from "emotion";
import { Table } from 'antd'; //Aman-4797-(removed Tooltip, Spin - not in use)-Console Warnings-Agency-Reports Part 2
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; 

const Swal = require('sweetalert2');


const tableCSS = css({
    backgroundColor: "white",
    fontFamily: "sans-serif",
    borderStyle: "bold",
    "& thead  > tr": {
        backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
        color: "white",
        fontSize: "12px",
        textAlign: "center !important",
        fontWeight: "500",
    },
    "& thead > tr >th": {
        textAlign: "center !important",
        color: "white",
    },
});
//const queryString = require('query-string'); //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2

class agencyAdvertiserDeliverytracking extends React.Component {
    constructor() {
        super();
        this.state = {
            tableState: { size: "small" },//sunita-task-3406-used for table design
            agencyAdvertiserCampaignInfo: [],//sunita-task-3406-declared for table data
            agencyAdvertiserCampaignInfo1: [],//sunita-task-3406-declare to set table data in this new array while filtering the data
            agencyMyReportsDataNew1: [],//sunita-task-3406-declare to set table data in this new array while filtering the data
            agencyMyReportsData: [],//sunita-task-3406-array declared for table data
            reportSummaryadv: [],//sunita-task-3406-array declared for report summary data
            reportDetailadv: [],//sunita-task-3406-array declared for report details of advertiser.
            advertiserList: [],//sunita-task-3406-array declared for advertiser name
            advertiserList1: [],//sunita-task-3406-array declared to filter advertiser name
            agencyID: "",// sunita-task-3406-declare to get the id from userLogin
            advertiserID: "None Selected",//sunita-task-3406-used to display bydefault non-selected .
            campaignStatus: "None Selected",//sunita-task-3406-used to display bydefault non-selected .
            deadline: "None Selected",//sunita-task-3406-used to display bydefault non-selected .
            label: "",//sunita-task-3406-used to filter deadline as per label .
            reportKey: "",//sunita-task-3406-used key to put conditions when user came by clicking on report name .
            reportKey1: "",//sunita-task-3406-used key to put conditions when user came by clicking on report name .
            advertiserName: "",//sunita-task-3406-to target advertiser name . 
            type: "Advertiser Campaign Delivery Tracking",//sunita-task-3406-given by default type
            reportName: "",//sunita-task-3406-used for report name .          
            loader: "",//sunita-task-3406-used for loader.
            loader1: "",//sunita-task-3406-used for generate loader.
            generateBtnFlag: false,//sunita-task-3406-used to enable - disable generate button.
            advID: '',//sunita-task-3406-used to access advid on this page came from myreport page.
            userType: "Advertiser",//sunita-task-3406-by default usertype
            pageSize: 100,//sunita-task-3406-added for page size.
            handleOnkey: 2,//sunita-task-3406-added for going back to my report page
        }//end of state

        //sunita-task-3406-binded all below mentioned handle changes as per requirement
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.onAdvertiserNameHandleChange = this.onAdvertiserNameHandleChange.bind(this);
        this.onCampaignStatusHandleChange = this.onCampaignStatusHandleChange.bind(this);
        this.ondeadlineHandleChange = this.ondeadlineHandleChange.bind(this);
        this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
        this.commonHandleChange = this.commonHandleChange.bind(this);
        this.generateHandleChange = this.generateHandleChange.bind(this);

    }// end of constrcutor

    /**
    * @author : Sunita Landge - task 3406
    * @description : handlechange for pageSize .
    */

    handleChangePageSize(e)
    {
        var pageSize = e.target.value;
        this.setState({ pageSize: pageSize });

    }//end of handleChangePageSize

    /**
    * @author : Sunita Landge - task 3406
    * @description : handlechange for backbutton to navigate on same page from where user came .
    */
    backButtonHandleChange(e) {
        e.preventDefault();                      //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE

        if (this.state.reportKey1 !== "report") {
            Swal.fire({
                title: 'Are you sure you want to discard this report?',
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                allowOutsideClick: false,
                confirmButtonText: "Discard",
            }).then((result) => {
                if (result.value) {
                    //window.location.href = "/agencyMyReports?handleOnkey=" + this.state.handleOnkey;//sunita-task-3459-added code when we go back that time by default it will open advertisertab.
                    this.props.history.push("/agencyMyReports",{handleOnkey:this.state.handleOnkey})

                }
                else if (result.dismiss === "close") {
                    window.location.href = "#";
                }
            })
        }
        else {
            //window.location.href = "/agencyMyReports?handleOnkey=" + this.state.handleOnkey;//sunita-task-3459-added code when we go back that time by default it will open advertisertab.
            this.props.history.push("/agencyMyReports",{handleOnkey:this.state.handleOnkey})

        }

    }//end of backButtonHandleChange

    /**
    * @author : Sunita Landge - task 3406
    * @description : handlechange to load data after selecting all three filters advertiser, campaign Status and timeline .
    */

    commonHandleChange(advertiserID, campaignStatus, deadline) {

        let reportSummaryadv = [{ "advertiserID": advertiserID, "advertiserName": "", "deliveryLabel": deadline, "totalAllocation": 0, "totalAccepted": 0, "deliveredLead": 0, "rejectedLead": 0, "qaReviewLead": 0, "balanceLead": 0 }]
        this.setState({ loader: "loader", agencyAdvertiserCampaignInfo: [], reportSummaryadv, generateBtnFlag: true })
        const { user } = this.props.auth;
        const agencyID = user.id;
        this.setState({ agencyID })

        let data = {
            //agencyID: agencyID,                                  //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE     
            advertiserID: advertiserID,
            campaignStatus: campaignStatus,
            timeLine: deadline,

        }

        fetch("/advertiserReportEngine/advCampDetails", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(agencyAdvertiserCampaignInfo => {   
                this.setState({ agencyAdvertiserCampaignInfo: agencyAdvertiserCampaignInfo.reportDetail, reportSummaryadv: agencyAdvertiserCampaignInfo.reportSummary, loader: "" })//,


            }).catch(function (err) { console.log(err) });
            
    }//end of commonHandleChange

    /**
    * @author : Sunita Landge - task 3406
    * @description : handlechange to target selected advertiser .
    */

    onAdvertiserNameHandleChange(e) {

        var advertiserID = e.target.value;
        this.setState({ advertiserID })
        let campaignStatus = this.state.campaignStatus;
        let deadline = this.state.deadline;
        if ((advertiserID && advertiserID !== "None Selected") && (campaignStatus && campaignStatus !== "None Selected") && (deadline && deadline !== "None Selected")) {
            this.commonHandleChange(advertiserID, campaignStatus, deadline); //sunita-task-3406-function called to load the data after selecting the filters
        }
        else {
            this.setState({ generateBtnFlag: false, agencyAdvertiserCampaignInfo: [] })
        }
    }//end of onAdvertiserNameHandleChange


    /**
    * @author : Sunita Landge - task 3406
    * @description : handlechange to target selected status .
    */

    onCampaignStatusHandleChange(e) {
        var campaignStatus = e.target.value;
        this.setState({ campaignStatus })
        let advertiserID = this.state.advertiserID;
        let deadline = this.state.deadline;
        if ((campaignStatus && campaignStatus !== "None Selected") && (advertiserID && advertiserID !== "None Selected") && (deadline && deadline !== "None Selected")) {
            this.commonHandleChange(advertiserID, campaignStatus, deadline);//sunita-task-3406-function called to load the data after selecting the filters
        }
        else {
            this.setState({ generateBtnFlag: false, agencyAdvertiserCampaignInfo: [] })
        }
    }//end of onCampaignStatusHandleChange

    /**
    * @author : Sunita Landge - task 3406
    * @description : handlechange to target selected deadline .
    */

    ondeadlineHandleChange(e) {

        var deadline = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;

        //Aman-4797-Commenting below code because it is not in use - taking this value from label directly 

        // if (deadline === "All") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
        //     this.setState({label : "All"});//this.state.label = "All" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
        // }
        // else if (deadline === "No Lead Delivered") {
        //     this.setState({label : "No Lead Delivered"});//this.state.label = "No Lead Delivered"
        // }
        // else if (deadline === "Ending Three Days") {
        //     this.setState({label : "Ending 3 days"});//this.state.label = "Ending 3 days"
        // }
        // else if (deadline === "Weekly") {
        //     this.setState({label : "Ending This Week"});//this.state.label = "Ending This Week"
        // }
        // else if (deadline === "Monthly") {
        //     this.setState({label : "Ending This Month"});//this.state.label = "Ending This Month"
        // }
        // else if (deadline === "Quarterly") {
        //     this.setState({label : "Ending This Quarter"});//this.state.label = "Ending This Quarter"
        // }
        // else if (deadline === "Yearly") {
        //     this.setState({label : "Ending This Year"}); //this.state.label = "Ending This Year"
        // }
        // else if (deadline === "PreMonth") {
        //     this.setState({label : "Ending Previous Month"}); //this.state.label = "Ending Previous Month"
        // }
        // else if (deadline === "PreQuarter") {
        //     this.setState({label : "Ending Previous Quarter"}); //this.state.label = "Ending Previous Quarter"
        // }
        // else if (deadline === "PreYear") {
        //     this.setState({label : "Ending Previous Year"}); //this.state.label = "Ending Previous Year"
        // }

        this.setState({ deadline, label: label})//Aman-4797-(remove this label: this.state.label/e.nativeEvent.target[index].text dupliacte)-Console Warnings-Agency-Reports Part 2
        let { advertiserID, campaignStatus } = this.state;

        if ((deadline && deadline !== "None Selected") && (campaignStatus && campaignStatus !== "None Selected") && (advertiserID && advertiserID !== "None Selected")) {

            this.commonHandleChange(advertiserID, campaignStatus, deadline);//sunita-task-3406-function called to load the data after selecting the filters
        }
        else {
            this.setState({ generateBtnFlag: false, agencyAdvertiserCampaignInfo: [] })
        }

    }//end of ondeadlineHandleChange

    /**
    * @author : Sunita Landge - task 3406
    * @description : handlechange to generate report successfully .
    */

    generateHandleChange(e) {
        const { user } = this.props.auth;
        const agencyID = user.id;
        let advertiserID = this.state.advertiserID;
        let deadline = this.state.deadline;
        let campaignStatus = this.state.campaignStatus;
        let reportDetailadv = [...this.state.agencyAdvertiserCampaignInfo];
        let reportSummaryadv = [...this.state.reportSummaryadv];
        let reportName = this.state.reportName;
        let type = this.state.type;
        let userType = this.state.userType;
        this.setState({ advertiserID, campaignStatus, deadline, user, agencyID, reportDetailadv, reportSummaryadv, reportName, type, userType, loader1: "gen_loader" })
        if ((advertiserID !== "None Selected") && (campaignStatus !== "None Selected") && (deadline !== "None Selected")) {
            let data = {
                //user: user,                   //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
                //agencyID: agencyID,
                advertiserID: advertiserID,
                deadline: deadline,
                campaignStatus: campaignStatus,
                type: type,
                reportName: reportName,
                reportDetail: reportDetailadv,
                reportSummary: reportSummaryadv,
                userType: userType

            }

            fetch("/advertiserReportEngine/generateReportAdvertiser", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then((res) => {
                    this.setState({ loader1: "" })
                    if (res.success === true) {                                 //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
                        Swal.fire({
                            title: '<span id="success_popUp"></span>',
                            //html: "<span id='for_popUp_generate'>Please <a href={'/agencyMyReports?handleOnkey=2'><span id='for_popUp_generate1'>click here</span></a> to check report.", //sunita-task-3459-added code when we go back that time by default it will open advertisertab.
                            html: "<h4><h4>Report generated successfully</h4><h4><a href='#'>Please </a><a href='agencyMyReports'><span style='color:#F28C0F'>click here</span></a><a href='#'> to check report</a></h4></h4>",
                            type: "success",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                            preConfirm: () => {
                               
                                 window.location.href="/agencyMyReports";
                            },
                           
                        });
                    }
                    if (res.success === false) {
                        Swal.fire({
                            title: 'Report data empty.',
                            type: "error",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                            preConfirm: () => {
                                // window.location.reload();
                            },
                        });
                    }

                }).catch(function (err) { console.log(err) });
        }
    }//end of generateHandleChange


    componentWillMount() {              //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
        }
        else {
 let reportKey,reportID,advertiserID,reportName,type; //Aman-4797-(added reportName)
            if (this.props.location.state !== undefined)
            {
                advertiserID=this.props.location.state.advID; 
                reportKey=this.props.location.state.reportKey;
                reportID=this.props.location.state.reportID;
                //this.state.reportID=reportID; //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                reportName=this.props.location.state.reportName;
                this.setState({reportName : reportName , reportID : reportID});//this.state.reportName=reportName; //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                type = this.props.location.state.type;
                 //type = this.props.location.state.advtype;

           }
            //const { isAuthenticated } = this.props.auth; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
            const { user } = this.props.auth;
            //var reportName = this.state.reportName; //Aman-4797-(comment reportName)
            const agencyID = user.id;
          
            this.setState({ agencyID, advertiserID, reportKey1:reportKey, generateBtnFlag: false, reportName : reportName, type}) //Aman-4797-(added reportName as a reportName)


            let data = {
                //agencyID: agencyID,               //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
                advertiserID: advertiserID,
                reportID: reportID,
            }       
            if (reportID) {

                //sunita-task-3406-this route fetched to show the report details of advertiser
                fetch("/reportEngine/reportDetail", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json())
                    .then(agencyAdvertiserCampaignInfo => {

                        let deadline, campaignStatus, advertiserID
                        if (agencyAdvertiserCampaignInfo) {
                            advertiserID = agencyAdvertiserCampaignInfo.advertiserID;
                            campaignStatus = agencyAdvertiserCampaignInfo.campaignStatus;
                            deadline = agencyAdvertiserCampaignInfo.deadline;
                        }
                        
                        if (agencyAdvertiserCampaignInfo.deadline !== "") {
                            if (agencyAdvertiserCampaignInfo.deadline === "All") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
                                this.setState({label : "All"}); //this.state.label = "All" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
                            }
                            else if (agencyAdvertiserCampaignInfo.deadline === "No Lead Delivered") {
                                this.setState({label : "No Lead Delivered"}); //this.state.label = "No Lead Delivered"
                            }
                            else if (agencyAdvertiserCampaignInfo.deadline === "Ending Three Days") {
                                this.setState({label : "Ending 3 days"}); //this.state.label = "Ending 3 days" 
                            }
                            else if (agencyAdvertiserCampaignInfo.deadline === "Weekly") {
                                this.setState({label : "Ending This Week"}); //this.state.label = "Ending This Week"
                            }
                            else if (agencyAdvertiserCampaignInfo.deadline === "Monthly") {
                                this.setState({label : "Ending This Month"}); //this.state.label = "Ending This Month"
                            }
                            else if (agencyAdvertiserCampaignInfo.deadline === "Quarterly") {
                                this.setState({label : "Ending This Quarter"}); //this.state.label = "Ending This Quarter"
                            }
                            else if (agencyAdvertiserCampaignInfo.deadline === "Yearly") {
                                this.setState({label : "Ending This Year"}); //this.state.label = "Ending This Year"
                            }
                            else if (agencyAdvertiserCampaignInfo.deadline === "PreMonth") {
                                this.setState({label : "Ending Previous Month"}); //this.state.label = "Ending Previous Month"
                            }
                            else if (agencyAdvertiserCampaignInfo.deadline === "PreQuarter") {
                                this.setState({label : "Ending Previous Quarter"}); //this.state.label = "Ending Previous Quarter"
                            }
                            else if (agencyAdvertiserCampaignInfo.deadline === "PreYear") {
                                this.setState({label : "Ending Previous Year"}); //this.state.label = "Ending Previous Year"
                            }
                        }

                        this.setState({
                            agencyAdvertiserCampaignInfo: agencyAdvertiserCampaignInfo.reportDetail, reportSummaryadv: agencyAdvertiserCampaignInfo.reportSummary, advertiserID, deadline, campaignStatus, label: this.state.label
                        })

                    }).catch(function (err) { console.log(err) });
            }

            //sunita-task-3406-api fetched for getting advertiser list
            fetch("/advertiserReportEngine/getADVList", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then(advertiserList => {

                    this.setState({ advertiserList, advertiserList1: advertiserList })
                }).catch(function (err) { console.log(err) });

        }

    }//end of componentWillMount

    /**
* @author : Sunita Landge-task 3406
* @description :Render part to display complete screen with functionality as per given screen .
*/

    render() {
        const asterisk =
        {
            color: 'red',
        }

        const columnsReport = [
            {
                title: <span>Camp ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campID",
                key: "campID",
                width: "7.5%",

                align: "left",
                sorter: (a, b) => a.campID - b.campID,
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span  style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3484- added css as per DI standard*/}
                            {record.campID}
                        </span>
                        <br />
                    </div>

                ),

            },
            {
                title: <span>Agency Camp ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "agencyID",
                key: "agencyID",
                width: "8%",

                align: "left",
                sorter: (a, b) => a.agencyID - b.agencyID,
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3484- added css as per DI standard*/}
                            {record.agencyID}
                        </span>
                        <br />
                    </div>
                ),
            },
            // {
            // 	title: <span>Advertiser&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
            // 	dataIndex: "clientName",
            // 	key: "clientName",
            // 	width: "8.5%",

            // 	align: "left",
            // 	sorter: (a, b) => {
            //         const asc = "asc";

            //         if (a.clientName.toUpperCase() < b.clientName.toUpperCase()) {
            //             return asc ? -1 : 1;
            //         } else if (
            //             a.clientName.toUpperCase() > b.clientName.toUpperCase()
            //         ) {
            //             return asc ? 1 : -1;
            //         } else {
            //             return 0;
            //         }
            //     },
            // 	render: (text, record) => (
            // 		<div id="to_truncate"style={{ width: "75px" }}>
            // 			<span style={{ fontSize: "12px" }}>
            // 			{record.clientName}
            // 			</span>
            // 			<br />
            // 		</div>
            // 	),
            // },
            {
                title: <span>Camp Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campaignName",
                key: "campaignName",
                width: "11%",

                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
                        return asc ? -1 : 1;
                    } else if (
                        a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
                    ) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div id="to_truncate1">
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3484- added css as per DI standard*/}
                            {record.campaignName}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Start Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "startDate",
                key: "startDate",
                width: "7%",

                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.startDate < b.startDate) {
                        return asc ? -1 : 1;
                    } else if (a.startDate > b.startDate) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3484- added css as per DI standard*/}
                            {record.startDate}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>End Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "endDate",
                key: "endDate",
                width: "7%",

                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.endDate < b.endDate) {
                        return asc ? -1 : 1;
                    } else if (a.endDate > b.endDate) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3484- added css as per DI standard*/}
                            {record.endDate}
                        </span>
                        <br />
                    </div>
                ),
            },

            {
                title: <span>Allocated Lead&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "allocatedLead",
                key: "allocatedLead",
                width: "7%",

                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.allocatedLead < b.allocatedLead) {
                        return asc ? -1 : 1;
                    } else if (a.allocatedLead > b.allocatedLead) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3484- added css as per DI standard*/}
                            {/* {record.leadAllocation} */}
                            {record.allocatedLead}
                        </span>
                        <br />
                    </div>
                ),
            },

            {
                title: "Delivery Status",
                width: "70%",
                children: [
                    {
                        title: <span>Delivered&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "deliveredLead",
                        key: "deliveredLead",
                        align: "left",
                        width: "6%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.deliveredLead < b.deliveredLead) {
                                return asc ? -1 : 1;
                            } else if (a.deliveredLead > b.deliveredLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                            <div style={{fontSize: '13px', color: '#4F4F4F'}}>{/*sunita-task-3484- added css as per DI standard*/}
                                {record.deliveredLead}
                                {/* {record.deliveredLead > 0 ? ( */}
                                <a
                                    href="#/"

                                >

                                </a>
                                {/* ) : (
                                                    0
                                                )} */}
                            </div>
                        ),

                    },
                    {
                        title: <span>Review Pending&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "qaReviewLead",
                        key: "qaReviewLead",
                        align: "left",
                        width: "8%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.qaReviewLead < b.qaReviewLead) {
                                return asc ? -1 : 1;
                            } else if (a.qaReviewLead > b.qaReviewLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                            <div style={{fontSize: '13px', color: '#4F4F4F'}}>{/*sunita-task-3484- added css as per DI standard*/}
                                {record.qaReviewLead}
                                {/* {record.qaReviewLead > 0 ? ( */}
                                <a
                                    href="#/">

                                </a>
                                {/* ) : (
                                                    0
                                                )} */}
                            </div>
                        ),
                    },
                    {
                        title: <span>Accepted&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "acceptedLead",
                        key: "acceptedLead",
                        align: "left",
                        width: "6%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.acceptedLead < b.acceptedLead) {
                                return asc ? -1 : 1;
                            } else if (a.acceptedLead > b.acceptedLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                            <div style={{fontSize: '13px', color: '#4F4F4F'}}>{/*sunita-task-3484- added css as per DI standard*/}
                                {record.acceptedLead}
                            </div>
                        ),
                    },
                    {
                        title: <span>Rejected&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "rejectedLead",
                        key: "rejectedLead",
                        align: "left",
                        width: "6%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.rejectedLead < b.rejectedLead) {
                                return asc ? -1 : 1;
                            } else if (a.rejectedLead > b.rejectedLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                            <div style={{fontSize: '13px', color: '#4F4F4F'}}>{/*sunita-task-3484- added css as per DI standard*/}
                                {record.rejectedLead}
                            </div>
                        ),
                    },
                    {
                        title: <span>Balance&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "balancedLead",
                        key: "balancedLead",
                        align: "left",
                        width: "6%",
                        //defaultSortOrder: 'dscend',
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.balancedLead < b.balancedLead) {
                                return asc ? -1 : 1;
                            } else if (a.balancedLead > b.balancedLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render(text, record) {

                            return {
                                props: {
                                    style: { wordWrap: "break-word", height: "5%", width: "6%" },
                                },
                                children: (
                                    <div style={{ fontSize: "13px",color:'#4F4F4F'}}>{/*sunita-task-3484- added css as per DI standard*/}
                                        {/* {" "} */}{record.balancedLead}
                                        {/* {record.balancedLead ? record.balancedLead : 0} */}
                                    </div>
                                ),
                            };
                        },
                    },
                ],

            },

        ]


        function onChange(pagination, filters, sorter) {
            console.log("params", pagination, filters, sorter);
        }
        return (
            <div>
                <Navigation />
                <div style={{ paddingBottom: '60px', paddingTop: '100px' }} class="container-fluid ">
                    <div class="row">
                       
                         <div class="col-lg-2 col-md-1 col-sm-1" >       {/*3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE */}
                            {/*Aman-4797-(added void(0) to "#/" and removed the duplicate style attribute)-Console Warnings-Agency-Reports Part 2 */}
                            <a style={{ color: '#056eb8' }} href={"#/"} onClick={(e) => {this.backButtonHandleChange(e)}}><FaArrowAltCircleLeft size={32} title="Back to agencyMyReport" /></a>

                        </div>
                       
                        <div class=" col-lg-8 col-md-8 col-sm-8" align="center">
                            <label id="labelDI">Advertiser Delivery Tracking Report</label>
                        </div>

                        <div class="col-lg-2 col-md-3 col-sm-12 generateButton2" style={{ textAlign: 'right' }} > {/*sunita-task-3484-changed classname as some css are reflecting*/}
                            {/*Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                            {this.state.reportKey1 === "report" ?

                                ""
                                :
                                this.state.generateBtnFlag === true ?
                                /*sunita-task-3484- added below css as per DI standard*/
                                    <button class="btn add-button" style={{ backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"
                                    ,color:" white"}} type="button" data-toggle="modal" data-target="#generateReport"
                                        onClick={this.generateHandleChange} >Generate Report</button>
                                    :
                                    <button disabled class="btn add-button" style={{ backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"
                                    ,color:" white"}} type="button" data-toggle="modal" data-target="#generateReport"
                                        onClick={this.generateHandleChange} >Generate Report</button>
                            }

                        </div>
                    </div>
                    <div class="row" style={{ paddingTop: '15px', paddingLeft: '25px', paddingBottom: '11px' }}>
                        
                        <div class="col-sm-12 col-md-4 col-lg-4" id="Ad_1">
                            <label style={{ fontSize:'14px',color: '#14254A',fontFamily:'sans-serif' }}>Advertiser&nbsp;:<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;</label>{/*sunita-task-3484- added css as per DI standard*/}
                            {/*Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                            {this.state.reportKey1 === "report" ?
                                <select disabled value={this.state.advertiserID} name="AdvertiserName" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555' }} onChange={this.onAdvertiserNameHandleChange}>{/*sunita-task-3484- added css as per DI standard*/}
                                    <option value={"None Selected"}>None Selected</option>
                                    {this.state.advertiserList.map((advertiserList, i) => (
                                        <option value={advertiserList.advertiserID} key={i}>{advertiserList.advertiserName}</option>))}
                                </select>
                                :
                                <select value={this.state.advertiserID} name="AdvertiserName" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555'  }} onChange={this.onAdvertiserNameHandleChange}>
                                    <option value={"None Selected"}>None Selected</option>
                                    {this.state.advertiserList.map((advertiserList, i) => (
                                        <option value={advertiserList.advertiserID} key={i}>{advertiserList.advertiserName}</option>))}
                                </select>

                            }
                        </div>
                        {/* &nbsp; */}
                        <div class="col-sm-12 col-md-4 col-lg-4" id="CS_1">
                            <label style={{ fontSize:'14px',color: '#14254A',fontFamily:'sans-serif' }}>Campaign Status&nbsp;:<span style={asterisk}>*</span>&nbsp;</label>{/*sunita-task-3484- added css as per DI standard*/}
                            {/*Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                            {this.state.reportKey1 === "report" ?
                                <select disabled name="CampaignStatus" id="CampStatDrop1" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555'  }}
                                    value={this.state.campaignStatus} required onChange={this.onCampaignStatusHandleChange} >{/*sunita-task-3484- added css as per DI standard*/}
                                    <option selected>None Selected</option>
                                    <option value="Active">Active</option>
                                    <option value="Paused">Paused</option>
                                    <option value="Completed">Completed</option>
                                </select>
                                :
                                <select name="CampaignStatus" id="CampStatDrop1" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555'  }}
                                    value={this.state.campaignStatus} required onChange={this.onCampaignStatusHandleChange} >
                                    <option selected>None Selected</option>
                                    <option value="Active">Active</option>
                                    <option value="Paused">Paused</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            }
                        </div>
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                     
                        <div class=" col-sm-12 col-md-4 col-lg-4" id="TL_1">
                            <label style={{ fontSize:'14px',color: '#14254A',fontFamily:'sans-serif' }}>Timeline&nbsp;:<span style={asterisk}>*</span>&nbsp;&nbsp;</label>{/*sunita-task-3484- added css as per DI standard*/}
                            {/*Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                            {this.state.reportKey1 === "report" ?
                                <select disabled name="Timeline" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555'  }}
                                    value={this.state.deadline} required onChange={this.ondeadlineHandleChange} > {/*sunita-task-3484- added css as per DI standard*/}
                                    <option value={"None Selected"}>None Selected</option>
                                    <option value="All" lable="All">All</option>
                                    <option value="No Lead Delivered" label="No Lead Delivered">No Lead Delivered</option>
                                    <option value="Ending Three Days" label="Ending 3 days">Ending 3 days</option>
                                    <option value="Weekly" label="Ending This Week">Ending This Week</option>
                                    <option value="Monthly" label="Ending This Month">Ending This Month</option>
                                    <option value="Quarterly" label="Ending This Quarter">Ending This Quarter</option>
                                    <option value="Yearly" label="Ending This Year">Ending This Year</option>
                                    <option value="PreMonth" label="Ending Previous Month">Ending Previous Month</option>
                                    <option value="PreQuarter" label="Ending Previous Quarter">Ending Previous Quarter</option>
                                    <option value="PreYear" label="Ending Previous Year">Ending Previous Year</option>

                                </select>
                                :
                                <select style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555'  }} name="Timeline"
                                    value={this.state.deadline} required onChange={this.ondeadlineHandleChange} >
                                    <option value={"None Selected"}>None Selected</option>
                                    <option value="All" lable="All">All</option>
                                    <option value="No Lead Delivered" label="No Lead Delivered">No Lead Delivered</option>
                                    <option value="Ending Three Days" label="Ending 3 days">Ending 3 days</option>
                                    <option value="Weekly" label="Ending This Week">Ending This Week</option>
                                    <option value="Monthly" label="Ending This Month">Ending This Month</option>
                                    <option value="Quarterly" label="Ending This Quarter">Ending This Quarter</option>
                                    <option value="Yearly" label="Ending This Year">Ending This Year</option>
                                    <option value="PreMonth" label="Ending Previous Month">Ending Previous Month</option>
                                    <option value="PreQuarter" label="Ending Previous Quarter">Ending Previous Quarter</option>
                                    <option value="PreYear" label="Ending Previous Year">Ending Previous Year</option>

                                </select>
                            }
                        </div>
                    </div>
                    {/* <br/> */}
                    <hr />

                    
                    <div style={{ backgroundColor: 'white', marginBottom: '30px',border: '1px solid #cccccc',borderRadius:'3px' }}>{/*sunita-task-3484- added css as per DI standard*/}
                        <div style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: '10px' }}
                        >

                            <div>


                                <div>
                                    
                                    <label style={{ fontFamily: 'Roboto', fontWeight: '600px', fontSize: '16px', color: '#F28C0F' }}>Delivery Summary :
                                            {/*Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                                            {this.state.deadline !== "None Selected" || this.state.reportKey1 === "report" ?
                                            <span style={{ color: "#414141", fontSize: "14px" }}>&nbsp;&nbsp;&nbsp;{this.state.label}
                                                {this.state.label === "All" || this.state.label === "No Lead Delivered" ? ""
                                                    : this.state.reportSummaryadv && this.state.reportSummaryadv.map(reportSummaryadv => (
                                                        <span style={{ color: "#414141", fontSize: "14px" }}>({reportSummaryadv.deliveryLabel})</span>
                                                    ))}
                                            </span>
                                            : ""}
                                    </label>
                                   
                                </div>
                                <hr></hr>
                                {/*Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                                {this.state.reportKey1 === "report" || this.state.generateBtnFlag === true ?

                                    <div>
                                        {this.state.reportSummaryadv && this.state.reportSummaryadv.map(reportSummaryadv => (
                                            <div class="row">
                                                <div class="col-sm-2.5" style={{ marginLeft: '16px' }}>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>
                                                        {/* <b> */}
                                                            Advertiser ID &nbsp;:&nbsp;
                                                            {/* </b> */}
                                                    </label>
                                                    <span style={{color: "#14254A"}}><b>{reportSummaryadv.advertiserID}&nbsp;</b></span>{/*sunita-task-3484- added css as per DI standard*/}
                                                </div>
                                                <div class="col-sm-3 col-lg-4 col-md-6" style={{ marginBottom: "10px" }}>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>
                                                        {/* <b> */}
                                                            Advertiser Name&nbsp;:&nbsp;
                                                            {/* </b> */}
                                                    </label>
                                                    <span style={{color: "#14254A"}}><b>{reportSummaryadv.advertiserName}</b></span>{/*sunita-task-3484- added css as per DI standard*/}
                                                </div>
                                                 {/* */}
                                                 <div class="col-sm-2">
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                    Report ID&nbsp;:&nbsp;
                </label>
                                                    <span style={{color: "#14254A"}}>{this.state.reportID}&nbsp;</span>
                                                </div>
                                                <div class="col-sm-3 col-lg-4" style={{ marginBottom: "10px" }}>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                    Report Name&nbsp;:&nbsp;
                </label>
                                                    <span style={{color: "#14254A"}}>{this.state.reportName}</span>
                                                </div>
                                                {/* Chaitanya-4099-Reportname and ReportID- not getting display once click on the report name link  */}

                                                <div class="col-sm-7 col-lg-6"></div>

                                                <div class="col-sm-12 col-md-12 col-lg-12">
                                                    <hr></hr>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}><b>Total count :</b></label>
                                                </div>

                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>{/*sunita-task-3484- added css as per DI standard*/}
                                                        Allocated Lead&nbsp;:&nbsp;</label>

                                                    <span style={{color:"#414141"}}>{/*sunita-task-3484- added css as per DI standard*/}
                                                        &nbsp;{reportSummaryadv.totalAllocation}
                                                    </span>
                                                </div>

                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Delivered&nbsp;:&nbsp;</label>{/*sunita-task-3484- added css as per DI standard*/}
                                                    <span style={{ color: '#193D8F' }}>{reportSummaryadv.deliveredLead}</span>

                                                </div>
                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Review Pending&nbsp;:&nbsp;</label>{/*sunita-task-3484- added css as per DI standard*/}
                                                    <span style={{ color: '#F28C0F' }}>
                                                        {reportSummaryadv.qaReviewLead}
                                                    </span>

                                                </div>
                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Accepted&nbsp;:&nbsp;</label>
                                                    <span style={{ color: '#008000' }}>{reportSummaryadv.totalAccepted}</span>{/*sunita-task-3484- added css as per DI standard*/}

                                                </div>
                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Rejected&nbsp;:&nbsp;</label>
                                                    <span style={{ color: '#FF0000' }}>{reportSummaryadv.rejectedLead}</span>{/*sunita-task-3484- added css as per DI standard*/}

                                                </div>
                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Balance&nbsp;:&nbsp;</label>
                                                    <span style={{ color: '#4F4F4F' }}>{/*sunita-task-3484- added css as per DI standard*/}
                                                        {reportSummaryadv.balanceLead}
                                                    </span>

                                                </div>


                                            </div>

                                        ))}
                                    </div>

                                    :
                                    <div>
                                        <div class="row">
                                            <div class="col-sm-2.5" style={{ marginLeft: '16px' }}>
                                                <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>
                                                    {/* <b> */}
                                                        Advertiser ID &nbsp;:&nbsp;
                                                        {/* </b> */}
                                                </label>
                                                <span style={{color:'#14254A'}}><b>{0}&nbsp;</b></span>{/*sunita-task-3484- added css as per DI standard*/}
                                            </div>
                                            <div class="col-sm-3 col-lg-4 col-md-6" style={{ marginBottom: "10px" }}>
                                                <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>
                                                    {/* <b> */}
                                                        Advertiser Name&nbsp;:&nbsp;
                                                        {/* </b> */}
                                                </label>
                                                <span style={{color:'#14254A'}}><b>{"NA"}</b></span>{/*sunita-task-3484- added css as per DI standard*/}
                                            </div>
                                            <div class="col-sm-7 col-lg-6"></div>

                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <hr></hr>
                                                <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}><span>Total count :</span></label>
                                            </div>

                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>
                                                    Allocated Lead&nbsp;:&nbsp;</label>

                                                <span style={{color:"#414141"}}>{/*sunita-task-3484- added css as per DI standard*/}
                                                    &nbsp;{0}
                                                </span>

                                            </div>

                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Delivered&nbsp;:&nbsp;</label>
                                                <span style={{ color: '#193D8F' }}>{0}</span>{/*sunita-task-3484- added css as per DI standard*/}

                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Review Pending&nbsp;:&nbsp;</label>
                                                <span style={{ color: '#F28C0F' }}>
                                                    {0}</span>

                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Accepted&nbsp;:&nbsp;</label>
                                                <span style={{ color: '#008000' }}>{0}</span>{/*sunita-task-3484- added css as per DI standard*/}

                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Rejected&nbsp;:&nbsp;</label>
                                                <span style={{ color: '#FF0000' }}>{0}</span>{/*sunita-task-3484- added css as per DI standard*/}

                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Balance&nbsp;:&nbsp;</label>
                                                <span style={{ color: '#4F4F4F' }}>{/*sunita-task-3484- added css as per DI standard*/}
                                                    {0}</span>

                                            </div>


                                        </div>
                                    </div>}


                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3" style={{ marginTop: '16px', marginLeft: '-21px' }}>

                                <span style={{color:"#717171"}}>Showing results <b style={{color:"#414141"}}>
                                    {this.state.agencyAdvertiserCampaignInfo.length}
                                </b></span>

                            </div>

                        </div>

                    </div>
                    <div align="center" id={this.state.loader1}></div>
                    <div id={this.state.loader}></div>
                    
                    <Table {...this.state.tableState}
                        id="myTable tr"
                        bordered
                        dataSource={this.state.agencyAdvertiserCampaignInfo}
                        columns={columnsReport}
                        onChange={onChange}
                        className={`${tableCSS} "ant-table-thead"`} //Aman-4797-(changed 2 className in One)-Console Warnings-Agency-Reports Part 2
                        //className="ant-table-thead"
                        //className={tableCSS}
                        scroll={{ x: 1200, y: 1333 }}
                        pagination={{
                            pageSize: this.state.pageSize,
                            position: "bottom",

                        }}>

                    </Table>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6"
                        style={{ marginTop: "-53px", marginLeft: "-5px", fontSize: '13px', color: '#4F4F4F', fontFamily: 'sans-serif' }}
                    >{/*sunita-task-3484- added css as per DI standard*/}
                        Records per page:&nbsp;
												<select
                            defaultValue={this.state.pageSize}
                            onChange={this.handleChangePageSize}
                            id="pacing11"
                            class="input-small"
                            className="form-control"
                            name="pacing"
                            style={{ width: "71px", height: "30px", display: "initial", fontSize: '13px', fontFamily: 'sans-serif', color: '#4F4F4F' }}>
                            <option value="100" selected>
                                100
													</option>
                            <option value="200">200</option>
                            <option value="300">300</option>

                        </select>

                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

agencyAdvertiserDeliverytracking.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(agencyAdvertiserDeliverytracking));

