/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file Default Fetch params
 * @author  Karan Jagtap
 * @version 1.0
 * @description Used to add authorization header for all fetch() api calls
 * */
const fetchDefaults = require("fetch-defaults");
var encryption = { secretKey: "DIMAND%^INTEGRATE#!APSS*@MEDIA&$"}; //Rutuja -4214 Login Route Issue - Payload showing details (decrypted Password)

var fetch_custom = fetchDefaults(fetch, {
	headers: { authorization: localStorage.getItem("jwtToken") },
});

const setTokenForFetch = (token) => {
	fetch_custom = fetchDefaults(fetch, {
		headers: { authorization: token },
	});
};

export { fetch_custom, setTokenForFetch ,encryption  }; //Rutuja -4214 Login Route Issue - Payload showing details (decrypted Password)
