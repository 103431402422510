/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
import React from 'react';
import { Table, TableBody, TableHead  } from 'mdbreact';


// stateless component
class LeadReviewTable extends React.Component {
  constructor() {
    super();
    this.state = { 
      lpStatus: 'Approved', lpStatus1: "Rejected",checked: true
    }; // end of this.state
    this.handleChange = this.handleChange.bind(this);
  
    
   
  } // end of constructor

  
  handleChange(e) {
    let fields = this.state;
    fields[e.target.name] = e.target.value;
    //alert(this.state.checked);
    this.setState({
      fields,
    
    });
  } // end of  handleChange(e)
  
  
render() {
  
  
 return(
    <div>
     {/* <p>this box is {msg}.</p> */}
         <h5  class="labelsubheading" style={{fontSize:'16px'}}>Lead Details</h5>
         <br/>
        <Table scrollY  className=" table table-bordered table-striped "  >
        <TableHead color="primary-color#4285F4" class="table-fixed">
       
        <tr>
        <th style={{width:'8%'}}>Lead ID</th>
          <th style={{width:'10%'}}>First Name </th>
          <th style={{width:'10%'}}>Last Name</th>
          <th style={{width:'15%'}}>Email Id</th>
          <th style={{width:'8%'}}>Contact Number</th>
          <th style={{width:'8%'}}>Country</th>
          <th style={{width:'10%'}}>Status</th>
          <th>Feedback</th>
         
          </tr>
      </TableHead>
      <TableBody>
        <tr>
        <td>101</td>
          <td>Mark</td>
          <td>Jackson</td>
          <td>mark@gmail.com</td>
          <td>9876567890</td>
          <td>US</td>
          <td>
          <div className="form-group">
          <select style={{ height: '60%' }} 
          id="status" name="status" value={this.state.status} 
          onChange={""} className="form-control">
          <option value="Approved">Approved</option>
          <option value="Rejected">Rejected</option>
          </select>
          </div>
          </td>
          <td>
          <textarea style={{height:'50px'}} id="feedback"  name="feedback" value={this.state.feedback} 
          onChange={this.handleChange}
         class="form-control" />
          </td>

        </tr>
        <tr>
        <td>101</td>
          <td>Mark</td>
          <td>Jackson</td>
          <td>mark@gmail.com</td>
          <td>9876567890</td>
          <td>US</td>
          <td>
          <div className="form-group">
          <select style={{ height: '60%' }} 
          id="status" name="status" value={this.state.status} 
          onChange={""} className="form-control">
          <option value="Approved">Approved</option>
          <option value="Rejected">Rejected</option>
          </select>
          </div>
          </td>
          <td>
          <textarea style={{height:'50px'}} id="feedback"  name="feedback" value={this.state.feedback} 
          onChange={this.handleChange}
         class="form-control" />
          </td>

        </tr>
        <tr>
        <td>101</td>
          <td>Mark</td>
          <td>Jackson</td>
          <td>mark@gmail.com</td>
          <td>9876567890</td>
          <td>US</td>
          <td>
          <div className="form-group">
          <select style={{ height: '60%' }} 
          id="status" name="status" value={this.state.status} 
          onChange={""} className="form-control">
          <option value="Approved">Approved</option>
          <option value="Rejected">Rejected</option>
          </select>
          </div>
         </td>
         <td>
          <textarea style={{height:'50px'}}  id="feedback"  name="feedback" value={this.state.feedback} 
          onChange={this.handleChange}
         class="form-control" />
          </td>

        </tr>
      
      </TableBody>
    </Table>
    <br/>
       <div class="col" align="right">
                    <button class="btn  btn-primary  text-uppercase" type="submit">
                      Submit Review
                    </button>
                    </div>
   
   
    </div>


  );

 }// end of render
}// end of class

      
export default LeadReviewTable
