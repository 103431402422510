/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Sanjana Godbole
 *@fileName :
 *Desc: Agency GDPR Certificate Tab
 */

import React from "react";
import * as $ from "jquery";
import Footer from "../layouts/footer";
import "antd/dist/antd.css";

import {
	Form,
	Select,
	Tooltip,
	InputNumber,
	Switch,
	Radio,
	Slider,
	Button,
	Upload,
	Icon,
	Rate,
	Checkbox,
	Row,
	Col,
} from "antd";
import { bake_cookie, read_cookie, delete_cookie } from "sfcookies";
const cookie_key = "agencyID";
const Swal = require("sweetalert2");
const { Option } = Select;
var assetLength;

//var gdprAnsArray=[];
var gdrpResultArray = [];
var caslResultArray = [];
var caslDynamicArray = [];
var gdprnames = [];
var gdprnames1 = [];
var gdprnames3 = [];
var gdprnames6 = [];

var key = 0;
var inputType = [];
//   var orgID=localStorage.getItem('agencyID');

// alert(orgID);

class AgencyGDPRCertificate extends React.Component {
	state = { newsuccess: "" };

	constructor() {
		super();

		this.state = {
			selected: "gdpr-2",
			selected1: "casl-2",
			checked: true,
			checked1: false,
			checked2: false,
			checked3: false,
			displayChk1: "disabled",
			displayChk2: "disabled",
			buttonDisplay: "disabled",
			gdprquestionList: [],
			caslquestionList: [],
			complianceNameList: [],
			varGDPR: "",
			varCASL: "",

			assetsFile: [],
			asseterrors: {},
			gdpr1: {},

			// ansDetails:[{
			//   ans:'',
			//   document:''
			// }],
			gdprDynamicArray: [],
			gdrpResultArray: [],
			caslDynamicArray: [],
			caslResultArray: [],
		};

		this.handleChangeGdpr = this.handleChangeGdpr.bind(this);
		this.handleChangeCasl = this.handleChangeCasl.bind(this);
		// this.handleChangeYes =this.handleChangeYes.bind(this);
		// this.handleChangeNo =this.handleChangeNo.bind(this);
		// this.handleChangeGdprFile=this.handleChangeGdprFile.bind(this);
	}

	// componentDidMount()
	// {
	//   alert("ComponentDidmount")
	//   var pID=localStorage.getItem('pID');
	//   alert("PublisherId from Local storage====>"+pID);
	//   if(pID=="")
	//   {
	//     this.setState({
	//       displayChk1:'disabled',
	//       displayChk2:'disabled',
	//       infoMsg:'Please Fill Company Information First'
	//     });
	//   }
	//   else
	//   {
	//     this.setState({
	//       displayChk1:'',
	//       displayChk2:'',
	//       infoMsg:''
	//     });
	//   }
	// }

	/*@author Sonali Kalke
	 *@fileName :
	 *Desc: Get compliance names from backend to display on front-end
	 */
	componentDidMount() {
		let data1 = {
			compilanceValue: "GDPR",
			role:"AC"//4142 add AC role vp
		};

		//  if(e.target.checked===true && e.target.value==="GDPR"){
		fetch("/publisherOnBoardDetails/getGdprQuestions", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data1),
		})
			.then((res) => res.json())
			.then((gdprquestionList) => {
				this.setState({ gdprquestionList: gdprquestionList });
				//alert("ComponentDidMount"+JSON.stringify(gdprquestionList))
			});
		//  }
		// var agencyID=localStorage.getItem('agencyID');
		var agencyID = read_cookie(cookie_key);
		// alert("agencyID from Local storage====>"+agencyID);
		if (agencyID == "") {
			this.setState({
				buttonDisplay: "disabled",
				displayChk1: "disabled",
				displayChk2: "disabled",
				infoMsg: "Please Fill Company Information First",
			});
		} else {
			this.setState({
				displayChk1: "",
				displayChk2: "",
				buttonDisplay: "button",
				infoMsg: "",
			});
		}

		let data = {
			orgID: agencyID,
			role: "AC",
		};

		fetch("/publisherOnBoardDetails/getcomplianceName", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((complianceNameList) => {
				//alert(JSON.stringify(complianceNameList));
				this.setState({ complianceNameList: complianceNameList });

				/*@author Sanobar Golandaj
				 *@fileName :
				 *Desc: displaying compliance name on frontend
				 */
				var gdpr = complianceNameList[0].complianceName;
				this.setState({
					varGDPR: gdpr,
				});

				var casl = complianceNameList[1].complianceName;
				this.setState({
					varCASL: casl,
				});
			});
	}
	/*@author Sonali Kalke
	 *@fileName :
	 *Desc: Fetch values of radio buttons.
	 */

	/*@author Sanobar Golandaj
	 *@fileName :
	 *Desc: handle change for gdpr radio button.
	 */
	handleChangeGdprRadio(i, e) {
		//  alert(JSON.stringify(e.target))
		const { name, value } = e.target;
		let gdprDynamicArray = [...this.state.gdprDynamicArray];

		gdprDynamicArray[i] = { ...gdprDynamicArray[i], ["answer"]: value };
		this.setState({ gdprDynamicArray });

		this.setState({
			selected: e.target.value,
		});
	}

	/*@author Sanobar Golandaj
	 *@fileName :
	 *Desc: handle change for casl radio button default selected NO.
	 */
	handleChangeCaslRadio(i, e) {
		//alert("e.target.value==>"+e.target.id);
		const { name, value } = e.target;
		let caslDynamicArray = [...this.state.caslDynamicArray];
		caslDynamicArray[i] = { ...caslDynamicArray[i], ["answer"]: value };
		this.setState({ caslDynamicArray });

		this.setState({
			selected1: e.target.value,
		});
	}

	/*@author Sonali Kalke
	 *@fileName :
	 *Desc:Fetch GDPR questions from backend.
	 */

	/*@author Sanobar Golandaj
	 *@fileName :
	 *Desc:handle change for gdpr.
	 */

	handleChangeGdpr = (e) => {
		this.setState({
			checked: !this.state.checked,
		});

		// alert(e.target.checked);
		this.props.form.validateFieldsAndScroll((error, values) => {
			if (!error) {
				let data = {
					compilanceValue: e.target.value,
					role :"AC"//vrushabh patil add ac role
				};

				if (e.target.checked === true && e.target.value === "GDPR") {
					fetch("/publisherOnBoardDetails/getGdprQuestions", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((gdprquestionList) => {
							this.setState({ gdprquestionList: gdprquestionList });
						});
				}
			}
		});
	};

	/*@author Sonali Kalke
	 *@fileName :
	 *Desc:store GDPR answers to database.
	 */

	handleSubmit = (e) => {
		//var agencyID=localStorage.getItem('agencyID');
		var agencyID = read_cookie(cookie_key);
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				gdrpResultArray = Array.from(
					new Set(this.state.gdprDynamicArray.map((q) => q.qID))
				).map((qID) => {
					return {
						qID: qID,
						question: this.state.gdprDynamicArray.find((q) => q.qID === qID)
							.question,
						complianceName: this.state.gdprDynamicArray.find(
							(q) => q.qID === qID
						).complianceName,
						complianceID: this.state.gdprDynamicArray.find((q) => q.qID === qID)
							.complianceID,
						answer: this.state.gdprDynamicArray.find((q) => q.qID === qID)
							.answer,
					};
				});

				caslResultArray = Array.from(
					new Set(this.state.caslDynamicArray.map((q) => q.qID))
				).map((qID) => {
					return {
						qID: qID,
						question: this.state.caslDynamicArray.find((q) => q.qID === qID)
							.question,
						complianceName: this.state.caslDynamicArray.find(
							(q) => q.qID === qID
						).complianceName,
						complianceID: this.state.caslDynamicArray.find((q) => q.qID === qID)
							.complianceID,
						answer: this.state.caslDynamicArray.find((q) => q.qID === qID)
							.answer,
					};
				});
				//alert("pID is"+pID);
				let data = {
					gdprArray: gdrpResultArray,
					caslArray: caslResultArray,
					orgID: agencyID,
				};

				//alert("Data===>"+JSON.stringify(data));
				fetch("/publisherOnBoardDetails/gdprAnswers", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						// alert(JSON.stringify(res))
						console.warn("Response in res in==" + JSON.stringify(res));
						if (res.success == true) {
							var a = res.message;
							Swal.fire({
								type: "success",
								title: "Compliance details submitted successfully !",
							});

							// alert(a)

							// this.setState({newsuccess:a});
							this.setState({
								buttonDisplay: "disabled",
							});
						}
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				console.log(err);
			}
		});
	};

	/*@author Sanobar Golandaj
	 *@fileName :
	 *Desc:handle change for Casl.
	 */
	handleChangeCasl = (e) => {
		this.setState({
			checked1: !this.state.checked1,
		});
		this.props.form.validateFieldsAndScroll((error, values) => {
			if (!error) {
				let data = {
					compilanceValue: e.target.value,
					role: "AC"
				};

				if (e.target.checked === true && e.target.value === "CASL") {
					fetch("/publisherOnBoardDetails/getCaslQuestions", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((caslquestionList) => {
							this.setState({ caslquestionList: caslquestionList });
						});
				}
			}
		});
	};

	/*@author Sonali Kalke
	 *@fileName :
	 *Desc: upload GDPR files into database
	 */

	/*@author Sanobar Golandaj
	 *@fileName :
	 *Desc:handle change for gdpr file,displaying file list on front end.
	 */

	handleChangeGdprFile(i, e) {
		e.preventDefault();

		// var agencyID=localStorage.getItem('agencyID');
		var agencyID = read_cookie(cookie_key);

		if (i == 0) {
			//  alert("file handle changed===>"+i);
			var name = e.target.name;
			name.split("#");
			var qID = name[0];
			var complianceID = name[2];
			let gdprFilesData = e.target.files;
			for (var i = 0, l = gdprFilesData.length; i < l; i++) {
				gdprnames.push(gdprFilesData[i].name);
			}
			//  alert("Name of files on handle change===>"+gdprnames[i]);
			var link;
			var filename = "";
			var gdprarrayLength = gdprnames.length;
			var nooutputgdpr = document.getElementById("nogdprList" + qID);
			if (gdprarrayLength > 0) {
				nooutputgdpr.innerHTML = gdprarrayLength + " Files Selected";
			} else {
				nooutputgdpr.innerHTML = "0 Files Selected"; 
			}
			//  ("Total files"+JSON.stringify(gdprnames));
			var HTML = "<table>";
			for (var j = 0; j < gdprnames.length; j++) {
				// alert("Files to be displayed");
				// User Story - 3427 - Karan Jagtap - changed remove icon
				link =
					'<a class="removeGdprFile" href="#" data-fileid=' +
					'"' +
					gdprnames[j] +
					'"' +
					'><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
				HTML +=
					"<tr><td style='display:flex;align-items:center;'>" +
					link +
					gdprnames[j] +
					"</td></tr>";
			}
			HTML += "</table>";
			document.getElementById("gdprList" + qID).innerHTML = HTML;
			$(document).on("click", ".removeGdprFile", function (e) {
				// alert("Inside removeGdprFile");
				e.preventDefault();
				var clickedFile = $(this).closest("a").data("fileid");
				var j = 0;
				for (j = 0; j < gdprnames.length; j++) {
					var temp = j;
					//alert("After Click====>"+j+"<===>"+clickedFile);
					if (gdprnames[temp] === clickedFile) {
						filename = gdprnames[temp];
						// alert("Deleted File:"+filename+"@"+temp);
						gdprnames.splice(j, 1);
						$(this).parent().remove();
						gdprarrayLength = gdprnames.length;
						/**
						 * @author Narendra Phadke
						 * @param Delete File From Database
						 */
						var typeOfSuppDoc = "Gdpr";
						let data = {
							orgID: agencyID,
							qID: qID,
							names: filename,
							complianceID: complianceID,
							//typeOfSuppDoc:typeOfSuppDoc
						};
						/*@author Sonali Kalke
						 *@fileName :
						 *Desc:delete files from database.
						 */
						fetch("publisherOnBoardDetails/gdprDocumentDeleteFile", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							//mode:'no-cors',
							//body: data
							body: JSON.stringify(data),
						});
						document.getElementById("gdprfile" + qID).value = "";
						if (gdprarrayLength > 0) {
							nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
						} else {
							nooutputgdpr.innerHTML = "0 File Selected";
							$("#gdpr").val("");
							var message = document.getElementById("gdprMessage" + qID);
							message.innerHTML = "";
						}
					} // end of if(names[temp] === clickedFile)
				} // end of for
			}); //$(document).on
			var data = new FormData();
			data.append("qID", qID);
			data.append("orgID", agencyID);
			data.append("complianceID", complianceID);
			for (var i = 0, l = gdprFilesData.length; i < l; i++) {
				data.append("file", gdprFilesData[i]);
			}
			console.warn("newData", data);
			//alert(gdprFilesData[0]);
			//campaign/supportingDocument
			fetch("/publisherOnBoardDetails/supportingDocumentGdpr", {
				method: "POST",
				body: data,
			})
				.then(function (response) {
					if (response.status >= 400) {
						throw new Error("Bad response from server");
					}
					return response.json();
				})
				.then(function (response) {
					console.log("Return with response");
				})
				.catch(function (err) {
					console.log(err);
				});
			this.setState({
				files: data,
			});
			var msg = "Please click on submit button to save data";
			var message = document.getElementById("gdprMessage" + qID);
			var number = document.getElementById("gdprMessage" + qID).innerHTML;
			if (number == "") {
				message.innerHTML += msg;
			}
		} else if (i == 1) {
			//alert("file handle changed===>"+i);
			var name = e.target.name;
			name.split("#");
			var qID = name[0];
			var complianceID = name[2];
			let gdprFilesData = e.target.files;
			for (var i = 0, l = gdprFilesData.length; i < l; i++) {
				gdprnames1.push(gdprFilesData[i].name);
			}
			//  alert("Name of files on handle change===>"+gdprnames1[i]);
			var link;
			var filename = "";
			var gdprarrayLength = gdprnames1.length;
			var nooutputgdpr = document.getElementById("nogdprList" + qID);
			if (gdprarrayLength > 0) {
				nooutputgdpr.innerHTML = gdprarrayLength + " Files Selected";
			} else {
				nooutputgdpr.innerHTML = "0 Files Selected";
			}
			//  ("Total files"+JSON.stringify(gdprnames1));
			var HTML = "<table>";
			for (var j = 0; j < gdprnames1.length; j++) {
				// alert("Files to be displayed");
				// User Story - 3427 - Karan Jagtap - changed remove icon
				link =
					'<a class="removeGdprFile" href="#" data-fileid=' +
					'"' +
					gdprnames1[j] +
					'"' +
					'><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
				HTML +=
					"<tr><td style='display:flex;align-items:center;'>" +
					link +
					gdprnames1[j] +
					"</td></tr>";
			}
			HTML += "</table>";
			document.getElementById("gdprList" + qID).innerHTML = HTML;
			$(document).on("click", ".removeGdprFile", function (e) {
				// alert("Inside removeGdprFile");
				e.preventDefault();
				var clickedFile = $(this).closest("a").data("fileid");
				var j = 0;
				for (j = 0; j < gdprnames1.length; j++) {
					var temp = j;
					//alert("After Click====>"+j+"<===>"+clickedFile);
					if (gdprnames1[temp] === clickedFile) {
						filename = gdprnames1[temp];
						// alert("Deleted File:"+filename+"@"+temp);
						gdprnames1.splice(j, 1);
						$(this).parent().remove();
						gdprarrayLength = gdprnames1.length;
						/**
						 * @author Narendra Phadke
						 * @param Delete File From Database
						 */
						var typeOfSuppDoc = "Gdpr";
						let data = {
							orgID: agencyID,
							qID: qID,
							names: filename,
							complianceID: complianceID,
							//typeOfSuppDoc:typeOfSuppDoc
						};
						/*@author Sonali Kalke
						 *@fileName :
						 *Desc:delete files from database.
						 */
						fetch("publisherOnBoardDetails/gdprDocumentDeleteFile", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							//mode:'no-cors',
							//body: data
							body: JSON.stringify(data),
						});
						document.getElementById("gdprfile" + qID).value = "";
						if (gdprarrayLength > 0) {
							nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
						} else {
							nooutputgdpr.innerHTML = "0 File Selected";
							$("#gdpr").val("");
							var message = document.getElementById("gdprMessage" + qID);
							message.innerHTML = "";
						}
					} // end of if(names[temp] === clickedFile)
				} // end of for
			}); //$(document).on
			var data = new FormData();
			data.append("qID", qID);
			data.append("orgID", agencyID);
			data.append("complianceID", complianceID);
			for (var i = 0, l = gdprFilesData.length; i < l; i++) {
				data.append("file", gdprFilesData[i]);
			}
			console.warn("newData", data);
			//campaign/supportingDocument
			fetch("publisherOnBoardDetails/supportingDocumentGdpr", {
				method: "POST",
				body: data,
			})
				.then(function (response) {
					if (response.status >= 400) {
						throw new Error("Bad response from server");
					}
					return response.json();
				})
				.then(function (response) {
					console.log("Return with response");
				})
				.catch(function (err) {
					console.log(err);
				});
			this.setState({
				files: data,
			});
			var msg = "Please click on submit button to save data";
			var message = document.getElementById("gdprMessage" + qID);
			var number = document.getElementById("gdprMessage" + qID).innerHTML;
			if (number == "") {
				message.innerHTML += msg;
			}
		} else if (i == 3) {
			//   alert("file handle changed===>"+i);
			var name = e.target.name;
			name.split("#");
			var qID = name[0];
			var complianceID = name[2];
			let gdprFilesData = e.target.files;
			for (var i = 0, l = gdprFilesData.length; i < l; i++) {
				gdprnames3.push(gdprFilesData[i].name);
			}
			//  alert("Name of files on handle change===>"+gdprnames3[i]);
			var link;
			var filename = "";
			var gdprarrayLength = gdprnames3.length;
			var nooutputgdpr = document.getElementById("nogdprList" + qID);
			if (gdprarrayLength > 0) {
				nooutputgdpr.innerHTML = gdprarrayLength + " Files Selected";
			} else {
				nooutputgdpr.innerHTML = "0 Files Selected";
			}
			//  ("Total files"+JSON.stringify(gdprnames3));
			var HTML = "<table>";
			for (var j = 0; j < gdprnames3.length; j++) {
				// alert("Files to be displayed");
				// User Story - 3427 - Karan Jagtap - changed remove icon
				link =
					'<a class="removeGdprFile" href="#" data-fileid=' +
					'"' +
					gdprnames3[j] +
					'"' +
					'><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
				HTML +=
					"<tr><td style='display:flex;align-items:center;'>" +
					link +
					gdprnames3[j] +
					"</td></tr>";
			}
			HTML += "</table>";
			document.getElementById("gdprList" + qID).innerHTML = HTML;
			$(document).on("click", ".removeGdprFile", function (e) {
				// alert("Inside removeGdprFile");
				e.preventDefault();
				var clickedFile = $(this).closest("a").data("fileid");
				var j = 0;
				for (j = 0; j < gdprnames3.length; j++) {
					var temp = j;
					//alert("After Click====>"+j+"<===>"+clickedFile);
					if (gdprnames3[temp] === clickedFile) {
						filename = gdprnames3[temp];
						// alert("Deleted File:"+filename+"@"+temp);
						gdprnames3.splice(j, 1);
						$(this).parent().remove();
						gdprarrayLength = gdprnames3.length;
						/**
						 * @author Narendra Phadke
						 * @param Delete File From Database
						 */
						var typeOfSuppDoc = "Gdpr";
						let data = {
							orgID: agencyID,
							qID: qID,
							names: filename,
							complianceID: complianceID,
							//typeOfSuppDoc:typeOfSuppDoc
						};
						/*@author Sonali Kalke
						 *@fileName :
						 *Desc:delete files from database.
						 */
						fetch("publisherOnBoardDetails/gdprDocumentDeleteFile", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							//mode:'no-cors',
							//body: data
							body: JSON.stringify(data),
						});
						document.getElementById("gdprfile" + qID).value = "";
						if (gdprarrayLength > 0) {
							nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
						} else {
							nooutputgdpr.innerHTML = "0 File Selected";
							$("#gdpr").val("");
							var message = document.getElementById("gdprMessage" + qID);
							message.innerHTML = "";
						}
					} // end of if(names[temp] === clickedFile)
				} // end of for
			}); //$(document).on
			var data = new FormData();
			data.append("qID", qID);
			data.append("orgID", agencyID);
			data.append("complianceID", complianceID);
			for (var i = 0, l = gdprFilesData.length; i < l; i++) {
				data.append("file", gdprFilesData[i]);
			}
			console.warn("newData", data);
			//campaign/supportingDocument
			fetch("publisherOnBoardDetails/supportingDocumentGdpr", {
				method: "POST",
				body: data,
			})
				.then(function (response) {
					if (response.status >= 400) {
						throw new Error("Bad response from server");
					}
					return response.json();
				})
				.then(function (response) {
					console.log("Return with response");
				})
				.catch(function (err) {
					console.log(err);
				});
			this.setState({
				files: data,
			});
			var msg = "Please click on submit button to save data";
			var message = document.getElementById("gdprMessage" + qID);
			var number = document.getElementById("gdprMessage" + qID).innerHTML;
			if (number == "") {
				message.innerHTML += msg;
			}
		} else if (i == 6) {
			// alert("file handle changed===>"+i);
			var name = e.target.name;
			name.split("#");
			var qID = name[0];
			var complianceID = name[2];
			let gdprFilesData = e.target.files;
			for (var i = 0, l = gdprFilesData.length; i < l; i++) {
				gdprnames6.push(gdprFilesData[i].name);
			}
			//  alert("Name of files on handle change===>"+gdprnames6[i]);
			var link;
			var filename = "";
			var gdprarrayLength = gdprnames6.length;
			var nooutputgdpr = document.getElementById("nogdprList" + qID);
			if (gdprarrayLength > 0) {
				nooutputgdpr.innerHTML = gdprarrayLength + " Files Selected";
			} else {
				nooutputgdpr.innerHTML = "0 Files Selected";
			}
			//  ("Total files"+JSON.stringify(gdprnames6));
			var HTML = "<table>";
			for (var j = 0; j < gdprnames6.length; j++) {
				// alert("Files to be displayed");
				// User Story - 3427 - Karan Jagtap - changed remove icon
				link =
					'<a class="removeGdprFile" href="#" data-fileid=' +
					'"' +
					gdprnames6[j] +
					'"' +
					'><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
				HTML +=
					"<tr><td style='display:flex;align-items:center;'>" +
					link +
					gdprnames6[j] +
					"</td></tr>";
			}
			HTML += "</table>";
			document.getElementById("gdprList" + qID).innerHTML = HTML;
			$(document).on("click", ".removeGdprFile", function (e) {
				// alert("Inside removeGdprFile");
				e.preventDefault();
				var clickedFile = $(this).closest("a").data("fileid");
				var j = 0;
				for (j = 0; j < gdprnames6.length; j++) {
					var temp = j;
					//alert("After Click====>"+j+"<===>"+clickedFile);
					if (gdprnames6[temp] === clickedFile) {
						filename = gdprnames6[temp];
						// alert("Deleted File:"+filename+"@"+temp);
						gdprnames6.splice(j, 1);
						$(this).parent().remove();
						gdprarrayLength = gdprnames6.length;
						/**
						 * @author Narendra Phadke
						 * @param Delete File From Database
						 */
						var typeOfSuppDoc = "Gdpr";
						let data = {
							orgID: agencyID,
							qID: qID,
							names: filename,
							complianceID: complianceID,
							//typeOfSuppDoc:typeOfSuppDoc
						};
						/*@author Sonali Kalke
						 *@fileName :
						 *Desc:delete files from database.
						 */
						fetch("publisherOnBoardDetails/gdprDocumentDeleteFile", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							//mode:'no-cors',
							//body: data
							body: JSON.stringify(data),
						});
						document.getElementById("gdprfile" + qID).value = "";
						if (gdprarrayLength > 0) {
							nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
						} else {
							nooutputgdpr.innerHTML = "0 File Selected";
							$("#gdpr").val("");
							var message = document.getElementById("gdprMessage" + qID);
							message.innerHTML = "";
						}
					} // end of if(names[temp] === clickedFile)
				} // end of for
			}); //$(document).on
			var data = new FormData();
			data.append("qID", qID);
			data.append("orgID", agencyID);
			data.append("complianceID", complianceID);
			for (var i = 0, l = gdprFilesData.length; i < l; i++) {
				data.append("file", gdprFilesData[i]);
			}
			console.warn("newData", data);
			//campaign/supportingDocument
			fetch("publisherOnBoardDetails/supportingDocumentGdpr", {
				method: "POST", 
				body: data,
			})
				.then(function (response) {
					if (response.status >= 400) {
						throw new Error("Bad response from server");
					}
					return response.json();
				})
				.then(function (response) {
					console.log("Return with response");
				})
				.catch(function (err) {
					console.log(err);
				});
			this.setState({
				files: data,
			});
			var msg = "Please click on submit button to save data";
			var message = document.getElementById("gdprMessage" + qID);
			var number = document.getElementById("gdprMessage" + qID).innerHTML;
			if (number == "") {
				message.innerHTML += msg;
			}
		}
	} // end of handleChangeGdprFile(i,e)

	render() {
		var agencyID = read_cookie(cookie_key);
		// alert("agencyID from Local storage====>"+agencyID);
		if (agencyID == "") {
			this.state.buttonDisplay = "disabled";
			this.state.displayChk1 = "disabled";
			this.state.displayChk2 = "disabled";
			this.state.infoMsg = "Please Fill Company Information First";
		} else {
			this.state.buttonDisplay = "";
			this.state.displayChk1 = "";
			this.state.displayChk2 = "";
			this.state.infoMsg = "";
		}
		const { getFieldDecorator } = this.props.form;
		const formItemLayout = {
			labelCol: { span: 6 },
			wrapperCol: { span: 14 },
		};

		this.state.gdprquestionList.forEach((itm, i) => {
			this.state.gdprDynamicArray.push(Object.assign({}, itm));
		});

		this.state.caslquestionList.forEach((itm, i) => {
			this.state.caslDynamicArray.push(Object.assign({}, itm));
		});

		//console.log("Dynamic array is:"+JSON.stringify(this.state.gdprDynamicArray));
		/*@author Sanobar Golandaj
		 *@fileName :
		 *Desc:display table for Gdpr on gdpr handle change
		 */
		const content = this.state.checked ? (
			<div class="table-responsive-lg">
				<table id="myTable" className=" table table-bordered table-striped">
					<thead>
						<tr style={{ backgroundColor: "#909394", color: "white" }}>
							<th>GDPR Requirement Questionnaire:</th>
							<th>Details</th>
							<th>Associated Document</th>
						</tr>
					</thead>
					<tbody>
						{this.state.gdprquestionList.map((gdprquestionList, i) => {
							if ((this.state.gdprquestionList[0].complianceName = "true")) {
								return (
									<tr>
										<td>
											<label class="word-wrap">
												{gdprquestionList.questions}
												{gdprquestionList.qID == 3 ? (
													<Tooltip title="Opt-In-process">
														<Icon type="question-circle-o" />
													</Tooltip>
												) : (
													" "
												)}

												{gdprquestionList.qID == 4 ? (
													<Tooltip title="Provide the link of privacy policy">
														<Icon type="question-circle-o" />
													</Tooltip>
												) : (
													" "
												)}

												{gdprquestionList.qID == 7 ? (
													<Tooltip title="Training types and frequency">
														<Icon type="question-circle-o" />
													</Tooltip>
												) : (
													" "
												)}

												{gdprquestionList.qID == 8 ? (
													<Tooltip title="Email id of DPO">
														<Icon type="question-circle-o" />
													</Tooltip>
												) : (
													" "
												)}
											</label>
										</td>

										<td>
											<Radio.Group>
												<Radio
													value="Yes"
													id="gdpr-1"
													checked={this.state.selected === "gdpr-1"}
													onChange={this.handleChangeGdprRadio.bind(this, i)}>
													Yes
												</Radio>

												<Radio
													value="No"
													id="gdpr-2"
													checked={this.state.selected === "gdpr-2"}
													onChange={this.handleChangeGdprRadio.bind(this, i)}>
													No
												</Radio>
											</Radio.Group>
										</td>

										{/* <td>{(this.state.selected === 'gdpr-1')?"hell":"234"}
              </td> */}

										{/* {gdprquestionList.qID} */}
										<td style={{ width: "380px" }}>
											{gdprquestionList.suppDoc == "Yes" ? (
												<div>
													<input
														type="file"
														style={{ color: "transparent" }}
														id={"gdprfile" + gdprquestionList.qID}
														name={
															gdprquestionList.qID +
															"#" +
															gdprquestionList.complianceID
														}
														onChange={this.handleChangeGdprFile.bind(this, i)}
														multiple></input>
													<div>
														<div id={"nogdprList" + gdprquestionList.qID}>
															0 file Selected
														</div>
														<div
															id={"gdprList" + gdprquestionList.qID}
															className="word-wrap"></div>

														<div
															id={"gdprMessage" + gdprquestionList.qID}
															style={{ color: "green" }}></div>
														{/* <div id="gdprMessage"></div> */}
													</div>
												</div>
											) : (
												" "
											)}
										</td>
									</tr>
								);
							}
						})}
					</tbody>
				</table>
			</div>
		) : null;

		/*@author Sanobar Golandaj
		 *@fileName :
		 *Desc:display table for Casl on casl handle change
		 */
		const content1 = this.state.checked1 ? (
			<div class="table-responsive-lg">
				<table id="myTable" className=" table table-bordered table-striped">
					<thead>
						<tr style={{ backgroundColor: "#909394", color: "white" }}>
							<th>CASL Requirement Questioanire:</th>
							<th style={{ width: "425px" }}>Details</th>
							{/* <th>browse</th> */}
						</tr>
					</thead>
					<tbody>
						{this.state.caslquestionList.map((caslquestionList, i) => {
							if ((this.state.caslquestionList[0].complianceName = "true")) {
								return (
									<tr>
										<td>
											<label class="word-wrap">
												{/*caslquestionList.questions*/} 
												{unescape(caslquestionList.questions)/*Patil-task-4142 remove %20 from UI string */}
											</label>
										</td>
										<td>
											<Radio.Group>
												<Radio
													value="Yes"
													id="casl-1"
													checked={this.state.selected1 === "casl-1"}
													onChange={this.handleChangeCaslRadio.bind(this, i)}>
													Yes
												</Radio>
												<Radio
													value="No"
													id="casl-2"
													checked={this.state.selected1 === "casl-2"}
													onChange={this.handleChangeCaslRadio.bind(this, i)}>
													No
												</Radio>
											</Radio.Group>
										</td>
									</tr>
								);
							}
						})}
					</tbody>
				</table>
			</div>
		) : null;

		return (
			<div>
				<div class="container-fluid" style={{ paddingBottom: "60px" }}>
					<div
						style={{ fontSize: "22px", color: "green", paddingLeft: "490px" }}>
						{this.state.newsuccess}
					</div>
					<Form {...formItemLayout} onSubmit={this.handleSubmit}>
						<Form.Item label="Compliance">
							{getFieldDecorator("checkbox-group", {
								initialValue: ["A", "B"],
							})(
								<Row>
									{this.state.complianceNameList.map((complianceNameList) => {
										if (complianceNameList.complianceName == "GDPR") {
											return (
												<Col span={8}>
													<Checkbox
														value={this.state.varGDPR}
														name="GDPR"
														//disabled={this.state.displayChk1} Comment this line to enable CASL CheckBox- task-4142-musbir
														checked={this.state.checked}
														onChange={this.handleChangeGdpr}>
														{complianceNameList.complianceName}
													</Checkbox>
												</Col>
											);
										}
									})}

									{this.state.complianceNameList.map((complianceNameList) => {
										if (complianceNameList.complianceName == "CASL") {
											return (
												<Col span={8}>
													<Checkbox
														value={this.state.varCASL}
														name="CASl" 
														//disabled={this.state.displayChk2} Comment this line to enable CASL CheckBox- task-4142-musbir
														checked1={this.state.checked1}
														onChange={this.handleChangeCasl}>
														{complianceNameList.complianceName}
													</Checkbox>
												</Col>
											);
										}
									})}
								</Row>
							)}
						</Form.Item>

						{content}
						{content1}

						<Form.Item wrapperCol={{ span:12, offset: 6 }} style={{float: "right", paddingRight: "20px"}/*Task-4403-Vrushabh-- Changesadded-float paddingRight: "20px"*/ }>
							<Button 
								type="primary"
								htmlType="submit"
								className={this.state.buttonDisplay}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</div>
				<Footer />
			</div>
		);
	}
}

const agencyRegistrationForm3 = Form.create({ name: "register" })(
	AgencyGDPRCertificate
);

export default agencyRegistrationForm3;
