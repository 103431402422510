/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Raunak Thakkar
 *@fileName :RequestForProposal.js
 *Desc: New UI for Request For Proposal
 */
import React from "react";
import { Icon, Tooltip, Checkbox, DatePicker, Radio } from "antd";
//import * as $ from "jquery"; //rutuja 4832 commented as never used in the file
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import { Input } from "antd";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer"; //sandeep-task-3127-added code to import Footer
import "antd/dist/antd.css";
import moment from "moment";
import "./requestForProposal.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//Sandeep-task-3388-import styled component
import styled from "styled-components";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft, FaSearch } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress"; // User Story - 3411 - Karan Jagtap
import Loader from "../campaignUI/Loader";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3767-added default header

//Sandeep-task-3313-added code to get countryRegion array of data on respective page
const BeneluxOptions =
	require("../campaignCreationFile/countryRegionArray").BeneluxOptions;
const DACHOptions =
	require("../campaignCreationFile/countryRegionArray").DACHOptions;
const NordicOptions =
	require("../campaignCreationFile/countryRegionArray").NordicOptions;
const APACOptions =
	require("../campaignCreationFile/countryRegionArray").APACOptions;
const NorthernAfricaCountriesOptions =
	require("../campaignCreationFile/countryRegionArray").NorthernAfricaCountriesOptions;
const ANZOptions =
	require("../campaignCreationFile/countryRegionArray").ANZOptions;
const CentralOrMiddleAfricanOptions =
	require("../campaignCreationFile/countryRegionArray").CentralOrMiddleAfricanOptions;
const SouthernAfricaCountriesOptions =
	require("../campaignCreationFile/countryRegionArray").SouthernAfricaCountriesOptions;
const EastAfricanCountriesOptions =
	require("../campaignCreationFile/countryRegionArray").EastAfricanCountriesOptions;
const WesternAfricaOptions =
	require("../campaignCreationFile/countryRegionArray").WesternAfricaOptions;
const MiddleEastOptions =
	require("../campaignCreationFile/countryRegionArray").MiddleEastOptions;
const EasternEuropeOptions =
	require("../campaignCreationFile/countryRegionArray").EasternEuropeOptions;
const NorthernEuropeOptions =
	require("../campaignCreationFile/countryRegionArray").NorthernEuropeOptions;
const SouthernEuropeOptions =
	require("../campaignCreationFile/countryRegionArray").SouthernEuropeOptions;
const WesternEuropeOptions =
	require("../campaignCreationFile/countryRegionArray").WesternEuropeOptions;
const CentralAsiaOptions =
	require("../campaignCreationFile/countryRegionArray").CentralAsiaOptions;
const EastAsiaOptions =
	require("../campaignCreationFile/countryRegionArray").EastAsiaOptions;
const SouthAsiaOptions =
	require("../campaignCreationFile/countryRegionArray").SouthAsiaOptions;
const SoutheastAsiaOptions =
	require("../campaignCreationFile/countryRegionArray").SoutheastAsiaOptions;
const WesternAsiaOptions =
	require("../campaignCreationFile/countryRegionArray").WesternAsiaOptions;
const SouthAmericaOptions =
	require("../campaignCreationFile/countryRegionArray").SouthAmericaOptions;
const AntarcticOptions =
	require("../campaignCreationFile/countryRegionArray").AntarcticOptions;
const NorthAmericaOptions =
	require("../campaignCreationFile/countryRegionArray").NorthAmericaOptions;
const MelanesiaOptions =
	require("../campaignCreationFile/countryRegionArray").MelanesiaOptions;
const PolynesiaOptions =
	require("../campaignCreationFile/countryRegionArray").PolynesiaOptions;
const MicronesiaOptions =
	require("../campaignCreationFile/countryRegionArray").MicronesiaOptions;
const SouthernAtlanticOceanOptions =
	require("../campaignCreationFile/countryRegionArray").SouthernAtlanticOceanOptions;
const RegionOption =
	require("../campaignCreationFile/countryRegionArray").RegionOption;

// import moment from 'moment';
// const queryString = require("query-string"); //rutuja 4832 commented queryString as never used in the file
//Sandeep-task-3388-added code for tootltip
const HtmlTooltip = styled(Tooltip)`
  backgroundColor: '#f5f5f9',
  color: 'rgba(0, 0, 0, 0.87)',
  maxWidth: 220,
  fontSize: '12px',
  border: '1px solid #dadde9',
  `;
const Swal = require("sweetalert2");
const CheckboxGroup = Checkbox.Group;
var abmnames = {};
var exclnames = {};
var supprnames = {};
let docNumber = [];
let fileDetailsForABM = [];
let fileDetailsForSuppression = [];
let fileDetailsForExclusion = [];
let fileDetailsForOther = [];
let duplicateABMFile = []; // this is used for check duplicate file name
let	duplicateSuppressionFile = [];
let	duplicateExclusionFile = [];
let	duplicateOtherFile = [];
/*  // rutuja 4832 commented below variable as never used in the file
var employeeSizecount = 3;
var count = 3;
var companyRevenuecount = 3;
var employeeSizeTextCount = 1;
var companyRevenueTextCount = 1;
var newabmfilenames = [];
var suppressionnames = [];
var newsuppressionfilenames = [];
var execlusionnames = [];
var newexclusionfilenames = [];
var newexeclusionfilenames = [];
var alreadyExist = [];
var othernames = [];
var newotherfilenames = [];
var key = 0;
let chkABMExist = [];

let chkExclusionExist = [];

let chkSuppressionExist = [];

let chkOtherExist = [];

var linkSuppCount = 0;
var oldABMFileArray = [];
var assetValidationArray = []; // store the rejected asset name in this array which come from Backend for validte true or false
var exclusionValidationArray = []; // store the rejected Exclusion name in this array which come from Backend for validte true or false
var otherValidationArray = [];
var successOtherCount = [],
	rejectOtherCount = [];
var successExclusionCount = [];
var rejectExclusionCount = [];
var successAbmCount = [];
var rejectAbmCount = [];
var successSuppressionCount = [];
var rejectSuppressionCount = [];
var suppValidationArray = [];*/ 


//snehal-task-3139-Industry- Add All/Any option in industry drop down- Create/Edit/Back/ Lead Validation(added All option)
const industryOptions = [
	{ id: "Any", name: "Any" },
	{
		id: "Accommodation and Food Services",
		name: "Accommodation and Food Services",
	},
	{
		id: "Administrative and Support and Waste Management and Remediation Services",
		name: "Administrative and Support and Waste Management and Remediation Services",
	},
	{
		id: "Advertising and Printing & Publishing",
		name: "Advertising and Printing & Publishing",
	},
	{ id: "Aerospace & Defense", name: "Aerospace & Defense" },
	{ id: "Accounting", name: "Accounting" },
	{
		id: "Agriculture and Forestry and Fishing and Hunting",
		name: "Agriculture and Forestry and Fishing and Hunting",
	},
	{ id: "Apparel & Fashion", name: "Apparel & Fashion" },
	{
		id: "Arts and Entertainment and and Recreation",
		name: "Arts and Entertainment and and Recreation",
	},
	{ id: "Automotive", name: "Automotive" },
	{ id: "Business Services", name: "Business Services" },
	{ id: "Construction", name: "Construction" },
	{ id: "Engineering and Construction", name: "Engineering and Construction" },
	{ id: "Education & Training", name: "Education & Training" },
	{ id: "Energy & Utilities", name: "Energy & Utilities" },
	{ id: "Financial Services & Banking", name: "Financial Services & Banking" },
	// { id: 'Finance and Insurance', name: 'Finance and Insurance' },
	{ id: "Food & Beverage", name: "Food & Beverage" },
	{
		id: "Government and Public Administration",
		name: "Government and Public Administration",
	},
	// { id: 'Health Care and Social Assistance', name: 'Health Care and Social Assistance' },
	{ id: "Healthcare", name: "Healthcare" },
	{ id: "Hospitality & Travel", name: "Hospitality & Travel" },
	// { id: 'Information Technology & Services', name: 'Information Technology & Services'},
	{ id: "Information", name: "Information" },
	{ id: "Insurance", name: "Insurance" },
	{ id: "Legal Solutions", name: "Legal Solutions" },
	{
		id: "Management of Companies and Enterprises",
		name: "Management of Companies and Enterprises",
	},
	{ id: "Manufacturing", name: "Manufacturing" },
	{ id: "Marketing", name: "Marketing" },
	{ id: "Media & Entertainment", name: "Media & Entertainment" },
	{ id: "Medical Devices & Equipment", name: "Medical Devices & Equipment" },
	{ id: "Mining", name: "Mining" },
	// { id: 'Mining, Quarrying, and Oil and Gas Extraction', name: 'Mining, Quarrying, and Oil and Gas Extraction' },
	{ id: "Not-for-Profit", name: "Not-for-Profit" },
	{ id: "Pharmaceuticals & Biotech", name: "Pharmaceuticals & Biotech" },
	{
		id: "Professional and Scientific & Technical Services",
		name: "Professional and Scientific & Technical Services",
	},
	{ id: "Real Estate", name: "Real Estate" },
	// { id: 'Real Estate and Rental and Leasing', name: 'Real Estate and Rental and Leasing' },
	{ id: "Recreation", name: "Recreation" },
	// { id: 'Retail Trade', name: 'Retail Trade' },
	{ id: "Retail", name: "Retail" },
	{ id: "Software", name: "Software" },
	{ id: "Telecommunications", name: "Telecommunications" },
	{ id: "Transportation & Logistics", name: "Transportation & Logistics" },
	// { id: 'Transportation and Warehousing', name: 'Transportation and Warehousing' },
	{ id: "Utilities", name: "Utilities" },
	{
		id: "Venture Capital & Private Equity",
		name: "Venture Capital & Private Equity",
	},
	{ id: "Wholesale & Distribution", name: "Wholesale & Distribution" },
	// { id: 'Wholesale Trade', name: 'Wholesale Trade' },
	{ id: "Consumer Goods & Services", name: "Consumer Goods & Services" },
	{
		id: "Scientific & Technical Service",
		name: "Scientific & Technical Service",
	},
	{ id: "Other", name: "Other" },
];
const jobLevelOptions = [
	{ id: "C-Level", name: "C-Level" },
	{ id: "VP", name: "VP" },
	{ id: "Director", name: "Director" },
	{ id: "Head", name: "Head" },
	{ id: "Manager", name: "Manager" },
	{ id: "Staff", name: "Staff" },
];
const jobFunctionOptions = [
	{ id: "Business", name: "Business" },
	{ id: "Chain Supply", name: "Chain Supply" },
	{ id: "Customer", name: "Customer" },
	{ id: "Executive", name: "Executive" },
	{ id: "Finance & Admin", name: "Finance & Admin" },
	{ id: "HR", name: "HR" },
	{ id: "IT", name: "IT" },
	{ id: "IT Security", name: "IT Security" },
	{ id: "Legal", name: "Legal" },
	{ id: "Marketing", name: "Marketing" },
	{ id: "Network", name: "Network" },
	{ id: "Operation", name: "Operation" },
	{ id: "Other", name: "Other" },
	{ id: "Procurement", name: "Procurement" },
	{ id: "Product", name: "Product" },
	{ id: "Sales", name: "Sales" },
	{ id: "Security & Compliance", name: "Security & Compliance" },
	{ id: "Support", name: "Support" },
];
const companyRevenueOption = [
	{ id: "<$1M", name: "<$1M" },
	{ id: "$1M - $5M", name: "$1M - $5M" },
	{ id: "$5M - $10M", name: "$5M - $10M" },
	{ id: "$10M - $25M", name: "$10M - $25M" },
	{ id: "$25M - $50M", name: "$25M - $50M" },
	{ id: "$50M - $100M", name: "$50M - $100M" },
	{ id: "$100M - $250M", name: "$100M - $250M" },
	{ id: "$250M - $500M", name: "$250M - $500M" },
	{ id: "$500M - $1B", name: "$500M - $1B" },
	{ id: "$1B - $5B", name: "$1B - $5B" },
	{ id: "$5B - $10B", name: "$5B - $10B" },
	{ id: ">$10B", name: ">$10B" },
];
const employeeSizeOption = [
	{ id: "1-50", name: "1-50" },
	{ id: "51-100", name: "51-100" },
	{ id: "101-200", name: "101-200" },
	{ id: "201-500", name: "201-500" },
	{ id: "501-1000", name: "501-1000" },
	{ id: "1001-5000", name: "1001-5000" },
	{ id: "5001-10000", name: "5001-10000" },
	{ id: "10001+", name: "10001+" },
];

class RequestForProposal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
			ratecardcplErrMsg3: "none",
			ratecardcplErrMsg2: "none",
			ratecardcplErrMsg: "none",
			netcplErrMsg: "none",
			netcplErrMsg2: "none",
			netcplErrMsg3: "none",
			netcplErrMsg4: "none",
			rfpId: "",
			agencyID: "",
			rfpCampaignID: "",
			status: "", //snehal-task-3509-Project's(DI) - Create RFP through XLS - FE
			abmerror: {},
			exclusionerror: {},
			suppressionerror: {},
			industry: [],
			industryDB: [],
			jobLevel: [],
			jobLevelDB: [],
			jobFunction: [],
			jobFunctionDB: [],
			companyRevenue: [],
			customJobLevel: [{ id: "0", value: "" }], //sunita-task-3246-added by default values for job level as one default textbox will shown.
			customJobFunction: [{ id: "0", value: "" }], //sunita-task-3246-added by default values for job function as one default textbox will shown.
			companyRevenuetofrom: [{ companyRevenueID: "1", to: "", from: "" }], //sunita-task-3246-added by default values for customrevenutofrom as one default textbox will shown.
			//customCompanyRevenueText: [], //rutuja 2832 commenting customCompanyRevenueText as declared below
			employeeSizetofrom: [{ employeeSizeID: "1", to: "", from: "" }],
			//customEmployeeSizeText: [], //rutuja 2832 commenting customEmployeeSizeText as declared below
			BasicDetailArray: [
				{
					biddingType: "Geowise",
					clientName:
						"" /* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE */,
					durationType: "",
					duration: "0",
					campaignType: "Open",
					campaignName: "",
					startDate: "",
					endDate: "",
					biddingEndDate: "",
					leadAllocation: "",
					netCPL: "0",
					CPL: "",
					rateCardCPL: "0",
					currency: "USD",
					marketingChannel: "Email",
					jobTitle: "",
					otherSpecs: "",
					noOfCustomQuestions: "",
					tal: "", //Sandeep-task-3518-added code for tal
					publication: "", //Sandeep-task-3518-added code for publication
					audienceMatch: "", //Sandeep-task-3518-added code for audienceMatch
					audienceReach: "Yes", //Sandeep -task-3808-Demo-RFP- Create-Audience Match & Audience Reach
				},
			],
			employeeSize: [],
			customEmployeeSizeText: [
				{ employeeSizeCustomID: "0", customEmployeeSizeText: "" },
			],
			
			customCompanyRevenueText: [
				{ companyRevenueCustomID: "0", customCompanyRevenueText: "" },
			],
			region: [],
			RegioncheckedList: "",

			BeneluxcheckedList: [],
			DACHcheckedList: [],
			NordiccheckedList: [],
			NorthernAfricaCountriescheckedList: [],
			CentralOrMiddleAfricancheckedList: [],
			SouthernAfricaCountriescheckedList: [],
			EastAfricanCountriescheckedList: [],
			WesternAfricacheckedList: [],
			middleEastCheckedList: [],
			EasternEuropecheckedList: [],
			NorthernEuropecheckedList: [],
			SouthernEuropecheckedList: [],
			WesternEuropecheckedList: [],
			CentralAsiacheckedList: [],
			EastAsiacheckedList: [],
			SouthAsiacheckedList: [],
			SoutheastAsiacheckedList: [],
			WesternAsiacheckedList: [],
			SouthAmericacheckedList: [],
			AntarcticcheckedList: [],
			NorthAmericacheckedList: [],
			AustraliaAndNewZealandcheckedList: [],
			MelanesiacheckedList: [],
			PolynesiacheckedList: [],
			MicronesiacheckedList: [],
			SouthernAtlanticOceancheckedList: [],
			docNumber: [],
			fileDetailsForABM: [],
			fileDetailsForSuppression: [],
			fileDetailsForExclusion: [],
			fileDetailsForOther: [],

			countryList: [],
			beneluxDisabled: false,
			dachDisabled: false,
			nordicDisabled: false,
			ApacDisabled: false,
			ANZdisabled: false,
			nordicChecked: false,
			beneluxChecked: false,
			dachChecked: false,
			anzChecked: false,
			apacChecked: false,
			northerneuropeChecked: false,
			westernEuropeChecked: false,
			southAsiaChecked: false,
			southeastAsiaChecked: false,
			eastAsiaChecked: false,
			centralAsiaChecked: false,
			middleEastChecked: false,
			indeterminate: false,
			indeterminateAsia1: false,
			indeterminateAsia2: false,
			indeterminateAsia3: false,
			indeterminateAsia4: false,
			indeterminateAsia5: false,
			indeterminateAfr1: false,
			indeterminateAfr2: false,
			indeterminateAfr3: false,
			indeterminateAfr4: false,
			indeterminateAfr5: false,
			indeterminateMeast: "",
			indeterminateEur1: false,
			indeterminateEur2: false,
			indeterminateEur3: false,
			indeterminateEur4: false,
			indeterminateAmr1: false,
			indeterminateAmr2: false,
			indeterminateAnt1: false,
			indeterminateMel: false,
			indeterminatePoly1: false,
			indeterminateMic1: false,
			indeterminateSAtlan: false,
			checkAllRegion: false,
			checkAll: false,
			checkAll1: false,
			checkAll2: false,
			checkAll3: false,
			checkAll4: false,
			checkAll5: false,
			checkAll6: false,
			checkAll7: false,
			checkAll8: false,
			checkAll9: false,
			checkAll10: false,
			checkAll11: false,
			checkAll12: false,
			checkAll13: false,
			checkAll14: false,
			checkAll15: false,
			checkAll16: false,
			checkAll17: false,
			checkAll18: false,
			checkAll19: false,
			checkAll20: false,
			checkAll21: false,
			checkAll22: false,
			checkAll23: false,
			checkAll24: false,
			checkAll25: false,
			checkAll26: false,
			checkAll27: false,
			checkRegionSelectAll: false,
			checkAmericaSelectAll: false,
			checkAsiaSelectAll: false,
			checkEuropeSelectAll: false,
			checkAfricaSelectAll: false,
			isCheckedGeoLead: false, //snehal-task-3793-geowise lead checkbox issue
			clientNameDisplay: "none", //* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE */
			cnameDisplay: "none",
			cnameDisplay2: "none",
			startDateDisplay: "none",
			startDateDisplay1: "none",
			startDateDisplay2: "none",
			startDateDisplayRfp: "none",
			endDateDisplay: "none",
			endDateDisplay1: "none",
			endDateDisplay2: "none",
			endDateDisplay3: "none",
			bidDateErrMsg: "none",
			bidDateErrMsg1: "none",
			bidDateErrMsg2: "none",
			cplErrMsg: "none",
			cplErrMsg2: "none",
			// cplErrMsg3:"none",
			leadAllocationErrMsg: "none",
			leadAllocationErrMsg2: "none",
			regionCountriesDisplay: "none",
			customIndustryText: "",
			customCompanyRevenueText: [], //Sandeep-task-2851-added code for  customCompanyRevenue array
			customEmployeeSizeText: [], //Sandeep-task-2851-added code for  customEmployeeSize array
			industryDisplay: "none",
			employeeSizeDisplay: "none",
			employeeSizeRangeDisplay2: "none",
			employeeSizeRangeDisplay3: "none",
			employeeSizeRangeDisplay4: "none",
			employeeSizeRangeDisplay5: "none",
			customEmployeeSizeTextDisplay: "none",
			customIndustryTextDisplay: "none",
			customQuestionErMsg: "none",
			jobTitleErrMsg: "none",
			customJobLevelDisplay: "none",
			customJobFunctionDisplay: "none",
			companyRevenueDisplay: "none",
			audienceMatchErMsg: "none", //Sandeep-task-3518-added code for error msg
			talErMsg: "none", //Sandeep-task-3518-added code for error msg
			publicationErMsg: "none", //Sandeep-task-3518-added code for error msg
			employeeSizeDB: [],
			companyRevenueDB: [],
			/*rutuja 2832 commenting industryDB ,jobLevelDB,jobFunctionDB as already declared
			industryDB: [],
			jobLevelDB: [],
			jobFunctionDB: [],*/
			companyRevenueRangeDisplay2: "none",
			companyRevenueRangeDisplay3: "none",
			companyRevenueRangeDisplay4: "none",
			companyRevenueRangeDisplay5: "none",
			companyRevenueRangeDisplay6: "none",
			abmFileError: "none",
			exclusionFileError: "none",
			suppressionFileError: "none",
			otherFileError: "none",
			abmError: {},
			//suppressionerror: {}, //rutuja 2832 commenting suppressionerror as already declared
			exclusionError: {},
			durationErrMsg: "none",
			customCompanyRevenueTextDisplay: "none",
			spanlist: {},
			fromRFP: true, //Sonali-3338-if we go on supp doc routes from rfp page then this flag will identify
			invalidHeaderFileABM: [], // Bug - 3338 - Karan Jagtap - error message gone issue
			invalidHeaderFileSuppression: [], // Bug - 3338 - Karan Jagtap - error message gone issue
			invalidHeaderFileExclusion: [], // Bug - 3338 - Karan Jagtap - error message gone issue

			// User Story - 3411 - Karan Jagtap - Start
			// array to maintain all abm files
			abmFilesSelectedDocuments: [],
			// array to maintain all exclusion files
			exclusionFilesSelectedDocuments: [],
			// array to maintain all suppression files
			suppressionFilesSelectedDocuments: [],
			// array to maintain all other files
			otherFilesSelectedDocuments: [],
			clientNameDetails: [], //* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE */
			fromScreen: "", //karan-task-3636-contains from redirected screen name
			geoLeadChk: "No", //sunita-task-3645-added variable for checking checkbox
			geoLeadDisplay: "none", //sunita-task-3645-added variable for showing countrywise lead pop up msg.
			countrywiseLeadDisplay: "none", //sunita-task-3645-added variable for showing error msgs for countryviz lead.
			countrywiseLeadDisplay2: "none", //sunita-task-3645-added variable for showing error msgs for countryviz lead.
			countrywiseLeadDisplay3: "none", //sunita-task-3645-added variable for showing error msgs for countryviz lead.
			countrywiseLeadDisplay4: "none", //sunita-task-3645-added variable for showing error msgs for countryviz lead.
			countrywiseLeadDisplayGeoviz: "none", //snehal-task-3807-error when camp is geovise and all lead allocation are not there
			countryWiseAllocationArray: [], //sunita-task-3645-added array for countryviz lead allocation.
			countrywiseLeadAllocation: "No", // sunita-task-3645-added variable for countrylead allocation is given or not.
			tempCountryAllocation: [],
			fromBack: "false", //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
		};
		this.handleChangeMultipleFileOfABM =
			this.handleChangeMultipleFileOfABM.bind(this);
		this.handleChangeMultipleFileOfExclusion =
			this.handleChangeMultipleFileOfExclusion.bind(this);
		this.handleChangeMultipleFileOfSuppression =
			this.handleChangeMultipleFileOfSuppression.bind(this);
		this.handleChangeMultipleFileOfOther =
			this.handleChangeMultipleFileOfOther.bind(this);
		this.commonhandlechange = this.commonhandlechange.bind(this);
		this.industryHandleChange = this.industryHandleChange.bind(this);
		this.employeeHandleChange = this.employeeHandleChange.bind(this);
		this.revenuehandleChange = this.revenuehandleChange.bind(this);
		this.jobLevelHandleChange = this.jobLevelHandleChange.bind(this);
		this.jobFunctionHandleChange = this.jobFunctionHandleChange.bind(this);
		this.validateRfp = this.validateRfp.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.customIndustryTextChange = this.customIndustryTextChange.bind(this);

		this.customJobLevelTextChange = this.customJobLevelTextChange.bind(this); //Sandeep-task-2851-added handle change for  customJobLevel
		this.customJobFunctionTextChange =
			this.customJobFunctionTextChange.bind(this); //Sandeep-task-2851-added handle change for  customJobFunction
		this.customCompanyRevenueTextChange1 =
			this.customCompanyRevenueTextChange1.bind(this); //Sandeep-task-2851-added handle change for  customCompanyRevenue
		this.customEmployeeSizeTextChange1 =
			this.customEmployeeSizeTextChange1.bind(this); //Sandeep-task-2851-added handle change for  customEmployeeSize

		this.handleStepThree = this.handleStepThree.bind(this);
		this.handleChangeCountryRegionSearch =
			this.handleChangeCountryRegionSearch.bind(this);
		this.disperse = this.disperse.bind(this);
		this.imperse = this.imperse.bind(this);
		this.checkDach = this.checkDach.bind(this);
		this.checkBenelux = this.checkBenelux.bind(this);
		// this.checkApac=this.checkApac.bind(this)
		this.handleClientsetup = this.handleClientsetup.bind(this); //{/* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE */}

		// User Story - 3411 - Karan Jagtap - reference to control Loader
		this.loadingRef = React.createRef(); // shows the Loader
		this.closeLoadingRef = React.createRef(); // hides the Loader
		this.geoleadChange = this.geoleadChange.bind(this); //sunita-task- 3645- added handle change for geoleadchange
		this.getTotalCountries = this.getTotalCountries.bind(this); //snehal-task-3805-country lead allocation issue
		this.RfpBackButton=this.RfpBackButton.bind(this);//Sonali-3716-VAPT related changes
		this.RfpCreateBackButton=this.RfpCreateBackButton.bind(this);//Sonali-3716-VAPT related changes
	}

	/**
	 * @author sunita landge
	 * @param  Description  task-3645-added handle change for checking the checkbox
	 */

	geoleadChange = () => {
		this.setState(
			{
				isCheckedGeoLead: !this.state.isCheckedGeoLead,
			},
			function () {
				if (this.state.isCheckedGeoLead === false) { //rutuja 2832
					this.setState({
						geoLeadChk: "No",
						geoLeadDisplay: "none",
					});
				} else {
					this.setState({
						geoLeadChk: "Yes",
						geoLeadDisplay: "block",
					});
				}
			}
		);
	};

	/**
	 * @author sunita landge
	 * @param  Description  task-3645-added handle change for  allocated lead.
	 */

	leadAllocationChange(i, e) {
		const { name, value } = e.target;
		//  alert(e.target.value);
		let countryWiseAllocationArray = [...this.state.countryWiseAllocationArray];
		countryWiseAllocationArray[i] = {
			...countryWiseAllocationArray[i],
			[name]: value,
		};
		this.setState({
			countryWiseAllocationArray,
			tempCountryAllocation: countryWiseAllocationArray,
		});
		//  alert("after lead allocation handle change setstate=>"+JSON.stringify(countryWiseAllocationArray));
	}

	/**
	 * @author sunita landge
	 * @param  Description  task-3645-added handle change for submitting the lead
	 */

	submitLead(e) {
		let error = {};

		let geoWiseLeadAllocationLength =
			this.state.countryWiseAllocationArray.length;
		//  alert(geoWiseLeadAllocationLength);
		let campLeadAllocation = this.state.BasicDetailArray[0].leadAllocation;
		let geoWiseLeadsTotal = 0;
		var leadAllocationNumeric = true;
		var count = 0;
		//sunita-task-3645-added below condition for submitting the lead whether campaign is rfp campaign or normal campaign.
		if (
			this.state.rfpCampaignID === "" ||
			this.state.rfpCampaignID === null ||
			this.state.rfpCampaignID === undefined ||
			this.state.rfpCampaignID === "undefined"
		) {
			for (let i = 0; i < geoWiseLeadAllocationLength; i++) {
				//snehal-task-3807-error when camp is geovise and all lead allocation are not there
				if (
					(this.state.countryWiseAllocationArray[i].leadCount === "" || //rutuja 2832
						this.state.countryWiseAllocationArray[i].leadCount < 0) &&
					this.state.BasicDetailArray[0].biddingType === "Geowise"
				) {
					leadAllocationNumeric = false;
					error["requireLead"] = "Please allocate leads to all countries";
					//Sandeep-task-3397-error msg issue
					this.setState({ error: error });
					break;
				}
				if (this.state.countryWiseAllocationArray[i].leadCount === "") { //rutuja 2832
					//  alert("inside if")
					if (count === 0) {
						//  alert("inisde count 0=>"+count);
						leadAllocationNumeric = false;
						error["requireLead"] = "Please enter countryviz lead allocation";
						//Sandeep-task-3397-error msg issue
						this.setState({ error: error });
					}
				} else {
					//  alert("inside else")
					count++;
					if (geoWiseLeadAllocationLength <= campLeadAllocation) {
						var pattern = new RegExp(/^[1-9][0-9]*$/);
						if (
							!pattern.test(this.state.countryWiseAllocationArray[i].leadCount)
						) {
							leadAllocationNumeric = false;
							error["requireLead"] = "";
							error["leadAllocationNumeric"] =
								"Please enter positive numeric and non-zero value for lead allocation";
							this.setState({ error: error });
							break;
						} else {
							leadAllocationNumeric = true;
							error["requireLead"] = "";
							error["leadAllocationNumeric"] = "";
							this.setState({ error: error });
						}
					} else if (geoWiseLeadAllocationLength > campLeadAllocation) {
						let pattern = new RegExp(/^[0-9]*$/); // rutuja 2832 declared pattern with let for removing console warning
						if (
							!pattern.test(this.state.countryWiseAllocationArray[i].leadCount)
						) {
							leadAllocationNumeric = false;
							error["requireLead"] = "";
							error["leadAllocationNumeric"] =
								"Please enter positive numeric value  for lead allocation";
							this.setState({ error: error });
							break;
						} else {
							leadAllocationNumeric = true;
							error["requireLead"] = "";
							error["leadAllocationNumeric"] = "";
							this.setState({ error: error });
						}
					} else {
					}
					if (leadAllocationNumeric == true) {
						geoWiseLeadsTotal =
							parseInt(geoWiseLeadsTotal) +
							parseInt(this.state.countryWiseAllocationArray[i].leadCount);
					}
				}
			}
		} else {
			//   alert("inside else");
			for (let i = 0; i < geoWiseLeadAllocationLength; i++) {
				//snehal-task-3807-error when camp is geovise and all lead allocation are not there
				if (
					(this.state.countryWiseAllocationArray[i].leadCount === "" ||//rutuja 2832 replace == with ===
						this.state.countryWiseAllocationArray[i].leadCount < 0) &&
					this.state.BasicDetailArray[0].biddingType === "Geowise"
				) {
					leadAllocationNumeric = false;
					error["requireLead"] = "Please allocate leads to all countries";
					//Sandeep-task-3397-error msg issue
					this.setState({ error: error });
					break;
				}
				if (this.state.countryWiseAllocationArray[i].leadCount === "") {//rutuja 2832 replace == with ===
					//    alert("inside if");
					if (count === 0) {
						//    alert("inisde count 0=>"+count);
						leadAllocationNumeric = false;
						error["requireLead"] = "Please enter countryviz lead allocation";
						//Sandeep-task-3397-error msg issue

						this.setState({ error: error });
					}
				} else {
					//    alert("inside if block else");
					count++;
					if (geoWiseLeadAllocationLength <= campLeadAllocation) {
						let pattern = new RegExp(/^[1-9][0-9]*$/); // rutuja 2832 declared pattern with let for removing console warning
						if (
							!pattern.test(this.state.countryWiseAllocationArray[i].leadCount)
						) {
							leadAllocationNumeric = false;
							error["requireLead"] = "";
							error["leadAllocationNumeric"] =
								"Please enter positive numeric and non-zero value for lead allocation";
							this.setState({ error: error });
							break;
						} else {
							leadAllocationNumeric = true;
							error["requireLead"] = "";
							error["leadAllocationNumeric"] = "";
							this.setState({ error: error });
						}
					} else if (geoWiseLeadAllocationLength > campLeadAllocation) {
						let pattern = new RegExp(/^[0-9]*$/); // rutuja 2832 declared pattern with let for removing console warning
						if (
							!pattern.test(this.state.countryWiseAllocationArray[i].leadCount)
						) {
							leadAllocationNumeric = false;
							error["requireLead"] = "";
							error["leadAllocationNumeric"] =
								"Please enter positive numeric value  for lead allocation";
							this.setState({ error: error });
							break;
						} else {
							leadAllocationNumeric = true;
							error["requireLead"] = "";
							error["leadAllocationNumeric"] = "";
							this.setState({ error: error });
						}
					} else {
					}
					if (leadAllocationNumeric == true) {
						geoWiseLeadsTotal =
							parseInt(geoWiseLeadsTotal) +
							parseInt(this.state.countryWiseAllocationArray[i].leadCount);
					}
				}
			}
		}

		if (leadAllocationNumeric == true) {
			if (geoWiseLeadsTotal > campLeadAllocation) {
				error["requireLead"] = "";
				error["geoWiseLeadAllocationError"] =
					"Please enter countryviz lead allocation equal to total lead allocation";
				//Sandeep-task-3397-error msg issue
				error["geoWiseLeadAllocationSuccess"] = "";
				this.setState({ error: error });
			} else if (geoWiseLeadsTotal < campLeadAllocation) {
				error["requireLead"] = "";
				error["geoWiseLeadAllocationError"] =
					"Please enter countryviz lead allocation equal to total lead allocation";
				//Sandeep-task-3397-error msg issue
				error["geoWiseLeadAllocationSuccess"] = "";
				this.setState({ error: error });
			} else {
				error["requireLead"] = "";
				error["geoWiseLeadAllocationSuccess"] =
					"CountryViz Lead Allocation Done Successfully. Please Close the Pop-up to Continue.";	//Chaitanya-4334-Prod Issue-Agency-CountryViz Lead allocation- Rename of 'Cancel' button as 'Close'
				error["geoWiseLeadAllocationError"] = "";
				this.setState({
					error: error,
					countrywiseLeadDisplay: "none",
					countrywiseLeadDisplay2: "none",
					countrywiseLeadDisplay3: "none",
					countrywiseLeadDisplay4: "none",
					countrywiseLeadDisplayGeoviz: "none",
				});
				//snehal-task-3807-error when camp is geovise and all lead allocation are not there
			}
		}
	}

	/**
	 * @author Raunak Thakkar
	 * @param  Description  for dis-selecting sub remaining sub-regions
	 */
	// checkApac(SouthAsiacheckedList,EastAsiacheckedList,southEastAsia,centralAsia){
	//   let bool=true
	//    BeneluxOptions.forEach((ele)=>{if(!WesternEuropecheckedList.includes(ele)){
	//   bool=false
	//   return bool
	//   }})
	//   return bool
	//       }

	/**
	 * @author Raunak Thakkar
	 * @param  Description  for dis-selecting sub remaining sub-regions
	 */
	checkBenelux(WesternEuropecheckedList) {
		let bool = true;
		BeneluxOptions.forEach((ele) => {
			if (!WesternEuropecheckedList.includes(ele)) {
				bool = false;
				return bool;
			}
		});
		return bool;
	}

	/**
	 * @author Raunak Thakkar
	 * @param  Description  for dis-selecting sub remaining sub-regions
	 */
	checkDach(WesternEuropecheckedList) {
		let bool = true;
		DACHOptions.forEach((ele) => {
			if (!WesternEuropecheckedList.includes(ele)) {
				bool = false;
				return bool;
			}
		});
		return bool;
	}
	/**
	 * @author Raunak Thakkar
	 * @param  Description  for dis-selecting sub remaining sub-regions
	 */
	imperse(centralAsia, eastAsia, southAsia, southeastAsia) {
		if (this.state.apacChecked === true) {
			this.setState(
				{
					indeterminateAsia1: false,
					indeterminateAsia2: false,
					indeterminateAsia3: false,
					indeterminateAsia4: false,
				},
				() => {
					["Central Asia", "East Asia", "South Asia", "Southeast Asia"].forEach(
						(ele) => {
							if (this.state.region.indexOf(ele) > -1) {
								this.state.region.splice(this.state.region.indexOf(ele), 1);
							}
						}
					);
				}
			);
		}
	}
	/**
	 * @author Raunak Thakkar
	 * @param  Description  for selecting sub remaining sub-regions against 2864 and 2950
	 */
	disperse(centralAsia, eastAsia, southAsia, southeastAsia) {
		if (this.state.apacChecked === true) {
			if (centralAsia === true && !this.state.region.includes("Central Asia")) {
				this.state.region.push("Central Asia");
			}
			if (eastAsia === true && !this.state.region.includes("East Asia")) {
				this.state.region.push("East Asia");
			}
			if (southAsia === true && !this.state.region.includes("South Asia")) {
				this.state.region.push("South Asia");
			}
			if (
				southeastAsia === true &&
				!this.state.region.includes("Southeast Asia")
			) {
				this.state.region.push("Southeast Asia");
			}
			this.setState(
				{
					indeterminateAsia1: centralAsia == true ? true : false,
					indeterminateAsia2: eastAsia == true ? true : false,
					indeterminateAsia3: southAsia == true ? true : false,
					indeterminateAsia4: southeastAsia == true ? true : false,
				},
				() => {
					if (this.state.region.includes("apac")) {
						//Sandeep-task-3419-region issue
						this.state.region.splice(this.state.region.indexOf("apac"), 1); //Sandeep-task-3419-region issue
					}
				}
			);
		}
	}
	handleChangeCountryRegionSearch(e) {
		let colorCount = 0;
		for (const [key, value] of Object.entries(this.state.spanlist)) {
			if (
				e.target.value.length > 0 &&
				RegExp(/^[^1234567890^!@#$%*()_={}\n]+$/).test(e.target.value)
			) {
				if (key.includes(e.target.value.toLowerCase())) {
					value[1].style.backgroundColor = "yellow";
					colorCount += 1;
				} else {
					value[1].style.backgroundColor = "#ffffff";
				}
			} else {
				value[1].style.backgroundColor = "#ffffff";
			}
		}
		if (
			!RegExp(/^[^1234567890^!@#$%*()_={}\n]+$/).test(e.target.value) &&
			colorCount == 0
		) {
			document.getElementById("colorCount").innerHTML = "Invalid characters";
			document.getElementById("colorCount").style.color = "red";
			if (e.target.value.length == 0) {
				document.getElementById("colorCount").innerHTML = colorCount + "/252"; //mufiz-Task-Prod Issue-Agency-Custom Region and Countries---Country Count issue and Non-Selected Region showing in Region column.
				document.getElementById("colorCount").style.color = "black";
				return;
			}
		} else {
			if (colorCount > 0) {
				document.getElementById("colorCount").innerHTML = colorCount + "/252";
				document.getElementById("colorCount").style.color = "black";
			}

			if (colorCount == 0) {
				document.getElementById("colorCount").innerHTML = "No search found";
				document.getElementById("colorCount").style.color = "red";
			}
		}
	}
	handleStepThree() {
		var regionBackEnd = [];
		var nordicArray = [];
		//var beneluxArray = []; //rutuja 2832 commented beneluxArray as already declared 
		//var dachArray = []; //rutuja 2832 commented dachArray as already declared 
		var anzArray = [];
		var apacArray = [];
		// regionBackEnd = this.state.BasicDetailArray[0].region.toLocaleString().toLowerCase().split(',');//Sandeep-task-3157-change in array name
		regionBackEnd = this.state.BasicDetailArray[0].region
			.toLocaleString()
			.split(",");
		//Sandeep-task-3313-added code for region issue
		// alert("regionBackEnd==>"+JSON.stringify(regionBackEnd));
		let backEndcounrties = [];
		backEndcounrties = this.state.BasicDetailArray[0].country.split(",");
		// alert("backEndcounrties===>"+JSON.stringify(this.state.BasicDetailArray[0].country));
		this.state.NorthernAfricaCountriescheckedList = backEndcounrties.filter(
			function (item) {
				return NorthernAfricaCountriesOptions.includes(item);
			}
		);
		this.state.CentralOrMiddleAfricancheckedList = backEndcounrties.filter(
			function (item) {
				return CentralOrMiddleAfricanOptions.includes(item);
			}
		);
		this.state.SouthernAfricaCountriescheckedList = backEndcounrties.filter(
			function (item) {
				return SouthernAfricaCountriesOptions.includes(item);
			}
		);
		this.state.EastAfricanCountriescheckedList = backEndcounrties.filter(
			function (item) {
				return EastAfricanCountriesOptions.includes(item);
			}
		);
		this.state.WesternAfricacheckedList = backEndcounrties.filter(function (
			item
		) {
			return WesternAfricaOptions.includes(item);
		});
		this.state.EasternEuropecheckedList = backEndcounrties.filter(function (
			item
		) {
			return EasternEuropeOptions.includes(item);
		});
		this.state.NorthernEuropecheckedList = backEndcounrties.filter(function (
			item
		) {
			return NorthernEuropeOptions.includes(item);
		});
		//  this.state.NorthernEuropecheckedList=backEndcounrties.filter(function(item){return NordicOptions.includes(item)});
		nordicArray = backEndcounrties.filter(function (item) {
			return NordicOptions.includes(item);
		});
		apacArray = backEndcounrties.filter(function (item) {
			return APACOptions.includes(item);
		});
		this.state.SouthernEuropecheckedList = backEndcounrties.filter(function (
			item
		) {
			return SouthernEuropeOptions.includes(item);
		});
		this.state.WesternEuropecheckedList = backEndcounrties.filter(function (
			item
		) {
			return WesternEuropeOptions.includes(item);
		});
		//  this.state.WesternEuropecheckedList=backEndcounrties.filter(function(item){return BeneluxOptions.includes(item)});
		/* rutuja 2832 commenting  beneluxArray and dachArray as never used in the file
		var beneluxArray = backEndcounrties.filter(function (item) {
			return BeneluxOptions.includes(item);
		});
		//  this.state.WesternEuropecheckedList=backEndcounrties.filter(function(item){return DACHOptions.includes(item)});
		var dachArray = backEndcounrties.filter(function (item) {
			return DACHOptions.includes(item);
		});*/

		this.state.CentralAsiacheckedList = backEndcounrties.filter(function (
			item
		) {
			return CentralAsiaOptions.includes(item);
		});
		this.state.EastAsiacheckedList = backEndcounrties.filter(function (item) {
			return EastAsiaOptions.includes(item);
		});
		this.state.SouthAsiacheckedList = backEndcounrties.filter(function (item) {
			return SouthAsiaOptions.includes(item);
		});
		//  this.state.SouthAsiacheckedList=backEndcounrties.filter(function(item){return ANZOptions.includes(item)});
		//alert("this.state.SouthAsiacheckedList===="+this.state.SouthAsiacheckedList)
		anzArray = backEndcounrties.filter(function (item) {
			return ANZOptions.includes(item);
		});

		this.state.SoutheastAsiacheckedList = backEndcounrties.filter(function (
			item
		) {
			return SoutheastAsiaOptions.includes(item);
		});
		this.state.WesternAsiacheckedList = backEndcounrties.filter(function (
			item
		) {
			return WesternAsiaOptions.includes(item);
		});
		this.state.SouthAmericacheckedList = backEndcounrties.filter(function (
			item
		) {
			return SouthAmericaOptions.includes(item);
		});
		this.state.AntarcticcheckedList = backEndcounrties.filter(function (item) {
			return AntarcticOptions.includes(item);
		});
		this.state.NorthAmericacheckedList = backEndcounrties.filter(function (
			item
		) {
			return NorthAmericaOptions.includes(item);
		});
		//  this.state.AustraliaAndNewZealandcheckedList=backEndcounrties.filter(function(item){return AustraliaAndNewZealandOptions.includes(item)});
		this.state.MelanesiacheckedList = backEndcounrties.filter(function (item) {
			return MelanesiaOptions.includes(item);
		});
		this.state.middleEastCheckedList = backEndcounrties.filter(function (item) {
			return MiddleEastOptions.includes(item);
		});
		this.state.PolynesiacheckedList = backEndcounrties.filter(function (item) {
			return PolynesiaOptions.includes(item);
		});
		this.state.MicronesiacheckedList = backEndcounrties.filter(function (item) {
			return MicronesiaOptions.includes(item);
		});
		this.state.SouthernAtlanticOceancheckedList = backEndcounrties.filter(
			function (item) {
				return SouthernAtlanticOceanOptions.includes(item);
			}
		);
		if (this.state.NorthernAfricaCountriescheckedList.length > 0) {
			this.state.region.push("Northern Africa Countries");
			this.setState({ indeterminateAfr1: true });
		}
		if (this.state.CentralOrMiddleAfricancheckedList.length > 0) {
			this.state.region.push("Central or Middle African");
			this.setState({ indeterminateAfr2: true });
		}
		if (this.state.SouthernAfricaCountriescheckedList.length > 0) {
			this.state.region.push("Southern Africa Countries");
			this.setState({ indeterminateAfr3: true });
		}
		if (this.state.EastAfricanCountriescheckedList.length > 0) {
			this.state.region.push("East African Countries");
			this.setState({ indeterminateAfr4: true });
		}
		if (this.state.WesternAfricacheckedList.length > 0) {
			this.state.region.push("Western Africa");
			this.setState({ indeterminateAfr5: true });
		}
		if (this.state.EasternEuropecheckedList.length > 0) {
			this.state.region.push("Eastern Europe");
			this.setState({ indeterminateEur1: true });
		}
		if (this.state.NorthernEuropecheckedList.length > 0) {
			let only = this.state.NorthernEuropecheckedList.map((ele) => {
				return !NordicOptions.includes(ele);
			});
			this.setState({
				indeterminateEur2: only.includes(true) ? true : false,
				nordicChecked: regionBackEnd.includes("nordic") ? true : false, //Sandeep-task-3419-region issue
				checkAll25: regionBackEnd.includes("nordic") ? true : false, //Sandeep-task-3419-region issue
			});
		}
		//2950 raunak- selecting appropriate region

		if (this.state.SouthernEuropecheckedList.length > 0) {
			this.state.region.push("Southern Europe");
			this.setState({ indeterminateEur3: true });
		}
		if (this.state.WesternEuropecheckedList.length > 0) {
			//2950 and 2864 raunak- selecting appropriate region
			this.setState({
				indeterminateEur4:
					(JSON.stringify(this.state.WesternEuropecheckedList.sort()) ===
						JSON.stringify([...DACHOptions, ...BeneluxOptions].sort())) |
					(JSON.stringify(this.state.WesternEuropecheckedList.sort()) ===
						JSON.stringify([...DACHOptions].sort())) |
					(JSON.stringify(this.state.WesternEuropecheckedList.sort()) ===
						JSON.stringify([...BeneluxOptions].sort()))
						? false
						: true,
				region: regionBackEnd,
				checkAll23:
					regionBackEnd.includes("benelux") &&
					this.checkBenelux(this.state.WesternEuropecheckedList) === true
						? true
						: false,
				//Sandeep-task-3419-region issue
				beneluxChecked:
					regionBackEnd.includes("benelux") &&
					this.checkBenelux(this.state.WesternEuropecheckedList) === true
						? true
						: false,
				//Sandeep-task-3419-region issue
				checkAll24:
					regionBackEnd.includes("dach") &&
					this.checkDach(this.state.WesternEuropecheckedList) === true
						? true
						: false,
				//Sandeep-task-3419-region issue
				dachChecked:
					regionBackEnd.includes("dach") &&
					this.checkDach(this.state.WesternEuropecheckedList) === true
						? true
						: false,
				//Sandeep-task-3419-region issue
			});
		}
		//Sandeep-task-3419-region issue
		if (
			this.state.CentralAsiacheckedList.length > 0 &&
			!regionBackEnd.includes("apac") //Sandeep-task-3419-region issue
		) {
			this.state.region.push("Central Asia");
			this.setState({ indeterminateAsia1: true });
		} //2950 raunak- selecting appropriate region
		if (
			this.state.EastAsiacheckedList.length > 0 &&
			!regionBackEnd.includes("apac") //Sandeep-task-3419-region issue
		) {
			this.state.region.push("East Asia");
			this.setState({ indeterminateAsia2: true });
		} //2950 raunak- selecting appropriate region
		if (
			this.state.SouthAsiacheckedList.length > 0 &&
			!regionBackEnd.includes("apac") &&
			!regionBackEnd.includes("anz") //Sandeep-task-3419-region issue
		) {
			this.state.region.push("South Asia");
			this.setState({ indeterminateAsia3: true });
		} //2950 raunak- selecting appropriate region
		if (
			this.state.SoutheastAsiacheckedList.length > 0 &&
			!regionBackEnd.includes("apac") //Sandeep-task-3419-region issue
		) {
			this.state.region.push("Southeast Asia");
			this.setState({ indeterminateAsia4: true });
		} //2950 raunak- selecting appropriate region
		if (this.state.WesternAsiacheckedList.length > 0) {
			this.state.region.push("Western Asia");
			this.setState({ indeterminateAsia5: true });
		}
		if (this.state.SouthAmericacheckedList.length > 0) {
			this.state.region.push("South America");
			this.setState({ indeterminateAmr1: true });
		}
		if (this.state.AntarcticcheckedList.length > 0) {
			this.state.region.push("Antarctic");
			this.setState({ indeterminateAnt1: true });
		}
		if (this.state.NorthAmericacheckedList.length > 0) {
			this.state.region.push("North America");
			this.setState({ indeterminateAmr2: true });
		}
		if (this.state.AustraliaAndNewZealandcheckedList.length > 0) {
			this.state.region.push("Australia and New Zealand");
		}
		if (this.state.MelanesiacheckedList.length > 0) {
			this.state.region.push("Melanesia");
			this.setState({ indeterminateMel: true });
		}
		if (this.state.PolynesiacheckedList.length > 0) {
			this.state.region.push("Polynesia");
			this.setState({ indeterminatePoly1: true });
		}
		if (this.state.middleEastCheckedList.length > 0) {
			this.state.region.push("Middle East");
			this.setState({ indeterminateMeast: true });
		}
		if (this.state.MicronesiacheckedList.length > 0) {
			this.state.region.push("Micronesia");
			this.setState({ indeterminateMic1: true });
		}
		if (this.state.SouthernAtlanticOceancheckedList.length > 0) {
			this.state.region.push("Southern Atlantic Ocean");
			this.setState({ indeterminateSAtlan: true });
		}

		if (
			this.state.NorthernAfricaCountriescheckedList.length ==
			NorthernAfricaCountriesOptions.length
		) {
			this.setState({ checkAll1: true, indeterminateAfr1: false });
		}
		if (
			this.state.CentralOrMiddleAfricancheckedList.length ==
			CentralOrMiddleAfricanOptions.length
		) {
			this.setState({ checkAll2: true, indeterminateAfr2: false });
		}
		if (
			this.state.SouthernAfricaCountriescheckedList.length ==
			SouthernAfricaCountriesOptions.length
		) {
			this.setState({ checkAll3: true, indeterminateAfr3: false });
		}
		if (
			this.state.EastAfricanCountriescheckedList.length ==
			EastAfricanCountriesOptions.length
		) {
			this.setState({ checkAll4: true, indeterminateAfr4: false });
		}
		if (
			this.state.WesternAfricacheckedList.length == WesternAfricaOptions.length
		) {
			this.setState({ checkAll5: true, indeterminateAfr5: false }, function () {
				if (
					this.state.checkAll1 == true &&
					this.state.checkAll2 == true &&
					this.state.checkAll3 == true &&
					this.state.checkAll4 == true &&
					this.state.checkAll5 == true
				) {
					this.setState({ checkAfricaSelectAll: true });
				}
			});
		}
		if (
			this.state.EasternEuropecheckedList.length == EasternEuropeOptions.length
		) {
			this.setState({ checkAll6: true, indeterminateEur1: false });
		}
		if (
			this.state.NorthernEuropecheckedList.length ==
			NorthernEuropeOptions.length
		) {
			this.setState({ checkAll7: true, indeterminateEur2: false });
		}
		if (nordicArray.length == NordicOptions.length) {
			if (regionBackEnd.includes("nordic")) {
				//Sandeep-task-3419-region issue
				this.state.region.push("nordic"); //Sandeep-task-3419-region issue
				this.setState({ checkAll25: true });
			}
		}
		if (apacArray.length == APACOptions.length) {
			if (regionBackEnd.includes("apac")) {
				//Sandeep-task-3419-region issue
				this.state.region.push("apac"); //Sandeep-task-3419-region issue
				this.setState({ checkAll26: true });
			}
		}

		if (
			this.state.SouthernEuropecheckedList.length ==
			SouthernEuropeOptions.length
		) {
			this.setState({ checkAll8: true, indeterminateEur3: false });
		}
		if (
			this.state.WesternEuropecheckedList.length == WesternEuropeOptions.length
		) {
			this.setState({ checkAll9: true, indeterminateEur4: false }, function () {
				if (
					this.state.checkAll6 == true &&
					this.state.checkAll7 == true &&
					this.state.checkAll8 == true &&
					this.state.checkAll9 == true
				) {
					this.setState({
						checkEuropeSelectAll: true,
						beneluxDisabled: true,
						dachDisabled: true,
						nordicDisabled: true,
					});
				}
			});
		}

		if (this.state.CentralAsiacheckedList.length == CentralAsiaOptions.length) {
			if (!regionBackEnd.includes("apac")) {
				//Sandeep-task-3419-region issue
				this.setState({
					checkAll10: true,
					indeterminateAsia1: false,
					ApacDisabled: true,
					ANZdisabled: true,
				});
			}
		}

		if (this.state.EastAsiacheckedList.length == EastAsiaOptions.length) {
			this.setState({
				checkAll11: true,
				indeterminateAsia2: false,
				ApacDisabled: true,
				ANZdisabled: true,
			});
		}
		if (this.state.SouthAsiacheckedList.length == SouthAsiaOptions.length) {
			this.setState({
				checkAll12: true,
				indeterminateAsia3: false,
				ApacDisabled: true,
				ANZdisabled: true,
			});
		}
		if (anzArray.length == ANZOptions.length) {
			if (regionBackEnd.includes("anz")) {
				//Sandeep-task-3419-region issue
				this.state.region.push("anz"); //Sandeep-task-3419-region issue
				this.setState({ checkAll18: true, indeterminateAsia3: false });
			}
			// Sandeep-task-3313-commented this code because getting issue with SouthAsia Region on edit side
			// let indx = this.state.region.indexOf("South Asia")
			// alert("indx"+indx)
			// if (indx > -1) {
			//    this.state.region.splice(indx, 1)

			// }
		}

		if (
			this.state.SoutheastAsiacheckedList.length == SoutheastAsiaOptions.length
		) {
			this.setState({
				checkAll13: true,
				indeterminateAsia4: false,
				ApacDisabled: true,
				ANZdisabled: true,
			});
		}
		if (this.state.WesternAsiacheckedList.length == WesternAsiaOptions.length) {
			this.setState(
				{ checkAll14: true, indeterminateAsia5: false },
				function () {
					if (
						this.state.checkAll10 == true &&
						this.state.checkAll11 == true &&
						this.state.checkAll12 == true &&
						this.state.checkAll13 == true &&
						this.state.checkAll14 == true
					) {
						this.setState({
							checkAsiaSelectAll: true,
							ApacDisabled: true,
							ANZdisabled: true,
						});
					}
				}
			);
		}
		if (
			this.state.SouthAmericacheckedList.length == SouthAmericaOptions.length
		) {
			this.setState({ checkAll15: true, indeterminateAmr1: false });
		}
		if (this.state.AntarcticcheckedList.length == AntarcticOptions.length) {
			this.setState({ checkAll16: true, indeterminateAnt1: false });
		}
		if (
			this.state.NorthAmericacheckedList.length == NorthAmericaOptions.length
		) {
			this.setState(
				{ checkAll17: true, indeterminateAmr2: false },
				function () {
					if (this.state.checkAll15 == true && this.state.checkAll17 == true) {
						this.setState({ checkAmericaSelectAll: true });
					}
				}
			);
		}

		if (this.state.MelanesiacheckedList.length == MelanesiaOptions.length) {
			this.setState({ checkAll19: true, indeterminateMel: false });
		}
		if (this.state.PolynesiacheckedList.length == PolynesiaOptions.length) {
			this.setState({ checkAll20: true, indeterminatePoly1: false });
		}

		if (this.state.middleEastCheckedList.length == MiddleEastOptions.length) {
			this.setState({ checkAll27: true, indeterminateMeast: false });
		}

		if (this.state.MicronesiacheckedList.length == MicronesiaOptions.length) {
			this.setState({ checkAll21: true, indeterminateMic1: false });
		}
		if (
			this.state.SouthernAtlanticOceancheckedList.length ==
			SouthernAtlanticOceanOptions.length
		) {
			this.setState(
				{ checkAll22: true, indeterminateSAtlan: false },
				function () {
					if (
						this.state.checkAll1 == true &&
						this.state.checkAll2 == true &&
						this.state.checkAll3 == true &&
						this.state.checkAll4 == true &&
						this.state.checkAll5 == true &&
						this.state.checkAll6 == true &&
						this.state.checkAll7 == true &&
						this.state.checkAll8 == true &&
						this.state.checkAll9 == true &&
						this.state.checkAll15 == true &&
						this.state.checkAll17 == true &&
						this.state.checkAll10 == true &&
						this.state.checkAll11 == true &&
						this.state.checkAll12 == true &&
						this.state.checkAll13 == true &&
						this.state.checkAll14 == true &&
						this.state.checkAll16 == true &&
						this.state.checkAll19 == true &&
						this.state.checkAll20 == true &&
						this.state.checkAll21 == true &&
						this.state.checkAll22 == true &&
						this.state.checkAll27 == true
					) {
						this.setState({
							dachDisabled: true,
							ANZdisabled: true,
							beneluxDisabled: true,
							nordicDisabled: true,
							ApacDisabled: true,
							checkRegionSelectAll: true,
						});
					}
				}
			);
		}

		// this.state.region.push(regionBackEnd) {/* sunita – Task -2956- code commented showing duplicate region name in rfpcampspecification page */}

		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].employeeSize
		) {
			var str = this.state.BasicDetailArray[0].employeeSize;
			var str_array = str.split("|");
			var employeeSizeArray = [];
			for (var i = 0; i < str_array.length; i++) {
				if (str_array[i] !== "") {
					employeeSizeArray.push({ id: str_array[i], name: str_array[i] });
					this.state.employeeSizeDB.push(str_array[i]);
				}
			}
			this.setState({ employeeSize: employeeSizeArray });
		}
		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].customEmpSize
		) {
			//var str = this.state.BasicDetailArray[0].customEmpSize; // rutuja 2832 commenting str as never used in the file

			this.setState({ customEmployeeSizeText: str }, function () {});
			// this.state.employeeSizetofrom = []
			// this.state.customEmployeeSizeText = []
			// if (str.charAt(0) === '|') { str = str.substr(1); }

			// var str_array = str.split('|')
			// for (var i = 0; i < str_array.length; i++) {
			//   if (str_array[i].includes("-")) {
			//     var array = str_array[i].split('-')
			//     this.state.employeeSizetofrom.push({ 'employeeSizeID': (i), 'from': array[0], 'to': array[1] })
			//   }
			//   else {
			//     this.state.customEmployeeSizeText.push({ 'employeeSizeCustomID': (i), 'customEmployeeSizeText': str_array[i] })
			//   }
			// }
		}
		if (
			this.state.BasicDetailArray[0].customCompRevenue &&
			this.state.BasicDetailArray[0].length &&
			this.state.BasicDetailArray[0].customCompRevenue
		) {
				let str = this.state.BasicDetailArray[0].customCompRevenue; // rutuja 2832 changed declaration str to let for removing console warning

			if (str.charAt(0) === "|") {
				str = str.substr(1);
			}
			//var str_array = str.split("|"); // rutuja 2832 commenting str_array as already declared
			this.state.companyRevenuetofrom = [];
			this.state.customCompanyRevenueText = [];
			// rutuja 2832 changed declaration vai i to let i for removing console warning
			for (let i = 0; i < str_array.length; i++) {
				if (str_array[i].includes("-")) {
					var array = str_array[i].split("-");
					this.state.companyRevenuetofrom.push({
						companyRevenueID: i,
						from: array[0],
						to: array[1],
					});
				} else {
					this.state.customCompanyRevenueText.push({
						companyRevenueCustomID: i,
						customCompanyRevenueText: str_array[i],
					});
				}
			}
		} //custom company revenue end

		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].companyRevenue
		) {
			let str = this.state.BasicDetailArray[0].companyRevenue; // rutuja 2832 replacing the declaration var str to let str
			let str_array = str.split("|"); // rutuja 2832 replacing the declaration var str_array to let str_array
			var companyRevenue = [];
			//rutuja 2832 replaced var i with let i for removing console warning 
			for (let i = 0; i < str_array.length; i++) {
				if (str_array[i] !== "") {
					companyRevenue.push({ id: str_array[i], name: str_array[i] });
					this.state.companyRevenueDB.push(str_array[i]);
				}
			}
			this.setState({ companyRevenue: companyRevenue }, function () {});
		} //Company Revenue End
		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].industry
		) {
			let str = this.state.BasicDetailArray[0].industry;  // rutuja 2832 replacing declaration var to let
			 let str_array = str.split("|"); // rutuja 2832 replacing declaration var to let
			var industry = [];
			//rutuja 2832 replaced var i with let i for removing console warning 
			for (let i = 0; i < str_array.length; i++) {
				if (str_array[i] !== "") {
					industry.push({ id: str_array[i], name: str_array[i] });
					this.state.industryDB.push(str_array[i]);
				}
			}
			this.setState({ industry: industry }, function () {});
		} //Industry End
		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].customIndustry
		) {
			let str = this.state.BasicDetailArray[0].customIndustry;  // rutuja 2832 changed declaration str to let for removing console warning
			this.setState({ customIndustryText: str });
		} //custom industry end
		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].jobLevel
		) {
			let str = this.state.BasicDetailArray[0].jobLevel;  // rutuja 2832 replacing declaration var to let
			let str_array = str.split("|"); // rutuja 2832 replacing declaration var to let
			var jobLevelArray = [];
			//rutuja 2832 replaced var i with let i for removing console warning 
			for (let i = 0; i < str_array.length; i++) {
				if (str_array[i] !== "") {
					jobLevelArray.push({ id: str_array[i], name: str_array[i] });
					this.state.jobLevelDB.push(str_array[i]);
				}
			}
			this.setState({ jobLevel: jobLevelArray });
		} //Job level End
		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].customJobLevel
		) {
			let str = this.state.BasicDetailArray[0].customJobLevel; // rutuja 2832 changed declaration str to let for removing console warning
			this.setState({ customJobLevelText: str }, function () {});

			// this.state.customJobLevel = []
			// if (str.charAt(0) === '|') { str = str.substr(1); }
			// var str_array = str.split('|')
			// for (var i = 0; i < str_array.length; i++) {
			//   this.state.customJobLevel.push({ "id": i, 'value': str_array[i] })
			// }
		} //custom job level
		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].jobFunction
		) {
			let str = this.state.BasicDetailArray[0].jobFunction;// rutuja 2832 replacing declaration var to let
			let str_array = str.split("|"); // rutuja 2832 replacing declaration var to let
			var jobFunctionArray = [];
			//rutuja 2832 replaced var i with let i for removing console warning 
			for (let i = 0; i < str_array.length; i++) {
				if (str_array[i] !== "") {
					jobFunctionArray.push({ id: str_array[i], name: str_array[i] });
					this.state.jobFunctionDB.push(str_array[i]);
				}
			}
			this.setState({ jobFunction: jobFunctionArray }, function () {});
		} //Job function End

		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].customJobFunction
		) {
			let str = this.state.BasicDetailArray[0].customJobFunction;    // rutuja 2832 changed declaration str to let for removing console warning

			this.setState({ customJobFunctionText: str });
			// this.state.customJobFunction = [];
			// if (str.charAt(0) === '|') { str = str.substr(1); }
			// var str_array = str.split('|')
			// for (var i = 0; i < str_array.length; i++) {
			//   this.state.customJobFunction.push({ "id": i, 'value': str_array[i] })
			// }
		}
		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].customJobFunction
		) {
			let str = this.state.BasicDetailArray[0].jobTitle;  // rutuja 2832 changed declaration str to let for removing console warning
			let BasicDetailArray = [...this.state.BasicDetailArray];
			//alert("here"+JSON.stringify(BasicDetailArray))
			BasicDetailArray[0] = { ...BasicDetailArray[0], "jobTitle": str }; //rutuja 2832
			this.setState({ BasicDetailArray: BasicDetailArray });
		}
		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].customJobFunction
		) {
			let str = this.state.BasicDetailArray[0].otherSpecs;  // rutuja 2832 changed declaration str to let for removing console warning
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "otherSpecs": str };
			this.setState({ BasicDetailArray: BasicDetailArray });
		}
		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].customJobFunction
		) {
			let str = this.state.BasicDetailArray[0].noOfCustomQuestions;  // rutuja 2832 changed declaration str to let for removing console warning
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = {
				...BasicDetailArray[0],
				"noOfCustomQuestions": str,
			};
			this.setState({ BasicDetailArray: BasicDetailArray });
		}
		if (
			this.state.BasicDetailArray &&
			this.state.BasicDetailArray.length &&
			this.state.BasicDetailArray[0].customCompRevenue
		) {
			let str = this.state.BasicDetailArray[0].customCompRevenue;  // rutuja 2832 changed declaration str to let for removing console warning
			this.setState({ customCompanyRevenueText: str }, function () {});
			//   if (str.charAt(0) === '|') { str = str.substr(1); }
			//   var str_array = str.split('|')
			//   this.state.companyRevenuetofrom = []
			//   this.state.customCompanyRevenueText = []
			//   for (var i = 0; i < str_array.length; i++) {
			//     if (str_array[i].includes("-")) {
			//       var array = str_array[i].split('-')
			//       this.state.companyRevenuetofrom.push({ 'companyRevenueID': i, 'from': array[0], 'to': array[1] })
			//     }
			//     else {

			//       this.state.customCompanyRevenueText.push({ 'companyRevenueCustomID': i, 'customCompanyRevenueText': str_array[i] })
			//     }
			//   }
		}
	}
	//countryRegion Ends

	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {

			//sonali-3716-VAPT
			if (this.props.location.state !== undefined) {
				const {
					rfpCampaignID,
					status,
					from
				} = this.props.location.state;
				this.setState({rfpCampaignID,status,fromScreen:from})
			}
		
			//const { user } = this.props.auth; //rutuja 2832
			//var agencyID = user.id; //rutuja 2832
			// var rfpCampaignID,status,fromScreen;
			// var parsed = this.props.location.state; //karan-task-3684-replace query params
			// if (parsed !== undefined) {
			// 	rfpCampaignID = parsed.rfpCampaignID;
			// 	status = parsed.status; //snehal-task-3509-Project's(DI) - Create RFP through XLS - FE
			// 	fromScreen = parsed.from;
			// }

			//let self = this; //rutuja 2832
			// self.setState({rfpCampaignID:rfpCampaignID})
			//snehal-task-3509-Project's(DI) - Create RFP through XLS - FE
			// self.setState(
			// 	{
			// 		rfpCampaignID: rfpCampaignID,
			// 		agencyID: agencyID,
			// 		status: status,
			// 		fromScreen,
			// 	},
			// 	function () {}
			// );
			//{/* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE */}
		
			fetch("/campaign/clientNameDetails") // karan-task-3767-added default header
				.then((res) => res.json())
				.then((clientNameDetails) => {
					this.setState({ clientNameDetails: clientNameDetails });
				}).catch((e)=>console.log(e));
			/**
			 * @author : Sandeep Dhawale
			 * @descrition : Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			 */
			if (this.props.location.state.fromScreen === "Client" && this.state.fromBack == "false") {
				//use local storage to set values

				var campaignName = localStorage.getItem("campaignName");
				if (campaignName !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"campaignName": campaignName, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				var campaignType = localStorage.getItem("campaignType");
				if (campaignType !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"campaignType": campaignType, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var clientName = localStorage.getItem("clientName");
				if (clientName !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0], 
						"clientName": clientName, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var biddingType = localStorage.getItem("biddingType");
				if (biddingType !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"biddingType": biddingType, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var durationType = localStorage.getItem("durationType");
				if (durationType !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"durationType": durationType, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				// else {
				// 	campaignStatus = "Active";
				// 	let BasicDetailArray = [...this.state.BasicDetailArray];
				// 	BasicDetailArray[0] = {
				// 		...BasicDetailArray[0],
				// 		["campaignStatus"]: campaignStatus,
				// 	};
				// 	this.state.BasicDetailArray = [...BasicDetailArray];
				// 	this.setState({ BasicDetailArray: BasicDetailArray });
				// }

				var startDate = localStorage.getItem("startDate");
				if (startDate !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"startDate": startDate, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var endDate = localStorage.getItem("endDate");
				if (endDate !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"endDate": endDate, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var biddingEndDate = localStorage.getItem("biddingEndDate");
				if (biddingEndDate !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"biddingEndDate": biddingEndDate, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var netCPL = localStorage.getItem("netCPL");
				if (netCPL !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"netCPL": netCPL, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var leadAllocation = localStorage.getItem("leadAllocation");
				if (leadAllocation !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"leadAllocation": leadAllocation, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var CPL = localStorage.getItem("CPL");
				if (CPL !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = { ...BasicDetailArray[0], "CPL" : CPL }; //rutuja 2832
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var rateCardCPL = localStorage.getItem("rateCardCPL");
				if (rateCardCPL !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"rateCardCPL": rateCardCPL, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				var audienceMatch = localStorage.getItem("audienceMatch");
				if (audienceMatch !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"audienceMatch": audienceMatch, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				var tal = localStorage.getItem("tal");
				if (tal !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = { ...BasicDetailArray[0], "tal": tal }; //rutuja 2832
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				var jobTitle = localStorage.getItem("jobTitle");
				if (jobTitle !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"jobTitle": jobTitle, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				var otherSpecs = localStorage.getItem("otherSpecs");
				if (otherSpecs !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"otherSpecs": otherSpecs, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				var publication = localStorage.getItem("publication");
				if (publication !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"publication": publication, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var employeeSize = localStorage.getItem("employeeSize");
				if (employeeSize !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"employeeSize": employeeSize, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].employeeSize
					) {
						var str = this.state.BasicDetailArray[0].employeeSize;
						var str_array = str.split(",");
						var employeeSizeArray = [];
						for (var i = 0; i < str_array.length; i++) {
							if (str_array[i] !== "") {
								employeeSizeArray.push({
									id: str_array[i],
									name: str_array[i],
								});
								this.state.employeeSizeDB.push(str_array[i]);
							}
						}
						this.setState({ employeeSize: employeeSizeArray });
					} //employee size end
				}

				var customEmpSize = localStorage.getItem("customemployeeSize");
				if (customEmpSize !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"customEmpSize": customEmpSize, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });

					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].customEmpSize
					) {
						let str = this.state.BasicDetailArray[0].customEmpSize;  // rutuja 2832 changed declaration str to let for removing console warning

						this.setState({ customEmployeeSizeText: str }, function () {});
					} //customEmployeeSize end
				}
				var jobLevel = localStorage.getItem("jobLevel");
				if (jobLevel !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"jobLevel": jobLevel, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].jobLevel
					) {
						let str = this.state.BasicDetailArray[0].jobLevel; // rutuja 2832 replacing declaration var to let
						let str_array = str.split(","); // rutuja 2832 replacing declaration var to let
						var jobLevelArray = [];
						//rutuja 2832 replaced var i with let i for removing console warning 
						for (let i = 0; i < str_array.length; i++) {
							if (str_array[i] !== "") {
								jobLevelArray.push({ id: str_array[i], name: str_array[i] });
								this.state.jobLevelDB.push(str_array[i]);
							}
						}
						this.setState({ jobLevel: jobLevelArray });
					} //Job level End
				}

				var customJobLevel = localStorage.getItem("customjobLevel");
				if (customJobLevel !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"customJobLevel": customJobLevel, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].customJobLevel
					) {
						let str = this.state.BasicDetailArray[0].customJobLevel;  // rutuja 2832 changed declaration str to let for removing console warning
						this.setState({ customJobLevelText: str }, function () {});
					} //custom job level
				}

				var companyRevenue = localStorage.getItem("companyRevenue");
				if (companyRevenue !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"companyRevenue": companyRevenue, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].companyRevenue
					) {
						let str = this.state.BasicDetailArray[0].companyRevenue;// rutuja 2832 replacing declaration var to let
						let str_array = str.split(",");// rutuja 2832 replacing declaration var to let
						let companyRevenue = [];  // rutuja 2832 changed declaration companyRevenue to let for removing console warning
						//rutuja 2832 replaced var i with let i for removing console warning 
						for (let i = 0; i < str_array.length; i++) {
							if (str_array[i] !== "") {
								companyRevenue.push({ id: str_array[i], name: str_array[i] });
								this.state.companyRevenueDB.push(str_array[i]);
							}
						}
						this.setState({ companyRevenue: companyRevenue }, function () {});
					} //Company Revenue End
				}

				var customCompRevenue = localStorage.getItem("customCompanyRevenue");
				if (customCompRevenue !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"customCompRevenue": customCompRevenue, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });

					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].customCompRevenue
					) {
						let str = this.state.BasicDetailArray[0].customCompRevenue;  // rutuja 2832 changed declaration of  var str to let for removing console warning
						this.setState({ customCompanyRevenueText: str }, function () {});
					}
				}

				var jobFunction = localStorage.getItem("jobFunction");
				if (jobFunction !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"jobFunction": jobFunction, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].jobFunction
					) {
						let str = this.state.BasicDetailArray[0].jobFunction; // rutuja 2832 replacing declaration var to let
						let str_array = str.split(",");// rutuja 2832 replacing declaration var to let
						var jobFunctionArray = [];
						//rutuja 2832 replaced var i with let i for removing console warning 
						for (let i = 0; i < str_array.length; i++) {
							if (str_array[i] !== "") {
								jobFunctionArray.push({ id: str_array[i], name: str_array[i] });
								this.state.jobFunctionDB.push(str_array[i]);
							}
						}
						this.setState({ jobFunction: jobFunctionArray });
					} //Job function End
				}
				var customJobFunction = localStorage.getItem("customJobFunction");
				if (customJobFunction !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"customJobFunction": customJobFunction, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });

					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].customJobFunction
					) {
						let str = this.state.BasicDetailArray[0].customJobFunction; // rutuja 2832 changed declaration of  var str to let for removing console warning

						this.setState({ customJobFunctionText: str });
					} //custom job function end
				}
				var industry = localStorage.getItem("industry");
				if (industry !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"industry": industry, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });

					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].industry
					) {
						let str = this.state.BasicDetailArray[0].industry; // rutuja 2832 replacing declaration var to let
						let str_array = str.split(",");// rutuja 2832 replacing declaration var to let
						//var industry = []; //rutuja 2832
						//rutuja 2832 replaced var i with let i for removing console warning 
						for (let i = 0; i < str_array.length; i++) {
							if (str_array[i] !== "") {
								industry.push({ id: str_array[i], name: str_array[i] });
								this.state.industryDB.push(str_array[i]);
							}
						}
						this.setState({ industry: industry }, function () {});
					} //Industry End
				}

				var customIndustry = localStorage.getItem("customIndustryText");
				if (customIndustry !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"customIndustry": customIndustry, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });

					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].customIndustry
					) {
						let str = this.state.BasicDetailArray[0].customIndustry;  // rutuja 2832 changed declaration of  var str to let for removing console warning
						this.setState({ customIndustryText: str });
					} //custom industry end
				}
				var noOfCustomQuestions = localStorage.getItem("noOfCustomQuestions");
				if (noOfCustomQuestions !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"noOfCustomQuestions": noOfCustomQuestions, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				var currency = localStorage.getItem("currency");
				if (currency !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"currency": currency, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				} else {
					currency = "USD - US Dollar";
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"currency": currency, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
			}
			/**
			 * @author : Sandeep Dhawale
			 * @descrition : Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			 */
			if (this.props.location.state.fromScreen === "Client" && this.state.fromBack == "false") {
				//use local storage to set values

				let campaignName = localStorage.getItem("campaignName"); // rutuja 2832 changed declaration of  var campaignName to let for removing console warning
				if (campaignName !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"campaignName": campaignName, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				let campaignType = localStorage.getItem("campaignType"); // rutuja 2832 changed declaration of  var campaignType to let for removing console warning
				if (campaignType !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"campaignType": campaignType, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let clientName = localStorage.getItem("clientName"); // rutuja 2832 changed declaration of  var clientname to let for removing console warning
				if (clientName !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"clientName": clientName, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let biddingType = localStorage.getItem("biddingType"); // rutuja 2832 changed declaration of  var biddingType to let for removing console warning
				if (biddingType !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"biddingType": biddingType, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let durationType = localStorage.getItem("durationType"); // rutuja 2832 changed declaration of  var durationType to let for removing console warning
				if (durationType !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
					"durationType": durationType, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				// else {
				// 	campaignStatus = "Active";
				// 	let BasicDetailArray = [...this.state.BasicDetailArray];
				// 	BasicDetailArray[0] = {
				// 		...BasicDetailArray[0],
				// 		["campaignStatus"]: campaignStatus,
				// 	};
				// 	this.state.BasicDetailArray = [...BasicDetailArray];
				// 	this.setState({ BasicDetailArray: BasicDetailArray });
				// }

				let startDate = localStorage.getItem("startDate"); // rutuja 2832 changed declaration of  var startdate to let for removing console warning
				if (startDate !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"startDate": startDate, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let endDate = localStorage.getItem("endDate"); // rutuja 2832 changed declaration of  var endDate to let for removing console warning
				if (endDate !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"endDate": endDate, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let biddingEndDate = localStorage.getItem("biddingEndDate");// rutuja 2832 changed declaration of  var biddingDate to let for removing console warning
				if (biddingEndDate !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"biddingEndDate": biddingEndDate, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let netCPL = localStorage.getItem("netCPL"); // rutuja 2832 changed declaration of  var netCPL to let for removing console warning
				if (netCPL !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"netCPL": netCPL, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let leadAllocation = localStorage.getItem("leadAllocation"); // rutuja 2832 changed declaration of  var leadAllocation to let for removing console warning
				if (leadAllocation !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"leadAllocation": leadAllocation, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let CPL = localStorage.getItem("CPL"); // rutuja 2832 changed declaration of  var cpl to let for removing console warning
				if (CPL !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = { ...BasicDetailArray[0], "CPL": CPL }; //rutuja 2832
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let rateCardCPL = localStorage.getItem("rateCardCPL"); // rutuja 2832 changed declaration of  var ratecardcpl to let for removing console warning
				if (rateCardCPL !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"rateCardCPL": rateCardCPL, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				let audienceMatch = localStorage.getItem("audienceMatch"); // rutuja 2832 changed declaration of  var audienceMatch to let for removing console warning
				if (audienceMatch !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"audienceMatch": audienceMatch, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				let tal = localStorage.getItem("tal"); // rutuja 2832 changed declaration of  var tal to let for removing console warning
				if (tal !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = { ...BasicDetailArray[0], "tal": tal }; //rutuja 2832
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				let jobTitle = localStorage.getItem("jobTitle"); //rutuja 2832
				if (jobTitle !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"jobTitle": jobTitle, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				let otherSpecs = localStorage.getItem("otherSpecs"); // rutuja 2832 changed declaration of  var otherspecs to let for removing console warning
				if (otherSpecs !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"otherSpecs": otherSpecs, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
				let publication = localStorage.getItem("publication"); // rutuja 2832 changed declaration of  var publication to let for removing console warning
				if (publication !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"publication": publication, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let employeeSize = localStorage.getItem("employeeSize"); // rutuja 2832 changed declaration of  var employeesize to let for removing console warning
				if (employeeSize !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"employeeSize": employeeSize, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].employeeSize
					) {
						let str = this.state.BasicDetailArray[0].employeeSize;//rutuja 2832 replacing declaration var to let
						let str_array = str.split(","); //rutuja 2832 commenting str_array as already declaredreplacing declaration var to let
						//var employeeSizeArray = []; //rutuja 2832 commenting employeeSizeArray as already declared
						//rutuja 2832 replaced var i with let i for removing console warning 
						for (let i = 0; i < str_array.length; i++) {
							if (str_array[i] !== "") {
								employeeSizeArray.push({
									id: str_array[i],
									name: str_array[i],
								});
								this.state.employeeSizeDB.push(str_array[i]);
							}
						}
						this.setState({ employeeSize: employeeSizeArray });
					} //employee size end
				}

				let customEmpSize = localStorage.getItem("customemployeeSize"); //// rutuja 2832 changed declaration of  var customemployeeSize to let for removing console warning
				if (customEmpSize !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"customEmpSize": customEmpSize, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });

					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].customEmpSize
					) {
						let str = this.state.BasicDetailArray[0].customEmpSize; // rutuja 2832 changed declaration of  var customempsize to let for removing console warning

						this.setState({ customEmployeeSizeText: str }, function () {});
					} //customEmployeeSize end
				}
				let jobLevel = localStorage.getItem("jobLevel"); //rutuja 2832
				if (jobLevel !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"jobLevel": jobLevel, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].jobLevel
					) {
						
						let str = this.state.BasicDetailArray[0].jobLevel;//rutuja 2832 replacing declaration var to let
						let str_array = str.split(","); // rutuja 2832 replacing declaration var to let 
						//var jobLevelArray = [];
						//rutuja 2832 replaced var i with let i for removing console warning 
						for (let i = 0; i < str_array.length; i++) {
							if (str_array[i] !== "") {
								jobLevelArray.push({ id: str_array[i], name: str_array[i] });
								this.state.jobLevelDB.push(str_array[i]);
							}
						}
						this.setState({ jobLevel: jobLevelArray });
					} //Job level End
				}

				let customJobLevel = localStorage.getItem("customjobLevel"); //// rutuja 2832 changed declaration of  var customjobLevel to let for removing console warning
				if (customJobLevel !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"customJobLevel": customJobLevel, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].customJobLevel
					) {
						let str = this.state.BasicDetailArray[0].customJobLevel; // rutuja 2832 changed declaration of  var customjobLevel to let for removing console warning
						this.setState({ customJobLevelText: str }, function () {});
					} //custom job level
				}

				let companyRevenue = localStorage.getItem("companyRevenue"); //rutuja 2832
				if (companyRevenue !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"companyRevenue": companyRevenue, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].companyRevenue
					) {
						
						let str = this.state.BasicDetailArray[0].companyRevenue; //rutuja 2832 replacing declaration var to let
						let str_array = str.split(","); // rutuja 2832 replacing declaration var to let
						//var companyRevenue = [];
							//rutuja 2832 replaced var i with let i for removing console warning
						for (let i = 0; i < str_array.length; i++) {
							if (str_array[i] !== "") {
								companyRevenue.push({ id: str_array[i], name: str_array[i] });
								this.state.companyRevenueDB.push(str_array[i]);
							}
						}
						this.setState({ companyRevenue: companyRevenue }, function () {});
					} //Company Revenue End
				}

				let customCompRevenue = localStorage.getItem("customCompanyRevenue"); //rutuja 2832
				if (customCompRevenue !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"customCompRevenue": customCompRevenue, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });

					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].customCompRevenue
					) {
						let str = this.state.BasicDetailArray[0].customCompRevenue;
						this.setState({ customCompanyRevenueText: str }, function () {});
					}
				}

				let jobFunction = localStorage.getItem("jobFunction"); //rutuja 2832
				if (jobFunction !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"jobFunction": jobFunction, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].jobFunction
					) {
						/* rutuja 2832
						var str = this.state.BasicDetailArray[0].jobFunction;
						var str_array = str.split(",");
						var jobFunctionArray = [];*/
						//rutuja 2832
						for (let i = 0; i < str_array.length; i++) {
							if (str_array[i] !== "") {
								jobFunctionArray.push({ id: str_array[i], name: str_array[i] });
								this.state.jobFunctionDB.push(str_array[i]);
							}
						}
						this.setState({ jobFunction: jobFunctionArray });
					} //Job function End
				}
				let customJobFunction = localStorage.getItem("customJobFunction"); //rutuja 2832
				if (customJobFunction !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"customJobFunction": customJobFunction, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });

					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].customJobFunction
					) {
						let str = this.state.BasicDetailArray[0].customJobFunction; // rutuja 2832 changed declaration of  var str to let for removing console warning

						this.setState({ customJobFunctionText: str });
					} //custom job function end
				}
				let industry = localStorage.getItem("industry"); //rutuja 2832
				if (industry !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"industry": industry, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });

					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].industry
					) {
						/* rutuja 2832
						var str = this.state.BasicDetailArray[0].industry;
						var str_array = str.split(",");
						//var industry = [];*/
						//rutuja 2832
						for (let i = 0; i < str_array.length; i++) {
							if (str_array[i] !== "") {
								industry.push({ id: str_array[i], name: str_array[i] });
								this.state.industryDB.push(str_array[i]);
							}
						}
						this.setState({ industry: industry }, function () {});
					} //Industry End
				}

				let customIndustry = localStorage.getItem("customIndustryText");// rutuja 2832 changed declaration of  var str to let for removing console warning
				if (customIndustry !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"customIndustry": customIndustry, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });

					if (
						this.state.BasicDetailArray &&
						this.state.BasicDetailArray.length &&
						this.state.BasicDetailArray[0].customIndustry
					) {
						let str = this.state.BasicDetailArray[0].customIndustry; //rutuja 2832
						this.setState({ customIndustryText: str });
					} //custom industry end
				}
				let  noOfCustomQuestions = localStorage.getItem("noOfCustomQuestions"); // rutuja 2832 changed declaration of  var str to let for removing console warning
				if (noOfCustomQuestions !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"noOfCustomQuestions": noOfCustomQuestions, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}

				let currency = localStorage.getItem("currency"); //rutuja 2832
				if (currency !== null) {
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"currency": currency, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				} else {
					currency = "USD - US Dollar";
					let BasicDetailArray = [...this.state.BasicDetailArray];
					BasicDetailArray[0] = {
						...BasicDetailArray[0],
						"currency": currency, //rutuja 2832
					};
					this.state.BasicDetailArray = [...BasicDetailArray];
					this.setState({ BasicDetailArray: BasicDetailArray });
				}
			}
			var rfpCampaignID=this.props.location.state.rfpCampaignID;//sonali=3716-VAPT-accessing ID from location.state
			if (rfpCampaignID) {
				let data = {
					rfpCampaignID: rfpCampaignID,
				};
				fetch("requestForProposal/editRfpCampaignNewInfo", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((rfpCampaignDetails) => {
						this.setState(
							{
								BasicDetailArray: rfpCampaignDetails,
								rfpId: rfpCampaignDetails[0].campID,
							},
							function () {
								this.handleStepThree();
							}
						);
					})
					.catch(function (err) {
						console.log(err);
					});

				fetch("requestForProposal/editSupportingDocInfoRFP", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((editSupportingDocInfoRFP) => {
						//  alert("editSupportingDocInfoRFP===>"+JSON.stringify(editSupportingDocInfoRFP));
						this.setState(
							{ editSupportingDocInfoRFP: editSupportingDocInfoRFP },
							function () {
								var abmFiles = [];
								var suppFiles = [];
								var exclFiles = [];
								var otherFiles = [];
								let abmFilesSelectedDocumentsTemp = [];
								let exclusionFilesSelectedDocumentsTemp = [];
								let suppressionFilesSelectedDocumentsTemp = [];
								let otherFilesSelectedDocumentsTemp = [];
								for (
									let i = 0;
									i < this.state.editSupportingDocInfoRFP.length;
									i++
								) { // rutuja 2832 replace == with === 
									if (this.state.editSupportingDocInfoRFP[i].type === "ABM") {
										abmFiles.push(this.state.editSupportingDocInfoRFP[i]);
										// User Story - 3411 - Karan Jagtap - modifying the array as per required by UI
										abmFilesSelectedDocumentsTemp.push({
											...this.state.editSupportingDocInfoRFP[i],
											success: true,
											loading: false,
										});
									}
									// rutuja 2832 replace == with === 
									if (
										this.state.editSupportingDocInfoRFP[i].type === "Suppression"
									) {
										suppFiles.push(this.state.editSupportingDocInfoRFP[i]);
										// User Story - 3411 - Karan Jagtap - modifying the array as per required by UI
										suppressionFilesSelectedDocumentsTemp.push({
											...this.state.editSupportingDocInfoRFP[i],
											success: true,
											loading: false,
										});
									}
									// rutuja 2832 replace == with === 
									if (
										this.state.editSupportingDocInfoRFP[i].type === "Exclusion"
									) {
										exclFiles.push(this.state.editSupportingDocInfoRFP[i]);
										// User Story - 3411 - Karan Jagtap - modifying the array as per required by UI
										exclusionFilesSelectedDocumentsTemp.push({
											...this.state.editSupportingDocInfoRFP[i],
											success: true,
											loading: false,
										});
									}
									// rutuja 2832 replace == with === 
									if (this.state.editSupportingDocInfoRFP[i].type === "Other") {
										otherFiles.push(this.state.editSupportingDocInfoRFP[i]);
										// User Story - 3411 - Karan Jagtap - modifying the array as per required by UI
										otherFilesSelectedDocumentsTemp.push({
											...this.state.editSupportingDocInfoRFP[i],
											success: true,
											loading: false,
										});
									}
								}

								if (abmFiles.length > 0) {
									for (let i = 0; i < abmFiles.length; i++) {
										duplicateABMFile.push(abmFiles[i].suppDocName);
										abmnames[abmFiles[i].suppDocName] = i;
									}
								}
								if (suppFiles.length > 0) {
									for (let i = 0; i < suppFiles.length; i++) {
										duplicateSuppressionFile.push(suppFiles[i].suppDocName);
										supprnames[suppFiles[i].suppDocName] = i;
									}
								}

								if (exclFiles.length > 0) {
									for (let i = 0; i < exclFiles.length; i++) {
										duplicateExclusionFile.push(exclFiles[i].suppDocName);
										exclnames[exclFiles[i].suppDocName] = i;
									}
								}
								if (otherFiles.length > 0) {
									for (let i = 0; i < otherFiles.length; i++) {
										duplicateOtherFile.push(otherFiles[i].suppDocName);
									}
								}

								// User Story - 3411 - Karan Jagtap - setting the state
								this.setState({
									abmFilesSelectedDocuments: abmFilesSelectedDocumentsTemp,
									exclusionFilesSelectedDocuments:
										exclusionFilesSelectedDocumentsTemp,
									suppressionFilesSelectedDocuments:
										suppressionFilesSelectedDocumentsTemp,
									otherFilesSelectedDocuments: otherFilesSelectedDocumentsTemp,
								});
							}
						);
					})
					.catch(function (err) {
						console.log(err);
					});

				//sunita-task-3645- api added here for getting country wise lead allocation data
				let proposalID = this.props.location.state.rfpCampaignID;//sonali-4030-getting ID from props
				
				//  alert("proposalID=>"+proposalID);
				fetch(
					"requestForProposal/getCountryWiseLeadForRFP?proposalID=" + proposalID
				)
					.then((res) => res.json())
					.then((tempCountryAllocation) => {
						//  alert("countryAllocationwillmount=>"+JSON.stringify(tempCountryAllocation));

						//  let geoLeadChk = rfpCampaignDetails[0].countrywiseLeadAllocation;
						//snehal-task-3793-geowise lead checkbox issue
						if (
							tempCountryAllocation.length > 0 &&
							this.state.BasicDetailArray[0].countrywiseLeadAllocation === "Yes"
						) {
							//  alert("inside yes geoleadchk");
							this.setState({
								geoLeadDisplay: "block",
								geoLeadChk:
									this.state.BasicDetailArray[0].countrywiseLeadAllocation,
								isCheckedGeoLead: true,
							}); //sunita-task-3645-setstate the values as per requirement
						} else {
							//  alert("inside no geoleadchk");
							this.setState({
								geoLeadDisplay: "none",
								geoLeadChk:
									this.state.BasicDetailArray[0].countrywiseLeadAllocation,
								isCheckedGeoLead: false,
							});
						}
						this.setState({
							tempCountryAllocation: tempCountryAllocation,
							countryWiseAllocationArray: tempCountryAllocation,
						});
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		}
	}
	
	componentDidMount() {
		for (
			let i = 0;
			i <
			document.getElementById("myCountryDiv").getElementsByTagName("span")
				.length;
			i++
		) {
			if (
				document.getElementById("myCountryDiv").getElementsByTagName("span")[i]
					.className === ""
			) {
				if (
					document
						.getElementById("myCountryDiv")
						.getElementsByTagName("span")
						[i].innerText.toLowerCase() != "select all"
				) {
					this.state.spanlist[
						document
							.getElementById("myCountryDiv")
							.getElementsByTagName("span")
							[i].innerText.toLowerCase()
					] = [
						document
							.getElementById("myCountryDiv")
							.getElementsByTagName("span")
							[i].innerText.toLowerCase(),
						document
							.getElementById("myCountryDiv")
							.getElementsByTagName("span")[i],
					];
				}
			}
		}
	}
	customIndustryTextChange(e) {
		const {  value } = e.target; //rutuja 2832 removed name as never used in the file
		document.getElementById("customIndustryText").style.border =
			"1px solid #ccc"; //Sandeep-task-3396-error msg issue
		localStorage.setItem("customIndustryText", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
		this.setState({
			customIndustryText: value,
			inputClassIndustry: "validate",
			customIndustryTextDisplay: "none",
			industryDisplay: "none",
		});
	}

	customJobLevelTextChange(e) {
		const { value } = e.target; //rutuja 2832 removed name as never used in the file
		document.getElementById("customjobLevelText").style.border =
			"1px solid #ccc"; //Sandeep-task-3396-error msg issue
		localStorage.setItem("customjobLevel", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
		this.setState({ customJobLevelText: value, customJobLevelDisplay: "none" });
	}

	customJobFunctionTextChange(e) {
		const { value } = e.target;//rutuja 2832 removed name as never used in the file
		document.getElementById("customjobFunctionText").style.border =
			"1px solid #ccc"; //Sandeep-task-3396-error msg issue
		localStorage.setItem("customJobFunction", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
		this.setState({
			customJobFunctionText: value,
			customJobFunctionDisplay: "none",
		});
	}

	customCompanyRevenueTextChange1(e) {
		const {  value } = e.target; //rutuja 2832 removed name as never used in the file
		document.getElementById("customcompanyRevenueText").style.border =
			"1px solid #ccc"; //Sandeep-task-3396-error msg issue
		localStorage.setItem("customCompanyRevenue", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
		this.setState({
			customCompanyRevenueText: value,
			customCompanyRevenueTextDisplay: "none",
		});
	}

	customEmployeeSizeTextChange1(e) {
		const { value } = e.target;//rutuja 2832 removed name as never used in the file
		document.getElementById("customemployeeSizeText").style.border =
			"1px solid #ccc"; //Sandeep-task-3396-error msg issue
		localStorage.setItem("customemployeeSize", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
		this.setState({
			customEmployeeSizeText: value,
			customEmployeeSizeTextDisplay: "none",
		});
	}
	jobLevelHandleChange = (value) => {
		//console.log(`Option selected:`, value);
		var jobLevelArray = [];
		//const name = "jobLevel";//rutuja 2832 commented name as never used in the file
		for (var j = 0; j < value.length; j++) {
			jobLevelArray.push(value[j].name);
		}
		localStorage.setItem("jobLevel", jobLevelArray); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
		this.setState({ jobLevel: value, jobLevelDB: jobLevelArray });
	};
	jobFunctionHandleChange = (value) => {
		var jobFunctionArray = [];
		//const name = "jobFunction"; // rutuja 2832 changed declaration of  var str to let for removing console warning
		for (var j = 0; j < value.length; j++) {
			jobFunctionArray.push(value[j].name);
		}
		localStorage.setItem("jobFunction", jobFunctionArray); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
		this.setState({ jobFunction: value, jobFunctionDB: jobFunctionArray });
	};
	handleChangeMultipleFileOfABM(e) {
		e.preventDefault();
		//Somnath Task-3936, Get value from props
		var rfpCampaignID;
			var parsed = this.props.location.state;
			if (parsed !== undefined) {
				rfpCampaignID = parsed.rfpCampaignID;
			}
		var data = new FormData();
		var notToBeshown = {};
		let abmError = {};
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener(
			"change",
			function () {
				//calling an eventListener at change event added by Raunak

				let filesData = e.files;

				var allowedFiles = [".xls", ".xlsx", ".csv"];
				var regex = new RegExp(
					"([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
				);
				for (let i = 0, l = filesData.length; i < l; i++) {
					if (!regex.test(filesData[i].name)) {
						// document.getElementById('abmFileUpload').value=null
						this.setState({ abmFileError: "block" });
						return;
					}
				}

				let abmFilesSelectedDocumentsTemp = [
					...this.state.abmFilesSelectedDocuments,
				];
				for (let i = 0, l = filesData.length; i < l; i++) {
					let fileName = filesData[i].name;
					if (fileName in abmnames) {
					} else {
						data.append("file", filesData[i]);
						abmFilesSelectedDocumentsTemp.push({
							success: true,
							loading: true,
							supportDocID: "",
							suppDocName: filesData[i].name,
							campID: this.state.rfpCampaignID || "",
							type: "ABM",
						});
					}
				}
				// User Story - 3411 - Karan Jagtap
				this.setState({
					abmFilesSelectedDocuments: abmFilesSelectedDocumentsTemp,
				});
				if (
					rfpCampaignID !== undefined ||
					rfpCampaignID !== "undefined" ||
					rfpCampaignID !== null ||
					rfpCampaignID !== "null" ||
					rfpCampaignID !== ""
				) {
					data.append("rfpCampaignID", rfpCampaignID);
				}
				data.append("fromRFP", this.state.fromRFP); //Sonali-3338-if we go on supp doc routes from rfp page then this flag will identify

				fetch("campaign/supportingDocumentABM", {
					method: "POST",
					body: data,
				})
					.then((res) => res.json())
					.then((otherList) => {
						// Bug - 3338 - Karan Jagtap - Start
						// Here, i've changed multiple lines hence commentting as a block comment
						// after route change the response array format was different,
						// hence, making the format FE compatible
						let fileDetailsTemp = otherList.map((obj) => {
							return { suppDocID: obj.supportDocID, filename: obj.fileName };
						});
						fileDetailsForABM.push(fileDetailsTemp);
						for (var i = 0; i < otherList.length; i++) {
							let docNumberSingle = otherList[i].supportDocID;
							if (
								docNumberSingle !== null &&
								docNumberSingle !== undefined &&
								docNumberSingle !== ""
							) {
								docNumber.push(docNumberSingle);
							}
						}
						this.setState({
							fileDetailsForABM: fileDetailsForABM,
							docNumber: docNumber,
							abmFileError: "none",
						});
						if (otherList.length > 0) {
							let containsFailedFile = false;
							let rejectedFileNames = [];
							let found = false;
							for (let i = 0; i < otherList.length; i++) {
								if (!otherList[i].success) {
									notToBeshown[otherList[i].fileName] = 0;
									containsFailedFile = true;
									rejectedFileNames.push(otherList[i].fileName);
								}
							}
							if (containsFailedFile) {
								var responses = rejectedFileNames;
								for (let i = 0; i < responses.length; i++) {
									notToBeshown[responses[i]] = i;
									if (this.state.invalidHeaderFileABM.includes(responses[i])) {
										found = true;
									}
								}
							}
							//leng = Object.keys(notToBeshown).length - 1;
							// Bug - 3338 - Karan Jagtap - maintaining the error file names in state for management
							if (!found) {
								this.setState({
									invalidHeaderFileABM: [
										...this.state.invalidHeaderFileABM,
										...rejectedFileNames,
									],
								});
							}
						}
						// Bug - 3338 - Karan Jagtap - End

						let abmFilesSelectedDocumentsTemp = [
							...this.state.abmFilesSelectedDocuments,
						];
						for (let i = 0, l = filesData.length; i < l; i++) {
							if (filesData[i].name in notToBeshown) {
								abmError["headerError"] = "Please Upload Valid Header File";
								this.setState({ abmError: abmError });

								// User Story - 3411 - Karan Jagtap - modifying the array as per required by the UI
								let supportDocIDTemp = "";
								let foundObj = fileDetailsTemp.find(
									(obj) => obj.filename === filesData[i].name
								);
								if (foundObj !== undefined && foundObj !== null) {
									supportDocIDTemp = foundObj.suppDocID;
								}
								let foundIndex = abmFilesSelectedDocumentsTemp.findIndex(
									(obj) => obj.suppDocName === filesData[i].name
								);
								abmFilesSelectedDocumentsTemp[foundIndex] = {
									success: false,
									loading: false,
									supportDocID: supportDocIDTemp,
									suppDocName: filesData[i].name,
									campID: this.state.rfpCampaignID || "",
									type: "ABM",
								};
							} else {
								if (filesData[i].name in abmnames) {
								} else {
									// User Story - 3411 - Karan Jagtap - modifying the array as per required by the UI
									let supportDocIDTemp = "";
									let foundObj = fileDetailsTemp.find(
										(obj) => obj.filename === filesData[i].name
									);
									if (foundObj !== undefined && foundObj !== null) {
										supportDocIDTemp = foundObj.suppDocID;
									}
									let foundIndex = abmFilesSelectedDocumentsTemp.findIndex(
										(obj) => obj.suppDocName === filesData[i].name
									);
									abmFilesSelectedDocumentsTemp[foundIndex] = {
										success: true,
										loading: false,
										supportDocID: supportDocIDTemp,
										suppDocName: filesData[i].name,
										campID: this.state.rfpCampaignID || "",
										type: "ABM",
									};
								}
							}
						}
						// User Story - 3411 - Karan Jagtap
						this.setState({
							abmFilesSelectedDocuments: abmFilesSelectedDocumentsTemp,
						});
						for (let i = 0, l = filesData.length; i < l; i++) {
							duplicateABMFile.push(filesData[i].name);
							abmnames[filesData[i].name] = i;
						}
					})
					.catch((error) => {
						console.error("Error:", error);
					});
			}.bind(this)
		);
	}

	/**
	 * @author : Karan Jagtap
	 * @descrition : Fired when clicked on remove icon of individual file
	 */
	removeABMFile = (e, item) => {
		e.preventDefault();
		let abmError = {};

		var clickedFile = item.suppDocName;
		let found = this.state.invalidHeaderFileABM.includes(clickedFile);
		if (found) {
			if (this.state.invalidHeaderFileABM.length - 1 === 0) {
				abmError["headerError"] = "";
				this.setState({ invalidHeaderFileABM: [], abmError: abmError });
			} else {
				this.setState({
					invalidHeaderFileABM: [
						...this.state.invalidHeaderFileABM.filter(
							(obj) => obj !== clickedFile
						),
					],
				});
			}
		}

		//added by sonali to get the docID of document to be deleted
		var suppDocID;
		var finalID;
		if (this.state.fileDetailsForABM[0]) {
			for (var j = 0; j < this.state.fileDetailsForABM[0].length; j++) {
				if (this.state.fileDetailsForABM[0][j].filename === clickedFile) {
					suppDocID = this.state.fileDetailsForABM[0][j].suppDocID;
					finalID = suppDocID;
				}
			}
		}
		// User Story - 3411 - Karan Jagtap - removing the file from UI
		this.setState({
			abmFilesSelectedDocuments: [
				...this.state.abmFilesSelectedDocuments.filter(
					(obj) => obj.suppDocName !== clickedFile
				),
			],
		});
		var indx = duplicateABMFile.indexOf(clickedFile);
		if (indx > -1) {
			duplicateABMFile.splice(indx, 1);
			delete abmnames[clickedFile];
		}
		// Bug - 3338 - Karan Jagtap - if this is empty means file upload failed and no need to delete it from db
		// hence not calling the below delete route
		if (finalID === undefined) {
			finalID = item.supportDocID;
		}
		let data = {
			campID: this.state.rfpCampaignID,
			names: clickedFile,
			finalID: finalID,
		};
		fetch("agencyDashboard/deleteRFPDocument", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}).catch((e)=>console.log(e));//Somnath Task-3936, Add Catch block
	};

	handleChangeMultipleFileOfExclusion(e) {
		e.preventDefault();
		//Somnath Task-3936, Get value from props
		var rfpCampaignID;
			var parsed = this.props.location.state;
			if (parsed !== undefined) {
				rfpCampaignID = parsed.rfpCampaignID;
			}
		var data = new FormData();
		var notToBeshown = {};
		let exclusionError = {};
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener(
			"change",
			function () {
				//calling an eventListener at change event added by Raunak
				let filesData = e.files;

				var allowedFiles = [".xls", ".xlsx", ".csv"];
				var regex = new RegExp(
					"([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
				);
				for (let i = 0, l = filesData.length; i < l; i++) {
					if (!regex.test(filesData[i].name.toLowerCase())) {
						//document.getElementById("exclusionFileUpload").value = null;
						this.setState({ exclusionFileError: "block" });
						return;
					}
				}

				let exclusionFilesSelectedDocumentsTemp = [
					...this.state.exclusionFilesSelectedDocuments,
				];
				for (let i = 0, l = filesData.length; i < l; i++) {
					let fileName = filesData[i].name;
					if (fileName in exclnames) {
					} else {
						data.append("file", filesData[i]);
						exclusionFilesSelectedDocumentsTemp.push({
							success: true,
							loading: true,
							supportDocID: "",
							suppDocName: filesData[i].name,
							campID: this.state.rfpCampaignID || "",
							type: "Exclusion",
						});
					}
				}

				this.setState({
					exclusionFilesSelectedDocuments: exclusionFilesSelectedDocumentsTemp,
				});

				if (
					rfpCampaignID !== undefined ||
					rfpCampaignID !== "undefined" ||
					rfpCampaignID !== null ||
					rfpCampaignID !== "null" ||
					rfpCampaignID !== ""
				) {
					data.append("rfpCampaignID", rfpCampaignID);
				}
				data.append("fromRFP", this.state.fromRFP); //Sonali-3338-if we go on supp doc routes from rfp page then this flag will identify

				fetch("campaign/supportingDocumentExclusion", {
					method: "POST",
					body: data,
				})
					.then((res) => res.json())
					.then((otherList) => {
						// Bug - 3338 - Karan Jagtap - Start
						// Here, i've changed multiple lines hence commentting as a block comment
						// after route change the response array format was different,
						// hence, making the format FE compatible
						let fileDetailsTemp = otherList.map((obj) => {
							return { suppDocID: obj.supportDocID, filename: obj.fileName };
						});
						fileDetailsForExclusion.push(fileDetailsTemp);
						for (var i = 0; i < otherList.length; i++) {
							let docNumberSingle = otherList[i].supportDocID;
							if (
								docNumberSingle !== null &&
								docNumberSingle !== undefined &&
								docNumberSingle !== ""
							) {
								docNumber.push(docNumberSingle);
							}
						}
						this.setState({
							fileDetailsForExclusion: fileDetailsForExclusion,
							docNumber: docNumber,
							loading: "",
							exclusionFileError: "none",
						});
						if (otherList.length > 0) {
							let containsFailedFile = false;
							let rejectedFileNames = [];
							let found = false;
							for (let i = 0; i < otherList.length; i++) {
								if (!otherList[i].success) {
									notToBeshown[otherList[i].fileName] = 0;
									containsFailedFile = true;
									rejectedFileNames.push(otherList[i].fileName);
								}
							}
							if (containsFailedFile) {
								var responses = rejectedFileNames;
								for (let i = 0; i < responses.length; i++) {
									notToBeshown[responses[i]] = i;
									if (
										this.state.invalidHeaderFileExclusion.includes(responses[i])
									) {
										found = true;
									}
								}
							}
							// Bug - 3338 - Karan Jagtap - maintaining the error file names in state for management
							if (!found) {
								this.setState({
									invalidHeaderFileExclusion: [
										...this.state.invalidHeaderFileExclusion,
										...rejectedFileNames,
									],
								});
							}
						}
						// Bug - 3338 - Karan Jagtap - End

						let exclusionFilesSelectedDocumentsTemp = [
							...this.state.exclusionFilesSelectedDocuments,
						];
						for (let i = 0, l = filesData.length; i < l; i++) {
							if (filesData[i].name in notToBeshown) {
								exclusionError["headerError"] =
									"Please Upload Valid Header File";
								this.setState({ exclusionError: exclusionError });
								// User Story - 3411 - Karan Jagtap - modifying the array as per required by the UI
								let supportDocIDTemp = "";
								let foundObj = fileDetailsTemp.find(
									(obj) => obj.filename === filesData[i].name
								);
								if (foundObj !== undefined && foundObj !== null) {
									supportDocIDTemp = foundObj.suppDocID;
								}
								let foundIndex = exclusionFilesSelectedDocumentsTemp.findIndex(
									(obj) => obj.suppDocName === filesData[i].name
								);
								exclusionFilesSelectedDocumentsTemp[foundIndex] = {
									success: false,
									loading: false,
									supportDocID: supportDocIDTemp,
									suppDocName: filesData[i].name,
									campID: this.state.rfpCampaignID || "",
									type: "Exclusion",
								};
							} else {
								if (filesData[i].name in exclnames) {
								} else {
									// User Story - 3411 - Karan Jagtap - modifying the array as per required by the UI
									let supportDocIDTemp = "";
									let foundObj = fileDetailsTemp.find(
										(obj) => obj.filename === filesData[i].name
									);
									if (foundObj !== undefined && foundObj !== null) {
										supportDocIDTemp = foundObj.suppDocID;
									}
									let foundIndex =
										exclusionFilesSelectedDocumentsTemp.findIndex(
											(obj) => obj.suppDocName === filesData[i].name
										);
									exclusionFilesSelectedDocumentsTemp[foundIndex] = {
										success: true,
										loading: false,
										supportDocID: supportDocIDTemp,
										suppDocName: filesData[i].name,
										campID: this.state.rfpCampaignID || "",
										type: "Exclusion",
									};
								}
							}
						}
						// User Story - 3411 - Karan Jagtap
						this.setState({
							exclusionFilesSelectedDocuments:
								exclusionFilesSelectedDocumentsTemp,
						});
						for (let i = 0, l = filesData.length; i < l; i++) {
							duplicateExclusionFile.push(filesData[i].name);
							exclnames[filesData[i].name] = i;
						}
					})
					.catch((error) => {
						console.error("Error:", error);
					});
			}.bind(this)
		);
	}

	/**
	 * @author : Karan Jagtap
	 * @descrition : Fired when clicked on remove icon of individual file
	 */
	removeExclusionFile = (e, item) => {
		e.preventDefault();
		let exclusionError = {};
		var clickedFile = item.suppDocName;
		let found = this.state.invalidHeaderFileExclusion.includes(clickedFile);
		if (found) {
			if (this.state.invalidHeaderFileExclusion.length - 1 === 0) {
				exclusionError["headerError"] = "";
				this.setState({
					invalidHeaderFileExclusion: [],
					exclusionError: exclusionError,
				});
			} else {
				this.setState({
					invalidHeaderFileExclusion: [
						...this.state.invalidHeaderFileExclusion.filter(
							(obj) => obj !== clickedFile
						),
					],
				});
			}
		}

		//added by sonali to get the docID of document to be deleted
		var suppDocID;
		var finalID;
		if (this.state.fileDetailsForExclusion[0]) {
			for (var j = 0; j < this.state.fileDetailsForExclusion[0].length; j++) {
				if (this.state.fileDetailsForExclusion[0][j].filename === clickedFile) {
					suppDocID = this.state.fileDetailsForExclusion[0][j].suppDocID;
					finalID = suppDocID;
				}
			}
		}
		// User Story - 3411 - Karan Jagtap - removing the file from UI
		this.setState({
			exclusionFilesSelectedDocuments: [
				...this.state.exclusionFilesSelectedDocuments.filter(
					(obj) => obj.suppDocName !== clickedFile
				),
			],
		});
		var indx = duplicateExclusionFile.indexOf(clickedFile);
		if (indx > -1) {
			duplicateExclusionFile.splice(indx, 1);
			delete exclnames[clickedFile];
		}

		// Bug - 3338 - Karan Jagtap - if this is empty means file upload failed and no need to delete it from db
		// hence not calling the below delete route
		if (finalID === undefined) {
			finalID = item.supportDocID;
		}
		let data = {
			campID: this.state.rfpCampaignID,
			names: clickedFile,
			finalID: finalID,
		};
		fetch("agencyDashboard/deleteRFPDocument", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		});
	};

	handleChangeMultipleFileOfSuppression(e) {
		e.preventDefault();
		//Somnath Task-3936, Get value from props
		var rfpCampaignID;
			var parsed = this.props.location.state;
			if (parsed !== undefined) {
				rfpCampaignID = parsed.rfpCampaignID;
			}
		var data = new FormData();
		var notToBeshown = {};
		let suppressionError = {};
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener(
			"change",
			function () {
				//calling an eventListener at change event added by Raunak
				let filesData = e.files;

				var allowedFiles = [".xls", ".xlsx", ".csv"];
				var regex = new RegExp(
					"([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
				);
				for (let i = 0, l = filesData.length; i < l; i++) {
					if (!regex.test(filesData[i].name.toLowerCase())) {
						//document.getElementById("suppressionFileUpload").value = null;
						this.setState({ suppressionFileError: "block" });
						return;
					}
				}

				let suppressionFilesSelectedDocumentsTemp = [
					...this.state.suppressionFilesSelectedDocuments,
				];
				for (let i = 0, l = filesData.length; i < l; i++) {
					let fileName = filesData[i].name;
					if (fileName in supprnames) {
					} else {
						data.append("file", filesData[i]);
						suppressionFilesSelectedDocumentsTemp.push({
							success: true,
							loading: true,
							supportDocID: "",
							suppDocName: filesData[i].name,
							campID: this.state.rfpCampaignID || "",
							type: "Suppression",
						});
					}
				}
				this.setState({
					suppressionFilesSelectedDocuments:
						suppressionFilesSelectedDocumentsTemp,
				});

				if (
					rfpCampaignID !== undefined ||
					rfpCampaignID !== "undefined" ||
					rfpCampaignID !== null ||
					rfpCampaignID !== "null" ||
					rfpCampaignID !== ""
				) {
					data.append("rfpCampaignID", rfpCampaignID);
				}
				data.append("fromRFP", this.state.fromRFP); //Sonali-3338-if we go on supp doc routes from rfp page then this flag will identify

				fetch("campaign/supportingDocumentSuppression", {
					method: "POST",
					body: data,
				})
					.then((res) => res.json())
					.then((otherList) => {
						// Bug - 3338 - Karan Jagtap - Start
						// Here, i've changed multiple lines hence commentting as a block comment
						// after route change the response array format was different,
						// hence, making the format FE compatible
						let fileDetailsTemp = otherList.map((obj) => {
							return { suppDocID: obj.supportDocID, filename: obj.fileName };
						});
						fileDetailsForSuppression.push(fileDetailsTemp);
						for (var i = 0; i < otherList.length; i++) {
							let docNumberSingle = otherList[i].supportDocID;
							if (
								docNumberSingle !== null &&
								docNumberSingle !== undefined &&
								docNumberSingle !== ""
							) {
								docNumber.push(docNumberSingle);
							}
						}
						this.setState({
							fileDetailsForSuppression: fileDetailsForSuppression,
							docNumber: docNumber,
							suppressionFileError: "none", //3438-karan-error message issue
						});
						if (otherList.length > 0) {
							let containsFailedFile = false;
							let rejectedFileNames = [];
							let found = false;
							for (let i = 0; i < otherList.length; i++) {
								if (!otherList[i].success) {
									notToBeshown[otherList[i].fileName] = 0;
									containsFailedFile = true;
									rejectedFileNames.push(otherList[i].fileName);
								}
							}
							if (containsFailedFile) {
								var responses = rejectedFileNames;
								for (let i = 0; i < responses.length; i++) {
									notToBeshown[responses[i]] = i;
									if (
										this.state.invalidHeaderFileSuppression.includes(
											responses[i]
										)
									) {
										found = true;
									}
								}
							}
							// Bug - 3338 - Karan Jagtap - maintaining the error file names in state for management
							if (!found) {
								this.setState({
									invalidHeaderFileSuppression: [
										...this.state.invalidHeaderFileSuppression,
										...rejectedFileNames,
									],
								});
							}
						}

						// Bug - 3338 - Karan Jagtap - End
						let suppressionFilesSelectedDocumentsTemp = [
							...this.state.suppressionFilesSelectedDocuments,
						];
						for (let i = 0, l = filesData.length; i < l; i++) {
							if (filesData[i].name in notToBeshown) {
								suppressionError["headerError"] =
									"Please Upload Valid Header File";
								this.setState({ suppressionerror: suppressionError });
								// User Story - 3411 - Karan Jagtap - modifying the array as per required by the UI
								let supportDocIDTemp = "";
								let foundObj = fileDetailsTemp.find(
									(obj) => obj.filename === filesData[i].name
								);
								if (foundObj !== undefined && foundObj !== null) {
									supportDocIDTemp = foundObj.suppDocID;
								}
								let foundIndex =
									suppressionFilesSelectedDocumentsTemp.findIndex(
										(obj) => obj.suppDocName === filesData[i].name
									);
								suppressionFilesSelectedDocumentsTemp[foundIndex] = {
									success: false,
									loading: false,
									supportDocID: supportDocIDTemp,
									suppDocName: filesData[i].name,
									campID: this.state.rfpCampaignID || "",
									type: "Suppression",
								};
							} else {
								if (filesData[i].name in supprnames) {
								} else {
									// User Story - 3411 - Karan Jagtap - modifying the array as per required by the UI
									let supportDocIDTemp = "";
									let foundObj = fileDetailsTemp.find(
										(obj) => obj.filename === filesData[i].name
									);
									if (foundObj !== undefined && foundObj !== null) {
										supportDocIDTemp = foundObj.suppDocID;
									}
									let foundIndex =
										suppressionFilesSelectedDocumentsTemp.findIndex(
											(obj) => obj.suppDocName === filesData[i].name
										);
									suppressionFilesSelectedDocumentsTemp[foundIndex] = {
										success: true,
										loading: false,
										supportDocID: supportDocIDTemp,
										suppDocName: filesData[i].name,
										campID: this.state.rfpCampaignID || "",
										type: "Suppression",
									};
								}
							}
						}
						// User Story - 3411 - Karan Jagtap
						this.setState({
							suppressionFilesSelectedDocuments:
								suppressionFilesSelectedDocumentsTemp,
						});
						for (let i = 0, l = filesData.length; i < l; i++) {
							duplicateSuppressionFile.push(filesData[i].name);
							supprnames[filesData[i].name] = i;
						}
					})
					.catch((error) => {
						console.error("Error:", error);
					});
			}.bind(this)
		);
	}


	/**
	 * @author : Karan Jagtap
	 * @descrition : Fired when clicked on remove icon of individual file
	 */
	removeSuppressionFile = (e, item) => {
		e.preventDefault();
		let suppressionError = {};
		var clickedFile = item.suppDocName;
		let found = this.state.invalidHeaderFileSuppression.includes(clickedFile);
		if (found) {
			if (this.state.invalidHeaderFileSuppression.length - 1 === 0) {
				suppressionError["headerError"] = "";
				this.setState({
					invalidHeaderFileSuppression: [],
					suppressionerror: suppressionError,
				});
			} else {
				this.setState({
					invalidHeaderFileSuppression: [
						...this.state.invalidHeaderFileSuppression.filter(
							(obj) => obj !== clickedFile
						),
					],
				});
			}
		}

		//added by sonali to get the docID of document to be deleted
		var suppDocID;
		var finalID;
		if (this.state.fileDetailsForSuppression[0]) {
			for (var j = 0; j < this.state.fileDetailsForSuppression[0].length; j++) {
				if (
					this.state.fileDetailsForSuppression[0][j].filename === clickedFile
				) {
					suppDocID = this.state.fileDetailsForSuppression[0][j].suppDocID;
					finalID = suppDocID;
				}
			}
		}
		// User Story - 3411 - Karan Jagtap - removing the file from UI
		this.setState({
			suppressionFilesSelectedDocuments: [
				...this.state.suppressionFilesSelectedDocuments.filter(
					(obj) => obj.suppDocName !== clickedFile
				),
			],
		});
		var indx = duplicateSuppressionFile.indexOf(clickedFile);
		if (indx > -1) {
			duplicateSuppressionFile.splice(indx, 1);
			delete supprnames[clickedFile];
		}

		// Bug - 3338 - Karan Jagtap - if this is empty means file upload failed and no need to delete it from db
		// hence not calling the below delete route
		if (finalID === undefined) {
			finalID = item.supportDocID;
		}
		let data = {
			campID: this.state.rfpCampaignID,
			names: clickedFile,
			finalID: finalID,
		};
		fetch("agencyDashboard/deleteRFPDocument", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}).catch((e)=>console.log(e));//Somnath Task-3936, Add catch Block
	};

	handleChangeMultipleFileOfOther(e) {
		e.preventDefault()
		//Somnath Task-3936, Get value from props
		var data = new FormData();
		var rfpCampaignID;
		var parsed = this.props.location.state;
		if (parsed !== undefined) {
			rfpCampaignID = parsed.rfpCampaignID;
		}
		e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
		e.setAttribute("type", "file"); //setting a attribute for type file added by Raunak
		e.setAttribute("multiple", true); //setting a attribute for multiple added by Raunak
		e.click(); //to open file dialougeadded by Raunak
		e.addEventListener(
			"change",
			function () {
				//calling an eventListener at change event added by Raunak
				let filesData = e.files;
				let notToBeshown = {};
				let otherFilesSelectedDocumentsTemp = [
					...this.state.otherFilesSelectedDocuments,
				];
				for (let i = 0, l = filesData.length; i < l; i++) {
					let fileName = filesData[i].name;
					if (!duplicateOtherFile.includes(fileName)) {
						let file = filesData[i];
						data.append("file", file);
						otherFilesSelectedDocumentsTemp.push({
							success: true,
							loading: true,
							supportDocID: "",
							suppDocName: filesData[i].name,
							campID: this.state.rfpCampaignID || "",
							type: "Other",
						});
					}
				}
				this.setState({
					otherFilesSelectedDocuments: otherFilesSelectedDocumentsTemp,
				});

				for (let i = 0, l = filesData.length; i < l; i++) {
					duplicateOtherFile.push(filesData[i].name);
				}
				if (
					rfpCampaignID !== undefined ||
					rfpCampaignID !== "undefined" ||
					rfpCampaignID !== null ||
					rfpCampaignID !== "null" ||
					rfpCampaignID !== ""
				) {
					data.append("rfpCampaignID", rfpCampaignID);
				}
				data.append("fromRFP", this.state.fromRFP); //Sonali-3338-if we go on supp doc routes from rfp page then this flag will identify

				// Bug - 3338 - Karan Jagtap - replace the API Endpoint
				fetch("campaign/supportingDocumentOther", {
					method: "POST",
					body: data,
				})
					.then((res) => res.json())
					.then((otherList) => {
						// Bug - 3338 - Karan Jagtap - Start
						// Here, i've changed multiple lines hence commentting as a block comment
						// after route change the response array format was different,
						// hence, making the format FE compatible
						let fileDetailsTemp = otherList.map((obj) => {
							return { suppDocID: obj.supportDocID, filename: obj.fileName };
						});
						fileDetailsForOther.push(fileDetailsTemp);
						for (var i = 0; i < otherList.length; i++) {
							let docNumberSingle = otherList[i].supportDocID;
							if (
								docNumberSingle !== null &&
								docNumberSingle !== undefined &&
								docNumberSingle !== ""
							) {
								docNumber.push(docNumberSingle);
							}
						}
						this.setState({
							fileDetailsForOther: fileDetailsForOther,
							docNumber: docNumber,
							otherFileError: "none",
						});
						if (otherList.length > 0) {
							let containsFailedFile = false;
							let rejectedFileNames = [];
							for (let i = 0; i < otherList.length; i++) {
								if (!otherList[i].success) {
									notToBeshown[otherList[i].fileName] = 0;
									containsFailedFile = true;
									rejectedFileNames.push(otherList[i].fileName);
								}
							}
							if (containsFailedFile) {
								var responses = rejectedFileNames;
								for (let i = 0; i < responses.length; i++) {
									notToBeshown[responses[i]] = i;
								}
							}
						}
						// Bug - 3338 - Karan Jagtap - End

						let otherFilesSelectedDocumentsTemp = [
							...this.state.otherFilesSelectedDocuments,
						];
						for (let i = 0, l = filesData.length; i < l; i++) {
							if (filesData[i].name in notToBeshown) {
								// User Story - 3411 - Karan Jagtap - modifying the array as per required by the UI
								let supportDocIDTemp = "";
								let foundObj = fileDetailsTemp.find(
									(obj) => obj.filename === filesData[i].name
								);
								if (foundObj !== undefined && foundObj !== null) {
									supportDocIDTemp = foundObj.suppDocID;
								}
								let foundIndex = otherFilesSelectedDocumentsTemp.findIndex(
									(obj) => obj.suppDocName === filesData[i].name
								);
								otherFilesSelectedDocumentsTemp[foundIndex] = {
									success: false,
									loading: false,
									supportDocID: supportDocIDTemp,
									suppDocName: filesData[i].name,
									campID: this.state.rfpCampaignID || "",
									type: "Other",
								};
							} else {
								// User Story - 3411 - Karan Jagtap - modifying the array as per required by the UI
								let supportDocIDTemp = "";
								let foundObj = fileDetailsTemp.find(
									(obj) => obj.filename === filesData[i].name
								);
								if (foundObj !== undefined && foundObj !== null) {
									supportDocIDTemp = foundObj.suppDocID;
								}
								let foundIndex = otherFilesSelectedDocumentsTemp.findIndex(
									(obj) => obj.suppDocName === filesData[i].name
								);
								otherFilesSelectedDocumentsTemp[foundIndex] = {
									success: true,
									loading: false,
									supportDocID: supportDocIDTemp,
									suppDocName: filesData[i].name,
									campID: this.state.rfpCampaignID || "",
									type: "Other",
								};
							}
						}
						this.setState({
							otherFilesSelectedDocuments: otherFilesSelectedDocumentsTemp,
						});
					})
					.catch((error) => {
						console.error("Error:", error);
					});
			}.bind(this)
		);
	}

	/**
	 * @author : Karan Jagtap
	 * @descrition : Fired when clicked on remove icon of individual file
	 */
	removeOtherFile = (e, item) => {
		e.preventDefault();
		var clickedFile = item.suppDocName;
		//added by sonali to get the docID of document to be deleted
		var suppDocID;
		var finalID;
		if (this.state.fileDetailsForOther[0]) {
			for (var j = 0; j < this.state.fileDetailsForOther[0].length; j++) {
				if (this.state.fileDetailsForOther[0][j].filename === clickedFile) {
					suppDocID = this.state.fileDetailsForOther[0][j].suppDocID;
					finalID = suppDocID;
				}
			}
		}
		// User Story - 3411 - Karan Jagtap - removing the file from UI
		this.setState({
			otherFilesSelectedDocuments: [
				...this.state.otherFilesSelectedDocuments.filter(
					(obj) => obj.suppDocName !== clickedFile
				),
			],
		});
		var indx = duplicateOtherFile.indexOf(clickedFile);
		if (indx > -1) {
			duplicateOtherFile.splice(indx, 1);
		}
		// Bug - 3338 - Karan Jagtap - if this is empty means file upload failed and no need to delete it from db
		// hence not calling the below delete route
		if (finalID === undefined) {
			finalID = item.supportDocID;
		}
		let data = {
			campID: this.state.rfpCampaignID,
			names: clickedFile,
			finalID: finalID,
		};
		fetch("agencyDashboard/deleteRFPDocument", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		});
	};

	commonhandlechange(e) {
		//  debugger
		const { name, value } = e.target;
		// alert("common habndle change==>"+e.target.value);
		//rutuja 2832 replace == with ===
		if (name === "biddingType") {
			localStorage.setItem("biddingType", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "biddingType": value }; //rutuja 2832
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({ BasicDetailArray: BasicDetailArray });
		}
		//* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE */
		//rutuja 2832 replace == with ===
		if (name === "clientName") {
			document.getElementById("client").style.border = "1px solid lightgray";
			localStorage.setItem("clientName", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "clientName": value }; //rutuja 2832
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				clientNameDisplay: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "durationType") {
			// alert("durationType===>"+value);
			localStorage.setItem("durationType", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			//  alert("BasicDetailArray===>"+JSON.stringify(BasicDetailArray));
			BasicDetailArray[0] = { ...BasicDetailArray[0], "durationType": value }; //rutuja 2832

			//  alert("BasicDetailArray[0]===>"+JSON.stringify(BasicDetailArray[0]));
			this.state.BasicDetailArray = [...BasicDetailArray];
			//  alert("arrayy===>"+JSON.stringify(this.state.BasicDetailArray));
			this.setState({ BasicDetailArray: BasicDetailArray }, function () {
				let BasicDetailArray = [...this.state.BasicDetailArray];
				BasicDetailArray[0] = { ...BasicDetailArray[0], "duration": "0" }; //rutuja 2832
				this.setState({ BasicDetailArray: BasicDetailArray });
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "duration") {
			localStorage.setItem("duration", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "duration": value }; //rutuja 2832
			this.state.BasicDetailArray = [...BasicDetailArray];
			document.getElementById("duration").style.border = "1px solid lightgray";
			this.setState({
				BasicDetailArray: BasicDetailArray,
				durationErrMsg: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "campaignType") {
			let BasicDetailArray = [...this.state.BasicDetailArray];
			localStorage.setItem("campaignType", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			BasicDetailArray[0] = { ...BasicDetailArray[0], "campaignType": value }; //rutuja 2832
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({ BasicDetailArray: BasicDetailArray });
		}
		//rutuja 2832 replace == with ===
		if (name === "campaignName") {
			document.getElementById("campaignName").style.border =
				"1px solid lightgray";
			localStorage.setItem("campaignName", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "campaignName": value }; //rutuja 2832
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				cnameDisplay: "none",
				cnameDisplay2: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "rfpstartDate") {
			document.getElementById("rfpstartDate").style.border =
				"1px solid lightgray";
			localStorage.setItem("startDate", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "startDate": value }; //rutuja 2832
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				startDateDisplay: "none",
				startDateDisplay1: "none",
				startDateDisplay2: "none",
				startDateDisplayRfp: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "rfpendDate") {
			document.getElementById("rfpendDate").style.border =
				"1px solid lightgray";
			localStorage.setItem("endDate", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "endDate": value }; //rutuja 2832
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				endDateDisplay: "none",
				endDateDisplay1: "none",
				endDateDisplay2: "none",
				endDateDisplay3: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "rfpleadAllocation") {
			document.getElementById("rfpleadAllocation").style.border =
				"1px solid lightgray";
			localStorage.setItem("leadAllocation", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = {
				...BasicDetailArray[0],
				"leadAllocation": value, //rutuja 2832
			};
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				leadAllocationErrMsg: "none",
				leadAllocationErrMsg2: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "rateCardCPL") {
			document.getElementById("rateCardCPL").style.border =
				"1px solid lightgray";
			document.getElementById("netCPL").style.border = "1px solid lightgray";
			localStorage.setItem("rateCardCPL", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "rateCardCPL": value }; //rutuja 2832
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				ratecardcplErrMsg: "none",
				ratecardcplErrMsg2: "none",
				ratecardcplErrMsg3: "none",
				netcplErrMsg3: "none",
				cplErrMsg3: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "rfpCPL") {
			document.getElementById("rfpCPL").style.border = "1px solid lightgray";
			document.getElementById("netCPL").style.border = "1px solid lightgray";
			let BasicDetailArray = [...this.state.BasicDetailArray];
			localStorage.setItem("CPL", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			BasicDetailArray[0] = { ...BasicDetailArray[0], "CPL": value }; //rutuja 2832
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				cplErrMsg: "none",
				cplErrMsg2: "none",
				cplErrMsg3: "none",
				netcplErrMsg3: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "netCPL") {
			document.getElementById("netCPL").style.border = "1px solid lightgray";
			localStorage.setItem("netCPL", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "netCPL": value }; //rutuja 2832
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				netcplErrMsg: "none",
				netcplErrMsg2: "none",
				netcplErrMsg3: "none",
				netcplErrMsg4: "none",
				cplErrMsg3: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "campaignCurrency") {
			document.getElementById("campaignCurrency").style.border =
				"1px solid lightgray";
			localStorage.setItem("currency", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "currency": value };
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({ BasicDetailArray: BasicDetailArray });
		}
		//rutuja 2832 replace == with ===
		if (name === "marketingChannel") {
			document.getElementById("marketingChannel").style.border =
				"1px solid lightgray";
			localStorage.setItem("marketingChannel", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = {
				...BasicDetailArray[0],
				"marketingChannel": value,
			};
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({ BasicDetailArray: BasicDetailArray });
		}
		//rutuja 2832 replace == with ===
		if (name === "jobTitle") {
			// document.getElementsByClassName("t1").style.border='1px solid lightgray';
			localStorage.setItem("jobTitle", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "jobTitle": value };
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				jobTitleErrMsg: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "otherSpecs") {
			// document.getElementsByClassName("t2").style.border='1px solid lightgray';
			localStorage.setItem("otherSpecs", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = { ...BasicDetailArray[0], "otherSpecs": value };
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({ BasicDetailArray: BasicDetailArray });
		}
		//rutuja 2832 replace == with ===
		if (name === "customQuestionNumbr") {
			document.getElementById("customQuestionNumbr").style.border =
				"1px solid lightgray";
			localStorage.setItem("noOfCustomQuestions", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = {
				...BasicDetailArray[0],
				"noOfCustomQuestions": value,
			};
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				customQuestionErMsg: "none",
			});
		}

		//Sandeep-task-3518-added code to store updated value for audienceMatch
		//rutuja 2832 replace == with ===
		if (name === "audienceMatch") {
			document.getElementById("audienceMatch").style.border =
				"1px solid lightgray";
			localStorage.setItem("audienceMatch", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = {
				...BasicDetailArray[0],
				"audienceMatch": value,
			};
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				audienceMatchErMsg: "none",
			});
		}
		//rutuja 2832 replace == with ===
		if (name === "audienceReach") {
			// document.getElementById("audienceMatch").style.border =
			// 	"1px solid lightgray";
			// 	localStorage.setItem("audienceMatch", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = {
				...BasicDetailArray[0],
				"audienceReach": value,
			};
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				//audienceMatchErMsg: "none",
			});
		}
		//Sandeep-task-3518-added code to store updated value for tal
		//rutuja 2832 replace == with ===
		if (name === "tal") {
			document.getElementById("tal").style.border = "1px solid lightgray";
			localStorage.setItem("tal", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = {
				...BasicDetailArray[0],
				"tal": value,
			};
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				talErMsg: "none",
			});
		}
		//Sandeep-task-3518-added code to store updated value for publication
		//rutuja 2832 replace == with ===
		if (name === "publication") {
			document.getElementById("publication").style.border =
				"1px solid lightgray";
			localStorage.setItem("publication", value); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
			let BasicDetailArray = [...this.state.BasicDetailArray];
			BasicDetailArray[0] = {
				...BasicDetailArray[0],
				"publication": value,
			};
			this.state.BasicDetailArray = [...BasicDetailArray];
			this.setState({
				BasicDetailArray: BasicDetailArray,
				publicationErMsg: "none",
			});
		}
	}
	//* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE */
	/**
	 * @author Snehal More
	 * @param  Description handleClientsetup
	 * @return Description to add new client
	 */
	handleClientsetup(e) {
		e.preventDefault();
		//Somnath Task-3936, Get value from props
		var rfpCampaignID;
			var parsed = this.props.location.state;
			if (parsed !== undefined) {
				rfpCampaignID = parsed.rfpCampaignID;
			}
		if (rfpCampaignID) {
			this.props.history.push("/addAdvertiser",{add:`editRfp`,rfpCampaignID})
		} else {
			this.props.history.push("/addAdvertiser",{add:`newCreateRfp`})
		}
	}
	biddingDateBack(value, dateString) {
		const name = "biddingEndDate";
		document.getElementById("bid").style.border = "1px solid lightgray";
		localStorage.setItem("biddingEndDate", dateString);
		var BasicDetailArray = [...this.state.BasicDetailArray];
		BasicDetailArray[0] = { ...BasicDetailArray[0], [name]: dateString };
		this.setState({
			BasicDetailArray: BasicDetailArray,
			bidDateErrMsg: "none",
			bidDateErrMsg1: "none",
			bidDateErrMsg2: "none",
		});
	}
	industryHandleChange = (value) => {
		//console.log(`Option selected:`, value);
		var industryArray = [];
		//const name = "industry"; // rutuja 2832 
		for (var j = 0; j < value.length; j++) {
			industryArray.push(value[j].name);
		}
		localStorage.setItem("industry", industryArray);
		this.setState({
			industry: value,
			industryDB: industryArray,
			industryDisplay: "none",
			inputClassIndustry: "validate",
		});
	};

	employeeHandleChange = (value) => {
		//console.log(`Option selected:`, value);
		var employeeSizeArray = [];
		//const name = "employeeSize";  // rutuja 2832 
		for (var j = 0; j < value.length; j++) {
			employeeSizeArray.push(value[j].name);
		}
		localStorage.setItem("employeeSize", employeeSizeArray);
		this.setState({
			employeeSize: value,
			employeeSizeDB: employeeSizeArray,
			employeeSizeDisplay: "none",
			inputClassDropDownEmployeeSize: "validate",
		});
	};

	revenuehandleChange = (value) => {
		//console.log(`Option selected:`, value);
		var companyRevenueArray = [];
		//const name = "companyRevenue";  // rutuja 2832 
		for (var j = 0; j < value.length; j++) {
			companyRevenueArray.push(value[j].name);
		}
		localStorage.setItem("companyRevenue", companyRevenueArray); //Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
		this.setState({
			companyRevenue: value,
			companyRevenueDB: companyRevenueArray,
			companyRevenueDisplay: "none",
			inputClassDropDowncompanyRevenue: "validate",
		});
	};
	RfpBackButton(e) {
		//snehal-task-3509-Project's(DI) - Create RFP through XLS - FE
		e.preventDefault();
		//Somnath Task-3936, Get value from props
		var status;
			var parsed = this.props.location.state;
			if (parsed !== undefined) {
				status = parsed.status;
			}
		const { user } = this.props.auth;
		var agencyID = user.id;

		// karan-task-3636-redirects back where it came from - start
		if (this.state.fromScreen === "rfpProgress") {
			this.props.history.push("/rfpProgress"); //karan-task-3803-replace query params
		} else if (this.state.fromScreen === "rfpDashboard") {
			this.props.history.push("/rfpDashboard"); //karan-task-3803-replace query params
		} else {
			// karan-task-3636-old code - start
			if (status == "RFPXlsx") {
				this.props.history.push("/createXLSRfp",{agencyID: agencyID}); //sonali-task-3716-replace query params

				//this.props.history.push("/createXLSRfp");//Somnath Task-3936, Remove AgencyID
			} else {
				this.props.history.push("/requestForProposalExsisting",{agencyID: agencyID}); //sonali-task-3716-replace query params
				//Sonali-4060-keeping below line in comment and added the old line above these changes were done 3716 but replaced by 3936 so not working
				//this.props.history.push("/requestForProposalExsisting");//Somnath Task-3936, Remove AgencyID
			}
			// karan-task-3636-old code - end
		}
		// karan-task-3636-redirects back where it came from - end
	}
	RfpCreateBackButton(e) {
		e.preventDefault();//Somnath TAsk-3936, Add preventDefault method
		const { user } = this.props.auth;
		// karan-task-3636-redirects back where it came from - start
		if (this.state.fromScreen === "rfpProgress") {
			this.props.history.push("/rfpProgress"); //karan-task-3803-replace query params
		} else if (this.state.fromScreen === "rfpDashboard") {
			this.props.history.push("/rfpDashboard"); //karan-task-3803-replace query params
		} else {
			//var agencyID = user.id;  // rutuja 2832 
			this.props.history.push("/newdashboard", { agencyID: user.id }); //karan-task-3684-replace query params
		}
		// karan-task-3636-redirects back where it came from - end
	}
	validateRfp(e) {
		e.preventDefault();
		// Sandeep-task-2851-added new variable
		let CampaignSpecificationform = true;  
		let finalFormIsValid = true;
		let clientNameFlag = true; //* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE */
		let campNameFlag = true;
		let dateflag = true;
		//let employeeSizeToFromValidate = true;  // rutuja 2832 
		//let employeeSizePickyValidate = true;  // rutuja 2832 
		let companyRevenueToFromValidate = true;
		let companyRevenueTextValidate = true;
		let employeeSizeTextValidate = true;
		let error = {};
		let countryWiseAllocationform = true;
		var currentDate = new Date(
			new Date().getTime() - new Date().getTimezoneOffset() * 60000
		)
			.toISOString()
			.split("T")[0];
		const {
			campaignName,
			clientName,
			//durationType, //rutuja 2832
			duration,
			startDate,
			endDate,
			biddingEndDate,
			leadAllocation,
			rateCardCPL,
			CPL,
			netCPL,
			//currency, //rutuja 2832
		} = this.state.BasicDetailArray[0];
		//* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE */
		if (clientName === "" || clientName === null || clientName === "null") {
			document.getElementById("client").style.border = "1px solid red";
			this.setState({ clientNameDisplay: "block" });
			clientNameFlag = false;
		}
		let netCPLdecimalLength = netCPL.toString().includes(".")
			? netCPL.toString().split(".")[1].length || 0
			: 0;
		let rateCardCPLdecimalLength = rateCardCPL.toString().includes(".")
			? rateCardCPL.toString().split(".")[1].length || 0
			: 0;
		//  var pattern1=RegExp(/[?#$@!%^&*()[]]/);
		var pattern1 = RegExp(/^[0-9]+[0-9]*$/); //Sandeep-task-2855-added regx to handle duration value

		if (!pattern1.test(duration)) {
			//Sandeep-task-2855 added if block to check pattern for duration field
			//alert("Negative Value");
			dateflag = false;
			document.getElementById("duration").style.border = "1px solid red";
			this.setState({ durationErrMsg: "block" });
		} else {
			// alert("Proper values");
			document.getElementById("duration").style.border = "1px solid gray";
			this.setState({ durationErrMsg: "none" });
		}
		if (document.getElementById("duration").disabled == false) {
			if (duration === "" || parseInt(duration) <= 0) { //rutuja 2832 replace == with ===
				dateflag = false;
				document.getElementById("duration").style.border = "1px solid red";
				this.setState({ durationErrMsg: "block" });
			}
		} else {
			document.getElementById("duration").style.border = "1px solid gray";
			this.setState({ durationErrMsg: "none" });
		}
		//Sandeep-task-3387-added code for space issue with camp Name
		let campaignName1 = campaignName.replace(/ /g, "");
		if (campaignName1 === "" || campaignName1.length === 0) { //rutuja 2832 replace == with ===
			document.getElementById("campaignName").style.border = "1px solid red";
			this.setState({ cnameDisplay: "block" });
			campNameFlag = false;
		}
		if (pattern1.test(campaignName1)) {
			document.getElementById("campaignName").style.border = "1px solid red";
			this.setState({ cnameDisplay2: "block" });
			//alert("The Campaign Name should not contain special characters!");
			campNameFlag = false;
		}
		//rutuja 2832 replace == with ===
		if (leadAllocation === "") {
			document.getElementById("rfpleadAllocation").style.border =
				"1px solid red";
			this.setState({ leadAllocationErrMsg: "block" });
			dateflag = false;
		}
		if ((this.state.region == "") | (this.state.region == null)) {
			this.setState({ regionCountriesDisplay: "block" });
			dateflag = false;
		}
		//rutuja 2832 replace == with ===
		if (CPL === "") {
			document.getElementById("rfpCPL").style.border = "1px solid red";
			this.setState({ cplErrMsg: "block" });
			dateflag = false;
		}
		//rutuja 2832 replace == with ===
		if (startDate === "") {
			document.getElementById("rfpstartDate").style.border = "1px solid red";
			this.setState({ startDateDisplay: "block" });
			dateflag = false;
		} else {
			if (Date.parse(startDate) <= Date.parse(biddingEndDate)) {
				dateflag = false;
				this.setState({ startDateDisplay1: "block" });
				document.getElementById("rfpstartDate").style.border = "1px solid red";
			}
		}
		//rutuja 2832 replace == with ===
		if (endDate === "") {
			document.getElementById("rfpendDate").style.border = "1px solid red";
			this.setState({ endDateDisplay: "block" });
			dateflag = false;
		} else {
			if (endDate <= currentDate) {
				dateflag = false;
				this.setState({ endDateDisplay1: "block" });
				document.getElementById("rfpendDate").style.border = "1px solid red";
			}
			if (endDate < startDate) {
				dateflag = false;
				this.setState({ endDateDisplay3: "block" });
				document.getElementById("rfpendDate").style.border = "1px solid red";
			}
		}
		//rutuja 2832 replace == with ===
		if (biddingEndDate === "") {
			document.getElementById("bid").style.border = "1px solid red";
			this.setState({ bidDateErrMsg: "block" });
			dateflag = false;
		} else {
			if (Date.parse(biddingEndDate) <= Date.now()) {
				document.getElementById("bid").style.border = "1px solid red";
				this.setState({ bidDateErrMsg1: "block" });
				dateflag = false;
			}
		}
		if (
			isNaN(leadAllocation) |
			leadAllocation.includes(".") |
			(parseInt(leadAllocation) <= 0) |
			leadAllocation.includes("*") |
			leadAllocation.includes("/") |
			leadAllocation.includes(",") |
			leadAllocation.includes("=") |
			leadAllocation.includes("+") |
			leadAllocation.includes("!") |
			leadAllocation.includes("@") |
			leadAllocation.includes("#") |
			leadAllocation.includes("$") |
			leadAllocation.includes("%") |
			leadAllocation.includes("^") |
			leadAllocation.includes("&") |
			leadAllocation.includes("*") |
			leadAllocation.includes("(") |
			leadAllocation.includes(")") |
			leadAllocation.includes("-")
		) {
			document.getElementById("rfpleadAllocation").style.border =
				"1px solid red";
			this.setState({ leadAllocationErrMsg2: "block" });
			dateflag = false;
		}

		if (isNaN(CPL)) {
			document.getElementById("rfpCPL").style.border = "1px solid red";
			this.setState({ cplErrMsg2: "block" });
			dateflag = false;
		}
		if (isNaN(netCPL) && netCPL !== "") {
			document.getElementById("netCPL").style.border = "1px solid red";
			this.setState({ netcplErrMsg2: "block" });
			dateflag = false;
		}
		if (
			(netCPL !== "" && netCPL < 0) ||
			(netCPL !== "" && netCPLdecimalLength > 2)
		) {
			document.getElementById("netCPL").style.border = "1px solid red";
			this.setState({ netcplErrMsg2: "block" });
			dateflag = false;
			return dateflag;
		}
		if (isNaN(rateCardCPL) && rateCardCPL !== "") {
			document.getElementById("rateCardCPL").style.border = "1px solid red";
			this.setState({ ratecardcplErrMsg2: "block" });
			dateflag = false;
		}
		if (
			(rateCardCPL !== "" && rateCardCPL < 0) ||
			rateCardCPLdecimalLength > 2
		) {
			document.getElementById("rateCardCPL").style.border = "1px solid red";
			this.setState({ ratecardcplErrMsg2: "block" });
			dateflag = false;
			return dateflag;
		}
		if (CPL !== "") {
			var pattern3 = new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/);
			if (!pattern3.test(CPL)) {
				document.getElementById("rfpCPL").style.border = "1px solid red";
				this.setState({ cplErrMsg2: "block" });
				dateflag = false;
			}
		}
		if (
			parseInt(rateCardCPL) > 0 &&
			parseInt(CPL) > 0 &&
			parseInt(rateCardCPL) < parseInt(CPL)
		) {
			document.getElementById("rateCardCPL").style.border = "1px solid red";
			this.setState({ ratecardcplErrMsg3: "block" });
			dateflag = false;
		}
		if (
			parseInt(netCPL) > 0 &&
			parseInt(CPL) > 0 &&
			parseInt(netCPL) > parseInt(CPL)
		) {
			document.getElementById("netCPL").style.border = "1px solid red";
			this.setState({ netcplErrMsg3: "block" });
			dateflag = false;
		}

		if (this.state.customEmployeeSizeText.length === 0) {
			employeeSizeTextValidate = true;
			CampaignSpecificationform = true;
		} // end of  if(this.state.customEmployeeSizeText.length===0)
		else {
			//snehal-task-3760-when i added comma in emp size so added validations for that
			var pattern = new RegExp(/^[1-9+<>][0-9+,\-<>|\n]*$/);
			if (!pattern.test(this.state.customEmployeeSizeText)) {
				document.getElementById("customemployeeSizeText").style.border =
					"1px solid red"; //Sandeep-task-3396-error msg issue
				this.setState({ customEmployeeSizeTextDisplay: "block" });
				employeeSizeTextValidate = false;
				dateflag = false; //snehal-task-3593-Agency side---create RFP by XLSX----error msg issue
				//return employeeSizeTextValidate;
			} else {
				var custEmpSize = this.state.customEmployeeSizeText;
				var custEmpSize_array = custEmpSize.split(/[\n |]+/);
				let custEmpSizeArray = [];
				for (var i = 0; i < custEmpSize_array.length; i++) {
					custEmpSizeArray.push({
						id: custEmpSize_array[i],
						name: custEmpSize_array[i],
					});
					//sandeep-task-3392-agency side---create rfp--company revenue issue(added reg ex and if condition)
					var temp_array = custEmpSize_array[i];
					var patternNegative = new RegExp(/^[-]/);
					var patternNegative_2 = new RegExp(/[-]$/);
					var patterncommastart = new RegExp(/^[,]/); //snehal-task-3760-when i added comma in emp size so added validations for that
					var patterncommaend = new RegExp(/[,]$/); //snehal-task-3760-when i added comma in emp size so added validations for that
					var pattern_space = new RegExp(/\d( )\d/); //Sandeep-task-3392-added code for space issue
					//sandeep-task-3392--agency side---create rfp--company revenue issue
					if (
						patternNegative.test(temp_array) ||
						patternNegative_2.test(temp_array) ||
						pattern_space.test(temp_array) ||
						patterncommaend.test(temp_array) ||
						patterncommastart.test(temp_array)
					) {
						employeeSizeTextValidate = false;
						document.getElementById("customemployeeSizeText").style.border =
							"1px solid red"; //Sandeep-task-3396-error msg issue
						this.setState({ customEmployeeSizeTextDisplay: "block" });
						dateflag = false; //snehal-task-3593-Agency side---create RFP by XLSX----error msg issue
						//return employeeSizeTextValidate;//Sandeep-task-3396-error msg issue
					}
					//snehal-task-3760-when i added comma in emp size so added validations for that
					var pattern2 = new RegExp(/^[1-9][0-9-,]*$/);
					var pattern_dash = new RegExp(/[-]+/);
					if (pattern2.test(temp_array) && pattern_dash.test(temp_array)) {
						var patterncomma = new RegExp(/[,]+/);
						var custEmpSizeArray1 = temp_array.split(/[-]+/);
						if (custEmpSizeArray1[1] == undefined) {
							employeeSizeTextValidate = true;
						} else {
							//snehal-task-3760-when i added comma in emp size so added validations for that
							var custEmpSizeArray_temp1 = custEmpSizeArray1[0];
							var custEmpSizeArray_temp2 = custEmpSizeArray1[1];
							if (patterncomma.test(custEmpSizeArray_temp1)) {
								let pattern_emp_comma = new RegExp(
									/^(\d{1,2})(,\d{2})*(,\d{3}){1}(\.\d{1,})?$/
								);
								if (pattern_emp_comma.test(custEmpSizeArray_temp1)) {
									 custEmpSizeArray1 = custEmpSizeArray_temp1; //rutuja 2832
									 custEmpSizeArray_temp1 = custEmpSizeArray1.replace( //rutuja 2832
										/,/g,
										""
									);
								} else {
									employeeSizeTextValidate = false;
									document.getElementById(
										"customemployeeSizeText"
									).style.border = "1px solid red";
									this.setState({ customEmployeeSizeTextDisplay: "block" });
									dateflag = false;
								}
							}
							if (patterncomma.test(custEmpSizeArray_temp2)) {
								let pattern_emp_comma = new RegExp(
									/^(\d{1,2})(,\d{2})*(,\d{3}){1}(\.\d{1,})?$/
								);
								if (pattern_emp_comma.test(custEmpSizeArray_temp2)) {
									var custEmpSizeArray2 = custEmpSizeArray_temp2;
									 custEmpSizeArray_temp2 = custEmpSizeArray2.replace( //rutuja 2832
										/,/g,
										""
									);
								} else {
									employeeSizeTextValidate = false;
									document.getElementById(
										"customemployeeSizeText"
									).style.border = "1px solid red";
									this.setState({ customEmployeeSizeTextDisplay: "block" });
									dateflag = false;
								}
							}
							if (
								parseInt(custEmpSizeArray_temp1) >
								parseInt(custEmpSizeArray_temp2)
							) {
								employeeSizeTextValidate = false;
								document.getElementById("customemployeeSizeText").style.border =
									"1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customEmployeeSizeTextDisplay: "block" });
								dateflag = false; //snehal-task-3593-Agency side---create RFP by XLSX----error msg issue
								//return employeeSizeTextValidate;//Sandeep-task-3396-error msg issue
							} else {
								employeeSizeTextValidate = true;
							}
						}
					} else {
						//snehal-task-3344-edit camp---error msg issue
						//snehal-task-3760-when i added comma in emp size so added validations for that
						let pattern_emp = new RegExp(/^[0-9+<>]?[0-9]+?[0-9]+?[n]?[<>+]?$/);
						let pattern_empComma = new RegExp(
							/^[0-9+<>]?[0-9,]+?[0-9]+?[n]?[<>+]?$/
						);
						let pattern_emp_comma = new RegExp(
							/^(\d{1,2})(,\d{2})*(,\d{3}){1}(\.\d{1,})?$/
						);
						if (pattern_emp.test(temp_array)) {
							employeeSizeTextValidate = true;
						} else if (pattern_empComma.test(temp_array)) {
							var temp_value = temp_array.replace("+", "");
							var temp_value2 = temp_value.replace("<", "");
							let temp_array = temp_value2.replace(">", ""); //rutuja 2832
							if (pattern_emp_comma.test(temp_array)) {
								employeeSizeTextValidate = true;
							} else {
								employeeSizeTextValidate = false;
								document.getElementById("customemployeeSizeText").style.border =
									"1px solid red";
								this.setState({ customEmployeeSizeTextDisplay: "block" });
								dateflag = false;
							}
						} else {
							employeeSizeTextValidate = false;
							document.getElementById("customemployeeSizeText").style.border =
								"1px solid red"; //Sandeep-task-3396-error msg issue
							this.setState({ customEmployeeSizeTextDisplay: "block" });
							dateflag = false; //snehal-task-3593-Agency side---create RFP by XLSX----error msg issue
							//return employeeSizeTextValidate;
						}
					}
				}
			}
		} // end of  if(this.state.customEmployeeSizeText.length>0)

		//sunita-task-3645-added below validation code for countrywise lead allocation

		//CountrtyWise chk box Validation
		//rutuja 2832 replace == with ===
		if (this.state.geoLeadChk === "Yes") {
			if (this.state.countryWiseAllocationArray.length === 0) {
				// alert("inside length is 0");
				this.setState({ countrywiseLeadDisplay: "block" });
				//CampaignSpecificationform = false;
				countryWiseAllocationform = false;
				return false; //sunita-task-3736-added code here as submitting rfp and getting blank lead data issue.
				// return CampaignSpecificationform;
			} else {
				this.setState({
					countrywiseLeadDisplay: "none",
					countrywiseLeadDisplay2: "none",
				});

				let geoWiseLeadAllocationLength =
					this.state.countryWiseAllocationArray.length;
				// alert("geoWiseLeadAllocationLength===>"+geoWiseLeadAllocationLength);
				let geoWiseLeadsTotal = 0;
				var leadAllocationNumeric = true;
				var count = 0;

				for (let i = 0; i < geoWiseLeadAllocationLength; i++) {
					//snehal-task-3807-error when camp is geovise and all lead allocation are not there
					if (
						//rutuja 2832 replace == with ===
						(this.state.countryWiseAllocationArray[i].leadCount === "" ||
							this.state.countryWiseAllocationArray[i].leadCount < 0) &&
						this.state.BasicDetailArray[0].biddingType === "Geowise"
					) {
						leadAllocationNumeric = false;
						countryWiseAllocationform = false;
						error["requireLead"] = "Please allocate leads to all countries";
						this.setState({
							error: error,
							countrywiseLeadDisplayGeoviz: "block",
						});
						return false;
					} else {
						leadAllocationNumeric = true;
						this.setState({ countrywiseLeadDisplayGeoviz: "none" });
					}
					//rutuja 2832 replace == with ===
					if (this.state.countryWiseAllocationArray[i].leadCount === "") {
						if (count === 0) {
							// alert("count==>0")
							leadAllocationNumeric = false;
							error["requireLead"] = "Please enter countryviz lead allocation";
							//Sandeep-task-3397-error msg issue
							this.setState({ error: error, countrywiseLeadDisplay: "block" });
							//CampaignSpecificationform = false;
							countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
							//return CampaignSpecificationform;

							// break; //temprory commented
							return false;
						}
					} else {
						count++;
						if (geoWiseLeadAllocationLength <= campLeadAllocation) {
							let pattern = new RegExp(/^[1-9][0-9]*$/); //rutuja 2832
							if (
								!pattern.test(
									this.state.countryWiseAllocationArray[i].leadCount
								)
							) {
								leadAllocationNumeric = false;
								error["requireLead"] = "";
								error["leadAllocationNumeric"] =
									"Please enter positive numeric and non-zero value for lead allocation";
								this.setState({
									error: error,
									countrywiseLeadDisplay4: "block",
								});
								//CampaignSpecificationform = false;
								countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
								//return CampaignSpecificationform;
								// break;  temprory commented
								return false;
							} else {
								leadAllocationNumeric = true;
								error["requireLead"] = "";
								error["leadAllocationNumeric"] = "";
								this.setState({
									error: error,
									countrywiseLeadDisplay: "none",
									countrywiseLeadDisplay2: "none",
									countrywiseLeadDisplay3: "none",
									countrywiseLeadDisplay4: "none",
									countrywiseLeadDisplayGeoviz: "none",
								});
								//snehal-task-3807-error when camp is geovise and all lead allocation are not there
							}
						} else if (geoWiseLeadAllocationLength > campLeadAllocation) {
							let pattern = new RegExp(/^[0-9]*$/); //rutuja 2832
							if (
								!pattern.test(
									this.state.countryWiseAllocationArray[i].leadCount
								)
							) {
								leadAllocationNumeric = false;
								error["requireLead"] = "";
								error["leadAllocationNumeric"] =
									"Please enter positive numeric value  for lead allocation";
								this.setState({
									error: error,
									countrywiseLeadDisplay3: "block",
								});
								//CampaignSpecificationform = false;
								countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
								//return CampaignSpecificationform;
								// break; tenprory commneted
								return false;
							} else {
								leadAllocationNumeric = true;
								error["requireLead"] = "";
								error["leadAllocationNumeric"] = "";
								this.setState({
									error: error,
									countrywiseLeadDisplay: "none",
									countrywiseLeadDisplay2: "none",
									countrywiseLeadDisplay3: "none",
									countrywiseLeadDisplay4: "none",
									countrywiseLeadDisplayGeoviz: "none",
								});
								//snehal-task-3807-error when camp is geovise and all lead allocation are not there
							}
						} else {
							//snehal-task-3807-R27.9--Ref(3546)---Geovize RFP----create camp----allocation issue
							if (
								this.state.countryWiseAllocationArray[i].leadCount <= 0 &&
								this.state.BasicDetailArray[0].biddingType === "Geowise"
							) {
								leadAllocationNumeric = false;
								error["requireLead"] = "";
								error["leadAllocationNumeric"] =
									"Please enter positive numeric and non-zero value for lead allocation";
								this.setState({
									error: error,
									countrywiseLeadDisplay4: "block",
								});
								countryWiseAllocationform = false;
								return false;
							} else {
								leadAllocationNumeric = true;
								error["requireLead"] = "";
								error["leadAllocationNumeric"] = "";
								this.setState({
									error: error,
									countrywiseLeadDisplay: "none",
									countrywiseLeadDisplay2: "none",
									countrywiseLeadDisplay3: "none",
									countrywiseLeadDisplay4: "none",
									countrywiseLeadDisplayGeoviz: "none",
								});
							}
						}
						if (leadAllocationNumeric == true) {
							geoWiseLeadsTotal =
								parseInt(geoWiseLeadsTotal) +
								parseInt(this.state.countryWiseAllocationArray[i].leadCount);
						}
					}
				}
				let campLeadAllocation = this.state.BasicDetailArray[0].leadAllocation;

				if (leadAllocationNumeric == true) {
					if (geoWiseLeadsTotal > campLeadAllocation) {
						error["requireLead"] = "";
						error["geoWiseLeadAllocationError"] =
							"Please enter countryviz lead allocation equal to total lead allocation";
						//Sandeep-task-3397-error msg issue
						error["geoWiseLeadAllocationSuccess"] = "";
						this.setState({ error: error, countrywiseLeadDisplay2: "block" });
						//CampaignSpecificationform = false;
						countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
						//return CampaignSpecificationform;
						return false;
					} else if (geoWiseLeadsTotal < campLeadAllocation) {
						error["requireLead"] = "";
						error["geoWiseLeadAllocationError"] =
							"Please enter countryviz lead allocation equal to total lead allocation";
						//Sandeep-task-3397-error msg issue
						error["geoWiseLeadAllocationSuccess"] = "";
						this.setState({ error: error, countrywiseLeadDisplay2: "block" });
						//CampaignSpecificationform = false;
						countryWiseAllocationform = false; //Sandeep-task-3397-error msg issue
						//return CampaignSpecificationform;
						return false;
					} else {
						error["requireLead"] = "";
						error["geoWiseLeadAllocationSuccess"] =
							"CountryViz Lead Allocation Done Successfully";
						error["geoWiseLeadAllocationError"] = "";
						this.setState({
							error: error,
							countrywiseLeadDisplay2: "none",
							countrywiseLeadDisplay: "none",
							countrywiseLeadDisplay3: "none",
							countrywiseLeadDisplay4: "none",
							countrywiseLeadDisplayGeoviz: "none",
						});
						//snehal-task-3807-error when camp is geovise and all lead allocation are not there
						//CampaignSpecificationform = true;
						countryWiseAllocationform = true; //Sandeep-task-3397-error msg issue
					}
				}
			}
		}

		// Sandeep-task-2851- added validation for customCompanyRevenueText
		if (this.state.customCompanyRevenueText.length > 0) {
			//var pattern = new RegExp(/^[0-9+<>$]*[MmBb|\n]*[+-<>$]*$/);
			let pattern = new RegExp( 
				/^[0-9|<|>|$]*[0-9|<|>|$]*[0-9 M m B b < >]*/i  //4625-Nilesh Add new RegExp for compony revenue
				//^[0-9.+<>$£]*[0-9 M m B b.+\-<>$£Kk|\n million billion Million Billion]*$/
			);//rutuja 2832
			//  var pattern = new RegExp(/^[1-9+<>][0-9+\-<>|\n]*$/);
			//  var pattern1 = new RegExp(/^[1-9+<>][0-9+\-<>|\n]*$/);
			if (!pattern.test(this.state.customCompanyRevenueText)) {
				document.getElementById("customcompanyRevenueText").style.border =
					"1px solid red"; //Sandeep-task-3396-error msg issue
				this.setState({ customCompanyRevenueTextDisplay: "block" });
				companyRevenueTextValidate = false;
				//return companyRevenueTextValidate;
			} else {
				var custComapnyRevenue = this.state.customCompanyRevenueText;
				//sandeep-task-3392-----create rfp--company revenue issue(change for space)
				var custComapnyRevenue_array = custComapnyRevenue.split(/[]+/); //4625-Nilesh remove the "|" & "\n" special char from split 
				
				//var custComapnyRevenue_array = custComapnyRevenue.split(/[\n|]+/);
				let custCompanyRevenueArray = [];
				//rutuja 2832
				for (let i = 0; i < custComapnyRevenue_array.length; i++) {
					custCompanyRevenueArray.push({
						id: custComapnyRevenue_array[i],
						name: custComapnyRevenue_array[i],
					});
					var temp_array = custComapnyRevenue_array[i];
					var pattern_dollar = new RegExp(/^[$\d]?\s+/); //Sandeep-task-3392-added code for space
					let pattern_space = new RegExp(/\d*[\s*]\d/); //Sandeep-task-3392-added code for space issue 
					var pattern_dot_start = new RegExp(/^[.]/); //sandeep-task-3392--agency side---create rfp--company revenue issue(change for dot)
					var pattern_dot_end = new RegExp(/[.]$/); //sandeep-task-3392--agency side---create rfp--company revenue issue(change for dot)
					//sandeep-task-3392--agency side---create rfp-company revenue issue(change for space,zero and dot and related to that)
					//  parseFloat(temp_array)=="0"||
					// pattern_dot_start.test(temp_array)||
					//rutuja 2832 replace == with ===
					if (
						pattern_dollar.test(temp_array) ||
						pattern_space.test(temp_array) ||
						temp_array === "." ||
						temp_array === "0" ||
						parseFloat(temp_array) == "0" ||
						pattern_dot_start.test(temp_array) ||
						pattern_dot_end.test(temp_array)
					) {
						document.getElementById("customcompanyRevenueText").style.border =
							"1px solid red"; //Sandeep-task-3396-error msg issue
						this.setState({ customCompanyRevenueTextDisplay: "block" });
						companyRevenueTextValidate = false;
						//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
					}
					var temp_array = custComapnyRevenue_array[i]; //sandeep-task-3392--agency side---create rfp--company revenue issue(change for space)
					let pattern2 = new RegExp(/[-]+/); //rutuja 2832
					if (pattern2.test(temp_array)) {
						var custCompanyRevenueArray1 = temp_array.split(/[-]+/);
						var value1 = custCompanyRevenueArray1[0];
						var value2 = custCompanyRevenueArray1[1];
						if (value1.match(/[.]/g) || value2.match(/[.]/g)) {
							let val1 = value1.split(".").length;
							let val2 = value2.split(".").length;
							if (val1 > 2 || val2 > 2) {
								document.getElementById(
									"customcompanyRevenueText"
								).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customCompanyRevenueTextDisplay: "block" });
								companyRevenueTextValidate = false;
								//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
							}
						}
						//sandeep-task-3392-agency side---create rfp--company revenue issue(if condtion changes)
						//rutuja 2832 replace == with ===
						if (value1 === "" || value2 === "") {
							document.getElementById("customcompanyRevenueText").style.border =
								"1px solid red"; //Sandeep-task-3396-error msg issue
							this.setState({ customCompanyRevenueTextDisplay: "block" });
							companyRevenueTextValidate = false;
							//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
						}
						var matches1 = value1.match(
							/[$,£,m,M,b,B,K,k,million,billion,Million,Billion]/g
						);
						var matches2 = value2.match(
							/[$,£,m,M,b,B,K,k,million,billion,Million,Billion]/g
						);
						//rutuja 2832 replace == with ===
						if (
							(matches1 === "M" ||
								matches1 === "m" ||
								matches1 ==="b" ||
								matches1 === "B" ||
								matches1 === "M,i,l,l,i,o,n" ||
								matches1 === "m,i,l,l,i,o,n" ||
								matches1 === "B,i,l,l,i,o,n" ||
								matches1 === "b,i,l,l,i,o,n") &&
							(matches2 === "M" ||
								matches2 === "m" ||
								matches2 === "b" ||
								matches2 === "B" ||
								matches2 === "M,i,l,l,i,o,n" ||
								matches2 === "m,i,l,l,i,o,n" ||
								matches2 === "B,i,l,l,i,o,n" ||
								matches2 === "b,i,l,l,i,o,n")
						) {
							if (
								((matches1 === "M" ||
									matches1 === "m" ||
									matches1 === "M,i,l,l,i,o,n" ||
									matches1 === "m,i,l,l,i,o,n") &&
									(matches2 === "M" ||
										matches2 === "m" ||
										matches2 === "M,i,l,l,i,o,n" ||
										matches2 === "m,i,l,l,i,o,n")) ||
								((matches1 === "b" ||
									matches1 === "B" ||
									matches1 === "B,i,l,l,i,o,n" ||
									matches1 === "b,i,l,l,i,o,n") &&
									(matches2 === "B" ||
										matches2 === "b" ||
										matches2 === "B,i,l,l,i,o,n" ||
										matches2 === "b,i,l,l,i,o,n"))
							) {
								if (parseFloat(value1) >= parseFloat(value2)) {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
								} else {
									//sandeep-task-3392--agency side---create rfp--company revenue issue
									let pattern_rev = new RegExp(/^.*[0-9].*$/);
									if (pattern_rev.test(temp_array)) {
										companyRevenueTextValidate = true;
									} else {
										document.getElementById(
											"customcompanyRevenueText"
										).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
										this.setState({ customCompanyRevenueTextDisplay: "block" });
										companyRevenueTextValidate = false;
										//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
									}
								}
							}
							//sandeep-task-3392--agency side---create rfp--company revenue issue(when user add matches for first and not for second)
							//rutuja 2832 replace == with ===
							else if (matches1 !== "" && matches2 === "") {
								companyRevenueTextValidate = false;
								document.getElementById(
									"customcompanyRevenueText"
								).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customCompanyRevenueTextDisplay: "block" });
								//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
								//rutuja 2832 replace == with ===
							} else if (
								(matches1 === "b" ||
									matches1 === "B" ||
									matches1 === "B,i,l,l,i,o,n" ||
									matches1 === "b,i,l,l,i,o,n") &&
								(matches2 === "M" ||
									matches2 === "m" ||
									matches2 === "M,i,l,l,i,o,n" ||
									matches2 === "m,i,l,l,i,o,n")
							) {
								companyRevenueTextValidate = false;
								document.getElementById(
									"customcompanyRevenueText"
								).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customCompanyRevenueTextDisplay: "block" });
								//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
								//rutuja 2832 replace == with ===
							} else if (
								(matches1 === "m" ||
									matches1 === "M" ||
									matches1 === "M,i,l,l,i,o,n" ||
									matches1 === "m,i,l,l,i,o,n") &&
								(matches2 === "B" ||
									matches2 === "b" ||
									matches2 === "B,i,l,l,i,o,n" ||
									matches2 === "b,i,l,l,i,o,n")
							) {
								//sandeep-task-3392--agency side---create rfp-company revenue issue
								let pattern_rev = new RegExp(/^.*[0-9].*$/);
								if (pattern_rev.test(temp_array)) {
									companyRevenueTextValidate = true;
								} else {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
								}
							}
							//rutuja 2832 replace == with ===
						} else if (
							(matches1 === "$,M" ||
								matches1 === "$,m" ||
								matches1 === "$,b" ||
								matches1 === "$,B" ||
								matches1 === "$,M,i,l,l,i,o,n" ||
								matches1 === "$,m,i,l,l,i,o,n" ||
								matches1 === "$,B,i,l,l,i,o,n" ||
								matches1 === "$,b,i,l,l,i,o,n" ||
								matches1 === "$,K" ||
								matches1 === "$,k" ||
								matches1 === "£,M" ||
								matches1 === "£,m" ||
								matches1 === "£,b" ||
								matches1 === "£,B" ||
								matches1 === "£,M,i,l,l,i,o,n" ||
								matches1 === "£,m,i,l,l,i,o,n" ||
								matches1 === "£,B,i,l,l,i,o,n" ||
								matches1 === "£,b,i,l,l,i,o,n" ||
								matches1 === "£,K" ||
								matches1 === "£,k") &&
							(matches2 === "M" ||
								matches2 === "m" ||
								matches2 === "b" ||
								matches2 === "B" ||
								matches2 === "M,i,l,l,i,o,n" ||
								matches2 === "m,i,l,l,i,o,n" ||
								matches2 === "B,i,l,l,i,o,n" ||
								matches2 === "b,i,l,l,i,o,n" ||
								matches2 === "£,M" ||
								matches2 === "£,m" ||
								matches2 === "£,b" ||
								matches2 === "£,B" ||
								matches2 === "£,M,i,l,l,i,o,n" ||
								matches2 === "£,m,i,l,l,i,o,n" ||
								matches2 === "£,B,i,l,l,i,o,n" ||
								matches2 === "£,b,i,l,l,i,o,n" ||
								matches2 === "£,K" ||
								matches2 === "£,k" ||
								matches2 === "$,M" ||
								matches2 === "$,m" ||
								matches2 === "$,b" ||
								matches2 === "$,B" ||
								matches2 === "$,M,i,l,l,i,o,n" ||
								matches2 === "$,m,i,l,l,i,o,n" ||
								matches2 === "$,B,i,l,l,i,o,n" ||
								matches2 === "$,b,i,l,l,i,o,n" ||
								matches2 === "$,K" ||
								matches2 === "$,k")
						) {
							if (
								((matches1 === "$,M" ||
									matches1 === "$,m" ||
									matches1 === "$,M,i,l,l,i,o,n" ||
									matches1 === "$,m,i,l,l,i,o,n") &&
									(matches2 === "M" ||
										matches2 === "m" ||
										matches2 === "M,i,l,l,i,o,n" ||
										matches2 === "m,i,l,l,i,o,n")) ||
								matches2 === "$,M" ||
								matches2 === "$,m" ||
								matches2 === "$,M,i,l,l,i,o,n" ||
								matches2 === "$,m,i,l,l,i,o,n" ||
								((matches1 === "$,B" ||
									matches1 === "$,b" ||
									matches1 === "$,B,i,l,l,i,o,n" ||
									matches1 === "$,b,i,l,l,i,o,n") &&
									(matches2 === "B" ||
										matches2 === "b" ||
										matches2 === "B,i,l,l,i,o,n" ||
										matches2 === "b,i,l,l,i,o,n")) ||
								matches2 === "$,B" ||
								matches2 === "$,b" ||
								matches2 === "$,B,i,l,l,i,o,n" ||
								matches2 === "$,b,i,l,l,i,o,n"
							) {
								if (parseFloat(value1) >= parseFloat(value2)) {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
								} else {
									//sandeep-task-3392--agency side---create rfp--company revenue issue
									let pattern_rev = new RegExp(/^.*[0-9].*$/);
									if (pattern_rev.test(temp_array)) {
										companyRevenueTextValidate = true;
									} else {
										document.getElementById(
											"customcompanyRevenueText"
										).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
										this.setState({ customCompanyRevenueTextDisplay: "block" });
										companyRevenueTextValidate = false;
										//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
									}
								}
							} else if (
								//rutuja 2832 replace == with ===
								((matches1 === "$,M" ||
									matches1 === "$,m" ||
									matches1 === "$,M,i,l,l,i,o,n" ||
									matches1 === "$,m,i,l,l,i,o,n") &&
									(matches2 === "B" ||
										matches2 === "b" ||
										matches2 === "B,i,l,l,i,o,n" ||
										matches2 === "b,i,l,l,i,o,n")) ||
								matches2 === "$,B" ||
								matches2 === "$,b" ||
								matches2 === "$,B,i,l,l,i,o,n" ||
								matches2 === "$,b,i,l,l,i,o,n"
							) {
								//sandeep-task-3392-agency side---create rfp--company revenue issue
								let pattern_rev = new RegExp(/^.*[0-9].*$/);
								if (pattern_rev.test(temp_array)) {
									companyRevenueTextValidate = true;
								} else {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
								}
								//rutuja 2832 replace == with ===
							} else if (
								(matches1 === "£,M" ||
									matches1 === "£,m" ||
									matches1 === "£,M,i,l,l,i,o,n" ||
									matches1 === "£,m,i,l,l,i,o,n") &&
								(matches2 === "£,B" ||
									matches2 === "£,b" ||
									matches2 === "£,B,i,l,l,i,o,n" ||
									matches2 === "£,b,i,l,l,i,o,n")
							) {
								//sandeep-task-3392--agency side---create rfp--company revenue issue
								let pattern_rev = new RegExp(/^.*[0-9].*$/);
								if (pattern_rev.test(temp_array)) {
									companyRevenueTextValidate = true;
								} else {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
								}
								//rutuja 2832 replace == with ===
							} else if (
								((matches1 === "£,M" ||
									matches1 === "£,m" ||
									matches1 === "£,M,i,l,l,i,o,n" ||
									matches1 === "£,m,i,l,l,i,o,n") &&
									(matches2 === "£,M" ||
										matches2 === "£,m" ||
										matches2 === "£,M,i,l,l,i,o,n" ||
										matches2 === "£,m,i,l,l,i,o,n")) ||
								((matches1 === "£,B" ||
									matches1 === "£,b" ||
									matches1 === "£,B,i,l,l,i,o,n" ||
									matches1 === "£,b,i,l,l,i,o,n") &&
									(matches2 === "£,B" ||
										matches2 === "£,b" ||
										matches2 === "£,B,i,l,l,i,o,n" ||
										matches2 === "£,b,i,l,l,i,o,n"))
							) {
								if (parseFloat(value1) >= parseFloat(value2)) {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
								} else {
									//sandeep-task-3392--agency side---create rfp--company revenue issue
									let pattern_rev = new RegExp(/^.*[0-9].*$/);
									if (pattern_rev.test(temp_array)) {
										companyRevenueTextValidate = true;
									} else {
										document.getElementById(
											"customcompanyRevenueText"
										).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
										this.setState({ customCompanyRevenueTextDisplay: "block" });
										companyRevenueTextValidate = false;
										//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
									}
								}
								//rutuja 2832 replace == with ===
							} else if (
								((matches1 === "$,B" ||
									matches1 === "$,b" ||
									matches1 === "$,B,i,l,l,i,o,n" ||
									matches1 === "$,b,i,l,l,i,o,n") &&
									(matches2 === "M" ||
										matches2 === "m" ||
										matches2 === "M,i,l,l,i,o,n" ||
										matches2 === "m,i,l,l,i,o,n" ||
										matches2 === "$,M" ||
										matches2 === "$,m" ||
										matches2 === "$,M,i,l,l,i,o,n" ||
										matches2 === "$,m,i,l,l,i,o,n")) ||
								((matches1 === "£,B" ||
									matches1 === "£,b" ||
									matches1 === "£,B,i,l,l,i,o,n" ||
									matches1 === "£,b,i,l,l,i,o,n") &&
									(matches2 === "M" ||
										matches2 === "m" ||
										matches2 === "M,i,l,l,i,o,n" ||
										matches2 === "m,i,l,l,i,o,n" ||
										matches2 === "£,M" ||
										matches2 === "£,m" ||
										matches2 === "£,M,i,l,l,i,o,n" ||
										matches2 === "£,m,i,l,l,i,o,n"))
							) {
								document.getElementById(
									"customcompanyRevenueText"
								).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customCompanyRevenueTextDisplay: "block" });
								companyRevenueTextValidate = false;
								//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
							} else if (
								//rutuja 2832 replace == with ===
								((matches1 === "£,K" || matches1 === "£,k") &&
									(matches2 === "£,K" || matches2 === "£,k")) ||
								((matches1 === "$,K" || matches1 === "$,k") &&
									(matches2 === "$,K" || matches2 === "$,k"))
							) {
								if (parseFloat(value1) >= parseFloat(value2)) {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
								} else {
									//sandeep-task-3392--agency side---create rfp--company revenue issue
									let pattern_rev = new RegExp(/^.*[0-9].*$/);
									if (pattern_rev.test(temp_array)) {
										companyRevenueTextValidate = true;
									} else {
										document.getElementById(
											"customcompanyRevenueText"
										).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
										this.setState({ customCompanyRevenueTextDisplay: "block" });
										companyRevenueTextValidate = false;
										//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
									}
								}
								//rutuja 2832 replace == with ===
							} else if (
								(matches1 === "$,K" ||
									matches1 === "$,k" ||
									matches1 === "£,K" ||
									matches1 === "£,k") &&
								(matches2 === "$,M" ||
									matches2 === "$,m" ||
									matches2 === "$,b" ||
									matches2 === "$,B" ||
									matches2 === "$,M,i,l,l,i,o,n" ||
									matches2 === "$,m,i,l,l,i,o,n" ||
									matches2 === "$,B,i,l,l,i,o,n" ||
									matches2 === "$,b,i,l,l,i,o,n" ||
									matches2 === "£,M" ||
									matches2 === "£,m" ||
									matches2 === "£,b" ||
									matches2 === "£,B" ||
									matches2 === "£,M,i,l,l,i,o,n" ||
									matches2 === "£,m,i,l,l,i,o,n" ||
									matches2 === "£,B,i,l,l,i,o,n" ||
									matches2 === "£,b,i,l,l,i,o,n")
							) {
								//sandeep-task-3392-agency side---create rfp--company revenue issue
								let pattern_rev = new RegExp(/^.*[0-9].*$/);
								if (pattern_rev.test(temp_array)) {
									companyRevenueTextValidate = true;
								} else {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
								}
							}
							//rutuja 2832 replace == with ===
						} else if (matches1 === "$" && matches2 === "$") {
							var value_temp1 = value1.replace("$", ""); //Sandeep-task-3392-added code for $ issue
							var value_temp2 = value2.replace("$", ""); //Sandeep-task-3392-added code for $ issue
							if (
								parseFloat(value_temp1) >= parseFloat(value_temp2) ||
								(value_temp1 === "" && value_temp2 !== "") //rutuja 2832 replace == with ===
							) {
								//Sandeep-task-3392-added code for $ issue
								document.getElementById(
									"customcompanyRevenueText"
								).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customCompanyRevenueTextDisplay: "block" });
								companyRevenueTextValidate = false;
								//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
							} else {
								//sandeep-task-3392--agency side---create rfp--company revenue issue
								let pattern_rev = new RegExp(/^.*[0-9].*$/);
								if (
									pattern_rev.test(value_temp1) ||
									pattern_rev.test(value_temp2)
								) {
									companyRevenueTextValidate = true;
								} else {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
								}
							}
						} else if (
							//rutuja 2832 replace == with ===
							(matches1 === "K" || matches1 === "k") &&
							(matches2 === "K" || matches2 === "k")
						) {
							if (parseFloat(value1) <= parseFloat(value2)) {
								document.getElementById(
									"customcompanyRevenueText"
								).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customCompanyRevenueTextDisplay: "block" });
								companyRevenueTextValidate = false;
								//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
							} else {
								//sandeep-task-3392--agency side---create rfp--company revenue issue
								let pattern_rev = new RegExp(/^.*[0-9].*$/);
								if (pattern_rev.test(temp_array)) {
									companyRevenueTextValidate = true;
								} else {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
								}
							}
							//rutuja 2832 replace == with ===
						} else if (matches1 === "£" && matches2 === "£") {
							if (parseFloat(value1) <= parseFloat(value2)) {
								document.getElementById(
									"customcompanyRevenueText"
								).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customCompanyRevenueTextDisplay: "block" });
								companyRevenueTextValidate = false;
								//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
							} else {
								//sandeep-task-3392--agency side---create rfp--company revenue issue
								let pattern_rev = new RegExp(/^.*[0-9].*$/);
								if (pattern_rev.test(temp_array)) {
									companyRevenueTextValidate = true;
								} else {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;	//Sandeep-task-3396-error msg issue
								}
							}
						} else {
							let pattern3 = new RegExp(/[0-9.]*$/);//rutuja 2832 //4625-Nilesh  remove "^" char from pattern3 
							if (pattern3.test(value1) && pattern3.test(value2)) {
								//Sandeep-task-3392-added code for comparision
								if (parseFloat(value1) >= parseFloat(value2)) {
									document.getElementById(
										"customcompanyRevenueText"
									).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
									this.setState({ customCompanyRevenueTextDisplay: "block" });
									companyRevenueTextValidate = false;
									//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
								} else {
									companyRevenueTextValidate = true;
								}
							} else {
								document.getElementById(
									"customcompanyRevenueText"
								).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customCompanyRevenueTextDisplay: "block" });
								companyRevenueTextValidate = false;
								return companyRevenueTextValidate;
							}
						}
					} else {
						if (temp_array.match(/[.]/g)) {
							let val1 = temp_array.split(".").length;
							if (val1 > 2) {
								document.getElementById(
									"customcompanyRevenueText"
								).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customCompanyRevenueTextDisplay: "block" });
								companyRevenueTextValidate = false;
								//return companyRevenueTextValidate;//Sandeep-task-3396-error msg issue
							}
						} else {
							//snehal-task-3344-edit camp---error msg issue
							let pattern_rev = new RegExp(/^.*[0-9].*$/);
							// let pattern_rev= new RegExp(/^[1-9+<>][0-9+<>\n]*$/)//Sandeep-task-3392-added new rexx
							if (pattern_rev.test(temp_array)) {
								companyRevenueTextValidate = true;
							} else {
								companyRevenueTextValidate = false;
								//Sandeep-task-3396-error msg issue
								document.getElementById(
									"customcompanyRevenueText"
								).style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
								this.setState({ customCompanyRevenueTextDisplay: "block" });
								return companyRevenueTextValidate;
							}
						}
					}
				}
			}
		}

		if (this.state.customIndustryText.length > 0) {
			let pattern2 = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z,()\|\/\+&@\-\.\n ]+$/);//rutuja 2832
			if (!pattern2.test(this.state.customIndustryText)) {
				document.getElementById("customIndustryText").style.border =
					"1px solid red"; //Sandeep-task-3396-error msg issue
				this.setState({ customIndustryTextDisplay: "block" });
				dateflag = false;
			}
		} //end of this.state.customIndustryText.length > 0
		if (this.state.customJobLevel.length > 0) {
			for (let p = 0; p < this.state.customJobLevel.length; p++) {
				if (this.state.customJobLevel[p].value !== "") {
					// var pattern3 = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z,():\|\/\+&@\-\.\n ]+$/);
					let pattern3 = new RegExp(/^[A-Za-z ,.\-&:()]+$/);//rutuja 2832
					if (!pattern3.test(this.state.customJobLevel[p].value)) {
						this.setState({ customJobLevelDisplay: "block" });
						dateflag = false;
						// return dateflag
					}
				}
			}
		} //end of this.state.customJobLevel.length > 0)
		//  Sandeep-task-2851-added validation for customJOb Level and customJobFunction
		//Sandeep-task-3799--Local/PAT/prod--RFP campaign--job level, job function validation issue
		if (this.state.customJobLevelText != "") {
			//Sandeep-task-3396-added code for error msg
			let pattern3 = new RegExp(
				/^(?=.*?[A-Za-z])[A-Za-z,():\|\/\+&@\-\.\n ]+$/
			);//rutuja 2832
			// var pattern3 = new RegExp(/^[A-Za-z ,.\-&:()]+$/)
			if (!pattern3.test(this.state.customJobLevelText)) {
				document.getElementById("customjobLevelText").style.border =
					"1px solid red"; //Sandeep-task-3396-error msg issue
				this.setState({ customJobLevelDisplay: "block" });
				dateflag = false;
				// return dateflag  //Sandeep-task-3396-added code for error msg
			}
		} //end of this.state.customJobLevelText !=""
		//Sandeep-task-3799--Local/PAT/prod--RFP campaign--job level, job function validation issue
		if (this.state.customJobFunctionText != "") {
			//Sandeep-task-3396-added code for error msg

			// var pattern3 = new RegExp(/^[A-Za-z ,.\-&:()]+$/)
			let pattern3 = new RegExp(
				/^(?=.*?[A-Za-z])[A-Za-z,():\|\/\+&@\-\.\n ]+$/
			);//rutuja 2832
			if (!pattern3.test(this.state.customJobFunctionText)) {
				document.getElementById("customjobFunctionText").style.border =
					"1px solid red"; //Sandeep-task-3396-error msg issue
				this.setState({ customJobFunctionDisplay: "block" });
				dateflag = false;
				// return dateflag //Sandeep-task-3396-added code for error msg
			}
		} //end of this.state.customJobFunctionText !=""

		if (this.state.customJobFunction.length > 0) {
			for (let p = 0; p < this.state.customJobFunction.length; p++) {
				if (this.state.customJobFunction[p].value !== "") {
					let pattern3 = new RegExp(/^[A-Za-z ,.\-&:()]+$/); //rutuja 2832
					if (!pattern3.test(this.state.customJobFunction[p].value)) {
						this.setState({ customJobFunctionDisplay: "block" });
						dateflag = false;
						return dateflag;
					}
				}
			}
		}
		if (this.state.BasicDetailArray[0].jobTitle.length > 0) {
			let pattern = new RegExp(/^[A-Za-z\n,| \-.&:()]+$/); //rutuja 2832
			if (!pattern.test(this.state.BasicDetailArray[0].jobTitle)) {
				document.getElementById("jobTitle").style.border = "1px solid red"; //Sandeep-task-3396-error msg issue
				this.setState({ jobTitleErrMsg: "block" });
				dateflag = false;
			}
		}
		if (this.state.BasicDetailArray[0].noOfCustomQuestions.length > 0) {
			// if(isNaN(this.state.BasicDetailArray[0].noOfCustomQuestions)==true){
			let pattern = new RegExp(/^(([0-9]*)|(([0-9]*)))$/); //rutuja 2832
			if (!pattern.test(this.state.BasicDetailArray[0].noOfCustomQuestions)) {
				// alert("error msg")
				document.getElementById("customQuestionNumbr").style.border =
					"1px solid red"; //Sandeep-task-3396-error msg issue
				this.setState({ customQuestionErMsg: "block" });
				dateflag = false;
			}
		}
		//Sandeep-task-3518-added code  for publication validation
		if (this.state.BasicDetailArray[0].publication.length > 0) {
			let pattern2 = new RegExp(/^[A-Za-z0-9]*$/); //rutuja 2832
			if (!pattern2.test(this.state.BasicDetailArray[0].publication)) {
				document.getElementById("publication").style.border = "1px solid red";
				this.setState({ publicationErMsg: "block" });
				dateflag = false;
			}
		}
		//rutuja task -4323 RFP clone campaign
		//Sandeep-task-3518-added code  for tal validation
		// if (this.state.BasicDetailArray[0].tal.length > 0) {
		// 	var pattern2 = new RegExp(/^[A-Za-z0-9]*$/);
		// 	console.log("publication" + JSON.stringify(this.state.BasicDetailArray[0].tal));
		// 	alert("publication" + JSON.stringify(this.state.BasicDetailArray[0].tal));
		// 	if (!pattern2.test(this.state.BasicDetailArray[0].tal)) {
		// 		document.getElementById("tal").style.border = "1px solid red";
		// 		this.setState({ talErMsg: "block" });
		// 		dateflag = false;
		// 	}
		// }

		//Sandeep-task-3518-added code  for audienceMatch validation
		if (this.state.BasicDetailArray[0].audienceMatch.length > 0) {
			let pattern2 = new RegExp(/^[A-Za-z0-9]*$/); //rutuja 2832
			if (!pattern2.test(this.state.BasicDetailArray[0].audienceMatch)) {
				document.getElementById("audienceMatch").style.border = "1px solid red";
				this.setState({ audienceMatchErMsg: "block" });
				dateflag = false;
			}
		}

		//* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE(added flag for client name) */
		if (
			clientNameFlag == true &&
			campNameFlag == true &&
			dateflag == true &&
			companyRevenueToFromValidate == true &&
			companyRevenueTextValidate == true
		) {
			finalFormIsValid = true;
		} else {
			finalFormIsValid = false;
		}
		return finalFormIsValid;
	}
	submitForm(e) {
		e.preventDefault();
		// const self = this; //karan-task-3803-replace query params //rutuja 2832
		if (this.validateRfp(e) == false) {
		} else {
			this.loadingRef.current.click();
			var data = new FormData();
			if (this.state.rfpCampaignID) {
				data.append("rfpCampaignID", this.state.rfpCampaignID);
			}

			// data.append("agencyID", this.state.agencyID);
			// alert("appendagencyid==>"+this.state.agencyID);

			data.append("region", this.state.region);
			// alert("this.state.region==="+this.state.region)
			data.append("beneluxCountry", this.state.BeneluxcheckedList);
			data.append("dachCountry", this.state.DACHcheckedList);
			data.append("nordicCountry", this.state.NordiccheckedList);
			data.append(
				"northAfricaCountry",
				this.state.NorthernAfricaCountriescheckedList
			);
			data.append(
				"southernAfricaCountry",
				this.state.SouthernAfricaCountriescheckedList
			);
			data.append(
				"eastAfricanCountry",
				this.state.EastAfricanCountriescheckedList
			);
			data.append("westAfricaCountry", this.state.WesternAfricacheckedList);
			data.append("easternEuropeCountry", this.state.EasternEuropecheckedList);
			data.append(
				"northernEuropeCountry",
				this.state.NorthernEuropecheckedList
			);
			data.append(
				"southernEuropeCountry",
				this.state.SouthernEuropecheckedList
			);
			data.append("westernEuropeCountry", this.state.WesternEuropecheckedList);
			data.append("centralAsiaCountry", this.state.CentralAsiacheckedList);
			data.append(
				"centralOrMiddleAfricanCountry",
				this.state.CentralOrMiddleAfricancheckedList
			);
			data.append("southAsiaCountry", this.state.SouthAsiacheckedList);
			//alert("this.state.SouthAsiacheckedList==="+this.state.SouthAsiacheckedList)
			data.append("eastAsiaCountry", this.state.EastAsiacheckedList);
			data.append("southeastAsiaCountry", this.state.SoutheastAsiacheckedList);
			data.append("westernAsiaCountry", this.state.WesternAsiacheckedList);
			data.append("southAmericaCountry", this.state.SouthAmericacheckedList);
			data.append("antarcticCountry", this.state.AntarcticcheckedList);
			data.append("northAmericaCountry", this.state.NorthAmericacheckedList);
			data.append(
				"australiaAndNewZealandCountry",
				this.state.AustraliaAndNewZealandcheckedList
			);
			data.append("melanesiaCountry", this.state.MelanesiacheckedList);
			data.append("middleeastcountry", this.state.middleEastCheckedList);
			//  alert("MiddleEast===>"+JSON.stringify(this.state.middleEastCheckedList));
			data.append("polynesiaCountry", this.state.PolynesiacheckedList);
			data.append("micronesia", this.state.MicronesiacheckedList);
			data.append(
				"southernAtlanticOcean",
				this.state.SouthernAtlanticOceancheckedList
			);
			data.append(
				"rfpCampDetails",
				JSON.stringify(this.state.BasicDetailArray)
			);
			data.append(
				"customEmpSize",
				JSON.stringify(this.state.employeeSizetofrom)
			);
			data.append(
				"customEmployeeSizeText",
				JSON.stringify(this.state.customEmployeeSizeText)
			);
			data.append("employeeSize", this.state.employeeSizeDB);
			data.append("industryApplicable", this.state.IndustryNotApplicable);
			data.append("industry", this.state.industryDB);
			data.append("customIndustry", this.state.customIndustryText);
			data.append("customJobLevel", this.state.customJobLevelText);
			data.append("customJobFunction", this.state.customJobFunctionText);
			data.append("customEmployeeSize", this.state.customEmployeeSizeText);
			data.append("customCompanyRevenue", this.state.customCompanyRevenueText);
			// alert("this.state.customJobLevelText===="+ this.state.customJobLevelText)
			// alert("this.state.customJobFunctionText===="+ this.state.customJobFunctionText)
			// alert("this.state.customEmployeeSizeText===="+ this.state.customEmployeeSizeText)
			// alert("this.state.customCompanyRevenueText===="+ this.state.customCompanyRevenueText)
			data.append("jobLevel", this.state.jobLevelDB);
			// data.append("customJobLevel", JSON.stringify(this.state.customJobLevel));
			data.append("jobFunction", this.state.jobFunctionDB);
			// data.append("customJobFunction", JSON.stringify(this.state.customJobFunction));
			data.append("companyRevenue", this.state.companyRevenueDB);
			//data.append("customCompRevenue", JSON.stringify(this.state.companyRevenuetofrom));
			//data.append("customCompanyRevenueText", JSON.stringify(this.state.customCompanyRevenueText));
			data.append("docNumberArray", this.state.docNumber);

			data.append(
				"countryWiseAllocation",
				JSON.stringify(this.state.countryWiseAllocationArray) //sunita-task- 3645- submitting data to BE
			);

			data.append("countrywiseLeadAllocation", this.state.geoLeadChk); //sunita-task- 3645- submitting data to BE
			data.append("status", this.state.status);//Somnath Task-4042, Add Status in data
			// alert("this.state.basicdetailarray======"+JSON.stringify(this.state.BasicDetailArray))
			fetch("requestForProposal/createRFPCampaignNewFlow", {
				method: "POST",
				body: data,
			})
				.then((res) => res.json())
				.then((response) => {
					//  alert("hey")
					//Sandeep-task-3703-PAT/Local--Agency side--create RFP--Add Advertiser--Back button issue
					localStorage.removeItem("campaignType");
					localStorage.removeItem("biddingType");
					localStorage.removeItem("campaignName");
					localStorage.removeItem("clientName");
					localStorage.removeItem("netCPL");
					localStorage.removeItem("rateCardCPL");
					localStorage.removeItem("startDate");
					localStorage.removeItem("endDate");
					localStorage.removeItem("biddingEndDate");
					localStorage.removeItem("audienceMatch");
					localStorage.removeItem("leadAllocation");
					localStorage.removeItem("CPL");
					localStorage.removeItem("tal");
					localStorage.removeItem("currency");
					localStorage.removeItem("publication");
					localStorage.removeItem("noOfCustomQuestions");
					localStorage.removeItem("durationType");
					localStorage.removeItem("jobTitle");
					localStorage.removeItem("otherSpecs");
					localStorage.removeItem("employeeSize");
					localStorage.removeItem("customemployeeSize");
					localStorage.removeItem("jobLevel");
					localStorage.removeItem("customjobLevel");
					localStorage.removeItem("companyRevenue");
					localStorage.removeItem("customCompanyRevenue");
					localStorage.removeItem("jobFunction");
					localStorage.removeItem("customJobFunction");
					localStorage.removeItem("industry");
					localStorage.removeItem("jobFunction");
					localStorage.removeItem("customIndustryText");
					this.closeLoadingRef.current.click();
					if (response.key === "create") {
						Swal.fire({
							heightAuto: false,
							text: "RFP created successfully.",
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								// window.location.reload();
								//karan-task-3636-handles automatic redirect-start
								//karan-task-3677-redirect to progress page only
								//self.props.history.push("/rfpProgress"); //karan-task-3803-replace query params
								//karan-task-3636-handles automatic redirect-end
								window.location.href = "/rfpProgress"; //rutuja task -4323- autoredirect 
								
							},
						});
					} else {
						//snehal-task-3509-Project's(DI) - Create RFP through XLS - FE
						if (this.state.status === "RFPXlsx") {
							Swal.fire({
								heightAuto: false,
								text: "RFP created successfully.",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									// window.location.reload();
									//karan-task-3636-handles automatic redirect-start
									//karan-task-3677-redirect to progress page only
									//self.props.history.push("/rfpProgress"); //karan-task-3803-replace query params
									//karan-task-3636-handles automatic redirect-end
									window.location.href = "/rfpProgress"; //rutuja task -4323- autoredirect 
								},
							});
						} else {
							Swal.fire({
								heightAuto: false,
								text: "RFP edited successfully.",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									//karan-task-3636-handles automatic redirect-start
									//karan-task-3677-redirect to progress page only
									//self.props.history.push("/rfpProgress"); //karan-task-3803-replace query params
									//karan-task-3636-handles automatic redirect-end
									window.location.href = "/rfpProgress"; //rutuja task -4323- autoredirect 
								},
							});
						
						}

					}
				});
		}
	}

	//sunita-task-3645-added handle change for countryWiseLeadAllocation
	openCountryWiseLeadAllocation() {
		// sunita-task-3736 added code as per requirement
		let error = {};
		error["requireLead"] = "";
		this.setState({ error: error });
		let commonCountryArray = this.state.BeneluxcheckedList.concat(
			this.state.DACHcheckedList,
			this.state.NordiccheckedList,
			this.state.NorthernAfricaCountriescheckedList,
			this.state.CentralOrMiddleAfricancheckedList,
			this.state.SouthernAfricaCountriescheckedList,
			this.state.EastAfricanCountriescheckedList,
			this.state.WesternAfricacheckedList,
			this.state.EasternEuropecheckedList,
			this.state.NorthernEuropecheckedList,
			this.state.SouthernEuropecheckedList,
			this.state.WesternEuropecheckedList,
			this.state.CentralAsiacheckedList,
			this.state.EastAsiacheckedList,
			this.state.SouthAsiacheckedList,
			this.state.SoutheastAsiacheckedList,
			this.state.WesternAsiacheckedList,
			this.state.SouthAmericacheckedList,
			this.state.AntarcticcheckedList,
			this.state.NorthAmericacheckedList,
			this.state.AustraliaAndNewZealandcheckedList,
			this.state.MelanesiacheckedList,
			this.state.middleEastCheckedList,
			this.state.PolynesiacheckedList,
			this.state.MicronesiacheckedList,
			this.state.SouthernAtlanticOceancheckedList
		);
		let countryWiseAllocationArray = [];
		let len = commonCountryArray.length;
		let tempCountryAllocation = [...this.state.tempCountryAllocation];
		for (let i = 0; i < len; i++) {
			countryWiseAllocationArray.push({
				country: commonCountryArray[i],
				leadCount: "", //sunita-task- 3736- added key as per BE
			});
		}
		//  alert("countrywiseallocation=>"+JSON.stringify(countryWiseAllocationArray));
		countryWiseAllocationArray.map((x) =>
			Object.assign(
				x,
				tempCountryAllocation.find((y) => y.country == x.country)
			)
		);
		//  alert("countryWiseAllocationArray=>"+JSON.stringify(countryWiseAllocationArray));
		this.setState({
			countryWiseAllocationArray: countryWiseAllocationArray,
			countrywiseLeadDisplay2: "none",
			countrywiseLeadDisplay: "none",
			countrywiseLeadDisplay3: "none",
			countrywiseLeadDisplay4: "none",
		}); //sunita-task-3736-setstate the values as per requirement.
	} // End of openCountryWiseLeadAllocation tempCountryAllocation
	/**
	 * @author Snehal More
	 * @param  Description handle change to get country count only
	 * @return Description handle change to get country cout used to lead allocation task
	 */
	getTotalCountries() {
		let getTotalCountriesCount = this.state.BeneluxcheckedList.concat(
			this.state.DACHcheckedList,
			this.state.NordiccheckedList,
			this.state.NorthernAfricaCountriescheckedList,
			this.state.CentralOrMiddleAfricancheckedList,
			this.state.SouthernAfricaCountriescheckedList,
			this.state.EastAfricanCountriescheckedList,
			this.state.WesternAfricacheckedList,
			this.state.middleEastCheckedList,
			this.state.EasternEuropecheckedList,
			this.state.NorthernEuropecheckedList,
			this.state.SouthernEuropecheckedList,
			this.state.WesternEuropecheckedList,
			this.state.CentralAsiacheckedList,
			this.state.EastAsiacheckedList,
			this.state.SouthAsiacheckedList,
			this.state.SoutheastAsiacheckedList,
			this.state.WesternAsiacheckedList,
			this.state.SouthAmericacheckedList,
			this.state.AntarcticcheckedList,
			this.state.NorthAmericacheckedList,
			this.state.AustraliaAndNewZealandcheckedList,
			this.state.MelanesiacheckedList,
			this.state.PolynesiacheckedList,
			this.state.MicronesiacheckedList,
			this.state.SouthernAtlanticOceancheckedList
		);
		this.setState({ getTotalCountriesCount });
		this.openCountryWiseLeadAllocation();
	}
	onChangeRegion = (RegioncheckedList) => {
		this.setState({
			RegioncheckedList,
			indeterminate:
				!!RegioncheckedList.length &&
				RegioncheckedList.length < RegionOption.length,
			checkAllRegion: RegioncheckedList.length === RegionOption.length,
		});
	};

	onCheckAllChangeRegion = (e) => {
		this.setState({
			RegioncheckedList: e.target.checked ? RegionOption : [],
			indeterminate: false,
			checkAllRegion: e.target.checked,
		});
	};

	onCheckAllChangeBenelux = (e) => {
		//Sandeep-task-3419-region issue
		var region = "benelux";
		if (!this.state.region.includes("benelux")) {
			//Sandeep-task-3419-region issue
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("benelux"); //Sandeep-task-3419-region issue
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		var countryArray = [];
		if (e.target.checked) {
			//benelux checked
			for (var i = 0; i < 3; i++) {
				countryArray.push(BeneluxOptions[i]);
			}

			if (this.state.dachChecked) {
				//dach already checked
				//rutuja 2832
				for (let i = 0; i < 3; i++) {
					countryArray.push(DACHOptions[i]);
				}
				//alert("dach also true"+JSON.stringify(countryArray));
				this.setState(
					{
						WesternEuropecheckedList: this.state.westernEuropeChecked
							? WesternEuropeOptions
							: countryArray,
						indeterminateEur4: !e.target.checked,
						checkAll23: e.target.checked,
						beneluxChecked: !this.state.beneluxChecked,
					},
					function () {
						Swal.fire({
							heightAuto: false,
							text: "Benelux Countries Selected",
						});
					}
				);
			} else {
				this.setState(
					{
						WesternEuropecheckedList: this.state.westernEuropeChecked
							? WesternEuropeOptions
							: countryArray,
						indeterminateEur4: false,
						checkAll23: e.target.checked,
						beneluxChecked: !this.state.beneluxChecked,
					},
					function () {
						Swal.fire({
							heightAuto: false,
							text: "Benelux Countries Selected",
						});
					}
				);
			}
		} else {
			if (this.state.dachChecked) {
				//benelux checked
				this.setState(
					{
						WesternEuropecheckedList: this.state.dachChecked ? DACHOptions : [],
						indeterminate: false,
						checkAll23: e.target.checked,
						beneluxChecked: !this.state.beneluxChecked,
					},
					function () {
						Swal.fire({ heightAuto: false, text: "Benelux Countries Removed" });
					}
				);
			} else {
				this.setState(
					{
						WesternEuropecheckedList: this.state.westernEuropeChecked
							? WesternEuropeOptions
							: [],
						indeterminate: false,
						checkAll23: e.target.checked,
						beneluxChecked: !this.state.beneluxChecked,
					},
					function () {
						Swal.fire({ heightAuto: false, text: "Benelux Countries Removed" });
					}
				);
			}
		}
	};

	onCheckAllChangeDACH = (e) => {
		//Sandeep-task-3419-region issue
		var region = "dach";
		if (!this.state.region.includes("dach")) {
			//Sandeep-task-3419-region issue
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("dach"); //Sandeep-task-3419-region issue
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		var countryArray = [];
		if (e.target.checked) {
			//dach checked
			for (var i = 0; i < 3; i++) {
				countryArray.push(DACHOptions[i]);
			}

			if (this.state.beneluxChecked) {
				//benelux checked
				//rutuja 2832
				for (let i = 0; i < 3; i++) {
					countryArray.push(BeneluxOptions[i]);
				}

				//alert("benelux also true"+JSON.stringify(countryArray));
				this.setState(
					{
						WesternEuropecheckedList: this.state.westernEuropeChecked
							? WesternEuropeOptions
							: countryArray,
						indeterminate: false,
						checkAll24: e.target.checked,
						dachChecked: !this.state.dachChecked,
					},
					function () {
						Swal.fire({ heightAuto: false, text: "DACH Countries Selected" });
					}
				);
			} else {
				this.setState(
					{
						WesternEuropecheckedList: this.state.westernEuropeChecked
							? WesternEuropeOptions
							: countryArray,
						indeterminate: false,
						checkAll24: e.target.checked,
						dachChecked: !this.state.dachChecked,
					},
					function () {
						Swal.fire({ heightAuto: false, text: "DACH Countries Selected" });
					}
				);
			}
		} else {
			if (this.state.beneluxChecked) {
				//benelux checked
				this.setState(
					{
						WesternEuropecheckedList: this.state.beneluxChecked
							? BeneluxOptions
							: [],
						indeterminate: false,
						checkAll24: e.target.checked,
						dachChecked: !this.state.dachChecked,
					},
					function () {
						Swal.fire({ heightAuto: false, text: "DACH Countries Removed" });
					}
				);
			} else {
				this.setState(
					{
						WesternEuropecheckedList: this.state.westernEuropeChecked
							? WesternEuropeOptions
							: [],
						indeterminate: false,
						checkAll24: e.target.checked,
						dachChecked: !this.state.dachChecked,
					},
					function () {
						Swal.fire({ heightAuto: false, text: "DACH Countries Removed" });
					}
				);
			}
		}
	};
	

	onCheckAllChangeNordic = (e) => {
		var region = "nordic"; //Sandeep-task-3419-region issue
		if (!this.state.region.includes("nordic")) {
			//Sandeep-task-3419-region issue
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("nordic"); //Sandeep-task-3419-region issue
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		if (e.target.checked) {
			this.setState(
				{
					NorthernEuropecheckedList: e.target.checked
						? this.state.northerneuropeChecked
							? NorthernEuropeOptions
							: NordicOptions
						: [],
					NordicOptions: [],
					indeterminate: false,
					checkAll25: e.target.checked,
					nordicChecked: !this.state.nordicChecked,
				},
				function () {
					Swal.fire({ heightAuto: false, text: "Nordic Countries Selected" });
				}
			);
		} else {
			this.setState(
				{
					NorthernEuropecheckedList:
						e.target.checked === false
							? this.state.northerneuropeChecked
								? NorthernEuropeOptions
								: []
							: [],
					indeterminate: false,
					checkAll25: e.target.checked,
					nordicChecked: !this.state.nordicChecked,
				},
				function () {
					Swal.fire({ heightAuto: false, text: "Nordic Countries Removed" });
				}
			);
		}
	};

	
	onCheckAllChangeAustraliaAndNewZealand = (e) => {
		var region = "anz"; //Sandeep-task-3419-region issue

		if (!this.state.region.includes("anz")) {
			//Sandeep-task-3419-region issue
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("anz"); //Sandeep-task-3419-region issue
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		if (e.target.checked) {
			this.setState(
				{
					SouthAsiacheckedList: e.target.checked
						? this.state.southAsiaChecked
							? SouthAsiaOptions
							: ANZOptions
						: [],
					ANZOptions: [],
					indeterminate: false,
					checkAll18: e.target.checked,
					anzChecked: !this.state.anzChecked,
				},
				function () {
					Swal.fire({ heightAuto: false, text: "ANZ Countries Selected" });
				}
			);
		} else {
			this.setState(
				{
					SouthAsiacheckedList:
						e.target.checked === false
							? this.state.southAsiaChecked
								? SouthAsiaOptions
								: []
							: [],
					indeterminate: false,
					checkAll18: e.target.checked,
					anzChecked: !this.state.anzChecked,
				},
				function () {
					Swal.fire({ heightAuto: false, text: "ANZ Countries Removed" });
				}
			);
		}
	};
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////onCheckAllChangeAPAC
	onCheckAllChangeAPAC = (e) => {
		if (e.target.checked == true) {
			this.setState({ ANZdisabled: true });
		} else {
			this.setState({ ANZdisabled: false });
		}
		var region = "apac"; //Sandeep-task-3419-region issue

		if (!this.state.region.includes("apac")) {
			//Sandeep-task-3419-region issue
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		} else {
			var index = this.state.region.indexOf("apac"); //Sandeep-task-3419-region issue
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		var countryArray = [];
		var countryArray1 = [];
		var countryArray2 = [];
		var countryArray3 = [];
		if (e.target.checked == true) {
			if (this.state.anzChecked == true || this.state.checkAll18 == true) {
				let index = this.state.region.indexOf("anz"); //Sandeep-task-3419-region issue //rutuja 2832
				if (index > -1) {
					this.state.region.splice(index, 1);
				}
			}
			if (this.state.southAsiaChecked) {
				countryArray = SouthAsiaOptions;
			} else {
				countryArray = [
					"Sri Lanka",
					"Bangladesh",
					"India",
					"Afghanistan",
					"Pakistan",
					"Bhutan",
					"Nepal",
					"Australia",
					"New Zealand",
				];
			}

			if (this.state.southeastAsiaChecked) {
				countryArray1 = SoutheastAsiaOptions;
			} else {
				countryArray1 = [
					"Indonesia",
					"Malaysia",
					"Singapore",
					"Thailand",
					"Tibet",
				];
			}

			if (this.state.eastAsiaChecked) {
				countryArray2 = EastAsiaOptions;
			} else {
				countryArray2 = [
					"China",
					"South Korea",
					"Japan",
					"Hong Kong",
					"Taiwan",
				];
			}

			if (this.state.centralAsiaChecked) {
				countryArray3 = CentralAsiaOptions;
			} else {
				countryArray3 = [
					"Kazakhstan",
					"Kyrgyzstan",
					"Tajikistan",
					"Uzbekistan",
					"Turkmenistan",
				];
			}
			//SoutheastAsiacheckedList:countryArray1,
			this.setState(
				{
					SouthAsiacheckedList: countryArray,
					SoutheastAsiacheckedList: countryArray1,
					EastAsiacheckedList: countryArray2,
					CentralAsiacheckedList: countryArray3,
					indeterminate: false,
					checkAll26: e.target.checked,
					apacChecked: !this.state.apacChecked,
				},
				function () {
					Swal.fire({ heightAuto: false, text: "APAC Countries Selected" });
				}
			);
		} else {
			if (this.state.southAsiaChecked) {
				countryArray = SouthAsiaOptions;
			} else {
				countryArray = [];
			}

			if (this.state.southeastAsiaChecked) {
				countryArray1 = SoutheastAsiaOptions;
			} else {
				countryArray1 = [];
			}

			if (this.state.eastAsiaChecked) {
				countryArray2 = EastAsiaOptions;
			} else {
				countryArray2 = [];
			}

			if (this.state.centralAsiaChecked) {
				countryArray3 = CentralAsiaOptions;
			} else {
				countryArray3 = [];
			}

			this.setState(
				{
					SouthAsiacheckedList: countryArray,
					SoutheastAsiacheckedList: countryArray1,
					EastAsiacheckedList: countryArray2,
					CentralAsiacheckedList: countryArray3,
					indeterminate: false,
					checkAll26: e.target.checked,
					apacChecked: !this.state.apacChecked,
				},
				function () {
					Swal.fire({ heightAuto: false, text: "APAC Countries Removed" });
				}
			);

			if (this.state.anzChecked == true || this.state.checkAll18 == true) {
				this.state.region.push("anz"); //Sandeep-task-3419-region issue
				this.setState(
					{
						SouthAsiacheckedList: this.state.checkAll18
							? this.state.southAsiaChecked
								? SouthAsiaOptions
								: ANZOptions
							: [],
						ANZOptions: [],
						indeterminate: false,
					},
					function () {
						Swal.fire({ heightAuto: false, text: "APAC Countries Removed" }); // Rutuja 4290- popup show wrong msg
					}
				);
			}
		}
	};

	////////////////////////////////////////////////////////////
	onChangeNorthernAfricaCountries = (NorthernAfricaCountriescheckedList) => {
		var region = "Northern Africa Countries";

		if (NorthernAfricaCountriescheckedList.length > 0) {
			if (!this.state.region.includes("Northern Africa Countries")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				NorthernAfricaCountriescheckedList,
				indeterminateAfr1:
					!!NorthernAfricaCountriescheckedList.length &&
					NorthernAfricaCountriescheckedList.length <
						NorthernAfricaCountriesOptions.length,
				checkAll1:
					NorthernAfricaCountriescheckedList.length ===
					NorthernAfricaCountriesOptions.length,
			},
			function () {
				this.onCheckAfricaSelectAll();
			}
		);
		if (NorthernAfricaCountriescheckedList.length == 0) {
			var index = this.state.region.indexOf("Northern Africa Countries");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	/**
	 * @author Narendra Phadke
	 * @param  Description  For Select All of Europe Region
	 */
	onCheckAfricaSelectAll = (e) => {
		if (
			this.state.checkAll1 === true &&
			this.state.checkAll2 === true &&
			this.state.checkAll3 === true &&
			this.state.checkAll4 === true &&
			this.state.checkAll5 === true
		) {
			this.setState({ checkAfricaSelectAll: true }, function () {
				this.onCheckRegionSelectAll(e);
			});
		} else {
			this.setState({ checkAfricaSelectAll: false }, function () {
				this.onCheckRegionSelectAll(e);
			});
		}
	}; //End OF Select all Handle change of region

	/**
	 * @author Narendra Phadke
	 * @param  Description  Select All for Africa Region
	 */
	onCheckAllChangeAfricaRegion = (e) => {
		this.onCheckAllChangeNorthernAfricaCountries(e);
		this.onCheckAllChangeCentralOrMiddleAfrican(e);
		this.onCheckAllChangeSouthernAfricaCountries(e);
		this.onCheckAllChangeEastAfricanCountries(e);
		this.onCheckAllChangeWesternAfrica(e);
	};

	onCheckAllChangeNorthernAfricaCountries = (e) => {
		var region = "Northern Africa Countries";
		// alert("onCheckAllChangeNorthernAfricaCountries"+e.target.checked);
		if (!this.state.region.includes("Northern Africa Countries")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Northern Africa Countries");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				NorthernAfricaCountriescheckedList: e.target.checked
					? NorthernAfricaCountriesOptions
					: [],
				indeterminateAfr1: false,
				checkAll1: e.target.checked,
			},
			function () {
				this.onCheckAfricaSelectAll(e);
			}
		);
	};
	//////////////////////////////////////////////////////
	onChangeCentralOrMiddleAfrican = (CentralOrMiddleAfricancheckedList) => {
		//alert("onChangeAfrica"+africacheckedList);
		var region = "Central or Middle African";
		if (CentralOrMiddleAfricancheckedList.length > 0) {
			if (!this.state.region.includes("Central or Middle African")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				CentralOrMiddleAfricancheckedList,
				indeterminateAfr2:
					!!CentralOrMiddleAfricancheckedList.length &&
					CentralOrMiddleAfricancheckedList.length <
						CentralOrMiddleAfricanOptions.length,
				checkAll2:
					CentralOrMiddleAfricancheckedList.length ===
					CentralOrMiddleAfricanOptions.length,
			},
			function () {
				this.onCheckAfricaSelectAll();
			}
		);
		if (CentralOrMiddleAfricancheckedList.length == 0) {
			var index = this.state.region.indexOf("Central or Middle African");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};
	onCheckAllChangeCentralOrMiddleAfrican = (e) => {
		var region = "Central or Middle African";
		// alert("onCheckAllChangeCentralOrMiddleAfrican"+e.target.checked);
		if (!this.state.region.includes("Central or Middle African")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Central or Middle African");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		// alert("On Uncheck"+this.state.region);
		this.setState(
			{
				CentralOrMiddleAfricancheckedList: e.target.checked
					? CentralOrMiddleAfricanOptions
					: [],
				indeterminateAfr2: false,
				checkAll2: e.target.checked,
			},
			function () {
				this.onCheckAfricaSelectAll(e);
			}
		);
	};
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	onChangeSouthernAfricaCountries = (SouthernAfricaCountriescheckedList) => {
		var region = "Southern Africa Countries";
		if (SouthernAfricaCountriescheckedList.length > 0) {
			if (!this.state.region.includes("Southern Africa Countries")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				SouthernAfricaCountriescheckedList,
				indeterminateAfr3:
					!!SouthernAfricaCountriescheckedList.length &&
					SouthernAfricaCountriescheckedList.length <
						SouthernAfricaCountriesOptions.length,
				checkAll3:
					SouthernAfricaCountriescheckedList.length ===
					SouthernAfricaCountriesOptions.length,
			},
			function () {
				this.onCheckAfricaSelectAll();
			}
		);
		if (SouthernAfricaCountriescheckedList.length == 0) {
			var index = this.state.region.indexOf("Southern Africa Countries");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeSouthernAfricaCountries = (e) => {
		var region = "Southern Africa Countries";
		// alert("onCheckAllChangeAfrica"+e.target.checked);
		if (!this.state.region.includes("Southern Africa Countries")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Southern Africa Countries");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				SouthernAfricaCountriescheckedList: e.target.checked
					? SouthernAfricaCountriesOptions
					: [],
				indeterminateAfr3: false,
				checkAll3: e.target.checked,
			},
			function () {
				this.onCheckAfricaSelectAll(e);
			}
		);
	};

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	onChangeEastAfricanCountries = (EastAfricanCountriescheckedList) => {
		var region = "East African countries";
		if (EastAfricanCountriescheckedList.length > 0) {
			if (!this.state.region.includes("East African countries")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				EastAfricanCountriescheckedList,
				indeterminateAfr4:
					!!EastAfricanCountriescheckedList.length &&
					EastAfricanCountriescheckedList.length <
					EastAfricanCountriesOptions.length,
				checkAll4:
					EastAfricanCountriescheckedList.length ===
					EastAfricanCountriesOptions.length,
			},
			function () {
				this.onCheckAfricaSelectAll();
			}
		);
		if (EastAfricanCountriescheckedList.length == 0) {
			var index = this.state.region.indexOf("East African countries");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};
	onCheckAllChangeEastAfricanCountries = (e) => {   //mufiz-Task-Prod Issue-Agency-Custom Region and Countries---Country Count issue and Non-Selected Region showing in Region column.
		var region = "East African countries";
		if (!this.state.region.includes("East African countries")) {
			if (e.target.checked == true) {
				this.state.region.push(region); 
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("East African countries");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				EastAfricanCountriescheckedList: e.target.checked
					? EastAfricanCountriesOptions
					: [],
				indeterminateAfr4: false,
				checkAll4: e.target.checked,
			},
			function () {
				this.onCheckAfricaSelectAll(e);
			}
		);
	};
	///////////////////////////////////////////////////////////////

	onChangeWesternAfrica = (WesternAfricacheckedList) => {
		var region = "Western Africa";
		if (WesternAfricacheckedList.length > 0) {
			if (!this.state.region.includes("Western Africa")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				WesternAfricacheckedList,
				indeterminateAfr5:
					!!WesternAfricacheckedList.length &&
					WesternAfricacheckedList.length < WesternAfricaOptions.length,
				checkAll5:
					WesternAfricacheckedList.length === WesternAfricaOptions.length,
			},
			function () {
				this.onCheckAfricaSelectAll();
			}
		);
		if (WesternAfricacheckedList.length == 0) {
			var index = this.state.region.indexOf("Western Africa");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeWesternAfrica = (e) => {
		var region = "Western Africa";
		// alert("onCheckAllChangeWesternAfrica"+e.target.checked);
		if (!this.state.region.includes("Western Africa")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Western Africa");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				WesternAfricacheckedList: e.target.checked ? WesternAfricaOptions : [],
				indeterminateAfr5: false,
				checkAll5: e.target.checked,
			},
			function () {
				this.onCheckAfricaSelectAll(e);
			}
		);
	};
	/////////////////////////////////////////////////////////////////////////////
	onChangeEasternEurope = (EasternEuropecheckedList) => {
		var region = "Eastern Europe";
		// alert("onCheckAllChangeAfrica"+e.target.checked);
		if (EasternEuropecheckedList.length > 0) {
			if (!this.state.region.includes("Eastern Europe")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				EasternEuropecheckedList,
				indeterminateEur1:
					!!EasternEuropecheckedList.length &&
					EasternEuropecheckedList.length < EasternEuropeOptions.length,
				checkAll6:
					EasternEuropecheckedList.length === EasternEuropeOptions.length,
			},
			function () {
				this.onCheckEuropeSelectAll();
			}
		);
		if (EasternEuropecheckedList.length == 0) {
			var index = this.state.region.indexOf("Eastern Europe");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	/**
	 * @author Narendra Phadke
	 * @param  Description  Add Select All for Europe region
	 */
	onCheckAllChangeEurope = (e) => {
		//Call all function of sub region
		this.onCheckAllChangeEasternEurope(e);
		this.onCheckAllChangeNorthernEurope(e);
		this.onCheckAllChangeSouthernEurope(e);
		this.onCheckAllChangeWesternEurope(e);
		if (e.target.checked == true) {
			this.setState({
				nordicDisabled: true,
				beneluxDisabled: true,
				dachDisabled: true,
			});
		} else {
			this.setState({
				nordicDisabled: false,
				beneluxDisabled: false,
				dachDisabled: false,
			});
		}
	};
	/**
	 * @author Narendra Phadke
	 * @param  Description  For Select All of Europe Region
	 */
	onCheckEuropeSelectAll = (e) => {
		if (
			this.state.checkAll6 === true &&
			this.state.checkAll7 === true &&
			this.state.checkAll8 === true &&
			this.state.checkAll9 === true
		) {
			this.setState({ checkEuropeSelectAll: true }, function () {
				this.onCheckRegionSelectAll(e);
			});
		} else {
			this.setState({ checkEuropeSelectAll: false }, function () {
				this.onCheckRegionSelectAll(e);
			});
		}
	}; //End OF Select all Handle change of region
	onCheckAllChangeEasternEurope = (e) => {
		var region = "Eastern Europe";
		// alert("onCheckAllChangeEasternEurope"+e.target.checked);
		if (!this.state.region.includes("Eastern Europe")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Eastern Europe");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				EasternEuropecheckedList: e.target.checked ? EasternEuropeOptions : [],
				indeterminateEur1: false,
				checkAll6: e.target.checked,
			},
			function () {
				this.onCheckEuropeSelectAll(e);
			}
		);
	};

	//////////////////////////////////////////////////////////////////
	onChangeNorthernEurope = (NorthernEuropecheckedList) => {
		if (this.state.nordicChecked == true || this.state.checkAll25 == true) {
			if (NorthernEuropecheckedList.length < NordicOptions.length) {
				var ind = this.state.region.indexOf("nordic"); //Sandeep-task-3419-region issue
				if (ind > -1) {
					this.state.region.splice(ind, 1);
				}
				this.setState({ nordicChecked: false, checkAll25: false });
			}
		}
		var region = "Northern Europe";
		if (NorthernEuropecheckedList.length > 0) {
			if (!this.state.region.includes("Northern Europe")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				NorthernEuropecheckedList,
				indeterminateEur2:
					!!NorthernEuropecheckedList.length &&
					NorthernEuropecheckedList.length < NorthernEuropeOptions.length,
				checkAll7:
					NorthernEuropecheckedList.length === NorthernEuropeOptions.length,
			},
			function () {
				this.onCheckEuropeSelectAll();
			}
		);
		if (NorthernEuropecheckedList.length == 0) {
			var index = this.state.region.indexOf("northern europe");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeNorthernEurope = (e) => {
		var region = "Northern Europe";
		if (e.target.checked == true) {
			this.setState({ nordicDisabled: true });
		} else {
			this.setState({ nordicDisabled: false });
		}
		// alert("onCheckAllChangeNorthernEurope"+e.target.checked);
		if (!this.state.region.includes("Northern Europe")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Northern Europe");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		if (e.target.checked) {
			if (this.state.nordicChecked) {
				this.setState({ checkAll25: false, nordicDisabled: true });
				let index = this.state.region.indexOf("nordic"); //Sandeep-task-3419-region issue  //rutuja 2832
				if (index > -1) {
					this.state.region.splice(index, 1);
				}
			}
			this.setState(
				{
					NorthernEuropecheckedList: e.target.checked
						? NorthernEuropeOptions
						: [],
					indeterminateEur2: false,
					checkAll7: e.target.checked,
					northerneuropeChecked: !this.state.northerneuropeChecked,
				},
				function () {
					this.onCheckEuropeSelectAll(e);
				}
			);
		} else {
			if (this.state.nordicChecked) {
				this.setState({
					NorthernEuropecheckedList: NordicOptions,
					indeterminateEur2: false,
					checkAll7: e.target.checked,
					northerneuropeChecked: !this.state.northerneuropeChecked,
					checkAll25: true,
					nordicDisabled: false,
				});
			} else {
				this.setState(
					{
						NorthernEuropecheckedList: [],
						indeterminateEur2: false,
						checkAll7: e.target.checked,
						northerneuropeChecked: !this.state.northerneuropeChecked,
						nordicDisabled: false,
					},
					function () {
						this.onCheckEuropeSelectAll(e);
					}
				);
			}
		}
	};

	/////////////////////////////////////////////////////////////
	onChangeSouthernEurope = (SouthernEuropecheckedList) => {
		var region = "Southern Europe";
		if (SouthernEuropecheckedList.length > 0) {
			if (!this.state.region.includes("Southern Europe")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				SouthernEuropecheckedList,
				indeterminateEur3:
					!!SouthernEuropecheckedList.length &&
					SouthernEuropecheckedList.length < SouthernEuropeOptions.length,
				checkAll8:
					SouthernEuropecheckedList.length === SouthernEuropeOptions.length,
			},
			function () {
				this.onCheckEuropeSelectAll();
			}
		);
		if (SouthernEuropecheckedList.length == 0) {
			var index = this.state.region.indexOf("Southern Europe");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeSouthernEurope = (e) => {
		var region = "Southern Europe";
		// alert("onCheckAllChangeAfrica"+e.target.checked);
		if (!this.state.region.includes("Southern Europe")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Southern Europe");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				SouthernEuropecheckedList: e.target.checked
					? SouthernEuropeOptions
					: [],
				indeterminateEur3: false,
				checkAll8: e.target.checked,
			},
			function () {
				this.onCheckEuropeSelectAll(e);
			}
		);
	};

	///////////////////////////////////////////////////////////////////

	onChangeWesternEurope = (WesternEuropecheckedList) => {
		if (this.state.beneluxChecked == true || this.state.checkAll23 == true) {
			if (WesternEuropecheckedList.length < BeneluxOptions.length) {
				var ind = this.state.region.indexOf("benelux"); //Sandeep-task-3419-region issue
				if (ind > -1) {
					this.state.region.splice(ind, 1);
				}
				this.setState({ beneluxChecked: false, checkAll23: false });
			}
		}
		if (this.state.dachChecked == true || this.state.checkAll24 == true) {
			if (WesternEuropecheckedList.length < DACHOptions.length) {
				let ind = this.state.region.indexOf("dach"); //Sandeep-task-3419-region issue  //rutuja 2832
				if (ind > -1) {
					this.state.region.splice(ind, 1);
				}
				this.setState({ dachChecked: false, checkAll24: false });
			}
		}
		var region = "Western Europe";
		if (WesternEuropecheckedList.length > 0) {
			if (!this.state.region.includes("Western Europe")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				WesternEuropecheckedList,
				indeterminateEur4:
					!!WesternEuropecheckedList.length &&
					WesternEuropecheckedList.length < WesternEuropeOptions.length,
				checkAll9:
					WesternEuropecheckedList.length === WesternEuropeOptions.length,
				dachDisabled:
					WesternEuropecheckedList.length === WesternEuropeOptions.length,
				beneluxDisabled:
					WesternEuropecheckedList.length === WesternEuropeOptions.length,
			},
			function () {
				this.onCheckEuropeSelectAll();
				["benelux", "dach"].forEach((ele) => {
					//Sandeep-task-3419-region issue
					if (this.state.region.includes(ele)) {
						this.state.region.splice(this.state.region.indexOf(ele), 1);
					}
				});
				DACHOptions.map(
					function (ele) {
						if (!WesternEuropecheckedList.includes(ele)) {
							this.setState({ dachChecked: false, checkAll24: false });
						}
					}.bind(this)
				);
				BeneluxOptions.map(
					function (ele) {
						if (!WesternEuropecheckedList.includes(ele)) {
							this.setState({ beneluxChecked: false, checkAll23: false });
						}
					}.bind(this)
				);
			}
		);
		if (WesternEuropecheckedList.length == 0) {
			var index = this.state.region.indexOf("Western Europe");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeWesternEurope = (e) => {
		var region = "Western Europe";
		// alert("onCheckAllChangeWesternEurope"+e.target.checked);
		if (e.target.checked == true) {
			this.setState({ dachDisabled: true, beneluxDisabled: true });
		} else {
			this.setState({ dachDisabled: false, beneluxDisabled: false });
		}
		if (!this.state.region.includes("Western Europe")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Western Europe");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		if (e.target.checked) {
			if (this.state.beneluxChecked && this.state.dachChecked) {
				this.setState({
					checkAll23: false,
					checkAll24: false,
					beneluxDisabled: true,
					dachDisabled: true,
				});
				if (this.state.beneluxChecked) {
					let index = this.state.region.indexOf("benelux"); //Sandeep-task-3419-region issue  //rutuja 2832
					if (index > -1) {
						this.state.region.splice(index, 1);
					}
				}

				if (this.state.dachChecked) {
					let index = this.state.region.indexOf("dach"); //Sandeep-task-3419-region issue  //rutuja 2832
					if (index > -1) {
						this.state.region.splice(index, 1);
					}
				}
			}
			// end of both checked
			else if (this.state.beneluxChecked && !this.state.dachChecked) {
				this.setState({ checkAll23: false, beneluxDisabled: true });
				if (this.state.beneluxChecked) {
					let index = this.state.region.indexOf("benelux"); //Sandeep-task-3419-region issue  //rutuja 2832
					if (index > -1) {
						this.state.region.splice(index, 1);
					}
				}
			} else if (!this.state.beneluxChecked && this.state.dachChecked) {
				this.setState({ checkAll24: false, dachDisabled: true });
				if (this.state.dachChecked) {
					let index = this.state.region.indexOf("dach"); //Sandeep-task-3419-region issue //rutuja 2832
					if (index > -1) {
						this.state.region.splice(index, 1);
					}
				}
			}
			this.setState(
				{
					WesternEuropecheckedList: e.target.checked
						? WesternEuropeOptions
						: [],
					indeterminateEur4: false,
					checkAll9: e.target.checked,
					westernEuropeChecked: !this.state.westernEuropeChecked,
				},
				function () {
					this.onCheckEuropeSelectAll(e);
				}
			);
		} else {
			var countryArray = [
				"Belgium",
				"Netherlands",
				"Luxembourg",
				"Austria",
				"Germany",
				"Switzerland",
			];
			if (this.state.beneluxChecked && this.state.dachChecked) {
				this.setState({
					WesternEuropecheckedList: countryArray,
					indeterminateEur4: false,
					checkAll9: e.target.checked,
					westernEuropeChecked: !this.state.westernEuropeChecked,
					beneluxDisabled: false,
					dachDisabled: false,
					checkAll23: true,
					checkAll24: true,
				});
			} else if (this.state.beneluxChecked && !this.state.dachChecked) {
				// alert("beneluxChecked");
				this.setState(
					{
						WesternEuropecheckedList: BeneluxOptions,
						indeterminateEur4: false,
						checkAll9: e.target.checked,
						westernEuropeChecked: !this.state.westernEuropeChecked,
						beneluxDisabled: false,
						checkAll23: true,
					},
					() => {
						this.state.region.push("benelux");
					}
				); // Raunak-task-2950  pushing dach into main region Array);
				//Sandeep-task-3419-region issue
			}
			if (this.state.dachChecked && !this.state.beneluxChecked) {
				//alert("dachChecked");
				this.setState(
					{
						WesternEuropecheckedList: DACHOptions,
						indeterminateEur4: false,
						checkAll9: e.target.checked,
						westernEuropeChecked: !this.state.westernEuropeChecked,
						dachDisabled: false,
						checkAll24: true,
					},
					() => {
						this.state.region.push("dach");
					}
				); // Raunak-task-2950  pushing dach into main region Array);
				//Sandeep-task-3419-region issue
			} else if (!this.state.dachChecked && !this.state.beneluxChecked) {
				//alert("No one checked");
				this.setState(
					{
						WesternEuropecheckedList: [],
						indeterminateEur4: false,
						checkAll9: e.target.checked,
						westernEuropeChecked: !this.state.westernEuropeChecked,
						beneluxDisabled: false,
						dachDisabled: false,
					},
					function () {
						this.onCheckEuropeSelectAll(e);
					}
				);
			}
		}
	};
	/////////////////////////////////////////////////////////
	onChangeCentralAsia = (CentralAsiacheckedList) => {
		var region = "Central Asia";
		if (CentralAsiacheckedList.length > 0) {
			if (!this.state.region.includes("Central Asia")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				CentralAsiacheckedList,
				indeterminateAsia1:
					!!CentralAsiacheckedList.length &&
					CentralAsiacheckedList.length < CentralAsiaOptions.length,
				checkAll10: CentralAsiacheckedList.length === CentralAsiaOptions.length,
			},
			function () {
				this.onCheckAsiaSelectAll();
				this.disperse(false, true, true, true); //2950 to disselect apac and check the subregion
				if (this.state.apacChecked === true) {
					this.setState({
						apacChecked: false,
						indeterminateAsia1: true,
						checkAll26: false,
					});
				}
			}
		);
		if (CentralAsiacheckedList.length == 0) {
			var index = this.state.region.indexOf("Central Asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	/**
	 * @author Narendra Phadke
	 * @param  Description  For Select All of Europe Region
	 */
	onCheckAsiaSelectAll = (e) => {
		if (
			this.state.checkAll10 === true &&
			this.state.checkAll11 === true &&
			this.state.checkAll12 === true &&
			this.state.checkAll13 === true &&
			this.state.checkAll14 === true
		) {
			this.setState({ checkAsiaSelectAll: true }, function () {
				this.onCheckRegionSelectAll(e);
			});
		} else {
			this.setState({ checkAsiaSelectAll: false }, function () {
				this.onCheckRegionSelectAll(e);
			});
		}
	}; //End OF Select all Handle change of region

	/**
	 * @author Narendra Phadke
	 * @param  Description  handle change for select all of ASIA
	 */
	onCheckAllChangeAsia = (e) => {
		this.onCheckAllChangeCentralAsia(e);
		this.onCheckAllChangeEastAsia(e);
		this.onCheckAllChangeSouthAsia(e);
		this.onCheckAllChangeSoutheastAsia(e);
		this.onCheckAllChangeWesternAsia(e);
		if (e.target.checked == true) {
			this.setState({ ApacDisabled: true, ANZdisabled: true }, function () {
				if (this.state.apacChecked == true || this.state.checkAll26 == true) {
					var index = this.state.region.indexOf("apac"); //Sandeep-task-3419-region issue
					if (index > -1) {
						this.state.region.splice(index, 1);
					}
				}
			});
		} else {
			this.setState({ ApacDisabled: false, ANZdisabled: false }, function () {
				if (this.state.apacChecked == true || this.state.checkAll26 == true) {
					this.state.region.push("apac"); //Sandeep-task-3419-region issue
					var countryArray = [];
					var countryArray1 = [];
					var countryArray2 = [];
					var countryArray3 = [];
					if (this.state.southAsiaChecked) {
						countryArray = SouthAsiaOptions;
					} else {
						countryArray = APACOptions;
					}

					if (this.state.southeastAsiaChecked) {
						countryArray1 = SoutheastAsiaOptions;
					} else {
						countryArray1 = APACOptions;
					}

					if (this.state.eastAsiaChecked) {
						countryArray2 = EastAsiaOptions;
					} else {
						countryArray2 = APACOptions;
					}

					if (this.state.centralAsiaChecked) {
						countryArray3 = CentralAsiaOptions;
					} else {
						countryArray3 = APACOptions;
					}
					//SoutheastAsiacheckedList:countryArray1,
					this.setState(
						{
							SouthAsiacheckedList: countryArray,
							SoutheastAsiacheckedList: countryArray1,
							EastAsiacheckedList: countryArray2,
							CentralAsiacheckedList: countryArray3,
							indeterminate: false,
							checkAll26: this.state.apacChecked,
							apacChecked: this.state.apacChecked,
							anzChecked: false,
							checkAll18: false,
						},
						function () {
							Swal.fire({ heightAuto: false, text: "APAC Countries Selected" });
						}
					);
				}
			});
		}
	};

	onCheckAllChangeCentralAsia = (e) => {
		if (
			e.target.checked == true ||
			this.state.checkAll11 == true ||
			this.state.checkAll12 == true ||
			this.state.checkAll13 == true
		) {
			this.setState({ ApacDisabled: true, ANZdisabled: true });
		} else {
			this.setState({ ApacDisabled: false, ANZdisabled: false });
		}
		var region = "Central Asia";
		// alert("onCheckAllChangeCentralAsia "+e.target.checked);
		if (!this.state.region.includes("Central Asia")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
				this.disperse(false, true, true, true); //2864-raunak when some other sub-region is selected and already apac is selected so to select other selected sub-region
			}
		}
		if (e.target.checked == false) {
			this.imperse(); //2950-raunak when apac is internally checked but check all is also checked
			var index = this.state.region.indexOf("Central Asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				CentralAsiacheckedList: e.target.checked ? CentralAsiaOptions : [],
				indeterminateAsia1: false,
				checkAll10: e.target.checked,
			},
			function () {
				this.onCheckAsiaSelectAll(e);
				if (
					e.target.checked == false &&
					this.state.apacChecked == true &&
					this.state.checkAll26 == true
				) {
					var countryArray3 = [];

					if (this.state.centralAsiaChecked) {
						countryArray3 = CentralAsiaOptions;
					} else {
						countryArray3 = APACOptions;
					}
					//SoutheastAsiacheckedList:countryArray1,
					this.setState(
						{
							CentralAsiacheckedList: countryArray3,
							indeterminate: false,
							checkAll26: this.state.apacChecked,
							apacChecked: this.state.apacChecked,
							anzChecked: false,
							checkAll18: false,
							ANZdisabled: true,
						},
						function () {}
					);
				}
			}
		);
	};

	///////////////////////////////////////////////////////////////////////////////////////
	onChangeEastAsia = (EastAsiacheckedList) => {
		var region = "East Asia";
		if (EastAsiacheckedList.length > 0) {
			if (!this.state.region.includes("East Asia")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				EastAsiacheckedList,
				indeterminateAsia2:
					!!EastAsiacheckedList.length &&
					EastAsiacheckedList.length < EastAsiaOptions.length,
				checkAll11: EastAsiacheckedList.length === EastAsiaOptions.length,
			},
			function () {
				this.onCheckAsiaSelectAll();
				this.disperse(true, false, true, true); //2950 to disselect apac and check the subregion
				if (this.state.apacChecked === true) {
					this.setState({
						apacChecked: false,
						checkAll26: false,
						indeterminateAsia2: true,
					});
				}
			}
		);
		if (EastAsiacheckedList.length == 0) {
			var index = this.state.region.indexOf("East Asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeEastAsia = (e) => {
		if (
			e.target.checked == true ||
			this.state.checkAll10 == true ||
			this.state.checkAll12 == true ||
			this.state.checkAll13 == true
		) {
			this.setState({ ApacDisabled: true, ANZdisabled: true });
		} else {
			this.setState({ ApacDisabled: false, ANZdisabled: false });
		}
		var region = "East Asia";
		if (!this.state.region.includes("East Asia")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
				this.disperse(true, false, true, true); //2864-raunak when some other sub-region is selected and already apac is selected so to select other selected sub-region
			}
		}
		if (e.target.checked == false) {
			this.imperse();
			var index = this.state.region.indexOf("East Asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				EastAsiacheckedList: e.target.checked ? EastAsiaOptions : [],
				countryList: e.target.checked ? EastAsiaOptions : [],
				indeterminateAsia2: false,
				checkAll11: e.target.checked,
			},
			function () {
				this.onCheckAsiaSelectAll(e);
				if (
					e.target.checked == false &&
					this.state.apacChecked == true &&
					this.state.checkAll26 == true
				) {
					var countryArray2 = [];

					if (this.state.eastAsiaChecked) {
						countryArray2 = EastAsiaOptions;
					} else {
						countryArray2 = APACOptions;
					}

					//SoutheastAsiacheckedList:countryArray1,
					this.setState(
						{
							EastAsiacheckedList: countryArray2,
							indeterminate: false,
							checkAll26: this.state.apacChecked,
							apacChecked: this.state.apacChecked,
							anzChecked: false,
							checkAll18: false,
							ANZdisabled: true,
						},
						function () {}
					);
				}
			}
		);
	};
	////////////////////////////////////////////////////////////////////////////////
	onChangeSouthAsia = (SouthAsiacheckedList) => {
		if (this.state.anzChecked == true) {
			if (SouthAsiacheckedList.length < APACOptions.length) {
				var ind = this.state.region.indexOf("anz"); //Sandeep-task-3419-region issue
				if (ind > -1) {
					this.state.region.splice(ind, 1);
				}
				this.setState({ anzChecked: false });
			}
		}
		if (this.state.apacChecked == true) {
			if (SouthAsiacheckedList.length < APACOptions.length) {
				let ind = this.state.region.indexOf("apac"); //Sandeep-task-3419-region issue 
				if (ind > -1) {
					this.state.region.splice(ind, 1);
				}
				this.setState({ apacChecked: false });
			}
		}
		var region = "South Asia";
		if (SouthAsiacheckedList.length > 0) {
			if (!this.state.region.includes("South Asia")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				SouthAsiacheckedList,
				indeterminateAsia3:
					!!SouthAsiacheckedList.length &&
					SouthAsiacheckedList.length < SouthAsiaOptions.length,
				checkAll12: SouthAsiacheckedList.length === SouthAsiaOptions.length,
				ANZdisabled: SouthAsiacheckedList.length === SouthAsiaOptions.length,
				ApacDisabled: SouthAsiacheckedList.length === SouthAsiaOptions.length,
			},
			function () {
				this.onCheckAsiaSelectAll();
				this.disperse(true, true, false, true); //2950 to disselect apac and check the subregion
				if (this.state.apacChecked === true) {
					this.setState({
						apacChecked: false,
						checkAll26: false,
						indeterminateAsia3: true,
					});
				}
				//Sandeep-task-3419-region issue
				if (this.state.region.includes("anz")) {
					this.state.region.splice(this.state.region.indexOf("anz"), 1);
				}
				ANZOptions.map(
					function (ele) {
						if (!SouthAsiacheckedList.includes(ele)) {
							this.setState({ checkAll18: false, anzChecked: false });
						}
					}.bind(this)
				);
			}
		);
		if (SouthAsiacheckedList.length == 0) {
			var index = this.state.region.indexOf("South Asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeSouthAsia = (e) => {
		var region = "South Asia";
		if (
			e.target.checked == true ||
			this.state.checkAll10 == true ||
			this.state.checkAll11 == true ||
			this.state.checkAll13 == true
		) {
			this.setState({ ApacDisabled: true, ANZdisabled: true });
		} else {
			this.setState({ ApacDisabled: false, ANZdisabled: false });
		}
		if (!this.state.region.includes("South Asia")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
				this.disperse(true, true, false, true); //2864-raunak when some other sub-region is selected and already apac is selected so to select other selected sub-region
			}
		}
		if (e.target.checked == false) {
			this.imperse();
			var index = this.state.region.indexOf("South Asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
			var index = this.state.region.indexOf("south asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		if (e.target.checked) {
			this.setState(
				{
					SouthAsiacheckedList: e.target.checked ? SouthAsiaOptions : [],
					//   countryList: e.target.checked ?SouthAsiaOptions : [],
					indeterminateAsia3: false,
					checkAll12: e.target.checked,
					southAsiaChecked: !this.state.southAsiaChecked,
				},
				function () {
					this.onCheckAsiaSelectAll(e);
				}
			);
		} else {
			this.setState(
				{
					SouthAsiacheckedList:
						e.target.checked === false
							? this.state.anzChecked
								? ANZOptions
								: []
							: [],

					indeterminateAsia3: false,
					checkAll12: e.target.checked,
					southAsiaChecked: !this.state.southAsiaChecked,
				},
				function () {
					this.onCheckAsiaSelectAll(e);
					if (
						e.target.checked == false &&
						this.state.apacChecked == true &&
						this.state.checkAll26 == true
					) {
						var countryArray = [];
						if (this.state.southAsiaChecked) {
							countryArray = SouthAsiaOptions;
						} else {
							countryArray = APACOptions;
						}

						//SoutheastAsiacheckedList:countryArray1,
						this.setState(
							{
								SouthAsiacheckedList: countryArray,
								indeterminate: false,
								checkAll26: this.state.apacChecked,
								apacChecked: this.state.apacChecked,
								anzChecked: false,
								checkAll18: false,
								ANZdisabled: true,
							},
							function () {}
						);
					}
				}
			);
		}

		// this.setState({
		//     SouthAsiacheckedList: e.target.checked ?SouthAsiaOptions : [],
		//   indeterminate: false,
		//   checkAll12: e.target.checked
		// });
	};

	////////////////////////////////////////////////////////////////////////////////
	onChangeSoutheastAsia = (SoutheastAsiacheckedList) => {
		var region = "Southeast Asia";
		if (SoutheastAsiacheckedList.length > 0) {
			if (!this.state.region.includes("Southeast Asia")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				SoutheastAsiacheckedList,
				indeterminateAsia4:
					!!SoutheastAsiacheckedList.length &&
					SoutheastAsiacheckedList.length < SoutheastAsiaOptions.length,
				checkAll13:
					SoutheastAsiacheckedList.length === SoutheastAsiaOptions.length,
			},
			function () {
				this.onCheckAsiaSelectAll();
				this.disperse(true, true, true, false); //2950 to disselect apac and check the subregion
				if (this.state.apacChecked === true) {
					this.setState({
						apacChecked: false,
						checkAll26: false,
						indeterminateAsia4: true,
					});
				}
			}
		);
		if (SoutheastAsiacheckedList.length == 0) {
			var index = this.state.region.indexOf("Southeast Asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeSoutheastAsia = (e) => {
		if (
			e.target.checked == true ||
			this.state.checkAll10 == true ||
			this.state.checkAll11 == true ||
			this.state.checkAll12 == true
		) {
			this.setState({ ApacDisabled: true, ANZdisabled: true });
		} else {
			this.setState({ ApacDisabled: false, ANZdisabled: false });
		}
		var region = "Southeast Asia";
		if (!this.state.region.includes("Southeast Asia")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
				this.disperse(true, true, true, false); //2864-raunak when some other sub-region is selected and already apac is selected so to select other selected sub-region
			}
		}
		if (e.target.checked == false) {
			this.imperse();
			var index = this.state.region.indexOf("Southeast Asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				SoutheastAsiacheckedList: e.target.checked ? SoutheastAsiaOptions : [],
				indeterminateAsia4: false,
				checkAll13: e.target.checked,
			},
			function () {
				this.onCheckAsiaSelectAll(e);
				if (
					e.target.checked == false &&
					this.state.apacChecked == true &&
					this.state.checkAll26 == true
				) {
					var countryArray1 = [];
					if (this.state.southeastAsiaChecked) {
						countryArray1 = SoutheastAsiaOptions;
					} else {
						countryArray1 = APACOptions;
					}

					//SoutheastAsiacheckedList:countryArray1,
					this.setState(
						{
							SoutheastAsiacheckedList: countryArray1,
							indeterminate: false,
							checkAll26: this.state.apacChecked,
							apacChecked: this.state.apacChecked,
							anzChecked: false,
							checkAll18: false,
							ANZdisabled: true,
						},
						function () {}
					);
				}
			}
		);
	};

	////////////////////////////////////////////////////////////////////////////////
	onChangeWesternAsia = (WesternAsiacheckedList) => {
		var region = "Western Asia";
		if (WesternAsiacheckedList.length > 0) {
			if (!this.state.region.includes("Western Asia")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				WesternAsiacheckedList,
				indeterminateAsia5:
					!!WesternAsiacheckedList.length &&
					WesternAsiacheckedList.length < WesternAsiaOptions.length,
				checkAll14: WesternAsiacheckedList.length === WesternAsiaOptions.length,
			},
			function () {
				this.onCheckAsiaSelectAll();
			}
		);
		if (WesternAsiacheckedList.length == 0) {
			var index = this.state.region.indexOf("Western Asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeWesternAsia = (e) => {
		var region = "Western Asia";
		if (!this.state.region.includes("Western Asia")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Western Asia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				WesternAsiacheckedList: e.target.checked ? WesternAsiaOptions : [],
				indeterminateAsia5: false,
				checkAll14: e.target.checked,
			},
			function () {
				this.onCheckAsiaSelectAll(e);
			}
		);
	};
	////////////////////////////////////////////////////////////////////////////////////
	onChangeSouthAmerica = (SouthAmericacheckedList) => {
		var region = "South America";
		if (SouthAmericacheckedList.length > 0) {
			if (!this.state.region.includes("South America")) {
				this.state.region.push(region);
			}
		}

		this.setState(
			{
				SouthAmericacheckedList,
				indeterminateAmr1:
					!!SouthAmericacheckedList.length &&
					SouthAmericacheckedList.length < SouthAmericaOptions.length,
				checkAll15:
					SouthAmericacheckedList.length === SouthAmericaOptions.length,
			},
			function () {
				this.onCheckAmericaSelectAll();
			}
		);
		if (SouthAmericacheckedList.length == 0) {
			var index = this.state.region.indexOf("South America");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	/**
	 * @author Narendra Phadke
	 * @param  Description Region select all option
	 */
	oncheckAllChangeRegionSelectAll = (e) => {
		//call each of region function
		this.onCheckAllChangeSouthAmerica(e);
		this.onCheckAllChangeNorthAmerica(e);
		this.onCheckAllChangeEasternEurope(e);
		this.onCheckAllChangeNorthernEurope(e);
		this.onCheckAllChangeSouthernEurope(e);
		this.onCheckAllChangeWesternEurope(e);
		this.onCheckAllChangeCentralAsia(e);
		this.onCheckAllChangeEastAsia(e);
		this.onCheckAllChangeSouthAsia(e);
		this.onCheckAllChangeSoutheastAsia(e);
		this.onCheckAllChangeWesternAsia(e);
		this.onCheckAllChangeNorthernAfricaCountries(e);
		this.onCheckAllChangeCentralOrMiddleAfrican(e);
		this.onCheckAllChangeSouthernAfricaCountries(e);
		this.onCheckAllChangeEastAfricanCountries(e);
		this.onCheckAllChangeWesternAfrica(e);
		this.onCheckAllChangeMiddleEast(e);

		this.onCheckAllChangeAntarctic(e);
		this.onCheckAllChangeMelanesia(e);
		this.onCheckAllChangePolynesia(e);
		this.onCheckAllChangeMicronesia(e);
		this.onCheckAllChangeSouthernAtlanticOcean(e);
		// this.onCheckRegionSelectAll(e);
		if (e.target.checked === true) {
			this.setState({ checkRegionSelectAll: true });
		} else {
			this.setState({
				checkRegionSelectAll: false,
				ANZdisabled: false,
				ApacDisabled: false,
			});
		}
	}; //End of region select all

	/**
	 * @author Narendra Phadke
	 * @param  Description  For Only All Region Selected
	 */
	onCheckRegionSelectAll = (e) => {
		// if(this.state.checkAll1 === true && this.state.checkAll2===true && this.state.checkAll3===true && this.state.checkAll4===true && this.state.checkAll5===true && this.state.checkAll6===true && this.state.checkAll7===true && this.state.checkAll8===true && this.state.checkAll9===true && this.state.checkAll10===true && this.state.checkAll11===true && this.state.checkAll12===true && this.state.checkAll13===true && this.state.checkAll14===true && this.state.checkAll15===true && this.state.checkAll16===true && this.state.checkAll17===true && this.state.checkAll18===true && this.state.checkAll19===true && this.state.checkAll20===true && this.state.checkAll21===true && this.state.checkAll22===true){

		if (
			this.state.checkAmericaSelectAll === true &&
			this.state.checkAsiaSelectAll === true &&
			this.state.checkEuropeSelectAll === true &&
			this.state.checkAfricaSelectAll === true &&
			this.state.checkAll16 === true &&
			this.state.checkAll19 === true &&
			this.state.checkAll20 === true &&
			this.state.checkAll21 === true &&
			this.state.checkAll22 === true &&
			this.state.checkAll27 == true
		) {
			this.setState({ checkRegionSelectAll: true });
		} else {
			this.setState({ checkRegionSelectAll: false });
		}
	}; //End OF Select all Handle change of region

	/**
	 * @author Narendra Phadke
	 * @param  Description  For Select All of America Region
	 */
	onCheckAmericaSelectAll = (e) => {
		if (this.state.checkAll15 === true && this.state.checkAll17 === true) {
			this.setState({ checkAmericaSelectAll: true }, function () {
				this.onCheckRegionSelectAll(e);
			});
		} else {
			this.setState({ checkAmericaSelectAll: false }, function () {
				this.onCheckRegionSelectAll(e);
			});
		}
	}; //End OF Select all Handle change of region

	/**
	 * @author Narendra Phadke
	 * @param  Description  America Region for select all
	 */
	onCheckAllChangeAmericaRegion = (e) => {
		//call each of region function
		this.onCheckAllChangeSouthAmerica(e);
		this.onCheckAllChangeNorthAmerica(e);
	};

	onCheckAllChangeSouthAmerica = (e) => {
		var region = "South America";
		if (!this.state.region.includes("South America")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("South America");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				SouthAmericacheckedList: e.target.checked ? SouthAmericaOptions : [],
				indeterminateAmr1: false,
				checkAll15: e.target.checked,
			},
			function () {
				this.onCheckAmericaSelectAll(e);
			}
		);
	};
	////////////////////////////////////////////////////////////////////////////////////
	onChangeAntarctic = (AntarcticcheckedList) => {
		var region = "Antarctic";
		if (AntarcticcheckedList.length > 0) {
			if (!this.state.region.includes("Antarctic")) {
				this.state.region.push(region);
			}
		}

		this.setState(
			{
				AntarcticcheckedList,
				indeterminateAnt1:
					!!AntarcticcheckedList.length &&
					AntarcticcheckedList.length < AntarcticOptions.length,
				checkAll16: AntarcticcheckedList.length === AntarcticOptions.length,
			},
			function () {
				this.onCheckRegionSelectAll();
			}
		);
		if (AntarcticcheckedList.length == 0) {
			var index = this.state.region.indexOf("Antarctic");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeAntarctic = (e) => {
		var region = "Antarctic";
		// alert("onCheckAllChangeSouthAmerica "+e.target.checked);
		if (!this.state.region.includes("Antarctic")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Antarctic");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				AntarcticcheckedList: e.target.checked ? AntarcticOptions : [],
				indeterminateAnt1: false,
				checkAll16: e.target.checked,
			},
			function () {
				this.onCheckRegionSelectAll(e);
			}
		);
	};
	////////////////////////////////////////////////////////////////////////////////////
	onChangeNorthAmerica = (NorthAmericacheckedList) => {
		var region = "North America";
		if (NorthAmericacheckedList.length > 0) {
			if (!this.state.region.includes("North America")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				NorthAmericacheckedList,
				indeterminateAmr2:
					!!NorthAmericacheckedList.length &&
					NorthAmericacheckedList.length < NorthAmericaOptions.length,
				checkAll17:
					NorthAmericacheckedList.length === NorthAmericaOptions.length,
			},
			function () {
				this.onCheckAmericaSelectAll();
			}
		);
		if (NorthAmericacheckedList.length == 0) {
			var index = this.state.region.indexOf("North America");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeNorthAmerica = (e) => {
		var region = "North America";
		// alert("onCheckAllChangeNorthAmerica "+e.target.checked);
		if (!this.state.region.includes("North America")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("North America");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				NorthAmericacheckedList: e.target.checked ? NorthAmericaOptions : [],
				indeterminateAmr2: false,
				checkAll17: e.target.checked,
			},
			function () {
				this.onCheckAmericaSelectAll(e);
			}
		);
	};

	////////////////////////////////////////////////////////////////////////////////////
	onChangeMelanesia = (MelanesiacheckedList) => {
		var region = "Melanesia";
		if (MelanesiacheckedList.length > 0) {
			if (!this.state.region.includes("Melanesia")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				MelanesiacheckedList,
				indeterminateMel:
					!!MelanesiacheckedList.length &&
					MelanesiacheckedList.length < MelanesiaOptions.length,
				checkAll19: MelanesiacheckedList.length === MelanesiaOptions.length,
			},
			function () {
				this.onCheckRegionSelectAll();
			}
		);
		if (MelanesiacheckedList.length == 0) {
			var index = this.state.region.indexOf("Melanesia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeMelanesia = (e) => {
		var region = "Melanesia";
		// alert("onCheckAllChangeMelanesia "+e.target.checked);
		if (!this.state.region.includes("Melanesia")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Melanesia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				MelanesiacheckedList: e.target.checked ? MelanesiaOptions : [],
				indeterminateMel: false,
				checkAll19: e.target.checked,
			},
			function () {
				this.onCheckRegionSelectAll(e);
			}
		);
	};
	//////////////////////////////////////////////////////////////////////////////////////
	onChangePolynesia = (PolynesiacheckedList) => {
		var region = "Polynesia";
		if (PolynesiacheckedList.length > 0) {
			if (!this.state.region.includes("Polynesia")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				PolynesiacheckedList,
				indeterminatePoly1:
					!!PolynesiacheckedList.length &&
					PolynesiacheckedList.length < PolynesiaOptions.length,
				checkAll20: PolynesiacheckedList.length === PolynesiaOptions.length,
			},
			function () {
				this.onCheckRegionSelectAll();
			}
		);
		if (PolynesiacheckedList.length == 0) {
			var index = this.state.region.indexOf("Polynesia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangePolynesia = (e) => {
		var region = "Polynesia";
		// alert("onCheckAllChangePolynesia "+e.target.checked);
		if (!this.state.region.includes("Polynesia")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Polynesia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				PolynesiacheckedList: e.target.checked ? PolynesiaOptions : [],
				indeterminatePoly1: false,
				checkAll20: e.target.checked,
			},
			function () {
				this.onCheckRegionSelectAll(e);
			}
		);
	};

	//////////////////////////////////////////////////////////////////////////////////////

	onChangeMiddleEast = (middleEastCheckedList) => {
		// alert(" inside onChangeMiddleEast ")
		var region = "Middle East";
		if (middleEastCheckedList.length > 0) {
			if (!this.state.region.includes("Middle East")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				middleEastCheckedList,
				indeterminateMeast:
					!!middleEastCheckedList.length &&
					middleEastCheckedList.length < MiddleEastOptions.length,
				checkAll27: middleEastCheckedList.length === MiddleEastOptions.length,
			},
			function () {
				this.onCheckRegionSelectAll();
			}
		);
		if (middleEastCheckedList.length == 0) {
			var index = this.state.region.indexOf("Middle East");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeMiddleEast = (e) => {
		var region = "Middle East";
		// alert("onCheckAllChangeMiddleEast "+e.target.checked);
		if (!this.state.region.includes("Middle East")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Middle East");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				middleEastCheckedList: e.target.checked ? MiddleEastOptions : [],
				indeterminateMeast: false,
				checkAll27: e.target.checked,
			},
			function () {
				this.onCheckRegionSelectAll(e);
			}
		);
	};
	//////////////////////////////////////////////////////////////////////////////////////

	onChangeMicronesia = (MicronesiacheckedList) => {
		var region = "Micronesia";
		if (MicronesiacheckedList.length > 0) {
			if (!this.state.region.includes("Micronesia")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				MicronesiacheckedList,
				indeterminateMic1:
					!!MicronesiacheckedList.length &&
					MicronesiacheckedList.length < MicronesiaOptions.length,
				checkAll21: MicronesiacheckedList.length === MicronesiaOptions.length,
			},
			function () {
				this.onCheckRegionSelectAll();
			}
		);
		if (MicronesiacheckedList.length == 0) {
			var index = this.state.region.indexOf("Micronesia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeMicronesia = (e) => {
		var region = "Micronesia";
		// alert("onCheckAllChangeMicronesia "+e.target.checked);
		if (!this.state.region.includes("Micronesia")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Micronesia");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				MicronesiacheckedList: e.target.checked ? MicronesiaOptions : [],
				indeterminateMic1: false,
				checkAll21: e.target.checked,
			},
			function () {
				this.onCheckRegionSelectAll(e);
			}
		);
	};
	////////////////////////////////////////////////////////////////////////////////////////
	onChangeSouthernAtlanticOcean = (SouthernAtlanticOceancheckedList) => {
		var region = "Southern Atlantic Ocean";
		if (SouthernAtlanticOceancheckedList.length > 0) {
			if (!this.state.region.includes("Southern Atlantic Ocean")) {
				this.state.region.push(region);
			}
		}
		this.setState(
			{
				SouthernAtlanticOceancheckedList,
				indeterminateSAtlan:
					!!SouthernAtlanticOceancheckedList.length &&
					SouthernAtlanticOceancheckedList.length <
						SouthernAtlanticOceanOptions.length,
				checkAll22:
					SouthernAtlanticOceancheckedList.length ===
					SouthernAtlanticOceanOptions.length,
			},
			function () {
				this.onCheckRegionSelectAll();
			}
		);
		if (SouthernAtlanticOceancheckedList.length == 0) {
			var index = this.state.region.indexOf("Southern Atlantic Ocean");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}
	};

	onCheckAllChangeSouthernAtlanticOcean = (e) => {
		var region = "Southern Atlantic Ocean";
		// alert("onCheckAllChangeSouthernAtlanticOcean "+e.target.checked);
		if (!this.state.region.includes("Southern Atlantic Ocean")) {
			if (e.target.checked == true) {
				this.state.region.push(region);
			}
		}
		if (e.target.checked == false) {
			var index = this.state.region.indexOf("Southern Atlantic Ocean");
			if (index > -1) {
				this.state.region.splice(index, 1);
			}
		}

		this.setState(
			{
				SouthernAtlanticOceancheckedList: e.target.checked
					? SouthernAtlanticOceanOptions
					: [],
				indeterminateSAtlan: false,
				checkAll22: e.target.checked,
			},
			function () {
				this.onCheckRegionSelectAll(e);
			}
		);
	};
	render() {
		const asterisk = {
			color: "red",
		};
		return (
			<div style={{ overflowX: "hidden" }}>
				<Loader
					loadingRef={this.loadingRef}
					closeLoadingRef={this.closeLoadingRef}
					message="Please wait. Saving the data...."
				/>
				<Navigation />
				<form>
					<div class="container-fluid">
						<div
							id="stepName"
							className="stepName row"
							style={{ paddingTop: "28px" }}>
							{this.state.rfpCampaignID ? (
								<div class="col-xs-10 col-sm-10 col-md-10 col-lg-11">
									{/*sunita-task-3226-added for proper alignment */}
									<a
										class="col backButton"
										href="#/"
										onClick={this.RfpBackButton.bind(this)}>
										<FaArrowAltCircleLeft
											size={32}
											title=""
											className="backFrom_rfp svgsize"
										/>
									</a>
									{/* //snehal-task-3509-Project's(DI) - Create RFP through XLS - FE */}
									{this.state.status ? (
										<label className="step" id="labelDI">
											Create Request For Proposal
										</label>
									) : (
										<label className="step">Edit Request For Proposal </label>
									)}
								</div>
							) : (
								<div class="col-xs-10 col-sm-10 col-md-10 col-lg-11  alingbackbutton">
									{/*sunita-task-3226-added for proper alignment */}
									<a
										class="col backButton"
										href="#/" //rutuja 2832
										onClick={this.RfpCreateBackButton.bind(this)}>
										<FaArrowAltCircleLeft
											size={32}
											title=""
											className="backFrom_rfp svgsize"
										/>
									</a>

									{/* //shivani-3284-passed ID for DI label consistency */}
									<label className="step  labelcreatRequestfor" >
										Create Request For Proposal 
									</label>
								</div>
							)}

							{/* <div
								class="col-xs-2 col-sm-2 col-md-2 col-lg-1"
								style={{ paddingTop: "90px" }}>
								<span>
									<button
										className={"btn mr-auto add-button submitButton"}
										onClick={this.submitForm}
										style={{ float: "right" }}>
										Submit
									</button>
								</span>
							</div> */}
						</div>
						<div class="row">
							<div class="col-lg-9">
								{/* Sandeep-task-3157-added code for to display Basic Details */}
								<legend>
									<h4 style={{ color: "orange", paddingTop: "11px",paddingLeft: "10px" /*vrushabh 4219 task  */}}>
										Basic Details
									</h4>
								</legend>
							</div>
							<div
								class="col-lg-3 addsubmitwwww"/*vrushabh 4219 task  */
								>
								<span>
									<button
										className={"btn mr-auto add-button "}
										onClick={this.submitForm}
										style={{marginLeft:"-60px",float:"right"/*vrushabh 4219 task start */}}
										>
										Submit
									</button>
								</span>
							</div>
						</div>

						<div class="card" className="Card_rfp">
							<div class="card-body">
								<div class="row" id="theonlyRow">
									<div
										className="col-lg-9 col-md-12 col-sm-11"
										style={{ color: "grey" }}>
										<div class="row">
											<div class="col-xs-12 col-sm-11 col-md-6 col-lg-3">
												{/*sunita-task-3226-added for proper alignment */}
												<label className="labelStyle">
													Bidding type <span style={asterisk}>*</span>
												</label>
												<select
													value={this.state.BasicDetailArray[0].biddingType}
													onChange={this.commonhandlechange}
													id="biddingType"
													className="form-control inputStyle_rfp"
													name="biddingType"
													style={{ width: "180px", height: "34px" }}>
													<option value="Geowise" selected="true">
														Geo Viz
													</option>
													<option value="Combine">Combine</option>
												</select>
											</div>
											<div class="col-xs-12 col-sm-11 col-md-6 col-lg-3">
												{/* snehal-task- 3621-Project's(DI) - Add Advertiser in RFP Flow up to create campaign - FE (UI changes for adv name)*/}
												<label className="labelStyle">
													Advertiser name<span style={asterisk}>*</span>
												</label>
												&nbsp;&nbsp;
												<Tooltip placement="right" title="Add Advertiser">
													<a
														onClick={this.handleClientsetup}
														className="iconbackground">
														<Icon
															type="plus-circle"
															className="iconbackground"
														/>{" "}
														{/*sunita-task-3818-added classname as per requirement for removing white patches */}
													</a>
												</Tooltip>
												<select
													id="client"
													name="clientName"
													value={this.state.BasicDetailArray[0].clientName}
													className="form-control inputStyle_rfp"
													required
													onChange={this.commonhandlechange}>
													<option value="">Select Advertiser</option>
													{this.state.clientNameDetails.map((clientname) => {
														return (
															<option value={clientname}>{clientname}</option>
														);
													})}
												</select>
												<span
													style={{ display: this.state.clientNameDisplay }}
													className="errorMessage">
													Please select advertiser name
												</span>
											</div>
											<div class="col-xs-12 col-sm-11 col-md-6 col-lg-3">
												{/*sunita-task-3226-added for proper alignment */}
												<label className="labelStyle">Type of duration</label>

												{/* {this.state.BasicDetailArray[0].durationType} */}
												<select
													value={this.state.BasicDetailArray[0].durationType}
													onChange={this.commonhandlechange}
													id="durationType"
													className="form-control inputStyle_rfp"
													name="durationType"
													style={{ width: "180px", height: "34px" }}>
													<option value="" selected>
														Select Duration
													</option>
													<option value="Monthly">Monthly</option>
													<option value="Weekly">Weekly</option>
													<option value="Quarterly">Quarterly</option>
												</select>
											</div>
											<div class="col-xs-12 col-sm-11 col-md-6 col-lg-3">
												<label className="labelStyle">
													Duration
													{this.state.BasicDetailArray[0].durationType === "" ? ( // rutuja 2832 replace == with === 
														""
													) : (
														<span style={asterisk}>*</span>
													)}
												</label>
												<input
													value={this.state.BasicDetailArray[0].duration}
													onChange={this.commonhandlechange}
													type="text"
													id="duration"
													className="form-control inputStyle_rfp"
													name="duration"
													
													disabled={
														this.state.BasicDetailArray[0].durationType === "" // rutuja 2832 replace == with === 
															? true
															: false
													}
													style={{ border: "1px solid #ccc" }} //sunita-task-3246-added css as per requirement
													required
												/>
												<span
													style={{ display: this.state.durationErrMsg }}
													className="errorMessage">
													Please enter only numerical values
												</span>
											</div>
										</div>
										<br />
										<br />
										{this.state.BasicDetailArray.map((basicDetails) => {
											return (
												<div class="row">
													<div class="col-xs-12 col-sm-11 col-md-6 col-lg-3">
														<label className="labelStyle">Campaign type </label>
														<br />
														<select
															id="campaignType"
															value={
																this.state.BasicDetailArray[0].campaignType
															}
															onChange={this.commonhandlechange}
															className="form-control inputStyle_rfp"
															name="campaignType"
															style={{ width: "180px", height: "34px" }}>
															<option value="open">Open</option>
															<option value="ABM">ABM</option>
														</select>
													</div>
													<div class="col-lg-3 col-md-6 col-sm-11 ">
														<label className="labelStyle">Campaign name</label>
														<span className="asterisk">*</span>
														<input
															type="text"
															id="campaignName"
															className="form-control inputStyle_rfp"
															name="campaignName"
															required
															onChange={this.commonhandlechange}
															value={
																this.state.BasicDetailArray[0].campaignName
															}
															//style={{width:"100%",height: "45%"}}
														></input>

														<span
															style={{ display: this.state.cnameDisplay2 }}
															className="errorMessage">
															Please enter only alphanumeric values.(consisting
															of or using both letters and numerals.)
														</span>
														<span
															style={{ display: this.state.cnameDisplay }}
															className="errorMessage">
															Please enter campaign name
														</span>
													</div>
													<div class="col-lg-3 col-md-6 col-sm-11">
														<label className="labelStyle">Start date</label>
														<span className="asterisk">*</span>
														<input
															type="date"
															id="rfpstartDate"
															name="rfpstartDate"
															min=""
															required
															pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
															value={this.state.BasicDetailArray[0].startDate}
															//style={{width:"100%",height: "45%"}}
															className="form-control inputStyle_rfp"
															onChange={this.commonhandlechange}
														/>
														<span
															style={{ display: this.state.startDateDisplay }}
															className="errorMessage">
															Please enter start date
														</span>
														<span
															style={{ display: this.state.startDateDisplay1 }}
															className="errorMessage">
															Start date should be greater than bidding date
														</span>
													</div>
													<div class="col-lg-3 col-md-6 col-sm-11">
														<label className="labelStyle">End date</label>
														<span className="asterisk">*</span>
														<input
															type="date"
															id="rfpendDate"
															name="rfpendDate"
															value={this.state.BasicDetailArray[0].endDate}
															//style={{width:"100%",height: "45%"}}
															className="form-control inputStyle_rfp"
															required
															onChange={this.commonhandlechange}
														/>
														<span
															style={{ display: this.state.endDateDisplay }}
															className="errorMessage">
															Please enter end date
														</span>
														<span
															style={{ display: this.state.endDateDisplay1 }}
															className="errorMessage">
															End date must be greater than current date
														</span>
														<span
															style={{ display: this.state.endDateDisplay3 }}
															className="errorMessage">
															Please enter end date greater than start date
														</span>
													</div>
												</div>
											);
										})}
										<br />
										<br />
										{this.state.BasicDetailArray.map((basicDetails) => {
											return (
												<div class="row">
													<div class="col-lg-3 col-md-6 col-sm-11">
														<label className="labelStyle">Bidding closes</label>
														<span className="asterisk">*</span>
														{this.state.BasicDetailArray[0].biddingEndDate ==
														"" ? (
															<DatePicker
																// showTime
																//Sandeep-task-3609-Akshay sir---RFP-Bidding time
																showTime={{ format: "HH:mm" }}
																//Sandeep-task-3609-Akshay sir---RFP-Bidding time
																format={"YYYY-MM-DD HH:mm"}
																placeholder="Select Time"
																id="bid"
																name="bid"
																className="inputStyle_rfp"
																onChange={this.biddingDateBack.bind(this)}
															/>
														) : (
															<DatePicker
																// showTime
																//Sandeep-task-3609-Akshay sir---RFP-Bidding time
																showTime={{ format: "HH:mm" }}
																//Sandeep-task-3609-Akshay sir---RFP-Bidding time
																format={"YYYY-MM-DD HH:mm"}
																placeholder="Default values"
																className="inputStyle_rfp"
																value={moment(basicDetails.biddingEndDate)}
																id="bid"
																name="bid"
																onChange={this.biddingDateBack.bind(this)}
															/>
														)}
														<span
															style={{ display: this.state.bidDateErrMsg }}
															className="errorMessage">
															Please enter bidding date
														</span>
														<span
															style={{ display: this.state.bidDateErrMsg1 }}
															className="errorMessage">
															Bidding date has to be greater than current date
														</span>
														<span
															style={{ display: this.state.bidDateErrMsg2 }}
															className="errorMessage">
															Bidding date can't be greater than start date
														</span>
													</div>

													<div class="col-lg-3 col-md-6 col-sm-11">
														{/*sunita-task-3226-added for proper alignment */}

														<label className="labelStyle">
															Max. lead available<span style={asterisk}>*</span>
														</label>
														<input
															value={
																this.state.BasicDetailArray[0].leadAllocation
															}
															onChange={this.commonhandlechange}
															type="text"
															id="rfpleadAllocation"
															// className="form-control inputStyle_rfp2"
															className="form-control inputStyle_rfp" //sunita-task-3226-added this class for getting css changes for this field also
															name="rfpleadAllocation"
															required
														/>
														<span
															style={{
																display: this.state.leadAllocationErrMsg,
															}}
															className="errorMessage">
															Please enter only numerical values
														</span>
														<span
															style={{
																display: this.state.leadAllocationErrMsg2,
															}}
															className="errorMessage">
															Please enter valid values
														</span>
													</div>

													<div class="col-lg-3 col-md-6 col-sm-11">
														{/*sunita-task-3226-added for proper alignment */}
														<label className="labelStyle">
															Target CPL<span style={asterisk}>*</span>
														</label>
														<input
															value={this.state.BasicDetailArray[0].CPL}
															onChange={this.commonhandlechange}
															type="text"
															id="rfpCPL"
															// className="form-control inputStyle_rfp2"
															className="form-control inputStyle_rfp" //sunita-task-3226-added this class for getting css changes for this field also
															name="rfpCPL"
															required
														/>
														<span
															style={{ display: this.state.cplErrMsg }}
															className="errorMessage">
															Please enter only numerical values
														</span>
														<span
															style={{ display: this.state.cplErrMsg2 }}
															className="errorMessage">
															Please enter only numerical values
														</span>
													</div>

													<div class="col-lg-3 col-md-6 col-sm-11">
														{/*sunita-task-3226-added for proper alignment */}
														<label className="labelStyle">Net CPL</label>
														<input
															value={this.state.BasicDetailArray[0].netCPL}
															onChange={this.commonhandlechange}
															type="text"
															id="netCPL"
															// className="form-control inputStyle_rfp2"
															className="form-control inputStyle_rfp" //sunita-task-3226-added this class for getting css changes for this field also
															name="netCPL"
															required
														/>
														<span
															style={{ display: this.state.netcplErrMsg }}
															className="errorMessage">
															Please enter only numerical values
														</span>
														<span
															style={{ display: this.state.netcplErrMsg2 }}
															className="errorMessage">
															Please enter only numerical values
														</span>
														{/* Sandeep-task-3396-error msg issue */}
														<span
															style={{ display: this.state.netcplErrMsg3 }}
															className="errorMessage">
															Net cpl should be less than targeted cpl
														</span>
													</div>
												</div>
											);
										})}
										<br />
										<br />
										<div class="row">
											<div class="col-lg-3 col-md-6 col-sm-11">
												{/*sunita-task-3226-added for proper alignment */}
												<label className="labelStyle">Rate card CPL</label>
												<input
													value={this.state.BasicDetailArray[0].rateCardCPL}
													onChange={this.commonhandlechange}
													type="text"
													id="rateCardCPL"
													// className="form-control inputStyle_rfp2"
													className="form-control inputStyle_rfp" //sunita-task-3226-added this class for getting css changes for this field also
													name="rateCardCPL"
													required
												/>
												<span
													style={{ display: this.state.ratecardcplErrMsg }}
													className="errorMessage">
													Please enter only numerical values
												</span>
												<span
													style={{ display: this.state.ratecardcplErrMsg2 }}
													className="errorMessage">
													Please enter only numerical values
												</span>
												<span
													style={{ display: this.state.ratecardcplErrMsg3 }}
													className="errorMessage">
													Rate card cpl should be greater than target cpl
												</span>
											</div>
											{/*sunita-task-3226-added for proper alignment */}
											<div class="col-lg-3 col-md-6 col-sm-11">
												<label className="labelStyle">
													Campaign currency<span style={asterisk}>*</span>
												</label>
												<select
													id="campaignCurrency"
													name="campaignCurrency"
													style={{ height: "34px" }}
													value={this.state.BasicDetailArray[0].currency}
													onChange={this.commonhandlechange}
													// className="form-control inputStyle_rfp2"
													className="form-control inputStyle_rfp" //sunita-task-3226-added this class for getting css changes for this field also
												>
													<option value="USD" selected>
														USD - US Dollar
													</option>
													<option value="EUR">EUR - Euro</option>
													<option value="GBP">GBP - British Pound</option>
													<option value="INR">INR - Indian Rupee</option>
													<option value="AUD">AUD - Australian Dollar</option>
													<option value="CAD">CAD - Canadian Dollar</option>
													<option value="SGD">SGD - Singapore Dollar</option>
													<option value="CHF">CHF - Swiss Franc</option>
													<option value="MYR">MYR - Malaysian Ringgit</option>
													<option value="JPY">JPY - Japanese Yen</option>
													<option value="CNY">
														CNY - Chinese Yuan Renminbi
													</option>
												</select>
											</div>

											<div class="col-lg-3 col-md-6 col-sm-11">
												{/*sunita-task-3226-added for proper alignment */}

												<label className="labelStyle">Marketing channel </label>
												<select
													style={{ height: "34px" }}
													value={
														this.state.BasicDetailArray[0].marketingChannel
													}
													onChange={this.commonhandlechange}
													id="marketingChannel"
													// className="form-control  inputStyle_rfp2"
													className="form-control inputStyle_rfp" //sunita-task-3226-added this class for getting css changes for this field also
													name="marketingChannel">
													<option value="TeleMarketing">TeleMarketing</option>
													<option value="Email" selected>
														Email
													</option>
													<option value="Email/Telemarketing">
														Email/Telemarketing
													</option>
													<option value="Display">Display</option>
													<option value="Programmatic">Programmatic</option>
													<option value="Social">Social</option>
													<option value="Hybrid">Hybrid</option>
													<option value="Others">Others</option>
												</select>
											</div>

											{/* //Sandeep-task-3518-added code  for publication,audienceMatch,tal */}
											<div class="col-lg-3 col-md-6 col-sm-11">
												<label style={{ color: "#14254A" }}>
													Account Match Percentage
												</label>
												<br />
												<textarea
													id="audienceMatch"
													name="audienceMatch"
													className="form-control inputStyle_rfp"
													cols="45"
													onChange={this.commonhandlechange}
													value={this.state.BasicDetailArray[0].audienceMatch}
													style={{
														width: "250px",
														height: "36px",
														resize: "none",
													}}></textarea>
												<span
													style={{ display: this.state.audienceMatchErMsg }}
													className="errorMessage">
													Please enter valid values for Account Match Percentage
												</span>
												{/* </div> */}

												{/* </div> */}
											</div>
										</div>
										<br />
										<br />
										<div class="row">
											<div class="col-lg-3 col-md-6 col-sm-11">
												<label style={{ color: "#14254A" }}>TAL</label>
												<br />
												<textarea
													id="tal"
													name="tal"
													className="form-control inputStyle_rfp"
													cols="45"
													onChange={this.commonhandlechange}
													value={this.state.BasicDetailArray[0].tal}
													style={{
														width: "250px",
														height: "36px",
														resize: "none",
													}}></textarea>
												<span
													style={{ display: this.state.talErMsg }}
													className="errorMessage">
													Please enter valid values for tal
												</span>
												{/* </div> */}

												{/* </div> */}
											</div>

											<div class="col-lg-3 col-md-6 col-sm-11">
												<label style={{ color: "#14254A" }}>Publication</label>
												{/* Sandeep -task-3808-Demo-RFP- Create-Audience Match & Audience Reach */}
												<HtmlTooltip
													placement="top"
													title={"Need to add brands Domain"}>
													<Icon type="question-circle" />
												</HtmlTooltip>
												<br />
												<textarea
													id="publication"
													name="publication"
													className="form-control inputStyle_rfp"
													cols="45"
													onChange={this.commonhandlechange}
													value={this.state.BasicDetailArray[0].publication}
													style={{
														width: "250px",
														height: "36px",
														resize: "none",
													}}></textarea>
												<span
													style={{ display: this.state.publicationErMsg }}
													className="errorMessage">
													Please enter valid values for publication
												</span>
												{/* </div> */}

												{/* </div> */}
											</div>

											<div class="col-lg-3 col-md-6 col-sm-11">
												{/*sunita-task-3226-added for proper alignment */}
												{/* <div class="col-lg-3" > */}
												<label style={{ color: "#14254A" }}>
													No&nbsp;of&nbsp;custom&nbsp;questions
												</label>
												<br />
												<textarea
													id="customQuestionNumbr"
													name="customQuestionNumbr"
													className="form-control inputStyle_rfp"
													cols="45"
													onChange={this.commonhandlechange}
													value={
														this.state.BasicDetailArray[0].noOfCustomQuestions
													}
													style={{
														width: "250px",
														height: "36px",
														resize: "none",
													}}></textarea>
												<span
													style={{ display: this.state.customQuestionErMsg }}
													className="errorMessage">
													Please enter only numerical values
												</span>
												{/* </div> */}

												{/* </div> */}
											</div>
											{/* Sandeep -task-3808-Demo-RFP- Create-Audience Match & Audience Reach */}
											<div class="col-lg-3 col-md-6 col-sm-11">
												<label style={{ color: "#14254A" }}>
													Audience Reach<span style={asterisk}>*</span>
												</label>
												<br />
												<Radio.Group
													className="camp-radio"
													onChange={this.commonhandlechange}
													name="audienceReach"
													// class={this.state.linkActive1}
													defaultValue={
														this.state.BasicDetailArray[0].audienceReach
													}>
													<Radio
														value="Yes"
														id="audienceReach"
														checked={
															this.state.BasicDetailArray[0].audienceReach ===
															"Yes"
														}
														onChange="">
														Yes
													</Radio>
													<Radio
														value="No"
														id="audienceReach"
														checked={
															this.state.BasicDetailArray[0].audienceReach ===
															"No"
														}
														onChange="">
														No
													</Radio>
												</Radio.Group>

												{/* </div> */}

												{/* </div> */}
											</div>
										</div>
										<br />
										<br />

										<div class="row">
											<div class="card-body" style={{ padding: "0px" }}>
												{/*sunita-task-3226-added css for proper alignment */}
												<div class="col-xl-4 col-lg-4 col-md-4 col-sm-11 ">
													{/* <div class="card" style={{width: "32rem"}}> */}
													<div
														class="card"
														style={{ height: "420px", width: "30rem" }}
														id="industry_rfp">
														<div class="card-body">
															<label className="labelStyle">Industry</label>
															{/* <span className="asterisk">*</span> */}
															{/* <Tooltip title="Not Applicable">
											<input type="checkbox" name="industryNA" style={{float:'right'}}onChange={this.commonhandleChange}/></Tooltip> */}
															{/* <p style={{float:'right',fontSize:'12px'}}>( If N/A please ? )</p>&nbsp;&nbsp; */}
															<span
																style={{ display: this.state.industryDisplay }}
																className="errorMessage">
																Please enter industry
															</span>
															<Picky
																value={this.state.industry}
																options={industryOptions}
																onChange={this.industryHandleChange}
																className={
																	this.state.inputClassIndustry +
																	" " +
																	this.state.drpdisabled1
																}
																open={false}
																valueKey="id"
																labelKey="name"
																multiple={true}
																includeSelectAll={true}
																includeFilter={true}
																dropdownHeight={200}
																numberDisplayed={0}
																id="industry"
																name="industry"
															/>
															<br />
															{/* <p align="center" style={{ fontSize: '12px', color: 'orange' }}>Add custom industry if not available in list</p> */}
															{/* <hr /> */}
															{/* <label className='labelStyle'>Industry custom values</label><br /> */}
															<label
																className="labelStyle"
																style={{ fontSize: "14px", color: "#F28C0F" }}>
																{" "}
																Custom values
															</label>
															:&nbsp;&nbsp;
															{/* Sandeep-task-3396-added tooltip */}
															<HtmlTooltip
																placement="right"
																title={
																	<React.Fragment>
																		<ul>
																			<li>
																				<b>
																					{
																						"Allowed values: - , / , |, & , ( ) , + , . , @ "
																					}
																				</b>
																			</li>
																		</ul>
																	</React.Fragment>
																}>
																<Icon type="question-circle" />
															</HtmlTooltip>
															{/* Sandeep-task-3396-change in error msg */}
															<span
																style={{
																	display: this.state.customIndustryTextDisplay,
																}}
																className="errorMessage">
																Please enter valid values for industry
															</span>
															<textarea
																className={
																	"form-control textarea_industry" +
																	" " +
																	this.state.drpdisabled1
																}
																style={{
																	resize: "none",
																	overflowY: "scroll",
																	height: "227px",
																	border: "1px solid #ccc",
																	borderRadius: "3px",
																}}
																id="customIndustryText"
																name="customIndustryText"
																onChange={this.customIndustryTextChange}
																value={
																	this.state.customIndustryText
																}></textarea>
															<br />
														</div>
													</div>
													{/* end of 1st card */}
												</div>
												<div class="col-xl-4 col-lg-4 col-md-4 col-sm-11 ">
													{/* <div class="card" style={{width: "27rem"}}> */}
													<div
														class="card"
														style={{ height: "420px", width: "30rem" }}
														id="jobLevel_rfp">
														{/*sunita-task-3226-changed the css for proper alignment */}
														<div class="card-body">
															<label className="labelStyle">Job level</label>
															<Picky
																value={this.state.jobLevel}
																options={jobLevelOptions}
																onChange={this.jobLevelHandleChange}
																//className={this.state.inputClassDropDowncompanyRevenue}
																className={
																	this.state.inputClassJobLevel +
																	" " +
																	this.state.drpdisabled1
																}
																open={false}
																valueKey="id"
																labelKey="name"
																multiple={true}
																includeSelectAll={true}
																includeFilter={true}
																dropdownHeight={300}
																numberDisplayed={1}
																id="jobLevel"
																name="jobLevel"
															/>
															{/* snehal-task-3102-Agency side--RFp--Create RFP--Fields alignment issue(added-numberDisplayed={1}) */}
															<br />
															{/* <p align="center" style={{ fontSize: '12px', color: 'orange' }}>Add custom job level if not available in list</p> */}
															{/* <hr /> */}
															<label
																style={{ fontSize: "14px", color: "#F28C0F" }}>
																Custom values
															</label>
															:&nbsp;&nbsp;
															{/*sunita-task-3226-added label as per requirement */}
															{/* <a data-toggle="tooltip" title="Add one more">
								  <span class="glyphicon glyphicon-plus-sign" onClick={(e) => this.addJobLevel(e)} style={{ color: "#2196f3", fontWeight: 'bold', fontSize: 14 }}>
								  </span>
								</a> */}
															{/* <Icon type="plus-circle"
								
								  onClick={(e) => this.addJobLevel(e)}
								  style={{ color: "#2196f3", fontWeight: 'bold', fontSize: 14 }} /> */}
															{/* Sandeep-task-3396-added tooltip */}
															<HtmlTooltip
																placement="right"
																title={
																	<React.Fragment>
																		<ul>
																			<li>
																				<b>
																					{
																						"Allowed values: & , ( ) , - , : , . "
																					}
																				</b>
																			</li>
																		</ul>
																	</React.Fragment>
																}>
																<Icon type="question-circle" />
															</HtmlTooltip>
															<br></br>
															<span
																style={{
																	display: this.state.customJobLevelDisplay,
																}}
																className="errorMessage">
																Please enter valid values for job level
															</span>
															{/* Sandeep-task-3396-change in error msg */}
															{/* <div className="rfpAllCustomdiv">
								  {this.state.customJobLevel.map((customJobLevel, i) => {
									return (
									  <div style={{ marginTop: '-12px', marginBottom: '5px' }} key={customJobLevel.id}>
  
  
										<Input placeholder="Job Level" id="customJobLevel" name="customJobLevel"
										  onChange={this.customJobLevelHandleChange.bind(this, i)} className="rfpAllCustomInput" value={this.state.customJobLevel[i].value} />&nbsp;&nbsp;
  
  
										<a  ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e) => this.removeJobLevel(e, i)} style={{ color: "Red", fontWeight: 'bold', fontSize: 14, paddingTop: "20px", paddingBottom: '14px' }}></span></a>
										
  
									  </div>
									)
								  })}
								</div> */}
															<textarea
																className={
																	"form-control textarea_jobLevel" +
																	" " +
																	this.state.drpdisabled1
																}
																style={{
																	resize: "none",
																	overflowY: "scroll",
																	height: "227px",
																	border: "1px solid #ccc",
																	borderRadius: "3px",
																}}
																id="customjobLevelText"
																name="customjobLevelText"
																onChange={this.customJobLevelTextChange}
																value={
																	this.state.customJobLevelText
																}></textarea>
														</div>
													</div>
													{/* end of Job Level card */}
												</div>
												<div class="col-xl-4 col-lg-4 col-md-4 col-sm-11 ">
													{/* <div class="card" style={{width: "27rem"}}> */}
													<div
														class="card"
														style={{ height: "420px", width: "30rem" }}
														id="jobFunction_rfp">
														{/*sunita-task-3226-changed the css for proper alignment */}
														<div class="card-body">
															<label className="labelStyle">Job function</label>
															&nbsp;&nbsp;
															<Picky
																value={this.state.jobFunction}
																options={jobFunctionOptions}
																onChange={this.jobFunctionHandleChange}
																className={
																	this.state.inputClassJobFunction +
																	" " +
																	this.state.drpdisabled1
																}
																//className={this.state.inputClassDropDowncompanyRevenue}
																open={false}
																valueKey="id"
																labelKey="name"
																multiple={true}
																includeSelectAll={true}
																includeFilter={true}
																dropdownHeight={300}
																numberDisplayed={1}
																id="jobLevel"
																name="jobLevel"
															/>
															{/* snehal-task-3102-Agency side--RFp--Create RFP--Fields alignment issue(added-numberDisplayed={1}) */}
															<br />
															{/* <p align="center" style={{ fontSize: '12px', color: 'orange' }}>Add custom job function if not available in list</p> */}
															{/* <hr /> */}
															<label
																style={{ fontSize: "14px", color: "#F28C0F" }}>
																Custom values{" "}
															</label>
															:&nbsp;&nbsp;{" "}
															{/*sunita-task-3226-added label as per requirement */}
															{/* <Icon type="plus-circle"
								  onClick={(e) => this.addJobFunction(e)}
								  style={{ color: "#2196f3", fontWeight: 'bold', fontSize: 14 }} /> */}
															{/* Sandeep-task-3396-added tooltip */}
															<HtmlTooltip
																placement="right"
																title={
																	<React.Fragment>
																		<ul>
																			<li>
																				<b>
																					{
																						"Allowed values: & , ( ) , - , : , . "
																					}
																				</b>
																			</li>
																		</ul>
																	</React.Fragment>
																}>
																<Icon type="question-circle" />
															</HtmlTooltip>
															<br></br>
															<span
																style={{
																	display: this.state.customJobFunctionDisplay,
																}}
																className="errorMessage">
																Please enter valid values for job function
															</span>
															{/* Sandeep-task-3396-change in error msg */}
															{/* <div className="rfpAllCustomdiv">
								  {this.state.customJobFunction.map((customJobFunction, i) => {
									return (
									  <div style={{ marginTop: '-12px', marginBottom: '5px' }} key={customJobFunction.id}>
  
  
										<Input placeholder="Job Function" id="customJobFunction" name="customJobFunction"
										  onChange={this.customJobFunctionHandleChange.bind(this, i)} className="rfpAllCustomInput" value={this.state.customJobFunction[i].value} />&nbsp;&nbsp;
  
  
										<a  ><span class="glyphicon glyphicon-minus-sign" name="removeJobFunction" id={i} onClick={(e) => this.removeJobFunction(e, i)} style={{ color: "Red", fontWeight: 'bold', fontSize: 14, paddingTop: "20px", paddingBottom: '14px' }}></span></a>
									   
  
  
									  </div>
									)
								  })}
								</div> */}
															<textarea
																className={
																	"form-control textarea_jobFunction" +
																	" " +
																	this.state.drpdisabled1
																}
																style={{
																	resize: "none",
																	overflowY: "scroll",
																	height: "227px",
																	border: "1px solid #ccc",
																	borderRadius: "3px",
																}}
																id="customjobFunctionText"
																name="customjobFunctionText"
																onChange={this.customJobFunctionTextChange}
																value={
																	this.state.customJobFunctionText
																}></textarea>
														</div>
													</div>
													{/* end of Job Function Card */}
												</div>
											</div>
										</div>
										{/*end of row */}

										<br />
										<br />
										<br />
									</div>
									{/*end of column */}

									<div class="col-xl-3 col-lg-3 col-md-12 col-sm-11 empSize">
										<div class="row">
											<div
												class="col-lg-12 col-md-6 col-sm-11"
												className="employeeSizeStyle">
												<div class="card empSize1">
													{" "}
													{/*sunita-task-3226-added classname for giving css */}
													<div class="card-body ">
														<label className="labelStyle">Employee size</label>
														<span
															style={{
																display: this.state.employeeSizeDisplay,
															}}
															className="errorMessage">
															Please enter employee size
														</span>
														<Picky
															value={this.state.employeeSize}
															options={employeeSizeOption}
															onChange={this.employeeHandleChange}
															className={
																this.state.inputClassEmployeeSize +
																" " +
																this.state.drpdisabled1
															}
															//className={this.state.inputClassDropDownEmployeeSize + ' ' + this.state.drpdisabled}
															open={false}
															valueKey="id"
															labelKey="name"
															multiple={true}
															includeSelectAll={true}
															includeFilter={true}
															dropdownHeight={200}
															numberDisplayed={1}
															id="employeeSize"
															name="employeeSize"
														/>
														{/* snehal-task-3102-Agency side--RFp--Create RFP--Fields alignment issue(added-numberDisplayed={1}) */}
														{/* <br /> */}
														{/* <p className="empSizena1" align="center" style={{ fontSize: '12px', color: 'orange', marginTop: '6px' }}>Add employee size if not available in list</p> */}
														{/*sunita-task-3226-changed the css for proper alignment */}
														{/* <hr /> */}
														<span
															style={{
																display: this.state.employeeSizeRangeDisplay2,
															}}
															className="errorMessage">
															Please enter adjacent to and from values
														</span>
														<span
															style={{
																display: this.state.employeeSizeRangeDisplay3,
															}}
															className="errorMessage">
															Please enter only number for to and from values
														</span>
														<span
															style={{
																display: this.state.employeeSizeRangeDisplay4,
															}}
															className="errorMessage">
															Please enter to value greater than from value
														</span>
														<span
															style={{
																display: this.state.employeeSizeRangeDisplay5,
															}}
															className="errorMessage">
															Please do not enter comma in employee size range
														</span>
														{/* <label className='labelStyle'>Employee size range</label> */}
														{/* <Icon type="plus-circle"
								onClick={(e) => this.addemployeeSizetofrom(e)}
								style={{ height: "15px", width: "15px", marginLeft: "5px" }} /> */}
														{/* <div style={{ overflowY: "scroll", height: '58px' }} className={this.state.drpdisabled}>
								{this.state.employeeSizetofrom.map((employee, i) => {
								  return (
									<div key={employee.employeeSizeID}>
									  <Input type="text" className="form-control" name="from" placeholder="From" onChange={this.employeeSizetofromHandleChange.bind(this, i)}
										style={{ width: "100px" }} value={this.state.employeeSizetofrom[i].from} />
									  <span style={{ margin: "10px", borderRadius: "2px", boxShadow: "0px" }}></span>
									  <Input type="text" name="to" placeholder="To" className="form-control"
										onChange={this.employeeSizetofromHandleChange.bind(this, i)}
										style={{ width: "100px" }} value={this.state.employeeSizetofrom[i].to} />
									  <span class="glyphicon glyphicon-minus-sign" id={employee.employeeSizeID} onClick={this.removeEmployeeSizetofrom.bind(this, i)} style={{ color: "Red", fontWeight: 'bold', fontSize: 14, marginLeft: "5px" }}></span>
									</div>
								  )
								})}
							  </div> */}
														{/* <p align="center" className="empSizena2" style={{ fontSize: '12px', color: 'orange', marginTop: '-13px' }}>Add employee size if not available in list</p>
							  <hr /> */}
														<br></br>
														{/* <label className='labelStyle'>Employee size custom values </label> */}
														<label
															className="labelStyle"
															style={{ fontSize: "14px", color: "#F28C0F" }}>
															Custom values:{" "}
														</label>
														&nbsp;&nbsp;
														{/*sunita-task-3226-changed the label as per new requirement */}
														{/* <Icon type="plus-circle"
								onClick={(e) => this.addcustomEmployeeSizeText(e)}
								style={{ height: "15px", width: "15px", marginLeft: "5px" }} /> */}
														{/* sandeep-task-3396-added code for tooltip */}
														<HtmlTooltip
															placement="right"
															title={
																<React.Fragment>
																	<ol>
																		<li>
																			{
																				"Add employee size if not available in list separated by '|'."
																			}
																		</li>
																		{/* Sandeep-task-3779-Emp size--Help option modification- Employee Size in all flow */}
																		<li>
																			{
																				"Comma separated employee size will not be allowed(e.g 10000+,5000+)."
																			}
																		</li>
																		<li>
																			{
																				"Employee size range must be include adjacent 'from-to' values(e.g 1-20)."
																			}
																		</li>
																		<li>
																			{"Other allowed ranges: 1000|>1000|1000<"}
																		</li>
																	</ol>
																</React.Fragment>
															}>
															<Icon type="question-circle" />
														</HtmlTooltip>
														<br />
														<span
															style={{
																display:
																	this.state.customEmployeeSizeTextDisplay,
															}}
															className="errorMessage">
															Please enter valid values for employee size
														</span>
														&nbsp;(&nbsp;&nbsp;
														{/* Sandeep-task-3779-Emp size--Help option modification- Employee Size in all flow */}
														<span>
															e.g.1000 &lt;|&gt;2500|10,000+|1,000-5,000
														</span>
														){" "}
														{/*//snehal-task-3760-when i added comma in emp size so added}*/}
														{/* <div style={{ overflowY: "scroll", height: '50px' }} class={this.state.drpdisabled + " " + "rfpAllCustomdiv"}> */}
														{/* <div style={{ overflowY: "scroll", height: '50px' }} class={this.state.drpdisabled + " " + "rfpAllCustomdiv"}>
								{this.state.customEmployeeSizeText.map((customEmployeeSizeText, i) => {
								  return (
									<div key={customEmployeeSizeText.employeeSizeCustomID}>
  
									  <input type="text" value={this.state.customEmployeeSizeText[i].customEmployeeSizeText} id="customEmployeeSizeText" name="customEmployeeSizeText" onChange={this.customEmployeeSizeTextChange.bind(this, i)} style={{ width: '90%', border: '1px solid #ccc' }} />
  
									  <span class="glyphicon glyphicon-minus-sign" onClick={this.removeCustomEmployeeSizeText.bind(this, i)} id={customEmployeeSizeText.employeeSizeCustomID} style={{ color: "Red", fontWeight: 'bold', fontSize: 14, marginLeft: "5px" }}></span>
									</div>
								  )
								})}
  
							  </div> */}
														<textarea
															className={
																"form-control textarea_employeeSize" +
																" " +
																this.state.drpdisabled1
															}
															style={{
																resize: "none",
																overflowY: "scroll",
																height: "153px",
																border: "1px solid #ccc",
																borderRadius: "3px",
															}}
															id="customemployeeSizeText"
															name="customemployeeSizeText"
															onChange={this.customEmployeeSizeTextChange1}
															value={
																this.state.customEmployeeSizeText
															}></textarea>
													</div>
												</div>
											</div>
											{/* end of 2nd card */}
											{/* </div>
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-11 "> */}

											{/* <div class="card" style={{width: "35rem"}}> */}
											<div
												class="col-lg-12 col-md-6 col-sm-11"
												className="companyRevenueStyle">
												<div
													class="card"
													style={{
														height: "420px",
														width: "30rem",
														marginTop: "208px",
													}}>
													<div class="card-body">
														<label className="labelStyle">
															Company revenue
														</label>
														<span
															style={{
																display: this.state.companyRevenueDisplay,
															}}
															className="errorMessage">
															Please enter only numerical values
														</span>
														<Picky
															value={this.state.companyRevenue}
															options={companyRevenueOption}
															onChange={this.revenuehandleChange}
															className={
																this.state.inputClassCompanyRevenue +
																" " +
																this.state.drpdisabled1
															}
															//className={this.state.inputClassDropDowncompanyRevenue}
															open={false}
															valueKey="id"
															labelKey="name"
															multiple={true}
															includeSelectAll={true}
															includeFilter={true}
															dropdownHeight={300}
															numberDisplayed={1}
															id="companyRevenue"
															name="companyRevenue"
														/>
														{/* snehal-task-3102-Agency side--RFp--Create RFP--Fields alignment issue(added-numberDisplayed={1}) */}
														<br />
														{/* <p align="center" style={{ fontSize: '12px', color: 'orange' }}>Add company revenue if not available in list</p> */}
														{/* <hr /> */}
														<span
															style={{
																display: this.state.companyRevenueRangeDisplay2,
															}}
															className="errorMessage">
															Please enter adjacent from and to values
														</span>
														<span
															style={{
																display: this.state.companyRevenueRangeDisplay3,
															}}
															className="errorMessage">
															Please enter to value greater than from value
														</span>
														<span
															style={{
																display: this.state.companyRevenueRangeDisplay4,
															}}
															className="errorMessage">
															Please enter to value greater than from value
														</span>
														<span
															style={{
																display: this.state.companyRevenueRangeDisplay5,
															}}
															className="errorMessage">
															Please enter to value greater than from value
														</span>
														<span
															style={{
																display: this.state.companyRevenueRangeDisplay6,
															}}
															className="errorMessage">
															Please enter valid range for from to to value
														</span>
														{/* <label className='labelStyle'>Company revenue range</label>
							  <Icon type="plus-circle"
								onClick={(e) => this.addcompanyRevenuetofrom(e)}
								style={{ height: "15px", width: "15px", marginLeft: "5px" }} />
  
							  <div style={{ overflowY: "scroll", height: '68px' }}>
								{this.state.companyRevenuetofrom.map((companyRevenue, i) => {
								  return (
									<div key={companyRevenue.companyRevenueID}>
									  <Input type="text" style={{ width: "100px" }} placeholder="From" className="form-control"
										onChange={this.companyRevenuetofromHandleChange.bind(this, i)} name="from" value={this.state.companyRevenuetofrom[i].from} />
									  <span style={{ margin: "10px", borderRadius: "2px", boxShadow: "0px" }}></span>
  
									  <Input type="text" style={{ width: "100px" }} placeholder="To" className="form-control"
										onChange={this.companyRevenuetofromHandleChange.bind(this, i)}
										name="to" value={this.state.companyRevenuetofrom[i].to} />
									  <span class="glyphicon glyphicon-minus-sign" onClick={this.removecompanyRevenuetofrom.bind(this, i)} style={{ color: "Red", fontWeight: 'bold', fontSize: 14, marginLeft: "5px" }}></span>
  
									</div>
								  )
								})}
							  </div> */}
														{/* <br />
							  <p align="center " style={{ fontSize: '12px', color: 'orange' }}>Add company revenue if not available in list</p>
							  <hr /> */}
														{/* <label className='labelStyle'>Company revenue custom values</label> */}
														<label
															className="labelStyle"
															style={{ fontSize: "14px", color: "#F28C0F" }}>
															Custom values:
														</label>
														&nbsp;&nbsp;
														{/*sunita-task-3226-changed the label as per new requirement */}
														{/* <Icon type="plus-circle"
								onClick={(e) => this.addCustomCompanyRevenueText(e)}
								style={{ height: "15px", width: "15px", marginLeft: "5px" }} /> */}
														{/* sandeep-task-3396-added code for tooltip */}
														<HtmlTooltip
															placement="right"
															title={
																<React.Fragment>
																	<ol>
																		<li>
																			{
																				"Add company revenue if not available in list separated by '|'."
																			}
																		</li>
																		<li>
																			{
																				"Comma separated company revenue will not be allowed."
																			}
																		</li>
																		<li>
																			{
																				"Company revenue range must be include adjacent 'from-to' values (e.g$1M-$10M)."
																			}
																		</li>
																		<li>
																			{
																				"Other allowed ranges: $100M| >$100M| $100M<"
																			}
																		</li>
																	</ol>
																</React.Fragment>
															}>
															<Icon type="question-circle" />
														</HtmlTooltip>
														<br></br>
														{/* sandeep-task-3396-change in position of msg */}
														<span
															style={{
																display:
																	this.state.customCompanyRevenueTextDisplay,
															}}
															className="errorMessage">
															Please enter valid values for company revenue
														</span>
														(&nbsp;&nbsp;<span>e.g.$1000M</span>)
														{/* <div style={{ overflowY: "scroll", height: '68px' }}>
								{this.state.customCompanyRevenueText.map((customCompanyRevenueText, i) => {
								  return (
  
									<div key={customCompanyRevenueText.companyRevenueCustomID}>
									  <input type="text" style={{ width: "90%", border: '1px solid #ccc' }} id="customCompanyRevenueText" name="customCompanyRevenueText" onChange={this.customCompanyRevenueTextChange.bind(this, i)} value={this.state.customCompanyRevenueText[i].customCompanyRevenueText} />
  
									  <span class="glyphicon glyphicon-minus-sign" onClick={this.removeCustomCompanyRevenueText.bind(this, i)} id={customCompanyRevenueText.companyRevenueCustomID} style={{ color: "Red", fontWeight: 'bold', fontSize: 14, marginLeft: "5px" }}></span>
									</div>
  
								  )
								})}
							  </div> */}
														<textarea
															className={
																"form-control textarea_companyRevenue" +
																" " +
																this.state.drpdisabled1
															}
															style={{
																resize: "none",
																overflowY: "scroll",
																height: "227px",
																border: "1px solid #ccc",
																borderRadius: "3px",
															}}
															id="customcompanyRevenueText"
															name="customcompanyRevenueText"
															onChange={this.customCompanyRevenueTextChange1}
															value={
																this.state.customCompanyRevenueText
															}></textarea>
													</div>
												</div>
											</div>
										</div>
										{/* end of card */}
									</div>

									<div
										class="card col-xl-9 col-lg-9 col-md-6 col-sm-11"
										style={{ border: "none", top: "10px" }}
										className="card_industry1"></div>
									<div
										class="card col-xl-9 col-lg-9 col-md-6 col-sm-11"
										style={{ border: "none", bottom: "55px" }}
										className="jobTitle_CustomQuestions">
										<div class="card-body" style={{ marginTop: "20px" }}>
											<div class="col-lg-5 ">
												<label
													style={{ color: "#14254A", marginLeft: "-11px" }}>
													Job title
												</label>
												<br />
												{/* Sandeep-task-3396-error msg issue */}
												<span
													style={{
														display: this.state.jobTitleErrMsg,
														width: "254px",
														marginLeft: "-11px",
													}}
													className="errorMessage">
													Please enter valid values for job title
												</span>
												<textarea
													// className="t1"
													id="jobTitle"
													name="jobTitle"
													cols="45"
													onChange={this.commonhandlechange}
													defaultValue={this.state.BasicDetailArray[0].jobTitle}
													value={this.state.BasicDetailArray[0].jobTitle}
													style={{
														resize: "none",
														width: "315px",
														height: "48px",
														marginLeft: "-10px",
														border: "1px solid #ccc",
														borderRadius: "3px",
													}}></textarea>
												&nbsp;
											</div>
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
											<div
												class="col-lg-5 "
												id="specdiv1"
												style={{ marginLeft: "0px" }}>
												<label style={{ color: "#14254A" }}>
													Other&nbsp;specifications
												</label>
												<br />
												<textarea
													className="t2"
													id="otherSpecs"
													name="otherSpecs"
													cols="45"
													onChange={this.commonhandlechange}
													defaultValue={
														this.state.BasicDetailArray[0].otherSpecs
													}
													value={this.state.BasicDetailArray[0].otherSpecs}
													style={{
														width: "315px",
														height: "48px",
														resize: "none",
														border: "1px solid #ccc",
														borderRadius: "3px",
													}}></textarea>{" "}
												&nbsp;
											</div>{" "}
											&nbsp;
											{/* <div class="col-lg-3" ><label style={{color:"#14254A"}}>No&nbsp;of&nbsp;custom&nbsp;questions</label><br/><textarea id="customQuestionNumbr" name="customQuestionNumbr"cols="45"onChange={this.commonhandlechange} defaultValue={this.state.BasicDetailArray[0].noOfCustomQuestions} style={{width:"289px",height:"48px",resize:'none'}}></textarea><br/><br/>
						   <span style={{display:this.state.customQuestionErMsg}}className="errorMessage">
						   Please enter only numerical values
													   </span></div>               */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
				<div class="row" style={{ marginLeft: "15px" }}>
					{/* Sandeep-task-3157-added code for Contry/Region and changing position of element*/}
					<legend>
						<h4 style={{ color: "orange" }}>Country/Region*</h4>
					</legend>
					<label className="labelStyle" style={{ marginLeft: "13px" }}>
						Select Region and Countries
					</label>
					<span className="asterisk">*</span>
					<div
						class="modal fade"
						id="regionCountries"
						role="dialog"
						data-backdrop="static"
						data-keyboard="false">
						<div class="modal-dialog modal-xl">
							<div class="modal-content">
								<div
									class="modal-header custom-classPopup"
									style={{ textAlign: "end" }}>
									<h4 class="modal-title" style={{ color: "#144999" }}>
										<font color="white">Select Region and Countries</font>
									</h4>
									{/* //snehal-task-3805-country lead allocation issue added onclick*/}
									<button
										type="button"
										className="btn"
										onClick={this.getTotalCountries}
										style={{ backgroundColor: "#144999", border: "1px solid" }}
										data-dismiss="modal">
										Continue
									</button>
								</div>
								<div class="modal-body">
									<div class="row">
										<div
											class="col-lg-4 col-md-12 col-sm-11"
											style={{ height: "530px", overflowX: "scroll" }}
											id="regions_rfp">
											<div
												style={{
													backgroundColor: " #f2f9f2",
													padding: "5px",
													zIndex: "1000",
													position: "sticky",
													top: 0,
												}}>
												<h4 style={{ color: "orange" }}>
													<u>Re</u>gion
												</h4>
											</div>
											<div
												style={{
													borderBottom: "1px solid #E9E9E9",
													display: "grid",
													width: "200px",
												}}>
												<Checkbox style={{ display: "none" }}>
													{" "}
													Select All{" "}
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeBenelux}
													checked={this.state.checkAll23}
													disabled={this.state.beneluxDisabled}>
													BENELUX
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeDACH}
													checked={this.state.checkAll24}
													disabled={this.state.dachDisabled}>
													DACH
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeNordic}
													checked={this.state.checkAll25}
													disabled={this.state.nordicDisabled}>
													NORDIC
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeAustraliaAndNewZealand}
													checked={this.state.checkAll18}
													disabled={this.state.ANZdisabled}>
													ANZ
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeAPAC}
													checked={this.state.checkAll26}
													disabled={this.state.ApacDisabled}>
													APAC
												</Checkbox>

												<Checkbox
													style={{ display: "block" }}
													onChange={this.oncheckAllChangeRegionSelectAll}
													checked={this.state.checkRegionSelectAll}>
													{" "}
													Select All{" "}
												</Checkbox>

												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													America
												</b>
												<Checkbox
													style={{ display: "block" }}
													onChange={this.onCheckAllChangeAmericaRegion}
													checked={this.state.checkAmericaSelectAll}>
													{" "}
													Select All{" "}
												</Checkbox>
												<Checkbox
													indeterminate={this.state.indeterminateAmr1}
													onChange={this.onCheckAllChangeSouthAmerica}
													checked={this.state.checkAll15}>
													South America
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeNorthAmerica}
													checked={this.state.checkAll17}
													indeterminate={this.state.indeterminateAmr2}>
													North America
												</Checkbox>

												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Europe
												</b>
												<Checkbox
													style={{ display: "block" }}
													onChange={this.onCheckAllChangeEurope}
													checked={this.state.checkEuropeSelectAll}>
													{" "}
													Select All
												</Checkbox>
												<Checkbox
													onChange={this.onCheckAllChangeEasternEurope}
													checked={this.state.checkAll6}
													indeterminate={this.state.indeterminateEur1}>
													Eastern Europe
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeNorthernEurope}
													checked={this.state.checkAll7}
													indeterminate={this.state.indeterminateEur2}>
													Northern Europe
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeSouthernEurope}
													checked={this.state.checkAll8}
													indeterminate={this.state.indeterminateEur3}>
													Southern Europe
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeWesternEurope}
													checked={this.state.checkAll9}
													indeterminate={this.state.indeterminateEur4}>
													Western Europe
												</Checkbox>

												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Asia
												</b>
												<Checkbox
													style={{ display: "block" }}
													onChange={this.onCheckAllChangeAsia}
													checked={this.state.checkAsiaSelectAll}>
													{" "}
													Select All{" "}
												</Checkbox>
												<Checkbox
													onChange={this.onCheckAllChangeCentralAsia}
													checked={this.state.checkAll10}
													indeterminate={this.state.indeterminateAsia1}>
													Central Asia
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeEastAsia}
													checked={this.state.checkAll11}
													indeterminate={this.state.indeterminateAsia2}>
													East Asia
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeSouthAsia}
													checked={this.state.checkAll12}
													indeterminate={this.state.indeterminateAsia3}>
													South Asia
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeSoutheastAsia}
													checked={this.state.checkAll13}
													indeterminate={this.state.indeterminateAsia4}>
													Southeast Asia
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeWesternAsia}
													checked={this.state.checkAll14}
													indeterminate={this.state.indeterminateAsia5}>
													Western Asia
												</Checkbox>

												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Africa
												</b>
												<Checkbox
													style={{ display: "block" }}
													onChange={this.onCheckAllChangeAfricaRegion}
													checked={this.state.checkAfricaSelectAll}>
													{" "}
													Select All{" "}
												</Checkbox>
												<Checkbox
													onChange={
														this.onCheckAllChangeNorthernAfricaCountries
													}
													checked={this.state.checkAll1}
													indeterminate={this.state.indeterminateAfr1}>
													Northern Africa Countries
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeCentralOrMiddleAfrican}
													checked={this.state.checkAll2}
													indeterminate={this.state.indeterminateAfr2}>
													Central or Middle African
												</Checkbox>

												<Checkbox
													onChange={
														this.onCheckAllChangeSouthernAfricaCountries
													}
													checked={this.state.checkAll3}
													indeterminate={this.state.indeterminateAfr3}>
													Southern Africa Countries
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeEastAfricanCountries}
													checked={this.state.checkAll4}
													indeterminate={this.state.indeterminateAfr4}>
													East African Countries
												</Checkbox>

												<Checkbox
													onChange={this.onCheckAllChangeWesternAfrica}
													checked={this.state.checkAll5}
													indeterminate={this.state.indeterminateAfr5}>
													Western Africa
												</Checkbox>

												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													{" "}
													Middle East
												</b>
												<Checkbox
													onChange={this.onCheckAllChangeMiddleEast}
													checked={this.state.checkAll27}
													indeterminate={this.state.indeterminateMeast}>
													Middle East
												</Checkbox>

												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Antarctic
												</b>

												<Checkbox
													onChange={this.onCheckAllChangeAntarctic}
													checked={this.state.checkAll16}
													indeterminate={this.state.indeterminateAnt1}>
													Antarctic
												</Checkbox>

												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Melanesia
												</b>
												<Checkbox
													onChange={this.onCheckAllChangeMelanesia}
													checked={this.state.checkAll19}
													indeterminate={this.state.indeterminateMel}>
													Melanesia
												</Checkbox>

												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Polynesia
												</b>
												<Checkbox
													onChange={this.onCheckAllChangePolynesia}
													checked={this.state.checkAll20}
													indeterminate={this.state.indeterminatePoly1}>
													Polynesia
												</Checkbox>

												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Micronesia
												</b>
												<Checkbox
													onChange={this.onCheckAllChangeMicronesia}
													checked={this.state.checkAll21}
													indeterminate={this.state.indeterminateMic1}>
													Micronesia
												</Checkbox>

												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													{" "}
													Southern Atlantic Ocean
												</b>
												<Checkbox
													onChange={this.onCheckAllChangeSouthernAtlanticOcean}
													checked={this.state.checkAll22}
													indeterminate={this.state.indeterminateSAtlan}>
													Southern Atlantic Ocean
												</Checkbox>
											</div>
										</div>
										<div
											class="col-lg-8 col-md-12 col-sm-11"
											style={{ height: "530px", overflowX: "scroll" }}>
											<div
												style={{
													backgroundColor: " #f2f9f2",
													padding: "5px",
													zIndex: "1000",
													position: "sticky",
													top: 0,
												}}>
												<h4 style={{ color: "orange" }}>
													<u>Co</u>untries
												</h4>

												<Input
													onChange={(e) => {
														this.handleChangeCountryRegionSearch(e);
													}}
													className="countrySearchInput"
													placeholder="Search Countries"
													prefix={
														<FaSearch size={13} style={{ float: "left" }} />
													}
												/>
												<span id="colorCount" className="searchCount">
													0/252
												</span> 
												{/* //mufiz-Task-Prod Issue-Agency-Custom Region and Countries---Country Count issue and Non-Selected Region showing in Region column. */}
											</div>
											<div id="myCountryDiv">
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													South America
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeSouthAmerica}
													checked={this.state.checkAll15}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeSouthAmerica}checked={this.state.checkAll15}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={SouthAmericaOptions}
													value={this.state.SouthAmericacheckedList}
													onChange={this.onChangeSouthAmerica}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													North America
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeNorthAmerica}
													checked={this.state.checkAll17}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeNorthAmerica } checked={this.state.checkAll17}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={NorthAmericaOptions}
													value={this.state.NorthAmericacheckedList}
													onChange={this.onChangeNorthAmerica}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Eastern Europe
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeEasternEurope}
													checked={this.state.checkAll6}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeEasternEurope}checked={this.state.checkAll6}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={EasternEuropeOptions}
													value={this.state.EasternEuropecheckedList}
													onChange={this.onChangeEasternEurope}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Northern Europe
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeNorthernEurope}
													checked={this.state.checkAll7}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeNorthernEurope}checked={this.state.checkAll7}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={NorthernEuropeOptions}
													value={this.state.NorthernEuropecheckedList}
													onChange={this.onChangeNorthernEurope}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Southern Europe
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeSouthernEurope}
													checked={this.state.checkAll8}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeSouthernEurope} checked={this.state.checkAll8}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={SouthernEuropeOptions}
													value={this.state.SouthernEuropecheckedList}
													onChange={this.onChangeSouthernEurope}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Western Europe
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeWesternEurope}
													checked={this.state.checkAll9}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeWesternEurope}  checked={this.state.checkAll9}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={WesternEuropeOptions}
													value={this.state.WesternEuropecheckedList}
													onChange={this.onChangeWesternEurope}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Central Asia
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeCentralAsia}
													checked={this.state.checkAll10}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox  onChange={this.onCheckAllChangeCentralAsia} checked={this.state.checkAll10}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={CentralAsiaOptions}
													value={this.state.CentralAsiacheckedList}
													onChange={this.onChangeCentralAsia}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													East Asia
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeEastAsia}
													checked={this.state.checkAll11}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeEastAsia} checked={this.state.checkAll11}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={EastAsiaOptions}
													value={this.state.EastAsiacheckedList}
													onChange={this.onChangeEastAsia}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													South Asia
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeSouthAsia}
													checked={this.state.checkAll12}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeSouthAsia} checked={this.state.checkAll12} /> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={SouthAsiaOptions}
													value={this.state.SouthAsiacheckedList}
													onChange={this.onChangeSouthAsia}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Southeast Asia
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeSoutheastAsia}
													checked={this.state.checkAll13}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeSoutheastAsia}checked={this.state.checkAll13}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={SoutheastAsiaOptions}
													value={this.state.SoutheastAsiacheckedList}
													onChange={this.onChangeSoutheastAsia}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Western Asia
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeWesternAsia}
													checked={this.state.checkAll14}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeWesternAsia}checked={this.state.checkAll14}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={WesternAsiaOptions}
													value={this.state.WesternAsiacheckedList}
													onChange={this.onChangeWesternAsia}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Northern Africa Countries
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={
														this.onCheckAllChangeNorthernAfricaCountries
													}
													checked={this.state.checkAll1}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeNorthernAfricaCountries}checked={this.state.checkAll1}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={NorthernAfricaCountriesOptions}
													value={this.state.NorthernAfricaCountriescheckedList}
													onChange={this.onChangeNorthernAfricaCountries}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Central or Middle African
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeCentralOrMiddleAfrican}
													checked={this.state.checkAll2}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeCentralOrMiddleAfrican } checked={this.state.checkAll2}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={CentralOrMiddleAfricanOptions}
													value={this.state.CentralOrMiddleAfricancheckedList}
													onChange={this.onChangeCentralOrMiddleAfrican}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Southern Africa Countries
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={
														this.onCheckAllChangeSouthernAfricaCountries
													}
													checked={this.state.checkAll3}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeSouthernAfricaCountries} checked={this.state.checkAll3}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={SouthernAfricaCountriesOptions}
													value={this.state.SouthernAfricaCountriescheckedList}
													onChange={this.onChangeSouthernAfricaCountries}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													East African Countries
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeEastAfricanCountries}
													checked={this.state.checkAll4}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeEastAfricanCountries} checked={this.state.checkAll4}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={EastAfricanCountriesOptions}
													value={this.state.EastAfricanCountriescheckedList}
													onChange={this.onChangeEastAfricanCountries}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Western Africa
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeWesternAfrica}
													checked={this.state.checkAll5}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeWesternAfrica} checked={this.state.checkAll5}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={WesternAfricaOptions}
													value={this.state.WesternAfricacheckedList}
													onChange={this.onChangeWesternAfrica}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Middle East
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeMiddleEast}
													checked={this.state.checkAll27}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeWesternAfrica} checked={this.state.checkAll5}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={MiddleEastOptions}
													value={this.state.middleEastCheckedList}
													onChange={this.onChangeMiddleEast}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Antarctic
												</b>
												&nbsp;&nbsp;
												{/* <Checkbox
			onChange={this.onCheckAllChangeAntarctic}
			checked={this.state.checkAll16}
			>
			Select All
			</Checkbox> */}
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeAntarctic} checked={this.state.checkAll16}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={AntarcticOptions}
													value={this.state.AntarcticcheckedList}
													onChange={this.onChangeAntarctic}
												/>
												<br />
												<br />
												{/* <b style={{fontSize:"14px",fontWeight:"700"}}>Australia and New Zealand</b>&nbsp;&nbsp;<br/>
			<Checkbox onChange={this.onCheckAllChangeAustraliaAndNewZealand} checked={this.state.checkAll18}/> 
			<CheckboxGroup style={{gridAutoColumns:'auto',columnCount:'1'}}
			options={AustraliaAndNewZealandOptions}
			value={this.state.AustraliaAndNewZealandcheckedList}
			onChange={this.onChangeAustraliaAndNewZealand}
			/> */}
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Melanesia
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeMelanesia}
													checked={this.state.checkAll19}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeMelanesia}checked={this.state.checkAll19}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={MelanesiaOptions}
													value={this.state.MelanesiacheckedList}
													onChange={this.onChangeMelanesia}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Polynesia
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangePolynesia}
													checked={this.state.checkAll20}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangePolynesia}checked={this.state.checkAll20}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={PolynesiaOptions}
													value={this.state.PolynesiacheckedList}
													onChange={this.onChangePolynesia}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Micronesia
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeMicronesia}
													checked={this.state.checkAll21}>
													select All
												</Checkbox>
												<br />
												{/* <Checkbox  onChange={this.onCheckAllChangeMicronesia}checked={this.state.checkAll21}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={MicronesiaOptions}
													value={this.state.MicronesiacheckedList}
													onChange={this.onChangeMicronesia}
												/>
												<br />
												<br />
												<b style={{ fontSize: "14px", fontWeight: "700" }}>
													Southern Atlantic Ocean
												</b>
												&nbsp;&nbsp;
												<Checkbox
													onChange={this.onCheckAllChangeSouthernAtlanticOcean}
													checked={this.state.checkAll22}>
													Select All
												</Checkbox>
												<br />
												{/* <Checkbox onChange={this.onCheckAllChangeSouthernAtlanticOcean}checked={this.state.checkAll22}/> */}
												<CheckboxGroup
													style={{ gridAutoColumns: "auto", columnCount: "1" }}
													options={SouthernAtlanticOceanOptions}
													value={this.state.SouthernAtlanticOceancheckedList}
													onChange={this.onChangeSouthernAtlanticOcean}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<Icon
						type="plus-circle"
						className="iconbackground"
						style={{ height: "15px", width: "15px", marginLeft: "5px" }}
						data-toggle="modal"
						data-target="#regionCountries"
						onClick={(e) => {
							this.setState({ regionCountriesDisplay: "none" });
						}}
					/>
					{/*sunita-task-3818-added classname as per requirement for removing white patches */}

					{/*sunita-task-3737-added column as per requirement */}
					<div class="col-lg-3 col-md-7 col-sm-11">
						<p>
							<span
								style={{ display: this.state.regionCountriesDisplay }}
								className="errorMessage">
								{" "}
								Please select region/country{" "}
							</span>
						</p>
					</div>

					{/* sunita- task-3645- added code for countrywise lead allocation */}
					{/* <div class="col-xl-6 col-lg-6 col-md-6 offset-4 col-sm-12 countryLead" style={{float:"right"}}> */}
					<div
						class="col-xl-6 col-lg-5 col-md-5 col-sm-12 countryLead"
						style={{ float: "right" }}>
						{/*sunita-task-3737-added column as per requirement */}
						<label className="labelStyle">
							Countryviz lead allocation:
							<Tooltip title=" Please select region and countries.">
								<Icon
									type="question-circle-o"
									className="iconbackground"
									style={{
										marginLeft: "-4px",
										marginBottom: "-2px",
										padding: "1px",
										marginTop: "4px",
									}}
								/>
								{/*sunita-task-3818-added classname as per requirement for removing white patches */}
							</Tooltip>
						</label>
						&nbsp;&nbsp;
						{/* rutuja 2832 replace == with === */}
						{this.state.region.length > 0 ? (
							<input
								type="checkbox"
								checked={this.state.geoLeadChk === "Yes" ? true : false}
								value={this.state.geoLeadChk}
								onChange={this.geoleadChange}
							/>
						) : (
							<input
								type="checkbox"
								checked={this.state.geoLeadChk === "Yes" ? true : false}
								value={this.state.geoLeadChk}
								disabled
								onChange={this.geoleadChange}
							/>
						)}
						{/* rutuja 2832 replace == with === */}
						{this.state.region.length > 0 && this.state.geoLeadChk === "Yes" ? (
							<span style={{ display: this.state.geoLeadDisplay }}>
								Please click to enter CountryViz lead allocation&nbsp;
								<Icon
									type="plus-circle"
									onClick={this.openCountryWiseLeadAllocation.bind(this)}
									style={{
										height: "15px",
										width: "15px",
										marginLeft: "5px",
									}}
									data-toggle="modal"
									data-target="#geoWiseLead"
								/>
								<p>
									<span
										style={{ display: this.state.countrywiseLeadDisplay }}
										className="errorMessage">
										{" "}
										Please enter countryviz lead allocation{" "}
									</span>
								</p>
								{/* //snehal-task-3807-error when camp is geovise and all lead allocation are not there */}
								<p>
									<span
										style={{ display: this.state.countrywiseLeadDisplayGeoviz }}
										className="errorMessage">
										{" "}
										Please allocate leads to all countries{" "}
									</span>
								</p>
								<p>
									<span
										style={{
											display: this.state.countrywiseLeadDisplay2,
										}}
										className="errorMessage">
										{" "}
										Please enter countryviz lead allocation equal to total lead
										allocation{" "}
									</span>
								</p>
								<p>
									<span
										style={{
											display: this.state.countrywiseLeadDisplay3,
										}}
										className="errorMessage">
										Please enter positive numeric value for lead allocation{" "}
									</span>
								</p>
								<p>
									<span
										style={{
											display: this.state.countrywiseLeadDisplay4,
										}}
										className="errorMessage">
										Please enter positive numeric and non-zero value for lead
										allocation
									</span>
								</p>
							</span>
						) : (
							" "
						)}
						<div class="modal fade" id="geoWiseLead" role="dialog">
							<div class="modal-dialog modal-md CountryVizLead">
								<div class="modal-content">
									<div class="modal-header">
										<button type="button" class="close" data-dismiss="modal">
											&times;
										</button>
										<h4
											class="modal-title"
											style={{
												fontFamily: "roboto",
												fontSize: "18px",
												color: "rgba(25,61,143,1)",
											}}>
											CountryViz Lead Allocation
										</h4>
										&nbsp; ( {this.state.countryWiseAllocationArray.length}
										&nbsp;Countries selected)
										<br />
									</div>
									<div class="modal-body">
										<p>
											<span
												style={{
													fontFamily: "roboto",
													fontSize: "12px",
													color: "rgba(25,61,143,1)",
												}}>
												Total Lead Allocation:
											</span>
											{this.state.BasicDetailArray[0].leadAllocation}
											<br />
											<span
												class="ErrorMessage"
												style={{
													color: "red",
													fontSize: "14px",
													fontWeight: "700",
												}}>
												{this.state.error.geoWiseLeadAllocationError}
												{this.state.error.leadAllocationNumeric}
												{this.state.error.requireLead}
											</span>
											<span
												class="SuccessMessage"
												style={{
													color: "green",
													fontSize: "14px",
													fontWeight: "700",
												}}>
												{this.state.error.geoWiseLeadAllocationSuccess}
											</span>
											<br /> <br />
											{/* sunita-task-3645-added code here as per condition if we get rfpcampaignid  */}
											{this.state.rfpCampaignID === undefined ||
											this.state.rfpCampaignID === null ||
											this.state.rfpCampaignID === "undefined" ||
											this.state.rfpCampaignID === "null" ||
											this.state.rfpCampaignID === "" ? (
												<div
													style={{ overflowY: "scroll" }}
													className="countryWiseAllocation_popup">
													{this.state.countryWiseAllocationArray.map(
														(countryWiseAllocationArray, i) => {
															return (
																<span key={i}>
																	<div class="row">
																		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
																			&nbsp; &nbsp; &nbsp;
																			{countryWiseAllocationArray.country}
																		</div>
																		<div
																			class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-4 "
																			style={{ marginBottom: "15px" }}>
																			<Input
																				id="leadCount"
																				name="leadCount"
																				defaultValue={
																					// countryWiseAllocationArray.lead
																					countryWiseAllocationArray.leadCount
																				}
																				value={
																					countryWiseAllocationArray.leadCount
																				}
																				onChange={this.leadAllocationChange.bind(
																					this,
																					i
																				)}
																			/>
																		</div>
																	</div>
																</span>
															);
														}
													)}
												</div>
											) : (
												<div
													style={{ overflowY: "scroll" }}
													className="countryWiseAllocation_popup">
													{this.state.countryWiseAllocationArray.map(
														(countryWiseAllocationArray, i) => {
															return (
																<span key={i}>
																	<div class="row">
																		<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
																			&nbsp; &nbsp; &nbsp;
																			{countryWiseAllocationArray.country}
																		</div>
																		<div
																			class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-4 "
																			style={{ marginBottom: "15px" }}>
																			<Input
																				id="leadCount"
																				name="leadCount"
																				defaultValue={
																					countryWiseAllocationArray.leadCount
																				}
																				value={
																					countryWiseAllocationArray.leadCount
																				}
																				onChange={this.leadAllocationChange.bind(
																					this,
																					i
																				)}
																			/>
																		</div>
																	</div>
																</span>
															);
														}
													)}
												</div>
											)}
											{/* end of scroll */}
										</p>
									</div>
									{/* sunita-task-3645- added below code for showing below buttons on pop up  */}
									<div class="modal-footer">
										<div class="float-left">
											<button
												type="button"
												className="btn mr-auto"
												onClick={this.submitLead.bind(this)}
												style={{ borderRadius: "15px", width: "80px" ,backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"}}>	{/*Chaitanya-Task-4333-Prod Issue-Agency-CountryViz Lead allocation-Done button Alignment issue on Popup*/}
												Done
											</button>
											<button
												type="button"
												class="btn btn-default"
												data-dismiss="modal"
												style={{ borderRadius: "15px", width: "80px" }}>
												Close		{/*Chaitanya-4334-Prod Issue-Agency-CountryViz Lead allocation- Rename of 'Cancel' button as 'Close'*/}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* end of geoWise Modal */}
					</div>
					{/* End of Geo wise lead allocation */}

					{/* </div> */}
					{/* end of geoWise Modal */}
					{/* End of Geo wise lead allocation */}
				</div>{" "}
				{/* End of row */}
				<div
					class="card col-xl-12 col-lg-12 col-md-12 col-sm-11"
					style={{ border: "none", marginTop: "30px" ,marginLeft: "15px", marginRight: "15px" }} //rutuja 2832
					className="row card_supportingDocument"
					>
					<div class="col card-body px-0">
						<div id="supportingDocument">
							<legend>
								<h4 style={{ color: "orange" }}>Supporting Document</h4>
							</legend>
							{/* User Story - 3411 - Karan Jagtap - Start */}
							{/* Start - ABM */}
							<div
								class="col-lg-3 col-md-12 col-sm-12 mb-lg-0 mb-md-2" // 3411 - added bottom margin
								style={{ paddingRight: 0, paddingLeft: 0 }}>
								<div
									className="card mr-lg-1"
									style={{ paddingRight: 15, paddingLeft: 15 }}>
									<div className="card-body px-0" style={{ margin: "10px" }}>
										<div class="row mb-2">
											{/* User Story - 3411 - Alignment issue solved */}
											<div class="col-9">
												<label style={{ color: "black", fontWeight: 500 }}>
													ABM :&nbsp;
												</label>
												<Tooltip
													placement="right"
													title="The ABM file must have 1 column with header name as “Account Name” OR “Domain Name”">
													<Icon type="question-circle" />
												</Tooltip>
											</div>
											{/* User Story - 3411 - Alignment issue solved */}
											<div class="col-3">
												<div
													class="upload-btn-wrapper"
													style={{ float: "right" }}>
													<img
														src="upload.png"
														alt="upload"
														style={{ height: 18, width: 18 }}
														onClick={this.handleChangeMultipleFileOfABM}
													/>
												</div>
											</div>
										</div>
										<div id="noABMList" style={{ display: "none" }}>
											0 ABM Files Selected
										</div>
										<span className="errorMessage">
											{this.state.abmError.headerError}
										</span>
										<span
											className="errorMessage"
											style={{ display: this.state.abmFileError }}>
											Please upload files having extensions: .xls, .xlsx, .csv
											only
										</span>
										<div
											className="mt-2"
											style={
												this.state.abmFilesSelectedDocuments.length > 5
													? {
															maxHeight: 280,
															overflowY: "auto",
													  }
													: {}
											}>
											{this.state.abmFilesSelectedDocuments.map((item) => {
												return (
													<div
														className="row align-items-center mb-2"
														style={{
															backgroundColor: item.success
																? "#DAECDA"
																: "#FFEEF0",
															paddingLeft: 12,
															paddingTop: 12,
															paddingBottom: 12,
															paddingRight: 12,
															marginLeft: 0,
															marginRight: 0,
															borderRadius: 2,
														}}>
														<div
															style={{ padding: 0, margin: 0 }}
															className="col-xs-2 col-md-auto">
															{item.loading ? (
																<CircularProgress
																	size={26}
																	style={{
																		height: 26,
																		width: 26,
																		display: "flex",
																		margin: "auto",
																	}}
																/>
															) : (
																<img
																	style={{ height: 26, width: 26 }}
																	src={
																		item.success ? "success.png" : "failed.png"
																	}
																	alt="" //rutuja 2832
																/>
															)}
														</div>
														<div
															style={{ padding: 0, margin: 0 }}
															className="col overflow-hidden align-middle">
															<div className="row align-middle m-0 p-0">
																<label
																	className="mb-0 ml-2 overflow-hidden align-middle"
																	style={{
																		fontWeight: "normal",
																		fontSize: 13,
																	}}>
																	{item.suppDocName}
																</label>
															</div>
														</div>
														<a
															className="col-xs-2 col-md-auto justify-content-center"
															disabled={item.loading ? true : false}
															style={{
																padding: 0,
																margin: 0,
																width: "auto",
															}}
															onClick={(e) => {
																this.removeABMFile(e, item);
															}}>
															<img
																className="mb-0 mx-2"
																style={{
																	height: 18,
																	width: 18,
																}}
																src="file_upload_2_blue.png"
																alt="" //rutuja 2832
															/>
														</a>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
							{/* End - ABM */}
							{/* Start - Exclusion */}
							{/* sonali-3467-added company name in exclusion file tooltip */}

							<div
								class="col-lg-3 col-md-12 col-sm-12 mb-lg-0 mb-md-2" // 3411 - added bottom margin
								style={{ paddingRight: 0, paddingLeft: 0 }}>
								<div
									className="card mx-lg-1"
									style={{ paddingRight: 15, paddingLeft: 15 }}>
									<div className="card-body px-0" style={{ margin: "10px" }}>
										<div class="row mb-2">
											{/* User Story - 3411 - Alignment issue solved */}
											<div class="col-9">
												<label style={{ color: "black", fontWeight: 500 }}>
													Exclusion :&nbsp;
												</label>
												<Tooltip
													placement="right"
													title='Exclusion file must have one column with header name "Domain" or "Company Name"'>
													<Icon type="question-circle" />
												</Tooltip>
											</div>
											{/* User Story - 3411 - Alignment issue solved */}
											<div class="col-3">
												<div
													class="upload-btn-wrapper"
													style={{ float: "right" }}>
													<img
														src="upload.png"
														alt="upload"
														style={{ height: 18, width: 18 }}
														onClick={this.handleChangeMultipleFileOfExclusion}
													/>
												</div>
											</div>
										</div>
										<div id="noExclusionList" style={{ display: "none" }}>
											0 Exclusion file Selected
										</div>
										<span className="errorMessage">
											{this.state.exclusionError.headerError}
										</span>
										<span
											className="errorMessage"
											style={{ display: this.state.exclusionFileError }}>
											Please upload files having extensions: .xls, .xlsx, .csv
											only
										</span>
										<div
											className="mt-2"
											style={
												this.state.exclusionFilesSelectedDocuments.length > 5
													? {
															maxHeight: 280,
															overflowY: "auto",
													  }
													: {}
											}>
											{this.state.exclusionFilesSelectedDocuments.map(
												(item) => {
													return (
														<div
															className="row align-items-center mb-2"
															style={{
																backgroundColor: item.success
																	? "#DAECDA"
																	: "#FFEEF0",
																paddingLeft: 12,
																paddingTop: 12,
																paddingBottom: 12,
																paddingRight: 12,
																marginLeft: 0,
																marginRight: 0,
																borderRadius: 2,
															}}>
															<div
																style={{ padding: 0, margin: 0 }}
																className="col-xs-2 col-md-auto">
																{item.loading ? (
																	<CircularProgress
																		size={26}
																		style={{
																			height: 26,
																			width: 26,
																			display: "flex",
																			margin: "auto",
																		}}
																	/>
																) : (
																	<img
																		style={{ height: 26, width: 26 }}
																		src={
																			item.success
																				? "success.png"
																				: "failed.png"
																		}
																		alt="" //rutuja 2832
																	/>
																)}
															</div>
															<div
																style={{ padding: 0, margin: 0 }}
																className="col overflow-hidden align-middle">
																<div className="row align-middle m-0 p-0">
																	<label
																		className="mb-0 ml-2 overflow-hidden align-middle"
																		style={{
																			fontWeight: "normal",
																			fontSize: 13,
																		}}>
																		{item.suppDocName}
																	</label>
																</div>
															</div>
															<a
																className="col-xs-2 col-md-auto justify-content-center"
																disabled={item.loading ? true : false}
																style={{
																	padding: 0,
																	margin: 0,
																	width: "auto",
																}}
																onClick={(e) => {
																	this.removeExclusionFile(e, item);
																}}>
																<img
																	className="mb-0 mx-2"
																	style={{
																		height: 18,
																		width: 18,
																	}}
																	src="file_upload_2_blue.png"
																	alt="" //rutuja 2832
																/>
															</a>
														</div>
													);
												}
											)}
										</div>
									</div>
								</div>
							</div>
							{/* End - Exclusion */}
							{/* Start - Suppression */}
							<div
								class="col-lg-3 col-md-12 col-sm-12 mb-lg-0 mb-md-2" // 3411 - added bottom margin
								style={{ paddingRight: 0, paddingLeft: 0 }}>
								<div
									className="card mx-lg-1"
									style={{ paddingRight: 15, paddingLeft: 15 }}>
									<div className="card-body px-0" style={{ margin: "10px" }}>
										<div class="row mb-2">
											{/* User Story - 3411 - Alignment issue solved */}
											<div class=" col-9">
												<label style={{ color: "black", fontWeight: 500 }}>
													Suppression :&nbsp;
												</label>
												<Tooltip
													placement="right"
													title="Suppersion file can contain 1 column Email">
													<Icon type="question-circle" />
												</Tooltip>
											</div>
											{/* User Story - 3411 - Alignment issue solved */}
											<div class="col-3">
												<div
													class="upload-btn-wrapper"
													style={{ float: "right" }}>
													<img
														src="upload.png"
														alt="upload"
														style={{ height: 18, width: 18 }}
														onClick={this.handleChangeMultipleFileOfSuppression}
													/>
												</div>
											</div>
										</div>
										<div id="noSuppList" style={{ display: "none" }}>
											0 Suppression Files Selected
										</div>
										<span className="errorMessage">
											{this.state.suppressionerror.headerError}
										</span>
										<span
											className="errorMessage"
											style={{ display: this.state.suppressionFileError }}>
											Please upload files having extensions: .xls, .xlsx, .csv
											only
										</span>
										<div
											className="mt-2"
											style={
												this.state.suppressionFilesSelectedDocuments.length > 5
													? {
															maxHeight: 280,
															overflowY: "auto",
													  }
													: {}
											}>
											{this.state.suppressionFilesSelectedDocuments.map(
												(item) => {
													return (
														<div
															className="row align-items-center mb-2"
															style={{
																backgroundColor: item.success
																	? "#DAECDA"
																	: "#FFEEF0",
																paddingLeft: 12,
																paddingTop: 12,
																paddingBottom: 12,
																paddingRight: 12,
																marginLeft: 0,
																marginRight: 0,
																borderRadius: 2,
															}}>
															<div
																style={{ padding: 0, margin: 0 }}
																className="col-xs-2 col-md-auto">
																{item.loading ? (
																	<CircularProgress
																		size={26}
																		style={{
																			height: 26,
																			width: 26,
																			display: "flex",
																			margin: "auto",
																		}}
																	/>
																) : (
																	<img
																		style={{ height: 26, width: 26 }}
																		src={
																			item.success
																				? "success.png"
																				: "failed.png"
																		}
																		alt="" //rutuja 2832
																	/>
																)}
															</div>
															<div
																style={{ padding: 0, margin: 0 }}
																className="col overflow-hidden align-middle">
																<div className="row align-middle m-0 p-0">
																	<label
																		className="mb-0 ml-2 overflow-hidden align-middle"
																		style={{
																			fontWeight: "normal",
																			fontSize: 13,
																		}}>
																		{item.suppDocName}
																	</label>
																</div>
															</div>
															<a
																className="col-xs-2 col-md-auto justify-content-center"
																disabled={item.loading ? true : false}
																style={{
																	padding: 0,
																	margin: 0,
																	width: "auto",
																}}
																onClick={(e) => {
																	this.removeSuppressionFile(e, item);
																}}>
																<img
																	className="mb-0 mx-2"
																	style={{
																		height: 18,
																		width: 18,
																	}}
																	src="file_upload_2_blue.png"
																	alt="" //rutuja 2832
																/>
															</a>
														</div>
													);
												}
											)}
										</div>
									</div>
								</div>
							</div>
							{/* End - Suppression */}
							{/* Start - Other */}
							<div
								class="col-lg-3 col-md-12 col-sm-12"
								style={{ paddingRight: 0, paddingLeft: 0 }}>
								<div
									className="card ml-lg-1"
									style={{ paddingRight: 15, paddingLeft: 15 }}>
									<div
										className="card-body px-0"
										id="other"
										style={{ margin: "10px" }}>
										<div class="row mb-2">
											{/* User Story - 3411 - Alignment issue solved */}
											<div class="col-9">
												<label style={{ color: "black", fontWeight: 500 }}>
													Other :&nbsp;
												</label>
											</div>
											{/* User Story - 3411 - Alignment issue solved */}
											<div class="col-3">
												<div
													class="upload-btn-wrapper"
													style={{ float: "right" }}>
													<img
														src="upload.png"
														alt="upload"
														style={{ height: 18, width: 18 }}
														onClick={this.handleChangeMultipleFileOfOther}
													/>
												</div>
											</div>
										</div>
										<div id="nootherList" style={{ display: "none" }}>
											0 Other Files Selected
										</div>
										<span
											className="errorMessage"
											style={{ display: this.state.otherFileError }}>
											Please upload files having extensions: .xls, .xlsx, .csv
											only
										</span>
										<div
											className="mt-2"
											style={
												this.state.otherFilesSelectedDocuments.length > 5
													? {
															maxHeight: 280,
															overflowY: "auto",
													  }
													: {}
											}>
											{this.state.otherFilesSelectedDocuments.map((item) => {
												return (
													<div
														className="row align-items-center mb-2"
														style={{
															backgroundColor: item.success
																? "#DAECDA"
																: "#FFEEF0",
															paddingLeft: 12,
															paddingTop: 12,
															paddingBottom: 12,
															paddingRight: 12,
															marginLeft: 0,
															marginRight: 0,
															borderRadius: 2,
														}}>
														<div
															style={{ padding: 0, margin: 0 }}
															className="col-xs-2 col-md-auto">
															{item.loading ? (
																<CircularProgress
																	size={26}
																	style={{
																		height: 26,
																		width: 26,
																		display: "flex",
																		margin: "auto",
																	}}
																/>
															) : (
																<img
																	style={{ height: 26, width: 26 }}
																	src={
																		item.success ? "success.png" : "failed.png"
																	}
																	alt="" //rutuja 2832
																/>
															)}
														</div>
														<div
															style={{ padding: 0, margin: 0 }}
															className="col overflow-hidden align-middle">
															<div className="row align-middle m-0 p-0">
																<label
																	className="mb-0 ml-2 overflow-hidden align-middle"
																	style={{
																		fontWeight: "normal",
																		fontSize: 13,
																	}}>
																	{item.suppDocName}
																</label>
															</div>
														</div>
														<a
															className="col-xs-2 col-md-auto justify-content-center"
															disabled={item.loading ? true : false}
															style={{
																padding: 0,
																margin: 0,
																width: "auto",
															}}
															onClick={(e) => {
																this.removeOtherFile(e, item);
															}}>
															<img
																className="mb-0 mx-2"
																style={{
																	height: 18,
																	width: 18,
																}}
																src="file_upload_2_blue.png"
																alt="" //rutuja 2832
															/>
														</a>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
							{/* End - Other */}
							{/* User Story - 3411 - Karan Jagtap - End */}
						</div>
					</div>
				</div>
				{/* Sandeep-task-3179-added submit button to below supporting Documnet section */}
				<div class="row">
					<div
						class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
						style={{ paddingTop: "30px", marginLeft: "-12px" }}>
						<span>
							<button
								id="submit"
								className={"btn mr-auto add-button submitButton"}
								onClick={this.submitForm}
								style={{ float: "right" }}>
								Submit
							</button>
						</span>
					</div>
				</div>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
				{/* Sandeep-task-3127-added code for footer */}
				<Footer />
			</div>
		);
	}
}
RequestForProposal.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(RequestForProposal)
);
