
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Shivani pathak
*@fileName :
 *Desc: Advertiser On Board Form
*/

import React from 'react';
import 'antd/dist/antd.css';
//import * as $ from "jquery"; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import {Form,Input,Tooltip,Icon,Select,Button,AutoComplete} from 'antd'; //Aman-4749-(removed 'CheckboxRow,Col,Cascader,Card' not in use)-Console Warnings - Advertiser - Settings
import { read_cookie} from 'sfcookies'; //Aman-4749-(removed 'delete_cookie,bake_cookie' not in use)-Console Warnings - Advertiser - Settings
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //kiran-task-3867-added default header
const cookie_key = 'advertiserID';
const Swal = require('sweetalert2');
const { Option } = Select;

const AutoCompleteOption = AutoComplete.Option;
class AdvertiserContactInformationEdit extends React.Component {
  constructor() {
    super();
  this.state = {
    confirmDirty: false,
    newsuccess:[],
    autoCompleteResult: [],
    errors:{},
    infoMsg:'',
    advertiserID:'',
    advContactInfo:[],
    contactperson1:[],
    contactperson2:[],
    contactPerson1Deatils:[{firstName:"",lastName:"",designation:"",email:"",phoneNo:"",countryPhoneCode:""}],
    contactPerson2Deatils:[{firstName:"",lastName:"",designation:"",email:"",phoneNo:"",countryPhoneCode:""}],
    countryPhoneCode1:'',
    countryPhoneCode2:'',
    buttonDisplay:'',
    displayContact1:'',
    displayContact2:'',
    displayDesignation1:'',
    displayDesignation2:'',
    displayEmail1:'',
    displayEmail2:'',
    displayPhn1:'',
    displayPhn2:'',
  };
  this.handleChange= this.handleChange.bind(this);
  this.phoneHandleChange=this.phoneHandleChange.bind(this);
  this.codeHandleChange=this.codeHandleChange.bind(this);
}
  
  componentWillMount()
  {
    //alert("in will mount")
    // var pID=localStorage.getItem('pID');
    //var pID=localStorage.getItem('pID');
    if(!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else{
      const {user} = this.props.auth;
 
  const advertiserID=user.id;

  //  let data={
  //    advertiserID:advertiserID
  //  } //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
   this.setState({advertiserID:advertiserID})
  //  fetch("/advertiserOnBoardDetails/getAdvContactEdit?advertiserID="+advertiserID)
   fetch("/advertiserOnBoardDetails/getAdvContactEdit") // kiran- task 3867- VAPT-Advertiser side-report and settings -URL links & API -FE
        .then(res=>res.json())
        .then(advContactInfo=>{
        
            //  alert("advcontactInfo===>"+JSON.stringify(advContactInfo))
          // if(advContactInfo.length>1){  
            this.setState({advContactInfo:advContactInfo,
            contactperson1:[advContactInfo[0]],
            contactperson2:[advContactInfo[1]],
           countryPhoneCode1:[advContactInfo[0].countryPhoneCode],
              countryPhoneCode2:[advContactInfo[1].countryPhoneCode]
             } );
            // } else{
            //     this.setState({advContactInfo:advContactInfo,
            //     contactperson1:[advContactInfo[0]],
            //     countryPhone1:[advContactInfo[0].countryPhoneCode],
              
            //      } );
            // }
          }).catch(function (err) {console.log(err)});
   
  
  
    }
   var advertiserID= read_cookie(cookie_key);
    

   
   //alert(advertiserID)
    if(advertiserID === "") //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
   
    {
     //alert("advId null==="+advertiserID)
      this.setState({
        buttonDisplay: "button", 
        displayContact1:'',
        displayContact2:'',
        displayDesignation1:'',
        displayDesignation2:'',
        displayEmail1:'',
        displayEmail2:'',
        displayPhn1:'',
        displayPhn2:'',
        infoMsg:''
      });
    }
 
   
    else


    {
     // alert("advId in else==="+advertiserID)
      this.setState({
        buttonDisplay:'',
        displayContact1:'',
        displayContact2:'',
        displayDesignation1:'',
        displayDesignation2:'',
        displayEmail1:'',
        displayEmail2:'',
        displayPhn1:'',
        displayPhn2:'',
        infoMsg:''
      });
    }
  
  }
  
  phoneHandleChange(value){
    this.setState({selectValue:value,select1:value.name});
    this.setState({countryPhone1:value},function(){console.log(this.state.countryPhone1)})
    let contactperson1=[...this.state.contactperson1]
    contactperson1[0] = { ...contactperson1[0], "countryPhoneCode": value };
    this.setState({contactperson1 : [...contactperson1]}) //this.state.contactperson1=[...contactperson1]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
 }
 codeHandleChange(value){
   this.setState({selectValue:value,select1:value.name});
   this.setState({countryPhone2:value},function(){console.log(this.state.countryPhone2)})
   let contactperson2=[...this.state.contactperson2]
   contactperson2[0] = { ...contactperson2[0], "countryPhoneCode": value };
   this.setState({contactperson2 : [...contactperson2]}) //this.state.contactperson2=[...contactperson2]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
 }
  handleSubmit = e => {
    e.preventDefault();
    //var advertiserID=this.state.advertiserID //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
    // alert("advID===>"+this.state.advertiserID)
    var Contact1=[...this.state.contactperson1]
    // alert("contact1===>"+JSON.stringify(Contact1))
    var Contact2=[...this.state.contactperson2]
    // alert("contact2===>"+JSON.stringify(Contact2))
    this.props.form.validateFieldsAndScroll((err, values) => {
      let errors={}
      // alert("Error");
      // alert(values.prefix2+"=="+values.prefix);
      //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
      if(values.prefix2 === "Select"||values.prefix === "Select")
      {
            if(values.prefix === "Select")
        {
          errors["PhoneCode1"]="Please select Phone Code!";
          this.setState({
            errors:errors
          })
          
        }
       
        if(values.prefix2 === "Select")
        {
          errors["PhoneCode2"]="Please select Phone Code!";
          this.setState({
            errors:errors
          })
        }
      }
        else{
          errors["PhoneCode1"]="";
          this.setState({
            errors:errors
          })
          errors["PhoneCode2"]="";
          this.setState({
            errors:errors
          })
      if (!err) {
        // alert("! err")
        console.log('Received values of form: ', values);
       // debugger;
        //var advertiserID=localStorage.getItem('advertiserID');
        //var advertiserID= read_cookie(cookie_key); //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
        // var data=[];
        // data=[{'contactID':1,'userID':advertiserID,'contactPerson':values.contactPerson,'lastName':values.contactPerson1LastName,'designation':values.designation,'email':values.email,countryPhoneCode:values.prefix,'phoneNo':values.phone},{'contactID':2,'userID':advertiserID,'contactPerson':values.contactPerson2,
        // 'lastName':values.contactPerson2LastName,'designation':values.designation2,'email':values.email2,countryPhoneCode:values.prefix2,'phoneNo':values.phone2}]
     //Aman-4749-(not removing this warning because getting issue in DI) 
      var contactInfo=[];
      var contactPerson2=values.contactPerson2;
      if(contactPerson2===undefined || contactPerson2===null){contactPerson2=''}
      var contactPerson2LastName=values.contactPerson2LastName;
      if(contactPerson2LastName===undefined || contactPerson2LastName===null){contactPerson2LastName=''}
      var designation2=values.designation2;
      if(designation2===undefined || designation2===null){designation2=''}
      var contactEmail2=values.contactEmail2;
      if(contactEmail2===undefined || contactEmail2===null){contactEmail2=''}
      var phone2=values.phone2;
     
      if(phone2===undefined || phone2===null){phone2=''}
      if(contactPerson2===undefined || contactPerson2===null || contactPerson2==="" || contactPerson2===''){
        contactInfo=[{'contactID':1,'contactPerson':values.contactPerson,'lastName':values.contactPerson1LastName,'designation':values.designation,'email':values.contactEmail1,countryPhoneCode:values.prefix,'phoneNo':values.phone1}]
      }else{
        contactInfo=[{'contactID':1,'contactPerson':values.contactPerson,'lastName':values.contactPerson1LastName,'designation':values.designation,'email':values.contactEmail1,countryPhoneCode:values.prefix,'phoneNo':values.phone1},{'contactID':2,'contactPerson':contactPerson2,'lastName':contactPerson2LastName,'designation':designation2,'email':contactEmail2,countryPhoneCode:values.prefix2,'phoneNo':phone2}]
      }
      //var contactPersonEmail=values.contactEmail1; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
      //const {user}=this.props.auth; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
      //let advertiserID=user.id;
    let data={
      // advertiserID:advertiserID, // remove id for kiran -task 3765- VAPT-Advertiser side-report and settings -URL links & API -BE
     contact1:(Contact1), 
      contact2:(Contact2),
    } 
   console.log("formdata===>"+JSON.stringify(data)) 
   
    // // let data=new FormData();
    // data.append("advertiserID",advertiserID);  
    // alert("Contact1===>"+JSON.stringify(Contact1))
    // data.append("Contact1",JSON.stringify(Contact1));
    // alert("Contact2===>"+JSON.stringify(Contact2))
    // data.append("Contact2",JSON.stringify(Contact2));
     
    //  alert("formdata===>"+JSON.stringify(data)) 
    
     
    
     //let formIsValid=true; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
        this.setState({
          errors:''
        })
   
        fetch("advertiserOnBoardDetails/editContactInfo", {
          method: "POST",
         headers: { "Content-Type": "application/json" },
         body:JSON.stringify(data)
         }).then(res=>res.json()).then(res=>{
                    // alert("in fetch block==="+JSON.stringify(res))
              //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
               if(res.success===true)
               {
                //alert("contact2"+JSON.stringify(pubContact2))
                 Swal.fire({
                   type:'success',
                   title:'Advertiser',
                   text:'Advertiser Contact Information saved successfully !',
                   preConfirm: () => {
                    window.location.reload();  // added for control goes back on previous tab
                  }
                 })
                 this.setState({
                  buttonDisplay:'disabled'});  // kiran- task 3867- added for save button disable

                 }
        }).catch(function (err) {
          console.log(err)
        });
      }
      }
    });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
    }
    this.setState({ autoCompleteResult });
  };


  // handleChange=e => {

  //   let fields = this.state;
  //   fields[e.target.name] = e.target.value;
  //   this.setState({
  //     fields
  //   });
    
  // }
  handleChange(e){
    const{name,value}=e.target
  // alert("in handlechange")
 
 
  if(name === "contactp") //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
  {
    let contactperson1=[...this.state.contactperson1]
    contactperson1[0] = { ...contactperson1[0], "firstName": value };//Aman-4749-(Unnecessarily computed property removed [])-Console Warnings - Advertiser - Settings
    this.setState({contactperson1 : [...contactperson1]}) //this.state.contactperson1=[...contactperson1]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
    this.setState({contactPerson1Deatils:contactperson1},function(){console.log(this.state.contactperson1)})
    
  }
  if(name === "contactp1lastname")//Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
  {
    let contactperson1=[...this.state.contactperson1]
    contactperson1[0] = { ...contactperson1[0], "lastName": value };//Aman-4749-(Unnecessarily computed property removed [])-Console Warnings - Advertiser - Settings
    this.setState({contactperson1 : [...contactperson1]}); //this.state.contactperson1=[...contactperson1]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
    this.setState({contactPerson1Deatils:contactperson1},function(){console.log(this.state.contactperson1)})
  }
  //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
  if(name==="designation")
  {
    let contactperson1=[...this.state.contactperson1]
    contactperson1[0] = { ...contactperson1[0], "designation": value };//Aman-4749-(Unnecessarily computed property removed [])-Console Warnings - Advertiser - Settings
    this.setState({contactperson1 : [...contactperson1]}); //this.state.contactperson1=[...contactperson1]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
    this.setState({contactPerson1Deatils:contactperson1},function(){console.log(this.state.contactperson1)})
  }
  //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
  if(name==="contactEmail1")
  {
    let contactperson1=[...this.state.contactperson1]
    contactperson1[0] = { ...contactperson1[0], "email": value };//Aman-4749-(Unnecessarily computed property removed [])-Console Warnings - Advertiser - Settings
    this.setState({contactperson1 : [...contactperson1]}); //this.state.contactperson1=[...contactperson1]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
    this.setState({contactPerson1Deatils:contactperson1},function(){console.log(this.state.contactperson1)})
  }
  //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
  if(name==="phone1")
  {
    let contactperson1=[...this.state.contactperson1]
    contactperson1[0] = { ...contactperson1[0], "phoneNo": value };//Aman-4749-(Unnecessarily computed property removed [])-Console Warnings - Advertiser - Settings
    this.setState({contactperson1 : [...contactperson1]}); //this.state.contactperson1=[...contactperson1]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
    this.setState({contactPerson1Deatils:contactperson1},function(){console.log(this.state.contactperson1)})
  }
 
 
 //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
  if(name==="contactp2")
  {
    let contactperson2=[...this.state.contactperson2]
    contactperson2[0] = { ...contactperson2[0], "firstName": value };//Aman-4749-(Unnecessarily computed property removed [])-Console Warnings - Advertiser - Settings
    this.setState({contactperson2 : [...contactperson2]}); //this.state.contactperson2=[...contactperson2]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
    this.setState({contactPerson2Deatils:contactperson2},function(){console.log(this.state.contactperson2)})
  }
  //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
  if(name==="contactp2lastname")
  {
    let contactperson2=[...this.state.contactperson2]
    contactperson2[0] = { ...contactperson2[0], "lastName": value };//Aman-4749-(Unnecessarily computed property removed [])-Console Warnings - Advertiser - Settings
    this.setState({contactperson2 : [...contactperson2]}); //this.state.contactperson2=[...contactperson2]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
    this.setState({contactPerson2Deatils:contactperson2},function(){console.log(this.state.contactperson2)})
  }
  //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
  if(name==="designation2")
  {
    let contactperson2=[...this.state.contactperson2]
    contactperson2[0] = { ...contactperson2[0], "designation": value };//Aman-4749-(Unnecessarily computed property removed [])-Console Warnings - Advertiser - Settings
    this.setState({contactperson2 : [...contactperson2]});//this.state.contactperson2=[...contactperson2]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
    this.setState({contactPerson2Deatils:contactperson2},function(){console.log(this.state.contactperson2)})
  }
  //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
  if(name==="contactEmail2")
  {
   let contactperson2=[...this.state.contactperson2]
   contactperson2[0] = { ...contactperson2[0], "email": value };//Aman-4749-(Unnecessarily computed property removed [])-Console Warnings - Advertiser - Settings
   this.setState({contactperson2 : [...contactperson2]}); //this.state.contactperson2=[...contactperson2]; //Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
   this.setState({contactPerson2Deatils:contactperson2},function(){console.log(this.state.contactperson2)})
  }
  //Aman-4749-(added '===' and instead saw '==')-Console Warnings - Advertiser - Settings
  if(name==="phone2")
  {
    
   let contactperson2=[...this.state.contactperson2]
   contactperson2[0] = { ...contactperson2[0], "phoneNo": value };//Aman-4749-(Unnecessarily computed property removed [])-Console Warnings - Advertiser - Settings
   this.setState({contactperson2 : [...contactperson2]}); //this.state.contactperson2=[...contactperson2];//Aman-4749-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Advertiser - Settings
   this.setState({contactPerson2Deatils:contactperson2},function(){console.log(this.state.contactperson2)})
  }
  }

  render() {
   // alert("in render")
    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    
    const phonePrefix=["1","7","20","27","30","32","33","34","36","39","40","41","43","44","45","46","47","48","49","51","52","53","54","55","56","57","58","60","61","62","63","64","65","66","76","77","81","82","84","86","87","90","91","92","93","94","95","98","211","212","213","216","218","220","221","222","223","224","225","226","227","228","229","230","231","232","234","235","236","237","238","239","240","241","242","243","244","245","246","247","248","249","250","251","252","254","255","256","257","258","260","261","262","263","264","265","266","267","268","269","290","291","297","298","299","350","351","352","353","354","355","356","357","358","359","370","371","372","373","374","375","376","377","378","380","381","382","383","385","386","387","389","420","421","423","500","501","502","503","504","505","506","507","508","509","590","591","592","594","595","596","597","598","670","672","673","674","675","676","677","678","679","680","681","682","683","685","686","687","688","689","690","691","692","800","850","852","853","855","856","870","878","880","881","882","883","886","888","942","960","961","963","964","965","966","967","968","970","971","972","973","974","975","976","977","979","992","993","994","995","996","998","1242","1246","1248","1264","1268","1284","1340","1345","1441","1473","1649","1664","1670","1671","1684","1721","1758","1784","1787","1808","1809","1829","1849","1868","1869","1876","1939","2908","3732","3735","4428","4779","5399","5993","5994","5997","5999","6721","6723","7840","7940","8810","8811","8812","8813","8816","8817","8818","8819","35818","37447","37449","37744","37745","38128","38129","38138","38139","38643","38649","88213","88216","90392","262269","262639","441481","441534","441624","447524","447624","447781","447839","447911","447924","6189162","6189164"];
   
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: this.state.countryPhoneCode1
      })(
        <Select style={{ width: 110 }} value={this.state.selectValue} name="countryPhoneCode"
        onChange={this.phoneHandleChange} showSearch >
        {phonePrefix.map(a=>(
        <Option value={a}>+ {a}</Option>
        ))}
      </Select>,
       );
      const prefixSelector2 = getFieldDecorator('prefix2', {
        initialValue: this.state.countryPhoneCode2,
      })(
        <Select style={{ width: 110 }} value={this.state.selectValue} name="countryPhoneCode"
        onChange={this.codeHandleChange} showSearch >
        {phonePrefix.map(a=>(
        <Option value={a}>+ {a}</Option>
        ))}
      </Select>,
      );
  
  
 
    //Aman-4749-(not removing this warning because getting issue in DI)
    const websiteOptions = autoCompleteResult.map(website => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));

    return (
      <div>
      
      <div class="container-fluid">
          <br/><br/>
          <div style={{ fontSize: '22px', color: 'green',paddingLeft:'490px'}}>{this.state.newsuccess}</div>
          <div style={{ fontSize: '16px', color: '#1890ff',paddingLeft:'490px'}}>{this.state.infoMsg}</div>
          <br/>

  <Form id='formId'{...formItemLayout} onSubmit={this.handleSubmit}>


  <div class="row">
<div  class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
  <Form.Item
          label={
            <span>
            Contact Person 1&nbsp;
           
            </span>
          }
          />
          </div>
          <div  class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
          <Form.Item
          label={
            <span>
            Contact Person 2&nbsp;
           
            </span>
          }
          />  
           </div>
     </div>   

<div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
{this.state.contactperson1.map(contactperson1 => (
  <Form.Item
          label={
            <span>
            FirstName&nbsp;
          
            </span>
          }
        >
          {getFieldDecorator('contactPerson', {
               initialValue:contactperson1.firstName,
            rules: [{pattern:/^[A-Za-z\s]+$/,message: 'Please enter the name', whitespace: true, required: true}],
          })(<Input onChange={this.handleChange}id="contactp" name="contactp" disabled={this.state.displayContact1} />)}
        </Form.Item>
))}
        </div>
 
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
        {this.state.contactperson2.map(contactperson2 => (
        <Form.Item label={<span>FirstName
          
        </span>
        }>
          {getFieldDecorator('contactPerson2', {
             initialValue:contactperson2.firstName,
            // rules: [{ pattern:/^[A-Za-z\s]+$/,required: true, message: 'Please enter the name!' }],
            rules: [{ pattern:/^[A-Za-z\s]+$/, }],
          })(<Input onChange={this.handleChange} id="contactp2" name="contactp2" disabled={this.state.displayContact2} />)}
        </Form.Item>
        ))}
        </div>
 </div>  
 {/* End of 1st Row */}
 <div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
{this.state.contactperson1.map(contactperson1 => (
  <Form.Item
          label={
            <span>
            LastName&nbsp;
          
            </span>
          }
        >
          {getFieldDecorator('contactPerson1LastName', {
              initialValue:contactperson1.lastName,
            rules: [{ pattern:/^[A-Za-z\s]+$/,required: true, message: 'Please enter the Last name!' }],
          })(<Input onChange={this.handleChange} id="contactp1lastname" name="contactp1lastname" disabled={this.state.displayContact1}/>)}
           </Form.Item>
 ))}
        </div>
 
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
        {this.state.contactperson2.map(contactperson2 => (
        <Form.Item label={<span>LastName
        </span>
        }>
          {getFieldDecorator('contactPerson2LastName', {
             initialValue:contactperson2.lastName,
            // rules: [{ pattern:/^[A-Za-z\s]+$/,required: true, message: 'Please enter the Last name!' }],
            rules: [{ pattern:/^[A-Za-z\s]+$/, }],
          })(<Input onChange={this.handleChange} id="contactp2lastname" name="contactp2lastname" disabled={this.state.displayContact2} />)}
        </Form.Item>
        ))}
        </div>
 </div>  


 <div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
{this.state.contactperson1.map(contactperson1 =>(
  <Form.Item
          label={
            <span>
            Designation&nbsp;
           
            </span>
          }
        >
          {getFieldDecorator('designation', {
            initialValue:contactperson1.designation,
            rules: [{ pattern:/^[A-Za-z\s]+$/,required: true, message: 'Please input the designation!', whitespace: true }],
          })(<Input onChange={this.handleChange} id="designation" name="designation" disabled={this.state.displayDesignation1} />)}
        </Form.Item>
       ))}
        </div>
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6"> 
        {this.state.contactperson2.map(contactperson2 =>( 
  <Form.Item
          label={
            <span>
            Designation&nbsp;
          
            </span>
          }
        >
          {getFieldDecorator('designation2', {
               initialValue:contactperson2.designation,
            // rules: [{pattern:/^[A-Za-z\s]+$/, required: true, message: 'Please input the designation!', whitespace: true }],
            rules: [{pattern:/^[A-Za-z\s]+$/,  whitespace: true }],
          })(<Input onChange={this.handleChange} id="designation2" name="designation2" disabled={this.state.displayDesignation2} />)}
        </Form.Item>
        ))}
        </div>
 </div> 
{/* End of 2nd Row       */}

 <div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
{this.state.contactperson1.map(contactperson1 =>(
<Form.Item label={<span>E-mail&nbsp;
    <Tooltip title="As per registration">
        <Icon type="question-circle-o" />
    </Tooltip>
    </span>
    }
    >
          {getFieldDecorator('contactEmail1', {
               initialValue:contactperson1.email,
            rules: [
              {
                         //shivani - 3599 - changed RegEx as per validation requirement for hyphen .
                         //saurabh - 3745- changed RegEx, user should not be able to add social domain.
                pattern:/^\s*$|^[a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@(?!gmail.com)(?!gmail.co.in)(?!yahoo.com)(?!yahoo.co.in)[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,

                // pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ],
          })(<Input onChange={this.handleChange} disabled={this.state.displayEmail1} id="contactEmail1" name="contactEmail1" />)}
        </Form.Item>
))}
        </div>
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6"> 
        {this.state.contactperson2.map(contactperson2 =>( 
        <Form.Item label={<span>E-mail&nbsp;
    <Tooltip title="As per registration">
        <Icon type="question-circle-o" />
    </Tooltip>
    </span>
    }
    >
          {getFieldDecorator('contactEmail2', {
              initialValue:contactperson2.email,
            rules: [
              {
                         //shivani - 3599 - changed RegEx as per validation requirement for hyphen .
                         //saurabh - 3745- changed RegEx, user should not be able to add social domain.
                pattern:/^\s*$|^[a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@(?!gmail.com)(?!gmail.co.in)(?!yahoo.com)(?!yahoo.co.in)[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,

                // pattern:/^[a-zA-Z0-9._%+-]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
                message: 'The input is not valid E-mail!',
                
                           },
             
              // {
              //  required: true,
              //   message: 'Please input your E-mail!',
              // },
            ],
          })(<Input onChange={this.handleChange} disabled={this.state.displayEmail1} id="contactEmail2" name="contactEmail2" />)}
        </Form.Item>
        ))}
        </div>
 </div>
{/* End of 2nd Row */}
       

       <div class="row">
       <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
       {this.state.contactperson1.map(contactperson1 => (
       
       <Form.Item label={<span>Phone Number&nbsp;
        <Tooltip title="As per registration">
          <Icon type="question-circle-o" />
      </Tooltip>
   </span>
   }>
         {getFieldDecorator('phone1', {
               initialValue:contactperson1.phoneNo,
           rules: [{pattern:/^[0-9][0-9]{2,12}$/, required: true, message: 'Please enter a valid phone number!' }],
         })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} disabled={this.state.displayPhn1} onChange={this.handleChange} id="phone1" name="phone1" />)}
       </Form.Item>
        ))}
       <span class="errorMessage" style={{paddingLeft:'205px',color:'#f5222d'}}>{this.state.errors.PhoneCode1}</span>
       </div>
       {/* {this.state.advContactInfo.length>1 ?  */}
       <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
       {this.state.contactperson2.map(contactperson2 => (
       <Form.Item label={<span>Phone Number&nbsp;
       <Tooltip title="As per registration">
          <Icon type="question-circle-o" />
      </Tooltip>
      </span>
       }>
         {getFieldDecorator('phone2', {
           initialValue:contactperson2.phoneNo,
          //  rules: [{pattern:/^[0-9][0-9]{2,12}$/,required: true, message: 'Please enter a valid phone number!' }],
          rules: [{pattern:/^[0-9][0-9]{2,12}$/, }],
         })(<Input addonBefore={prefixSelector2} style={{ width: '100%' }} disabled={this.state.displayPhn2}  onChange={this.handleChange} id="phone2" name="phone2" />)}
       </Form.Item>
       ))}
       <span class="errorMessage" style={{paddingLeft:'205px',color:'#f5222d'}}>{this.state.errors.PhoneCode2}</span>
       </div>
      {/* //  :<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
        
      //   <Form.Item label={<span>Phone Number 2&nbsp;
      //   <Tooltip title="As per registration">
      //      <Icon type="question-circle-o" />
      //  </Tooltip>
      //  </span>
      //   }>
      //     {getFieldDecorator('phone2', {
             
      //       rules: [{pattern:/^[0-9][0-9]{2,12}$/, message: 'Please enter a valid phone number !' }],
      //     })(<Input addonBefore={prefixSelector2} style={{ width: '100%' }} disabled={this.state.displayPhn2}  onChange={this.handleChange} id="phone2" name="phone2"   />)}
      //   </Form.Item>
      //   <span class="errorMessage" style={{paddingLeft:'205px',color:'#f5222d'}}>{this.state.errors.PhoneCode2}</span>
  
      //   </div>} */}

 </div> 

 {/* End of 4th row */}
<div class="row" > 
<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
           
          </div>
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
          <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit"  className={this.state.buttonDisplay}>
            Save 
          </Button>
        </Form.Item> 
          </div>
        </div><br/><br/><br/>

        {/* End of 6th row */}

 

 <div class="row">
        <div class="col-xs-4  col-sm-4 col-md-4 col-lg-4">
          </div>
         
          <div class="col-xs-4  col-sm-4 col-md-4 col-lg-4">
          </div>
     </div>





      </Form>  
</div>
<Footer/>
</div> 
// Final Div
     
    );
  }
}
AdvertiserContactInformationEdit.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
const advertiserRegistrationForm1 = Form.create({ name: 'register' })(AdvertiserContactInformationEdit);
export default connect(mapStateToProps, { logoutUser })(withRouter(advertiserRegistrationForm1));
// const AdvertiserRegistrationForm = Form.create({ name: 'register' })(AdvertiserContactInformation);

// export default AdvertiserRegistrationForm;
//ReactDOM.render(<WrappedRegistrationForm />, document.getElementById('container'));
          