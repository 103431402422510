/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 * All Rights Reserved
 */

/*
@author:Nikita
@Description:Email Config
date: 18-12-2019
*/

// import React, { Component } from "react"; //Nilesh-2833-Removing console warnings
import React from "react";
import {
  // Form, //Nilesh-2833-Removing console warnings
  // Input, //Nilesh-2833-Removing console warnings
   button,
  // Card, //Nilesh-2833-Removing console warnings
  // Icon, //Nilesh-2833-Removing console warnings
  // Tooltip, //Nilesh-2833-Removing console warnings
  // Table, //Nilesh-2833-Removing console warnings
  Checkbox,
  // Row, //Nilesh-2833-Removing console warnings
  // Col,  //Nilesh-2833-Removing console warnings
} from "antd";
import { connect } from "react-redux";
import Navigation from "../layouts/navPage";
import PropTypes from "prop-types";
import Footer from "../layouts/footer";
import "./agencyEmailConfig.css";
// import { css } from "emotion";  //Nilesh-2833-Removing console warnings
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
// import { startsWith } from "core-js/core/string";  //Nilesh-2833-Removing console warnings
//import { margins } from "pdfkit/js/page";

const Swal = require("sweetalert2");

const campActivity = [
  { id: "Create Campaign", name: "createCampaign", value: "No" },
  { id: "Campaign Allocation", name: "campaignAllocation", value: "No" },
  { id: "Edit Campaign", name: "editCampaign", value: "No" },
  { id: "Accept Campaign", name: "acceptCampaign", value: "No" },
  { id: "Pause Campaign", name: "pauseCampaign", value: "No" },
  { id: "Resume Campaign", name: "resumeCampaign", value: "No" },
  { id: "Complete Campaign", name: "completeCampaign", value: "No" },
  { id: "Reject Campaign", name: "rejectCampaign", value: "No" },
  { id: "Counter Campaign", name: "counterCampaign", value: "No" },
  { id: "Counter Accept Campaign", name: "counterAcceptCampaign", value: "No" },
  { id: "Counter Reject Campaign", name: "counterRejectCampaign", value: "No" },
  { id: "Active Campaign", name: "activeCampaign", value: "No" },
  { id: "Bidding Allocation", name: "biddingAllocation", value: "No" },
  { id: "Bidding Submission", name: "biddingSubmission", value: "No" },
  { id: "Bidding Review", name: "biddingReview", value: "No" },
  { id: "Live RFP", name: "activeRFP", value: "No" }, //Saurabh – Task- 3789 – Added Live RFP
];

const leadActivity = [
  { id: "Lead Upload", name: "leadUpload", value: "No" },
  { id: "Lead Review", name: "leadReview", value: "No" },
  { id: "Leads Decrement", name: "leadsDecrement", value: "No" },
  { id: "Update Leads Permission", name: "updateLeadsPermission", value: "No" },
];

const pubActivity = [
  { id: "Cancel Publisher", name: "cancelPublisher", value: "No" },
  { id: "End Date Publisher", name: "endDatePublisher", value: "No" },
  { id: "Re-Allocation Campaign", name: "reAllocationCampaign", value: "No" },
  { id: "Landing Page Submit", name: "landingPageSubmit", value: "No" },
  { id: "CS Submit", name: "csSubmit", value: "No" },
  { id: "POC Submit", name: "pocSubmit", value: "No" },
  { id: "Creative Review", name: "creativeReview", value: "No" },
];

// const deadline = [
//     { id: 'First Delivery Date Warn', name: 'firstDeliveryDateWarn', value: 'No' },
//     // { id: 'First Delivery Date Cross', name: 'firstDeliveryDateCross', value: 'No' },
//     { id: 'Accept Campaign Warn', name: 'acceptCampaignWarn', value: 'No' },
//     { id: 'Creatives Upload Warn', name: 'creativesUploadWarn', value: 'No' },
// ];

const others = [
  { id: "Link Agency Publisher", name: "linkAgencyPublisher", value: "No" },
  { id: "Link Agency Advertiser", name: "linkAgencyAdvertiser", value: "No" },
  { id: "New Message", name: "message", value: "No" },
  // { id: 'Add User', name: 'addUser', value: 'No' },
  { id: "Invoice Reviewed", name: "invoiceReviewed", value: "No" },
  {
    id: "Invoice Reviewed Deadline Alert",
    name: "invoiceReviewAlert",
    value: "No",
  },
  { id: "Salesforce Client Setup", name: "clientSetup", value: "No" },
  {
    id: "Salesforce Notifications",
    name: "salesforceNotification",
    value: "No",
  },
  { id: "Marketo Client Setup", name: "marketoClientSetup", value: "No" },
  { id: "Marketo Notifications", name: "marketoNotification", value: "No" },
  { id: "Hubspot Client Setup", name: "hubspotClientSetup", value: "No" },
  { id: "Hubspot Notifications", name: "hubspotNotification", value: "No" },
  { id: "RFP Reminder", name: "rfpSetupReminder", value: "No" }, //karan-task-3515-added rfp reminder
  {id: 'RFP Follow up',name:'rfpFollowupEmail',value:'No'},//kiran-task-4293-added for follow up check box
];

const campActivityForNC = [
  { id: "Edit Campaign", name: "editCampaign", value: "No" },
  { id: "Pause Campaign", name: "pauseCampaign", value: "No" },
  { id: "Resume Campaign", name: "resumeCampaign", value: "No" },
  { id: "Complete Campaign", name: "completeCampaign", value: "No" },
  { id: "Active Campaign", name: "activeCampaign", value: "No" },
  { id: "Live RFP", name: "activeRFP", value: "No" }, //Saurabh – Task- 3789 – Added Live RFP
];

const leadActivityForNC = [
  { id: "Lead Upload", name: "leadUpload", value: "No" },
  { id: "Leads Decrement", name: "leadsDecrement", value: "No" },
  { id: "Voice Link Upload", name: "voiceLinkUpload", value: "No" },
];

const pubActivityForNC = [
  { id: "End Date Publisher", name: "endDatePublisher", value: "No" },
  { id: "Landing Page Submit", name: "landingPageSubmit", value: "No" },
  { id: "CS Submit", name: "csSubmit", value: "No" },
  { id: "POC Submit", name: "pocSubmit", value: "No" },
  { id: "Creative Review", name: "creativeReview", value: "No" },
];

const pubActivityForQA = [
  { id: "Creative Review", name: "creativeReview", value: "No" },
];

const leadActivityForQA = [
  { id: "Lead Review", name: "leadReview", value: "No" },
];

// const deadlineForNC = [
//     // { id: 'First Delivery Date Cross', name: 'firstDeliveryDateCross', value: 'No' },
//     { id: 'Accept Campaign Warn', name: 'acceptCampaignWarn', value: 'No' },
//     { id: 'Creatives Upload Warn', name: 'creativesUploadWarn', value: 'No' },
// ];

const othersForNC = [
  { id: "Link Agency Publisher", name: "linkAgencyPublisher", value: "No" },
  // { id: 'Link Agency Advertiser', name: 'linkAgencyAdvertiser', value: 'No' },
  { id: "New Message", name: "message", value: "No" },
  // { id: 'Add User', name: 'addUser', value: 'No' },
  // { id: 'Invoice Reviewed', name: 'invoiceReviewed', value: 'No' },
  { id: "Salesforce Client Setup", name: "clientSetup", value: "No" },
  {
    id: "Salesforce Notifications",
    name: "salesforceNotification",
    value: "No",
  },
  { id: "Marketo Client Setup", name: "marketoClientSetup", value: "No" },
  { id: "Marketo Notifications", name: "marketoNotification", value: "No" },
  { id: "Hubspot Client Setup", name: "hubspotClientSetup", value: "No" },
  { id: "Hubspot Notifications", name: "hubspotNotification", value: "No" },
  { id: "RFP Reminder", name: "rfpSetupReminder", value: "No" }, //karan-task-3515-added rfp reminder
];

const campPlain = campActivity.map(function(a) {
  return a.name;
});
const pubPlain = pubActivity.map(function(a) {
  return a.name;
});
const leadPlain = leadActivity.map(function(a) {
  return a.name;
});
//const deadlinePlain = deadline.map(function (a) { return a.name });
const otherPlain = others.map(function(a) {
  return a.name;
});

const campPlainNC = campActivityForNC.map(function(a) {
  return a.name;
});
const pubPlainNC = pubActivityForNC.map(function(a) {
  return a.name;
});
const leadPlainNC = leadActivityForNC.map(function(a) {
  return a.name;
});
//const deadlinePlainNC = deadlineForNC.map(function (a) { return a.name });
const otherPlainNC = othersForNC.map(function(a) {
  return a.name;
});
// const pubActivityPlainQA = pubActivityForQA.map(function(a) {   //Nilesh-2833-Removing console warnings
//   return a.name;
// });
// const leadActivityPlainQA = leadActivityForQA.map(function(a) {  //Nilesh-2833-Removing console warnings
//   return a.name;
// });

class AgencyEmailConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campIndeterminate: true,
      pubIndeterminate: true,
      leadIndeterminate: true,
      deadlineIndeterminate: true,
      othersIndeterminate: true,

      campIndeterminateForNC: true,
      pubIndeterminateForNC: true,
      leadIndeterminateForNC: true,
      deadlineIndeterminateForNC: true,
      othersIndeterminateForNC: true,

      pubIndeterminateForQA: true,
      leadIndeterminateForQA: true,

      campCheckAll: false,
      pubCheckAll: false,
      leadCheckAll: false,
      deadlineCheckAll: false,
      othersCheckAll: false,
      emailConfigData: [],
      role: "",
    };
  }

  // campCheckedList
  campOnChange = (campCheckedList) => {
    this.setState({
      campCheckedList,
      campIndeterminate:
        !!campCheckedList.length &&
        campCheckedList.length < campActivity.length,
      campCheckAll: campCheckedList.length === campActivity.length,
    });
  };

  campOnChangeForNC = (campCheckedListForNC) => {
    this.setState({
      campCheckedListForNC,
      campIndeterminateForNC:
        !!campCheckedListForNC.length &&
        campCheckedListForNC.length < campActivityForNC.length,
      campCheckAll: campCheckedListForNC.length === campActivityForNC.length,
    });
  };

  campOnCheckAllChange = (e) => {
    this.setState({
      campCheckedList: e.target.checked ? campPlain : [],
      campIndeterminate: false,
      campCheckedListForNC: e.target.checked ? campPlainNC : [],
      campIndeterminateForNC: false,
      campCheckAll: e.target.checked,
    });
  };

  // pubCheckedList
  pubOnChange = (pubCheckedList) => {
    this.setState({
      pubCheckedList,
      pubIndeterminate:
        !!pubCheckedList.length && pubCheckedList.length < pubActivity.length,
      pubCheckAll: pubCheckedList.length === pubActivity.length,
    });
  };

  pubOnChangeForNC = (pubCheckedListForNC) => {
    this.setState({
      pubCheckedListForNC,
      pubIndeterminateForNC:
        !!pubCheckedListForNC.length &&
        pubCheckedListForNC.length < pubActivityForNC.length,
      pubCheckAll: pubCheckedListForNC.length === pubActivityForNC.length,
    });
  };

  pubOnChangeForQA = (pubCheckedListForQA) => {
    this.setState({
      pubCheckedListForQA,
      pubIndeterminateForQA:
        !!pubCheckedListForQA.length &&
        pubCheckedListForQA.length < pubActivityForQA.length,
      pubCheckAll: pubCheckedListForQA.length === pubActivityForQA.length,
    });
  };

  pubOnCheckAllChange = (e) => {
    this.setState({
      pubCheckedList: e.target.checked ? pubPlain : [],
      pubIndeterminate: false,
      pubCheckedListForNC: e.target.checked ? pubPlainNC : [],
      pubIndeterminateForNC: false,
      pubCheckAll: e.target.checked,
    });
  };

  // leadCheckedList
  leadOnChange = (leadCheckedList) => {
    this.setState({
      leadCheckedList,
      leadIndeterminate:
        !!leadCheckedList.length &&
        leadCheckedList.length < leadActivity.length,
      leadCheckAll: leadCheckedList.length === leadActivity.length,
    });
  };

  leadOnChangeForNC = (leadCheckedListForNC) => {
    this.setState({
      leadCheckedListForNC,
      leadIndeterminateForNC:
        !!leadCheckedListForNC.length &&
        leadCheckedListForNC.length < leadActivityForNC.length,
      leadCheckAll: leadCheckedListForNC.length === leadActivityForNC.length,
    });
  };

  leadOnChangeForQA = (leadCheckedListForQA) => {
    this.setState({
      leadCheckedListForQA,
      leadIndeterminateForQA:
        !!leadCheckedListForQA.length &&
        leadCheckedListForQA.length < leadActivityForQA.length,
      leadCheckAll: leadCheckedListForQA.length === leadActivityForQA.length,
    });
  };

  leadOnCheckAllChange = (e) => {
    this.setState({
      leadCheckedList: e.target.checked ? leadPlain : [],
      leadIndeterminate: false,
      leadCheckedListForNC: e.target.checked ? leadPlainNC : [],
      leadIndeterminateForNC: false,
      leadCheckAll: e.target.checked,
    });
  };

  // deadlineCheckedList
  // deadlineOnChange = deadlineCheckedList => {
  //     this.setState({
  //         deadlineCheckedList,
  //         deadlineIndeterminate: !!deadlineCheckedList.length && deadlineCheckedList.length < deadline.length,
  //         deadlineCheckAll: deadlineCheckedList.length === deadline.length,
  //     });
  // };

  // deadlineOnChangeForNC = deadlineCheckedListForNC => {
  //     this.setState({
  //         deadlineCheckedListForNC,
  //         deadlineIndeterminateForNC: !!deadlineCheckedListForNC.length && deadlineCheckedListForNC.length < deadlineForNC.length,
  //         deadlineCheckAll: deadlineCheckedListForNC.length === deadlineForNC.length,
  //     });
  // };

  // deadlineOnCheckAllChange = e => {
  //     this.setState({
  //         deadlineCheckedList: e.target.checked ? deadlinePlain : [],
  //         deadlineIndeterminate: false,
  //         deadlineCheckAll: e.target.checked,
  //     });
  // };

  // othersCheckedList
  othersOnChange = (othersCheckedList) => {
    this.setState({
      othersCheckedList,
      othersIndeterminate:
        !!othersCheckedList.length && othersCheckedList.length < others.length,
      othersCheckAll: othersCheckedList.length === others.length,
    });
  };

  othersOnChangeForNC = (othersCheckedListForNC) => {
    this.setState({
      othersCheckedListForNC,
      othersIndeterminateForNC:
        !!othersCheckedListForNC.length &&
        othersCheckedListForNC.length < othersForNC.length,
      othersCheckAll: othersCheckedListForNC.length === othersForNC.length,
    });
  };

  othersOnCheckAllChange = (e) => {
    this.setState({
      othersCheckedList: e.target.checked ? otherPlain : [],
      othersIndeterminate: false,
      othersCheckedListForNC: e.target.checked ? otherPlainNC : [],
      othersIndeterminateForNC: false,
      othersCheckAll: e.target.checked,
    });
  };

  handleSubmit = (e) => {
    //camp
    e.preventDefault();

    var finalCampCheckedList = [];
    var data = {};
    var dataForNC = {};
    var dataForQA = {};

    var campCheckedList = this.state.campCheckedList;
    var result2 = campActivity.filter(function(b) {
      var name = b.name.toString();
      return !campCheckedList.includes(name);
    });

    for (var i = 0; i < this.state.campCheckedList.length; i++) {
      var temp = this.state.campCheckedList[i];
      data[temp] = "Yes";
    }

    for (let i = 0; i < result2.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = result2[i].name;
      data[temp] = "No";
    }

    //Added by Sonali for AC
    var campCheckedListForNC = this.state.campCheckedListForNC;
    var result2ForNC = campActivityForNC.filter(function(b) {
      var name = b.name.toString();
      return !campCheckedListForNC.includes(name);
    });

    for (let i = 0; i < this.state.campCheckedListForNC.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = this.state.campCheckedListForNC[i];
      dataForNC[temp] = "Yes";
    }

    for (let i = 0; i < result2ForNC.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = result2ForNC[i].name;
      dataForNC[temp] = "No";
    }

    //pub
    var pubCheckedList = this.state.pubCheckedList;
     result2 = pubActivity.filter(function(b) { //Nilesh-2833-Removing console warnings
      var name = b.name.toString();
      return !pubCheckedList.includes(name);
    });

    for (let i = 0; i < this.state.pubCheckedList.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = this.state.pubCheckedList[i];
      data[temp] = "Yes";
    }

    for (let i = 0; i < result2.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = result2[i].name;
      data[temp] = "No";
    }

    var pubCheckedListForQA = this.state.pubCheckedListForQA;
    var result2ForQA = pubActivityForQA.filter(function(b) {
      var name = b.name.toString();
      return !pubCheckedListForQA.includes(name);
    });

    for (let i = 0; i < this.state.pubCheckedListForQA.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = this.state.pubCheckedListForQA[i];
      dataForQA[temp] = "Yes";
    }

    for (let i = 0; i < result2ForQA.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = result2ForQA[i].name;
      dataForQA[temp] = "No";
    }

    //Added by Sonali for AC
    var pubCheckedListForNC = this.state.pubCheckedListForNC;
     result2ForNC = pubActivityForNC.filter(function(b) { //Nilesh-2833-Removing console warnings
      var name = b.name.toString();
      return !pubCheckedListForNC.includes(name);
    });

    for (let i = 0; i < this.state.pubCheckedListForNC.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = this.state.pubCheckedListForNC[i];
      dataForNC[temp] = "Yes";
    }

    for (let i = 0; i < result2ForNC.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = result2ForNC[i].name;
      dataForNC[temp] = "No";
    }

    var leadCheckedList = this.state.leadCheckedList;
     result2 = leadActivity.filter(function(b) { //Nilesh-2833-Removing console warnings
      var name = b.name.toString();
      return !leadCheckedList.includes(name);
    });

    for (let i = 0; i < this.state.leadCheckedList.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = this.state.leadCheckedList[i];
      data[temp] = "Yes";
    }

    for (let i = 0; i < result2.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = result2[i].name;
      data[temp] = "No";
    }

    //Added by Sonali for AC

    var leadCheckedListForNC = this.state.leadCheckedListForNC;
    result2ForNC = leadActivityForNC.filter(function(b) { //Nilesh-2833-Removing console warnings
      var name = b.name.toString(); 
      return !leadCheckedListForNC.includes(name);
    });

    for (let i = 0; i < this.state.leadCheckedListForNC.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = this.state.leadCheckedListForNC[i];
      dataForNC[temp] = "Yes";
    }

    for (let i = 0; i < result2ForNC.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = result2ForNC[i].name;
      dataForNC[temp] = "No";
    }

    var leadCheckedListForQA = this.state.leadCheckedListForQA;
     result2ForQA = leadActivityForQA.filter(function(b) { //Nilesh-2833-Removing console warnings
      var name = b.name.toString();
      return !leadCheckedListForQA.includes(name);
    });

    for (let i = 0; i < this.state.leadCheckedListForQA.length; i++) {   //Nilesh-2833-Removing console warnings
      let temp = this.state.leadCheckedListForQA[i];
      dataForQA[temp] = "Yes";
    }

    for (let i = 0; i < result2ForQA.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = result2ForQA[i].name;
      dataForQA[temp] = "No";
    }

    //deadline
    // var deadlineCheckedList = this.state.deadlineCheckedList;
    // var result2 = deadline.filter(function (b) {
    //     var name = (b.name).toString();
    //     return !deadlineCheckedList.includes(name)
    // })

    // for (var i = 0; i < this.state.deadlineCheckedList.length; i++) {
    //     var temp = this.state.deadlineCheckedList[i];
    //     data[temp] = 'Yes';
    // }

    // for (var i = 0; i < result2.length; i++) {
    //     var temp = result2[i].name;
    //     data[temp] = 'No';
    // }

    //Added by Sonali for AC

    // var deadlineCheckedListForNC = this.state.deadlineCheckedListForNC;
    // var result2ForNC = deadlineForNC.filter(function (b) {
    //     var name = (b.name).toString();
    //     return !deadlineCheckedListForNC.includes(name)
    // })

    // for (var i = 0; i < this.state.deadlineCheckedListForNC.length; i++) {
    //     var temp = this.state.deadlineCheckedListForNC[i];
    //     dataForNC[temp] = 'Yes';
    // }

    // for (var i = 0; i < result2ForNC.length; i++) {
    //     var temp = result2ForNC[i].name;
    //     dataForNC[temp] = 'No';
    // }

    //other
    var othersCheckedList = this.state.othersCheckedList;
     result2 = others.filter(function(b) { //Nilesh-2833-Removing console warnings
      var name = b.name.toString();
      return !othersCheckedList.includes(name);
    });

    for (let i = 0; i < this.state.othersCheckedList.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = this.state.othersCheckedList[i];
      data[temp] = "Yes";
    }

    for (let i = 0; i < result2.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = result2[i].name;
      data[temp] = "No";
    }

    //Added by sonali for AC
    var othersCheckedListForNC = this.state.othersCheckedListForNC;
     result2ForNC = othersForNC.filter(function(b) { //Nilesh-2833-Removing console warnings
      var name = b.name.toString();
      return !othersCheckedListForNC.includes(name);
    });

    for (let i = 0; i < this.state.othersCheckedListForNC.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = this.state.othersCheckedListForNC[i];
      dataForNC[temp] = "Yes";
    }

    for (let i = 0; i < result2ForNC.length; i++) {  //Nilesh-2833-Removing console warnings
      let temp = result2ForNC[i].name;
      dataForNC[temp] = "No";
    }

    if (this.state.role === "AC") {
      finalCampCheckedList.push(data);
    } else if (this.state.role === "ANC") {
      finalCampCheckedList.push(dataForNC);
    } else {
      finalCampCheckedList.push(dataForQA);
    }

    // const { user } = this.props.auth;  //Nilesh-2833-Removing console warnings

    let configData = {
      activity: finalCampCheckedList,
    }; // karan-task-3723-vapt header and query params

    fetch("/userConfiguration/emailConfigurationInfo", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(configData),
    })
      .then((res) => res.json())
      .then((response) => {
        Swal.fire({
          text: "Email Configuration Done Successfully",
          type: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          preConfirm: () => {
            window.location.reload();
          },
        });
      });
  };

  /**
   * @author Priyanka Patil
   * @param  Description Adeed function to Refresh the page under task 3874
   */
  handleRefreshPage(e) {
    e.preventDefault(); //Nilesh-2833-Removing console warnings
    Swal.fire({
      title: "Are you sure you want to discard the changes?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      allowOutsideClick: false,
      confirmButtonText: "Discard",
    }).then((result) => {
      if (result.value) {
        window.location.href = "/newdashboard";           //Chaitanya-4291-diverted path of Discard button to dashboard
      } else if (result.dismiss === "close") {
        window.location.href = "#";
      } else {
        window.location.href = "#";
      }
    });
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth;

      this.setState({ role: user.role });
      fetch("/userConfiguration/getEmailConfigurationInfo", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }) // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((emailConfigData) => {
          this.setState({ emailConfigData: emailConfigData });

          var tempArray = [];
          // var temp = this.state.emailConfigData.filter(function(obj) {
          //   let keys = Object.keys(obj);
          //   let values = keys.map(function(key) {
          //     var a = obj[key];
          //     if (a == "Yes") { 
          //       tempArray.push(key);
          //     }
          //   });
          // });
          
          //Nilesh-2833-Removing console warnings- in below code add forEach instead of filter
          this.state.emailConfigData.forEach(function(obj) {
            let keys = Object.keys(obj);
            keys.forEach(function(key) {
              var a = obj[key];
              if (a === "Yes") { 
                tempArray.push(key);
              }
            });
          });

          var resultCamp = campActivity.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultPub = pubActivity.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultLead = leadActivity.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          // var resultDeadline = deadline.filter(function (b) {
          //     var name = (b.name).toString();
          //     return tempArray.includes(name)
          // })

          var resultOther = others.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultCampForNC = campActivityForNC.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultPubForNC = pubActivityForNC.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultLeadForNC = leadActivityForNC.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultPubForQA = pubActivityForQA.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultLeadForQA = leadActivityForQA.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          // var resultDeadlineForNC = deadlineForNC.filter(function (b) {
          //     var name = (b.name).toString();
          //     return tempArray.includes(name)
          // })

          var resultOtherForNC = othersForNC.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          const campData = resultCamp.map(function(a) {
            return a.name;
          });
          const pubData = resultPub.map(function(a) {
            return a.name;
          });
          const leadData = resultLead.map(function(a) {
            return a.name;
          });
          //  const deadlineData = resultDeadline.map(function (a) { return a.name });
          const otherData = resultOther.map(function(a) {
            return a.name;
          });

          const campDataForNC = resultCampForNC.map(function(a) {
            return a.name;
          });
          const pubDataForNC = resultPubForNC.map(function(a) {
            return a.name;
          });
          const leadDataForNC = resultLeadForNC.map(function(a) {
            return a.name;
          });
          // const deadlineDataForNC = resultDeadlineForNC.map(function (a) { return a.name });
          const otherDataForNC = resultOtherForNC.map(function(a) {
            return a.name;
          });

          const pubDataForQA = resultPubForQA.map(function(a) {
            return a.name;
          });
          const leadDataForQA = resultLeadForQA.map(function(a) {
            return a.name;
          });

          this.setState({
            campCheckedList: campData,
            pubCheckedList: pubData,
            leadCheckedList: leadData,
            // deadlineCheckedList: deadlineData,
            othersCheckedList: otherData,
            campCheckedListForNC: campDataForNC,
            pubCheckedListForNC: pubDataForNC,
            leadCheckedListForNC: leadDataForNC,
            pubCheckedListForQA: pubDataForQA,
            leadCheckedListForQA: leadDataForQA,
            // deadlineCheckedListForNC: deadlineDataForNC,
            othersCheckedListForNC: otherDataForNC,
          });
          if (
            this.state.emailConfigData &&
            this.state.emailConfigData.length > 0
          ) {
            for (var i = 0; i < this.state.emailConfigData.length; i++) {
              if (this.state.role === "AC") {  //Nilesh-2833-Removing console warnings
                if (
                  this.state.emailConfigData[i].createCampaign === "Yes" &&
                  this.state.emailConfigData[i].campaignAllocation === "Yes" &&
                  this.state.emailConfigData[i].editCampaign === "Yes" &&
                  this.state.emailConfigData[i].acceptCampaign === "Yes" &&
                  this.state.emailConfigData[i].pauseCampaign === "Yes" &&
                  this.state.emailConfigData[i].resumeCampaign === "Yes" &&
                  this.state.emailConfigData[i].completeCampaign === "Yes" &&
                  this.state.emailConfigData[i].rejectCampaign === "Yes" &&
                  this.state.emailConfigData[i].counterCampaign === "Yes" &&
                  this.state.emailConfigData[i].counterAcceptCampaign ===
                    "Yes" &&
                  this.state.emailConfigData[i].counterRejectCampaign ===
                    "Yes" &&
                  this.state.emailConfigData[i].activeCampaign === "Yes" &&
                  this.state.emailConfigData[i].biddingAllocation === "Yes" &&
                  this.state.emailConfigData[i].biddingSubmission === "Yes" &&
                  this.state.emailConfigData[i].biddingReview === "Yes" &&
                  this.state.emailConfigData[i].activeRFP === "Yes" //Saurabh – Task- 3789 – Added Live RFP
                ) {
                  this.setState({ campCheckAll: true });
                }
              } else {
                if (
                  this.state.emailConfigData[i].editCampaign === "Yes" &&
                  this.state.emailConfigData[i].pauseCampaign === "Yes" &&
                  this.state.emailConfigData[i].resumeCampaign === "Yes" &&
                  this.state.emailConfigData[i].activeCampaign === "Yes" &&
                  this.state.emailConfigData[i].completeCampaign === "Yes" &&
                  this.state.emailConfigData[i].activeRFP === "Yes" //Saurabh – Task- 3789 – Added Live RFP
                ) {
                  this.setState({ campCheckAll: true });
                }
              }

              if (this.state.role === "AC") {  //Nilesh-2833-Removing console warnings
                if (
                  this.state.emailConfigData[i].cancelPublisher === "Yes" &&
                  this.state.emailConfigData[i].endDatePublisher === "Yes" &&
                  this.state.emailConfigData[i].reAllocationCampaign ===
                    "Yes" &&
                  this.state.emailConfigData[i].landingPageSubmit === "Yes" &&
                  this.state.emailConfigData[i].csSubmit === "Yes" &&
                  this.state.emailConfigData[i].pocSubmit === "Yes" &&
                  this.state.emailConfigData[i].creativeReview === "Yes"
                ) {
                  this.setState({ pubCheckAll: true });
                }
              } else {
                if (
                  this.state.emailConfigData[i].endDatePublisher === "Yes" &&
                  this.state.emailConfigData[i].landingPageSubmit === "Yes" &&
                  this.state.emailConfigData[i].csSubmit === "Yes" &&
                  this.state.emailConfigData[i].pocSubmit === "Yes" &&
                  this.state.emailConfigData[i].creativeReview === "Yes"
                ) {
                  this.setState({ pubCheckAll: true });
                }
              }

              if (this.state.role === "AC") {
                if (
                  this.state.emailConfigData[i].leadUpload === "Yes" &&
                  this.state.emailConfigData[i].leadReview === "Yes" &&
                  this.state.emailConfigData[i].leadsDecrement === "Yes" &&
                  this.state.emailConfigData[i].updateLeadsPermission === "Yes"
                ) {
                  this.setState({ leadCheckAll: true });
                }
              } else {
                if (
                  this.state.emailConfigData[i].leadUpload === "Yes" &&
                  this.state.emailConfigData[i].leadsDecrement === "Yes" &&
                  this.state.emailConfigData[i].voiceLinkUpload === "Yes"
                ) {
                  this.setState({ leadCheckAll: true });
                }
              }

              if (
                this.state.emailConfigData[i].firstDeliveryDateWarn === "Yes" &&
                this.state.emailConfigData[i].acceptCampaignWarn === "Yes" &&
                this.state.emailConfigData[i].creativesUploadWarn === "Yes"
              ) {
                this.setState({
                  deadlineCheckAll: true,
                });
              }

              if (this.state.role === "AC") {
                if (
                  this.state.emailConfigData[i].linkAgencyPublisher === "Yes" &&
                  this.state.emailConfigData[i].linkAgencyAdvertiser ===
                    "Yes" &&
                  this.state.emailConfigData[i].message === "Yes" &&
                  this.state.emailConfigData[i].invoiceReviewed === "Yes" &&
                  this.state.emailConfigData[i].clientSetup === "Yes" &&
                  this.state.emailConfigData[i].salesforceNotification ===
                    "Yes" &&
                  this.state.emailConfigData[i].marketoClientSetup === "Yes" &&
                  this.state.emailConfigData[i].marketoNotification === "Yes" &&
                  this.state.emailConfigData[i].invoiceReviewAlert === "Yes" &&
                  this.state.emailConfigData[i].hubspotClientSetup === "Yes" &&
                  this.state.emailConfigData[i].hubspotNotification === "Yes" &&
                  this.state.emailConfigData[i].rfpSetupReminder === "Yes" //karan-task-3515-added rfp reminder
                ) {
                  this.setState({ othersCheckAll: true });
                }
              } else {
                if (
                  this.state.emailConfigData[i].linkAgencyPublisher === "Yes" &&
                  this.state.emailConfigData[i].message === "Yes" &&
                  this.state.emailConfigData[i].clientSetup === "Yes" &&
                  this.state.emailConfigData[i].salesforceNotification ===
                    "Yes" &&
                  this.state.emailConfigData[i].marketoClientSetup === "Yes" &&
                  this.state.emailConfigData[i].marketoNotification === "Yes" &&
                  this.state.emailConfigData[i].hubspotClientSetup === "Yes" &&
                  this.state.emailConfigData[i].hubspotNotification === "Yes" &&
                  this.state.emailConfigData[i].rfpSetupReminder === "Yes" //karan-task-3515-added rfp reminder
                ) {
                  this.setState({ othersCheckAll: true });
                }
              }
            }
          }
        });
    }
  }

  render() {
    return (
      <div /*style={{ backgroundColor: "rgb(241, 241, 241)" }}*/ >  {/*Chaitanya-4291-R29-Prod issue-Agency-Setting option-Email Notifications-page horizontal line issue*/}
        <Navigation />
        <div
          id="Container-AG" //mufiz-bug-4111-Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) */
          className="container-fluid"
          style={{ paddingTop: "85px", paddingBottom: "60px" }}
        >
          <p style={{ paddingTop: "20px" }}>
            {/* //shivani-3285-passed ID for DI label consistency */}
            <h3 align="center" id="labelDIAg">
              {" "}
              {/* //mufiz-bug-4111-Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) */}
              Email Notification
            </h3>
            <hr style={{ border: "1px solid #e0e0e0" }} />
          </p>

          {/*Priyanka--3874--Changed design of the Page as per new Design */}
          <div class="container-AG">
            <form className="emailformAGC" onSubmit={this.handleSubmit}>
              <div className="row">
                {this.state.role === "AC" || this.state.role === "ANC" ? (
                  // kiran -  task 3894 - changed some bootstrap classes
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                    <p>
                      <h4 class="hFour" style={{ fontFamily: "roboto" }}>
                        Campaign Related Activity Notification
                        <div
                          className="selectAllAGC
											"
                        >
                          <Checkbox
                            // indeterminate={this.state.campIndeterminate}
                            onChange={this.campOnCheckAllChange}
                            checked={this.state.campCheckAll}
                          >
                            Select All
                          </Checkbox>
                        </div>
                      </h4>
                    </p>

                    {this.state.role === "AC" ? (  //Nilesh-2833-Removing console warnings
                      <div className="background" id="camp_ac_back">
                        <Checkbox.Group
                          className="camp_ac"
                          id="non_single"
                          options={campActivity.map((campActivity) => ({
                            label: campActivity.id,
                            value: campActivity.name,
                          }))}
                          onChange={this.campOnChange}
                          value={this.state.campCheckedList}
                        />
                      </div>
                    ) : (
                      <div className="background" id="camp_anc_back">
                        <Checkbox.Group
                          className="camp_anc"
                          id="non_single"
                          options={campActivityForNC.map(
                            (campActivityForNC) => ({
                              label: campActivityForNC.id,
                              value: campActivityForNC.name,
                            })
                          )}
                          onChange={this.campOnChangeForNC}
                          value={this.state.campCheckedListForNC}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* {/ end of 1st Row /} */}

              <br />
              <br />

              <div className="row">
                {/* kiran -  task 3894 - changed some bootstrap classes */}
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                  <p>
                    <h4 class="hFour" style={{ fontFamily: "roboto" }}>
                      Publisher Related Activity Notification
                      {this.state.role === "AQA" ? (
                        ""
                      ) : (
                        <div className="selectAllAGC">
                          <Checkbox
                            // indeterminate={this.state.pubIndeterminate}
                            onChange={this.pubOnCheckAllChange}
                            checked={this.state.pubCheckAll}
                          >
                            Select All
                          </Checkbox>
                        </div>
                      )}
                    </h4>
                  </p>

                  {/*  style={{ width: '100%', lineHeight: '30px' }} */}
                  {this.state.role === "AC" ? (
                    <div className="background" id="pub_ac_back">
                      <Checkbox.Group
                        className="pub_ac"
                        id="non_single"
                        options={pubActivity.map((pubActivity) => ({
                          label: pubActivity.id,
                          value: pubActivity.name,
                        }))}
                        value={this.state.pubCheckedList}
                        onChange={this.pubOnChange}
                      />
                    </div>
                  ) : this.state.role === "ANC" ? (
                    <div className="background" id="pub_anc_back">
                      <Checkbox.Group
                        className="pub_anc"
                        id="non_single"
                        options={pubActivityForNC.map((pubActivityForNC) => ({
                          label: pubActivityForNC.id,
                          value: pubActivityForNC.name,
                        }))}
                        value={this.state.pubCheckedListForNC}
                        onChange={this.pubOnChangeForNC}
                      />
                    </div>
                  ) : (
                    <div className="background" id="pub_aqa_back">
                      <Checkbox.Group
                        className="pub_aqa"
                        id="single"
                        options={pubActivityForQA.map((pubActivityForQA) => ({
                          label: pubActivityForQA.id,
                          value: pubActivityForQA.name,
                        }))}
                        value={this.state.pubCheckedListForQA}
                        onChange={this.pubOnChangeForQA}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* {/ end of 2nd Row /} */}

              <br />
              <br />

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                  <p>
                    <h4 class="hFour" style={{ fontFamily: "roboto" }}>
                      Lead Related Activity Notification
                      {this.state.role === "AQA" ? (
                        ""
                      ) : (
                        <div className="selectAllAGC">
                          <Checkbox
                            // indeterminate={this.state.othersIndeterminate}
                            onChange={this.leadOnCheckAllChange}
                            checked={this.state.leadCheckAll}
                          >
                            Select All
                          </Checkbox>
                        </div>
                      )}
                    </h4>
                  </p>

                  {/*  style={{ width: '100%', lineHeight: '30px' }} */}
                  {this.state.role === "AC" ? (
                    <div className="background" id="lead_ac_back">
                      <Checkbox.Group
                        className="lead_ac"
                        id="single"
                        options={leadActivity.map((leadActivity) => ({
                          label: leadActivity.id,
                          value: leadActivity.name,
                        }))}
                        value={this.state.leadCheckedList}
                        onChange={this.leadOnChange}
                      />
                    </div>
                  ) : this.state.role === "ANC" ? (
                    <div className="background" id="lead_anc_back">
                      <Checkbox.Group
                        className="lead_anc"
                        id="single"
                        options={leadActivityForNC.map((leadActivityForNC) => ({
                          label: leadActivityForNC.id,
                          value: leadActivityForNC.name,
                        }))}
                        value={this.state.leadCheckedListForNC}
                        onChange={this.leadOnChangeForNC}
                      />
                    </div>
                  ) : (
                    <div className="background" id="lead_aqa_back">
                      <Checkbox.Group
                        className="lead_aqa"
                        id="single"
                        options={leadActivityForQA.map((leadActivityForQA) => ({
                          label: leadActivityForQA.id,
                          value: leadActivityForQA.name,
                        }))}
                        value={this.state.leadCheckedListForQA}
                        onChange={this.leadOnChangeForQA}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <p>
                                    <h4 style={{ fontFamily: 'roboto' }}>Deadline Notification</h4>
                                </p>
                                <div style={{ paddingBottom: '8px' }}>
                                    <Checkbox
                                        // indeterminate={this.state.deadlineIndeterminate}
                                        onChange={this.deadlineOnCheckAllChange}
                                        checked={this.state.deadlineCheckAll}
                                    >
                                        Select All</Checkbox>
                                </div>

                                <div style={{ background: '#fff', padding: '10px 15px 10px 15px', border: '1px solid #E9E9E9' }}>
                                    {this.state.role === "AC" ?
                                        <Checkbox.Group
                                            style={{ display: 'grid' }}
                                            options={deadline.map(deadline => ({ label: deadline.id, value: deadline.name }))}
                                            value={this.state.deadlineCheckedList}
                                            onChange={this.deadlineOnChange}
                                        />
                                        :
                                        <Checkbox.Group
                                            style={{ display: 'grid' }}
                                            options={deadlineForNC.map(deadlineForNC => ({ label: deadlineForNC.id, value: deadlineForNC.name }))}
                                            value={this.state.deadlineCheckedListForNC}
                                            onChange={this.deadlineOnChangeForNC}
                                        />
                                    }
                                </div>
                            </div> */}
              <br />
              <br />

              <div className="row" id="otherNot">
                {this.state.role === "AC" || this.state.role === "ANC" ? (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                    <p>
                      <h4 class="hFour" style={{ fontFamily: "roboto" }}>
                        Other Notification
                        <div className="selectAllAGC">
                          <Checkbox
                            // indeterminate={this.state.othersIndeterminate}
                            onChange={this.othersOnCheckAllChange}
                            checked={this.state.othersCheckAll}
                          >
                            Select All
                          </Checkbox>
                        </div>
                      </h4>
                    </p>

                    {/*  style={{ width: '100%', lineHeight: '30px' }} */}
                    {this.state.role === "AC" ? (
                      <div className="background" id="other_ac_back">
                        <Checkbox.Group
                          className="other_ac"
                          id="non_single"
                          options={others.map((others) => ({
                            label: others.id,
                            value: others.name,
                          }))}
                          value={this.state.othersCheckedList}
                          onChange={this.othersOnChange}
                        />
                      </div>
                    ) : (
                      <div className="background" id="other_anc_back">
                        <Checkbox.Group
                          className="other_anc"
                          id="non_single"
                          options={othersForNC.map((othersForNC) => ({
                            label: othersForNC.id,
                            value: othersForNC.name,
                          }))}
                          value={this.state.othersCheckedListForNC}
                          onChange={this.othersOnChangeForNC}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* {/ end od 4th row /} */}

              <br />
              <br />

              <div class="row">
                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                  <button
                    type="primary"
                    htmltype="submit"
                    class="btn add-button"
                    id="submitBtn" // Mufiz-4111,4117
                    style={{ float: "right" }}
                  >
                    Submit
                  </button>

                  <button
                    type="button"
                    data-dismiss="modal"
                    class="can-btn-email-Ag"
                    style={{ float: "right", marginRight: "8px" }}
                    onClick={this.handleRefreshPage}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* {/ end of container /} */}
        <Footer />
      </div>
    );
  }
}

AgencyEmailConfig.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(AgencyEmailConfig));
