/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanobar Golandaj
@Creation Date:12-09-2019
@Description:UI for (Dashboard) campaign specification
*/

import React, { Component } from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import CampaignSpecificationDashboardDetails from "./campaignSpecificationDashboardDetails";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3766-specification VAPT default header call
import "./campaignSpecificationDashboard.css"; //Mufiz-Bug-4243-R-28.8-Prod Issue-Agency-Campaign specification not opening -Shows blank page
//var greeting = "";//Rutuja 2978 commented this as never used
class CampaignDashboard extends Component {
	constructor() {
		super();
		this.state = {
			campID: "",
			parentCampID: "",
			reallocationID: "",

			agencyID: "",
			step: 2, //snehal-task-2935  assign value for step to display on URL
			button: false,
			// status:'',
			rfpID: "",
			Status: "",
			incompleteCampaignDetails: [],
			tabkey: "",
			campaignStatus:"" //kiran-4538-added campaignStatus variable
		};
		this.handleBackButtonToDashboard =
			this.handleBackButtonToDashboard.bind(this);
		this.handleFileDownload = this.handleFileDownload.bind(this);
		this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
		this.handlePDFFileShowDownload = this.handlePDFFileShowDownload.bind(this);
		this.goToEditCampaign = this.goToEditCampaign.bind(this);
		this.goTocreateCampaign = this.goTocreateCampaign.bind(this); //shivani-task 3121-bind this goTocreateCampaign fun here.
	}

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			// karan-task-3715-replace query params
			var campID, tabkey, parentCampID;
			if (this.props.location.state !== undefined) {
				 campID = this.props.location.state.campID;
				 tabkey = this.props.location.state.tabkey;
				 parentCampID = this.props.location.state.parentCampID;
			} else if (sessionStorage.getItem("campID") !== undefined) {
				 campID = sessionStorage.getItem("campID");
				 tabkey = sessionStorage.getItem("tabkey");
				 parentCampID = sessionStorage.getItem("parentCampID");
			} else {
				if (sessionStorage.getItem("parentCampID") === undefined) {
					sessionStorage.clear();
				}
			}
			const {  user } = this.props.auth; //Rutuja 2978  Removed isAuthenticated as never used
			var agencyID = user.id;
			this.setState({ agencyID: agencyID, parentCampID: parentCampID, tabkey});
			
			fetch("campaign/CampaignDetails?campID=" + campID)
				.then((res) => res.json())
				.then((campaignDetails) => {
					//this.state.campaignName = campaignDetails[0].campaignName;
					this.setState({campaignName: campaignDetails[0].campaignName}); //Rutuja - 2978 commented Above and used this.setState
					//shivani-task 3121-added conditions to hide download button for incomplete flow.
					var Status = campaignDetails[0].status;
					var campaignStatus = campaignDetails[0].campaignStatus;
					var button = this.state.button;
					if (
						Status === "BasicDetails" ||
						Status === "DeliveryOption" ||
						Status === "CampaignSpecification" ||
						Status === "XlsStep1" ||
						Status === "Step1" ||
						Status === "SupportingDocument" || // added this for bug - 3355
						Status === "undefined" ||
						Status === "null" ||
						Status === undefined ||
						Status === null
					) {
						button = false;
					} else {
						button = true;
					}
					this.setState({
						Status: Status,
						button,
						campaignStatus:campaignStatus, //kiran-4538-set state campaignStatus
						rfpID: campaignDetails[0].rfpProposalID,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} // end of Didmount

	handleBackButtonToDashboard(e) {
		e.preventDefault();//Rutuja 2978-added to remove #
		//shivani-task 3121-added this condition for , if user go from incomplete then user should back on the same page after clicking on back button.
        //Nilesh-4920- add user
		const { user } = this.props.auth
		if (this.state.button === false) {
			window.location.href = "/incompleteCampaignTab";
		}
		//Nilesh-4920- add else if condition for ANC
		else if(user.role === "ANC"){
             this.props.history.push("/newdashboard")
		}
		else 
		{
				this.props.history.push("/campaignList", {
				agencyID: this.state.agencyID,});
			
			//karan-task-3684-replace query params
		}
	}

	handleFileDownload(e) {
		var campID = this.state.campID;
		//var parentCampID = this.state.parentCampID; //Rutuja 2978  commented as never used
		/**
		 * @author Narendra Phadke
		 * @param  Description handle the Campaign related Files
		 * @return Description return All The File Download in Zip
		 */
		console.warn("campID===>" + campID);

		var formData = new FormData();
		formData.append("campID", campID);
		//const { isAuthenticated, user } = this.props.auth; //Rutuja 2978  Removed isAuthenticated as never used
		//var userID = user.id; //Rutuja 2978  commented as never used

		//This api is used for dynamic pdf genration
		fetch(
			"agency/downloadDynamicPDFCampaignDetailsforAgency?campID=" +
				campID +
				"&campName=" +
				this.state.campaignName 
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.handlePDFFileShowDownload);

		fetch(
			"agency/downloadCampaignDetailsforAgency?campID=" +
				campID +
				"&campName=" +
				this.state.campaignName 
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.handleFileShowDownload);
	} /** End of handle File Download */

	handleFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		let zipFileDownloadName =
			this.state.parentCampID + "-" + this.state.campaignName + ".zip";

		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/zip",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}

	handlePDFFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		let zipFileDownloadName =
			this.state.parentCampID +
			"-" +
			this.state.campaignName +
			"-CampaignSpecification.pdf";
		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/pdf",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}
	/**
	 * @author Snehal more
	 * @param  Description goToEditCampaign function related to edit campaign direct from camp specification page
	 * @return Description go to edit campaign directly
	 */
	 goToEditCampaign(e) {
		// karan-task-3715-replace query params
		var campID, parentCampID, rfpCampaignID;
		if (this.props.location.state !== undefined) {
			campID = this.props.location.state.campID;
			parentCampID = this.props.location.state.parentCampID;
			rfpCampaignID = this.props.location.state.rfpCampaignID;
		}
		var edit = 1;
		if (rfpCampaignID) {
			if (rfpCampaignID === 0) {
				this.props.history.push("/welcomeEdit", {
					campID,
					parentCampID,
					step: this.state.step,
					edit,
				}); // karan-task-3714-vapt header and query params
			} else {
				this.props.history.push("/welcomeEdit", {
					campID,
					parentCampID,
					rfpCampaignID,
					step: this.state.step,
					edit,
				}); // karan-task-3714-vapt header and query params
			}
		} else {
			// window.location.href = '/welcomePage?campID='+campID+'&parentCampID='+parentCampID;
			this.props.history.push("/welcomeEdit", {
				campID,
				parentCampID,
				step: this.state.step,
				edit,
			}); // karan-task-3714-vapt header and query params
		}
	} //end of function goToEditCampaign



	/**
	 * @author Shivani pathak
	 * task- 3121
	 * @param  Description goTocreateCampaign function related to continue campaign direct from camp specification page
	 * @return Description go to create campaign directly after clicked on continue button.
	 */
	 goTocreateCampaign(e) {
		var campID = e.target.id;
		var status = e.target.getAttribute("status");
		var rfpID = e.target.getAttribute("rfpID");
		var reallocationId = e.target.getAttribute("reallocationId");
		var parentCampID = e.target.getAttribute("parentCampID");

		if (Number(rfpID) === 0) { //rutuja 2978 added numver and chnaged == to ===
			rfpID = "";
		}
		if (rfpID) {
			this.props.history.push("/createcampaign2", {
				campID,
				parentcampID: parentCampID,
				reallocationID: reallocationId,
				rfpCampaignID: rfpID,
				status,
			}); // karan-task-3767-replace query params

			// User Story 3125 : Karan Jagtap : added 1 more status="SupportingDocument"
			if (status === "Step1" || status === "SupportingDocument") { //Rutuja 2978 changed == to ===
				window.location.href =
					// "/step2supportingdocument?campID=" +
					// campID +
					// "&rfpCampaign&rfpCampaignID=" +
					// rfpID +
					// "&status=" +
					// status +
					// "&parentcampID=" +
					// parentCampID +
					// "&reallocationID=" +
					// reallocationId;
					this.props.history.push("/step2supportingdocument", {
						campID,
						rfpCampaignID: rfpID,
						status,
						parentcampID: parentCampID,
						reallocationID: reallocationId,
						rfpCampaign: "",
					}); // karan-task-3719-vapt header and query params
			}
		} else {
			this.props.history.push("/createcampaign2", {
				campID,
				parentcampID: parentCampID,
				reallocationID: reallocationId,
				status,
			}); // karan-task-3767-replace query params
			// User Story 3125 : Karan Jagtap : added 1 more status="SupportingDocument"
			if (status === "Step1" || status === "SupportingDocument") { //Rutuja 2978 changed == to ===
				// window.location.href =
				// 	"/step2supportingdocument?campID=" +
				// 	campID +
				// 	"&parentcampID=" +
				// 	parentCampID +
				// 	"&reallocationID=" +
				// 	reallocationId +
				// 	"&status=" +
				// 	status;
				this.props.history.push("/step2supportingdocument", {
					campID,
					parentcampID: parentCampID,
					reallocationID: reallocationId,
					status,
				}); // karan-task-3719-vapt header and query params
			}
		}
	} //end of function goTocreateCampaign
	render() {
		// karan-task-3715-replace query params
		if (this.props.location.state !== undefined) {
			this.state.campID = this.props.location.state.campID;
			this.state.parentCampID = this.props.location.state.parentCampID;
			this.state.reallocationID = this.props.location.state.reallocationID;
		} else if (sessionStorage.getItem("campID") !== undefined) {
			this.state.campID = sessionStorage.getItem("campID"); 
			this.state.parentCampID = sessionStorage.getItem("parentCampID");
			this.state.reallocationID = sessionStorage.getItem("reallocationID"); 
		}
		const {  user } = this.props.auth; //Rutuja 2978  removed isAuthenticated as never used

		//const status = this.state.Status; //Rutuja 2978  commented as never used
		const campaignStatus = this.state.campaignStatus; //kiran-4538-assign campaignStatus
		return (
			<div>
				<Navigation />
				{/* Nilesh-4839 Add display, flexDirection,flex, backgroundColor, paddingTop, position, top and zIndex in below div */}
				<div class="CampSpecDashBord" style={{ paddingTop: "110px", display: "flex", flexDirection: "column"}}>
					<div class="container-fluid fixed-top" style={{
						flex: "0 0 auto",
						backgroundColor:"rgb(245 245 245)",
						paddingTop: "10px",
						position: "sticky",
						top: "79px",
						zIndex: "999"
					}}>
						<div class="row" style={{ paddingBottom: "20px" }}>
							<div class="col-lg-2 col-md-6 col-sm-12">
							{/* Rutuja 2978 Added href in <a> */}
								<a href="#/"> 
									
									{" "}
									<FaArrowAltCircleLeft
										size={32}
										style={{ float: "left", color: "#056eb8" }}
										title="Back to Dashboard"
										onClick={this.handleBackButtonToDashboard}
									/>
								</a>
							</div>
							<div class="col-lg-2 col-md-0 col-sm-0">
								<label></label>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-12">
								{/* snehal-task-3561-Reff(3428)----spec and cust mapping format issue  */}
								<label
								id="idOfCampSpecDash"
									style={{
										color: "#193D8F",
										fontSize: "28px",
										fontWeight: "500",
										fontFamily: "roboto",
									}}>
									Campaign ID:{this.state.parentCampID}
								</label>
							</div>
							<div class="col-lg-2 col-md-0 col-sm-0">
								<label></label>
							</div>

							{this.state.button === true ? ( //shivani-task 3121-added conditions to hide download button for incomplete flow.
								<div
									class="col-lg-1 col-md-6 col-sm-6"
									style={{ float: "right" }}>
									<button
										type="button"
										class="btn add-button"
										id="campaignSpecDownloadBtn"
										onClick={this.handleFileDownload}>
										Download
									</button>
								</div>
							) : (
								""
							)}

                         {/* kiran-4538-added condition for hide edit button from completed,pause,cancel tab */}
							{this.state.button === true ? ( //shivani-task 3121-added conditions to align Edit button after hiding download button. 
								campaignStatus !== "Live_Incomplete" && campaignStatus !== "Paused" && campaignStatus !== "Cancel Campaign" && campaignStatus !== "Completed"? ( //Nilesh-5052-Add Completed status 
									<div
									class="col-lg-1 col-md-6 col-sm-6"
									style={{ float: "right", marginLeft: "-20px" }}>
										{
										user.role === "AQA" || Number(this.state.tabkey) === 2 || Number(this.state.tabkey) === 1 ? ( //Rutuja 2978 added number and changed == to === 	
											<button
												class="btn add-button"
												disabled
												id="editBtnInCSD"
												onClick={this.goToEditCampaign}>
												Edit Campaign
											</button>
										) : (
											<button
												id="editBtnInCSD"
												class="btn add-button"
												onClick={this.goToEditCampaign}>
												Edit Campaign
											</button>
										)}
									</div>
									):("")
							) : (
								<div
									class="col-lg-1 col-md-6 col-sm-6"
									style={{ float: "right", marginLeft: "100px" }}>
									<button
										class="btn add-button"
										id={this.state.campID}
										status={this.state.Status}
										rfpID={this.state.rfpID}
										parentCampID={this.state.parentCampID}
										reallocationId={this.state.reallocationID}
										onClick={this.goTocreateCampaign}>
										Continue
									</button>
								</div>
							)}
						</div>
					</div>

					{/* <div>  */}
					<CampaignSpecificationDashboardDetails
						greeting={this.state.campID}
						parentCampID={this.state.parentCampID}
					/>
					{/* </div> */}

					<div class="container-fluid" style={{ paddingBottom: "70px" }}>
						<div class="row">
							{/* <div class="col-lg-4"></div>
                   <div class="col-lg-3"><label></label></div> */}
							{this.state.button === true ? (
								<div class="col-lg-5" style={{ paddingTop: "20px" }}>
									<button

										type="button"
										class="btn add-button"
										id="campaignSpecDownloadBtnButton"
										style={{ float: "left" }}
										onClick={this.handleFileDownload}>
										Download
									</button>
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CampaignDashboard.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(CampaignDashboard)
);
