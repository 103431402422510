
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Somnath Keswad
@Creation Date:11-10-2019
@Description:UI for  Advertiser Review Lead
*/

import React from "react";
import Footer from '../layouts/footer';
import PublisherNavigation from '../layouts/navPage';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd'; //sunita-task-3271-added code for ant table 
import { FaArrowAltCircleLeft } from "react-icons/fa";
import './advertisers.css';
import { css } from "emotion"; //sunita-task-3271-imported css as per requirement
import { LeadReviewLoader } from "../loaders/LeadReviewLoader"; // Sandeep-task-3441-added loader
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3768-vAPT URL changes
const Swal = require('sweetalert2')
// const queryString = require('query-string');//kiran-4747-removing console warning-no used
const xlsx = require('xlsx');


//sunita-task-3271-added code for table css
const tableCSS = css({
  backgroundColor: "white",
  fontFamily: "sans-serif",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
    // backgroundColor:'#193D8F-#007CC3(linear gradient) !important',
    color: "white",
    fontSize: "12px",
    textAlign: "center",
    fontWeight: "500",
  },
  "& thead > tr >th": {
    textAlign: "center",
    color: "white",
    // backgroundColor:'#193D8F-#007CC3(linear gradient) !important',
  },
});




class AdvertiserReviewLead extends React.Component {

  constructor() {
    super();
    this.state = {
      fields: {},
      key: 1,
      errors: {},
      campID: '',
      btnDisable: 'disabled1',
      reviewLeadFlag: false,
      reviewlead: [],
      campaignLeadDetails: [],
      uploadLeadFile: [],
      clientDownloadLead: [],
      status: "Accepted",
      parentCampID: '',
      reallocationID: '',
      marketingChannelFlag: false,
      buttonFlag: false ,//shivani-task 3089-used this flag to make submit button enable or disabled.
      tableState: { size: "small" }, //Array for table properties
      pageSize: 100,//sunita-task-3271-added code for pagination
      loading: false, //sunita-task-3271-added code for loader
      listLeadCampID:[], //sunita-task-3271-added this array for storing campID when we are coming from dashboard.
      listLeadCampIDSearch:[],/* sunita-task-3271- added this array for search functionality*/
      tableSearchText:"",//sunita-task 3271-added this parameter for search functionality.
      advertiserID:'', //sunita-task-3271- added as per requirement.
      filesData1: '',//sunita-task-3271-added code for file
      loader2:"",////Sandeep-task-3341-used for loader.
    };


    this.submitreviewlead = this.submitreviewlead.bind(this);
    this.handleDownloadLeadDetails = this.handleDownloadLeadDetails.bind(this);
    this.handleChangeFileUpload = this.handleChangeFileUpload.bind(this);
    this.openLinkInBrowser = this.openLinkInBrowser.bind(this);
    this.advLeadreviewDetails=this.advLeadreviewDetails.bind(this);  //sunita-task-3271-handle change for showing left side campaign data.
    this.handleChangeSearch=this.handleChangeSearch.bind(this);  //sunita-task-3271-handle change for search functionality.
    this.displayCampaignSpecification=this.displayCampaignSpecification.bind(this); //sunita-task-3727-added handle change for displaying campaign specification
    this.handleChangePageSize = this.handleChangePageSize.bind(this);  //kiran-4880-added bind to this function


    // User Story - 3441 - Sandeep - reference to control Loader
		this.loadingRef = React.createRef(); // shows the Loader
		this.closeLoadingRef = React.createRef(); // hides the Loader

  }



  /**
  * @author Sunita Landge - task 3727
  * @param  Description handle the campaignSpecification on advertiser review lead page
  * @return Description redirect to campaignSpecificationAdvertiserDashboard
  */

	 displayCampaignSpecification(e) {
   var campID = this.state.campID;;//Priyanka--4216--accessing campID
   var parentCampID = this.state.reviewlead[0].parentCampID
   sessionStorage.setItem("campID", campID);
   sessionStorage.setItem("parentCampID", parentCampID);
   window.open("/campaignSpecificationAdvertiserDashboard");
   //snehal-task-3766-vAPT URL changes
   // ); //sunita-task-3099-added changes to open link in new tab   
   }



   /**
 * @author Sunita Landge
 * @param  Description for pagination
 * @return return as per page size record
 */

   //sunita-task-3271-added handle change for pagination
    handleChangePageSize(e) {
      var pageSize = e.target.value;
      this.setState({ pageSize: pageSize });
    } //end of handleChangePageSize
  

    /**
  * @author Sunita Landge
  * @param  Description  new function created  for showing information of campaign details.
  */

   //sunita-task-3271-added below code for showing right campaign data.
    getCampaignInfo(campID)
    {
      //This API is for campaign details from campaign table
      fetch("/dashboardAdvertiser/advReviewlead?campID=" + campID)
        .then(res => res.json())
        .then(reviewlead => {
         //kiran-4747-removing console warning-changed == to ===
          if ((reviewlead[0].marketingChannel === "TeleMarketing" && reviewlead[0].callAudit === "Yes") || (reviewlead[0].marketingChannel === "Email/Telemarketing" && reviewlead[0].callAudit === "Yes")) {
            this.setState({ marketingChannelFlag: true })
          }
          else {
            this.setState({ marketingChannelFlag: false })
          }

          //sunita-task-3271-added below code to remove uploaded file when clicking on any other campaign id.
          document.getElementById('abm1').value = ""; 
          document.getElementById('fileName').value = "";
          document.getElementsByClassName("successMessage").value = "";
          document.getElementsByClassName("errorMessage").value = "";

          var parentCampID = reviewlead[0].parentCampID;

          this.setState({ reviewlead: reviewlead, campID: campID ,buttonFlag:false,parentCampID});
        }
        ).catch(function (err) { console.log(err) });
    }


     /**
 * @author Sunita Landge
 * @param  Description new function created for showing lead's campaign id data .
 */

     //sunita-task-3271-showing left side campaign id data
    advLeadreviewDetails(e)
    {
      e.preventDefault();//kiran-4747-removing console warning-added e.preventDefault();
      this.setState({loading:true,loader2:"loader_review"});//Sandeep-task-3341
      var campID = e.target.id;
      const { user } = this.props.auth;
      var advertiserID = user.id;    
      this.setState({campID,advertiserID});
      
     
      // sunita-task-3271- api for showing table data
      fetch("/dashboardAdvertiser/getAcceptedLeadByAgency?campID=" + campID)
      .then(res => res.json())
      .then(campaignLeadDetails => {
       
        this.setState({ campaignLeadDetails: campaignLeadDetails,loading:false ,loader2:''})//Sandeep-task-3341
        if (campaignLeadDetails.length > 0) {
          // this.state.btnDisable = 'btn add-button';
          this.setState({btnDisable:'btn add-button'})//kiran-4747-removing console warning-use setState
          this.forceUpdate();
        }

        //sunita-task-3271-added for voicelog link column
        if((campaignLeadDetails[0].channel==="Telemarketing" && campaignLeadDetails[0].callAudit==="Yes" )||( campaignLeadDetails[0].channel==="Email/Telemarkting" && campaignLeadDetails[0].callAudit==="Yes"))
        {
          this.setState({marketingChannelFlag:true});
        }
        else
        {
          this.setState({marketingChannelFlag:false});
        }

      }).catch(function (err) { console.log(err) });
      this.getCampaignInfo(campID);//sunita-task-3271-this function called for showing right side campaign details data.
      this.fun_Get_Lead_TableData(campID);//sunita-task-3271-called this fun as per requirement for showing table data.

       //sunita-task-3271-added for removing uploaded file name
    var filesData1 = this.state.filesData1;
    if (filesData1 === undefined || filesData1 === "undefined" || filesData1 === "" || filesData1 === null) {
      var label = document.getElementById('fileName');
      label.innerHTML = "No File Choosen"
      // this.state.errors.successMessage = "";
      // this.state.errors.errorMessage = "";
      this.setState({
        errors: {
          successMessage: "",
          errorMessage: ""
        }
      });//kiran-4747-removing console warning-use setState
    }


    }


     /**
  * @author Sunita Landge
  * @param  Description  new function created for showing lead's table data as per campID 
  */

     //sunita-task-3271-showing lead's table data as per camp id
      fun_Get_Lead_TableData(campID)
      {

        this.setState({loading:true});//sunita-task-3271-added for loader
        // const { user } = this.props.auth;

        fetch("/dashboardAdvertiser/getAcceptedLeadByAgency?campID=" + campID)
        .then(res => res.json())
        .then(campaignLeadDetails => {
          
          // if(campaignLeadDetails[0].channel==="Telemarketing"|| campaignLeadDetails[0].channel==="Email/Telemarketing")
          // {
          //   this.setState({marketingChannelFlag: true })
          // }
          // else{
          //   this.setState({marketingChannelFlag: false })
          // }

          this.setState({ campaignLeadDetails: campaignLeadDetails,loading:false,reviewLeadFlag:false }); //sunita-task-3271-added code bcz as after uploading file status is showing in bold form when clicking on other campaign id's.
          if (campaignLeadDetails.length > 0) {
            // this.state.btnDisable = 'btn add-button';
            this.setState({btnDisable:'btn add-button'})//kiran-4747-removing console warning-use setState
            this.forceUpdate();
          }
        }).catch(function (err) { console.log(err) });
  
      }

    /**
   * @author Sunita Landge
   * @param  Description for searching campaign id and it's data.
   * @return return the searched data
   */

    //sunita-task-3271-added for search functionality
  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    // If the search bar isn't empty
    if (e.target.value === "") {
      newList = this.state.listLeadCampID;
    }
    else {
      // Assign the original list to currentList
      currentList = this.state.listLeadCampID;
      newList = currentList.filter(item => {
        // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();  
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        // check to see if the current list item includes the search term
        // If it does, it will be added to newList. Using lowercase eliminates
        // issues with capitalization in search terms and search content
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });

    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      listLeadCampIDSearch: [...newList], tableSearchText: filterData
    });
  }




  openLinkInBrowser(e) {
    e.preventDefault(); //kiran-4747-removing console warning-added e.preventDefault();
    var leadInfoID = e.target.id;
    fetch("lead/getVoiceLogLink?leadInfoID=" + leadInfoID + "")
      .then(res => res.json())
      .then(voiceLogDetails => {
        this.setState({ voiceLogDetails: voiceLogDetails })
        window.open(voiceLogDetails[0].voiceLogLink, '_blank');
      }

      ).catch(function (err) {
        console.log(err)
      });

  }
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
      //snehal-task-3724-vAPT URL changes
      if(this.props.location.state!==undefined){
      var campID = this.props.location.state.campID;
      }
      const { user } = this.props.auth; //sunita-task-3271-taken as per requirement.
      var advertiserID = user.id; //sunita-task-3271-taken as per requirement.
      //Sandeep-task-3341-used for loader
      this.setState({campID,advertiserID,loading:true,loader2:'loader_review'}); //sunita-task-3271-setstate the values for loading, advid .
      
      this.fun_getCampDetailsFromTable(campID);   //Priyanka-4216-calling this function to get camp and lead details
        //sunita-task-3271 - added this api for lead data when user click on id from dashboard and come to lead review page .
        fetch("/dashboardAdvertiser/leadReviewCampID", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((listLeadCampID) => {
            this.setState(
              {
                listLeadCampID: listLeadCampID, loader2:"" 
              }
            );//Sandeep-task-3441-added loader
          })
          .catch(function (err) {});
    }
  }

  handleChange(i, e) {
    const { name, value } = e.target;
    let campaignLeadDetails = [...this.state.campaignLeadDetails];
    campaignLeadDetails[i] = { ...campaignLeadDetails[i], [name]: value };
    this.setState({ campaignLeadDetails });
  }
  /**
         * @author Somnath Keswad
         * @param  Description handle the Download the Lead in excel file
         * @return Description return All details of lead in excel file
         */
  handleDownloadLeadDetails(e) {
    
    let errors = {};
    //snehal-task-3724-vAPT URL changes
    var campID = this.state.campID;   //Priyanka--4216--accessing campID
    var parentCampID = this.state.reviewlead[0].parentCampID;
    if (parentCampID === undefined) { parentCampID = campID; }
    var reallocationID = this.state.reviewlead[0].reallocationID;

    fetch("dashboardAdvertiser/downloadClientQALead?campID=" + campID + "")
      .then(res => res.json())
      .then(downloadFiles => {
        this.setState({ downloadFiles: downloadFiles })
        if (downloadFiles.success === true) {
          errors['failureMsg'] = "No Data Exists"
          this.setState({ errors: errors });
        } else {
          var ws = xlsx.utils.json_to_sheet(downloadFiles);
          /* add to workbook */
          var wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Lead");
          var buf = xlsx.write(wb, { bookType: 'xlsx', type: 'buffer' }); // generate a nodejs buffer
          // var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});
          /* generate a download */
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
          }
          var fileName = parentCampID + '-' + reallocationID + ".xlsx";
          saveAs(new Blob([s2ab(buf)], { type: "application/octet-stream" }), fileName);
        }
      })
  } // End Of handleDownloadLeadDetails


  /**
  * @author Somnath Keswad
  * @param  Description handle the upload lead
  * @return Description return All details of lead
  */
  handleChangeFileUpload(e) {
    e.preventDefault();
    let errors = {};
    //var campID = this.state.campID;//sunita-task-3271-added as per requirement
    //const { user } = this.props.auth; //sunita-task-3271-taken as per requirement.

  //  var advertiserID = user.id;//sunita-task-3271-taken as per requirement.

    // var names = []; //kiran-4747-removing console warning-no used
    var ext;
   // let filesData = e.target.files;
    let filesData1 = e.target.files[0];
    var agencyID = this.state.reviewlead[0].agencyID;
    let message = "";
    if (filesData1 == null) {
      ext = '';
      var label = document.getElementById('fileName')
      label.innerHTML = "No file Choosen"


    }
    else {
      ext = filesData1.name.split('.').pop()
      let label = document.getElementById('fileName')
      label.innerHTML = filesData1.name;
    }

    var data = new FormData();
    if (ext === "xlsx" || ext === "XLSX") {//kiran-4747-removing console warning-changed == to ===
      data.append("campID", this.state.campID);
      data.append("message", message);
      data.append("file", filesData1);
      data.append("agencyID", agencyID);
     data.append("downloadLeadLength",this.state.campaignLeadDetails.length) //Rutuja 5399 passing lead legnth
      fetch("/dashboardAdvertiser/uploadReviewLead", {
        method: 'POST',
        body: data
      }).then(res => res.json())
        .then(campaignLeadDetails => {
          if(campaignLeadDetails.length > 0){
          let len = campaignLeadDetails.length;
          for (let i = 0; i < len; i++) {
            //sonali-3361- added below conditions for advertiserstatus.
            if (campaignLeadDetails[i].advertiserStatus === 'Accepted') {
              campaignLeadDetails[i].status = "Accepted";

              if(campaignLeadDetails[i].campID===this.state.campID)
              {
                campaignLeadDetails[i].status = "Accepted";
              }
              else
              {
                campaignLeadDetails[i].status = "Rejected";
              }
            }
         
           if (campaignLeadDetails[i].advertiserStatus === 'Rejected') {
            campaignLeadDetails[i].status = "Rejected";
          }
            //shivani-task 3089-added this condition to display agencyStatus properly .
            if (campaignLeadDetails[i].status !== "") {
              campaignLeadDetails[i].agencyStatus = "ACCEPTED";
            }
          }
          this.setState({ campaignLeadDetails: campaignLeadDetails, reviewLeadFlag: true, buttonFlag: true })//shivani-task 3089-added buttonFlag:true to make button disabled if i upload leads through excel file.

          errors['successMessage'] = "File Uploaded Successfully" //sunita-task-3271-only removed li tag as it's not showing properly.
      this.setState({ errors: errors })
      
        }  else if (campaignLeadDetails.success == "successfalse"){ //Rutuja 5399 showing swal fire if lead status count is not correct
          Swal.fire({
            text: "Uploaded leads count wrong please enter correct leads count",  //4612-Nilesh-File validation for wrong status upload in file
            type: "error",
            allowOutsideClick: false,
            heightAuto: false,
            preConfirm: () => {
              window.location.reload();
            },
          })
        }
        else if (false === campaignLeadDetails.success) {
          //Chaitanya-5407-validation for wrong camp ID upload in file
          Swal.fire({
            text: campaignLeadDetails.message,
            type: "error",
            allowOutsideClick: false,
            heightAuto: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        }
        else {
          Swal.fire({
            text: "Please enter valid status against leads as per the given instructions on the screen",  //4612-Nilesh-File validation for wrong status upload in file
            type: "error",
            allowOutsideClick: false,
            heightAuto: false,
            preConfirm: () => {
              window.location.reload();
            },
          })
        }

           //sunita-task-3271-added below api for removing particular campaign id from left side after file upload
        fetch("/dashboardAdvertiser/leadReviewCampID", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((listLeadCampID) => {
            this.setState(
              {
                listLeadCampID: listLeadCampID,   
              }
            );
          })



        }).catch(function (err) { console.log(err) });
      
      // errors['successMessage'] = "File Uploaded Successfully" //sunita-task-3271-only removed li tag as it's not showing properly.
      // this.setState({ errors: errors })
    }
    else {
      errors['errorMessage'] = "Invalid File Please Upload With .xlsx Format" //sunita-task-3271-only removed li tag as it's not showing properly.
      this.setState({ errors: errors })

    }
  } // End of handleChangeFileUpload


  /**
  * @author Somnath Keswad
  * @param  Description handle the upload lead from submit button on portal
  * @return Description return All details of lead
  */
  submitreviewlead(e) {
    e.preventDefault();
    
    var campID = this.state.campID;//sunita-task-3271-taken as per requirement.
   // const { user } = this.props.auth;
   // var advertiserID = user.id;//sunita-task-3271-taken as per requirement.
    // let errors = {};//kiran-4747-removing console warning-no used
    let data = {
      campID: campID,
      campaignLeadDetails: this.state.campaignLeadDetails,
      agencyID: this.state.reviewlead[0].agencyID
    }
    this.loadingRef.current.click();//Sandeep-task-3441-added code for loader
    fetch("/dashboardAdvertiser/uploadReviewLeadSubmit", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(campaignLeadDetails => {
       
        let len = campaignLeadDetails.length;
        for (let i = 0; i < len; i++) {
          //kiran-4747-removing console warning-changed == to ===
          if (campaignLeadDetails[i].status === 'Advertiser Accepted') {
            campaignLeadDetails[i].status = "Accepted";
          }
          if (campaignLeadDetails[i].status === 'Advertiser Rejected') {
            campaignLeadDetails[i].status = "Rejected";
          }
        }
        this.setState({ campaignLeadDetails: campaignLeadDetails, reviewLeadFlag: true })       
        if (campaignLeadDetails.length > 0) {
          // errors["leaderror"]="Lead reviewed successfully";

          this.closeLoadingRef.current.click();//Sandeep-task-3441-added code for loader
          Swal.fire({
            text: ("Lead reviewed successfully"),
            type: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
             preConfirm: () => {
              window.location.reload();
            }

          })

         //sunita-task-3271-added beow api for removing particular campaign id from left side after submiting the lead
          fetch("/dashboardAdvertiser/leadReviewCampID", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
            .then((listLeadCampID) => {
              this.closeLoadingRef.current.click();//Sandeep-task-3441-added code for loader
              this.setState(
                {
                  listLeadCampID: listLeadCampID,    
                }
              );
            })
          //  this.setState({errors:errors,btnDisable:'disabled1'});

          this.setState({ btnDisable: 'disabled1' });
        } else {
          this.setState({ btnDisable: 'btn add-button' });
        }
      }).catch(function (err) { console.log(err) });
  } // End of submitreviewlead

  backToDashboard(e) {
    e.preventDefault();//snehal-task-3724-vAPT URL changes
    const { user } = this.props.auth;
    this.props.history.push("/advertiserDashboard",{advertiserID:user.id})//snehal-task-3768-vAPT URL changes
  }

  /**
  * @author Priyanka Patil
  * @param  Description handle the refresh lead review page under task 4216
  * @return Campaign Details and Leads are displaying whose name in top in the left side list
  */

  fun_getCampDetailsFromTable(campID)
  {
    //const { user } = this.props.auth;
   // const { id: agencyID, role: userRole } = user;//kiran-4747-removing console warning-no used
    let data = { campID };	
    fetch("/dashboardAdvertiser/leadReviewCampID", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((listLeadCampID) => {
        //kiran-4747-added toString() and changed == to ===
        var campArray = listLeadCampID.filter((a) => {
					return a.campID.toString() === campID
				})
				if(campArray.length>0){	}
				else{
					campID = listLeadCampID[0].campID;
				}
        this.setState(
          {
            campID,listLeadCampID,loading: false,loader2: "" 
          }
        );
        this.getCampaignInfo(campID);
        this.fun_Get_Lead_TableData(campID);
      })
      .catch(function (err) {});
  }

  render() {

     //sunita-task-3271- below all columns1,columns2 are used for antd table
     const columns1 = [
      {
        title: 'Lead ID',
        dataIndex: 'leadInfoID',
        key: 'leadInfoID',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.leadInfoID}
            </span>

          </div>
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 105,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record) =>
          <div >
            {/* <span style={{ fontSize: '11px',wordBreak: "break-all" }}> */}
            <span style={{ fontSize: '13px', color: '#4F4F4F', wordBreak: "break-all" }}>

              {record.email}
            </span>

          </div>
      },


      {
        title: 'Job Title',
        dataIndex: 'jobTitle',
        key: 'jobTitle',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.jobTitle}
            </span>

          </div>
      },
      {
        // title: 'Status',
        title:'Agency Status',
        dataIndex: 'agencyStatus',
        key: 'agencyStatus',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record, i) =>
          <div >
            <span style={{ fontSize: '14px', color: '#555555 ' }}>

                {record.agencyStatus}
               
            </span>

          </div>
      },


      {
        title: 'Advertiser Status',
        dataIndex: 'status',
        key: 'status',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record, i) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>

              {this.state.reviewLeadFlag === true ?
                <label>{record.status}</label>
                :
                <select style={{ height: '60%' }}
                                id="status" name="status"
                                Value={record.status}
                                defaultValue={this.state.status}
                                onChange={this.handleChange.bind(this, i)}
                                className="form-control">
                                <option value="Advertiser Accepted">Accepted </option>
                                {/* <option value="Approved">Approved</option> */}
                                <option value="Advertiser Rejected">Rejected</option>
                              </select>
              }

            </span>

          </div>
      },

      {
        title: 'Reason for Rejection',
        dataIndex: 'reason',
        key: 'reason',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record, i) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>

              {this.state.reviewLeadFlag === true ?
                <input type="textbox" id="reason" name="reason"
                  defaultValue={record.reason} onChange={this.handleChange.bind(this, i)}
                  class="form-control"></input>
                :
                <input type="textbox" id="reason" name="reason" value={this.state.reason}
                  onChange={this.handleChange.bind(this, i)}
                  class="form-control"></input>
              }

            </span>

          </div>
      },
    ]

    //sunita-task-3271-added below all columns for antd table
    const columns2 = [
      {
        title: 'Lead ID',
        dataIndex: 'leadInfoID',
        key: 'leadInfoID',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.leadInfoID}
            </span>

          </div>
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 105,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record) =>
          <div >
            {/* <span style={{ fontSize: '11px',wordBreak: "break-all" }}> */}
            <span style={{ fontSize: '13px', color: '#4F4F4F', wordBreak: "break-all" }}>

              {record.email}
            </span>

          </div>
      },


      {

        title: 'Voice Log Link',
        dataIndex: 'voiceLogLink',
        key: 'voiceLogLink',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   

        render: (text, record) =>
          <div >
            <span style={{ fontSize: '13px', color: '#1890FF' }}>
            {/* {record.callAudit}
              {record.channel} */}
              {this.state.marketingChannelFlag === true ?
              
              //sunita-task-3324-added condition as per channel for showing voicelog link column
              ((record.channel === "Telemarketing" ||record.Channel==="Telemarketing" )|| (((record.channel=== "Email/Telemarketing"||record.Channel==="Email/Telemarketing")||(record.channel==="Email"||record.Channel==="Email")) && record.callAudit==="Yes"))?
                  //kiran-4747-removing console warning-added "#/"
                  <a href='#/' onClick={this.openLinkInBrowser} id={record.leadInfoID}>Link</a>
                  :
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>No Link Available</span>
                : ''}

            </span>

          </div>
      },


      {
        title: 'Job Title',
        dataIndex: 'jobTitle',
        key: 'jobTitle',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.jobTitle}
            </span>

          </div>
      },
      {
        // title: 'Status',
        title:'Agency Status',
        dataIndex: 'agencyStatus',
        key: 'agencyStatus',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record, i) =>
          <div >
            <span style={{ fontSize: '14px', color: '#555555 ' }}>

                {record.agencyStatus}
               
            </span>

          </div>
      },

      {
        title: 'Advertiser Status',
        dataIndex: 'status',
        key: 'status',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record, i) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>

              {this.state.reviewLeadFlag === true ?
                <label>{record.status}</label>
                :
                <select style={{ height: '60%' }}
                                id="status" name="status"
                                Value={record.status}
                                defaultValue={this.state.status}
                                onChange={this.handleChange.bind(this, i)}
                                className="form-control">
                                <option value="Advertiser Accepted">Accepted </option>
                                {/* <option value="Approved">Approved</option> */}
                                <option value="Advertiser Rejected">Rejected</option>
                              </select>
              }

            </span>

          </div>
      },

      {
        title: 'Reason for Rejection',
        dataIndex: 'reason',
        key: 'reason',
        width: 90,
        align: 'left',//Sandeep-task-3441-algin table data to left   
        render: (text, record, i) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>

              {this.state.reviewLeadFlag === true ?
                <input type="textbox" id="reason" name="reason"
                  defaultValue={record.reason} onChange={this.handleChange.bind(this, i)}
                  class="form-control"></input>
                :
                <input type="textbox" id="reason" name="reason" value={this.state.reason}
                  onChange={this.handleChange.bind(this, i)}
                  class="form-control"></input>
              }

            </span>

          </div>
      },

    ]


    function onChange(pagination, filters, sorter) {

    }




    return (
      <div>
         <LeadReviewLoader
					loadingRef={this.loadingRef}
					closeLoadingRef={this.closeLoadingRef}
					message="Please wait. Saving the data...."
				/>
         {/* Sandeep-task-3341-added code for loader */}
        <PublisherNavigation />
        <div class="container-fluid" style={{ paddingTop: '85px' }}>{/*sunita-task-3271-added code as per requirement */}
        
          {/* <div class="container-fluid" style={{ paddingTop: '85px' }}> */}
            {/*    
          <Tabs activeKey={this.state.key} onSelect={this.handleSelect}>  
          <Tab eventKey={1} title="Lead QA review by Agency"> */}

                  {/* sunita-task-3271- added below code as per new screen requirement */}
                <div class="row" >
                  <div class="container-fluid" style={{marginTop:'10px'}}>
                    <div class="row" style={{ paddingBottom: '6px' }}>
                      <div class="col-md-1">
                      {/* kiran-4747-removing console warning-added "#/" */}
                        <a href="#/"> <FaArrowAltCircleLeft size={32} style={{ float: 'left', color: '#056eb8' }} title="Back to Dashboard" onClick={this.backToDashboard.bind(this)} /></a>
                      </div>

                      {/* <div class="col-md-11"> */}
                      <div class="col-md-8 reviewLead1"> {/*sunita-task-3727-added column as per requirement */}
                        
                        <center><label id="labeladvertiser" align="center" style={{fontSize: "28px", fontFamily: 'roboto', fontWeight: "500", color: "#193D8F", textAlign: 'center',marginRight:'110px'}}> Lead Review</label></center>

                      </div>
                          {/*sunita-task-3727-added below code as per new requirement for opening the specification page */}
                      <div class="col-sm-12 col-md-3 col-lg-3 specificationCamp1" style={{textAlign:'right'}}>
                      <button class="btn add-button"  onClick={this.displayCampaignSpecification}> Campaign Specification</button>&nbsp;
                      </div>

                    </div>
                  </div>

                </div>
          <form name="reviewlead" onSubmit={this.submitreviewlead} encType="multipart/form-data" >{/*Akash-task-3892-Production Issue---Lead Issue--Adv_Side */}

            <div class="card card-signin my-1">
              <div class="card-body" style={{ padding: '5px' }} >

                {/* sunita-task-3271-removed code from here and added above */}
                {/* <div class="row">
                  <div class="container-fluid">
                    <div class="row" style={{ paddingBottom: '20px' }}>
                      <div class="col-md-1">
                        
                        <a> <FaArrowAltCircleLeft size={32} style={{ float: 'left', color: '#056eb8' }} title="Back to Dashboard" onClick={this.backToDashboard.bind(this)} /></a>
                      </div>

                      <div class="col-md-11">
                        <center><label style={{ color: '#056eb8', fontSize: '20px' }}> Lead Review</label></center>

                      </div>

                    </div>
                  </div>

                </div> */}
                {/* <div style={{ MozBoxShadow: '3px 3px 5px 0px #e6e6ff', WebkitBoxShadow: '3px 3px 5px 1px #e6e6ff', boxShadow: '3px 3px 5px 0px #e6e6ff' }}> */}

                {/* sunita-task-3271- added below code as per new screen requirement */}
                <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 campaignslistsadv " style={{ background: '#FFFFFF', borderRight: '2px solid #eee', padding: '0px', height: '90px' }}>
                
                       <div>
                        {/* //sunita-task 3271- i have added black color for the label as per @Tanuja's suggetion (it was blue in screen)*/}
                        <center><label className="campLeadHeading" style={{ fontSize: '17px', color: '#14254A', marginTop: '10px', fontWeight: '500', fontFamily: 'roboto', marginLeft: '-63px' }}>Campaigns for Lead Review :</label></center>
                      </div>

                      {/* sunita-task-3271-added below code for as search */}
                      <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                          <span >
                            <input type="text" className="inputstyle4" name="search" placeholder="Search Campaign"
                              style={{
                                backgroundImage: 'url(searchIcon.png)',
                                backgroundPosition: '15px 6px',
                                backgroundSize: '15px 15px',
                                backgroundRepeat: "no-repeat",
                                width: '280px',
                                height: '28px',
                                fontSize: '12px',
                                // float: 'left',
                                // marginLeft: '17px',
                                border: '1px solid #CCCCCC',
                                padding: '12px 20px 15px 36px',
                                backgroundColor: "#FFFFFF",
                                // marginBottom: '5px',
                                // borderBottom: '1px solid #eee',
                                fontFamily: 'sans-serif',
                                borderRadius: '2px',
                              }}
                              onChange={this.handleChangeSearch} onPressEnter={this.handleChangeSearch}
                            >


                            </input>
                          </span>
                        </div>
                      </div>

                      <br />
                      <hr />


                      {/* sunita-task-3271-added below condition as search table data */}
                    <div style={{ overflowY: 'scroll', height: '1604px', background: '#FFFFFF', padding: '0px', borderRight: '2px solid #eee', marginTop: '-9px' }}> {/*sunita-task-3271-added col and it's data as per requirement */}


                              {this.state.tableSearchText === "" ?

                                <div  >

                                  {this.state.listLeadCampID.map(listLeadCampID => (
                                    <div style={{ borderBottom: '1px solid #eee', padding: '5px 15px' }} className="campNames">
                                      {/* kiran-4747-removing console warning-added "#/" */}
                                      <a href="#/" className="reviewcampdata" id={listLeadCampID.campID} onClick={this.advLeadreviewDetails}>{listLeadCampID.campID} - {listLeadCampID.clientCampID} - {listLeadCampID.campaignName} </a>{/* Saurabh – Task- 3747 – Adding Agency campaign ID i.e clientCampID */}
                                    </div>
                                  ))}

                                </div>

                                :

                                this.state.listLeadCampIDSearch.length > 0 ?
                                  <div >

                                    {this.state.listLeadCampIDSearch.map(listLeadCampIDSearch => (
                                      <div style={{ borderBottom: '1px solid #eee', padding: '5px 15px' }} className="campNames">
                                        <a href="#/" className="reviewcampdata" id={listLeadCampIDSearch.campID} onClick={this.advLeadreviewDetails}>{listLeadCampIDSearch.campID} - {listLeadCampIDSearch.clientCampID} - {listLeadCampIDSearch.campaignName} </a>{/* Saurabh – Task- 3747 – Adding Agency campaign ID i.e clientCampID */}
                                      </div>
                                    ))}

                                  </div>

                                  : <center><label style={{ color: 'red', fontSize: 'medium', marginTop: '34px' }}>No Data Exist</label></center>
                              }

                     </div>

                </div>

                <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9" style={{ background: '#FFFFFF', paddingTop: '20px' }}>{/*sunita-task-3271-added before whole data in this column as per requirement */}
                  <div >

                    {this.state.reviewlead.map(
                      reviewlead => (
                        <div class="row" style={{ marginTop: '-9px' }}>

                          {/* <div class="col-sm-2"> */}
                          {/*sunita-task-3271-added below label css changes for campID,campname,start date,end date and status as per new requirement for alignment*/}
                          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                            <label style={{ fontSize: '14px', color: 'black', fontWeight: '600' }}>
                              Campaign ID :
                              </label>
                              &nbsp;<span>{reviewlead.parentCampID}</span>
                          </div>

                          {/* <div class="col-sm-3" > */}
                          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-8">
                            <label style={{ fontSize: '14px', color: 'black', fontWeight: '600' }} >
                              Campaign Name :
                              </label>
                              &nbsp;<span className="reviewcampdata1">{reviewlead.campaignName}</span>
                          </div>

                         {/* <div class="col-sm-3"> */}
                         <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                            <label style={{ fontSize: '14px', color: 'black', fontWeight: '600' }} >Campaign Status : </label>
                            &nbsp;<span>{reviewlead.campaignStatus}</span>
                          </div>


                          {/* <div class="col-sm-2"> */}
                          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                            <label style={{ fontSize: '14px', color: 'black', fontWeight: '600' }} >Start Date : </label>
                            &nbsp;<span>{reviewlead.startDate}</span>
                          </div>


                          {/* <div class="col-sm-2"> */}
                          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-4">
                            <label style={{ fontSize: '14px', color: 'black', fontWeight: '600' }} >End Date : </label>
                            &nbsp;<span>{reviewlead.endDate}</span>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <br/>
                  <hr/>
                {/* </div>
                <br /> */}
                <div class="row">
                  {/* className=" table table-bordered table-striped "  */}
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 ">
                      {/* <p>Instruction :-Click on "Download Leads" to review agency accepted leads.</p> */}
                        <div> {/*sunita-task-3271-added below changes as per new requirement screen for instructions*/}
                          
                          <h5><span style={{ fontWeight: "bold", color: "#F28C0F" }}>Offline Lead Review Instructions :- </span><br /></h5>
                           <span>1. Click on <b>"Download Leads"</b> to get Lead's File.</span><br/>
                          <span>2. After reviewing the lead, Please update status as <b> "Accepted" OR "Rejected" </b>
        in a advertiser's status column in Lead's File.</span>
                        </div>
                        <div>
                          <span>3. If you rejecting the lead then please <b>add reason of rejection</b> in reason column in Lead's File.</span><br/>
                          <span>4. Click on <b>"Upload Lead"</b> to upload reviewed Lead's File.</span>
                        </div>
                      </div>
                </div>
                <div class="row" style={{marginTop:'10px',marginBottom:'10px'}}>{/*sunita-task-3160-added row and column as per requirement */}
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                            <button class="btn add-button" type="button" onClick={this.handleDownloadLeadDetails} style={{ backgroundColor: "#056eb8", color: "white", height: '32px', fontSize: '14px',fontFamily: 'sans-serif',fontWeight: '400', borderRadius: '4px' }}> {/*sunita-task-3271-added as per requirement */}
                              Download Leads
                            </button>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 " id="lead_button1" style={{ marginTop: '1px' }}>{/*sunita-task-3160-adjusted css as per requirement */}
                    {/* <p>Instruction :- Click below to upload review file</p>                                     */}
                     {/*sunita-task-3271-added as per requirement */}
                    <label class=" btn btn-defaulttab" style={{ height: '32px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px', backgroundColor: 'transparent', color: '#193D8F', border: '1px solid #193D8F' }}>Upload Lead
                    <input style={{ display: 'none' }} type="file" id="abm1" name="abmFiles" multiple="multiple" key={this.state.filesData1} //sunita-task-3271-added for file
                      onChange={this.handleChangeFileUpload} />
                    </label> 
                      <span id="fileName">No File Choosen</span>
                    <div className="successMessage">{this.state.errors.successMessage}</div>
                    <div className="errorMessage">{this.state.errors.errorMessage}</div>
                    {/* <Upload  onChange={this.handleupload}>
                        <Button>
                            <Icon type="upload" /> Click to Upload
                        </Button>
                    </Upload> */}
                  </div>

                  </div>

                  <div class="row" style={{marginBottom:'6px'}}>{/*sunita-task-3160-added row and below column as per requirement */}
                        <div className="col-xs-12 col-sm-12 col-md-9 col-lg-10 col-xl-10" >
                            <span style={{ fontWeight: "bold", marginTop: '115px', color: "#056eb8" }}>Online Lead Review & Instant Status Update:-<br />
                            </span>
                            <span>Total Leads : {this.state.campaignLeadDetails.length}</span>{/*sunita-task-3271-added as per requirement */}

                            <div style={{ fontSize: '18px', color: 'green', textAlign: 'center' }}>
                              {this.state.errors.leaderror}
                            </div>

                            <div style={{ fontSize: '20px', color: 'red', textAlign: 'center' }}>
                              {this.state.errors.failureMsg}
                           </div>
                        </div>
                            
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 advsubmitBtn" style={{marginTop:'5px'}}>{/*sunita-task-3160-addeed column and below button as per requirement */} 
                            {this.state.buttonFlag === true ? //shivani-task 3089-added condition for buttonFlag:true to make button disable if i upload leads through excel file.
                                  <button class="btn add-button" id="advbtn"
                                    disabled className={this.state.btnDisable} type="submit"
                                    style={{ align: "right", paddingBottom: '6px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px' }} 
                                  >Submit</button> /*sunita-task-3271-added css as per requirement */
                                
                              :

                                <button class="btn add-button" id="advbtn"
                                  className={this.state.btnDisable} type="submit" 
                                  style={{ align: "right", paddingBottom: '6px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px' }}
                                >Submit</button> /*sunita-task-3271-added css as per requirement */
                              
                            }
                       </div>
                 </div>

                     {/*sunita-task-3271-added below antd table as per requirement before here used is normal bootstrap table */}
                     <div id={this.state.loader2}></div>

                     {/* //Sandeep-task-3341 */}
                      <Table {...this.state.tableState}
                        id="myTable"
                        bordered
                        dataSource={this.state.campaignLeadDetails}
                         onChange={onChange}

                         columns={this.state.marketingChannelFlag === true ? columns2 : columns1}
                        // columns={columns1}
                        //loading={this.state.loading}
                        // className="ant-table-thead"
                        // className={tableCSS}
                        className={`${tableCSS} "ant-table-thead"`} //kiran-4747-removing console warning-changed classname
                        // scroll={{ x: 1375, y: 4000 }}
                        scroll={{ x: 1375, y: 1333 }}
                        pagination={{
                          pageSize: this.state.pageSize,
                          position: "bottom",

                        }}>

                      </Table>
                      {/* sunita-task-3271-added below code for paginaion */}
                      <div class="row">
                        <div
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                          style={{ marginTop: "-52px", marginLeft: "5px", fontSize: '13px', color: '#4F4F4F', fontFamily: 'sans-serif' }}>
                          &nbsp;Records per page:&nbsp;
                                <select
                            defaultValue={this.state.pageSize}
                            onChange={this.handleChangePageSize}
                            id="pacing1"
                            class="input-small"
                            className="form-control"
                            name="pacing"
                            style={{ width: "71px", height: "30px", display: "initial", fontSize: '13px', fontFamily: 'sans-serif', color: '#4F4F4F' }}>
                            <option value="100" selected>
                              100
                                  </option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                          </select>
                        </div>
                      </div>

                      <br />


{/* ========================sunita-task-3271-commented below normal bootstrap table code(jst kept for backup) and added new antd table */}

                {/* <div class="table-responsive-lg"> */}
                {/* <div style={{overflowY:'scroll',maxHeight:'2526px',tableLayout:'fixed'}}>sunita-task-3180-added css as per requirement */}
                  {/* <table class="table table-bordered "
                    style={{ paddingTop: '80px', borderCollapse: 'collapse', border: '1px solid #D3D3D3', overflowX: 'hidden' }}> */}
                    {/* <thead style={{position:'sticky',top:'0'}} >sunita-task-3180-added css as per requirement */}
                      {/* <tr>
                        <th style={{ width: '90px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Lead ID</th>
                        <th style={{ width: '105px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Email</th>
                        {this.state.marketingChannelFlag === true ?
                          <th style={{ width: '105px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Voice Log Link</th>
                          : ''}
                        <th style={{ width: '150px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Job title</th>
                        <th style={{ width: '150px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Agency Status</th>
                        <th style={{ width: '150px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Advertiser Status</th>
                        <th style={{ width: '130px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Reason for rejection</th>
                      </tr>
                    </thead> */}



                    {/* {this.state.campaignLeadDetails.map((campaignLeadDetails, i) => (
                      <tbody style={{ height: '260px', textAlign: 'center' }} >
                        <tr >
                          <td>
                            {campaignLeadDetails.leadInfoID}
                          </td> */}
                          {/* <td>
                      {campaignLeadDetails.supportDocID}
                        </td> */}
                          {/* <td>
                            {campaignLeadDetails.email}
                          </td>
                          {this.state.marketingChannelFlag === true ?
                            campaignLeadDetails.channel === "Telemarketing" ?
                              <td>
                                <a href='#' onClick={this.openLinkInBrowser} id={campaignLeadDetails.leadInfoID}>Link </a>
                              </td> : <td>No Link Available</td>
                            : ''}

                          <td>
                            {campaignLeadDetails.jobTitle}
                          </td>

                          <td>
                            {campaignLeadDetails.agencyStatus}
                          </td> */}

                          {/* {this.state.reviewLeadFlag === true ?

                            <td>
                              <label>{campaignLeadDetails.status}</label></td>
                            :
                            <td>
                              <select style={{ height: '60%' }}
                                id="status" name="status"
                                Value={campaignLeadDetails.status}
                                defaultValue={this.state.status}
                                onChange={this.handleChange.bind(this, i)}
                                className="form-control">
                                <option value="Advertiser Accepted">Accepted </option> */}
                                {/* <option value="Approved">Approved</option> */}
                                {/* <option value="Advertiser Rejected">Rejected</option>
                              </select>
                            </td>


                          } */}

                          {/* {this.state.reviewLeadFlag === true ?
                            <td>
                              <input type="textbox" id="reason" name="reason"
                                defaultValue={campaignLeadDetails.reason} onChange={this.handleChange.bind(this, i)}
                                class="form-control"></input>
                            </td> :
                            <td>
                              <input type="textbox" id="reason" name="reason" value={this.state.reason}
                                onChange={this.handleChange.bind(this, i)}
                                class="form-control"></input>
                            </td>}
                        </tr>
                      </tbody>))} */}



                  {/* </table>
                </div>
                </div> */}
{/* ============================= */}

                
                {this.state.buttonFlag === true ? //shivani-task 3089-added condition for buttonFlag:true to make button disable if i upload leads through excel file.

                  <div class="col" align="right" style={{ paddingBottom: '20px' }}>
                    <button class="btn add-button"
                      disabled className={this.state.btnDisable} type="submit"
                      style={{ fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px' }}
                    >Submit</button> {/*sunita-task-3271-added css as per requirement */}
                  </div>
                  :
                  <div class="col" align="right" style={{ paddingBottom: '20px' }}>
                    <button class="btn add-button"
                      className={this.state.btnDisable} type="submit"
                      style={{ fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px' }}
                    >Submit</button> {/*sunita-task-3271-added css as per requirement */}
                  </div>}

                  <br/>

              </div>
            </div>
            </div>


        </form> {/* //end of form*/}
        </div>




        <Footer />
      </div>
    )
  }

}
/**
      * @author Narendra Phadke
      * @param  Description handle the login authentication
      * @return Description return All details of authentication
      */
AdvertiserReviewLead.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(AdvertiserReviewLead));
