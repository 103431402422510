/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Snehal More
@file Name:clientSetupHelp.js
@Description: UI for clientSetupHelp
*/


import React from 'react';
import {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../login/authentication';
import Navigation from "../layouts/navPage";
import Footer from '../layouts/footer'; 
// import { Button, Badge } from 'antd'; //Nilesh-4800-Removing console warnings
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaArrowAltCircleLeft } from "react-icons/fa";
// import {Modal } from 'react-bootstrap'; //Nilesh-4800-Removing console warnings

// const Swal = require('sweetalert2');
// const queryString = require('query-string');
// var region;
class SalesforceLeadHelp extends Component{
    constructor(props){
        super(props);
        this.state={
        }
        }//end of state
        componentDidMount(){
            if(!this.props.auth.isAuthenticated) {
                this.props.history.push('/userLogin');
            }
            else{
                console.log("Log");
               
    
            }
        }
        ClientsetupBackButton(e){
             e.preventDefault(); //Nilesh-4800-Removing console warnings
              window.location.href="salesforceLead"
                
        }
        render(){

    //  const {isAuthenticated, user} = this.props.auth; //Nilesh-4800-Removing console warnings
    return(
            
     <div>
            <Navigation />
            <div class="container-fluid" style={{ paddingTop: '110px', marginBottom:'50px'}}>
                     <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1">
                        < a class="col" href='#/'   //Nilesh-4800-Removing console warnings
                        style={{color: '#056eb8'}}onClick={this.ClientsetupBackButton.bind(this)} >
                        <FaArrowAltCircleLeft size={32} title="" className="backFrom_salesforce"/>
                        </a>
                        </div>
                    </div>
                    <div class="container">
                        <h3 style={{color:' #14254A'}}> Upload Lead In Salesforce</h3>
                        <div class="container">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe 
                                title="Salesforce Lead Video" //Nilesh-4800-Removing console warnings
                                style={{border:'1px solid lightgrey'}}
                                class="embed-responsive-item" 
                                src="salesforcelead.mp4" ></iframe>
                            </div>
                        </div>
                        {/* <img className="img-responsive" style={{marginTop:'10px',border:'1px solid grey'}} src="clientsetupHelp1.png" width="80%" height="500px"></img> */}
                        <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>Client Setup: -  </span><br />  To check the lead which are newly added 
                        in current day, where salesforce is check the lead are already present in system or not. If lead is already present in system, then salesforce
                         will reject that lead as well as if uploaded lead is not present in DI that time this lead will upload in salesforce and create the SFID for 
                         that specific lead.</p>
                        {/* <img className="img-responsive" style={{marginTop:'10px',border:'1px solid grey'}} src="clientsetupHelp1.png" width="80%" height="500px"></img> */}
                        </div>

                       
            </div>
            <Footer/>
    </div>          
         
               
            )}
            };//end of class
       /**
       * @author Snehal More
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      SalesforceLeadHelp.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(SalesforceLeadHelp));










