/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanjana Godbole
@Creation Date:07-01-2019
@Description:

*/
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
// import { Button, Icon, Tooltip } from "antd";//kiran-4747-removing console warning-no used
import * as $ from "jquery";
import { withRouter } from "react-router-dom";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import "./advertisers.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3724-vAPT URL changes
// const queryString = require("query-string"); //kiran-4747-removing console warning-no used
const Swal = require("sweetalert2");
// var lpresult = [];//kiran-4747-removing console warning-no used
var formIsValid = false;

class AdvertiserCreativesReview extends React.Component {
  constructor() {
    super();
    this.state = {
      advertiserID: "",
      newsuccess: "",
      dynamic: [],
      stateCopy: [{ status: "", feedback: "" }],
      newDynamicArray: [],
      campID: "",
      pID: "",
      publisherName: "",
      parentCampID: "",
      reallocationID: "",
      errors: {},
      reerrors: {},
      lpReviewPendingListCampIDOnNextPage: [],
      lpReviewPendingList: [],
      creativeReview: [],
      tableStatus: "false",
      agencyList: [],
      landingPageList: [],
      pocList: [],
      alertDisplay: "none",
      alertBorder: "",
      alertWidth: "",
      alertAlign: "",
      spanSpaceDisplay: "none",
      lperror: {},
      cserror: {},
      pocerror: {},
      marketingChannel: "",
      count: 0,
      agencyID: "",
      backKey: "",
      tempArray: [],
      creativesMessage: "",
      submitButton: "btn add-button",
      btnDisable: true,
      approvalFor: "Agency",
      creativeCampList: [], 
      searchData: ``, 
      listCampID: [], 
      ListForLPReview: [], 
      agencyListForLPReview: [], 
    }; // end of this.state
    //kiran-4174-added searchData 
    this.submitLpReviewFeedback = this.submitLpReviewFeedback.bind(this);
    this.handleChangeLpFeedback = this.handleChangeLpFeedback.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.liveTab = this.liveTab.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleChangeStatus1 = this.handleChangeStatus1.bind(this);
    this.handleChangeStatus2 = this.handleChangeStatus2.bind(this);
    this.downloadPOCFile = this.downloadPOCFile.bind(this);
    this.apiCall = this.apiCall.bind(this);
    this.handleCampID = this.handleCampID.bind(this);
  } // end of constructor

  handleCampID(e) {
    e.preventDefault();
    const campID = e.target.id;
    const pID = "All";
    this.setState({ campID });
    this.apiCall(campID, pID); //kiran- 4174-added call
  }
  handleChangeStatus2(e) {
    var timeline = document.getElementById("timeline2");
    //kiran-4747-removing console warning-changed == to ===
    if (e.target.value === "Rejected") {
      timeline.style.display = "block";
    } else {
      timeline.style.display = "none";
    }
  }
  handleChangeStatus1(e) {
    var timeline = document.getElementById("timeline1");
    if (e.target.value === "Rejected") {
      timeline.style.display = "block";
    } else {
      timeline.style.display = "none";
    }
  }

  handleChangeStatus(i, e) {
    var timeline = document.getElementById("timeline");
    if (e.target.value === "Rejected") {
      timeline.style.display = "block";
    } else {
      timeline.style.display = "none";
    }
  }

  /**
   * @author Sonali Kalke
   * @param  Description download CS files in PDF.PPT,word format
   * @return Description return pdf,ppt,word file
   */
  handleCSFileDownload(e) {
    e.preventDefault();//kiran-4747-removing console warning-added e.preventDefault();
    var campID = e.target.id;
    var supportDocID = e.target.getAttribute("supportDocID");
    var pID = e.target.getAttribute("pID");
    var csFileName = e.target.getAttribute("csFileName");
    var ext = csFileName.split(".").pop();
    this.setState({ ext: ext, filename: csFileName });
    fetch(
      "/dashboardAdvertiser/csFileDownload?campID=" +
        campID +
        "&pID=" +
        pID +
        "&supportDocID=" +
        supportDocID
    )
      .then((r) => r.blob())
      .then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
      .then(this.downloadPOCFile);
  }
  
  apiCall(campID, pID) {
    let self = this;
    const { user } = this.props.auth;
    const agencyID = user.id; 
    //karan-task-3715-replace query params
    let parentCampID, reallocationID, backKey;
    if (this.props.location.state !== undefined) {
      parentCampID = this.props.location.state.parentCampID;
      reallocationID = this.props.location.state.reallocationID;
      backKey = this.props.location.state.backKey;
    }
    var submitButtonAction;
    let apiData = { campID, pID }; 

    this.setState({
      parentCampID,
      reallocationID,
      agencyID,
      campID,
      backKey,
      pID,
    }); //kiran-4174-added api call
    //Get list of campign Id
    fetch("dashboardAdvertiser/lpPOCCSReviewNotifyDashAdv")
      .then((res) => res.json())
      .then((creativeCampList) =>
        this.setState({ creativeCampList, listCampID: creativeCampList })
      )
      .catch((e) => console.log(e));

    //Get Campaign Info
    fetch("dashboardAdvertiser/lpReviewPendingForCampIDAdv?campID=" + campID)
      .then((res) => res.json())
      .then((lpReviewPendingListCampIDOnNextPage) => {
        let marketingChannel =
          lpReviewPendingListCampIDOnNextPage[0].marketingChannel;
        let approvalFor =
          lpReviewPendingListCampIDOnNextPage[0].creativeApprovalRequiredFor;
        let campaignName = lpReviewPendingListCampIDOnNextPage[0].campaignName;
        self.setState({
          lpReviewPendingListCampIDOnNextPage,
          marketingChannel,
          approvalFor,
          campaignName,
        });
      })
      .catch((e) => console.log(e));

    //getting Agency
    fetch("/dashboardAdvertiser/AgencyIdForCreativeReview", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(apiData),
    })
      .then((res) => res.json())
      .then((agencyListForLPReview) => self.setState({ agencyListForLPReview }))
      .catch((e) => console.log(e));

    //Get Creatives Data
    fetch("/dashboardAdvertiser/getCreativeInfoAdv", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(apiData),
    })
      .then((res) => res.json())
      .then((lpReviewPendingList) => {
        if (lpReviewPendingList.length === 0) {
          let message = "Creatives Not Available";
          self.setState({
            creativesMessage: message,
            submitButton: "disabled",
          });
        } else {
          let message = " ";
          self.setState({ creativesMessage: message });
        }

        self.setState(
          { lpReviewPendingList: lpReviewPendingList, tableStatus: "true" },
          () => {
            if (lpReviewPendingList.length > 0) {
              for (var i = 0; i < lpReviewPendingList.length; i++) {
                //kiran-4747-removing console warning-changed == to ===
                if (
                  lpReviewPendingList[i].lpStatus === "AgencyReviewPending" ||
                  lpReviewPendingList[i].lpStatus === "Rejected" ||
                  lpReviewPendingList[i].pocStatus === "AgencyReviewPending" ||
                  lpReviewPendingList[i].pocStatus === "Rejected" ||
                  lpReviewPendingList[i].csStatus === "AgencyReviewPending" ||
                  lpReviewPendingList[i].csStatus === "Rejected"
                ) {
                  submitButtonAction = false;
                }
                //Narendra-Bug-2878 - Adding hasownproperty because in some creatives landing page not present
                if (lpReviewPendingList[i].hasOwnProperty("lpStatus")) {
                  if (
                    lpReviewPendingList[i].lpStatus === "Rejected" ||
                    lpReviewPendingList[i].lpStatus === "ClientRejected"
                  ) {
                    var timeline = document.getElementById(
                      "timeline" +
                        lpReviewPendingList[i].supportDocID +
                        lpReviewPendingList[i].pID +
                        "lpStatus"
                    );
                    if (
                      timeline == null ||
                      timeline === "null" ||
                      timeline === undefined
                    ) {
                    } else {
                      timeline.style.display = "block";
                    }
                  }
                }
                //Narendra-Bug-2878 - Adding hasownproperty because in some creatives poc not present
                if (lpReviewPendingList[i].hasOwnProperty("pocStatus")) {
                  if (
                    lpReviewPendingList[i].pocStatus === "Rejected" ||
                    lpReviewPendingList[i].pocStatus === "ClientRejected"
                  ) {
                    var pocTimeline = document.getElementById(
                      "timeline" +
                        lpReviewPendingList[i].supportDocID +
                        lpReviewPendingList[i].pID +
                        "pocStatus"
                    );
                    if (
                      pocTimeline &&
                      pocTimeline.style &&
                      pocTimeline.style.display
                    ) {
                      pocTimeline.style.display = "block";
                    }
                  }
                }
                //Narendra-Bug-2878 - Adding hasownproperty because in some creatives callscript not present
                if (lpReviewPendingList[i].hasOwnProperty("csStatus")) {
                  if (
                    lpReviewPendingList[i].csStatus === "Rejected" ||
                    lpReviewPendingList[i].csStatus === "ClientRejected"
                  ) {
                    var csTimeline = document.getElementById(
                      "timeline" +
                        lpReviewPendingList[i].supportDocID +
                        lpReviewPendingList[i].pID +
                        "csStatus"
                    );
                    if (
                      csTimeline &&
                      csTimeline.style &&
                      csTimeline.style.display
                    ) {
                      csTimeline.style.display = "block";
                    }
                    // csTimeline.style.display = "block";
                  }
                }
              }
              this.setState({ submitButtonAction: submitButtonAction });
            }
          }
        );
      })
      .catch((e) => console.log(e));
  }
  

  /**
   * @author Somnath Keswad
   * @param  Description download POC files in PDF.PPT,word format
   * @return Description return pdf,ppt,word file
   */
  handleFileDownload(e) {
    e.preventDefault(); //kiran-4747-removing console warning-added e.preventDefault();
    var campID = e.target.id;
    var supportDocID = e.target.getAttribute("supportDocID");
    var pID = e.target.getAttribute("pID");
    var pocFileName = e.target.getAttribute("pocFileName");
    var ext = pocFileName.split(".").pop();
    this.setState({ ext: ext, filename: pocFileName });
    fetch(
      "/dashboardAdvertiser/pocFileDownload?campID=" +
        campID +
        "&pID=" +
        pID +
        "&supportDocID=" +
        supportDocID
    )
      .then((r) => r.blob())
      .then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
      .then(this.downloadPOCFile);
  } /*** End of handleFileDownload */

  /**
   * @author Somnath Keswad
   * @param  Description download POC files in PDF.PPT,word format
   * @return Description return pdf,ppt,word file
   */
  downloadPOCFile(blob) {
    /***** If POC File is PDF */
    if (this.state.ext === "pdf") { //kiran-4747-removing console warning-changed == to ===
      let url = this.state.filename;
      let fileName = url.substring(url.lastIndexOf("\\") + 1);
      let pdfFileDownloadName = fileName;
      let newBlob = new Blob([this.state.downloadFiles], {
        type: "application/pdf",
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement("a");
      link.href = data;
      link.download = pdfFileDownloadName;
      link.click();
    }
    //kiran-4747-removing console warning-changed == to ===
    if (this.state.ext === "ppt" || this.state.ext === "pptx") {
      let url = this.state.filename;
      let fileName = url.substring(url.lastIndexOf("\\") + 1);
      let pptFileDownloadName = fileName;
      let newBlob = new Blob([this.state.downloadFiles], {
        type: "application/vnd.ms-powerpoint",
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement("a");
      link.href = data;
      link.download = pptFileDownloadName;
      link.click();
    }

    if (this.state.ext === "txt") {
      let url = this.state.filename;
      let fileName = url.substring(url.lastIndexOf("\\") + 1);
      let textFileDownloadName = fileName;
      let newBlob = new Blob([this.state.downloadFiles], {
        type: "text/plain",
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement("a");
      link.href = data;
      link.download = textFileDownloadName;
      link.click();
    }

    if (this.state.ext === "docx") {
      let url = this.state.filename;
      let fileName = url.substring(url.lastIndexOf("\\") + 1);
      let docFileDownloadName = fileName;
      let newBlob = new Blob([this.state.downloadFiles], {
        type: "application/msword",
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement("a");
      link.href = data;
      link.download = docFileDownloadName;
      link.click();
    }
//kiran-4747-removing console warning-changed == to ===
    if (
      this.state.ext === "png" ||
      this.state.ext === "jpeg" ||
      this.state.ext === "jpg" ||
      this.state.ext === "PNG" ||
      this.state.ext === "JPEG" ||
      this.state.ext === "JPG"
    ) {
      let url = this.state.filename;
      let fileName = url.substring(url.lastIndexOf("\\") + 1);
      let docFileDownloadName = fileName;
      let newBlob = new Blob([this.state.downloadFiles], {
        type: "application/Image",
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement("a");
      link.href = data;
      link.download = docFileDownloadName;
      link.click();
    }
  } /**** End of downloadPOCFie */

  // kiran-4174-handlechanged for search
  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [],
      campaignDetails = [];
    let searchData = e.target.value;
    // If the search bar isn't empty
    if (searchData === "") {
      campaignDetails = [...this.state.listCampID];
    } else {
      // Assign the original list to currentList
      currentList = [...this.state.listCampID];
      campaignDetails = currentList.filter((item) => {
        let obj = {}; //Somnath Task-3972, Add specific Keys in search criteria
        obj[`campID`] = item.campID;
        // obj[`parentCampID`]=item.parentCampID;
        obj[`clientCampID`] = item.clientCampID;
        obj[`campaignName`] = item.campaignName;
        const filter = searchData.toLowerCase();
        var data = Object.values(obj);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });
    }
    this.setState({ creativeCampList: [...campaignDetails], searchData });
  } //End of handleChangeSearch

  /**
   * @author Somnath Keswad
   * @param  Description handle the dynamic value of landing page or POC file
   * @return Description return dynamic array
   */
  handleChangeLpFeedback(i, e) {
    if (
      e.target.value === "AdvertiserApproved" ||
      e.target.value === "AdvertiserRejected"
    ) {
      this.setState((prevstate) => ({ count: prevstate.count + 1 }));
    }
    if (e.target.value === "Select") {
      this.setState((prevstate) => ({ count: prevstate.count - 1 }));
    }

    const { name, value } = e.target;

    var pocflag = "pocflag";
    var lpflag = "lpflag";
    var csflag = "csflag";
    var value2 = "true";
    let lpReviewPendingList = [...this.state.lpReviewPendingList];
    lpReviewPendingList[i] = { ...lpReviewPendingList[i], [name]: value };
    lpReviewPendingList[i] = { ...lpReviewPendingList[i], [pocflag]: value2 };
    lpReviewPendingList[i] = { ...lpReviewPendingList[i], [lpflag]: value2 };
    lpReviewPendingList[i] = { ...lpReviewPendingList[i], [csflag]: value2 };
    this.setState({ lpReviewPendingList, btnDisable: false });
    var supportDocID = e.target.getAttribute("supportDocID");
    var pID = e.target.getAttribute("pID");
    var id = e.target.getAttribute("id");
    var timeline = document.getElementById(
      "timeline" + supportDocID + pID + id
    );
    if (
      e.target.name === "lpStatus" ||
      e.target.name === "pocStatus" ||
      e.target.name === "csStatus"
    ) {
      if (e.target.value === "AdvertiserRejected") {
        timeline.style.display = "block";
      } else {
        timeline.style.display = "none";
      }
    }
  }

  handleChange(e) {
    // var a="";
    this.setState({
      alertDisplay: "none",
      btnDisable: true,       //4531-Chaitanya task-Advertiser side-creatives review page-Submit button issue
      tableStatus: "false",
    });
    var agencyID = e.target.value;
    //snehal-task-3724-vAPT URL changes
    if (this.props.location.state !== undefined) {
      var campID = this.props.location.state.campID;
    }
    let data = {
      campID: campID,
      agencyID: agencyID,
    };

    if (agencyID === "All") {
      fetch("dashboardAdvertiser/creativeDetails?campID=" + campID)
        .then((res) => res.json())
        .then((lpReviewPendingList) => {
          //kiran-4747-removing console warning-changed == to ===
          if (lpReviewPendingList.length === 0) {
            var message = "Creatives Not Available";
            this.setState({
              creativesMessage: message,
              submitButton: "disabled",
            });
          } else {
            let message = " ";//kiran-4747-removing console warning-changed var to let
            this.setState({ creativesMessage: message });
          }
          this.setState({
            lpReviewPendingList: lpReviewPendingList,
            tableStatus: "true",
          });
          if (lpReviewPendingList.length > 0) {
            for (var i = 0; i < lpReviewPendingList.length; i++) {
              if (lpReviewPendingList[i].lpStatus === "AdvertiserRejected") {
                var timeline = document.getElementById(
                  "timeline" +
                    lpReviewPendingList[i].supportDocID +
                    lpReviewPendingList[i].pID +
                    "lpStatus"
                );
                timeline.style.display = "block";
              }
              if (lpReviewPendingList[i].pocStatus === "AdvertiserRejected") {
                var pocTimeline = document.getElementById(
                  "timeline" +
                    lpReviewPendingList[i].supportDocID +
                    lpReviewPendingList[i].pID +
                    "pocStatus"
                );
                pocTimeline.style.display = "block";
              }
              if (lpReviewPendingList[i].csStatus === "AdvertiserRejected") {
                var csTimeline = document.getElementById(
                  "timeline" +
                    lpReviewPendingList[i].supportDocID +
                    lpReviewPendingList[i].pID +
                    "csStatus"
                );
                csTimeline.style.display = "block";
              }
            }
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      this.setState({ publisherName: agencyID, pID: agencyID });
    } else {
      fetch("/dashboardAdvertiser/agencySpecificCreative", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((lpReviewPendingList) => {
          if (lpReviewPendingList.length === 0) {
            var message = "Creatives Not Available";
            this.setState({
              creativesMessage: message,
              submitButton: "disabled",
            });
          } else {
            let message = " ";//kiran-4747-removing console warning-changed var to let
            this.setState({ creativesMessage: message });
          }
          this.setState({
            lpReviewPendingList: lpReviewPendingList,
            tableStatus: "true",
          });
          if (lpReviewPendingList.length > 0) {
            for (var i = 0; i < lpReviewPendingList.length; i++) {
              if (lpReviewPendingList[i].lpStatus === "AdvertiserRejected") {
                var timeline = document.getElementById(
                  "timeline" +
                    lpReviewPendingList[i].supportDocID +
                    lpReviewPendingList[i].pID +
                    "lpStatus"
                );
                timeline.style.display = "block";
              }
              if (lpReviewPendingList[i].pocStatus === "AdvertiserRejected") {
                var pocTimeline = document.getElementById(
                  "timeline" +
                    lpReviewPendingList[i].supportDocID +
                    lpReviewPendingList[i].pID +
                    "pocStatus"
                );
                pocTimeline.style.display = "block";
              }
              if (lpReviewPendingList[i].csStatus === "AdvertiserRejected") {
                var csTimeline = document.getElementById(
                  "timeline" +
                    lpReviewPendingList[i].supportDocID +
                    lpReviewPendingList[i].pID +
                    "csStatus"
                );
                csTimeline.style.display = "block";
              }
            }
          }
        })
        .catch(function (err) {
          console.log(err);
        });

      fetch("/publisher/getPublisherDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((agencyList) => {
          this.setState({
            publisherName: agencyList[0].publisherName,
            pID: agencyList[0].pID,
            tempArray: agencyList,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    } /** End of Else */
  }

  componentWillMount() {
    //snehal-task-3724-vAPT URL changes
    // var advertiserID;
    let pID;
    if (this.props.location.state !== undefined) {
      var campID = this.props.location.state.campID;
    }
    this.setState({ campID });
    // advertiserID = advertiserID || "All";
    pID = pID || "All"; 
    // this.apiCall(campID, advertiserID);
    this.apiCall(campID, pID); 

    fetch("/publisher/agencyLPAndPOCApproved?campID=" + campID)
      .then((res) => res.json())
      .then((response) => {})
      .catch(function (err) {
        console.log(err);
      });
  }
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    }
    let self = this;
    const { user } = this.props.auth;
    //snehal-task-3724-vAPT URL changes
    if (this.props.location.state !== undefined) {
      var campID = this.props.location.state.campID;
    }
    this.setState({ campID: campID, agencyID: user.id });
    fetch("dashboardAdvertiser/getCampDetails?campID=" + campID)
      .then((res) => res.json())
      .then((lpReviewPendingListCampIDOnNextPage) => {
        var marketingChannel =
          lpReviewPendingListCampIDOnNextPage[0].marketingChannel;
        let approvalFor =
          lpReviewPendingListCampIDOnNextPage[0].creativeApprovalRequiredFor;
        self.setState({
          lpReviewPendingListCampIDOnNextPage:
            lpReviewPendingListCampIDOnNextPage,
          marketingChannel: marketingChannel,
          parentCampID: lpReviewPendingListCampIDOnNextPage[0].parentCampID,
          reallocationID: lpReviewPendingListCampIDOnNextPage[0].reallocationID,
          campID: lpReviewPendingListCampIDOnNextPage[0].campID,
          approvalFor,
        });
      })
      .catch(function (err) {
        console.log(err);
      });

    /** Show Agency List in dropdown */
    fetch("dashboardAdvertiser/agencyListForReview?campID=" + campID)
      .then((res) => res.json())
      .then((agencyList) => {
        self.setState({ agencyList: agencyList });
      })
      .catch(function (err) {
        console.log(err);
      });

    fetch("dashboardAdvertiser/creativeDetails?campID=" + campID)
      .then((res) => res.json())
      .then((lpReviewPendingList) => {
        if (lpReviewPendingList.length === 0) {
          var message = "Creatives Not Available";
          self.setState({
            creativesMessage: message,
            submitButton: "disabled",
          });
        } else {
          let message = " ";
          self.setState({ creativesMessage: message });
        }
        self.setState({
          lpReviewPendingList: lpReviewPendingList,
          tableStatus: "true",
        });

        if (lpReviewPendingList.length > 0) {
          for (var i = 0; i < lpReviewPendingList.length; i++) {
            if (lpReviewPendingList[i].lpStatus === "AdvertiserRejected") {
              var timeline = document.getElementById(
                "timeline" +
                  lpReviewPendingList[i].supportDocID +
                  lpReviewPendingList[i].pID +
                  "lpStatus"
              );
              timeline.style.display = "block";
            }
            if (lpReviewPendingList[i].pocStatus === "AdvertiserRejected") {
              var pocTimeline = document.getElementById(
                "timeline" +
                  lpReviewPendingList[i].supportDocID +
                  lpReviewPendingList[i].pID +
                  "pocStatus"
              );
              pocTimeline.style.display = "block";
            }
            if (lpReviewPendingList[i].csStatus === "AdvertiserRejected") {
              var csTimeline = document.getElementById(
                "timeline" +
                  lpReviewPendingList[i].supportDocID +
                  lpReviewPendingList[i].pID +
                  "csStatus"
              );
              csTimeline.style.display = "block";
            }
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  validateForm(lpReviewPendingList) {
    let errors = {};
    let spanDisplay = "none";

    if (lpReviewPendingList === undefined || lpReviewPendingList === null) {
      formIsValid = false;
      errors["noDataFeedback"] = "No Data Exists";
      this.setState({
        errors: errors,
        reerrors: "",

        alertWidth: "1000px",
        alertAlign: "center",
        alertDisplay: "block",
        spanSpaceDisplay: spanDisplay,
      });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      for (var i = 0; i < lpReviewPendingList.length; i++) {
        if (lpReviewPendingList[i].hasOwnProperty("csStatus")) {
          if (lpReviewPendingList[i].csStatus === "AdvertiserRejected") {
            if (
              (lpReviewPendingList[i].csFeedback !== "" &&
                lpReviewPendingList[i].csFeedback !== null) ||
              (lpReviewPendingList[i].csFeedbackFileName !== "" &&
                lpReviewPendingList[i].csFeedbackFileName !== null)
            ) {
              lpReviewPendingList[i].csflag = "true";
            } else {
              lpReviewPendingList[i].csflag = "false";
            }
          } else {
            if (lpReviewPendingList[i].csStatus === "AdvertiserApproved") {
              lpReviewPendingList[i].csflag = "true";
            }
          }
        }

        if (lpReviewPendingList[i].hasOwnProperty("lpStatus")) {
          if (lpReviewPendingList[i].lpStatus === "AdvertiserRejected") {
            if (
              (lpReviewPendingList[i].lpFeedback !== "" &&
                lpReviewPendingList[i].lpFeedback !== null) ||
              (lpReviewPendingList[i].lpFeedbackFileName !== "" &&
                lpReviewPendingList[i].lpFeedbackFileName !== null)
            ) {
              lpReviewPendingList[i].lpflag = "true";
            } else {
              lpReviewPendingList[i].lpflag = "false";
            }
          } else {
            if (lpReviewPendingList[i].lpStatus === "AdvertiserApproved") {
              lpReviewPendingList[i].lpflag = "true";
            }
          }
        }

        if (lpReviewPendingList[i].hasOwnProperty("pocStatus")) {
          if (lpReviewPendingList[i].pocStatus === "AdvertiserRejected") {
            if (
              (lpReviewPendingList[i].pocFeedback !== "" &&
                lpReviewPendingList[i].pocFeedback !== null) ||
              (lpReviewPendingList[i].pocFeedbackFileName !== "" &&
                lpReviewPendingList[i].pocFeedbackFileName !== null)
            ) {
              lpReviewPendingList[i].pocflag = "true";
            } else {
              lpReviewPendingList[i].pocflag = "false";
            }
          } else {
            if (lpReviewPendingList[i].pocStatus === "AdvertiserApproved") {
              lpReviewPendingList[i].pocflag = "true";
            }
          }
        }
      }
    }

    if (this.state.count === 0) {
      formIsValid = false;
      errors["errorfeedback"] =
        "Please Select Minimum One Status For Any Agency";
      this.setState({
        errors: errors,
        reerrors: "",
        submitButton: "disabled",
        alertWidth: "1000px",
        alertAlign: "center",
        alertDisplay: "block",
        spanSpaceDisplay: spanDisplay,
      });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      //kiran-4747-removing console warning-changed var to let
      for (let i = 0; i < lpReviewPendingList.length; i++) {
        if (
          lpReviewPendingList[i].pocflag === "false" ||
          lpReviewPendingList[i].lpflag === "false" ||
          lpReviewPendingList[i].csflag === "false"
        ) {
          formIsValid = false;
          errors["rejectedfeedback"] =
            "Please Fill Feedback Or Upload a Feedback File";
          this.setState({
            errors: errors,
            reerrors: "",

            alertWidth: "1000px",
            alertAlign: "center",
            alertDisplay: "block",
            spanSpaceDisplay: spanDisplay,
          });

          break;
        } else {
          formIsValid = true;
        }
      }
    }

    return formIsValid;
  } // end of validateForm

  submitLpReviewFeedback(e) {
    // let errors = {};
    // let spanDisplay = "none";

    e.preventDefault();
    if (this.validateForm(this.state.lpReviewPendingList) === false) {
      return;
    } else {
      // var parsed = queryString.parse(this.props.location.search);
      // var parentCampID = parsed.parentCampID;
      // var reallocationID = parsed.reallocationID;
      // var pID = document.getElementById("pID").value;//kiran-4747-removing console warning-no used
      var campID = document.getElementById("campID").value;
      // const { user } = this.props.auth;
      let data = {
        campID: campID,
        parentCampID: this.state.parentCampID,
        reallocationID: this.state.reallocationID,
        status: this.state.status,
        clientCampID:
          this.state.lpReviewPendingListCampIDOnNextPage[0].clientCampID,
        dynamicArray: this.state.lpReviewPendingList,
      };
      fetch("/dashboardAdvertiser/submitCreative", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success === true) {
            this.setState({ btnDisable: true });
            // var a = res.message;
            this.setState({ alertDisplay: "none" });
            Swal.fire({
              text: res.message,
              type: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
          }
        })
        .then(function (response) {})
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  // feedback Submitting
  liveTab(e) {
    var tab = e.target.name;
    if (tab === "APC") {
      window.location.href = "/allocatingCampaign#8";
    } else {
      window.location.href = "/allocatingCampaign#5";
    }
  }
  /**
   * @author Somnath Keswad
   * @param  Description upload Feedback file of landingpage link
   * @return Description return message successfully Delete file
   */
  submitLPFeedbackFile(i, e) {
    // let lperror = {};//kiran-4747-removing console warning-no used
    e.preventDefault();
    //snehal-task-3724-vAPT URL changes
    if (this.props.location.state !== undefined) {
      var campID = this.props.location.state.campID;
    }
    var supportDocID = e.target.id;
    var pID = e.target.getAttribute("pID");

    if (
      document.getElementById("fileName" + supportDocID + pID) != null ||
      document.getElementById("fileName" + supportDocID + pID) != undefined  
    ) {
      document.getElementById("fileName" + supportDocID + pID).innerHTML =
        ""; /**** this is making empty for hide the message No File Chosen because of in dynamic delete function it assign value No File Chosen */
    }

    var names = [];
    let filesData = e.target.files;
    if (filesData.length > 0) {
      var fileLP = filesData[0].name;
//kiran-4747-added toString(),Number and changed == to ===
      for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
        if (
          this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
         Number(pID) === this.state.lpReviewPendingList[i].pID
        ) {
          this.state.lpReviewPendingList[i].lpFeedbackFileName = fileLP; 
          
        }
      }

      this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });

      var FileExt;
      let file = e.target.files[0];

      for (let i = 0, l = filesData.length; i < l; i++) {
        names[i] = filesData[i].name;
        FileExt = names[i].split(".").pop();
        if (
          document.getElementById("lpFileError" + supportDocID + pID) !== null
        ) {
          document.getElementById(
            "lpFileError" + supportDocID + pID
          ).innerHTML = "";
        }
      }

      // var link;
      var filename = "";
      var arrayLength = names.length;
      var nooutput = document.getElementById(
        "lpFeedbackList" + supportDocID + pID
      );
      if (arrayLength > 0) {
        var allowedFiles = [
          ".ppt",
          ".doc",
          ".pdf",
          ".png",
          ".jpeg",
          ".pptx",
          ".docx",
          ".jpg",
        ];
        //kiran-4747-removing console warning-changed == to ===
        if (
          FileExt === "doc" ||
          FileExt === "DOC" ||
          FileExt === "ppt" ||
          FileExt === "PPT" ||
          FileExt === "pdf" ||
          FileExt === "PDF" ||
          FileExt === "png" ||
          FileExt === "jpeg" ||
          FileExt === "pptx" ||
          FileExt === "PPTX" ||
          FileExt === "docx" ||
          FileExt === "DOCX" ||
          FileExt === "jpg" ||
          FileExt === "JPG" ||
          FileExt === "JPEG" ||
          FileExt === "PNG"
        ) {
          nooutput.innerHTML = arrayLength + "  File Selected";
          var data = new FormData();
          data.append("campID", campID);
          data.append("file", file);
          data.append("pID", pID);
          data.append("supportDocID", supportDocID);
          fetch("dashboardAdvertiser/uploadLPFeedbackFile", {
            method: "POST",
            body: data,
          })
            .then(function (response) {
              if (response.status >= 400) {
                throw new Error("Bad response from server");
              }
              return response.json();
            })
            .then(function (response) {
              console.log("Return with response");
              console.log(response);
              var msg = "Landing Page Feedback File Uploaded Successfully";
              var message = document.getElementById(
                "lpFileMessage" + supportDocID + pID
              );
              if (arrayLength > 0) {
                message.innerHTML = msg;
              } else {
                message.innerHTML = "";
              }

              document.getElementById(
                "lpFileError" + supportDocID + pID
              ).innerHTML = "";
            })
            .catch(function (err) {
              console.log(err);
            });
          this.setState({
            files: data,
          });
        } else {
          var msg3 =
            "Please upload files having extensions: " +
            allowedFiles.join(", ") +
            " only";
          var lpmessageError = document.getElementById(
            "lpFileError" + supportDocID + pID
          );
          lpmessageError.innerHTML += msg3;
          //kiran-4747-added toString(),Number and changed == to ===
          for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
            if (
              this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
             Number(pID)  === this.state.lpReviewPendingList[i].pID
            ) {
              this.state.lpReviewPendingList[i].lpFeedbackFileName = "";
            }
          }
        }
      } else {
        nooutput.innerHTML = " No File Chosen";
      }

      $(document).on("click", ".removeFile", function (e) {
        e.preventDefault();

        var clickedFile = $(this).parent().children("a").data("fileid");

        var j = 0;
        for (j; j < names.length; j++) {
          var temp = j;

          if (names[temp] === clickedFile) {
            filename = names[temp];
            names.splice(j, 1);
            $(this).parent().remove();
            arrayLength = names.length;

            let data = {
              campID: campID,
              supportDocID: supportDocID,
              pID: pID,
            };

            document.getElementById(supportDocID).value = "";
            fetch("dashboardAdvertiser/LPFeedbackFileDelete", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }).catch(function (err) {
              console.log(err);
            });

            if (arrayLength > 0) {
              nooutput.innerHTML = arrayLength + "  File Selected";
            } else {
              nooutput.innerHTML = " No File Chosen";
              $("#abm1").val("");
              var message = document.getElementById(
                "lpFileMessage" + supportDocID + pID
              );
              message.innerHTML = "";
            }
          }
        } // end of for
      }); //$(document).on
    }
  } // End of Submit LP_FeedbackFile
  /**
   * @author Somnath Keswad
   * @param  Description Delete LP Feedback from DB
   * @return Description return message successfully Delete file
   */
  deleteLPFeedback(e) {
    e.preventDefault();
    //snehal-task-3724-vAPT URL changes
    if (this.props.location.state !== undefined) {
      var campID = this.props.location.state.campID;
    }
    var supportDocID = e.target.id;
    var pID = e.target.getAttribute("pID");
    document.getElementById("fileName" + supportDocID + pID).innerHTML = "";
    let data = {
      campID: campID,
      supportDocID: supportDocID,
      pID: pID,
    };
    for (var i = 0; i < this.state.lpReviewPendingList.length; i++) {
      //kiran-4747-added toString(),Number and changed == to ===
      if (
        Number(supportDocID) === this.state.lpReviewPendingList[i].supportDocID &&
        Number(pID) === this.state.lpReviewPendingList[i].pID
      ) {
        this.state.lpReviewPendingList[i].lpFeedbackFileName = ""; 
      }
    }
    this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });
    fetch("dashboardAdvertiser/LPFeedbackFileDelete", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).catch(function (err) {
      console.log(err);
    });
    this.forceUpdate();
    var nooutput = document.getElementById(
      "lpFileMessage" + supportDocID + pID
    );
    nooutput.innerHTML = "";
    var nooutput1 = document.getElementById("fileName" + supportDocID + pID);
    nooutput1.innerHTML = "No File Chosen";
  } /****** End OF Delete LandingPAGE FeedBack File */

  /**
   * @author Somnath Keswad
   * @param  Description upload Newsletter Feedback file
   * @return Description return message successfully Delete file
   */
  submitPOCFeedbackFile(i, e) {
    e.preventDefault();
    // let pocerror = {};
    //snehal-task-3724-vAPT URL changes
    if (this.props.location.state !== undefined) {
      var campID = this.props.location.state.campID;
    }
    var supportDocID = e.target.id;

    var pID = e.target.getAttribute("pID");

    if (
      document.getElementById("POCFeedbackFileName" + supportDocID + pID) !=
        null ||
      document.getElementById("POCFeedbackFileName" + supportDocID + pID) !=
        undefined
    ) {
      document.getElementById(
        "POCFeedbackFileName" + supportDocID + pID
      ).innerHTML =
        ""; /**** this is making empty for hide the message No File Chosen because of in dynamic delete function it assign value No File Chosen */
    }

    var names = [];
    var FileExt;
    let filesData = e.target.files;
    if (filesData.length > 0) {
      var filePoc = filesData[0].name;
      for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
        //kiran-4747-added toString(),Number and changed == to ===
        if (
          this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
          Number(pID) === this.state.lpReviewPendingList[i].pID
        ) {
          this.state.lpReviewPendingList[i].pocFeedbackFileName = filePoc;
        }
      }
      this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });

      let file = e.target.files[0];
      console.warn("data files", filesData);
      //kiran-4747-removing console warning-changed var to let
      for (let i = 0, l = filesData.length; i < l; i++) {
        names[i] = filesData[i].name;
        if (
          document.getElementById("pocFileError" + supportDocID + pID) !== null
        ) {
          document.getElementById(
            "pocFileError" + supportDocID + pID
          ).innerHTML = "";
        }
        FileExt = names[i].split(".").pop();
      }

      // var link;
      var filename = "";
      var arrayLength = names.length;
      var nooutput = document.getElementById(
        "POCFeedbackList" + supportDocID + pID
      );

      if (arrayLength > 0) {
        var allowedFiles = [
          ".ppt",
          ".doc",
          ".pdf",
          ".png",
          ".jpeg",
          ".pptx",
          ".docx",
          ".jpg",
        ];
        //kiran-4747-removing console warning-changed == to ===
        if (
          FileExt === "doc" ||
          FileExt === "DOC" ||
          FileExt === "ppt" ||
          FileExt === "PPT" ||
          FileExt === "pdf" ||
          FileExt === "PDF" ||
          FileExt === "png" ||
          FileExt === "jpeg" ||
          FileExt === "pptx" ||
          FileExt === "PPTX" ||
          FileExt === "docx" ||
          FileExt === "DOCX" ||
          FileExt === "jpg" ||
          FileExt === "JPG" ||
          FileExt === "JPEG" ||
          FileExt === "PNG"
        ) {
          nooutput.innerHTML = arrayLength + "  File Selected";
          var data = new FormData();
          data.append("campID", campID);
          data.append("file", file);
          data.append("pID", pID);
          data.append("supportDocID", supportDocID);

          fetch("dashboardAdvertiser/uploadPOCFeedbackFile", {
            method: "POST",
            body: data,
          })
            .then(function (response) {
              if (response.status >= 400) {
                throw new Error("Bad response from server");
              }
              return response.json();
            })
            .then(function (response) {
              console.log("Return with response");
              console.log(response);
              var msg = "Newsletter Feedback File Uploaded Successfully";
              var message = document.getElementById(
                "pocFileMessage" + supportDocID + pID
              );

              if (arrayLength > 0) {
                message.innerHTML = msg;
              } else {
                message.innerHTML = "";
              }

              document.getElementById(
                "pocFileError" + supportDocID + pID
              ).innerHTML = "";
            })
            .catch(function (err) {
              console.log(err);
            });
          this.setState({
            files: data,
            pocerror: "",
          });
        } else {
          var msg1 =
            "Please upload files having extensions: " +
            allowedFiles.join(", ") +
            " only";
          var messageError = document.getElementById(
            "pocFileError" + supportDocID + pID
          );
          messageError.innerHTML += msg1;
          //kiran-4747-added toString(),Number and changed == to ===
          for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
            if (
              this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
              Number(pID) === this.state.lpReviewPendingList[i].pID
            ) {
              this.state.lpReviewPendingList[i].pocFeedbackFileName = "";
            }
          }
        }
      } else {
        nooutput.innerHTML = " No File Chosen";
      }

      $(document).on("click", ".removeFile", function (e) {
        e.preventDefault();
        var clickedFile = $(this).parent().children("a").data("fileid");
        var j = 0;
        for (j; j < names.length; j++) {
          var temp = j;

          if (names[temp] === clickedFile) {
            filename = names[temp];
            names.splice(j, 1);
            $(this).parent().remove();
            arrayLength = names.length;

            let data = {
              campID: campID,
              supportDocID: supportDocID,
              pID: pID,
            };

            document.getElementById(supportDocID).value = "";
            fetch("dashboardAdvertiser/POCFeedbackFileDelete", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }).catch(function (err) {
              console.log(err);
            });

            if (arrayLength > 0) {
              nooutput.innerHTML = arrayLength + "  File Selected";
            } else {
              nooutput.innerHTML = " No File Chosen";
              $("#abm1").val("");
              var message = document.getElementById(
                "pocFileMessage" + supportDocID + pID
              );
              message.innerHTML = "";
            }
          } // end of  if(names[temp] === clickedFile)
        } // end of for
      }); //$(document).on
    }
  } // End of Submit POC_FeedbackFile

  /**
   * @author Somnath Keswad
   * @param  Description upload CallScript File file
   * @return Description return message successfully Delete file
   */
  submitCSFeedbackFile(i, e) {
    // let cserror = {};
    e.preventDefault();
    //snehal-task-3724-vAPT URL changes
    if (this.props.location.state !== undefined) {
      var campID = this.props.location.state.campID;
    }
    var supportDocID = e.target.id;
    var pID = e.target.getAttribute("pID");
    if (
      document.getElementById("CSFeedbackFileName" + supportDocID + pID) !=
        null ||
      document.getElementById("CSFeedbackFileName" + supportDocID + pID) !=
        undefined
    ) {
      document.getElementById(
        "CSFeedbackFileName" + supportDocID + pID
      ).innerHTML =
        ""; /**** this is making empty for hide the message No File Chosen because of in dynamic delete function it assign value No File Chosen */
    }

    var names = [];
    let filesData = e.target.files;
    if (filesData.length > 0) {
      var fileCs = filesData[0].name;
      //kiran-4747-added toString(),Number and changed == to ===
      for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
        if (
          this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
          Number(pID) === this.state.lpReviewPendingList[i].pID
        ) {
          this.state.lpReviewPendingList[i].csFeedbackFileName = fileCs;
        }
      }
      this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });

      let file = e.target.files[0];
      var FileExt;
      console.warn("data files", filesData);
      for (let i = 0, l = filesData.length; i < l; i++) {
        names[i] = filesData[i].name;
        if (
          document.getElementById("csFileError" + supportDocID + pID) !== null
        ) {
          document.getElementById(
            "csFileError" + supportDocID + pID
          ).innerHTML = "";
        }
        FileExt = names[i].split(".").pop();
      }

      // var link;
      var filename = "";
      var arrayLength = names.length;
      var nooutput = document.getElementById(
        "CSFeedbackList" + supportDocID + pID
      );

      if (arrayLength > 0) {
        var allowedFiles = [
          ".ppt",
          ".doc",
          ".pdf",
          ".png",
          ".jpeg",
          ".pptx",
          ".docx",
          ".jpg",
        ];
        //kiran-4747-removing console warning-changed == to ===
        if (
          FileExt === "doc" ||
          FileExt === "DOC" ||
          FileExt === "ppt" ||
          FileExt === "PPT" ||
          FileExt === "pdf" ||
          FileExt === "PDF" ||
          FileExt === "png" ||
          FileExt === "jpeg" ||
          FileExt === "pptx" ||
          FileExt === "PPTX" ||
          FileExt === "docx" ||
          FileExt === "DOCX" ||
          FileExt === "jpg" ||
          FileExt === "JPG" ||
          FileExt === "JPEG" ||
          FileExt === "PNG"
        ) {
          nooutput.innerHTML = arrayLength + "  File Selected";
          var data = new FormData();
          data.append("campID", campID);
          data.append("file", file);
          data.append("pID", pID);
          data.append("supportDocID", supportDocID);

          fetch("dashboardAdvertiser/uploadCSFeedbackFile", {
            method: "POST",
            body: data,
          })
            .then(function (response) {
              if (response.status >= 400) {
                throw new Error("Bad response from server");
              }
              return response.json();
            })
            .then(function (response) {
              console.log("Return with response");
              console.log(response);
              var msg = "Call Script File Feedback File Uploaded Successfully";
              var message = document.getElementById(
                "csFileMessage" + supportDocID + pID
              );
              if (arrayLength > 0) {
                message.innerHTML = msg;
              } else {
                message.innerHTML = "";
              }

              document.getElementById(
                "csFileError" + supportDocID + pID
              ).innerHTML = "";
            })
            .catch(function (err) {
              console.log(err);
            });
          this.setState({
            files: data,
            cserror: "",
          });
        } else {
          var msg2 =
            "Please upload files having extensions: " +
            allowedFiles.join(", ") +
            " only";
          var csmessageError = document.getElementById(
            "csFileError" + supportDocID + pID
          );
          csmessageError.innerHTML += msg2;
          for (let i = 0; i < this.state.lpReviewPendingList.length; i++) {
            //kiran-4747-added toString(),Number and changed == to ===
            if (
              this.state.lpReviewPendingList[i].supportDocID.toString() === supportDocID &&
              Number(pID) === this.state.lpReviewPendingList[i].pID
            ) {
              this.state.lpReviewPendingList[i].csFeedbackFileName = "";
            }
          }
        }
      } else {
        nooutput.innerHTML = " No File Chosen";
      }

      $(document).on("click", ".removeFile", function (e) {
        e.preventDefault();
        var clickedFile = $(this).parent().children("a").data("fileid");
        var j = 0;
        for (j; j < names.length; j++) {
          var temp = j;

          if (names[temp] === clickedFile) {
            filename = names[temp];
            names.splice(j, 1);
            $(this).parent().remove();
            arrayLength = names.length;

            let data = {
              campID: campID,
              supportDocID: supportDocID,
              pID: pID,
            };

            document.getElementById(supportDocID).value = "";
            fetch("dashboardAdvertiser/CSFeedbackFileDelete", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }).catch(function (err) {
              console.log(err);
            });

            if (arrayLength > 0) {
              nooutput.innerHTML = arrayLength + "  File Selected";
            } else {
              nooutput.innerHTML = " No File Chosen";
              $("#abm1").val("");
              var message = document.getElementById(
                "csFileMessage" + supportDocID + pID
              );
              message.innerHTML = "";
            }
          } // end of  if(names[temp] === clickedFile)
        } // end of for
      }); //$(document).on
    }
  } // End of Submit CS_FeedbackFile

  /**
   * @author Somnath Keswad
   * @param  Description Delete POC Feedback from DB
   * @return Description return message successfully Delete file
   */
  deletePOCFeedback(e) {
    e.preventDefault();
    //snehal-task-3724-vAPT URL changes
    if (this.props.location.state !== undefined) {
      var campID = this.props.location.state.campID;
    }
    var supportDocID = e.target.id;
    var pID = e.target.getAttribute("pID");

    document.getElementById(
      "POCFeedbackFileName" + supportDocID + pID
    ).innerHTML = "";
    let data = {
      campID: campID,
      supportDocID: supportDocID,
      pID: pID,
    };
    for (var i = 0; i < this.state.lpReviewPendingList.length; i++) {
      //kiran-4747-added Number and changed == to ===
      if (
        Number(supportDocID) === this.state.lpReviewPendingList[i].supportDocID &&
        Number(pID) === this.state.lpReviewPendingList[i].pID
      ) {
        this.state.lpReviewPendingList[i].pocFeedbackFileName = "";
      }
    }
    this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });

    fetch("dashboardAdvertiser/POCFeedbackFileDelete", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).catch(function (err) {
      console.log(err);
    });
    this.forceUpdate();

    document.getElementById("pocFileMessage" + supportDocID + pID).innerHTML =
      "";
    var nooutput1 = document.getElementById(
      "POCFeedbackFileName" + supportDocID + pID
    );
    nooutput1.innerHTML = "No File Chosen";
  } /****** End OF Delete LandingPAGE FeedBack File */
  /**
   * @author Somnath Keswad
   * @param  Description Delete CallScript Feedback from DB
   * @return Description return message successfully Delete file
   */
  deleteCSFeedback(e) {
    e.preventDefault();
    //snehal-task-3724-vAPT URL changes
    if (this.props.location.state !== undefined) {
      var campID = this.props.location.state.campID;
    }
    var supportDocID = e.target.id;
    var pID = e.target.getAttribute("pID");
    document.getElementById(
      "CSFeedbackFileName" + supportDocID + pID
    ).innerHTML = "";
    let data = {
      campID: campID,
      supportDocID: supportDocID,
      pID: pID,
    };
    for (var i = 0; i < this.state.lpReviewPendingList.length; i++) {
       //kiran-4747-added Number and changed == to ===
      if (
        Number(supportDocID) === this.state.lpReviewPendingList[i].supportDocID &&
        Number(pID) === this.state.lpReviewPendingList[i].pID
      ) {
        this.state.lpReviewPendingList[i].csFeedbackFileName = "";
      }
    }
    this.setState({ lpReviewPendingList: this.state.lpReviewPendingList });

    fetch("dashboardAdvertiser/CSFeedbackFileDelete", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).catch(function (err) {
      console.log(err);
    });
    this.forceUpdate();

    document.getElementById("csFileMessage" + supportDocID + pID).innerHTML =
      "";
    var nooutput1 = document.getElementById(
      "CSFeedbackFileName" + supportDocID + pID
    );
    nooutput1.innerHTML = "No File Chosen";
  } /****** End OF Delete CS FeedBack File */

  /**
   * @author Sandeep Dhawale
   * @param  Description  get  Link of lpLink
   * @return Description return open Link when i click on link
   */
  lpReviewPendingList(e) {
    e.preventDefault();//kiran-4747-removing console warning-added e.preventDefault();
    var lpLink = e.target.getAttribute("lpLink"); //Sandeep-task-2469-added code to get the link
    let assethttps = lpLink.includes("https://"); //Sandeep-task-2469-added code to check https content present or not
    let assethttp = lpLink.includes("http://"); //Sandeep-task-2469-added code to check http content present or not
    if (assethttps === true || assethttp === true) {
      window.open(lpLink); //Sandeep-task-2469-added codeto open link
    } else {
      window.open("http://" + lpLink); //Sandeep-task-2469-added codeto open link with http
    }
  } //end of lpReviewPendingList function

  render() {
    // var Tab = localStorage.getItem("Tab");

    // const { user } = this.props.auth;

    return (
      <div>
        <Navigation />
        <div
          style={{ paddingBottom: "60px", paddingTop: "90px" }}
          class="container-fluid"
        >
          <div
            class="col-lg-12"
            style={{ display: this.state.alertDisplay }}
          ></div>
          {/* shivani-3310-passed ID "labelDI" for label consistency */}

          <div class="col" align="center">
            <label id="labelDI">Advertiser Creatives Review</label>
          </div>

          <div class="col-md-1">
            {/* //snehal-task-3768-vAPT URL changes */}
            {/* kiran-4747-removing console warning-added e.preventDefault(); and #/ */}
            <a
              href="#/"
              onClick={(e) =>
                {
                  e.preventDefault();
                this.props.history.push("/advertiserDashboard", {
                  advertiserID: this.state.advertiserID,
                })
              }
              }
              style={{ color: "#056eb8" }}
            >
              <FaArrowAltCircleLeft
                size={32}
                style={{ float: "left" }}
                title="Back to Dashboard"
              />
            </a>
          </div>
          <div class="col-md-11">
            <select
              style={{ float: "right" }}
              id="publisherName"
              name="agencyName"
              defaultValue={this.state.agencyName}
              onChange={this.handleChange}
              class="form-control"
            >
              <option value="All">All</option>
              {this.state.agencyList.map((agencyList) => (
                <option value={agencyList.agencyID}>
                  {agencyList.agencyName}{" "}
                </option>
              ))}
              ;
            </select>
          </div>
          <br />
          <br />

          <div className="" style={{ display: this.state.alertDisplay }}>
            <div class="container-fluid">
              <div class="card card-signin my-1">
                <div class="card-body">
                  <div class="row">
                    <div
                      style={{
                        fontSize: "15px",
                        color: "red",
                        fontWeight: "600",
                        paddingLeft: "520px",
                      }}
                    >
                      {this.state.errors.errorfeedback}
                      {this.state.errors.rejectedfeedback}
                      {this.state.errors.noDataFeedback}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="col-lg-3"
              style={{ paddingRight: "0px", borderRadius: "0px" }}
            >
              <div
                style={{ border: "1px solid gray", backgroundColor: "#ffff" }}
              >
                <div>
                  <div class="card-body" style={{ borderRadius: "0px" }}>
                    <div>
                      <label
                        id="label"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          width: "235px",
                        }}
                      >
                        Campaign for Creatives Review :
                      </label>
                    </div>

                    <div class="row">
                      <div
                        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                        style={{ paddingLeft: "1px" }}
                      >
                        <input
                          type="text"
                          class="form-control"
                          className="inputstylesearch"
                          name="search"
                          placeholder="Search Campaign"
                          style={{
                            backgroundImage: "url(searchIcon.png)",
                            backgroundPosition: "15px 6px",
                            backgroundSize: "15px 15px",
                            backgroundRepeat: "no-repeat",
                            width: "280px",
                            height: "28px",
                            fontSize: "12px",
                            marginLeft: "10px",
                            border: "1px solid #CCCCCC",
                            padding: "12px 10px 15px 36px",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "sans-serif",
                            borderRadius: "2px",
                          }}
                          onChange={this.handleChangeSearch.bind(this)}
                          onPressEnter={this.handleChangeSearch.bind(this)}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: "351px", overflowY: "scroll" }}>
                    {this.state.creativeCampList.length > 0 ? (
                      <div>
                        {this.state.creativeCampList.map((creativeCampList) => (
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <tbody>
                            {/* kiran-4747-removing console warning-changed == to === */}
                              {this.state.campID === creativeCampList.campID ? (
                                <tr
                                  style={{
                                    borderBottom: "2px solid #ccc",
                                    borderTop: "1px solid #ccc",
                                    backgroundColor: "#F1F2F8",
                                  }}
                                >
                                  <td
                                    style={{
                                      height: "39px",
                                      borderLeft: "3px solid blue",
                                    }}
                                  >
                                    {" "}
                                    <a
                                      style={{ padding: "5px 0px" }}
                                      href="#/"
                                      className="getcampId"
                                      id={creativeCampList.campID}
                                      onClick={this.handleCampID.bind(this)}
                                    >
                                      &nbsp;&nbsp;{creativeCampList.campID} -{" "}
                                      {creativeCampList.campaignName}{" "}
                                    </a>
                                  </td>
                                </tr>
                              ) : (
                                <tr
                                  style={{
                                    borderBottom: "1px solid #ccc",
                                    borderTop: "1px solid #ccc",
                                  }}
                                >
                                  <td style={{ height: "39px" }}>
                                    {" "}
                                    <a
                                      style={{ padding: "5px 0px" }}
                                      href="#/"
                                      className="getcampId"
                                      id={creativeCampList.campID}
                                      onClick={this.handleCampID.bind(this)}
                                    >
                                      &nbsp;&nbsp;&nbsp;
                                      {creativeCampList.campID} -{" "}
                                      {creativeCampList.campaignName}{" "}
                                    </a>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        ))}
                      </div>
                    ) : (
                      <center>
                        {this.state.searchData.length > 0 ? (
                          <label
                            style={{
                              color: "red",
                              fontSize: "medium",
                              marginTop: "34px",
                            }}
                          >
                            No Data Exist
                          </label>
                        ) : (
                          <label></label>
                        )}
                      </center>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-12"> */}
            <div class="col-lg-9" style={{ paddingRight: "0px" }}>
              <div class="card card-signin my-2">
                <div class="card-body">
                  <form
                    method="POST"
                    name="lpReview"
                    onSubmit={this.submitLpReviewFeedback}
                  >
                    <div class="row">
                      {this.state.lpReviewPendingListCampIDOnNextPage.map(
                        (lpReviewPendingListCampIDOnNextPage) => (
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <label id="label" className="CampaignIDAdv">
                              Campaign ID:{" "}
                            </label>{" "}
                            {/*Chaitanya-4267-RESPONSIVE-Advertiser login-Advertiser Creatives Review Page responsiveness issue*/}
                            {/* {this.state.parentCampID==="null" ||this.state.parentCampID===null|| this.state.parentCampID==="undefined"||this.state.parentCampID===""?
                        this.state.campID:this.state.parentCampID}&nbsp;&nbsp; */}
                            {lpReviewPendingListCampIDOnNextPage.campID}
                          </div>
                        )
                      )}

                      {this.state.lpReviewPendingListCampIDOnNextPage.map(
                        (lpReviewPendingListCampIDOnNextPage) => (
                          <div class="col-sm-6 col-md-6 col-lg-5 col-lg-offset-1">
                            <label id="label" className="CampaignNameAdv">
                              Campaign Name:{" "}
                            </label>{" "}
                            {/*Chaitanya-4267-RESPONSIVE-Advertiser login-Advertiser Creatives Review Page responsiveness issue*/}
                            {lpReviewPendingListCampIDOnNextPage.campaignName}
                          </div>
                        )
                      )}
                      <hr />
                      <div
                        class="col"
                        align="center"
                        style={{ fontSize: "16px" }}
                      >
                        <label id="labelheading" style={{ color: "red" }}>
                          {this.state.creativesMessage}
                        </label>
                      </div>

                      <div class="table-responsive">
                        {this.state.tableStatus === "true" ? (
                          <table class="table table-bordered table-striped">
                            <thead>
                              <tr class="info">
                                <th
                                  class="table-header"
                                  style={{ width: "10%" }}
                                >
                                  Agency Name
                                </th>
                                <th
                                  class="table-header"
                                  style={{ width: "20%" }}
                                >
                                  Asset Name
                                </th>
                                {/* kiran-4747-removing console warning-changed == to === */}
                                {this.state.marketingChannel ===
                                  "Email/Telemarketing" ||
                                this.state.marketingChannel ===
                                  "EmailTelemarketing" ||
                                this.state.marketingChannel === "Email" ? (
                                  <th
                                    class="table-header"
                                    style={{ width: "25%" }}
                                  >
                                    Landing Page
                                  </th>
                                ) : (
                                  ""
                                )}
                                {this.state.marketingChannel ===
                                  "Email/Telemarketing" ||
                                this.state.marketingChannel ===
                                  "EmailTelemarketing" ||
                                this.state.marketingChannel === "Email" ? (
                                  <th
                                    class="table-header"
                                    style={{ width: "25%" }}
                                  >
                                    Newsletter File
                                  </th>
                                ) : (
                                  ""
                                )}
                                {this.state.marketingChannel ===
                                  "Email/Telemarketing" ||
                                this.state.marketingChannel ===
                                  "EmailTelemarketing" ||
                                this.state.marketingChannel ===
                                  "TeleMarketing" ? (
                                  <th
                                    class="table-header"
                                    style={{ width: "30%" }}
                                  >
                                    Call Script
                                  </th>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </thead>

                            <tbody>
                              {this.state.lpReviewPendingList.map(
                                (lpReviewPendingList, i) => {
                                  return (
                                    <tr>
                                      {this.state.lpReviewPendingListCampIDOnNextPage.map(
                                        (
                                          lpReviewPendingListCampIDOnNextPage
                                        ) => (
                                          <input
                                            type="hidden"
                                            id="campID"
                                            name="campID"
                                            value={
                                              lpReviewPendingListCampIDOnNextPage.campID
                                            }
                                          />
                                        )
                                      )}
                                      <input
                                        type="hidden"
                                        id="supportDocID"
                                        value={lpReviewPendingList.supportDocID}
                                        name="supportDocID"
                                      />
                                      <input
                                        type="hidden"
                                        id="pID"
                                        value={lpReviewPendingList.pID}
                                        name="pID"
                                      />

                                      <td style={{ textAlign: "center" }}>
                                        {lpReviewPendingList.agencyName}{" "}
                                      </td>

                                      <td
                                        style={{
                                          wordWrap: "break-word",
                                          wordBreak: "break-all",
                                          whiteSpace: "normal",
                                        }}
                                      >
                                        {lpReviewPendingList.assetStatus ===
                                        "Removed" ? (
                                          <span
                                            style={{
                                              color: "red",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {lpReviewPendingList.suppDocName}
                                          </span>
                                        ) : (
                                          lpReviewPendingList.suppDocName
                                        )}{" "}
                                      </td>

                                      {this.state.marketingChannel ===
                                        "Email/Telemarketing" ||
                                      this.state.marketingChannel ===
                                        "EmailTelemarketing" ||
                                      this.state.marketingChannel === "Email" ? (
                                        lpReviewPendingList.assetStatus ===
                                        "Removed" ? (
                                          <td>
                                            <span
                                              style={{
                                                color: "red",
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {lpReviewPendingList.lpLink}
                                            </span>
                                          </td>
                                        ) : (
                                          <td
                                            style={{
                                              wordWrap: "break-word",
                                              wordBreak: "break-all",
                                              whiteSpace: "normal",
                                            }}
                                          >
                                            {lpReviewPendingList.lpLink ? (
                                              <div
                                                style={{
                                                  paddingBottom: "14px",
                                                  color: "#1890ff",
                                                }}
                                              >
                                                {" "}
                                                {/*Chaitanya-4140-Landing Page link are showing in Blue color*/}
                                                {/* Sandeep-task-2469-added code to open link when user click on link */}
                                                {/* kiran-4747-removing console warning-added "#/" */}
                                                <a
                                                href="#/"
                                                  target="_blank"
                                                  onClick={this.lpReviewPendingList.bind(
                                                    this
                                                  )}
                                                  lpLink={
                                                   lpReviewPendingList.lpLink
                                                  }
                                                >
                                                  {lpReviewPendingList.lpLink}{" "}
                                                </a>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {lpReviewPendingList.lpLink ? (
                                              <div>
                                                {" "}
                                                <div className="form-group">
                                                  {lpReviewPendingList.lp_status ===
                                                  "AdvertiserApproved" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Approved
                                                    </span>
                                                  ) : lpReviewPendingList.lp_status ===
                                                    "AdvertiserRejected" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Rejected
                                                    </span>
                                                  ) : lpReviewPendingList.lp_status ===
                                                      "AgencyApproved" &&
                                                    this.state.approvalFor ===
                                                      "Advertiser" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Approved
                                                    </span>
                                                  ) : lpReviewPendingList.lp_status ===
                                                      "AgencyApproved" &&
                                                    this.state.approvalFor ===
                                                      "Agency" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Approved
                                                    </span>
                                                  ) : (
                                                    <select
                                                      style={{ height: "60%" }}
                                                      id="lpStatus"
                                                      name="lpStatus"
                                                      supportDocID={
                                                        lpReviewPendingList.supportDocID
                                                      }
                                                      pID={
                                                        lpReviewPendingList.pID
                                                      }
                                                      defaultValue={lpReviewPendingList.lpStatus
                                                      }
                                                      onChange={this.handleChangeLpFeedback.bind(
                                                        this,
                                                        i
                                                      )}
                                                    >
                                                      <option value="Select">
                                                        --Select--
                                                      </option>
                                                      <option value="AdvertiserApproved">
                                                        Approved
                                                      </option>
                                                      <option value="AdvertiserRejected">
                                                        Rejected
                                                      </option>
                                                    </select>
                                                  )}
                                                </div>
                                                <div
                                                  id={
                                                    "timeline" +
                                                    lpReviewPendingList.supportDocID +
                                                    lpReviewPendingList.pID +
                                                    "lpStatus"
                                                  }
                                                  style={{ display: "none" }}
                                                >
                                                  Enter Feedback:
                                                  <textarea
                                                    id="lpFeedback"
                                                    name="lpFeedback"
                                                    defaultValue={
                                                      lpReviewPendingList.lpFeedback
                                                    }
                                                    onChange={this.handleChangeLpFeedback.bind(
                                                      this,
                                                      i
                                                    )}
                                                    class="form-control"
                                                  />
                                                  Upload a file:
                                                  <br />
                                                  <label class="chooseFile btn btn-default">
                                                    Choose a File
                                                    <input
                                                      type="file"
                                                      id={
                                                        lpReviewPendingList.supportDocID
                                                      }
                                                      pID={
                                                        lpReviewPendingList.pID
                                                      }
                                                      style={{
                                                        color: "transparent",
                                                        display: "none",
                                                      }}
                                                      name="uploadLPFeedBack"
                                                      class="fileupload"
                                                      onChange={this.submitLPFeedbackFile.bind(
                                                        this,
                                                        i
                                                      )}
                                                    />
                                                  </label>
                                                  <div
                                                    id={
                                                      "lpFeedbackList" +
                                                      lpReviewPendingList.supportDocID +
                                                      lpReviewPendingList.pID
                                                    }
                                                  >
                                                    {lpReviewPendingList.lpFeedbackFileName ? (
                                                      <div
                                                        id={
                                                          "fileName" +
                                                          lpReviewPendingList.supportDocID +
                                                          lpReviewPendingList.pID
                                                        }
                                                      >
                                                        1 File Chosen
                                                      </div>
                                                    ) : (
                                                      "No File Chosen"
                                                    )}
                                                  </div>
                                                  <div
                                                    id={
                                                      "lpFileList" +
                                                      lpReviewPendingList.supportDocID +
                                                      lpReviewPendingList.pID
                                                    }
                                                    className="word-wrap"
                                                  ></div>
                                                  {lpReviewPendingList.lpFeedbackFileName ? (
                                                    <div
                                                      id={
                                                        "fileName" +
                                                        lpReviewPendingList.supportDocID +
                                                        lpReviewPendingList.pID
                                                      }
                                                    >
                                                      <a
                                                        href="#/"
                                                        data-fileid={
                                                          lpReviewPendingList.supportDocID +
                                                          lpReviewPendingList.pID
                                                        }
                                                        class="removeFilePOC"
                                                      >
                                                        <i
                                                          class="fa fa-times"
                                                          onClick={this.deleteLPFeedback.bind(
                                                            this
                                                          )}
                                                          id={
                                                            lpReviewPendingList.supportDocID
                                                          }
                                                          pID={
                                                            lpReviewPendingList.pID
                                                          }
                                                          name={
                                                            lpReviewPendingList.lpFeedbackFileName
                                                          }
                                                        />
                                                      </a>{" "}
                                                      {lpReviewPendingList.lpFeedbackFileName
                                                        ? lpReviewPendingList.lpFeedbackFileName
                                                        : ""}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <div
                                                    id={
                                                      "lpFileError" +
                                                      lpReviewPendingList.supportDocID +
                                                      lpReviewPendingList.pID
                                                    }
                                                    style={{ color: "red" }}
                                                  ></div>
                                                  <div
                                                    id={
                                                      "lpFileMessage" +
                                                      lpReviewPendingList.supportDocID +
                                                      lpReviewPendingList.pID
                                                    }
                                                    style={{ color: "green" }}
                                                  ></div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        )
                                      ) : (
                                        ""
                                      )}
                                      {/* kiran-4747-removing console warning-changed == to === */}
                                      {this.state.marketingChannel ===
                                        "Email/Telemarketing" ||
                                      this.state.marketingChannel ===
                                        "EmailTelemarketing" ||
                                      this.state.marketingChannel === "Email" ? (
                                        lpReviewPendingList.assetStatus ===
                                        "Removed" ? (
                                          <td>
                                            <span
                                              style={{
                                                color: "red",
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {lpReviewPendingList.pocFileName}
                                            </span>
                                          </td>
                                        ) : (
                                          <td>
                                            {lpReviewPendingList.pocFileName ? (
                                              <div className="form-group">
                                                <a
                                                  href="#/"
                                                  id={
                                                    lpReviewPendingList.campID
                                                  }
                                                  supportDocID={
                                                    lpReviewPendingList.supportDocID
                                                  }
                                                  pID={lpReviewPendingList.pID}
                                                  pocFileName={
                                                    lpReviewPendingList.pocFileName
                                                  }
                                                  onClick={this.handleFileDownload.bind(
                                                    this
                                                  )}
                                                >
                                                  {
                                                    lpReviewPendingList.pocFileName
                                                  }
                                                </a>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {lpReviewPendingList.pocFileName ? (
                                              <div>
                                                <div className="form-group">
                                                  {lpReviewPendingList.poc_status ===
                                                  "AdvertiserApproved" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Approved
                                                    </span>
                                                  ) : lpReviewPendingList.poc_status ===
                                                    "AdvertiserRejected" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Rejected
                                                    </span>
                                                  ) : lpReviewPendingList.poc_status ===
                                                      "AgencyApproved" &&
                                                    this.state.approvalFor ===
                                                      "Advertiser" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Approved
                                                    </span>
                                                  ) : lpReviewPendingList.poc_status ===
                                                      "AgencyApproved" &&
                                                    this.state.approvalFor ===
                                                      "Agency" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Approved
                                                    </span>
                                                  ) : (
                                                    <select
                                                      style={{ height: "60%" }}
                                                      id="pocStatus"
                                                      name="pocStatus"
                                                      supportDocID={
                                                        lpReviewPendingList.supportDocID
                                                      }
                                                      pID={
                                                        lpReviewPendingList.pID
                                                      }
                                                      defaultValue={
                                                        lpReviewPendingList.pocStatus
                                                      }
                                                      onChange={this.handleChangeLpFeedback.bind(
                                                        this,
                                                        i
                                                      )}
                                                    >
                                                      <option value="Select">
                                                        --Select--
                                                      </option>
                                                      <option value="AdvertiserApproved">
                                                        Approved
                                                      </option>
                                                      <option value="AdvertiserRejected">
                                                        Rejected
                                                      </option>
                                                    </select>
                                                  )}
                                                  <div
                                                    id={
                                                      "timeline" +
                                                      lpReviewPendingList.supportDocID +
                                                      lpReviewPendingList.pID +
                                                      "pocStatus"
                                                    }
                                                    style={{ display: "none" }}
                                                  >
                                                    Enter Feedback:
                                                    <textarea
                                                      id="pocFeedback"
                                                      name="pocFeedback"
                                                      defaultValue={
                                                        lpReviewPendingList.pocFeedback
                                                      }
                                                      onChange={this.handleChangeLpFeedback.bind(
                                                        this,
                                                        i
                                                      )}
                                                      class="form-control"
                                                    />
                                                    Upload a file:
                                                    <br />
                                                    <label class="chooseFile btn btn-default">
                                                      Choose a File
                                                      <input
                                                        type="file"
                                                        id={
                                                          lpReviewPendingList.supportDocID
                                                        }
                                                        pID={
                                                          lpReviewPendingList.pID
                                                        }
                                                        style={{
                                                          color: "transparent",
                                                          display: "none",
                                                        }}
                                                        name="uploadLPFeedBack"
                                                        class="fileupload"
                                                        onChange={this.submitPOCFeedbackFile.bind(
                                                          this,
                                                          i
                                                        )}
                                                      />
                                                    </label>
                                                    <div
                                                      id={
                                                        "POCFeedbackList" +
                                                        lpReviewPendingList.supportDocID +
                                                        lpReviewPendingList.pID
                                                      }
                                                    >
                                                      {lpReviewPendingList.pocFeedbackFileName ? (
                                                        <div
                                                          id={
                                                            "POCFeedbackFileName" +
                                                            lpReviewPendingList.supportDocID +
                                                            lpReviewPendingList.pID
                                                          }
                                                        >
                                                          1 File Chosen
                                                        </div>
                                                      ) : (
                                                        "No File Chosen"
                                                      )}
                                                    </div>
                                                    <div
                                                      id={
                                                        "pocFileList" +
                                                        lpReviewPendingList.supportDocID +
                                                        lpReviewPendingList.pID
                                                      }
                                                      className="word-wrap"
                                                    ></div>
                                                    {lpReviewPendingList.pocFeedbackFileName ? (
                                                      <div
                                                        id={
                                                          "POCFeedbackFileName" +
                                                          lpReviewPendingList.supportDocID +
                                                          lpReviewPendingList.pID
                                                        }
                                                      >
                                                        <a
                                                          href="#/"
                                                          class="removeFilePOC"
                                                        >
                                                          <i
                                                            class="fa fa-times"
                                                            onClick={this.deletePOCFeedback.bind(
                                                              this
                                                            )}
                                                            id={
                                                              lpReviewPendingList.supportDocID
                                                            }
                                                            pID={
                                                              lpReviewPendingList.pID
                                                            }
                                                            name={
                                                              lpReviewPendingList.pocFeedbackFileName
                                                            }
                                                          />
                                                        </a>{" "}
                                                        {lpReviewPendingList.pocFeedbackFileName
                                                          ? lpReviewPendingList.pocFeedbackFileName
                                                          : ""}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <div
                                                      id={
                                                        "pocFileError" +
                                                        lpReviewPendingList.supportDocID +
                                                        lpReviewPendingList.pID
                                                      }
                                                      style={{ color: "red" }}
                                                    ></div>
                                                    <div
                                                      id={
                                                        "pocFileMessage" +
                                                        lpReviewPendingList.supportDocID +
                                                        lpReviewPendingList.pID
                                                      }
                                                      style={{ color: "green" }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        )
                                      ) : (
                                        ""
                                      )}

                                      {this.state.marketingChannel ===
                                        "Email/Telemarketing" ||
                                      this.state.marketingChannel ===
                                        "EmailTelemarketing" ||
                                      this.state.marketingChannel ===
                                        "TeleMarketing" ? (
                                        lpReviewPendingList.assetStatus ===
                                        "Removed" ? (
                                          <td>
                                            <span
                                              style={{
                                                color: "red",
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {lpReviewPendingList.csFileName}
                                            </span>
                                          </td>
                                        ) : (
                                          <td>
                                            {lpReviewPendingList.csFileName ? (
                                              <div className="form-group">
                                                <a
                                                  href="#/"
                                                  id={
                                                    lpReviewPendingList.campID
                                                  }
                                                  supportDocID={
                                                    lpReviewPendingList.supportDocID
                                                  }
                                                  pID={lpReviewPendingList.pID}
                                                  csFileName={
                                                    lpReviewPendingList.csFileName
                                                  }
                                                  onClick={this.handleCSFileDownload.bind(
                                                    this
                                                  )}
                                                >
                                                  {
                                                    lpReviewPendingList.csFileName
                                                  }
                                                </a>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {lpReviewPendingList.csFileName ? (
                                              <div>
                                                <div className="form-group">
                                                  {lpReviewPendingList.cs_status ===
                                                  "AdvertiserApproved" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Approved
                                                    </span>
                                                  ) : lpReviewPendingList.cs_status ===
                                                    "AdvertiserRejected" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Rejected
                                                    </span>
                                                  ) : lpReviewPendingList.cs_status ===
                                                      "AgencyApproved" &&
                                                    this.state.approvalFor ===
                                                      "Advertiser" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Approved
                                                    </span>
                                                  ) : lpReviewPendingList.cs_status ===
                                                      "AgencyApproved" &&
                                                    this.state.approvalFor ===
                                                      "Agency" ? (
                                                    <span>
                                                      <label>Status :</label>
                                                      &nbsp;Approved
                                                    </span>
                                                  ) : (
                                                    <select
                                                      style={{ height: "60%" }}
                                                      id="csStatus"
                                                      name="csStatus"
                                                      supportDocID={
                                                        lpReviewPendingList.supportDocID
                                                      }
                                                      pID={
                                                        lpReviewPendingList.pID
                                                      }
                                                      defaultValue={
                                                        lpReviewPendingList.csStatus
                                                      }
                                                      onChange={this.handleChangeLpFeedback.bind(
                                                        this,
                                                        i
                                                      )}
                                                    >
                                                      <option value="Select">
                                                        --Select--
                                                      </option>
                                                      <option value="AdvertiserApproved">
                                                        Approved
                                                      </option>
                                                      <option value="AdvertiserRejected">
                                                        Rejected
                                                      </option>
                                                    </select>
                                                  )}
                                                  <div
                                                    id={
                                                      "timeline" +
                                                      lpReviewPendingList.supportDocID +
                                                      lpReviewPendingList.pID +
                                                      "csStatus"
                                                    }
                                                    style={{ display: "none" }}
                                                  >
                                                    Enter Feedback:
                                                    <textarea
                                                      id="csFeedback"
                                                      name="csFeedback"
                                                      defaultValue={
                                                        lpReviewPendingList.csFeedback
                                                      }
                                                      onChange={this.handleChangeLpFeedback.bind(
                                                        this,
                                                        i
                                                      )}
                                                      class="form-control"
                                                    />
                                                    Upload a file:
                                                    <br />
                                                    <label class="chooseFile btn btn-default">
                                                      Choose a File
                                                      <input
                                                        type="file"
                                                        id={
                                                          lpReviewPendingList.supportDocID
                                                        }
                                                        pID={
                                                          lpReviewPendingList.pID
                                                        }
                                                        style={{
                                                          color: "transparent",
                                                          display: "none",
                                                        }}
                                                        name="uploadCSFeedBack"
                                                        class="fileupload"
                                                        onChange={this.submitCSFeedbackFile.bind(
                                                          this,
                                                          i
                                                        )}
                                                      />
                                                    </label>
                                                    <div
                                                      id={
                                                        "CSFeedbackList" +
                                                        lpReviewPendingList.supportDocID +
                                                        lpReviewPendingList.pID
                                                      }
                                                    >
                                                      {lpReviewPendingList.csFeedbackFileName ? (
                                                        <div
                                                          id={
                                                            "CSFeedbackFileName" +
                                                            lpReviewPendingList.supportDocID +
                                                            lpReviewPendingList.pID
                                                          }
                                                        >
                                                          1 File Chosen
                                                        </div>
                                                      ) : (
                                                        "No File Chosen"
                                                      )}
                                                    </div>
                                                    <div
                                                      id={
                                                        "csFileList" +
                                                        lpReviewPendingList.supportDocID +
                                                        lpReviewPendingList.pID
                                                      }
                                                      className="word-wrap"
                                                    ></div>
                                                    {lpReviewPendingList.csFeedbackFileName ? (
                                                      <div
                                                        id={
                                                          "CSFeedbackFileName" +
                                                          lpReviewPendingList.supportDocID +
                                                          lpReviewPendingList.pID
                                                        }
                                                      >
                                                        <a
                                                          href="#/"
                                                          class="removeFileCS"
                                                        >
                                                          <i
                                                            class="fa fa-times"
                                                            onClick={this.deleteCSFeedback.bind(
                                                              this
                                                            )}
                                                            id={
                                                              lpReviewPendingList.supportDocID
                                                            }
                                                            pID={
                                                              lpReviewPendingList.pID
                                                            }
                                                            name={
                                                              lpReviewPendingList.csFeedbackFileName
                                                            }
                                                          />
                                                        </a>{" "}
                                                        {lpReviewPendingList.csFeedbackFileName
                                                          ? lpReviewPendingList.csFeedbackFileName
                                                          : ""}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <div
                                                      id={
                                                        "csFileError" +
                                                        lpReviewPendingList.supportDocID +
                                                        lpReviewPendingList.pID
                                                      }
                                                      style={{ color: "red" }}
                                                    ></div>
                                                    <div
                                                      id={
                                                        "csFileMessage" +
                                                        lpReviewPendingList.supportDocID +
                                                        lpReviewPendingList.pID
                                                      }
                                                      style={{ color: "green" }}
                                                    ></div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* kiran-4747-removing console warning-changed == to === */}
                      {this.state.tableStatus === "true" ? (
                        <div class="col" align="right">
                          <button
                            class="btn  btn-primary  text-uppercase"
                            type="submit"
                            disabled={this.state.btnDisable}
                            style={{
                              backgroundColor: "#056eb8",
                              color: "white",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>{" "}
                    {/*end of row*/}
                  </form>
                </div>
              </div>
            </div>
            {/*end of card card-signin my-1*/}
          </div>
          {/*end of card-body*/}
          <Footer />
        </div>{" "}
        {/*end of outter container*/}
        <Footer />
      </div>
    ); // end of return div // end of return()
  } // end of render()
} // end of AdvertiserCreativesReview class

AdvertiserCreativesReview.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(AdvertiserCreativesReview)
);
