/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Sanjana Godbole,Sonali
@Creation Date:22-10-2019
@Description:UI for PublisherInvoice(Dashboard)
*/

import React from "react";
import * as $ from "jquery";
// import Navigation from "../layouts/publisherNavPage"; //Nilesh-4242-Removing console warnings
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
// import { Table } from "antd";  //Nilesh-4242-Removing console warnings
// import { css } from "emotion"; //Nilesh-4242-Removing console warnings
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
import { FaArrowAltCircleLeft } from "react-icons/fa";
import PublisherNavigation from "../layouts/publisherNavPage";

// const queryString = require("query-string"); //Nilesh-4242-Removing console warnings
// var path = require("path");  //Nilesh-4242-Removing console warnings
const Swal = require("sweetalert2");
  //Nilesh-4242-Removing console warnings
// const tableCSS = css({
// 	backgroundColor: "white",
// 	borderStyle: "bold",
// 	"& thead  > tr": {
// 		backgroundImage: "linear-gradient(to right,#28166f,#007cc3)",
// 		color: "white",
// 	},
// 	"& thead > tr >th": {
// 		border: "1px solid black",
// 		// borderWidth: '2px',
// 		// borderColor: 'yellow',
// 		// borderStyle: 'solid'
// 		color: "white",
// 	},
// });
class PublisherInvoice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			invoiceDetail: [],
			success: "",
			idInvoice: "",
			tableState: { size: "small" }, //Array for table properties
			agencyID: "",
			firstDate: "",
			lastDate: "",
			pID: "",
			month: "",
			year: "",
			fileExt: "",
			invoiceFeedbackArray: [],
			feedbackFlag: false,
			feedbackfile: "",
			invoiceID: "",
			fileName: "",
		};
		this.showFile = this.showFile.bind(this);
		this.getFeedback = this.getFeedback.bind(this);
		this.submitFeedback = this.submitFeedback.bind(this);
		// this.getFeedbackFile=this.getFeedbackFile.bind(this);
		// this.deleteFeedbackFile=this.deleteFeedbackFile.bind(this);
		this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
		// var invoiceFeedbackArray = [];  //Nilesh-4242-Removing console warnings
	} //end of constructor

	getFeedback(e) {
		var feedback = e.target.value;
		var invoiceId = e.target.id;

		this.setState({ invoiceId: invoiceId, feedback: feedback });
	}

	getFeedbackFile(i, e) {
		e.preventDefault();
		var invoiceID = e.target.getAttribute("invoiceID");
		//Nilesh-4242-Removing console warnings
		if (
			e.target.files[0] == null ||
			e.target.files[0] === undefined ||
			e.target.files[0] === ""
		) {
		} else {
			var feedbackfile = e.target.files[0];
			var fileName = feedbackfile.name;
			var ext = fileName.split(".").pop();
			if (ext !== undefined) {
				ext = ext.toLowerCase();
			}
			//  alert(ext)
			if (ext === "xls" || ext === "xlsx" || ext === "csv") {
				this.setState({
					invoiceID: invoiceID,
					feedbackfile: feedbackfile,
					fileName: fileName,
				});
				// var data = new FormData();
				// data.append("invoiceID",invoiceID);
				// data.append("feedbackFile",feedbackfile);

				// fetch("/publisherDashboard/uploadFeedbackFile",{
				//   method:'POST',
				//   body: data
				// }).then(res => res.json())
				// .then(res => {
				//   if(res.success===true){
				//     Swal.fire({
				//       text:'File Uploaded Successfully',
				//       type: 'success',
				//       confirmButtonText: 'Ok',
				//       allowOutsideClick: false,
				//       // onClose:()=>{window.location.reload()}
				//     })

				//     var msg= " File uploaded successfully";
				//     var message = document.getElementById('otherMessage'+invoiceID);
				// var number = document.getElementById(   //Nilesh-4242-Removing console warnings
				// 	"otherMessage" + invoiceID
				// ).innerHTML;
				// var dispFile = document.getElementById("feedBackFileDiv2" + invoiceID);  //Nilesh-4242-Removing console warnings
				//     // alert(dispFile)
				var link =
					'<i class="fa fa-times removeFilePOC" style="color:red;" id=' +
					'"' +
					invoiceID +
					'"' +
					"name=" +
					'"' +
					fileName +
					'"' +
					"></i>";
				//     // dispFile.innerHTML+=link+fileName
				//     // if(number=="")
				//     // {
				//     //   alert("here")
				//     //   message.innerHTML += msg;
				//     //   dispFile.innerText="";
				//     //   dispFile.innerHTML+=link+fileName
				//     // }
				//     // var a=document.getElementById("feedBackFileDiv2"+invoiceID).innerHTML
				var HTML = "<table>";
				HTML +=
					"<tr><td>" +
					link +
					"&nbsp;&nbsp;&nbsp;&nbsp;" +
					fileName +
					"</td></tr>";
				HTML += "</table>";
				document.getElementById("feedBackFileDiv2" + invoiceID).innerHTML =
					HTML;

				//   }
				// })
				// .catch(
				//   function (err) {console.log(err)}
				// );
			} else {
				Swal.fire({
					type: "error",
					html: "Please upload file of <b>.xlsx/xls</b> format only",
				});
			}

			$(document)
				.off()
				.on("click", ".removeFilePOC", function (e) {
					var id = e.target.id;
					// alert("hi")
					var fileName = e.target.getAttribute("name");
					// alert("here."+id+fileName)

					let data = { id: id, fileName: fileName };
					fetch("publisherDashboard/deleteFeedBackFile", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((res) => {
							if (res.success === true) { //Nilesh-4242-Removing console warnings
								Swal.fire({
									text: "File Deleted Successfully",
									type: "success",
									confirmButtonText: "Ok",
									allowOutsideClick: false,
									preConfirm: () => {
										//  this.forceUpdate();
										var nooutput = document.getElementById(
											"feedBackFileDiv2" + id
										);
										nooutput.innerHTML = "No File Choosen";
									},
								});
								document.getElementById("invoiceFeedbackFile" + i).value = "";
								return;
							}
						})
						.catch(function (err) {
							console.log(err);
						});
				});
			// alert(fileName)
		}

		var invoiceIdForFile = e.target.id;
		this.setState({
			invoiceIdForFile: invoiceIdForFile,
			feedbackfile: feedbackfile,
			feedbackFlag: true,
		});
	}

	submitFeedbackdetails(i, e) {
		const { name, value } = e.target;

		let invoiceDetail = [...this.state.invoiceDetail];
		invoiceDetail[i] = { ...invoiceDetail[i], [name]: value };
		//invoiceFeedbackArray.push({"id":name,"value":value})
		this.setState({ invoiceDetail, feedbackFlag: true });
	}

	submitFeedback(e) {
		e.preventDefault();

		var data = new FormData();
		data.append("invoiceID", this.state.invoiceID);
		data.append("feedbackFile", this.state.feedbackfile);

		fetch("/publisherDashboard/uploadFeedbackFile", {
			method: "POST",
			body: data,
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.success === true) {
					// Swal.fire({
					//   text:'File Uploaded Successfully',
					//   type: 'success',
					//   confirmButtonText: 'Ok',
					//   allowOutsideClick: false,
					//   // onClose:()=>{window.location.reload()}
					// })
					// var msg= " File uploaded successfully";
					// var message = document.getElementById('otherMessage'+invoiceID);
					// var number=document.getElementById("otherMessage"+this.state.invoiceID).innerHTML;
					// var dispFile=document.getElementById('feedBackFileDiv2'+this.state.invoiceID)
					// // alert(dispFile)
					// var link= '<i class="fa fa-times removeFilePOC" style="color:red;" id='+'"'+this.state.invoiceID+'"'+'name='+'"'+this.state.fileName+'"'+'></i>'
					// // dispFile.innerHTML+=link+fileName
					// // if(number=="")
					// // {
					// //   alert("here")
					// //   message.innerHTML += msg;
					// //   dispFile.innerText="";
					// //   dispFile.innerHTML+=link+fileName
					// // }
					// // var a=document.getElementById("feedBackFileDiv2"+invoiceID).innerHTML
					// var HTML = "<table>";
					// HTML += "<tr><td>"+link+"&nbsp;&nbsp;&nbsp;&nbsp;"+this.state.fileName+"</td></tr>";
					// HTML += "</table>";
					// document.getElementById("feedBackFileDiv2"+this.state.invoiceID).innerHTML=HTML
				}
			})
			.catch(function (err) {
				console.log(err);
			});

		if (this.state.feedbackFlag === false) {  //Nilesh-4242-Removing console warnings
			// alert("no passing..")
			Swal.fire({
				text: "Please Fill Feedback Or Upload a Feedback File ",
				type: "error",
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		} else {
			 //Sanjana-3925-VAPT--publisher side-finance-Invoice- API-FE----user removed
			//  const { user } = this.props.auth;  //Nilesh-4242-Removing console warnings
			 var parsed = this.props.location.state;//Somnath-3955-VAPT--publisher side--Home--URL links And Routes-FE
			 var month = parsed.month;
			 let data = {
				 dynamicArray: this.state.invoiceDetail,
			 month: month,
			 };
 
			 
			 fetch("/publisherDashboard/submitFeedback", {
				 method: "POST",
				 headers: { "Content-Type": "application/json" },
				 body: JSON.stringify(data),
			 })
				 .then((res) => res.json())
				 .then((res) => {
					 // alert(JSON.stringify(res))
					 if (res.success === true) {
						 Swal.fire({
							 text: "Feedback Submitted Successfully",
							 type: "success",
							 confirmButtonText: "Ok",
							 allowOutsideClick: false,

							preConfirm: () => {
								//     const {isAuthenticated, user} = this.props.auth;
								// this.setState({pID:user.id})
								// var parsed = queryString.parse(this.props.location.search);
								// var month = parsed.month;
								// this.setState({month:month})
								// let data={pID:user.id,month:month}

								// fetch("publisherDashboard/publisherInvoiceDetail",{
								//     method: "POST",
								//     headers: { "Content-Type": "application/json" },
								//     body: JSON.stringify(data)
								//     }).then(res => res.json())
								//   .then(invoiceDetail =>{
								//     this.setState({invoiceDetail:invoiceDetail,year:invoiceDetail[0].year})

								//     }).catch(function (err) {console.log(err)});
								e.preventDefault();
								window.location.reload();
							},
						});
					} else {
					}
				})
				.then(function (response) {
					console.log("Return with response");
					console.log(response);
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	showFile(blob) {
		if (this.state.extension === "pdf") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pdfFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/pdf",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			window.open(data, "_blank");
			link.download = pdfFileDownloadName;
			link.click();
		}
		if (this.state.extension === "ppt" || this.state.extension === "pptx") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pptFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/vnd.ms-powerpoint",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = pptFileDownloadName;
			link.click();
		}
		if (
			this.state.extension === "xlsx" ||
			this.state.extension === "xls" ||
			this.state.extension === "csv"
		) {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/vnd.ms-excel",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = fileName;
			link.click();
		}
		if (this.state.extension === "docx" || this.state.extension === "doc") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/msword",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
		if (this.state.extension === "txt") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "text/plain",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
		//Nilesh-4242-Removing console warnings
		if (
			this.state.extension === "png" ||
			this.state.extension === "jpeg" ||
			this.state.extension === "jpg" ||
			this.state.extension === "PNG" ||
			this.state.extension === "JPEG" ||
			this.state.extension === "JPG"
		) {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/Image",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
	}

	openFileInBrowser(e) {
		e.preventDefault();
		var filename = e.target.id;
		var invoiceID = e.target.getAttribute("invoiceID");
		var ext = filename.split(".").pop();
		this.setState({ extension: ext, filename: filename });
		// const { user } = this.props.auth; //Nilesh-4242-Removing console warnings
		let data1 = { filename: filename, invoiceID: invoiceID };// Akash-bug-4101
		fetch("/publisherDashboard/ShowFileDetails", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data1),
		})
			.then((r) => r.blob())
			.then((downloadFiles) => {
				this.setState({ downloadFiles: downloadFiles });
			})
			.then(this.handleFileShowDownload);
	}

	handleFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		let zipFileDownloadName = this.state.pID + "_invoice_documents.zip";

		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/zip",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}

	deleteFeedbackFile(i, e) {
		var id = e.target.id;
		var fileName = e.target.getAttribute("name");
		var status = e.target.getAttribute("status");
		// alert(id)
		// alert(fileName)
		//  alert("invoice"+invoiceID)
		if (status === "InvoiceAccepted" || status === "InvoicePaid") {
		} else {
			let data = { id: id, fileName: fileName };
			fetch("publisherDashboard/deleteFeedBackFile", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.success === true) { //Nilesh-4242-Removing console warnings
						Swal.fire({
							text: "File Deleted Successfully",
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								this.forceUpdate();
								var nooutput = document.getElementById("feedBackFileDiv2" + id);
								nooutput.innerHTML = "No File Choosen";
							},
						});
						document.getElementById("invoiceFeedbackFile" + i).value = "";
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			const { user } = this.props.auth;
			this.setState({ pID: user.id });

			//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
			if(this.props.location.state!==undefined){
				var parsed = this.props.location.state;
				
				var month = parsed.month;
				var year = parsed.year;
			this.setState({ month: month, year: year });
			//Sanjana-3925-VAPT--publisher side-finance-Invoice- API-FE----pID removed
			let data = { month: month, year: year };

			fetch("publisherDashboard/publisherInvoiceDetail", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((invoiceDetail) => {
					this.setState({ invoiceDetail: invoiceDetail }, function () {});
					for (var i = 0; i < invoiceDetail.length; i++) {
						var idInvoice = invoiceDetail[i].invoiceID;
						this.setState({ idInvoice: idInvoice });
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		} // End of else of Authontication
	}
	} // End of componentDidMount

	render() {
		return (
			<div>
				<PublisherNavigation />
				<form
					method="POST"
					name="submitFeedback"
					onSubmit={this.submitFeedback}>
					<div
						style={{ paddingBottom: "60px", paddingTop: "90px" }}
						class="container-fluid">
						<div class="row">
							{/* { this.state.Step=='1'? */}
							<div class=" col-lg-4" style={{ paddingLeft: "20px" }}>
								<a
								    href="#/" //Nilesh-4242-Removing console warnings
									//karan-task-3717-replace query params
									onClick={(e) =>{
										e.preventDefault();
										this.props.history.push("/newPublisherDashboard")
									}}
									style={{ color: "#056eb8" }}>
									<FaArrowAltCircleLeft
										size={32}
										style={{ float: "left" }}
										title="Back to Dashboard"
									/>
								</a>
							</div>
							{/* //shivani-3240-passed ID for DI label consistency */}
							<div class="col-lg-4" id="labelDI">
								<label id="labelDI">Invoice Details</label>
							</div>
							<div class="col-lg-4"></div>
						</div>

						<br />
						<div>
							<div
								style={{
									paddingLeft: "10px",
									paddingTop: "10px",
									paddingBottom: "10px",
								}}>
								<label id="label">Invoice For : </label>&nbsp;{this.state.month}
								&nbsp;{this.state.year}
							</div>
						</div>
						<br />
						{this.state.idInvoice === undefined ? ( //Nilesh-4242-Removing console warnings
							<div
								style={{ textAlign: "center", color: "red", fontSize: "18px" }}>
								No Data Exists
							</div>
						) : (
							<div class="table-responsive">
								<table class="table table-bordered table-striped">
									<thead>
										<tr class="info">
											<th class="table-header" style={{ width: "8%" }}>
												Invoice ID
											</th>
											<th class="table-header" style={{ width: "8%" }}>
												Agency ID
											</th>

											<th class="table-header" style={{ width: "20%" }}>
												Invoice Document
											</th>

											<th class="table-header" style={{ width: "6%" }}>
												Month
											</th>
											<th class="table-header" style={{ width: "5%" }}>
												Year
											</th>
											<th class="table-header" style={{ width: "10%" }}>
												Invoice Status
											</th>
											<th class="table-header" style={{ width: "25%" }}>
												Feedback
											</th>
											<th class="table-header" style={{ width: "30%" }}>
												Upload Feedback File
											</th>
											<th class="table-header" style={{ width: "30%" }}>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{this.state.invoiceDetail.map((invoiceDetail, i) => {
											return (
												<tr>
													<td>{invoiceDetail.invoiceID}</td>
													<td>{invoiceDetail.agencyID}</td>
													<td>
														<a
															href="#/" //Nilesh-4242-Removing console warnings
															id={invoiceDetail.invoiceDocumentName}
															invoiceID={invoiceDetail.invoiceID}
															onClick={this.openFileInBrowser.bind(this)}>
															{invoiceDetail.invoiceDocumentName}
														</a>
													</td>
													<td>{invoiceDetail.month}</td>
													<td>{invoiceDetail.year}</td>
													<td>{invoiceDetail.status}</td>
													<td>
														Enter Feedback:
														<textarea
															id="invoiceFeedback"
															name="feedback"
															defaultValue={invoiceDetail.feedback}
															onChange={this.submitFeedbackdetails.bind(
																this,
																i
															)}
															class="form-control"
														/>
													</td>
													<td>
														{invoiceDetail.status === "InvoiceAccepted" ||
														invoiceDetail.status === "InvoicePaid" ? (
															<label
																id={invoiceDetail.invoiceID}
																disabled
																class="btn chooseFile">
																Choose file
																<input
																	type="file"
																	style={{ display: "none" }}
																	disabled
																	id={"invoiceFeedbackFile" + i}
																	invoiceID={invoiceDetail.invoiceID}
																	name="invoiceFeedbackFile"></input>
															</label>
														) : (
															<label
																id={invoiceDetail.invoiceID}
																class="btn chooseFile">
																Choose file
																<input
																	type="file"
																	style={{ display: "none" }}
																	id={"invoiceFeedbackFile" + i}
																	invoiceID={invoiceDetail.invoiceID}
																	name="invoiceFeedbackFile"
																	onChange={this.getFeedbackFile.bind(
																		this,
																		i
																	)}></input>
															</label>
														)}
														<br />
														<div
															id={"feedBackFileDiv2" + invoiceDetail.invoiceID}
															name={invoiceDetail.feedbackFIleName}>
															<span class="word-wrap">
																{invoiceDetail.feedbackFIleName ? (
																	<i
																		class="fa fa-times"
																		onClick={this.deleteFeedbackFile.bind(
																			this,
																			i
																		)}
																		id={invoiceDetail.invoiceID}
																		name={invoiceDetail.feedbackFIleName}
																		status={invoiceDetail.status}>
																		{" "}
																		&nbsp;
																	</i>
																) : (
																	"No File Choosen"
																)}
																{invoiceDetail.feedbackFIleName
																	? invoiceDetail.feedbackFIleName
																	: ""}
															</span>
														</div>
														<div
															id={"otherMessage" + invoiceDetail.invoiceID}
															style={{ color: "green" }}></div>
														<div
															id={
																"otherMessage" + invoiceDetail.invoiceID
															}></div>
													</td>
													<td>
														<select
															style={{ height: "60%" }}
															id="status"
															name="status"
															invoiceID={invoiceDetail.invoiceID}
															defaultValue={invoiceDetail.status}
															onChange={this.submitFeedbackdetails.bind(
																this,
																i
															)}>
															{/* <option value="Select">--Select--</option> */}
															<option value="InvoiceAccepted">Accept</option>
															{/* <option value="InvoiceRejected">Reject</option> */}
														</select>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
						{this.state.idInvoice === undefined ? (  //Nilesh-4242-Removing console warnings
							<div style={{ textAlign: "right", display: "none" }}>
								{" "}
								<button class="btn add-button" type="HTMLsubmit">
									Submit
								</button>
							</div>
						) : (
							<div style={{ textAlign: "right" }}>
								{" "}
								<button class="btn add-button" type="HTMLsubmit">
									Submit
								</button>
							</div>
						)}
					</div>
				</form>
				<Footer />
			</div>
		);
	}
}
//end of class PublisherInvoice
PublisherInvoice.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(PublisherInvoice)
);
