
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
* All Rights Reserved
*/
/*
@author:Sanjana Godbole
@Creation Date:15-01-2019
@Description:UI for Pending campaigns under Agency view
*/

import React from "react";
import * as $ from "jquery";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
import {Modal, Button } from 'react-bootstrap';
import "./newCampaign.css";//Sandeep-task-3697-Advertiser side--Action link.
import jsPDF from 'jspdf';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3850-VAPT URL changes
const Swal = require('sweetalert2')
var result=[];
var displayresult=[];
var displaycpl=[];
var displayallocatedLead=[];
class NewCampaign extends React.Component {
    constructor() {
       
            super();
            this.state = {
              newsuccess:"",
              stateCopy:[{
                allocatedLead:"",
                cpl:'',
                budget:''
              }],
              fields: {},
              newAgencyDynamicArray: [],
              tempnewAgencyDynamicArray: [],
              campID: " ",
              campIDTemp: " ",
              id: "",
              leadAllocation: 0,
              campaignName:'',
              btnStatus:'',
             newCampaignList: [],
              errors: {},
              alertDisplay:'none',
              alertBorder: '',
              alertWidth:'',
              alertAlign:'',
              spanSpaceDisplay:'none',
              success:'',
              puberror:'',
              allocerrors:'',
              reerrors:'',
              dateerrors:'',
              advID:'',
              show: false,
              campaignDetailID:[],
              onClickAllocatedLead:[],
              allocatedLeadPubListAgencyList:[],
              budgetDisplay:"",
              cplDisplay:"",
              actionPopupVisible: false,//Sandeep-task-3697-Advertiser side--Action link.
              x: 0,//Sandeep-task-3697-Advertiser side--Action link.
              y: 0,//Sandeep-task-3697-Advertiser side--Action link.
              actionPopupSelectedRecord: -1,//Sandeep-task-3697-Advertiser side--Action link.
              agencyList:[],
              campaignBriefSetupDetails:[],
              supportingDocs:[],
              campaignSetupSupportingDocs:[]

            };
          this.submitAgencyList = this.submitAgencyList.bind(this);
          this.handleRefreshPage=this.handleRefreshPage.bind(this);
          this.handleCampaignID = this.handleCampaignID.bind(this);
          this.editQuickUpload=this.editQuickUpload.bind(this);
          this.handleChangeDynamicAgency=this.handleChangeDynamicAgency.bind(this);
          this.showLeadAssignTOAgency=this.showLeadAssignTOAgency.bind(this);
          this.handleCampaignSetupDetails=this.handleCampaignSetupDetails.bind(this);
          this.handleFileDownload=this.handleFileDownload.bind(this);
          this.handleFileShowDownload=this.handleFileShowDownload.bind(this);
          } // end of constructor 
        
          showLeadAssignTOAgency(e) {
            //use for popup show leadassigntoAgency when i click on allocated lead
            var campID = e.target.id;
           
            this.state.campIDTemp= campID;
          
           
            fetch("/advertiser/viewAgencyAssignLead?campID="+campID)
              .then(res => res.json())
              .then(onClickAllocatedLead =>
                this.setState({ onClickAllocatedLead: onClickAllocatedLead })
              ).catch(function (err) {console.log(err)});


              fetch("/advertiser/allocatedLeadAgency?campID=" + campID + "")
              .then(res => res.json())
              .then(allocatedLeadPubListAgencyList =>
                this.setState({allocatedLeadPubListAgencyList: allocatedLeadPubListAgencyList})
              ).catch(function (err) {console.log(err)});

          }
          handleCampaignID(e) {
            var campID = e.target.id;
            var status=e.target.getAttribute('name');
            var advertiserID=e.target.getAttribute('advertiserID');

            let data={
              campID:campID,
              status:status,
            }
            /** Get AgencyName, allocated leads, Budget, startDate, endDate as per Campaign Level */
            fetch("/advertiser/quickCampDetail", {
              method: "POST",
              headers: {"Content-Type": "application/json"},
              body: JSON.stringify(data)
            }).then(res=> res.json())
              .then(campaignDetailID =>{
           


              if(campaignDetailID[0].budget==0)
              {
           
                this.setState({ budgetDisplay: "disabled"})
            
              }
            if(campaignDetailID[0].CPL==0)
            {
           
              this.setState({ cplDisplay: "disabled"})
            }
          
                this.setState({ campaignDetailID: campaignDetailID })
               }).catch(function (err) {console.log(err)});
               /** Get AgencyName, allocated leads, Budget, startDate, endDate as per agency Level */
              fetch("/advertiser/assignAgencyList",{
              method: "POST",
              headers: {"Content-Type": "application/json"},
              body: JSON.stringify(data)
              }).then(res=> res.json())
              .then(agencyList =>{
             
        agencyList.forEach((itm, i) => {
      this.state.newAgencyDynamicArray.push(Object.assign({}, itm, this.state.stateCopy[i]));
      });

      this.setState({ agencyList: agencyList });
     
               } ).catch(function (err) {console.log(err)});
             
         
              this.state.tempnewAgencyDynamicArray=Array.from(new Set(this.state.newAgencyDynamicArray.map(a=>a.agencyID))).map(agencyID=>{
                 return{
                   agencyID:agencyID,
                   agencyName:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).agencyName,
                   startDate:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).startDate,
                   endDate:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).endDate,
                   gdprCompliance:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).gdprCompliance,
                   allocatedLead:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).allocatedLead,
                   cpl:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).cpl,
              budget:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).budget,

                };
               });
            
               this.state.newAgencyDynamicArray=[...this.state.tempnewAgencyDynamicArray];
               this.setState({ newAgencyDynamicArray: this.state.tempnewAgencyDynamicArray });
             
              
      


         
         
              }
handleChangeDynamicDate(i,e){
  const { name, value } = e.target;
 
  let newAgencyDynamicArrayData = [...this.state.newAgencyDynamicArray];
  newAgencyDynamicArrayData[i] = {...newAgencyDynamicArrayData[i], [name]: value};
  this.setState({newAgencyDynamicArray:newAgencyDynamicArrayData});
}

          
handleChangeDynamicAgencyAllocatedLead(i,e) {

            const { name, value } = e.target;
            let fields = this.state;
          
              let newAgencyDynamicArray = [...this.state.newAgencyDynamicArray];
             
              var budget=newAgencyDynamicArray[i].budget;
              var cpl=newAgencyDynamicArray[i].cpl;
              var budget=cpl*value;
              var cplValue=isNaN(cpl);
              var budgetValue=isNaN(budget);
               
              if(cpl==undefined||cpl==""||cpl===null||cpl==="0"||cplValue===true)
              {
           
           
            var newCpl=0;
            if(value==""&&(cpl==""||cpl=="0"))
            {
            
          newCpl="";  
          }  
           
              var name1="cpl";
              var name2="allocatedLead";
               
              fields[name1] = newCpl;
              fields[name2] = value;

              newAgencyDynamicArray[i] = {...newAgencyDynamicArray[i], [name1]: newCpl};
              newAgencyDynamicArray[i] = {...newAgencyDynamicArray[i], [name2]: value};
              this.setState({newAgencyDynamicArray});
             

              }
              else
              {
if(value==""&&cpl!=="")
{
 
}
                


              }



               if(budget==undefined||budget==""||budget===null||budget==="0"||budgetValue===true)
              {
                
                
                    var budget=0;
                    if(value==""&&(budget==""||budget=="0"))
            {
           
          budget="";  
          }  
                    var name1="budget";
                    var name2="allocatedLead";

                    fields[name1] = budget;
                    fields[name2] = value;

                    newAgencyDynamicArray[i] = {...newAgencyDynamicArray[i], [name1]: budget};
                    newAgencyDynamicArray[i] = {...newAgencyDynamicArray[i], [name2]: value};
                    this.setState({newAgencyDynamicArray});

              }
              else  
              {
                var cpl=newAgencyDynamicArray[i].cpl;
                var budget=cpl*value;
                if(value==""&&cpl!==""&&budget!=="")
                {
                  cpl="";
                  budget="";
                }
                
                var name1="budget";
                var name2="allocatedLead";
                var name3="cpl";
                fields[name1] = budget;
                fields[name3] = cpl;
                fields[name2] = value;
                
                 newAgencyDynamicArray[i] = {...newAgencyDynamicArray[i], [name1]: budget};
                newAgencyDynamicArray[i] = {...newAgencyDynamicArray[i], [name2]: value};
                newAgencyDynamicArray[i] = {...newAgencyDynamicArray[i], [name3]: cpl};
                this.setState({newAgencyDynamicArray});
              }


             
           
         
          }
  handleChangeDynamicAgency(i, e) {
    const { name, value } = e.target;
    let fields = this.state;
    let newAgencyDynamicArrayData = [...this.state.newAgencyDynamicArray];
    if (name == "cpl") {
      var leads = newAgencyDynamicArrayData[i].allocatedLead;
      var newBudget = value * leads;
      if (value == "") {
        newBudget = "";
      }
      var name1 = "budget";
      fields[name1] = newBudget;
      newAgencyDynamicArrayData[i] = { ...newAgencyDynamicArrayData[i], [name1]: newBudget };
      newAgencyDynamicArrayData[i] = { ...newAgencyDynamicArrayData[i], [name]: value };
      this.setState({ newAgencyDynamicArray: newAgencyDynamicArrayData });
    }
    else {
      var leads = newAgencyDynamicArrayData[i].allocatedLead;
      var newCpl = value / leads;
      if (!isNaN(newCpl) === true) {
        newCpl = ""
      }
      if (value == "" || isNaN(value) === true) {
        newCpl = ""
      }
      var name1 = "cpl";
      fields[name1] = newCpl;
      newAgencyDynamicArrayData[i] = { ...newAgencyDynamicArrayData[i], [name1]: newCpl };
      newAgencyDynamicArrayData[i] = { ...newAgencyDynamicArrayData[i], [name]: value };
      this.setState({ newAgencyDynamicArray: newAgencyDynamicArrayData });
    }
  } // end of  handleChangeDynamicAgency

          handleClose() {
            this.setState({ show: false });
          }

          componentDidMount() {
            if(!this.props.auth.isAuthenticated) {
              this.props.history.push('/userLogin');
          }else{
             
            const {isAuthenticated, user} = this.props.auth;
            var advertiserID=user.id;
            this.setState({advertiserID:advertiserID});
          
           fetch("advertiser/advertiserNewCampaign")
           .then(res => res.json())
           .then(newCampaignList =>{
             //alert("newCampaignList====="+JSON.stringify(newCampaignList))
            this.setState({ newCampaignList: newCampaignList})
            }).catch(function (err) {console.log(err)});
          }
         }
         
        //handle file download
        handleFileDownload(e){
          var advCampID=this.state.advCampID;
          let downloadFileName=advCampID+"-CampaignSetupSpecification.pdf";
          var supportDocNames=this.state.supportingDocs.join('');
          let campaignName=this.state.campaignBriefSetupDetails[0].advCampaignName;
          this.setState({campaignName:campaignName});
          var doc=new jsPDF();
          // doc.setFontType("bold");
          doc.setFont("calibri");
          doc.setTextColor('Green');
          doc.setFontSize(20);
          doc.text(65, 15, 'Campaign ID :'+advCampID);
          doc.setLineWidth(1.5);
          doc.setDrawColor(0, 128, 0);
          doc.line(65, 18, 132, 18);
          // doc.setFontType("normal");
          doc.setFontSize(16);
          doc.setTextColor('orange');
          doc.text(20, 24, 'Basic Details');
          doc.setLineWidth(0.8);
          doc.setDrawColor(255,165,0);
          doc.line(20, 26, 50, 26);
          doc.setFontSize(12);
          doc.setTextColor('black');
          doc.text(20, 33, 'Campaign Name');
          doc.setTextColor('Blue');
          doc.text(73, 33, ':');
          var strArr = doc.splitTextToSize(""+this.state.campaignBriefSetupDetails[0].advCampaignName+"", 130)
          doc.text(strArr, 75, 33);
       

          doc.setTextColor('black');
          doc.text(20, 40, 'Campaign Type');
          doc.setTextColor('Blue');
          doc.text(73, 40, ':');
          doc.text(75, 40, ''+this.state.campaignBriefSetupDetails[0].advABM);

          doc.setTextColor('black');
          doc.text(20, 45, 'Start Date');
          doc.setTextColor('Blue');
          doc.text(73, 45, ':');
          doc.text(75, 45, ''+this.state.campaignBriefSetupDetails[0].advStartDate);

          doc.setTextColor('black');
          doc.text(20, 50, 'End Date');
          doc.setTextColor('Blue');
          doc.text(73, 50, ':');
          doc.text(75, 50, ''+this.state.campaignBriefSetupDetails[0].advEndDate);

          doc.setTextColor('black');
          doc.text(20, 55, 'Time Zone');
          doc.setTextColor('Blue');
          doc.text(73, 55, ':');
          doc.text(75, 55, ''+this.state.campaignBriefSetupDetails[0].advTimezone);

          doc.setTextColor('black');
          doc.text(20, 60, 'Leads Allocation');
          doc.setTextColor('Blue');
          doc.text(73, 60, ':');
          doc.text(75, 60, ''+this.state.campaignBriefSetupDetails[0].advLeadAllocation);
 //mufiz-khan-Bug-4360-title:-Prod Issue-Agency/Advertiser Side-View Campaign-Campaign name --Camp Setup Spec PDF -- Additional Long Comments not showing in PDF File */


          doc.setTextColor('black');
          doc.text(20, 65, 'Additional Comments');
         
          let addComments = unescape(this.state.campaignBriefSetupDetails[0].advAdditionalComment);
          var lenghtOfComment = addComments.length;
          var rows = lenghtOfComment/60;
          var addCommentsArray = [] ;
          var lineForBreak = 60;
          var newLinestart = 0
          var afterBreakLine= 0
          for (newLinestart=0; newLinestart< rows ; newLinestart++){

            addCommentsArray[newLinestart] = addComments.slice(afterBreakLine,lineForBreak);
            lineForBreak= lineForBreak+60
            afterBreakLine= afterBreakLine+60
            // console.log("addCommentsArray====>  "+addCommentsArray[newLinestart]);
          }


          doc.text(73, 65, ':');
          var align= 65;
          for (newLinestart=0; newLinestart< rows ; newLinestart++){
            doc.setTextColor('Blue');
            const stripped =  addCommentsArray[newLinestart].replace(/\s+/g, ' ')
            doc.text(75, align,""+stripped);
              align=align +5;
            
              
        }
          
 //mufiz-khan-Bug-4360-title:-Prod Issue-Agency/Advertiser Side-View Campaign-Campaign name --Camp Setup Spec PDF -- Additional Long Comments not showing in PDF File */

          doc.setFontSize(16);
          doc.setTextColor('orange');
          doc.text(20, align, 'Supporting Documents');
          doc.setLineWidth(0.8);
          doc.setDrawColor(255,165,0);
          doc.line(20, align+3,72, align+3);
          doc.setFontSize(12);
          doc.setTextColor('black');
          doc.setTextColor('Blue');
          doc.setTextColor('black');
          doc.text(22, align+10, 'ID');
          doc.text(32, align+10, 'Name');
          doc.setTextColor('Blue');
          doc.text(20, align+15, ""+supportDocNames+"");

          doc.save(downloadFileName);
          
          

      
      var formData=new FormData();
      formData.append("advCampID",advCampID);
      const {isAuthenticated, user} = this.props.auth;
       fetch("/advertiser/downloadSetupSuppDoc?advCampID=" + advCampID)
       .then(r => r.blob())
       .then(downloadFilesData =>
         this.setState({ downloadFilesData: downloadFilesData })
           )
           .then(this.handleFileShowDownload);
       }/** End of handle File Download */

       handleFileShowDownload(blob){
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var advCampID=this.state.advCampID;
        var campaignName=this.state.campaignName;
        let zipFileDownloadName=advCampID+'-'+campaignName+'.zip';
        console.warn("Blob Size===>");

        console.warn("New Blob Data===>"+JSON.stringify(this.state.downloadFilesData));
        var newBlob = new Blob([this.state.downloadFilesData], {type: "application/zip"})
       console.warn("New Blob Data===>"+JSON.stringify(newBlob));
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        } 
        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download=zipFileDownloadName;
        link.click();
        }


        handleCampaignSetupDetails(e){
          var advCampID=e.target.id;
          this.setState({advCampID : advCampID})
              fetch("advertiser/campaignSetupSpecDetails?advCampID="+advCampID+"")
              .then(res => res.json())
              .then((campaignBriefSetupDetails =>{
              this.setState({campaignBriefSetupDetails:campaignBriefSetupDetails})
              })).catch(function(err){console.log(err); });

              fetch("/agency/campaignSetupSuppDocs?advCampID="+advCampID+"")
              .then(res=>res.json())
              .then((campaignSetupSupportingDocs=>{
                console.log("inside campaignSetupSuppDocs ===============>")
                this.setState({campaignSetupSupportingDocs:campaignSetupSupportingDocs})
                if(this.state.campaignSetupSupportingDocs!=undefined||this.state.campaignSetupSupportingDocs!='')
                {
                    for(var i=0;i<this.state.campaignSetupSupportingDocs.length;i++)
                    {
                      this.state.supportingDocs.push('*  '+this.state.campaignSetupSupportingDocs[i].advSupportDocID+'  '+this.state.campaignSetupSupportingDocs[i].advSuppDocName,'\n');
                    }
                }
              })).catch(function(err){console.log(err);});
         }
    
          // refreshing parent page
          handleRefreshPage(e){
             //snehal-task-3850-VAPT URL changes
            e.preventDefault();
            this.props.history.push('/advertiserTabs')
            window.location.reload();//Somnath Task-4035, reload Window
          }
          submitAgencyList(e) {
            e.preventDefault();
            let errors = {};
            let spanDisplay='none';
            let fields = this.state.fields;
            let formIsValid = false;
            let date=false;
            let cpl=false;
            let lead=false;
            let success = {};
            let puberror ={};
            let valid=false;
            let rebudget=false;
            let allocerrors={};
            let reerrors={};
            let dateerrors={};
            result=Array.from(new Set(this.state.newAgencyDynamicArray.map(a=>a.agencyID))).map(agencyID=>{
                  return{
                      agencyID:agencyID,
                      agencyName:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).agencyName,
                      startDate:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).startDate,
                      endDate:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).endDate,
                      allocatedLead:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).allocatedLead,
                      cpl:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).cpl,
                      budget:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).budget
                      };
            });
            var totalLead = document.getElementById("totalLead").value;
            var totalAllocate=document.getElementById("totalAllocate").value;
            var totalBudget=document.getElementById("totalBudget").value;
            var totalRemainingBudget=document.getElementById("totalRemainingBudget").value;
            var totalstartDate=document.getElementById("totalstartDate").value;
            var totalendDate=document.getElementById("totalendDate").value;
            
            var sum=0;
            var num;
            var multiply=0;
            var num1;
            var num2;
            var addmulti=0;
            var add=totalLead-totalAllocate;
            var dynamiclength=result.length;
                        
            for(var i=0;i<dynamiclength;i++)
            {
            
                if((result[i].allocatedLead !== undefined && result[i].allocatedLead !== "")
                    || (result[i].cpl !== undefined && result[i].cpl !== "")){
                    
                    if(result[i].startDate<=result[i].endDate){
                                                      
                                                      
                                                        date=true;
                                                        displayresult.splice(i,1);
                                                     
                                                        
                                                      }
                                                else
                                                          {
                                                            date=false;
                                                          displayresult[i]=result[i].agencyName;
                                                         
                                                          // sandeep-task-2861-change in message format for end date Validation
                                                          errors["endDate"]="Please enter end date grater than start date for "+ displayresult;

                                                          this.setState({
                                                            newsuccess:0,
                                                            puberror:'',
                                                            dateerrors:'',
                                                            errors: errors,
                                                            alertWidth:'1000px',
                                                            alertAlign:'center',
                                                            alertDisplay:'block',
                                                            spanSpaceDisplay:spanDisplay,
                                                                           });
                                                       
                                                     
                                                
                                                         
                                                          return;
                                                          }
                                                          if(result[i].endDate>totalendDate)
                                                          {
                                                       
                                                        date=false;
                                                        displayresult[i]=result[i].agencyName;
                                                       
                                                         // sandeep-task-2861-change in message format for allocation end date Validation
                                                        dateerrors["date"]="Please enter allocation end date cannot be greater than campaign end  date for "+ displayresult;
                                                        
                                                        this.setState({
                                                          dateerrors:dateerrors,
                                                          newsuccess:0,
                                                          puberror:'',
                                                          errors: '',
                                                          alertWidth:'1000px',
                                                          alertAlign:'center',
                                                          alertDisplay:'block',
                                                          spanSpaceDisplay:spanDisplay,
                                                                         });
                                                                         return;
                                                          }
                                                            else
                                                            {
                                                             
                                                              date=true;
                                                            } 


                                                            if(result[i].startDate<totalstartDate)
                                                            {
                                                         
                                                          date=false;
                                                          displayresult[i]=result[i].agencyName;
                                                         
                                                          // sandeep-task-2861-change in message format for allocation start date Validation
                                                          dateerrors["date"]="Please enter allocation start date cannot be greater than campaign start date for "+ displayresult;
                                                          
                                                          this.setState({
                                                            dateerrors:dateerrors,
                                                            newsuccess:0,
                                                            puberror:'',
                                                            errors: '',
                                                            alertWidth:'1000px',
                                                            alertAlign:'center',
                                                            alertDisplay:'block',
                                                            spanSpaceDisplay:spanDisplay,
                                                                           });
                                                                           return;
                                                            }
                                                              else
                                                              {
                                                               
                                                                date=true;
                                                              } 
                                                         

                                           
                                                       
                                            var pattern = new RegExp(/^[1-9]+[0-9]*$/);
                                            
                                           
                                          if(!pattern.test(result[i].allocatedLead))
                                                    {
                                                      
                                                      lead=false;
                                                      displayallocatedLead[i]=result[i].agencyName;
                                                    
                                                      errors["allocatedLead"] = "Please enter allocation in number for  " +displayallocatedLead;

                                                     displayallocatedLead= [displayallocatedLead[i]];
                                                      var a=  displayallocatedLead.includes("displayallocatedLeads[i]");
                                                     
                                                      this.setState({
                                                        newsuccess:0,
                                                        puberror:'',
                                                        errors: errors,
                                                        dateerrors:'',
                                                        allocerrors:'',
                                                        alertWidth:'1000px',
                                                        alertAlign:'center',
                                                        alertDisplay:'block',
                                                        spanSpaceDisplay:spanDisplay,
                                                                       });
                                                   
                                                    
                                                      return;
                                                    }
                                            else
                                                    { 
                                                     
                                                      
                                                      lead=true;
                                                      
                                                      num= parseInt(result[i].allocatedLead);
                                                      sum+=num; 
                                                      displayallocatedLead.splice(i,1);
                                                     
                                                    }

                                                     // cpl validations
                             var patterndecimal=new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);//decimal 
                             var patternnonzero=new RegExp(/^[1-9]+[0-9]*$/);//numbers except zero
                             var testval1=result[i].cpl;

                         
                             var testval=parseInt(testval1);
                             
                         
                             if (testval1==""||testval1==null||testval1==undefined ) 
                             {
                               
                               cpl=false; 
                               displaycpl[i]=result[i].publisherName;
                               
                               errors["cpl"] = "Please check CPL value. CPL should follow below criteria for " +displaycpl[i];   
                              
                               errors["cpl1"] =<li>CPL should be only number.</li>;
                               errors["cpl2"] =<li>CPL should be greater than zero.</li>;
                               errors["cpl3"] =<li>CPL value allowed only 2 decimal places.</li>;
                         
                               this.setState({
                                 dateerrors:'',
                                 newsuccess:'',
                                 puberror:'',
                                 allocerrors:'',
                                 errors: errors,
                                 alertBorder: '1px solid red',
                                 alertWidth:'1000px',
                                 alertAlign:'center',
                                 alertDisplay:'block',
                                 spanSpaceDisplay:spanDisplay,
                                                });
                               return;
                         
                             }
                         
                             else
                             {
                               
                               if(testval1==0)
                               {
                              
                                cpl=false;
                                displaycpl[i]=result[i].publisherName;
                              
                                errors["cpl"] = "Please check CPL value. CPL should follow below criteria for " +displaycpl[i];   
                               
                                errors["cpl1"] =<li>CPL should be only number.</li>;
                                errors["cpl2"] =<li>CPL should be greater than zero.</li>;
                                errors["cpl3"] =<li>CPL value allowed only 2 decimal places.</li>;
                         
                         
                         
                         
                            
                                this.setState({
                                  dateerrors:'',
                                  newsuccess:'',
                                  puberror:'',
                                  allocerrors:'',
                                  errors: errors,
                                  alertBorder: '1px solid red',
                                  alertWidth:'1000px',
                                  alertAlign:'center',
                                  alertDisplay:'block',
                                  spanSpaceDisplay:spanDisplay,
                                                 });
                                                 return;
                               }
                               else
                               {
                                 if(patterndecimal.test(testval1))
                                 {
                                
                                   cpl=true;
                                 }
                                 else
                                 {
                                 
                                   cpl=false;
                                   displaycpl[i]=result[i].publisherName;
                                 
                                   errors["cpl"] = "Please check CPL value. CPL should follow below criteria for " +displaycpl[i];   
                                  
                                   errors["cpl1"] =<li>CPL should be only number.</li>;
                                   errors["cpl2"] =<li>CPL should be greater than zero.</li>;
                                   errors["cpl3"] =<li>CPL value allowed only 2 decimal places.</li>;
                          
                        
                                   this.setState({
                                     dateerrors:'',
                                     newsuccess:'',
                                     puberror:'',
                                     allocerrors:'',
                                     errors: errors,
                                     alertBorder: '1px solid red',
                                     alertWidth:'1000px',
                                     alertAlign:'center',
                                     alertDisplay:'block',
                                     spanSpaceDisplay:spanDisplay,
                                                    });
                                                    return;
                                 }
                         
                               }
                             }
                         
                             // end of cpl validations

                                  }//main
               
                             else
                               {
                                  
                                 puberror["one"]="Please enter valid allocation and cpl for atleast one agency";  
                                 

                                this.setState({
                                  puberror: puberror,
                                  allocerrors:'',
                                  errors: errors,
                                  newsuccess:0,
                                  alertWidth:'1000px',
                                  alertAlign:'center',
                                  alertDisplay:'block',
                                  spanSpaceDisplay:spanDisplay,
                                                 });
                              document.body.scrollTop = 0;
                              document.documentElement.scrollTop = 0;
       
                              }
                                                 
                
                            //validation for budget
                                  num1=parseInt(result[i].allocatedLead);
                                  num2=parseFloat(result[i].cpl);
                                
                                  multiply=num1*num2;
                                 
                                  addmulti+=multiply;
                                  
                                 document.getElementById("budget").innerHTML = multiply;
                                if(totalRemainingBudget == "")
                                {
                                 
                                        if(addmulti>totalBudget)
                                        {
                                          rebudget=false;
                                        
                                          reerrors["remaining"]="Allocated budget cannot be greater than total budget";
                                          this.setState({
                                            puberror:'',
                                            allocerrors:'',
                                            reerrors:reerrors,
                                            errors: errors,
                                            newsuccess:0,
                                            alertWidth:'1000px',
                                            alertAlign:'center',
                                            alertDisplay:'block',
                                            spanSpaceDisplay:spanDisplay,
                                                            });
                                        return;
                                        }
                                        else
                                        { 
                                          rebudget=true;
                                         
                                        }

                                } 
                                else
                                { 
                                        if(addmulti>totalRemainingBudget)
                                        {
                                          rebudget=false;
                                         
                                          reerrors["remaining"]="Allocated budget cannot be greater than total budget";
                                          this.setState({
                                            puberror:'',
                                            allocerrors:'',
                                            reerrors:reerrors,
                                            errors: errors,
                                            alertWidth:'1000px',
                                            alertAlign:'center',
                                            alertDisplay:'block',
                                            spanSpaceDisplay:spanDisplay,
                                                            });
                                        return;
                                        }
                                        else
                                        { 
                                          rebudget=true;
                                       
                                        }
                                }
                            
                                                            if(sum>add)
                                                            {

                                                              valid=false;
                                                              
                                                              allocerrors["allocate"]="Allocated leads cannot be greater than total leads";
                                                              this.setState({
                                                                puberror:'',
                                                                allocerrors:allocerrors,
                                                                reerrors:'',
                                                                newsuccess:0,
                                                                errors: errors,
                                                                alertWidth:'1000px',
                                                                alertAlign:'center',
                                                                alertDisplay:'block',
                                                                spanSpaceDisplay:spanDisplay,
                                                                               });
                                                            
                                                             return;
                                                              
                                                            }
                                                             else
                                                             {
                                                              valid=true;
                                                            
                                                              
                                                             }
                                    
  }// end of for  

 if (cpl===true &&  rebudget===true)
 {
 
  formIsValid=false;
 }
                if(date===true  && lead===true && valid===true && (cpl===true ||  rebudget===true))
                {
                  formIsValid=true;
                  }

  
                   console.log("Errors ====>"+JSON.stringify(this.state.errors));           

                    if( formIsValid ===true)
                  {
                     
                      
                      var campID = document.getElementById("campID").value;
                      var leadAllocation = document.getElementById("totalLead").value;
                      var status=document.getElementById("status").value;
                      const {isAuthenticated, user} = this.props.auth;
                      Swal.fire({
              
                        text: ("Campaign Allocated Successfully.."),
                        type: 'success',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        preConfirm: () => {
                          window.location.reload();
                        }
                      })

                      let data = {
                        campID: campID,
                        status:status,
                        leadAllocation:leadAllocation,
                        dynamicArray:this.state.newAgencyDynamicArray
                      };
                     
                      this.setState({
                          
                        errors:'',
                            puberror:'',
                            allocerrors:'',
                            reerrors:'',
                            dateerrors:'',
                          newsuccess:0,
                          alertWidth:'1000px',
                          alertAlign:'center',
                          alertDisplay:'block',
                        spanSpaceDisplay:spanDisplay
                          });
                  
                      fetch("advertiser/agencyAllocation", {
                        //insert data in publisher_assignment table
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(data)
                      }).then(res=>res.json()).then(res=>{
                        console.warn("Response in res in=="+JSON.stringify(res));
                          if(res.success==true)
                          {
                            this.setState({inputClass: "disabled"})
                            console.warn("Response in res=="+JSON.stringify(res));


                           }
                      else{
                      
                      }
                      }) 
                      .catch(err => {
                        console.log(err);
                      });

                  }
                  else
                  {
                   
                  }

            
        }   // end of submitAllocatingCampaign 
        editQuickUpload(e){
          e.preventDefault();
          //Sandeep-task-3697-Advertiser side--Action link.
          var campID=e.target.getAttribute('advCampID');
          var status=e.target.getAttribute('name');
          this.props.history.push('/editQuickUpload',{advCampID:campID}) //snehal-task-3850-VAPT URL changes
         // window.location.href="/editQuickUpload?advCampID="+campID;
        }

        /**
	 * @author Sandeep Dhawale
	 * @description Task-3697 handles when clicked on Action dots (3-dots)
	 */

	onActionClick = (e,newCampaignList,) => {
		e.preventDefault();
		if (!this.state.actionPopupVisible) {
			const self = this;
			document.addEventListener(`click`, function onClickOutside() {
				self.setState({ actionPopupVisible: false,actionPopupSelectedRecord:-1});
				document.removeEventListener(`click`, onClickOutside);
			});
		}
		this.setState({
			x: e.clientX,
		  y: e.clientY + window.pageYOffset-165,
			actionPopupVisible: true,
      actionPopupSelectedRecord: newCampaignList,
			// text:text,
     });
   	};

    render() {
      const {isAuthenticated, user} = this.props.auth;
      this.state.newAgencyDynamicArray=Array.from(new Set(this.state.newAgencyDynamicArray.map(a=>a.agencyID))).map(agencyID=>{
        return{
          agencyID:agencyID,
          agencyName:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).agencyName,
          startDate:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).startDate,
          endDate:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).endDate,
          gdprCompliance:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).gdprCompliance,
          allocatedLead:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).allocatedLead,
          cpl:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).cpl,
     budget:this.state.newAgencyDynamicArray.find(a=>a.agencyID=== agencyID).budget,

       };
      });
    

       return (
          <div>
              
                <div className="card-body">
                  <div class="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pendingAllocationFontSize" >
                   <div class=" float-left">
                 
                   
                 </div>
               
                  <div class="table-responsive-lgAdvTab "> 
                 
                      <table id="myTable" class="table " className=" table table-bordered table-striped scrollmenuForAdvTab ">
                        <thead class="thead1">
                          <tr class="custom-class">
                            <th class="th" style={{borderRightColor:'transparent',verticalAlign:'top',width:'3.3%',textAlign:'center'}} >
                              Campaign Id
                            </th>
                           {/* Sandeep-task-3410-alignment issue */}
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'6.5%',textAlign:'center'}} >
                              Campaign Name
                            </th>
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'3.5%',textAlign:'center'}} >
                              Campaign Type
                            </th>
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'4%',textAlign:'center'}}>
                            <div class="tip">
                                 <span>  Start Date</span>
                                  <span class="tooltiptext4">(yyyy-mm-dd)</span>
                              </div>
                            </th>
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'4%',textAlign:'center'}}  >
                            <div class="tip">
                                 <span>End Date</span>
                                  <span class="tooltiptext4">(yyyy-mm-dd)</span>
                              </div>
                            </th>
                        
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'3.5%',textAlign:'left'}} >
                              Total Leads
                            </th>
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'3.3%',textAlign:'center'}} >
                              Allocated Leads
                            </th>
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'3.3%',textAlign:'center'}} >
                              Status
                            </th>
                             {/* Sandeep-task-3410-alignment issue */}
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'4.3%',textAlign:'center'}} >
                              Action
                            </th>
                           
                          </tr>
                        </thead>
                        <tbody className="tbody1">
                         {this.state.newCampaignList.map(
                            newCampaignList => (
                              <tr className="tr1">
                                <td class="campId" style={{textAlign:'center',width:'6%'}} >
                             {newCampaignList.campID}
                                </td>
                               {/* Sandeep-task-3410-added code for alignment */}
                                <td style={{textAlign:'left',width:'11%',whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis',   textAlign:'left'}} >
                                <a
                                  href="#"
                                 id={newCampaignList.campID}
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                  onClick={this.handleCampaignSetupDetails}
                                >
                                
                               {newCampaignList.campaignName}
                                  </a>
                                </td>
                                 {/* Sandeep-task-3410-alignment issue */}
                           <td style={{textAlign:'center',width:'7%'}}>{newCampaignList.ABM}</td>
                                <td style={{textAlign:'center',width:'7%'}} >
                             {newCampaignList.startDate}
                                </td>
                                <td style={{textAlign:'center',width:'7%'}}  >
                             {newCampaignList.endDate}
                                </td>
                         
                                <td style={{textAlign:'center',width:'6%'}}>
                             {newCampaignList.leadAllocation}
                                </td>
                                 {/* Sandeep-task-3410-alignment issue */}
                                <td style={{textAlign:'center',width:'7%'}} >
                                  {newCampaignList.allocatedLead>0?
                                <a
                                    href=""
                                    data-toggle="modal"
                               id={newCampaignList.campID}
                                    data-target="#leaddetails"
                                    onClick={this.showLeadAssignTOAgency}
                                  >
                                {newCampaignList.allocatedLead?newCampaignList.allocatedLead:0}</a>:0}
                                </td>
                                <td style={{textAlign:'center',width:'7%',whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis',   textAlign:'left'}} >
                                {/* {newCampaignList.status=='New'?'Quick Upload':newCampaignList.status=='AllocatingInProgress'?'Quick Upload':''} */}
                                {/* Sandeep-task-3410-added code for status issue */}
                                {newCampaignList.status=='New'?'New':newCampaignList.status=='AllocatingInProgress'?'Allocating In Progress':newCampaignList.status=='Assign'?'Allocated':newCampaignList.status=='Step1'?'Step1':''}
                                </td>
                                {/* Sandeep-task-3410-alignment issue */}
                                <td style={{textAlign:'center',width:'6%'}} >
                                  {/* Sandeep-task-3151-added code for disable Allocate button if supporting document are not present */}
                                  {/* Sandeep-task-3697-Advertiser side--Action link. */}
                                  <div
					className="action_context_menu_icon"
					onClick={(e,i) => this.onActionClick(e,newCampaignList)}>
					<span
						style={{
							// padding: 5,
							position: "relative",
							// top: 15,
							left: 0,
						}}>
						...
					</span>
				</div>  
            {this.state.actionPopupVisible && (
						<div
							 className="popup-campaign-progress1"
							style={{
								 right: `${window.innerWidth - this.state.x}px`,
								top: `${this.state.y}px`,
								padding: 7,width:"100px"
							}}>
                                {this.state.actionPopupSelectedRecord.status==="Step1"||this.state.actionPopupSelectedRecord.status==="Assign"?
                               <a style={{float:"right",}} href=""  disabled id={this.state.actionPopupSelectedRecord.campID} name={this.state.actionPopupSelectedRecord.status} advertiserID={this.state.advertiserID} data-toggle="modal"
                                    data-target="#myModal"onClick={this.handleCampaignID}>
                                        {/* Sandeep-task-3697-Advertiser side--Action link. */}
                                 <img style={{marginLeft: "-25px", marginBottom: "-16px"}} src="Allocate_Disable.jpg" alt width="15" height="15" ></img>
                                      Allocate</a>:
                                    <a style={{float:"right"}} href="" id={this.state.actionPopupSelectedRecord.campID} name={this.state.actionPopupSelectedRecord.status} advertiserID={this.state.advertiserID} data-toggle="modal"
                                    data-target="#myModal"onClick={this.handleCampaignID}>
                                      {/* Sandeep-task-3697-Advertiser side--Action link. */}
                                       <img style={{marginLeft: "-25px", marginBottom: "-16px"}} id={this.state.actionPopupSelectedRecord.campID} name={this.state.actionPopupSelectedRecord.status} advertiserID={this.state.advertiserID} data-toggle="modal"  data-target="#myModal"
                                      src="allocate.jpg" alt width="15" height="15" ></img>
                                      Allocate</a>
                                  }
                                    <br/>
                                    <a style={{float:"right",marginRight:"24px"}} href="#" advCampID={this.state.actionPopupSelectedRecord.campID} name={this.state.actionPopupSelectedRecord.status}
                                   onClick={this.editQuickUpload}>
                                       {/* Sandeep-task-3697-Advertiser side--Action link. */}
                                     <img  style={{marginLeft: "-28px", marginBottom: "-16px"}} advCampID={this.state.actionPopupSelectedRecord.campID} name={this.state.actionPopupSelectedRecord.status} src="Edit.png" alt width="15" height="15" ></img>
                                     Edit</a>
         </div> )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      </div>
                    
                    
                    </div>
                    <div class="modal fade" id="myModal" role="dialog" data-backdrop="static" data-keyboard="false" >
                      <div class="modal-dialog modal-lg">
                      
                        <div class="modal-content">
                          <div class="modal-header custom-class">
                        
                            {this.state.campaignDetailID.map(
                              campaignDetailID => (
                                <div class="col-sm-11 text-left" style={{fontSize:"14px"}}>
                                 
                                  Campaign Allocation:&nbsp;{this.state.parentCampID}&nbsp;
                                 -&nbsp; {campaignDetailID.campaignName}
                               
                                
                                </div>
                              )
                            )}
                            <button
                              type="button"
                              class="cancel-button"
                              style={{fontSize:15,outline:'none'}}
                              data-dismiss="modal"
                              onClick={this.handleRefreshPage}
                            >
                              &times;
                            </button>
                          </div>
                          

                                    
            <div className=""  style={{display:this.state.alertDisplay}}>
            <div class="container-fluid" >
            
                 
                                 <div class="col-lg-12" style={{display:this.state.alertDisplay}}>
                                  <div style={{fontSize:'12px',color:'red',fontWeight:'600'}}>
                                  {this.state.errors.lead}<br/>
                                  {this.state.reerrors.remaining}<br/>
                                  {this.state.dateerrors.date}
                                  {this.state.errors.allocatedLead}
                                  {this.state.allocerrors.allocate}
                                  {this.state.puberror.one}
                                  {this.state.errors.endDate}
                                  </div>
                                  <div style={{fontSize:'12px',color:'red',fontWeight:'600',textAlign:'initial'}}>
                                  {this.state.errors.cpl}
                                  {this.state.errors.cpl1}
                                  {this.state.errors.cpl2}
                                  {this.state.errors.cpl3}
                                  </div>
                                  </div>
                                  
                                
                       
                        </div>
                        </div>
                          {/*end of  message */}
                     
                         <div class="modal-body">
                            <div class="container-fluid" >
                              <div style={{ border: "1px solid #cdcdcd" }}>
                                {this.state.campaignDetailID.map(
                                  campaignDetailID => (
                                    
                                    <div class="row">
                                         <div class="col-sm-4">
                                        <label id="label"style={{width:'90px'}}>Campaign Type:</label>
                                        &nbsp; {campaignDetailID.ABM}
                                      </div>
                                      
                                      <div class="col-sm-4">
                                        <label id="label" style={{width:'90px'}}>Total Leads:</label>
                                        &nbsp; {campaignDetailID.leadAllocation}
                                      </div>
                                      <div class="col-sm-4">
                                        <label id="label" style={{width:'121px'}}>
                                          Allocated Leads:
                                          
                                        </label>
                                        &nbsp;
                                        {campaignDetailID.allocatedLead
                                          ? campaignDetailID.allocatedLead
                                          : "0"}
                                      </div>
                                      <div class="col-sm-4">
                                        <label id="label" style={{width:'90px'}}>Pending Leads:</label>
                                        &nbsp;
                                        {campaignDetailID.pendingLead?campaignDetailID.pendingLead
                                          : campaignDetailID.leadAllocation}
                                      </div>
                                      <div class="col-sm-4">
                                        <label id="label" style={{width:'90px'}}>Start Date:</label>
                                        &nbsp; {campaignDetailID.startDate}
                                      </div>
                                      <div class="col-sm-4">
                                        <label id="label" style={{width:'120px'}}>End Date:</label>
                                        &nbsp; {campaignDetailID.endDate}
                                      </div>
                                     
                                      <div class="col-sm-4">
                                        <label id="label" style={{width:'90px'}}>CPL:</label>
                                        &nbsp; {(campaignDetailID.CPL)}&nbsp;{campaignDetailID.currency}</div>


                                      <div class="col-sm-4">
                                        <label id="label" style={{width:'90px'}}>Total Budget:</label>
                                        &nbsp; {campaignDetailID.budget?campaignDetailID.budget:(campaignDetailID.leadAllocation*campaignDetailID.CPL).toFixed(2)}&nbsp;
                                        {campaignDetailID.currency}
                                      </div>
                                     
                                      <div class="col-sm-4">
                                        <label id="label" style={{width:'120px'}}>Remaining Budget:</label>
                                      &nbsp; {campaignDetailID.budget?(campaignDetailID.budget-campaignDetailID.allocatedbudget):((campaignDetailID.leadAllocation*campaignDetailID.CPL)-campaignDetailID.allocatedbudget).toFixed(2)}
                                      &nbsp;
                                      {campaignDetailID.currency}

                                    </div>
          <input type="hidden" id="campID" name="campID" value={campaignDetailID.campID}/>  
          <input type="hidden" id="totalLead" name="totalLead" value={campaignDetailID.leadAllocation}/>  
          <input type="hidden" id="status" name="status" value={campaignDetailID.status}/> 
          <input type="hidden" id="totalAllocate" name="totalAllocate" value={campaignDetailID.allocatedLead
                                    ? campaignDetailID.allocatedLead: "0"}/>  
          <input type="hidden" id="totalBudget" name="totalBudget" value={campaignDetailID.budget?campaignDetailID.budget:(campaignDetailID.leadAllocation*campaignDetailID.CPL).toFixed(2)}
                              />
          <input type="hidden" id="totalRemainingBudget" name="totalRemainingBudget" value={campaignDetailID.budget?(campaignDetailID.budget-campaignDetailID.allocatedbudget):((campaignDetailID.leadAllocation*campaignDetailID.CPL)-campaignDetailID.allocatedbudget).toFixed(2)} />    
          <input type="hidden" id="totalstartDate" name="otalstartDate" value={campaignDetailID.startDate}/>
          <input type="hidden" id="totalendDate" name="totalendDate" value={campaignDetailID.endDate}/>                        
          <input type="hidden" id="totalPending" name="totalPending" value={campaignDetailID.allocatedLead
          ? campaignDetailID.leadAllocation - campaignDetailID.allocatedLead: "0"}/>                         
                                    </div>
                                  )
                                )}
                              </div>
                              <br />

                              <div class="row">
                               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                 <form
                                    method="POST"
                                    name="Publisher"
                                    onSubmit={this.submitAgencyList}
                                  >
                                     <div class="table-responsive-lgAdvTab">
                                    <table class=" table table-striped">
                                      <thead>
                                        <tr class="custom-class">
                                         
                                          <th style={{width:107}}>Agency Name</th>
                                          <th>Start Date</th>
                                          <th>End Date</th>
                                          <th>Allocation</th>
                                          <th >
                                            CPL
                                          </th>
                                          <th>Budget</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      

 {this.state.newAgencyDynamicArray.map((newAgencyDynamicArray,i) =>{

   return (
     <tr>
     

       <td  style={{textAlign:'center'}}>            
                 
                 <label  id="label" style={{align:'center'}}>{newAgencyDynamicArray.agencyName}</label> </td>

        <td><input type="date"id="startDate"name="startDate"value={this.state.startDate}onChange={this.handleChangeDynamicDate.bind(this, i)}
                 style={{width: "87%"}}defaultValue={newAgencyDynamicArray.startDate}class="form-control"/>        </td>
           <td><input type="date" id="endDate"name="endDate" value={this.state.endDate} onChange={this.handleChangeDynamicDate.bind(this, i)}
                      style={{width: "87%"}}defaultValue={newAgencyDynamicArray.endDate} class="form-control"/> </td> 
             <td ><input type="textbox" class="form-control"id="allocatedLead" name="allocatedLead" defaultValue={newAgencyDynamicArray.allocatedLead} //value={this.state.allocatedLead}
                     onChange={this.handleChangeDynamicAgencyAllocatedLead.bind(this, i)}style={{width: "80%",height: "100%"}}/>
             </td>
             <td >
               <input  type="text" class="form-control" id="cpl"   name="cpl" //value={this.state.cpl}
                disabled={this.state.cplDisplay} value={newAgencyDynamicArray.cpl} 
                 onChange={this.handleChangeDynamicAgency.bind(this, i)}
                       style={{width: "80%", height: "100%"}}/></td> 
              <td ><input type="textbox" class="form-control"id="budget" name="budget" //value={this.state.budget}
              value={newAgencyDynamicArray.budget}  disabled={this.state.budgetDisplay}
                     onChange={this.handleChangeDynamicAgency.bind(this, i)}style={{width: "100%",height: "100%"}}/>
             </td>
        </tr>);}) }
            </tbody>
                                    </table>
                                    </div>
                                    
                                    <div class="modal-footer">
                                      <button
                                        type="submit" className={this.state.inputClass}
                                        class="btn add-button"
                                      >
                                        Allocate
                                      </button>
                                    
                                    </div>
                                  </form>
                                </div>

                                {/**End of col */}
                              </div>
                              </div></div></div></div>
                            </div> 

                          {/** Show Lead Allocation popup */}

          <div class="modal fade" id="leaddetails" role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,.4)" }}>
           {/* Sandeep-task-3410-modal blurr issue */}
          <div class="modal-dialog modal-lg lead-class">
            <div class="modal-content">
            
              <div class="modal-header custom-class ">
            
            <div class="float-left"><h4 class="modal-title" style={{color:'#fff'}}>  Lead Allocation Detail (Campaign ID : {this.state.campIDTemp})</h4></div>
                <button type="button" data-dismiss="modal" class="cancel-button" style={{fontSize:15,color:'#ffffff',paddingLeft:'360px',outline:'none'}}>
                  &times;
                </button>

                                      </div>
                                      

              {/**End of modal-header */}
              <div class="modal-body">
                <div class="container-fluid" >
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div style={{ border: "1px solid #cdcdcd" }}>
                      {this.state.onClickAllocatedLead.map(
                        onClickAllocatedLead => (
                          <div class="row">
                            <div class="col-sm-6">
                              <label id="label" style={{width:'110px'}}>Total Leads:</label>
                              {onClickAllocatedLead.leadAllocation}
                              
                            </div>
                          
                            <div class="col-sm-6">
                              <label id="label" style={{width:'115px'}}>Total Budget:</label>
                              {onClickAllocatedLead.budget?(onClickAllocatedLead.budget):(onClickAllocatedLead.leadAllocation*onClickAllocatedLead.CPL).toFixed(2)}
                              {onClickAllocatedLead.currency}

                            </div>
                         
                            <div class="col-sm-6">
                              <label id="label" style={{width:'110px'}}>Allocated Leads:</label>
                              {onClickAllocatedLead.allocatedLead ? onClickAllocatedLead.allocatedLead:"0"}
                            </div>
                          
                            <div class="col-sm-6">
                              <label id="label" style={{width:'115px'}}>Allocated Budget:</label>
                              {onClickAllocatedLead.allocatedBudget ? (onClickAllocatedLead.allocatedBudget).toFixed(2):"0"}
                              {onClickAllocatedLead.currency}
                            </div>
                                
                                  <div class="col-sm-6">
                                    <label id="label" style={{width:'110px'}}>Remaining Leads:</label>
                                    {onClickAllocatedLead.pendingLead ? onClickAllocatedLead.pendingLead:
                                      onClickAllocatedLead.allocatedLead==onClickAllocatedLead?'0':onClickAllocatedLead.leadAllocation}
                                  </div>
                                 
                                  <div class="col-sm-6">
                                    <label id="label" style={{width:'115px'}}>Remaining Budget: </label>
                                    {onClickAllocatedLead.budget?(onClickAllocatedLead.budget-onClickAllocatedLead.allocatedBudget).toFixed(2):((onClickAllocatedLead.leadAllocation*onClickAllocatedLead.CPL)-onClickAllocatedLead.allocatedBudget).toFixed(2)}
                                    {onClickAllocatedLead.currency}
                                  </div>
                                
                                </div>
                              )
                            )}

                          </div>
                          



                          <br />
                          <div class="table-responsive-">
                          <table class=" table table-striped  table-bordered">
                            <thead>
                              <tr class="custom-class">
                                <th>Agency Name</th>
                                <th style={{width: "65%"}}>Allocation Details</th>
                                <th>CPL</th>
                                <th>Allocated Budget</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.allocatedLeadPubListAgencyList.map(
                                allocatedLeadPubListAgencyList => (
                                  <tr>
                                    <td style={{textAlign:'center'}}>
                                      <label id="label">
                                   
                                        {allocatedLeadPubListAgencyList.agencyName}
                                      </label>
                                    </td>
                                    <td>
                                     

                <label id="label">Allocated:</label>{allocatedLeadPubListAgencyList.allocatedLead}
                                      
                                      &nbsp;&nbsp;
                                 
                                      <label id="label">Start Date:</label> {allocatedLeadPubListAgencyList.startDate}  &nbsp;&nbsp;
                                  <label id="label">End Date:</label> {allocatedLeadPubListAgencyList.endDate}  &nbsp;&nbsp;
                                  <br>
                                  </br>
                                  <label id="label">Days Left:</label> {allocatedLeadPubListAgencyList.daysLeft>0?allocatedLeadPubListAgencyList.daysLeft:<font color="Red">0</font>} &nbsp;&nbsp;
                                    </td>
                                   
                                    <td>
                                      <label id="label">
                                        {allocatedLeadPubListAgencyList.CPL}
                                      </label>
                                    </td>
                                    
                                    <td>
                                      <label id="label">
                                        {allocatedLeadPubListAgencyList.allocatedBudget}
                                      </label>
                                    </td>
                                   
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                          </div>
                        </div>
                     
                      </div>
                      {/**End of container */}
                    </div>
                    {/**End of body */}
                    <div class="modal-footer">
                    
                      <button
                        type="button" 
                        class="btn add-button"
                        onClick={this.handleRefreshPage}
                      >
                        Close
                      </button>
                    </div>
                    {/**End of model footer */}
                  </div>
                  {/**End of modal-content */}
                </div>
                {/**End of modal-dialog */}
              </div>
      {/** Campaign Spec Download */}
                      <div>
                  <div class="modal fade" id="exampleModal"  role="dialog">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Campaign Details&nbsp;&nbsp;&nbsp;&nbsp;
                          <button type="button" class="btn add-button"   id="cmd"
                        
                          onClick={this.handleFileDownload} >
                          Download
                          </button></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                      <div class="container-fluid" >
                                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div style={{ border: "1px solid #cdcdcd" }}>
                                    <div class="table-responsive">
                                      <table id="myTable" className=" table table-bordered">
                                        <thead>
                                          <tr class="custom-class">
                                            <th>Basic Details</th>
                                          </tr>
                                        </thead>
                                      </table>
                                      </div>
                                    
                {this.state.campaignBriefSetupDetails.map(
                  campaignBriefSetupDetails => (
                    <div class="row">
                      <div class="col-sm-12">
                      <div class="col-sm-6">
                        <label id="label" style={{width:'130px'}}>
                          Campaign ID:
                        </label>
                        &nbsp;
                        <span >
                          {campaignBriefSetupDetails.advCampID}
                        </span>
                        </div>
                        <div class="col-sm-6">
                        <label id="label" style={{width:'130px'}}>
                          Campaign Name:
                        </label>
                        &nbsp;
                        <span >
                          {campaignBriefSetupDetails.advCampaignName}
                        </span>
                        </div>
                      </div>
                      <div class="col-sm-12">
                      <div class="col-sm-6">
                        <label id="label" style={{width:'130px'}}>
                          Start Date:
                          </label>
                          &nbsp;
                          <span>
                        {campaignBriefSetupDetails.advStartDate}
                        </span>
                      </div>
                      <div class="col-sm-6">
                      <label id="label" style={{width:'130px'}}>End Date:</label>&nbsp;
                        {campaignBriefSetupDetails.advEndDate}
                      </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="col-sm-6">
                      <label id="label" style={{width:'130px'}}>Timezone:</label>&nbsp;
                      <span>
                        {campaignBriefSetupDetails.advTimezone}
                        </span>
                      </div>
                      <div class="col-sm-6">
                      <label id="label" style={{width:'130px'}}>Campaign Type:</label>&nbsp;
                      <span>
                        {campaignBriefSetupDetails.advABM}
                        </span>
                      </div>
                      </div>
                      
                      <div class="col-sm-12">
                      <div class="col-sm-6">
                      <label id="label" style={{width:'130px'}}>Total leads:</label>
                        &nbsp;
                        <span>
                        {campaignBriefSetupDetails.advLeadAllocation}
                        </span>
                      </div>
                      </div>
                     
                      <div class="col-sm-6 col-xl-11" style={{marginRight: "10px", marginLeft: "16px" }}>
                       
                      <label id="label" >Additional Comments:</label>
                        &nbsp;
                        <span id="commentSpan" style={{overflowWrap:"anywhere",marginLeft: "11px"}}>
                        {unescape(campaignBriefSetupDetails.advAdditionalComment)}
                        
                        </span>
                      </div>
                      </div>
                  
                    )
                )}   
                <br/> 
                      <table id="myTable" class="scrollmenuForAdvTab" className=" table table-bordered">
                          <thead>
                          <tr class="custom-class">
                          <th>Supporting Document</th>
                          </tr>
                          </thead>
                          </table> 
                          

                      <div class="row">
                      <div class="col-sm-12">
                      <div class="col-sm-2">
                        <label id="label" style={{width:'130px'}}>ID:</label>
                        <br/>
                        {this.state.campaignSetupSupportingDocs.map(campaignSetupSupportingDocs=>( 
                          <div>
                        {campaignSetupSupportingDocs.advSupportDocID}
                          </div>
                          ))}
                      
                        
                        </div> 

                      <div class="col-sm-10">
                    
                              <label id="label" style={{width:'130px'}}>Name:</label>

                      <br/>
                      {this.state.campaignSetupSupportingDocs.map(campaignSetupSupportingDocs=>( 
                          <div>
                      {campaignSetupSupportingDocs.advSuppDocName}
                      </div>
                          ))}
                      </div>
                      </div>
                      </div>
                      
                    
                      
                    

                {/*end of modal data */}
                                      </div>
                                      </div>
                                      </div>
                      </div>
                      <div class="modal-footer">
                      <button type="button" class="btn add-button" id="cmd" onClick={this.handleFileDownload}  >Download</button>
                    
                      </div>
                    </div>
                  </div>
                </div>

                                      </div>

                   </div>
                   
                    </div>
                
          </div>// end of first div
       );// end of return
    }// end of render
 }// end of class 
/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      NewCampaign.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(NewCampaign)); 
