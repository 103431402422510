/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file authentication
 * @author   Narendra Phadke
 * @version 1.0
 * @section DESCRIPTION login middleware
 */

import axios from "axios";
import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import setAuthToken from "../setAuthToken";
import jwt_decode from "jwt-decode";
import { setTokenForFetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header

export const registerUser = (user, history) => (dispatch) => {
	axios
		.post("/api/users/register", user)
		.then((res) => history.push("/login"))
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};

/**
 * @author Narendra Phadke
 * @param  Description handle the Agency login authentication
 * @return Description return All details of authentication
 */
export const userLogin = (user) => (dispatch) => {
	fetch("/users/userLogin", {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(user),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success == true) {
				//console.warn("token=="+JSON.stringify(res));
				const token = res.token;
				// res.data.user
				// localStorage.clear();
				localStorage.setItem("jwtToken", token);
				setTokenForFetch(token); //karan-task-3717-added default header
				//setAuthToken(token);
				var decoded = jwt_decode(token);
				decoded.token = token; //Somnath Task:2993, Add token in decoded object to pass the setCurrentUser method to access in every page through props
				dispatch(setCurrentUser(decoded));
			} else {
				dispatch({
					type: GET_ERRORS,
					payload: res,
				});
			}
		})
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.res,
			});
		});
};

/**
 * @author Aman Shaikh Task - 4619
 * @param  Description handle the Switch login authentication
 * @return Description return All details other user login deatils for Switch login authentication
 */
export const SwitchLogin = (user) => (dispatch) => {
	fetch("/users/switchLogin", {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(user),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success == true) {
				const token = res.token;
				localStorage.setItem("jwtToken", token);
				setTokenForFetch(token); //karan-task-3717-added default header
				var decoded = jwt_decode(token);
				decoded.token = token; //Somnath Task:2993, Add token in decoded object to pass the setCurrentUser method to access in every page through props
				dispatch(setCurrentUser(decoded));
			} else {
				dispatch({
					type: GET_ERRORS,
					payload: res,
				});
			}
		})
		.catch((err) => {
			dispatch({
				type: GET_ERRORS,
				payload: err.res,
			});
		});
};

export const setCurrentUser = (decoded) => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded,
	};
};

export const logoutUser = (history) => (dispatch) => {
	localStorage.removeItem("jwtToken");
	localStorage.removeItem("ClientSwitch"); //Aman Task-4619- Added to remove localStorage of ClientSwitch info after logout
	//setAuthToken(false);
	dispatch(setCurrentUser({}));
	if (history === "undefined" || history === undefined || history === "") {
		window.location.href = "/userLogin";
	} else {
		history.push("/userLogin");
	}
};
