/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/**
* @author:Somnath  Keswad
* @Creation Date:06-01-2020
* @Description :UI for Publisher Lead Delivery Chart
**/
import React, { Component } from 'react';
import CanvasJSReact from '../../assets/canvasjs.react';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Somnath Task-3943-added default header
import PublisherNavigation from "../layouts/publisherNavPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class PublisherLeadDeliveryReport extends Component
{

    constructor() {
        super();
        this.state = {
			leadDetails:[],
			acceptedleadArray:[],
			rejectedLeadArray:[],
			pIDList:[],
			pacing:'Default',
           
        }
        this.toggleDataSeries = this.toggleDataSeries.bind(this);
		this.handleChange=this.handleChange.bind(this);
        this.getFilterBaseData=this.getFilterBaseData.bind(this);
	}// End of Constructor
getFilterBaseData(){
	// const {user}=this.props.auth;
	var pacing=this.state.pacing;
	// Get Publisher Lead Details
	fetch("reports/publisherLeadCountOnGraph?pacing="+pacing)
	.then(res => res.json())
	.then(leadDetails =>{
	this.setState({ leadDetails: leadDetails,pacing:pacing})
	var acceptedleadArray=[]; var rejectedLeadArray=[];
		for(var i=0;i<leadDetails.length;i++){
			var year=leadDetails[i].Year;
		if(year===undefined || year===null || year===''){
				var dt = new Date();
				  year=dt.getFullYear();
			  }
			  year='-'+year;
			  if(pacing==='Today'){year='';}
			var month=leadDetails[i].Month+year;
			acceptedleadArray.push({label:month,y:leadDetails[i].TotalAccepted});
			rejectedLeadArray.push({label:month,y:leadDetails[i].TotalRejected});
		}
		this.setState({acceptedleadArray:acceptedleadArray,rejectedLeadArray:rejectedLeadArray})
	}).catch(function (err) {console.log(err)});
}// End of getFilterBaseData
handleChange(e){
    let fields = this.state;
    let self=this;
    fields[e.target.name] = e.target.value
    self.setState({fields})
    self.getFilterBaseData();
}// End of handleChange
componentDidMount(){
	if(!this.props.auth.isAuthenticated) {
		this.props.history.push('/userLogin');
	}
	else{ 
		// const {user}=this.props.auth;
		var pacing='Default';
		// Get publisher Lead Details
		fetch("reports/publisherLeadCountOnGraph?pacing="+pacing)
		.then(res => res.json())
		.then(leadDetails =>{
		this.setState({ leadDetails: leadDetails})
		var acceptedleadArray=[]; var rejectedLeadArray=[];
			for(let i=0;i<leadDetails.length;i++){
				let month=leadDetails[i].Month+'-'+leadDetails[i].Year;
				acceptedleadArray.push({label:month,y:leadDetails[i].TotalAccepted});
				rejectedLeadArray.push({label:month,y:leadDetails[i].TotalRejected});
			}
			this.setState({acceptedleadArray:acceptedleadArray,rejectedLeadArray:rejectedLeadArray})
		}).catch(function (err) {console.log(err)});

	}
}// End of ComponentDidMount
toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
        }
		else{
            e.dataSeries.visible = true;
        
		}
		this.chart1.render();
}// End of toggleDataSeries

  render() {
         const leadDeliveryChart = {
			theme: "light2",
			animationEnabled: true,
			title:{
				text: "Lead Delivery"
			},
			
			axisX: {
				title:this.state.pacing
			},
			axisY: {
            interval: 50,
				title: "No. of Accepted leads",
		titleFontColor: "#4F81BC",
		lineColor: "#4F81BC",
		labelFontColor: "#4F81BC",
        tickColor: "#4F81BC"
    			},
			axisY2: {
		title: "No. of Rejected leads",
		titleFontColor: "#C0504E",
		lineColor: "#C0504E",
		labelFontColor: "#C0504E",
		tickColor: "#C0504E"
		 	},
			toolTip: {
				shared: true
			},
			legend: {
				cursor: "pointer",
				itemclick: this.toggleDataSeries
            },
            height:400,
            dataPointWidth: 20,
			data: [{
                    click: function(e)
                    {
                        // alert(  e.dataSeries.type+ ", dataPoint { x:" + e.dataPoint.name + ", y: "+ e.dataPoint.y + " }" );
                    document.getElementById('twobar').innerHTML=e.dataPoint.name+":"+e.dataPoint.y;
                 },
				type: "column",
		name: "Accepted",
		legendText: "Accepted",
		showInLegend: true, 
		dataPoints:this.state.acceptedleadArray
			},
			{
		type: "column",	
		name: "Rejected",
		legendText: "Rejected",
		axisYType: "secondary",
		showInLegend: true,
		dataPoints:this.state.rejectedLeadArray

			}]
        }
        
        return(
			
        <div class="container-fluid">
		 <PublisherNavigation />
		 <div class="row" style={{paddingTop:"90px"}}>
		 <div class="offset-lg-2 offset-sm-2 col-lg-8 col-md-8 col-sm-8" //4170-nilesh text align center
		 align="center"//4170-nilesh text align center
		 >
         <h1>Delivery Analysis Dashboard</h1>
		 </div>
		 </div>
	<br/>
	<div class="row">
               
			   <div class="col-xs-12 col-sm-12 col-md-12 col-lg12 col-xl-12" >
				   
				  <div class="col-sm-2">
					  <select style={{ height: '60%'}} id="pacing" name="pacing"
						  onChange={this.handleChange}>
						  <option value="Default">Select Pacing</option>
						  <option value="Today">Today</option>
						  <option value="Weekly">This Week</option>
						  <option value="Monthly">This Month</option>
						  <option value="Quarterly">This Quarter</option>
						  <option value="Yearly">This Year</option>
						  <option value="Last Week">Last Week</option>
						  <option value="Last Month">Last Month</option>
						  <option value="Last Quarter">Last Quarter</option>
						  <option value="Last Year">Last Year</option>
						  </select>
				  </div>
				
				  </div>
			  </div>
			  <br/>
             <div class="row" >
			
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg12 col-xl-12"  >
				<CanvasJSChart options = {leadDeliveryChart}
                                onRef={ref => this.chart1 = ref}
                            />
                            
                      {/* <div id="twobar">
                     
                    </div>   */}
                </div>
            </div>
			{/* <hr style={{borderTop:"1px solid #2196f3"}}/> */}
           
			<Footer />
        </div>
          
      )
                }
}
PublisherLeadDeliveryReport.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default connect(mapStateToProps, { logoutUser })(withRouter(PublisherLeadDeliveryReport));