/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Somnath Keswad
@Creation Date:22/11/2019
@Description:Upload suppression file against multiple Campaign with selected client
*/

import React from "react";
//import * as $ from "jquery";	//Chaitanya-2898-Removed Console warning
//import { BrowserRouter as Router, Route } from "react-router-dom";	//Chaitanya-2898-Removed Console warning
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/publisherNavPage";
import { css } from "emotion";
import { Table } from "antd";	//Chaitanya-2898-Removed Console warning
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header
import "./updateExistingLead.css";//vrushabh patil import css
//const Swal = require("sweetalert2");	//Chaitanya-2898-Removed Console warning
//const queryString = require("query-string");	//Chaitanya-2898-Removed Console warning
const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "12px",
		fontWeight: "500",
		
	},
	"& thead > tr >th": {
		border: "1px solid black",
		// borderWidth: '2px',
		// borderColor: 'yellow',
		// borderStyle: 'solid'
		color: "white",
	},
});

class UpdateExistingLead extends React.Component {
	constructor() {
		super();

		this.state = {
			fields: {},
			clientList: [],
			pageSize2: 20,
			
			clientName: "",
			campaignInfo: [],
			tableState: { size: "small" },
			suppressionerror: {},
			loaderSymbol: "",
			disabled: "",
			pID: "",
		};

		this.handleUpdateExistLead = this.handleUpdateExistLead.bind(this);
	} /*** End of constructor */

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			//var parsed = queryString.parse(this.props.location.search);	//Chaitanya-2898-Removed Console warning
			//Sandeep-task-3729-
			if (this.props.location.state !== undefined) {
				var	pID = this.props.location.state.pID;
		  //  this.setState({reportID})
		      this.setState({pID});
		  }
			// var pID = parsed.pID;

			this.setState({ pID: pID });
			//Chaitanya-2898-Removed Console warning
			// let data = {
			// 	pID: pID,
			// };
			fetch("/publisher/updateLeadsCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				//body: JSON.stringify(data),//sonali-3968-removing pID
			})
				.then((res) => res.json())
				.then((campaignInfo) => {
					this.setState({ campaignInfo: campaignInfo });
				})
				.catch(function (err) {
					console.log(err);
				});

			// const { user } = this.props.auth;
			fetch("/publisher/updateLeadsPermissionTimeFlag", {
				//insert data in publisher_assignment table
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((updateLeadsPermissionTimeFlag) => {})
				.catch(function (err) {
					console.log(err);
				});
		}
	} /*** End of ComponentDidMount */

	handleUpdateExistLead(e) {
		e.preventDefault();//sonali-task-3968-added preventDefault
		var campID = e.target.getAttribute("campID");
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");

		
		this.props.history.push("/publisherUpdateDeliverLead",{campID:campID,parentCampID:parentCampID,reallocationID:reallocationID}); //sonali-task-3968-replace query params

	
	}

	render() {
		
		const campaignData = [
			{
				title: "Campaign ID",
				dataIndex: "campID",
				key: "campID",
				width: "15%",//task no 4231 vrushabh increse width
				align: "center",
				sorter: (a, b) => a.campID - b.campID,
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>
							{" "}
							<a
								href="#/"	//Chaitanya-2898-Removed Console warning
								onClick={this.handleUpdateExistLead}
								campID={record.campID}
								campaignName={record.campaignName}
								parentCampID={record.parentCampID}
								reallocationID={record.reallocationID}
								style={{ fontSize: "11px", wordBreak: "break-all" }}>
								{record.campID}
							</a>
						</span>
					</div>
				),
			},
			{
				title: "Campaign Name",
				dataIndex: "campaignName",
				key: "campaignName",
				align: "center",
				width: "25%",//task no 4231 vrushabh
				sorter: (a, b) => {
					const asc = "asc";
					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div class="dotUploadSuppression" > {/*vrushabh -4231 removed width*/}
						<span style={{ fontSize: "12px" }}>{record.campaignName}</span>
					</div>
				),
			},
			{
				title: "Start Date",
				dataIndex: "startDate",
				key: "startDate",
				align: "center",
				width: "15%",
				sorter: (a, b) => {
					const asc = "asc";
					if (a.startDate.toUpperCase() < b.startDate.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (a.startDate.toUpperCase() > b.startDate.toUpperCase()) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.startDate}</span>
					</div>
				),
			},
			{
				title: "End Date",
				dataIndex: "endDate",
				key: "endDate",
				align: "center",
				width: "15%",
				sorter: (a, b) => {
					const asc = "asc";
					if (a.endDate.toUpperCase() < b.endDate.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (a.endDate.toUpperCase() > b.endDate.toUpperCase()) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.endDate}</span>
					</div>
				),
			},
			{
				title: "Time Allocated(Hours)",
				dataIndex: "timeAllocated",
				key: "timeAllocated",
				width: "25%",//task no 4231 vrushabh
				align: "center",
				sorter: (a, b) => a.updateLeadTime - b.updateLeadTime,
				render: (text, record) => (
					<div>
						<span>{record.updateLeadTime}</span>
					</div>
				),
			},
		];
		// rowSelection object indicates the need for row selection

		function onChange(pagination, filters, sorter) {
			console.log("params", pagination, filters, sorter);
		}
		return (
			<div>
				<Navigation />
<div class="Existing"/*class add*/>
				<div class="container-fluid ">
					<div class="row" style={{ paddingTop: "7%" }}>
						<div class="col-sm-12" align="center">
						<a href="#/" onClick={() =>	//Chaitanya-2898-Removed Console warning
									this.props.history.push("/newPublisherDashboard", {
                                        pID: this.state.pID,
									})
								} //Sanjana-task-3728-replace query params
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									//style={{ marginTop:"80px",marginRight:"40px" }}//float: "left",task no 4231 vrushabh
									title="Back to Dashboard"
								/>
			    </a>
							{/* //shivani-3308-passed ID for DI label consistency */}
							<label align="center" id="labelDI">
								Update Existing Lead
							</label>
						</div>
					</div>
					<hr style={{ margin: "15px"}} />
					<div class="row" style={{ paddingTop: "2%" }}>
						{/* <div class="col-sm-6"  >
</div> */}
					</div></div>
					<div
						className={this.state.loaderSymbol}
						style={{ left: "650px", top: "80px", zIndex: "2" }/*vp*/}></div>
					<br />
					<div class="row">
						<div class="col-sm-12">
							<Table
								{...this.state.tableState}
								id="myTable"
								bordered
								dataSource={this.state.campaignInfo}
								loading={this.state.loading2}
								onChange={onChange}
								columns={campaignData}
								//class="ant-table-thead" {/*vrushabh -4231 */}
								className={tableCSS}
								scroll={{x:600}}/*vrushabh -4231 increse scroll size*/
								pagination={{ pageSize: this.state.pageSize2 }}></Table>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		); /*** End of return */
	} /** End of render */
} /*** End of Class */

UpdateExistingLead.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(UpdateExistingLead)
);
