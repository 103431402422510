/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Sanjana Godbole
@fileName : incompleteCampaign.js
@Creation Date:15-01-2019
@Description:UI for Incomplete campaigns under Agency view

*/

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";

import "./incompleteCampaign.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //kiran-task-4063-added default header

class IncompleteCampaign extends React.Component {
	constructor() {
		super();
		this.state = {
			newsuccess: "",
			fields: {},
			campCount: [],
			campID: " ",
			campIDTemp: " ",
			id: "",
			leadAllocation: 0,
			campaignName: "",
			btnStatus: "",
			pendingLead: 0,
			campaignVisibleTime: "",
			campAcceptReject: "",
			incompleteCampaignDetails: [],
			errors: {},
			alertDisplay: "none",
			alertBorder: "",
			alertWidth: "",
			alertAlign: "",
			spanSpaceDisplay: "none",
			success: "",
			puberror: "",
			allocerrors: "",
			reerrors: "",
			dateerrors: "",
			agencyID: "",
			show: false,
		};

		this.count1 = 0;
		this.logChange = this.logChange.bind(this);
		this.handleRefreshPage = this.handleRefreshPage.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleEditCampaign = this.handleEditCampaign.bind(this);
		this.myFunction = this.myFunction.bind(this);
		this.myFunction1 = this.myFunction1.bind(this);
		this.displayCampaignSpecification =
			this.displayCampaignSpecification.bind(this); //shivani-task 3121-added code to bind displayCampaignSpecification function.
	} // end of constructor

	//shivani-task 3121-added url to load the specifications of camp.
	displayCampaignSpecification(e) {
		e.preventDefault(); //kiran-bug 4063- camp name should be in blue color
		var campID = e.target.getAttribute("campID");
		var parentCampID = e.target.getAttribute("parentCampId");
		var reallocationID = e.target.getAttribute("reallocationId");
		this.props.history.push("/campaignSpecificationDashboard", {
			campID,
			parentCampID,
			reallocationID,
		}); //karan-task-3715-replace query params
	} //end of displayCampaignSpecification

	myFunction(e) {
		var input, filter, table, tr, td, i, txtValue;
		input = document.getElementById("myInput");
		filter = input.value.toUpperCase();
		table = document.getElementById("myTable");
		tr = table.getElementsByTagName("tr");
		for (i = 0; i < tr.length; i++) {
			td = tr[i].getElementsByTagName("td")[2];
			if (td) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else {
					tr[i].style.display = "none";
				}
			}
		}
	} //end of myFunction

	myFunction1(e) {
		var input, filter, table, tr, td, i, txtValue;
		input = document.getElementById("myInput1");
		filter = input.value.toUpperCase();
		table = document.getElementById("myTable");
		tr = table.getElementsByTagName("tr");
		for (i = 0; i < tr.length; i++) {
			td = tr[i].getElementsByTagName("td")[0];
			if (td) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else {
					tr[i].style.display = "none";
				}
			}
		}
	} //end of myFunction1

	handleClose() {
		this.setState({ show: false });
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the incomplete campaign
	 * @return Description return All details of incomplete campaign
	 */
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			let self = this;
			var campID = localStorage.getItem("campID");
			const { user } = this.props.auth;
			var agencyID = user.id;
			this.setState({ agencyID: agencyID });
			if (campID === undefined || campID === "" || campID === null) {
				let data = {
					// agencyID: agencyID, // kiran-bug 4063-PAT/PROD Issue-Agency--incomplete campaign-agency id remove from payload
				};
				fetch("/agency/incompleteCampaign", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((incompleteCampaignDetails) => {
						self.setState({
							incompleteCampaignDetails: incompleteCampaignDetails,
						});
					})
					.catch(function (err) {});
			} else {
			}
		}
	} // end of componentDidMount

	logChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle complete the incomplete campaign
	 * @return Description return successfully return complete campaign
	 */
	handleEditCampaign(e) {
		e.preventDefault();
		var campID = e.target.id;
		var status = e.target.getAttribute("status");
		var rfpID = e.target.getAttribute("rfpID");
		var reallocationId = e.target.getAttribute("reallocationId");
		var parentCampID = e.target.getAttribute("parentCampID");

		if (rfpID == 0) {
			rfpID = "";
		}
		if (rfpID) {
			this.props.history.push("/createcampaign2", {
				campID,
				parentcampID: parentCampID,
				reallocationID: reallocationId,
				rfpCampaignID: rfpID,
				status,
			}); // karan-task-3767-replace query params
			// User Story 3125 : Karan Jagtap : added 1 more status="SupportingDocument"
			if (status == "Step1" || status === "SupportingDocument") {
				// window.location.href =
				// 	"/step2supportingdocument?campID=" +
				// 	campID +
				// 	"&rfpCampaign&rfpCampaignID=" +
				// 	rfpID +
				// 	"&status=" +
				// 	status +
				// 	"&parentcampID=" +
				// 	parentCampID +
				// 	"&reallocationID=" +
				// 	reallocationId;
				this.props.history.push("/step2supportingdocument", {
					campID,
					rfpCampaignID: rfpID,
					status,
					parentcampID: parentCampID,
					reallocationID: reallocationId,
					rfpCampaign: "",
				}); // karan-task-3719-vapt header and query params
			}
		} else {
			this.props.history.push("/createcampaign2", {
				campID,
				parentcampID: parentCampID,
				reallocationID: reallocationId,
				status,
			}); // karan-task-3767-replace query params
			// User Story 3125 : Karan Jagtap : added 1 more status="SupportingDocument"
			if (status == "Step1" || status === "SupportingDocument") {
				// window.location.href =
				// 	"/step2supportingdocument?campID=" +
				// 	campID +
				// 	"&parentcampID=" +
				// 	parentCampID +
				// 	"&reallocationID=" +
				// 	reallocationId +
				// 	"&status=" +
				// 	status;
				this.props.history.push("/step2supportingdocument", {
					campID,
					parentcampID: parentCampID,
					reallocationID: reallocationId,
					status,
				}); // karan-task-3719-vapt header and query params
			}
		}
	}

	/*
           @author:Sanobar golandaj
           @Description:validations for required fields
          */

	handleRefreshPage(e) {
		window.location.href = "/allocatingCampaign";
	}
	submitPublisher(e) {
		e.preventDefault();
	}

	render() {
		const { user } = this.props.auth;
		return (
			<div>
				<div className="card-body">
					<div class="row">
						<div class="col-lg-7"></div>
						<div class="col-lg-3" style={{ paddingLeft: "250px" }}>
							<input
								type="text"
								id="myInput"
								onChange={this.myFunction}
								style={{ backgroundRepeat: "no-repeat", width: "140px" }}
								placeholder="Campaign Name"
								class="form-control"
								title="Type in a name"
							/>
						</div>
						<div class="col-lg-2" style={{ paddingLeft: "90px" }}>
							<input
								type="text"
								id="myInput1"
								onChange={this.myFunction1}
								style={{ backgroundRepeat: "no-repeat", width: "120px" }}
								placeholder="Campaign ID"
								class="form-control"
								title="Type in a name"
							/>
						</div>
					</div>
					{/*end of 1st div*/}
					<br />
					<div class="row">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"></div>
						<div class="table-responsive-lg">
							<table
								id="myTable"
								class="table"
								className=" table table-bordered table-striped ">
								<thead class="thead1 incompleteCampaignFontSize">
									<tr class="info tr1">
										<th
											class="th table-header"
											style={{
												textSize: "10",
												borderRightColor: "transparent",
												verticalAlign: "top",
												width: "3.3%",
												textAlign: "center",
											}}>
											Campaign Id
										</th>
										<th
											class="table-header"
											style={{
												borderRightColor: "transparent",
												verticalAlign: "top",
												width: "4.2%",
												textAlign: "center",
											}}>
											Agency Campaign Id
										</th>
										<th
											class="table-header"
											style={{
												borderRightColor: "transparent",
												verticalAlign: "top",
												width: "9.5%",
												textAlign: "center",
											}}>
											Campaign Name
										</th>
										<th
											class="table-header"
											style={{
												borderRightColor: "transparent",
												verticalAlign: "top",
												width: "4.5%",
												textAlign: "center",
											}}>
											Campaign Type
										</th>
										<th
											class="table-header"
											style={{
												borderRightColor: "transparent",
												verticalAlign: "top",
												width: "4%",
												textAlign: "center",
											}}>
											<div class="tip">
												<span> Start Date</span>
												<span class="tooltiptext4">(yyyy-mm-dd)</span>
											</div>
										</th>
										<th
											class="table-header"
											style={{
												borderRightColor: "transparent",
												verticalAlign: "top",
												width: "4%",
												textAlign: "center",
											}}>
											<div class="tip">
												<span>End Date</span>
												<span class="tooltiptext4">(yyyy-mm-dd)</span>
											</div>
										</th>
										<th
											class="table-header"
											style={{
												borderRightColor: "transparent",
												verticalAlign: "top",
												width: "3.5%",
												textAlign: "center",
											}}>
											Total Leads
										</th>
										<th
											class="table-header"
											style={{
												borderRightColor: "transparent",
												verticalAlign: "top",
												width: "4%",
												textAlign: "center",
											}}>
											Campaign Status
										</th>
										{user.role === "AC" ? (
											<th
												class="table-header"
												style={{
													borderRightColor: "transparent",
													verticalAlign: "top",
													width: "6%",
													textAlign: "center",
												}}>
												Action
											</th>
										) : (
											""
										)}
									</tr>
								</thead>
								<tbody className="tbody1 incompleteCampaignFontSize">
									{this.state.incompleteCampaignDetails.map(
										(incompleteCampaignDetails) => (
											<tr className="tr1">
												<td
													class="campId"
													style={{ textAlign: "center", width: "6%" }}>
													{incompleteCampaignDetails.parentCampID
														? incompleteCampaignDetails.parentCampID
														: incompleteCampaignDetails.campID}
												</td>
														{/* Rutuja 5056 added css property and title for showing camp ID properly  */}
												<td
													class="campId"
													style={{ textAlign: "center", width: "8%", overflow: "hidden", whiteSpace: "nowrap",
													textOverflow: "ellipsis", }} title={incompleteCampaignDetails.clientCampID}>
														
													{incompleteCampaignDetails.clientCampID}
												</td>
												<td style={{ textAlign: "left", width: "17%" }}>
													{/* //shivani-task 3121-added this onClick function for display specifications from incomplete.. */}
													<a href="#" //kiran-bug 4063- camp name should be in blue color
														onClick={this.displayCampaignSpecification}
														campID={incompleteCampaignDetails.campID}
														campaignName={
															incompleteCampaignDetails.campaignName
														}
														parentCampID={
															incompleteCampaignDetails.parentCampID
														}
														reallocationID={
															incompleteCampaignDetails.reallocationID
														}>
														{incompleteCampaignDetails.campaignName}
													</a>
												</td>
												<td style={{ textAlign: "center", width: "9%" }}>
													{incompleteCampaignDetails.ABM}
												</td>
												<td style={{ textAlign: "center", width: "7%" }}>
													{incompleteCampaignDetails.startDate}
												</td>
												<td style={{ textAlign: "center", width: "7%" }}>
													{incompleteCampaignDetails.endDate}
												</td>
												<td style={{ textAlign: "center", width: "7%" }}>
													{incompleteCampaignDetails.leadAllocation}
												</td>
												<td style={{ textAlign: "center", width: "8.5%" }}>
													{incompleteCampaignDetails.status}
												</td>
												<td style={{ textAlign: "center", width: "10%" }}>
													<a
														href="#/"
														id={incompleteCampaignDetails.campID}
														status={incompleteCampaignDetails.status}
														rfpID={incompleteCampaignDetails.rfpProposalID}
														parentCampID={
															incompleteCampaignDetails.parentCampID
														}
														reallocationId={
															incompleteCampaignDetails.reallocationID
														}
														onClick={this.handleEditCampaign}>
														Continue
													</a>
												</td>
											</tr>
										)
									)}
								</tbody>
							</table>
						</div>
					</div>
					{/*end of 2nd div*/}
				</div>{" "}
				{/*end of card body*/}
			</div> //end of main div
		);
	} // end of render
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
IncompleteCampaign.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(IncompleteCampaign)
);
