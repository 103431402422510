/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanobar Golandaj
@Description:UI for delivery format under create campaign
*/

import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3766-specification VAPT api call

/*@author Narendra phadke
 *use for fetch url value
 */
const queryString = require("query-string");

class DeliveryFormat extends React.Component {
	constructor() {
		super();

		this.state = {
			fields: {},
			dynamicData: [],
			editDeliveryFormat: [],
			campID: "",
			companyName: "Yes",
			campaignID: "Yes",
			campaignDetail: [],
			assetName: "Yes",
			pID: "Yes",
			assetId: "No",
			leadInteractionDate: "Yes",
			firstName: "Yes",
			lastName: "Yes",
			jobTitleDelivery: "Yes",
			jobLevelDelivery: "",
			jobFunctionDelivery: "",
			assetName: "Yes",

			address: "Yes",
			email: "Yes",
			workPhone: "Yes",
			street: "No",
			city: "Yes",
			state: "Yes",
			countryDelivery: "Yes",
			zipCode: "Yes",
			companyEmployeeSize: "Yes",
			companyRevenue: "No",
			industry: "Yes",
			ip: "No",
			optInDate: "Yes",
			optInTime: "Yes",
			optInTimeZone: "Yes",
			linkedIn: "Yes",
			extra1: "No",
			extra2: "No",
			extra3: "No",
			extra4: "No",
			extra5: "No",

			//isChecked: false,
			errors: {},
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeCheck = this.handleChangeCheck.bind(this);
		this.handleChangeCamp = this.handleChangeCamp.bind(this);
		this.submitDeliveryFormat = this.submitDeliveryFormat.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleCheckedCustom = this.handleCheckedCustom.bind(this);
		this.handleBackButton = this.handleBackButton.bind(this);
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the logout authentication
	 * @return Description return All details of authentication
	 */
	componentDidMount() {
		let me = this;
		if (!me.props.auth.isAuthenticated) {
			me.props.history.push("/userLogin");
		} else {
			var step3 =
				window.location.href.split("&")[
					window.location.href.split("?").length - 1
				];
			if (step3 == undefined || step3 == null || step3 == "") {
				// var campID = window.location.href.split("?")[window.location.href.split("?").length - 1];
				var parsed = queryString.parse(me.props.location.search);
				var campID = parsed.campID;
				// alert("Camp ID="+campID);
				fetch("campaign/CampaignDetails?campID=" + campID + "")
					.then((res) => res.json())
					.then((detailsOfCampaign) => {
						//alert("Level="+JSON.stringify(detailsOfCampaign));
						if (detailsOfCampaign[0].jobLevel === "") {
							me.setState({
								detailsOfCampaign: detailsOfCampaign,
								jobLevelDelivery: "No",
							});
						} else {
							me.setState({
								jobLevelDelivery: "Yes",
							});
						}

						if (detailsOfCampaign[0].jobFunction === "") {
							me.setState({
								jobFunctionDelivery: "No",
							});
						} else {
							me.setState({
								jobFunctionDelivery: "Yes",
							});
						}

						//alert("Level="+this.state.jobLevelDelivery+" Function="+this.state.jobFunctionDelivery);
					});
			} else {
				var parsed = queryString.parse(me.props.location.search);
				var campID = parsed.campID;
				//alert(campID);
				fetch("campaign/deliveryFormatEdit?campID=" + campID + "")
					.then((res) => res.json())
					.then((editDeliveryFormat) => {
						me.setState({
							editDeliveryFormat: editDeliveryFormat, //[{'city':true}]
						});
					});
				//var campID = window.location.href.split("?")[window.location.href.split("?").length - 1];

				// var parsed = queryString.parse(me.props.location.search);
				// var campID = parsed.campID;
				// alert("Camp ID="+campID);
				fetch("campaign/CampaignDetails?campID=" + campID + "")
					.then((res) => res.json())
					.then((detailsOfCampaign) => {
						// alert("Level="+JSON.stringify(detailsOfCampaign));
						if (detailsOfCampaign[0].jobLevel === "") {
							me.setState({
								detailsOfCampaign: detailsOfCampaign,
								jobLevelDelivery: "No",
							});
						} else {
							me.setState({
								jobLevelDelivery: "Yes",
							});
						}

						if (detailsOfCampaign[0].jobFunction == "") {
							me.setState({
								jobFunctionDelivery: "No",
							});
						} else {
							me.setState({
								jobFunctionDelivery: "Yes",
							});
						}
						//  alert("else Level="+this.state.jobLevelDelivery+" Function="+this.state.jobFunctionDelivery);
					});
				me.setState({
					campID: campID,
				});
			}
		}
		//alert("loop Level="+this.state.jobLevelDelivery+" Function="+this.state.jobFunctionDelivery);
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the for checkbox selected dynamic
	 * @return Description return All details of delivery format
	 */
	handleChange(e) {
		var step3 =
			window.location.href.split("&")[
				window.location.href.split("?").length - 1
			];
		//alert("step3 : " + step3);

		let fields = this.state;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the for Back Button Call
	 * @return Description return Back Button
	 */
	handleBackButton(e) {
		//alert("Back Button");
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;

		window.location.href = "/supportingDocument?campID=" + campID + "&step2";
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the for checkbox selected dynamic
	 * @return Description return All details of delivery format
	 */
	handleChecked(e) {
		const { name, value } = e.target;
		let editDeliveryFormat = [...this.state.editDeliveryFormat];
		if (e.target.checked === true) {
			editDeliveryFormat[0] = { ...editDeliveryFormat[0], [name]: "Yes" };
		} else {
			editDeliveryFormat[0] = { ...editDeliveryFormat[0], [name]: "No" };
		}
		this.setState({ editDeliveryFormat });
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the for checkbox selected dynamic
	 * @return Description return All details of delivery format
	 */
	handleCheckedCustom(e) {
		const { name, value } = e.target;

		let editDeliveryFormat = [...this.state.editDeliveryFormat];
		editDeliveryFormat[0] = { ...editDeliveryFormat[0], [name]: value };
		this.setState({ editDeliveryFormat });
	}

	handleChangeCamp(e) {
		this.setState({ campID: e.target.value });
	}

	/*@author Narendra Phadke
	 *Desc Fetch all information of Delivery Format
	 */

	handleChangeCheck(e) {
		let fields = this.state;
		fields[e.target.name] = e.target.value;
		if (fields[e.target.name].isChecked) {
			fields[e.target.name] = "Yes";
		} else {
			fields[e.target.name] = "No";
		}

		this.setState({ isChecked: !this.state.isChecked });
	}

	submitDeliveryFormat(e) {
		e.preventDefault();

		/*@author Narendra phadke
		 *Desc Fetch campID from Url
		 * dependencies query-string
		 */

		const { isAuthenticated, user } = this.props.auth;

		console.log("User Details" + JSON.stringify(user));
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		console.log("Control on react page");
		console.log(campID);

		var step3 =
			window.location.href.split("&")[
				window.location.href.split("?").length - 1
			];
		//alert("step3 : " + step3);
		if (
			this.state.editDeliveryFormat[0] === undefined ||
			this.state.editDeliveryFormat[0] === "" ||
			this.state.editDeliveryFormat[0] === null
		) {
			// alert(JSON.stringify(this.state.editDeliveryFormat[0]));
			step3 = "undefined";
		}
		if (
			step3 == undefined ||
			step3 == null ||
			step3 == "" ||
			step3 == "undefined"
		) {
			let data = {
				campID: campID,
				companyName: this.state.companyName,
				pID: this.state.pID,
				assetId: this.state.assetId,
				campaignID: this.state.campaignID,
				leadInteractionDate: this.state.leadInteractionDate,
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				jobTitleDelivery: this.state.jobTitleDelivery,
				jobLevelDelivery: this.state.jobLevelDelivery,
				jobFunctionDelivery: this.state.jobFunctionDelivery,
				address: this.state.address,

				assetName: this.state.assetName,
				email: this.state.email,
				workPhone: this.state.workPhone,
				street: this.state.street,
				city: this.state.city,
				state: this.state.state,
				countryDelivery: this.state.countryDelivery,
				zipCode: this.state.zipCode,
				companyEmployeeSize: this.state.companyEmployeeSize,
				companyRevenue: this.state.companyRevenue,
				industry: this.state.industry,
				ip: this.state.ip,
				extra1: this.state.extra1,
				extra2: this.state.extra2,
				extra3: this.state.extra3,
				extra4: this.state.extra4,
				extra5: this.state.extra5,
				customQuestion1: this.state.customQuestion1,
				customQuestion2: this.state.customQuestion2,
				customQuestion3: this.state.customQuestion3,
				customQuestion4: this.state.customQuestion4,
				customQuestion5: this.state.customQuestion5,
				customQuestion6: this.state.customQuestion6,
				additionalComments: this.state.additionalComments,
				answer1: this.state.answer1,
				answer2: this.state.answer2,
				answer3: this.state.answer3,
				answer4: this.state.answer4,
				answer5: this.state.answer5,
				answer6: this.state.answer6,
			}; // karan-task-3719-vapt header and query params

			// alert("click data"+data.optInDate);
			// alert("click data"+data.optInTime);
			// alert("click data"+data.optInTimeZone);

			fetch("/campaign/deliveryFormat", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				//mode:'no-cors',
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetail) => {
					console.log("Return with response");
					//var campID=response.body.campID;
					this.setState({ campaignDetail: campaignDetail });
					console.log(JSON.stringify(campaignDetail));
					var campID = campaignDetail[0].campID;
					console.log(campID);
					//alert("Successfully Campaign Delivery Format");
					//send campID to new page
					window.location.href = "/finishCampaign?campID=" + campID + "";
					if (data === "success") {
						console.log("Inside if loop");
						this.refs.msg.show("Some text or component", {
							time: 2000,
							type: "success",
							icon: <img src="path/to/some/img/32x32.png" />,
						});
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		} //end of if Block
		else {
			let data = {
				editDeliveryFormat: this.state.editDeliveryFormat[0],
			};
			console.log("DynamiC Data : " + JSON.stringify(data));

			fetch("/campaign/editDeliveryFormat", {
				method: "POST",
				headers: { "Content-Type": "application/json" },

				body: JSON.stringify(data),
			})
				.then(function (response) {
					if (response.status >= 400) {
						throw new Error("Bad response from server");
					}
					return response.json();
				})
				.then(function (response) {
					console.log("Return with response");
					//var campID=response.body.campID;
					console.log(response);
					var campID = response.campID;
					console.log(campID);
					//alert("Successfully Campaign Delivery Format");
					//send campID to new page
					window.location.href = "/finishCampaign?campID=" + campID + "";
					if (data === "success") {
						console.log("Inside if loop");
						this.refs.msg.show("Some text or component", {
							time: 2000,
							type: "success",
							icon: <img src="path/to/some/img/32x32.png" />,
						});
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		if (!fields["campaignName"]) {
			formIsValid = false;
			errors["campaignName"] = "*Required.";
		}
		if (!fields["leadAllocation"]) {
			formIsValid = false;
			errors["leadAllocation"] = "*Required.";
		}
		if (!fields[" publisherCampID"]) {
			formIsValid = false;
			errors[" publisherCampID"] = "*Required.";
		}

		this.setState({
			errors: errors,
		});
		return formIsValid;
	}

	render() {
		/**
		 * @author Narendra Phadke
		 * @param  Description handle the login authentication
		 * @return Description return All details of authentication
		 */
		const { isAuthenticated, user } = this.props.auth;
		/*@author Narendra phadke
		 *Desc Fetch campID from Url
		 * dependencies query-string
		 */
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		console.log("Control on react page");
		console.log(campID);
		//alert(this.state.editDeliveryFormat[0]);
		var step3 =
			window.location.href.split("&")[
				window.location.href.split("?").length - 1
			];
		if (
			this.state.editDeliveryFormat[0] === undefined ||
			this.state.editDeliveryFormat[0] === "" ||
			this.state.editDeliveryFormat[0] === null
		) {
			step3 = "undefined";
		}
		if (
			step3 === undefined ||
			step3 === null ||
			step3 === "" ||
			step3 === "undefined"
		) {
			return (
				<div>
					<Navigation />
					<div
						class="row"
						style={{
							paddingTop: "90px",
							paddingBottom: "6px",
							backgroundColor: "white",
							border: "1px solid",
							borderColor: "#bfc7cc",
							width: "110%",
							position: "fixed",
							zIndex: "1",
						}}>
						<div class="col-lg-4" style={{ paddingLeft: "150px" }}>
							<button
								className="btn  btn-primary btn-block"
								type="button"
								onClick={this.handleBackButton}
								style={{
									width: "min-content",
									height: "26px",
									fontSize: "11px",
								}}>
								Back
							</button>
						</div>
						<div class="col-lg-4">
							<label class="labelfont">
								STEP 3 : Delivery Format(Campaign ID:{parsed.campID})
							</label>
						</div>
						<div class=" col-lg-4" class="createbutton">
							<button
								class="btn  btn-primary btn-block"
								type="submit"
								form="deliveryformat"
								style={{
									width: "min-content",
									height: "26px",
									fontSize: "11px",
								}}>
								Save & Next
							</button>
						</div>
					</div>

					{/* <div class="col" align="center" style={{marginTop:'90px'}}><label id="labelheading" >STEP 3 : Delivery Format(Campaign ID:{parsed.campID})</label></div> */}
					<div
						class="container-fluid"
						style={{ paddingBottom: "60px", paddingTop: "150px" }}>
						<form
							method="POST"
							id="deliveryformat"
							name="deliveryFormat"
							onSubmit={this.submitDeliveryFormat}>
							<div class="row">
								<div class="col-lg-2">
									<input
										type="checkbox"
										id="pID"
										name="pID"
										disabled
										value={this.state.pID}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Publisher ID</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id="campaignID"
										name="campaignID"
										disabled
										value={this.state.campaignID}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Campaign ID</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id="leadInteractionDate"
										name="leadInteractionDate"
										disabled
										value={this.state.leadInteractionDate}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Lead Interaction Date</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id="firstName"
										name="firstName"
										disabled
										value={this.state.firstName}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">First Name</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id="lastName"
										name="lastName"
										disabled
										value={this.state.lastName}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Last Name</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id="companyName"
										name="companyName"
										disabled
										value={this.state.companyName}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Company Name</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id="email"
										name="email"
										disabled
										value={this.state.email}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Email</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id="workPhone"
										name="workPhone"
										disabled
										value={this.state.workPhone}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Work Phone</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id="jobTitleDelivery"
										name="jobTitleDelivery"
										disabled
										value={this.state.jobTitleDelivery}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Job Title</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id="address"
										name="address"
										disabled
										value={this.state.address}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Address</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id="countryDelivery"
										name="countryDelivery"
										disabled
										value={this.state.countryDelivery}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Country</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="city"
										value={this.state.city}
										onChange={this.handleChangeCheck}
										disabled
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">City</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="state"
										disabled
										value={this.state.state}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">State</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="zipCode"
										disabled
										value={this.state.zipCode}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Zip Code</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id="companyEmployeeSize"
										name="companyEmployeeSize"
										disabled
										value={this.state.companyEmployeeSize}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Company Employee Size</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="industry"
										disabled
										value={this.state.industry}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Industry</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="assetName"
										disabled
										value={this.state.assetName}
										onChange={this.handleChangeCheck}
										defaultChecked
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Asset Name</label>
								</div>

								<div class="col-lg-2">
									{this.state.jobLevelDelivery == "Yes" ? (
										<input
											type="checkbox"
											id="jobLevelDelivery"
											name="jobLevelDelivery"
											value={this.state.jobLevelDelivery}
											onChange={this.handleChangeCheck}
											defaultChecked
										/>
									) : (
										<input
											type="checkbox"
											id="jobLevelDelivery"
											name="jobLevelDelivery"
											value={this.state.jobLevelDelivery}
											onChange={this.handleChangeCheck}
										/>
									)}
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Job Level</label>
								</div>

								<div class="col-lg-2">
									{this.state.jobFunctionDelivery == "Yes" ? (
										<input
											type="checkbox"
											id="jobFunctionDelivery"
											name="jobFunctionDelivery"
											value={this.state.jobFunctionDelivery}
											onChange={this.handleChangeCheck}
											defaultChecked
										/>
									) : (
										<input
											type="checkbox"
											id="jobFunctionDelivery"
											name="jobFunctionDelivery"
											value={this.state.jobFunctionDelivery}
											onChange={this.handleChangeCheck}
										/>
									)}
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Job Function</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="street"
										value={this.state.street}
										onChange={this.handleChangeCheck}
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Street</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="companyRevenue"
										value={this.state.companyRevenue}
										onChange={this.handleChangeCheck}
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Company Revenue</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id="ip"
										name="ip"
										value={this.state.ip}
										onChange={this.handleChangeCheck}
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">IP</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id="assetId"
										name="assetId"
										value={this.state.assetId}
										onChange={this.handleChangeCheck}
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Asset ID</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="extra1"
										value={this.state.extra1}
										onChange={this.handleChangeCheck}
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Extra 1</label>
								</div>
								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="extra2"
										value={this.state.extra2}
										onChange={this.handleChangeCheck}
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Extra 2</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="extra3"
										value={this.state.extra3}
										onChange={this.handleChangeCheck}
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Extra 3</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="extra4"
										value={this.state.extra4}
										onChange={this.handleChangeCheck}
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Extra 4</label>
								</div>

								<div class="col-lg-2">
									<input
										type="checkbox"
										id=""
										name="extra5"
										value={this.state.extra5}
										onChange={this.handleChangeCheck}
									/>
									&nbsp;&nbsp;&nbsp;&nbsp;
									<label id="label">Extra 5</label>
								</div>
							</div>
							<br />
							{/* <div class="row">
             
              <div class="col-sm-3 col-md-3 col-lg-3">
            <label id="label" style={{color:'orange'}}>Custom Question 1</label>
                      <textarea  id="customQuestion1" class="form-control" name="customQuestion1"
                      value={this.state.customQuestion1} onChange={this.handleChange}/>
            </div>

            <div class="col-sm-3  col-md-3 col-lg-3">
            <label id="label">Answer 1</label>
                      <textarea  id="answer1" class="form-control" name="answer1"
                      value={this.state.answer1} onChange={this.handleChange}/>
            </div>

            <div class="col-sm-3 col-md-3 col-lg-3">
            <label id="label" style={{color:'orange'}}>Custom Question 2</label>
                    <textarea  id="customQuestion2" name="customQuestion2" class="form-control"
                    value={this.state.customQuestion2} onChange={this.handleChange}/>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
            <label id="label">Answer 2</label>
                    <textarea  id="answer2" name="answer2" class="form-control"
                    value={this.state.answer2} onChange={this.handleChange}/>
            </div>
              </div>
           

            <div class="row">
              
              <div class="col-sm-3 col-md-3 col-lg-3">
              
                    <label id="label" style={{color:'orange'}}>Custom Question 3</label>
                      <textarea  id="customQuestion3" name="customQuestion3" class="form-control"
                      value={this.state.customQuestion3} onChange={this.handleChange}/>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
            <label id="label">Answer 3</label>
                      <textarea  id="answer3" name="answer3" class="form-control"
                      value={this.state.answer3} onChange={this.handleChange}/>
            </div>


            <div class="col-sm-3 col-md-3 col-lg-3">
            <label id="label" style={{color:'orange'}}>Custom Question 4</label>
                    <textarea  id="customQuestion4" name="customQuestion4" class="form-control"
                    value={this.state.customQuestion4} onChange={this.handleChange}/>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
            <label id="label">Answer 4</label>
                    <textarea  id="answer4" name="answer4" class="form-control"
                    value={this.state.answer4} onChange={this.handleChange}/>
            </div>
             
              </div>
              <div class="row">
              
              <div class="col-sm-3 col-md-3 col-lg-3">
            <label id="label" style={{color:'orange'}}>Custom Question 5</label>
                      <textarea  id="customQuestion5" name="customQuestion5" class="form-control"
                      value={this.state.customQuestion5} onChange={this.handleChange}/>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
            <label id="label">Answer 5</label>
                      <textarea  id="answer5" name="answer5" class="form-control"
                      value={this.state.answer5} onChange={this.handleChange}/>
            </div>

            <div class="col-sm-3 col-md-3 col-lg-3">
            <label id="label" style={{color:'orange'}}>Custom Question 6</label>
                    <textarea  id="customQuestion6" name="customQuestion6" class="form-control" 
                    value={this.state.customQuestion6} onChange={this.handleChange}/>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
            <label id="label">Answer 6</label>
                    <textarea  id="answer6" name="answer6" class="form-control" 
                    value={this.state.answer6customQuestion6} onChange={this.handleChange}/>
            </div>

            <div class="col-lg-12">
            <label id="label">Additional Comments</label> 
              <textarea  id="additionalComments" name="additionalComments" class="form-control" style={{width:'100%'}}  
              value={this.state.additionalComments} onChange={this.handleChange}/>
            </div>
              </div>
             */}

							<br />
							{/* <div class="row">
<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  
                  <div class="float-left">
                  <button className="btn  btn-primary btn-block text-uppercase" onClick={this.handleBackButton}>Back</button>
                    </div>
                    <div class="float-right">
                    <button class="btn  btn-primary btn-block text-uppercase" type="submit">Save and Next</button>
                    </div>
                    </div>
                </div> */}
						</form>
					</div>
					<Footer />
				</div>
			);
		} else {
			return (
				<div>
					<Navigation />

					<div
						class="row"
						style={{
							paddingTop: "90px",
							paddingBottom: "6px",
							backgroundColor: "white",
							border: "1px solid",
							borderColor: "#bfc7cc",
							width: "110%",
							position: "fixed",
							zIndex: "1",
						}}>
						<div class=" col-lg-4" style={{ paddingLeft: "150px" }}>
							<button
								className="btn  btn-primary btn-block"
								type="button"
								onClick={this.handleBackButton}
								style={{
									width: "min-content",
									height: "26px",
									fontSize: "11px",
								}}>
								Back
							</button>
						</div>
						<div class="col-lg-4">
							<label class="labelfont">
								STEP 3 : Delivery Format(Campaign ID:{parsed.campID})
							</label>
						</div>
						<div class=" col-lg-4" class="createbutton">
							<button
								class="btn  btn-primary btn-block"
								type="submit"
								form="deliveryformatback"
								style={{
									width: "min-content",
									height: "26px",
									fontSize: "11px",
								}}>
								Save & Next
							</button>
						</div>
					</div>

					{/* <div class="col" align="center" style={{paddingTop:"90px"}}><label id="labelheading" >STEP 3 : Delivery Format(Campaign ID:{parsed.campID})</label></div> */}
					<div
						class="container-fluid"
						style={{ paddingBottom: "60px", paddingTop: "150px" }}>
						<form
							method="POST"
							id="deliveryformatback"
							name="deliveryFormat"
							onSubmit={this.submitDeliveryFormat}>
							{this.state.editDeliveryFormat.map((editDeliveryFormat) => {
								return (
									<div class="row">
										<div class="col-lg-2">
											<input
												type="checkbox"
												id="pID"
												name="pID"
												disabled
												defaultChecked={editDeliveryFormat.pID === "Yes"}
												onChange={this.handleChecked}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Publisher ID</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="campaignID"
												name="campaignID"
												disabled
												defaultChecked={editDeliveryFormat.campaignID === "Yes"}
												onChange={this.handleChecked}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Campaign ID</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="leadInteractionDate"
												name="leadInteractionDate"
												disabled
												defaultChecked={
													editDeliveryFormat.leadInteractionDate == "Yes"
												}
												onChange={this.handleChecked}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Lead Interaction Date</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="firstName"
												name="firstName"
												disabled
												defaultChecked={editDeliveryFormat.firstName === "Yes"}
												onChange={this.handleChecked}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">First Name</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="lastName"
												name="lastName"
												onChange={this.handleChecked}
												disabled
												defaultChecked={editDeliveryFormat.lastName === "Yes"}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Last Name</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="companyName"
												name="companyName"
												onChange={this.handleChecked}
												defaultChecked={
													editDeliveryFormat.companyName === "Yes"
												}
												disabled
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Company Name</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="email"
												name="email"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.email === "Yes"}
												disabled
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Email</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="workPhone"
												name="workPhone"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.workPhone === "Yes"}
												disabled
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Work Phone</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="jobTitleDelivery"
												name="jobTitle"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.jobTitle === "Yes"}
												disabled
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Job Title</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="address"
												name="address"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.address === "Yes"}
												disabled
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Address</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="countryDelivery"
												name="country"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.country === "Yes"}
												disabled
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Country</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="city"
												value={this.state.city}
												name="city"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.city === "Yes"}
												disabled
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">City</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id=""
												name="state"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.state === "Yes"}
												disabled
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">State</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id=""
												name="zipCode"
												disabled
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.zipCode === "Yes"}
												disabled
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Zip Code</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="companyEmployeeSize"
												name="companyEmployeeSize"
												onChange={this.handleChecked}
												disabled
												defaultChecked={
													editDeliveryFormat.companyEmployeeSize === "Yes"
												}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Company Employee Size</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id=""
												name="industry"
												disabled
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.industry === "Yes"}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Industry</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="assetName"
												name="assetName"
												onChange={this.handleChecked}
												disabled
												defaultChecked={editDeliveryFormat.assetName === "Yes"}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Asset Name</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id=""
												name="street"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.street === "Yes"}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Street</label>
										</div>
										<div class="col-lg-2">
											{this.state.jobLevelDelivery == "Yes" ? (
												<input
													type="checkbox"
													id="jobLevelDelivery"
													name="jobLevel"
													onChange={this.handleChecked}
													value={this.state.jobLevelDelivery}
													defaultChecked
												/>
											) : (
												<input
													type="checkbox"
													id="jobLevelDelivery"
													name="jobLevel"
													onChange={this.handleChecked}
													value={this.state.jobLevelDelivery}
												/>
											)}
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Job Level</label>
										</div>

										<div class="col-lg-2">
											{this.state.jobFunctionDelivery == "Yes" ? (
												<input
													type="checkbox"
													id="jobFunctionDelivery"
													name="jobFunction"
													onChange={this.handleChecked}
													value={this.state.jobFunctionDelivery}
													defaultChecked
												/>
											) : (
												<input
													type="checkbox"
													id="jobFunctionDelivery"
													name="jobFunction"
													onChange={this.handleChecked}
													value={this.state.jobFunctionDelivery}
												/>
											)}
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Job Function</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												disabled
												id=""
												name="companyRevenue"
												onChange={this.handleChecked}
												defaultChecked={
													editDeliveryFormat.companyRevenue === "Yes"
												}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Company Revenue</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="ip"
												name="ip"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.ip === "Yes"}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">IP</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id="assetId"
												name="supportDocID"
												onChange={this.handleChecked}
												defaultChecked={
													editDeliveryFormat.supportDocID === "Yes"
												}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Asset ID</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id=""
												name="extra1"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.extra1 === "Yes"}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Extra 1</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id=""
												name="extra2"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.extra2 === "Yes"}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Extra 2</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id=""
												name="extra3"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.extra3 === "Yes"}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Extra 3</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id=""
												name="extra4"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.extra4 === "Yes"}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Extra 4</label>
										</div>

										<div class="col-lg-2">
											<input
												type="checkbox"
												id=""
												name="extra5"
												onChange={this.handleChecked}
												defaultChecked={editDeliveryFormat.extra5 === "Yes"}
											/>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<label id="label">Extra 5</label>
										</div>
									</div>
								);
							})}

							<br />

							{this.state.editDeliveryFormat.map((editDeliveryFormat) => {
								return (
									<div class="row">
										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label" style={{ color: "orange" }}>
												Custom Question 1
											</label>
											<textarea
												id="customQuestion1"
												class="form-control"
												name="customQuestion1"
												defaultValue={editDeliveryFormat.customQuestion1}
												onChange={this.handleCheckedCustom}
											/>
										</div>

										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label">Answer 1</label>
											<textarea
												id="answer1"
												class="form-control"
												name="answer1"
												defaultValue={editDeliveryFormat.answer1}
												onChange={this.handleCheckedCustom}
											/>
										</div>

										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label" style={{ color: "orange" }}>
												Custom Question 2
											</label>
											<textarea
												id="customQuestion2"
												name="customQuestion2"
												class="form-control"
												defaultValue={editDeliveryFormat.customQuestion2}
												onChange={this.handleCheckedCustom}
											/>
										</div>

										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label">Answer 2</label>
											<textarea
												id="answer2"
												name="answer2"
												class="form-control"
												defaultValue={editDeliveryFormat.answer2}
												onChange={this.handleCheckedCustom}
											/>
										</div>
									</div>
								);
							})}

							{this.state.editDeliveryFormat.map((editDeliveryFormat) => {
								return (
									<div class="row">
										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label" style={{ color: "orange" }}>
												Custom Question 3
											</label>
											<textarea
												id="customQuestion3"
												name="customQuestion3"
												class="form-control"
												defaultValue={editDeliveryFormat.customQuestion3}
												onChange={this.handleCheckedCustom}
											/>
										</div>
										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label">Answer 3</label>
											<textarea
												id="answer3"
												name="answer3"
												class="form-control"
												defaultValue={editDeliveryFormat.answer3}
												onChange={this.handleCheckedCustom}
											/>
										</div>

										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label" style={{ color: "orange" }}>
												Custom Question 4
											</label>
											<textarea
												id="customQuestion4"
												name="customQuestion4"
												class="form-control"
												defaultValue={editDeliveryFormat.customQuestion4}
												onChange={this.handleCheckedCustom}
											/>
										</div>

										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label">Answer 4</label>
											<textarea
												id="answer4"
												name="answer4"
												class="form-control"
												defaultValue={editDeliveryFormat.answer4}
												onChange={this.handleCheckedCustom}
											/>
										</div>
									</div>
								);
							})}

							{this.state.editDeliveryFormat.map((editDeliveryFormat) => {
								return (
									<div class="row">
										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label" style={{ color: "orange" }}>
												Custom Question 5
											</label>
											<textarea
												id="customQuestion5"
												name="customQuestion5"
												class="form-control"
												defaultValue={editDeliveryFormat.customQuestion5}
												onChange={this.handleCheckedCustom}
											/>
										</div>
										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label">Answer 5</label>
											<textarea
												id="answer5"
												name="answer5"
												class="form-control"
												defaultValue={editDeliveryFormat.answer5}
												onChange={this.handleCheckedCustom}
											/>
										</div>

										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label" style={{ color: "orange" }}>
												Custom Question 6
											</label>
											<textarea
												id="customQuestion6"
												name="customQuestion6"
												class="form-control"
												defaultValue={editDeliveryFormat.customQuestion6}
												onChange={this.handleCheckedCustom}
											/>
										</div>

										<div class="col-sm-3 col-md-3 col-lg-3">
											<label id="label">Answer 6</label>
											<textarea
												id="answer6"
												name="answer6"
												class="form-control"
												defaultValue={editDeliveryFormat.answer6}
												onChange={this.handleCheckedCustom}
											/>
										</div>
										<div class="col-lg-12">
											<label id="label">Additional Comments</label>
											<textarea
												id="additionalComments"
												name="additionalComments"
												class="form-control"
												style={{ width: "100%" }}
												defaultValue={editDeliveryFormat.additionalComments}
												onChange={this.handleCheckedCustom}
											/>
										</div>
									</div>
								);
							})}

							<br />
							{/* 
<div class="row">
  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
  <div class="float-left">
  <button className="btn  btn-primary btn-block text-uppercase" onClick={this.handleBackButton} >Back</button>
    </div>
    <div class="float-right">
    <button class="btn  btn-primary btn-block text-uppercase" type="submit">Save and Next</button>
    </div>
  </div>
</div> */}
						</form>
					</div>

					<Footer />
				</div>
			);
		}
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
DeliveryFormat.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(DeliveryFormat)
);
