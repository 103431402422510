/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file Request For Proposal Progress
 * @author  Karan Jagtap
 * @version 1.0
 * @section Request For Proposal Progress UI use
 * */
import React, { Component } from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../login/authentication";
import "./rfpProgress.css";
//import moment from "moment"; rutuja task 2832- commented moment as never used in the file to remove console warning
import { Table, Checkbox, Tooltip, DatePicker } from "antd";
import { css } from "emotion";
import * as $ from "jquery";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3796-vapt files auth header

/**
 * @author Karan Jagtap
 * @description Task-3636 all required constants for this file
 */
const Swal = require("sweetalert2");
const TABLE_CSS = css({
	borderStyle: "bold !important",
	backgroundColor: "white !important",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "12px",
		textAlign: "center",
		fontWeight: "500",
	},
	"& thead > tr > th": {
		textAlign: "center",
		color: "white",
		backgroundColor: "transparent",
	},
	"& tbody > tr > td": {
		fontSize: "12px",
	},
	"& .ant-table-scroll .ant-table-body": {
		overflowX: "auto !important",
	},
	"& thead > tr > th .ant-table-header-column .ant-table-column-sorters .ant-table-column-sorter":
		{ display: "table-cell !important" },
	"& .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full":
	{
		marginTop: "-0.5em",
	},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-down.ant-table-column-sorter-down.on > svg":
	{
		backgroundColor: "transparent !important",
		color: "white !important",
	},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-up.ant-table-column-sorter-up.on > svg":
	{
		backgroundColor: "transparent !important",
		color: "white !important",
	},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-up.ant-table-column-sorter-up.off > svg":
	{
		backgroundColor: "transparent !important",
		color: "#6a88bb !important",
	},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-down.ant-table-column-sorter-down.off > svg":
	{
		backgroundColor: "transparent !important",
		color: "#6a88bb !important",
	},
});

/**
 * @author Karan Jagtap
 * @description Task-3636 Main class for rfpProgress.js file
 */
class RFPProgress extends Component {
	constructor(props) {
		super();
		/**
		 * @author Karan Jagtap
		 * @description Task-3636 setting all reqired variables
		 */
		this.state = {
			tabs: [
				{
					name: "Active",
					id: "active",
					count: 0,
					backgroundColor: "green",
				},
				{
					name: "Closed",
					id: "closed",
					count: 0,
					backgroundColor: "#ffaf42",
				},
				{
					name: "Archive",
					id: "archive",
					count: 0,
					backgroundColor: "blue",
				},
			],
			activeTab: "active",
			selectedFilter: "All",
			selectedFilterLabel: "All",
			activeData: [],
			createdData: [],
			closedData: [],
			avoidingData: [],
			tableData: [],
			recordsPerPage: 100,
			searchedText: "",
			loading: true,
			actionPopupVisible: false,
			x: 0,
			y: 0,
			actionCampID: -1,
			btnSubmitPublisher: false,
			publisherList: [],
			errors: {},
			parsedAdvertiserID: "",
			parsedFromDate: "",
			parsedToDate: "",
			actionName: "",
			endDate: "",
			actioncampID1: "",
			biddingEndDate: "",
			alertBorder: "",
			alertDisplay: "",	
		};
		//rutuja task-4323 clone RFP code was missed added the code for clone RFP 
		this.cloneCampaign = this.cloneCampaign.bind(this); //shivani-3800- binded this clone handlechange which is used for the clone option .
	}

	/**
		  * @author Shivani Pathak-3800
		  * @description Added below handlechange for clone RFP.
		  */
	cloneCampaign(e) {
		e.preventDefault();
		const { user } = this.props.auth;
		var agencyID = user.id;
		var campID = this.state.actionCampID;
		this.setState({ agencyID: agencyID, campID });

		Swal.fire({
			title: "Are you sure you want to clone " + campID + "RFP ?", //rutuja 2832 made changes in title for removing console warning
			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#d33",
			confirmButtonColor: "#3085d6",
			allowOutsideClick: false,
			confirmButtonText: "Clone",
		}).then((result) => {
			if (result.value) {
				let data = {
					//user: user, // rutuja Task - 4323 User removed data show in payload
					campID: campID
				}
				fetch("/rfpDashboardBE/createCloneOfRFPCampaign", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						authorization: this.props.auth.user.token,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((response) => {
						Swal.fire({
							html: 'RFP Cloned Successfully. Proposal ID :' + response.campID,
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								this.fetchActiveData();
								window.location.reload();
							}
						})
					})
					.catch(function (err) {
						console.log(err);
					});
			}

		});
	}


	/**
	 * @author Saurabh Adhav
	 * @description Task-3775 Creating Will Mount
	 */
	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			this.completeRfpMark();
		}
	}
	/**
	 * @author Saurabh Adhav
	 * @description Task-3775 Archive data
	 */
	completeRfpMark = () => {
		fetch("/rfpDashboardBE/completeRFPMark", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			}, //karan-task-3803-vapt header and query params
		})
			.then((res) => res.json())
			.then((res) => {
				this.setState(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	/**
	 * @author Karan Jagtap
	 * @description Task-3636 calling of apis needed for this page
	 */
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			//karan-task-3803-vapt header and query params
			if (this.props.location.state !== undefined) {
				const {
					status,
					fromDate,
					toDate,
					advertiserID,
					actionName,
					publisherID,
				} = this.props.location.state;
				if (status !== undefined) {
					// if came from dashboard
					let advertiserFlag = true;
					if (advertiserID === undefined) {
						advertiserFlag = false;
					}
					this.setState(
						{
							selectedFilter: "byDashboard",
							activeTab: status,
							parsedAdvertiserID: advertiserID,
							parsedFromDate: fromDate,
							parsedToDate: toDate,
						},
						() => {
							this.fetchTabCounts(
								advertiserFlag ? advertiserID : publisherID,
								fromDate,
								toDate,
								status,
								"",
								false,
								advertiserFlag
							);
							if (status === "active") {
								this.fetchActiveData(
									advertiserFlag ? advertiserID : publisherID,
									fromDate,
									toDate,
									advertiserFlag
								);
							} else if (status === "archive") {
								this.fetchCreatedData(
									advertiserFlag ? advertiserID : publisherID,
									fromDate,
									toDate,
									advertiserFlag
								);
							} else if (status === "closed") {
								this.fetchClosedData(
									advertiserFlag ? advertiserID : publisherID,
									fromDate,
									toDate,
									advertiserFlag
								);
							} else if (this.state.activeTab === "rfp_avoiding") {
								// this is temporary
								// it will be changed when api call is made
								this.setState({ tableData: [] });
							}
						}
					);
				} else if (actionName !== undefined) {
					// if came from dashboard by clicking on operational actions
					this.fetchOperationRFPData(actionName);
				}
			} else {
				// if directly came here by clicking on menu
				this.fetchTabCounts();
				this.fetchActiveData();
			}
		}
	}

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 handles when clicked on Action dots (3-dots)
	 */
	onActionClick = (
		e,
		campID,
		status,
		campExists,
		startDate,
		endDate,
		biddingEndDate
	) => {
		e.preventDefault();
		if (!this.state.actionPopupVisible) {
			const self = this;
			document.addEventListener(`click`, function onClickOutside() {
				self.setState({ actionPopupVisible: false, actionCampID: -1 });
				document.removeEventListener(`click`, onClickOutside);
			});
		}
		this.setState({
			x: e.clientX,
			y: e.clientY + window.pageYOffset,
			actionPopupVisible: true,
			actionCampID: campID,
			actionStatus: status,
			actionCampExists: campExists,
			actionStartDate: startDate,
			actionEndDate: endDate,
			actioncampID1: campID,
			actionBiddingEndDate: biddingEndDate,
		});
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 handles individual action item click
	 */
	handeContextMenuItemClick = (e) => {
		if (e.currentTarget.id === "send_to_publisher") {
			this.showPublisher();
		} else if (e.currentTarget.id === "edit_rfp") {//Somnath Task-3936, Add URL in history Object
			this.props.history.push(`/requestForProposal2`,{rfpCampaignID:this.state.actionCampID,from:`rfpProgress`})
		} else if (e.currentTarget.id === "create_rfp_campaign") {
			this.props.history.push("/createcampaign2", {
				rfpCampaignID: this.state.actionCampID,
			}); // karan-task-3767-replace query params
		} else if (e.currentTarget.id === "live_rfp") {
		}
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3636- this code is directly copy-pasted from requestForProposalExisting.js file - it fetches publishers list and displays
	 * @originalauthor : unknown
	 */
	showPublisher = () => {
		var campID = this.state.actionCampID;
		// Get RFP Publisher Details
		fetch("agencyDashboard/GetRFPPublisher?campID=" + campID) //karan-task-3803-vapt header and query params
			.then((res) => res.json())
			.then((publisherList) => {
				let temp = publisherList.filter((a) => {
					if (
						a.status === "" || //rutuja 2832
						a.status ==="null" || //rutuja 2832
						a.status == null ||
						a.status == undefined
					) {
					} else {
						return a;
					}
				});
				if (temp.length == publisherList.length) {
					this.setState({ btnSubmitPublisher: true });
				} else {
					this.setState({ btnSubmitPublisher: false });
				}
				this.setState({ publisherList: publisherList });
			})
			.catch(function (err) {
				console.log(err);
			});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3636- this code is directly copy-pasted from requestForProposalExisting.js file - it submits the publishers list
	 * @originalauthor : unknown
	 */
	submitPublisherList = (e) => {
		//const { user } = this.props.auth; //rutuja 2832 commented a never used in the file
		let errors = {};
		let publisherValidate = true; 

		var pubList = this.state.publisherList.filter(function (a) {
			return a.Checked == true && a.status !== "Assign";
		});

		if (pubList.length == 0) {
			errors["unchecked"] = "Please Select Minimum One Publisher";
			this.setState({ errors: errors });
		let	publisherValidate = false; 
			$("#myModal").modal("show");
			//Sandeep-task-3227-added code to show modal when error
		} else {
			errors["unchecked"] = "";
			this.setState({ errors: errors }); 
			let publisherValidate = true;
			$("#myModal").modal("hide"); // karan-task-3636- made this small change in original code, because original code had a bug in it. Bug was confirmed by Deepika
		}
		//$("#myModal").modal("hide");//commented by karan-task-3636
		//Sandeep-task-3227-added code to hide modal when no error
		var data = {
			pubList: this.state.publisherList,
		}; //karan-task-3803-vapt header and query params

		fetch("agencyDashboard/submitRFPPubList", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then(function (response) {
				if (response.status >= 400) {
					throw new Error("Bad response from server");
				} else {
					Swal.fire({
						text: "Request For Proposal Sent To Publisher Successfully",
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
				}
			})
			.catch(function (err) {
				console.log(err);
			});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3636- this code is directly copy-pasted from requestForProposalExisting.js file - it handles checkbox click
	 * @originalauthor : unknown
	 */
	handleCheckPublisher = (i, e) => {
		const { name, checked } = e.target;
		let publisherList = [...this.state.publisherList];
		publisherList[i] = { ...publisherList[i], [name]: checked };
		this.state.publisherList = [...publisherList];
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 redirects to rfpCampaignSpecification page when clicked on name
	 */
	onRFPNameClick = (campID) => {
		this.props.history.push("/rfpCampaignSpecification",{proposalID: campID,step:3}); //sonali-task-3716-replace query params
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 fetches all tab counts
	 * @route /rfpDashboardBE/rfpCampaignCount
	 */
	fetchTabCounts = (
		advertiserName = "",
		startDate = "",
		endDate = "",
		tab = this.state.activeTab,
		actionName = "",
		fromAction = false,
		advertiserFlag = true
	) => {
		const data = {
			pacing: this.state.selectedFilter,
			advertiserName,
			startDate,
			endDate,
			tab,
			actionName,
			fromAction,
			byAdvertiser: advertiserFlag,
			publisherId: advertiserName,
		}; //karan-task-3803-vapt header and query params
		fetch("/rfpDashboardBE/rfpCampaignCount", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			}, //karan-task-3803-vapt header and query params
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				this.setState({
					tabs: this.state.tabs.map((tab) => {
						if (tab.id === "active" && !fromAction) {
							tab.count = res[0].activeCount;
						} else if (tab.id === "archive") {
							tab.count = res[0].createdCount;
						} else if (tab.id === "closed") {
							tab.count = res[0].closedCount;
						}
						return tab;
					}),
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 fetches active tab data
	 * @route /rfpDashboardBE/rfpActiveCampaign
	 */
	fetchActiveData = (
		advertiserName = "",
		startDate = "",
		endDate = "",
		advertiserFlag = true
	) => {
		this.setState({ loading: true });
		const data = {
			pacing: this.state.selectedFilter,
			advertiserName,
			startDate,
			endDate,
			byAdvertiser: advertiserFlag,
			publisherId: advertiserName,
		}; //karan-task-3803-vapt header and query params
		fetch("/rfpDashboardBE/rfpActiveCampaign", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			}, //karan-task-3803-vapt header and query params
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				this.setState({
					activeData: this.addKeysToEachObject(res),
					tableData: this.addKeysToEachObject(res),
					loading: false,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ loading: false });
			});
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 fetches Campaign created tab data
	 * @route /rfpDashboardBE/rfpCampaignCreated
	 */
	fetchCreatedData = (
		advertiserName = "",
		startDate = "",
		endDate = "",
		advertiserFlag = true
	) => {
		this.setState({ loading: true });
		const data = {
			pacing: this.state.selectedFilter,
			advertiserName,
			startDate,
			endDate,
			byAdvertiser: advertiserFlag,
			publisherId: advertiserName,
		}; //karan-task-3803-vapt header and query params
		fetch("/rfpDashboardBE/rfpCampaignCreated", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			}, //karan-task-3803-vapt header and query params
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				this.setState({
					createdData: this.addKeysToEachObject(res),
					tableData: this.addKeysToEachObject(res),
					loading: false,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ loading: false });
			});
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 fetches closed tab data
	 * @route /rfpDashboardBE/rfpClosedCampaign
	 */
	fetchClosedData = (
		advertiserName = "",
		startDate = "",
		endDate = "",
		advertiserFlag = true
	) => {
		this.setState({ loading: true });
		const data = {
			pacing: this.state.selectedFilter,
			advertiserName,
			startDate,
			endDate,
			byAdvertiser: advertiserFlag,
			publisherId: advertiserName,
		}; //karan-task-3803-vapt header and query params
		fetch("/rfpDashboardBE/rfpClosedCampaign", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			}, //karan-task-3803-vapt header and query params
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				this.setState({
					closedData: this.addKeysToEachObject(res),
					tableData: this.addKeysToEachObject(res),
					loading: false,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ loading: false });
			});
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 fetches operational action rfp data
	 * @route /rfpDashboardBE/operationalActionRFP
	 */
	fetchOperationRFPData = (actionName) => {
		this.setState({ loading: true });
		const data = {
			flag: actionName,
		};
		fetch("/rfpDashboardBE/operationalActionRFP", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			}, //karan-task-3803-vapt header and query params
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				this.setState(
					{
						actionName,
						activeData: this.addKeysToEachObject(res),
						tableData: this.addKeysToEachObject(res),
						loading: false,
						tabs: this.state.tabs.map((tab) => {
							if (tab.id === "active") {
								tab.count = res.length;
							}
							return tab;
						}),
					},
					() => this.fetchTabCounts("", "", "", "", actionName, true)
				);
			})
			.catch((err) => {
				console.log(err);
				this.setState({ loading: false });
			});
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3661 add keys to data
	 */
	addKeysToEachObject = (data) => {
		let res = [];
		res = data.map((item, i) => {
			return {
				...item,
				key: i,
			};
		});
		return res;
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 handles tab clicks
	 */
	handleTabChange = (e) => {
		let pacing = "All";
		let pacingLabel = "All";
		//let countFlag = false; //rutuja 2832 commented countFlag as never used in the file
		// karan-task-3677-code commented because now filter should reset to default on tab change
		// if (
		// 	this.state.selectedFilter !== "byDashboard" &&
		// 	this.state.actionName === ""
		// ) {
		// 	pacing = this.state.selectedFilter;
		// 	pacingLabel = this.state.selectedFilterLabel;
		// } else {
		// 	countFlag = true;
		// }
		// if (
		// 	this.state.selectedFilter === "byDashboard" ||
		// 	this.state.actionName !== ""
		// ) {
		// 	countFlag = true;
		// }
		this.setState(
			{
				activeTab: e.currentTarget.id,
				searchedText: "",
				selectedFilter: pacing,
				selectedFilterLabel: pacingLabel,
			},
			() => {
				// karan-task-3677-code commented because now on every tab change the count should also update
				// if (countFlag) {
				// 	this.fetchTabCounts();
				// }
				this.fetchTabCounts();
				if (this.state.activeTab === "active") {
					this.fetchActiveData();
				} else if (this.state.activeTab === "archive") {
					this.fetchCreatedData();
				} else if (this.state.activeTab === "closed") {
					this.fetchClosedData();
				} else if (this.state.activeTab === "rfp_avoiding") {
					// this is temporary
					// it will be changed when api call is made
					this.setState({ tableData: [] });
				}
			}
		);
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 handles dropdown filter change
	 */
	handleFilterChange = (e) => {
		this.setState(
			{
				searchedText: "",
				selectedFilterLabel: e.target.getAttribute("label"),
				selectedFilter: e.target.id,
			},
			() => {
				this.fetchTabCounts();
				if (this.state.activeTab === "active") {
					this.fetchActiveData();
				} else if (this.state.activeTab === "archive") {
					this.fetchCreatedData();
				} else if (this.state.activeTab === "closed") {
					this.fetchClosedData();
				} else if (this.state.activeTab === "rfp_avoiding") {
					// this is temporary
					// it will be changed when api call is made
					this.setState({ tableData: [] });
				}
			}
		);
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 handles searching of proposal id and rfp name from currently active tab data
	 */
	//Somnath Task-3972, Add changes to Search box
	handleSearchText = (e) => {
		let filterData = e.target.value;
		let newList = [], currentList = [];
		if (this.state.activeTab === "active") {
			newList = [...this.state.activeData];
		} else if (this.state.activeTab === "archive") {
			newList = [...this.state.createdData];
		} else if (this.state.activeTab === "closed") {
			newList = [...this.state.closedData];
		}
		if (filterData !== "") {
			currentList = newList;
			newList = currentList.filter((item) => {
				let obj = {};//Somnath Task-3972, Add specific Keys in search criteria
				obj[`campID`] = item.campID;
				obj[`campaignName`] = item.campaignName;
				obj[`startDate`] = item.startDate;
				obj[`endDate`] = item.endDate;
				obj[`campaignType`] = item.campaignType;
				obj[`biddingEndDate`] = item.biddingEndDate;
				obj[`CPL`] = item.CPL;
				obj[`leadAllocation`] = item.leadAllocation;
				obj[`status`] = item.status;
				const lc = obj;
				filterData = filterData.toLowerCase();
				var data = Object.values(lc);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filterData);
			});
		}

		this.setState({
			searchedText: filterData,
			tableData: newList
		});
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 handles dropdown for records per page change
	 */
	handleRecordsPerPage = (e) => {
		this.setState({ recordsPerPage: e.target.value });
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3636 redirects to create rfp page
	 */
	redirectToCreateRFP = (e) => {
		e.preventDefault();
		//Somnath Task-3936, Add URL in history Object
		this.props.history.push(`/requestForProposal2`,{from:`rfpProgress`})
	};
	/**
	 * @author Saurabh Adhav
	 * @description Task-3789 Archive RFP Date change
	 */
	handleChangeDate(value, dateString) {
		this.setState({ biddingEndDate: dateString });
	}
	/**
	 * @author Saurabh Adhav
	 * @description Task-3789 Archive RFP Date change
	 */
	handleRefreshPage(e) {
		//const { user } = this.props.auth; //rutuja 2832 commented user as never used in the file
		//var agencyID = user.id; //rutuja 2832 commented agecny id as never used in the file

		this.props.history.go(0); //karan-task-3684-replace query params
	}
	/**
	 * @author Saurabh Adhav
	 * @description Task-3789 Archive RFP
	 */
	archiveRfp = (e) => {
		const { user } = this.props.auth;
		let errors = {};
		let archiveRfpValidation = true;
//rutuja 2832
		if (
			this.state.biddingEndDate === "" || 
			this.state.biddingEndDate === "null" ||
			this.state.biddingEndDate == null
		) {
			errors["errmsg1"] = "Please Select Bidding End Date";
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertDisplay: "block",
			});
			archiveRfpValidation = false;
			$("#live_rfp_model").modal("show");
		} else if (Date.parse(this.state.biddingEndDate) <= Date.now()) {
			errors["errmsg2"] = "Bidding date has to be greater than current date";
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertDisplay: "block",
			});
			archiveRfpValidation = false;
			$("#live_rfp_model").modal("show");
		} else if (this.state.actionStartDate < this.state.biddingEndDate) {
			errors["errmsg3"] = "Bidding date can't be greater than start date";
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertDisplay: "block",
			});
			archiveRfpValidation = false;
			$("#live_rfp_model").modal("show");
		} else {
			errors["notSelected"] = "";
			this.setState({ errors: errors });
			archiveRfpValidation = true;
			$("#live_rfp_model").modal("hide");
		}
		var data = {
			user: user,
			campID: this.state.actioncampID1,
			biddingEndDate: this.state.biddingEndDate,
		};
		if (archiveRfpValidation == true) {
			fetch("rfpDashboardBE/archiveToLiveRFP", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					authorization: this.props.auth.user.token,
				},
				body: JSON.stringify(data),
			})
				.then(function (response) {
					if (response.status >= 400) {
						throw new Error("Bad response from server");
					} else {
						Swal.fire({
							text: "RFP Archved successfully.",
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								window.location.reload();
							},
						});
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	};

	render() {
		const TABLE_COLUMNS = [
			{
				title: "Proposal ID",
				dataIndex: "campID",
				key: "campID",
				width: "100px",
				sorter: (a, b) => a.campID - b.campID,
			},
			{
				title: "RFP Name",
				dataIndex: "campaignName",
				key: "campaignName",
				width: "160px",
				sorter: (a, b) =>
					a.campaignName.toLowerCase() < b.campaignName.toLowerCase() ? -1 : 1,
				render: (text, record) => (
					<div style={{ width: 160 }}>
						<a
								style={{
								color: "#1890ff",
								display: "block",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								minWidth: 160,
							}}
							onClick={(e) => this.onRFPNameClick(record.campID)}> 
							{text}
						</a>
					</div>
				),
			},
			{
				title: "Camp Type",
				dataIndex: "campaignType",
				key: "campaignType",
				width: "100px",
				sorter: (a, b) =>
					a.campaignName.toLowerCase() < b.campaignName.toLowerCase() ? -1 : 1,
			},
			{
				title: "Start Date",
				dataIndex: "startDate",
				key: "startDate",
				width: "90px",
				sorter: (a, b) => (a.startDate < b.startDate ? -1 : 1),
			},
			{
				title: "End Date",
				dataIndex: "endDate",
				key: "endDate",
				width: "90px",
				sorter: (a, b) => (a.endDate < b.endDate ? -1 : 1),
			},
			{
				title: "Bidding Closes",
				dataIndex: "biddingEndDate",
				key: "biddingEndDate",
				width: "140px",
				sorter: (a, b) => (a.biddingEndDate < b.biddingEndDate ? -1 : 1),
			},
			{
				title: "Total Leads",
				dataIndex: "leadAllocation",
				key: "leadAllocation",
				width: "100px",
				sorter: (a, b) => a.leadAllocation - b.leadAllocation,
			},
			{
				title: "CPL",
				dataIndex: "CPL",
				key: "CPL",
				width: "60px",
				sorter: (a, b) => a.CPL - b.CPL,
				render: (text, record) => (
					<div>
						<span style={{ marginRight: 5 }}>{text}</span>
						<span>{record.currency}</span>
					</div>
				),
			},
			{
				title: "Action",
				dataIndex: "action",
				key: "action",
				width: "40px",
				render: (text, record) => (
					<div
						className="action_context_menu_icon"
						onClick={(e) =>
							this.onActionClick(
								e,
								record.campID,
								record.status,
								record.campExists
							)
						}>
						<span
							style={{
								position: "relative",
								left: 0,
							}}>
							...
						</span>
					</div>
				),
			},
		];

		const TABLE_COLUMNS2 = [
			{
				title: "Proposal ID",
				dataIndex: "campID",
				key: "campID",
				width: "100px",
				sorter: (a, b) => a.campID - b.campID,
			},
			{
				title: "RFP Name",
				dataIndex: "campaignName",
				key: "campaignName",
				width: "180px",
				sorter: (a, b) =>
					a.campaignName.toLowerCase() < b.campaignName.toLowerCase() ? -1 : 1,
				render: (text, record) => (
					<div style={{ width: 160 }}>
						<a
							style={{
								color: "#1890ff",
								display: "block",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								minWidth: 160,
							}}
							onClick={(e) => this.onRFPNameClick(record.campID)}>
							{text}
						</a>
					</div>
				),
			},
			{
				title: "Camp Type",
				dataIndex: "campaignType",
				key: "campaignType",
				width: "100px",
				sorter: (a, b) =>
					a.campaignName.toLowerCase() < b.campaignName.toLowerCase() ? -1 : 1,
			},
			{
				title: "Start Date",
				dataIndex: "startDate",
				key: "startDate",
				width: "90px",
				sorter: (a, b) => (a.startDate < b.startDate ? -1 : 1),
			},
			{
				title: "End Date",
				dataIndex: "endDate",
				key: "endDate",
				width: "90px",
				sorter: (a, b) => (a.endDate < b.endDate ? -1 : 1),
			},
			{
				title: "Bidding Closes",
				dataIndex: "biddingEndDate",
				key: "biddingEndDate",
				width: "110px",
				sorter: (a, b) => (a.biddingEndDate < b.biddingEndDate ? -1 : 1),
			},
			{
				title: "Total Leads",
				dataIndex: "leadAllocation",
				key: "leadAllocation",
				width: "80px",
				sorter: (a, b) => a.leadAllocation - b.leadAllocation,
			},
			{
				title: "CPL",
				dataIndex: "CPL",
				key: "CPL",
				width: "60px",
				sorter: (a, b) => a.CPL - b.CPL,
				render: (text, record) => (
					<div>
						<span style={{ marginRight: 5 }}>{text}</span>
						<span>{record.currency}</span>
					</div>
				),
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				width: "100px",
				sorter: (a, b) => (a.status < b.status ? -1 : 1),
			},
			{
				title: "Action",
				dataIndex: "action",
				key: "action",
				width: "40px",
				render: (text, record) => (
					<div
						className="action_context_menu_icon"
						onClick={(e) =>
							this.onActionClick(
								e,
								record.campID,
								record.status,
								record.campExists,
								record.startDate,
								record.endDate,
								record.biddingEndDate
							)
						}>
						<span
							style={{
								position: "relative",
								left: 0,
							}}>
							...
						</span>
					</div>
				),
			},
		];

		return (
			<div>
				<Navigation />
				<div
					style={{
						paddingTop: 90,
						paddingBottom: 80,
						paddingLeft: 20,
						paddingRight: 20,
					}}>
					<div className="row mt-2 mb-4 mx-0">
						<div className="col pl-0">
							<div className="float-left">
								<span className="page-heading-rfp-progress">RFP Progress</span>
							</div>
						</div>
						<div className="col pr-0 d-flex d-flex align-items-end justify-content-end">
							<div className="float-right">
								<div
									onClick={this.redirectToCreateRFP}
									style={{ cursor: "pointer", pointerEvents: "auto" }}>
									<label className="create-rfp-label">
										&nbsp;Create New RFP&nbsp;
										<img
											src="createCampaign.png"
											alt=""
											className="create-rfp-img"></img>
									</label>
								</div>
							</div>
						</div>
					</div>

					<div
						className="row fix-margin-row flex-wrap"
						style={{ borderBottom: "1px solid #a9a9a97d" }}>
						<div className="col d-flex fix-padding-row flex-wrap">
							{this.state.tabs.map((tab, i) => {
								let classNames = "";
								if (tab.id === this.state.activeTab) {
									classNames =
										"non-active-rfp-progress-tab active-rfp-progress-tab";
								} else {
									classNames = "non-active-rfp-progress-tab";
								}
								return (
									<div
										key={i}
										className={classNames}
										id={tab.id}
										onClick={this.handleTabChange}>
										<span style={{ marginRight: 5 }}>{tab.name}</span>
										<span
											style={{
												paddingLeft: "5px",
												paddingRight: "5px",
												height: "22px",
												backgroundColor: tab.backgroundColor,
												borderRadius: "18%",
												fontSize: "16px",
												color: "white",
												textAlign: "center",
												display: "inline-block",
											}}>
											{tab.count}
										</span>
									</div>
								);
							})}
						</div>
						<div
							className="col-md-auto pr-0 d-flex align-items-center search-box-responsive-rfp-progress"
							style={{ paddingBottom: 5 }}>
							<input
								style={{
									backgroundImage: "url(searchIcon.png)",
									backgroundPosition: "8px 6px",
									backgroundSize: "12px 12px",
									backgroundRepeat: "no-repeat",
									height: "20px",
									fontSize: "10px",
									border: "1px solid #ddd",
									padding: "12px 20px 12px 34px",
									backgroundColor: "#ffffff",
									borderRadius: 4,
								}}
								type="text"
								placeholder="Search"
								name="searchedText"
								value={this.state.searchedText}
								onChange={this.handleSearchText}
							/>
						</div>
					</div>

					<div className="row mt-2">
						<div className="col-xs-12 col-sm-7 d-flex align-items-center">
							<label
								style={{
									color: "#71717199",
									fontFamily: "Roboto",
									marginRight: 5,
									marginBottom: 0,
								}}>
								Showing results for
							</label>
							<span
								style={{
									color: "#000",
									fontFamily: "Roboto",
									fontWeight: "500",
								}}>
								"{this.state.selectedFilterLabel}"
							</span>
						</div>
						{/* kiran-bug-4255-changed classname to dropdownAgencyRfp for conflicts for responsiveness */}
						<div className="col-xs-12 col-sm-5">
							<div className="float-right">
								<div
									class="float-right dropdownAgencyRfp"
									style={{
										border: "1px solid #d9d9d9",
										backgroundColor: "white",
										padding: "2px 6px",
									}}>
									<div
										className="float-left mr-2"
										style={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											minWidth: "82px",
										}}>
										<span id="myText">{this.state.selectedFilterLabel}</span>
									</div>
									<img
										style={{ marginTop: "4px" }}
										alt=""
										className="filterImage float-right"
										src="Filter.png"
										height="14px"
										width="14px"></img>
									{/* Sandeep-task-2911 to handle filter size increment issue  */}
									{/* kiran-bug-4255-changed classname to dropdown-contentAgencyRfp for conflicts for responsiveness */}
									<div class="dropdown-contentAgencyRfp">
										<a
											href="#/"
											value="All"
											name="All"
											onClick={this.handleFilterChange}
											id="All"
											label="All">
											{" "}
											All
										</a>
										<a
											href="#/"
											value="Weekly"
											name="Weekly"
											onClick={this.handleFilterChange}
											id="Weekly"
											label="Ending This Week">
											Ending This Week
										</a>
										<a
											href="#/"
											value="Monthly"
											name="Monthly"
											onClick={this.handleFilterChange}
											id="Monthly"
											label="Ending This Month">
											Ending This Month
										</a>
										<a
											href="#/"
											value="Quarterly"
											onClick={this.handleFilterChange}
											name="Quarterly"
											id="Quarterly"
											label="Ending This Quarter">
											Ending This Quarter
										</a>
										<a
											href="#/"
											value="Yearly"
											onClick={this.handleFilterChange}
											name="Yearly"
											id="Yearly"
											label="Ending This Year">
											Ending This Year
										</a>
										<a
											href="#/"
											value="PreMonth"
											onClick={this.handleFilterChange}
											name="PreMonth"
											id="PreMonth"
											label="Ending Previous Month">
											Ending Previous Month
										</a>
										<a
											href="#/"
											value="PreQuarter"
											onClick={this.handleFilterChange}
											name="PreQuarter"
											id="PreQuarter"
											label="Ending Previous Quarter">
											Ending Previous Quarter
										</a>
										<a
											href="#/"
											value="PreYear"
											onClick={this.handleFilterChange}
											name="PreYear"
											id="PreYear"
											label="Ending Previous Year">
											Ending Previous Year
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Start - Table DI Loader - Incase there are any future changes, please don't move the sequence of this loader div which is before main table */}
					{this.state.loading && (
						<div align="center" id="loader_campaign_list_rfp_progress"></div>
					)}

					<div style={{ marginTop: 10 }}>
						{this.state.activeTab === "archive" ? (
							<Table
								className={TABLE_CSS}
								bordered
								size="small"
								columns={TABLE_COLUMNS2}
								dataSource={
									this.state.tableData.length > 0 ? this.state.tableData : null
								}
								scroll={{
									x: this.state.tableData.length > 0 ? "auto" : "hidden",
								}}
								pagination={{
									pageSize: this.state.recordsPerPage,
									position: "bottom",
								}}
							/>
						) : (
							<Table
								className={TABLE_CSS}
								bordered
								size="small"
								columns={TABLE_COLUMNS}
								dataSource={
									this.state.tableData.length > 0 ? this.state.tableData : null
								}
								scroll={{
									x: this.state.tableData.length > 0 ? "auto" : "hidden",
								}}
								pagination={{
									pageSize: this.state.recordsPerPage,
									position: "bottom",
								}}
							/>
						)}
					</div>

					<div className="row">
						<div
							className="col-6 d-flex align-items-center"
							style={{ marginTop: -56, height: 56 }}>
							<div style={{ height: 24 }}>
								<span style={{ marginRight: 5, marginLeft: 8 }}>
									Records per page :&nbsp;
								</span>
								<span>
									<select
										className="form-control"
										style={{
											width: 70,
											display: "initial",
											height: "initial",
											padding: 2,
										}}
										value={this.state.recordsPerPage}
										onChange={this.handleRecordsPerPage}>
										<option value={100}>100</option>
										<option value={200}>200</option>
										<option value={300}>300</option>
									</select>
								</span>
							</div>
						</div>
					</div>

					{this.state.actionPopupVisible &&
						(this.state.activeTab === "archive" ? (
							<div
								className="popup-rfp-progress"
								style={{
									right: `${window.innerWidth - this.state.x}px`,
									top: `${this.state.y}px`,
									padding: 7,
								}}>
								{this.state.actionCampExists === "Yes" ? (
									<Tooltip placement="left" title="Campaign Created">
										<div id="live_rfp" style={{ opacity: 0.4 }}>
											<img
												src="live_rfp.png"
												style={{ height: 15, width: 15, marginLeft: "inherit" }}
												alt="live_rfp" //rutuja 2832 added alt tag to remove console warning
											/>
											<span style={{ fontSize: 12, lineHeight: 0 }}>
												Live RFP
											</span>
										</div>
									</Tooltip>
								) : (
									<div
										id="live_rfp"
										data-toggle="modal"
										data-target="#live_rfp_model"
										onClick={this.handeContextMenuItemClick}>
										<img
											src="live_rfp.png"
											style={{ height: 15, width: 15, marginLeft: "inherit" }}
											alt="live_rfp" //rutuja 2832 added alt tag to remove console warning
										/>
										<span style={{ fontSize: 12, lineHeight: 0 }}>
											Live RFP
										</span>
									</div>
								)}
							</div>
						) : (
							<div
								className="popup-rfp-progress"
								style={{
									right: `${window.innerWidth - this.state.x}px`,
									top: `${this.state.y}px`,
									padding: 7,
								}}>
								{this.state.actionStatus === "BiddingDateCrossed" ? (
									<Tooltip placement="left" title="Bidding date crossed">
										<div id="send_to_publisher" style={{ opacity: 0.4 }}>
											<img
												src="send_to_publisher_action.png"
												style={{ height: 15, width: 15, marginLeft: "inherit" }}
												alt="send_to_publisher_action" //rutuja 2832 added alt tag to remove console warning
											/>
											<span style={{ fontSize: 12, lineHeight: 0 }}>
												Send To Publisher
											</span>
										</div>
									</Tooltip>
								) : (
									<div
										id="send_to_publisher"
										data-toggle="modal"
										data-target="#myModal"
										onClick={this.handeContextMenuItemClick}>
										<img
											src="send_to_publisher_action.png"
											style={{ height: 15, width: 15, marginLeft: "inherit" }}
											alt="send_to_publisher_action" //rutuja 2832 added alt tag to remove console warning
										/>
										<span style={{ fontSize: 12, lineHeight: 0 }}>
											Send To Publisher
										</span>
									</div>
								)}
								<div
									style={{
										height: 0.5,
										backgroundColor: "#eee",
										marginLeft: 5,
										marginRight: 5,
										padding: 0,
										marginTop: 2,
										marginBottom: 2,
									}}></div>
								{this.state.actionStatus === "BiddingDateCrossed" ? (
									<Tooltip placement="left" title="Bidding date crossed">
										<div id="edit_rfp" style={{ opacity: 0.4 }}>
											<img
												src="Edit.png"
												style={{ height: 15, width: 15, marginLeft: "inherit" }}
												alt="" //rutuja -2832 added alt tag to remove console warning
											/>
											<span style={{ fontSize: 12, lineHeight: 0 }}>
												Edit RFP
											</span>
										</div>
									</Tooltip>
								) : (
									<div id="edit_rfp" onClick={this.handeContextMenuItemClick}>
										<img
											src="Edit.png"
											style={{ height: 15, width: 15, marginLeft: "inherit" }}
											alt="" //rutuja -2832 added alt tag to remove console warning
										/>
										<span style={{ fontSize: 12, lineHeight: 0 }}>
											Edit RFP
										</span>
									</div>
								)}
								<div
									style={{
										height: 0.5,
										backgroundColor: "#eee",
										marginLeft: 5,
										marginRight: 5,
										padding: 0,
										marginTop: 2,
										marginBottom: 2,
									}}></div>
								{this.state.actionCampExists === "Yes" ? (
									<Tooltip placement="left" title="Campaign Already Exists">
										<div style={{ opacity: 0.4 }} id="create_rfp_campaign">
											<img
												src="create_rfp_campaign_action.png"
												style={{ height: 15, width: 15, marginLeft: "inherit" }}
												alt="" //rutuja -2832 added alt tag to remove console warning
											/>
											<span style={{ fontSize: 12, lineHeight: 0 }}>
												Create RFP Campaign
											</span>
										</div>
									</Tooltip>
								) : (
									<div
										id="create_rfp_campaign"
										onClick={this.handeContextMenuItemClick}>
										<img
											src="create_rfp_campaign_action.png"
											style={{ height: 15, width: 15, marginLeft: "inherit" }}
											alt="" //rutuja -2832 added alt tag to remove console warning
										/>
										<span style={{ fontSize: 12, lineHeight: 0 }}>
											Create RFP Campaign
										</span>
									</div>
								)}
								{/*	rutuja task-4323 clone RFP code was missed added the code for clone RFP */}
								{/* //shivani-3800- added clone option only for action tab. */}
								{this.state.activeTab === "active" ?
								this.state.actionCampExists === "Yes" ? (
								
							<Tooltip placement="left" title="">
								
								<div style={{ opacity: 0.4 }} id=""
								onClick={this.cloneCampaign}>
									<img
										src="clone.png"
										style={{ height: 15, width: 15, marginLeft: "inherit" }}
										alt="" //rutuja -2832 added alt tag to remove console warning
									/>
									<span style={{ fontSize: 12, lineHeight: 0 }}>
										Clone RFP
									</span>
								</div>
							</Tooltip>
						) : (
						
							<div
								id=""onClick={this.cloneCampaign}
								>
								<img
									src="clone.png"
									style={{ height: 15, width: 15, marginLeft: "inherit" }}
									alt="" //rutuja -2832 added alt tag to remove console warning
								/>
								<span style={{ fontSize: 12, lineHeight: 0 }}>
									Clone RFP
								</span>
							</div>
						)
					:""}
							</div>
						))}

					<div
						class="modal"
						id="myModal"
						aria-hidden="true"
						data-keyboard="false"
						style={{ backgroundColor: "rgba(0,0,0,.4)" }} //karan-task-3371-background defocused
						data-backdrop="static">
						<div class="modal-dialog modal-lg">
							<div class="modal-content">
								<div class="modal-header custom-classPopup">
									<h4
										class="modal-title"
										style={{ color: "#144999", paddingRight: "320px" }}>
										<font color="white">RFP Submit</font>
									</h4>
									<button
										type="button"
										class="cancel-button"
										style={{
											fontSize: 15,
											color: "#ffffff",
											paddingLeft: "338px",
										}}
										data-dismiss="modal">
										&times;
									</button>
								</div>
								<div class="modal-body">
									<div class="table-responsive-lg">
										<span style={{ color: "red" }}>
											{" "}
											{this.state.errors.unchecked
												? this.state.errors.unchecked
												: ""}
										</span>
										<table class=" table table-striped table-bordered">
											<thead>
												<tr>
													<th>Publisher Name</th>
													<th>Allocate</th>
													<th>Acknowledge Status</th>
													<th>Timestamp</th>
												</tr>

												{this.state.publisherList.map((publisherList, i) => (
													<tr key={i}>
														<td>{publisherList.publisherName}</td>
														{publisherList.status === "Assign" || //rutuja 2832
															publisherList.status === "submittedByPublisher" ? (
															<td>
																<Checkbox defaultChecked disabled></Checkbox>
															</td>
														) : (
															<td>
																<Checkbox
																	id="Checked"
																	name="Checked"
																	onChange={(e) =>
																		this.handleCheckPublisher(i, e)
																	}></Checkbox>
															</td>
														)}
														{publisherList.acknowledgeStatus === "Yes" ? (
															<td>{"Acknowledged"}</td>
														) : publisherList.acknowledgeStatus === "No" ? (
															<td>{"No Response"}</td>
														) : (
															<td>
																{publisherList.acknowledgeStatus
																	? publisherList.acknowledgeStatus
																	: "NA"}
															</td>
														)}

														{publisherList.acknowledgeStatus === "Yes" ? (
															<td>{publisherList.timestamp}</td>
														) : (
															<td>{"NA"}</td>
														)}
													</tr>
												))}
											</thead>
										</table>
									</div>
								</div>
								<div class="modal-footer">
									<button
										type="button"
										class="btn btn-info "
										disabled={this.state.btnSubmitPublisher}
										onClick={this.submitPublisherList}
										style={{
											backgroundColor: "rgb(20, 73, 153)",
											color: "white",
											width: "min-content",
											height: "31px",
											fontSize: "14px",
										}}>
										Submit
									</button>
									&nbsp;
								</div>
							</div>
						</div>
					</div>
					<div
						class="modal fade"
						id="live_rfp_model"
						aria-hidden="true"
						data-keyboard="false"
						style={{ backgroundColor: "rgba(0,0,0,.4)" }} //karan-task-3371-background defocused
						data-backdrop="static">
						<div class="modal-dialog modal-lg responsive-model">
							<div class="modal-content">
								<div class="modal-header custom-class">
									<h4 class="modal-title" style={{ color: "#144c9b" }}>
										<font color="white">
											Edit Bidding End Date To Archive RFP&nbsp;
										</font>
									</h4>
									<button
										type="button"
										class="cancel-button"
										style={{ fontSize: 15, float: "right" }}
										data-dismiss="modal"
										onClick={this.handleRefreshPage.bind(this)}>
										&times;
									</button>
								</div>
								<div class="modal-body">
									<div>
										<div
											style={{
												border: this.state.alertBorder,
												display: this.state.alertDisplay,
											}}>
											<div class="errorMessage">
												{this.state.errors.errmsg1} {this.state.errors.errmsg2}{" "}
												{this.state.errors.errmsg3}{" "}
											</div>
										</div>
									</div>

									<p>
										<div>
											<div class="row">
												<div
													class="col-3 responsive-model-1"
													style={{ marginLeft: "60px" }}>
													<label id="label" style={{ width: "110px" }}>
														<font color="black">Start Date</font>
													</label>
													<br />
													{this.state.actionStartDate}
												</div>
												<div
													class="col-3 responsive-model-1"
													style={{ marginLeft: "60px" }}>
													<label id="label" style={{ width: "110px" }}>
														<font color="black">End Date</font>
													</label>
													<br />
													{this.state.actionEndDate}
												</div>
												<div class="col-3 responsive-model-1">
													<label id="label" style={{ width: "110px" }}>
														<font color="black">Bidding End Date</font>
														<span style={{ color: "red" }}>*</span>
													</label>
													<DatePicker
														showTime={{ format: "HH:mm" }}
														format={"YYYY-MM-DD HH:mm"}
														className="inputStyle_rfp"
														defaultValue={this.state.actionEndDate}
														id="endDate"
														name="endDate"
														onChange={this.handleChangeDate.bind(this)}
													/>
												</div>
											</div>
										</div>
									</p>
								</div>
								<div class="modal-footer">
									<center>
										<button
											type="button"
											class="btn btn-default "
											onClick={this.archiveRfp}
											style={{
												backgroundColor: "#144999",
												color: "white",
												borderRadius: "3px",
											}}>
											Archive RFP&nbsp;
											<i class="fa fa-pencil-square-o" aria-hidden="true"></i>
										</button>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

/**
 * @author Karan Jagtap
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
RFPProgress.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(RFPProgress)
);
