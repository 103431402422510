/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Shivani Pathak
 *@fileName :
 *Desc: Publisher Unread Alerts
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../login/authentication";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PublisherNavigation from "../layouts/publisherNavPage";
import Footer from "../layouts/footer";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import "./publisherUnreadAlerts.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header

// const queryString = require("query-string"); //Nilesh-4242-Removing console warnings
class PublisherUnreadAlerts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pID: "",
			columnUnreadAlertsSearch: [],
			tableSearchText2: "",
			unreadAlerts: [],
			unreadAlertsLength: 0,
			unreadAlertsData: [],
			// loading: " ",
			loader_progress: "", //Nilesh-5068- Added new loader
		};
		this.handleChangeSearch = this.handleChangeSearch.bind(this);
	}
	// start of component will mount
	componentWillMount() {
		// const { isAuthenticated, user } = this.props.auth;
		const { user } = this.props.auth; //Nilesh-4242-Removing console warnings
		var pID = user.id;
		// var userRole = user.role; //Nilesh-4242-Removing console warnings
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			//    alert("In willMount")

			// const { isAuthenticated, user } = this.props.auth; //Nilesh-4242-Removing console warnings
			// var pID = user.id;  //Nilesh-4242-Removing console warnings
			var campID;
			// alert("pID===>"+JSON.stringify(pID))
			this.setState({ pID: pID });

			//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
if(this.props.location.state!==undefined){
	var parsed = this.props.location.state;
	campID = parsed.campID;
  }


			// this.setState({campID:campID})
			fetch("/notification/publisherDashboardUnreadAlerts", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((unreadAlerts) => {
					//   alert("unreadAlerts===>"+JSON.stringify(unreadAlerts))
					let filterunreadalerts = unreadAlerts.filter((a) => {
						return a.campID === campID;  //Nilesh-4242-Removing console warnings
					});
					var array = [];
					var array1 = [];
					for (var i = 0; i < unreadAlerts.length; i++) {
						if (unreadAlerts[i].campID === campID) { //Nilesh-4242-Removing console warnings
							array.push(unreadAlerts[i]);
						} else {
							array1.push(unreadAlerts[i]);
						}
					}

					var finalArray = array.concat(array1);
					//    alert("finalArray===>"+JSON.stringify(finalArray))
					//    alert("UnRead MessaunreadAlertsge=="+JSON.stringify(unreadAlerts));
					this.setState({
						unreadAlertsData: finalArray,
						unreadAlerts: filterunreadalerts,
						unreadAlertsLength: unreadAlerts.length,
					});
					if (this.state.unreadAlerts && this.state.unreadAlerts.length > 0) {
						var name = "key";
						var value;
						for (let i = 0; i < this.state.unreadAlerts.length; i++) { //Nilesh-4242-Removing console warnings
							value = this.state.unreadAlerts[i].alertID;
							let unreadAlerts = [...this.state.unreadAlerts];
							unreadAlerts[i] = { ...unreadAlerts[i], [name]: value };
							// console.log(JSON.stringify(unreadAlerts[i]))
							this.setState({ unreadAlerts: unreadAlerts });
						}
					}
					//    alert("UnRead MessaunreadAlertsge=="+unreadAlerts.length+" "+JSON.stringify(this.state.unreadAlerts));
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}
	//end of willMount
	//backbutton functionality
	UnreadAlertBackButton(e) {
		e.preventDefault(); //Nilesh-4242-Removing console warnings
		// const { user } = this.props.auth; //Nilesh-4242-Removing console warnings
		// var pID = user.id; //Nilesh-4242-Removing console warnings
		//Sanjana-task-3728-replace query params
		this.props.history.push("/newPublisherDashboard", {pID: this.state.pID})
	}
	// end of back button functionality
	//campHandleChange
	campHandleChange(i, e) { //Nilesh-4242-Removing console warnings
		e.preventDefault();
		// let Self = this;  //Nilesh-4242-Removing console warnings
		// const { user } = this.props.auth; //Nilesh-4242-Removing console warnings

		// this.setState({
		// 	loading: "whirly-loader",
		// });
		//Nilesh-5068- Added new loader
		this.setState({
		loader_progress: "loader_campaign_list",
		});

		//  alert("inside handlechange")
		// alert("campID===>"+campID)
		// alert("campID===>"+i+" array==="+this.state.unreadAlertsData[i].campID);
		var campID = this.state.unreadAlertsData[i].campID;
		var alertID = this.state.unreadAlertsData[i].alertID;

		//  alert("alertID1===>"+alertID);
		fetch("/notification/publisherDashboardUnreadAlerts", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((unreadAlerts) => {
				let filterunreadalerts = unreadAlerts.filter((a) => {
					return a.campID === campID; //Nilesh-4242-Removing console warnings
				});
				// alert("temp1===>"+JSON.stringify(filterunreadalerts))
				// var array = [];
				//      for(let campID in unreadAlerts){
				//       unreadAlerts[campID]["CampID"] = campID;
				//        array.push(unreadAlerts[campID]);
				//      }

				// unreadAlerts.sort((a, b) => a.campID - b.campID);
				// this.setState({unreadAlerts: unreadAlerts});
				var array = [];
				var array1 = [];
				for (var i = 0; i < unreadAlerts.length; i++) {
					if (unreadAlerts[i].campID === campID) {  //Nilesh-4242-Removing console warnings
						array.push(unreadAlerts[i]);
					} else {
						array1.push(unreadAlerts[i]);
					}
				}

				// var finalArray = array.concat(array1);  //Nilesh-4242-Removing console warnings
				//  alert("finalArray===>"+JSON.stringify(finalArray))
				//   this.setState({ unreadAlertsData:finalArray,unreadAlerts:filterunreadalerts,unreadAlertsLength:filterunreadalerts.length,loading:""})
				this.setState({
					unreadAlerts: filterunreadalerts,
					unreadAlertsLength: filterunreadalerts.length,
					loader_progress: "",//Nilesh-5068
				});
				//  alert("unreadAlerts===>"+JSON.stringify(unreadAlerts))
				if (this.state.unreadAlerts && this.state.unreadAlerts.length > 0) {
					var name = "key";
					var value;
					for (let i = 0; i < this.state.unreadAlerts.length; i++) { //Nilesh-4242-Removing console warnings
						value = this.state.unreadAlerts[i].alertID;
						let unreadAlerts = [...this.state.unreadAlerts];
						unreadAlerts[i] = { ...unreadAlerts[i], [name]: value };
						//console.log(JSON.stringify(unreadAlerts[i]))
						this.setState({ unreadAlerts: unreadAlerts });
					}
				}

				// console.log("UnRead MessaunreadAlertsge=="+unreadAlerts.length+" "+JSON.stringify(this.state.unreadAlerts));
			})
			.catch(function (err) {
				console.log(err);
			});

		//   alert("alertID===>"+alertID)
		let data = { alertID: alertID, clickedcampaignID: campID };
		// alert("recordOpen=="+JSON.stringify(data));
		// alert("alertID===>"+alertID);
		fetch("/notification/AgencyAlertsToAgecncyStatusChanged", {
			//insert data in publisher_assignment table
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())

			.catch(function (err) {
				console.log(err);
			});
	}
	//end of campHandleChange

	//search functionality

	handleChangeSearch(e) {
		e.preventDefault();
		let currentList = [];
		// Variable to hold the filtered list before putting into state
		let newList = [];
		//  alert("e.target.value==>"+e.target.value)
		let filterData = e.target.value;
		// If the search bar isn't empty
		if (e.target.value === "") {
			newList = this.state.unreadAlertsData;
		} else {
			// Assign the original list to currentList
			// alert("JSON Data=="+JSON.stringify(this.state.unreadAlertsData))
			currentList = this.state.unreadAlertsData;
			// alert("JSON Data=="+JSON.stringify(newList))
			newList = currentList.filter((item) => {
				let obj={};//Somnath Task-3972, Add specific Keys in search criteria
				obj[`campID`]=item.campID;
				obj[`campaignName`]=item.campaignName;
				obj[`startDate`]=item.startDate;
				obj[`endDate`]=item.endDate;
				obj[`CPL`]=item.CPL;
				obj[`allocatedLead`]=item.allocatedLead;

				const filter = e.target.value.toLowerCase();
				// alert("JSON Data=="+JSON.stringify(filter))
				//const filter = e.target.value.toLowerCase();
				// check to see if the current list item includes the search term
				// If it does, it will be added to newList. Using lowercase eliminates
				// issues with capitalization in search terms and search content
				var data = Object.values(obj);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
			// alert("JSON Data=="+JSON.stringify(newList))
		}
		// Set the filtered state based on what our rules added to newList
		this.setState({
			columnUnreadAlertsSearch: [...newList],
			tableSearchText2: filterData,
		});
	}
	render() {
		// const { isAuthenticated, user } = this.props.auth; //Nilesh-4242-Removing console warnings
		const { user } = this.props.auth;

		return (
			<div>
				<PublisherNavigation />
				<div class="container-fluid">
					<div class="row" style={{ paddingTop: "100px" }}>
						<div class="col-sm-12 col-md-6 col-lg-4">
							<a
								href="#/" //Nilesh-4242-Removing console warnings
								style={{ color: "#056eb8" }}
								onClick={this.UnreadAlertBackButton.bind(this)}>
								<FaArrowAltCircleLeft size={32} 
								title="Back to Dashboard" //Nilesh-5068-Add tooltip
								/>
							</a>
							<h3 style={{ color: "#124E9C" }}>Unread Alerts</h3>
						</div>

						<div class=" float-lg-right col-sm-12 col-md-1 offset-md-5 col-lg-3  offset-lg-5">
							{/* <Tooltip  class="float-right" title=" Search by campaign ID OR any campaign keyword.">
                <Icon style={{marginRight:'5px'}} type="question-circle-o" />
              </Tooltip> */}

							<span>
								<input
									className="search"
									id="search"
									type="text"
									name="search"
									placeholder="Search by campaign ID OR any campaign keyword."
									style={{
										backgroundImage: "url(searchIcon.png)",
										backgroundPosition: "15px 6px",
										backgroundSize: "15px 15px",
										backgroundRepeat: "no-repeat",
										width: "280px",
										height: "30px",
										marginTop: "35px",
										fontSize: "10px",
										border: "1px solid lightGray",
										padding: "12px 20px 12px 34px",
										borderRadius: "5px",
										marginLeft: "-5rem"
									}}
									onChange={this.handleChangeSearch}
									onPressEnter={this.handleChangeSearch}></input>
							</span>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12 col-md-6 col-lg-4">
							<div
								class="first-div"
								style={{
									background: "#FFFFFF",
									height: "420px",
									width: "340px",
									overflowY: "scroll",
								}}>
								<h4
									style={{
										color: "black",
										marginLeft: "20px",
										marginTop: "20px",
									}}>
									All
								</h4>
								<hr />
								{this.state.unreadAlertsData.map((unreadAlertsData, i) => {
									//  alert("unreadAlertsData===>"+unreadAlertsData.alertID)
									return (
										<p style={{ marginLeft: "5px", color: "black" }}>
											<td>
												<span>
													<a
														href="#/"
														onClick={this.campHandleChange.bind(this, i)}
														id={unreadAlertsData.campID}
														campID={unreadAlertsData.campID}
														alertID={unreadAlertsData.alertID}>
														The Campaign{" "}
														<b>&nbsp;{unreadAlertsData.campaignName}&nbsp;</b>
														<b> CID :&nbsp;{unreadAlertsData.campID}&nbsp;</b>
														has been
														<b>&nbsp;{unreadAlertsData.content}&nbsp;</b>
														{unreadAlertsData.author}&nbsp;on&nbsp;
														{unreadAlertsData.datetime}
														<hr />
													</a>
												</span>
											</td>
										</p>
									);
								})}
							</div>
							<br />
							<br />
						</div>
						<br />
						<div class=" second_div col-sm-12 col-md-6 col-lg-8">
							<div
								style={{
									background: "#FFFFFF",
									height: "420px",
									width: "870px",
									overflowY: "scroll",
									overflowX: "hidden",
									paddingTop: "15px",
								}}>
									{/* Nilesh-5068-Added new loader */}
								<div
									// className={this.state.loader_progress}
									style={{
										left: "710px",
										top: "80px",
										bottom: "300px",
										zIndex: "2",
									  }}
									></div>
									<div id={this.state.loader_progress}></div>
								{this.state.tableSearchText2 === "" ? (
									<div>
										{this.state.unreadAlerts.map((unreadAlerts) => {
											return (
												<p style={{ marginLeft: "5px", color: "black" }}>
													<div>
														<h4 style={{ marginLeft: "15px" }}>
															{unreadAlerts.content}
														</h4>
														<h5 style={{ marginLeft: "15px", color: "black" }}>
															<b style={{ width: "2px" }}>
																Campaign ID :&nbsp;{unreadAlerts.campID}
															</b>
														</h5>
														<h5 style={{ marginLeft: "15px", color: "black" }}>
															<b>
																Campaign Name :&nbsp;{unreadAlerts.campaignName}
															</b>
														</h5>
														<div class="row">
															<p style={{ marginLeft: "15px" }}>
																&nbsp;&nbsp;&nbsp;&nbsp;Start Date :&nbsp;
																{unreadAlerts.startDate}
															</p>
															<p>
																&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;End
																Date :&nbsp;{unreadAlerts.endDate}
															</p>
															<p style={{ marginLeft: "15px" }}>
																&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allocation
																:&nbsp;{unreadAlerts.allocatedLead}
															</p>
															{user.role === "PNC" ? (
																""
															) : (
																<p>
																	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CPL
																	:&nbsp;{unreadAlerts.CPL}
																</p>
															)}
														</div>
													</div>
													<hr />
												</p>
											);
										})}
									</div>
								) : this.state.columnUnreadAlertsSearch.length > 0 ? (
									<div>
										{this.state.columnUnreadAlertsSearch.map(
											(columnUnreadAlertsSearch) => {
												return (
													<p style={{ marginLeft: "5px", color: "black" }}>
														<div>
															<h4 style={{ marginLeft: "15px" }}>
																{columnUnreadAlertsSearch.content}
															</h4>
															<h5
																style={{ marginLeft: "15px", color: "black" }}>
																<b style={{ width: "2px" }}>
																	Campaign ID :&nbsp;
																	{columnUnreadAlertsSearch.campID}
																</b>
															</h5>
															<h5
																style={{ marginLeft: "15px", color: "black" }}>
																<b>
																	Campaign Name :&nbsp;
																	{columnUnreadAlertsSearch.campaignName}
																</b>
															</h5>
															<div class="row">
																<p style={{ marginLeft: "15px" }}>
																	&nbsp;&nbsp;&nbsp;&nbsp;Start Date :&nbsp;
																	{columnUnreadAlertsSearch.startDate}
																</p>
																<p>
																	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;End
																	Date :&nbsp;{columnUnreadAlertsSearch.endDate}
																</p>
																<p style={{ marginLeft: "15px" }}>
																	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allocation
																	:&nbsp;
																	{columnUnreadAlertsSearch.allocatedLead}
																</p>
																{user.role === "PNC" ? (
																	""
																) : (
																	<p>
																		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CPL
																		:&nbsp;{columnUnreadAlertsSearch.CPL}
																	</p>
																)}
															</div>
														</div>
														<hr />
													</p>
												);
											}
										)}
									</div>
								) : (
									<center>
										<label
											style={{
												color: "gray",
												fontSize: "medium",
												padding: "200px",
											}}>
											No Data Exist
										</label>
									</center>
								)}
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}
PublisherUnreadAlerts.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(PublisherUnreadAlerts)
);
