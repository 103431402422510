/* Copyright(c) 2023 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*@author Rutja Jagtap task- 4654
*@fileName : publisherLeadReportByCampaign.js
@Creation Date:27-03-2023
 *Desc: PC- Reports - Lead Report By Campaign
*/
import React from "react";
import PublisherNavigation from "../layouts/publisherNavPage"; //kiran-2836-removed console warning for $
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import './agencyMyReports.css';
import { css } from "emotion";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
// import styled from "styled-components";  //kiran-2836-removiing console warning-not in used
import { Table} from 'antd';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import moment from 'moment';
import { saveAs } from "file-saver";
import { LeadReviewLoader } from "../loaders/LeadReviewLoader";
const xlsx = require("xlsx");
const Swal = require('sweetalert2');
var selectedCampaign = [];

const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "12px",
		fontWeight: "500",
	},
	"& thead > tr >th": {
		textAlign: "center",
		color: "white",
	},
});
// const queryString = require('query-string');//kiran-2836-Removing console warnings
// const PickyStyle = styled(Picky)`                 // kiran-2836-Removing console warnings - not in use 
// background-color: #bd141480 !important;
// border-radius: '4px !important'

// #-list.picky__dropdown{
//   overflow:'hidden !important';
// }
// .picky__placeholder {
//     color: #000000 !important;
// }
// .picky {
//     width:148px !important;
//     height:32px !important;
//   }
//  `
//     ;


//sunita - 3413 - added array  for lead status .
const leadStatusOptions =
[
    
    { id: "Delivered", name: "Delivered" },
    { id: "Review-Pending", name: "Review-Pending" },
    { id: "Accepted", name: "Accepted" },
    { id: "Rejected", name: "Rejected" }
];

//sunita - 3413 - added array  for campaign status .
const campaignStatusOptions =
    [
        { id: "Active", name: "Active" },
        // { id: "Paused", name: "Paused" }, //sunita-task-3570-commented the paused camp status as per requirement.
        { id: "Completed", name: "Completed" }
    ]

class publisherLeadReportByCampaign extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            tableState: { size: "small" },
            agencyAdvertiserCampaignInfo: [],
            agencyMyReportsDataNew1: [],
            agencyMyReportsData: [],
            advertiserList: [],
            advertiserList1: [],
            agencyID: "",
            advertiserID: "Select Advertiser",
            campaignStatus: [],
            invoiceDetail: [],
            startDate: "",
            endDate: "",
            label: "",
            reportKey: "",
            type: "Advertiser Lead Tracking",
            reportName: "",
            generateBtnFlag: false,
            advID: '',
            userType: "Advertiser",
            campID: "Select Campaign",
            getCampaignListByADV: [],
            advLeadDetailsDelivery: [],
            token: "",
            deliveryDetails: [],
            leadStatus: [],
            campaignName: "",
            campaignStatusArray: [],
            leadIndeterminate: true,
            leadCheckAll: false,
            campaignLeadStatusArray: [],
            checkedColumnKeys: [],
            drpdisabled_report: "",
            advcountDetails: [],
            customQuestionCount: 0,
            clientCampID: "Select Campaign",
            loader_progress: "",
            pageSize2:5, //-added pagesize for table
            getCampaignListByADVSearch:[],


        }//end of state

        this.onCampaignStatusHandleChange = this.onCampaignStatusHandleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onLeadStatusHandleChange = this.onLeadStatusHandleChange.bind(this);
        this.searchHandleChange = this.searchHandleChange.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.onAdvertiserNameHandleChange = this.onAdvertiserNameHandleChange.bind(this);
        this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
        this.onCampaignDetailsHandleChange = this.onCampaignDetailsHandleChange.bind(this);
        this.handledownloadLead = this.handledownloadLead.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);// searching table data  
        this.loadingRef = React.createRef();
        this.closeLoadingRef = React.createRef();
    }// end of constrcutor

    /**
    * @author : Rutuja Jagtap task- 4654
    * @description : handlechange for downloading leads .
    */
   
    handledownloadLead(e) {
        let advLeadDetailsDelivery = this.state.advLeadDetailsDelivery;
        let advertiserList = this.state.advertiserList;
        let advertiserID = this.state.advertiserID;
       let getCampaignListByADV = this.state.getCampaignListByADV;
        if (advLeadDetailsDelivery.length > 0) {
            delete advLeadDetailsDelivery["statusToDisplay"];
            
            var ws = xlsx.utils.json_to_sheet(advLeadDetailsDelivery);
            
            var wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, ws, "Lead");
            var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (let i = 0; i !== s.length; ++i)
                    view[i] = s.charCodeAt(i) & 0xff;
                return buf;
            }
       
            let clientCampID = getCampaignListByADV[0].clientCampID;
            
           let advertiserName = "";
        for(let i=0;i<advertiserList.length;i++)
        {
            if(Number(advertiserList[i].advertiserID) === advertiserID){ //kiran-2836-Removing console warnings
                advertiserName = advertiserList[i].advertiserName;
            }
        }
          
            var fileName = advertiserID + "_"+ advertiserName + "_" + clientCampID + "_ - Lead Report By Campaign.xlsx"; //kiran-2836-Removing console warnings
            saveAs(
                new Blob([s2ab(buf)], { type: "application/octet-stream" }),
                fileName
            );
        }

    }

    handleChange(e) {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let advertiserID = this.state.advertiserID;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let campaignStatusArray = this.state.campaignStatusArray;
    
        if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate")
         && (advertiserID && advertiserID !== "Select Advertiser") 
        && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (selectedCampaign && selectedCampaign.length > 0) 
        && (campaignStatusArray && campaignStatusArray.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0}); //kiran-2836-Removing console warnings
            }
         
        if (e.target.name.toString() === "startDate") { //kiran-2836-Removing console warnings
            let date = e.target.value;
            date = moment().format('mm/dd/yyyy');
            let dateArray = date.split('/');
            if (dateArray.length === 0) {
                dateArray = date.split('-');
            }


            this.setState({
                startDate: e.target.value
            })
        }
        else if (e.target.name.toString() === "endDate") { //kiran-2836-Removing console warnings
            this.setState({
                endDate: e.target.value
            })
        }

      //  var diff = startDate.getTime() - endDate.getTime();


    } // End of showInvoice
   
    leadOnChange = checkedColumnKeys => {

        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let advertiserID = this.state.advertiserID;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let campaignStatusArray = this.state.campaignStatusArray;
    
        if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate")
         && (advertiserID && advertiserID !== "Select Advertiser") 
        && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (selectedCampaign && selectedCampaign.length > 0) 
        && (campaignStatusArray && campaignStatusArray.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0}); //kiran-2836-Removing console warnings
            }
         

        if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch1")) {
            checkedColumnKeys.push("assetTimestampTouch1");
        } else {
            let index = checkedColumnKeys.indexOf("assetTimestampTouch1");
            if (index > -1) {
                checkedColumnKeys.splice(index, 1);
            }
        }

        if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch2")) {
            checkedColumnKeys.push("assetTimestampTouch2");
        } else {
            let index = checkedColumnKeys.indexOf("assetTimestampTouch2");
            if (index > -1) {
                checkedColumnKeys.splice(index, 1);
            }
        }

        if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch3")) {
            checkedColumnKeys.push("assetTimestampTouch3");
        } else {
            let index = checkedColumnKeys.indexOf("assetTimestampTouch3");
            if (index > -1) {
                checkedColumnKeys.splice(index, 1);
            }
        }


        this.setState({
            checkedColumnKeys,
            leadIndeterminate: !!checkedColumnKeys.length && checkedColumnKeys.length < this.state.checkBoxObjArray.length,
            leadCheckAll: checkedColumnKeys.length === this.state.checkBoxObjArray.length,
        });

    };


    /**
   * @author : Rutuja Jagtap task- 4654
   * @description : handlechange for pageSize .
   */


    handleChangePageSize(e) {
        var pageSize2 = e.target.value;
        this.setState({ pageSize2: pageSize2 });

    }//end of handleChangePageSize

    /**
    * @author : Rutuja Jagtap task- 4654
    * @description : handlechange for backbutton to navigate on same page from where user came .
    */
    backButtonHandleChange(e) {
        e.preventDefault();
        this.props.history.push("/newPublisherDashboard") //rutuja -task-4666 Back button should redirect to dashboard page
    }//end of backButtonHandleChange


    /**
    * @author : Rutuja Jagtap task- 4654
    * @description :  handleChange to load the campaign after selecting advertiser and campaign status .
    */

    onCampaignDetailsHandleChange(clientName, campaignStatusArray) {
        const { user } = this.props.auth;
        const agencyID = user.id;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        this.setState({ agencyID })
      
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        let advertiserID1 = this.state.advertiserID;
        let campaignStatusArray1 = this.state.campaignStatusArray;
    
        if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate")
         && (advertiserID1 && advertiserID1 !== "Select Advertiser") 
        && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (selectedCampaign && selectedCampaign.length > 0) 
        && (campaignStatusArray1 && campaignStatusArray1.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0}); //kiran-2836-Removing console warnings
            }
     
        //-added below condition to close the loader when all filters are having values. 
        if ((clientName && clientName !== "Select Advertiser") && (selectedCampaign && selectedCampaign.length > 0)  && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0)) {

         
        }
        let advcountDetails = [{ "advertiserID": clientName, "advertiserName": "", "deliveryLabel": "", "totalAllocation": 0, "totalAccepted": 0, "deliveredLead": 0, "rejectedLead": 0, "qaReviewLead": 0, "balanceLead": 0 ,"totalcount":0,"InternalReview":0,"Advertiser Rejected":0,"publisherRejected":0    }]
        this.setState({ agencyID, advcountDetails, advLeadDetailsDelivery: [], });

        if ((clientName && clientName !== "Select Advertiser") && (campaignStatusArray && campaignStatusArray.length > 0)) {
            let data = {

                clientName: clientName,
                campaignStatus: campaignStatusArray,
            }

            fetch("/publisherReports/getCampaignListByADV", {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then(getCampaignListByADV => {
                    this.setState({ getCampaignListByADV: getCampaignListByADV})
                    this.setState({getCampaignListByADVSearch: this.state.getCampaignListByADV});//rutuja 4486 
                   
                }).catch(function (err) { console.log(err) });


        }

    }


    /**
    * @author : Rutuja Jagtap task- 4654
    * @description : handlechange to target selected advertiser .
    */

    onAdvertiserNameHandleChange(e) {

        let startDate1 = this.state.startDate;
        let endDate1 = this.state.endDate;
        let advertiserID1 = this.state.advertiserID;
        let campaignLeadStatusArray1 = this.state.campaignLeadStatusArray;
        let campaignStatusArray1 = this.state.campaignStatusArray;
    
        if ((startDate1 && startDate1 !== "Select startDate") && (endDate1 && endDate1 !== "Select endDate")
         && (advertiserID1 && advertiserID1 !== "Select Advertiser") 
        && (campaignLeadStatusArray1 && campaignLeadStatusArray1.length > 0) &&(selectedCampaign && selectedCampaign.length > 0) 
        && (campaignStatusArray1 && campaignStatusArray1.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0}); //kiran-2836-Removing console warnings
            }
         
        var advertiserID = e.target.value;
        let startDate = e.target.value;
        let endDate = e.target.value;

        let campaignStatusArray = this.state.campaignStatusArray;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
       
      


        this.setState({ advertiserID, selectedCampaign: "", advLeadDetailsDelivery: [] }) //sunita-task-3582-added code for showing blank data if data is not present in table.
        
        if ((advertiserID && advertiserID !== "Select Advertiser") && (campaignStatusArray && campaignStatusArray.length > 0)) {

            this.onCampaignDetailsHandleChange(advertiserID, campaignStatusArray); //sunita-task-3413-function called to load the campaign after selecting advertiser and campaign status .
        }



        //-added below condition for - if same campaign having by number of advertiser So data should load in table.
        if (Number(campaignStatusArray.length) === 0 || campaignStatusArray === []) { //kiran-2836-Removing console warnings
            this.setState({ campID: "Select Campaign", generateBtnFlag: false, advLeadDetailsDelivery: [] })
        }
        else if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate") && (advertiserID && advertiserID !== "Select Advertiser") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (selectedCampaign && selectedCampaign.length > 0) && (campaignStatusArray && campaignStatusArray.length > 0)) {
          

        }
        else {
            this.setState({ generateBtnFlag: false, advLeadDetailsDelivery: [] })

        }


    }//end of onAdvertiserNameHandleChange


    /**
    * @author : Rutuja Jagtap task- 4654
    * @description : handlechange to target selected campaignstatus .
    */

    onCampaignStatusHandleChange(campaignStatus) {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let advertiserID1 = this.state.advertiserID;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let campaignStatusArray1 = this.state.campaignStatusArray;
    
        if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate")
         && (advertiserID1 && advertiserID1 !== "Select Advertiser") 
        && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (selectedCampaign && selectedCampaign.length > 0) 
        && (campaignStatusArray1 && campaignStatusArray1.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0});//kiran-2836-Removing console warnings
            }
         
    
        let campaignStatusArray = [];
        let checkActive = campaignStatus.some(o => o.name === "Active")
        if (checkActive) { campaignStatusArray.push("Active") }
        let checkCompleted = campaignStatus.some(o => o.name === "Completed")
        if (checkCompleted) { campaignStatusArray.push("Completed") }

        let advertiserID = this.state.advertiserID;
        this.setState({ campaignStatus, campaignStatusArray })
        if (Number(campaignStatusArray.length) === 0 || campaignStatusArray === []) { //kiran-2836-Removing console warnings
            this.setState({ campID: "Select Campaign", generateBtnFlag: false, advLeadDetailsDelivery: [] })
        }
        else if ((advertiserID && advertiserID !== "Select Advertiser") && (campaignStatusArray && campaignStatusArray.length > 0)) {
            this.onCampaignDetailsHandleChange(advertiserID, campaignStatusArray);//sunita-task-3413-function called to load the campaign after selecting advertiser and campaign status .
        }
        else {
            this.setState({ generateBtnFlag: false, advLeadDetailsDelivery: [] })
        }


    }//end of onCampaignStatusHandleChange


    /**
    * @author : Rutuja Jagtap task- 4654
    * @description : handlechange to target selected leadstatus .
    */

    onLeadStatusHandleChange(leadStatus) {
        let campaignLeadStatusArray = [];
        let checkAccepted = leadStatus.some(o => o.name === "Delivered")
        if (checkAccepted) { campaignLeadStatusArray.push("Delivered") }

        let checkRejected = leadStatus.some(o => o.name === "Review-Pending")
        if (checkRejected) { campaignLeadStatusArray.push("Review-Pending") }

        let checkInQA = leadStatus.some(o => o.name === "Accepted")
        if (checkInQA) { campaignLeadStatusArray.push("Accepted") }

        let checkInDiQA = leadStatus.some(o => o.name === "Rejected")
        if (checkInDiQA) { campaignLeadStatusArray.push("Rejected") }

        // let checkInQA = leadStatus.some(o => o.name === "In QA")
        // if (checkInQA) { campaignLeadStatusArray.push("In QA") }
        

        let advertiserID = this.state.advertiserID;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let campaignStatusArray = this.state.campaignStatusArray;
        let campaignLeadStatusArray1 = this.state.campaignLeadStatusArray;
    
        if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate")
         && (advertiserID && advertiserID !== "Select Advertiser") 
        && (campaignLeadStatusArray1 && campaignLeadStatusArray1.length > 0) && (selectedCampaign && selectedCampaign.length > 0) 
        && (campaignStatusArray && campaignStatusArray.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0});//kiran-2836-Removing console warnings
            }
         


        this.setState({ leadStatus, campaignLeadStatusArray })
        if ((advertiserID && advertiserID !== "Select Advertiser") && (campaignStatusArray && campaignStatusArray.length > 0)) {

           // this.onCampaignDetailsHandleChange(advertiserID, campaignStatusArray); //function called to load the campaign after selecting advertiser and campaign status .
        }

        if ((advertiserID && advertiserID !== "Select Advertiser") && (selectedCampaign && selectedCampaign.length > 0)  && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campaignStatusArray && campaignStatusArray.length > 0)) {

        }
        else {
            this.setState({ generateBtnFlag: false, advLeadDetailsDelivery: [] })
        }

    }




    /**
    * @author : Rutuja Jagtap task- 4654
    * @description : handleChange for table data after selecting values from all filters .
    */

    searchHandleChange(e) {
     
     this.setState({ loader_progress: "loader_campaign_list" });// added loader

        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
      
        const { user } = this.props.auth;
        const agencyID = user.id;
        let campaignStatusArray = this.state.campaignStatusArray;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
         let advertiserID = this.state.advertiserID;
        this.setState({ agencyID })
            
             if ((advertiserID && advertiserID !== "Select Advertiser")&& (selectedCampaign && selectedCampaign.length > 0)  && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0)  && (endDate && endDate !== "Select Campaign")) {
           
                let data = {
                    selectedCampaign: selectedCampaign,
                    endDate: endDate,
                    startDate: startDate,
                    leadStatus: campaignLeadStatusArray,
                    advertiserID: advertiserID,
                    campaignStatus: campaignStatusArray,

                }
                
                fetch("/publisherReports/advLeadDetailsDelivery", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'

                    },
                    body: JSON.stringify(data)
                }).then((res) => res.json())
                    .then(advLeadDetailsDelivery => {
                        this.setState({ loader_progress: "" }); //-stoping loader                       
                        if(advLeadDetailsDelivery.result.length>0)
                        {
                            this.setState({ advLeadDetailsDelivery: advLeadDetailsDelivery.result, })
                            let leadCount = this.state.advLeadDetailsDelivery.length;
                            this.setState({leadCount : leadCount});
                        }
                        else{
                            Swal.fire({
                                type: "warning",
                                title: "No records Found",
                                confirmButtonText: "Ok",
                                allowOutsideClick: false,
                                preConfirm: () => {
                                 window.location.reload();
                                  },
                              });
                        }
                     
                    }).catch(function (err) { console.log(err) });
                   

            }
           

            else{
                Swal.fire({
                    type: "warning",
                    title: "Please Select All Mandatory Fields",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false
                  });
                  this.setState({ loader_progress: "" }); 
            }

        }
   /**
       * @author : Rutuja Jagtap task- 4654
       * @description : Searching table information
       */
        
     handleChangeSearch(e) {
		e.preventDefault();
       let currentList = [];
		let newList = [];
    
		let filterData = e.target.value;
        
         
        
		// If the search bar isn't empty
		if (e.target.value === "") {
           
			newList = this.state.getCampaignListByADVSearch;
         
		} else {
           
			// Assign the original list to currentList
			currentList = this.state.getCampaignListByADVSearch;
            
			newList = currentList.filter((item) => {
				let obj = {};// Add specific Keys in search criteria
				obj[`campID`] = item.campID;
				 obj[`campaignName`] = item.campaignName;
				obj[`agencyCampID`] = item.clientCampID;
			
				const lc = obj;
				
				const filter = e.target.value.toLowerCase();
				
				var data = Object.values(lc);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
          
		}
   
		// Set the filtered state based on what our rules added to newList
		this.setState({
			getCampaignListByADV: [...newList],
			tableSearchText: filterData,
		});
	}

    
    
   
    componentWillMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
        }
        else {                                                          
            let reportKey, reportID, advertiserID, reportName, type;
            if (this.props.location.state !== undefined) {
                advertiserID = this.props.location.state.advID;
                reportKey = this.props.location.state.reportKey;
                reportID = this.props.location.state.reportID;
                reportName = this.props.location.state.reportName;
                this.setState({reportID:reportID,reportName:reportName}); //kiran-2836-Removing console warnings
                type = this.props.location.state.type;
            }

            // const { isAuthenticated } = this.props.auth; //kiran-2836-Removing console warnings
            const { user } = this.props.auth;
            const pID = user.id;
            this.setState({
                pID: pID,
                advertiserID: advertiserID,
                reportKey: reportKey,
                generateBtnFlag: false,
                reportName: reportName,
                type: type,
                
            })

            
        //Fetching advertiser list 
            fetch("/publisherReports/clientNameDetails", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify()
            }).then(res => res.json())
                .then(advertiserList => {

                    this.setState({ advertiserList, advertiserList1: advertiserList })

                }
                ).catch(function (err) { console.log(err) });

        }

    }//end of componentWillMount



    /**
* @author : rutuja jagtap
* @description :Render part to display complete screen with functionality as per given screen .
*/

    render() {
        console.log(this.state)
        const asterisk =
        {
            color: 'red',
        }

        const columnsReport = [
            {
                title: <span>agencyCampID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campID",
                key: "campID",
                width: "22%",

                align: "left",
                sorter: (a, b) => a.campID - b.campID,
                
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.clientCampID}
                        </span>
                        <br />
                    </div>

                ),
                

            },
            {
                title: <span>Camp ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campID",
                key: "campID",
                width: "15%",

                align: "left",
                sorter: (a, b) => a.campID - b.campID,
                
                render: (text, record) => (
                    <div class="dot1" style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.campID}
                        </span>
                        <br />
                    </div>

                ),
                

            },
            {
                title: <span>Campaign Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campID",
                key: "campID",
                width: "58%",

                align: "left",
                sorter: (a, b) => a.campID - b.campID,
                
                render: (text, record) => (
                    <div  class="dot1" style={{ width: "300px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.campaignName}
                        </span>
                        <br />
                    </div>

                ),
                

            },
           ]

            const rowSelection = {
                onChange: (key, selectedRows) => {
                    selectedCampaign = selectedRows;
                },
                onSelection: this.onSelection,
                hideDefaultSelections: true,
    
                getCheckboxProps: (record) => ({
                    disabled: record.name === "Disabled User", // Column configuration not to be checked
                    name: record.name,
                }),
            };
            
         
    

        function onChange(pagination, filters, sorter) {
            console.log("params", pagination, filters, sorter);
        }

        return (
            <div>
            
            <LeadReviewLoader
              loadingRef={this.loadingRef}
              closeLoadingRef={this.closeLoadingRef}
              message="Please Wait. Report is generating..."
            />
            
                <PublisherNavigation />
                <div style={{ paddingBottom: '10px', paddingTop: '100px' }} class="container-fluid ">
                    <div class="row">


                        <div class=" col-xs-2 col-lg-2" >
                            {/*3721-Rutuja Kale-VAPT-removing # -Agency side--Reports menu--URL Links & API-FE*/}
                            {/* kiran-2836-Removing console warnings-Add the {“#/”} instead of {void(0)} */}
                            <a href={"#/"} onClick={this.backButtonHandleChange} style={{ color: '#056eb8' }} ><FaArrowAltCircleLeft size={32} title="Back to agencyMyReport" /></a>

                        </div>


                        <div class=" col-xs-10 col-lg-6" align="center">
                            <label id="labelDI"> Lead Report By Campaign</label>
                        </div>

                    </div>
                  
                    <div class="row">
                        <div class="col-lg-6" style={{ marginTop:"1%"}}>
                        <div class="col-lg-6 " id="AD_ALTD1" >
                    <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Advertiser&nbsp;:<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;</label><br />
                                
                                <select 
                                defaultValue={this.state.clientID}
                                value={this.state.advertiserID} 
                                class="form-control" 
                                name="AdvertiserName" 
                                //id="AD_ALTD1" 
                                style={{ width: '220px', height: '34px', border: '1px solid #cccccc', backgroundColor: "#FFFFFF", borderRadius: '3px', fontFamily: 'sans-serif', color: '#555555' }} 
                                onChange={this.onAdvertiserNameHandleChange}
                                >
                                    <option value={"Select Advertiser"} 
                                    style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }}>
                                        Select Advertiser</option>
                                    {this.state.advertiserList.map((advertiserList, i) => (
                                        <option value={advertiserList.clientName} key={i} 
                                        style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }}>
                                            {advertiserList.clientName}</option>))}
                                </select>
                                </div>
                                <div class="col-lg-6" id="AD_ALTD1" >
                                <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Campaign Status&nbsp;:<span style={asterisk}>*</span>&nbsp;</label><br />
                                            <div
                                        style={{ width: '220px', border: " 1px solid #CCCCCC", borderRadius: " 3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: " #555555" }}>
                                        <Picky
                                            value={this.state.campaignStatus}
                                            options={campaignStatusOptions}
                                            onChange={this.onCampaignStatusHandleChange}
                                            open={false}
                                            valueKey="id"
                                            labelKey="name"
                                            multiple={true}
                                            includeSelectAll={true}
                                            includeFilter={false}
                                            placeholder='Select Status'
                                            dropdownHeight={200}
                                            numberDisplayed={0}
                                            id="CampaignStatus"
                                            name="CampaignStatus"
                                            className={"PickyStyle"}
                                        />
                                    </div>
                    </div>
                        <div class="col-lg-6" id="AD_ALTD1" style={{ marginTop:"3%"}}><label  id="label" style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}><b>Start date </b> <span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                        {/* kiran-2836-Removing console warnings -removed required*/}
                            <input 
                            style={{ width: '220px', border: " 1px solid #CCCCCC", borderRadius: " 3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: " #555555" }}
                            type="date" id="startDate" name="startDate" ref={(input) => { this.nameInput = input; }} min="" pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"

                                value={this.state.startDate}
                                className={this.state.inputClassSdate}
                                onChange={this.handleChange}
                                // onClick={this.showInvoice}
                                class="form-control" required /></div>
                   
                    <div class="col-lg-6   " id="AD_ALTD1" style={{ marginTop:"3%"}}>
                    <label id="label" style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}><b>End date </b> <span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>

                            <input 
                            style={{ width: '220px', border: " 1px solid #CCCCCC", borderRadius: " 3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: " #555555" }}
                            type="date" id="endDate" name="endDate" ref={(input) => { this.nameInput = input; }} min="" required pattern="[0-9]{2}-[0-9]{}-[0-9]{4}"
                                value={this.state.endDate}
                                onChange={this.handleChange}
                                //onClick={this.onCampaignLeadDetailsHandleChange}
                                className="form-control"
                                />
                
                    </div>
                    <div class="col-lg-6  " id="AD_ALTD1" style={{ marginTop:"3%"}}>
                      <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Lead Status&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;</label><br />
                            
                                <div
                                    style={{ width: '220px', border: " 1px solid #CCCCCC", borderRadius: " 3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: " #555555" }}>
                                    <Picky
                                         value={this.state.leadStatus}
                                         options={leadStatusOptions}
                                         onChange={this.onLeadStatusHandleChange}
                                        open={false}
                                        valueKey="id"
                                        labelKey="name"
                                        multiple={true}
                                        //includeSelectAll={true}
                                        placeholder={'Select Status'}
                                       // includeFilter={true}
                                        dropdownHeight={200}
                                        numberDisplayed={0}
                                        id="leadStatus"
                                        name="leadStatus"
                                        className={"PickyStyle"}
                                    /></div>
                
                    </div>
                    
                    </div>
                    <div align="center"  id={this.state.loader_progress}></div>
                    <div class="col-lg-6" style={{ marginTop:"1%"}}>
                    <div class="row">
                        <div class="col-lg-6" id="AD_ALTD" >
                        <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>&nbsp;Campaign &nbsp;:<span style={asterisk}>*</span>&nbsp;</label><br />                        </div>
                                     <div class="col-xs-12 col-sm-12 col-md-12  col-lg-6 col-xl-6">
								<input
									id="search-bar11"
									type="text"
									onkeyup="myFunction()"
                                    
									style={{
										backgroundImage: "url(Group_294.png)",
										backgroundPosition: "15px 6px",
										backgroundSize: "15px 15px",
										backgroundRepeat: "no-repeat",
										width: "100%",
										height: "20px",
										fontSize: "10px",
										border: "1px solid #ddd",
										padding: "12px 20px 12px 34px",
										borderRadius: "25px",
										backgroundColor: "rgb(221, 221, 221)",
                                        margin: "0px 0px 20px 10px",
                                        
									}}
									onChange={this.handleChangeSearch}
									onPressEnter={this.handleChangeSearch}
									placeholder="Search..."
									title="Type in a name"
								/>
							</div>
                            </div>  
                                     
                            
                                     <Table
								{...this.state.tableState}
								rowSelection={rowSelection}
                                name ="test"
								id="myTable"
								bordered
								dataSource={this.state.getCampaignListByADV}
								loading={this.state.loading2}
								onChange={onChange}
								columns={columnsReport}
                                className={`${tableCSS} "ant-table-thead table-responsive"`}
								scroll={{ y: 150 }}
								pagination={{ pageSize: this.state.pageSize2 }}></Table>            
                                {/* kiran-2836-Removing console warnings-changed className*/}
                    </div>
                    <div class="col-lg-12 " id="AD_ALTD"  style={{ marginTop:"1%"}}>
                    <button
                                            type="button"
                                            class="btn add-button"
                                           
                                            onClick={this.searchHandleChange}>
                                         Search
                                        </button>
                
                    </div>
                  
                    </div>
                             
                                    
                                    <div class="row" style={{ marginTop:"1%"}}>
                        {this.state.leadCount > 0 ? (
                        <div style={{ backgroundColor: 'white',width:"85%", marginLeft:"20px", marginBottom: '30px', border: '1px solid #cccccc', borderRadius: '3px' }}>
                            <div style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: '10px' }}>
                                    {/* <div>
                                        {this.state.totalCount > 0 ? ( */}
                                                <div class="row">

                                                        <div class="col-sm-2 col-md-8 col-lg-8">

                                                            <label style={{ color: "#14254A",paddingTop: "1%",paddingLeft: "2%" }}>
                                                                <span>Total Leads Count&nbsp;:&nbsp;</span>
                                                                <span style={{ color: "#14254A" }}>
                                                                    &nbsp;{this.state.leadCount}
                                                                </span>
                                                            </label>
                                                            
                                                        </div> 
                        
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <button
                                                                    type="button"
                                                                    class="btn add-button" 
                                                                    style={{ float:"Right",marginRight: "40%"}}
                                                                    value={this.state.clientLeadDetails}
                                                                    onClick={this.handledownloadLead}>
                                                                    Download
                                                            </button>
                                                        </div>
                                                </div>
                                        {/* ):("")}
                                    </div> */}
                            </div>
                        </div>
                     ):("")}
                    </div></div>
                <Footer />

                </div>
            
            
        );
    }
}

publisherLeadReportByCampaign.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(publisherLeadReportByCampaign));

