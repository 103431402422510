/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author: Snehal More
*@fileName :clientSetup.js
 *Desc:  salesforce client set up for salesforce lead
*/

import React from 'react';
import {Component} from 'react';
import AdminNavpage from  '../layouts/adminNavpage';
import Footer from '../layouts/footer';
import './clientSetup.css'; //comment remove
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
//import {Tooltip,Icon} from 'antd'; //Rutuja 4796 commented as never used
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3927-VAPT URL changes
const Swal = require('sweetalert2')
 class ClientSetup extends Component {
    constructor(){ 
        super() 
        this.state={
            clientID:'',
            mappingFile:'',
            fileName:'',
            fileName1:'',
            fileName2:'',
            privateKeyPath:'',
            publicCertificatePath:'',
            mappingFileName:'',
            privateKeyPathName:'',
            publicCertificatePathName:'',
            checkboxDisplay:'none',
            clientNameDisplay:"none",
            agencyNameDisplay:'none',
            emailDisplay:"none",
            emailDisplay1:"none",
            userNameDisplay:"none",
            userNameDisplay1:"none",
            passwordDisplay:"none",
            passwordDisplay1:"none",
            tokenDisplay:"none",
            tokenDisplay1:"none",
            loginUrlDisplay:"none",
            loginUrlDisplay1:"none",
            apiVersionDisplay:"none",
            apiVersionDisplay1:"none",
            mappingFileDisplay:"none",
            mappingFileDisplay1:'none',
            JWTConsumerKeyDisplay :'none', 
            JWTConsumerKeyDisplay1 :'none',
            ConsumerSecretDisplay:'none',
            ConsumerSecretDisplay1:'none',
            TokenURLDisplay:'none',
            TokenURLDisplay1:'none',
            JWTAUDDisplay:'none',
            JWTAUDDisplay1:'none',
            PrivateKeyPathDisplay:'none',
            PrivateKeyPathDisplay1:'none',
            PublicCertificatePathDisplay:'none',
            PublicCertificatePathDisplay1:'none',
            simple:'',
            app:'',
            mappingnameDisplay:'none',
            privateFileNameDisplay:'none',
            publicFileNameDisplay:'none',
            isChecked:'false',
            allClientNameDetails:[],
            allAgencyNameDetails:[],
            ClientSetupArray:[{clientName:"",agencyName:'',email:"",userName:"",password:"",token:"",loginURL:"",apiVersion:"",jwtConsumerKey:'',consumerSecret:'',jwtAud:'',tokenURL:'',app:'',simple:""}],
        }
        this.commonhandleChange=this.commonhandleChange.bind(this);
        this.submitForm=this.submitForm.bind(this);
        this.validateForm=this.validateForm.bind(this);
        this.handleChangeCheck = this.handleChangeCheck.bind(this);
    }
componentWillMount(){ 
    if(!this.props.auth.isAuthenticated) {
        this.props.history.push('/userLogin');
      }
      else
      {      
        fetch("/admin/allClientNameDetails" ) //Rutuja 4796 console warrning
        .then(res => res.json())
        .then((allClientNameDetails=>{
        this.setState({allClientNameDetails: allClientNameDetails})
        }))
        fetch("/admin/allAgencyNameDetails") //Rutuja 4796 cnsole warning
        .then(res => res.json())
        .then((allAgencyNameDetails=>{
        this.setState({allAgencyNameDetails: allAgencyNameDetails})
        }))
    }
}
downloadmappingFile(e) {//kiran-4881-function for download file
    e.preventDefault();
    let { mapping, mappingFileName } = this.state.ClientSetupArray[0];
    const buf = Buffer.from(mapping);
    var newBlob = new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = mappingFileName;
    link.click();
}
clientSetupBackButton(e){
    //const {user} = this.props.auth //Rutuja 4796 commented as never used
  //var adminID= user.id; //Rutuja 4796 commented as never used
  window.location.href="adminDashboard"; // Rutuja - 4212 Back button shows ID in URL
  e.preventDefault()
  }
commonhandleChange(e){ 
    e.preventDefault()
    const{name,value}=e.target
    if(name==="clientName"){
        document.getElementById('clientName1').style.border='1px solid lightgray';
        document.getElementById('agencyName').style.border='1px solid lightgray';
        document.getElementById('email').style.border='1px solid lightgray';
        document.getElementById('userName').style.border='1px solid lightgray';
        document.getElementById('password').style.border='1px solid lightgray';
        document.getElementById('token').style.border='1px solid lightgray';
        document.getElementById('loginURL').style.border='1px solid lightgray';
        document.getElementById('apiVersion').style.border='1px solid lightgray';
        document.getElementById('jwtConsumerKey').style.border='1px solid lightgray';
        document.getElementById('consumerSecret').style.border='1px solid lightgray';
        document.getElementById('jwtAud').style.border='1px solid lightgray';
        document.getElementById('tokenURL').style.border='1px solid lightgray';
        document.getElementById('mappingFile').value="";
        document.getElementById('publicCertificatePath').value="";
        document.getElementById('privateKeyPath').value="";
        this.setState({clientNameDisplay:"none",mappingFileName:'',publicCertificatePathName:"",privateKeyPathName:"",
           agencyNameDisplay:'none',emailDisplay:"none", emailDisplay1:"none",
            userNameDisplay:"none",userNameDisplay1:"none",passwordDisplay:"none",passwordDisplay1:"none",
            tokenDisplay:"none",tokenDisplay1:"none",loginUrlDisplay:"none",loginUrlDisplay1:"none",
            apiVersionDisplay:"none",apiVersionDisplay1:"none",mappingFileDisplay:"none",mappingFileDisplay1:'none',
            JWTConsumerKeyDisplay :'none',JWTConsumerKeyDisplay1 :'none',ConsumerSecretDisplay:'none',ConsumerSecretDisplay1:'none',
            TokenURLDisplay:'none',TokenURLDisplay1:'none',JWTAUDDisplay:'none',JWTAUDDisplay1:'none',
            PrivateKeyPathDisplay:'none',PrivateKeyPathDisplay1:'none',PublicCertificatePathDisplay:'none',PublicCertificatePathDisplay1:'none'   
        }); //Rutuja 4796 removed clientNameDisplay:"none" as two times used
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "clientName": value }; //rutuja 4796 removed [] from "clientName"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });  
        if(this.state.ClientSetupArray[0].clientName.length>0 && this.state.ClientSetupArray[0].agencyName.length>0){
            let data={
                clientSetupDetails:this.state.ClientSetupArray
            }
            fetch("/admin/salesForceClientSetupDetails", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
                }).then(res => res.json()).then(salesForceClientSetupDetails=> { 
                    if(salesForceClientSetupDetails.length>0){
                        
                    this.setState({ ClientSetupArray:salesForceClientSetupDetails, 
                        mappingFileName:salesForceClientSetupDetails[0].mappingFileName,
                        mappingFile:salesForceClientSetupDetails[0].mapping,
                        privateKeyPath:salesForceClientSetupDetails[0].privateKeyPath,
                        privateKeyPathName:salesForceClientSetupDetails[0].privateKeyPathName,
                        publicCertificatePath:salesForceClientSetupDetails[0].publicCertificatePath,
                        publicCertificatePathName:salesForceClientSetupDetails[0].publicCertificatePathName,
                        mappingnameDisplay:'none',privateFileNameDisplay:'none',publicFileNameDisplay:'none'
                        })
                    Swal.fire({              
                        text: ("Salesforce client setup is already exists"),
                        type: 'question',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,                    
                      })
                }  
               
                    else{
                       var ClientSetupArray=[{clientName:this.state.ClientSetupArray[0].clientName,
                        agencyName:this.state.ClientSetupArray[0].agencyName,email:"",userName:"",password:"",
                       token:"",loginURL:"",apiVersion:"",jwtConsumerKey:"",consumerSecret:"",
                       jwtAud:"",tokenURL:"",publicCertificatePathName:"",privateKeyPathName:"",app:'No',simple:'No'}]
                       this.setState({ ClientSetupArray:ClientSetupArray, fileName:'', fileName1:'', fileName2:'',mappingnameDisplay:'none',privateFileNameDisplay:'none',publicFileNameDisplay:'none'})
                       Swal.fire({              
                        text: ("Salesforce client setup not exists"),
                        type: 'question',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,                   
                      })
                    }              
                }) 
            }        
    }    
    if(name==="agencyName"){
        document.getElementById('clientName1').style.border='1px solid lightgray';
        document.getElementById('agencyName').style.border='1px solid lightgray';
        document.getElementById('email').style.border='1px solid lightgray';
        document.getElementById('loginURL').style.border='1px solid lightgray';
        document.getElementById('apiVersion').style.border='1px solid lightgray';
        document.getElementById('userName').style.border='1px solid lightgray';
        document.getElementById('mappingFile').value="";
        document.getElementById('password').style.border='1px solid lightgray';
        document.getElementById('token').style.border='1px solid lightgray';   
        document.getElementById('jwtConsumerKey').style.border='1px solid lightgray';
        document.getElementById('consumerSecret').style.border='1px solid lightgray';
        document.getElementById('jwtAud').style.border='1px solid lightgray';
        document.getElementById('tokenURL').style.border='1px solid lightgray';        
        document.getElementById('publicCertificatePath').value="";
        document.getElementById('privateKeyPath').value="";
        this.setState({agencyNameDisplay:"none",mappingFileName:'',publicCertificatePathName:"",privateKeyPathName:"",
            clientNameDisplay:"none",emailDisplay:"none", emailDisplay1:"none",
            userNameDisplay:"none",userNameDisplay1:"none",passwordDisplay:"none",passwordDisplay1:"none",
            tokenDisplay:"none",tokenDisplay1:"none",loginUrlDisplay:"none",loginUrlDisplay1:"none",
            apiVersionDisplay:"none",apiVersionDisplay1:"none",mappingFileDisplay:"none",mappingFileDisplay1:'none',
            JWTConsumerKeyDisplay :'none',JWTConsumerKeyDisplay1 :'none',ConsumerSecretDisplay:'none',ConsumerSecretDisplay1:'none',
            TokenURLDisplay:'none',TokenURLDisplay1:'none',JWTAUDDisplay:'none',JWTAUDDisplay1:'none',
            PrivateKeyPathDisplay:'none',PrivateKeyPathDisplay1:'none',PublicCertificatePathDisplay:'none',PublicCertificatePathDisplay1:'none'
        });//Rutuja 4796 removed agencyNameDisplay as used 2 times

        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "agencyName": value }; //rutuja 4796 removed [] from "agencyName"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray  }); 
        
        if(this.state.ClientSetupArray[0].clientName.length>0 && this.state.ClientSetupArray[0].agencyName.length>0){
        let data={
            clientSetupDetails:this.state.ClientSetupArray
        }
        fetch("/admin/salesForceClientSetupDetails", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
            }).then(res => res.json()).then(salesForceClientSetupDetails=> {
                if(salesForceClientSetupDetails.length>0){
                this.setState({ ClientSetupArray:salesForceClientSetupDetails,
                    mappingFileName:salesForceClientSetupDetails[0].mappingFileName,
                    mappingFile:salesForceClientSetupDetails[0].mapping,
                    privateKeyPath:salesForceClientSetupDetails[0].privateKeyPath,
                    privateKeyPathName:salesForceClientSetupDetails[0].privateKeyPathName,
                    publicCertificatePath:salesForceClientSetupDetails[0].publicCertificatePath,
                    publicCertificatePathName:salesForceClientSetupDetails[0].publicCertificatePathName,
                    mappingnameDisplay:'none',privateFileNameDisplay:'none',publicFileNameDisplay:'none'
                })
                Swal.fire({              
                    text: ("Salesforce client setup is already exists"),
                    type: 'question',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,                  
                  })
            }  
                else{
                   var ClientSetupArray=[{clientName:this.state.ClientSetupArray[0].clientName,
                    agencyName:this.state.ClientSetupArray[0].agencyName,email:"",userName:"",
                    password:"",token:"",loginURL:"",apiVersion:"",mappingFileName:'',
                    jwtConsumerKey:"",consumerSecret:"",jwtAud:"",tokenURL:"",publicCertificatePathName:"",
                    privateKeyPathName:"",app:'No',simple:'No'}]
                   this.setState({ ClientSetupArray:ClientSetupArray,mappingnameDisplay:'none',fileName:'',
                   privateFileNameDisplay:'none',publicFileNameDisplay:'none',fileName1:'',fileName2:''})
                   Swal.fire({              
                    text: ("Salesforce client setup not exists"),
                    type: 'question',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,                   
                  })
                }              
            }) 
        }       
    }    
    if(name==="email"){
        document.getElementById('email').style.border='1px solid lightgray';
        this.setState({emailDisplay:"none"});
        this.setState({emailDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "email": value }; //rutuja 4796 removed [] from "email"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }
    if(name==="userName"){
        document.getElementById('userName').style.border='1px solid lightgray';
        this.setState({userNameDisplay:"none"});
        this.setState({userNameDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "userName": value }; //rutuja 4796 removed [] from "userName"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }
    
    if(name==="password"){
        document.getElementById('password').style.border='1px solid lightgray';
        this.setState({passwordDisplay:"none"});
        this.setState({passwordDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "password": value }; //rutuja 4796 removed [] from "password"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }
    if(name==="token"){
        document.getElementById('token').style.border='1px solid lightgray';
        this.setState({tokenDisplay:"none"});
        this.setState({tokenDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "token": value }; //rutuja 4796 removed [] from "token"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }
    if(name==="loginURL"){
        document.getElementById('loginURL').style.border='1px solid lightgray';
        this.setState({loginUrlDisplay:"none"});
        this.setState({loginUrlDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "loginURL": value }; //rutuja 4796 removed [] from "loginURL"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }
    if(name==="apiVersion"){
        document.getElementById('apiVersion').style.border='1px solid lightgray';
        this.setState({apiVersionDisplay:"none"});
        this.setState({apiVersionDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "apiVersion": value }; //rutuja 4796 removed [] from "apiversion"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }
    if(name==="mappingFile"){
        let files=e.target.files;
        let fileData=e.target.files[0];
        var fileName=e.target.files[0].name;
        var HTML = "<b>";
        HTML += 'Uploaded File : ' + fileName ;
        HTML += "</b>";
        document.getElementById("mappingName").innerHTML = HTML;
       
        if(files.length>0)
        {
            this.setState({mappingFile:fileData,mappingFileDisplay:'none',mappingFileDisplay1:'none',mappingFileName:"",mappingnameDisplay:'block'})
        }
    } 
    if(name==="jwtConsumerKey"){
        document.getElementById('jwtConsumerKey').style.border='1px solid lightgray';
        this.setState({JWTConsumerKeyDisplay:"none"});
        this.setState({JWTConsumerKeyDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "jwtConsumerKey": value };//rutuja 4796 removed [] from "jwtConsumerKey"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }   
    if(name==="consumerSecret"){
        document.getElementById('consumerSecret').style.border='1px solid lightgray';
        this.setState({ConsumerSecretDisplay:"none"});
        this.setState({ConsumerSecretDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "consumerSecret": value };//rutuja 4796 removed [] from "consumerSecret"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }   
    if(name==="jwtAud"){
        document.getElementById('jwtAud').style.border='1px solid lightgray';
        this.setState({JWTAUDDisplay:"none"});
        this.setState({JWTAUDDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "jwtAud": value }; //rutuja 4796 removed [] from "jwtAud"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }   
    if(name==="tokenURL"){
        document.getElementById('tokenURL').style.border='1px solid lightgray';
        this.setState({TokenURLDisplay:"none"});
        this.setState({TokenURLDisplay1:"none"});
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "tokenURL": value };//rutuja 4796 removed [] from "tokenURL"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray });    
    }   
        if(name==="privateKeyPath"){
            let files=e.target.files;
            let fileData=e.target.files[0];
            var fileName1=e.target.files[0].name;
            let HTML = "<b>"; //Rutuja 4796 chnaged var to let
            HTML += 'Uploaded File : ' + fileName1 ;
            HTML += "</b>";
            document.getElementById("privateFileName").innerHTML = HTML;
            if(files.length>0)
                {    
                                
                    this.setState({privateKeyPath:fileData,PrivateKeyPathDisplay:'none',PrivateKeyPathDisplay1:'none',privateKeyPathName:"",privateFileNameDisplay:'block'})
                }
            } 
            if(name==="publicCertificatePath"){
                let files=e.target.files;
                let fileData=e.target.files[0];
                var fileName2=e.target.files[0].name;
                let HTML = "<b>"; //Rutuja 4796 changed var to let
                HTML += 'Uploaded File : ' + fileName2 ;
                HTML += "</b>";
                document.getElementById("publicFileName").innerHTML = HTML;
                if(files.length>0)
                    {
                        
                        this.setState({publicCertificatePath:fileData,PublicCertificatePathDisplay:'none',PublicCertificatePathDisplay1:'none',publicCertificatePathName:"",publicFileNameDisplay:'block'})
                    }
                } 
}


handleChangeCheck(e){
    
let fields = this.state;
//const {name, value } = e.target;//Rutuja 4796 commneted as never used
fields[e.target.name] = e.target.value;
 //var simple="No"; //Rutuja 4796 commneted as never used
 var app="No";
        document.getElementById('password').style.border='1px solid lightgray';
        document.getElementById('token').style.border='1px solid lightgray';   
        document.getElementById('jwtConsumerKey').style.border='1px solid lightgray';
        document.getElementById('consumerSecret').style.border='1px solid lightgray';
        document.getElementById('jwtAud').style.border='1px solid lightgray';
        document.getElementById('tokenURL').style.border='1px solid lightgray';        
        document.getElementById('publicCertificatePath').value="";
        document.getElementById('privateKeyPath').value="";
        //publicCertificatePathName:"",privateKeyPathName:"",
        this.setState({
        passwordDisplay:"none",passwordDisplay1:"none",
        tokenDisplay:"none",tokenDisplay1:"none",JWTConsumerKeyDisplay :'none',JWTConsumerKeyDisplay1 :'none',ConsumerSecretDisplay:'none',ConsumerSecretDisplay1:'none',
        TokenURLDisplay:'none',TokenURLDisplay1:'none',JWTAUDDisplay:'none',JWTAUDDisplay1:'none',
        PrivateKeyPathDisplay:'none',PrivateKeyPathDisplay1:'none',PublicCertificatePathDisplay:'none',PublicCertificatePathDisplay1:'none'})
 if (e.target.value==="app") {
         
        if (e.target.checked===true) {

            app = 'Yes'
        } else {
            
        }
 }
 else {
    app = 'No';
  //  simple=""; //Rutuja 4796 console warning
}
 if (e.target.value==="simple") {             
        if (e.target.checked===false) {

         // simple=''; //Rutuja 4796 console warning
          app="Yes"
        }
    }
        let ClientSetupArray=[...this.state.ClientSetupArray]
        ClientSetupArray[0] = { ...ClientSetupArray[0], "app": app }; //rutuja 4796 removed [] from "app"
        this.state.ClientSetupArray=[...ClientSetupArray];
        this.setState({ ClientSetupArray:ClientSetupArray,isChecked: !this.state.isChecked ,checkboxDisplay:'none'});
//console.log("ClientSetupArray---->"+JSON.stringify(ClientSetupArray)); //4796 commented console
}
   
validateForm(){
    let formIsvalid=true;
    const {clientName,email,agencyName,userName,password,token,loginURL,apiVersion,jwtConsumerKey,consumerSecret,jwtAud,tokenURL} =this.state.ClientSetupArray[0];//Rutuja4796 removed app as never used
    if(clientName==="" ) 
    {
        document.getElementById('clientName1').style.border='1px solid red';
       this.setState({clientNameDisplay:"block"});
        formIsvalid=false;
    }
    if(agencyName==="" ) 
    {
        document.getElementById('agencyName').style.border='1px solid red';
       this.setState({agencyNameDisplay:"block"});
        formIsvalid=false;
    }
    if(email==="" )
    {
        document.getElementById('email').style.border='1px solid red';
       this.setState({emailDisplay:"block"});
        formIsvalid=false;        
    }
    if(email!=="" )
    {
        //Saurabh – Task- 3701 – Email Validation added new regular expression
        var pattern1 = new RegExp(/^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i);
            if (!pattern1.test(email)) 
            {
                document.getElementById('email').style.border='1px solid red';
                this.setState({emailDisplay1:"block"});
                formIsvalid=false;  
            }
    }
    if(userName==="" )
    {
        document.getElementById('userName').style.border='1px solid red';
       this.setState({userNameDisplay:"block"});
        formIsvalid=false;
    }
    if(userName!=="" )
    {
            let pattern1 = new RegExp(/^(?!.*__.*)(?!.*\.\..*)[A-Za-z0-9_.@$]+$/); //Rutuja 4796 changed var to let
            if (!pattern1.test(userName)) 
            {
                document.getElementById('userName').style.border='1px solid red';
                this.setState({userNameDisplay1:"block"});
                formIsvalid=false;  
            }
    }
    
    if(this.state.app==="false"&& this.state.simple==="false" ){       
        this.setState({checkboxDisplay:"block"}); 
         formIsvalid=false;
    }
    if(this.state.app==="No"&& this.state.simple==="No" ){       
        this.setState({checkboxDisplay:"block"}); 
         formIsvalid=false;
    }
    else{
    }


    if(this.state.app==="No" || this.state.ClientSetupArray[0].app==="No"){
    if(password==="" || password===null)
    {
        document.getElementById('password').style.border='1px solid red';
        this.setState({passwordDisplay:"block",passwordDisplay1:"none"}); 
        formIsvalid=false;
    }
    if(password!=="" && password!==null)
    {
            let pattern1 = new RegExp(/^(?=.{6,})/); //Rutuja 4796 changed var to let
            if (!pattern1.test(password)) 
            {
                document.getElementById('password').style.border='1px solid red';
                this.setState({passwordDisplay1:"block"});
                formIsvalid=false;
            }
    }
    if(token==="" || token===null)
    {
        document.getElementById('token').style.border='1px solid red';
        this.setState({tokenDisplay:"block"});
        formIsvalid=false; 
    }
    if(token!=="" && token!==null )
    {
            let pattern1 = new RegExp(/^(?=.*[A-Za-z0-9])[A-Za-z0-9]+$/); //Rutuja 4796 changed var to let
            if (!pattern1.test(token)) 
            {
                document.getElementById('token').style.border='1px solid red';
                this.setState({tokenDisplay1:"block"});
                formIsvalid=false; 
            }
    }
    }
    else{
    }
    if(loginURL==="" )
    {
        document.getElementById('loginURL').style.border='1px solid red';
       this.setState({loginUrlDisplay:"block"});
        formIsvalid=false;
    }
    if(loginURL!=="" )
    {
            let pattern1 = new RegExp(/(http(s)?:)?([\w-]+\.)+[\w-]+([\w- ;,.?%&=]*)?/); //Rutuja 4796 changed var to let
            if (!pattern1.test(loginURL))
            {
                document.getElementById('loginURL').style.border='1px solid red';
                this.setState({loginUrlDisplay1:"block"});
                formIsvalid=false;
            }
    }
    if(apiVersion==="" )
    {
        document.getElementById('apiVersion').style.border='1px solid red';
       this.setState({apiVersionDisplay:"block"});
        formIsvalid=false;
    }
    if(apiVersion!=="" )
    {
            let pattern1 = new RegExp(/^(?=.*[1-9])\d*(?:\.\d{1})?$/); //Rutuja 4796 changed var to let
            if (!pattern1.test(apiVersion)) 
            {
                document.getElementById('apiVersion').style.border='1px solid red';
                this.setState({apiVersionDisplay1:"block"});
                formIsvalid=false;
            }
    }
    
        if(this.state.mappingFile==="" )
        {   
            if(this.state.mappingFileName==="" )
            {       
            this.setState({mappingFileDisplay:"block"});
            formIsvalid=false;    
            }
        }
    var allowedFiles = [".xlsx"];
    //var mappingFile1 = document.getElementById("mappingFile");
    var mappingFile1="";
    if(this.state.mappingFileName===""){
            mappingFile1= document.getElementById("mappingFile");
            var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");
                        if(this.state.mappingFile!=="" )//Rutuja 4796 changed= to ==
                        {
                            if (!regex.test(mappingFile1.value.toLowerCase()))
                            {
                                this.setState({mappingFileDisplay1:"block"});
                                formIsvalid=false;
                            }
                        }
    }else{
        mappingFile1= this.state.mappingFileName;
        let regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$"); //Rutuja 4796 changed var to let
                        if(this.state.mappingFile!=="" ) //Rutuja 4796 changed= to ==
                        {
                            if (!regex.test(mappingFile1.toLowerCase()))
                            {
                                this.setState({mappingFileDisplay1:"block"});
                                formIsvalid=false;
                            }
        
    }
    }
    if(this.state.app==="Yes"|| this.state.ClientSetupArray[0].app==="Yes"){
    if(jwtConsumerKey==="" || jwtConsumerKey===null)
    {
        document.getElementById('jwtConsumerKey').style.border='1px solid red';
       this.setState({JWTConsumerKeyDisplay:"block"});
        formIsvalid=false;
    }
    if(jwtConsumerKey!=="" && jwtConsumerKey!==null )
    {
            let pattern1 = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z0-9\!|&@$#%?_.\_*\n]+$/); //Rutuja 4796 changed var to let
            if (!pattern1.test(jwtConsumerKey)) 
            {
                document.getElementById('jwtConsumerKey').style.border='1px solid red';
                this.setState({JWTConsumerKeyDisplay1:"block"});
                formIsvalid=false; 
            }
    }
    if(consumerSecret==="" || consumerSecret===null)
    {
        document.getElementById('consumerSecret').style.border='1px solid red';
       this.setState({ConsumerSecretDisplay:"block"});
        formIsvalid=false;
    }
    if(consumerSecret!=="" && consumerSecret!==null)
    {
            let pattern1 = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z0-9]+$/);//Rutuja 4796 changed var to let
            if (!pattern1.test(consumerSecret)) 
            {
                document.getElementById('consumerSecret').style.border='1px solid red';
                this.setState({ConsumerSecretDisplay1:"block"});
                formIsvalid=false; 
            }
    }
    if(jwtAud==="" || jwtAud===null)
    {
        document.getElementById('jwtAud').style.border='1px solid red';
       this.setState({JWTAUDDisplay:"block"});
        formIsvalid=false;
    }
    if(jwtAud!=="" && jwtAud!==null)
    {
            let pattern1 = new RegExp(/(http(s)?:)?([\w-]+\.)+[\w-]+([\w- ;,.?%&=]*)?/); //Rutuja 4796 changed var to let
            if (!pattern1.test(jwtAud))
            {
                document.getElementById('jwtAud').style.border='1px solid red';
                this.setState({JWTAUDDisplay1:"block"});
                formIsvalid=false;
            }
    }
    if(tokenURL==="" || tokenURL===null)
    {
        document.getElementById('tokenURL').style.border='1px solid red';
       this.setState({TokenURLDisplay:"block"});
        formIsvalid=false;
    }
    if(tokenURL!=="" && tokenURL!==null)
    {
            let pattern1 = new RegExp(/(http(s)?:)?([\w-]+\.)+[\w-]+([\w- ;,.?%&=]*)?/); //Rutuja 4796 changed var to let
            if (!pattern1.test(tokenURL))
            {
                document.getElementById('tokenURL').style.border='1px solid red';
                this.setState({TokenURLDisplay1:"block"});
                formIsvalid=false;
            }
    }
    if(this.state.privateKeyPath==="" )
    {   
        if(this.state.privateKeyPathName==="" )
        {            
        this.setState({PrivateKeyPathDisplay:"block"});
        formIsvalid=false;    
        }
    }
    var allowedFiles1 = [".pem"];
    var privateKeyPath1="";
    if(this.state.privateKeyPathName==="" ){
        privateKeyPath1= document.getElementById("privateKeyPath");
            let regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles1.join('|') + ")$"); //Rutuja 4796 changed var to let
                        if(this.state.privateKeyPath!=="" )//Rutuja 4796 changed = to ==
                        {
                            if (!regex.test(privateKeyPath1.value.toLowerCase()))
                            {
                                this.setState({PrivateKeyPathDisplay1:"block"});
                                formIsvalid=false;
                            }
                        }
    }else{
        privateKeyPath1= this.state.privateKeyPathName;
        let regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles1.join('|') + ")$");//Rutuja 4796 changed var to let
                        if(this.state.privateKeyPath!=="" )//RUtuja 4796 changed = to ==
                        {
                            if (!regex.test(privateKeyPath1.toLowerCase()))
                            {
                                this.setState({PrivateKeyPathDisplay1:"block"});
                                formIsvalid=false;
                            }
    }    
    }

    if(this.state.publicCertificatePath==="")
        {  
            if(this.state.publicCertificatePathName==="" )
            {     
            this.setState({PublicCertificatePathDisplay:"block"});
            formIsvalid=false;    
            }
        }
        var allowedFiles2 = [".crt",".txt",".dat"];
    //var publicCertificatePath1 = document.getElementById("privateKeyPath");
    var publicCertificatePath1="";
    if(this.state.publicCertificatePathName===""){
        publicCertificatePath1= document.getElementById("publicCertificatePath");
            let regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles2.join('|') + ")$"); //Rutuja 4796 changed var to let
                        if(this.state.publicCertificatePath!=="" )//Rutuja 4796 changed = to ==
                        {
                            if (!regex.test(publicCertificatePath1.value.toLowerCase()))
                            {
                                this.setState({PublicCertificatePathDisplay1:"block"});
                                formIsvalid=false;
                            }
                        }
    }else{
        publicCertificatePath1= this.state.publicCertificatePathName;
        //publicCertificatePath1= document.getElementById("publicCertificatePath");
        let regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles2.join('|') + ")$"); //Rutuja 4796 changed var to let                      
                        if(this.state.publicCertificatePath!=="" )//Rutuja 4796 changed = to ==
                        {  
                            if (!regex.test(publicCertificatePath1.toLowerCase()))
                            {
                                this.setState({PublicCertificatePathDisplay1:"block"});
                                formIsvalid=false;
                            }
                         }    
    
    }
    }
    
    else{
    }
    return formIsvalid;
}

submitForm(e){
    e.preventDefault();
    if(this.validateForm()=== false)
            {
            }
    else{
    var data = new FormData();
    data.append("clientSetupDetails",JSON.stringify(this.state.ClientSetupArray));
    data.append("mappingFileNameIfExist",this.state.mappingFileName);
    data.append("mappingFile",this.state.mappingFile);
    data.append("privateKeyFileNameIfExist",this.state.privateKeyPathName);   
    data.append("privateKeyPath",this.state.privateKeyPath);    
    data.append("publicCertificateFileNameIfExist",this.state.publicCertificatePathName);   
    data.append("publicCertificatePath",this.state.publicCertificatePath);
    fetch("/admin/salesForceClientSetup", {
    method: "POST",
    // headers: { "Content-Type": "application/json" },
    body: data
    }).then(res => res.json()).then(response=> {
    Swal.fire({
              
        text: ("Client Salesforce Setup Added Successfully"),
        type: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        preConfirm: () => {
          window.location.reload();
        }
      })
    })
    }
}
render(){
return(
<div className="main" style={{overflowX: "hidden"}}>
        <AdminNavpage/> 
<div  style={{ paddingTop: '100px' }}>
<div class="row" >
                        <div class="col-lg-1 col-md-1 col-sm-1"  style={{float:'left',marginLeft:'40px'}}>
                        < a class="col" href='adminDashboard'  style={{color: '#056eb8',right:'33%',marginLeft:'80px'}}onClick={this.clientSetupBackButton.bind(this)} >
                        <FaArrowAltCircleLeft size={32} title="" className="backFrom_salesforce"/>
                        </a>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9" align="center">
                            <label style={{fontSize:"22px",fontWeight:"bold",color:'#056eb8'}} > Client Salesforce Setup </label>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1">
                        <button class="btn add-button"  type="submit" value="help"  style={{float:'right',marginRight:'10px'}}>{/*Priyanka--4294--fix help button responsive issue*/}
                            <a href="./clientSetupHelp" style={{color:'white'}}>  Help</a>  
                        </button> 
                        </div>
                    </div>  
</div>  
<div class="container" style={{marginTop: '10px'}} > {/* rutuja - 4212 Responsive Issue */}
            <div class="card" className="cardStep1">        
            <form method="POST" id="clientSetup" name="clientSetup">              
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>Client name</label>
                            <span className="asterisk">*</span>
                            {/* &nbsp;&nbsp; */}
                            {/* <Tooltip   placement="right" title="Add Client">
                                {this.state.clientID!==""?<a onClick={this.handleClientsetup}>
                                <Icon type="plus-circle" />
                                </a>:this.state.fromIncomplete=="Yes"?<a onClick={this.handleClientincomplete}>
                                <Icon type="plus-circle"/>
                                </a>:<a href="/addAdvertiser?add=+step1newCreate">
                                <Icon type="plus-circle"/>
                                </a>}
                            </Tooltip> */}
                            {/* rutuja task - 4383 responsive issue */}
                            <select id="clientName1" name="clientName" value={this.state.ClientSetupArray[0].clientName}
                            class="form-control inputStyle" required onChange={this.commonhandleChange}
                            style={{height:'calc(2.25rem + 12px)', borderRadius:'4px',width:'180px'}}>
                            <option value='' selected>Select Client</option>                          
                            {this.state.allClientNameDetails.map(clientname=>{return(
                            <option value={clientname} >{clientname}</option>
                            )})}
                            </select>
                                <span style={{display:this.state.clientNameDisplay}}className="errorMessage">
                                Please enter client name
                                </span>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <label className='labelStyle'>Agency name</label>
                            <span className="asterisk">*</span>
                            <select id="agencyName" name="agencyName" value={this.state.ClientSetupArray[0].agencyName}
                            class="form-control inputStyle" required onChange={this.commonhandleChange}
                            style={{height:'calc(2.25rem + 12px)', borderRadius:'4px',width:'180px'}}>
                            <option value='' selected>Select Agency</option>                          
                            {this.state.allAgencyNameDetails.map(agencyID=>{return(
                            <option value={agencyID} >{agencyID}</option>
                            )})}
                            </select>
                            <span style={{display:this.state.agencyNameDisplay}}className="errorMessage">
                                Please enter agency name
                                </span>
                            </div>             
                           
                            <div class="col-lg-4 col-md-6 col-sm-12">                            
                            <label className='labelStyle'>Email</label>
                            <span className="asterisk">*</span>
                            <input type="email" id="email"
                                className="form-control inputStyle"
                                name="email" required onChange={this.commonhandleChange}
                                value={this.state.ClientSetupArray[0].email}>
                                </input>
                                <span style={{display:this.state.emailDisplay}}className="errorMessage">
                                Please enter email
                                </span>
                                <span style={{display:this.state.emailDisplay1}}className="errorMessage">
                                Please enter valid email
                                </span>
                        </div> 
                            
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                            <label className='labelStyle'>Login url</label>
                            <span className="asterisk">*</span>
                            <input type="text" id="loginURL"
                                className="form-control inputStyle"
                                name="loginURL" required onChange={this.commonhandleChange}
                                value={this.state.ClientSetupArray[0].loginURL}>
                                </input> 
                                <span style={{display:this.state.loginUrlDisplay}}className="errorMessage">
                                Please enter login url
                                </span>
                                <span style={{display:this.state.loginUrlDisplay1}}className="errorMessage">
                                Please enter valid login url
                                </span>                              
                        </div>               
                    
                    <div class="col-lg-4 col-md-6 col-sm-12 ">
                            <label className='labelStyle'>API version</label>
                            <span className="asterisk">*</span>
                            <input type="text" id="apiVersion"
                                className="form-control inputStyle"
                                name="apiVersion" required onChange={this.commonhandleChange}
                                value={this.state.ClientSetupArray[0].apiVersion}>
                                </input>
                                <span style={{display:this.state.apiVersionDisplay}}className="errorMessage">
                                Please enter API version
                                </span>
                                <span style={{display:this.state.apiVersionDisplay1}}className="errorMessage">
                                Please enter valid API version
                                </span>
                        </div>   
                        <div class="col-lg-4 col-md-6 col-sm-12 ">{/*Priyanka*/}
                            <label className='labelStyle'>Mapping file</label>
                            <span className="asterisk">*</span>
                            <input type="file" id="mappingFile" style={{color:'transparent'}}
                                name="mappingFile" required onChange={this.commonhandleChange}
                                defaultValue={this.state.mappingFile}>
                                </input>
                               {this.state.mappingFileName!==''? (//RUtuja 4796 changed = to ==
                            //    Uploaded file:
                            <a href="#/"> 
                            <span><b onClick={this.downloadmappingFile.bind(this)}>&nbsp;{this.state.mappingFileName}<i class="fa fa-download"></i></b></span></a>
                            ) :("")}
                            {/* kiran-4881-added code for download mapping file */}
                            <span id={"mappingName"} className="word-wrap" style={{display:this.state.mappingnameDisplay}}>
                             </span>
                                <span style={{display:this.state.mappingFileDisplay}}className="errorMessage">
                                Please enter mapping file
                                </span>
                                <span style={{display:this.state.mappingFileDisplay1}}className="errorMessage">
                                Please upload mapping file having extensions: xlsx
                                </span>
                        </div>   
                        
                   
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                            <label className='labelStyle'>User name</label>
                            <span className="asterisk">*</span>
                            <input type="text" id="userName"
                                className="form-control inputStyle"
                                name="userName" required onChange={this.commonhandleChange}
                                value={this.state.ClientSetupArray[0].userName}>
                                </input>
                                <span style={{display:this.state.userNameDisplay}}className="errorMessage">
                                Please enter user name
                                </span>
                                <span style={{display:this.state.userNameDisplay1}}className="errorMessage">
                                Please enter valid user name
                                </span>
                            </div>
                            </div>
                        <hr/>
                        <div >
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                        {this.state.app==="Yes" || this.state.ClientSetupArray[0].app==="Yes" ?  
                         <input type="checkbox" id="simple" name="simple" value='simple' 
                         onChange={this.handleChangeCheck} disabled />                         
                        : this.state.ClientSetupArray.length>0 && this.state.ClientSetupArray[0].app==="No" ?
                        <input type="checkbox" id="simple" name="simple" value='simple' 
                         onChange={this.handleChangeCheck} checked={this.state.ClientSetupArray[0].app==="No"?"Yes":"No"}/>
                        :<input type="checkbox" id="simple" name="simple" value='simple' 
                        onChange={this.handleChangeCheck}/>}
                        &nbsp;&nbsp;
                        <label className='labelStyle'>Password/Token Through :</label>         
                        </div> <span style={{display:this.state.checkboxDisplay}}className="errorMessage"><b>
                        Please select the password/token Through or application through for adding client to salesforce
                                </b></span>
                                </div>
                                <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                            <label className='labelStyle'>Password</label>
                            <span className="asterisk">*</span>
                            {this.state.app==="Yes" || this.state.ClientSetupArray[0].app==="Yes" ?
                                <input type="password" id="password" name="password"
                                    className="form-control inputStyle"
                                    onChange={this.commonhandleChange}
                                    value='' disabled></input>
                                :<input type="password" id="password" name="password"
                                    className="form-control inputStyle"
                                    onChange={this.commonhandleChange}
                                    value={this.state.ClientSetupArray[0].password}>
                                </input>
                            }
                               
                                <span style={{display:this.state.passwordDisplay}}className="errorMessage">
                                Please enter password
                                </span>
                                <span style={{display:this.state.passwordDisplay1}}className="errorMessage">
                                Please enter password having min 6 letters
                                </span>
                            </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 " >
                            <label className='labelStyle'>Token</label>
                            <span className="asterisk">*</span>  
                            {this.state.app==="Yes" || this.state.ClientSetupArray[0].app==="Yes"?
                            <input type="text" id="token" 
                                className="form-control inputStyle"
                                name="token" required onChange={this.commonhandleChange}
                                value='' disabled>
                                </input>
                            :<input type="text" id="token" 
                                className="form-control inputStyle"
                                name="token" required onChange={this.commonhandleChange}
                                value={this.state.ClientSetupArray[0].token}>
                                </input>}
                                <span style={{display:this.state.tokenDisplay}}className="errorMessage">
                                Please enter token
                                </span> 
                                <span style={{display:this.state.tokenDisplay1}}className="errorMessage">
                                Please enter valid token
                                </span>
                        </div> 
                        </div>
                        </div>
                        <hr/>
                        <div >
                        <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                        {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                        <input type="checkbox" id="app" name="app" value='app' 
                        onChange={this.handleChangeCheck} disabled />
                        : this.state.ClientSetupArray.length>0 && this.state.ClientSetupArray[0].app==="Yes" ?
                        <input type="checkbox" id="app" name="app" value='app' 
                        onChange={this.handleChangeCheck} checked={this.state.ClientSetupArray[0].app==="Yes"?"Yes":"No"}/>
                        :<input type="checkbox" id="app" name="app" value='app' 
                        onChange={this.handleChangeCheck}/>}
                        &nbsp;&nbsp;
                        <label className='labelStyle' >Application Through :</label>
                        </div>
                        </div>
                        
                        <div class="row" >
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                            <label className='labelStyle'>JWT consumer key</label>
                            <span className="asterisk">*</span>
                            {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                            <input type="text" id="jwtConsumerKey"
                                className="form-control inputStyle" 
                                name="jwtConsumerKey" required onChange={this.commonhandleChange}
                                value='' disabled>
                                </input>
                            : <input type="text" id="jwtConsumerKey"
                                className="form-control inputStyle" 
                                name="jwtConsumerKey" required onChange={this.commonhandleChange}
                                value={this.state.ClientSetupArray[0].jwtConsumerKey}>
                                </input>
                            
                            }
                                <span style={{display:this.state.JWTConsumerKeyDisplay}}className="errorMessage">
                                Please enter JWT Consumer Key
                                </span>
                                <span style={{display:this.state.JWTConsumerKeyDisplay1}}className="errorMessage">
                                Please enter valid JWT Consumer Key
                                </span>
                            </div>
                            
                            <div class="col-lg-4 col-md-6 col-sm-12" >
                            <label className='labelStyle'>Consumer secret</label>
                            <span className="asterisk">*</span>
                            {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                                <input type="text" id="consumerSecret" name="consumerSecret"
                                    className="form-control inputStyle"
                                    onChange={this.commonhandleChange} 
                                    value='' disabled>
                                </input>
                            :<input type="text" id="consumerSecret" name="consumerSecret"
                                 className="form-control inputStyle"
                                 onChange={this.commonhandleChange} 
                                 value={this.state.ClientSetupArray[0].consumerSecret}>
                                </input>
                            }  
                                <span style={{display:this.state.ConsumerSecretDisplay}}className="errorMessage">
                                Please enter Consumer secret
                                </span>
                                <span style={{display:this.state.ConsumerSecretDisplay1}}className="errorMessage">
                                Please enter valid Consumer secret
                                </span>
                            </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                            <label className='labelStyle'>JWT AUD</label>
                            <span className="asterisk">*</span>  
                            {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                            <input type="text" id="jwtAud"
                                className="form-control inputStyle" 
                                name="jwtAud" required onChange={this.commonhandleChange}
                                value='' disabled>
                                </input>
                            :<input type="text" id="jwtAud"
                                 className="form-control inputStyle" 
                                 name="jwtAud" required onChange={this.commonhandleChange}
                                 value={this.state.ClientSetupArray[0].jwtAud}>
                                 </input>
                            }
                                <span style={{display:this.state.JWTAUDDisplay}}className="errorMessage">
                                Please enter JWT AUD
                                </span> 
                                <span style={{display:this.state.JWTAUDDisplay1}}className="errorMessage">
                                Please enter JWT AUD
                                </span>
                        </div> 
                        
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                            <label className='labelStyle'>Token URL</label>
                            <span className="asterisk">*</span>
                            {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                            <input type="text" id="tokenURL"
                                className="form-control inputStyle" 
                                name="tokenURL" required onChange={this.commonhandleChange}
                                value='' disabled>
                                </input>
                            : <input type="text" id="tokenURL"
                                className="form-control inputStyle" 
                                name="tokenURL" required onChange={this.commonhandleChange}
                                value={this.state.ClientSetupArray[0].tokenURL} >
                                </input>
                            }
                                <span style={{display:this.state.TokenURLDisplay}}className="errorMessage">
                                Please enter  Token URL
                                </span>
                                <span style={{display:this.state.TokenURLDisplay1}}className="errorMessage">
                                Please enter valid  Token URL
                                </span>
                            </div>
                        
                            <div class="col-lg-4 col-md-6 col-sm-12" >
                            <label className='labelStyle'>Private key path</label>{/*Priyanka*/}
                            <span className="asterisk">*</span>
                           
                            {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                            <input type="file" id="privateKeyPath" style={{color:'transparent'}}
                                name="privateKeyPath" required onChange={this.commonhandleChange}
                                defaultValue='' disabled="true">
                                </input>
                            :<input type="file" id="privateKeyPath" style={{color:'transparent'}}
                                name="privateKeyPath" required onChange={this.commonhandleChange}
                                defaultValue={this.state.ClientSetupArray[0].privateKeyPath}>
                                </input>
                            }
                            {/*Priyanka-4294-added below condition to display filename when checkbox of app is on */}     
                            {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ?
                            ""
                            :<span><b>&nbsp;{this.state.privateKeyPathName}</b></span>}  {/*remove uploaded 4428 path task*/}
                            <span id={"privateFileName"} className="word-wrap word-wrapmargin  " style={{display:this.state.privateFileNameDisplay}}>{/*add class task4428 vrushanh */}
                             </span>                        
                                <span style={{display:this.state.PrivateKeyPathDisplay}}className="errorMessage">
                                Please enter Private key path
                                </span>
                                <span style={{display:this.state.PrivateKeyPathDisplay1}}className="errorMessage">
                                Please upload Private key path file having extensions: pem
                                </span>
                            </div> 
                        <div class="col-lg-4 col-md-6 col-sm-12 ">
                            <label className='labelStyle'>Public certificate path</label>
                            <span className="asterisk">*</span>
                            {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No" ? 
                            <input type="file" id="publicCertificatePath" style={{color:'transparent'}}
                                name="publicCertificatePath" required onChange={this.commonhandleChange}
                                defaultValue='' disabled="true">
                                </input>
                            :<input type="file" id="publicCertificatePath" style={{color:'transparent'}}
                                name="publicCertificatePath" required onChange={this.commonhandleChange}
                                defaultValue={this.state.ClientSetupArray[0].publicCertificatePath}>
                                </input>
                                
                            }
                            {/*Priyanka-4294-added below condition to display filename when checkbox of app is on */}
                            {this.state.app==="No" || this.state.ClientSetupArray[0].app==="No"?
                            ""
                            :<span><b>&nbsp;{this.state.publicCertificatePathName}</b></span>}{/*remove uploaded 4428 path task*/}
                            <span id={"publicFileName"} className="word-wrap word-wrapmargin" style={{display:this.state.publicFileNameDisplay}}>{/*add class task4428 vrushanh */}
                             </span>
                                <span style={{display:this.state.PublicCertificatePathDisplay}}className="errorMessage">
                                Please enter Public certificate path
                                </span>
                                <span style={{display:this.state.PublicCertificatePathDisplay1}}className="errorMessage">
                                Please upload Public certificate path file having extensions: crt
                                </span>
                        </div>   
                        </div>
                        </div>
            </form>
            <div class="float-right" style={{marginRight:'50px'}}>
                <button className="btn add-button" value="submit" onClick={this.submitForm} 
                >Submit</button>
            </div><br/><br/>
   </div>
 </div> 
 <Footer/>
</div>
);
}
}
/**
       * @author snehal more
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      ClientSetup.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })

export default connect(mapStateToProps, { logoutUser })(withRouter(ClientSetup));