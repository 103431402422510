/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Sandeep Dhawale
@Creation Date:10-05-2021
@Description:UI for advertiser report under Advertiser view(under story 3448)
*/
import React from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Tooltip } from "antd"; //kiran-4748-removing console warning-no used of Input, Button, Icon, Divider
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import './advertiserMyReports.css';
import { css } from 'emotion';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //kiran - task 3867- added default header
import { saveAs } from "file-saver"; //rutuja -task 4497 added for downloading report
const xlsx = require("xlsx"); //rutuja -task 4497 added for downloading report
var dateTime = require("node-datetime"); //rutuja -task 4497 added for taking current date
const Swal = require("sweetalert2");
// const queryString = require("query-string");//kiran-4748-removing console warning-no used
const tableCSS = css({
  backgroundColor: 'white',
  fontFamily: "sans-serif", 
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize: '12px !important',
    fontWeight: '500',
  },
  '& thead > tr >th': {
    textAlign: "center !important",
    color: 'white',
  }
});


class advertiserMyReport extends React.Component {
  constructor() {
    super();
    this.state = {
      agencyID: '',
      reportName: '',
      reportKey:"report",
      reportNameDisplay: 'none',
      reportTypeDisplay: 'none',
      tableSearchText: "",
      reportType: 'Agency Campaign Delivery Tracking',//Sandeep-task-3479-added code for report type
      myReportDetails: [],
      agencyMyReportsData:[],//Sandeep-task-3479-added code for filter data
      reportDetailsSearch: [],
      pageSize2: 100,
      loader2: "",//Sandeep-task-3485-added code for loader issue 
      token: '',
      tableState: { size: 'small' }
    }
    this.commonhandleChange = this.commonhandleChange.bind(this);
    this.validateReportData = this.validateReportData.bind(this);
    this.createReportButton = this.createReportButton.bind(this);
    this.editReportButton = this.editReportButton.bind(this);
    // this.myReportBackButton = this.myReportBackButton.bind(this);
     //Sandeep-task-3485-commented  code for back button  
    this.handleEditReport = this.handleEditReport.bind(this);
    this.handleChangeReportType = this.handleChangeReportType.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.newhandleChange=this.newhandleChange.bind(this);//Sandeep-task-3589-added code for pop up issue
    this.onClickReportName = this.onClickReportName.bind(this); //kiran - added for call from report name
    this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);   //Chaitanya-4181-My report-create new report
    this.deleteReport = this.deleteReport.bind(this); //rutuja task-4496 added handlechange to delete report
  }// end of constrcutor


  //start of componentWillMount
   /**
* @author : Sandeep Dhawale-task 3448
* @description : calling componentWillMount to render data .
*/
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
      // const { user } = this.props.auth; //kiran-4748-removing console warning-no used user,advertiserID,token
      // var advertiserID = user.id;
      var reportType = this.state.reportType;
      // const token = user.token;
        this.setState({ loader2: "loader_report3" })
        //Sandeep-task-3485-added code for loader issue 
      let data = {
        // user: user,
        type: reportType,
        // advertiserID:advertiserID
      };
    
      fetch("/advertiserReportEngine/getAdvertiserReports ", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // authorization: token,
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(myReportDetails => {
          // alert("myReportDetails" + JSON.stringify(myReportDetails))
          this.setState({ myReportDetails: myReportDetails, loader2: "",agencyMyReportsData:myReportDetails }, function () {

          })
        }).catch(function (err) { console.log(err) });
    }
  }

  //kiran - added for call from report name
  onClickReportName(e) {

    e.preventDefault();
    // const { isAuthenticated } = this.props.auth;//kiran-4748-removing console warning-no used
    var reportType = e.target.getAttribute("reportType");
    var reportID = e.target.getAttribute("reportID");
    var reportName = e.target.getAttribute("reportName");
  
    var reportKey = e.target.getAttribute("reportKey");
    // var agencyID = e.target.getAttribute("agencyID");//kiran-4748-removing console warning-no used
    
    
    if(reportType ==="Agency Campaign Delivery Tracking"){

      this.props.history.push("/advertiserDeliveryTrackingReport",{reportID,reportKey,reportName});
      // alert("report key====" +reportKey)
    }
    else if(reportType==="Agency Lead Tracking"){
      this.props.history.push("/advertiserLeadTracking",{reportID,reportKey,reportName});
      // alert("report key" +reportKey)
      // this.props.history.push("/advertiserLeadTracking",{reportID},{reportKey});
    }
    
  }


   /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange for validateReportData .
*/
  validateReportData() {
    let formIsvalid = true;
    var reportName1 = this.state.reportName.replace(/ /g, "")
    if (reportName1 === "") { //kiran-4748-removing console warning-changed == to ===
      this.setState({ reportNameDisplay: 'block', })
      formIsvalid = false;
      return formIsvalid;
    }
   
    var pattern=RegExp(/^(?=.*?[A-Za-z]).+/)     
   // eslint-disable-next-line no-useless-escape
   var pattern2 = new RegExp(/^[_()\/+&@\-\. ]$/);
    if (!pattern.test(reportName1) || pattern2.test(reportName1)) {
      this.setState({ reportNameDisplay: 'block' })
      formIsvalid = false;
      return formIsvalid;
    }
    if (this.state.reportType === "") {//kiran-4748-removing console warning-changed == to ===
      this.setState({ reportTypeDisplay: 'block' })
      formIsvalid = false;
      return formIsvalid;
    }
    return formIsvalid;
  }
     /**
 * @author Somnath Keswad
 * @param  Description Task-3981 Add Back Button
 * @return return on Dashboard Page
 */
  myReportBackButton(e) {
    e.preventDefault();
    this.props.history.push("/advertiserDashboard")
  }//handel change for back button
  /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange for createReportData .
*/
  createReportButton(e) {
    e.preventDefault();
    // var pID = user.id;
    var reportName = this.state.reportName;
    var reportType = this.state.reportType;
    if (this.validateReportData() === false) { }
    // Sandeep-task-3479-change in report type
    else if (reportType==="Agency Lead Tracking"){
      this.props.history.push('advertiserLeadTracking',{reportName,reportType});//Somnath Task-4024, Redirect URL
      // window.location.href = "advertiserLeadTracking?reportName=" + reportName + "&reportType=" + reportType;
    }
    else {
      this.props.history.push('advertiserDeliveryTrackingReport',{reportName,reportType});//Somnath Task-4024, Redirect URL
      // window.location.href = "advertiserDeliveryTrackingReport?reportName=" + reportName + "&reportType=" + reportType;
      
    }
  }//handel change for report create button
  /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange for editReportData .
*/
  editReportButton() {
    // const { user } = this.props.auth
    // const token = user.token;
    let agencyID=this.state.myReportDetails[0].agencyID;//Sandeep-task-3504 agencyID blank issue
    // let type=this.state.reportType;
    // let reportName=this.state.reportName;//kiran-4748-removing console warning-no used
    if (this.validateReportData() === false) { }
    else {
      let data = {
        // user: user,
        reportID: this.state.reportID,
        reportName: this.state.reportName,
        type:this.state.reportType,
        agencyID:agencyID,//Sandeep-task-3504 agencyID blank issue
      };
      fetch("/reportEngine/updateReportName", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // authorization: token,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          Swal.fire({
            // Sandeep-task-3500-chanage in font size
            text: "Report name edited successfully",
            icon: "Success",
            type: "success",
            confirmButtonColor: "#144999",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            } else if (result.dismiss === "close") {
              window.location.reload();
            } else {
              window.location.reload();
            }
          });
        });
    }
  }//handel edit report
  /**
* @author : Sandeep Dhawale-task 3448
* @description : commonHandleChange  for containing  agencyName,CampaignStatus and Timeline.
*/
  commonhandleChange(e) {
    const { name, value } = e.target
    if (name.toString()=== "reportName") { //kiran-4748-added toString() and changed == to ===
      this.setState({ reportName: value, reportNameDisplay: 'none' });
    }
    if (name.toString() === "reportType") {
      this.setState({ reportType: value, reportNameDisplay: 'none' });
    }
  }


     /**
       * @author Sandeep Dhawale
       * @param  Description handle change newHandleChange
       * @return Description to blank the report name fileld whenever we click on +create new report
       */
  // Sandeep-task-3589-addde code for cross button and cancel button issue
  newhandleChange(){
    this.setState({reportName:'',reportType:"Agency Campaign Delivery Tracking"})
  }
 /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange for handle ReportData .
*/
  handleEditReport(e) {
    e.preventDefault();
    // const { isAuthenticated, user } = this.props.auth;
    var reportID = e.target.getAttribute("reportID");
    var reportName = e.target.getAttribute("reportName");
    var reportType = e.target.getAttribute("reportType");
    this.setState({ reportID: reportID, reportName: reportName,reportType})
  }//Function handle change for edit report
  /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange for handleReportType.
*/
  handleChangeReportType(e) {
    const { name, value } = e.target
    // const { isAuthenticated, user } = this.props.auth;//kiran-4748-removing console warning-no used
    let type=e.target.value;
    if (name.toString() === "reportType") { //kiran-4748-added toString() and changed == to ===
      this.setState({ reportType: value,tableSearchText:"" });//Sandeep-task-3588-Advertiser side---agency lead tracking---search box filter issue
    }
 
      let myReportDetails  = [...this.state.agencyMyReportsData]
      myReportDetails = myReportDetails.filter((a) => {
          return a.type === type
        })
        document.getElementById("search-bar").value = '';//Sandeep-task-3588-added code for filter issue
        //document.getElementById("searchID42").value = '';
        this.setState({myReportDetails ,type,tableSearchText:""})//Sandeep---task-3588-Advertiser side---agency lead tracking---search box filter issue
     
    
  }//handle change for ReportType

  /**
* @author : Sandeep Dhawale-task 3448
* @description : function for Searching data on page .
*/ 
  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    this.setState({filterData})//Sandeep-task-3588-Advertiser side---agency lead tracking---search box filter issue
    // If the search bar isn't empty
    if (e.target.value === "") { //kiran-4748-removing console warning-changed == to ===
      newList = this.state.myReportDetails;
    } else {
      // Assign the original list to currentList
      currentList = this.state.myReportDetails
      newList = currentList.filter((item) => {
        let obj={};//Somnath Task-3972, Add required comumn in search fields
        obj[`reportID`]=item.reportID;
        obj[`reportName`]=item.reportName;
        obj[`createdBy`]=item.createdBy;
        obj[`created`]=item.created;
        obj[`lastUpdated`]=item.lastUpdated;
        const lc = obj;
        //const lc = item.toLowerCase();
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });
    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      reportDetailsSearch: [...newList],
      tableSearchText: filterData,
    });
  }//handle search box
   /**
* @author : Sandeep Dhawale-task 3448
* @description : handlechange for pageSize .
*/
  handleChangePageSize2(e) {
    var pageSize2 = e.target.value;
    this.setState({ pageSize2: pageSize2 });
  }


  
 /**
* @author : Rutuja Jagtap-task 4496
* @description :  Added api in this function for deleting Report.
*/

deleteReport(e) {
 
  // const { user } = this.props.auth;//kiran-4748-removing console warning-no used
  var reportID = e.target.getAttribute('reportID')
  
  var type = this.state.reportType;
   let data;
       
      data = {
        reportID: reportID,
        type: type,
      }
   fetch("/advertiserReportEngine/deleteReport", {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
    .then(res => {
      if (res.success === true) {

        
        Swal.fire({
          text: "Report Deleted successfully.",
          type: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: true,
          preConfirm: () => {
           
            window.location.reload();
          },
        });
      }
      else{
        Swal.fire({
          text: "Something went wrong while deleting report",
          type: "Failure",
          confirmButtonText: "Ok",
          allowOutsideClick: true,
          preConfirm: () => {
           
            window.location.reload();
          },
        });

      }
    }
    ).catch(function (err) {
      console.log(err)
    });

}


/**
* @author : Rutuja Jagtap-task 4497
* @description :  Added api in this function for downoading Report.
*/
downloadReport(e) {
  e.preventDefault();
  var reportID = e.target.getAttribute('reportID')
 let reportName = e.target.getAttribute('reportName')
 let stackHolder = e.target.getAttribute('stackHolder')
 var dt = dateTime.create();
   var curdate = dt.format("Y-m-d_H:M:S");
 let data = {
   reportID: reportID,
   stackHolder:stackHolder,
 }
 fetch("/advertiserReportEngine/reportDetailForADV", {
   method: 'POST',
   headers: {
     "Content-Type": "application/json",
   },
   body: JSON.stringify(data)
 }).then(res => res.json())
 .then(advertiserMyReportCampaignInfo => {

   this.setState({ advertiserMyReportCampaignInfo: advertiserMyReportCampaignInfo.reportDetail});
   advertiserMyReportCampaignInfo = this.state.advertiserMyReportCampaignInfo;
    
       if (advertiserMyReportCampaignInfo.length > 0) {
    
     var ws = xlsx.utils.json_to_sheet(advertiserMyReportCampaignInfo);
     
     var wb = xlsx.utils.book_new();
     xlsx.utils.book_append_sheet(wb, ws, "Lead");
     var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
     function s2ab(s) {
      
         var buf = new ArrayBuffer(s.length);
         var view = new Uint8Array(buf);
         for (let i = 0; i !== s.length; ++i)
             view[i] = s.charCodeAt(i) & 0xff;
         return buf;
     }

    
     var fileName = reportID  + "_" + reportName  + "_" + curdate +".xlsx";
     saveAs(
         new Blob([s2ab(buf)], { type: "application/octet-stream" }),
         fileName
     );
     Swal.fire({
      html: "Your Report has been successfully Downloaded!",
      type: "success",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
  });
          } else {
            Swal.fire({
              html: "This report data Does not exists!",
              type: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
          });
          }
 
 


}).catch(function (err) { console.log(err) });
}

  render() {
    // const { isAuthenticated, user } = this.props.auth;//kiran-4748-removing console warning-no used
    const Column = [
      {
        title: <span>Report ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'reportID',
        key: 'reportID',
        width: '100px',
        align: 'left',
        sorter: (a, b) => a.reportID - b.reportID,
        render: (text, record) =>
        //   Sandeep-task-3485-added font size 
          <div><span style={{ fontSize: '13px',color: '#4F4F4F' }}>{record.reportID}</span></div>
      },
      {
        title: <span>Report Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'reportName',
        key: 'reportName',
        width: '200px',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.reportName.toUpperCase() < b.reportName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.reportName.toUpperCase() > b.reportName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
      //  Sandeep-task-3479-added code to redirect to paticular page when click on reportName
      //   <div id="to_truncate" >    
      //   {record.type === "Agency Lead Tracking" ?
      //     <a href={"/advertiserLeadTracking?agencyID=" + record.agencyID + "&reportID=" + record.reportID + "&reportKey=" + this.state.reportKey}
      //     >{record.reportName}</a>
      //     :
      //     <a href={'advertiserDeliveryTrackingReport?advertiserID=' + record.advertiserID + "&reportID=" + record.reportID + "&reportKey=" + this.state.reportKey}

      //     >{record.reportName}</a>}
      // </div>
      <div id="to_truncate_new" title={record.reportName}>    {/*chaitanya-4187-Advertiser-My reports- Report title not showing full name on report list page*/}
   
     {/* kiran-4748-removing console warning-added "#/" */}
     <span ><a href="#/" onClick={this.onClickReportName} 
     style={{color:"#1B81D8"}} //Somnath Task-4080 Add report color blue.
       reportID={record.reportID} reportKey={this.state.reportKey} agencyID={record.agencyID} reportType={record.type} reportName={record.reportName}
     >
       {record.reportName}</a>
       
       </span>
   </div>


       
      },
      {
        title: <span>Report Type&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'type',
        key: 'type',
        width: '200px',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";
          
          if (a.type.toUpperCase() < b.type.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.type.toUpperCase() > b.type.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
           //Sandeep-task-3485-added font size 
          <div><span style={{ fontSize: '13px',color: '#4F4F4F' }}>{record.type}</span></div>
      },
      {
        title: <span>Created On&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'created',
        key: 'created',
        width: '120px',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.created.toUpperCase() < b.created.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.created.toUpperCase() > b.created.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          //Sandeep-task-3485-added font size 
          <div><span style={{ fontSize: '13px',color: '#4F4F4F' }}>{record.created}</span></div>
      },
      {
        title: <span>Created By&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: '150px',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.createdBy.toUpperCase() < b.createdBy.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.createdBy.toUpperCase() > b.createdBy.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          //Sandeep-task-3485-added font size  
          <div><span style={{ fontSize: '13px',color: '#4F4F4F' }}>{record.createdBy}</span></div>
      },
      {
        title: <span>Edited On&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'lastUpdated',
        key: 'lastUpdated',
        width: '120px',
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.lastUpdated.toUpperCase() < b.lastUpdated.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.lastUpdated.toUpperCase() > b.lastUpdated.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          //Sandeep-task-3485-added font size  
          <div><span style={{ fontSize: '13px',color: '#4F4F4F' }}>{record.lastUpdated}</span></div>
      },
      {
        title: 'Action',
        dataIndex: 'clientName',
        key: 'clientName',
        width: '160px',
        align: 'left',
        render: (text, record) =>
          <div>
             <span style={{ display: "inline-flex" }}>
            <a href="#/" style={{ float: "left" }} data-toggle="modal" data-target="#editReportModal" data-backdrop="static" data-keyboard="false">
              <Tooltip placement="top" title="Edit">
                <img
                  src="edit_report.png"
                  alt=""
                  reportID={record.reportID} reportName={record.reportName} reportType={record.type} 
                  onClick={this.handleEditReport}
                  width="20"
                  height="20"></img>
              </Tooltip>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 {/* rutuja task-4497 added download report functionality  */}
 
{/* kiran-4748-removing console warning-added "#/" */}
            <a 
                  href="#/"
                  reportID={record.reportID}
                  reportName={record.reportName}
                  stackHolder={record.stackHolder}
                  type={record.type}
                    onClick={this.downloadReport.bind(this)}>
                  <Tooltip placement="top" title="Download">
                    <img
                     reportid={record.reportID}
                     reportName={record.reportName}
                     stackHolder={record.stackHolder}
                     type={record.type}
                      src="downloadreport.png"
                      data-toggle="modal"
                      data-target="#download_report"
                      width="20"
                      height="20"
                      alt=""></img>
                  </Tooltip>
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Tooltip placement="top" title="Delete">
                <img
                  onClick={this.deleteReport}
                 reportID={record.reportID} 
                 reportType={record.type} 
                  src="file_upload_2_blue.png"
                  alt=""
                  width="20"
                  height="20"></img>
              </Tooltip>
           </span>
          </div>
      },

    ];
    function onChange(pagination, filters, sorter) {
    }
    return (
      <div >
        <Navigation />
        <div style={{ margin: '20px' }}>
          <div class="container-fluid" style={{ paddingTop: '80px' }}>
            <div class="row">
              <div class="col-lg-2 col-md-1 col-sm-1">
                 {/* Sandeep-task-3485-commented  code for back button  */}
                 
                 {/* kiran-4748-removing console warning-added "#/" */}
                < a class="col" href='#/' style={{ color: '#056eb8', float: 'left', marginLeft: "-15px" }} onClick={this.myReportBackButton.bind(this)} >
                  <FaArrowAltCircleLeft size={32} title="Back to Advertiser Dashboard" className="backFrom_myReport" />
                </a>
              </div>
             
              <div class=" col-lg-8 col-md-8 col-sm-8" align="center">
              <center><label id="labelDI" style={{fontSize:'28px', fontStyle:'roboto',fontWeight:'500',color:'#193D8F'}} align="center">My Reports</label></center>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-12">
                {/* <button class="btn add-button" type="submit" value="submit1" data-toggle="modal" data-target="#createReportModal" data-backdrop="static" data-keyboard="false" style={{ marginBottom: '5px', float: 'right' }}>
                 + Create New Report
                    </button> */}
                    {/* Sandeep-task-3589-Advertiser side----create report cancel and cross button issue */}
                    <button class="btn add-button" onClick={this.newhandleChange} type="submit" value="submit1" data-toggle="modal" data-target="#createReportModal" data-backdrop="static" data-keyboard="false" style={{ marginBottom: '5px', float: 'right' }}>
                  + Create New Report
                    </button>
                <div class="modal fade" id="createReportModal" role="dialog" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                  <div class="modal-dialog modal-sm" >
                    <div class="modal-content" id="modal-content_1" style={{ marginTop: '152px' }}>
                      <div class="modal-header" style={{ backgroundColor: "#144999",height: '50px'}}>
                        <p style={{ color: '#FFFFFF', fontSize: '17px', fontWeight: '400 ' ,fontFamily:'Roboto'}}>Create New Report
                                                <button
                            type="button"
                            class="close"
                            style={{ fontColor: '#FFFFFF', fontSize: '18px',marginTop:'-5px',fontWeight: '400'}}
                            data-dismiss="modal">
                            &times;
                                                </button></p>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 ">
                         {/* Sandeep-task-3485-added color  */}
                        <label className="labelStyle" style={{fontSize:'14px',color:'#193D8F'}}>Report Name : </label>
                        <span className="asterisk">*</span>
                        <input
                          type="text"
                          id="reportName"
                          className="form-control reportName_Advertiser"    //Chaitanya-4181-My report-create new report
                          name="reportName"
                          required
                          style={{ width: "100%" }}
                          onChange={this.commonhandleChange}
                          value={this.state.reportName}></input>
                        <ul style={{ display: this.state.reportNameDisplay }} className="errorMessage"><li style={{listStyle: 'disc outside none',display: 'list-item',marginLeft: '2em'}}>Please enter valid report name</li></ul>
                        <p></p>
                         {/* Sandeep-task-3485-added color  */}
                        <label className="labelStyle"  style={{fontSize:'14px',color:'#193D8F'}}>Report Type : </label>
                        {/* <span className="asterisk">*</span> */}
                        <select
                          id="reportType"
                          name="reportType"
                          value={this.state.reportType}
                          class="form-control report_type_createNewReport"  //Chaitanya-4181-My report-create new report
                          style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px', width: "100%" }}
                          onChange={this.commonhandleChange}>
                              {/* Sandeep-task-3479-change in report type */}
                          <option value="Agency Campaign Delivery Tracking" selected>Agency Campaign Delivery Tracking</option>
                          <option value="Agency Lead Tracking" >Agency Lead Tracking</option>
                          <option value="Financial" disabled>Financial</option>
                        </select>
                        <ul style={{ display: this.state.reportTypeDisplay }} className="errorMessage"><li style={{listStyle: 'disc outside none',display: 'list-item',marginLeft: '2em'}}>Please select report type</li></ul>
                      </div>
                      <br />
                      <div class="row">
                        <button class="btn offset-lg-1 col-lg-4 offset-md-2 col-md-3 offset-sm-2 col-sm-6 col-sm-6 offset-2 col-4" id="cancle_button" style={{ color: "#144999", float: 'left', borderColor: '#144999', backgroundColor: '#FFFFFF', border: '2px solid #144999' }} type="button" value="cancel" data-dismiss="modal"> Cancel </button>
                        <button class="btn offset-lg-2 col-lg-4 offset-md-2 col-md-3 offset-sm-2 col-sm-6 offset-2 col-4" id="create_button" type="cancel" value="cancel" onClick={this.createReportButton.bind(this)} style={{backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"}}> Create </button>         {/*4314-R-29-Prod issue-My Report-create new report-cancel button-Advertiser-(Removed add.button)*/}
                        {/* Sandeep-task-3485-added code for create button alignment issue */}
                      </div>
                      <br /> <br />
                    </div>
                  </div>
                </div>
                {/* <button class="btn add-button"  type="submit" value="help" style={{margin:'5px'}} >
                            <a href="#" style={{color:'white'}} >  Help</a>  
                        </button>    */}
              </div>
            </div>
            <div class="modal fade" id="editReportModal" role="dialog" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
              <div class="modal-dialog modal-sm" >
                <div class="modal-content" id="modal-content_1" style={{ marginTop: '152px' }}>
                  <div class="modal-header" style={{ backgroundColor: "#144999",height: '50px' ,fontFamily:'Roboto'}}>
                    <p style={{ color: '#FFFFFF', fontSize: '17px', fontWeight: '400' }}>Edit Report Name
                                                <button
                        type="button"
                        class="close"
                        style={{ fontColor: '#FFFFFF', fontSize: '18px',marginTop:'-5px',fontWeight: '400' }}
                        data-dismiss="modal">
                        &times;
                                                </button>
                    </p>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 ">
                     {/* Sandeep-task-3485-added color  */}
                    <label className="labelStyle" style={{fontSize:'14px',color:'#193D8F'}}>Report Name : </label>
                    <span className="asterisk">*</span>
                    <input
                      type="text"
                      id="reportName"
                      className="form-control reportName_Advertiser"    //Chaitanya-4181-My report-create new report
                      name="reportName"
                      required
                      style={{ width: "100%" }}
                      onChange={this.commonhandleChange}
                      value={this.state.reportName}></input>
                    <ul style={{ display: this.state.reportNameDisplay }} className="errorMessage"><li style={{listStyle: 'disc outside none',display: 'list-item',marginLeft: '2em'}}>Please enter valid report name</li></ul>
                    <p></p>
                     {/* Sandeep-task-3485-added color  */}
                    <label className="labelStyle"  style={{fontSize:'14px',color:'#193D8F'}}>Report Type : </label>
                    {/* <span className="asterisk">*</span> */}
                    <select
                      id="reportType"
                      name="reportType"
                      value={this.state.reportType}
                      className="form-control report_type_input_Adv"  //Chaitanya-4181-My report-create new report
                      disabled
                      style={{ height: 'calc(2.25rem + 12px)', borderRadius: '4px', width: "100%" }}
                      onChange={this.commonhandleChange}>
                         {/* Sandeep-task-3479-change in report type */}
                      <option value="Agency Campaign Delivery Tracking">Agency Campaign Delivery Tracking</option>
                      <option value="Agency Lead Tracking" >Agency Lead Tracking</option>
                      <option value="Financial" disabled>Financial</option>
                    </select>
                    <ul style={{ display: this.state.reportTypeDisplay }} className="errorMessage"><li style={{listStyle: 'disc outside none',display: 'list-item',marginLeft: '2em'}}>Please select report type</li></ul>
                  </div>
                  <br />
                  <div class="row">
                    <button class="btn offset-lg-1 col-lg-4 offset-md-2 col-md-3 offset-sm-2 col-sm-6 col-sm-6 offset-2 col-4" style={{marginLeft:'34px', color: "#144999", float: 'left', borderColor: '#144999', backgroundColor: '#FFFFFF', border: '2px solid #144999' }} type="button" value="cancel" data-dismiss="modal"> Cancel </button>
                    <button class="btn offset-lg-1 col-lg-4 offset-md-2 col-md-3 offset-sm-2 col-sm-6 offset-2 col-4" id="edit_button" style={{marginLeft:'43px',backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"}} type="cancel" value="cancel" data-dismiss="modal" onClick={this.editReportButton.bind(this)}> Edit </button>          {/*4314-R-29-Prod issue-My Report-create new report-cancel button-Advertiser-(Removed add.button)*/}
                     {/* Chaitanya-task-4099-There should be uniques screen of My Reports - Edit-Advertiser side*/}
                     {/* Sandeep-task-3485-added code for data-dismiss modal */}
                  </div>
                  <br /> <br />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-12 col-sm-12">
              {/* {this.state.reportType != "" ?
                <p>Showing results for <label>{this.state.reportType}</label></p> : ""} */}
                </div>
              <div class="offset-lg-4 col-lg-2 col-md-6 col-sm-6" id="advertiser_reportType" >              {/*Chaitanya-4181Ref-(4099)-Advertiser-My report-responsiveness*/}
                <select
                  id="reportType"      
                  name="reportType" 
                  value={this.state.reportType}
                  class="form-control reportType_myreportAdv"
                  style={{
                    height: 'calc(2.25rem + 12px)'
                    , width: '255px'
                    , borderRadius: '4px', float: 'right', marginRight: "-15px"
                  }}
                  onChange={this.handleChangeReportType}>
                     {/* Sandeep-task-3479-change in report type */}
                  <option value="Agency Campaign Delivery Tracking" selected >Agency Campaign Delivery Tracking</option>
                  <option value="Agency Lead Tracking" >Agency Lead Tracking</option>
                  <option value="Financial" disabled>Financial</option>
                </select>
              </div>
              <div class=" col-lg-2 col-md-5 col-sm-4" style={{ float: 'right', marginRight: '-10px' }} id="advertiser_searchbar">    {/*Chaitanya-4181 Ref-(4099)-Advertiser-My report-responsiveness*/}
                <input
                  id="search-bar"
                  type="text"
                  class="form-control search_myreport_advertiser"     //chaitanya-4181 Ref-(4099)-Advertiser-My report-responsiveness
                  style={{
                    backgroundImage: "url(searchIcon.png)",
                    backgroundPosition: "15px 6px",
                    backgroundSize: "15px 15px",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "20px",
                    fontSize: "12px",
                    padding: "16px 20px 16px 34px",
                    borderRadius: "4px",
                    border: '1px solid rgb(221, 221, 221)'
                  }}
                  onChange={this.handleChangeSearch}
                  placeholder="Search..."
                  title="Search"
                />
              </div>
            </div>
          </div>

          <div align="center" id={this.state.loader2}></div>
           {/* Sandeep-task-3485-added code for loader issue  */}
          <div class="table-responsive" style={{ marginBottom: '120px' }}>
            {this.state.tableSearchText === "" ?
             //kiran-4748-removing console warning-changed classname
             <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.myReportDetails}
                onChange={onChange} columns={Column}  className={`${tableCSS} "ant-table-thead"`} scroll={{ y: 700, x: 1000, }}
                pagination={{ pageSize: this.state.pageSize2 }}></Table>
              : <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.reportDetailsSearch}
                onChange={onChange} columns={Column} className={`${tableCSS} "ant-table-thead"`} scroll={{ y: 700, x: 1000, }}
                pagination={{ pageSize: this.state.pageSize2 }}></Table>
            }
            <p>
              <div class="row" style={{ paddingBottom: '120px' }}>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{ marginTop: '-50px' }} id="recordsPerPageAdv">    {/*Chaitanya-4181-My report-create new report*/}
                  &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                  <select
                    defaultValue={this.state.pageSize2}
                    onChange={this.handleChangePageSize2}
                    id="pacing1"
                    class="input-small"
                    className="form-control pacing_Adv"                       //Chaitanya-4181-My report-create new report
                    name="pacing" style={{ width: '80px', height: '30px', display: 'initial' }}>
                    <option value="100" selected>100</option>
                    <option value="150" >150</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                  </select>
                </div>
              </div>
            </p>

          </div>
        </div>
        <Footer />
      </div>
    );
  }// end of renders
}// end of class

/**
       * @author Sandeep Dhawale
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
advertiserMyReport.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(advertiserMyReport));

