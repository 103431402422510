/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
//import axios from 'axios';

const setAuthToken = token => {
    if(token) {
        fetch.defaults.headers.common['Authorization'] = token;
    }
    else {
        delete fetch.defaults.headers.common['Authorization'];
    }
}

export default setAuthToken;