/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

import React from 'react';
import PublisherNavigation from "../layouts/publisherNavPage";
import Footer from '../layouts/footer';
import "antd/dist/antd.css";

import { Comment, Avatar, Form, Button, List, Input } from "antd";
import moment from "moment";

const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
   
    itemLayout="horizontal"
    renderItem={props => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <div>
      <Form.Item>
        <TextArea rows={4} onChange={onChange} value={value} />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={onSubmit}
          type="primary"
        >
          Add Comment
        </Button>
      </Form.Item>
    </div>
  );

class ContactAgency extends React.Component {  

    state = {
       // comments: ["Welcome to the chat"],
       comments: [
        {
          author: "Sanjana Godbole",
          avatar:
            "DI.png",
          content: <p>Welcome to chat</p>,
          datetime: moment().calendar()
        }
        
      ],
        submitting: false,
        value: ""
         };
         
         handleSubmit = () => {
            if (!this.state.value) {
              return;
            }
        
            this.setState({
              submitting: true
            });
           // alert("this.state.value==="+JSON.stringify(this.state.comments));
            setTimeout(() => {
              this.setState({
                submitting: false,
                value: "",
                value1: "",
                comments: [
                    ...this.state.comments,
                  {
                    author: "Han Solo",
                    avatar:
                      "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                    content: <p>{this.state.value}</p>,
                    datetime: moment().calendar()
                  },
                  
                ],
               
              });
            }, 1000);
            alert("this.state.value==="+JSON.stringify(this.state.comments));

          };
        
          handleChange = e => {
            this.setState({
              value: e.target.value,
          
            });
          };
      
    render() {  

      const verticalLine = {
        borderLeft: "1px solid black",
        height:"500px"
      };

      const { comments, submitting, value } = this.state;

      return (  
        <div>
           <PublisherNavigation/>
          <div class="container-fluid" style={{paddingTop:'110px'}}>
          <div class="row">
          <div class="col-xs-3 col-sm-3 col-lg-3"><h3>Messenger</h3></div>
          <div class="col-xs-1 col-sm-1 col-lg-1"><div style={verticalLine}></div></div>
          <div class="col-xs-8 col-sm-8 col-lg-8"><h3>Conversation Title</h3>
          {comments.length > 0 && <CommentList comments={comments} />}
          <Comment
          avatar={
            <Avatar
              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              alt="Han Solo"
            />
          }
          content={
            <Editor
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              submitting={submitting}
              value={value}
            />
          }
        />
          </div>
          </div>
          </div>
          {/* end of container */}

          <Footer/>
        </div>  
      );  
    }  
  }  
  export default ContactAgency;