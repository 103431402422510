/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Sanjana Godbole
@Creation Date:19-12-2018
@Description: Request For Proposal For Publisher 

*/

import React from "react";

import * as $ from "jquery";
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { MDBRow, MDBCol } from "mdbreact";
import Footer from "../layouts/footer";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import PublisherNavigation from "../layouts/publisherNavPage";
import { Checkbox, Button } from "antd";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
const Swal = require("sweetalert2");
const queryString = require("query-string");
var dropdownValueArray = [];
var biddingCount = 0;
var countryName = [];

const channel = [
	{ id: "TeleMarketing", name: "TeleMarketing" },
	{ id: "Email", name: "Email" },
	{ id: "Email/Telemarketing", name: "Email/Telemarketing" },
	{ id: "Display", name: "Display" },
	{ id: "Programmatic", name: "Programmatic" },
	{ id: "Social", name: "Social" },
	{ id: "Hybrid", name: "Hybrid" },
	{ id: "Others", name: "Others" },
];

class RequestForProposalPublisher extends React.Component {
	constructor() {
		super();
		this.state = {
			GetBiddingDetail: [],
			biddingDocument: [],
			downloadFiles: [],
			campID: "",
			value: null,
			campaignDetails: [],
			arrayValue: [],
			publisherBidding: [],
			errors: {},
			biddingerror: {},
			alertBorder: "",
			disable: "",
			acknowledgeBtnStatus: false,
			unique: [],
			docType: "",
			biddingType: "",
		};
		this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.campaignDetails = this.campaignDetails.bind(this);
		this.RFPdocumentDownloadABM = this.RFPdocumentDownloadABM.bind(this);
		this.RFPdocumentDownloadSuppression =
			this.RFPdocumentDownloadSuppression.bind(this);
		this.RFPdocumentDownloadExclusion =
			this.RFPdocumentDownloadExclusion.bind(this);
		this.RFPdocumentDownloadOther = this.RFPdocumentDownloadOther.bind(this);
		this.handlebackButton = this.handlebackButton.bind(this);
		// this.selectValue=this.selectValue.bind(this);
		// this.removeValue=this.removeValue.bind(this);
		// this.selectMultipleOption = this.selectMultipleOption.bind(this);
		// this.handleChange=this.handleChange.bind(this)
	}

	//     handleFileShowDownload(blob){
	// alert("inside download==="+this.state.downloadFiles.length)

	//       for(var i=0;i<this.state.downloadFiles.length;i++){
	//         var fileName=this.state.downloadFiles[i].documentName;
	//         alert("inside download==>"+fileName)

	//         var extension=this.state.downloadFiles[i].extension;
	//         alert("inside download==>"+extension)

	//         if(extension==='xls'){
	//           alert("inside xls")
	//           var newBlob = new Blob([this.state.downloadFiles[i].data], {type: "application/vnd.ms-excel"});

	//           if(this.state.extension==='xlsx'){
	//             alert("inside xlsx")
	//             var newBlob = new Blob([this.state.downloadFiles[i].data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
	//           }
	//           if(this.state.extension==='csv'){
	//             alert("inside csv")
	//             var newBlob = new Blob([this.state.downloadFiles[i].data], {type: "text/csv"});
	//           }

	//         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
	//           window.navigator.msSaveOrOpenBlob(newBlob);
	//           return;
	//         }
	//           const data1 = window.URL.createObjectURL(newBlob);
	//           var link = document.createElement('a');
	//           link.href = data1;
	//           link.download=fileName;
	//           link.click();

	//         }
	//       }

	//     }

	handlebackButton(e) {
		const { user } = this.props.auth;
		this.props.history.push("/newPublisherDashboard"); //karan-task-3717-replace query params
	}
	RFPdocumentDownloadABM(e) {
		var campID = e.target.id;
		fetch("publisherDashboard/downloadRFPDocumentABM?campID=" + campID)
			.then((r) => r.blob())
			.then((downloadFiles) => {
				this.setState({ downloadFiles: downloadFiles, docType: "ABM" });
			})
			.then(this.handleFileShowDownload);
	}

	RFPdocumentDownloadSuppression(e) {
		var campID = e.target.id;
		fetch("publisherDashboard/downloadRFPDocumentSuppression?campID=" + campID)
			.then((r) => r.blob())
			.then((downloadFiles) =>
				this.setState({
					downloadFiles: downloadFiles,
					campID: campID,
					docType: "Suppression",
				})
			)
			.then(this.handleFileShowDownload);
	}

	RFPdocumentDownloadExclusion(e) {
		var campID = e.target.id;
		fetch("publisherDashboard/downloadRFPDocumentExclusion?campID=" + campID)
			.then((r) => r.blob())
			.then((downloadFiles) =>
				this.setState({
					downloadFiles: downloadFiles,
					campID: campID,
					docType: "Exclusion",
				})
			)
			.then(this.handleFileShowDownload);
	}

	RFPdocumentDownloadOther(e) {
		var campID = e.target.id;
		fetch("publisherDashboard/downloadRFPDocumentOther?campID=" + campID)
			.then((r) => r.blob())
			.then((downloadFiles) =>
				this.setState({
					downloadFiles: downloadFiles,
					campID: campID,
					docType: "Other",
				})
			)
			.then(this.handleFileShowDownload);
	}

	campaignDetails(e) {
		var campID = e.target.id;

		this.setState({ campID: campID });
		//alert("page3")//Somnath Task-3935, Redirect URL
		this.props.history.push("/publisherRfpCampaignSpecification",{proposalID:campID})
		// fetch("agencyDashboard/getRfpCampaignDetails?campID="+campID)
		// .then(res=>res.json())
		// .then(campaignDetails=>{
		//   this.setState({campaignDetails:campaignDetails})
		// }).catch(function(err){console.log(err)});
	}

	hideAlert() {
		//alert("Inside hide");
		this.setState({ disable: "drpdisable" });
		return;
	}

	removeValue(k) {
		var x = document.getElementById("selected" + k);
		var removedElement = x.value;
		x.remove(x.selectedIndex);
		if (this.state.publisherBidding[k].hasOwnProperty("marketingChannel")) {
			var channel = this.state.publisherBidding[k].marketingChannel;
			var str = channel.split(",");
			for (var i = 0; i < str.length; i++) {
				if (str[i] == removedElement) {
					str.splice(i, 1);
				}
			}
			str = str.toString();
			this.state.publisherBidding[k].marketingChannel = str;
		}
	}

	selectValue(k) {
		let itemList = document.getElementById("marketingChannel" + k);
		let outputBox = document.getElementById("selected");
		let collection = itemList.selectedOptions;
		let output = "";
		for (let i = 0; i < collection.length; i++) {
			if (output === "") {
				output = "";
			}
			output += collection[i].label;
			// alert(" 0");
		}
		if (output === "") {
			output = "You didn't order anything!";
		}
		let dropdownValueValidate = true;
		if (this.state.publisherBidding[k].hasOwnProperty("marketingChannel")) {
			var str = this.state.publisherBidding[k].marketingChannel;
			str = str.split(",");
			for (var j = 0; j < str.length; j++) {
				var dropdownValue = str[j];
				if (dropdownValue === output) {
					dropdownValueValidate = false;
					break;
				} else {
					dropdownValueValidate = true;
				}
			}
		}
		if (dropdownValueValidate === true) {
			var select = document.getElementById("selected" + k);
			var opt = document.createElement("option");
			opt.value = output;
			opt.textContent = output;
			select.appendChild(opt);
			if (this.state.publisherBidding[k].hasOwnProperty("marketingChannel")) {
				var channel = this.state.publisherBidding[k].marketingChannel;
				var channel = channel + "," + output;
				this.state.publisherBidding[k].marketingChannel = channel;
			} else {
				this.state.publisherBidding[k].marketingChannel = output;
			}
		}
	}
	// handleChange(e)
	// {
	//     let fields = this.state;
	//     fields[e.target.name] = e.target.value;

	// }

	// selectMultipleOption(value) {
	//     console.count('onChange')
	//     console.log("Val", value);
	//     this.setState({ arrayValue: value });
	//   }

	handleChange(i, e) {
		const { name, value } = e.target;
		let publisherBidding = [...this.state.publisherBidding];
		publisherBidding[i] = { ...publisherBidding[i], [name]: value };
		this.state.publisherBidding = [...publisherBidding];
		//  alert(JSON.stringify(this.state.publisherBidding))
	}

	selectMultipleOption(i, value) {
		let name = "marketingChannel";
		// var arrayValue = [...this.state.arrayValue];
		// arrayValue[i] = {...arrayValue[i],id:value.id,name:value.name};
		// this.state.arrayValue=[...arrayValue];

		// alert(JSON.stringify(this.state.arrayValue))
		// alert(JSON.stringify(this.state.arrayValue[i]))

		var countryvalue = [];
		for (var j = 0; j < value.length; j++) {
			countryvalue.push(value[j].name);
		}
		countryvalue = countryvalue.toString();
		var publisherBidding = [...this.state.publisherBidding];
		publisherBidding[i] = { ...publisherBidding[i], [name]: countryvalue };
		this.state.publisherBidding = [...publisherBidding];

		// alert(JSON.stringify(this.state.publisherBidding))
	}

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			const { user } = this.props.auth;
			var parsed = this.props.location.state;//Somnath Task-3935, Get parameter from URL
			var campID = parsed.campID;
			// Get RFP Campaign Details
			fetch("publisherDashboard/rfpBiddingDetail?campID=" + campID)
				.then((res) => res.json())
				.then((GetBiddingDetail) => {
					this.setState({
						GetBiddingDetail: GetBiddingDetail,
						biddingType: GetBiddingDetail[0].biddingType,
					});
				})
				.catch(function (err) {
					console.log(err);
				});

			// Get RFP Bidding Details Either country wise or Combine.
			fetch(
				"requestForProposal/biddingBasedOnType?campID=" +
					campID +
					"&pID=" +
					user.id
			)
				.then((res) => res.json())
				.then((publisherBidding) => {
					this.setState({ publisherBidding: publisherBidding });
					if (publisherBidding.length > 0) {
						let acknowledgeBtnStatus = false;
						let acknowledgeStatus = publisherBidding[0].acknowledgeStatus;
						if (acknowledgeStatus === "Yes") {
							acknowledgeBtnStatus = true;
						}
						this.setState({ acknowledgeBtnStatus: acknowledgeBtnStatus });
					}
				})
				.catch(function (err) {
					console.log(err);
				});

			// Get RFP Bidding document Details
			let typeArray = [];
			fetch("publisherDashboard/rfpDocumentDetail?campID=" + campID)
				.then((res) => res.json())
				.then((biddingDocument) => {
					this.setState({ biddingDocument: biddingDocument });
					for (var i = 0; i < biddingDocument.length; i++) {
						typeArray.push(biddingDocument[i].type);
					}
					function onlyUnique(value, index, self) {
						return self.indexOf(value) === index;
					}
					var unique = typeArray.filter(onlyUnique);
					this.setState({ unique: unique });
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} // componentDid

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let biddingerror = {};
		var monthlyLeadValidate = true;
		var dailyLeadValidate = true;
		var totalLeadValidate = true;
		var cplValidate = true;
		var marketingChannelValidate = true;
		let formIsValid = false;
		let countryName = [];
		let countryNameMonthly = [];
		let countryNameDaily = [];
		let countryNameTotal = [];
		let countryNameCPL = [];
		var biddingValCheck = 0;
		var commonValidate = true;
		let chkAllValid = false;
		let biddingCount = 0;
		let biddingType = this.state.biddingType;
		// alert(biddingType);
		//var pattern = new RegExp(/^[1-9]+[0-9]*$/);
		// alert("publisherBidding"+JSON.stringify(this.state.publisherBidding))

		// alert("publisherBiddinglength="+publisherBiddinglength);
		var publisherBiddingArray = this.state.publisherBidding;
		var publisherBiddinglength = publisherBiddingArray.length;
		for (let i = 0; i < publisherBiddinglength; i++) {
			let monthlyLead = publisherBiddingArray[i].monthlyLead;
			let dailyLead = publisherBiddingArray[i].dailyLead;
			let totalLead = publisherBiddingArray[i].totalLead;
			let CPL = publisherBiddingArray[i].CPL;
			let marketingChannel = publisherBiddingArray[i].marketingChannel;
			let country = publisherBiddingArray[i].country;
			if (
				monthlyLead == "" ||
				monthlyLead == undefined ||
				monthlyLead == null ||
				monthlyLead == "0"
			) {
				if (biddingCount === 0) {
					chkAllValid = false;
					biddingValCheck++;
					biddingerror["one"] =
						"Please Enter Monthly Lead Allocation For Atleast One Country";
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
				} else {
					if (dailyLead == "" || dailyLead == undefined || dailyLead == null) {
					} else {
						var pattern = new RegExp(/^[0-9]*$/);
						if (!pattern.test(dailyLead)) {
							dailyLeadValidate = false;
							if (biddingType == "Geowise") {
								countryNameDaily.push(country);
								biddingerror["dailyLead"] =
									"Please enter Daily Lead only in numeric for  " +
									countryNameDaily;
							} else {
								biddingerror["dailyLead"] =
									"Please enter Daily Lead only in numeric for region";
							}
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "1px solid red",
							});
							return dailyLeadValidate;
						} else {
							dailyLeadValidate = true;
							biddingerror["dailyLead"] = "";
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "0px solid red",
							});
						}
					}
					if (totalLead == "" || totalLead == undefined || totalLead == null) {
					} else {
						var pattern = new RegExp(/^[0-9]*$/);
						if (!pattern.test(totalLead)) {
							totalLeadValidate = false;
							if (biddingType == "Geowise") {
								countryNameTotal.push(country);
								biddingerror["totalLead"] =
									"Please enter Max. Lead Available only in numeric for  " +
									countryNameTotal;
							} else {
								biddingerror["totalLead"] =
									"Please enter Max. Lead Available only in numeric for Region";
							}
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "1px solid red",
							});
							return totalLeadValidate;
						} else {
							totalLeadValidate = true;
							biddingerror["totalLead"] = "";
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "0px solid red",
							});
						}
					}
					if (CPL == "" || CPL == undefined || CPL == null) {
					} else {
						let pattern = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/);
						if (!pattern.test(CPL)) {
							cplValidate = false;
							if (biddingType == "Geowise") {
								countryNameCPL.push(country);
								biddingerror["cpl"] =
									"Please enter Target CPL only in numeric for  " +
									countryNameCPL;
							} else {
								biddingerror["cpl"] =
									"Please enter Target CPL only in numeric for region ";
							}
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "1px solid red",
							});
							return cplValidate;
						} else {
							cplValidate = true;
							biddingerror["cpl"] = "";
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "0px solid red",
							});
						}
					}
				}
			} //End of if check undefined
			else {
				biddingCount++;
				chkAllValid = true;
				if (
					monthlyLead == "" ||
					monthlyLead == null ||
					monthlyLead == undefined
				) {
					monthlyLeadValidate = false;
					if (biddingType == "Geowise") {
						countryNameMonthly.push(country);
						biddingerror["monthlyLead"] =
							"Please enter only numeric values for  " + countryNameMonthly;
					} else {
						biddingerror["monthlyLead"] =
							"Please enter only numeric values for Region";
					}
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
					return monthlyLeadValidate;
				} else {
					var pattern = new RegExp(/^[1-9]+[0-9]*$/);
					if (!pattern.test(monthlyLead)) {
						monthlyLeadValidate = false;
						if (biddingType == "Geowise") {
							countryNameMonthly.push(country);
							biddingerror["monthlyLead"] =
								"Please enter Monthly Lead only in numeric for  " +
								countryNameMonthly;
						} else {
							biddingerror["monthlyLead"] =
								"Please enter Monthly Lead only in numeric for region";
						}
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "1px solid red",
						});
						return monthlyLeadValidate;
					} else {
						monthlyLeadValidate = true;
						biddingerror["monthlyLead"] = "";
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "0px solid red",
						});
					}
				}

				if (dailyLead == "" || dailyLead == undefined || dailyLead == null) {
					dailyLeadValidate = false;
					if (biddingType == "Geowise") {
						countryNameDaily.push(country);
						biddingerror["dailyLead"] =
							"Please Enter Daily Leads For  " + countryNameDaily;
					} else {
						biddingerror["dailyLead"] = "Please Enter Daily Leads For Region";
					}
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
					return dailyLeadValidate;
				} else {
					var pattern = new RegExp(/^[1-9]+[0-9]*$/);
					if (!pattern.test(dailyLead)) {
						dailyLeadValidate = false;
						if (biddingType == "Geowise") {
							countryNameDaily.push(country);
							biddingerror["dailyLead"] =
								"Please enter Daily Lead only in numeric for  " +
								countryNameDaily;
						} else {
							biddingerror["dailyLead"] =
								"Please enter Daily Lead only in numeric for region";
						}
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "1px solid red",
						});
						return dailyLeadValidate;
					} else {
						dailyLeadValidate = true;
						biddingerror["dailyLead"] = "";
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "0px solid red",
						});
					}
				}
				if (totalLead == "" || totalLead == undefined || totalLead == null) {
					totalLeadValidate = false;
					if (biddingType == "Geowise") {
						countryNameTotal.push(country);
						biddingerror["totalLead"] =
							"Please Enter  Max. Leads Available For  " + countryNameTotal;
					} else {
						biddingerror["totalLead"] =
							"Please Enter  Max. Leads Available For Region";
					}
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
					return totalLeadValidate;
				} else {
					var pattern = new RegExp(/^[1-9]+[0-9]*$/);
					if (!pattern.test(totalLead)) {
						totalLeadValidate = false;
						if (biddingType == "Geowise") {
							countryNameTotal.push(country);
							biddingerror["totalLead"] =
								"Please enter Max. Lead Available only in numeric for  " +
								countryNameTotal;
						} else {
							biddingerror["totalLead"] =
								"Please enter Max. Lead Available only in numeric for region";
						}
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "1px solid red",
						});
						return totalLeadValidate;
					} else {
						totalLeadValidate = true;
						biddingerror["totalLead"] = "";
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "0px solid red",
						});
					}
				}
				if (CPL == "" || CPL == undefined || CPL == null) {
					cplValidate = false;
					if (biddingType == "Geowise") {
						countryNameCPL.push(country);
						biddingerror["cpl"] = "Please Enter CPL For  " + countryNameCPL;
					} else {
						biddingerror["cpl"] = "Please Enter CPL For Region";
					}
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
					return cplValidate;
				} else {
					let pattern = new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/);
					if (!pattern.test(CPL)) {
						cplValidate = false;
						if (biddingType == "Geowise") {
							countryNameCPL.push(country);
							biddingerror["cpl"] =
								"Please enter Target CPL only in numeric for  " +
								countryNameCPL;
						} else {
							biddingerror["cpl"] =
								"Please enter Target CPL only in numeric for region";
						}
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "1px solid red",
						});
						return cplValidate;
					} else {
						cplValidate = true;
						biddingerror["cpl"] = "";
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "0px solid red",
						});
					}
				}
				if (
					marketingChannel == "" ||
					marketingChannel == undefined ||
					marketingChannel == null
				) {
					marketingChannelValidate = false;
					if (biddingType == "Geowise") {
						countryName.push(country);
						biddingerror["marketingChannel"] =
							"Please Enter AtLeast One MarketingChannel For  " + countryName;
					} else {
						biddingerror["marketingChannel"] =
							"Please Enter AtLeast One MarketingChannel For Region";
					}
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
					return marketingChannelValidate;
				} else {
					marketingChannelValidate = true;
					biddingerror["marketingChannel"] = "";
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "0px solid red",
					});
				}
			} // End of Else Block of check undefined

			if (biddingCount == 0) {
				if (publisherBiddinglength == biddingValCheck) {
				} else {
					chkAllValid = true;
					biddingerror["one"] = "";
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "0px solid red",
					});
				}
			}
			if (
				monthlyLeadValidate === false ||
				dailyLeadValidate === false ||
				totalLeadValidate === false ||
				cplValidate === false ||
				marketingChannelValidate === false ||
				chkAllValid === false
			) {
				commonValidate = false;
			}
		} // End of For Loop
		if (commonValidate === true) {
			formIsValid = true;
		} else {
			formIsValid = false;
		}
		// alert("Form is Valid "+formIsValid);
		return formIsValid;
	} //End of validateForm()

	submitBidding() {
		if (this.validateForm() === false) {
			//  alert("validate")
			return;
		} else {
			// alert("In Submit");
			// this.setState({disable:"drpdisable"});
			const { user } = this.props.auth;
			var parsed = this.props.location.state;//Somnath Task-3935, Get parameter from URL
			var campID = parsed.campID;
			let self = this;
			var data = {
				user: user,
				campID: campID,
				publisherBidding: this.state.publisherBidding,
			};

			// alert(JSON.stringify(this.state.publisherBidding))
			fetch("publisherDashboard/submitPublisherBidding", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((response) => response.json())
				.then(function (response) {
					// alert(JSON.stringify(response))
					// success message with response
					if (response.success == true) {
						// alert("befor hide")
						{
							self.hideAlert();
						}
						//alert("afterhide");
						Swal.fire({
							text: "Publisher Bidding Submitted Successfully",
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							// preConfirm:()=>{this.hideAlert()}
							// onClose:()=>{this.hideAlert()
							// // alert("called")

							// }
						});
					}
				})
				.catch(function (err) {
					console.log(err);
				});
			// this.setState({disable:"drpdisable"});
		}
	} // End of submitBidding

	Biddingview(e) {
		var parsed = this.props.location.state;//Somnath Task-3935, Get parameter from URL
		var campID = parsed.campID;
		window.location.href = "/requestForProposalBidding?campID=" + campID;
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description download the RFP Document
	 * @return Description return All The File Download in Zip
	 */
	RFPdocumentDownload(e) {
		var campID = e.target.id;
		fetch("publisherDashboard/downloadRFPDocument?campID=" + campID)
			.then((r) => r.blob())
			.then((downloadFiles) =>
				this.setState({ downloadFiles: downloadFiles, campID: campID })
			)
			.then(this.handleFileShowDownload);
	}
	handleFileShowDownload(blob) {
		let zipFileName;
		if (this.state.docType === "ABM") {
			zipFileName = this.state.campID + "RFP_ABM_Document.zip";
		}
		if (this.state.docType === "Suppression") {
			zipFileName = this.state.campID + "RFP_Suppression_Document.zip";
		}
		if (this.state.docType === "Exclusion") {
			zipFileName = this.state.campID + "RFP_Exclusion_Document.zip";
		}
		if (this.state.docType === "Other") {
			zipFileName = this.state.campID + "RFP_Other_Document.zip";
		}

		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/zip",
		});
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}
		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileName;
		link.click();
	}
	acknowledgeStatus(e) {
		e.preventDefault();
		var parsed = this.props.location.state;//Somnath Task-3935, Get parameter from URL
		var campID = parsed.campID;
		const { user } = this.props.auth;
		fetch(
			"requestForProposal/RFPAcknowledge?biddingCampID=" +
				campID +
				"&pID=" +
				user.id
		)
			.then((res) => res.json())
			.then((publisherBidding) => {
				this.setState({ publisherBidding: publisherBidding });
				if (publisherBidding.length > 0) {
					let acknowledgeBtnStatus = false;
					let acknowledgeStatus = publisherBidding[0].acknowledgeStatus;
					if (acknowledgeStatus === "Yes") {
						acknowledgeBtnStatus = true;
					}
					this.setState({ acknowledgeBtnStatus: acknowledgeBtnStatus });
				}
			})
			.catch(function (err) {
				console.log(err);
			});
	} // End of acknowledgeStatus
	render() {
		const asterisk = { color: "red" };
		return (
			<div>
				<PublisherNavigation />
				<div
					class="container-fluid"
					style={{ paddingTop: "90px", marginBottom: "60px" }}>
					<div>
						<a href="#" style={{ color: "#056eb8" }}>
							<FaArrowAltCircleLeft onClick={this.handlebackButton} size={32} />
						</a>{" "}
					</div>
					<div
						class="col"
						style={{
							fontWeight: "500",
							textAlign: "center",
							color: "#056EB8",
							fontSize: "20px",
						}}>
						Request For Proposal
					</div>
					<br />
					<legend className="legend labelsubheading">
						RFP Campaigns Details
					</legend>

					<table class="table table-bordered">
						<thead style={{ backgroundColor: "#7e7f8138" }}>
							<tr>
								<th style={{ verticalAlign: "top" }}>Proposal ID</th>
								<th style={{ verticalAlign: "top" }}>Campaign Name</th>
								<th style={{ width: "7%", verticalAlign: "top" }}>
									Start Date
								</th>
								<th style={{ width: "7%", verticalAlign: "top" }}>End Date</th>
								<th style={{ width: "7%", verticalAlign: "top" }}>
									Bidding Closes
								</th>
								<th style={{ verticalAlign: "top" }}>Marketing Channel</th>
								<th style={{ verticalAlign: "top" }}>Campaign Type</th>
								<th style={{ verticalAlign: "top" }}>Document</th>
								<th style={{ verticalAlign: "top" }}>Download Documents</th>
							</tr>
						</thead>
						<tbody>
							{this.state.GetBiddingDetail.map((item) => (
								<tr>
									<td>{item.campID}</td>
									<td>
										<a href="#" onClick={this.campaignDetails} id={item.campID}>
											{item.campaignName}
										</a>
									</td>
									<td>{item.startDate}</td>
									<td>{item.endDate}</td>
									<td>{item.biddingEndDate}</td>
									<td>{item.marketingChannel}</td>
									<td>{item.campaignType}</td>
									<td>
										{this.state.biddingDocument.map((doc) => {
											if (doc.campID == item.campID) {
												return (
													<p>
														{doc.suppDocName}
														<br />
													</p>
												);
											}
										})}
									</td>
									<td>
										{/* <a href="#"  id={item.campID}onClick={this.RFPdocumentDownload.bind(this)}>
                   Download Document
          </a> */}
										{this.state.unique.map((item1) => (
											<div>
												{item1 === "ABM" ? (
													<a
														href="#"
														id={item.campID}
														onClick={this.RFPdocumentDownloadABM.bind(this)}>
														ABM
													</a>
												) : item1 === "Suppression" ? (
													<a
														href="#"
														id={item.campID}
														onClick={this.RFPdocumentDownloadSuppression.bind(
															this
														)}>
														Suppression
													</a>
												) : item1 === "Exclusion" ? (
													<a
														href="#"
														id={item.campID}
														onClick={this.RFPdocumentDownloadExclusion.bind(
															this
														)}>
														Exclusion
													</a>
												) : item1 === "Other" ? (
													<a
														href="#"
														id={item.campID}
														onClick={this.RFPdocumentDownloadOther.bind(this)}>
														Other
													</a>
												) : (
													""
												)}
											</div>
										))}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<p></p>
					<button
						type="button"
						disabled={this.state.acknowledgeBtnStatus}
						className={this.state.disable + " " + "btn btn-info"}
						onClick={this.acknowledgeStatus.bind(this)}
						style={{
							backgroundImage: "linear-gradient(to right,#28166f,#007cc3)",
							color: "white",
							width: "min-content",
							height: "31px",
							fontSize: "14px",
						}}>
						Acknowledge
					</button>
					<br />
					<p></p>

					<legend className="legend labelsubheading">Bidding</legend>

					<div class="row">
						<div
							class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
							style={{ border: this.state.alertBorder }}>
							<div class="errorMessage">
								{this.state.biddingerror.one ? (
									<span>
										{this.state.biddingerror.one}
										<br />
									</span>
								) : (
									""
								)}

								{this.state.biddingerror.monthlyLeadBlank ? (
									<span>
										{this.state.biddingerror.monthlyLeadBlank}
										<br />
									</span>
								) : this.state.biddingerror.monthlyLead ? (
									<span>
										{this.state.biddingerror.monthlyLead}
										<br />
									</span>
								) : (
									""
								)}

								{this.state.biddingerror.dailyLeadBlank ? (
									<span>
										{this.state.biddingerror.dailyLeadBlank}
										<br />
									</span>
								) : this.state.biddingerror.dailyLead ? (
									<span>
										{this.state.biddingerror.dailyLead}
										<br />
									</span>
								) : (
									""
								)}

								{this.state.biddingerror.totalLeadBlank ? (
									<span>
										{this.state.biddingerror.totalLeadBlank}
										<br />
									</span>
								) : this.state.biddingerror.totalLead ? (
									<span>
										{this.state.biddingerror.totalLead}
										<br />
									</span>
								) : (
									""
								)}

								{this.state.biddingerror.cplBlank ? (
									<span>
										{this.state.biddingerror.cplBlank}
										<br />
									</span>
								) : this.state.biddingerror.cpl ? (
									<span>
										{this.state.biddingerror.cpl}
										<br />
									</span>
								) : (
									""
								)}

								{this.state.biddingerror.marketingChannelBlank ? (
									<span>{this.state.biddingerror.marketingChannelBlank}</span>
								) : this.state.biddingerror.marketingChannel ? (
									<span>{this.state.biddingerror.marketingChannel}</span>
								) : (
									""
								)}
							</div>
						</div>
					</div>
					{this.state.acknowledgeBtnStatus == true ? (
						<table class="table table-bordered">
							<thead style={{ backgroundColor: "#7e7f8138" }}>
								<tr>
									{/* <th style={{width:"10%"}}>Region</th> */}
									<th style={{ width: "10%" }}>Country</th>
									<th style={{ width: "9%" }}>Monthly Lead</th>
									<th style={{ width: "9%" }}>Daily Lead</th>
									<th style={{ width: "9%" }}>Max. Lead Available</th>
									<th style={{ width: "9%" }}>Target CPL</th>
									{/* <th style={{width:"10%"}}>Currency<span style={asterisk}>*</span></th> */}
									<th>Marketing Channel</th>
									<th style={{ width: "12%" }}>Notes</th>
								</tr>
							</thead>
							<tbody>
								{this.state.acknowledgeBtnStatus === true
									? this.state.publisherBidding.map((item, i) => (
											<tr>
												{/* <td>{item.region}</td> */}
												<td style={{ wordBreak: "break-all" }}>
													{item.country}
												</td>
												<td>
													{" "}
													<input
														type="text"
														id="monthlyLead"
														name="monthlyLead"
														defaultValue={item.monthlyLead}
														onChange={this.handleChange.bind(this, i)}
														className="form-control"
														required
													/>
												</td>
												<td>
													{" "}
													<input
														type="text"
														id="dailyLead"
														name="dailyLead"
														defaultValue={item.dailyLead}
														onChange={this.handleChange.bind(this, i)}
														className="form-control"
														required
													/>
												</td>
												<td>
													<input
														type="text"
														id="totalLead"
														name="totalLead"
														defaultValue={item.totalLead}
														onChange={this.handleChange.bind(this, i)}
														className="form-control"
														required
													/>
												</td>
												<td>
													<input
														type="text"
														id="CPL"
														name="CPL"
														defaultValue={item.CPL}
														onChange={this.handleChange.bind(this, i)}
														className="form-control"
														required
													/>
												</td>
												{/* <td><input type="text" id="Currency" name="Currency" 
                                  defaultValue={item.currency}
                                  onChange={this.handleChange.bind(this,i)}
                                  className="form-control"
                                  required /></td> */}
												{/* <td>  <select type="text" id="currency" name="currency" 
                               style={{height:'35px'}}
                                  defaultValue={item.currency}
                                  onChange={this.handleChange.bind(this,i)}
                                  // className="form-control"
                >

                  <option value="USD" selected>USD - US Dollar</option>
                  <option value="EUR" >EUR - Euro</option>
                  <option value="GBP">GBP - British Pound</option>
                  <option value="INR">INR - Indian Rupee</option>
                  <option value="AUD">AUD - Australian Dollar</option>
                  <option value="CAD">CAD - Canadian Dollar</option>
                  <option value="SGD">SGD - Singapore Dollar</option>
                  <option value="CHF">CHF - Swiss Franc</option>
                  <option value="MYR">MYR - Malaysian Ringgit</option>
                  <option value="JPY">JPY - Japanese Yen</option>
                  <option value="CNY">CNY - Chinese Yuan Renminbi</option>

                  </select>        </td> */}

												<td>
													{/* <label id="label">Marketing channel-Available</label> */}
													<select
														// value={this.state.marketingChannel}
														// onChange={this.handleChange}
														id={"marketingChannel" + i}
														name="marketingChannel"
														size="7"
														style={{ width: "46%", height: "60%" }}>
														<option value="Available" disabled>
															Available
														</option>
														<option value="TeleMarketing">TeleMarketing</option>
														<option value="Email" selected>
															Email
														</option>
														<option value="Email/Telemarketing">
															Email/Telemarketing
														</option>
														<option value="Display">Display</option>
														<option value="Programmatic">Programmatic</option>
														<option value="Social">Social</option>
														<option value="Hybrid">Hybrid</option>
														<option value="Others">Others</option>
													</select>
													&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
													{/* <label id="label">Marketing channel-Selected</label> */}
													<select
														// value={this.state.marketingChannel}
														// onChange={this.handleChange}
														id={"selected" + i}
														name={"selected" + i}
														size="7"
														multiple
														style={{ width: "42%", height: "60%" }}>
														<option value={"selected" + i} disabled>
															Selected
														</option>
													</select>
													<div>
														<Button
															type="primary"
															className={this.state.inputClass11}
															style={{ marginLeft: "88px" }}
															onClick={this.selectValue.bind(this, i)}>
															Add
														</Button>
													</div>
													{/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
													<div>
														<Button
															type="primary"
															className={this.state.inputClass11}
															style={{
																float: "right",
																marginRight: "6px",
																marginTop: "-32px",
															}}
															onClick={this.removeValue.bind(this, i)}>
															Remove
														</Button>
													</div>
												</td>
												<td>
													<textarea
														style={{
															width: "100%",
															height: "40%",
															resize: "none",
														}}
														value={this.state.campaignCollatral}
														rows="7"
														cols="30"
														id="note"
														name="note"
														onChange={this.handleChange.bind(this, i)}
													/>
												</td>
											</tr>
									  ))
									: ""}
							</tbody>
						</table>
					) : (
						""
					)}
					{this.state.acknowledgeBtnStatus == true ? (
						<div align="right">
							<button
								type="button"
								className={this.state.disable + " " + "btn btn-info"}
								onClick={this.submitBidding.bind(this)}
								style={{
									backgroundImage: "linear-gradient(to right,#28166f,#007cc3)",
									color: "white",
									width: "min-content",
									height: "31px",
									fontSize: "14px",
								}}>
								Submit
							</button>
						</div>
					) : (
						""
					)}
				</div>
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<h4 class="modal-title">RFP Allocation</h4>
								<button type="button" class="close" data-dismiss="modal">
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div class="table-responsive-lg">
									<table class=" table table-striped">
										<thead>
											<tr class="info">
												<th class="table-header">Publisher Name</th>
												<th class="table-header">Allocate</th>
											</tr>
										</thead>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="campDetails" class="modal fade" role="dialog">
					<div class="modal-dialog modal-lg">
						<div class="modal-content">
							<div class="modal-header custom-class">
								<h4
									class="modal-title"
									style={{
										color: "white",
										fontSize: "20px",
										paddingRight: "593px",
									}}>
									Campaign Details
								</h4>
								<button
									type="button"
									class="cancel-button pull-right"
									style={{ fontSize: 15 }}
									data-dismiss="modal">
									&times;
								</button>
							</div>
							<div class="modal-body">
								<p>
									{this.state.campaignDetails.map((campaignDetails) => (
										<div>
											{" "}
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Campaign Name{" "}
													</label>
													:&nbsp;&nbsp;
													{campaignDetails.campaignName}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>
														Campaign Type{" "}
													</label>
													:&nbsp;
													{campaignDetails.campaignType}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4"></div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>Start Date </label>
													:&nbsp;&nbsp;
													{campaignDetails.startDate}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>End Date </label>
													:&nbsp;
													{campaignDetails.endDate}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label>Bidding Closes &nbsp;&nbsp;:</label>
													{campaignDetails.biddingEndDate}&nbsp;&nbsp;&nbsp;
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Bidding Type{" "}
													</label>
													:&nbsp;&nbsp;
													{campaignDetails.biddingType}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>
														Type Of Duation{" "}
													</label>
													:&nbsp;
													{campaignDetails.durationType}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "93px" }}>Duration </label>
													:&nbsp;
													{campaignDetails.duration}&nbsp;&nbsp;&nbsp;
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Marketing Channel{" "}
													</label>
													:&nbsp;&nbsp;
													{campaignDetails.marketingChannel}&nbsp;&nbsp;&nbsp;
												</div>
											</div>
											<div class="row">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>Region </label>
													:&nbsp;&nbsp;
													{campaignDetails.region}
												</div>
											</div>
											<div class="row word-wrap">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>Country </label>
													:&nbsp;&nbsp;
													{campaignDetails.country}
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>Industry </label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.industry}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>Job Title </label>
													:&nbsp;&nbsp;
													{campaignDetails.jobTitle}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label>No. Of Custom Questions :</label>
													&nbsp;&nbsp;&nbsp;
													{campaignDetails.noOfCustomQuestions}
												</div>
											</div>
											<div class="row">
												<label style={{ paddingLeft: "15px", width: "125px" }}>
													Other{" "}
												</label>
												:&nbsp;&nbsp;&nbsp;
												{campaignDetails.conversionCriteria}
											</div>
											<div class="row w-100%">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>
														Job Function{" "}
													</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.jobFunction}
												</div>
											</div>
											<div class="row w-100%">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>
														company Revenue{" "}
													</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.companyRevenue}
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>Job Level </label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.jobLevel}
												</div>
											</div>
											<div class="row">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label>Employee Size </label>:&nbsp;&nbsp;&nbsp;
													{campaignDetails.empSize}
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Max Lead Available{" "}
													</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.leadAllocation}
												</div>

												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>Target CPL</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.CPL}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "148px" }}>currency </label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.currency}
												</div>
											</div>
										</div>
									))}
								</p>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									class="btn add-button"
									data-dismiss="modal">
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
} // end of class

RequestForProposalPublisher.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(RequestForProposalPublisher)
);
