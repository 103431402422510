/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/**
* @file reviewAgency 
* @author Raunak Thakkar
* @version 1.0
* @section use for admin 
*/
import React, { Component } from 'react';
// import {Table} from 'mdbreact';  //Nilesh-4754-Removing console warnings
import AdminNavpage from '../layouts/adminNavpage.js';
import './admin.css';
import Footer from '../layouts/footer';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Somnath Task-3930-added default header
class ReviewAgency extends Component
{
    constructor(){
        super();
        this.state = {
            companyDetails:[],
            message:''
        }

   // this.handleReview = this.handleReview.bind(this);
    }//end of constructor

    handleReview(e){
         var orgID=e.target.id;
        this.props.history.push('/approveOnBoaredAgency',{orgID})//Somnath Task-3930-replace query params
        
    }

    /*@author Sonali Kalke
   * Desc getting Records of agencys having status verification pending in table format
  */

     componentDidMount(){
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
          }
  else{     
        fetch("/admin/agencyDetails")
        .then(res => res.json())
        .then(companyDetails =>{
          if(companyDetails.success===true){
            
           var message=companyDetails.message;
            this.setState({message:message})
            }else{
                this.setState({ companyDetails: companyDetails })
            }
           
        }).catch(function (err) {
            console.log(err)
          });
        }
    }


    render()
    {
        return(
            <div>
            <div>
             <AdminNavpage/> 
             </div>
             <div class="container-fluid">
            <div class="row"style={{paddingTop:"90px",paddingBottom:'6px',backgroundColor:'white',border:'1px solid',borderColor:'#bfc7cc',width:'100%',position:'fixed',zIndex:'1'}}>
            <div class="col" align="center"><label id="labelheading"style={{color:'#056EB8',fontSize:'20px'}} >Pending Review Agency List</label></div>
            
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"  style={{fontSize:'14px',backgroundColor:'#dae0e063'}}>
            <div class="table-responsive-lg">
            <br/>
            <table
                        style={{height:'451px'}}
                        id="myTable"
                        className=" table table-bordered table-striped "
                      >
                         {/* <Table   className=" table table-bordered table-striped " class=""> */}
                        <thead class="thead">
                          <tr class="tr custom-class">
                          <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'156px'}}>
                            Agency Name
                            </th>
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'200px'}}>
                            E-mail
                            </th>
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',width:'222px'}}>
                            Phone Number
                            </th>
                            <th style={{borderRightColor:'transparent',verticalAlign:'left',width: '150px'}}>
                            Country
                            </th>
                            <th style={{borderRightColor:'transparent',verticalAlign:'top'}}>
                            Delivery Channel
                            </th>
                            <th style={{borderRightColor:'transparent',verticalAlign:'top',paddingRight:'52px'}}>
                            Action
                            </th>
                           
                           
                          </tr>
                        </thead>
                        <tbody class="tbodyy">

                        <div  class="errorMessage" style={{textAlign:'center',fontSize:'17px'}}>{this.state.message}</div>
                        {this.state.companyDetails.map(companyDetails => (
                                
          
                            <tr class="tr">
                                <td style={{wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',textAlign:'left',width:'200px'}}>
                                    {companyDetails.agencyName}
                                </td>
                                <td style={{wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',textAlign:'left',width:'250px'}}>
                                    {companyDetails.email}
                                </td>
                                <td style={{textAlign:'center',width:'150px'}}>
                                    {companyDetails.phone}
                                </td>
                                <td style={{wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',textAlign:'left',width:'180px'}}>
                                    {companyDetails.country}
                                </td>
                                <td style={{wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',textAlign:'left',width:'400px'}}>
                                   {companyDetails.dcTelemarketing === 'Yes'?  //Nilesh-4754-Removing console warnings
                                   <span style={{fontWeight: 'bold'}}>Telemarketing, &nbsp;</span>:''}
                                   {companyDetails.dcEmail === 'Yes'?  //Nilesh-4754-Removing console warnings
                                   <span style={{fontWeight: 'bold'}}>E-Mail, &nbsp;</span>:''}
                                   {companyDetails.dcSocial === 'Yes'?  //Nilesh-4754-Removing console warnings
                                   <span style={{fontWeight: 'bold'}}>Social, &nbsp;</span>:''}
                                   {companyDetails.dcDisplay === 'Yes'? //Nilesh-4754-Removing console warnings
                                   <span style={{fontWeight: 'bold'}}>Display, &nbsp;</span>:''}
                                   {companyDetails.dcProgrammatic === 'Yes'?  //Nilesh-4754-Removing console warnings
                                   <span style={{fontWeight: 'bold'}}>Programmatic, &nbsp;</span>:'' 
                                   }

                                </td>
                                <td style={{wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',textAlign:'center'}}>
                                 <button class="btn add-button" onClick={this.handleReview.bind(this)} id={companyDetails.agencyID}>Review</button>
                                </td>
                                
                               
                                
                                
                            </tr>
                      

              
                       
                        
                          ))}
               
                
               </tbody>




                          </table>
             
            </div>
            </div>
            </div>
            </div>
            <Footer/>
            </div>
            
        )
    }
}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      ReviewAgency.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
 export default connect(mapStateToProps, { logoutUser })(withRouter(ReviewAgency)); 