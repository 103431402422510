/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*
/*
@author:Sanjana Godbole
@Creation Date:12-09-2019
@Description:UI for AgencyFrontPage(Dashboard)
*/


import React from 'react';
import * as $ from "jquery";
import 'datatables.net';
import 'antd/dist/antd.css';
import { Tag} from 'antd';
import Moment from 'react-moment';
import { Table, Input, Button, Icon ,Divider,Tooltip,Badge,Card} from 'antd';
// import { css } from 'react-emotion';
import DataTable from 'react-data-table-component';
import Highlighter from 'react-highlight-words';
import Navigation from '../layouts/navPage';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
// import DashboardAll from '../login/dashboardAll';
// import DashboardActive from '../login/dashboardActive';
import Footer from '../layouts/footer';
import { Modal} from 'react-bootstrap';
import { Progress } from 'antd';
import {MDBContainer, MDBFooter,MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText } from "mdbreact";


import './dashboard.css';


 import { css } from 'emotion';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
import { error } from 'winston';
//var http = require('http');



const Swal = require('sweetalert2');
var budgetValidate=true;
var cplValidate=true;
const queryString = require('query-string');
//var querystringURL = require('querystring');
 //const header = css({ backgroundColor: 'rgb(100, 108, 140)', color: 'white', margin: '50px'});
 var dateTime = require("node-datetime");
 const tableCSS = css({
  backgroundColor: 'white',
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize:'12px',
    fontWeight:'500',
  },
  '& thead > tr >th': {
   // border: '1px solid black',
    // borderWidth: '2px',
    // borderColor: 'yellow',
    // borderStyle: 'solid'
    color: 'white',
  }
});
 
let leadAllocationValidate=true;
const months=[{id:1,month:'January'},{id:2,month:'February'},{id:3,month:'March'},{id:4,month:'April'},{id:5,month:'May'},{id:6,month:'June'},{id:7,month:'July'},{id:8,month:'August'},{id:9,month:'September'},{id:10,month:'October'},{id:11,month:'November'},{id:12,month:'December'}];
class Dashboard extends React.Component {  

  
    constructor(props) {
        super(props);
        this.state = 
        {
          fields: {},
        
          errors: {},
          disabletip:'tooltip-top',
            agencyID:"",
            campaignDetails:[],
            dashboardCampaignCount:[],
            activeKey:"",
            pacing:'All',
            pageSize:5,
            pageSize1:5,
            pageSize2:5,
            pageSize3:5,
            pageSize4:5,
            stateCopy:[{
              allocatedLead:"",
              cpl:''
            }],
            biddingArray:[],
            reviewArray:[],
            tabReview:'creatives',
            linkActive:'',
            linkActive1:'active',
            linkActive2:'',
            linkActive3:'',
            linkActive4:'',
            linkActive5:'',
            headExample:'head-example',
            headExample1:'head-exampleGeneral1',
            headExample2:'head-example2',
            headExample3:'head-exampleGeneral3',
            headExample4:'head-exampleGeneral4',
            headExample5:'head-exampleGeneral5',
            headExample6:'head-exampleGeneral6',
            tableState:{size:'small'}, //Array for table properties 
            tableSearchText:'',
            downloadFiles:[],
            tableSearchText1:'',
            tableSearchText2:'',
            campaignDetailsSearch:[],
            columnUnreadMsgSearch:[],
            columnUnreadAlertsSearch:[],
            show:"none",
            ReviewTab:"",
            RFPBiddingTab:"Counter",
            tableState:{size:'small'}, //Array for table properties 
            step:1,
            alertBorder:'',
            alertDisplay: 'none',
            extension:'',
            tab:"",
            tabName:'Active',
            incrementLeads:"",
            incrementCampDetails:[],
            campID:'',
            loading:true,
            loading1:true,
            loading2:true,
            parentCampID:'',
            reallocationID:'',
            counterLength:'',
            rejectLength:'',
            creativeLength:'',
            leadsLength:'',
            endDateCampaign:[],
           reviewTab1:"",
           inputClass:'btn add-button',
           months:[],
           invoiceLength:'',
           monthArrayLength:'',
           unreadMessages:[],
            unreadMessagesLength:0,
            unreadAlerts:[],
            unreadAlertsLength:0,
            panelBlock:"",
            newDate:'',
            rfpBiddingLength:'',
            modalShow:false
            //makeCampaignLive:false,
        }//end of state


        
        this.campaignLink=this.campaignLink.bind(this);
        this.biddingLink=this.biddingLink.bind(this);
        this.reviewLink=this.reviewLink.bind(this);
        this.handleAllocatedLeads=this.handleAllocatedLeads.bind(this);
        this.displayPopup=this.displayPopup.bind(this);
        this.displayCampaignSpecification=this.displayCampaignSpecification.bind(this);
        this.displayCampaignSpecificationAlerts=this.displayCampaignSpecificationAlerts.bind(this);
        this.displayCampaignSpecificationMsg=this.displayCampaignSpecificationMsg.bind(this);
        this.displayCampaignSetup=this.displayCampaignSetup.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleChangePageSize=this.handleChangePageSize.bind(this);
        this.handleChangePageSize1=this.handleChangePageSize1.bind(this);
        this.handleChangePageSize2=this.handleChangePageSize2.bind(this);
        this.handleChangePageSize3=this.handleChangePageSize3.bind(this);
        this.handleChangePageSize4=this.handleChangePageSize4.bind(this);
        this.handleChangeSearch=this.handleChangeSearch.bind(this);
        this.handleChangeSearch1=this.handleChangeSearch1.bind(this);
        this.handleChangeSearch2=this.handleChangeSearch2.bind(this);
        this.pauseCampaign=this.pauseCampaign.bind(this);
        this.resumeCampaign=this.resumeCampaign.bind(this);
        this.allocatePublisher=this.allocatePublisher.bind(this);
        this.handleRefreshData=this.handleRefreshData.bind(this);
        this.creativesReview=this.creativesReview.bind(this);

        this.handleEditCampaign=this.handleEditCampaign.bind(this);
        this.handleEditCampaignNew=this.handleEditCampaignNew.bind(this);
        this.allocatedCampaign=this.allocatedCampaign.bind(this);
        this.rFPBiddingTab=this.rFPBiddingTab.bind(this);
        this.leadReview=this.leadReview.bind(this);
        this.handleChangeCompleteCampaign=this.handleChangeCompleteCampaign.bind(this);
        this.handleChangeAllocatedLeads=this.handleChangeAllocatedLeads.bind(this);
        this.handleRefreshPage=this.handleRefreshPage.bind(this);
        this.handleIncrementLeads=this.handleIncrementLeads.bind(this);
        this.handleChangeDate=this.handleChangeDate.bind(this);
        this.handleIncCampaignLeads=this.handleIncCampaignLeads.bind(this);
        this.getCampID=this.getCampID.bind(this);
        this.reAllocateCampaign = this.reAllocateCampaign.bind(this);
        this.reAllocate=this.reAllocate.bind(this);
        this.handleChangeIncremental=this.handleChangeIncremental.bind(this);
        this.handleEndDate=this.handleEndDate.bind(this);
        this.handleChangeEndDate=this.handleChangeEndDate.bind(this);
        this.handleChangeEndDateCampaign=this.handleChangeEndDateCampaign.bind(this);
        this.cplHandleChange=this.cplHandleChange.bind(this);
        this.budgetHandleChange=this.budgetHandleChange.bind(this);
        this.leadAllocationHandleChange=this.leadAllocationHandleChange.bind(this);
        this.cloneCampaign=this.cloneCampaign.bind(this);
        this.cloneCompletedCampaign=this.cloneCompletedCampaign.bind(this);
        this.fadeInOut=this.fadeInOut.bind(this);
        this.closeChat=this.closeChat.bind(this);
        this.recordOpen=this.recordOpen.bind(this);
        this.cancelCampaign=this.cancelCampaign.bind(this);
        
        this.getDetails=this.getDetails.bind(this);
        this.modalHandleClose=this.modalHandleClose.bind(this);

    }// end of constructor

    fadeInOut(e)
    {
      //  alert("Id===>"+e.target.id);
      var id=e.target.id

      if(id==="messages")
      {

        this.setState(state => ({
         panelBlock:"message", show: "block"
        }));
      }
      else
      {
        this.setState(state => ({
          panelBlock:"alert", show: "block"
         }));
      }
   
      //alert("Id===>"+this.state.panelBlock);
       
    }

    closeChat()
  {
    this.setState(state => ({
      show: "none"
    }));
    
  }
    leadAllocationHandleChange(e)
    {
    //  alert(this.state.reTotalLeads);
      let errors={};
      let fields = this.state;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
 if ((this.state.reTotalLeads === undefined || this.state.retotalleads=="")) 
 {
           errors["leadAllocation"] = <li>Please fill below required fields leadAllocation</li>;
          leadAllocationValidate = false;
          this.setState({alertDisplay:'block',alertBorder:'1px soild red'})
 
  }
  else if((this.state.reTotalLeads === undefined || this.state.retotalleads=="") && this.state.reBudget!="")
  {
    errors["leadAllocation"] = <li>Please fill below required fields leadAllocation</li>;
    leadAllocationValidate = false;
    this.setState({alertDisplay:'block',
    alertBorder:'1px soild red',
    reBudget:this.state.reBudget,
    reCpl:''})
  }
  else if((this.state.reTotalLeads === undefined || this.state.retotalleads=="") && this.state.reCpl!="")
  {
    errors["leadAllocation"] = <li>Please fill below required fields leadAllocation</li>;
    leadAllocationValidate = false;
    this.setState({alertDisplay:'block',
    alertBorder:'1px soild red',
    reBudget:"",
    reCpl:this.state.reCpl})
  } 

  else
  {
    var pattern = new RegExp(/^(|[1-9]\d*)$/);
      if (!pattern.test (fields["reTotalLeads"])) 
      {
       leadAllocationValidate = false;
        errors["leadAllocation"] = <li>Please enter valid number for lead allocation</li>;
         this.setState({
    errors: errors,
    alertBorder: '1px solid red',
    alertWidth: '1000px',
    alertAlign: 'center',
    alertDisplay: 'block'
  });
}
      else
      {
        //leadallocation present
        errors["leadAllocation"] = "";
         leadAllocationValidate = true;
         this.setState({
          errors: errors,
          alertBorder: '0px solid white',
          alertWidth: '1000px',
          alertAlign: 'center',
          alertDisplay: 'block'
        });
      if(this.state.reBudget!='' )
      {
        //alert("Budget not null"+this.state.reBudget)
        var value1=this.state.reTotalLeads;
        var value2=this.state.reBudget;
        var name1="reCpl"
          var newCPL=value2/value1;
         // alert("newCPL==>"+newCPL);
          var a=isFinite(newCPL);
          var b=isNaN(newCPL);
          if(a==false || b==true)
          {
            //alert("not finite")
            newCPL = "";
            this.setState({
                [name1]: newCPL
            });
          
          }
         
           else{ 
            newCPL = newCPL.toFixed(2);
            this.setState({
                [name1]: newCPL
            });
      }
         
      }
      else if(this.state.reCpl!='')
      {
        var value1=this.state.reTotalLeads;
        var value2=this.state.reCpl;
          var name1="reBudget"
          var newbudget=value2*value1;
          var a=isFinite(newbudget);
          var b=isNaN(newbudget);
          if(a==false || b==true)
          {
            newbudget = "";
            this.setState({
                [name1]: newbudget
            });
          }
          else
          {
          newbudget = newbudget.toFixed(2);
          this.setState({
          [name1]: newbudget
              });
            }
      }
      }
  }
}

    budgetHandleChange(e){
      let errors={};
      let fields = this.state;
      var name=e.target.name;
      fields[e.target.name] = e.target.value;
      var value=e.target.value;
      fields[e.target.name] = e.target.value;
      var value1=this.state.reTotalLeads;
  budgetValidate=true;
      var name1="reCpl"
      var pattern = new RegExp(/^[1-9]\d*(\.\d{1,2})?$/);
     if (!pattern.test(this.state.reBudget)) 
     {
      budgetValidate = false;
       errors["budget"] = <li>Please enter valid number for budget</li>;
       this.setState({
        errors: errors,
        alertBorder: '1px solid red',
        alertWidth: '1000px',
        alertAlign: 'center',
        reCpl:''
    });
     }
      else{
        errors["budget"] = "";
          this.setState({
            errors: errors,
            alertBorder: '0px solid white',
            alertWidth: '1000px',
            alertAlign: 'center',
          })
   if((this.state.reCpl=='' || this.state.reCpl===undefined) && (this.state.reTotalLeads==""|| this.state.reTotalLeads===undefined) && (fields["reBudget"]))
          {
              this.setState({
                reCpl:'',
              })
          }
      else if((this.state.reTotalLeads!=""|| this.state.reTotalLeads!=undefined) && (fields["reBudget"]))
    {
    var newCPL=value/value1;
      newCPL = newCPL.toFixed(2);
    this.setState({
      fields,  [name1]: newCPL,
    })
  }
}
}
  

    cplHandleChange(e)
    {
    let errors={};
      let fields = this.state;
    var name=e.target.name;
    var value=e.target.value;
    fields[e.target.name] = e.target.value;
    var value1=this.state.reTotalLeads;
      var name1="reBudget"
    var pattern = new RegExp(/^[1-9]\d*(\.\d{1,2})?$/);
    if (!pattern.test(this.state.reCpl)) 
    {
     cplValidate = false;
      errors["cpl"] = <li>Please Enter Valid Number For Cpl</li>;
      this.setState({
        errors: errors,
        alertBorder: '1px solid red',
        alertWidth: '1000px',
        alertAlign: 'center',
        reBudget:0,
    });
    }
    else
    {
      errors["cpl"] = "";
    this.setState({
      errors: errors,
      alertBorder: '0px solid white',
      alertWidth: '1000px',
      alertAlign: 'center',
      })
    }

    if((fields["reTotalLeads"]) && (fields["reCpl"]))
    {
      var newbudget=value*value1;
      newbudget = newbudget.toFixed(2);
    this.setState({
      fields,  [name1]: newbudget
    }); 
  }
   else 
  {
    this.setState({
      reBudget:'',
    })
  }
  }

    campaignSetup(e)
    {
      var advCampID=e.target.getAttribute('advCampID');
      let agencyAllocationID=e.target.id;
      // window.location.href="/agencyCampaignSetupInDetail?agencyAllocationID="+agencyAllocationID+"&advCampID="+advCampID;
      window.location.href="/createcampaign2?agencyAllocationID="+agencyAllocationID+"&advCampID="+advCampID;
    }

 /**
       * @author Supriya Gore
       * @param  Description handle the End Date Of Campaign
       * @return Description return All End Date Of Campaign
       */

     
      validateForm2() {
           let errors = {};
           let formIsValid = true;
           let newDecdate= true;
           var dt = dateTime.create();
           var formatted = dt.format("Y-m-d");

           for(var i=0;i<this.state.endDateCampaign.length;i++)
          {
            var a=this.state.endDateCampaign[i].endDate
            if(this.state.endDateCampaign[i].endDate<formatted)
            {
              errors["commonError"] = <li>End Date should be greater than current date</li>;
             newDecdate=false;
             this.setState({alertDisplay:'block',alertBorder:'1px soild red'})
            }
            else
            {
            }

          }
    
        if(this.state.endDate<formatted)
        {
         errors["commonError"] = <li>End Date should be greater than current date</li>;
         newDecdate=false;
         this.setState({alertDisplay:'block',alertBorder:'1px soild red'})

       }   
            else
           {
           }
         if(newDecdate==false)
         {
           formIsValid=false;
         
         } 
         else{
           formIsValid=true;
           this.setState({alertBorder:'0px soild white'})

         }
         
         
         
         this.setState({
           errors:errors,
            alertBorder: '1px solid red',
                  alertWidth: '1000px',
                  alertAlign: 'center',
                 alertDisplay: 'block',
         })
         
         return formIsValid;
           }
         

      handleChangeEndDateCampaign(e)
      {
        e.preventDefault();
  e.target.className += " was-validated";
  if (this.validateForm2() === false) {
    return;
  }
 else {
  
   this.setState({
      // alertDisplay: 'none'
      alertBorder:'0px solid white'
    });
  }
  

        const {isAuthenticated, user} = this.props.auth;
        var agency=user;
        let data={
          endDateCampaign:this.state.endDateCampaign,
          user:agency
        };

        fetch("/agency/editEndDateCampaign", {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then(response=> { 
            // console.log("Return with response");
            Swal.fire({text: ("End Date has been Changed."),
            type: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
              }
            })
            
             this.setState({inputClass:'disabled'})
        })
      }



    /**
       * @author Supriya Gore
       * @param  Description handle the End Date Of Campaign
       * @return Description return All End Date Of Campaign
       */
      handleChangeEndDate(e)
      {
        
        var endDate=e.target.value;
        var name='endDate';
        //var endDateCampaign=

        var endDateCampaign = [...this.state.endDateCampaign];
        endDateCampaign[0] = {...endDateCampaign[0], [name]: endDate};
        this.setState({endDateCampaign:endDateCampaign,endDate:endDate})

      }


  /**
       * @author Supriya Gore
       * @param  Description handle the End Date Of Campaign
       * @return Description return All End Date Of Campaign
       */
      handleEndDate(e)
      {
        
        var campID=e.target.id;
       
        let data={
          campID:campID
        };
       // var makeCampaignLive;
        //update the status in publisher_allocation table
        fetch("/agency/endDateCampaign", {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then(endDateCampaign=> { 
            // console.log("Return with response");
           // makeCampaignLive=true;
            this.setState({endDateCampaign:endDateCampaign,campID:campID});
        })
      }

      modalHandleClose(e)
      {
        this.setState({modalShow:false});
        window.location.reload();
      }

      getDetails(e){
        var parentCampID=e.target.getAttribute('parentCampId');
     var reallocationID=e.target.getAttribute('reallocationId');
     this.setState({parentCampID:parentCampID,reallocationID:reallocationID});
    //   alert("getDetails");
    //  Swal.fire('Any fool can use a computer')

    Swal.fire({ title: 'Are you sure you want to create copy of the campaign?', type: 'question', showCancelButton: true, cancelButtonColor: '#d33', confirmButtonColor: '#3085d6', allowOutsideClick: false, confirmButtonText: 'Yes' }).
    then((result) => {
      if (result.value) {
        this.setState({modalShow:true});
      }
    })
    // Swal.fire({text: ("Are you sure to clone the campaign?"),
    // type: 'success',
    // confirmButtonText: 'Ok',
    // allowOutsideClick: false,
    // preConfirm: () => {
    //   this.setState({modalShow:true});
    //   // $('#cloneDetails').modal('show');
    // //  <p data-toggle="modal" 
    // //  data-target="#confirmClone"></p>
      
    //   }
    // })
      }

     creativesReview(e){
      var parsed = queryString.parse(this.props.location.search);
      var agencyID=parsed.agencyID;
      var campID = e.target.getAttribute('campID');
      
     var parentCampID=e.target.getAttribute('parentCampId');
     var reallocationID=e.target.getAttribute('reallocationId');
     var backKey='Dash';
     window.location.href ='/agencyCreativesReview?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&agencyID='+agencyID+'&backKey='+backKey;
        

    }
  
    leadReview(e){
      var parsed = queryString.parse(this.props.location.search);
      var agencyID=parsed.agencyID;
      // var parsed = queryString.parse(this.props.location.search);
      // var agencyID=parsed.agencyID;
      var campID = e.target.getAttribute('campID');
      
     var parentCampID=e.target.getAttribute('parentCampId');
     var reallocationID=e.target.getAttribute('reallocationId');
     
     window.location.href ='/agencyReviewLead?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&agencyID='+agencyID;
        

    }


    handleRefreshPage(e){
    var parsed = queryString.parse(this.props.location.search);
      var agencyID=parsed.agencyID;
      
      window.location.href = '/dashboard?agencyID='+agencyID;    
    }

    handleAllocatedLeads(e)
    {
    var campID = e.target.getAttribute('campID');
   var campaignName = e.target.getAttribute('campaignName');
  var parentCampID=e.target.getAttribute('parentCampId');
  var reallocationID=e.target.getAttribute('reallocationId');
  var pID=e.target.getAttribute('pID');
      this.setState({parentCampID,reallocationID});
     
      let data={
        campID:campID,
        pID:pID
      };

      fetch("/agencyDashboard/campaignDetails", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then(incrementCampDetails=> { 
          // console.log("Return with response");
          this.setState({incrementCampDetails:incrementCampDetails,campID:campID});
      })

    }
    

    handleIncrementLeads(e)
    {
      var name=e.target.name;
      var value=e.target.value;
      let errors = {};
      
      this.setState({
        incrementLeads:value
        })
      //window.location.href = '/agencyAllocatedLeads?campID='+campID+'';

    }


    handleChangeDate(e)
    {
      
      //var endDate=e.target.value;
      const { name, value } = e.target;
      let incrementCampDetails = [...this.state.incrementCampDetails];
      incrementCampDetails[0] = { ...incrementCampDetails[0], [name]: value };
      this.setState({incrementCampDetails})
    }


    validateForm() {
       
         let errors = {};
         let incdecleads=true;
         let formIsValid = true;
       
         var dt = dateTime.create();
         var formatted = dt.format("Y-m-d");
       
         var currentDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
  
         var dd = new Date(currentDate);
         dd.setMonth(dd.getMonth() - 1);
         var month, day, year;
         month = '' + (dd.getMonth() + 1);
         day = '' + dd.getDate();
         year = dd.getFullYear();
       var leads=parseFloat(this.state.incrementLeads);
 
         if(this.state.incrementLeads== undefined || this.state.incrementLeads==""|| this.state.incrementLeads==null)
         {
          incdecleads = false;
          errors["commonError"] = <li>Please fill below required fields</li>;
          this.setState({alertBorder:'1px solid red',display:'block'})
         }
         else
         {
       
           var pattern = new RegExp(/^(|[1-9]\d*)$/);
         if (!pattern.test(this.state.incrementLeads))
           {
           
             errors["commonError"] =<li>Please Enter a whole number for Lead Incremental</li>;
               incdecleads = false;
               this.setState({alertBorder:'1px solid red',display:'block'})
              
               
               
         }else if(this.state.incrementCampDetails[0].campEndDate<formatted)
         {
          // errors["commonError"] = <li style={{display:'block',Align: 'center',Width: '1000px',color:'red'}}>End Date should be greater</li>;
          incdecleads = false;
         }
         else
         {
         }
       
       }
       if(this.state.incrementCampDetails[0].campEndDate< (currentDate))
       {
         incdecleads = false;
         errors["Currentdate"]=<li>End date can't be less than current date</li>;
         this.setState({alertBorder:'1px solid red',display:'block'})

       }

       if(this.state.incrementCampDetails[0].campEndDate< (this.state.incrementCampDetails[0].startDate))
       {
         incdecleads = false;
         errors["EnddateValidate"]=<li>End date must be greater than start date</li>;
         this.setState({alertBorder:'1px solid red',display:'block'})
       }
        
       if(incdecleads==false)
       {
         formIsValid=false;
       
       } 
       else{
         formIsValid=true;
       }
       
       
       
       this.setState({
         errors:errors,
          alertBorder: '1px solid red',
                alertWidth: '1000px',
                alertAlign: 'center',
               alertDisplay: 'block',
       })
       
       return formIsValid;
         }
       
         handleIncCampaignLeads(e){

          e.preventDefault();
          e.target.className += " was-validated";
          if (this.validateForm() === false) {
            return;
          }
         else {
          
           this.setState({
              // alertDisplay: 'none'
              alertBorder:'0px solid white'
            });
          }
           
            const {isAuthenticated, user} = this.props.auth;
            var agency=user;
            
            let data={
                incrementLeads:this.state.incrementLeads,
                campaignDetails:this.state.incrementCampDetails,
                user:agency
              };
           
              //update the status in publisher_allocation table
              fetch("/agencyDashboard/incrementCampaignLeadsDash", {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
              }).then(res => res.json())
              .then(response=> { 
                  // console.log("Return with response");
                  Swal.fire({text: ("Campaign Leads has been Incremented."),
                  type: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  preConfirm: () => {
                    window.location.reload();
                    }
                  
                })
                // var mesg="Campaign Leads has been Incremented. Please Close the Window ";
                  //this.setState({successMsg:mesg,inputClass:'disabled'})
              })
      }
      getCampID(e){
        var campID=e.target.id;
        this.setState({campID:campID})
       }
   /**
           * @author Somnath Keswad
           * @param  Description handle Refresh table data on event
           * @return Description return successfully updated data
           */
    handleRefreshData(){
      const {user} = this.props.auth;
      var data={ agencyID:user.id,pacing:this.state.pacing}
      // fetch("/agencyDashboard/dashboardAllCampaign", {
      //   method: "POST",
      //   headers: {"Content-Type": "application/json"},
      //   body: JSON.stringify(data)
      // }).then(res => res.json())
      // .then((campaignDetails=>
      //   this.setState({campaignDetails: campaignDetails})
      //   )).catch(function (err) {console.log(err)});

        fetch("/agencyDashboard/dashboardCampaignCount", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((dashboardCampaignCount=>
            this.setState({dashboardCampaignCount: dashboardCampaignCount})
          )).catch(function (err) {console.log(err)});

    if(this.state.tab=="Active")
     {
        fetch("/agencyDashboard/dashboardActiveCampaign", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((campaignDetails=>
            this.setState({campaignDetails: campaignDetails})
        )).catch(function (err) {console.log(err)});

     }
     
     else if(this.state.tab=="All")
     {
      fetch("/agencyDashboard/dashboardAllCampaign", {
         method: "POST",
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(data)
       }).then(res => res.json())
       .then((campaignDetails=>
           this.setState({campaignDetails: campaignDetails})
         )).catch(function (err) {console.log(err)});

     }else if(this.state.tab=="Pause")
      {
            fetch("/agencyDashboard/dashboardPausedCampaignNew", {
              method: "POST",
              headers: {"Content-Type": "application/json"},
              body: JSON.stringify(data)
            }).then(res => res.json())
            .then((campaignDetails=>
                this.setState({campaignDetails: campaignDetails})
              )).catch(function (err) {console.log(err)});


              // var data1={ user:user}
              // fetch("/agencyDashboard/autoCompleteInPause", {
              //   method: "POST",
              //   headers:{"Content-Type": "application/json"},
              //   body: JSON.stringify(data1)
              // });

     }else if(this.state.tab=="Completed")
          {
            fetch("/agencyDashboard/dashboardArchiveCampaign", {
              method: "POST",
              headers: {"Content-Type": "application/json"},
              body: JSON.stringify(data)
            }).then(res => res.json())
            .then((campaignDetails=>{
             
                this.setState({campaignDetails: campaignDetails})
            }
              )).catch(function (err) {console.log(err)});
            }


            else if(this.state.tab=="cancelCamp")
            {
             fetch("/agencyDashboard/dashboardCancelCampaign", {
               method: "POST",
               headers: {"Content-Type": "application/json"},
               body: JSON.stringify(data)
             }).then(res => res.json())
             .then((campaignDetails=>{
                 this.setState({campaignDetails: campaignDetails})
                
               }
               )).catch(function (err) {console.log(err)});
             }
       
            
     
    }
     /**
           * @author Supriya Gore
           * @param  Description handle edit flow of campaign
           * @return Description return successfully return on edit flow
           */
          handleEditCampaign(e){
            var campID = e.target.id;
            var parentCampID=e.target.getAttribute('parentCampID');
            var reallocationID=e.target.getAttribute('reallocationID');

            var rfpCampaignID=e.target.getAttribute('rfpCampaignID');
            if(rfpCampaignID){
              if(rfpCampaignID==0){
                window.location.href = '/welcomeEdit?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&step='+this.state.step;

              }
              else{
                window.location.href = '/welcomeEdit?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&step='+this.state.step+'&rfpCampaignID='+rfpCampaignID;;

              }
            }
            else{
            
             // window.location.href = '/WelcomeEdit?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&step='+this.state.step;
           window.location.href = '/welcomeEdit?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&step='+this.state.step;
            }
          }


          handleEditCampaignNew(e)
          {
            var campID = e.target.id;
            var parentCampID=e.target.getAttribute('parentCampID');
            var reallocationID=e.target.getAttribute('reallocationID');
              window.location.href = '/welcomeEdit?campID='+campID;

            
          // window.location.href = '/edit?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&step='+this.state.step;
          }
   
displayPopup(e)
{

  Swal.fire({text: ("Campaign Completed Marked.."),
    type: 'success',
    confirmButtonText: 'Ok',
    allowOutsideClick: false,
    preConfirm: () => {

      window.location.reload();
       }
  })
}
/**
           * @author Somnath Keswad
           * @param  Description Complete Campaign
           * @return Description Mark as complete campaign
           */
handleChangeCompleteCampaign(e){
  e.preventDefault();

var campID=e.target.id;
const {user} = this.props.auth;
let self=this;




Swal.fire({
  title: 'Are you sure you want to mark this campaign as Completed?',
  
  type: 'question',
  showCancelButton: true,
  cancelButtonColor: '#3085d6',
  confirmButtonColor: '#d33',
  allowOutsideClick: false,
  confirmButtonText: 'Yes, complete it!'
}).then((result) => {
  if (result.value) {
    let data = {
      campID:campID,
      user:user
    };
    fetch("/agency/completeCampaign", {
      //insert data in publisher_assignment table
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    })

    Swal.fire({
        
        text: 'Campaign marked completed !',
        type: 'success',
        confirmButtonText: 'Ok',
        preConfirm: () => {
          self.handleRefreshData();
}
})

  }///
})
/////////////////////////////////////
// fetch("/agency/completeCampaign", {
//   method: "POST",
//   headers: { "Content-Type": "application/json" },
//   body: JSON.stringify(data)
// }).then(function(response) {
// self.handleRefreshData();
//   }).catch(function (err) {console.log(err)});
//   Swal.fire({text: ("Campaign Completed Marked.."),
//     type: 'success',
//     confirmButtonText: 'Ok',
//     allowOutsideClick: false,
//     preConfirm: () => {
//       window.location.reload();
//        }
//   })
  
}

handleChangeAllocatedLeads(e)
{
  var campID = e.target.getAttribute('CampId');;
  var parentCampID=e.target.getAttribute('parentCampId');
  var reallocationID=e.target.getAttribute('reallocationId');
  window.location.href = '/agencyAllocatedLeads?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&step='+this.state.step;
  
};//end of handle allocated leads  


    campaignLink(e) {
      e.preventDefault();
      const {isAuthenticated, user} = this.props.auth;
      var agencyID=user.id;
      var data={ agencyID:agencyID,pacing:this.state.pacing}
      this.setState({tabName:e.target.id})
     if(e.target.id=="Active")
     {
       var tab="Active";
      fetch("/agencyDashboard/dashboardActiveCampaign", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails, tab:tab, linkActive1:'active',linkActive:'',linkActive2:'',linkActive3:'',linkActive4:'',linkActive5:'',loading:false})
         
        }
        )).catch(function (err) {console.log(err)});
      

     }else if(e.target.id=="All")
     {
      var tab="All";
     
       fetch("/agencyDashboard/dashboardAllCampaign", {
         method: "POST",
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(data)
       }).then(res => res.json())
       .then((campaignDetails=>{
           this.setState({campaignDetails: campaignDetails,tab:tab,linkActive4:'',linkActive:'active',linkActive1:'',linkActive2:'',linkActive3:'',linkActive4:'',linkActive5:'',loading:false})
          
         }
         )).catch(function (err) {console.log(err)});

     }else if(e.target.id=="Pause")
     {
      var tab="Pause";


      var data1={ user:user}
      fetch("/agencyDashboard/autoCompleteInPause", {
        method: "POST",
        headers:{"Content-Type": "application/json"},
        body: JSON.stringify(data1)
      });
      
      fetch("/agencyDashboard/dashboardPausedCampaignNew", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails,tab:tab,linkActive2:'active',linkActive:'',linkActive1:'',linkActive3:'',linkActive4:'',linkActive5:'',loading:false})
         

        }
        )).catch(function (err) {console.log(err)});

       



     }else if(e.target.id=="Completed")
     {
      var tab="Completed";
      fetch("/agencyDashboard/dashboardArchiveCampaign", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then((campaignDetails=>{

          this.setState({campaignDetails: campaignDetails,tab:tab,linkActive3:'active',linkActive1:'',linkActive2:'',linkActive:'',linkActive4:'',linkActive5:'',loading:false})
         

        }
        )).catch(function (err) {console.log(err)});

     }
     else if(e.target.id=="CampaignSetup")
     {
      var tab="CampaignSetup";

      fetch("/agencyDashboard/dashboardCampaignSetup", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails,tab:tab,linkActive4:'active',linkActive3:'',linkActive1:'',linkActive2:'',linkActive:'',linkActive5:'',loading:false})
         

        }
        )).catch(function (err) {console.log(err)});

     }
     else if(e.target.id=="cancelCamp")
     {
       var tab="cancelCamp";
      fetch("/agencyDashboard/dashboardCancelCampaign", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails, tab:tab, linkActive4:'',linkActive3:'',linkActive1:'',linkActive2:'',linkActive:'',linkActive5:'active',loading:false})
         
        }
        )).catch(function (err) {console.log(err)});
      }


    }//end of linkClicked


    biddingLink(e) {
      e.preventDefault();

      
     const {user} = this.props.auth;
         var agencyID=user.id;
       
     if(e.target.id==="reject")
     {
      
        //getting Campaign ID which status is New, Counter, Reject
        var tabReviewReject="Reject";
        fetch("agencyDashboard/notifyDashNewCamp?agencyID="+agencyID)
        .then(res => res.json())
        .then(notifyDashNewCamp =>{
       
        this.setState({biddingArray:notifyDashNewCamp[2],rejectLength:notifyDashNewCamp[2].length,RFPBiddingTab:tabReviewReject,headExample1:'head-example1',headExample:'head-exampleGeneral',headExample6:'head-exampleGeneral6',loading1:false})
        }).catch(function (err) {
        console.log(err)
        });
     }else if(e.target.id==="RFP"){
       // Get RFP Bidding campID
       var tabReviewRFP="RFPBiddingTab";
       fetch("agencyDashboard/biddingCampIDOnDashBoard?agencyID="+agencyID)
       .then(res => res.json())
       .then(rfpBidding =>{
         this.setState({ rfpBiddingLength:rfpBidding.length,biddingArray:rfpBidding,RFPBiddingTab:"RFPBiddingTab",headExample1:'head-exampleGeneral1',headExample:'head-exampleGeneral',headExample6:'head-example6',loading1:false})
         }).catch(function (err) {console.log(err)});
     }
     else
     {
      //getting Campaign ID which status is New, Counter, Reject
      var tabReviewCounter="Counter";
      fetch("agencyDashboard/notifyDashNewCamp?agencyID="+agencyID)
      .then(res => res.json())
      .then(notifyDashNewCamp =>{
      this.setState({biddingArray:notifyDashNewCamp[1],counterLength:notifyDashNewCamp[1].length,headExample:'head-example',headExample1:'head-exampleGeneral1',RFPBiddingTab:tabReviewCounter,headExample6:'head-exampleGeneral6',loading1:false})
      }).catch(function (err) {
      console.log(err)
      });
     }
     
    }//end of biddingLink
    /**
 * @author Somnath Keswad
 * @param  Description  Generate the invoice document based on info
 * @return Description insert the document in Blob
 */
    generateInvoice(e){
      const {user} = this.props.auth;
      var agencyID=user.id;
      fetch("agencyDashboard/autoGenerateInvoice?agencyID="+agencyID)
        .then(res => res.json())
        .then(autoGenerateInvoice =>{}).catch(function (err) {
          console.log(err)});
    }
   
    reviewLink(e) {
      e.preventDefault();
     const {isAuthenticated, user} = this.props.auth;
         var agencyID=user.id;
         var userRole=user.role;
         var tabReview=e.target.id;
         
         this.setState({tabReview:tabReview})
     if(e.target.id=="creatives")
     {
      this.setState({
        reviewTab1:'creatives'
      })
        //getting Campaign ID which Delivered The Lead
        var ReviewTab="ReviewCreatives";
        fetch("agencyDashboard/lpPOCCSReviewNotifyDash?agencyID="+agencyID)
        .then(res => res.json())
        .then(lpPOCCSReviewNotifyDash =>{
          this.setState({ reviewArray:lpPOCCSReviewNotifyDash,creativeLength:lpPOCCSReviewNotifyDash.length,ReviewTab:ReviewTab,headExample2: 'head-example2',headExample3: 'head-exampleGeneral3',headExample4: 'head-exampleGeneral4',headExample5: 'head-exampleGeneral5',loading2:false})
          }).catch(function (err) {
          console.log(err)
        });


     }
     else if(e.target.id=="leads")
     {
      this.setState({
        reviewTab1:'creatives'
      })
        //getting Campaign ID which Delivered The Lead
        let ReviewTab="ReviewLeads";
        fetch("agencyDashboard/leadReviewDashboard?agencyID="+agencyID+"&userRole="+userRole)
        .then(res => res.json())
        .then(leadReviewDashboard =>{
          this.setState({ reviewArray:leadReviewDashboard,leadsLength:leadReviewDashboard.length,ReviewTab:ReviewTab,headExample3:'head-example3',headExample2: 'head-exampleGeneral2',headExample4: 'head-exampleGeneral4',headExample5: 'head-exampleGeneral5',loading2:false})
          }).catch(function (err) {
          console.log(err)
        });
     }
     else if((e.target.id=="others"))
     {
      var ReviewTab="RevoewOthers";

      this.setState({reviewTab1:'others'})

      let data={
        agencyID:agencyID
      }
      fetch("/campaign/publisherLinkReview", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then((publisherLinkReview=>{
          this.setState({ reviewArray:publisherLinkReview,othersLength:publisherLinkReview.length,ReviewTab:ReviewTab,headExample4:'head-exampleGeneral4',headExample3:'head-exampleGeneral3',headExample2: 'head-exampleGeneral2',headExample5: 'head-example5',loading2:false, reviewTab1:ReviewTab})
         
        }
        )).catch(function (err) {console.log(err)});
  
     }else
     {
      // const dt = new Date();
      // var index=dt.getMonth();
      // var c=0;
      // var monthList=[];
      // for(var i=0;i<index;i++){
      //   monthList.push(months[i]);
      // }
      // var s=monthList.length;
      //  for(var i=0;i<index;i++){
      //     if(monthList[i].id===index){
      //       c=c+1;
      //       monthList[i].index=c;
      //     }else{
      //         let len1=s-i;
      //         monthList[i].index=len1;
      //     }
      //   }
      //   monthList.sort(function(a, b){return a.index-b.index})
        let dataa={agencyID:this.state.agencyID}
      fetch("/agencyDashboard/getMonthList",{
        //insert data in publisher_assignment table
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataa)
      })
       .then(res => res.json())
       .then(monthArray => {

        let ReviewTab="invoice";
        this.setState({monthArrayLength:monthArray.length,reviewArray:monthArray,ReviewTab:ReviewTab,headExample3:'head-exampleGeneral3',headExample2: 'head-exampleGeneral2',headExample4: 'head-example4',headExample5: 'head-exampleGeneral5',loading2:false})
     
      }).catch(function (err) {console.log(err)});
      
        // let ReviewTab="invoice";
        // this.setState({invoiceLength:monthArray.length,reviewArray:monthArray,ReviewTab:ReviewTab,headExample3:'head-exampleGeneral3',headExample2: 'head-exampleGeneral2',headExample4: 'head-example4',headExample5: 'head-exampleGeneral5',loading2:false})
     }

    }//end of reviewLink



allocatePublisher(e){
    var campID = e.target.getAttribute('id');
    var parentCampID=e.target.getAttribute('parentCampId');
    var reallocationID=e.target.getAttribute('reallocationId');
    var rfpProposalID=e.target.getAttribute('rfpProposalID');
    // alert(rfpProposalID)
    if(rfpProposalID==='null' || rfpProposalID==null || rfpProposalID===undefined || rfpProposalID===''|| rfpProposalID==0 || rfpProposalID=='0'){
      window.location.href ='/publisherAllocation?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID;
    }else{
      window.location.href ='/rfpCampaignAllocation?campID='+campID+'&parentCampID='+parentCampID+'&proposalID='+rfpProposalID;
    }
}// End of allocatePublisher

    cloneCampaign(e){

      e.preventDefault();
      const {isAuthenticated, user} = this.props.auth;
         var agencyID=user.id;
       
      // const {isAuthenticated, user} = this.props.auth;
      // var agencyID=user.id;
      this.setState({agencyID:agencyID});
  
      var campID=e.target.id;
      var parentCampID=e.target.getAttribute('parentCampId');
      var reallocationID=e.target.getAttribute('reallocationId');
      
      Swal.fire({
        title: 'Are you sure you want to create copy of the campaign?',
        type: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        allowOutsideClick: false,
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.value) {
          let data = {
            campID:campID,
            parentCampID:parentCampID,
            reallocationID:reallocationID,
            user:user
          };
          fetch("/agencyDashboard/agencyCloneCampaign", {
            //insert data in publisher_assignment table
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
          }).then(res => res.json()).then(response=> { 
            // console.log("Return with response");
               var message1=response.message;
              // var successmsg= document.getElementById('successmsg');
              // successmsg.innerHTML=message1;
             
              Swal.fire({text: (message1),
              type: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              preConfirm: () => {
                             
            
    if(this.state.tab=="Active")
    {
     
     var data1={ agencyID:agencyID,pacing:this.state.pacing}
     
     fetch("/agencyDashboard/dashboardActiveCampaign", {
       method: "POST",
       headers: {"Content-Type": "application/json"},
       body: JSON.stringify(data1)
     }).then(res => res.json())
     .then((campaignDetails=>{
         this.setState({campaignDetails: campaignDetails})
         
         
       }
       )).catch(function (err) {console.log(err)});

       var data={ agencyID:agencyID,pacing:this.state.pacing}

       fetch("/agencyDashboard/dashboardCampaignCount", {
         method: "POST",
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(data)
       }).then(res => res.json())
       .then((dashboardCampaignCount=>{

           this.setState({dashboardCampaignCount: dashboardCampaignCount})
          
       }

         )).catch(function (err) {console.log(err)});


    }else if(this.state.tab=="All")
    {

    var data1={ agencyID:agencyID,pacing:this.state.pacing}
      fetch("/agencyDashboard/dashboardAllCampaign", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data1)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails})

        }
        )).catch(function (err) {console.log(err)});

        var data={ agencyID:agencyID,pacing:this.state.pacing}

        fetch("/agencyDashboard/dashboardCampaignCount", {
         method: "POST",
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(data)
       }).then(res => res.json())
       .then((dashboardCampaignCount=>{
           this.setState({dashboardCampaignCount: dashboardCampaignCount})
           
         }
         )).catch(function (err) {console.log(err)});

    }else if(this.state.tab=="Pause")
    {

     var data1={ agencyID:agencyID,pacing:this.state.pacing}

     fetch("/agencyDashboard/dashboardPausedCampaignNew", {
       method: "POST",
       headers: {"Content-Type": "application/json"},
       body: JSON.stringify(data1)
     }).then(res => res.json())
     .then((campaignDetails=>{
         this.setState({campaignDetails: campaignDetails})
        
       }
       )).catch(function (err) {console.log(err)});

       var data={ agencyID:agencyID,pacing:this.state.pacing}

       fetch("/agencyDashboard/dashboardCampaignCount", {
         method: "POST",
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(data)
       }).then(res => res.json())
       .then((dashboardCampaignCount=>{
           this.setState({dashboardCampaignCount: dashboardCampaignCount})
          
         }
         )).catch(function (err) {console.log(err)});


    }else if(this.state.tab=="Completed")
    {

     var data1={ agencyID:agencyID,pacing:this.state.pacing}
     fetch("/agencyDashboard/dashboardArchiveCampaign", {
       method: "POST",
       headers: {"Content-Type": "application/json"},
       body: JSON.stringify(data1)
     }).then(res => res.json())
     .then((campaignDetails=>{

         this.setState({campaignDetails: campaignDetails})
        
       }
       )).catch(function (err) {console.log(err)});

       var data={ agencyID:agencyID,pacing:this.state.pacing}

       fetch("/agencyDashboard/dashboardCampaignCount", {
         method: "POST",
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(data)
       }).then(res => res.json())
       .then((dashboardCampaignCount=>{
           this.setState({dashboardCampaignCount: dashboardCampaignCount})
          
         }
         )).catch(function (err) {console.log(err)});
     }
         

              }
              })
  
              this.setState({inputClass: "disabled"})
              // this.setState({newSuccess:message1}, () => {
              //   });
              // this.setState({newSuccess:message1})
           
        }).catch(function (err) {console.log(err)});

         
        }///
      })
    }


    
    pauseCampaign(e){
      e.preventDefault();
      const {isAuthenticated, user} = this.props.auth;
         var agencyID=user.id;
       
      // const {isAuthenticated, user} = this.props.auth;
      // var agencyID=user.id;
      this.setState({agencyID:agencyID});
  
      var campID=e.target.id;
     
      Swal.fire({
        title: 'Are you sure you want to pause the campaign?',
        html:"This will pause the campaign for all allocated publishers.<br/>"+
        "<br/><span style='color:red;'><b>* Reason For Pause</b></span>"+
        "<div><textarea id='pauseReason' rows='2' cols='40' style='resize:none';></textarea></div>",
        type: 'question',
        showCancelButton: true,
        cancelButtonColor: '#3085d6',
        confirmButtonColor: '#d33',
        allowOutsideClick: false,
        confirmButtonText: 'Yes, pause it!', 

              }).then((result) => {

        if (result.value&&document.getElementById('pauseReason').value!=="") {
          var reason=document.getElementById('pauseReason').value
          let data = {
            campID:campID,
            user:user,
            reason:reason
          };
        
         
          fetch("/agency/agencyPausedCampaign", {
            //insert data in publisher_assignment table
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
          })

          Swal.fire({
              
              text: 'Campaign paused !',
              type: 'success',
              confirmButtonText: 'Ok',
              preConfirm: () => {
               
            
    if(this.state.tab=="Active")
     {
      
      var data1={ agencyID:agencyID,pacing:this.state.pacing}
      
      fetch("/agencyDashboard/dashboardActiveCampaign", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data1)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails})
          
          
        }
        )).catch(function (err) {console.log(err)});

        var data={ agencyID:agencyID,pacing:this.state.pacing}

        fetch("/agencyDashboard/dashboardCampaignCount", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((dashboardCampaignCount=>{

            this.setState({dashboardCampaignCount: dashboardCampaignCount})
           
        }

          )).catch(function (err) {console.log(err)});


     }else if(this.state.tab=="All")
     {

     var data1={ agencyID:agencyID,pacing:this.state.pacing}
       fetch("/agencyDashboard/dashboardAllCampaign", {
         method: "POST",
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(data1)
       }).then(res => res.json())
       .then((campaignDetails=>{
           this.setState({campaignDetails: campaignDetails})

         }
         )).catch(function (err) {console.log(err)});

         var data={ agencyID:agencyID,pacing:this.state.pacing}

         fetch("/agencyDashboard/dashboardCampaignCount", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((dashboardCampaignCount=>{
            this.setState({dashboardCampaignCount: dashboardCampaignCount})
           
          }
          )).catch(function (err) {console.log(err)});

     }else if(this.state.tab=="Pause")
     {

      var data1={ agencyID:agencyID,pacing:this.state.pacing}

      fetch("/agencyDashboard/dashboardPausedCampaignNew", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data1)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails})
         
        }
        )).catch(function (err) {console.log(err)});

        var data={ agencyID:agencyID,pacing:this.state.pacing}

        fetch("/agencyDashboard/dashboardCampaignCount", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((dashboardCampaignCount=>{
            this.setState({dashboardCampaignCount: dashboardCampaignCount})
           
          }
          )).catch(function (err) {console.log(err)});


     }else if(this.state.tab=="Completed")
     {

      var data1={ agencyID:agencyID,pacing:this.state.pacing}
      fetch("/agencyDashboard/dashboardArchiveCampaign", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data1)
      }).then(res => res.json())
      .then((campaignDetails=>{

          this.setState({campaignDetails: campaignDetails})
         
        }
        )).catch(function (err) {console.log(err)});

        var data={ agencyID:agencyID,pacing:this.state.pacing}

        fetch("/agencyDashboard/dashboardCampaignCount", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((dashboardCampaignCount=>{
            this.setState({dashboardCampaignCount: dashboardCampaignCount})
           
          }
          )).catch(function (err) {console.log(err)});
      }
          

    }
  })
    
        }///
        else
        {
          if((document.getElementById('pauseReason').value===""||document.getElementById('pauseReason').value===undefined||document.getElementById('pauseReason').value===null)&&result.value===true)
        {
          Swal.fire({
            text: 'Please Enter Reason For Pausing the Campaign !',
            type: 'error',
            timer:8000,
            showConfirmButton: false
          })
       
      }
        }
      })
    }
   
    resumeCampaign(e){
      e.preventDefault();
      const {isAuthenticated, user} = this.props.auth;
         var agencyID=user.id;
      
      // const {isAuthenticated, user} = this.props.auth;
      // var agencyID=user.id;
      this.setState({agencyID:agencyID});
  
      var campID=e.target.id;
      
      Swal.fire({
        title: 'Are you sure you want to resume the campaign?',
        text: 'This will resume the campaign for all allocated publishers.',
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#3085d6',
        confirmButtonColor: '#d33',
        allowOutsideClick: false,
        confirmButtonText: 'Yes, resume it!'
      }).then((result) => {
        if (result.value) {
          let data = {
            campID:campID,
            user:user
          };
          fetch("/agency/agencyActiveCampaign", {
            //insert data in publisher_assignment table
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
          })

          Swal.fire({
              
              text: 'Campaign resumed !',
              type: 'success',
              confirmButtonText: 'Ok',
              preConfirm: () => {
               
            
    if(this.state.tab=="Active")
     {
      
      var data1={ agencyID:agencyID,pacing:this.state.pacing}
      
      fetch("/agencyDashboard/dashboardActiveCampaign", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data1)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails})
          
          
        }
        )).catch(function (err) {console.log(err)});

        var data={ agencyID:agencyID,pacing:this.state.pacing}

        fetch("/agencyDashboard/dashboardCampaignCount", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((dashboardCampaignCount=>{

            this.setState({dashboardCampaignCount: dashboardCampaignCount})
           
        }

          )).catch(function (err) {console.log(err)});


     }else if(this.state.tab=="All")
     {

     var data1={ agencyID:agencyID,pacing:this.state.pacing}
       fetch("/agencyDashboard/dashboardAllCampaign", {
         method: "POST",
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(data1)
       }).then(res => res.json())
       .then((campaignDetails=>{
           this.setState({campaignDetails: campaignDetails})

         }
         )).catch(function (err) {console.log(err)});

         var data={ agencyID:agencyID,pacing:this.state.pacing}

         fetch("/agencyDashboard/dashboardCampaignCount", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((dashboardCampaignCount=>{
            this.setState({dashboardCampaignCount: dashboardCampaignCount})
           
          }
          )).catch(function (err) {console.log(err)});

     }else if(this.state.tab=="Pause")
     {

      var data1={ agencyID:agencyID,pacing:this.state.pacing}

      fetch("/agencyDashboard/dashboardPausedCampaignNew", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data1)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails})
         
        }
        )).catch(function (err) {console.log(err)});

        var data={ agencyID:agencyID,pacing:this.state.pacing}

        fetch("/agencyDashboard/dashboardCampaignCount", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((dashboardCampaignCount=>{
            this.setState({dashboardCampaignCount: dashboardCampaignCount})
           
          }
          )).catch(function (err) {console.log(err)});


     }else if(this.state.tab=="Completed")
     {

      var data1={ agencyID:agencyID,pacing:this.state.pacing}
      fetch("/agencyDashboard/dashboardArchiveCampaign", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data1)
      }).then(res => res.json())
      .then((campaignDetails=>{

          this.setState({campaignDetails: campaignDetails})
         
        }
        )).catch(function (err) {console.log(err)});

        var data={ agencyID:agencyID,pacing:this.state.pacing}

        fetch("/agencyDashboard/dashboardCampaignCount", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((dashboardCampaignCount=>{
            this.setState({dashboardCampaignCount: dashboardCampaignCount})
           
          }
          )).catch(function (err) {console.log(err)});
      }
          

    }
  })
    
        }///
      })
    }
 
 /**
           * @author Narendra Phadke
           * @param  Description handle Cancel campaign
           * @return Description return campaign moved to cancel tab
           */
   

  cancelCampaign(e){
    e.preventDefault();
    const {user} = this.props.auth;
       var agencyID=user.id;
    
    // const {isAuthenticated, user} = this.props.auth;
    // var agencyID=user.id;
    this.setState({agencyID:agencyID});
    let self=this;
    var campID=e.target.id;
      var parentCampID=e.target.getAttribute('parentCampId');
     var reallocationID=e.target.getAttribute('reallocationId');
      Swal.fire({
      title: 'Are you sure you want to cancel this campaign?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      allowOutsideClick: false,
      confirmButtonText: 'Yes, Cancel it!'
    }).then((result) => {
      if (result.value) {
        let data = {
                    campID:campID,
                    parentCampID:parentCampID,
                    reallocationID:reallocationID,
                    user:user
                  };
       
        fetch("/agencyDashboard/agencyCancelCampaign", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }).then(function(response) {
          // console.log("Successfully Campaign Moved To Active Tab !!!");
            self.handleRefreshData();
         }).catch(function (err) {console.log(err)});

        Swal.fire({              
            text: 'Campaign Cancelled successfully!',
            type: 'success',
            confirmButtonText: 'Ok',
            preConfirm: () => { }})
  
      }
    })
  }




     /**
           * @author Somnath Keswad
           * @param  Description handle Active selected campaign
           * @return Description return campaign moved to active tab
           */
    liveCampaign(e){
      e.preventDefault();
      const {user} = this.props.auth;
         var agencyID=user.id;
      
      // const {isAuthenticated, user} = this.props.auth;
      // var agencyID=user.id;
      this.setState({agencyID:agencyID});
      let self=this;
      var campID=e.target.id;
        Swal.fire({
        title: 'Are you sure you want to Activate the campaign?',
        text: 'This will Activate the campaign for all allocated publishers.',
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#3085d6',
        confirmButtonColor: '#d33',
        allowOutsideClick: false,
        confirmButtonText: 'Yes, Activate it!'
      }).then((result) => {
        if (result.value) {
          let data = {
            campID:campID,
            user:user
          };
         
          fetch("/agency/backToLiveCampaign", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
          }).then(function(response) {
            // console.log("Successfully Campaign Moved To Active Tab !!!");
              self.handleRefreshData();
           }).catch(function (err) {console.log(err)});

          Swal.fire({              
              text: 'Campaign Activated !',
              type: 'success',
              confirmButtonText: 'Ok',
              preConfirm: () => { }})
    
        }
      })
    }

    displayCampaignSpecification(e)
    {
   var campID = e.target.getAttribute('campID');
   var campaignName = e.target.getAttribute('campaignName');
  var parentCampID=e.target.getAttribute('parentCampId');
  var reallocationID=e.target.getAttribute('reallocationId');
 

  
  window.location.href ='/campaignSpecificationDashboard?campID='+campID+'&parentCampID='+parentCampID;
     
    
    }

    displayCampaignSpecificationAlerts(e)
    {
   var campID = e.target.getAttribute('campID');
   var campaignName = e.target.getAttribute('campaignName');
  var parentCampID=e.target.getAttribute('parentCampId');
  var reallocationID=e.target.getAttribute('reallocationId');
 

  
  
  window.location.href ='/agencyUnreadAlerts?campID='+campID+'&parentCampID='+parentCampID;
     
    
    }
    displayCampaignSpecificationMsg(e)
    {
   var campID = e.target.getAttribute('campID');
   var campaignName = e.target.getAttribute('campaignName');
  var parentCampID=e.target.getAttribute('parentCampId');
  var reallocationID=e.target.getAttribute('reallocationId');
  var publisherID=e.target.getAttribute('pID');

  
  window.location.href ='/campaignSpecificationDashboard?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&pID='+publisherID;
     
    
    }


    displayCampaignSetup(e)
    {
    var advCampID = e.target.getAttribute('advCampID');
    var advCampaignName = e.target.getAttribute('advCampaignName');
    var agencyAllocationID=e.target.getAttribute('agencyAllocationID');
   window.location.href ='/campaignsetupSpecification?advCampID='+advCampID+'&advCampaignName='+advCampaignName+'&agencyAllocationID='+agencyAllocationID;
     
    
    }
    /**
           * @author Supriya Gore
           * @param  Description handle allocated campaign tab campaign
           * @return Description return successfully return on allocated campaign
           */
    allocatedCampaign(e)
    {

   var campID = e.target.getAttribute('campID');
   var campaignName = e.target.getAttribute('campaignName');
  var parentCampID=e.target.getAttribute('parentCampId');
  var reallocationID=e.target.getAttribute('reallocationId');
  
  // window.location.href ='/allocatedCampaignTab?campID='+campID;
  window.location.href ='/campaignRespond?campID='+campID+'&activeKey='+this.state.RFPBiddingTab;
     
    
    }


    rFPBiddingTab(e)
    {

      var campID = e.target.getAttribute('campID');
      var campaignName = e.target.getAttribute('campaignName');
     var parentCampID=e.target.getAttribute('parentCampId');
     var reallocationID=e.target.getAttribute('reallocationId');
     
     window.location.href ='/requestProposalAgency?campID='+campID;
    }
    
    getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
      render: text => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ),
    });
  
    handleSearch = (selectedKeys, confirm) => {
      confirm();
      this.setState({ searchText: selectedKeys[0] });
    };
  
    handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
    };
  
    handleChangePageSize(e)
    {
      var pageSize=e.target.value;
      this.setState({pageSize:pageSize});
    }
    
    
    handleChangePageSize1(e)
    {
      var pageSize1=e.target.value;
      this.setState({pageSize1:pageSize1});
    }
    
    
    handleChangePageSize2(e)
    {
      var pageSize2=e.target.value;
      this.setState({pageSize2:pageSize2});
    }

    handleChangePageSize3(e)
    {
      var pageSize3=e.target.value;
      this.setState({pageSize3:pageSize3});
    }
    handleChangePageSize4(e)
    {
      //alert("7")
      var pageSize4=e.target.value;
      this.setState({pageSize4:pageSize4});
    }
    
    // allocatePublisher(e){
    //   var campID=e.target.id;
    //   window.location.href = '/publisherAllocation?campID='+campID+'';
    //   }


    handleChange(e) 
    {
    var pacing=e.target.id;
  document.getElementById("myText").innerHTML = e.target.name;
    var parsed = queryString.parse(this.props.location.search);
    var agencyID=parsed.agencyID;
    this.setState({pacing:pacing});
    var tabName=this.state.tabName;
    var data={ agencyID:agencyID,pacing:pacing}

    fetch("/agencyDashboard/dashboardCampaignCount", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    }).then(res => res.json())
    .then((dashboardCampaignCount=>{
        this.setState({dashboardCampaignCount:dashboardCampaignCount,loading:false})
       
      }
      )).catch(function (err) {console.log(err)});

      if(tabName=="Active")
     {
      fetch("/agencyDashboard/dashboardActiveCampaign", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails,loading:false})

         
        }
        )).catch(function (err) {console.log(err)});

     }else if(tabName=="All")
     {
     
       fetch("/agencyDashboard/dashboardAllCampaign", {
         method: "POST",
         headers: {"Content-Type": "application/json"},
         body: JSON.stringify(data)
       }).then(res => res.json())
       .then((campaignDetails=>{
           this.setState({campaignDetails: campaignDetails,loading:false})
          
         }
         )).catch(function (err) {console.log(err)});

     }else if(tabName=="Pause")
     {
      fetch("/agencyDashboard/dashboardPausedCampaignNew", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails,loading:false})
         
        }
        )).catch(function (err) {console.log(err)});


     }else if(tabName=="Completed")
     {
      fetch("/agencyDashboard/dashboardArchiveCampaign", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then((campaignDetails=>{
          this.setState({campaignDetails: campaignDetails,loading:false})
         
        }
        )).catch(function (err) {console.log(err)});
      }
    }

    recordOpen(parameter1,parameter2)
    {
      var alertID,clickedcampaignID;
      const {user} = this.props.auth;
      let data={alertID:parameter1,clickedcampaignID:parameter2,user:user}
      // alert("recordOpen=="+JSON.stringify(data));
      fetch("/notification/AgencyAlertsToAgecncyStatusChanged",{
        //insert data in publisher_assignment table
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      })
       .then(res => res.json())
        .catch(function (err) {console.log(err)});
    }

    componentDidMount(){
    
      let dataa={agencyID:this.state.agencyID}
      fetch("/agencyDashboard/getMonthList",{
        //insert data in publisher_assignment table
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataa)
      })
       .then(res => res.json())
       .then(monthArray => {

        this.setState({monthArrayLength:monthArray.length})
     
      }).catch(function (err) {console.log(err)});


      const {user} = this.props.auth;
      let leadData={
        user:user
      }
      fetch("/publisher/updateLeadsPermissionTimeFlag",{
        //insert data in publisher_assignment table
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(leadData)
      })
       .then(res => res.json())
       .then(updateLeadsPermissionTimeFlag => {

     
      }).catch(function (err) {console.log(err)});

     
    }

    cloneCompletedCampaign(e) {
      e.preventDefault();
      //e.target.className += " was-validated";
     
    //   if (this.validateForm1() === false) {
    //     return;
    //   }
    //  else {
      let errors={};
      var startDateValidate=true;
      var endDateValidate=true;
      var firstLeadDateValidate=true;
      var currentDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
    
      var dt = new Date(currentDate);
      dt.setMonth(dt.getMonth() - 1);
      //  alert(dt+" "+this.state.startDate)
      var month, day, year;
      month = '' + (dt.getMonth() + 1);
      day = '' + dt.getDate();
      year = dt.getFullYear();
  
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      var updatedDate = [year, month, day].join('-');

       this.setState({
          alertDisplay: 'none',alertBorder:'0px solid white'
        });

//alert(this.state.cloneStartDate+"=="+this.state.cloneEndDate+"=="+this.state.cloneFirstLeadDate+"=="+currentDate+"=="+updatedDate);


if(this.state.cloneStartDate===""||this.state.cloneStartDate===undefined ||this.state.cloneStartDate===null)
{

  errors['startDate']=<li>Please Enter Start Date</li>
  startDateValidate=false;
  this.setState({errors:errors})
}
else
{
  //alert("Start Date Present");
  if(Date.parse(this.state.cloneStartDate)>Date.parse(this.state.cloneEndDate))
  {
   // alert("Start Date Must be smaller than End Date");
  errors['startEndDate']=<li>Start Date Must be smaller than End Date</li>
  startDateValidate=false;
  this.setState({errors:errors})
 } 
 if(Date.parse(this.state.cloneStartDate)<Date.parse(updatedDate))
 {
  //alert("Start Date can not be older than a month time period");
  errors['startDateUpdated']=<li>Start Date can not be older than a month time period</li>
  startDateValidate=false;
  this.setState({errors:errors})
 }
 if(Date.parse(this.state.cloneStartDate)>(Date.parse(this.state.cloneFirstLeadDate)))
 {
  //alert("Start Date can not be greater than First Lead Delivey Date");
  errors['startDateLead']=<li>Start Date can not be greater than First Lead Delivey Date</li>
  startDateValidate=false;
  this.setState({errors:errors})
 }

}
//end of startdate validation

if(this.state.cloneEndDate===""||this.state.cloneEndDate===undefined ||this.state.cloneEndDate===null)
{
 
errors['endDate']=<li>Please Enter End Date</li>
endDateValidate=false;
this.setState({errors:errors})
}
else
{

  if(Date.parse(this.state.cloneStartDate)>Date.parse(this.state.cloneEndDate))
  {
 // alert("End Date Must be greater than Start Date");
  errors['EndStartDate']=<li>End Date Must be greater than Start Date</li>
  endDateValidate=false;
  this.setState({errors:errors})
 } 

 if(Date.parse(this.state.cloneEndDate)<Date.parse(currentDate))
 {
 // alert("End Date can not be smaller than a Current");
  errors['endDateCurrent']=<li>End Date can not be smaller than a Current</li>
  endDateValidate=false;
  this.setState({errors:errors})
 }
 if(Date.parse(this.state.cloneEndDate)<(Date.parse(this.state.cloneFirstLeadDate)))
 {
 // alert("End Date can not be smaller than First Lead Delivey Date");
  errors['endDateLead']=<li>End Date can not be smaller than First Lead Delivey Date</li>
  endDateValidate=false;
  this.setState({errors:errors})
 }




}
// end of enddate validation

if(this.state.cloneFirstLeadDate===""||this.state.cloneFirstLeadDate===undefined ||this.state.cloneFirstLeadDate===null){
 
errors['firstLeadDate']=<li>Please Enter First Lead Delivery Date</li>
firstLeadDateValidate=false
this.setState({errors:errors})
}
// else
// {
//   if(Date.parse(this.state.cloneEndDate)<(Date.parse(this.state.cloneFirstLeadDate)))
//   {
//    alert("End Date can not be smaller than First Lead Delivey Date");
//    errors['leadEndDate']=<li>First Lead Date can not be greater than End Date</li>
//    firstLeadDateValidate=false;
//    this.setState({errors:errors})
//   }

//   if(Date.parse(this.state.cloneStartDate)>(Date.parse(this.state.cloneFirstLeadDate)))
//   {
//    //alert("Start Date can not be older than First Lead Delivey Date");
//    errors['leadStartDate']=<li>First Lead Date can not be smaller than Start Date</li>
//    firstLeadDateValidate=false;
//    this.setState({errors:errors})
//   }

// }
// end of firstLeadDeliveryDate Validation





       
//alert(startDateValidate+"===="+endDateValidate+"===="+firstLeadDateValidate);
          
          
            
  if(endDateValidate===true&&firstLeadDateValidate===true&&startDateValidate===true){
  const {user} = this.props.auth;
  let errors={}
  errors['endDate']=""
  errors['commonError']=""
  errors['startDate']=""
  this.setState({errors:errors})
              let data={
                user:user,
                parentCampID:this.state.parentCampID,
                startDate:this.state.cloneStartDate,
                endDate:this.state.cloneEndDate,
                firstLeadDeliveryDate:this.state.cloneFirstLeadDate,
              }
              fetch("/agencyDashboard/cloneCompletedCampaign", {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
              }).then(res => res.json()).then(response=> { 
                  // console.log("Return with response");
                     var message1=response.message;
                    // var successmsg= document.getElementById('successmsg');
                    // successmsg.innerHTML=message1;
                   
                    Swal.fire({text: (message1),
                    type: 'success',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    preConfirm: () => {
                    window.location.reload();
                    }
                    })
        
                    this.setState({inputClass: "disabled"})
                    // this.setState({newSuccess:message1}, () => {
                    //   });
                    // this.setState({newSuccess:message1})
                 
              }).catch(function (err) {console.log(err)});
           // }
              
            }
           
          
        
  }// end of cloneCompletedCampaign
    

    reAllocateCampaign(e) {
      e.preventDefault();
      e.target.className += " was-validated";
     
      if (this.validateForm1() === false) {
        return;
      }
     else {
     
       this.setState({
          alertDisplay: 'none',alertBorder:'0px solid white'
        });
      const {user} = this.props.auth;
      let data={
        user:user,
        parentCampID:this.state.parentCampID,
        startDate:this.state.reStartDate,
        endDate:this.state.reEndDate,
        firstLeadDeliveryDate:this.state.reFirstLeadDate,
        budget:this.state.reBudget,
        CPL:this.state.reCpl,
        leadAllocation:this.state.reTotalLeads
      }
      fetch("/agency/reCreateCampaign", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json()).then(response=> { 
          // console.log("Return with response");
             var message1=response.message;
            // var successmsg= document.getElementById('successmsg');
            // successmsg.innerHTML=message1;
           
            Swal.fire({text: (message1),
            type: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            preConfirm: () => {
            window.location.reload();
            }
            })

            this.setState({inputClass: "disabled"})
            // this.setState({newSuccess:message1}, () => {
            //   });
            // this.setState({newSuccess:message1})
         
      }).catch(function (err) {console.log(err)});
    }
     
  }

  validateForm1() {
    let errors = {};
    let formIsValid = true;
    let budgetValidate=true;
    let cplValidate=true;
    let leadValidate=true;
    let dateValidate=true;
    let firstDateValidate=true;
    
    
  //  alert(this.state.reTotalLeads+" "+this.state.reBudget+" "+this.state.reCpl+" "+this.state.reStartDate+" "+this.state.reEndDate
  // +" "+this.state.reFirstLeadDate);

  //Lead validation     
 if (this.state.reTotalLeads === undefined ||this.state.reTotalLeads==="" ||this.state.reTotalLeads===null) 
 {
  leadValidate=false;
  errors["commonError"] = <li>Please Fill Below Required Fields</li>;
  this.setState({errors:errors});
  
}
else 
{

    var pattern = new RegExp(/^(0|[1-9]\d*)$/);
  if (!pattern.test(this.state.reTotalLeads)) 
  {
   
    leadValidate = false;
    errors["lead"] = <li>Please Enter Valid Number For Total Lead</li>;
    this.setState({errors:errors});
  
  }
  else
  {
    errors["lead"] = "";
    this.setState({errors:errors});
   
 }
}
// end of Lead validation




  //Budget validation     
 if (this.state.reBudget === undefined ||this.state.reBudget==="" ||this.state.reBudget===null) 
 {
  budgetValidate=false;
  errors["commonError"] = <li>Please Fill Below Required Fields</li>;
  this.setState({errors:errors});
 
}
else 
{

    var pattern = new RegExp(/^[1-9]\d*(\.\d{1,2})?$/);
  if (!pattern.test(this.state.reBudget)) 
  {
   
    budgetValidate = false;
    errors["budget"] = <li>Please Enter Valid Number For Budget</li>;
    this.setState({errors:errors});
   
  }
  else
  {
    errors["budger"] = "";
   this.setState({errors:errors});
    
 }
}
// end of Budget validation


 //cpl validation     
 if (this.state.reCpl === undefined ||this.state.reCpl==="" ||this.state.reCpl===null) 
 {
  cplValidate=false;
  errors["commonError"] = <li>Please Fill Below Required Fields</li>;
  this.setState({errors:errors});
  
}
else 
{

    var pattern = new RegExp(/^[1-9]\d*(\.\d{1,2})?$/);
  if (!pattern.test(this.state.reCpl)) 
  {
   
    cplValidate = false;
    errors["cpl"] = <li>Please Enter Valid Number For CPL</li>;
    this.setState({alertBorder:'1px solid red'})
   
  }
  else
  {
    errors["cpl"] = "";
    this.setState({errors:errors});
   
 }
}
// end of CPL validation

//SD validation     
if (this.state.reStartDate === undefined ||this.state.reStartDate==="" ||this.state.reStartDate===null) 
{
  dateValidate=false;
 errors["commonError"] = <li>Please Fill Below Required Fields</li>;
 this.setState({errors:errors});
}
else 
{

  var currentDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
var dt = new Date(currentDate);
dt.setMonth(dt.getMonth() - 1);
var month, day, year;
month = '' + (dt.getMonth() + 1);
day = '' + dt.getDate();
year = dt.getFullYear();

if (month.length < 2) month = '0' + month;
if (day.length < 2) day = '0' + day;
var updatedDate = [year, month, day].join('-');

if ((Date.parse(this.state.reStartDate) < Date.parse(updatedDate))) {
  dateValidate = false;
  errors["startDate"] = <li>Start Date Can Not Be Older Than A Month Time Period</li>;
 
}

///Start date can not be older than a month time period


if ((Date.parse(this.state.reStartDate) > Date.parse(this.state.reEndDate))) {
  dateValidate = false;
  errors["startDate"] = <li>Start Date Must Be Smaller Than End Date</li>;
  
}
//Start Date Must Be Smaller Than End Date

if ((Date.parse(this.state.reStartDate) > Date.parse(this.state.reFirstLeadDate))) {
  dateValidate = false;
  errors["startDate"] = <li>Start Date Must Be Smaller Than First Lead Delivery Date</li>;

//Start Date Must Be Smaller Than First Lead Delivery Date


}
}
// end of SD validation

  
//ED validation     
if (this.state.reEndDate === undefined ||this.state.reEndDate==="" ||this.state.reEndDate===null) 
{
  dateValidate=false;
 errors["commonError"] = <li>Please Fill Below Required Fields</li>;
 this.setState({errors:errors});
}
else 
{

  var currentDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]; 

if ((Date.parse(this.state.reStartDate) > Date.parse(this.state.reEndDate))) {
  dateValidate = false;
  errors["endDate"] = <li>End  Date Must Be Greater Than Start Date</li>;
  this.setState({errors:errors});
  
}
//End  Date Must Be Greater Than Start Date

if ((Date.parse(this.state.reEndDate) < Date.parse(this.state.reFirstLeadDate))) {
  dateValidate = false;
  errors["endDate"] = <li>End Date Must Be Greater Than First Lead Delivery Date</li>;
  this.setState({errors:errors});

//End Date Must Be Greater Than First Lead Delivery Date
}
if (this.state.reEndDate < currentDate) {
  dateValidate = false;
  errors["endDate"] = <li>End Date Must Be Greater Than Current Date</li>;
  this.setState({errors:errors});
}

}
// end of ED validation



 
//FLDD validation     
if (this.state.reFirstLeadDate === undefined ||this.state.reFirstLeadDate==="" ||this.state.reFirstLeadDate===null) 
{
  firstDateValidate=false;
 errors["commonError"] = <li>Please Fill Below Required Fields</li>;
 this.setState({errors:errors});
}
else 
{

 
  var currentDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];

  if ((Date.parse(this.state.reFirstLeadDate) < Date.parse(currentDate))){
    firstDateValidate=false;
    errors["firstdeliverydateCurrentDate"]=<li>First Lead Delivery Date Must Be Greater Than Current Date</li>;
    this.setState({errors:errors});
    }
//First Lead Delivery Date Must Be Greater Than Current Date



if ((Date.parse(this.state.reFirstLeadDate)> Date.parse(this.state.reEndDate))) {
  firstDateValidate=false;
  errors["firstdeliverydateEnddate"]=<li>First Lead Delivery Date Can't Be Greater Than End date</li>;
  this.setState({errors:errors});
}
//

if ((Date.parse(this.state.reFirstLeadDate)<Date.parse(this.state.reStartDate))){
 firstDateValidate=false;
errors["firstdeliverydateStartdate"]=<li>First Lead Delivery Date Can't Be Less Than Start Date</li>;
this.setState({errors:errors});
}

}
// end of FLDD validation
//alert(leadValidate+"=="+budgetValidate+"=="+cplValidate+"=="+dateValidate+"=="+firstDateValidate);
if(leadValidate===false|| budgetValidate===false|| cplValidate===false|| dateValidate===false|| firstDateValidate===false)
{
  formIsValid=false;
    
     this.setState({
       errors: errors,
       alertBorder: '1px solid red',
       alertWidth: '1000px',
       alertAlign: 'center',
      alertDisplay: 'block',
      
 
     });
    
}
else
{

  formIsValid=true;
  errors["commonError"] ="";
     this.setState({
       errors: errors,
       alertBorder: '0px solid while',
      alertDisplay: 'none',
     
 
     });


}
//alert("formIsValid"+formIsValid);
return formIsValid;

}// end of validateForm1

    componentWillMount() 
    {
      
   //Dashboard data
   if(!this.props.auth.isAuthenticated) {
    this.props.history.push('/userLogin');
}
else{
        const dt = new Date();
        var index=dt.getMonth();
        
         const {isAuthenticated, user} = this.props.auth;
         var agencyID=user.id;
         var userID=user.userID;
         var userRole=user.role;
          var firstName=user.firstName;
          var lastName=user.lastName;
        this.setState({agencyID:agencyID,invoiceLength:index,userID:userID,firstName:firstName,lastName:lastName});

        // var userID=user.userID;
        // var firstName=user.firstName;
        // var lastName=user.lastName;
        // fetch("agency/autoCompleteStatus?agencyID="+agencyID+ "&userID="+userID+"&firstName="+firstName+"&lastName="+lastName)
        // .then(res => res.json()).catch(function (err) {
        //   console.log(err)
        // });
        var datetime = new Date();
    var enddate=datetime.toISOString().slice(0,10);
        this.setState({enddate:enddate});

      var data={ agencyID:agencyID,pacing:this.state.pacing};

         
      // fetch("/agencyDashboard/feedbackFileDownload?invoiceID="+invoiceID)
      // .then(r => r.blob())
      // .then(downloadFiles =>
      // this.setState({ downloadFiles: downloadFiles}))
      // .then(this.reviewInvoice);


        fetch("/agencyDashboard/dashboardCampaignCount", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((dashboardCampaignCount=>{
            this.setState({dashboardCampaignCount: dashboardCampaignCount})
            // alert(JSON.stringify(this.state.dashboardCampaignCount));
           
          }
          )).catch(function (err) {console.log(err)});
        
          var tab="Active";
        fetch("/agencyDashboard/dashboardActiveCampaign", {
        
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((campaignDetails=>{

            this.setState({campaignDetails:campaignDetails,tab:tab,loading:false})
           
          }
          )).catch(function (err) {console.log(err)});

         
            //getting Campaign ID which status is New, Counter, Reject
            fetch("agencyDashboard/notifyDashNewCamp?agencyID="+agencyID)
            .then(res => res.json())
            .then(notifyDashNewCamp =>{
            this.setState({biddingArray:notifyDashNewCamp[1],counterLength:notifyDashNewCamp[1].length,loading1:false})
            }).catch(function (err) {
            console.log(err)
            });


            fetch("agencyDashboard/notifyDashNewCamp?agencyID="+agencyID)
            .then(res => res.json())
            .then(notifyDashNewCamp =>{
           
            this.setState({rejectLength:notifyDashNewCamp[2].length})
            }).catch(function (err) {
            console.log(err)
            });


            fetch("agencyDashboard/leadReviewDashboard?agencyID="+agencyID+"&userRole="+userRole)
            .then(res => res.json())
            .then(leadReviewDashboard =>{
              this.setState({ leadsLength:leadReviewDashboard.length})
              }).catch(function (err) {
              console.log(err)
            });

            fetch("/campaign/publisherLinkReview", {
              method: "POST",
              headers: {"Content-Type": "application/json"},
              body: JSON.stringify(data)
            }).then(res => res.json())
            .then((publisherLinkReview=>{
                this.setState({ othersLength:publisherLinkReview.length})
               
              }
              )).catch(function (err) {console.log(err)});

             //getting Campaign ID which Delivered The Lead
             var ReviewTab="ReviewCreatives"
            fetch("agencyDashboard/lpPOCCSReviewNotifyDash?agencyID="+agencyID)
            .then(res => res.json())
            .then(lpPOCCSReviewNotifyDash =>{

              this.setState({ reviewArray:lpPOCCSReviewNotifyDash,creativeLength:lpPOCCSReviewNotifyDash.length,ReviewTab:ReviewTab,loading2:false})
              }).catch(function (err) {
              console.log(err)
            });
          }
          // Get RFP Bidding campID
          fetch("agencyDashboard/biddingCampIDOnDashBoard?agencyID="+agencyID)
          .then(res => res.json())
          .then(rfpBidding =>{
            this.setState({ rfpBiddingLength:rfpBidding.length})
            }).catch(function (err) {console.log(err)});
          
          fetch("agencyDashboard/completeOnEndDate?agencyID="+agencyID+ "&userID="+userID+"&firstName="+firstName+"&lastName="+lastName)
          .then(res => res.json()).catch(function (err) {
            console.log(err)
          });
          // If bidding date is crossed
          fetch("agencyDashboard/checkBiddingDateCrossed?agencyID="+agencyID)
          .then(res => res.json()).catch(function (err) {console.log(err)});

          /* @author Narendra Phadke
          * @param  Description Unread message on dashboard
          * @return Description get all unread message
          */
          let dataForUnread={
            agencyID:agencyID
          }
          fetch("/notification/agencyDashboardUnreadMessages", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(dataForUnread)
          }).then(res => res.json())
          .then((unreadMessages=>{
            // alert("UnRead Message=="+JSON.stringify(unreadMessages));
              this.setState({unreadMessages:unreadMessages, unreadMessagesLength:unreadMessages.length})
             
            }
            )).catch(function (err) {console.log(err)});

            /* @author Narendra Phadke
          * @param  Description Unread message on dashboard
          * @return Description get all unread message
          */
            fetch("/notification/agencyDashboardUnreadAlerts", {
              method: "POST",
              headers: {"Content-Type": "application/json"},
              body: JSON.stringify(dataForUnread)
            }).then(res => res.json())
            .then((unreadAlerts=>{
              
                this.setState({unreadAlerts:unreadAlerts,unreadAlertsLength:unreadAlerts.length})
                if(this.state.unreadAlerts&&this.state.unreadAlerts.length>0)
                        {
                          var name="key"
                          var value
                          for(var i=0;i<this.state.unreadAlerts.length;i++)
                          { 
                            value=this.state.unreadAlerts[i].alertID
                            let unreadAlerts=[...this.state.unreadAlerts]
                            unreadAlerts[i] = { ...unreadAlerts[i], [name]: value };
                            //console.log(JSON.stringify(unreadAlerts[i]))
                          this.setState({unreadAlerts:unreadAlerts})
                          }
                        }
                    // console.log("UnRead MessaunreadAlertsge=="+unreadAlerts.length+" "+JSON.stringify(this.state.unreadAlerts));

              }
              )).catch(function (err) {console.log(err)});
 } // end of componentdidMount

 
 
    reAllocate(e){
      var campID=e.target.id;
      var parentCampID=e.target.getAttribute('parentCampID');
      var reallocationID=e.target.getAttribute('reallocationID');
      this.setState({parentCampID:parentCampID,reallocationID:reallocationID})
    }

    
/**
* @author Narendra
* @param  Description  Searching table information
*/
handleChangeSearch(e) {
  e.preventDefault();
  let currentList = [];
      // Variable to hold the filtered list before putting into state
  let newList = [];
  let filterData = e.target.value;
  // If the search bar isn't empty
  if (e.target.value === "") {
      newList = this.state.campaignDetails;
  } else {
      // Assign the original list to currentList
      
      currentList = this.state.campaignDetails;
      newList = currentList.filter(item => {
                // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
                // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        //const filter = e.target.value.toLowerCase();
          // check to see if the current list item includes the search term
          // If it does, it will be added to newList. Using lowercase eliminates
          // issues with capitalization in search terms and search content
        var data=Object.values(lc);
        var dataFinal=data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower=dataFinal.toLowerCase();
       return dataToLower.includes(filter);
});
}
  // Set the filtered state based on what our rules added to newList
this.setState({
campaignDetailsSearch:[...newList],tableSearchText:filterData
});
}


  /**
* @author Narendra
* @param  Description  Searching table information
*/
handleChangeSearch1(e) {
  e.preventDefault();
  let currentList = [];
      // Variable to hold the filtered list before putting into state
  let newList = [];
  let filterData = e.target.value;
  // If the search bar isn't empty
  if (e.target.value === "") {
      newList = this.state.unreadMessages;
  } else {
      // Assign the original list to currentList
      currentList = this.state.unreadMessages;
      newList = currentList.filter(item => {
                // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
                // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        //const filter = e.target.value.toLowerCase();
          // check to see if the current list item includes the search term
          // If it does, it will be added to newList. Using lowercase eliminates
          // issues with capitalization in search terms and search content
        var data=Object.values(lc);
        var dataFinal=data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower=dataFinal.toLowerCase();
       return dataToLower.includes(filter);
});
  //alert("JSON Data=="+JSON.stringify(newList))
}
  // Set the filtered state based on what our rules added to newList
this.setState({
  columnUnreadMsgSearch:[...newList],tableSearchText1:filterData
});
}




    /**
* @author Narendra
* @param  Description  Searching table information
*/
handleChangeSearch2(e) {
  e.preventDefault();
  let currentList = [];
      // Variable to hold the filtered list before putting into state
  let newList = [];
  let filterData = e.target.value;
  // If the search bar isn't empty
  if (e.target.value === "") {
      newList = this.state.unreadAlerts;
  } else {
      // Assign the original list to currentList
      currentList = this.state.unreadAlerts;
      newList = currentList.filter(item => {
                // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
                // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        //const filter = e.target.value.toLowerCase();
          // check to see if the current list item includes the search term
          // If it does, it will be added to newList. Using lowercase eliminates
          // issues with capitalization in search terms and search content
        var data=Object.values(lc);
        var dataFinal=data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower=dataFinal.toLowerCase();
       return dataToLower.includes(filter);
});
  //alert("JSON Data=="+JSON.stringify(newList))
}
  // Set the filtered state based on what our rules added to newList
this.setState({
  columnUnreadAlertsSearch:[...newList],tableSearchText2:filterData
});
}

handleChangeIncremental(e) {
  let fields = this.state;
  fields[e.target.name] = e.target.value;
  this.setState({
    fields
  });

} // end of  handleChange(e)

componentWillReceiveProps(nextProps) {
this.setState({
campaignDetails: nextProps.campaignDetails
});
}
getInvoiceLink(e){
  var month=e.target.getAttribute('month');
  var year=e.target.getAttribute('year');
  window.location.href="/agencyInvoice?month="+month+"&Year="+year;
}

getPublisherLink(e){
  var pID=e.target.id;
  var userType=e.target.getAttribute("userType");
  if(userType==="Publisher"){
    window.location.href="/reviewLinkPublisher?pID="+pID;
  }
  else{
    window.location.href="/reviewLinkAdvertiser?advertiserID="+pID;

  }
}


  /**
 * @author Supriya Gore
 * @param  Description  handle libyAI url with post method
 * @return Description return successfully response with url
 */
getLIbyAIURL(e){
  const {isAuthenticated, user} = this.props.auth;
  var campID = e.target.id;

  let data={
    user:user,
    campID:campID
  }

  // fetch("/setCookie", {
  //   method: 'POST',
  //   headers: { "Content-Type": "application/json" },
  //   body: JSON.stringify(data)
  // }).then(res => res.json())
  // .then(response=> { 
     this.handleRefreshData(e);
  //   if(response.message==="cookiesSet")
  //   {
      window.open('http://libyai.com/LoginControl?campID='+campID+'&userID='+user.id, '_blank');
      //  window.open('http://localhost:8091/LIbyAI/LoginControl?campID='+campID+'&userID='+user.id, '_blank');
  //   }
    
  // })

  
}
    render() {  
      
 
      const asterisk =
      {
        color: 'red',
      }
      const {isAuthenticated, user} = this.props.auth;

      const columnUnreadMsg=[
        {
          title: 'Camp_Id',
          dataIndex: 'campID',
          key: 'campID',
          width:80,
          align:'center',
          render: (text,record) => 
          <div >
        <span  style={{fontSize:'11px'}}> <a href="#" onClick={this.displayCampaignSpecificationMsg} campID={record.campID}  
              campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID} pID={record.pID}
              style={{fontSize:"11px" ,wordBreak:"break-all"}}>{record.parentCampID?record.parentCampID:record.campID}</a></span>
    
          </div>
        },
        {
          title: 'Campaign Name',
          dataIndex: 'campaignName',
          key: 'campaignName',
          width:200,
          align:'center',
          render: (text,record) => 
          <div >
        <div class="dot2" style={{fontSize:'11px'}}>{record.campaignName}</div>
    
          </div>
        },
        {
          title: 'Title',
          dataIndex: 'content',
          key: 'content',
          width:180,
          align:'center',
          render: (text,record) => 
          <div >
        <div class="dot2" style={{fontSize:'11px'}}>{record.content}</div>
    
          </div>
        },
        {
          title: 'Sender',
          dataIndex: 'author',
          key: 'author',
          width:120,
          align:'center',
          render: (text,record) => 
          <div >
        <span  style={{fontSize:'11px'}}>{record.author}</span>
    
          </div>
        },
        {
          title: 'When',
          dataIndex: 'datetime',
          key: 'datetime',
          //width:100,
          bgcolor:'red',
          align:'center',
          render: (text,record) => 
          <div>
          <span  style={{fontSize:'11px'}}>
          <Moment format="DD-MMM-YY HH:mm">
              {record.datetime}
          </Moment>
            </span>
    
          </div>
        },

      ]


      const columnUnreadAlerts=[
        {
          title: 'Camp_Id',
          dataIndex: 'campID',
          key: 'campID',
          width:80,
          align:'center',
          render: (text,record) => 
          <div >
          <span  style={{fontSize:'12px'}}> <a href="#" onClick={this.displayCampaignSpecificationAlerts} campID={record.campID}  
                campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID} pID={record.pID}
                style={{fontSize:"11px" ,wordBreak:"break-all"}}>{record.campID}</a></span>
        {/* <span  style={{fontSize:'11px'}}>{record.parentCampID?record.parentCampID:record.campID}</span> */}
            </div>
        },
        {
          title: 'Campaign Name',
          dataIndex: 'campaignName',
          key: 'campaignName',
          width:160,
          align:'center',
          render: (text,record) => 
          <div >
        <div class="dotUnreadAlert" style={{fontSize:'11px',width:'110px'}}>{record.campaignName}</div>
    
          </div>
        },
        {
          title: 'Title',
          dataIndex: 'content',
          key: 'content',
          width:180,
          align:'center',
          render: (text,record) => 
          <div >
        <div class="word-wrap" style={{fontSize:'11px'}}>{record.content}</div>
    
          </div>
        },
        {
          title: 'Sender',
          dataIndex: 'author',
          key: 'author',
          width:80,
          align:'center',
          render: (text,record) => 
          <div >
        <span  style={{fontSize:'11px'}}>{record.author}</span>
    
          </div>
        },
        {
          title: 'When',
          dataIndex: 'datetime',
          key: 'datetime',
          width:100,
          bgcolor:'red',
          align:'center',
          render: (text,record) => 
        
         <div >
        <span  style={{fontSize:'11px'}}>
          <Moment format="DD-MMM-YY HH:mm">
            {record.datetime}
          </Moment>
        </span>
    
          </div>
        },

      ]


const invoiceData=[
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
      width: '11%',
      align:'center',
    sorter: (a, b) => a.ID - b.ID,
    render: (text,record) => 
    <div><span>{record.ID}</span></div>
    },
    {
      title: 'Months',
      dataIndex: 'month',
      key: 'month',
      align:'center',  
       sorter: (a, b) => {
        const asc = 'asc';
        if ((a.month).toUpperCase() < (b.month).toUpperCase()) {
            return asc ? -1 : 1;
        } else if ((a.month).toUpperCase() > (b.month).toUpperCase()) {
            return asc ? 1 : -1;
        } else {
            return 0;
        }
      },
       render: (text,record) => 
      <div >
          <span style={{fontSize:'12px'}}>{record.month}</span>
      </div>
      },
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        width: '11%',
        align:'center',
      sorter: (a, b) => a.year - b.year,
      render: (text,record) => 
      <div><span>{record.year}</span></div>
      },
      {
        title: 'Invoice Link',
        dataIndex: 'invoiceLink',
        key: 'invoiceLink',
        width: '40%',
        align:'center',
      sorter: (a, b) => a.invoiceLink - b.invoiceLink,
      render: (text,record) => 
      <div><span><a href="#" month={record.month} year={record.year} onClick={this.getInvoiceLink.bind(this)} style={{fontSize:"12px"}}
     >Get Invoice</a></span></div>
      }
];
const columns01 = [
  {
      title: 'Camp_ID',
      dataIndex: 'campID',
      key: 'campID',
      width: '50%',
      //  ...this.getColumnSearchProps('campID'),
     align:'center',
     sorter: (a, b) => a.campID - b.campID,
     render: (text,record) => 
     <div>
  <span>
  {this.state.RFPBiddingTab==='RFPBiddingTab'?
   <a href="#" 
   onClick={this.rFPBiddingTab} campID={record.campID}  
   campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID}
  style={{fontSize:"12px"}}
  >{record.parentCampID?record.parentCampID:record.campID}</a>:
    <a href="#" 
  onClick={this.allocatedCampaign} campID={record.campID}  
  campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID}
 style={{fontSize:"12px"}}
 >{record.parentCampID?record.parentCampID:record.campID}</a>
  }</span>
 



     </div>
      
  },
  {
      title: 'Camp_Name',
      dataIndex: 'campaignName',
      key: 'campaignName',
     
      //  ...this.getColumnSearchProps('campaignName'),
       align:'center',  
       sorter: (a, b) => {
        const asc = 'asc';
        if ((a.campaignName).toUpperCase() < (b.campaignName).toUpperCase()) {
            return asc ? -1 : 1;
        } else if ((a.campaignName).toUpperCase() > (b.campaignName).toUpperCase()) {
            return asc ? 1 : -1;
        } else {
            return 0;
        }
      },
       render: (text,record) => 
       <div class="dot" style={{textAlign:'center'}}>
  <span style={{fontSize:'12px'}}>{record.campaignName}</span>
  
 
       </div>
    
  }
]

     const columns1 = [
      {
          title: 'Camp_ID',
          dataIndex: 'campID',
          key: 'campID',
          width: '48%',
          //  ...this.getColumnSearchProps('campID'),
         align:'center',
         sorter: (a, b) => a.campID - b.campID,
         render: (text,record) => 
         <div>
      <span><a href="#" 
      onClick={this.creativesReview} campID={record.campID}  
      campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID}
     style={{fontSize:"12px"}}
     >{record.parentCampID?record.parentCampID:record.campID}</a></span>
     

    
   
         </div>
          
      },
      {
          title: 'Camp_Name',
          dataIndex: 'campaignName',
          key: 'campaignName',
         
          //  ...this.getColumnSearchProps('campaignName'),
           align:'center',  
           sorter: (a, b) => {
            const asc = 'asc';
            if ((a.campaignName).toUpperCase() < (b.campaignName).toUpperCase()) {
                return asc ? -1 : 1;
            } else if ((a.campaignName).toUpperCase() > (b.campaignName).toUpperCase()) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
          },
           render: (text,record) => 
           <div class="dot" style={{textAlign:'center'}}>
      <span style={{fontSize:'12px'}}>{record.campaignName}</span>
      
     
           </div>
        
      }
    ]
    
//Shivani- create different column for RFP
   
const columnsRFP = [
  {
      title: 'Proposal_ID',
      dataIndex: 'campID',
      key: 'campID',
      width: '50%',
      //  ...this.getColumnSearchProps('campID'),
     align:'center',
     sorter: (a, b) => a.campID - b.campID,
     render: (text,record) => 
     <div>
  <span>
  {this.state.RFPBiddingTab==='RFPBiddingTab'?
   <a href="#" 
   onClick={this.rFPBiddingTab} campID={record.campID}  
   campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID}
  style={{fontSize:"12px"}}
  >{record.parentCampID?record.parentCampID:record.campID}</a>:
    <a href="#" 
  onClick={this.allocatedCampaign} campID={record.campID}  
  campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID}
 style={{fontSize:"12px"}}
 >{record.parentCampID?record.parentCampID:record.campID}</a>
  }</span>
 



     </div>
      
  },
  {
      title: 'Camp_Name',
      dataIndex: 'campaignName',
      key: 'campaignName',
     
      //  ...this.getColumnSearchProps('campaignName'),
       align:'center',  
       sorter: (a, b) => {
        const asc = 'asc';
        if ((a.campaignName).toUpperCase() < (b.campaignName).toUpperCase()) {
            return asc ? -1 : 1;
        } else if ((a.campaignName).toUpperCase() > (b.campaignName).toUpperCase()) {
            return asc ? 1 : -1;
        } else {
            return 0;
        }
      },
       render: (text,record) => 
       <div class="dot" style={{textAlign:'center'}}>
  <span style={{fontSize:'12px'}}>{record.campaignName}</span>
  
 
       </div>
    
  }
]


     const columns2 = [
      {
          title: 'Camp_ID',
          dataIndex: 'campID',
          key: 'campID',
                    //  ...this.getColumnSearchProps('campID'),
         align:'center',
         width:'19%',
         sorter: (a, b) => a.campID - b.campID,
         render: (text,record) => 
         <div>
         
      <span style={{fontSize:"12px"}}>
        {this.state.ReviewTab==='ReviewCreatives'?
        <a href="#" onClick={this.creativesReview}  campID={record.campID}  
         parentCampID={record.parentCampID} reallocationID={record.reallocationID}style={{fontSize:"12px"}}
      >{record.parentCampID?record.parentCampID:record.campID}</a>:
      
      <a href="#" onClick={this.leadReview} campID={record.campID}  
      parentCampID={record.parentCampID} reallocationID={record.reallocationID}
      
     
     style={{fontSize:"12px"}}
      >{record.parentCampID?record.parentCampID:record.campID}</a>}
      </span>
     

    
   
         </div>
          
      },
      {
          title: 'Camp_Name',
          dataIndex: 'campaignName',
          key: 'campaignName',
          width:'23%',

          //  ...this.getColumnSearchProps('campaignName'),
           align:'center',  
           sorter: (a, b) => {
            const asc = 'asc';
            if ((a.campaignName).toUpperCase() < (b.campaignName).toUpperCase()) {
                return asc ? -1 : 1;
            } else if ((a.campaignName).toUpperCase() > (b.campaignName).toUpperCase()) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
          },
           render: (text,record) => 
           <div class="dot2" style={{width:'70px'}}>
      <span style={{fontSize:'12px'}}> 
    {record.campaignName}</span>
      
     
           </div>
        
      }
      ,
      {
          title: 'Status',
          dataIndex: 'Status',
          key: 'Status',
          width:'28%',
           align:'center',  
           sorter: (a, b) => {
            const asc = 'asc';
            if ((a.status).toUpperCase() < (b.status).toUpperCase()) {
                return asc ? -1 : 1;
            } else if ((a.status).toUpperCase() > (b.status).toUpperCase()) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
          },
           render: (text,record) => 
           <div>
      <span style={{fontSize:'12px'}} class="word-wrap"> 
    {record.status}</span>
      
     
           </div>
        
      },
      {
        title:'Download Progress',
       width:'60%',
        children: [
          {
            title: 'Download Lead',
            dataIndex: 'downloadLead',
            key: 'downloadLead',
            align:'center',
            width:'18%',
            render(text, record) {
              return {
                  props: {
                  style: {wordWrap:"break-word",height:"5%"},
                },
              children: <div>{record.downloadedCount}</div>,
              };
            },
           // width: 200,ca
          },
          {
            title: 'New Lead',
            dataIndex: 'newLead',
            key: 'newLead',
            align:'center',
             width:'20%',
            render(text, record) {
              var pendingLeads=record.totalLeadCount-record.downloadedCount;
              return {
                  props: {
                  style: {wordWrap:"break-word",height:"5%"},
                },
                  children: <div>
                    {pendingLeads<0?0:pendingLeads}
                    </div>,
              };
            },
           // width: 200,
           
          }
        ]       
      }
      

     ]
    
     const columns3= [
      {
        title: 'Publisher/Advertiser Name',
        dataIndex: 'publisherName',
        key: 'publisherName',
        width: '30%',
        //  ...this.getColumnSearchProps('campID'),
       align:'center',
       sorter: (a, b) => a.name - b.name,
       render: (text,record) => 
       <div>
         {record.name}
         </div>
      }
      ,
      {
        title: 'User Type ',
        dataIndex: 'userType',
        key: 'userType',
        width: '30%',
        //  ...this.getColumnSearchProps('campID'),
       align:'center',
       sorter: (a, b) => a.userType - b.userType,
       render: (text,record) => 
       <div>
         {record.userType}
         </div>
      },
      {
        title: 'Review ',
        dataIndex: 'reviewDetails',
        key: 'reviewDetails',
        width: '30%',
        //  ...this.getColumnSearchProps('campID'),
       align:'center',
       sorter: (a, b) => a.reviewDetails - b.reviewDetails,
       render: (text,record) => 
       <div>
         <a href="#" id={record.id} userType={record.userType} onClick={this.getPublisherLink.bind(this)}>Click here</a>
         </div>
      }
     
      
    ]
      const columns = [
        {
            title: 'Campaign ID',
            dataIndex: 'campID',
            key: 'campID',
            width: '7.5%',
            //  ...this.getColumnSearchProps('campID'),
           align:'right',
           sorter: (a, b) => a.campID - b.campID,
           render: (text,record) => 
           <div style={{width:'75px'}}>
        <span style={{fontSize:"12px"}}>
        {/* {user.role==="AC"?
<a href="#" id={record.campID} style={{float:'left'}}>
<Tooltip placement="top" title="Edit">
<img src="NewEdit.png"  id={record.campID} parentCampId={record.parentCampID?record.parentCampID:record.campID}
             reallocationId={record.reallocationID}
             onClick={this.handleEditCampaignNew}  width="15" height="15"></img>
</Tooltip>
</a>
:
<span style={{float:'left'}}>
<Tooltip placement="top" title="Edit">
<img src="Edit_Disable.png"   width="15" height="15"></img>
</Tooltip>
</span>
}
&nbsp;&nbsp; */}
        {record.parentCampID?record.parentCampID:
        record.advCampID?record.advCampID:text
      }
      </span>           
        <br/>



           </div>
            
        },
        {
            title: 'Campaign Name',
            dataIndex: 'campaignName',
            key: 'campaignName',
              width: '10%',
            //  ...this.getColumnSearchProps('campaignName'),
             align:'center',  
            
             sorter: (a, b) => {
              const asc = 'asc';
              
              if ((a.campaignName).toUpperCase() < (b.campaignName).toUpperCase()) {
                  return asc ? -1 : 1;
              } else if ((a.campaignName).toUpperCase() > (b.campaignName).toUpperCase()) {
                  return asc ? 1 : -1;
              } else {
                  return 0;
              }
            },
             render: (text,record) => 
          
             <div class="dot1" style={{width:'90px'}}>
        <span style={{width:'100px'}}>
        {record.campaignName?
          <a href="#" onClick={this.displayCampaignSpecification} campID={record.campID}  
         campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID}
         
        
        style={{fontSize:"12px",width:'100px'}}>{record.campaignName}</a>
        :
        <span  style={{fontSize:"12px",width:'100px'}}>
           <a href="#" onClick={this.displayCampaignSetup} advCampID={record.advCampID}  agencyAllocationID={record.agencyAllocationID}
           advCampaignName={record.advCampaignName}   
           >
        {record.advCampaignName}
        </a></span>
        }
        
        
        </span>
         <br/>
         
         {/* <span style={{fontSize:"11px"}}>
                    {record.requiredLeadPerAsset==='Yes'?
                              <Progress strokeColor={{'0%': '#108ee9','100%': '#87d068',}} percent={Math.round(record.totAcceptedLead/(record.totAcceptedLead+(record.allocatedLead - record.totAcceptedLead))*100)} size="small" status="active"  />
                    :<Progress  strokeColor={{'0%': '#108ee9','100%': '#87d068',}}  percent={Math.round(((record.qaReviewLead+record.acceptedLead)/record.allocatedLead)*100)} size="small" status="active"  />}
                    </span>  */}
        </div>
          // <span style={{fontSize:"8px" }}> {record.startDate} To {record.endDate}</span> 
        },
     
        
        {
          title: 'Progress Status',
          width:'14.5%',
          children: [
            {
              title: <Tooltip placement="top" title="Allocation Status">Allocation</Tooltip>,
              dataIndex: 'allocationStatus',
              key: 'allocationStatus',
              align:'center',
              width:'6%',
              render(text, record) {
                return {
                    props: {
                    style: {wordWrap:"break-word",height:"5%"},
                  },
                    children: <div style={{width:'56px'}}>



<Progress type="circle"  strokeColor={{'0%': '#108ee9','100%': '#87d068',}} percent={Math.round((record.allocatedLead*100)/record.leadAllocation)} width={28} />

{/* {text=="No"?
<span style={{ height: "10px", width: "10px", backgroundColor:"green", borderRadius: "50%",display:"inline-block"}}></span>

:
<span style={{ height: "10px", width: "10px", backgroundColor:"red", borderRadius: "50%",display:"inline-block"}}></span>

                      } */}
                    </div>,
                };
              },
             // width: 200,
            },
            {
              title: <Tooltip placement="top" title="Creatives Status">Creatives Approval</Tooltip>,
              dataIndex: 'creativeApproval',
              key: 'creativeApproval',
              align:'center', 
               width:'6%',
              render(text, record) {
                return {
                    props: {
                    style: {wordWrap:"break-word",height:"5%"},
                  },
                    children: <div>
                {text=="No"?
 <Progress type="circle" percent={100} width={28} />

:
<Progress
      type="circle"
      strokeColor="Red"
      percent={100}
      width={28}
      format={() => '0%'}

    />


                      }

                    </div>,
                };
              },
            },
            {
                title: <Tooltip placement="top" title="Delivery status">Delivery</Tooltip>,
                dataIndex: 'deliveryStatus',
                key: 'deliveryStatus',
                align:'center',
                width:'5%',
                render(text, record) {
                    return {
                        props: {
                        style: {wordWrap:"break-word",height:"5%"},
                      },
                        children: <div>
        <span style={{fontSize:"11px"}}>
                    {record.requiredLeadPerAsset==='Yes'?
                              <Progress type="circle" strokeColor={{'0%': '#108ee9','100%': '#87d068',}}percent={Math.round(record.totAcceptedLead/(record.totAcceptedLead+(record.allocatedLead - record.totAcceptedLead))*100)} width={30} />
                    :<Progress  type="circle" strokeColor={{'0%': '#108ee9','100%': '#87d068',}}  percent={Math.round(((record.qaReviewLead+record.acceptedLead)/record.allocatedLead)*100)} width={30} />}
                    </span> 
                        </div>,
                    };
                  },
              },
          ],
        },
        {
            title: 'Allocation Status',
            align:'center',
            width:"15%",
            children: [
                {
                  title: 'Total',
                  dataIndex: 'leadAllocation',
                  key: 'leadAllocation',
                  align:'right',
                  width:'5%',
                  render(text, record) {
                    return {
                        props: {
                        style: {wordWrap:"break-word",height:"5%"},
                      },
                        children: <div>
                          {record.leadAllocation?record.leadAllocation:record.advAllocatedLead}</div>,
                    };
                  },
                 // width: 200,
                },
                {
                  title: 'Allocated',
                  dataIndex: 'allocatedLead',
                  key: 'allocatedLead',
                  align:'right',
                  width:'6%',
                  render:(text, record)=> 

                  <div style={{fontSize:"12px"}}>
                  {text>0?
                  <a href="#"   
                  CampId={record.campID}
                    parentCampId={record.parentCampID}
                    reallocationId={record.reallocationID}
                     onClick={this.handleChangeAllocatedLeads}
                     style={{fontSize:"12px"}}>
                     {text}
                     </a>
                  : <div>0</div>}
                  </div>
                
                  
               
                },
                {
                    title: 'Pending',
                    dataIndex: 'pendingLead',
                    key: 'pendingLead',
                    align:'right',
                    width:'6%',
                    render(text, record) {
                        return {
                            props: {
                            style: {wordWrap:"break-word",height:"5%"},
                          },
                            children: <div style={{fontSize:"12px"}}> {record.pendingLead?record.pendingLead:0}</div>,
                        };
                      },
                  },
              ],
          },
          {
            title: 'Delivery Status',
           width:"10%",
            children: [
                {
                  title: 'Delivered',
                  dataIndex: 'deliveredLead',
                  key: 'deliveredLead',
                  align:'right',
                  width:'7%',
                  render(text, record) {
                    return {
                        props: {
                        style: {wordWrap:"break-word",height:"5%"},
                      },
                        children: <div style={{fontSize:"12px"}}> {record.deliveredLead?record.deliveredLead:0}</div>,
                    };
                  },
                 // width: 200,
                },
                {
                  title: 'Review pending',
                  dataIndex: 'qaReviewLead',
                  key: 'qaReviewLead',
                  align:'right',
                  width:'6%',
                  render(text, record) {
                    return {
                        props: {
                        style: {wordWrap:"break-word",height:"5%"},
                      },
                        children: <div style={{fontSize:"12px"}}> {record.qaReviewLead?record.qaReviewLead:0}</div>,
                    };
                  },
                },
                {
                    title: 'Accepted',
                    dataIndex: 'acceptedLead',
                    key: 'acceptedLead',
                    align:'right',
                    width:'5.6%',
                    render(text, record) {
                        return {
                            props: {
                            style: {wordWrap:"break-word",height:"5%"},
                          },
                            children: <div style={{fontSize:"12px"}}> {record.acceptedLead?record.acceptedLead:0}</div>,
                        };
                      },
                  },
                  {
                    title: 'Rejected',
                    dataIndex: 'rejectedLead',
                    key: 'rejectedLead',
                    align:'right',
                    width:'6%',
                    render(text, record) {
                        return {
                            props: {
                            style: {wordWrap:"break-word",height:"5%"},
                          },
                            children: <div style={{fontSize:"12px"}}> {record.rejectedLead?record.rejectedLead:0}</div>,
                        };
                      },
                  },
                  {
                    title: 'Balanced',
                    dataIndex: 'balancedLead',
                    key: 'balancedLead',
                    align:'right',
                    width:'6%',
                    render(text, record) {
                        return {
                            props: {
                            style: {wordWrap:"break-word",height:"5%",width:'6%'},
                          },
                            children: <div style={{fontSize:"12px"}}> {record.balancedLead?record.balancedLead:0}</div>,
                        };
                      },
                  },
              ],
          },
          {
            title: 'Action',
            dataIndex: '',
            key: '',
            width:'18%',
            align:'center',
            render: (text,record) => 
              <div >

                {record.campaignStatus == "Cancel Campaign" ?
                  <span>
                    <span style={{ float: 'left' }}>
                      <Tooltip placement="top" title="Allocate">
                        <img src="Allocate_Disable.jpg "
                          width="15" height="15"></img>
                      </Tooltip>
                    </span>
                    <Divider type="vertical" style={{ float: 'left' }} />
                    <span style={{ float: 'left' }}>
                      <Tooltip placement="top" title="Edit">
                        <img src="Edit_Disable.png" width="15" height="15"></img>
                      </Tooltip>
                    </span>
                    <Divider type="vertical" style={{ float: 'left' }} />
                    <span style={{ float: 'left' }}>
                      <Tooltip placement="top" title="Edit">
                        <img src="Increment_Disable.jpg" width="15" height="15"></img>
                      </Tooltip>
                    </span>
                    <Divider type="vertical" style={{ float: 'left' }} />
                    <span style={{ float: 'left' }}>
                      <Tooltip placement="top" title="Pause">
                        <img src="Paused_Disable.jpg" id={record.campID} width="15" height="15"></img>
                      </Tooltip>
                    </span>
                    <Divider type="vertical" style={{ float: 'left' }} />
                    <span style={{ float: 'left' }}>
                      <Tooltip placement="topRight" title="Campaign can not be marked completed as leads are pending" overlayStyle={{ color: 'white', backgroundColor: 'orange' }}>
                        <img src="Complete_Disable.jpg" id={record.campID} width="15" height="15"></img>
                      </Tooltip>
                    </span>
                    <Divider type="vertical" style={{ float: 'left' }} />
                    <span style={{ float: 'left' }}>
                      <Tooltip placement="top" title="Clone">
                        <img src="clone_disable.png "
                          width="15" height="15"></img>
                      </Tooltip>
                    </span>
                    <Divider type="vertical" style={{ float: 'left' }} />
                    <span style={{ float: 'left' }}>
                      <Tooltip placement="top" title="Cancel">
                        <img src="Reject_Disabled.png "
                          width="15" height="15"></img>
                      </Tooltip>
                    </span>
                  </span>
                  :
                  record.campaignStatus == "Active" ?
                    <span>
                      {user.role === "AC" ?
                        <a href="#" id={record.campID}
                          style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Allocate">
                           <img src="allocate.jpg" id={record.campID} rfpProposalID={record.rfpProposalID} onClick={this.allocatePublisher}
                              parentCampID={record.parentCampID} reallocationID={record.reallocationID} width="15" height="15"></img>
                          </Tooltip>
                        </a>
                        :
                        <span style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Allocate">
                            <img src="Allocate_Disable.jpg "
                              width="15" height="15"></img>
                          </Tooltip>
                        </span>
                      }

                      <Divider type="vertical" style={{ float: 'left' }} />
                      {user.role === "AC"?
                        <a href="#" id={record.campID} style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Edit">
                            <img src="Edit.png" id={record.campID} parentCampId={record.parentCampID ? record.parentCampID : record.campID}
                              reallocationId={record.reallocationID}  rfpCampaignID={record.rfpProposalID}
                              onClick={this.handleEditCampaign} width="15" height="15"></img>
                          </Tooltip>
                        </a>
                        :
                        <span style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Edit">
                            <img src="Edit_Disable.png" width="15" height="15"></img>
                          </Tooltip>
                        </span>
                      }
                      {user.role==='AQA'?''
                      :<Divider type="vertical" style={{ float: 'left' }} />}
                      {user.role === "AC" || user.role === "ANC" ?
                        <a href="#" id={record.campID}
                          data-toggle="modal" data-target="#incrementalModal1"
                          style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Lead Increment">
                            <img src="incremental.jpg" id={record.campID} campID={record.campID} pID={record.pID}
                              campaignName={record.campaignName} parentCampID={record.parentCampID} reallocationID={record.reallocationID} onClick={this.handleAllocatedLeads} width="15" height="15"></img>
                          </Tooltip>
                        </a> : ""}


                      <Divider type="vertical" style={{ float: 'left' }} />
                      {user.role === "AC" ?
                        <a href="#" id={record.campID} onClick={this.pauseCampaign} style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Pause">
                            <img src="paused.jpg" id={record.campID} width="15" height="15"></img>
                          </Tooltip>
                        </a> :
                        <span style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Pause">
                            <img src="Paused_Disable.jpg" id={record.campID} width="15" height="15"></img>
                          </Tooltip>
                        </span>
                      }

                      <Divider type="vertical" style={{ float: 'left' }} />

                      {user.role === "AC" ?
                        record.requiredLeadPerAsset === 'Yes' ?

                          record.completeStatus === true && record.leadAllocation == record.allocatedLead ?

                            <a href="#" id={record.campID} onClick={this.handleChangeCompleteCampaign} style={{ float: 'left' }}>
                              <Tooltip placement="top" title="Complete">
                                <img src="completed.jpg" id={record.campID} width="15" height="15"></img>
                              </Tooltip>
                            </a>
                            :
                            <span style={{ float: 'left' }}>
                              <Tooltip placement="topRight" title="Campaign can not be marked completed as leads are pending" overlayStyle={{ color: 'white', backgroundColor: 'orange' }}>
                                <img src="Complete_Disable.jpg" id={record.campID} width="15" height="15"></img>
                              </Tooltip>

                            </span>

                          :
                          (record.leadAllocation == record.allocatedLead) && (record.acceptedLead >= record.leadAllocation) ?

                            <a href="#" id={record.campID} onClick={this.handleChangeCompleteCampaign} style={{ float: 'left' }}>
                              <Tooltip placement="top" title="Complete">
                                <img src="completed.jpg" id={record.campID} width="15" height="15"></img>
                              </Tooltip>
                            </a>
                            :
                            <span style={{ float: 'left' }}>
                              <Tooltip placement="topRight" title="Campaign can not be marked completed as leads are pending" overlayStyle={{ color: 'white', backgroundColor: 'orange' }}>
                                <img src="Complete_Disable.jpg" id={record.campID} width="15" height="15"></img>
                              </Tooltip>

                            </span>

                        :
                        <span style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Complete">
                            <img src="Complete_Disable.jpg" id={record.campID} width="15" height="15"></img>
                          </Tooltip>
                        </span>
                      }
                      <Divider type="vertical" style={{ float: 'left' }} />
                      {user.role === "AC"?
                        <a href="#" id={record.campID}
                          style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Clone">
                            <img src="clone.png" id={record.campID} onClick={this.cloneCampaign}
                              parentCampID={record.parentCampID} reallocationID={record.reallocationID} width="15" height="15"></img>
                          </Tooltip>
                        </a>
                        :
                        <span style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Clone">
                            <img src="clone_disable.png "
                              width="15" height="15"></img>
                          </Tooltip>
                        </span>
                      }
                      <Divider type="vertical" style={{ float: 'left' }} />
                      {user.role === "AC" ?
                        <a href="#" id={record.campID}
                          style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Cancel">
                            <img src="Reject.png" id={record.campID} onClick={this.cancelCampaign}
                              parentCampID={record.parentCampID} reallocationID={record.reallocationID} width="15" height="15"></img>
                          </Tooltip>
                        </a>
                        :
                        <span style={{ float: 'left' }}>
                          <Tooltip placement="top" title="Cancel">
                            <img src="Reject_Disabled.png "
                              width="15" height="15"></img>
                          </Tooltip>
                        </span>
                      }
                    </span>
                    :
                    record.campaignStatus == "Paused" ?
                      <span>
                        {user.role === "AC" || user.role === "ANC" || user.role!== "AQA"?
                          <span style={{ float: 'left' }}>
                            <Tooltip placement="top" title="Allocate" >
                              <img src="Allocate_Disable.jpg" id={record.campID} width="15" height="15"></img>
                            </Tooltip></span>
                          : ""}

                        <Divider type="vertical" style={{ float: 'left' }} />
                        {user.role === "AC" || user.role === "ANC" || user.role!== "AQA"?
                          <span style={{ float: 'left' }}>
                            <Tooltip placement="top" title="Edit">
                              <img src="Edit_Disable.png" id={record.campID} width="15" height="15"></img>
                            </Tooltip>
                          </span> : ""
                        }

                        <Divider type="vertical" style={{ float: 'left' }} />
                        {user.role === "AC" || user.role === "ANC" || user.role!== "AQA"?
                          <span style={{ float: 'left' }}>
                            <Tooltip placement="top" title="Lead Increment">
                              <img src="Increment_Disable.jpg" id={record.campID} width="15" height="15"></img>
                            </Tooltip>
                          </span> : ""
                        }

                        <Divider type="vertical" style={{ float: 'left' }} />
                        {user.role === "AC" ?
                          <a href="#" id={record.campID} onClick={this.resumeCampaign} style={{ float: 'left' }}>
                            <Tooltip placement="top" title="Resume">
                              <img src="Continue.png" id={record.campID} width="15" height="15"></img>
                            </Tooltip>
                          </a>
                          :
                          <span style={{ float: 'left' }}>
                            <Tooltip placement="top" title="Resume">
                              <img src="Continue_Disable.png" id={record.campID} width="15" height="15"></img>
                            </Tooltip>
                          </span>
                        }

                        <Divider type="vertical" style={{ float: 'left' }} />
                        {user.role === "AC" || user.role === "ANC" || user.role!== "AQA"?
                          <span style={{ float: 'left' }}>
                            <Tooltip placement="top" title="Complete">
                              <img src="Complete_Disable.jpg" id={record.campID} width="15" height="15"></img>
                            </Tooltip>
                          </span> : ""}
                      </span>
                      :
                      record.campaignStatus == "Completed" ?
                        <span>
                          {user.role === "AC" || user.role === "ANC" || user.role!== "AQA"?
                            <a href="#" id={record.campID} data-toggle="modal" data-target="#editEndDate1" style={{ float: 'left' }}>
                              <Tooltip placement="top" title="Edit End Date">
                                <img src="EditEndDate.png" id={record.campID}

                                  onClick={this.handleEndDate} width="15" height="15"></img>
                              </Tooltip>
                            </a>

                            : ""}
                          <Divider type="vertical" style={{ float: 'left' }} />
                          {user.role === "AC" ?
                            <a href="#" id={record.campID} data-toggle="modal"
                              // data-target="#confirmClone" 
                              onClick={this.getDetails} parentCampId={record.parentCampID} reallocationId={record.reallocationID} style={{ float: 'left' }}>
                              <Tooltip placement="top" title="Clone">
                                <img src="clone.png" id={record.campID} parentCampId={record.parentCampID} reallocationId={record.reallocationID}
                                  width="15" height="15"></img>
                              </Tooltip>
                            </a>
                            :
                            <span style={{ float: 'left' }}>
                              <Tooltip placement="top" title="Clone">
                                <img src="clone_disable.png "
                                  width="15" height="15"></img>
                              </Tooltip>
                            </span>
                          }

                          <Divider type="vertical" style={{ float: 'left' }} />
                          {user.role === "AC" ?
                            <span>
                              {record.endDate >= this.state.enddate ?
                                <a href="#" id={record.campID} onClick={this.liveCampaign.bind(this)} style={{ float: 'left' }}>
                                  <Tooltip placement="top" title="Live">

                                    <img src="Continue.png" id={record.campID} width="15" height="15"></img>
                                  </Tooltip>
                                </a> :
                                <span style={{ float: 'left' }}>
                                  <Tooltip placement="top" overlayStyle={{ color: 'white', backgroundColor: 'orange' }} title="Edit end date to make campaign live.">
                                    <img src="Continue_Disable.png" id={record.campID} width="15" height="15"></img>
                                  </Tooltip>
                                </span>
                              }
                            </span>
                            : ""

                          }

                          <Divider type="vertical" style={{ float: 'left' }} />
                          {user.role === "AC" ? record.reAllocationExist === "false" ? <a href="#" id={record.campID} data-toggle="modal" data-target="#reallocate" style={{ float: 'left' }}> <Tooltip placement="top" title="Reallocate"> <img src="Reallocation1.png" width="15" height="15" id={record.campID} parentCampID={record.parentCampID ? record.parentCampID : record.campID} reallocationID={record.reallocationID} onClick={this.reAllocate}></img> </Tooltip> </a> : <span style={{ float: 'left' }}> <Tooltip placement="top" title="Reallocate"> <img src="Reallocation1_Disable.png" width="15" height="15" ></img> </Tooltip> </span> : <span style={{ float: 'left' }}> <Tooltip placement="top" title="Reallocate"> <img src="Reallocation_Disable.png" width="15" height="15" ></img> </Tooltip> </span>}

                        </span>
                        :
                        record.campaignStatus == "Live_Incomplete" ?
                          <span>
                            {user.role === "AC" || user.role === "ANC" || user.role!== "AQA"?
                              <a href="#" id={record.campID} data-toggle="modal" data-target="#editEndDate1" style={{ float: 'left' }}>
                                <Tooltip placement="top" title="Edit End Date">
                                  <img src="EditEndDate.png" id={record.campID}

                                    onClick={this.handleEndDate} width="15" height="15"></img>
                                </Tooltip>
                              </a>

                              : ""}
                            <Divider type="vertical" style={{ float: 'left' }} />

                            {user.role === "AC" ?
                              <span>

                                {record.endDate >= this.state.enddate ?
                                  <a href="#" id={record.campID} onClick={this.liveCampaign.bind(this)} style={{ float: 'left' }}>
                                    <Tooltip placement="top" title="Live">
                                      <img src="Continue.png" id={record.campID} width="15" height="15"></img>
                                    </Tooltip>
                                  </a> :
                                  <span style={{ float: 'left' }}>
                                    <Tooltip placement="top" overlayStyle={{ color: 'white', backgroundColor: 'orange' }} title="Edit end date to make campaign live.">
                                      <img src="Continue_Disable.png" id={record.campID} width="15" height="15"></img>
                                    </Tooltip>
                                  </span>
                                }
                              </span>
                              :
                              <span style={{ float: 'left' }}>
                                <Tooltip placement="top" overlayStyle={{ color: 'white', backgroundColor: 'orange' }} title="Edit end date to make campaign live.">
                                  <img src="Continue_Disable.png" id={record.campID} width="15" height="15"></img>
                                </Tooltip>
                              </span>
                            }
                            <Divider type="vertical" style={{ float: 'left' }} />
                            {user.role === "AC" ?
                              <a href="#" id={record.campID} data-toggle="modal"
                                // data-target="#confirmClone" 
                                onClick={this.getDetails} parentCampId={record.parentCampID} reallocationId={record.reallocationID} style={{ float: 'left' }}>
                                <Tooltip placement="top" title="Clone">
                                  <img src="clone.png" id={record.campID}
                                    parentCampID={record.parentCampID} reallocationID={record.reallocationID} width="15" height="15"></img>
                                </Tooltip>
                              </a>
                              :
                              <span style={{ float: 'left' }}>
                                <Tooltip placement="top" title="Clone">
                                  <img src="clone_disable.png "
                                    width="15" height="15"></img>
                                </Tooltip>
                              </span>
                            }
                            <Divider type="vertical" style={{ float: 'left' }} />

                            {user.role === "AC" ?
                              record.reAllocationExist === "false" ? <a href="#" id={record.campID} data-toggle="modal"
                                data-target="#reallocate" style={{ float: 'left' }}>
                                <Tooltip placement="top" title="Reallocate">
                                  <img src="Reallocation1.png" width="15" height="15" id={record.campID}
                                    parentCampID={record.parentCampID ? record.parentCampID : record.campID}
                                    reallocationID={record.reallocationID} onClick={this.reAllocate}></img>
                                </Tooltip>
                              </a> :
                                <span style={{ float: 'left' }}>
                                  <Tooltip placement="top" title="Reallocate">
                                    <img src="Reallocation1_Disable.png" width="15" height="15" ></img>
                                  </Tooltip>
                                </span>
                              :
                              <span style={{ float: 'left' }}>
                                <Tooltip placement="top" title="Reallocate">
                                  <img src="Reallocation_Disable.png" width="15" height="15" ></img>
                                </Tooltip>
                              </span>
                            }
                          </span>
                          :
                          record.advStatus == "PendingSetup" ?
                            <span>
                              <a href="#" id={record.campID} advCampID={record.advCampID} onClick={this.campaignSetup.bind(this)} style={{ float: 'left' }}>
                                <Tooltip placement="top" title="Campaign Setup">
                                  <img src="camp_setup.png" advCampID={record.advCampID} id={record.agencyAllocationID} width="20" height="20"></img>
                                </Tooltip>
                              </a>
                            </span>
                            :
                            record.campaignStatus == "PausedIncomplete" ?
                              <span>
                                {user.role === "AC" || user.role === "ANC" || user.role!== "AQA"?
                                  <a href="#" id={record.campID} data-toggle="modal" data-target="#editEndDate1" style={{ float: 'left' }}>
                                    <Tooltip placement="top" title="Edit End Date">
                                      <img src="EditEndDate.png" id={record.campID}

                                        onClick={this.handleEndDate} width="15" height="15"></img>
                                    </Tooltip>
                                  </a>

                                  : ""}
                                <Divider type="vertical" style={{ float: 'left' }} />

                                {user.role === "AC" ?
                                  <span>

                                    {record.endDate >= this.state.enddate ?
                                      <a href="#" id={record.campID} onClick={this.liveCampaign.bind(this)} style={{ float: 'left' }}>
                                        <Tooltip placement="top" title="Live">
                                          <img src="Continue.png" id={record.campID} width="15" height="15"></img>
                                        </Tooltip>
                                      </a> :
                                      <span style={{ float: 'left' }}>
                                        <Tooltip placement="top" title="Edit end date to make campaign live." overlayStyle={{ color: 'white', backgroundColor: 'orange' }} >
                                          <img src="Continue_Disable.png" id={record.campID} width="15" height="15"></img>
                                        </Tooltip>
                                      </span>
                                    }
                                  </span>
                                  : ""
                                }
                                <Divider type="vertical" style={{ float: 'left' }} />
                                {user.role === "AC" ?
                                  <a href="#" id={record.campID} data-toggle="modal"
                                    data-target="#confirmClone" onClick={this.getDetails} parentCampId={record.parentCampID} reallocationId={record.reallocationID} style={{ float: 'left' }}>
                                    <Tooltip placement="top" title="Clone">
                                      <img src="clone.png" id={record.campID}
                                        parentCampID={record.parentCampID} reallocationID={record.reallocationID} width="15" height="15"></img>
                                    </Tooltip>
                                  </a>
                                  :
                                  <span style={{ float: 'left' }}>
                                    <Tooltip placement="top" title="Clone">
                                      <img src="clone_disable.png "
                                        width="15" height="15"></img>
                                    </Tooltip>
                                  </span>
                                }

                                <Divider type="vertical" style={{ float: 'left' }} />
                                {user.role === "AC" ? record.reAllocationExist === "false" ? <a href="#" id={record.campID} data-toggle="modal" data-target="#reallocate" style={{ float: 'left' }}> <Tooltip placement="top" title="Reallocate"> <img src="Reallocation1.png" width="15" height="15" id={record.campID} parentCampID={record.parentCampID ? record.parentCampID : record.campID} reallocationID={record.reallocationID} onClick={this.reAllocate}></img> </Tooltip> </a> : <span style={{ float: 'left' }}> <Tooltip placement="top" title="Reallocate"> <img src="Reallocation1_Disable.png" width="15" height="15" ></img> </Tooltip> </span> : <span style={{ float: 'left' }}> <Tooltip placement="top" title="Reallocate"> <img src="Reallocation_Disable.png" width="15" height="15" ></img> </Tooltip> </span>}
                              </span>
                              : ""
                }
                {/* <!-- Modal --> */}
                <div class="modal fade" id="editEndDate1" role="dialog" data-backdrop="static"
                  data-keyboard="false">
                  <div class="modal-dialog cust-class">
                    <div class="modal-content">
                      <div class="modal-header custom-class">
                        <h4 class="modal-title" style={{ color: '#144c9b', paddingRight: '177px' }}><font color='white'>Edit End Date&nbsp;(Campaign ID:{this.state.campID})</font></h4>
                        <button type="button" class="cancel-button" style={{ fontSize: 15 }} data-dismiss="modal" onClick={this.handleRefreshPage}>&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="successMessage">{this.state.successMsg}</div>
                        <div style={{ border: this.state.alertBorder, display: this.state.alertDisplay }}>
                          <div class="errorMessage">{this.state.errors.commonError}</div>
                        </div>
                        <p>
                          {this.state.endDateCampaign.map(
                            endDateCampaign => (
                              <div>
                                <div class="row">
                                  <div class="col-6">
                                    <label id="label" style={{ width: '110px' }}><font color='black'>Start Date</font></label><br />
                                    {endDateCampaign.startDate}
                                    {/* <input type="date" id="startDate" name="startDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                          defaultValue={onClickAllocatedLeadPublisher.startDate}
                          value={this.state.startDate}
                          className="form-control"
                           /> */}
                                  </div>
                                  <div class="col-4">
                                    <label id="label" style={{ width: '110px' }}><font color='black'>End Date</font></label>
                                    <input type="date" id="endDate" name="endDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                      defaultValue={endDateCampaign.endDate}
                                      onChange={this.handleChangeEndDate}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}</p>
                      </div>
                      {/* end of body */}
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-default "
                          className={this.state.inputClass}
                          onClick={this.handleChangeEndDateCampaign}
                          style={{ backgroundColor: '#144c9b', color: "white", borderRadius: "3px" }}
                        >
                          Edit&nbsp;<i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                        {/* <button
                                type="button"
                                class="btn btn-default cancel-button"
                                data-dismiss="modal"
                                onClick={this.handleRefreshPage}
                              >
                                Close
                              </button> */}
                      </div>
                    </div>
                    {/* end of content */}
                  </div>
                </div>
                {/* end od endDate modal */}
                {/* <i href="#" 
             parentCampId={record.parentCampID?record.parentCampID:record.campID}
             reallocationId={record.reallocationID}
             campID={record.campID}
             id={record.campID}
             onClick={this.handleEditCampaign}
             class="fa fa-edit"
             style={{color:'#2196f3'}}
            >
            </i> */}
                {/* <Divider type="vertical" /> */}

                {/* {record.campaignStatus=="Active"?
            <i href="#" class="fa fa-plus-circle" style={{color:'#2196f3'}} title="Increment"data-toggle="modal"  data-target="#incrementalModal1"   campID={record.campID}   pID={record.pID}
         campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID} onClick={this.handleAllocatedLeads}>
           
            </i>:
            <a href="#" className="disabledCursor">
              <i href="#" class="fa fa-plus-circle"  style={{color:'grey'}} title="Increment"data-toggle="modal"  data-target="#incrementalModal1"   campID={record.campID}   pID={record.pID}
              campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID} onClick={this.handleAllocatedLeads}>
                </i></a>
            } */}
                {/* start of increment modal */}
                <div class="modal fade" id="incrementalModal1" role="dialog" data-backdrop="static"
                  data-keyboard="false">
                  <div class="modal-dialog  cust-class">
                    <div class="modal-content">
                      <div class="modal-header custom-class">
                        <h4 class="modal-title" style={{ color: '#144999', paddingRight: '12px' }}><font color='white' style={{ paddingRight: '25px' }}>Please Enter Incremental Leads&nbsp;(Campaign ID:{this.state.parentCampID})</font></h4>
                        <button type="button" class="cancel-button" style={{ fontSize: 15 }} data-dismiss="modal">&times;</button>
                      </div>
                      <div class="modal-body">
                        <p>
                          <div class="successMessage">{this.state.successMsg}</div>
                          <div class="">
                            <div style={{ border: this.state.alertBorder, display: this.state.alertDisplay }}>
                              <div class="errorMessage">

                                <li>{this.state.errors.commonError}</li>
                                {this.state.errors.Currentdate}
                                {this.state.errors.EnddateValidate}
                                {this.state.errors.leadAllocation}
                                {this.state.errors.cpl}
                                {this.state.errors.budget}
                              </div>
                            </div>
                          </div>
                          <br />
                          {this.state.incrementCampDetails.map(
                            incrementCampDetails => (
                              <div class="row">
                                <div class="col-6">

                                  <label id="label" style={{ paddingRight: '100px', color: '#144c9b' }}>Increamental Leads:<span style={{ color: 'red' }}>*</span></label>
                                  <input style={{ width: '150px', fontWeight: '500' }} type="text" className="form-control"
                                    name="incrementalLead" onChange={this.handleIncrementLeads} required />
                                </div>
                                <div class="col-6">
                                  <label id="label" style={{ paddingRight: '160px', color: '#144c9b' }}>End Date: </label>
                                  <input type="date" id="endDate" name="campEndDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                    defaultValue={incrementCampDetails.campEndDate}
                                    onChange={this.handleChangeDate}
                                    className="form-control" />
                                </div>
                              </div>
                            ))}
                        </p>
                      </div>
                      <div class="modal-footer">
                        <center>
                          <button
                            type="button"
                            class="btn btn-default "
                            className={this.state.inputClass}
                            onClick={this.handleIncCampaignLeads}
                            style={{ backgroundColor: '#144999', color: "white", borderRadius: "3px" }}>

                            Increment
                    {/* <span><i class="fa fa-plus-circle" aria-hidden="true"></i></span> */}
                          </button>
                          {/* <button
                  type="button"
                  class="btn btn-default cancel-button"
                  data-dismiss="modal"
                  onClick={this.handleRefreshPage}
                  >Cancel</button> */}
                        </center>

                      </div>

                    </div>
                  </div>
                </div>
                {/* end of increment modal */}

                {/* <!-- Modal --> */}
                <div class="modal fade" id="confirmClone" role="dialog" data-backdrop="static"
                  data-keyboard="false">
                  <div class="modal-dialog modal-sm cust-class" style={{ top: '58%', left: '30%' }}>

                    {/* <!-- Modal content--> */}
                    <div class="modal-content">
                          <div class="modal-header custom-class">
                        <h4 class="modal-title" style={{ color: '#144c9b', paddingRight: '190px' }}><font color='white' >Confirm</font></h4>

                        <button type="button" class="cancel-button" style={{ fontSize: 15 }} data-dismiss="modal" id='Completed' onClick={this.handleRefreshPage}>
                          &times;
                    </button>
                      </div>
                      <br />
                      {/* <center>
                  <div style={{fontSize:'16px',color:'green',fontWeight:'600'}} id='successmsg'>
                                  </div></center> */}
                      <br />

                      <div class="modal-body">
                        <label>Are you sure you want to create copy of this campaign?</label>
                        <br />
                        <button type="button" class="btn btn-primary" data-toggle="modal"
                          data-target="#cloneDetails">Yes</button>
         &nbsp;&nbsp;
         <button type="button" class="btn btn-primary" data-dismiss="modal" id='Completed' onClick={this.handleRefreshPage}>
                          No
                    </button>
                      </div>
                      {/* <div class="modal-footer"> */}
                      {/* <button type="submit" class="btn btn-primary" className={this.state.inputClass} 
        style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
                                        >Re-allocate
                                        {/* <i class="fa fa-retweet" aria-hidden="true"></i> */}
                      {/* </button> */}
                      {/* <button type="button" class="btn btn-default cancel-button" data-dismiss="modal" id='Completed' onClick={this.handleRefreshPage}>Close</button> */}
                      {/* </div> */}
                      {/* </form> */}
                    </div>
                  </div>
                </div>
                {/* <!-- Modal --> */}
                {/*          
         <div class="modal fade" id="cloneDetails" role="dialog" data-backdrop="static"
                        data-keyboard="false">
    <div class="modal-dialog modal-lg cust-class" style={{ top:'58%',left:'15%'}}>
    
      {/* <!-- Modal content--> 
      <div class="modal-content">
             <form method="POST" name="acceptPub"
                onSubmit={this.cloneCompletedCampaign}
                className="needs-validation" noValidate>
                            
          <div class="modal-header custom-class">
          <h4 class="modal-title" style={{color:'#144c9b',paddingRight:'410px'}}><font color='white' >Clone Detail&nbsp;(Campaign ID:{this.state.parentCampID})</font></h4>

                    <button type="button" class="cancel-button" style={{fontSize:15}} data-dismiss="modal" id='Completed' onClick={this.handleRefreshPage}>
                      &times;
                    </button>
                  </div>
                  <br/>

          {/* validation error messages 
          <div class="container-fluid">
                  <div  class="errorMessage"  style={{border:this.state.alertBorder}}>
                        {this.state.errors.endDate}
                        {this.state.errors.startDate}
                        {this.state.errors.commonError}
                  </div>
                  </div>
                  {/* <center>
                  <div style={{fontSize:'16px',color:'green',fontWeight:'600'}} id='successmsg'>
                                  </div></center>
                  <br/>

        <div class="modal-body">
           <div class="row">
              <div class="col-sm-4">
                <label id="label">Start Date<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                <input type="date"  class="form-control" id="cloneStartDate" name="cloneStartDate" value={this.state.cloneStartDate}
                min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}" onChange={this.handleChangeIncremental}></input>
              </div>
              <div class="col-sm-4">
              <label id="label">End Date<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
              <input type="date" class="form-control" id="cloneEndDate" name="cloneEndDate" value={this.state.cloneEndDate} 
              min=""  required onChange={this.handleChangeIncremental}></input>
              </div>
              <div class="col-sm-4">
              <label id="label">First Lead Delivery Date<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}></span></label>
              <input type="date" class="form-control" id="cloneFirstLeadDate" name="cloneFirstLeadDate" value={this.state.cloneFirstLeadDate} 
              min=""  required onChange={this.handleChangeIncremental}></input>
              </div>
            </div>
        </div>
        <div class="modal-footer">
        <button type="submit" class="btn btn-primary" className={this.state.inputClass} 
        style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
                                        >Clone
                                         <i class="fa fa-retweet" aria-hidden="true"></i> *
                                        </button>
          {/* <button type="button" class="btn btn-default cancel-button" data-dismiss="modal" id='Completed' onClick={this.handleRefreshPage}>Close</button> 
        </div>
        </form>
      </div>
      
    </div>
  </div> */}
                <Modal show={this.state.modalShow} size="lg" onHide={this.modalHandleClose} aria-labelledby="example-modal-sizes-title-lg contained-modal-title-vcenter" centered>

                  <Modal.Header style={{ backgroundImage: " linear-gradient(to right,#144999,#144999)", color: "white" }} closeButton>
                    <Modal.Title style={{ color: "white" }} id="example-modal-sizes-title-lg contained-modal-title-vcenter">Clone Detail&nbsp;(Campaign ID:{this.state.parentCampID})

        </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form method="POST" name="acceptPub"

                      className="needs-validation" noValidate>

                      {/* validation error messages */}
                      <div class="container-fluid">
                        <div class="errorMessage" style={{ border: this.state.alertBorder }}>
                          {this.state.errors.endDate}
                          {this.state.errors.startDate}
                          {this.state.errors.firstLeadDate}

                          {this.state.errors.startEndDate}
                          {this.state.errors.startDateUpdated}
                          {this.state.errors.startDateLead}


                          {this.state.errors.EndStartDate}
                          {this.state.errors.endDateCurrent}
                          {this.state.errors.endDateLead}


                          {/* {this.state.errors.leadStartDate}
                        {this.state.errors.leadEndDate} */}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <label id="label">Start Date<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                          <input type="date" class="form-control" id="cloneStartDate" name="cloneStartDate" value={this.state.cloneStartDate}
                            min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}" onChange={this.handleChangeIncremental}></input>
                        </div>
                        <div class="col-sm-6">
                          <label id="label">End Date<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                          <input type="date" class="form-control" id="cloneEndDate" name="cloneEndDate" value={this.state.cloneEndDate}
                            min="" required onChange={this.handleChangeIncremental}></input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <label id="label">First Lead Delivery Date<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}></span></label>
                          <input type="date" class="form-control" id="cloneFirstLeadDate" name="cloneFirstLeadDate" value={this.state.cloneFirstLeadDate}
                            min="" required onChange={this.handleChangeIncremental}></input>
                        </div>
                      </div>

                    </form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={this.cloneCompletedCampaign}>
                      Clone Campaign
          </Button>
                    {/* <Button variant="secondary" onClick={this.modalHandleClose}>
            Close
          </Button> */}

                  </Modal.Footer>
                </Modal>
                {/* <!-- Modal --> */}
                <div class="modal fade" id="reallocate" role="dialog" data-backdrop="static"
                  data-keyboard="false">
                  <div class="modal-dialog modal-lg cust-class" style={{ top: '58%', left: '15%' }}>

                    {/* <!-- Modal content--> */}
                    <div class="modal-content">
                      <form method="POST" name="acceptPub"
                        onSubmit={this.reAllocateCampaign}
                        className="needs-validation" noValidate>

                        <div class="modal-header custom-class">
                          <h4 class="modal-title" style={{ color: '#144c9b', paddingRight: '410px' }}><font color='white' >Re-Allocation Detail&nbsp;(Campaign ID:{this.state.parentCampID})</font></h4>

                          <button type="button" class="cancel-button" style={{ fontSize: 15 }} data-dismiss="modal" id='Completed' onClick={this.handleRefreshPage}>
                            &times;
                    </button>
                        </div>
                        <br />

                        {/* validation error messages */}
                        <div class="container-fluid">
                          <div class="errorMessage" style={{ border: this.state.alertBorder }}>
                            {this.state.errors.commonError}
                            {this.state.errors.commonCpl}
                            {this.state.errors.endDate}
                            {this.state.errors.startDate}
                            {this.state.errors.leadsvalidate}
                            {this.state.errors.cplBudget1}
                            {this.state.errors.advCPL}
                            {this.state.errors.cpl}
                            {this.state.errors.lead}
                            {this.state.errors.budget}
                            {this.state.errors.advBudget}
                            {this.state.errors.firstdeliverydateEnddate}
                            {this.state.errors.firstdeliverydateStartdate}
                            {this.state.errors.leadAllocation}

                            {this.state.errors.firstdeliverydateCurrentDate}
                          </div>
                        </div>
                        {/* <center>
                  <div style={{fontSize:'16px',color:'green',fontWeight:'600'}} id='successmsg'>
                                  </div></center> */}
                        <br />

                        <div class="modal-body">
                          <div class="row">
                            <div class="col-sm-4">
                              <label id="label">Start Date<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                              <input type="date" class="form-control" id="reStartDate" name="reStartDate" value={this.state.reStartDate}
                                min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}" onChange={this.handleChangeIncremental}></input>
                            </div>
                            <div class="col-sm-4">
                              <label id="label">End Date<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                              <input type="date" class="form-control" id="reEndDate" name="reEndDate" value={this.state.reEndDate}
                                min="" required onChange={this.handleChangeIncremental}></input>
                            </div>
                            <div class="col-sm-4">
                              <label id="label">First Lead Delivery Date<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}></span></label>
                              <input type="date" class="form-control" id="reFirstLeadDate" name="reFirstLeadDate" value={this.state.reFirstLeadDate}
                                min="" required onChange={this.handleChangeIncremental}></input>
                            </div>
                          </div>
                          <br />
                          <div class="row">
                            <div class="col-sm-4">
                              <label id="label">Total Leads<span style={asterisk}>*</span></label>
                              <input type="textbox" class="form-control" id="reTotalLeads" name="reTotalLeads"
                                value={this.state.reTotalLeads} onChange={this.leadAllocationHandleChange} required></input>
                            </div>
                            <div class="col-sm-4">
                              <label id="label">Budget<span style={asterisk}>*</span></label>
                              <input type="textbox" class="form-control" id="reBudget" name="reBudget" value={this.state.reBudget} required
                                onChange={this.budgetHandleChange}></input>
                            </div>
                            <div class="col-sm-4">
                              <label id="label">CPL<span style={asterisk}>*</span></label>
                              <input type="textbox" class="form-control" id="reCpl" name="reCpl" value={this.state.reCpl}
                                required onChange={this.cplHandleChange}></input>
                            </div>

                          </div>

                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary" className={this.state.inputClass}
                            style={{ backgroundColor: '#144999', color: "white", borderRadius: "3px" }}
                          >Re-allocate
                                        {/* <i class="fa fa-retweet" aria-hidden="true"></i> */}
                          </button>
                          {/* <button type="button" class="btn btn-default cancel-button" data-dismiss="modal" id='Completed' onClick={this.handleRefreshPage}>Close</button> */}
                        </div>
                      </form>
                    </div>

                  </div>
                </div>

              </div>
       
            
          },
      ];
      function onChange(pagination, filters, sorter) {
        console.log('params', pagination, filters, sorter);
      }

     
      
        return (  
           <div> 
               <Navigation /> 
               <div class="container-fluid" style={{ paddingTop:'85px',paddingBottom:"80px" }}>
               <div style={{backgroundColor:'rgb(241, 241, 241)',paddingTop:'20px' }}>
                <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" style={{marginTop:'-10px'}}>
                <span>
                <h3 style={{textIndent:'15px',fontFamily:'roboto'}}>Campaign Overview
                &nbsp;&nbsp;           
                {/* <div  style={{left:'506px',top:'28px'}}class="whirly-loader"></div>       */}
                {user.role!="ANC" && user.role!="AQA"?
                    <Tooltip placement="right" title="New Campaign"><span>
                  <a href="welcomePage" style={{color:'black',fontWeight:'500',height:'15px'}}>
                    <label style={{backgroundColor:"#ffaf42", fontSize:"16px",color:"white",fontWeight:'inherit'}}>&nbsp;Create Campaign&nbsp;
                    <img src="createCampaign.png" style={{display:'inline-block', height: "22px", width: "35px"}}></img>
                    </label></a></span></Tooltip>
                  :''  
                  }
                </h3>
               
                    </span>
                </div>

                {/* <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style={{marginTop:'-10px'}}>
                <span class="float-left" style={{marginRight:"15px",marginLeft:"-208px"}}>
              
                
              </span>
               
                </div> */}
            
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"  style={{ paddingTop:'3px'}}>
                    <span class="float-right" style={{marginRight:"15px"}}>


        
 {this.state.tab=="CampaignSetup"?"":  
 <span>  
 <span id="myText">All</span>
<div class="dropdown1">
 <img src="Filter.png" height="20%" width="20%"></img>
  <div class="dropdown-content1">
  <a  href="#" value="All"  name="All" onClick={this.handleChange}   id="All">All</a>
  <a  href="#" value="Ending this month" name="Ending this month" onClick={this.handleChange}   id="Monthly">Ending this month</a>
    <a href="#" value="Ending this week" name="Ending this week" onClick={this.handleChange}   id="Weekly">Ending this week</a>
   
    
  </div>
</div> 
</span>
 }
                    {/* <select  
                defaultValue={this.state.pacing}
               onChange={this.handleChange}
                id="pacing1"
                class="input-small"
                className="form-control"
                name="pacing" style={{width:'90px',height:'30px',fontSize:"11px",fontFamily:'roboto'}}>
                <option value="All" selected>All</option>
                <option value="Monthly" >Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Weekly">Weekly</option>
                </select> */}

                    </span>
              <span class="float-right">

              </span>
                </div>
                </div>
                {/* end of row 1 */}

                <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
               
 {this.state.dashboardCampaignCount.map(dashboardCampaignCount => (
                <div class="float-center topnav">

<a href="#"  class={this.state.linkActive1} onClick={this.campaignLink} id="Active">Active&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"green", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.activeCount}</span></a>
&nbsp;&nbsp;&nbsp;
<a href="#"  class={this.state.linkActive2} onClick={this.campaignLink} id="Pause">Pause&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"#ffaf42", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.pauseCount}</span></a>
&nbsp;&nbsp;&nbsp;
<a href="#"  class={this.state.linkActive3} onClick={this.campaignLink} id="Completed">Completed&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"blue", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.completedCount}</span></a>
&nbsp;&nbsp;
<a href="#" class={this.state.linkActive} onClick={this.campaignLink} id="All">All&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"#484141", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.allCount}</span></a>
&nbsp;&nbsp;&nbsp;
{user.role==="AC"?
 <a href="#" class={this.state.linkActive4} onClick={this.campaignLink} id="CampaignSetup">Campaign Setup&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"#bdbdb3", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.campSetUpCount}</span></a>
:''}
 &nbsp;&nbsp;&nbsp; 
 <a href="#"  class={this.state.linkActive5} onClick={this.campaignLink} id="cancelCamp">Cancel Campaign&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"red", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.cancelCampaign}</span></a>
&nbsp;&nbsp;&nbsp;&nbsp;


 

                </div>
                ))}
                 </div>

                 <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" style={{marginBottom:'10px',marginTop:"5px"}}>
               
                 <span class="float-right" style={{marginRight:"10px"}}>

                 <input type="text" onkeyup="myFunction()"
style={{backgroundImage:'url(searchIcon.png)',
backgroundPosition:'15px 6px',
backgroundSize:'15px 15px',
backgroundRepeat:"no-repeat",
width: '100%',
height:'20px',
fontSize:'10px',
border: '1px solid #ddd',
padding: '12px 20px 12px 34px',
border: '1px solid #ddd',
borderRadius:"25px",
backgroundColor: "rgb(221, 221, 221)"

}} 
                 onChange={this.handleChangeSearch}  onPressEnter={this.handleChangeSearch}
                 placeholder="Search..." title="Type in a name"/>



                               </span>
                </div>
                </div>
                {/* end of row 2 */}
                 </div>
                 {/* <br/> */}
                <div class="row">
                {/* <table id="example" class="display" width="100%"></table> */}
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{paddingTop:'5px'}}>
                {/* <div style={{border:'1px solid #e8e8e8'}}></div> */}

                {this.state.tableSearchText===""?
                <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.campaignDetails} 
         onChange={onChange} columns={columns}  loading={this.state.loading} className="ant-table-thead" className={tableCSS} scroll={{ y: 240 }}
           expandedRowRender={record => 
            <div>


<div class="container-fluid">
<table border='1' style={{tableLayout:"auto",borderCollapse:"collapse"}}>
{/* <div class="row"> */}

  <tr>
  <td rowSpan="2">&nbsp; 
Agency CampaignID:&nbsp;&nbsp;<b>{record.clientCampID?record.clientCampID:''}</b>
</td>
<th style={{fontWeight:'400'}}>&nbsp;Campaign Name:&nbsp;</th>
<td><b>&nbsp;{record.campaignName?record.campaignName:record.advCampaignName}</b></td>
<th style={{fontWeight:'400'}}>&nbsp;Start Date:&nbsp;</th>
<td  style={{width:'8%'}}><b>&nbsp;{record.startDate?record.startDate:record.advstartDate}</b></td>
<th style={{fontWeight:'400'}}>&nbsp;End Date:&nbsp;</th>
<td style={{width:'8%'}}><b>&nbsp;{record.endDate?record.endDate:record.advendDate}</b></td>

<th style={{fontWeight:'400'}}>&nbsp;QA Review:&nbsp;</th>
<td><b>&nbsp;{record.onlyQALeads?record.onlyQALeads:0}</b></td>
{/* <th rowSpan="2" style={{fontWeight:'400'}}>&nbsp; Agency CampaignID:</th>
<td rowSpan="2"><b>&nbsp;{record.clientCampID?record.clientCampID:''}</b></td> */}
{this.state.tab=="CampaignSetup"?"": 
<td rowSpan="2">&nbsp; 
Insight:&nbsp;&nbsp;

 {/* <a href={'http://libyai.com/LoginControl?userID='+user.id+'&campID=' + record.campID} target="_blank">  */}
 <a href="#" id={record.campID} onClick={this.getLIbyAIURL.bind(this)}>
<img style={{display:"inherit"}}src='1.png' id={record.campID} width='40px' height='20px'></img>
</a>
</td>
}
</tr>
<tr>
  {/* <td>
{user.role==="AC"?:''}
</td> */}
<th style={{fontWeight:'400' }}>&nbsp;Campaign Status:&nbsp;</th>
<td>
  <b>&nbsp;
 {record.campaignStatus=="Active"? <span style={{color:"#5fba7d",fontSize:"12px" }}> {record.campaignStatus}</span>:
record.campaignStatus=="Live_Incomplete"? <span style={{color:"orange",fontSize:"12px"}}> {record.campaignStatus}</span>:
record.campaignStatus=="Paused"? <span style={{color:"#ffaf42",fontSize:"12px" }}> {record.campaignStatus}</span>:
record.campaignStatus=="Completed"? <span style={{color:"skyblue",fontSize:"12px" }}> {record.campaignStatus}</span>:
record.advStatus=="PendingSetup"? <span style={{color:"#abab93",fontSize:"12px" }}> {record.advStatus}</span>:
record.campaignStatus=="PausedIncomplete"? <span style={{color:"orange",fontSize:"12px" }}>{record.campaignStatus}</span>:
record.campaignStatus=="Cancel Campaign"? <span style={{color:"red",fontSize:"12px" }}>{record.campaignStatus}</span>:
""
} 
</b>
</td>

{user.role==="AC"?<th style={{fontWeight:'400'}}>&nbsp;CPL:</th>:''}
{user.role==="AC"?<td><b>&nbsp;{record.CPL?record.CPL:record.advCPL}&nbsp;{record.currency}</b></td>:''}
{user.role==="AC"?<th style={{fontWeight:'400'}}>&nbsp;Budget:</th>:''}
{user.role==="AC"?<td  ><b>&nbsp;{record.budget?record.budget:record.advBudget}&nbsp;{record.currency}</b></td>:''}

<th style={{fontWeight:'400'}}>&nbsp;Agency Internal Review:&nbsp;</th>
<td><b>&nbsp;{record.agencyInternalReviewLead?record.agencyInternalReviewLead:0}</b></td>

</tr>
  {/* </div> */}

  </table>
</div>
</div>
           
          }
        pagination={{ pageSize: this.state.pageSize,position:'bottom' }}></Table>
        :
        <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.campaignDetailsSearch} 
        onChange={onChange} columns={columns} className="ant-table-thead"   loading={this.state.loading} className={tableCSS} scroll={{ y: 240 }}
          expandedRowRender={record => 
            <div class="container-fluid">
            <table border='1' style={{tableLayout:"auto",borderCollapse:"collapse"}}>
{/* <div class="row"> */}
  <tr>
  <td rowSpan="2">&nbsp; 
Agency CampaignID:&nbsp;&nbsp;<b>{record.clientCampID?record.clientCampID:''}</b>
</td>
<th style={{fontWeight:'400'}}>&nbsp;Campaign Name:&nbsp;</th>
<td><b>&nbsp;{record.campaignName}</b></td>
<th style={{fontWeight:'400'}}>&nbsp;Start Date:&nbsp;</th>
<td><b>&nbsp;{record.startDate}</b></td>
<th style={{fontWeight:'400'}}>&nbsp;End Date:&nbsp;</th>
<td><b>&nbsp;{record.endDate}</b></td>
<th style={{fontWeight:'400'}}>&nbsp;QA Review:&nbsp;</th>
<td><b>&nbsp;{record.onlyQALeads?record.onlyQALeads:0}</b></td>

{user.role==="AC"?<td rowSpan="2">&nbsp; 
Insight:&nbsp;&nbsp;
{/* <a href={'http://libyai.com/LoginControl?userID='+user.id+'&campID=' + record.campID} target="_blank"> */}
<a href="#" id={record.campID} onClick={this.getLIbyAIURL.bind(this)}>
<img style={{display:"inherit"}}src='1.png' width='40px' height='20px'></img>
</a>
</td>:''}

</tr>
<tr>
<th style={{fontWeight:'400'}}>&nbsp;Campaign Status:&nbsp;</th>
<td><b>
&nbsp;{record.campaignStatus=="Active"? <span style={{color:"#5fba7d",fontSize:"12px" }}> {record.campaignStatus}</span>:
            record.campaignStatus=="Live_Incomplete"? <span style={{color:"orange",fontSize:"12px"}}> {record.campaignStatus}</span>:
            record.campaignStatus=="Paused"? <span style={{color:"#ffaf42",fontSize:"12px" }}> {record.campaignStatus}</span>:
            record.campaignStatus=="Completed"? <span style={{color:"skyblue",fontSize:"12px" }}> {record.campaignStatus}</span>:
            record.advStatus=="PendingSetup"? <span style={{color:"#abab93",fontSize:"12px" }}> {record.advStatus}</span>:
            record.campaignStatus=="PausedIncomplete"? <span style={{color:"orange",fontSize:"12px" }}>{record.campaignStatus}</span>:
             record.campaignStatus=="Cancel Campaign"? <span style={{color:"red",fontSize:"12px" }}>{record.campaignStatus}</span>:
            ""
            } 
  </b>
  </td>
  {user.role==="AC"? <th  style={{fontWeight:'400'}}>&nbsp;CPL:&nbsp;</th>:''}
          {user.role==="AC"?<td><b>&nbsp;{record.CPL}&nbsp;{record.currency}</b></td>:''}
{user.role==="AC"? <th style={{fontWeight:'400'}}>&nbsp;Budget:&nbsp;</th>:''}
{user.role==="AC"?<td><b>&nbsp;{record.budget} &nbsp;{record.currency}</b></td>:''}

<th style={{fontWeight:'400'}}>&nbsp;Agency Internal Review see:&nbsp;</th>
<td><b>&nbsp;{record.agencyInternalReviewLead?record.agencyInternalReviewLead:0}</b></td>

</tr>
</table>
</div>
            
         }
       pagination={{ pageSize: this.state.pageSize,position:'bottom' }}></Table>
        }

                </div>
                
              
                 </div>
                      {/* end of row 3  headerClassName={header} className={styles.mystyle}*/}
                      <br/>
                      <div class="row">
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px',marginLeft:'5px'}}>
       &nbsp;Records per page:&nbsp;
        <select  
                defaultValue={this.state.pageSize}
               onChange={this.handleChangePageSize}
                id="pacing1"
                class="input-small"
                className="form-control"
                name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                <option value="5" selected>5</option>
                <option value="10" >10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                </select>

                </div>
                        </div>

                    
                      <div class="row">
                        {user.role==='AQA'?''
                      :<div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                      {/* <h3 style={{fontFamily:'Lora'}}>Bidding</h3> */}
                      <div style={{ background: '#ECECEC', padding: '10px 15px 10px 15px' ,height:'430px' }}>
                      <p>
                      <h3 style={{fontFamily:'roboto'}}>Bidding</h3>
                      {/* <h3 style={{textIndent:'15px',fontFamily:'roboto'}}>Bidding</h3> */}
                    </p>
                       <br/>
                      <p>  
                       

           <Badge count={this.state.counterLength} overflowCount={10} offset={[1,-5]}>
           <Button size="default" style={{borderRadius:'40px'}} className={this.state.headExample}  id="counter" onClick={this.biddingLink}>
            <span style={{font:'8px'}}>Counter</span> 
             </Button>
         </Badge>
         
         <Badge count={this.state.rejectLength} overflowCount={10} offset={[1,-5]}>
           <Button size="default" style={{borderRadius:'40px'}} className={this.state.headExample1}  id="reject" onClick={this.biddingLink}>Reject</Button>
         </Badge>
    
         {user.role==="AC"?
         <Badge count={this.state.rfpBiddingLength} overflowCount={10} offset={[1,-5]}>
           <Button size="default" style={{borderRadius:'40px'}} className={this.state.headExample6}  id="RFP" onClick={this.biddingLink}>RFP</Button>
         </Badge>
         
  :""
  } 
    
</p>

   <p>    <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.biddingArray}   loading={this.state.loading1}
         onChange={onChange} columns={this.state.RFPBiddingTab=='Counter' || this.state.RFPBiddingTab=='Reject'?columns01:this.state.RFPBiddingTab==='RFPBiddingTab'?columnsRFP:columns01} className="ant-table-thead" className={tableCSS} scroll={{ y: 200 }}
        pagination={{ pageSize: this.state.pageSize1 }}></Table></p>
        <p>
        <div class="row">
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px',marginLeft:'5px'}}>
        Records per page:&nbsp;
        <select  
                defaultValue={this.state.pageSize1}
               onChange={this.handleChangePageSize1}
                id="pacing1"
                class="input-small"
                className="form-control"
                name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                <option value="5" selected>5</option>
                <option value="10" >10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                </select>
                </div>
                        </div>
        </p>
                      </div>
                      </div>
    }
                       <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                       <div style={{ background: '#ECECEC', padding: '10px 15px 10px 15px',height:'430px' }}>
                       <p>
                      
                       <h3 style={{fontFamily:'roboto'}}>Review</h3>
                      {/* <h3 style={{textIndent:'15px',fontFamily:'roboto'}}>Review</h3> */}
                        </p>
                        <br/>
                          <p>  
                          
            <span class="badgelink">
            <Badge count={this.state.creativeLength} overflowCount={10} offset={[1,-5]}>
       <Button size="default" className={this.state.headExample2} id="creatives" onClick={this.reviewLink}>Creatives</Button>
     </Badge>
     </span>

     
         
             <span class="badgelink">
        <Badge count={this.state.leadsLength} overflowCount={10} offset={[1,-5]}>
       <Button size="default" className={this.state.headExample3} id="leads" onClick={this.reviewLink}>Leads</Button>
     </Badge> </span>
     {user.role==="AC"?
     <span class="badgelink">
        <Badge count={this.state.monthArrayLength} overflowCount={10} offset={[1,-5]}>
       <Button size="default" className={this.state.headExample4} id="invoice" onClick={this.reviewLink}>Invoice</Button>
     </Badge> </span>
     :""}
    
{user.role==="AC"?
     <span class="badgelink">
        <Badge count={this.state.othersLength} coverflowCount={10} offset={[1,-5]}>
       <Button size="default" className={this.state.headExample5} id="others" onClick={this.reviewLink}>Others</Button>
     </Badge> </span>
     :''}
     {/* <span class="badgelink">
        <Badge count={0} coverflowCount={10} offset={[1,-5]}>
       <Button size="default"  id="others" onClick={this.generateInvoice.bind(this)}>Generate Invoice</Button>
     </Badge> </span> */}
     
             </p>
            
                          <p>  <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.reviewArray}   loading={this.state.loading2}
         onChange={onChange} columns={this.state.tabReview=='creatives' || this.state.tabReview=='Creatives'?columns1: this.state.tabReview=='leads'?columns2:this.state.tabReview=='invoice'?invoiceData:columns3} className="ant-table-thead" className={tableCSS} scroll={{ y: 200 }}
        pagination={{ pageSize: this.state.pageSize2 }}></Table></p>
            

            {/* {this.state.ReviewTab=='ReviewOthers'?
         <p>  <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.reviewArray}   loading={this.state.loading2}
         onChange={onChange} columns={columns3} className="ant-table-thead" className={tableCSS} scroll={{ y: 200 }}
        pagination={{ pageSize: this.state.pageSize2 }}></Table></p>:
        <p><Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.reviewArray}   loading={this.state.loading2}
        onChange={onChange} columns={columns2} className="ant-table-thead" className={tableCSS} scroll={{ y: 200 }}
       pagination={{ pageSize: this.state.pageSize2 }}></Table></p>} */} 

<p>
        <div class="row">
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px',marginLeft:'5px'}}>
        Records per page:&nbsp;
        <select  
                defaultValue={this.state.pageSize2}
               onChange={this.handleChangePageSize2}
                id="pacing1"
                class="input-small"
                className="form-control"
                name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                <option value="5" selected>5</option>
                <option value="10" >10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                </select>

                </div>
                        </div>

        </p>
                       
                       
                      </div>

                      
                       </div>
                       {/* <div class="col-xs-4 col-sm-4 col-md-2 col-lg-4 col-xl-4 col-md-offset-2">
                      <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.reviewArray} 
         onChange={onChange} columns={columns2} className="ant-table-thead"
        pagination={{ pageSize: this.state.pageSize }}></Table>
                       </div> */}
                        </div>
                   
                        <br/>
                  
{/* Start of new footer */}

<MDBCard style={{ width: "22rem",display:'none',height:'239px',zIndex:-1, 
   position: 'fixed',
   display:this.state.show,
   width: '65%',
   height: '60%',
   top: '238px',
   left: '0',
   right: '0',
   bottom: '0',
   zIndex: 2,
   boxShadow:'5px 2px',
   background:'#F1F1F1'

  }} id="card1">
<MDBCardBody>

{/* {this.state.panelBlock} */}
{this.state.panelBlock==="message"?

   <MDBCardTitle style={{fontSize:'11px'}}>Unread Messages &nbsp; <input type="text" onkeyup="myFunction()"
style={{backgroundImage:'url(Group_294.png)',
backgroundPosition:'15px 6px',
backgroundSize:'15px 15px',
backgroundRepeat:"no-repeat",
width: '20%',
height:'20px',
fontSize:'10px',
border: '1px solid #ddd',
padding: '12px 20px 12px 34px',
border: '1px solid #ddd',
borderRadius:"25px",
backgroundColor: "#DDDDDD"

}} 
                 onChange={this.handleChangeSearch1}  onPressEnter={this.handleChangeSearch1}
                 placeholder="Search..." title="Search"/>
   <MDBBtn style={{left:'546px'}} onClick={this.closeChat}>&times;</MDBBtn></MDBCardTitle>
   :


<MDBCardTitle  style={{fontSize:'11px'}}>Unread Alerts &nbsp; <input type="text" onkeyup="myFunction()"
style={{backgroundImage:'url(Group_294.png)',
backgroundPosition:'15px 6px',
backgroundSize:'15px 15px',
backgroundRepeat:"no-repeat",
width: '20%',
height:'20px',
fontSize:'10px',
border: '1px solid #ddd',
padding: '12px 20px 12px 34px',
border: '1px solid #ddd',
borderRadius:"25px",
backgroundColor: "#DDDDDD"


}} 
                 onChange={this.handleChangeSearch2}  onPressEnter={this.handleChangeSearch2}
                 placeholder="Search..." title="Search"/>
                
   <MDBBtn style={{left:'566px'}} onClick={this.closeChat}>&times;</MDBBtn></MDBCardTitle>

   

}



{this.state.panelBlock==="message"?

<MDBCardText>
{/* {this.state.panelBlock} */}
{this.state.tableSearchText1===""?
<Table dataSource={this.state.unreadMessages} columns={columnUnreadMsg}  bordered

loading={this.state.loading} size="small"  className={tableCSS} scroll={{ y: 200 }}
pagination={{ pageSize: this.state.pageSize3 }} />
:
<Table dataSource={this.state.columnUnreadMsgSearch} columns={columnUnreadMsg}  bordered

loading={this.state.loading} size="small"  className={tableCSS} scroll={{ y: 200 }}
pagination={{ pageSize: this.state.pageSize3 }} />}
<br/>
<p>
          <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px',fontSize:'12px',marginLeft:'15px'}}>
          &nbsp;Records per page:&nbsp;
          <select  
                  defaultValue={this.state.pageSize3}
                 onChange={this.handleChangePageSize3}
                  id="pacing1"
                  class="input-small"
                  className="form-control"
                  name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                  <option value="5" selected>5</option>
                  <option value="10" >10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  </select>
  
                  </div>
                          </div> 
          </p>


</MDBCardText>
:

<MDBCardText>
{/* {this.state.panelBlock} */}
{this.state.tableSearchText2===""?
<Table dataSource={this.state.unreadAlerts} columns={columnUnreadAlerts}  bordered

loading={this.state.loading} size="small"  className={tableCSS} scroll={{ y: 200 }}
expandedRowRender={record => 
  //<div class="container-fluid">
  <span>
    {/* {alert("Record===>"+JSON.stringify(record))}  */}
 {this.recordOpen(record.alertID,record.campID)}
  <table border='1' style={{tableLayout:"auto",borderCollapse:"collapse"}}>
 
    <tr>
     
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CN&nbsp;</b></th>
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;SD&nbsp;</b></th>
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;ED&nbsp;</b></th>
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;AL&nbsp;</b></th>
{user.role==="AC"? <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CPL&nbsp;</b></th>:''}
</tr>
<tr>
<td style={{fontSize:'10px',width:'150px'}} class="word-wrap">&nbsp;{record.campaignName}</td>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.startDate}</td>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.endDate}</td>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.allocatedLead}</td>
{user.role==="AC"? <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.CPL}</td>:''}
</tr>
</table>
</span>
//</div>
  }
pagination={{ pageSize: this.state.pageSize4 }} />
:
<Table dataSource={this.state.columnUnreadAlertsSearch} columns={columnUnreadAlerts}  bordered

loading={this.state.loading} size="small"  className={tableCSS} scroll={{ y: 200 }}
expandedRowRender={record => 
 // <div class="container-fluid">
 
  <table border='1' style={{tableLayout:"auto",borderCollapse:"collapse"}}>
    <tr>
     
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CN&nbsp;</b></th>

<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;SD&nbsp;</b></th>
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;ED&nbsp;</b></th>
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;AL&nbsp;</b></th>
{user.role==="AC"? <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CPL&nbsp;</b></th>:''}
  </tr>
  <tr>
  <td style={{fontSize:'10px',width:'150px'}} class="word-wrap">&nbsp;{record.campaignName}</td>
  <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.startDate}</td>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.endDate}</td>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.allocatedLead}</td>
{user.role==="AC"? <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.CPL}</td>:''}
</tr>
</table>
 //</div>
  }
pagination={{ pageSize: this.state.pageSize4 }} />}
<br/>
<p>
          <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px',fontSize:'12px',marginLeft:'15px'}}>
          &nbsp;Records per page:&nbsp;
          <select  
                  defaultValue={this.state.pageSize4}
                 onChange={this.handleChangePageSize4}
                  id="pacing1"
                  class="input-small"
                  className="form-control"
                  name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                  <option value="5" selected>5</option>
                  <option value="10" >10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  </select>
  
                  </div>
                          </div> 
          </p>


</MDBCardText>

         }
   


  
 </MDBCardBody>
</MDBCard>



   <div class="footer">
  <div class="float-left"> 

  <button type="button"id="alerts" style={{backgroundColor:"#555",borderTop:"rgb(85, 85, 85)",
    borderLeft: "rgb(85, 85, 85)",
    borderBottom: "rgb(85, 85, 85)"}}  id="alerts" onClick={this.fadeInOut} >Unread Alerts  ({this.state.unreadAlertsLength})</button>
  
  <button type="button"id="messages" id="messages"style={{backgroundColor:"#555",border:" rgb(85, 85, 85)"}} onClick={this.fadeInOut} >Unread Messages  ({this.state.unreadMessagesLength})</button>
 

</div>
</div> 


{/* end of new footer */}


                </div>
                {/* end of container 1 */}
                 
             
               
           {/* <Footer/> */}
           </div>  
           // end of last div
        );  
     }// end of render  
};
//end of class Dashboard

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      Dashboard.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(Dashboard)); 

