/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file Request For Proposal Dashboard
 * @author  Karan Jagtap
 * @version 1.0
 * @section Request For Proposal UI use
 * */
 import React, { Component } from "react";
 import PropTypes from "prop-types";
 import { connect } from "react-redux";
 import { withRouter } from "react-router-dom";
 import { Chart } from "react-google-charts";
 import { Button, Card } from "antd";
 import CanvasJSReact from "../../assets/canvasjs.react";
 
 import { logoutUser } from "../login/authentication";
 import Navigation from "../layouts/navPage";
 import Footer from "../layouts/footer";
 import "../login/dashboard.css";
 import "./rfpDashboardFE.css";
 import { css } from "emotion";
 import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3796-vapt files auth header
 
 /**
  * @author Karan Jagtap
  * @description Task-3521 all required constants for this file
  */
 const MONTHS = [
	 "Jan",
	 "Feb",
	 "Mar",
	 "Apr",
	 "May",
	 "Jun",
	 "Jul",
	 "Aug",
	 "Sep",
	 "Oct",
	 "Nov",
	 "Dec",
 ];
 const CURRENT_DATE = new Date();
 const RFP_STATUS = "RFP Status - ";
 const CanvasJSChart = CanvasJSReact.CanvasJSChart;
 const PIE_CHART_CSS = css({
	 "& #reactgooglegraph-1": {
		 marginLeft: "0px !important",
	 },
 });
 
 /**
  * @author Karan Jagtap
  * @description Task-3521 Main class for rfpDashboardFE.js file
  */
 class RFPDashboard extends Component {
	 constructor(props) {
		 super();
		 /**
		  * @author Karan Jagtap
		  * @description Task-3521 setting all reqired variables
		  */
		 this.state = {
			 advertiserNames: [],
			 selectedFilter: "Monthly",
			 selectedFilterLabel: "Ending This Month",
			 campignToCreate: 0,
			 rfpToAllocate: 0,
			 rfpToRespond: 0,
			 advertiserName: "All",
			 startDate: "",
			 endDate: "",
			 pieChartData: [],
			 pieChartEmpty: true,
			 chartTitle: "",
			 barChartData: [],
			 barChartEmpty: true,
			 barChartOptions: {},
			 dateRange: false,
			 // karan-task-3661-publihser side -start
			 enabledClassName: "head-exampleGeneralAdv",
			 disabledClassName: "head-exampleGeneralPub",
			 advertiserButtonClassName: "head-exampleGeneralAdv",
			 publisherButtonClassName: "head-exampleGeneralPub",
			 advPubEnabledBtnID: "advertiser",
			 publisherNames: [],
			 publisherName: "All",
			 // karan-task-3661-publihser side -end
		 };
	 }
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3521 calling of apis needed for this page
	  */
	 componentDidMount() {
		 if (!this.props.auth.isAuthenticated) {
			 this.props.history.push("/userLogin");
		 } else {
			 this.fetchChartData();
			 this.fetchOperationalData();
			 this.fetchAdvertiserNames();
			 this.fetchPublisherNames();
		 }
	 }
 
	 /**
	  * @author Saurabh Adhav
	  * @description Task-3775 Creating Will Mount
	  */
	 componentWillMount() {
		 if (!this.props.auth.isAuthenticated) {
			 this.props.history.push("/userLogin");
		 } else {
			 this.completeRfpMark();
		 }
	 }
	 // end of componentWillMount
	 /**
	  * @author Saurabh Adhav
	  * @description Task-3775 Archive data
	  */
	 completeRfpMark = () => {
		 fetch("/rfpDashboardBE/completeRFPMark", {
			 method: "POST",
			 headers: {
				 "Content-Type": "application/json",
			 }, //karan-task-3803-vapt header and query params
		 })
			 .then((res) => res.json())
			 .then((res) => {
				 this.setState(res);
			 })
			 .catch((err) => {
				 console.log(err);
			 });
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3521 extracts advertiser id from advertiser name
	  */
	 extractAdvertiserID = (advertiserName) => {
		 let advertiserID = "All";
		 if (advertiserName !== "All") {
			 advertiserID = advertiserName.split("-")[0].trim();
		 }
		 return advertiserID;
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3521 fetches chart data (pie chart and bar graph)
	  * @route /rfpDashboardBE/chartDataRFP
	  */
	 fetchChartData = () => {
		 const advertiserID = this.extractAdvertiserID(this.state.advertiserName);
		 const data = {
			 pacing: this.state.selectedFilter,
			 startDate: this.state.startDate,
			 endDate: this.state.endDate,
			 advertiserName: advertiserID,
		 }; //karan-task-3803-vapt header and query params
		 fetch("/rfpDashboardBE/chartDataRFP", {
			 method: "POST",
			 headers: {
				 "Content-Type": "application/json",
			 }, //karan-task-3803-vapt header and query params
			 body: JSON.stringify(data),
		 })
			 .then((res) => res.json())
			 .then((res) => {
				 const pieChartData = res.pieChart;
				 const barChartData = res.barChart;
				 let pieCount = 0,
					 barCount = 0;
				 let pieChartEmpty = true,
					 barChartEmpty = true;
				 for (let i = 1; i < pieChartData.length; i++) {
					 pieCount = pieCount + pieChartData[i][1];
				 }
				 if (pieCount > 0) {
					 pieChartEmpty = false;
				 }
				 for (let i = 0; i < barChartData.length; i++) {
					 for (let j = 0; j < barChartData[i].length; j++) {
						 barCount = barCount + barChartData[i][j].y;
					 }
				 }
				 if (barCount > 0) {
					 barChartEmpty = false;
				 }
				 const options = this.formatDataToBarChart(barChartData);
				 this.setState({
					 pieChartData,
					 pieChartEmpty,
					 barChartData,
					 barChartEmpty,
					 barChartOptions: options,
				 });
			 })
			 .catch((err) => {
				 console.log(err);
			 });
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3661 fetches chart data (pie chart and bar graph) for publisher
	  * @route /rfpDashboardBE/chartByPublisher
	  */
	 fetchPublisherChartData = () => {
		 const publisherId = this.extractAdvertiserID(this.state.publisherName);
		 const data = {
			 pacing: this.state.selectedFilter,
			 startDate: this.state.startDate,
			 endDate: this.state.endDate,
			 publisherId,
		 };
		 fetch("/rfpDashboardBE/chartByPublisher", {
			 method: "POST",
			 headers: {
				 "Content-Type": "application/json",
			 }, //karan-task-3803-vapt header and query params
			 body: JSON.stringify(data),
		 })
			 .then((res) => res.json())
			 .then((res) => {
				 const pieChartData = res.pieChart;
				 const barChartData = res.barChart;
				 let pieCount = 0,
					 barCount = 0;
				 let pieChartEmpty = true,
					 barChartEmpty = true;
				 for (let i = 1; i < pieChartData.length; i++) {
					 pieCount = pieCount + pieChartData[i][1];
				 }
				 if (pieCount > 0) {
					 pieChartEmpty = false;
				 }
				 for (let i = 0; i < barChartData.length; i++) {
					 for (let j = 0; j < barChartData[i].length; j++) {
						 barCount = barCount + barChartData[i][j].y;
					 }
				 }
				 if (barCount > 0) {
					 barChartEmpty = false;
				 }
				 const options = this.formatDataToBarChart(barChartData);
				 this.setState({
					 pieChartData,
					 pieChartEmpty,
					 barChartData,
					 barChartEmpty,
					 barChartOptions: options,
				 });
			 })
			 .catch((err) => {
				 console.log(err);
			 });
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3521 fetches operational action data (campaign to create, rfp to allocate, rfp to respond)
	  * @route /rfpDashboardBE/operationalAction
	  */
	 fetchOperationalData = () => {
		 fetch("/rfpDashboardBE/operationalAction", {
			 method: "POST",
			 headers: {
				 "Content-Type": "application/json",
			 }, //karan-task-3803-vapt header and query params
		 })
			 .then((res) => res.json())
			 .then((res) => {
				 const { campignToCreate, rfpToAllocate, rfpToRespond } = res[0];
				 this.setState({ campignToCreate, rfpToAllocate, rfpToRespond });
			 })
			 .catch((err) => {
				 console.log(err);
			 });
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3521 fetches advertiser names to show in dropdown
	  * @route /agencyDashboard/selectClient?agencyID=
	  */
	 fetchAdvertiserNames = () => {
		 fetch("agencyDashboard/selectClient") //karan-task-3796-vapt remove ids
			 .then((res) => res.json())
			 .then((advertiserNames) => {
				 this.setState({ advertiserNames });
				 this.changeChartTitleText(
					 this.state.selectedFilter,
					 this.state.advertiserName
				 );
			 })
			 .catch(function (err) {
				 console.log(err);
			 });
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3661 fetches publisher names to show in dropdown
	  * @route /agencyDashboard/selectPublisher?agencyID=
	  */
	 fetchPublisherNames = () => {
		 fetch("agencyDashboard/selectPublisher") //karan-task-3796-vapt remove ids
			 .then((res) => res.json())
			 .then((publisherNames) => {
				 let formatPublisherNames = [];
				 for (let i = 0; i < publisherNames.length; i++) {
					 const obj = publisherNames[i];
					 formatPublisherNames.push(`${obj.pID} - ${obj.publisherName}`);
				 }
				 this.setState({ publisherNames: formatPublisherNames });
				 this.changeChartTitleText(
					 this.state.selectedFilter,
					 this.state.publisherName
				 );
			 })
			 .catch(function (err) {
				 console.log(err);
			 });
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3521 formats the data from api response to bar chart required by CanvasJS
	  */
	 formatDataToBarChart = (barChartData) => {
		 if (this.state.chartTitle === "") {
			 this.changeChartTitleText(this.state.selectedFilter, "All");
		 }
		 const options = {
			 height: 300,
			 animationEnabled: true,
			 title: {
				 text: this.state.chartTitle,
				 horizontalAlign: "left",
				 fontSize: 15,
				 fontColor: "#1c1b19",
				 fontWeight: "bold",
				 fontFamily: "roboto",
			 },
			 axisY: {
				 title: "RFP",
				 gridThickness: 0,
			 },
			 legend: {
				 verticalAlign: "center",
				 horizontalAlign: "right",
				 reversed: true,
				 cursor: "pointer",
				 itemclick: this.toggleDataSeries,
			 },
			 toolTip: {
				 shared: true,
				 reversed: true,
				 enabled: true, //disable here
				 animationEnabled: true,
				 content: toolTipContent,
			 },
			 data: [
				 {
					 cursor: "pointer",
					 indexLabel: "{l}",
					 indexLabelFontColor: "black",
					 indexLabelPlacement: "inside",
					 type: "stackedColumn",
					 name: "Active RFP",
					 showInLegend: false,
					 color: "#39b54a",
					 dataPoints: barChartData[0],
					 click: (e) => {
						 if (this.state.advPubEnabledBtnID === "advertiser") {
							 const advertiserID = this.extractAdvertiserID(
								 this.state.advertiserName
							 );
							 this.props.history.push("/rfpProgress", {
								 fromDate: e.dataPoint.fromDate,
								 toDate: e.dataPoint.toDate,
								 advertiserID: advertiserID,
								 status: "active",
							 }); //karan-task-3803-replace query params
						 } else if (this.state.advPubEnabledBtnID === "publisher") {
							 const publisherID = this.extractAdvertiserID(
								 this.state.publisherName
							 );
							 this.props.history.push("/rfpProgress", {
								 fromDate: e.dataPoint.fromDate,
								 toDate: e.dataPoint.toDate,
								 publisherID: publisherID,
								 status: "active",
							 }); //karan-task-3803-replace query params
						 }
					 },
				 },
				 {
					 cursor: "pointer",
					 indexLabel: "{l}",
					 indexLabelFontColor: "black",
					 indexLabelPlacement: "inside",
					 type: "stackedColumn",
					 name: "Archive",
					 showInLegend: false,
					 color: "#007cb4",
					 dataPoints: barChartData[1],
					 click: (e) => {
						 if (this.state.advPubEnabledBtnID === "advertiser") {
							 const advertiserID = this.extractAdvertiserID(
								 this.state.advertiserName
							 );
							 this.props.history.push("/rfpProgress", {
								 fromDate: e.dataPoint.fromDate,
								 toDate: e.dataPoint.toDate,
								 advertiserID: advertiserID,
								 status: "archive",
							 }); //karan-task-3803-replace query params
						 } else if (this.state.advPubEnabledBtnID === "publisher") {
							 const publisherID = this.extractAdvertiserID(
								 this.state.publisherName
							 );
							 this.props.history.push("/rfpProgress", {
								 fromDate: e.dataPoint.fromDate,
								 toDate: e.dataPoint.toDate,
								 publisherID: publisherID,
								 status: "archive",
							 }); //karan-task-3803-replace query params
						 }
					 },
				 },
				 {
					 cursor: "pointer",
					 indexLabel: "{l}",
					 indexLabelFontColor: "black",
					 indexLabelPlacement: "inside",
					 type: "stackedColumn",
					 name: "Closed RFP",
					 showInLegend: false,
					 color: "#ff931e",
					 dataPoints: barChartData[2],
					 click: (e) => {
						 if (this.state.advPubEnabledBtnID === "advertiser") {
							 const advertiserID = this.extractAdvertiserID(
								 this.state.advertiserName
							 );
							 this.props.history.push("/rfpProgress", {
								 fromDate: e.dataPoint.fromDate,
								 toDate: e.dataPoint.toDate,
								 advertiserID: advertiserID,
								 status: "closed",
							 }); //karan-task-3803-replace query params
						 } else if (this.state.advPubEnabledBtnID === "publisher") {
							 const publisherID = this.extractAdvertiserID(
								 this.state.publisherName
							 );
							 this.props.history.push("/rfpProgress", {
								 fromDate: e.dataPoint.fromDate,
								 toDate: e.dataPoint.toDate,
								 publisherID: publisherID,
								 status: "closed",
							 }); //karan-task-3803-replace query params
						 }
					 },
				 },
			 ],
		 };
		 function toolTipContent(e) {
			 //var str = ""; //rutuja 2832 commented str as never used in file
			 var str6; //rutuja 2832 remove str5 as never used in file
			 for (var i = 0; i < e.entries.length; i++) {
				 //sandeep-task-2964- added code to align tooltip content properly
				 var str1 =
					 '<span style= "color:#39b54a' +
					 '"> ' +
					 e.entries[0].dataSeries.name +
					 '</span>:<strong style="float:right">' +
					 e.entries[0].dataPoint.y +
					 "</strong><br/>";
				 var str2 =
					 '<span style= "color:#007cb4' +
					 '"> ' +
					 e.entries[1].dataSeries.name +
					 '</span>:<strong style="float:right">' +
					 e.entries[1].dataPoint.y +
					 "</strong><br/>";
				 var str3 =
					 '<span style= "color:#ff931e' +
					 '"> ' +
					 e.entries[2].dataSeries.name +
					 '</span>:<strong style="float:right">' +
					 e.entries[2].dataPoint.y +
					 "</strong><br/>";
				 var str12 = str2.concat(str1);
				 var str123 = str3.concat(str12);
			 }
			 str6 =
				 '<span style = "color:Black"></span><strong>' +
				 e.entries[0].dataPoint.label +
				 "</strong><br/>";
 
			 return str6.concat(str123);
		 }
		 return options;
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3521 dropdown handler for pacing
	  */
	 handleSelectPieFilter = (e) => {
		 const selectedFilter = e.target.value;
		 if (selectedFilter === "dateRange") {
			 this.setState({
				 dateRange: true,
				 selectedFilter,
				 selectedFilterLabel: e.target.options[e.target.selectedIndex].text,
			 });
		 } else {
			 this.changeChartTitleText(selectedFilter, this.state.advertiserName);
			 this.setState(
				 {
					 dateRange: false,
					 selectedFilter,
					 selectedFilterLabel: e.target.options[e.target.selectedIndex].text,
				 },
				 () => {
					 if (this.state.advPubEnabledBtnID === "advertiser") {
						 this.fetchChartData();
					 } else if (this.state.advPubEnabledBtnID === "publisher") {
						 this.fetchPublisherChartData();
					 }
				 }
			 );
		 }
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3521 dropdown handler for advertiser names
	  */
	 handleSelectBarFilter = (e) => {
		 if (this.state.advPubEnabledBtnID === "advertiser") {
			 this.setState({ advertiserName: e.target.value }, () => {
				 this.fetchChartData();
			 });
		 } else if (this.state.advPubEnabledBtnID === "publisher") {
			 this.setState({ publisherName: e.target.value }, () => {
				 this.fetchPublisherChartData();
			 });
		 }
		 this.changeChartTitleText(this.state.selectedFilter, e.target.value);
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3521 changes the title of both charts as per params
	  * @params pacing, advertiser
	  */
	 changeChartTitleText = (pacing, advertiser) => {
		 let timeSpan = "";
		 if (pacing === "Monthly") {
			 timeSpan = MONTHS[CURRENT_DATE.getMonth()];
		 } else if (pacing === "Quarterly") {
			 if (CURRENT_DATE.getMonth() > 2) {
				 timeSpan =
					 MONTHS[CURRENT_DATE.getMonth() - 2] +
					 "," +
					 MONTHS[CURRENT_DATE.getMonth() - 1] +
					 "," +
					 MONTHS[CURRENT_DATE.getMonth()];
			 } else {
				 timeSpan = MONTHS[0] + "," + MONTHS[1] + "," + MONTHS[2];
			 }
		 } else if (pacing === "Yearly") {
			 timeSpan = CURRENT_DATE.getFullYear();
		 } else if (pacing === "PreMonth") {
			 timeSpan = MONTHS[CURRENT_DATE.getMonth() - 1];
		 } else if (pacing === "PreQuarter") {
			 if (CURRENT_DATE.getMonth() > 2) {
				 timeSpan =
					 MONTHS[CURRENT_DATE.getMonth() - 5] +
					 "," +
					 MONTHS[CURRENT_DATE.getMonth() - 4] +
					 "," +
					 MONTHS[CURRENT_DATE.getMonth() - 3];
			 } else {
				 timeSpan = MONTHS[9] + "," + MONTHS[10] + "," + MONTHS[11];
			 }
		 } else if (pacing === "PreYear") {
			 timeSpan = CURRENT_DATE.getFullYear() - 1;
		 } else if (pacing === "dateRange") {
			 timeSpan = this.state.startDate + " to " + this.state.endDate;
		 }
		 const chartTitle = RFP_STATUS + advertiser + " - (" + timeSpan + ")";
		 this.setState({ chartTitle });
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3636 redirects to create rfp page
	  */
	  redirectToCreateRFP = (e) => {
		e.preventDefault();//Sonali-3716-removing ID from url and adding preventDefault
		this.props.history.push("/requestForProposal2",{agencyID: this.props.auth.user.id,from:"rfpDashboard"}); //sonali-task-3716-replace query params
		//window.location.href = `/requestForProposal2?agencyID=${this.props.auth.user.id}&from=rfpDashboard`;
	};
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3521 handles date range values
	  */
	 commonhandleChange = (e) => {
		 const { name, value } = e.target;
		 let startDate = this.state.startDate;
		 let endDate = this.state.endDate;
		 //rutuja 2832 replaced == with === for console warning
		 if (name === "StartDate") {
			 startDate = value;
		 } else {
			 endDate = value;
		 }
		 this.setState({ startDate, endDate }, () => {
			 if (this.state.dateRange && startDate !== "" && endDate !== "") {
				 if (this.state.advPubEnabledBtnID === "advertiser") {
					 this.fetchChartData();
				 } else if (this.state.advPubEnabledBtnID === "publisher") {
					 this.fetchPublisherChartData();
				 }
			 }
		 });
	 };
 
	 /**
	  * @author Karan Jagtap
	  * @description Task-3636 handles operational action clicks
	  */
	 //shivani - 3777 - added link for rfpToRespond Action .
	 handleOperationalActionClick = (actionName) => {
		if (actionName === "rfpToRespond") {
			this.props.history.push("/rfpAnalysis",{actionName: actionName}); //sonali-task-3716-replace query params
		//	window.location.href = "/rfpAnalysis?actionName=" + actionName;
		} else {

			this.props.history.push("/rfpProgress", { actionName }); //karan-task-3803-replace query params-Sonali
		}
	};

	 /**
	  * @author Karan Jagtap
	  * @description Task-3661 handles advertiser and publisher button click
	  */
	 handleAdvPubButtonClick = (e) => {
		 const { id } = e.target;
		 if (id === "advertiser") {
			 this.setState(
				 {
					 advertiserButtonClassName: this.state.enabledClassName,
					 publisherButtonClassName: this.state.disabledClassName,
					 advPubEnabledBtnID: id,
					 advertiserName: "All",
					 publisherName: "All",
				 },
				 () => {
					 this.fetchChartData();
				 }
			 );
		 } else if (id === "publisher") {
			 this.setState(
				 {
					 advertiserButtonClassName: this.state.disabledClassName,
					 publisherButtonClassName: this.state.enabledClassName,
					 advPubEnabledBtnID: id,
					 advertiserName: "All",
					 publisherName: "All",
				 },
				 () => {
					 this.fetchPublisherChartData();
				 }
			 );
		 }
		 this.changeChartTitleText(this.state.selectedFilter, "All");
	 };
 
	 render() {
		 return (
			 <div className={PIE_CHART_CSS}>
				 <Navigation />
				 {/* Start - Main Content */}
				 <div
					 style={{
						 paddingTop: 90,
						 paddingBottom: 80,
						 paddingLeft: 20,
						 paddingRight: 20,
					 }}>
					 {/* Start - RFP Heading */}
					 <div
						 className="row mt-2 mb-4 mx-0"
						 style={{
							 borderBottom: "2px solid #eeeeee",
							 paddingBottom: 9,
						 }}>
						 <div className="col pl-0">
							 <div className="float-left">
								 <span className="page-heading-rfp-dashboard">
									 RFP Dashboard
								 </span>
							 </div>
						 </div>
						 <div className="col pr-0">
							 <div className="float-right">
								 <div
									 onClick={this.redirectToCreateRFP}
									 style={{ cursor: "pointer", pointerEvents: "auto" }}>
									 <label className="create-rfp-label">
										 &nbsp;Create New RFP&nbsp;
										 <img
											 src="createCampaign.png"
											 alt=""
											 className="create-rfp-img"></img>
									 </label>
								 </div>
							 </div>
						 </div>
					 </div>
					 {/* End - RFP Heading */}
 
					 {/* Start - Advertiser and Publisher */}
					 <div className="row mx-0">
						 <div className="col padding-left-col">
							 <div style={{ border: "1px solid black", width: "fit-content" }}>
								 <Button
									 className={this.state.advertiserButtonClassName}
									 size="default"
									 style={{ borderRadius: "1px" }}
									 id="advertiser"
									 onClick={this.handleAdvPubButtonClick}>
									 Advertiser
								 </Button>
								 <Button
									 className={this.state.publisherButtonClassName}
									 size="default"
									 style={{ borderRadius: "1px" }}
									 id="publisher"
									 onClick={this.handleAdvPubButtonClick}>
									 Publishers
								 </Button>
							 </div>
						 </div>
					 </div>
					 {/* End - Advertiser and Publisher */}
 
					 {/* Start - Chart Row */}
					 <div className="row my-2 mx-0">
						 <div className="col-lg-6 col-md-12 padding-left-col md-screen-padding">
							 <div
								 className="card"
								 style={{
									 border: "0.7px solid #CCCCCC",
									 paddingLeft: 10,
									 paddingRight: 10,
								 }}>
								 <div className="card-body">
									 {/* Start - Card Heading - Showing & Dropdown */}
									 <div className="row mx-0">
										 <div className="col px-0 d-flex align-items-center">
											 <span>
												 <span
													 style={{
														 fontFamily: "Roboto",
														 color: "#71717199",
														 fontSize: "13px",
														 fontWeight: 500,
													 }}>
													 Showing results for&nbsp;&nbsp;
												 </span>
												 <span
													 style={{
														 color: "#F28C0F",
														 fontFamily: "Roboto",
														 fontSize: "12px",
														 fontWeight: 500,
													 }}>
													 "{this.state.selectedFilterLabel}"
												 </span>
											 </span>
										 </div>
										 <div className="col-sm-auto px-0 mt-sm-1 mt-md-0">
											 <div className="float-right">
												 <select
													 className="form-control"
													 style={{
														 fontSize: "11px",
														 fontFamily: "roboto",
														 border: "1.4px solid rgb(20, 76, 155)",
														 height: "inherit",
													 }}
													 onChange={this.handleSelectPieFilter}>
													 <option
														 value="Monthly"
														 name="Monthly"
														 id="Monthly"
														 label="Ending This Month">
														 Ending This Month
													 </option>
													 <option
														 value="Quarterly"
														 name="Quarterly"
														 id="Quarterly"
														 label="Ending This Quarter">
														 Ending This Quarter
													 </option>
													 <option
														 value="Yearly"
														 name="Yearly"
														 id="Yearly"
														 label="Ending This Year">
														 Ending This Year
													 </option>
													 <option
														 value="PreMonth"
														 name="PreMonth"
														 id="PreMonth"
														 label="Ending Previous Month">
														 Ending Previous Month
													 </option>
													 <option
														 value="PreQuarter"
														 name="PreQuarter"
														 id="PreQuarter"
														 label="Ending Previous Quarter">
														 Ending Previous Quarter
													 </option>
													 <option
														 value="PreYear"
														 name="PreYear"
														 id="PreYear"
														 label="Ending Previous Year">
														 Ending Previous Year
													 </option>
													 <option
														 value="dateRange"
														 name="dateRange"
														 id="dateRange"
														 label="Date Range">
														 Date Range
													 </option>
												 </select>
											 </div>
										 </div>
									 </div>
									 {/* End - Card Heading - Showing & Dropdown */}
									 <hr
										 className="my-2"
										 style={{
											 color: "lightgrey",
										 }}></hr>
 
									 {/* Start - Date Range Picker */}
									 {this.state.dateRange && (
										 <div className="row">
											 <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												 <span style={{ display: this.state.dateDisplay }}>
													 From&nbsp;
													 <input
														 type="date"
														 id="StartDate"
														 name="StartDate"
														 style={{ height: "32px" }}
														 value={this.state.startDate}
														 onChange={this.commonhandleChange}
													 />
													 <br />
													 To&nbsp;&nbsp;&nbsp;
													 <input
														 type="date"
														 id="EndDate"
														 name="EndDate"
														 style={{ height: "32px", marginLeft: "10px" }}
														 value={this.state.endDate}
														 onChange={this.commonhandleChange}
													 />
												 </span>
											 </div>
										 </div>
									 )}
									 {/* End - Date Range Picker */}
 
									 {/* Start - Main Chart */}
									 {/* rutuja -2832 added alt tag */}
									 <div>
										 {!this.state.pieChartEmpty ? (
											 <Chart
												 chartType="PieChart"
												 loader={<div>Loading Chart</div>}
												 data={this.state.pieChartData}
												 options={{
													 height: 300,
													 title: this.state.chartTitle,
													 titleTextStyle: {
														 color: "#1a1715",
														 fontName: "roboto",
														 fontSize: 15,
													 },
													 is3D: true,
													 slices: {
														 0: { color: "#39b54a" },
														 1: { color: "#007cb4" },
														 2: { color: "#ff931e" },
													 },
													 chartArea: {
														 backgroundColor: "transparent",
														 width: "100%",
														 left: 0,
													 },
													 legend: {
														 position: "labeled",
													 },
												 }}
											 />
										 ) : (
											 <img
												 src="no_data_pie_chart.png"
												 className="max-width-custom"
												 style={{
													 padding: 20,
													 height: "100%",
													 width: "-webkit-fill-available",
												 }}
												 alt=""
											 />
										 )}
									 </div>
									 {/* End - Main Chart */}
								 </div>
							 </div>
						 </div>
						 <div className="col-lg-6 col-md-12 padding-right-col md-screen-padding md-screen-margin-top">
							 <div
								 className="card"
								 style={{
									 border: "0.7px solid #CCCCCC",
									 paddingLeft: 10,
									 paddingRight: 10,
								 }}>
								 <div className="card-body">
									 {/* Start - Card Heading - Showing & Dropdown */}
									 <div className="row mx-0">
										 <div className="col px-0 d-flex align-items-center">
											 <span>
												 <span
													 style={{
														 fontFamily: "Roboto",
														 color: "#71717199",
														 fontSize: "13px",
														 fontWeight: 500,
													 }}>
													 Showing results for&nbsp;&nbsp;
												 </span>
												 <span
													 style={{
														 color: "#F28C0F",
														 fontFamily: "Roboto",
														 fontSize: "12px",
														 fontWeight: 500,
													 }}>
													 "{this.state.selectedFilterLabel}"
												 </span>
											 </span>
										 </div>
										 <div className="col-sm-auto px-0 mt-sm-1 mt-md-0">
											 <div className="float-right">
												 <select
													 className="form-control"
													 style={{
														 fontSize: "11px",
														 fontFamily: "roboto",
														 border: "1.4px solid rgb(20, 76, 155)",
														 height: "inherit",
													 }}
													 value={
														 this.state.advPubEnabledBtnID === "advertiser"
															 ? this.state.advertiserName
															 : this.state.publisherName
													 }
													 onChange={this.handleSelectBarFilter}>
													 <option value="All" name="All" id="All">
														 {this.state.advPubEnabledBtnID === "advertiser"
															 ? "Select Advertiser"
															 : "Select Publisher"}
													 </option>
													 {this.state.advPubEnabledBtnID === "advertiser"
														 ? this.state.advertiserNames.map((item, i) => (
																 <option value={item} key={i}>
																	 {item}
																 </option>
														   ))
														 : this.state.publisherNames.map((item, i) => (
																 <option value={item} key={i}>
																	 {item}
																 </option>
														   ))}
												 </select>
											 </div>
										 </div>
									 </div>
									 {/* End - Card Heading - Showing & Dropdown */}
									 <hr
										 className="my-2"
										 style={{
											 color: "lightgrey",
										 }}></hr>
									 {/* Start - Main Chart */}
									 {/* rutuja -2832 added alt tag */}
									 <div>
										 {!this.state.barChartEmpty ? (
											 <CanvasJSChart
												 options={this.state.barChartOptions}
												 onRef={(ref) => (this.chart = ref)}
											 />
										 ) : (
											 <img
												 src="no_data_bar_chart.png"
												 className="max-width-custom"
												 style={{
													 padding: 20,
													 height: "100%",
													 width: "-webkit-fill-available",
												 }}
												 alt=""
											 />
										 )}
									 </div>
									 {/* End - Main Chart */}
								 </div>
							 </div>
						 </div>
					 </div>
					 {/* End - Chart Row */}
 
					 {/* Start - Operational Heading */}
					 <div
						 className="row my-4 mx-0"
						 style={{
							 borderBottom: "2px solid #eeeeee",
							 paddingBottom: 9,
						 }}>
						 <div className="col pl-0">
							 <div className="float-left">
								 <span
									 style={{
										 fontFamily: "Roboto",
										 fontSize: "22px",
										 fontWeight: "450",
										 borderBottom: "3px solid #144c9b",
										 paddingBottom: "10px",
										 color: "rgba(0, 0, 0, 0.85)",
									 }}>
									 Operational Actions
								 </span>
							 </div>
						 </div>
					 </div>
					 {/* End - Operational Heading */}
 
					 {/* Start - Operational Actions Columns */}
					 <div className="row">
						 <div className="col-md-4 col-sm-12">
							 <Card
								 // karan-task-3636- added click functionality
								 onClick={(e) =>
									 this.handleOperationalActionClick("RFPToAllocate")
								 }
								 style={{
									 boxShadow: "rgb(208 216 243 / 60%) 1px 3px 20px 0px",
									 cursor: "pointer",
								 }}>
									{/* rutuja -2832 added alt tag */}
								 <div className="row fix-margin-row">
									 <div className="col-auto d-flex align-items-center">
										 <img src="toAllocateNew.jpg" height="45px" width="45px"
										 alt="" />
									 </div>
									 <div className="col d-flex flex-column pl-2">
										 <span
											 style={{
												 color: "#0F53A2",
												 fontSize: 32,
												 fontWeight: 500,
												 lineHeight: 1,
											 }}>
											 {this.state.rfpToAllocate}
										 </span>
										 <span
											 style={{
												 fontSize: 16,
											 }}>
											 RFP To Allocate
										 </span>
									 </div>
								 </div>
							 </Card>
						 </div>
						 <div className="col-md-4 col-sm-12 mt-md-0 mt-sm-2">
							 <Card
								 // karan-task-3636- added click functionality
								 onClick={(e) =>
									 this.handleOperationalActionClick("rfpToRespond")
								 }
								 style={{
									 boxShadow: "rgb(208 216 243 / 60%) 1px 3px 20px 0px",
									 cursor: "pointer",
								 }}>
									{/* rutuja -2832 added alt tag */}
								 <div className="row fix-margin-row">
									 <div className="col-auto d-flex align-items-center">
										 <img src="campRespond.png" height="45px" width="45px" 
										 alt=""/>
									 </div>
									 <div className="col d-flex flex-column pl-2">
										 <span
											 style={{
												 color: "#0F53A2",
												 fontSize: 32,
												 fontWeight: 500,
												 lineHeight: 1,
											 }}>
											 {this.state.rfpToRespond}
										 </span>
										 <span
											 style={{
												 fontSize: 16,
											 }}>
											 RFP To Respond
										 </span>
									 </div>
								 </div>
							 </Card>
						 </div>
						 <div className="col-md-4 col-sm-12 mt-md-0 mt-sm-2">
							 <Card
								 // karan-task-3636- added click functionality
								 onClick={(e) =>
									 this.handleOperationalActionClick("CampaignToCreate")
								 }
								 style={{
									 boxShadow: "rgb(208 216 243 / 60%) 1px 3px 20px 0px",
									 cursor: "pointer",
								 }}>
								{/* rutuja -2832 added alt tag */}
								 <div className="row fix-margin-row">
									 <div className="col-auto d-flex align-items-center">
										 <img src="toAllocateNew.jpg" height="45px" width="45px"
										 alt="" />
									 </div>
									 <div className="col d-flex flex-column pl-2">
										 <span
											 style={{
												 color: "#0F53A2",
												 fontSize: 32,
												 fontWeight: 500,
												 lineHeight: 1,
											 }}>
											 {this.state.campignToCreate}
										 </span>
										 <span
											 style={{
												 fontSize: 16,
											 }}>
											 Campaign To Create
										 </span>
									 </div>
								 </div>
							 </Card>
						 </div>
					 </div>
					 {/* End - Operational Actions Columns */}
				 </div>
				 {/* End - Main Content */}
				 <Footer />
			 </div>
		 );
	 }
 }
 
 /**
  * @author Karan Jagtap
  * @param  Description handle the login authentication
  * @return Description return All details of authentication
  */
 RFPDashboard.propTypes = {
	 logoutUser: PropTypes.func.isRequired,
	 auth: PropTypes.object.isRequired,
 };
 
 const mapStateToProps = (state) => ({
	 auth: state.auth,
 });
 
 export default connect(mapStateToProps, { logoutUser })(
	 withRouter(RFPDashboard)
 );
 