/* Copyright(c) 2023 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/**
 * @file LeadFileTrackingReport 
 * @author  Aman Shaikh - 4660
 * @version 1.0
 * @section DESCRIPTION Lead File Tracking Report Page 
 */

import React from "react";
import Navigation from "../layouts/MBAdminNavPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import "./LeadFileTrackingReport.css"
import { Icon, Table, Tooltip} from 'antd';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import { saveAs } from "file-saver";
import { css } from "emotion";

const xlsx = require("xlsx");
const Swal = require('sweetalert2');

const tableCSS = css({
  backgroundColor: 'white',
  fontFamily: "sans-serif",
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize: '12px !important',
    fontWeight: '500',
  },
  '& thead > tr >th': {
    textAlign: "center !important",
    color: 'white',
  }
});

class LeadFileTrackingReport extends React.Component {
  constructor() {
    super();
    this.state = {
      LeadFileTrackingDetails: [],
      allUserNames: [],
      UserName: [],
      selectedUser: '',
      selectedDate: '',
      sevenDayDate: '',
      orgID: '',
      loader_progress: "",
      tableState: { size: "small" },
    }
    this.NameHandleChange = this.NameHandleChange.bind(this);
    this.dateHandleChange = this.dateHandleChange.bind(this);
    this.searchHandleChange = this.searchHandleChange.bind(this);
    this.handledownloadLeadTrackingReport = this.handledownloadLeadTrackingReport.bind(this);
  }
 //Aman-4660 -MBAdmin-(to get UserName and set)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
  NameHandleChange(e) {
    e.preventDefault();
    this.setState({ selectedUser : this.state.UserName[e.target.value]});
  }
   //Aman-4660 -MBAdmin-(to get Date and set)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
  dateHandleChange(e){
    e.preventDefault();
       // Get the selected date from a form input or other source
       var selectedDate = e.target.value;
       var sevenDayPreviousDate = sevenDayPreviousDate(selectedDate)

      function sevenDayPreviousDate(selectedDate){
        let d = new Date(selectedDate);
        d.setDate(d.getDate() - 7);
        return d.toISOString().split('T')[0];
      };
      this.setState({ sevenDayDate : sevenDayPreviousDate,selectedDate:selectedDate}); 
  }
   //Aman-4660 -MBAdmin-(this will search the data on the bases of name and date)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
  searchHandleChange(e) {
      let selectedUser = this.state.selectedUser;
      let selectedDate = this.state.selectedDate;     
      
      if((selectedUser && selectedUser !== "Select User") && (selectedUser !== "undefined" || undefined || null || "null" || "")
       && (selectedDate && selectedDate !== "")){ 
        let finalData = this.state.allUserNames.filter((element) => {
          return element.firstName == selectedUser.firstName && element.lastName == selectedUser.lastName; //Nilesh-4980- fetching the lastname data from element
        });
        this.setState({ loader_progress: "loader_campaign_list" });
        let data = {
          finalData: finalData,
          selectedDate: selectedDate,
        }
        fetch("/admin/LeadFileTrackingReportData", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(data)
        }).then(res => res.json())
          .then(LeadFileTrackingData => {
            this.setState({ loader_progress: "" });
          if(LeadFileTrackingData.length>0){   
            this.setState({ LeadFileTrackingDetails: LeadFileTrackingData, })
          }else{
              Swal.fire({
                  type: "warning",
                  title: "No records Found",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  preConfirm: () => {
                    window.location.reload();
                  },
            });
          }
        }).catch(function (err) { console.log(err) });
      }else{
        Swal.fire({
          type: "warning",
          title: "Please Select All Mandatory Fields",
          confirmButtonText: "Ok",
          allowOutsideClick: false
        });
        this.setState({ loader_progress: "" });
      }
  }
 //Aman-4660 -MBAdmin-(This will generate the EXCEL file)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
  handledownloadLeadTrackingReport(){
    let LeadFileTrackingDetails = this.state.LeadFileTrackingDetails;
    let selectedUser = this.state.selectedUser;

    if (LeadFileTrackingDetails.length > 0) {
        var ws = xlsx.utils.json_to_sheet(LeadFileTrackingDetails);
        var wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Lead");
        var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); 

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (let i = 0; i !== s.length; ++i)
                view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }

        var fileName = "Lead File Tracking Report-"+selectedUser.firstName+""+selectedUser.lastName+"-"+this.state.selectedDate+".xlsx";
        saveAs(
            new Blob([s2ab(buf)], { type: "application/octet-stream" }),
            fileName
        );
    }
  }
  //Aman-4660 -MBAdmin-(This will get the User Name from Route)eport - Lead File Tracking Report- DMA's Upload lead file count and file details
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
      const { isAuthenticated } = this.props.auth;
      var reportType = this.state.reportType;
      this.setState({ loader: "loader_report" })
      let data = {
        type: reportType
      };
      fetch("/admin/allPcPncUserDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      }).then((res) => res.json())
        .then((UserNameDetails) => {
          // alert("UserNameDetails -->" + JSON.stringify(UserNameDetails))
          this.setState({ allUserNames: UserNameDetails});
          let userNameArray = [];
          if(UserNameDetails.length>0)
          {
            for(let i=0;i<UserNameDetails.length;i++){
              userNameArray.push({firstName:UserNameDetails[i].firstName,lastName:UserNameDetails[i].lastName});
            }
            userNameArray = Array.from(
              new Set(
                userNameArray.map((el) =>
                  JSON.stringify(el)
                )
              )
            ).map((el) => JSON.parse(el));
          }
          this.setState({ UserName: userNameArray})
        }).catch(function (err) { console.log(err) ;});
    }
  }
  
  render() {
    const { isAuthenticated, user } = this.props.auth;
    const asterisk = { color: 'red' }

    const columnsReport = [
      {
          title: <span>Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
          dataIndex: "Date",
          key: "Date",
          width: "15%",
          align: "left",
          sorter: (a, b) => {
            const asc = "asc";
            if (a.created < b.created) {
                return asc ? -1 : 1;
            } else if (a.created > b.created) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
          },
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                      {record.created}
                  </span>
                  <br />
              </div>
          ),
      },
      {
          title: <span>Agency Camp ID&nbsp;&nbsp;&nbsp;</span>,
          dataIndex: "clientCampID",
          key: "clientCampID",
          width: "15%",
          align: "left",
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                      {record.clientCampID}
                  </span>
                  <br />
              </div>
          ),
      },
      {
          title: <span>CampaignID&nbsp;&nbsp;&nbsp;</span>,
          dataIndex: "campID",
          key: "campID",
          width: "10%",
          align: "left",
          render: (text, record) => (
              <div>
                  <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                      {record.campID}
                  </span>
                  <br />
              </div>
          ),
      },
      {
        title: <span>File Name&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "fileName",
        key: "fileName",
        width: "40%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.fileName}
                </span>
                <br />
            </div>
        ),
      },
      {
        title: <span>Lead Count&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "leadCount",
        key: "leadCount",
        width: "15%",
        align: "left",
        render: (text, record) => (
            <div>
                <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
                    {record.leadCount}
                </span>
                <br />
            </div>

        ),
      },
    ]

    return (
    <div>
      <Navigation />
        <div class="container-fluid" style={{ paddingTop: '100px' }}>

        {/* heading section */}
          <div class="row">
                  {/* <div class="col-lg- col-md-3 col-sm-3">
                  <a
                        onClick={() =>
                          this.props.history.push("/MBAdminDashboard", {
                            agencyID: this.state.agencyID,
                          })
                        } //karan-task-3684-replace query params
                        style={{ color: "#056eb8" }}>
                        <FaArrowAltCircleLeft
                          size={32}
                          style={{ float: "left" }}
                          title="Back to Dashboard"
                        />
                      </a>
                  </div> */}
                  
            <div class="offset-lg-2 offset-sm-2 col-lg-8 col-md-8 col-sm-8" align="center">
              <center><label id="labelDI" style={{ fontSize: '28px', fontStyle: 'roboto', fontWeight: '500', color: '#193D8F' }} align="center">Lead File Tracking Report</label></center>
            </div>
          </div>
          
        <div className="Main-body">  
          <div class="container-fluid" style={{ paddingTop: '10px', marginBottom: '20px' }}>
            <div className="card card-signin my-1">
              <div className="card-body">

                {/* Filter section */}          
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-5" id="AD_ALTD">
                      <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Select User&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;</label><br />
                        <select  
                              style={{ width: '220px', height: "40px", border: '1px solid #cccccc', backgroundColor: "#FFFFFF", borderRadius: '3px', fontFamily: 'sans-serif', color: '#555555' }} 
                              onChange={this.NameHandleChange}
                              required>
                            <option value={"Select Publisher"} 
                              >
                                Select User</option>
                                {this.state.UserName.map((user, i) => (
                                  <option value={i} key={i}
                                  style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }}>
                                  {user.firstName} {user.lastName}</option>
                                ))}
                        </select>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-5 cl-LeadTracking" id="AD_ALTD">
                      <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Select Date&nbsp;<span style={asterisk}>*</span>&nbsp;</label>
                      <Tooltip
                            title="Result will display previous 7 Days from selected date"
                            placement="right"
                          >
                            <Icon type="question-circle" style={{ fontSize: 12 }} />
                          </Tooltip>
                      <br />
                        <input
                          style={{ width: '220px', border: "1px solid #CCCCCC", borderRadius: "3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: "#555555" }}
                          type="date" pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                              value={this.state.selectedDate}
                              className={this.state.inputClassSdate}
                              onChange={this.dateHandleChange}
                              required
                        />  
                      <br />
                      {this.state.selectedDate == "" ?
                        (""):
                        (
                        <p><b style={{color: "green" }}>{this.state.sevenDayDate}</b> to <b style={{color: "green" }}>{this.state.selectedDate}</b>
                        {/* Aman-4683-(Change the place start to end)-Prod_Issue_R-30.5--Wrong entry in userID column in DB while uploading leads. */}
                        </p>
                        )
                      }
                    </div>
                    <div className="col-lg-2" id="AD_ALTD"> 
                    <br/>           
                          <button
                            type="button"
                            class="btn add-button"
                            onClick={this.searchHandleChange}>
                            Search
                          </button>
                    </div>      
                </div> 

                <div align="center"  id={this.state.loader_progress}></div>  {/* Aman-4660 -MBAdmin-(Loader)eport - Lead File Tracking Report- DMA's Upload lead file count and file details */}

                  {this.state.LeadFileTrackingDetails.length > 0 ? 
                      <div> 
                       <hr /> 
                        <p style={{ marginBottom: "10px"}}>
                          <br />
                              <Table
                                {...this.state.tableState}
                                bordered
                                dataSource={this.state.LeadFileTrackingDetails}
                                columns={columnsReport}
                                className={`${tableCSS} "ant-table-thead table-responsive"`}
                                scroll={{ y: 250, x: 800 }}
                                pagination={false} 
                                >
                              </Table>
                        </p>
                            <button class="btn add-button" 
                            style={{float: "right" }} 
                            title="Export the data in excel file"
                            value={this.state.clientLeadDetails}
                            onClick={this.handledownloadLeadTrackingReport}
                            >Export</button>
                      </div> 
                : " "}
              </div>
            </div>
          </div>
        </div>
                        
      </div>
      <Footer />
    </div>
    );
  }
}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
LeadFileTrackingReport.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(LeadFileTrackingReport));

