/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sunita Landge
@file Name:campaignRespond.js
@Description: Campaign Response
*/

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../login/authentication";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import { Button, Badge } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaArrowAltCircleLeft } from "react-icons/fa";
// import { Modal } from "react-bootstrap";//kiran-4745-removing console warning-no used
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header
const europeRegion = [
	"Eastern Europe",
	"Northern Europe",
	"Southern Europe",
	"Western Europe",
];
var tempCampID;
var regionArray = [];
var total;
var result = [];
var displayresult = [];
var displaycpl = [];
var displayallocatedLead = [];
var publisherCount = 0;
const Swal = require("sweetalert2");
// var region;
class CampaignRespond extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agencyID: "",
			headExample: "head-example",
			headExample1: "head-exampleGeneral1",
			biddingArray: [],
			loading1: true,
			campLableName: "",
			counteredCampLength: "",
			rejectedCampLength: "",
			liveCampaignList: [],
			counterCampID: [],
			errors: {},
			puberror: "",
			allocerrors: "",
			reerrors: "",
			dateerrors: "",
			campaignDetailID: [],
			region: false,
			showPubInCounterAllocate: [],
			mergedShowPubInCounterAllocate: [],
			mergedCampaignDetailAssign: [],
			status: "",
			toStep: 1,
			stateCopy: [
				{
					allocatedLead: "",
					cpl: "",
				},
			],
			campCount: [],
			gdprStatus: "false",
			alertDisplay: "none",
			alertBorder: "",
			show: false,
			success: "",
			publisherCampCPL: [],
			dynamic: [],
			test: [],
			toolTipData: [],
			newGDPRPubArray: [],
			counterGDPRPublisher: [],
			gdprPublisher: [],
			campID: " ",
			id: "",
			campIDTemp: " ",
			leadAllocation: 0,
			newDynamicArray: [],
			counterDataArray: [],
			sumOfAllocatedLeads: [],
			campaignDetailAssign: [],
			// liveCampaignList: [],
			campaignName: "",

			// alertDisplay: "none",
			// alertBorder: "",
			// show: false,
			// success: "",//kiran-4745-removing console warning-no used
			token: "", // shivani-task 2993-declare token to set token for API authentication to pass in every fetch header.
			counterpID: "",
			parentCampID: "",
			reallocationID: "",
			mergedGdprPublisher: [],
			newCampaignDetailID: [],
			defaultCampID: "",
			rejectReason: "",
			// campTab:"counter"
			// clearRejectReason:''
		}; //end of state

		this.biddingLink = this.biddingLink.bind(this);
		this.campHandleChange = this.campHandleChange.bind(this);
		this.handleCounterAllocateID = this.handleCounterAllocateID.bind(this);
		this.submitCounterGDPRPublisher =
			this.submitCounterGDPRPublisher.bind(this);
		this.submitGDPRPublisher = this.submitGDPRPublisher.bind(this);
		this.submitCounterPublisher = this.submitCounterPublisher.bind(this);
		this.submitPublisher = this.submitPublisher.bind(this);
		this.isCounterGDPRPUB = this.isCounterGDPRPUB.bind(this);
		this.handleChangeGDPRPublisher = this.handleChangeGDPRPublisher.bind(this);
		this.handleChangeDynamicCounterPublisher =
			this.handleChangeDynamicCounterPublisher.bind(this);
		this.rejectCounterCampaign = this.rejectCounterCampaign.bind(this);
		this.acceptCounterCampaign = this.acceptCounterCampaign.bind(this);
		this.handleCampaignIDOnClickAccept =
			this.handleCampaignIDOnClickAccept.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.isGDPRPUB = this.isGDPRPUB.bind(this);
		this.handleCampaignID = this.handleCampaignID.bind(this);
		this.handleChangeDynamicPublisher =
			this.handleChangeDynamicPublisher.bind(this);
		this.handleEditCampaign = this.handleEditCampaign.bind(this);
		this.cancelButton = this.cancelButton.bind(this);
	} //end of constructor

	biddingLink(e) {
		// alert("hi");
		e.preventDefault();
		// const { user } = this.props.auth;
		// var agencyID = user.id;

		if (e.target.id === "reject") {
			// var tabReviewReject = "Reject"; //kiran-4745-removing console warning-no used
			fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((notifyDashNewCamp) => {
					// alert("abcd==>"+JSON.stringify(notifyDashNewCamp[2]));
					this.setState({
						biddingArray: notifyDashNewCamp[2],
						headExample1: "head-example",
						headExample: "head-exampleGeneral1",
						loading1: false,
						campLableName: "Rejected Campaign",
						rejectedCampLength: notifyDashNewCamp[2].length,
					});
					// alert("notifyDashNewCampLength2==>"+notifyDashNewCamp[2].length);
					let self = this;
					let campID = notifyDashNewCamp[2][0].campID;
					//  alert("campID"+campID);

					fetch("agency/campaignAllocated?campID=" + campID)
						.then((res) => res.json())
						.then((liveCampaignList) => {
							self.setState({
								liveCampaignList: liveCampaignList,
								campID: campID,
							});
							// alert("livecamplist2==>"+JSON.stringify(liveCampaignList));
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			//getting Campaign ID which status is New, Counter, Reject
			// var tabReviewCounter = "Counter";//kiran-4745-removing console warning-no used
			fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((notifyDashNewCamp) => {
					//  alert("campID"+JSON.stringify(notifyDashNewCamp));
					this.setState({
						biddingArray: notifyDashNewCamp[1],
						headExample: "head-example",
						headExample1: "head-exampleGeneral1",
						loading1: false,
						campLableName: "Countered Campaign",
						counteredCampLength: notifyDashNewCamp[1].length,
					});
					// alert("notifyDashNewCampLength1==>"+notifyDashNewCamp[1].length);

					let self = this;
					let campID = notifyDashNewCamp[1][0].campID;
					//  alert("campID"+campID);

					fetch("agency/campaignAllocated?campID=" + campID)
						.then((res) => res.json())
						.then((liveCampaignList) => {
							self.setState({
								liveCampaignList: liveCampaignList,
								campID: campID,
							});
							// alert("livecamplist2==>"+JSON.stringify(liveCampaignList));
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} //end of biddingLink

	campHandleChange(e) {
		e.preventDefault();
		// debugger
		let self = this;
		var campID = e.target.id;
		// alert("campID"+campID);
		var pacing = localStorage.getItem("pacingALT");
		const { user } = this.props.auth;//kiran-4745-removing console warning-no used
		var agencyID = user.id;
		this.setState({ agencyID: agencyID });
		// alert(agencyID);
		fetch("publisher/toolTipData")
			.then((res) => res.json())
			.then((toolTipData) => {
				self.setState({ toolTipData: toolTipData });
				// alert("tooltipdata===>"+JSON.stringify(toolTipData));
				console.log("tooltipdata===>" + JSON.stringify(toolTipData));
			})
			.catch(function (err) {
				console.log(err);
			});
//kiran-4745-removing console warning-changed == to ===
		if (
			(campID === undefined && pacing === undefined) ||
			(campID === "" && pacing === "") ||
			(campID == null && pacing == null)
		) {
			fetch("publisher/allocatedCampaign?agencyID=" + agencyID)
				.then((res) => res.json())
				.then((liveCampaignList) => {
					// alert("liveCampaignList1"+JSON.stringify(liveCampaignList))
					//  console.log("liveCampaignList1"+JSON.stringify(liveCampaignList));
					self.setState({ liveCampaignList: liveCampaignList });
				})
				.catch(function (err) {
					console.log(err);
				});
			fetch("publisher/campaignCount?agencyID=" + agencyID)
				.then((res) => res.json())
				.then((campCount) => this.setState({ campCount: campCount }))
				.catch(function (err) {
					console.log(err);
				});
		} //end Of if Statement which load by default if campID undifined
		else {
			// if user Will Came from directly DashBoard with CampID For Counter and Reject Counter Action

			fetch("agency/campaignAllocated?campID=" + campID)
				.then((res) => res.json())
				.then((liveCampaignList) => {
					self.setState({ liveCampaignList: liveCampaignList, campID: campID });
					//  alert("livecamplist2==>"+JSON.stringify(liveCampaignList));
					//  console.log("livecamplist2==>"+JSON.stringify(liveCampaignList));
				})
				.catch(function (err) {
					console.log(err);
				});

			localStorage.removeItem("counterID");
		}
	}

	/**
	 * @author Sunita Landge
	 * @param  Description  Counter allocation popup
	 * @return Description return Publisher and Campaign Data to show in Popup
	 */

	handleCounterAllocateID(e) {
		// counter Allocation Show Popup
		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampID");
		var reallocationID = e.target.getAttribute("reallocationID");
		var pID = e.target.getAttribute("name");
		this.setState({ counterpID: pID, parentCampID, reallocationID });
		// alert(this.state.gdprStatus);
		tempCampID = campID;

		var emptyArray = [];
		this.setState({
			publisherCampCPL: emptyArray,
			showPubInCounterAllocate: emptyArray,
			mergedShowPubInCounterAllocate: emptyArray,
		});

		let self = this;
		fetch("/publisher/allocatingCounterCampID?campID=" + campID + "")
			.then((res) => res.json())
			.then((counterCampID) => {
				//  alert("counterCampId===>"+JSON.stringify(counterCampID));
				this.setState({ counterCampID: counterCampID });
				if (this.state.counterCampID && this.state.counterCampID.length) {
					var region = this.state.counterCampID[0].region;
					//   if(region=='Europe'){
					//     this.setState({gdprStatus:'true'});
					//   }
					//  this.setState({region:region});
					// alert("region\t"+region);
					// var region= this.state.campaignDetailID[0].region;
					var str_array = region.split(",");
					for (let i = 0; i < str_array.length; i++) {
						regionArray.push(str_array[i]);
					}
					for (let j = 0; j < regionArray.length; j++) {
						//kiran-4745-removing console warning-changed == to ===
						if (
							regionArray[j] === "Europe" ||
							europeRegion.includes(regionArray[j])
						) {
							this.setState({ gdprStatus: "true", region: true });
						}
					}
				}
			});

		let data = { campID: campID };
		/**
		 * @author Sunita Landge
		 * @param  Description  use in allocating leads to publisher for checking status and view CPL edditable or not
		 * @return Description return CPL and publisher ID and status
		 */

		total = this.state.campaignDetailID.leadAllocation;
		//showPubInCounterAllocate only difference in fetch API From Backend in Query
		fetch("publisher/showPubInCounterAllocate?campID=" + campID + "")
			.then((res) => res.json())
			.then((showPubInCounterAllocate) => {
				//  alert("Showpubincounter==="+JSON.stringify(showPubInCounterAllocate));
				self.setState({ showPubInCounterAllocate: showPubInCounterAllocate });

				fetch("agency/publisherCampaignCPL", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
				}) // karan-task-3714-vapt header and query params
					.then((res) => res.json())
					.then((publisherCampCPL) => {
						//  alert("GDPR uuuuu="+JSON.stringify(publisherCampCPL));
						this.setState({ publisherCampCPL: publisherCampCPL });
						if (this.state.publisherCampCPL.length > 0) {
							this.state.counterDataArray =
								this.state.showPubInCounterAllocate.map((e) =>
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
								);
						} else {
							this.state.counterDataArray =
								this.state.showPubInCounterAllocate.map((e) =>
									Object.assign(
										{},
										e,
										this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}
									)
								);
						}

						//alert("counterDataArray NON-Gdpr===="+JSON.stringify(this.state.counterDataArray));
						const result = Array.from(
							new Set(this.state.showPubInCounterAllocate.map((p) => p.pID))
						).map((pID) => {
							return {
								pID: pID,
								publisherName: this.state.showPubInCounterAllocate.find(
									(p) => p.pID === pID
								).publisherName,
								startDate: this.state.showPubInCounterAllocate.find(
									(p) => p.pID === pID
								).startDate,
								endDate: this.state.showPubInCounterAllocate.find(
									(p) => p.pID === pID
								).endDate,
								allocatedLead: this.state.showPubInCounterAllocate.find(
									(p) => p.pID === pID
								).allocatedLead,
								cpl:
									this.state.showPubInCounterAllocate.find((p) => p.pID === pID)
										.cpl != null
										? this.state.showPubInCounterAllocate.find(
												(p) => p.pID === pID
										  ).cpl
										: this.state.showPubInCounterAllocate.find(
												(p) => p.pID === pID
										  ).campCPL,
								firstLeadDeliveryDate: this.state.showPubInCounterAllocate.find(
									(p) => p.pID === pID
								).firstLeadDeliveryDate,
								rating: this.state.showPubInCounterAllocate.find(
									(p) => p.pID === pID
								).rating,
								gdprCompliance: this.state.showPubInCounterAllocate.find(
									(p) => p.pID === pID
								).gdprCompliance,
							};
						});
						// alert("Array==="+JSON.stringify(result));

						let mergedShowPubInCounterAllocate = [];
						// let liveDeliveryStatusListTemp = this.state.sumOfAllocatedLeads;
						mergedShowPubInCounterAllocate = result.map((e) =>
							Object.assign(
								{},
								e,
								this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) || {}
							)
						);

						this.setState({
							mergedShowPubInCounterAllocate: mergedShowPubInCounterAllocate,
						});
						// alert("Data in array=="+JSON.stringify(this.state.mergedShowPubInCounterAllocate));
					})
					.catch(function (err) {
						console.log(err);
					});
			})
			.catch(function (err) {
				console.log(err);
			});
	} //end of handleCounterAllocateID

	submitGDPRPublisher(e) {
		// alert("at gdpr")
		/******* This for Rejected Counter */
		// alert("submitGDPRPublisher  reject yes/no");
		e.preventDefault();
		let errors = {};
		let spanDisplay = "none";
		// let fields = this.state.fields;
		let formIsValid = false;
		let date = false;
		let cpl = false;
		let lead = false;
		// let success = {};
		let puberror = {};
		let valid = false;
		let rebudget = false;
		let allocerrors = {};
		let reerrors = {};
		let dateerrors = {};
		// let r1 = []; //kiran-4745-removing console warning-no used

		result = Array.from(
			new Set(this.state.newGDPRPubArray.map((p) => p.pID))
		).map((pID) => {
			return {
				pID: pID,
				publisherName: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.publisherName,
				startDate: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.startDate,
				endDate: this.state.newGDPRPubArray.find((p) => p.pID === pID).endDate,
				allocatedLead: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.allocatedLead,
				cpl:
					this.state.newGDPRPubArray.find((p) => p.pID === pID).cpl != null
						? this.state.newGDPRPubArray.find((p) => p.pID === pID).cpl
						: this.state.newGDPRPubArray.find((p) => p.pID === pID).campCPL,
				firstLeadDeliveryDate: this.state.newGDPRPubArray.find(
					(p) => p.pID === pID
				).firstLeadDeliveryDate,
			};
		});
		console.warn(
			"New newGDPRPubArray Array of the created after ====>" +
				JSON.stringify(result)
		);
		var totalLead = document.getElementById("totalLead").value;
		// alert("Total Leadddd="+totalLead);
		var totalAllocate = document.getElementById("totalAllocate").value;
		// alert("Total Allocate="+totalAllocate);
		var totalBudget = document.getElementById("totalBudget").value;
		//  alert("Total budget="+totalBudget);
		var totalRemainingBudget = document.getElementById(
			"totalRemainingBudget"
		).value;
		//  alert("Total remaining budget="+totalRemainingBudget);
		var totalstartDate = document.getElementById("totalstartDate").value;
		// alert("Campaign start date="+totalstartDate);
		var totalendDate = document.getElementById("totalendDate").value;
		//  alert("Campaign end date="+totalendDate);
		// var totalPending = document.getElementById("totalPending").value;//kiran-4745-removing console warning-no used
		//  alert("pending Leads="+totalPending);
		// alert("length"+result.length);
		var RejectedLead = document.getElementById("RejectedLead").value;
		//  alert(RejectedLead)

		var sum = 0;
		var num;
		var multiply = 0;
		var num1;

		var num2;
		var addmulti = 0;

		var add = totalLead - totalAllocate;
		var dynamiclength = result.length;
		//  alert("dynamiclength===>"+dynamiclength);
		for (var i = 0; i < dynamiclength; i++) {
			//alert("Allocation and CPL===>"+result[i].allocatedLead +"====="+result[i].cpl)
//kiran-4745-removing console warning-changed == to ===
			if (
				result[i].allocatedLead === "" ||
				result[i].allocatedLead === undefined ||
				result[i].allocatedLead == null
			) {
				if (publisherCount === 0) {
					//  alert("Please enter information for atleast one publisher")
					puberror["one"] = (
						<li>
							Please enter valid lead allocation for atleast one publisher
						</li>
					);

					this.setState({
						puberror: puberror,
						allocerrors: "",
						errors: errors,
						newsuccess: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				}
			} else {
				//alert("There is one publisher present");
				publisherCount++;
				//alert("publisherCount===>"+publisherCount);
				if (result[i].startDate <= result[i].endDate) {
					date = true;
					displayresult.splice(i, 1);
					// alert("valid for date");
				} else {
					date = false;
					displayresult[i] = result[i].publisherName;
					//alert("invalid date");
					errors["endDate"] =
						"Please enter valid end date for " + displayresult;
					this.setState({
						newsuccess: "",
						puberror: "",
						dateerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					// alert( displayresult[i]);
					return;
				}

				if (result[i].endDate > totalendDate) {
					// alert("campaign date is greater than pub");
					date = false;
					displayresult[i] = result[i].publisherName;
					// alert("invalid date");
					dateerrors["date"] =
						"Allocation end date cannot be greater than campaign end  date for " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("can allocate");
					date = true;
				}

				if (result[i].startDate < totalstartDate) {
					// alert("campaign start date is greater than pub");
					date = false;
					displayresult[i] = result[i].publisherName;
					// formIsValid = false;
					// alert("invalid date");
					dateerrors["date"] =
						"Allocation start date cannot be less than campaign start date for " +
						displayresult[i];
					// alert(JSON.stringify(displayresult[i]))
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("can allocate");
					date = true;
				}

				if (result[i].firstLeadDeliveryDate > result[i].endDate) {
					// alert("first lead date must be less than end date");
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"First lead delivery date can't be greater than end date " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].firstLeadDeliveryDate < result[i].startDate) {
					date = false;
					displayresult[i] = result[i].publisherName;

					dateerrors["date"] =
						"First lead delivery date can't be less than start date " +
						displayresult[i];

					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				var pattern = new RegExp(/^[1-9]+[0-9]*$/);
				if (!pattern.test(result[i].allocatedLead)) {
					// alert("before"+(result[i].allocatedLead)+"==="+result[i].publisherName)
					// alert("enter only number lead"+result[i].publisherName)
					lead = false;
					displayallocatedLead[i] = result[i].publisherName;
					errors["allocatedLead"] =
						"Please enter allocation in number for  " + displayallocatedLead;

					displayallocatedLead = [displayallocatedLead[i]];
					// var a = displayallocatedLead.includes("displayallocatedLeads[i]");//kiran-4745-removing console warning-no used

					this.setState({
						newsuccess: "",
						puberror: "",
						errors: errors,
						dateerrors: "",
						allocerrors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					return;
				} else {
					lead = true;
					num = parseInt(result[i].allocatedLead);

					// alert("Number:"+num);
					// alert("Sum before:"+sum);
					sum += num;
					// alert("Sum:"+sum);

					displayallocatedLead.splice(i, 1);

					//  formIsValid = true;
					// alert("true for lead");
				}

				/**validations for cpl */

				// cpl validations
				var patterndecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/); //decimal
				// var patternnonzero = new RegExp(/^[1-9]+[0-9]*$/); //numbers except zero//kiran-4745-removing console warning-no used
				var testval1 = result[i].cpl;
				// alert("cpl==>"+testval1);

				// var testval = parseInt(testval1);//kiran-4745-removing console warning-no used
				// alert("CPL only value parse int===>"+testval);

				if (testval1 === "" || testval1 == null || testval1 === undefined) {
					cpl = false;
					displaycpl[i] = result[i].publisherName;
					// alert("enter cpl"+displaycpl[i])
					errors["cpl"] =
						"Please check CPL value. CPL should follow below criteria for " +
						displaycpl[i];

					errors["cpl1"] = <li>CPL should be only number.</li>;
					errors["cpl2"] = <li>CPL should be greater than zero.</li>;
					errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

					// displaycpl= [displaycpl[i]];
					// alert("new dispay==>"+displaycpl)
					// var b=  displaycpl.includes("displaycpl[i]");
					// alert("b1==>"+b)
					this.setState({
						dateerrors: "",
						newsuccess: "",
						puberror: "",
						allocerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("has some value")
					if (testval1 === 0) {//kiran-4745-removing console warning-changed == to ===
						//  alert("has zero value")
						cpl = false;
						displaycpl[i] = result[i].publisherName;
						//  alert("enter cpl"+displaycpl[i]);
						errors["cpl"] =
							"Please check CPL value. CPL should follow below criteria for " +
							displaycpl[i];

						errors["cpl1"] = <li>CPL should be only number.</li>;
						errors["cpl2"] = <li>CPL should be greater than zero.</li>;
						errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

						//  displaycpl= [displaycpl[i]];
						//  var b=  displaycpl.includes("displaycpl[i]");
						//  alert("b2==>"+b)
						this.setState({
							dateerrors: "",
							newsuccess: "",
							puberror: "",
							allocerrors: "",
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						if (patterndecimal.test(testval1)) {
							// alert("valid")
							cpl = true;
						} else {
							// alert("invalid")
							cpl = false;
							displaycpl[i] = result[i].publisherName;
							// alert("enter cpl"+displaycpl[i])
							errors["cpl"] =
								"Please check CPL value. CPL should follow below criteria for " +
								displaycpl[i];

							errors["cpl1"] = <li>CPL should be only number.</li>;
							errors["cpl2"] = <li>CPL should be greater than zero.</li>;
							errors["cpl3"] = (
								<li>CPL value allowed only 2 decimal places.</li>
							);

							//           displaycpl= [displaycpl[i]];
							//           var b=  displaycpl.includes("displaycpl[i]");
							//  alert("b3==>"+b)
							this.setState({
								dateerrors: "",
								newsuccess: "",
								puberror: "",
								allocerrors: "",
								errors: errors,
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});
							return;
						}
					}
				}

				// end of cpl validations

				//validation for budget
				num1 = parseInt(result[i].allocatedLead);
				num2 = parseFloat(result[i].cpl);
				// alert(" before multiplication:"+multiply);
				// alert("lead"+num1);
				// alert("cpl"+num2);

				multiply = num1 * num2;
				// alert("after  multiplication:"+multiply);

				// alert("before addition of multiplication"+addmulti);
				addmulti += multiply;
				// alert("after addition of multiplication"+addmulti);

				// alert("budget remain==="+totalRemainingBudget)
				//kiran-4745-removing console warning-changed == to ===
				if (totalRemainingBudget === "") {
					if (addmulti > totalBudget) {
						rebudget = false;
						// alert("greater than total budget");
						reerrors["remaining"] = (
							<li>Allocated budget cannot be greater than total budget</li>
						);
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							newsuccess: "",
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						// alert("less than budget can allocate")
					}
				} else {
					if (addmulti > totalRemainingBudget) {
						rebudget = false;
						// alert("greater than total budget");
						reerrors["remaining"] = (
							<li>Allocated budget cannot be greater than total budget</li>
						);
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						// alert("less than budget can allocate")
					}
				}
				add = totalLead - totalAllocate;
				//  alert("total leads==>"+total+"sum==>"+sum)
				if (sum > add) {
					valid = false;
					// alert("not allowed");
					allocerrors["allocate"] = (
						<li>Allocated leads cannot be greater than total leads</li>
					);
					this.setState({
						puberror: "",
						allocerrors: allocerrors,
						reerrors: "",
						newsuccess: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
				} else {
					valid = true;
					//  alert("allowed3");
				}
				// alert("total leads==>"+RejectedLead+"sum==>"+sum)
				if (sum > RejectedLead) {
					errors["reject2"] = (
						<li>Allocated Lead cannot be greater then Rejected Leads</li>
					);
					puberror["one"] = "";
					formIsValid = false;
					this.setState({
						puberror: "",
						reerrors: "",
						newsuccess: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
				} else {
				}
			} // end of else

			// alert("total lead==="+this.state.campaignDetailID.leadAllocation);
			// alert("total allocate==="+this.state.campaignDetailID.allocatedLead)
			//  alert("total budget==="+this.state.campaignDetailID.budget)
			//  alert("total Remaining budget==="+this.state.campaignDetailID.remainingBudget)
		} // end of for

		var al = 0;
		
        //kiran-4745-removing console warning-changed var to let
		for (let i = 0; i < result.length; i++) {
			if (result[i].allocatedLead !== undefined) {
				//  alert(result[i].allocatedLead);
				al = al + parseInt(result[i].allocatedLead);
			}
		}

		if (
			date === true &&
			cpl === true &&
			lead === true &&
			valid === true &&
			rebudget === true
		) {
			formIsValid = true;
			this.setState({ alertBorder: "0px solid white" });
		}

		console.log("Errors ====>" + JSON.stringify(this.state.errors));
		if (al > RejectedLead) {
			errors["reject"] = (
				<li>Allocated Lead cannot be greater then Rejected Leads</li>
			);
			puberror["one"] = "";
			formIsValid = false;
		} else {
		}

		if (formIsValid === true) {
			// alert("Form valid");
			this.setState({ alertDisplay: "none" });
			if (al < RejectedLead) {
				var campID = document.getElementById("campID").value;
				var leadAllocation = document.getElementById("leadAllocation").value;
				// console.warn("New Array of the created====>"+JSON.stringify(this.state.newGDPRPubArray));

				/**
				 * @author Narendra Phadke
				 * @param  Description handle the publisher Login Details
				 * @return Description return All publisher Details
				 */
				const {  user } = this.props.auth;//kiran-4745-removing console warning-no used

				let data = {
					campID: campID,
					user: user,
					clientCampID: this.state.campaignDetailID[0].clientCampID,
					leadAllocation: leadAllocation,
					dynamicArray: this.state.newGDPRPubArray,
				};
				console.log("Data : " + JSON.stringify(data));

				fetch("publisher/reassignCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						console.warn("Response in res in==" + JSON.stringify(res));
						//kiran-4745-removing console warning-changed == to ===
						if (res.success === true) {
							this.setState({ inputClass: "disabled" });
							console.warn("Response in res==" + JSON.stringify(res));
							//  alert(res.message);
							//  var a=(res.message);
							//this.setState({newsuccess:a});

							Swal.fire({
								text:
									res.message +
									", " +
									"This allocation is partial of rejected leads and remaining leads will move to Pending Allocation",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
						} else {
							// alert("Not Allocated");
						}
					})
					.catch((err) => {
						console.log(err);
					});
			} // end of   if (al < RejectedLead)
			else {
				//kiran-4745-removing console warning-changed var to let
				let campID = document.getElementById("campID").value;
				let leadAllocation = document.getElementById("leadAllocation").value;
				// console.warn("New Array of the created====>"+JSON.stringify(this.state.newGDPRPubArray));

				/**
				 * @author Narendra Phadke
				 * @param  Description handle the publisher Login Details
				 * @return Description return All publisher Details
				 */
				const { user } = this.props.auth;//kiran-4745-removing console warning-no used

				let data = {
					campID: campID,
					user: user,
					clientCampID: this.state.campaignDetailID[0].clientCampID,
					leadAllocation: leadAllocation,
					dynamicArray: this.state.newGDPRPubArray,
				};
				console.log("Data : " + JSON.stringify(data));
				// alert("Data : "+JSON.stringify(this.state.campaignDetailID[0].clientCampID));
				// success["message"] = "Campaign allocation done successfully";
				// this.setState({
				//     //  errors:'',
				//   errors:'',
				//       puberror:'',
				//       allocerrors:'',
				//       reerrors:'',
				//       dateerrors:'',
				//     newsuccess:'',
				//     alertWidth:'1000px',
				//     alertAlign:'center',
				//     alertDisplay:'block',
				//   spanSpaceDisplay:spanDisplay
				//     });

				//publisher/assignCampaign
				fetch("publisher/reassignCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						console.warn("Response in res in==" + JSON.stringify(res));
						if (res.success === true) {//kiran-4745-removing console warning-changed == to ===
							this.setState({ inputClass: "disabled" });
							console.warn("Response in res==" + JSON.stringify(res));
							//  alert(res.message);
							//  var a=(res.message);
							//this.setState({newsuccess:a});

							Swal.fire({
								text: res.message,
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
						} else {
							// alert("Not Allocated");
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} else {
			// alert("Form Invalid");
		}
	} // end of submitGDPRPublisher

	submitCounterGDPRPublisher(e) {
		//  alert("submitGDPRPublisher  counter yes/no");
		e.preventDefault();
		let errors = {};
		let spanDisplay = "none";
		// let fields = this.state.fields;
		let formIsValid = false;
		let date = false;
		let cpl = false;
		let lead = false;
		// let success = {};
		let puberror = {};
		let valid = false;
		let rebudget = false;
		let allocerrors = {};
		let reerrors = {};
		let dateerrors = {};
		// let r1 = [];//kiran-4745-removing console warning-no used

		result = Array.from(
			new Set(this.state.newGDPRPubArray.map((p) => p.pID))
		).map((pID) => {
			return {
				pID: pID,
				publisherName: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.publisherName,
				startDate: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.startDate,
				endDate: this.state.newGDPRPubArray.find((p) => p.pID === pID).endDate,
				allocatedLead: this.state.newGDPRPubArray.find((p) => p.pID === pID)
					.allocatedLead,
				cpl:
					this.state.newGDPRPubArray.find((p) => p.pID === pID).cpl != null
						? this.state.newGDPRPubArray.find((p) => p.pID === pID).cpl
						: this.state.newGDPRPubArray.find((p) => p.pID === pID).campCPL,
				firstLeadDeliveryDate: this.state.newGDPRPubArray.find(
					(p) => p.pID === pID
				).firstLeadDeliveryDate,
			};
		});
		//  console.warn("New newGDPRPubArray Array of the created after ====>"+JSON.stringify(result));
		var totalLead = document.getElementById("totalLead").value;
		// alert("Total Leadddd="+totalLead);
		var totalAllocate = document.getElementById("totalAllocate").value;
		// alert("Total Allocate="+totalAllocate);
		var totalBudget = document.getElementById("totalBudget").value;
		//  alert("Total budget="+totalBudget);
		var totalRemainingBudget = document.getElementById(
			"totalRemainingBudget"
		).value;
		//  alert("Total remaining budget="+totalRemainingBudget);
		var totalstartDate = document.getElementById("totalstartDate").value;
		// alert("Campaign start date="+totalstartDate);
		var totalendDate = document.getElementById("totalendDate").value;
		//  alert("Campaign end date="+totalendDate);
		// var totalPending = document.getElementById("totalPending").value;//kiran-4745-removing console warning-no used
		//  alert("pending Leads="+totalPending);
		// alert("length"+result.length);
		var RejectedLead = document.getElementById("RejectedLead").value;
		//  alert(RejectedLead)

		var sum = 0;
		var num;
		var multiply = 0;
		var num1;
		var num2;
		var addmulti = 0;

		var add = totalLead - totalAllocate;
		var dynamiclength = result.length;

		//                alert("dyanamic length===>"+dynamiclength);
		for (var i = 0; i < dynamiclength; i++) {
			//alert("Allocation and CPL===>"+result[i].allocatedLead +"====="+result[i].cpl)
              //kiran-4745-removing console warning-changed == to ===
			if (
				result[i].allocatedLead === "" ||
				result[i].allocatedLead === undefined ||
				result[i].allocatedLead == null
			) {
				if (publisherCount === 0) {
					//  alert("Please enter information for atleast one publisher")
					puberror["one"] = (
						<li>
							Please enter valid lead allocation for atleast one publisher
						</li>
					);

					this.setState({
						puberror: puberror,
						allocerrors: "",
						errors: errors,
						newsuccess: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				}
			} else {
				//alert("There is one publisher present");
				publisherCount++;
				//alert("publisherCount===>"+publisherCount);
				if (result[i].startDate <= result[i].endDate) {
					date = true;
					displayresult.splice(i, 1);
					// alert("valid for date");
				} else {
					date = false;
					displayresult[i] = result[i].publisherName;
					//alert("invalid date");
					errors["endDate"] =
						"Please enter valid end date for " + displayresult;
					this.setState({
						newsuccess: "",
						puberror: "",
						dateerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					// alert( displayresult[i]);
					return;
				}

				if (result[i].endDate > totalendDate) {
					// alert("campaign date is greater than pub");
					date = false;
					displayresult[i] = result[i].publisherName;
					// alert("invalid date");
					dateerrors["date"] =
						"Allocation end date cannot be greater than campaign end  date for " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("can allocate");
					date = true;
				}

				if (result[i].startDate < totalstartDate) {
					// alert("campaign start date is greater than pub");
					date = false;
					displayresult[i] = result[i].publisherName;
					// formIsValid = false;
					// alert("invalid date");
					dateerrors["date"] =
						"Allocation start date cannot be less than campaign start date for " +
						displayresult[i];
					// alert(JSON.stringify(displayresult[i]))
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("can allocate");
					date = true;
				}

				if (result[i].firstLeadDeliveryDate > result[i].endDate) {
					// alert("first lead date must be less than end date");
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"First lead delivery date can't be greater than end date " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].firstLeadDeliveryDate < result[i].startDate) {
					date = false;
					displayresult[i] = result[i].publisherName;

					dateerrors["date"] =
						"First lead delivery date can't be less than start date " +
						displayresult[i];

					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				var pattern = new RegExp(/^[1-9]+[0-9]*$/);
				if (!pattern.test(result[i].allocatedLead)) {
					// alert("before"+(result[i].allocatedLead)+"==="+result[i].publisherName)
					// alert("enter only number lead"+result[i].publisherName)
					lead = false;
					displayallocatedLead[i] = result[i].publisherName;
					errors["allocatedLead"] =
						"Please enter allocation in number for   " + displayallocatedLead;

					displayallocatedLead = [displayallocatedLead[i]];
					// var a = displayallocatedLead.includes("displayallocatedLeads[i]");//kiran-4745-removing console warning-no used

					this.setState({
						newsuccess: "",
						puberror: "",
						errors: errors,
						dateerrors: "",
						allocerrors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					return;
				} else {
					lead = true;
					num = parseInt(result[i].allocatedLead);

					// alert("Number:"+num);
					// alert("Sum before:"+sum);
					sum += num;
					// alert("Sum:"+sum);

					displayallocatedLead.splice(i, 1);

					//  formIsValid = true;
					// alert("true for lead");
				}

				/**validations for cpl */

				// cpl validations
				var patterndecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/); //decimal
				// var patternnonzero = new RegExp(/^[1-9]+[0-9]*$/); //numbers except zero
				var testval1 = result[i].cpl;
				// alert("cpl==>"+testval1);

				// var testval = parseInt(testval1);//kiran-4745-removing console warning-no used
				// alert("CPL only value parse int===>"+testval);
               //kiran-4745-removing console warning-changed == to ===
				if (testval1 === "" || testval1 == null || testval1 === undefined) {
					cpl = false;
					displaycpl[i] = result[i].publisherName;
					// alert("enter cpl"+displaycpl[i])
					errors["cpl"] =
						"Please check CPL value. CPL should follow below criteria for " +
						displaycpl[i];

					errors["cpl1"] = <li>CPL should be only number.</li>;
					errors["cpl2"] = <li>CPL should be greater than zero.</li>;
					errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

					// displaycpl= [displaycpl[i]];
					// alert("new dispay==>"+displaycpl)
					// var b=  displaycpl.includes("displaycpl[i]");
					// alert("b1==>"+b)
					this.setState({
						dateerrors: "",
						newsuccess: "",
						puberror: "",
						allocerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("has some value")
					//kiran-4745-removing console warning-changed == to ===
					if (testval1 === 0) {
						//  alert("has zero value")
						cpl = false;
						displaycpl[i] = result[i].publisherName;
						//  alert("enter cpl"+displaycpl[i]);
						errors["cpl"] =
							"Please check CPL value. CPL should follow below criteria for " +
							displaycpl[i];

						errors["cpl1"] = <li>CPL should be only number.</li>;
						errors["cpl2"] = <li>CPL should be greater than zero.</li>;
						errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

						//  displaycpl= [displaycpl[i]];
						//  var b=  displaycpl.includes("displaycpl[i]");
						//  alert("b2==>"+b)
						this.setState({
							dateerrors: "",
							newsuccess: "",
							puberror: "",
							allocerrors: "",
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						if (patterndecimal.test(testval1)) {
							// alert("valid")
							cpl = true;
						} else {
							// alert("invalid")
							cpl = false;
							displaycpl[i] = result[i].publisherName;
							// alert("enter cpl"+displaycpl[i])
							errors["cpl"] =
								"Please check CPL value. CPL should follow below criteria for " +
								displaycpl[i];

							errors["cpl1"] = <li>CPL should be only number.</li>;
							errors["cpl2"] = <li>CPL should be greater than zero.</li>;
							errors["cpl3"] = (
								<li>CPL value allowed only 2 decimal places.</li>
							);

							//           displaycpl= [displaycpl[i]];
							//           var b=  displaycpl.includes("displaycpl[i]");
							//  alert("b3==>"+b)
							this.setState({
								dateerrors: "",
								newsuccess: "",
								puberror: "",
								allocerrors: "",
								errors: errors,
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});
							return;
						}
					}
				}

				// end of cpl validations

				//validation for budget
				num1 = parseInt(result[i].allocatedLead);
				num2 = parseFloat(result[i].cpl);
				// alert(" before multiplication:"+multiply);
				// alert("lead"+num1);
				// alert("cpl"+num2);

				multiply = num1 * num2;
				// alert("after  multiplication:"+multiply);

				// alert("before addition of multiplication"+addmulti);
				addmulti += multiply;
				// alert("after addition of multiplication"+addmulti);

				// alert("budget remain==="+totalRemainingBudget)
				//kiran-4745-removing console warning-changed == to ===
				if (totalRemainingBudget === "") {
					if (addmulti > totalBudget) {
						rebudget = false;
						// alert("greater than total budget");
						reerrors["remaining"] = (
							<li>Allocated budget cannot be greater than total budget</li>
						);
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							newsuccess: "",
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						// alert("less than budget can allocate")
					}
				} else {
					if (addmulti > totalRemainingBudget) {
						rebudget = false;
						// alert("greater than total budget");
						reerrors["remaining"] = (
							<li>Allocated budget cannot be greater than total budget</li>
						);
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						// alert("less than budget can allocate")
					}
				}
				add = totalLead - totalAllocate;
				if (sum > add) {
					valid = false;
					// alert("not allowed");
					allocerrors["allocate"] = (
						<li>Allocated leads cannot be greater than total leads</li>
					);
					this.setState({
						puberror: "",
						allocerrors: allocerrors,
						reerrors: "",
						newsuccess: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
				} else {
					valid = true;
					//  alert("allowed4");
				}
			} // end of else

			// alert("total lead==="+this.state.campaignDetailID.leadAllocation);
			// alert("total allocate==="+this.state.campaignDetailID.allocatedLead)
			//  alert("total budget==="+this.state.campaignDetailID.budget)
			//  alert("total Remaining budget==="+this.state.campaignDetailID.remainingBudget)
		} // end of for

		var al = 0;
		//kiran-4745-removing console warning-changed != to !==
		for (let i = 0; i < result.length; i++) {
			if (result[i].allocatedLead !== undefined) {
				//  alert(result[i].allocatedLead);
				al = al + parseInt(result[i].allocatedLead);
			}
		}

		if (
			date === true &&
			cpl === true &&
			lead === true &&
			valid === true &&
			rebudget === true
		) {
			formIsValid = true;
			this.setState({ alertBorder: "0px solid white" });
		}

		console.log("Errors ====>" + JSON.stringify(this.state.errors));
		if (al > RejectedLead) {
			errors["reject"] = (
				<li>Allocated Lead cannot be greater then Rejected Leads</li>
			);
			puberror["one"] = "";
			formIsValid = false;
		} else {
		}

		if (formIsValid === true) {
			this.setState({ alertDisplay: "none" });
			// alert("Form valid");
			if (al < RejectedLead) {
				var campID = document.getElementById("campID").value;
				var leadAllocation = document.getElementById("leadAllocation").value;
				// console.warn("New Array of the created====>"+JSON.stringify(this.state.newGDPRPubArray));

				/**
				 * @author Narendra Phadke
				 * @param  Description handle the publisher Login Details
				 * @return Description return All publisher Details
				 */
				const {  user } = this.props.auth; //kiran-4745-removing console warning-no used
				// alert("Data : "+JSON.stringify(this.state.counterCampID));

				let data = {
					campID: campID,
					user: user,
					counterpID: this.state.counterpID,
					counterDetails: this.state.counterCampID,
					clientCampID: this.state.counterCampID[0].clientCampID,
					leadAllocation: leadAllocation,
					dynamicArray: this.state.newGDPRPubArray,
				};
				console.log("Data : " + JSON.stringify(data));

				//publisher/reassignCampaign
				fetch("publisher/counterAllocate", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						console.warn("Response in res in==" + JSON.stringify(res));
						if (res.success === true) { //kiran-4745-removing console warning-changed == to ===
							this.setState({ inputClass: "disabled" });
							console.warn("Response in res==" + JSON.stringify(res));
							//  alert(res.message);
							//  var a=(res.message);
							//  this.setState({newsuccess:a});

							Swal.fire({
								text:
									res.message +
									", " +
									"This allocation is partial of countered leads and remaining leads will move to Pending Allocation",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
						} else {
							// alert("Not Allocated");
						}
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				let campID = document.getElementById("campID").value;
				let leadAllocation = document.getElementById("leadAllocation").value;
				//kiran-4745-removing console warning-changed var to let
				// console.warn("New Array of the created====>"+JSON.stringify(this.state.newGDPRPubArray));

				/**
				 * @author Narendra Phadke
				 * @param  Description handle the publisher Login Details
				 * @return Description return All publisher Details
				 */
				const { user } = this.props.auth;//kiran-4745-removing console warning-no used
				// alert("Data : "+JSON.stringify(this.state.counterCampID));

				let data = {
					campID: campID,
					user: user,
					counterpID: this.state.counterpID,
					counterDetails: this.state.counterCampID,
					clientCampID: this.state.counterCampID[0].clientCampID,
					leadAllocation: leadAllocation,
					dynamicArray: this.state.newGDPRPubArray,
				};
				console.log("Data : " + JSON.stringify(data));
				//   alert("Data : "+JSON.stringify(this.state.newGDPRPubArray));
				// success["message"] = "Campaign allocation done successfully";
				// this.setState({
				//     //  errors:'',
				//   errors:'',
				//       puberror:'',
				//       allocerrors:'',
				//       reerrors:'',
				//       dateerrors:'',
				//     newsuccess:'',
				//     alertWidth:'1000px',
				//     alertAlign:'center',
				//     alertDisplay:'block',
				//   spanSpaceDisplay:spanDisplay
				//     });

				//publisher/reassignCampaign
				fetch("publisher/counterAllocate", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						console.warn("Response in res in==" + JSON.stringify(res));
						if (res.success === true) {//kiran-4745-removing console warning-changed == to ===
							this.setState({ inputClass: "disabled" });
							console.warn("Response in res==" + JSON.stringify(res));
							//  alert(res.message);
							//  var a=(res.message);
							//  this.setState({newsuccess:a});

							Swal.fire({
								text: res.message,
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
						} else {
							// alert("Not Allocated");
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} else {
			// alert("Form Invalid");
		}
	} // end of submitCounterGDPRPublisher

	/**
	 * @author Somnath Keswad
	 * @param  Description  Handle allocate rejected lead to another publisher
	 * @return Description allocate the rejected lead to another publisher
	 */
	submitPublisher(e) {
		//alert("submitPublisher for Non-GDPR Rejected Re-Allocation");
		e.preventDefault();
		let errors = {};
		let spanDisplay = "none";
		// let fields = this.state.fields;
		let formIsValid = false;
		let date = false;
		let cpl = false;
		let lead = false;
		// let success = {};
		let puberror = {};
		let valid = false;
		let rebudget = false;
		let allocerrors = {};
		let reerrors = {};
		let dateerrors = {};
		// let r1 = [];//kiran-4745-removing console warning-no used

		result = Array.from(
			new Set(this.state.newDynamicArray.map((p) => p.pID))
		).map((pID) => {
			return {
				pID: pID,
				publisherName: this.state.newDynamicArray.find((p) => p.pID === pID)
					.publisherName,
				startDate: this.state.newDynamicArray.find((p) => p.pID === pID)
					.startDate,
				endDate: this.state.newDynamicArray.find((p) => p.pID === pID).endDate,
				allocatedLead: this.state.newDynamicArray.find((p) => p.pID === pID)
					.allocatedLead,
				cpl:
					this.state.newDynamicArray.find((p) => p.pID === pID).cpl != null
						? this.state.newDynamicArray.find((p) => p.pID === pID).cpl
						: this.state.newDynamicArray.find((p) => p.pID === pID).campCPL,
				firstLeadDeliveryDate: this.state.newDynamicArray.find(
					(p) => p.pID === pID
				).firstLeadDeliveryDate,
			};
		});

		console.warn(
			"New Array of the created after ====>" + JSON.stringify(result)
		);

		var totalLead = document.getElementById("totalLead").value;
		// alert("Total Leadddd="+totalLead);

		var totalAllocate = document.getElementById("totalAllocate").value;
		// alert("Total Allocate="+totalAllocate);

		var totalBudget = document.getElementById("totalBudget").value;
		//  alert("Total budget="+totalBudget);

		var totalRemainingBudget = document.getElementById(
			"totalRemainingBudget"
		).value;
		//  alert("Total remaining budget="+totalRemainingBudget);

		var totalstartDate = document.getElementById("totalstartDate").value;
		// alert("Campaign start date="+totalstartDate);

		var totalendDate = document.getElementById("totalendDate").value;
		//  alert("Campaign end date="+totalendDate);
		// var totalPending = document.getElementById("totalPending").value;
		// var pendingAllocation = parseInt(totalPending); //kiran-4745-removing console warning-no used
		var RejectedLead = document.getElementById("RejectedLead").value;
		// alert(RejectedLead)
		var sum = 0;
		var num;
		var multiply = 0;
		var num1;
		var num2;
		var addmulti = 0;

		var add = totalLead - totalAllocate;
		var dynamiclength = result.length;

		// alert("Dynamic Array Length===>"+dynamiclength);
		for (var i = 0; i < dynamiclength; i++) {
			//alert("Allocation and CPL===>"+result[i].allocatedLead +"====="+result[i].cpl)
          //kiran-4745-removing console warning-changed == to ===
			if (
				result[i].allocatedLead === "" ||
				result[i].allocatedLead === undefined ||
				result[i].allocatedLead == null
			) {
				if (publisherCount === 0) {
					//  alert("Please enter information for atleast one publisher")
					puberror["one"] = (
						<li>
							Please enter valid lead allocation for atleast one publisher
						</li>
					);

					this.setState({
						puberror: puberror,
						allocerrors: "",
						errors: errors,
						newsuccess: "",
						alertWidth: "1000px",
						alertBorder: "1px solid red",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				}
			} else {
				//alert("There is one publisher present");
				publisherCount++;
				//alert("publisherCount===>"+publisherCount);
				if (result[i].startDate <= result[i].endDate) {
					date = true;
					displayresult.splice(i, 1);
					// alert("valid for date");
				} else {
					date = false;
					displayresult[i] = result[i].publisherName;
					//alert("invalid date");
					errors["endDate"] =
						"Please enter valid end date for " + displayresult;
					this.setState({
						newsuccess: "",
						puberror: "",
						dateerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					// alert( displayresult[i]);
					return;
				}

				if (result[i].endDate > totalendDate) {
					// alert("campaign date is greater than pub");
					date = false;
					displayresult[i] = result[i].publisherName;
					// alert("invalid date");
					dateerrors["date"] =
						"Allocation end date cannot be greater than campaign end  date for " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("can allocate");
					date = true;
				}

				if (result[i].startDate < totalstartDate) {
					// alert("campaign start date is greater than pub");
					date = false;
					displayresult[i] = result[i].publisherName;
					// formIsValid = false;
					// alert("invalid date");
					dateerrors["date"] =
						"Allocation start date cannot be less than campaign start date for " +
						displayresult[i];
					// alert(JSON.stringify(displayresult[i]))
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("can allocate");
					date = true;
				}

				if (result[i].firstLeadDeliveryDate > result[i].endDate) {
					// alert("first lead date must be less than end date");
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"First lead delivery date can't be greater than end date " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].firstLeadDeliveryDate < result[i].startDate) {
					date = false;
					displayresult[i] = result[i].publisherName;

					dateerrors["date"] =
						"First lead delivery date can't be less than start date " +
						displayresult[i];

					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				var pattern = new RegExp(/^[1-9]+[0-9]*$/);
				if (!pattern.test(result[i].allocatedLead)) {
					// alert("before"+(result[i].allocatedLead)+"==="+result[i].publisherName)
					// alert("enter only number lead"+result[i].publisherName)
					lead = false;
					displayallocatedLead[i] = result[i].publisherName;
					errors["allocatedLead"] =
						"Please enter allocation in number for  " + displayallocatedLead;

					displayallocatedLead = [displayallocatedLead[i]];
					// var a = displayallocatedLead.includes("displayallocatedLeads[i]");

					this.setState({
						newsuccess: "",
						puberror: "",
						errors: errors,
						dateerrors: "",
						allocerrors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					return;
				} else {
					lead = true;
					num = parseInt(result[i].allocatedLead);

					// alert("Number:"+num);
					// alert("Sum before:"+sum);
					sum += num;
					// alert("Sum:"+sum);

					displayallocatedLead.splice(i, 1);

					//  formIsValid = true;
					// alert("true for lead");
				}

				/**validations for cpl */

				// cpl validations
				var patterndecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/); //decimal
				// var patternnonzero = new RegExp(/^[1-9]+[0-9]*$/); //numbers except zero//kiran-4745-removing console warning-no used
				var testval1 = result[i].cpl;
				// alert("cpl==>"+testval1);

				// var testval = parseInt(testval1);
				// alert("CPL only value parse int===>"+testval);

				if (testval1 === "" || testval1 == null || testval1 === undefined) {
					cpl = false;
					displaycpl[i] = result[i].publisherName;
					// alert("enter cpl"+displaycpl[i])
					errors["cpl"] =
						"Please check CPL value. CPL should follow below criteria for " +
						displaycpl[i];

					errors["cpl1"] = <li>CPL should be only number.</li>;
					errors["cpl2"] = <li>CPL should be greater than zero.</li>;
					errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

					// displaycpl= [displaycpl[i]];
					// alert("new dispay==>"+displaycpl)
					// var b=  displaycpl.includes("displaycpl[i]");
					// alert("b1==>"+b)
					this.setState({
						dateerrors: "",
						newsuccess: "",
						puberror: "",
						allocerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("has some value")
					//kiran-4745-removing console warning-changed == to ===
					if (testval1 === 0) {
						//  alert("has zero value")
						cpl = false;
						displaycpl[i] = result[i].publisherName;
						//  alert("enter cpl"+displaycpl[i]);
						errors["cpl"] =
							"Please check CPL value. CPL should follow below criteria for " +
							displaycpl[i];

						errors["cpl1"] = <li>CPL should be only number.</li>;
						errors["cpl2"] = <li>CPL should be greater than zero.</li>;
						errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

						//  displaycpl= [displaycpl[i]];
						//  var b=  displaycpl.includes("displaycpl[i]");
						//  alert("b2==>"+b)
						this.setState({
							dateerrors: "",
							newsuccess: "",
							puberror: "",
							allocerrors: "",
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						if (patterndecimal.test(testval1)) {
							// alert("valid")
							cpl = true;
						} else {
							// alert("invalid")
							cpl = false;
							displaycpl[i] = result[i].publisherName;
							// alert("enter cpl"+displaycpl[i])
							errors["cpl"] =
								"Please check CPL value. CPL should follow below criteria for " +
								displaycpl[i];

							errors["cpl1"] = <li>CPL should be only number.</li>;
							errors["cpl2"] = <li>CPL should be greater than zero.</li>;
							errors["cpl3"] = (
								<li>CPL value allowed only 2 decimal places.</li>
							);

							//           displaycpl= [displaycpl[i]];
							//           var b=  displaycpl.includes("displaycpl[i]");
							//  alert("b3==>"+b)
							this.setState({
								dateerrors: "",
								newsuccess: "",
								puberror: "",
								allocerrors: "",
								errors: errors,
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});
							return;
						}
					}
				}

				// end of cpl validations

				//validation for budget
				num1 = parseInt(result[i].allocatedLead);
				num2 = parseFloat(result[i].cpl);
				// alert(" before multiplication:"+multiply);
				// alert("lead"+num1);
				// alert("cpl"+num2);

				multiply = num1 * num2;
				// alert("after  multiplication:"+multiply);

				// alert("before addition of multiplication"+addmulti);
				addmulti += multiply;
				// alert("after addition of multiplication"+addmulti);

				// alert("budget remain==="+totalRemainingBudget)
				//kiran-4745-removing console warning-changed == to ===
				if (totalRemainingBudget === "") {
					if (addmulti > totalBudget) {
						rebudget = false;
						// alert("greater than total budget");
						reerrors["remaining"] = (
							<li>Allocated budget cannot be greater than total budget</li>
						);
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							newsuccess: "",
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						// alert("less than budget can allocate")
					}
				} else {
					if (addmulti > totalRemainingBudget) {
						rebudget = false;
						// alert("greater than total budget");
						reerrors["remaining"] = (
							<li>Allocated budget cannot be greater than total budget</li>
						);
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						// alert("less than budget can allocate")
					}
				}
				add = totalLead - totalAllocate;
				if (sum > add) {
					valid = false;
					// alert("not allowed");
					allocerrors["allocate"] = (
						<li>Allocated leads cannot be greater than total leads</li>
					);
					this.setState({
						puberror: "",
						allocerrors: allocerrors,
						reerrors: "",
						newsuccess: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
				} else {
					valid = true;
					// alert("allowed1");
				}
			} // end of else

			// alert("total lead==="+this.state.campaignDetailID.leadAllocation);
			// alert("total allocate==="+this.state.campaignDetailID.allocatedLead)
			//  alert("total budget==="+this.state.campaignDetailID.budget)
			//  alert("total Remaining budget==="+this.state.campaignDetailID.remainingBudget)
		} // end of for

		var al = 0;
		//kiran-4745-removing console warning-changed var to let
		for (let i = 0; i < result.length; i++) {
			if (result[i].allocatedLead !== undefined) {
				//  alert(result[i].allocatedLead);
				al = al + parseInt(result[i].allocatedLead);
			}
		}
		// alert("Sum : "+al);
		// alert("RejectedLead=" + RejectedLead);
		if (
			date === true &&
			cpl === true &&
			lead === true &&
			valid === true &&
			rebudget === true
		) {
			formIsValid = true;
			this.setState({ alertBorder: "0px solid white" });
		}

		console.log("Errors ====>" + JSON.stringify(this.state.errors));

		if (al > RejectedLead) {
			errors["reject"] = (
				<li>Allocated Lead cannot be greater then Rejected Leads</li>
			);
			puberror["one"] = "";
			formIsValid = false;
		} else {
		}

		if (formIsValid === true) {
			// alert("Form valid");
			this.setState({ alertDisplay: "none" });
			if (al < RejectedLead) {
				var campID = document.getElementById("campID").value;
				var leadAllocation = document.getElementById("leadAllocation").value;
				// console.warn("New Array of the created====>"+JSON.stringify(this.state.newDynamicArray));

				/**
				 * @author Narendra Phadke
				 * @param  Description handle the publisher Login Details
				 * @return Description return All publisher Details
				 */
				const {  user } = this.props.auth;//kiran-4745-removing console warning-no used

				//var clientCampID=this.state.campaignDetailID[0].clientCampID;
				//console.warn("New clientCampID====>"+JSON.stringify(clientCampID));

				let data = {
					campID: campID,
					user: user,
					clientCampID: this.state.campaignDetailID[0].clientCampID,
					leadAllocation: leadAllocation,
					dynamicArray: this.state.newDynamicArray,
				};

				// success["message"] = "Campaign allocation done successfully";
				// this.setState({
				//     //  errors:'',

				//       puberror:'',
				//       allocerrors:'',
				//       reerrors:'',
				//       dateerrors:'',
				//     newsuccess:'',
				//     alertWidth:'1000px',
				//     alertAlign:'center',
				//     alertDisplay:'block',
				//   spanSpaceDisplay:spanDisplay
				//     });

				//publisher/reassignCampaign
				fetch("publisher/reassignCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						console.warn("Response in res in==" + JSON.stringify(res));
						if (res.success === true) {//kiran-4745-removing console warning-no used
							this.setState({
								inputClass: "disabled",
							});

							console.warn("Response in res==" + JSON.stringify(res));
							//  alert("Success Msg"+res.message);
							// var a=(res.message);
							// this.setState({newsuccess:a});
							Swal.fire({
								text:
									res.message +
									", " +
									"This allocation is partial of rejected leads and remaining leads will move to Pending Allocation",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
						} else {
							//alert("Not Allocated");
						}
					})
					.catch((err) => {
						console.log(err);
					});
			} // end of  if (al < RejectedLead)
			else {
				let campID = document.getElementById("campID").value;
				let leadAllocation = document.getElementById("leadAllocation").value;
				//kiran-4745-removing console warning-changed var to let
				// console.warn("New Array of the created====>"+JSON.stringify(this.state.newDynamicArray));

				/**
				 * @author Narendra Phadke
				 * @param  Description handle the publisher Login Details
				 * @return Description return All publisher Details
				 */
				const {  user } = this.props.auth;//kiran-4745-removing console warning-no used

				//var clientCampID=this.state.campaignDetailID[0].clientCampID;
				//console.warn("New clientCampID====>"+JSON.stringify(clientCampID));

				let data = {
					campID: campID,
					user: user,
					clientCampID: this.state.campaignDetailID[0].clientCampID,
					leadAllocation: leadAllocation,
					dynamicArray: this.state.newDynamicArray,
				};

				// success["message"] = "Campaign allocation done successfully";
				// this.setState({
				//     //  errors:'',

				//       puberror:'',
				//       allocerrors:'',
				//       reerrors:'',
				//       dateerrors:'',
				//     newsuccess:'',
				//     alertWidth:'1000px',
				//     alertAlign:'center',
				//     alertDisplay:'block',
				//   spanSpaceDisplay:spanDisplay
				//     });

				//publisher/reassignCampaign
				fetch("publisher/reassignCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						console.warn("Response in res in==" + JSON.stringify(res));
						//kiran-4745-removing console warning-changed == to ===
						if (res.success === true) {
							this.setState({
								inputClass: "disabled",
							});

							console.warn("Response in res==" + JSON.stringify(res));
							//  alert("Success Msg"+res.message);
							// var a=(res.message);
							// this.setState({newsuccess:a});
							Swal.fire({
								text: res.message,
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
						} else {
							//alert("Not Allocated");
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} else {
			// alert("Form Invalid");
		}
	} // end of submitpublisher

	/**
	 * @author Somnath Keswad
	 * @param  Description  Handle allocate Counter lead to another publisher
	 * @return Description allocate the counter lead to another publisher
	 */
	submitCounterPublisher(e) {
		// alert("submitPublisher for Non-GDPR Countered Re-Allocation");
		e.preventDefault();

		let errors = {};
		let spanDisplay = "none";
		// let fields = this.state.fields;
		let formIsValid = false;
		let date = false;
		let cpl = false;
		let lead = false;
		// let success = {};
		let puberror = {};
		let valid = false;
		let rebudget = false;
		let allocerrors = {};
		let reerrors = {};
		let dateerrors = {};
		// let r1 = [];//kiran-4745-removing console warning-no used

		result = Array.from(
			new Set(this.state.counterDataArray.map((p) => p.pID))
		).map((pID) => {
			return {
				pID: pID,
				publisherName: this.state.counterDataArray.find((p) => p.pID === pID)
					.publisherName,
				startDate: this.state.counterDataArray.find((p) => p.pID === pID)
					.startDate,
				endDate: this.state.counterDataArray.find((p) => p.pID === pID).endDate,
				allocatedLead: this.state.counterDataArray.find((p) => p.pID === pID)
					.allocatedLead,
				cpl:
					this.state.counterDataArray.find((p) => p.pID === pID).cpl != null
						? this.state.counterDataArray.find((p) => p.pID === pID).cpl
						: this.state.counterDataArray.find((p) => p.pID === pID).campCPL,
				firstLeadDeliveryDate: this.state.counterDataArray.find(
					(p) => p.pID === pID
				).firstLeadDeliveryDate,
			};
		});

		//  console.warn("New Array of the created after ====>"+JSON.stringify(result));

		var totalLead = document.getElementById("totalLead").value;
		// alert("Total Leadddd="+totalLead);

		var totalAllocate = document.getElementById("totalAllocate").value;
		// alert("Total Allocate="+totalAllocate);

		var totalBudget = document.getElementById("totalBudget").value;
		//  alert("Total budget="+totalBudget);

		var totalRemainingBudget = document.getElementById(
			"totalRemainingBudget"
		).value;
		//  alert("Total remaining budget="+totalRemainingBudget);

		var totalstartDate = document.getElementById("totalstartDate").value;
		// alert("Campaign start date="+totalstartDate);

		var totalendDate = document.getElementById("totalendDate").value;
		//  alert("Campaign end date="+totalendDate);
		// var totalPending = document.getElementById("totalPending").value;
		// var pendingAllocation = parseInt(totalPending);//kiran-4745-removing console warning-no used
		var counterLead = document.getElementById("counterLead").value;
		var clientCampID = document.getElementById("clientCampID").value;

		// alert("length"+result.length);

		var sum = 0;
		var num;
		var multiply = 0;
		var num1;
		var num2;
		var addmulti = 0;

		var add = totalLead - totalAllocate;
		var dynamiclength = result.length;
		//  alert("Dynamic Array Length===>"+dynamiclength);

		for (var i = 0; i < dynamiclength; i++) {
			//alert("Allocation and CPL===>"+result[i].allocatedLead +"====="+result[i].cpl)
//kiran-4745-removing console warning-changed == to ===
			if (
				result[i].allocatedLead === "" ||
				result[i].allocatedLead === undefined ||
				result[i].allocatedLead == null
			) {
				if (publisherCount === 0) {
					//  alert("Please enter information for atleast one publisher")
					puberror["one"] = (
						<li>
							Please enter valid lead allocation for atleast one publisher
						</li>
					);

					this.setState({
						puberror: puberror,
						allocerrors: "",
						errors: errors,
						newsuccess: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				}
			} else {
				//alert("There is one publisher present");
				publisherCount++;
				//alert("publisherCount===>"+publisherCount);
				if (result[i].startDate <= result[i].endDate) {
					date = true;
					displayresult.splice(i, 1);
					// alert("valid for date");
				} else {
					date = false;
					displayresult[i] = result[i].publisherName;
					//alert("invalid date");
					errors["endDate"] =
						"Please enter valid end date for " + displayresult;
					this.setState({
						newsuccess: "",
						puberror: "",
						dateerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					// alert( displayresult[i]);
					return;
				}

				if (result[i].endDate > totalendDate) {
					// alert("campaign date is greater than pub");
					date = false;
					displayresult[i] = result[i].publisherName;
					// alert("invalid date");
					dateerrors["date"] =
						"Allocation end date cannot be greater than campaign end  date for " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("can allocate");
					date = true;
				}

				if (result[i].startDate < totalstartDate) {
					// alert("campaign start date is greater than pub");
					date = false;
					displayresult[i] = result[i].publisherName;
					// formIsValid = false;
					// alert("invalid date");
					dateerrors["date"] =
						"Allocation start date cannot be less than campaign start date for " +
						displayresult[i];
					// alert(JSON.stringify(displayresult[i]))
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("can allocate");
					date = true;
				}

				if (result[i].firstLeadDeliveryDate > result[i].endDate) {
					// alert("first lead date must be less than end date");
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"First lead delivery date can't be greater than end date " +
						displayresult[i];
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].firstLeadDeliveryDate < result[i].startDate) {
					date = false;
					displayresult[i] = result[i].publisherName;

					dateerrors["date"] =
						"First lead delivery date can't be less than start date " +
						displayresult[i];

					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				var pattern = new RegExp(/^[1-9]+[0-9]*$/);
				if (!pattern.test(result[i].allocatedLead)) {
					// alert("before"+(result[i].allocatedLead)+"==="+result[i].publisherName)
					// alert("enter only number lead"+result[i].publisherName)
					lead = false;
					displayallocatedLead[i] = result[i].publisherName;
					errors["allocatedLead"] =
						"Please enter allocation in number for  " + displayallocatedLead;

					displayallocatedLead = [displayallocatedLead[i]];
					// var a = displayallocatedLead.includes("displayallocatedLeads[i]");

					this.setState({
						newsuccess: "",
						puberror: "",
						errors: errors,
						dateerrors: "",
						allocerrors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					return;
				} else {
					lead = true;
					num = parseInt(result[i].allocatedLead);

					// alert("Number:"+num);
					// alert("Sum before:"+sum);
					sum += num;
					// alert("Sum:"+sum);

					displayallocatedLead.splice(i, 1);

					//  formIsValid = true;
					// alert("true for lead");
				}

				/**validations for cpl */

				// cpl validations
				var patterndecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/); //decimal
				// var patternnonzero = new RegExp(/^[1-9]+[0-9]*$/); //numbers except zero//kiran-4745-removing console warning-no used
				var testval1 = result[i].cpl;
				// alert("cpl==>"+testval1);

				// var testval = parseInt(testval1);
				// alert("CPL only value parse int===>"+testval);

				if (testval1 === "" || testval1 == null || testval1 === undefined) {
					cpl = false;
					displaycpl[i] = result[i].publisherName;
					// alert("enter cpl"+displaycpl[i])
					errors["cpl"] =
						"Please check CPL value. CPL should follow below criteria for " +
						displaycpl[i];

					errors["cpl1"] = <li>CPL should be only number.</li>;
					errors["cpl2"] = <li>CPL should be greater than zero.</li>;
					errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

					// displaycpl= [displaycpl[i]];
					// alert("new dispay==>"+displaycpl)
					// var b=  displaycpl.includes("displaycpl[i]");
					// alert("b1==>"+b)
					this.setState({
						dateerrors: "",
						newsuccess: "",
						puberror: "",
						allocerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					// alert("has some value")
					if (testval1 === 0) {
						//  alert("has zero value")
						cpl = false;
						displaycpl[i] = result[i].publisherName;
						//  alert("enter cpl"+displaycpl[i]);
						errors["cpl"] =
							"Please check CPL value. CPL should follow below criteria for " +
							displaycpl[i];

						errors["cpl1"] = <li>CPL should be only number.</li>;
						errors["cpl2"] = <li>CPL should be greater than zero.</li>;
						errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;

						//  displaycpl= [displaycpl[i]];
						//  var b=  displaycpl.includes("displaycpl[i]");
						//  alert("b2==>"+b)
						this.setState({
							dateerrors: "",
							newsuccess: "",
							puberror: "",
							allocerrors: "",
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						if (patterndecimal.test(testval1)) {
							// alert("valid")
							cpl = true;
						} else {
							// alert("invalid")
							cpl = false;
							displaycpl[i] = result[i].publisherName;
							// alert("enter cpl"+displaycpl[i])
							errors["cpl"] =
								"Please check CPL value. CPL should follow below criteria for " +
								displaycpl[i];

							errors["cpl1"] = <li>CPL should be only number.</li>;
							errors["cpl2"] = <li>CPL should be greater than zero.</li>;
							errors["cpl3"] = (
								<li>CPL value allowed only 2 decimal places.</li>
							);

							//           displaycpl= [displaycpl[i]];
							//           var b=  displaycpl.includes("displaycpl[i]");
							//  alert("b3==>"+b)
							this.setState({
								dateerrors: "",
								newsuccess: "",
								puberror: "",
								allocerrors: "",
								errors: errors,
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});
							return;
						}
					}
				}

				// end of cpl validations

				//validation for budget
				num1 = parseInt(result[i].allocatedLead);
				num2 = parseFloat(result[i].cpl);
				// alert(" before multiplication:"+multiply);
				// alert("lead"+num1);
				// alert("cpl"+num2);

				multiply = num1 * num2;
				// alert("after  multiplication:"+multiply);

				// alert("before addition of multiplication"+addmulti);
				addmulti += multiply;
				// alert("after addition of multiplication"+addmulti);

				// alert("budget remain==="+totalRemainingBudget)
				if (totalRemainingBudget === "") {
					if (addmulti > totalBudget) {
						rebudget = false;
						// alert("greater than total budget");
						reerrors["remaining"] = (
							<li>Allocated budget cannot be greater than total budget</li>
						);
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							newsuccess: "",
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						// alert("less than budget can allocate")
					}
				} else {
					if (addmulti > totalRemainingBudget) {
						rebudget = false;
						// alert("greater than total budget");
						reerrors["remaining"] = (
							<li>Allocated budget cannot be greater than total budget</li>
						);
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							alertWidth: "1000px",
							alertBorder: "1px solid red",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
						// alert("less than budget can allocate")
					}
				}
				add = totalLead - totalAllocate;
				if (sum > add) {
					valid = false;
					// alert("not allowed");
					allocerrors["allocate"] = (
						<li>Allocated leads cannot be greater than total leads</li>
					);
					this.setState({
						puberror: "",
						allocerrors: allocerrors,
						reerrors: "",
						newsuccess: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
				} else {
					valid = true;
					//  alert("allowed2");
				}
			} // end of else

			// alert("total lead==="+this.state.campaignDetailID.leadAllocation);
			// alert("total allocate==="+this.state.campaignDetailID.allocatedLead)
			//  alert("total budget==="+this.state.campaignDetailID.budget)
			//  alert("total Remaining budget==="+this.state.campaignDetailID.remainingBudget)
		} // end of for
		var al = 0;
		//kiran-4745-removing console warning-changed var to let
		for (let i = 0; i < result.length; i++) {
			if (result[i].allocatedLead !== undefined) {
				//  alert(result[i].allocatedLead);
				al = al + parseInt(result[i].allocatedLead);
			}
		}

		if (
			date === true &&
			cpl === true &&
			lead === true &&
			valid === true &&
			rebudget === true
		) {
			formIsValid = true;
			this.setState({ alertBorder: "0px solid white" });
		}
		//  console.log("Errors ====>"+JSON.stringify(this.state.errors));

		if (al > counterLead) {
			errors["reject"] = (
				<li>Allocated Lead cannot be greater then Counter Leads</li>
			);
			puberror["one"] = "";
			formIsValid = false;
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
		} else {
			errors["reject"] = "";
			this.setState({ errors: errors });
		}

		if (formIsValid === true) {
			//  alert("Form valid");
			this.setState({ alertDisplay: "none" });
			if (al < counterLead) {
				var campID = document.getElementById("campID").value;
				var leadAllocation = document.getElementById("leadAllocation").value;
				// console.warn("New Array of the created====>"+JSON.stringify(this.state.newDynamicArray));

				/**
				 * @author Narendra Phadke
				 * @param  Description handle the publisher Login Details
				 * @return Description return All publisher Details
				 */
				const {  user } = this.props.auth;//kiran-4745-removing console warning-no used

				let data = {
					campID: campID,
					user: user,
					counterpID: this.state.counterpID,
					counterDetails: this.state.counterCampID,
					leadAllocation: leadAllocation,
					clientCampID: clientCampID,
					dynamicArray: this.state.counterDataArray,
				};
				// alert(JSON.stringify(data));
				console.warn("Data ====>" + JSON.stringify(data));
				// success["message"] = "Campaign allocation done successfully";
				// this.setState({
				//     //  errors:'',

				//       puberror:'',
				//       allocerrors:'',
				//       reerrors:'',
				//       dateerrors:'',
				//     newsuccess:'',
				//     alertWidth:'1000px',
				//     alertAlign:'center',
				//     alertDisplay:'block',
				//   spanSpaceDisplay:spanDisplay
				//     });
				// alert("IN Submit Counter")
				//publisher/counterAllocate
				fetch("publisher/counterAllocate ", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						console.warn("Response in res in==" + JSON.stringify(res));
						if (res.success === true) {
							this.setState({
								inputClass: "disabled",
							});

							console.warn("Response in res==" + JSON.stringify(res));
							// alert("Allocation Msg"+res.message);
							// var a=(res.message);
							// this.setState({newsuccess:a});

							Swal.fire({
								text:
									res.message +
									", " +
									"This allocation is partial of countered leads and remaining leads will move to Pending Allocation",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
						} else {
							//  alert("Not Allocated");
						}
					})
					.catch((err) => {
						console.log(err);
					});
			} // end of  if (al < counterLead)
			else {
				//kiran-4745-removing console warning-changed var to let
				let campID = document.getElementById("campID").value;
				let leadAllocation = document.getElementById("leadAllocation").value;
				// console.warn("New Array of the created====>"+JSON.stringify(this.state.newDynamicArray));

				/**
				 * @author Narendra Phadke
				 * @param  Description handle the publisher Login Details
				 * @return Description return All publisher Details
				 */
				const { user } = this.props.auth;//kiran-4745-removing console warning-no used

				let data = {
					campID: campID,
					user: user,
					counterpID: this.state.counterpID,
					counterDetails: this.state.counterCampID,
					leadAllocation: leadAllocation,
					clientCampID: clientCampID,
					dynamicArray: this.state.counterDataArray,
				};
				// alert(JSON.stringify(data));
				console.warn("Data ====>" + JSON.stringify(data));
				// success["message"] = "Campaign allocation done successfully";
				// this.setState({
				//     //  errors:'',

				//       puberror:'',
				//       allocerrors:'',
				//       reerrors:'',
				//       dateerrors:'',
				//     newsuccess:'',
				//     alertWidth:'1000px',
				//     alertAlign:'center',
				//     alertDisplay:'block',
				//   spanSpaceDisplay:spanDisplay
				//     });
				// alert("IN Submit Counter")
				//publisher/counterAllocate
				fetch("publisher/counterAllocate ", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						console.warn("Response in res in==" + JSON.stringify(res));
						//kiran-4745-removing console warning-changed == to ===
						if (res.success === true) {
							this.setState({
								inputClass: "disabled",
							});

							console.warn("Response in res==" + JSON.stringify(res));
							// alert("Allocation Msg"+res.message);
							// var a=(res.message);
							// this.setState({newsuccess:a});

							Swal.fire({
								text: res.message,
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
						} else {
							//  alert("Not Allocated");
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} else {
			// alert("Form Invalid");
		}
	} // end of submitCounterPublisher

	handleRefreshPage(e) {
		// window .location.reload();
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description handle the Counter publisher
	 * @return Description return the publisher which is GDPR Compliance yes or no
	 */
	isCounterGDPRPUB(e) {
		var status = e.target.value;
		var campID = e.target.id;
		// alert("isCounterGDPRPUB"+status+"==="+e.target.value);
		var emptyArray = [];
		this.setState({
			publisherCampCPL: emptyArray,
			gdprPublisher: emptyArray,
			mergedGdprPublisher: emptyArray,
			newGDPRPubArray: emptyArray,
		});

		let data = { campID: campID };
		/**
		 * @author Supriya Gore
		 * @param  Description  use in allocating leads to publisher for checking status and view CPL edditable or not
		 * @return Description return CPL and publisher ID and status
		 */
//kiran-4745-removing console warning-changed == to ===
		if (status === "Yes") {
			// alert("isCounterGDPRPUB YEs");
			this.setState({ btnStatus: status, gdprStatus: "Yes" });
			fetch("agency/counterGDPRPublisher?campID=" + campID + "")
				.then((res) => res.json())
				.then((gdprPublisher) => {
					// alert("Counter GDPR yes==="+JSON.stringify(gdprPublisher));
					this.setState({ gdprPublisher: gdprPublisher });

					fetch("agency/publisherCampaignCPL", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						}, // karan-task-3714-vapt header and query params
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((publisherCampCPL) => {
							// alert("GDPR uuuuu="+JSON.stringify(publisherCampCPL));
							this.setState({ publisherCampCPL: publisherCampCPL });

							/**
							 * @author Supriya Gore
							 * @param  Description Merge two array
							 * @return Description one campaignDetailAssign and gdprPublisher array
							 */
							if (this.state.publisherCampCPL.length > 0) {
								this.state.newGDPRPubArray = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
								);
							} else {
								this.state.newGDPRPubArray = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}
									)
								);
							}

							const resultGDPR = Array.from(
								new Set(this.state.gdprPublisher.map((p) => p.pID))
							).map((pID) => {
								return {
									pID: pID,
									publisherName: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).publisherName,
									startDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.startDate,
									endDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.endDate,
									allocatedLead: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).allocatedLead,
									cpl:
										this.state.gdprPublisher.find((p) => p.pID === pID).cpl !=
										null
											? this.state.gdprPublisher.find((p) => p.pID === pID).cpl
											: this.state.gdprPublisher.find((p) => p.pID === pID)
													.campCPL,
									firstLeadDeliveryDate: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).firstLeadDeliveryDate,
									rating: this.state.gdprPublisher.find((p) => p.pID === pID)
										.rating,
									gdprCompliance: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).gdprCompliance,
								};
							});

							let mergedGdprPublisher = [];
							mergedGdprPublisher = resultGDPR.map((e) =>
								Object.assign(
									{},
									e,
									this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) ||
										{}
								)
							);
							this.setState({ mergedGdprPublisher: mergedGdprPublisher });
							// alert("Counter GDPR Yes==="+JSON.stringify(this.state.mergedGdprPublisher));
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
		//kiran-4745-removing console warning-changed == to ===
		if (status === "No") {
			this.setState({ btnStatus: status, gdprStatus: "No" });
			fetch("agency/counterNoGDPRPublisher?campID=" + campID + "")
				.then((res) => res.json())
				.then((gdprPublisher) => {
					//alert("Counter GDPR No==="+JSON.stringify(gdprPublisher));

					this.setState({ gdprPublisher: gdprPublisher });
					fetch("agency/publisherCampaignCPL", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						}, // karan-task-3714-vapt header and query params
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((publisherCampCPL) => {
							//alert("GDPR uuuuu="+JSON.stringify(publisherCampCPL));
							this.setState({ publisherCampCPL: publisherCampCPL });

							/**
							 * @author Supriya Gore
							 * @param  Description Merge two array
							 * @return Description one campaignDetailAssign and gdprPublisher array
							 */

							if (this.state.publisherCampCPL.length > 0) {
								this.state.newGDPRPubArray = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
								);
							} else {
								this.state.newGDPRPubArray = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}
									)
								);
							}

							//   alert("counterDataArray==="+JSON.stringify(this.state.counterDataArray));

							const resultGDPR = Array.from(
								new Set(this.state.gdprPublisher.map((p) => p.pID))
							).map((pID) => {
								return {
									pID: pID,
									publisherName: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).publisherName,
									startDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.startDate,
									endDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.endDate,
									allocatedLead: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).allocatedLead,
									cpl:
										this.state.gdprPublisher.find((p) => p.pID === pID).cpl !=
										null
											? this.state.gdprPublisher.find((p) => p.pID === pID).cpl
											: this.state.gdprPublisher.find((p) => p.pID === pID)
													.campCPL,
									firstLeadDeliveryDate: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).firstLeadDeliveryDate,
									rating: this.state.gdprPublisher.find((p) => p.pID === pID)
										.rating,
									gdprCompliance: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).gdprCompliance,
								};
							});

							let mergedGdprPublisher = [];
							mergedGdprPublisher = resultGDPR.map((e) =>
								Object.assign(
									{},
									e,
									this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) ||
										{}
								)
							);
							this.setState({ mergedGdprPublisher: mergedGdprPublisher });
							// alert("Counter GDPR No==="+JSON.stringify(this.state.mergedGdprPublisher));
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (status === "false") {
			this.setState({ gdprStatus: status, btnStatus: "No" });
		}
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description handle the Counter publisher
	 * @return Description return the response merge the publisher using spread operator
	 */
	handleChangeGDPRPublisher(i, e) {
		const { name, value } = e.target;
		let newGDPRPubArray = [...this.state.newGDPRPubArray];
		newGDPRPubArray[i] = { ...newGDPRPubArray[i], [name]: value };

		// this.state.newGDPRPubArray = [...newGDPRPubArray];
		this.setState({newGDPRPubArray:[...newGDPRPubArray]})

		// alert("Counter Gdpr==="+JSON.stringify(this.state.newGDPRPubArray));
		//  this.setState({ newGDPRPubArray });
	} // end of  handleChangeDynamicPublisher

	handleChangeDynamicCounterPublisher(i, e) {
		const { name, value } = e.target;
		let counterDataArray = [...this.state.counterDataArray];
		counterDataArray[i] = { ...counterDataArray[i], [name]: value };

		// this.state.counterDataArray = [...counterDataArray];
		this.setState({counterDataArray:[...counterDataArray]});//kiran-4745-removing console warning-use setState
		//this.setState({counterDataArray});
		//alert("Counter array=="+JSON.stringify(this.state.counterDataArray));
		console.warn(
			"Dynamic data in Counter Handle Change===>" +
				JSON.stringify(this.state.counterDataArray)
		);

		// console.warn("Dynamic Objects===>"+JSON.stringify(this.state.newDynamicArray))
	} // end of  handleChangeDynamicPublisher

	/**
	 * @author Somnath Keswad
	 * @param  Description handle the Campaign ID to accept counter
	 * @return Description return the response to accepted successfully
	 */

	handleCampaignIDOnClickAccept(e) {
		var allocationID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampID");
		var reallocationID = e.target.getAttribute("reallocationID");
		this.setState({ parentCampID, reallocationID });
		fetch("/publisher/newCampaignID?allocationID=" + allocationID)
			.then((res) => res.json())
			.then((newCampaignDetailID) => {
				// alert("newCampaignDetailID"+JSON.stringify(newCampaignDetailID));
				this.setState({ newCampaignDetailID: newCampaignDetailID });
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description  Handle accept the counter with respect Lead and CPL to that publisher
	 * @return Description return successfully accepted counter message
	 */
	acceptCounterCampaign(e) {
		e.preventDefault();
		this.setState({ alertDisplay: "none" });
		// let success=this.state.success;
		// let spanDisplay = "none";//kiran-4745-removing console warning-no used
		var allocationID = this.state.newCampaignDetailID[0].allocationID;
		// alert(allocationID);
		var pID = this.state.newCampaignDetailID[0].pID;
		//alert(pID);
		var counterLead = this.state.newCampaignDetailID[0].counterLead;
		// alert("counterLead : "+counterLead);
		var counterCPL = this.state.newCampaignDetailID[0].counterCPL;
		//snehal-changes- to senf total final budget to BE to update in campaign table
		var finalBudget =
			parseFloat(this.state.toolTipData[0].budget) +
			parseFloat(
				(
					this.state.newCampaignDetailID[0].counterLead *
						this.state.newCampaignDetailID[0].counterCPL -
					this.state.newCampaignDetailID[0].allocatedLead *
						this.state.newCampaignDetailID[0].CPL
				).toFixed(2)
			);
		/**
		 * @author Narendra Phadke
		 * @param  Description handle the publisher Login Details
		 * @return Description return All publisher Details
		 */
		const { user } = this.props.auth;//kiran-4745-removing console warning-no used

		console.log("User Details" + JSON.stringify(user));

		let data = {
			allocationID: allocationID,
			pID: pID,
			user: user,
			counterLead: counterLead,
			counterCPL: counterCPL,
			campaignDetail: this.state.newCampaignDetailID,
			finalBudget: finalBudget,
		};
		// let newsuccess="";
		//       this.setState({

		//             newsuccess: newsuccess,
		//               alertWidth:'1000px',
		//               alertAlign:'center',
		//               alertDisplay:'block',
		//               spanSpaceDisplay:spanDisplay

		//             });
		//alert("campID =" + data.campID);

		fetch("publisher/acceptCounterCampaign", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				// alert("data"+JSON.stringify( this.state.newCampaignDetailID));
				console.warn("Response in res in==" + JSON.stringify(res));
				if (res.success === true) {//kiran-4745-removing console warning-changed == to ===
					console.warn("Response in res==" + JSON.stringify(res));
					// alert(res.message);
					// var a=(res.message);
					// this.setState({newsuccess:a});

					Swal.fire({
						text: res.message,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
				} else {
					// alert("Not Allocated");
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description  Handle counter Reject for Publisher
	 * @return Description Reject counter lead and that lead move to pending allocation
	 */

	rejectCounterCampaign(e) {
		this.setState({ alertDisplay: "none" });
		e.preventDefault();
		// let spanDisplay = "none";

		// var allocatedLead = this.state.newCampaignDetailID[0].allocatedLead;
		// alert(allocatedLead);
		var pID = this.state.newCampaignDetailID[0].pID;
		//var reason= this.state.fields.rejectionReason;
		// alert(JSON.stringify(this.state.newCampaignDetailID[0].clientCampID));

		const {  user } = this.props.auth;//kiran-4745-removing console warning-no used

		console.log("User Details" + JSON.stringify(user));
		// alert(JSON.stringify(this.state.newCampaignDetailID));
		let data = {
			allocationID: this.state.newCampaignDetailID[0].allocationID,
			pID: pID,
			reason: this.state.rejectReason,
			// reason1:this.state.clearRejectReason,
			user: user,
			campaignDetail: this.state.newCampaignDetailID,
		};
		// alert(JSON.stringify(data));
		//   let newsuccess = "";
		//   this.setState({

		//     newsuccess: newsuccess,
		//     alertWidth: '1000px',
		//     alertAlign: 'center',
		//     alertDisplay: 'block',
		//     spanSpaceDisplay: spanDisplay

		//   });
		fetch("publisher/rejectCounterCampaign", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				console.warn("Response in res in==" + JSON.stringify(res));
				if (res.success === true) {//kiran-4745-removing console warning-changed == to ===
					console.warn("Response in res==" + JSON.stringify(res));
					var a = res.message;
					this.setState({ newsuccess: a });
					Swal.fire({
						text: res.message,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
				} else {
					// alert("Not Rejected");
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleChange(e) {
		// alert("in handle change");
		// let fields = this.state;
		// fields[e.target.name] = e.target.value;
		// this.setState({
		//   fields

		var rejectReason = e.target.value;
		// alert(rejectReason);
		// var rejectID=e.target.getAttribute('id');
		// alert("rejectID==>"+rejectID);

		this.setState({
			rejectReason: rejectReason,
		});
	} // end of  handleChange(e)

	cancelButton(e) {
		//   alert("handle changed");
		//   // var rejectReason=e.target.value;
		//   var clearRejectReason=e.target.value;
		//  alert("clearRejectReason"+clearRejectReason);
		//   this.setState({clearRejectReason:''});
		window.location.reload();
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description handle Check the rejected GDPR Publisher
	 * @return Description return the publisher which is GDPR Compliance yes or no
	 */
	isGDPRPUB(e) {
		// for Rejected Campaign
		var status = e.target.value;
		var campID = e.target.id;
		// alert(status);

		var emptyArray = [];
		this.setState({
			publisherCampCPL: emptyArray,
			gdprPublisher: emptyArray,
			mergedGdprPublisher: emptyArray,
		});

		let data = { campID: campID };
		/**
		 * @author Supriya Gore
		 * @param  Description  use in allocating leads to publisher for checking status and view CPL edditable or not
		 * @return Description return CPL and publisher ID and status
		 */

		if (status === "Yes") { //kiran-4745-removing console warning-changed == to ===
			this.setState({ btnStatus: status, gdprStatus: "Yes" });
			fetch("agency/gdprPublisher?campID=" + campID + "")
				.then((res) => res.json())
				.then((gdprPublisher) => {
					this.setState({ gdprPublisher: gdprPublisher });
					// alert(JSON.stringify(this.state.gdprPublisher))

					fetch("agency/publisherCampaignCPL", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						}, // karan-task-3714-vapt header and query params
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((publisherCampCPL) => {
							//alert("GDPR uuuuu="+JSON.stringify(publisherCampCPL));
							this.setState({ publisherCampCPL: publisherCampCPL });

							if (this.state.publisherCampCPL.length > 0) {
								this.state.newGDPRPubArray = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
								);
							} else {
								this.state.newGDPRPubArray = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}
									)
								);
							}

							const resultGDPR = Array.from(
								new Set(this.state.gdprPublisher.map((p) => p.pID))
							).map((pID) => {
								return {
									pID: pID,
									publisherName: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).publisherName,
									startDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.startDate,
									endDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.endDate,
									allocatedLead: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).allocatedLead,
									cpl:
										this.state.gdprPublisher.find((p) => p.pID === pID).cpl !=
										null
											? this.state.gdprPublisher.find((p) => p.pID === pID).cpl
											: this.state.gdprPublisher.find((p) => p.pID === pID)
													.campCPL,
									firstLeadDeliveryDate: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).firstLeadDeliveryDate,
									rating: this.state.gdprPublisher.find((p) => p.pID === pID)
										.rating,
									gdprCompliance: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).gdprCompliance,
								};
							});

							let mergedGdprPublisher = [];
							mergedGdprPublisher = resultGDPR.map((e) =>
								Object.assign(
									{},
									e,
									this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) ||
										{}
								)
							);

							this.setState({ mergedGdprPublisher: mergedGdprPublisher });
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
		if (status === "No") {//kiran-4745-removing console warning-changed == to ===
			this.setState({ btnStatus: status, gdprStatus: "No" });
			fetch("agency/noGDPRPublisher?campID=" + campID + "")
				.then((res) => res.json())
				.then((gdprPublisher) => {
					this.setState({ gdprPublisher: gdprPublisher });

					fetch("agency/publisherCampaignCPL", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						}, // karan-task-3714-vapt header and query params
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((publisherCampCPL) => {
							//alert("GDPR uuuuu="+JSON.stringify(publisherCampCPL));
							this.setState({ publisherCampCPL: publisherCampCPL });

							if (this.state.publisherCampCPL.length > 0) {
								this.state.newGDPRPubArray = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
								);
							} else {
								this.state.newGDPRPubArray = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}
									)
								);
							}

							const resultGDPR = Array.from(
								new Set(this.state.gdprPublisher.map((p) => p.pID))
							).map((pID) => {
								return {
									pID: pID,
									publisherName: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).publisherName,
									startDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.startDate,
									endDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.endDate,
									allocatedLead: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).allocatedLead,
									cpl:
										this.state.gdprPublisher.find((p) => p.pID === pID).cpl !=
										null
											? this.state.gdprPublisher.find((p) => p.pID === pID).cpl
											: this.state.gdprPublisher.find((p) => p.pID === pID)
													.campCPL,
									firstLeadDeliveryDate: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).firstLeadDeliveryDate,
									rating: this.state.gdprPublisher.find((p) => p.pID === pID)
										.rating,
									gdprCompliance: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).gdprCompliance,
								};
							});

							let mergedGdprPublisher = [];
							mergedGdprPublisher = resultGDPR.map((e) =>
								Object.assign(
									{},
									e,
									this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) ||
										{}
								)
							);
							this.setState({ mergedGdprPublisher: mergedGdprPublisher });
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (status === "false") {//kiran-4745-removing console warning-changed == to ===
			this.setState({ gdprStatus: status, btnStatus: "No" });
		}
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description  Rejected allocation popup
	 * @return Description return Publisher and Campaign Data to show in Popup
	 */
	handleCampaignID(e) {
		var campID = e.target.id;
		tempCampID = campID;
		var parentCampID = e.target.getAttribute("parentCampID");
		var reallocationID = e.target.getAttribute("reallocationID");
		// alert(reallocationID)
		var pID = e.target.getAttribute("name");
		this.setState({ rejectpID: pID, parentCampID, reallocationID });

		let data = { campID: campID };
		/**
		 * @author Supriya Gore
		 * @param  Description  use in allocating leads to publisher for checking status and view CPL edditable or not
		 * @return Description return CPL and publisher ID and status
		 */

		let self = this;
		/** Get publisher list except Rejected Publisher */
		fetch("publisher/unRejectedPublisher?campID=" + campID)
			.then((res1) => res1.json())
			.then((campaignDetailAssign) => {
				//alert("Normal Details===="+JSON.stringify(campaignDetailAssign));
				self.setState({ campaignDetailAssign: campaignDetailAssign });

				fetch("agency/publisherCampaignCPL", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					}, // karan-task-3714-vapt header and query params
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((publisherCampCPL) => {
						//alert("GDPR uuuuu="+JSON.stringify(publisherCampCPL));
						this.setState({ publisherCampCPL: publisherCampCPL });

						if (this.state.publisherCampCPL.length > 0) {
							this.state.newDynamicArray = this.state.campaignDetailAssign.map(
								(e) =>
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
							);
						} else {
							this.state.newDynamicArray = this.state.campaignDetailAssign.map(
								(e) =>
									Object.assign(
										{},
										e,
										this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}
									)
							);
						}
						//alert("newDynamicArray in non-gdpr==="+JSON.stringify(this.state.newDynamicArray));

						const result = Array.from(
							new Set(this.state.campaignDetailAssign.map((p) => p.pID))
						).map((pID) => {
							return {
								pID: pID,
								publisherName: this.state.campaignDetailAssign.find(
									(p) => p.pID === pID
								).publisherName,
								startDate: this.state.campaignDetailAssign.find(
									(p) => p.pID === pID
								).startDate,
								endDate: this.state.campaignDetailAssign.find(
									(p) => p.pID === pID
								).endDate,
								allocatedLead: this.state.campaignDetailAssign.find(
									(p) => p.pID === pID
								).allocatedLead,
								cpl:
									this.state.campaignDetailAssign.find((p) => p.pID === pID)
										.cpl != null
										? this.state.campaignDetailAssign.find((p) => p.pID === pID)
												.cpl
										: this.state.campaignDetailAssign.find((p) => p.pID === pID)
												.campCPL,
								firstLeadDeliveryDate: this.state.campaignDetailAssign.find(
									(p) => p.pID === pID
								).firstLeadDeliveryDate,
								rating: this.state.campaignDetailAssign.find(
									(p) => p.pID === pID
								).rating,
								gdprCompliance: this.state.campaignDetailAssign.find(
									(p) => p.pID === pID
								).gdprCompliance,
							};
						});
						// alert("Array==="+JSON.stringify(result));

						let mergedCampaignDetailAssign = [];
						// let liveDeliveryStatusListTemp = this.state.sumOfAllocatedLeads;
						mergedCampaignDetailAssign = result.map((e) =>
							Object.assign(
								{},
								e,
								this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) || {}
							)
						);

						this.setState({
							mergedCampaignDetailAssign: mergedCampaignDetailAssign,
						});
						//alert("Array==="+JSON.stringify(this.state.mergedCampaignDetailAssign));
					})
					.catch(function (err) {
						console.log(err);
					});
			})
			.catch(function (err) {
				console.log(err);
			});
		/** Get campaign Data to show on popup */
		fetch("/publisher/allocatingCampaignID?campID=" + campID)
			.then((res2) => res2.json())
			.then((campaignDetailID) => {
				this.setState({ campaignDetailID: campaignDetailID });
				if (this.state.campaignDetailID && this.state.campaignDetailID.length) {
					var region = this.state.campaignDetailID[0].region;
					var str_array = region.split(",");
					for (var i = 0; i < str_array.length; i++) {
						regionArray.push(str_array[i]);
					}
					for (var j = 0; j < regionArray.length; j++) {
						if (
							regionArray[j] === "Europe" ||
							europeRegion.includes(regionArray[j])
						) {
							this.setState({ gdprStatus: "true", region: true });
						}
					}
				}
			});
		total = this.state.campaignDetailID.leadAllocation;
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the multiple allocatation of campaign
	 * @return Description return successfully allocate campaign message
	 */
	handleChangeDynamicPublisher(i, e) {
		const { name, value } = e.target;
		let newDynamicArray = [...this.state.newDynamicArray];
		newDynamicArray[i] = { ...newDynamicArray[i], [name]: value };

		// this.state.newDynamicArray = [...newDynamicArray];
		this.setState({newDynamicArray:[...newDynamicArray]});//kiran-4745-removing console warning-use setState

		console.warn(
			"dynamic array test In Handle Change===>" +
				JSON.stringify(this.state.newDynamicArray)
		);

		// console.warn("Dynamic Objects===>"+JSON.stringify(this.state.newDynamicArray))
	} // end of  handleChangeDynamicPublisher

	handleEditCampaign(e) {
		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampID");
		var reallocationID = e.target.getAttribute("reallocationID");
		this.props.history.push("/welcomeEdit", {
			campID,
			parentCampID,
			reallocationID,
			step: this.state.toStep,
		}); // karan-task-3714-vapt header and query params
	}

	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			//karan-task-3715-replace query params
			let tab, campID;
			if (this.props.location.state !== undefined) {
				campID = this.props.location.state.campID;
				tab = this.props.location.state.activeKey;
			}
			const {  user } = this.props.auth;
			const token = user.token; //shivani-task 2993- added code to declare token and using this token to authenticate the api.
			this.setState({ campID: campID, token }); //shivani-task 2993- added code to setState the token to increase api security.
			var agencyID = user.id;
			this.setState({ agencyID: agencyID });
			if (tab === "Reject") {
				// var tabReviewReject = "Reject";
				fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
					.then((res) => res.json())
					.then((notifyDashNewCamp) => {
						this.setState({
							biddingArray: notifyDashNewCamp[2],
							headExample1: "head-example",
							headExample: "head-exampleGeneral1",
							loading1: false,
							campLableName: "Rejected Campaign",
							rejectedCampLength: notifyDashNewCamp[2].length,
							tab: tab,
							campID: campID,
							counteredCampLength: notifyDashNewCamp[1].length,
						});

						// this.setState({rejectedCampLength:notifyDashNewCamp[2].length})
					})
					.catch(function (err) {
						console.log(err);
					});
			} else if (tab === undefined) {
				//  alert("inside elseif");
				//getting Campaign ID which status is New, Counter, Reject
				// var tabReviewCounter = "Counter";

				fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
					.then((res) => res.json())
					.then((notifyDashNewCamp) => {
						var tempArray = notifyDashNewCamp[1];
						var campID = tempArray[0].campID;

						// alert("campID"+JSON.stringify(notifyDashNewCamp));
						this.setState({
							biddingArray: notifyDashNewCamp[1],
							headExample: "head-example",
							headExample1: "head-exampleGeneral1",
							loading1: false,
							campLableName: "Countered Campaign",
							counteredCampLength: notifyDashNewCamp[1].length,
							rejectedCampLength: notifyDashNewCamp[2].length,
							tab: tab,
							defaultCampID: campID,
							campID: campID,
						});

						//  alert("willMountnotifyCamp1=>"+notifyDashNewCamp[2].length);
					})
					.catch(function (err) {
						console.log(err);
					});

				// var tabReviewReject = "Reject";
				fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
					.then((res) => res.json())
					.then((notifyDashNewCamp) => {
						this.setState({ rejectedCampLength: notifyDashNewCamp[2].length });
						//  alert("willMountnotifyCamp2=>"+notifyDashNewCamp[2].length);
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				//  alert("hiii");
				//getting Campaign ID which status is New, Counter, Reject
				// var tabReviewCounter = "Counter";

				fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
					.then((res) => res.json())
					.then((notifyDashNewCamp) => {
						var tempArray = notifyDashNewCamp[1];
						var campID = tempArray[0].campID;

						// alert("campID"+JSON.stringify(notifyDashNewCamp));
						this.setState({
							biddingArray: notifyDashNewCamp[1],
							headExample: "head-example",
							headExample1: "head-exampleGeneral1",
							loading1: false,
							campLableName: "Countered Campaign",
							counteredCampLength: notifyDashNewCamp[1].length,
							rejectedCampLength: notifyDashNewCamp[2].length,
							tab: tab,
							defaultCampID: campID,
							campID: campID,
						});

						// alert("willMountnotifyCamp1=>"+notifyDashNewCamp[2].length);
					})
					.catch(function (err) {
						console.log(err);
					});
			}

			let self = this;
			fetch("publisher/toolTipData")
				.then((res) => res.json())
				.then((toolTipData) => self.setState({ toolTipData: toolTipData }))
				.catch(function (err) {
					console.log(err);
				});
//kiran-4745-removing console warning-changed == to ===
			if (tab === undefined || tab == null || tab === "") {
				let self = this;

				fetch("publisher/allocatedCampaign?agencyID=" + agencyID)
					.then((res) => res.json())
					.then((liveCampaignList) => {
						var campID = this.state.defaultCampID;
						let filterLiveCampArray = liveCampaignList.filter((a) => {
							return a.campID == campID;
						});
						//  alert("liveCampaignListLength"+JSON.stringify(filterLiveCampArray));
						self.setState({
							liveCampaignList: filterLiveCampArray,
							campID: campID,
						});
						//  alert("livecamplist4"+JSON.stringify(liveCampaignList))
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				//  alert("tab1===>"+tab);
				fetch("agency/campaignAllocated?campID=" + campID)
					.then((res) => res.json())
					.then((liveCampaignList) => {
						self.setState({
							liveCampaignList: liveCampaignList,
							campID: campID,
						});
						// alert("livecamplist3==>"+JSON.stringify(liveCampaignList));
					})
					.catch(function (err) {
						console.log(err);
					});

				localStorage.removeItem("counterID");
			}
		}
	}

	render() {
		const {  user } = this.props.auth;
		return (
			<div>
				<Navigation />
				<div class="container-fluid" style={{ paddingTop: "115px" }}>
					{/* <div>
                   <a href={'dashboard?agencyID='+this.state.agencyID}  style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Dashboard"/></a>

                   </div> */}

					<div class="row">
						<div style={{ paddingLeft: "32px" }}>
							{/* {this.state.agencyID} */}
							{/* kiran-4745-removing console warning-added "#/" and  e.preventDefault();*/}
							<a
							href="#/"
								onClick={(e) =>
									{
										e.preventDefault();
									this.props.history.push("/newdashboard", {
										agencyID: this.state.agencyID,
									})
								}} //karan-task-3684-replace query params
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back to Dashboard"
								/>
							</a>
						</div>
						{/* //shivani-3284-passed ID for DI label consistency */}
						<label id="labelDI" style={{ marginLeft: "575px" }}>
							Campaign Respond
						</label>
					</div>
					<div>
						<Badge
							count={this.state.counteredCampLength}
							overflowCount={10}
							offset={[1, -5]}
							style={{ right: "12px", marginTop: "-6px" }}>
							<Button
								type="default"
								size="small"
								className={this.state.headExample}
								id="counter"
								onClick={this.biddingLink}
								style={{
									width: 102,
									marginRight: 12,
									marginLeft: 15,
									borderRadius: 20,
								}}>
								<span style={{ font: "8px" }}>Countered</span>
							</Button>
						</Badge>

						<Badge
							count={this.state.rejectedCampLength}
							overflowCount={10}
							offset={[1, -5]}
							style={{ right: "-2px", marginTop: "-6px" }}>
							<Button
								type="default"
								size="small"
								className={this.state.headExample1}
								id="reject"
								onClick={this.biddingLink}
								style={{ width: 102, borderRadius: 20 }}>
								<span style={{ font: "8px" }}>Rejected</span>
							</Button>
						</Badge>
					</div>

					<div class="container" style={{ marginLeft: "2px" }}>
						<div class="row " style={{ paddingTop: "12px" }}>
							{/* <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5"> */}
							<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
								<div
									style={{
										background: "#FFFFFF",
										padding: "15px 8px",
										height: "385px",
										overflowY: "scroll",
									}}>
									{/* <div> */}
									<h4
										style={{
											fontFamily: "roboto",
											color: "#124E9C",
											fontSize: "medium",
											paddingBottom: "0px",
											marginLeft: "6px",
										}}>
										{this.state.campLableName}
										{/* <span style={{marginLeft:'10px'}}>
                                            <Badge count={this.state.campLength} ></Badge> 
                                            </span> */}
									</h4>
									<hr />
									{/* </div> */}

									{/* <div> */}

									{this.state.biddingArray.length > 0 ? (
										<div>
											{this.state.biddingArray.map((biddingArray) => (
												<div>
													<span>
														{this.state.campID === biddingArray.campID ? (
															<u>
																<a
																	href="#/"
																	id={biddingArray.campID}
																	onClick={this.campHandleChange}>
																	Campaign ID:{biddingArray.campID}
																	<br />
																	{biddingArray.campaignName}
																</a>
															</u>
														) : (
															<a
																href="#/"
																id={biddingArray.campID}
																onClick={this.campHandleChange}>
																Campaign ID:{biddingArray.campID}
																<br />
																{biddingArray.campaignName}
															</a>
														)}
													</span>
													<hr />
												</div>
											))}
										</div>
									) : (
										<center>
											<label style={{ color: "red", fontSize: "medium" }}>
												No Data Exist
											</label>
										</center>
									)}

									{/* </div> */}
									{/* </div> */}
								</div>
							</div>

							<div class=" col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
								{this.state.campLableName === "Countered Campaign" ? (
									this.state.liveCampaignList.length > 0 ? (
										<div
											style={{
												overflowY: "scroll",
												overflowX: "hidden",
												width: "904px",
												height: "387px",
											}}>
											{this.state.liveCampaignList.map((liveCampaignList) =>
												liveCampaignList.status === "Counter" ? (
													<div>
														<div
															style={{
																background: "#FFFFFF",
																height: "439px",
																padding: "18px 13px 10px 14px",
															}}>
															<div class="camp" style={{ marginLeft: "27px" }}>
																<div>
																	<label style={{ paddingBottom: "1px" }}>
																		{" "}
																		Campaign ID :&nbsp;{" "}
																		{liveCampaignList.campID}
																	</label>
																</div>

																<div>
																	<label style={{ paddingBottom: "15px" }}>
																		Campaign Name :&nbsp;{" "}
																		{liveCampaignList.campaignName}
																	</label>
																</div>

																<div class="row">
																	<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																		<span>Campaign Type</span>
																		&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
																		{liveCampaignList.ABM}
																	</div>
																	<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																		<span style={{ width: "39%" }}>
																			Start Date
																			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																		</span>
																		&nbsp;:&nbsp;{liveCampaignList.startDate}
																	</div>
																	<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																		<span>End Date</span>&nbsp;:&nbsp;
																		{liveCampaignList.endDate}
																	</div>
																</div>

																<div class="row">
																	<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																		<span style={{ width: "40%" }}>
																			Total
																			Leads&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																		</span>
																		:&nbsp;{liveCampaignList.leadAllocation}
																	</div>
																	<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																		<span>Total Allocated</span>&nbsp;:&nbsp;
																		{liveCampaignList.totalCounterAllocatedLead
																			? liveCampaignList.totalCounterAllocatedLead
																			: "0"}
																	</div>
																	<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																		<span style={{ width: "25%" }}>
																			Balanced
																		</span>
																		&nbsp;:&nbsp;
																		{liveCampaignList.totalCounterAllocatedLead
																			? liveCampaignList.leadAllocation -
																					liveCampaignList.totalCounterAllocatedLead <
																			  0
																				? 0
																				: liveCampaignList.leadAllocation -
																				  liveCampaignList.totalCounterAllocatedLead
																			: liveCampaignList.leadAllocation}
																	</div>
																</div>

																{/* <div>
                                                           <label style={{fontWeight:'normal',marginRight:'6px'}}>Campaign Type</label>:<span style={{marginRight:'125px'}}>&nbsp;{liveCampaignList.ABM}</span>
                                                            <label style={{fontWeight:'normal',marginRight:'35px'}}>Start Date </label>:<span style={{marginRight:'96px'}}>&nbsp;{liveCampaignList.startDate}</span>
                                                            <label style={{fontWeight:'normal',marginRight:'6px'}}>End Date </label>:<span>&nbsp;{liveCampaignList.endDate}</span>
                                                       </div> */}

																{/* <div>
                                                            <label style={{fontWeight:'normal',marginRight:'29px'}}>Total Leads </label> :<span style={{marginRight:'136px'}}>&nbsp;{liveCampaignList.leadAllocation}</span>
                                                             <label style={{fontWeight:'normal',marginRight:'2px'}}>Total Allocated </label> :<span style={{marginRight:'153px'}}>&nbsp;{liveCampaignList.totalCounterAllocatedLead?liveCampaignList.totalCounterAllocatedLead: "0"}</span>
                                                             <label style={{fontWeight:'normal',marginRight:'2px'}}>Balanced </label> :<span>&nbsp;{liveCampaignList.totalCounterAllocatedLead?(liveCampaignList.leadAllocation-liveCampaignList.totalCounterAllocatedLead)<0?0:(liveCampaignList.leadAllocation-liveCampaignList.totalCounterAllocatedLead):liveCampaignList.leadAllocation}</span>
                                                      </div> 
                                                        */}
															</div>

															{/* {this.state.liveCampaignList.map(liveCampaignList => ( */}
															<div>
																{/* <span style={{ color: 'orange', fontWeight: 'bold' }}>{liveCampaignList.status=='Counter'?'Counter':'Allocated'}</span> */}
																{/* kiran-4745-removing console warning-changed == to === */}
																{this.state.toolTipData.map((toolTipData) => {
																	if (
																		toolTipData.campID === liveCampaignList.campID &&
																		toolTipData.status === "Counter" &&
																		toolTipData.pID === liveCampaignList.pID &&
																		toolTipData.allocationID ===
																			liveCampaignList.allocationID
																	) {
																		return (
																			<div>
																				{" "}
																				{toolTipData.counterLead *
																					toolTipData.counterCPL -
																					toolTipData.allocatedLead *
																						toolTipData.CPL +
																					toolTipData.budget >
																				toolTipData.budget ? (
																					<span>
																						<div
																							class="row"
																							style={{
																								marginLeft: "19px",
																								paddingTop: "7px",
																							}}>
																							<div
																								class="row"
																								style={{
																									paddingTop: "17px",
																									display: "inlineBlock",
																									margin: "10px",
																									border: "2px solid #eee",
																									width: "300px",
																									height: "154px",
																								}}>
																								<span
																									style={{
																										color: "orange",
																										fontWeight: "normal",
																										fontSize: "16px",
																										marginLeft: "25px",
																										width: "136px",
																									}}>
																									Old Details &nbsp;:
																								</span>

																								<div>
																									<label
																										style={{
																											width: "103px",
																											marginLeft: "26px",
																											fontWeight: "normal",
																										}}>
																										Allocated Leads
																									</label>
																									:
																									<span>
																										&nbsp;
																										{toolTipData.allocatedLead}
																									</span>
																								</div>
																								{user.role === "AC" ? (
																									<div>
																										<label
																											style={{
																												width: "92px",
																												marginLeft: "24px",
																												fontWeight: "normal",
																											}}>
																											Allocated CPL
																										</label>
																										:
																										<span>
																											&nbsp;{toolTipData.CPL}
																											&nbsp;
																											{toolTipData.currency}
																										</span>
																									</div>
																								) : (
																									""
																								)}
																							</div>
																							<div
																								class="row"
																								style={{
																									paddingTop: "17px",
																									display: "inlineBlock",
																									margin: "10px",
																									border: "2px solid #eee",
																									width: "300px",
																									height: "154px",
																								}}>
																								<span
																									style={{
																										color: "orange",
																										fontWeight: "normal",
																										fontSize: "16px",
																										marginLeft: "25px",
																									}}>
																									Countered Details &nbsp;:
																								</span>
																								<div>
																									<label
																										style={{
																											width: "93px",
																											fontWeight: "normal",
																											marginLeft: "22px",
																										}}>
																										&nbsp;Countered by
																									</label>
																									:
																									<span>
																										&nbsp;
																										{toolTipData.publisherName}
																									</span>
																								</div>
																								<div>
																									<label
																										style={{
																											width: "113px",
																											fontWeight: "normal",
																											marginLeft: "22px",
																										}}>
																										&nbsp;Countered Leads
																									</label>
																									:
																									<span>
																										&nbsp;
																										{toolTipData.counterLead}
																									</span>
																								</div>
																								{user.role === "AC" ? (
																									<div>
																										<label
																											style={{
																												width: "102px",
																												fontWeight: "normal",
																												marginLeft: "22px",
																											}}>
																											&nbsp;Countered CPL
																										</label>
																										:
																										<span>
																											&nbsp;
																											{toolTipData.counterCPL}
																											&nbsp;
																											{toolTipData.currency}
																										</span>
																									</div>
																								) : (
																									""
																								)}
																							</div>
																						</div>

																						{user.role === "AC" ? (
																							<div
																								style={{ paddingTop: "20px" }}>
																								<p>
																									&nbsp;
																									<span
																										style={{
																											fontWeight: "bold",
																											color: "#ff000cad",
																											marginLeft: "24px",
																										}}>
																										Your Original Budget is
																									</span>{" "}
																									<span
																										style={{
																											fontWeight: "bold",
																											color: "#ff000cad",
																										}}>
																										{toolTipData.budget}&nbsp;
																										{toolTipData.currency}.
																									</span>
																									&nbsp;{" "}
																									<span
																										style={{
																											fontWeight: "bold",
																											color: "#ff000cad",
																										}}>
																										If you accept this countered
																										proposal then budget will
																										increase by{" "}
																									</span>
																									<span
																										style={{
																											fontWeight: "bold",
																											color: "#ff000cad",
																										}}>
																										{(
																											toolTipData.counterLead *
																												toolTipData.counterCPL -
																											toolTipData.allocatedLead *
																												toolTipData.CPL
																										).toFixed(2)}
																										&nbsp;{toolTipData.currency}
																										.
																									</span>
																									&nbsp;
																									<br />
																									<span
																										style={{
																											fontWeight: "bold",
																											color: "#ff000cad",
																											marginLeft: "28px",
																										}}>
																										Total budget will be{" "}
																										{parseFloat(
																											toolTipData.budget
																										) +
																											parseFloat(
																												(
																													toolTipData.counterLead *
																														toolTipData.counterCPL -
																													toolTipData.allocatedLead *
																														toolTipData.CPL
																												).toFixed(2)
																											)}
																										.
																									</span>{" "}
																								</p>
																							</div>
																						) : (
																							""
																						)}
																					</span>
																				) : (
																					<span>
																						<div
																							class="row"
																							style={{
																								marginLeft: "19px",
																								paddingTop: "7px",
																							}}>
																							<div
																								class="row"
																								style={{
																									paddingTop: "17px",
																									display: "inlineBlock",
																									margin: "10px",
																									border: "2px solid #eee",
																									width: "300px",
																									height: "154px",
																								}}>
																								<span
																									style={{
																										color: "orange",
																										fontWeight: "normal",
																										fontSize: "16px",
																										marginLeft: "25px",
																										width: "136px",
																									}}>
																									Old Details &nbsp;:
																								</span>

																								<div>
																									<label
																										style={{
																											width: "103px",
																											marginLeft: "26px",
																											fontWeight: "normal",
																										}}>
																										Allocated Leads
																									</label>
																									:
																									<span>
																										&nbsp;
																										{toolTipData.allocatedLead}
																									</span>
																								</div>

																								{user.role === "AC" ? (
																									<div>
																										<label
																											style={{
																												width: "92px",
																												marginLeft: "24px",
																												fontWeight: "normal",
																											}}>
																											Allocated CPL
																										</label>
																										:
																										<span>
																											&nbsp;{toolTipData.CPL}
																											&nbsp;
																											{toolTipData.currency}
																										</span>
																									</div>
																								) : (
																									""
																								)}
																							</div>
																							<div
																								class="row"
																								style={{
																									paddingTop: "17px",
																									display: "inlineBlock",
																									margin: "10px",
																									border: "2px solid #eee",
																									width: "300px",
																									height: "154px",
																								}}>
																								<span
																									style={{
																										color: "orange",
																										fontWeight: "normal",
																										fontSize: "16px",
																										marginLeft: "25px",
																									}}>
																									Countered Details &nbsp;:
																								</span>
																								<div>
																									<label
																										style={{
																											width: "90px",
																											fontWeight: "normal",
																											marginLeft: "25px",
																										}}>
																										Countered by
																									</label>
																									:
																									<span>
																										&nbsp;
																										{toolTipData.publisherName}
																									</span>
																								</div>
																								<div>
																									<label
																										style={{
																											width: "109px",
																											fontWeight: "normal",
																											marginLeft: "25px",
																										}}>
																										Countered Leads
																									</label>
																									:
																									<span>
																										&nbsp;
																										{toolTipData.counterLead}
																									</span>
																								</div>
																								{user.role === "AC" ? (
																									<div>
																										<label
																											style={{
																												width: "98px",
																												fontWeight: "normal",
																												marginLeft: "25px",
																											}}>
																											Countered CPL
																										</label>
																										:
																										<span>
																											&nbsp;
																											{toolTipData.counterCPL}
																											&nbsp;
																											{toolTipData.currency}
																										</span>
																									</div>
																								) : (
																									""
																								)}
																							</div>
																						</div>
																					</span>
																				)}
																				{user.role === "AC" ? (
																					<div class="text-right">
																						<div style={{ paddingTop: "15px" }}>
																							<button
																								type="button"
																								class="btn btn-default"
																								id={
																									liveCampaignList.allocationID
																								}
																								data-toggle="modal"
																								data-target="#acceptCounter"
																								parentCampID={
																									liveCampaignList.parentCampID
																										? liveCampaignList.parentCampID
																										: liveCampaignList.campID
																								}
																								reallocationID={
																									liveCampaignList.reallocationID
																								}
																								onClick={
																									this
																										.handleCampaignIDOnClickAccept
																								}
																								style={{
																									color: "#124E9C",
																									marginRight: "22px",
																									borderColor: "#124E9C",
																									width: "77px",
																									padding: "1px",
																								}}>
																								Accept
																							</button>

																							<button
																								type="button"
																								class="btn btn-default"
																								id={
																									liveCampaignList.allocationID
																								}
																								data-toggle="modal"
																								data-target="#rejectCounter"
																								parentCampID={
																									liveCampaignList.parentCampID
																										? liveCampaignList.parentCampID
																										: liveCampaignList.campID
																								}
																								reallocationID={
																									liveCampaignList.reallocationID
																								}
																								onClick={
																									this
																										.handleCampaignIDOnClickAccept
																								}
																								style={{
																									color: "#124E9C",
																									marginRight: "22px",
																									borderColor: "#124E9C",
																									width: "77px",
																									padding: "1px",
																								}}>
																								Reject
																							</button>

																							<button
																								type="button"
																								class="btn btn-default"
																								id={liveCampaignList.campID}
																								name={liveCampaignList.pID}
																								data-toggle="modal"
																								data-target="#counterAllocation"
																								parentCampID={
																									liveCampaignList.parentCampID
																										? liveCampaignList.parentCampID
																										: liveCampaignList.campID
																								}
																								reallocationID={
																									liveCampaignList.reallocationID
																								}
																								style={{
																									color: "#124E9C",
																									marginRight: "22px",
																									borderColor: "#124E9C",
																									width: "77px",
																									padding: "1px",
																								}}
																								onClick={
																									this.handleCounterAllocateID
																								}>
																								Allocate
																							</button>
																						</div>
																					</div>
																				) : (
																					<div class="text-right">
																						<div style={{ paddingTop: "15px" }}>
																							<button
																								type="button"
																								class="btn btn-default"
																								id={
																									liveCampaignList.allocationID
																								}
																								data-toggle="modal"
																								data-target="#acceptCounter"
																								parentCampID={
																									liveCampaignList.parentCampID
																										? liveCampaignList.parentCampID
																										: liveCampaignList.campID
																								}
																								reallocationID={
																									liveCampaignList.reallocationID
																								}
																								disabled={true}
																								onClick={
																									this
																										.handleCampaignIDOnClickAccept
																								}
																								style={{
																									color: "#124E9C",
																									marginRight: "22px",
																									borderColor: "#124E9C",
																									width: "77px",
																									padding: "1px",
																								}}>
																								Accept
																							</button>

																							<button
																								type="button"
																								class="btn btn-default"
																								id={
																									liveCampaignList.allocationID
																								}
																								data-toggle="modal"
																								data-target="#rejectCounter"
																								parentCampID={
																									liveCampaignList.parentCampID
																										? liveCampaignList.parentCampID
																										: liveCampaignList.campID
																								}
																								reallocationID={
																									liveCampaignList.reallocationID
																								}
																								disabled={true}
																								onClick={
																									this
																										.handleCampaignIDOnClickAccept
																								}
																								style={{
																									color: "#124E9C",
																									marginRight: "22px",
																									borderColor: "#124E9C",
																									width: "77px",
																									padding: "1px",
																								}}>
																								Reject
																							</button>

																							<button
																								type="button"
																								class="btn btn-default"
																								id={liveCampaignList.campID}
																								name={liveCampaignList.pID}
																								data-toggle="modal"
																								data-target="#counterAllocation"
																								parentCampID={
																									liveCampaignList.parentCampID
																										? liveCampaignList.parentCampID
																										: liveCampaignList.campID
																								}
																								reallocationID={
																									liveCampaignList.reallocationID
																								}
																								style={{
																									color: "#124E9C",
																									marginRight: "22px",
																									borderColor: "#124E9C",
																									width: "77px",
																									padding: "1px",
																								}}
																								disabled={true}
																								onClick={
																									this.handleCounterAllocateID
																								}>
																								Allocate
																							</button>
																						</div>
																					</div>
																				)}
																				<br />
																			</div>
																		);
																	}
																	return [];
																})}
															</div>
															{/* //  ))} */}
														</div>
														<br />
													</div>
												) : (
													""
												)
											)}
										</div>
									) : (
										""
									)
								) : (
									<div>
										{this.state.liveCampaignList.length > 0 ? (
											<div
												style={{
													overflowY: "scroll",
													overflowX: "hidden",
													width: "904px",
													height: "385px",
												}}>
												{this.state.liveCampaignList.map((liveCampaignList) =>
													liveCampaignList.status === "Reject" ? (
														<div>
															<div
																style={{
																	background: "#FFFFFF",
																	padding: "18px 13px 10px 14px",
																	height: "305px",
																}}>
																<div
																	class="camp"
																	style={{ marginLeft: "27px" }}>
																	<label
																		style={{
																			color: "#ff000cad",
																			fontSize: "medium",
																			paddingBottom: "3px",
																		}}>
																		Campaign rejected by&nbsp;
																		{liveCampaignList.publisherName}
																	</label>
																	<div>
																		<label style={{ paddingBottom: "1px" }}>
																			{" "}
																			Campaign ID :&nbsp;{" "}
																			{liveCampaignList.campID}
																		</label>
																	</div>

																	<div>
																		<label style={{ paddingBottom: "15px" }}>
																			Campaign Name :&nbsp;{" "}
																			{liveCampaignList.campaignName}
																		</label>
																	</div>
																	{/*                                         
                                            <div>
                                                <label style={{fontWeight:'normal',marginRight:'98px'}}>Campaign Type :&nbsp;{liveCampaignList.ABM}&nbsp;&nbsp;&nbsp;</label> 
                                                <label style={{fontWeight:'normal',marginRight:'75px'}}>Start Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{liveCampaignList.startDate}&nbsp;&nbsp;&nbsp;</label>   
                                                <label style={{fontWeight:'normal',marginRight:'98px'}}>End Date :&nbsp;{liveCampaignList.endDate}</label>
                                           </div>
                                        
                                            <div>
                                                <label style={{fontWeight:'normal',marginRight:'121px'}}>Total Leads &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{liveCampaignList.leadAllocation}</label>
                                                 <label style={{fontWeight:'normal',marginRight:'144px'}}>Total Allocated :&nbsp;{liveCampaignList.totalRejectAllocatedLead?liveCampaignList.totalRejectAllocatedLead: "0"}</label>
                                                 <label style={{fontWeight:'normal',marginRight:'98px'}}>Balanced :&nbsp;{liveCampaignList.totalRejectAllocatedLead?(liveCampaignList.leadAllocation-liveCampaignList.totalRejectAllocatedLead)<0?0:(liveCampaignList.leadAllocation-liveCampaignList.totalRejectAllocatedLead):liveCampaignList.leadAllocation}</label> 
                                          </div>
                                           */}

																	<div class="row">
																		<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																			<span>Campaign Type</span>
																			&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
																			{liveCampaignList.ABM}
																		</div>
																		<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																			<span style={{ width: "39%" }}>
																				Start Date
																				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																			</span>
																			&nbsp;:&nbsp;{liveCampaignList.startDate}
																		</div>
																		<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																			<span>End Date</span>&nbsp;:&nbsp;
																			{liveCampaignList.endDate}
																		</div>
																	</div>
																	<div class="row">
																		<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																			<span style={{ width: "40%" }}>
																				Total
																				Leads&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																			</span>
																			:&nbsp;{liveCampaignList.leadAllocation}
																		</div>
																		<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																			<span>Total Allocated</span>&nbsp;:&nbsp;
																			{liveCampaignList.totalRejectAllocatedLead
																				? liveCampaignList.totalRejectAllocatedLead
																				: "0"}
																		</div>
																		<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
																			<span style={{ width: "25%" }}>
																				Balanced
																			</span>
																			&nbsp;:&nbsp;
																			{liveCampaignList.totalRejectAllocatedLead
																				? liveCampaignList.leadAllocation -
																						liveCampaignList.totalRejectAllocatedLead <
																				  0
																					? 0
																					: liveCampaignList.leadAllocation -
																					  liveCampaignList.totalRejectAllocatedLead
																				: liveCampaignList.leadAllocation}
																		</div>
																	</div>

																	{this.state.toolTipData.map((toolTipData) => {
																		if (
																			toolTipData.allocationID ===
																				liveCampaignList.allocationID &&
																			toolTipData.status === "Reject" &&
																			toolTipData.pID === liveCampaignList.pID
																		) {
																			return (
																				<div>
																					<div class="row">
																						<span
																							style={{ marginLeft: "11px" }}>
																							&nbsp;Rejection Reason &nbsp;:
																						</span>
																					</div>
																					<div
																						class="row"
																						style={{ paddingBottom: "6px" }}>
																						<textarea
																							style={{
																								resize: "none",
																								width: "452px",
																								height: "66px",
																								marginLeft: "15px",
																								borderRadius: "5px",
																								marginTop: "4px",
																								textAlign: "justify",
																								borderColor: "#D3D3D3",
																							}}>
																							{toolTipData.reasonOfRejection
																								? unescape(
																										toolTipData.reasonOfRejection
																								  )
																								: ""}
																						</textarea>
																					</div>
																				</div>
																			);
																		}
																		return [];
																	})}

																	{user.role === "AC" ? (
																		<div class="text-right">
																			<div>
																				<button
																					type="button"
																					class="btn btn-default"
																					id={liveCampaignList.campID}
																					name={liveCampaignList.pID}
																					parentCampID={
																						liveCampaignList.parentCampID
																							? liveCampaignList.parentCampID
																							: liveCampaignList.campID
																					}
																					reallocationID={
																						liveCampaignList.reallocationID
																					}
																					data-toggle="modal"
																					data-target="#campAllocation"
																					onClick={this.handleCampaignID}
																					style={{
																						color: "#124E9C",
																						marginRight: "22px",
																						borderColor: "#124E9C",
																						width: "77px",
																						padding: "1px",
																					}}>
																					Allocate
																				</button>
																				<button
																					type="button"
																					class="btn btn-default"
																					id={liveCampaignList.campID}
																					parentCampID={
																						liveCampaignList.parentCampID
																							? liveCampaignList.parentCampID
																							: liveCampaignList.campID
																					}
																					reallocationID={
																						liveCampaignList.reallocationID
																					}
																					onClick={this.handleEditCampaign}
																					style={{
																						color: "#124E9C",
																						marginRight: "22px",
																						borderColor: "#124E9C",
																						width: "77px",
																						padding: "1px",
																					}}>
																					Edit
																				</button>
																			</div>
																		</div>
																	) : (
																		<div class="text-right">
																			<div>
																				<button
																					type="button"
																					class="btn btn-default"
																					id={liveCampaignList.campID}
																					name={liveCampaignList.pID}
																					parentCampID={
																						liveCampaignList.parentCampID
																							? liveCampaignList.parentCampID
																							: liveCampaignList.campID
																					}
																					reallocationID={
																						liveCampaignList.reallocationID
																					}
																					data-toggle="modal"
																					data-target="#campAllocation"
																					disabled={true}
																					onClick={this.handleCampaignID}
																					style={{
																						color: "#124E9C",
																						marginRight: "22px",
																						borderColor: "#124E9C",
																						width: "77px",
																						padding: "1px",
																					}}>
																					Allocate
																				</button>
																				<button
																					type="button"
																					class="btn btn-default"
																					id={liveCampaignList.campID}
																					parentCampID={
																						liveCampaignList.parentCampID
																							? liveCampaignList.parentCampID
																							: liveCampaignList.campID
																					}
																					reallocationID={
																						liveCampaignList.reallocationID
																					}
																					disabled={true}
																					onClick={this.handleEditCampaign}
																					style={{
																						color: "#124E9C",
																						marginRight: "22px",
																						borderColor: "#124E9C",
																						width: "77px",
																						padding: "1px",
																					}}>
																					Edit
																				</button>
																			</div>
																		</div>
																	)}
																</div>
															</div>
															<br />
														</div>
													) : (
														""
													)
												)}
											</div>
										) : (
											""
										)}
									</div>
								)}
							</div>
						</div>
						<Footer />
					</div>
				</div>
				{/* {start of Counter Campaign Allocate } */}
				<div
					class="modal fade"
					id="counterAllocation"
					role="dialog"
					style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
					{" "}
					{/* //shivani-task 3237-made background blur */}
					<div class="modal-dialog modal-xl">
						{/* <!-- Modal content--> */}
						<div class="modal-content" style={{ marginTop: "152px" }}>
							{/* shivani-task 3237-modal shifted down by applying margin */}
							<div class="modal-header custom-class">
								{" "}
								{/* Counter Allocation */}
								{/* <label id="label" style={{fontSize:"larger",color:'white'}}>Campaign Allocation 456</label> */}
								{this.state.counterCampID.map((counterCampID) => (
									<div
										class="col-sm-11 text-left"
										style={{ fontSize: "17px", paddingTop: "7px" }}>
										Campaign Allocation:&nbsp;{this.state.parentCampID}&nbsp;
										-&nbsp; {counterCampID.campaignName}
									</div>
								))}
								<button
									type="button"
									class="cancel-button"
									style={{ fontSize: 22, color: "#ffffff", marginLeft: "35px" }}
									onClick={this.handleRefreshPage}
									data-dismiss="modal">
									&times;
								</button>
								{/* <button type="button" class="cancel-button" style={{fontSize:18,paddingLeft:'760px',outline:'none'}} onClick={this.handleRefreshPage} data-dismiss="modal">&times;</button> */}
								{/* shivani-task 3237-changed the size of cross button */}
							</div>
							{/* message */}
							<div className="" style={{ display: this.state.alertDisplay }}>
								<div class="container-fluid">
									{/* <div class="card card-signin my-1"> */}
									<div class="card-body">
										<div class="row">
											<div
												class="col-lg-12"
												style={{ border: this.state.alertBorder }}>
												<div class="errorMessage">
													{this.state.errors.endDate
														? this.state.errors.endDate
														: ""}
													{this.state.errors.allocatedLead
														? this.state.errors.allocatedLead
														: ""}
													{this.state.errors.reject
														? this.state.errors.reject
														: ""}
													{this.state.puberror.one
														? this.state.puberror.one
														: ""}
													{this.state.errors.lead ? this.state.errors.lead : ""}
													{this.state.allocerrors.allocate
														? this.state.allocerrors.allocate
														: ""}
													{this.state.reerrors.remaining
														? this.state.reerrors.remaining
														: ""}
													{this.state.dateerrors.date
														? this.state.dateerrors.date
														: ""}

													{this.state.errors.cpl ? this.state.errors.cpl : ""}
													{this.state.errors.cpl1 ? this.state.errors.cpl1 : ""}
													{this.state.errors.cpl2 ? this.state.errors.cpl2 : ""}
													{this.state.errors.cpl3 ? this.state.errors.cpl3 : ""}
												</div>
											</div>
											<div class="successMessage">{this.state.newsuccess} </div>
										</div>
									</div>
								</div>
							</div>
							{/*end of  message */}
							{/* <!-- Modal body--> */}
							<div class="modal-body">
								<div class="container-fluid">
									<div style={{ border: "1px solid #cdcdcd" }}>
										{this.state.counterCampID.map((counterCampID) => (
											<div class="container-fluid">
												<div class="row">
													<div class="col-sm-4">
														<table>
															<tr>
																<td>
																	{" "}
																	<label id="label">Campaign Region</label>
																</td>
																<td>:&nbsp;{counterCampID.region}</td>
															</tr>
															<tr>
																<td>
																	<label id="label">Start Date</label>
																</td>
																<td>:&nbsp;{counterCampID.startDate}</td>
															</tr>

															<tr>
																<td>
																	{" "}
																	<label id="label">Total Leads</label>
																</td>
																<td>:&nbsp;{counterCampID.leadAllocation}</td>
															</tr>

															<tr>
																<td>
																	<label id="label">Campaign CPL</label>
																</td>
																<td>
																	:&nbsp;
																	{counterCampID.CPL ? counterCampID.CPL : "0"}
																	&nbsp;
																	{counterCampID.currency}
																</td>
															</tr>
														</table>
													</div>
													<div class="col-sm-4">
														<table>
															<tr>
																<td>
																	{" "}
																	<label id="label">Campaign Type</label>
																</td>
																<td>:&nbsp;{counterCampID.ABM}</td>
															</tr>
															<tr>
																<td>
																	<label id="label">End Date</label>
																</td>
																<td>:&nbsp;{counterCampID.endDate}</td>
															</tr>

															<tr>
																<td>
																	{" "}
																	<label id="label">Allocated Leads</label>
																</td>
																<td>
																	:&nbsp;
																	{counterCampID.allocatedLead
																		? counterCampID.allocatedLead
																		: "0"}
																</td>
															</tr>
															{user.role === "AC" ? (
																<tr>
																	<td>
																		<label id="label">Total Budget</label>
																	</td>
																	<td>
																		:&nbsp;{counterCampID.budget}&nbsp;
																		{counterCampID.currency}
																	</td>
																</tr>
															) : (
																""
															)}
														</table>
													</div>
													<div class="col-sm-4">
														<table>
															<tr>
																<td>
																	{" "}
																	<label id="label"></label>
																</td>
																<td></td>
															</tr>
															<tr>
																<td>
																	<label id="label">
																		First Lead Delivery Date
																	</label>
																</td>
																<td>
																	:&nbsp;{counterCampID.firstLeadDeliveryDate}
																</td>
															</tr>

															<tr>
																<td>
																	{" "}
																	<label id="label">Pending Leads</label>
																</td>
																<td>
																	:&nbsp;
																	{counterCampID.allocatedLead
																		? counterCampID.leadAllocation -
																				counterCampID.allocatedLead <
																		  0
																			? 0
																			: counterCampID.leadAllocation -
																			  counterCampID.allocatedLead
																		: counterCampID.leadAllocation}
																</td>
															</tr>
															{user.role === "AC" ? (
																<tr>
																	<td>
																		<label id="label">Remaining Budget</label>
																	</td>
																	<td>
																	{/* kiran-4745-removing console warning-changed == to === */}
																		:&nbsp;
																		{counterCampID.remainingBudget
																			? counterCampID.remainingBudget
																			: counterCampID.allocatedLead ===
																			  counterCampID.leadAllocation
																			? "0"
																			: counterCampID.budget}
																		&nbsp;{counterCampID.currency}
																	</td>
																</tr>
															) : (
																""
															)}
														</table>
													</div>
												</div>
											</div>
										))}

										{this.state.counterCampID.map((counterCampID) => (
											<input
												type="hidden"
												id="totalLead"
												name="totalLead"
												value={counterCampID.leadAllocation}
											/>
										))}
									</div>
									<br />
									{/* <div align="center">
                                {this.state.campaignDetailID.map(
                                  campaignDetailID => (
                                 
                                    <label id="label" style={{ fontSize: "15px" }}>
Rejected Leads:&nbsp; <span style={{ color: 'Orange', fontWeight: 'bold' }}>  
{campaignDetailID.rejectedLead}</span>

</label>

                                  ))}</div>   */}
									{/* //shivani-task 3237- commented this extra code , as its showing both the counts counter and reject on single popUp.  */}
									<div class="col-md-12 col-lg-12">
										{this.state.region ? (
											<span style={{ fontWeight: "bold" }}>
												Targeted Country is GDPR Compliance.Do you want to
												allocate GDPR Compliance Publisher ?&nbsp;
												<button
													type="submit"
													onClick={this.isCounterGDPRPUB}
													id={tempCampID}
													value="Yes"
													class="btn btn-info btn-sm">
													Yes
												</button>
												&nbsp;
												<button
													type="submit"
													onClick={this.isCounterGDPRPUB}
													id={tempCampID}
													value="No"
													class="btn btn-danger btn-xs">
													No
												</button>
											</span>
										) : (
											""
										)}
									</div>
									{/* kiran-4745-removing console warning-changed == to === */}

									{this.state.btnStatus === "Yes" ? (
										<span>
											<br />
											<br />
											<br />
											{/* {this.state.btnStatus} */}

											<div class="row">
												<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
													{/* <div class="container-fluid"> */}
													{/* <div align="center">
                                {this.state.campaignDetailID.map(
                                  campaignDetailID => (
                                    <label id="label" style={{ fontSize: "15px" }}>Rejected Leads :&nbsp; <span style={{ color: 'Red', fontWeight: 'bold' }}>  {campaignDetailID.rejectedLead}</span></label>
                                  ))}</div>*/}

													<form
														method="POST"
														name="Publisher"
														onSubmit={this.submitCounterGDPRPublisher}>
														<div class="table-responsive-lg">
															<table class=" table table-striped">
																<thead>
																	<tr class="info">
																		<th class="table-header">Publisher Name</th>
																		<th class="table-header">Rating</th>
																		<th class="table-header">GDPR</th>

																		<th class="table-header">Start Date</th>
																		<th class="table-header">End Date</th>
																		<th class="table-header">
																			{" "}
																			First Lead Delivery Date
																		</th>
																		<th class="table-header">Allocated Lead</th>
																		<th class="table-header">Allocation</th>
																		{user.role === "AC" ? (
																			<th
																				class="table-header"
																				style={{ width: "10%" }}>
																				CPL
																			</th>
																		) : (
																			""
																		)}
																	</tr>
																</thead>
																<tbody>
																	{/*
																	 * @author Narendra Phadke
																	 * @param  Description handle the multiple allocate campaign
																	 * @return Description return successfully allocated message
																	 */}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalPending"
																				name="totalPending"
																				value={
																					campaignDetailID.allocatedLead
																						? campaignDetailID.leadAllocation -
																						  campaignDetailID.allocatedLead
																						: "0"
																				}
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="campID"
																				name="campID"
																				value={campaignDetailID.campID}
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalLead"
																				name="totalLead"
																				value={campaignDetailID.leadAllocation}
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalAllocate"
																				name="totalAllocate"
																				value={campaignDetailID.allocatedLead}
																			/>
																		)
																	)}

																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="leadAllocation"
																				name="leadAllocation"
																				value={counterCampID.leadAllocation}
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalBudget"
																				name="totalBudget"
																				value={campaignDetailID.budget}
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalRemainingBudget"
																				name="totalRemainingBudget"
																				value={campaignDetailID.remainingBudget}
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalendDate"
																				name="totalendDate"
																				value={campaignDetailID.endDate}
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalstartDate"
																				name="otalstartDate"
																				value={campaignDetailID.startDate}
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalfirstLeadDeliveryDate"
																				name="totalfirstLeadDeliveryDate"
																				value={
																					campaignDetailID.firstLeadDeliveryDate
																				}
																			/>
																		)
																	)}

																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalAllocate"
																				name="totalAllocate"
																				value={counterCampID.allocatedLead}
																			/>
																		)
																	)}

																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalBudget"
																				name="totalBudget"
																				value={counterCampID.budget}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalRemainingBudget"
																				name="totalRemainingBudget"
																				value={counterCampID.remainingBudget}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalendDate"
																				name="totalendDate"
																				value={counterCampID.endDate}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalfirstLeadDeliveryDate"
																				name="totalfirstLeadDeliveryDate"
																				value={
																					counterCampID.firstLeadDeliveryDate
																				}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalstartDate"
																				name="otalstartDate"
																				value={counterCampID.startDate}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="counterLead"
																				name="counterLead"
																				value={counterCampID.counterLead}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="clientCampID"
																				name="clientCampID"
																				value={counterCampID.clientCampID}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalPending"
																				name="totalPending"
																				value={
																					counterCampID.allocatedLead
																						? counterCampID.leadAllocation -
																						  counterCampID.allocatedLead
																						: "0"
																				}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="RejectedLead"
																				name="RejectedLead"
																				value={counterCampID.counterLead}
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="RejectedLead"
																				name="RejectedLead"
																				value={campaignDetailID.rejectedLead}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="campID"
																				name="campID"
																				value={counterCampID.campID}
																			/>
																		)
																	)}
																	{this.state.mergedGdprPublisher.map(
																		(gdprPublisher, i) => {
																			//  {this.state.newDynamicArray.map((p,i) =>{
																			return (
																				<tr>
																					{/* {alert("Inside gdpr publisher22"+JSON.stringify(gdprPublisher))} */}
																					<td style={{ textAlign: "center" }}>
																						{/* <img src={gdprPublisher.pID + ".png"} style={{width:'100%'}}></img> */}
																						<label
																							id="label"
																							style={{
																								align: "center",
																								marginLeft: "-8px",
																							}}>
																							{gdprPublisher.publisherName}
																						</label>{" "}
																					</td>

																					<td>
																						<label id="label">
																							{gdprPublisher.rating}
																						</label>
																					</td>
																					<td style={{ textAlign: "center" }}>
																						<label
																							id="label"
																							style={{ align: "center" }}>
																							{gdprPublisher.gdprCompliance}
																						</label>{" "}
																					</td>

																					<td>
																						<input
																							type="date"
																							id="startDate"
																							name="startDate"
																							value={this.state.startDate}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.startDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>
																					<td>
																						<input
																							type="date"
																							id="endDate"
																							name="endDate"
																							value={this.state.endDate}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.endDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>
																					<td>
																						<input
																							type="date"
																							id="firstLeadDeliveryDate"
																							name="firstLeadDeliveryDate"
																							value={
																								this.state.firstLeadDeliveryDate
																							}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.firstLeadDeliveryDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>

																					<td>
																						{gdprPublisher.pubAllocatedLead ==
																						null
																							? 0
																							: gdprPublisher.pubAllocatedLead}
																					</td>

																					<td>
																						<input
																							type="textbox"
																							class="form-control"
																							id="allocatedLead"
																							name="allocatedLead"
																							value={this.state.allocatedLead}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{
																								width: "100%",
																								height: "100%",
																							}}
																						/>
																					</td>
																					{/* <td > <input  type="textbox" class="form-control" id="cpl" name="cpl" value={this.state.cpl} onChange={this.handleChangeGDPRPublisher.bind(this, i)}
                                style={{width: "100%", height: "100%"}}/></td>  */}

																					<td>
																						{gdprPublisher.status ===
																						"Accept" ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								disabled
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : gdprPublisher.status ===
																						  "Assign" ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : gdprPublisher.status == null ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={
																									gdprPublisher.campCPL
																								}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						)}
																					</td>
																				</tr>
																			);
																		}
																	)}
																</tbody>
															</table>
														</div>

														<div class="modal-footer">
															<button
																type="submit"
																className={this.state.inputClass}
																class="btn add-button"
																data-toggle="modal"
																data-target="#myModal">
																Allocate
															</button>
															{/* <button
                                        type="button" 
                                class="btn btn-default" 
                                //data-toggle="modal" 
                                data-target="#myModal"
                                        onClick={this.handleRefreshPage}
                                      >
                                        Close
                                      </button> */}
														</div>
													</form>

													{/* </div> */}
												</div>

												{/**End of col */}
											</div>
										</span>
									) : (
										""
									)}
									{/*
									 * @author Supriya Gore
									 * @param  Description handle the multiple allocate campaign
									 * @return Description return successfully allocated message
									 */}
									{this.state.btnStatus === "No" ? (
										<span>
											<br />
											<br />
											<br />

											<div class="row">
												<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
													{/* <div class="container-fluid"> */}
													{}
													<span
														class="d-flex justify-content-center"
														style={{ fontWeight: "bold", color: "red" }}>
														Below List is for Non GDPR Compliance Publisher
													</span>
													<form
														method="POST"
														name="Publisher"
														onSubmit={this.submitCounterGDPRPublisher}>
														<div class="table-responsive-lg">
															<table class=" table table-striped">
																<thead>
																	<tr class="info">
																		<th class="table-header">Publisher Name</th>
																		<th class="table-header">Rating</th>
																		<th class="table-header">GDPR</th>

																		<th class="table-header">Start Date</th>
																		<th class="table-header">End Date</th>
																		<th class="table-header">
																			First Lead Delivery Date
																		</th>
																		<th class="table-header">Allocated Lead</th>
																		<th class="table-header">Allocation</th>
																		{user.role === "AC" ? (
																			<th
																				class="table-header"
																				style={{ width: "10%" }}>
																				CPL
																			</th>
																		) : (
																			""
																		)}
																	</tr>
																</thead>
																<tbody>
																	{/*
																	 * @author Narendra Phadke
																	 * @param  Description handle the multiple allocate campaign
																	 * @return Description return successfully allocated message
																	 */}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalPending"
																				name="totalPending"
																				value={
																					campaignDetailID.allocatedLead
																						? campaignDetailID.leadAllocation -
																						  campaignDetailID.allocatedLead
																						: "0"
																				}
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="campID"
																				name="campID"
																				value={campaignDetailID.campID}
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalLead"
																				name="totalLead"
																				value={campaignDetailID.leadAllocation}
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalAllocate"
																				name="totalAllocate"
																				value={campaignDetailID.allocatedLead}
																			/>
																		)
																	)}

																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="leadAllocation"
																				name="leadAllocation"
																				value={counterCampID.leadAllocation}
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalBudget"
																				name="totalBudget"
																				value={campaignDetailID.budget}
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalRemainingBudget"
																				name="totalRemainingBudget"
																				value={campaignDetailID.remainingBudget}
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalendDate"
																				name="totalendDate"
																				value={campaignDetailID.endDate}
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalstartDate"
																				name="otalstartDate"
																				//value={this.state.startDate}
																				value={campaignDetailID.startDate}
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalfirstLeadDeliveryDate"
																				name="totalfirstLeadDeliveryDate"
																				value={
																					campaignDetailID.firstLeadDeliveryDate
																				}
																			/>
																		)
																	)}

																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalAllocate"
																				name="totalAllocate"
																				value={counterCampID.allocatedLead}
																			/>
																		)
																	)}

																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalBudget"
																				name="totalBudget"
																				value={counterCampID.budget}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalRemainingBudget"
																				name="totalRemainingBudget"
																				value={counterCampID.remainingBudget}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalendDate"
																				name="totalendDate"
																				value={counterCampID.endDate}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalfirstLeadDeliveryDate"
																				name="totalfirstLeadDeliveryDate"
																				value={
																					counterCampID.firstLeadDeliveryDate
																				}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalstartDate"
																				name="otalstartDate"
																				value={counterCampID.startDate}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="counterLead"
																				name="counterLead"
																				value={counterCampID.counterLead}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="clientCampID"
																				name="clientCampID"
																				value={counterCampID.clientCampID}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="totalPending"
																				name="totalPending"
																				value={
																					counterCampID.allocatedLead
																						? counterCampID.leadAllocation -
																						  counterCampID.allocatedLead
																						: "0"
																				}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="RejectedLead"
																				name="RejectedLead"
																				value={counterCampID.counterLead}
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="RejectedLead"
																				name="RejectedLead"
																				value={campaignDetailID.rejectedLead}
																			/>
																		)
																	)}
																	{this.state.counterCampID.map(
																		(counterCampID) => (
																			<input
																				type="hidden"
																				id="campID"
																				name="campID"
																				value={counterCampID.campID}
																			/>
																		)
																	)}
																	{this.state.mergedGdprPublisher.map(
																		(gdprPublisher, i) => {
																			//  {this.state.newDynamicArray.map((p,i) =>{
																			//alert("mergedGdprPublisher=="+JSON.stringify(gdprPublisher))
																			return (
																				<tr>
																					<td style={{ textAlign: "center" }}>
																						{/* <img src={gdprPublisher.pID + ".png"} style={{width:'100%'}}></img> */}
																						<label
																							id="label"
																							style={{
																								align: "center",
																								marginLeft: "-8px",
																							}}>
																							{gdprPublisher.publisherName}
																						</label>{" "}
																					</td>
																					<td>
																						{/* {alert("Inside gdpr publisher111"+JSON.stringify(gdprPublisher))} */}

																						<label id="label">
																							{gdprPublisher.rating}
																						</label>
																					</td>
																					<td style={{ textAlign: "center" }}>
																						<label
																							id="label"
																							style={{ align: "center" }}>
																							{gdprPublisher.gdprCompliance}
																						</label>{" "}
																					</td>

																					<td>
																						<input
																							type="date"
																							id="startDate"
																							name="startDate"
																							value={this.state.startDate}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.startDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>
																					<td>
																						<input
																							type="date"
																							id="endDate"
																							name="endDate"
																							value={this.state.endDate}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.endDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>
																					<td>
																						<input
																							type="date"
																							id="firstLeadDeliveryDate"
																							name="firstLeadDeliveryDate"
																							value={
																								this.state.firstLeadDeliveryDate
																							}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.firstLeadDeliveryDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>

																					<td>
																						{gdprPublisher.pubAllocatedLead ==
																						null
																							? 0
																							: gdprPublisher.pubAllocatedLead}
																					</td>

																					<td>
																						<input
																							type="textbox"
																							class="form-control"
																							id="allocatedLead"
																							name="allocatedLead"
																							value={this.state.allocatedLead}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{
																								width: "100%",
																								height: "100%",
																							}}
																						/>
																					</td>
																					{/* <td > <input  type="textbox" class="form-control" id="cpl" name="cpl" value={this.state.cpl} onChange={this.handleChangeGDPRPublisher.bind(this, i)}
                                style={{width: "100%", height: "100%"}}/></td>  */}
								{/* kiran-4745-removing console warning-changed == to === */}

																					<td>
																						{gdprPublisher.status ===
																						"Accept" ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								disabled
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : gdprPublisher.status ===
																						  "Assign" ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : gdprPublisher.status == null ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={
																									gdprPublisher.campCPL
																								}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						)}
																					</td>
																				</tr>
																			);
																		}
																	)}
																</tbody>
															</table>
														</div>

														<div class="modal-footer">
															<button
																type="submit"
																className={this.state.inputClass}
																class="btn add-button"
																data-toggle="modal"
																data-target="#myModal"
																style={{
																	backgroundColor: "#144999",
																	color: "white",
																}}>
																Allocate
															</button>
															{/* <button
                                        type="button" 
                                class="btn btn-default" 
                                //data-toggle="modal" 
                                data-target="#myModal"
                                        onClick={this.handleRefreshPage}
                                      >
                                        Close
                                      </button> */}
														</div>
													</form>

													{/* </div> */}
												</div>

												{/**End of col */}
											</div>
										</span>
									) : (
										""
									)}

									{this.state.gdprStatus === "false" ? (
										<div class="row">
											<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
												<div align="center">
													{this.state.counterCampID.map((counterCampID) => (
														<label id="label" style={{ fontSize: "15px" }}>
															Counter Leads :&nbsp;{" "}
															<span
																style={{ color: "Orange", fontWeight: "bold" }}>
																{" "}
																{counterCampID.counterLead}
															</span>
														</label>
													))}
												</div>
												<form
													method="POST"
													name="Publisher"
													onSubmit={this.submitCounterPublisher}>
													<div class="table-responsive-lg">
														<table class=" table table-striped">
															<thead>
																<tr class="info">
																	<th class="table-header">Publisher Name</th>
																	<th class="table-header">Rating</th>
																	<th class="table-header">GDPR</th>
																	<th class="table-header">Start Date</th>
																	<th class="table-header">End Date</th>
																	<th class="table-header">
																		First Lead Delivery Date
																	</th>
																	<th class="table-header">Allocated Lead</th>
																	<th class="table-header">Allocation</th>
																	{user.role === "AC" ? (
																		<th
																			class="table-header"
																			style={{ width: "22%" }}>
																			CPL
																		</th>
																	) : (
																		""
																	)}
																</tr>
															</thead>
															<tbody>
																{/*
																 * @author Narendra Phadke
																 * @param  Description handle the multiple allocate campaign
																 * @return Description return successfully allocated message
																 */}
																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="totalPending"
																			name="totalPending"
																			value={
																				counterCampID.allocatedLead
																					? counterCampID.leadAllocation -
																					  counterCampID.allocatedLead
																					: "0"
																			}
																		/>
																	)
																)}

																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="campID"
																			name="campID"
																			value={counterCampID.campID}
																		/>
																	)
																)}

																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="totalLead"
																			name="totalLead"
																			value={counterCampID.leadAllocation}
																		/>
																	)
																)}
																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="totalAllocate"
																			name="totalAllocate"
																			value={counterCampID.allocatedLead}
																		/>
																	)
																)}
																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="leadAllocation"
																			name="leadAllocation"
																			value={counterCampID.leadAllocation}
																			class="form-control"
																		/>
																	)
																)}
																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="totalBudget"
																			name="totalBudget"
																			value={counterCampID.budget}
																		/>
																	)
																)}
																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="totalRemainingBudget"
																			name="totalRemainingBudget"
																			value={counterCampID.remainingBudget}
																		/>
																	)
																)}
																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="totalendDate"
																			name="totalendDate"
																			value={counterCampID.endDate}
																		/>
																	)
																)}
																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="totalfirstLeadDeliveryDate"
																			name="totalfirstLeadDeliveryDate"
																			value={
																				counterCampID.firstLeadDeliveryDate
																			}
																		/>
																	)
																)}
																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="totalstartDate"
																			name="otalstartDate"
																			value={counterCampID.startDate}
																		/>
																	)
																)}
																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="counterLead"
																			name="counterLead"
																			value={counterCampID.counterLead}
																		/>
																	)
																)}
																{this.state.counterCampID.map(
																	(counterCampID) => (
																		<input
																			type="hidden"
																			id="clientCampID"
																			name="clientCampID"
																			value={counterCampID.clientCampID}
																		/>
																	)
																)}
																{this.state.mergedShowPubInCounterAllocate.map(
																	(showPubInCounterAllocate, i) => (
																		<tr>
																			<td style={{ textAlign: "center" }}>
																				{/* <img src={showPubInCounterAllocate.pID + ".png"} style={{width:'100%'}}></img> */}
																				<label
																					id="label"
																					style={{ marginLeft: "-8px" }}>
																					{
																						showPubInCounterAllocate.publisherName
																					}
																				</label>
																			</td>

																			<td>
																				{" "}
																				<input
																					type="hidden"
																					id="rejectedpID"
																					name="rejectedpID"
																					value={
																						showPubInCounterAllocate.rejectedpID
																					}
																				/>
																				<label id="label">
																					{" "}
																					{showPubInCounterAllocate.rating}{" "}
																				</label>
																			</td>

																			<td style={{ textAlign: "center" }}>
																				<label
																					id="label"
																					style={{ align: "center" }}>
																					{
																						showPubInCounterAllocate.gdprCompliance
																					}
																				</label>{" "}
																			</td>

																			<td>
																				<input
																					type="date"
																					id="startDate"
																					name="startDate"
																					value={this.state.startDate}
																					onChange={this.handleChangeDynamicCounterPublisher.bind(
																						this,
																						i
																					)}
																					style={{ width: "87%" }}
																					defaultValue={
																						showPubInCounterAllocate.startDate
																					}
																					class="form-control"
																				/>
																			</td>

																			<td>
																				<input
																					type="date"
																					id="endDate"
																					name="endDate"
																					value={this.state.endDate}
																					onChange={this.handleChangeDynamicCounterPublisher.bind(
																						this,
																						i
																					)}
																					style={{ width: "87%" }}
																					defaultValue={
																						showPubInCounterAllocate.endDate
																					}
																					class="form-control"
																				/>
																			</td>

																			<td>
																				<input
																					type="date"
																					id="firstLeadDeliveryDate"
																					name="firstLeadDeliveryDate"
																					value={
																						this.state.firstLeadDeliveryDate
																					}
																					onChange={this.handleChangeDynamicCounterPublisher.bind(
																						this,
																						i
																					)}
																					style={{ width: "87%" }}
																					defaultValue={
																						showPubInCounterAllocate.firstLeadDeliveryDate
																					}
																					class="form-control"
																				/>
																			</td>

																			<td>
																				{showPubInCounterAllocate.pubAllocatedLead ==
																				null
																					? 0
																					: showPubInCounterAllocate.pubAllocatedLead}
																			</td>

																			<td>
																				<input
																					type="textbox"
																					class="form-control"
																					id="allocatedLead"
																					name="allocatedLead"
																					value={this.state.allocatedLead}
																					onChange={this.handleChangeDynamicCounterPublisher.bind(
																						this,
																						i
																					)}
																					style={{
																						width: "100%",
																						height: "100%",
																					}}
																				/>
																			</td>
																			{/* <td >
                    <input
                       type="textbox"
                       class="form-control"
                       id="cpl"
                       name="cpl"
                       value={this.state.cpl}
                       onChange={this.handleChangeDynamicCounterPublisher.bind(this, i)}
                       style={{width: "110%", height: "100%"}}
                     />
              </td>  */}
			  {/* kiran-4745-removing console warning-changed == to === */}

																			<td>
																				{showPubInCounterAllocate.status ===
																				"Accept" ? (
																					<input
																						type="textbox"
																						class="form-control"
																						id="cpl"
																						name="cpl"
																						defaultValue={
																							showPubInCounterAllocate.cpl
																						}
																						disabled
																						onChange={this.handleChangeDynamicCounterPublisher.bind(
																							this,
																							i
																						)}
																						style={{
																							width: "100%",
																							height: "100%",
																						}}
																					/>
																				) : showPubInCounterAllocate.status ===
																				  "Assign" ? (
																					<input
																						type="textbox"
																						class="form-control"
																						id="cpl"
																						name="cpl"
																						defaultValue={
																							showPubInCounterAllocate.cpl
																						}
																						onChange={this.handleChangeDynamicCounterPublisher.bind(
																							this,
																							i
																						)}
																						style={{
																							width: "100%",
																							height: "100%",
																						}}
																					/>
																				) : showPubInCounterAllocate.status ==
																				  null ? (
																					<input
																						type="textbox"
																						class="form-control"
																						id="cpl"
																						name="cpl"
																						defaultValue={
																							showPubInCounterAllocate.cpl
																						}
																						onChange={this.handleChangeDynamicCounterPublisher.bind(
																							this,
																							i
																						)}
																						style={{
																							width: "100%",
																							height: "100%",
																						}}
																					/>
																				) : (
																					<input
																						type="textbox"
																						class="form-control"
																						id="cpl"
																						name="cpl"
																						defaultValue={
																							showPubInCounterAllocate.campCPL
																						}
																						onChange={this.handleChangeDynamicCounterPublisher.bind(
																							this,
																							i
																						)}
																						style={{
																							width: "100%",
																							height: "100%",
																						}}
																					/>
																				)}
																			</td>
																		</tr>
																	)
																)}
															</tbody>
														</table>
													</div>
													<div class="modal-footer">
														<button
															type="submit"
															className={this.state.inputClass}
															class="btn add-button"
															data-toggle="modal"
															data-target="#myModal"
															style={{
																backgroundColor: "#144999",
																color: "white",
															}}>
															Allocate
														</button>
														{/* <button
                                        type="button" 
                               class="btn btn-default" 
                               //data-toggle="modal" 
                               data-target="#myModal"
                                        onClick={this.handleRefreshPage}
                                      >
                                        Close
                                      </button> */}
													</div>
												</form>
											</div>

											{/**End of col */}
										</div>
									) : (
										""
									)}
									{/**End of 2nd row */}
								</div>
								{/**End of container */}
							</div>
							{/* <!-- End Modal body--> */}

							{/* <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div><!-- Footer End--> */}
						</div>{" "}
						{/* <!--modal content--> */}
					</div>{" "}
					{/* <!-- modal dialog--> */}
				</div>{" "}
				{/* <!-- modal fade--> */}
				{/* End OF Counter Campaign Allocate */}
				{/* Accept Model */}
				<div
					class="modal fade"
					id="acceptCounter"
					role="dialog"
					style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
					{" "}
					{/* //shivani-task 3237-made background blur */}
					<div class="modal-dialog">
						{/*Model Content*/}
						<div class="modal-content" style={{ marginTop: "160px" }}>
							{/* shivani-task 3237-modal shifted down by applying margin */}
							<form
								method="POST"
								name="acceptCounter"
								// onSubmit={this.submitCounterProposal}
								onSubmit={this.acceptCounterCampaign}>
								<div
									class="modal-header custom-class"
									style={{ fontSize: "17px", fontWeight: "700" }}>
									<h4
										class="modal-title"
										style={{
											color: "white",
											paddingRight: "300px",
											paddingTop: "7px",
										}}>
										Accept Campaign
									</h4>
									<button
										type="button"
										class="cancel-button"
										style={{ fontSize: 20 }}
										data-dismiss="modal">
										&times;
									</button>
									{/* shivani-task 3237-changed the size of cross button */}
								</div>

								{/* message */}
								{/* <div className=""  style={{display:this.state.alertDisplay}}>
            <div class="container-fluid">
                 <div class="card card-signin my-1">
                          <div class="card-body" >
                              <div class="row">
                                <div class="col-lg-12" style={{display:this.state.alertDisplay}}>
                                  <div style={{fontSize:'14px',color:'#008000',fontWeight:'600',paddingLeft:'30px'}}>
                                  {this.state.newsuccess}</div> 
                                  
                                </div>
                              </div>
                          </div>
                        </div>
                        </div>
                        </div> */}
								<div class="modal-body">
									<label id="label" style={{ fontWeight: "700" }}>
										Are you sure you want to accept below Campaign with listed
										CPL?
									</label>
									{this.state.newCampaignDetailID.map((newCampaignDetailID) => (
										<p>
											<label id="label">Campaign ID :</label>
											&nbsp;
											<label style={{ fontWeight: "400" }}>
												{this.state.parentCampID}&nbsp;
											</label>
											<br />
											{/* <label id="label">Allocation ID:</label>
                                      &nbsp;
                                      <label id="label">
                                        {this.state.reallocationID}
                                      </label>
                                      <input
                                        type="hidden"
                                        id="allocationID"
                                        value={newCampaignDetailID.allocationID}
                                        onChange={this.handleChange}
                                        name="allocationID"
                                      /> */}
											<label id="label">
												Old CPL
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
											</label>
											&nbsp;
											<label style={{ fontWeight: "400" }}>
												{newCampaignDetailID.CPL} &nbsp;
												{newCampaignDetailID.currency}
											</label>
											<br />
											<label id="label">Counter CPL &nbsp;:</label>&nbsp;
											<label style={{ fontWeight: "400" }}>
												{newCampaignDetailID.counterCPL} &nbsp;
												{newCampaignDetailID.currency}
											</label>
										</p>
									))}
								</div>
								<div class="modal-footer">
									{/* <button
                                        type="button" 
                                        class="btn btn-default"
                                         onClick={this.handleRefreshPage}
                                      >
                                  Close
                                </button> */}
									<button
										type="submit"
										class="btn add-button "
										style={{ backgroundColor: "#144999", color: "white" }}>
										Accept
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				{/*End of  Accept Model */}
				{/* Reject Model */}
				<div
					class="modal fade"
					id="rejectCounter"
					role="dialog"
					style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
					{" "}
					{/* //shivani-task 3237-made background blur */}
					<div class="modal-dialog">
						{/*Model Content*/}
						<div class="modal-content" style={{ marginTop: "160px" }}>
							{/* shivani-task 3237-modal shifted down by applying margin */}
							<form
								method="POST"
								name="rejectPub"
								onSubmit={this.rejectCounterCampaign}>
								<div class="modal-header custom-class ">
									<h4
										class="modal-title"
										style={{
											color: "white",
											paddingRight: "300px",
											paddingTop: "7px",
										}}>
										Reject Campaign
									</h4>
									{/* <div class="text-right"> */}
									<button
										type="button"
										class="cancel-button"
										style={{ fontSize: 20 }}
										data-dismiss="modal"
										// name="cancelReason"
										// value={this.state.rejectionReason}
										onClick={this.cancelButton}>
										&times;
									</button>
									{/* shivani-task 3237-changed the size of cross button */}
									{/* </div> */}
								</div>
								{/* <div className="" style={{ display: this.state.alertDisplay }}>
                              <div class="container-fluid">
                                <div class="card card-signin my-1">
                                  <div class="card-body" >
                                    <div class="row">
                                      <div class="col-lg-12" style={{display:this.state.alertDisplay}}>
                                      <div style={{ fontSize: '14px', color: '#008000', fontWeight: '600', paddingLeft: '30px' }}>
                                        {this.state.newsuccess}</div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
								<div class="modal-body">
									<label id="label" style={{ fontWeight: "700" }}>
										Are you sure you want to reject below Campaign with listed
										CPL?
									</label>
									{this.state.newCampaignDetailID.map((newCampaignDetailID) => (
										<p>
											<label id="label">Campaign ID :</label>
											&nbsp;{this.state.parentCampID}&nbsp;
											<br />
											{/* <label id="label">Allocation ID:</label>
                                  &nbsp;{this.state.reallocationID}
                                <br /> */}
											<label id="label">
												{" "}
												Old CPL
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
											</label>
											&nbsp;{newCampaignDetailID.CPL}&nbsp;
											{newCampaignDetailID.currency}
											<br />
											<label id="label"> Counter CPL &nbsp;:</label>
											&nbsp;{newCampaignDetailID.counterCPL}&nbsp;
											{newCampaignDetailID.currency}
											<br />
											<label id="label">Rejection Reason :</label>
											<textarea
												id="rejectionReason"
												name="rejectionReason"
												value={this.state.rejectionReason}
												style={{ resize: "none" }}
												onChange={this.handleChange}
												// onChange={this.handleChange.bind(this,i)}
												class="form-control"
											/>
											<input
												type="hidden"
												id="allocatedLead"
												name="allocatedLead"
												value={newCampaignDetailID.allocatedLead}
											/>
										</p>
									))}
								</div>
								<div class="modal-footer">
									{/* <button
                                type="button"
                                class="btn btn-default"
                                data-dismiss="modal"
                                onClick={this.handleRefreshPage}
                              >
                                Close
                              </button> */}
									<button
										type="submit"
										class="btn add-button"
										style={{ backgroundColor: "#144999", color: "white" }}>
										Reject
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				{/*End of  Reject Model */}
				{/*Start of rejected Campaign Allocate Modal */}
				{/* <!-- Modal --> */}
				<div
					class="modal fade"
					id="campAllocation"
					role="dialog"
					style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
					{" "}
					{/* //shivani-task 3237-made background blur */}
					<div class="modal-dialog modal-xl">
						{/* <!-- Modal content--> */}
						<div class="modal-content" style={{ marginTop: "143px" }}>
							{/* shivani-task 3237-modal shifted down by applying margin */}
							<div class="modal-header custom-class">
								{" "}
								{/* Rejected Allocation */}
								{/* <label id="label" style={{fontSize:"larger",color:'white'}}>Campaign Allocation123 </label> */}
								{this.state.campaignDetailID.map((campaignDetailID) => (
									<div
										class="col-sm-11 text-left"
										style={{ fontSize: "17px", paddingTop: "7px" }}>
										Campaign Allocation:&nbsp;{this.state.parentCampID}&nbsp;
										-&nbsp; {campaignDetailID.campaignName}
									</div>
								))}
								<button
									type="button"
									class="cancel-button"
									style={{ fontSize: 22, outline: "none", marginLeft: "35px" }}
									onClick={this.handleRefreshPage}
									data-dismiss="modal">
									&times;
								</button>
								{/* shivani-task 3237-changed the size of cross button */}
							</div>
							{/* message */}
							<div className="" style={{ display: this.state.alertDisplay }}>
								<div class="container-fluid">
									{/* {/* <div class="card card-signin my-1"> */}
									<div class="card-body">
										<div class="row">
											<div
												class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
												style={{ border: this.state.alertBorder }}>
												<div class="errorMessage">
													{this.state.errors.endDate
														? this.state.errors.endDate
														: ""}
													{this.state.errors.allocatedLead
														? this.state.errors.allocatedLead
														: ""}
													{this.state.errors.reject
														? this.state.errors.reject
														: ""}
													{this.state.puberror.one
														? this.state.puberror.one
														: ""}
													{this.state.errors.lead ? this.state.errors.lead : ""}
													{this.state.allocerrors.allocate
														? this.state.allocerrors.allocate
														: ""}
													{this.state.reerrors.remaining
														? this.state.reerrors.remaining
														: ""}
													{this.state.dateerrors.date
														? this.state.dateerrors.date
														: ""}

													{this.state.errors.cpl ? this.state.errors.cpl : ""}
													{this.state.errors.cpl1 ? this.state.errors.cpl1 : ""}
													{this.state.errors.cpl2 ? this.state.errors.cpl2 : ""}
													{this.state.errors.cpl3 ? this.state.errors.cpl3 : ""}
													{/* {this.state.errors.reject2}  */}
												</div>
												<div class="successMessage">
													{this.state.newsuccess}{" "}
												</div>
											</div>
										</div>
									</div>
									{/* </div> */}
								</div>
							</div>
							{/*end of  message */}

							{/* <!-- Modal body--> */}
							<div class="modal-body">
								<div class="container-fluid">
									<div style={{ border: "1px solid #cdcdcd" }}>
										{this.state.campaignDetailID.map((campaignDetailID) => (
											<div class="container-fluid">
												<div class="row">
													<tr>
														<td
															style={{
																verticalAlign: "top",
																textAlign: "left",
															}}>
															{" "}
															<label id="label" style={{ width: "94px" }}>
																&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Campaign
																&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Region
															</label>
														</td>
														<td>:&nbsp;{campaignDetailID.region}</td>
													</tr>
												</div>
												<div class="row">
													<div class="col-sm-4">
														<table>
															<tr>
																<td>
																	<label id="label">Start Date</label>
																</td>
																<td>:&nbsp;{campaignDetailID.startDate}</td>
															</tr>

															<tr>
																<td>
																	{" "}
																	<label id="label">Total Leads</label>
																</td>
																<td>
																	:&nbsp;{campaignDetailID.leadAllocation}
																</td>
															</tr>

															<tr>
																<td>
																	<label id="label">Campaign CPL</label>
																</td>
																<td>
																	:&nbsp;
																	{campaignDetailID.CPL
																		? campaignDetailID.CPL
																		: "0"}
																	&nbsp;{campaignDetailID.currency}
																</td>
															</tr>
															<tr>
																<td>
																	{" "}
																	<label id="label">Campaign Type</label>
																</td>
																<td>:&nbsp;{campaignDetailID.ABM}</td>
															</tr>
														</table>
													</div>
													<div class="col-sm-4">
														<table>
															<tr>
																<td>
																	<label id="label">End Date</label>
																</td>
																<td>:&nbsp;{campaignDetailID.endDate}</td>
															</tr>

															<tr>
																<td>
																	{" "}
																	<label id="label">Allocated Leads</label>
																</td>
																<td>
																	:&nbsp;
																	{campaignDetailID.allocatedLead
																		? campaignDetailID.allocatedLead
																		: "0"}
																</td>
															</tr>
															{user.role === "AC" ? (
																<tr>
																	<td>
																		<label id="label">Total Budget</label>
																	</td>
																	<td>
																		:&nbsp;{campaignDetailID.budget}&nbsp;
																		{campaignDetailID.currency}
																	</td>
																</tr>
															) : (
																""
															)}
														</table>
													</div>
													<div class="col-sm-4">
														<table>
															{/* <tr>
                                      <td> <label id="label"></label>
                                      </td>
                        
                                      </tr> */}
															<tr>
																<td>
																	<label id="label">
																		First Lead Delivery Date
																	</label>
																</td>
																<td>
																	:&nbsp;
																	{campaignDetailID.firstLeadDeliveryDate}
																</td>
															</tr>

															<tr>
																<td>
																	{" "}
																	<label id="label">Pending Leads</label>
																</td>
																<td>
																	:&nbsp;
																	{campaignDetailID.allocatedLead
																		? campaignDetailID.leadAllocation -
																				campaignDetailID.allocatedLead <
																		  0
																			? 0
																			: campaignDetailID.leadAllocation -
																			  campaignDetailID.allocatedLead
																		: campaignDetailID.leadAllocation}
																</td>
															</tr>
															{user.role === "AC" ? (
																<tr>
																	<td>
																		<label id="label">Remaining Budget</label>
																	</td>
																	<td>
																		:&nbsp;
																		{campaignDetailID.remainingBudget
																			? campaignDetailID.remainingBudget
																			: campaignDetailID.allocatedLead ===
																			  campaignDetailID.leadAllocation
																			? "0"
																			: campaignDetailID.budget}
																		&nbsp;
																		{campaignDetailID.currency}
																	</td>
																</tr>
															) : (
																""
															)}
														</table>
													</div>
												</div>
											</div>
										))}
									</div>
									<br />

									<div align="center">
										{this.state.campaignDetailID.map((campaignDetailID) => (
											<label id="label" style={{ fontSize: "15px" }}>
												Rejected Leads :&nbsp;{" "}
												<span style={{ color: "Orange", fontWeight: "bold" }}>
													{campaignDetailID.rejectedLead}
												</span>
											</label>
										))}
									</div>
									<div class="col-md-12 col-lg-12">
										{this.state.region ? (
											<span>
												<br />
												<span style={{ fontWeight: "bold" }}>
													Targeted Country is GDPR Compliance.Do you want to
													allocate GDPR Compliance Publisher ?&nbsp;
													<button
														type="submit"
														onClick={this.isGDPRPUB}
														id={tempCampID}
														value="Yes"
														class="btn btn-info btn-sm">
														Yes
													</button>
													&nbsp;
													<button
														type="submit"
														onClick={this.isGDPRPUB}
														id={tempCampID}
														value="No"
														class="btn btn-danger btn-xs">
														No
													</button>
												</span>
											</span>
										) : (
											""
										)}
									</div>

									{this.state.btnStatus === "Yes" ? (
										<span>
											<br />
											<br />
											<br />
											<br />
											<div class="row">
												<div
													class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
													style={{ overflowY: "scroll", height: "165px" }}>
													{/* <div class="container-fluid"> */}
													{/* <div align="center">
                                {this.state.campaignDetailID.map(
                                  campaignDetailID => (
                                    <label id="label" style={{ fontSize: "15px" }}>Rejected Leads :&nbsp; <span style={{ color: 'Red', fontWeight: 'bold' }}>  {campaignDetailID.rejectedLead}</span></label>
                                  ))}</div>*/}

													<form
														method="POST"
														name="Publisher"
														onSubmit={this.submitGDPRPublisher}>
														<div class="table-responsive-lg">
															<table class=" table table-striped">
																<thead>
																	<tr class="info">
																		<th class="table-header">Publisher Name</th>
																		<th class="table-header">Rating</th>
																		<th class="table-header">GDPR</th>

																		<th class="table-header">Start Date</th>
																		<th class="table-header">End Date</th>
																		<th class="table-header">
																			First Lead Delivery Date
																		</th>
																		<th class="table-header">Allocated Lead</th>
																		<th class="table-header">Allocation</th>
																		{user.role === "AC" ? (
																			<th
																				class="table-header"
																				style={{ width: "10%" }}>
																				CPL
																			</th>
																		) : (
																			""
																		)}
																	</tr>
																</thead>
																<tbody>
																	{/*
																	 * @author Narendra Phadke
																	 * @param  Description handle the multiple allocate campaign
																	 * @return Description return successfully allocated message
																	 */}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalPending"
																				name="totalPending"
																				//value={this.state.startDate}
																				value={
																					campaignDetailID.allocatedLead
																						? campaignDetailID.leadAllocation -
																						  campaignDetailID.allocatedLead
																						: "0"
																				}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="campID"
																				name="campID"
																				//value={this.state.startDate}
																				value={campaignDetailID.campID}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalLead"
																				name="totalLead"
																				//value={this.state.startDate}
																				value={campaignDetailID.leadAllocation}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalAllocate"
																				name="totalAllocate"
																				//value={this.state.startDate}
																				value={campaignDetailID.allocatedLead}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="leadAllocation"
																				name="leadAllocation"
																				//value={this.state.startDate}
																				value={campaignDetailID.leadAllocation}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalBudget"
																				name="totalBudget"
																				//value={this.state.startDate}
																				value={campaignDetailID.budget}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalRemainingBudget"
																				name="totalRemainingBudget"
																				//value={this.state.startDate}
																				value={campaignDetailID.remainingBudget}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalendDate"
																				name="totalendDate"
																				//value={this.state.startDate}
																				value={campaignDetailID.endDate}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalstartDate"
																				name="otalstartDate"
																				//value={this.state.startDate}
																				value={campaignDetailID.startDate}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalFirstLeadDeliveryDate"
																				name="totalFirstLeadDeliveryDate"
																				//value={this.state.startDate}
																				value={
																					campaignDetailID.firstLeadDeliveryDate
																				}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="RejectedLead"
																				name="RejectedLead"
																				value={campaignDetailID.rejectedLead}
																			/>
																		)
																	)}

																	{this.state.mergedGdprPublisher.map(
																		(gdprPublisher, i) => {
																			//  {this.state.newDynamicArray.map((p,i) =>{
																			return (
																				<tr>
																					<td style={{ textAlign: "center" }}>
																						{/* <img src={gdprPublisher.pID + ".png"} style={{width:'100%'}}></img> */}
																						<label
																							id="label"
																							style={{
																								align: "center",
																								marginLeft: "-27px",
																							}}>
																							{gdprPublisher.publisherName}
																						</label>{" "}
																					</td>
																					<td>
																						{/* {alert("Inside gdpr publisher"+JSON.stringify(gdprPublisher))} */}
																						<label id="label">
																							{gdprPublisher.rating}
																						</label>
																					</td>
																					<td style={{ textAlign: "center" }}>
																						<label
																							id="label"
																							style={{ align: "center" }}>
																							{gdprPublisher.gdprCompliance}
																						</label>{" "}
																					</td>

																					<td>
																						<input
																							type="date"
																							id="startDate"
																							name="startDate"
																							value={this.state.startDate}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.startDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>
																					<td>
																						<input
																							type="date"
																							id="endDate"
																							name="endDate"
																							value={this.state.endDate}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.endDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>
																					<td>
																						<input
																							type="date"
																							id="firstLeadDeliveryDate"
																							name="firstLeadDeliveryDate"
																							value={
																								this.state.firstLeadDeliveryDate
																							}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.firstLeadDeliveryDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>

																					<td>
																						{gdprPublisher.pubAllocatedLead ==
																						null
																							? 0
																							: gdprPublisher.pubAllocatedLead}
																					</td>

																					<td>
																						<input
																							type="textbox"
																							class="form-control"
																							id="allocatedLead"
																							name="allocatedLead"
																							value={this.state.allocatedLead}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{
																								width: "100%",
																								height: "100%",
																							}}
																						/>
																					</td>
																					{/* <td > <input  type="textbox" class="form-control" id="cpl" name="cpl" value={this.state.cpl} onChange={this.handleChangeGDPRPublisher.bind(this, i)}
                        style={{width: "100%", height: "100%"}}/></td>  */}
                                                                               {/* kiran-4745-removing console warning-changed == to === */}
																					<td>
																						{gdprPublisher.status ===
																						"Accept" ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								disabled
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : gdprPublisher.status ===
																						  "Assign" ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : gdprPublisher.status == null ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={
																									gdprPublisher.campCPL
																								}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						)}
																					</td>
																				</tr>
																			);
																		}
																	)}
																</tbody>
															</table>
														</div>

														<div class="modal-footer">
															<button
																type="submit"
																className={this.state.inputClass}
																class="btn add-button"
																data-toggle="modal"
																data-target="#myModal"
																style={{
																	backgroundColor: "#144999",
																	color: "white",
																}}>
																Allocate
															</button>
															{/* <button
                                        type="button" 
                               class="btn btn-default" 
                               //data-toggle="modal" 
                               data-target="#myModal"
                                        onClick={this.handleRefreshPage}
                                      >
                                        Close
                                      </button> */}
														</div>
													</form>

													{/* </div> */}
												</div>

												{/**End of col */}
											</div>
										</span>
									) : (
										""
									)}
									{/**If Agency Click On No Button*/
									/*
									 * @author Supriya Gore
									 * @param  Description handle the No GDPR Compliance
									 * @return Description return successfully Not GDPR Compliance in rejected status
									 */}
									{this.state.btnStatus === "No" ? (
										<span>
											<br />
											<br />
											<br />
											<br />
											<div class="row">
												<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
													{/* <div class="container-fluid"> */}
													{}
													<span
														class="d-flex justify-content-center"
														style={{ fontWeight: "bold", color: "red" }}>
														Below List is for Non GDPR Compliance Publisher
													</span>
													<form
														method="POST"
														name="Publisher"
														onSubmit={this.submitGDPRPublisher}>
														<div class="table-responsive-lg">
															<table class=" table table-striped">
																<thead>
																	<tr class="info">
																		<th class="table-header">Publisher Name</th>
																		<th class="table-header">Rating</th>
																		<th class="table-header">GDPR</th>

																		<th class="table-header">Start Date</th>
																		<th class="table-header">End Date</th>
																		<th class="table-header">
																			First Lead Delivery Date
																		</th>
																		<th class="table-header">Allocated Lead</th>
																		<th class="table-header">Allocation</th>
																		{user.role === "AC" ? (
																			<th
																				class="table-header"
																				style={{ width: "10%" }}>
																				CPL
																			</th>
																		) : (
																			""
																		)}
																	</tr>
																</thead>
																<tbody>
																	{/*
																	 * @author Narendra Phadke
																	 * @param  Description handle the multiple allocate campaign
																	 * @return Description return successfully allocated message
																	 */}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalPending"
																				name="totalPending"
																				//value={this.state.startDate}
																				value={
																					campaignDetailID.allocatedLead
																						? campaignDetailID.leadAllocation -
																						  campaignDetailID.allocatedLead
																						: "0"
																				}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="campID"
																				name="campID"
																				//value={this.state.startDate}
																				value={campaignDetailID.campID}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalLead"
																				name="totalLead"
																				//value={this.state.startDate}
																				value={campaignDetailID.leadAllocation}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalAllocate"
																				name="totalAllocate"
																				//value={this.state.startDate}
																				value={campaignDetailID.allocatedLead}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="leadAllocation"
																				name="leadAllocation"
																				//value={this.state.startDate}
																				value={campaignDetailID.leadAllocation}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}

																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalBudget"
																				name="totalBudget"
																				//value={this.state.startDate}
																				value={campaignDetailID.budget}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalRemainingBudget"
																				name="totalRemainingBudget"
																				//value={this.state.startDate}
																				value={campaignDetailID.remainingBudget}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalendDate"
																				name="totalendDate"
																				//value={this.state.startDate}
																				value={campaignDetailID.endDate}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalstartDate"
																				name="otalstartDate"
																				//value={this.state.startDate}
																				value={campaignDetailID.startDate}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="totalfirstLeadDeliveryDate"
																				name="totalfirstLeadDeliveryDate"
																				//value={this.state.startDate}
																				value={
																					campaignDetailID.firstLeadDeliveryDate
																				}
																				style={{
																					width: "87%",
																					height: "100%",
																				}}
																				class="form-control"
																			/>
																		)
																	)}
																	{this.state.campaignDetailID.map(
																		(campaignDetailID) => (
																			<input
																				type="hidden"
																				id="RejectedLead"
																				name="RejectedLead"
																				value={campaignDetailID.rejectedLead}
																			/>
																		)
																	)}

																	{this.state.mergedGdprPublisher.map(
																		(gdprPublisher, i) => {
																			//  {this.state.newDynamicArray.map((p,i) =>{
																			return (
																				<tr>
																					{/* {alert("@@@@@@@"+JSON.stringify(gdprPublisher))} */}
																					<td style={{ textAlign: "center" }}>
																						{/* <img src={gdprPublisher.pID + ".png"} style={{width:'100%'}}></img> */}
																						<label
																							id="label"
																							style={{
																								align: "center",
																								marginLeft: "-27px",
																							}}>
																							{gdprPublisher.publisherName}
																						</label>{" "}
																					</td>
																					<td>
																						<label id="label">
																							{gdprPublisher.rating}
																						</label>
																					</td>
																					<td style={{ textAlign: "center" }}>
																						<label
																							id="label"
																							style={{ align: "center" }}>
																							{gdprPublisher.gdprCompliance}
																						</label>{" "}
																					</td>

																					<td>
																						<input
																							type="date"
																							id="startDate"
																							name="startDate"
																							value={this.state.startDate}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.startDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>
																					<td>
																						<input
																							type="date"
																							id="endDate"
																							name="endDate"
																							value={this.state.endDate}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.endDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>

																					<td>
																						<input
																							type="date"
																							id="firstLeadDeliveryDate"
																							name="firstLeadDeliveryDate"
																							value={
																								this.state.firstLeadDeliveryDate
																							}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{ width: "87%" }}
																							defaultValue={
																								gdprPublisher.firstLeadDeliveryDate
																							}
																							class="form-control"
																						/>{" "}
																					</td>

																					<td>
																						{gdprPublisher.pubAllocatedLead ==
																						null
																							? 0
																							: gdprPublisher.pubAllocatedLead}
																					</td>

																					<td>
																						<input
																							type="textbox"
																							class="form-control"
																							id="allocatedLead"
																							name="allocatedLead"
																							value={this.state.allocatedLead}
																							onChange={this.handleChangeGDPRPublisher.bind(
																								this,
																								i
																							)}
																							style={{
																								width: "100%",
																								height: "100%",
																							}}
																						/>
																					</td>
																					<td>
																						{gdprPublisher.status ===
																						"Accept" ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								disabled
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : gdprPublisher.status ===
																						  "Assign" ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : gdprPublisher.status == null ? (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={gdprPublisher.cpl}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						) : (
																							<input
																								type="textbox"
																								class="form-control"
																								id="cpl"
																								name="cpl"
																								defaultValue={
																									gdprPublisher.campCPL
																								}
																								onChange={this.handleChangeGDPRPublisher.bind(
																									this,
																									i
																								)}
																								style={{
																									width: "100%",
																									height: "100%",
																								}}
																							/>
																						)}
																					</td>
																				</tr>
																			);
																		}
																	)}
																</tbody>
															</table>
														</div>

														<div class="modal-footer">
															<button
																type="submit"
																className={this.state.inputClass}
																class="btn add-button"
																data-toggle="modal"
																data-target="#myModal"
																style={{
																	backgroundColor: "#2196F3",
																	color: "white",
																}}>
																Allocate
															</button>
															{/* <button
                                        type="button" 
                               class="btn btn-default" 
                               //data-toggle="modal" 
                               data-target="#myModal"
                                        onClick={this.handleRefreshPage}
                                      >
                                        Close
                                      </button> */}
														</div>
													</form>

													{/* </div> */}
												</div>

												{/**End of col */}
											</div>
										</span>
									) : (
										""
									)}

									{/**If Agency Click On No Button*/}
									{/* kiran-4745-removing console warning-changed == to === */}
									{this.state.gdprStatus === "false" ? (
										<div class="row">
											<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
												<form
													method="POST"
													name="Publisher"
													onSubmit={this.submitPublisher}>
													<div class="table-responsive-lg">
														<table class=" table table-striped">
															<thead>
																<tr class="info">
																	<th class="table-header">Publisher Name</th>
																	<th class="table-header">Rating</th>
																	<th class="table-header">GDPR</th>

																	<th class="table-header">Start Date</th>
																	<th class="table-header">End Date</th>
																	<th class="table-header">
																		First Lead Delivery Date
																	</th>
																	<th class="table-header">Allocated Leads</th>
																	<th class="table-header">Allocation</th>
																	{user.role === "AC" ? (
																		<th
																			class="table-header"
																			style={{ width: "10%" }}>
																			CPL
																		</th>
																	) : (
																		""
																	)}
																</tr>
															</thead>
															<tbody>
																{/*
																 * @author Narendra Phadke
																 * @param  Description handle the multiple allocate campaign
																 * @return Description return successfully allocated message
																 */}

																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="campID"
																			name="campID"
																			//value={this.state.startDate}
																			value={campaignDetailID.campID}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}

																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalLead"
																			name="totalLead"
																			//value={this.state.startDate}
																			value={campaignDetailID.leadAllocation}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}

																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalAllocate"
																			name="totalAllocate"
																			//value={this.state.startDate}
																			value={campaignDetailID.allocatedLead}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}

																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="leadAllocation"
																			name="leadAllocation"
																			//value={this.state.startDate}
																			value={campaignDetailID.leadAllocation}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}

																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalBudget"
																			name="totalBudget"
																			//value={this.state.startDate}
																			value={campaignDetailID.budget}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalRemainingBudget"
																			name="totalRemainingBudget"
																			//value={this.state.startDate}
																			value={campaignDetailID.remainingBudget}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalendDate"
																			name="totalendDate"
																			//value={this.state.startDate}
																			value={campaignDetailID.endDate}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalfirstLeadDeliveryDate"
																			name="totalfirstLeadDeliveryDate"
																			//value={this.state.startDate}
																			value={
																				campaignDetailID.firstLeadDeliveryDate
																			}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalstartDate"
																			name="otalstartDate"
																			value={campaignDetailID.startDate}
																		/>
																	)
																)}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalPending"
																			name="totalPending"
																			value={
																				campaignDetailID.allocatedLead
																					? campaignDetailID.leadAllocation -
																					  campaignDetailID.allocatedLead
																					: "0"
																			}
																		/>
																	)
																)}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="RejectedLead"
																			name="RejectedLead"
																			value={campaignDetailID.rejectedLead}
																		/>
																	)
																)}
																{this.state.mergedCampaignDetailAssign.map(
																	(campaignDetailAssign, i) => {
																		//  {this.state.newDynamicArray.map((p,i) =>{
																		return (
																			<tr>
																				{/* {alert("Inside campaignDetailAssign"+JSON.stringify(campaignDetailAssign))} */}

																				<td style={{ textAlign: "center" }}>
																					{/* <img src={campaignDetailAssign.pID + ".png"} style={{width:'100%'}}></img> */}
																					<label
																						id="label"
																						style={{
																							align: "center",
																							marginLeft: "-27px",
																						}}>
																						{campaignDetailAssign.publisherName}
																					</label>{" "}
																				</td>
																				<td style={{ textAlign: "center" }}>
																					<label
																						id="label"
																						style={{ align: "center" }}>
																						{campaignDetailAssign.rating}
																					</label>
																				</td>
																				<td style={{ textAlign: "center" }}>
																					<label
																						id="label"
																						style={{ align: "center" }}>
																						{
																							campaignDetailAssign.gdprCompliance
																						}
																					</label>
																				</td>

																				<td>
																					<input
																						type="date"
																						id="startDate"
																						name="startDate"
																						value={this.state.startDate}
																						onChange={this.handleChangeDynamicPublisher.bind(
																							this,
																							i
																						)}
																						style={{ width: "87%" }}
																						defaultValue={
																							campaignDetailAssign.startDate
																						}
																						class="form-control"
																					/>{" "}
																				</td>
																				<td>
																					<input
																						type="date"
																						id="endDate"
																						name="endDate"
																						value={this.state.endDate}
																						onChange={this.handleChangeDynamicPublisher.bind(
																							this,
																							i
																						)}
																						style={{ width: "87%" }}
																						defaultValue={
																							campaignDetailAssign.endDate
																						}
																						class="form-control"
																					/>{" "}
																				</td>
																				<td>
																					<input
																						type="date"
																						id="firstLeadDeliveryDate"
																						name="firstLeadDeliveryDate"
																						value={
																							this.state.firstLeadDeliveryDate
																						}
																						onChange={this.handleChangeDynamicPublisher.bind(
																							this,
																							i
																						)}
																						style={{ width: "87%" }}
																						defaultValue={
																							campaignDetailAssign.firstLeadDeliveryDate
																						}
																						class="form-control"
																					/>{" "}
																				</td>

																				<td>
																					{campaignDetailAssign.pubAllocatedLead ==
																					null
																						? 0
																						: campaignDetailAssign.pubAllocatedLead}
																				</td>
																				<td>
																					<input
																						type="textbox"
																						class="form-control"
																						id="allocatedLead"
																						name="allocatedLead"
																						value={this.state.allocatedLead}
																						onChange={this.handleChangeDynamicPublisher.bind(
																							this,
																							i
																						)}
																						style={{
																							width: "100%",
																							height: "100%",
																						}}
																					/>
																				</td>
																				{/* <td > <input  type="textbox" class="form-control" id="cpl" name="cpl" value={this.state.cpl} onChange={this.handleChangeDynamicPublisher.bind(this, i)}
                       style={{width: "110%", height: "100%"}}/></td>  */}

																				<td>
																				{/* kiran-4745-removing console warning-changed == to === */}
																					{campaignDetailAssign.status ===
																					"Accept" ? (
																						<input
																							type="textbox"
																							class="form-control"
																							id="cpl"
																							name="cpl"
																							defaultValue={
																								campaignDetailAssign.cpl
																							}
																							disabled
																							onChange={this.handleChangeDynamicPublisher.bind(
																								this,
																								i
																							)}
																							style={{
																								width: "100%",
																								height: "100%",
																							}}
																						/>
																					) : campaignDetailAssign.status ===
																					  "Assign" ? (
																						<input
																							type="textbox"
																							class="form-control"
																							id="cpl"
																							name="cpl"
																							defaultValue={
																								campaignDetailAssign.cpl
																							}
																							onChange={this.handleChangeDynamicPublisher.bind(
																								this,
																								i
																							)}
																							style={{
																								width: "100%",
																								height: "100%",
																							}}
																						/>
																					) : campaignDetailAssign.status ==
																					  null ? (
																						<input
																							type="textbox"
																							class="form-control"
																							id="cpl"
																							name="cpl"
																							defaultValue={
																								campaignDetailAssign.cpl
																							}
																							onChange={this.handleChangeDynamicPublisher.bind(
																								this,
																								i
																							)}
																							style={{
																								width: "100%",
																								height: "100%",
																							}}
																						/>
																					) : (
																						<input
																							type="textbox"
																							class="form-control"
																							id="cpl"
																							name="cpl"
																							defaultValue={
																								campaignDetailAssign.campCPL
																							}
																							onChange={this.handleChangeDynamicPublisher.bind(
																								this,
																								i
																							)}
																							style={{
																								width: "100%",
																								height: "100%",
																							}}
																						/>
																					)}
																				</td>
																			</tr>
																		);
																	}
																)}
															</tbody>
														</table>
													</div>
													<div class="modal-footer">
														<button
															type="submit"
															className={this.state.inputClass}
															class="btn add-button"
															style={{
																backgroundColor: "#144999",
																color: "white",
															}}>
															Allocate
														</button>
														{/* <button
                                        type="button" 
                                        class="btn btn-default"
                                        onClick={this.handleRefreshPage}
                                      >
                                        Close
                                      </button> */}
													</div>
												</form>
											</div>

											{/**End of col */}
										</div>
									) : (
										""
									)}
									{/**End of 2nd row */}
								</div>
								{/**End of container */}
							</div>
							{/* <!-- End Modal body--> */}

							{/* <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div><!-- Footer End--> */}
						</div>{" "}
						{/* <!--modal content--> */}
					</div>{" "}
					{/* <!-- modal dialog--> */}
				</div>{" "}
				{/* <!-- modal fade--> */}
				{/**End of  rejected Campaign Allocate Modal */}
			</div>
		);
	}
} //end of class
/**
 * @author Sunita Landge
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CampaignRespond.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(CampaignRespond)
);
