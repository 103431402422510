/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Sanobar Golandaj
@Description:UI for finish campaign under create campaign
*/

import React from 'react';
import { form } from 'mdbreact';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import Footer from '../layouts/footer';
import Navigation from '../layouts/navPage';
/*@author Narendra phadke
*use for fetch url value
*/
const queryString = require('query-string');



class EditFinishCampaign extends React.Component {
    /*@author Narendra Phadke
    * Desc Display current Adding campaign basic details
    */
    constructor(props) {
        super(props)
        // console.log(props)
        this.state = {
            campaignDetails: [],


        }
        this.logChange = this.logChange.bind(this);

    }
    componentDidMount() {
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
        }else{

        /*@author Narendra phadke
      *Desc Fetch campID from Url
      * dependencies query-string
      */
        var parsed = queryString.parse(this.props.location.search);
        var campID = parsed.campID;
        console.log("Control on react page");
        this.setState({campID});
        console.log(campID);
 const {isAuthenticated, user} = this.props.auth;

        let self = this;

        fetch("campaign/editFinishCampaign?campID=" + campID + "")
            .then(res => res.json())
            .then(campaignDetails => self.setState({ campaignDetails: campaignDetails }));
        

        /**
         * @author Narendra Phadke
         * @param  Description handle the email functionality
         * @return Description return successfully email send
         */
        let data={
            campID:campID,
            user:user
        }
        fetch("campaign/emailSendFinishCampaign",{
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
            // .then(res => res.json())
            // .then(campaignDetails => self.setState({ campaignDetails: campaignDetails }));
        }
    }
    logChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
   
    render() {
        const linkStyle = {
            fontSize: '15px',
            color: 'red'

        };

        /*@author Narendra phadke
          *Desc Fetch campID from Url
          * dependencies query-string
          */
        var parsed = queryString.parse(this.props.location.search);



        return (
            <div>
                <Navigation />

                <div class="col" align="center"style={{paddingTop:"90px"}}><label id="labelsuccessheading" >Campaign Updated successfully !!!</label></div>
                <div class="col" align="center"><label id="labelsuccessheading" >Campaign ID :{parsed.campID} </label></div>
                <div class="col" align="center"><label id="labelsuccessheading" style={{color:'orange'}} >Please <a href='allocatingCampaign#4'>click here</a> to check live campaigns.</label></div>
            
                <div class="container" style={{marginBottom:"60px"}}>

                   
                    <div class="card card-signin">
                        <div class="card-body">


                            {this.state.campaignDetails.map(campaignDetail =>

                              
                                   

                                    <div class="row">
                                         <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                         <legend className="legend labelsubheading">&nbsp;&nbsp;Basic Details</legend>
                                         
                                         
                                         <div class=" col-md-6 col-lg-4">
                                            <label id="label">Campaign ID:&nbsp;</label>
                                            {campaignDetail.campID}
                                        </div>
                                        

                                        <div class="col-md-6 col-lg-4">
                                       
                                       <label id="label">Campaign Name:&nbsp;</label>
                                       {campaignDetail.campaignName}
                                   </div>


                                   <div class="col-md-6 col-lg-4">
                                        
                                        <label id="label">Client Name:&nbsp;</label>
                                        {campaignDetail.clientName}
                                    </div>

                                   <div class="col-md-6 col-lg-4">
                                        
                                        <label id="label">Agency Campaign ID:&nbsp;</label>
                                        {campaignDetail.clientCampID}
                                    </div>

                                   
                                        <div class=" col-md-6 col-lg-4"> 
                                        
                                            <label id="label">Insertion Order:&nbsp;</label>
                                            {campaignDetail.insertionOrder}
                                        </div>
                                       
                             
                                        

                                   



               
                                      
                <div class="col-md-6 col-lg-4">
                                        
                                            <label id="label">Campaign Status:&nbsp;</label>
                                            {campaignDetail.campaignStatus}
                                        </div>


                                        <div class="col-md-6 col-lg-4">
                                        
                                        <label id="label">Start Date:&nbsp;</label>
                                        {campaignDetail.startDate}
                                    </div>


                        
                                    <div class="col-md-6 col-lg-4">
                                        
                                        <label id="label">End Date:&nbsp;</label>
                                        {campaignDetail.endDate}
                                    </div>

                                        <div class="col-md-6 col-lg-4">
                                    <label id="label">Timezone:&nbsp;</label>
                                            {campaignDetail.timezone}
                                        </div>
                                       
                <div class="col-md-6 col-lg-4">
                                        
                                            <label id="label">Lead Allocation:&nbsp;</label>
                                            {campaignDetail.leadAllocation}
                                        </div>
                                        

                                  

               
                            
             
                                        
                <div class="col-md-6 col-lg-4">
                                        
                                            <label id="label">Created By:&nbsp;</label>
                                            {campaignDetail.createdByCompanyName}
                                        </div>
                                       

                            </div>
                            </div>


                            
                            )}
                        </div>
                    </div>
                </div>



                <Footer />
            </div>


        );
    }
}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      EditFinishCampaign.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })

export default connect(mapStateToProps, { logoutUser })(withRouter(EditFinishCampaign));

