/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file addCompliance
 * @author Sonali
 * @version 1.0
 * @section add Compliance and compliance questions
 */
import React, { Component } from "react";
//import { Table } from "mdbreact"; Rutuja 4753 console warrning
import AdminNavpage from "../layouts/adminNavpage.js";
import "./admin.css";
import Footer from "../layouts/footer";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Somnath Task-3930-added default header
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
// import Picky from "react-picky"; Rutuja 4753 console warrning
import "./addCompliance.css"; // 1330-Added css for Compliance Question screen design-Front End
// import { isNull } from "lodash"; Rutuja 4753 console warrning
// import * as $ from "jquery";Rutuja 4753 console warrning

const Swal = require("sweetalert2");
var count = 1;
// var questionID = 0; Rutuja 4753 console warrning

class AddCompliance extends Component {
  constructor() {
    super();
    this.state = {
      complianceNames: [],
      complianceName: "",
      complianceRole: "",
      questionDetails: [],
      dynamicQuestion: [0],
      questionArray: [],
      cID: "",
      formIsvalid:true,
    };

    this.handleChangeComplianceName = this.handleChangeComplianceName.bind(
   this
    );
    this.handleChangeComplianceRole = this.handleChangeComplianceRole.bind(
      this
    );
    this.submitCompliance = this.submitCompliance.bind(this);
    this.displayComplianceQuestions = this.displayComplianceQuestions.bind(
      this
    );
    
    this.submitQuestions = this.submitQuestions.bind(this);
    this.validateForm=this.validateForm.bind(this);
    this.validateQueForm=this.validateQueForm.bind(this);
    // this.handleAddQuestion=this.handleAddQuestion.bind(this);
  } //end of constructor
  //Chaitanya-4385-R-29.2-Admin-Add Compliance --issue
  
  validateQueForm(questionArray){
    let formIsvalid=true;
    //alert(JSON.stringify(questionArray));
    if (questionArray.length !== 0 ){
      var keys=Object.keys(questionArray[0]);
      if(keys.includes("newQuestion", 0) && keys.includes("answer", 0)) 
        {
          //alert(questionArray.newQuestion);
          if(questionArray.newQuestion === "");
          //alert("true");
        }
      else{ 
        //alert("false")
        formIsvalid=false;
        questionArray.length=0;
        //alert(JSON.stringify(questionArray));
      }
    }else{ 
      formIsvalid=false;
    }
    return formIsvalid;
    }

  submitQuestions(e) {
    //Chaitanya-4385-R-29.2-Admin-Add Compliance --issue
    if(this.validateQueForm(this.state.questionArray)=== false)
     {
      this.setState({
        formIsvalid: false
      });
      Swal.fire({
        text: "Please Enter Required Fields",
        type: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
     }
    else{
    let data = {
      questionArray: this.state.questionArray,
      // cID:this.state.cID
    };

    fetch("admin/submitQuestions", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: "Questions Added Successfully",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.href = "/addCompliance";
            },
          });
        }
      })
      .catch(function(err) {
        console.log(err);
      });
  }
}
//add  delete fuction vrushabh task 4437
deleteQuestion(questionDetails) {
  
  var qID =questionDetails.qID;

  Swal.fire({
    title:
      `Are you sure you want to delete this question`,
    type: "question",
    showCancelButton: true,
    cancelButtonColor: "#d33",
    confirmButtonColor: "#3085d6",
    allowOutsideClick: false,
    confirmButtonText: "Yes",
  }).then((questionDetails) => {
    //alert(questionDetails.value)
    if (questionDetails.value) {
      let data = {
        qID: qID

      };
    
      fetch("/admin/questionDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      .then((res) => res.json())
      .then((response) => {
        
        if (response.success === true) {
          Swal.fire({
            html: "Your question has been successfully Deleted",
            type: "success",
            allowOutsideClick: false,
            confirmButtonText: "Ok",
            preConfirm: () => {
            window.location.reload()
            },
          });
        }
      })
      .catch(function(err) {
        console.log(err);
      });



     

    }
  });
}
  handleChangeComplianceName(e) {
    var complianceName = e.target.value;
    this.setState({ complianceName: complianceName });
  }
  handleChangeComplianceRole(e) {
    var complianceRole = e.target.value;
    this.setState({ complianceRole: complianceRole });
  }
  handleAddQuestion(i, e) {
    const { name, value } = e.target;
    //alert("name==>"+name)
    //alert("value==>"+value)

    var cID = e.target.getAttribute("cID");
    let questionArray = [...this.state.questionArray];
    questionArray[i] = { ...questionArray[i], [name]: value, "cID" : cID }; //Rutuja 4753 changed ["cID"] to "cID"

   // this.state.questionArray = [...questionArray];
   this.setState({questionArray: [...questionArray]}); //Rutuja 4753 commented above this.state and used this.setState
    //alert("questionArray==>"+JSON.stringify(questionArray))
  }

  displayComplianceQuestions(e) {
    e.preventDefault(); //Rutuja 4753 console warrning task 
    var complianceID = e.target.getAttribute("complianceID");
    // alert("complianceID==>"+complianceID)
    fetch("/admin/getQuestions?complianceID=" + complianceID)
      .then((res) => res.json())
      .then((questionDetails) => {
        //alert(JSON.stringify(questionDetails))
        this.setState({
          questionDetails: questionDetails,
          cID: questionDetails[0].complianceID,
          complianceName: questionDetails[0].complianceName,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  }
  
  //Chaitanya-4385-R-29.2-Admin-Add Compliance --issue
  validateForm(complianceName,complianceRole){
    let formIsvalid=true;
    if(complianceName==="" || complianceRole===""  ) //Rutuja 4753 changed == to ===
    {
      formIsvalid=false;
    }
    return formIsvalid;
  }

  submitCompliance(e) {
    //Chaitanya-4385-R-29.2-Admin-Add Compliance --issue
    if(this.validateForm(this.state.complianceName,this.state.complianceRole)=== false)
     {
      this.setState({
        formIsvalid: false
        });
        Swal.fire({
          text: "Please Enter Required Fields",
           type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
      });
     }
    else{
    e.preventDefault();
    // const {user} = this.props.auth;

    let data = {
      complianceName: this.state.complianceName,
      complianceRole: this.state.complianceRole,
    };
    fetch("/admin/addCompliance", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: "Compliance Added Successfully",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.href = "/addCompliance";
            },
          });
        }
      })
      .catch(function(err) {
        console.log(err);
      });
  }
}
  addQuestion(e) {
    e.preventDefault();//Rutuja task 4819 console warrning
    count = count + 1;

    if (count <= 15) {
      this.setState({
        dynamicQuestion: [...this.state.dynamicQuestion, count],
      });
    }
  }
  removeQuestion(i,e) { //rutuja task 4819  used e for adding e.preventDefault
    e.preventDefault(); //Rutuja task 4753 console warrning
    this.state.dynamicQuestion.splice(i, 1);
    this.setState({ dynamicQuestion: this.state.dynamicQuestion });
    this.state.questionArray.splice(i, 1);
  }

  componentDidMount() {
    fetch("admin/getComplianceNames")
      .then((res) => res.json())
      .then((complianceNames) => {
        this.setState({ complianceNames: complianceNames });
        //alert(JSON.stringify(complianceNames))
      })
      .catch(function(err) {
        console.log(err);
      });
  }
  //Somnath Task-3965, Set Blank array when close the popup instead of refresh
  onClose(e) {
    this.setState({ questionDetails: [] });
    // window.location.href = "/addCompliance";
  }

  render() {
    return (
      <div>
        <AdminNavpage />
        <div class="container-fluid" style={{ paddingTop: "90px" }}>
          <div class="row">
            <div
              class="col-lg-4 col-md-4 col-sm-4 col-xs-2"
              style={{ paddingLeft: "20px" }}
            >
              <a href="./adminDashboard" style={{ color: "#056eb8" }}>
                <FaArrowAltCircleLeft
                  size={32}
                  style={{ float: "left" }}
                  title="Back to Dashboard"
                />
              </a>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-8">
              <label
                id="label"
                className="AddComplianceTitle" /*1330-Compliance Question screen design*/
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#056eb8",
                  marginLeft: "141px",
                }}
              >
                {" "}
                Add Compliance
              </label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-2"></div>
          </div>
          <br />

          <form  method="POST">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-4 col-xs-4 CompName">
              {" "}
              {/*1330-Compliance Question screen design*/}
              <span className="asterisk">*</span>
              <label
                id="label1"
                style={{
                  fontWeight: "400",
                  fontFamily: "-apple-system, Segoe UI",
                  color: "#000080",
                  maxWidth: "100%",
                  marginTop:"30px",/*vrushabh add margin top 4142*/
                  marginBottom: "3px",
                }}
              >
                Compliance Name: &nbsp;&nbsp;
              </label>
              <input
                type="text"
                name="compliance"
                id="complianceName1"
                required
                onChange={this.handleChangeComplianceName}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "2px",
                  borderColor: "#eee",
                  boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
                  width: "180px",
                  height: "calc(2.25rem + 12px)",
                }}
              />
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 CompRole">
              {" "}
              {/*1330-Compliance Question screen design*/}
              <span className="asterisk">*</span>
              <label
                id="label1"
                style={{
                  fontWeight: "400",
                  fontFamily: "-apple-system, Segoe UI",
                  color: "#000080",
                  maxWidth: "100%",
                  marginBottom: "3px",
                 
                }}
              >
                Role:
              </label>
              &nbsp;&nbsp;
              <input
                type="text"
                name="role"
                id="complianceRole1"
                required
                onChange={this.handleChangeComplianceRole}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "2px",
                  borderColor: "#eee",
                  boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
                  width: "180px",
                  height: "calc(2.25rem + 12px)",
                }}
              />
            </div>
            <div class="col-lg-4 col-md-2 col-sm-4 col-xs-4 ComplianceDetailSubmit">
              {" "}
              {/*1330-Compliance Question screen design*/}
              <button
                type="button"
                onClick={this.submitCompliance}
                style={{
                  backgroundImage: "linear-gradient(to left,#0777cb, #103784)",
                  color: "white",
                }}
                class="btn"
              >
                Submit
              </button>
            </div>
          </div>
          </form>
          <br />
          <div class="table-responsive-lg-md-sm">
            <table
              id="myTable"
              class="table"
              className=" table table-bordered table-striped"
            >
              <thead class="CompDetailTableHead">
                {" "}
                {/*1330-Compliance Question screen design*/}
                <tr class="info tr1">
                  <th class="table-header">Compliance Name</th>
                  <th class="table-header">Role</th>
                </tr>
              </thead>
              <tbody className="tbody1 CompDetailTableBody">
                {" "}
                {/*1330-Compliance Question screen design*/}
                {this.state.complianceNames.map((complianceNames) => (
                  <tr className="tr1">
                    <td style={{ textAlign: "left" }}>
                      <a
                        href="#/" //Rutuja task - 4753 changed # to #/
                        data-toggle="modal"
                        data-target="#question1"
                        onClick={this.displayComplianceQuestions}
                        complianceID={complianceNames.complianceID}
                      >
                        {complianceNames.complianceName}
                      </a>
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {complianceNames.role}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            class="modal fade"
            id="question1"
            role="dialog"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content CompQuesContent">
                {" "}
                {/*1330-Compliance Question screen design*/}
                <div class="modal-header custom-classPopup">
                  <h4
                    class="modal-title CompQuesTitle" /*1330-Compliance Question screen design*/
                    style={{ color: "#144999", paddingRight: "780px" }}
                  >
                    <font
                      color="white"
                      style={{
                        paddingRight: "35px",
                        fontFamily: "arial",
                        fontSize: "18px",
                      }}
                    >
                      Compliance Questions
                    </font>
                  </h4>
                  <h4>
                    <button
                      type="button"
                      onClick={this.onClose.bind(this)}
                      style={{ fontSize: "larger",float: "right" }}/*/*vrushabh4438*/
                      class="cancel-button"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </h4>
                </div>
                {/*end header*/}
                <div class="modal-body CompQuesBody">
                  {" "}
                  {/*1330-Compliance Question screen design*/}
                  <div class="row">
                    {this.state.dynamicQuestion.map((dynamicQuestion, i) => {
                      return (
                        <form  method="POST">
                        <div key={dynamicQuestion}>
                          {/* {alert(JSON.stringify(dynamicQuestion))} */}
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 AddNewQues">
                            {" "}
                            {/*1330-Compliance Question screen design*/}
                            <label>Add New Question:</label>
                            {/* {this.state.dynamicQuestion.map((dynamicQuestion,i)=>{return(
                              <div key={dynamicQuestion}> */}
                            <textarea
                              rows="3"
                              cols="40"
                              name="newQuestion"
                              required
                              id="newQuestion1"
                              className="AddQuesText"
                              cID={this.state.cID}
                              onChange={this.handleAddQuestion.bind(this, i)}
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "2px",
                                borderColor: "#000",
                                boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
                              }}
                            ></textarea>
                            {/* </div>
      )})} */}
                          </div>
                          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 ComQueAns">
                            <label>Answers:</label>

                            <textarea
                              rows="3"
                              cols="15"
                              name="answer"
                              id="answer" 
                              cID={this.state.cID}
                              onChange={this.handleAddQuestion.bind(this, i)}
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "2px",
                                borderColor: "#000",
                                boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
                              }}
                            ></textarea>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 SuppDocRequired">
                            <label>Supporting Document Required:</label>
                            <select
                              name="suppDocReq"
                              cID={this.state.cID}
                              onChange={this.handleAddQuestion.bind(this, i)}
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "2px",
                                borderColor: "#eee",
                                boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
                                width: "90px",
                                height: "calc(2.25rem + 12px)",
                              }}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 MinusSign">
                          {/* //Rutuja 4753 added href  */}
                          {/* Rutuja Task 4819 passing e in below onclick for adding e.preventdefult() for removing # in url*/}
                            <a href="#/">
                              <span
                                class="glyphicon glyphicon-minus-sign"
                                id={i}
                                onClick={(e) => this.removeQuestion(i,e)}
                                style={{
                                  color: "Red",
                                  fontWeight: "bold",
                                  fontSize: 22,
                                }}
                              ></span>
                            </a>
                          </div>
                        </div>
                        </form>
                      );
                    })}
                  </div>
                  <br />
                  <div class="row">
                    <p>
                      {/* Rutuja 4753 added href  */}
                      <a href ="#/" data-toggle="tooltip" title="Add one more question">
                        <span
                          class="glyphicon glyphicon-plus-sign"
                          onClick={(e) => this.addQuestion(e)}
                          style={{
                            color: "#2196f3",
                            fontWeight: "bold",
                            fontSize: 22,
                            marginLeft: "12px",
                          }}
                        ></span>
                      </a>
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={this.submitQuestions}
                    style={{
                      backgroundImage:
                        "linear-gradient(to left,#0777cb, #103784)",
                      color: "white",
                    }}
                    class="btn"
                  >
                    Submit
                  </button>

                 
                  <br />
                  <br />
                  {/* onChange={this.handleCustomQuestion.bind(this,i)} */}
                  <p>
                    <label style={{ fontWeight: "bold", fontSize: 20 }}>
                      {this.state.complianceName} Questions
                    </label>

                    <div class="table-responsive-lg-md-sm">
                      <table
                        id="myTable"
                        class="table"
                        className=" table table-bordered table-striped "
                      >
                        <thead class="CompQueTableHead">
                          {" "}
                          {/*1330-Compliance Question screen design*/}
                          <tr class="info tr1">
                            <th class="table-header col-2">ID</th>
                            <th class="table-header col-6">Question</th>
                            <th class="table-header col-2">Answers</th>
                            <th class="table-header col-2">Remove</th>{/*add colum vrushabh patil task 4437*/}

                          </tr>
                        </thead>
                        <tbody className="tbody1 CompQueTableBody">
                          {" "}
                          {/*1330-Compliance Question screen design*/}
                          {this.state.questionDetails.map((questionDetails) => (
                            <tr className="tr1">
                              <td class="col-2" style={{ textAlign: "left" }}>
                                {questionDetails.qID}
                              </td>
                              <td class="col-6" style={{ textAlign: "left" }}>
                                {questionDetails.questions}
                              </td>
                              <td class="col-2" style={{ textAlign: "left" }}>
                                {questionDetails.answer === null ||
                                questionDetails.answer === undefined ||
                                questionDetails.answer === "null" ||
                                questionDetails.answer === "undefined"
                                  ? ""
                                  : questionDetails.answer}
                              </td>
                              <td class="col-2" style={{ textAlign: "left" }}>
                              <span
                                class="glyphicon glyphicon-minus-sign"
                               
                                 onClick={() => this.deleteQuestion(questionDetails)}/*vrushabh add minus symbol */
                                style={{
                                  color: "Red",
                                  fontWeight: "bold",
                                  fontSize: 22, 
                                }}
                              ></span>
                              </td>

                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* end of 1st row */}
                  </p>
                  <div class="row"></div>
                  {/* row end*/}
                </div>
                {/*modal body end*/}
              </div>
            </div>
          </div>
        </div>
        {/* //close of conatiner */}
        <Footer />
      </div>
    );
  }
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AddCompliance.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(AddCompliance)
);
