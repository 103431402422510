/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/**
   * @author Snehal More
   * @param  Description createXLSRfp-task-3509-Project's(DI) - Create RFP through XLS - FE
   * @return Description createXLSRfp page create RFP througn xlsx file 
   * */
 import React from "react";
 import Footer from '../layouts/footer';
 import Navigation from '../layouts/navPage';
 import { Table } from 'antd';
 import PropTypes from 'prop-types';
 import './createXLSRFP.css';
 import { connect } from 'react-redux';
 import { logoutUser } from '../login/authentication';
 import { withRouter } from 'react-router-dom';
 import { Tooltip} from 'antd';
 import { css } from 'emotion';
 import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Sonali-task-4064-added default header
 
 const Swal = require('sweetalert2');
 const XLS = require('xlsx');
 const queryString = require('query-string');
 const tableCSS = css({
   backgroundColor: 'white',
   fontFamily: "sans-serif",
   borderStyle: 'bold',
   '& thead  > tr': {
     backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
     color: 'white',
     fontSize: '12px !important',
     fontWeight: '500',
   },
   '& thead > tr >th': {
     textAlign: "center !important",
     color: 'white',
   },
 });
 class CreateXLSRfp extends React.Component {
   constructor() {
     super();
     this.state = {
       agencyID: '',//snehal-for agency id 
       createdBy: '',//snehal-for created by 
       errorList: [],//snehal-for error arry
       fileName: '',//snehal-for file name
       tableSearchText: "",//snehal-for search 
       xlsxRFP: [],//snehal-for rfp list 
       loader_file: "",//snehal-for loader for file 
       loader: "",//snehal-for loader
       pageSize2: 50,//snehal-for pagination
       token: '',//snehal-for fot authentication
       tableState: { size: 'small' }//snehal-for table
     };
 
     this.createRfpByXls = this.createRfpByXls.bind(this);
     this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
     this.onClickRfpName = this.onClickRfpName.bind(this);
     this.onClickRfp = this.onClickRfp.bind(this);
 
     this.handleChangeSearch = this.handleChangeSearch.bind(this)
   }
   /**
        * @author Snehal More
        * @param  Description ComponentWillMount
        * @return Description render data when we go on page
        */
   //start of componentWillMount
   componentWillMount() {
     if (!this.props.auth.isAuthenticated) {
       this.props.history.push('/userLogin');
     }
     else {
       const {  user } = this.props.auth;
       // var createdBy = user.firstName + " " + user.lastName;
       var agencyID = user.id;
       const token = user.token;
       this.setState({ loader: "loader_xlsx" })
      // Sonali-4072-removing data
       // let data = {
       //   agencyID: agencyID,
       //   user: user
       // };
       fetch("/requestForProposal/rfpXLSXCampaignData", {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
           authorization: token,
         },
       //  body: JSON.stringify(data)
       }).then(res => res.json())
         .then(xlsxRFP => {
           this.setState({ xlsxRFP: xlsxRFP, loader: "" })
         }).catch(function (err) { console.log(err) });
     }
   }
   /**
 * @author Snehal More
 * @param  Description handle change handleChangeSearch
 * @return Description function is used to search the perticular record from multiple records
 */
   handleChangeSearch(e) {
     e.preventDefault();
     let currentList = [];
     // Variable to hold the filtered list before putting into state
     let newList = [];
     let filterData = e.target.value;
     // If the search bar isn't empty
     if (e.target.value == "") {
       newList = this.state.xlsxRFP;
     } else {
       // Assign the original list to currentList
       currentList = this.state.xlsxRFP
       newList = currentList.filter((item) => {
         let obj = {};//Somnath Task-3972, Add specific Keys in search criteria
         obj[`campID`] = item.campID;
         obj[`campaignName`] = item.campaignName;
         obj[`startDate`] = item.startDate;
         obj[`endDate`] = item.endDate;
         obj[`campaignType`] = item.campaignType;
         obj[`biddingEndDate`] = item.biddingEndDate;
         obj[`leadAllocation`] = item.leadAllocation;
         const filter = e.target.value.toLowerCase();
         var data = Object.values(obj);
         var dataFinal = data.toString();
         dataFinal = dataFinal.replace(",", " ");
         var dataToLower = dataFinal.toLowerCase();
         return dataToLower.includes(filter);
       });
     }
     // Set the filtered state based on what our rules added to newList
     this.setState({
       reportDetailsSearch: [...newList],
       tableSearchText: filterData,
     });
   }//handle search box
   /**
      * @author Snehal More
      * @param  Description createRfpByXls
      * @return Description upload file handle change
      */
   //start of createRfpXls
   createRfpByXls(e) {
     e.preventDefault();
     if (e.target.files[0] == null || e.target.files[0] == undefined || e.target.files[0] == "") { }
     else {
       const { isAuthenticated, user } = this.props.auth;
       const token = user.token;
       var xlsxFile = e.target.files[0];
       let fileName = xlsxFile.name;
       var ext = fileName.split('.').pop()
       var reader = new FileReader();
       var agencyID = user.id;
       this.setState({ fileName: fileName })
       if (ext == "xlsx") {
         this.setState({ loader_file: "loader_file_rfp_xlsx" })
         var data = new FormData();
         data.append("xlsxFile", xlsxFile);
        // 4072-removing agency ID 
        // data.append("agencyID", agencyID);
 
         fetch("/requestForProposal/createRFPCampaignThroughXLSX", {
           method: 'POST',
           // headers: {
           //   "Content-Type": "application/json",
           //   authorization: token,
           // },
           body: data
         }).then(res => res.json())
           .then(res => {
             this.setState({ loader_file: "" })
             if (res.success === true) {
               Swal.fire({
 
                 text: ("RFP Created Successfully, with RFP ID:'" + res.campID + "'"),
                 type: 'success',
                 confirmButtonText: 'Ok',
                 allowOutsideClick: false,
 
               })
               //snehal-task-3597-Agency side---RFP by XLSX----max lead error msg and page refresh issue
                   // Sonali-4072-removing data
               // let data = {
               //   agencyID: agencyID,
               //   user: user
               // };
               fetch("/requestForProposal/rfpXLSXCampaignData", {
                 method: "POST",
                 headers: {
                   "Content-Type": "application/json",
                   authorization: token,
                 },
                // body: JSON.stringify(data1)
               }).then(res => res.json())
                 .then(xlsxRFP => {
                   this.setState({ xlsxRFP: xlsxRFP, loader: "" ,xlsFile:''})
                 }).catch(function (err) { console.log(err) });
             }
             if (res.success === false) {
               if (res.Error) {
 
                 var Error1 = JSON.stringify(res.Error);
                 var Error2 = Error1.split('|');
 
                 Swal.fire({
                   html: Error2.join('<br>'),
                   type: 'error',
                   confirmButtonText: 'Ok',
                   allowOutsideClick: false,
                 })
                 this.setState({ fileName: '',xlsFile:'' })//snehal-task-3597-Agency side---RFP by XLSX----max lead error msg and page refresh issue
               }
               else {
                 Swal.fire({
                   text: ("This is not valid XLSX file."),
                   type: 'error',
                   confirmButtonText: 'Ok',
                   allowOutsideClick: false,
                 })
                 this.setState({ fileName: '',xlsFile:''})//snehal-task-3597-Agency side---RFP by XLSX----max lead error msg and page refresh issue
               }
             }
           })
 
           .catch(
             function (err) { console.log(err) }
           );
 
       }
       else {
         Swal.fire({
           type: 'error',
           html: "please upload file of <b>XLSX</b> format only"
         })
         this.setState({ fileName: '',xlsxFile:'' })//snehal-task-3597-Agency side---RFP by XLSX----max lead error msg and page refresh issue
       }
     }
   }
   /**
         * @author Snehal More
         * @param  Description onClickRfpName
         * @return Description handle change onClickRfpName
         */
   //start of onClickRfpName
   onClickRfpName(e) {
     e.preventDefault();
     const { isAuthenticated, user } = this.props.auth;
     var agencyID = user.id;
     const token = user.token;
     var campID = e.target.getAttribute("campID");
     var status = e.target.getAttribute("status");
     this.setState({ campID: campID, token });
     // window.location.href =
     //   "/rfpCampaignSpecification?proposalID=" + campID + "&status=" + status;
     this.props.history.push("/rfpCampaignSpecification",{proposalID:campID,status:status}); //sonali-task-3716-replace query params
 
     fetch("agencyDashboard/getRfpCampaignDetails?campID=" + campID)
       .then((res) => res.json())
       .then((campaignDetails) => {
         this.setState({ campaignDetails: campaignDetails });
       })
       .catch(function (err) {
         console.log(err);
       });
 
   } //end of displayCampaignSpecification
   /**
        * @author Snehal More
        * @param  Description onClickRfp
        * @return Description handle change onClickRfp continue
        */
   //start of onClickRfp
   onClickRfp(e) {
     e.preventDefault();
     var campID = e.target.getAttribute("campID");
     var status = e.target.getAttribute("status");
     this.props.history.push("/requestForProposal2",{rfpCampaignID:campID,status:status}); //sonali-task-3716-replace query params
 
   }
   /**
  * @author Snehal More
  * @param  Description handle change handleChangePageSize2
  * @return Description to change records per page 
  */
   handleChangePageSize2(e) {
     var pageSize2 = e.target.value;
     this.setState({ pageSize2: pageSize2 });
   }
   render() {
     const { isAuthenticated, user } = this.props.auth;
     const Column = [
       {
         title: <span>Personal ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
         dataIndex: 'campID',
         key: 'campID',
         width: '100px',
         align: 'left',
         sorter: (a, b) => a.campID - b.campID,
         render: (text, record) =>
           <div><span style={{ fontSize: '13px', color: '#4F4F4F' }}>{record.campID}</span></div>
       },
       {
         title: <span>Campaign Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
         dataIndex: 'campaignName',
         key: 'campaignName',
         width: '200px',
         align: 'left',
         sorter: (a, b) => {
           const asc = "asc";
 
           if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
             return asc ? -1 : 1;
           } else if (
             a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
           ) {
             return asc ? 1 : -1;
           } else {
             return 0;
           }
         },
         render: (text, record) =>
           <div id="to_truncate1">
             <span ><a href="#" onClick={this.onClickRfpName} style={{ fontSize: '13px' }}
               campID={record.campID} status={record.status}
             >
               {record.campaignName}</a></span>
           </div>
       },
       {
         title: <span>Campaign Type&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
         dataIndex: 'campaignType',
         key: 'campaignType',
         width: '200px',
         align: 'left',
         sorter: (a, b) => {
           const asc = "asc";
 
           if (a.campaignType.toUpperCase() < b.campaignType.toUpperCase()) {
             return asc ? -1 : 1;
           } else if (
             a.campaignType.toUpperCase() > b.campaignType.toUpperCase()
           ) {
             return asc ? 1 : -1;
           } else {
             return 0;
           }
         },
         render: (text, record) =>
           <div><span style={{ fontSize: '13px', color: '#4F4F4F' }}>{record.campaignType}</span></div>
       },
       {
         title: <span>Start Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
         dataIndex: 'startDate',
         key: 'startDate',
         width: '120px',
         align: 'left',
         sorter: (a, b) => {
           const asc = "asc";
 
           if (a.startDate.toUpperCase() < b.startDate.toUpperCase()) {
             return asc ? -1 : 1;
           } else if (
             a.startDate.toUpperCase() > b.startDate.toUpperCase()
           ) {
             return asc ? 1 : -1;
           } else {
             return 0;
           }
         },
         render: (text, record) =>
           <div><span style={{ fontSize: '13px', color: '#4F4F4F' }}>{record.startDate}</span></div>
       },
       {
         title: <span>End Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
         dataIndex: 'endDate',
         key: 'endDate',
         width: '120px',
         align: 'left',
         sorter: (a, b) => {
           const asc = "asc";
 
           if (a.endDate.toUpperCase() < b.endDate.toUpperCase()) {
             return asc ? -1 : 1;
           } else if (
             a.endDate.toUpperCase() > b.endDate.toUpperCase()
           ) {
             return asc ? 1 : -1;
           } else {
             return 0;
           }
         },
         render: (text, record) =>
           <div><span style={{ fontSize: '13px', color: '#4F4F4F' }}>{record.endDate}</span></div>
       },
       {
         title: <span>Bidding Closes&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
         dataIndex: 'biddingEndDate',
         key: 'biddingEndDate',
         width: '200px',
         align: 'left',
         sorter: (a, b) => {
           const asc = "asc";
 
           if (a.biddingEndDate.toUpperCase() < b.biddingEndDate.toUpperCase()) {
             return asc ? -1 : 1;
           } else if (
             a.biddingEndDate.toUpperCase() > b.biddingEndDate.toUpperCase()
           ) {
             return asc ? 1 : -1;
           } else {
             return 0;
           }
         },
         render: (text, record) =>
           <div><span style={{ fontSize: '13px', color: '#4F4F4F' }}>
             {/* Sandeep-task-3678-RFP XLSX---Bidding time issue */}
             {record.biddingEndDate}
             </span></div>
       },
       {
         title: <span>Total Leads&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
         dataIndex: 'leadAllocation',
         key: 'leadAllocation',
         width: '120px',
         align: 'left',
         sorter: (a, b) => a.leadAllocation - b.leadAllocation,
         render: (text, record) =>
           <div><span style={{ fontSize: '13px', color: '#4F4F4F' }}>{record.leadAllocation}</span></div>
       },
       {
         title: "Action",
         width: "150px",
         render: (record) => (
           <div>
             <Tooltip placement="left" title="Continue">
               <a href="#" onClick={this.onClickRfp} style={{ fontSize: '13px' }}
                 campID={record.campID} status={record.status} >
                 Continue
               </a>
             </Tooltip>
           </div>
         ),
       },
     ];
     function onChange(pagination, filters, sorter) {
     }
     return (
       <div>
         <Navigation />
         <div class="container-fluid" style={{ paddingTop: '95px' }}>
           <center><label id="labelDI" style={{ fontSize: '28px', fontStyle: 'roboto', fontWeight: '500', color: '#193D8F' }} align="center">Create XLSX RFP</label></center>
           {/* <div style={{ MozBoxShadow: '3px 3px 5px 0px #e6e6ff', WebkitBoxShadow: '3px 3px 5px 1px #e6e6ff', boxShadow: '3px 3px 5px 0px #e6e6ff' }}> */}
           <div style={{ borderBottom: '1px solid rgb(221, 221, 221)' }}>
             <div style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
               <div class="row" >
                 <div class="col">
                   <label style={{ paddingRight: "100px", fontSize: '16px', color: '#14254A' }}>To create RFP through XLSX, upload file here</label>
                   <br />
                   <label class="btn " style={{ color: "#144999", borderColor: '#144999', backgroundColor: '#FFFFFF', border: '2px solid #144999', fontFamily: 'sans serif' }}>Choose file
                     <input type="file" style={{ display: 'none', marginRight: "100px" }} value={this.state.xlsFile} name="xlsFile" onChange={this.createRfpByXls}></input>
                     {/* //snehal-task-3597-Agency side---RFP by XLSX----max lead error msg and page refresh issue */}
                   </label>
                   {this.state.fileName.length > 0 ? <span style={{ fontSize: '14px', color: '#414141' }}>&nbsp;&nbsp;{this.state.fileName}</span>
                     : <span style={{ fontSize: '14px', color: '#414141' }}>&nbsp;&nbsp;No file choosen</span>}
                 </div>
               </div>
               <div class="row">
                 <div class="col-lg-4 col-md-8 col-sm-12">
                   <ul>
                     {this.state.errorList.map(errorList => (
                       <li style={{ color: 'Red' }}>&nbsp;&nbsp;&nbsp;&nbsp;{JSON.stringify(errorList)}</li>
                     ))}
                   </ul>
                 </div>
               </div>
             </div>
           </div>
 
           <div class="row" style={{ paddingTop: '15px', }}>
             <div class=" col-lg-10 col-md-5 col-sm-8">
               <label style={{ fontSize: '20px', color: '#F28C0F', fontWeight: '500' }}>List of existing RFP by XLSX</label>
             </div>
             <div class=" col-lg-2 col-md-5 col-sm-4" style={{ float: 'right' }}>
               <input
                 id="search_xlsx_rfp"
                 type="text"
                 className="form-control search_xlsx_rfp"
                 style={{
                   backgroundImage: "url(searchIcon.png)",
                   backgroundPosition: "15px 6px",
                   backgroundSize: "15px 15px",
                   backgroundRepeat: "no-repeat",
                   //width: "100%",
                   height: "20px",
                   fontSize: "12px",
                   padding: "16px 20px 16px 34px",
                   borderRadius: "4px",
                   border: '1px solid rgb(221, 221, 221)'
                 }}
                 onChange={this.handleChangeSearch}
                 placeholder="Search..."
                 title="Search"
               />
             </div>
           </div>
           <div align="center" id={this.state.loader}></div>
           <div align="center" id={this.state.loader_file}></div>
           <div class="table-responsive" >
             {this.state.tableSearchText === "" ?
               <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.xlsxRFP}
                 onChange={onChange} columns={Column} className="ant-table-thead" className={tableCSS} scroll={{ y: 700, x: 1000, }}
                 pagination={{ pageSize: this.state.pageSize2 }}></Table>
               : <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.reportDetailsSearch}
                 onChange={onChange} columns={Column} className="ant-table-thead" className={tableCSS} scroll={{ y: 700, x: 1000, }}
                 pagination={{ pageSize: this.state.pageSize2 }}></Table>
             }
             <p>
               <div class="row" style={{ paddingBottom: '120px' }}>
                 <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{ marginTop: '-50px', fontFamily: 'sans-serif', fontSize: '13px', color: '#4F4F4F' }}>
                   &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                   <select
                     defaultValue={this.state.pageSize2}
                     onChange={this.handleChangePageSize2}
                     id="pacing1"
                     class="input-small"
                     className="form-control"
                     name="pacing" style={{ width: '80px', height: '30px', display: 'initial' }}>
                     <option value="100" selected>50</option>
                     <option value="150" >100</option>
                     <option value="200">150</option>
                     <option value="250">200</option>
                   </select>
                 </div>
               </div>
             </p>
 
           </div>
 
         </div>
         <Footer />
       </div>
     );
   }
 
 }
 /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
 CreateXLSRfp.propTypes = {
   logoutUser: PropTypes.func.isRequired,
   auth: PropTypes.object.isRequired
 }
 
 const mapStateToProps = (state) => ({
   auth: state.auth
 })
 
 export default connect(mapStateToProps, { logoutUser })(withRouter(CreateXLSRfp));
 