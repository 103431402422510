/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
import { GET_ERRORS } from '../components/login/types';

const initialState = {};

export default function(state = initialState, action ) {
    switch(action.type) {
        case GET_ERRORS:
            return action.payload;
        default: 
            return state;
    }
}