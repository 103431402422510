/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*@author Sandeep Dhawale
*@fileName : advertiserLeadTracking.js
@Creation Date:20-05-2021
 *Desc: 
*/
import React from "react";
// import { Tabs, Tab } from 'react-bootstrap'; //kiran-4748-removing console warning-no used
import Navigation from "../layouts/navPage";
// import * as $ from "jquery"; //kiran-4748-removing console warning-no used
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import './advertiserLeadTracking.css';
import Picky from "react-picky";
import "react-picky/dist/picky.css";
// import styled from "styled-components"; //kiran-4748-removing console warning-no used
// import Loader from "../Reports/report_Loader";
import { css } from "emotion";
import { Table,Checkbox } from 'antd';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //kiran-task-3867-added default header


// const CheckboxGroup = Checkbox.Group; //kiran-4748-removing console warning-no used
const Swal = require('sweetalert2');



const tableCSS = css({
    backgroundColor: "white",
    fontFamily: "sans-serif",
    borderStyle: "bold",
    "& thead  > tr": {
        backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
        color: "white",
        fontSize: "13px",
        textAlign: "center !important",
        fontWeight: "500",
    },
    "& thead > tr >th": {
        textAlign: "center !important",
        color: "white",
        fontSize: "12px",
 
    },
});
const queryString = require('query-string');
// const PickyStyle = styled(Picky)`
// 	 background-color: #bd141480 !important;
//      .picky {
//         width:148px !important;
//        height:32px !important;
//        overFlowY:hidden !important;
//     }
//  `; //kiran-4748-removing console warning-no used


//  let z=7000;
//  let a=60;
//  if(a==60){
//      z=100*a
//  }
// Sandeep-task-3591-added code for width
let z = 8000;
 let a = 60;
 if (a === 60) { //kiran-4748-removing console warning-changed == to ===
   z = 120 * a
 }


 const  columnsReport = [
 
    {
        title: <span>PID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "pID",
        key: "pID",
        width: "10%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.pID < b.pID) {
                return asc ? -1 : 1;
            } else if (a.pID > b.pID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.pID}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>CampID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "campaignID",
        key: "campID",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.campID < b.campID) {
                return asc ? -1 : 1;
            } else if (a.campID > b.campID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.campID}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Lead Interaction Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "leadInteractionDate",
        key: "leadInteractionDate",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.leadInteractionDate < b.leadInteractionDate) {
                return asc ? -1 : 1;
            } else if (a.leadInteractionDate > b.leadInteractionDate) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.leadInteractionDate}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Email&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "email",
        key: "email",
        width: "30%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.email.toUpperCase() < b.email.toUpperCase()) {
                return asc ? -1 : 1;
            } else if (
                a.email.toUpperCase() > b.email.toUpperCase()
            ) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.email}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>First Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "firstName",
        key: "firstName",
        width: "25%",
        align: "left",
        sorter: (a, b) => a.firstName - b.firstName,
        render: (text, record) => (
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.firstName}
                </span>
                <br />
            </div>

        ),

    }
, 
    {
        title: <span>Last Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "lastName",
        key: "lastName",
        width: "25%",
        align: "left",
        sorter: (a, b) => a.lastName - b.lastName,
        render: (text, record) => (
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.lastName}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Campaign Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "campaignName",
        key: "campaignName",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.campaignName < b.campaignName) {
                return asc ? -1 : 1;
            } else if (a.campaignName > b.campaignName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.campaignName}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>LinkedIn Company Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "linkedInCompanyName",
        key: "linkedInCompanyName",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.linkedInCompanyName < b.linkedInCompanyName) {
                return asc ? -1 : 1;
            } else if (a.linkedInCompanyName > b.linkedInCompanyName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            //kiran-4748-removing console warning-changed == to ===
            record.linkedInCompanyName===""||record.linkedInCompanyName===undefined||record.linkedInCompanyName==null||record.linkedInCompanyName==="undefined"||record.linkedInCompanyName==="null"?
            ""
            :
            <div  id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.linkedInCompanyName}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Job Title&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "jobTitle",
        key: "jobTitle",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.jobTitle < b.jobTitle) {
                return asc ? -1 : 1;
            } else if (a.jobTitle > b.jobTitle) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span id="to_truncate1"style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.jobTitle}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>LinkedIn Job Title&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "linkedInJobTitle",
        key: "linkedInJobTitle",
        width: "30%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.linkedInJobTitle < b.linkedInJobTitle) {
                return asc ? -1 : 1;
            } else if (a.linkedInJobTitle > b.linkedInJobTitle) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            record.linkedInJobTitle===""||record.linkedInJobTitle===undefined||record.linkedInJobTitle===null||record.linkedInJobTitle==="undefined"||record.linkedInJobTitle==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.linkedInJobTitle}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Job Level&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "jobLevel",
        key: "jobLevel",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.jobLevel < b.jobLevel) {
                return asc ? -1 : 1;
            } else if (a.jobLevel > b.jobLevel) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.jobLevel}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Job Function&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "jobFunction",
        key: "jobFunction",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.jobFunction < b.jobFunction) {
                return asc ? -1 : 1;
            } else if (a.jobFunction > b.jobFunction) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.jobFunction}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Address&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "address",
        key: "address",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.address < b.address) {
                return asc ? -1 : 1;
            } else if (a.address > b.address) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.address}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>City&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "city",
        key: "city",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.city < b.city) {
                return asc ? -1 : 1;
            } else if (a.city > b.city) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.city}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>State&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "state",
        key: "state",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.state < b.state) {
                return asc ? -1 : 1;
            } else if (a.state > b.state) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.state}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Country&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "country",
        key: "country",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.country < b.country) {
                return asc ? -1 : 1;
            } else if (a.country > b.country) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.country}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Street&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "street",
        key: "street",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.street < b.street) {
                return asc ? -1 : 1;
            } else if (a.street > b.street) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.street===""||record.street===undefined||record.street==null||record.street==="undefined"||record.street==="null"?
            ""
            :
            <div style={{ width: "75px" }}>
                <span id="to_truncate1"style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.street}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Zip Code&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "zipCode",
        key: "zipCode",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.zipCode < b.zipCode) {
                return asc ? -1 : 1;
            } else if (a.zipCode > b.zipCode) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.zipCode}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Phone No.&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "workPhone",
        key: "workPhone",
        width: "28%",//Sandeep-task-3590-change in width bcz of alignment issue

        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.workPhone < b.workPhone) {
                return asc ? -1 : 1;
            } else if (a.workPhone > b.workPhone) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "120px" }}>   
            {/* Sandeep-task-3590-change in width bcz of alignment issue */}
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.workPhone}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Company Revenue&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "companyRevenue",
        key: "companyRevenue",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.companyRevenue < b.companyRevenue) {
                return asc ? -1 : 1;
            } else if (a.companyRevenue > b.companyRevenue) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.companyRevenue}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Company Employee Size&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "companyEmployeeSize",
        key: "companyEmployeeSize",
        width: "18%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.companyEmployeeSize < b.companyEmployeeSize) {
                return asc ? -1 : 1;
            } else if (a.companyEmployeeSize > b.companyEmployeeSize) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.companyEmployeeSize}
                </span>
                <br />
            </div>
        ),
    },
  
    {
        title: <span>Industry&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "industry",
        key: "industry",
        width: "20%",

        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.industry.toUpperCase() < b.industry.toUpperCase()) {
                return asc ? -1 : 1;
            } else if (
                a.industry.toUpperCase() > b.industry.toUpperCase()
            ) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.industry}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>IP&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "ip",
        key: "ip",
        width: "18%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.ip < b.ip) {
                return asc ? -1 : 1;
            } else if (a.ip > b.ip) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.ip}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Asset ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "supportDocID",
        key: "supportDocID",
        width: "18%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";
            if (a.supportDocID < b.supportDocID) {
                return asc ? -1 : 1;
            } else if (a.supportDocID > b.supportDocID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.supportDocID}
                </span>
                <br />
            </div>
        ),
    }, 
    {
        title: <span>Custom Questions&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "customQuestionAliasName",
        key: "customQuestionAliasName",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.customQuestionAliasName < b.customQuestionAliasName) {
                return asc ? -1 : 1;
            } else if (a.customQuestionAliasName > b.customQuestionAliasName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.customQuestionAliasName}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 1&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra1",
        key: "extra1",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra1 < b.extra1) {
                return asc ? -1 : 1;
            } else if (a.extra1 > b.extra1) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (  
          
            record.extra1===""||record.extra1===undefined||record.extra1==null||record.extra1==="undefined"||record.extra1==="null"?
            ""
            :    
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra1}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 2&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra2",
        key: "extra2",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra2 < b.extra2) {
                return asc ? -1 : 1;
            } else if (a.extra2 > b.extra2) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra2===""||record.extra2===undefined||record.extra2==null||record.extra2==="undefined"||record.extra2==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra2}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 3&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra3",
        key: "extra3",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra3 < b.extra3) {
                return asc ? -1 : 1;
            } else if (a.extra3 > b.extra3) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra3===""||record.extra3===undefined||record.extra3===null||record.extra3==="undefined"||record.extra3==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra3}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 4&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra4",
        key: "extra4",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra4 < b.extra4) {
                return asc ? -1 : 1;
            } else if (a.extra4 > b.extra4) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra4===""||record.extra4===undefined||record.extra4===null||record.extra4==="undefined"||record.extra4==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra4}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 5&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra5",
        key: "extra5",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra5 < b.extra5) {
                return asc ? -1 : 1;
            } else if (a.extra5 > b.extra5) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
                record.extra5===""||record.extra5===undefined||record.extra5==null||record.extra5==="undefined"||record.extra5==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra5}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 6&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra6",
        key: "extra6",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra6 < b.extra6) {
                return asc ? -1 : 1;
            } else if (a.extra6 > b.extra6) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra6===""||record.extra6===undefined||record.extra6==null||record.extra6==="undefined"||record.extra6==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra6}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 7&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra7",
        key: "extra7",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra7 < b.extra7) {
                return asc ? -1 : 1;
            } else if (a.extra7 > b.extra7) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra7===""||record.extra7===undefined||record.extra7==null||record.extra7==="undefined"||record.extra7==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra7}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 8&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra8",
        key: "extra8",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra8 < b.extra8) {
                return asc ? -1 : 1;
            } else if (a.extra8 > b.extra8) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra8===""||record.extra8===undefined||record.extra8==null||record.extra8==="undefined"||record.extra8==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra8}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 9&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra9",
        key: "extra9",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra9 < b.extra9) {
                return asc ? -1 : 1;
            } else if (a.extra9 > b.extra9) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra9===""||record.extra9===undefined||record.extra9==null||record.extra9==="undefined"||record.extra9==="null"?
            ""
            :
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra9}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 10&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra10",
        key: "extra10",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra10 < b.extra10) {
                return asc ? -1 : 1;
            } else if (a.extra10 > b.extra10) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra10===""||record.extra10===undefined||record.extra10==null||record.extra10==="undefined"||record.extra10==="null"?
            ""
            :
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra10}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 11&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra11",
        key: "extra11",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra11 < b.extra11) {
                return asc ? -1 : 1;
            } else if (a.extra11 > b.extra11) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra11===""||record.extra11===undefined||record.extra11==null||record.extra11==="undefined"||record.extra11==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra11}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 12&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra12",
        key: "extra12",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra12 < b.extra12) {
                return asc ? -1 : 1;
            } else if (a.extra12 > b.extra12) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra12===""||record.extra12===undefined||record.extra12==null||record.extra12==="undefined"||record.extra12==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra12}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 13&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra13",
        key: "extra13",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra13 < b.extra13) {
                return asc ? -1 : 1;
            } else if (a.extra13 > b.extra13) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra13===""||record.extra13===undefined||record.extra13===null||record.extra13==="undefined"||record.extra13==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra13}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 14&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra14",
        key: "extra14",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra14 < b.extra14) {
                return asc ? -1 : 1;
            } else if (a.extra14 > b.extra14) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra14===""||record.extra14===undefined||record.extra14==null||record.extra14==="undefined"||record.extra14==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra14}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 15&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra15",
        key: "extra15",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra15 < b.extra15) {
                return asc ? -1 : 1;
            } else if (a.extra15 > b.extra15) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra15===""||record.extra15===undefined||record.extra15==null||record.extra15==="undefined"||record.extra15==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra15}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 16&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra16",
        key: "extra16",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra16 < b.extra16) {
                return asc ? -1 : 1;
            } else if (a.extra16 > b.extra16) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra16===""||record.extra16===undefined||record.extra16==null||record.extra16==="undefined"||record.extra16==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra16}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 17&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra17",
        key: "extra17",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra17 < b.extra17) {
                return asc ? -1 : 1;
            } else if (a.extra17 > b.extra17) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra17===""||record.extra17===undefined||record.extra17==null||record.extra17==="undefined"||record.extra17==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra17}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 18&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra18",
        key: "extra18",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra18 < b.extra18) {
                return asc ? -1 : 1;
            } else if (a.extra18 > b.extra18) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra18===""||record.extra18===undefined||record.extra18==null||record.extra18==="undefined"||record.extra18==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra18}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 19&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra19",
        key: "extra19",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra19 < b.extra19) {
                return asc ? -1 : 1;
            } else if (a.extra19 > b.extra19) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra19===""||record.extra19===undefined||record.extra19==null||record.extra19==="undefined"||record.extra19==="null"?
            ""
            :
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra19}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Extra 20&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "extra20",
        key: "extra20",
        width: "15%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.extra20 < b.extra20) {
                return asc ? -1 : 1;
            } else if (a.extra20 > b.extra20) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.extra20===""||record.extra20===undefined||record.extra20==null||record.extra20==="undefined"||record.extra20==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.extra20}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Reallocation ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "reAllocationID",
        key: "reAllocationID",
        width: "16%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.reAllocationID < b.reAllocationID) {
                return asc ? -1 : 1;
            } else if (a.reAllocationID > b.reAllocationID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.reAllocationID}
                </span>
                <br />
            </div>
        ),
    },
    
    // {
    //     title: <span>Domain&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    //     dataIndex: "domain",
    //     key: "domain",
    //     width: "25%",
    //     align: "left",
    //     sorter: (a, b) => {
    //         const asc = "asc";

    //         if (a.domain < b.domain) {
    //             return asc ? -1 : 1;
    //         } else if (a.domain > b.domain) {
    //             return asc ? 1 : -1;
    //         } else {
    //             return 0;
    //         }
    //     },
    //     render: (text, record) => (
    //         <div id="to_truncate1" style={{ width: "75px" }}>
    //             <span style={{fontSize:'13px'}}>
    //                 {record.domain}
    //             </span>
    //             <br />
    //         </div>
    //     ),
    // },
    {
        title: <span>Alternate Phone No&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "alternatePhoneNo",
        key: "alternatePhoneNo",
        width: "30%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.alternatePhoneNo < b.alternatePhoneNo) {
                return asc ? -1 : 1;
            } else if (a.alternatePhoneNo > b.alternatePhoneNo) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.alternatePhoneNo}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>LinkedIn&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "linkedIn",
        key: "linkedIn",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.linkedIn < b.linkedIn) {
                return asc ? -1 : 1;
            } else if (a.linkedIn > b.linkedIn) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.linkedIn}
                </span>
                <br />
            </div>
        ),
    },
   
 
    {
        title: <span>Comments&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "comments",
        key: "comments",
        width: "35%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.comments < b.comments) {
                return asc ? -1 : 1;
            } else if (a.comments > b.comments) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.comments}
                </span>
                <br />
            </div>
        ),
    },
    // {
    //     title: <span>Parent CampID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    //     dataIndex: "parentCampID",
    //     key: "parentCampID",
    //     width: "13%",
    //     align: "left",
    //     sorter: (a, b) => {
    //         const asc = "asc";

    //         if (a.parentCampID < b.parentCampID) {
    //             return asc ? -1 : 1;
    //         } else if (a.parentCampID > b.parentCampID) {
    //             return asc ? 1 : -1;
    //         } else {
    //             return 0;
    //         }
    //     },
    //     render: (text, record) => (
    //         <div style={{ width: "75px" }}>
    //             <span style={{fontSize:'13px'}}>
    //                 {record.parentCampID}
    //             </span>
    //             <br />
    //         </div>
    //     ),
    // },
    //   {
    //     title: <span>Lead Info ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    //     dataIndex: "leadInfoID",
    //     key: "leadInfoID",
    //     width: "13%",
    //     align: "left",
    //     sorter: (a, b) => {
    //         const asc = "asc";

    //         if (a.leadInfoID < b.leadInfoID) {
    //             return asc ? -1 : 1;
    //         } else if (a.leadInfoID > b.leadInfoID) {
    //             return asc ? 1 : -1;
    //         } else {
    //             return 0;
    //         }
    //     },
    //     render: (text, record) => (
    //         <div style={{ width: "75px" }}>
    //             <span style={{fontSize:'13px'}}>
    //                 {record.leadInfoID}
    //             </span>
    //             <br />
    //         </div>
    //     ),
    // },
  
 
  
  
    //   {
    //     title: <span>Last Updated&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    //     dataIndex: "lastUpdated",
    //     key: "lastUpdated",
    //     width: "22%",
    //     align: "left",
    //     sorter: (a, b) => {
    //         const asc = "asc";

    //         if (a.lastUpdated < b.lastUpdated) {
    //             return asc ? -1 : 1;
    //         } else if (a.lastUpdated > b.lastUpdated) {
    //             return asc ? 1 : -1;
    //         } else {
    //             return 0;
    //         }
    //     },
    //     render: (text, record) => (
    //         <div style={{ width: "75px" }}>
    //             <span style={{fontSize:'13px'}}>
    //                 {record.lastUpdated}
    //             </span>
    //             <br />
    //         </div>
    //     ),
    // },
    {
        title: <span>Company Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "companyName",
        key: "companyName",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.companyName < b.companyName) {
                return asc ? -1 : 1;
            } else if (a.companyName > b.companyName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.companyName}
                </span>
                <br />
            </div>
        ),
    },
    // {
    //     title: <span>Status&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    //     dataIndex: "status",
    //     key: "status",
    //     width: "25%",
    //     align: "left",
    //     sorter: (a, b) => {
    //         const asc = "asc";

    //         if (a.status < b.status) {
    //             return asc ? -1 : 1;
    //         } else if (a.status > b.status) {
    //             return asc ? 1 : -1;
    //         } else {
    //             return 0;
    //         }
    //     },
    //     render: (text, record) => (
    //         <div id="to_truncate1"style={{ width: "75px" }}>
    //             <span style={{fontSize:'13px'}}>
    //                 {record.statusToDisplay}
    //             </span>
    //             <br />
    //         </div>
    //     ),
    // },
    {
        title: <span>Asset Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "assetName",
        key: "assetName",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.assetName < b.assetName) {
                return asc ? -1 : 1;
            } else if (a.assetName > b.assetName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.assetName}
                </span>
                <br />
            </div>
        ),
    },
  
    // {
    //     title: <span>Voice Log Link&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    //     dataIndex: "voiceLogLink",
    //     key: "voiceLogLink",
    //     width: "25%",
    //     align: "left",
    //     sorter: (a, b) => {
    //         const asc = "asc";

    //         if (a.voiceLogLink < b.voiceLogLink) {
    //             return asc ? -1 : 1;
    //         } else if (a.voiceLogLink > b.voiceLogLink) {
    //             return asc ? 1 : -1;
    //         } else {
    //             return 0;
    //         }
    //     },
    //     render: (text, record) => (
    //         <div id="to_truncate1"style={{ width: "75px" }}>
    //             <span style={{fontSize:'13px'}}>
    //                 {record.voiceLogLink}
    //             </span>
    //             <br />
    //         </div>
    //     ),
    // },
    // {
    //     title: <span>Channel&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
    //     dataIndex: "channel",
    //     key: "channel",
    //     width: "25%",
    //     align: "left",
    //     sorter: (a, b) => {
    //         const asc = "asc";

    //         if (a.channel < b.channel) {
    //             return asc ? -1 : 1;
    //         } else if (a.channel > b.channel) {
    //             return asc ? 1 : -1;
    //         } else {
    //             return 0;
    //         }
    //     },
    //     render: (text, record) => (
    //         <div id="to_truncate1"style={{ width: "75px" }}>
    //             <span style={{fontSize:'13px'}}>
    //                 {record.channel}
    //             </span>
    //             <br />
    //         </div>
    //     ),
    // },
  
    {
                title: <span>Asset Name Touch1&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "assetNameTouch1",
                key: "assetNameTouch1",
                width: "28%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";
        
                    if (a.assetNameTouch1 < b.assetNameTouch1) {
                        return asc ? -1 : 1;
                    } else if (a.assetNameTouch1 > b.assetNameTouch1) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div id="to_truncate1" style={{ width: "75px" }}>
                        <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                            {record.assetNameTouch1}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Asset Name Timestamp Touch1&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "assetTimestampTouch1",
                key: "assetTimestampTouch1",
                width: "27%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";
        
                    if (a.assetTimestampTouch1 < b.assetTimestampTouch1) {
                        return asc ? -1 : 1;
                    } else if (a.assetTimestampTouch1 > b.assetTimestampTouch1) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                            {record.assetTimestampTouch1}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Asset Name Touch2&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "assetNameTouch2",
                key: "assetNameTouch2",
                width: "28%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";
        
                    if (a.assetNameTouch2 < b.assetNameTouch2) {
                        return asc ? -1 : 1;
                    } else if (a.assetNameTouch2 > b.assetNameTouch2) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div id="to_truncate1" style={{ width: "75px" }}>
                        <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                            {record.assetNameTouch2}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Asset Name Timestamp Touch2&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "assetTimestampTouch2",
                key: "assetTimestampTouch2",
                width: "27%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";
        
                    if (a.assetTimestampTouch2 < b.assetTimestampTouch2) {
                        return asc ? -1 : 1;
                    } else if (a.assetTimestampTouch2 > b.assetTimestampTouch2) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                            {record.assetTimestampTouch2}
                        </span>
                        <br />
                    </div>
                ),
            },
          
            {
                title: <span>Asset Name Touch3&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "assetNameTouch3",
                key: "assetNameTouch3",
                width: "28%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";
        
                    if (a.assetNameTouch3 < b.assetNameTouch3) {
                        return asc ? -1 : 1;
                    } else if (a.assetNameTouch3 > b.assetNameTouch3) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div id="to_truncate1" style={{ width: "75px" }}>
                        <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                            {record.assetNameTouch3}
                        </span>
                        <br />
                    </div>
                ),
            },
          
           
            {
                title: <span>Asset Name Timestamp Touch3&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "assetTimestampTouch3",
                key: "assetTimestampTouch3",
                width: "27%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";
        
                    if (a.assetTimestampTouch3 < b.assetTimestampTouch3) {
                        return asc ? -1 : 1;
                    } else if (a.assetTimestampTouch3 > b.assetTimestampTouch3) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                            {record.assetTimestampTouch3}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Status&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "status",
                key: "status",
                width: "27%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";
        
                    if (a.status < b.status) {
                        return asc ? -1 : 1;
                    } else if (a.status > b.status) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                            {record.status}
                            {/* Sandeep-task-3591-added code for status issue */}
                        </span>
                        <br />
                    </div>
                ),
            },
]

const  columnsReportForblank= [
 
    {
        title: <span>PID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "pID",
        key: "pID",
        width: "10%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.pID < b.pID) {
                return asc ? -1 : 1;
            } else if (a.pID > b.pID) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.pID}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Lead Interaction Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "leadInteractionDate",
        key: "leadInteractionDate",
        width: "30%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.leadInteractionDate < b.leadInteractionDate) {
                return asc ? -1 : 1;
            } else if (a.leadInteractionDate > b.leadInteractionDate) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.leadInteractionDate}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Email&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "email",
        key: "email",
        width: "30%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.email.toUpperCase() < b.email.toUpperCase()) {
                return asc ? -1 : 1;
            } else if (
                a.email.toUpperCase() > b.email.toUpperCase()
            ) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.email}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>First Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "firstName",
        key: "firstName",
        width: "25%",
        align: "left",
        sorter: (a, b) => a.firstName - b.firstName,
        render: (text, record) => (
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.firstName}
                </span>
                <br />
            </div>

        ),

    },
    {
        title: <span>Last Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "lastName",
        key: "lastName",
        width: "25%",
        align: "left",
        sorter: (a, b) => a.lastName - b.lastName,
        render: (text, record) => (
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.lastName}
                </span>
                <br />
            </div>
        ),
    },  
    {
        title: <span>LinkedIn Company Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "linkedInCompanyName",
        key: "linkedInCompanyName",
        width: "30%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.linkedInCompanyName < b.linkedInCompanyName) {
                return asc ? -1 : 1;
            } else if (a.linkedInCompanyName > b.linkedInCompanyName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.linkedInCompanyName===""||record.linkedInCompanyName===undefined||record.linkedInCompanyName==null||record.linkedInCompanyName==="undefined"||record.linkedInCompanyName==="null"?
            ""
            :
            <div  id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.linkedInCompanyName}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Job Title&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "jobTitle",
        key: "jobTitle",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.jobTitle < b.jobTitle) {
                return asc ? -1 : 1;
            } else if (a.jobTitle > b.jobTitle) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span id="to_truncate1"style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.jobTitle}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>LinkedIn Job Title&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "linkedInJobTitle",
        key: "linkedInJobTitle",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.linkedInJobTitle < b.linkedInJobTitle) {
                return asc ? -1 : 1;
            } else if (a.linkedInJobTitle > b.linkedInJobTitle) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        //kiran-4748-removing console warning-changed == to ===
        render: (text, record) => (
            record.linkedInJobTitle===""||record.linkedInJobTitle===undefined||record.linkedInJobTitle==null||record.linkedInJobTitle==="undefined"||record.linkedInJobTitle==="null"?
            ""
            :
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.linkedInJobTitle}
                </span>
                <br />
            </div>
        ),
    },
   
    {
        title: <span>Address&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "address",
        key: "address",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.address < b.address) {
                return asc ? -1 : 1;
            } else if (a.address > b.address) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1"style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.address}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>City&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "city",
        key: "city",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.city < b.city) {
                return asc ? -1 : 1;
            } else if (a.city > b.city) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.city}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>State&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "state",
        key: "state",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.state < b.state) {
                return asc ? -1 : 1;
            } else if (a.state > b.state) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.state}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Country&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "country",
        key: "country",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.country < b.country) {
                return asc ? -1 : 1;
            } else if (a.country > b.country) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.country}
                </span>
                <br />
            </div>
        ),
    },
  
    {
        title: <span>Zip Code&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "zipCode",
        key: "zipCode",
        width: "20%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.zipCode < b.zipCode) {
                return asc ? -1 : 1;
            } else if (a.zipCode > b.zipCode) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.zipCode}
                </span>
                <br />
            </div>
        ),
    },
    {
        title: <span>Phone No.&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "workPhone",
        key: "workPhone",
        width: "25%",

        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.workPhone < b.workPhone) {
                return asc ? -1 : 1;
            } else if (a.workPhone > b.workPhone) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.workPhone}
                </span>
                <br />
            </div>
        ),
    },
  
    {
        title: <span>Company Employee Size&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "companyEmployeeSize",
        key: "companyEmployeeSize",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.companyEmployeeSize < b.companyEmployeeSize) {
                return asc ? -1 : 1;
            } else if (a.companyEmployeeSize > b.companyEmployeeSize) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.companyEmployeeSize}
                </span>
                <br />
            </div>
        ),
    },
  
    {
        title: <span>Industry&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "industry",
        key: "industry",
        width: "25%",

        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.industry.toUpperCase() < b.industry.toUpperCase()) {
                return asc ? -1 : 1;
            } else if (
                a.industry.toUpperCase() > b.industry.toUpperCase()
            ) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1">
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.industry}
                </span>
                <br />
            </div>
        ),
    },
  
    {
        title: <span>Company Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "companyName",
        key: "companyName",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.companyName < b.companyName) {
                return asc ? -1 : 1;
            } else if (a.companyName > b.companyName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.companyName}
                </span>
                <br />
            </div>
        ),
    },
      {
        title: <span>Asset Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: "assetName",
        key: "assetName",
        width: "25%",
        align: "left",
        sorter: (a, b) => {
            const asc = "asc";

            if (a.assetName < b.assetName) {
                return asc ? -1 : 1;
            } else if (a.assetName > b.assetName) {
                return asc ? 1 : -1;
            } else {
                return 0;
            }
        },
        render: (text, record) => (
            <div id="to_truncate1" style={{ width: "75px" }}>
                <span style={{fontSize:'13px',color: '#4F4F4F'}}>
                    {record.assetName}
                </span>
                <br />
            </div>
        ),
    }
  
]





 
const leadStatusOptions =[
    { id: "Accepted", name: "Accepted" },
	{ id: "Rejected", name: "Rejected" },
	{ id: "In QA", name: "In QA" },
];
 
const CampaignStatusOptions =[
    { id: "Active", name: "Active" },
	//  { id: "Paused", name: "Paused" },
	{ id: "Completed", name: "Completed" },
];
 

const checkBoxArray = [
    { id: 'campaignID', name: 'campaignID', value: 'No' },
    { id: 'pID', name: 'pID', value: 'No' },
    { id: 'CampaignName', name: 'campaignName', value: 'No' },
    { id: 'Lead Interaction Day', name: 'leadInteractionDate', value: 'No' },
    { id: 'Email', name: 'email', value: 'No' },
    { id: 'Work Phone', name: 'workPhone', value: 'No' },
     { id: 'Company Name', name: 'companyName', value: 'No' },
    { id: 'Industry', name: 'industry', value: 'No' },
    { id: 'Company revenue', name: 'companyRevenue', value: 'No' },
    { id: 'LinkedIn Job Title', name: 'linkedInJobTitle', value: 'No' },
    { id: 'Job Level', name: 'jobLevel', value: 'No' },
    { id: 'Country', name: 'country', value: 'No' },
    { id: 'Address', name: 'address', value: 'No' }, 
    { id: 'City', name: 'city', value: 'No' },
    { id: 'IP', name: 'ip', value: 'No' },
    { id: 'Comments', name: 'comments', value: 'No' },
    //{ id: 'Campaign ID', name: 'campID', value: 'No' },
    { id: 'Allocation ID', name: 'reAllocationID', value: 'No' },
    { id: 'First Name', name: 'firstName', value: 'No' },
    { id: 'Last Name', name: 'lastName', value: 'No' },
    { id: 'Alternate Phone No', name: 'alternatePhoneNo', value: 'No' },
    { id: 'LinkedIn Company Name', name: 'linkedInCompanyName', value: 'No' },
    { id: 'Company Employee Size', name: 'companyEmployeeSize', value: 'No' },
    { id: 'Job Title', name: 'jobTitle', value: 'No' },
    { id: 'LinkedIn', name: 'linkedIn', value: 'No' },
    { id: 'Job Function', name: 'jobFunction', value: 'No' },
    { id: 'State', name: 'state', value: 'No' },
    { id: 'Zip Code', name: 'zipCode', value: 'No' },
    // { id: 'Voice Log Link', name: 'voiceLogLink', value: 'No' },
    { id: 'Asset ID', name: 'supportDocID', value: 'No' }, 
    { id: 'Asset Name', name: 'assetName', value: 'No' }, 
    { id: 'Street', name: 'street', value: 'No' },
    { id: 'Custom Questions', name: 'Custom Question', value: 'No' },
    { id: 'Touch1', name: 'assetNameTouch1', value: 'No' },  
    { id: 'Touch2', name: 'assetNameTouch2', value: 'No' },  
    { id: 'Touch3', name: 'assetNameTouch3', value: 'No' },  
    { id: 'Extra1', name: 'extra1', value: 'No' },
    { id: 'Extra2', name: 'extra2', value: 'No' },
    { id: 'Extra3', name: 'extra3', value: 'No' },
    { id: 'Extra4', name: 'extra4', value: 'No' },
    { id: 'Extra5', name: 'extra5', value: 'No' },
    { id: 'Extra6', name: 'extra6', value: 'No' },
    { id: 'Extra7', name: 'extra7', value: 'No' },
    { id: 'Extra8', name: 'extra8', value: 'No' },
    { id: 'Extra9', name: 'extra9', value: 'No' },
    { id: 'Extra10', name: 'extra10', value: 'No' },
    { id: 'Extra11', name: 'extra11', value: 'No' },
    { id: 'Extra12', name: 'extra12', value: 'No' },
    { id: 'Extra13', name: 'extra13', value: 'No' },
    { id: 'Extra14', name: 'extra14', value: 'No' },
    { id: 'Extra15', name: 'extra15', value: 'No' },
    { id: 'Extra16', name: 'extra16', value: 'No' },
    { id: 'Extra17', name: 'extra17', value: 'No' },
    { id: 'Extra18', name: 'extra18', value: 'No' },
    { id: 'Extra19', name: 'extra19', value: 'No' },
    { id: 'Extra20', name: 'extra20', value: 'No' }, 

];

//kiran-4748-removing console warning-no used
//  const leadPlain = checkBoxArray.map(function (a) { return a.name });


/**
* @author : Sandeep Dhawale-task-3479
* @description : created class component named as advertiserLeadTracking.
*/
class advertiserLeadTracking extends React.Component {
    constructor() {
        super();
        this.state = {
            tableState: { size: "small" }, //Sandeep-3479-addede code store tableState
            agencyMyReportsDataNew1: [],//Sandeep-3479-dded code to store agencyMyReportsDataNew1
            agencyMyReportsData: [],//Sandeep-3479-added code to store agencyMyReportsData
            reportSummary: [],//Sandeep-3479-added code to store reportSummary data
            agencyList: [],//Sandeep-3479-added code to store agencyList data
            agencyList1: [],//Sandeep-3479-added code to store agencyList1 data
            agencyID: "",//Sandeep-3479-added code to store agencyID
            agencyID1: "Select Agency",//Sandeep-3479-added code to store agencyName
            campaignStatus: [],//Sandeep-3479-added code to store campaignStatus data
            timeline: "Select Timeline",//Sandeep-3479-added code to store timeline data
            label: "", //Sandeep-3479-added code to store  label
            reportKey: "",//Sandeep-3479-added code to store  reportKey

            agencyName: "", //Sandeep-3479-added code to store  agencyName
            reportType: "Agency Lead Tracking",//Chaitanya-4181Ref-(4099)-Advertiser-My report-report type not showing on listing page
            pageSize: 100,//Sandeep-3479-added code to store  pageSize
            reportName: "",   //Sandeep-3479-added code to store  reportName     
            generateBtnFlag: false,//Sandeep-3479-added code to store  generateBtnFlag
            token: "",//Sandeep-3479-added code to store  token
            loader: "",//Sandeep-3479-added code to store loader
            loader1: "",//Sandeep-3479-added code to store  loader1

            campaignLoader:"",//Sandeep-3479-added code to store  campaignLoader
            leadIndeterminate: true,//Sandeep-3479-added code to store  leadIndeterminate
            leadCheckAll:false,//Sandeep-3479-added code to store  leadCheckAll
            userType: "Advertiser",//Sandeep-3479-added code to store  reportKey
            CampaignDetail:[],//Sandeep-3479-added code to store  CampaignDetail
            CampaignLeadDetail:[],//Sandeep-3479-added code to store  CampaignLeadDetail
            campID:"Select Campaign",//Sandeep-3479-added code to store  campID
            leadStatus:[],//Sandeep-3479-added code to store  leadStatus
            campaignName:"",//Sandeep-3479-added code to store  campaignName
            campaignStatusArray:[],//Sandeep-3479-added code to store  campaignStatusArray
            campaignLeadStatusArray:[],//Sandeep-3479-added code to store  campaignLeadStatusArray
           deliveryFormat:[],//Sandeep-3479-added code to store  deliveryFormat
      
        dataTableColumns:columnsReport,//Sandeep-3479-added code to store dataTableColumns
        checkedColumnKeys:[],//Sandeep-3479-added code to store checkedColumnKeys
        drpdisabled_report: "",//Sandeep-3479-added code to store drpdisabled_report
        customQuestionCount:0,//Sandeep-3479-added code to store customQuestionCount
        checkBoxObjArray:checkBoxArray,//Sandeep-3479-store checkboxarray into checkBoxObjArray
        multiTouch:"No",//Sandeep-3479-added code for multitouch 
        }
        /**
* @author : Sandeep Dhawale-task-3479
* @description : all handle change are defined
*/
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.onAgencyNameHandleChange = this.onAgencyNameHandleChange.bind(this);
        this.onCampaignStatusHandleChange = this.onCampaignStatusHandleChange.bind(this);
        this.ondeadlineHandleChange = this.ondeadlineHandleChange.bind(this);
        this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
        this.generateHandleChange = this.generateHandleChange.bind(this);
        this.onCampaignDetailsHandleChange = this.onCampaignDetailsHandleChange.bind(this);
        this.onLeadStatusHandleChange = this.onLeadStatusHandleChange.bind(this);
        this.onCampaignHandleChange = this.onCampaignHandleChange.bind(this);
        this.onCampaignLeadDetailsHandleChange = this.onCampaignLeadDetailsHandleChange.bind(this);
 
    }

 
    leadOnChange = checkedColumnKeys  => {
     if(checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch1")){
        checkedColumnKeys.push("assetTimestampTouch1");
     }else{
        let index = checkedColumnKeys.indexOf("assetTimestampTouch1");
           if (index > -1) {
            checkedColumnKeys.splice(index, 1);
        }
     }

     if(checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch2")){
        checkedColumnKeys.push("assetTimestampTouch2");
     }else{
        let index = checkedColumnKeys.indexOf("assetTimestampTouch2");
           if (index > -1) {
            checkedColumnKeys.splice(index, 1);
        }
     }

     if(checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch3")){
        checkedColumnKeys.push("assetTimestampTouch3");
     }else{
        let index = checkedColumnKeys.indexOf("assetTimestampTouch3");
           if (index > -1) {
            checkedColumnKeys.splice(index, 1);
        }
     }
        this.setState({
            checkedColumnKeys,
           leadIndeterminate: !!checkedColumnKeys.length &&checkedColumnKeys.length < this.state.checkBoxObjArray.length,
           leadCheckAll:checkedColumnKeys.length === this.state.checkBoxObjArray.length,
        });
    };

    /**
* @author : Sandeep Dhawale-task-3479
* @description : handlechange for pageSize .
*/
    handleChangePageSize(e) {
        var pageSize = e.target.value;
        this.setState({ pageSize: pageSize });
    }

    /**
* @author : Sandeep Dhawale-task-3479
* @description : handlechange for backbutton to navigate on same page from where user came .
*/
    backButtonHandleChange(e) {
        e.preventDefault(); 
        if (this.state.reportKey !== "report") {
            Swal.fire({
                title: 'Are you sure you want to discard this report?',
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                allowOutsideClick: false,
                confirmButtonText: "Discard",
            }).then((result) => {
                if (result.value) {
                    window.location.href = "/advertiserMyReports";
                }
                else if (result.dismiss === "close") {
                    window.location.href = "#";
                }
            })
        }
        else {
            window.location.href = "/advertiserMyReports";
        }


    }
    /**
* @author : Sandeep Dhawale-task-3479
* @description : handlechange to load data after selecting all three filters agency, campaign Status and timeline .
*/
   
    onLeadStatusHandleChange(leadStatus){
        // let CampaignLeadDetail=[...this.state.CampaignLeadDetail]
      
        let campaignLeadStatusArray =[];
        let checkAccepted = leadStatus.some(o=>o.name==="Accepted")
        if(checkAccepted){campaignLeadStatusArray.push("Accepted")}


        let checkRejected = leadStatus.some(o=>o.name==="Rejected")
        if(checkRejected){campaignLeadStatusArray.push("Rejected")}
    
        
        let checkInQA = leadStatus.some(o=>o.name==="In QA")
        if(checkInQA){campaignLeadStatusArray.push("In QA")}

        let agencyID1 = this.state.agencyID1;
        var campID = this.state.campID;
        let timeline = this.state.timeline;
        // let campaignStatusArray = this.state.campaignStatusArray;
        this.setState({ leadStatus,campaignLeadStatusArray})
      
      
        if ((agencyID1 && agencyID1 !== "Select Agency")&&(campID && campID !== "Select Campaign") &&(timeline && timeline !== "Select Timeline")&& (campaignLeadStatusArray && campaignLeadStatusArray.length>0)) {
        this.onCampaignLeadDetailsHandleChange(agencyID1,campaignLeadStatusArray,campID,timeline);
        
        }
        else{
                this.setState({ generateBtnFlag: false,CampaignLeadDetail:[]})
    
           
        }

      
    }

    setDataTableColumn(checkedColumnKeys,customQuestionCount,multiTouch){
     checkedColumnKeys.push("status")
        let dataTableColumns=[...columnsReport]
        let checkBoxObjArray=[...this.state.checkBoxObjArray]
        if(customQuestionCount>0 && (checkedColumnKeys && checkedColumnKeys.includes("Custom Question"))){

            let cqArray=[];
           
                for (let i = 1; i <= customQuestionCount; i++) {
                    let CQkey=`CQ-${i}`,ansKey=`CQ-${i}-ANS`;
                    let Q_obj = {
                        title: <span>{CQkey}&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: CQkey,
                        key: CQkey,
                        width: "20%",
                        align: "left",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a[CQkey] < b[CQkey]) {
                                return asc ? -1 : 1;
                            } else if (a[CQkey] > b[CQkey]) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        }
                    }
                    let ans_obj = {
                        title: <span>{ansKey}&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: ansKey,
                        key: ansKey,
                        width: "20%",
                        align: "left",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a[ansKey] < b[ansKey]) {
                                return asc ? -1 : 1;
                            } else if (a[ansKey] > b[ansKey]) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        }
                    }
                    cqArray.push(Q_obj);
                    cqArray.push(ans_obj);
                    // if(this.state.reportKey!=="report"){
                    checkedColumnKeys.push(CQkey);
                    checkedColumnKeys.push(ansKey);
               
                
                }//End of CQ for loop
                checkedColumnKeys=[...new Set(checkedColumnKeys)];
                dataTableColumns=dataTableColumns.concat(cqArray);
        }else{
            if(customQuestionCount===0){
                checkBoxObjArray=checkBoxObjArray.filter(a=>a.id!=="Custom Question")
            }
            
            let keys=[];
            for (let i = 1; i <= customQuestionCount; i++) {
                let CQkey=`CQ-${i}`,ansKey=`CQ-${i}-ANS`;
                keys.push(CQkey);
                keys.push(ansKey);
            }
            dataTableColumns=dataTableColumns.filter((obj)=>{
                return keys && ! keys.includes(obj.key)
            })
        }
        if(multiTouch==="Yes"){
            checkBoxObjArray=checkBoxObjArray.filter(a=>a.name!=="assetName")
        }
        dataTableColumns=dataTableColumns.filter((obj)=>{
                return checkedColumnKeys && checkedColumnKeys.includes(obj.key)
        })
       
        checkedColumnKeys=[...new Set(checkedColumnKeys)]
        this.setState({dataTableColumns,checkBoxObjArray,checkedColumnKeys})
    
    }



    onCampaignLeadDetailsHandleChange(agencyID1,campaignLeadStatusArray,campID,timeline){
       
        let reportSummary = [{ "agencyID1": agencyID1, "agencyName": "","deliveryLabel":"", "totalAllocation": 0, "totalAccepted": 0, "deliveredLead": 0, "rejectedLead": 0, "qaReviewLead": 0, "balanceLead": 0 }]
        this.setState({ reportSummary, CampaignLeadDetail: [],loader:'lead_loader',generateBtnFlag:false})
        const { user } = this.props.auth;
        let advertiserID = user.id;
        let campaignStatusArray=this.state.campaignStatusArray;
        this.setState({advertiserID})
    if(this.state.reportKey==="report"){
    if ((agencyID1 && agencyID1 !== "Select Agency") && (campaignLeadStatusArray && campaignLeadStatusArray.length>0)&&(campID && campID !== "Select Campaign")&&(timeline && timeline !== "Select Timeline")) 
     {
      
        let data = {
            // advertiserID: advertiserID,
            agencyID: this.state.agencyID,
            campID:campID,
            timeLine:this.state.timeline,
            leadStatus:campaignLeadStatusArray,
            campaignStatus:this.state.campaignStatusArray,
        }
        fetch("/advertiserReportEngine/leadDetailsAgencyWise", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                // authorization: this.state.token,
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(advLeadDetails => { 
                let deliveryFormat=advLeadDetails.deliveryFormat;
                let checkedColumnKeys=[],multiTouch="No";
                if(deliveryFormat.length>0){multiTouch=deliveryFormat[0].multiTouch};
                for (const [key, value] of Object.entries(deliveryFormat[0]))
                 {
                    if(value==="Yes" || value==="yes"){
                        checkedColumnKeys.push(key)
                    }
                  }  
                  let customQuestionCount=advLeadDetails.customQuestionCount;
                  if(customQuestionCount>0){
                    checkedColumnKeys.push("Custom Question");
                  }
                  this.setDataTableColumn(checkedColumnKeys,customQuestionCount,multiTouch)
                this.setState({CampaignLeadDetail:advLeadDetails.result,reportSummary:advLeadDetails.countDetails,deliveryFormat:advLeadDetails.deliveryFormat,loader:"",checkedColumnKeys,customQuestionCount,multiTouch})
                if(this.state.CampaignLeadDetail.length>0){
                    this.setState({generateBtnFlag:true})
                }
                else{
                    this.setState({generateBtnFlag:false})

                }
     
            }).catch(function (err) { console.log(err) });
    }
}
    else
     if ((agencyID1 && agencyID1 !== "Select Agency") && (campaignLeadStatusArray && campaignLeadStatusArray.length>0)&&(campID && campID !== "Select Campaign")&&(timeline && timeline !== "Select Timeline") && (campaignStatusArray && campaignStatusArray.length>0)) 
     {
      
        let data = {
            // advertiserID: advertiserID,
            agencyID: this.state.agencyID1,//Sandeep-task-3591-added code for agencyID issue
            campID:campID,


            timeLine:this.state.timeline,
            leadStatus:campaignLeadStatusArray,
            campaignStatus:this.state.campaignStatusArray,
        }
        fetch("/advertiserReportEngine/leadDetailsAgencyWise", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                // authorization: this.state.token,
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(advLeadDetails => { 
                this.setState({loader:""})//Sandeep-task-3591-added code for loader issue
                let deliveryFormat=advLeadDetails.deliveryDetails;
                let checkedColumnKeys=[],multiTouch="No";
                if(deliveryFormat.length>0){multiTouch=deliveryFormat[0].multiTouch};
                for (const [key, value] of Object.entries(deliveryFormat[0]))
                 {
                    if(value==="Yes" || value==="yes"){
                        checkedColumnKeys.push(key)
                    }
                  }  
                  let customQuestionCount=advLeadDetails.customQuestionCount;
                 
                  if(customQuestionCount>0){
                    checkedColumnKeys.push("Custom Question");
                  }
                  this.setDataTableColumn(checkedColumnKeys,customQuestionCount,multiTouch)
                  
                this.setState({CampaignLeadDetail:advLeadDetails.result,reportSummary:advLeadDetails.countDetails,deliveryFormat:advLeadDetails.deliveryDetails,loader:"",checkedColumnKeys,customQuestionCount,multiTouch})
              
               
                if(this.state.CampaignLeadDetail.length>0){
                    this.setState({generateBtnFlag:true})
                }
                else{
                    this.setState({generateBtnFlag:false})

                }
     
            }).catch(function (err) { console.log(err) });
    }

    }
    onCampaignHandleChange(e){
       var campID = e.target.value;      
        this.setState({campID})
        let  agencyID1 = this.state.agencyID1;
        let campaignLeadStatusArray=this.state.campaignLeadStatusArray;
        
         //kiran-4748-added toString() and changed == to ===
        let checkName = this.state.CampaignDetail.filter(obj => obj.campID.toString()=== campID)
        let campaignName = "";
        if (checkName && checkName.length > 0) {
            campaignName = checkName[0].campaignName
        }
        this.setState({ campaignName })


        let timeline=this.state.timeline;
      
        if ((agencyID1 && agencyID1 !== "Select Agency")&&(campID && campID !== "Select Campaign") &&(timeline && timeline !== "Select Timeline")&& (campaignLeadStatusArray && campaignLeadStatusArray.length>0)) {
        this.onCampaignLeadDetailsHandleChange(agencyID1,campaignLeadStatusArray,campID,timeline);       
        }
        else{       
                this.setState({ generateBtnFlag: false,CampaignLeadDetail:[]})  
        }

    }

    onCampaignDetailsHandleChange(agencyID1,campaignStatusArray){
        const { user } = this.props.auth;
        let advertiserID = user.id;
        let timeline=this.state.timeline;
        let agencyID=this.state.agencyID1;//Sandeep-task-3591-added code for agencyID issue
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let campID = this.state.campID;
        this.setState({ advertiserID ,campID:""})//Sandeep-task-3591-added code for campID issue
        if ((agencyID1 && agencyID1 !== "Select Agency") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campID && campID !== "Select Campaign") && (timeline && timeline !== "Select Timeline")) {

            this.setState({ campaignLoader: "" })////Sandeep-task-3591-added code for Loader issue
        }
        let reportSummary = [{ "agencyID": agencyID, "agencyName": "", "deliveryLabel":"", "totalAllocation": 0, "totalAccepted": 0, "deliveredLead": 0, "rejectedLead": 0, "qaReviewLead": 0, "balanceLead": 0 }]
        this.setState({reportSummary, CampaignLeadDetail: [],campaignLoader:"campaignLoader"})    
        if ((agencyID1 && agencyID1 !== "Select Agency") && (campaignStatusArray && campaignStatusArray.length>0)) {
        let data = {
            // advertiserID: advertiserID,
            agencyID: agencyID1,
            campaignStatus:campaignStatusArray,
        }
        fetch("/advertiserReportEngine/getCampaignListByADV", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                // authorization: this.state.token,
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(CampaignDetail => {
                this.setState({CampaignDetail,campaignLoader:""})//
            }).catch(function (err) { console.log(err) });
    }
}
    /**
* @author : Sandeep Dhawale-task-3479
* @description : handlechange to target selected agency.
*/
    onAgencyNameHandleChange(e) {
        var agencyID1 = e.target.value;
        this.setState({ agencyID1 })
        let campaignStatusArray = this.state.campaignStatusArray;
       
       if ((agencyID1 && agencyID1 !== "Select Agency") && (campaignStatusArray && campaignStatusArray.length>0)) {
     
            this.onCampaignDetailsHandleChange(agencyID1, campaignStatusArray);
        }
        else{
        this.setState({ generateBtnFlag: false,CampaignLeadDetail:[]})
     
    }
 
}

    /**
* @author : Sandeep Dhawale-task-3479
* @description : handlechange to target selected status .
*/
    onCampaignStatusHandleChange(campaignStatus) {
        let campaignStatusArray =[];
        let checkActive = campaignStatus.some(o=>o.name==="Active")
        if(checkActive){campaignStatusArray.push("Active")}

        let checkPaused = campaignStatus.some(o=>o.name==="Paused")
        if(checkPaused){campaignStatusArray.push("Paused")}
      
        let checkCompleted = campaignStatus.some(o=>o.name==="Completed")
        if(checkCompleted){campaignStatusArray.push("Completed")}
        let agencyID1 = this.state.agencyID1;
        //  let campID =this.state.campID;
        this.setState({ campaignStatus,campaignStatusArray})
        if (campaignStatusArray.length===0 || campaignStatusArray == []){ // kiran-4748-not resolved giving issue 
            this.setState({campID:"Select Campaign",generateBtnFlag: false,CampaignLeadDetail:[]})
         }
        else  if ((agencyID1 && agencyID1 !== "Select Agency") && (campaignStatusArray && campaignStatusArray.length>0)) {
    
            this.onCampaignDetailsHandleChange(agencyID1, campaignStatusArray);
        }
        else{
        this.setState({ generateBtnFlag: false,CampaignLeadDetail:[]})
     
    }

    }
    /**
* @author : Sandeep Dhawale-task-3479
* @description : handlechange to target selected deadline .
*/
    ondeadlineHandleChange(e) {

        var timeline = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
          //kiran-4748-added toString() and changed == to === , use setState
        if (timeline.toString() === "All") {
            
            this.setState({label:"All"}); // this.state.label = "All"
        }
        else if (timeline.toString() === "Weekly") {
            this.setState({label:"Ending This Week"});  // this.state.label = "Ending This Week"
        }
        else if (timeline.toString() === "Monthly") {
            this.setState({label:"Ending This Month"});//this.state.label = "Ending This Month"
        }
        else if (timeline.toString() === "Quarterly") {
            this.setState({label:"Ending This Quarter"}); // this.state.label = "Ending This Quarter"
        }
        else if (timeline.toString() === "Yearly") {
            this.setState({label:"Ending This Year"});//this.state.label = "Ending This Year"
        }
        else if (timeline.toString() === "PreMonth") {
            this.setState({label:"Ending Previous Month"});// this.state.label = "Ending Previous Month"
        }
        else if (timeline.toString() === "PreQuarter") {
            this.setState({label:"Ending Previous Quarter"}); //this.state.label = "Ending Previous Quarter"
        }
        else if (timeline.toString() === "PreYear") {
            this.setState({label:"Ending Previous Year"}); //this.state.label = "Ending Previous Year"
        }
        //this.setState({ timeline, label: e.nativeEvent.target[index].text, label: this.state.label})
       this.setState({ timeline, label:label})

        let  agencyID1 = this.state.agencyID1;
        let campaignLeadStatusArray=this.state.campaignLeadStatusArray;
        // let campaignStatusArray=this.state.campaignStatusArray;
        

        let campID =this.state.campID;

        if ((agencyID1 && agencyID1 !== "Select Agency")&&(campID && campID !== "Select Campaign") &&(timeline && timeline !== "Select Timeline")&& (campaignLeadStatusArray && campaignLeadStatusArray.length>0)) {
           
        this.onCampaignLeadDetailsHandleChange(agencyID1,campaignLeadStatusArray,campID,timeline);
        
        }
        else{
       
            this.setState({ generateBtnFlag: false,CampaignLeadDetail:[]})

       
    }

     
    }
    /**
* @author : Sandeep Dhawale-task-3479
* @description : handlechange to generate report successfully .
*/
    generateHandleChange(e) {
        // const { user } = this.props.auth;
        // let advertiserID = user.id; //kiran-4748-removing console warning-no used
        // var pID =e.target.value;
        let agencyID1 = this.state.agencyID1;
        let timeline = this.state.timeline;
       
        let campID = this.state.campID;
        let campaignStatus = [...this.state.campaignStatusArray];
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let reportDetail = [...this.state.CampaignLeadDetail]
        let reportSummary = [...this.state.reportSummary]
      
        let reportName = this.state.reportName;
        let type = this.state.reportType;           //Chaitanya-4181Ref-(4099)-Advertiser-My report-report type not showing on listing page
   
        let userType = this.state.userType;
        let leadStatus=this.state.campaignLeadStatusArray;
    
        if(leadStatus){
            leadStatus=leadStatus.toString();
        }
        this.setState({ campaignStatus, reportDetail, loader1: "gen_loader"})

      

        if ((agencyID1 && agencyID1 !== "Select Agency") && (campaignStatus && campaignStatus.length>0)&& (campaignLeadStatusArray && campaignLeadStatusArray.length>0)&&(campID && campID !== "Select Campaign")&&(timeline && timeline !== "Select Timeline"))
         {
            if(reportSummary && reportSummary.length>0){
                reportSummary[0].customQuestionCount=this.state.customQuestionCount;
                reportSummary[0].leadStatus=leadStatus;
                reportSummary[0].campID=this.state.campID;
                reportSummary[0].campaignName=this.state.campaignName;               
               reportSummary[0].multiTouch=this.state.multiTouch; 
                

            }
            
            let data = {
                // user: user,
                // advertiserID: advertiserID,
                agencyID: agencyID1,
                deadline:this.state.timeline,
                campaignStatus:campaignStatus,//JSON array
                type: type,
                campID : this.state.campID,
                reportName: reportName,
                reportSummary: reportSummary,
                reportDetail: reportDetail,
                userType: userType,
                checkedColumnKeys:this.state.checkedColumnKeys
                
            }
            fetch("/advertiserReportEngine/generateReportForADV", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    // authorization: this.state.token,
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then((res) => {
                    this.setState({ loader1: "" })
                    if (res.success === true) {
                        Swal.fire({
                            title: '<span id="success_popUp">Report generated successfully.</span>',
                            html: "<span id='for_popUp_generate'>Please <a href='/advertiserMyReports'><span id='for_popUp_generate1'>click here</span></a> to check report.</span>",
                            type: "success",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                            preConfirm: () => {
                                window.location.href = "/advertiserMyReports";
                            },
                        });
                    }
                   
                }).catch(function (err) { console.log(err) });
        }
    }
    componentWillMount(e) {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
        }
        else {
            if (this.props.location.state !== undefined) {
                	let reportID = this.props.location.state.reportID; 
                this.state.reportID=reportID; //kiran-4748- not use setState giving issue

                 let reportKey=this.props.location.state.reportKey
                this.state.reportKey=reportKey//kiran-4748-  not use setState  giving issue

               let reportName = this.props.location.state.reportName; 
                this.state.reportName=reportName;//kiran-4748- not use setState  giving issue
                }
           
            var parsed = queryString.parse(this.props.location.search);
            // const { isAuthenticated } = this.props.auth;//kiran-4748-removing console warning-no used
            const { user } = this.props.auth;
            const token = user.token;
            var reportKey = this.state.reportKey;
            // var reportKey = parsed.reportKey
            // var reportID = parsed.reportID;
            let advertiserID = user.id;
            let stackHolder = "Advertiser";
            // var reportName = parsed.reportName;//kiran-4748-removing console warning-no used
            var reportName = this.state.reportName;
            let agencyID=parsed.agencyID;
            var type = parsed.reportType; 
          
            this.setState({
                advertiserID, reportKey, generateBtnFlag: false,
                reportName, type, token 
            })
        if(this.state.reportKey==="report"){
           
            this.setState({ drpdisabled_report: "drpdisable_report"})
        }
        else{
         this.setState({ drpdisabled_report: ""})
        }

       
            let data = {
                // advertiserID: advertiserID,
                stackHolder: stackHolder,
                reportID:  this.state.reportID,
                agencyID: agencyID,
                
                
            }
            // alert("stackholder"+stackHolder)
            // alert("reportID"+ this.state.reportID)
            // alert("agencyID"+ agencyID)
            // alert("report key" + reportKey)
            
            if (this.state.reportID) {
            // if (reportID) {
                this.setState({loader:'lead_loader'})
                fetch("/advertiserReportEngine/reportDetailForADV", {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        // authorization: token,
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json())
                    .then(result => {
                        let deadline,timeline,campaignStatus,checkedColumnKeys
                        if (result) {
                           timeline = result.deadline;
                          campaignStatus = result.campaignStatus;
                          checkedColumnKeys = result.manageColumns;
                          checkedColumnKeys = checkedColumnKeys.split(",");
                        }
                       
                        var str = campaignStatus;
                        var statusTempArray = [];
                      var str_array = str.split(",");
                    for (var i = 0; i < str_array.length; i++) {
                        //kiran-4748-removing console warning-changed != to !==
                   if (str_array[i] !== "") {
                   statusTempArray.push({ id: str_array[i], name: str_array[i] });
                        }
                    }
                   
                    let reportSummary=result.reportSummary;
                   let multiTouch = reportSummary[0].multiTouch;
                  let campaignName =reportSummary[0].campaignName;
                  let customQuestionCount=reportSummary[0].customQuestionCount;
                  let leadStatus=reportSummary[0].leadStatus;
                                                                                             
                     var str1 = leadStatus;
                        var leadTempArray = [];
                      var str_array1= str1.split(",");
                      //kiran-4748-removing console warning-changed != to !== and used let instead of var
                    for (let i = 0; i < str_array1.length; i++) {
                   if (str_array1[i] !== "") {
                    leadTempArray.push({ id: str_array1[i], name: str_array1[i] });
                        }
                    }
                        if (result.deadline !== "") {
                            //kiran-4748-//kiran-4748-added toString() and changed == to === , use setState
                            if (result.deadline.toString() === "All") {
                                this.setState({label:"All"}); //this.state.label = "All"
                             
                            }
                            else if (result.deadline.toString() === "Weekly") {
                                this.setState({label:"Ending This Week"}); //this.state.label = "Ending This Week"
                            }
                            else if (result.deadline.toString() === "Monthly") {
                                this.setState({label:"Ending This Month"}); //this.state.label = "Ending This Month"
                            }
                            else if (result.deadline.toString() === "Quarterly") {
                                this.setState({label:"Ending This Quarter"});//this.state.label = "Ending This Quarter"
                            }
                            else if (result.deadline.toString() === "Yearly") {
                                this.setState({label:"Ending This Year"}); // this.state.label = "Ending This Year"
                            }
                            else if (result.deadline.toString() === "PreMonth") {
                                this.setState({label:"Ending Previous Month"});//this.state.label = "Ending Previous Month"
                            }
                            else if (result.deadline.toString() === "PreQuarter") {
                                this.setState({label:"Ending Previous Quarter"}); //this.state.label = "Ending Previous Quarter"
                            }
                            else if (result.deadline.toString() === "PreYear") {
                                this.setState({label:"Ending Previous Year"}); //this.state.label = "Ending Previous Year"
                            }
                        }
                        this.setState({ CampaignLeadDetail: result.reportDetail, reportSummary: result.reportSummary,  deadline,
                            timeline,leadStatus:leadTempArray,campID:reportSummary[0].campID,campaignName,multiTouch,customQuestionCount,checkedColumnKeys,campaignStatus: statusTempArray,campaignLeadStatusArray:str_array1,
                            label: this.state.label,loader:"",agencyID}
                            ,function(){
                                this.setDataTableColumn(checkedColumnKeys,customQuestionCount,multiTouch)
                                   }
                            )
                    }).catch(function (err) { console.log(err) });
            }
           
               
            fetch("/advertiserReportEngine/getAgencyList", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    // authorization: token,
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then(agencyList => {
                    this.setState({ agencyList, agencyList1: agencyList,agencyID})
                }).catch(function (err) { console.log(err) });
            }
         
    }
    handleSubmit = e => {

        this.setDataTableColumn(this.state.checkedColumnKeys,this.state.customQuestionCount,this.state.multiTouch)
    }
    /**
* @author : Sandeep Dhawale-task-3479
* @description :Render part to display complete screen with functionality as per given screen .
*/
 
render() {
        const asterisk =
        {
            color: 'red',
        }
       
        function onChange(pagination, filters, sorter) {
            console.log("params", pagination, filters, sorter);
        }

        return (
            <div>
                <Navigation />
                <div style={{ paddingBottom: '60px', paddingTop: '100px' }} class="container-fluid ">
                    <div class="row">
                    {/* kiran-4748-removing console warning-added "#/" */}
                        <div class="col-sm-12 col-md-1 col-lg-2" >
                            <a  href="#/" onClick={this.backButtonHandleChange} style={{ color: '#056eb8' }}><FaArrowAltCircleLeft size={32} title="Back to advertiserMyReport" /></a>

                        </div>
                        <div class=" col-xs-12 col-sm-12 col-md-8 offset-lg-2 col-lg-4" align="center">
                            <label id="labelDI">Agency Lead Tracking Report</label>
                        </div>
                        <div class="col-sm-12 col-md-3 offset-lg-1 col-lg-3" id="gnrt-btn"style={{paddingLeft:'193px'}}>
                            {this.state.reportKey === "report" ?
                                "" :
                                this.state.generateBtnFlag === true ?
                                    <button class="btn" type="button" data-toggle="modal" data-target="#generateReport"
                                        onClick={this.generateHandleChange} style={{ backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"
                                            ,color:" white"}}>Generate Report</button>
                                    :
                                    <button disabled class="btn" type="button" data-toggle="modal" data-target="#generateReport"
                                    style={{ backgroundImage: "linear-gradient(to left,#007CC3,#193D8F)"
                                            ,color:" white"}}>Generate Report</button>

                            }

                        </div>
                    </div>
                    <div class="row" style={{ paddingTop: '15px', paddingLeft: '25px', paddingBottom: '5px' }}>
                        <div class="col-sm-12 col-md-3 col-lg-2" id="leadTracking_agency">                          {/* Chaitanya-4181-Advertiser- My report-advertiserLeadTrakingReport-responsiveness */}
                            <label style={{ fontSize: '14px',fontFamily: 'Roboto',color:'#14254A'}}>Agency&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;</label>
                            {/* kiran-4748-removing console warning-changed == to === */}
                            {this.state.reportKey === "report" ?
                            // Sandeep-task-3590-dropdown height issue
                                <select disabled value={this.state.agencyID} class="form-control" name="agencyName" id="for_res"style={{ width: '148px', height: '38px' , border:" 1px solid #CCCCCC",  borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555",opacity:'0.3'}} onChange={this.onAgencyNameHandleChange}>
                                    {/* kiran-4748-remove duplicate attribute */}
                                    <option value={"Select Agency"} style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Select Agency</option>
                                    {this.state.agencyList.map((agencyList, i) => (
                                        <option placeholder={"Select Agency"} style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}} value={agencyList.agencyID} key={i}>{agencyList.agencyName}</option>))}
                                </select>
                                :
                                // Sandeep-task-3590-dropdown height issue
                                <select value={this.state.agencyID} class="form-control"name="agencyName" id="for_res" style={{ width: '148px', height: '38px' , border:" 1px solid #CCCCCC",  borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555"}} onChange={this.onAgencyNameHandleChange}>
                                    <option value={"Select Agency"} style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Select Agency</option>
                                    {this.state.agencyList.map((agencyList, i) => (
                                        <option style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}} value={agencyList.agencyID} key={i}>{agencyList.agencyName}</option>))}
                                </select>

                            }
                        </div>&nbsp;
                        <div class="col-sm-12 col-md-3 col-lg-2" id="leadTracking_campusStatus">                    {/* Chaitanya-4181-Advertiser- My report-advertiserLeadTrakingReport-responsiveness */}
                            <label style={{ fontSize: '14px',fontFamily: 'Roboto',color:'#14254A'}}>Campaign Status&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;</label>
                            {/* kiran-4748-removing console warning-changed == to === */}
                            {this.state.reportKey=== "report" ?
                            <div disabled 
                            style={{ width: '148px',  border:" 1px solid #CCCCCC", borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555"}}>
                                 <Picky
                                 value={this.state.campaignStatus}
												options={CampaignStatusOptions}
                                                onChange={this.onCampaignStatusHandleChange} 
												open={false}
												valueKey="id"
												labelKey="name"                                             
												multiple={true}
												includeSelectAll={true}
												includeFilter={false}
												numberDisplayed={1}
												id="" disabled
												name="CampaignStatus"                                     
                                                className={"PickyStyle" + this.state.drpdisabled_report}
                            
                                                /> 
                                                {/* kiran-4748-removing console warning */}
                                              </div>
                                :
                                <div  
                                style={{ width: '148px' , border:" 1px solid #CCCCCC",   borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555"}}>
                                <Picky
                                value={this.state.campaignStatus}
                                options={CampaignStatusOptions}
                                onChange={this.onCampaignStatusHandleChange} 
                                open={false}
                                valueKey="id"
                                labelKey="name"
                                multiple={true}
                                includeSelectAll={true}
                                includeFilter={false}
                                dropdownHeight={200}
                                numberDisplayed={0}
                                id="CampaignStatus"
                                name="CampaignStatus"
                                className={"PickyStyle" + this.state.drpdisabled_report
                            }
                            // kiran-4748-removing console warning-changed == to ===
                            
                            /> 
                            </div>
                            }
                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="col-sm-12 col-md-3 col-lg-2" id="leadTracking_campaign">        {/* Chaitanya-4181-Advertiser- My report-advertiserLeadTrakingReport-responsiveness */}
                            <label style={{ fontSize: '14px',fontFamily: 'Roboto',color:'#14254A'}}>Campaign&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;</label> 
                            {this.state.reportKey=== "report" ?
                            // Sandeep-task-3590-dropdown height issue
                            <select disabled class="form-control" value={this.state.campaignName} id="for_res" name="campID" style={{ width: '148px', height: '38px' , border:" 1px solid #CCCCCC",  borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555",opacity:'0.3'}} onChange={this.onCampaignHandleChange}>
                                    <option placeholder={"Select Agency"} style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}
                                    value={this.state.campaignName} selected>{this.state.campID}-{this.state.campaignName}</option> 
                             
                                </select> 
                                // kiran-4748-removing console warning-remove duplicate attribute   
                                :   
                                <select class="form-control"value={this.state.campID} id="for_res" name="campID" style={{ width: '148px', height: '38px' , border:" 1px solid #CCCCCC",  borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555"}} onChange={this.onCampaignHandleChange}>
                                <option style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Select Campaign</option> 
                           {this.state.CampaignDetail && this.state.CampaignDetail.map((CampaignDetail,i) => (
                                <option style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}} value={CampaignDetail.campID} key={i}>{CampaignDetail.campID}-{CampaignDetail.campaignName}</option>))} 
                            </select>     }  
                        </div>&nbsp;
                
                        <div class="col-sm-12 col-md-3 col-lg-2" id="leadTracking_Timeline">        {/* Chaitanya-4181-Advertiser- My report-advertiserLeadTrakingReport-responsiveness */}
                           
                            <label style={{ fontSize: '14px',fontFamily: 'Roboto',color:'#14254A'}}>Timeline&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;</label>
                            {this.state.reportKey === "report" ?
                            // Sandeep-task-3590-dropdown height issue
                             // kiran-4748-removing console warning-remove unnecessary value attribute 
                                <select class="form-control"disabled name="Timeline" id="for_res" style={{ width: '148px', height: '38px' , border:" 1px solid #CCCCCC",  borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555",opacity:'0.3'}}
                                    value={this.state.timeline} required onChange={this.ondeadlineHandleChange} >
                                    <option value={"Select Timeline"} style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Select Timeline</option>
                                    <option value="All" label="All" style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>All</option>
                                    <option value="Weekly" label="Ending This Week" style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending This Week</option>
                                    <option value="Monthly" label="Ending This Month" style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending This Month</option>
                                    <option value="Quarterly" label="Ending This Quarter" style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending This Quarter</option>
                                    <option value="Yearly" label="Ending This Year" style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending This Year</option>
                                    <option value="PreMonth" label="Ending Previous Month" style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending Previous Month</option>
                                    <option value="PreQuarter" label="Ending Previous Quarter" style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending Previous Quarter</option>
                                    <option value="PreYear" label="Ending Previous Year" style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending Previous Year</option>

                                </select>
                                :
                                // Sandeep-task-3590-dropdown height issue
                                <select class="form-control"style={{ width: '148px', height: '38px' , border:" 1px solid #CCCCCC",  borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555"}} name="Timeline" id="for_res"
                                    value={this.state.timeline} required onChange={this.ondeadlineHandleChange} >

                                    <option value={"Select Timeline"} style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Select Timeline</option>

                                    <option value="All" lable="All"style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>All</option>
                                    <option value="Weekly" label="Ending This Week"style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending This Week</option>
                                    <option value="Monthly" label="Ending This Month"style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending This Month</option>
                                    <option value="Quarterly" label="Ending This Quarter"style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending This Quarter</option>
                                    <option value="Yearly" label="Ending This Year"style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending This Year</option>
                                    <option value="PreMonth" label="Ending Previous Month"style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending Previous Month</option>
                                    <option value="PreQuarter" label="Ending Previous Quarter"style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending Previous Quarter</option>
                                    <option value="PreYear" label="Ending Previous Year"style={{fontSize:"14px",fontFamily:"sans-serif",color:"#555555"}}>Ending Previous Year</option>

                                </select>
                            }
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-2" id="leadTracking_leadStatus">              {/* Chaitanya-4181-Advertiser- My report-advertiserLeadTrakingReport-responsiveness */}
                            <label style={{ fontSize: '14px',fontFamily:'Roboto',color:'#14254A',marginLeft:'5px'}}>Lead Status&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;</label> 
                            {this.state.reportKey === "report" ?
                             <div disabled 
                             style={{ width: '148px',  border:" 1px solid #CCCCCC", borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555"}}>
                                 <Picky
                                 value={this.state.leadStatus}
                                 options={leadStatusOptions}
                                 onChange={this.onLeadStatusHandleChange}
                                 open={false}
                                 valueKey="id"
                                 labelKey="name"                               
                                 multiple={true}
                                //  disabled={true}
                                 includeSelectAll={true}
                                 includeFilter={false}
                                 dropdownHeight={200}
                                 numberDisplayed={1}
                                 id=""disabled
                                //  name="leadStatus"
                                className={"PickyStyle"+ this.state.drpdisabled_report
                                }
                                //kiran-4748-removing console warning
                             />
                             </div>
                                :
                                <div  
                                style={{ width: '148px' , border:" 1px solid #CCCCCC",   borderRadius:" 3px",backgroundColor: "#FFFFFF",fontFamily: "sans-serif",color:" #555555"}}>
                                <Picky
												value={this.state.leadStatus}
												options={leadStatusOptions}
												onChange={this.onLeadStatusHandleChange}
												open={false}
												valueKey="id"
												labelKey="name"
												multiple={true}
												includeSelectAll={true}
												includeFilter={false}
												dropdownHeight={200}
												numberDisplayed={0}
												id="leadStatus"
												name="leadStatus"
                                                className={"PickyStyle" +this.state.drpdisabled_report
                                                 }
											/>
                                            {/* kiran-4748-removing console warning */}
                                            </div>
                        }
                                          
                        </div>&nbsp;
                    </div>
                    {/* <br/> */}
                    <hr />
                    
{/* kiran-4748-removing console warning-changed == to === */}
 {this.state.reportKey === "report" || this.state.generateBtnFlag === true ?

                    <div style={{ backgroundColor: 'white', marginBottom: '30px' }}>
                        <div style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: '10px',border: "1px solid #CCCCCC",
                        backgroundColor: "#FFFFFF", borderRadius: "3px"}}
                        >
                            <div>                         
                                <div>
                                    <label style={{ fontFamily: 'Roboto', fontWeight: '600px', fontSize: '18px', color: '#F28C0F' }}>Delivery Summary :
                                     {this.state.timeline !== "Select Timeline" || this.state.reportKey === "report" ?
                                            <span style={{ color: "black", fontSize: "14px" }}>&nbsp;&nbsp;&nbsp;{this.state.label}
                                              
                                                {this.state.label === "All"? ""
                                                    :
                                                     this.state.reportSummary && this.state.reportSummary.map(reportSummary => (
                                                        <span style={{ color: "black", fontSize: "14px" }}>({reportSummary.deliveryLabel})</span>
                                                    ))
                                                }
                                            </span>
                                            : ""}

                                    </label>
                                </div>
                               
                                <hr></hr>
                                {/* {this.state.reportKey == "report" || this.state.generateBtnFlag == true ? */}
                                    <div>
                                        {this.state.reportSummary && this.state.reportSummary.map(reportSummary => (
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                        Agency ID&nbsp;:&nbsp;
                                                     </label>
                                                    <label style={{color:" #14254A", fontFamily: "Roboto", fontSize: "14px"}}>{reportSummary.agencyID}&nbsp;</label>
                                                </div>
                                                <div class="col-sm-3 col-lg-4" style={{ marginBottom: "10px" }}>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                        Agency Name&nbsp;:&nbsp;
                                                     </label>
                                                    <label style={{color:" #14254A", fontFamily: "Roboto", fontSize: "14px"}}>{reportSummary.agencyName}</label>
                                                </div>
                                                {/* */}
                                                <div class="col-sm-2">
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>
                                                        Report ID&nbsp;:&nbsp;
                                                     </label>
                                                    <label style={{color:" #14254A", fontFamily: "Roboto", fontSize: "14px"}}>{this.state.reportID}&nbsp;</label>
                                                </div>
                                                <div class="col-sm-3 col-lg-4" style={{ marginBottom: "10px" }}>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>
                                                        Report Name&nbsp;:&nbsp;
                                                     </label>
                                                    <label style={{color:" #14254A", fontFamily: "Roboto", fontSize: "14px"}}>{this.state.reportName}</label>
                                                </div>
                                                {/* Chaitanya-4099-Reportname and ReportID- not getting display once click on the report name link*/}

                                                <div class="col-sm-12 col-md-12 col-lg-12">
                                                    <hr></hr>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}><b>Total count :</b></label>
                                                </div>

                                                <div class="col-sm-2">
                                                    <span style={{ color:" #4F4F4F", fontFamily: "Roboto", fontSize: "14px" }}>
                                                       
                                                        Allocated Lead&nbsp;:&nbsp;</span>

                                                    <span style={{color:"#414141"}}>
                                                        {reportSummary.totalAllocation}
                                                    </span>
                                                </div>

                                                <div class="col-sm-2">
                                                    <span  style={{ color:" #4F4F4F", fontFamily: "Roboto", fontSize: "14px" }}>Delivered&nbsp;:&nbsp;</span>
                                                    <span style={{ color:" #193D8F" }}>{reportSummary.deliveredLead}</span>

                                                </div>
                                                <div class="col-sm-2"> 
                                                    <span  style={{ color:" #4F4F4F", fontFamily: "Roboto", fontSize: "14px" }}>Review Pending&nbsp;:&nbsp;</span>
                                                    {this.state.campaignLeadStatusArray.includes("In QA")?                                                    
                                                    <b style={{fontWeight:700,fontSize:'14px',color:"#F28C0F"}}>{reportSummary.qaReview}</b>:
                                                    <span style={{color:"#F28C0F"}}>
                                                        {reportSummary.qaReview}</span>
                                                   }

                                                </div>
                                                <div class="col-sm-2">                                              
                                                    <span  style={{ color:" #4F4F4F", fontFamily: "Roboto", fontSize: "14px" }}>Accepted&nbsp;:&nbsp;</span>
                                                    {this.state.campaignLeadStatusArray.includes("Accepted")?                                                                                                  
                                                    <b style={{fontWeight:700,fontSize:'14px',color: '#008000'}}>{reportSummary.acceptedLead}</b>:
                                                    <span style={{ color: '#008000' }}>{reportSummary.totalAccepted}</span> 
                                                    
                                                  }

                                                </div>
                                                <div class="col-sm-2">
                                                    <span  style={{ color:" #4F4F4F", fontFamily: "Roboto", fontSize: "14px" }}>Rejected&nbsp;:&nbsp;</span>
                                                    {this.state.campaignLeadStatusArray.includes("Rejected")?
                                                     <b style={{fontWeight:700,fontSize:'14px',color: '#FF0000'}}>{reportSummary.rejectedLeads}</b>:
                                                    <span style={{ color: '#FF0000' }}>{reportSummary.rejectedLeads}</span>
                                                   }
                                                </div>
                                                <div class="col-sm-2">
                                                    <span  style={{ color:" #4F4F4F", fontFamily: "Roboto", fontSize: "14px" }}>Balance&nbsp;:&nbsp;</span>
                                                    <span style={{color:"#4F4F4F"}}>
                                                        {reportSummary.balanced}</span>

                                                </div>


                                            </div>
                                        ))}
                                    </div> 

             
                            </div>
{/* :""} */}

{/* ///manage column */}

<div class="modal fade" id="manageColumn" role="dialog" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                  <div class="modal-dialog modal-md float-right" id="manageColumn_1" style={{paddingTop:'120px',paddingRight:"36%"}}>   {/* Chaitanya-4181-Advertiser- My report-advertiserLeadTrakingReport-responsiveness */}
                    <div class="modal-content" id="manage_popup_cont_Adv" style={{width:'410px',float:'right'}}>
                      <div class="modal-header"style={{height:'65px'}}>
                          <div>
												<span style={{fontFamily:'Roboto',fontSize:'20px',fontWeight:'500',color:'#414141'}}>Manage Columns	
                                                <span id="manageColumn_close" style={{ cursor:"pointer", float:"right",fontSize:'30px'}} align="right" data-dismiss="modal">&times;</span></span>
												</div>
                                                </div>
                                  {/* {this.state.reportKey == "report"?
"": */}
                                                <div>
                                
                                                <div id="manageColumn_body" class="modal-body" style={{overflowY:'scroll',height:'330px',overflowX:"hidden"}}>      {/* Chaitanya-4181-Advertiser- My report-advertiserLeadTrakingReport-responsiveness */}
                                                <Checkbox.Group 
                                           
                                         style={{ display: 'grid',gridTemplateColumns: 'auto auto',width: '391px',fontSize:'12px',margin:'5px auto' }}
                                            options={this.state.checkBoxObjArray.map(obj => ({ label: obj.id, value: obj.name }))}
                                            value={this.state.checkedColumnKeys}                                       
                                            onChange={this.leadOnChange}
                                            
                                        />
                                             </div>
                                            
                                                                                         <hr></hr>
                                        <div style={{marginLeft:'40px',height:'45px'}}>
                                             <button id="manageColumn_Cancle" type="button" class="btn11 info11" data-dismiss="modal" style={{ width: '150px', height: '35px' }} >Cancel</button>       {/*Chaitanya-4181-Advertiser- My report- advertiserLeadTrakingReport*/}
                                             <button id="manageColumn_Save" className="btn add-button" style={{width:'150px',marginLeft:'15px', height: '35px',marginTop:'-3px'}} data-dismiss="modal"      /* Chaitanya-4181-Advertiser- My report-advertiserLeadTrakingReport-responsiveness */
                                             onClick={this.handleSubmit}>
								Save And Close
							 </button>
                                             </div>
                                                    </div>											
											</div>
										</div>
									</div>

{/* manage column */}
                            <div>
                            <div class="col-sm-12 col-md-4 col-lg-3" style={{ marginTop: '16px', marginLeft: '-21px' }}>
                                <span style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "16px" }}><b>
                                    Lead Details  : 
                                </b></span>
                            </div>
                            
{/* kiran-4748-removing console warning-changed == to === */}
                            {this.state.reportKey === "report"?
                            "":
                            <div class="col-sm-12 offset-md-5 col-md-3 offset-lg-7 col-lg-2" align="right" style={{ marginTop: '16px'}}>  
                                <span data-toggle="modal" 
										data-target="#manageColumn" style={{cursor:"pointer", color:"#1890FF", fontFamily: "Roboto", fontSize: "16px",fontWeight:"500"}}>Manage Columns</span></div>}
                            </div>
                        </div>

                    </div>
         :""}
                    <div align="center" id={this.state.loader1}></div>
                    <div align="center" id={this.state.loader}></div>
                    <div align="center" id={this.state.campaignLoader}></div>

{this.state.CampaignLeadDetail.length>0 ?
                    <Table {...this.state.tableState}
                        id="myTableReport tr"
                        bordered
                        dataSource={this.state.CampaignLeadDetail}
                        columns={this.state.dataTableColumns}
                        onChange={onChange}
                         className={`${tableCSS} "ant-table-thead"`} // kiran -4748-removing console warning-changed classname
                        scroll= {
                        this.state.multiTouch !=="No" ?            
                        {x:z ,y: 1333 }
                        :
                        this.state.customQuestionCount !==0 ?  
                        {x:4000 ,y: 1333 }
                        :
                        {x:5500 ,y: 1333 }
                    }                
                        pagination={{
                            pageSize: this.state.pageSize,
                            position: "bottom",

                        }}>

                    </Table>
:                   <Table {...this.state.tableState}
                        id="myTable tr"
                        bordered
                        dataSource={""}
                        columns={columnsReportForblank}
                        onChange={onChange}
                        className={`${tableCSS} "ant-table-thead"`} //kiran-4748-removing console warning-changed classname
                        scroll={{ x:2500, y: 1333 }}
                        pagination={{
                            pageSize: this.state.pageSize,
                            position: "bottom",

                        }}>

                    </Table>
} 
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6"
                        style={{ marginTop: "-53px", marginLeft: "5px", fontSize: '13px', color: '#4F4F4F', fontFamily: 'sans-serif' }}
                    >
                        Records per page:&nbsp;
												<select
                            defaultValue={this.state.pageSize}
                            onChange={this.handleChangePageSize}
                            id="pacing11"
                            class="input-small"
                            className="form-control"
                            name="pacing"
                            style={{ width: "71px", height: "30px", display: "initial", fontSize: '13px', fontFamily: 'sans-serif', color: '#4F4F4F' }}>
                            <option value="100" selected>
                                100
													</option>
                            <option value="200">200</option>
                            <option value="300">300</option>

                        </select>

                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}
/**
   * @author Sandeep Dhawale-task-3479
   * @param  Description handle the login authentication
   * @return Description return All details of authentication
   */
advertiserLeadTracking.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(advertiserLeadTracking));

