
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Sanjana Godbole
@Creation Date:21-09-2019
@Description:UI for PublisherFrontPage(Dashboard)
*/
 
import React from 'react';
import { Table, Button, Tooltip,Badge,Progress} from 'antd';
import PublisherNavigation from "../layouts/publisherNavPage";
// import Footer from '../layouts/footer';
import './dashboardPublisher.css';
import { css } from 'emotion';
import { saveAs } from 'file-saver';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
import Moment from 'react-moment';
// import Footer1 from  '../layouts/footer1';
// import {Launcher} from 'react-chat-window';
// import { Widget ,addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
// import { Container,Button as Button1, lightColors, darkColors,Link } from 'react-floating-action-button';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from "mdbreact";
const queryString = require('query-string');
const Swal = require('sweetalert2')
const xlsx =require('xlsx');
// const { TabPane } = Tabs;

 //const header = css({ backgroundColor: 'rgb(100, 108, 140)', color: 'white', margin: '50px'});
//  var dateTime = require("node-datetime");
 const tableCSS = css({
   
  backgroundColor: 'white',
  borderStyle: 'bold',
  '& thead  > tr': {
    //Narendra - Add !important because some header issue in my laptop and production
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3)!important',
  
    color: 'white',
    fontSize:'12px',
    fontWeight:'500'
  },
  '& thead > tr >th': {
    border: '1px solid black',
    height:'20%',
    // borderWidth: '2px',
    // borderColor: 'yellow',
    // borderStyle: 'solid',
    color: 'white',
  },
  // '& tbody > tr >td': {
  //   border: '1px solid black',
  //   borderWidth: '2px',
  //   borderColor: 'yellow',
  //   borderStyle: 'solid',
  //   color: 'white',
  // },
});
 
// const months=[{id:1,month:'January'},{id:2,month:'February'},{id:3,month:'March'},{id:4,month:'April'},{id:5,month:'May'},{id:6,month:'June'},{id:7,month:'July'},{id:8,month:'August'},{id:9,month:'September'},{id:10,month:'October'},{id:11,month:'November'},{id:12,month:'December'}];

class DasboardPublisher extends React.Component {  


    constructor(props) {
        super(props);
        this.state = 
        {
            campaignDetails:[],
            dashboardCampaignCount:[],
            campaignDetailsSearch:[],
            columnUnreadMsgSearch:[],
            columnUnreadAlertsSearch:[],
            tableSearchText:'',
            tableSearchText1:'',
            tableSearchText2:'',
            biddingArray:[],
            reviewArray:[],
            pacing:"All",
            linkActive:'active',
            linkActive1:'',
            linkActive2:'',
            linkActive3:'',
            linkActive4:'',
            loading:true,
            loading1:true,
            loading2:true,
            pageSize:5,
            pageSize1:5,
            pageSize2:5,
            pageSize3:5,
            pageSize4:5,
            monthCount:'',
            headExampleNewsLetter:'NewsLetter',
            headExampleCallScript:'CallScriptG',
            headExampleLandingPage:'LandingPageG',
            headExampleNewCampaign:'NewCampaign',
            headExampleCounter:'CounterG',
            newCampaignDetailID: [],
            errors: {},
            success:{},
            fields: {},
            alertDisplay:'none',
            alertBorder: '',
            alertWidth:'',
            alertAlign:'',
            spanSpaceDisplay:'none',
            tab1:'',
            tabName:'Active',
            headExampleCounter:'CounterG',
            step:1,
            pID:'',
            feedBackLength:'',
            leadsLength:'',
            invoiceDetailsCount:'',
            newCampaignLength:'',
            counterLength:'',
            btnDisable:'btn add-button',
            userlogger:"",
            pacing:"All",
            ReviewTab:'',
            headExample2:'head-example2',
            headExample3:'head-exampleGeneral3',
            headExample4:'head-exampleGeneral4',
            headExample5:'head-exampleGeneral5',
            inputClass:'btn add-button',
            reviewTabl1:'reviewCreatives',
            inputClass:'btn add-button',
            tabReview:'newsLetter',
            agencyLinkReview:[],
            othersLength:'',
            visible:false,
            visible1:false,
            show:"none",
            unreadMessages:[],
            unreadMessagesLength:0,
            unreadAlerts:[],
            unreadAlertsLength:0,
            panelBlock:"",
            pacingCampaignDetails:[],
            ext:'',
            fileName:'',
            downloadFiles:[],
            showRFPTBL:false,
            RFPLength:'',
            pacingUnit:'',
            cancelTab:''

        }// end of state
        this.campaignLink=this.campaignLink.bind(this);
        this.handleChangeSearch=this.handleChangeSearch.bind(this);
        this.handleChangeSearch1=this.handleChangeSearch1.bind(this);
        this.handleChangeSearch2=this.handleChangeSearch2.bind(this);
        this.uploadLeds=this.uploadLeds.bind(this);
        this.biddingLink=this.biddingLink.bind(this);
        this.creativeLink=this.creativeLink.bind(this);
        this.leadLink=this.leadLink.bind(this);
        this.linkOthers=this.linkOthers.bind(this);
        this.displayCampaignSpecification=this.displayCampaignSpecification.bind(this);
        this.displayCampaignSpecificationMsg=this.displayCampaignSpecificationMsg.bind(this);
        this.biddingCampID=this.biddingCampID.bind(this);
        this.counter=this.counter.bind(this);
        this.creativeFeedBack=this.creativeFeedBack.bind(this);
        this.submitCounterProposal=this.submitCounterProposal.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleDeliveredDownload=this.handleDeliveredDownload.bind(this);
        this.handleAcceptedDownload=this.handleAcceptedDownload.bind(this);
        this.handleRejectedDownload=this.handleRejectedDownload.bind(this);
        this.handleQALeadDownload=this.handleQALeadDownload.bind(this);
        this.handleRefreshPage=this.handleRefreshPage.bind(this);
        this.handleCampaignIDOnClickAccept=this.handleCampaignIDOnClickAccept.bind(this);
       this.acceptCampaign=this.acceptCampaign.bind(this);
       this.rejectCampaign=this.rejectCampaign.bind(this);
       this.handleChangeFilter=this.handleChangeFilter.bind(this);
       this.handleChangePageSize=this.handleChangePageSize.bind(this);
       this.handleChangePageSize1=this.handleChangePageSize1.bind(this);
       this.handleChangePageSize2=this.handleChangePageSize2.bind(this);
       this.handleChangePageSize3=this.handleChangePageSize3.bind(this);
       this.handleChangePageSize4=this.handleChangePageSize4.bind(this);
        this.leadReview=this.leadReview.bind(this);
        this.invoiceLink=this.invoiceLink.bind(this);
        this.uploadVoiceLog=this.uploadVoiceLog.bind(this);
        // this.openCity=this.openCity.bind(this);
        this.fadeInOut=this.fadeInOut.bind(this);
        this.closeChat=this.closeChat.bind(this);
        this.downloadIOFile=this.downloadIOFile.bind(this);
        this.recordOpen=this.recordOpen.bind(this);
    }
    //end of constructor

    recordOpen(parameter1,parameter2)
    {
      var alertID,clickedcampaignID;
      const {user} = this.props.auth;
      let data={alertID:parameter1,clickedcampaignID:parameter2,user:user}
      // alert("recordOpen=="+JSON.stringify(data));
      fetch("/notification/AgencyAlertsToAgecncyStatusChanged",{
        //insert data in publisher_assignment table
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      })
       .then(res => res.json())
        .catch(function (err) {console.log(err)});
    }

    onRedirect(e){
      e.preventDefault();
      const {isAuthenticated, user} = this.props.auth;
     window.location.href="/requestForProposalPublisher?PublisherID="+user.id;
     
    }


    fadeInOut(e)
    {
      //  alert("Id===>"+e.target.id);
      var id=e.target.id

      if(id==="messages")
      {

        this.setState(state => ({
         panelBlock:"message", show: "block"
        }));
      }
      else
      {
        this.setState(state => ({
          panelBlock:"alert", show: "block"
         }));
      }
   
      //alert("Id===>"+this.state.panelBlock);
       
    }

    closeChat()
  {
    this.setState(state => ({
      show: "none"
    }));
    
  }




    messageAgency = event => {
      window.location.href="/contactAgency";
    }


    // openCity(evt,city)
    // {
    //   var i, tabcontent, tablinks;
    //   tabcontent = document.getElementsByClassName("tabcontent");
    //   for (i = 0; i < tabcontent.length; i++) {
    //     tabcontent[i].style.display = "none";
    //   }
    //   tablinks = document.getElementsByClassName("tablinks");
    //   for (i = 0; i < tablinks.length; i++) {
    //     tablinks[i].className = tablinks[i].className.replace(" active", "");
    //   }
    //   // document.getElementById(cityName).style.display = "block";
    //  // evt.currentTarget.className += " active";
    // }
    uploadVoiceLog(e){
      var parsed = queryString.parse(this.props.location.search);
      //var pID = parsed.pID;
      var campID=e.target.id;
      var reallocationID=e.target.getAttribute('reallocationid');
      window.location.href='/voiceLogsUpload?campID='+campID+'&reallocationID='+reallocationID;
    }

    getagencyLink(e){
      var agencyID=e.target.id;
    window.location.href="/reviewLinkAgency?agencyID="+agencyID;
    }

    handleChangePageSize(e)
    {
      var pageSize=e.target.value;
      this.setState({pageSize:pageSize});
    }
    
    
    handleChangePageSize1(e)
    {
      var pageSize1=e.target.value;
      this.setState({pageSize1:pageSize1});
    }
    
    
    handleChangePageSize2(e)
    {
      var pageSize2=e.target.value;
      this.setState({pageSize2:pageSize2});
    }

    handleChangePageSize3(e)
    {
      var pageSize3=e.target.value;
      this.setState({pageSize3:pageSize3});
    }
    handleChangePageSize4(e)
    {
      var pageSize4=e.target.value;
      this.setState({pageSize4:pageSize4});
    }
    handleChangeFilter(e)
    {
     
      var pacing=e.target.id;
      const {isAuthenticated, user} = this.props.auth;
      document.getElementById("myText").innerHTML = e.target.name;
      var parsed = queryString.parse(this.props.location.search);
      var pID=parsed.pID;
     this.setState({pacing:pacing});
    
    var tabName=this.state.tabName;
    fetch("publisherDashboard/publisherDashCount?pID="+pID+"&pacing="+pacing)
    .then(res => res.json())
    .then(pubCampCount => {
     this.setState({ dashboardCampaignCount: pubCampCount })
    }).catch(function (err) {console.log(err)});

    if(tabName=="Active")
    {
  
       // Getting Publisher Active Campaign Details
    fetch("publisherDashboard/pubDashActiveCampaign?pID="+pID+"&pacing="+pacing+"&userRole="+user.role)
    .then(res => res.json())
    .then(campaignDetails => {
    
     this.setState({ campaignDetails: campaignDetails, loading:false })
    }).catch(function (err) {console.log(err)});
  

    }
    else  if(tabName=="Pending")
    {
 // Getting Publisher Pending Campaign Details
 fetch("publisherDashboard/pubDashPendingCampaign?pID="+pID+"&pacing="+pacing+"&userRole="+user.role)
 .then(res => res.json())
 .then(pendingCampaign => {
     // console.warn("###########"+JSON.stringify(pendingCampaign))
  this.setState({ campaignDetails: pendingCampaign })
 }).catch(function (err) {console.log(err)});

 

      
    }
    else if(tabName=="Paused")
    {

      // Getting Publisher Paused Campaign Details
 fetch("publisherDashboard/pubDashPausedCampaign?pID="+pID+"&pacing="+pacing+"&userRole="+user.role)
 .then(res => res.json())
 .then(pausedCampaign => {
  //  alert(pausedCampaign)
    //  console.warn("########### pausedCampaign "+JSON.stringify(pausedCampaign))
  // alert("pausedCampaign "+JSON.stringify(pausedCampaign))
     this.setState({ campaignDetails: pausedCampaign})
 }).catch(function (err) {console.log(err)});

    }
    else  if(tabName=="Completed")
    {
      
 // Getting Publisher Complete Campaign Details
 fetch("publisherDashboard/pubDashCompleteCampaign?pID="+pID+"&pacing="+pacing+"&userRole="+user.role)
 .then(res => res.json())
 .then(completeCampaign => {
     // console.warn("########### completeCampaign "+JSON.stringify(completeCampaign))
  this.setState({ campaignDetails: completeCampaign })
 }).catch(function (err) {console.log(err)});
    }
  }
    rejectCampaign(e)
    {
      this.setState({alertDisplay:'none'});
      e.preventDefault();
      let spanDisplay = 'none';
   var allocatedLead =this.state.newCampaignDetailID[0].allocatedLead;
   var parsed = queryString.parse(this.props.location.search);
   var pID = parsed.pID;
    const { isAuthenticated, user } = this.props.auth;

                console.log("User Details" + JSON.stringify(user));

                let data = {
                  allocationID: this.state.newCampaignDetailID[0].allocationID,
                  pID: pID,
                  reason: this.state.fields.rejectionReason,
                  user: user,
                  allocatedLead:this.state.newCampaignDetailID[0].allocatedLead,
                  campaignDetail: this.state.newCampaignDetailID
                };
                // let newsuccess = "";
                // this.setState({

                //   newsuccess: newsuccess,
                //   alertWidth: '1000px',
                //   alertAlign: 'center',
                //   alertDisplay: 'block',
                //   spanSpaceDisplay: spanDisplay


                // });
              fetch("publisher/rejectCampaign", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(data)
                })

                  .then(res => res.json()).then(res => {
                    console.warn("Response in res in==" + JSON.stringify(res));
                    if (res.success == true) {

                      console.warn("Response in res==" + JSON.stringify(res));
                      var a = (res.message);
                      // this.setState({ newsuccess: a,
                      //   btnDisable:'disabled1',
                      // });

                      
                      Swal.fire
                      ({
                       text: (res.message),
                        type: 'success',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        preConfirm: () => {
                          window .location.reload();
                        }
                      })

                    }
                    else {
                      Swal.fire({
              
                        text: "Some error occur during rejecting campaign...Please try again",
                        type: 'error',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        preConfirm: () => {
                          window .location.reload();
                        }
                      })

                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });

              
              }
    handleCampaignIDOnClickAccept(e)
    {
      var allocationID = e.target.id;
      var parentCampID=e.target.getAttribute('parentCampID');
      var reallocationID=e.target.getAttribute('reallocationID');
      this.setState({parentCampID,reallocationID});
      fetch("/publisher/newCampaignID?allocationID="+allocationID +"")
      .then(res => res.json())
      .then(newCampaignDetailID =>
        this.setState({ newCampaignDetailID: newCampaignDetailID,parentCampID:parentCampID}) 
      )
      .catch(function (err) {
        console.log(err)
      });
        }

    acceptCampaign(e)
    {
    e.preventDefault();
    this.setState({alertDisplay:'none'});
    let spanDisplay='none';
    var allocatedLead = document.getElementById("allocatedLead").value;
    var allocationID=this.state.newCampaignDetailID[0].allocationID;
    var parsed = queryString.parse(this.props.location.search);
    var pID = parsed.pID;
    const {isAuthenticated, user} = this.props.auth;

    let timezone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timestamp = new Date();
    
     let data = {allocationID:allocationID,
        pID:pID,
        user:user,
        allocatedLead:allocatedLead,
        campaignDetail:this.state.newCampaignDetailID,
        timezone,timestamp
      };
    fetch("publisher/acceptNewCampaign", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    })
    .then(res=>res.json()).then(res=>{
      console.warn("Response in res in=="+JSON.stringify(res));
      if(res.success==true)
      {
        console.warn("Response in res=="+JSON.stringify(res));

                    Swal.fire({
              
                      text: (res.message),
                      type: 'success',
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      preConfirm: () => {
                        window.location.reload();
                      }
                    })

      }
      else
      {
        Swal.fire({
              
          text: "Some error occur during accepting campaign...Please try again",
          type: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          preConfirm: () => {
            window .location.reload();
          }
        })
      }
    })
    .catch(err => {
    console.log(err);
    });
  }

    handleRefreshPage(e){
        var pID = window.location.href.split("=")[window.location.href.split("?").length - 1];
        window.location.href = '/dashboardPublisher?pID='+pID+'';
        window.location.reload();
              }//end of handle refresh page
    
    
              creativeFeedBack(e)
    {

    }

    handleChange(e) {
     
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
  
    }

    submitCounterProposal(e) {
      e.preventDefault();
      
     // var campID = document.getElementById("campID").value;
      //  var pID = window.location.href.split("=")[window.location.href.split("?").length - 1];
       /**
              * @author Narendra Phadke
              * @param  Description handle the publisher Login Details
              * @return Description return All publisher Details
              */
             const { isAuthenticated, user } = this.props.auth;
             var pID =user.id;
            //  console.log("User Details" + JSON.stringify(user));
             let data = {
              allocationID: this.state.newCampaignDetailID[0].allocationID,
               pID: pID,
               proposedLead: this.state.fields.proposedLead,
               proposedCPL:this.state.fields.proposedCPL,
               user: user,
               campaignDetail: this.state.newCampaignDetailID
             };
     
     
             if (this.validateForm()) {
         
              this.setState({alertDisplay:'none',alertBorder:'0px solid white'});
               
              fetch("publisherDashboard/counterCampaignNew", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(data)
                })
    
                  .then(res => res.json()).then(res => {
                    console.warn("Response in res in==" + JSON.stringify(res));
                    if (res.success ===true) {
                      this.setState({
                        inputClass: "disabled"
                      })
                      
                      console.warn("Response in res==" + JSON.stringify(res));
                      // var a = (res.message);
                      // this.setState({ newsuccess: a });
    
                      Swal.fire({
                  
                        text: (res.message),
                        type: 'success',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        preConfirm: () => {
                          window .location.reload();
                        }
                      })
    
    
                    }
                    else {
                      Swal.fire({
                  
                        text: "Some error occur during countering campaign...Please try again",
                        type: 'error',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        preConfirm: () => {
                          window .location.reload();
                        }
                      })
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
       //   var a = "Counter proposal done successfully.Please click on close button.";
         // this.setState({fields:fields,newsuccess: a });
      }//end of if
    
    }//submitcounter()


    validateForm() {
  
      let fields = this.state.fields;
      var proposedLead=this.state.fields.proposedLead;
      var allocatedLead=this.state.newCampaignDetailID[0].allocatedLead;
      var oldCPL=this.state.newCampaignDetailID[0].CPL;
      let errors = {};
      // let spanDisplay='none';
      let formIsValid = true;
    
     if (!fields["proposedLead"]) {
        formIsValid = false;
        errors["proposedLead"]=<li>Please enter proposed lead</li>;
        this.setState({alertBorder:'1px solid red'})
      }
    
      //regular expression for entering only numbers validation
      if (typeof fields["proposedLead"] !== "undefined") {
         
        var pattern = new RegExp(/^[1-9]+[0-9]*$/);//numbers except zero
    
        if (!pattern.test(fields["proposedLead"])) {
          formIsValid =false;
          errors["proposedLead"]=<li>Please enter proposed lead  number only</li>;
          this.setState({alertBorder:'1px solid red'})
        }
    }
    
    
    if (!fields["proposedCPL"]) {
      formIsValid = false;
      errors["proposedCPL"]=<li>Please enter proposed CPL</li>;
      this.setState({alertBorder:'1px solid red'})
    }
   
    //regular expression for entering  numbers  and decimal only validation
    if (typeof fields["proposedCPL"] !== "undefined") {
       
      var pattern = new  RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
    
      //if(patterndecimal.test(testval) && (result[i].cpl!=="0")) 
      if (!pattern.test(fields["proposedCPL"]) || (fields["proposedCPL"])=="0")
      {
        formIsValid =false;
        errors["proposedCPL"] ="Please check Proposed CPL value";
        errors["proposedCPL1"]=<li>CPL should be only number.</li>
        errors["proposedCPL2"]=<li>CPL should be greater than zero.</li>
        errors["proposedCPL3"]=<li>CPL value allowed only 2 decimal places.</li>
        
        this.setState({alertBorder:'1px solid red'})
      }

    }
    if(proposedLead>allocatedLead)
    {
      formIsValid =false;
      errors["GraterProposedLead"]=<li>Proposed leads cannot be greater than allocated leads</li>
      this.setState({border:'1px solid red'})
    }
    if(proposedLead==allocatedLead && oldCPL==fields.proposedCPL)
    {
      formIsValid =false;
      errors["equalsToCPL"] =<li>Proposed CPL cannot be equals to allocated CPL</li>;
      this.setState({alertBorder:'1px solid red'})
    } 
    if(fields.proposedCPL<oldCPL)
    {
      formIsValid =false;
      errors["lessProposedCPL"] =<li>Proposed CPL cannot be less than allocated CPL</li>;
      this.setState({alertBorder:'1px solid red'})
    }      
    
          this.setState({
            errors: errors,
            alertBorder:'1px solid red',
            alertWidth:'1000px',
            alertAlign:'center',
            alertDisplay:'block',
            //  spanSpaceDisplay:spanDisplay
          });
          // document.body.scrollTop = 0;
          // document.documentElement.scrollTop = 0;
          return formIsValid;

        }
      

    counter(e)
    {
      var allocationID = e.target.getAttribute('id');
      var campID = e.target.getAttribute('campID');
      var campaignName = e.target.getAttribute('campaignName');
     var parentCampID=e.target.getAttribute('parentCampID');
     var reallocationID=e.target.getAttribute('reallocationId');

     fetch("/publisherDashboard/counterDetails?allocationID="+allocationID +"")
        .then(res => res.json())
        .then(newCampaignDetailID =>{
          this.setState({ newCampaignDetailID: newCampaignDetailID,campID:campID})
        }

        ).catch(function (err) {
          console.log(err)
        });
    }

    biddingCampID(e)
    {
      var campID = e.target.getAttribute('id');
      var campaignName = e.target.getAttribute('campaignName');
     var parentCampID=e.target.getAttribute('parentCampID');
     var reallocationID=e.target.getAttribute('reallocationId');
    }

    displayCampaignSpecification(e)
    {
      var campID = e.target.getAttribute('campid');
      var cancelTab=e.target.getAttribute('canceltab');
      var pID = e.target.getAttribute('pid');
      var campaignName = e.target.getAttribute('campaignname');
     var parentCampID=e.target.getAttribute('parentcampid');
     var reallocationID=e.target.getAttribute('reallocationid');
     var status=e.target.getAttribute('status');
     if(cancelTab==="Cancel"){
      window.location.href ='/campaignSpecificationPublisherDashboard?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&key='+status+'&cancelKey='+cancelTab;
     }
     else{
      window.location.href ='/campaignSpecificationPublisherDashboard?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&key='+status;
     }
     

    }


    displayCampaignSpecificationMsg(e)
    {
      //alert("Agency==>"+e.target.getAttribute('agencyID'));
      var campID = e.target.getAttribute('campID');
      var pID = e.target.getAttribute('pID');
      var campaignName = e.target.getAttribute('campaignName');
     var parentCampID=e.target.getAttribute('parentCampID');
     var reallocationID=e.target.getAttribute('reallocationId');
     var agencyID=e.target.getAttribute('agencyID');
      window.location.href ='/campaignSpecificationPublisherDashboard?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&agencyID='+agencyID;

    }

    
    
     uploadLeds(e)
    {
      var campID = e.target.getAttribute('id');
      // var campaignName = e.target.getAttribute('campaignname');
     var parentCampID=e.target.getAttribute('parentcampid');
     var reallocationID=e.target.getAttribute('reallocationid');
      window.location.href="/publisherDeliverLead?campID="+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID;
    }// end of uploadLeds

    leadLink(e)
    {
     //getting Campaign ID which PNC upload lead
     const {user} = this.props.auth;
     var pID=user.id;
      var ReviewTab="ReviewLeads";
      this.setState({  reviewTabl1:"reviewCreatives"});
      var tabReview=e.target.id;
         
      this.setState({tabReview:tabReview})
     fetch("publisherDashboard/leadReviewDashboard?pID="+pID)
     .then(res => res.json())
     .then(leadReviewDashboard =>{
       this.setState({ reviewArray:leadReviewDashboard,leadsLength:leadReviewDashboard.length,ReviewTab:ReviewTab,headExample3:'head-example3',headExample2: 'head-exampleGeneral2',headExample5:'head-exampleGeneral5',headExample4:'head-exampleGeneral4',loading2:false})
       }).catch(function (err) {
       console.log(err)
     });   
    }

    linkOthers(e)
    {
      //  alert("on click other"+e.target.id)
      // reviewTabl1
      var tabReview=e.target.id;
      var parsed = queryString.parse(this.props.location.search);
      var pID = parsed.pID;
      var ReviewTab="ReviewCreatives";
       this.setState({tabReview:tabReview})
      
      // this.setState({headExample2:'head-exampleGeneral2',headExample3: 'head-exampleGeneral3',headExample5:'head-example5',})

      if(e.target.id==="others")
      {
        let data={
          pID:pID
        }
        fetch("/publisher/agencyLinkReview", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then((agencyLinkReview=>{
          
            this.setState({ reviewArray:agencyLinkReview,othersLength:agencyLinkReview.length,ReviewTab:ReviewTab,headExample2:'head-exampleGeneral2',headExample3: 'head-exampleGeneral3',headExample4: 'head-exampleGeneral4',headExample5:'head-example5',loading2:false,tabReview:'others',reviewTabl1:'invoiceTab'})
           
          }
          )).catch(function (err) {console.log(err)});
      }
      
    }
    invoiceLink(e)
    {
    
      var parsed = queryString.parse(this.props.location.search);
    var pID = parsed.pID;
    const {user} = this.props.auth;
    this.setState({pID:pID,userlogger:user.role})
    this.setState({  reviewTabl1:"invoiceTab"});
     //getting Campaign ID which PNC upload lead
       var ReviewTab="ReviewInvoice";
       let dataa={pID:this.state.pID}
      fetch("/publisherDashboard/getMonthList",{
        //insert data in publisher_assignment table
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataa)
      })
       .then(res => res.json())
       .then(monthArray => {
       this.setState({ monthCount:monthArray.length,reviewArray:monthArray,ReviewTab:ReviewTab,headExample4:'head-example4',headExample3: 'head-exampleGeneral3',headExample2: 'head-exampleGeneral2',headExample5: 'head-exampleGeneral5',loading2:false,tabReview:'invoice',reviewTabl1:'invoiceTab'})
      }).catch(function (err) {console.log(err)});
      }


    creativeLink(e)
    {

      e.preventDefault();
      var parsed = queryString.parse(this.props.location.search);
      var pID = parsed.pID;
      var ReviewTab="ReviewCreatives";
      this.setState({reviewTabl1:"reviewCreatives"})
     
      var pacing="All";// pacing from dromdown end this month-Monthly, end this weekly-Weekly & All 

      var tabReview=e.target.id;
         
      this.setState({tabReview:tabReview})
      var pacing="All";// pacing from dropdown end this month-Monthly, end this weekly-Weekly & All 
     const {isAuthenticated, user} = this.props.auth;
         var agencyID=user.id;
    
     if(e.target.id==="landingPage")
     {
            
      
   // Getting Publisher LP Campaign Details
   fetch("publisherDashboard/lpInCreative?pID="+pID)
   .then(res => res.json())
   .then(reviewArray => {
      //  console.warn("########### lpCampaign "+JSON.stringify(lpCampaign))
    this.setState({ reviewArray: reviewArray,ReviewTab:ReviewTab,loading2:false,headExampleNewsLetter:'NewsLetterG',headExampleCallScript:'CallScriptG',headExampleLandingPage:'LandingPage'})
   }).catch(function (err) {console.log(err)});



     }
     else  if(e.target.id==="callScript")
     {

    // Getting Publisher callScript Campaign Details
    fetch("publisherDashboard/callScriptInCreative?pID="+pID)
    .then(res => res.json())
    .then(reviewArray => {
       //  console.warn("########### csCampaign "+JSON.stringify(csCampaign))
     this.setState({ reviewArray: reviewArray,loading2:false,headExample2: 'head-example2',headExample3: 'head-exampleGeneral3',headExampleNewsLetter:'NewsLetterG',headExampleCallScript:'CallScript',headExampleLandingPage:'LandingPageG',ReviewTab:ReviewTab,headExample4: 'head-exampleGeneral4',headExample5: 'head-exampleGeneral5'})
    }).catch(function (err) {console.log(err)});

   

     }
     else{
       // Getting Publisher NewsLetter Campaign Details
     fetch("publisherDashboard/newsLetterInCreative?pID="+pID)
     .then(res => res.json())
     .then(reviewArray => {
        //  console.warn("########### newsLetterCampaign "+JSON.stringify(newsLetterCampaign))
      this.setState({ reviewArray: reviewArray,loading2:false,headExample3:'head-exampleGeneral3',headExample2: 'head-example2',headExampleNewsLetter:'NewsLetter',headExampleCallScript:'CallScriptG',headExampleLandingPage:'LandingPageG',ReviewTab:ReviewTab,headExample4: 'head-exampleGeneral4',headExample5: 'head-exampleGeneral5'})
     }).catch(function (err) {console.log(err)});

    

     }
    }// end of creativeLink

    biddingLink(e) {
      e.preventDefault();
      var parsed = queryString.parse(this.props.location.search);
      var pID = parsed.pID;
      var pacing="All";// pacing from dromdown end this month-Monthly, end this weekly-Weekly & All 
     const {isAuthenticated, user} = this.props.auth;
         var agencyID=user.id;
     if(e.target.id==="Counter")
     {
       var tab1="Counter";
            // Getting Publisher Counter Campaign Details
      fetch("publisherDashboard/dashCounterCampaign?pID="+pID)
      .then(res => res.json())
      .then(biddingArray => {
         console.warn("########### counterCampaign "+JSON.stringify(biddingArray))
       this.setState({showRFPTBL:false,biddingArray: biddingArray,counterLength:biddingArray.length,headExampleNewCampaign:'NewCampaignG',headExampleCounter:'Counter',headExampleRFP:'RFPG',loading1:false ,tab1:tab1})
      }).catch(function (err) {console.log(err)});
     }else if(e.target.id==="RFP")
     {
       var tab1="RFP";
            // Getting Publisher RFP Campaign Details
      fetch("publisherDashboard/getRFPAllocation?pID="+pID)
      .then(res => res.json())
      .then(biddingArray => {
          this.setState({showRFPTBL:true,biddingArray: biddingArray,RFPLength:biddingArray.length,headExampleNewCampaign:'NewCampaignG',headExampleCounter:'CounterG',headExampleRFP:'RFP',loading1:false ,tab1:tab1})
      }).catch(function (err) {console.log(err)});
     }
     else
     {
       var tab1="NewCampaign";

      // Getting Publisher New Campaign Details
   fetch("publisherDashboard/pubDashNewCampaign?pID="+pID+"&pacing="+pacing)
   .then(res => res.json())
   .then(biddingArray => {
     //("########### newCampaign "+JSON.stringify(newCampaign))
    this.setState({showRFPTBL:false,biddingArray: biddingArray,newCampaignLength:biddingArray.length,headExampleNewCampaign:'NewCampaign',headExampleCounter:'CounterG',headExampleRFP:'RFPG',loading1:false,tab1:tab1})
   }).catch(function (err) {console.log(err)});


     }
     
    }//end of biddingLink
    campaignLink(e) {
      e.preventDefault();
      
      const {isAuthenticated, user} = this.props.auth;
      var agencyID=user.id;
      var data={ agencyID:agencyID,pacing:this.state.pacing}
      this.setState({tabName:e.target.id})
    var parsed = queryString.parse(this.props.location.search);
    var pID = parsed.pID;
    var pacing=this.state.pacing;// pacing from dromdown end this month-Monthly, end this weekly-Weekly & All 
    this.setState({tabName:e.target.id})
   
     if(e.target.id=="Active")
     {
       var tab="Active";

    // Getting Publisher Active Campaign Details
    fetch("publisherDashboard/pubDashActiveCampaign?pID="+pID+"&pacing="+pacing+"&userRole="+user.role)
    .then(res => res.json())
    .then(campaignDetails => {
     var pacing=this.state.pacing;
     let pacingData={pID:pID,pacing:pacing,tabKey:"Active"}
     fetch("/publisherDashboard/pacingCampaignDetailsPublisher",{
       //insert data in publisher_assignment table
       method: "POST",
       headers: { "Content-Type": "application/json" },
       body: JSON.stringify(pacingData)
     })
      .then(res => res.json())
      .then(pacingCampaignDetails => {
      const  pacingResult=Array.from(new Set(pacingCampaignDetails.map(p=>p.campID))).map(campID=>{
          return{
            campID:campID,
            pacingUnit:pacingCampaignDetails.find(p=>p.campID=== campID).pacingUnit,
          };
        });
        campaignDetails = campaignDetails.map((e) => Object.assign({}, e, pacingResult.find((e1) => e1.campID == e.campID) || {}));
       this.setState({campaignDetails: campaignDetails, loading:false ,pacingCampaignDetails:pacingCampaignDetails,
    linkActive:'active',linkActive1:'',linkActive2:'',linkActive3:'',linkActive4:'',
      })
      
       
        //alert("pacingCampaignDetails Inside=="+JSON.stringify(pacingCampaignDetails))
    // let campaignDetails = this.state.campaignDetails.map((item, i) => Object.assign({}, item, this.state.pacingCampaignDetails[i]));
    // alert(campaignDetails);
     }).catch(function (err) {console.log(err)});
    
     

    }).catch(function (err) {console.log(err)});

    }
     else if(e.target.id=="Pending")
     {
      var tab="Pending";
       // Getting Publisher Pending Campaign Details
    fetch("publisherDashboard/pubDashPendingCampaign?pID="+pID+"&pacing="+pacing+"&userRole="+user.role)
    .then(res => res.json())
    .then(campaignDetails => {
        // console.warn("###########"+JSON.stringify(pendingCampaign))
     this.setState({ campaignDetails: campaignDetails, loading:false ,linkActive:'',linkActive1:'active',linkActive2:'',linkActive3:'',linkActive4:'',})
    }).catch(function (err) {console.log(err)});

    }
     else if(e.target.id=="Paused")
     {
      var tab="Paused";
       // Getting Publisher Paused Campaign Details
    fetch("publisherDashboard/pubDashPausedCampaign?pID="+pID+"&pacing="+pacing+"&userRole="+user.role)
    .then(res => res.json())
    .then(campaignDetails => {
        // console.warn("########### pausedCampaign "+JSON.stringify(pausedCampaign))
        var pacing=this.state.pacing;
        let pacingData={pID:pID,pacing:pacing,tabKey:"Paused"}
        fetch("/publisherDashboard/pacingCampaignDetailsPublisher",{
          //insert data in publisher_assignment table
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(pacingData)
        })
         .then(res => res.json())
         .then(pacingCampaignDetails => {
             console.warn("campaignDetails Inside=="+JSON.stringify(campaignDetails));
         this.setState({ pacingCampaignDetails:pacingCampaignDetails,campaignDetails: campaignDetails, loading:false,linkActive:'',linkActive1:'',linkActive2:'active',linkActive3:'',linkActive4:'',})
        
        }).catch(function (err) {console.log(err)});
     
    //  alert(JSON.stringify(this.state.campaignDetails))
    }).catch(function (err) {console.log(err)});
      
      }
     else if(e.target.id=="Completed")
     {
      var tab="Completed";
        // Getting Publisher Complete Campaign Details
    fetch("publisherDashboard/pubDashCompleteCampaign?pID="+pID+"&pacing="+pacing+"&userRole="+user.role)
    .then(res => res.json())
    .then(campaignDetails => {
        // console.warn("########### completeCampaign "+JSON.stringify(completeCampaign))
        var pacing=this.state.pacing;
        let pacingData={pID:pID,pacing:pacing,tabKey:"Completed"}
        fetch("/publisherDashboard/pacingCampaignDetailsPublisher",{
          //insert data in publisher_assignment table
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(pacingData)
        })
         .then(res => res.json())
         .then(pacingCampaignDetails => {
             console.warn("campaignDetails Inside=="+JSON.stringify(campaignDetails));
         this.setState({ pacingCampaignDetails:pacingCampaignDetails})
         this.setState({ campaignDetails: campaignDetails, loading:false,linkActive:'',linkActive1:'',linkActive2:'',linkActive3:'active',linkActive4:'', })
        }).catch(function (err) {console.log(err)});
    
    }).catch(function (err) {console.log(err)});

      }
      else if(e.target.id=="Cancel")
     {
     
      var tab="Cancel";
      var cancelTab="Cancel";
        // Getting Publisher Cancel Campaign Details
    fetch("publisherDashboard/pubDashcancelCampaign?pID="+pID+"&pacing="+pacing+"&userRole="+user.role)
    .then(res => res.json())
    .then(campaignDetails => {
        // console.warn("########### completeCampaign "+JSON.stringify(completeCampaign))
        var pacing=this.state.pacing;
        let pacingData={pID:pID,pacing:pacing,tabKey:"Cancel"}
        fetch("/publisherDashboard/pacingCampaignDetailsPublisher",{
          //insert data in publisher_assignment table
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(pacingData)
        })
         .then(res => res.json())
         .then(pacingCampaignDetails => {
             console.warn("campaignDetails Inside=="+JSON.stringify(campaignDetails));
         this.setState({ pacingCampaignDetails:pacingCampaignDetails})
         this.setState({ campaignDetails: campaignDetails, loading:false,linkActive:'',linkActive1:'',linkActive2:'',linkActive3:'',linkActive4:'active',cancelTab:cancelTab})
        }).catch(function (err) {console.log(err)});
    
    }).catch(function (err) {console.log(err)});

      }
    }// end of campaignLink



    /**
* @author Narendra
* @param  Description  Searching table information
*/
handleChangeSearch(e) {
  e.preventDefault();
  let currentList = [];
      // Variable to hold the filtered list before putting into state
  let newList = [];
  let filterData = e.target.value;
  // If the search bar isn't empty
  if (e.target.value === "") {
      newList = this.state.campaignDetails;
  } else {
      // Assign the original list to currentList
      currentList = this.state.campaignDetails;
      newList = currentList.filter(item => {
                // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
                // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        //const filter = e.target.value.toLowerCase();
          // check to see if the current list item includes the search term
          // If it does, it will be added to newList. Using lowercase eliminates
          // issues with capitalization in search terms and search content
        var data=Object.values(lc);
        var dataFinal=data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower=dataFinal.toLowerCase();
       return dataToLower.includes(filter);
});
}
  // Set the filtered state based on what our rules added to newList
this.setState({
campaignDetailsSearch:[...newList],tableSearchText:filterData
});
}



    /**
* @author Narendra
* @param  Description  Searching table information
*/
handleChangeSearch1(e) {
  e.preventDefault();
  let currentList = [];
      // Variable to hold the filtered list before putting into state
  let newList = [];
  let filterData = e.target.value;
  // If the search bar isn't empty
  if (e.target.value === "") {
      newList = this.state.unreadMessages;
  } else {
      // Assign the original list to currentList
      currentList = this.state.unreadMessages;
      newList = currentList.filter(item => {
                // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
                // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        //const filter = e.target.value.toLowerCase();
          // check to see if the current list item includes the search term
          // If it does, it will be added to newList. Using lowercase eliminates
          // issues with capitalization in search terms and search content
        var data=Object.values(lc);
        var dataFinal=data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower=dataFinal.toLowerCase();
       return dataToLower.includes(filter);
});
  //alert("JSON Data=="+JSON.stringify(newList))
}
  // Set the filtered state based on what our rules added to newList
this.setState({
  columnUnreadMsgSearch:[...newList],tableSearchText1:filterData
});
}




    /**
* @author Narendra
* @param  Description  Searching table information
*/
handleChangeSearch2(e) {
  e.preventDefault();
  let currentList = [];
      // Variable to hold the filtered list before putting into state
  let newList = [];
  let filterData = e.target.value;
  // If the search bar isn't empty
  if (e.target.value === "") {
      newList = this.state.unreadAlerts;
  } else {
      // Assign the original list to currentList
      currentList = this.state.unreadAlerts;
      newList = currentList.filter(item => {
                // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
                // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        //const filter = e.target.value.toLowerCase();
          // check to see if the current list item includes the search term
          // If it does, it will be added to newList. Using lowercase eliminates
          // issues with capitalization in search terms and search content
        var data=Object.values(lc);
        var dataFinal=data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower=dataFinal.toLowerCase();
       return dataToLower.includes(filter);
});
  //alert("JSON Data=="+JSON.stringify(newList))
}
  // Set the filtered state based on what our rules added to newList
this.setState({
  columnUnreadAlertsSearch:[...newList],tableSearchText2:filterData
});
}

  /**
   * @author Narendra Phadke
   * @param  Description handle the download rejected lead
   * @return Description return download rejected lead excel file
   */
  handleRejectedDownload(e){
    var campID = e.target.id;
   var parentCampID=e.target.getAttribute('parentCampID');
   var reallocationID=e.target.getAttribute('reallocationID');
    const {isAuthenticated, user} = this.props.auth;
    let data={
      campID:campID,
      user: user
    }
    fetch("publisher/publisherDownloadRejectedLeadDetails",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
      }).then(res => res.json())
    .then(downloadFiles =>{
        this.setState({ downloadFiles: downloadFiles })
  //       /* make the worksheet */
  if(downloadFiles.length>0){
  var ws = xlsx.utils.json_to_sheet(downloadFiles);
  /* add to workbook */
  var wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, "Lead");
  
  // /* write workbook */
  var buf = xlsx.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
  var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});
  /* generate a download */
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  var fileName="rejected"+parentCampID+"-"+reallocationID+".xlsx";
  saveAs(new Blob([s2ab(buf)],{type:"application/octet-stream"}), fileName);
  //window.location.href = '/dashboardPublisher?pID='+user.id;
}else{

  console.log("Data Not Exists");
}
    }
      )
    
      
  }


   /**
   * @author Somnath Keswad
   * @param  Description handle the download QA_Review lead
   * @return Description return download QA_Review lead excel file
   */
  handleQALeadDownload(e){
    var campID = e.target.id;
    var parentCampID=e.target.getAttribute('parentCampID');
    var reallocationID=e.target.getAttribute('reallocationID');
    const {isAuthenticated, user} = this.props.auth;
    let data={
      campID:campID,
      user: user
    }
    fetch("publisher/qaReviewDownloadLeadDetails",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
      }).then(res => res.json())
    .then(downloadFiles =>{
        this.setState({ downloadFiles: downloadFiles })
  //       /* make the worksheet */
  if(downloadFiles.length>0){
  var ws = xlsx.utils.json_to_sheet(downloadFiles);
  /* add to workbook */
  var wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, "Lead");
  
  // /* write workbook */
  var buf = xlsx.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
  var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});
  /* generate a download */
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  var fileName="QA_Review"+parentCampID+"-"+reallocationID+".xlsx";
  saveAs(new Blob([s2ab(buf)],{type:"application/octet-stream"}), fileName);
  //window.location.href = '/dashboardPublisher?pID='+user.id;
}else{
  console.log("Data Not Exists");
}
    }
      )
    
      
  }

    /**
   * @author Narendra Phadke
   * @param  Description handle the download accepted lead
   * @return Description return download accepted lead excel file
   */
  handleAcceptedDownload(e){
    var campID = e.target.id;
    var parentCampID=e.target.getAttribute('parentCampID');
    var reallocationID=e.target.getAttribute('reallocationID');
    const {isAuthenticated, user} = this.props.auth;
    let data={
      campID:campID,
      user: user
    }
   fetch("publisher/publisherDownloadAcceptedLeadDetails",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
      }).then(res => res.json())
    .then(downloadFiles =>{
        this.setState({ downloadFiles: downloadFiles })
  //       /* make the worksheet */
  if(downloadFiles.length>0){
  var ws = xlsx.utils.json_to_sheet(downloadFiles);
  /* add to workbook */
  var wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, "Lead");
  
  // /* write workbook */
  var buf = xlsx.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
  var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});
  /* generate a download */
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  var fileName="accepted"+parentCampID+"-"+reallocationID+".xlsx";
  saveAs(new Blob([s2ab(buf)],{type:"application/octet-stream"}), fileName);
  //window.location.href = '/dashboardPublisher?pID='+user.id;
}else{

  console.log("Data Not Exists");
}
    }
      )
    
     
  }

/**
   * @author Narendra Phadke
   * @param  Description handle the lead
   * @return Description return to go on lead review page
   */
  leadReview(e){

    const {user} = this.props.auth;
    var pID=user.id;
   
    // var parsed = queryString.parse(this.props.location.search);
    // var agencyID=parsed.agencyID;
    var campID = e.target.getAttribute('campid');
    
   var parentCampID=e.target.getAttribute('parentcampid');
   var reallocationID=e.target.getAttribute('reallocationid');
  // alert(reallocationID);
   window.location.href ='/publisherInternalReviewLead?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID+'&pID='+pID;
      
  
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the download delivered lead
   * @return Description return download delivered lead excel file
   */
  handleDeliveredDownload(e){
    var campID = e.target.id;
    var parentCampID=e.target.getAttribute('parentCampID');
    var reallocationID=e.target.getAttribute('reallocationID');
    
    const {isAuthenticated, user} = this.props.auth;
    let data={
      campID:campID,
      user: user
    }

    if(user.role==="PC")
    {
  fetch("publisher/publisherDownloadLeadDetails",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
      }).then(res => res.json())
    .then(downloadFiles =>{
        this.setState({ downloadFiles: downloadFiles })
  //       /* make the worksheet */
  if(downloadFiles.length>0){
  var ws = xlsx.utils.json_to_sheet(downloadFiles);
  /* add to workbook */
  var wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, "Lead");
  
  // /* write workbook */
  var buf = xlsx.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
  // var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});
  /* generate a download */
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  var fileName="delivered"+parentCampID+"-"+reallocationID+".xlsx";
  saveAs(new Blob([s2ab(buf)],{type:"application/octet-stream"}), fileName);
  //window.location.href = '/dashboardPublisher?pID='+user.id;
}else{
  console.log("Data Not Exists");
}
    }
      )
    
  }else{
    fetch("publisherDashboard/publisherDownloadLeadDetailsPNC",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
      }).then(res => res.json())
    .then(downloadFiles =>{
        this.setState({ downloadFiles: downloadFiles })
  //       /* make the worksheet */
  if(downloadFiles.length>0){
  var ws = xlsx.utils.json_to_sheet(downloadFiles);
  /* add to workbook */
  var wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, "Lead");
  
  // /* write workbook */
  var buf = xlsx.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
  // var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});
  /* generate a download */
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  var fileName="delivered"+parentCampID+"-"+reallocationID+".xlsx";
  saveAs(new Blob([s2ab(buf)],{type:"application/octet-stream"}), fileName);
  //window.location.href = '/dashboardPublisher?pID='+user.id;
}else{
  console.log("Data Not Exists");
}
    })
  
  }
  }
// end of  handleChangeSearch
componentWillMount()
{
 //Dashboard data
 if(!this.props.auth.isAuthenticated) {
  this.props.history.push('/userLogin');
}
else{
    var parsed = queryString.parse(this.props.location.search);
    var pID = parsed.pID;
    const {user} = this.props.auth;
    this.setState({pID:pID,userlogger:user.role})
    var pacing=this.state.pacing;// pacing from dromdown end this month-Monthly, end this weekly-Weekly & All 
    
    // Getting Publisher Campaign Count of All Tab
    let dataa={pID:this.state.pID}
    fetch("/publisherDashboard/getMonthList",{
      //insert data in publisher_assignment table
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataa)
    })
     .then(res => res.json())
     .then(monthArray => {
     this.setState({ monthCount:monthArray.length})
    }).catch(function (err) {console.log(err)});

    fetch("publisherDashboard/publisherDashCount?pID="+pID+"&pacing="+pacing)
    .then(res => res.json())
    .then(pubCampCount => {
     this.setState({ dashboardCampaignCount: pubCampCount })
    }).catch(function (err) {console.log(err)});

    // Getting Publisher Active Campaign Details
    fetch("publisherDashboard/pubDashActiveCampaign?pID="+pID+"&pacing="+pacing+"&userRole="+user.role)
    .then(res => res.json())
    .then(campaignDetails => {
     var pacing=this.state.pacing;
     let pacingData={pID:pID,pacing:pacing,tabKey:"Active"}
     fetch("/publisherDashboard/pacingCampaignDetailsPublisher",{
       //insert data in publisher_assignment table
       method: "POST",
       headers: { "Content-Type": "application/json" },
       body: JSON.stringify(pacingData)
     })
      .then(res => res.json())
      .then(pacingCampaignDetails => {
      const  pacingResult=Array.from(new Set(pacingCampaignDetails.map(p=>p.campID))).map(campID=>{
          return{
            campID:campID,
            pacingUnit:pacingCampaignDetails.find(p=>p.campID=== campID).pacingUnit,
          };
        });
        campaignDetails = campaignDetails.map((e) => Object.assign({}, e, pacingResult.find((e1) => e1.campID == e.campID) || {}));
       this.setState({campaignDetails: campaignDetails, loading:false ,pacingCampaignDetails:pacingCampaignDetails})
      
       
        //alert("pacingCampaignDetails Inside=="+JSON.stringify(pacingCampaignDetails))
    // let campaignDetails = this.state.campaignDetails.map((item, i) => Object.assign({}, item, this.state.pacingCampaignDetails[i]));
    // alert(campaignDetails);
     }).catch(function (err) {console.log(err)});
    
     

    }).catch(function (err) {console.log(err)});

  


    let data={
      pID:pID
    }
    fetch("/publisher/agencyLinkReview", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    }).then(res => res.json())
    .then((agencyLinkReview=>{
        this.setState({ othersLength:agencyLinkReview.length})
       
      }
      )).catch(function (err) {console.log(err)});
    // // Getting Publisher Pending Campaign Details
    // fetch("publisherDashboard/pubDashPendingCampaign?pID="+pID+"&pacing="+pacing)
    // .then(res => res.json())
    // .then(pendingCampaign => {
    //  this.setState({ activeCampaign: pendingCampaign })
    // }).catch(function (err) {console.log(err)});

    // Getting Publisher Paused Campaign Details
    // fetch("publisherDashboard/pubDashPausedCampaign?pID="+pID+"&pacing="+pacing)
    // .then(res => res.json())
    // .then(pausedCampaign => {
    // this.setState({ activeCampaign: pausedCampaign })
    // }).catch(function (err) {console.log(err)});

    // Getting Publisher Complete Campaign Details
    // fetch("publisherDashboard/pubDashCompleteCampaign?pID="+pID+"&pacing="+pacing)
    // .then(res => res.json())
    // .then(completeCampaign => {
    // this.setState({ activeCampaign: completeCampaign })
    // }).catch(function (err) {console.log(err)});

     // Getting Publisher New Campaign Details
     var tab1="NewCampaign"
     fetch("publisherDashboard/pubDashNewCampaign?pID="+pID+"&pacing="+pacing)
     .then(res => res.json())
     .then(biddingArray => {
     this.setState({ biddingArray: biddingArray,loading1:false,tab1:tab1,
      headExampleNewCampaign:'NewCampaign',headExampleCounter:'CounterG',headExampleRFP:'RFPG',
      newCampaignLength:biddingArray.length})
     //alert("Last updated===>"+JSON.stringify(this.state.biddingArray));
     }).catch(function (err) {console.log(err)});
   
     // Get RFP Bidding Campaign Details.
     fetch("publisherDashboard/getRFPAllocation?pID="+pID)
      .then(res => res.json())
      .then(biddingArray => {
          this.setState({RFPLength:biddingArray.length})
      }).catch(function (err) {console.log(err)});

     // Getting Publisher Counter Campaign Details
     fetch("publisherDashboard/dashCounterCampaign?pID="+pID)
     .then(res => res.json())
     .then(counterCampaign => {
      this.setState({ counterLength:counterCampaign.length})
     }).catch(function (err) {console.log(err)});

     var ReviewTab='ReviewCreatives';
     // Getting Publisher NewsLetter Campaign Details (Common for all-Newsletter,CallScript and Landing Page)
     fetch("publisherDashboard/newsLetterInCreative?pID="+pID)
     .then(res => res.json())
     .then(reviewArray => {
      this.setState({ reviewArray: reviewArray,loading2:false,feedBackLength:reviewArray.length ,ReviewTab:ReviewTab,headExample2: 'head-example2',headExample3: 'head-exampleGeneral3',headExample4: 'head-exampleGeneral4'})
     }).catch(function (err) {console.log(err)});

     fetch("publisherDashboard/leadReviewDashboard?pID="+pID)
            .then(res => res.json())
            .then(leadReviewDashboard =>{
              this.setState({ leadsLength:leadReviewDashboard.length})
              }).catch(function (err) {
              console.log(err)
            });

            var userID=user.userID;
          var firstName=user.firstName;
          var lastName=user.lastName;
          fetch("/publisherDashboard/publisherCompleteOnEndDate?pID="+ pID+"&userID="+userID+"&firstName="+firstName+"&lastName="+lastName)
        .then(res => res.json())        
        .catch(function (err) { console.log(err)});

            
    //  // Getting Publisher callScript Campaign Details
    //  fetch("publisherDashboard/callScriptInCreative?pID="+pID)
    //  .then(res => res.json())
    //  .then(csCampaign => {
    //     //  console.warn("########### csCampaign "+JSON.stringify(csCampaign))
    //   this.setState({ activeCampaign: csCampaign })
    //  }).catch(function (err) {console.log(err)});

    //  // Getting Publisher LP Campaign Details
    //  fetch("publisherDashboard/lpInCreative?pID="+pID)
    //  .then(res => res.json())
    //  .then(lpCampaign => {
    //     //  console.warn("########### lpCampaign "+JSON.stringify(lpCampaign))
    //   this.setState({ activeCampaign: lpCampaign })
    //  }).catch(function (err) {console.log(err)});

    }



 /* @author Narendra Phadke
          * @param  Description Unread message on dashboard
          * @return Description get all unread message
          */
         const {isAuthenticated, user} = this.props.auth;
         var pID=user.id;
         //alert("agencyID==>"+agencyID);
         let dataForUnread={
          pID:pID
        }
        fetch("/notification/publisherDashboardUnreadMessages", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(dataForUnread)
        }).then(res => res.json())
        .then((unreadMessages=>{
         //alert("UnRead Message=="+JSON.stringify(unreadMessages));
            this.setState({unreadMessages:unreadMessages,unreadMessagesLength:unreadMessages.length})
           
          }
          )).catch(function (err) {console.log(err)});

          fetch("/notification/publisherDashboardUnreadAlerts", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(dataForUnread)
          }).then(res => res.json())
          .then((unreadAlerts=>{
           // alert("UnRead MessaunreadAlertsge=="+JSON.stringify(unreadAlerts));
              this.setState({unreadAlerts:unreadAlerts,unreadAlertsLength:unreadAlerts.length})

              if(this.state.unreadAlerts&&this.state.unreadAlerts.length>0)
              {
                var name="key"
                var value
                for(var i=0;i<this.state.unreadAlerts.length;i++)
                { 
                  value=this.state.unreadAlerts[i].alertID
                  let unreadAlerts=[...this.state.unreadAlerts]
                  unreadAlerts[i] = { ...unreadAlerts[i], [name]: value };
                  // console.log(JSON.stringify(unreadAlerts[i]))
                this.setState({unreadAlerts:unreadAlerts})
                }
              }
             
            }
            )).catch(function (err) {console.log(err)});


} // end of componentWillMount
componentDidMount(){
  let dataa={pID:this.state.pID}
    fetch("/publisherDashboard/getMonthList",{
      //insert data in publisher_assignment table
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataa)
    })
     .then(res => res.json())
     .then(monthArray => {
     this.setState({ monthCount:monthArray.length})
    }).catch(function (err) {console.log(err)});

    const {user} = this.props.auth;
    let leadData={
      user:user
    }
    fetch("/publisher/updateLeadsPermissionTimeFlag",{
      //insert data in publisher_assignment table
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(leadData)
    })
     .then(res => res.json())
     .then(updateLeadsPermissionTimeFlag => {

   
    }).catch(function (err) {console.log(err)});

    

}

unreadMessagePage(e)
{
  var parsed = queryString.parse(this.props.location.search);
  var pID = parsed.pID;
  var campID=e.target.id;
  window.location.href='/publisherCreativeUploads?pID='+pID+'&campID='+campID;
}


uploadCreative(e){
  var parsed = queryString.parse(this.props.location.search);
  var pID = parsed.pID;
  var campID=e.target.id;
  window.location.href='/publisherCreativeUploads?pID='+pID+'&campID='+campID;
}

getInvoiceLink(e){
  var month=e.target.getAttribute('month');
  var year=e.target.getAttribute('year');
  window.location.href="/publisherInvoice?month="+month+"&year="+year;
}
/**
   * @author Somnath Keswad
   * @param  Description download Insertion Order Pdf Document
   * @return Description return pdf blob
   */
  downloadIODocument(e){
    var campID=e.target.id;
    const {user} = this.props.auth;
    var pID=user.id;
  
    var documentName="Insertion_Order_Document_"+campID+'.pdf';
    var ext=documentName.split('.').pop();
    this.setState({ ext: ext,filename:documentName})
    fetch("/publisherDashboard/getIODocument?campID="+campID+"&pID="+pID)
    .then(r => r.blob())
    .then(downloadFiles =>
      this.setState({ downloadFiles: downloadFiles}))
    .then(this.downloadIOFile);  
  } /*** End of downloadIODocument */

    /**
   * @author Somnath Keswad
   * @param  Description download Insertion Order Document
   * @return
   */
  downloadIOFile(blob){
      let url=this.state.filename;
      let fileName=url.substring(url.lastIndexOf('\\')+1);
      let pdfFileDownloadName=fileName;
      let newBlob = new Blob([this.state.downloadFiles], {type: "application/pdf"});
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        } 
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      link.download=pdfFileDownloadName;
      link.click();
  
  }/**** End of downloadIOFile */
  RFPReview(e){
    var campID=e.target.id;
    window.location.href="/requestForProposalPublisher?campID="+campID;
  }
    render()

         {
          const asterisk =
          {
            color: 'red',
          }

          const {isAuthenticated, user} = this.props.auth;

          const columnUnreadMsg=[
            {
              title: 'Camp_Id',
              dataIndex: 'campID',
              key: 'campID',
              width:80,
              align:'right',
              render: (text,record) => 
              <div >
            <span  style={{fontSize:'11px'}}> <a href="#" onClick={this.displayCampaignSpecificationMsg} campID={record.campID}  agencyID={record.agencyID}
                  campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID} pID={record.pID}
                  style={{fontSize:"11px" ,wordBreak:"break-all"}}>{record.parentCampID?record.parentCampID:record.campID}</a></span>
        
              </div>
            },
            {
              title: 'Campaign Name',
              dataIndex: 'campaignName',
              key: 'campaignName',
              width:200,
              align:'center',
              render: (text,record) => 
              <div >
            <div class="dot2" style={{fontSize:'11px'}}>
           
      {record.campaignName}</div>
        
              </div>
            },
            {
              title: 'Title',
              dataIndex: 'content',
              key: 'content',
              width:180,
              align:'center',
              render: (text,record) => 
              <div >
            <div class="dot2" style={{fontSize:'11px'}}>{record.content}</div>
        
              </div>
            },
            {
              title: 'Sender',
              dataIndex: 'author',
              key: 'author',
              width:120,
              align:'center',
              render: (text,record) => 
              <div >
            <span  style={{fontSize:'11px'}}>{record.author}</span>
        
              </div>
            },
            {
              title: 'When',
              dataIndex: 'datetime',
              key: 'datetime',
              //width:100,
              align:'center',
              render: (text,record) => 
              <div >
            <span  style={{fontSize:'11px'}}>
            <Moment format="DD-MMM-YY HH:mm">
                {record.datetime}
            </Moment>
              </span>
        
              </div>
            },

          ]

          

          const columnUnreadAlerts=[
            {
              title: 'Camp_Id',
              dataIndex: 'campID',
              key: 'campID',
              width:80,
              align:'center',
              render: (text,record) => 
              <div >
            {/* <span  style={{fontSize:'12px'}}> <a href="#" onClick={this.displayCampaignSpecification} campID={record.campID}  
                  campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID} pID={record.pID}
                  style={{fontSize:"11px" ,wordBreak:"break-all"}}>{record.campID}</a></span> */}
          <span  style={{fontSize:'11px'}}>{record.parentCampID?record.parentCampID:record.campID}</span>
              </div>
            },
            {
              title: 'Camp_Name',
              dataIndex: 'campaignName',
              key: 'campaignName',
              width:160,
              align:'center',
              render: (text,record) => 
              <div >
            <div class="dotUnreadAlert" style={{fontSize:'11px',width:'110px'}}>{record.campaignName}</div>
        
              </div>
            },
            {
              title: 'Title',
              dataIndex: 'content',
              key: 'content',
              width:160,
              align:'center',
              render: (text,record) => 
              <div >
            <div class="word-wrap" style={{fontSize:'11px'}}>{record.content}</div>
        
              </div>
            },
            {
              title: 'Sender',
              dataIndex: 'author',
              key: 'author',
              width:90,
              align:'center',
              render: (text,record) => 
              <div >
            <span  style={{fontSize:'11px'}} class="word-wrap">{record.author}</span>
        
              </div>
            },
            {
              title: 'When',
              dataIndex: 'datetime',
              key: 'datetime',
             width:100,
             align:'center',
              render: (text,record) => 
              <div >
            <span  style={{fontSize:'11px'}}>
                <Moment format="DD-MMM-YY HH:mm">
                  {record.datetime}
              </Moment>
              </span>
        
              </div>
            },

          ]

          const columns3= [
            {
              title: 'Agency Name',
              dataIndex: 'agencyName',
              key: 'agencyName',
              width: '30%',
              //  ...this.getColumnSearchProps('campID'),
             align:'center',
             sorter: (a, b) => a.agencyName - b.agencyName,
             render: (text,record) => 
             <div>
               {record.agencyName}
               </div>
            }
            ,
            {
              title: 'Review ',
              dataIndex: 'reviewDetails',
              key: 'reviewDetails',
              width: '30%',
              //  ...this.getColumnSearchProps('campID'),
             align:'center',
             sorter: (a, b) => a.reviewDetails - b.reviewDetails,
             render: (text,record) => 
             <div>
               <a href="#" id={record.agencyID} onClick={this.getagencyLink.bind(this)}>Click here</a>
               </div>
            },
           
          ]

          const invoiceData=[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              width: '10.8%',
              align:'right',
            sorter: (a, b) => a.id - b.id,
            render: (text,record) => 
            <div><span>{record.id}</span></div>
            },
            {
              title: 'Months',
              dataIndex: 'month',
              key: 'month',
              align:'center',  
               sorter: (a, b) => {
                const asc = 'asc';
                if ((a.month).toUpperCase() < (b.month).toUpperCase()) {
                    return asc ? -1 : 1;
                } else if ((a.month).toUpperCase() > (b.month).toUpperCase()) {
                    return asc ? 1 : -1;
                } else {
                    return 0;
                }
              },
               render: (text,record) => 
              <div >
                  <span style={{fontSize:'12px'}}>{record.month}</span>
              </div>
              },
              {
                title: 'Year',
                dataIndex: 'year',
                key: 'year',
                width: '10.8%',
                align:'right',
              sorter: (a, b) => a.year - b.year,
              render: (text,record) => 
              <div><span>{record.year}</span></div>
              },
              {
                title: 'Invoice Link',
                dataIndex: 'invoiceLink',
                key: 'invoiceLink',
                width: '40%',
                align:'center',
              sorter: (a, b) => a.invoiceLink - b.invoiceLink,
              render: (text,record) => 
              <div><span><a href="#" month={record.month} year={record.year} onClick={this.getInvoiceLink.bind(this)} style={{fontSize:"12px"}}
             >Get Invoice</a></span></div>
              }
        ];
        const columns23 = [
          {
              title: 'Camp_ID',
              dataIndex: 'campID',
              key: 'campID',
             align:'center',
            //  className:  this.state.ReviewTab=="ReviewCreatives"||this.state.ReviewTab=="ReviewLeads"? "show" : "hide", 

             sorter: (a, b) => a.campID - b.campID,
             width:'30.9%',
            render: (text,record) => 
             <div>

{}
          <span style={{fontSize:"12px"}}>
          {this.state.ReviewTab==='ReviewCreatives'?
          <a href="#" campid={record.campID} 
          id={record.campID} 
          parentcampid={record.parentCampID} reallocationid={record.reallocationID}
          style={{fontSize:"12px"}}
          onClick={this.uploadCreative.bind(this)}
          >{record.parentCampID?record.parentCampID:record.campID}</a>:
          <a href="#" onClick={this.leadReview} campid={record.campID}  
          parentcampid={record.parentCampID} reallocationid={record.reallocationID}
          style={{fontSize:"12px"}}>{record.parentCampID?record.parentCampID:record.campID}</a>
          
          }</span>
          </div>
              
          },
          
          {
              title: 'Camp_Name',
              dataIndex: 'campaignName',
              key: 'campaignName',
             align:'center', 
             width:'30.2%',
            //  className:  this.state.ReviewTab=="ReviewCreatives"||this.state.ReviewTab=="ReviewLeads"? "show" : "hide", 

             sorter: (a, b) => {
              const asc = 'asc';
              if ((a.campaignName).toUpperCase() < (b.campaignName).toUpperCase()) {
                  return asc ? -1 : 1;
              } else if ((a.campaignName).toUpperCase() > (b.campaignName).toUpperCase()) {
                  return asc ? 1 : -1;
              } else {
                  return 0;
              }
            },
               render: (text,record) => 
               <div>
          <span style={{fontSize:'12px'}}> 
        {record.campaignName}</span>
               </div> 
          },
      ]


          const columns2 = [
            {
                title: 'Camp_ID',
                dataIndex: 'campID',
                key: 'campID',
               align:'right',
              //  className:  this.state.ReviewTab=="ReviewCreatives"||this.state.ReviewTab=="ReviewLeads"? "show" : "hide", 

               sorter: (a, b) => a.campID - b.campID,
               width:'20.9%',
              render: (text,record) => 
               <div>
  
{}
            <span style={{fontSize:"12px"}}>
            {this.state.ReviewTab==='ReviewCreatives'?
            <a href="#" campid={record.campID} 
            id={record.campID} 
            parentcampid={record.parentCampID} reallocationid={record.reallocationID}
            style={{fontSize:"12px"}}
            onClick={this.uploadCreative.bind(this)}
            >{record.parentCampID?record.parentCampID:record.campID}</a>:
            <a href="#" onClick={this.leadReview} campid={record.campID}  
            parentcampid={record.parentCampID} reallocationid={record.reallocationID}
            style={{fontSize:"12px"}}>{record.parentCampID?record.parentCampID:record.campID}</a>
            
            }</span>
            </div>
                
            },
            
            {
                title: 'Camp_Name',
                dataIndex: 'campaignName',
                key: 'campaignName',
               align:'center', 
               width:'40.2%',
              //  className:  this.state.ReviewTab=="ReviewCreatives"||this.state.ReviewTab=="ReviewLeads"? "show" : "hide", 
 
               sorter: (a, b) => {
                const asc = 'asc';
                if ((a.campaignName).toUpperCase() < (b.campaignName).toUpperCase()) {
                    return asc ? -1 : 1;
                } else if ((a.campaignName).toUpperCase() > (b.campaignName).toUpperCase()) {
                    return asc ? 1 : -1;
                } else {
                    return 0;
                }
              },
                 render: (text,record) => 
                 <div>
            <span style={{fontSize:'12px'}}> 
          {record.campaignName}</span>
            
           
                 </div>
              
            },
            {
              title: 'Doc_Type',
              dataIndex: 'id',
              key: 'id',
              width:'20%',
              align:'center', 
              render: (text,record) => 
                 <div>{record.csDocType}&nbsp;{record.lpDocType}&nbsp;{record.pocDocType}</div>
            },
            //   // render: (text,record) => 
            //   // <div>{record.csDocType}&nbsp;{record.lpDocType}&nbsp;{record.pocDocType}</div>
            // },
            // {
            //   title: 'Month',
            //   dataIndex: 'month',
            //   key: 'month',
            //   width:'20%',
            //   align:'center', 
            //   className:  this.state.ReviewTab=="ReviewInvoice"? "show" : "hide", 
            //   // render: (text,record) => 
            //   // <div>{record.csDocType}&nbsp;{record.lpDocType}&nbsp;{record.pocDocType}</div>
            // },
            // {
            //   title: 'Invoice Link',
            //   dataIndex: 'invoice_link',
            //   key: 'invoice_link',
            //   width:'20%',
            //   align:'center', 
            //   className:  this.state.ReviewTab=="ReviewInvoice"? "show" : "hide", 
            //   // render: (text,record) => 
            //   // <div>{record.csDocType}&nbsp;{record.lpDocType}&nbsp;{record.pocDocType}</div>
            // },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              // width:'8%',
              align:'center',  
             // className:  this.state.userlogger=="PC"? "show" : "hide",
               render: (text,record) => 
               <div>
                {record.status==="Rejected" || record.status==="ClientRejected"?
                <span style={{fontSize:'12px',color:"red"}}>{record.status}  </span>:
                <span style={{fontSize:'12px',color:"green"}}>{record.status}  </span>
                }
              </div>
            },
        //   {
        //     title: 'Action',
        //     dataIndex: 'allocatedLead',
        //     key: 'allocatedLead',
        //     width:'15%',
        //     align:'center',
        //     render: (text,record) => 
        //     <div >
        //  <a href="#" id={record.campID} 
        //  >
        // <Tooltip placement="top" title="Feedback">
        // <img src="allocate.jpg"  id={record.campID}  onClick={this.creativeFeedBack} 
        // parentCampID={record.parentCampID} reallocationID={record.reallocationID}  width="20" height="20"></img>
        // </Tooltip>
        // </a>
        //    </div>
        //  },
        ]
        
        const RFPColumn=[
          {
            title: 'Proposal ID',
            dataIndex: 'campID',
            key: 'campID',
            width: '14%',
            align:'center',
          sorter: (a, b) => a.id - b.id,
          render: (text,record) => 
          <div><span><a href="#" onClick={this.RFPReview.bind(this)} id={record.campID}  
          style={{fontSize:"12px"}}>{record.campID}</a></span></div>
          },
          {
            title: 'Camp_Name',
            dataIndex: 'campaignName',
            key: 'campaignName',
            align:'center',  
             sorter: (a, b) => {
              const asc = 'asc';
              if ((a.campaignName).toUpperCase() < (b.campaignName).toUpperCase()) {
                  return asc ? -1 : 1;
              } else if ((a.campaignName).toUpperCase() > (b.campaignName).toUpperCase()) {
                  return asc ? 1 : -1;
              } else {
                  return 0;
              }
            },
             render: (text,record) => 
            <div >
                <span style={{fontSize:'12px'}}>{record.campaignName}</span>
            </div>
            }]

          const columns1 = [
            {
                title: 'Camp_ID',
                dataIndex: 'campID',
                key: 'campID',
               align:'center',
               sorter: (a, b) => a.campID - b.campID,
               width:'14%',
              render: (text,record) => 
               <div>
            <span>
            <span style={{fontSize:'12px'}}>{record.parentCampID?record.parentCampID:record.campID}</span>
              {/* <a href="#" 
            onClick={this.biddingCampID} campID={record.campID}  
            campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID}
           style={{fontSize:"12px"}}
           >{record.campID}</a> */}
           </span>
           </div>
            },
            {
                title: 'Camp_Name',
                dataIndex: 'campaignName',
                key: 'campaignName',
                align:'center',  
                 width:'35%',
                 sorter: (a, b) => {
                  const asc = 'asc';
                  if ((a.campaignName).toUpperCase() < (b.campaignName).toUpperCase()) {
                      return asc ? -1 : 1;
                  } else if ((a.campaignName).toUpperCase() > (b.campaignName).toUpperCase()) {
                      return asc ? 1 : -1;
                  } else {
                      return 0;
                  }
                },
                 render: (text,record) => 
                 <div >
               <span class="dot" style={{fontSize:'12px'}}><a href="#" onClick={this.displayCampaignSpecification} campid={record.campID}  
                  campaignname={record.campaignName} status={record.status} parentcampid={record.parentCampID} reallocationid={record.reallocationID}
                  style={{fontSize:"12px" ,wordBreak:"break-all"}}>{record.campaignName}</a></span>
            <br/> 
            {record.startDate} To {record.endDate}
              <br/>
              { this.state.userlogger=="PC"?  <span style={{fontSize:"10px"}}>CPL:  <span style={{fontWeight:"1000"}}>{record.CPL}{record.currency}</span> </span> :""}
            <span  style={{fontSize:'10px'}}>
         
           </span>
                 </div>
              
            },
              {
              title: 'Allocation Date',
              dataIndex: 'created',
              key: 'created',
             align:'center',  
               width:'15%',
               render: (text,record) => 
               <div >
          <span style={{fontSize:'12px'}}>{record.created}</span>
       
         
               </div>
            
          },
          
       
    
    //   {
    //     title: 'Camp_Type',
    //     dataIndex: 'ABM',
    //     key: 'ABM',
    //     width:'15%',
    //     align:'center',  
        
    //      render: (text,record) => 
    //      <div >
    // <span style={{fontSize:'12px'}}>{record.ABM}</span>
 
   
    //      </div>
      
    // },
    {
      title: 'Allocated Leads',
      dataIndex: 'allocatedLead',
      key: 'allocatedLead',
       width:'15%',
      align:'center',  
      
       render: (text,record) => 
       <div >
  <span style={{fontSize:'12px'}}>{record.allocatedLead}</span>

 
       </div>
    
  },
  {
    title: 'Hrs Left',
    dataIndex: '',
    key: '',
     width:'9%',
    align:'center',  
    
     render: (text,record) => 
     <div >
       
       
<span style={{fontSize:'12px'}}>
  
  
  
  {record.hourLeft}</span>


     </div>
  
},
  //   {
  //     title: 'CPL',
  //     dataIndex: 'CPL',
  //     key: 'CPL',
  //    width:'9%',
  //      align:'center',  
  //  // className:  this.state.userlogger=="PC"? "show" : "hide",
  //      render: (text,record) => 
  //      <div >
  // <span style={{fontSize:'12px'}}>{record.CPL}<br/>{record.currency}</span>

 
  //      </div>
    
  // },
  {
    title: 'Action',
    dataIndex: '',
    key: '',
    //width:'15%',
     align:'center',
    render: (text,record) =>
    <div> 
    <div class="modal fade" id="accept" role="dialog"  data-backdrop="static"
                        data-keyboard="false">
                        <div class="modal-dialog cust-class">
                          {/*Model Content*/}
                          <div class="modal-content">
                            <form
                              method="POST"
                              name="acceptPub"
                             // onSubmit={this.submitCounterProposal}
                            onSubmit={this.acceptCampaign}
                            >
                              <div class="modal-header custom-class">
                              <h4 class="modal-title" style={{color:'white'}}><center>Accept Campaign (Campaign ID:{this.state.parentCampID})</center></h4>
                                <button
                                  type="button"
                                  class="cancel-button"
                                  style={{fontSize:15, float:'right'}}
                                  data-dismiss="modal"
                                  
                                  onClick={this.handleRefreshPage}
                                >
                                  &times;
                                </button>
                              </div>

                              
                                   {/* message */}
            <div className=""  style={{display:this.state.alertDisplay}}>
            <div class="container-fluid">
                 <div class="card card-signin my-1">
                          <div class="card-body" >
                              <div class="row">
                                {/* <div class="col-lg-12" style={{display:this.state.alertDisplay}}> */}
                                  <div style={{fontSize:'14px',color:'#008000',fontWeight:'600',paddingLeft:'30px'}}>
                                  {this.state.newsuccess}</div> 
                                  
                                {/* </div> */}
                              </div>
                          </div>
                        </div>
                        </div>
                        </div>
                              <div class="modal-body">
                              <label id="label" style={{float:'left'}}>
                                  <b>Are you sure you want to accept below Campaign
                                  with listed CPL?</b>
                                 </label> 
                              <br/><br/>
                              {this.state.newCampaignDetailID.map(
                                  newCampaignDetailID =>(
                                    <p>
                                      {/* <label id="label" style={{float:'left',color:'black'}}><b>Campaign ID:</b>&nbsp;&nbsp;</label> */}
                                      {/* &nbsp; */}
                                      {/* <label id="label"style={{float:'left',color:'black'}}>
                                        {this.state.parentCampID} &nbsp;
                                      </label><br/><br /> */}
                                      <label id="label"style={{float:'left'}}><b>Allocated CPL:</b>&nbsp;&nbsp;</label>&nbsp;
                                      <label id="label"style={{float:'left',color:'black'}}>
                                        {newCampaignDetailID.CPL} &nbsp;{newCampaignDetailID.currency}
                                      </label>
                                      </p>
                                 ))}
                              </div>
                              <div class="modal-footer">
                              <button type="submit" className={this.state.btnDisable}  style={{backgroundColor:'#0000b3',color:"white" ,borderRadius: "3px"}}>
                                {/* <i class="fa fa-check-circle" aria-hidden="true"></i>  */}
                                Accept
                                </button>
                              {/* <button
                                        type="button" 
                                        class="btn btn-default cancel-button"
                                        data-dismiss="modal"
                                         onClick={this.handleRefreshPage}
                                      >
                                  Close
                                </button> */}
                                
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
    <div >

{/* Reject Model */}
                      <div class="modal fade" id="reject" role="dialog"  data-backdrop="static"
                        data-keyboard="false">
                        <div class="modal-dialog">
                          {/*Model Content*/}
                          <div class="modal-content">
                          <form
                            method="POST"
                            name="rejectPub"
                         
                            onSubmit={this.rejectCampaign}
                          >
                            <div class="modal-header custom-class">
                            <h4 class="modal-title" style={{color:'#144c9b'}}><font color='white'>Rejection (Campaign ID:{this.state.parentCampID})</font></h4>
                              <button
                                type="button"
                                class="cancel-button"
                                style={{fontSize:15,float:'right'}}
                                data-dismiss="modal"
                                onClick={this.handleRefreshPage}
                              >
                                &times;
                              </button>
                            </div>
                            <div className="" style={{ display: this.state.alertDisplay }}>
                              <div class="container-fluid">
                                <div class="card card-signin my-1">
                                  <div class="card-body" >
                                    <div class="row">
                                      {/* <div class="col-lg-12" style={{display:this.state.alertDisplay}}> */}
                                      <div style={{ fontSize: '14px', color: '#008000', fontWeight: '600', paddingLeft: '30px' }}>
                                        {this.state.newsuccess}</div>

                                      {/* </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                            
                            <label id="label" style={{float:'left'}}>
                               <b><center> Are you sure you want to reject below Campaign
                                with listed CPL?</center></b>
                       </label><br/><br/>
                            {this.state.newCampaignDetailID.map(
                              newCampaignDetailID =>(
                              <div>
                                {/* <label id="label" style={{float:'left',color:'black'}}><b>Campaign ID:</b>&nbsp;</label>
                                  <label id="label" style={{float:'left',color:'black'}}>{this.state.parentCampID}</label>
                                <br /><br/> */}
                                <label id="label" style={{float:'left'}}><b> Allocated CPL:</b>&nbsp;</label>
                                <label id="label" style={{float:'left',color:'black'}}>{newCampaignDetailID.CPL} &nbsp;{newCampaignDetailID.currency}</label>
                                <br /><br/>
                                <label id="label" style={{float:'left'}}><b>Rejection Reason:</b>&nbsp;</label>
                                <textarea style={{height:'200px',resize:'none'}}
                                  id="rejectionReason"
                                  name="rejectionReason"
                                  value={this.state.rejectionReason}
                                  onChange={this.handleChange}
                                  class="form-control"
                                />
                                    <input type="hidden" id="allocatedLead" name="allocatedLead" value={newCampaignDetailID.allocatedLead}/>
                              </div>
                             ))}
                            </div>
                            <div class="modal-footer">
                            <button type="submit" className={this.state.btnDisable} style={{backgroundColor:"#056eb8",color:"white",width:'min-content',height:'31px',fontSize:'14px'}}>
                                Reject
                                {/* <span><i class="fa fa-times-circle" aria-hidden="true"></i></span> */}
                                </button>
                              {/* <button
                                type="button"
                                class="btn btn-default cancel-button"
                                data-dismiss="modal"
                                onClick={this.handleRefreshPage}
                              >
                                Close
                              </button> */}
                             
                            </div>
                          </form>
                          </div>
                        </div>
                      </div>
                      {/*end of reject modal */}

  {this.state.userlogger=="PC"?

 <span style={{display:'inline-flex'}}>
<a href="#" id={record.campID} 
  >
<Tooltip placement="top" title="Accept">
<img src="Accept.jpg" id={record.allocationID}  data-toggle="modal" data-target="#accept" onClick={this.handleCampaignIDOnClickAccept}
   parentCampID={record.parentCampID} reallocationID={record.reallocationID}  width="20" height="20"></img>
</Tooltip>
</a>
&nbsp;&nbsp;

{/* <Divider type="vertical" style={{float:'left'}} /> */}

<a href="#" id={record.campID} >
<Tooltip placement="top" title="Reject">
<img src="Reject.png"   id={record.allocationID} data-toggle="modal" data-target="#reject" onClick={this.handleCampaignIDOnClickAccept}
 parentCampId={record.parentCampID?record.parentCampID:record.campID}
     reallocationId={record.reallocationID}
     width="20" height="20"></img>
</Tooltip>
</a>
&nbsp;&nbsp;
{/* <Divider type="vertical" style={{float:'left'}} /> */}

 {this.state.tab1=="NewCampaign"?   
<a href="#" id={record.campID} data-toggle="modal"
                          data-target="#counterproposal11">
<Tooltip placement="top" title="Counter">
<img src="Counter.png"   id={record.allocationID}  campID={record.campID}   pID={record.pID}
         campaignName={record.campaignName}  parentCampID={record.parentCampID} reallocationID={record.reallocationID}
     onClick={this.counter}  width="20" height="20"></img>
</Tooltip>
</a>
:

<Tooltip placement="top" overlayStyle={{color: 'white', backgroundColor: 'orange'}} title="Counter Proposal Submitted">
<img src="Counter_Disabled.png"    width="20" height="20"></img>
</Tooltip>

} 


</span>
:
<span style={{display:'inline-flex'}}>

<Tooltip placement="top" title="Accept">
<img src="Accept_Disabled.jpg"  width="20" height="20"></img>
</Tooltip>

&nbsp;&nbsp;

{/* <Divider type="vertical" style={{float:'left'}} /> */}


<Tooltip placement="top" title="Reject">
<img src="Reject_Disabled.png"   
     width="20" height="20"></img>
</Tooltip>

&nbsp;&nbsp;
{/* <Divider type="vertical" style={{float:'left'}} /> */}

 {this.state.tab1=="NewCampaign"?   

<Tooltip placement="top" title="Counter">
<img src="Counter_Disabled.png"    width="20" height="20"></img>
</Tooltip>

:

<Tooltip placement="top" title="Counter">
<img src="Counter_Disabled.png"    width="20" height="20"></img>
</Tooltip>

} 


</span>





}

 
<div
                        class="modal fade"
                        id="counterproposal11"
                        role="dialog"
                        data-backdrop="static"
                        data-keyboard="false"
                      >
                        <div class="modal-dialog cust-class">
                          {/*Model Content*/}
                          <div class="modal-content">
                           <form method="POST"  name="counterproposal"  onSubmit= {this.submitCounterProposal} >
                            <div class="modal-header custom-class">
                            <h4 class="modal-title" style={{color:'white'}} ><center>Counter Proposal&nbsp;(Campaign ID:{this.state.campID})</center></h4>
                              
                                           
                             
                              <button
                                type="button"
                                class="cancel-button"
                                style={{fontSize:15,float:'right' }}
                                data-dismiss="modal"
                                onClick={this.handleRefreshPage}
                              >
                                &times;
                              </button>
                               
                            </div>
                         
                        {this.state.newCampaignDetailID.map(
                                    newCampaignDetailID =>(
                                      

                            <div class="modal-body">
                              <div class="container-fluid">
                                <div class="row">
                                  <div class="col-sm-12">
                                    {" "}
                                  
                                 </div>
                                  </div>
                                 {/* <ColoredLine color="black" />  */}
                                  <br/>
                                  <div className=""  style={{display:this.state.alertDisplay}}>
            <div class="container-fluid">
                 {/* <div class="card card-signin my-1"> */}
                          <div class="card-body" >
                              <div class="row">
                                 <div class="col-lg-12" style={{display:this.state.alertDisplay}} style={{border:this.state.alertBorder}}> 
                                  <div class="errorMessage" >
                                  {this.state.errors.proposedLead?this.state.errors.proposedLead:''}
                                
                                  
                                  {this.state.errors.proposedCPL?this.state.errors.proposedCPL:''}
                                  {this.state.errors.proposedCPL1?this.state.errors.proposedCPL1:''}
                                  {this.state.errors.proposedCPL2?this.state.errors.proposedCPL2:''}
                                  {this.state.errors.proposedCPL3?this.state.errors.proposedCPL3:''}
                                  {this.state.errors.GraterProposedLead?this.state.errors.GraterProposedLead:''}
                                  {this.state.errors.lessProposedCPL?this.state.errors.lessProposedCPL:''}
                                  {this.state.errors.equalsToCPL?this.state.errors.equalsToCPL:''}
                                  </div>
                                  <div class="successMessage">
                                 {this.state.newsuccess}
                                  </div> 
                                  
                                 </div> 
                             </div>
                          {/* </div> */}
                        </div>
                        </div>
                        </div>
                        {/* <br/> */}
                         <div class="row">
                                  <div class="col-sm-6">
                                    <label id="label">Allocated Leads:</label>
                                   <br/>
                                    {newCampaignDetailID.allocatedLead}
                                  </div>
                                  <div class="col-sm-6">
                                    <label id="label">Proposed Leads:<span style={asterisk}>*</span></label>
                                    <input
                                      type="text"
                                      id="proposedLead"
                                      name="proposedLead"
                                      value={this.state.proposedLead}
                                      onChange={this.handleChange}
                                      style={{
                                        width: "87%",
                                        
                                        borderRadius: "2px"
                                      }}
                                      class="form-control"
                                    />
                                    
                                    {/* <div className="errorMsg">{this.state.errors.proposedLead}
                                    </div> */}
                                   
                                 </div>
                                  
                                  
                                  <div class="col-sm-6">
                                    <label id="label">Allocated CPL:</label>
                                    <br/>
                                    {newCampaignDetailID.CPL}&nbsp;{newCampaignDetailID.currency}
                                  </div>
                                  <div class="col-sm-6">
                                    <label id="label">Proposed CPL: <span style={asterisk}>*</span></label>
                                    &nbsp;
                                    <input
                                      type="text"
                                      id="proposedCPL"
                                      name="proposedCPL"
                                      value={this.state.proposedCPL}
                                      onChange={this.handleChange}
                                      style={{
                                        width: "87%",
                                       
                                        borderRadius: "2px"
                                      }}
                                      class="form-control"
                                    />
                                    
                                    {/* <div className="errorMsg">{this.state.errors.proposedCPL}</div> */}
                                    
                                </div>
                                </div>
                                {/**End of row */}
                            </div>
                              {/*container-fluid*/}
                              
                            </div>
                                  ))} 
                            <div class="modal-footer">
                            <button
                                type="submit"
                                class="btn btn-info "
                                className={this.state.inputClass}
                                
                              >
                                Submit
                              </button>
                              {/* <button
                                type="button"
                                class="btn btn-default cancel-button"
                                data-dismiss="modal"
                                onClick={this.handleRefreshPage}
                              >
                                Close
                              </button> */}
                             
                            </div>
                            </form> 
                          </div>
                        </div>
                      </div>
                      {/*End of  Counter Proposal Model */}
 

  

     </div>

 </div>   
  },
  

          ]
          
          

          const columns = [
            {
                title: 'Campain ID',
                dataIndex: 'campID',
                key: 'campID',
                  width: '8%',
                align:'center',
                sorter: (a, b) => a.campID - b.campID,
               render: (text,record) => 
               <div>
            <span style={{fontSize:"12px"}}>
        {record.parentCampID?record.parentCampID:text}
      </span>
           </div>
                
            },
            {
                title: 'Campaign Name',
                dataIndex: 'campaignName',
                key: 'campaignName',
                   width: '18%',
                 align:'center', 
                 sorter: (a, b) => {
                  const asc = 'asc';
                  if ((a.campaignName).toUpperCase() < (b.campaignName).toUpperCase()) {
                      return asc ? -1 : 1;
                  } else if ((a.campaignName).toUpperCase() > (b.campaignName).toUpperCase()) {
                      return asc ? 1 : -1;
                  } else {
                      return 0;
                  }
                }, 
                render: (text,record) => 
               <div >
                  <div class="dot1">
                
                  <a href="#" onClick={this.displayCampaignSpecification} campid={record.campID}  
                  campaignname={record.campaignName}  status="mainTab" parentcampid={record.parentCampID} reallocationid={record.reallocationID} pid={record.pID} canceltab={this.state.cancelTab}
                  style={{fontSize:"11px" ,wordBreak:"break-all"}}>{record.campaignName}</a>
                  </div>
                  <span style={{float:"left"}}>
                 <span style={{fontSize:"11px"}}>Days Left:&nbsp;
                 
                
                 {(record.DateDiff<=2)?<span style={{color:'red',fontWeight:"700"}}>{record.DateDiff}</span> : (record.DateDiff<=5 && record.DateDiff>2)?<span style={{color:'orange',fontWeight:"1000"}}>{record.DateDiff}</span> :<span style={{color:'green',fontWeight:"1000"}}>{record.DateDiff}</span>}
                 
                 </span> 

<br/>
                { this.state.userlogger=="PC"?  <span style={{fontSize:"11px"}}>CPL:  <span style={{fontWeight:"700"}}>{record.CPL}{record.currency}</span> </span> :""}
                </span>
                  
                    {/* <span style={{fontSize:"11px"}}>
                    {record.requiredLeadPerAsset==='Yes'?
                              <Progress percent={Math.round(record.totAcceptedLead/(record.totAcceptedLead+(record.allocatedLead - record.totAcceptedLead))*100)} size="small" status="active" />
                    :<Progress percent={Math.round(((record.qaReviewLead+record.acceptedLead)/record.allocatedLead)*100)} size="small" status="active" />}
                    </span>  */}
                    {this.state.tabName=="Paused"?<div><span style={{color:'red',float:"left",fontSize:'11px',fontWeight:'400'}}><b>Reason For Pause:</b>&nbsp;&nbsp;<label style={{color:'black', fontWeight:'400'}}>{record.reason}</label></span></div>:''}<br/>
            </div>
                
            },
            {
              title: 'Progress Status',
           
              children: [
                {
                  title:  <Tooltip placement="top" title="Creatives Status">Creative Approval</Tooltip>,
                  dataIndex: 'creativeApproval',
                  key: 'creativeApproval',
                  align:'center',
                   width:'6%',
                  render(text, record) {
                    return {
                        props: {
                        style: {wordWrap:"break-word",height:"5%"},
                      },
                        children: <div>
       
    {record.creativeApproval==="No"?
  <Progress
  type="circle"
  strokeColor="Red"
  percent={100}
  width={30}
  format={() => '0%'}

/>

    :


    <Progress type="circle" percent={100} width={30} />
                             }
                        </div>,
                    };
                  },
                
                },
                {
                  title: <Tooltip placement="top" title="Delivery Status">Delivery Status</Tooltip>,
                  dataIndex: 'deliveryStatus',
                  key: 'deliveryStatus',
                  align:'center',
                    width:'6%',
                  render(text, record) {
                    return {
                        props: {
                        style: {wordWrap:"break-word",height:"5%"},
                      },
                        children: <div>


                   {record.requiredLeadPerAsset==='Yes'?
                              <Progress type="circle" percent={Math.round(record.totAcceptedLead/(record.totAcceptedLead+(record.allocatedLead - record.totAcceptedLead))*100)} width={30} />
                    :<Progress  type="circle" percent={Math.round(((record.qaReviewLead+record.acceptedLead)/record.allocatedLead)*100)} width={30}/>}
                   
 {/* <Progress type="circle" percent={Math.round((record.deliveredLead*100)/record.leadAllocation)} width={30} /> */}

{/*                           
                     {record.deliveryStatus==="No"?
    <span style={{ height: "10px", width: "10px", backgroundColor:"red", borderRadius: "50%",display:"inline-block"}}></span>
    :
    <span style={{ height: "10px", width: "10px", backgroundColor:"green", borderRadius: "50%",display:"inline-block"}}></span>
                          } */}
    
                        </div>,
                    };
                  },
                },
             
              ],
            },
            // {
            //   title: 'Camp Type',
            //   dataIndex: 'ABM',
            //   key: 'ABM',
            //      width: '6%',
            //   align:'center',  
            //   render: (text,record) => 
            //   <div>{text}</div>
            // },

            {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'ABM',
             width: '7%',
             align:'center',  
             render: (text,record) => 
            <div >{text}</div>
            },

        
          {
          title: 'End Date',
          dataIndex: 'endDate',
          key: 'endDate',
           width: '7%',
           align:'center',  
           render: (text,record) => 
           <div >{text}</div>
         },
         {
          title: 'Total Leads',
          dataIndex: 'allocatedLead',
          key: 'allocatedLead',
           width:'5%',
          align:'right',  
           render: (text,record) => 
           <div >
          <span style={{fontSize:'12px'}}>{record.allocatedLead}</span>
          </div>
          },
          
      
              //   {
                  
              //   title: 'CPL',
              //  className: this.state.userlogger=="PC"? "show" : "hide",

              //  children: [
              //   {
              //     title: 'USD',
              //     dataIndex: 'CPL',
              //     key: 'CPL',
              //     align:'center',
              //   //  className: this.state.userlogger=="PC"? "show" : "hide",
              //     width: '6%',
              //     render: (text,record) => 
              //     <div >{text}<br/>{record.currency}</div>
              //   }
              //  ]
              // },
      
              {
        title: 'Delivery Status',
    
                children: [
                    {
                      title: 'Delivered',
                      dataIndex: 'deliveredLead',
                      key: 'deliveredLead',
                      align:'right',
                       width:'6%',
                      render:(text, record) =>
                      <div>
                      
                      {record.status==="Cancel"||record.campaignStatus==="Cancel Campaign"?0:
                            <div style={{fontSize:"12px"}}>
                      {((text?text:0)+(record.totalReject?record.totalReject:0))>0?
                        <a href="#" onClick={this.handleDeliveredDownload} id={record.campID} reallocationID={record.reallocationID} parentCampID={record.parentCampID?record.parentCampID:record.campID}>{((text?text:0)+(record.totalReject?record.totalReject:0))}</a>:0}
                     </div>}
                     </div>
                    },
                    {
                      title: 'Review Pending',
                      dataIndex: 'qaReviewLead',
                      key: 'qaReviewLead',
                      align:'right',
                       width:'6%',
                      render:(text, record) =>
                      <div>
                      {record.status==="Cancel"||record.campaignStatus==="Cancel Campaign"?0:
                            <div style={{fontSize:"12px",color:'green'}}>
                           
                       {(text)>0?<a href="#" style={{fontSize:"12px",color:'#37eb34'}} onClick={this.handleQALeadDownload}  id={record.campID} reallocationID={record.reallocationID} parentCampID={record.parentCampID?record.parentCampID:record.campID}>
                      {text} 
                       </a>:
                      <span style={{fontSize:"12px"}}>0</span> 
                       }

                                </div>
                                }
                                </div>
                    },
                    {
                        title: 'Accepted',
                        dataIndex: 'acceptedLead',
                        key: 'acceptedLead',
                        align:'right',
                          width:'6%',
                        render:(text, record) =>
                        <div>
                        {record.status==="Cancel"||record.campaignStatus==="Cancel Campaign"?0:
                            <div  style={{fontSize:"12px",color:'green'}} >
                       {text>0
                       ?
                       <a href="#" onClick={this.handleAcceptedDownload} style={{fontSize:"12px",color:'#37eb34'}}
                        id={record.campID} reallocationID={record.reallocationID} parentCampID={record.parentCampID?record.parentCampID:record.campID}>
                      
                     {text}
                        </a>:
                        <span style={{fontSize:"12px"}}>0</span> 
                      
                      }
                           </div>}
                           </div>
                      },
                      {
                        title: 'Rejected',
                        dataIndex: 'rejectedLead',
                        key: 'rejectedLead',
                        align:'right',
                         width:'6%',
                        render:(text, record) =>
                        <div>
                        {record.status==="Cancel"||record.campaignStatus==="Cancel Campaign"?0:
                            <div style={{fontSize:"12px"}}>
                              
                       {text>0?
                       <a href="#" onClick={this.handleRejectedDownload} style={{fontSize:"12px",color:'red'}} id={record.campID} reallocationID={record.reallocationID} parentCampID={record.parentCampID?record.parentCampID:record.campID}>{text}
                       </a>:0}
                                </div>}
                                </div>
                      },
                      {
                        title: 'Balanced',
                        dataIndex: 'totAcceptedLead',
                        key: 'totAcceptedLead',
                        align:'right',
                          width:'6%',
                        render(text, record) {
                            return {
                                props: {
                                style: {wordWrap:"break-word",height:"5%"},
                              },
                                children: 
                                <div>
                                  {record.status==="Cancel"||record.campaignStatus==="Cancel Campaign"?0:
                                  <div style={{fontSize:"12px"}}>
                                  {record.balancedLead}</div>}
                                  </div>
                                  ,
                            };
                          },
                      },
                  ],
              },
              {
                title: 'Action',
                dataIndex: 'allocatedLead',
                key: 'ActionFirst',
                 width:'10%',
                align:'center',
                render: (text,record,index) => 
                <div key={index}>
 {/* {record.status}
 {record.isApproveCreative} */}
 

    {record.status=="Accept" && record.isApproveCreative==="No" && record.campaignStatus!=="Cancel Campaign"?
    
                      <span style={{ display: 'inline-flex' }}>
                        {this.state.userlogger==='PQA'?
                         <Tooltip placement="top" title="Upload Creatives">
                            <img src="Upload_Creatives_Disabled.png" disabled width="30" height="30"></img>
                          </Tooltip>
                      :  <a href="#" id={record.campID}>
                          <Tooltip placement="top" title="Upload Creatives">
                            <img src="Upload_Creatives.png" id={record.campID} onClick={this.uploadCreative.bind(this)}
                              parentcampid={record.parentCampID} reallocationid={record.reallocationID} width="30" height="30"></img>
                          </Tooltip>
</a>}&nbsp;&nbsp;&nbsp;
    <span>
                          <Tooltip placement="topLeft" overlayStyle={{ color: 'white', backgroundColor: 'orange' }} title="Please Upload Creatives First">
                            <img src="Upload_Leads_Disabled.png" width="30" height="30"></img>
                          </Tooltip>
                        </span>&nbsp;&nbsp;&nbsp;
    <span>
      {this.state.userlogger==='PQA'?
      <img src="voiceLog_disable.png" id={record.campID}
       width="30" height="30"></img>
      :<span>
                          {(record.status == "Accept" && record.isApproveCreative === "No" && record.campaignStatus !== "Cancel Campaign") && (record.marketingChannel == "TeleMarketing" || record.marketingChannel == "Email/Telemarketing") ?
                            <Tooltip placement="top" title="Upload Voice Logs">
                              <img src="voice_log.png" id={record.campID} onClick={this.uploadVoiceLog.bind(this)}
                                parentcampid={record.parentCampID} reallocationid={record.reallocationID} width="30" height="30"></img>
                            </Tooltip>
                            : <Tooltip placement="topLeft" title="You can not upload voice logs as the campaign's marketing channel is not telemarketing" overlayStyle={{ color: 'white', backgroundColor: 'orange' }}>
                              <img src="voiceLog_disable.png" id={record.campID}
                                width="30" height="30"></img>
                            </Tooltip>}
                          </span>}
                        </span>
                      </span>
    :record.status=="Accept"  && record.isApproveCreative==="Yes" && record.campaignStatus!=="Cancel Campaign"?
    <span style={{display:'inline-flex'}}>
       {this.state.userlogger==='PQA'?
                         <Tooltip placement="top" title="Upload Creatives">
                            <img src="Upload_Creatives_Disabled.png" disabled width="30" height="30"></img>
                          </Tooltip>
     :<a href="#" id={record.campID} >
    <Tooltip placement="top" title="Upload Creatives">
    <img src="Upload_Creatives.png"  id={record.campID}  onClick={this.uploadCreative.bind(this)}
               parentcampid={record.parentCampID} reallocationid={record.reallocationID}  width="30" height="30"></img>
    </Tooltip>
</a>}&nbsp;&nbsp;&nbsp;
    {/* <Divider type="vertical" style={{float:'left'}} /> */}
     { record.status=="LP_Pending" && record.isApproveCreative==="Yes"?   
     <span>
     <Tooltip placement="topLeft" overlayStyle={{color: 'white', backgroundColor: 'orange'}} title="Please Upload Creatives First">
     <img src="Upload_Leads_Disabled.png"  
                 width="30" height="30"></img>
     </Tooltip>
     </span>
    :
    <span>
      {this.state.userlogger==='PQA'?
      <img src="Upload_Leads_Disabled.png"  
      width="30" height="30"></img>
    :<a href="#" id={record.campID} >
    <Tooltip placement="top" title="Upload Leads">
    <img src="Upload_Leads.png"  id={record.campID} parentcampid={record.parentCampID?record.parentCampID:record.campID}
                 reallocationid={record.reallocationID}
                 onClick={this.uploadLeds}  width="30" height="30"></img>
    </Tooltip>
    </a>}
    </span>
     } 
     &nbsp;&nbsp;&nbsp;
     <span>
       {this.state.userlogger==='PQA'?<img src="voiceLog_disable.png"  id={record.campID}  
                   width="30" height="30"></img>
      :<span>
       {record.internalReviewLead>0?
     (((record.status=="Accept" && record.isApproveCreative==="Yes" && record.campaignStatus!=="Cancel Campaign") && (record.marketingChannel=="TeleMarketing" || record.marketingChannel=="Email/Telemarketing"))?
   <Tooltip placement="top" title="Upload Voice Logs">
    <img src="voice_log.png"  id={record.campID}  onClick={this.uploadVoiceLog.bind(this)}
               parentcampid={record.parentCampID} reallocationid={record.reallocationID}  width="30" height="30"></img>
    </Tooltip>
      : <Tooltip placement="topLeft" title="You can not upload voice logs as the campaign's marketing channel is not telemarketing" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>

      <img src="voiceLog_disable.png"  id={record.campID}  
                width="30" height="30"></img>
      </Tooltip>)
      :<Tooltip placement="topLeft" title="Please upload leads " overlayStyle={{color: 'white', backgroundColor: 'orange'}}>

      <img src="voiceLog_disable.png"  id={record.campID}  
      width="30" height="30"></img>
      </Tooltip>}
      </span>}

    </span>
    </span>:
    
     record.status=="LP_Pending" && record.isApproveCreative==="No" && record.campaignStatus!=="Cancel Campaign"?  
     <span style={{display:'inline-flex'}}>
        {this.state.userlogger==='PQA'?
                         <Tooltip placement="top" title="Upload Creatives">
                            <img src="Upload_Creatives_Disabled.png" disabled width="30" height="30"></img>
                          </Tooltip>
     :<a href="#" id={record.campID}>
    <Tooltip placement="top" title="Upload Creatives">
    <img src="Upload_Creatives.png"  id={record.campID}  onClick={this.uploadCreative.bind(this)}
               parentcampid={record.parentCampID} reallocationid={record.reallocationID}  width="30" height="30"></img>
    </Tooltip>
       </a>}&nbsp;&nbsp;&nbsp;
    <span>
    <Tooltip placement="topLeft" overlayStyle={{color: 'white', backgroundColor: 'orange'}} title="Please Upload Creatives First">
    <img src="Upload_Leads_Disabled.png"  
                width="30" height="30"></img>
    </Tooltip>
    </span>&nbsp;&nbsp;&nbsp;

    <span>
    {this.state.userlogger==='PQA'?<img src="voiceLog_disable.png"  id={record.campID}  
                  width="30" height="30"></img>
      :
    <div>
    {record.internalReviewLead>0?
    (((record.status=="LP_Pending" && record.isApproveCreative==="No" && record.campaignStatus!=="Cancel Campaign") && (record.marketingChannel=="TeleMarketing" || record.marketingChannel=="Email/Telemarketing"))?
   <Tooltip placement="top" title="Upload Voice Logs">
    <img src="voice_log.png"  id={record.campID}  onClick={this.uploadVoiceLog.bind(this)}
               parentcampid={record.parentCampID} reallocationid={record.reallocationID}  width="30" height="30"></img>
    </Tooltip>
      : <Tooltip placement="topLeft" title="You can not upload voice logs as the campaign's marketing channel is not telemarketing" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>

      <img src="voiceLog_disable.png"  id={record.campID}  
                width="30" height="30"></img>
    </Tooltip>)
    :<Tooltip placement="topLeft" title="Please upload leads " overlayStyle={{color: 'white', backgroundColor: 'orange'}}>

    <img src="voiceLog_disable.png"  id={record.campID}  
                width="30" height="30"></img>
  </Tooltip>}
     </div>}
    </span>


     </span>
     : record.status=="LP_Pending" && record.isApproveCreative==="Yes" && record.campaignStatus!=="Cancel Campaign"?  
     <span style={{display:'inline-flex'}}>
        {this.state.userlogger==='PQA'?
                         <Tooltip placement="top" title="Upload Creatives">
                            <img src="Upload_Creatives_Disabled.png" disabled width="30" height="30"></img>
                          </Tooltip>
     :<a href="#" id={record.campID}>
   <Tooltip placement="top" title="Upload Creatives">
   <img src="Upload_Creatives.png"  id={record.campID}  onClick={this.uploadCreative.bind(this)}
              parentcampid={record.parentCampID} reallocationid={record.reallocationID}  width="30" height="30"></img>
   </Tooltip>
    </a>}&nbsp;&nbsp;&nbsp;
   {/* <Divider type="vertical" style={{float:'left'}} /> */}
   { record.status=="LP_Pending" && record.isApproveCreative==="Yes" && record.campaignStatus!=="Cancel Campaign"?  
    <span>
      
      <Tooltip placement="topLeft" overlayStyle={{color: 'white', backgroundColor: 'orange'}} title="Please Upload Creatives First">
    <img src="Upload_Leads_Disabled.png"  
                width="30" height="30"></img>
    </Tooltip>
    </span>


   :
   <div>
      {this.state.userlogger=='PQA'?
      <img src="Upload_Leads_Disabled.png"  
      width="30" height="30"></img>
      :
   <a href="#" id={record.campID} >
   <Tooltip placement="top" title="Upload Leads">
   <img src="Upload_Leads.png"  id={record.campID} parentcampid={record.parentCampID?record.parentCampID:record.campID}
                reallocationid={record.reallocationID}
                onClick={this.uploadLeds}  width="30" height="30"></img>
   </Tooltip>
   </a>
    }
   </div>
   } 
   &nbsp;&nbsp;&nbsp;
   <span>
   {record.internalReviewLead>0 || this.state.userlogger!=='PQA'?
    (((record.status=="LP_Pending" && record.isApproveCreative==="Yes" || record.campaignStatus!=="Cancel Campaign") && (record.marketingChannel=="TeleMarketing" || record.marketingChannel=="Email/Telemarketing"))?
   <Tooltip placement="top" title="Upload Voice Logs">
    <img src="voice_log.png"  id={record.campID}  onClick={this.uploadVoiceLog.bind(this)}
               parentcampid={record.parentCampID} reallocationid={record.reallocationID}  width="30" height="30"></img>
    </Tooltip>
      : <Tooltip placement="topLeft" title="You can not upload voice logs as the campaign's marketing channel is not telemarketing" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>

      <img src="voiceLog_disable.png"  id={record.campID}  
                 parentCampID={record.parentCampID} reallocationID={record.reallocationID}  width="30" height="30"></img>
      </Tooltip>):
      <Tooltip placement="topLeft" title="Please upload leads" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>

      <img src="voiceLog_disable.png"  id={record.campID}  
                 parentCampID={record.parentCampID} reallocationID={record.reallocationID}  width="30" height="30"></img>
      </Tooltip>}
    </span>

   
   </span>
    :record.status=="Paused"?  
    <span style={{display:'inline-flex'}}>
<span>
    <Tooltip placement="topRight" title="You can not upload creatives as the campaign is paused" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>
    <img src="Upload_Creatives_Disabled.png"   width="30" height="30"></img>
    </Tooltip>
    </span>&nbsp;&nbsp;&nbsp;
    {/* <Divider type="vertical" style={{float:'left'}} /> */}
    
    <span>
    <Tooltip placement="topLeft" title="You can not upload leads as the campaign is paused" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>
    <img src="Upload_Leads_Disabled.png"  width="30" height="30"></img>
    </Tooltip>
    </span>&nbsp;&nbsp;&nbsp;

  <span>

   <Tooltip placement="topLeft"  title="You can not upload voice logs as the campaign's marketing channel is not telemarketing" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>
      <img src="voiceLog_disable.png"  id={record.campID}  
                 parentCampID={record.parentCampID} reallocationID={record.reallocationID}  width="30" height="30"></img>
      </Tooltip>
      </span>
    </span>:
     record.status=="Completed"?  
     <span style={{display:'inline-flex'}}>
     <span>
    
    <Tooltip placement="topRight" title="You can not upload creatives as the campaign is completed" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>
    <img src="Upload_Creatives_Disabled.png" width="30" height="30"></img>
    </Tooltip>
   </span>&nbsp;&nbsp;&nbsp;
    {/* <Divider type="vertical" style={{float:'left'}} /> */}
    
    <span>
    <Tooltip placement="topLeft"  title="You can not upload leads as the campaign is completed" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>
    <img src="Upload_Leads_Disabled.png"   width="30" height="30"></img>
    </Tooltip>
   </span>&nbsp;&nbsp;&nbsp;

   <span>
   <Tooltip placement="topLeft"  title="You can not upload voice logs as the campaign's marketing channel is not telemarketing" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>

      <img src="voiceLog_disable.png"  id={record.campID}  
                 parentCampID={record.parentCampID} reallocationID={record.reallocationID}  width="30" height="30"></img>
      </Tooltip>
      </span>
     
     </span>

    :
    (record.status==="Live_Incomplete" && record.campaignStatus!=="Cancel Campaign") ||  (record.status==="PausedIncomplete" && record.campaignStatus!=="Cancel Campaign")||  (record.status==="Cancel ALU" && record.campaignStatus!=="Cancel Campaign")?  
    <span style={{display:'inline-flex'}}>
    <span>
      
   <Tooltip placement="topRight" overlayStyle={{color: 'white', backgroundColor: 'orange'}} title="You can not upload creatives as the campaign is completed">
   <img src="Upload_Creatives_Disabled.png"   width="30" height="30"></img>
   </Tooltip>
  </span>&nbsp;&nbsp;&nbsp;
   {/* <Divider type="vertical" style={{float:'left'}} /> */}
   
   <span>
   <Tooltip placement="topLeft" overlayStyle={{color: 'white', backgroundColor: 'orange'}} title="You can not upload leads as the campaign is completed">
   <img src="Upload_Leads_Disabled.png"   width="30" height="30"></img>
   </Tooltip>
  </span>&nbsp;&nbsp;&nbsp;

  <span>
  <Tooltip placement="topLeft"  title="You can not upload voice logs as the campaign's marketing channel is not telemarketing" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>

      <img src="voiceLog_disable.png"  id={record.campID}  
                 parentCampID={record.parentCampID} reallocationID={record.reallocationID}  width="30" height="30"></img>
      </Tooltip>
      </span>
     
    
    </span>
    :
       record.status==="Cancel" ||  record.campaignStatus==="Cancel Campaign"?  
       <span style={{display:'inline-flex'}}>
       <span>
      <Tooltip placement="topRight" overlayStyle={{color: 'white', backgroundColor: 'orange'}} title="You can not upload creatives as the campaign\allocation is cancelled">
      <img src="Upload_Creatives_Disabled.png"   width="30" height="30"></img>
      </Tooltip>
     </span>&nbsp;&nbsp;&nbsp;
      {/* <Divider type="vertical" style={{float:'left'}} /> */}
      
      <span>
      <Tooltip placement="topLeft" overlayStyle={{color: 'white', backgroundColor: 'orange'}} title="You can not upload leads as the campaign\allocation is cancelled">
      <img src="Upload_Leads_Disabled.png"   width="30" height="30"></img>
      </Tooltip>
     </span>&nbsp;&nbsp;&nbsp;
   
     <span>
     <Tooltip placement="topLeft"  title="You can not upload voice logs as the campaign\allocation is cancelled" overlayStyle={{color: 'white', backgroundColor: 'orange'}}>
   
         <img src="voiceLog_disable.png"  id={record.campID}  
                    parentCampID={record.parentCampID} reallocationID={record.reallocationID}  width="30" height="30"></img>
         </Tooltip>
         </span>
        
       
       </span>
        :
""}
    
     </div>
        
              },
          ];
    
    
    
    
        return (  
           
                <div>
                  <PublisherNavigation /> 
                <div class="container-fluid" style={{ paddingTop:'85px',paddingBottom:"10px" }}>
                <div style={{backgroundColor:'rgb(241, 241, 241)',paddingTop:'20px' }}>
                <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-10px'}}>
                <h3 style={{textIndent:'15px',fontFamily:'roboto'}}>Campaign Overview</h3>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"  style={{ paddingTop:'3px'}}>
                    <span class="float-right" style={{marginRight:"15px"}}>


                    {/* <div class="w3-dropdown-hover">
    <img src="https://www.google.com/search?q=image&rlz=1C1CHBD_enIN854IN854&tbm=isch&source=iu&ictx=1&fir=ImjCvcVywvDmVM%253A%252CLlgDpz1LoiuznM%252C_&vet=1&usg=AI4_-kSGLTam-9IPW3-IFmqNSK2MqArAqg&sa=X&ved=2ahUKEwi-7YX4lN_kAhW67nMBHR73BcMQ9QEwAHoECAUQBA#imgrc=ImjCvcVywvDmVM:"></img>
    <div class="w3-dropdown-content w3-bar-block w3-border">
      <a href="#" class="w3-bar-item w3-button">Link 1</a>
      <a href="#" class="w3-bar-item w3-button">Link 2</a>
      <a href="#" class="w3-bar-item w3-button">Link 3</a>
    </div>
  </div> */}


<span id="myText">All</span>
<div class="dropdown1">
<img src="Filter.png" height="20%" width="20%"></img>
  <div class="dropdown-content1">
  <a  href="#" value="All" onClick={this.handleChangeFilter}  name="All" id="All">All</a>
  <a  href="#" value="Monthly" onClick={this.handleChangeFilter}  name="Ending this month" id="Monthly">Ending this month</a>
    <a href="#" value="Weekly" onClick={this.handleChangeFilter}  name="Ending this week" id="Weekly">Ending this week</a>
  </div>
</div>
{/* 
                    <select  
                defaultValue={this.state.pacing}
               onChange={this.handleChange}
                id="pacing1"
                class="input-small"
                className="form-control"
                name="pacing" style={{width:'90px',height:'30px',fontSize:"11px",fontFamily:'roboto'}}>
                <option value="Yearly" >Yearly</option>
                <option value="Monthly" >Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Weekly" selected>Weekly</option>
                </select> */}

                    </span>
              <span class="float-right">

              </span>
                </div>
                </div>
                {/* end of row 1 */}

                <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
 {this.state.dashboardCampaignCount.map((dashboardCampaignCount,index) => (
                <div class="float-center topnav" key={index}>

<a href="#"  class={this.state.linkActive} onClick={this.campaignLink} id="Active">Active&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"green", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.activeCount}</span></a>
{/* &nbsp;&nbsp;&nbsp;&nbsp;
<a href="#"  class={this.state.linkActive1} onClick={this.campaignLink} id="Pending">Pending&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"Red", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.pendingCount}</span></a> */}
&nbsp;&nbsp;&nbsp;&nbsp;
<a href="#"  class={this.state.linkActive2} onClick={this.campaignLink} id="Paused">Paused&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"blue", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.pausedCount}</span></a>
&nbsp;&nbsp;
<a href="#" class={this.state.linkActive3} onClick={this.campaignLink} id="Completed">Completed&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"#484141", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.completeCount}</span></a>
&nbsp;&nbsp;&nbsp;&nbsp;
<a href="#" class={this.state.linkActive4} onClick={this.campaignLink} id="Cancel">Cancel&nbsp;<span style={{ height: "22px", width: "35px", backgroundColor:"#FF0000", borderRadius: "18%", fontSize:"16px",color:"white", textAlign:"center",display: "inline-block"}}>{dashboardCampaignCount.cancelCount}</span></a>
&nbsp;&nbsp;&nbsp;&nbsp;

                </div>
                ))}
                 </div>

                 <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginBottom:'10px',marginTop:"5px"}}>
                 <span class="float-right" style={{marginRight:"10px"}}>

                 {/* <input type="text" id="filterValue"></input> onkeyup="myFunction()" */}
                 <input type="text" 
style={{backgroundImage:'url(Group_294.png)',
backgroundPosition:'15px 6px',
backgroundSize:'15px 15px',
backgroundRepeat:"no-repeat",
width: '100%',
height:'20px',
fontSize:'10px',
border: '1px solid #ddd',
padding: '12px 20px 12px 34px',
border: '1px solid #ddd',
borderRadius:"25px",
backgroundColor: "rgb(221, 221, 221)"

}} 
                 onChange={this.handleChangeSearch} 
                  // onPressEnter={this.handleChangeSearch}
                 placeholder="Search..." title="Search"/>



                               </span>
                </div>
                </div>
                {/* end of row 2 */}
                 </div>              
                {/* <table id="example" class="display" width="100%"></table> */}
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{paddingTop:'5px'}}>
                {this.state.tableSearchText===""?
                <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.campaignDetails} columns={columns} 
                loading={this.state.loading} size="small"  className={tableCSS} scroll={{ y: 240 }}
                pagination={{ pageSize:this.state.pageSize,position:'bottom' }}                
                expandedRowRender={record =>                 
                  <div class="container-fluid">
                   
               <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
              
               {record.pacingUnit==""||record.pacingUnit==undefined||record.pacingUnit==null?"":
               <div>
                <label style={{fontSize:'12px'}}>Unit : {record.pacingUnit}</label>
               <table class="table table-bordered">
               <tr>
                 <th style={{fontSize:'12px'}}>
                   Pacing Month
                 </th>
                 {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                      
                      record.campID==pacingCampaignDetails.campID?
                 <td style={{fontSize:'12px'}}>
                            {pacingCampaignDetails.pacingMonth}

                 </td>
                  :""
                  )})}
                 </tr>

                 {record.pacingUnit=='Percentage'?
                 <tr>
                 <th style={{fontSize:'12px'}}>
                   Pacing (%)
                 </th>
                 {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                      
                      record.campID==pacingCampaignDetails.campID?
                 <td style={{fontSize:'12px'}}>
                            {pacingCampaignDetails.pacingPercentage}%

                 </td>
                  :""
                  )})}
                 </tr>
                 :
                 <tr>
                 <th style={{fontSize:'12px'}}>
                   Pacing Lead Count
                 </th>
                 {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                      
                      record.campID==pacingCampaignDetails.campID?
                 <td style={{fontSize:'12px'}}>
                            {pacingCampaignDetails.pacingLeadCount}

                 </td>
                  :""
                  )})}
                 </tr>
                 }
                 <tr>
                 <th style={{fontSize:'12px'}}>
                   Pacing End Date
                 </th>
                 {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                      
                      record.campID==pacingCampaignDetails.campID?
                 <td style={{fontSize:'12px'}}>
                          {pacingCampaignDetails.pacingEndDate}

                 </td>
                  :""
                  )})}
                 </tr>
                 <tr>
                 <th style={{fontSize:'12px'}}>
                   Pacing Carry Forward
                 </th>
                 {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                      
                      record.campID==pacingCampaignDetails.campID?
                 <td style={{fontSize:'12px'}}>
                            {pacingCampaignDetails.pacingCarryForward}

                 </td>
                  :""
                  )})}
                 </tr>
                 <tr>
                 <th style={{fontSize:'12px'}}>
                   Carry Forward Leads (Yes)
                 </th>
                 {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                      
                      record.campID==pacingCampaignDetails.campID?
                 <td style={{fontSize:'12px'}}>
                            {pacingCampaignDetails.carryLeadCount}

                 </td>
                  :""
                  )})}
                 </tr>
                 <tr>
                 <th style={{fontSize:'12px'}}>
                   Carry Forward Leads (No)
                 </th>
                 {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                      
                      record.campID==pacingCampaignDetails.campID?
                 <td style={{fontSize:'12px'}}>
                            {pacingCampaignDetails.carryLeadCountNo}

                 </td>
                  :""
                  )})}
                 </tr>
                 <tr>
                 <th style={{fontSize:'12px'}}>
                   Extra Leads Delivered
                 </th>
                 {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                      
                      record.campID==pacingCampaignDetails.campID?
                 <td style={{fontSize:'12px'}}>
                            {pacingCampaignDetails.leadCountAllocation}

                 </td>
                  :""
                  )})}
                 </tr>
                 {/* <tr>
                 <th>
                   Pacing Unit
                 </th>
                 {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                      
                      record.campID==pacingCampaignDetails.campID?
                 <td>
                            {pacingCampaignDetails.pacingUnit}

                 </td>
                  :""
                  )})}
                 </tr> */}

                 </table>
                 </div>
                 
                 }
                           
                     </div>
                     
                     <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                       {user.role!="PNC"?
                     <Tooltip placement="bottom" title='IO Download'>
                     <a href="#" id={record.campID}  onClick={this.downloadIODocument.bind(this)}>
                        <img src="download_io.png" height="30px" width="30px" id={record.campID}>
                          </img>
                      </a>
                      </Tooltip> :''}
              </div> 
              
      </div>
                  
               }
               ></Table>
:

<Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.campaignDetailsSearch} columns={columns} 
loading={this.state.loading}  size="small"  className={tableCSS} scroll={{ y: 240 }}
pagination={{ pageSize: this.state.pageSize,position:'bottom' }}
expandedRowRender={(record,key) => 
  <div class="container-fluid">
      <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
              
              {record.pacingUnit==""||record.pacingUnit==undefined||record.pacingUnit==null?"":
              <div>
               <label style={{fontSize:'12px'}}>Unit : {record.pacingUnit}</label>
              <table class="table table-bordered">
              <tr>
                <th style={{fontSize:'12px'}}>
                  Pacing Month
                </th>
                {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                     
                     record.campID==pacingCampaignDetails.campID?
                <td style={{fontSize:'12px'}}>
                           {pacingCampaignDetails.pacingMonth}

                </td>
                 :""
                 )})}
                </tr>

                {record.pacingUnit=='Percentage'?
                <tr>
                <th style={{fontSize:'12px'}}>
                  Pacing (%)
                </th>
                {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                     
                     record.campID==pacingCampaignDetails.campID?
                <td style={{fontSize:'12px'}}>
                           {pacingCampaignDetails.pacingPercentage}%

                </td>
                 :""
                 )})}
                </tr>
                :
                <tr>
                <th style={{fontSize:'12px'}}>
                  Pacing Lead Count
                </th>
                {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                     
                     record.campID==pacingCampaignDetails.campID?
                <td style={{fontSize:'12px'}}>
                           {pacingCampaignDetails.pacingLeadCount}

                </td>
                 :""
                 )})}
                </tr>
                }
                <tr>
                <th style={{fontSize:'12px'}}>
                  Pacing End Date
                </th>
                {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                     
                     record.campID==pacingCampaignDetails.campID?
                <td style={{fontSize:'12px'}}>
                         {pacingCampaignDetails.pacingEndDate}

                </td>
                 :""
                 )})}
                </tr>
                <tr>
                <th style={{fontSize:'12px'}}>
                  Pacing Carry Forward
                </th>
                {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                     
                     record.campID==pacingCampaignDetails.campID?
                <td style={{fontSize:'12px'}}>
                           {pacingCampaignDetails.pacingCarryForward}

                </td>
                 :""
                 )})}
                </tr>
                <tr>
                <th style={{fontSize:'12px'}}>
                  Carry Forward Leads (Yes)
                </th>
                {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                     
                     record.campID==pacingCampaignDetails.campID?
                <td style={{fontSize:'12px'}}>
                           {pacingCampaignDetails.carryLeadCount}

                </td>
                 :""
                 )})}
                </tr>
                <tr>
                <th style={{fontSize:'12px'}}>
                  Carry Forward Leads (No)
                </th>
                {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                     
                     record.campID==pacingCampaignDetails.campID?
                <td style={{fontSize:'12px'}}>
                           {pacingCampaignDetails.carryLeadCountNo}

                </td>
                 :""
                 )})}
                </tr>
                <tr>
                <th style={{fontSize:'12px'}}>
                  Extra Leads Delivered
                </th>
                {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                     
                     record.campID==pacingCampaignDetails.campID?
                <td style={{fontSize:'12px'}}>
                           {pacingCampaignDetails.leadCountAllocation}

                </td>
                 :""
                 )})}
                </tr>
                {/* <tr>
                <th>
                  Pacing Unit
                </th>
                {this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
                     
                     record.campID==pacingCampaignDetails.campID?
                <td>
                           {pacingCampaignDetails.pacingUnit}

                </td>
                 :""
                 )})}
                </tr> */}

                </table>
                </div>
                
                }
                          
                    </div>
                    
 <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                       {user.role!="PNC"?
                     <Tooltip placement="bottom" title='IO Download'>
                     <a href="#" id={record.campID}  onClick={this.downloadIODocument.bind(this)}>
                        <img src="download_io.png" height="30px" width="30px" id={record.campID} style={{float:"right"}}>
                          </img>
                      </a>
                      </Tooltip> :''}
              </div> 
</div>
  
}
>  
</Table>}
                </div>
                 {/* end of table row */}
                 <div class="row">
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-50px',marginLeft:'20px'}}>
       Records per page :&nbsp;
        <select  
                defaultValue={this.state.pageSize}
               onChange={this.handleChangePageSize}
                id="pacing1"
                class="input-small"
                className="form-control"
                name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                <option value="5" selected>5</option>
                <option value="10" >10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                </select>

                </div>
                        </div>
                    {/* end of pagination row */}
<br/>
                    <div class="row">
                    {this.state.userlogger==='PQA'?""
                     : <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                      {/* <h3 style={{fontFamily:'Lora'}}>Bidding</h3> */}
                      <div style={{ background: '#ECECEC', padding: '10px 15px 10px 15px' ,height:'430px' }}>
                      {/* <p> */}
                      <h3 style={{fontFamily:'roboto'}}>Bidding</h3>
                    {/* </p> */}
                       <br/>
                      <p>  
         

<Badge count={this.state.newCampaignLength} overflowCount={10} offset={[1,-5]}>
<Button  size="default" className={this.state.headExampleNewCampaign}   id="NewCampaign" onClick={this.biddingLink}>
         New
        </Button>
</Badge>




<Badge count={this.state.counterLength} overflowCount={10} offset={[1,-5]}>
<Button  size="default" className={this.state.headExampleCounter}   id="Counter" onClick={this.biddingLink}>
      Counter
        </Button>       
</Badge>


{user.role==="PC"?
<Badge count={this.state.RFPLength} overflowCount={10} offset={[1,-5]}>
<Button  size="default" className={this.state.headExampleRFP}   id="RFP" onClick={this.biddingLink}>
      RFP
        </Button>       
</Badge>:""
         }
       


</p>

   <p>    <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.biddingArray}   loading={this.state.loading1}
         columns={this.state.showRFPTBL===true?RFPColumn:columns1} className="ant-table-thead"   size="small" className={tableCSS} scroll={{ y: 200 }}
        pagination={{ pageSize: this.state.pageSize1 }}></Table>
  </p>
        <p>
        <div class="row">
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px',marginLeft:'5px'}}>
        Records per page:&nbsp;
        <select  
                defaultValue={this.state.pageSize1}
               onChange={this.handleChangePageSize1}
                id="pacing1"
                class="input-small"
                className="form-control"
                name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                <option value="5" selected>5</option>
                <option value="10" >10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                </select>

                </div>
                        </div>

        </p>
                   
                      </div>
                       </div>
                       }

                       <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                       <div style={{ background: '#ECECEC', padding: '10px 15px 10px 15px',height:'430px' }}>
                       <p>
                      <h3 style={{fontFamily:'roboto'}}>Review</h3>
                        </p>
                      <br/>
                          <p>  


                            
            <span class="badgelink">

 <Badge count={this.state.feedBackLength} overflowCount={10} offset={[1,-5]}>
<Button  size="default" className={this.state.headExample2} id="newsLetter" onClick={this.creativeLink}>
        Creatives
        </Button>
</Badge>


         
</span>
{ this.state.userlogger==="PC" || this.state.userlogger==="PQA"?
         
<span class="badgelink">
        <Badge count={this.state.leadsLength} overflowCount={10} offset={[1,-5]}>
       <Button size="default" id="leads" onClick={this.leadLink}  className={this.state.headExample3} >Leads</Button>
     </Badge> </span>

 :""}

 { this.state.userlogger=="PC"?
     
    
<span class="badgelink">
        <Badge count={this.state.monthCount} overflowCount={10} offset={[1,-5]}>
       <Button size="default" id="invoice" onClick={this.invoiceLink}  className={this.state.headExample4} >Invoice</Button>
     </Badge> </span>

 :""}    
 


{ this.state.userlogger=="PC"?
         
         <span class="badgelink">
                 <Badge count={this.state.othersLength} overflowCount={10} offset={[1,-5]}>
                <Button size="default" id="others" onClick={this.linkOthers}  className={this.state.headExample5} >Others</Button>
              </Badge> </span>
         
          :""} 


             </p>
             {this.state.reviewTabl1=='reviewCreatives'?<span>
      <p>  <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.reviewArray}   loading={this.state.loading2}
        columns={this.state.tabReview==='newsLetter'?columns2:''||this.state.tabReview==='leads'?columns23:''} className="ant-table-thead" className={tableCSS}  size="small" scroll={{ y: 200 }}
        pagination={{ pageSize: this.state.pageSize2 }}></Table></p>

 
        <p>


        <div class="row">
                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px',marginLeft:'5px'}}>
        Records per page:&nbsp;
        <select  
                defaultValue={this.state.pageSize2}
               onChange={this.handleChangePageSize2}
                id="pacing1"
                class="input-small"
                className="form-control"
                name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                <option value="5" selected>5</option>
                <option value="10" >10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                </select>

                </div>
                        </div> 
        </p></span>
        :""}

        {this.state.reviewTabl1=='invoiceTab'?
        <span>
             <p>  <Table {...this.state.tableState} id="myTable1" bordered dataSource={this.state.reviewArray}   loading={this.state.loading2}
             columns={this.state.tabReview==='invoice'?invoiceData:''||this.state.tabReview==='others'?columns3:''} className="ant-table-thead" className={tableCSS}  size="small" scroll={{ y: 200 }}
             pagination={{ pageSize: this.state.pageSize2 }}></Table></p>
     
             
     
             <p>
             <div class="row">
                           <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px',marginLeft:'5px'}}>
             Records per page:&nbsp;
             <select  
                     defaultValue={this.state.pageSize2}
                    onChange={this.handleChangePageSize2}
                     id="pacing1"
                     class="input-small"
                     className="form-control"
                     name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                     <option value="5" selected>5</option>
                     <option value="10" >10</option>
                     <option value="15">15</option>
                     <option value="20">20</option>
                     </select>
     
                     </div>
                             </div> 
             </p>
             </span>
             :""}
             {/* {this.state.ReviewTab=='ReviewCreatives'?
        <span>
             <p>  <Table {...this.state.tableState} id="myTable1" bordered dataSource={this.state.reviewArray}   loading={this.state.loading2}
             columns={columns3} className="ant-table-thead" className={tableCSS}  size="small" scroll={{ y: 200 }}
             pagination={{ pageSize: this.state.pageSize2 }}></Table></p>
     
             
     
             <p>
             <div class="row">
                           <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px'}}>
             Records per page:&nbsp;
             <select  
                     defaultValue={this.state.pageSize2}
                    onChange={this.handleChangePageSize2}
                     id="pacing1"
                     class="input-small"
                     className="form-control"
                     name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                     <option value="5" selected>5</option>
                     <option value="10" >10</option>
                     <option value="15">15</option>
                     <option value="20">20</option>
                     </select>
     
                     </div>
                             </div> 
             </p>
             </span>
             :""} */}

             
                    

                      </div>
                       </div>
                   
                        </div>
{/* end of bidding and review table */}


{/* Start of new footer */}

<MDBCard style={{ width: "22rem",display:'none',height:'239px',zIndex:-1, 
   position: 'fixed',
   display:this.state.show,
   width: '65%',
   height: '60%',
   top: '238px',
   left: '0',
   right: '0',
   bottom: '0',
   zIndex: 2,
   boxShadow:'5px 2px',
   background:'#F1F1F1'

  }} id="card1">
<MDBCardBody>

{/* {this.state.panelBlock} */}
{this.state.panelBlock==="message"?

   <MDBCardTitle style={{fontSize:'11px'}}>Unread Messages &nbsp; <input type="text" onkeyup="myFunction()"
style={{backgroundImage:'url(Group_294.png)',
backgroundPosition:'15px 6px',
backgroundSize:'15px 15px',
backgroundRepeat:"no-repeat",
width: '20%',
height:'20px',
fontSize:'10px',
border: '1px solid #ddd',
padding: '12px 20px 12px 34px',
border: '1px solid #ddd',
borderRadius:"25px",
backgroundColor: "#DDDDDD"

}} 
                 onChange={this.handleChangeSearch1}  onPressEnter={this.handleChangeSearch1}
                 placeholder="Search..." title="Search"/>
   <MDBBtn style={{left:'546px'}} onClick={this.closeChat}>&times;</MDBBtn></MDBCardTitle>
   :


<MDBCardTitle style={{fontSize:'11px'}}>Unread Alerts &nbsp; <input type="text" onkeyup="myFunction()"
style={{backgroundImage:'url(Group_294.png)',
backgroundPosition:'15px 6px',
backgroundSize:'15px 15px',
backgroundRepeat:"no-repeat",
width: '20%',
height:'20px',
fontSize:'10px',
border: '1px solid #ddd',
padding: '12px 20px 12px 34px',
border: '1px solid #ddd',
borderRadius:"25px",
backgroundColor: "#DDDDDD"


}} 
                 onChange={this.handleChangeSearch2} 
                  // onPressEnter={this.handleChangeSearch2}
                 placeholder="Search..." title="Search"/>
   <MDBBtn style={{left:'546px'}} onClick={this.closeChat}>&times;</MDBBtn></MDBCardTitle>

   

}



{this.state.panelBlock==="message"?

<MDBCardText>
{/* {this.state.panelBlock} */}
{this.state.tableSearchText1===""?
    <Table dataSource={this.state.unreadMessages} columns={columnUnreadMsg}  bordered

    loading={this.state.loading} size="small"  className={tableCSS} scroll={{ y: 200 }}
     pagination={{ pageSize: this.state.pageSize3 }} />
:
      <Table dataSource={this.state.columnUnreadMsgSearch} columns={columnUnreadMsg}  bordered

      loading={this.state.loading} size="small"  className={tableCSS} scroll={{ y: 200 }}
    pagination={{ pageSize: this.state.pageSize3 }} />}
<br/>
<p>
          <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px',fontSize:'12px',marginLeft:'15px'}}>
          Records per page:&nbsp;
          <select  
                  defaultValue={this.state.pageSize3}
                 onChange={this.handleChangePageSize3}
                  id="pacing1"
                  class="input-small"
                  className="form-control"
                  name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                  <option value="5" selected>5</option>
                  <option value="10" >10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  </select>
  
                  </div>
                          </div> 
          </p>


</MDBCardText>
:

<MDBCardText>
{/* {this.state.panelBlock} */}
{this.state.tableSearchText2===""?
<Table dataSource={this.state.unreadAlerts} columns={columnUnreadAlerts}  bordered

loading={this.state.loading} size="small"  className={tableCSS} scroll={{ y: 200 }}
expandedRowRender={record => 
  <span>
    {this.recordOpen(record.alertID,record.campID)}
  <table border='1' style={{tableLayout:"auto",borderCollapse:"collapse"}}>
  <tr>
     
     <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CN&nbsp;</b></th>
     <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;SD&nbsp;</b></th>
     <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;ED&nbsp;</b></th>
     <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;AL&nbsp;</b></th>
     <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CPL&nbsp;</b></th>
     </tr>
     <tr>
     <td style={{fontSize:'10px',width:'150px'}} class="word-wrap">&nbsp;{record.campaignName}</td>
     <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.startDate}</td>
     <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.endDate}</td>
     <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.allocatedLead}</td>
     <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.CPL}</td>
     </tr>
 
    {/* <tr>
     
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CN:&nbsp;</b></th>
<td style={{fontSize:'10px',width:'150px'}} class="word-wrap">&nbsp;{record.campaignName}</td>
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;SD:&nbsp;</b></th>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.startDate}</td>

<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;ED:&nbsp;</b></th>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.endDate}</td>
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;AL:&nbsp;</b></th>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.allocatedLead}</td>

<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CPL:&nbsp;</b></th>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.CPL}</td>
</tr> */}
</table>
</span>

  }

pagination={{ pageSize: this.state.pageSize4 }} />
:
<Table dataSource={this.state.columnUnreadAlertsSearch} columns={columnUnreadAlerts}  bordered

loading={this.state.loading} size="small"  className={tableCSS} scroll={{ y: 200 }}
expandedRowRender={record => 
  <table border='1' style={{tableLayout:"auto",borderCollapse:"collapse"}}>
    <tr>
     
     <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CN&nbsp;</b></th>
     <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;SD&nbsp;</b></th>
     <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;ED&nbsp;</b></th>
     <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;AL&nbsp;</b></th>
     <th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CPL&nbsp;</b></th>
     </tr>
     <tr>
     <td style={{fontSize:'10px',width:'150px'}} class="word-wrap">&nbsp;{record.campaignName}</td>
     <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.startDate}</td>
     <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.endDate}</td>
     <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.allocatedLead}</td>
     <td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.CPL}</td>
     </tr>
 
  {/* <tr>
   
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CN:&nbsp;</b></th>
<td style={{fontSize:'10px',width:'150px'}} class="word-wrap">&nbsp;{record.campaignName}</td>
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;SD:&nbsp;</b></th>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.startDate}</td>

<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;ED:&nbsp;</b></th>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.endDate}</td>
<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;AL:&nbsp;</b></th>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.allocatedLead}</td>

<th style={{fontWeight:'400',fontSize:'10px',width:'12px'}}><b>&nbsp;CPL:&nbsp;</b></th>
<td style={{fontSize:'10px',width:'90px'}}>&nbsp;{record.CPL}</td>
</tr> */}
</table>
  }

pagination={{ pageSize: this.state.pageSize4 }} />}
<br/>
<p>
          <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-60px',fontSize:'12px',marginLeft:'15px'}}>
          Records per page:&nbsp;
          <select  
                  defaultValue={this.state.pageSize4}
                 onChange={this.handleChangePageSize4}
                  id="pacing1"
                  class="input-small"
                  className="form-control"
                  name="pacing" style={{width:'65px',height:'30px',display: 'initial'}}>
                  <option value="5" selected>5</option>
                  <option value="10" >10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  </select>
  
                  </div>
                          </div> 
          </p>


</MDBCardText>

         }
   


  
 </MDBCardBody>
</MDBCard>



   <div class="footer">
  <div class="float-left"> 

  <button type="button"id="alerts" style={{backgroundColor:"#555",borderTop:"rgb(85, 85, 85)",
    borderLeft: "rgb(85, 85, 85)",
    borderBottom: "rgb(85, 85, 85)"}}  id="alerts" onClick={this.fadeInOut} >Unread Alerts  ({this.state.unreadAlertsLength})</button>
  
  <button type="button"id="messages" id="messages"style={{backgroundColor:"#555",border:" rgb(85, 85, 85)"}} onClick={this.fadeInOut} >Unread Messages  ({this.state.unreadMessagesLength})</button>
 

</div>
</div> 


{/* end of new footer */}


       
                  </div>
                  {/* end of container-fluid */}
                
                </div>
                //end of first div
                )
    }




}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      DasboardPublisher.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(DasboardPublisher)); 


