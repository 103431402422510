/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Somnath Keswad
@Creation Date:12-06-2019
@Description:Get All Campaign List
*/
import React from "react";
import Navigation from "../layouts/navPage";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { Table} from 'mdbreact';
import Footer from '../layouts/footer';
import { MDBRow} from "mdbreact";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import "./listAllCampaign.css";
import { saveAs } from 'file-saver';
const xlsx =require('xlsx');
const bigList=[
  { id: 'Africa', name: 'Africa' },
  { id: 'Asia', name: 'Asia' },
  { id: 'Countries of Australia  and Oceania(Island)', name: 'Countries of Australia  and Oceania(Island)' },
  { id: 'Europe', name: 'Europe' },
  { id: 'North America', name: 'North America' },
  { id: 'South America', name: 'South America' }
]
const statusList=[
  { id: 'Live', name: 'Live' },
  { id: 'Paused', name: 'Paused' },
  { id: 'Completed', name: 'Completed' }
]
class ListAllCampaign extends React.Component {
    constructor() {
        super();
        this.state = {
          newsuccess:"",
          invalidCampID:"",
          value: null,
          arrayValue: [],
          campStatus:[],
          status1:'',
          region1:'',
         
          currentDate:'',
          date:'',
          fileName:'CampaignDetails',
          startDate:'',
          endDate:'',
          selectedpublisherID:'',
          allCampaignList:[],
          allCampaignList1: [],
          showTable:'false',
          disableButton:'',
          tableStatus: '',
          fields: {},
          errors: {},
          dateerrors:{},
          puberror:{},
          publisherName1:'true',
          startDate1:'true',
          endDate1:'true',
          btnDisable:'disabled1',
      }
      this.handleChange = this.handleChange.bind(this);
      this.showCampDetails = this.showCampDetails.bind(this);
      this.selectMultipleRegion = this.selectMultipleRegion.bind(this);
      this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
      this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
      this.selectMultipleStatus=this.selectMultipleStatus.bind(this);
      this.downloadExcel=this.downloadExcel.bind(this);
    }// end of constrcutor 

    downloadExcel(e){
      var data=this.state.allCampaignList
      var downloadFiles=[];
    // alert(JSON.stringify(downloadFiles))
    for(var i=0; i<data.length;i++)
    {
      downloadFiles.push({'Campaign ID':data[i].parentCampID,'Allocation ID':data[i].reallocationID,'Agency Campaign ID':data[i].clientCampID,'Campaign Name':data[i].campaignName,'Region':data[i].region,'Start Date':data[i].startDate,'End Date':data[i].endDate,'Campaign Status':data[i].campaignStatus,'Total Leads':data[i].leadAllocation});
    }
    if(downloadFiles.length>0){
    var ws = xlsx.utils.json_to_sheet(downloadFiles);
    /* add to workbook */
    var wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Lead");
    
    // /* write workbook */
    var buf = xlsx.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
    var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});
    /* generate a download */
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
    var fileName="campaignDetails.xlsx";
    saveAs(new Blob([s2ab(buf)],{type:"application/octet-stream"}), fileName);
  }else{
  
    console.log("Data Not Exists");
  }
 }


    selectMultipleRegion(value) {
      
      var regionValue=[];
      for(var j=0;j<value.length;j++)
      {
        if(value[j].name=="Countries of Australia  and Oceania(Island)"){
          regionValue.push("Countries of Australia  and Oceania");
        }
        else{
          regionValue.push(value[j].name);
        }
    }
      this.setState({ arrayValue: value,region1:regionValue });
      //alert
    }
    selectMultipleStatus(value) {
      
      
      var statusValue=[];
      for(var j=0;j<value.length;j++)
      {
      //  alert("selectMultipleRegion"+ value[j].name);
      if(value[j].name=='Live'){
        statusValue.push('Active');
      }else{
        statusValue.push(value[j].name);
      }
    
      }
      this.setState({ campStatus: value,status1:statusValue });
      //alert
    }

    handleChangeStartDate(e) 
    {
        let fields = this.state;
        fields[e.target.name] = e.target.value;
        if(this.state.startDate1 ==='false')
        {
          this.setState({inputClassSdate: "valid", fields,inputClassEdate: "valid", fields});
        }
        else{
          this.setState({fields});    
    }
       var todate=document.getElementById('startDate').value;
       if(this.state.endDate=="" || this.state.endDate!=="")
       {
          this.setState({ endDate:todate});
       }
        
      }// handleChangeStartDate


      handleChangeEndDate(e) 
      {
          let fields = this.state;
          fields[e.target.name] = e.target.value;
          if(this.state.endDate1 ==='false')
            {
               this.setState({inputClassEdate: "valid", fields});
            }
          else{
               this.setState({fields});    
              }
             
          if(this.state.endDate!=="")
              {
                this.setState({ endDate:this.state.endDate});
              }
          
        }// handleChangeEndDate


    handleChange(e) 
    {
        let fields = this.state;
        fields[e.target.name] = e.target.value;
        this.setState({fields});
        // alert(this.state.campID)
     }// handleChange

 /*
    @author:Somnath Keswad
    @Creation Date:13/06/2019
    @Description:getting all info about campaign
    */
  showCampDetails(e) {
    e.preventDefault();
    // e.target.className += " was-validated";
    if (this.validateForm() === false) {
      var allCampaignList=[];
      this.setState({allCampaignList})
      return;
    }else{
    const { isAuthenticated, user } = this.props.auth;
    let campID=this.state.campID;
    let data = {
      startDate: this.state.fields.startDate,
      endDate: this.state.fields.endDate,
      agencyID:user.id,
      campID:this.state.campID,
      region:this.state.region1,
      status:this.state.status1
    };
    if(campID!==undefined || campID!=="" || campID!==null){
      let invalidCampID={};
      /** For invalid campID Validation */
      fetch("/reports/allCampaignReport",{
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
        }).then(res => res.json())
        .then(allCampaignList =>{
          this.setState({ allCampaignList: allCampaignList })
          if (this.state.allCampaignList==undefined ||this.state.allCampaignList==""||this.state.allCampaignList==null)
          {
              invalidCampID["campID"]="Invalid Campaign ID";
              this.setState({btnDisable:'disabled1',errors:''});
          }      
           else
                {
                   this.setState({btnDisable:'button'});
                }
        });
    }
    let newsuccess={};
    fetch("/reports/allCampaignReport",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
      }).then(res => res.json())
      .then(allCampaignList =>{
        this.setState({ allCampaignList: allCampaignList })
        // alert(this.state.allCampaignList)
        // if( (this.state.startDate=="") || (this.state.endDate=="") || (this.state.endDate<this.state.startDate))
        // {
        //     this.setState({newsuccess:''})
        // }
        //  else
          if (this.state.allCampaignList==undefined ||this.state.allCampaignList==""||this.state.allCampaignList==null)
        {
          //  alert("no data");
          newsuccess["records"]="No data exists";
            this.setState({ newsuccess:newsuccess,invalidCampID:'',btnDisable:'disabled1',errors:''});
        }      
         else
              {               
                this.setState({btnDisable:'button',newsuccess:'',invalidCampID:''});
              }
          }).catch(function (err) {
            console.log(err)
          });
    }
  }// end of showCampDetails

  
 validateForm()
 {
   //alert("Validate");
    let IsValid = true;
    let fields = this.state.fields;
    let regionValidate=true;
    let statusValidate=true;
    let errors = {};
   let dateerrors={};
    // let newsuccess={};
  
   
    let formIsValid = true;
    
 
    var pattern = new RegExp(/^[0-9\b]+$/);
  if(this.state.campID===undefined || this.state.campID==="" || this.state.campID===null){
    //alert("Camp id undefined")
    formIsValid=true;
    //errors["invalidCamp"] ='Please enter  Campaign ID';
  }else{
    //alert("Camp id nt in pattern")
    if (!pattern.test (this.state.campID))
    {
      formIsValid = false;
      // alert("Error")
      errors["invalidCamp"] = 'Please enter only numerical values for campaign id';
    }
  }
   
  this.setState(
    
    { errors: errors,
      dateerrors:dateerrors,
      newsuccess:'',
      showTable:false,
      alertBorder: '1px solid red',
      alertWidth: '1000px',
      alertAlign: 'center',
      alertDisplay: 'block'
    });


        
  return formIsValid;
 }// end of validate

 componentDidMount(){

   if (!this.props.auth.isAuthenticated) {
     this.props.history.push('/userLogin');
   }
   else{
   const { isAuthenticated, user } = this.props.auth;
   var agencyID=user.id;
   let self = this;
   let month="Monthly";
   let data={agencyID:agencyID,month:month}
   fetch("/reports/allCampaignReport",{
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
    }).then(res => res.json())
     .then(allCampaignList =>
       self.setState({allCampaignList:allCampaignList})
     ).catch(function (err) {
      console.log(err)
    });

  }
} // end of componentDidMount()
    render()
    {


      const { isAuthenticated, user } = this.props.auth;
      const asterisk =
        {

          color: 'red',

        }

            return(
                    <div>
                     <Navigation />
                <form method="" name="" onSubmit={this.showCampDetails}  className="needs-validation" encType="multipart/form-data"  noValidate> 
                  <div class="container-fluid" style={{ paddingTop: '90px', marginBottom: '60px' }}>
                  
                    <div style={{fontSize:'16px',color:'red',fontWeight:'600',textAlign:'center'}}>
                    {this.state.errors.commonError}
                    </div> 
                    <br/>
                    {/* start */}

                    <MDBRow lg="12">
                    <div class="col-lg-2">
                    <label id="label" style={{ fontSize: "16px",fontWeight:'20' }}>Campaign ID</label>
                      </div>
                      <div class="col-lg-2">
                      <label id="label"  style={{ fontSize: "16px" }}>Region </label>
                      </div>
                      <div class="col-lg-2">
                      <label id="label"  style={{ fontSize: "16px" }}>Status </label>
                      </div>
                      <div class="col-lg-2">
                      
                      <label id="label" style={{ fontSize: "16px",fontWeight:'20' }}>Campaign Created From</label>
                      
                      </div>
                      <div class="col-lg-2">
                      <label id="label" style={{ fontSize: "16px" }} >To</label>
                      </div>
                      </MDBRow>

                      <MDBRow lg="12">
                    <div class="col-lg-2">
                    <input type='TextBox' id='campID' class="form-control"name='campID'value={this.state.campID} placeholder={' Enter Campaign ID'} onChange={this.handleChange}style={{width:'160px'}}/>
                      </div>
                      <div class="col-lg-2">
                      <Picky value={this.state.arrayValue} options={bigList}  onChange={this.selectMultipleRegion}  open={false} valueKey="id"
className={this.state.inputClassDropDownRegion} labelKey="name"  multiple={true}  includeSelectAll={true}  includeFilter={true}
 dropdownHeight={300}  name="region"   id="region" />
                      </div>
                      <div class="col-lg-2">
                      <Picky value={this.state.campStatus} options={statusList} onChange={this.selectMultipleStatus} open={false} valueKey="id"
className={this.state.inputClassDropDownStatus} labelKey="name" multiple={true} includeSelectAll={true} includeFilter={true}
dropdownHeight={200} name="status" id="status" />
                      </div>
                      <div class="col-lg-2">
                      <input type="date" id="startDate"  style={{width:'160px'}}name="startDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                    value={this.state.startDate} className={this.state.inputClassSdate}
                                    onChange={this.handleChangeStartDate}
                                    class="form-control" min=""
                                    required/>
                                    <span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span>
                      </div>
                      <div class="col-lg-2">
                      
                      <input type="date" id="endDate" style={{ width: '160px' }} name="endDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                    value={this.state.endDate} className={this.state.inputClassEdate}
                                    onChange={this.handleChangeEndDate}
                                    class="form-control"
                                    required />
                                    <span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span>
                      </div>
                      </MDBRow>
                      <MDBRow>
                
                      <div class="col-lg-2">
                      <button class="btn  text-uppercase add-button"style={{float:'left',backgroundColor:"#2196F3",color:"white"}} disabled={this.state.disableButton} onClick={this.showCampDetails} type="submit" >
                              Search
                           </button>
                           &nbsp;&nbsp;&nbsp;&nbsp;
                           </div>
                      {/* <ReactToExcel  className={this.state.btnDisable} 
                          
                             table="myTable" filename={this.state.fileName + '_' 
                            + this.state.fields.startDate + '_'+this.state.fields.endDate}
                             sheet='sheet1' buttonText="Download"></ReactToExcel> */}
                             <div class="col-lg-2">
<button class="btn text-uppercase add-button" onClick={this.downloadExcel} type="submit" >
                              Download
                           </button>
                         </div>
                      {/* <div class="col-lg-2">
                      <label id="label" style={{ fontSize: "16px",fontWeight:'20' }}>Campaign Created From</label>
                      <input type="date" id="startDate"  style={{width:'160px'}}name="startDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                    value={this.state.startDate} className={this.state.inputClassSdate}
                                    onChange={this.handleChangeStartDate}
                                    class="form-control" min=""
                                    required/>
                                    <span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span>
                      </div>
                      <div class="col-lg-2">
                      <label id="label" style={{ fontSize: "16px" }} >To</label>
                      <input type="date" id="endDate" style={{ width: '160px' }} name="endDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                    value={this.state.endDate} className={this.state.inputClassEdate}
                                    onChange={this.handleChangeEndDate}
                                    class="form-control"
                                    required />
                                    <span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span>
                      </div> */}
                     
                      </MDBRow>
                      {/* <MDBRow lg="12">
                    <div class="col-lg-2">
                 
                      </div>
                      <div class="col-lg-2">
                      
                      </div>
                      <div class="col-lg-2">
                      
                      </div>
                      <div class="col-lg-2">
                     
                      </div>
                      <div class="col-lg-2">
                      
                      </div>
                      <div class="col-lg-2">
                 
                      </div>
                      </MDBRow> */}

                      
                      <MDBRow lg="12">
                    <div class="col-lg-2">
             
                      </div>
                      <div class="col-lg-2">
                      <span style={asterisk}> {this.state.errors.startDate} </span>
                      </div>
                      <div class="col-lg-2">
                      <span style={asterisk}> {this.state.dateerrors.endDate} </span>
                      </div>
                      <div class="col-lg-2">
                     
                      </div>
                      <div class="col-lg-2">
                      
                      </div>
                      <div class="col-lg-2">
                 
                      </div>
                      </MDBRow>
          <div style={{fontSize:'16px',color:'red',fontWeight:'600',textAlign:'center'}}>
                          {this.state.newsuccess.records}<br/>
                          {this.state.invalidCampID.campID}<br/>  
                          {this.state.errors.invalidCamp}
                        </div>


                         {/* {this.state.showTable === true? */}
                         <div id="campReport" class="row">

                         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                              <center>
                                <label style={{  float: 'center' }}> {this.state.publisher}</label>
                              </center>
                              {/* <div className="card card-signin my-1" >
                                <div className="card-body"> */}
                             
                                  <div class="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="table-responsive" >
              <Table scrollY  className=" table table-bordered table-striped " id="myTable">
                                        <thead>
                                          <tr class="custom-class">
                                            <th>Campaign ID</th>
                                            <th>Allocation ID</th>
                                            <th>Agency Camp ID</th>
                                            <th>Campaign Name</th>
                                            <th>Region</th>
                                            <th style={{width:"8%"}}>Start  Date</th>
                                            <th style={{width:"8%"}}>End   Date</th>
                                            <th >Status</th>
                                            <th>Total Leads</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.allCampaignList.map(allCampaignList => (
                                            <tr>
                                            <td class="campId">{allCampaignList.parentCampID?allCampaignList.parentCampID:allCampaignList.campID}</td>
                                            <td >{allCampaignList.reallocationID}</td>
                                            <td >{allCampaignList.clientCampID}</td>
                                            <td> {allCampaignList.campaignName}</td>
                                            <td> {allCampaignList.region}</td>
                                            <td>{allCampaignList.startDate}</td>
                                            <td>{allCampaignList.endDate}</td>
                                            <td>{allCampaignList.campaignStatus=='Active'?'Live':allCampaignList.campaignStatus}</td>
                                            <td>{allCampaignList.leadAllocation}</td>
                                            </tr>
                                               ))}
                                        </tbody>
                                        </Table>
                                      
                                    </div>
                                    {/*/ enf og lg-12*/}
                                  </div>{/*end of row*/}
                                  </div>
                                {/* </div>{/*end of card-body*/}
                              {/* </div>end of card card-signin my-1 */}
                         </div>
                         </div>

                     
                  {/* </div>Card-body */}
{/* // </div>Card */}
</div>{/*Container */}
 </form> 
 <Footer />
</div>
            );
    }// end of renders
}// end of class


/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
ListAllCampaign.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(ListAllCampaign));

