/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/**
* @file ApproveOnBoardAdvertiser
* @author Sanjana Godbole
* @version 1.0
* @section use for admin to approve Agency
**/

import React from 'react';
import AdminNavpage from '../layouts/adminNavpage.js';
import Footer from '../layouts/footer';
import { FaArrowAltCircleLeft } from "react-icons/fa";


import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Somnath Task-3930-added default header
const Swal = require('sweetalert2')
// const queryString = require('query-string'); //Nilesh-4754-Removing console warnings
// var str=[]; //Nilesh-4754-Removing console warnings
// var newstr=[];  //Nilesh-4754-Removing console warnings
// let gdprnamesArray = []; //Nilesh-4754-Removing console warnings

class ApproveOnBoardAdvertiser extends React.Component {

  constructor()
  {
    super();
    
      
    this.state = {
      basicInfo:[],
      contactInfo:[],
      //bankInfo:[],
      successMessage:'',
      newfailure:'',
      buttonDisplay:'btn add-button',
      Color:"#2196F3",
      bcolor:"#2196F3",
      gdprnames:[]

    };
    this.handleBackButton=this.handleBackButton.bind(this);
    this.handleApproveButton=this.handleApproveButton.bind(this);
  }


  handleBackButton(e){
    e.preventDefault(); //Nilesh-4754-Removing console warnings
    window.location.href = '/reviewAdvertiser';

    }

  

    handleApproveButton(e){

      var orgID=e.target.id;
      //const {user} = this.props.auth;
      let data={
        orgID:orgID,
       // user:user
      };
      this.setState({
        buttonDisplay:'disabled',color:'#827d7d',bcolor:'#827d7d'});

       fetch("/admin/approveAdvertiser",{
        method:'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)

      })
        .then(res => res.json())
        .then(res=>{
            if(res.success === true)  //Nilesh-4754-Removing console warnings
            {
           
              var successMessage=(res.message);
              var email=(res.email);
              this.setState({successMessage:successMessage,email:email});
              Swal.fire({
          
               title:(successMessage+"(Advertiser ID:"+this.state.orgID+")"),
               text: ("Login Details are sent to "+email),   
               confirmButtonText: 'Ok',
               allowOutsideClick: false,
               preConfirm: () => {
                 //window .location.reload();
                 window.location.href ='/reviewAdvertiser';
                
               }
             })

              //window.location.href = '/approvedAgency?orgID='+orgID+'&successMessage='+this.state.successMessage+'&email='+this.state.email;

      
             }
        }).catch(function (err) {console.log(err)});

      
             

    }

    componentDidMount() {

      if(!this.props.auth.isAuthenticated) {
        this.props.history.push('/userLogin');
      }
      else{
      var parsed = this.props.location.state; //Somnath Task-3930-replace query params
      var orgID=parsed.orgID;
      this.setState({ orgID:orgID});


      fetch("admin/getAdvertiserInfo?orgID="+orgID)
        .then(res => res.json())
        .then(basicInfo =>{
         this.setState({ basicInfo: basicInfo })
         
        }).catch(function (err) {console.log(err)});

        fetch("/admin/getContactInfo?orgID="+orgID)
        .then(res => res.json())
        .then(contactInfo =>{

         this.setState({ contactInfo: contactInfo })
        }).catch(function (err) {console.log(err)});

        // fetch("/admin/getBankInfo?orgID="+orgID)
        // .then(res => res.json())
        // .then(bankInfo =>{

        //  this.setState({ bankInfo: bankInfo })
        // }).catch(function (err) {console.log(err)});

      }   

    }


   render() {
      return (
         <div>
             <AdminNavpage/>
          

<div class="row"  style={{paddingTop:"90px",paddingBottom:'6px',backgroundColor:'white',width:'100%',position:'fixed',zIndex:'1'}}>
    <div class=" col-lg-4">
    <div class="float-left">&nbsp;&nbsp;&nbsp;&nbsp;
    <FaArrowAltCircleLeft
     size={32} 
    //  style={{float:'left'}} 
     title="Back To Review Advertiser"
     onClick={this.handleBackButton}
     style={{color: '#056eb8', float:'left !important'}} //Nilesh-4754-Removing console warnings
     /></div>
    </div>

    <div class= "col-lg-4" >  <center><h4 style={{color:'#056EB8',fontSize:'20px'}}>Review Advertiser Details</h4></center></div>
    <div class=" col-lg-4">
    <div class="float-right">
    <button class="btn add-button" type="submit" className={this.state.buttonDisplay}  onClick={this.handleApproveButton} id={this.state.orgID} form="" >Approve</button>    
    </div>   
        
    </div>

</div>
<br/>
<div className="container-fluid" style={{paddingTop:"90px",paddingBottom:'60px'}}>

         


            <div class="row" style={{paddingTop:"30px"}}>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="legend labelsubheading">
                           <h4 style={{color:'orange'}}>Advertiser Information</h4><hr/>
            </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <table class="table table-bordered table-striped">
    <thead>
      <tr class="custom-class">
      <th>Advertiser ID</th>
        <th>Advertiser Name</th>
        <th>Website</th>
        <th>Email</th>
        <th>Phone Number</th>
        <th>Country</th>
        <th>State</th>
        <th>City</th>
        <th>Zip/Postal Code</th>
        <th>Delivery Channel</th>
      </tr>
    </thead>
    <tbody>
    {this.state.basicInfo.map(
              basicInfo => (
      <tr>
         <td> {this.state.orgID} </td>
        <td> {basicInfo.advertiserName} </td>
        <td> {basicInfo.website}  </td>
        <td> {basicInfo.email}  </td>
        <td>   {basicInfo.phone}  </td>
        <td> {basicInfo.country} </td>
        <td> {basicInfo.state} </td>
        <td> {basicInfo.city} </td>
        <td>    {basicInfo.zipcode} </td>
        <td> {basicInfo.dcTelemarketing === 'Yes'?  //Nilesh-4754-Removing console warnings
                                   <span style={{fontWeight: 'bold'}}>Telemarketing &nbsp;</span>:''}
                                   {basicInfo.dcEmail === 'Yes'? //Nilesh-4754-Removing console warnings
                                   <span style={{fontWeight: 'bold'}}>E-Mail &nbsp;</span>:''}
                                   {basicInfo.dcSocial === 'Yes'?  //Nilesh-4754-Removing console warnings
                                   <span style={{fontWeight: 'bold'}}>Social &nbsp;</span>:''}
                                   {basicInfo.dcDisplay === 'Yes'? //Nilesh-4754-Removing console warnings
                                   <span style={{fontWeight: 'bold'}}>Display &nbsp;</span>:''}
                                   {basicInfo.dcProgrammatic === 'Yes'? //Nilesh-4754-Removing console warnings
                                   <span style={{fontWeight: 'bold'}}>Programmatic &nbsp;</span>:'' 
                                   } </td>
      
      </tr>
        ))}
         </tbody>
  </table>

              </div>
              </div>

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
       <div class="row" style={{paddingTop:"30px"}}>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="legend labelsubheading">
                           <h4 style={{color:'orange'}}>Contact Information</h4><hr/>
            </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <table class="table table-bordered table-striped">
    <thead>
      <tr class="custom-class">
        <th>Contact Person</th>
        <th>Designation </th>
        <th>Email</th>
        <th>Phone Number</th>
        
      </tr>
    </thead>
    <tbody>
    {this.state.contactInfo.map(
              contactInfo => (
      <tr>
        <td> {contactInfo.firstName}  {contactInfo.lastName} </td>
        <td> {contactInfo.designation}  </td>
        <td> {contactInfo.email}  </td>
        <td>   {contactInfo.phoneNo}  </td>
       
      
      </tr>
        ))}
         </tbody>
  </table>

              </div>
              </div>

{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

           
{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
{/* 
<div class="row" style={{paddingTop:"30px"}}>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="legend labelsubheading">
                           <h4 style={{color:'orange'}}>Bank Details</h4><hr/>
            </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <table class="table table-bordered table-striped">
    <thead>
      <tr class="custom-class">
        <th>Bank Name</th>
        <th>Account Number </th>
        <th>Accountholder Name</th>
        <th>IFSC Number</th>
        <th>PAN/TAN Number</th>
        <th>TAX/GST Number</th>
        
      </tr>
    </thead>
    <tbody>
    {this.state.bankInfo.map(
              bankInfo => (
      <tr>
        <td> {bankInfo.bankName}  </td>
        <td>{bankInfo.accountNumber} </td>
        <td>{bankInfo.accountHolderName}</td>
        <td> {bankInfo.ifscCode}  </td>
        <td>{bankInfo.panNumber} </td>
        <td>{bankInfo.gstNumber}   </td>
       
      
      </tr>
        ))}
         </tbody>
  </table>

              </div>
              </div> */}


{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            </div>
            {/* //end of container */}
            <Footer/>
         </div>
         //end of main div
      );
   }
}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      ApproveOnBoardAdvertiser.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
 export default connect(mapStateToProps, { logoutUser })(withRouter(ApproveOnBoardAdvertiser)); 