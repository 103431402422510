/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file adminNavpage
 * @author Raunak Thakkar
 * @version 1.0
 * @section use for admin
 */
import React, { Component } from "react";
//import { BrowserRouter as Router, Route, Link } from "react-router-dom";	//Chaitanya-4751-Removed Console Warning
//import { DropdownMenu, MenuItem } from "react-bootstrap-dropdown-menu";	//Chaitanya-4751-Removed Console Warning
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";

class adminNavpage extends Component {
	onLogout(e) {
		e.preventDefault();
		const {user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		let data = {
			user: user,
		};
		fetch("users/userLogout", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {})
			.catch((e)=>console.log(e));//Somnath Task-3930, Handle the error
		this.props.logoutUser(this.props.history);
		localStorage.removeItem("activeKey");
	}

	onChangePasswordAdmin(e) {
		e.preventDefault();
		//const { isAuthenticated, user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		this.props.history.push("/changePasswordAdmin")//snehal-task-3927-VAPT URL changes
		localStorage.removeItem("activeKey");
	}

	onViewUserPassword(e) {
		e.preventDefault();
		//const { isAuthenticated, user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		this.props.history.push("/viewUserPassword")//snehal-task-3927-VAPT URL changes
		localStorage.removeItem("activeKey");
	}
	// onAddUser(e)
	// {
	//   e.preventDefault();
	//   const {isAuthenticated, user} = this.props.auth;
	//   window.location.href="/addUserAdmin?adminID="+user.id;
	//   localStorage.removeItem('activeKey');
	// }

	/**
	 * @author Sunita Landge
	 * @param  Description Add admin home page icon
	 * @return Description return back to admin dashboard
	 */
	onHome(e) {
		e.preventDefault();
		//const { isAuthenticated, user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		this.props.history.push("/adminDashboard");//Somnath Task-3930, remove adminID from URL
		// window.location.href = "/adminDashboard?adminID=" + user.id;
		localStorage.removeItem("activeKey");
	}

	onClientSetup(e) {
		e.preventDefault();
		//const { isAuthenticated, user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		this.props.history.push("/clientSetup")//snehal-task-3927-VAPT URL changes
		localStorage.removeItem("activeKey");
	}
	onMarketoClientSetup(e) {
		e.preventDefault();
		//const { isAuthenticated, user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		this.props.history.push("/marketoClientSetup")//snehal-task-3927-VAPT URL changes
		localStorage.removeItem("activeKey");
	}
	onHubspotClientSetup(e) {
		e.preventDefault();
		//const { isAuthenticated, user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		this.props.history.push("/hubspotClientSetup")//snehal-task-3927-VAPT URL changes
		localStorage.removeItem("activeKey");
	}

	/**
	 * @author Karan Jagtap
	 * @param  Description Task-3453 - handles Add Country menu click
	 * @return Description – re-directs to new route /addCountry
	 */
	onAddCountry(e) {
		e.preventDefault();
		//const { isAuthenticated, user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		this.props.history.push("/addCountry")//snehal-task-3927-VAPT URL changes
		localStorage.removeItem("activeKey");
	} // end of onAddCountry function

	
	/**
	 * @author Rutuja Jagtap
	 * @param  Description Task-4722 - handles Add state menu click
	 * @return Description – re-directs to new route /addState
	 */
	onAddState(e) {
		e.preventDefault();
		//const { isAuthenticated, user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		this.props.history.push("/addState")
		localStorage.removeItem("activeKey");
	} 

	
	/**
	 * @author Rutuja Jagtap
	 * @param  Description Task-4357 - handles  Change user status of email
	 * @return Description – re-directs to new route /changeUserStatus
	 */
	 onChangeUserStatus(e) {
		e.preventDefault();
		//const { isAuthenticated, user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		this.props.history.push("/changeUserStatus")
		localStorage.removeItem("activeKey");
	} // end of onAddCountry function

	generateReports(e) {
		e.preventDefault();
		//const { isAuthenticated, user } = this.props.auth;	// Chaitanya-4751-Removed Console Warning
		this.props.history.push("/generateReports")
		localStorage.removeItem("activeKey");
	} 
	//kiran-4721-added new function for generateReports

	render() {
		return (
			<div>
				<nav className="navbar navbar-expand-md navbar-dark navbar-fixed-top">
					<img
						src="DemandIntegrateLogo_White.png"
						alt="Demand Integrate"
						height="50px"
						width="200px"
						id="navbar_admin_navpage_logo_css"></img>
					<button
						class="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent2">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div
						className="collapse navbar-collapse"
						id="navbarSupportedContent2">
						<ul class="navbar-nav navbar-right">
							<li class="nav-item">
								<a
									class="abc nav-link"
									style={{ color: "aliceblue", fontSize: "16px" }}
									href="#/"	// Chaitanya-4751-Removed Console Warning
									onClick={this.onHome.bind(this)}>
									{" "}
									<span
										class="glyphicon glyphicon-home"
										style={{ color: "aliceblue" }}></span>
									&nbsp;HOME{" "}
								</a>
							</li>
							&nbsp;
							<li>
								<a
									class="abc nav-link"
									style={{ color: "aliceblue", fontSize: "16px" }}
									href="./addCompliance">
									ADD COMPLIANCE{" "}
								</a>
							</li>
							&nbsp;
							<li class="nav-item">
								<a
									class="abc nav-link"
									style={{ color: "aliceblue", fontSize: "16px" }}
									href="./linkPublisher">
									LINK PUBLISHER{" "}
								</a>
							</li>
							&nbsp;
							<li class="nav-item">
								<a
									class="abc nav-link"
									style={{ color: "aliceblue", fontSize: "16px" }}
									href="./linkAdvertiser">
									LINK ADVERTISER{" "}
								</a>
							</li>
							&nbsp;
							<li class="nav-item dropdown">
								<a
									class="abc nav-link dropdown-toggle dropbtn"
									href="./reportByPublisher"
									id="navbardrop"
									data-toggle="dropdown">
									REVIEW
								</a>
								<div class="dropdown-content dropdown-menu">
									<a class="dropdown-item" href="./reviewPublisher">
										Publisher
									</a>
									<a className="dropdown-item" href="./reviewAgency">
										Agency
									</a>
									<a className="dropdown-item" href="./reviewAdvertiser">
										Advertiser
									</a>
									{/* karan-task-3648-added publisher request menu */}
									<a className="dropdown-item" href="./publisherRequest">
										Publisher Request
									</a>
									{/* kiran- 4623- added new submenu under review section at Admin side*/}
									<a className="dropdown-item" href="./leadRejection">
										Lead Rejection
									</a>

									{/* <a className="dropdown-item" href="./publisherAnalysisDashbord">Publisher Dashboard</a> */}
								</div>
							</li>
							<li class="nav-item dropdown">
								<a
									href=" nav-link "
									class="abc nav-link dropdown-toggle dropbtn"
									style={{ color: "aliceblue" }}
									id="navbardrop"
									data-toggle="dropdown">
									<span
										class="	glyphicon glyphicon-cog"
										style={{ color: "aliceblue" }}></span>
									{/* MY ACCOUNT */}
								</a>
								<div class="dropdown-content dropdown-menu">
									<div>
										<a
											class="dropdown-item"
											href="onChangePasswordAdmin"
											onClick={this.onChangePasswordAdmin.bind(this)}>
											Change Password
										</a>
										<a
											class="dropdown-item"
											href="onViewUserPassword"
											onClick={this.onViewUserPassword.bind(this)}>
											View User Password
										</a>
										{/* <a class="dropdown-item" href="#" onClick={this.onAddUser.bind(this)}>Add User</a> */}
										<a
											class="dropdown-item"
											href="onClientSetup"
											onClick={this.onClientSetup.bind(this)}>
											Salesforce Client Setup
										</a>
										<a
											class="dropdown-item"
											href="onMarketoClientSetup"
											onClick={this.onMarketoClientSetup.bind(this)}>
											Marketo Client Setup
										</a>
										<a
											class="dropdown-item"
											href="onHubspotClientSetup"
											onClick={this.onHubspotClientSetup.bind(this)}>
											Hubspot Client Setup
										</a>
										{/** karan-3453-added new menu item */}
										<a
											class="dropdown-item"
											href="onAddCountry"
											onClick={this.onAddCountry.bind(this)}>
												{/* rutuja task- 4622 change title from add country to add city */}
											Add City 
										</a>
										{/** rutuja-4722-added new menu item */}
										<a
											class="dropdown-item"
											href="onAddState"
											onClick={this.onAddState.bind(this)}>
												{/* rutuja task- 4622 change title from add country to add city */}
											Add State / province / county
										</a>
										{/** rutuja-4357-added new menu item change user status */}
										<a
											class="dropdown-item"
											href="onChangeUserStatus"
											onClick={this.onChangeUserStatus.bind(this)}>
											Change User Status
										</a>
										{/* kiran-4721-added new submenu */}
										<a
											class="dropdown-item"
											href="./generateReports"
											onClick={this.generateReports.bind(this)}
											>
											Generate Reports
										</a>
									</div>
								</div>
							</li>
							&nbsp;&nbsp;&nbsp;
							<li class="nav-item">
								<a
									class="abc nav-link"
									style={{ color: "aliceblue" }}
									href="onLogout"
									onClick={this.onLogout.bind(this)}>
									{" "}
									<span
										class="glyphicon glyphicon-log-out"
										style={{ color: "aliceblue" }}></span>
								</a>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		);
	}
}
// export default  adminNavpage;
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
adminNavpage.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(adminNavpage)
);
