/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file Publisher request page
 * @author  Karan Jagtap
 * @version 1.0
 * @section Publisher request page
 * */
import React from "react";
import Navigation from "../layouts/adminNavpage";
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../login/authentication";
import { css } from "emotion";
import { Table } from "antd";
import "./PublisherRequest.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Somnath Task-3930-added default header
const Swal = require("sweetalert2");
const TABLE_CSS = css({
	borderStyle: "bold !important",
	backgroundColor: "white !important",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "12px",
		textAlign: "center",
		fontWeight: "500",
	},
	"& thead > tr > th": {
		textAlign: "center",
		color: "white",
		//padding: "8px 10px",
		backgroundColor: "transparent",
	},
	"& tbody > tr > td": {
		fontSize: "12px",
	},
	"& .ant-table-scroll .ant-table-body": {
		overflowX: "auto !important",
	},
	"& thead > tr > th .ant-table-header-column .ant-table-column-sorters .ant-table-column-sorter":
		{ display: "table-cell !important" },
	"& .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full":
		{
			marginTop: "-0.5em",
		},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-down.ant-table-column-sorter-down.on > svg":
		{
			backgroundColor: "transparent !important",
			color: "white !important",
		},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-up.ant-table-column-sorter-up.on > svg":
		{
			backgroundColor: "transparent !important",
			color: "white !important",
		},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-up.ant-table-column-sorter-up.off > svg":
		{
			backgroundColor: "transparent !important",
			color: "#6a88bb !important",
		},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-down.ant-table-column-sorter-down.off > svg":
		{
			backgroundColor: "transparent !important",
			color: "#6a88bb !important",
		},
});

/**
 * @author Karan Jagtap
 * @description Task-3648 Main class for PublisherRequest.js file
 */
class PublisherRequest extends React.Component {
	constructor(props) {
		super();
		this.state = {
			publisherList: [],
			loading: true,
		};
	}

	/**
	 * @author Karan Jagtap
	 * @description Task-3648-getting auth status and publisher list
	 */
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			this.setState({ loading: true });
			this.fetchPublisherList();
		}
	}

	/**
	 * @author Karan Jagtap
	 * @description Task-3648-gets publisher list
	 * @route /agencyOnBoardDetails/getNewPublisher
	 */
	fetchPublisherList = () => {
		fetch("/agencyOnBoardDetails/getNewPublisher", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((res) => {
				this.setState({
					publisherList: this.addKeysToEachObject(res),
					loading: false,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ loading: false });
			});
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3648 add keys to data
	 */
	addKeysToEachObject = (data) => {
		let res = [];
		res = data.map((item, i) => {
			return {
				...item,
				key: i,
			};
		});
		return res;
	};

	/**
	 * @author Karan Jagtap
	 * @description Task-3648-handles complete action button click
	 */
	onCompleteBtnClick = (e, record) => {
		e.preventDefault();

		const data = {
			id: record.id,
			// user: this.props.auth.user,
			email: record.email,
		};
		/**
		 * @author Karan Jagtap
		 * @description Task-3648-reviews publisher and sends email
		 * @route /agencyOnBoardDetails/ReviewPublisher
		 */
		fetch("/agencyOnBoardDetails/ReviewPublisher", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				this.fetchPublisherList();
				Swal.fire({
					text: res.msg,
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
					heightAuto: false,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	render() {
		// all antd table configurations are in this array
		const TABLE_COLUMNS = [
			{
				title: "Publisher ID",
				dataIndex: "id",
				key: "id",
				width: "120px",
				sorter: (a, b) => a.id - b.id,
			},
			{
				title: "Publisher Name",
				dataIndex: "publisherName",
				key: "publisherName",
				width: "160px",
				sorter: (a, b) =>
					a.publisherName.toLowerCase() < b.publisherName.toLowerCase()
						? -1
						: 1,
				render: (text, record) => (
					<div style={{ width: 160 }}>
						<div
							style={{
								display: "block",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								minWidth: 160,
							}}>
							{text}
						</div>
					</div>
				),
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email",
				width: "160px",
				sorter: (a, b) =>
					a.email.toLowerCase() < b.email.toLowerCase() ? -1 : 1,
				render: (text, record) => (
					<div style={{ width: 160 }}>
						<div
							style={{
								display: "block",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								minWidth: 160,
							}}>
							{text}
						</div>
					</div>
				),
			},
			{
				title: "Contact No.",
				dataIndex: "contact",
				key: "contact",
				width: "120px",
				sorter: (a, b) => (a.contact < b.contact ? -1 : 1),
			},
			{
				title: "Country",
				dataIndex: "country",
				key: "country",
				width: "100px",
				sorter: (a, b) =>
					a.country.toLowerCase() < b.country.toLowerCase() ? -1 : 1,
			},
			{
				title: "State",
				dataIndex: "state",
				key: "state",
				width: "100px",
				sorter: (a, b) =>
					a.state.toLowerCase() < b.state.toLowerCase() ? -1 : 1,
			},
			{
				title: "City",
				dataIndex: "city",
				key: "city",
				width: "100px",
				sorter: (a, b) =>
					a.city.toLowerCase() < b.city.toLowerCase() ? -1 : 1,
			},

			{
				title: "Address",
				dataIndex: "address",
				key: "address",
				width: "160px",
				sorter: (a, b) =>
					a.address.toLowerCase() < b.address.toLowerCase() ? -1 : 1,
				render: (text, record) => (
					<div style={{ width: 160 }}>
						<div
							style={{
								display: "block",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								minWidth: 160,
							}}>
							{text}
						</div>
					</div>
				),
			},
			{
				title: "Comment",
				dataIndex: "comment",
				key: "comment",
				width: "160px",
				sorter: (a, b) =>
					a.comment.toLowerCase() < b.comment.toLowerCase() ? -1 : 1,
				render: (text, record) => (
					<div style={{ width: 160 }}>
						<div
							style={{
								display: "block",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								minWidth: 160,
							}}>
							{text}
						</div>
					</div>
				),
			},
			{
				title: "Action",
				dataIndex: "action",
				key: "action",
				width: "80px",
				render: (text, record) => (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100%",
							width: "100%",
						}}>
						<button
							className="btn-send-request"
							disabled={record.isOnboarded === "No" ? true : false} //karan-task-3648-disable button
							style={{ padding: "3px 6px", fontSize: 12 }}
							onClick={(e) => this.onCompleteBtnClick(e, record)}>
							Completed
						</button>
					</div>
				),
			},
		];

		return (
			<div>
				<Navigation />
				<div
					style={{
						paddingTop: 90,
						paddingBottom: 80,
						paddingLeft: 20,
						paddingRight: 20,
					}}>
					{/* Start - Page Heading */}
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
						}}>
						<span
							style={{
								fontSize: 28,
								fontWeight: "500",
								color: "#193D8F",
								fontFamily: "Roboto",
							}}>
							Publisher Request
						</span>
					</div>
					{/* End - Page Heading */}

					{/* Start - Table DI Loader - Incase there are any future changes, please don't move the sequence of this loader div which is before main table */}
					{this.state.loading && (
						<div
							align="center"
							id="loader_campaign_list_publisher_request"></div>
					)}
					{/* End - Table DI Loader */}

					{/* Start - Main Table */}
					<div style={{ marginTop: 10 }}>
						<Table
							className={TABLE_CSS}
							//loading={this.state.loading}
							bordered
							size="small"
							columns={TABLE_COLUMNS}
							dataSource={
								this.state.publisherList.length > 0
									? this.state.publisherList
									: null
							}
							scroll={{
								x: this.state.publisherList.length > 0 ? "auto" : "hidden",
							}}
							pagination={{
								pageSize: 10,
								position: "bottom",
							}}
						/>
					</div>
					{/* End - Main Table */}

					{/* Start - Table footer */}
					<div className="row">
						<div
							className="col-6 d-flex align-items-center"
							style={{ marginTop: -56, height: 56 }}>
							<div style={{ height: 24 }}>
								<span style={{ marginRight: 5, marginLeft: 8 }}>
									Records per page :&nbsp;
								</span>
								<span>
									<select
										className="form-control"
										style={{
											width: 70,
											display: "initial",
											height: "initial",
											padding: 2,
										}}
										// value={this.state.recordsPerPage}
										// onChange={this.handleRecordsPerPage}
									>
										<option value={100}>100</option>
										<option value={200}>200</option>
										<option value={300}>300</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					{/* End - Table footer */}
				</div>
				<Footer />
			</div>
		);
	}
}

/**
 * @author Karan Jagtap
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
PublisherRequest.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(PublisherRequest)
);
