/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

import React from "react";
import Footer from "../layouts/footer";
import PublisherNavigation from "../layouts/publisherNavPage";
import { MDBRow, MDBCol } from "mdbreact";
//Chaitanya-2898-Removed Console warning
//import LeadTable from "./table";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
//Chaitanya-2898-Removed Console warning
//import fs from "fs";
import { saveAs } from "file-saver";
//Chaitanya-2898-Removed Console warning
import { Table } from "mdbreact";
import "./loader.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header
//import "./updateExistingLead.css";//task 4231 vrushabh patil  creat css

//const queryString = require("query-string");//Chaitanya-2898-Removed Console warning
const xlsx = require("xlsx");
const Swal = require("sweetalert2");

var leadLength;
var leadFileExt;
class PublisherUpdateDeliverLead extends React.Component {
	constructor() {
		super();
		this.state = {
			campID: "",
			parentCampID: "",
			reallocationID: "",
			selectedFile: null,
			filesData: [],
			fields: {},
			leadDetails: [],
			leadDetailsData: [],
			getExt: [],
			leadDetailsData1: [],
			leadDetailsDataTest: [],
			errors: {},
			fileerrors: {},
			formaterrors: {},
			inputClass: "disabled",
			loading: " ",
			downloadExistingLead: [],
		};
		//    this.handleselectedFile = this.handleselectedFile.bind(this);
		this.submitDeliverLead = this.submitDeliverLead.bind(this);
		this.handleChangeFileDeliverLead =
			this.handleChangeFileDeliverLead.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.downloadAuditReport = this.downloadAuditReport.bind(this);
		this.handleChangeFileDownloadLead =
			this.handleChangeFileDownloadLead.bind(this);
			this.backButton=this.backButton.bind(this);
	}

	backButton(e){
		e.preventDefault();//Chaitanya-2898-Removed Console warning
		const { user } = this.props.auth;
		this.props.history.push("/updateExistingLead",{pID:user.id}); //sonali-task-3968-replace query params
	}
	/**
	 * @author Narendra Phadke
	 * @param  Description handle the login authentication
	 * @return Description return All details of authentication
	 */
	componentDidMount() {

		//sonali-3968-accessing parameters from props
		if (this.props.location.state !== undefined) {
			var	campID = this.props.location.state.campID;
			var	parentCampID = this.props.location.state.parentCampID;
			var	reallocationID = this.props.location.state.reallocationID;
	  //  this.setState({reportID})
		  this.setState({campID,parentCampID,reallocationID});
	  }
		//var parsed = queryString.parse(this.props.location.search);
		///var campID = parsed.campID;
		//var parentCampID = parsed.parentCampID;
		//var reallocationID = parsed.reallocationID;
		//this.setState({ parentCampID, reallocationID });

		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		}
		this.setState({ campID: campID });
		fetch("publisher/getDeliveryFileExtension?campID=" + campID)
			.then((res) => res.json())
			.then((getExt) => {
				this.setState({ getExt: getExt[0].leadDeliveryOption });
			})
			.catch(function (err) {
				console.log(err);
			});

		//const { user } = this.props.auth;//Chaitanya-2898-Removed Console warning
		fetch("/publisher/updateLeadsPermissionTimeFlag", {
			//insert data in publisher_assignment table
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((updateLeadsPermissionTimeFlag) => {})
			.catch(function (err) {
				console.log(err);
			});
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the file upload
	 * @return Description return All details of file
	 */
	handleChangeFileDeliverLead(e) {
		e.preventDefault();
		//  var campID = window.location.href.split("?")[window.location.href.split("?").length - 1];

		//var fileName;//Chaitanya-2898-Removed Console warning
		//var file = [];//Chaitanya-2898-Removed Console warning
		let filesData = e.target.files[0];
		let filesData1 = e.target.files;
		var names = [];
		var output = document.getElementById("LeadList");
		leadLength = filesData1.length;

		for (var i = 0, l = filesData1.length; i < l; i++) {
			names[i] = filesData1[i].name;
			console.log("Name of files=" + filesData1[i].name);
		}

		var extFileName = filesData.name;
		leadFileExt = extFileName.split(".").pop(); /** Get File extension */
		output.innerHTML = "<ul>";
		for (let i = 0; i < filesData1.length; ++i) {	//Chaitanya-2898-Removed Console warning
			output.innerHTML += "<li>" + names[i] + "</li>";
		}
		output.innerHTML += "</ul>";
		
		//var parsed = queryString.parse(this.props.location.state);
		//sonali-3968-accessing parameters from location.state
		var campID = this.props.location.state.campID;
		var reAllocationID = this.props.location.state.reallocationID;
		var parentCampID = this.props.location.state.parentCampID;

		//const { isAuthenticated, user } = this.props.auth;//Chaitanya-2898-Removed Console warning
		if (this.validateForm() === "false") {
			return;
		} else {
			//Not insert file data into database only read file and get result into array
			//var wait = document.getElementById("pleaseWait");//Chaitanya-2898-Removed Console warning
			// wait.innerHTML="Please wait......";
			this.setState({
				loading: "whirly-loader",
			});
			var data = new FormData();
			data.append("campID", campID);
			data.append("parentCampID", parentCampID);
			//sonali-3968-removing user name and pID from the data below
		//	data.append("userName", user.name);
		//	data.append("pIDFile", user.id);
			data.append("leadKey", "update");
			data.append("reAllocationID", reAllocationID);
			data.append("file", filesData);
			if (leadFileExt.toString() === "xlsx") {	//Chaitanya-2898-Removed Console warning
				/** If user upload .xlsx file */
				fetch("/lead/uploadFileForLeadDeliver", {
					method: "POST",
					body: data,
				})
					.then((res) => res.json())
					.then((leadDetailsDataTest) => {
						if (leadDetailsDataTest.length > 0) {
							//  var  pclick= document.getElementById('pleaseClick')
							// pclick.innerHTML="Please click on show lead status.";
							// this.setState({ leadDetailsDataTest: leadDetailsDataTest,inputClass: "enabled",loading:""})

							let formaterrors = {};
							let result = {
								leadDetailsData: leadDetailsDataTest,
								//user: user,//sonali-3968-removing user from the following data
								campID: campID,
								parentCampID: parentCampID,
								reallocationID: reAllocationID,
								leadKey: "update",
							};
							if (leadDetailsDataTest === undefined) {
								formaterrors["format"] = "Please upload correct format file";
							} else {
								fetch("/lead/updateLead", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(result),
								})
									.then((res) => res.json())
									.then((leadDetailsData) => {
										this.setState({
											leadDetailsData: leadDetailsData,
											loading: "",
										});
										var status1;
										if (
											leadDetailsData[0].marketingChannel.toString() === "TeleMarketing"//Chaitanya-2898-Removed Console warning
										) {
											for (var i = 0; i < leadDetailsData.length; i++) {
												if (leadDetailsData[i].status.toString() === "voiceLogPending") {//Chaitanya-2898-Removed Console warning
													status1 = true;
												}
											}
											if (status1 == true) {
												var voiceLogLinkHere =
													document.getElementById("voiceLogLink");
												voiceLogLinkHere.innerHTML =
													"Please click <a href='/voiceLogsUpload?campID=" +
													this.state.parentCampID +
													"'>here</a> to upload voice logs";

												// Swal.fire({

												//   text: "Please upload voice logs for this campaign.",

												//   confirmButtonText: 'Ok',
												//   allowOutsideClick: false,
												//   preConfirm: () => {
												//     //window .location.reload();
												//     window.location.href ="/voiceLogsUpload?campID="+this.state.campID+"&parentCampID="+this.state.parentCampID+"&reallocationID="+this.state.reallocationID;

												//   }
												// })
											}
										}
									});

								// fetch("/lead/updateLead1",{
								//   method: 'POST',
								//    headers: {'Content-Type': 'application/json'},
								//    body: JSON.stringify(result)
								// }).then(res => res.json())
								// .then(leadDetailsData =>
								//   this.setState({ leadDetailsData: leadDetailsData })
								// );
							}
						}
						else {
							//Priyanka - 4575 - Added condition to display invalid template error
							if(leadDetailsDataTest.message == 'wrong template'){
							  this.setState({
								loading1: false,
								msg: "INVALID DELIVERY TEMPLATE",
								loaderLead: "",
							  });
							}
							else{
							
							  this.setState({
								loading1: false,
								msg: "Please try to upload lead again",
								loaderLead: "",
							  }); //Sandeep-task-3119-added code to stop loading and display error
							}
						  }
					});
			} /** End of else if Statement which check file extension */ else if (
				leadFileExt.toString() === "csv"//Chaitanya-2898-Removed Console warning
			) {
				/** If user upload .csv file */
				fetch("/lead/uploadLeadDeliverCSV", {
					method: "POST",
					body: data,
				})
					.then((res) => res.json())
					.then((leadDetailsDataTest) => {
						if (leadDetailsDataTest.length > 0) {
							//  var  pclick= document.getElementById('pleaseClick')
							// pclick.innerHTML="Please click on show lead status.";
							// this.setState({ leadDetailsDataTest: leadDetailsDataTest,inputClass: "enabled",loading:""})
							console.warn(
								"Final Response=" + JSON.stringify(leadDetailsDataTest)
							);
							let formaterrors = {};

							let result = {
								leadDetailsData: leadDetailsDataTest,
								//user: user,//sonali-3968-removing user from the data
								campID: campID,
								parentCampID: parentCampID,
								reallocationID: reAllocationID,
								leadKey: "update",
							};
							if (leadDetailsDataTest === undefined) {
								formaterrors["format"] = "Please upload correct format file";
							} else {
								fetch("/lead/updateLead", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(result),
								})
									.then((res) => res.json())
									.then((leadDetailsData) => {
										this.setState({
											leadDetailsData: leadDetailsData,
											loading: "",
										});
										var status1;
										if (
											leadDetailsData[0].marketingChannel.toString() === "TeleMarketing"//Chaitanya-2898-Removed Console warning
										) {
											for (var i = 0; i < leadDetailsData.length; i++) {
												if (leadDetailsData[i].status.toString() === "voiceLogPending") {//Chaitanya-2898-Removed Console warning
													status1 = true;
												}
											}
											if (status1 == true) {
												var voiceLogLinkHere =
													document.getElementById("voiceLogLink");
												voiceLogLinkHere.innerHTML =
													"Please click <a href='/voiceLogsUpload?campID=" +
													this.state.parentCampID +
													"'>here</a> to upload voice logs";

												// Swal.fire({

												//   text: "Please upload voice logs for this campaign.",

												//   confirmButtonText: 'Ok',
												//   allowOutsideClick: false,
												//   preConfirm: () => {
												//     //window .location.reload();
												//     window.location.href ="/voiceLogsUpload?campID="+this.state.campID+"&parentCampID="+this.state.parentCampID+"&reallocationID="+this.state.reallocationID;

												//   }
												// })
											}
										}
									});

								// fetch("/lead/updateLead1",{
								//   method: 'POST',
								//    headers: {'Content-Type': 'application/json'},
								//    body: JSON.stringify(result)
								// }).then(res => res.json())
								// .then(leadDetailsData =>
								//   this.setState({ leadDetailsData: leadDetailsData })
								// );
							}
						}
					});
			} /** End of else if Statement which check file extension */
			this.setState({
				filesData,
			});
		}
	}
	/**
	 * @author Narendra Phadke
	 * @param  Description submit the file
	 * @return Description return All details of file
	 */
	submitDeliverLead(e) {
		e.preventDefault();
		let formaterrors = {};
		//var lblError = document.getElementById("lblError");
		//sonali-3968-accessing parameters from location.state
		//var parsed = queryString.parse(this.props.location.state);
		var campID = this.props.location.state.campID;
		var parentCampID = this.props.location.state.parentCampID;
		var reallocationID = this.props.location.state.reallocationID;
		if (leadLength == undefined) {
			return;
		} else {
			if (this.validateForm() === "false") {
				return;
			} else {
				//  console.warn(JSON.stringify(this.state.leadDetailsDataTest));

				//const { isAuthenticated, user } = this.props.auth;//Chaitanya-2898-Removed Console warning
				let result = {
					leadDetailsData: this.state.leadDetailsDataTest,
					//user: user,//sonali-3968-removing user from data
					campID: campID,
					parentCampID: parentCampID,
					reallocationID: reallocationID,
					leadKey: "update",
				};
				if (this.state.leadDetailsDataTest === undefined) {
					formaterrors["format"] = "Please upload correct format file";
				} else {
					fetch("/lead/updateLead", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(result),
					})
						.then((res) => res.json())
						.then((leadDetailsData) => {
							this.setState({ leadDetailsData: leadDetailsData, loading: "" });
							var status1;
							if (leadDetailsData[0].marketingChannel.toString() === "TeleMarketing") {//Chaitanya-2898-Removed Console warning
								for (var i = 0; i < leadDetailsData.length; i++) {
									if (leadDetailsData[i].status.toString() === "voiceLogPending") {//Chaitanya-2898-Removed Console warning
										status1 = true;
									}
								}
								if (status1 == true) {
									Swal.fire({
										text: "Please upload voice logs for this campaign.",

										confirmButtonText: "Ok",
										allowOutsideClick: false,
										preConfirm: () => {
											//window .location.reload();
											window.location.href =
												"/voiceLogsUpload?campID=" +
												this.state.campID +
												"&parentCampID=" +
												this.state.parentCampID +
												"&reallocationID=" +
												this.state.reallocationID;
										},
									});
								}
							}
						});

					// fetch("/lead/updateLead1",{
					//   method: 'POST',
					//    headers: {'Content-Type': 'application/json'},
					//    body: JSON.stringify(result)
					// }).then(res => res.json())
					// .then(leadDetailsData =>
					//   this.setState({ leadDetailsData: leadDetailsData })
					// );
				}
			}
		}
	}

	validateForm() {
		var pclick = document.getElementById("pleaseClick");
		pclick.innerHTML = "";
		let formIsValid = true;
		let errors = {};
		let fileerrors = {};

		//Chaitanya-2898-Removed Console warning
		// var campFileExt;
		// if (this.state.getExt.toString() === "Excel") {
		// 	campFileExt = "xlsx";
		// } else if (this.state.getExt.toString() === "CSV") {
		// 	campFileExt = "csv";
		// }
		// if(leadFileExt!=campFileExt){
		//   errors["fileExt"]="Please upload only ."+campFileExt+" file format as per the campaign specification.";
		//    formIsValid='false';
		// }

		if (leadLength == undefined) {
			errors["file"] = "Please upload minimum one excel file";
			formIsValid = "false";
		}

		if (leadLength !== undefined) {
			var allowedFiles = [".xls", ".xlsx", ".csv"];
			var deliveryLeadfiles = document.getElementById("deliveryLeadfiles");

			var regex = new RegExp(
				"([a-zA-Z0-9s_\\.()-:])+(" + allowedFiles.join("|") + ")$"
			);

			if (!regex.test(deliveryLeadfiles.value.toLowerCase())) {
				fileerrors["fileextensions"] =
					"Please upload files having extensions: " +
					allowedFiles.join(", ") +
					" only";
				formIsValid = "false";
			}
		}
		this.setState({
			errors: errors,
			fileerrors: fileerrors,
		});

		return formIsValid;
	}
	/**
	 * @author Somnath Keswad
	 * @param  Description Write the data in Excel File
	 * @return Description Download the Excel File
	 */

	downloadAuditReport(e) {
		e.preventDefault();
		//sonali-3968-accessing parameters from state
		var campID = e.target.id;
	//	var parsed = queryString.parse(this.props.location.state);
		var parentCampID = this.props.location.state.parentCampID;
		var reAllocationID = this.props.location.state.reallocationID;
		//const { isAuthenticated, user } = this.props.auth;//Chaitanya-2898-Removed Console warning
		let data = {
			campID: campID,
			parentCampID: parentCampID,
			reallocationID: reAllocationID,
			listLeadInfoID: this.state.leadDetailsData,
			//user: user,//sonali-3968-removing user
		};
		fetch("publisher/downloadAuditReport", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((downloadFiles) => {
				this.setState({ downloadFiles: downloadFiles });
				//       /* make the worksheet */

				if (downloadFiles.length > 0) {
					var ws = xlsx.utils.json_to_sheet(downloadFiles);
					/* add to workbook */
					var wb = xlsx.utils.book_new();
					xlsx.utils.book_append_sheet(wb, ws, "Lead");

					// /* write workbook */
					var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
					//var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" });//Chaitanya-2898-Removed Console warning
					/* generate a download */
					function s2ab(s) {
						var buf = new ArrayBuffer(s.length);
						var view = new Uint8Array(buf);
						for (var i = 0; i !== s.length; ++i)	//Chaitanya-2898-Removed Console warning
							view[i] = s.charCodeAt(i) & 0xff;
						return buf;
					}
					var fileName =
						"Audit_Report" + parentCampID + "-" + reAllocationID + ".xlsx";
					saveAs(
						new Blob([s2ab(buf)], { type: "application/octet-stream" }),
						fileName
					);
				} else {
					console.log("Data Not Exists");
				}
			});

		// window.location.href = '/publisherView?pID='+user.id;
	} /**  End of downloadAuditReport */

	handleChangeFileDownloadLead(e) {
		//sonali-3968-accessing parameters from state
		
		var parentCampID = this.props.location.state.parentCampID;
		var campID = this.props.location.state.campID;
		var reAllocationID = this.props.location.state.reallocationID;
		// alert(reAllocationID)
		//const { isAuthenticated, user } = this.props.auth;//Chaitanya-2898-Removed Console warning
		let data = {
			campID: campID,
			parentCampID: parentCampID,
			reallocationID: reAllocationID,
			//user: user,//sonali-task-3968-removing user
		};
		fetch("publisher/downloadExistingLead", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((downloadExistingLead) => {
				this.setState({ downloadExistingLead: downloadExistingLead });

				//       /* make the worksheet */
				if (downloadExistingLead.length > 0) {
					var ws = xlsx.utils.json_to_sheet(downloadExistingLead);
					/* add to workbook */
					var wb = xlsx.utils.book_new();
					xlsx.utils.book_append_sheet(wb, ws, "Lead");

					// /* write workbook */
					var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
					//var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" });	//Chaitanya-2898-Removed Console warning
					/* generate a download */
					function s2ab(s) {
						var buf = new ArrayBuffer(s.length);
						var view = new Uint8Array(buf);
						for (var i = 0; i !== s.length; ++i)	//Chaitanya-2898-Removed Console warning
							view[i] = s.charCodeAt(i) & 0xff;
						return buf;
					}
					var fileName =
						"Download Update Lead " +
						parentCampID +
						"-" +
						reAllocationID +
						".xlsx";
					saveAs(
						new Blob([s2ab(buf)], { type: "application/octet-stream" }),
						fileName
					);
				} else {
					Swal.fire({                                             
						text: "Data Not Exists",   //4808-Nilesh- Add swal fire 
						type: "error",
						allowOutsideClick: false,
						heightAuto: false,
						preConfirm: () => {
						  window.location.reload();
						},
					  })
					console.log("Data Not Exists");
				}
			});
	} //end of Download existing lead to update

	render() {
		//const { isAuthenticated, user } = this.props.auth;	//Chaitanya-2898-Removed Console warning

		const result = Array.from(
			new Set(this.state.leadDetailsData.map((p) => p.qaReviewLead))
		).map((qaReviewLead) => {
			return {
				totalLead: this.state.leadDetailsData.find(
					(p) => p.qaReviewLead === qaReviewLead
				).totalLead,
				acceptedLead: this.state.leadDetailsData.find(
					(p) => p.qaReviewLead === qaReviewLead
				).acceptedLead,
				qaReview: this.state.leadDetailsData.find(
					(p) => p.qaReviewLead === qaReviewLead
				).qaReview,
				agencyInternalReview: this.state.leadDetailsData.find(
					(p) => p.qaReviewLead === qaReviewLead
				).agencyInternalReview,
				diQAAcceptedReview: this.state.leadDetailsData.find(
					(p) => p.qaReviewLead === qaReviewLead
				).diQAAcceptedLead, //Supriya Task:3391 - DI QA Accepted lead count added in result
				rejectedLead: this.state.leadDetailsData.find(
					(p) => p.qaReviewLead === qaReviewLead
				).rejectedLead,
			};
		});

		return (
			<div>
				<PublisherNavigation />
				<br />

				<form onSubmit={this.submitDeliverLead}>
					<div
						class="container-fluid "
				
						style={{ paddingBottom: "60px", paddingTop: "70px" }}>
						<div class="row">
							<div class="col-lg-4">
								{/* IoIosArrowDropleftCircle */}
								<a
									//style={{ color: "#fff" }}	//Chaitanya-2898-Removed Console warning
									onClick={this.backButton}
									href="#/"					//Chaitanya-2898-Removed Console warning
									style={{ color: "#056eb8" }}>
									<FaArrowAltCircleLeft size={32} title="Back to update existing leads" />
								</a>
							</div>
							<div class="col-lg-4" align="center"/*task -4231 vrushabh patil change the name*/>
								<label
									id="labelheading"
									style={{ color: "#056eb8", fontSize: "20px" }}>
									Update Existing Leads 
								</label>
							</div>
						</div>

						<div></div>

						<hr />
						<div
							class="float-left"
							style={{
								
								color: "#000080",
								fontSize: "18px",
								paddingLeft: "30px",
							}}>
							Campaign Id:{" "}
							<span style={{ color: "" }}>{this.state.parentCampID}</span>&nbsp;
						</div>
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							{/* <div class="card card-signin my-1"> */}
							<div class="card-body">
								<MDBRow>
									<MDBCol lg="4">
										<div class="upload-btn-wrapper">
									
											<button
												class="btn add-button"
												type="button"
												onClick={this.handleChangeFileDownloadLead}
												style={{ backgroundColor: "#056eb8", color: "white" }}>
												<i class="fa fa-cloud-download" aria-hidden="true"></i>{" "}
												Download Leads
											</button>
										</div>
									</MDBCol>
									<MDBCol lg="8" >
										<div class="upload-btn-wrapper mech"/*task 4231 vrushabh  add class */>
										
											<button class="btn add-button" style={{"cursor": "pointer"}}
										  >Upload leads</button>
											<input
												type="file"
												id="deliveryLeadfiles"
												name="deliveryLeadfiles"
												onChange={this.handleChangeFileDeliverLead}
												
											/>
										</div>

										<div id="LeadList" style={{ color: "black" }}></div>
										<div style={{ color: "red", paddingTop: "23px" }}>
											{this.state.errors.fileExt}
										</div>
										<div style={{ color: "red" }}>
											{this.state.fileerrors.fileextensions}
										</div>
										<div style={{ color: "red" }}>
											{this.state.formaterrors.format}
										</div>
										<div
											id="pleaseWait"
											style={{
												color: "red",
												fontWeight: "bold",
												fontSize: "13px",
												left: "200px",
												top: "200px",
											}}
											className={this.state.loading}></div>
										<div
											id="pleaseClick"
											style={{
												color: "red",
												fontWeight: "bold",
												fontSize: "13px",
											}}></div>

										{/* <button type="submit" class="btn add-button"   onClick={this.validateForm}   
                                                  
                                                  >Show lead status</button> */}

										<div style={{ color: "red" }}>{this.state.errors.file}</div>
									</MDBCol>

									{/* </div> */}
								</MDBRow>
								<br />

								<div style={{ display: this.state.tableDisplay }}>
									<div class="row">
										{/* <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> */}
										<div class="col-xs-12 col-sm-12 col-md-10 col-lg-11">
											{result.map((result) => {
												if (result.totalLead != undefined) {
													return (
														<label
															id="label"
															class="font-weight-bold"
															style={{ color: "green", fontSize: "13px" }}>
															Leads are uploaded successfully!&nbsp;
															<span style={{ color: "black" }}>(&nbsp;</span>
															<span
																id="label"
																class="font-weight-bold"
																style={{ color: "black" }}>
																Total:
															</span>
															&nbsp;
															<span
																style={{ color: "black", fontWeight: "bold" }}>
																{result.totalLead}
															</span>
															<span style={{ color: "black" }}>
																&nbsp;|&nbsp;
															</span>
														</label>
													);
												}
											})}
											{/*shivani-3740-changed color for label and count*/}
											{result.map((result) => {
												if (result.qaReview != undefined) {
													return (
														<label
															id="label"
															class="font-weight-bold"
															style={{ fontSize: "13px", color: "green" }}>
															QA Review:&nbsp;
															<span
																style={{ color: "green", fontWeight: "bold" }}>
																{result.qaReview}
															</span>
															<span
																style={{ color: "black", fontWeight: "bold" }}>
																<span style={{ color: "black" }}>
																	&nbsp;|&nbsp;
																</span>
															</span>{" "}
														</label>
													);
												}
											})}
											{/*Supriya Task:3391 - display DI QA Accepted lead count*/}
											{/*shivani-3740-changed color for label and count*/}
											{result.map((result) => {
												if (result.diQAAcceptedReview != undefined) {
													return (
														<label
															id="label"
															class="font-weight-bold"
															style={{ fontSize: "13px" }}>
															DI QA Accepted:&nbsp;
															<span style={{ fontWeight: "bold" }}>
																{result.diQAAcceptedReview}
															</span>
															<span
																style={{ color: "black", fontWeight: "bold" }}>
																<span style={{ color: "black" }}>
																	&nbsp;|&nbsp;
																</span>
															</span>{" "}
														</label>
													);
												}
											})}
											{/*shivani-3740-changed color for label and count*/}
											{result.map((result) => {
												if (result.agencyInternalReview != undefined) {
													return (
														<label
															id="label"
															class="font-weight-bold  margineleftincress"//vrushabh -4231 add class 
															style={{
																color: "rgb(5, 110, 184",
																fontSize: "13px",
															}}>
															Agency Internal Review:&nbsp;
															<span
																style={{
																	color: "rgb(5, 110, 184",
																	fontWeight: "bold",
																}}>
																{result.agencyInternalReview}
															</span>
															<span
																style={{ color: "black", fontWeight: "bold" }}>
																<span style={{ color: "black" }}>
																	&nbsp;|&nbsp;
																</span>
															</span>{" "}
														</label>
													);
												}
											})}
											{/*shivani-3740-changed color for label and count*/}
											{result.map((result) => {
												if (result.rejectedLead != undefined) {
													return (
														<label
															id="label"
															class="font-weight-bold margineincrese "//vrushabh -4231 add class
															style={{ fontSize: "13px", color: "red" }}>
															Rejected:&nbsp;
															<span
																style={{ color: "red", fontWeight: "bold" }}>
																{result.rejectedLead}
															</span>
															<span
																style={{ color: "black", fontWeight: "bold" }}>
																&nbsp;)
															</span>
														</label>
													);
												}
											})}
										</div>

										{result.map((result) => {
											if (result.totalLead != undefined) {
												return (
													<div
														class="col-xs-12 col-sm-12 col-md-2 col-lg-1"
														className="float-right infolead"> {/*vrushabh -4231 add class*/}
														<div class="tip">
															<span>
																<a href="#/"	//Chaitanya-2898-Removed Console warning
																>		
																	<img
																		src="download.jpg"
																		onClick={this.downloadAuditReport}
																		id={this.state.campID}
																		alt=""			//Chaitanya-2898-Removed Console warning
																		style={{
																			width: "30px",
																			height: "30px",
																			color: "#17278E",
																		}}></img>
																</a>
															</span>
															<span class="load">Download Audit Report</span>
														</div>
													</div>
												);
											}
										})}
									</div>
									{/* </div> */}
									<MDBRow>
										<MDBCol lg="12" className="mb-12">
											<div style={{ textAlign:"center"}}> {/*vrushabh -4231 add text center*/}
												<Table
													scrollY
													className=" table table-bordered table-striped "
													style={{ fontSize: "12px" }}>
													<thead>
														<tr class="info" style={{ verticalAlign: "top" }}>
															<th
																class="table-header"
																style={{ width: "15%", verticalAlign: "top" }}>
																Lead ID
															</th>
															<th
																class="table-header"
																style={{ width: "15%", verticalAlign: "top" }}>
																Campaign ID
															</th>
															<th
																class="table-header"
																style={{ width: "35%", verticalAlign: "top",}}>
																Email
															</th>
															<th
																class="table-header"
																style={{ width: "35%",verticalAlign: "top" }}>
																Status
															</th>
														</tr>
													</thead>
													{this.state.leadDetailsData.map(
														(leadDetailsData, i) => {
															return (
																<tbody>
																	{/*Supriya Task:3391 - added status DI QA Accepted, so that lead will display in green colour*/}
																	{/*shivani-3740-changed table data color as per conditions.*/}
																	{leadDetailsData.status.toString() === "InternalReview" ||		//Chaitanya-2898-Removed Console warning
																	leadDetailsData.status.toString() === "QA Review" ||			//Chaitanya-2898-Removed Console warning
																	leadDetailsData.status.toString() ===							//Chaitanya-2898-Removed Console warning
																		"AgencyInternalReview" ||
																	leadDetailsData.status.toString() === "DI QA ACCEPTED" ? (		//Chaitanya-2898-Removed Console warning
																		<tr style={{ color: "green" }}>
																			<td style={{ color: "green" }}>
																				{leadDetailsData.leadInfoID}
																			</td>
																			<td style={{ color: "green" }}>
																				{leadDetailsData.parentCampID}
																			</td>
																			<td style={{ color: "green" }}>
																				{leadDetailsData.email}
																			</td>
																			<td style={{ color: "green" }}>
																				{leadDetailsData.status}
																			</td>
																		</tr>
																	) : (
																		<tr style={{ color: "red" }}>
																			<td style={{ color: "red" }}>
																				{leadDetailsData.leadInfoID}
																			</td>
																			<td style={{ color: "red" }}>
																				{leadDetailsData.parentCampID}
																			</td>
																			<td style={{ color: "red" }}>
																				{leadDetailsData.email}
																			</td>
																			<td style={{ color: "red" }}>
																				{leadDetailsData.status}
																			</td>
																		</tr>
																	)}
																</tbody>
															);
														}
													)}
												</Table>
											</div>
										</MDBCol>
									</MDBRow>

									<div
										id="voiceLogLink"
										style={{
											color: "red",
											fontWeight: "bold",
											fontSize: "13px",
										}}></div>
								</div>
							</div>
							{/* </div> */}
						</div>
					</div>
				</form>

				<Footer />
			</div>
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
PublisherUpdateDeliverLead.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(PublisherUpdateDeliverLead)
);
