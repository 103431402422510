/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Sanjana Godbole
@fileName: requestProposalAgency.js
@Creation Date:19-12-2018
@Description: Request For Proposal For Publisher 
@Console error warning handled by:Sunita Landge
*/


import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";

import Footer from '../layouts/footer';
import Navigation from '../layouts/navPage';
//import { saveAs } from "file-saver";//task-3840-Create-Audience Match & Audience Reach-Agency -File Download-FE
import "react-picky/dist/picky.css";
import { Tooltip} from 'antd';
//import { token } from 'morgan'; //rutuja 2832 commented token as never used in the file
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //task-3840-Create-Audience Match & Audience Reach-Agency -File Download-FE
const Swal = require('sweetalert2')
const queryString = require('query-string');
//const xlsx = require("xlsx");//task-3840-Create-Audience Match & Audience Reach-Agency -File Download-FE
//kiran-4745-removing console warning-no used
var statusCount=0;

class RequestForProposalAgency extends React.Component
{
    constructor() 
    {
      super();
      this.state = 
      {
        GetBiddingDetail: [], biddingDocument: [], downloadFiles: [], 
        campID: '',
        pID:'',//task-3840-Create-Audience Match & Audience Reach-Agency -File Download-FE
        downloadFlag:false,
        key:"",//shivani-3781- to access is from url.
         value: null,
        arrayValue: [], publisherBidding: [], campaignInfo: [], errors: {},
      
        
        acceptAll: '',
        enableFlag: false,
        actionCheck: false,
        details:[]//task-3840-Create-Audience Match & Audience Reach-Agency -File Download-FE

      }//end of state

      this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
      this.acceptAllBiddings = this.acceptAllBiddings.bind(this);

    }//end of constructor
			//shivani-3781-added handlechange for backbutton .

    rfpBackButton(e){
      e.preventDefault();
      const { user } = this.props.auth
      var parsed = queryString.parse(this.props.location.search);
      var campID = parsed.campID;
    var key= parsed.key;
      var agencyID = user.id;

      if(key){
       
      window.location.href = "/rfpAnalysis?agencyID=" + agencyID +"&campID=" + campID;
      }
      else{
      window.location.href = "/newDashboard";
      }
		// this.props.history.push("/rfpAnalysis",{agencyID:agencyID,campID:campID})

    }
  acceptAllBiddings(e)
  {
    this.setState({
      actionCheck: !this.state.actionCheck,
      acceptAll: !this.state.actionCheck
    });

  }//end of acceptAllBiddings


  handleBidStatus(i, e)
  {
    const { name, value } = e.target;
    let publisherBidding = [...this.state.publisherBidding];
    publisherBidding[i] = { ...publisherBidding[i], [name]: value }
    this.setState({ publisherBidding: publisherBidding }, function () {  });

  }// End of handleBidStatus

  handleChange(i, e)
  {
    const { name, value } = e.target;
    let publisherBidding = [...this.state.publisherBidding];
    publisherBidding[i] = { ...publisherBidding[i], [name]: value };
    // this.state.publisherBidding = [...publisherBidding];
    this.setState({publisherBidding:[...publisherBidding]})//kiran-4745-removing console warning-use setState
    
  }//end of handleChange


  selectMultipleOption(i, value) 
  {
    let name = 'marketingChannel';
    var countryvalue = [];
    for (var j = 0; j < value.length; j++)
    {
      countryvalue.push(value[j].name);
    }
    countryvalue = countryvalue.toString();
    var publisherBidding = [...this.state.publisherBidding];
    publisherBidding[i] = { ...publisherBidding[i], [name]: countryvalue };
    // this.state.publisherBidding = [...publisherBidding];
    this.setState({publisherBidding:[...publisherBidding]}) //kiran-4745-removing console warning-use setState

  }

  componentDidMount() 
  {
    if (!this.props.auth.isAuthenticated)
    {
      this.props.history.push('/userLogin');
    }
    else 
    {

      //Sonali-3716-VAPT
			if (this.props.location.state !== undefined) {
				const {
          campID,key
				} = this.props.location.state;
				this.setState({campID,key})
			}
			
      //var parsed = queryString.parse(this.props.location.search);
      // var parsed = this.props.location.state;
      var campID = this.props.location.state.campID;//Sonali-3716-VAPT-accessing campID from location.state
      fetch("agencyDashboard/campaignSpecificPublisherBidding?campID=" + campID)
        .then(res => res.json())
        .then(publisherBidding => {
          this.setState({ publisherBidding: publisherBidding }, function () {
            // console.log("publisherBidding===>" + JSON.stringify(this.state.publisherBidding))
          })
          for (var s = 0; s < this.state.publisherBidding.length; s++)
          {
            if (this.state.publisherBidding[s].status === 'Bid' || this.state.publisherBidding[s].status === 'BidRejected' || this.state.publisherBidding[s].status === 'BidAccepted')
            {
              // this.state.enableFlag = true;
              this.setState({enableFlag:true})//kiran-4745-removing console warning-use setState
            }

            var ch = this.state.publisherBidding[s].marketingChannel;
            var str = ch.split(',');
            for (var j = 0; j < str.length; j++)
            {
              var output = str[j];
              var select = document.getElementById("selected" + s);
              var opt = document.createElement("option");
              opt.value = output;
              opt.textContent = output;
              select.appendChild(opt);
            }

          }
          this.setState({ enableFlag: this.state.enableFlag }, function ()
          {
            if (this.state.enableFlag === true)
            
            {
              this.setState({ actionCheck: false })
            }
          })

        }).catch(function (err) { console.log(err) });

            
      // Get RFP Quick campaign Details
      fetch("agencyDashboard/getRFPQuickCampaign?campID=" + campID)
        .then(res => res.json())
        .then(campaignInfo => {
          this.setState({ campaignInfo: campaignInfo })
        }).catch(function (err) { console.log(err) });

      // Get RFP Bidding document Details
      fetch("publisherDashboard/rfpDocumentDetail?campID=" + campID)
        .then(res => res.json())
        .then(biddingDocument => {
          this.setState({ biddingDocument: biddingDocument })
        }).catch(function (err) { console.log(err) });
    }
  
   } // end of componentDidMount

  
  submitBidding()
  {
    var errors = {};
    var status = false;
    var publisherBiddingLength = this.state.publisherBidding.length;
    for (var i = 0; i < publisherBiddingLength; i++) 
    {
      if (this.state.publisherBidding[i].status === "Accepted" || this.state.publisherBidding[i].status === "Rejected")
      {
        statusCount++;
      }
    }
    if (statusCount === 0)
    {
      status = true;
    }
    if (status === true && (this.state.acceptAll === "" || this.state.acceptAll === 'undefined' || this.state.acceptAll === undefined || this.state.acceptAll === 'null' || this.state.acceptAll === null)) 
    {
      errors["statusCount"] = "Please enter status for atleast one country or select checkbox to accept all bidding requests.";
      this.setState({
        errors: errors,
        alertBorder: '1px solid red',
      });
    }
    else
    {
      errors["statusCount"] = "";
      this.setState({
        errors: errors,
        alertBorder: '0px solid red',
      });
     // const { user } = this.props.auth; //rutuja 2832 commented user as never used in th file
    //  var parsed = queryString.parse(this.props.location.search);
      var campID = this.props.location.state.campID;//Sonali-3716-VAPT-accessing campID from location.state
      var data = {
        // user: user, // kiran- removed for 4072 issue
        campID: campID,
        publisherBidding: this.state.publisherBidding,
        acceptAll: this.state.acceptAll

      }
      fetch("agencyDashboard/acceptRejectBiddingByAgency", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(response => response.json())
        .then(function (response) {
          if (response.success === true) {
           
            Swal.fire({
              text: (" Bidding  Request Accepted Successfully"),
              type: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.href = "/rfpProgress"; //Rutuja Task- 4323 after accpting bidding page auto redirect to rfpProgress
              }
            })

          }
        }).catch(function (err) { console.log(err) });

    }

  }// End of submitBidding

  Biddingview(e)
  {
    var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
    window.location.href = "/requestForProposalBidding?campID=" + campID;

  }//end of Biddingview

  /**
  * @author Somnath Keswad
  * @param  Description download the RFP Document
  * @return Description return All The File Download in Zip
  */
  RFPdocumentDownload(e)
  {
    var campID = e.target.id;
    fetch("publisherDashboard/downloadRFPDocument?campID=" + campID)
      .then(r => r.blob())
      .then(downloadFiles =>
        this.setState({ downloadFiles: downloadFiles, campID: campID, downloadFlag:1111})
      )
      .then(this.handleFileShowDownload);

  }//end of RFPdocumentDownload

  RFPdocumentDownloadAudienceReach= (e, item) =>{
    e.preventDefault();
 

  var data = new FormData();
  var campID = item.campID;
  var pID = item.pID;
  
  data.append("campID",campID);
  data.append("pID",pID);
    fetch("publisherDashboard/downloadRFPDocumentAudienceReach", {
      method: "POST",
      body: data
     
    }).then((r) => r.blob())
        .then((downloadFiles) =>{
          this.setState({ downloadFiles: downloadFiles,campID:campID,pID:pID,downloadFlag:2222})//task-3840-Create-Audience Match & Audience Reach-Agency -File Download-FE
        }
        )
        .then(this.handleFileShowDownload);
  }


  
//task-3840-Create-Audience Match & Audience Reach-Agency -File Download-FE
  handleFileShowDownload(blob) {
    let zipFileName;
    if(this.state.downloadFlag===1111){
      zipFileName = this.state.campID + "RFP_Document.zip";
    }
    if(this.state.downloadFlag===2222){
    
     zipFileName=this.state.campID+"_pID"+this.state.pID+"Audience_Match_Audience_Reach_File.zip";
    }

    
    var newBlob = new Blob([this.state.downloadFiles], {
      type: "application/zip",
    });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = zipFileName;
    link.click();
  }//task-3840-Create-Audience Match & Audience Reach-Agency -File Download-FE
  
  
  render()
  {
    return (

      <div>
        <Navigation />
        <div class="container-fluid" style={{ paddingTop: '90px', marginBottom: '60px', }}>
        {/* //shivani-3781-added backbutton . */}

          <div class="row">
          {/* kiran-4745-removing console warning-added "#/" */}
        <div
							class="col-1"
							>
							<a
              href="#/"
                onClick={this.rfpBackButton.bind(this)}
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back to rfpAnalysis"
								/>
							</a>
						</div>
          <div class="col" style={{ fontWeight: '500', textAlign: 'center', color: '#056EB8', fontSize: '20px' }}>Request For Proposal
          </div>
</div>
          <legend className="legend labelsubheading">RFP Campaigns Details</legend>

          <table class="table table-bordered">
            <thead style={{ backgroundColor: "#7e7f8138" }} >
              <tr>
                <th style={{ verticalAlign: "top" }}>Campaign Name</th>
                <th style={{ width: "7%", verticalAlign: "top" }}>Start Date</th>
                <th style={{ width: "7%", verticalAlign: "top" }}>End Date</th>
                <th style={{ width: "7%", verticalAlign: "top" }}>Bidding Closes</th>
                <th style={{ verticalAlign: "top" }}>Marketing Channel</th>
                <th style={{ verticalAlign: "top" }}>Campaign Type</th>
                <th style={{ verticalAlign: "top" }}>Geo Location</th>
                <th style={{ verticalAlign: "top" }}>Conversion Criteria</th>
                <th style={{ verticalAlign: "top" }}>Document</th>
                <th style={{ verticalAlign: "top" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.campaignInfo.map(item => (
                <tr>
                  <td>{item.campaignName}</td>
                  <td>{item.startDate}</td>
                  <td>{item.endDate}</td>
                  <td>{item.biddingEndDate}</td>
                  <td>{item.marketingChannel}</td>
                  <td>{item.campaignType}</td>
                  <td>{item.country}</td>
                  <td style={{ wordBreak: "break-word", width: "20%" }}>{item.conversionCriteria}</td>
                  <td>
                    {this.state.biddingDocument.map(doc => {
                      //kiran-4745-removing console warning-changed == to === and added return []
                      if (doc.campID === item.campID) {
                        return (
                          <p>{doc.suppDocName}
                          <br /></p>
                        )
                      }
                      return [];
                    })}</td>

                  <td>

                    {this.state.biddingDocument.length > 0 ?
                      <a href="#/" id={item.campID} onClick={this.RFPdocumentDownload.bind(this)}>
                        Download Document
                      </a> :
                      ""
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br /><br />
          <legend className="legend labelsubheading">Bidding Request For Proposal Campaigns</legend>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ border: this.state.alertBorder }}>
            <div class="errorMessage">
              {this.state.errors.statusCount}
            </div>
          </div>
          <br />
          <br />
          <table class="table table-bordered">
            <thead style={{ backgroundColor: "#7e7f8138" }}>
              <tr>
                <th style={{ width: "8%", verticalAlign: "top" }}>Publisher</th>
                <th style={{ width: "11%", verticalAlign: "top" }}>Geo</th>
                <th style={{ width: "7%", verticalAlign: "top" }}>Monthly Lead</th>
                <th style={{ width: "7%", verticalAlign: "top" }}>Daily Lead</th>
                <th style={{ width: "7%", verticalAlign: "top" }}>Max. Lead Available</th>
                <th style={{ width: "7%", verticalAlign: "top" }}>Target CPL</th>
                <th style={{ width: "27%", verticalAlign: "top" }}>Marketing Channel</th>
                <th style={{ width: "10%", verticalAlign: "top" }}>Notes</th>
               
                <th style={{ width: "10%", verticalAlign: "top" }}>Action
                
                &nbsp;&nbsp;
                  {this.state.actionCheck}
                  {this.state.enableFlag === true ?
                    <Tooltip placement="top" title="Accept all bidding requests"> <input type="checkbox" checked={this.state.actionCheck} name="acceptAll" onClick={this.acceptAllBiddings} /></Tooltip>
                    :
                    <Tooltip placement="right" disabled title="All bidding requests are accepted"> <input type="checkbox" checked /></Tooltip>
                  }
                </th>
               
                <th style={{ width: "10%", verticalAlign: "top" }}>Audience Match & Audience Reach File</th>{//task-3840-Create-Audience Match & Audience Reach-Agency -File Download-FE
                  }
              </tr>
            </thead>
            <tbody>
              {this.state.publisherBidding.map((item, i) => (
                <tr>
                  <td style={{ fontWeight: "bold" }}>{item.pID}-
        <br />{item.publisherName}</td>
                  <td style={{ wordBreak: 'break-all' }}>{item.country}</td>
                  <td> <input type="text" id="monthlyLead" name="monthlyLead"
                    defaultValue={item.monthlyLead}
                    onChange={this.handleChange.bind(this, i)}
                    className="form-control" disabled
                    required /></td>
                  <td> <input type="text" id="dailyLead" name="dailyLead"
                    defaultValue={item.dailyLead}
                    onChange={this.handleChange.bind(this, i)}
                    className="form-control" disabled
                    required /></td>
                  <td><input type="text" id="totalLead" name="totalLead"
                    defaultValue={item.totalLead}
                    onChange={this.handleChange.bind(this, i)}
                    className="form-control" disabled
                    required /></td>
                  <td><input type="text" id="CPL" name="CPL"
                    defaultValue={item.CPL}
                    onChange={this.handleChange.bind(this, i)}
                    className="form-control" disabled
                    required /></td>

                 <td>

                    <div class="row">
                      <div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                        <input
                          id={"TeleMarketing  " + item.country}  // rutuja 2832 removed concatination of space 
                          type="checkbox"
                          name="Telemarketing"
                          style={{ pointerEvents: "none", cursor: "not-allowed" }}
                          defaultChecked={item.Telemarketing === "Yes" ? true : false}
                        />
                        <label style={{ fontWeight: "500", cursor: "not-allowed" }} className="tabFontSize">
                          TeleMarketing
                        </label>

                      </div>
                      <div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                        <input
                          // id={"display" + " " + item.country}
                          id={"display " + item.country}  //kiran-4745-removing console warning-
                          type="checkbox"
                          name="Display"
                          style={{ pointerEvents: "none", cursor: "not-allowed" }}
                          defaultChecked={item.Display === "Yes" ? true : false}
                        />

                        <label style={{ fontWeight: "500", cursor: "not-allowed" }} className="tabFontSize">
                          Display
                        </label>
                     </div>
                      <div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                        <input
                          id={"hybrid "  + item.country} //kiran-4745-removing console warning
                          type="checkbox"
                          name="Hybrid"
                          style={{ pointerEvents: "none", cursor: "not-allowed" }}
                          defaultChecked={item.Hybrid === "Yes" ? true : false}
                        />
                        <label style={{ fontWeight: "500", cursor: "not-allowed" }} className="tabFontSize">
                          Hybrid
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                        <input
                          id={"social " + item.country}//kiran-4745-removing console warning
                          type="checkbox"
                          name="Social"
                          style={{ pointerEvents: "none", cursor: "not-allowed" }}
                          defaultChecked={item.Social === "Yes" ? true : false}
                        />
                        <label style={{ fontWeight: "500", cursor: "not-allowed" }} className="tabFontSize">
                          Social
                        </label>
                      </div>
                      <div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                        <input
                          id={"email " + item.country}//kiran-4745-removing console warning
                          type="checkbox"
                          name="Email"
                          style={{ pointerEvents: "none", cursor: "not-allowed" }}
                          defaultChecked={item.Email === "Yes" ? true : false}
                        />

                        <label style={{ fontWeight: "500", cursor: "not-allowed" }} className="tabFontSize">
                          Email
                        </label>
                      </div>
                      <div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                        <input
                          id={"others " + item.country}//kiran-4745-removing console warning
                          type="checkbox"
                          name="Others"
                          style={{ pointerEvents: "none", cursor: "not-allowed" }}
                          defaultChecked={item.Others === "Yes" ? true : false}
                        />
                        <label style={{ fontWeight: "500", cursor: "not-allowed" }} className="tabFontSize">
                          Others
                        </label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 " >
                        <input
                          id={"programming "  + item.country}//kiran-4745-removing console warning
                          type="checkbox"
                          name="Programming"
                          style={{ pointerEvents: "none", cursor: "not-allowed" }}
                          defaultChecked={item.Programming === "Yes" ? true : false}
                        />
                        <label style={{ fontWeight: "500", cursor: "not-allowed" }} className="tabFontSize">
                          Programming
                        </label>
                      </div>
                      <div class="col-xs-12  col-sm-12 col-md-5 col-lg-6 col-xl-6 " >
                        <input
                          id={"Email/Telemarketing "  + item.country}//kiran-4745-removing console warning
                          type="checkbox"
                          name="EmailAndTelemarketing"
                          style={{ pointerEvents: "none", cursor: "not-allowed" }}
                          defaultChecked={item.EmailAndTelemarketing === "Yes" ? true : false}
                        />
                        <label style={{ fontWeight: "500", cursor: "not-allowed" }} className="tabFontSize">
                          Email/Telemarketing
                        </label>
                      </div>

                    </div>
                  </td>
                  <td><textarea style={{ height: "50%", width: '100%', resize: 'none' }} defaultValue={item.note} id="note" rows="10" cols="30" name="note" onChange={this.handleChange.bind(this, i)} /></td>

                  <td>
                    {item.status === 'BidAccepted' ?
                      <select disabled style={{ height: '60%' }} id="status" name="status"
                        country={item.country} bID={item.bID} pID={item.pID}
                        onChange={this.handleBidStatus.bind(this, i)}>
                        <option value="Select">--Select--</option>
                        <option value="Accepted" selected>Accept</option>
                        <option value="Rejected">Reject</option>
                      </select>
                      :
                      item.status === 'BidRejected' ?
                        <select disabled style={{ height: '60%' }} id="status" name="status"
                          country={item.country} bID={item.bID} pID={item.pID}
                          onChange={this.handleBidStatus.bind(this, i)}>
                          <option value="Select">--Select--</option>
                          <option value="Accepted" >Accept</option>
                          <option value="Rejected" selected>Reject</option>
                        </select>
                        :
                        item.status === 'Bid' || 'Accepted' || 'Rejected' ?
                          <select style={{ height: '60%' }} id="status" name="status"
                            country={item.country} bID={item.bID} pID={item.pID}
                            onChange={this.handleBidStatus.bind(this, i)}>
                            <option value="Select">--Select--</option>
                            <option value="Accepted">Accept</option>
                            <option value="Rejected">Reject</option>
                          </select>
                          : ""
                    }

                  </td>
                 
                  {//task-3840-Create-Audience Match & Audience Reach-Agency -File Download-FE -- added RFPdocumentDownloadAudienceReach() to call download function
                  <td  >
                  
                      <img
                                  alt="" //rutuja 2832 added alt tag
                                  src="back_button.png"
                                  id={item.campID}
                                  style={{ height: "32px", width: "32px",transform: "rotateZ(270deg)",float:"left"}}
                                  onClick={(e) => this.RFPdocumentDownloadAudienceReach(e, item)}></img>
                  </td>
                           }
                </tr>
              ))}
            </tbody>       
          </table>
          
          <div class="float-right" style={{marginBottom:'50px'}}> {/*sunita-task-3389- added css as button is not showing it's getting hide */}
            {this.state.enableFlag === true ?

              <button type="button" class="btn btn-info " onClick={this.submitBidding.bind(this)} style={{ backgroundImage: "linear-gradient(to right,#28166f,#007cc3)", color: "white", height: '31px' }}>
                Submit</button>
              :
              <button type="button" class="btn btn-info " disabled onClick={this.submitBidding.bind(this)} style={{ backgroundImage: "linear-gradient(to right,#28166f,#007cc3)", color: "white", height: '31px' }}>
                Submit</button>
            } 
          </div>  
          
        </div>

        <div class="modal" id="myModal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">RFP Allocation</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="modal-body">
                <div class="table-responsive-lg">

                  <table class=" table table-striped">
                    <thead>
                      <tr class="info">
                        <th class="table-header">Publisher Name</th>
                        <th class="table-header">Allocate</th>
                      </tr>


                    </thead>
                  </table>


                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>//end of main div

    );//end of return
      
  }//end of render
}// end of class


 /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
 */

RequestForProposalAgency.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(RequestForProposalAgency));

