/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sandeep Dhawale 
@file Name:helpOption.js
@Description: UI for helpOption
*/

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../login/authentication";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
// import { Button, Badge } from "antd"; //Nilesh-2833-Removing console warning
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { FaArrowAltCircleLeft } from "react-icons/fa"; //Nilesh-2833-Removing console warning
// import { Modal } from "react-bootstrap"; //Nilesh-2833-Removing console warning
import "./helpOption.css";
// const Swal = require("sweetalert2"); //Nilesh-2833-Removing console warning
// const queryString = require("query-string"); //Nilesh-2833-Removing console warning
// var region;
class HelpOption extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      console.log("Log");
    }
  }

  render() {
    // const { isAuthenticated, user } = this.props.auth; //Nilesh-2833-Removing console warning
    return (
      <div>
        <Navigation />
        <div className="container-fluid " style={{ paddingTop: "115px" }}>
          <div class="row">
            <div class="col-sm-12 col-md-12 mt-5 col-lg-12 col-xl-12 col-xs-12 text-center">
              <label
                style={{
                  fontSize: "larger",
                  color: "#056FB9",
                  fontWeight: "bold",
                  paddingBottom: "11px",
                }}
              >
                How Can We Help You?
              </label>
            </div>
          </div>

          <div className="input text-center ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xs-12">
              <input
                type="text"
                name="search"
                placeholder="type keyword to find answer"
                style={{
                  backgroundImage: "url(searchIcon.png)",
                  backgroundPosition: "15px 12px",
                  backgroundSize: "15px 15px",
                  backgroundRepeat: "no-repeat",
                  width: "300px",
                  height: "35px",
                  marginTop: "10px",
                  fontSize: "15px",
                  align: "center",
                  //  float:'right',

                  border: "1px solid lightGray",
                  padding: "12px 20px 12px 34px",
                  marginBottom: "30px",
                  borderRadius: "25px",
                  //    backgroundColor: "rgb(221, 221, 221)"
                }}
                onChange={this.handleChangeSearch}
                onPressEnter={this.handleChangeSearch}
              />
            </div>
          </div>
          <br />
          <div class="row" style={{ fontSize: "larger", fontWeight: "bold" }}>
            <div
              class="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xs-6"
              style={{ color: "#14254A", marginBottom: "10px" }}
            >
              Getting Started
              <p />
              <a href="#/" //Nilesh-2833-Removing console warning
              >
                <p id="signup" style={{ color: "#0000EE", fontSize: "12px" }}>
                  Sign up for DI Account
                </p>
              </a>
              <a href="#/"  //Nilesh-2833-Removing console warning
               >
                <p style={{ color: "blue", fontSize: "12px" }}>
                  Add Client / Publisher / Agency{" "}
                </p>
              </a>
            </div>
            <div
              class="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xs-6"
              style={{ color: "#14254A", marginBottom: "10px" }}
            >
              Campaign Related Action
              <p />
              <a href="createCampaignHelp">
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Create Campaign
                </p>
              </a>
              <a href="editCampaignHelp">
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Edit Campaign
                </p>
              </a>
              <a href="publisherAllocationHelp">
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Campaign Allocation
                </p>
              </a>
            </div>
            <div
              class="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xs-6"
              style={{ color: "#14254A", marginBottom: "10px" }}
            >
              Campaign Related Action
              <p />
              <a href="#/" //Nilesh-2833-Removing console warning
              >
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Clone Campaign
                </p>
              </a>
              <a href="#/"  //Nilesh-2833-Removing console warning
              >
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Pause Campaign{" "}
                </p>
              </a>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xs-6 ">
              <label>What's New</label>
              <textarea id="labelNew" style={{ width: "90%", resize: "none" }}>
                {" "}
                {/* Mufiz Task-3910 RESPONSIVE---Prod Issue-Agency side-setting-add user/ add publisher /Help*/}
                {/* // onChange={this.handleChange} */}
              </textarea>
            </div>
          </div>

          <div class="row" style={{ fontSize: "larger", fontWeight: "bold" }}>
            <div
              class="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xs-6"
              style={{ color: "#14254A", marginBottom: "10px" }}
            >
              Campaign Related Action
              <p />
              <a href="#/" //Nilesh-2833-Removing console warning
              >
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Pause Campaign{" "}
                </p>
              </a>
              <a href="#/" //Nilesh-2833-Removing console warning
              >
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Edit End Date
                </p>
              </a>
            </div>
            <div
              class="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xs-6"
              style={{ color: "#14254A", marginBottom: "10px" }}
            >
              Campaign Related Action
              <p />
              <a href="#/"  //Nilesh-2833-Removing console warning
              >
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Resume Campaign
                </p>
              </a>
              <a href="#/" //Nilesh-2833-Removing console warning
              >
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Complete Campaign
                </p>
              </a>
            </div>
            <div
              class="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xs-6"
              style={{ color: "#14254A", marginBottom: "10px" }}
            >
              Campaign Related Action
              <p />
              <a href="#/"  //Nilesh-2833-Removing console warning
              >
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Review Creative{" "}
                </p>
              </a>
              <a href="#/"  //Nilesh-2833-Removing console warning
              >
                <p style={{ color: "#0000EE", fontSize: "12px" }}>
                  Review Lead
                </p>
              </a>
            </div>
          </div>

          <div class="row">
            <label
              style={{
                fontSize: "larger",
                fontWeight: "bold",
                paddingTop: "5px",
                marginLeft: "12px",
                color: "#14254A",
                marginBottom: "10px",
              }}
            >
              Frequently Asked By User
            </label>{" "}
          </div>
          <p
            className="allpublisher"
            style={{ color: "#0000EE", fontSize: "12px", fontWeight: "bold" }}
          >
            How I Can Upload Lead?
          </p>
          <p
            className="allpublisher"
            style={{ color: "#0000EE", fontSize: "12px", fontWeight: "bold" }}
          >
            How I Can Edit End Date For All Publisher?
          </p>
          <p
            className="allpublisher"
            style={{ color: "#0000EE", fontSize: "12px", fontWeight: "bold" }}
          >
            How To Cancel Campaign For Particular Publisher?
          </p>
        </div>{" "}
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
} //end of class
/**
 * @author Sandeep Dhawale
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
HelpOption.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(HelpOption));
