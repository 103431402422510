/* Copyright(c) 2023 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file Report Generation Page
 * @author kiran mate 4721
 * @version 1.0
 * @section generate Reports
 * */

import { saveAs } from "file-saver"; 
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import React from "react";
import AdminNavpage from "../layouts/adminNavpage";
import { FaArrowAltCircleLeft } from "react-icons/fa";
//import Picky from "react-picky";//RUtuja 4796 commented as never used
import "react-picky/dist/picky.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import { Tooltip,Icon } from "antd";
import styled from "styled-components";
const xlsx = require("xlsx");
const Swal = require("sweetalert2");


const HtmlTooltip = styled(Tooltip)`
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: '12px',
		border: '1px solid #dadde9',
		`;
    // kiran-4721-added for tooltip
const companyNameOptions = [
  { id: "APSS", name: "APSS" },
  { id: "Machbizz", name: "Machbizz" },
];
//RUtuja 4796 commented as never used
 // const MONTHS = [
//   'January', 'February', 'March', 'April', 'May', 'June',
//   'July', 'August', 'September', 'October', 'November', 'December'
// ];

class GenerateReports extends React.Component {
  constructor() {
    super();
    this.state = {
      companyType: [],
      selectedDate: "",
      buttonDisplay: "disabled btn add-button",
      loader_progress: "",
      selectedDateforMonth:"",
      result: [],
      emailError: "",
    };
    this.oncompanyNameHandleChange = this.oncompanyNameHandleChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.dateHandleChange = this.dateHandleChange.bind(this);
    this.handleChangeWeek = this.handleChangeWeek.bind(this);
    this.generateHandleChange = this.generateHandleChange.bind(this);
    this.handleChangeMonths = this.handleChangeMonths.bind(this);
    this.dateHandleChangeforMonth=this.dateHandleChangeforMonth.bind(this);
    this.handleChangeEmail=this.handleChangeEmail.bind(this);
  }
  handleChangeMonths(e){
    e.preventDefault();
    let month = e.target.value;
    this.setState({
      month: month,
      buttonDisplay: "btn add-button",
    });

  }
 
  generateHandleChange(e) {
    let companyType = this.state.companyNameArray;
    let report = this.state.report;
    let selectedDate = this.state.selectedDate;
    //let month=this.state.month;//RUtuja 4796 commented as never used
    //let emailW=this.state.emailW;//RUtuja 4796 commented as never used
    if(report==="Daily")
    {
      //kiran-4774-added undefined condition in if
    if (companyType !== undefined && report !== "" && selectedDate !== "") {
      this.setState({ loader_progress: "loader_campaign_list" });
      let data = {
        companyType: companyType,
        report: report,
        selectedDate: selectedDate,
      };
      fetch("/admin/DailyReportData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        
        .then((result) => {
          this.setState({ loader_progress: "" });
             var date = new Date(selectedDate);
            var month= date.toLocaleString('en-US', { month: 'long' });
           // var monthNumber=date.getMonth();//RUtuja 4796 commented as never used
          if (result.length > 0) {
            var ws = xlsx.utils.json_to_sheet(result);
            var wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, ws, "Lead");
            var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (let i = 0; i !== s.length; ++i)
                    view[i] = s.charCodeAt(i) & 0xff;
                return buf;
            }
           
           
            var fileName = companyType + " " + report + " " + month + " "+ selectedDate + ".xlsx";
            saveAs(
                new Blob([s2ab(buf)], { type: "application/octet-stream" }),
                fileName
            );
            // kiran-4782-added swal msg and page refreshed
            Swal.fire({
              html: "Report is generated.",
              type: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              },
            })
          } else {
            Swal.fire({
              type: "warning",
              title: "No records Found",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
            this.setState({ loader_progress: "" });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      Swal.fire({
        type: "warning",
        title: "Please Select All Mandatory Fields",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
      this.setState({ loader_progress: "" });
    }
  }
  else if(report==="Monthly"){
    let companyType = this.state.companyNameArray;
    let report = this.state.report;
    let month=this.state.month;
    //kiran-4774-added undefined condition in if
    //kiran-4782-added empty condition for month
    if (companyType !== undefined && report !== "" && (month!== undefined && month !== "")) {
      let data = {
        companyType: companyType,
        report: report,
        month: month,
      };
      fetch("/admin/MonthlyReportData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      .then((res) => res.json())
      .then((result) => {
        //Kiran-4780-Added below code to merge all promise result
        this.setState({ loader_progress: "" });
          if (result[0].Sum_of_Lead_Upload_Count > 0 && result[0].Count_of_Allocated_Leads > 0 && result[0].Total_Lead_Count > 0) {
          var ws = xlsx.utils.json_to_sheet(result);
          var wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Lead");
          var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
          function s2ab(s) {
              var buf = new ArrayBuffer(s.length);
              var view = new Uint8Array(buf);
              for (let i = 0; i !== s.length; ++i)
                  view[i] = s.charCodeAt(i) & 0xff;
              return buf;
          }
          var fileName = companyType + " " + report + " " + month + ".xlsx";
          saveAs(
              new Blob([s2ab(buf)], { type: "application/octet-stream" }),
              fileName
          );
          // kiran-4782-added swal msg and page refreshed
          this.setState({ month: "" });
          Swal.fire({
            html: "Report is generated.",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          })
          } 
          else{
            Swal.fire({
              type: "warning",
              title: "No records Found",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
            this.setState({ loader_progress: "" });
          }
        })
      .catch(function (err) {
        console.log(err);
      });

    }
  
  else {
    Swal.fire({
      type: "warning",
      title: "Please Select All Mandatory Fields",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    });
    this.setState({ loader_progress: "" });
  }
 
  }
  else{ 
    //for weekly report
    let companyType = this.state.companyNameArray;
    let report = this.state.report;
    let emailW=this.state.emailW;
    let data = {
      companyType: companyType,
      report: report,
      emailW:emailW, 
    };
    //kiran-4774-added undefined condition in if
    if (( companyType !==undefined) && (report !== "") && (emailW !== undefined && emailW !== "")) {
    fetch("/admin/WeeklyReportData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    Swal.fire({
      html: "Report is generating, You will receive mail after report is generated.",
      type: "success",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      preConfirm: () => {
        window.location.reload();
      },
    })
    //kiran-4774-page refresh
    this.setState({
      buttonDisplay: "disabled btn add-button",
    });
    }
    else{
      Swal.fire({
        type: "warning",
        title: "Please Select All Mandatory Fields",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
      this.setState({ loader_progress: "" });
    }
  }
  }

  handleChange(e) {
    e.preventDefault();
   // const { name, value } = e.target;//RUtuja 4796 commented as never used
    //const { isAuthenticated, user } = this.props.auth;//RUtuja 4796 commented as never used

    if (e.target.value === "Weekly") {
      this.setState({
        weeklyDiv: true,
      });
      // this.setState({
      //   buttonDisplay: "btn add-button",
      // });
      this.setState({emailW:""}) //kiran-4782-set value to blank for showing proper message
    } else {
      this.setState({
        weeklyDiv: false,
      });
    }

    if (e.target.value === "Monthly") {
      this.setState({
        monthDiv: true,
      });
      this.setState({month:""}) //kiran-4782-set value to blank for showing proper message
    } else {
      this.setState({
        monthDiv: false,
      });
    }
    if (e.target.value === "Daily") {
      this.setState({
        dailyDiv: true,
      });
      this.setState({selectedDate:""}) //kiran-4782-set value to blank for showing proper message
    } else {
      this.setState({
        dailyDiv: false,
      });
    }
    let report = e.target.value;
    this.setState({ report: report });
  }
  handleChangeWeek(e) {
   // const { name, value } = e.target;//RUtuja 4796 commented as never used
    //const { isAuthenticated, user } = this.props.auth;//RUtuja 4796 commented as never used
    this.setState({
      buttonDisplay: "btn add-button",
    });
  }
    oncompanyNameHandleChange = e => {
    const companyType = e.target.value;
    let companyNameArray = [];
    if (companyType === "APSS") {
      companyNameArray.push("APSS");
    } else if (companyType === "Machbizz") {
      companyNameArray.push("Machbizz");
    }
    this.setState({ companyType, companyNameArray });
  }
  dateHandleChange(e) {
    e.preventDefault();
    let selectedDate = e.target.value;
    this.setState({
      selectedDate: selectedDate,
      buttonDisplay: "btn add-button",
    });
  }
  dateHandleChangeforMonth(e){
    e.preventDefault();
    let selectedDateforMonth = e.target.value;
    this.setState({
      selectedDateforMonth: selectedDateforMonth,
      buttonDisplay: "btn add-button",
    });

  }
  //kiran-4764-handlechange for email
  handleChangeEmail(e){
    var emailW = e.target.value;
    this.setState({ emailW: emailW });
    
    var pattern = new RegExp(
       /^\s*$|^[\.a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@(?!gmail.com)(?!gmail.co.in)(?!yahoo.com)(?!yahoo.co.in)(?!facebook.com)(?!facebook.co.in)[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/
         );
    if (emailW.match(pattern)) {
      this.setState({ emailError: "" });
    } else {
      this.setState({ emailError: "Please enter a valid Email" });
    }
    this.setState({
      buttonDisplay: "btn add-button",
    });
    //kiran-4774-button enabled
  }
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    }
  }
  render() {
    const asterisk = { color: "red" };
    return (
      <div>
        <AdminNavpage />
        <div className="container-fluid" style={{ paddingTop: "100px" }}>
          <div className="row">
            <div
              className="col-lg-1 col-md-1 col-sm-1"
              style={{ float: "left", marginLeft: "40px" }}
            >
              {/* rutuja 4796 added href */}
              <a href="adminDashboard" 
                onClick={() =>
                  this.props.history.push("/adminDashboard", {
                    agencyID: this.state.agencyID,
                  })
                }
                style={{ color: "#056eb8", right: "33%", marginLeft: "80px" }}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  style={{ float: "left" }}
                  title="Back to Dashboard"
                />
              </a>
            </div>

            <div className="col-lg-9 col-md-9 col-sm-9" align="center">
              <center>
                <label
                  id="labelDI"
                  style={{
                    fontSize: "28px",
                    fontStyle: "roboto",
                    fontWeight: "500",
                    color: "#193D8F",
                  }}
                  align="center"
                >
                  Generate Reports
                </label>
              </center>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1"></div>
          </div>

          <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3" id="AD_ALTD">
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Company Name &nbsp;<span style={asterisk}>*</span>
                &nbsp;&nbsp;&nbsp;
              </label>
              <br />
              <div>
                {/* kiran-4782-changed radio button to dropdown */}
                <select
        value={this.state.companyType}
        onChange={this.oncompanyNameHandleChange}
        id="companyType"
        name="companyType"
        className={"PickyStyle"}

        class="form-control"
        style={{
          height: "calc(2.25rem + 12px)",
          borderRadius: "4px",
          width: "180px",
        }}
      >
        <option selected disabled value="">Select Company</option>
        {companyNameOptions.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3" id="AD_ALTD">
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Report Type &nbsp;<span style={asterisk}>*</span>
                &nbsp;&nbsp;&nbsp;
              </label>
              {/* kiran-4721-added tooltip */}
              {this.state.weeklyDiv && (   
              <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment> 
                               
                              <li>
                                {
                                  "Weekly Report gives previous week data"
                                }
                              </li>
                          </React.Fragment>
                        }
                      >
                        <Icon type="question-circle" />
                      </HtmlTooltip>
                       )}

                        {this.state.monthDiv && (
                          <HtmlTooltip
                          placement="right"
                          title={
                            <React.Fragment> 
                                  <li>
                                  {
                                    "Monthly Report gives current system year data"
                                  }
                                </li>
                                
                            </React.Fragment>
                          }
                        >
                          <Icon type="question-circle" />
                        </HtmlTooltip>
                        )}
              <br />
              <div>
                <select
                  required
                  id="report"
                  name="report"
                  value={this.state.report}
                  style={{
                    height: "calc(2.25rem + 12px)",
                    borderRadius: "4px",
                    width: "180px",
                  }}
                  onChange={this.handleChange}
                  class="form-control"
                >
                  <option selected value="Select Report Type" disabled>
                    Select Report Type
                  </option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
                    
              </div>
              {/* {this.state.weeklyDiv && (
              <span
											style={{ color:"#299617" }}
											>
											Weekly Report gives previous week data
										</span>
                     )} */}
            </div>
            {this.state.dailyDiv && (
              <div
                className="col-12 col-sm-6 col-md-6 col-lg-3 cl-LeadTracking"
                id="AD_ALTD"
              >
                <label
                  style={{
                    fontSize: "14px",
                    color: "#14254A",
                    fontFamily: "sans-serif",
                  }}
                >
                  Select Date&nbsp;<span style={asterisk}>*</span>&nbsp;
                </label>
                <br />
                <input
                  style={{
                    width: "220px",
                    border: "1px solid #CCCCCC",
                    borderRadius: "3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: "#555555",
                    lineHeight: "2",
                  }}
                  type="date"
                  pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                  value={this.state.selectedDate}
                  className="form-control inputStyle "
                  onChange={this.dateHandleChange}
                  required
                />
              </div>
            )}
            {this.state.monthDiv && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-3" id="AD_ALTD">
                <label
                  style={{
                    fontSize: "14px",
                    color: "#14254A",
                    fontFamily: "sans-serif",
                  }}
                >
                  Select Month &nbsp;<span style={asterisk}>*</span>
                  &nbsp;&nbsp;&nbsp;
                </label>
                <br />
                <div>
                  <select
                    required
                    value={this.state.months}
                    style={{
                      height: "calc(2.25rem + 12px)",
                      borderRadius: "4px",
                      width: "180px",
                    }}
                    onChange={this.handleChangeMonths}
                    class="form-control"
                  >
                    <option selected value="Select Month" disabled>
                      Select Month
                    </option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>

                  </select>
                </div>
              </div>
            )}
            {/* kiran-4764-added email input for weekly report */}
            {this.state.weeklyDiv && (
            <div className="col-12 col-sm-6 col-md-6 col-lg-3" id="AD_ALTD">
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Email &nbsp;<span style={asterisk}>*</span>
                &nbsp;&nbsp;&nbsp;
              </label>
              {/*kiran- 4774-added tooltip */}
              <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment> 
                               
                              <li>
                                {
                                  "Report will get send to the mentioned EmailID"
                                }
                              </li>
                          </React.Fragment>
                        }
                      >
                        <Icon type="question-circle" />
                      </HtmlTooltip>
              <br />
              <input
                type="text"
                name="emailW"
                id="emailW"
                required
                onChange={(e) => this.handleChangeEmail(e)}
                style={{
                  height: "calc(2.25rem + 12px)",
                    borderRadius: "4px",
                    width: "180px",
                }}
                class="form-control"
              />
              {this.state.emailError && (
                      <div style={{ color: "red" }}>{this.state.emailError}</div>
               )}
              </div>
            )}
          </div>
          <div className="row" style={{ marginTop: "10px" }}>
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-start"
              id="AD_ALTD"
            >
              <br />
              <button
                type="button"
                className={this.state.buttonDisplay}
                onClick={this.generateHandleChange}
                disabled={this.state.emailError !== ""}
              >
                {/* kiran-4764-button label changed for weekly */}
                {this.state.weeklyDiv ? 'Send to Email' : 'Generate'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
GenerateReports.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(GenerateReports)
);
