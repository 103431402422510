/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*
/*
@author:Sanjana Godbole
@Creation Date:12-09-2019
@Description:UI for AgencyFrontPage(Dashboard)
*/

import React from "react";
import * as $ from "jquery";
import "datatables.net";
import "antd/dist/antd.css";
import Footer from "../layouts/footer"; //4440-Nilesh

// import Moment from "react-moment";//kiran-4745-removing console warning-no used
import { Table, Radio, Input, Button, Icon, Badge, Card, Avatar } from "antd";

import Highlighter from "react-highlight-words";
import Navigation from "../layouts/navPage";

import "./dashboard.css";

import { css } from "emotion";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { Chart } from "react-google-charts";
import CanvasJSReact from "../../assets/canvasjs.react";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const Swal = require("sweetalert2");
// var budgetValidate = true;
// var cplValidate = true;
// var dateTime = require("node-datetime");//kiran-4745-removing console warning-no used
const tableCSS = css({
  backgroundColor: "white",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
    color: "white",
    fontSize: "12px",
    fontWeight: "500",
  },
  "& thead > tr >th": {
    color: "white",
  },
});

// ];//kiran-4745-removing console warning-no used
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unreadAlertsMap: [
        { unreadAlertsID: "0", unreadAlertsText: "" },
        { unreadAlertsID: "1", unreadAlertsText: "" },
        { unreadAlertsID: "2", unreadAlertsText: "" },
        { unreadAlertsID: "3", unreadAlertsText: "" },
        { unreadAlertsID: "4", unreadAlertsText: "" },
        { unreadAlertsID: "5", unreadAlertsText: "" },
      ],
      fields: {},

      errors: {},
      disabletip: "tooltip-top",
      agencyID: "",
      campaignDetails: [],
      dashboardCampaignCount: [],
      activeKey: "",
      pacing: "Monthly",
      pageSize: 5,
      pageSize1: 5,
      pageSize2: 5,
      pageSize3: 5,
      pageSize4: 5,
      stateCopy: [
        {
          allocatedLead: "",
          cpl: "",
        },
      ],
      biddingArray: [],
      reviewArray: [],
      tabReview: "creatives",
      color1: "#ffffff",
      color2: "#f1f1f1",
      linkActive: "",
      linkActive1: "active",
      linkActive2: "",
      linkActive3: "",
      linkActive4: "",
      linkActive5: "",
      headExampleAdv: "head-exampleGeneralAdv", //for advertiser tab
      headExamplePub: "head-exampleGeneralPub", //for publisher tab
      headExample: "head-example",
      headExample1: "head-exampleGeneral1Color",
      headExample2: "head-example2",
      headExample3: "head-exampleGeneral3",
      headExample4: "head-exampleGeneral4",
      headExample5: "head-exampleGeneral5",
      headExample6: "head-exampleGeneral6",
      tableState: { size: "small" }, //Array for table properties
      tableSearchText: "",
      downloadFiles: [],
      tableSearchText1: "",
      tableSearchText2: "",
      campaignDetailsSearch: [],
      columnUnreadMsgSearch: [],
      columnUnreadAlertsSearch: [],
      show: "none",
      ReviewTab: "",
      RFPBiddingTab: "Counter",
      // tableState: { size: "small" }, //Array for table properties //kiran-4745-removed console warning-remove duplicate
      step: 1,
      alertBorder: "",
      alertDisplay: "none",
      extension: "",
      tab: "",
      tabName: "Active",
      incrementLeads: "",
      incrementCampDetails: [],
      campID: "",
      loading: true,
      loading1: true,
      loading2: true,
      parentCampID: "",
      reallocationID: "",
      counterLength: "",
      rejectLength: "",
      creativeLength: "",
      leadsLength: "",
      endDateCampaign: [],
      reviewTab1: "",
      inputClass: "btn add-button",
      months: [],
      invoiceLength: "",
      monthArrayLength: "",
      unreadMessages: [],
      userName: "",
      unreadMessagesLength: 0,
      unreadAlerts: [],
      unreadAlertsLength: 0,
      panelBlock: "",
      newDate: "",
      rfpBiddingLength: "",
      modalShow: false,
      byCampaign: true,
      byClient: true,
      byLead: false,
      byPublisher: false,
      publisherList: [],
      clientList: [],
      clientName: "All Clients",
      pID: "All Publishers",
      pieChartArray: [],
      barChartArray: [],

      campaignCount: [],
      setupCount: "",
      allocateCount: "",
      respondCount: "",
      activeAcceptedArray: [],
      pausedQaArray: [],
      completedDeleveredArray: [],
      cancelRejectedArray: [],
      dataExist: true,
      dateDisplay: "none",
      startDate: "",
      endDate: "",
      selectedOption: "Campaign",
      label: "Ending This Month ",
      tabkey: "", //sunita-task-3270-added for getting key as per status
      alertShows: "" //Nilesh-5067- Added alertShows for checking alerts data
    }; //end of state

    this.funByClient = this.funByClient.bind(this);
    this.funByPublisher = this.funByPublisher.bind(this);
    this.dataFilter = this.dataFilter.bind(this);
    this.dataFilterClientPub = this.dataFilterClientPub.bind(this);
    this.biddingLink = this.biddingLink.bind(this);
    this.reviewLink = this.reviewLink.bind(this);
    //shivani-3634- commented below handlechange for performance review task .
    this.displayCampaignSpecificationMsg =
      this.displayCampaignSpecificationMsg.bind(this);
    this.handleChangePageSize1 = this.handleChangePageSize1.bind(this);
    this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
    //shivani-3634- commented below handlechange for performance review task .
    this.handleRefreshData = this.handleRefreshData.bind(this);
    this.creativesReview = this.creativesReview.bind(this);
    this.allocatedCampaign = this.allocatedCampaign.bind(this);
    this.rFPBiddingTab = this.rFPBiddingTab.bind(this);
    this.leadReview = this.leadReview.bind(this);
    //shivani-3634- commented below handlechange for performance review task .
    this.displayCampaignSpecificationAlerts =
      this.displayCampaignSpecificationAlerts.bind(this);
    this.commonhandleChange = this.commonhandleChange.bind(this);
    this.handleMarkAllReadAndClear = this.handleMarkAllReadAndClear.bind(this);
    this.openViewAllCreatives = this.openViewAllCreatives.bind(this); //Sandeep-task-3653-added handle change for creativesReview
    this.openViewAllLeadReview = this.openViewAllLeadReview.bind(this); //Sandeep-task-3653-added handle change for leadsReview
  } // end of constructor

  /**
   * @author Sandeep Dhawale
   * @param  Description handle the lead data
   * @return Description return to go on agencyReviewLead page
   */
  openViewAllLeadReview(e) {
    e.preventDefault();
    const { user } = this.props.auth;
    let agencyID = user.id;
    let campID = this.state.reviewArray[0].campID;
    let parentCampID = this.state.reviewArray[0].parentCampID;
    let reallocationID = this.state.reviewArray[0].reallocationID;
    let tabkey = this.state.reviewArray[0].tab;
    this.props.history.push("/agencyReviewLead", {
      campID,
      parentCampID,
      reallocationID,
      agencyID,
      tabkey,
    }); //karan-task-3715-replace query params
  }
  /**
   * @author Sandeep Dhawale
   * @param  Description handle the creatives data
   * @return Description return to go on agencyCreativesReview page
   */
  openViewAllCreatives(e) {
    e.preventDefault();
    const { user } = this.props.auth;
    let agencyID = user.id;
    let campID = this.state.reviewArray[0].campID;
    let parentCampID = this.state.reviewArray[0].parentCampID;
    let reallocationID = this.state.reviewArray[0].reallocationID;
    let backKey = "Dash";
    this.props.history.push("/agencyCreativesReview", {
      campID,
      parentCampID,
      reallocationID,
      agencyID,
      backKey,
    }); //karan-task-3715-replace query params
  }

  displayCampaignSpecificationAlerts(e) {
    e.preventDefault(); //karan-task-3895-link issue
    var campID = e.target.getAttribute("campID");
    // var campaignName = e.target.getAttribute("campaignName");
    var parentCampID = e.target.getAttribute("parentCampId");
    // var reallocationID = e.target.getAttribute("reallocationId");//kiran-4745-removing console warning-no used
    var alertID = e.target.getAttribute("alertID");
    let data = { alertID: alertID, clickedcampaignID: campID };

    fetch("/notification/AgencyAlertsToAgecncyStatusChanged", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())

      .catch(function (err) {
        console.log(err);
      });

    this.props.history.push("/agencyUnreadAlerts", { campID, parentCampID }); //karan-task-3715-replace query params
  }
  //shivani-3634- commented below handlechange for performance review task .

  /**
   * @author Supriya Gore
   * @param  Description handle the End Date Of Campaign
   * @return Description return All End Date Of Campaign
   */

  creativesReview(e) {
    e.preventDefault();
    const { user } = this.props.auth;
    var agencyID = user.id;
    var campID = e.target.getAttribute("campID");

    var parentCampID = e.target.getAttribute("parentCampId");
    var reallocationID = e.target.getAttribute("reallocationId");
    var backKey = "Dash";
    this.props.history.push("/agencyCreativesReview", {
      campID,
      parentCampID,
      reallocationID,
      agencyID,
      backKey,
    }); //karan-task-3715-replace query params
  }

  leadReview(e) {
    e.preventDefault(); //karan-task-3895-link issue
    const { user } = this.props.auth;
    var agencyID = user.id;
    var campID = e.target.getAttribute("campID");
    var tabkey = e.target.getAttribute("tabkey"); //sunita-task-3270-added for fetching the camp id as per status to open the particular tab on agencyreviewlead page
    var parentCampID = e.target.getAttribute("parentCampId");
    var reallocationID = e.target.getAttribute("reallocationId");
    this.props.history.push("/agencyReviewLead", {
      campID,
      parentCampID,
      reallocationID,
      agencyID,
      tabkey,
    }); //karan-task-3715-replace query params
  }
  //shivani-3634- commented below handlechange for performance review task .

  /**
   * @author Somnath Keswad
   * @param  Description handle Refresh table data on event
   * @return Description return successfully updated data
   */
  handleRefreshData() {
    var data = { pacing: this.state.pacing }; //karan-task-3796-vapt remove ids

    fetch("/agencyDashboard/dashboardCampaignCount", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((dashboardCampaignCount) =>
        this.setState({ dashboardCampaignCount: dashboardCampaignCount })
      )
      .catch(function (err) {
        console.log(err);
      });
    if (this.state.tab === "Active") {
      //kiran-4745-removing console warning-changed == to ===
      fetch("/agencyDashboard/dashboardActiveCampaign", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((campaignDetails) =>
          this.setState({ campaignDetails: campaignDetails })
        )
        .catch(function (err) {
          console.log(err);
        });
    } else if (this.state.tab === "All") {
      fetch("/agencyDashboard/dashboardAllCampaign", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((campaignDetails) =>
          this.setState({ campaignDetails: campaignDetails })
        )
        .catch(function (err) {
          console.log(err);
        });
    } else if (this.state.tab === "Pause") {
      fetch("/agencyDashboard/dashboardPausedCampaignNew", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((campaignDetails) =>
          this.setState({ campaignDetails: campaignDetails })
        )
        .catch(function (err) {
          console.log(err);
        });
    } else if (this.state.tab === "Completed") {
      fetch("/agencyDashboard/dashboardArchiveCampaign", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((campaignDetails) => {
          this.setState({ campaignDetails: campaignDetails });
        })
        .catch(function (err) {
          console.log(err);
        });
    } else if (this.state.tab === "cancelCamp") {
      fetch("/agencyDashboard/dashboardCancelCampaign", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((campaignDetails) => {
          this.setState({ campaignDetails: campaignDetails });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  dataFilterClientPub(e) {
    if (e.target.id === "clients") {
      //kiran-4745-removing console warning-changed == to ===
      let info = {
        pID: this.state.pID,
        byCampaign: this.state.byCampaign,
        byPublisher: false,
        clientName: this.state.clientName,
        pacing: this.state.pacing,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }; //karan-task-3796-vapt remove ids
      this.funByClient(info);
      this.setState({
        headExampleAdv: "head-exampleGeneralAdv",
        headExamplePub: "head-exampleGeneralPub",
        byClient: true,
        byPublisher: false,
        pID: "All Publishers",
        publisherName: "All Publishers",
      });
    } else {
      let info = {
        pID: this.state.pID,
        byCampaign: this.state.byCampaign,
        byPublisher: true,
        clientName: this.state.clientName,
        pacing: this.state.pacing,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }; //karan-task-3796-vapt remove ids
      this.funByPublisher(info);
      this.setState({
        headExampleAdv: "head-exampleGeneralPub",
        headExamplePub: "head-exampleGeneralAdv",
        byClient: false,
        byPublisher: true,
        clientName: "All Clients",
      });
    }
  }

  funByClient(info) {
    fetch("/agencyDashboard/dashboardByClient", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(info),
    })
      .then((res) => res.json())
      .then((chartInfo) => {
        let barchart = chartInfo[1];
        let active = 0,
          completed = 0,
          paused = 0,
          cancel = 0;
        let activeAcceptedArray = [],
          completedDeleveredArray = [],
          pausedQaArray = [],
          cancelRejectedArray = [];
        if (barchart.length > 0) {
          activeAcceptedArray = barchart[0];
          completedDeleveredArray = barchart[1];
          pausedQaArray = barchart[2];
          cancelRejectedArray = barchart[3];
          active = activeAcceptedArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
          completed = completedDeleveredArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
          paused = pausedQaArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
          cancel = cancelRejectedArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
        }

        let total = active + completed + paused + cancel;
        let dataExist = true;
        if (total === 0 || barchart.length === 0) {
          dataExist = false;
        }
        this.setState({
          pieChartArray: chartInfo[0],
          barChartArray: chartInfo[1],
          activeAcceptedArray,
          completedDeleveredArray,
          pausedQaArray,
          cancelRejectedArray,
          dataExist,
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  }
  funByPublisher(info) {
    fetch("/agencyDashboard/dashboardByPublisher", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(info),
    })
      .then((res) => res.json())
      .then((chartInfo) => {
        let barchart = chartInfo[1];
        let active = 0,
          completed = 0,
          paused = 0,
          cancel = 0;
        let activeAcceptedArray = [],
          completedDeleveredArray = [],
          pausedQaArray = [],
          cancelRejectedArray = [];
        if (barchart.length > 0) {
          activeAcceptedArray = barchart[0];
          completedDeleveredArray = barchart[1];
          pausedQaArray = barchart[2];
          cancelRejectedArray = barchart[3];
          active = activeAcceptedArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
          completed = completedDeleveredArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
          paused = pausedQaArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
          cancel = cancelRejectedArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
        }

        let total = active + completed + paused + cancel;
        let dataExist = true;
        if (total === 0 || barchart.length === 0 || chartInfo[1].length === 0) {
          dataExist = false;
        }

        this.setState({
          pieChartArray: chartInfo[0],
          barChartArray: chartInfo[1],
          activeAcceptedArray,
          completedDeleveredArray,
          pausedQaArray,
          cancelRejectedArray,
          dataExist,
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  }
  dataFilter(e) {
    // const { name, value } = e.target;
    e.preventDefault();

    if (e.target.id === "byCampaign") {
      // this.state.byCampaign = true;
      this.setState({ byCampaign: true }); //kiran-4745-removing console warning-use setState
      this.setState({
        byCampaign: true,
        byLead: false,
        linkActive1: "active",
        linkActive2: "",
        color1: "#f1f1f1",
        color2: "#ffffff",
      });
      if (this.state.byClient) {
        let info = {
          pID: this.state.pID,
          byCampaign: true,
          byPublisher: false,
          clientName: this.state.clientName,
          pacing: this.state.pacing,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        }; //karan-task-3796-vapt remove ids
        this.setState({
          byCampaign: true,
          byLead: false,
          byClient: true,
          byPublisher: false,
          linkActive1: "active",
          linkActive2: "",
          color1: "#ffffff",
          color2: "#f1f1f1",
        });
        this.funByClient(info);
      } else {
        this.setState({ byLead: true });
        let info = {
          pID: this.state.pID,
          byCampaign: true,
          byPublisher: true,
          clientName: this.state.clientName,
          pacing: this.state.pacing,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        }; //karan-task-3796-vapt remove ids
        this.setState({
          byCampaign: true,
          byLead: false,
          byClient: false,
          byPublisher: true,
          linkActive1: "active",
          linkActive2: "",
          color1: "#ffffff",
          color2: "#f1f1f1",
        });
        this.funByPublisher(info);
      }
    } else {
      this.setState({ byCampaign: false, byLead: true }); //kiran-4745-removing console warning-use setState

      if (this.state.byClient) {
        let info = {
          pID: this.state.pID,
          byCampaign: false,
          byPublisher: false,
          clientName: this.state.clientName,
          pacing: this.state.pacing,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        }; //karan-task-3796-vapt remove ids
        this.setState({
          byCampaign: false,
          byLead: true,
          byClient: true,
          byPublisher: false,
          linkActive1: "",
          linkActive2: "active",
          color1: "#f1f1f1",
          color2: "#ffffff",
        });
        this.funByClient(info);
      } else {
        // this.state.byLead = true;
        this.setState({ byLead: true }); //kiran-4745-removing console warning-use setState

        let info = {
          pID: this.state.pID,
          byCampaign: false,
          byPublisher: true,
          clientName: this.state.clientName,
          pacing: this.state.pacing,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        }; //karan-task-3796-vapt remove ids
        this.setState({
          byCampaign: false,
          byLead: true,
          byClient: false,
          byPublisher: true,
          linkActive1: "",
          linkActive2: "active",
          color1: "#f1f1f1",
          color2: "#ffffff",
        });
        this.funByPublisher(info);
      }
    }
  }

  biddingLink(e) {
    e.preventDefault();
    if (e.target.id === "reject") {
      //getting Campaign ID which status is New, Counter, Reject
      var tabReviewReject = "Reject";
      fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((notifyDashNewCamp) => {
          this.setState({
            biddingArray: notifyDashNewCamp[2],
            rejectLength: notifyDashNewCamp[2].length,
            RFPBiddingTab: tabReviewReject,
            headExample1: "head-example1",
            headExample: "head-exampleGeneral",
            headExample6: "head-exampleGeneral6",
            loading1: false,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    } else if (e.target.id === "RFP") {
      // Get RFP Bidding campID
      // var tabReviewRFP = "RFPBiddingTab";//kiran-4745-removing console warning-no used
      fetch("agencyDashboard/biddingCampIDOnDashBoard") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((rfpBidding) => {
          this.setState({
            rfpBiddingLength: rfpBidding.length,
            biddingArray: rfpBidding,
            RFPBiddingTab: "RFPBiddingTab",
            headExample1: "head-exampleGeneral1Color",
            headExample: "head-exampleGeneral",
            headExample6: "head-example6",
            loading1: false,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      //getting Campaign ID which status is New, Counter, Reject
      var tabReviewCounter = "Counter";
      fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((notifyDashNewCamp) => {
          this.setState({
            biddingArray: notifyDashNewCamp[1],
            counterLength: notifyDashNewCamp[1].length,
            headExample: "head-exampleColor",
            headExample1: "head-exampleGeneral1Color",
            RFPBiddingTab: tabReviewCounter,
            headExample6: "head-exampleGeneral6",
            loading1: false,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  } //end of biddingLink
  //shivani-3634- commented below handlechange for performance review task .

  reviewLink(e) {
    e.preventDefault();
    var tabReview = e.target.id;

    this.setState({ tabReview: tabReview });
    if (e.target.id === "creatives") {
      //kiran-4745-removing console warning-changed == to ===
      this.setState({
        reviewTab1: "creatives",
      });
      //getting Campaign ID which Delivered The Lead
      var ReviewTab = "ReviewCreatives";
      fetch("agencyDashboard/lpPOCCSReviewNotifyDash") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((lpPOCCSReviewNotifyDash) => {
          this.setState({
            reviewArray: lpPOCCSReviewNotifyDash,
            creativeLength: lpPOCCSReviewNotifyDash.length,
            ReviewTab: ReviewTab,
            headExample2: "head-example2",
            headExample3: "head-exampleGeneral3",
            headExample4: "head-exampleGeneral4",
            headExample5: "head-exampleGeneral5",
            loading2: false,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    } else if (e.target.id === "leads") {
      this.setState({
        reviewTab1: "creatives",
      });
      //getting Campaign ID which Delivered The Lead
      let ReviewTab = "ReviewLeads";
      fetch("agencyDashboard/leadReviewDashboard") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((leadReviewDashboard) => {
          this.setState({
            reviewArray: leadReviewDashboard,
            leadsLength: leadReviewDashboard.length,
            ReviewTab: ReviewTab,
            headExample3: "head-example3",
            headExample2: "head-exampleGeneral2",
            headExample4: "head-exampleGeneral4",
            headExample5: "head-exampleGeneral5",
            loading2: false,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    } else if (e.target.id === "others") {
      //kiran-4745-removing console warning-changed == to === and changed var to let
      let ReviewTab = "RevoewOthers";

      this.setState({ reviewTab1: "others" });

      fetch("/campaign/publisherLinkReview", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }) //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((publisherLinkReview) => {
          this.setState({
            reviewArray: publisherLinkReview,
            othersLength: publisherLinkReview.length,
            ReviewTab: ReviewTab,
            headExample4: "head-exampleGeneral4",
            headExample3: "head-exampleGeneral3",
            headExample2: "head-exampleGeneral2",
            headExample5: "head-example5",
            loading2: false,
            reviewTab1: ReviewTab,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      fetch("/agencyDashboard/getMonthList", {
        //insert data in publisher_assignment table
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }) //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((monthArray) => {
          let ReviewTab = "invoice";
          this.setState({
            monthArrayLength: monthArray.length,
            reviewArray: monthArray,
            ReviewTab: ReviewTab,
            headExample3: "head-exampleGeneral3",
            headExample2: "head-exampleGeneral2",
            headExample4: "head-example4",
            headExample5: "head-exampleGeneral5",
            loading2: false,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  } //end of reviewLink

  //shivani-3634- commented below handlechange for performance review task .

  displayCampaignSpecificationMsg(e) {
    e.preventDefault(); //karan-task-3919-campid missing
    var campID = e.target.getAttribute("campID");
    // var campaignName = e.target.getAttribute("campaignName");
    var parentCampID = e.target.getAttribute("parentCampId");
    var reallocationID = e.target.getAttribute("reallocationId");
    var publisherID = e.target.getAttribute("pID");
    var type = e.target.getAttribute("type");
    //{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher
    //Sonali-putting below condition in comment and added type field
    if (type === "RFP") {
      //Rutuja task 4944 commenting window.location.href as its showing id in url and also page going crash
      // window.location.href =
      //   "/rfpCampaignSpecification?proposalID=" +
      //   campID +
      //   "&pID=" +
      //   publisherID ;
      this.props.history.push("/rfpCampaignSpecification",{proposalID: campID,step:3}); //Rutuja task 4944 redirecting to rfp spec by using this.props.history.push 
    } else {
      this.props.history.push("/campaignSpecificationDashboard", {
        campID,
        parentCampID,
        reallocationID,
        pID: publisherID,
      }); //karan-task-3715-replace query params
    }
  }

  /**
   * @author Supriya Gore
   * @param  Description handle allocated campaign tab campaign
   * @return Description return successfully return on allocated campaign
   */
  allocatedCampaign(e) {
    e.preventDefault(); //karan-task-3895-link issue
    var campID = e.target.getAttribute("campID");

    this.props.history.push("/campaignRespond", {
      campID,
      activeKey: this.state.RFPBiddingTab,
    }); //karan-task-3715-replace query params
  }

  rFPBiddingTab(e) {
    e.preventDefault(); //Sonali-3716-added to remove # from url
    var campID = e.target.getAttribute("campID");
    this.props.history.push("/requestProposalAgency", { campID: campID }); //sonali-task-3716-replace query params
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChangePageSize1(e) {
    var pageSize1 = e.target.value;
    this.setState({ pageSize1: pageSize1 });
  }

  handleChangePageSize2(e) {
    var pageSize2 = e.target.value;
    this.setState({ pageSize2: pageSize2 });
  }

  //shivani-3634- commented below handlechange for performance review task .

  componentDidMount() {
    fetch("/agencyDashboard/getMonthList", {
      //insert data in publisher_assignment table
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }) //karan-task-3796-vapt remove ids
      .then((res) => res.json())
      .then((monthArray) => {
        this.setState({ monthArrayLength: monthArray.length });
      })
      .catch(function (err) {
        console.log(err);
      });

    fetch("/publisher/updateLeadsPermissionTimeFlag", {
      //insert data in publisher_assignment table
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((updateLeadsPermissionTimeFlag) => {})
      .catch(function (err) {
        console.log(err);
      });
  }
  //shivani-3634- commented below handlechange for performance review task .

  componentWillMount() {
    //Dashboard data
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      //Nilesh-5067- Added loader
			this.setState({
				loading2: true,
		  }); 
      //  {/* Sandeep-task-3221-added glyphicon-arrow-down and glyphicon-arrow-up*/}
      $(document).on("click", ".panel-heading span.icon_minim", function (e) {
        var $this = $(this);
        if (!$this.hasClass("panel-collapsed")) {
          $this.parents(".panel").find(".panel-body").slideUp();
          $this.addClass("panel-collapsed");
          $this
            .removeClass("glyphicon-arrow-down")
            .addClass("glyphicon-arrow-up");
        } else {
          $this.parents(".panel").find(".panel-body").slideDown();
          $this.removeClass("panel-collapsed");
          $this
            .removeClass("glyphicon-arrow-up")
            .addClass("glyphicon-arrow-down");
        }
      });
      $(document).on("focus", ".panel-footer input.chat_input", function (e) {
        var $this = $(this);
        if ($("#minim_chat_window").hasClass("panel-collapsed")) {
          $this.parents(".panel").find(".panel-body").slideDown();
          $("#minim_chat_window").removeClass("panel-collapsed");
          $("#minim_chat_window")
            .removeClass("glyphicon-arrow-up")
            .addClass("glyphicon-arrow-down");
        }
      });
      $(document).on("click", "#new_chat", function (e) {
        var size = $(".chat-window:last-child").css("margin-left");
        var size_total = parseInt(size) + 400;
        //  alert(size_total);
        var clone = $("#chat_window_1").clone().appendTo(".container");
        clone.css("margin-left", size_total);
      });
      $(document).on("click", ".icon_close", function (e) {
        $("#chatbox").hide();
      });

      const { user } = this.props.auth;
      var agencyID = user.id;
      var userID = user.userID;
      // var userRole = user.role; //kiran-4745-removing console warning-no used
      var firstName = user.firstName;
      var lastName = user.lastName;
      this.setState({ agencyID: agencyID });

      fetch("agencyDashboard/newDashboardCampaignCount") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((campaignCount) =>
          this.setState({ campaignCount: campaignCount }, function () {})
        )
        .catch(function (err) {
          console.log(err);
        });

      const dt = new Date();
      var index = dt.getMonth();

      this.setState({
        agencyID: agencyID,
        invoiceLength: index,
        userID: userID,
        firstName: firstName,
        lastName: lastName,
      });

      var datetime = new Date();
      var enddate = datetime.toISOString().slice(0, 10);
      this.setState({ enddate: enddate });

      var data = { pacing: this.state.pacing };
      fetch("agencyDashboard/selectPublisher") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((publisherList) => {
          this.setState({ publisherList: publisherList });
        })
        .catch(function (err) {
          console.log(err);
        });

      fetch("agencyDashboard/selectClient") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((clientList) => {
          this.setState({ clientList: clientList });
        })
        .catch(function (err) {
          console.log(err);
        });

      let info = {
        byCampaign: this.state.byCampaign,
        clientName: this.state.clientName,
        pacing: this.state.pacing,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }; //karan-task-3796-vapt remove ids
      fetch("/agencyDashboard/dashboardByClient", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(info),
      })
        .then((res) => res.json())
        .then((chartInfo) => {
          let barchart = chartInfo[1];
          let activeAcceptedArray = barchart[0];
          let completedDeleveredArray = barchart[1];
          let pausedQaArray = barchart[2];
          let cancelRejectedArray = barchart[3];
          let active = activeAcceptedArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
          let completed = completedDeleveredArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
          let paused = pausedQaArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
          let cancel = cancelRejectedArray.reduce(function (total, arr) {
            return total + arr.y;
          }, 0);
          let total = active + completed + paused + cancel;
          let dataExist = true;
          if (total === 0) {
            dataExist = false;
          }
          this.setState({
            pieChartArray: chartInfo[0],
            barChartArray: chartInfo[1],
            activeAcceptedArray,
            completedDeleveredArray,
            pausedQaArray,
            cancelRejectedArray,
            dataExist,
          });
        })
        .catch(function (err) {
          console.log(err);
        });

      fetch("/agencyDashboard/dashboardCampaignCount", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((dashboardCampaignCount) => {
          this.setState({ dashboardCampaignCount: dashboardCampaignCount });
        })
        .catch(function (err) {
          console.log(err);
        });

      var tab = "Active";
      fetch("/agencyDashboard/dashboardActiveCampaign", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((campaignDetails) => {
          this.setState({
            campaignDetails: campaignDetails,
            tab: tab,
            loading: false,
          });
        })
        .catch(function (err) {
          console.log(err);
        });

      //getting Campaign ID which status is New, Counter, Reject
      fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((notifyDashNewCamp) => {
          this.setState({
            biddingArray: notifyDashNewCamp[1],
            counterLength: notifyDashNewCamp[1].length,
            loading1: false,
          });
        })
        .catch(function (err) {
          console.log(err);
        });

      fetch("agencyDashboard/notifyDashNewCamp") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((notifyDashNewCamp) => {
          this.setState({ rejectLength: notifyDashNewCamp[2].length });
        })
        .catch(function (err) {
          console.log(err);
        });

      fetch("agencyDashboard/leadReviewDashboard") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((leadReviewDashboard) => {
          this.setState({ leadsLength: leadReviewDashboard.length });
        })
        .catch(function (err) {
          console.log(err);
        });

      fetch("/campaign/publisherLinkReview", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((publisherLinkReview) => {
          this.setState({ othersLength: publisherLinkReview.length });
        })
        .catch(function (err) {
          console.log(err);
        });

      //getting Campaign ID which Delivered The Lead
      var ReviewTab = "ReviewCreatives";
      fetch("agencyDashboard/lpPOCCSReviewNotifyDash") //karan-task-3796-vapt remove ids
        .then((res) => res.json())
        .then((lpPOCCSReviewNotifyDash) => {
          this.setState({
            reviewArray: lpPOCCSReviewNotifyDash,
            creativeLength: lpPOCCSReviewNotifyDash.length,
            ReviewTab: ReviewTab,
            loading2: false,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
    // Get RFP Bidding campID
    fetch("agencyDashboard/biddingCampIDOnDashBoard") //karan-task-3796-vapt remove ids
      .then((res) => res.json())
      .then((rfpBidding) => {
        this.setState({ rfpBiddingLength: rfpBidding.length });
      })
      .catch(function (err) {
        console.log(err);
      });

    fetch("agencyDashboard/completeOnEndDate") //karan-task-3796-vapt remove ids
      .then((res) => res.json())
      .catch(function (err) {
        console.log(err);
      });
    // If bidding date is crossed
    fetch("agencyDashboard/checkBiddingDateCrossed") //karan-task-3796-vapt remove ids
      .then((res) => res.json())
      .catch(function (err) {
        console.log(err);
      });

    /* @author Narendra Phadke
     * @param  Description Unread message on dashboard
     * @return Description get all unread message
     */
    let dataForUnread = {
      page: "Dashboard", //Somnath Task-3639, Add page key
    }; //karan-task-3796-vapt remove ids
    fetch("/notification/agencyDashboardUnreadMessages", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForUnread),
    })
      .then((res) => res.json())
      .then((unreadMessages) => {
        this.setState(
          {
            unreadMessages: unreadMessages,
            unreadMessagesLength: unreadMessages.length,
            userName: unreadMessages.author,
          },
          function () {
            this.setState({ userName: unreadMessages.author });
          }
        );
      })
      .catch(function (err) {
        console.log(err);
      });

    /* @author Narendra Phadke
     * @param  Description Unread message on dashboard
     * @return Description get all unread message
     */
    fetch("/notification/agencyDashboardUnreadAlerts", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForUnread),
    })
      .then((res) => res.json())
      .then((unreadAlerts) => {
        
        this.setState({
         alertShows: true
        })
        //sandeep-task-2998 added code to split date from datetime
        for (var i = 0; i < unreadAlerts.length; i++) {
          var datetime = unreadAlerts[i].datetime; //extract datetime form unreadAlerts
          var arr1 = datetime.split(" ");
          var arr2 = arr1[0].split("-");
          var year = arr2[0];
          var yr = year.slice(2, 4);
          var mnth = arr2[1];
          var dte = arr2[2];
          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          const d = new Date(mnth);
          datetime = dte + "-" + months[d.getMonth()] + "-" + yr;
          unreadAlerts[i].datetime = datetime; //added datetime in unreadAlerts array
        }
        this.setState({
          unreadAlerts: unreadAlerts,
          unreadAlertsLength: unreadAlerts.length,
        });

        if (this.state.unreadAlerts && this.state.unreadAlerts.length > 0) {
          var name = "key";
          var value;
          //kiran-4745-removing console warning-changed var to let
          for (let i = 0; i < this.state.unreadAlerts.length; i++) {
            value = this.state.unreadAlerts[i].alertID;
            let unreadAlerts = [...this.state.unreadAlerts];
            unreadAlerts[i] = { ...unreadAlerts[i], [name]: value };

            this.setState({ unreadAlerts: unreadAlerts });
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  } // end of componentdidMount
  //shivani-3634- commented below handlechange for performance review task .

  componentWillReceiveProps(nextProps) {
    this.setState({
      campaignDetails: nextProps.campaignDetails,
    });
  }
  getInvoiceLink(e) {
    e.preventDefault();
    var month = e.target.getAttribute("month");
    var year = e.target.getAttribute("year");
    // window.location.href = "/agencyInvoice?month=" + month + "&Year=" + year;
    this.props.history.push("/agencyInvoice", { month, Year: year }); //karan-task-3715-replace query params
  }

  getPublisherLink(e) {
    e.preventDefault(); //Somnath Task-3980, Add prevent default method
    var pID = e.target.id;
    var userType = e.target.getAttribute("userType");
    if (userType === "Publisher") {
      this.props.history.push("/reviewLinkPublisher", { pID }); //karan-task-3715-replace query params
    } else {
      this.props.history.push("/reviewLinkAdvertiser", { advertiserID: pID }); //Somnath Task-4071, Push History object url data
    }
  }
  //shivani-3634- commented below handlechange for performance review task .

  commonhandleChange(e) {
    const { name, value } = e.target;
    let startDate,
      endDate,
      info = {};
    //kiran-4745-removing console warning-changed == to ===
    if (name === "StartDate") {
      startDate = value;
      info = {
        pID: this.state.pID,
        byCampaign: this.state.byCampaign,
        byPublisher: this.state.byPublisher,
        clientName: this.state.clientName,
        pacing: this.state.pacing,
        startDate,
        endDate: this.state.endDate,
      }; //karan-task-3796-vapt remove ids
      this.setState({ startDate });
    } else {
      endDate = value;
      info = {
        pID: this.state.pID,
        byCampaign: this.state.byCampaign,
        byPublisher: this.state.byPublisher,
        clientName: this.state.clientName,
        pacing: this.state.pacing,
        startDate: this.state.startDate,
        endDate,
      }; //karan-task-3796-vapt remove ids
      this.setState({ endDate });
    }

    if (this.state.byClient) {
      this.funByClient(info);
    } else {
      this.funByPublisher(info);
    }
  }
  handlePacing(e) {
    let pacing = e.target.value;
    //Sandeep-task-2998 added code to get the label from selector  to  inside handlePacing
    let index = e.nativeEvent.target.selectedIndex;
    // let label = e.nativeEvent.target[index].text;//kiran-4745-removing console warning-no used

    this.setState({ pacing: pacing, label: e.nativeEvent.target[index].text });
    if (pacing === "dateRange") {
      this.setState({ dateDisplay: "block", startDate: "", endDate: "" });
    } else {
      this.setState({ dateDisplay: "none" });
      let info = {
        pID: this.state.pID,
        byCampaign: this.state.byCampaign,
        byPublisher: this.state.byPublisher,
        clientName: this.state.clientName,
        pacing,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }; //karan-task-3796-vapt remove ids
      if (this.state.byClient) {
        this.funByClient(info);
      } else {
        this.funByPublisher(info);
      }
    }
  }
  handleClientName(e) {
    let clientName = e.target.value;
    this.setState({ clientName });

    let info = {
      pID: this.state.pID,
      byCampaign: this.state.byCampaign,
      byPublisher: this.state.byPublisher,
      clientName,
      pacing: this.state.pacing,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    }; //karan-task-3796-vapt remove ids
    this.funByClient(info);
  }
  handlePublisherName(e) {
    let pID = e.target.value;
    let pubName = this.state.publisherList.filter(
      (a) => a.pID.toString() === pID
    ); //kiran-4745-added toString() and changed == to ===
    let publisherName = "";
    if (pubName.length > 0) {
      publisherName = pubName[0].publisherName;
    }
    this.setState({ pID, publisherName });

    let info = {
      pID,
      byCampaign: this.state.byCampaign,
      byPublisher: this.state.byPublisher,
      clientName: this.state.clientName,
      pacing: this.state.pacing,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    }; //karan-task-3796-vapt remove ids
    this.funByPublisher(info);
  }

  /**
   * @author Supriya Gore
   * @param  Description  handle mark all read and clear alerts
   * @return Description return successfully response with updated alerts
   */
  handleMarkAllReadAndClear(e) {
    e.preventDefault();
    let data = {
      unreadAlerts: this.state.unreadAlerts,
    };

    fetch("/notification/markAllReadAndClear", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((markAllReadAndClear) => {
        if (markAllReadAndClear.affectedRows > 0) {
          let dataForUnread = {
            page: "Dashboard", //Somnath Task-3639, Add page key
          }; //karan-task-3796-vapt remove ids

          fetch("/notification/agencyDashboardUnreadAlerts", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataForUnread),
          })
            .then((res) => res.json())
            .then((unreadAlerts) => {
              for (var i = 0; i < unreadAlerts.length; i++) {
                var datetime = unreadAlerts[i].datetime;
                var arr1 = datetime.split(" ");
                var arr2 = arr1[0].split("-");
                var year = arr2[0];
                var yr = year.slice(2, 4);
                var mnth = arr2[1];
                var dte = arr2[2];
                const months = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];
                const d = new Date(mnth);
                datetime = dte + "-" + months[d.getMonth()] + "-" + yr;
                unreadAlerts[i].datetime = datetime; //added datetime in unreadAlerts array
              }
              this.setState({
                unreadAlerts: unreadAlerts,
                unreadAlertsLength: unreadAlerts.length,
              });

              if (
                this.state.unreadAlerts &&
                this.state.unreadAlerts.length > 0
              ) {
                var name = "key";
                var value;
                //kiran-4745-removing console warning-changed var to let
                for (let i = 0; i < this.state.unreadAlerts.length; i++) {
                  value = this.state.unreadAlerts[i].alertID;
                  let unreadAlerts = [...this.state.unreadAlerts];
                  unreadAlerts[i] = { ...unreadAlerts[i], [name]: value };
                  this.setState({ unreadAlerts: unreadAlerts });
                }
              }
            })
            .catch(function (err) {
              console.log(err);
            });
        }
      });
  }
  render() {
    const { user } = this.props.auth;
    //shivani-3634- commented below handlechange for performance review task .

    const invoiceData = [
      {
        title: "ID",
        dataIndex: "ID",
        key: "ID",
        width: "11%",
        align: "center",
        sorter: (a, b) => a.ID - b.ID,
        render: (text, record) => (
          <div>
            <span>{record.ID}</span>
          </div>
        ),
      },
      {
        title: "Months",
        dataIndex: "month",
        key: "month",
        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.month.toUpperCase() < b.month.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (a.month.toUpperCase() > b.month.toUpperCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "12px" }}>{record.month}</span>
          </div>
        ),
      },
      {
        title: "Year",
        dataIndex: "year",
        key: "year",
        width: "11%",
        align: "center",
        sorter: (a, b) => a.year - b.year,
        render: (text, record) => (
          <div>
            <span>{record.year}</span>
          </div>
        ),
      },
      {
        title: "Invoice Link",
        dataIndex: "invoiceLink",
        key: "invoiceLink",
        width: "40%",
        align: "center",
        sorter: (a, b) => a.invoiceLink - b.invoiceLink,
        render: (text, record) => (
          <div>
            <span>
              {/* kiran-4745-removing console warning-added "#/" */}
              <a
                href="#/"
                month={record.month}
                year={record.year}
                onClick={this.getInvoiceLink.bind(this)}
                style={{ fontSize: "12px" }}
              >
                Get Invoice
              </a>
            </span>
          </div>
        ),
      },
    ];
    //Sandeep-task-3221-removed _ from code
    const columns01 = [
      {
        title: "Camp ID",
        dataIndex: "campID",
        key: "campID",
        width: "50%",

        align: "center",
        sorter: (a, b) => a.campID - b.campID,
        render: (text, record) => (
          <div>
            <span>
              {/* kiran-4745-removing console warning-added "#/" */}
              {this.state.RFPBiddingTab === "RFPBiddingTab" ? (
                <a
                  href="#/"
                  onClick={this.rFPBiddingTab}
                  campID={record.campID}
                  campaignName={record.campaignName}
                  parentCampID={record.parentCampID}
                  reallocationID={record.reallocationID}
                  style={{ fontSize: "12px" }}
                >
                  {record.parentCampID ? record.parentCampID : record.campID}
                </a>
              ) : (
                <a
                  href="#/" //karan-task-3895-link issue
                  onClick={this.allocatedCampaign}
                  campID={record.campID}
                  campaignName={record.campaignName}
                  parentCampID={record.parentCampID}
                  reallocationID={record.reallocationID}
                  style={{ fontSize: "12px" }}
                >
                  {record.parentCampID ? record.parentCampID : record.campID}
                </a>
              )}
            </span>
          </div>
        ),
      },
      //Sandeep-task-3221-removed _ from code
      {
        title: "Camp Name",
        dataIndex: "campaignName",
        key: "campaignName",

        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div class="dot" style={{ textAlign: "center" }}>
            <span style={{ fontSize: "12px" }}>{record.campaignName}</span>
          </div>
        ),
      },
    ];
    //Sandeep-task-3221-removed _ from code
    const columns1 = [
      {
        title: "Camp ID",
        dataIndex: "campID",
        key: "campID",
        width: "48%",

        align: "center",
        sorter: (a, b) => a.campID - b.campID,
        render: (text, record) => (
          <div>
            <span>
              {/* kiran-4745-removing console warning-added "#/" */}
              <a
                href="#/" //karan-task-3895-link issue
                onClick={this.creativesReview}
                campID={record.campID}
                campaignName={record.campaignName}
                parentCampID={record.parentCampID}
                reallocationID={record.reallocationID}
                style={{ fontSize: "12px" }}
              >
                {record.parentCampID ? record.parentCampID : record.campID}
              </a>
            </span>
          </div>
        ),
      },
      //Sandeep-task-3221-removed _ from code
      {
        title: "Camp Name",
        dataIndex: "campaignName",
        key: "campaignName",

        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div class="dot" style={{ textAlign: "center" }}>
            <span style={{ fontSize: "12px" }}>{record.campaignName}</span>
          </div>
        ),
      },
    ];
    //Shivani- create different column for RFP
    //Sandeep-task-3221-removed _ from code
    const columnsRFP = [
      {
        title: "Proposal ID",
        dataIndex: "campID",
        key: "campID",
        width: "50%",

        align: "center",
        sorter: (a, b) => a.campID - b.campID,
        render: (text, record) => (
          <div>
            <span>
              {/* kiran-4745-removing console warning-added "#/" */}
              {this.state.RFPBiddingTab === "RFPBiddingTab" ? (
                <a
                  href="#/"
                  onClick={this.rFPBiddingTab}
                  campID={record.campID}
                  campaignName={record.campaignName}
                  parentCampID={record.parentCampID}
                  reallocationID={record.reallocationID}
                  style={{ fontSize: "12px" }}
                >
                  {record.parentCampID ? record.parentCampID : record.campID}
                </a>
              ) : (
                <a
                  href="#/" //karan-task-3895-link issue
                  onClick={this.allocatedCampaign}
                  campID={record.campID}
                  campaignName={record.campaignName}
                  parentCampID={record.parentCampID}
                  reallocationID={record.reallocationID}
                  style={{ fontSize: "12px" }}
                >
                  {record.parentCampID ? record.parentCampID : record.campID}
                </a>
              )}
            </span>
          </div>
        ),
      },
      //Sandeep-task-3221-removed _ from code
      {
        title: "Camp Name",
        dataIndex: "campaignName",
        key: "campaignName",

        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div class="dot" style={{ textAlign: "center" }}>
            <span style={{ fontSize: "12px" }}>{record.campaignName}</span>
          </div>
        ),
      },
    ];
    //Sandeep-task-3221-removed _ from code
    const columns2 = [
      {
        title: "Camp ID",
        dataIndex: "campID",
        key: "campID",

        align: "center",
        width: "19%",
        sorter: (a, b) => a.campID - b.campID,
        render: (text, record) => (
          <div>
            {/* kiran-4745-removing console warning-added "#/" */}
            <span style={{ fontSize: "12px" }}>
              {this.state.ReviewTab === "ReviewCreatives" ? (
                <a
                  href="#/" //karan-task-3895-link issue
                  onClick={this.creativesReview}
                  campID={record.campID}
                  parentCampID={record.parentCampID}
                  reallocationID={record.reallocationID}
                  style={{ fontSize: "12px" }}
                >
                  {record.parentCampID ? record.parentCampID : record.campID}
                </a>
              ) : (
                <a
                  href="#/" //karan-task-3895-link issue
                  onClick={this.leadReview}
                  campID={record.campID}
                  parentCampID={record.parentCampID}
                  reallocationID={record.reallocationID}
                  tabkey={record.tab} //sunita-task-3270-added for fetching the camp id as per status to open the particular tab on agencyreviewlead page
                  style={{ fontSize: "12px" }}
                >
                  {record.parentCampID ? record.parentCampID : record.campID}
                </a>
              )}
            </span>
          </div>
        ),
      },
      //Sandeep-task-3221-removed _ from code
      {
        title: "Camp Name",
        dataIndex: "campaignName",
        key: "campaignName",
        width: "23%",

        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          // 4565-Nilesh- Adjust Camp Name
          <div class="dot2" style={{ width: "auto" }}>
            <span style={{ fontSize: "12px" }} class="word-wrap">
              {record.campaignName}
            </span>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        width: "25%",
        align: "center",
        sorter: (a, b) => {
          const asc = "asc";
          if (a.status.toUpperCase() < b.status.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (a.status.toUpperCase() > b.status.toUpperCase()) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "12px" }} class="word-wrap">
              {record.status}
            </span>
          </div>
        ),
      },
      {
        title: "Download Progress",
        width: "60%",
        children: [
          {
            title: "Download Lead",
            dataIndex: "downloadLead",
            key: "downloadLead",
            align: "center",
            width: "18%",
            render(text, record) {
              return {
                props: {
                  style: { wordWrap: "break-word", height: "5%" },
                },
                children: <div>{record.downloadedCount}</div>,
              };
            },
          },
          {
            title: "New Lead",
            dataIndex: "newLead",
            key: "newLead",
            align: "center",
            width: "20%",
            render(text, record) {
              var pendingLeads = record.totalLeadCount - record.downloadedCount;
              return {
                props: {
                  style: { wordWrap: "break-word", height: "5%" },
                },
                children: <div>{pendingLeads < 0 ? 0 : pendingLeads}</div>,
              };
            },
          },
        ],
      },
    ];

    const columns3 = [
      {
        title: "Publisher/Advertiser Name",
        dataIndex: "publisherName",
        key: "publisherName",
        width: "30%",

        align: "center",
        sorter: (a, b) => a.name - b.name,
        render: (text, record) => <div>{record.name}</div>,
      },
      {
        title: "User Type ",
        dataIndex: "userType",
        key: "userType",
        width: "30%",

        align: "center",
        sorter: (a, b) => a.userType - b.userType,
        render: (text, record) => <div>{record.userType}</div>,
      },
      {
        title: "Review ",
        dataIndex: "reviewDetails",
        key: "reviewDetails",
        width: "30%",

        align: "center",
        sorter: (a, b) => a.reviewDetails - b.reviewDetails,
        render: (text, record) => (
          <div>
            <a
              href="#/"
              id={record.id}
              userType={record.userType}
              onClick={this.getPublisherLink.bind(this)}
            >
              Click here
            </a>
          </div>
        ),
      },
    ];

    //Sandeep-3377-loader changed for  no data

    let locale = {
      emptyText: "Data is loading",
    };

    function onChange(pagination, filters, sorter) {}

    var options1;
    var clientName = this.state.clientName;
    var publisherName = this.state.publisherName;
    var text;

    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var temptimeline = this.state.pacing;
    var sd, ed;
    if (this.state.startDate === "") {
      //kiran-4745-removing console warning-changed == to ===
      sd = "Start Date";
    } else {
      sd = this.state.startDate;
    }

    if (this.state.endDate === "") {
      ed = "End Date";
    } else {
      ed = this.state.endDate;
    }

    var timeline;
    var currentMonth;
    var PrevMonth;
    var NextMonth;
    if (temptimeline === "Monthly") {
      var date = new Date();
      timeline = months[date.getMonth()];
    } else if (temptimeline === "Quarterly") {
      date = new Date();
      PrevMonth = months[date.getMonth() - 2];
      currentMonth = months[date.getMonth()];
      NextMonth = months[date.getMonth() - 1];
      let m = date.getMonth();
      m += 1;
      if (m < 4) {
        timeline = "Jan,Feb,Mar";
      } else if (m > 3 && m < 7) {
        timeline = "Apr,May,Jun";
      } else if (m > 6 && m < 10) {
        timeline = "Jul,Aug,Sep";
      } else {
        timeline = "Oct,Nov,Dec";
      }
    } else if (temptimeline === "Yearly") {
      //kiran-4745-removing console warning-changed == to ===
      date = new Date();
      timeline = date.getFullYear();
    } else if (temptimeline === "PreMonth") {
      date = new Date();
      timeline = months[date.getMonth() - 1];
    } else if (temptimeline === "PreQuarter") {
      date = new Date();
      PrevMonth = months[date.getMonth() - 5];
      currentMonth = months[date.getMonth() - 4];
      NextMonth = months[date.getMonth() - 3];

      let m = date.getMonth();
      m += 1;
      let Q;
      if (m < 4) {
        Q = 1;
      } else if (m > 3 && m < 7) {
        Q = 2;
      } else if (m > 6 && m < 10) {
        Q = 3;
      } else {
        Q = 4;
      }

      if (Q === 1) {
        Q = 4;
      } else {
        Q = Q - 1;
      }
      if (Q === 1) {
        timeline = "Jan,Feb,Mar";
      } else if (Q === 2) {
        timeline = "Apr,May,Jun";
      } else if (Q === 3) {
        timeline = "Jul,Aug,Sep";
      } else {
        timeline = "Oct,Nov,Dec";
      }
    } else if (temptimeline === "PreYear") {
      date = new Date();
      timeline = date.getFullYear() - 1;
    } else if ((temptimeline = "dateRange")) {
      // timeline = sd + " " + "To" + " " + ed;
      timeline = `${sd} To ${ed}`;
    }
    //kiran-4745-removing console warning-changed == to ===
    if (this.state.byClient === false) {
      text = publisherName
        ? "Delivery Status-" + publisherName + "(" + timeline + ")"
        : "Delivery Status-All Publisher(" + timeline + ")";
    }
    if (clientName === "All Clients") {
      clientName = "All Advertiser";
    }
    if (this.state.byClient === true) {
      text = clientName
        ? "Delivery Status-" + clientName + "(" + timeline + ")"
        : "Delivery Status-All Advertiser(" + timeline + ")";
    }
    // var cancelled = JSON.stringify(this.state.barChartArray[0]);
    var from;
    var byPublisher = this.state.byPublisher;
    let pID = this.state.pID;

    const self = this; //karan-3684-class instance needed
    if (this.state.byCampaign) {
      from = "byCampaign";
      options1 = {
        animationEnabled: true,
        exportEnabled: false,
        title: {
          text: text,
          horizontalAlign: "left",
          fontSize: 15,
          fontColor: "#1c1b19",

          fontWeight: "bold",
          fontFamily: "roboto",
        },

        axisY: {
          title: "Campaign",
          gridThickness: 0,
        },
        toolTip: {
          shared: true,
          reversed: true,
          enabled: true, //disable here
          animationEnabled: true,
          content: toolTipContent1,
        },
        legend: {
          verticalAlign: "center",
          horizontalAlign: "right",
          reversed: true,
          cursor: "pointer",
          itemclick: this.toggleDataSeries,
        },
        height: 260,
        data: [
          {
            cursor: "pointer",
            click: function (e) {
              //kiran-4745-removing console warning-changed == to ===
              if (byPublisher === false) {
                //Nilesh-4946- Add if condition, remove campaignList page at ANC side
                if (user.role === "AC" || user.role === "AQA") {
                  self.props.history.push("/campaignList", {
                    agencyID: user.id,
                    fromDate: e.dataPoint.fromDate,
                    toDate: e.dataPoint.toDate,
                    clientName: clientName,
                    from: from,
                    status: "Active",
                  }); //karan-task-3684-replace query params
                }
              } else {
                //Nilesh-4946- Add if condition, remove campaignList page at ANC side
                if (user.role === "AC" || user.role === "AQA") {
                  self.props.history.push("/campaignList", {
                    agencyID: user.id,
                    fromDate: e.dataPoint.fromDate,
                    toDate: e.dataPoint.toDate,
                    publisherName: pID,
                    from: from,
                    byPublisher: byPublisher,
                    status: "Active",
                  }); //karan-task-3684-replace query params
                }
              }
            },
            indexLabel: "{l}",
            indexLabelFontColor: "black",
            indexLabelPlacement: "inside",
            type: "stackedColumn",
            name: "Active",
            showInLegend: false,

            color: "#39B54A",
            // cursor: "pointer",//kiran-4745-removed duplicate
            dataPoints: this.state.activeAcceptedArray,
          },
          {
            click: function (e) {
              if (byPublisher === false) {
                //Nilesh-4946- Add if condition, remove campaignList page at ANC side
                if (user.role === "AC" || user.role === "AQA") {
                  self.props.history.push("/campaignList", {
                    agencyID: user.id,
                    fromDate: e.dataPoint.fromDate,
                    toDate: e.dataPoint.toDate,
                    clientName: clientName,
                    from: from,
                    status: "Cancel",
                  }); //karan-task-3684-replace query params
                }
              } else {
                //Nilesh-4946- Add if condition, remove campaignList page at ANC side
                if (user.role === "AC" || user.role === "AQA") {
                  self.props.history.push("/campaignList", {
                    agencyID: user.id,
                    fromDate: e.dataPoint.fromDate,
                    toDate: e.dataPoint.toDate,
                    publisherName: pID,
                    from: from,
                    byPublisher: byPublisher,
                    status: "Cancel",
                  }); //karan-task-3684-replace query params
                }
              }
            },
            indexLabel: "{l}",
            indexLabelFontColor: "black",
            indexLabelPlacement: "inside",
            type: "stackedColumn",
            name: "Cancelled",
            showInLegend: false,

            color: "#ED1C24",
            cursor: "pointer",
            dataPoints: this.state.cancelRejectedArray,
          },

          {
            click: function (e) {
              //kiran-4745-removing console warning-changed == to ===
              if (byPublisher === false) {
                //Nilesh-4946- Add if condition, remove campaignList page at ANC side
                if (user.role === "AC" || user.role === "AQA") {
                  self.props.history.push("/campaignList", {
                    agencyID: user.id,
                    fromDate: e.dataPoint.fromDate,
                    toDate: e.dataPoint.toDate,
                    clientName: clientName,
                    from: from,
                    status: "Completed",
                  }); //karan-task-3684-replace query params
                }
              } else {
                //Nilesh-4946- Add if condition, remove campaignList page at ANC side
                if (user.role === "AC" || user.role === "AQA") {
                  self.props.history.push("/campaignList", {
                    agencyID: user.id,
                    fromDate: e.dataPoint.fromDate,
                    toDate: e.dataPoint.toDate,
                    publisherName: pID,
                    from: from,
                    byPublisher: byPublisher,
                    status: "Completed",
                  }); //karan-task-3684-replace query params
                }
              }
            },
            indexLabel: "{l}",
            indexLabelFontColor: "black",
            indexLabelPlacement: "inside",
            type: "stackedColumn",
            name: "Completed",
            showInLegend: false,

            color: "#00A5F0",
            cursor: "pointer",
            dataPoints: this.state.completedDeleveredArray,
          },
          {
            click: function (e) {
              //kiran-4745-removing console warning-changed == to ===
              if (byPublisher === false) {
                //Nilesh-4946- Add if condition, remove campaignList page at ANC side
                if (user.role === "AC" || user.role === "AQA") {
                  self.props.history.push("/campaignList", {
                    agencyID: user.id,
                    fromDate: e.dataPoint.fromDate,
                    toDate: e.dataPoint.toDate,
                    clientName: clientName,
                    from: from,
                    status: "Paused",
                  }); //karan-task-3684-replace query params
                }
              } else {
                //Nilesh-4946- Add if condition, remove campaignList page at ANC side
                if (user.role === "AC" || user.role === "AQA") {
                  self.props.history.push("/campaignList", {
                    agencyID: user.id,
                    fromDate: e.dataPoint.fromDate,
                    toDate: e.dataPoint.toDate,
                    publisherName: pID,
                    from: from,
                    byPublisher: byPublisher,
                    status: "Paused",
                  }); //karan-task-3684-replace query params
                }
              }
            },
            indexLabel: "{l}",
            indexLabelFontColor: "black",
            indexLabelPlacement: "inside",
            type: "stackedColumn",
            name: "Paused",
            showInLegend: false,

            color: "#FF931E",
            cursor: "pointer",
            dataPoints: this.state.pausedQaArray,
          },
        ],
      };
      function toolTipContent1(e) {
        var str5;

        for (var i = 0; i < e.entries.length; i++) {
          //sandeep-task-2964- added code to align tooltip content properly
          var str1 =
            '<span style= "color:#39B54A' +
            '"> ' +
            e.entries[0].dataSeries.name +
            '</span>:<strong style="float:right">' +
            e.entries[0].dataPoint.y +
            "</strong><br/>";
          var str2 =
            '<span style= "color:#ED1C24' +
            '"> ' +
            e.entries[1].dataSeries.name +
            '</span>:<strong style="float:right">' +
            e.entries[1].dataPoint.y +
            "</strong><br/>";
          var str3 =
            '<span style= "color:#00A5F0' +
            '"> ' +
            e.entries[2].dataSeries.name +
            '</span>:<strong style="float:right">' +
            e.entries[2].dataPoint.y +
            "</strong><br/>";
          var str4 =
            '<span style= "color:#FF931E' +
            '"> ' +
            e.entries[3].dataSeries.name +
            '</span>:<strong style="float:right">' +
            e.entries[3].dataPoint.y +
            "</strong><br/>";

          var str12 = str2.concat(str1);
          var str123 = str3.concat(str12);
          var str1234 = str4.concat(str123);
        }

        str5 =
          '<span style = "color:Black"></span><strong>' +
          e.entries[0].dataPoint.label +
          "</strong><br/>";

        return str5.concat(str1234);
      }
    } else {
      from = "byLead";
      options1 = {
        animationEnabled: true,
        exportEnabled: false,
        title: {
          text: text,
          horizontalAlign: "left",
          fontSize: 15,
          fontColor: "#1c1b19",

          fontWeight: "bold",
          fontFamily: "roboto",
        },
        axisY: {
          title: "Lead",
          gridThickness: 0,
        },
        toolTip: {
          shared: true,
          reversed: true,
          enabled: true, //disable here
          animationEnabled: true,
          content: toolTipContent,
        },
        legend: {
          verticalAlign: "center",
          horizontalAlign: "right",
          reversed: true,
          cursor: "pointer",
          itemclick: this.toggleDataSeries,
        },
        height: 260,
        data: [
          {
            indexLabel: "{l}",
            indexLabelFontColor: "black",
            indexLabelPlacement: "inside",
            type: "stackedColumn",
            name: "To be Delivered",
            showInLegend: false,

            color: "#39B54A",
            dataPoints: this.state.barChartArray[0],
          },
          {
            indexLabel: "{l}",
            indexLabelFontColor: "black",
            indexLabelPlacement: "inside",
            type: "stackedColumn",
            name: "Rejected",
            showInLegend: false,

            color: "#ED1C24",
            dataPoints: this.state.barChartArray[3],
          },
          {
            indexLabel: "{l}",
            indexLabelFontColor: "black",
            indexLabelPlacement: "inside",
            type: "stackedColumn",
            name: "Accepted",
            showInLegend: false,

            color: "#00A5F0",
            dataPoints: this.state.barChartArray[1],
          },
          {
            indexLabel: "{l}",
            indexLabelFontColor: "black",
            indexLabelPlacement: "inside",
            type: "stackedColumn",
            name: "In QA",
            showInLegend: false,

            color: "#FF931E",
            dataPoints: this.state.barChartArray[2],
          },
        ],
      };
      function toolTipContent(e) {
        // var str = "";
        var str5, str6;
        for (var i = 0; i < e.entries.length; i++) {
          //sandeep-task-2964- added code to align tooltip content properly
          var str1 =
            '<span style= "color:#39B54A' +
            '"> ' +
            e.entries[0].dataSeries.name +
            '</span>:<strong style="float:right">' +
            e.entries[0].dataPoint.y +
            "</strong><br/>";
          var str2 =
            '<span style= "color:#ED1C24' +
            '"> ' +
            e.entries[1].dataSeries.name +
            '</span>:<strong style="float:right">' +
            e.entries[1].dataPoint.y +
            "</strong><br/>";
          var str3 =
            '<span style= "color:#00A5F0' +
            '"> ' +
            e.entries[2].dataSeries.name +
            '</span>:<strong style="float:right">' +
            e.entries[2].dataPoint.y +
            "</strong><br/>";
          var str4 =
            '<span style= "color:#FF931E' +
            '"> ' +
            e.entries[3].dataSeries.name +
            '</span>:<strong style="float:right">' +
            e.entries[3].dataPoint.y +
            "</strong><br/>";
          var str12 = str2.concat(str1);
          var str123 = str3.concat(str12);
          var str1234 = str4.concat(str123);
        }

        str5 =
          '<span style = "color:#7400B8;"><strong>' +
          "Allocated" +
          '</span>: <strong style="float:right">' +
          e.entries[0].dataPoint.allocatedLead +
          "</strong></span><br/>";
        //sandeep-task-2964- added code to align tooltip content properly
        str6 =
          '<span style = "color:Black"></span><strong>' +
          e.entries[0].dataPoint.label +
          "</strong><br/>";

        return str6.concat(str5.concat(str1234));
      }
    }

    return (
      <div>
        <Navigation />
        <div
          class="container-fluid"
          style={{ paddingTop: "74px", paddingBottom: "80px" }}
        >
          <div class="row">
            <div
              class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9"
              style={{ paddingTop: "35px" }}
            >
              {/*sunita-task- 3482-added below classname as per requirement */}
              <span
                className="deliveryDashboard"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "22px",
                  fontWeight: "450",
                  borderBottom: "3px solid #144c9b",
                  // paddingBottom: "10px" sunita-task- 3818 - commented css as blue line not get aligned,
                  paddingBottom: "3px",
                  color: "rgba(0, 0, 0, 0.85)",
                }}
              >
                Delivery Dashboard
              </span>

              <hr />
            </div>
            {/*sunita-task- 3482-added id as per requirement for setting the vertical line */}
            {/* sandeep-task-2998 added vertical line */}
          </div>

          <div class="row">
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 clientPub"
              style={{
                paddingBottom: "5px",
              }}
            >
              {/*sunita-task-3818-added css as per requirement */}
              <div
                className="float-left clientpublisher"
                style={{ border: "1px solid black" }}
              >
                <Button
                  size="default"
                  style={{ borderRadius: "1px" }}
                  id="clients"
                  className={this.state.headExampleAdv}
                  onClick={this.dataFilterClientPub}
                >
                  Advertiser
                </Button>
                <Button
                  size="default"
                  style={{ borderRadius: "1px" }}
                  id="publishers"
                  className={this.state.headExamplePub}
                  onClick={this.dataFilterClientPub}
                >
                  Publishers
                </Button>
              </div>
              <div class="float-right topnav color">
                {this.state.selectedOption === "Lead" ? (
                  <Radio.Group
                    className="lead-radio"
                    onChange={this.dataFilter}
                    name="filter"
                    class={this.state.linkActive2}
                    defaultValue="Lead"
                  >
                    <Radio value="Lead" id="byLead">
                      Lead
                    </Radio>
                    <Radio value="Campaign" id="byCampaign">
                      Campaign
                    </Radio>
                  </Radio.Group>
                ) : (
                  ""
                )}

                {this.state.selectedOption === "Campaign" ? (
                  <Radio.Group
                    className="camp-radio"
                    onChange={this.dataFilter}
                    name="filter"
                    class={this.state.linkActive1}
                    defaultValue="Campaign"
                  >
                    <Radio value="Lead" id="byLead">
                      Lead
                    </Radio>
                    <Radio value="Campaign" id="byCampaign">
                      Campaign
                    </Radio>
                  </Radio.Group>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div class="row">
            <div
              id="cardmonth"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 "
            >
              <div class="card" style={{ border: "0.7px solid #CCCCCC" }}>
                {" "}
                {/*sunita-task-3482-changing border color*/}
                <div class="card-body" style={{ height: "395px" }}>
                  <div class="row  ">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div
                        style={{
                          width: "300px",
                          paddingBottom: "20px",
                          paddingTop: "5px",
                        }}
                      >
                        {" "}
                        {/*Akash-Task-3878 */}{" "}
                        <label
                          style={{
                            fontFamily: "Roboto",
                            color: "#71717199",
                            fontSize: "13px",
                          }}
                        >
                          Showing result for &nbsp;
                        </label>
                        <span
                          id="pacing2"
                          style={{
                            color:
                              "#F28C0F" /*sunita-task-3482-changing border color*/,
                            fontFamily: "Roboto",
                            fontSize: "12px",
                          }}
                        >
                          {" "}
                          "{this.state.label}"
                        </span>
                      </div>
                    </div>
                    {/* sandeep-task-2998 -added code to show the result of dropdown action on screen */}
                    <div
                      class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 "
                      style={{ paddingBottom: "5px" }}
                    >
                      {" "}
                      {/*sunita-task-3818- added css as per requirement */}
                      <div class="float-right">
                        <select
                          id="pacing1"
                          name="pacing"
                          class="input-small"
                          className="form-control"
                          onChange={this.handlePacing.bind(this)}
                          style={{
                            width: "161px", //Akash-Task-4124
                            height: "30px",
                            fontSize: "11px",
                            fontFamily: "roboto",
                            border: "1.4px solid rgb(20, 76, 155)",
                          }}
                        >
                          <option
                            value="Monthly"
                            name="Monthly"
                            id="Monthly"
                            label="Ending This month"
                          >
                            Ending This month
                          </option>
                          <option
                            value="Quarterly"
                            name="Quarterly"
                            id="Quarterly"
                            label="Ending This Quarter"
                          >
                            Ending This Quarter
                          </option>
                          <option
                            value="Yearly"
                            name="Yearly"
                            id="Yearly"
                            label="Ending This Year"
                          >
                            Ending This Year
                          </option>
                          <option
                            value="PreMonth"
                            name="PreMonth"
                            id="PreMonth"
                            label="Ending Previous Month"
                          >
                            Ending Previous Month
                          </option>
                          <option
                            value="PreQuarter"
                            name="PreQuarter"
                            id="PreQuarter"
                            label="Ending Previous Quarter"
                          >
                            Ending Previous Quarter
                          </option>
                          <option
                            value="PreYear"
                            name="PreYear"
                            id="PreYear"
                            label="Ending Previous Year"
                          >
                            Ending Previous Year
                          </option>
                          <option
                            value="dateRange"
                            name="dateRange"
                            id="dateRange"
                            label="Date Range"
                          >
                            Date Range
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <hr style={{ color: "lightgrey" }}></hr>
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <span style={{ display: this.state.dateDisplay }}>
                        From&nbsp;
                        <input
                          type="date"
                          id="StartDate"
                          name="StartDate"
                          style={{ height: "32px" }}
                          value={this.state.startDate}
                          onChange={this.commonhandleChange}
                        />
                        <br />
                        To&nbsp;&nbsp;&nbsp;
                        <input
                          type="date"
                          id="EndDate"
                          name="EndDate"
                          style={{ height: "32px", marginLeft: "10px" }}
                          value={this.state.endDate}
                          onChange={this.commonhandleChange}
                        />
                      </span>
                    </div>
                  </div>

                  {this.state.dataExist ? (
                    this.state.byCampaign ? (
                      <Chart
                        width={"415px"}
                        height={"252px"}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={this.state.pieChartArray}
                        options={{
                          width: 550,
                          height: 250,
                          title: text,
                          titleTextStyle: {
                            color: "#1a1715",

                            fontName: "roboto",
                            fontSize: 15,
                          },

                          is3D: true,
                          pieSliceText: "none",
                          pieSliceTextStyle: {
                            color: "black",
                            fontSize: "30px",
                            fontWeight: "800",
                          },
                          chartArea: {
                            left: 10,
                            width: "60%",
                            height: "75%",
                            backgroundColor: "transparent",
                          },
                          slices: {
                            0: { color: "#39B54A" },
                            1: { color: "#00A5F0" },
                            2: { color: "#FF931E" },
                            3: { color: "#ED1C24" },
                          },
                          backgroundColor: "transparent",
                          legend: {
                            position: "labeled",
                          },
                        }}
                        rootProps={{ "data-testid": "2" }}
                      />
                    ) : (
                      <Chart
                        width={"415px"}
                        height={"263px"}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={this.state.pieChartArray}
                        options={{
                          width: 550,
                          height: 250,
                          title: text,
                          titleTextStyle: {
                            color: "#1a1715",

                            fontName: "roboto",
                            fontSize: 15,
                          },

                          is3D: true,
                          pieSliceText: "percentage",
                          pieSliceTextStyle: {
                            color: "black",
                            fontSize: "30px",
                            fontWeight: "800",
                          },
                          chartArea: {
                            left: 10,
                            width: "60%",
                            height: "75%",
                            backgroundColor: "transparent",
                          },
                          slices: {
                            0: { color: "#39B54A" },
                            1: { color: "#00A5F0" },
                            2: { color: "#FF931E" },
                            3: { color: "#ED1C24" },
                          },
                          backgroundColor: "transparent",
                          legend: {
                            position: "labeled",
                          },
                        }}
                        rootProps={{ "data-testid": "2" }}
                      />
                    )
                  ) : (
                    <img
                      src="piechart1.png"
                      className="piechart"
                      alt="pieChart"
                      style={{
                        width: "374px",
                        display: "block",
                        marginLeft: "10px",
                      }}
                    ></img>
                  )}
                </div>
              </div>
            </div>
            <div
              id="cardclient"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5"
            >
              <div
                class="card"
                style={{
                  border: "0.7px solid #CCCCCC", //sunita-task-3482-changing border color
                  //borderBottom: "1.7px solid #414141",
                }}
              >
                <div class="card-body" style={{ height: "395px" }}>
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div
                        style={{
                          width: "300px",
                          paddingBottom: "20px",
                          paddingTop: "5px",
                        }}
                      >
                        {" "}
                        {/*Akash-Task-3878 */}
                        <label
                          style={{
                            fontFamily: "Roboto",
                            color: "#71717199",
                            fontSize: "13px",
                          }}
                        >
                          Showing result for &nbsp;
                        </label>
                        <span
                          id="pacing1"
                          style={{
                            color: "#F28C0F", //sunita-task-3482-changing text color
                            fontFamily: "Roboto",
                            fontSize: "12px",
                          }}
                        >
                          {" "}
                          "{this.state.label}"
                        </span>
                      </div>
                    </div>
                    {/* sandeep-task-2998 -added code to show the result of dropdown action on screen */}

                    <div
                      class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                      style={{ paddingBottom: "5px" }}
                    >
                      {/*sunita-task-3818- added css as per requirement */}
                      <div class="float-right">
                        {/* kiran-4745-removing console warning-changed == to === */}
                        {this.state.byClient === true ? (
                          <span>
                            <select
                              id="clientName"
                              name="clientName"
                              class="input-small"
                              className="form-control clientName_l" // Musbir-Task-4388
                              onChange={this.handleClientName.bind(this)}
                              style={{
                                width: "150px", // Musbir-Task-4388
                                //width: "138px", //Akash-Task-4124
                                height: "30px",
                                fontSize: "11px",
                                fontFamily: "roboto",
                                border: "1.4px solid rgb(20, 76, 155)",
                              }}
                            >
                              <option value="All Clients">
                                Select Advertiser
                              </option>
                              {/**Somnath Task-3253, Add unique key @ iteration of array*/}
                              {this.state.clientList.map((clientList, i) => (
                                <option value={clientList} key={i}>
                                  {clientList}
                                </option>
                              ))}
                            </select>
                          </span>
                        ) : (
                          ""
                        )}
                        {/**Somnath Task-3253, Add unique key @ iteration of array*/}
                        {this.state.byClient === false ? (
                          <span>
                            {" "}
                            <select
                              id="publisherName"
                              name="publisherName"
                              class="input-small"
                              className="form-control"
                              onChange={this.handlePublisherName.bind(this)}
                              style={{
                                width: "150px", //Akash-Task-4124
                                height: "30px",
                                fontSize: "11px",
                                fontFamily: "roboto",
                                border: "1.4px solid rgb(20, 76, 155)",
                              }}
                            >
                              <option value="All Publishers">
                                Select Publisher
                              </option>
                              {this.state.publisherList.map(
                                (publisherList, i) => (
                                  <option value={publisherList.pID} key={i}>
                                    {publisherList.publisherName}
                                  </option>
                                )
                              )}
                            </select>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <hr style={{ color: "lightgrey" }}></hr>
                  {/* sandeep-task-2998 -added horizontaline  */}
                  <br />
                  {this.state.dataExist ? (
                    <CanvasJSChart
                      options={options1}
                      onRef={(ref) => (this.chart = ref)}
                    />
                  ) : (
                    //kiran-4745-removing console warning-added alt=""
                    <img
                      alt=""
                      src="barchart1.png"
                      className="barchart"
                      style={{
                        width: "365px",
                        display: "block",
                        marginLeft: "70px",
                      }}
                    ></img>
                  )}
                </div>
              </div>
            </div>
            <br />
            <br />

            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 demo ">
              {/* Sandeep-task-3221-added font size and font family for alert */}
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "22px",
                  fontWeight: "450",
                  borderBottom: "3px solid #144c9b",
                  paddingBottom: "2px",
                  color: "rgba(0, 0, 0, 0.85)",
                }}
              >
                Alerts
              </span>
              <span
                class="float-right"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  marginTop: "10px",
                  fontWeight: "450",
                  color: "#144c9b",
                }}
              >
                {/* kiran-4745-removing console warning-added "#/" */}
                <a href="#/" onClick={this.handleMarkAllReadAndClear}>
                  Mark All Read & Clear
                </a>
              </span>
              {/* Sandeep-task-2998-added code to handle mark all read & clear functionality */}
              <hr />

              {this.state.unreadAlerts.length > 0 ? (
                //  {/* Sandeep-task-3221-added height to scroll for alert */}
                <div
                  className="unreadalerts"
                  style={{
                    overflowY: "scroll",
                    maxHeight: "350px",
                    position: "absolute",
                  }}
                >
                  {this.state.unreadAlerts.map((unreadAlerts) => {
                    return (
                      <span className="cardUnreadAlerts">
                        <div
                          class="card"
                          style={{ boxShadow: "5px 5px 5px  #888888" }}
                        >
                          <div class="card-body">
                            <b>
                              {/* //shivani-3634-passed alertID to this handlechange for performance review task. */}
                              {/* kiran-4745-removing console warning-added "#/" */}
                              <a
                                href="#/" //karan-task-3895-link issue
                                onClick={
                                  this.displayCampaignSpecificationAlerts
                                }
                                campID={unreadAlerts.campID}
                                campaignName={unreadAlerts.campaignName}
                                parentCampID={unreadAlerts.parentCampID}
                                reallocationID={unreadAlerts.reallocationID}
                                pID={unreadAlerts.pID}
                                alertID={unreadAlerts.alertID}
                              >
                                {unreadAlerts.campID}
                              </a>
                            </b>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <span
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "12px",
                              }}
                            >
                              <b>{unreadAlerts.campaignName}</b>
                              &nbsp;&nbsp;|&nbsp;&nbsp;
                              {/* kiran-4745-removing console warning-changed == to === */}
                              {unreadAlerts.content ===
                              "New Campaign Allocated" ? (
                                <sapn
                                  style={{
                                    color: "#524373",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Publisher Accept Campaign" ? (
                                <sapn
                                  style={{
                                    color: "#0AB710",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Publisher Upload Creatives" ? (
                                <sapn
                                  style={{
                                    color: "#192BCF",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Creatives review is completed" ? (
                                <sapn
                                  style={{
                                    color: "#310273",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Campaign end date is updated" ? (
                                <sapn
                                  style={{
                                    color: "#4285F4",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Allocation cancelled after 48 hrs" ? (
                                <sapn
                                  style={{
                                    color: "#D11124",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Publisher Upload Lead" ? (
                                <sapn
                                  style={{
                                    color: "#19BDCF",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Publisher Counter Campaign" ? (
                                <sapn
                                  style={{
                                    color: "#F2860F",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content === "Campaign Edited" ? (
                                <sapn
                                  style={{
                                    color: "#4285F4",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Publisher Reject Campaign" ? (
                                <sapn
                                  style={{
                                    color: "#D11124",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Allocate campaign to publisher for bidding" ? (
                                <sapn
                                  style={{
                                    color: "#F2860F",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Publisher Submited Bidding" ? (
                                <sapn
                                  style={{
                                    color: "#F2860F",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Publisher Bidding is reviewed by Agency" ? (
                                <sapn
                                  style={{
                                    color: "#310273",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                "Campaign is cancel" ? (
                                <sapn
                                  style={{
                                    color: "#D11124",
                                    fontWeight: "600",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : unreadAlerts.content ===
                                  "Campaign lead is decremented" ||
                                unreadAlerts.content ===
                                  "Publisher Accept Campaign and IO Generated" ||
                                unreadAlerts.content ===
                                  "updated existing Lead(s)" ? (
                                <sapn
                                  style={{ color: "green", fontWeight: "600" }}
                                >
                                  {unreadAlerts.content}
                                </sapn>
                              ) : //shivani-3634-merge above conditions for same color for performance review task .
                              // : unreadAlerts.content ==
                              //   "Publisher Accept Campaign and IO Generated" ? (
                              // 	<sapn
                              // 		style={{ color: "green", fontWeight: "600" }}>
                              // 		{unreadAlerts.content}
                              // 	</sapn>
                              // ) : //Sandeep-task-3279-added code for to compair content of  updated existing Lead
                              // unreadAlerts.content ==
                              //   "updated existing Lead(s)" ? (
                              // 	<sapn
                              // 		style={{ color: "green", fontWeight: "600" }}>
                              // 		{unreadAlerts.content}
                              // 	</sapn>
                              // )
                              //Chaitanya-5047-Added orange color for No Leads found for Asset alert
                              unreadAlerts.content &&
                                unreadAlerts.content.startsWith(
                                  "No Leads found for Asset"
                                ) ? (
                                <span
                                  style={{
                                    color: "#FFA500",
                                    fontWeight: "500",
                                  }}
                                >
                                  {unreadAlerts.content}
                                </span>
                              ) : unreadAlerts.content === "" ? (
            	//Nilesh-5067- Add condition for shows no alerts
               (this.state.alertShows === true) ?
						  // Sandeep-task-2998 added bellIcon
              <span style={{ marginLeft: "60px" }}>
              {/* kiran-4745-removing console warning-added alt="" */}
              <img
                alt=""
                className="alertImg"
                src="bellIcon.png"
                width="100"
                height="80"
              ></img>
              <sapn
                className="noAlert"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginLeft: "85px",
                  fontSize: "15px",
                }}
              >
                "No Alerts Available!!"
              </sapn>
            </span>
							 : 
							//Nilesh-5067- Add condition for shows no alerts
							 <Table
							   id="myTable"
							   loading={this.state.loading2}
							   className={`${tableCSS} "ant-table-thead"`}  
							   size="small"
							 >		
							</Table>	                
              ) : (
                //Nilesh-5067- Add condition for shows no alerts
                  (this.state.alertShows === true) ?
								  // Sandeep-task-2998 added bellIcon 
                  <span style={{ marginLeft: "110px" }}>
                  {/* kiran-4745-removing console warning-added alt="" */}
                  <img
                    alt=""
                    className="alertImg"
                    src="bellIcon.png"
                    width="100"
                    height="80"
                  ></img>
                  <sapn
                    className="noAlert"
                    style={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "600",
                      marginLeft: "85px",
                    }}
                  >
                    "No Alerts Available!!"
                  </sapn>
                </span>
							 : 
							//Nilesh-5067- Add condition for shows no alerts
							 <Table
							   id="myTable"
							   loading={this.state.loading2}
							   className={`${tableCSS} "ant-table-thead"`}  
							   size="small"
							 >		
							</Table>	
                              )}
                              &nbsp;&nbsp;|&nbsp;&nbsp; {unreadAlerts.datetime}
                            </span>
                          </div>
                        </div>
                        <br />
                      </span>
                    );
                  })}
                </div>
              ) : (
                //Nilesh-5067- Add condition for shows no alerts
                 (this.state.alertShows === true) ?
								   // Sandeep-task-2998 added bellIcon
                   <span style={{ marginLeft: "110px" }}>
                   {/* kiran-4745-removing console warning-added alt="" */}
                   <img
                     alt=""
                     className="alertImg"
                     src="bellIcon.png"
                     width="100"
                     height="80"
                   ></img>
                   <sapn
                     className="noAlert"
                     style={{
                       color: "black",
                       fontWeight: "600",
                       // color: "orange",
                       fontSize: "15px",
                       marginLeft: "85px",
                     }}
                   >
                     No Alerts Available!!
                   </sapn>
                 </span>
							  : 
							//Nilesh-5067- Add condition for shows no alerts
							 <Table
							   id="myTable"
							   loading={this.state.loading2}
							   className={`${tableCSS} "ant-table-thead"`}  
							   size="small"
							 >		
							</Table>      
              )}
              {/* end of scroll div*/}
            </div>
          </div>
          {/* end of 2nd row */}
          <br />

          <div class="row">
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              {/* Sandeep-task-3221-added color code for operational action  */}
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "22px",
                  fontWeight: "450",
                  borderBottom: "3px solid #144c9b",
                  paddingBottom: "10px",
                  color: "rgba(0, 0, 0, 0.85)",
                }}
              >
                Operational Actions
              </span>
              <hr />
            </div>
          </div>
          {/* end of 3rd row */}

          <div class="row">
            {this.state.campaignCount.map((campaignCount, i) => {
              return (
                <div
                  class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                  key={i}
                >
                  <Card style={{ width: 300 }} className="cardSetup">
                    <div class="row">
                      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <img
                          src="toSetupNew.jpg"
                          className="steupImage"
                          alt="Campaigns To Setup"
                          height="30px"
                          width="30px"
                        ></img>
                      </div>
                      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <span
                          className="steupCount"
                          style={{
                            color: "#144c9b",
                            fontSize: "36px",
                            fontWeight: "500",
                          }}
                        >
                          {campaignCount.setupCount}
                        </span>
                        <br />
                        <span
                          className="steupTitle"
                          style={{ fontSize: "16px", color: "#f1f1f1" }}
                        >
                          {user.role === "ANC" ? (
                            //kiran-4745-removed console warning-replace <a> tag with <span>
                            <span style={{ color: "#414141" }}>
                              Campaigns To Setup
                            </span>
                          ) : (
                            <span style={{ color: "#414141" }}>
                              {" "}
                              <a
                                onClick={() =>
                                  this.props.history.push("/campaignList", {
                                    key: "campaignSetup",
                                  })
                                } //karan-task-3684-replace query params
                              >
                                Campaigns To Setup
                              </a>
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
            {/**Somnath Task-3253, Add unique key @ iteration of array*/}
            {this.state.campaignCount.map((campaignCount, i) => {
              return (
                <div
                  class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                  key={i}
                >
                  <Card style={{ width: 300 }} className="cardAllocate">
                    <div class="row">
                      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <img
                          className="alllocateImage"
                          src="toAllocateNew.jpg"
                          alt="Campaigns To Allocate"
                          height="30px"
                          width="30px"
                        ></img>
                      </div>
                      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <span
                          className="allocateCount"
                          style={{
                            color: "#144c9b",
                            fontSize: "36px",
                            fontWeight: "500",
                          }}
                        >
                          {campaignCount.allocateCount}
                        </span>
                        <br />
                        <span
                          className="allocateTitle"
                          style={{ fontSize: "16px", color: "#414141" }}
                        >
                          {" "}
                          {/* Nilesh-5033- Add if condition, remove campaignList page at ANC side */}
                          {user.role === "ANC" ? (
                            <span style={{ color: "#414141" }}>
                              Campaigns To Allocate
                            </span>
                          ) : (
                            <a
                              onClick={() =>
                                this.props.history.push("/campaignList", {
                                  key: "active",
                                })
                              } //karan-task-3684-replace query params
                            >
                              Campaigns To Allocate
                            </a>
                          )}
                        </span>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
            {/**Somnath Task-3253, Add unique key @ iteration of array*/}
            {this.state.campaignCount.map((campaignCount, i) => {
              return (
                <div
                  class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                  key={i}
                >
                  <Card style={{ width: 300 }} className="cardRespond">
                    <div class="row">
                      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <img
                          className="respondImage"
                          src="campRespond.png"
                          alt="Campaigns To Respond"
                          height="30px"
                          width="30px"
                        ></img>
                      </div>
                      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <span
                          className="respondCount"
                          style={{
                            color: "#144c9b",
                            fontSize: "36px",
                            fontWeight: "500",
                          }}
                        >
                          {campaignCount.respondCount}
                        </span>
                        <br />
                        <span
                          className="respondTitle"
                          style={{ fontSize: "16px", color: "#414141" }}
                        >
                          <a
                            onClick={() => {
                              this.props.history.push("/campaignRespond"); //karan-task-3715-replace query params
                            }}
                          >
                            Campaigns To Respond
                          </a>
                        </span>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
          {/* end of 3rd row */}
          <br />
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <div
                className="biddingClass"
                style={{
                  background: "#ECECEC",
                  padding: "10px 15px 10px 15px",
                  height: "430px",
                }}
              >
                <p>
                  <h3 style={{ fontFamily: "roboto" }}>Bidding</h3>
                </p>
                <br />
                <p>
                  <Badge
                    count={this.state.counterLength}
                    overflowCount={10}
                    offset={[1, -5]}
                  >
                    <Button
                      size="default"
                      style={{ borderRadius: "40px" }}
                      className={this.state.headExample}
                      id="counter"
                      onClick={this.biddingLink}
                    >
                      <span style={{ font: "8px" }}>Counter</span>
                    </Button>
                  </Badge>
                  <Badge
                    count={this.state.rejectLength}
                    overflowCount={10}
                    offset={[1, -5]}
                  >
                    <Button
                      size="default"
                      style={{ borderRadius: "40px" }}
                      className={this.state.headExample1}
                      id="reject"
                      onClick={this.biddingLink}
                    >
                      Reject
                    </Button>
                  </Badge>
                  {user.role === "AC" ? (
                    <Badge
                      count={this.state.rfpBiddingLength}
                      overflowCount={10}
                      offset={[1, -5]}
                    >
                      <Button
                        size="default"
                        style={{ borderRadius: "40px" }}
                        className={this.state.headExample6}
                        id="RFP"
                        onClick={this.biddingLink}
                      >
                        RFP
                      </Button>
                    </Badge>
                  ) : (
                    ""
                  )}
                </p>
                {/**Somnath Task-3253, Add unique rowkey @ iteration of array*/}
                {/* Sandeep-task-3377-added data is loading loader */}
                {/* Sandeep-task-3377-removed height property */}
                <p>
                  {" "}
                  <Table
                    {...this.state.tableState}
                    id="myTable"
                    bordered
                    dataSource={this.state.biddingArray}
                    locale={this.state.loading1 ? locale : "No Data"}
                    rowKey="campID"
                    loading={this.state.loading1}
                    onChange={onChange}
                    columns={
                      this.state.RFPBiddingTab === "Counter" ||
                      this.state.RFPBiddingTab === "Reject"
                        ? columns01
                        : this.state.RFPBiddingTab === "RFPBiddingTab"
                        ? columnsRFP
                        : columns01
                    }
                    // className="ant-table-thead"
                    // className={tableCSS}
                    className={`${tableCSS} "ant-table-thead"`}
                    scroll={{ y: 200 }}
                    pagination={{ pageSize: this.state.pageSize1 }}
                  ></Table>
                </p>
                {/* kiran-4745-removing console warning-changed classname */}
                <p>
                  <div class="row">
                    <div
                      class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                      style={{ marginTop: "-68px", marginLeft: "5px" }}
                    >
                      Records per page:&nbsp;
                      <select
                        defaultValue={this.state.pageSize1}
                        onChange={this.handleChangePageSize1}
                        id="pacing1"
                        class="input-small"
                        className="form-control"
                        name="pacing"
                        style={{
                          width: "65px",
                          height: "30px",
                          display: "initial",
                        }}
                      >
                        <option value="5" selected>
                          5
                        </option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>
                </p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
              <div
                className="biddingClass"
                style={{
                  background: "#ECECEC",
                  padding: "10px 15px 10px 15px",
                  height: "430px",
                }}
              >
                <p>
                  <h3 style={{ fontFamily: "roboto" }}>Review</h3>
                </p>
                <br />
                <p>
                  <span class="badgelink">
                    <Badge
                      count={this.state.creativeLength}
                      overflowCount={10}
                      offset={[1, -5]}
                    >
                      <Button
                        size="default"
                        className={this.state.headExample2}
                        id="creatives"
                        onClick={this.reviewLink}
                      >
                        Creatives
                      </Button>
                    </Badge>
                  </span>
                  <span class="badgelink">
                    <Badge
                      style={{ marginRight: "20px" }}
                      count={this.state.leadsLength}
                      overflowCount={10}
                      offset={[1, -5]}
                    >
                      <Button
                        size="default"
                        className={this.state.headExample3}
                        id="leads"
                        onClick={this.reviewLink}
                      >
                        Leads
                      </Button>
                    </Badge>{" "}
                  </span>
                  {user.role === "AC" ? (
                    <span class="badgelink ">
                      <Badge
                        count={this.state.monthArrayLength}
                        overflowCount={10}
                        offset={[1, -5]}
                      >
                        <Button
                          size="default"
                          className={this.state.headExample4}
                          id="invoice"
                          onClick={this.reviewLink}
                        >
                          Invoice
                        </Button>
                      </Badge>{" "}
                    </span>
                  ) : (
                    ""
                  )}

                  {user.role === "AC" ? (
                    <span class="badgelink">
                      <Badge
                        style={{ marginRight: "20px" }}
                        count={this.state.othersLength}
                        coverflowCount={10}
                        offset={[1, -5]}
                      >
                        <Button
                          size="default"
                          className={this.state.headExample5}
                          id="others"
                          onClick={this.reviewLink}
                        >
                          Others
                        </Button>
                      </Badge>{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                {/**Somnath Task-3253, Add unique rowkey @ iteration of array*/}
                {/* Sandeep-task-3377-added data is loading loader */}
                <p>
                  {" "}
                  <Table
                    {...this.state.tableState}
                    id="myTable"
                    bordered
                    dataSource={this.state.reviewArray}
                    locale={this.state.loading2 ? locale : "No Data"}
                    loading={this.state.loading2}
                    rowKey="campID"
                    onChange={onChange}
                    columns={
                      this.state.tabReview === "creatives" ||
                      this.state.tabReview === "Creatives"
                        ? columns1
                        : this.state.tabReview === "leads"
                        ? columns2
                        : this.state.tabReview === "invoice"
                        ? invoiceData
                        : columns3
                    }
                    // className="ant-table-thead table-responsive"
                    // className={tableCSS}
                    className={`${tableCSS} "ant-table-thead table-responsive"`}
                    scroll={{ y: 150 }}
                    pagination={{ pageSize: this.state.pageSize2 }}
                  ></Table>
                </p>
                {/* kiran-4745-removing console warning-changed classname */}
                {/* kiran-4745-removing console warning-changed == to === */}
                <div style={{ marginTop: "-56px" }}>
                  {/* Sandeep-task-3653-Review section(Creatives and Leads)--View all link added(Agency, pub, Adv) */}
                  {this.state.tabReview === "creatives" ||
                  this.state.tabReview === "Creatives" ? (
                    this.state.creativeLength > 10 ? (
                      <span style={{ marginLeft: "211px" }}>
                        {/* kiran-4745-removing console warning-added "#/" and alt="" */}
                        <a href="#/" onClick={this.openViewAllCreatives}>
                          View all creatives
                          <img
                            alt=""
                            style={{ marginTop: "-18px", marginLeft: "326px" }}
                            src="Iconfeather.png"
                            width="20"
                            height="15"
                          ></img>
                        </a>
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                {/* Sandeep-task-3653-Review section(Creatives and Leads)--View all link added(Agency, pub, Adv) */}
                <div style={{ marginTop: "-56px" }}>
                  {this.state.tabReview === "leads" ? (
                    this.state.leadsLength > 10 ? (
                      <span style={{ marginLeft: "220px" }}>
                        {/* kiran-4745-removing console warning-added "#/" and alt=""  */}
                        <a href="#/" onClick={this.openViewAllLeadReview}>
                          View all leads
                          <img
                            alt=""
                            style={{ marginTop: "-18px", marginLeft: "312px" }}
                            src="Iconfeather.png"
                            width="20"
                            height="15"
                          ></img>
                        </a>
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                {/* Sandeep-task-3653-Review section(Creatives and Leads)--View all link added(Agency, pub, Adv) */}
                {/* kiran-4745-removing console warning-changed == to === */}
                {this.state.tabReview === "creatives" ||
                this.state.tabReview === "Creatives" ||
                this.state.tabReview === "leads" ? (
                  this.state.creativeLength > 10 ||
                  this.state.leadsLength > 10 ? (
                    ""
                  ) : (
                    <p>
                      <div class="row">
                        <div
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                          style={{ marginTop: "-5px", marginLeft: "5px" }}
                        >
                          Records per page:&nbsp;
                          <select
                            defaultValue={this.state.pageSize2}
                            onChange={this.handleChangePageSize2}
                            id="pacing1"
                            class="input-small"
                            className="form-control"
                            onfocus="this.size=2"
                            name="pacing"
                            style={{
                              width: "65px",
                              height: "30px",
                              display: "initial",
                            }}
                          >
                            <option value="5" selected>
                              5
                            </option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                          </select>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                  )
                ) : (
                  <p>
                    <div class="row">
                      <div
                        class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                        style={{ marginTop: "-5px", marginLeft: "5px" }}
                      >
                        Records per page:&nbsp;
                        <select
                          defaultValue={this.state.pageSize2}
                          onChange={this.handleChangePageSize2}
                          id="pacing1"
                          class="input-small"
                          className="form-control"
                          onfocus="this.size=2"
                          name="pacing"
                          style={{
                            width: "65px",
                            height: "30px",
                            display: "initial",
                          }}
                        >
                          <option value="5" selected>
                            5
                          </option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </p>
                )}
              </div>
            </div>
          </div>
          <br />
        </div>
        {/* end of container 1 */}
        <div class="container pull-right" id="chatbox">
          <div
            class="row chat-window col-xs-6 col-md-5 pull-right"
            id="chat_window_1"
          >
            <div class="col-xs-12 col-md-12">
              <div
                class="panel panel-default"
                style={{ display: "initial", border: "0px solid transparent" }}
              >
                <div class="panel-heading top-bar">
                  <div class="col-md-8 col-xs-8">
                    <h3 class="panel-title">
                      <span class="glyphicon glyphicon-comment"></span>
                      &nbsp;&nbsp;Messages
                    </h3>
                  </div>
                  <div class="col-md-4 col-xs-4" style={{ textAlign: "right" }}>
                    {/* <a href="#"><span id="minim_chat_window" style={{ color: "white" }} class="glyphicon glyphicon-minus icon_minim"></span></a> */}
                    <a href="#">
                      <span
                        id="minim_chat_window"
                        style={{ color: "white" }}
                        class="glyphicon glyphicon-arrow-down icon_minim"
                      ></span>
                    </a>
                    {/* Sandeep-task-3221-added glyphicon-arrow-down */}
                  </div>
                </div>
                {/* kiran-4745-removing console warning-changed == to === */}
                {this.state.unreadMessagesLength === "0" ? (
                  <div
                    id="messagebody"
                    class="panel-body msg_container_base panel-collapsed"
                  >
                    <div class="row msg_container base_receive">
                      <div class="col-md-12 col-xs-12">
                        <div class="messages msg_receive">
                          <p style={{ fontSize: "larger", color: "orange" }}>
                            No Message From Publisher
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    id="messagebody"
                    class="panel-body msg_container_base panel-collapsed"
                  >
                    {this.state.unreadMessages.map((unreadMessages) => {
                      return (
                        <div class="row msg_container base_receive">
                          <div class="col-md-3 col-xs-3 avatar">
                            <Avatar
                              style={{
                                backgroundColor: "#007cc3",
                                verticalAlign: "middle",
                              }}
                              size="large"
                            >
                              {unreadMessages.author.match(/\b\w/g).join("")}
                            </Avatar>
                          </div>
                          <div class="col-md-9 col-xs-9">
                            <div class="messages msg_receive">
                              <p
                                className="panelCampInfo"
                                style={{ fontSize: "10px" }}
                              >
                                {/* Sonali-3216-added type below */}
                                {/* kiran-4745-removing console warning-added "#/" */}
                                (Campaign Id:
                                <a
                                  href="#/"
                                  onClick={this.displayCampaignSpecificationMsg}
                                  campID={unreadMessages.campID}
                                  type={unreadMessages.campaignType}
                                  campaignName={unreadMessages.campaignName}
                                  parentCampID={unreadMessages.parentCampID}
                                  reallocationID={unreadMessages.reallocationID}
                                  pID={unreadMessages.pID}
                                >
                                  {unreadMessages.parentCampID
                                    ? unreadMessages.parentCampID
                                    : unreadMessages.campID}
                                </a>
                                |Name:{unreadMessages.campaignName})
                              </p>
                              {/* kiran-4657-added overflowWrap property */}
                              <p
                                className="panelCampContent"
                                style={{
                                  fontSize: "9px",
                                  overflowWrap: "break-word",
                                }}
                              >
                                <b>{unreadMessages.content}</b>
                              </p>
                              <div
                                className="panelCampDate"
                                style={{ fontSize: "9px", float: "right" }}
                              >
                                <p>{unreadMessages.datetime}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* //end of msg  */}
              </div>
            </div>
          </div>
        </div>
        {/* 4440-Footer */}
        <Footer />
      </div>
      // end of last div
    );
  } // end of render
}
//end of class Dashboard

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(withRouter(Dashboard));
