/* Copyright(c) 2022 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author :Rutuja Jagtap task-4517
 *@fileName :addAQALogin.js
 *Desc: Add AQA user
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { Tooltip, Icon, Table } from "antd";
import { css } from "emotion";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
const Swal = require("sweetalert2");
const tableCSS = css({
  backgroundColor: "white",
  fontFamily: "sans-serif",
  fontSize: "20px",
  borderStyle: "bold",
  "& thead > tr > th": {
    backgroundColor: "#144999",
    color: "white",
    fontWeight: "500",
  },
  "& thead > tr": {
    borderWidth: "2px",
    borderStyle: "solid",
  },
});

class addAQARole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputClass11: "btn add-button",
      fields: {},
      errors: "",
      loader2: "",
      userDomain: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitUser = this.submitUser.bind(this);
  } // end of constructor
  componentDidMount() {
    this.setState({ loader2: "loader_report1" });

    fetch("/users/getAQAUserList", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((getAQAUserList) => {
        this.setState({ getUserList: getAQAUserList, loader2: "" });
      })
      .catch(function (err) {
        console.log(err);
      });
  } //end of componentDidMount

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    let spanDisplay = "none";
    let requiredFeildValidate = true; //Nilesh-2833-Removing console warning
    let userNameValidate = true; //Nilesh-2833-Removing console warning
    let firstNameValidate = true; //Nilesh-2833-Removing console warning
    let lastNameValidate = true;
    let roleValidate = true;
    fields["userName"] = fields["userName"] + this.state.userDomain;
    this.setState({ fields });

    if (!fields["userName"] && !fields["firstName"] && !fields["lastName"]) {
      requiredFeildValidate = false;
      errors["commonError"] = <li>Please fill below required fields</li>;
      this.setState({ errors: errors });
    } else {
      if (
        //Nilesh-2833-Removing console warning
        this.state.userName === "" ||
        this.state.userName === null ||
        this.state.userName === undefined
      ) {
        userNameValidate = false;
        errors["userName"] = <li>Please enter user name</li>;
        this.setState({ errors: errors });
      } else {
        var pattern = new RegExp(
          //Nilesh-2833-Removing console warning
          // eslint-disable-next-line no-useless-escape
          /^\s*$|^[\.a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@(?!gmail.com)(?!gmail.co.in)(?!yahoo.com)(?!yahoo.co.in)(?!facebook.com)(?!facebook.co.in)[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/
        );

        if (this.state.userName.match(pattern)) {
          userNameValidate = true;
        } else {
          userNameValidate = false;
          errors["userName"] = <li>Please enter valid user name</li>;
          this.setState({ errors: errors });
        }
      }

      if (
        //Nilesh-2833-Removing console warning
        this.state.firstName === "" ||
        this.state.firstName == null ||
        this.state.firstName === undefined
      ) {
        firstNameValidate = false;
        errors["firstName"] = <li>Please enter first name</li>;
        this.setState({ errors: errors });
      } else {
        let pattern = new RegExp(/^[a-zA-Z]+$/); //Nilesh-2833-Removing console warning
        if (!pattern.test(fields["firstName"])) {
          userNameValidate = false;
          errors["firstName"] = <li>please enter valid first name</li>;
          this.setState({ errors: errors });
        } else {
          errors["firstName"] = "";
          this.setState({ errors: errors });
        }
      }
      if (
        //Nilesh-2833-Removing console warning
        this.state.lastName === "" ||
        this.state.lastName == null ||
        this.state.lastName === undefined
      ) {
        lastNameValidate = false;
        errors["lastName"] = <li>Please enter last name</li>;
        this.setState({ errors: errors });
      } else {
        let pattern = new RegExp(/^[a-zA-Z]+$/); //Nilesh-2833-Removing console warning
        if (!pattern.test(fields["lastName"])) {
          userNameValidate = false;
          errors["lastName"] = <li>please enter valid last name</li>;
          this.setState({ errors: errors });
        } else {
          errors["lastName"] = "";
          this.setState({ errors: errors });
        }
      }

      if (
        //Nilesh-2833-Removing console warning
        this.state.role === "" ||
        this.state.role == null ||
        this.state.role === undefined
      ) {
        roleValidate = false;
        errors["role"] = <li>Please enter role</li>;
        this.setState({ errors: errors });
      } else {
        errors["role"] = "";
        this.setState({ errors: errors });
      }
    }

    if (
      //Nilesh-2833-Removing console warning
      requiredFeildValidate === false ||
      userNameValidate === false ||
      firstNameValidate === false ||
      lastNameValidate === false ||
      roleValidate === false
    ) {
      formIsValid = false;
      this.setState({
        errors: errors,
        alertBorder: "1px solid red",
        alertWidth: "1000px",
        alertAlign: "center",
        alertDisplay: "block",
        spanSpaceDisplay: spanDisplay,
      });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else if (
      //Nilesh-2833-Removing console warning
      requiredFeildValidate === true || // mufiz Task-3984 Settings - Add User - Username should accept EmailID as per tooltip
      userNameValidate === true ||
      firstNameValidate === true ||
      lastNameValidate === true ||
      roleValidate === true
    ) {
      formIsValid = true;
    }
    return formIsValid;
  }

  handleChange(e) {
    let fields = this.state;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  submitUser(e) {
    let data;
    data = {
      userName: this.state.userName,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    };

    if (this.validateForm() === false) {
      return;
    } else {
      let errors = {};
      fetch("/users/getUserDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((userDetails) => {
          if (userDetails.length > 0) {
            errors["sameUser"] = <li>User Already Exists</li>;
            this.setState({ errors: errors });
          } else {
            fetch("/users/addAQAUser", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            })
              .then(function (response) {
                if (response.status >= 400) {
                  throw new Error("Bad response from server");
                }
                return response.json();
              })
              .then(function (response) {
                // success message with response
                if (response.success === true) {
                  //Nilesh-2833-Removing console warning
                  Swal.fire({
                    text: "User Added Successfully",
                    type: "success",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    preConfirm: () => {
                      window.location.reload();
                    },
                  });
                } else {
                }
              });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  //Chaitanya-5100-Added function for Active status
  changeUserStatusActive(e, record) {
    var orgID = record.userID;
    let data = {
      orgID: orgID,
    };
    fetch("/users/setUserStatusActiveDIQA", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: "User Activated",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        }
        this.setState({ isDisabled: true });
      });
  }
  //Chaitanya-5100-Added function for Inactive status
  changeUserStatusInactive(e, record) {
    var orgID = record.userID;
    let data = {
      orgID: orgID,
    };
    fetch("/users/setUserStatusInactive", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: "User Deactivated",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        }
        this.setState({ isDisabled: true });
      });
  }

  render() {
    const asterisk = {
      color: "red",
    };
    const addUserAgency = [
      {
        title: "ID",
        dataIndex: "ID",
        key: "ID",
        width: 40,
        align: "left",
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "14px" }}>{record.userID}</span>
          </div>
        ),
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
        width: 90,
        align: "left",
        render: (text, record) => (
          <div>
            <div style={{ fontSize: "14px", width: "110px" }}>
              {record.firstName}
            </div>
          </div>
        ),
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
        width: 90,
        align: "left",
        render: (text, record) => (
          <div>
            <div class="word-wrap" style={{ fontSize: "14px" }}>
              {record.lastName}
            </div>
          </div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 150,
        align: "left",
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "14px" }}>{record.userName}</span>
          </div>
        ),
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        width: 60,
        align: "Center",
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "14px" }}>{record.role}</span>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 40,
        align: "Center",
        render: (text, record) => (
          <div>
            {record.status === "N" ? (
              <span style={{ fontSize: "14px" }}>Deactivated</span>
            ) : (
              <span style={{ fontSize: "14px" }}>Active</span>
            )}
          </div>
        ),
      },
      {
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        width: 40,
        align: "Center",
        render: (text, record) => (
          <div>
            {record.status === "Y" ||
            record.status === null ||
            record.status === "" ? (
              <button
                style={{ padding: "7px 7px", margin: "auto" }}
                className={this.state.inputClass11}
                onClick={(e) => this.changeUserStatusInactive(e, record)}
                type="submit"
              >
                Deactive{" "}
              </button>
            ) : (
              <button
                style={{ padding: "7px 7px", margin: "auto" }}
                className={this.state.inputClass11}
                onClick={(e) => this.changeUserStatusActive(e, record)}
                type="submit"
              >
                Activate{" "}
              </button>
            )}
          </div>
        ),
      },
    ];

    return (
      <div>
        <Navigation />
        <div class="container-fluid" style={{ paddingTop: "108px" }}>
          <div class="row">
            <div class="col-lg-4" id="AddUser" style={{ paddingLeft: "20px" }}>
              <a href="./newdashboard" style={{ color: "#056eb8" }}>
                <FaArrowAltCircleLeft size={32} style={{ float: "left" }} />
              </a>
            </div>
          </div>
          <h2
            align="center"
            id="labelDI"
            class="addUsr"
            style={{ marginTop: "-31px" }}
          >
            Add User Details
          </h2>

          <div
            class="card-body"
            style={{
              margin: "10px",
              paddingBottom: "40px",
              backgroundColor: "#fff",
              borderRadius: "4px",
              boxShadow: "grey 1px 1px 6px 0px", //mufiz-task-3975 styling a box
            }}
          >
            {/* //shivani-3284-passed ID for DI label consistency */}
            <div style={{ fontSize: "14px", color: "red" }}>
              {this.state.errors.commonError}
            </div>
            <div
              className="errorMsg"
              style={{ fontSize: "14px", color: "red" }}
            >
              {this.state.errors.userName}
              {this.state.errors.role}
              {this.state.errors.firstName}
              {this.state.errors.lastName}
              {this.state.errors.sameUser}
            </div>
            {/* //3975-UI/UX Findings-Add User Details Screen-Mufiz- */}
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ">
                <label id="label">
                  User Name{" "}
                  <span style={asterisk}>
                    *&nbsp;
                    <Tooltip title=" User name should be Valid Email Id.">
                      <Icon
                        type="question-circle-o"
                        className="iconbackground"
                      />
                      {/*sunita-task-3818-added classname as per requirement for removing white patches */}
                    </Tooltip>
                  </span>
                </label>
                <br />

                <input
                  value={this.state.userName}
                  onChange={this.handleChange}
                  type="text"
                  id="userNameNew"
                  className="form-control"
                  name="userName"
                  style={{ width: "60%", height: "60%" }}
                  required
                />
                {/* kiran- bug 3911- added new changes as per requirement in username  */}
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 roleinputbox">
                {" "}
                {/*sunita-task-3782-added classname as per requirement */}
                <label id="label">
                  Role <span style={asterisk}>*</span>
                </label>
                <select
                  value={this.state.role}
                  onChange={this.handleChange}
                  type="text"
                  id="role"
                  className="form-control"
                  name="role"
                  style={{ width: "60%", height: "60%" }}
                  required
                >
                  <option value="None Selected" disabled selected>
                    None Selected
                  </option>
                  <option value="AQA">Quality Assurance</option>
                </select>
              </div>
            </div>
            <div class="row" style={{ marginTop: "10px" }}>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 roleinputbox">
                <label id="label">
                  First Name <span style={asterisk}>*</span>
                </label>
                <input
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  type="text"
                  id="firstName"
                  className="form-control"
                  name="firstName"
                  style={{ width: "60%", height: "33px" }}
                  required
                />
              </div>

              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 roleinputbox">
                <label id="label">
                  Last Name <span style={asterisk}>*</span>
                </label>
                <input
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  type="text"
                  id="lastName"
                  className="form-control"
                  name="lastName"
                  style={{ width: "60%", height: "33px" }}
                  required
                />
              </div>
            </div>
            {/* end of 2nd row */}
            <br />

            <button
              class="btn add-button"
              style={{ float: "right", marginTop: "-6px" }} //mufiz-task-3975 chang location from left to right
              className={this.state.inputClass11}
              onClick={this.submitUser}
              type="submit"
            >
              Save {/*Mufiz-Bug-3910  */}
            </button>
          </div>
          {/* end of card body */}
        </div>
        {/* end of container-fluid */}
        <br />
        <div id={this.state.loader2} />
        <p>
          {" "}
          <Table
            {...this.state.tableState}
            bordered
            dataSource={this.state.getUserList}
            columns={addUserAgency}
            className={`${tableCSS} "ant-table-thead"`} //Nilesh-2833-Removing console warning
            scroll={{ x: 1200, y: 1333 }}
          />
        </p>
        <br />
        <Footer />
      </div>
    );
  } // end of render
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
addAQARole.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(addAQARole));
