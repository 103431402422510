/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Priyanka Patil
@Creation Date:13-09-2022
@Description:UI for CRM Lead  report  under Agency view
*/
import React from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3940-VAPT URL changes
// import { Table} from 'mdbreact';
import { css } from 'emotion';
import { saveAs } from "file-saver";
import { LeadReviewLoader } from "../loaders/LeadReviewLoader";//4453-Nilesh, Added Loader


const Swal = require("sweetalert2");
const xlsx = require("xlsx");
var dateTime = require("node-datetime");

var fileName;



const tableCSS = css({
  backgroundColor: 'white',
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize: '14px !important',
    fontWeight: '500',
  },
  '& thead > tr >th': {
    border: '1px solid black',
    color: 'white',
  }
});


class leadViewMarketo extends React.Component {
  constructor() {
    super();
    this.state = {
      campID: '',
      leadInfoArray: [],
      fileID: '',
      marketoClientInfo: [],
      marketoClient: "",
      pageSize2: 100,
      tableState: { size: 'small' },
      fileErrors: {},
      loaderMessage: "",
      selectedLeads: [], //4453-Nilesh Add a new array for select leads
    }

    this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
    this.handleRedeliverLeads = this.handleRedeliverLeads.bind(this);
    this.handleRejectLeads = this.handleRejectLeads.bind(this);
    this.loadingRef = React.createRef();
    this.closeLoadingRef = React.createRef();

  }// end of constrcutor
  marketoLeadViewBackButton(e) {
    e.preventDefault(); //Priyanka-4463-to prevent # in URL
    let marketoClient = this.state.marketoClient;
    let campID = this.state.campID;
    this.props.history.push("/leadDeliverMarketo", { marketoClient: marketoClient, campID: campID });

  }
  //start of componentWillMount
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
      var parsed = this.props.location.state;
      let campID = parsed.campID;
      let fileID = parsed.fileID;
      let marketoClient = parsed.client;
      let marketoClientInfo = parsed.marketoClientInfo;
      let succLeadCount = parsed.succLeadCount;
      let failLeadCount = parsed.failLeadCount;

      this.setState({
        campID: campID,
        fileID: fileID,
        succLeadCount: succLeadCount,
        failLeadCount: failLeadCount,
        marketoClientInfo: marketoClientInfo,
        marketoClient: marketoClient
      });
//Rutuja Task-2978 changed != to !==
      if (succLeadCount != null || succLeadCount !== undefined) {
        let data = {
          campID: campID,
          fileID: fileID,
          succLeadCount: succLeadCount
        };

        fetch("/deliverLeadsMarketo/getSuccLeadInfo", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((leadInfoArray) => {
            this.setState({ leadInfoArray: leadInfoArray });
          })
          .catch(function (err) {
            console.log(err);
          });
      }
      //Rutuja Task-2978 changed != to !==
      else if (failLeadCount != null || failLeadCount !== undefined) {
        let data = {
          campID: campID,
          fileID: fileID,
          failLeadCount: failLeadCount
        };

        fetch("/deliverLeadsMarketo/getFailureLeadInfo", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((leadInfoArray) => {
            this.setState({ leadInfoArray: leadInfoArray });
          })
          .catch(function (err) {
            console.log(err);
          });
      } else { }
    }
  }

  //handel change for pagination
  handleChangePageSize2(e) {
    var pageSize2 = e.target.value;
    this.setState({ pageSize2: pageSize2 });
  }


  /**
 * @author Nilesh Zinzurao, Task: 4453
 * @param  Description Redeliver failure leads to Marketo
 */
  handleRedeliverLeads(e) {
    e.preventDefault();
    let selectedLeads = this.state.selectedLeads;
    //let key = this.state.key; //Rutuja Task-2978 commented as never used
    let fileID = this.state.fileID;

    if (selectedLeads.length > 0) {
      let loaderMessage = "CSV File is generating...Please do not Press Refresh or Back Button...";
      this.setState({ loaderMessage: loaderMessage });
      this.loadingRef.current.click();
      let data = {
        selectedLeads: selectedLeads,
        fileID: fileID
      };
      fetch("/generateCSV/redeliverLeadsToMarketo", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((response) => {
          this.closeLoadingRef.current.click();
          // alert(response);
          if (response.success === true) {
            Swal.fire({
              text: "CSV File generated Successfully",
              type: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                let marketoClient = this.state.marketoClient;
                let campID = this.state.campID;
                this.props.history.replace("/leadDeliverMarketo", { marketoClient: marketoClient, campID: campID });
              },
            });
          } else {
            Swal.fire({
              type: "error",
              title: "CSV File not generated",
              text: JSON.stringify(response),
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });

    }
    else {
      Swal.fire({
        type: "warning",
        title: "Please select at least one lead",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        preConfirm: () => {
          window.location.reload();
        },
      });
    }
  }

  /**
* @author Nilesh Zinzurao, Task: 4453
* @param  Description Reject failure leads for Marketo
*/
  handleRejectLeads(e) {
    e.preventDefault();
    let selectedLeads = this.state.selectedLeads;
    let fileID = this.state.fileID;
    if (selectedLeads.length > 0) {
      Swal.fire({
        title:
          "All Selected leads will gets Rejected. Do you want to continue?",
        type: "question",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        allowOutsideClick: false,
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          let loaderMessage = "Please Wait..."
          this.setState({ loaderMessage: loaderMessage });
          this.loadingRef.current.click();
          let data = {
            selectedLeads: selectedLeads,
            fileID: fileID
          };
          fetch("/generateCSV/rejectLeadsMarketo", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((response) => {
              this.closeLoadingRef.current.click();
              if (response.success === true) {
                Swal.fire({
                  text: "Leads rejected Successfully",
                  type: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  preConfirm: () => {
                    window.location.reload();
                  },
                });
              }
            })
            .catch(function (err) {
              console.log(err);
            });
        }
        else {
          window.location.reload();
        }
      });
    }
    else {
      Swal.fire({
        type: "warning",
        title: "Please select at least one lead",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        preConfirm: () => {
          window.location.reload();
        },
      });
    }

  }

  downloadFailureLeads(e) {
    let leadInfoArray = this.state.leadInfoArray;
    const { user } = this.props.auth;
    let marketoClientInfo = this.state.marketoClientInfo;
    //alert(JSON.stringify(marketoClientInfo))

    let clientID = marketoClientInfo[0].clientID;
    let clientName = marketoClientInfo[0].clientName;
    //alert(clientID+" "+clientName)

    var dt = dateTime.create();
    var date = dt.format('Y-m-d_H.M.S');

    let leadIDs = [];
    for (let i = 0; i < leadInfoArray.length; i++) {
      leadIDs.push(leadInfoArray[i].leadInfoID);
    }

    let data = { leadIDs: leadIDs };
    fetch("/deliverLeadsMarketo/failureLeadsDetails", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((leadDetails) => {
        if (leadDetails.length > 0) {
          var ws = xlsx.utils.json_to_sheet(leadDetails);
          var wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Lead");
          var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (let i = 0; i !== s.length; ++i)
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }
          fileName = this.state.campID + '_' + user.id + '_' + clientID + '_' + clientName + '_FailureLeads_' + leadDetails.length + 'L_' + date + '.xlsx';
          //fileName = "Test.xlsx";
          //alert("fileName-->"+fileName);

          saveAs(
            new Blob([s2ab(buf)], { type: "application/octet-stream" }),
            fileName
          );
        }
        else { }
      })
      .catch(function (err) {
        console.log(err);
      });
  }


  uploadCorrectedFailureLeads(e) {
    e.preventDefault();
   // var fileName; //Rutuja Task-2978 commented as never used
   // var file = []; //Rutuja Task-2978 commented as never used
    let fileerrors = {};
    e = document.createElement("input"); //creating a input element but not binding it to body added by sandeep
    e.setAttribute("type", "file"); //setting a attribute for type file added by sandeep
    e.setAttribute("multiple", true); //setting a attribute for multiple added by sandeep
    e.click(); //to open file dialougeadded by sandeep
    e.addEventListener(
      "change",
      function () {
        //calling an eventListener at change event added by sandeep
        let filesData = e.files[0];
        var extFileName = filesData.name;
       // var fileContents = filesData.data; //Rutuja Task-2978 commented as never used
        let leadFileExt = extFileName.split(".").pop();

        if (leadFileExt === "xlsx") {

        }
        else {
          fileerrors["fileextensions"] =
            "Please upload files having extension .xlsx only";
        }

        this.setState({
          fileerrors: fileerrors,
        });

      }.bind(this)
    );
  }





  render() {
    var parsed = this.props.location.state;//snehal-task-3940-VAPT URL changes
    this.state.campID = parsed.campID;
    this.state.fileID = parsed.fileID;
    const Column1 = [
      {
        title: 'Lead Info ID',
        dataIndex: 'leadInfoID',
        key: 'leadInfoID',
        width: '130px',
        align: 'center',
        render: (text, record) =>
          <div><span style={{ fontSize: '12px' }}>{record.leadInfoID}</span></div>
      },
      {
        title: 'Marketo ID',
        dataIndex: 'marketoID',
        key: 'marketoID',
        width: '100px',
        align: 'center',

        render: (text, record) =>
          <div><span style={{ fontSize: '12px' }}>{record.marketoID}</span></div>
      },

      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        width: '150px',
        render: (text, record) =>
          <div>
            <span style={{ fontSize: '12px' }}>{record.email}</span>
          </div>
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        align: 'center',
        width: '100px',
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.firstName}</span>
          </div>
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        align: 'center',
        width: '100px',
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.lastName}</span>
          </div>
      },
      {
        title: 'Job Title',
        dataIndex: 'jobTitle',
        key: 'jobTitle',
        align: 'center',
        width: '100px',

        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.jobTitle}</span>
          </div>
      },
      {
        title: ' Company Name',
        dataIndex: 'companyName',
        key: 'companyName',
        align: 'center',
        width: '150px',
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.companyName}</span>
          </div>
      },
      {
        title: 'Lead Status',
        dataIndex: 'marketoLeadStatus',
        key: 'marketoLeadStatus',
        align: 'center',
        width: '150px',
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.marketoLeadStatus}</span>
          </div>
      },
      {
        title: 'Batch ID',
        dataIndex: 'marketoBatchID',
        key: 'marketoBatchID',
        align: 'center',
        width: '100px',
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.marketoBatchID}</span>
          </div>
      },
      {
        title: 'Request ID',
        dataIndex: 'marketoRequestID',
        key: 'marketoRequestID',
        align: 'center',
        width: '100px',
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.marketoRequestID}</span>
          </div>
      },



    ];
    const Column2 = [
      {
        title: 'Lead Info ID',
        dataIndex: 'leadInfoID',
        key: 'leadInfoID',
        width: '130px',
        align: 'center',
        render: (text, record) =>
          <div><span>{record.leadInfoID}</span></div>
      },

      {
        title: ' Campaign Marketo Message',
        dataIndex: 'marketoLeadStatus',
        key: 'marketoLeadStatus',
        width: '350px',
        align: 'center',

        render: (text, record) =>
          <div><span style={{ fontSize: '12px' }}>{record.marketoLeadStatus}</span></div>
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        width: '150px',
        render: (text, record) =>
          <div>
            <span style={{ fontSize: '12px' }}>{record.email}</span>
          </div>
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        align: 'center',
        width: '100px',
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.firstName}</span>
          </div>
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        align: 'center',
        width: '100px',
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.lastName}</span>
          </div>
      },
      {
        title: 'Job Title',
        dataIndex: 'jobTitle',
        key: 'jobTitle',
        align: 'center',
        width: '100px',

        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.jobTitle}</span>
          </div>
      },
      {
        title: ' Company Name',
        dataIndex: 'companyName',
        key: 'companyName',
        align: 'center',
        width: '150px',
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '12px' }}>{record.companyName}</span>
          </div>
      },
      {
        title: 'Batch ID',
        dataIndex: 'marketoBatchID',
        key: 'marketoBatchID',
        width: '130px',
        align: 'center',
        render: (text, record) =>
          <div><span>{record.marketoBatchID}</span></div>
      },
      {
        title: 'Request ID',
        dataIndex: 'marketoRequestID',
        key: 'marketoRequestID',
        width: '130px',
        align: 'center',
        render: (text, record) =>
          <div><span>{record.marketoRequestID}</span></div>
      },


    ];

    //4453-Nilesh selects leads 
    const rowSelection = {
      onChange: (key, selectedRows) => {
        this.setState({ selectedLeads: selectedRows });
      },
      onSelection: this.onSelection,
      hideDefaultSelections: true,

      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };

    function onChange(pagination, filters, sorter) {
      console.log('params', pagination, filters, sorter);
    }
    return (
      <div>
        {/* Nilesh-4453-Added Loader */}
        <LeadReviewLoader
          loadingRef={this.loadingRef}
          closeLoadingRef={this.closeLoadingRef}
          message={this.state.loaderMessage}
        />
        <Navigation />
        <div class="container-fluid" style={{ paddingTop: '100px', marginBottom: '30px' }}>
          <div class="row">
            <div class="col-lg-1 col-md-1 col-sm-1" style={{ float: 'left', marginLeft: '10px' }}>
              {/* Rutuja 2978 added #/ in href */}
              < a class="col" href='#/' style={{ color: '#056eb8', right: '33%', marginLeft: '80px' }} onClick={this.marketoLeadViewBackButton.bind(this)} > 
                <FaArrowAltCircleLeft size={32} title="" className="backFrom_marketoLead" />
              </a>
            </div>
            <div class=" col-lg-10 col-md-10 col-sm-10" align="center">
              {/* //Rutuja Task-2978 changed != to !== */}
              {this.state.succLeadCount !== undefined ?
                <div class="col" align="center" style={{ fontSize: "20px", fontWeight: "bold" }}><label id="labelheading" style={{ color: '#056eb8' }} >
                  Success Leads of Campaign ID: &nbsp;{this.state.campID}</label></div>
                : this.state.failLeadCount !== undefined ? <div class="col" align="center" style={{ fontSize: "20px", fontWeight: "bold" }}><label id="labelheading" style={{ color: '#056eb8' }} >
                  Failure Leads of Campaign ID: &nbsp;{this.state.campID}</label></div>
                  : <div class="col" align="center" style={{ fontSize: "20px", fontWeight: "bold" }}><label id="labelheading" style={{ color: '#056eb8' }} >
                    Leads of campaignID: &nbsp;{this.state.campID}</label></div>}
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1"></div>
          </div>
        </div>



        <div className="card card-signin my-1">
          <div class="row">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="table-responsive">
              {/* //Rutuja Task-2978 changed != to !== */}
                {this.state.succLeadCount !== undefined ?
                  <Table
                    {...this.state.tableState}
                    id="myTable"
                    bordered
                    dataSource={this.state.leadInfoArray}
                    onChange={onChange}
                    columns={Column1}
                    class="ant-table-thead"
                    className={tableCSS}
                    scroll={{ y: 400, x: 1300, }}
                    pagination={{ pageSize: this.state.pageSize2 }}>
                  </Table>
                  :
                  <Table
                    {...this.state.tableState}
                    rowSelection={rowSelection}
                    id="myTable"
                    bordered
                    dataSource={this.state.leadInfoArray}
                    onChange={onChange}
                    columns={Column2}
                    class="ant-table-thead"
                    className={tableCSS}
                    scroll={{ y: 400, x: 1300, }}
                    pagination={{ pageSize: this.state.pageSize2 }}>
                  </Table>
                }
                <p>
                  <div class="row" style={{ marginBottom: '30px' }}>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{ marginTop: '-50px' }}>
                      &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                      <select
                        defaultValue={this.state.pageSize2}
                        onChange={this.handleChangePageSize2}
                        id="pacing1"
                        class="input-small"
                        className="form-control"
                        name="pacing" style={{ width: '80px', height: '30px', display: 'initial' }}>
                        <option value="100" selected>100</option>
                        <option value="150" >150</option>
                        <option value="200">200</option>
                        <option value="250">250</option>
                      </select>
                    </div>
                  </div>
                </p>

              </div>
            </div>

          </div>{/*Card-body */}
        </div>{/*Card */}

        {/*Nilesh-4453-Added two buttons */}
        {/* //Rutuja Task-2978 changed != to !== */}
        {this.state.failLeadCount !== undefined ?
          <div
            class="col-lg-12 col-md-6 col-sm-6"
            style={{ paddingTop: "5px" }}>
            <div class="row">
              <div class="col" style={{ display: "flex" }}>
                <div class="col-lg-1 col-md-1 col-sm-1">
                  <button
                    type="button"
                    class="btn add-button"
                    onClick={this.handleRedeliverLeads}>
                    Redeliver Leads
                  </button>
                </div>
                <div class="col-lg-11 col-md-11 col-sm-11" style={{ marginLeft: "5%", fontSize: "15px", fontWeight: "bold" }}>

                  <label id="label">Selected Leads will be redelivered.</label>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col" style={{ display: "flex" }}>
                <div class="col-lg-1 col-md-1 col-sm-1">
                  <button
                    type="button"
                    class="btn add-button"
                    onClick={this.handleRejectLeads}>
                    Reject Leads
                  </button>
                </div>
                <div class="col-lg-11 col-md-11 col-sm-11" style={{ marginLeft: "5%", fontSize: "15px", fontWeight: "bold" }}>

                  <label id="label">Selected Leads will gets rejected. To deliver, upload those leads again.</label>

                </div>
              </div>
            </div>
          </div>
          : ""}

        <Footer />
      </div>
    );
  }// end of renders
}// end of class

/**
       * @author Priyanka Patil
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
leadViewMarketo.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(leadViewMarketo));

