/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file Publisher details review
 * @author Sanobar Golandaj
 * @version 1.0
 * @section publisher details review
 */
import React, { Component } from "react";
// import { Table } from "mdbreact"; //kiran-4747-removing console warning-no used
import Navigation from "../layouts/navPage";

import Footer from "../layouts/footer";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3724-vAPT URL changes
// const queryString = require("query-string");//kiran-4747-removing console warning-no used
const Swal = require("sweetalert2");

class ReviewAgencyByAdvertiser extends Component {
	constructor() {
		super();
		this.state = {
			pID: "",
			agencyReviewDetails: [],
		};
		this.handleBackButtonToDashboard = this.handleBackButtonToDashboard.bind(
			this
		);
		this.submitLink = this.submitLink.bind(this);
	} //end of constructor
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			const {  user } = this.props.auth;
			var advertiserID = user.id;
			this.setState({ advertiserID: advertiserID });
			//snehal-task-3724-vAPT URL changes
			if(this.props.location.state!==undefined){
				var agencyID = this.props.location.state.agencyID;
				}
			let data = {
				agencyID: agencyID,
			};
			fetch("/dashboardAdvertiser/agencyReviewDetails", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((agencyReviewDetails) => {
					console.log("Return with response");
					this.setState({ agencyReviewDetails: agencyReviewDetails });
				});
		}
	}
	handleBackButtonToDashboard(e) {
		e.preventDefault();
		this.props.history.push("/advertiserDashboard",{advertiserID:this.state.advertiserID})//snehal-task-3768-vAPT URL changes
	}

	submitLink(e) {
		const {  user } = this.props.auth;
		// var advertiserID = user.id;//kiran-4747-removing console warning-no used
		//snehal-task-3724-vAPT URL changes
		if(this.props.location.state!==undefined){
			var agencyID = this.props.location.state.agencyID;
			}

		let data = {
			agencyID: agencyID,
		};

		fetch("/dashboardAdvertiser/agencyLinkApprove", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				console.log("Return with response");
				Swal.fire({
					text: "Agency Approved Successfully.",
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
					preConfirm: () => {
							this.props.history.push("/advertiserDashboard",{advertiserID:user.id})//snehal-task-3768-vAPT URL changes
					},
				});
			});
	}

	render() {
		return (
			<div>
				<Navigation />
				<div class="container-fluid" style={{ paddingTop: "90px" }}>
					<div class="row">
						<div class="col-lg-4 col-md-4" style={{ paddingLeft: "20px" }}>
						{/* kiran-4747-removing console warning-added "#/" */}
							<a
								href="#/"
								onClick={this.handleBackButtonToDashboard}
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back to Dashboard"
								/>
							</a>
						</div>

						<div class="col-lg-4 col-md-4">
							{/* shivani-3310-passed ID "labelDI" for label consistency */}
							{/* shivani-3319-added margin to display label in center */}
							<label
								id="labelDI" 						
								>
								{" "}
								Agency details
							</label>
						</div>

						<div class="col-lg-4 col-md-4">
							<button
								type="button"
								class="btn add-button"
								style={{ float: "right" }}
								onClick={this.submitLink}>
								Approve
							</button>
						</div>
					</div>
					<br />
						{/* rutuja task - 4325 made changes to make table responsive */}
					<div style={{ border: "1px solid #cdcdcd" }} class="table-responsive">
						
						{this.state.agencyReviewDetails.map((agencyReviewDetails) => (
							<div class="row">
								<div class="col-lg-6">
									<table>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Agency Name:</label>
											</td>
											<td>
												<span> {agencyReviewDetails.agencyName}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Country:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.country}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;City:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.city}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Country Code:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.countryCode}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Website:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.website}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">
													&nbsp;Non-disclosure agreement signed:
												</label>
											</td>
											<td>
												<span>{agencyReviewDetails.NDA}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">
													&nbsp;Insertion order required for each campaign:
												</label>
											</td>
											<td>
												<span>{agencyReviewDetails.IO}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Payment terms:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.payment_terms}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Telemarketing:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.dcTelemarketing}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Programmatic:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.dcProgrammatic}</span>
											</td>
										</tr>
										{/* User Story : 3188 : Karan Jagtap : Sub-Contracting display value */}
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Sub-Contracting:</label>
											</td>
											<td>
												<span>
													{agencyReviewDetails.subContracting === undefined ||
													agencyReviewDetails.subContracting === null ||
													agencyReviewDetails.subContracting === "undefined" ||
													agencyReviewDetails.subContracting === "null" ||
													agencyReviewDetails.subContracting === ""
														? ""
														: agencyReviewDetails.subContracting}
												</span>
											</td>
										</tr>
									</table>
								</div>

								<div class="col-lg-6">
									<table>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Email:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.email}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;State:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.state}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Zip code:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.zipcode}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Phone no:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.phone}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">
													&nbsp;Master service agreement signed:
												</label>
											</td>
											<td>
												<span>{agencyReviewDetails.MSA}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">
													&nbsp;Revenue model defined/agreed:
												</label>
											</td>
											<td>
												<span>{agencyReviewDetails.RMD}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Revenue model:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.revenue_model}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Email:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.dcEmail}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Display:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.dcDisplay}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Social:</label>
											</td>
											<td>
												<span>{agencyReviewDetails.dcSocial}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
						))}
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
ReviewAgencyByAdvertiser.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(ReviewAgencyByAdvertiser)
);
