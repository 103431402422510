/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sandeep Dhawale 
@file Name:createCampaignHelp.js
@Description: UI for createCampaignHelp
*/

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../login/authentication";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import { Button, Badge } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import "./publisherAllocationHelp.css"; //Add css File By mufiz-Bug 3910

// const Swal = require('sweetalert2');
// const queryString = require('query-string');
// var region;
class createCampaignHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  } //end of state
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      console.log("Log");
    }
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    return (
      <div>
        <Navigation />
        <div className="container-fluid " style={{ paddingTop: "115px" }}>
          <div className="text-right">
            {" "}
            <button
              style={{
                border: "2px solid #14254A",
                borderRadius: "40px",
                width: "13rem",
                height: "4rem",
                backgroundColor: "white",
              }}
              type="button"
              class="btn-btn-default"
            >
              <span style={{ color: "#14254A", fontWeight: "bold" }}>
                <a href="helpOption" style={{ color: "#000000" }}>
                  Help Main Option
                </a>
              </span>
            </button>
          </div>
          <p
          class="createCampvdo"
            style={{
              color: "#14254A",
              opacity: "75%",
              fontWeight: "bold",
            }}
          >
            Campaign-->Create New Campaign
          </p>
          <hr />
          <h3 class="createCampvdo" style={{ color: " #14254A",}}>
            Create New Campaign
          </h3>
          <div class="container">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                style={{ border: "1px solid lightgrey" }}
                class="embed-responsive-item"
                src="create.mp4"
              />
            </div>
          </div>
          <br />
          <h4
         class="createCampvdo" //mufiz Task 3910
            style={{
              color: " #14254A",
              marginTop: "10px",
            }}
          >
            Create New Campaign step1
          </h4>
          <p class="createCamp" style={{ marginTop: "10px" }}>
            1.To Create a new Campaign,we need to go &nbsp;
            <span style={{ color: "#14254A", fontWeight: "bold" }}>
              Create Campaign->Create New Campaign
            </span>
            .
          </p>
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
              
              border: "1px solid grey",
            }}
            src="createcamp.png"
            width="80%"
            height="500px"
          />

          <p class="createCamp" style={{ marginTop: "10px"}}>
            2.Then you see Create Campaign Welcome screen as shown below. It is
            two step form.Now click on Let's Create Campaign to create campaign
          </p>
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
        
              border: "1px solid grey",
            }}
            src="welcometocreatecamp.png"
            width="80%"
            height="500px"
          />
          <p class="createCamp" style={{ marginTop: "10px" }}>
            <span style={{ fontWeight: "bold" }}>Campaign details: -</span>This
            section divide into four parts.
          </p>
          <p class="createCamp" >     {/* class="createCamp" added by mufiz //mufiz Task 3910 */}
            A. Basic details&nbsp; B. Delivery Option&nbsp; C. Campaign
            Specification&nbsp; D. Custom Questions
          </p>

          <p class="createCamp" 
            style={{ marginTop: "10px", color: "#14254A" }}
          >
            <span style={{ fontWeight: "bold" }}>A. Basic details: - </span>{" "}
            This field contain basic detail of campaign like Campaign name,
            Client name, Start date, End Date, Etc.
          </p>
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
              
              border: "1px solid grey",
            }}
            src="basicdetail.png"
            width="80%"
            height="500px"
          />

          <p class="createCamp"
            style={{ marginTop: "10px", color: "#14254A" }}
          >
            <span style={{ fontWeight: "bold" }}>B. Delivery Option :- </span>{" "}
            This field contain Pacing details about campaign.
          </p>
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
              
              border: "1px solid grey",
            }}
            src="deliveryoption1.png"
            width="80%"
            height="500px"
          />
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
              
              border: "1px solid grey",
            }}
            src="deliveryoption2.png"
            width="80%"
            height="500px"
          />
          <p
          class="createCamp"
            style={{ marginTop: "10px", color: "#14254A" }}
          >
            <span style={{ fontWeight: "bold" }}>
              C. Campaign Specification:-{" "}
            </span>{" "}
            This field contain detail specification of campaign like Region and
            Countries, Employee size, Industry, Job Title, Etc..
          </p>
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
            
              border: "1px solid grey",
            }}
            src="campspecification1.png"
            width="80%"
            height="500px"
          />
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
             
              border: "1px solid grey",
            }}
            src="campspecification2.png"
            width="80%"
            height="500px"
          />
          <p
          class="createCamp"
            style={{ marginTop: "10px",  color: "#14254A" }}
          >
            <span style={{ fontWeight: "bold" }}>D. Custom Question:- </span>{" "}
            This section contain addition questions related to campaign.Enter
            Custom Questions and Answers then click on{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>
              {" "}
              ‘SAVE AND NEXT’
            </span>{" "}
            option.
            <br />
            Otherwise click on{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>
              {" "}
              ‘SKIP AND NEXT’
            </span>
          </p>
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
              
              border: "1px solid grey",
            }}
            src="customquestion.png"
            width="80%"
            height="500px"
          />
          <h4
           class="createCamp"
            style={{
              color: " #14254A",
             
              marginTop: "10px",
            }}
          >
            Create New Campaign step2
          </h4>
          <p
           class="createCamp"
            style={{ marginTop: "10px",  color: "#14254A" }}
          >
            <span style={{ fontWeight: "bold" }}> Supporting documents: </span>{" "}
          </p>
          <p class="createCamp" >
          
            This section divide into Three parts.
          </p>
          <p  class="createCamp">
              
            A.Delivery Format&nbsp; B. Upload Supporting Documents&nbsp; C.
            Upload Campaign Assets
          </p>
          <p  class="createCamp" style={{ color: "#14254A" }}>
            <span style={{ fontWeight: "bold" }}>A) Delivery format </span>{" "}
          </p>
          <p   class="createCamp">
            {" "}
            -Select required fields on page using check boxes.{" "}
          </p>
          <p  class="createCamp">
            -To Travel back to campaign detail page, click on{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>‘BACK’</span>{" "}
            button.{" "}
          </p>
          <img
            style={{
              marginTop: "10px",
              display:"Block",
              border: "1px solid grey",
              width:"80%"
            }}
            src="deliveryformat.png"
            
          />

          <p class="createCamp"
            style={{ marginTop: "10px",  color: "#14254A" }}
          >
            <span style={{ fontWeight: "bold" }}>
              B) Upload Supporting Document:
            </span>
          </p>
          <p  class="createCamp" style={{ marginTop: "10px"}}>
            -Choose and upload
            <span style={{ color: "black", fontWeight: "bold" }}>
              {" "}
              ABM, Suppression, Exclusion and other
            </span>{" "}
            files
          </p>
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
            
              border: "1px solid grey",
            }}
            src="uploadsupportingdoc.png"
            width="80%"
            height="500px"
          />

          <p  class="createCamp"
            style={{ marginTop: "10px", color: "#14254A" }}
          >
            <span style={{ fontWeight: "bold" }}>
              C) Uploading Campaign Assets
            </span>
          </p>
          <ul class="createCamp" style={{ listStyleType: "circle" }}>
            <li style={{ marginTop: "10px"}}>
              {" "}
              Upload Asset files or Asset Link, and after successful upload give
              name to respective asset.
            </li>
            <li style={{ marginTop: "10px",  }}>
              Can choose the option
              <span style={{ color: "black", fontWeight: "bold" }}>
                ” asset wise leads required”
              </span>{" "}
              so, can put the leads percentage.
            </li>
            <li style={{ marginTop: "10px" }}>
              Can also choose the option{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                ” Required Multitouch Campaign”
              </span>{" "}
              so, can put the touch Asset wise.
            </li>
            <li style={{ marginTop: "10px"}}>
              After uploading all files click on
              <span style={{ color: "black", fontWeight: "bold" }}>
                {" "}
                ‘save and next’
              </span>{" "}
              option.
            </li>
          </ul>
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
           
              border: "1px solid grey",
            }}
            src="uploadcampasset.png"
            width="80%"
            height="500px"
          />
          <p class="createCamp"
            style={{ marginTop: "10px", color: "#14254A" }}
          >
            <span style={{ fontWeight: "bold" }}>
              iii) Campaign Created successfully:
            </span>
          </p>
          <p class="createCamp" style={{ marginTop: "10px" }}>
            -Shows campaign’s basic details and message that
            <span style={{ color: "black", fontWeight: "bold" }}>
              {" "}
              “Campaign created successfully with Campaign ID”.
            </span>{" "}
          </p>
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
             
              border: "1px solid grey",
            }}
            src="campcreatedsuccessfully.png"
            width="80%"
            height="500px"
          />
          <hr style={{ border: "1px solid grey" }} />
          <div class="row" id="roww">
            <h3
              style={{
                marginTop: "10px",
               
                color: " #14254A",
              }}
            >
              Was this artical helpful?
            </h3>{" "}
            <button
              style={{
                border: "2px solid #14254A",
                borderRadius: "40px",
                width: "100px",
             
                backgroundColor: "white",
              }}
              type="button"
              class="btn-btn-default"
            >
              <span style={{ color: "#14254A", fontWeight: "bold" }}>Yes</span>
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              style={{
                border: "2px solid #14254A",
                borderRadius: "40px",
                width: "100px",
                backgroundColor: "white",
              }}
              type="button"
              className="btn-btn-default"
            >
              <span style={{ color: "#14254A", fontWeight: "bold" }}>No</span>
            </button>
          </div>
          <hr style={{ border: "1px solid grey" }} />
          <div className="text-center">
            {" "}
            <button
              style={{
                border: "2px solid #14254A ",
                borderRadius: "40px",
                width: "14rem",
                height: "5rem",
                backgroundColor: "white",
              }}
              type="button"
              class="btn-btn-default"
            >
              <span style={{ color: "#14254A", fontWeight: "bold" }}>
                <a href="helpOption" style={{ color: "#000000"}}>
                  Help Main Option
                </a>
              </span>
            </button>
          </div>
        </div>
        <br />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
} //end of class
/**
 * @author Sandeep Dhawale
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
createCampaignHelp.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(createCampaignHelp));
