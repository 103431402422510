/*@author Sanobar Golandaj
*@fileName :createCampaign.js
 *Desc: Creating Campaign Step1 Page 
*/


import React from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import * as $ from "jquery";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { MDBRow, MDBCol, MDBBtn, Iframe } from "mdbreact";
import Footer from '../layouts/footer';
import Navigation from '../layouts/navPage';
import './loader.css';
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import { Tooltip,Icon,Input,Form, Row, Col, Button,Tag,message} from 'antd';
import "./createCampaign.css";

//  import file from './components/campaign/file.js'; 
const queryString = require('query-string');
    
    

const Swal = require('sweetalert2')
const { TextArea } = Input;
var industryFileLength;
var names=[];

var joblevelEmpty=true;
var jobfunctionEmpty=true;
var empsizeEmpty=true;
var revenueEmpty=true;
var industryEmpty=true;

var joblevelEmptyBack=true;
var jobfunctionEmptyBack=true;
var empsizeEmptyBack=true;
var revenueEmptyBack=true;
var industryEmptyBack=true;
// var customEmpTxtLength;
// var regionCountryInfo = file.data;
// var jobLevelvalue=[];
// var employeeSizevalue=[];
// var countryvalue=[];
var creativeArray=[];
var creativeValueArray=[];
var countryvalue=[];
// var jobFunctionvalue=[];
//  var filenames=[];
var creativeArrayLength;
var zipfilelength;
var zipfilelengthback;
// var zipFileLengthBack;
// var stateFileLengthBack;
// var cityFileLengthBack;
var cityfilelength;
var cityfilelengthback;
var statefilelength;
var empPopLength;
var statefilelengthback;
var revenueLength;
var newjobfunctionbackarray=[];
var jobfunctionarray=[];
var count=1;
var custIndustry;
var indstry;
var custempSize;
var empSize;
let timeZoneValidate=true;
let leadAllocationValidate=true;
let  employeeSizeValidate=false;
let industryValidate=false;



const creatives=[ { id: 'Newsletter', name: 'Newsletter' },
{ id: 'Landing Page', name: 'Landing Page' },
{ id: 'Call Script', name: 'Call Script' },
];


const employeeSizeOption=[
 
  { id: '1-50', name: '1-50' },
  { id: '51-100', name: '51-100' },
  { id: '101-200', name: '101-200' },
  { id: '201-500', name: '201-500' },
  { id: '501-1000', name: '501-1000' },
  { id: '1001-5000',name:'1001-5000'},
  { id: '5001-10000', name: '5001-10000' },
  { id: '10001+', name: '10001+' },

];

const companyRevenueOption=[
 
  { id: '<$1M',name: '<$1M' },
  { id: '$1M - $5M', name: '$1M - $5M' },
  { id: '$5M - $10M', name: '$5M - $10M' },
  { id: '$10M - $25M', name: '$10M - $25M' },
  { id: '$25M - $50M', name: '$25M - $50M' },
  { id: '$50M - $100M', name: '$50M - $100M' },
  { id: '$100M - $250M', name: '$100M - $250M' },
  { id: '$250M - $500M', name: '$250M - $500M' },
  { id: '$500M - $1B', name: '$500M - $1B' },
  { id: '$1B - $5B', name: '$1B - $5B' },
  { id: '$5B - $10B', name: '$5B - $10B' },
  { id: '>$10B', name: '>$10B' },
];
const bigList=[
  { id: 'C-Level', name: 'C-Level' },
  { id: 'Director', name: 'Director' },
  { id: 'Head', name: 'Head' },
  { id: 'Manager', name: 'Manager' },
  { id: 'Staff', name: 'Staff' },
  { id: 'VP', name: 'VP' },
];
const jobLevelOptions = [
  { value: 'C-Level', label: 'C-Level' },
  { value: 'Director', label: 'Director' },
  { value: 'Head', label: 'Head' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Staff', label: 'Staff' },
  { value: 'VP', label: 'VP' }

];

const jobFunctionOptions = [
  { id: 'Business', name: 'Business' },
  { id: 'Chain Supply', name: 'Chain Supply' },
  { id: 'Customer', name: 'Customer' },
  { id: 'Executive', name: 'Executive' },
  { id: 'Finance & Admin', name: 'Finance & Admin' },
  { id: 'HR', name: 'HR' },
  { id: 'IT', name: 'IT' },
  { id: 'IT Security', name: 'IT Security' },
  { id: 'Legal', name: 'Legal' },
  { id: 'Marketing', name: 'Marketing' },
  { id: 'Network', name: 'Network' },
  { id: 'Operation', name: 'Operation' },
  { id: 'Other', name: 'Other' },
  { id: 'Procurement', name: 'Procurement' },
  { id: 'Product', name: 'Product' },
  { id: 'Sales', name: 'Sales' },
  { id: 'Security & Compliance', name: 'Security & Compliance' },
  { id: 'Support', name: 'Support' }
]
const regionoptions=[

  { id: 'Africa', name: 'Africa' },
  { id: 'Asia', name: 'Asia' },
  {id:'Antarctic',name:'Antarctic'},
  { id: 'Countries of Australia  and Oceania(Island)', name: 'Countries of Australia  and Oceania(Island)'},
  { id: 'Europe', name: 'Europe' },
  { id: 'Indian Ocean', name: 'Indian Ocean' },
  { id: 'North America', name: 'North America' },
  { id: 'South America', name: 'South America' },
  { id: 'Southeast Africa', name: 'Southeast Africa' },
  { id: 'Southeastern Europe', name: 'Southeastern Europe' },
   { id: 'Southern Atlantic Ocean', name: 'Southern Atlantic Ocean' },


]


const industryOptions = [
  { id: 'Accommodation and Food Services', name: 'Accommodation and Food Services' },
  { id: 'Administrative and Support and Waste Management and Remediation Services', name: 'Administrative and Support and Waste Management and Remediation Services' },
  { id: 'Advertising and Printing & Publishing', name: 'Advertising and Printing & Publishing' },
  { id: 'Aerospace & Defense', name: 'Aerospace & Defense' },
  { id: 'Accounting', name: 'Accounting' },
  { id: 'Agriculture,Forestry,Fishing and Hunting', name: 'Agriculture,Forestry,Fishing and Hunting' },
  { id: 'Apparel & Fashion', name: 'Apparel & Fashion' },
  { id: 'Arts,Entertainment and Recreation', name: 'Arts,Entertainment and Recreation' },
  { id: 'Automotive', name: 'Automotive' },
  { id: 'Business Services', name: 'Business Services' },
  { id: 'Construction', name: 'Construction' },
  { id: 'Engineering and Construction', name: 'Engineering and Construction' },
  { id: 'Education & Training', name: 'Education & Training' },
  { id: 'Energy & Utilities', name: 'Energy & Utilities' },
  { id: 'Financial Services & Banking', name: 'Financial Services & Banking' },
  // { id: 'Finance and Insurance', name: 'Finance and Insurance' },
  { id: 'Food & Beverage', name: 'Food & Beverage' },
  { id: 'Government and Public Administration', name: 'Government and Public Administration' },
  // { id: 'Health Care and Social Assistance', name: 'Health Care and Social Assistance' },
  { id: 'Healthcare', name: 'Healthcare' },
  { id: 'Hospitality & Travel', name: 'Hospitality & Travel' },
  // { id: 'Information Technology & Services', name: 'Information Technology & Services'},
  { id: 'Information', name: 'Information'},
  { id: 'Insurance', name: 'Insurance' },
  { id: 'Legal Solutions', name: 'Legal Solutions' },
  { id: 'Management of Companies and Enterprises', name: 'Management of Companies and Enterprises' },
  { id: 'Manufacturing', name: 'Manufacturing' },
  { id: 'Marketing', name: 'Marketing' },
  { id: 'Media & Entertainment', name: 'Media & Entertainment' },
  { id: 'Medical Devices & Equipment', name: 'Medical Devices & Equipment' },
  { id: 'Mining', name: 'Mining' },
  // { id: 'Mining, Quarrying, and Oil and Gas Extraction', name: 'Mining, Quarrying, and Oil and Gas Extraction' },
  { id: 'Not-for-Profit', name: 'Not-for-Profit' },
  { id: 'Pharmaceuticals & Biotech', name: 'Pharmaceuticals & Biotech' },
  { id: 'Professional and Scientific & Technical Services', name: 'Professional and Scientific & Technical Services' },
  { id: 'Real Estate', name: 'Real Estate' },
  // { id: 'Real Estate and Rental and Leasing', name: 'Real Estate and Rental and Leasing' },
  { id: 'Recreation', name: 'Recreation' },
  // { id: 'Retail Trade', name: 'Retail Trade' },
  { id: 'Retail', name: 'Retail' },
  { id: 'Software', name: 'Software' },
  { id: 'Telecommunications', name: 'Telecommunications' },
  { id: 'Transportation & Logistics', name: 'Transportation & Logistics' },
  // { id: 'Transportation and Warehousing', name: 'Transportation and Warehousing' },
  { id: 'Utilities', name: 'Utilities' },
  { id: 'Venture Capital & Private Equity', name: 'Venture Capital & Private Equity' },
  { id: 'Wholesale & Distribution', name: 'Wholesale & Distribution' },
  // { id: 'Wholesale Trade', name: 'Wholesale Trade' },
  { id: 'Consumer Goods & Services', name: 'Consumer Goods & Services' },
  { id: 'Scientific & Technical Service', name: 'Scientific & Technical Service' },
  { id: 'Other', name: 'Other' }
];
const TimeZoneOption=[

  { id: '(GMT-12:00) International Date Line West', name: '(GMT-12:00) International Date Line West' },
  { id: '(GMT-11:00) Midway Island, Samoa', name: '(GMT-11:00) Midway Island, Samoa' },
  { id: '(GMT-10:00) Hawaii', name: '(GMT-10:00) Hawaii' },
  { id: '(GMT-09:00) Alaska', name: 'Supp(GMT-09:00) Alaskaort' },
  { id: '(GMT-08:00) Pacific Time (US & Canada)', name: '(GMT-08:00) Pacific Time (US & Canada)' },
  { id: '(GMT-08:00) Tijuana, Baja California', name: '(GMT-08:00) Tijuana, Baja California' },
  { id: '(GMT-07:00) Arizona', name: '(GMT-07:00) Arizona' },
  { id: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', name: '(GMT-07:00) Chihuahua, La Paz, Mazatlan' },
  { id: '(GMT-07:00) Mountain Time (US & Canada)pport', name: '(GMT-07:00) Mountain Time (US & Canada)' },
  { id: '(GMT-06:00) Central America', name: '(GMT-06:00) Central America' },
  { id: '(GMT-06:00) Guadalajara, Mexico City, Monterrey', name: '(GMT-06:00) Guadalajara, Mexico City, Monterrey' },
  { id: '(GMT-06:00) Central Time (US & Canada)(GMT-06:00) Central Time (US & Canada)', name: '(GMT-06:00) Central Time (US & Canada)' },
  { id: '(GMT-06:00) Saskatchewan', name: '(GMT-06:00) Saskatchewan' },
  { id: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', name: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco' },
  { id: '(GMT-05:00) Eastern Time (US & Canada)', name: '(GMT-05:00) Eastern Time (US & Canada)' },
  { id: '(GMT-05:00) Indiana (East)', name: '(GMT-05:00) Indiana (East)' },
  { id: '(GMT-04:00) Atlantic Time (Canada)', name: '(GMT-04:00) Atlantic Time (Canada)' },
  { id: '(GMT-04:00) Caracas, La Paz', name: '(GMT-04:00) Caracas, La Paz' },
  { id: '(GMT-04:00) Manaus', name: '(GMT-04:00) Manaus' },
  { id: '(GMT-04:00) Santiago', name: '(GMT-04:00) Santiago' },
  { id: '(GMT-03:30) Newfoundland', name: '(GMT-03:30) Newfoundland' },
  { id: '(GMT-03:00) Brasilia', name: '(GMT-03:00) Brasilia' },
  { id: '(GMT-03:00) Buenos Aires, Georgetown', name: '(GMT-03:00) Buenos Aires, Georgetown' },
  { id: '(GMT-03:00) Greenland', name: '(GMT-03:00) Greenland' },
  { id: '(GMT-03:00) Montevideo', name: '(GMT-03:00) Montevideo' },
  { id: '(GMT-02:00) Mid-Atlantic', name: '(GMT-02:00) Mid-Atlantic' },
  { id: '(GMT-01:00) Cape Verde Is', name: '(GMT-01:00) Cape Verde Is' },
  { id: '(GMT-01:00) Azores', name: '(GMT-01:00) Azores' },
  { id: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', name: '(GMT+00:00) Casablanca, Monrovia, Reykjavik' },
  { id: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London', name: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London' },
  { id: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', name: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
  { id: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', name: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' },
  { id: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', name: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris' },
  { id: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', name: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
  { id: '(GMT+01:00) West Central Africa', name: '(GMT+01:00) West Central Africa' },
  { id: '(GMT+02:00) Amman', name: '(GMT+02:00) Amman' },
  { id: '(GMT+02:00) Athens, Bucharest, Istanbul', name: '(GMT+02:00) Athens, Bucharest, Istanbul' },
  { id: '(GMT+02:00) Beirut', name: '(GMT+02:00) Beirut' },
  { id: '(GMT+02:00) Cairo', name: '(GMT+02:00) Cairo' },
  { id: '(GMT+02:00) Harare, Pretoria', name: '(GMT+02:00) Harare, Pretoria' },
  { id: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' },
  { id: '(GMT+02:00) Jerusalem', name: '(GMT+02:00) Jerusalem' },
  { id: '(GMT+02:00) Minsk', name: '(GMT+02:00) Minsk' },
  { id: '(GMT+02:00) Windhoek', name: '(GMT+02:00) Windhoek' },
  { id: '(GMT+03:00) Kuwait, Riyadh, Baghdad', name: '(GMT+03:00) Kuwait, Riyadh, Baghdad' },
  { id: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', name: '(GMT+03:00) Moscow, St. Petersburg, Volgograd' },
  { id: '(GMT+03:00) Nairobi', name: '(GMT+03:00) Nairobi' },
  { id: '(GMT+03:00) Tbilisi', name: '(GMT+03:00) Tbilisi' },
  { id: '(GMT+03:30) Tehran', name: '(GMT+03:30) Tehran' },
  { id: '(GMT+04:00) Abu Dhabi, Muscat', name: '(GMT+04:00) Abu Dhabi, Muscat' },
  { id: '(GMT+04:00) Baku', name: '(GMT+04:00) Baku' },
  { id: '(GMT+04:00) Yerevan', name: '(GMT+04:00) Yerevan' },
  { id: '(GMT+04:30) Kabul', name: '(GMT+04:30) Kabul' },
  { id: '(GMT+05:00) Yekaterinburg', name: '(GMT+05:00) Yekaterinburg' },
  { id: '(GMT+05:00) Islamabad, Karachi, Tashkent', name: '(GMT+05:00) Islamabad, Karachi, Tashkent' },
  { id: '(GMT+05:30) Sri Jayawardenapura', name: '(GMT+05:30) Sri Jayawardenapura' },
  { id: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', name: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
  { id: '(GMT+05:45) Kathmandu', name: '(GMT+05:45) Kathmandu' },
  { id: '(GMT+06:00) Almaty, Novosibirsk', name: '(GMT+06:00) Almaty, Novosibirsk' },
  { id: '(GMT+06:00) Astana, Dhaka', name: '(GMT+06:00) Astana, Dhaka' },
  { id: '(GMT+06:30) Yangon (Rangoon)', name: '(GMT+06:30) Yangon (Rangoon)' },
  { id: '(GMT+07:00) Bangkok, Hanoi, Jakarta', name: '(GMT+07:00) Bangkok, Hanoi, Jakarta' },
  { id: '(GMT+07:00) Krasnoyarsk', name: '(GMT+07:00) Krasnoyarsk' },
  { id: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', name: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi' },
  { id: '(GMT+08:00) Kuala Lumpur, Singapore', name: '(GMT+08:00) Kuala Lumpur, Singapore' },
  { id: '(GMT+08:00) Irkutsk, Ulaan Bataar', name: '(GMT+08:00) Irkutsk, Ulaan Bataar' },
  { id: '(GMT+08:00) Perth', name: '(GMT+08:00) Perth' },
  { id: '(GMT+08:00) Taipei', name: '(GMT+08:00) Taipei' },
  { id: '(GMT+09:00) Osaka, Sapporo, Tokyo', name: '(GMT+09:00) Osaka, Sapporo, Tokyo' },
  { id: '(GMT+09:00) Seoul', name: '(GMT+09:00) Seoul' },
  { id: '(GMT+09:00) Yakutsk', name: '(GMT+09:00) Yakutsk' },
  { id: '(GMT+09:30) Adelaide', name: '(GMT+09:30) Adelaide' },
  { id: '(GMT+09:30) Darwin', name: '(GMT+09:30) Darwin' },
  { id: '(GMT+10:00) Brisbane', name: '(GMT+10:00) Brisbane' },
  { id: '(GMT+10:00) Canberra, Melbourne, Sydney', name: '(GMT+10:00) Canberra, Melbourne, Sydney' },
  { id: '(GMT+10:00) Hobart', name: '(GMT+10:00) Hobart' },
  { id: '(GMT+10:00) Guam, Port Moresby', name: '(GMT+10:00) Guam, Port Moresby' },
  { id: '(GMT+10:00) Vladivostok', name: '(GMT+10:00) Vladivostok' },
  { id: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia' },
  { id: '(GMT+12:00) Auckland, Wellington', name: '(GMT+12:00) Auckland, Wellington' },
  { id: '(GMT+12:00) Fiji, Kamchatka, Marshall Is', name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is' },
  { id: '(GMT+13:00) Nuku alofa', name: 'Supp(GMT+13:00) Nuku alofaort' },
 
]
var timelineArray=[];
var jobLevelArray=[];
var customindustryArray=[];
var countryArray=[];
var regionArray=[];
var empSizeArray = [];
var customEmpSizeArrayFrom = [];
var customEmpSizeArrayTo = [];
var customEmpSizeArrayTextBack = [];
var customCompanyRevenueArrayText=[];
var customQuestionArray = [];
var customAnswerArray = [];
var compRevenueArray =[];
var customRevenueArrayFrom =[];
var customRevenueArrayTo =[];
var jobFunctionArray = [];
var industryArray=[];
var timeZoneArray=[];
var customJobFunctionArray = [];
var customJobLevelArray =[];
var value = [];
var monthArray= ["January","February","March","April","May","June","July",
            "August","September","October","November","December"];

class CreateCampaign extends React.Component {
  constructor() {
    super();
    this.state = {
      pacingCarryForward:"No",
      zipFileLengthBack:'',
      cityFileLengthBack:'',
      stateFileLengthBack:'',
      industryNAValue:'',
      empSizeNAValue:'',
      industryNAValueBack:'',
      empSizeNAValueBack:'',
      flagJobFunctionState:"",
      flagJobLevelState:"",
      revenueflagFromToState:"",
      revenueflagTextState:"",
      flagFromToState:false,
      flagTextState:false,
      creativevalue: null,
      creativearrayValue: [],
    jobTitleChk:"",
      count:'',
      creatives:"",
      creatives:[],
      CreativeValue:[],
      dynamicCustomQuestion:[0],
      //dynamicCompanyRevenue:[0],
     // dynamicEmployeeSize:[{From:"",To:""}],
      //dynamicEmployeeSizetxt:[0],
      //dynamicCompanyRevenuetxt:[0],
      dynamicjobFunction:[0],
      dynamicpacing:[],
     
      expand: false,
      value: null,
      arrayValue: [],
      campaignDetail: [],
      questionDetail: [],
      fields: {
        campID:'',
        username: {
          value: 'benjycui',
        },
      },
      multi: {},
      insertionOrder: 'Yes',
      campaignStatus: 'Active',
      pacing: 'Monthly',
      marketingChannel: 'Email',
      campaignReportingDay: 'Any',
      leadDeliveryOption: 'Excel',
      errors: {},
      pacingSuccess:{},
      reerrors:{},
      reerrorstxt:{},
      reerrorsfrom:{},
      reerrorsto:{},
      reerrorsrange:{},
      reerrorsboth:{},
      cityfileerrors:{},
      statefileerrors:{},
      zipfileerrors:{},
      fileerrors:{},
      industryerrors:{},
      filecityerrors:{},
      filestateerrors:{},
      createdBy:'',
      alertDisplay: 'none',
      alertBorder: '',
      alertWidth: '',
      alertAlign: '',
      spanSpaceDisplay: 'none',
      timeZone1: 'true',
       region2:'true',
      jobFunction1: 'true',
       region2:'true',
      industryFileData:[],
      uploadFormatFile:[],
      clientNameDetails:[],
      clientNameDetailsStep1:[],
      industry:[],
      industry1:[],
      abm: 'Open',
      stateFileData:[],
      cityFileData:[],
      zipCodeFileData:[],

      country2:'true',
      jobLevel2:'true',
      jobfunction2:'true',
      country3:'true',
      jobLevel3:'true',
      employeeSize2:'true',
      jobfunction2:'true',
      selectedOption: "",
      selectedOption1:"",
      selectedOption2:"",
      selectedOption3:"",
      selectedOption4:"",
      selectedOptionIndustry4:"",
      selectedOption5:"",
      selectedOption6:"",
      selectedOption7:"",
      customCompRevenue:"",
      customEmpSize:"",
      //budget:"",
      currency:"USD",
      drpdisabled:'',
      drpdisable2:'',
      drpdisable3:'',
      drpdisabledemp:'',
      countryoptions:countryArray,
      country:[],// for label in component
      country1: [],// for db
      jobLevel:[],//for label in component
      jobLevel1: null,// for db
      employeeSize: [],//for label in component
      employeeSize1: null,// for db
      companyRevenue: [],//for label in component
      companyRevenue1: null,// for db
      region:[],// for label in component
      regiondb:[],// for db
      companyRevenue2:'true',
      jobFunction:[],
      timeZone:[],
      loading:" ",
      lpTimeline:"24",
      requiredLPApproval:"No",
      callAudit:"No",
      clientName:[],
      customEmployeeSize:[],
      customEmployeeSizeBack:{},
      customRevenue:{},
      customRevenueBack:{},
      customEmployeeSizeFromPopup:[{From:"",To:""}],
      customEmployeeSizeToPopup:[],
      customEmployeeSizePopUpText:[{customEmployeeSizeText:""}],
      customQuestionPopupBack:[],
      customAnswerPopupBack:[],
      customEmployeeSizePopupBackFromTo:[],
      
     // customEmployeeSizePopupBackTo:[],
      customEmployeeSizePopupBackText:[],
      customRevenuePopupBackFromTo:[],
      customRevenuePopupBackTo:[],
      customRevenuePopupBackText:[],
      customJobFunctionPopupBack:[], 
      customJobLevelPopupBack:[], 
      
      customIndustryBack:[],
      customRevenuePopupFromTo:[{From:"",To:""}],
      customRevenueTextPopup:[{customCompanyRevenue:""}],
     // customRevenueToPopup:[],
     customJobFunctionPopup:[{jobFunction:""}],
     customJobLevelPopup:[{jobLevel:""}],
     customQuestionsPopup:[],
      customAnswersPopup:[],
      newemparray:[],
      revenuecreate:[],
      // zipcodeBack:false,
      zipcodeFileBack:[],
      cityFileBack:[],
      stateFileBack:[],
      //customQuestionsDynamic:[{customQuestion:'',answer:'',disAllowAnswer:''}],
      // revenue:[],
      checked:false,
      checked1:false,
      checked2:false,
      checked3:false,
      jobTitlechk:'No',
      zipcodeChk:'No',
      cityChk:'No',
      stateChk:'No',
      NA:'No',
      campID:'',
      count:'',
      customQuestionID:'',
      pacingCampaignNoChangeArray:[],
      pacingCampaignDetails:[],
     isOpen:'',
      zipname:[],
      buttonDisplay: "button",
      buttonDisplayIndustry: "button",
      buttonDisplayEmp: "button",
      buttonDisplayRevenue: "button",
      buttonDisplayJobLevel: "button",
     
      buttonDisplayJobfnBack: "button",
      buttonDisplayIndustryBack: "button",
      buttonDisplayEmpBack: "button",
      buttonDisplayRevenueBck: "button",
      buttonDisplayJobLevelBack: "button",
      displayCallaudit:'none',
      displayCallauditBack:'none',
      pacingUnit:'Percentage',
      creativeApprovalRequiredFor:'Agency',
   
      regionCountryInfo : {
      

      
        
        "Africa": {
            "Algeria": {},
            "Angola": {},
            "Benin": {},
            "Botswana": {},
            "Burkina Faso": {},
            "Burundi": {},
            "Cabo Verde": {},
            "Cape Verde":{},            
            "Cameroon": {},
            "Central African Republic": {},
            "Chad": {},
            "Comoros": {},
            "Congo - Brazzaville":{},
            "Congo, the Democratic Republic of the":{},
            "Democratic Republic of the Congo": {},
            "Republic of the Congo": {},
            "Cote dIvoire": {},
            "Djibouti": {},
            "Egypt": {},
            "Equatorial Guinea": {},
            "Eritrea": {},
            "Eswatini": {},
            "Ethiopia": {},
            "Gabon": {},
            "Gambia": {},
            "Ghana": {},
            "Guinea": {},
            "Guinea-Bissau": {},
            "Kenya": {},
            "Lesotho": {},
            "Liberia": {},
            "Libya": {},
            "Madagascar": {},
            "Malawi": {},
            "Mali": {},
            "Mauritania": {},
            "Morocco": {},
            "Mozambique": {},
            "Namibia": {},
            "Niger": {},
            "Nigeria": {},
            "Reunion":{},
            "Rwanda": {},
            "Saint Helena, Ascension and Tristan da Cunha":{},
            "Sao Tome and Principe": {},
            "Senegal Republic": {},
            "Seychelles": {},
            "Sierra Leone": {},
            "Somalia Republic": {},
            "South Africa": {},
            "South Sudan": {},
            "Sudan": {},
            "Swaziland":{},
            "Tanzania": {},
            "The Gambia":{},
            "Togo": {},
            "Tunisia": {},
            "Uganda": {},
            "Western Sahara":{},
            "Zambia": {},
            "Zimbabwe": {}
          },
        
        "Asia": {
            "Afghanistan": {},
            "Armenia": {},
            "Azerbaijan": {},
            "Bahrain": {},
            "Bangladesh": {},
            "Bhutan": {},
            "British Indian Ocean Territory":{},
            "Brunei": {},
            "Cambodia": {},
            "China": {},
            "Cocos (Keeling) Islands":{},
            "Cyprus": {},
            "Georgia": {},
            "Hong Kong":{},
            "India": {},
            "Indonesia": {},
            "Iran": {},
            "Iraq": {},
            "Israel": {},
            "Japan": {},
            "Jordan": {},
            "Kazakhstan": {},
            "Kuwait": {},
            "Kyrgyzstan": {},
            "Laos": {},
            "Lebanon": {},
            "Malaysia": {},
            "Maldives": {},
            "Mongolia": {},
            "Myanmar": {},
            "Nepal": {},
            "North Korea": {},
            "Oman": {},
            "Pakistan": {},
            "Palestine": {},
            "Philippines": {},
            "Qatar": {},
            "Russia": {},
            "Saudi Arabia": {},
            "Singapore": {},
            "South Korea": {},
            "Sri Lanka": {},
            "Syria": {},
            "Taiwan": {},
            "Tajikistan": {},
            "Thailand": {},
            "Timor-Leste": {},
            "Turkey": {},
            "Turkmenistan": {},
            "United Arab Emirates (UAE)": {},
            "Uzbekistan": {},
            "Vietnam": {},
            "Yemen": {}
        },

        "Antarctic":{
        "French Southern Territories":{}

        },


        "Countries of Australia  and Oceania(Island)": {
          "Australia": {},
          "American Samoa":{},
          "Cook Islands":{},
          "Fiji": {},
          "French Polynesia":{},
          "Guam":{},
          "Kiribati": {},
          "Marshall Islands": {},
          "Micronesia": {},
          "Nauru": {},
          "New Caledonia":{},
          "New Zealand": {},
          "Norfolk Island":{},
          "Niue":{},
          "Palau": {},
          "Papua New Guinea": {},
          "Pitcairn":{},
          "Samoa": {},
          "Solomon Islands": {},
          "Tonga": {},
          "Tokelau":{},
          "Tuvalu": {},
          "Vanuatu":{},
          "Wallis and Futuna":{}
      },
      "Europe": {
        "Albania": {},
        "Aland Islands":{},
        "Andorra": {},
        "Armenia": {},
        "Austria": {},
        "Azerbaijan": {},
        "Belarus": {},
        "Belgium": {},
        "Bosnia and Herzegovina": {},
        "Bulgaria": {},
        "Croatia": {},
        "Cyprus": {},
        "Czech Republic": {},
        "Denmark": {},
        "Estonia": {},
        "Faroe Islands":{},
        "Finland": {},
        "France": {},
        "Gibraltar":{},
        "Georgia": {},
        "Germany": {},
        "Greece": {},
        "Guernsey":{},
        "Hungary": {},
        "Holy See Vatican City State":{},
        "Iceland": {},
        "Ireland": {},
        "Isle of Man":{},
        "Italy": {},
        "Jersey":{},
        "Kazakhstan": {},
        "Latvia": {},
        "Liechtenstein": {},
        "Lithuania": {},
        "Luxembourg": {},
        "Macedonia": {},
        "Malta": {},
        "Moldova": {},
        "Monaco": {},
        "Montenegro": {},
        "Norway": {},
        "Netherlands": {},
        "Poland": {},
        "Portugal": {},
        "Romania": {},
        "Russia": {},
        "San Marino": {},
        "Serbia": {},
        "Slovakia": {},
        "Slovenia": {},
        "Spain": {},
        "Svalbard and Jan Mayen Islands":{},
        "Sweden": {},
        "Switzerland": {},
        "Turkey": {},
        "Ukraine": {},
        "United Kingdom": {},
        "Vatican City": {}
  
    },
    
        
    "North America": {
      "Antigua and Barbuda": {},
      "Bahamas": {},
      "Barbados": {},
      "Belize": {},
      "Bermuda":{},
      "Canada": {},
      "Costa Rica": {},
      "Cuba": {},
      "Cayman Islands":{},
      "Dominica": {},
      "Dominican Republic": {},
      "El Salvador": {},
      "Greenland":{},
      "Grenada": {},
      "Guatemala": {},
      "Guadeloupe":{},
      "Haiti": {},
      "Honduras": {},
      "Jamaica": {},
      "Martinique":{},
      "Mexico": {},
      "Montserrat":{},
      "Nicaragua": {},
      "Panama": {},
      "Puerto Rico":{},
      "Saint Barthelemy":{},
      "Saint Kitts and Nevis": {},
      "Saint Lucia": {},
      "Saint Martin French part":{},
      "Sint Maarten Dutch part":{},
      "Saint Pierre and Miquelon":{},
      "Saint Vincent and the Grenadines": {},
      "Trinidad and Tobago": {},
      "The Bahamas":{},
      "Turks and Caicos Islands":{},
      "United States": {},
      "United States Minor Outlying Islands":{},
      "Virgin Islands, British":{}
     
      
  },
  "South America": {
    "Anguilla":{},
    "Antarctica":{},
    "Argentina":{},
      "Aruba": {},
      "Bolivia": {},
      "Bonaire, Sint Eustatius and Saba":{},
      "Brazil": {},
      "Chile": {},
      "Colombia": {},
      "Curacao":{},
      "Ecuador": {},
      "Falkland Islands Malvinas":{},
      "French Guiana":{},
      "Guyana": {},
      "Paraguay": {},
      "Peru":{},
      "Suriname": {},
      "Uruguay": {},
      "Venezuela": {}
    
  },

"Southeast Africa":{
  "Mayotte":{}

},
"Southeastern Europe":
{
  "Albania":{}
},

"Southern Atlantic Ocean":
{
  "Bouvet Island":{},
  "South Georgia and the South Sandwich Islands":{}
}




      }  
    }
    this.nameInput = React.createRef();
   // this.focusTextInput = this.focusTextInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitCampaign = this.submitCampaign.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleChangetimezone= this.handleChangetimezone.bind(this);
    this.RegionhandleChange = this.RegionhandleChange.bind(this);
    this.handleChangeMultiselectCountry = this.handleChangeMultiselectCountry.bind(this);
    this.handleChangeMultiselectJobLevel = this.handleChangeMultiselectJobLevel.bind(this);
    this.handleChangeMultiselectJobFunction = this.handleChangeMultiselectJobFunction.bind(this);
    this.handleChangeMultiselectemployeeSize = this.handleChangeMultiselectemployeeSize.bind(this);
    this.handleuploadIndustry = this.handleuploadIndustry.bind(this);
    this.jobLevelhandleChange = this.jobLevelhandleChange.bind(this);
    this.countryHandleChange=this.countryHandleChange.bind(this);
    this.employeehandleChange=this.employeehandleChange.bind(this);
    this.revenuehandleChange=this.revenuehandleChange.bind(this);
    this.handleUploadChange=this.handleUploadChange.bind(this);
    //this.handleCampaignID = this.handleCampaignID.bind(this);
    this.jobFunctionhandleChange = this.jobFunctionhandleChange.bind(this);
    this.jobFunctionhandleChangeif = this.jobFunctionhandleChangeif.bind(this);
    this.handleUploadIndustryBack=this.handleUploadIndustryBack.bind(this);
    this.handleUploadLeadDeliveryFormatBack=this.handleUploadLeadDeliveryFormatBack.bind(this);
    this.employeehandleChangeBack=this.employeehandleChangeBack.bind(this);
    this.revenuehandleChangeBack=this.revenuehandleChangeBack.bind(this);
    this.countryHandleChangeBack=this.countryHandleChangeBack.bind(this);
    this.jobLevelhandleChangeBack=this.jobLevelhandleChangeBack.bind(this);
    this.handleChangetimezoneBack= this.handleChangetimezoneBack.bind(this);
    this.RegionhandleChangeBack = this.RegionhandleChangeBack.bind(this);
    this.handleClientChange=this.handleClientChange.bind(this);
    this.handleClientChangeBack=this.handleClientChangeBack.bind(this);
    this.selectMultipleOption = this.selectMultipleOption.bind(this);
    this.selectMultipleOptionBack = this.selectMultipleOptionBack.bind(this);
    this.customHandleChange=this.customHandleChange.bind(this);
    this.industryhandleChange = this.industryhandleChange.bind(this);
    this.industryhandleChangeBack = this.industryhandleChangeBack.bind(this);
    //this.submitRevenueSize = this.submitRevenueSize.bind(this);
    //this.customEmployeeSizeFromPopupHandleChange=this.customEmployeeSizeFromPopupHandleChange.bind(this);
    this.customEmployeeSizeToPopupHandleChange=this.customEmployeeSizeToPopupHandleChange.bind(this);
   // this.customRevenueFromPopupHandleChange=this.customRevenueFromPopupHandleChange.bind(this);
    this.customRevenueToPopupHandleChange=this.customRevenueToPopupHandleChange.bind(this);
    //this.customJobFunctionPopupHandleChange=this.customJobFunctionPopupHandleChange.bind(this);
    this.cplHandleChange=this.cplHandleChange.bind(this);
    this.budgetHandleChange=this.budgetHandleChange.bind(this);
    this.cplHandleChangeBack=this.cplHandleChangeBack.bind(this);
    this.budgetHandleChangeBack=this.budgetHandleChangeBack.bind(this);
    this.leadAllocationHandleChange=this.leadAllocationHandleChange.bind(this);
    // this.lpApprovalHandleChange=this.lpApprovalHandleChange.bind(this);
    this.leadAllocationHandleChangeBack=this.leadAllocationHandleChangeBack.bind(this);

    this.addRevenueSize=this.addRevenueSize.bind(this);
    this.addRevenueBackSize=this.addRevenueBackSize.bind(this);
    this.addJobFunction=this.addJobFunction.bind(this);
    this.addJobLevel=this.addJobLevel.bind(this);
  this.addJobLevelBack=this.addJobLevelBack.bind(this);
    this.addJobFunctionBack=this.addJobFunctionBack.bind(this);
    
   // this.customEmployeeSizePopupHandleChange=this.customEmployeeSizePopupHandleChange.bind(this)
    this.addEmpSize=this.addEmpSize.bind(this);
    this.addEmpSizeBack=this.addEmpSizeBack.bind(this);

    this.lpApprovalhandleChange=this.lpApprovalhandleChange.bind(this);
    this.lpApprovalhandleChangeBack=this.lpApprovalhandleChangeBack.bind(this);

    this.addIndustry=this.addIndustry.bind(this);
    
    this.addIndustryBack=this.addIndustryBack.bind(this);


    this.statehandleChange=this.statehandleChange.bind(this);
    this.stateFilehandleChange=this.stateFilehandleChange.bind(this);

    this.cityhandleChange=this.cityhandleChange.bind(this);
    this.cityFilehandleChange=this.cityFilehandleChange.bind(this);


    this.zipcodehandleChange=this.zipcodehandleChange.bind(this);
    this.zipcodeFilehandleChange=this.zipcodeFilehandleChange.bind(this);

    this.zipcodehandleChangeBack=this.zipcodehandleChangeBack.bind(this);
     this.zipcodeFilehandleChangeBack=this.zipcodeFilehandleChangeBack.bind(this);

     this.dynamicZipFile=this.dynamicZipFile.bind(this);

     this.dynamicCityFile=this.dynamicCityFile.bind(this);
     this.dynamicStateFile=this.dynamicStateFile.bind(this);

     this.cityhandleChangeBack=this.cityhandleChangeBack.bind(this);
     this.cityFilehandleChangeBack=this.cityFilehandleChangeBack.bind(this);

     this.statehandleChangeBack=this.statehandleChangeBack.bind(this);
     this.stateFilehandleChangeBack=this.stateFilehandleChangeBack.bind(this);

    this.handleChangeIndustry=this.handleChangeIndustry.bind(this);
    this.customRevenuePopupHandleChange=this.customRevenuePopupHandleChange.bind(this);
    this.handleLeadvalidationback=this.handleLeadvalidationback.bind(this);
   
  //  this.customEmployeeSizePopupHandleChangeBack=this.customEmployeeSizePopupHandleChangeBack.bind(this);
 this.customRevenuePopupHandleChangeBack=this.customRevenuePopupHandleChangeBack.bind(this);
    // this.customJobFunctionPopupHandleChangeBack=this.customJobFunctionPopupHandleChangeBack.bind(this);
 this.handleLeadvalidation=this.handleLeadvalidation.bind(this);/* function at job title for lead validation */
    this.selectCreativesMultipleOption=this.selectCreativesMultipleOption.bind(this);
    this.selectCreativesMultipleOptionBack=this.selectCreativesMultipleOptionBack.bind(this);
    this.handleIndustryNA=this.handleIndustryNA.bind(this);
    this.handleIndustrybackNA=this.handleIndustrybackNA.bind(this);
    this.handleEmpsizeNA=this.handleEmpsizeNA.bind(this);
    this.handleEmpsizebackNA=this.handleEmpsizebackNA.bind(this);
    this.validatecustomQuestion=this.validatecustomQuestion.bind(this);
    this.validatecustomQuestionBack=this.validatecustomQuestionBack.bind(this);
    this.handleChangeMarketingChannel=this.handleChangeMarketingChannel.bind(this);
    this.handleChangeMarketingChannelBack=this.handleChangeMarketingChannelBack.bind(this);
    this.addPacingAllocation=this.addPacingAllocation.bind(this);
    this.addPacingAllocationBack=this.addPacingAllocationBack.bind(this);
  this.func1=this.func1.bind(this)
  this.func2=this.func2.bind(this)
  };
  func2(){
    // alert("called")
  let errors={}
  var d1,d2=""
  d1=this.state.campaignDetail[0].startDate
  d2=this.state.campaignDetail[0].endDate
  d1=new Date(d1)
  d2=new Date(d2)
  this.setState(prevState=>({prevD1Back:prevState.campaignDetail[0].startDate}))
  var oldlen=this.state.pacingCampaignDetails.length
  var  pd1=new Date(this.state.prevD1Back)
if(d1=="Invalid Date"||d2=="Invalid Date"){
  errors['blankEndDateback']="Please Enter End Date And Start Date For Campaign"
  this.setState({errors:errors})
} 
else{
  if(d2<d1){
    errors['blankEndDateback']="Campaign Start Date Cannot be Greater Than Campaign End Date"
  this.setState({errors:errors,pacingCampaignDetails:[]})
  }
  else{
  errors['blankEndDateback']=""
  this.setState({errors:errors})
  var months;
  var currentmonth;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  months=months+2;
  var actualIndex=(this.state.pacingCampaignDetails.length>0?this.state.pacingCampaignDetails[0].pacingID:0);
  // console.log(actualIndex)
  var indexpreviousMonth=d1.getMonth()
  var custompacingCampaignDetails;
  for (var i = 0; i < months; i++) {
    if(indexpreviousMonth>11){
      indexpreviousMonth=0
    }
    currentmonth=monthArray[indexpreviousMonth]
    custompacingCampaignDetails=[{'pacingMonth':currentmonth,'pacingPercentage':"",'pacingLeadCount':"",'pacingEndDate':"",'pacingCarryForward':"No",'pacingID':actualIndex}];
    indexpreviousMonth=indexpreviousMonth+1
    actualIndex=actualIndex+1
    let finalData = this.state.pacingCampaignDetails.concat(
      custompacingCampaignDetails
    );

    this.state.pacingCampaignDetails = [...finalData];
    var cleanBack = this.state.pacingCampaignDetails.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.pacingMonth === arr.pacingMonth&&t.pacingID===arr.pacingID)))
    console.log(cleanBack)
    this.setState({ pacingCampaignDetails: cleanBack })
}
if(this.state.prevD1Back!=undefined){
  if(pd1.getTime()===d1.getTime()){
    //do nothing
  }
  else{
// alert("old length=>"+oldlen)
for(var i=0;i<oldlen;i++){
this.state.pacingCampaignDetails.shift()
}
let dynamicpacingTemp=[...this.state.pacingCampaignDetails]
// console.log("after pop ing "+JSON.stringify(this.state.pacingCampaignDetails))         
this.setState({pacingCampaignDetails:[]})
setTimeout(function(){
this.setState({pacingCampaignDetails:dynamicpacingTemp})
}.bind(this),300) 
  } 
}
} 
  }
}
  func1(){
    let errors={};
    var d1,d2=""
    var custompacingCampaignDetails;
    this.setState(prevState=>({prevD1:prevState.startDate}))
    d1=this.state.startDate
    d2=this.state.endDate
    var oldlen=this.state.dynamicpacing.length
    d1=new Date(d1)
    d2=new Date(d2)
    var pd1=new Date(this.state.prevD1)
    if(d1=="Invalid Date"||d2=="Invalid Date"){
      errors['blankEndDate']="Please Enter End Date And Start Date For Campaign"
      this.setState({errors:errors})
    }
    else{
      if(d2<d1){
        // alert("here")
        errors['blankEndDate']="Campaign End Date Cannot be Greater Than Campaign Start Date"
        this.setState({errors:errors})
        if(this.state.dynamicpacing.length>0){
         this.setState({dynamicpacing:[]})
        }
      }
      else{
        errors['blankEndDate']=""
        this.setState({errors:errors})
      
        var months;
        var currentmonth;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth() + 1;
        months += d2.getMonth();
        months=months+2;
        var actualIndex=0
        var indexpreviousMonth=d1.getMonth()
        // alert(months)
        for (var i = 0; i < months; i++) {
          if(indexpreviousMonth>11){
            indexpreviousMonth=0
          }
          // alert("actualIndex"+actualIndex);
          currentmonth=monthArray[indexpreviousMonth]
          custompacingCampaignDetails=[{'pacingMonth':currentmonth,'pacingPercentage':"",'pacingLeadCount':"",'pacingEndDate':"",'pacingCarryForward':"No",'count':actualIndex}];
          indexpreviousMonth=indexpreviousMonth+1;
          actualIndex=actualIndex+1;
         
          let finalData = this.state.dynamicpacing.concat(
            custompacingCampaignDetails
          );
          this.state.dynamicpacing = [...finalData];
          var clean = this.state.dynamicpacing.filter((arr, index, self) =>
          index === self.findIndex((t) => (t.pacingMonth === arr.pacingMonth&&t.count===arr.count)))
          this.setState({ dynamicpacing:clean});
      }
      if(this.state.prevD1!=undefined){
        if(pd1.getTime()===d1.getTime()){
          //do nothing
        }
        else{
        // alert(oldlen) 
        console.log("before poping=>"+JSON.stringify(this.state.dynamicpacing)) 
        for(var i=0;i<oldlen;i++){
          // console.log(i)
          this.state.dynamicpacing.shift()
         }
         let dynamicpacingTemp=[...this.state.dynamicpacing]
         this.setState({dynamicpacing:[]})
         setTimeout(function(){
         this.setState({dynamicpacing:dynamicpacingTemp})
        }.bind(this),400) 
        }
      }
      }
      }
     }
  handleChangeLeadPercentage(i,e){
  // alert("handleChangeLeadPercentage");
    var {name,value}=e.target;
      var leadAllocation=this.state.leadAllocation;
      leadAllocation=parseInt(leadAllocation);
      var parsed = queryString.parse(this.props.location.search);
      var campID = parsed.campID;
      if (campID == undefined || campID == null || campID == "")
      {
        let dynamicpacing = [...this.state.dynamicpacing];
        if(name==='pacingPercentage'){
          var leadPercentageVal=parseInt(value);
         // alert("pacingPercentageChanged====>"+leadPercentageVal+"==="+leadAllocation)
          var count=leadPercentageVal*leadAllocation/100;
          count=Math.round(count);
          //alert("pacingLeadCount====>"+count)
           var leadPercentage='pacingLeadCount';
         
      
          dynamicpacing[i] = { ...dynamicpacing[i], [leadPercentage]: count };
          dynamicpacing[i] = { ...dynamicpacing[i], [name]: value };
          this.state.dynamicpacing=[...dynamicpacing];
          this.setState({ dynamicpacing:dynamicpacing});
         // alert("dynamicpacing"+JSON.stringify(this.state.dynamicpacing));
        }
        if(name==='pacingLeadCount'){
         
          var leadcountVal=parseInt(value);
          var count=leadcountVal/leadAllocation*100;
          count=Math.round(count);
          var leadCount='pacingPercentage';
         // alert("pacingLeadCountChanged===>"+leadcountVal+"==="+leadAllocation)
         //  alert("pacingPercentage====>"+count)

           let dynamicpacing = [...this.state.dynamicpacing];
           dynamicpacing[i] = { ...dynamicpacing[i], [leadCount]: count };
           dynamicpacing[i] = { ...dynamicpacing[i], [name]: value };
           
           this.state.dynamicpacing=[...dynamicpacing];
           this.setState({ dynamicpacing:dynamicpacing});
           //alert("dynamicpacing"+JSON.stringify(this.state.dynamicpacing));

        
        }
       
        if(name==='pacingEndDate'){
        
          var pacingEndDate='pacingEndDate';
          //alert("pacingPercentage====>"+count)
          let dynamicpacing = [...this.state.dynamicpacing];
          dynamicpacing[i] = { ...dynamicpacing[i], [name]: value };
          this.state.dynamicpacing=[...dynamicpacing];
          this.setState({ dynamicpacing: dynamicpacing});
        }
        if(name==='pacingCarryForward'){
        
          var pacingCarryForward='pacingCarryForward';
          //alert("pacingPercentage====>"+count)
          dynamicpacing[i] = { ...dynamicpacing[i], [name]: value };
         // dynamicpacing[i] = { ...dynamicpacing[i] };
         
          this.state.dynamicpacing=[...dynamicpacing];
          this.setState({ dynamicpacing: dynamicpacing});
        }
      }
      else{
      
         let fields = this.state;
        var name=e.target.name;
        var value=e.target.value;
        fields[e.target.name] = e.target.value;
       
  //       var {name,value}=e.target;
  // let companyRevenue = [...this.state.customRevenuePopupFromTo];
  // companyRevenue[i] = { ...companyRevenue[i], [name]: value };
  // this.setState({ customRevenuePopupFromTo: companyRevenue});

        let pacingCampaignDetailsData = [...this.state.pacingCampaignDetails];
        
        if(name==='pacingPercentage'){
          var leadPercentage=parseInt(value);
    
          var leadAllocation=this.state.campaignDetail[0].leadAllocation;
          var count=(leadPercentage*leadAllocation)/100;
          count=Math.round(count);
           var pacingLeadCount='pacingLeadCount';
         
         // alert("pacingLeadCount===>"+count)
         
          pacingCampaignDetailsData[i] = { ...pacingCampaignDetailsData[i], [name]: value };
          pacingCampaignDetailsData[i] = { ...pacingCampaignDetailsData[i], [pacingLeadCount]: count };
          this.state.pacingCampaignDetails=[...pacingCampaignDetailsData];
          this.setState({ pacingCampaignDetails:pacingCampaignDetailsData});
         // alert("Lead Count===>"+this.state.pacingLeadCount+"==="+JSON.stringify(this.state.pacingCampaignDetails));
        }
        if(name==='pacingLeadCount'){
          var leadcount=parseInt(value);
          var leadAllocation=this.state.campaignDetail[0].leadAllocation;
          var count=leadcount/leadAllocation*100;
          count=Math.round(count);
          var leadCount='pacingPercentage';
          //alert("pacingPercentage====>"+count)
          pacingCampaignDetailsData[i] = { ...pacingCampaignDetailsData[i], [name]: value };
          pacingCampaignDetailsData[i] = { ...pacingCampaignDetailsData[i], [leadCount]: count };
         
          this.state.pacingCampaignDetails=[...pacingCampaignDetailsData];
          this.setState({ pacingCampaignDetails: pacingCampaignDetailsData});
        }
    
    
        if(name==='pacingEndDate'){
        
          var pacingEndDate='pacingEndDate';
          //alert("pacingPercentage====>"+count)
          pacingCampaignDetailsData[i] = { ...pacingCampaignDetailsData[i], [name]: value };
         // pacingCampaignDetailsData[i] = { ...pacingCampaignDetailsData[i] };
         
          this.state.pacingCampaignDetails=[...pacingCampaignDetailsData];
          this.setState({ pacingCampaignDetails: pacingCampaignDetailsData});
        }
    
        if(name==='pacingCarryForward'){
        
          var pacingCarryForward='pacingCarryForward';
          //alert("pacingPercentage====>"+count)
          pacingCampaignDetailsData[i] = { ...pacingCampaignDetailsData[i], [name]: value };
         // pacingCampaignDetailsData[i] = { ...pacingCampaignDetailsData[i] };
         
          this.state.pacingCampaignDetails=[...pacingCampaignDetailsData];
          this.setState({ pacingCampaignDetails: pacingCampaignDetailsData});
        }
    
    

        //alert("pacingCampaignDetails==>"+JSON.stringify(this.state.pacingCampaignDetails));

      }  
     }// End of handleChangeLeadPercentage


  // pacing month allocation add and remove
addPacing(e)
{

  var d = new Date();
  var n =new Date(this.state.startDate)
  var year=d.getFullYear();
// alert(n.getMonth())
    var currentMonth=monthArray[n.getMonth()];
var previousMonth; var indexpreviousMonth;
if(this.state.endDate===undefined||this.state.startDate===undefined)
{
 let errors={};
 errors['blankEndDate']="Please Enter End Date And Start Date For Campaign"
 this.setState({errors:errors})
}
else
{
  let errors={};
 errors['blankEndDate']=""
 this.setState({errors:errors})

  var res = this.state.endDate.split("-");
  var j;var month;var currentEnteredMonth;
  for(j=0;j<res.length;j++)
  { 
    var ddate=res[0];
    var month=res[1];
  }
  
  month=parseInt(month);
  // alert("end date month==>"+month+"===="+ddate);
  
  
  for(i=0;i<this.state.dynamicpacing.length;i++)
  {
    //alert("dynamicpacing===>"+JSON.stringify(this.state.dynamicpacing[this.state.dynamicpacing.length-1].pacingMonth));
    previousMonth=(this.state.dynamicpacing[this.state.dynamicpacing.length-1].pacingMonth);
  }
  
  
 var indexpreviousMonth1=monthArray.indexOf(previousMonth);
  indexpreviousMonth=monthArray.indexOf(previousMonth);
  indexpreviousMonth1=indexpreviousMonth+1
  // alert("Previous Month===>"+previousMonth+"==="+indexpreviousMonth1+"year===>"+year);
  
  if(indexpreviousMonth1<=month || year<=ddate)
   {
     if(indexpreviousMonth1==month)
     {
       let errors={}
       errors['pacingExtraMonths']="Sorry you cannot add more months for pacing"
       this.setState({errors:errors})
      //  alert("bassssss")
       this.setState({drpdisabled2:'drpdisable'})
       return
     }
    count=count+1;
    //alert("Count===>"+count);
    var custompacingCampaignDetails=[{'pacingMonth':currentMonth,'pacingPercentage':"",'pacingLeadCount':"",'pacingEndDate':"",'pacingCarryForward':"No",count:count}];
    let finalData=this.state.dynamicpacing.concat(custompacingCampaignDetails);
    this.setState({dynamicpacing:finalData});
    // alert("dynamicpacing===>"+JSON.stringify(this.state.dynamicpacing.length));
  var i;
    for(i=0;i<this.state.dynamicpacing.length;i++)
    {
      //alert("dynamicpacing===>"+JSON.stringify(this.state.dynamicpacing[this.state.dynamicpacing.length-1].pacingMonth));
      previousMonth=(this.state.dynamicpacing[this.state.dynamicpacing.length-1].pacingMonth);
    }
    //alert("Index===>"+previousMonth+"===="+(monthArray.indexOf(previousMonth)));
     indexpreviousMonth=monthArray.indexOf(previousMonth);
    var nextMonth;
  
  if(previousMonth===undefined)
  {
  //ignore
  }
  else
  {
    if(indexpreviousMonth===11)
    {
      nextMonth=monthArray[0];
     // alert("Next Month if december is entered==>"+nextMonth);
    }
    else
    {
      indexpreviousMonth=indexpreviousMonth+1;
      nextMonth=monthArray[indexpreviousMonth];
     // alert("Next Month==>"+nextMonth);
    }
  
   // alert("Next Month==>"+nextMonth+"===="+count);
    var pacingMonthName='pacingMonth';
           
    var custompacingCampaignDetails=[{'pacingMonth':nextMonth,'pacingPercentage':"",'pacingLeadCount':"",'pacingEndDate':"",'pacingCarryForward':"No",count:count}];
    let finalData=this.state.dynamicpacing.concat(custompacingCampaignDetails);
    this.state.dynamicpacing=[...finalData];
    this.setState({dynamicpacing:this.state.dynamicpacing});
    
    // let dynamicpacingData = [...this.state.dynamicpacing];
    // dynamicpacingData[count] = { ...dynamicpacingData[count], [pacingMonthName]: nextMonth };
    //  this.state.dynamicpacing=[...dynamicpacingData];
    //  this.setState({ dynamicpacing:dynamicpacingData});
  //alert("dynamicpacing===>"+JSON.stringify( this.state.dynamicpacing));
  
  }
  }
  else{
  // alert("not valid")
  }
}
}// end of addPacing()

addPacingBack(e)
{

  // count=count+1;
  // this.setState({pacingCampaignDetails:[...this.state.pacingCampaignDetails,count]})
  var d = new Date();
  var n = new Date(this.state.campaignDetail[0].startDate)
  var year=d.getFullYear();
  // alert(n.getMonth())
  var currentMonth=monthArray[n.getMonth()];
  var indexpreviousMonthBack;
  var previousMonthBack;
  //alert("currentMonth==>"+currentMonth);
if(this.state.campaignDetail[0].startDate===undefined||this.state.campaignDetail[0].startDate===""||this.state.campaignDetail[0].endDate===undefined||this.state.campaignDetail[0].endDate===""){
  let errors={}
  errors['pacingExtraMonthsBack']="Please Add Start Date And End Date"
  this.setState({errors:errors})
}else{
  let errors={}
  errors['pacingExtraMonthsBack']=""
  this.setState({errors:errors})
  var res = this.state.campaignDetail[0].endDate.split("-");
  var j;var month;var currentEnteredMonth;
  for(j=0;j<res.length;j++)
  { 
    var ddate=res[0];
    var month=res[1];
  }
  
  month=parseInt(month);
  // alert("end date month==>"+month+"===="+ddate);
  
  
  for(var p=0;p<this.state.pacingCampaignDetails.length;p++)
  {
    //alert("dynamicpacing===>"+JSON.stringify(this.state.dynamicpacing[this.state.dynamicpacing.length-1].pacingMonth));
    previousMonthBack=(this.state.pacingCampaignDetails[this.state.pacingCampaignDetails.length-1].pacingMonth);
  }
  

   indexpreviousMonthBack=monthArray.indexOf(previousMonthBack);
   indexpreviousMonthBack=indexpreviousMonthBack+1;
// alert("month===>"+month+"===="+indexpreviousMonthBack+"==="+previousMonthBack);
if(indexpreviousMonthBack<=month || year<=ddate)
{
  if(indexpreviousMonthBack==month)
  {
    let errors={}
    errors['pacingExtraMonthsBack']="Sorry you cannot add more months for pacing"
    this.setState({errors:errors})
    this.setState({drpdisable3:'drpdisable'})
    return
  }
  var count=this.state.pacingCampaignDetails.length;
  count=count+1;
  //  alert("Count===>"+count);
  var custompacingCampaignDetails=[{'pacingMonth':currentMonth,'LeadPercentage':"",'LeadCount':"",'EndDate':"",'CarryForward':"No",count:count}];
  let finalDataText=this.state.pacingCampaignDetails.concat(custompacingCampaignDetails);
  this.setState({pacingCampaignDetails:finalDataText});

 // alert("pacingCampaignDetails==>"+JSON.stringify(this.state.pacingCampaignDetails));
  
  //alert("pacingCampaignDetails length===>"+JSON.stringify(this.state.pacingCampaignDetails.length));
var i;var previousMonth;
for(i=0;i<this.state.pacingCampaignDetails.length;i++)
{
 // alert("pacingCampaignDetails===>"+JSON.stringify(this.state.pacingCampaignDetails[this.state.pacingCampaignDetails.length-1].pacingMonth));
  previousMonth=(this.state.pacingCampaignDetails[this.state.pacingCampaignDetails.length-1].pacingMonth);
}
var indexpreviousMonth=monthArray.indexOf(previousMonth);
var nextMonth;

if(previousMonth===undefined)
{
//ignore
}
else
{
if(indexpreviousMonth===11)
{
  nextMonth=monthArray[0];
 //alert("Next Month if december is entered==>"+nextMonth);
}
else
{
  indexpreviousMonth=indexpreviousMonth+1;
  nextMonth=monthArray[indexpreviousMonth];
  //alert("Next Month==>"+nextMonth);
}

//  alert("Next Month==>"+nextMonth+"===="+count);
 var pacingMonthName='pacingMonth';
         
 var custompacingCampaignDetails=[{'pacingMonth':nextMonth,'pacingPercentage':"",'pacingLeadCount':"",'pacingEndDate':"",'pacingCarryForward':"No",count:count}];
 let finalData=this.state.pacingCampaignDetails.concat(custompacingCampaignDetails);
 this.state.pacingCampaignDetails=[...finalData];
 this.setState({pacingCampaignDetails:this.state.pacingCampaignDetails});



}
 
}
else
{
}
}
}

removePacing(e,i){
 let errors={}
 errors['pacingExtraMonths']=""
  // alert("i==>"+i);
  this.state.dynamicpacing.splice(i,1);
  // alert("dynamicpacing===>"+JSON.stringify(this.state.dynamicpacing));
   this.setState({dynamicpacing:this.state.dynamicpacing,drpdisabled2:'',errors:errors});
   
  
     
 }

 removePacingBack(e,i){
  let errors={}
  errors['pacingExtraMonthsBack']=""
  var pacingID=e.target.getAttribute('name');
  
  this.state.pacingCampaignDetails.splice(i,1);
  this.setState({pacingCampaignDetails:this.state.pacingCampaignDetails,drpdisable3:'',errors:errors});

  if(pacingID!==undefined ||pacingID!=''){
    // alert(pacingID)
  fetch("campaign/deletePacingCampaign?pacingID="+pacingID )
  .then(res => res.json())
    .then(pacingID =>{
  
     this.setState({'pacingID':pacingID});
   }).catch(function(err){console.log(err)});
  }
}
  
  addPacingAllocation(){
    let errors={}
    let pacingSuccess={};
    let fields = this.state.fields;
    let leadValidate=true;
    let percentageUndefined;
    let percentageCount;
    let countUndefined;
    let countCount;
    let pacingDateValidate;
    let pacingCurrentMonthValidate;
    
    var pattern=RegExp('^[0-9]+$')
    var leadperCount=0;
    var leadCountper=0;

    if(this.state.leadAllocation==undefined||this.state.leadAllocation==null||this.state.leadAllocation=="")
    {
      leadValidate=false;
      errors["leadRequired"]=<li>Please enter lead allocation first</li>;
      pacingSuccess["PacingSuccess"]="";
      this.setState({errors:errors,pacingSuccess:pacingSuccess});
      return;
    }
    else
    {
       leadValidate=true;
       errors["leadRequired"]="";
       this.setState({errors:errors});
    }
    
    if(this.state.pacingUnit==="Number")
    {     
      for(var s=0;s<this.state.dynamicpacing.length;s++)
      {
        if(pattern.test(this.state.dynamicpacing[s].pacingLeadCount))
        {
         errors['WrongValues']=""
         countUndefined=true; 
       }
        else
        {
         errors['WrongValues']=<li>Please Enter Numbers Only</li>
         countUndefined=false;
         return;
        }
   
      }
      var pacingLeadCountTotal=0
      for(j=0;j<this.state.dynamicpacing.length;j++)
      {
        pacingLeadCountTotal=pacingLeadCountTotal+parseInt(this.state.dynamicpacing[j].pacingLeadCount) 
      }  
    
      for(var i=0;i<this.state.dynamicpacing.length;i++)
      {
        var pacingLeadCount=this.state.dynamicpacing[i].pacingLeadCount;
  
         //alert("pacingLeadCount===>"+pacingLeadCount)
         if(pacingLeadCount==""||pacingLeadCount==undefined||pacingLeadCount==null)
         {
           countUndefined=false;
            errors["countPer"]=<li>Please enter lead count</li>;
            pacingSuccess["PacingSuccess"]="";
             this.setState({errors:errors,pacingSuccess:pacingSuccess});
             return;
          }
         else
         {
           countUndefined=true;
           errors["countPer"]="";
  
         
               pacingLeadCount =parseInt(pacingLeadCount)
  
               leadCountper=parseInt(leadCountper)+parseInt(pacingLeadCount);
  
               //alert("leadcount Count===>"+leadCountper);
           if(pacingLeadCountTotal==this.state.leadAllocation)
                 {
                  
                   countCount=true;
                   errors["countPerCount"]="";
                   this.setState({errors:errors});
                 }
                 else
                 {
                   if(pacingLeadCountTotal>this.state.leadAllocation)
                   {
                    countCount=false;
                    errors["countPerCount"]=<li>Lead count can't be greater than allocated lead</li>;
                    pacingSuccess["PacingSuccess"]="";
                    this.setState({errors:errors,pacingSuccess:pacingSuccess});
                    return;
                   }
                   if(pacingLeadCountTotal<this.state.leadAllocation)
                   {
                    countCount=false;
                    errors["countPerCount"]=<li>Lead count can't be lesser than allocated lead</li>;
                    pacingSuccess["PacingSuccess"]="";
                    this.setState({errors:errors,pacingSuccess:pacingSuccess});
                    return;
                   }
                 }
            
             this.setState({errors:errors});
         }
       //validation for pacingLeadCount
  
  
       var pacingEndDate=this.state.dynamicpacing[i].pacingEndDate;
       if(pacingEndDate==""||pacingEndDate==undefined||pacingEndDate==null)
       {
         pacingDateValidate=false;
         errors["Pacingdate"]=<li>Please select pacing date1</li>;
         pacingSuccess["PacingSuccess"]="";
         this.setState({errors:errors,pacingSuccess:pacingSuccess});
         return;
       }
       else
       {
         pacingDateValidate=true;
         errors["Pacingdate"]="";
         this.setState({errors:errors});
       
  
        // alert("pacingEndDate==>"+pacingEndDate)
         var res = pacingEndDate.split("-");
         var j;var month;var currentEnteredMonth;
         for(j=0;j<res.length;j++)
         {
           var month=res[1];
         }
        //alert("month==>"+month);
        month=parseInt(month);
        if(month===1)
        {
          currentEnteredMonth="January"
        }else
        if(month===2)
        {
          currentEnteredMonth="February"
        }else
        if(month===3)
        {
          currentEnteredMonth="March"
        }else
        if(month===4)
        {
          currentEnteredMonth="April"
        }else if(month===5)
        {
          currentEnteredMonth="May"
        }else
        if(month===6)
        {
          currentEnteredMonth="June"
        }else
        if(month===7)
        {
          currentEnteredMonth="July"
        }else
        if(month===8)
        {
          currentEnteredMonth="August"
        }else
        if(month===9)
        {
          currentEnteredMonth="September"
        }else
        if(month===10)
        {
          currentEnteredMonth="October"
        }else
        if(month===11)
        {
          currentEnteredMonth="November"
        }else
        if(month===12)
        {
          currentEnteredMonth="December"
        }else
        {
  //do nothing
        }
        
  
        // currentEnteredMonth=monthArray[month];
        var pacingMonth=this.state.dynamicpacing[i].pacingMonth;
  
      //  alert("currentEnteredMonth===>"+currentEnteredMonth+"===="+pacingMonth)
  
        if (currentEnteredMonth===pacingMonth)
        {
          pacingCurrentMonthValidate=true
          errors["PacingCurrentMonth"]="";
          this.setState({errors:errors});
       
  //alert("Good");
        }
        else
        {
          pacingCurrentMonthValidate=false;
          errors["PacingCurrentMonth"]=<li>Pacing Month and Entered Date Do Not Match</li>;
          pacingSuccess["PacingSuccess"]="";
          this.setState({errors:errors,pacingSuccess:pacingSuccess});
          return;
        //  alert("Bad");
        }
  
       if ((Date.parse(pacingEndDate)<Date.parse(this.state.startDate))){
         // alert("cant less than start date")
         pacingDateValidate=false;
         errors["Pacingdate"]=<li>Pacing end date can't be less than campaign start date</li>;
         pacingSuccess["PacingSuccess"]="";
         this.setState({errors:errors,pacingSuccess:pacingSuccess});
         return;
         }
  
     if ((Date.parse(pacingEndDate)> Date.parse(this.state.endDate))) {
       pacingDateValidate=true;
       // alert(" cant be greater than end date ");
       errors["Pacingdate"]=<li>Pacing end date  can't be greater than campaign end date</li>;
       pacingSuccess["PacingSuccess"]="";
       this.setState({errors:errors,pacingSuccess:pacingSuccess});
       return;
       }
  
       }
       // validate pacingEndDate
       let pacingForm;
       if(pacingDateValidate==false|| countCount==false||countUndefined===false||percentageCount===false||
        percentageUndefined===false||leadValidate===false||pacingCurrentMonthValidate===false)
        {
          pacingForm=false;
      //    alert("false")
          this.setState({errors:errors})
        }
        else{
          pacingForm=true;
          pacingSuccess["PacingSuccess"]=<li>Pacing Added Successfully</li>;
          //  alert("true");
            this.setState({errors:'',pacingSuccess:pacingSuccess})
        }  
      }//end of for
    }

        if(this.state.pacingUnit==="Percentage")
    {
      for(var a=0;a<this.state.dynamicpacing.length;a++)
                {
                  if(pattern.test(this.state.dynamicpacing[a].pacingPercentage))
                  {
                   errors['WrongValues']=""
                   percentageUndefined=true; 
                 }
                  else
                  {
                   errors['WrongValues']=<li>Please Enter Numbers Only</li>
                   percentageUndefined=false;
                   return;
                  }
                }
      var pacingPercentageTotal=0;
      for(j=0;j<this.state.dynamicpacing.length;j++)
      {
    pacingPercentageTotal=pacingPercentageTotal+parseInt(this.state.dynamicpacing[j].pacingPercentage) 
      }
  
  //alert("dynamicpacing length===>"+this.state.dynamicpacing.length)
      for(var i=0;i<this.state.dynamicpacing.length;i++)
      {
       // alert("i==>"+i);
        var pacingPercentage=this.state.dynamicpacing[i].pacingPercentage;
      //  alert("pacingPercentage===>"+pacingPercentage)
        if(pacingPercentage===undefined||pacingPercentage===""||pacingPercentage===null)
        {
          percentageUndefined=false;
          errors["percentagePer"]=<li>Please enter lead percentage</li>;
          pacingSuccess["PacingSuccess"]="";
          this.setState({errors:errors,pacingSuccess:pacingSuccess});
          return;
        }
        else
        {
          percentageUndefined=true;
          errors["percentagePer"]="";
          leadperCount=parseInt(leadperCount)+parseInt(pacingPercentage);
        //  alert("pacingPercentage Count==>"+leadperCount) 
        if(pacingPercentageTotal==100)
                {
                  // alert("per matched 100");
                  percentageCount=true;
                  errors["percentagePerCount"]="";
                  this.setState({errors:errors});
      
                }
                else
                {
                  //alert("per not matched");
                  if(pacingPercentageTotal>100)
                  {
                    percentageCount=false;
                    errors["percentagePerCount"]=<li>Lead percentage can't be greater than hundred</li>;
                    pacingSuccess["PacingSuccess"]="";
                    this.setState({errors:errors,pacingSuccess:pacingSuccess});
                    return;  
                  }
                  if(pacingPercentageTotal<100)
                  {
                    percentageCount=false;
                    errors["percentagePerCount"]=<li>Lead percentage can't be lesser than hundred</li>;
                    pacingSuccess["PacingSuccess"]="";
                    this.setState({errors:errors,pacingSuccess:pacingSuccess});
                    return;
                  }
                }
              
          this.setState({errors:errors});
        }// validation for pacingPercentage
  
       var pacingEndDate=this.state.dynamicpacing[i].pacingEndDate;
      //  alert()
       if(pacingEndDate==""||pacingEndDate==undefined||pacingEndDate==null)
       {
         pacingDateValidate=false;
         errors["Pacingdate"]=<li>Please select pacing date2</li>;
         pacingSuccess["PacingSuccess"]="";
         this.setState({errors:errors,pacingSuccess:pacingSuccess});
         return;
       }
       else
       {
         pacingDateValidate=true;
         errors["Pacingdate"]="";
         this.setState({errors:errors});
       
  
        // alert("pacingEndDate==>"+pacingEndDate)
         var res = pacingEndDate.split("-");
         var j;var month;var currentEnteredMonth;
         for(j=0;j<res.length;j++)
         {
           var month=res[1];
         }
        //alert("month==>"+month);
        month=parseInt(month);
        if(month===1)
        {
          currentEnteredMonth="January"
        }else
        if(month===2)
        {
          currentEnteredMonth="February"
        }else
        if(month===3)
        {
          currentEnteredMonth="March"
        }else
        if(month===4)
        {
          currentEnteredMonth="April"
        }else if(month===5)
        {
          currentEnteredMonth="May"
        }else
        if(month===6)
        {
          currentEnteredMonth="June"
        }else
        if(month===7)
        {
          currentEnteredMonth="July"
        }else
        if(month===8)
        {
          currentEnteredMonth="August"
        }else
        if(month===9)
        {
          currentEnteredMonth="September"
        }else
        if(month===10)
        {
          currentEnteredMonth="October"
        }else
        if(month===11)
        {
          currentEnteredMonth="November"
        }else
        if(month===12)
        {
          currentEnteredMonth="December"
        }else
        {
  //do nothing
        }
        
  
        // currentEnteredMonth=monthArray[month];
        var pacingMonth=this.state.dynamicpacing[i].pacingMonth;
  
      //  alert("currentEnteredMonth===>"+currentEnteredMonth+"===="+pacingMonth)
  
        if (currentEnteredMonth===pacingMonth)
        {
          pacingCurrentMonthValidate=true
          errors["PacingCurrentMonth"]="";
          this.setState({errors:errors});
       
  //alert("Good");
        }
        else
        {
          pacingCurrentMonthValidate=false;
          errors["PacingCurrentMonth"]=<li>Pacing Month and Entered Date Do Not Match</li>;
          pacingSuccess["PacingSuccess"]="";
          this.setState({errors:errors,pacingSuccess:pacingSuccess});
          return;
        //  alert("Bad");
        }
  
       if ((Date.parse(pacingEndDate)<Date.parse(this.state.startDate))){
         // alert("cant less than start date")
         pacingDateValidate=false;
         errors["Pacingdate"]=<li>Pacing end date can't be less than campaign start date</li>;
         pacingSuccess["PacingSuccess"]="";
         this.setState({errors:errors,pacingSuccess:pacingSuccess});
         return;
         }
  
     if ((Date.parse(pacingEndDate)> Date.parse(this.state.endDate))) {
       pacingDateValidate=true;
       // alert(" cant be greater than end date ");
       errors["Pacingdate"]=<li>Pacing end date  can't be greater than campaign end date</li>;
       pacingSuccess["PacingSuccess"]="";
       this.setState({errors:errors,pacingSuccess:pacingSuccess});
       return;
       }
  
       }
       // validate pacingEndDate
  
  
       let pacingForm;
       if(pacingDateValidate==false|| countCount==false||countUndefined===false||percentageCount===false||
        percentageUndefined===false||leadValidate===false||pacingCurrentMonthValidate===false)
        {
          pacingForm=false;
      //    alert("false")
          this.setState({errors:errors})
        }
        else{
          pacingForm=true;
          pacingSuccess["PacingSuccess"]=<li>Pacing Added Successfully</li>;
          //  alert("true");
            this.setState({errors:'',pacingSuccess:pacingSuccess})
        }
  
      
      }//end of for
      
    }
    


          
  }
  //end of addpacingallocation


  addPacingAllocationBack(){
    let errors={}
    let pacingSuccess={};
    let fields = this.state.fields;
    let leadValidate=true;
    let percentageUndefined;
    let percentageCount;
    let countUndefined;
    let countCount;
    let pacingDateValidate;
    let pacingCurrentMonthValidate;
    
    var patternBack=RegExp('^[0-9]+$')
    var leadperCount=0;
    var leadCountper=0;

    var leadAllocation=this.state.campaignDetail[0].leadAllocation;
    if(leadAllocation==undefined||leadAllocation==null||leadAllocation=="")
    {
      leadValidate=false;
      errors["leadRequired"]=<li>Please enter lead allocation first</li>;
      pacingSuccess["PacingSuccess"]="";
      this.setState({errors:errors,pacingSuccess:pacingSuccess});
      return;
    }
    else
    {
       leadValidate=true;
       errors["leadRequired"]="";
       this.setState({errors:errors});
    }
    if(this.state.pacingCampaignDetails[0].pacingUnit==="Number"||this.state.pacingUnit==="Number")
    {for(var a=0;a<this.state.pacingCampaignDetails.length;a++){
      if(patternBack.test(this.state.pacingCampaignDetails[a].pacingLeadCount))
      {
        errors['wrongValuesBack']=""
        countCount=true;
      }
      else
      {
        errors['wrongValuesBack']=<li>Please Enter Numbers Only</li>
        countCount=false;
        return;
      }
    }
      
      var pacingLeadCountTotalBack=0;
      for(var i=0;i<this.state.pacingCampaignDetails.length;i++)
      {
        pacingLeadCountTotalBack=pacingLeadCountTotalBack+parseInt(this.state.pacingCampaignDetails[i].pacingLeadCount) 
      }
      for(var i=0;i<this.state.pacingCampaignDetails.length;i++)
    {
           var pacingLeadCount=this.state.pacingCampaignDetails[i].pacingLeadCount;

       //alert("pacingLeadCount===>"+pacingLeadCount)
       if(pacingLeadCount==""||pacingLeadCount==undefined||pacingLeadCount==null)
       {
         countUndefined=false;
          errors["countPer"]=<li>Please enter lead count</li>;
          pacingSuccess["PacingSuccess"]="";
           this.setState({errors:errors,pacingSuccess:pacingSuccess});
           return;
        }
       else
       {
         countUndefined=true;
         errors["countPer"]="";

       
             pacingLeadCount =parseInt(pacingLeadCount)

             leadCountper=parseInt(leadCountper)+parseInt(pacingLeadCount);

             //alert("leadcount Count===>"+leadCountper);
         if(pacingLeadCountTotalBack===leadAllocation)
               {
                
                 countCount=true;
                 errors["countPerCount"]="";
                 this.setState({errors:errors});
               }
               else
               {
                 if(pacingLeadCountTotalBack<leadAllocation)
                 {
                  countCount=false;
                  errors["countPerCount"]=<li>Lead count can't be lesser than allocated lead</li>;
                  pacingSuccess["PacingSuccess"]="";
                  this.setState({errors:errors,pacingSuccess:pacingSuccess});
                  return;
                 }
                 if(pacingLeadCountTotalBack>leadAllocation)
                 {
                  countCount=false;
                  errors["countPerCount"]=<li>Lead count can't be greater than allocated lead</li>;
                  pacingSuccess["PacingSuccess"]="";
                  this.setState({errors:errors,pacingSuccess:pacingSuccess});
                  return;
                 }
               }


           this.setState({errors:errors});
       }
     //validation for pacingLeadCount


     var pacingEndDate=this.state.pacingCampaignDetails[i].pacingEndDate;
     if(pacingEndDate==""||pacingEndDate==undefined||pacingEndDate==null)
     {
       pacingDateValidate=false;
       errors["Pacingdate"]=<li>Please select pacing date3</li>;
       pacingSuccess["PacingSuccess"]="";
       this.setState({errors:errors,pacingSuccess:pacingSuccess});
       return;
     }
     else
     {
       pacingDateValidate=true;
       errors["Pacingdate"]="";
       this.setState({errors:errors});
     

      // alert("pacingEndDate==>"+pacingEndDate)
       var res = pacingEndDate.split("-");
       var j;var month;var currentEnteredMonth;
       for(j=0;j<res.length;j++)
       {
         var month=res[1];
       }
      //alert("month==>"+month);
      month=parseInt(month);
      if(month===1)
      {
        currentEnteredMonth="January"
      }else
      if(month===2)
      {
        currentEnteredMonth="February"
      }else
      if(month===3)
      {
        currentEnteredMonth="March"
      }else
      if(month===4)
      {
        currentEnteredMonth="April"
      }else if(month===5)
      {
        currentEnteredMonth="May"
      }else
      if(month===6)
      {
        currentEnteredMonth="June"
      }else
      if(month===7)
      {
        currentEnteredMonth="July"
      }else
      if(month===8)
      {
        currentEnteredMonth="August"
      }else
      if(month===9)
      {
        currentEnteredMonth="September"
      }else
      if(month===10)
      {
        currentEnteredMonth="October"
      }else
      if(month===11)
      {
        currentEnteredMonth="November"
      }else
      if(month===12)
      {
        currentEnteredMonth="December"
      }else
      {
//do nothing
      }
      

      // currentEnteredMonth=monthArray[month];
      var pacingMonth=this.state.pacingCampaignDetails[i].pacingMonth;

    //  alert("currentEnteredMonth===>"+currentEnteredMonth+"===="+pacingMonth)

      if (currentEnteredMonth===pacingMonth)
      {
        pacingCurrentMonthValidate=true
        errors["PacingCurrentMonth"]="";
        this.setState({errors:errors});
     
//alert("Good");
      }
      else
      {
        pacingCurrentMonthValidate=false;
        errors["PacingCurrentMonth"]=<li>Pacing Month and Entered Date Do Not Match</li>;
        pacingSuccess["PacingSuccess"]="";
        this.setState({errors:errors,pacingSuccess:pacingSuccess});
      //  alert("Bad");
       return;
      }

     if ((Date.parse(pacingEndDate)<Date.parse(this.state.campaignDetail[0].startDate))){
       // alert("cant less than start date")
       pacingDateValidate=false;
       errors["Pacingdate"]=<li>Pacing end date can't be less than campaign start date</li>;
       pacingSuccess["PacingSuccess"]="";
       this.setState({errors:errors,pacingSuccess:pacingSuccess});
       return;

       }

   if ((Date.parse(pacingEndDate)> Date.parse(this.state.campaignDetail[0].endDate))) {
     pacingDateValidate=false;
     // alert(" cant be greater than end date ");
     errors["Pacingdate"]=<li>Pacing end date  can't be greater than campaign end date</li>;
     pacingSuccess["PacingSuccess"]="";
     this.setState({errors:errors,pacingSuccess:pacingSuccess});
     return;
     }

     }
     // validate pacingEndDate


    // alert(pacingDateValidate+"="+countCount+"="+countUndefined+"="+percentageCount+"="+percentageUndefined+"="+leadValidate+"="+pacingCurrentMonthValidate);
     let pacingForm;
     if(pacingDateValidate==false|| countCount==false||countUndefined===false||percentageCount===false||
      percentageUndefined===false||leadValidate===false||pacingCurrentMonthValidate===false)
      {
        pacingForm=false;
        //alert("false")
        this.setState({errors:errors})
      }
      else{
        pacingForm=true;
        pacingSuccess["PacingSuccess"]=<li>Pacing Added Successfully</li>;
         //alert("true");
          this.setState({errors:'',pacingSuccess:pacingSuccess})
      }
    }
    }//end of lead count validations
    if(this.state.pacingCampaignDetails[0].pacingUnit==="Percentage"||this.state.pacingUnit==="Percentage")
    {for( var p=0;p<this.state.pacingCampaignDetails.length;p++)
      {
        if(patternBack.test(this.state.pacingCampaignDetails[p].pacingPercentage))
        {
          errors['wrongValuesBack']=""
          percentageCount=true;
        }
        else
        {
          errors['wrongValuesBack']=<li>Please Enter Numbers Only</li>
          percentageCount=false;
          return;
        }
      }

      var pacingPercentageTotalBack=0
      for(j=0;j<this.state.pacingCampaignDetails.length;j++)
      {
    pacingPercentageTotalBack=pacingPercentageTotalBack+parseInt(this.state.pacingCampaignDetails[j].pacingPercentage) 
      }
      for(var i=0;i<this.state.pacingCampaignDetails.length;i++)
      {
             var pacingPercentage=this.state.pacingCampaignDetails[i].pacingPercentage;
       
        if(pacingPercentage===undefined||pacingPercentage===""||pacingPercentage===null)
        {
          percentageUndefined=false;
          errors["percentagePer"]=<li>Please enter lead percentage</li>;
          pacingSuccess["PacingSuccess"]="";
          this.setState({errors:errors,pacingSuccess:pacingSuccess});
          return;
        }
        else
        {
          percentageUndefined=true;
          errors["percentagePer"]="";
          leadperCount=parseInt(leadperCount)+parseInt(pacingPercentage);
        //  alert("pacingPercentage Count==>"+leadperCount)
          if(pacingPercentageTotalBack===100)
                {
                  //alert("per matched 100");
                  percentageCount=true;
                  errors["percentagePerCount"]="";
                  this.setState({errors:errors});
      
                }
                else
                {
                  if(pacingPercentageTotalBack<100)
                  {
  //alert("per not matched");
  percentageCount=false;
  errors["percentagePerCount"]=<li>Lead percentage can't be lesser than hundred</li>;
  pacingSuccess["PacingSuccess"]="";
  this.setState({errors:errors,pacingSuccess:pacingSuccess});
  return;
                  }
                  if(pacingPercentageTotalBack>100)
                  {
                    percentageCount=false;
  errors["percentagePerCount"]=<li>Lead percentage can't be greater than hundred</li>;
  pacingSuccess["PacingSuccess"]="";
  this.setState({errors:errors,pacingSuccess:pacingSuccess});
  return;
                  }
                }
          this.setState({errors:errors});
        }// validation for pacingPercentage
       var pacingEndDate=this.state.pacingCampaignDetails[i].pacingEndDate;
       if(pacingEndDate==""||pacingEndDate==undefined||pacingEndDate==null)
       {
         pacingDateValidate=false;
         errors["Pacingdate"]=<li>Please select pacing date4</li>;
         pacingSuccess["PacingSuccess"]="";
         this.setState({errors:errors,pacingSuccess:pacingSuccess});
         return;
       }
       else
       {
         pacingDateValidate=true;
         errors["Pacingdate"]="";
         this.setState({errors:errors});
       
  
        // alert("pacingEndDate==>"+pacingEndDate)
         var res = pacingEndDate.split("-");
         var j;var month;var currentEnteredMonth;
         for(j=0;j<res.length;j++)
         {
           var month=res[1];
         }
        //alert("month==>"+month);
        month=parseInt(month);
        if(month===1)
        {
          currentEnteredMonth="January"
        }else
        if(month===2)
        {
          currentEnteredMonth="February"
        }else
        if(month===3)
        {
          currentEnteredMonth="March"
        }else
        if(month===4)
        {
          currentEnteredMonth="April"
        }else if(month===5)
        {
          currentEnteredMonth="May"
        }else
        if(month===6)
        {
          currentEnteredMonth="June"
        }else
        if(month===7)
        {
          currentEnteredMonth="July"
        }else
        if(month===8)
        {
          currentEnteredMonth="August"
        }else
        if(month===9)
        {
          currentEnteredMonth="September"
        }else
        if(month===10)
        {
          currentEnteredMonth="October"
        }else
        if(month===11)
        {
          currentEnteredMonth="November"
        }else
        if(month===12)
        {
          currentEnteredMonth="December"
        }else
        {
  //do nothing
        }
        
  
        // currentEnteredMonth=monthArray[month];
        var pacingMonth=this.state.pacingCampaignDetails[i].pacingMonth;
  
      //  alert("currentEnteredMonth===>"+currentEnteredMonth+"===="+pacingMonth)
  
        if (currentEnteredMonth===pacingMonth)
        {
          pacingCurrentMonthValidate=true
          errors["PacingCurrentMonth"]="";
          this.setState({errors:errors});
       
  //alert("Good");
        }
        else
        {
          pacingCurrentMonthValidate=false;
          errors["PacingCurrentMonth"]=<li>Pacing Month and Entered Date Do Not Match</li>;
          pacingSuccess["PacingSuccess"]="";
          this.setState({errors:errors,pacingSuccess:pacingSuccess});
        //  alert("Bad");
         return;
        }
  
       if ((Date.parse(pacingEndDate)<Date.parse(this.state.campaignDetail[0].startDate))){
         // alert("cant less than start date")
         pacingDateValidate=false;
         errors["Pacingdate"]=<li>Pacing end date can't be less than campaign start date</li>;
         pacingSuccess["PacingSuccess"]="";
         this.setState({errors:errors,pacingSuccess:pacingSuccess});
         return;
  
         }
  
     if ((Date.parse(pacingEndDate)> Date.parse(this.state.campaignDetail[0].endDate))) {
       pacingDateValidate=false;
       // alert(" cant be greater than end date ");
       errors["Pacingdate"]=<li>Pacing end date  can't be greater than campaign end date</li>;
       pacingSuccess["PacingSuccess"]="";
       this.setState({errors:errors,pacingSuccess:pacingSuccess});
       return;
       }
  
       }
       // validate pacingEndDate
  
  
      // alert(pacingDateValidate+"="+countCount+"="+countUndefined+"="+percentageCount+"="+percentageUndefined+"="+leadValidate+"="+pacingCurrentMonthValidate);
       let pacingForm;
       if(pacingDateValidate==false|| countCount==false||countUndefined===false||percentageCount===false||
        percentageUndefined===false||leadValidate===false||pacingCurrentMonthValidate===false)
        {
          pacingForm=false;
          //alert("false")
          this.setState({errors:errors})
        }
        else{
          pacingForm=true;
          pacingSuccess["PacingSuccess"]=<li>Pacing Added Successfully</li>;
           //alert("true");
            this.setState({errors:'',pacingSuccess:pacingSuccess})
        }
      }//end of for
    }
  }// end of addPacingAllocationBack
  validatecustomQuestionBack()
  {let  errors={}
    // alert("at back beinf alled.")
    
    if(this.state.questionDetail==[]||this.state.questionDetail=="")
    {
      errors['validateBack']=<li style={{fontSize:"13px",paddingLeft:"17px"}}>Please Enter Custom Questions </li>
    this.setState({errors:errors})
    }
    else
    if(this.state.questionDetail!=[]||this.state.questionDetail!="")
    {
// alert("allowed..")
errors['validateBack']=""
    this.setState({errors:errors})
    Swal.fire({
              
      text: ("Custom Questions Added Successfully"),
      type: 'success',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
    })
    }
  }
  validatecustomQuestion()
  {
    let  errors={}
    if(this.state.customQuestionsPopup==[]||this.state.customQuestionsPopup=="")
    {
      //alert("please enter Custom Questions") 
      errors['validate']=<li style={{fontSize:"13px",paddingLeft:"17px"}}>Please Enter Custom Questions </li>
    this.setState({errors:errors})
    }
    else
    if(this.state.customQuestionsPopup!=[]||this.state.customQuestionsPopup!="")
    {
// alert("allowed..")
errors['validate']=""
    this.setState({errors:errors})
    Swal.fire({
              
      text: ("Custom Questions Added Successfully"),
      type: 'success',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
    })
    }
  }  
  handleEmpsizeNA(e)
  {
    if(e.target.checked)
    {
      this.setState({empSizeNAValue:'Not_Applicable'})
    //  var span2=document.getElementById('span2');
    //  span2.style.display='none';
    // alert("check")
    this.setState({drpdisabledemp:'drpdisable',inputClassDropDownEmployeeSize:''})
    }
    else
    {
      this.setState({empSizeNAValue:''})
      // var span2=document.getElementById('span2');
      // span2.style.display='inline'; 
      this.setState({drpdisabledemp:''})
    }
  }
  handleEmpsizebackNA(e)
  {
    if(e.target.checked)
    {
      this.setState({empSizeNAValueBack:'Not_Applicable'})
     var span3=document.getElementById('span3');
    //  span3.style.display='none';
    this.setState({drpdisabledemp:'drpdisable',inputClassDropDownEmployeeSize:''})
    }
    else
    {
      this.setState({empSizeNAValueBack:''})
      var span3=document.getElementById('span3');
      // span3.style.display='inline'; 
      this.setState({drpdisabledemp:''})
    }
  }

  handleIndustryNA(e)
  { 
    if(e.target.checked)
    {
      this.setState({industryNAValue:'Not_Applicable'})
      //  alert("checked")
    //  var span1=document.getElementById('span1');
    //  span1.style.display='none';
    this.setState({drpdisabled:'drpdisable',inputClassDropDownIndystry:''})
    
    }
    else
    {
      this.setState({industryNAValue:''})
      // var span1=document.getElementById('span1');
      // span1.style.display='inline';
      this.setState({drpdisabled:'',})

    }
  }
  handleIndustrybackNA(e)
  {
    if(e.target.checked)
    {
      this.setState({industryNAValueBack:'Not_Applicable'})
    //  var span4=document.getElementById('span4');
    //  span4.style.display='none';
    this.setState({drpdisabled:'drpdisable',inputClassDropDownIndystry:''})
    }
    else
    {
      this.setState({industryNAValueBack:''})
    //   var span4=document.getElementById('span4');
    //  span4.style.display='inline';
     this.setState({drpdisabled:''})
     }
  }

 /**
        * @author Sanjana Godbole
        * @param  Description Select creatives
        * @return Description 
      */
  selectCreativesMultipleOption(creativevalue) {
this.setState({
  CreativeValue:creativevalue
})


 var timeline=document.getElementById("timeline");
        var creativeLength=creativevalue.length;
 for(var i=0;i<creativevalue.length;i++)
 {
  if(this.state.marketingChannel==="Email")
  {
    if(creativevalue[i].id==="Newsletter"||creativevalue[i].id==="Landing Page")
{
  creativeArrayLength++
}    else
    {
      //creativevalue.length=0;
      Swal.fire({
              
        text: ("Selected marketing channel is email, So please select Landing Page and Newsletter as a Creative"),
        type: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
    }
  }
 
  if(this.state.marketingChannel==="TeleMarketing" )
  {
    if (creativevalue[i].id==="Landing Page"||creativevalue[i].id==="Newsletter")
    {
   // creativevalue.length=0;
      Swal.fire({
                
        text: ("Selected marketing channel is Telemarketing, So please select Call Script as a Creative"),
        type: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
     
    }
   
  }
  
// if(this.state.marketingChannel==="Display")
// {
//   if(creativevalue[i].id==="Landing Page"||creativevalue[i].id==="POC")
//   {
//     Swal.fire({          
//       text: ("Please select POC and LP"),
//       type: 'error',
//       confirmButtonText: 'Ok',
//       allowOutsideClick: false,
    
//     })
//   } 
// }
// if(this.state.marketingChannel==="Programmatic")
// {
//   if(creativevalue[i].id==="Landing Page"||creativevalue[i].id==="POC")
//   {
//     Swal.fire({          
//       text: ("Please select POC and LP"),
//       type: 'error',
//       confirmButtonText: 'Ok',
//       allowOutsideClick: false,
    
//     })
//   } 
// }
// if(this.state.marketingChannel==="Social")
// {
//   if(creativevalue[i].id==="Landing Page"||creativevalue[i].id==="POC")
//   {
//     Swal.fire({          
//       text: ("Please select POC and LP"),
//       type: 'error',
//       confirmButtonText: 'Ok',
//       allowOutsideClick: false,
    
//     })
//   } 
// }
// if(this.state.marketingChannel==="Hybrid")
// {
//   if(creativevalue[i].id==="Landing Page"||creativevalue[i].id==="POC")
//   {
//     Swal.fire({          
//       text: ("Please select POC and LP"),
//       type: 'error',
//       confirmButtonText: 'Ok',
//       allowOutsideClick: false,
    
//     })
//   } 
// }

}
   
    this.setState({ creativearrayValue: creativevalue });
    creativeArray=this.state.creativearrayValue;
    //alert("New array==>"+JSON.stringify(creativeArray))
    //alert(JSON.stringify(this.state.creativearrayValue))
    var approvedBy=document.getElementById("approvedBy");
    var creativeLength=creativevalue.length;
    // alert("creativeLength===>"+creativeLength);
     if(creativeLength>0)
     {
    //  //  alert("display block")
     timeline.style.display="block";
     approvedBy.style.display="block";
     }
     else 
     {
    //  //  alert("no")
       timeline.style.display="none";
       approvedBy.style.display="none";
     }
  }

 

  selectCreativesMultipleOptionBack(creativevalue) {
//  alert(JSON.stringify(creativevalue));
this.setState({
  CreativeValue:creativevalue
})
var counter2
for(var i=0;i<creativevalue.length;i++)
{
  if(this.state.campaignDetail[0].marketingChannel==="Email")
{
  if(creativevalue[i].id==="Newsletter"||creativevalue[i].id==="Landing Page")
       {
        counter2++
      }
       else
       {
       
         //creativevalue.length=0;
          Swal.fire({
            text: ("Selected marketing channel is email, So please select Landing Page and Newsletter as a Creative"),
            type: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
          })
        }
      
}
}
for(var i=0;i<creativevalue.length;i++)
{
if(this.state.campaignDetail[0].marketingChannel==="TeleMarketing")
  {
    if (creativevalue[i].id==="Call Script")
    {
     creativeArrayLength++
    }

    else
    {
      //creativevalue.length=0;
      Swal.fire({
                
        text: ("Selected marketing channel is Telemarketing, So please select Call Script as a Creative"),
        type: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
    
    }
  }
}
    
    var str = this.state.campaignDetail[0].requiredLPApproval;
    var creativeValueA = [];
    const name="requiredLPApproval"

    for (var j = 0; j < creativevalue.length; j++) {
      creativeValueA.push(creativevalue[j].name);
      
        }

     var campaignDetail = [...this.state.campaignDetail];
     campaignDetail[0] = {...campaignDetail[0], [name]: creativeValueA};
     this.setState({ creatives:creativevalue,creativearrayValue: creativevalue ,campaignDetail:campaignDetail,creativeValueA: creativevalue});
     //alert("campaign details are==>"+JSON.stringify(campaignDetail));
     var timeline=document.getElementById("timeline");
     var approvedBy=document.getElementById("approvedBy");
     var creativeLength=creativevalue.length;
     if(creativeLength>0)
     {
     timeline.style.display="block";
     approvedBy.style.display="block";
     }
     else 
     {
       timeline.style.display="none";
       approvedBy.style.display="none";
     }

   }
//end 




  handleLeadvalidationback(e){

const {name, value } = e.target;
let campaignDetail = [...this.state.campaignDetail];
if(e.target.checked=== true){
  campaignDetail[0] = { ...campaignDetail[0], [name]: 'Yes' };
  }else{
    campaignDetail[0] = { ...campaignDetail[0], [name]: 'No' };
  }
  //alert(JSON.stringify(campaignDetail));
  this.setState({checked3:e.target.checked, campaignDetail ,jobTitleValidate:value});
}



  /**
        * @author Supriya Gore
        * @param  Description handle add the dynamic custom question fields
        * @return Description return all custom question field
      */
     addCustomQuestion(){
      count=count+1;
      
      if(count<=15)
      {
        this.setState({dynamicCustomQuestion:[...this.state.dynamicCustomQuestion,count]})
        
      }
      
    
    }
    /**
        * @author Supriya Gore
        * @param  Description handle remove the dynamic custom question fields
        * @return Description return remaining custom question field
      */
    removeCustomQuestion(i){
      this.state.dynamicCustomQuestion.splice(i,1);
      this.setState({dynamicCustomQuestion:this.state.dynamicCustomQuestion});
      this.state.customQuestionsPopup.splice(i,1);
  
    }

  /**
        * @author Supriya Gore
        * @param  Description handle add the dynamic custom question fields
        * @return Description return all custom question field
      */
     addCustomQuestionBack(){

       var customQuestionID=this.state.customQuestionID;
       var parsed = queryString.parse(this.props.location.search);
            var campID = parsed.campID;
       var length=(this.state.questionDetail).length;
      // alert(length);
      //var count=length+1;
      var customQuesID='';
      //var count=0;
      
      if(customQuestionID==''||customQuestionID==null||customQuestionID==undefined)
      {
        if(length!=0)
        {
          
        customQuesID=this.state.questionDetail[length-1].customQuestionID;
        count=parseInt(customQuesID)+1;

        }else
        {
          count=count;
        }
      }else{
        customQuesID=customQuestionID;
        count=customQuesID;
      }
       
      var customQuesAnsArray=[{'customQuestionID':count,'campID':campID,'customQuestion':'','answer':'','disAllowAnswer':''}];
     
      let finalData=this.state.questionDetail.concat(customQuesAnsArray);
  

      //this.state.questionDetail=[...finalData];
      if(count<=15)
      {
        this.setState({questionDetail:finalData})
      }
      
   
    }
    /**
        * @author Supriya Gore
        * @param  Description handle remove the dynamic custom question fields
        * @return Description return remaining custom question field
      */
     removeCustomQuestionBack(e,i){
     // alert("Remove ======>"+JSON.stringify(this.state.questionDetail));
    
      var customQuestionID=e.target.getAttribute('name');
      // alert(supportDocID)
      this.state.questionDetail.splice(i,1);
      this.setState({questionDetail:this.state.questionDetail});
      if(customQuestionID!==undefined ||customQuestionID!=''){
        // alert(customQuestionID)
      fetch("campaign/deleteCustomQuestion?customQuestionID="+customQuestionID )
      .then(res => res.json())
        .then(customQuestion =>{
      
         this.setState({'customQuestionID':customQuestion});
       }).catch(function(err){console.log(err)});
      }

    }


    

/**
 * @author Supriya Gore
 * @param  Description handle the Dynamic custom questions
 * @return Description return Number of text field
 */
handleCustomQuestion(i,e) {
  const { name, value } = e.target;
//alert( name+" "+value);
  let customQuestionsPopup = [...this.state.customQuestionsPopup];
       customQuestionsPopup[i] = { ...customQuestionsPopup[i], [name]: value };
      
       this.state.customQuestionsPopup=[...customQuestionsPopup];
  // alert("customQuestion  details"+JSON.stringify(this.state.customQuestionsPopup));    
}

/**
 * @author Supriya Gore
 * @param  Description handle the Dynamic custom questions
 * @return Description return Number of text field
 */
handleCustomQuestionBack(i,e) {
 
  const { name, value } = e.target;

  let questionDetail = [...this.state.questionDetail];
  questionDetail[i] = { ...questionDetail[i], [name]: value };
 // alert(JSON.stringify(customEmployeeSizePopupBack));
  //this.setState({ questionDetail });
      
       this.state.questionDetail=[...questionDetail];
       //this.setState({ customQuestionsPopup });
  //   alert("Custom question Back==="+JSON.stringify(questionDetail));
  // alert("Custom question this Back====>" + JSON.stringify(this.state.questionDetail));

}

  // getFields() {
  //   const count = this.state.expand ? 10 : 6;
  //  // const { getFieldDecorator } = this.props.form;
  // //  var questionLength=Object.keys(this.state.customQuestionsPopup).length;
  // //  alert(questionLength)
  //   const children = [];
  //   for (let i = 1; i < 10; i++) {
  //     children.push(
  //       <Col span={8} key={i} style={{ display: i < count ? 'block' : 'none' }}>
  //         <Form.Item><Tag color="#108ee9">{`Question ${i}`}</Tag><TextArea rows={4} id="question" name="question" onChange={this.customQuestionPopupHandleChange.bind(this,i)} />
  //           {/* {getFieldDecorator(`field-${i}`, {
  //             rules: [
  //               {
  //                 required: true,
  //                 message: 'Input something!',
  //               },
  //             ],
  //           })()} */}


  //         </Form.Item>
  //         <Form.Item><Tag color="#f50">{`Answer ${i}`}</Tag><TextArea rows={4} id="answer" name="answer" onChange={this.customAnswerPopupHandleChange.bind(this,i)}/>
  //           {/* {getFieldDecorator(`field-${i}`, {
  //             rules: [
  //               {
  //                 required: true,
  //                 message: 'Input something!',
  //               },
  //             ],
  //           }
  //           )(<Input placeholder="placeholder" />)} */}
        
  //         </Form.Item>
  //       </Col>,
  //     );
  //   }
  //   return children;
  // }


  // /**
  //      * @author Supriya Gore
  //      * @param  Description handle the Custom Question Answer Back
  //      * @return Description return Custom Question Answer
  //      */

  // getFieldsBack() {
  //   const count = this.state.expand ? 10 : 6;
  //  // const { getFieldDecorator } = this.props.form;
  //   const children = [];
  //   let data=[...this.state.questionDetail];
  //   alert("Length=="+this.state.questionDetail.length);
  //   var questionLength=Object.keys(data).length;
   
  //   //var questionLength1=10-questionLength;
  //   // alert("questionData ==="+this.state.questionDetail[4].customQuestion+"::::"+this.state.questionDetail[1].customQuestion);
  //  // alert("questionData ==="+JSON.stringify(this.state.questionDetail));
  //   alert("questionData ==="+JSON.stringify(this.state.customQuestionPopupBack)+":::"+JSON.stringify(this.state.customAnswerPopupBack));
  //   for (let i = 1; i <= questionLength; i++) {
      
  //     children.push(
       
  //       <Col span={8} key={i} style={{ display: i < count ? 'block' : 'none' }}>
       
  //         <Form.Item><Tag color="#108ee9">{`Question ${i}`}</Tag><TextArea rows={4} id="question" name="question" defaultValue={this.state.questionDetail[i-1].customQuestion} onChange={this.customQuestionPopupHandleChangeBack.bind(this,(i-1))} />
        
  //         </Form.Item>
  //         <Form.Item><Tag color="#f50">{`Answer ${i}`}</Tag><TextArea rows={4} id="answer" name="answer" defaultValue={this.state.questionDetail[i-1].answer} onChange={this.customAnswerPopupHandleChangeBack.bind(this,(i-1))}/>
                  
  //         </Form.Item>
         
  //       </Col>,
        
  //     );
  //   }
  
  //   //children=children1.concat(children2);
  //   return children;
  // }

  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };

  

  customHandleChange(e){
            var parsed = queryString.parse(this.props.location.search);
            var campID = parsed.campID;
            if (campID == undefined || campID == null || campID == ""){
                  let fields = this.state;
                  fields[e.target.name] = e.target.value;
                  this.setState({fields});
            }
            else{
                  const { name, value } = e.target;
                  let campaignDetail = [...this.state.campaignDetail];
                  campaignDetail[0] = { ...campaignDetail[0], [name]: value };
                  this.setState({ campaignDetail });
            }
  }


  selectMultipleOption(value) {
   
    var jobLevelValue=[];
    for(var j=0;j<value.length;j++)
    {
   
      jobLevelValue.push(value[j].name+"|");
  
    }
    this.setState({ arrayValue: value,jobLevel1:jobLevelValue });
 
  }


  selectMultipleOptionBack(value)
   {
   
  
    const name="jobLevel";
    var jobLevelValue=[];
    for(var j=0;j<value.length;j++)
    {
 
      if(value[j].name!=="")
      {
          jobLevelValue.push(value[j].name+"|");
      }
      
      
  
    }
    var campaignDetail = [...this.state.campaignDetail];
    campaignDetail[0] = {...campaignDetail[0], [name]: jobLevelValue};
   
    this.setState({ jobLevel: value,jobLevel1:jobLevelValue ,campaignDetail});

       
  }

   /**
       * @author Sanjana Godbole
       * @Creation Date:21-02-2019
       * @param  Handle Change for country 
       * @return 
       */
      countryHandleChange = (value) => 
      {
        var countryvalue=[];
        console.log("countries=="+JSON.stringify(value));
        const name="country";
       //  var countryvalue=[];
        for(var j=0;j<value.length;j++)
        {
          countryvalue.push(value[j].name);
          
        }
        this.setState({ selectedOption2:value,country:value,country1:countryvalue });
      }// end of countryHandleChange

      /**
       * @author Narendra Phadke
       * @Creation Date:21-02-2019
       * @param  Handle Change for country back button and edit
       * @return 
       */
      countryHandleChangeBack = (value) => 
      {
        console.log(`Option Country selected:`, value);
        const name="country";
         var countryvalue=[];
        for(var j=0;j<value.length;j++)
        {
          countryvalue.push(value[j].name);
      
        }
        var campaignDetail = [...this.state.campaignDetail];
        campaignDetail[0] = {...campaignDetail[0], [name]: countryvalue};
        this.setState({selectedOption1:value, country:value,country1:countryvalue,campaignDetail });
     
        
     
      }// end of countryHandleChange
    
      /**
       * @author Narendra Phadke
       * @Creation Date:21-02-2019
       * @param  Handle Change for country back button and edit
       * @return 
       */
      RegionhandleChangeBack (value) 
      {



        
        
    //     let fields = this.state;
    //     fields[e.target.name] = e.target.value;
    //   alert("Change:"+e.target.value+"======>"+JSON.stringify(this.state.country));
    
    //   this.setState({
    //     country: ''
       
    // })
    
    
    
    // // document.getElementById("country").value = "Johnny Bravo";
    
    //     if(this.state.region1 ==='false')
    //     {
    //      this.setState({
    //        inputClassregion: "valid", fields
          
    //    });
    //     }
    //     else{
    //      this.setState({
    //         fields
    //    });    
    //   }
      
      
    
    //   var regionCountryInfo =this.state.regionCountryInfo;
    //   countryArray=[];
    // for (var country in regionCountryInfo[e.target.value])
    //  {
    //  countryArray.push({value:country, label:country});
    //   }
    
    // // alert("Country array:"+JSON.stringify(countryArray));
    // var i;
    // var newcountryArray=[];
    
    //   for(i=0;i<countryArray.length;i++)
    //   {
    //   // alert("Value:- "  + countryArray[i].value + " Label:- "  + countryArray[i].label);
    //     newcountryArray.push({value:countryArray[i].value, label:countryArray[i].label});
    
    //   }
    // this.setState({countryoptions:newcountryArray}, function () {
    //   console.log(JSON.stringify(newcountryArray));
    // });
    
    

  
   
  
       
        const name="region";

        var regionValue=[];
        for(var j=0;j<value.length;j++)
        {
          regionValue.push(value[j].name);
      
        }

       // alert("Back_regionValue"+regionValue);
        var campaignDetail = [...this.state.campaignDetail];
        
        campaignDetail[0] = {...campaignDetail[0], [name]: regionValue};
        this.setState({selectedOption6:value,region:value,regiondb:regionValue,campaignDetail:campaignDetail})
   //  alert("Back campaignDetail"+JSON.stringify(campaignDetail[0].region));
        var regionCountryInfo =this.state.regionCountryInfo;
        countryArray=[];
      
        for(var j=0;j<regionValue.length;j++)
        {
        var regionValues=regionValue[j];
        
      
          for (var country in regionCountryInfo[regionValues])
       {
          countryArray.push({id:country, name:country});
        }
        }
      
      
      ////Sort Array Alphabatically
        countryArray.sort(function(a, b){
          var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase()
          if (nameA < nameB) //sort string ascending
              return -1 
          if (nameA > nameB)
              return 1
          return 0 //default return value (no sorting)
      })
      
    //  alert("Country array:"+JSON.stringify(countryArray));
      var i;
      var newcountryArray=[];
     
      
        for(i=0;i<countryArray.length;i++)
        {
        // alert("Value:- "  + countryArray[i].value + " Label:- "  + countryArray[i].label);
          newcountryArray.push({id:countryArray[i].name, name:countryArray[i].name});
      
        }
       
            
      this.setState({countryoptions:newcountryArray}, function () {
        console.log(JSON.stringify(newcountryArray));
      });
     
      }// end of regionHandleChangeBack
    

  /**
       * @author Narendra Phadke
       * @param  Description handle the upload industry
       * @return Description return All details
       */
  handleuploadIndustry(e)
   {
    e.preventDefault();


    let filesData = e.target.files;
    let filesData1 = e.target.files[0];
    industryFileLength = filesData.length;
   //  alert("Handle Changed:"+JSON.stringify(filesData1));

    if (industryFileLength > 0) {
      this.setState({
        inputClass1: "valid",
        industryFileData: filesData1
      })
    }

  }

  /**
       * @author Narendra Phadke
       * @param  Description handle the Back Button upload industry
       * @return Description return All details
       */
      handleUploadIndustryBack(e)
      {
        e.preventDefault();
  
     //  var industryFileLength;
       const name="industryFile";
      
     
       let filesData1 = e.target.files[0];
       let fileName1=filesData1.name;
      
        //delete fileName from campaignDetails Array
        let campaignDetail=this.state.campaignDetail[0];
        delete campaignDetail['industryFileName'];
       //var industryFileName="industryFileName";
       console.warn(JSON.stringify(campaignDetail));
    
        campaignDetail.industryFileName=fileName1;
        industryFileLength=campaignDetail.industryFileName.length;
        // alert("Length on back:"+industryFileLength);


        if (industryFileLength > 0) {
          this.setState({
            inputClass1: "valid",
            //industryFileData: filesData1
          })
        }
    

var campID=this.state.campaignDetail[0].campID;
        var data = new FormData();  
      data.append("campID",campID);
      data.append("file",filesData1);
          //campaign/supportingDocument
          fetch("/campaign/backButtonUpdateIndustryFile",{
            method: 'POST',
        
            //mode:'no-cors',
            body: data
        
          });
  
     }

      /**
       * @author Narendra Phadke
       * @param  Description handle the Back Button upload lead delivery format
       * @return Description return All details
       */
      handleUploadLeadDeliveryFormatBack(e)
      {
        e.preventDefault();

       let filesData1 = e.target.files[0];
       let fileName1=filesData1.name;
      
      

        //delete fileName from campaignDetails Array
        let campaignDetail=this.state.campaignDetail[0];
        delete campaignDetail['leadDeliveryFileName'];
       //var industryFileName="industryFileName";
       console.warn(JSON.stringify(campaignDetail));
      // alert( campaignDetail.leadDeliveryFileName=fileName1);

        campaignDetail.leadDeliveryFileName=fileName1;
        console.warn("Handle Changed 11:"+JSON.stringify(campaignDetail));
        //this.setState(campaignDetail=>{[...campaignDetail]});

        var campID=this.state.campaignDetail[0].campID;
        var data = new FormData();  
      data.append("campID",campID);
      data.append("file",filesData1);
          //campaign/supportingDocument
          fetch("/campaign/backButtonUpdateLeadDeliveryFile",{
            method: 'POST',
        
            //mode:'no-cors',
            body: data
        
          });
  
     }

     /**
       * @author Supriya Gore
       * @param  Description handle the client name
       * @return Description return client name
       */
      handleClientChange(e) {
        e.preventDefault();
        let clientName = e.target.value;
     
        this.setState({
          clientName: clientName
        })
    
      }

       /**
       * @author Supriya Gore
       * @param  Description handle the client name Back button
       * @return Description return client name Back Button
       */
      handleClientChangeBack(e) {
        e.preventDefault();
        let clientName = e.target.value;
        const name="clientName";
      
        var campaignDetail = [...this.state.campaignDetail];
        campaignDetail[0] = {...campaignDetail[0], [name]: clientName};
        this.setState({
          clientName: clientName,campaignDetail:campaignDetail
        })
    
      }


/**
       * @author Narendra Phadke
       * @param  Description handle the upload format
       * @return Description return All details
       */
      handleUploadChange(e) {
        e.preventDefault();
        let filesData = e.target.files[0];
        
        this.setState({
          uploadFormatFile: filesData
        })
    
      }

   /**
 * @author Narendra Phadke
 * @param  Description handle the region selected dynamic
 * @return Description return successfully selected region
 */
  RegionhandleChange(value)
  {   var regionValue = [];
   var diselectedvalue='';
      const name="jobFunction";
        for(var j=0;j<value.length;j++)
        {
                    regionValue.push(value[j].name);  
                   
                  }
        //alert("the value is "+JSON.stringify(value))         
         //alert("the old array--->"+regionValue);
          
         //let fields = this.state;
       //fields[e.target.name] = e.target.value;
   //alert("Change:"+e.target.value+"======>"+JSON.stringify(this.state.country));
  
    this.setState({selectedOption6:value,region:value,regiondb:regionValue})
    if(value.length<this.state.region.length)
  {
    //alert("elements are less..")
    for(var s=0;s<this.state.region.length;s++)
    {
      for(var r=0;r<value.length;r++)
      {
        if(value[r]===this.state.region[s])
        {
          //alert("we have this value"+JSON.stringify(this.state.region[s]))
        }
        else
        {
          diselectedvalue=this.state.region[s]

         // this.setState({country:[],country1:[],regiondb:[]})
          //alert("we want this value"+JSON.stringify(this.state.region[s]))
        }
        
      } 
    }
    //alert("this is dis selected value"+JSON.stringify(diselectedvalue))
  }
  // // document.getElementById("country").value = "Johnny Bravo";
  
    //   if(this.state.region1 ==='false')
    //   {
    //    this.setState({
    //      inputClassregion: "valid"
    //         //, fields
        
    //  });
    //   }
    //   else{
    //    this.setState({
    //      // fields
    //  });    
    // }
    
    
  
    var regionCountryInfo =this.state.regionCountryInfo;
    countryArray=[];
  
    for(var j=0;j<regionValue.length;j++)
    {
    var regionValues=regionValue[j];
    
      for (var country in regionCountryInfo[regionValues])
   {
      countryArray.push({id:country, name:country});
    }
    }
  
  
  ////Sort Array Alphabatically
    countryArray.sort(function(a, b){
      var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase()
      if (nameA < nameB) //sort string ascending
          return -1 
      if (nameA > nameB)
          return 1
      return 0 //default return value (no sorting)
  })
  
  var i;
  var newcountryArray=[];
  
    for(i=0;i<countryArray.length;i++)
    {
    // alert("Value:- "  + countryArray[i].value + " Label:- "  + countryArray[i].label);
      newcountryArray.push({id:countryArray[i].name, name:countryArray[i].name});
 
    }


  
  this.setState({countryoptions:newcountryArray}, function () {
    //alert(JSON.stringify(newcountryArray));
    //alert("country options "+JSON.stringify(this.state.countryoptions))
  });
 
  
}//end of RegionhandleChange







 /**
       * @author Sanjana Godbole
       * @Creation Date:21-02-2019
       * @param  Handle Change for  Job Level
       * @return 
   */
  jobLevelhandleChange= (selectedOption) => 
  {
  // alert(JSON.stringify(selectedOption.length));

     console.log(`Option selected:`, selectedOption);
  var jobLevelvalue=[];
  const name="jobLevel";
 
    for(var j=0;j<selectedOption.length;j++)
    {
       // const  value = selectedOption.target.value;
       jobLevelvalue.push(selectedOption[j].value);   
    }
     
    
    this.setState({ selectedOption3:selectedOption,jobLevel:selectedOption ,jobLevel1:jobLevelvalue});
  }
  
/**
       * @author Narendra Phadke
       * @Creation Date:21-02-2019
       * @param  Handle Change for  Job Level back and edit
       * @return 
   */
  jobLevelhandleChangeBack= (selectedOption) => 
  {
     console.log(`Option selected:`, selectedOption);
 var jobLevelvalue=[];
  const name="jobLevel";
 
    for(var j=0;j<selectedOption.length;j++)
    {
       // const  value = selectedOption.target.value;
       jobLevelvalue.push(selectedOption[j].value);

    }


    
        var campaignDetail = [...this.state.campaignDetail];
        campaignDetail[0] = {...campaignDetail[0], [name]: jobLevelvalue};
    this.setState({ jobLevel:selectedOption , campaignDetail:campaignDetail});
  }


  /**
      * @author Narendra Phadke
      * @Creation Date:04-03-2019
      * @param  Handle Change for  Job Function
      * @return
  */
 jobFunctionhandleChangeif= (value) => 
  {
     console.log(`Option selected:`, value);
  // var jobLevelvalue=[];
  // let jobFunction1=this.state.campaignDetail[0].jobFunction;
  
   var jobFunctionvalue = [];
  const name="jobFunction";
    for(var j=0;j<value.length;j++)
    {
    
       // const  value = selectedOption.target.value;
       jobFunctionvalue.push(value[j].name+"|");  
    }
 
    this.setState({ selectedOption4:value,jobFunction:value, jobFunction1:jobFunctionvalue});
  }




 jobFunctionhandleChange = (value) => {
  console.log(`Option selected:`, value);
 
  const name="jobFunction";
  var jobFunctionvalue = [];
  for (var j = 0; j < value.length; j++) {
  
    jobFunctionvalue.push(value[j].name+"|");

 }
 

  var campaignDetail = [...this.state.campaignDetail];
      campaignDetail[0] = {...campaignDetail[0], [name]: jobFunctionvalue};
  
  this.setState({ jobFunction: value, campaignDetail:campaignDetail });
}

/**
      * @author Supriya Gore
      * @Creation Date:04-03-2019
      * @param  Handle Change for  industry
      * @return
  */
 industryhandleChange= (value) => 
  {
    //alert(`Option selected:`+ JSON.stringify(value))
     console.log(`Option selected:`, value);
  
   var industryValue = [];
  const name="industry";
    for(var j=0;j<value.length;j++)
    {
       industryValue.push(value[j].name+"|");  
    }
    
    this.setState({ selectedOptionIndustry4:value,industry:value, industry1:industryValue});
  }


/**
      * @author Supriya Gore
      * @Creation Date:04-03-2019
      * @param  Handle Change for  industry for back
      * @return
  */
 industryhandleChangeBack= (value) => 
  {
    console.log(`Option selected:`, value);
  
    var industryValue = [];
   const name="industry";
     for(var j=0;j<value.length;j++)
     {
        industryValue.push(value[j].name+"|");  
     }
   

        var campaignDetail = [...this.state.campaignDetail];
        campaignDetail[0] = {...campaignDetail[0], [name]: industryValue};
    this.setState({ industry:value , campaignDetail:campaignDetail});
  }

  /**
       * @author Sanjana Godbole
       * @Creation Date:26-02-2019
       * @param  Handle Change for  Emp Size
       * @return 
   */

  employeehandleChange = (value) => 
  {

   
    // this.setState({ jobLevel:selectedOption });
    console.log(`Option selected:`, value);
  var employeeSizevalue=[];
  const name="employeeSize";
    for(var j=0;j<value.length;j++)
    {
      employeeSizevalue.push(value[j].name);
    }
    // alert("size===>"+employeeSizevalue);
    this.setState({ selectedOption1:value,employeeSize:value ,employeeSize1:employeeSizevalue});
  }



  

  revenuehandleChange = (value) => 
  {
   console.log(`Option selected:`, value);
    var companyRevenuevalue=[];
    const name="companyRevenue";
    for(var j=0;j<value.length;j++)
    {
      companyRevenuevalue.push(value[j].name);
     
    }

  this.setState({ selectedOption5:value,companyRevenue:value ,companyRevenue1:companyRevenuevalue});
  }

 /**
       * @author Narendra Phadke
       * @Creation Date:26-02-2019
       * @param  Handle Change for  Emp Size for back and edit
       * @return 
   */
  employeehandleChangeBack = (value) => 
  {

    // this.setState({ jobLevel:selectedOption });
    console.log(`Option selected:`, value);
 var employeeSizevalue=[];
  const name="employeeSize";
    for(var j=0;j<value.length;j++)
    {
      employeeSizevalue.push(value[j].name);
    }
    //create dynamic array
    var campaignDetail = [...this.state.campaignDetail];
    campaignDetail[0] = {...campaignDetail[0], [name]: employeeSizevalue};

    this.setState({ employeeSize:value ,campaignDetail:campaignDetail});
  }


  revenuehandleChangeBack = (value) => 
  {
    console.log(`Option selected:`, value);
 var companyRevenuevalue=[];
  const name="companyRevenue";
    for(var j=0;j<value.length;j++)
    {
      companyRevenuevalue.push(value[j].name);
    }
    //create dynamic array
    var campaignDetail = [...this.state.campaignDetail];
    campaignDetail[0] = {...campaignDetail[0], [name]: companyRevenuevalue};

    this.setState({ companyRevenue:value ,campaignDetail:campaignDetail});
   
  }

   /**
       * @author Narendra Phadke
       * @param  Description handle the Custom Employee Size text field
       * @return Description return Custom Employee Size
       */
       
  customEmployeeSizePopupHandleChange(i,e)
      {
          var name=e.target.name;
         var value=e.target.value;

          let customEmployeeSize = [...this.state.customEmployeeSizePopUpText];
          customEmployeeSize[i] = { ...customEmployeeSize[i], [name]: value };
        
          this.setState({ customEmployeeSizePopUpText: customEmployeeSize});
      }

        /**
       * @author Narendra Phadke
       * @param  Description handle the Custom Employee Size text field
       * @return Description return Custom Employee Size
       */
       
      customEmployeeSizePopupHandleChangeBack(i,e)
      {
          var name=e.target.name;
         var value=e.target.value;

          let customEmployeeSize = [...this.state.customEmployeeSizePopupBackText];
          customEmployeeSize[i] = { ...customEmployeeSize[i], [name]: value };
         
          this.setState({ customEmployeeSizePopupBackText: customEmployeeSize});
      }
      /**
       * @author Narendra Phadke
       * @param  Description handle change for popup from
       */
      customEmployeeSizeFromPopupHandleChange(i,e)
      {
        var {name,value}=e.target;
        let customEmployeeSizeFromPopup = [...this.state.customEmployeeSizeFromPopup];
        customEmployeeSizeFromPopup[i] = { ...customEmployeeSizeFromPopup[i], [name]: value };
        this.setState({ customEmployeeSizeFromPopup: customEmployeeSizeFromPopup});
      }
    

      /**
       * @author Narendra Phadke
       * @param  Description handle change for popup to
       */
      customEmployeeSizeToPopupHandleChange(i,e)
      {
        var {name,value}=e.target;
        let customEmployeeSizeToPopup = [...this.state.customEmployeeSizeToPopup];
        customEmployeeSizeToPopup[i] = { ...customEmployeeSizeToPopup[i], [name]: value };
        this.setState({ customEmployeeSizeToPopup: customEmployeeSizeToPopup});
        // this.state.customEmployeeSizeToPopup[name1]=value;
      }

      /**
       * @author Narendra Phadke
       * @param  Description handle change for popup from
       */
      customEmployeeSizeFromPopupHandleChangeBack(i,e)
      {
        var {name,value}=e.target;
        let customEmployeeSizeFromPopup = [...this.state.customEmployeeSizePopupBackFromTo];
        customEmployeeSizeFromPopup[i] = { ...customEmployeeSizeFromPopup[i], [name]: value };
        this.setState({customEmployeeSizePopupBackFromTo: customEmployeeSizeFromPopup});
      }

      // /**
      //  * @author Narendra Phadke
      //  * @param  Description handle change for popup to
      //  */
      // customEmployeeSizeToPopupHandleChangeBack(i,e)
      // {
      //   var {name,value}=e.target;
      //   let customEmployeeSizeToPopup = [...this.state.customEmployeeSizePopupBackTo];
      //   customEmployeeSizeToPopup[i] = { ...customEmployeeSizeToPopup[i], [name]: value };
      //   this.setState({customEmployeeSizePopupBackTo: customEmployeeSizeToPopup});
      //   // this.state.customEmployeeSizeToPopup[name1]=value;
      // }
/**
          * @author Sanobar Golandaj
          * @param  Description handle adding dynamic custom employee fields
          * @return Description return custom employee field
        */

      addDynamicEmployeeSize(){
        count=count+1;
         var customEmployeeSize=[{'From':"",'To':"",count:count}];
         let finalDataText=this.state.customEmployeeSizeFromPopup.concat(customEmployeeSize);
        this.setState({customEmployeeSizeFromPopup:finalDataText})
       
      }
      /**
          * @author Narendra Phadke
          * @param  Description handle add the dynamic custom employee fields
          * @return Description return remaining customemployee field
        */
       addDynamicEmployeeSizeTextBack(e){
        var count=this.state.customEmployeeSizePopupBackText.length;
        count=count+1;
        var customEmployeeSizeText=[{'customEmployeeSizeText':"",count:count}];
        let finalDataText=this.state.customEmployeeSizePopupBackText.concat(customEmployeeSizeText);
        this.setState({customEmployeeSizePopupBackText:finalDataText})
       
      }
      /**
          * @author Narendra Phadke
          * @param  Description handle remove the dynamic custom employee fields
          * @return Description return remaining customemployee field
        */
      removeDynamicEmployeeSize(i){
        this.state.customEmployeeSizeFromPopup.splice(i,1);
        this.setState({customEmployeeSizeFromPopup:this.state.customEmployeeSizeFromPopup});
      //  this.state.dynamicEmployeeSize.splice(i,1);
      }


      addDynamicEmployeeSizeBack(e){
        var count=this.state.customEmployeeSizePopupBackFromTo.length;
        count=count+1;
        var customEmployeeSizeFrom=[{'From':"",'To':"",count:count}];
        let finalDataFrom=this.state.customEmployeeSizePopupBackFromTo.concat(customEmployeeSizeFrom);
      this.setState({customEmployeeSizePopupBackFromTo:finalDataFrom})
        // this.setState({dynamicEmployeeSize:[...this.state.dynamicEmployeeSize,count]})
       
      }

      addDynamicEmployeeSizetxt(e){
        count=count+1;
        var customEmployeeSize=[{'customEmployeeSizeText':"",count:count}];
        let finalDataFrom=this.state.customEmployeeSizePopUpText.concat(customEmployeeSize);
        this.setState({customEmployeeSizePopUpText:finalDataFrom})
       
      }

       /**
          * @author Narendra Phadke
          * @param  Description handle add the dynamic custom employee fields
          * @return Description return remaining customemployee field
        */
       addDynamicEmployeeSizeBackText(e){
         var count=this.state.customEmployeeSizePopupBackText.length;
        count=count+1;
        var customEmployeeSizeText=[{'customEmployeeSizeText':"",count:count}];
        let finalDataText=this.state.customEmployeeSizePopupBackText.concat(customEmployeeSizeText);
        this.setState({customEmployeeSizePopupBackText:finalDataText})
       
      }
      /**
          * @author Sanobar Golandaj
          * @param  Description handle remove the dynamic custom employee fields
          * @return Description return remaining customemployee field
        */
      removeDynamicEmployeeSizetxt(e,i){
        this.state.customEmployeeSizePopUpText.splice(i,1);
        this.setState({customEmployeeSizePopUpText:this.state.customEmployeeSizePopUpText});
       // this.state.dynamicEmployeeSizetxt.splice(i,1);
      }


      removeDynamicEmployeeSizeBackText(e,i){
        this.state.customEmployeeSizePopupBackText.splice(i,1);
        this.setState({customEmployeeSizePopupBackText:this.state.customEmployeeSizePopupBackText});
       // this.state.dynamicEmployeeSizetxt.splice(i,1);
      }

      /**
          * @author Narendra Phadke
          * @param  Description handle remove the dynamic custom employee fields
          * @return Description return remaining customemployee field
        */
       removeDynamicEmployeeSizeBack(e,i){
       
        // count=count-1;
        this.state.customEmployeeSizePopupBackFromTo.splice(i,1);
        this.setState({customEmployeeSizePopupBackFromTo:this.state.customEmployeeSizePopupBackFromTo});
       }
      /**
       * @author Sanobar  Golandaj,Narendra phdke,Sanjana  Godbole
       * @param  Description validations for employee size
       */
      addEmpSize(e)
      {
       
     
        let  reerrors={}
      

        let EmployeeSize=[...this.state.customEmployeeSizeFromPopup];
        var dynamicEmployeeSizeLength =EmployeeSize.length;
// alert(dynamicEmployeeSizeLength)
        var customEmployeeSizeText=[...this.state.customEmployeeSizePopUpText];
        var customEmployeeSizeTextLength =(Object.keys(customEmployeeSizeText).length);

        //alert("customEmployeeSizeTextLength===>"+customEmployeeSizeTextLength);
        //alert("dynamicEmployeeSizeLength===>"+dynamicEmployeeSizeLength);

        var flagFromTo=true;
        var flagText=true;
      if(dynamicEmployeeSizeLength >0 && customEmployeeSizeTextLength> 0){
        if(dynamicEmployeeSizeLength > 1 || customEmployeeSizeTextLength > 1){
          // alert("Inside loop of array have values");
          if(dynamicEmployeeSizeLength > 1){
          for(var i=0;i<dynamicEmployeeSizeLength;i++){
            var From = EmployeeSize[i].From.replace(/,/g, '');
            var TO = EmployeeSize[i].To.replace(/,/g, '');
            
                    if(EmployeeSize[i].From==="" || EmployeeSize[i].To==="")
                    {
                       //alert("Please Enter From and To value");
                      flagFromTo=false;
                      reerrors["fromToMsg"]=<li>Please Enter From and To value For Employee Size Range</li>;
                      this.setState({reerrors:reerrors});
                    }
                    else
                    {
                      var pattern = new RegExp(/^[0-9,]+$/);
                      if(!pattern.test(EmployeeSize[i].From) || !pattern.test(EmployeeSize[i].To))
                      {
                        // alert("Please enter only number");
                        reerrors["onlyNumMsg"]=<li>Please Enter Numeric Values Only For Employee Size Range</li>;
                      this.setState({reerrors:reerrors});
                        flagFromTo=false;
                        empsizeEmpty=false;
                        return;
                      }
                      else{
                  
                        reerrors["onlyNumMsg"]="";
                        this.setState({reerrors:reerrors});
                          flagFromTo=true;
                          empsizeEmpty=true;

                              //Check range of from value and to value
              if(parseInt(From)>=(parseInt(TO)))
              {
                 // alert("Please enter to value greater than from value");
                 reerrors["greaterMsg"]=<li>Please Enter To Value Greater Than From Value For Employee Size Range</li>;
                 this.setState({reerrors:reerrors});
                 flagFromTo=false;
                 empsizeEmpty=false;
                 return;
              }
              else
              {

                 // alert("From less than to"+EmployeeSize[0].From+"==="+EmployeeSize[0].To);
                 reerrors["greaterMsg"]="";
                 this.setState({reerrors:reerrors});
                 flagFromTo=true;
                 empsizeEmpty=true;
              
              }
              if(EmployeeSize[i].From.includes(',')||EmployeeSize[i].To.includes(','))
            {
              //alert("true");
              
                if(From<999 || TO<999)
                {
                  //alert("comma not allowed here..")
                  reerrors["commaNotallowed"]=<li>comma not allowed For Employee Size Range</li>;
                  this.setState({reerrors:reerrors});
                    flagFromTo=false;
                    empsizeEmpty=false;
                }
            }
                      }
                     
                    }
               }//end for loop 
              }else{
                //check condition if from array not greater than one
                if(EmployeeSize[0].From==="" || EmployeeSize[0].To===""){
                  // alert("Please Enter From and To value");
                   flagFromTo=false;
                  reerrors["fromToMsg"]=<li>Please Enter From And To Value For Employee Size Range</li>;
                  this.setState({reerrors:reerrors});
                }else{
                  var pattern = new RegExp(/^[0-9,]+$/);
                  if(!pattern.test(EmployeeSize[0].From) || !pattern.test(EmployeeSize[0].To))
                  {
                  
                    reerrors["onlyNumMsg"]=<li>Please Enter Numeric Values Only For Employee Size Range</li>;
                  this.setState({reerrors:reerrors});
                    flagFromTo=false;
                    empsizeEmpty=false;
                  }
                  else{
                    // alert("Please enter only number");
                    reerrors["onlyNumMsg"]="";
                  this.setState({reerrors:reerrors});
                    flagFromTo=true;
                    empsizeEmpty=true;

                         //Check range of from value and to value
              if(parseInt(From)>=(parseInt(TO)))
              {
                 // alert("Please enter to value greater than from value");
                 reerrors["greaterMsg"]=<li>Please Enter To Value Greater Than From Value For Employee Size Range</li>;
                 this.setState({reerrors:reerrors});
                 flagFromTo=false;
                 empsizeEmpty=false;
                 return;
              }
              else
              {

                 // alert("From less than to"+EmployeeSize[0].From+"==="+EmployeeSize[0].To);
                 reerrors["greaterMsg"]="";
                 this.setState({reerrors:reerrors});
                 flagFromTo=true;
                 empsizeEmpty=true;
              
              }
              if(EmployeeSize[0].From.includes(',')||EmployeeSize[0].To.includes(','))
            {
              //alert("true");
              
                if(From<999 || TO<999)
                {
                  //alert("comma not allowed here..")
                  reerrors["commaNotallowed"]=<li>Comma Not allowed For Employee Size Range</li>;
                  this.setState({reerrors:reerrors});
                    flagFromTo=false;
                    empsizeEmpty=false;
                }
            }
                  }
                
                }

            }


              //text value field validation
              if(customEmployeeSizeTextLength > 1){
                for(var j=0;j<customEmployeeSizeTextLength;j++){
                  //check all condition of text fields
                  var custEmpsize=customEmployeeSizeText[j].customEmployeeSizeText.replace(/,/g, '');
                  //alert(custEmpsize)
              if(custEmpsize===""){
                      //alert("Please enter value in text 1");
                      reerrors["onlyTextMsg"]=<li>Please Enter Numeric Values Only For Employee Size Value</li>;
                      this.setState({reerrors:reerrors});
                      flagText=false;
                      empsizeEmpty=false;
              }else{
                var pattern = new RegExp(/^[0-9,+<>]+$/);
                if(!pattern.test(custEmpsize))
                {
                   //alert("Please enter only number in text 2");
                  reerrors["onlyTextMsg"]=<li>Please Enter Numeric Values Only For Employee Size Value</li>;
                  this.setState({reerrors:reerrors});
                  flagText=false;
                  empsizeEmpty=false;
                }
                else
                {
                  reerrors["onlyTextMsg"]="";
                  this.setState({reerrors:reerrors});
                  flagText=true;
                  empsizeEmpty=true;
                }
              }
              }
              }else{
                if(custEmpsize===""){
                  reerrors["onlyTextMsg"]="";
                  this.setState({reerrors:reerrors});
                  flagText=false;
                }else{
                  var pattern = new RegExp(/^[0-9,+<>]+$/);
                  if(!pattern.test(custEmpsize))
                  {
                     //alert("Please enter only number in text 3");
                    reerrors["onlyTextMsg"]=<li>Please Enter Numeric Values Only For Employee Size Value</li>;
                    this.setState({reerrors:reerrors});
                    flagText=false;
                    empsizeEmpty=false;
                  }
                  else
                  { 
                    reerrors["onlyTextMsg"]="";
                      this.setState({reerrors:reerrors});
                      flagText=true;
                      empsizeEmpty=true;
                  }
                }
              }
              

        }else{



          
          //check all three are blank
        //  alert("Text box value=="+customEmployeeSizeText[0].customEmployeeSizeText);
        //   alert("EmployeeSize[0]=="+EmployeeSize[0].From);
        //    alert("EmployeeSize[0]=="+EmployeeSize[0].To);
          if((EmployeeSize[0].From==="" || EmployeeSize[0].To==="") && customEmployeeSizeText[0].customEmployeeSizeText===""){
            flagFromTo=false;
            flagText=false;
            
            reerrors["fromToMsg"]=<li> Please Enter Either Employee Size Range Or Value</li>;
            this.setState({reerrors:reerrors});
            // {alert(this.state.buttonDisplayEmp);}
          }else{
            var From2 = EmployeeSize[0].From.replace(/,/g, '');
                var TO2 = EmployeeSize[0].To.replace(/,/g, '');
                var custEmpsize2= customEmployeeSizeText[0].customEmployeeSizeText.replace(/,/g, '');
                // alert(custEmpsize2)
                //alert(From+''+TO)
                //alert("in else");
            if(EmployeeSize[0].From==="" || EmployeeSize[0].To==="")
            {
              flagFromTo=false;
            }else{
              var pattern = new RegExp(/^[0-9,]+$/);
              if(!pattern.test(EmployeeSize[0].From) || !pattern.test(EmployeeSize[0].To))
              {
                //alert("Please enter only number");
                reerrors["onlyNumMsg"]=<li>Please Enter Numeric Values Only For Employee Size Range</li>;
              this.setState({reerrors:reerrors});
           
              { 
                // alert(this.state.buttonDisplayEmp);
              }
                flagFromTo=false;
                empsizeEmpty=false;
              }
              else{
                reerrors["onlyNumMsg"]="";
                this.setState({reerrors:reerrors,});
                  flagFromTo=true;
                    //Check range of from value and to value
              if(parseInt(From2)>=(parseInt(TO2)))
              { 
                
                //alert(parseInt(EmployeeSize[0].From)+""+(parseInt(EmployeeSize[0].To)))
                 // alert("Please enter to value greater than from value");
                 reerrors["greaterMsg"]=<li>Please Enter To Value Greater Than From Value</li>;
                 this.setState({reerrors:reerrors});
                 flagFromTo=false;
                 empsizeEmpty=false;
                 return;
              }
              else
              {

                 // alert("From less than to"+EmployeeSize[0].From+"==="+EmployeeSize[0].To);
                 reerrors["greaterMsg"]="";
                 this.setState({reerrors:reerrors});
                 flagFromTo=true;
                 empsizeEmpty=true;
                 empsizeEmpty=true;
              
              }
              if(EmployeeSize[0].From.includes(',')||EmployeeSize[0].To.includes(','))
            {
              //alert("true");
              
                if(From2<999 || TO2<999)
                {
                  //alert("comma not allowed here..")
                  reerrors["commaNotallowed"]=<li>Comma not allowed For Employee Size Reange .</li>;
                  this.setState({reerrors:reerrors});
                    flagFromTo=false;
                    empsizeEmpty=false;
                }
            }
              
            }//uncomment from here
             }
              //check all condition of text fields
              if(custEmpsize2===""){
                flagText=false;
              }else{
                var pattern = new RegExp(/^[0-9,+<>]+$/);
                if(!pattern.test(custEmpsize2))
                {
                   //alert("Please enter only number in text");
                  reerrors["onlyTextMsg"]=<li>Please Enter Numeric Values Only For Employee Size Value</li>;
                  this.setState({reerrors:reerrors});
                  flagText=false;
                  empsizeEmpty=false;
                }
                else
                {
                  reerrors["onlyTextMsg"]="";
                  this.setState({reerrors:reerrors});
                  flagText=true;
                  empsizeEmpty=true;
                }
              }
          }

        }
      }else{
        // alert("here")
        if(dynamicEmployeeSizeLength==0)
        {
          if(customEmployeeSizeText[0].customEmployeeSizeText!=="")
          {
            // alert("has value should allow")
            reerrors["success"]=<li>Successfully Entered Custom Employee Size Value</li>;
        this.setState({reerrors:reerrors});
        empsizeEmpty=true;

          }
          else
          {
            // alert("has no value should not allow")
            reerrors["success"]=""
            reerrors["fromToMsg"]=<li> Please Enter Either Employee Size Range Or Value</li>;
            this.setState({reerrors:reerrors});
            flagFromTo=false;
            flagText=false;
          }
        }
        // flagFromTo=false;
        // flagText=false;
        //     reerrors["fromToMsg"]=<li>Please Enter Either Employee Size Range Or Value</li>;
        //     this.setState({reerrors:reerrors});
      }
   

      this.setState({
        flagFromToState:flagFromTo,
        flagTextState:flagText
      });

      if(flagFromTo==true)
      {
        reerrors["success"]=<li>Successfully Entered Custom Employee Size Range</li>;
        this.setState({reerrors:reerrors});
        empsizeEmpty=true;
      }
      else
      {
        reerrors["success"]="";
      
        this.setState({reerrors:reerrors});
      }
      if(flagText==true)
      {
       // alert(flagText);
        reerrors["successText"]=<li>Successfully Entered Custom Employee Size Value</li>;
        this.setState({reerrors:reerrors});
        empsizeEmpty=true;
      }
      else
      {
     
        reerrors["successText"]="";
        this.setState({reerrors:reerrors});
      }
  
         } // end of addEmpSize()
        
  
/**
       * @author Supriya Gore
       * @param  Description handle the Custom Employee Size Back for From value
       * @return Description return Custom Employee Size
       */
      customEmpSizePopupHandleChangeBackFrom(i,e)
      {
      
      //  alert(e.target.value);
      //  let fields = this.state;
      //  fields[e.target.name] = e.target.value;
         var name="from"
        const { value } = e.target;
          
            let customEmployeeSizePopupBackFromTo = [...this.state.customEmployeeSizePopupBackFromTo];
            customEmployeeSizePopupBackFromTo[i] = { ...customEmployeeSizePopupBackFromTo[i], [name]: value };
          //  alert(JSON.stringify(customEmployeeSizePopupBackFromTo));
            this.setState({ customEmployeeSizePopupBackFromTo });
         }
    
      /**
           * @author Supriya Gore
           * @param  Description handle the Custom Employee Size Back for To value
           * @return Description return Custom Employee Size
           */
      customEmpSizePopupHandleChangeBackTo(i,e)
      {
 
     //  alert(e.target.value);
      //  let fields = this.state;
      //  fields[e.target.name] = e.target.value;
         var name="to"
        const { value } = e.target;
          
            let customEmployeeSizePopupBackTo = [...this.state.customEmployeeSizePopupBackTo];
            customEmployeeSizePopupBackTo[i] = { ...customEmployeeSizePopupBackTo[i], [name]: value };
           // alert(JSON.stringify(customEmployeeSizePopupBack));
            this.setState({ customEmployeeSizePopupBackTo });
            // var questionLength=this.state.customEmployeeSizePopupBackTo.length;
            // alert("questionLength===>"+questionLength)
           }
    

  /**
       * @author Sanobar  Golandaj,Sanjana  Godbole
       * @param  Description validations for employee size
       */
 addEmpSizeBack(e)
 {
  let  reerrors={}
      

        let EmployeeSize=[...this.state.customEmployeeSizePopupBackFromTo];
        var dynamicEmployeeSizeLength =EmployeeSize.length;

        var customEmployeeSizeText=[...this.state.customEmployeeSizePopupBackText];
        var customEmployeeSizeTextLength =(Object.keys(customEmployeeSizeText).length);

        // alert("customEmployeeSizeTextLength===>"+customEmployeeSizeTextLength);
        // alert("dynamicEmployeeSizeLength===>"+dynamicEmployeeSizeLength);

        var flagFromTo=true;
        var flagText=true;

      if(dynamicEmployeeSizeLength >0 && customEmployeeSizeTextLength> 0){
        if(dynamicEmployeeSizeLength > 1 || customEmployeeSizeTextLength > 1){
          //  alert("Inside loop of array have values");
          if(dynamicEmployeeSizeLength > 1){
          for(var i=0;i<dynamicEmployeeSizeLength;i++){
            var Fromback = EmployeeSize[i].From.replace(/,/g, '');
            var TOback = EmployeeSize[i].To.replace(/,/g, '');
                    if(EmployeeSize[i].From==="" || EmployeeSize[i].To==="")
                    {
                      //  alert("Please Enter From and To value 1");
                      flagFromTo=false;
                      reerrors["fromToMsg"]=<li>Please Enter From and To value For Employee Size Range</li>;
                      this.setState({reerrors:reerrors});
                      empsizeEmptyBack=true;
                    }

                    else
                    {
                      var pattern = new RegExp(/^[0-9,]+$/);
                      if(!pattern.test(EmployeeSize[i].From) || !pattern.test(EmployeeSize[i].To))
                      {
                        //  alert("Please enter only number 1");
                        reerrors["onlyNumMsg"]=<li>Please Enter Numeric Values Only For Employee Size Range</li>;
                      this.setState({reerrors:reerrors});
                        flagFromTo=false;
                        empsizeEmptyBack=false;
                        return;
                      }
                      else{
                  
                        reerrors["onlyNumMsg"]="";
                        this.setState({reerrors:reerrors});
                          flagFromTo=true;
                          empsizeEmptyBack=true;

                                 //Check range of from value and to value
              if(parseInt(Fromback)>=(parseInt(TOback)))
              {
                 // alert("Please enter to value greater than from value");
                 reerrors["greaterMsg"]=<li>Please Enter To Value Greater Than From Value For Employee Size Range</li>;
                 this.setState({reerrors:reerrors});
                 flagFromTo=false;
                 empsizeEmptyBack=false;
                 return;
              }
              else
              {

                 // alert("From less than to"+EmployeeSize[0].From+"==="+EmployeeSize[0].To);
                 reerrors["greaterMsg"]="";
                 this.setState({reerrors:reerrors});
                 flagFromTo=true;
                 empsizeEmptyBack=true;
              
              }
              if(EmployeeSize[i].From.includes(',')||EmployeeSize[i].To.includes(','))
              {
                //alert("true");
                
                  if(Fromback<999 || TOback<999)
                  {
                    //alert("comma not allowed here..")
                    reerrors["commaNotallowed"]=<li>Comma Not Allowed For Employee Size Range</li>;
                    this.setState({reerrors:reerrors});
                      flagFromTo=false;
                      empsizeEmptyBack=false;
                  }
              }
                      }
                    
                    }
               }//end for loop
              }else{
                //check condition if from array not greater than one
                if(EmployeeSize[0].From==="" || EmployeeSize[0].To===""){
                  // alert("Please Enter From and To value 2");
                   flagFromTo=false;
                  reerrors["fromToMsg"]=<li>Please Enter From And To Value For Employee Size Range</li>;
                  this.setState({reerrors:reerrors});
                  empsizeEmptyBack=true;
                }else{
                  var pattern = new RegExp(/^[0-9,]+$/);
                  if(!pattern.test(EmployeeSize[0].From) || !pattern.test(EmployeeSize[0].To))
                  {
                    //  alert("Please enter only number 2");
                    reerrors["onlyNumMsg"]=<li>Please Enter Numeric Values Only </li>;
                  this.setState({reerrors:reerrors});
                    flagFromTo=false;
                    empsizeEmptyBack=false;
                  }
                  else{
                    // alert("Please enter only number");
                    reerrors["onlyNumMsg"]="";
                  this.setState({reerrors:reerrors});
                    flagFromTo=true;
                    empsizeEmptyBack=true;

                      //Check range of from value and to value
                  if(parseInt(Fromback) < parseInt(TOback))
                  {
                    reerrors["greaterMsg"]="";
                    this.setState({reerrors:reerrors});
                    flagFromTo=true;
                    empsizeEmptyBack=true;
                  }
                  else{
                    //  alert("Please enter to value greater than from value 2");
                    reerrors["greaterMsg"]=<li>Please Enter To Value Greater Than From Value For Employee Size Range</li>;
                    this.setState({reerrors:reerrors});
                    flagFromTo=false;
                    empsizeEmptyBack=false;
                  }
                  if(EmployeeSize[0].From.includes(',')||EmployeeSize[0].To.includes(','))
              {
                //alert("true");
                
                  if(Fromback<999 || TOback<999)
                  {
                    //alert("comma not allowed here..")
                    reerrors["commaNotallowed"]=<li>Comma Not Allowed For Employee Size Range</li>;
                    this.setState({reerrors:reerrors});
                      flagFromTo=false;
                      empsizeEmptyBack=false;
                  }
              }
                  }
                
                }

            }


              //text value field validation
              if(customEmployeeSizeTextLength > 1){
                for(var j=0;j<customEmployeeSizeTextLength;j++){
                  //check all condition of text fields
                  var custEmpsizeback=customEmployeeSizeText[j].customEmployeeSizeText.replace(/,/g, '');
              if(custEmpsizeback===""){
                      // alert("Please enter value in text");
                      reerrors["onlyTextMsg"]=<li>Please Enter Numeric Values Only For Employee Size Value</li>;
                      this.setState({reerrors:reerrors});
                      flagText=false;
                      empsizeEmptyBack=false;
              }else{
                var pattern = new RegExp(/^[0-9,+<>]+$/);
                if(!pattern.test(custEmpsizeback))
                {
                  // alert("Please enter only number in text");
                  reerrors["onlyTextMsg"]=<li>Please Enter Numeric Values Only For Employee Size Value</li>;
                  this.setState({reerrors:reerrors});
                  flagText=false;
                  empsizeEmptyBack=false;
                }
                else
                {
                  reerrors["onlyTextMsg"]="";
                  this.setState({reerrors:reerrors});
                  flagText=true;
                  empsizeEmptyBack=true;
                }
              }
              }
              }else{
                if(custEmpsizeback===""){
                  reerrors["onlyTextMsg"]="";
                  this.setState({reerrors:reerrors});
                  flagText=false;
                }else{
                  var pattern = new RegExp(/^[0-9,+<>]+$/);
                  if(!pattern.test(custEmpsizeback))
                  {
                    // alert("Please enter only number in text");
                    reerrors["onlyTextMsg"]=<li>Please Enter Numeric Values Only For Employee Size Value</li>;
                    this.setState({reerrors:reerrors});
                    flagText=false;
                    empsizeEmptyBack=false;
                    return;
                  }
                  else
                  { 
                    reerrors["onlyTextMsg"]="";
                      this.setState({reerrors:reerrors});
                      flagText=true;
                      empsizeEmptyBack=true;
                  }
                }
              }
              

        }else{
          //check all three are blank
          // alert("Text box value=="+customEmployeeSizeText[0].customEmployeeSizeText);
          // alert("EmployeeSize[0]=="+EmployeeSize[0].From);
          // alert("EmployeeSize[0]=="+EmployeeSize[0].To);
          if((EmployeeSize[0].From==="" || EmployeeSize[0].To==="") && customEmployeeSizeText[0].customEmployeeSizeText===""){
            flagFromTo=false;
            flagText=false;
            reerrors["fromToMsg"]=<li> Please Enter Either Employee Size Range Or Value</li>;
            this.setState({reerrors:reerrors});
          }else{
            //  alert("in else");
            var From4 = EmployeeSize[0].From.replace(/,/g, '');
            var TO4 = EmployeeSize[0].To.replace(/,/g, '');
            var custEmpsizeback2=customEmployeeSizeText[0].customEmployeeSizeText.replace(/,/g, '')
            if(EmployeeSize[0].From==="" || EmployeeSize[0].To==="")
            {
              flagFromTo=false;
            }else{
              var pattern = new RegExp(/^[0-9,]+$/);
              if(!pattern.test(EmployeeSize[0].From) || !pattern.test(EmployeeSize[0].To))
              {
                //  alert("Please enter only number 1");
                reerrors["onlyNumMsg"]=<li>Please Enter Only Whole Numeric Values For Employee Size Range</li>;
              this.setState({reerrors:reerrors});
                flagFromTo=false;
                empsizeEmptyBack=false;
              }
              else{
                reerrors["onlyNumMsg"]="";
                this.setState({reerrors:reerrors});
                  flagFromTo=true;
                  empsizeEmptyBack=true;

                  if(parseInt( From4)>=(parseInt(TO4)))
                  {
                     // alert("Please enter to value greater than from value");
                     reerrors["greaterMsg"]=<li>Please Enter To Value Greater Than From Value For Employee Size Range</li>;
                     this.setState({reerrors:reerrors});
                     flagFromTo=false;
                     empsizeEmptyBack=false;
                     return;
                  }
                  else
                  {
    
                     // alert("From less than to"+EmployeeSize[0].From+"==="+EmployeeSize[0].To);
                     reerrors["greaterMsg"]="";
                     this.setState({reerrors:reerrors});
                     flagFromTo=true;
                     empsizeEmptyBack=true;
                  
                  }
                  if(EmployeeSize[0].From.includes(',')||EmployeeSize[0].To.includes(','))
              {
                //alert("true");
                
                  if(From4<999 || TO4<999)
                  {
                    //alert("comma not allowed here..")
                    reerrors["commaNotallowed"]=<li>Comma Not Allowed For For Employee Size Range</li>;
                    this.setState({reerrors:reerrors,});
                      flagFromTo=false;
                      empsizeEmptyBack=false;
                  }
              }
              }
             
             }

              //check all condition of text fields
              if(custEmpsizeback2===""){
                flagText=false;
              }else{
                var pattern = new RegExp(/^[0-9,+<>]+$/);
                if(!pattern.test(custEmpsizeback2))
                {
                  // alert("Please enter only number in text");
                  reerrors["onlyTextMsg"]=<li>Please Enter Numeric Values Only For Employee Size Value</li>;
                  this.setState({reerrors:reerrors});
                  flagText=false;
                  empsizeEmptyBack=false;
                }
                else
                {
                  reerrors["onlyTextMsg"]="";
                  this.setState({reerrors:reerrors});
                  flagText=true;
                  empsizeEmptyBack=true;
                }
               
              }
          }

        }
      }else{
        if(dynamicEmployeeSizeLength==0)
        {
          if(customEmployeeSizeText[0].customEmployeeSizeText!=="")
          {
            // alert("has value should allow")
            reerrors["success"]=<li>Successfully Entered Custom Employee Size</li>;
        this.setState({reerrors:reerrors});
        empsizeEmpty=true;

          }
          else
          {
            // alert("has no value should not allow")
            reerrors["success"]=""
            reerrors["fromToMsg"]=<li> Please Enter Either Employee Size Range Or Value</li>;
            this.setState({reerrors:reerrors});
            flagFromTo=false;
            flagText=false;
          }
        }
      //   flagFromTo=false;
      //  flagText=false;
        // empsizeEmptyBack=true;
        //     reerrors["fromToMsg"]=<li>Please Enter Either Employee Size Range Or Value</li>;
        //     this.setState({reerrors:reerrors});
      }
   

      this.setState({
        flagFromToState:flagFromTo,
        flagTextState:flagText
      });


      if(flagFromTo==true) { reerrors["success"]=<li>Successfully Entered Custom Employee Size Range</li>;
       this.setState({reerrors:reerrors}); empsizeEmpty=true; } 
       else { reerrors["success"]=""; this.setState({reerrors:reerrors}); }
        if(flagText==true)
         { // alert(flagText); 
          reerrors["successText"]=<li>Successfully Entered Custom Employee Size Value</li>; 
          this.setState({reerrors:reerrors}); empsizeEmpty=true; } 
          else { reerrors["successText"]=""; 
          this.setState({reerrors:reerrors}); }
}// end of addEmpSizeBack



 
       addIndustry(e)
       {
         let industryerrors={}
        //  alert("inside industry popup ");
        //  alert(this.state.customIndustry)
         if(this.state.customIndustry==undefined||this.state.customIndustry==""||this.state.customIndustry===null)
         {
        //  alert("enter value for industry");
          industryerrors["industryundefined"]=<li>Please enter custom value for industry</li>;
          this.setState({industryerrors:industryerrors,inputClassCustomIndustry:'invalid'});
          industryEmpty=true;
         }
         else
         {

          var pattern = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z,()\|\/\+&@\-\.\n ]+$/);
          if(!pattern.test(this.state.customIndustry))
          {
          //  alert("Please enter only alphabetical value");
             industryerrors["industryundefined"]=<li>Please Enter Alphabetical Values Only</li>;
            this.setState({industryerrors:industryerrors,inputClassCustomIndustry:'invalid'});
            industryEmpty=false;
          }
          else
          {
                // alert("has correct  value");
                industryerrors["industrysuccess"]=<li>Successfully entered custom industry</li>;
                this.setState({industryerrors:industryerrors,
                  inputClassCustomIndustry:'valid'});
                  industryEmpty=true;
          }

          
         }

       }

       addIndustryBack(e)
       {
        //  alert("inside  back of industry");
         let errors={}
         let fields = this.state;
         var name=e.target.name;
         var value=e.target.value;
         fields[e.target.name] = e.target.value;
        let campaignDetail = [...this.state.campaignDetail];
         campaignDetail[0] = { ...campaignDetail[0], [name]: value };
          this.setState({ campaignDetail });
          // alert("Lead Allocation===>"+JSON.stringify(campaignDetail));
          
          if( this.state.campaignDetail[0].customIndustry==0)
          {
            // alert("empty");
            errors["industryundefined"]=<li>Please enter value for custom industry</li>;
            this.setState({errors:errors,
              inputClassDropDownIndystry:'invalid'})  
              industryEmptyBack=true;        
          }
          else
          {
            // alert("has")
           
            var pattern = new RegExp(/^(?=.*?[A-Za-z])[A-Za-z,()\|\/\+&@\-\.\n ]+$/);
            if(!pattern.test(this.state.campaignDetail[0].customIndustry))
          {
            // alert("Please enter only alphabetical value");
            errors["industryundefined"]=<li>Please Enter Alphabetical Values Only</li>;
            this.setState({errors:errors,inputClassCustomIndustry:'invalid'});
            industryEmptyBack=false;
          }
          else
          {
            errors["industrysuccess"]=<li>Successfully entered custom industry</li>;

            this.setState({errors:errors,
              inputClassDropDownIndystry:'valid'
            }) 
            industryEmptyBack=true;
        
          }
        
        
        
        
        
        
        
        
        
          }
            
       }

       handleChangeIndustry(e)
       {
        
        let fields = this.state;
        var name=e.target.name;
        var value=e.target.value;
        fields[e.target.name] = e.target.value;
         let campaignDetail = [...this.state.campaignDetail];
        campaignDetail[0] = { ...campaignDetail[0], [name]: value };
         this.setState({ campaignDetail });
        //  alert()JSON.stringify(campaignDetail));
      }
       

        /**
       * @author Sanobar  Golandaj
       * @param  Description handle change for popup text field
       */
      customRevenuePopupHandleChange(e)
      {
    // alert(e.target.name+":=>"+e.target.value);
      let fields = this.state;
      fields[e.target.name] = e.target.value;
          var name=e.target.name;
        value=e.target.value;
         //  alert("text value==>"+e.target.value);
      //  alert("text name=>"+name);
        
         this.state.customRevenue[name]=value;
      }

//        /**
//        * @author Sanobar  Golandaj
//        * @param  Description handle change for popup from
//        */
//   customRevenueFromPopupHandleChange(i,e)
//   {


// var {name,value}=e.target;
// // let name="From";
//   let companyRevenue = [...this.state.customRevenueFromPopup];
//   companyRevenue[i] = { ...companyRevenue[i], [name]: value };
//  // alert(JSON.stringify(customEmployeeSizePopupBack));
//   this.setState({ customRevenueFromPopup: companyRevenue});
    
   
//   }

        /**
       * @author Sanobar  Golandaj
       * @param  Description handle change for popup to
       */
  customRevenueToPopupHandleChange(i,e)
  {
 
    var {name,value}=e.target;
      let companyRevenue = [...this.state.customRevenueToPopup];
      companyRevenue[i] = { ...companyRevenue[i], [name]: value };
    // alert(JSON.stringify(customEmployeeSizePopupBack));
      this.setState({ customRevenueToPopup: companyRevenue});
      //this.state.customRevenueToPopup=[...companyRevenue];
   }

   /**
       * @author Narendra Phadke
       * @param  Description handle the Custom company Revenue To field
       * @return Description return Custom Employee Size
       */
       
      customRevenueToPopupHandleChangeBack(i,e)
      {
          var name=e.target.name;
         var value=e.target.value;

          let customCompanyRevenue = [...this.state.customRevenuePopupBackTo];
          customCompanyRevenue[i] = { ...customCompanyRevenue[i], [name]: value };
        
          this.setState({ customRevenuePopupBackTo: customCompanyRevenue});
      }

       /**
       * @author Narendra Phadke
       * @param  Description handle the Custom company Revenue From field
       * @return Description return Custom Employee Size
       */
       
      customRevenueFromPopupHandleChangeBack(i,e)
      {
          var name=e.target.name;
         var value=e.target.value;

          let customCompanyRevenue = [...this.state.customRevenuePopupBackFromTo];
          customCompanyRevenue[i] = { ...customCompanyRevenue[i], [name]: value };
        
          this.setState({ customRevenuePopupBackFromTo: customCompanyRevenue});
      }


  /**
       * @author Supriya Gore
       * @param  Description handle the Custom Revenue
       * @return Description return Custom Revenuw
       */
       /**
       * @author Sanobar  Golandaj,Sanjana Godbole
       * @param  Description validations for Revenue popup
       */
addRevenueSize(e)
  {
     
        let reerrors= {};
       
        let RevenueSize=[...this.state.customRevenuePopupFromTo];
        // alert("RevenueSize===>"+JSON.stringify(RevenueSize));
        var dynamicRevenueLength =(Object.keys(RevenueSize).length);
      //  alert("dynamicRevenueLength===>"+dynamicRevenueLength);
        dynamicRevenueLength =parseInt(dynamicRevenueLength);


        var customRevenueText=[...this.state.customRevenueTextPopup];
        // alert("customRevenueText===>"+JSON.stringify(customRevenueText));
        var customRevenueTextLength =(Object.keys(customRevenueText).length);
      //  alert("customRevenueTextLength===>"+customRevenueTextLength);
       customRevenueTextLength=parseInt(customRevenueTextLength);
       
       var flagFromTo=true;
      var flagText=true;

      if(dynamicRevenueLength >0 && customRevenueTextLength> 0){
        if(dynamicRevenueLength > 1 || customRevenueTextLength > 1)
        {
          //  alert("Inside loop of array have values");
          if(dynamicRevenueLength > 1){
          for(var i=0;i<dynamicRevenueLength;i++){
   
                    if(RevenueSize[i].From==="" || RevenueSize[i].To==="")
                    {
                      //  alert("Please Enter From and To value");
                      flagFromTo=false;
                      
                      reerrors["fromToMsgrev"]=<li>Please Enter From and To value</li>;
                      this.setState({reerrors:reerrors,});
                      return;
                    }
                    else
                    {
                        reerrors["onlyNumMsgrev"]="";
                        this.setState({reerrors:reerrors});
                          flagFromTo=true;
                          revenueEmpty=true;



                          var matchesFrom = RevenueSize[i].From.match(/[m,M,b,B]/g); 
                          var matchesTo = RevenueSize[i].To.match(/[m,M,b,B]/g); 
                          var matchesFrom1 = RevenueSize[0].From.match(/(\d+)/); 
                          var matchesTo1 = RevenueSize[0].To.match(/(\d+)/); 
                          
                         
                          if(matchesFrom ||  matchesTo)
                          {
                           // alert("either or have M/B"+matchesFrom+"===="+matchesTo);
      
                            if( (matchesFrom=='B'||matchesFrom=='b')&& (matchesTo=='M'|| matchesTo=='m'))
                              {
                                //alert("From value is bigger...Shoul be stopped"+RevenueSize[i].From)
                                reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                                this.setState({reerrors:reerrors});
                                flagFromTo=false;
                                revenueEmpty=false;
                                return;
                              }
      
                              if( (matchesFrom=='M'||matchesFrom=='m')&& (matchesTo=='M'|| matchesTo=='m'))
                              {
                                       //Check range of from value and to value
                              if(parseInt(matchesTo1) <= parseInt(matchesFrom1))
                              {
                
                                //alert("Please enter to value greater than from value"+RevenueSize[i].From+"---"+RevenueSize[i].To);
                                reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                                this.setState({reerrors:reerrors});
                                flagFromTo=false;
                                revenueEmpty=false;
                                return;
                              
                              }
                              else{
                               // alert("From is less than to"+RevenueSize[i].From+"---"+RevenueSize[i].To);
                                reerrors["greaterMsgrev"]="";
                                this.setState({reerrors:reerrors});
                                flagFromTo=true;
                                revenueEmpty=true;
                               
                              }
                
                              }
      
      
      
                              if( (matchesFrom=='B'||matchesFrom=='b')&& (matchesTo=='B'|| matchesTo=='b'))
                              {
                                       //Check range of from value and to value
                              if(parseInt(matchesTo1) <= parseInt(matchesFrom1))
                              {
                
                                //alert("Please enter to value greater than from value"+RevenueSize[i].From+"---"+RevenueSize[i].To);
                                reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                                this.setState({reerrors:reerrors});
                                flagFromTo=false;
                                revenueEmpty=false;
                                return;
                              
                              }
                              else{
                               // alert("From is less than to"+RevenueSize[i].From+"---"+RevenueSize[i].To);
                                reerrors["greaterMsgrev"]="";
                                this.setState({reerrors:reerrors});
                                flagFromTo=true;
                                revenueEmpty=true;
                               
                              }
                
                              }
                                
      
                                
                                         
                             
                          }                             //Check range of from value and to value
                      if(parseInt(matchesTo) <= parseInt(matchesFrom))
                      {

                        //alert("Please enter to value greater than from value");
                        reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                        this.setState({reerrors:reerrors});
                        flagFromTo=false;
                        revenueEmpty=false;
                        return;
                      
                      }
                      else{
                        //alert("From is less than to");
                        reerrors["greaterMsgrev"]="";
                        this.setState({reerrors:reerrors});
                        flagFromTo=true;
                        revenueEmpty=true;
                       
                      }
                    
                    }
               }//end for loop
              }else{
                
                    reerrors["onlyNumMsgrev"]="";
                  this.setState({reerrors:reerrors});
                    flagFromTo=true;
                    revenueEmpty=true;
                  

            }


              //text value field validation
              if(customRevenueTextLength  > 1){
                for(var j=0;j<customRevenueTextLength;j++){
                  //check all condition of text fields
              if(customRevenueText[j].customCompanyRevenue===""){
                      // alert("Please enter value in text 3");
                      reerrors["onlyTextMsgrev"]=<li>Please Enter Only Numeric Values Company Revenue Value</li>;
                      this.setState({reerrors:reerrors});
                      flagText=false;
                      revenueEmpty=false;
              }else{
                // alert(" text has value 3");
                  reerrors["onlyTextMsgrev"]="";
                  this.setState({reerrors:reerrors});
                  flagText=true;
                  revenueEmpty=true;
               
              }
              }
              }else{
                if(customRevenueText[0].customCompanyRevenue===""){
                  // alert("Please enter value in text 2");
                  reerrors["onlyTextMsgrev"]="";
                  this.setState({reerrors:reerrors});
                  flagText=false;
                }else{
                
                    reerrors["onlyTextMsgrev"]="";
                      this.setState({reerrors:reerrors});
                      flagText=true;
                      revenueEmpty=true;
                      // alert(" text has value 2");
                 
                }
              }
              

        }else{
          //check all three are blank
          //  alert("Text box value=="+RevenueSize[0].From);
          // alert("EmployeeSize[0]=="+EmployeeSize[0].From);
          // alert("EmployeeSize[0]=="+EmployeeSize[0].To);
          if((RevenueSize[0].From==="" || RevenueSize[0].To==="") && customRevenueText[0].customCompanyRevenue===""){
            flagFromTo=false;
            flagText=false;
            reerrors["fromToMsgrev"]=<li> Please Enter Either Custom Revenue Size Or Value</li>;
            this.setState({reerrors:reerrors});
          }else{
            // alert("in else");
             
            if(RevenueSize[0].From==="" || RevenueSize[0].To==="")
            {
              flagFromTo=false;
            }else{
             
                reerrors["onlyNumMsgrev"]="";
                this.setState({reerrors:reerrors});
                  flagFromTo=true;
                  revenueEmpty=true;




                 



                   var matchesFrom = RevenueSize[0].From.match(/[m,M,b,B]/g); 
                   var matchesTo = RevenueSize[0].To.match(/[m,M,b,B]/g); 
                   var matchesFrom1 = RevenueSize[0].From.match(/(\d+)/); 
                   var matchesTo1 = RevenueSize[0].To.match(/(\d+)/); 

                  

                    if(matchesFrom ||  matchesTo)
                    {
                     // alert("either or have M/B"+matchesFrom+"===="+matchesTo);

                      if( (matchesFrom=='B'||matchesFrom=='b')&& (matchesTo=='M'|| matchesTo=='m'))
                        {
                          //alert("From value is bigger...Shoul be stopped"+RevenueSize[0].From)
                          reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                          this.setState({reerrors:reerrors});
                          flagFromTo=false;
                          revenueEmpty=false;
                          return;
                        }

                        if( (matchesFrom=='M'||matchesFrom=='m')&& (matchesTo=='M'|| matchesTo=='m'))
                        {
                                 //Check range of from value and to value
                        if(parseInt(matchesTo1) <= parseInt(matchesFrom1))
                        {
          
                          //alert("Please enter to value greater than from value"+RevenueSize[0].From+"---"+RevenueSize[0].To);
                          reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                          this.setState({reerrors:reerrors});
                          flagFromTo=false;
                          revenueEmpty=false;
                          return;
                        
                        }
                        else{
                         // alert("From is less than to"+RevenueSize[0].From+"---"+RevenueSize[0].To);
                          reerrors["greaterMsgrev"]="";
                          this.setState({reerrors:reerrors});
                          flagFromTo=true;
                          revenueEmpty=true;
                         
                        }
          
                        }



                        if( (matchesFrom=='B'||matchesFrom=='b')&& (matchesTo=='B'|| matchesTo=='b'))
                        {
                                 //Check range of from value and to value
                        if(parseInt(matchesTo1) <= parseInt(matchesFrom1))
                        {
          
                          //alert("Please enter to value greater than from value"+RevenueSize[0].From+"---"+RevenueSize[0].To);
                          reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value parse</li>;
                          this.setState({reerrors:reerrors});
                          flagFromTo=false;
                          revenueEmpty=false;
                          return;
                        
                        }
                        else{
                         // alert("From is less than to"+RevenueSize[0].From+"---"+RevenueSize[0].To);
                          reerrors["greaterMsgrev"]="";
                          this.setState({reerrors:reerrors});
                          flagFromTo=true;
                          revenueEmpty=true;
                         
                        }
          
                        }
                          

                          
                                   
                       
                    }
                       
                    


                     //Check range of from value and to value
              if(parseInt(matchesTo) <= parseInt(matchesFrom))
              {

                //alert("Please enter to value greater than from value");
                reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                this.setState({reerrors:reerrors});
                flagFromTo=false;
                revenueEmpty=false;
                return;
              
              }
              else{
                //alert("From is less than to");
                reerrors["greaterMsgrev"]="";
                this.setState({reerrors:reerrors});
                flagFromTo=true;
                revenueEmpty=true;
               
              }
           
             }

              //check all condition of text fields
              if(customRevenueText[0].customCompanyRevenue===""){
                flagText=false;
                // alert("Please enter value in text 1");
              }
              else
              {
               
                  reerrors["onlyNumMsgrev"]="";
                  this.setState({reerrors:reerrors});
                  flagText=true;
                  revenueEmpty=true;
                  // alert(" text has value 1");
                
              }
          }

        }
      }else{
        flagFromTo=false;
        flagText=false;
            reerrors["fromToMsgrev"]=<li>Please Enter Either Custom Revenue Size Range Or Value</li>;
            this.setState({reerrors:reerrors});
      }
   

      this.setState({
        revenueflagFromToState:flagFromTo,
        revenueflagTextState:flagText
      });

//  alert(this.state.revenueflagFromToState+"==="+this.state.revenueflagTextState)
      if(flagFromTo==true||flagText==true)
      {
        reerrors["successrev"]=<li>Successfully Entered Custom Company Revenue Range</li>;
        this.setState({reerrors:reerrors});
        revenueEmpty=true;
      }
      else
      {
        reerrors["successrev"]="";
        this.setState({reerrors:reerrors});

      }
      
      }// end of addRevenueSize

       /**
       * @author Sanobar  Golandaj
       * @param  Description custom pop handle change for text field for back
       */
  customRevenuePopupHandleChangeBack(e)
  {
     // alert(e.target.name+":=>"+e.target.value);
     let fields = this.state;
     fields[e.target.name] = e.target.value;
      
       var name=e.target.name;
       value=e.target.value;

      // alert("text value==>"+e.target.value);
      //  alert("text name=>"+name);
       
        this.state.customRevenueBack[name]=value;

  }


        /**
       * @author Sanobar  Golandaj,Sanjana Godbole
       * @param  Description validations for pop on back
       */
  addRevenueBackSize(e)
  {
    let reerrors= {};
       
        let RevenueSize=[...this.state.customRevenuePopupBackFromTo];
        // alert("RevenueSize===>"+JSON.stringify(RevenueSize));
        var dynamicRevenueLength =(Object.keys(RevenueSize).length);
      //  alert("dynamicRevenueLength===>"+dynamicRevenueLength);
        dynamicRevenueLength =parseInt(dynamicRevenueLength);


        var customRevenueText=[...this.state.customRevenuePopupBackText];
        // alert("customRevenueText===>"+JSON.stringify(customRevenueText));
        var customRevenueTextLength =(Object.keys(customRevenueText).length);
      //  alert("customRevenueTextLength===>"+customRevenueTextLength);
       customRevenueTextLength=parseInt(customRevenueTextLength);
       
       var flagFromTo=true;
      var flagText=true;

      if(dynamicRevenueLength >0 && customRevenueTextLength> 0){
        if(dynamicRevenueLength > 1 || customRevenueTextLength > 1)
        {
          //  alert("Inside loop of array have values");
          if(dynamicRevenueLength > 1){
          for(var i=0;i<dynamicRevenueLength;i++){
   
                    if(RevenueSize[i].From==="" || RevenueSize[i].To==="")
                    {
                      //  alert("Please Enter From and To value");
                      flagFromTo=false;
                      reerrors["fromToMsgrev"]=<li>Please Enter From and To value</li>;
                      this.setState({reerrors:reerrors});
                     revenueEmptyBack=true;
                      return;
                    }
                    else
                    {
                    
                  
                        reerrors["onlyNumMsgrev"]="";
                        this.setState({reerrors:reerrors});
                          flagFromTo=true;
                          // revenueEmptyBack=true;
                          var matchesFrom = RevenueSize[i].From.match(/[m,M,b,B]/g); 
                          var matchesTo = RevenueSize[i].To.match(/[m,M,b,B]/g); 
                          var matchesFrom1 = RevenueSize[i].From.match(/(\d+)/); 
                                 var matchesTo1 = RevenueSize[i].To.match(/(\d+)/); 
       
                         
       
                           if(matchesFrom ||  matchesTo)
                           {
                             //alert("either or have M/B"+matchesFrom+"===="+matchesTo);
       
                             if( (matchesFrom=='M'||matchesFrom=='M')&& (matchesTo=='B'|| matchesTo=='b'))
                               {
                                // alert("From value is bigger...Shoul be stopped"+RevenueSize[i].From)
                                 reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                                 this.setState({reerrors:reerrors});
                                 flagFromTo=false;
                                 revenueEmptyBack=false;
                                 return;
                               }
                               else    
                                     //Check range of from value and to value
                               if(parseInt(matchesTo1) <= parseInt(matchesFrom1))
                               {
                 
                               //  alert("Please enter to value greater than from value"+RevenueSize[i].From+"---"+RevenueSize[i].To);
                                 reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                                 this.setState({reerrors:reerrors});
                                 flagFromTo=false;
                                 revenueEmptyBack=false;
                                 return;
                               
                               }
                               else{
                                 //alert("From is less than to"+RevenueSize[i].From+"---"+RevenueSize[i].To);
                                 reerrors["greaterMsgrev"]="";
                                 this.setState({reerrors:reerrors});
                                 flagFromTo=true;
                                 revenueEmptyBack=true;
                                
                               }
                              
                              
                           }

                           
                             //Check range of from value and to value
                      if(parseInt(matchesTo) < parseInt(matchesFrom))
                      {

                        //alert("Please enter to value greater than from value");
                        reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                        this.setState({reerrors:reerrors});
                        flagFromTo=false;
                        revenueEmptyBack=false;
                        return;
                      
                      }
                      else{
                        //alert("From is less than to");
                        reerrors["greaterMsgrev"]="";
                        this.setState({reerrors:reerrors});
                        flagFromTo=true;
                        revenueEmptyBack=true;

                       
                      }
                    
                    }
               }//end for loop
              }else{
                
                    reerrors["onlyNumMsgrev"]="";
                  this.setState({reerrors:reerrors});
                    flagFromTo=true;
                    revenueEmptyBack=true;
                  

            }


              //text value field validation
              if(customRevenueTextLength  > 1){
                for(var j=0;j<customRevenueTextLength;j++){
                  //check all condition of text fields
              if(customRevenueText[j].customCompanyRevenue===""){
                      // alert("Please enter value in text 3");
                      reerrors["onlyTextMsgrev"]=<li>Please Enter Only Whole Numeric Values Company Revenue Value</li>;
                      this.setState({reerrors:reerrors});
                      flagText=false;
                      revenueEmptyBack=false;
              }else{
                // alert(" text has value 3");
                  reerrors["onlyTextMsgrev"]="";
                  this.setState({reerrors:reerrors});
                  flagText=true;
                  revenueEmptyBack=true;
              }
              }
              }else{
                if(customRevenueText[0].customCompanyRevenue===""){
                  // alert("Please enter value in text 2");
                  reerrors["onlyTextMsgrev"]="";
                  this.setState({reerrors:reerrors});
                  flagText=false;
                  revenueEmptyBack=false;
                }else{
                
                    reerrors["onlyTextMsgrev"]="";
                      this.setState({reerrors:reerrors});
                      flagText=true;
                      revenueEmptyBack=true;
                      // alert(" text has value 2");
                 
                }
              }
              

        }else{
          //check all three are blank
          //  alert("Text box value=="+RevenueSize[0].From);
          // alert("EmployeeSize[0]=="+EmployeeSize[0].From);
          // alert("EmployeeSize[0]=="+EmployeeSize[0].To);
        //  alert("Values===>"+customRevenueText[0].customCompanyRevenue+"===="+RevenueSize[0].From+"===" +RevenueSize[0].To);
          if((RevenueSize[0].From==="" || RevenueSize[0].To==="") && customRevenueText[0].customCompanyRevenue===undefined)
          {
            flagFromTo=false;
            flagText=false;
           // alert(" Please Enter Either Custom Revenue Size Or Value 1");
            reerrors["fromToMsgrev"]=<li> Please Enter Either Custom Revenue Size Or Value</li>;
            this.setState({reerrors:reerrors});
            revenueEmptyBack=true;
          }
          else
          {
            // alert("in else====>"+customRevenueText[0].customCompanyRevenue);
             
            if(RevenueSize[0].From==="" || RevenueSize[0].To==="")
            {
              flagFromTo=false;
            }else{
             
          //    alert("Present");
                reerrors["onlyNumMsgrev"]="";
                this.setState({reerrors:reerrors});
                  flagFromTo=true;
                  revenueEmptyBack=true;
           

             }

              //check all condition of text fields
              if(customRevenueText[0].customCompanyRevenue===""){
                flagText=false;
                 //alert("Please enter value in text 1");
              }
              else
              {
               
                  reerrors["onlyNumMsgrev"]="";
                  this.setState({reerrors:reerrors});
                  flagText=true;
                  revenueEmptyBack=true;
                  // alert(" text has value 1");


                 
  var matchesFrom = RevenueSize[0].From.match(/[m,M,b,B]/g); 
  var matchesTo = RevenueSize[0].To.match(/[m,M,b,B]/g); 
  var matchesFrom1 = RevenueSize[0].From.match(/(\d+)/); 
         var matchesTo1 = RevenueSize[0].To.match(/(\d+)/); 

 

   if(matchesFrom ||  matchesTo)
   {
    // alert("either or have M/B"+matchesFrom+"===="+matchesTo);

     if( (matchesFrom=='M'||matchesFrom=='M')&& (matchesTo=='B'|| matchesTo=='b'))
       {
         //alert("From value is bigger...Shoul be stopped"+RevenueSize[0].From)
         reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
         this.setState({reerrors:reerrors});
         flagFromTo=false;
         revenueEmptyBack=false;
         return;
       }
       else    
             //Check range of from value and to value
       if(parseInt(matchesTo1) <= parseInt(matchesFrom1))
       {

         //alert("Please enter to value greater than from value"+RevenueSize[0].From+"---"+RevenueSize[0].To);
         reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
         this.setState({reerrors:reerrors});
         flagFromTo=false;
         revenueEmptyBack=false;
         return;
       
       }
       else{
        // alert("From is less than to"+RevenueSize[0].From+"---"+RevenueSize[0].To);
         reerrors["greaterMsgrev"]="";
         this.setState({reerrors:reerrors});
         flagFromTo=true;
         revenueEmptyBack=true;
        
       }
      
      
   }
      
   

   
                     //Check range of from value and to value
              if(parseInt(matchesTo) < parseInt(matchesFrom))
              {

                //alert("Please enter to value greater than from value");
                reerrors["greaterMsgrev"]=<li>Please Enter To Value Greater Than From Value</li>;
                this.setState({reerrors:reerrors});
                flagFromTo=false;
                revenueEmptyBack=false;
                return;
              
              }
              else{
                //alert("From is less than to");
                reerrors["greaterMsgrev"]="";
                this.setState({reerrors:reerrors});
                flagFromTo=true;
                revenueEmptyBack=true;
               
              }
                
              }
          }

        }
      }else{
        flagFromTo=false;
        flagText=false;
      // alert("Please Enter Either Custom Revenue Size Or Value 2") ;
            reerrors["fromToMsgrev"]=<li>Please Enter Either Custom Revenue Size Range Or Value</li>;
            this.setState({reerrors:reerrors});
            revenueEmptyBack=true;
      }
   

      this.setState({
        revenueflagFromToState:flagFromTo,
        revenueflagTextState:flagText
      });

 //alert(flagFromTo+"==="+flagText)
      if(flagFromTo==true||flagText==true)
      {
        reerrors["successrev"]=<li>Successfully Entered Custom Company Revenue Range</li>;
        this.setState({reerrors:reerrors});
        revenueEmptyBack=true;
      }
      else
      {
        reerrors["successrev"]="";
        this.setState({reerrors:reerrors});
      }

}//addRevenueBackSize

/**
        * @author Narendra Phadke
        * @param  Description handle add the dynamic custom revenue fields
        * @return Description return all custom revenue field
      */
  customRevenueFromPopupHandleChange(i,e)
  {

  var {name,value}=e.target;
  let companyRevenue = [...this.state.customRevenuePopupFromTo];
  companyRevenue[i] = { ...companyRevenue[i], [name]: value };
  this.setState({ customRevenuePopupFromTo: companyRevenue});
   }

/**
        * @author Narendra Phadke
        * @param  Description handle add the dynamic custom revenue fields
        * @return Description return all custom revenue field
      */
     customRevenueTextPopupHandleChange(i,e)
     {
   
   var {name,value}=e.target;
     let companyRevenue = [...this.state.customRevenueTextPopup];
     companyRevenue[i] = { ...companyRevenue[i], [name]: value };
    // alert(JSON.stringify(customEmployeeSizePopupBack));
     this.setState({ customRevenueTextPopup: companyRevenue});
      }

      /**
        * @author Narendra Phadke
        * @param  Description handle add the dynamic custom revenue fields
        * @return Description return all custom revenue field
      */
     customRevenueBackTextPopupHandleChange(i,e)
     {
   
   var {name,value}=e.target;
     let companyRevenue = [...this.state.customRevenuePopupBackText];
     companyRevenue[i] = { ...companyRevenue[i], [name]: value };
    // alert(JSON.stringify(customEmployeeSizePopupBack));
     this.setState({ customRevenuePopupBackText: companyRevenue});
      }
/**
       * @author Narendra Phadke
       * @param  Description handle the Custom company Revenue Text field
       * @return Description return Custom Employee Size
       */
       
      customRevenueTextPopupHandleChangeBack(i,e)
      {
          var name=e.target.name;
         var value=e.target.value;

          let customCompanyRevenue = [...this.state.customRevenuePopupBackText];
          customCompanyRevenue[i] = { ...customCompanyRevenue[i], [name]: value };
        //  alert(JSON.stringify(JSON.stringify(customCompanyRevenue)));
          this.setState({ customRevenuePopupBackText: customCompanyRevenue});
          
      }

/****************************Narendra dynamic start*/
/**
        * @author Narendra Phadke
        * @param  Description handle add the dynamic custom revenue fields
        * @return Description return all custom revenue field
      */
     addDyanamicCompanyRevenue(e){
      count=count+1;
      var customRevenue=[{'From':"",'To':"",count:count}];
      let finalData=this.state.customRevenuePopupFromTo.concat(customRevenue);
      this.setState({customRevenuePopupFromTo:finalData})
      // alert("Add ======>"+JSON.stringify(this.state.dynamicCustomQuestion));
    }
    /**
        * @author Narendra Phadke
        * @param  Description handle remove the dynamic custom revenue fields
        * @return Description return remaining custom revenue field
      */
    removeDyanamicCompanyRevenue(e,i){
      this.state.customRevenuePopupFromTo.splice(i,1);
      this.setState({customRevenuePopupFromTo:this.state.customRevenuePopupFromTo});
     
     // alert(JSON.stringify(this.state.dynamicCustomQuestion))
    }

    /**
        * @author Narendra Phadke
        * @param  Description handle remove the dynamic custom revenue fields
        * @return Description return remaining custom revenue field
      */
     removeDyanamicCompanyRevenueBack(e,i){
      this.state.customRevenuePopupBackFromTo.splice(i,1);
     this.setState({customRevenuePopupBackFromTo:this.state.customRevenuePopupBackFromTo});
     // this.state.dynamicCompanyRevenue.splice(i,1);
     // alert(JSON.stringify(this.state.dynamicCustomQuestion))
    }

    /**
        * @author Narendra Phadke
        * @param  Description handle add the dynamic custom revenue text fields
        * @return Description return all custom revenue text field
      */
     addDyanamicCompanyRevenueText(e){
      count=count+1;
      var customCompanyRevenueText=[{'customCompanyRevenue':"",count:count}];
        let finalDataText=this.state.customRevenueTextPopup.concat(customCompanyRevenueText);
      this.setState({customRevenueTextPopup:finalDataText})
      // alert("Add ======>"+JSON.stringify(this.state.dynamicCustomQuestion));
    }
    /**
        * @author Narendra Phadke
        * @param  Description handle remove the dynamic custom revenue fields
        * @return Description return remaining custom revenue field
      */
    removeDyanamicCompanyRevenueText(e,i){
      this.state.customRevenueTextPopup.splice(i,1);
      this.setState({customRevenueTextPopup:this.state.customRevenueTextPopup});
     
     // alert(JSON.stringify(this.state.dynamicCustomQuestion))
    }


    /**
        * @author Narendra Phadke
        * @param  Description handle add the dynamic custom revenue fields
        * @return Description return all custom revenue field
      */
     addDyanamicCompanyRevenueBack(e){
      var count=this.state.customRevenuePopupBackFromTo.length;
      count=count+1;
     // this.setState({dynamicCompanyRevenue:[...this.state.dynamicCompanyRevenue,count]})
      var customRevenuePopupBackFromTo=[{'From':"",'To':"",count:count}];
      let finalDataFrom=this.state.customRevenuePopupBackFromTo.concat(customRevenuePopupBackFromTo);
      this.setState({customRevenuePopupBackFromTo:finalDataFrom})
      // alert("Add ======>"+JSON.stringify(this.state.dynamicCustomQuestion));
    }
 
 /**
        * @author Narendra Phadke
        * @param  Description handle add the dynamic custom revenue text fields
        * @return Description return all custom revenue text field
      */
     addDyanamicCompanyRevenueBackText(e){
      var count=this.state.customRevenuePopupBackText.length;
      count=count+1;
      var customCompanyRevenueText=[{'customCompanyRevenueText':"",count:count}];
        let finalDataText=this.state.customRevenuePopupBackText.concat(customCompanyRevenueText);
      this.setState({customRevenuePopupBackText:finalDataText});
      // alert("Add ======>"+JSON.stringify(this.state.customRevenuePopupBackText));
    }
    /**
        * @author Narendra Phadke
        * @param  Description handle remove the dynamic custom revenue fields
        * @return Description return remaining custom revenue field
      */
    removeDyanamicCompanyRevenueBackText(e,i){
      this.state.customRevenuePopupBackText.splice(i,1);
      this.setState({customRevenuePopupBackText:this.state.customRevenuePopupBackText});
     // this.state.customRevenuePopupBackText.splice(i,1);
     // alert(JSON.stringify(this.state.dynamicCustomQuestion))
    }
    /******************End */

  /**
       * @author Supriya Gore
       * @param  Description handle the Custom Revenue Back for From value
       * @return Description return Custom Revenue
       */
     

  customRevenuePopupHandleChangeBackFrom(i,e)
  {
   // alert(e.target.name+":=>"+e.target.value);
   var name="from"
   const { value } = e.target;
     
       let customRevenuePopupBackFromTo = [...this.state.customRevenuePopupBackFromTo];
       customRevenuePopupBackFromTo[i] = { ...customRevenuePopupBackFromTo[i], [name]: value };
        // alert(JSON.stringify( customRevenuePopupBackFromTo));
       this.setState({ customRevenuePopupBackFromTo });
      //  var questionLength=Object.keys(this.state.customRevenuePopupBack).length;
      //  revenueLength=questionLength;
       
 }

  /**
       * @author Supriya Gore
       * @param  Description handle the Custom Revenue Back for To value
       * @return Description return Custom Revenue
       */
  customRevenuePopupHandleChangeBackTo(i,e)
  {
   // alert(e.target.name+":=>"+e.target.value);
   var name="to"
   const { value } = e.target;
     
       let customRevenuePopupBackTo = [...this.state.customRevenuePopupBackTo];
       customRevenuePopupBackTo[i] = { ...customRevenuePopupBackTo[i], [name]: value };
      // alert(JSON.stringify(customEmployeeSizePopupBack));
       this.setState({ customRevenuePopupBackTo });
      
     
   
  }

  addDynamicJobFunctionBack(e){
    var count=this.state.customJobFunctionPopupBack.length;
    count=count+1;
    var jobFunction=[{jobFunction:'',count:count}];
    let finalData=this.state.customJobFunctionPopupBack.concat(jobFunction);
    this.setState({customJobFunctionPopupBack:finalData})
    // alert("Add ======>"+JSON.stringify(this.state.dynamicCustomQuestion));
  }
  
  removeDynamicJobFunctionBack(e,i){
    this.state.customJobFunctionPopupBack.splice(i,1);
    this.setState({customJobFunctionPopupBack:this.state.customJobFunctionPopupBack});
 
  }
 
// add an
      addDynamicJobFunction(e){
        count=count+1;
        var jobFunction=[{'jobFunction':"",count:count}];
        let finalData=this.state.customJobFunctionPopup.concat(jobFunction);
        this.setState({customJobFunctionPopup:finalData})
        
      }
    
      removeDynamicJobFunction(e,i){
        this.state.customJobFunctionPopup.splice(i,1);
        this.setState({customJobFunctionPopup:this.state.customJobFunctionPopup});
       
      }

     
      /**
       * @author Supriya Gore,Sanjana Godbole
       * @param  Description handle the Custom Job Function
       * @return Description return Custom Job Function
       */
      addJobFunction(e)
      {
        //debugger
          let errors = {};
          var flagJobFunction=true;
            
      //Job  Function Popup Validation(customJobFunctionPopup)
      let jobFunction=[...this.state.customJobFunctionPopup];
      //alert("jobFunction===>"+JSON.stringify(jobFunction));
        var dynamicjobFunctionLength =jobFunction.length;
        //alert("dynamicjobFunctionLength====>"+dynamicjobFunctionLength);
      
       var customJobFunctionPopupLength=(Object.keys(this.state.customJobFunctionPopup).length);
    
       if(dynamicjobFunctionLength >0){

        if(dynamicjobFunctionLength > 1)
        {

          for(var i=0;i<dynamicjobFunctionLength;i++)
          {
   
            if ((jobFunction[i].jobFunction==="" ))
            {
              //alert("Blank");
              errors["jobfunctionundefined"]=<li>Please Enter Job Function  Value</li>;
              this.setState({
                errors:errors,
                
              });
              flagJobFunction=false;
            return;
            }
            else
            {
               //alert("Present");
    
              var pattern = new RegExp(/^[a-zA-Z\s\-\/\,\'\&]*$/);
              if (!pattern.test(jobFunction[i].jobFunction))
                      {
                       
                        //alert("Pattern Not  Matched");
                          errors["jobFunctionPattern"] = <li>Please Enter Alphabatical Values </li>;
                          this.setState({
                            errors:errors,
                            
                           })
                           flagJobFunction=false;
                           jobfunctionEmpty=false;
                           return;
                      }
                      else{
                        //alert("Pattern Matched");
                       errors["jobFunctionPattern"] = "";
                       this.setState({
                         errors:errors,
                        
                        })
                        flagJobFunction=true;
                        jobfunctionEmpty=true;
                      }
    
            }


          }//end of for loop
        }// end of >1
        else
        {
          // alert("dynamicjobFunctionLength < 1");
        if ((jobFunction[0].jobFunction==="" ))
        {
          // alert("Blank");
          errors["jobfunctionundefined"]=<li>Please Enter Min One Value</li>;
                this.setState({
                  errors:errors,
                  
                });
                flagJobFunction=false;   
        }
        else
        {
          // alert("Present");

          var pattern = new RegExp(/^[a-zA-Z\s\-\/\,\'\&]*$/);
          if (!pattern.test(jobFunction[0].jobFunction))
                  {
                       // alert("Pattern  not a pattern");
                    
                      errors["jobFunctionPattern"] = <li>Please Enter Alphabatical Values </li>;
                      this.setState({
                        errors:errors,
                       
                       })

                       flagJobFunction=false;
                       jobfunctionEmpty=false;
                      
                  }
                  else{
                    //alert("Pattern Matched");
                   errors["jobFunctionPattern"] = "";
                   this.setState({
                     errors:errors,
                     
                    })
                    flagJobFunction=true;
                    jobfunctionEmpty=true;
                  }

        }
      }//else of >1     
    }// end of >0
    if(dynamicjobFunctionLength==0)//if the there are no feilds present in the pop-up
{
  flagJobFunction=false;
  jobfunctionEmpty=false;
  errors["jobFunctionNofields"] = <li>No Data present</li>;
  this.setState({
    errors:errors,
    
   })
}   
  this.setState({
        flagJobFunctionState:flagJobFunction
       })

      if(flagJobFunction==true)
      {
     
        errors["success"]=<li>Successfully Entered Custom Job Function Values</li>;
        this.setState({errors:errors});
        // setTimeout(() => {
        //   this.setState({
        //     isOpen: 'modal'
        //   })
        //   // alert(this.state.isOpen)
        //   }, 1000);
  
        jobfunctionEmpty=true;
      }
      else
      {
        errors["success"]="";
        this.setState({errors:errors});
      }
       
    } // end of addJOBFunction


/**
       * @author Narendra
       * @param  Description handle the Custom Job Function
       * @return Description return Custom Job Function
       */
  customJobFunctionPopupHandleChange(i,e)
  {
    var {name,value}=e.target;
     let customJobFunctionPopup = [...this.state.customJobFunctionPopup];
     customJobFunctionPopup[i] = { ...customJobFunctionPopup[i], [name]: value };
     this.setState({ customJobFunctionPopup: customJobFunctionPopup});
}

  /**
       * @author Supriya Gore, Sanjana Godbole
       * @param  Description handle the Custom Job Function Back
       * @return Description return Custom Job Function
       */
      addJobFunctionBack(e)
      {
        //alert("here")
        let errors = {};
        var flagJobFunction=true;
          
    //Job  Function Popup Validation(customJobFunctionPopup)
    let jobFunction=[...this.state.customJobFunctionPopupBack];
    // alert("jobFunction===>"+JSON.stringify(jobFunction));
      var dynamicjobFunctionLength =jobFunction.length;
      // alert("dynamicjobFunctionLength====>"+dynamicjobFunctionLength);
    
     var customJobFunctionPopupLength=(Object.keys(this.state.customJobFunctionPopup).length);
  
     if(dynamicjobFunctionLength >0){
      if(dynamicjobFunctionLength > 1)
      {

        for(var i=0;i<dynamicjobFunctionLength;i++)
        {
 
          if ((jobFunction[i].jobFunction==="" ))
          {
         //    alert("Blank");
            errors["jobfunctionundefined"]=<li>Please Enter Job Function  Value</li>;
            this.setState({
              errors:errors
            });
            flagJobFunction=false;
            return;
          }
          else
          {
           //  alert("Present");
  
            
            var pattern = new RegExp(/^[a-zA-Z\s\-\/\,\'\&]*$/);
            if (!pattern.test(jobFunction[i].jobFunction))
                    {
                     
                      //alert("alpha");
                        errors["jobFunctionPattern"] = <li>Please Enter Alphabatical Values </li>;
                        this.setState({
                          errors:errors
                         })
                         flagJobFunction=false;
                         jobfunctionEmptyBack=false;
                         return;
                    }
                    else{
                     // alert("Pattern Matched");
                     errors["jobFunctionPattern"] = "";
                     this.setState({
                       errors:errors
                      })
                      flagJobFunction=true;
                      jobfunctionEmptyBack=true;
                    }
  
          }


        }//end of for loop
      }// end of >1
      else
      {
        // alert("dynamicjobFunctionLength < 1");
        // alert(jobFunction[0].jobFunction)
      if ((jobFunction[0].jobFunction===""))
      {
        // alert("Blank");
        errors["jobfunctionundefined"]=<li>Please Enter Min One Value</li>;
              this.setState({
                errors:errors
              });
              flagJobFunction=false;   
              jobfunctionEmptyBack=true;
      }
      else
      {
        // alert("Present");

        var pattern = new RegExp(/^[a-zA-Z\s\-\/\,\'\&]*$/);
        if (!pattern.test(jobFunction[0].jobFunction))
                {
                    //  alert("Pattern  not a pattern");
                  
                    errors["jobFunctionPattern"] = <li>Please Enter Alphabatical Values </li>;
                    this.setState({
                      errors:errors
                     })

                     flagJobFunction=false;
                     jobfunctionEmptyBack=false;
                }
                else{
                //  alert("Pattern Matched");
                 errors["jobFunctionPattern"] = "";
                 this.setState({
                   errors:errors
                  })
                  flagJobFunction=true;
                  jobfunctionEmptyBack=true;
                }

      }
    }//else of >1
    }// end of >0
    if(dynamicjobFunctionLength==0)//if the there are no feilds present in the pop-up
    {
      flagJobFunction=false;
      errors["jobFunctionNofields"] = <li>No Data present</li>;
      this.setState({
        errors:errors
       })
       jobfunctionEmptyBack=true;
    }   
    this.setState({
      flagJobFunctionState:flagJobFunction
     })

    if(flagJobFunction==true)
    {
      //alert("here ")
      errors["success"]=<li>Successfully Entered Custom Job Function Values</li>;
      this.setState({errors:errors});
      jobfunctionEmptyBack=true;
    }
    else
    {
      errors["success"]="";
      this.setState({errors:errors});
    }



      }//addjobFunctionBack


  customJobFunctionPopupHandleChangeBack(i,e)
  {
   
    var name="jobFunction"
   const { value } = e.target;
     
       let customJobFunctionPopupBack = [...this.state.customJobFunctionPopupBack];
       customJobFunctionPopupBack[i] = { ...customJobFunctionPopupBack[i], [name]: value };
      // alert(JSON.stringify(customEmployeeSizePopupBack));
       this.setState({ customJobFunctionPopupBack });
      }



      addDynamicJobLevel(e){
        count=count+1;
        var jobLevel=[{'jobLevel':"",count:count}];
        let finalDataLevel=this.state.customJobLevelPopup.concat(jobLevel);
        this.setState({customJobLevelPopup:finalDataLevel})
        
      }
    
      removeDynamicJobLevel(e,i){
        this.state.customJobLevelPopup.splice(i,1);
        this.setState({customJobLevelPopup:this.state.customJobLevelPopup});
       
      }


      addDynamicJobLevelBack(e){
        var count=this.state.customJobLevelPopupBack.length;
        count=count+1;
        var jobLevel=[{jobLevel:'',count:count}];
        let finalDataLevel=this.state.customJobLevelPopupBack.concat(jobLevel);
        this.setState({customJobLevelPopupBack:finalDataLevel})
        // alert("Add ======>"+JSON.stringify(this.state.dynamicCustomQuestion));
      }
      
      removeDynamicJobLevelBack(e,i){
        this.state.customJobLevelPopupBack.splice(i,1);
        this.setState({customJobLevelPopupBack:this.state.customJobLevelPopupBack});
     
      }


      customJobLevelPopupHandleChange(i,e)
      {
        // alert("handle chnaged")
        var {name,value}=e.target;
         let customJobLevelPopup = [...this.state.customJobLevelPopup];
         customJobLevelPopup[i] = { ...customJobLevelPopup[i], [name]: value };
         //alert(JSON.stringify(customJobLevelPopup));
         this.setState({ customJobLevelPopup: customJobLevelPopup});
    }
    
    customJobLevelPopupHandleChangeBack(i,e)
    {
     
      var name="jobLevel"
     const { value } = e.target;
       
         let customJobLevelPopupBack = [...this.state.customJobLevelPopupBack];
         customJobLevelPopupBack[i] = { ...customJobLevelPopupBack[i], [name]: value };
        // alert(JSON.stringify(customEmployeeSizePopupBack));
         this.setState({ customJobLevelPopupBack });
        
        }
  

    addJobLevel(e)
    {
      
        let errors = {};
        var flagJobLevel=true;
    
    let jobLevel=[...this.state.customJobLevelPopup];
     
      var dynamicjobLevelLength =jobLevel.length;
      // alert("dynamicjobFunctionLength====>"+dynamicjobFunctionLength);
    
    //  var customJobLevelPopupLength=(Object.keys(this.state.customJobLevelPopup).length);
  
     if(dynamicjobLevelLength >0){
      if(dynamicjobLevelLength > 1)
      {

        for(var i=0;i<dynamicjobLevelLength;i++)
        {
 
          if ((jobLevel[i].jobLevel==="" ))
          {
            //alert("Blank");
            errors["joblevelundefined"]=<li>Please Enter Job Level  Value</li>;
            this.setState({
              errors:errors
            });
            flagJobLevel=false;
          return;
          }
          else
          {
             //alert("Present");
  
            var pattern = new RegExp(/^[a-zA-Z\s\-\/\,\'\&]*$/);
            if (!pattern.test(jobLevel[i].jobLevel))
                    {
                     
                      //alert("Pattern Not  Matched");
                        errors["joblevelPattern"] = <li>Please Enter Alphabatical Values </li>;
                        this.setState({
                          errors:errors
                         })
                         flagJobLevel=false;
                         joblevelEmpty=false;
                         return;
                    }
                    else{
                      //alert("Pattern Matched");
                     errors["joblevelPattern"] = "";
                     this.setState({
                       errors:errors
                      })
                      flagJobLevel=true;
                      joblevelEmpty=true;
                    }
  
          }


        }//end of for loop
      }// end of >1
      else
      {
        // alert("dynamicjobFunctionLength < 1");
      if ((jobLevel[0].jobLevel==="" ))
      {
        // alert("Blank");
        errors["joblevelundefined"]=<li>Please Enter Min One Value</li>;
              this.setState({
                errors:errors
              });
              flagJobLevel=false;   
      }
      else
      {
        // alert("Present");

        var pattern = new RegExp(/^[a-zA-Z\s\-\/\,\'\&]*$/);
        if (!pattern.test(jobLevel[0].jobLevel))
                {
                     // alert("Pattern  not a pattern");
                  
                    errors["joblevelPattern"] = <li>Please Enter Alphabatical Values </li>;
                    this.setState({
                      errors:errors
                     })

                     flagJobLevel=false;
                     joblevelEmpty=false;

                    
                }
                else{
                  //alert("Pattern Matched");
                 errors["joblevelPattern"] = "";
                 this.setState({
                   errors:errors
                  })
                  flagJobLevel=true;
                  joblevelEmpty=true;
                }

      }
    }//else of >1
    }// end of >0
    if(dynamicjobLevelLength==0)
    {
      errors["NoFields"]=<li>No Data Present</li>;
      this.setState({
        errors:errors
      });
      flagJobLevel=false;
      joblevelEmpty=true;
    }
  
    this.setState({
      flagJobLevelState:flagJobLevel
     })
    if(flagJobLevel==true)
    {
      errors["success1"]=<li>Successfully Entered Custom Job Level Values</li>;
      this.setState({errors:errors});
      joblevelEmpty=true;
    }
    else
    {
      errors["success1"]="";
      this.setState({errors:errors});
    }



         } // end of addJOBLevel




         addJobLevelBack(e)
         {
           let errors = {};
           var flagJobLevel=true;
             
       let jobLevel=[...this.state.customJobLevelPopupBack];
       
         var dynamicjobLevelLength =jobLevel.length;
          //alert("dynamicjobFunctionLength====>"+dynamicjobFunctionLength);
       
        // var customJobLevelPopupLength=(Object.keys(this.state.customJobFunctionPopup).length);
     
        if(dynamicjobLevelLength >0){
         if(dynamicjobLevelLength > 1)
         {
           
   
           for(var i=0;i<dynamicjobLevelLength;i++)
           {
    
             if ((jobLevel[i].jobLevel==="" ))
             {
              //  alert("Blank");
               errors["joblevelundefined"]=<li>Please Enter Job Function  Value</li>;
               this.setState({
                 errors:errors
               });
               flagJobLevel=false;
               return;
             }
             else
             {
              //  alert("Present");
     
               var pattern = new RegExp(/^[a-zA-Z\s\-\/\,\'\&]*$/);
               if (!pattern.test(jobLevel[i].jobLevel))
                       {
                        
                         //alert("alpha");
                           errors["joblevelPattern"] = <li>Please Enter Alphabatical Values </li>;
                           this.setState({
                             errors:errors
                            })
                            flagJobLevel=false;
                            joblevelEmptyBack=false;
                            return;
                       }
                       else{
                        // alert("Pattern Matched");
                        errors["joblevelPattern"] = "";
                        this.setState({
                          errors:errors
                         })
                         flagJobLevel=true;
                         joblevelEmptyBack=true;
                       }
     
             }
   
   
           }//end of for loop
         }// end of >1
         else
         {
          //  alert("dynamicjobFunctionLength < 1");
          //  alert(jobLevel[0].jobLevel)
         if ((jobLevel[0].jobLevel===""  || jobLevel[0].jobLevel===undefined ))
         {
            //  alert("Blank 2");
           errors["joblevelundefined"]=<li>Please Enter Min One Value</li>;
                 this.setState({
                   errors:errors
                 });
                 flagJobLevel=false;   
         }
         else
         {
           // alert("Present");
   
           var pattern = new RegExp(/^[a-zA-Z\s\-\/\,\'\&]*$/);
           if (!pattern.test(jobLevel[0].jobLevel))
                   {
                       //  alert("Pattern  not a pattern");
                     
                       errors["joblevelPattern"] = <li>Please Enter Alphabatical Values </li>;
                       this.setState({
                         errors:errors
                        })
   
                        flagJobLevel=false;
                        joblevelEmptyBack=false;
                   }
                   else{
                   //  alert("Pattern Matched");
                    errors["joblevelPattern"] = "";
                    this.setState({
                      errors:errors
                     })
                     flagJobLevel=true;
                     joblevelEmptyBack=true;
                   }
   
         }
       }//else of >1
       }// end of >0
       if(dynamicjobLevelLength==0)
       {
        errors["NoFields"] = <li>No Data Present</li>;
        this.setState({
          errors:errors
         })
         flagJobLevel=false;
         joblevelEmptyBack=true;
       }     
       this.setState({
         flagJobLevelState:flagJobLevel
        })
       
       if(flagJobLevel==true)
       {
         errors["success1"]=<li>Successfully Entered Custom Job Level Values</li>;
         this.setState({errors:errors});
         joblevelEmptyBack=true;
       }
       else
       {
         errors["success1"]="";
         this.setState({errors:errors});
       }
   
   
   
         }//addjoblevelBack

  // /**
  //      * @author Supriya Gore
  //      * @param  Description handle the Custom Question  Forward
  //      * @return Description return Custom Question
  //      */
  // customQuestionPopupHandleChange(i,e)
  // {
  //   alert(e.target.name+":=>"+e.target.value);
  //   var name="question"
  //  const { value } = e.target;
     
  //      let customQuestionsPopup = [...this.state.customQuestionsPopup];
  //      customQuestionsPopup[i] = { ...customQuestionsPopup[i], [name]: value };
  //     // alert(JSON.stringify(customEmployeeSizePopupBack));
  //      this.setState({ customQuestionsPopup });
   
  // }



  // /**
  //      * @author Supriya Gore
  //      * @param  Description handle the Custom Answer Forward
  //      * @return Description return Custom Answer
  //      */

  // customAnswerPopupHandleChange(i,e)
  // {
  //   alert(e.target.name+":=>"+e.target.value);
  //   var name="answer"
  //  const { value } = e.target;
     
  //      let customAnswersPopup = [...this.state.customAnswersPopup];
  //      customAnswersPopup[i] = { ...customAnswersPopup[i], [name]: value };
  //     // alert(JSON.stringify(customEmployeeSizePopupBack));
  //      this.setState({ customAnswersPopup });
   
  // }

  // /**
  //      * @author Supriya Gore
  //      * @param  Description handle the Custom Question Back
  //      * @return Description return Custom Question
  //      */
  // customQuestionPopupHandleChangeBack(i,e)
  // {
  //   alert(e.target.name+":=>"+e.target.value);
  //   var name="customQuestion"
  //  const { value } = e.target;
     
  //      let questionDetail = [...this.state.questionDetail];
  //      questionDetail[i] = { ...questionDetail[i], [name]: value };
  //     // alert(JSON.stringify(customEmployeeSizePopupBack));
  //      this.setState({ questionDetail });
   
  // }

  // /**
  //      * @author Supriya Gore
  //      * @param  Description handle the Custom Answer Back
  //      * @return Description return Custom Answer
  //      */
  // customAnswerPopupHandleChangeBack(i,e)
  // {
  //   alert(e.target.name+":=>"+e.target.value);
  //   var name="answer"
  //  const { value } = e.target;
     
  //      let questionDetail = [...this.state.questionDetail];
  //      questionDetail[i] = { ...questionDetail[i], [name]: value };
  //     // alert(JSON.stringify(customEmployeeSizePopupBack));
  //      this.setState({ questionDetail });
   
  // }



  cplHandleChange(e)
  {
    let fields = this.state;
    var name=e.target.name;
   
    var value=e.target.value;
    fields[e.target.name] = e.target.value;
    
    var value1=this.state.leadAllocation;
    
    var name1="budget"
    var newbudget=value*value1;
    var f=newbudget.toFixed(2)
    
        this.setState({
          fields,  [name1]: f
        });

  }


  budgetHandleChange(e)
  {
    let fields = this.state;
    var name=e.target.name;
    var value=e.target.value;
    fields[e.target.name] = e.target.value;
    
    var value1=this.state.leadAllocation;
    
    var name1="cpl"
    var newCPL=value/value1;
    var n=newCPL.toFixed(2);
  
        this.setState({
          fields,  [name1]: n
        });

  }

  cplHandleChangeBack(e)
  {
    let fields = this.state;
    var name=e.target.name;
    var value=e.target.value;

   // alert("CPL===>"+e.target.name+"====>"+e.target.value);
    fields[e.target.name] = e.target.value;
    
    var value1=this.state.campaignDetail[0].leadAllocation;
    
    var name1="budget"
    var newbudget=value*value1;
    newbudget=newbudget.toFixed(2)
    fields[name1] = newbudget;

    

    // const { name, value } = e.target;

    let campaignDetail = [...this.state.campaignDetail];
    campaignDetail[0] = { ...campaignDetail[0], [name1]: newbudget };
    campaignDetail[0] = { ...campaignDetail[0], [name]: e.target.value };
    
    this.setState({ campaignDetail:campaignDetail});
    //alert("campaignDetail===>"+JSON.stringify(campaignDetail));
   
     

  }


  budgetHandleChangeBack(e)
  {
    
    let fields = this.state;
    var name=e.target.name;
    var value=e.target.value;
    fields[e.target.name] = e.target.value;
   
    var value1=this.state.campaignDetail[0].leadAllocation;
    //alert(value+"valu1 is==>"+value1)

    
    var name1="CPL"
    var newCPL=value/value1;

   
    var n=newCPL.toFixed(2);
    //alert(n)
    fields[name1] = n;
    // const { name, value } = e.target;
    //alert("New CPL===>"+n);
    let campaignDetail = [...this.state.campaignDetail];
    campaignDetail[0] = { ...campaignDetail[0], [name]: value };
    campaignDetail[0] = { ...campaignDetail[0], [name1]: n };
    this.setState({ campaignDetail });
  
        // this.setState({
        //   [name1]: newCPL
        // });

  }


  leadAllocationHandleChangeBack(e)
  {
 
  let fields = this.state;
  var name=e.target.name;
  var value=e.target.value;
  fields[e.target.name] = e.target.value;

  let campaignDetail = [...this.state.campaignDetail];
  campaignDetail[0] = { ...campaignDetail[0], [name]: value };
  //Sonali;Budget and Cpl values getting reflected as infinity
  //  this.setState({ campaignDetail:campaignDetail });
  this.state.campaignDetail=[...campaignDetail];

   let fields1 = this.state.campaignDetail[0];
   let errors = {};

    //  //LeadAllocation Validation

   if (fields1["leadAllocation"] == undefined) 
   {
  
   leadAllocationValidate = false;
   errors["leadAllocation"] = <li>Please fill lead allocation</li>;
    }
  else
  {
          if(fields1["leadAllocation"]=="")
        {
          // alert("has a value but entered after removal:"+e.target.value);
           var pattern = new RegExp(/^(|[1-9]\d*)$/);
           var newleadAllocation=e.target.value;
           if (!pattern.test(e.target.value))
        {

        //  alert("Not a pattern");
         leadAllocationValidate = false;
          errors["leadAllocation"] = <li>Please enter number for lead allocation</li>;
        }
        else
        {
   //       alert("Pattern Matches entered after removal ");
          leadAllocationValidate=true;
          var cpl= this.state.campaignDetail[0].CPL;
          var budget=this.state.campaignDetail[0].budget;
  
        // alert("Values CPL and Budget=====>"+cpl+"====="+budget);

         var value1=e.target.value;
         var name1="budget"
         var newbudget=cpl*value1;
         newbudget=newbudget.toFixed(2)
         fields[name1] = newbudget;
       
         let campaignDetail = [...this.state.campaignDetail];
         campaignDetail[0] = { ...campaignDetail[0], [name1]: newbudget };
         campaignDetail[0] = { ...campaignDetail[0], [name]: value };
         this.setState({ campaignDetail });




        }

        
        }
        else{
          // alert("has a value:"+this.state.leadAllocation);
          var pattern = new RegExp(/^(|[1-9]\d*)$/);

        if (!pattern.test (fields1["leadAllocation"]) || e.target.value==""||e.target.value==null||e.target.value==undefined )
        {
         // alert("Not a pattern");
         leadAllocationValidate = false;
          errors["leadAllocation"] = <li>Please enter number for lead allocation</li>;
        }
        else
        {
        //  alert("Pattern Matches");
         leadAllocationValidate=true;
          var cpl= this.state.campaignDetail[0].CPL;
          var budget=this.state.campaignDetail[0].budget;
  
      //  alert("Values CPL and Budget=====>"+cpl+"====="+budget);

         var value1=e.target.value;
         var name1="budget"
         var newbudget=cpl*value1;
         fields[name1] = newbudget;
     //    alert("Lead Allocation===>"+value1+"===="+cpl);
      
         let campaignDetail = [...this.state.campaignDetail];
         campaignDetail[0] = { ...campaignDetail[0], [name1]: newbudget };
         //campaignDetail[0] = { ...campaignDetail[0], [name]: value };
         this.setState({ campaignDetail });
        }
        }
  }// end of big else
  if(leadAllocationValidate==false)
{
this.setState({
  errors: errors,
  alertBorder: '1px solid red',
  alertWidth: '1000px',
  alertAlign: 'center',
alertDisplay: 'block',
//spanSpaceDisplay: spanDisplay
});
document.body.scrollTop = 0;
document.documentElement.scrollTop = 0;



}
else{
this.setState({
  errors: "",
  alertBorder: '0px solid white',
  alertWidth: '1000px',
  alertAlign: 'center',
// alertDisplay: 'block',
//spanSpaceDisplay: spanDisplay
});
document.body.scrollTop = 0;
document.documentElement.scrollTop = 0;
}



  }// end of leadAllocationHandleBAck

  // lpApprovalHandleChange(e)
  // {
  //   let fields = this.state;
  //   fields[e.target.name] = e.target.value;
  //   alert("handle change of lp approval"+e.target.value); 
  //   if(e.target.value='Yes')
  //   {
  //     alert("display div")

  //   }
  //   else if(e.target.value='No')
  //   {
  //     alert("remove")
  //   }
  // }

  leadAllocationHandleChange(e)
  {
let errors={};
let fields = this.state;
fields[e.target.name] = e.target.value;

this.setState({
  fields
});
 //LeadAllocation Validation

 if (this.state.leadAllocation == undefined) 
 {

 
  }
else
{
        if(this.state.leadAllocation=="")
      {
       // alert("has a value but null:"+this.state.leadAllocation);
        errors["leadAllocation"] = <li>Please fill below required field leadAllocation</li>;
        leadAllocationValidate = false;
      }
      else{
      //   alert("has a value:"+this.state.leadAllocation);
        var pattern = new RegExp(/^(|[1-9]\d*)$/);

      if (!pattern.test (fields["leadAllocation"])) 
      {
       // alert("Please enter valid number for lead allocation");
       leadAllocationValidate = false;
        errors["leadAllocation"] = <li>Please enter valid number for lead allocation</li>;
      }
      else{
       // alert("Pattern Matched");

        var cpl=this.state.cpl;
        var budget=this.state.budget;

       // alert(cpl+"====="+budget);


        var cplValue=isNaN(cpl);
        var budgetValue=isNaN(budget);
        

       if(cplValue==true)
       {
         // alert("CPL NaN");

      var name1="cpl"
        var newCPL=budget/this.state.leadAllocation;
 
        this.setState({
            [name1]: newCPL
        });

       }
       else
       {
       

          var value1=this.state.leadAllocation;
    
          var name1="budget"
          var newbudget=cpl*value1;
          newbudget=newbudget.toFixed(2)
              this.setState({
                 [name1]: newbudget
              });


       }

       
       if(budgetValue==true)
       {
      

      var name1="budget"
        var newBudget=cpl/this.state.leadAllocation;
   // alert("New CPL===> "+newCPL);
        this.setState({
            [name1]: newBudget
        });

       }
       else
       {
       


       }

        


        errors["leadAllocation"] = "";
        leadAllocationValidate = true;
      }
      }
} 
  
if(leadAllocationValidate==false)
{
  this.setState({
    errors: errors,
    alertBorder: '1px solid red',
    alertWidth: '1000px',
    alertAlign: 'center',
    alertDisplay: 'block',
  //spanSpaceDisplay: spanDisplay
});
document.body.scrollTop = 0;
document.documentElement.scrollTop = 0;


}
else{
  this.setState({
    errors: "",
    alertBorder: '0px solid white',
    alertWidth: '1000px',
    alertAlign: 'center',
  // alertDisplay: 'block',
  //spanSpaceDisplay: spanDisplay
});
document.body.scrollTop = 0;
document.documentElement.scrollTop = 0;
}
}

stateFilehandleChange(e)
{
  e.preventDefault();
  /**
 * @author Narendra Phadke
 * @param  Description  Get the state file
 */
  let filestateerrors={}
  let filesData = e.target.files;
  let filesData1 = e.target.files[0];
  statefilelength=filesData.length;
  let  stateValidate=true;
  

  if(statefilelength!==undefined)
  {
      // alert("length defined in formvalid()");
  var allowedFiles = [ ".xls",".xlsx",".csv"];
  var stateFile = document.getElementById("stateFile"); 
   
  var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

  if (!regex.test(stateFile.value.toLowerCase())) {
    filestateerrors["fileextensions"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
      stateValidate=false;

  // alert("Please upload files having extensions:");
   
  }

  // if (industryFileLength > 0) {
    this.setState({
      inputClass1: "valid",
       filestateerrors:filestateerrors,
      stateFileData: filesData1
    })
   
  //}
  }
}


statehandleChange(e)
{
//  alert("handle change checkbox");
  let fields = this.state;
  const {name, value } = e.target;
  fields[e.target.name] = e.target.value;
    // alert("Checked or not===>"+e.target.name+":"+e.target.checked);
    if (e.target.checked===true) {
      fields[e.target.name] = 'Yes'
    } else {
      fields[e.target.name] = 'No'
    }
    // alert(this.state.stateChk)


var statefile=document.getElementById("statefile");
    if(this.state.stateChk=='Yes')
    {
      // alert("checkbox yes");
      statefile.style.display="block";

    }
    else
    {
      // alert("checkbox no");
      statefile.style.display="none";
    }

    if(this.state.stateChk=='No')
    {
        this.fileInputstate.value = "";
    }
}




cityFilehandleChange(e)
{
  e.preventDefault();
  /**
 * @author Narendra Phadke
 * @param  Description  Get the city file
 */

  let  filecityerrors={}
  let filesData = e.target.files;
  let filesData1 = e.target.files[0];
   cityfilelength=filesData.length;
  let cityValidate=true;

  // alert(cityfilelength)
  if(cityfilelength!==undefined)
  {
      // alert("length defined in formvalid()");
  var allowedFiles = [ ".xls",".xlsx",".csv"];
  var cityFile = document.getElementById("cityFile"); 
   
  var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

  if (!regex.test(cityFile.value.toLowerCase())) {
    filecityerrors["fileextensions"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
   cityValidate=false;

  // alert("Please upload files having extensions:");
   
  }
  // if (industryFileLength > 0) {
    this.setState({
      filecityerrors:filecityerrors,
      cityFileData: filesData1
    })

}
}


cityhandleChange(e)
{
//  alert("handle change checkbox");
  let fields = this.state;
 
  fields[e.target.name] = e.target.value;
    // alert("Checked or not===>"+e.target.name+":"+e.target.checked);
    if (e.target.checked===true) {
      fields[e.target.name] = 'Yes'
    } else {
      fields[e.target.name] = 'No'
    }
    // alert(this.state.stateChk)


var cityfile=document.getElementById("cityfile");
    if(this.state.cityChk=='Yes')
    {
      // alert("checkbox yes");
      cityfile.style.display="block";

    }
    else
   {
      // alert("checkbox no");
      cityfile.style.display="none";
    }

    if(this.state.cityChk=='No')
    {
     this.fileInputcity.value = "";
    }
}




zipcodeFilehandleChange(e)
{
  /**
 * @author Narendra Phadke
 * @param  Description  Get the zip code file
 */

  let fileerrors ={};
  let zipValidate=true;
  let filesData = e.target.files;
  let filesData1 = e.target.files[0];
  zipfilelength=filesData.length;
  // alert(zipfilelength)
  this.state.zipname=filesData[0].name;
  //  alert(JSON.stringify(this.state.zipname));


    if(zipfilelength!==undefined)
    {
        // alert("length defined in formvalid()");
    var allowedFiles = [ ".xls",".xlsx",".csv"];
    var zipcodeFile = document.getElementById("zipcodeFile"); 
     
    var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

    if (!regex.test(zipcodeFile.value.toLowerCase())) {
     fileerrors["fileextensions"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
     zipValidate=false;

    //  alert("Please upload files having extensions:");
     
    }
    

}

    this.setState({
      fileerrors:fileerrors,
      zipCodeFileData: filesData1
    })

    
 }
 handleLeadvalidation(e)
 {

  var jobTitleValidation=e.target.checked;
  // alert("value --->"+jobTitleValidation)
   if(jobTitleValidation==true){
    jobTitleValidation='Yes';
   }
   else{
    jobTitleValidation='No';
   }
 
   this.setState({jobTitlechk:jobTitleValidation})
 
  }
  
  

zipcodehandleChange(e)
{
//  alert("handle change checkbox");
  let fields = this.state;
  const {name, value } = e.target;
  fields[e.target.name] = e.target.value;
    // alert("Checked or not===>"+e.target.name+":"+e.target.checked);
    if (e.target.checked===true) {
      fields[e.target.name] = 'Yes'
    } else {
      fields[e.target.name] = 'No'
    }
    // alert(this.state.stateChk)


var zipcodefile=document.getElementById("zipcodefile");
    if(this.state.zipcodeChk=='Yes')
    {
      // alert("checkbox yes");
      zipcodefile.style.display="block";

    }
    else
    {
    //  alert("checkbox no");
      //  document.getElementById("zipcodefile").innerHTML="";
       zipcodefile.style.display="none";
      // this.setState({zipname:"})
      //  alert(JSON.stringify(this.state.zipname));
   }

      if(this.state.zipcodeChk=='No')
      {
          this.fileInputzipcode.value = "";
      }


}


stateFilehandleChangeBack(e)
{
  /**
 * @author Narendra Phadke
 * @param  Description  Get the zip code file
 */

var parsed = queryString.parse(this.props.location.search);
var campID = parsed.campID;

  let statefileerrors ={};
  let stateValidate=true;
  let filesData = e.target.files;
  let filesData1 = e.target.files[0];
  statefilelengthback=filesData.length;
  this.state.stateFileLengthBack=filesData.length;


  var names=[];
  // alert(statefilelength)
  // alert(filesData[0].name)
  if(statefilelengthback!==undefined)
    {
        // alert("length defined in formvalid()");
    var allowedFiles = [ ".xls",".xlsx",".csv"];
    var stateFile = document.getElementById("stateFile"); 
     
    var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

    if (!regex.test(stateFile.value.toLowerCase())) {
     statefileerrors["fileextensions"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
     stateValidate=false;

    //  alert("Please upload files having extensions:");
    this.setState({statefileerrors:statefileerrors})
     
    }
    else
    {
      this.setState({statefileerrors:''})
    }
    }
 
  


  for (var i = 0, l = statefilelengthback; i < l; i++) 
  {
     names[i]=filesData[i].name;
  }

  var link;
  var statefilename='';
  var arrayLength=names.length;
  var HTML = "<table>";
         for (var j = 0; j <  arrayLength; j++)
          {
           link= '<a class="removeFile" href="#" data-fileid='+'"'+names[j]+'"'+'><i class="fa fa-times"></i></a>';
           HTML += "<tr><td>"+link+"&nbsp;&nbsp;&nbsp;&nbsp;"+names[j]+"</td></tr>";
         }
           HTML += "</table>";
           document.getElementById("stateList").innerHTML = HTML;

           $(document).on("click",".removeFile", function(e)
           {
           e.preventDefault();
           var clickedFile = $(this).parent().children("a").data("fileid");
           var j=0
           for (j; j <  names.length; j++)
           {
             var temp=j;
             
             if(names[temp] === clickedFile)
             {
               
               statefilename=names[temp];
               names.splice(j, 1);
               $(this).parent().remove(); 
               arrayLength=names.length;
               
        
              
            
                    /**
                    * @author Narendra Phadke
                    * @param  Delete File From Database
                    */
                   
                   let state="No";
                   let typeOfFile="state";
                     let data={
                       campID:campID,
                       state:state,
                       typeOfFile:typeOfFile
                     }
                     
                     fetch("campaign/createCampaignDeleteFile",{
                       method: 'POST',
                     headers: {'Content-Type': 'application/json'},
                       //mode:'no-cors',
                     //body: data
                     body:  JSON.stringify(data)
                     })  
               
             }}
            });
            let state="Yes";
            let typeOfFile="state";
          var data = new FormData();  
         data.append("campID",campID);
         //data.append("names",names);
        data.append("value",state);
        data.append("typeOfFile",typeOfFile);
        data.append("file",filesData1);
         fetch("campaign/createCampaignUploadFiles",{
           method: 'POST',
         body:  data
         }).then(function(response) {
           if (response.status >= 400) {
             throw new Error("Bad response from server");
           }
           return response.json();
         }).then(function(response) { 
         console.log("Return with response");
           console.log(response);
     
         
         }).catch(function(err) {
           console.log(err)
         });
       } 
cityFilehandleChangeBack(e)
{
  /**
 * @author Narendra Phadke
 * @param  Description  Get the zip code file
 */
var parsed = queryString.parse(this.props.location.search);
var campID = parsed.campID;
  let cityfileerrors ={};
  let cityValidate=true;
  let filesData = e.target.files;
  let filesData1 = e.target.files[0];
   cityfilelengthback=filesData.length;
   this.state.cityFileLengthBack=filesData.length;
  var names=[];
 


  if(cityfilelengthback!==undefined)
    {
        // alert("length defined in formvalid()");
    var allowedFiles = [ ".xls",".xlsx",".csv"];
    var cityFile = document.getElementById("cityFile"); 
     
    var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

    if (!regex.test(cityFile.value.toLowerCase())) {
     cityfileerrors["fileextensions"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
     cityValidate=false;

    //  alert("Please upload files having extensions:");
    this.setState({cityfileerrors:cityfileerrors})
     
    }
    else
    {
      this.setState({cityfileerrors:''})
    }
    }
 
  for (var i = 0, l = cityfilelengthback; i < l; i++) 
  {
     names[i]=filesData[i].name;
  }

  var link;
  var cityfilename='';
  var arrayLength=names.length;
  var HTML = "<table>";
         for (var j = 0; j <  arrayLength; j++)
          {
           link= '<a class="removeFile" href="#" data-fileid='+'"'+names[j]+'"'+'><i class="fa fa-times"></i></a>';
           HTML += "<tr><td>"+link+"&nbsp;&nbsp;&nbsp;&nbsp;"+names[j]+"</td></tr>";
         }
           HTML += "</table>";
           document.getElementById("cityList").innerHTML = HTML;

           $(document).on("click",".removeFile", function(e)
           {
           e.preventDefault();
           var clickedFile = $(this).parent().children("a").data("fileid");
           var j=0
           for (j; j <  names.length; j++)
           {
             var temp=j;
             
             if(names[temp] === clickedFile)
             {
               cityfilename=names[temp];
               names.splice(j, 1);
               $(this).parent().remove(); 
               arrayLength=names.length;

                /**
                    * @author Narendra Phadke
                    * @param  Delete File From Database
                    */
                                   
                   let city="No";
                   let typeOfFile="city";
                     let data={
                       campID:campID,
                       city:city,
                       typeOfFile:typeOfFile
                     }
                     
                     fetch("campaign/createCampaignDeleteFile",{
                       method: 'POST',
                     headers: {'Content-Type': 'application/json'},
                       //mode:'no-cors',
                     //body: data
                     body:  JSON.stringify(data)
                     })  

             }}
            });

            let city="Yes";
            let typeOfFile="city";
          var data = new FormData();  
         data.append("campID",campID);
         //data.append("names",names);
        data.append("value",city);
        data.append("typeOfFile",typeOfFile);
        data.append("file",filesData1);
         fetch("campaign/createCampaignUploadFiles",{
           method: 'POST',
         body:  data
         }).then(function(response) {
           if (response.status >= 400) {
             throw new Error("Bad response from server");
           }
           return response.json();
         }).then(function(response) { 
         console.log("Return with response");
           console.log(response);
     
         
         }).catch(function(err) {
           console.log(err)
         });

       } 

zipcodeFilehandleChangeBack(e)
{
  /**add
 * @author Narendra Phadke
 * @param  Description  Get the zip code file
 */
var parsed = queryString.parse(this.props.location.search);
var campID = parsed.campID;
  let zipfileerrors ={};
  let zipValidate=true;
  let filesData = e.target.files;
  let filesData1 = e.target.files[0];
   zipfilelengthback=filesData.length;
   this.state.zipFileLengthBack=filesData.length;
  var names=[];
  //  alert(zipfilelengthback)
 

   if(zipfilelengthback!==undefined)
    {
        // alert("length defined in formvalid()");
    var allowedFiles = [ ".xls",".xlsx",".csv"];
    var zipcodeFile = document.getElementById("zipcodeFile"); 
     
    var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

    if (!regex.test(zipcodeFile.value.toLowerCase())) {
     zipfileerrors["fileextensions"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
     zipValidate=false;

    //  alert("Please upload files having extensions:");
    this.setState({zipfileerrors:zipfileerrors})
     
    }
    else
    {
      this.setState({zipfileerrors:''})
      
    }
    }

  for (var i = 0, l = zipfilelengthback; i < l; i++) 
  {
     names[i]=filesData[i].name;
  }

  var link;
  var zipfilename='';
  var arrayLength=names.length;
  var HTML = "<table>";
         for (var j = 0; j <  arrayLength; j++)
          {
           link= '<a class="removeFile" href="#" data-fileid='+'"'+names[j]+'"'+'><i class="fa fa-times"></i></a>';
           HTML += "<tr><td>"+link+"&nbsp;&nbsp;&nbsp;&nbsp;"+names[j]+"</td></tr>";
         }
           HTML += "</table>";
           document.getElementById("zipcodeList").innerHTML = HTML;

           $(document).on("click",".removeFile", function(e)
           {
           e.preventDefault();
           var clickedFile = $(this).parent().children("a").data("fileid");
           var j=0
           for (j; j <  names.length; j++)
           {
             var temp=j;
             
             if(names[temp] === clickedFile)
             {
               zipfilename=names[temp];
               names.splice(j, 1);
               $(this).parent().remove(); 
               arrayLength=names.length;

               
                /**
                    * @author Narendra Phadke
                    * @param  Delete File From Database
                    */
                   var parsed = queryString.parse(this.props.location.search);
                   var campID = parsed.campID;
                   let zipCode="No";
                   let typeOfFile="zipCode";
                     let data={
                       campID:campID,
                       zipCode:zipCode,
                       typeOfFile:typeOfFile
                     }
                     
                     fetch("campaign/createCampaignDeleteFile",{
                       method: 'POST',
                     headers: {'Content-Type': 'application/json'},
                       //mode:'no-cors',
                     //body: data
                     body:  JSON.stringify(data)
                     })  

             }}
            });

            let zipCode="Yes";
            let typeOfFile="zipCode";
          var data = new FormData();  
         data.append("campID",campID);
         //data.append("names",names);
        data.append("value",zipCode);
        data.append("typeOfFile",typeOfFile);
        data.append("file",filesData1);
         fetch("campaign/createCampaignUploadFiles",{
           method: 'POST',
         body:  data
         }).then(function(response) {
           if (response.status >= 400) {
             throw new Error("Bad response from server");
           }
           return response.json();
         }).then(function(response) { 
         console.log("Return with response");
           console.log(response);
     
         
         }).catch(function(err) {
           console.log(err)
         });
       }   
    

    dynamicCityFile(e) {
      e.preventDefault();
      /**
                    * @author Narendra Phadke
                    * @param  Delete File From Database
                    */
                   var parsed = queryString.parse(this.props.location.search);
                   var campID = parsed.campID;
                   let city="No";
                   let typeOfFile="city";
                     let data={
                       campID:campID,
                       city:city,
                       typeOfFile:typeOfFile
                     }
                     
                     fetch("campaign/createCampaignDeleteFile",{
                       method: 'POST',
                     headers: {'Content-Type': 'application/json'},
                       //mode:'no-cors',
                     //body: data
                     body:  JSON.stringify(data)
                     })  
  document.getElementById("cityfilesName").innerHTML="";
this.state.cityFileLengthBack=0;
// this.setState({
//   checked1:false
// })
          }

          dynamicStateFile(e) {
            e.preventDefault();
            /**
          * @author Narendra Phadke
          * @param  Delete File From Database
          */

         var parsed = queryString.parse(this.props.location.search);
         var campID = parsed.campID;
         let state="No";
         let typeOfFile="state";
           let data={
             campID:campID,
             state:state,
             typeOfFile:typeOfFile
           }
           
           fetch("campaign/createCampaignDeleteFile",{
             method: 'POST',
           headers: {'Content-Type': 'application/json'},
             //mode:'no-cors',
           //body: data
           body:  JSON.stringify(data)
           })  
        document.getElementById("statefilesName").innerHTML="";
       this.state.stateFileLengthBack=0;
      
      // this.setState({checked2:false})
      
    
                }

    dynamicZipFile(e) {
      e.preventDefault();
      /**
                    * @author Narendra Phadke
                    * @param  Delete File From Database
                    */
                   var parsed = queryString.parse(this.props.location.search);
                   var campID = parsed.campID;
                   let zipCode="No";
                   let typeOfFile="zipCode";
                     let data={
                       campID:campID,
                       zipCode:zipCode,
                       typeOfFile:typeOfFile
                     }
                     
                     fetch("campaign/createCampaignDeleteFile",{
                       method: 'POST',
                     headers: {'Content-Type': 'application/json'},
                       //mode:'no-cors',
                     //body: data
                     body:  JSON.stringify(data)
                     })  
  document.getElementById("fileName").innerHTML="";
  this.state.zipFileLengthBack=0;
  // this.setState({
  //   checked:false
  // })
 
          }

zipcodehandleChangeBack(e)
{  
  // alert("handle change checkbox");
this.setState({checked:!this.state.checked})

  let fields = this.state;
  const {name, value } = e.target;
  fields[e.target.name] = e.target.value;
    // alert("Checked or not===>"+e.target.name+":"+e.target.checked);
    if (e.target.checked===true) {
      fields[e.target.name] = 'Yes'
    } else {
      fields[e.target.name] = 'No'
    }
    // alert(this.state.stateChk)

if(this.state.zipcodeChk=='Yes')
{
this.setState({zipcodeBack:'Yes'})
}
else

{
  this.setState({zipcodeBack:'No'})
}
var zipcodefile=document.getElementById("zipcodefile");
    if(this.state.zipcodeChk=='Yes')
    {
      // alert("checkbox yes");
      zipcodefile.style.display="block";

    }
    else
    {
      // this.setState({ key: Math.random() })
      // alert("checkbox no");
      zipcodefile.style.display="none";
    }

    if(this.state.zipcodeChk=='No')
    {
      // alert("file refresh====>"+ document.getElementById("fileName"));
    
      if(document.getElementById("fileName")!==null)
      {
         document.getElementById("fileName").innerHTML="";
      } 
     
    }

    if(this.state.zipcodeChk=='No')
    {
      // alert("file refresh====>"+ document.getElementById("fileName"));
      document.getElementById("zipcodeList").innerHTML="";
     
    }
    
}



statehandleChangeBack(e)
{  
  // alert("handle change checkbox state");
this.setState({checked2:!this.state.checked2})

  let fields = this.state;
  const {name, value } = e.target;
  fields[e.target.name] = e.target.value;
    // alert("Checked or not===>"+e.target.name+":"+e.target.checked);
    if (e.target.checked===true) {
      fields[e.target.name] = 'Yes'

    } else {

      fields[e.target.name] = 'No'
    }
    // alert(this.state.stateChk)
    if(this.state.stateChk=='Yes')
    {
    this.setState({stateBack:'Yes'})
    }
    else
    {
      this.setState({stateBack:'No'})
    }



var statefile=document.getElementById("statefile");
    if(this.state.stateChk=='Yes')
    {
      // alert("checkbox yes");
      statefile.style.display="block";

    }
    else
    {
      // alert("checkbox no");
      statefile.style.display="none";
    }

    

    if(this.state.stateChk=='No')
    {
      // alert("file refresh====>"+ document.getElementById("fileName"));
      if(document.getElementById("statefilesName")!==null)
      {
            document.getElementById("statefilesName").innerHTML="";
      } 
     
    }

    if(this.state.stateChk=='No')
    {
      // alert("file refresh====>"+ document.getElementById("fileName"));
      document.getElementById("stateList").innerHTML="";
     
    }
}

cityhandleChangeBack(e)
{  
  // alert("handle change checkbox");
this.setState({checked1:!this.state.checked1})

  let fields = this.state;
  const {name, value } = e.target;
  fields[e.target.name] = e.target.value;
    // alert("Checked or not===>"+e.target.name+":"+e.target.checked);
    if (e.target.checked===true) {
      fields[e.target.name] = 'Yes'
    } else {
      fields[e.target.name] = 'No'
    }
    // alert(this.state.stateChk)
    if(this.state.cityChk=='Yes'){
      this.setState({cityBack:'Yes'})
    }else
    {
      this.setState({cityBack:'No'})
    }

var cityfile=document.getElementById("cityfile");
    if(this.state.cityChk=='Yes')
    {
      // alert("checkbox yes");
      cityfile.style.display="block";

    }
    else
    {
      // alert("checkbox no");
      cityfile.style.display="none";
    }

   

    if(this.state.cityChk=='No')
    {
      // alert("file refresh====>"+ document.getElementById("fileName"));
      if(document.getElementById("cityfilesName")!==null)
      {
            document.getElementById("cityfilesName").innerHTML="";
      } 
     
    }

    if(this.state.cityChk=='No')
    {
      // alert("file refresh====>"+ document.getElementById("fileName"));
      document.getElementById("cityList").innerHTML="";
     
    }
}

lpApprovalhandleChange(e)
{
  let fields = this.state;
  fields[e.target.name] = e.target.value;
  //  alert("Handlee Change"+e.target.value);
   const { name, value } = e.target;
      
   let campaignDetail = [...this.state.campaignDetail];
   campaignDetail[0] = { ...campaignDetail[0], [name]: value };
   this.setState({ campaignDetail });

   
   var timeline=document.getElementById("timeline");
  if(this.state.requiredLPApproval=='Yes')
  {
    // alert("display block")
  timeline.style.display=campaignDetail="block";
  }
  else 
  {
    // alert("no")
    timeline.style.display=campaignDetail="none";
  }
 
}


lpApprovalhandleChangeBack(e)
{
  let fields = this.state;
  fields[e.target.name] = e.target.value;
  //  alert("Handlee Change"+e.target.value);
   const { name, value } = e.target;
      
   let campaignDetail = [...this.state.campaignDetail];
   campaignDetail[0] = { ...campaignDetail[0], [name]: value };
   this.setState({ campaignDetail });

   
   var timeline=document.getElementById("timeline");
  if(campaignDetail.requiredLPApproval=='Yes')
  {
    // alert("display block")
  timeline.style.display=campaignDetail="block";
  }
  else 
  {
    // alert("no")
    timeline.style.display=campaignDetail="none";
  }
 
}

handleChangeMarketingChannel(e)
{
  if(e.target.value=="TeleMarketing"||e.target.value=="Email/Telemarketing")
  {
    // alert("bloack")
this.setState({displayCallaudit:'block'})
  }
  else
  {
    this.setState({displayCallaudit:'none'})
  }
}
handleChangeMarketingChannelBack(e)
{
  if(e.target.value=="TeleMarketing"||e.target.value=="Email/Telemarketing")
  {
    // alert("bloack")
this.setState({displayCallauditBack:'block'})
  }
  else
  {
    // if()
    this.setState({displayCallauditBack:'none'})
  }
}
  handleChange(e)
   {
  // alert("herer")  
  
  
     var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
    if (campID == undefined || campID == null || campID == ""){
    
      let fields = this.state;
    fields[e.target.name] = e.target.value;
  
  
  //alert(this.state.requiredLPApproval);
 if (typeof fields["industry"] !== "undefined") {
      //this.state.industry1='false';
      this.setState({
        inputClass1: "valid"
      })
    }
   
    // var {name,value}=e.target; 
    // let dynamicpacing = [...this.state.dynamicpacing];
    //  this.setState({ dynamicpacing: dynamicpacing});

    this.setState({
      fields
    });
   
   

    //alert(JSON.stringify(fields))
  }//handle change for create flow pacing modal
  
  else{//handle change for back on pacing modal
        const { name, value } = e.target;
        let campaignDetail = [...this.state.campaignDetail];
        campaignDetail[0] = { ...campaignDetail[0], [name]: value };
        this.state.campaignDetail=[...campaignDetail];
        this.setState({ campaignDetail:campaignDetail });
if(e.target.name==="pacingUnit")
{

        let pacingCampaignDetails = [...this.state.pacingCampaignDetails];
        pacingCampaignDetails[0] = { ...pacingCampaignDetails[0], [name]: value };
        this.state.pacingCampaignDetails=[...pacingCampaignDetails];
        this.setState({ pacingCampaignDetails:pacingCampaignDetails,pacingUnit:e.target.value });
        this.setState({pacingUnit:e.target.value});
}
        //alert("pacingCampaignDetails===>"+JSON.stringify(this.state.pacingCampaignDetails));
       
    }
      
  }

  /*@author Narendra Phadke
 *Desc for delete the files
 */
handleDeleteFile(e) {
  // var fields =this.state;
  var value = [];
  var fileName = e.target.id;
  var industryFile="industryFile";
    let campaignDetail=this.state.campaignDetail[0];
    var campID=this.state.campaignDetail[0].campID;
var clickedFile = $(this).parent().children("a").data("fileid");
$(this).parent().remove(); 
delete campaignDetail[industryFile];
delete campaignDetail['industryFileName'];
this.setState({campaignDetail:campaignDetail})
      /**
      * @author Narendra Phadke
      * @param  Delete File From Database
      */
     
      let data={
      campID:campID     
      }
      fetch("campaign/DeleteBackButtonIndustryFile",{
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      //mode:'no-cors',
      //body: data
      body:  JSON.stringify(data)
      });
}

/*@author Narendra Phadke
 *Desc for delete the files
 */
handleDeleteDeliveryFile(e) {
  // var fields =this.state;
  var value = [];
  var fileName = e.target.id;
  var leadDeliveryFormat="leadDeliveryFormat";
  
    let campaignDetail=this.state.campaignDetail[0];
    var campID=this.state.campaignDetail[0].campID;

delete campaignDetail[leadDeliveryFormat];
delete campaignDetail['leadDeliveryFileName'];

this.setState({campaignDetail:campaignDetail})
      /**
      * @author Narendra Phadke
      * @param  Delete File From Database
      */
     
      let data={
      campID:campID     
      }
      fetch("campaign/DeleteBackButtonDeliveryFile",{
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      //mode:'no-cors',
      //body: data
      body:  JSON.stringify(data)
      });
}



  /*@author Narendra Phadke
  *Desc for multiselect option for country
  */
  handleChangeMultiselectCountry(e, checked) {
    // var fields =this.state;
    var value = [];
    //fields[e.target.name] = e.target.options;
    var options = e.target.options;
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      country: value
    });

  }

  /*@author Narendra Phadke
*Desc for multiselect option for jobLevel
*/
  handleChangeMultiselectJobLevel(e, checked) {
    // var fields =this.state;
    var value = [];
    //fields[e.target.name] = e.target.options;
    var options = e.target.options;
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      jobLevel: value
    });

  }
 
//   handleChangetimezone(e)
//   {

//     let fields = this.state;
//     fields[e.target.name] = e.target.value;
//     // alert(this.state.timeZone);
//       if(this.state.timeZone1 ==='false')
//        {
//         this.setState({
//           inputClass: "valid", fields
         
//       });
//        }
//        else{
//         this.setState({
//            fields
//       });    
//     }
// }


handleChangetimezone = (value) => 
  {
    console.log(`Option selected:`, value);
 var timeZonevalue=[];
  const name="timeZoneRevenue";
    
        timeZonevalue.push(value.name);            
        this.setState({ selectedOption7:value,timeZone:value,timeZone1:timeZonevalue});
  //  alert(JSON.stringify(timeZonevalue))
  }

/*@author Narendra Phadke
 *Desc for select timezone
 */
// handleChangetimezoneBack(e)
// {

//   const { name, value } = e.target;
//   let campaignDetail = [...this.state.campaignDetail];
//   campaignDetail[0] = { ...campaignDetail[0], [name]: value };
//   this.setState({ campaignDetail:campaignDetail });
  
// }

handleChangetimezoneBack = (value) => 
  {
    console.log(`Option selected:`, value);
 var timeZonevalue=[];
  const name="timezone";
   
      timeZonevalue.push(value.name);
    
    //create dynamic array
    var campaignDetail = [...this.state.campaignDetail];
    campaignDetail[0] = {...campaignDetail[0], [name]: timeZonevalue};
  // alert("back"+companyRevenuevalue);
  
    this.setState({ selectedOption7:value,timeZone:value ,campaignDetail:campaignDetail});
  //  alert(JSON.stringify(timeZonevalue))
  }



  /*@author Narendra Phadke
 *Desc for multiselect option for employeeSize
 */
  handleChangeMultiselectemployeeSize(e, checked) {
    // var fields =this.state;
    var value = [];
    //fields[e.target.name] = e.target.options;
    var options = e.target.options;
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      employeeSize: value
    });

  }

 




  /*@author Narendra Phadke
*Desc for multiselect option for jobFunction
*/
  handleChangeMultiselectJobFunction(e, checked) {
    // var fields =this.state;
    // var value = [];
    //fields[e.target.name] = e.target.options;
    var options = e.target.options;
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      jobFunction: value
    });

  }


  //handle File Selection
  handleselectedFile = event => {
    let fields = this.state;
    fields[event.target.name] = event.target.files[0];
    this.setState({
      fields
    });
  }

  
  /*@author Narendra Phadke
      *Desc Fetch all information of create campaign 
      */
  submitCampaign(e) {
    e.preventDefault();
e.target.className += "was-validated";
    // 
      // const {isAuthenticated, user} = this.props.auth;
      // this.state.createdBy=user.name;
      var parsed = queryString.parse(this.props.location.search);
      var campID = parsed.campID;
  // alert(this.state.CreativeValue.length)
      if (campID == undefined || campID == null || campID == "")
    {
      //Narendra Commit validation
      if (this.validateForm() === false) {
        // alert("validate")
        return;
      }
     else {
       this.setState({
          alertDisplay: 'none',loading:'whirly-loader'
        });
      }

var customRevenueFromLength =(Object.keys(this.state.customRevenuePopupFromTo).length);
//var customRevenueToLength=(Object.keys(this.state.customRevenueToPopup).length);
var customRevenueTextLength=(Object.keys(this.state.customRevenueTextPopup).length);
 //var customRevenueFromPopup= parseInt(this.state.customRevenueFromPopup);
// var customRevenueToPopup=parseInt(this.state.customRevenueToPopup);
var revenuecreate=[];
// var customRevenueFromPopup={};
// var customRevenueToPopup={};
/*********Narendra Comment */
// for(var i=1;i<=customRevenueFromLength;i++)
// {
//   for(var j=i;j<=i;j++)
//   {
//     var count=i-1;
//     var count1=j-1;
//    revenuecreate.push({From:this.state.customRevenueFromPopup[count1].From,To:this.state.customRevenueToPopup[count1].To});
//    }
//   this.setState({revenuecreate:revenuecreate});
// };
//revenuecreate=[...this.state.customRevenueFromPopup];
for(var i=1;i<=customRevenueTextLength;i++)
{
  var count=i-1;
  this.state.customRevenuePopupFromTo.push({CustomRevenue:this.state.customRevenueTextPopup[count].customCompanyRevenue});
  this.setState({revenuecreate:revenuecreate});
};
/*****************End */

var customEmpFromLength =(Object.keys(this.state.customEmployeeSizeFromPopup).length);
//var customEmpToLength=(Object.keys(this.state.customEmployeeSizeToPopup).length);
var customEmpTextLength=(Object.keys(this.state.customEmployeeSizePopUpText).length);
var customEmployeeSize=[];
// for(var i=1;i<=customEmpFromLength;i++)
// {
//  for(var j=i;j<=i;j++)
//   {
//     var count=i-1;
//     var count1=j-1;
//     customEmployeeSize.push({From:this.state.customEmployeeSizeFromPopup[count1].From,To:this.state.customEmployeeSizeToPopup[count1].To});
//     }
//   this.setState({customEmployeeSize:customEmployeeSize});
// }
//customEmployeeSize=[...this.state.customRevenuePopupFromTo];
for(var i=1;i<=customEmpTextLength;i++)
{
  var count=i-1;
  this.state.customEmployeeSizeFromPopup.push({customEmployeeSizeText:this.state.customEmployeeSizePopUpText[count].customEmployeeSizeText});
  this.setState({customEmployeeSize:customEmployeeSize});
};

if(this.state.zipcodeChk=='No')
{
  // alert("no");
  this.setState({zipCodeFileData:null})
}
else
{
  // alert("has a value");
}


if(this.state.cityChk=='No')
{
  // alert("no");
  this.setState({cityFileData:null})
}
else
{
  // alert("has a value");
}



if(this.state.stateChk=='No')
{
  // alert("no");
  this.setState({stateFileData:null})
}
else
{
  // alert("has a value");
}
      const {isAuthenticated, user} = this.props.auth;
      var agencyID=user.id;
     
      /**********Narendra I will comment this code because now some files add then need to use formData */
    //   let data={
    //     agencyID:agencyID,
    //     campaignName: this.state.campaignName,
    //   clientName: this.state.clientName,
    //   clientCampID: this.state.clientCampID,
    //   startDate: this.state.startDate,
    //   endDate: this.state.endDate,
    //   timeZone:this.state.timeZone1,
    //   region:this.state.regiondb,
    //   country:this.state.country1,
    //   jobLevel: this.state.jobLevel1,
    //   jobFunction: this.state.jobFunction1,
    //   insertionOrder: this.state.insertionOrder,
    //   marketingChannel: this.state.marketingChannel,
    //   pacing: this.state.pacing,
    //   pacingLeadAllocation: this.state.pacingLeadAllocation,
    //   campaignReportingDay: this.state.campaignReportingDay,
    //   industry: this.state.industry1,
    //   customIndustry:this.state.customIndustry,
    //   campaignStatus: this.state.campaignStatus,
    //   createdByCompanyName:  this.state.createdBy,
    //   leadDeliveryOption: this.state.leadDeliveryOption,
    //   noOfLeadPerDomain: this.state.noOfLeadPerDomain,
    //   leadAllocation: this.state.leadAllocation,
    //   abm: this.state.abm,
    //   jobTitle: this.state.jobTitle,
    //   otherSpecs: this.state.otherSpecs,
    //   employeeSize: this.state.employeeSize1,
    //   companyRevenue:this.state.companyRevenue1,
    //   //leadDeliveryFormat: this.state.uploadFormatFile,
    //  // industryFile: this.state.industryFileData,
    //   customCompRevenue:revenuecreate,
      
    //   customEmpSize:empcreate,
    //   budget: this.state.budget,
    //   cpl: this.state.cpl,
    //   currency: this.state.currency,
    //   requiredLPApproval: this.state.requiredLPApproval,
    //   lpTimeline: this.state.lpTimeline,
    //   customJobFunction:this.state.customJobFunctionPopup,
    //   customQuestion:this.state.customQuestionsPopup,
    //   answer:this.state.customAnswersPopup,
    //   firstLeadDeliveryDate:this.state.firstLeadDeliveryDate

    //   }

    /***********Narendra end comment */


    
      /**
     * @author Narendra Phadke
     * @param  Description  add form data because files only support formData
     * @return Description return response to Campaign page
     */
        //alert("New array==>"+JSON.stringify(this.state.creativearrayValue))
         var data = new FormData();  
      data.append("agencyID", agencyID);
      data.append("campaignName", this.state.campaignName);
      data.append("clientName", this.state.clientName);
      data.append("clientCampID", this.state.clientCampID);
      data.append("startDate", this.state.startDate);
      data.append("endDate", this.state.endDate);
      data.append("timeZone",this.state.timeZone1);
      data.append("region",this.state.regiondb);
      data.append("country",this.state.country1);
      data.append("jobLevel", this.state.jobLevel1);
      data.append("jobFunction", this.state.jobFunction1);
      data.append("insertionOrder", this.state.insertionOrder);
      data.append("marketingChannel", this.state.marketingChannel);
      data.append("pacing", this.state.pacing);
      data.append("pacingLeadAllocation", this.state.pacingLeadAllocation);
      data.append("campaignReportingDay", this.state.campaignReportingDay);
      data.append("industry", this.state.industry1);
      data.append("customIndustry",this.state.customIndustry);
      data.append("campaignStatus", this.state.campaignStatus);
      data.append("createdByCompanyName",  this.state.createdBy);
      data.append("leadDeliveryOption", this.state.leadDeliveryOption);
      data.append("noOfLeadPerDomain", this.state.noOfLeadPerDomain);
      data.append("leadAllocation", this.state.leadAllocation);
      data.append("abm", this.state.abm);
      data.append("jobTitle", this.state.jobTitle);
      data.append("jobTitlechk", this.state.jobTitlechk);

      data.append("otherSpecs", this.state.otherSpecs);
      data.append("employeeSize", this.state.employeeSize1);
      data.append("companyRevenue",this.state.companyRevenue1);
      // data.append("leadDeliveryFormat", this.state.uploadFormatFile);
      // data.append("industryFile", this.state.industryFileData);
      data.append("customCompRevenue", JSON.stringify(this.state.customRevenuePopupFromTo));
      data.append("customEmpSize", JSON.stringify(this.state.customEmployeeSizeFromPopup));
      data.append("budget", this.state.budget);
      data.append("cpl", this.state.cpl);
      data.append("currency", this.state.currency);
      //data.append("requiredLPApproval", this.state.requiredLPApproval);
      data.append("requiredLPApproval", JSON.stringify(this.state.creativearrayValue));
      data.append("callAudit",this.state.callAudit);
      data.append("creativeApprovalRequiredFor",this.state.creativeApprovalRequiredFor);
      data.append("lpTimeline", this.state.lpTimeline);
      data.append("customJobFunction", JSON.stringify(this.state.customJobFunctionPopup));
      data.append("customJobLevel", JSON.stringify(this.state.customJobLevelPopup));
      data.append("customQuestion", JSON.stringify(this.state.customQuestionsPopup));
      //data.append("answer", this.state.customAnswersPopup);
      data.append("firstLeadDeliveryDate", this.state.firstLeadDeliveryDate);
      //Narendra - Adding some filds like city, state, zipcode files
      data.append("stateFileCheck", this.state.stateChk);
      data.append("stateFileData", this.state.stateFileData);
      data.append("cityFileCheck", this.state.cityChk);
      data.append("cityFileData", this.state.cityFileData);
      data.append("zipCodeFileCheck", this.state.zipcodeChk);
      data.append("zipCodeFileData", this.state.zipCodeFileData);
      data.append("empSizeApplicable", this.state.empSizeNAValue);
      data.append("industryApplicable", this.state.industryNAValue);
      data.append("pacingCampaignDetails", JSON.stringify(this.state.dynamicpacing));
      data.append("pacingUnit", this.state.pacingUnit);
      data.append("leadInteractionDays", this.state.leadInteractionDays);
    //  /campaign/createCampaign
 //alert(JSON.stringify(this.state.customQuestionsPopup))   
fetch("/campaign/createCampaign", {
  method: 'POST',
 // mode:'no-cors',
 //'Accept': 'application/json',
 // headers: { "Content-Type": "multipart/form-data" },
 // headers: { 'content-type': 'multipart/form-data' },
  body: data
}).then(res => res.json())
.then(response=> { 
    console.log("Return with response");
    //var campID=response.body.campID; 
    this.setState({response:response,loading:""});

       var campID=response.insertId;
     
   
    
      window.location.href = '/supportingDocument?campID='+campID+'';
      if(data === "success"){
        console.log("Inside if loop");
         this.refs.msg.show('Some text or component', {
            time: 2000,
            type: 'success',
            icon: <img src="path/to/some/img/32x32.png" />
          })
        }
      }).catch(function (err) {
        console.log(err)
      });


      
    }
    else{
     
      if (this.validateForm() === false) {
        return;
      }
     else {
       this.setState({
          alertDisplay: 'none'
        });
      }
    
      var  customRevenuePopFrom=(Object.keys(this.state.customRevenuePopupBackFromTo).length)
      var  customRevenuePopTo=(Object.keys(this.state.customRevenuePopupBackTo).length)
    
      var customRevenueTextLength=(Object.keys(this.state.customRevenuePopupBackText).length);
      var revenuecreateBack=[];
    
      // for(var i=1;i<=customRevenuePopFrom;i++)
      // {
      //   for(var j=i;j<=i;j++)
      //   {
      //     var count=i-1;
      //     var count1=j-1;
      //     revenuecreateBack.push({From:this.state.customRevenuePopupBackFromTo[count1].From,To:this.state.customRevenuePopupBackTo[count1].To});
      //    }
      //   this.setState({revenuecreateBack:revenuecreateBack});
      // };
      for(var i=1;i<=customRevenueTextLength;i++)
      {
        var count=i-1;
        this.state.customRevenuePopupBackFromTo.push({CustomRevenue:this.state.customRevenuePopupBackText[count].customCompanyRevenueText});
        this.setState({customRevenuePopupBackFromTo:this.state.customRevenuePopupBackFromTo});
      };

    var  customEmpSizePopFromLength=(Object.keys(this.state.customEmployeeSizePopupBackFromTo).length);
    // var  customEmpSizePopToLength=(Object.keys(this.state.customEmployeeSizePopupBackTo).length);
    var customEmpTextLength=(Object.keys(this.state.customEmployeeSizePopupBackText).length);
    var customEmployeeSizeBack=[];
 
    // for(var i=1;i<=customEmpSizePopFromLength;i++)
    // {
    //  for(var j=i;j<=i;j++)
    //   {
    //     var count=i-1;
    //     var count1=j-1;
    //     customEmployeeSizeBack.push({From:this.state.customEmployeeSizePopupBackFromTo[count1].From,To:this.state.customEmployeeSizePopupBackTo[count1].To});
    //     }
    //   this.setState({customEmployeeSizeBack:customEmployeeSizeBack});
    // }
    for(var i=1;i<=customEmpTextLength;i++)
    {
      var count=i-1;
      this.state.customEmployeeSizePopupBackFromTo.push({customEmployeeSizeText:this.state.customEmployeeSizePopupBackText[count].customEmployeeSizeText});
      this.setState({customEmployeeSizePopupBackFromTo:this.state.customEmployeeSizePopupBackFromTo});
    };

        let dataUpdate={
          campID:campID,
          campaignDetail:this.state.campaignDetail,
          region:this.state.regiondb,
          country:this.state.country1,
          jobLevel:this.state.jobLevel1,
          jobFunction:this.state.jobFunction1,
          jobTitleValidation:this.state.jobTitleChk,
          customCompRevenue: this.state.customRevenuePopupBackFromTo,
          customEmployeeSize: this.state.customEmployeeSizePopupBackFromTo,
          customJobFunction:this.state.customJobFunctionPopupBack,
          customQuestion:this.state.questionDetail,
          customIndustry:this.state.customIndustry,
          firstLeadDeliveryDate:this.state.firstLeadDeliveryDate,
          requiredLPApproval:JSON.stringify(this.state.creativearrayValue),
          customJobLevel:JSON.stringify(this.state.customJobLevelPopupBack),
          empSizeApplicable:this.state.empSizeNAValueBack,
          industryApplicable:this.state.industryNAValueBack,
          pacingCampaignDetails:this.state.pacingCampaignDetails,
          pacingUnit:this.state.pacingUnit
        }
//alert("data==>"+JSON.stringify(this.state.campaignDetail))
fetch("/campaign/backButtonCampaign", {
  method: 'POST',
  //mode:'no-cors',
  headers: {'Content-Type': 'application/json'},
   body: JSON.stringify(dataUpdate)
}).then(function (response) {
  if (response.status >= 400) {
    throw new Error("Bad response from server");
  }
  return response.json();   
  }).then(function(response) { 
    console.log("Return with response");
    //var campID=response.body.campID;
      console.log(response);
      var campID=response.campID;
     //alert("Successfully Insert Campaign Details");
      window.location.href = '/supportingDocument?campID='+campID+'&step2';
      if(dataUpdate === "success"){
        console.log("Inside if loop");
         this.refs.msg.show('Some text or component', {
            time: 2000,
            type: 'success',
            icon: <img src="path/to/some/img/32x32.png" />
          })
        }
      }).catch(function (err) {
        console.log(err)
      });



   // }
    }
  }
  /*@author sanobar Golandaj
 *Desc for validating number fields
 */
  validateForm() {
    
        

    var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
    if (campID == undefined || campID == null || campID == "")
    {
    let fields = this.state.fields;

    //alert("jobTitlechk====>"+this.state.jobTitlechk)
    let errors = {};
    let formIsValid = true;
    let spanDisplay = 'none';
   
    let requiredFeildValidate=true;
    let  countryValidate= true;
    let regionValidate=true;
    let jobLevelValidate=true;
   
    let customEmployeeSizeValidate=true;
    let employeePopupValidate=true;
    let jobFunctionValidate=true;
    let companyRevenueValidate=true;
    let customRevenueValidate=true;
    let clientNameValidate=true;
    
    let dateValidate=true;
    let firstDateValidate=true;
  
    // let leadAllocationValidate=true;
    let leadPerDomainValidate=true;
    let pacingLeadValidate=true;
    let budgetValidate=true;
    let cplValidate=true;
    let zipvalidate=true;
    let cityvalidate=true;
    let statevalidate=true;
    let creativesvalidate=true;
    let JobLevelPop=true;
    let JobFunctionPop=true;
    let EmpSizePop=true;
    let RevenuePop=true;
    let IndustryPop=true;
    let InteractionDay=true;
    let campName=true
if(this.state.campaignName!=""||this.state.campaignName.length>0){
var pattern=RegExp(/[?#]/)
if(pattern.test(this.state.campaignName)){
campName=false
errors["campNameError"]=<li>Please enter a valid campaign name</li>;
    this.setState({errors:errors})
}
}
    if(joblevelEmpty==false)
    {
      // alert("enter job level correct value")
      Swal.fire({
            
        text:"Entered wrong value for job level",
        type: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
     
      JobLevelPop=false;
    }
    else
    {
      JobLevelPop=true;
    }


    if(jobfunctionEmpty==false)
    {
      // alert("enter job level correct value")
      Swal.fire({
            
        text:"Entered wrong value for job function",
        type: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
      JobFunctionPop=false;
    }
    else
    {
      JobFunctionPop=true;
    }


    if(empsizeEmpty==false)
    {
      // alert("enter job level correct value")
      Swal.fire({
            
        text:"Entered wrong value for employee size",
        type: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
      EmpSizePop=false;
    }
    else
    {
      EmpSizePop=true;
    }
  

    if(revenueEmpty==false)
    {
      // alert("enter job level correct value")
      Swal.fire({
            
        text:"Entered wrong value for revenue size",
        type: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
      RevenuePop=false;
    }
    else
    {
      RevenuePop=true;
    }


    if(industryEmpty==false)
    {
      // alert("enter job level correct value")
      Swal.fire({
            
        text:"Entered wrong value for industry",
        type: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
      IndustryPop=false;
    }
    else
    {
      IndustryPop=true;
    }

// alert("zipfilelength====>"+zipfilelength);
if(this.state.zipcodeChk=='Yes')
{
  //  alert("checkbox yes");
  if(zipfilelength!==1)
  {
    // alert("enter file");
    zipvalidate=false;
    errors["filemin"]=<li>Please upload file for zipcode</li>;
    this.setState({errors:errors})
  }
  else
  {
    
    if(zipfilelength!==undefined)
    {
    //  alert("has file checking for range");
    var allowedFiles = [ ".xls",".xlsx",".csv"];
    var zipcodeFile = document.getElementById("zipcodeFile"); 
     
    var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

    if (!regex.test(zipcodeFile.value.toLowerCase())) 
    {
    zipvalidate=false;
    errors["fileextension"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
    //  alert("Please upload files having extensions:");
    this.setState({errors:errors})
    }
    else
    {
      // alert("regex matched");
      zipvalidate=true;
    }

  }
  
  }
}
else{
  // alert("checkbox no");
  zipvalidate=true;
  // this.setState({errors:''})
}




if(this.state.cityChk=='Yes')
{
  // alert("checkbox yes");
  if(cityfilelength!==1)
  {
    // alert("enter file"+zipfilelength);
    cityvalidate=false;
    errors["filemin"]=<li>Please upload file for city</li>;
    this.setState({errors:errors})
  }
  else
  {
    // alert("has file"+zipfilelength);
    if(cityfilelength!==undefined)
    {
        // alert("length defined in formvalid()");
    var allowedFiles = [ ".xls",".xlsx",".csv"];
    var cityFile = document.getElementById("cityFile"); 
     
    var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

    if (!regex.test(cityFile.value.toLowerCase())) {
    cityvalidate=false;
    errors["fileextension"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
    //  alert("Please upload files having extensions:");
    this.setState({errors:errors})
    }
    else{
      cityvalidate=true;
    }

  }
  }
}
else{
  // alert("checkbox no");
  cityvalidate=true;
  // this.setState({errors:''})
}



if(this.state.stateChk=='Yes')
{
  // alert("checkbox yes===>"+statefilelength);
  if(statefilelength!==1)
  {
    // alert("enter file"+zipfilelength);
    statevalidate=false;
    errors["filemin"]=<li>Please upload file for state</li>;
    this.setState({errors:errors})
  }
  else
  {
    // alert("has file"+zipfilelength);
    if(statefilelength!==undefined)
    {
        // alert("length defined in formvalid()");
    var allowedFiles = [ ".xls",".xlsx",".csv"];
    var stateFile = document.getElementById("stateFile"); 
     
    var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

    if (!regex.test(stateFile.value.toLowerCase())) {
    statevalidate=false;
    errors["fileextension"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
    //  alert("Please upload files having extensions:");
    this.setState({errors:errors})
    }
    else
    {
      statevalidate=true;
    }

  }
  }
}
else{
  // alert("checkbox no");
  statevalidate=true;
  // this.setState({errors:''})
}



if (this.state.cpl == undefined) 
 {
  cplValidate=false;
  errors["commonError"] = <li>Please fill below required fields</li>;

  }
else 
{

  if(this.state.cpl=="")
  {
 
    errors["commonError"] = <li>Please fill below required fields</li>;
    cplValidate=false;
  }
  else{
   // alert("has a value:"+this.state.cpl);
    var pattern = new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/);

  if (!pattern.test (fields["cpl"])) 
  {
    cplValidate = false;
    errors["cpl"] = <li>Please enter valid number for cpl</li>;
  }
  }

}

 
    //budget Validation

 if (this.state.budget == undefined) 
 {
 

  }
else
{
        if(this.state.budget=="")
      {
     //  alert("has a value but null:"+this.state.leadAllocation);
        errors["commonError"] = <li>Please fill below required fields</li>;
        budgetValidate=false;
      }
      else{
       //  alert("has a value:"+this.state.budget);
        var pattern = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/);

      if (!pattern.test (fields["budget"])) 
      {
        budgetValidate = false;
        errors["budget"] = <li>Please enter valid number upto 2 decimal for budget</li>;
      }
      }
} 

    
  //   //LeadAllocation Validation

  //   if (this.state.leadAllocation == undefined) 
  //   {
    
  //   alert("No value:"+this.state.noOfLeadPerDomain);
    
  //    }
  //  else
  //  {
  //          if(this.state.leadAllocation=="")
  //        {
  //         // alert("has a value but null:"+this.state.leadAllocation);
  //          errors["commonError"] = <li>Please fill below required fields</li>;
  //          leadAllocationValidate = false;
  //        }
  //        else{
  //           alert("has a value:"+this.state.leadAllocation);
  //          var pattern = new RegExp(/^(|[1-9]\d*)$/);

  //        if (!pattern.test (fields["leadAllocation"])) 
  //        {
  //         leadAllocationValidate = false;
  //          errors["leadAllocation"] = <li>Please enter valid number for lead allocation</li>;
  //        }
  //        }
  //  } 
    
  //Industry Field and Industry File upload validation
    if(typeof fields["industry"] == "undefined")
          {
            if (industryFileLength > 0)
            {
              industryValidate=true;
              this.setState({
                inputClass1: "valid"
              })
            }
            else
            {
              this.state.industry1 = 'false';
              industryValidate=false;
              errors["commonError"] = <li>Please fill below required fields</li>;
            //  alert("industry Please fill below required fields");
             this.setState({
                inputClass1: "invalid"
              })
            }
          }

     else
          {
            // alert("present");
            industryValidate=true;
            this.setState({
              inputClass1: "valid"
            })
          }
          
     
    
  //   if (typeof fields["timeZone"] == "undefined") {
  //     timeZoneValidate=false;
  //    alert(" Time Zone undefined");
  //    errors["commonError"] = <li>Please fill below required fields</li>;
  //     this.setState({
  //       inputClass: "invalid"
  //     })
  //   }


  //   if (typeof fields["timeZone"] !== "undefined") {
  //     timeZoneValidate=true;
  //  alert(" time zone defined");
  //     this.setState({
  //       inputClass: "valid"
  //     })
  //   }

 

  //   // timeZone
  //   if(this.state.selectedOption7.length===0)
      
  //   {
  //     // formIsValid = false;
  //     timeZoneValidate= false;
  //    alert("Blank timeZone");
     
  //     this.setState({
  //       inputClass: "invalid"
  //     })
  //      errors["commonError"] = <li>Please fill below required fields</li>;
  //     // alert("required");
  //   }
  //   else
  //   {
  //     timeZoneValidate= true;
  //     // formIsValid = true;
  //  // alert("has timZone")
  //     this.setState({
  //       inputClass: "valid"

  //     })
  //   }




    //Client Name Validation
    var ddl = document.getElementById("client");
    var selectedValue = ddl.options[ddl.selectedIndex].value;
    if (selectedValue == "")
    {
      //  alert("clientName absent");
      clientNameValidate = false;
     errors["commonError"] = <li>Please fill below required fields</li>;
   
    }
    else
    {
       //  alert("clientName Present");
      clientNameValidate = true;
    
    }

   
    
//Lead Per Domain validation
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (this.state.noOfLeadPerDomain == undefined) 
       {
      // alert("No value:"+this.state.noOfLeadPerDomain);
        }
    else
      {
        
        if(this.state.noOfLeadPerDomain=="")
          {
            // alert("has a value but null:"+this.state.noOfLeadPerDomain);
            leadPerDomainValidate = true;
          }
       else
          {
        // alert("has a value:"+this.state.noOfLeadPerDomain);
        var pattern = new RegExp(/^(0|[1-9]\d*)$/);

            if (!pattern.test(fields["noOfLeadPerDomain"])) 
            {
            leadPerDomainValidate = false;
            errors["noOfLeadPerDomain"] = <li>Please enter valid number for lead per domain</li>;
            }
          }  
      }




// Pacing validation
      if (this.state.pacingLeadAllocation == undefined) 
      {
      // alert("No value:"+this.state.pacingLeadAllocation);
       }
     else
     {
       
       if(this.state.pacingLeadAllocation=="")
      {
        pacingLeadValidate = true;
        // alert("has a value but null:"+this.state.pacingLeadAllocation);
      }
      else{
        // alert("has a value:"+this.state.pacingLeadAllocation);
       var pattern = new RegExp(/^(0|[1-9]\d*)$/);

     if (!pattern.test(fields["pacingLeadAllocation"])) 
     {
       pacingLeadValidate = false;
      errors["pacingLeadAllocation"] = <li>Please enter number for pacing count</li>;
     }
     
      }
     }




    // Country Validation
    if(this.state.selectedOption2.length===0)
      
    {
      // formIsValid = false;
      countryValidate= false;
     // alert("Blank country");
     
      this.setState({
        inputClassDropDownCountry: "invalid"
      })
       errors["commonError"] = <li>Please fill below required fields</li>;
      // alert("required");
    }
    else
    {
      countryValidate= true;
      // formIsValid = true;
    //  alert("has country")
      this.setState({
        inputClassDropDownCountry: "valid"

      })
    }



    //Region Validation
    if(this.state.selectedOption6.length===0)
    {
     regionValidate=false;
      this.setState({
        inputClassregion: "invalid"
      })
      errors["commonError"] = <li>Please fill below required fields</li>;
    }
    else
    {
      regionValidate=true;
      this.setState({
        inputClassregion: "valid"

      })
    }
   

// / Employee size  drop down and popup validation
var customEmpFromLength =(Object.keys(this.state.customEmployeeSizeFromPopup).length);
var customEmpToLength=(Object.keys(this.state.customEmployeeSizeToPopup).length);
var customEmpTxtLength=(Object.keys(this.state.customEmployeeSize).length);
if(this.state.selectedOption1.length===0)
{
 employeeSizeValidate=false;
 //alert("Emp Dropdown has no val");
errors["commonError"] = <li>Please fill below required fields</li>;
  this.setState({
    inputClassDropDownEmployeeSize: "invalid",
    errors:errors
  })
  if(this.state.flagFromToState==true||this.state.flagTextState==true)

   {
  //  alert(" pop up has value")
    employeeSizeValidate=true;
    this.setState({
     inputClassDropDownEmployeeSize: "valid",
     errors:''
   })
   }
   else
   {
   // alert("pop up blank"+this.state.flagFromToState+"=="+this.state.flagTextState);
    employeeSizeValidate=false;
    errors["commonError"] = <li>Please fill below required fields</li>;
    this.setState({
     inputClassDropDownEmployeeSize: "invalid",
     errors:errors
   })
    
   }
}
else
{
//alert("dropdown has value");
  
  employeeSizeValidate=true;
  this.setState({
   inputClassDropDownEmployeeSize: "valid",
   errors:''
 })
}
if(this.state.empSizeNAValue=="Not_Applicable")
{
  employeeSizeValidate=true;
  this.setState({inputClassDropDownEmployeeSize:''})
 
}



//Industry Validation


if(this.state.selectedOptionIndustry4.length===0)
{
  // alert("dropdown blank")
  industryValidate=false;
  this.setState({
    inputClassDropDownIndystry: "invalid",
    inputClassCustomIndustry:"invalid",
  })

        if(this.state.customIndustry==undefined||this.state.customIndustry==""||this.state.customIndustry==null)
        {
        //  alert("industry text area blank");
          industryValidate=false;
          errors["commonError"] = <li>Please fill below required fields</li>;
          this.setState({errors:errors,
            inputClassDropDownIndystry: "invalid",
            inputClassCustomIndustry:"invalid",})
        }
        else
        {
          //  alert("industry has value")
          industryValidate=true;
          this.setState({
            inputClassDropDownIndystry: "valid",
            inputClassCustomIndustry:"valid",
          })
        }
}
else
{
  //  alert("drop down has value")
  industryValidate=true;
  this.setState({
    inputClassDropDownIndystry: "valid",
    inputClassCustomIndustry:"valid",
  })
}
if(this.state.industryNAValue=="Not_Applicable")
{
  industryValidate=true;
  this.setState({inputClassDropDownIndystry:''})
  
 
}


  
  //  Validation for required text feild.

if ((!fields["campaignName"]) || (!fields["startDate"]) || (!fields["endDate"])|| (!fields["firstLeadDeliveryDate"])||
  (!fields["jobTitle"]))
 {
  // alert("Feild unavailable"+this.state.firstLeadDeliveryDate);
  requiredFeildValidate = false;
  
      errors["commonError"] = <li>Please fill below required fields</li>;
  
  }



    //  end date validation
    if ((Date.parse(this.state.endDate) < Date.parse(this.state.startDate))) {
      // alert("End date should be greater than Start date");
      dateValidate = false;
      errors["endDate"] = <li>End date must be greater than start date</li>;

    }
    var currentDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
    //  alert("currentDate"+currentDate+this.state.startDate);
    var dt = new Date(currentDate);
    dt.setMonth(dt.getMonth() - 1);
    //  alert(dt+" "+this.state.startDate)
    var month, day, year;
    month = '' + (dt.getMonth() + 1);
    day = '' + dt.getDate();
    year = dt.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var updatedDate = [year, month, day].join('-');


  //  alert("Dates:"+this.state.startDate+"  "+updatedDate);

    if ((Date.parse(this.state.startDate) < Date.parse(updatedDate))) {
      // alert("Error");
      dateValidate = false;
      errors["startDate"] = <li>Start date can not be older than a month time period</li>;
    }

    if (this.state.endDate < currentDate) {
      // alert("Error");
      dateValidate = false;
      errors["endDate"] = <li>End date must be greater than current date</li>;
    }



    if ((Date.parse(this.state.firstLeadDeliveryDate)> Date.parse(this.state.endDate))) {
          firstDateValidate=false;
          // alert(" cant be greater than end date ");
          errors["firstdeliverydateEnddate"]=<li>First lead delivery date can't be greater than end date</li>;
      }

   if ((Date.parse(this.state.firstLeadDeliveryDate)<Date.parse(this.state.startDate))){
        // alert("cant less than start date")
        firstDateValidate=false;
        errors["firstdeliverydateStartdate"]=<li>First lead delivery date can't be less than start date</li>;
    }


    if ((Date.parse(this.state.firstLeadDeliveryDate) < Date.parse(currentDate))){
      //  alert("less  than current")
      firstDateValidate=false;
      errors["firstdeliverydateCurrentDate"]=<li>First lead delivery date must be greater than current date</li>;
  }


     //start of creatives validations
     var counter=0;
var counteremail=0;
//alert("creatives===>"+this.state.CreativeValue.length)
for(var i=0;i<this.state.CreativeValue.length;i++)
  {
    if(this.state.marketingChannel==="Email/Telemarketing")
    {
      // alert("here"+JSON.stringify(this.state.CreativeValue))
      if(this.state.CreativeValue[i].id=="Newsletter"&&this.state.CreativeValue.length==1)
      {
        Swal.fire({
            
          text: ("Selected marketing channel is Email/TeleMarketing, So please select Appropiate Creatives"),
          type: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        })
        creativesvalidate=false;
      }
    }
    if(this.state.marketingChannel==="TeleMarketing")//for display
    {
    
      if(this.state.CreativeValue.length==2)
{
counter=2;
}

      if(this.state.CreativeValue[i].id==="Call Script")
      {counter++;}
      else
      {
        var timeline=document.getElementById("timeline");
        timeline.style.display="none"
        errors["TimeLineerror"] = <li>Selected marketing channel is Telemarketing, So please select Call Script as a Creative</li>;
        //this.state.CreativeValue.length=0;
        Swal.fire({
            
          text: ("Selected marketing channel is Telemarketing, So please select Call Script as a Creative"),
          type: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        })
        creativesvalidate=false;
      }
      if(counter==1)
      {
      creativesvalidate=true;
      }
      if(counter==2)
      {
      creativesvalidate=false;
      }
     
    }// end of  this.state.campaignDetail[0].marketingChannel
    if(this.state.marketingChannel==="Email")
    {
if(this.state.CreativeValue[i].id==="Newsletter")
{
counteremail=1;
}
if(this.state.CreativeValue.length==2)
{
counteremail=2;
}
if(this.state.CreativeValue[i].id==="Call Script")
{
counteremail=1;
}
if(counteremail==2)
{
  creativesvalidate=true;
}
if(counteremail==1)
{
  errors["POcerror"] = <li>Selected marketing channel is E-mail,So please select Landing Page and Newsletter as a Creative</li>;
  creativesvalidate=false;
  // this.state.CreativeValue.length=0;
        Swal.fire({
            
          text: ("Selected marketing channel is email, So please select Landing Page and Newsletter as a Creative"),
          type: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        })
}
    }// end of  this.state.campaignDetail[0].marketingChannel email
  } // end of for
     
//      if(counter==1)
//      {
//       Swal.fire({
             
//         text: ("Selected marketing channel is Display, So please select aprropiate creative"),
//         type: 'error',
//         confirmButtonText: 'Ok',
//         allowOutsideClick: false,
//       })
//       creativesvalidate=false; 
//      }
//   if(counter==2)
//   {
//   //alert("True");
//   creativesvalidate=true;
//   }
// // end for display

// var counter=0;

// for(var i=0;i<this.state.CreativeValue.length;i++)
// {
//   if(this.state.marketingChannel==="Programmatic")//for Programmatic
//   {
//     if(this.state.CreativeValue[i].id==="POC" || this.state.CreativeValue[i].id==="Landing Page")
//     {
     
//       counter++;
   
//   }
//   else
//   {
//     var timeline=document.getElementById("timeline");
//     timeline.style.display="none"
//     Swal.fire({
        
//       text: ("Selected marketing channel is Programmatic, So please select aprropiate creative"),
//       type: 'error',
//       confirmButtonText: 'Ok',
//       allowOutsideClick: false,
//     })
//     creativesvalidate=false;
//   }
  
  
//   }
// }
// if(counter==1)
// {
//  Swal.fire({
        
//    text: ("Selected marketing channel is Programmatic, So please select aprropiate creative"),
//    type: 'error',
//    confirmButtonText: 'Ok',
//    allowOutsideClick: false,
//  })
//  creativesvalidate=false; 
// }
// if(counter==2)
// {
// //alert("True");
// creativesvalidate=true;
// }
// //end for programmatic
// var counter=0;

// for(var i=0;i<this.state.CreativeValue.length;i++)
// {
//   if(this.state.marketingChannel==="Social")//for Social
//   {
//     if(this.state.CreativeValue[i].id==="POC" || this.state.CreativeValue[i].id==="Landing Page")
//     {
     
//       counter++;
   
//   }
//   else
//   {
//     var timeline=document.getElementById("timeline");
//     timeline.style.display="none"
//     Swal.fire({
        
//       text: ("Selected marketing channel is Social, So please select aprropiate creative"),
//       type: 'error',
//       confirmButtonText: 'Ok',
//       allowOutsideClick: false,
//     })
//     creativesvalidate=false;
//   }
  
  
//   }
// }
// if(counter==1)
// {
//  Swal.fire({
        
//    text: ("Selected marketing channel is Social, So please select aprropiate creative"),
//    type: 'error',
//    confirmButtonText: 'Ok',
//    allowOutsideClick: false,
//  })
//  creativesvalidate=false; 
// }
// if(counter==2)
// {
// //alert("True");
// creativesvalidate=true;
// }
// //end for Social
// var counter=0;

// for(var i=0;i<this.state.CreativeValue.length;i++)
// {
//   if(this.state.marketingChannel==="Hybrid")//for hybrid
//   {
//     if(this.state.CreativeValue[i].id==="POC" || this.state.CreativeValue[i].id==="Landing Page")
//     {
     
//       counter++;
   
//   }
//   else
//   {
//     var timeline=document.getElementById("timeline");
//     timeline.style.display="none"
//     Swal.fire({
        
//       text: ("Selected marketing channel is Hybrid, So please select aprropiate creative"),
//       type: 'error',
//       confirmButtonText: 'Ok',
//       allowOutsideClick: false,
//     })
//     creativesvalidate=false;
//   }
  
  
//   }
// }
// if(counter==1)
// {
//  Swal.fire({
        
//    text: ("Selected marketing channel is Hybrid, So please select aprropiate creative"),
//    type: 'error',
//    confirmButtonText: 'Ok',
//    allowOutsideClick: false,
//  })
//  creativesvalidate=false; 
// }
// if(counter==2)
// {
// //alert("True");
// creativesvalidate=true;
// }
// //end for Hybrid


  //  alert("requiredFeildValidate===>"+requiredFeildValidate+"  "+
  //  "countryValidate==>"+countryValidate+"  "+
  // "regionValidate==>"+ regionValidate+"  "+
  // "jobLevelValidate===>"+jobLevelValidate+"  "+
  // "employeeSizeValidate===>"+employeeSizeValidate+"  "+
  //  "jobFunctionValidate===>"+jobFunctionValidate+" "+ 
  //  "companyRevenueValidate==>"+companyRevenueValidate+"  "+
  //  "clientNameValidate==>"+clientNameValidate+"  "+
  //  "industryValidate==>"+industryValidate+"  "+
  //  "dateValidate==>"+dateValidate+" "+
  //  "timeZoneValidate==>"+timeZoneValidate+" "+
  //  "leadAllocationValidate==>"+leadAllocationValidate+" "+
   
   
  //  "customRevenueValidate==>"+customRevenueValidate+" "+"customEmployeeSizeValidate==>"+customEmployeeSizeValidate);
  if(this.state.fields.leadInteractionDays===undefined||this.state.fields.leadInteractionDays===""){}
  else{
    if(this.state.fields.leadInteractionDays==0){
      errors['blankInteractionDays']=<li>Please Enter Value More Than Zero For Lead Interaction Day</li>
      this.setState({errors:errors})
    InteractionDay=false
    }
    var pattern= new RegExp(/^[0-9]\d*$/)
    if(!pattern.test(this.state.fields.leadInteractionDays)){
      // alert("in here")
      errors['blankInteractionDays']=<li>Please Enter Numbers Only For Lead Interaction Day</li>
      this.setState({errors:errors})
    InteractionDay=false
    }
    
  }
// alert(InteractionDay)
 //alert("budgetValidate===>"+budgetValidate+"===="+cplValidate+"creative validate"+creativesvalidate)
  if(campName==false||requiredFeildValidate==false ||  countryValidate==false ||  regionValidate==false || jobLevelValidate==false ||
  employeeSizeValidate==false || jobFunctionValidate==false || companyRevenueValidate == false || clientNameValidate==false
  || industryValidate==false || dateValidate==false ||leadAllocationValidate==false|| pacingLeadValidate==false||leadPerDomainValidate===false||
 budgetValidate==false || cplValidate==false||zipvalidate==false||statevalidate==false
  ||firstDateValidate==false||creativesvalidate==false||JobLevelPop==false||JobFunctionPop==false||EmpSizePop==false||RevenuePop==false||IndustryPop==false||InteractionDay===false)
 
  {
    formIsValid=false;
    this.setState({
      errors: errors,
      alertBorder: '1px solid red',
      alertWidth: '1000px',
      alertAlign: 'center',
    alertDisplay: 'block',
     spanSpaceDisplay: spanDisplay

    });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  


  // alert("formIsValid"+formIsValid);
    return formIsValid;
  }
  else{
  //  alert("In else");
    // in Else block
    let fields;
    let fields1 = this.state.campaignDetail;
    let errors = {};
    let formIsValid = true;
    let spanDisplay = 'none';
    
    let   country3= true;
    let jobLevel3=true;
    let  employeeSize3=true;
    let  companyRevenue3=true;
    let jobfunction3=true;
    // alert("Inside else:==>"+JSON.stringify(fields1));
   

    let requiredFeildValidate=true;
    let  countryValidate= true;
    let regionValidate=true;
    let jobLevelValidate=true;
    let  employeeSizeValidate=true;
    let employeePopupValidate=true;
    let customEmployeeSizeValidate=true;
    let jobFunctionValidate=true;
    let companyRevenueValidate=true;
    let customRevenueValidate=true;
    let clientNameValidate=true;
    let industryValidate=true;
    let dateValidate=true;
    let firstDateValidate=true;
    let timeZoneValidate=true;
    let leadAllocationValidate=true;
    let leadPerDomainValidate=true;
    let pacingLeadValidate=true;
    let budgetValidate=true;
    let cplValidate=true;


    let zipvalidateBack=true;
    let cityvalidateBack=true;
    let statevalidateBack=true;
  let creativesvalidate=true;

  let JobLevelPopBack=true;
  let JobFunctionPopBack=true;
  let EmpSizePopBack=true;
  let RevenuePopBack=true;
  let IndustryPopBack=true;
  let InteractionDayBack=true;
  let countryArray1Back=[];
  let campNameBack=true
  if(this.state.campaignDetail[0].campaignName.length>0||this.state.campaignDetail[0].campaignName!==""){
var pattern=RegExp(/[?#]/)
if(pattern.test(this.state.campaignDetail[0].campaignName)){
campNameBack=false
errors["campNameErrorBack"]=<li>Please enter a valid campaign name</li>;
    this.setState({errors:errors})
}
  }
  if(joblevelEmptyBack==false)
  {
    // alert("enter job level correct value")
    Swal.fire({
          
      text:"Entered wrong value for job level",
      type: 'error',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
    })
    JobLevelPopBack=false;
  }
  else
  {
    JobLevelPopBack=true;
  }


  if(jobfunctionEmptyBack==false)
  {
    // alert("enter job level correct value")
    Swal.fire({
          
      text:"Entered wrong value for job function",
      type: 'error',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
    })
    JobFunctionPopBack=false;
  }
  else
  {
    JobFunctionPopBack=true;
  }


  if(empsizeEmptyBack==false)
  {
    // alert("enter job level correct value")
    Swal.fire({
          
      text:"Entered wrong value for employee size",
      type: 'error',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
    })
    EmpSizePopBack=false;
  }
  else
  {
    EmpSizePopBack=true;
  }


  if(revenueEmptyBack==false)
  {
    // alert("enter job level correct value")
    Swal.fire({
          
      text:"Entered wrong value for revenue size",
      type: 'error',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
    })
    RevenuePopBack=false;
  }
  else
  {
    RevenuePopBack=true;
  }


  if(industryEmptyBack==false)
  {
    // alert("enter job level correct value")
    Swal.fire({
          
      text:"Entered wrong value for industry",
      type: 'error',
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
    })
    IndustryPopBack=false;
  }
  else
  {
    IndustryPopBack=true;
  }
 

let  createzipvalidate=false;
let xlszipvalidate=false;
    if(this.state.zipcodeBack=='Yes' || this.state.zipcodeChk=='Yes')
    {
      //  alert("backc checkbox yes===>"+zipFileLengthBack);
       if(this.state.zipFileLengthBack>0)
       {
          // alert("has file");
          xlszipvalidate=true;
       }
   else
       {
        //  alert("please select file")
        xlszipvalidate=false;
         errors["fileminzipcode"]=<li>Please upload file for zipcode</li>;
        this.setState({errors:errors})
       }
      }

     

       
          

     if(this.state.zipcodeChk=='Yes' ||  this.state.zipcodeBack=='Yes')
{
  //  alert("checkbox yes");
  if(zipfilelengthback!==1)
  {
  // alert("enter file");
  createzipvalidate=false;
    errors["fileminzipcode"]=<li>Please upload file for zipcode</li>;
    this.setState({errors:errors})
  }
  else
  {
    
    if(zipfilelengthback!==undefined)
    {
    // alert("has file checking for range");
    var allowedFiles = [ ".xls",".xlsx",".csv"];
    var zipcodeFile = document.getElementById("zipcodeFile"); 
     
    var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

    if (!regex.test(zipcodeFile.value.toLowerCase())) 
    {
      createzipvalidate=false;
    errors["fileextension"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
    //  alert("Please upload files having extensions:");
    this.setState({errors:errors})
    }
    else
    {
      //  alert("regex matched");
      createzipvalidate=true;
    }

  }
  
  }
}
      
else
{
  createzipvalidate=true;
}


if(createzipvalidate==true || xlszipvalidate==true)
{
  zipvalidateBack=true;
  
  errors["fileminzipcode"]="";
    this.setState({errors:errors})
}
else
{
  // alert("false")

  zipvalidateBack=false;
}


let createcityvalidate=false;
let xlscityvalidate=false;
if(this.state.cityBack=='Yes'  || this.state.cityChk=='Yes' )
{
  
   if(this.state.cityFileLengthBack>0 )
   {
     
      // alert("has file city");
    xlscityvalidate=true;
   }
else
   {
    // alert("please select file city")
    xlscityvalidate=false;
     errors["filemincity"]=<li>Please upload file for city</li>;
     this.setState({errors:errors})
   }
  }

if(this.state.cityChk=='Yes' || this.state.cityBack=='Yes' )
{
  //  alert("checkbox yes");
  if(cityfilelengthback!==1)
  {
  // alert("enter file");
  createcityvalidate=false;
    errors["filemincity"]=<li>Please upload file for city</li>;
    this.setState({errors:errors})
  }
  else
  {
    
    if(cityfilelengthback!==undefined)
    {
    //  alert("has file checking for range");
    var allowedFiles = [ ".xls",".xlsx",".csv"];
    var cityFile = document.getElementById("cityFile"); 
     
    var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

    if (!regex.test(cityFile.value.toLowerCase())) 
    {
      createcityvalidate=false;
    errors["fileextension"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
    //  alert("Please upload files having extensions:");
    this.setState({errors:errors})
    }
    else
    {
    //  alert("regex matched");
    createcityvalidate=true;
    }

  }
  
  }
}


else
{
  //  alert("checkbox no");
  createcityvalidate=true;
  this.setState({errors:''})
}

if(createcityvalidate==true || xlscityvalidate==true)
{
  cityvalidateBack=true;
  errors["filemincity"]="";
     this.setState({errors:errors})
}
else
{
  // alert("false")

  cityvalidateBack=false;
}


let xlsstatevalidate=false;
let createstatevalidate=false;
// alert("back value==>"+this.state.stateBack)
// alert("handle change==>"+this.state.stateChk)

//  alert("back file length==>"+this.state.stateFileLengthBack)
//  alert("handle change file length==>"+statefilelengthback)

 if(this.state.stateBack=='Yes' ||  this.state.stateChk=='Yes')
      {
         
         if(this.state.stateFileLengthBack>0)
         {
            // alert("back  file present");
            xlsstatevalidate=true;
         }
     else
         {
          //  alert("on back please select file state ")
            xlsstatevalidate=false;
           errors["fileminstate"]=<li>Please upload file for state</li>;
           this.setState({errors:errors})
         }
        }
       
        
if(this.state.stateChk=='Yes' ||   this.state.stateBack=='Yes' )
{
  //  alert("on handle change checkbox yes ");
  if(statefilelengthback!==1)
  {
    // alert("enter file handle change");
     createstatevalidate=false;
    errors["fileminstate"]=<li>Please upload file for state</li>;
    this.setState({errors:errors})
  }
  else
  {
    // alert("has file on handle change");
    if(statefilelengthback!==undefined)
    {
        // alert("length defined in formvalid()");
    var allowedFiles = [ ".xls",".xlsx",".csv"];
    var stateFile = document.getElementById("stateFile"); 
     
    var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");

    if (!regex.test(stateFile.value.toLowerCase())) {
      createstatevalidate=false;
    errors["fileextension"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
    //  alert("Please upload files having extensions:");
    this.setState({errors:errors})
    }
    else{
      createstatevalidate=true;
    }
   

  }
  }
}


else

{
// alert("no checkbox")
createstatevalidate=true;
}



if(createstatevalidate==true ||  xlsstatevalidate==true)
{
  statevalidateBack=true;
  errors["fileminstate"]="";
  this.setState({errors:errors})

}
else
{
  // alert("false")
  // alert("inisde false")
  statevalidateBack=false;
}



//  alert("city===>"+cityvalidateBack)
// alert("zip===>"+zipvalidateBack)
// alert("state form is valid==>"+statevalidateBack)


//cpl Validation

if (this.state.campaignDetail[0].CPL == undefined) 
 {
 

 cplValidate=false;
 errors["commonError"] = <li>Please fill below required fields</li>;
  }
else
{
        if(this.state.campaignDetail[0].CPL=="")
      {
    
        errors["commonError"] = <li>Please fill below required fields</li>;
        cplValidate=false;
      }
      else{
        var pattern= new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/);
        if (!pattern.test(this.state.campaignDetail[0].CPL))
        {
          cplValidate = false;
          errors["cpl"] = <li>Please enter valid number for cpl</li>;
        }
      }
} 


//Budget Validation

if (this.state.campaignDetail[0].budget == undefined) 
 {
 
 
  }
else
{
        if(this.state.campaignDetail[0].budget=="")
      {
  //      alert("has a value but null:"+this.state.leadAllocation);
        errors["commonError"] = <li>Please fill below required fields</li>;
        budgetValidate=false;
      }
      else{
        var pattern= new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/);
        if (!pattern.test(this.state.campaignDetail[0].budget))
        {
          budgetValidate = false;
          errors["budget"] = <li>Please enter valid number upto 2 decimal for budget</li>;
        }
      }
} 



   //Industry File and feild validation
    //  alert(JSON.stringify(this.state.campaignDetail[0].industry));
    if (this.state.campaignDetail[0].industry=== undefined || this.state.campaignDetail[0].industry==="") {
      // alert("textarea empty:"+industryFileLength);
      if (industryFileLength > 0) {
        //  alert("textarea empty but has uploded industry");
        this.setState({
          inputClass1: "valid"
        })
      }
      else {
        //  alert("textarea empty but no uploded industry");
        this.state.industry1 = 'false';
        errors["commonError"] = <li>Please fill below required fields</li>;
        // alert("industry1 Please fill below required fields");
        industryValidate = false;
        this.setState({
          inputClass1: "invalid"
        })
      }
    }
    else{
      //  alert("textarea has value");
      this.setState({
        inputClass1: "valid"
      })
    }

    

      
    //LeadAllocation Validation

    if (fields1[0].leadAllocation == undefined) 
    {
    
    //alert("No value:"+this.state.noOfLeadPerDomain);
     }
   else
   {
           if(fields1[0].leadAllocation=="")
         {
          leadAllocationValidate = false;
          // alert("has a value but null:"+this.state.leadAllocation);
           errors["leadAllocation"] = <li>Please fill below required fields</li>;
         }
         else{
           // alert("has a value:"+this.state.leadAllocation);
           var pattern = new RegExp(/^(|[1-9]\d*)$/);

         if (!pattern.test (fields1[0].leadAllocation)) 
         {
          leadAllocationValidate = false;
           errors["leadAllocation"] = <li>Please enter number for lead allocation</li>;
         }
         }
   }
 
 

//NoOfLeadPerDomain Validation
    if (fields1[0].noOfLeadPerDomain == undefined) 
       {
         
      // alert("No value:"+this.state.noOfLeadPerDomain);
        }
      else
      {
              if(fields1[0].noOfLeadPerDomain=="")
            {
              // alert("has a value but null:"+this.state.noOfLeadPerDomain);
              leadPerDomainValidate=true;
            }
            else{
              // alert("has a value:"+this.state.noOfLeadPerDomain);
              var pattern = new RegExp(/^(0|[1-9]\d*)$/);

            if (!pattern.test (fields1[0].noOfLeadPerDomain)) 
            {
              leadPerDomainValidate = false;
              errors["noOfLeadPerDomain"] = <li>Please enter number for lead per domain</li>;
            }
            }
      }

//Pacing Count Validation
// pacing count back
      if (fields1[0].pacingLeadAllocation == undefined) 
      {
    //  alert("No value:"+this.state.pacingLeadAllocation);
       }
     else
     {
             if(fields1[0].pacingLeadAllocation=="")
           {
            pacingLeadValidate=true;
            //  alert("has a value but null:"+this.state.pacingLeadAllocation);
           }
           else{
              // alert("has a value:"+this.state.pacingLeadAllocation);
             var pattern = new RegExp(/^(0|[1-9]\d*)$/);

           if (!pattern.test (fields1[0].pacingLeadAllocation)) 
           {
            pacingLeadValidate = false;
            errors["pacingLeadAllocation"] = <li>Please enter number for pacing count</li>;
           }
           }
     }


     // region back
 if(this.state.region.length===0)
 {
   // formIsValid = false;
   regionValidate= false;
  
  
   this.setState({
    inputClassregion: "invalid"
   })
   errors["commonError"] = <li>Please fill below required fields</li>;
 }
 else
 {
  let regionCountryInfo=this.state.regionCountryInfo;
  var regionBack=this.state.campaignDetail[0].region;
  regionBack=regionBack.toString();
  var checkRegion=regionoptions.filter(function(a){
    return regionBack.includes(a.name);
  });

  if(regionBack.split(',').length>checkRegion.length)
  {
    var regionValue=[];
    for(var j=0;j<checkRegion.length;j++)
    {
      regionValue.push(checkRegion[j].name);
  
    }
    
    regionValue=regionValue.toString();
    let campaignDetail = [...this.state.campaignDetail];
    var name="region"
    campaignDetail[0] = { ...campaignDetail[0], [name]:regionValue};
     this.setState({region:checkRegion,campaignDetail})
     
//formation of country array
     for(var j=0;j<checkRegion.length;j++)
     {
     var regionValues=checkRegion[j].name;
     alert(regionValues)
     for (var country in regionCountryInfo[regionValues])
      {
      countryArray1Back.push({id:country, name:country});
      // alert(JSON.stringify(countryArray1))
      }  

     }
     regionValidate= true;
    }
 }


    // industry back
if(this.state.industry.length===0)
{
      // formIsValid = false;
      industryValidate= false;
      // alert("industry dropdown in back unchecked");
//
      if(this.state.campaignDetail[0].customIndustry==0)
      {
          // alert(" industry textarea blank"+fields1[0].customIndustry);
          industryValidate= false;
          errors["commonError"] = <li>Please fill below required fields</li>;
          this.setState({errors:errors,
            inputClassDropDownIndystry: "invalid"})
      }
        else
        {
          
          //  alert("texarea has value"+fields1[0].customIndustry)
           industryValidate= true;
          this.setState({
            inputClassDropDownIndystry: "valid"
          })
        }
}
else
{
     industryValidate= true;
      //  alert("industry dropdown has value")
      this.setState({
        inputClassDropDownIndystry: "valid"
        })
}

if(this.state.industryNAValueBack=="Not_Applicable")
{
  industryValidate= true;
  this.setState({inputClassDropDownIndystry:''})
}


// country back
 if(this.state.country.length===0 || this.state.country==="" )
    {
      // formIsValid = false;
      countryValidate= false;
   
     
      this.setState({
        inputClassDropDownCountry1: "invalid"
      })
      errors["commonError"] = <li>Please fill below required fields</li>;
    }
    else
        {let regionCountryInfo=this.state.regionCountryInfo;
          if(this.state.region.length>0)
          {
          for(var j=0;j<checkRegion.length;j++)
          {
          var regionValues=checkRegion[j].name;
          // alert(regionValues)
          for (var country in regionCountryInfo[regionValues])
           {
           countryArray1Back.push({id:country, name:country});
           // alert(JSON.stringify(countryArray1))
           }  
     
          }
        }
            // countryValidate= false; 
          if(this.state.campaignDetail[0].region!=="")
          {
            var countryBack=this.state.campaignDetail[0].country
            countryBack=countryBack.toString();
          var regCountry=this.state.regionCountryInfo
          
      // alert(JSON.stringify(countryBack)+"=====>"+JSON.stringify(countryArray1Back))
      var checkCountry=countryArray1Back.filter(function(a){
        return countryBack.includes(a.name);
      });
      // alert(JSON.stringify(checkCountry))
      this.setState({country:checkCountry})
    }
      if(countryBack.split(',').length>checkCountry.length)
      {
        countryValidate= true;    
      }
   if(checkCountry.length===0)
   {
    errors["commonError"] = <li>Please select Proper countries</li>;
    countryValidate= false; 
  }
      this.setState({
        inputClassDropDownCountry1: "valid"

      })
    }


    



    
    

    var ddl = document.getElementById("clientName");

    var selectedValue = ddl.options[ddl.selectedIndex].value;
    if (selectedValue == "")
    {
  
     clientNameValidate = false;
     errors["commonError"] = <li>Please fill below required fields</li>;
    }
    else
    {
      clientNameValidate = true;
     
    }




       
          
// Employee size dropdown and  popup validation Back
var  customEmpSizePopFrom=(Object.keys(this.state.customEmployeeSizePopupBackFromTo).length)
 //var  customEmpSizePopTo=(Object.keys(this.state.customEmployeeSizePopupBackTo).length)


if(this.state.campaignDetail[0].employeeSize.length===0)
{
  employeeSizeValidate=false;
 //alert("drop down blank")
errors["commonError"] = <li>Please fill below required fields</li>;
 this.setState({
    inputClassDropDownEmployeeSize: "invalid",
    errors:errors
  })
//alert(this.state.flagFromToState+"==="+this.state.flagTextState);
  if(this.state.flagFromToState==true||this.state.flagTextState==true)

  {
   // alert(" pop up has value")
   employeeSizeValidate=true;
   this.setState({
    inputClassDropDownEmployeeSize: "valid",
    errors:''
  })
  }
  else
  {
  // alert("pop up blank"+this.state.flagFromToState+"=="+this.state.flagTextState);
   employeeSizeValidate=false;
   errors["commonError"] = <li>Please fill below required fields</li>;
   this.setState({
    inputClassDropDownEmployeeSize: "invalid",
    errors:errors
  })
   
  }
      
    }
else
{

//alert("dropdown has value");
  employeeSizeValidate=true;

this.setState({ inputClassDropDownEmployeeSize: "valid",
  errors:''})

 
}
if(this.state.empSizeNAValueBack=="Not_Applicable")
{
  employeeSizeValidate=true;
  this.setState({inputClassDropDownEmployeeSize:''})
}

// alert("employeeSizeValidate===>"+employeeSizeValidate);
//  Required Fields  Validation
   
    
    if ( (!fields1[0].campaignName) || (!fields1[0].startDate) || (!fields1[0].endDate)||(!fields1[0].firstLeadDeliveryDate)||(!fields1[0].jobTitle))
       {
        requiredFeildValidate = false;
     
      errors["commonError"] = <li>Please fill below required fields</li>;
   
    }

    //  end date validation
    if ((Date.parse(fields1[0].endDate) < Date.parse(fields1[0].startDate))) {
     
      dateValidate = false;
      errors["endDate"] = <li>End date must be greater than start date</li>;
    }
    var currentDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
    
    var dt = new Date(currentDate);
    dt.setMonth(dt.getMonth() - 1);
    //  alert(dt+" "+this.state.startDate)
    var month, day, year;
    month = '' + (dt.getMonth() + 1);
    day = '' + dt.getDate();
    year = dt.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var updatedDate = [year, month, day].join('-');

    if ((Date.parse(fields1[0].startDate) < Date.parse(updatedDate))) {
      // alert("Error");
      dateValidate = false;
      errors["startDate"] = <li>Start date can not be older than a month time period</li>;
    }
    if (fields1[0].endDate < currentDate) {
      // alert("Error");
      dateValidate = false;
      errors["endDate"] = <li>End date must be greater than current date</li>;
    }


    if ((Date.parse(fields1[0].firstLeadDeliveryDate)> Date.parse(fields1[0].endDate))) {
      firstDateValidate=false;
      // alert(" cant be greater than end date ");
      errors["firstdeliverydateEnddate"]=<li>First lead delivery date can't be greater than end date</li>;
  }

if ((Date.parse(fields1[0].firstLeadDeliveryDate)<Date.parse(fields1[0].startDate))){
    // alert("cant less than start date")
    firstDateValidate=false;
    errors["firstdeliverydateStartdate"]=<li>First lead delivery date can't be less than start date</li>;
}


if ((Date.parse(fields1[0].firstLeadDeliveryDate) < Date.parse(currentDate))){
  // alert("less  than current")
  firstDateValidate=false;
  errors["firstdeliverydateCurrentDate"]=<li>First lead delivery date must be greater than current date</li>;
}
    
    // timeZone
    if(this.state.timeZone.length===0)
      
    {
      // formIsValid = false;
      timeZoneValidate= false;
   
     
      this.setState({
        inputClass: "invalid"
      })
       errors["commonError"]=<li>Please fill below required fields</li>;
     
    }
    else
    {
      timeZoneValidate= true;
      // formIsValid = true;
 
      this.setState({
        inputClass: "valid"

      })
    }
  //start of creatives validations at back
  var counter=0;
  var counteremail=0;
//alert("creatives===>"+this.state.creatives.length)
  for(var i=0;i<this.state.creatives.length;i++)
  {
    if(this.state.campaignDetail[0].marketingChannel==="Email/Telemarketing")
    {
      // alert("here"+JSON.stringify(this.state.creatives))
      if(this.state.creatives[i].id=="Newsletter"&&this.state.creatives.length==1)
      {
        Swal.fire({
            
          text: ("Selected marketing channel is Email/TeleMarketing, So please select Appropiate Creatives"),
          type: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        })
        creativesvalidate=false;
      }
    }
    if(this.state.campaignDetail[0].marketingChannel==="TeleMarketing")//for display
    { if(this.state.creatives.length==3)
      {
      counter=3;
      }
      

      if(this.state.creatives.length==2)
      {
      counter=2;
      }
      
      if(this.state.creatives[i].id==="Call Script")
      {counter++;}
      else
      {
        var timeline=document.getElementById("timeline");
        timeline.style.display="none"
        //this.state.CreativeValue.length=0;
        errors["TimeLineerror"] = <li>Selected marketing channel is Telemarketing, So please select Call Script as a Creative</li>;
        Swal.fire({
            
          text: ("Selected marketing channel is Telemarketing, So please select Call Script as a Creative"),
          type: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        })
        creativesvalidate=false;
      }
      if(counter==1)
      {
      creativesvalidate=true;
      }
      if(counter==2)
      {
      creativesvalidate=false;
      }
      if(counter==3)
      {
      creativesvalidate=false;
      }
      
    }// end of  this.state.campaignDetail[0].marketingChannel
    if(this.state.campaignDetail[0].marketingChannel==="Email")
    {  
if(this.state.creatives[i].id==="Newsletter")
{
counteremail=1;
}
if(this.state.creatives.length==2)
{
counteremail=2;
}
if(this.state.creatives[i].id==="Call Script")
{
counteremail=1;
}
if(counteremail==2)
{
  creativesvalidate=true;
}
if(counteremail==1)
{
  errors["POcerror"] = <li>Selected marketing channel is email, So please select Landing Page and Newsletter as a Creative</li>;
  creativesvalidate=false;
  //this.state.CreativeValue.length=0;
        Swal.fire({
            
          text: ("Selected marketing channel is email, So please select Landing Page and Newsletter as a Creative"),
          type: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        })
}
    }// end of  this.state.campaignDetail[0].marketingChannel email
  } // end of for


















// alert("requiredFeildValidate===>"+requiredFeildValidate+"  "+
//    "countryValidate==>"+countryValidate+"  "+
//    "regionValidate==>"+ regionValidate+"  "+
//    "jobLevelValidate===>"+jobLevelValidate+"  "+
//    "employeeSizeValidate===>"+employeeSizeValidate+"  "+
//     "jobFunctionValidate===>"+jobFunctionValidate+" "+ 
//     "companyRevenueValidate==>"+companyRevenueValidate+"  "+
//     "clientNameValidate==>"+clientNameValidate+"  "+
//     "industryValidate==>"+industryValidate+"  "+
//     "dateValidate==>"+dateValidate+" "+
//     "timeZoneValidate==>"+timeZoneValidate+" "+
//     "leadAllocationValidate==>"+leadAllocationValidate+" "+ 
//     " creative validate"+creativesvalidate+
//     "customRevenueValidate==>"+customRevenueValidate+" "+"customEmployeeSizeValidate==>"+customEmployeeSizeValidate);
if(this.state.campaignDetail[0].leadInteractionDays===null||this.state.campaignDetail[0].leadInteractionDays===""){}
else{
  // alert("in here")
  if(this.state.campaignDetail[0].leadInteractionDays==0){
    errors['blankInteractionDaysBack']=<li>Please Enter Value More Than Zero For Lead Interaction Day</li>
    this.setState({errors:errors})
  InteractionDayBack=false
  }
  var pattern= new RegExp(/^[0-9]\d*$/)
  if(!pattern.test(this.state.campaignDetail[0].leadInteractionDays)){
    // alert("in here")
    errors['blankInteractionDaysBack']=<li>Please Enter Numbers Only For Lead Interaction Day</li>
    this.setState({errors:errors})
  InteractionDayBack=false
  }
  else{
    
  }
  
}

  if(campNameBack==false||requiredFeildValidate==false ||  countryValidate==false ||  regionValidate==false || jobLevelValidate==false ||
  jobFunctionValidate==false || clientNameValidate==false||zipvalidateBack===false||cityvalidateBack===false||
  statevalidateBack===false||
   industryValidate==false || dateValidate==false || leadAllocationValidate==false ||
    leadPerDomainValidate==false ||pacingLeadValidate==false || budgetValidate==false  ||cplValidate==false||customEmployeeSizeValidate==false||
    firstDateValidate==false||creativesvalidate==false ||employeeSizeValidate==false||JobLevelPopBack==false||JobFunctionPopBack==false||EmpSizePopBack==false||
    RevenuePopBack==false||IndustryPopBack==false||InteractionDayBack===false)
    
    {
  
      formIsValid=false;
      this.setState({
        errors: errors,
        alertBorder: '1px solid red',
        alertWidth: '1000px',
        alertAlign: 'center',
      alertDisplay: 'block',
       spanSpaceDisplay: spanDisplay
  
      });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    
      return formIsValid;



  }
  // alert(JSON.stringify(creativevalue2))
  
  }// end of validateForm()
  // end of validateForm()


  componentWillMount(){
   

    var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
   
 
    fetch("campaign/campDetailsForEdit?campID=" + campID + "")
      .then(res => res.json())
      .then(campaignDetail => {
       
        this.setState({
          campaignDetail: campaignDetail,
          campID:campID
        });
      
      
// alert("will mount data==>"+JSON.stringify(this.state.campaignDetail))
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var  filezip = this.state.campaignDetail[0].zipCodeFileName;
          var str=this.state.campaignDetail[0].zipCode;
       
          var zipcodefile=document.getElementById("zipcodefile");
            if(str=='Yes')
            {
            
               this.state.zipFileLengthBack=filezip.length;
            
              zipcodefile.style.display="block";
              this.setState({checked:true})
            
            }
            else
            {
              zipcodefile.style.display="none";
            }
           
         this.setState({zipcodeBack:str,zipcodeFileBack:filezip,
          });
        }
if(this.state.campaignDetail && this.state.campaignDetail.length)
{
  if(this.state.campaignDetail[0].marketingChannel=="TeleMarketing"||this.state.campaignDetail[0].marketingChannel=="Email/Telemarketing")
  {
    // console.log("true..")
    this.setState({displayCallauditBack:'block'})
  }
}
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var  filecity = this.state.campaignDetail[0].cityFileName;
          var str=this.state.campaignDetail[0].city;;
          // var lengthcityback=this.state.campaignDetail[0].cityFileName.length;
          // alert("length from back===>"+lengthcityback)
        //  alert("file name on back"+str );
        //  alert("file name on back"+filecity);
          var cityfile=document.getElementById("cityfile");
            if(str=='Yes')
            {
              //  alert("checked display file name alog with it");
              this.state.cityFileLengthBack=filecity.length;
              cityfile.style.display="block";
              this.setState({checked1:true})
              // alert(zipcodeBack)
            }
            else
            {
              cityfile.style.display="none";
            }
         this.setState({cityBack:str,cityFileBack:filecity,
          });
          // alert("file length"+this.state.cityFileBack.length)
        }

        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var jobTitleValidation=this.state.campaignDetail[0].jobTitleValidation;;
      
       
         
            if(jobTitleValidation=='Yes')
            {
          
              this.setState({checked3:true})
              
            }
            else
            {
              //cityfile.style.display="none";
            }
         //this.setState({jobTitleCheckBox:jobTitleCheckBox,cityFileBack:filecity,});
          // alert("file length"+this.state.cityFileBack.length)
        }


        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var  filestate = this.state.campaignDetail[0].stateFileName;
          var str=this.state.campaignDetail[0].state;;
        //  alert("file name on back"+str );
        //  alert("file name on back"+filestate);
          var statefile=document.getElementById("statefile");
            if(str=='Yes')
            {
              //  alert("checked display file name alog with it");
              this.state.stateFileLengthBack=filestate.length;
              statefile.style.display="block";
              this.setState({checked2:true})
              // alert(zipcodeBack)
            }
            else
            {
              statefile.style.display="none";
            }
         this.setState({stateBack:str,stateFileBack:filestate,
          });
          // alert(this.state.stateFileBack.length)
        }

        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].jobLevel;
         
          var str_array = str.split('|');
         
          for (var i = 0; i < str_array.length; i++) {
            if(str_array[i]!==""){ 
              jobLevelArray.push({ 'id': str_array[i], 'name': str_array[i] });
            }
          } this.setState({ jobLevel: jobLevelArray });
        }// for jobLevel Array
  
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].industry;
          var str_array = str.split('|');
          for (var i = 0; i < str_array.length; i++) {
            
              if(str_array[i]!=""){
              industryArray.push({ 'id': str_array[i], 'name': str_array[i] });
              }
         
          } this.setState({ industry: industryArray });
        }//for industryArray

        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].employeeSize;
          var str_array = str.split('|');
          for (var i = 0; i < str_array.length; i++) {
            //  if(str_array[i] =='0-100 101-250 501-1000  1001-2500 5001-7500  7501-10000 10001+')
            // {
            //   empSizeArray.push({ 'value': '0-100 101-250 501-1000  1001-2500 5001-7500  7501-10000 10001+', 'label': 'ALL' });
            // }
            // else 
            // {
              if(str_array[i]!=""){
              empSizeArray.push({ 'id': str_array[i],'name': str_array[i] });
              }
         
          } this.setState({ employeeSize: empSizeArray });
        }//for employeeSizeArray

           /**
       * @author Supriya Gore
       * @param  Description handle the Custom Employee Size Back
       * @return Description return Custom Employee Size
      */


        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].customEmpSize;
          var arrayLength = (str.split('|')).length;
          // var str_array = str.split('-').join(',').split(',');
          var str_array = str.split('|');
         
          var subString="-";
          for (var i = 1; i < str_array.length; i++) {
          
           var check=str_array[i].includes(subString);
        
           if(check ===true)
           {
            var array=str_array[i].split('-');
           
              if(str_array[i]!=""){
              customEmpSizeArrayFrom.push({ 'From': array[0] , 'To':array[1], 'count':i });
             // j++;
             this.setState({
              flagFromToState:true,
             
            });
            }
           }else{
           }
          }

          if(customEmpSizeArrayFrom.length==0)
          {
          customEmpSizeArrayFrom.push({ 'From':"" , 'To':"", 'count':0 });
          this.setState({
            flagFromToState:false,
           
          });
          }


          this.setState({ customEmployeeSizePopupBackFromTo: customEmpSizeArrayFrom });

         

         // alert("WillmountEmpSize===>"+JSON.stringify(this.state.customEmployeeSizePopupBackFromTo));
        }//for customEmpSizeArray


        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].customEmpSize;
     
          var arrayLength = (str.split('|')).length;
         
          var str_array = str.split('|');
         
         var subString="-";
          for (var i = 1; i < str_array.length; i++) {
        
           var check=str_array[i].includes(subString);
       
           if(check ===false){
            var array=str_array[i].split(',');
           
              if(str_array[i]!=""){
                
                customEmpSizeArrayTextBack.push({ 'customEmployeeSizeText': str_array[i] , 'count':i });
             // j++;
             this.setState({
           
              flagTextState:true
            });
            }
          }
          }

          if(customEmpSizeArrayTextBack.length==0)
          {
            customEmpSizeArrayTextBack.push({ 'customEmployeeSizeText':"" , 'count':0 });
            this.setState({
           
              flagTextState:false
            });
          }
          this.setState({ customEmployeeSizePopupBackText: customEmpSizeArrayTextBack });
        
        }//for customEmpSizeArray

        /**
       * @author Supriya Gore
       * @param  Description handle the Custom Revenue Size Back
       * @return Description return Custom Revenue Size 
       */
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].customCompRevenue;
          // alert("Custom revenue array===="+str);
          var str_array = str.split('|');
        //  alert("Custom revenue array===="+str_array);
        var subString="-";
          for (var i = 0; i < str_array.length; i++) {
            var check=str_array[i].includes(subString);
         
            if(check ===true){
            var array=str_array[i].split('-');

              if(str_array[i]!=""){
              customRevenueArrayFrom.push({ 'From': array[0], 'To':array[1], 'count':i});
              }
          // alert(""+JSON.stringify( customRevenueArrayFrom));
          }
        }
          var customRevenueArrayFromLength=customRevenueArrayFrom.length;
        
          if(customRevenueArrayFrom.length==0)
          {
            customRevenueArrayFrom.push({ 'From':"" , 'To':"", 'count':0 });
          }


          
          this.setState({ customRevenuePopupBackFromTo: customRevenueArrayFrom });
        }//for customRevenueArray



 
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].customCompRevenue;
        
          var arrayLength = (str.split('|')).length;
          // var str_array = str.split('-').join(',').split(',');
          var str_array = str.split('|');
         // alert("Custom emp array===="+str_array+" length="+arrayLength);
         var subString="-";
          for (var i = 1; i < str_array.length; i++) {
        
           var check=str_array[i].includes(subString);
           
             if(check ===false){
            var array=str_array[i].split('|');
          
              if(str_array[i]!=""){
                customCompanyRevenueArrayText.push({ 'customCompanyRevenueText': array[0] ,'count':i });
              j++;
            }
          }
              
       
          }
         
          if(customCompanyRevenueArrayText.length==0)
          {
            customCompanyRevenueArrayText.push({ 'customCompanyRevenueText':"" , 'count':0 });
          }
       
          this.setState({ customRevenuePopupBackText: customCompanyRevenueArrayText });
        }//for customEmpSizeArray
 
        /**
       * @author Supriya Gore
       * @param  Description handle the Custom Job Function Back
       * @return Description return Custom Job Function
       */
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].customJobFunction;
         
          var str_array = str.split('|');
        
          for (var i = 0; i < str_array.length; i++) {
            var check=str_array[i].includes(subString);
           
           
              if(str_array[i]!=""){
              customJobFunctionArray.push({ 'jobFunction': str_array[i],'count':i });
              }
          
          } 
        


          var customJobFunctionArrayLength=customJobFunctionArray.length;
        
          if(customJobFunctionArray.length==0)
          {
            customJobFunctionArray.push({ 'jobFunction':"" , 'count':0 });
          }

          this.setState({ customJobFunctionPopupBack: customJobFunctionArray });

          // this.setState({
          //   flagJobFunctionState:true
          //  })
        }//for customJobFunctionArray



          /**
       * @author Sanobar Golandaj
       * @param  Description handle the Custom Job level Back
       * @return Description return Custom Job Level
       */
      if (this.state.campaignDetail && this.state.campaignDetail.length) {
        var str = this.state.campaignDetail[0].customJobLevel;
       
        if(str==undefined || str ==""){

        }else
        {
       var str_array = str.split('|');
      
        for (var i = 0; i < str_array.length; i++) {
          var check=str_array[i].includes(subString);
         
         
            if(str_array[i]!=""){
            customJobLevelArray.push({ 'jobLevel': str_array[i],'count':i });
            }
        
        } 
      }
      
        var customJobLevelArrayLength=customJobLevelArray.length;
      
        if(customJobLevelArray.length==0)
        {
          customJobLevelArray.push({ 'jobLevel':"" , 'count':0 });
        }

        this.setState({ customJobLevelPopupBack: customJobLevelArray });

      
      }//for customLevelArray
        
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].companyRevenue;
           var str_array = str.split('|');
          
           
          for (var i = 0; i < str_array.length; i++) {
            
            
            // if(str_array[i] === '1 - 9 million 10-49 million  50-99 million 100-199 million  200-399 million  400-499 million  500-749 million 750-999 million 1-9 billion  10-49 billion  50-99 billion  100 billion or more')
            //        {
                  //      alert("ALL"+str_array[i]);
                  //     compRevenueArray.push({ 'value':'1 - 9 million 10-49 million  50-99 million 100-199 million  200-399 million  400-499 million  500-749 million 750-999 million 1-9 billion  10-49 billion  50-99 billion  100 billion or more', 'label': 'ALL'});
                  // }
                  // else
                  // {
                    if(str_array[i]!=""){
                    compRevenueArray.push({ 'id': str_array[i], 'name': str_array[i] });
                    }
                  // }

            }
             this.setState({ companyRevenue: compRevenueArray});
        }


  
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].jobFunction;
          var str_array = str.split('|');
          for (var i = 0; i < str_array.length; i++) {
            if(str_array[i]!==""){ 
            jobFunctionArray.push({ 'id': str_array[i], 'name': str_array[i] });
            }
          } this.setState({ jobFunction: jobFunctionArray });
        }// for jobFunctionArray
     

        // if (this.state.campaignDetail && this.state.campaignDetail.length) {
        //   var str = this.state.campaignDetail[0].timezone;
        //   var str_array = str.split(',');
        //   for (var i = 0; i < str_array.length; i++) {
        //     timeZoneArray.push({ 'id': str_array[i], 'name': str_array[i] });
        //   } this.setState({ timeZone: timeZoneArray });
        // }// timeZone

          if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].customIndustry;
          // alert(str);
          this.setState({customIndustryBack:str});
          // alert(JSON.stringify(this.state.customIndustryBack))
          }

         /**
       * @author Sanobar Golandaj
       * @param  Description handle the lp approval Back
       * @return Description return lp timeline based on lp aprroval 
       */
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          var str = this.state.campaignDetail[0].requiredLPApproval;



          var str_array = str.split(',');
          for (var i = 0; i < str_array.length; i++) {
            
              if(str_array[i]!=""){
              creativeValueArray.push({ 'id': str_array[i], 'name': str_array[i] });
              }
        
          } 
         this.setState({ creatives: creativeValueArray });
          // alert(JSON.stringify(str));
          var timeline=document.getElementById("timeline");
          var approvedBy=document.getElementById("approvedBy");
         if(str!="")
         {
           timeline.style.display=campaignDetail="block";
           approvedBy.style.display=campaignDetail="block";
         }
         else
         {
          timeline.style.display=campaignDetail="none";
          approvedBy.style.display=campaignDetail="none";
         }
        }
     
     


     
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
         // var str = this.state.campaignDetail[0].region;
          var str = this.state.campaignDetail[0].region;
          var regionValue = [];
          var str_array = str.split(',');
          for (var i = 0; i < str_array.length; i++) {
            regionArray.push({ 'id': str_array[i], 'name': str_array[i] });
            regionValue.push( str_array[i]);
          } 
          //this.setState({ region: regionArray });
 

          /*******In region base country selection */
          let regionCountryInfo=this.state.regionCountryInfo;
          
          //regionwise dynamic country array
          // alert(this.state.campaignDetail[0].region);
          let countryArray1=[];

          for(var j=0;j<regionValue.length;j++)
          {
          var regionValues=regionValue[j];
          
          for (var country in regionCountryInfo[regionValues])
           {
           countryArray1.push({id:country, name:country});
           }  

          }

          countryArray1.sort(function(a, b){
            var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase()
            if (nameA < nameB) //sort string ascending
                return -1 
            if (nameA > nameB)
                return 1
            return 0 //default return value (no sorting)
        })

          var i;
          var newcountryArray1=[];
          
            for(i=0;i<countryArray1.length;i++)
            {
          
              newcountryArray1.push({id:countryArray1[i].name, name:countryArray1[i].name});
            }
      
          this.setState({region: regionArray, countryoptions:newcountryArray1})
        }////region
        let comaWithinCountryName=["Virgin Islands, British","Saint Helena, Ascension and Tristan da Cunha","Congo, the Democratic Republic of the","Bonaire, Sint Eustatius and Saba"];
        if (this.state.campaignDetail && this.state.campaignDetail.length) {
          let str = this.state.campaignDetail[0].country;
          let str_array = str.split(',');
          let tempArray=[...str_array];
          let strlength=str_array.length;
          for (let i = 0; i <strlength; i++){
            if(str_array[i]=='Virgin Islands' ||str_array[i]==' British'||str_array[i]=='Saint Helena'||str_array[i]==' Ascension and Tristan da Cunha'||str_array[i]=='Congo'||str_array[i]==' the Democratic Republic of the'||str_array[i]=='Bonaire'||str_array[i]==' Sint Eustatius and Saba'){}
            else{
              countryArray.push({ 'id': str_array[i], 'name': str_array[i] });
            }
          } 
          let countryTemp=[];
          for(let i=0;i<comaWithinCountryName.length;i++){
            for(let j=0;j<tempArray.length;j++){
              let comaCountry=(comaWithinCountryName[i]).toLowerCase();
              let str=(tempArray[j]).toLowerCase()
              if(comaCountry.includes(str)){
                // alert("Hi"+comaWithinCountryName[i])
                countryTemp.push({ 'id': comaWithinCountryName[i], 'name': comaWithinCountryName[i] });
              }
            }
          }
          let rmDuplicate = countryTemp.filter((countryTemp, index, self) =>
              index === self.findIndex((t) => (t.id === countryTemp.id)));
              countryArray=countryArray.concat(rmDuplicate)
          this.setState({ country: countryArray });
        }// for countryArray


        if (this.state.campaignDetail && this.state.campaignDetail.length) 
        {
           custIndustry = this.state.campaignDetail[0].customIndustry;
           indstry = this.state.campaignDetail[0].industry;
        if(custIndustry==""&&indstry=="")
        {
          document.getElementById('NA3').checked="true"
          // document.getElementById('span4').style.display="none"
          
          this.setState({drpdisabled:'drpdisable'})
          this.setState({industryNAValueBack:'Not_Applicable'})
        }
        }//industry
        if (this.state.campaignDetail && this.state.campaignDetail.length) 
        {
           custempSize = this.state.campaignDetail[0].customEmpSize;
           empSize = this.state.campaignDetail[0].employeeSize;
        if(custempSize==""&&empSize=="")
        {
          document.getElementById('NA4').checked="true"
          // document.getElementById('span3').style.display="none"
          this.setState({drpdisabledemp:'drpdisable'})
          this.setState({empSizeNAValueBack:'Not_Applicable'})
        }
        }
      });

          /**
       * @author Supriya Gore
       * @param  Description handle the pacing campaign details edit
       * @return Description return pacing campaign details
       */
      var pacingCampaignData=[];
      fetch("campaign/pacingDetailsForEdit?campID=" + campID + "")
      .then(res => res.json())
      .then(pacingCampaignDetails => {
       // alert("pacingDetailsForEdit==="+JSON.stringify(pacingCampaignDetails));
       for(var i=0;i<pacingCampaignDetails.length;i++){
       // alert("pacing unit==>"+JSON.stringify(pacingCampaignDetails[i].pacingUnit))
        pacingCampaignData.push({...pacingCampaignDetails[i],count:i});
       }
        this.setState({
          pacingCampaignDetails: pacingCampaignData,pacingCampaignNoChangeArray:pacingCampaignDetails
        });       

        //alert("pacing unit111==>"+JSON.stringify(pacingCampaignDetails[0].pacingUnit))

        // this.setState({
        //   pacingUnit: pacingCampaignDetails[0].pacingUnit
        // });  
       // alert("pacing unit222==>"+this.state.pacingUnit)

      });

      /**
       * @author Supriya Gore
       * @param  Description handle the Custom question answer details Back
       * @return Description return Custom question answer details
       */
      fetch("campaign/questionDetailsForEdit?campID=" + campID + "")
      .then(res => res.json())
      .then(questionDetail => {
        this.setState({
          questionDetail: questionDetail
        });

        


        
          var questionLength = this.state.questionDetail.length;
         
          var customQuestionArrayLength=customQuestionArray.length;
             
              // for(var j=(questionLength+1);j<=10;j++)
              // {
              //   this.state.questionDetail.push({"customQuestionID":j,"campID":campID, "customQuestion": "","answer":""});
              // }
              // alert(JSON.stringify(this.state.questionDetail));
        
        
      });
    } 
  /**
 * @author Narendra Phadke
 * @param  Description handle the Fetch campaign details
 * @return Description return successfully get details
 */
  componentDidMount() {
    
       // this.nameInput.focus();
    
    if(!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else{
    var currentDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
    //  alert("currentDate"+currentDate+this.state.startDate);
    var dt = new Date(currentDate);
    dt.setMonth(dt.getMonth() - 1);
    //  alert(dt+" "+this.state.startDate)
    var month, day, year;
    month = '' + (dt.getMonth() + 1);
    day = '' + dt.getDate();
    year = dt.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    var updatedDate = [year, month, day].join('-');
  
    //this.nameInput.min=updatedDate;
    //this.focusTextInput(this.nameInput,updatedDate);




   //document.getElementById('startDate').min = updatedDate;
  // document.getElementById('endDate').min = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
     var regionSel = document.getElementById("region");
  
  //  alert("Hi"+document.getElementById('region').options.length+region);
  //   for (var region in this.state.regionCountryInfo)
  //   {
  //   //Load regions
  //   // alert(""+document.getElementById('region').options.length+region);
  //   regionSel.options[regionSel.options.length] = new Option(region, region);
   
  // }
  //var clentName=document.getElementById('clientName');

  const {user} = this.props.auth;
var userID=user.id;


 //Client Name from db-need for create campaign
  fetch("/campaign/clientNameDetails?agencyID=" + userID + "")
  .then(res => res.json())
  .then((clientNameDetails=>{
    this.setState({clientNameDetails: clientNameDetails})
   // alert(JSON.stringify(clientNameDetails));
    var clientSelect = document.getElementById("client");

    var i;
    for(i=0;i<clientNameDetails.length;i++)

    {
      clientSelect.options[clientSelect.options.length] = new Option(clientNameDetails[i], clientNameDetails[i]);
    } 
    
  }
  )).catch(function (err) {
    console.log(err)
  });


  //Client Name from db-need for Back flow of Campaign
  fetch("/campaign/clientNameDetails?agencyID=" + userID + "")
  .then(res => res.json())
  .then((clientNameDetailsStep1=>{
    this.setState({clientNameDetailsStep1: clientNameDetailsStep1})
   
   // alert(JSON.stringify(clientNameDetails));
    
  }
  ));
  
}
  //this.setState({customEmployeeSizePopup:customEmployeeSizePopupData});
  } // end of componentDidMount()


  

  render() {
    var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
    /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      const {isAuthenticated, user} = this.props.auth;
      
      const { selectedOption} = this.state;
      const { selectedOption3} = this.state;
      var {campaignDetail}=this.state;
//this.setState({jobLevel:this.state.jobLevel});
      var classNames = require('classnames');
 
// alert("IN Values"+JSON.stringify(this.state.customAnswersPopup));
      this.state.createdBy=user.firstName+" "+user.lastName;
    const asterisk =
    {

      color: 'red',

    }
    const emptext = <span>Add Custom EmployeeSize Range</span>;

    if (campID == undefined || campID == null || campID == "") {
    
    return (

      <div>
        <Navigation />
        <div class="row"  style={{paddingTop:"90px",paddingBottom:'6px',backgroundColor:'white',border:'1px solid',borderColor:'#bfc7cc',width:'110%',position:'fixed',zIndex:'1'}}>
        <div class=" col-lg-3"> <label></label></div>
        <div class= " col-xs-6 col-lg-6" ><label class="labelfont" style={{color:'#164495',fontSize:'20px',textAlign:'center'}} >STEP 1 : Campaign Details</label></div>
        <div  class=" col-lg-1" id=""  style={{right:'425px',top:'230px'}}className={this.state.loading}> </div>
        <div class="col-xs-6 col-lg-4" class="" >  
       
          <button class="btn add-button" style={{float:'right'}}
         form="createcampaign" type="submit">Save & Next</button>
         
</div>

</div>


        <div className="col-lg-12" class="displayerror" style={{ display: this.state.alertDisplay}} >
          <div class="container-fluid">
            {/* <div class="card card-signin my-1"> */}
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12"
                    style={{ border: this.state.alertBorder, display: this.state.alertDisplay }}>
                    
                      <div className="errorMessage">{this.state.errors.commonError}</div>
                      <div className="errorMessage">
                        {this.state.errors.campNameError?this.state.errors.campNameError:''}
                        {this.state.errors.blankInteractionDays?this.state.errors.blankInteractionDays:''}
                        {this.state.errors.leadAllocation?this.state.errors.leadAllocation:''}
                        {this.state.errors.noOfLeadPerDomain?this.state.errors.noOfLeadPerDomain:''}
                        {this.state.errors.pacingLeadAllocation?this.state.errors.pacingLeadAllocation:''}
                        {this.state.errors.endDate?this.state.errors.endDate:''}
                        {this.state.errors.startDate?this.state.errors.startDate:''}
                         {this.state.errors.clientName?this.state.errors.clientName:''}
                         {this.state.errors.budget?this.state.errors.budget:''}
                         {this.state.errors.cpl?this.state.errors.cpl:''}  
                         {this.state.errors.revenueFrom?this.state.errors.revenueFrom:''}  
                         {this.state.errors.revenueTo?this.state.errors.revenueTo:''}  
                         {this.state.errors.empSizeFrom?this.state.errors.empSizeFrom:''}  
                         {this.state.errors.empSizeTo?this.state.errors.empSizeTo:''} 
                         {this.state.errors.jobFunction?this.state.errors.jobFunction:''}  
                         {this.state.errors.firstdeliverydateEnddate?this.state.errors.firstdeliverydateEnddate:''}
                         {this.state.errors.firstdeliverydateStartdate?this.state.errors.firstdeliverydateStartdate:''}
                         {this.state.errors.firstdeliverydateCurrentDate?this.state.errors.firstdeliverydateCurrentDate:''}
                        {this.state.errors.POcerror?this.state.errors.POcerror:''}
                        {this.state.errors.TimeLineerror?this.state.errors.TimeLineerror:''}
                         {this.state.errors.industryerrr?this.state.errors.industryerrr:''}
                         {this.state.errors.filemin?this.state.errors.filemin:''}
                         {this.state.errors.fileextension?this.state.errors.fileextension:''}
                         


                         
                      </div>
                    
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>


        <form method="POST" id="createcampaign" name="Campaign" className="needs-validation" onSubmit={this.submitCampaign}  noValidate  encType="multipart/form-data" >
          <div class="container-fluid"  style={{ paddingBottom: '70px',paddingBottom:'60px'}}>
            {/* <div class="card card-signin my-1"> */}
              <div class="card-body" >
                {/*start first row */}
                <legend className="legend labelsubheading" >Basic Details</legend>
                <MDBRow>
                  <MDBCol lg="3"  className="mb-3">
                    <label id="label" >Campaign name <span style={asterisk}>*</span></label>
                    <input
                      value={this.state.campaignName}
                      onChange={this.handleChange}
                      type="text"
                      id="campaignName"
                      className="form-control"
                      name="campaignName"

                      required />
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Client name <span style={asterisk}>*</span></label>
                    &nbsp;&nbsp;
                    <Tooltip   placement="right" title="Add Client">
                    <a href="/addAdvertiser?add=+step1">
                    <Icon type="plus-circle"/>
                    </a>
                    </Tooltip>
                    < select id="client" name="client"
                      value={this.state.clientName}
                      onChange={this.handleClientChange}
                      // className={this.state.inputClass2}
                      class="form-control"
                      required>
                      <option value="" disabled selected>Select Client</option>
                      
                     
                    </select>
                    {/* <select
                      value={this.state.clientName}
                      onChange={this.handleClientChange}
                      id="client"
                      class="form-control"
                      name="client">
                    <option value="Select Client" selected disabled>Select Client</option>
                
                    </select> */}
                  

                    {/* < select id="clientName" name="clientName"
                      value={this.state.clientName}
                      onChange={this.handleClientChange}
                      
                      class="form-control"
                      required>
                      <option value="Select Client" selected disabled>Select Client</option>
                   
                     {this.state.clientNameDetails.map((clientNameDetails) => {
                  return (
                    <option value={clientNameDetails} >{clientNameDetails} </option>
                  )})}; 
                     </select> */}
                    {/* <input
                      value={this.state.clientName}
                      onChange={this.handleChange}
                      type="text"
                      id="clientName"
                      className="form-control"
                      name="clientName"

                      required /> */}
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Agency campaign ID</label>
                    <input value={this.state.clientCampID}
                      onChange={this.handleChange}
                      type="text"
                      id="clientCampID"
                      className="form-control"
                      name="clientCampID"
                    />
                  </MDBCol>

                  {/* <MDBCol lg="3" className="mb-3">
                    <label id="label">Insertion Order</label>
                    < select id="insertionOrder" name="insertionOrder"
                      value={this.state.insertionOrder}
                      onChange={this.handleChange}
                      class="form-control"
                      required>
                      <option value="Yes" selected>Yes</option>
                      <option value="No">No</option>
                    </select>
                  </MDBCol> */}
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Campaign status </label>
                    < select id="campaignStatus" name="campaignStatus"
                      value={this.state.campaignStatus}
                      onChange={this.handleChange}
                      class="form-control"
                      required>
                      <option value="Active" selected>Active</option>
                      <option value="Pending">Pending</option>
                      <option value="Pause">Pause</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Start date  <span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                    <input type="date" id="startDate" name="startDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                      value={this.state.startDate}
                      onChange={this.handleChange}
                      className="form-control"

                      required />
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">End date  <span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                    <input type="date" id="endDate" name="endDate" min=""
                      value={this.state.endDate}
                      onChange={this.handleChange}
                      className="form-control"
                      required />
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                  <label id="label">First lead delivery<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "10px" }}>(dd-mm-yyyy) </span></label>
                    <input type="date" class="form-control"
                      id="firstLeadDeliveryDate" name="firstLeadDeliveryDate"  min=""
                      value={this.state.firstLeadDeliveryDate} onChange={this.handleChange} required/>
                  </MDBCol>


                  <MDBCol lg="3" className="mb-3">
                    <label id="label">No. of lead per domain</label>
                    <input type="textbox" class="form-control"
                      id="noOfLeadPerDomain" name="noOfLeadPerDomain"
                      value={this.state.noOfLeadPerDomain} onChange={this.handleChange} />
                  </MDBCol>
                 

                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Lead allocation <span style={asterisk}>*</span></label>
                    <input type="textbox" class="form-control"
                      id="leadAllocation" name="leadAllocation"
                      value={this.state.leadAllocation} onChange={this.leadAllocationHandleChange} required />
                  </MDBCol>

                  {/* <MDBCol lg="3" className="mb-3">
                                            <label id="label">No. of Leads Per Domain</label>
                                                  <input  type="textbox" class="form-control" 
                                                  id="noOfLeadPerDomain" name="noOfLeadPerDomain"
                                                  value={this.state.noOfLeadPerDomain} onChange={this.handleChange} />
                                                   
                                          </MDBCol> */}

                                          
                 
                            <MDBCol lg="3" className="mb-3">
                              <label id="label">Campaign budget<span style={asterisk}>*</span></label>
                              <input 
                              value={this.state.budget}
                              onChange={this.budgetHandleChange}
                              type="text"
                              id="budget"
                              class="form-control"
                              name="budget" required />
                            </MDBCol>


                        <MDBCol lg="3" className="mb-3">

                          <label id="label">CPL<span style={asterisk}>*</span></label>
                          <input 
                          value={this.state.cpl}
                          onChange={this.cplHandleChange}
                          type="text"
                          id="cpl"
                          class="form-control"
                          name="cpl" required />
                      </MDBCol>


                      <MDBCol lg="3" className="mb-3">
                    <label id="label">Campaign currency<span style={asterisk}>*</span></label>    
                    <select id="currency"  style={{border:'1px solid #ccc',
                  boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)', height:'calc(2.25rem + 12px)'}}name="currency" 
                  value={this.state.currency} onChange={this.handleChange} class="form-control">

                  <option value="USD" selected>USD - US Dollar</option>
                  <option value="EUR" >EUR - Euro</option>
                  <option value="GBP">GBP - British Pound</option>
                  <option value="INR">INR - Indian Rupee</option>
                  <option value="AUD">AUD - Australian Dollar</option>
                  <option value="CAD">CAD - Canadian Dollar</option>
                  <option value="SGD">SGD - Singapore Dollar</option>
                  <option value="CHF">CHF - Swiss Franc</option>
                  <option value="MYR">MYR - Malaysian Ringgit</option>
                  <option value="JPY">JPY - Japanese Yen</option>
                  <option value="CNY">CNY - Chinese Yuan Renminbi</option>

                  </select>
 
          </MDBCol>

           
  
  </MDBRow>
  <MDBRow>

                  {/* <MDBCol lg="6" className="mb-6">
                  <div>
                    <label id="label">Timezone<span style={asterisk}>*</span></label>
                    </div>
                    <div>

                    <Picky
           value={this.state.timeZone} 
                options={TimeZoneOption}
                onChange={this.handleChangetimezone}
                className={this.state.inputClass}
                open={false}
                valueKey="id"
                labelKey="name"
                multiple={false}
                includeSelectAll={true}
                includeFilter={true}
                dropdownHeight={400}
                id="timeZone" name="timeZone"
                width="180px"
              /> 
                    </div>
                  
                  </MDBCol> */}


                  

              <MDBCol lg="3" className="mb-3">
                  {/* <label id="label">Created By</label> */}
                    <input type="hidden" class="form-control"
                      id="createdBy" name="createdBy" defaultvalue={user.firstName+" "+user.lastName}
                      value={this.state.createdBy} onChange={this.handleChange} readonly />
                  </MDBCol>
                </MDBRow>
                {/*end of first row*/}
                <br />
                {/*start second row*/}
                <legend className="legend labelsubheading">Delivery Option</legend>
                <MDBRow>
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Pacing </label>
                    <select
                      value={this.state.pacing}
                      onChange={this.handleChange}
                      id="pacing"
                      className="form-control"
                      name="pacing">
                      <option value="Daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly" selected>Monthly</option>
                      <option value="Quaterly">Quaterly</option>
                    </select>
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Pacing count </label>&nbsp;&nbsp;&nbsp;
                    <Tooltip title="Customize pacing"><Icon type="plus-circle" style={{color:'#2196f3'}} data-toggle="modal" data-target="#customPacingCount" onClick={this.func1}/>
                    </Tooltip>

                    <input type="text" id="pacingLeadAllocation" name="pacingLeadAllocation"
                      value={this.state.pacingLeadAllocation}
                      onChange={this.handleChange}
                      class="form-control"
                    />
                  </MDBCol>
                 {/* <!-- Modal --> */}
 <div class="modal fade" id="customPacingCount" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
      <div class="modal-header custom-classPopup">
        
        <h4 class="modal-title" style={{color:'#144999',paddingRight:'600px'}}><font color='white'>Monthly Allocation</font></h4>
        <button type="button" className={this.state.buttonDisplayIndustry} class="cancel-button" style={{fontSize:15,color:'#ffffff'}} data-dismiss="modal">&times;</button>
      </div>
        <div class="modal-body">
         
            <div class="row">
              <div class="col-lg-6">
                
            <label id="label">Unit&nbsp;: </label>
            <span> <select
                value={this.state.pacingUnit}
                        onChange={this.handleChange}
                          id="unit"
                          name="pacingUnit"
                          style={{height:'30px',borderRadius:'5px'}}>
                          <option value="Percentage" selected>%</option>
                          <option value="Number">Num</option>
                      </select></span>
             
                      </div>
                      

                      <div  class="col-lg-6">
                        <label id="label">&nbsp;Total Leads:&nbsp;</label>{this.state.leadAllocation}
                      </div>
                </div>
                <br/>
                <div class="SuccessMessage" style={{float:'center',color:'green'}}>
                  {this.state.pacingSuccess.PacingSuccess}
                 
                  </div>
                <div class="errorMessage" style={{float:'center'}}>
                  {this.state.errors.WrongValues}
                  {this.state.errors.pacingExtraMonths}
                  {this.state.errors.blankEndDate}
                  {this.state.errors.leadRequired}
                  {this.state.errors.percentagePer}
                  {this.state.errors.countPer}
                  {this.state.errors.percentagePerCount}
                  {this.state.errors.countPerCount}
                  {this.state.errors.Pacingdate}
                  {this.state.errors.PacingCurrentMonth}
                  </div>
                  <br/>

          <div class="table-responsive-lg">   
                                    <table class=" table table-striped">
                                      <thead>
                                        <tr class="info">
                                          <th class="table-header" style={{width:'120px'}}>Month</th>
                                          <th class="table-header" style={{width:'120px'}}>Lead %</th>
                                          <th class="table-header" style={{width:'120px'}}>Lead count</th>
                                          <th class="table-header" style={{width:'120px'}}>End date</th>
                                          <th class="table-header" style={{width:'120px'}}>Carry forward</th>
                                          <th class="table-header" style={{width:'120px'}}></th>
                                          </tr>
                                      </thead>
                                      {this.state.pacingUnit=='Percentage'?
                                      <tbody>
                                         
                                      {this.state.dynamicpacing.map((dynamicpacing,i)=>{return(
                                  // <div key={dynamicpacing}>
    

                                        <tr>
                                        
                                        <td><input type="text"  id="pacingMonth" name="pacingMonth"
                                         defaultValue={dynamicpacing.pacingMonth}
                                         key={dynamicpacing.count}
                                        //value={this.state.pacingMonth} 
                                        disabled
                                        class="form-control" onChange={this.handleChangeLeadPercentage.bind(this,i)} style={{width:'100px'}}/>
                                              </td>
                                        
                                          
                                           <td>
                                           <input type="text" id="pacingPercentage" name="pacingPercentage"
                                            defaultValue={dynamicpacing.pacingPercentage} 
                                            key={dynamicpacing.count}
                                            value={dynamicpacing.pacingPercentage}
                                            class="form-control" style={{width:'80px'}}
                                          onChange={this.handleChangeLeadPercentage.bind(this,i)}/>
                                          </td>
                                   
                                        <td><input type="text" id="pacingLeadCount" name="pacingLeadCount"
                                         key={dynamicpacing.count} value={dynamicpacing.pacingLeadCount}
                                                defaultValue={dynamicpacing.pacingLeadCount} class="form-control" style={{width:'80px'}}
                                                onChange={this.handleChangeLeadPercentage.bind(this,i)} disabled/></td>
                                          


                                       

                                         <td><input type="date" class="form-control"
                                            value={this.state.pacingEndDate}
                                            key={dynamicpacing.count}
                                              id="pacingEndDate" name="pacingEndDate"  min=""
                                              value={this.state.pacingEndDate} onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                              style={{width:'150px'}}/></td>
                                        <td> {this.state.dynamicpacing.length === i + 1?
                                        <select
                                        defaultValue={this.state.pacingCarryForward}
                                           value={this.state.dynamicpacing.pacingCarryForward}
                                           key={dynamicpacing.count}
                                           onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                            id="pacingCarryForward"
                                                  className="form-control"
                                                  name="pacingCarryForward"
                                                  style={{height:'30px',opacity:0.5}}
                                                  disabled={true}
                                                  
                                                  >
                                                  <option value="Yes" >Yes</option>
                                                  <option value="No" selected>No</option>
                                                  </select>: <select
                                        defaultValue={this.state.pacingCarryForward}
                                           value={this.state.dynamicpacing.pacingCarryForward}
                                           key={dynamicpacing.count}
                                           onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                            id="pacingCarryForward"
                                                  className="form-control"
                                                  name="pacingCarryForward"
                                                  style={{height:'30px'}}
                                                  
                                                  >
                                                  <option value="Yes" >Yes</option>
                                                  <option value="No" selected>No</option>
                                                  </select>
                                        }</td>
                                                  <td>
                                                    <Tooltip title="Please enter 0 if not required " placement="right"><Icon type="question-circle"/></Tooltip>
                                          {/* <span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removePacing(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span> */}
                                          </td>
                                        </tr>
                                         )})}

                                        </tbody>
                                        :
                                        <tbody>
                                        
                                        {this.state.dynamicpacing.map((dynamicpacing,i)=>{return(
                                  // <div key={dynamicpacing}>
    

                                        <tr>
                                        <td><input type="text"  id="pacingMonth" name="pacingMonth"
                                         defaultValue={dynamicpacing.pacingMonth}
                                         key={dynamicpacing.count}
                                        value={dynamicpacing.pacingMonth} 
                                        disabled
                                        class="form-control" onChange={this.handleChangeLeadPercentage.bind(this,i)} style={{width:'100px'}}/>
                                              </td>
                                        
                                          
                                           <td>
                                           <input type="text" id="pacingPercentage" name="pacingPercentage"
                                            defaultValue={dynamicpacing.pacingPercentage} 
                                            value={dynamicpacing.pacingPercentage}
                                            key={dynamicpacing.count}
                                            class="form-control" style={{width:'80px'}}
                                            disabled
                                          onChange={this.handleChangeLeadPercentage.bind(this,i)}/>
                                          </td>
                                   
                                        <td><input type="text" id="pacingLeadCount" name="pacingLeadCount"
                                         key={dynamicpacing.count}
                                                defaultValue={dynamicpacing.pacingLeadCount} class="form-control" style={{width:'80px'}}
                                                onChange={this.handleChangeLeadPercentage.bind(this,i)} /></td>
                                          


                                       

                                         <td><input type="date" class="form-control"
                                            value={this.state.pacingEndDate}
                                            key={dynamicpacing.count}
                                              id="pacingEndDate" name="pacingEndDate"  min=""
                                              value={this.state.pacingEndDate} onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                              style={{width:'150px'}}/></td>
                                        <td> {this.state.dynamicpacing.length === i + 1?
                                        <select
                                        defaultValue={this.state.pacingCarryForward}
                                        value={this.state.dynamicpacing.pacingCarryForward}
                                        key={dynamicpacing.count}
                                        onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                         id="pacingCarryForward"
                                               className="form-control"
                                               name="pacingCarryForward"
                                               style={{height:'30px',opacity:0.5}}
                                               disabled={true}
                                               >
                                               <option value="Yes" >Yes</option>
                                               <option value="No" selected>No</option>
                                               </select>
                                        :<select
                                        defaultValue={this.state.pacingCarryForward}
                                        value={this.state.dynamicpacing.pacingCarryForward}
                                        key={dynamicpacing.count}
                                        onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                         id="pacingCarryForward"
                                               className="form-control"
                                               name="pacingCarryForward"
                                               style={{height:'30px'}}
                                               
                                               >
                                               <option value="Yes" >Yes</option>
                                               <option value="No" selected>No</option>
                                               </select>}</td>
                                                  <td>
                                                  <Tooltip title="Please enter 0 if not required " placement="right"><Icon type="question-circle"/></Tooltip>
                                          {/* <span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removePacing(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span> */}
                                          </td>
                                        </tr>
                                      // {/* // </p> */}
                                      // </div>
                                         )})}
                                          </tbody>

                                      }
                                        
                                        </table>
                                        </div>
                                        

              {/* <a data-toggle="tooltip" title="Add one more question"><span class={this.state.drpdisabled2+" "+"glyphicon glyphicon-plus-sign"} onClick={(e)=>this.addPacing(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize:25}}></span></a> */}

       
        </div>


        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addPacingAllocation}
             style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>

 


                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Campaign reporting day</label>
                    <select
                      value={this.state.campaignReportingDay}
                      onChange={this.handleChange}
                      id="campaignReportingDay"
                      className="form-control"
                      name="campaignReportingDay">
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Any"  selected>Any</option>
                    </select>
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Campaign type</label>
                    <select
                      value={this.state.abm}
                      onChange={this.handleChange}
                      id="abm"
                      className="form-control"
                      name="abm">

                      <option value selected="OPEN">OPEN</option>
                      <option value="ABM">ABM</option>
                    </select>
                  </MDBCol>

                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Lead Interaction Days</label>
                    <input type="textbox" class="form-control" style={{width:'180px',height:'34.5px'}}
                      id="leadInteractionDays" name="leadInteractionDays"
                      value={this.state.leadInteractionDays} onChange={this.handleChange} required />
                  </MDBCol>
               
                  <MDBCol  lg="2" >

                    <label id="label" style={{marginTop:'2%'}}>Creatives approval required</label>
                     
                    <Picky
              value={this.state.creativearrayValue}
              options={creatives}
              onChange={this.selectCreativesMultipleOption}
              open={false}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={600}
              
            />
                   
                      
                      
                       {/* <select id="requiredLPApproval"  style={{border:'1px solid #ccc',
                      boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)', height:'calc(2.25rem + 12px)'}}name="requiredLPApproval" 
                      value={this.state.requiredLPApproval}
                       onChange={this.lpApprovalhandleChange}
                      //  onChange={this.lpApprovalHandleChange}
                       class="form-control">
                      <option value="No" selected>No</option>
                      <option value="Yes">Yes</option>
                      </select>  */}
                  </MDBCol>
                  <MDBCol  lg="1" className="mb-1">
                    </MDBCol>
                 
                 
                  <MDBCol  lg="3" className="mb-3">
                    
                    <div id="timeline" style={{display:'none'}}>
                        <label id="label" style={{marginTop:'2%'}}>Creatives approval timeline (Hours)</label>
                        <select id="lpTimeline"  style={{border:'1px solid #ccc',
                        boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)', height:'calc(2.25rem + 12px)'}}name="lpTimeline" 
                        value={this.state.lpTimeline} onChange={this.handleChange} class="form-control">
                        <option value="12" >12 </option>
                        <option value="24" selected>24 </option>
                        <option value="48">48 </option>
                        </select>
                        </div>
                  </MDBCol>
                  <MDBCol  lg="3" className="mb-3">
                    
                    <div id="approvedBy" style={{display:'none'}}>
                        <label id="label" style={{marginTop:'2%'}}>Creatives approval required for</label>
                        <select id="creativeApprovalRequiredFor"  style={{width:'180px',height:'34.5px'}}name="creativeApprovalRequiredFor" 
                        value={this.state.creativeApprovalRequiredFor} onChange={this.handleChange} class="form-control">
                        <option value="Agency" selected>Agency</option>
                        <option value="Advertiser">Advertiser</option>
                        </select>
                        </div>
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Marketing channel </label>
                    <select
                      value={this.state.marketingChannel}
                      onChange={e=>{this.handleChangeMarketingChannel(e);this.handleChange(e);}}
                      id="marketingChannel"
                      className="form-control"
                      name="marketingChannel">
                      <option value="TeleMarketing">TeleMarketing</option>
                      <option value="Email" selected>Email</option>
                      <option value="Email/Telemarketing" selected>Email/Telemarketing</option>
                      <option value="Display">Display</option>
                      <option value="Programmatic">Programmatic</option>
                      <option value="Social">Social</option>
                      <option value="Hybrid">Hybrid</option>
                      <option value="Others">Others</option>
                    </select>
                  </MDBCol>
                  <MDBCol  lg="3" className="mb-3">
                    
                    <div id="voiceLog"style={{display:this.state.displayCallaudit}} >
                        <label id="label" style={{marginTop:'2%'}}>Call Audit</label>
                        <select id="callAudit"  style={{border:'1px solid #ccc',
                        boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)', height:'calc(2.25rem + 12px)',width:'182px'}}name="callAudit" 
                        value={this.state.callAudit} onChange={this.handleChange} class="form-control">
                        <option value="Yes" >Yes </option>
                        <option value="No" >No </option>
                        </select>
                        </div>
                  </MDBCol>

                  
                  {/* <MDBCol lg="3" className="mb-3">
                    <label id="label">Lead Delivery Option </label>
                    <select
                      value={this.state.leadDeliveryOption}
                      onChange={this.handleChange}
                      id="leadDeliveryOption"
                      className="form-control"
                      name="leadDeliveryOption">
                      <option value="Excel" selected>Excel</option>
                      <option value="CSV">CSV</option>
                    </select>
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Upload Lead Delivery Format</label>
                    <input type="file" class="fileupload" id="uploadFormat" name="uploadFormat"
                      value={this.state.uploadFormat} onChange={this.handleUploadChange} />
                  </MDBCol> */}
                  {/* style={{width:'500px'}} */}
                  {/*end of second row*/}
                </MDBRow>   
                <br />
                {/*start third row*/}
                <legend className="legend labelsubheading">Campaign Specification</legend>
                <MDBRow>

               
                  <MDBCol lg="3" className="mb-3">
                  <label id="label">Region<span style={asterisk}>*</span></label>
                    <br />
                    
                    <Picky
           value={this.state.region} 
                options={regionoptions}
                onChange={this.RegionhandleChange}
                className={this.state.inputClassregion}
                open={false}
                valueKey="id"
                labelKey="name"
                multiple={true}
                includeSelectAll={true}
                includeFilter={true}
                dropdownHeight={200}
                numberDisplayed={1}
                id="region" name="region"
              /> 


                    {/* <Select value={this.state.region}  onChange={this.RegionhandleChange} id="region" 
 className={this.state.inputClassregion}  isMulti  name="region" options={regionoptions} class="basicmulti-select" classNamePrefix="select" />
   */}
                    {/* <div>
                    <select
                      value={this.state.region}
                      onChange={this.RegionhandleChange}
                      id="region"
                      class="form-control"
                      className={this.state.inputClassregion}
                      name="region">
                    <option value="Select Region" selected disabled>Select Region</option>
                
                    </select>
                    </div> */}
                  </MDBCol>


                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Country <span style={asterisk}>*</span></label>
                    <br />

                    
                    <Picky
           value={this.state.country} 
                options={this.state.countryoptions}
               // countryHandleChangeBack
                onChange={this.countryHandleChange}
                
                className={this.state.inputClassDropDownCountry}
                open={false}
                valueKey="id"
                labelKey="name"
                multiple={true}
                includeSelectAll={true}
                includeFilter={true}
                dropdownHeight={300}
                numberDisplayed={1}
                id="country" name="country"
              /> 

                    
{/* <Select value={this.state.country}  onChange={this.countryHandleChange} id="country" 
 className={this.state.inputClassDropDownCountry}  isMulti  name="country" options={this.state.countryoptions} class="basicmulti-select" classNamePrefix="select" />
   */}
              </MDBCol>
              <MDBCol lg="3" className="mb-3">
                      <label id="label">State:&nbsp;</label>
                      <Tooltip placement="right" title="State Validation">
                    <input type="checkbox" id="stateChk" name="stateChk" 
                      value={this.state.stateChk} onChange={this.statehandleChange}/></Tooltip>
                    {/* </MDBCol> */}
                    {/* <MDBCol lg="2" className="mb-2"> */}
                      <div id="statefile" style={{display:'none'}}>
                      <input type="file" value={this.state.stateFile}  onChange={this.stateFilehandleChange} name="stateFile" id="stateFile" ref={ref=> this.fileInputstate = ref}/>
                      </div>
                      <div style={{color:'red'}}>{this.state.filestateerrors.fileextensions}
                      </div>
                     </MDBCol>


                     <MDBCol lg="3" className="mb-3">
                      <label id="label">City:&nbsp;</label>
                      <Tooltip placement="right" title="City Validation">
                    <input type="checkbox" id="cityChk" name="cityChk" 
                      value={this.state.cityChk} onChange={this.cityhandleChange}/></Tooltip>
                    {/* </MDBCol> */}
                    {/* <MDBCol lg="2" className="mb-2"> */}
                      <div id="cityfile" style={{display:'none'}}>
                      <input type="file"  onChange={this.cityFilehandleChange} name="cityFile" id="cityFile" ref={ref=> this.fileInputcity = ref}/>
                      </div>
                      <div style={{color:'red'}}>{this.state.filecityerrors.fileextensions}
                      </div>
                     </MDBCol>

                  
                 
                </MDBRow>
                <MDBRow>
                   


                     <MDBCol lg="3" className="mb-3">
                      <label id="label">Zip code:&nbsp;</label>
                      <Tooltip placement="right" title="Zip Code Validation">
                    <input type="checkbox" id="zipcodeChk" name="zipcodeChk" 
                      value={this.state.zipcodeChk}   onChange={this.zipcodehandleChange}/></Tooltip>
                    {/* </MDBCol> */}
                    {/* <MDBCol lg="2" className="mb-2"> */}
                      <div id="zipcodefile" style={{display:'none'}}>
                      <input type="file" onChange={this.zipcodeFilehandleChange} name="zipcodeFile" id="zipcodeFile" ref={ref=> this.fileInputzipcode = ref}/>
                      </div>
                      <div style={{color:'red'}}>{this.state.fileerrors.fileextensions}
                      </div>
                     </MDBCol>


                     <MDBCol lg="3" className="mb-3">
                     {/* create */}
                <label id="label">Industry <span style={asterisk}>*</span>&nbsp;&nbsp;</label>
                <Tooltip title="Not Applicable" placement="left"><input type="checkbox" id="NA1" name="NA1"  style={{float:'right'}}value={this.state.NA} onChange={this.handleIndustryNA}/></Tooltip>
             <span id="span1" class={this.state.drpdisabled}>
               <Tooltip title="Add Custom Industry"><Icon type="plus-circle" style={{color:'#2196f3'}} data-toggle="modal" data-target="#customIndustryPopup"/></Tooltip> 
            
                 <br />
                       
                 <Picky
              value={this.state.industry}
              options={industryOptions}
              onChange={this.industryhandleChange}
              className={this.state.inputClassDropDownIndystry+ ' ' + this.state.drpdisabled} 
              open={false}  
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={200}
              numberDisplayed={1}
              id="industry" name="industry"
             visible={false}
            />
            </span>
        
          
                  </MDBCol>

 {/* <!-- Modal --> */}
 <div class="modal fade" id="customIndustryPopup" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
    
      {/* <!-- Modal content--> */}
      <div class="modal-content">
        <div class="modal-header custom-classPopup">
        
          <h4 class="modal-title" style={{color:'#144999'}}><font color='white'>Custom Industry</font></h4>
          <button type="button" className={this.state.buttonDisplayIndustry} class="cancel-button" style={{fontSize:15,color:'#ffffff',float:'right'}} data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <div  class="errorMessage">
          {this.state.industryerrors.industryundefined}
        </div>


        <div class="successMessage" style={{ fontSize: '14px'}}>
            {this.state.industryerrors.industrysuccess}
        </div>
          <p>
         
      <div class="row">
      <div class="col-sm-12"> 
      <textarea id="customIndustry" rows="30" name="customIndustry"  className={this.state.inputClassCustomIndustry}
                      value={this.state.customIndustry} onChange={this.handleChange} class="form-control" />
      </div>
      </div>
      
      </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addIndustry}
             style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>

  {/* End of Industry Modal */}


  {/* create */}
  <MDBCol lg="3" className="mb-3">
                  <label id="label">Employee size <span style={asterisk}>*</span>&nbsp;&nbsp; </label>
                  <Tooltip title="Not Applicable" placement="left"><input type="checkbox" id="NA2" name="NA2"  style={{float:'right'}}value={this.state.NA} onChange={this.handleEmpsizeNA}/></Tooltip>
                  <span id="span2" class={this.state.drpdisabledemp}>
                  <Tooltip title="Add Custom Employee Size Range"><Icon type="plus-circle"style={{color:'#2196f3'}} data-toggle="modal" data-target="#customEmpSize"/></Tooltip>
                  
                  <Picky
              value={this.state.employeeSize}
              options={employeeSizeOption}
              onChange={this.employeehandleChange}
              // className={this.state.inputClassDropDownEmployeeSize}
              className={this.state.inputClassDropDownEmployeeSize+ ' ' + this.state.drpdisabledemp} 

              open={false}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={200}
              id="employeeSize" name="employeeSize"
            />
                
</span>
                    {/* <label id="label">Employee Size<span style={asterisk}>*</span> </label>
                    <Select value={this.state.employeeSize} isMulti  onChange={this.employeehandleChange} options={employeeSizeOption}  className={this.state.inputClassDropDownEmployeeSize} class="basic-multi-select" classNamePrefix="select"  required/>
   */}
                </MDBCol>

                {/* create */}
                <MDBCol lg="3" className="mb-3">
                    <label id="label">Company revenue &nbsp;&nbsp;   <Tooltip title="Add Custom Revenue Size Range"><Icon type="plus-circle" data-toggle="modal" data-target="#customRevenueSize"/></Tooltip> </label>

                    <Picky
              value={this.state.companyRevenue}
              options={companyRevenueOption}
              onChange={this.revenuehandleChange}
              className={this.state.inputClassDropDowncompanyRevenue}
              open={false}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={200}
              id="companyRevenue" name="companyRevenue"
            />
{/* <Select  isMulti  onChange={this.revenuehandleChange} options={companyRevenueOption} className={this.state.inputClassDropDowncompanyRevenue} class="basic-multi-select" classNamePrefix="select"  required/>
   */}
                </MDBCol>

                </MDBRow>

                <MDBRow>


                <MDBCol lg="3" className="mb-3">

                {/* Create  */}
        <label id="label">Job level <Tooltip title="Add Custom Job Level"><Icon type="plus-circle"  data-toggle="modal" data-target="#customJobLevel"/></Tooltip> </label>
        <br />

          <Picky
          value={this.state.arrayValue}
          options={bigList}
          onChange={this.selectMultipleOption}
          open={false}
          valueKey="id"
          className={this.state.inputClassDropDownJobLevel}
          labelKey="name"
          multiple={true}
          includeSelectAll={true}
          includeFilter={true}
          dropdownHeight={160}
          name="jobLevel" 
          id="jobLevel" 
          />
        </MDBCol>
        {/* Create */}
                  <MDBCol lg="3" className="mb-3">
                  <label id="label">Job function &nbsp;&nbsp;   <Tooltip title="Add Custom Job Function"><Icon type="plus-circle" data-toggle="modal" data-target="#customJobFunction"/></Tooltip> </label>
                 <br />
                 <Picky
              value={this.state.jobFunction}
              options={jobFunctionOptions}
              onChange={this.jobFunctionhandleChangeif}
              className={this.state.inputClassDropDownJobFunction}
              open={false}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={200}
              id="jobFunction" name="jobFunction"
            />

                    {/* <Select value={this.state.jobFunction}   isMulti onChange={this.jobFunctionhandleChangeif} 
                    options={jobFunctionOptions} className={this.state.inputClassDropDownJobFunction}
                     class="basic-multi-select" classNamePrefix="select" required /> */}
                  
                  </MDBCol> 

                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Job title <span style={asterisk}>*</span></label>&nbsp;
                    <Tooltip placement="right" title="Job Title Validation">
                     <input type="checkbox" id="jobChk" name="jobChk" 
                      value={this.state.jobTitlechk} onChange={this.handleLeadvalidation}/></Tooltip> 
                    <textarea id="jobTitle" name="jobTitle"
                      value={this.state.jobTitle} onChange={this.handleChange} class="form-control" required />
                  </MDBCol>

                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Other specs</label>
                    <textarea id="otherSpecs" name="otherSpecs"
                      value={this.state.otherSpecs} onChange={this.handleChange} class="form-control" />
                  </MDBCol>
                   {/* <MDBCol lg="3" className="mb-3">


<label id="label">Job Level <span style={asterisk}>*</span></label>
<br />
<Select value={this.state.jobLevel} isMulti  onChange={this.jobLevelhandleChange} options={jobLevelOptions}  className={this.state.inputClass} class="basic-multi-select" classNamePrefix="select"  required/>


</MDBCol> */}

                  

                  {/* <MDBCol lg="3" className="mb-3">
                  <div>
                    <label id="label">Upload Industry</label>
                    </div>
                    <div>
                    <input type="file" id="uploadIndustry" name="uploadIndustry"
                      value={this.state.uploadIndustry} onChange={this.handleuploadIndustry}
                      style={{ width: '250px' }} class="fileupload" />
                      </div>
                  </MDBCol> */}
               
                
               
                {/* <!-- Modal --> */}
  <div class="modal fade" id="customEmpSize" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
    
      {/* <!-- Modal content--> */}
      <div class="modal-content">
        <div class="modal-header custom-classPopup">
        
          <h4 class="modal-title"style={{color:'#144999'}}><font color='white' >Custom Employee Size Range</font></h4>
          <button type="button" className={this.state.buttonDisplayEmp} class="cancel-button" style={{fontSize:15,color:'#ffffff',float:"right"}}  data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        
          <p>
          <div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
          {this.state.reerrors.fromToMsg} 
          {this.state.reerrors.onlyNumMsg} 
          {this.state.reerrors.greaterMsg} 
          {/* {this.state.reerrors.onlyTextMsg} */}
          {this.state.reerrors.commaNotallowed}
         {this.state.reerrors.formatMsg}
         </div>
         <div className="errorMsg" style={{ fontSize: '14px', color: 'green' }}>
         
          {this.state.reerrors.success}  
         </div>
        <label>Employee Size Range</label>
        <div class="container-fluid">
        <div class="row">
         
        {this.state.customEmployeeSizeFromPopup.map((customEmployeeSizeFromPopup,i)=>{return(
                              <div key={customEmployeeSizeFromPopup.count}>
                              {/* <p> Custom Question: {i+1}</p> */}
                              &nbsp;&nbsp;
  <div class="col-sm-12 col-md-5 col-lg-5 col-xs-12">
   <Input  placeholder="From" id="From1" name="From"  onChange={this.customEmployeeSizeFromPopupHandleChange.bind(this,i)}/>
   </div>
   <div class="col-sm-12 col-md-5 col-lg-5 col-xs-12">
  <Input  placeholder="To" id="To1" name="To" onChange={this.customEmployeeSizeFromPopupHandleChange.bind(this,i)}/>
  </div>
   <label ></label>
   <div class="col-sm-12 col-md-2 col-lg-2 col-xs-12">
  <a><span class="glyphicon glyphicon-minus-sign" id={i} onClick={()=>this.removeDynamicEmployeeSize(i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span></a>
     </div>                         
                              </div>
                             
                        )})}
</div>
<br/>
<div class="row">

   <div class="col-sm-4 col-md-4 col-lg-4 col-xs-4">                      
 <a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDynamicEmployeeSize(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize: 14}}></span></a>
</div>
 {/*from to close*/} 
</div>
<br/>
<div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
        
          {this.state.reerrors.onlyTextMsg}
        
         </div>
         <div className="errorMsg" style={{ fontSize: '14px', color: 'green' }}>
         
          {this.state.reerrors.successText}  
         </div>
<br/>
<label>Employee Size Value </label> (&nbsp;&nbsp;<span>E.g.1000 &lt;,1000+,&gt;2500</span>)
<div class="row">

 {this.state.customEmployeeSizePopUpText.map((customEmployeeSizePopUpText,i)=>{return(
                              <div key={customEmployeeSizePopUpText.count}>
                             
                              &nbsp;&nbsp;
                              <div class="col-sm-12 col-md-12 col-lg-10 col-xs-10"> <Input   id="empSize1" name="customEmployeeSizeText" onChange={this.customEmployeeSizePopupHandleChange.bind(this,i)}/></div>
  
                              <div class="col-sm-12 col-md-2 col-lg-2 col-xs-12">
                           
                              <a><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDynamicEmployeeSizetxt(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span></a>
                              </div>
                              <br/>
                              </div>
                        )})}
</div>
<br/>
<div class="row">
<div class="col-sm-2 col-md-2 col-lg-2 col-xs-2">                      
 <a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDynamicEmployeeSizetxt(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize: 14}}></span></a>
</div>
</div>
</div>

</p>
</div>

       
    
       
        <div class="modal-footer">
        <button type="button" class="btn btn-default" onClick={this.addEmpSize}
        
        style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
        >Add</button>
        </div>
      </div>
      
    </div>
  </div>

  {/* End of Emp Size Modal */}


       {/* <!-- Modal --> */}
       <div class="modal fade" id="customRevenueSize" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
    
      {/* <!-- Modal content--> */}
      <div class="modal-content">
        <div class="modal-header custom-classPopup">
        
          <h4 class="modal-title"style={{color:'#144999'}}><font color='white'>Custom Revenue Size</font></h4>
          <button type="button" className={this.state.buttonDisplayRevenue} class="cancel-button" style={{fontSize:15,color:'white',float:'right'}}  data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
     
          <p>
          <div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
          {this.state.reerrors.fromToMsgrev} 
          {this.state.reerrors.onlyNumMsgrev} 
          {this.state.reerrors.greaterMsgrev} 
          {this.state.reerrors.onlyTextMsgrev}  

         
         </div>
         <div className="errorMsg" style={{ fontSize: '14px', color: 'green' }}>
         
          {this.state.reerrors.successrev}  
         </div>
        <div class="container-fluid">
<label>Custom Revenue Size Range</label>
          <div class="row">
{this.state.customRevenuePopupFromTo.map((customRevenuePopupFromTo,i)=>{return(
                              <div key={customRevenuePopupFromTo.count}>
                              {/* <p> Custom Question: {i+1}</p> */}
                              &nbsp;&nbsp;
                              <div class="col-sm-10 col-sm-12 col-md-5 col-lg-5"> 
<Input  placeholder="From" id="From1" name="From" onChange={this.customRevenueFromPopupHandleChange.bind(this,i)}/>
                              </div>
  <div class="col-sm-12 col-sm-12 col-md-5 col-lg-5">
    <Input  placeholder="To" id="To1" name="To" onChange={this.customRevenueFromPopupHandleChange.bind(this,i)}/></div>
    <div class="col-sm-2 col-sm-2 col-md-2 col-lg-2">
  <a ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDyanamicCompanyRevenue(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span></a>
                              </div>
                              </div>
                        )})}

   </div>
   <br/>
   <div class="row">    
   <div class="col-sm-2 col-sm-2 col-md-2 col-lg-2">                 
 <a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDyanamicCompanyRevenue(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize:14}}></span></a>
</div>
</div>
<br/>

<label>Custom Revenue Size Value</label>(&nbsp;&nbsp;<span>E.g.$1000M</span>)
 <div class="row">

{this.state.customRevenueTextPopup.map((customRevenueTextPopup,i)=>{return(
                             <div key={customRevenueTextPopup.count}>
                            
                             &nbsp;&nbsp;
                             <div class="col-sm-10 col-sm-10 col-md-10 col-lg-10"> <Input  id="customCompanyRevenue" name="customCompanyRevenue" onChange={this.customRevenueTextPopupHandleChange.bind(this,i)}/></div>
 
                             <div class="col-sm-2 col-sm-2 col-md-2 col-lg-2">
                           
                             <a><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDyanamicCompanyRevenueText(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span></a>
                             </div>
                             </div>
                       )})}
</div>
<br/>
 <div class="row">
<div class="col-sm-4 col-sm-4 col-md-4 col-lg-4">                      
<a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDyanamicCompanyRevenueText(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize: 14}}></span></a>
</div>
</div>
</div>
   
 

  

      </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addRevenueSize}
             style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>

  {/* End of Revenue Size Modal */}

  
       {/* <!-- Modal --> */}
       <div class="modal fade" id="customJobFunction" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm">
    
      {/* <!-- Modal content--> */}
      <div class="modal-content">
        <div class="modal-header custom-classPopup">
        
          <h4 class="modal-title"style={{color:'#144999'}}><font color='white'>Custom Job Function </font></h4>
          <button type="button" className={this.state.buttonDisplay} class="cancel-button" style={{fontSize:15,color:'#ffffff',float:'right'}}  data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <p>

          <div class="container-fluid">
  

          <div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
                    
          {this.state.errors.jobFunctionPattern}  
          {this.state.errors.jobfunctionundefined}
          {this.state.errors.jobFunctionNofields}  
        </div>

        <div className="errorMsg" style={{ fontSize: '14px', color: 'green' }}>
                    
                    {this.state.errors.success}  
                    
                  </div>
        <div class="row">
{this.state.customJobFunctionPopup.map((customJobFunctionPopup,i)=>{return(
  <div key={customJobFunctionPopup.count}>
  {/* <p> Custom Question: {i+1}</p> */}
  
  <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 ">
    
 <Input placeholder="Job Function" id="jobFunction1" name="jobFunction" onChange={this.customJobFunctionPopupHandleChange.bind(this,i)}/></div>

<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
  <a  ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDynamicJobFunction(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14,paddingTop:"20px"}}></span></a>
  </div>

  </div>
)})}
</div>
<br/>
<div class="row">
<div class="col-sm-8">                      
<a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDynamicJobFunction(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize:14}}></span></a>
</div>
</div>
      

      </div> 
      </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addJobFunction}
          style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>
  

  {/* End of Job Function Modal */}




   {/* <!-- Modal --> */}
   <div class="modal fade" id="customJobLevel" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm">
    
      {/* <!-- Modal content--> */}
      <div class="modal-content">

      <div class="modal-header" style={{backgroundColor:"#144c9b"}}>
                    <h4 class="modal-title" style={{color:'white',fontSize:'18px'}}>Custom Job Level </h4>
                    <button  className={this.state.buttonDisplayJobLevel} style={{marginTop:"-2px",padding: "1rem", float:"right",color:" white", backgroundColor: "rgb(0, 0, 179)", border: "rgb(0, 0, 179)"}} data-dismiss="modal"  onClick={this.handleRefreshPage}>&times;</button>
                   </div>


        {/* <div class="modal-header custom-classPopup">
        
          <h4 class="modal-title"style={{color:'#144999',paddingRight:'78px'}}><font color='white' style={{paddingRight:'35px'}}>Custom Job Level</font></h4>
          <button type="button" className={this.state.buttonDisplayJobLevel} class="cancel-button" style={{fontSize:15,color:'#ffffff'}} data-dismiss="modal">&times;</button>
        </div> */}
        <div class="modal-body">
          <p>

          <div class="container-fluid">
  

          <div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
                    
          {this.state.errors.joblevelPattern}  
          {this.state.errors.joblevelundefined}  
          {this.state.errors.NoFields}
        </div>

        <div className="errorMsg" style={{ fontSize: '14px', color: 'green' }}>
                    
                    {this.state.errors.success1}  
                    
                  </div>
        <div class="row">
{this.state.customJobLevelPopup.map((customJobLevelPopup,i)=>{return( 
  <div key={customJobLevelPopup.count}> 
 
  
  <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 ">
    
 <Input placeholder="Job Level" id="jobLevel1" name="jobLevel" onChange={this.customJobLevelPopupHandleChange.bind(this,i)}/></div>

<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
  <a  ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDynamicJobLevel(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14,paddingTop:"20px"}}></span></a>
  </div>

  </div> 
 )})} 
</div>
<br/>
<div class="row">
<div class="col-sm-8">                      
<a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDynamicJobLevel(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize:14}}></span></a>
</div>
</div>
      

      </div> 
      </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addJobLevel}
             style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>
  

  {/* End of Job Function Modal */}

                </MDBRow>

                <MDBRow>

                  {/* <MDBCol lg="3" className="mb-3">
                    <label id="label">Job Title <span style={asterisk}>*</span></label>
                    <textarea id="jobTitle" name="jobTitle"
                      value={this.state.jobTitle} onChange={this.handleChange} class="form-control" required />
                  </MDBCol> */}
                  {/* Industry text area added by Sonali */}
                  {/* <MDBCol lg="3" className="mb-3">
                    <label id="label">Industry</label>
                    <textarea id="customIndustry" name="customIndustry"  className={this.state.inputClassCustomIndustry}
                      value={this.state.customIndustry} onChange={this.handleChange} class="form-control" />
                  </MDBCol> */}
                  {/* <MDBCol lg="3" className="mb-3">
                    <label id="label">Other Specs</label>
                    <textarea id="otherSpecs" name="otherSpecs"
                      value={this.state.otherSpecs} onChange={this.handleChange} class="form-control" />
                  </MDBCol> */}
                  


                  {/* <MDBCol lg="3" className="mb-3">
                    <label id="label">Custom Employee Size</label>
                    <textarea id="customEmpSize" name="customEmpSize"  className={this.state.inputClassDropDowncompanyEmpSize1}
                      value={this.state.customEmpSize} onChange={this.handleChange} class="form-control" />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                <MDBCol lg="3" className="mb-3">
                    <label id="label">Custom Company Revenue</label>
                    <textarea id="customCompRevenue" name="customCompRevenue" className={this.state.inputClassDropDowncompanyRevenue1}
                      value={this.state.customCompRevenue} onChange={this.handleChange} class="form-control"required />
                  </MDBCol> */}
                </MDBRow>
                <br/>
                <legend className="legend labelsubheading">Custom Questions</legend>
                <button type="button"  class="btn add-button" data-toggle="modal" data-target="#customQuestion">Add Custom Question</button>

{/* <!-- Modal --> */}
<div class="modal fade" id="customQuestion" role="dialog" data-backdrop="static" data-keyboard="false">
<div class="modal-dialog modal-xl ">

{/* <!-- Modal content--> */}
<div class="modal-content">
 <div class="modal-header custom-classPopup">
 
   <h4 class="modal-title"style={{color:'#144999',paddingRight:'780px'}}><font color='white' style={{paddingRight:'35px'}}>Custom Questions</font></h4>
   <button type="button" class="cancel-button" data-dismiss="modal">&times;</button>
    </div>
   <div>
   <label style={{fontSize:'16px',color:'#2196F3'}}>&nbsp;&nbsp;&nbsp;Instructions</label>
   <ol>
     <li style={{fontSize:'14px'}}>Every answer should be on new Line in text box</li>
     <li style={{fontSize:'14px'}}>Please fill the answer in the respective block</li>
     </ol>
     <hr  />
     <div class="errorMessage">{this.state.errors.validate}</div>
     </div>
 <div class="modal-body">
   <p> 
{/* end of 1st row */}
  
<div class="row">

  {/* <div class="col-sm-4 col-md-4 col-md-12 col-lg-12"> */}
  {this.state.dynamicCustomQuestion.map((dynamicCustomQuestion,i)=>{return(
                              <div key={dynamicCustomQuestion}>
                              {/* <p> Custom Question: {i+1}</p> */}
                              &nbsp;&nbsp;
                              <div class="col-lg-3">
                              <label >Questions</label>
                              
                              <textarea  id="customQuestions" name="question"   onChange={this.handleCustomQuestion.bind(this,i)} style={{width:'250px'}}/>&nbsp;&nbsp;
                          </div>

                          <div class="col-lg-3">
                              <label >Answers</label>
                              <textarea  id="customAnswers" name="answer"   onChange={this.handleCustomQuestion.bind(this,i)} style={{width:'250px'}}/>&nbsp;&nbsp;
                             </div> 
                             
                             <div class="col-lg-3">
                              <label >Not Allowed Answers</label>
                              <textarea  id="avoidAnswers" name="disAllowAnswer"   onChange={this.handleCustomQuestion.bind(this,i)} style={{width:'250px'}}/>
                              &nbsp;&nbsp;
                              </div>

                            {/* <span style={{marginBottom:"54px", display:"inline-block"}}> <a href="#"><i class="fa fa-times fa-lg"  id={i} onClick={()=>this.removeCustomQuestion(i)} ></i></a></span> */}
                              {/* <button type="button" style={{marginBottom:"54px"}} id={i} onClick={()=>this.removeCustomQuestion(i)} class="btn btn-primary">Remove</button> */}
                              <div class="col-lg-3">
                              <label ></label>
                              <a  ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={()=>this.removeCustomQuestion(i)} style={{color:"Red",fontWeight: 'bold',fontSize: 22}}></span></a>
                              
                              </div>
                              </div>
                        )})}


    {/* </div> */}
 
</div>
<div class="row">
  <div class="col-sm-4 col-md-4 col-md-12 col-lg-12">
  <a data-toggle="tooltip" title="Add one more question"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addCustomQuestion(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize: 30}}></span></a>

  {/* <button type="button" onClick={(e)=>this.addCustomQuestion(e)} class="btn btn-primary">Add Custom Question</button> */}
  </div>
 </div>
   {/* <Row gutter={24}>{this.getFields()}</Row>
 <Row>
   <Col span={24} style={{ textAlign: 'right' }}>
     
{this.state.expand ? 
<Button style={{ marginLeft: 8, fontSize: 12 }} onClick={this.toggle}>
Less <Icon type= 'up'></Icon>
</Button>
:  <Button style={{ marginLeft: 8, fontSize: 12 }} onClick={this.toggle}>
More <Icon type= 'down'></Icon>
</Button>
}



    
   </Col>
 </Row> */}
</p>
 </div>
 <div class="modal-footer">
   <button type="button" class="btn add-button"  
  onClick={this.validatecustomQuestion}
   >Add</button>
 </div>
</div>

</div>
</div>

{/* End of Revenue Size Modal */}
           




                {/* <div class="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="float-right">
                   <button class="btn  btn-primary btn-block text-uppercase" type="submit">Save and Next</button>
                    </div>
                  </div>
                </div> */}
              

              </div> {/* end of cardbody */}
            {/* </div>end of card sign in */}
          </div>{/* end of container */}


        </form>


        <Footer />

      </div>


    );
  }// else block for Back button
  else {

    
    return (

      <div>
        <Navigation />
        <div class="row"  style={{paddingTop:"90px",paddingBottom:'6px',backgroundColor:'white',border:'1px solid',borderColor:'#bfc7cc',width:'110%',position:'fixed',zIndex:'1'}}>
        <div class=" col-lg-4"> <label></label></div>
        <div class= " col-xs-6 col-lg-4" ><label class="labelfont" style={{color:'#164495',fontSize:'20px'}}>STEP 1 : Campaign Details</label></div>
        <div class="col-xs-6 col-lg-4" class="createbutton"><button class="btn add-button" 
         form="createcampaignback" type="submit">Save & Next</button>
</div>

</div>
        {/* <div class="col" align="center" style={{paddingTop:"90px"}}><label id="labelheading" >STEP 1 : Campaign Details</label></div> */}
        <div className="col-lg-12" class="displayerror" style={{ display: this.state.alertDisplay }}>
          <div class="container-fluid">
            {/* <div class="card card-signin my-1"> */}
              <div class="card-body" >
                <div class="row">
                  <div class="col-lg-12"
                    style={{ border: this.state.alertBorder, display: this.state.alertDisplay }}>
                    
                      <div class="errorMessage">{this.state.errors.commonError?this.state.errors.commonError:''}</div>
                      <div className="errorMessage" >
                        {this.state.errors.campNameErrorBack?this.state.errors.campNameErrorBack:''}
                        {this.state.errors.blankInteractionDaysBack}
                        {this.state.errors.leadAllocation?this.state.errors.leadAllocation:''}
                        {this.state.errors.noOfLeadPerDomain?this.state.errors.noOfLeadPerDomain:''}
                        {this.state.errors.pacingLeadAllocation?this.state.errors.pacingLeadAllocation:''}
                        {this.state.errors.endDate?this.state.errors.endDate:''}
                        {this.state.errors.startDate?this.state.errors.startDate:''}
                        {this.state.errors.LeadPerDomain?this.state.errors.LeadPerDomain:''}
                        {this.state.errors.clientName?this.state.errors.clientName:''}
                        {this.state.errors.budget?this.state.errors.budget:''}
                        {this.state.errors.cpl?this.state.errors.cpl:''}
                        {this.state.errors.revenueFrom?this.state.errors.revenueFrom:''}  
                         {this.state.errors.revenueTo?this.state.errors.revenueTo:''}  
                         {this.state.errors.empSizeFrom?this.state.errors.empSizeFrom:''}  
                         {this.state.errors.empSizeTo?this.state.errors.empSizeTo:''} 
                         {this.state.errors.jobFunction?this.state.errors.jobFunction:''} 
                         {this.state.errors.firstdeliverydateEnddate?this.state.errors.firstdeliverydateEnddate:''}
                         {this.state.errors.firstdeliverydateStartdate?this.state.errors.firstdeliverydateStartdate:''}
                         {this.state.errors.firstdeliverydateCurrentDate?this.state.errors.firstdeliverydateCurrentDate:''}
                         {this.state.errors.POcerror?this.state.errors.POcerror:''}
                         {this.state.errors.TimeLineerror?this.state.errors.TimeLineerror:''}
                         {this.state.errors.filemincity?this.state.errors.filemincity:''}
                         {this.state.errors.fileminstate?this.state.errors.fileminstate:''}
                         {this.state.errors.fileminzipcode?this.state.errors.fileminzipcode:''}
                         {this.state.errors.fileextension?this.state.errors.fileextension:''}
                         {this.state.errors.regionError?this.state.errors.regionError:''}

                      </div>
                    
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>

      


        <form method="POST" id="createcampaignback" name="Campaign" className="needs-validation" onSubmit={this.submitCampaign} noValidate>
          <div class="container-fluid" style={{ paddingBottom: '60px' }}>
            {/* <div class="card card-signin my-1"> */}
              <div class="card-body" >
                {/*start first row */}
              
                <legend className="legend labelsubheading">Basic Details</legend>
                {this.state.campaignDetail.map(campaignDetail => {
                  return (
                    <MDBRow>


                      <MDBCol lg="3" className="mb-3">
                        <label id="label">Campaign name <span style={asterisk}>*</span></label>
                        <input
                          defaultValue={campaignDetail.campaignName}
                          value={this.state.campaignName}
                          onChange={this.handleChange}
                          type="text"
                          id="campaignName"
                          className="form-control"
                          name="campaignName"

                          required />
                      </MDBCol>



                      {/* {this.state.campaignDetail.map(campaignDetail => {
                    return (
                      <div>
                    <label id="label">Campaign Type</label>
                    <select
                      defaultValue={campaignDetail.ABM}
                      onChange={this.handleChange}
                      id="abm"
                      className="form-control"
                      name="ABM">
                      <option value="Open" selected="Open">Open</option>
                      <option value="Abm">Abm</option>
                    </select>
                    </div>
                    )})} */}
                      <MDBCol lg="3" className="mb-3">
                      {this.state.campaignDetail.map(campaignDetail => {
                    return (
                      <div>
                        <label id="label">Client name<span style={asterisk}>*</span></label>
&nbsp;&nbsp;
                        <Tooltip  placement="right" title="Add Client">
                        <a href={'/addAdvertiser?add=+step1&campID='+this.state.campID+''}>
                        <Icon type="plus-circle"/>
                        </a>
                                               </Tooltip>
                        < select id="clientName" name="clientName"
                        defaultValue={campaignDetail.clientName}
                        value={campaignDetail.clientName}
                      // value={this.state.clientName}
                      onChange={this.handleClientChangeBack}
                      class="form-control"
                      required>
                      <option value="" disabled selected>Select Client</option>
                      {this.state.clientNameDetailsStep1.map((clientNameDetailsStep1) => 
                        
                          <option value={clientNameDetailsStep1} >{clientNameDetailsStep1} </option>
                        )}; 
                     </select>
                      </div>
                    )})}
                  
                      
                      </MDBCol>

                      <MDBCol lg="3" className="mb-3">
                        <label id="label">Agency campaign ID</label>
                        <input defaultValue={campaignDetail.clientCampID}
                          onChange={this.handleChange}
                          type="text"
                          id="clientCampID"
                          className="form-control"
                          name="clientCampID"
                        />
                      </MDBCol>

                      {/* <MDBCol lg="3" className="mb-3">
                        <label id="label">Insertion Order</label>
                        < select id="insertionOrder" name="insertionOrder"
                          defaultValue={campaignDetail.insertionOrder}
                          onChange={this.handleChange}
                          class="form-control"
                          required>
                          <option value="Yes" selected>Yes</option>
                          <option value="No">No</option>
                        </select>
                      </MDBCol> */}
                      <MDBCol lg="3" className="mb-3">
                        <label id="label">Campaign status </label>
                        < select id="campaignStatus" name="campaignStatus"
                          defaultValue={campaignDetail.campaignStatus}
                          onChange={this.handleChange}
                          class="form-control"
                          required>
                          <option value="Active" selected>Active</option>
                          <option value="Pending">Pending</option>
                          <option value="Pause">Pause</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </MDBCol>
                      <MDBCol lg="3" className="mb-3">
                        <label id="label">Start date  <span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                        <input type="date" id="startDate" name="startDate" ref={(input) => { this.nameInput = input; }}    min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                          defaultValue={campaignDetail.startDate}
                          value={this.state.startDate}
                          onChange={this.handleChange}
                          className="form-control"

                          required />
                      </MDBCol>
                      <MDBCol lg="3" className="mb-3">
                        <label id="label">End date  <span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                        <input type="date" id="endDate" name="endDate" min=""
                          defaultValue={campaignDetail.endDate}
                          value={this.state.endDate}
                          onChange={this.handleChange}
                          className="form-control"
                          required />
                      </MDBCol>
                      <MDBCol lg="3" className="mb-3">
                  <label id="label">First lead delivery<span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "10px" }}>(dd-mm-yyyy) </span></label>
                    <input type="date" class="form-control"
                     defaultValue={campaignDetail.firstLeadDeliveryDate}
                      id="firstLeadDeliveryDate" name="firstLeadDeliveryDate"  min=""
                      value={this.state.firstLeadDeliveryDate} onChange={this.handleChange} required/>
                  </MDBCol>

                  
                  <MDBCol lg="3" className="mb-3">
                        <label id="label">No. of lead per domain</label>
                        <input type="textbox" class="form-control"
                          id="noOfLeadPerDomain" name="noOfLeadPerDomain"
                          defaultValue={campaignDetail.noOfLeadPerDomain} onChange={this.handleChange} />
                      </MDBCol>
                      
                    

                             <MDBCol lg="3" className="mb-3">
                        <label id="label">Lead allocation <span style={asterisk}>*</span></label>
                        <input type="textbox" class="form-control"
                          id="leadAllocation" name="leadAllocation"
                          defaultValue={campaignDetail.leadAllocation} 
                          value={this.state.leadAllocation}
                          onChange={this.leadAllocationHandleChangeBack} required/>
                          {/* // onChange={this.leadAllocationHandleChangeBack} required /> */}
                      </MDBCol>

                      
                      

                      <MDBCol md="3" className="mb-3">
                                <label id="label">Campaign budget<span style={asterisk}>*</span></label>
                                
                                  <input
                                   defaultValue={campaignDetail.budget} 
                                  value={this.state.budget}
                                    onChange={this.budgetHandleChangeBack}
                                    type="text" 
                                    id="budget" name="budget"
                                    class="form-control"
                                     required />
      
                                </MDBCol>
                                <MDBCol lg="3" className="mb-3">

                                  <label id="label">CPL<span style={asterisk}>*</span></label>
                                  <input 
                                  defaultValue={campaignDetail.CPL} 
                                  value={this.state.CPL}
                                  onChange={this.cplHandleChangeBack}
                                  type="text"
                                  id="cpl" style={{width:'180px'}}
                                  class="form-control"
                                  name="CPL" required />

                                    
                                    </MDBCol>


                                    <MDBCol lg="3" className="mb-3">
                                <label id="label">Campaign currency<span style={asterisk}>*</span></label>
                                
                                  <select id="currency" style={{ border: '1px solid #ccc', boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)', height: 'calc(2.25rem + 12px)' }} name="currency"
                                   defaultValue={campaignDetail.currency} 
                                   onChange={this.handleChange} class="form-control">
                                  {/* <option defaultValue={sdBudget.currency} >{sdBudget.currency ? sdBudget.currency:"USD - US Dollar"}</option> */}
                                    <option value="USD">USD - US Dollar</option> 
                                    <option value="EUR" >EUR - Euro</option>
                                    <option value="GBP">GBP - British Pound</option>
                                    <option value="INR">INR - Indian Rupee</option>
                                    <option value="AUD">AUD - Australian Dollar</option>
                                    <option value="CAD">CAD - Canadian Dollar</option>
                                    <option value="SGD">SGD - Singapore Dollar</option>
                                    <option value="CHF">CHF - Swiss Franc</option>
                                    <option value="MYR">MYR - Malaysian Ringgit</option>
                                    <option value="JPY">JPY - Japanese Yen</option>
                                    <option value="CNY">CNY - Chinese Yuan Renminbi</option>
      

                                  </select>
                                  </MDBCol>


                                
                      {/* <MDBCol lg="6" className="mb-6">

                      <div>
                        <label id="label">Timezone<span style={asterisk}>*</span></label></div>
                        <div>
                        <Picky
                value={this.state.timeZone} 
                options={TimeZoneOption}
               onChange={this.handleChangetimezoneBack}
                className={this.state.inputClass}
                open={false}
                valueKey="id"
                labelKey="name"
                multiple={false}
                includeSelectAll={true}
                includeFilter={true}
                dropdownHeight={400}
                id="timeZone" name="timeZone"
                width="180px"
              /> 
                      
                        </div>
                      </MDBCol> */}
                      
                 

  <MDBCol lg="3" className="mb-3">
                      {/* <label id="label">Created By</label> */}
                        <input type="hidden" class="form-control"
                          id="createdBy" name="createdBy" defaultvalue={user.firstName+" "+user.lastName}
                          defaultValue={campaignDetail.createdByCompanyName} onChange={this.handleChange} readonly />
                     
                      </MDBCol>
                    </MDBRow>
                  )
                })}
                {/*end of first row*/}
                <br />
                {/*start second row*/}
                <legend className="legend labelsubheading">Delivery Option</legend>
                {this.state.campaignDetail.map(campaignDetail => {
                  return (
                    <MDBRow>
                      <MDBCol lg="3" className="mb-3">
                        <label id="label">Pacing </label>
                        <select
                          defaultValue={campaignDetail.pacing}
                          onChange={this.handleChange}
                          id="pacing"
                          className="form-control"
                          name="pacing">
                          <option value="Daily">Daily</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly" selected>Monthly</option>
                          <option value="Quaterly">Quaterly</option>
                        </select>
                      </MDBCol>
                      <MDBCol lg="3" className="mb-3">
                        <label id="label">Pacing count</label>&nbsp;&nbsp;&nbsp;
                        <Tooltip title="Customize pacing"><Icon type="plus-circle" style={{color:'#2196f3'}} data-toggle="modal" data-target="#customPacingCount" onClick={this.func2}/>
                         </Tooltip>
                        <input type="text" id="pacingLeadAllocation" name="pacingLeadAllocation"
                          defaultValue={campaignDetail.pacingLeadAllocation}
                          onChange={this.handleChange}
                          class="form-control"
                        />

                        {/* <!-- Modal --> */}
     <div class="modal fade" id="customPacingCount" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
      <div class="modal-header custom-classPopup">
        
        <h4 class="modal-title" style={{color:'#144999',paddingRight:'600px'}}><font color='white'>Monthly Allocation</font></h4>
        <button type="button" className={this.state.buttonDisplayIndustry} class="cancel-button" style={{fontSize:15,color:'#ffffff'}} data-dismiss="modal">&times;</button>
      </div>
        <div class="modal-body">
         
            <div class="row">
            
              <div class="col-lg-6">
             
            <label id="label">Unit&nbsp;: </label>
            <span> <select
             defaultValue={this.state.pacingCampaignDetails.pacingUnit}
                
              
                        onChange={this.handleChange}
                          id="pacingUnit"
                          name="pacingUnit"
                          style={{height:'30px',borderRadius:'5px'}}>
                          <option value="Percentage" selected>%</option>
                          <option value="Number">Num</option>
                      </select></span>
             
                      </div>
                      

                      <div  class="col-lg-6">
                        <label id="label">&nbsp;Total Leads:&nbsp;</label>{campaignDetail.leadAllocation}
                      </div>
                </div>
                <br/>
                <div class="SuccessMessage" style={{float:'center',color:'green'}}>
                  {this.state.pacingSuccess.PacingSuccess}
                 
                  </div>
                <div class="errorMessage" style={{float:'center'}}>
           {this.state.errors.blankEndDateback}
           {this.state.errors.wrongValuesBack}
                {this.state.errors.pacingExtraMonthsBack}
                {this.state.errors.leadRequired}
                  {this.state.errors.percentagePer}
                  {this.state.errors.countPer}
                  {this.state.errors.percentagePerCount}
                  {this.state.errors.countPerCount}
                  {this.state.errors.Pacingdate}
                  {this.state.errors.PacingCurrentMonth}
                  </div>
                  <br/> 

          <div class="table-responsive-lg">   
                                    <table class=" table table-striped">
                                      <thead>
                                        <tr class="info">
                                          <th class="table-header" style={{width:'120px'}}>Month</th>
                                          <th class="table-header" style={{width:'120px'}}>Lead %</th>
                                          <th class="table-header" style={{width:'120px'}}>Lead count</th>
                                          <th class="table-header" style={{width:'120px'}}>End date</th>
                                          <th class="table-header" style={{width:'120px'}}>Carry forward</th>
                                          <th class="table-header" style={{width:'120px'}}></th>
                                          </tr>
                                      </thead>
                                     {/* {this.state.pacingUnit} */}

                                     {this.state.pacingUnit===undefined?

<tbody>
                                      
{this.state.pacingCampaignDetails.map((pacingCampaignDetailsData,i)=>{return(



  <tr>
   
  <td><input type="text"  id="pacingMonth" name="pacingMonth"
   value={pacingCampaignDetailsData.pacingMonth} disabled
   //value={this.state.pacingMonth} 
   key={pacingCampaignDetailsData.count}
  class="form-control" onChange={this.handleChangeLeadPercentage.bind(this,i)} style={{width:'100px'}}/>
        </td>
  
    
     <td>
     <input type="text" 
      // value={this.state.pacingPercentage}
      value={pacingCampaignDetailsData.pacingPercentage}
      key={pacingCampaignDetailsData.count}
       name="pacingPercentage" class="form-control" style={{width:'80px'}}
    onChange={this.handleChangeLeadPercentage.bind(this,i)}/>
    </td>

  <td><input type="text" 
   value={pacingCampaignDetailsData.pacingLeadCount} 
   key={pacingCampaignDetailsData.count}class="form-control" style={{width:'80px'}}
    name="pacingLeadCount" disabled
          onChange={this.handleChangeLeadPercentage.bind(this,i)} /></td>
    


 

   <td><input type="date" class="form-control"
      //value={this.state.pacingEndDate}
      key={pacingCampaignDetailsData.count}
        id="pacingEndDate" name="pacingEndDate"  min=""
        value={pacingCampaignDetailsData.pacingEndDate} onChange={this.handleChangeLeadPercentage.bind(this,i)}
        style={{width:'150px'}}/></td>
  <td>
     <select
     // value={this.state.pacingCarryForward}
      key={pacingCampaignDetailsData.count}
     value={pacingCampaignDetailsData.pacingCarryForward}
     onChange={this.handleChangeLeadPercentage.bind(this,i)}
      id="pacingCarryForward"
            className="form-control"
            name="pacingCarryForward"
            style={{height:'30px'}}>
            <option value="Yes" >Yes</option>
            <option value="No" selected>No</option>
            </select></td>
            <td>
            <Tooltip title="Please enter 0 if not required " placement="right"><Icon type="question-circle"/></Tooltip>
    {/* <span class="glyphicon glyphicon-minus-sign" id={i} name={pacingCampaignDetailsData.pacingID} onClick={(e)=>this.removePacingBack(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span> */}
    </td>
  </tr>

  
   )})}

  </tbody>:


                                      this.state.pacingUnit=='Percentage'?
                                      <tbody>
                                     {/* {alert("pacingCampaignDetails UI====>"+JSON.stringify(this.state.pacingCampaignDetails))} */}
                                      {this.state.pacingCampaignDetails.map((pacingCampaignDetailsData,i)=>{return(
                                  
                                
                                        <tr>
                                          

                                        <td><input type="text"  id="pacingMonth" name="pacingMonth"
                                         value={pacingCampaignDetailsData.pacingMonth} disabled
                                         //value={this.state.pacingMonth} 
                                         key={pacingCampaignDetailsData.count}
                                        class="form-control" onChange={this.handleChangeLeadPercentage.bind(this,i)} style={{width:'100px'}}/>
                                              </td>
                                        
                                          
                                           <td>
                                           <input type="text" 
                                            // value={this.state.pacingPercentage}
                                            value={pacingCampaignDetailsData.pacingPercentage}
                                            key={pacingCampaignDetailsData.count}
                                             name="pacingPercentage" class="form-control" style={{width:'80px'}}
                                          onChange={this.handleChangeLeadPercentage.bind(this,i)}/>
                                          </td>
                                   
                                        <td><input type="text" 
                                         value={pacingCampaignDetailsData.pacingLeadCount} 
                                         key={pacingCampaignDetailsData.count}class="form-control" style={{width:'80px'}}
                                          name="pacingLeadCount" disabled
                                                onChange={this.handleChangeLeadPercentage.bind(this,i)} /></td>
                                          


                                       

                                         <td><input type="date" class="form-control"
                                            //value={this.state.pacingEndDate}
                                            key={pacingCampaignDetailsData.count}
                                              id="pacingEndDate" name="pacingEndDate"  min=""
                                              value={pacingCampaignDetailsData.pacingEndDate} 
                                              onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                              style={{width:'150px'}}/></td>
                                        <td>{this.state.pacingCampaignDetails.length==i+1?
                                        <select
                                        // value={this.state.pacingCarryForward}
                                         key={pacingCampaignDetailsData.count}
                                        value={pacingCampaignDetailsData.pacingCarryForward}
                                        onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                         id="pacingCarryForward"
                                               className="form-control"
                                               name="pacingCarryForward"
                                               style={{height:'30px',opacity:0.5}}
                                               disabled={true}
                                               >
                                               <option value="Yes" >Yes</option>
                                               <option value="No" selected>No</option>
                                               </select>
                                        :<select
                                        // value={this.state.pacingCarryForward}
                                         key={pacingCampaignDetailsData.count}
                                        value={pacingCampaignDetailsData.pacingCarryForward}
                                        onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                         id="pacingCarryForward"
                                               className="form-control"
                                               name="pacingCarryForward"
                                               style={{height:'30px'}}
                                               >
                                               <option value="Yes" >Yes</option>
                                               <option value="No" selected>No</option>
                                               </select>}
                                           </td>
                                                  <td>
                                                  <Tooltip title="Please enter 0 if not required " placement="right"><Icon type="question-circle"/></Tooltip>        
                                          {/* <span class="glyphicon glyphicon-minus-sign" id={i} name={pacingCampaignDetailsData.pacingID} onClick={(e)=>this.removePacingBack(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span> */}
                                          </td>
                                        </tr>
                                     
                                        
                                         )})}

                                        </tbody>
                                        :
                                        <tbody>
                                        
                                        {this.state.pacingCampaignDetails.map((pacingCampaignDetailsData,i)=>{return(
                                  
    

                                  <tr>
                                   
                                  <td><input type="text"  id="pacingMonth" name="pacingMonth"
                                   value={pacingCampaignDetailsData.pacingMonth} disabled
                                   //value={this.state.pacingMonth}
                                    key={pacingCampaignDetailsData.count}
                                  class="form-control" onChange={this.handleChangeLeadPercentage.bind(this,i)} style={{width:'100px'}}/>
                                        </td>
                                  
                                    
                                     <td>
                                     <input type="text" 
                                      // value={this.state.pacingPercentage}
                                      value={pacingCampaignDetailsData.pacingPercentage}
                                      key={pacingCampaignDetailsData.count} disabled
                                       name="pacingPercentage" class="form-control" style={{width:'80px'}}
                                    onChange={this.handleChangeLeadPercentage.bind(this,i)}/>
                                    </td>
                             
                                  <td><input type="text" 
                                   value={pacingCampaignDetailsData.pacingLeadCount} 
                                   key={pacingCampaignDetailsData.count}class="form-control" style={{width:'80px'}}
                                    name="pacingLeadCount" 
                                          onChange={this.handleChangeLeadPercentage.bind(this,i)} /></td>
                                    


                                 

                                   <td><input type="date" class="form-control"
                                     // value={this.state.pacingEndDate}
                                      key={pacingCampaignDetailsData.count}
                                        id="pacingEndDate" name="pacingEndDate"  min=""
                                        value={pacingCampaignDetailsData.pacingEndDate} onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                        style={{width:'150px'}}/></td>
                                  <td>{this.state.pacingCampaignDetails.length==i+1?
                                     <select
                                     // value={this.state.pacingCarryForward}
                                      key={pacingCampaignDetailsData.count}
                                     value={pacingCampaignDetailsData.pacingCarryForward}
                                     onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                      id="pacingCarryForward"
                                            className="form-control"
                                            name="pacingCarryForward"
                                            style={{height:'30px',opacity:0.5}}
                                            disabled={true}
                                            >
                                            <option value="Yes" >Yes</option>
                                            <option value="No" selected>No</option>
                                            </select>:
                                             <select
                                             // value={this.state.pacingCarryForward}
                                              key={pacingCampaignDetailsData.count}
                                             value={pacingCampaignDetailsData.pacingCarryForward}
                                             onChange={this.handleChangeLeadPercentage.bind(this,i)}
                                              id="pacingCarryForward"
                                                    className="form-control"
                                                    name="pacingCarryForward"
                                                    style={{height:'30px'}}
                                                    >
                                                    <option value="Yes" >Yes</option>
                                                    <option value="No" selected>No</option>
                                                    </select>
                                            }
                                            </td>
                                            <td>
                                            <Tooltip title="Please enter 0 if not required " placement="right"><Icon type="question-circle"/></Tooltip>
                                    {/* <span class="glyphicon glyphicon-minus-sign" id={i} name={pacingCampaignDetailsData.pacingID} onClick={(e)=>this.removePacingBack(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span> */}
                                    </td>
                                  </tr>
                               
                                  
                                   )})}
  
                                          </tbody>

                                      }
                                        </table>
                                        </div>

              {/* <a data-toggle="tooltip" title="Add one more question"><span class={this.state.drpdisable3+" "+"glyphicon glyphicon-plus-sign"} onClick={(e)=>this.addPacingBack(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize:25}}></span></a> */}

       
        </div>


        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addPacingAllocationBack}
             style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>


                      </MDBCol>


                      <MDBCol lg="3" className="mb-3">
                        <label id="label">Campaign reporting day</label>
                        <select
                          defaultValue={campaignDetail.campaignReportingDay}
                          onChange={this.handleChange}
                          id="campaignReportingDay"
                          className="form-control"
                          name="campaignReportingDay">
                          <option value="Monday">Monday</option>
                          <option value="Tuesday" >Tuesday</option>
                          <option value="Wednesday">Wednesday</option>
                          <option value="Thursday">Thursday</option>
                          <option value="Friday">Friday</option>
                          <option value="Any" selected>Any</option>
                        </select>
                      </MDBCol>

                      
                      
                      <MDBCol lg="3" className="mb-3">
                  {this.state.campaignDetail.map(campaignDetail => {
                    return (
                      <div>
                    <label id="label">Campaign type</label>
                    <select
                      defaultValue={campaignDetail.ABM}
                      onChange={this.handleChange}
                      id="abm"
                      className="form-control"
                      name="ABM">
                      <option value="OPEN" selected="OPEN">OPEN</option>
                      <option value="ABM">ABM</option>
                    </select>
                    </div>
                    )})}
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Lead Interaction Days</label>
                    <input type="textbox" class="form-control" style={{width:'180px',height:'34.5px'}}
                      id="leadInteractionDays" name="leadInteractionDays"
                      value={campaignDetail.leadInteractionDays} onChange={this.handleChange} required />
                  </MDBCol>
                      <MDBCol lg="2" className="mb-2">
                        <label id="label" style={{marginTop:'2%'}}>Creatives approval required</label>
                          
                        <Picky
             value={this.state.creatives}
             options={creatives}
             onChange={this.selectCreativesMultipleOptionBack}
             open={false}
             valueKey="id"
             labelKey="name"
             multiple={true}
             includeSelectAll={true}
             includeFilter={true}
             dropdownHeight={600}
             style={{width:'200px'}}
            />
                          {/* <label id="label" style={{marginTop:'2%'}}>LP approval required</label>
                            <select id="requiredLPApproval"  style={{border:'1px solid #ccc',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)', height:'calc(2.25rem + 12px)'}}name="requiredLPApproval" 
                            defaultValue={campaignDetail.requiredLPApproval}
                            onChange={this.lpApprovalhandleChangeBack} class="form-control">
                            <option value="No" selected>No</option>
                            <option value="Yes" >Yes</option>
                          </select> */}
                            </MDBCol>

                            <MDBCol lg="1" className="mb-1">

                            </MDBCol>

                      <MDBCol lg="3" className="mb-3">
                      <div id="timeline" style={{display:'none'}}>
                                  <label id="label">Creatives approval timeline (Hours)</label>
                              {/* <label id="label" style={{marginTop:'2%'}}>LP approval timeline</label> */}
                              <select id="lpTimeline"  name="lpTimeline"  style={{border:'1px solid #ccc',
                                boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)', height:'calc(2.25rem + 12px)'}} 
                                onChange={this.handleChange} 
                                defaultValue={campaignDetail.lpTimeline} 
                                class="form-control">
                                {/* <option defaultValue={sdBudget.lpTimeline} >{sdBudget.lpTimeline ? sdBudget.lpTimeline:"12"}</option> */} */}
                                <option value="12">12</option>
                                <option value="24" >24</option>
                                <option value="48">48</option>
                                </select>
                                </div>
                             
                                </MDBCol>
                                <MDBCol  lg="3" className="mb-3">
                    <div id="approvedBy" style={{display:'none'}}>
                        <label id="label" style={{marginTop:'2%'}}>Creatives approval required for</label>
                        <select id="creativeApprovalRequiredFor"  style={{width:'180px',height:'34.5px'}}name="creativeApprovalRequiredFor" 
                        defaultValue={campaignDetail.creativeApprovalRequiredFor} onChange={this.handleChange} class="form-control">
                        <option value="Agency" selected>Agency</option>
                        <option value="Advertiser">Advertiser</option>
                        </select>
                        </div>
                  </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                        <label id="label">Marketing channel </label>
                        <select
                          defaultValue={campaignDetail.marketingChannel}
                          onChange={e=>{this.handleChangeMarketingChannelBack(e);this.handleChange(e);}}
                          id="marketingChannel"
                          className="form-control"
                          name="marketingChannel">
                          <option value="TeleMarketing">TeleMarketing</option>
                          <option value="Email" selected>Email</option>
                          <option value="Email/Telemarketing" selected>Email/Telemarketing</option>
                          <option value="Display">Display</option>
                          <option value="Programmatic">Programmatic</option>
                          <option value="Social">Social</option>
                          <option value="Hybrid">Hybrid</option>
                          <option value="Others">Others</option>
                          
                        </select>
                      </MDBCol>
                                <MDBCol  lg="3" className="mb-3">
                    
                    <div id="voiceLog" style={{display:this.state.displayCallauditBack}}>
                        <label id="label" style={{marginTop:'2%'}}>Call Audit</label>
                        <select id="callAudit"  style={{border:'1px solid #ccc',
                        boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)', height:'calc(2.25rem + 12px)',width:'182px'}}name="callAudit" 
                        defaultValue={campaignDetail.callAudit} onChange={this.handleChange} class="form-control">
                        <option value="Yes" >Yes </option>
                        <option value="No" >No </option>
                        </select>
                        </div>
                  </MDBCol>
                  
                               
                      {/* <MDBCol lg="3" className="mb-3">
                        <label id="label">Lead Delivery Option </label>
                        <select
                          defaultValue={campaignDetail.leadDeliveryOption}
                          onChange={this.handleChange}
                          id="leadDeliveryOption"
                          className="form-control"
                          name="leadDeliveryOption">
                          <option value="Excel" selected>Excel</option>
                          <option value="CSV">CSV</option>
                        </select>
                      </MDBCol> */}
                      {/* <MDBCol lg="3" className="mb-3">
                        <label id="label">Upload Lead Delivery Format</label>
                        <input type="file" class="fileupload" id="uploadFormat" name="uploadFormat"
                         // defaultValue={campaignDetail.leadDeliveryFileName}
                           onChange={this.handleUploadLeadDeliveryFormatBack} />
                            {this.state.campaignDetail.map(campaignDetail => {
                      return (
                    <div id="fileName">
                    <i class="fa fa-times"></i>
                    <a href=""id={campaignDetail.leadDeliveryFileName} onClick={this.handleDeleteDeliveryFile.bind(this)} >{campaignDetail.leadDeliveryFileName}</a>
                    
                     </div>)})}
                      </MDBCol> */}
                      {/* style={{width:'500px'}} */}
                      {/*end of second row*/}
                    </MDBRow>
                  )
                })}
                <br />
                {/*start third row*/}
                <legend className="legend labelsubheading">Campaign Specification</legend>

                <MDBRow>
                
                  <MDBCol lg="3" className="mb-3">
                  {/* {this.state.campaignDetail.map((campaignDetail,i) => {
                    return ( */}
                      <div>
                    <label id="label">Region<span style={asterisk}>*</span></label></div>
                    <div>
                    <Picky
           value={this.state.region} 
                options={regionoptions}
                onChange={this.RegionhandleChangeBack}
                className={this.state.inputClassregion}
                open={false}
                valueKey="id"
                labelKey="name"
                multiple={true}
                includeSelectAll={true}
                includeFilter={true}
                dropdownHeight={200}
                numberDisplayed={1}
                id="region" name="region"
              /> 


                    {/* <Select value={this.state.region}  onChange={this.RegionhandleChangeBack} id="region" 
 className={this.state.inputClassregion}  isMulti  name="region" options={regionoptions} class="basicmulti-select" classNamePrefix="select" /> */}
                    {/* <select
                      //defaultValue={campaignDetail.region}
                      value={this.state.region}
                      onChange={this.RegionhandleChangeBack}
                      id="region" 
                      className="form-control"
                      name="region">
                      <option value="Select Region" selected disabled>Select Region</option>
                
                    </select> */}
                    </div>
                    {/* )})} */}
                  </MDBCol>


                  <MDBCol lg="3" className="mb-3">
                
                    <label id="label">Country<span style={asterisk}>*</span></label>
                    <br />

                    <Picky
           value={this.state.country} 
                options={this.state.countryoptions}
               // countryHandleChangeBack
                onChange={this.countryHandleChangeBack}
                
                className={this.state.inputClassDropDownCountry1}
                open={false}
                valueKey="id"
                labelKey="name"
                multiple={true}
                includeSelectAll={true}
                numberDisplayed={1}
                includeFilter={true}
                dropdownHeight={300}
                id="country" name="country"
              /> 
              </MDBCol>


              <MDBCol lg="3" className="mb-3">
                      <label id="label">State:&nbsp;</label>
                      <Tooltip placement="right" title="State Validation">
                    <input type="checkbox" id="stateChk" name="stateChk" 
                      checked={this.state.checked2} onChange={this.statehandleChangeBack}/></Tooltip>
                    {/* </MDBCol> */}
                    {/* <MDBCol lg="2" className="mb-2"> */}
                      <div id="statefile" style={{display:'none'}}>
                      <input type="file"   defaultValue={this.state.stateFileBack} style={{color:'transparent'}}onChange={this.stateFilehandleChangeBack} name="stateFile" id="stateFile" ref={ref=> this.fileInputstateback = ref}/>
                      {/* </div> */}
                      {/* className="removeFileBack" */}

                     {this.state.stateFileBack.length>0?
                      <div id="statefilesName"><a href="#" >
              <i  class="fa fa-times " onClick={this.dynamicStateFile}  id="statefile"  name="statefile"  />
              </a>{this.state.stateFileBack}
                                  <br/><br/>
                  </div>
                :""}
                      <div id={"stateList"} className="word-wrap">
                      
                      
                      
                      </div>
                      <div style={{color:'red'}}>{this.state.statefileerrors.fileextensions}
                      </div>
                      </div>
                     </MDBCol>
                 
              <MDBCol lg="3" className="mb-3">
                      <label id="label">City:&nbsp;</label>
                      <Tooltip placement="right" title="City Validation">
                    <input type="checkbox" id="cityChk" name="cityChk" 
                      checked={this.state.checked1} onChange={this.cityhandleChangeBack}/></Tooltip>
                    {/* </MDBCol> */}
                    
                    {/* <MDBCol lg="2" className="mb-2"> */}
                      <div id="cityfile" style={{display:'none'}}>
                      <input type="file"   defaultValue={this.state.cityFileBack} style={{color:'transparent'}}onChange={this.cityFilehandleChangeBack} name="cityFile" id="cityFile"/>
                      {/* </div> */}
                     
                      {this.state.cityFileBack.length>0?
                       <div id="cityfilesName">
                       
                         <a href="#" >
              <i  class="fa fa-times" onClick={this.dynamicCityFile}  id="cityfile"  name="cityfile" ref={ref=> this.fileInputcityback= ref}/>
              </a>{this.state.cityFileBack}
                                  <br/><br/>
                  </div> 
                    :""}
                      <div id={"cityList"} className="word-wrap">
                      
                      
                      
                      </div>
                      <div style={{color:'red'}}>{this.state.cityfileerrors.fileextensions}
                      </div>
                      </div>
                     
                     </MDBCol>

                  
                  
                
                
                     
                </MDBRow>
                <MDBRow>
                 
                
{/*                   
{this.state.campaignDetail.map(campaignDetail => {
                    return ( */}


<MDBCol lg="3" className="mb-3">
                      <label id="label">Zip code:&nbsp;</label>
                      <Tooltip placement="right" title="Zip Code Validation">
                    <input type="checkbox" id="zipcodeChk" name="zipcodeChk"  
                      checked={this.state.checked}  onChange={this.zipcodehandleChangeBack}/></Tooltip>
                    {/* </MDBCol> */}
                 
                    {/* <MDBCol lg="2"
                     className="mb-2"> */}
                      <div id="zipcodefile" style={{display:'none'}}>
                      <input type="file"   defaultValue={this.state.zipcodeFileBack} style={{color:'transparent'}}onChange={this.zipcodeFilehandleChangeBack} name="zipcodeFile" id="zipcodeFile"/>
                      {/* </div> */}
                      {this.state.zipcodeFileBack.length>0?
                      <div id="fileName"><a href="#" >
                  <i  class="fa fa-times" onClick={this.dynamicZipFile}  id="zipcodefile"  name="zipcodefile" />
                   </a>{this.state.zipcodeFileBack}
                                  <br/><br/>
                  </div>:""}
                      <div id={"zipcodeList"} className="word-wrap">
                      
                      
                      
                      </div>
                      <div style={{color:'red'}}>{this.state.zipfileerrors.fileextensions}
                      </div>
                      </div>
                     </MDBCol>
                  <MDBCol lg="3" className="mb-3">
                  {/* <div>
                    <label id="label">Industry <span style={asterisk}>*</span></label></div>
                    <div>
                    <input type="textbox" id="industry" name="industry"
                    value={this.state.industry}
                      defaultValue={campaignDetail.industry} className={this.state.inputClass1}
                       onChange={this.handleChange} class="form-control"
                    />
                    </div> */}
                    
                    <label id="label">Industry <span style={asterisk}>*</span>&nbsp;&nbsp;  </label>
                    <Tooltip title="Not Applicable" placement="left"><input type="checkbox" id="NA3" name="NA3" style={{float:'right'}} value={this.state.NA} onChange={this.handleIndustrybackNA} /></Tooltip>
                    <span id="span4" class={this.state.drpdisabled}>
                    <Tooltip title="Add Custom Industry"><Icon type="plus-circle" style={{color:'#2196f3'}} data-toggle="modal" data-target="#customIndustryBack"/></Tooltip>
                    
                 <br />
                 <Picky
              value={this.state.industry}
              options={industryOptions}
              onChange={this.industryhandleChangeBack}
              // className={this.state.inputClassDropDownIndystry}
              className={this.state.inputClassDropDownIndystry+ ' ' + this.state.drpdisabled} 

              open={false}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={300}
              numberDisplayed={1}
              id="industry" name="industry"
            />
            </span>
                  </MDBCol>
                   {/* )})} */}

                    {/* <!-- Modal --> */}
 <div class="modal fade" id="customIndustryBack" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
    
      {/* <!-- Modal content--> */}
      <div class="modal-content">
        <div class="modal-header custom-classPopup">
        
          <h4 class="modal-title"style={{color:'#144999'}}><font color='white' >Custom Industry</font></h4>
          <button type="button" className={this.state.buttonDisplayIndustryBack} class="cancel-button" style={{fontSize:15,color:'#ffffff',float:'right'}} data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
          {this.state.errors.industryundefined}
        </div>

        <div class="successMessage" style={{ fontSize: '14px'}}>
            {this.state.errors.industrysuccess}
        </div>
          <p>
         
      <div class="row">
      <div class="col-sm-12"> 
      
      <textarea id="customIndustry" name="customIndustry"  className={this.state.inputClassDropDownIndystry}
      defaultValue={this.state.customIndustryBack} onChange={this.handleChangeIndustry} class="form-control" />
    
      </div>
      </div>
      
      </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addIndustryBack}
           style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>

  {/* End of Industry Modal */}
       

                  {/* <MDBCol lg="3" className="mb-3">
                    <label id="label">Upload Industry</label>
                    <input type="file" id="industryFile" name="industryFile"
                      value={this.state.industryFile} 
                      onChange={this.handleUploadIndustryBack}
                      style={{ width: '250px' }} class="fileupload" /> */}
                    {/* {this.state.campaignDetail.map(campaignDetail => {
                      return (
                    <div id="noassetList">
                    {/* <i class="fa fa-times"></i> */}
                    {/* <a href="" id={campaignDetail.industryFileName} onClick={this.handleDeleteFile.bind(this)} >{campaignDetail.industryFileName}</a>
                                         */}
                     {/* </div>)})} } */}
                    {/* <div id="assetList" className="word-wrap">
                    </div>
                    <div id="assetMessage" style={{ color: 'green' }}></div> */}
                  {/* </MDBCol> */}
                  <MDBCol lg="3" className="mb-3">
                 
                    <label id="label">Employee size<span style={asterisk}>*</span> &nbsp;&nbsp;</label> 
                    
                    <Tooltip title="Not Applicable" placement="left"><input type="checkbox" id="NA4" name="NA4"  style={{float:'right'}}value={this.state.NA} onChange={this.handleEmpsizebackNA}/></Tooltip> 
                    <span id="span3" class={this.state.drpdisabledemp}>
                    <Tooltip title="Add Custom Employee Size Range"><Icon type="plus-circle"style={{color:'#2196f3'}} data-toggle="modal" data-target="#customEmpSize"/></Tooltip> 
                    
                    <Picky
              value={this.state.employeeSize}
              options={employeeSizeOption}
              onChange={this.employeehandleChangeBack}
              // className={this.state.inputClassDropDownEmployeeSize}
              className={this.state.inputClassDropDownEmployeeSize+ ' ' + this.state.drpdisabledemp} 

              open={false}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={300}
              id="employeeSize" name="employeeSize"
            />
            </span>
      
                                {/* <Select
                                value={this.state.employeeSize} isMulti
                                onChange={this.employeehandleChangeBack} 
                                options={employeeSizeOption} className={this.state.inputClassDropDownEmployeeSize} class="basic-multi-select" classNamePrefix="select" required />
                  */}
                 
                 
                 
                   </MDBCol>
                   <MDBCol lg="3" className="mb-3">
                 <label id="label">Company revenue&nbsp;&nbsp;   <Tooltip title="Add Custom Revenue Size Range"><Icon type="plus-circle" data-toggle="modal" data-target="#customRevenueSize"/></Tooltip> </label>

                               <Picky
              value={this.state.companyRevenue}
              options={companyRevenueOption}
              onChange={this.revenuehandleChangeBack}
              className={this.state.inputClassDropDowncompanyRevenue}
              open={false}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={300}
              id="companyRevenue" name="companyRevenue" ></Picky>
                 
                  </MDBCol>

                      {/* <!-- Modal --> */}
  <div class="modal fade" id="customEmpSize" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
    
      {/* <!-- Modal content--> */}
      <div class="modal-content">
        <div class="modal-header custom-classPopup">
        
          <h4 class="modal-title"style={{color:'#144999'}}><font color='white'>Custom Employee Size</font></h4>
          <button type="button" style={{float:'right'}} className={this.state.buttonDisplayEmpBack} class="cancel-button" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
                    
                    {this.state.errors.empRegex}  <br/>
                    {this.state.errors.empUndefined} 
                    {this.state.errors.empmatched} 
                    {this.state.errors.emprange}
        </div>
        
        <p>
        <div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
          {this.state.reerrors.fromToMsg} 
          {this.state.reerrors.onlyNumMsg} 
          {this.state.reerrors.greaterMsg} 
          {/* {this.state.reerrors.onlyTextMsg}  */}
          {this.state.reerrors.commaNotallowed} 
         
         </div>
         <div className="errorMsg" style={{ fontSize: '14px', color: 'green' }}>
         
          {this.state.reerrors.success}  
         </div>
        <label>Employee Size Range </label>
        <div class="container-fluid">
        <div class="row">
         
        {this.state.customEmployeeSizePopupBackFromTo.map((customEmployeeSizePopupBackFromTo,i)=>{return(
                              <div key={customEmployeeSizePopupBackFromTo.count}>
                              {/* <p> Custom Question: {i+1}</p> */}
                              &nbsp;&nbsp;
  <div class="col-sm-12 col-md-5 col-lg-5 col-xs-12">
   <Input  placeholder="From" id="From1" name="From" defaultValue={this.state.customEmployeeSizePopupBackFromTo[i].From}
     onChange={this.customEmployeeSizeFromPopupHandleChangeBack.bind(this,i)}/>
   </div>
   <div class="col-sm-12 col-md-5 col-lg-5 col-xs-12">
  <Input  placeholder="To" id="To1" name="To" defaultValue={this.state.customEmployeeSizePopupBackFromTo[i].To}
  onChange={this.customEmployeeSizeFromPopupHandleChangeBack.bind(this,i)}/>
  </div>
   <label ></label>
   <div class="col-sm-12 col-md-2 col-lg-2 col-xs-12">
  <a><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDynamicEmployeeSizeBack(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span></a>
     </div>                         
                              </div>
                             
                        )})}
</div>
<br/>
<div class="row">

   <div class="col-sm-4 col-md-4 col-lg-4 col-xs-4">                      
 <a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDynamicEmployeeSizeBack(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize: 14}}></span></a>
</div>
 {/*from to close*/} 
</div>
<br/>
<div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
        
          {this.state.reerrors.onlyTextMsg} 
          
         
         </div>
         <div className="errorMsg" style={{ fontSize: '14px', color: 'green' }}>
         
          {this.state.reerrors.successText}  
         </div>
<br/>
<label>Employee Size Value </label> (&nbsp;&nbsp;<span>E.g.1000 &lt;,1000+,&gt;2500</span>)
<div class="row">

 {this.state.customEmployeeSizePopupBackText.map((customEmployeeSizePopupBackText,i)=>{return(
                              <div key={customEmployeeSizePopupBackText.count}>
                             
                              &nbsp;&nbsp;
                              <div class="col-sm-12 col-md-12 col-lg-10 col-xs-10"> <Input   id="empSize1" name="customEmployeeSizeText" defaultValue={this.state.customEmployeeSizePopupBackText[i].customEmployeeSizeText}
                              onChange={this.customEmployeeSizePopupHandleChangeBack.bind(this,i)}/></div>
  
                              <div class="col-sm-12 col-xs-12 col-md-2 col-lg-2">
                              <label ></label>
                              <a><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDynamicEmployeeSizeBackText(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span></a>
                              </div>
                              <br/>
                              </div>
                        )})}
</div>
<br/>
<div class="row">
<div class="col-sm-2 col-md-2 col-lg-2 col-xs-2">                      
 <a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDynamicEmployeeSizeBackText(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize: 14}}></span></a>
</div>
</div>
</div>

</p>
                  
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addEmpSizeBack}
            style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>

  {/* End of Emp Size Modal */}


       {/* <!-- Modal --> */}
       <div class="modal fade" id="customRevenueSize" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md">
    
      {/* <!-- Modal content--> */}
      <div class="modal-content">
        <div class="modal-header custom-classPopup">
        
          <h4 class="modal-title"style={{color:'#144999'}}><font color='white'>Custom Revenue Size</font></h4>
          <button type="button" style={{float:'right'}} className={this.state.buttonDisplayRevenueBck} class="cancel-button" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
          {this.state.reerrors.fromToMsgrev} 
          {this.state.reerrors.onlyNumMsgrev} 
          {this.state.reerrors.greaterMsgrev} 
          {this.state.reerrors.onlyTextMsgrev}  
         
         </div>
         <div className="errorMsg" style={{ fontSize: '14px', color: 'green' }}>
         
          {this.state.reerrors.successrev}  
         </div>
        
        <div class="container-fluid">
<label>Custom Revenue Size Range</label>
          <div class="row">
{this.state.customRevenuePopupBackFromTo.map((customRevenuePopupBackFromTo,i)=>{return(
                              <div key={customRevenuePopupBackFromTo.count}>
                              {/* <p> Custom Question: {i+1}</p> */}
                              &nbsp;&nbsp;
                              <div class="col-sm-10 col-sm-12 col-md-5 col-lg-5"> 
<Input  placeholder="From" id="From1" name="From" defaultValue={this.state.customRevenuePopupBackFromTo[i].From}
 onChange={this.customRevenueFromPopupHandleChangeBack.bind(this,i)}/>
                              </div>
  <div class="col-sm-12 col-sm-12 col-md-5 col-lg-5">
    <Input  placeholder="To" id="To1" name="To" defaultValue={this.state.customRevenuePopupBackFromTo[i].To}
    onChange={this.customRevenueFromPopupHandleChangeBack.bind(this,i)}/></div>
    <div class="col-sm-2 col-sm-2 col-md-2 col-lg-2">
  <a ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDyanamicCompanyRevenueBack(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span></a>
                              </div>
                              </div>
                        )})}

   </div>
   <br/>
   <div class="row">    
   <div class="col-sm-2 col-sm-2 col-md-2 col-lg-2">                 
 <a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDyanamicCompanyRevenueBack(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize:14}}></span></a>
</div>
</div>
<br/>

<label>Custom Revenue Size Value</label> (&nbsp;&nbsp;<span>E.g.$1000M</span>)
 <div class="row">

{this.state.customRevenuePopupBackText.map((customRevenuePopupBackText,i)=>{return(
                             <div key={customRevenuePopupBackText.count}>
                            
                             &nbsp;&nbsp;
                             <div class="col-sm-10 col-sm-10 col-md-10 col-lg-10"> <Input  id="customCompanyRevenue" name="customCompanyRevenueText"  defaultValue={this.state.customRevenuePopupBackText[i].customCompanyRevenueText}
                              onChange={this.customRevenueTextPopupHandleChangeBack.bind(this,i)}
                             //onChange={this.customRevenueBackTextPopupHandleChangeBack.bind(this,i)}
                             />
                             </div>
 
                             <div class="col-sm-2 col-sm-2 col-md-2 col-lg-2">
                            
                             <a><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDyanamicCompanyRevenueBackText(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span></a>
                             </div>
                             </div>
                       )})}
</div>
<br/>
 <div class="row">
<div class="col-sm-4 col-sm-4 col-md-4 col-lg-4">                      
<a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDyanamicCompanyRevenueBackText(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize: 14}}></span></a>
</div>
</div>
</div>
   
 

  

      
      
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addRevenueBackSize}
           style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>

  {/* End of Revenue Size Modal */}

  
       {/* <!-- Modal --> */}
       <div class="modal fade" id="customJobFunction" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm">
    
      {/* <!-- Modal content--> */}
      <div class="modal-content">
        <div class="modal-header custom-classPopup">
        
          <h4 class="modal-title" style={{color:'#144999',paddingRight:'50px'}}><font color='white' style={{paddingRight:'35px'}}>Custom Job Function </font></h4>
          <button type="button" className={this.state.buttonDisplayJobfnBack} class="cancel-button" style={{fontSize:15,color:'#ffffff',float:'right'}} data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <p>

<div class="container-fluid">

<div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
                    
                    {this.state.errors.jobFunctionPattern}  
                    {this.state.errors.jobfunctionundefined}  
                    {this.state.errors.jobFunctionNofields}
                  </div>
          
                  <div className="errorMsg" style={{ fontSize: '14px', color: 'green' }}>
                              
                              {this.state.errors.success}  
                              
                            </div>
<div class="row">
{this.state.customJobFunctionPopupBack.map((customJobFunctionPopupBack,i)=>{return(
<div key={customJobFunctionPopupBack.count}>
{/* <p> Custom Question: {i+1}</p> */}

<div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 ">

<Input placeholder="Job Function" id="jobFunction1" name="jobFunction" defaultValue={this.state.customJobFunctionPopupBack[i].jobFunction}
 onChange={this.customJobFunctionPopupHandleChangeBack.bind(this,i)}/></div>

<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
<a  ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDynamicJobFunctionBack(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14,paddingTop:"20px"}}></span></a>
</div>

</div>
)})}
</div>
<br/>
<div class="row">
<div class="col-sm-8">                      
<a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDynamicJobFunctionBack(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize:14}}></span></a>
</div>
</div>


</div> 
</p>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addJobFunctionBack}
            style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>

  {/* End of Job Function Modal */}



     {/* <!-- Modal --> */}
     <div class="modal fade" id="customJobLevel" role="dialog"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm">
    
      {/* <!-- Modal content--> */}
      <div class="modal-content">
        <div class="modal-header custom-classPopup">
        
          <h4 class="modal-title" style={{color:'#144999'}}><font color='white'>Custom Job Level </font></h4>
          <button type="button" className={this.state.buttonDisplayJobLevelBack} class="cancel-button"  style={{fontSize:15,color:'#ffffff',float:'right'}} data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <p>

<div class="container-fluid">

<div className="errorMsg" style={{ fontSize: '14px', color: 'red' }}>
                    
          {this.state.errors.joblevelPattern}  
          {this.state.errors.joblevelundefined} 
          {this.state.errors.NoFields} 
                  </div>
          
                  <div className="errorMsg" style={{ fontSize: '14px', color: 'green' }}>
                              
                              {this.state.errors.success1}  
                              
                            </div>
<div class="row">
{this.state.customJobLevelPopupBack.map((customJobLevelPopupBack,i)=>{return(
<div key={customJobLevelPopupBack.count}>
{/* <p> Custom Question: {i+1}</p> */}

<div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 ">

<Input placeholder="Job Level" id="jobLevel1" name="jobLevel" defaultValue={this.state.customJobLevelPopupBack[i].jobLevel}
 onChange={this.customJobLevelPopupHandleChangeBack.bind(this,i)}/></div>

<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
<a  ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={(e)=>this.removeDynamicJobLevelBack(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14,paddingTop:"20px"}}></span></a>
</div>

</div>
)})}
</div>
<br/>
<div class="row">
<div class="col-sm-8">                      
<a data-toggle="tooltip" title="Add one more"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addDynamicJobLevelBack(e)} style={{color:"#2196f3",fontWeight: 'bold',fontSize:14}}></span></a>
</div>
</div>


</div> 
</p>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" onClick={this.addJobLevelBack}
          
          style={{backgroundColor:'#144999',color:"white" ,borderRadius: "3px"}}
          >Add</button>
        </div>
      </div>
      
    </div>
  </div>

  {/* End of Job level Modal */}

              </MDBRow>
              
              
              {this.state.campaignDetail.map(campaignDetail => {
            
                    return (
              <MDBRow>
                

                <MDBCol lg="3" className="mb-3">

                  <label id="label">Job level <Tooltip title="Add Custom Job Level"><Icon type="plus-circle" data-toggle="modal" data-target="#customJobLevel"/></Tooltip></label>
                  <br />
                  {/* value={this.state.arrayValue} */}

                  <Picky
                  value={this.state.jobLevel}
                  options={bigList}
                  onChange={this.selectMultipleOptionBack}
                  className={this.state.inputClassDropDownJobLevel1}
                  open={false}
                  valueKey="id"
                  labelKey="name"
                  multiple={true}
                  includeSelectAll={true}
                  includeFilter={true}
                  dropdownHeight={160}
                  id="jobLevel" name="jobLevel"
                  />

                  {/* <Select name="jobLevel" id="jobLevel"
                  //defaultValue={campaignDetail.jobLevel}
                  value={this.state.jobLevel}  isMulti 
                  onChange={this.jobLevelhandleChangeBack} 
                  className={this.state.inputClassDropDownJobLevel1}
                  options={jobLevelOptions} class="basic-multi-select" classNamePrefix="select" /> */}
                 


                  </MDBCol>
                <MDBCol lg="3" className="mb-3">
                 
                     
                 <label id="label">Job function &nbsp;&nbsp;   <Tooltip title="Add Custom Job Function"><Icon type="plus-circle" data-toggle="modal" data-target="#customJobFunction"/></Tooltip> </label>
                                  <br />
                                  <Picky
                               value={this.state.jobFunction}
                               options={jobFunctionOptions}
                               onChange={this.jobFunctionhandleChange}
                               className={this.state.inputClassDropDownJobFunction1}
                               open={false}
                               valueKey="id"
                               labelKey="name"
                               multiple={true}
                               includeSelectAll={true}
                               includeFilter={true}
                               dropdownHeight={300}
                               id="jobFunction" name="jobFunction"
                             />
                 
                                     {/* <Select value={this.state.jobFunction}   isMulti onChange={this.jobFunctionhandleChange} 
                                     options={jobFunctionOptions} className={this.state.inputClassDropDownJobFunction1}
                                      class="basic-multi-select" classNamePrefix="select" required />
                                     */}
                                   
                                   </MDBCol>
               
                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Job title <span style={asterisk}>*</span></label>&nbsp;
                    <Tooltip placement="right" title="Job Title Validation">
                     <input type="checkbox" id="jobTitleValidation" name="jobTitleValidation" 
                      checked={this.state.checked3} 
                      onChange={this.handleLeadvalidationback}/></Tooltip> 
                    <textarea id="jobTitle" name="jobTitle"
                     defaultValue={campaignDetail.jobTitle} value={this.state.jobTitle} onChange={this.handleChange} class="form-control" required />
                  </MDBCol>
                      {/* Industry field added by Sonali */}
                  {/* <MDBCol lg="3" className="mb-3">
                    <label id="label">Industry</label>
                    <textarea id="customIndustry" name="customIndustry"  className={this.state.inputClassDropDownIndystry}
                      defaultValue={campaignDetail.customIndustry} value={this.state.customIndustry}  onChange={this.handleChange} class="form-control" />
                  </MDBCol> */}


                  <MDBCol lg="3" className="mb-3">
                    <label id="label">Other specs</label>
                    <textarea id="otherSpecs" name="otherSpecs"
                      defaultValue={campaignDetail.otherSpecs} value={this.state.otherSpecs} onChange={this.handleChange} class="form-control" />
                  </MDBCol>

                 
               
                  {/* <MDBCol lg="3" className="mb-3">
                    <label id="label">Custom Employee Size</label>
                    <textarea id="customEmpSize" name="customEmpSize"  className={this.state.inputClassDropDowncompanyEmpSize1}
                      defaultValue={campaignDetail.customEmpSize}  onChange={this.customHandleChange} class="form-control"  required/>
                  </MDBCol> */}
                </MDBRow>
                )})}
           {this.state.campaignDetail.map(campaignDetail => {
                    return (
                 <MDBRow>
                {/* <MDBCol lg="3" className="mb-3">
                    <label id="label">Custom Company Revenue</label>
                    <textarea id="customCompRevenue" name="customCompRevenue" className={this.state.inputClassDropDowncompanyRevenue1}
                      defaultValue={campaignDetail.customCompRevenue}  onChange={this.customHandleChange} class="form-control" />
                  </MDBCol> */}
                </MDBRow>






               
               )})}
<br/>
<legend className="legend labelsubheading">Custom Questions</legend>

<button type="button" class="btn add-button" data-toggle="modal" data-target="#customQuestion">Add Custom Question</button>

{/* <!-- Modal --> */}
<div class="modal fade" id="customQuestion" role="dialog" data-backdrop="static" data-keyboard="false">
<div class="modal-dialog  modal-xl">

{/* <!-- Modal content--> */}
<div class="modal-content">
 <div class="modal-header custom-classPopup">
 
   <h4 class="modal-title"style={{color:'#144999',paddingRight:'780px'}}><font color='white' style={{paddingRight:'35px'}}>Custom Questions</font></h4>
   <button type="button" class="cancel-button" data-dismiss="modal">&times;</button>
   {/* <button type="cancel-button" class="cancel-button" data-dismiss="modal">&times;</button> */}
 </div>
 <div>
   <label style={{fontSize:'16px',color:'#2196F3'}}>&nbsp;&nbsp;&nbsp;Instructions</label>
   <ol>
     <li style={{fontSize:'14px'}}>Every answer should be on new Line in text box</li>
     <li style={{fontSize:'14px'}}>Please fill the answer in the respective block</li>
     </ol>
     <hr  />
     <div class="errorMessage">{this.state.errors.validateBack}</div>
     </div>
 <div class="modal-body">
   <p>
 
{/* end of 1st row */}
  

  <div class="row">
  {/* <div class="col-sm-4 col-md-4 col-md-12 col-lg-12"> */}
  {this.state.questionDetail.map((questionDetail,i)=>{
    return(
    
                              <div key={questionDetail.customQuestionID}>
                              {/* <p> Custom Question: {i+1}</p> */}
                              &nbsp;&nbsp;
                              
                              <div class="col-lg-3">
                              <label>Questions</label>
                              <textarea  id="customQuestions" name="customQuestion"  defaultValue={questionDetail.customQuestion} onChange={this.handleCustomQuestionBack.bind(this,i)} style={{width:'250px'}}/>&nbsp;&nbsp;
                             </div>

                             <div class="col-lg-3">
                             <label >Answers</label>
                              <textarea  id="customAnswers" name="answer"  defaultValue={questionDetail.answer} onChange={this.handleCustomQuestionBack.bind(this,i)} style={{width:'250px'}}/>&nbsp;&nbsp;
                             </div>

                             <div class="col-lg-3">
                             <label >Not Allowed Answers</label>
                              <textarea  id="avoidAnswers" name="disAllowAnswer"   defaultValue={questionDetail.disAllowAnswer} onChange={this.handleCustomQuestionBack.bind(this,i)} style={{width:'250px'}}/>
                              &nbsp;&nbsp;
                              </div>
                              
                            {/* <span style={{marginBottom:"54px", display:"inline-block"}}> <a href="#"><i class="fa fa-times fa-lg"  id={i} onClick={()=>this.removeCustomQuestion(i)} ></i></a></span> */}
                              {/* <button type="button" style={{marginBottom:"54px"}} id={i} name={questionDetail.customQuestionID} onClick={(e)=>this.removeCustomQuestionBack(e,i)} class="btn btn-primary">Remove</button> */}
                              <div class="col-lg-3">
                              <a  ><span class="glyphicon glyphicon-minus-sign" name={questionDetail.customQuestionID} id={i} onClick={(e)=>this.removeCustomQuestionBack(e,i)}  style={{color:"Red",fontWeight: 'bold',fontSize: 22}}></span></a>
                              </div>
                              </div>
                        )})}


    {/* </div> */}
 
</div>
<div class="row">
  <div class="col-sm-4 col-md-4 col-md-12 col-lg-12">
  {/* <button type="button" onClick={(e)=>this.addCustomQuestionBack(e)} class="btn btn-primary">Add Custom Question</button> */}

  <a data-toggle="tooltip" title="Add one more question"><span class="glyphicon glyphicon-plus-sign" onClick={(e)=>this.addCustomQuestionBack(e)}
   style={{color:"#2196f3",fontWeight: 'bold',fontSize: 30}}></span></a>
  </div>
 
</div>
  
   {/* <Row gutter={24}>{this.getFieldsBack()}</Row>
 <Row>
   <Col span={24} style={{ textAlign: 'right' }}>
     
{this.state.expand ? 
<Button style={{ marginLeft: 8, fontSize: 12 }} onClick={this.toggle}>
Less <Icon type= 'up'></Icon>
</Button>
:  <Button style={{ marginLeft: 8, fontSize: 12 }} onClick={this.toggle}>
More <Icon type= 'down'></Icon>
</Button>
}



    
   </Col>
 </Row> */}
</p>
 </div>
 <div class="modal-footer">
   <button type="button" class="btn add-button" 
   onClick={this.validatecustomQuestionBack}
   >Add</button>
 </div>
</div>

</div>
</div>

{/* End of Revenue Size Modal */}
           



                  {/* /////////// */}

              </div> {/* end of cardbody */}
            {/* </div> */}
            {/* end of card sign in */}
          </div>{/* end of container */}


        </form>


        <Footer />

      </div>


    );

  }


}


}
 /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      CreateCampaign.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })

export default connect(mapStateToProps, { logoutUser })(withRouter(CreateCampaign));
