/*/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Snehal More
 *@fileName :
 *Desc: Publisher GDPR Edit Certificate Tab
 */

import React from "react";
import * as $ from "jquery";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import "../agency/loader1.css"; //Sandeep-task-2943- code to import loader with Global compliance bcz of loading issue
import "antd/dist/antd.css";
import {
  Form,
  Select,
  Tooltip,
  Radio,
  Button,
  Icon,
  Checkbox,
  Row,
  Col,
} from "antd";
import { read_cookie } from "sfcookies";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Priyanka--3944--added default header

const cookie_key = "publisherID";
const Swal = require("sweetalert2");
const { Option } = Select;
var assetLength;

var gdrpResultArray = [];
var caslResultArray = [];
var caslDynamicArray = [];
var gdprnames = [];
var gdprnames1 = [];
var gdprDynamicArray = [];
var gdprnames3 = [];
var gdprnames6 = []; 
var publisherInfo = [];
var key = 0;
var inputType = [];

class PublisherGDPRCertificateEdit extends React.Component {
  state = { newsuccess: "" };
  constructor() {
    super();
    this.state = {
      selected: "gdpr-2",
      selected1: "casl-2",
      checked: true,
      checked1: false,  
      checked2: false,
      checked3: false,
      displayChk1: "",
      displayChk2: "",
      buttonDisplay: "disabled",
      gdprquestionList: [],
      publisherInfo: [],
      caslquestionList: [],
      caslquestionListForEdit: [],
      complianceNameList: [],
      varGDPR: "",
      varCASL: "",
      assetsFile: [],
      asseterrors: {},
      gdpr1: {},
      PID: "",
      gdprDynamicArray: [],
      gdrpResultArray: [],
      caslDynamicArray: [],
      caslResultArray: [], 
    };
    this.handleChangeGdprRadio = this.handleChangeGdprRadio.bind(this);
    this.handleChangeGdpr = this.handleChangeGdpr.bind(this);
    this.handleChangeCasl = this.handleChangeCasl.bind(this);
    
  }

  /*@author Sonali Kalke
   *@fileName :
   *Desc: Get compliance names from backend to display on front-end
   */
   componentDidMount() { 
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth;
      var pID = user.id;
      // let data={ pID:pID};      Priyanka--3944--removed params
      this.setState({ pID: pID });
      let data = { compilanceValue: "GDPR" }; //kiran bug 4132-sent GDPR value
      let data1 = { compilanceValue: "GDPR" };
      fetch("/publisherOnBoardDetails/getGdprQuestionsForEdit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data1),
      })
        .then((res) => res.json())
        .then((gdprquestionPubList) => {
          let gdprquestionList = gdprquestionPubList.gdprQuestion;
          let publisherInfo = gdprquestionPubList.publisherInfo;
          this.setState({ gdprquestionList, publisherInfo });
        });
      var pID = read_cookie(cookie_key);
      if (pID == "") {
        this.setState({
          buttonDisplay: "disabled",
          displayChk1: "disabled",
          displayChk2: "disabled",
          infoMsg: "Please Fill Company Information First",
        });
      } else {
        this.setState({
          displayChk1: "",
          displayChk2: "",
          buttonDisplay: "button",
          infoMsg: "",
        });
      }

      /* let data={   //Priyanka--3944--removed data
        orgID:pID,
        role:'PC'
       }*/

      fetch("/publisherOnBoardDetails/getcomplianceNameForEdit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((complianceNameList) => {
          this.setState({ complianceNameList: complianceNameList });
          /*@author Sanobar Golandaj
           *@fileName :
           *Desc: displaying compliance name on frontend
           */
          var gdpr = complianceNameList[0].complianceName;
          this.setState({
            varGDPR: gdpr,
          });

          var casl = complianceNameList[1].complianceName;
          if (casl === "CASL") {
            let data = {
              compilanceValue: `CASL`,
            };
            this.setState({ checked1: false }); //kiran- bug 4239-bydefault casl in unchecked
            fetch("/publisherOnBoardDetails/getCaslQuestionsForEdit", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            })
              .then((res) => res.json())
              .then((caslquestionListForEdit) => {
                if (caslquestionListForEdit.length > 0) {
                  this.setState({ caslquestionList: caslquestionListForEdit });
                } else {
                  var caslquestionListForEdit = [
                    {
                      qID: "12",
                      questions: "CASL question 1 here",
                      complianceName: "CASL",
                      complianceID: 2,
                      answer: "No",
                    },
                    {
                      qID: "13",
                      questions: "CASL question 2 here",
                      complianceName: "CASL",
                      complianceID: 2,
                      answer: "No",
                    },
                  ];
                  this.setState({ caslquestionList: caslquestionListForEdit });
                }
              })
              .catch((e) => console.log(e));
          }
          this.setState({
            varCASL: casl,
          });
        });
    }
  }

  /*@author Sonali Kalke
   *@fileName :
   *Desc: Fetch values of radio buttons.
   */

  /*@author Sanobar Golandaj
   *@fileName :
   *Desc: handle change for gdpr radio button.
   */
  handleChangeGdprRadio(i, e) {
    const { name, value } = e.target;
    let publisherInfo = [...this.state.publisherInfo];
    publisherInfo[i] = { ...publisherInfo[i], answer: value };
    this.setState({ selected: e.target.value, publisherInfo });
  }

  /*@author Sanobar Golandaj
   *@fileName :
   *Desc: handle change for casl radio button default selected NO.
   */
  handleChangeCaslRadio(i, e) {
    const { name, value } = e.target;
    let caslquestionList = [...this.state.caslquestionList];
    caslquestionList[i] = { ...caslquestionList[i], answer: value };
    this.setState({ selected: e.target.value, caslquestionList });
  }

  /*@author Sonali Kalke
   *@fileName :
   *Desc:Fetch GDPR questions from backend.
   */

  /*@author Sanobar Golandaj
   *@fileName :
   *Desc:handle change for gdpr.
   */

  handleChangeGdpr = (e) => {
    this.setState({
      checked: !this.state.checked,
    });
    
    this.props.form.validateFieldsAndScroll((error, values) => {
      if (!error) {
        let data = {
          compilanceValue: e.target.value,
        };
        if (e.target.checked === true && e.target.value === "GDPR") {
          fetch("/publisherOnBoardDetails/getGdprQuestionsForEdit", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((gdprquestionPubList) => {
              //kiran- bug 4239- while gdpr checked / uncheked added publisher info
              let gdprquestionList = gdprquestionPubList.gdprQuestion; 
              let publisherInfo = gdprquestionPubList.publisherInfo; 
              this.setState({ gdprquestionList, publisherInfo });
            });
        }
      }
    });
  };

  /*@author Sonali Kalke
   *@fileName :
   *Desc:store GDPR answers to database.
   */

  handleSubmit = (e) => {
    //var pID=localStorage.getItem('pID');
    var pID = read_cookie(cookie_key);
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { user } = this.props.auth;
        var pID = user.id;
        let data = {
          gdprArray: this.state.publisherInfo,
          caslArray: this.state.caslquestionList,
          //orgID:pID   //Priyanka--3944--removed id from data
        };
        fetch("/publisherOnBoardDetails/gdprAnswersForEdit", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success === true) {
              Swal.fire({
                type: "success",
                title: "Compliance details submitted successfully !",
              });

              this.setState({ inputClass: "disabled" });
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        console.log(err);
      }
    });
  };

  /*@author Sanobar Golandaj
   *@fileName :
   *Desc:handle change for Casl.
   */
  handleChangeCasl = (e) => {
    this.setState({
      checked1: !this.state.checked1,
    });
    this.props.form.validateFieldsAndScroll((error, values) => {
      if (!error) {
        let data = {
          compilanceValue: e.target.value,
          // pID:this.state.pID   //Priyanka--3944--removed id from data
        };

        if (e.target.checked === true && e.target.value === "CASL") {
          fetch("/publisherOnBoardDetails/getCaslQuestionsForEdit", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((caslquestionListForEdit) => {
              if (caslquestionListForEdit.length > 0) {
                this.setState({ caslquestionList: caslquestionListForEdit });
              } else {
                var caslquestionListForEdit = [
                  {
                    qID: "12",
                   questions: "CASL question 1 here",
                    complianceName: "CASL",
                    complianceID: 2,
                    answer: "No",
                  },
                  {
                    qID: "13",
                    questions: "CASL question 2 here",
                    complianceName: "CASL",
                    complianceID: 2,
                    answer: "No",
                  },
                ];
                this.setState({ caslquestionList: caslquestionListForEdit });
              }
            })
            .catch((e) => console.log(e)); //kiran - bug- 4132 handle error
        }
      }
    });
  };

  /*@author Sonali Kalke
   *@fileName :
   *Desc: upload GDPR files into database
   */

  /*@author Sanobar Golandaj
   *@fileName :
   *Desc:handle change for gdpr file,displaying file list on front end.
   */

  handleChangeGdprFile(i, e) {
    var pID = read_cookie(cookie_key);
    e.preventDefault();

    if (i == 0) {
      var name = e.target.name;
      name=name.split("#");
      var qID = name[0];
      var complianceID = name[1];
      let gdprFilesData = e.target.files;
      var gdprFilesDataNew = [];
      var duplicategdprList = document.getElementById(
        "duplicategdprList" + qID
      );

      for (var i = 0, l = gdprFilesData.length; i < l; i++) {
        var newItem = gdprFilesData[i].name;

        if (gdprnames.indexOf(newItem) === -1) {
          gdprnames.push(newItem);
          gdprFilesDataNew.push(gdprFilesData[i]);
        } else {
          duplicategdprList.innerHTML =
            newItem + " " + "File has already uploaded";
        }
      }

      var link;
      var filename = "";
      var gdprarrayLength = gdprnames.length;
      var nooutputgdpr = document.getElementById("nogdprList" + qID);
      if (gdprarrayLength > 0) {
        nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
      } else {
        nooutputgdpr.innerHTML = "0 Files Selected";
      }
      var HTML = "<table>";
      for (var j = 0; j < gdprnames.length; j++) {
        // User Story - 3427 - Karan Jagtap - changed remove icon
        link =
          '<a class="removeGdprFile" href="#" data-fileid=' +
          '"' +
          gdprnames[j] +
          '"' +
          '><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
        HTML +=
          "<tr><td style='display:flex;align-items:center;'>" +
          link +
          gdprnames[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("gdprList" + qID).innerHTML = HTML;
      var orgID = this.state.pID;
      $(document).on("click", ".removeGdprFile", function (e) {
        e.preventDefault();
        var clickedFile = $(this).closest("a").data("fileid");
        var j = 0;
        for (j = 0; j < gdprnames.length; j++) {
          var temp = j;
          if (gdprnames[temp] === clickedFile) {
            filename = gdprnames[temp];
            gdprnames.splice(j, 1);
            $(this).parent().remove();
            gdprarrayLength = gdprnames.length;
            /**
             * @author Narendra Phadke
             * @param Delete File From Database
             */
            var typeOfSuppDoc = "Gdpr";
            let data = {
              // orgID:orgID,      //Priyanka--3944--removed user id from data
              qID: qID,
              names: filename,
              complianceID: complianceID,
            };
            /*@author Sonali Kalke
             *@fileName :
             *Desc:delete files from database.
             */
            fetch("publisherOnBoardDetails/gdprDocumentDeleteFileUpdate", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            });
            document.getElementById("gdprfile" + qID).value = "";
            if (gdprarrayLength > 0) {
              nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
            } else {
              nooutputgdpr.innerHTML = "0 File Selected";
              $("#gdpr").val("");
              var message = document.getElementById("gdprMessage" + qID);
              message.innerHTML = "";
            }
          } 
        } 
      }); 
      var data = new FormData();
      data.append("qID", qID);
      data.append("orgID", this.state.pID);
      data.append("complianceID", complianceID);

      for (var i = 0, l = gdprFilesDataNew.length; i < l; i++) {
        data.append("file", gdprFilesDataNew[i]);
      }
      console.warn("newData", data);
      fetch("publisherOnBoardDetails/supportingDocumentGdprUpdate", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {})
        .catch(function (err) {
          console.log(err);
        });
      this.setState({
        files: data,
      });
      var msg = "Please click on submit button to save data";
      var message = document.getElementById("gdprMessage" + qID);
      var number = document.getElementById("gdprMessage" + qID).innerHTML;
      if (number == "") {
        message.innerHTML += msg;
      }
    } else if (i == 1) {
      var name = e.target.name;
      name=name.split("#");//vrushabh
      var qID = name[0];
      var complianceID = name[1];
      let gdprFilesData = e.target.files;
      var gdprFilesDataNew = [];

      var duplicategdprList = document.getElementById(
        "duplicategdprList" + qID
      );
      for (var i = 0, l = gdprFilesData.length; i < l; i++) {
        var newItem = gdprFilesData[i].name;

        if (gdprnames1.indexOf(newItem) === -1) {
          gdprnames1.push(newItem);
          gdprFilesDataNew.push(gdprFilesData[i]);
        } else {
          duplicategdprList.innerHTML =
            newItem + " " + "File has already uploaded";
        }
      }

      var link;
      var filename = "";

      var gdprarrayLength = gdprnames1.length;
      var nooutputgdpr = document.getElementById("nogdprList" + qID);
      if (gdprarrayLength > 0) {
        nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected"; 
      } else {
        nooutputgdpr.innerHTML = "0 Files Selected";
      }
      var HTML = "<table>";
      for (var j = 0; j < gdprnames1.length; j++) {
        // User Story - 3427 - Karan Jagtap - changed remove icon
        link =
          '<a class="removeGdprFile1" href="#" data-fileid=' +
          '"' +
          gdprnames1[j] +
          '"' +
          '><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
        HTML +=
          "<tr><td style='display:flex;align-items:center;'>" +
          link +
          gdprnames1[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("gdprList" + qID).innerHTML = HTML;
      var orgID = this.state.pID;
      $(document).on("click", ".removeGdprFile1", function (e) {
        e.preventDefault();
        var clickedFile = $(this).closest("a").data("fileid");
        var j = 0;
        for (j = 0; j < gdprnames1.length; j++) {
          var temp = j;
          if (gdprnames1[temp] === clickedFile) {
            filename = gdprnames1[temp];
            gdprnames1.splice(j, 1);
            $(this).parent().remove();
            gdprarrayLength = gdprnames1.length;
            /**
             * @author Narendra Phadke
             * @param Delete File From Database
             */
            var typeOfSuppDoc = "Gdpr";
            let data = {
              orgID: orgID,
              qID: qID,
              names: filename,
              complianceID: complianceID,
            };
            /*@author Sonali Kalke
             *@fileName :
             *Desc:delete files from database.
             */
            fetch("publisherOnBoardDetails/gdprDocumentDeleteFileUpdate", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            });
            document.getElementById("gdprfile" + qID).value = "";
            if (gdprarrayLength > 0) {
              nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
            } else {
              nooutputgdpr.innerHTML = "0 File Selected";
              $("#gdpr").val("");
              var message = document.getElementById("gdprMessage" + qID);
              message.innerHTML = "";
            }
          } 
        } 
      }); 
      var data = new FormData();
      data.append("qID", qID);
      data.append("orgID", this.state.pID);
      data.append("complianceID", complianceID);

      for (var i = 0, l = gdprFilesDataNew.length; i < l; i++) {
        data.append("file", gdprFilesDataNew[i]);
      }

      fetch("publisherOnBoardDetails/supportingDocumentGdprUpdate", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {})
        .catch(function (err) {
          console.log(err);
        });
      this.setState({
        files: data,
      });
      var msg = "Please click on submit button to save data";
      var message = document.getElementById("gdprMessage" + qID);
      var number = document.getElementById("gdprMessage" + qID).innerHTML;
      if (number == "") {
        message.innerHTML += msg;
      }
    } else if (i == 3) {
      var name = e.target.name;
      name=name.split("#");
      var qID = name[0];
      var complianceID = name[1];//vp
      let gdprFilesData = e.target.files;
      var gdprFilesDataNew = [];
      var duplicategdprList = document.getElementById(
        "duplicategdprList" + qID
      );

      for (var i = 0, l = gdprFilesData.length; i < l; i++) {
        var newItem = gdprFilesData[i].name;

        if (gdprnames3.indexOf(newItem) === -1) {
          gdprnames3.push(newItem);
          gdprFilesDataNew.push(gdprFilesData[i]);
        } else {
          duplicategdprList.innerHTML =
            newItem + " " + "File has already uploaded";
        }

      }

      var link;
      var filename = "";
      var gdprarrayLength = gdprnames3.length;
      var nooutputgdpr = document.getElementById("nogdprList" + qID);
      if (gdprarrayLength > 0) {
        nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
      } else {
        nooutputgdpr.innerHTML = "0 Files Selected";
      }
      var HTML = "<table>";
      for (var j = 0; j < gdprnames3.length; j++) {
        // User Story - 3427 - Karan Jagtap - changed remove icon
        link =
          '<a class="removeGdprFile2" href="#" data-fileid=' +
          '"' +
          gdprnames3[j] +
          '"' +
          '><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
        HTML +=
          "<tr><td style='display:flex;align-items:center;'>" +
          link +
          gdprnames3[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("gdprList" + qID).innerHTML = HTML;
      var orgID = this.state.pID;
      $(document).on("click", ".removeGdprFile2", function (e) {
        e.preventDefault();
        var clickedFile = $(this).closest("a").data("fileid");
        var j = 0;
        for (j = 0; j < gdprnames3.length; j++) {
          var temp = j;
          if (gdprnames3[temp] === clickedFile) {
            filename = gdprnames3[temp];
            gdprnames3.splice(j, 1);
            $(this).parent().remove();
            gdprarrayLength = gdprnames3.length;
            /**
             * @author Narendra Phadke
             * @param Delete File From Database
             */
            var typeOfSuppDoc = "Gdpr";
            let data = {
              orgID: orgID,
              qID: qID,
              names: filename,
              complianceID: complianceID,
            };
            /*@author Sonali Kalke
             *@fileName :
             *Desc:delete files from database.
             */
            fetch("publisherOnBoardDetails/gdprDocumentDeleteFileUpdate", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            });
            document.getElementById("gdprfile" + qID).value = "";
            if (gdprarrayLength > 0) {
              nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
            } else {
              nooutputgdpr.innerHTML = "0 File Selected";
              $("#gdpr").val("");
              var message = document.getElementById("gdprMessage" + qID);
              message.innerHTML = "";
            }
          } 
        } 
      }); 
      var data = new FormData();
      data.append("qID", qID);
      data.append("orgID", this.state.pID);
      data.append("complianceID", complianceID);
      for (var i = 0, l = gdprFilesDataNew.length; i < l; i++) {
        data.append("file", gdprFilesDataNew[i]);
      }
      fetch("publisherOnBoardDetails/supportingDocumentGdprUpdate", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {})
        .catch(function (err) {
          console.log(err);
        });
      this.setState({
        files: data,
      });
      var msg = "Please click on submit button to save data";
      var message = document.getElementById("gdprMessage" + qID);
      var number = document.getElementById("gdprMessage" + qID).innerHTML;
      if (number == "") {
        message.innerHTML += msg;
      }
    } else if (i == 6) {
      var name = e.target.name;
      name=name.split("#");
      var qID = name[0];
      var complianceID = name[1];
      let gdprFilesData = e.target.files;
      var gdprFilesDataNew = [];
      var duplicategdprList = document.getElementById(
        "duplicategdprList" + qID
      );

      for (var i = 0, l = gdprFilesData.length; i < l; i++) {
        var newItem = gdprFilesData[i].name;

        if (gdprnames6.indexOf(newItem) === -1) {
          gdprnames6.push(newItem);
          gdprFilesDataNew.push(gdprFilesData[i]);
        } else {
          duplicategdprList.innerHTML =
            newItem + " " + "File has already uploaded";
        }
      }

      var link;
      var filename = "";
      var gdprarrayLength = gdprnames6.length;
      var nooutputgdpr = document.getElementById("nogdprList" + qID);
      if (gdprarrayLength > 0) {

        nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
      } else {
        nooutputgdpr.innerHTML = "0 Files Selected";
      }
      var HTML = "<table>";
      for (var j = 0; j < gdprnames6.length; j++) {
        // User Story - 3427 - Karan Jagtap - changed remove icon
        link =
          '<a class="removeGdprFile3" href="#" data-fileid=' +
          '"' +
          gdprnames6[j] +
          '"' +
          '><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
        HTML +=
          "<tr><td style='display:flex;align-items:center;'>" +
          link +
          gdprnames6[j] +
          "</td></tr>";
      }
      HTML += "</table>";
      document.getElementById("gdprList" + qID).innerHTML = HTML;
      var orgID = this.state.pID;
      $(document).on("click", ".removeGdprFile3", function (e) {
        e.preventDefault();
        var clickedFile = $(this).closest("a").data("fileid");
        var j = 0;
        for (j = 0; j < gdprnames6.length; j++) {
          var temp = j;
          if (gdprnames6[temp] === clickedFile) {
            filename = gdprnames6[temp];
            gdprnames6.splice(j, 1);
            $(this).parent().remove();
            gdprarrayLength = gdprnames6.length;
            /**
             * @author Narendra Phadke
             * @param Delete File From Database
             */
            var typeOfSuppDoc = "Gdpr";
            let data = {
              orgID: orgID,
              qID: qID,
              names: filename,
              complianceID: complianceID,
            };
            /*@author Sonali Kalke
             *@fileName :
             *Desc:delete files from database.
             */
            fetch("publisherOnBoardDetails/gdprDocumentDeleteFileUpdate", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            });
            document.getElementById("gdprfile" + qID).value = "";
            if (gdprarrayLength > 0) {
              nooutputgdpr.innerHTML = gdprarrayLength + "Files Selected";
            } else {
              nooutputgdpr.innerHTML = "0 File Selected";
              $("#gdpr").val("");
              var message = document.getElementById("gdprMessage" + qID);
              message.innerHTML = "";
            }
          } 
        } 
      }); 
      var data = new FormData();
      data.append("qID", qID);
      data.append("orgID", this.state.pID);
      data.append("complianceID", complianceID);
      for (var i = 0, l = gdprFilesDataNew.length; i < l; i++) {
        data.append("file", gdprFilesDataNew[i]);
      }
      fetch("publisherOnBoardDetails/supportingDocumentGdprUpdate", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {})
        .catch(function (err) {
          console.log(err);
        });
      this.setState({
        files: data,
      });
      var msg = "Please click on submit button to save data";
      var message = document.getElementById("gdprMessage" + qID);
      var number = document.getElementById("gdprMessage" + qID).innerHTML;
      if (number == "") {
        message.innerHTML += msg;
      }
    }
  } 

  render() {
    var pID = read_cookie(cookie_key);
    if (pID == "") {
      this.state.buttonDisplay = "";
      this.state.displayChk1 = "";
      this.state.displayChk2 = "";
      this.state.infoMsg = "Please Fill Company Information First";
    } else {
      this.state.buttonDisplay = "";
      this.state.displayChk1 = "";
      this.state.displayChk2 = "";
      this.state.infoMsg = "";
    }

    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    this.state.gdprquestionList.forEach((itm, i) => {
      this.state.gdprDynamicArray.push(Object.assign({}, itm));
    });

    this.state.caslquestionList.forEach((itm, i) => {
      this.state.caslDynamicArray.push(Object.assign({}, itm));
    });

    /*@author Sanobar Golandaj
     *@fileName :
     *Desc:display table for Gdpr on gdpr handle change
     */
    const content = this.state.checked ? (
      <div class="table-responsive-lg">
        <table id="myTable" className=" table table-bordered table-striped">
          <thead>
            <tr style={{ backgroundColor: "#909394", color: "white" }}>
              <th>GDPR Requirement Questionnaire:</th>
              <th>Details</th>
              <th>Associated Document</th>
            </tr>
          </thead>
          <tbody>
            {this.state.gdprquestionList.length > 0 &&
            this.state.publisherInfo.length > 0
              ? this.state.gdprquestionList.map((gdprquestionList, i) => {
                  if (
                    (this.state.gdprquestionList[0].complianceName = "true")
                  ) {
                    return (
                      // kiran- bug 4132- checked array length with gdprquestionList and publisherInfo
                      <tr>
                        <td>
                          <label class="word-wrap">
                            {gdprquestionList.questions}
                            {gdprquestionList.qID == 3 ? (
                              <Tooltip title="Opt-In-process">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            ) : (
                              " "
                            )}

                            {gdprquestionList.qID == 4 ? (
                              <Tooltip title="Provide the link of privacy policy">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            ) : (
                              " "
                            )}

                            {gdprquestionList.qID == 7 ? (
                              <Tooltip title="Training types and frequency">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            ) : (
                              " "
                            )}

                            {gdprquestionList.qID == 8 ? (
                              <Tooltip title="Email id of DPO">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            ) : (
                              " "
                            )}
                          </label>
                        </td>

                        <td>
                          {this.state.publisherInfo[i] == null ||
                          this.state.publisherInfo[i] == undefined ||
                          this.state.publisherInfo[i] == "" ? (
                            ""
                          ) : (
                            <Radio.Group>
                              <Radio
                                value="Yes"
                                id="gdpr-1"
                                checked={
                                  this.state.publisherInfo[i].answer === "Yes"
                                }
                                onChange={this.handleChangeGdprRadio.bind(
                                  this,
                                  i
                                )}
                              >
                                Yes
                              </Radio>

                              <Radio
                                value="No"
                                id="gdpr-1"
                                checked={
                                  this.state.publisherInfo[i].answer === "No"
                                }
                                onChange={this.handleChangeGdprRadio.bind(
                                  this,
                                  i
                                )}
                              >
                                No
                              </Radio>
                            </Radio.Group>
                          )}
                        </td>

                        

                       
                        <td style={{ width: "380px" }}>
                          {gdprquestionList.suppDoc == "Yes" ? (
                            <div>
                              <input
                                type="file"
                                style={{ color: "transparent", border: "none" }}
                                id={"gdprfile" + gdprquestionList.qID}
                                name={
                                  gdprquestionList.qID +
                                  "#" +
                                  gdprquestionList.complianceID
                                }
                                onChange={this.handleChangeGdprFile.bind(
                                  this,
                                  i
                                )}
                                multiple
                              ></input>
                              <div>
                                <div>
                                  {this.state.publisherInfo[i].documentName ===
                                    null ||
                                  this.state.publisherInfo[i].documentName ===
                                    "" ||
                                  this.state.publisherInfo[i].documentName ===
                                    undefined ? (
                                    <div
                                      id={"nogdprList" + gdprquestionList.qID}
                                    >
                                      0 file Selected{" "}
                                    </div>
                                  ) : (
                                    <div
                                      id={"nogdprList" + gdprquestionList.qID}
                                    >
                                      Uploaded file:
                                      {this.state.publisherInfo[i].documentName}
                                    </div>
                                  )}
                                  <div
                                    id={
                                      "duplicategdprList" + gdprquestionList.qID
                                    }
                                    style={{ color: "red" }}
                                    className="word-wrap"
                                  ></div>
                                  <div
                                    id={"gdprList" + gdprquestionList.qID}
                                    className="word-wrap"
                                  ></div>

                                  <div
                                    id={"gdprMessage" + gdprquestionList.qID}
                                    style={{ color: "green" }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            " "
                          )}
                        </td>
                      </tr>
                    );
                  }
                })
              : ""}
          </tbody>
        </table>
      </div>
    ) : null;

    /*@author Sanobar Golandaj
     *@fileName :
     *Desc:display table for Casl on casl handle change
     */
    const content1 = this.state.checked1 ? (
      <div class="table-responsive-lg">
        <table id="myTable" className=" table table-bordered table-striped">
          <thead>
            <tr style={{ backgroundColor: "#909394", color: "white" }}>
              <th>CASL Requirement Questioanire:</th>
              <th style={{ width: "425px" }}>Details</th>
            </tr>
          </thead>
          <tbody>
            {this.state.caslquestionList.map((caslquestionList, i) => {
              if ((this.state.caslquestionList[0].complianceName = "true")) {
                return (
                  <tr>
                    <td>
                      <label class="word-wrap">
                        {/*caslquestionList.questions*/}
                        {unescape(caslquestionList.questions)/*vrushabh-task-4142* remove %20 from UI string */}
                      </label>
                    </td>
                    <td>
                      <Radio.Group>
                        <Radio
                          value="Yes"
                          id="gdpr-2"
                          checked={
                            this.state.caslquestionList[i].answer === "Yes"
                          }
                          onChange={this.handleChangeCaslRadio.bind(this, i)}
                        >
                          Yes
                        </Radio>
                        <Radio
                          value="No"
                          id="gdpr-2"
                          checked={
                            this.state.caslquestionList[i].answer === "No"
                          }
                          onChange={this.handleChangeCaslRadio.bind(this, i)}
                        >
                          No
                        </Radio>
                      </Radio.Group>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    ) : null;

    return (
      <div>
        <div class="container-fluid" style={{ paddingBottom: "60px" }}>
          <div
            style={{ fontSize: "22px", color: "green", paddingLeft: "490px" }}
          >
            {this.state.newsuccess}
          </div>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label="Compliance">
              {getFieldDecorator("checkbox-group", {
                initialValue: ["A", "B"],
              })(
                <Row>
                  {this.state.complianceNameList.map((complianceNameList) => {
                    if (complianceNameList.complianceName == "GDPR") {
                      return (
                        <Col span={8}>
                          <Checkbox
                            value={this.state.varGDPR}
                            name="GDPR"
                            disabled={this.state.displayChk1}
                            checked={this.state.checked}
                            onChange={this.handleChangeGdpr}
                          >
                            {complianceNameList.complianceName}
                          </Checkbox>
                        </Col>
                      );
                    }
                  })}
                  {this.state.complianceNameList.map((complianceNameList) => {
                    if (complianceNameList.complianceName == "CASL") {
                      return (
                        <Col span={8}>
                          <Checkbox
                            value={this.state.varCASL}
                            name="CASL"
                            disabled={this.state.displayChk2}
                            checked={this.state.checked1}
                            onChange={this.handleChangeCasl}
                          >
                            {complianceNameList.complianceName}
                          </Checkbox>
                        </Col>
                      );
                    }
                  })}
                </Row>
              )}
            </Form.Item>

            {content}
            {content1}
            {/* kiran- bug 4132- button right aligned */}
            <div class="row" style={{ float: "right" }}>
              <div
                class="col-xs-12  col-sm-12 col-md-6 col-lg-6"
                style={{ paddingRight: "40px", paddingBottom: "20px" }}
              >
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={this.state.buttonDisplay}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
}
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
PublisherGDPRCertificateEdit.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const publisherRegistrationForm3 = Form.create({ name: "register" })(
  PublisherGDPRCertificateEdit
);
export default connect(mapStateToProps, { logoutUser })(
  withRouter(publisherRegistrationForm3)
);







