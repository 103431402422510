/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*@author Shivani Pathak
*@fileName : agencyPublisherDeliverytracking.js
@Creation Date:08-04-2021
 *Desc: 
*/
import React from "react";
//import { Tabs, Tab } from 'react-bootstrap'; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import Navigation from "../layouts/navPage"; 
//import * as $ from "jquery"; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import './agencyMyReports.css';
// import Loader from "../Reports/report_Loader";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";      //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE


import { css } from "emotion";
import { Table } from 'antd'; //Aman-4797-(remove  Tooltip, Spin not in use)-Console Warnings-Agency-Reports Part 2
//import { type } from "jquery"; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2

const Swal = require('sweetalert2');

const tableCSS = css({
    backgroundColor: "white",
    fontFamily: "sans-serif",
    borderStyle: "bold",
    "& thead  > tr": {
        backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
        color: "white",
        fontSize: "12px",
        textAlign: "center !important",
        fontWeight: "500",
    },
    "& thead > tr >th": {
        textAlign: "center !important",
        color: "white",
    },
});
//const queryString = require('query-string'); //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2


/**
* @author : Shivani Pathak-task 3354
* @description : created class component named as agencyPublisherDeliverytracking.
*/
class agencyPublisherDeliverytracking extends React.Component {
    constructor() {
        super();
        this.state = {
            tableState: { size: "small" }, //shivani-3354-used for table design
            agencyMyReportCampaignInfo: [], //shivani-3354-array declared for table data
            agencyMyReportCampaignInfo1: [],//shivani-3354-declare to set table data in this new array while filtering the data
            agencyMyReportsDataNew1: [],//shivani-3354-declare to set table data in this new array while filtering the data
            agencyMyReportsData: [],//shivani-3354-array declared for table data
            reportSummary: [],//shivani-3354-array declared for report summary data
            publisherList: [],//shivani-3354-array declared for publisher name
            publisherList1: [],//shivani-3354-array declared to filter publisher name
            agencyID: "",// shivani-3354-declare to used from userLogin
            pID: "None Selected",//shivani-3354-used to display bydefault non-selected .
            campaignStatus: "None Selected",//shivani-3354-used to display bydefault non-selected .
            deadline: "None Selected",//shivani-3354-used to display bydefault non-selected .
            label: "", //shivani-3354-used to filter deadline as per label .
            reportKey: "",//shivani-3354-used key to put conditions when user came by clicking on report name .
            reportKey1: "",//shivani-3354-used key to put conditions when user came by clicking on report name .
            publisherName: "", //shivani-3354-to target publisher name . 
            type: "Publisher Campaign Delivery Tracking",//shivani-3354-used bydefault this type .{/* //shivani-3432-passed value for same report type for create and edit report */}
            reportName: "",//shivani-3354-used to pass report name to api .          
            generateBtnFlag: false,//shivani-3354- used to enable - disable generate button.
            token: "",// shivani-3354-used token for authentication
            loader: "",//shivani-3354-used for loader.
            loader1: "",//shivani-3354-used for generate loader.
            userType: "Publisher",
            pageSize: 100,//sunita-task-3486-added for page size.

        }
        /**
* @author : Shivani Pathak-task 3354
* @description : binded all handlechange here .
*/
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.onPublisherNameHandleChange = this.onPublisherNameHandleChange.bind(this);
        this.onCampaignStatusHandleChange = this.onCampaignStatusHandleChange.bind(this);
        this.ondeadlineHandleChange = this.ondeadlineHandleChange.bind(this);
        this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
        this.commonHandleChange = this.commonHandleChange.bind(this);
        this.generateHandleChange = this.generateHandleChange.bind(this);


        // this.loadingRef = React.createRef(); // 3354- shivani pathak - removed default loader and implemented custom loader
        // this.closeLoadingRef = React.createRef(); // 3354- shivani pathak - removed default loader and implemented custom loader
    }
    /**
* @author : Shivani Pathak-task 3354
* @description : handlechange for pageSize .
*/
    handleChangePageSize(e) {
        var pageSize = e.target.value;
        this.setState({ pageSize: pageSize });
    }

    /**
* @author : Shivani Pathak-task 3354
* @description : handlechange for backbutton to navigate on same page from where user came .
*/
    backButtonHandleChange() {
        if (this.state.reportKey1 !== "report") {
            Swal.fire({
                title: 'Are you sure you want to discard this report?',
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                allowOutsideClick: false,
                confirmButtonText: "Discard",
            }).then((result) => {
                if (result.value) {
                    window.location.href = "/agencyMyReports";
                }
                else if (result.dismiss === "close") {
                    window.location.href = "#";
                }
            })
        }
        else {
            window.location.href = "/agencyMyReports";
        }


    }
    /**
* @author : Shivani Pathak-task 3354
* @description : handlechange to load data after selecting all three filters publisher, campaign Status and timeline .
*/
    commonHandleChange(pID, campaignStatus, deadline) {
        let reportSummary = [{ "pID": pID, "publisherName": "", "deliveryLabel": deadline, "totalAllocation": 0, "totalAccepted": 0, "deliveredLead": 0, "rejectedLead": 0, "qaReviewLead": 0, "balanceLead": 0 }]
        this.setState({ agencyMyReportCampaignInfo: [], reportSummary, loader: "loader", generateBtnFlag: true })
        // 
        // this.loadingRef.current.click(); 

        const { user } = this.props.auth;
        const agencyID = user.id;
        this.setState({ agencyID })
        //   let self=this;
        let data = {
           // agencyID: agencyID,                                           //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
            pID: pID,
            campaignStatus: campaignStatus,
            deadline: deadline,
        }

        fetch("/reportEngine/campaignInfo", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                

            },          
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(result => {
                // self.closeLoadingRef.current.click();

                this.setState({ agencyMyReportCampaignInfo: result.reportDetail, reportSummary: result.reportSummary, loader: "" })
            }).catch(function (err) { console.log(err) });
    }
    
    /**
* @author : Shivani Pathak-task 3354
* @description : handlechange to target selected publisher .
*/
    onPublisherNameHandleChange(e) {

        var pID = e.target.value;
        this.setState({ pID })
        let campaignStatus = this.state.campaignStatus;
        let deadline = this.state.deadline;
        if ((pID && pID !== "None Selected") && (campaignStatus && campaignStatus !== "None Selected") && (deadline && deadline !== "None Selected")) {
            this.commonHandleChange(pID, campaignStatus, deadline);
        }
        //shivani-task 3435-added else condition to make button disable when user click on "None Selected".
        else {
            this.setState({ generateBtnFlag: false,agencyMyReportCampaignInfo:[]})

        }
    }
    /**
* @author : Shivani Pathak-task 3354
* @description : handlechange to target selected status .
*/
    onCampaignStatusHandleChange(e) {
        var campaignStatus = e.target.value;
        this.setState({ campaignStatus })
        let pID = this.state.pID;
        let deadline = this.state.deadline;
        if ((campaignStatus && campaignStatus !== "None Selected") && (pID && pID !== "None Selected") && (deadline && deadline !== "None Selected")) {
            this.commonHandleChange(pID, campaignStatus, deadline);
        }
        //shivani-task 3435-added else condition to make button disable when user click on "None Selected".
        else {
            this.setState({ generateBtnFlag: false,agencyMyReportCampaignInfo:[]})
        }
    }
    /**
* @author : Shivani Pathak-task 3354
* @description : handlechange to target selected deadline .
*/
    ondeadlineHandleChange(e) {

        var deadline = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        
        //Aman-4797-Commenting below code because it is not in use - taking this value from label directly 

        //shivani-3431-used below conditions to display bydeafult deadline value in front of delivery summary when user came by clicking on report name.
        // if (deadline === "All") {//Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
        //    this.setState({label : "All"}); //this.state.label = "All" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
        // }
        // else if (deadline === "No Lead Delivered") {
        //     this.setState({label : "No Lead Delivered"})//this.state.label = "No Lead Delivered"
        // }
        // else if (deadline === "Ending Three Days") {
        //     this.setState({label : "Ending 3 days"}); //this.state.label = "Ending 3 days"
        // }
        // else if (deadline === "Weekly") {
        //     this.setState({label : "Ending This Week"}); //this.state.label = "Ending This Week" 
        // }
        // else if (deadline === "Monthly") {
        //     this.setState({label : "Ending This Month"}); //this.state.label = "Ending This Month"
        // }
        // else if (deadline === "Quarterly") {
        //     this.setState({label : "Ending This Quarter"}); //this.state.label = "Ending This Quarter" 
        // }
        // else if (deadline === "Yearly") {
        //     this.setState({label : "Ending This Year"}); //this.state.label = "Ending This Year"
        // }
        // else if (deadline === "PreMonth") {
        //     this.setState({label : "Ending Previous Month"}); //this.state.label = "Ending Previous Month" 
        // }
        // else if (deadline === "PreQuarter") {
        //     this.setState({label : "Ending Previous Quarter"}); //this.state.label = "Ending Previous Quarter" 
        // }
        // else if (deadline === "PreYear") {
        //     this.setState({label : "Ending Previous Year"}); //this.state.label = "Ending Previous Year"
        // }

        this.setState({ deadline, label: label }) //Aman-4797-(remove this label: this.state.label/ e.nativeEvent.target[index].text dupliacte)-Console Warnings-Agency-Reports Part 2

        let { pID, campaignStatus } = this.state;

        if ((deadline && deadline !== "None Selected") && (campaignStatus && campaignStatus !== "None Selected") && (pID && pID !== "None Selected")) {

            this.commonHandleChange(pID, campaignStatus, deadline);
        }
        //shivani-task 3435-added else condition to make button disable when user click on "None Selected".
        else {
            this.setState({ generateBtnFlag: false,agencyMyReportCampaignInfo:[]})
        }
    }
    /**
* @author : Shivani Pathak-task 3354
* @description : handlechange to generate report successfully .
*/
    generateHandleChange(e) {
        const { user } = this.props.auth;
        const agencyID = user.id;
        // var pID =e.target.value;
        let pID = this.state.pID;
        let deadline = this.state.deadline;
        let campaignStatus = this.state.campaignStatus;
        let reportDetail = [...this.state.agencyMyReportCampaignInfo]
        let reportSummary = [...this.state.reportSummary]
        let reportName = this.state.reportName;
        let type = this.state.type;
        let userType = this.state.userType;
        this.setState({ pID, campaignStatus, deadline, user, agencyID, reportDetail, reportSummary, reportName, type, userType, loader1: "gen_loader" })


        if ((pID !== "None Selected") && (campaignStatus !== "None Selected") && (deadline !== "None Selected")) {
            let data = {
                //user: user,                                               //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
                //agencyID: agencyID,
                pID: pID,
                deadline: deadline,
                campaignStatus: campaignStatus,
                type: type,
                reportName: reportName,
                reportSummary: reportSummary,
                reportDetail: reportDetail,
                userType: userType
                
            }


            fetch("/reportEngine/generateReport", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                   
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then((res) => {
                    this.setState({ loader1: "" })
                    if (res.success === true) {
                        Swal.fire({
                            title: '<span id="success_popUp">Report generated successfully.</span>',
                            html: "<span id='for_popUp_generate'>Please <a href='/agencyMyReports'><span id='for_popUp_generate1'>click here</span></a> to check report.</span>",
                            type: "success",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                            preConfirm: () => {
                                window.location.href = "/agencyMyReports";
                            },
                        });
                    }
                    if (res.success === false) {
                        Swal.fire({
                            title: 'Report data empty.',
                            type: "error",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                            preConfirm: () => {
                                // window.location.reload();
                            },
                        });
                    }

                }).catch(function (err) { console.log(err) });
        }
    }
    componentWillMount(e) {                             //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
        }
        else {
            let reportKey,reportID,pID,type,reportName; 
            if (this.props.location.state !== undefined)
            {
                pID=this.props.location.state.pID; 
                reportKey=this.props.location.state.reportKey;
                reportID=this.props.location.state.reportID;
                //this.state.reportID=reportID; //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                reportName=this.props.location.state.reportName;
                this.setState({reportName : reportName, reportID : reportID}); //this.state.reportName=reportName; //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                type = this.props.location.state.type;
           }
           // const { isAuthenticated } = this.props.auth; ////Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
            const { user } = this.props.auth;
             const agencyID = user.id;
            
 
           
            this.setState({
                agencyID:agencyID,
                pID:pID, 
                reportKey1:reportKey,
                generateBtnFlag: false,
                reportName:reportName,
                type:type, 
            })
            let data = {
                //agencyID: this.state.agencyID,                                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
                pID: pID,
                reportID:reportID,

            }
            if (reportID) {
                fetch("/reportEngine/reportDetail", {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                       
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json())
                    .then(result => {
                       
                        //shivani-3354-used below condition to display bydeafult values when user came by clicking on report name.
                        let deadline, campaignStatus, pID
                        if (result) {
                            pID = result.pID;
                            campaignStatus = result.campaignStatus;
                            deadline = result.deadline;
                        }

                        //shivani-3354-used below conditions to display bydeafult deadline value in front of delivery summary when user came by clicking on report name.
                        if (result.deadline !== "") {

                            if (result.deadline === "All") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
                                this.setState({label : "All"}); //this.state.label = "All" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                            }
                            else if (result.deadline === "No Lead Delivered") {
                                this.setState({label : "No Lead Delivered"}); //this.state.label = "No Lead Delivered" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                            }
                            else if (result.deadline === "Ending Three Days") {
                                this.setState({label : "Ending 3 days"}); //this.state.label = "Ending 3 days" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                            }
                            else if (result.deadline === "Weekly") {
                                this.setState({label : "Ending This Week"}); //this.state.label = "Ending This Week" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                            }
                            else if (result.deadline === "Monthly") {
                                this.setState({label : "Ending This Month"}); //this.state.label = "Ending This Month" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                            }
                            else if (result.deadline === "Quarterly") {
                                this.setState({label : "Ending This Quarter"}); //this.state.label = "Ending This Quarter" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                            }
                            else if (result.deadline === "Yearly") {
                                this.setState({label : "Ending This Year"}); //this.state.label = "Ending This Year" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                            }
                            else if (result.deadline === "PreMonth") {
                                this.setState({label : "Ending Previous Month"}); //this.state.label = "Ending Previous Month" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                            }
                            else if (result.deadline === "PreQuarter") {
                                this.setState({label : "Ending Previous Quarter"}); //this.state.label = "Ending Previous Quarter" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                            }
                            else if (result.deadline === "PreYear") {
                                this.setState({label : "Ending Previous Year"}); //this.state.label = "Ending Previous Year" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
                            }
                        }

                        this.setState({ agencyMyReportCampaignInfo: result.reportDetail, reportSummary: result.reportSummary, pID, deadline, campaignStatus, label: this.state.label })

                    }).catch(function (err) { console.log(err) });
            }

            fetch("/reportEngine/getPublisher", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    
                },
              //  body: JSON.stringify(data)
            }).then(res => res.json())
                .then(publisherList => {
                   
                    this.setState({ publisherList, publisherList1: publisherList })
                }).catch(function (err) { console.log(err) });
                     
              }
    }
    
    /**
* @author : Shivani Pathak-task 3354
* @description :Render part to display complete screen with functionality as per given screen .
*/
    render() {
        const asterisk =
        {
            color: 'red',
        }
        // let locale = {
        //     emptyText:"wait a moment while we are loading your data....",
        //  }; 

        const columnsReport = [
            {
                title: <span>Camp ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campID",
                key: "campID",
                width: "7.5%",

                align: "left",
                sorter: (a, b) => a.campID - b.campID,
                
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.campID}
                        </span>
                        <br />
                    </div>

                ),
                

            },
            {
                title: <span>Agency Camp ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "agencyID",
                key: "agencyID",
                width: "7%",
                align: "left",
                sorter: (a, b) => a.agencyID - b.agencyID,
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.agencyID}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Advertiser&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "clientName",
                key: "clientName",
                width: "8.5%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.clientName.toUpperCase() < b.clientName.toUpperCase()) {
                        return asc ? -1 : 1;
                    } else if (
                        a.clientName.toUpperCase() > b.clientName.toUpperCase()
                    ) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div id="to_truncate" style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.clientName}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Camp Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campaignName",
                key: "campaignName",
                width: "11%",

                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
                        return asc ? -1 : 1;
                    } else if (
                        a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
                    ) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div id="to_truncate1">
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.campaignName}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Start Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "startDate",
                key: "startDate",
                width: "7%",

                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.startDate < b.startDate) {
                        return asc ? -1 : 1;
                    } else if (a.startDate > b.startDate) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.startDate}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>End Date&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "endDate",
                key: "endDate",
                width: "7%",

                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.endDate < b.endDate) {
                        return asc ? -1 : 1;
                    } else if (a.endDate > b.endDate) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.endDate}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: <span>Allocated Lead&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "allocatedLead",
                key: "allocatedLead",
                width: "7%",
                align: "left",
                sorter: (a, b) => {
                    const asc = "asc";

                    if (a.allocatedLead < b.allocatedLead) {
                        return asc ? -1 : 1;
                    } else if (a.allocatedLead > b.allocatedLead) {
                        return asc ? 1 : -1;
                    } else {
                        return 0;
                    }
                },
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.allocatedLead}
                        </span>
                        <br />
                    </div>
                ),
            },
            {
                title: "Delivery Status",
                width: "70%",
                // align: "center",
                children: [
                    {
                        title: <span>Delivered&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "deliveredLead",
                        key: "deliveredLead",
                        align: "left",
                        width: "6%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.deliveredLead < b.deliveredLead) {
                                return asc ? -1 : 1;
                            } else if (a.deliveredLead > b.deliveredLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                            <div style={{fontSize: '13px', color: '#4F4F4F'}}>{/*sunita-task-3486- added css as per DI standard*/}
                                {record.deliveredLead}
                                {/* {record.deliveredLead > 0 ? ( */}
                                <a
                                    href="#/"

                                >

                                </a>
                                {/* ) : (
                                                    0
                                                )} */}
                            </div>
                        ),

                    },
                    {
                        title: <span>Review Pending&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "qaReviewLead",
                        key: "qaReviewLead",
                        align: "left",
                        width: "7%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.qaReviewLead < b.qaReviewLead) {
                                return asc ? -1 : 1;
                            } else if (a.qaReviewLead > b.qaReviewLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                            <div style={{fontSize: '13px', color: '#4F4F4F'}}>{/*sunita-task-3486- added css as per DI standard*/}
                                {record.qaReviewLead}
                                {/* {record.qaReviewLead > 0 ? ( */}
                                <a
                                    href="#/">

                                </a>
                                {/* ) : (
                                                    0
                                                )} */}
                            </div>
                        ),
                    },
                    {
                        title: <span>Accepted&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "acceptedLead",
                        key: "acceptedLead",
                        align: "left",
                        width: "6%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.acceptedLead < b.acceptedLead) {
                                return asc ? -1 : 1;
                            } else if (a.acceptedLead > b.acceptedLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                            <div style={{fontSize: '13px', color: '#4F4F4F'}}>{/*sunita-task-3486- added css as per DI standard*/}
                                {record.acceptedLead}
                            </div>
                        ),
                    },
                    {
                        title: <span>Rejected&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "rejectedLead",
                        key: "rejectedLead",
                        align: "left",
                        width: "6%",
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.rejectedLead < b.rejectedLead) {
                                return asc ? -1 : 1;
                            } else if (a.rejectedLead > b.rejectedLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render: (text, record) => (
                            <div style={{fontSize: '13px', color: '#4F4F4F'}}> {/*sunita-task-3486- added css as per DI standard*/}
                                {record.rejectedLead}
                            </div>
                        ),
                    },
                    {
                        title: <span>Balance&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                        dataIndex: "balancedLead",
                        key: "balancedLead",
                        align: "left",
                        width: "6%",
                        //defaultSortOrder: 'dscend',
                        sorter: (a, b) => {
                            const asc = "asc";

                            if (a.balancedLead < b.balancedLead) {
                                return asc ? -1 : 1;
                            } else if (a.balancedLead > b.balancedLead) {
                                return asc ? 1 : -1;
                            } else {
                                return 0;
                            }
                        },
                        render(text, record) {

                            return {
                                props: {
                                    style: { wordWrap: "break-word", height: "5%", width: "6%" },
                                },
                                children: (
                                    <div style={{fontSize: '13px', color: '#4F4F4F'}}>{/*sunita-task-3486- added css as per DI standard*/}
                                        {/* {" "} */}{record.balancedLead}
                                        {/* {record.balancedLead ? record.balancedLead : 0} */}
                                    </div>
                                ),
                            };
                        },
                    },
                ],

            },


        ]


        function onChange(pagination, filters, sorter) {
            console.log("params", pagination, filters, sorter);
        }
        
        return (
            <div>
                <Navigation />
                <div style={{ paddingBottom: '60px', paddingTop: '100px' }} class="container-fluid ">
                    <div class="row">
                        <div class="col-sm-12 col-md-1 col-lg-2" >{/*3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE*/}
                            {/*Aman-4797-(added void(0) to "#/" and removed the duplicate style attribute)-Console Warnings-Agency-Reports Part 2 */}
                            <a style={{ color: '#056eb8' }} href={"#/"} onClick={this.backButtonHandleChange}><FaArrowAltCircleLeft size={32} title="Back to agencyMyReport" /></a>

                        </div>
                        <div class=" col-xs-12 col-sm-12 col-md-8 col-lg-7" align="center">
                            <label id="labelDI">Publisher Delivery Tracking Report</label>
                        </div>
                        <div class="col-sm-12 col-md-3 offset-lg-1 col-lg-2" style={{paddingInlineStart: "37px"}}>
                        {/*Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                            {this.state.reportKey1 === "report" ?
                                "" :
                                this.state.generateBtnFlag === true ?
                                    <button class="btn add-button" type="button" data-toggle="modal" data-target="#generateReport" 
                                        onClick={this.generateHandleChange} >Generate Report</button>
                                    :
                                    <button disabled class="btn add-button" type="button" data-toggle="modal" data-target="#generateReport">Generate Report</button>

                            }

                        </div>
                    </div>
                    <div class="row" style={{ paddingTop: '15px', paddingLeft: '25px', paddingBottom: '5px' }}>
                        <div class="col-sm-12 col-md-4 col-lg-3" id="fr_md0">
                            <label style={{ fontSize:'14px',color: '#14254A',fontFamily:'sans-serif' }}>Publisher&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;</label>{/*sunita-task-3486- added css as per DI standard*/}
                            {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                            {this.state.reportKey1 === "report" ?
                                <select disabled value={this.state.pID} name="PublisherName" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555' }} onChange={this.onPublisherNameHandleChange}>{/*sunita-task-3486- added css as per DI standard*/}
                                    <option value={"None Selected"} >None Selected</option>
                                    {this.state.publisherList.map((publisherList, i) => (
                                        <option value={publisherList.pID} key={i}>{publisherList.publisherName}</option>))}
                                </select>
                                :
                                <select value={this.state.pID} name="PublisherName" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555' }} onChange={this.onPublisherNameHandleChange}>
                                    <option value={"None Selected"} >None Selected</option>
                                    {this.state.publisherList.map((publisherList, i) => (
                                        <option value={publisherList.pID} key={i}>{publisherList.publisherName}</option>))}
                                </select>

                            }
                        </div>&nbsp;
                        <div class="col-sm-12 col-md-4 col-lg-4" id="fr_mdl" style={{ marginLeft: "50px" }}>
                            <label style={{ fontSize:'14px',color: '#14254A',fontFamily:'sans-serif' }}>Campaign Status&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;</label>
                            {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                            {this.state.reportKey1 === "report" ?
                                <select disabled name="CampaignStatus" id="CampStatDrop" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555'  }}
                                    value={this.state.campaignStatus} required onChange={this.onCampaignStatusHandleChange} >{/*sunita-task-3486- added css as per DI standard*/}
                                    <option value={"None Selected"} >None Selected</option>
                                    <option value="Active">Active</option>
                                    <option value="Paused">Paused</option>
                                    <option value="Completed">Completed</option>
                                </select>
                                :
                                <select name="CampaignStatus" id="CampStatDrop" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555'  }}
                                    value={this.state.campaignStatus} required onChange={this.onCampaignStatusHandleChange} >
                                    <option value={"None Selected"} >None Selected</option>
                                    <option value="Active">Active</option>
                                    <option value="Paused">Paused</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            }
                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div class=" col-sm-12 col-md-3 col-lg-3" id="fr_md2">
                            <label style={{ fontSize:'14px',color: '#14254A',fontFamily:'sans-serif' }}>Timeline&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;</label>
                            {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                            {this.state.reportKey1 === "report" ?
                                <select disabled name="Timeline" style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555' }}
                                    value={this.state.deadline} required onChange={this.ondeadlineHandleChange} >{/*sunita-task-3486- added css as per DI standard*/}


                                    <option value={"None Selected"} >None Selected</option>

                                    <option value="All" lable="All">All</option>
                                    <option value="No Lead Delivered" label="No Lead Delivered">No Lead Delivered</option>
                                    <option value="Ending Three Days" label="Ending 3 days">Ending 3 days</option>
                                    <option value="Weekly" label="Ending This Week">Ending This Week</option>
                                    <option value="Monthly" label="Ending This Month">Ending This Month</option>
                                    <option value="Quarterly" label="Ending This Quarter">Ending This Quarter</option>
                                    <option value="Yearly" label="Ending This Year">Ending This Year</option>
                                    <option value="PreMonth" label="Ending Previous Month">Ending Previous Month</option>
                                    <option value="PreQuarter" label="Ending Previous Quarter">Ending Previous Quarter</option>
                                    <option value="PreYear" label="Ending Previous Year">Ending Previous Year</option>

                                </select>
                                :
                                <select style={{ width: '148px', height: '32px',border:'1px solid #cccccc',borderRadius:'3px',fontFamily:'sans-serif',color:'#555555' }} name="Timeline"
                                    value={this.state.deadline} required onChange={this.ondeadlineHandleChange} >

                                    <option value={"None Selected"} >None Selected</option>

                                    <option value="All" lable="All">All</option>
                                    <option value="No Lead Delivered" label="No Lead Delivered">No Lead Delivered</option>
                                    <option value="Ending Three Days" label="Ending 3 days">Ending 3 days</option>
                                    <option value="Weekly" label="Ending This Week">Ending This Week</option>
                                    <option value="Monthly" label="Ending This Month">Ending This Month</option>
                                    <option value="Quarterly" label="Ending This Quarter">Ending This Quarter</option>
                                    <option value="Yearly" label="Ending This Year">Ending This Year</option>
                                    <option value="PreMonth" label="Ending Previous Month">Ending Previous Month</option>
                                    <option value="PreQuarter" label="Ending Previous Quarter">Ending Previous Quarter</option>
                                    <option value="PreYear" label="Ending Previous Year">Ending Previous Year</option>

                                </select>
                            }
                        </div>
                    </div>
                    {/* <br/> */}
                    <hr />
                    <div style={{ backgroundColor: 'white', marginBottom: '30px' }}>
                        <div style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: '10px' }}
                        >

                            <div>

                                <div>
                                    <label style={{ fontFamily: 'Roboto', fontWeight: '600px', fontSize: '18px', color: '#F28C0F' }}>Delivery Summary :
                                        {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                                        {this.state.deadline !== "None Selected" || this.state.reportKey1 === "report" ?
                                            <span style={{ color: "black", fontSize: "14px" }}>&nbsp;&nbsp;&nbsp;{this.state.label}
                                                {/* //shivani-task 3431-display  "" label for All and No Lead Delivered. */}
                                                {this.state.label === "All" || this.state.label === "No Lead Delivered" ? ""
                                                    : this.state.reportSummary && this.state.reportSummary.map(reportSummary => (
                                                        <span style={{ color: "black", fontSize: "14px" }}>({reportSummary.deliveryLabel})</span>
                                                    ))
                                                }
                                            </span>
                                            : ""}

                                    </label>
                                </div>
                                <hr></hr>
                                {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                                {this.state.reportKey1 === "report" || this.state.generateBtnFlag === true ?
                                    <div>
                                        {this.state.reportSummary && this.state.reportSummary.map(reportSummary => (
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                        Publisher ID&nbsp;:&nbsp;
                </label>
                                                    <span style={{color: "#14254A"}}>{reportSummary.pID}&nbsp;</span>{/*sunita-task-3486- added css as per DI standard*/}
                                                </div>
                                                <div class="col-sm-3 col-lg-4" style={{ marginBottom: "10px" }}>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                        Publisher Name&nbsp;:&nbsp;
                </label>
                                                    <span style={{color: "#14254A"}}>{reportSummary.publisherName}</span>{/*sunita-task-3486- added css as per DI standard*/}
                                                </div>
                                                {/* */}
                                                <div class="col-sm-2">
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>
                                                    Report ID&nbsp;:&nbsp;
                </label>
                                                    <span style={{color: "#14254A"}}>{this.state.reportID}&nbsp;</span>
                                                </div>
                                                <div class="col-sm-3 col-lg-4" style={{ marginBottom: "10px" }}>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                    Report Name&nbsp;:&nbsp;
                </label>
                                                    <span style={{color: "#14254A"}}>{this.state.reportName}</span>
                                                </div>
                                                {/*  Chaitanya-4099-Reportname and ReportID- not getting display once click on the report name link  */}
                                                <div class="col-sm-7 col-lg-6"></div>


                                                <div class="col-sm-12 col-md-12 col-lg-12">
                                                    <hr></hr>
                                                    <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}><b>Total count :</b></label>
                                                </div>

                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>{/*sunita-task-3486- added css as per DI standard*/}
                                                        Allocated Lead&nbsp;:&nbsp;</label>

                                                    <span style={{color:"#414141"}}>{/*sunita-task-3486- added css as per DI standard*/}
                                                        {reportSummary.totalAllocation}
                                                    </span>
                                                </div>

                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Delivered&nbsp;:&nbsp;</label>{/*sunita-task-3486- added css as per DI standard*/}
                                                    <span style={{ color: '#193D8F' }}>{reportSummary.deliveredLead}</span>

                                                </div>
                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Review Pending&nbsp;:&nbsp;</label>{/*sunita-task-3486- added css as per DI standard*/}
                                                    <span style={{ color: '#F28C0F' }}>
                                                        {reportSummary.qaReviewLead}</span>

                                                </div>
                                                <div class="col-sm-2">
                                                    <label id="label" style={{color:"#4F4F4F"}}>Accepted&nbsp;:&nbsp;</label>{/*sunita-task-3486- added css as per DI standard*/}
                                                    <span style={{ color: '#008000' }}>{reportSummary.totalAccepted}</span>

                                                </div>
                                                <div class="col-sm-2" style={{color:"#4F4F4F"}}> {/*sunita-task-3486- added css as per DI standard*/}
                                                    <label id="label">Rejected&nbsp;:&nbsp;</label>
                                                    <span style={{ color: '#FF0000' }}>{reportSummary.rejectedLead}</span>

                                                </div>
                                                <div class="col-sm-2" style={{color:"#4F4F4F"}}>{/*sunita-task-3486- added css as per DI standard*/}
                                                    <label id="label">Balance&nbsp;:&nbsp;</label>
                                                    <span style={{ color: '#4F4F4F' }}>
                                                        {reportSummary.balanceLead}</span>

                                                </div>


                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <div>
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                    Publisher ID&nbsp;:&nbsp;
                </label>
                                                {/* //shivani-task 3431-changed bydefault value of pID. */}
                                                <span style={{color: "#14254A"}}>{0}&nbsp;</span>{/*sunita-task-3486- added css as per DI standard*/}

                                            </div>
                                            <div class="col-sm-3 col-lg-4" style={{ marginBottom: "10px" }}>
                                                <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}>

                                                    Publisher Name&nbsp;:&nbsp;
                </label>

                                                <span style={{color: "#14254A"}}>{"NA"}</span>{/*sunita-task-3486- added css as per DI standard*/}


                                            </div>
                                            <div class="col-sm-7 col-lg-6"></div>

                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <hr></hr>
                                                <label style={{ color: "#14254A", fontFamily: "Roboto", fontSize: "14px" }}><b>Total count :</b></label>
                                            </div>

                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>
                                                    Allocated Lead&nbsp;:&nbsp;</label>

                                                <span style={{color:"#414141"}}>{/*sunita-task-3486- added css as per DI standard*/}
                                                    {0}
                                                </span>

                                            </div>

                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Delivered&nbsp;:&nbsp;</label>
                                                <span style={{ color: '#193D8F' }}>{0}</span>{/*sunita-task-3486- added css as per DI standard*/}

                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Review Pending&nbsp;:&nbsp;</label>
                                                <span style={{ color: '#F28C0F' }}>{0}</span>


                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Accepted&nbsp;:&nbsp;</label>
                                                <span style={{ color: '#008000' }}>{0}</span>{/*sunita-task-3486- added css as per DI standard*/}

                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Rejected&nbsp;:&nbsp;</label>
                                                <span style={{ color: '#FF0000' }}>{0}</span>{/*sunita-task-3486- added css as per DI standard*/}

                                            </div>
                                            <div class="col-sm-2">
                                                <label id="label" style={{color:"#4F4F4F"}}>Balance&nbsp;:&nbsp;</label>{/*sunita-task-3486- added css as per DI standard*/}
                                                <span style={{ color: '#4F4F4F' }}>
                                                    {0}</span>

                                            </div>


                                        </div>
                                        {/* ))}  */}
                                    </div>}

                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3" style={{ marginTop: '16px', marginLeft: '-21px' }}>

                                <span style={{color:"#717171"}}>Showing results    <b style={{color:"#414141"}}>{/*sunita-task-3486- added css as per DI standard*/}
                                    {this.state.agencyMyReportCampaignInfo.length}
                                </b></span>

                            </div>
                        </div>

                    </div>
                    {/*          
            <Loader 

					loadingRef={this.loadingRef}
					closeLoadingRef={this.closeLoadingRef}
					message="Wait a moment while we are loading your data...."
				/>
    */}
                    <div align="center" id={this.state.loader1}></div>
                    <div align="center" id={this.state.loader}></div>

                    <Table {...this.state.tableState}
                        id="myTable tr"
                        bordered
                        // locale={locale}
                        //    rowKey={this.state.reportID}

                        dataSource={this.state.agencyMyReportCampaignInfo}
                        columns={columnsReport}
                        onChange={onChange}
                        className={`${tableCSS} "ant-table-thead"`} //Aman-4797-(changed 2 className in One)-Console Warnings-Agency-Reports Part 2
                        //className="ant-table-thead"
                        //className={tableCSS}
                        scroll={{ x: 1200, y: 1333 }}
                        pagination={{
                            pageSize: this.state.pageSize,
                            position: "bottom",

                        }}>

                    </Table>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6"
                        style={{ marginTop: "-53px", marginLeft: "5px", fontSize: '13px', color: '#4F4F4F', fontFamily: 'sans-serif' }}
                    >
                        Records per page:&nbsp;
												<select
                            defaultValue={this.state.pageSize}
                            onChange={this.handleChangePageSize}
                            id="pacing11"
                            class="input-small"
                            className="form-control"
                            name="pacing"
                            style={{ width: "71px", height: "30px", display: "initial", fontSize: '13px', fontFamily: 'sans-serif', color: '#4F4F4F' }}>
                            <option value="100" selected>
                                100
													</option>
                            <option value="200">200</option>
                            <option value="300">300</option>

                        </select>

                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}
/**
   * @author Shivani Pathak-task 3354
   * @param  Description handle the login authentication
   * @return Description return All details of authentication
   */
agencyPublisherDeliverytracking.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(agencyPublisherDeliverytracking));

