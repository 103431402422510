/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Snehal More
@Creation Date:26-03-2019
@Description:UI for Salesforce Lead  report  under Agency view
*/
import React from "react";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { Table} from 'antd';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Somnath Task-3939, added default header
// import { Table} from 'mdbreact';
import { css } from 'emotion';
import XLSX from 'xlsx';//Nilesh-4855 Add xlsx file download
// const queryString = require('query-string'); //Nilesh-4800-Removing console warnings
const tableCSS = css({
  backgroundColor: 'white',
  borderStyle: 'bold',
  '& thead  > tr': {
    backgroundImage: 'linear-gradient(to right,#28166f,#007cc3) !important',
    color: 'white',
    fontSize:'14px !important',
    fontWeight:'500',
  },
  '& thead > tr >th': {
    border: '1px solid black',
    color: 'white',
  }
});


class SalesforceLeadView extends React.Component {
    constructor() {
        super();
        this.state = {
            salesforceClientLeadDetails:[],
            clientName:'',
            campaignID:'',
            campID:'',
            clientNameDisplay:'none',
            campaignIDDisplay:'none',
            campaignIDDisplay2:'none',
            pageSize2:100,
            tableState:{size:'small'}
            }
    
    this.handleChangePageSize2=this.handleChangePageSize2.bind(this);
    
    }// end of constrcutor
    
    salesforceLeadViewBackButton(e){
      e.preventDefault();  //Nilesh-4800-Removing console warnings
    window.location.href="salesforceLead";
    }
//start of componentWillMount
 componentWillMount(){   
  if(!this.props.auth.isAuthenticated) {
    this.props.history.push('/userLogin');
  }
  else{
    var parsed = this.props.location.state; //Somnath Task-3939-replace query params
	if (parsed === undefined) {
		this.props.history.replace("/salesforceLead");
		return;
	}
    var campID=parsed.campID;
    var clientName=parsed.clientName;
    var pacing=parsed.pacing;
    var successLeadCount=parsed.successLeadCount;
    var failureLeadCount=parsed.failureLeadCount;
    //alert(successLeadCount+"successLeadCount"+"FailureLeadCount"+failureLeadCount)
    // const {isAuthenticated, user} = this.props.auth;
    // var agencyID=user.id;
    let data ={campID:campID,clientName:clientName,pacing:pacing}
    if(successLeadCount != null || successLeadCount !== undefined){ //Nilesh-4800-Removing console warnings
    fetch("/agencyDashboard/salesforceCampaignLeadDetailsSuccess",{
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body:JSON.stringify(data)
    }).then(res=>res.json())
    .then(salesforceCampaignLeadDetailsSuccess=>{
      //alert("salesforceCampaignLeadDetailsSuccess"+JSON.stringify(salesforceCampaignLeadDetailsSuccess))
        this.setState({salesforceClientLeadDetails:salesforceCampaignLeadDetailsSuccess});
    }).catch(function (err) {console.log(err)});    
}
else if(failureLeadCount != null || failureLeadCount !== undefined){ //Nilesh-4800-Removing console warnings
  fetch("/agencyDashboard/salesforceCampaignLeadDetailsFailure",{
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data)
  }).then(res=>res.json())
  .then(salesforceCampaignLeadDetailsFailure=>{
    //alert("salesforceCampaignLeadDetailsFailure"+JSON.stringify(salesforceCampaignLeadDetailsFailure))
      this.setState({salesforceClientLeadDetails:salesforceCampaignLeadDetailsFailure});
  }).catch(function (err) {console.log(err)});    
}

}
}
//handel change for pagination
handleChangePageSize2(e)
{
  var pageSize2=e.target.value;
  this.setState({pageSize2:pageSize2});
}

//Nilesh-4855 Xlsx file download function
handleFileExport = () => {

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  
  // Define custom headers
  const headers = [
    'Camp ID',
    'Client Name',
    'Lead Info ID',
    'Email',
    'First Name',
    'Last Name',
    'Company Name',
    'Job Title',
    'Salesforce ID',
    'Salesforce Lead Status',
    'Salesforce Lead',
  ];


  // Convert your data to the required format
  const formattedData = this.state.salesforceClientLeadDetails.map(item => [
    item.campID,
    item.clientName,
    item.leadInfoID,
    item.email,
    item.firstName,
    item.lastName,
    item.companyName,
    item.jobTitle,
    item.sfID,
    item.sfLeadStatus,
    item.sfLead,
  ]);

  // Combine headers and data
  const sheetData = [headers, ...formattedData];

  // Create a new worksheet with custom headers
  const ws = XLSX.utils.aoa_to_sheet(sheetData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
  XLSX.writeFile(wb, `Salesforce Report-${this.state.clientName}-${this.state.campID}-${this.state.pacing}-${formattedDate}.xlsx`);
};

    render(){
        var parsed = this.props.location.state; //Somnath Task-3939-replace query params
       this.state.campID=parsed.campID;
       this.state.clientName=parsed.clientName;
       this.state.pacing=parsed.pacing;
       this.state.successLeadCount=parsed.successLeadCount;
       this.state.failureLeadCount=parsed.failureLeadCount;
      const Column1=[
        {
            title: 'Lead Info ID',  
            dataIndex: 'leadInfoID',
            key: 'leadInfoID',
            width: '130px',
            align:'center',
        render: (text,record) => 
        <div><span style={{fontSize:'12px'}}>{record.leadInfoID}</span></div>
        },
        {
          title: 'SF ID',
          dataIndex: 'sfID',
          key: 'sfID',
          width: '100px',
          align:'center',
         
      render: (text,record) => 
      <div><span  style={{fontSize:'12px'}}>{record.sfID}</span></div>
      },
      
        {
            title: 'Email',
            dataIndex: 'email',  
            key: 'email',
            align:'center', 
            width:'150px', 
            render: (text,record) => 
            <div>
                <span  style={{fontSize:'12px'}}>{record.email}</span>
            </div>
        },
            {
              title: 'First Name',
              dataIndex: 'firstName',
              key: 'firstName',
              align:'center',  
              width:'100px',
              render: (text,record) => 
              <div >
                  <span  style={{fontSize:'12px'}}>{record.firstName}</span>
              </div>
              },
              {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                align:'center',  
                width:'100px',
                render: (text,record) => 
                <div >
                    <span  style={{fontSize:'12px'}}>{record.lastName}</span>
                </div>
                },
                {
                  title: 'Job Title',
                  dataIndex: 'jobTitle',
                  key: 'jobTitle',
                  align:'center',  
                  width:'100px',
                  
                  render: (text,record) => 
                  <div >
                      <span  style={{fontSize:'12px'}}>{record.jobTitle}</span>
                  </div>
                  },
                  {
                    title: ' Company Name',
                    dataIndex: 'companyName',
                    key: 'companyName',
                    align:'center',  
                    width:'150px',
                    render: (text,record) => 
                    <div >
                        <span  style={{fontSize:'12px'}}>{record.companyName}</span>
                    </div>
                    },
                    {  
                      title: 'Lead Status',
                      dataIndex: 'sfLead',
                      key: 'sfLead',
                      align:'center',  
                      width:'150px',
                      render: (text,record) => 
                      <div >
                          <span  style={{fontSize:'12px'}}>{record.sfLead}</span>
                      </div>
                      },
                    
         
  ];
  const Column2=[
    {
        title: 'Lead Info ID',  
        dataIndex: 'leadInfoID',
        key: 'leadInfoID',
        width: '130px',
        align:'center',
    render: (text,record) => 
    <div><span>{record.leadInfoID}</span></div>
    },
    
  {
    title: ' Campaign SF Error',
    dataIndex: 'sfLeadStatus',
    key: 'sfLeadStatus',
    width: '350px',
    align:'center',
    
    render: (text,record) => 
    <div><span  style={{fontSize:'12px'}}>{record.sfLeadStatus}</span></div>
    },
    {
        title: 'Email',
        dataIndex: 'email',  
        key: 'email',
        align:'center', 
        width:'150px', 
        render: (text,record) => 
        <div>
            <span  style={{fontSize:'12px'}}>{record.email}</span>
        </div>
    },
        {
          title: 'First Name',
          dataIndex: 'firstName',
          key: 'firstName',
          align:'center',  
          width:'100px',
          render: (text,record) => 
          <div >
              <span  style={{fontSize:'12px'}}>{record.firstName}</span>
          </div>
          },
          {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            align:'center',  
            width:'100px',
            render: (text,record) => 
            <div >
                <span  style={{fontSize:'12px'}}>{record.lastName}</span>
            </div>
            },
            {
              title: 'Job Title',
              dataIndex: 'jobTitle',
              key: 'jobTitle',
              align:'center',  
              width:'100px',
              
              render: (text,record) => 
              <div >
                  <span  style={{fontSize:'12px'}}>{record.jobTitle}</span>
              </div>
              },
              {
                title: ' Company Name',
                dataIndex: 'companyName',
                key: 'companyName',
                align:'center',  
                width:'150px',
                render: (text,record) => 
                <div >
                    <span  style={{fontSize:'12px'}}>{record.companyName}</span>
                </div>
                },
                {  
                  title: 'Lead Status',
                  dataIndex: 'sfLead',
                  key: 'sfLead',
                  align:'center',  
                  width:'150px',
                  render: (text,record) => 
                  <div >
                      <span  style={{fontSize:'12px'}}>{record.sfLead}</span>
                  </div>
                  },
                
     
];
  function onChange(pagination, filters, sorter) {
    console.log('params', pagination, filters, sorter);
  }
     return(
                    <div >
                     <Navigation />
                     <div class="container-fluid" style={{ paddingTop: '100px',marginBottom:'30px' }}>
                      <div class="row">
                        {/* kiran- task 3873- back button left aligned */}
                        <div class="col-sm-2"  style={{float:'left',marginLeft:'20px'}}>
                        < a class="col" href='#/' //Nilesh-4800-Removing console warnings
                        style={{color: '#056eb8' }}onClick={this.salesforceLeadViewBackButton.bind(this)} >
                        <FaArrowAltCircleLeft size={32} title="Back to SalesForce Lead upload" className="backFrom_salesforceLead"/>
                        </a>
                        </div>
                        <div class="col-lg-6 col-md-8 col-sm-6" align="center">
                        {this.state.successLeadCount!==undefined? //Nilesh-4800-Removing console warnings
                  <div class="col" align="center" style={{fontSize:"20px",fontWeight:"bold"}}><label id="labelheading"style={{color:'#056eb8'}} >
                    Success Leads of Campaign ID: &nbsp;{this.state.campID}</label></div>
                  :this.state.failureLeadCount!==undefined ? //Nilesh-4800-Removing console warnings
                  <div class="col" align="center" style={{fontSize:"20px",fontWeight:"bold"}}><label id="labelheading"style={{color:'#056eb8'}} >
                  Failure Leads of Campaign ID: &nbsp;{this.state.campID}</label></div>
                  :<div class="col" align="center" style={{fontSize:"20px",fontWeight:"bold"}}><label id="labelheading"style={{color:'#056eb8'}} >
                  Leads of campaignID: &nbsp;{this.state.campID}</label></div>}
                  </div>
                   {/* Nilesh-4855 add download button */}
                   <div
              class="col-sm-12 col-md-3 col-lg-3"
              style={{ textAlign: "right" }}
            >
             { (this.state.salesforceClientLeadDetails.length > 0) ? 
             <button
                class="btn add-button"
                onClick={this.handleFileExport}
              >
                Download
              </button> : 
              <button 
              disabled
              class="btn add-button"
              onClick={this.handleFileExport}
            >
              Download
            </button>}

            </div>
                  <div class="col-lg-1 col-md-1 col-sm-1"></div>
                  </div>
                  </div>
            
 <div className="card card-signin my-1">
                          <div class="row">
                         
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="table-responsive">
                                    {this.state.successLeadCount !==undefined ? //Nilesh-4800-Removing console warnings
                                      <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.salesforceClientLeadDetails}  
         onChange={onChange} columns={Column1} 
        //  className="ant-table-thead" 
         className={`${tableCSS} "ant-table-thead"`} //Nilesh-4800-Removing console warnings
         scroll={{y: 400,x: 1300, }}
        pagination={{ pageSize: this.state.pageSize2 }}></Table>
        : <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.salesforceClientLeadDetails}  
        onChange={onChange} columns={Column2} 
        // className="ant-table-thead"
         className={`${tableCSS} "ant-table-thead"`} //Nilesh-4800-Removing console warnings
          scroll={{y: 400 ,x: 1300,}}
        pagination={{ pageSize: this.state.pageSize2 }}></Table> }
                  <p>
                  <div class="row"style={{ marginBottom:'30px' }}>
                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{marginTop:'-50px'}}>
                  &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                  <select 
                  defaultValue={this.state.pageSize2}
                  onChange={this.handleChangePageSize2}
                  id="pacing1"
                  class="input-small"
                  className="form-control"
                  name="pacing" style={{width:'80px',height:'30px',display: 'initial'}}>
                  <option value="100" selected>100</option>
                  <option value="150" >150</option>
                  <option value="200">200</option>
                  <option value="250">250</option>
                  </select>
                  </div>
                  </div>
                  </p>

                                    </div>                                                                                
                                </div>
                                
                  </div>{/*Card-body */}
</div>{/*Card */}

 <Footer />
</div>
            );
    }// end of renders
}// end of class

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      SalesforceLeadView.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(SalesforceLeadView));

