/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Sanjana Godbole
@Creation Date:12-12-2018
@Description:UI for  allocated campaigns under Agency view
*/

import React from "react";
import { Tabs, Tab } from 'react-bootstrap';
import { Progress } from "mdbreact";
import * as $ from "jquery";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";

import './allocatingCampaign.css';
import IncompleteCampaign from "./incompleteCampaign";
//var pid = [];
//var campID;
class IncompleteCampaignTab extends React.Component {
  constructor() {
    super();
    this.state = {
      key:2,
      fields: {},
      dynamic: [],
      campID: " ",
      id: "",
      leadAllocation: 0,
      allocatedLead: "",
      pendingLead: 0,
      campaignVisibleTime: "",
      campAcceptReject: "",
      campaignDetailAllocates: [],
      campaignDetailAssign: [],
      campaignDetailID: [],
      liveCampaignList: [],
      onClickAllocatedLead: [],
      onClickAllocatedLeadPubList: [],
      errors: {}
    };
    this.count1 = 0;
    this.handleChange = this.handleChange.bind(this);
    this.logChange = this.logChange.bind(this);

  } // end of constructor


  
  componentDidMount() {   

   // alert("ComponentDid mount key====>"+this.state.key);
    if(!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
  }


  }// componentDidMount()
  logChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  
  handleChange(e) {
    // alert("in handle change");
    let fields = this.state;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  } // end of  handleChange(e)

 

  render() {
   /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
     
      const {isAuthenticated, user} = this.props.auth;
      
     
    return (
      <div>
      
        <Navigation />
       
        <div class="container-fluid" style={{paddingTop:'90px',marginBottom:'60px'}}>
       {/* <div className="col" align="center" style={{paddingTop:'90px',marginBottom:'60px'}}> */}
{/* //shivani-3283-passed ID for DI label consistency */}
<div align="center" id="labelDI">Incomplete Campaign
                         </div>
        <Tabs activeKey={this.state.key} onSelect={this.handleSelect}
          id="controlled-tab-example">
            {user.role==="AC"?
            // <Tab eventKey={2} title=" Incomplete Campaign"> 
            <Tab eventKey={2}> 
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">

              {this.state.key==2?
                 <IncompleteCampaign /> 
              :""}
              </div>
               </Tab>:''}
             
      </Tabs>
        {/* </div>  */}
         </div>
        <Footer />
        
        
      </div>// first div
    ); // end of return
  } // end of render
} // end of  class AllocatingCampaign
/**
       * @author Supriya Gore
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      IncompleteCampaignTab.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(IncompleteCampaignTab)); 

