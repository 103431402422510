
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Sanobar Golandaj
@Creation Date:12-09-2019
@Description:UI for (Dashboard) campaign specification
*/

import React,{ Component } from 'react'
import Navigation from '../layouts/navPage';
import Footer from '../layouts/footer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
//import CampaignSpecificationDashboardDetails from "./campaignSpecificationDashboardDetails"; //Rutuja 2978 commented as never used
//import { FaArrowAltCircleLeft } from "react-icons/fa"; //Rutuja 2978 commented as never used
import CampaignSetupSpecificationDetails from './campaignsetupSpecificationDetails';

//var greeting=""; //Rutuja 2978 commented as never used
class CampaignSetupSpecification extends Component{

    constructor(props) {
        
       
        super(props);
        this.state = {
              campID: '',
              parentCampID:'',
              agencyAllocationID:'',
              agencyID:'',
        }
        

    }

    componentDidMount() {
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
          }  
        else{
            var parsed = this.props.location.state; //Somnath Task-4035, replace query params
			if (parsed === undefined) {
				this.props.history.replace("/campaignList");
				return;
			}
      var advCampID=parsed.advCampID;
      const { user} = this.props.auth;
       advCampID=user.id; //Rutuja 2978 removed declaration of var as already declared
      //var agencyAllocationID=parsed.agencyAllocationID; //Rutuja 2978 commented as never used
      
      this.setState({ advCampID: advCampID})
        }
    }
    
    render()
    {

    var parsed = this.props.location.state; //Somnath Task-4035, replace query params
    this.state.advCampID=parsed.advCampID;
    this.state.advCampaignName=parsed.advCampaignName ;

   this.state.agencyAllocationID=parsed.agencyAllocationID ;

     return(
            <div>
           
           <Navigation/>
           <div style={{paddingTop:'110px'}}>
          
            <CampaignSetupSpecificationDetails  greeting={this.state.advCampID+ ''+this.state.agencyAllocationID}/>
           </div>
           <Footer/>
            </div>





               
           
        )
    }
};

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      CampaignSetupSpecification.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(CampaignSetupSpecification)); 
