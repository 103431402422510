/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Shende
 *@fileName :addUserAgency.js
 *Desc: Add User
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import {
  //Form, // Nilesh-2833-Removing console warnings
  //Input, // Nilesh-2833-Removing console warnings
  Tooltip,
  Icon,
  Table,
  // Cascader, // Nilesh-2833-Removing console warnings
  // Select, // Nilesh-2833-Removing console warnings
  // Row, // Nilesh-2833-Removing console warnings
  // Col, // Nilesh-2833-Removing console warnings
  // Checkbox, // Nilesh-2833-Removing console warnings
  // Button, // Nilesh-2833-Removing console warnings
  // AutoComplete, // Nilesh-2833-Removing console warnings
  // Card, // Nilesh-2833-Removing console warnings
} from "antd";
// import { ThemeConsumer } from "styled-components"; // Nilesh-2833-Removing console warnings
import { css } from "emotion"; //Sandeep-task-3447-added css file
import "./addUserAgency.css"; //Sandeep-task-3447-added css file
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
const Swal = require("sweetalert2");
const tableCSS = css({
  backgroundColor: "white",
  fontFamily: "sans-serif",
  fontSize: "20px",
  borderStyle: "bold",
  "& thead > tr > th": {
    backgroundColor: "#144999", //rutuja task -4524 changed background color
    color: "white",
    fontWeight: "500",
    // wordWrap:"break-word",
    // width:'10%'
  },
  "& thead > tr": {
    borderWidth: "2px",
    borderStyle: "solid",
  },
}); //Sandeep-task-3447-added Tablecss file

class AddUserAgency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputClass11: "btn add-button",
      fields: {},
      errors: "",
      loader2: "",
      userDomain: "",
      selectedUserDomain: "@machbizz.com", //Sanjana-3192-For default domain in select dropdown in case user login with "machbizz.com"
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.dropDownChange = this.dropDownChange.bind(this); //Sanjana-3192-handleChange function for dropdown in case user login with "machbizz.com"
  } // end of constructor
  componentDidMount() {
    // alert("inside didmount");
    // const { isAuthenticated, user } = this.props.auth; // Nilesh-2833-Removing console warnings
    const { user } = this.props.auth;
    // let orgID = user.id; // Nilesh-2833-Removing console warnings
    var userName = user.name;
    userName = userName.split("@");
    var userName1 = "@" + userName[1];
    /* @author Sandeep Dhawale
     * @param  Description get user details
     * @return Description get user details
     */

    this.setState({ loader2: "loader_report1" });

    fetch("/users/getUserList", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }) // karan-task-3723-vapt header and query params
      .then((res) => res.json())
      .then((getUserList) => {
        this.setState({ getUserList: getUserList, loader2: "" });
      })
      .catch(function (err) {
        console.log(err);
      });
    // alert("userName1"+userName1)
    //Sanjana-3192-condition to display single/multiple domain suggestion in case user login with "machbizz.com"
    if (userName[1] === "machbizz.com" || userName[1] === "machbizz.co.in") {
      const selectAfter = (
        <select
          onChange={this.dropDownChange}
          style={{ backgroundColor: "#fafafa", border: "0px solid #d9d9d9" }}
        >
          <option selected name=".machbizz.com" value="@machbizz.com">
            @machbizz.com
          </option>
          <option name=".machbizz.co.in" value="@machbizz.co.in">
            @machbizz.co.in
          </option>
        </select>
      );
      this.setState({ userDomain: selectAfter });
    } else {
      this.setState({ userDomain: userName1 });
    }
  } //end of componentDidMount

  //Sanjana-3192-handleChange function for dropdown in case user login with "machbizz.com"
  dropDownChange(e) {
    // const { name, value } = e.target; // Nilesh-2833-Removing console warnings
    const { value } = e.target;

    this.setState({ selectedUserDomain: value });
  }
  validateForm() {
    // alert("inside validate form");
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    let spanDisplay = "none";
    // let requiredFeildValidate = "true";
    let requiredFeildValidate = true; //Nilesh-2833-Removing console warnings
    // let userNameValidate = "true";
    let userNameValidate = true; //Nilesh-2833-Removing console warnings
    // let firstNameValidate = "true";
    let firstNameValidate = true; //Nilesh-2833-Removing console warnings
    // let lastNameValidate = "true";
    let lastNameValidate = true; //Nilesh-2833-Removing console warnings
    // let roleValidate = "true";
    let roleValidate = true; //Nilesh-2833-Removing console warnings
    //Sanjana-3192-condition added in validation when user in case user login with "machbizz.com"
    if (
      this.state.selectedUserDomain === "" ||
      this.state.selectedUserDomain === "null" ||
      this.state.selectedUserDomain == null
    ) {
      fields["userName"] = fields["userName"] + this.state.userDomain;
    } else {
      fields["userName"] = fields["userName"] + this.state.selectedUserDomain;
    }
    this.setState({ fields });

    if (
      !fields["userName"] &&
      // !userDomain["userDomain"]&&
      !fields["role"] &&
      !fields["firstName"] &&
      !fields["lastName"]
    ) {
      requiredFeildValidate = false;
      errors["commonError"] = <li>Please fill below required fields</li>;
      this.setState({ errors: errors });
    } else {
      if (
        //Nilesh-2833-Removing console warnings
        this.state.userName === "" ||
        this.state.userName == null ||
        this.state.userName === undefined
      ) {
        userNameValidate = false;
        //errors["userName"] = <li>Please enter user name</li>;
        errors["userName"] = <li>Please enter user name</li>;
        this.setState({ errors: errors });
      } else {
        //shivani - 3599 - changed RegEx as per validation requirement for hyphen .
        // var pattern = new RegExp(/^([a-zA-Z]+[\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$/)
        //sunita-task-3782-below regex accept only business domains.
        //Sandeep-task-3893-Production issue-Email format issue--All roles
        var pattern = new RegExp(
          // eslint-disable-next-line no-useless-escape
          /^\s*$|^[\.a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@(?!gmail.com)(?!gmail.co.in)(?!yahoo.com)(?!yahoo.co.in)(?!facebook.com)(?!facebook.co.in)[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/
        ); //mufiz 4091

        if (this.state.userName.match(pattern)) {
          // mufiz Task-3984 Settings - Add User - Username should accept EmailID as per tooltip
          userNameValidate = true;
        } else {
          userNameValidate = false;
          errors["userName"] = <li>Please enter valid user name</li>;
          this.setState({ errors: errors });
        } // mufiz Task-3984 Settings - Add User - Username should accept EmailID as per tooltip
      }

      if (
        //Nilesh-2833-Removing console warnings
        this.state.firstName === "" ||
        this.state.firstName == null ||
        this.state.firstName === undefined
      ) {
        firstNameValidate = false;
        errors["firstName"] = <li>Please enter first name</li>;
        this.setState({ errors: errors });
      } else {
        let pattern = new RegExp(/^[a-zA-Z]+$/); // Nilesh-2833-Removing console warnings
        if (!pattern.test(fields["firstName"])) {
          userNameValidate = false;
          errors["firstName"] = <li>please enter valid first name</li>;
          this.setState({ errors: errors });
        } else {
          errors["firstName"] = "";
          this.setState({ errors: errors });
        }
      }
      if (
        //Nilesh-2833-Removing console warnings
        this.state.lastName === "" ||
        this.state.lastName == null ||
        this.state.lastName === undefined
      ) {
        lastNameValidate = false;
        errors["lastName"] = <li>Please enter last name</li>;
        this.setState({ errors: errors });
      } else {
        let pattern = new RegExp(/^[a-zA-Z]+$/); // Nilesh-2833-Removing console warnings
        if (!pattern.test(fields["lastName"])) {
          userNameValidate = false;
          errors["lastName"] = <li>please enter valid last name</li>;
          this.setState({ errors: errors });
        } else {
          errors["lastName"] = "";
          this.setState({ errors: errors });
        }
      }
      if (
        //Nilesh-2833-Removing console warnings
        this.state.role === "" ||
        this.state.role == null ||
        this.state.role === undefined
      ) {
        roleValidate = false;
        errors["role"] = <li>Please enter role</li>;
        this.setState({ errors: errors });
      } else {
        errors["role"] = "";
        this.setState({ errors: errors });
      }
    }

    if (
      requiredFeildValidate === false ||
      userNameValidate === false ||
      firstNameValidate === false ||
      lastNameValidate === false ||
      roleValidate === false
    ) {
      formIsValid = false;
      this.setState({
        errors: errors,
        alertBorder: "1px solid red",
        alertWidth: "1000px",
        alertAlign: "center",
        alertDisplay: "block",
        spanSpaceDisplay: spanDisplay,
      });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else if (
      requiredFeildValidate === true || //// mufiz Task-3984 Settings - Add User - Username should accept EmailID as per tooltip
      userNameValidate === true ||
      firstNameValidate === true ||
      lastNameValidate === true ||
      roleValidate === true
    ) {
      formIsValid = true;
      // alert("success");
    }
    return formIsValid;
  }

  handleChange(e) {
    // alert("inside handle change");
    let fields = this.state;
    // alert("fields=>"+this.state);

    fields[e.target.name] = e.target.value;
    // alert("fields=>"+e.target.name);
    this.setState({ fields });
  }
  //Rutuja-5065-function for Active status route call
  changeUserStatusActive(e, record) {
    var orgID = record.userID;
    let data = {
      orgID: orgID,
    };
    fetch("/users/setUserStatusActiveDIQA", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: "User Activated",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        }
        this.setState({ isDisabled: true });
      });
  }

  //Supriya: Submit User
  submitUser(e) {
    // alert("inside submituser");
    // const { isAuthenticated, user } = this.props.auth; // Nilesh-2833-Removing console warnings
    //Sanjana-3192-condition added to generate final data  when user in case user login with "machbizz.com"/ with "apssmedia.com"
    let data;

    //sunita-task-3782-changed below condition as per requirement
    if (this.state.userDomain) {
      data = {
        // userName: this.state.userName + this.state.userDomain,
        userName: this.state.userName,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        role: this.state.role,
      }; // karan-task-3723-vapt header and query params
    } else {
      data = {
        userName: this.state.userName + this.state.selectedUserDomain,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        role: this.state.role,
      }; // karan-task-3723-vapt header and query params
    }

    if (this.validateForm() === false) {
      return;
    } else {
      let errors = {};
      fetch("/users/addUserDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((userDetails) => {
          if (userDetails.length > 0) {
            errors["sameUser"] = <li>User Already Exists</li>;
            this.setState({ errors: errors });
          } else {
            fetch("/users/addUser", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            })
              .then(function (response) {
                if (response.status >= 400) {
                  throw new Error("Bad response from server");
                }
                return response.json();
              })
              .then(function (response) {
                // success message with response
                if (response.success === true) {
                  //Nilesh-2833-Removing console warnings
                  Swal.fire({
                    text: "User Added Successfully",
                    type: "success",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    preConfirm: () => {
                      //sunita-task-3764-commented below code as per naren sir suggestion.
                      // this.props.history.push("/newdashboard", {
                      // 	agencyID: user.id
                      // }
                      // ); //karan-task-3684-replace query params
                      window.location.reload(); //sunita-task-3764-added code for refreshing the page.
                    },
                  });
                } else {
                }
              });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  //kiran-4596-function for route call
  changeUserStatusInactive(e, record) {
    // const {name, value } = e.target; // Nilesh-2833-Removing console warnings
    var orgID = record.userID;
    let data = {
      orgID: orgID,
    };
    fetch("/users/setUserStatusInactive", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: "User Deactivated",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        }
        this.setState({ isDisabled: true });
      });
  }

  render() {
    const asterisk = {
      color: "red",
    };
    //Sandeep-task-3447-added table to get user details
    const addUserAgency = [
      {
        title: "ID",
        dataIndex: "ID",
        key: "ID",
        width: 40,
        align: "left",
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "14px" }}>{record.userID}</span>
          </div>
        ),
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
        width: 90,
        align: "left",
        render: (text, record) => (
          <div>
            <div style={{ fontSize: "14px", width: "110px" }}>
              {record.firstName}
            </div>
          </div>
        ),
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
        width: 90,
        align: "left",
        render: (text, record) => (
          <div>
            <div class="word-wrap" style={{ fontSize: "14px" }}>
              {record.lastName}
            </div>
          </div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 150,
        align: "left",
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "14px" }}>{record.userName}</span>
          </div>
        ),
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        width: 60,
        align: "Center",
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "14px" }}>{record.role}</span>
          </div>
        ),
      },
      //kiran-4596-added status and action column
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 40,
        align: "Center",
        render: (text, record) => (
          <div>
            {record.status === "N" ? (
              <span style={{ fontSize: "14px" }}>Deactivated</span>
            ) : (
              <span style={{ fontSize: "14px" }}>Active</span>
            )}
          </div>
        ),
      },

      {
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        width: 40,
        align: "Center",
        render: (text, record) => (
          <div>
            {/* Aman-4961-added record.status === "" to AC user with empty user status can Deactive user */}
            {record.status === "Y" ||
            record.status === null ||
            record.status === "" ? (
              // Chaitanya-5088-change deactivate button alignment
              <button
                style={{ padding: "7px 7px", margin: "auto" }}
                className={this.state.inputClass11}
                onClick={(e) => this.changeUserStatusInactive(e, record)}
                // disabled={this.state.isDisabled}
                type="submit"
              >
                Deactive{" "}
              </button>
            ) : (
              //Rutuja-5065-change button to activate login
              <button
                style={{ padding: "7px 7px", margin: "auto" }}
                className={this.state.inputClass11}
                onClick={(e) => this.changeUserStatusActive(e, record)}
                //disabled='disabled'
                type="submit"
              >
                Activate{" "}
              </button>
            )}
          </div>
        ),
      },
    ];
    return (
      <div>
        <Navigation />
        <div class="container-fluid" style={{ paddingTop: "108px" }}>
          <div class="row">
            {/*mufiz-task-397 add new back button
             */}
            <div class="col-lg-4" id="AddUser" style={{ paddingLeft: "20px" }}>
              <a href="./newdashboard" style={{ color: "#056eb8" }}>
                <FaArrowAltCircleLeft size={32} style={{ float: "left" }} />
              </a>
            </div>
          </div>
          <h2
            align="center"
            id="labelDI"
            class="addUsr"
            style={{ marginTop: "-31px" }}
          >
            Add User Details
          </h2>

          <div
            class="card-body"
            style={{
              margin: "10px",
              paddingBottom: "40px",
              backgroundColor: "#fff",
              borderRadius: "4px",
              boxShadow: "grey 1px 1px 6px 0px", //mufiz-task-3975 styling a box
            }}
          >
            {/* <legend className="legend labelsubheading">User Details</legend> */}
            {/* //shivani-3284-passed ID for DI label consistency */}

            <div style={{ fontSize: "14px", color: "red" }}>
              {this.state.errors.commonError}
            </div>
            <div
              className="errorMsg"
              style={{ fontSize: "14px", color: "red" }}
            >
              {this.state.errors.userName}
              {this.state.errors.role}
              {this.state.errors.firstName}
              {this.state.errors.lastName}
              {this.state.errors.sameUser}
            </div>
            {/* //3975-UI/UX Findings-Add User Details Screen-Mufiz- */}
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ">
                <label id="label">
                  User Name{" "}
                  <span style={asterisk}>
                    *&nbsp;
                    <Tooltip title=" User name should be Valid Email Id.">
                      <Icon
                        type="question-circle-o"
                        className="iconbackground"
                      />
                      {/*sunita-task-3818-added classname as per requirement for removing white patches */}
                    </Tooltip>
                  </span>
                </label>
                <br />

                <input
                  value={this.state.userName}
                  onChange={this.handleChange}
                  type="text"
                  id="userNameNew"
                  className="form-control"
                  name="userName"
                  style={{ width: "60%", height: "60%" }}
                  required
                />
                {/* kiran- bug 3911- added new changes as per requirement in username  */}
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 roleinputbox">
                {" "}
                {/*sunita-task-3782-added classname as per requirement */}
                <label id="label">
                  Role <span style={asterisk}>*</span>
                </label>
                <select
                  value={this.state.role}
                  onChange={this.handleChange}
                  type="text"
                  id="role"
                  className="form-control"
                  name="role"
                  style={{ width: "60%", height: "60%" }}
                  required
                >
                  <option value="None Selected" disabled selected>
                    None Selected
                  </option>
                  <option value="AC">Agency Commercial</option>
                  <option value="ANC">Agency Non-Commercial</option>
                  <option value="AC-ACCOUNT">Accounting</option>
                  <option value="AQA">Quality Assurance</option>
                </select>
              </div>
            </div>
            {/* end of 1st row */}
            <br />
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <label id="label">
                  First Name <span style={asterisk}>*</span>
                </label>
                <input
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  type="text"
                  id="firstName"
                  className="form-control"
                  name="firstName"
                  style={{ width: "60%", height: "33px" }}
                  required
                />
              </div>

              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 roleinputbox">
                <label id="label">
                  Last Name <span style={asterisk}>*</span>
                </label>
                <input
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  type="text"
                  id="lastName"
                  className="form-control"
                  name="lastName"
                  style={{ width: "60%", height: "33px" }}
                  required
                />
              </div>
            </div>
            {/* end of 2nd row */}
            <br />

            <button
              class="btn add-button"
              style={{ float: "right", marginTop: "-6px" }} //mufiz-task-3975 chang location from left to right
              className={this.state.inputClass11}
              onClick={this.submitUser}
              type="submit"
            >
              Save {/*Mufiz-Bug-3910  */}
            </button>
          </div>
          {/* end of card body */}
        </div>
        {/* end of container-fluid */}
        <br />
        <div id={this.state.loader2} />
        <p>
          {" "}
          <Table
            {...this.state.tableState}
            // id="myTable" //rutuja task-4524 table responsive issue
            bordered
            dataSource={this.state.getUserList}
            rowKey="campID"
            columns={addUserAgency}
            // class="ant-table-thead" //Nilesh-2833-Removing console warnings
            // className={tableCSS}
            className={`${tableCSS} "ant-table-thead"`}
            scroll={{ x: 1200, y: 1333 }}
          />
        </p>
        <br />

        <Footer />
      </div>
    );
  } // end of render
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AddUserAgency.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(AddUserAgency)
);
