/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Somnath  Keswad
@Creation Date:23-09-2019
@Description:UI for Creative Upload fom Dashboard
*/

import React, { Component } from "react";
import * as $ from "jquery";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
// import { Button, Icon, Tooltip, Input } from "antd"; //Nilesh-4240-Removing console warnings
import PublisherNavigation from "../layouts/publisherNavPage";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //sonali-task-3945-added default header
import "./publisherCreativeUploads.css";	//chaitanya-task-4107-Publisherside- Review - Creatives review
// const queryString = require("query-string"); //Nilesh-4240-Removing console warnings

var Filecs = false;
var Filepoc = false;
var result = [];
var displayresult = [];
var uploadCS = false;
var uploadCSBE = true;
var uploadPOC = false;
var uploadPOCBE = true;
var lpsubmitLink = false;
var files = 0; //Rutuja task -4889 Declared files to 0 It will increase when Newsletter uploaded and it will decrease when newsletter deleted 
var files1 = 0; ////Rutuja task -4921 Declared files to 0 It will increase when Newsletter uploaded and it will decrease when newsletter deleted 
const Swal = require("sweetalert2");
class PublisherCreativeUploads extends Component {
	constructor() {
		super();
		this.state = {
			pID: "",
			tableStatus: "true",
			alertDisplay: "none",
			pid: "",
			PocFilename: "",
			CallscriptFilename: "",
			POCSubmissionPopUP: [],
			lpSubmissionData: [],
			LPLInk: "",
			LP_POC_CS_SubmissionPopUP: [],
			newDynamicArray: [],
			pocArray: [],
			csArray: [],
			fields: {},
			minerror: {},
			newsuccess: "",
			cserror: "",
			pocerror: "",
			campaignDetails: [],
			marketingChannel: "",
			failure: "",
			parentCampID: "",
			reallocationID: "",
			lpExt: "",
			lpFilename: "",
			pocExt: "",
			pocFileFeedbackName: "",
			csExt: "",
			csFileFeedbackName: "",
			downloadLPFiles: [],
			downloadPOCFeedbackFiles: [],
			downloadCSFeedbackFiles: [],
			alertBorder: "",
			Step: "", //if visits the page from dashboard then will be back redirected to dashboard
		};

		this.handleChangeDynamicLpInsert =
		this.handleChangeDynamicLpInsert.bind(this);
		this.submitLandingPage1 = this.submitLandingPage1.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.deletePOCFile = this.deletePOCFile.bind(this);
		this.deleteCSFile = this.deleteCSFile.bind(this);
		this.downloadLPFeedbackFile = this.downloadLPFeedbackFile.bind(this);
		this.downloadPOCFeedbackFile = this.downloadPOCFeedbackFile.bind(this);
		this.downloadCSFeedbackFile = this.downloadCSFeedbackFile.bind(this);
	}

	handleChangeUploadPOCFile(i, e) {
		e.preventDefault();
			var campID=this.state.campID;
			// var pID=this.state.pID;  //Nilesh-4240-Removing console warnings
		// }
		// else{					
		// var parsed = queryString.parse(this.props.location.search);
		//  pID = parsed.pID;
		// campID = parsed.campID;

		// var pID = window.location.href.split("=")[window.location.href.split("?").length - 1];
		//var parsed = queryString.parse(this.props.location.search);
		// var pID = this.props.location.state.pID;  //Nilesh-4240-Removing console warnings
	    campID = this.props.location.state.campID;  //Nilesh-4240-Removing console warnings
		var supportDocID = e.target.id;
		// var nofileSelected = document.getElementById('fileName'+supportDocID);
		if (
			document.getElementById("fileName" + supportDocID) != null ||
			document.getElementById("fileName" + supportDocID) != undefined
		) {
			document.getElementById("fileName" + supportDocID).innerHTML =
				""; /**** this is making empty for hide the message No File Chosen because of in dynamic delete function it assign value No File Chosen */
		}
		const { name, value } = e.target;
		let pocArray = [...this.state.pocArray];
		pocArray[i] = { ...pocArray[i], [name]: value };
		this.setState({ pocArray });
		var names = [];
		let filesData = e.target.files;
		if (filesData.length > 0) {
			var filePoc = filesData[0].name;
			this.setState({ filePoc: filePoc });
			let file = e.target.files[0];
			console.warn("data files", filesData);
			var FileExt;
			for (let i = 0, l = filesData.length; i < l; i++) {  //Nilesh-4240-Removing console warnings
				names[i] = filesData[i].name;
				FileExt = names[i].split(".").pop();
			}

			/**
			 * @author Sanjana Godbole
			 * @param  A FileList object that lists every selected file
			 * @return List of uploded file along with remove link
			 */
			let pocerror = {};
			var link;
			var filename = "";
			var arrayLength = names.length;
			var nooutput = document.getElementById("pocList" + supportDocID);

			if (arrayLength > 0) {
				var allowedFiles = [".ppt", ".doc", ".pdf", ".docx", ".pptx"];
				//Nilesh-4240-Removing console warnings
				if (
					FileExt === "doc" ||
					FileExt === "ppt" ||
					FileExt === "pdf" ||
					FileExt === "docx" ||
					FileExt === "pptx" ||
					FileExt === "DOC" ||
					FileExt === "PPT" ||
					FileExt === "PDF" ||
					FileExt === "DOCX" ||
					FileExt === "PPTX"
				) {
					Filepoc = true;
					files += arrayLength //Rutuja 4889 increased File counter when newsletter uploaded
					// alert(files)
					
					nooutput.innerHTML = arrayLength + "  File Selected";
					var HTML = "<table>";

					for (var j = 0; j < arrayLength; j++) {
						link =
							'<a class="removeFilePOC" href="#" data-fileid=' +
							'"' +
							names[j] +
							'"' +
							'><i class="fa fa-times"></i></a>';

						HTML +=
							"<tr><td>" +
							link +
							"&nbsp;&nbsp;&nbsp;&nbsp;" +
							names[j] +
							"</td></tr>";
					}
					HTML += "</table>";
					document.getElementById("abmList" + supportDocID).innerHTML = HTML;
					$(document).on("click", ".removeFilePOC", function (e) {
						e.preventDefault();
						var clickedFile = $(this).parent().children("a").data("fileid");
						var j = 0;
						for (j; j < names.length; j++) {
							var temp = j;

							if (names[temp] === clickedFile) {
								filename = names[temp];
								names.splice(j, 1);
								$(this).parent().remove();
								arrayLength = names.length;

								/**
								 * @author Narendra Phadke
								 * @param  Delete File From Database
								 */
								// var typeOfSuppDoc = "ABM";  //Nilesh-4240-Removing console warnings
								let data = {
									// campID: this.props.location.state.campID,
									campID: campID,   //Nilesh-4885-Remove this.props.location.state.campID
									supportDocID: supportDocID,
									//pID: pID, //sonali-3945-removing pID 
								};

								document.getElementById(supportDocID).value = "";
								fetch("publisher/pocDelete", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								}).catch(function (err) {
									console.log(err);
								});
								files = files - 1; //Rutuja 4889 decresed File counter when newsletter deleted
								
								if (arrayLength > 0) {
									nooutput.innerHTML = arrayLength + "  File Selected";
								} else {
									nooutput.innerHTML = " No File Chosen";
									$("#abm1").val("");
									var message = document.getElementById(
										"pocFileMessage" + supportDocID
									);
									message.innerHTML = "";
							 //Rutuj task - 4889 after deleting file marking uploadPOC false
								if (files == 0){
								Filepoc = false;
								uploadPOC = false; //Rutuja task - 4889 after deleting file checking array if array length is 0 then Marked filepoc false
									}
								}
							} // end of  if(names[temp] === clickedFile)
						} // end of for
					}); //$(document).on

					var data = new FormData();
					data.append("campID", campID);
					data.append("file", file);
					//data.append("pID", pID);//sonali-3945-removing pID
					data.append("supportDocID", supportDocID);
					// data.append("suppDocName",this.state.POCSubmissionPopUP[0].suppDocName);
					// data.append("clientCampID",this.state.POCSubmissionPopUP[0].clientCampID);
					// console.warn("newData",JSON.stringify(filesData));

					//Sonali-4032-adding this route 
					fetch("publisher/uploadPOCFILE", {
						// ha api cha response khe yet nheye
						method: "POST",
						body: data,
					})
						.then(function (response) {
							if (response.status >= 400) {
								throw new Error("Bad response from server");
							}
							return response.json();
						})
						.then(function (response) {
							// ha bagh ha response tya mule if  if (arrayLength>0) execute hot nheye and uploadPOC la true milat nheye arrayLength 1 yete
						
							var msg = "Newsletter File Uploaded Successfully";
							var message = document.getElementById(
								"pocFileMessage" + supportDocID
							);

							if (arrayLength > 0) {
								message.innerHTML = msg;
								uploadPOC = true;
							} else {
								message.innerHTML = "";
								uploadPOC = false;
								uploadPOCBE = false;
							}

							//this.setState({uploadPOC:uploadPOC,uploadPOCBE:uploadPOCBE });
						})
						.catch(function (err) {
							console.log(err);
						});

					this.setState({
						files: data,
					});
					var error = "pocerr:" + supportDocID;

					pocerror[error] = "";
					this.setState({
						pocerror: pocerror,
					});
				} else {
					error = "pocerr:" + supportDocID;  //Nilesh-4240-Removing console warnings
					pocerror[error] =
						"Please upload files having extensions: " +
						allowedFiles.join(", ") +
						" only";
					this.setState({
						pocerror: pocerror,
					});
				}
			} else {
				nooutput.innerHTML = " No File Chosen";
			}
		}
	}

	/**
	 * @author Supriya Gore
	 * @param  Description download LP files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	handleLPFileDownload(e) {
		e.preventDefault()
				//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
				var campID=this.state.campID;
				// var pID=this.state.pID;  //Nilesh-4240-Removing console warnings
				
				

		var supportDocID = e.target.getAttribute("supportDocID");

		var lpFeedbackFileName = e.target.getAttribute("lpFeedbackFileName");
		var ext = lpFeedbackFileName.split(".").pop();
		this.setState({ lpExt: ext, lpFilename: lpFeedbackFileName });
		//sonali-3945-removing pID from following call
		fetch(
			"/publisher/lpFeedbackFileDownload?campID=" +
				campID +
				"&supportDocID=" +
				supportDocID
		)
			.then((r) => r.blob())
			.then((downloadLPFiles) => {
				this.setState({ downloadLPFiles: downloadLPFiles });
			})

			.then(this.downloadLPFeedbackFile);
	} /*** End of handleLPFileDownload */

	/**
	 * @author Supriya Gore
	 * @param  Description download LP files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	downloadLPFeedbackFile(blob) {
		/***** If POC File is PDF */
		if (this.state.lpExt === "pdf") {   //Nilesh-4240-Removing console warnings
			var url = this.state.lpFilename;
			var fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pdfFileDownloadName = fileName;
			var newBlob = new Blob([this.state.downloadLPFiles], {
				type: "application/pdf",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = pdfFileDownloadName;
			link.click();
		}
		if (this.state.lpExt === "ppt" || this.state.lpExt === "pptx") {  //Nilesh-4240-Removing console warnings
			url = this.state.lpFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4240-Removing console warnings
			let pptFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadLPFiles], {  //Nilesh-4240-Removing console warnings
				type: "application/vnd.ms-powerpoint",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = pptFileDownloadName;
			link.click();
		}

		if (this.state.lpExt === "txt") {  //Nilesh-4240-Removing console warnings
			url = this.state.lpFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4240-Removing console warnings
			let textFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadLPFiles], {  //Nilesh-4240-Removing console warnings
				type: "text/plain",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = textFileDownloadName;
			link.click();
		}
		if (this.state.lpExt === "docx") {  //Nilesh-4240-Removing console warnings
			url = this.state.lpFilename; //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4240-Removing console warnings
			let docFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadLPFiles], {  //Nilesh-4240-Removing console warnings
				type: "application/msword",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
        //Nilesh-4240-Removing console warnings
		if (
			this.state.lpExt === "png" ||
			this.state.lpExt === "jpeg" ||
			this.state.lpExt === "jpg" ||
			this.state.lpExt === "PNG" ||
			this.state.lpExt === "JPEG" ||
			this.state.lpExt === "JPG"
		) {
			url = this.state.lpFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4240-Removing console warnings
			let pngFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadLPFiles], {  //Nilesh-4240-Removing console warnings
				type: "application/Image",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = pngFileDownloadName;
			link.click();
		}
	} /**** End of downloadLPFeedbackFile */

	/**
	 * @author Supriya Gore
	 * @param  Description download POC files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	handlePOCFeedbackFileDownload(e) {
		e.preventDefault()
		//Nilesh-4240-Removing console warnings
		// var pubID = this.props.location.state;//Somnath Task-3955, VAPT--publisher side--Home--URL links And Routes-FE
		var campID=this.state.campID;
		// var pID=this.state.pID; //Nilesh-4240-Removing console warnings
		var supportDocID = e.target.getAttribute("supportDocID");
		var pocFeedbackFileName = e.target.getAttribute("pocFeedbackFileName");
		var ext = pocFeedbackFileName.split(".").pop();
		this.setState({ pocExt: ext, pocFeedbackFilename: pocFeedbackFileName });
		//sonali-3945-removing pID from the following call
		fetch(
			"/publisher/pocFeedbackFileDownload?campID=" +
				campID +
				"&supportDocID=" +
				supportDocID
		)
			.then((r) => r.blob())
			.then((downloadPOCFeedbackFiles) => {
				this.setState({ downloadPOCFeedbackFiles: downloadPOCFeedbackFiles });
			})

			.then(this.downloadPOCFeedbackFile);
	} /*** End of handlePOCFileDownload */

	/**
	 * @author Supriya Gore
	 * @param  Description download POC files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	downloadPOCFeedbackFile(blob) {
		/***** If POC File is PDF */
		if (this.state.pocExt === "pdf") {  //Nilesh-4240-Removing console warnings
			var url = this.state.pocFeedbackFilename;
			var fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pdfFileDownloadName = fileName;
			var newBlob = new Blob([this.state.downloadPOCFeedbackFiles], {
				type: "application/pdf",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = pdfFileDownloadName;
			link.click();
		}
		if (this.state.pocExt === "ppt" || this.state.pocExt === "pptx") { //Nilesh-4240-Removing console warnings
			url = this.state.pocFeedbackFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4240-Removing console warnings
			let pptFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadPOCFeedbackFiles], {  //Nilesh-4240-Removing console warnings
				type: "application/vnd.ms-powerpoint",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = pptFileDownloadName;
			link.click();
		}

		if (this.state.pocExt === "txt") {  //Nilesh-4240-Removing console warnings
			url = this.state.pocFeedbackFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4240-Removing console warnings
			let textFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadPOCFeedbackFiles], {  //Nilesh-4240-Removing console warnings
				type: "text/plain",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = textFileDownloadName;
			link.click();
		}

		if (this.state.pocExt === "docx") {  //Nilesh-4240-Removing console warnings
			url = this.state.pocFeedbackFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4240-Removing console warnings
			let docFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadPOCFeedbackFiles], {  //Nilesh-4240-Removing console warnings
				type: "application/msword",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
        //Nilesh-4240-Removing console warnings
		if (
			this.state.pocExt === "png" ||
			this.state.pocExt === "jpeg" ||
			this.state.pocExt === "jpg" ||
			this.state.pocExt === "PNG" ||
			this.state.pocExt === "JPEG" ||
			this.state.pocExt === "JPG"
		) {
			url = this.state.pocFeedbackFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4240-Removing console warnings
			let pngFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadPOCFeedbackFiles], {  //Nilesh-4240-Removing console warnings
				type: "application/Image",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = pngFileDownloadName;
			link.click();
		}
	} /**** End of downloadPOCFeedbackFile */

	/**
	 * @author Supriya Gore
	 * @param  Description download CS files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	handleCSFeedbackFileDownload(e) {
		e.preventDefault();
		//Nilesh-4240-Removing console warnings
		// var pubID = this.props.location.state;//Somnath Task-3955, VAPT--publisher side--Home--URL links And Routes-FE
		var campID=this.state.campID;
		// var pID=this.state.pID;  //Nilesh-4240-Removing console warnings
		var supportDocID = e.target.getAttribute("supportDocID");
		var csFeedbackFileName = e.target.getAttribute("csFeedbackFileName");
		var ext = csFeedbackFileName.split(".").pop();
		this.setState({ csExt: ext, csFeedbackFilename: csFeedbackFileName });
		
//sonali-3945-removing pID from the following call	
		fetch(
			"/publisher/csFeedbackFileDownload?campID=" +
				campID +
				"&supportDocID=" +
				supportDocID
		)
			.then((r) => r.blob())
			.then((downloadCSFeedbackFiles) => {
				this.setState({ downloadCSFeedbackFiles: downloadCSFeedbackFiles });
			})

			.then(this.downloadCSFeedbackFile);
	} /*** End of handleCSFileDownload */

	/**
	 * @author Supriya Gore
	 * @param  Description download CS files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	downloadCSFeedbackFile(blob) {
		/***** If POC File is PDF */
		if (this.state.csExt === "pdf") {  //Nilesh-4240-Removing console warnings
			var url = this.state.csFeedbackFilename;
			var fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pdfFileDownloadName = fileName;
			var newBlob = new Blob([this.state.downloadCSFeedbackFiles], {
				type: "application/pdf",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = pdfFileDownloadName;
			link.click();
		}
		if (this.state.csExt === "ppt" || this.state.csExt === "pptx") {  //Nilesh-4240-Removing console warnings
			url = this.state.csFeedbackFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4240-Removing console warnings
			let pptFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadCSFeedbackFiles], {  //Nilesh-4240-Removing console warnings
				type: "application/vnd.ms-powerpoint",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = pptFileDownloadName;
			link.click();
		}

		if (this.state.csExt === "txt") {  //Nilesh-4240-Removing console warnings
			url = this.state.csFeedbackFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4240-Removing console warnings
			let textFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadCSFeedbackFiles], {  //Nilesh-4240-Removing console warnings
				type: "text/plain",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = textFileDownloadName;
			link.click();
		}

		if (this.state.csExt === "docx") {  //Nilesh-4240-Removing console warnings
			url = this.state.csFeedbackFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4240-Removing console warnings
			let docFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadCSFeedbackFiles], {  //Nilesh-4240-Removing console warnings
				type: "application/msword",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
        //Nilesh-4240-Removing console warnings
		if (
			this.state.csExt === "png" ||
			this.state.csExt === "jpeg" ||
			this.state.csExt === "jpg" ||
			this.state.csExt === "PNG" ||
			this.state.csExt === "JPEG" ||
			this.state.csExt === "JPG"
		) {
			url = this.state.csFeedbackFilename;  //Nilesh-4240-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4240-Removing console warnings
			let pngFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadCSFeedbackFiles], { //Nilesh-4240-Removing console warnings
				type: "application/Image",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4240-Removing console warnings
			link.href = data;
			link.download = pngFileDownloadName;
			link.click();
		}
	} /**** End of downloadPOCFie */

	handleChangeUploadCSFile(i, e) {
		e.preventDefault();
		//SOmnath Task-3955 get pid and campid from state
		var campID=this.state.campID;
			// var pID=this.state.pID;  //Nilesh-4240-Removing console warnings
		var supportDocID = e.target.id;

		// var nofileSelected = document.getElementById('fileName'+supportDocID);
		if (
			document.getElementById("csFileName" + supportDocID) != null ||
			document.getElementById("csFileName" + supportDocID) != undefined  
		) {
			document.getElementById("csFileName" + supportDocID).innerHTML =
				""; /**** this is making empty for hide the message No File Chosen because of in dynamic delete function it assign value No File Chosen */
		}

		const { name, value } = e.target;
		let csArray = [...this.state.csArray];
		csArray[i] = { ...csArray[i], [name]: value };
		this.setState({ csArray });
		var names = [];
		let filesData = e.target.files;
		if (filesData.length > 0) {
			var fileCs = filesData[0].name;
			this.setState({ fileCs: fileCs });
			let file = e.target.files[0];
			console.warn("data files", filesData);
			var FileExt;
			for (let i = 0, l = filesData.length; i < l; i++) {  //Nilesh-4240-Removing console warnings
				names[i] = filesData[i].name;
				FileExt = names[i].split(".").pop();
			}

			/**
			 * @author Sanjana Godbole
			 * @param  A FileList object that lists every selected file
			 * @return List of uploded file along with remove link
			 */
			let cserror = {};
			var link;
			var filename = "";
			var arrayLength = names.length;
			var nooutput = document.getElementById("csList" + supportDocID);

			if (arrayLength > 0) {
				var allowedFiles = [".doc", ".docx"];
				//Nilesh-4240-Removing console warnings
				if (
					FileExt === "doc" ||
					FileExt === "docx" ||
					FileExt === "DOC" ||
					FileExt === "DOCX"
				) {
					nooutput.innerHTML = arrayLength + "  File Selected";
					Filecs = true;
					files1 += arrayLength //Rutuja 4921 increased File counter when newsletter uploaded
					var HTML = "<table>";
					for (var j = 0; j < arrayLength; j++) {
						link =
							'<a class="removeFileCS" href="#" data-fileid=' +
							'"' +
							names[j] +
							'"' +
							'><i class="fa fa-times"></i></a>';
						HTML +=
							"<tr><td>" +
							link +
							"&nbsp;&nbsp;&nbsp;&nbsp;" +
							names[j] +
							"</td></tr>";
					}
					HTML += "</table>";
					document.getElementById("csAbmList" + supportDocID).innerHTML = HTML;
					$(document).on("click", ".removeFileCS", function (e) {
						e.preventDefault();
						var clickedFile = $(this).parent().children("a").data("fileid");
						var j = 0;
						for (j; j < names.length; j++) {
							var temp = j;

							if (names[temp] === clickedFile) {
								filename = names[temp];
								names.splice(j, 1);
								$(this).parent().remove();
								arrayLength = names.length;

								/**
								 * @author Narendra Phadke
								 * @param  Delete File From Database
								 */
								// var typeOfSuppDoc = "ABM";  //Nilesh-4240-Removing console warnings
								let data = {
									// campID: this.props.location.state.campID,
									campID: campID,  //Nilesh-4885-Remove this.props.location.state.campID
									supportDocID: supportDocID,
									//pID: pID,
									//sonali-3945-removing pID 
								};

								document.getElementById(supportDocID).value = "";
								fetch("publisher/csDelete", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								}).catch(function (err) {
									console.log(err);
								});
								files1 = files1 - 1; //Rutuja 4921 decresed File counter when newsletter deleted
								if (arrayLength > 0) {
									nooutput.innerHTML = arrayLength + "  File Selected";
								} else {
									nooutput.innerHTML = " No File Chosen";
									$("#abm1").val("");
									var message = document.getElementById(
										"csFileMessage" + supportDocID
									);
									message.innerHTML = "";
									//Rutuj task - 4921 after deleting file marking uploadCS false
									if (files1 == 0){
										Filecs = false; //Rutuja task - 4921 after deleting file checking array if array length is 0 then Marked Filecs false
										uploadCS = false; 
											}
								}
							} // end of  if(names[temp] === clickedFile)
						} // end of for
					}); //$(document).on

					var data = new FormData();
					data.append("campID", campID);
					data.append("file", file);
					//sonali-3945-removing pID from the following 
					//data.append("pID", pID);
					data.append("supportDocID", supportDocID);
					// data.append("suppDocName",this.state.POCSubmissionPopUP[0].suppDocName);
					// data.append("clientCampID",this.state.POCSubmissionPopUP[0].clientCampID);
					// console.warn("newData",JSON.stringify(filesData));

					fetch("publisher/uploadCSFILE", {
						method: "POST",
						body: data,
					})
						.then(function (response) {
							if (response.status >= 400) {
								throw new Error("Bad response from server");
							}
							return response.json();
						})
						.then(function (response) {
						
							var msg = "CS File Uploaded Successfully";
							var message = document.getElementById(
								"csFileMessage" + supportDocID
							);

							if (arrayLength > 0) {
								message.innerHTML += msg;
								uploadCS = true;
							} else {
								message.innerHTML = "";
								uploadCS = false;
								uploadCSBE = false;
							}
						})
						.catch(function (err) {
							console.log(err);
						});

					this.setState({
						files: data,
					});
					var error = "cserr:" + supportDocID;

					cserror[error] = "";
					this.setState({
						cserror: cserror,
					});
				} else {
					error = "cserr:" + supportDocID;  //Nilesh-4240-Removing console warnings
					cserror[error] =
						"Please upload files having extensions: " +
						allowedFiles.join(", ") +
						" only";
					this.setState({
						cserror: cserror,
					});
					//this.state.cserror=[...cserror];
				}
			} else {
				nooutput.innerHTML = " No File Chosen";
			}
		}
	}

	handleChangeDynamicLpInsert(i, e) {
		document.getElementById("lpLink" + i).style.border = "1px solid lightgray";
		document.getElementById("feedback" + i).innerHTML = null;
		const { name, value } = e.target;
		let newDynamicArray = [...this.state.newDynamicArray];
		newDynamicArray[i] = { ...newDynamicArray[i], [name]: value };
		this.setState({ newDynamicArray });

		lpsubmitLink = true;
		// var lpArray = this.state.newDynamicArray;  //Nilesh-4240-Removing console warnings
	} // end of  handleChangeDynamicLpInsert
	handleChange(e) {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});
	}
	deletePOCFile(e) {
		e.preventDefault();
		//Nilesh-4240-Removing console warnings
		// var pubID = this.props.location.state;//Somnath Task-3955, VAPT--publisher side--Home--URL links And Routes-FE
		// var campID=this.state.campID;  //Nilesh-4240-Removing console warnings
			// var pID=this.state.pID;  //Nilesh-4240-Removing console warnings
		var supportDocID = e.target.id;

		document.getElementById("fileName" + supportDocID).innerHTML = "";
		let data = {
			campID: this.props.location.state.campID,
			supportDocID: supportDocID,
			//pID: pID,//sonali-3945-removing pID from the following call
		};

		fetch("publisher/pocDelete", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}).catch(function (err) {
			console.log(err);
		});
		this.forceUpdate();
		var nooutput = document.getElementById("pocList" + supportDocID);
		nooutput.innerHTML = "";
		var nooutput1 = document.getElementById("fileName" + supportDocID);
		nooutput1.innerHTML = "No File Chosen";
	}

	deleteCSFile(e) {
		e.preventDefault();
		//Nilesh-4240-Removing console warnings
		// var pubID = this.props.location.state;//Somnath Task-3955, VAPT--publisher side--Home--URL links And Routes-FE
		// var campID=this.state.campID;  //Nilesh-4240-Removing console warnings
			// var pID=this.state.pID;  //Nilesh-4240-Removing console warnings
		var supportDocID = e.target.id;

		document.getElementById("csFileName" + supportDocID).innerHTML = "";
		let data = {
			campID: this.props.location.state.campID,
			supportDocID: supportDocID,
			//pID: pID, //sonali-3945-removing pID from the following call
		};

		fetch("publisher/csDelete", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}).catch(function (err) {
			console.log(err);
		});
		this.forceUpdate();
		var nooutput = document.getElementById("csList" + supportDocID);
		nooutput.innerHTML = "";
		var nooutput1 = document.getElementById("csFileName" + supportDocID);
		nooutput1.innerHTML = "No File Chosen";
	}
	submitLandingPage1(e) {
		e.preventDefault();
		// let fields = this.state.fields;  //Nilesh-4240-Removing console warnings
		let errors = {};
		let minerror = {};
		// let success = {};   //Nilesh-4240-Removing console warnings
		let formIsValid = false;
		let spanDisplay = "none";
		let link = false;
		let LandingPageLink = false;
		let CallScriptValidate = false;
		let NewsletterValidate = false;

		/*
@author:Sanobar Golandaj
@Creation Date:04-02-2019
@Description:validation for selecting min one lp link
*/

		result = Array.from(
			new Set(this.state.newDynamicArray.map((s) => s.supportDocID))
		).map((supportDocID) => {
			return {
				supportDocID: supportDocID,
				suppDocName: this.state.newDynamicArray.find(
					(s) => s.supportDocID === supportDocID
				).suppDocName,
				status: this.state.newDynamicArray.find(
					(s) => s.supportDocID === supportDocID
				).status,
				lpLink: this.state.newDynamicArray.find(
					(s) => s.supportDocID === supportDocID
				).lpLink,
				feedback: this.state.newDynamicArray.find(
					(s) => s.supportDocID === supportDocID
				).feedback,
				assetStatus: this.state.newDynamicArray.find(
					(s) => s.supportDocID === supportDocID
				).assetStatus,
			};
		});

		var dynamiclength = result.length;
		for (var i = 0; i < dynamiclength; i++) {
			if (result[i].lpLink !== undefined && result[i].lpLink !== "") {
				LandingPageLink = true;
			} else {
			}
		}
        
		if (Filecs === true) { //Nilesh-4240-Removing console warnings
			CallScriptValidate = true;
		} else {
		}

		if (Filepoc === true) { //Nilesh-4240-Removing console warnings
			NewsletterValidate = true;
		} else {
		}
        // alert("LandingPageLink---->" + JSON.stringify(LandingPageLink))
		// alert("LandingPageLink====>" + typeof(LandingPageLink))
		// alert("CallScriptValidate---->" + JSON.stringify(CallScriptValidate))
		// alert("CallScriptValidate====>" + typeof(CallScriptValidate))
		// alert("NewsletterValidate---->" + JSON.stringify(NewsletterValidate))
		// alert("NewsletterValidate====>" + typeof(NewsletterValidate))
		if (
			LandingPageLink === true ||
			CallScriptValidate === true ||
			NewsletterValidate === true
		) {
			formIsValid = true;
			this.setState({
				minerror: "",
				alertBorder: "0px solid white",
			});
		} else {
			// minerror["commonError1"] = "Please enter min one creative";
			minerror["commonError1"] = "Please enter LP/POC/Call Script details";//Nilesh-5020 - Add error message 
			formIsValid = false;
			this.setState({
				minerror: minerror,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}

		dynamiclength = result.length;  //Nilesh-4240-Removing console warnings
		for (let i = 0; i < dynamiclength; i++) {  //Nilesh-4240-Removing console warnings
			if (result[i].lpLink !== undefined && result[i].lpLink !== "") {
				// formIsValid=true;
				link = true;
				displayresult.splice(i, 1);
			} else {
				// formIsValid=false;
			}

			if (link === true) {
				//  newsuccess["msg"]="Successfully submitted";
				formIsValid = true;
				this.setState({ alertBorder: "0px solid white" });
			} else {
				errors["message"] = "Please enter minimum one lp link";
				this.setState({
					newsuccess: "",
					errors: errors,
					alertBorder: "1px solid red",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
					spanSpaceDisplay: spanDisplay,
				});
			}

			// validation for disable submit button if all links are approved.
			if (
				result[i].status === "Approved" ||
				result[i].status === "ClientApproved" ||
				result[i].status === "ClientReviewPending"
			) {
				formIsValid = false;
				this.setState({
					alertDisplay: "none",
					alertBorder: "0px solid white",
					btnDisable: "disabled1",
				});
			} else {
				this.setState({
					btnDisable: "button",
				});
			}
		}

		if (formIsValid === true || uploadPOC === true || uploadCS === true) {
				//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
		// var campID,pID,parentCampID;  //Nilesh-4240-Removing console warnings
		// if(this.props.location.state!==undefined)
		// {
			// var campID=this.state.campID;  //Nilesh-4240-Removing console warnings
			// var pID = this.state.pID;   //Nilesh-4240-Removing console warnings
		// }
		
		// else
		// {
		// var parsed = queryString.parse(this.props.location.search);
		//  pID = parsed.pID;
		//  campID = parsed.campID;
		// }
		
			var supportDocID = document.getElementById("supportDocID").value;
			
			/**
			 * @author Narendra Phadke
			 * @param  Description handle the email functionality
			 * @return Description return All details of email
			 */
			// const { isAuthenticated, user } = this.props.auth;  //Nilesh-4240-Removing console warnings
			let data = {
				campID: this.props.location.state.campID,
				//pID: pID, //sonali-3945-removing pID from the following 
				lpLink: this.state.lpLink,
				feedback: this.state.feedback,
				supportDocID: supportDocID,
				agencyID: this.state.campaignDetails[0].agencyID,
				clientCampID: this.state.LP_POC_CS_SubmissionPopUP[0].clientCampID,
				dynamicArray: this.state.newDynamicArray,
				//user: user,//sonali-3945-removing user from the following 
				parentCampID: this.props.location.state.parentCampID,//Sonali-4138-getting paremntCampID from location.state				
				reallocationID: this.state.reallocationID,
			};
			// success["msg"]="Successfully submitted";
			this.setState({
				errors: "",
				// success : success,
				alertBorder: "0px solid white",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});

			// var lpSuccess = false;  //Nilesh-4240-Removing console warnings
			// var lpSuccessBE = true;   //Nilesh-4240-Removing console warnings
			// var pocSuccess = false;    //Nilesh-4240-Removing console warnings
			// var csSuccess = false;    //Nilesh-4240-Removing console warnings
			var length;
			if (lpsubmitLink === true && uploadCS === false && uploadPOC === false) {
				let urls = ["/publisher/submitLandingPage"];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
			
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								let data = {
									//pID: pID,//sonali-3945-removing pID from the following call
									campID: this.props.location.state.campID,
								};
								// Sandeep-task-3273- added code for Upload creatives LP link validation message issue
								fetch("/publisher/creativeUploadDetails", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								})
									.then((res) => res.json())
									.then((LP_POC_CS_SubmissionPopUP) => {
										var lpLinkData = LP_POC_CS_SubmissionPopUP;

										const filteredData = lpLinkData.filter(item => item.landingPageID !== undefined);//Rutuja task 4889 -Filtered the data if Landing page is undefined  
										var lpLinkValue = filteredData.filter(function (s) {
											return s.sslFeedback !== "";
										});
										
										length = lpLinkValue.length;
										if (length > 0) {
											Swal.fire({
												text: "LP did not submitted successfully",
												type: "error",
											});
										} else {
											Swal.fire({
												text: " LP submitted Successfully",
												type: "success",
											}).then((result) => {
												if (result.value) {
													window.location.reload();
												}
											});
										}
										//Rutuja 4889 commneted this.setstate as no need to set the state 
										// this.setState(
										// 	{ LP_POC_CS_SubmissionPopUP: LP_POC_CS_SubmissionPopUP },
										// 	function () {
										// 		// console.log(
										// 		// 	"LP_POC_CS_SubmissionPopUP" +
										// 		// 		JSON.stringify(this.state.LP_POC_CS_SubmissionPopUP)
										// 		// );
										// 	}
										// );
									})
									.catch(function (err) {
										console.log(err);
									});
							})

							.catch((err) => {
								Swal.fire({
									text: "LP did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (uploadPOC === true && lpsubmitLink === false && uploadCS === false) {
				let urls = ["/publisher/submitPOCRecord"];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusPOC", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
								Swal.fire({
									text: " POC submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "POC did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (uploadCS === true && lpsubmitLink === false && uploadPOC === false) {
				let urls = ["/publisher/submitCSRecord"];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusCS", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
								Swal.fire({
									text: " CS submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "CS did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (lpsubmitLink === true && uploadCS === true && uploadPOC === false) {
				let urls = [
					"/publisher/submitLandingPage",
					"/publisher/submitCSRecord",
				];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusCS", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
								Swal.fire({
									text: "LP and CS submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "LP and CS did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (lpsubmitLink === true && uploadPOC === true && uploadCS === false) {
				let urls = [
					"/publisher/submitLandingPage",
					"/publisher/submitPOCRecord",
				];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusPOC", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
								Swal.fire({
									text: "LP and POC submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "LP and POC did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (uploadCS === true && uploadPOC === true && lpsubmitLink === false) {
				let urls = ["/publisher/submitPOCRecord", "/publisher/submitCSRecord"];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusPOC", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
					
									fetch("/publisher/updateStatusCS", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data11),
									})
										.then((res) => res.json())
										.then(console.log("finally Submitted"))
										.catch(function (err) {
											console.log(err);
										});
								Swal.fire({
									text: "POC and CS submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "POC and CS did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (lpsubmitLink === true && uploadCS === true && uploadPOC === true) {
				let urls = [
					"/publisher/submitPOCRecord",
					"/publisher/submitCSRecord",
					"/publisher/submitLandingPage",
				];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusPOC", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
					
									fetch("/publisher/updateStatusCS", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data11),
									})
										.then((res) => res.json())
										.then(console.log("finally Submitted"))
										.catch(function (err) {
											console.log(err);
										});
								Swal.fire({
									text: "LP,POC,CS submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "LP,POC,CS did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			/*let data11 = {
				campID: this.props.location.state.campID,
			};
			fetch("/publisher/updateStatusPOC", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data11),
			})
				.then((res) => res.json())
				.then(console.log("finally Submitted"))
				.catch(function (err) {
					console.log(err);
				});

				fetch("/publisher/updateStatusCS", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data11),
				})
					.then((res) => res.json())
					.then(console.log("finally Submitted"))
					.catch(function (err) {
						console.log(err);
					});*/
		} else {
		}
	} // end for submit

	//Sonali-4138-did mount changes to will mount
	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		}
	//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
		var campID,pID,parentCampID,step;
		if(this.props.location.state!==undefined)
		{
			var parsed = this.props.location.state;
			campID=parsed.campID;
			pID=parsed.pID
			parentCampID = parsed.parentCampID;
			step=parsed.step;
			this.setState({ pID: pID, parentCampID: parentCampID,campID:campID,step:step});
	// 	}
		
	// 	else
	// 	{
	// 	var parsed = queryString.parse(this.props.location.search);
	// 	 pID = parsed.pID;
	// 	 campID = parsed.campID;
	//  parentCampID = parsed.parentCampID;
	//  this.setState({ pID: pID, parentCampID: parentCampID,campID:campID});
	// 	}

		

		//var pubID = queryString.parse(this.props.location.search);
	//Sonali-3945-VAPT-accessing parameters from location.state

	//	var parsed = queryString.parse(this.props.location.search);

		// alert(pID)
		this.setState({ pID: pID, parentCampID: parentCampID });
		let data = {
			//pID: pID,//sonali-3945-removing pID from the following call
			campID: this.props.location.state.campID,
		};

		fetch("/publisher/creativeUploadDetails", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((LP_POC_CS_SubmissionPopUP) => {
				this.setState(
					{ LP_POC_CS_SubmissionPopUP: LP_POC_CS_SubmissionPopUP },
					function () {
						console.log(
							"LP_POC_CS_SubmissionPopUP" +
								JSON.stringify(this.state.LP_POC_CS_SubmissionPopUP)
						);
					}
				);
			})
			.catch(function (err) {
				console.log(err);
			});

		fetch("/publisher/campaignDetailsForCreativeUploads", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((campaignDetails) => {
				this.setState({
					campaignDetails: campaignDetails,
					marketingChannel: campaignDetails[0].marketingChannel,
					parentCampID: campaignDetails[0].parentCampID,
					reallocationID: campaignDetails[0].reallocationID,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
		}
	}
	render() {
		this.state.LP_POC_CS_SubmissionPopUP.forEach((itm, i) => {
			this.state.newDynamicArray.push(Object.assign({}, itm));
		});

		// const { TextArea } = Input; //Nilesh-4240-Removing console warnings
		return (
			<div>
				<PublisherNavigation />
				<form method="POST" name="submitLP" onSubmit={this.submitLandingPage1}>
					<div
						style={{ paddingBottom: "60px", paddingTop: "90px" }}
						class="container-fluid">
						<div class="row">
							{/* { this.state.Step=='1'? */}
							<div class=" col-lg-4" style={{ paddingLeft: "20px" }}>
								<a
								    href="#/"  //Nilesh-4240-Removing console warnings
									//karan-task-3717-replace query params
									onClick={(e) =>{
										e.preventDefault();
										this.props.history.push("/newPublisherDashboard", {
											pID: this.state.pID,
										})
									}
									}
									style={{ color: "#056eb8" }}>
									<FaArrowAltCircleLeft
										size={32}
										style={{ float: "left" }}
										title="Back to Dashboard"
									/>
								</a>
							</div>
							{/* :
                 <div class="col-lg-4">
                   <a href={'/publisherView#5?pID='+this.state.pid}  style={{color:'#fff'}}><button class="btn btn-default" type="button" style={{backgroundColor:"#056eb8",borderColor:'#056eb8',color:"white",width:'min-content',height:'31px',fontSize:'14px'}}>Back to Pending Campaign</button></a></div>
                 } */}
							{/* //shivani-3285-passed ID for DI label consistency */}
							<div
								class="col-lg-4"
								align="center"
								style={{ textAlign: "center" }}>
								<label id="labelDI">Upload creatives</label>
							</div>
							<div class="col-lg-4"></div>
						</div>

						{/* <div class="col-lg-12"
                    style={{ border: this.state.alertBorder, display: this.state.alertDisplay }}> */}
						<div style={{ border: this.state.alertBorder }}>
							<div
								className="errorMessage"
								style={{ display: this.state.alertDisplay }}>
								{this.state.minerror.commonError1}
							</div>
						</div>
						{/* </div> */}

						<hr />
						{this.state.campaignDetails.map((campaignDetails, i) => {
							return (
								<div class="row">
									<div class="col-lg-3">
										<label id="label" className="CampIDPub" style={{ fontWeight: "400" }}>	{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}
											Camp ID :
										</label>
										<label style={{ fontWeight: "400" }}>
											{" "}
											{campaignDetails.parentCampID}
										</label>
									</div>
									<div class="col-lg-4">
										<label id="label" className="CampNamePub" style={{ fontWeight: "400" }}>	{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}
											Camp Name :
										</label>
										<label style={{ fontWeight: "400" }}>
											{campaignDetails.campaignName}
										</label>
									</div>
									{/* <div class="col-lg-"><label style={{fontWeight:'400',color:'#2196F3'}}>Camp Status :</label><label style={{fontWeight:'400'}}> Allocating Progress</label></div> */}
									<div class="col-lg-3">
										<label id="label" className="StartDatePub" style={{ fontWeight: "400" }}>	{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}
											Start Date :
										</label>
										<label style={{ fontWeight: "400" }}>
											{campaignDetails.startDate}
										</label>
									</div>
									<div class="col-lg-2">
										<label id="label" className="EndDatePub" style={{ fontWeight: "400" }}>		{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}
											End Date :
										</label>
										<label style={{ fontWeight: "400" }}>
											{campaignDetails.endDate}
										</label>
									</div>
								</div>
							);
						})}
						<hr />
						<div class="col-lg-12" style={{ display: this.state.alertDisplay }}>
							<div
								style={{
									fontSize: "18px",
									color: "green",
									textAlign: "center",
								}}>
								{this.state.newsuccess}
								{/* {this.state.errors.errorfeedback} */}
							</div>
							<div
								style={{ fontSize: "18px", color: "red", textAlign: "center" }}>
								{this.state.failure}
								{/* {this.state.errors.errorfeedback} */}
							</div>
						</div>

						<div class="table-responsive">
							{this.state.tableStatus == "true" ? (
								<table class="table table-bordered table-striped">
									<thead>
										<tr class="info">
											<th class="table-header" style={{ width: "23%" }}>
												Assets
											</th>
											{/* Nilesh-4240-Removing console warnings */}
											{this.state.marketingChannel === "Email/Telemarketing" ||
											this.state.marketingChannel === "EmailTelemarketing" ||
											this.state.marketingChannel === "Email" ? (
												<th class="table-header" style={{ width: "25%" }}>
													Landing Page
												</th>
											) : (
												""
											)}
											{/* Nilesh-4240-Removing console warnings */}
											{this.state.marketingChannel === "Email/Telemarketing" ||
											this.state.marketingChannel === "EmailTelemarketing" ||
											this.state.marketingChannel === "Email" ? (
												<th class="table-header" style={{ width: "25%" }}>
													Newsletter
												</th>
											) : (
												""
											)}
											{/* Nilesh-4240-Removing console warnings */}
											{this.state.marketingChannel === "Email/Telemarketing" ||
											this.state.marketingChannel === "EmailTelemarketing" ||
											this.state.marketingChannel === "TeleMarketing" ? (
												<th class="table-header" style={{ width: "30%" }}>
													Call Script
												</th>
											) : (
												""
											)}
										</tr>
									</thead>
									<tbody>
										{this.state.LP_POC_CS_SubmissionPopUP.map(
											(LP_POC_CS_SubmissionPopUP, i) => {
												return (
													<tr>
														<input
															type="hidden"
															id="supportDocID"
															value={LP_POC_CS_SubmissionPopUP.supportDocID}
															onChange={this.handleChange}
															name="supportDocID"
														/>
														{LP_POC_CS_SubmissionPopUP.assetStatus ===
														"Removed" ? (
															<span
																style={{
																	color: "red",
																	textDecoration: "line-through",
																}}>
																<td>{LP_POC_CS_SubmissionPopUP.suppDocName}</td>
															</span>
														) : (
															<td>{LP_POC_CS_SubmissionPopUP.suppDocName}</td>
														)}
														{/* Nilesh-4240-Removing console warnings */}
														{this.state.marketingChannel ===
															"Email/Telemarketing" ||
														this.state.marketingChannel ===
															"EmailTelemarketing" ||
														this.state.marketingChannel === "Email" ? (
															<td>
																{LP_POC_CS_SubmissionPopUP.assetStatus ===
																"Removed" ? (
																	<span
																		style={{
																			color: "red",
																			textDecoration: "line-through",
																		}}>
																		<input
																			type="textbox"
																			style={{
																				width: "-webkit-fill-available",
																			}}
																			id="lpLink"
																			name="lpLink"
																			defaultValue={
																				LP_POC_CS_SubmissionPopUP.lpLink
																			}
																			class="form-control"
																			onChange={this.handleChangeDynamicLpInsert.bind(
																				this,
																				i
																			)}
																			disabled></input>
																	</span>
																) : LP_POC_CS_SubmissionPopUP.lpStatus ===
																		"Approved" ||
																  LP_POC_CS_SubmissionPopUP.lpStatus ===
																		"ClientApproved" ||
																  LP_POC_CS_SubmissionPopUP.lpStatus ===
																		"ClientReviewPending" ? (
																	<input
																		type="textbox"
																		style={{ width: "-webkit-fill-available" }}
																		id={"lpLink" + i}
																		name="lpLink"
																		defaultValue={
																			LP_POC_CS_SubmissionPopUP.lpLink
																		}
																		onChange={this.handleChangeDynamicLpInsert.bind(
																			this,
																			i
																		)}
																		disabled></input>
																) : LP_POC_CS_SubmissionPopUP.sslFeedback !=
																		"" &&
																  LP_POC_CS_SubmissionPopUP.lpLink != "" &&
																  LP_POC_CS_SubmissionPopUP.lpStatus ===
																		"pendingSubmission" ? (
																	<input
																		type="textbox"
																		style={{
																			width: "-webkit-fill-available",
																			border: "1px solid red",
																		}}
																		id={"lpLink" + i}
																		name="lpLink"
																		defaultValue={
																			LP_POC_CS_SubmissionPopUP.lpLink
																		}
																		onChange={this.handleChangeDynamicLpInsert.bind(
																			this,
																			i
																		)}></input>
																) : (
																	<input
																		type="textbox"
																		style={{ width: "-webkit-fill-available" }}
																		id={"lpLink" + i}
																		name="lpLink"
																		defaultValue={
																			LP_POC_CS_SubmissionPopUP.lpLink
																		}
																		onChange={this.handleChangeDynamicLpInsert.bind(
																			this,
																			i
																		)}></input>
																)}

																<br />
																{LP_POC_CS_SubmissionPopUP.sslFeedback != "" &&
																LP_POC_CS_SubmissionPopUP.lpLink != "" &&
																LP_POC_CS_SubmissionPopUP.lpStatus ===
																	"pendingSubmission" ? (
																	<label
																		id={"feedback" + i}
																		style={{ color: "red" }}>
																		{LP_POC_CS_SubmissionPopUP.sslFeedback}
																	</label>
																) : (
																	<label id={"feedback" + i}></label>
																)}
																<br />
																<label id="label" className="LPStatusPub" style={{ fontWeight: "400" }}>	{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}													
																	Status:&nbsp;
																</label>
																{/* Nilesh-4240-Removing console warnings */}
																{
																	LP_POC_CS_SubmissionPopUP.lpStatus === "" ||
																	LP_POC_CS_SubmissionPopUP.lpStatus == null ? (
																		"pendingSubmission"
																	) : LP_POC_CS_SubmissionPopUP.lpStatus ===
																			"AdvertiserReviewPending" ||
																	  LP_POC_CS_SubmissionPopUP.lpStatus ===
																			"AdvertiserReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AdvertiserReviewPending
																		</label>
																	) : LP_POC_CS_SubmissionPopUP.lpStatus ===
																			"AdvertiserApproved" ||
																	  LP_POC_CS_SubmissionPopUP.lpStatus ===
																			"AdvertiserApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : LP_POC_CS_SubmissionPopUP.lpStatus ===
																			"AgencyReviewPending" ||
																	  LP_POC_CS_SubmissionPopUP.lpStatus ===
																			"AgencyReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AgencyReviewPending
																		</label>
																	) : LP_POC_CS_SubmissionPopUP.lpStatus ===
																			"AgencyApproved" ||
																	  LP_POC_CS_SubmissionPopUP.lpStatus ===
																			"AgencyApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : (
																		<label style={{ fontWeight: "400" }}>
																			Rejected
																		</label>
																	)
																	// <label style={{fontWeight:'400'}}>{LP_POC_CS_SubmissionPopUP.lpStatus}</label>
																}
																<br />
																{/* Nilesh-4240-Removing console warnings */}
																{LP_POC_CS_SubmissionPopUP.lpFeedback === "" ||
																LP_POC_CS_SubmissionPopUP.lpFeedback == null ? (
																	""
																) : (
																	<div>
																		<label
																			id="label"	className="LPStatusPub"	//chaitanya-task-4107-Publisherside- Review - Creatives review
																			style={{ fontWeight: "400" }}>
																			Feedback:
																		</label>
																		{LP_POC_CS_SubmissionPopUP.lpFeedback}
																		{/* <TextArea readonly>{LP_POC_CS_SubmissionPopUP.lpFeedback}</TextArea> */}
																		<br />
																	</div>
																)}
																	{/* 4288-Sanjana-Added condition-AdvertiserRejected */}
																	{/* Nilesh-4240-Removing console warnings */}
																{LP_POC_CS_SubmissionPopUP.lpStatus ===
																	"Rejected" ||
																LP_POC_CS_SubmissionPopUP.lpStatus ===
																	"ClientRejected" ||
																	LP_POC_CS_SubmissionPopUP.pocStatus ===
																	"AdvertiserRejected" 												
																	? (
																	<div>
																		<label
																			id="label" className="LPStatusPub"	//chaitanya-task-4107-Publisherside- Review - Creatives review
																			style={{ fontWeight: "400" }}>
																			Feedback File Name:&nbsp;
																		</label>
																		<a
																			href="#/" //Nilesh-4240-Removing console warnings
																			supportDocID={
																				LP_POC_CS_SubmissionPopUP.supportDocID
																			}
																			lpFeedbackFileName={
																				LP_POC_CS_SubmissionPopUP.lpFeedbackFileName
																			}
																			onClick={this.handleLPFileDownload.bind(
																				this
																			)}>
																			{
																				LP_POC_CS_SubmissionPopUP.lpFeedbackFileName
																			}
																		</a>
																	</div>
																) : (
																	""
																)}
															</td>
														) : (
															""
														)}
														{/* Ending of LP column condition */}
														{/* Nilesh-4240-Removing console warnings */}
														{this.state.marketingChannel ===
															"Email/Telemarketing" ||
														this.state.marketingChannel ===
															"EmailTelemarketing" ||
														this.state.marketingChannel === "Email" ? (
															<td>
																{LP_POC_CS_SubmissionPopUP.assetStatus ===
																"Removed" ? (
																	<span
																		style={{
																			color: "red",
																			textDecoration: "line-through",
																		}}>
																		{LP_POC_CS_SubmissionPopUP.pocFileName}{" "}
																	</span>
																) : (
																	<div>
																		<label class="btn chooseFile">
																			Choose a File
																			{LP_POC_CS_SubmissionPopUP.pocStatus ===
																				"Approved" ||
																			LP_POC_CS_SubmissionPopUP.pocStatus ===
																				"ClientApproved" ||
																			LP_POC_CS_SubmissionPopUP.pocStatus ===
																				"ClientReviewPending" ? (
																				<input
																					style={{ display: "none" }}
																					type="file"
																					id={
																						LP_POC_CS_SubmissionPopUP.supportDocID
																					}
																					name="uploadPOC"
																					disabled
																				/>
																			) : (
																				<input
																					style={{ display: "none" }}
																					type="file"
																					id={
																						LP_POC_CS_SubmissionPopUP.supportDocID
																					}
																					name="uploadPOC"
																					onChange={this.handleChangeUploadPOCFile.bind(
																						this,
																						i
																					)}
																				/>
																			)}
																		</label>{" "}
																		<br />
																		<label
																			id={
																				"pocList" +
																				LP_POC_CS_SubmissionPopUP.supportDocID
																			}>
																			{LP_POC_CS_SubmissionPopUP.pocFileName ? (
																				<label
																					id={
																						"fileName" +
																						LP_POC_CS_SubmissionPopUP.supportDocID
																					}>
																					1 File Selected
																				</label>
																			) : (
																				"No File Chosen"
																			)}
																			{/* No File Chosen */}                                                                                                 													
																		</label>
																		<div style={{ color: "red" }}>
																			{
																				this.state.pocerror[
																					"pocerr:" +
																						LP_POC_CS_SubmissionPopUP.supportDocID
																				]
																			}
																		</div>
																		<br />
																		<div
																			id={
																				"abmList" +
																				LP_POC_CS_SubmissionPopUP.supportDocID
																			}
																			className="word-wrap"></div>
																		{LP_POC_CS_SubmissionPopUP.pocFileName ? (
																			<div
																				id={
																					"fileName" +
																					LP_POC_CS_SubmissionPopUP.supportDocID
																				}>                                                                                                                      
																				<a
																					href="#/" //Nilesh-4240-Removing console warnings
																					data-fileid={
																						LP_POC_CS_SubmissionPopUP.pocFileName
																					}
																					class="removeFilePOC">
																					{LP_POC_CS_SubmissionPopUP.pocStatus ===
																						"Approved" ||
																					LP_POC_CS_SubmissionPopUP.pocStatus ===
																						"ClientApproved" ||
																					LP_POC_CS_SubmissionPopUP.pocStatus ===
																						"ClientReviewPending" ? (
																						""
																					) : (
																						<i
																							class="fa fa-times"
																							onClick={this.deletePOCFile}
																							id={
																								LP_POC_CS_SubmissionPopUP.supportDocID
																							}
																							name={
																								LP_POC_CS_SubmissionPopUP.pocFileName
																							}
																						/>
																					)}
																					{/* <i class="fa fa-times" onClick={this.deletePOCFile} id={LP_POC_CS_SubmissionPopUP.supportDocID} name={LP_POC_CS_SubmissionPopUP.pocFileName} /> */}
																				</a>{" "}
																				{LP_POC_CS_SubmissionPopUP.pocFileName
																					? LP_POC_CS_SubmissionPopUP.pocFileName
																					: ""}
																			</div>
																		) : (
																			""
																		)}
																		<div
																			id={
																				"pocFileMessage" +
																				LP_POC_CS_SubmissionPopUP.supportDocID
																			}
																			style={{ color: "green" }}></div>
																		{/* <label id="POCFile" style={{fontWeight:'300'}}>No file Choosen</label> */}
																	</div>
																)}
																<br />
																<label id="label" className="NewsletterPub" style={{ fontWeight: "400" }}>	{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}
																	Status:&nbsp;
																</label>
																{/* Nilesh-4240-Removing console warnings */}
																{
																	LP_POC_CS_SubmissionPopUP.pocStatus === "" ||
																	LP_POC_CS_SubmissionPopUP.pocStatus ==
																		null ? (
																		"pendingSubmission"
																	) : LP_POC_CS_SubmissionPopUP.pocStatus ===
																			"AdvertiserReviewPending" ||
																	  LP_POC_CS_SubmissionPopUP.pocStatus ===
																			"AdvertiserReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AdvertiserReviewPending
																		</label>
																	) : LP_POC_CS_SubmissionPopUP.pocStatus ===
																			"AdvertiserApproved" ||
																	  LP_POC_CS_SubmissionPopUP.pocStatus ===
																			"AdvertiserApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : LP_POC_CS_SubmissionPopUP.pocStatus ===
																			"AgencyReviewPending" ||
																	  LP_POC_CS_SubmissionPopUP.pocStatus ===
																			"AgencyReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AgencyReviewPending
																		</label>
																	) : LP_POC_CS_SubmissionPopUP.pocStatus ===
																			"AgencyApproved" ||
																	  LP_POC_CS_SubmissionPopUP.pocStatus ===
																			"AgencyApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : (
																		<label style={{ fontWeight: "400" }}>
																			Rejected 
																		</label>
																	)
																	//  <label style={{fontWeight:'400'}}>{LP_POC_CS_SubmissionPopUP.pocStatus}</label>
																}

																<br />
																{/* Nilesh-4240-Removing console warnings */}
																{LP_POC_CS_SubmissionPopUP.pocFeedback === "" ||
																LP_POC_CS_SubmissionPopUP.pocFeedback ==
																	null ? (
																	""
																) : (
																	<div>
																		<label
																			id="label"	className="NewsletterPub"		//chaitanya-task-4107-Publisherside- Review - Creatives review
																			style={{ fontWeight: "400" }}>
																			Feedback:
																		</label>
																		{LP_POC_CS_SubmissionPopUP.pocFeedback}
																		{/* <TextArea readonly>{LP_POC_CS_SubmissionPopUP.pocFeedback}</TextArea> */}
																	</div>
																)}
																<br />
																{/* 4288-Sanjana-Added condition-AdvertiserRejected */}
																{/* Nilesh-4240-Removing console warnings */}
																{LP_POC_CS_SubmissionPopUP.pocStatus ===
																	"Rejected" ||
																LP_POC_CS_SubmissionPopUP.pocStatus ===
																	"ClientRejected" ||
																	LP_POC_CS_SubmissionPopUP.pocStatus ===
																	"AdvertiserRejected" 												
																	? (
																	<div>
																		<label
																			id="label"	className="NewsletterPub"		//chaitanya-task-4107-Publisherside- Review - Creatives review
																			style={{ fontWeight: "400" }}>
																			Feedback File Name:&nbsp;
																		</label>
																		<a
																			href="#/"  //Nilesh-4240-Removing console warnings
																			supportDocID={
																				LP_POC_CS_SubmissionPopUP.supportDocID
																			}
																			pocFeedbackFileName={
																				LP_POC_CS_SubmissionPopUP.pocFeedbackFileName
																			}
																			onClick={this.handlePOCFeedbackFileDownload.bind(
																				this
																			)}>
																			{
																				LP_POC_CS_SubmissionPopUP.pocFeedbackFileName
																			}
																		</a>
																	</div>
																) : (
																	""
																)}
															</td>
														) : (
															""
														)}
														{/* Ending of poc column condition */}
														
														{/* Nilesh-4240-Removing console warnings */}
														{this.state.marketingChannel ===
															"Email/Telemarketing" ||
														this.state.marketingChannel ===
															"EmailTelemarketing" ||
														this.state.marketingChannel === "TeleMarketing" ? (
															<td>
																{LP_POC_CS_SubmissionPopUP.assetStatus ===
																"Removed" ? (
																	<span
																		style={{
																			color: "red",
																			textDecoration: "line-through",
																		}}>
																		{LP_POC_CS_SubmissionPopUP.csFileName}{" "}
																	</span>
																) : (
																	<div>
																		<label class="btn chooseFile">
																			Choose a File
																			{LP_POC_CS_SubmissionPopUP.csStatus ===
																				"Approved" ||
																			LP_POC_CS_SubmissionPopUP.csStatus ===
																				"ClientApproved" ||
																			LP_POC_CS_SubmissionPopUP.csStatus ===
																				"ClientReviewPending" ? (
																				<input
																					style={{ display: "none" }}
																					type="file"
																					id={
																						LP_POC_CS_SubmissionPopUP.supportDocID
																					}
																					name="uploadCS"
																					disabled
																				/>
																			) : (
																				<input
																					style={{ display: "none" }}
																					type="file"
																					id={
																						LP_POC_CS_SubmissionPopUP.supportDocID
																					}
																					name="uploadCS"
																					onChange={this.handleChangeUploadCSFile.bind(
																						this,
																						i
																					)}
																				/>
																			)}
																		</label>{" "}
																		<br />
																		<label
																			id={
																				"csList" +
																				LP_POC_CS_SubmissionPopUP.supportDocID
																			}>
																			{LP_POC_CS_SubmissionPopUP.csFileName ? (
																				<label
																					id={
																						"csFileName" +
																						LP_POC_CS_SubmissionPopUP.supportDocID
																					}>
																					1 File Selected
																				</label>
																			) : (
																				"No File Chosen"
																			)}
																			{/* No File Chosen */}
																		</label>
																		<div style={{ color: "red" }}>
																			{
																				this.state.cserror[
																					"cserr:" +
																						LP_POC_CS_SubmissionPopUP.supportDocID
																				]
																			}
																		</div>
																		<br />
																		<div
																			id={
																				"csAbmList" +
																				LP_POC_CS_SubmissionPopUP.supportDocID
																			}
																			className="word-wrap"></div>
																		{LP_POC_CS_SubmissionPopUP.csFileName ? (
																			<div
																				id={
																					"csFileName" +
																					LP_POC_CS_SubmissionPopUP.supportDocID
																				}>
																				<a
																					href="#/"  //Nilesh-4240-Removing console warnings
																					data-fileid={
																						LP_POC_CS_SubmissionPopUP.csFileName
																					}
																					class="removeFileCS">
																					{LP_POC_CS_SubmissionPopUP.csStatus ===
																						"Approved" ||
																					LP_POC_CS_SubmissionPopUP.csStatus ===
																						"ClientApproved" ||
																					LP_POC_CS_SubmissionPopUP.csStatus ===
																						"ClientReviewPending" ? (
																						""
																					) : (
																						<i
																							class="fa fa-times"
																							onClick={this.deleteCSFile}
																							id={
																								LP_POC_CS_SubmissionPopUP.supportDocID
																							}
																							name={
																								LP_POC_CS_SubmissionPopUP.csFileName
																							}
																						/>
																					)}

																					{/* <i class="fa fa-times" onClick={this.deleteCSFile} id={LP_POC_CS_SubmissionPopUP.supportDocID} name={LP_POC_CS_SubmissionPopUP.csFileName} /> */}
																				</a>{" "}
																				{LP_POC_CS_SubmissionPopUP.csFileName
																					? LP_POC_CS_SubmissionPopUP.csFileName
																					: ""}
																			</div>
																		) : (
																			""
																		)}
																		<div
																			id={
																				"csFileMessage" +
																				LP_POC_CS_SubmissionPopUP.supportDocID
																			}
																			style={{ color: "green" }}></div>
																		{/* <label id="POCFile" style={{fontWeight:'300'}}>No file Choosen</label> */}
																	</div>
																)}
																<br />
																<label id="label" className="CSStatusPub" style={{ fontWeight: "400" }}>		{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}	
																	CSStatus:&nbsp;
																</label>
																
																{/* Nilesh-4240-Removing console warnings */}
																{
																	LP_POC_CS_SubmissionPopUP.csStatus == "" ||
																	LP_POC_CS_SubmissionPopUP.csStatus == null ? (
																		"pendingSubmission"
																	) : LP_POC_CS_SubmissionPopUP.csStatus ===
																			"AdvertiserReviewPending" ||
																	  LP_POC_CS_SubmissionPopUP.csStatus ===
																			"AdvertiserReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AdvertiserReviewPending
																		</label>
																	) : LP_POC_CS_SubmissionPopUP.csStatus ===
																			"AdvertiserApproved" ||
																	  LP_POC_CS_SubmissionPopUP.csStatus ===
																			"AdvertiserApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : LP_POC_CS_SubmissionPopUP.csStatus ===
																			"AgencyReviewPending" ||
																	  LP_POC_CS_SubmissionPopUP.csStatus ===
																			"AgencyReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AgencyReviewPending
																		</label>
																	) : LP_POC_CS_SubmissionPopUP.csStatus ===
																			"AgencyApproved" ||
																	  LP_POC_CS_SubmissionPopUP.csStatus ===
																			"AgencyApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : (
																		<label style={{ fontWeight: "400" }}>
																			Rejected
																		</label>
																	)
																	//  <label style={{fontWeight:'400'}}>{LP_POC_CS_SubmissionPopUP.csStatus}</label>
																}
																<br />
																{LP_POC_CS_SubmissionPopUP.csFeedback == "" ||
																LP_POC_CS_SubmissionPopUP.csFeedback == null ||
																LP_POC_CS_SubmissionPopUP.csFeedback ==
																	undefined ? (
																	""
																) : (
																	<div>
																		<label
																			id="label"	className="CSStatusPub"		//chaitanya-task-4107-Publisherside- Review - Creatives review
																			style={{ fontWeight: "400" }}>
																			Feedback:
																		</label>
																		{LP_POC_CS_SubmissionPopUP.csFeedback}
																		{/* <TextArea readonly>{LP_POC_CS_SubmissionPopUP.csFeedback}</TextArea>  */}
																	</div>
																)}
																<br />
																	{/* 4288-Sanjana-Added condition-AdvertiserRejected */}
																	{/* Nilesh-4240-Removing console warnings */}
																{LP_POC_CS_SubmissionPopUP.csStatus ===
																	"Rejected" ||
																LP_POC_CS_SubmissionPopUP.csStatus ===
																	"ClientRejected" ||
																	LP_POC_CS_SubmissionPopUP.csStatus ===
																	"AdvertiserRejected"  ? (
																	<div>
																		<label
																			id="label"	className="CSStatusPub"		//chaitanya-task-4107-Publisherside- Review - Creatives review
																			style={{ fontWeight: "400" }}>
																			Feedback File Name k:&nbsp;
																		</label>
																		<a
																			href="#/"  //Nilesh-4240-Removing console warnings
																			supportDocID={
																				LP_POC_CS_SubmissionPopUP.supportDocID
																			}
																			csFeedbackFileName={
																				LP_POC_CS_SubmissionPopUP.csFeedbackFileName
																			}
																			onClick={this.handleCSFeedbackFileDownload.bind(
																				this
																			)}>
																			{
																				LP_POC_CS_SubmissionPopUP.csFeedbackFileName
																			}
																		</a>
																	</div>
																) : (
																	""
																)}
															</td>
														) : (
															""
														)}

														{/* Ending of CS column condition */}
													</tr>
												);
											}
										)}
									</tbody>
								</table>
							) : (
								""
							)}
						</div>
						<div style={{ textAlign: "right" }}>
							{" "}
							<button class="btn add-button" type="HTMLsubmit">
								Submit
							</button>
						</div>
					</div>
				</form>
				<Footer />
			</div>
		);
	}
}

PublisherCreativeUploads.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(PublisherCreativeUploads)
);
