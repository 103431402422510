/* Copyright(c) 2022 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*@author Vrushabh patil task- 4439
*@fileName : LeadReportByCampaign.js
@Creation Date:21-04-2021
 *Desc: UI for Agency View- -Lead Report by Campaign  for story 3413
*/
import React from "react";
// import { Tabs, Tab } from 'react-bootstrap';//kiran-2834-removing console warning-no used
import Navigation from "../layouts/navPage";
// import * as $ from "jquery"; //kiran-2834-removing console warning-no used
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import './agencyMyReports.css';
import { css } from "emotion";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
// import styled from "styled-components";//kiran-2834-removing console warning-no used
import { Table, } from 'antd'; //rutuja 4486 added radio button
// import { truncate } from "fs";//kiran-2834-removing console warning-no used
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import moment from 'moment';
import { saveAs } from "file-saver";
import { LeadReviewLoader } from "../loaders/LeadReviewLoader";
const xlsx = require("xlsx");
const Swal = require('sweetalert2');
var selectedCampaign = [];

const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "12px",
		fontWeight: "500",
	},
	"& thead > tr >th": {
		textAlign: "center",
		color: "white",
	},
});
//kiran-2834-removing console warning-no used
// const queryString = require('query-string');

// const PickyStyle = styled(Picky)
//     `
	
// background-color: #bd141480 !important;
// border-radius: '4px !important'

// #-list.picky__dropdown{
//   overflow:'hidden !important';
// }
// .picky__placeholder {
//     color: #000000 !important;
// }
// .picky {
//     width:148px !important;
//     height:32px !important;
//   }
//  `
//     ;


//sunita - 3413 - added array  for lead status .
const leadStatusOptions =
[
    
    { id: "Delivered", name: "Delivered" },
    { id: "Review-Pending", name: "Review-Pending" },
    { id: "Accepted", name: "Accepted" },
    { id: "Rejected", name: "Rejected" }
];

//sunita - 3413 - added array  for campaign status .
const campaignStatusOptions =
    [
        { id: "Active", name: "Active" },
        // { id: "Paused", name: "Paused" }, //sunita-task-3570-commented the paused camp status as per requirement.
        { id: "Completed", name: "Completed" }
    ]

class leadReportByCampaign extends React.Component {
    constructor() {
        super();
        this.state = {
            tableState: { size: "small" },
            agencyAdvertiserCampaignInfo: [],
            agencyMyReportsDataNew1: [],
            agencyMyReportsData: [],
            advertiserList: [],
            advertiserList1: [],
            agencyID: "",
            advertiserID: "Select Advertiser",
            campaignStatus: [],
            invoiceDetail: [],
            startDate: "",
            endDate: "",
            label: "",
            reportKey: "",
            type: "Advertiser Lead Tracking",
            reportName: "",
            generateBtnFlag: false,
            advID: '',
            userType: "Advertiser",
            campID: "Select Campaign",
            getCampaignListByADV: [],
            advLeadDetailsDelivery: [],
            token: "",
            deliveryDetails: [],
            leadStatus: [],
            campaignName: "",
            campaignStatusArray: [],
            leadIndeterminate: true,
            leadCheckAll: false,
            campaignLeadStatusArray: [],
            checkedColumnKeys: [],
            drpdisabled_report: "",
            advcountDetails: [],
            customQuestionCount: 0,
            clientCampID: "Select Campaign",
            loader_progress: "",
            pageSize2:5, //rutuja 4486-added pagesize for table
            getCampaignListByADVSearch:[],
            selectedAdvertiserName: "" //Chaitanya-5329-Added this to set selected Adv

        }//end of state

        //sunita-task-3413-binded all below mentioned handle changes as per requirement
        this.onCampaignStatusHandleChange = this.onCampaignStatusHandleChange.bind(this);
      //  this.onCampaignHandleChange = this.onCampaignHandleChange.bind(this); 
        this.handleChange = this.handleChange.bind(this);//vrushabh
        this.onLeadStatusHandleChange = this.onLeadStatusHandleChange.bind(this);
        this.searchHandleChange = this.searchHandleChange.bind(this);
        this.handleChangePageSize = this.handleChangePageSize.bind(this);
        this.onAdvertiserNameHandleChange = this.onAdvertiserNameHandleChange.bind(this);
        this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
        this.onCampaignDetailsHandleChange = this.onCampaignDetailsHandleChange.bind(this);
        this.handledownloadLead = this.handledownloadLead.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);//rutuja 4486 searching table data  
        this.loadingRef = React.createRef();
        this.closeLoadingRef = React.createRef();
    }// end of constrcutor

    /**
    * @author : Vrushabh patil task- 4439
    * @description : handlechange for checkbox in manage column .
    */
   
    handledownloadLead(e) {
        let advLeadDetailsDelivery = this.state.advLeadDetailsDelivery;
        let advertiserList = this.state.advertiserList;
        let advertiserID = this.state.advertiserID;
       let getCampaignListByADV = this.state.getCampaignListByADV;
        if (advLeadDetailsDelivery.length > 0) {
            delete advLeadDetailsDelivery["statusToDisplay"];
            
            var ws = xlsx.utils.json_to_sheet(advLeadDetailsDelivery);
            
            var wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, ws, "Lead");
            var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (let i = 0; i !== s.length; ++i)
                    view[i] = s.charCodeAt(i) & 0xff;
                return buf;
            }
       
            let clientCampID = getCampaignListByADV[0].clientCampID;
            
           let campID = this.state.campID;
           let advertiserName = "";
        for(let i=0;i<advertiserList.length;i++)
        {
            //kiran-2834-added toString() and changed == to ===
            if(advertiserList[i].advertiserID.toString() === advertiserID){
                advertiserName = advertiserList[i].advertiserName;
            }
        }
          
            var fileName = advertiserID + "_"+ advertiserName + "_" + clientCampID + "_"+ campID + "- Lead Report By Campaign.xlsx";
            saveAs(
                new Blob([s2ab(buf)], { type: "application/octet-stream" }),
                fileName
            );
        }

    }

    handleChange(e) {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let advertiserID = this.state.advertiserID;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let campID = this.state.campID;
        let campaignStatusArray = this.state.campaignStatusArray;
    
        if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate")
         && (advertiserID && advertiserID !== "Select Advertiser") 
        && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campID && campID !== "Select Campaign") 
        && (campaignStatusArray && campaignStatusArray.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0});//kiran-2834-removing console warning-use setState
            }
         

        if (e.target.name.toString() === "startDate") { //kiran-2834-added toString() and changed == to ===
            let date = e.target.value;
            date = moment().format('mm/dd/yyyy');
            let dateArray = date.split('/');
            if (dateArray.length === 0) {
                dateArray = date.split('-');
            }


            this.setState({
                startDate: e.target.value
            })
        }
        else if (e.target.name.toString() === "endDate") {//kiran-2834-added toString() and changed == to ===
            this.setState({
                endDate: e.target.value
            })
        }




    } // End of showInvoice
   
    leadOnChange = checkedColumnKeys => {

        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let advertiserID = this.state.advertiserID;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let campID = this.state.campID;
        let campaignStatusArray = this.state.campaignStatusArray;
    
        if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate")
         && (advertiserID && advertiserID !== "Select Advertiser") 
        && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campID && campID !== "Select Campaign") 
        && (campaignStatusArray && campaignStatusArray.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0});//kiran-2834-removing console warning-use setState
            }
         

        if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch1")) {
            checkedColumnKeys.push("assetTimestampTouch1");
        } else {
            let index = checkedColumnKeys.indexOf("assetTimestampTouch1");
            if (index > -1) {
                checkedColumnKeys.splice(index, 1);
            }
        }

        if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch2")) {
            checkedColumnKeys.push("assetTimestampTouch2");
        } else {
            let index = checkedColumnKeys.indexOf("assetTimestampTouch2");
            if (index > -1) {
                checkedColumnKeys.splice(index, 1);
            }
        }

        if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch3")) {
            checkedColumnKeys.push("assetTimestampTouch3");
        } else {
            let index = checkedColumnKeys.indexOf("assetTimestampTouch3");
            if (index > -1) {
                checkedColumnKeys.splice(index, 1);
            }
        }


        this.setState({
            checkedColumnKeys,
            leadIndeterminate: !!checkedColumnKeys.length && checkedColumnKeys.length < this.state.checkBoxObjArray.length,
            leadCheckAll: checkedColumnKeys.length === this.state.checkBoxObjArray.length,
        });

    };


    /**
   * @author : Vrushabh patil task- 4439
   * @description : handlechange for pageSize .
   */


    handleChangePageSize(e) {
        var pageSize2 = e.target.value;
        this.setState({ pageSize2: pageSize2 });

    }//end of handleChangePageSize

    /**
    * @author : Vrushabh patil task- 4439
    * @description : handlechange for backbutton to navigate on same page from where user came .
    */
    backButtonHandleChange(e) {
        e.preventDefault();
        this.props.history.push("/newdashboard") //rutuja - task-4484 Back button should redirect to dashboard page
    }//end of backButtonHandleChange


    /**
    * @author : Vrushabh patil task- 4439
    * @description :  handleChange to load the campaign after selecting advertiser and campaign status .
    */

    onCampaignDetailsHandleChange(advertiserID, campaignStatusArray) {
        const { user } = this.props.auth;
        const agencyID = user.id;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let campID = this.state.campID;
        this.setState({ agencyID })
      
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        let advertiserID1 = this.state.advertiserID;
        let campaignStatusArray1 = this.state.campaignStatusArray;
    
        if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate")
         && (advertiserID1 && advertiserID1 !== "Select Advertiser") 
        && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campID && campID !== "Select Campaign") 
        && (campaignStatusArray1 && campaignStatusArray1.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0});//kiran-2834-removing console warning-use setState
            }
     
        //sunita-task-3570-added below condition to close the loader when all filters are having values. 
        if ((advertiserID && advertiserID !== "Select Advertiser") && (campID && campID !== "Select Campaign") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0)) {

         
        }
        let advcountDetails = [{ "advertiserID": advertiserID, "advertiserName": "", "deliveryLabel": "", "totalAllocation": 0, "totalAccepted": 0, "deliveredLead": 0, "rejectedLead": 0, "qaReviewLead": 0, "balanceLead": 0 ,"totalcount":0,"InternalReview":0,"Advertiser Rejected":0,"publisherRejected":0    }]
        this.setState({ agencyID, advcountDetails, advLeadDetailsDelivery: [], });

        if ((advertiserID && advertiserID !== "Select Advertiser") && (campaignStatusArray && campaignStatusArray.length > 0)) {
            let data = {

                //agencyID: agencyID,                                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
                advertiserID: advertiserID,
                campaignStatus: campaignStatusArray,
                advertiserName: this.state.selectedAdvertiserName //Chaitanya-5329-passing Adv name to get correct data from BE
            }

            fetch("/advertiserReportEngine/getCampaignListByADV", {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then(getCampaignListByADV => {
                    this.setState({ getCampaignListByADV: getCampaignListByADV})
                    this.setState({getCampaignListByADVSearch: this.state.getCampaignListByADV});//rutuja 4486 
                   
                    //rutuja 4504 commented below the code from front end and added at back end as it taking to much time to load data in table
                     //rutuja 4486- for deselecting radio button
                    // if (this.state.getCampaignListByADV.length > 0) {
					// 	var name = "key";
					// 	var value;
					// 	for (var i = 0; i < this.state.getCampaignListByADV.length; i++) {
					// 		value = this.state.getCampaignListByADV[i].campID;
					// 		let getCampaignListByADV = [...this.state.getCampaignListByADV];
                          
					// 		getCampaignListByADV[i] = { ...getCampaignListByADV[i], [name]: value };
					// 		this.setState({ getCampaignListByADV: getCampaignListByADV });
                    //         this.setState({getCampaignListByADVSearch: this.state.getCampaignListByADV});//rutuja 4486 
                           
					// 	}
					// }
                }).catch(function (err) { console.log(err) });


        }

    }


    /**
    * @author : Sunita Landge - task 3406
    * @description : handlechange to target selected advertiser .
    */

    onAdvertiserNameHandleChange(e) {

        let startDate1 = this.state.startDate;
        let endDate1 = this.state.endDate;
        let advertiserID1 = this.state.advertiserID;
        let campaignLeadStatusArray1 = this.state.campaignLeadStatusArray;
        let campID1 = this.state.campID;
        let campaignStatusArray1 = this.state.campaignStatusArray;

        //Chaitanya-5329-taking Adv name list and and adv
        let advertiserList = this.state.advertiserList;
        var advertiserID = e.target.value;
        
        //Chaitanya-5329-added to Find the selected advertiser's name from the advertiserList
        const selectedAdvertiser = advertiserList.find(advertiser => advertiser.advertiserID.toString() === advertiserID);
        const advertiserName = selectedAdvertiser ? selectedAdvertiser.advertiserName : '';
        this.setState({selectedAdvertiserName: advertiserName});
    
        if ((startDate1 && startDate1 !== "Select startDate") && (endDate1 && endDate1 !== "Select endDate")
         && (advertiserID1 && advertiserID1 !== "Select Advertiser") 
        && (campaignLeadStatusArray1 && campaignLeadStatusArray1.length > 0) && (campID1 && campID1 !== "Select Campaign") 
        && (campaignStatusArray1 && campaignStatusArray1.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0});//kiran-2834-removing console warning-use setState
            }
         
        var advertiserID = e.target.value;
        let startDate = e.target.value;
        let endDate = e.target.value;

        let campaignStatusArray = this.state.campaignStatusArray;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
       
      


        let campID = ""; //sunita-task-3582 -  added code for displaying the particular adv data in table.
        this.setState({ advertiserID, campID: "", advLeadDetailsDelivery: [] }) //sunita-task-3582-added code for showing blank data if data is not present in table.
        
        if ((advertiserID && advertiserID !== "Select Advertiser") && (campaignStatusArray && campaignStatusArray.length > 0)) {

            this.onCampaignDetailsHandleChange(advertiserID, campaignStatusArray); //sunita-task-3413-function called to load the campaign after selecting advertiser and campaign status .
        }



        //sunita-task-3575-added below condition for - if same campaign having by number of advertiser So data should load in table.
        if (campaignStatusArray.length === 0 || campaignStatusArray == []) { //not resolved-giving issue
            this.setState({ campID: "Select Campaign", generateBtnFlag: false, advLeadDetailsDelivery: [] })
        }
        else if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate") && (advertiserID && advertiserID !== "Select Advertiser") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campID && campID !== "Select Campaign") && (campaignStatusArray && campaignStatusArray.length > 0)) {
          

        }
        else {
            this.setState({ generateBtnFlag: false, advLeadDetailsDelivery: [] })

        }


    }//end of onAdvertiserNameHandleChange


    /**
    * @author : Vrushabh patil task- 4439
    * @description : handlechange to target selected campaignstatus .
    */

    onCampaignStatusHandleChange(campaignStatus) {

        var campID = this.state.campID;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let advertiserID1 = this.state.advertiserID;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let campaignStatusArray1 = this.state.campaignStatusArray;
    
        if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate")
         && (advertiserID1 && advertiserID1 !== "Select Advertiser") 
        && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campID && campID !== "Select Campaign") 
        && (campaignStatusArray1 && campaignStatusArray1.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0});//kiran-2834-removing console warning-use setState
            }
         
    
        let campaignStatusArray = [];
        let checkActive = campaignStatus.some(o => o.name === "Active")
        if (checkActive) { campaignStatusArray.push("Active") }

        //sunita-task-3570-commented the paused checkbox as per requirement
        // let checkPaused = campaignStatus.some(o => o.name === "Paused")
        // if (checkPaused) { campaignStatusArray.push("Paused") }
      
       
        let checkCompleted = campaignStatus.some(o => o.name === "Completed")
        if (checkCompleted) { campaignStatusArray.push("Completed") }

        let advertiserID = this.state.advertiserID;
        // let campID = this.state.campID;
        this.setState({ campaignStatus, campaignStatusArray })
        if (campaignStatusArray.length === 0 || campaignStatusArray == []) { //not resolved-giving issue
            this.setState({ campID: "Select Campaign", generateBtnFlag: false, advLeadDetailsDelivery: [] })
        }
        else if ((advertiserID && advertiserID !== "Select Advertiser") && (campaignStatusArray && campaignStatusArray.length > 0)) {
            this.onCampaignDetailsHandleChange(advertiserID, campaignStatusArray);//sunita-task-3413-function called to load the campaign after selecting advertiser and campaign status .
        }
        else {
            this.setState({ generateBtnFlag: false, advLeadDetailsDelivery: [] })
        }


    }//end of onCampaignStatusHandleChange


    /**
    * @author : Vrushabh patil task- 4439
    * @description : handlechange to target selected leadstatus .
    */

    onLeadStatusHandleChange(leadStatus) {
        let campaignLeadStatusArray = [];
        let checkAccepted = leadStatus.some(o => o.name === "Delivered")
        if (checkAccepted) { campaignLeadStatusArray.push("Delivered") }

        let checkRejected = leadStatus.some(o => o.name === "Review-Pending")
        if (checkRejected) { campaignLeadStatusArray.push("Review-Pending") }

        let checkInQA = leadStatus.some(o => o.name === "Accepted")
        if (checkInQA) { campaignLeadStatusArray.push("Accepted") }

        let checkInDiQA = leadStatus.some(o => o.name === "Rejected")
        if (checkInDiQA) { campaignLeadStatusArray.push("Rejected") }

        // let checkInQA = leadStatus.some(o => o.name === "In QA")
        // if (checkInQA) { campaignLeadStatusArray.push("In QA") }
        

        let advertiserID = this.state.advertiserID;
        var campID = this.state.campID;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let campaignStatusArray = this.state.campaignStatusArray;
        let campaignLeadStatusArray1 = this.state.campaignLeadStatusArray;
    
        if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate")
         && (advertiserID && advertiserID !== "Select Advertiser") 
        && (campaignLeadStatusArray1 && campaignLeadStatusArray1.length > 0) && (campID && campID !== "Select Campaign") 
        && (campaignStatusArray && campaignStatusArray.length > 0))
            {
                // this.state.leadCount=0;
                this.setState({leadCount:0});//kiran-2834-removing console warning-use setState
            }
         


        this.setState({ leadStatus, campaignLeadStatusArray })
        if ((advertiserID && advertiserID !== "Select Advertiser") && (campID && campID !== "Select Campaign") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campaignStatusArray && campaignStatusArray.length > 0)) {

        }
        else {
            this.setState({ generateBtnFlag: false, advLeadDetailsDelivery: [] })
        }

    }




    /**
    * @author : Rutuja Jagtap - task 4439
    * @description : handleChange for table data after selecting values from all filters .
    */

    searchHandleChange(e) {
      //  let advcountDetails = [{ "advertiserID": advertiserID, "advertiserName": "", "deliveryLabel": "", "totalAllocation": 0, "totalAccepted": 0, "deliveredLead": 0, "rejectedLead": 0, "qaReviewLead": 0,  "balanceLead": 0 ,"totalcount":0,"InternalReview":0,"Advertiser Rejected":0,"publisherRejected":0  }]
       // this.setState({ advcountDetails, generateBtnFlag: false, advLeadDetailsDelivery: [] });
        // alert("advcountdetails=>" + JSON.stringify(advcountDetails));
      
     this.setState({ loader_progress: "loader_campaign_list" });//rutuja -4486 added loader

        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        const { user } = this.props.auth;
        const agencyID = user.id;
        let campaignStatusArray = this.state.campaignStatusArray;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
         let campID= this.state.campID;
         let advertiserID = this.state.advertiserID;
        this.setState({ agencyID })

            if ((advertiserID && advertiserID !== "Select Advertiser") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campID && campID !== "Select Campaign") && (endDate && endDate !== "Select Campaign")) {
           
                let data = {
                    campID: campID,
                    endDate: endDate,//vrush
                    startDate: startDate,
                    //agencyID: agencyID,                                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
                    leadStatus: campaignLeadStatusArray,
                    advertiserID: advertiserID,
                    campaignStatus: campaignStatusArray,

                }
                
                fetch("/advertiserReportEngine/advLeadDetailsDelivery", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'

                    },
                    body: JSON.stringify(data)
                }).then(res => res.json())
                    .then(advLeadDetailsDelivery => {
                        this.setState({ loader_progress: "" }); //4486-stoping loader                       
                        if(advLeadDetailsDelivery.result.length>0)
                        {
                            this.setState({ advLeadDetailsDelivery: advLeadDetailsDelivery.result, })
                            let leadCount = this.state.advLeadDetailsDelivery.length;
                            this.setState({leadCount : leadCount});
                        }
                        else{
                            Swal.fire({
                                type: "warning",
                                title: "No records Found",
                                confirmButtonText: "Ok",
                                allowOutsideClick: false,
                                //rutuja task-4505 Page refresh issue
                                preConfirm: () => {
                                 window.location.reload();
                                  },
                              });
                        }
                     
                    }).catch(function (err) { console.log(err) });
                   

            }
           

            else{
                Swal.fire({
                    type: "warning",
                    title: "Please Select All Mandatory Fields",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false
                  });
                  this.setState({ loader_progress: "" }); //4486-stoping loader 
            }

        }
//rutuja 4486-commented onCampaignHandleChange as no more required

    // /**
    //    * @author : Vrushabh patil task- 4439
    //    * @description : handleChange for campaign dropdown .
    //    */

    // onCampaignHandleChange(e) {
    //     let campID = e.target.value;
    //     this.setState({ campID });
    //     //this.setState({clientCampID});
    //     let advertiserID = this.state.advertiserID;
    //     let campaignLeadStatusArray = this.state.campaignLeadStatusArray;

      
    //     let checkName = this.state.getCampaignListByADV.filter(obj => obj.campID == campID)
   
    //     let campaignName = "";
    //    let startDate = this.state.startDate;
    //     let endDate = this.state.endDate;
    //     let campaignStatusArray = this.state.campaignStatusArray;
    //     if ((startDate && startDate !== "Select startDate") && (endDate && endDate !== "Select endDate") && (advertiserID && advertiserID !== "Select Advertiser") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campID && campID !== "Select Campaign") && (campaignStatusArray && campaignStatusArray.length > 0)) {
           
    //         this.setState({leadcount:0})
    //     }

    //     if (checkName && checkName.length > 0) {
    //         campaignName = checkName[0].campaignName
    //         //clientCampID=clientCampID[0].clientCampID

    //     }
    //     this.setState({ campaignName })



    //     if ((advertiserID && advertiserID !== "Select Advertiser") && (campID && campID !== "Select Campaign") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0)) {


    //        // this.searchHandleChange(advertiserID, campaignLeadStatusArray, campID);//sunita-task-3413-function called to load the table data after selecting values from all filters .vrushabh
    //     }
    //     else {
    //         this.setState({ generateBtnFlag: false, advLeadDetailsDelivery: [] })
    //     }


    // }

   /**
       * @author : Rutuja jagtap task- 4486
       * @description : Searching table information
       */
        
     handleChangeSearch(e) {
		e.preventDefault();
       let currentList = [];
		let newList = [];
    
		let filterData = e.target.value;
        
         
        
		// If the search bar isn't empty
		if (e.target.value === "") {
           
			newList = this.state.getCampaignListByADVSearch;
         
		} else {
           
			// Assign the original list to currentList
			currentList = this.state.getCampaignListByADVSearch;
            
			newList = currentList.filter((item) => {
				let obj = {};// Add specific Keys in search criteria
				obj[`campID`] = item.campID;
				 obj[`campaignName`] = item.campaignName;
				obj[`agencyCampID`] = item.clientCampID;
			
				const lc = obj;
				
				const filter = e.target.value.toLowerCase();
				
				var data = Object.values(lc);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
          
		}
   
		// Set the filtered state based on what our rules added to newList
		this.setState({
			getCampaignListByADV: [...newList],
			tableSearchText: filterData,
		});
	}

    
    
    /**
* @author : rutuja jagtap task 4439
* @description : for disable screen if report key is present in url
*/
    componentWillMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
        }
        else {                                                          //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
            let reportKey, reportID, advertiserID, reportName, type;
            if (this.props.location.state !== undefined) {
                advertiserID = this.props.location.state.advID;
                reportKey = this.props.location.state.reportKey;
                reportID = this.props.location.state.reportID;
                // this.state.reportID = reportID;
                reportName = this.props.location.state.reportName;
                // this.state.reportName = reportName;
                this.setState({reportID:reportID , reportName:reportName});//kiran-2834-removing console warning-use setState
                type = this.props.location.state.type;
            }

            // const { isAuthenticated } = this.props.auth;//kiran-2834-removing console warning-no used
            const { user } = this.props.auth;
            const agencyID = user.id;
            this.setState({
                agencyID: agencyID,
                advertiserID: advertiserID,
                reportKey: reportKey,
                generateBtnFlag: false,
                reportName: reportName,
                type: type,
                
            })

           


            let data = {
                advertiserID: advertiserID,
                
            }

            //sunita-task-3413-api fetched for getting advertiser list
            fetch("/advertiserReportEngine/clientNameDetails", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //authorization: token,
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then(advertiserList => {

                    this.setState({ advertiserList, advertiserList1: advertiserList })

                }
                ).catch(function (err) { console.log(err) });



        }

    }//end of componentWillMount



    /**
* @author : rutuja jagtap
* @description :Render part to display complete screen with functionality as per given screen .
*/

    render() {
        console.log(this.state)
        const asterisk =
        {
            color: 'red',
        }
//rutuja task-4486  added table 
        const columnsReport = [
            {
                title: <span>agencyCampID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campID",
                key: "campID",
                width: "22%",

                align: "left",
                sorter: (a, b) => a.campID - b.campID,
                
                render: (text, record) => (
                    <div style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.clientCampID}
                        </span>
                        <br />
                    </div>

                ),
                

            },
            {
                title: <span>Camp ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campID",
                key: "campID",
                width: "15%",

                align: "left",
                sorter: (a, b) => a.campID - b.campID,
                
                render: (text, record) => (
                    <div class="dot1" style={{ width: "75px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.campID}
                        </span>
                        <br />
                    </div>

                ),
                

            },
            {
                title: <span>Campaign Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
                dataIndex: "campID",
                key: "campID",
                width: "58%",

                align: "left",
                sorter: (a, b) => a.campID - b.campID,
                
                render: (text, record) => (
                    <div  class="dot1" style={{ width: "300px" }}>
                        <span style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3486- added css as per DI standard*/}
                            {record.campaignName}
                        </span>
                        <br />
                    </div>

                ),
                

            },
           ]

            const rowSelection = {
                onChange: (selectedRows) => {
                     selectedCampaign = selectedRows;
                  
                   this.setState({
                    campID: selectedCampaign
                    
                })
                },
                
                onSelection: this.onSelection,
               hideDefaultSelections: true,
                type: "radio",
                getCheckboxProps: (record) => ({
				disabled: record.name === "Disabled User", // Column configuration not to be checked
				name: record.name,
			}),
            };
            
         
    

        function onChange(pagination, filters, sorter) {
            console.log("params", pagination, filters, sorter);
        }

        return (
            <div>
            {/* Priyanka-4413-Added Loader */}
            <LeadReviewLoader
              loadingRef={this.loadingRef}
              closeLoadingRef={this.closeLoadingRef}
              message="Please Wait. Report is generating..."
            />
            
                <Navigation />
                {/* 4486 */}
                <div style={{ paddingBottom: '10px', paddingTop: '100px' }} class="container-fluid ">
                    <div class="row">


                        <div class="col-sm-12 col-md-1 col-lg-2" >
                            {/*3721-Rutuja Kale-VAPT-removing # -Agency side--Reports menu--URL Links & API-FE*/}
                            {/* kiran-2834-removing console warning-added "#/" */}
                            <a href={"#/"} onClick={this.backButtonHandleChange} style={{ color: '#056eb8' }}><FaArrowAltCircleLeft size={32} title="Back to agencyMyReport" /></a>

                        </div>


                        <div class=" col-xs-12 col-sm-12 col-md-8 offset-lg-2 col-lg-4" align="center">
                            <label id="labelDI"> Lead Report By Campaign</label>
                        </div>

                        {/* //rutuja task-4486  chnage the UI sturcture for adding table  */}
                    </div>
                  
                    <div class="row">
                        <div class="col-lg-6" style={{ marginTop:"1%"}}>
                        <div class="col-lg-6" id="AD_ALTD" >
                    <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Advertiser&nbsp;:<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;</label><br />
                                
                                <select 
                                defaultValue={this.state.clientID}
                                value={this.state.advertiserID} 
                                class="form-control" 
                                name="AdvertiserName" 
                                id="for_res" 
                                style={{ width: '220px', height: '34px', border: '1px solid #cccccc', backgroundColor: "#FFFFFF", borderRadius: '3px', fontFamily: 'sans-serif', color: '#555555' }} 
                                onChange={this.onAdvertiserNameHandleChange}
                                >
                                    <option value={"Select Advertiser"} 
                                    style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }}>
                                        Select Advertiser</option>
                                    {this.state.advertiserList.map((advertiserList, i) => (
                                        <option value={advertiserList.advertiserID} key={i} 
                                        style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }}>
                                            {advertiserList.advertiserID}-{advertiserList.advertiserName}</option>))}
                                </select>
                    </div>
                    <div class="col-lg-6" id="AD_ALTD" >
                    <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Campaign Status&nbsp;:<span style={asterisk}>*</span>&nbsp;</label><br />
                                <div
                                        style={{ width: '220px', border: " 1px solid #CCCCCC", borderRadius: " 3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: " #555555" }}>
                                        <Picky
                                            value={this.state.campaignStatus}
                                            options={campaignStatusOptions}
                                            onChange={this.onCampaignStatusHandleChange}
                                            open={false}
                                            valueKey="id"
                                            labelKey="name"
                                            multiple={true}
                                            includeSelectAll={true}
                                            includeFilter={false}
                                            placeholder='Select Status'
                                            dropdownHeight={200}
                                            numberDisplayed={0}
                                            id="CampaignStatus"
                                            name="CampaignStatus"
                                            className={"PickyStyle"}
                                        />
                                    </div>
                    </div>
                        <div class="col-lg-6" id="AD_ALTD" style={{ marginTop:"3%"}}><label  id="label" style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}><b>Start date </b> <span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                        {/* kiran-2834-removing console warning-changed classname */}
                            <input 
                            style={{ width: '220px', border: " 1px solid #CCCCCC", borderRadius: " 3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: " #555555" }}
                            type="date" id="startDate" name="startDate" ref={(input) => { this.nameInput = input; }} min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"

                                value={this.state.startDate}
                                // className={this.state.inputClassSdate}
                                onChange={this.handleChange}
                                // onClick={this.showInvoice}
                                // className="form-control" 
                                className={`${this.state.inputClassSdate} form-control`}
                                /></div>
                   
                    <div class="col-lg-6   " id="AD_ALTD" style={{ marginTop:"3%"}}>
                    <label id="label" style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}><b>End date </b> <span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>

                            <input 
                            style={{ width: '220px', border: " 1px solid #CCCCCC", borderRadius: " 3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: " #555555" }}
                            type="date" id="endDate" name="endDate" ref={(input) => { this.nameInput = input; }} min="" required pattern="[0-9]{2}-[0-9]{}-[0-9]{4}"
                                value={this.state.endDate}
                                onChange={this.handleChange}
                                //onClick={this.onCampaignLeadDetailsHandleChange}
                                className="form-control"
                                />
                
                    </div>
                    <div class="col-lg-12  " id="AD_ALTD" style={{ marginTop:"3%"}}>
                      <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Lead Status&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;</label><br />
                            
                                <div
                                    style={{ width: '220px', border: " 1px solid #CCCCCC", borderRadius: " 3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: " #555555" }}>
                                    <Picky
                                         value={this.state.leadStatus}
                                         options={leadStatusOptions}
                                         onChange={this.onLeadStatusHandleChange}
                                        open={false}
                                        valueKey="id"
                                        labelKey="name"
                                        multiple={true}
                                        //includeSelectAll={true}
                                        placeholder={'Select Status'}
                                       // includeFilter={true}
                                        dropdownHeight={200}
                                        numberDisplayed={0}
                                        id="leadStatus"
                                        name="leadStatus"
                                        className={"PickyStyle"}
                                    /></div>
                
                    </div>
                    
                    </div>
                    <div align="center"  id={this.state.loader_progress}></div>
                    <div class="col-lg-6" style={{ marginTop:"1%"}}>
                    <div class="row">
                        <div class="col-lg-6" id="AD_ALTD" >
                        <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Campaign &nbsp;:<span style={asterisk}>*</span>&nbsp;</label><br />                        </div>
                                     <div class="col-xs-12 col-sm-12 col-md-12  col-lg-6 col-xl-6">
								<input
									id="search-bar1"
									type="text"
									onkeyup="myFunction()"
                                    
									style={{
										backgroundImage: "url(Group_294.png)",
										backgroundPosition: "15px 6px",
										backgroundSize: "15px 15px",
										backgroundRepeat: "no-repeat",
										width: "100%",
										height: "20px",
										fontSize: "10px",
										border: "1px solid #ddd",
										padding: "12px 20px 12px 34px",
										borderRadius: "25px",
										backgroundColor: "rgb(221, 221, 221)",
                                        margin: "0px 0px 20px 10px",
                                        
									}}
									onChange={this.handleChangeSearch}
									onPressEnter={this.handleChangeSearch}
									placeholder="Search..."
									title="Type in a name"
								/>
							</div>
                            </div>  
                            {/* kiran-2834-removing console warning-changed classname */}
                            
                                     <Table
								{...this.state.tableState}
								rowSelection={rowSelection}
                                name ="test"
								id="myTable"
								bordered
								dataSource={this.state.getCampaignListByADV}
								loading={this.state.loading2}
								onChange={onChange}
								columns={columnsReport}
								// className="ant-table-thead table-responsive"
								// className={tableCSS}
                                className={`${tableCSS} "ant-table-thead table-responsive"`}
								scroll={{ y: 150 }}
                                 //Mufiz-Task-3910 responsiveness issue
								pagination={{ pageSize: this.state.pageSize2 }}></Table>            
 
                    </div>
                    <div class="col-lg-12 " id="AD_ALTD"  style={{ marginTop:"1%"}}>
                    <button
                                            type="button"
                                            class="btn add-button"
                                           
                                            onClick={this.searchHandleChange}>
                                         Search
                                        </button>
                
                    </div>
                  
                    </div>
                             
                                    
                                    <div class="row" style={{ marginTop:"1%"}}>
                        {this.state.leadCount > 0 ? (
                        <div style={{ backgroundColor: 'white',width:"85%", marginLeft:"20px", marginBottom: '30px', border: '1px solid #cccccc', borderRadius: '3px' }}>
                            <div style={{ paddingLeft: "10px", paddingTop: "10px", paddingBottom: '10px' }}>
                                    {/* <div>
                                        {this.state.totalCount > 0 ? ( */}
                                                <div class="row">

                                                        <div class="col-sm-2 col-md-8 col-lg-8">

                                                            <label style={{ color: "#14254A",paddingTop: "1%",paddingLeft: "2%" }}>
                                                                <span>Total Leads Count&nbsp;:&nbsp;</span>
                                                                <span style={{ color: "#14254A" }}>
                                                                    &nbsp;{this.state.leadCount}
                                                                </span>
                                                            </label>
                                                            
                                                        </div> 
                        
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <button
                                                                    type="button"
                                                                    class="btn add-button" 
                                                                    style={{ float:"Right",marginRight: "40%"}}
                                                                    value={this.state.clientLeadDetails}
                                                                    onClick={this.handledownloadLead}>
                                                                    Download
                                                            </button>
                                                        </div>
                                                </div>
                                        {/* ):("")}
                                    </div> */}
                            </div>
                        </div>
                     ):("")}
                    </div></div>
                <Footer />

                </div>
            
            
        );
    }
}

leadReportByCampaign.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(leadReportByCampaign));

