/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file UnauthorizedPage Page
 * @author Aman Shaikh 5084
 * @version 1.0
 * @section use for admin (internal use only)
 */
import React from "react";
import Header from "../src/components/layouts/header1";
import Footer from "../src/components/layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../src/components/login/authentication";
import { withRouter } from "react-router-dom";
import { Icon, Tooltip } from "antd";
import "datatables.net";
import "antd/dist/antd.css";

import "./unauthorized.css"

class UnauthorizedPage extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.replace("/userLogin");
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
      if (!this.props.auth.isAuthenticated) {
        this.props.history.replace("/userLogin");
      }
    }
  }

  handleGoBack = () => {
    if (this.props.history.length > 1) {
      this.props.history.goBack();
    } else {
      this.props.history.replace("/"); //Aman-5084- Redirects to home if no history
    }
  };

  LogintoContinue = () => {
    this.props.history.replace("/userLogin");
    window.location.reload();
  };

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>;//Aman-5084-Shows a loading state
    }

    return (
      <div>
      <Header/>
        <div className="unauthorized container-fluid" style={{ justifyContent: "center"}}>
          <div class="lock"></div>
          <div class="message">

            <h1 style={{display: "flex", alignItems:"center"}}>
            <img className="unauthorized-lock-img" src="unauthorized-lock.gif" alt="Lock" srcset="" />Access to this page is restricted
            </h1>
            <p>Please check your login credentials. you don't have permission to access this page</p>
          </div>
          <div className="unauthorized-action-btn">
            <Tooltip title={<span>Go back to the previous page</span>}>{/* <Icon type="arrow-left"/> */}
              <button className="unauthorized-back-btn" onClick={this.handleGoBack}>Go Back</button>
            </Tooltip>
            <Tooltip title={<span>If you have access ? Log in to Continue</span>}>
              <button className="unauthorized-login-btn" onClick={this.LogintoContinue}>Log in</button>
            </Tooltip>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
UnauthorizedPage.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(UnauthorizedPage)
);
