/* Copyright(c) 2022 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Rutuja Jagtap task-3879
@Creation Date:20-07-2022
@Description:UI for (QADashboard) campaign specification
*/

import React,{ Component } from 'react'
import Navigation from '../layouts/navPage';
import Footer from '../layouts/footer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
import CampaignSpecificationQADashboardDetails from "./campaignSpecificationQADashboardDetails";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";

class CampaignSpecificationQADashboard extends Component{

    constructor() {
       
        super();
        this.state = {
              campID: '',
              parentCampID:'',
              reallocationID:'',
              advertiserID:'',
              campaignName:'',
        }
        this.handleBackButtonToDashboard=  this.handleBackButtonToDashboard.bind(this);
        this.handleFileDownload = this.handleFileDownload.bind(this);
        this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
        this.handlePDFFileShowDownload = this.handlePDFFileShowDownload.bind(this);
    }

    componentDidMount() {  
      if(!this.props.auth.isAuthenticated) {
        this.props.history.push('/userLogin');
      }
      else{
        if(this.props.location.state!==undefined){
          var parsed = this.props.location.state;
          var campID=parsed.campID;
        }
        else if (sessionStorage.getItem("campID") !== undefined) {
           campID = sessionStorage.getItem("campID");
        } else {
          if (sessionStorage.getItem("parentCampID") === undefined) {
            sessionStorage.clear();
          }
        }

      fetch("campaign/CampaignDetails?campID=" + campID)
      .then(res => res.json())
      .then(campaignDetails =>{
        this.setState({campaignName:campaignDetails[0].campaignName});
        }).catch(function (err) {
        console.log(err)
      });

    }
    }
    
    handleBackButtonToDashboard(e){
      //kiran-4752-removing console warning-added e.preventDefault();
      e.preventDefault();
          this.props.history.push("/dashboardQA",{advertiserID:this.state.advertiserID})//kiran-4630-navigate to proper page when click on back button
      }
    
    handleFileDownload(e) {
        var campID=this.state.campID;
         /**
       * @author rutuja jagtap
       * @param  Description handle the Campaign related Files
       * @return Description return All The File Download in Zip
       */
        
       fetch("agency/downloadDynamicPDFCampaignDetailsforAgency?campID=" + campID + "&campName="+this.state.campaignName ).then(r => r.blob())
          .then(downloadFiles =>
            this.setState({ downloadFiles: downloadFiles })
            )
            .then(this.handlePDFFileShowDownload);

          fetch("agency/downloadCampaignDetailsforAgency?campID=" + campID + "&campName="+this.state.campaignName ).then(r => r.blob())
          .then(downloadFiles =>
            this.setState({ downloadFiles: downloadFiles })
            )
            .then(this.handleFileShowDownload);
        }/** End of handle File Download */

        handleFileShowDownload(blob){
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            let zipFileDownloadName=this.state.campID+'-'+this.state.campaignName+".zip";
          
            var newBlob = new Blob([this.state.downloadFiles], {type: "application/zip"})
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            } 
           
            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download=zipFileDownloadName;
            link.click();
          
          // ));
        }

        handlePDFFileShowDownload(blob){
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            let zipFileDownloadName=this.state.campID+'-'+this.state.campaignName+"-CampaignSpecification.pdf";
             var newBlob = new Blob([this.state.downloadFiles], {type: "application/pdf"})
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            } 
           
            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download=zipFileDownloadName;
            link.click();
          
          // ));
  
          }
           
           

    render()
    {
      if(this.props.location.state!==undefined ){
      // this.state.campID=this.props.location.state.campID;
      // this.state.parentCampID=this.props.location.state.parentCampID
      // this.state.reallocationID=this.props.location.state.reallocationID;
      this.setState({campID:this.props.location.state.campID,parentCampID:this.props.location.state.parentCampID,reallocationID:this.props.location.state.reallocationID})
      //kiran-4752-removing console warning-use setState
      }
      else if (sessionStorage.getItem("campID") !== undefined) {
        this.state.campID = sessionStorage.getItem("campID");
        this.state.parentCampID = sessionStorage.getItem("parentCampID");
        this.state.reallocationID = sessionStorage.getItem("reallocationID");//not use SetState-giving issue of page crash
      }
        return(
            <div>
           
           <Navigation/>
           {/* Nilesh-4839 Add display, flexDirection,flex, backgroundColor, paddingTop, position, top and zIndex in below div */}
           <div style={{paddingTop:'110px', display: "flex", flexDirection: "column"}}>

             <div class="container-fluid fixed-top" style={{
						flex: "0 0 auto",
						backgroundColor:"rgb(245 245 245)",
						paddingTop: "10px",
						position: "sticky",
						top: "79px",
						zIndex: "999"
					}}>
             <div class="row" style={{paddingBottom:'20px'}}>
             {/* kiran-4752-removing console warning-added "#/" */}
             <div class="col-lg-3 col-md-2 col-sm-3"><a href="#/"> <FaArrowAltCircleLeft size={32} style={{float:'left',color:'#056eb8'}} title="Back to Dashboard" onClick={this.handleBackButtonToDashboard} /></a></div>
            
              <div class="col-lg-6 col-md-8 col-sm-6" align="center">
                <label 
                       style={{                       
                        color: "#193D8F",
                        fontSize: "28px",                       
                        fontWeight:'500',
                        fontFamily:'roboto'}}>
                          Campaign ID:{this.state.campID}</label></div> 
             
             <div class="col-lg-3 col-md-2 col-sm-3" ><button type="button"class="btn add-button" style={{float:'right'}} id="cmd"
                onClick={this.handleFileDownload}>Download</button></div>
                </div>
           </div>
          
              <CampaignSpecificationQADashboardDetails  greeting={this.state.campID}/> 
          
                
                 

               <div class="container-fluid" style={{paddingBottom:'70px'}}>
                 <div class="row">
                   <div class="col-lg-5" style={{paddingTop:'20px'}}><button type="button"class="btn add-button" id="cmd" style={{float:'left'}}
                onClick={this.handleFileDownload}>Download</button></div> 
                 </div>
               </div>
           </div>
           <Footer/>
            </div>
        )
    }
};

/**
       * @author Rutuja Jagtap
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
 CampaignSpecificationQADashboard.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(CampaignSpecificationQADashboard)); 
