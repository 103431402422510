/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file Bidding Section of Publisher Dashboard Page
 * @author  Karan Jagtap
 * @version 1.0
 * */
import React, { Component } from "react";
import { Table, Button, Badge } from "antd";
import "../dashboardPublisher.css";

/**
 * @author Karan Jagtap
 * @description Task-3635 Bidding Section class which is a child component to newPublisherDashboard.js (Publisher Dashboard) page
 */
class BiddingSection extends Component {
	/**
	 * @author Karan Jagtap
	 * @description Task-3635-only re-render if one of the below values changes
	 */
	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.newCampaignLength !== nextProps.newCampaignLength) {
			return true;
		}
		if (
			this.props.headExampleNewCampaign !== nextProps.headExampleNewCampaign
		) {
			return true;
		}
		if (this.props.counterLength !== nextProps.counterLength) {
			return true;
		}
		if (this.props.headExampleCounter !== nextProps.headExampleCounter) {
			return true;
		}
		if (this.props.RFPLength !== nextProps.RFPLength) {
			return true;
		}
		if (this.props.headExampleRFP !== nextProps.headExampleRFP) {
			return true;
		}
		if (this.props.loading1 !== nextProps.loading1) {
			return true;
		}
		if (this.props.showRFPTBL !== nextProps.showRFPTBL) {
			return true;
		}
		if (this.props.pageSize1 !== nextProps.pageSize1) {
			return true;
		}
		//karan-task-3686-allowing multiple re-renders even for constant values
		if (this.props.columns1 !== nextProps.columns1) {
			return true;
		}
		if (this.props.RFPColumn !== nextProps.RFPColumn) {
			return true;
		}
		if (
			JSON.stringify(this.props.biddingArray) !==
			JSON.stringify(nextProps.biddingArray)
		) {
			return true;
		}
		return false;
	}

	render() {
		return (
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
				{/* <h3 style={{fontFamily:'Lora'}}>Bidding</h3> */}
				<div
					style={{
						background: "#ECECEC",
						padding: "10px 15px 10px 15px",
						height: "430px",
					}}
					className="biddingDiv">
					{/* <p> */}
					<h3 style={{ fontFamily: "roboto" }}>Bidding</h3>
					{/* </p> */}
					<br />
					<p>
					<div className="bidding-main" style={{display: "flex", justifyContent: "space-between"}}> {/* Aman - 4595 - (Added div for alignment between buttons)PC- Accept Campaign - Select box /Select All option required */}
						<div className="bidingTab"> {/* Aman - 4595 - (Added div separation between buttons)PC- Accept Campaign - Select box /Select All option required */}
							<Badge
								count={this.props.newCampaignLength}
								overflowCount={10}
								offset={[1, -5]}>
								<Button
									size="default"
									style={{ borderRadius: "40px" }}
									className={this.props.headExampleNewCampaign}
									id="NewCampaign"
									onClick={this.props.biddingLink}>
									New
								</Button>
							</Badge>

							<Badge
								count={this.props.counterLength}
								overflowCount={10}
								offset={[1, -5]}>
								<Button
									size="default"
									style={{ borderRadius: "40px" }}
									className={this.props.headExampleCounter}
									id="Counter"
									onClick={this.props.biddingLink}>
									Counter
								</Button>
							</Badge>
						
							{this.props.role === "PC" ? (
								<Badge
									count={this.props.RFPLength}
									overflowCount={10}
									offset={[1, -5]}>
									<Button
										size="default"
										style={{ borderRadius: "40px" }}
										className={this.props.headExampleRFP}
										id="RFP"
										onClick={this.props.biddingLink}>
										RFP
									</Button>
								</Badge>
							) : (
								""
							)}
						</div>

						{/* Aman - 4595 - (Added for Accept all Campaign)PC- Accept Campaign - Select box /Select All option required */}
						{this.props.role === "PC" ? (
						<div className="acceptAllbtn">
							{this.props.newCampaignLength === 0 ? (
								""
								): (
								<Badge style={{ borderRadius: "40px"}}
									count={this.props.newCampaignLength}
									overflowCount={10}
									offset={[1, -5]}>
								
									<Button
										size="default"
										style={{ borderRadius: "40px"}}
										onClick={this.props.biddingLink}
										data-toggle="modal"
										data-target="#acceptAll"
										>
										Accept all Campaigns
									</Button>
								</Badge>
							)}
						</div>	
						) : (
							""
						)}
					</div>
					</p>	
						
					<p>
						{" "}
						<Table
							id="myTable"
							bordered
							dataSource={this.props.biddingArray}
							loading={this.props.loading1}
							columns={
								this.props.showRFPTBL === true
									? this.props.RFPColumn
									: this.props.columns1
							}
							className="ant-table-thead table-responsive"
							size="small"
							className={this.props.tableCSS}
							scroll={{ x: 200, y: 150 }}
							pagination={{ pageSize: this.props.pageSize1 }}></Table>
					</p>
					{/*shivani-task 2919 - added y :150 for vertical scrollbar*/}
					<p>
						<div class="row">
							<div
								class="col-xs-6 col-sm-6 col-md-5 col-lg-6 col-xl-6"
								style={{ marginTop: "-70px", marginLeft: "5px" }}>
								Records per page:&nbsp;
								<select
									defaultValue={this.props.pageSize1}
									onChange={this.props.handleChangePageSize1}
									id="pacing1"
									class="input-small"
									className="form-control"
									name="pacing"
									style={{
										width: "65px",
										height: "30px",
										display: "initial",
									}}>
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="15">15</option>
									<option value="20">20</option>
								</select>
							</div>
						</div>
					</p>
				</div>
			</div>
		);
	}
}

export default React.memo(BiddingSection);
