/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Somnath Keswad
@Creation Date:18/11/2019
@Description:UI for  Lead API URL
*/
import React from "react";
import {Icon,Tooltip} from 'antd';
import Footer from '../layouts/footer';
var dateTime = require("node-datetime");//Somnath Task-3403, date time to get date time in specified format
class ApiLead extends React.Component {

    constructor(){
        super();
        this.state={
          url:'',
          leadDetails:'',
          loaderSymbol:'',
          btnDisable:false,
          displayTable:'none',
          displayPostString:'none',
          tblValue:0,
          postStringValue:0,
          checkUrl:'',
        }
        this.handleChange=this.handleChange.bind(this);
        this.submitLead=this.submitLead.bind(this);
        this.functionShowTable=this.functionShowTable.bind(this);
        this.showPostString=this.showPostString.bind(this);
    }

    showPostString(e){
      if(this.state.postStringValue===0){
        this.setState({displayPostString:'block',postStringValue:1});
      }else{
        this.setState({displayPostString:'none',postStringValue:0});
      }
    }

    functionShowTable(e){
      if(this.state.tblValue===0){
        this.setState({displayTable:'block',tblValue:1});
      }else{
        this.setState({displayTable:'none',tblValue:0});
      }
    }
    handleChange(e)
   {
    let url = e.target.value;
    this.setState({url:url});
   }
   submitLead(e)
   {
     var checkEmpty=[];
     var leadUrl=this.state.url;
    if(leadUrl===''){
      let message=<li>Please enter lead url first.</li>
      this.setState({checkUrl:message})
    }
    else{
      var clientDateTime= dateTime.create();//Somnath Task-3403, Get current Date time as per specified dateTime
      clientDateTime=clientDateTime.format("Y-m-d H:M:S")
     this.setState({leadDetails:checkEmpty,loaderSymbol:'whirly-loader',btnDisable:true,checkUrl:''})  
        let data={leadUrl:leadUrl,clientDateTime};//Somnath Task-3403, Pass clientDateTime in body
        fetch("leadAPI/leadAPIURL",{
            method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(response =>{
            // alert(JSON.stringify(response))
            this.setState({leadDetails:response,loaderSymbol:'',btnDisable:false})
          }).catch(function(err){console.log(err)});

        }// End of Else
   }
    render() {
        return (
          <div>
           <div><nav className="navbar navbar-expand-md navbar-dark navbar-fixed-top" ><div style={{float:'left'}}>
<img src="DemandIntegrateLogo_White.png" alt="Demand Integrate" height="50px" width="200px"></img></div></nav></div>
  <div class="container-fluid">
           <div class="row" style={{paddingTop:'7%'}}>
           <div class="col-sm-1">
           <Tooltip title="Click here for sample post string">
           <Icon type="question-circle" style={{fontSize:"20px"}} onClick={this.showPostString}/>
           </Tooltip>
             </div>
             <div class="col-sm-11" style={{display:this.state.displayPostString}}>
             <span class="word-wrap">
             <label>Sample Post String :</label> https://demandintegrate.azurewebsites.net/leadAPIURL?campID=1000001&pID=5001&LIDT=MM/DD/YYYY&email=test@dintegrate.com&fname=John&lname=Deo&ADD=Berlin&WP=030-23456789&ALTPH=030-23456790&city=Berlin&state=Berlin&zipcode=10115&country=Germany&companyName=DI&jobTitle=All&
jobLevel=Head&jobFunction=Business&revenue=€5M-€10M&companySize=1-50&industry=Construction&assetName=5-questions-cfo-s-ask.pdf&assetNameTouch1=abc.pdf&assetTimestampTouch1=MM/DD/YYYY&assetNameTouch2=questions.pdf&assetTimestampTouch2=MM/DD/YYYY&assetNameTouch3=abc.pdf&assetTimestampTouch3=MM/DD/YYYY&allocationID=10001&CQ-1=251-500&CQ-2=Yes</span>
             </div>
             </div>
             <br/>
           <div class="row">
            <div class="col-sm-1">
                    <select
                      value={this.state.request}
                      // onChange={this.handleChangeRequest}
                      id="campaignReportingDay"
                      className="form-control"
                      name="request" style={{width:'85px',color:'gray'}}>
                      {/* <option value="GET" disabled>GET</option> */}
                      <option value="POST"  selected>POST</option>
                    </select>
            </div>
            <div class="col-sm-8">
            <textarea id="url" name="url" style={{height:'200px'}}
                      value={this.state.url} onChange={this.handleChange} class="form-control" />
            <p></p>
            <button class="btn add-button" onClick={this.submitLead} disabled={this.state.btnDisable}
         >Send</button>
    <div style={{color:'Red'}}>{this.state.checkUrl}</div>
        <div className={this.state.loaderSymbol} style={{left:'500px',bottom:'96px'}}></div>
                  <p></p>
         {this.state.leadDetails.length>0?JSON.stringify(this.state.leadDetails):''}
           </div>
           <div class="col-sm-3">
           <Tooltip title="Click here for field descreption">
           <Icon type="question-circle" style={{fontSize:"20px"}} onClick={this.functionShowTable}/>
           </Tooltip>
           <div style={{display:this.state.displayTable}}>
           <table border="1">
              <thead style={{display:'block'}}>
              <tr class="" style={{backgroundColor:'#f5f5f0'}}>
                  <th class="table-header" style={{width: "118px"}}>Key Name</th>
                  <th class="table-header" style={{width: "180px"}}>Descreption</th>
                </tr>
              </thead>
              <tbody style={{display:'block',overflow:'auto',height:'350px',width:'100%'}}>
                <tr >
                  <td style={{width:'120px'}}>campID</td>
                  <td style={{width:'180px'}}>Campaign ID</td>
                </tr>
                <tr >
                  <td>pID</td>
                  <td>Publisher ID</td>
                </tr>
                <tr>
                  <td>LIDT</td>
                  <td>Lead Interaction Date</td>
                </tr>
                <tr>
                  <td>email</td>
                  <td>E-mail</td>
                </tr>
                <tr>
                  <td>fname</td>
                  <td>First Name</td>
                </tr>
                <tr>
                  <td>lname</td>
                  <td>Last Name</td>
                </tr>
               
                {/* <tr>
                  <td>street</td>
                  <td>Street</td>
                </tr> */}
                <tr>
                  <td>WP</td>
                  <td>Work Phone</td>
                </tr>
                <tr>
                  <td>ALTPH</td>
                  <td>Alternate Phone No</td>
                </tr>
                <tr>
                  <td>ADD</td>
                  <td>Address</td>
                </tr>
                <tr>
                  <td>street</td>{/*Somnath Task:3002, Add Street*/}
                  <td>Street</td>
                </tr>
                <tr>
                  <td>city</td>
                  <td>City</td>
                </tr>
                <tr>
                  <td>state</td>
                  <td>State</td>
                </tr>
                <tr>
                  <td>zipcode</td>
                  <td>Zip Code</td>
                </tr>
                <tr>
                  <td>country</td>
                  <td>Country</td>
                </tr>
                <tr>
                  <td>companyName</td>
                  <td>Company Name</td>
                </tr>
                <tr>
                  <td>linkedInCompanyName</td>{/*Somnath Task:3002, Add linkedIn CompanyName*/}
                  <td>LinkedIn Company Name</td>
                </tr>
                <tr>
                  <td>jobTitle</td>
                  <td>Job Title</td>
                </tr>
                <tr>
                  <td>linkedInJobTitle</td>{/*Somnath Task:3002, Add linkedIn JobTitle*/}
                  <td>LinkedIn Job Title</td>
                </tr>
                <tr>
                  <td>jobLevel</td>
                  <td>Job Level</td>
                </tr>
                <tr>
                  <td>jobFunction</td>
                  <td>Job Function</td>
                </tr>
                
                <tr>
                  <td>revenue</td>
                  <td>Company Revenue</td>
                </tr>
                <tr>
                  <td>companySize</td>
                  <td>Company Size</td>
                </tr>
                <tr>
                  <td>industry</td>
                  <td>Industry</td>
                </tr>
                <tr>
                  <td>assetName</td>
                  <td>Asset Name</td>
                </tr>
                <tr>
                  <td>assetNameTouch1</td>
                  <td>Asset Name Touch 1</td>
                </tr>
                <tr>
                  <td>assetTimestampTouch1</td>
                  <td>Asset Timestamp Touch 1</td>
                </tr>
                <tr>
                  <td>assetNameTouch2</td>
                  <td>Asset Name Touch 2</td>
                </tr>
                <tr>
                  <td>assetTimestampTouch2</td>
                  <td>Asset Timestamp Touch 2</td>
                </tr>
                <tr>
                  <td>assetTimestampTouch3</td>
                  <td>Asset Timestamp Touch 3</td>
                </tr>
                <tr>
                  <td>assetNameTouch3</td>
                  <td>Asset Name Touch 3</td>
                </tr>
               
                <tr>
                  <td>assetID</td>
                  <td>Asset ID</td>
                </tr>
                <tr>
                  <td>channel</td>{/*Somnath Task:3002, Add Channel*/}
                  <td>Channel</td>
                </tr>
                <tr>
                  <td>allocationID</td>
                  <td>Allocation ID</td>
                </tr>
                <tr>
                  <td>CQ-1</td>
                  <td>Custom Question 1</td>
                </tr>
                <tr>
                  <td>CQ-2</td>
                  <td>Custom Question 2</td>
                </tr>
                <tr>
                  <td>CQ-3</td>
                  <td>Custom Question 3</td>
                </tr>
                <tr>
                  <td>CQ-4</td>
                  <td>Custom Question 4</td>
                </tr>
                <tr>
                  <td>linkedIn</td>
                  <td>Linked In</td>
                </tr>
                <tr>
                  <td>domain</td>
                  <td>Domain</td>
                </tr>
                
                <tr>
                  <td>comments</td>
                  <td>Comments</td>
                </tr>
                <tr>
                  <td>ip</td>
                  <td>IP Address</td>
                </tr>
                <tr>
                  <td>extra1</td>
                  <td>Extra 1</td>
                </tr>
                <tr>
                  <td>extra2</td>
                  <td>Extra 2</td>
                </tr>
                <tr>
                  <td>extra3</td>
                  <td>Extra 3</td>
                </tr>
                <tr>
                  <td>extra4</td>
                  <td>Extra 4</td>
                </tr>
                <tr>
                  <td>extra5</td>
                  <td>Extra 5</td>
                </tr>
              </tbody>
            </table>
            </div>
           </div>
           </div>
           </div>
           <Footer />
          </div>
        );
      }
}
export default ApiLead;