/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Shivani Pathak
@Creation Date:05/06/2020
@Description:UI for  display Invoices History
*/
import React from "react";
import Navigation from "../layouts/navPage";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import * as $ from "jquery";
import { DatePicker } from "antd";
import Footer from '../layouts/footer';
import { MDBRow} from "mdbreact";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
import moment from "moment";
// import Table from "ant-responsive-table";
import { Table} from 'antd';
import { css } from 'emotion';
import { Tooltip} from 'antd';
// import './agencyInvoicesList.css';
import './invoiceHistory.css';
import { RecordReference } from "jsforce";
const { MonthPicker} = DatePicker;
const Swal = require('sweetalert2');
// const tableCSS = css({
//   backgroundColor: 'white',
//   borderStyle: 'bold',
//   '& thead  > tr': {
//     backgroundImage:  'linear-gradient(to right,#28166f,#007cc3) !important',
//     color: 'white',
//     //fontSize:'12px',
//     fontWeight:'500',
//   },
//   '& thead > tr >th': {
//    // border: '1px solid black',
//     color: 'white',
//   }
// });

const tableCSS = css({
  backgroundColor: 'white',
  '& thead > tr > th': {
    backgroundColor: '#3863A0',
    color: 'white',
    fontWeight:'500',
    // wordWrap:'break-word',
    // height:'10px',
    // wordBreak:"break-all",
    // overflowWrap:'inherit',
  },
  '& thead > tr': {
    borderWidth: '2px',
    borderStyle: 'solid'
  }
});
const queryString = require('query-string');
class AgencyInvoiceList extends React.Component {
    constructor() {
        super();
        this.state = {
          newsuccess:"",
          invoiceDetail:[],
          startDate:'',
          endDate:'',
          allPublisherList:[],
          finalInvoiceDetail:[],
          pubName:[],
          disableButton:true,
          fields: {
            startDate:'',endDate:''
          },
          errors: {},
          dateerrors:{},
          pID:'',
          invoiceGenerationDate:'',
          btnGenerateDisable:true,
          generateInvoicepID:'',
          invoiceHistory:[]
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleBackButton=  this.handleBackButton.bind(this);
        this.showInvoice = this.showInvoice.bind(this);
        this.submitInvoiceFeedback = this.submitInvoiceFeedback.bind(this);
        this.handleFileDownload=this.handleFileDownload.bind(this);
        this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
        this.reviewFeedbackFile=this.reviewFeedbackFile.bind(this);
    }// end of constrcutor
    publisherHandleChange(e){
      let fields = this.state;
      fields[e.target.name] = e.target.value;
      this.setState({ fields });
      let pID=e.target.value;
      this.setState({publisherName:pID})
      // let startDate = this.state.fields.startDate;
      // let endDate = this.state.fields.endDate;
      let publisherName=this.state.publisherName;
      
      //alert(this.state.startDate+"==="+this.state.endDate+"==="+this.state.publisherName);
    if ((!fields["startDate"]) || (!fields["endDate"]))
     {
        this.setState({ disableButton: true })
     }
     else
     {
      if((publisherName==="" || publisherName===undefined || publisherName==='undefined')){
           this.setState({ disableButton: true })
      }else{
          this.setState({ disableButton: false })
      }
     }
}
handleChange(e) {
  let fields = this.state;
  fields[e.target.name] = e.target.value;
  this.setState({ fields });
  let startDate = this.state.fields.startDate;
  let endDate = this.state.fields.endDate;
  let publisherName=this.state.publisherName;
  if ((!fields["startDate"]) || (!fields["endDate"]))
  {
    this.setState({ disableButton: true });
  }
  else
  {
    if((publisherName==="" || publisherName===undefined || publisherName==='undefined')){
      this.setState({ disableButton: true });
    }else{
       this.setState({ disableButton: false })
    }
  }
}// End of handleChange

showInvoice(e) {
    e.preventDefault();
    let self=this;
    e.target.className += " was-validated";
    let startDate=self.state.fields.startDate;
    let endDate=self.state.fields.endDate;
    let publisherName=self.state.publisherName;
    // alert(publisherName)
    let finalInvoiceDetail=[...self.state.finalInvoiceDetail];
    let final=finalInvoiceDetail.filter((a)=>{
        return a.createdDate>=startDate && a.createdDate<=endDate && a.pID==publisherName;
    });
    self.setState({invoiceDetail:final});
    if (self.validateForm() === false) { 
        return;
    }
    else {
            self.setState({errors: ''});
    } 
}// End of showInvoice


validateForm()
{
    let IsValid = true;
    let fields = this.state.fields;
    let startDate=fields.startDate;
    let endDate=fields.endDate;
    let errors = {};
    let dateerrors={};
    let formIsValid = true;
    if ((!fields["startDate"]) || (!fields["endDate"]))
    {
      formIsValid = false;
      errors["commonError"] = <li>Please fill below required fields.</li>;
      }
    
    if (startDate !== undefined)
    {
      this.setState({ inputClassSdate: "valid"})
    }
 
   
    if (startDate=== "")
    {
      this.setState({inputClassSdate: "invalid"}) 
    }
    if (endDate!== undefined)
    {
        this.setState({inputClassEdate:"valid"})
    }
    if (endDate==="")
    {
      this.setState({inputClassEdate: "invalid"})
    }
    if ((Date.parse(endDate) < Date.parse(startDate))) {
        formIsValid = false;
        dateerrors["endDate"]= <li>End date must be greater than start date</li>; 
    }
 
    this.setState({ errors: errors, dateerrors:dateerrors,newsuccess:'',
     alertBorder: '1px solid red', alertWidth: '1000px',
      alertAlign: 'center', alertDisplay: 'block'});

    return formIsValid;
 }// end of validate

componentDidMount(){
   if (!this.props.auth.isAuthenticated) {
     this.props.history.push('/userLogin');
   }
   else{
   const {user} = this.props.auth;
   var agencyID=user.id;
   let localStorageData=[];
   let localStorageData1=[];
    // localStorageData = JSON.parse(localStorage.getItem("invoiceKey"));
    localStorageData=(JSON.parse(localStorage.getItem('invoiceKey')));
    localStorageData1.push(JSON.stringify(localStorageData));
  //  alert(localStorageData1[0])
  //akash-Bug--4036-4037--4039-4040
   fetch("agencyDashboard/getInvoiceHistory")
     .then(res => res.json())
     .then(invoiceDetail =>{
       let data=[];
      if(localStorageData.length>0){
        let pID=localStorageData[0].pID;
        let month=localStorageData[0].month;
        let year=localStorageData[0].year;
        data=invoiceDetail.filter((a)=>{
          return a.pID==pID && a.month==month && a.year==year;
        })
      }
       this.setState({invoiceDetail:data,finalInvoiceDetail:invoiceDetail},function(){
        // alert(JSON.stringify(this.state.invoiceDetail))
       })}
     ).catch(function(err){console.log(err)});
      // Get All Publisher List of current Agency
     fetch("agencyDashboard/publisherForInvoice")//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
     .then(res => res.json())
     .then(allPublisherList =>
       this.setState({allPublisherList:allPublisherList,pubName:allPublisherList})
      //  alert(JSON.stringify(this.state.allPublisherList))
     ).catch(function(err){console.log(err)});
  }
 
} // end of componentDidMount()
handleBackButton(e){
  const {user} = this.props.auth;
  localStorage.removeItem('invoiceKey');
  this.props.history.push("/agencyInvoicesList");//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
 // window.location.href="/agencyInvoicesList?agencyID="+user.id;
}
handleInvoiceFeedback(i,e){
    const { name, value } = e.target;
    let invoiceDetail=[...this.state.invoiceDetail];
    invoiceDetail[i] = { ...invoiceDetail[i], [name]:value };
    this.setState({ invoiceDetail,feedbackFlag:true });
}// End of handleInvoiceFeedback

downloadInvoiceDocument(e){
    var filename=e.target.id;
    var invoiceID=e.target.getAttribute('invoiceID');
    var ext=filename.split('.').pop();
    this.setState({ extension:ext,filename:filename})
    const {user}=this.props.auth;
   //Sanjana-3925-VAPT--publisher side-finance-Invoice- API-FE----pID removed
   let data1={filename:filename,invoiceID:invoiceID}
    fetch("/publisherDashboard/downloadInvoiceDocument",{
       method:"POST",
       headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data1)
     }).then(r => r.blob())
      .then(downloadFiles =>
      {
        this.setState({ downloadFiles: downloadFiles})
      }).then(this.handleFileDownload)
}// End of downloadInvoiceDocument

handleFileDownload(blob){
    const {user}=this.props.auth;
    let zipFileDownloadName=user.id+"_invoice_documents.zip";
    var newBlob = new Blob([this.state.downloadFiles], {type: "application/zip"})
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    } 
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download=zipFileDownloadName;
    link.click();
}// End of handleFileDownload

uploadFeedbackFile(e){
  e.preventDefault();
  var invoiceID=e.target.id;
  if(e.target.files[0]==null||e.target.files[0]==undefined||e.target.files[0]=="")
  {
  }
  else
  {
    var feedbackfile=e.target.files[0];
    var fileName=feedbackfile.name;
    var ext=fileName.split('.').pop()
    if(ext!==undefined){
        ext=(ext).toLowerCase()
    }
    if(ext==="xls"||ext==="xlsx"||ext==="csv")
    {
        var data = new FormData();  
        data.append("invoiceID",invoiceID);
        data.append("feedbackFile",feedbackfile);
        fetch("/publisherDashboard/uploadFeedbackFile",{
        method:'POST',
        body: data
        }).then(res => res.json())
        .then(res => {
        if(res.success===true){
            Swal.fire({
            text:'File Uploaded Successfully',
            type: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            })
            var link= '<i class="fa fa-times removeFilePOC" style="color:red;" id='+'"'+invoiceID+'"'+'name='+'"'+fileName+'"'+'></i>'
            var HTML = "<table>";
            HTML += "<tr><td>"+link+"&nbsp;&nbsp;&nbsp;&nbsp;"+fileName+"</td></tr>";
            HTML += "</table>";
            document.getElementById("feedBackFileDiv2"+invoiceID).innerHTML=HTML
        }
        }).catch(function(err){console.log(err)});
    }
    else
    {
        Swal.fire({
        type:'error',
        html:"Please upload file of <b>.xlsx/xls</b> format only"
        })
    }
    $(document).off().on("click",".removeFilePOC",function(e)
    {
        var id=e.target.id;
        var fileName=e.target.getAttribute('name');
        let data={id:id,fileName:fileName}
        fetch("publisherDashboard/deleteFeedBackFile",{
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(data)
            }).then(res =>res.json())
              .then(res => {
               if(res.success==true){
                Swal.fire({
                            text: ("File Deleted Successfully"),
                            type: 'success',
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            preConfirm:()=>{
                                var nooutput = document.getElementById("feedBackFileDiv2"+id);
                                nooutput.innerHTML="No File Choosen";
                            }
                })
             return
               }
             }).catch(function (err) {console.log(err)});
        })
    }
    var invoiceIdForFile=e.target.id;
    this.setState({invoiceIdForFile:invoiceIdForFile,feedbackfile:feedbackfile,feedbackFlag:true});
}// End of uploadFeedbackFile
submitInvoiceFeedback(e){
   e.preventDefault();
   if(this.state.feedbackFlag==false)
   {
        Swal.fire({             
                text: "Please Fill Feedback Or Upload a Feedback File ",
                type: 'error',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
        });
   }
   else
   {  
    const {user}=this.props.auth; 
     //Sanjana-3925-VAPT--publisher side-finance-Invoice- API-FE----user removed
    let data={ dynamicArray: this.state.invoiceDetail}//Akash-task-4057
    fetch("/publisherDashboard/submitFeedback",{
     method:'POST',
     headers: {"Content-Type": "application/json"},
     body: JSON.stringify(data)
    }).then(res => res.json()).then(res => {
    if(res.success === true) {
        Swal.fire({
                text: ("Feedback Submitted Successfully"),
                type: 'success',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                preConfirm: () => {
                   window.location.reload();
                }
        })
    }
    }).then(function (response) {
       console.log(response);
    }).catch(function(err){console.log(err);});
   }
}
/**
 * @author Somnath Keswad
 * @param  Description  Get the invoice document from database
 * @return Description return response in Blob
 */
getInvoiceDocument(e){
  const {user}=this.props.auth;
  const agencyID=user.id;
  let invoiceDocumentName=e.target.getAttribute('invoiceDocumentName');
  // alert("invoiceDocumentName===>"+invoiceDocumentName)
  let invoiceID=e.target.getAttribute('invoiceid');
  // alert("invoiceID===>"+invoiceID)
  let pID=e.target.getAttribute('pid');
  // alert("pID===>"+pID)
  //alert("===="+this.state.filename)
  // alert("invoiceDocumentName===>"+invoiceDocumentName)
  this.setState({filename:invoiceDocumentName});
  //akash-Bug--4036-4037--4039-4040
  fetch("/agencyDashboard/invoiceHistoryDocument?invoiceID="+invoiceID)
        .then(r => r.blob())
        .then(downloadFiles =>
          // alert("downloadFiles===>"+downloadFiles)
          this.setState({ downloadFiles: downloadFiles,pID:pID })
          )
          .then(this.handleFileShowDownload);  
} // End of getInvoiceDocument

handleFileShowDownload(blob){
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  // alert("here")
  let zipFileDownloadName=this.state.pID+"_Invoice_Documents.zip";

  var newBlob = new Blob([this.state.downloadFiles], {type: "application/zip"})
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  } 
 
  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download=zipFileDownloadName;
  link.click();

// ));
}
downloadFeedbackFile(e){
  // var invoiceID=e.target.id;
  const {user}=this.props.auth;
  const agencyID=user.id;

  let invoiceID=e.target.getAttribute('invoiceid');
  // alert("invoiceID===>"+invoiceID)
  var feedbackFIleName=e.target.getAttribute('fileName');
  // alert("feedbackFIleName===>"+feedbackFIleName)
  var extension=feedbackFIleName.split('.').pop();
  this.setState({extension:extension,filename:feedbackFIleName});
      fetch("/agencyDashboard/invoiceHistoryFeedbackFile?invoiceID="+invoiceID)//Akash-task-4057
      .then(r => r.blob())
      .then(downloadFiles =>
      this.setState({ downloadFiles: downloadFiles}))
      .then(this.reviewFeedbackFile);
}// End of downloadFeedbackFile
/**
 * @author Somnath Keswad
 * @param  Description  Get the invoice document from array
 * @return Description download the file as per extension
 */
reviewFeedbackFile(blob){
  if(this.state.extension==='pdf'){
      let url=this.state.filename;
      let fileName=url.substring(url.lastIndexOf('\\')+1);
      let pdfFileDownloadName=fileName;
      let newBlob = new Blob([this.state.downloadFiles], {type: "application/pdf"});
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
      } 
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      window.open(data, '_blank')
      link.download=pdfFileDownloadName;
      link.click();
  }
  if(this.state.extension==='xlsx' || this.state.extension==='xls'|| this.state.extension==='csv'){ 
    let url=this.state.filename;
    let fileName=url.substring(url.lastIndexOf('\\')+1);
    let newBlob = new Blob([this.state.downloadFiles], {type: "application/vnd.ms-excel"})
    if(window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
    } 
    const data = window.URL.createObjectURL(newBlob);
    let link = document.createElement('a');
    link.href = data;
    link.download=fileName;
    link.click();
    }

    if(this.state.extension==='ppt' || this.state.extension==='pptx'){  
      let url=this.state.filename;
      let fileName=url.substring(url.lastIndexOf('\\')+1);
      let pptFileDownloadName=fileName;
      let newBlob = new Blob([this.state.downloadFiles], {type: "application/vnd.ms-powerpoint"})
      if(window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      } 
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      link.download=pptFileDownloadName;
      link.click();
  }

  if(this.state.extension==='docx'){
    let url=this.state.filename;
    let fileName=url.substring(url.lastIndexOf('\\')+1);
    let docFileDownloadName=fileName;
    let newBlob = new Blob([this.state.downloadFiles], {type: 'application/msword'});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    } 
    const data = window.URL.createObjectURL(newBlob);
    let link = document.createElement('a');
    link.href = data;
    link.download=docFileDownloadName;
    link.click();
}
if(this.state.extension==='txt'){
  let url=this.state.filename;
  let fileName=url.substring(url.lastIndexOf('\\')+1);
  let docFileDownloadName=fileName;
  let newBlob = new Blob([this.state.downloadFiles], {type: 'text/plain'});
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  } 
  const data = window.URL.createObjectURL(newBlob);
  let link = document.createElement('a');
  link.href = data;
  link.download=docFileDownloadName;
  link.click();
}
if(this.state.extension=='png' || this.state.extension=='jpeg' || this.state.extension=='jpg' || this.state.extension=='PNG' || this.state.extension=='JPEG' || this.state.extension=='JPG'){
    let url=this.state.filename;
    let fileName=url.substring(url.lastIndexOf('\\')+1);
    let docFileDownloadName=fileName;
    let newBlob = new Blob([this.state.downloadFiles], {type: 'application/Image'});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    } 
    const data = window.URL.createObjectURL(newBlob);
    let link = document.createElement('a');
    link.href = data;
    link.download=docFileDownloadName;
    link.click();
}
}/**** End of downloadPOCFie */

 /**
 * @author Somnath Keswad
 * @param  Description  update the invoice as per agency
 * @return Description return agencyReviewed Status
 */
invoiceReviewed(e){
  var invoiceID=e.target.id;
  var pID=e.target.getAttribute('pID');
  var month=e.target.getAttribute('month');
  var year=e.target.getAttribute('year');
  const {user}=this.props.auth;
  Swal.fire({
    text:"This confirm your invoice review is done and ready to share. Do you want to send to Publisher",
    type: 'question',
    confirmButtonText: 'Yes',
    showCancelButton: true,
    cancelButtonColor: '#d33',
    confirmButtonColor: '#3085d6',
    allowOutsideClick: false,
  }).then((result)=>{
    if(result.value===true)
    {
    // var parsed = queryString.parse(this.props.location.search);
// var month=parsed.month;
var userName=user.name;
let data={
        invoiceID:invoiceID,
        pID:pID,
        user:user,
        month:month,
        year:year,
}
fetch("agencyDashboard/agencyInvoiceReview", {
method: "POST",
headers: {"Content-Type": "application/json"},
body: JSON.stringify(data)
}).then(res => res.json())
.then((res =>{
var message="Invoice Reviewed Successfully !!!";
Swal.fire({
  text: (message),
  type: 'success',
  confirmButtonText: 'Ok',
  allowOutsideClick: false,
  preConfirm: () => {
    window.location.reload();
  }
})
})).catch(function (err) {console.log(err)});
    }
  }
  )
} // End of invoiceReviewed 

 /**
 * @author Somnath Keswad
 * @param  Description  update the invoice as per agency
 * @return Description return agencyReviewed Status
 */
paidInvoice(e){
      let self=this;
      var invoiceID=e.target.id;
      var pID=e.target.getAttribute('pID');
      const {user}=this.props.auth;
      Swal.fire({
        text:"Are you sure you want to paid Invoice for this Publisher?",
        type: 'question',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        allowOutsideClick: false,
      }).then((result)=>{
        if(result.value===true)
        {
          let invoiceData=self.state.invoiceDetail.filter((a)=>a.invoiceID==invoiceID);
            let data={
                      invoiceID:invoiceID,
                      pID:pID,
                      user:user,
                      invoiceData:invoiceData,
            }
            fetch("agencyDashboard/markAsInvoicePaid", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
            }).then(res => res.json())
            .then((res =>{
            var message="Invoice Paid Successfully !!!";
                Swal.fire({
                          text: (message),
                          type: 'success',
                          confirmButtonText: 'Ok',
                          allowOutsideClick: false,
                          preConfirm: () => {
                            window.location.reload();
                          }
                })
            })).catch(function (err) {console.log(err)});
        }
      })
} // End of paidInvoice
    /**
 * @author Somnath Keswad
 * @param  Description  Get the popup for review invoice by agency
 * @return Description return status as AgencyReview as per user action
 */
displayInvoicePopup(e){
  var invoiceID=e.target.id;
  var pID=e.target.getAttribute('pID');
  this.setState({invoiceID:invoiceID,pID:pID})
} // End of displayInvoicePopup
dynamicHandleChange(i,e){
    const { name, value } = e.target;
    let invoiceDetail=[...this.state.invoiceDetail];
    invoiceDetail[i] = { ...invoiceDetail[i], [name]:value };
    this.setState({ invoiceDetail});
}// End of dynamicHandleChange

handleMonthYear(date, dateString){
  this.setState({invoiceGenerationDate:dateString})
  if(dateString!=="" && this.state.generateInvoicepID!==""){
    this.setState({btnGenerateDisable:false});
  }
}
generateInvoice(){
  let invoiceGenerationDate=this.state.invoiceGenerationDate;
  const {user}=this.props.auth;
  let data={
    invoiceGenerationDate:invoiceGenerationDate,
    user:user,
    pID:this.state.generateInvoicepID,
  }
  fetch("agencyDashboard/generateNewInvoice", {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(data)
    }).then(res => res.json())
    .then((res =>{
      var success=res.success;
      var message=res.msg;
      if(success===true){
        Swal.fire({
          text: message,
          type: 'success',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          preConfirm: () => {
            window.location.reload();
          }
        });
      }else{
        Swal.fire({
          text:message,
          type: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        })
      }
    }))
}// End of generateInvoice
handleChangePubID(e){
  let generateInvoicepID=e.target.value
  this.setState({generateInvoicepID});
  if(this.state.invoiceGenerationDate!=="" && generateInvoicepID!==""){
    this.setState({btnGenerateDisable:false});
  }
}
render()
{
    // const {user } = this.props.auth;
    
    const asterisk ={color: 'red',}
    
//     const columns1 = [
//       {
//           title: 'Invoice ID',
//           dataIndex: 'invoiceID',
//           key: 'invoiceID',
//           width: '7%',
//           align:'center',
//         // sorter: (a, b) => a.invoiceID - b.invoiceID,
        
//          render: (text,record) => 
//       <div class="dot_1"><span>{record.invoiceID}</span></div>
//         //  ),
//         //  width:'7%'
//       },
      
//       {
//           title: 'Publisher ID',
//           dataIndex: 'pID',
//           key: 'pID',
//           width: '8%',
//           align:'center',
//          //sorter: (a, b) => a.pID - b.pID,
//          render: (text,record) => 
//       <div class="dot_2 "><span>{record.pID}</span></div>
//       },
//       {
//           title: 'Publisher Name',
//           dataIndex: 'publisherName',
//           key: 'publisherName',
//           width:'10%',
//            align:'center',  
         
//            render: (text,record) => 
//           <div class="dot_3" style={{width:"95px"}} >
//               <span style={{fontSize:'12px'}}>{record.publisherName}</span>
//           </div>
//       },
//       {
//           title: 'Invoice Document',
//           dataIndex: 'invoiceDocumentName',
//           key: 'invoiceDocumentName',
//           width:'10%',
//           align:'center',



//           render: (text,record) => 
//          record.type==="Manual"? <div  style={{width:"111px"}} class="dot" >
//                   <span style={{fontSize:'12px'}}>
//                    <a href="#" 
//     onClick={this.getInvoiceDocument.bind(this)} invoiceid={record.invoiceID} pid={record.pID} type="Manual"
//     invoiceDocumentName={record.invoiceDocumentName}  style={{fontSize:"12px"}}>{record.invoiceDocumentName}</a>
//                 </span>
//               </div>:
//               <div style={{width:"99px"}} class="dot" >
//                           <span style={{fontSize:'12px'}}>
//                          <a href="#" 
//               onClick={this.getInvoiceDocument.bind(this)} invoiceid={record.invoiceID} pid={record.pID} type="Automatic" 
//               invoiceDocumentName={record.invoiceDocumentName}  style={{fontSize:"12px"}}>{record.invoiceDocumentName}</a>
//                         </span>
//                       </div>
//                },
          
          
//       {
//         title: 'Invoice Type',
//         dataIndex: 'InvoiceType',
//         key: 'InvoiceType',
//         width: '10%',
//         align:'center',
//         //sorter: (a, b) => a.month - b.month,
//         render: (text,record) => 
//         <div style={{width:"98px"}}><span>{record.type}</span></div>
//       },
      
     
//       {
//         title: 'Month/Year',
//         dataIndex: 'month',
//         key: 'month',
//         width: '10%',
//         align:'center',
//         //sorter: (a, b) => a.month - b.month,
//         render: (text,record) => 
//         <div style={{width:"98px"}}><span>{record.month}-{record.year}</span></div>
//       },
   
//       {
//           title: 'Invoice Status',
//           dataIndex: 'status',
//           key: 'status',
//            align:'center',
//            width:'12%',  
       
//            render: (text,record) => 
//           <div style={{width:"120px"}}>
//               <span style={{fontSize:'12px'}}>{record.status}
//               </span>
//           </div>
//       },
//       {  
//           title: 'Feedback',
//           dataIndex: 'feedback',
//           key: 'feedback',
//           width:'12%',
//           render: (text,record) => 
//           <div class="text-center" >
//           <div class="word-wrap"> 
//           <span style={{fontSize:'12px',marginLeft:'-3px'}}>{record.feedback}</span>
//           <br/></div>
//          <div class="dot" class="word-wrap" style={{paddingBottom:'20px'}}>
//          {record.feedbackFIleName!==null?<span style={{fontSize:'12px'}}><label id="label">Feedback File</label><br/>
         
//           <a  href="#" onClick={this.downloadFeedbackFile.bind(this)} invoiceid={record.invoiceID}
//            fileName={record.feedbackFIleName}>{record.feedbackFIleName}</a>
//         </span>:''}
//         </div>
//       </div>
       
//       },




//       {
//         title: 'Invoice Paid Date',
//         dataIndex: 'InvoicePaidDate',
//         key: 'InvoicePaidDate',
//         width: '10%',
//         align:'center',
//         //sorter: (a, b) => a.month - b.month,
//         render: (text,record) => 
//        record.status==="InvoicePaid"? <div style={{width:"98px"}}><span>{record.invoicePaidDate}</span></div>:''
//       },

//       {
//         title: 'Invoice Generated Date',
//         dataIndex: 'InvoiceGeneratedDate',
//         key: 'InvoiceGeneratedDate',
//        // width: '10%',
//         align:'center',
//         //sorter: (a, b) => a.month - b.month,
//         render: (text,record) => 
//         <div style={{width:"98px"}}><span>{record.createdDate}</span></div>
//       },



//       {
        
//         title: 'Invoice Generated Date',
//         dataIndex: 'invoiceGeneratedDate',
//         key: 'invoiceGenerateddDate',
//         width: '12%',
//         align:'center',
//       // sorter: (a, b) => a.invoicePaidDate - b.invoicePaidDate,
//        render: (text,record,i) => 
//       <div style={{width:"110px"}}>
//         {/* {record.lastUpdated<=record.invoicePaidDate?"Enable":"Disable"} */}
//         {record.status==='InvoicePaid'?
//         <input type="date" id="invoicePaidDate" name="invoicePaidDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
//         defaultValue={record.invoicePaidDate} onChange={this.dynamicHandleChange.bind(this,i)}style={{width:"150px", right:"14px"}}
//       className="form-control" disabled />:

//         <input type="date" id="invoicePaidDate" name="invoicePaidDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
//         defaultValue={record.invoicePaidDate} onChange={this.dynamicHandleChange.bind(this,i)}style={{width:"150px", right:"14px"}}
//       className="form-control"  />}</div>
//       },
//       {
//           title: 'Action',
//           dataIndex: 'Action',
//           key: 'Action',
//           // width: '10%',
//           align:'center',
//          //sorter: (a, b) => a.Action - b.Action,
//          render: (text,record) => 
     
//       <div class="d-inline-block"style={{width:"58px"}}><span>
//             {record.date}
//         {record.status==='AgencyInvoicePending'?<a href="#" data-toggle="modal" data-target="#myModal"
//       data-backdrop="static" data-keyboard="false"
//       onClick={this.displayInvoicePopup.bind(this)} id={record.invoiceID} month={record.month} year={record.year}  pID={record.pID}
//       style={{fontSize:"12px"}}><Tooltip placement="top" title="Confirm Invoice" >
//         <img style={{padding:'1px'}}  className="float-left" src="Review.png" width="28" height="28" id={record.invoiceID}month={record.month} year={record.year}  pID={record.pID} type="Automatic"onClick={this.invoiceReviewed.bind(this)}/>
//       </Tooltip></a>:<Tooltip placement="top" title="Invoice confirmed" overlayStyle={{color: 'white', backgroundColor: 'orange' }} >
//         <img style={{padding:"1px"}} className="float-left"src="Reviewdisable.png" width="28" height="28"/>
//       </Tooltip>}
//       {record.status==='AgencyInvoicePending'|| record.status==='InvoicePaid'?
//       <Tooltip placement="top" title="Invoice Paid" overlayStyle={{color: 'white', backgroundColor: 'orange' }}>
//         <img style={{padding:'1px'}} class="float-left" src="DisablePaidInvoice.png" width="28" height="28"/>
//       </Tooltip>

//       :
//      (record.invoicePaidDate!==null||record.invoicePaidDate!==undefined)&&(record.lastUpdated<=record.invoicePaidDate)?
      
// <a href="#" data-toggle="modal" data-target="#myModal"
//       data-backdrop="static" data-keyboard="false"
//       style={{fontSize:"12px"}}><Tooltip placement="top" title="Confirm Paid Invoice" >
//      <img style={{padding:'1px'}}className="float-right" src="paidInvoice.png" width="28" height="28" id={record.invoiceID}month={record.month} pID={record.pID} disabled={this.state.disableTooltip} onClick={this.paidInvoice.bind(this)}/>
//       </Tooltip></a>
//      :
//       <Tooltip placement="top" title="Invoice Paid" overlayStyle={{color: 'white', backgroundColor: 'orange' }} >
//       <img style={{padding:'1px'}} className="float-right" src="DisablePaidInvoice.png" width="28" height="28" />
//       </Tooltip>
//     }
//       </span></div>
//       }
//     ]
    // function onChange(pagination, filters, sorter) {
    //   console.log('params', pagination, filters, sorter);
    // }
     
return(
    <div>
         <Navigation />
        
         <form method="" name="" onSubmit={this.submitInvoiceFeedback}  className="needs-validation" encType="multipart/form-data"  noValidate> 
                  <div class="container-fluid" style={{ paddingTop: '90px', marginBottom: '60px' }}>
                  <div class="col" align="center" style={{fontSize:"20px",fontWeight:"bold"}}><label class="text-center" id="labelheading"style={{color:'#056eb8'}} >Invoice History</label>
                  <a> <FaArrowAltCircleLeft size={32} style={{float:'left',color:'#056eb8'}} title="Back" onClick={this.handleBackButton} /></a>
                  </div>
                  {/* <div class="col-lg-2"><a> <FaArrowAltCircleLeft size={32} style={{float:'left',color:'#056eb8'}} title="Back" onClick={this.handleBackButton} /></a></div>  */}
                  
                    {/* <div className="card-body"> */}
                    <div style={{fontSize:'16px',color:'red',fontWeight:'600',textAlign:'center'}}>
                    {this.state.errors.commonError}  
                    </div> 
                    <br/>
                   


<MDBRow lg="12">
    <div class="col-sm-12 col-md-6 col-lg-3">
        <div>
            <label id="label" style={{ fontSize: "16px",fontWeight:'20',marginLeft:'15px' }}>From<span style={asterisk}>*</span></label>   
        </div> 
        <div>
            <input type="date" id="startDate"  style={{width:'160px',marginLeft:'15px'}}name="startDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
            value={this.state.startDate} className={this.state.inputClassSdate}
            onChange={this.handleChange}class="form-control" min=""required/>
        </div>
        <div>
            {/*<span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span>*/}
        </div>
        <span style={asterisk}> {this.state.errors.startDate} </span>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-2">
        <div>
                    <label id="label" style={{ fontSize: "16px",marginLeft:'15px'}} >To<span style={asterisk}>*</span></label>
                    <div>
                        <input type="date" id="endDate" style={{ width: '160px',marginLeft:'15px'}} name="endDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                        value={this.state.endDate} className={this.state.inputClassEdate}
                        onChange={this.handleChange} class="form-control" required />
                    </div>
                    {/*<span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span>*/}
                </div>
                <span style={asterisk}> {this.state.dateerrors.endDate} </span>
            </div>
            
            <div class="col-lg-1" >
                <label>
                </label>
              </div>

            <div class=" col-sm-12 col-md-6 col-lg-2">
                <div>
                  <label id="label" style={{ fontSize: "16px",marginLeft:'15px'}}>Publisher Name <span style={asterisk}>*</span></label></div>
                <div>
                  <select required id="publisherName" name="publisherName"
                  style={{marginLeft:'15px'}}
                     className={this.state.inputClass5}
                    onChange={this.publisherHandleChange.bind(this)}
                    class="form-control" required >
                  <option value="Select Publisher ID" disabled="disabled" selected>Select Publisher</option>
                  {this.state.pubName.map(pubName => (
                      <option value={pubName.pID}  name={pubName.publisherName} >{pubName.publisherName}
                      </option>
                      // <option hidden value={pubName.publisherName}></option>
                    ))}
                  </select>
                </div>
                {/* <span style={asterisk}> {this.state.errors.publisherName} </span> */}
              </div>
            <div class="col-sm-12 col-md-3 col-lg-4">
            <div class="col" style={{ fontSize: "18px" }}>
            <label></label>
            </div>
            <div class=" col-sm-12 col-md-3 col-lg-4" >
                <label>
                </label>
              </div>
            &nbsp;&nbsp;
            <button class="btn text-uppercase add-button"style={{float:'left',backgroundColor:"#2196F3",color:"white",marginLeft:'15px'}} disabled={this.state.disableButton} onClick={this.showInvoice} type="submit" >
             Search</button>
            </div>
            </MDBRow>

<div class=" col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{marginTop:'15px'}}>
<div className="card card-signin my-1" style={{overflowY:'scroll',height:'300px'}}>
<table class="table table-bordered "
            style={{paddingTop:'80px',borderCollapse:'collapse', border: '1px solid #D3D3D3',overflowX:'hidden'}}>
<thead>
  <tr>
  <th style={{width:'90px',color:'white',backgroundColor:'#124E9C',textAlign:'center'}}>Invoice ID</th>
  <th style={{width:'105px',color:'white',backgroundColor:'#124E9C',textAlign:'center'}}>Publisher ID</th>
  <th style={{width:'150px',color:'white',backgroundColor:'#124E9C',textAlign:'center'}}>Publisher Name</th>
  <th style={{width:'150px',color:'white',backgroundColor:'#124E9C',textAlign:'center'}}>Invoice Document</th>
  <th style={{width:'130px',color:'white',backgroundColor:'#124E9C',textAlign:'center'}}>Invoice Type</th>
  <th style={{width:'130px',color:'white',backgroundColor:'#124E9C',textAlign:'center'}}>Month/year</th>
  <th style={{width:'150px',color:'white',backgroundColor:'#124E9C',textAlign:'center'}}>Invoice Status</th>
  <th style={{width:'170px',color:'white',backgroundColor:'#124E9C',textAlign:'center'}}>Feedback</th>
  <th style={{width:'150px',color:'white',backgroundColor:'#124E9C',textAlign:'center'}}>Invoice Paid Date</th>
  <th style={{width:'150px',color:'white',backgroundColor:'#124E9C',textAlign:'center'}}>Invoice Generated Date</th>
    </tr>
  </thead>

  {/* {this.state.unreadAlertsData.map((unreadAlertsData) => { */}
  {this.state.invoiceDetail.map((invoiceDetail) => {
  return(
    <tbody>
      <tr style={{textAlign:'center',height:'50px'}}>
  <td>{invoiceDetail.invoiceID}</td>
  <td>{invoiceDetail.pID}</td>
  <td>{invoiceDetail.publisherName}</td>
  {/* <td>
  {invoiceDetail.type==="Manual"?
  
                   
  <a href="#" onClick={this.getInvoiceDocument.bind(this)} id={invoiceDetail.invoiceID} month={invoiceDetail.month} year={invoiceDetail.year}  pID={invoiceDetail.pID} invoiceDocumentName={invoiceDetail.invoiceDocumentName}  type="Manual"invoiceDocumentName={invoiceDetail.invoiceDocumentName}  style={{fontSize:"12px"}}>{invoiceDetail.invoiceDocumentName}</a>
          
                :
                <a href="#" onClick={this.getInvoiceDocument.bind(this)} id={invoiceDetail.invoiceID} month={invoiceDetail.month} year={invoiceDetail.year}  pID={invoiceDetail.pID} invoiceDocumentName={invoiceDetail.invoiceDocumentName}  type="Automatic"invoiceDocumentName={invoiceDetail.invoiceDocumentName}  style={{fontSize:"12px"}}>{invoiceDetail.invoiceDocumentName}</a>
 
 }</td>  */}
   
  
                
  
  <td><a href="#" onClick={this.getInvoiceDocument.bind(this)} invoiceid={invoiceDetail.invoiceID} pID={invoiceDetail.pID} invoiceDocumentName={invoiceDetail.invoiceDocumentName}    style={{fontSize:"12px"}}>{invoiceDetail.invoiceDocumentName}</a></td>
  
  <td>{invoiceDetail.type}</td>
   <td>{invoiceDetail.month}{invoiceDetail.year}</td>
  <td>{invoiceDetail.status}</td>
  
  <td><div class="word-wrap"> 
           <span style={{fontSize:'12px',wordWrap:"break-word",height:"5%"}}>{invoiceDetail.feedback}</span>          
            <br/></div>
         <div class="dot" class="word-wrap" style={{paddingBottom:'20px',width:'140px'}}>
          {invoiceDetail.feedbackFIleName!==null?<span style={{fontSize:'12px'}}><label id="label">Feedback File</label>:<br/>
         
           <a  href="#" onClick={this.downloadFeedbackFile.bind(this)} invoiceid={invoiceDetail.invoiceID}
            fileName={invoiceDetail.feedbackFIleName}>{invoiceDetail.feedbackFIleName}</a>
         </span>:''}
      </div>
  
  
  
  </td>
  <td>{invoiceDetail.invoicePaidDate}</td>
  <td>{invoiceDetail.createdDate}</td>
        </tr>
      </tbody>
    )})} 
 </table>
  </div>
  </div>

  
                {/* <div style={{fontSize:'16px',color:'red',fontWeight:'600',textAlign:'center'}}>
                {this.state.newsuccess.records}
                    </div>
                    <div id="campReport" class="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
    <div className="card card-signin my-1" >
    <p>    <Table {...this.state.tableState} id="myTable" bordered dataSource={this.state.invoiceDetail}   loading={this.state.loading1}
         onChange={onChange} columns={columns1}
          className="ant-table-thead" className={tableCSS} scroll={{ y: 800 }}
        pagination={{ pageSize: this.state.pageSize1 }}></Table></p>
    </div>
    end of card card-signin my-1
</div>
</div> */}

                     
                  {/* </div>Card-body */}
                  
</div>{/*Container */}
 </form> 
 <Footer />
</div>
            );
    }// end of renders
}// end of class



AgencyInvoiceList.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(AgencyInvoiceList));

