/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Sonali Kalke
 *@fileName :
 *Desc:
 */
import React from "react";
// import { Tabs, Tab } from "react-bootstrap"; //Aman-5075-(console warnings)commnted not in use
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
//import { Table } from "mdbreact"; //Aman-5075-(console warnings)commnted not in use
//import { saveAs } from "file-saver"; //Aman-5075-(console warnings)commnted not in use
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { Tooltip } from "antd"; //Aman-5075-(console warnings) Icon,Button,Upload removed not in use
import { FaArrowAltCircleLeft } from "react-icons/fa";
// import readXlsxFile from "read-excel-file"; //Aman-5075-(console warnings)commnted not in use
import * as $ from "jquery";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Nilesh-4674-added default header

const Swal = require("sweetalert2");
// const XLS = require("xlsx");//Aman-5075-(console warnings)commnted not in use
//const queryString = require("query-string");//Aman-5075-(console warnings)commnted not in use
class CreateXLSCampaign extends React.Component {
  constructor() {
    super();
    this.state = {
      agencyID: "",
      createdBy: "",
      leadDeliveryOption: "Excel",
      campaignStatus: "Active",
      pacing: "Weekly",
      marketingChannel: "Email",
      campaignReportingDay: "Any",
      lpTimeline: "24",
      currency: "USD",
      callAudit: "No",
      xlsCampaign: true,
      pacingCount: "0",
      multipleCampaignXLName: "",
      errorList: [],
      loader_progress: "", //Aman-5075 - set the loader state
      SingleFileName: "Single_Campaign_Though_Xls.xlsx", //Aman-5075 Name of Excel file
      MultipleFileName: "Multiple_Campaign_Though_Xls.xlsx", //Aman-5075 Name of Excel file
    };

    this.createCampaignByXls = this.createCampaignByXls.bind(this);
    this.createMultipleCampaign = this.createMultipleCampaign.bind(this);
    this.handleDownload = this.handleDownload.bind(this); //Aman-5075-added to bind single file
    this.MultipleFileName = this.MultipleFileName.bind(this); //Aman-5075-added to bind one-or-more file
  }

  createMultipleCampaign(e) {
    e.preventDefault();
    let self = this;
    var xlsxFile = e.target.files[0];
    if (xlsxFile === null || xlsxFile === undefined || xlsxFile === "") {
    } else {
      var fileName = xlsxFile.name;
      var errorList = [];
      this.setState({ multipleCampaignXLName: fileName, errorList: errorList });
      var ext = fileName.split(".").pop();
      if (ext === "xlsx") {
        var HTML2 = "<table>";
        var imgsuccess =
          '<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>';
        var link =
          '<a class="removeExcelFile" href="#" data-fileid=' +
          '"' +
          fileName +
          '"' +
          '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
        HTML2 +=
          "<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" +
          imgsuccess +
          "&nbsp;" +
          "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
          fileName +
          "</span>" +
          link +
          "<br><br></td></tr>";
        HTML2 += "</table>";
        document.getElementById("multipleCampaignList").innerHTML = HTML2;

        var data = new FormData();
        data.append("xlsxFile", xlsxFile);
        data.append("createdBy", this.state.createdBy);
        // data.append("agencyID",this.state.agencyID);
        data.append("leadDeliveryOption", this.state.leadDeliveryOption);
        data.append("campaignStatus", this.state.campaignStatus);
        data.append("pacing", this.state.pacing);
        data.append("marketingChannel", this.state.marketingChannel);
        data.append("campaignReportingDay", this.state.campaignReportingDay);
        data.append("lpTimeline", this.state.lpTimeline);
        data.append("currency", this.state.currency);
        data.append("callAudit", this.state.callAudit);
        data.append("pacingCount", this.state.pacingCount);
        //Aman-5075-Show the SweetAlert with a progress bar and loader icon
        Swal.fire({
          title: "Please wait processing your file",
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          allowOutsideClick: false, // Prevent closing by clicking outside
          showConfirmButton: false, // Remove the "OK" button
        });
        fetch("/xlsCampaign/createMultipleCampaignThoughXls", {
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            //Aman-5075- Close the SweetAlert
            Swal.close();
            // alert(JSON.stringify(res))
            if (res[0].success === true) {
              var listCampID = res[0].campList;
              // alert(listCampID);
              var cID = "";
              for (var i = 0; i < listCampID.length; i++) {
                cID = cID + " | " + listCampID[i];
              }
              let message = "Campaign Creates Successfully" + cID;
              Swal.fire({
                text: message,
                type: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                preConfirm: () => {
                  window.location.href = "/incompleteCampaignTab"; //4674-Nilesh- Remove #2 from Url
                },
              });
            } else {
              let errorList = res[0].Error;
              // Swal.fire({
              //     text: (JSON.stringify(errorList)),
              //     type: 'error',
              //     confirmButtonText: 'Ok',
              //     allowOutsideClick: false,
              // });
              this.setState({ errorList: errorList });
            }
          })
          .catch(function (err) {
            console.log(err);
          });
        $(document).on("click", ".removeExcelFile", function (e) {
          e.preventDefault();
          //  $('multipleCampaignfile').val("");
          document.getElementById("multipleCampaignfile").value = null;
          document.getElementById("multipleCampaignList").innerHTML = "";
          var errorList = [];
          self.setState({ multipleCampaignXLName: "", errorList: errorList });
        });
      } else {
        Swal.fire({
          type: "error",
          html: "Please upload file of <b>XLSX</b> format only",
          confirmButtonText: "Ok",
        });
      }
    }
  } // End of createMultipleCampaign

  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth; //Aman-5075-(console warnings) isAuthenticated removed not in use
      var createdBy = user.firstName + " " + user.lastName;
      var agencyID = user.id;
      this.setState({ createdBy: createdBy, agencyID: agencyID });
    }
  }

  createCampaignByXls(e) {
    e.preventDefault();
    //Aman-5075-(console warnings) in if changed == to ===
    if (
      e.target.files[0] == null ||
      e.target.files[0] === undefined ||
      e.target.files[0] === ""
    ) {
    } else {
      var xlsxFile = e.target.files[0];
      var fileName = xlsxFile.name;
      var ext = fileName.split(".").pop();
      // var reader = new FileReader(); //Aman-5075-(console warnings)commnted not in use
      if (ext === "xlsx") {
        //Aman-5075-(console warnings) changed == to ===
        var data = new FormData();
        data.append("xlsxFile", xlsxFile);
        data.append("createdBy", this.state.createdBy);
        // data.append("agencyID",this.state.agencyID);
        data.append("leadDeliveryOption", this.state.leadDeliveryOption);
        data.append("campaignStatus", this.state.campaignStatus);
        data.append("pacing", this.state.pacing);
        data.append("marketingChannel", this.state.marketingChannel);
        data.append("campaignReportingDay", this.state.campaignReportingDay);
        data.append("lpTimeline", this.state.lpTimeline);
        data.append("currency", this.state.currency);
        data.append("callAudit", this.state.callAudit);
        data.append("pacingCount", this.state.pacingCount);
        //Aman-5075- Show the SweetAlert with a progress bar and loader icon
        Swal.fire({
          title: "Please wait processing your file",
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          allowOutsideClick: false, // Prevent closing by clicking outside
          showConfirmButton: false, // Remove the "OK" button
        });

        fetch("/campaign/uploadXlsFile", {
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success === true) {
              if (res.flag === false) {
                //Aman-5075-Close the SweetAlert
                Swal.close();
                Swal.fire({
                  text:
                    "Campaign Creates Successfully with campaign ID:'" +
                    res.campID +
                    "' but without clientname as the client does not exists",
                  type: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,

                  preConfirm: () => {
                    window.location.href = "/incompleteCampaignTab"; //4674-Nilesh- Remove #2 from Url
                  },
                });
              } else {
                Swal.fire({
                  text:
                    "Campaign Creates Successfully, with campaign ID:'" +
                    res.campID +
                    "'",
                  type: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,

                  preConfirm: () => {
                    window.location.href = "/incompleteCampaignTab"; //4674-Nilesh- Remove #2 from Url
                  },
                });
              }
            }
            if (res.success === false) {
              //Aman-5075-Close the SweetAlert
              Swal.close();
              if (res.validationError) {
                var validationError1 = JSON.stringify(res.validationError); //sandeep-task-3020-added code to store validationError
                var validationError2 = validationError1.split("|"); //sandeep-task-3020-added code to store spilted value

                Swal.fire({
                  // text: (validationError2),
                  html: validationError2.join("<br>"), //sandeep-task-3020-code added for display  text on new line in  sweetAlert
                  type: "error",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  preConfirm: () => {
                    window.location.reload();
                  },
                });
              } else {
                Swal.fire({
                  text: "This is not valid XLSX file.",
                  type: "error",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              }
            }
          })

          .catch(function (err) {
            console.log(err);
          });
      } else {
        Swal.fire({
          type: "error",
          html: "please upload file of <b>XLSX</b> format only",
        });
      }
    }
  }

  /**
   * @author Aman Shaikh 5075
   * @param  Description Task-5075 Added to download Single_Campaign_Though_Xls file
   * @return return file
   */
  handleDownload() {
    const url = "/TemplateFormatFolder/Single_Campaign_Though_Xls.xlsx";
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = this.state.SingleFileName;
        // Trigger the download by simulating a click
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  /**
   * @author Aman Shaikh 5075
   * @param  Description Task-5075 Added to download Multiple_Campaign_Though_Xls file
   * @return return file
   */
  MultipleFileName() {
    const url = "/TemplateFormatFolder/Multiple_Campaign_Though_Xls.xlsx";
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = this.state.MultipleFileName;
        // Trigger the download by simulating a click
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  /**
   * @author Aman Shaikh
   * @param  Description Task-5075 Add Back Button
   * @return return on Dashboard Page
   */
  backButtonFunction(e) {
    e.preventDefault();
    this.props.history.push("/newdashboard");
  }

  //Aman-5075-added handle Email Suppression compare-Data refresh issue
  refreshPage(e) {
    window.location.reload();
  }
  render() {
    return (
      <div>
        <Navigation />
        <div
          class="container-fluid"
          id="containerUCS"
          style={{ paddingTop: "112px", paddingBottom: "10px" }}
        >
          {/* Aman-5075 back Button and page title*/}
          <div class="row" style={{ margin: "0px 0px" }}>
            <div class="col-md-1">
              {" "}
              <a
                href={"#/"}
                style={{ color: "#056eb8" }}
                onClick={this.backButtonFunction.bind(this)}
              >
                <FaArrowAltCircleLeft size={32} title="Back to Dashboard" />
              </a>
            </div>
            <div class="col-md-8 reviewLead1">
              {" "}
              <center>
                <label
                  id="labelDI"
                  class="PubReviewLead"
                  style={{ marginLeft: "135px" }}
                >
                  {" "}
                  Create Campaign by XLSX
                </label>
              </center>
            </div>

            {/*Aman-5075-added handle Email Suppression compare-Data refresh issue*/}
            <div
              class="col-sm-12 col-md-3 col-lg-3 specificationCamp"
              style={{ textAlign: "right" }}
            >
              <button
                class="btn add-button"
                type="primary"
                onClick={this.refreshPage}
                style={{
                  backgroundColor: "#056eb8",
                  color: "#fff",
                  width: "min-content",
                  height: "31px",
                  fontSize: "14px",
                  borderColor: "#056eb8",
                  float: "right",
                }}
              >
                <Tooltip placement="left" title="Refresh Page">
                  <span
                    className="fas fa-sync-alt"
                    style={{ color: "aliceblue" }}
                  ></span>
                </Tooltip>
              </button>
              &nbsp;
            </div>
          </div>
          {/*Aman-5075-below is div to disaply single file upload option */}
          <div style={{ padding: "10px" }}>
            <div class="row">
              <div
                class="col-12 col-sm-12 col-md-12 col-lg-6"
                style={{
                  margin: "20px 0px",
                }}
              >
                <div
                  className="card"
                  style={{
                    padding: "20px",
                    MozBoxShadow: "3px 3px 5px 0px #e6e6ff",
                    WebkitBoxShadow: "3px 3px 5px 1px #e6e6ff",
                    boxShadow: "3px 3px 5px 0px #e6e6ff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>
                      To create campaign through (.XLSX), upload file here
                    </label>

                    <button
                      class="btn add-button"
                      type="button"
                      onClick={this.handleDownload}
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#056eb8",
                        color: "white",
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        fontWeight: "400",
                        borderRadius: "4px",
                        outline: "none",
                        width: "175px",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title="Download Excel Template for Single Campaign Creation"
                      >
                        &nbsp; Download Template &nbsp;
                        <i class="fa fa-cloud-download" aria-hidden="true"></i>
                      </Tooltip>
                    </button>
                  </div>
                  <br />

                  <div>
                    <label class="btn chooseFile">
                      Choose file
                      <input
                        type="file"
                        style={{ display: "none" }}
                        name="xlsFile"
                        onChange={this.createCampaignByXls}
                      ></input>
                    </label>
                  </div>
                </div>
              </div>
              {/*Aman-5075-below is div to disaply multiple file upload option */}
              <div
                class="col-12 col-sm-12 col-md-12 col-lg-6"
                style={{
                  margin: "20px 0px",
                }}
              >
                <div
                  className="card"
                  style={{
                    padding: "20px",
                    MozBoxShadow: "3px 3px 5px 0px #e6e6ff",
                    WebkitBoxShadow: "3px 3px 5px 1px #e6e6ff",
                    boxShadow: "3px 3px 5px 0px #e6e6ff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>
                      To create multiple campaign through (.XLSX), upload file
                      here
                    </label>

                    <button
                      class="btn add-button"
                      type="button"
                      onClick={this.MultipleFileName}
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#056eb8",
                        color: "white",
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        fontWeight: "400",
                        borderRadius: "4px",
                        outline: "none",
                        width: "175px",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title="Download Excel Template for Multiple Campaign Creation"
                      >
                        &nbsp; Download Template &nbsp;
                        <i class="fa fa-cloud-download" aria-hidden="true"></i>
                      </Tooltip>
                    </button>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      // justifyContent: "space-between",
                      overflow: "hidden",
                    }}
                  >
                    <label
                      class="btn chooseFile"
                      style={{ marginRight: "20px" }}
                    >
                      Choose file
                      <input
                        type="file"
                        style={{ display: "none" }}
                        name="file"
                        id="multipleCampaignfile"
                        onChange={this.createMultipleCampaign}
                      ></input>
                    </label>
                    {/* <label>&nbsp;&nbsp;{this.state.multipleCampaignXLName}</label> */}
                    <div
                      id="multipleCampaignList"
                      className="word-wrap"
                      // style={{padding:"5px 10px"}}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Aman-5075 -Added loader*/}
        <div align="center" id={this.state.loader_progress}></div>

        {this.state.errorList.length === 0 ? (
          ""
        ) : (
          <div class="container-fluid">
            <div class="card col" style={{ padding: "0px 10px" }}>
              <ul>
                {this.state.errorList.map((errorList) => (
                  <li style={{ color: "Red" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;{JSON.stringify(errorList)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CreateXLSCampaign.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(CreateXLSCampaign)
);
