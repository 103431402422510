/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Narendra Phadke
*@fileName : 
 *Desc:  For AI point of view
*/
import React from "react";
import { Tabs, Tab } from 'react-bootstrap';
import Footer from '../layouts/footer'; 
import Navigation from '../layouts/navPage';
import { Table} from 'mdbreact';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import {Upload, Button,Tooltip,Icon} from 'antd';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import readXlsxFile from 'read-excel-file';

import { Widget ,addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import { Container, Button1, lightColors, darkColors,Link } from 'react-floating-action-button';
import { Drawer ,Comment, Avatar,Input,List,Form } from 'antd';
import 'antd/dist/antd.css';
import {MDBBtn} from "mdbreact";
import moment from 'moment';
const Swal = require('sweetalert2');
const XLS = require('xlsx');
const queryString = require('query-string');
const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? 'messages' : 'message'}`}
    itemLayout="horizontal"
    renderItem={props => <Comment {...props} />}
  />
);
const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>

    <button type="submit"class="btn add-button" id="cmd"
    loading={submitting} onClick={onSubmit}>Send Message</button>
     </Form.Item>
  </div>
);
class keywordExtractionFromPDF extends React.Component{
    
  constructor() {
    super();
    this.state = {
      agencyID:'',
      createdBy:'',
      leadDeliveryOption: 'Excel',
      campaignStatus: 'Active',
      pacing: 'Weekly',
      marketingChannel: 'Email',
      campaignReportingDay: 'Any',
      lpTimeline:"24",
      currency:"USD",
      callAudit:"No",
      xlsCampaign:true,
      pacingCount:"0",
      comments: [],
      topKeyword:[],
    };
    
 this.createCampaignByXls=this.createCampaignByXls.bind(this);
  } 

  componentWillMount(){
    const {isAuthenticated, user} = this.props.auth;
    var createdBy=user.agencyName;
    var agencyID=user.id;
    this.setState({createdBy:createdBy,agencyID:agencyID});
  }
 
  createCampaignByXls(e){
    e.preventDefault();
    if(e.target.files[0]==null||e.target.files[0]==undefined||e.target.files[0]=="")
    { }
    else
    {
      var xlsxFile=e.target.files[0];
      var fileName=xlsxFile.name;
      var ext=fileName.split('.').pop()
      var reader = new FileReader();
  
        var data = new FormData();  
        data.append("xlsxFile",xlsxFile);
       
        data.append("agencyID",this.state.agencyID);

fetch("/keywordExtractionAI/keywordExtraction",{
  method:'POST',
  body: data
}).then(res => res.json())
.then(topKeyword => {
    this.setState({ topKeyword: topKeyword })
})

.catch(
  function (err) {console.log(err)}
);



  }
}



handleSubmit = () => {
    
  if (!this.state.value) {
    return;
  }else{

let data={
message:this.state.value
}
alert("Send message")
fetch("/keywordExtractionAI/chatBotAI", {
method: 'POST',
headers: {'Content-Type': 'application/json'},
body: JSON.stringify(data)
}).then(res => res.json())
.then(comments =>{
this.setState({ comments: comments,visible: true, submitting: true,})
}).catch(function(err) {
console.log(err)}); 

// alert("BroadCast");


  // var uname=user.firstName+" "+user.lastName;

  // var fnamefirst = user.firstName.slice(0, 1);
  // var lnamefirst = user.lastName.slice(0, 1);
  
  
  // var uavatar=fnamefirst+lnamefirst;

  setTimeout(() => {
    this.setState({
      submitting: false,
      value: '',
      comments: [
       
        {
          author:"uname",
          avatar: "uavatar",
          content: <p>{this.state.value}</p>,
          datetime: moment().calendar() ,
        },
        ...this.state.comments,
      ],
    });
  }, 1000);
  }
}


handleChange = e => {
  //alert("Value==>"+ e.target.value);
  this.setState({
   
    value: e.target.value,
  });
};

 showDrawer = () => {
   /**
     * @author Narendra Phadke
     * @param  Description fetch brodcast message history 
     * @return Description return all history
     */
  
  //var parsed = queryString.parse(this.props.location.search);
      var campID=this.state.campID;
      const { user} = this.props.auth;
          let data={
          campID:campID,
          user:user,
       }
      //This API is for brodcast message history
      // fetch("/notification/campaignSpecificationBrodcastMessageHistory", {
      //   method: 'POST',
      //   headers: {'Content-Type': 'application/json'},
      //   body: JSON.stringify(data)
      // }).then(res => res.json())
      //   .then(comments =>{
      //    // alert("Message history=="+JSON.stringify(comments));
      //     this.setState({ comments: comments,visible: true, })
      //   }).catch(function(err) {
      //     console.log(err)
      // });

      setTimeout(() => {
        this.setState({
          submitting: false,
          value: '',
          comments: [
           
            {
              author:"Narendra",
              avatar: "Phadke",
              content: <p>{this.state.value}</p>,
              datetime: moment().calendar() ,
            },
            ...this.state.comments,
          ],
        });
      }, 1000);
   this.setState({
     visible: true,
   });
};


render(){
  const { comments, submitting, value } = this.state;
    return(
    <div>
        <Navigation />
        

    <div class="container-fluid" style={{paddingTop:'85px'}}> <br/><br/>
    <div style={{  MozBoxShadow:'3px 3px 5px 0px #e6e6ff',WebkitBoxShadow:'3px 3px 5px 1px #e6e6ff',boxShadow:'3px 3px 5px 0px #e6e6ff'}}>
          <div style={{paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px"}}>
        <div class="row" >
          <center><label style={{paddingRight:"100px"}}>Upload PDF file here</label></center>
        </div>
        <br/>
        <div class="row">
          <center>
        <label class="btn chooseFile">Choose file
              <input type="file" style={{display:'none',marginRight:"100px"}} name="xlsFile" onChange={this.createCampaignByXls}></input>
              </label>
              </center>
          </div>
          <label>Top 10 Keyword And Phrase:</label>
          {this.state.topKeyword.map(topKeyword => {return(
            <div>
             
            {topKeyword.keyword}
            </div>
          )}
          )}


              </div>
              </div>                 
   </div>    

     <Drawer
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          width={350}
        >
         <p style={{fontSize:"20px",fontWeight:"500"}}>Messages</p>
         <MDBBtn style={{left:'389px',bottom:'43px'}} onClick={this.onClose}>&times;</MDBBtn>
          <p>

         

         
          </p>

          <p>  <Comment
          // avatar={
          //   <Avatar
          //     src="DI.png"
          //     alt="Demand Integrate"
          //   />
          // }
          content={
            <Editor
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              submitting={submitting}
              value={value}
            />
          }

        />

<p style={{fontSize:"20px",fontWeight:"500"}}>History</p>
 <p style={{overflowY:"scroll",height:"200px"}}>{comments.length > 0 && <CommentList comments={comments} />}</p> 


<p style={{overflowY:"scroll",height:"137px"}}>

</p>

          </p>
        </Drawer>

               
   <Container>
            {/* <Button
                tooltip="Contact Publisher"
                 styles={{backgroundColor: darkColors.lighterRed, color: lightColors.white}}
                icon="fa fa-user"
                onClick={this.contactPublisher}
                
                />
            <Button
                tooltip="BroadCast"
                 styles={{backgroundColor: darkColors.lighterRed, color: lightColors.white}}
                icon="fa fa-rss"
                onClick={this.showDrawer} 
                /> */}
            <Button
                tooltip="Chat With Us!"
                 styles={{backgroundColor: darkColors.lighterRed, color: lightColors.white}}
                icon="fa fa-comments-o fa-3x"
                //rotate={true}
                onClick={this.showDrawer} 
                />
        </Container>  

        <Footer/>
    </div>
     )
}

}
 /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      keywordExtractionFromPDF.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })

export default connect(mapStateToProps, { logoutUser })(withRouter(keywordExtractionFromPDF));
