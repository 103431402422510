
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Sanobar Golandaj
@Description:UI for agency login
*/

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {userLogin} from "./authentication";
import { Form, Icon, Input, Button, Checkbox,Card , Select } from 'antd';
import  './userLogin.css';
import  './forgotPasswordEmail.css';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";


var dateTime = require("node-datetime")
var selectedRole="";
var selectedID="";
class ForgotPasswordEmail extends React.Component  {
  
 
  constructor() {
    super();
    this.state = {
      fields: {},
      email:'',
      errors: {},
      newsuccess:'',
      displayDropdown:"none",
      roles:[]    }

    this.handleChange = this.handleChange.bind(this);

  };

  handleChange(e) {
    let errors={}
    var email=e.target.value;
    errors['wrongemailId']=""
    this.setState({errors:errors})
    this.setState({email:email});
}



  handleSubmit = e => {
    e.preventDefault();
   var current= dateTime.create()
   current=current.format("Y-m-d H:M:S")
  //  alert(current) //current date going as last updated date- raunak
    var email=this.state.email;
    var role=selectedRole;
    var userID=selectedID;

    let data={
        email:email,
        role:role,
        lastUpdated:current,
        userID:userID //Aman-4876-added user id to pass BE to get that user info to send OTP 
    }
    fetch("/users/forgotPasswordEmail", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then(response=> { 
       this.setState({roles:response});
        if(response.length>1)
        {
          let errors={}
          this.setState({displayDropdown:"block"})
          errors['blankSubmit']="";
          errors['wrongemailId']="";
          if(selectedRole==null||selectedRole==undefined||selectedRole=="")
          {
            errors['selectRole']="Please Select Role";
          }
          else
          {
            //Aman-4876-added history.push to navigate other page without user id
            this.props.history.push({
              pathname: "/forgotPasswordOTP",
              state: { userID: userID }
            });
            //window.location.href="/forgotPasswordOTP?userID="+userID;
            // this.props.history.push("/forgotPasswordOTP", {
            //   userID: userID,
            // });
          }
          
        }
        else if(response.length===1)
        {
          let errors={}
          errors['blankSubmit']=""
          this.setState({errors:errors})
          var userID1=response[0].userID;
          window.localStorage.setItem("url", userID1); //Aman-4876-added window store user id to navigate other page with user id
          if(role==null||role==undefined)
          {
            //Aman-4876-added history.push to navigate other page without user id
            this.props.history.push({
              pathname: "/forgotPasswordOTP",
              state: { userID: userID }
            });
           // window.location.href="/forgotPasswordOTP?userID="+userID1;
            // this.props.history.push("/forgotPasswordOTP", {
            //   userID: userID1,
            // });
          }else
          {
            //Aman-4876-added history.push to navigate other page without user id
            this.props.history.push({
              pathname: "/forgotPasswordOTP",
              state: { userID: userID }
            });
            //window.location.href="/forgotPasswordOTP?userID="+userID1; 
            // this.props.history.push("/forgotPasswordOTP", {
            //   userID: userID1,
            // });
           //window.location.href="/forgotPasswordOTP?email="+email+"&role="+role;
          }
          
        }
       else if(response.length===0)  
        {
          if(this.state.email!=="")
          {
            let errors={}
            errors['blankSubmit']=""
            errors['wrongemailId']="Please Enter Valid Email-ID."
            this.setState({errors:errors})  
          }
          if(this.state.email==="")
          {
            let errors={}
            errors['blankSubmit']="Please Enter Email-ID"
            errors['wrongemailId']=""
            this.setState({errors:errors})
          }
          
        }

      })
      //Aman-4876-added window store user id to navigate other page with user id
      window.localStorage.setItem("url", userID);
  };

  componentDidMount()
  {

  }
  
  

render() {
  const { getFieldDecorator } = this.props.form;
  const {errors} = this.state;
  const { Option } = Select;
  function onChange(value) {
    
  
    var result = value.split(" ");

    selectedRole=result[0];
    selectedID =result[1];
    
  }
  
  function onBlur() {
    console.log('blur');
  }
  
  function onFocus() {
    console.log('focus');
  }
  
  function onSearch(val) {
    console.log('search:', val);
  }
  return (
    <div style={{backgroundColor:'rgb(216, 221, 230)'}}>
   

    <div class="container-fluid" style={{position:"absolute",width:"100%",height:"100%"}}>
    <div class="row" style={{position:"absolute",width:"100%",height:"100%"}}>
    <div class="col-xs-12  col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center " id="forgot_password_background"  style={{backgroundSize:'cover', backgroundPosition: 'center center',   backgroundRepeat: 'no-repeat'}}> 
    
    <Card style={{ width: 350, height:400, margin:"auto",display:"block",border:"2px solid #ff000005",borderRadius:"10px",backgroundColor:'rgba(20,72,152,0.0)',right:'381px',paddingTop:'50px'}}> 
    <Form onSubmit={this.handleSubmit} className="login-form">
      <br/>
     <span > {errors.agency}</span>
     <br/>
     <br/>
     <div id="forgot_password_form">
     <div style={{color:"white"}}>Please Enter Your Registerd E-mail ID.</div>
     <Form.Item>
       {/* Sandeep-task-3893-Production issue-Email format issue--All roles */}
          {getFieldDecorator('username', {
            rules: [
              {
                pattern:  /^[\.a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,
                 message: 'The input is not valid E-mail!'
               
                 }, 
               ],
          })(
            <Input  
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email" onChange={this.handleChange} id="email" name="email"
            />,
          )}<div style={{ fontSize: '14px', color: 'red',paddingLeft:'10px'}}>{this.state.newsuccess}</div>
        </Form.Item>
       

       {this.state.displayMsg} 
<span class="errorMessage" style={{color:'red'}}>{this.state.errors.blankSubmit}</span>


<span class="errorMessage" style={{color:'red'}}>{this.state.errors.wrongemailId}</span>

<span class="errorMessage" style={{color:'red'}}>{this.state.errors.selectRole}</span>

{/* kiran-4280-check responsiveness */}
  <Select 
    showSearch
    style={{ display:this.state.displayDropdown }}
    placeholder="Select a Role"
    optionFilterProp="children"
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    onSearch={onSearch}
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >

<Option value="Select Client" selected disabled>Select Role</Option>
                   
                   {this.state.roles.map((roles) => {
                return (
                  // Sandeep-task-3748-RD sir-PROD--login issue--Role Display issue.
                  roles.role==="ADV"?<Option value={roles.role+" "+roles.userID}>{roles.nameOfUser}-Advertiser</Option>:
                  roles.role==="AC"?<Option value={roles.role+" "+roles.userID}>{roles.nameOfUser}-Agency Commercial</Option>:
                  roles.role==="ANC"?<Option value={roles.role+" "+roles.userID}>{roles.nameOfUser}-Agency Non-Commercial</Option>:
                  roles.role==="PC"?<Option value={roles.role+" "+roles.userID}>{roles.nameOfUser}-Publisher Commercial</Option>:
                  roles.role==="PNC"?<Option value={roles.role+" "+roles.userID}>{roles.nameOfUser}-Publisher Non-Commercial</Option>:
                  roles.role==="AC-ACCOUNT"?<Option value={roles.role+" "+roles.userID}>{roles.nameOfUser}-AC Accounting</Option>:
                  roles.role==="PC-ACCOUNT"?<Option value={roles.role+" "+roles.userID}>{roles.nameOfUser}-PC Accounting</Option>:
                  roles.role==="PQA"?<Option value={roles.role+" "+roles.userID}>{roles.nameOfUser}-Publisher Quality Analyst</Option>:
                  roles.role==="AQA"?<Option value={roles.role+" "+roles.userID}>{roles.nameOfUser}-Agency Quality Analyst </Option>:
                  roles.role==="Admin"?<Option value={roles.role}>{roles.nameOfUser} Admin</Option>:''
                )})}
{/* kiran-4280-added roles  */}

  </Select>

        <Form.Item>
          <br/>
          <button type="primary" htmlType="submit" className="login-form-button" style={{width:'100px',margin:'9px',borderRadius:'40px',fontSize:'18px',backgroundImage: 'linear-gradient(#1f143a, #101f65, #093394)',outline:'none',color:'white'}}>
            Submit
          </button>
          <button type="primary" htmlType="submit" className="login-form-button" style={{width:'100px',borderRadius:'40px',fontSize:'18px',backgroundImage: 'linear-gradient(#1f143a, #101f65, #093394)',outline:'none'}}>
          <a href="userLogin" style={{color:'white'}} >Cancel</a>
          </button>
        </Form.Item>
        </div>
      </Form>
      </Card> 
      </div>     
    </div>
    </div>

   


</div>);
}

 /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      propTypes = {
        loginUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired
      }
      
      }
      const mapStateToProps = (state) => ({
        auth: state.auth,
        errors: state.errors
      })
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(ForgotPasswordEmail);
export default connect(mapStateToProps, { userLogin })(WrappedNormalLoginForm); 