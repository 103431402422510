/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Sanjana Shende,Raunak Thakkar,Snehal More,Shivani Pathak
 *@fileName :WelcomeEdit.js
 *Desc:UI for Edit Campaign
 */

import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { FaArrowAltCircleLeft } from "react-icons/fa"; //sandeep-task-3017-added code for backButton
import { Card, Steps, Tooltip } from "antd";
import "./welcomeEdit.css"; //Nilesh-5112- Add new css file

const { Step } = Steps;
class WelcomeEdit extends Component {
  constructor() {
    super();
    this.state = {
      campId: "",
      step2NotEnabled: true,
      current: 2,
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this); //sandeep-task-3017-added function handleBackButton
    this.displayCampaignSpecification =
      this.displayCampaignSpecification.bind(this); //Chaitanya-5076-Added Campaign Specification function
  }
  //end of constructor
  /**
   * @author Sandeep Dhawale
   * @param  Description function to handleBackButton
   * @return Description redirect to specific page for to handle diiferent path
   */
  handleBackButton(e) {
    const { user } = this.props.auth;
    // karan-task-3714-vapt header and query params
    var parsed = this.props.location.state;
    var edit, campID, parentCampID;
    if (parsed !== undefined) {
      edit = parsed.edit;
      campID = parsed.campID; //sunita-task-3177-parsing the campid for getting the value.
      parentCampID = parsed.parentCampID; //sunita-task-3177-parsing the parent campid for getting the value.
      this.setState({ campID, parentCampID });

      if (edit) {
        this.props.history.push("/campaignSpecificationDashboard", {
          campID: parsed.campID,
          parentCampID: parsed.parentCampID,
        }); //karan-task-3715-replace query params
      } else {
        this.props.history.push("/campaignList", { agencyID: user.id }); //karan-task-3684-replace query params
      }
    } // karan-task-3714-vapt header and query params
  } //end of handleBackButton

  handleEdit() {
    // karan-task-3714-vapt header and query params
    var parsed = this.props.location.state;
    var rfpCampaignID;
    if (parsed !== undefined) {
      // alert(parsed.campID)
      rfpCampaignID = parsed.rfpCampaignID;
      if (rfpCampaignID) {
        // window.location.href='/newEditCampaign?campID='+parsed.campID+'&parentCampID='+parsed.parentCampID+'&reallocationID='+parsed.reallocationID+'&rfpCampaignID='+rfpCampaignID;
        this.props.history.push("/newEditCampaign", {
          campID: parsed.campID,
          parentCampID: parsed.parentCampID,
          rfpCampaignID: rfpCampaignID,
        }); // karan-task-3767-replace query params
      } else {
        // window.location.href='/newEditCampaign?campID='+parsed.campID+'&parentCampID='+parsed.parentCampID+'&reallocationID='+parsed.reallocationID;
        this.props.history.push("/newEditCampaign", {
          campID: parsed.campID,
          parentCampID: parsed.parentCampID,
        }); // karan-task-3767-replace query params
      }
    } // karan-task-3714-vapt header and query params
  }
  // Chaitanya-5076-Campaign Specification function
  displayCampaignSpecification(e) {
    e.preventDefault(); //Somnath Task-3955 Add method
    var campID = e.target.getAttribute("campID");
    var parentCampID = e.target.getAttribute("parentCampId");
    var reallocationID = e.target.getAttribute("reallocationId");
    this.props.history.push("/campaignSpecificationDashboard", {
      campID,
      parentCampID,
      reallocationID,
    });
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      console.log("Log");
      // karan-task-3714-vapt header and query params
      var parsed = this.props.location.state;
      var rfpCampaignID;
      var campId = parsed.campId;
      var parentCampID = parsed.parentCampID;
      var reallocationID = parsed.reallocationID;
      if (parsed !== undefined) {
        campId = parsed.campID;
        rfpCampaignID = parsed.rfpCampaignID;
        this.setState({
          campId: campId,
          rfpCampaignID: rfpCampaignID,
          parentCampID,
          reallocationID,
        });
      } else {
        this.props.history.push("/newDashboard");
      } // karan-task-3714-vapt header and query params
    }
  }

  //end of componentDidMount
  callStep2(e) {
    if (e) e.preventDefault(); // karan-task-3714-vapt header and query params
    //alert("callStep2");
    // karan-task-3714-vapt header and query params
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      // window.location.href = '/newEditSupportingDocuments?campID='+parsed.campID+'&parentCampID='+parsed.parentCampID+'&reallocationID='+parsed.reallocationID+'&step=1';
      this.props.history.push("/newEditSupportingDocuments", {
        campID: parsed.campID,
        parentCampID: parsed.parentCampID,
        step: 1,
      }); // karan-task-3933-vapt header and query params
      //}
    } // karan-task-3714-vapt header and query params
  }

  handleEditCampaign(e) {
    // karan-task-3714-vapt header and query params
    var parsed = this.props.location.state;
    var campID, parentCampID, reallocationID, rfpCampaignID;
    if (parsed !== undefined) {
      campID = parsed.campID;
      parentCampID = parsed.parentCampID;
      reallocationID = parsed.reallocationID;
      rfpCampaignID = parsed.rfpCampaignID;
      // window.location.href = '/edit?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID;
      window.location.href =
        "/edit?campID=" + campID + "&parentCampID=" + parentCampID;
    } // karan-task-3714-vapt header and query params
  }

  render() {
    return (
      <div>
        <Navigation />

        <div
          class="container"
          style={{ marginTop: "8%", marginBottom: "35px" }}
        >
      {/* Nilesh-5112- Add new class for back button and camp spec button */}
       <div className="row welcomeEdit">
          {/* Back button */}
            <div className="col-lg-2 col-md-2 col-sm-12">
              <a>
                {" "}
                <FaArrowAltCircleLeft
                  size={32}
                  style={{ float: "left", color: "#056eb8" }}
                  //title="Back To Campaign Progress"
                  onClick={this.handleBackButton}
                />
              </a>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 mt-sm-3 mt-md-0 mt-lg-0">
            </div>
            {/* Campaign Specification button */}
            <div className="col-lg-2 col-md-2 col-sm-12">
              {/* Chaitanya-5076-Added Campaign Specification button*/}
              <button
                className="btn add-button"
                onClick={this.displayCampaignSpecification}
                campId={this.state.campId}
                parentCampID={this.state.parentCampID}
                reallocationID={this.state.reallocationID}
                style={{ float: "right" }} 
              >
                {" "}
                Campaign Specification
              </button>
            </div>
          </div>

          <div className="div_welcomeCreateCampaign">
            <div class="row">
              {/* <div class="col-lg -11 col-md-11 offset-md-3  col-sm-10"> */}
              <div className="col-lg -8 col-md-8 col-sm-7 offset-md-3">
                <div class="title" style={{ marginBottom: "10px" }}>
                  <h2 className="welcomeCreateCampaignEdit">
                    {" "}
                    Edit Campaign (Campaign ID:{this.state.campId})
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {/* end of 2st row */}
          <br />
          <div class="row" className="steps_css">
            <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-2">
              <Steps progressDot current={this.state.current}>
                <Step description="Campaign Details" />
                <Step description="Supporting Documents" />
                <Step description="Summary" />
              </Steps>
            </div>
          </div>
          {/* end of 3rd row */}
          <br />
          <br />
          <br />
          <div class="row" align="center">
            <div class="col-lg-1 col-md-2 col-sm-2"></div>
            <div class=" col-lg-5 col-md-8 col-sm-8 " align="center">
              <Card
                className="welcome_cards1"
                style={{ backgroundColor: "#c3c3c3", opacity: "1" }}
              >
                <a>
                  <Tooltip title="Edit">
                    <img
                      alt=""
                      src="NewEdit.png"
                      style={{ height: "20px", width: "20px", float: "right" }}
                      onClick={this.handleEdit}
                    />
                  </Tooltip>
                </a>
                <img
                  alt=""
                  src="Campaign_Details.png"
                  style={{ height: "84px", width: "125px" }}
                />
                <b
                  class="card_title"
                  style={{
                    marginRight: "25px",
                    fontSize: "17px",
                    fontWeight: "500",
                    fontFamily: "roboto",
                  }}
                >
                  Step 1 : Campaign Details
                </b>
                <p className="para2" style={{ marginLeft: "122px" }}>
                  <br />
                  <li>Basic Details</li>
                  <li>Delivery Options</li>
                  <li>Campaign Specifications</li>
                  <li>Custom Questions</li>
                </p>
              </Card>
            </div>
            <div class=" col-lg-5 col-md-8 col-sm-8" align="center">
              <Card
                className="welcome_cards2"
                style={{ backgroundColor: "#c3c3c3", opacity: "1" }}
              >
                <a href="#" onClick={this.callStep2.bind(this)}>
                  <Tooltip title="Edit">
                    <img
                      alt=""
                      src="NewEdit.png"
                      style={{ height: "20px", width: "20px", float: "right" }}
                    />
                  </Tooltip>
                </a>
                <img
                  alt=""
                  src="Supporting_Documents.png"
                  style={{ height: "80px", width: "125px" }}
                />
                <b
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    fontFamily: "roboto",
                  }}
                  class="card_title"
                >
                  Step 2 : Supporting Documents
                </b>
                <p
                  className="para2"
                  style={{ marginLeft: "102px", paddingBottom: "4px" }}
                >
                  <br />
                  <li>Delivery Format</li>
                  <li>ABM, Suppression, Exclusion Lists</li>
                  <li>Assets & Assets Links</li>
                  <li>Multi-touch & Asset Viz Leads</li>
                </p>
              </Card>
            </div>
          </div>
          {/* end of 2nd row */}

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              {/* <button type="button" style={{marginBottom:'62px',backgroundImage:"linear-gradient(#193D8F, #007CC3)", borderRadius:"20px", padding:"10px",color:"white",float:"right"}}> 
<img src="ic_home_24px.png" style={{height:"15px",width:"15px",display:"inline",margin:"5px"}}></img>Back To Home</button> */}
            </div>
          </div>
          {/* end of 3rd row */}
        </div>
        {/* end of container */}
        <Footer />
      </div>
    );
  }
  //end of render
}
// end of class
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
WelcomeEdit.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(WelcomeEdit)
);
