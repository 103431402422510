/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file NotFound.js
 * @author   Aman Shaikh - 5084
 * @version 1.0
 * @section DESCRIPTION Added to solve the issue of incorrect route 
 */
import React from "react";
import Header from "../src/components/layouts/header1";
import Footer from "../src/components/layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../src/components/login/authentication";
import { withRouter } from "react-router-dom";
import { Icon, Tooltip } from "antd";
import "datatables.net";
import "antd/dist/antd.css";

import "./unauthorized.css"

class NotFound extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    }
  }

  handleGoBack = () => {
    this.props.history.goBack();
  }; 

  LogintoContinue = () => {
    this.props.history.push("/userLogin");
    window.location.reload();
  }; 

  render() {
    return (
      <div>
      <Header/>
        <div className="unauthorized container-fluid" style={{ justifyContent: "center"}}>
          <div class="lock"></div>
          <div class="message">

            <img className="unauthorized-404-img" src="unauthorized-404.gif" alt="Lock" srcset="" />
            <h4>Page Not Found</h4>
          </div>
          <div className="unauthorized-action-btn">
            <Tooltip title={<span>Go back to the previous page</span>}>{/* <Icon type="arrow-left"/> */}
              <button className="unauthorized-back-btn" onClick={this.handleGoBack}>Go Back</button>
            </Tooltip>
            <Tooltip title={<span>If you have access ? Log in to Continue</span>}>
              <button className="unauthorized-login-btn" onClick={this.LogintoContinue}>Log in</button>
            </Tooltip>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
NotFound.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(NotFound)
);
