/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Shivani pathak
 *@fileName :
 *Desc: Agency Bank details tab
 */

import React from "react";
import "antd/dist/antd.css";
import { Form, Input, Button, Tooltip, Icon } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import { read_cookie, delete_cookie } from "sfcookies";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Priyanka--4062--added default header
const cookie_key = "agencyID";
const Swal = require("sweetalert2");
class AgencyBankDetailsEdit extends React.Component {
  state = {
    newsuccess: "",
    infoMsg: "",
    buttonDisplay: "disabled",
    displayBankName: "disabled",
    displayAccountNumber: "disabled",
    displayAccountHolder: "disabled",
    displayIFSCCode: "disabled",
    displayPAN: "disabled",
    displayGST: "disabled",
    bankInfo: [],
    bankInfo1: [{ bankName: "" }],
  };
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth;
      const agencyID = user.id;
      this.setState({ agencyID: agencyID });
      fetch("/agencyOnBoardDetails/getBankInfoForEdit") //Akash-task-4046
        .then((res) => res.json())
        .then((bankInfo) => {
          this.setState({ bankInfo: bankInfo });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
    var agencyID = read_cookie(cookie_key);
    if (agencyID == "") {
      this.setState({
        buttonDisplay: "",
        displayBankName: "",
        displayAccountNumber: "",
        displayAccountHolder: "",
        displayIFSCCode: "",
        displayPAN: "",
        displayGST: "",
        infoMsg: "",
      });
    } else {
      this.setState({
        buttonDisplay: "button",
        displayBankName: "",
        displayAccountNumber: "",
        displayAccountHolder: "",
        displayIFSCCode: "",
        displayPAN: "",
        displayGST: "",
        infoMsg: "",
      });
    }
  } 

  handleSubmit = (e) => {
    e.preventDefault();
    //var bankInfo = [...this.state.bankInfo];    //Chaitanya-4750-Removed Console warning
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        //const { user } = this.props.auth;     //Chaitanya-4750-Removed Console warning
        //const agencyID = user.id;             //Chaitanya-4750-Removed Console warning
        let data = {
          //agencyID:agencyID,  Priyanka--4062--removed params
          bankInfo: this.state.bankInfo,
        };
        fetch("/agencyOnBoardDetails/editAgencyBankInfo", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success == true) {
              //var a = res.message;      //Chaitanya-4750-Removed Console warning
              Swal.fire({
                type: "success",
                title: "Bank information saved successfully !",
                preConfirm: () => {
                  window.location.reload();
                }, //kiran - bug 4201- after submit first tab should open
              });
              this.setState({
                buttonDisplay: "disabled",
              });
            }
            delete_cookie("agencyID");
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
      }
    });
  };

  handleChange = (e) => {
    //const { isAuthenticated, user } = this.props.auth;    //Chaitanya-4750-Removed Console warning
    const { name, value } = e.target;
    if (name.toString() === "bankName") {   //Chaitanya-4750-Removed Console warning
      let bankInfo = [...this.state.bankInfo];
      bankInfo[0] = { ...bankInfo[0], "bankName": value };
      //this.state.bankInfo = [...bankInfo];  //Chaitanya-4750-Removed Console warning
      bankInfo = [...bankInfo];
      this.setState({bankInfo : bankInfo});
      this.setState({ bankInfo1: bankInfo }, function () {
      });
    }

    if (name.toString() === "accountNumber") {    //Chaitanya-4750-Removed Console warning
      let bankInfo = [...this.state.bankInfo];
      bankInfo[0] = { ...bankInfo[0], "accountNumber": value };
      //this.state.bankInfo = [...bankInfo];    //Chaitanya-4750-Removed Console warning
      bankInfo = [...bankInfo];
      this.setState({bankInfo : bankInfo});
      this.setState({ bankInfo1: bankInfo }, function () {
      });
    }

    if (name.toString() === "accountholderName") {    //Chaitanya-4750-Removed Console warning
      let bankInfo = [...this.state.bankInfo];
      bankInfo[0] = { ...bankInfo[0], "accountHolderName": value };
      //this.state.bankInfo = [...bankInfo];    //Chaitanya-4750-Removed Console warning
      bankInfo = [...bankInfo];
      this.setState({bankInfo : bankInfo});
      this.setState({ bankInfo1: bankInfo }, function () {
      });
    }

    if (name.toString() === "ifscCode") {   //Chaitanya-4750-Removed Console warning
      let bankInfo = [...this.state.bankInfo];
      bankInfo[0] = { ...bankInfo[0], "ifscCode": value };
      //this.state.bankInfo = [...bankInfo];    //Chaitanya-4750-Removed Console warning
      bankInfo = [...bankInfo];
      this.setState({bankInfo : bankInfo});
      this.setState({ bankInfo1: bankInfo }, function () {
      });
    }
    if (name.toString() === "panNumber") {    //Chaitanya-4750-Removed Console warning
      let bankInfo = [...this.state.bankInfo];
      bankInfo[0] = { ...bankInfo[0], "panNumber": value };
      //this.state.bankInfo = [...bankInfo];    //Chaitanya-4750-Removed Console warning
      bankInfo = [...bankInfo];
      this.setState({bankInfo : bankInfo});
      this.setState({ bankInfo1: bankInfo }, function () {
      });
    }
    if (name.toString() === "taxNumber") {    //Chaitanya-4750-Removed Console warning
      let bankInfo = [...this.state.bankInfo];
      bankInfo[0] = { ...bankInfo[0], "gstNumber": value };
      //this.state.bankInfo = [...bankInfo];    //Chaitanya-4750-Removed Console warning
      bankInfo = [...bankInfo];
      this.setState({bankInfo : bankInfo});
      this.setState({ bankInfo1: bankInfo }, function () {
      });
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <div>
        <div class="container-fluid">
          <br />
          <br />
          <div
            style={{ fontSize: "22px", color: "green", paddingLeft: "490px" }}
          >
            {this.state.newsuccess}
          </div>
          <div
            style={{ fontSize: "16px", color: "#1890ff", paddingLeft: "490px" }}
          >
            {this.state.infoMsg}
          </div>
          <br />
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <div class="row">
              <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
                {this.state.bankInfo.map((bankInfo) => (
                  <Form.Item label={<span>Bank Name&nbsp;</span>}>
                    {getFieldDecorator("bankName", {
                      initialValue: bankInfo.bankName,
                      rules: [
                        {
                          pattern: /^[A-Za-z\s]+$/,
                          required: true,
                          message: "Please input your bank name!",
                          whitespace: true,
                        },
                      ],
                    })(
                      <Input
                        onChange={this.handleChange}
                        id="bankName"
                        name="bankName"
                      />
                    )}
                  </Form.Item>
                ))}
              </div>
              <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
                {this.state.bankInfo.map((bankInfo) => (
                  <Form.Item label={<span>Account Number&nbsp;</span>}>
                    {getFieldDecorator("accountNumber", {
                      initialValue: bankInfo.accountNumber,
                      rules: [
                        {
                          pattern: /^[0-9]*$/,
                          required: true,
                          message: "Please input your valid account number!",
                          whitespace: true,
                        },
                      ],
                    })(
                      <Input
                        onChange={this.handleChange}
                        id="accountNumber"
                        name="accountNumber"
                      />
                    )}
                  </Form.Item>
                ))}
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
                {this.state.bankInfo.map((bankInfo) => (
                  <Form.Item label={<span>Accountholder Name&nbsp;</span>}>
                    {getFieldDecorator("accountholderName", {
                      initialValue: bankInfo.accountHolderName,
                      rules: [
                        {
                          pattern: /^[A-Za-z\s]+$/,
                          required: true,
                          message: "Please input your accountholder name!",
                          whitespace: true,
                        },
                      ],
                    })(
                      <Input
                        onChange={this.handleChange}
                        id="accountholderName"
                        name="accountholderName"
                      />
                    )}
                  </Form.Item>
                ))}
              </div>
              <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
                {this.state.bankInfo.map((bankInfo) => (
                  <Form.Item
                    label={
                      <span>
                        IFSC Code&nbsp;
                        <Tooltip title="Example -BKID0000660">
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("ifscCode", {
                      initialValue: bankInfo.ifscCode,
                      rules: [
                        {
                          pattern: /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
                          required: true,
                          message: "Please input your IFSC Code!",
                          whitespace: true,
                        },
                      ],
                    })(
                      <Input
                        onChange={this.handleChange}
                        id="ifscCode"
                        name="ifscCode"
                      />
                    )}
                  </Form.Item>
                ))}
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
                {this.state.bankInfo.map((bankInfo) => (
                  <Form.Item
                    label={
                      <span>
                        PAN/TAN Number&nbsp;
                        <Tooltip
                          title="Example:PAN:AAAPL1234C&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;TAN: RXOY 02811 K"
                        >
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("panNumber", {
                      initialValue: bankInfo.panNumber,
                      rules: [
                        {
                          pattern: /[A-Za-z]{5}\d{4}[A-Za-z]{1}/,
                          required: true,
                          message: "Please input your PAN/TAN Number!",
                        },
                      ],
                    })(
                      <Input
                        style={{ width: "100%" }}
                        onChange={this.handleChange}
                        id="panNumber"
                        name="panNumber"
                      />
                    )}
                  </Form.Item>
                ))}
              </div>
              <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
                {this.state.bankInfo.map((bankInfo) => (
                  <Form.Item
                    label={
                      <span>
                        TAX/GST Number&nbsp;
                        <Tooltip title="Example- TAX :XXX-XX-XXXX   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GST: 22 AAAAA0000A 1 Z 5">
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("taxNumber", {
                      initialValue: bankInfo.gstNumber,
                      rules: [
                        {
                          pattern:
                            /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
                          required: true,
                          message: "Please input TAX/GST Number!",
                        },
                      ],
                    })(
                      <Input
                        onChange={this.handleChange}
                        id="taxNumber"
                        name="taxNumber"
                      />
                    )}
                  </Form.Item>
                ))}
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6"></div>
              <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginBottom: "60px" }}
                    className={this.state.buttonDisplay}
                  >
                    Save
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
}

AgencyBankDetailsEdit.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const agencyRegistrationForm4 = Form.create({ name: "register" })(
  AgencyBankDetailsEdit
);
export default connect(mapStateToProps, { logoutUser })(
  withRouter(agencyRegistrationForm4)
);
