/* Copyright(c) 2022 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Chaitanya Shinde
 *@fileName :addCustomCountry.js
 *Desc: Task-3982-New UI for Add Custom Country
 */
import React, { Component } from "react";
import { Tooltip, Checkbox, Input, Icon } from "antd";
import NavigationBar from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft, FaSearch } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import styled from "styled-components";
import $ from "jquery";
import "./addCustomRegion.css";
const Swal = require("sweetalert2");
const CheckboxGroup = Checkbox.Group;
const NorthernAfricaCountriesOptions = require("../campaignCreationFile/countryRegionArray")
  .NorthernAfricaCountriesOptions;
const CentralOrMiddleAfricanOptions = require("../campaignCreationFile/countryRegionArray")
  .CentralOrMiddleAfricanOptions;
const SouthernAfricaCountriesOptions = require("../campaignCreationFile/countryRegionArray")
  .SouthernAfricaCountriesOptions;
const EastAfricanCountriesOptions = require("../campaignCreationFile/countryRegionArray")
  .EastAfricanCountriesOptions;
const WesternAfricaOptions = require("../campaignCreationFile/countryRegionArray")
  .WesternAfricaOptions;
const MiddleEastOptions = require("../campaignCreationFile/countryRegionArray")
  .MiddleEastOptions;
const EasternEuropeOptions = require("../campaignCreationFile/countryRegionArray")
  .EasternEuropeOptions;
const NorthernEuropeOptions = require("../campaignCreationFile/countryRegionArray")
  .NorthernEuropeOptions;
const SouthernEuropeOptions = require("../campaignCreationFile/countryRegionArray")
  .SouthernEuropeOptions;
const WesternEuropeOptions = require("../campaignCreationFile/countryRegionArray")
  .WesternEuropeOptions;
const CentralAsiaOptions = require("../campaignCreationFile/countryRegionArray")
  .CentralAsiaOptions;
const EastAsiaOptions = require("../campaignCreationFile/countryRegionArray")
  .EastAsiaOptions;
const SouthAsiaOptions = require("../campaignCreationFile/countryRegionArray")
  .SouthAsiaOptions;
const SoutheastAsiaOptions = require("../campaignCreationFile/countryRegionArray")
  .SoutheastAsiaOptions;
const WesternAsiaOptions = require("../campaignCreationFile/countryRegionArray")
  .WesternAsiaOptions;
const SouthAmericaOptions = require("../campaignCreationFile/countryRegionArray")
  .SouthAmericaOptions;
const AntarcticOptions = require("../campaignCreationFile/countryRegionArray")
  .AntarcticOptions;
const NorthAmericaOptions = require("../campaignCreationFile/countryRegionArray")
  .NorthAmericaOptions;
const MelanesiaOptions = require("../campaignCreationFile/countryRegionArray")
  .MelanesiaOptions;
const PolynesiaOptions = require("../campaignCreationFile/countryRegionArray")
  .PolynesiaOptions;
const MicronesiaOptions = require("../campaignCreationFile/countryRegionArray")
  .MicronesiaOptions;
const SouthernAtlanticOceanOptions = require("../campaignCreationFile/countryRegionArray")
  .SouthernAtlanticOceanOptions;
const HtmlTooltip = styled(Tooltip)`
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '12px',
    border: '1px solid #dadde9',
    `;

class AddCustomRegion extends Component {
  constructor() {
    super();
    this.state = {
      regionName: "",
      spanlist: {},
      error: {},
      NorthernAfricaCountriescheckedList: [],
      CentralOrMiddleAfricancheckedList: [],
      SouthernAfricaCountriescheckedList: [],
      EastAfricanCountriescheckedList: [],
      WesternAfricacheckedList: [],
      middleEastCheckedList: [],
      EasternEuropecheckedList: [],
      NorthernEuropecheckedList: [],
      SouthernEuropecheckedList: [],
      WesternEuropecheckedList: [],
      CentralAsiacheckedList: [],
      EastAsiacheckedList: [],
      SouthAsiacheckedList: [],
      SoutheastAsiacheckedList: [],
      WesternAsiacheckedList: [],
      SouthAmericacheckedList: [],
      AntarcticcheckedList: [],
      NorthAmericacheckedList: [],
      PolynesiacheckedList: [],
      MicronesiacheckedList: [],
      SouthernAtlanticOceancheckedList: [],
      countryArray: [],
      errorCountrySelect: "none",
      addCustomCountryPopUp: "none",
      regionerror1: "none",
      regionerror2: "none",
      regionerror3: "none",
      regionError4: "none",
      errorregionName: "none",
    };
    this.handleChangeCountryRegionSearch = this.handleChangeCountryRegionSearch.bind(
      this
    );
    this.validateForm = this.validateForm.bind(this);
    this.addCustomCountry = this.addCustomCountry.bind(this);
    this.createCustomRegion = this.createCustomRegion.bind(this);
    this.commonhandleChange = this.commonhandleChange.bind(this);
  }
  addCountryBackButton() {
    const { user } = this.props.auth;
    this.props.history.push("/newdashboard", { agencyID: user.id });
  }
  handleChangeCountryRegionSearch(e) {
    let colorCount = 0;
    for (const [key, value] of Object.entries(this.state.spanlist)) {
      if (
        e.target.value.length > 0 &&
        RegExp(/^[^1234567890^!@#$%*()_={}\n]+$/).test(e.target.value)
      ) {
        if (key.includes(e.target.value.toLowerCase())) {
          value[1].style.backgroundColor = "yellow";
          colorCount += 1;
        } else {
          value[1].style.backgroundColor = "whitesmoke";
        }
      } else {
        value[1].style.backgroundColor = "whitesmoke";
      }
    }
  }
  handleRefreshPage(e) {
    Swal.fire({
      title: "Are you sure you want to discard the changes?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      allowOutsideClick: false,
      confirmButtonText: "Discard",
    }).then((result) => {
      if (result.value) {
        window.location.href = "/newdashboard";
      } else if (result.dismiss === "close") {
        window.location.href = "#";
      } else {
        window.location.href = "#";
      }
    });
  }
  cancelReginoNamePopup(e) {}
  componentDidMount() {
    for (
      let i = 0;
      i <
      document.getElementById("myCountryDiv").getElementsByTagName("span")
        .length;
      i++
    ) {
      if (
        document.getElementById("myCountryDiv").getElementsByTagName("span")[i]
          .className === ""
      ) {
        if (
          document
            .getElementById("myCountryDiv")
            .getElementsByTagName("span")
            [i].innerText.toLowerCase() != "select all"
        ) {
          this.state.spanlist[
            document
              .getElementById("myCountryDiv")
              .getElementsByTagName("span")
              [i].innerText.toLowerCase()
          ] = [
            document
              .getElementById("myCountryDiv")
              .getElementsByTagName("span")
              [i].innerText.toLowerCase(),
            document
              .getElementById("myCountryDiv")
              .getElementsByTagName("span")[i],
          ];
        }
      }
    }
  }
  onCheckAllChangeSouthAmerica = (e) => {
    this.setState({
      SouthAmericacheckedList: e.target.checked ? SouthAmericaOptions : [],
      checkAll15: e.target.checked,
    });
  };
  onChangeSouthAmerica = (SouthAmericacheckedList) => {
    this.setState({
      SouthAmericacheckedList,
      checkAll15: SouthAmericacheckedList.length === SouthAmericaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeNorthAmerica = (e) => {
    this.setState({
      NorthAmericacheckedList: e.target.checked ? NorthAmericaOptions : [],
      checkAll17: e.target.checked,
    });
  };
  onChangeNorthAmerica = (NorthAmericacheckedList) => {
    this.setState({
      NorthAmericacheckedList,
      checkAll17: NorthAmericacheckedList.length === NorthAmericaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeEasternEurope = (e) => {
    this.setState({
      EasternEuropecheckedList: e.target.checked ? EasternEuropeOptions : [],
      checkAll6: e.target.checked,
    });
  };
  onChangeEasternEurope = (EasternEuropecheckedList) => {
    this.setState({
      EasternEuropecheckedList,
      checkAll6:
        EasternEuropecheckedList.length === EasternEuropeOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeNorthernEurope = (e) => {
    this.setState({
      NorthernEuropecheckedList: e.target.checked ? NorthernEuropeOptions : [],
      checkAll7: e.target.checked,
    });
  };
  onChangeNorthernEurope = (NorthernEuropecheckedList) => {
    this.setState({
      NorthernEuropecheckedList,
      checkAll7:
        NorthernEuropecheckedList.length === NorthernEuropeOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeSouthernEurope = (e) => {
    this.setState({
      SouthernEuropecheckedList: e.target.checked ? SouthernEuropeOptions : [],
      checkAll8: e.target.checked,
    });
  };
  onChangeSouthernEurope = (SouthernEuropecheckedList) => {
    this.setState({
      SouthernEuropecheckedList,
      checkAll8:
        SouthernEuropecheckedList.length === SouthernEuropeOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeWesternEurope = (e) => {
    this.setState({
      WesternEuropecheckedList: e.target.checked ? WesternEuropeOptions : [],
      checkAll9: e.target.checked,
    });
  };
  onChangeWesternEurope = (WesternEuropecheckedList) => {
    this.setState({
      WesternEuropecheckedList,
      checkAll9:
        WesternEuropecheckedList.length === WesternEuropeOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeCentralAsia = (e) => {
    this.setState({
      CentralAsiacheckedList: e.target.checked ? CentralAsiaOptions : [],
      checkAll10: e.target.checked,
    });
  };
  onChangeCentralAsia = (CentralAsiacheckedList) => {
    this.setState({
      CentralAsiacheckedList,
      checkAll10: CentralAsiacheckedList.length === CentralAsiaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeEastAsia = (e) => {
    this.setState({
      EastAsiacheckedList: e.target.checked ? EastAsiaOptions : [],
      checkAll11: e.target.checked,
    });
  };
  onChangeEastAsia = (EastAsiacheckedList) => {
    this.setState({
      EastAsiacheckedList,
      checkAll11: EastAsiacheckedList.length === EastAsiaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeSouthAsia = (e) => {
    this.setState({
      SouthAsiacheckedList: e.target.checked ? SouthAsiaOptions : [],
      checkAll12: e.target.checked,
    });
  };
  onChangeSouthAsia = (SouthAsiacheckedList) => {
    this.setState({
      SouthAsiacheckedList,
      checkAll12: SouthAsiacheckedList.length === SouthAsiaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeSoutheastAsia = (e) => {
    this.setState({
      SoutheastAsiacheckedList: e.target.checked ? SoutheastAsiaOptions : [],
      checkAll13: e.target.checked,
    });
  };
  onChangeSoutheastAsia = (SoutheastAsiacheckedList) => {
    this.setState({
      SoutheastAsiacheckedList,
      checkAll13:
        SoutheastAsiacheckedList.length === SoutheastAsiaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeWesternAsia = (e) => {
    this.setState({
      WesternAsiacheckedList: e.target.checked ? WesternAsiaOptions : [],
      checkAll14: e.target.checked,
    });
  };
  onChangeWesternAsia = (WesternAsiacheckedList) => {
    this.setState({
      WesternAsiacheckedList,
      checkAll14: WesternAsiacheckedList.length === WesternAsiaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeNorthernAfricaCountries = (e) => {
    this.setState({
      NorthernAfricaCountriescheckedList: e.target.checked
        ? NorthernAfricaCountriesOptions
        : [],
      checkAll1: e.target.checked,
    });
  };
  onChangeNorthernAfricaCountries = (NorthernAfricaCountriescheckedList) => {
    this.setState({
      NorthernAfricaCountriescheckedList,
      checkAll1:
        NorthernAfricaCountriescheckedList.length ===
        NorthernAfricaCountriesOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeCentralOrMiddleAfrican = (e) => {
    this.setState({
      CentralOrMiddleAfricancheckedList: e.target.checked
        ? CentralOrMiddleAfricanOptions
        : [],
      checkAll2: e.target.checked,
    });
  };
  onChangeCentralOrMiddleAfrican = (CentralOrMiddleAfricancheckedList) => {
    this.setState({
      CentralOrMiddleAfricancheckedList,
      checkAll2:
        CentralOrMiddleAfricancheckedList.length ===
        CentralOrMiddleAfricanOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeSouthernAfricaCountries = (e) => {
    this.setState({
      SouthernAfricaCountriescheckedList: e.target.checked
        ? SouthernAfricaCountriesOptions
        : [],
      checkAll3: e.target.checked,
    });
  };
  onChangeSouthernAfricaCountries = (SouthernAfricaCountriescheckedList) => {
    this.setState({
      SouthernAfricaCountriescheckedList,
      checkAll3:
        SouthernAfricaCountriescheckedList.length ===
        SouthernAfricaCountriesOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeEastAfricanCountries = (e) => {
    this.setState({
      EastAfricanCountriescheckedList: e.target.checked
        ? EastAfricanCountriesOptions
        : [],
      checkAll4: e.target.checked,
    });
  };
  onChangeEastAfricanCountries = (EastAfricanCountriescheckedList) => {
    this.setState({
      EastAfricanCountriescheckedList,
      checkAll4:
        EastAfricanCountriescheckedList.length ===
        EastAfricanCountriesOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeWesternAfrica = (e) => {
    this.setState({
      WesternAfricacheckedList: e.target.checked ? WesternAfricaOptions : [],
      checkAll5: e.target.checked,
    });
  };
  onChangeWesternAfrica = (WesternAfricacheckedList) => {
    this.setState({
      WesternAfricacheckedList,
      checkAll5:
        WesternAfricacheckedList.length === WesternAfricaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeMiddleEast = (e) => {
    this.setState({
      middleEastCheckedList: e.target.checked ? MiddleEastOptions : [],
      checkAll27: e.target.checked,
    });
  };
  onChangeMiddleEast = (middleEastCheckedList) => {
    this.setState({
      middleEastCheckedList,
      checkAll27: middleEastCheckedList.length === MiddleEastOptions.length,
    });
  };
  /////////////////////////////////////////////////////
  onChangeAntarctic = (AntarcticcheckedList) => {
    this.setState({
      AntarcticcheckedList,
      //checkAll27:AntarcticcheckedList.length === MiddleEastOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeMelanesia = (e) => {
    this.setState({
      MelanesiacheckedList: e.target.checked ? MelanesiaOptions : [],
      checkAll19: e.target.checked,
    });
  };
  onChangeMelanesia = (MelanesiacheckedList) => {
    this.setState({
      MelanesiacheckedList,
      checkAll19: MelanesiacheckedList.length === MelanesiaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangePolynesia = (e) => {
    this.setState({
      PolynesiacheckedList: e.target.checked ? PolynesiaOptions : [],
      checkAll20: e.target.checked,
    });
  };
  onChangePolynesia = (PolynesiacheckedList) => {
    this.setState({
      PolynesiacheckedList,
      checkAll20: PolynesiacheckedList.length === PolynesiaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeMicronesia = (e) => {
    this.setState({
      MicronesiacheckedList: e.target.checked ? MicronesiaOptions : [],
      checkAll21: e.target.checked,
    });
  };
  onChangeMicronesia = (MicronesiacheckedList) => {
    this.setState({
      MicronesiacheckedList,
      checkAll21: MicronesiacheckedList.length === MicronesiaOptions.length,
    });
  };
  ///////////////////////////////////////////////////////
  onCheckAllChangeSouthernAtlanticOcean = (e) => {
    this.setState({
      SouthernAtlanticOceancheckedList: e.target.checked
        ? SouthernAtlanticOceanOptions
        : [],
      checkAll22: e.target.checked,
    });
  };
  onChangeSouthernAtlanticOcean = (SouthernAtlanticOceancheckedList) => {
    this.setState({
      SouthernAtlanticOceancheckedList,
      checkAll22:
        SouthernAtlanticOceancheckedList.length ===
        SouthernAtlanticOceanOptions.length,
    });
  };
  validateForm() {
    let formIsvalid = true;
    let error = {};
    let countryArray = {};
    countryArray = this.state.NorthernAfricaCountriescheckedList.concat(
      this.state.CentralOrMiddleAfricancheckedList,
      this.state.SouthernAfricaCountriescheckedList,
      this.state.EastAfricanCountriescheckedList,
      this.state.WesternAfricacheckedList,
      this.state.middleEastCheckedList,
      this.state.EasternEuropecheckedList,
      this.state.NorthernEuropecheckedList,
      this.state.SouthernEuropecheckedList,
      this.state.WesternEuropecheckedList,
      this.state.CentralAsiacheckedList,
      this.state.EastAsiacheckedList,
      this.state.SouthAsiacheckedList,
      this.state.SoutheastAsiacheckedList,
      this.state.WesternAsiacheckedList,
      this.state.SouthAmericacheckedList,
      this.state.AntarcticcheckedList,
      this.state.NorthAmericacheckedList,
      this.state.MelanesiacheckedList,
      this.state.PolynesiacheckedList,
      this.state.MicronesiacheckedList,
      this.state.SouthernAtlanticOceancheckedList
    );
    this.setState({ countryArray });
    if (countryArray.length <= 2) {
      formIsvalid = false;
      this.setState(
        { errorCountrySelect: "block", alertBorder1: "1px solid red" },
        function() {
          Swal.fire({
            title: "Please select more than 1 country to add custom region",
            titleColor: "#FF0000",
            type: "warning",
            confirmButtonColor: "#3085d6",
            allowOutsideClick: false,
            confirmButtonText: "OK",
            preConfirm: () => {
              window.location.reload();
            },
          });
        }
      );
    } else {
      this.setState({ errorCountrySelect: "none", alertBorder1: "0px" });
      $("#addCustomCountryPopUp").modal("show");
    }
    return formIsvalid;
  }

  validateRegionName() {
    let formIsvalid = true;
    let error = {};
    let regionName1 = this.state.regionName.replace(/ /g, "");
    if (regionName1 !== "") {
      if (regionName1.length > 50) {
        formIsvalid = false;
        document.getElementById("regionName").style.border = "1px solid red";
        error["regionError1"] = (
          <li>
            <b>
              You have exceeded maximum character limit. Maximum 50 characters
              are allowed
            </b>
          </li>
        );
        this.setState({ error: error, alertBorder: "1px solid red" });
        return formIsvalid;
      } else {
        formIsvalid = false;
        var pattern = RegExp(/[\/:*"'<>?#|]/);
        var pattern1 = new RegExp(/^(|[0-9]\d*)$/);
        if (pattern.test(regionName1)) {
          document.getElementById("regionName").style.border = "1px solid red";
          error["regionError2"] = (
            <li>
              <b>
                Please enter valid input. A region name can't contain any of the
                character /:*?''&lt;&gt;|
              </b>
            </li>
          );
          this.setState({ error: error, alertBorder: "1px solid red" });
          return formIsvalid;
        }
        if (pattern1.test(regionName1)) {
          document.getElementById("regionName").style.border = "1px solid red";
          error["regionError4"] = (
            <li>
              <b>
                Please enter valid input. A region name can't contain only
                numbers
              </b>
            </li>
          );
          this.setState({ error: error, alertBorder: "1px solid red" });
          return formIsvalid;
        }
      }
    } else {
      formIsvalid = false;
      document.getElementById("regionName").style.border = "1px solid red";
      error["regionError3"] = (
        <li>
          <b>Please enter valid region name.</b>
        </li>
      );
      this.setState({ error: error, alertBorder: "1px solid red" });
      return formIsvalid;
    }
  }
  addCustomCountry(e) {
    e.preventDefault();
    if (this.validateForm() === false) {
    } else {
      $("#addCustomCountryPopUp").modal("show");
    }
  }
  commonhandleChange(e) {
    let error = {};
    const { name, value } = e.target;
    if (name == "regionName") {
      document.getElementById("regionName").style.border =
        "1px solid lightgray";

      this.setState({
        regionName: e.target.value,
        alertBorder: "0px",
        error: "",
      });
    }
  }
  createCustomRegion(e) {
    e.preventDefault();
    if (this.validateRegionName() === false) {
    } else {
      $("#addCustomCountryPopUp").modal("hide");
      let data = {
        countryArray: this.state.countryArray,
        regionName: this.state.regionName,
      };
      fetch("/campaignNew/createCustomRegion", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          Swal.fire({
            text: "Custom region created successfully",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        });
    }
  }
  render() {
    return (
      <div>
        <NavigationBar />
        <div style={{ paddingTop: "100px" }}>
          <div class="row">
            <div
              class="col-lg-1 col-md-1 col-sm-1 col-xs-2"
              // style={{ float: "left", marginLeft: "40px" }}
            >
              <a
                class="col backArrowCustomRegion"
                href="#"
                //className="backArrowCustomRegion"
                style={{ color: "#056eb8" }}
                onClick={this.addCountryBackButton.bind(this)}
              >
                <FaArrowAltCircleLeft size={32} title="Back to Dashboard" />
              </a>
            </div>
            <div
              class="col-lg-10 col-md-10 col-sm-10 col-xs-8"
              style={{
                border: "2px solid gray",
                borderRadius: "5px",
                marginBottom: "100px",
              }}
            >
              <br />
              <div align="center">
                <label
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    color: "#056eb8",
                  }}
                >
                  {" "}
                  Add Custom Region{" "}
                </label>
              </div>
              <div class="container1">
                <div>
                  <div class="row" style={{}}>
                    {/* <div id="error-list" 
                      className="errorMessage"
                      style={{paddingLeft:"10px", 
                      fontSize: "17px",
                      display: this.state.errorCountrySelect,
                      border:this.state.alertBorder1,
                      width:"95%",
                      marginBottom:'5px'}}>
                 <li>
							   <b>Please select country for creating new region</b>
							  </li>
               </div> */}
                    <br />
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "#056eb8",
                        }}
                      >
                        {" "}
                        Countries &nbsp;&nbsp;
                      </label>
                      <Tooltip
                        placement="right"
                        title="Select countries to add custom region"
                      >
                        <Icon
                          type="question-circle"
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#056eb8",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <Input
                        onChange={(e) => {
                          this.handleChangeCountryRegionSearch(e);
                        }}
                        className="addRegion_search"
                        placeholder="Searching for"
                        style={{ width: "50%", float: "right" }}
                        prefix={
                          <FaSearch size={13} style={{ float: "left" }} />
                        }
                      />
                      {/* <span id="colorCount" className="searchCount">
																	0/219
																</span> */}
                    </div>
                  </div>
                  <div
                    id="myCountryDiv"
                    className="myCountryDiv_css"
                    style={{ height: "400px", overflowY: "scroll" }}
                  >
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      South America
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeSouthAmerica}
                      checked={this.state.checkAll15}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={SouthAmericaOptions}
                      value={this.state.SouthAmericacheckedList}
                      onChange={this.onChangeSouthAmerica}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      North America
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeNorthAmerica}
                      checked={this.state.checkAll17}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={NorthAmericaOptions}
                      value={this.state.NorthAmericacheckedList}
                      onChange={this.onChangeNorthAmerica}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Eastern Europe
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeEasternEurope}
                      checked={this.state.checkAll6}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={EasternEuropeOptions}
                      value={this.state.EasternEuropecheckedList}
                      onChange={this.onChangeEasternEurope}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Northern Europe
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeNorthernEurope}
                      checked={this.state.checkAll7}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={NorthernEuropeOptions}
                      value={this.state.NorthernEuropecheckedList}
                      onChange={this.onChangeNorthernEurope}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Southern Europe
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeSouthernEurope}
                      checked={this.state.checkAll8}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={SouthernEuropeOptions}
                      value={this.state.SouthernEuropecheckedList}
                      onChange={this.onChangeSouthernEurope}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Western Europe
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeWesternEurope}
                      checked={this.state.checkAll9}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={WesternEuropeOptions}
                      value={this.state.WesternEuropecheckedList}
                      onChange={this.onChangeWesternEurope}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Central Asia
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeCentralAsia}
                      checked={this.state.checkAll10}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={CentralAsiaOptions}
                      value={this.state.CentralAsiacheckedList}
                      onChange={this.onChangeCentralAsia}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      East Asia
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeEastAsia}
                      checked={this.state.checkAll11}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={EastAsiaOptions}
                      value={this.state.EastAsiacheckedList}
                      onChange={this.onChangeEastAsia}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      South Asia
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeSouthAsia}
                      checked={this.state.checkAll12}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={SouthAsiaOptions}
                      value={this.state.SouthAsiacheckedList}
                      onChange={this.onChangeSouthAsia}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Southeast Asia
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeSoutheastAsia}
                      checked={this.state.checkAll13}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={SoutheastAsiaOptions}
                      value={this.state.SoutheastAsiacheckedList}
                      onChange={this.onChangeSoutheastAsia}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Western Asia
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeWesternAsia}
                      checked={this.state.checkAll14}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={WesternAsiaOptions}
                      value={this.state.WesternAsiacheckedList}
                      onChange={this.onChangeWesternAsia}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Northern Africa Countries
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeNorthernAfricaCountries}
                      checked={this.state.checkAll1}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={NorthernAfricaCountriesOptions}
                      value={this.state.NorthernAfricaCountriescheckedList}
                      onChange={this.onChangeNorthernAfricaCountries}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Central or Middle African
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeCentralOrMiddleAfrican}
                      checked={this.state.checkAll2}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={CentralOrMiddleAfricanOptions}
                      value={this.state.CentralOrMiddleAfricancheckedList}
                      onChange={this.onChangeCentralOrMiddleAfrican}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Southern Africa Countries
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeSouthernAfricaCountries}
                      checked={this.state.checkAll3}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={SouthernAfricaCountriesOptions}
                      value={this.state.SouthernAfricaCountriescheckedList}
                      onChange={this.onChangeSouthernAfricaCountries}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      East African Countries
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeEastAfricanCountries}
                      checked={this.state.checkAll4}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={EastAfricanCountriesOptions}
                      value={this.state.EastAfricanCountriescheckedList}
                      onChange={this.onChangeEastAfricanCountries}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Western Africa
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeWesternAfrica}
                      checked={this.state.checkAll5}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={WesternAfricaOptions}
                      value={this.state.WesternAfricacheckedList}
                      onChange={this.onChangeWesternAfrica}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Middle East
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeMiddleEast}
                      checked={this.state.checkAll27}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={MiddleEastOptions}
                      value={this.state.middleEastCheckedList}
                      onChange={this.onChangeMiddleEast}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Antarctic
                    </b>
                    &nbsp;&nbsp;
                    {/* <Checkbox
														onChange={this.onCheckAllChangeMiddleEast}
                            checked={this.state.checkAll27}>Select All </Checkbox> */}
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={AntarcticOptions}
                      value={this.state.AntarcticcheckedList}
                      onChange={this.onChangeAntarctic}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Melanesia
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeMelanesia}
                      checked={this.state.checkAll19}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={MelanesiaOptions}
                      value={this.state.MelanesiacheckedList}
                      onChange={this.onChangeMelanesia}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Polynesia
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangePolynesia}
                      checked={this.state.checkAll20}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={PolynesiaOptions}
                      value={this.state.PolynesiacheckedList}
                      onChange={this.onChangePolynesia}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Micronesia
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeMicronesia}
                      checked={this.state.checkAll21}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={MicronesiaOptions}
                      value={this.state.MicronesiacheckedList}
                      onChange={this.onChangeMicronesia}
                    />
                    <br />
                    <br />
                    <b
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#414141",
                      }}
                    >
                      Southern Atlantic Ocean
                    </b>
                    &nbsp;&nbsp;
                    <Checkbox
                      onChange={this.onCheckAllChangeSouthernAtlanticOcean}
                      checked={this.state.checkAll22}
                    >
                      Select All{" "}
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                      style={{ gridAutoColumns: "auto", columnCount: "1" }}
                      options={SouthernAtlanticOceanOptions}
                      value={this.state.SouthernAtlanticOceancheckedList}
                      onChange={this.onChangeSouthernAtlanticOcean}
                    />
                    <br />
                    <br />
                  </div>
                  <br />
                  <div
                    className="row button_row"
                    style={{ float: "right", marginRight: "0%" }}
                  >
                    <button
                      class="btn "
                      style={{
                        color: "#144999",
                        float: "right",
                        margin: "0px 0px 30px",
                        borderColor: "#144999",
                        backgroundColor: "#FFFFFF",
                        border: "2px solid #144999",
                      }}
                      type="button"
                      value="cancel"
                      onClick={this.handleRefreshPage}
                    >
                      &nbsp;&nbsp; Cancel &nbsp;&nbsp;
                    </button>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      class="btn"
                      style={{
                        color: "#144999",
                        float: "right",
                        margin: "0px 0px 30px",
                        borderColor: "#144999",
                        backgroundColor: "#144999",
                        backgroundImage:
                          "linear-gradient(to left,#0777cb, #103784)",
                        color: "white",
                        border: "2px solid #144999",
                      }}
                      type="button"
                      value="cancel"
                      onClick={this.addCustomCountry}
                      data-toggle="modal"
                      data-target="#addCustomCountryPopUp"
                      data-backdrop="false"
                      data-keyboard="false"
                    >
                      &nbsp;&nbsp; Add &nbsp;&nbsp;
                    </button>
                  </div>
                  {this.state.errorCountrySelect === "block" ? (
                    ""
                  ) : (
                    <div
                      className="modal fade"
                      id="addCustomCountryPopUp"
                      role="dialog"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                    >
                      <div className="modal-dialog modal-md">
                        <div
                          className="modal-content regionNamePopup"
                          style={{ marginTop: "152px" }}
                        >
                          <div
                            class="modal-header"
                            style={{
                              backgroundColor: "#144999",
                              height: "50px",
                            }}
                          >
                            <p
                              style={{
                                color: "#FFFFFF",
                                fontSize: "17px",
                                fontWeight: "400 ",
                                fontFamily: "Roboto",
                              }}
                            >
                              Region Name
                              <button
                                type="button"
                                class="close"
                                style={{
                                  fontColor: "#FFFFFF",
                                  fontSize: "18px",
                                  marginTop: "-5px",
                                  fontWeight: "400",
                                }}
                                data-dismiss="modal"
                              >
                                &times;
                              </button>
                            </p>
                          </div>
                          <div
                            class="col"
                            style={{ display: this.state.alertDisplay }}
                          >
                            <div class="container-fluid">
                              <div class="card-body">
                                <div
                                  class="col-lg-12 col-md-12 col-sm-12"
                                  style={{ border: this.state.alertBorder }}
                                >
                                  <div
                                    id="error-list"
                                    className="errorMessage"
                                    style={{ fontSize: "17px" }}
                                  >
                                    {this.state.error.regionError1
                                      ? this.state.error.regionError1
                                      : ""}
                                    {this.state.error.regionError2
                                      ? this.state.error.regionError2
                                      : ""}
                                    {this.state.error.regionError3
                                      ? this.state.error.regionError3
                                      : ""}
                                    {this.state.error.regionError4
                                      ? this.state.error.regionError4
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <div class="container-row">
                            <div
                              class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-xs-6"
                              align="center"
                            >
                              <label
                                className="labelStyle"
                                style={{ fontSize: "14px" }}
                              >
                                Region Name{" "}
                              </label>
                              <span className="asterisk">*</span>
                              <HtmlTooltip
                                placement="right"
                                title={
                                  <React.Fragment>
                                    <ol>
                                      <li>
                                        {"Maximum 50 charactors are allowed."}
                                      </li>
                                      <li>
                                        {
                                          "A region name can't conatain any of the following character /:*?''<>|"
                                        }
                                      </li>
                                      <li>
                                        {
                                          "A region name can't conatain only numeric values."
                                        }
                                      </li>
                                    </ol>
                                  </React.Fragment>
                                }
                              >
                                <Icon type="question-circle" />
                              </HtmlTooltip>
                              <label
                                className="labelStyle"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                &nbsp;&nbsp;:
                              </label>
                            </div>
                            <div
                              class="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-xs-6"
                              align="left"
                            >
                              <input
                                type="text"
                                id="regionName"
                                className="form-control"
                                name="regionName"
                                required
                                // style={{ width: "90%" }}
                                onChange={this.commonhandleChange}
                                value={this.state.regionName}
                              ></input>
                            </div>
                          </div>
                          <br />
                          <br />
                          <div
                            class="container-row" /*style={{ padding: "15px" }}*/
                          >
                            <div class="offset-lg-5 offset-md-5 regionCancelAdd">
                              <button
                                class="btn"
                                style={{
                                  color: "#144999",
                                  margin: "0px 0px 30px",
                                  borderColor: "#144999",
                                  backgroundColor: "#FFFFFF",
                                  border: "2px solid #144999",
                                }}
                                type="button"
                                value="cancel"
                                data-dismiss="modal"
                              >
                                &nbsp;&nbsp; Cancel &nbsp;&nbsp;
                              </button>{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <button
                                class="btn"
                                style={{
                                  color: "#144999",
                                  // float: "right",
                                  margin: "0px 0px 30px",
                                  borderColor: "#144999",
                                  backgroundColor: "#144999",
                                  backgroundImage:
                                    "linear-gradient(to left,#0777cb, #103784)",
                                  color: "white",
                                  border: "2px solid #144999",
                                }}
                                type="cancel"
                                id="create_region"
                                value="cancel"
                                onClick={this.createCustomRegion}
                              >
                                &nbsp;&nbsp; Add &nbsp;&nbsp;
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-2"></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AddCustomRegion.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(AddCustomRegion)
);
