/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Shivani Pathak
 *@fileName :WelcomePage.js
 *Desc:UI for Create Campaign Welcome page
 */

import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import { Card, Steps, Tooltip } from "antd";
import "./welcomePageStyle.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3766-specification VAPT api call
const { Step } = Steps;
class WelcomePage extends Component {
	constructor() {
		super();
		this.state = {
			campId: "",
			step2NotEnabled: true,
			current: 0,
			rfpCampaignID: "",
			step2Incomplete: false,
		};
	}
	//end of constructor
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			// karan-task-3933-vapt header and query params
			var parsed = this.props.location.state;
			var rfpCampaignID, campID;
			if (parsed !== undefined) {
				if (parsed.hasOwnProperty("campID")) {
					campID = parsed.campID;
				}
				if (parsed.hasOwnProperty("rfpCampaignID")) {
					rfpCampaignID = parsed.rfpCampaignID;
				}
			}
			this.setState({ rfpCampaignID: rfpCampaignID });
			this.setState({ campId: campID }, function () {
				if (this.state.campId === "" || this.state.campId === undefined) {
					this.setState({ step2NotEnabled: true }, function () {
						console.log(this.state.step2Enabled);
					});
				} else {
					this.setState(
						{ step2NotEnabled: false, current: this.state.current + 1 },
						function () {
							console.log(this.state.step2Enabled);
						}
					);
				}
			});

			fetch("campaign/CampaignDetails?campID=" + campID + "")
				.then((res) => res.json())
				.then((campaignDetails) => {
					if (campaignDetails.length > 0) {
						if (campaignDetails[0].status === "SupportingDocument") {
							this.setState({ step2Incomplete: true });
						}
					}
				});
		}
	}

	/**
	 * @author : Karan Jagtap
	 * @description : This will be called for new code file supportingDocumentCreate.js
	 */
	// User Story 3125 : Karan Jagtap - Only the name is modified, definition is the same
	// route - '/step2supportingdocument'
	callStep2(e) {
		e.preventDefault();
		var campID = this.state.campId;
		var rfpCampaignID = this.state.rfpCampaignID;
		// karan-task-3933-vapt header and query params
		var fromBack;
		var parsed = this.props.location.state;
		if (parsed !== undefined) {
			if (parsed.hasOwnProperty("fromBack")) {
				fromBack = parsed.fromBack;
			}
			if (
				parsed.hasOwnProperty("rfpCampaignID") &&
				(rfpCampaignID === undefined || rfpCampaignID === null)
			) {
				rfpCampaignID = parsed.rfpCampaignID;
			}
		}
		if (fromBack === undefined || fromBack === "") {
			if (rfpCampaignID) {
				this.props.history.push("/step2supportingdocument", {
					campID,
					rfpCampaignID,
					rfpCampaign: "",
				}); // karan-task-3719-vapt header and query params
			} else {
				let campID = this.state.campId;
				// check for campaign step2.1 is complete and then navigate it to back flow
				if (this.state.step2Incomplete) {
					this.props.history.push("/step2supportingdocument", {
						campID,
						step2: "",
					}); // karan-task-3719-vapt header and query params
				} else {
					this.props.history.push("/step2supportingdocument", {
						campID,
					}); // karan-task-3719-vapt header and query params
				}
			}
		} else {
			if (rfpCampaignID === undefined || rfpCampaignID === "") {
				let campID = this.state.campId;
				this.props.history.push("/step2supportingdocument", {
					campID,
					step2: "",
				}); // karan-task-3719-vapt header and query params
			} else {
				this.props.history.push("/step2supportingdocument", {
					campID,
					rfpCampaignID,
					rfpCampaign: "",
				}); // karan-task-3719-vapt header and query params
			}
		}
	}

	handleEdit() {
		var campID = this.state.campId;
		// karan-task-3933-vapt header and query params
		var parsed = this.props.location.state;
		var rfpCampaignID;
		if (parsed !== undefined) {
			if (parsed.hasOwnProperty("rfpCampaignID")) {
				rfpCampaignID = parsed.rfpCampaignID;
			}
		}
		if (rfpCampaignID) {
			// alert("inside if block");
			this.props.history.push("/createcampaign2", {
				campID,
				status: "Step1",
			}); // karan-task-3767-replace query params
		} else {
			this.props.history.push("/createcampaign2", {
				campID,
				status: "Step1",
			}); // karan-task-3767-replace query params
		}
	}

	//end of componentDidMount

	render() {
		const { user } = this.props.auth;
		return (
			<div>
				<Navigation />
				<div
					class="container"
					style={{ marginTop: "100px", marginBottom: "35px" }}>
					<div className="div_welcomeCreateCampaign">
						<div class="row">
							<div class="col-lg -6 col-md-6 offset-md-3  col-sm-6">
								<div class="title" style={{ marginBottom: "10px" }}>
									<h2 className="welcomeCreateCampaign">
										{" "}
										Welcome to Create Campaign
									</h2>
								</div>
							</div>
						</div>
						{/* end of 1st row */}
						{/* <div class="row">
														 <div class="col-lg-12 col-md-12 col-lg-12 col-sm-12" align='center'>
												 <p className="para1"> Campaign name, Client name, Agency Campaign ID, Campaign Status,
																 Start Date, End Date, First lead Delivery Date, No.of Lead per Domain, Lead Allocation, 
																 Campaign Budget, CPL, Campaign Currency, Pacing, Pacing Count, Marketing Channel, 
																 Campaign reporting Day, Campaign Type, Creatives Approval required,  Creatives approval Timeline.</p>
												 </div>
												 </div> */}
					</div>
					<br />
					<div class="row" className="steps_css">
						<div class="col-md-8 offset-md-2 col-sm-10 offset-sm-2">
							<Steps progressDot current={this.state.current}>
								<Step description="Campaign Details" />
								<Step description="Supporting Documents" />
								<Step description="Summary" />
							</Steps>
						</div>
					</div>
					{/* end of 3rd row */}
					<br />
					<br />
					<br />

					<div class="row" align="center">
						<div class="col-lg-1 col-md-2 col-sm-2"></div>
						<div class=" col-lg-5 col-md-8 col-sm-8 " align="center">
							<Card className="welcome_cards1">
								<img
									src="Campaign_Details.png"
									style={{ height: "80px", width: "125px" }}
									alt="Campaign_Details"
								/>
								<b
									style={{
										fontSize: "17px",
										fontWeight: "500",
										fontFamily: "roboto",
									}}
									class="card_title">
									Step 1 : Campaign Details
								</b>
								<p className="para2" style={{ marginLeft: "122px" }}>
									<br />

									<li>Basic Details</li>
									<li>Delivery Options</li>
									<li>Campaign Specifications</li>
									<li>Custom Questions</li>
								</p>
								{this.state.campId === "" || this.state.campId === undefined ? (
									user.role === "ANC" ? (
										<a href="/createcampaign2">
											<button
												type="button"
												class="btn"
												disabled
												style={{
													backgroundImage: "linear-gradient(#193D8F, #007CC3)",
													borderRadius: "100px",
												}}>
												Let's Create Campaign
											</button>
										</a>
									) : (
										<a href="/createcampaign2">
											<button
												type="button"
												class="btn"
												style={{
													backgroundImage: "linear-gradient(#193D8F, #007CC3)",
													borderRadius: "100px",
												}}>
												Let's Create Campaign
											</button>
										</a>
									)
								) : (
									<a href="#/" onClick={this.handleEdit.bind(this)}>
										<button
											type="button"
											class="btn"
											style={{
												backgroundImage: "linear-gradient(#193D8F, #007CC3)",
												borderRadius: "100px",
												width: "153px",
											}}>
											Let's Edit Campaign
										</button>
									</a>
								)}
							</Card>
						</div>

						<div class=" col-lg-5 col-md-8 col-sm-8" align="center">
							<Card className="welcome_cards2">
								<img
									src="Supporting_Documents.png"
									style={{ height: "80px", width: "125px" }}
									alt="Supporting_Documents"
								/>
								<b
									style={{
										fontSize: "17px",
										fontFamily: "roboto",
										fontWeight: "500",
									}}
									class="card_title">
									Step 2 : Supporting Documents
								</b>
								<p className="para2" style={{ marginLeft: "102px" }}>
									<br />
									<li>Delivery Format</li>
									<li>ABM, Suppression, Exclusion Lists</li>
									<li>Assets & Assets Links</li>
									<li>Multi-touch & Asset Viz Leads</li>
								</p>
								{this.state.campId === "" || this.state.campId === undefined ? (
									<button
										type="button"
										className="button_upload"
										disabled={this.state.step2NotEnabled}
										style={{
											borderRadius: "100px",
											padding: "4px",
											marginTop: "1px",
											width: "153px",
										}}>
										Upload Documents
									</button>
								) : (
									<a href="#/">
										<button
											type="button"
											className="button_upload"
											onClick={this.callStep2.bind(this)}
											style={{
												backgroundImage: "linear-gradient(#193D8F, #007CC3)",
												borderRadius: "100px",
												padding: "5px",
												color: "white",
												width: "153px",
											}}>
											Continue
										</button>
									</a>
								)}
							</Card>
						</div>
					</div>
				</div>{" "}
				{/* end of container */}
				<Footer />
			</div>
		);
	}
	//end of render
}
// end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
WelcomePage.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(WelcomePage)
);
