/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author :Shivani pathak
*@fileName :advertiserInformationEdit.js
 *Desc: Advertiser Info Edit 
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd'; 
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import Footer from '../layouts/footer';
import Navigation from '../layouts/navPage';
//import { Card,Steps,Tooltip } from 'antd'; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
//import { ThemeConsumer } from 'styled-components'; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
//import { MDBBtn, CardBody } from "mdbreact"; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
import AdvertiserCompanyInformationEdit from "./advertiserCompanyInformationEdit";
import AdvertiserContactInformationEdit from "./advertiserContactInformationEdit";
import { FaArrowAltCircleLeft } from "react-icons/fa";

//const Swal = require('sweetalert2'); //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
class AdvertiserInformationEdit extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
         
        }
        this.handleBackButton=  this.handleBackButton.bind(this);
     };
     handleBackButton(e){
        e.preventDefault();  //Aman-4821-(added preventDefault)-Advertiser dashboard shows in URL#/
        //const {user} = this.props.auth;//Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
        //localStorage.removeItem('invoiceKey');
        window.location.href="/advertiserInformation";
      }
     render(){
        const { TabPane } = Tabs;
        return(
            <div>
                 <Navigation /> 
                 <div class="container-fluid" style={{paddingTop:"100px"}}>
                        <br/>
                        <div>
                        {/*Aman-4749-(href attribute is required added "/")-Console Warnings - Advertiser - Settings */}
                        <a href={"#/"}> <FaArrowAltCircleLeft size={32} style={{float:'left',color:'#056eb8',paddingBottom:'3px'}} title="Back" onClick={this.handleBackButton} /></a>
                        </div>
                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                        </div>
                       
                        <div class="col-xs-12  col-sm-12 col-md-4 col-lg-4">
                        {/* </div></div><label id="label" style={{ ,marginLeft:"450px"}}>Campaign Allocation</label> */}

                        <span ><h4 style={{textAlign:"center",color:"#056eb8",fontSize:"20px",fontWeight:"bold"}}>Edit Advertiser Information</h4></span>
                        
                        </div>
                        
                        <div class=" col-md-4 col-lg-4">
                        </div>
                    </div>
                    {/* End of 1st Row */}
                    <div class="row">
                    <div class="col-xs-12  col-sm-12 col-md-12 col-lg-12">
                        <Tabs onChange={this.callback} type="card">
                        <TabPane tab="Advertiser Information" key="1-Company Information">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
                         <AdvertiserCompanyInformationEdit /> 
                        </div>
                        </TabPane>
                        <TabPane tab="Contact Information" key="2-Contact Information">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
                         <AdvertiserContactInformationEdit /> 
                        </div>
                        </TabPane>
                        


                        {/* <TabPane tab="Privacy Policy" key="5-Privacy Policy">
                        Content of Tab Pane 5
                        </TabPane> */}
                        </Tabs>
                        </div>
                    </div>
                    {/* End of 2nd Row */}
                    </div>
                 <Footer/> 
                </div>
        )
     }
    }
    /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      AdvertiserInformationEdit.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
    
    
    export default connect(mapStateToProps, { logoutUser })(withRouter(AdvertiserInformationEdit));