
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Sanjana Godbole
*@fileName :
 *Desc: Advertiser On Board Form
*/

import React from 'react';
import 'antd/dist/antd.css';
import * as $ from "jquery";
import Footer from "../layouts/footer";
import {Form,Input,Tooltip,Icon,Cascader,Select,Row,Col,Checkbox,Button,AutoComplete,Card} from 'antd';
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
const cookie_key = 'advertiserID';
const { Option } = Select;

const AutoCompleteOption = AutoComplete.Option;
class AdvertiserContactInformation extends React.Component {
  state = {
    confirmDirty: false,
    newsuccess:[],
    autoCompleteResult: [],
    infoMsg:'',

    buttonDisplay:'disabled',
    displayContact1:'disabled',
    displayContact2:'disabled',
    displayDesignation1:'disabled',
    displayDesignation2:'disabled',
    displayEmail1:'disabled',
    displayEmail2:'disabled',
    displayPhn1:'disabled',
    displayPhn2:'disabled',
    errors:{},
  };

  componentDidMount()
  {
    
    var pID=localStorage.getItem('pID');
    //var pID=localStorage.getItem('pID');
    var advertiserID=localStorage.getItem("advertiserID")
    // = read_cookie(cookie_key);
  // alert(advertiserID)
 // var advertiserID="50001";
    // alert(advertiserID)
    //Nilesh-5155- add trim method 
    if(!advertiserID || advertiserID.trim() === "")
    // if(advertiserID=="")
    {

      this.setState({
        buttonDisplay: "button",
      
        displayContact1:'disabled',
        displayContact2:'disabled',
        displayDesignation1:'disabled',
        displayDesignation2:'disabled',
        displayEmail1:'disabled',
        displayEmail2:'disabled',
        displayPhn1:'disabled',
        displayPhn2:'disabled',
        infoMsg:'Please Fill Company Information First'
      });
   
    }
    else


    {
      // alert("inside else");
      this.setState({
        buttonDisplay:'',
        displayContact1:'',
        displayContact2:'',
        displayDesignation1:'',
        displayDesignation2:'',
        displayEmail1:'',
        displayEmail2:'',
        displayPhn1:'',
        displayPhn2:'',
        infoMsg:''
      }); 
    }
  
  }
  handleSubmit = e => {
    e.preventDefault();
    let errors={} //Rutuja 5154 declared error for showing phone code error message
    this.props.form.validateFieldsAndScroll((err, values) => {
      //Rutuja 5154 if phone code is not selected then setstate error message
      if(values.prefix ==="Select")
        {
          errors["PhoneCode"]="Please select Phone Code!";
          this.setState({
            errors:errors
          })
        }
          //Rutuja 5154 if phone code is selected then setstate error to null
      if (!err && values.prefix !=="Select") {
        this.setState({
          errors:''
        })
        console.log('Received values of form: ', values);
       // debugger;
        //var advertiserID=localStorage.getItem('advertiserID');
          var advertiserID= read_cookie(cookie_key);
        var data=[];
        data=[{'contactID':1,'userID':advertiserID,'contactPerson':values.contactPerson,'lastName':values.contactPerson1LastName,'designation':values.designation,'email':values.email,countryPhoneCode:values.prefix,'phoneNo':values.phone},{'contactID':2,'userID':advertiserID,'contactPerson':values.contactPerson2,
        'lastName':values.contactPerson2LastName,'designation':values.designation2,'email':values.email2,countryPhoneCode:values.prefix2,'phoneNo':values.phone2}]
       

        fetch("/publisherOnBoardDetails/contactInfo", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }).then(res=>res.json()).then(res=>{
          console.warn("Response in res in=="+JSON.stringify(res));
            if(res.success==true)
            {
              var a=(res.message);
             
              this.setState({newsuccess:a});
              this.setState({
                buttonDisplay:'disabled'});
               
               
               
                // $(document).ready(function(){
                //   $("#formId input,textarea,select,Timezone,Delivery Channel,City,Country,AutoComplete,Picky,Checkbox,ReCAPTCHA").prop("disabled",true);
                //   })
             }
        }).catch(function (err) {
          console.log(err)
        });

      }
    });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleWebsiteChange = value => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
    }
    this.setState({ autoCompleteResult });
  };


  handleChange=e => {

    let fields = this.state;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
    
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const phonePrefix=["1","7","20","27","30","32","33","34","36","39","40","41","43","44","45","46","47","48","49","51","52","53","54","55","56","57","58","60","61","62","63","64","65","66","76","77","81","82","84","86","87","90","91","92","93","94","95","98","211","212","213","216","218","220","221","222","223","224","225","226","227","228","229","230","231","232","234","235","236","237","238","239","240","241","242","243","244","245","246","247","248","249","250","251","252","254","255","256","257","258","260","261","262","263","264","265","266","267","268","269","290","291","297","298","299","350","351","352","353","354","355","356","357","358","359","370","371","372","373","374","375","376","377","378","380","381","382","383","385","386","387","389","420","421","423","500","501","502","503","504","505","506","507","508","509","590","591","592","594","595","596","597","598","670","672","673","674","675","676","677","678","679","680","681","682","683","685","686","687","688","689","690","691","692","800","850","852","853","855","856","870","878","880","881","882","883","886","888","942","960","961","963","964","965","966","967","968","970","971","972","973","974","975","976","977","979","992","993","994","995","996","998","1242","1246","1248","1264","1268","1284","1340","1345","1441","1473","1649","1664","1670","1671","1684","1721","1758","1784","1787","1808","1809","1829","1849","1868","1869","1876","1939","2908","3732","3735","4428","4779","5399","5993","5994","5997","5999","6721","6723","7840","7940","8810","8811","8812","8813","8816","8817","8818","8819","35818","37447","37449","37744","37745","38128","38129","38138","38139","38643","38649","88213","88216","90392","262269","262639","441481","441534","441624","447524","447624","447781","447839","447911","447924","6189162","6189164"];
   
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: 'Select',
    })(
      <Select style={{ width: 110 }} showSearch >
        {phonePrefix.map(a=>(
        <Option value={a}>+ {a}</Option>
        ))}
      </Select>,
       );
      const prefixSelector2 = getFieldDecorator('prefix2', {
        initialValue: 'Select',
      })(
        <Select style={{ width: 110 }} showSearch >
        {phonePrefix.map(a=>(
        <Option value={a}>+ {a}</Option>
        ))}
      </Select>,
      );
  
  
 

    const websiteOptions = autoCompleteResult.map(website => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));

    return (
      <div>
      
      <div class="container-fluid">
          <br/><br/>
          <div style={{ fontSize: '22px', color: 'green',paddingLeft:'490px'}}>{this.state.newsuccess}</div>
          <div style={{ fontSize: '16px', color: '#1890ff',paddingLeft:'490px'}}>{this.state.infoMsg}</div>
          <br/>

  <Form id='formId'{...formItemLayout} onSubmit={this.handleSubmit}>


  <div class="row">
<div  class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
  <Form.Item
          label={
            <span>
            Contact Person 1&nbsp;
           
            </span>
          }
          />
          </div>
          <div  class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
          <Form.Item
          label={
            <span>
            Contact Person 2&nbsp;
           
            </span>
          }
          />  
           </div>
     </div>   

<div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
  <Form.Item
          label={
            <span>
            FirstName&nbsp;
          
            </span>
          }
        >
          {getFieldDecorator('contactPerson', {
            rules: [{pattern:/^[A-Za-z\s]+$/,message: 'Please enter the name', whitespace: true, required: true}],
          })(<Input id="contactp" name="contactp" disabled={this.state.displayContact1} />)}
        </Form.Item>
        </div>
 
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
       
        <Form.Item label={<span>FirstName
          
        </span>
        }>
          {getFieldDecorator('contactPerson2', {
            rules: [{ pattern:/^[A-Za-z\s]+$/}],
          })(<Input id="contactp2" name="contactp2" disabled={this.state.displayContact2} />)}
        </Form.Item>
        </div>
 </div>  
 {/* End of 1st Row */}
 <div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
  <Form.Item
          label={
            <span>
            LastName&nbsp;
          
            </span>
          }
        >
          {getFieldDecorator('contactPerson1LastName', {
            rules: [{ pattern:/^[A-Za-z\s]+$/,required: true, message: 'Please enter the Last name!' }],
          })(<Input id="contactp1lastname" name="contactp1lastname" disabled={this.state.displayContact1}/>)}
           </Form.Item>

        </div>
 
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
       
        <Form.Item label={<span>LastName
        </span>
        }>
          {getFieldDecorator('contactPerson2LastName', {
            rules: [{ pattern:/^[A-Za-z\s]+$/}],
          })(<Input id="contactp2lastname" name="contactp2lastname" disabled={this.state.displayContact2} />)}
        </Form.Item>
        </div>
 </div>  


 <div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
  <Form.Item
          label={
            <span>
            Designation&nbsp;
           
            </span>
          }
        >
          {getFieldDecorator('designation', {
            rules: [{ pattern:/^[A-Za-z\s]+$/,required: true, message: 'Please input the designation!', whitespace: true }],
          })(<Input onChange={this.handleChange} id="designation" name="designation" disabled={this.state.displayDesignation1} />)}
        </Form.Item>
       
        </div>
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
  <Form.Item
          label={
            <span>
            Designation&nbsp;
          
            </span>
          }
        >
          {getFieldDecorator('designation2', {
            rules: [{pattern:/^[A-Za-z\s]+$/,whitespace: true }],
          })(<Input onChange={this.handleChange} id="designation2" name="designation2" disabled={this.state.displayDesignation2} />)}
        </Form.Item>
        </div>
 </div> 
{/* End of 2nd Row       */}

 <div class="row">

<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
<Form.Item label={<span>E-mail&nbsp;
    <Tooltip title="As per registration">
        <Icon type="question-circle-o" />
    </Tooltip>
    </span>
    }
    >
          {getFieldDecorator('email', {
            rules: [
              {
                         //shivani - 3599 - changed RegEx as per validation requirement for hyphen .
                         //saurabh - 3745- changed RegEx, user should not be able to add social domain.
                pattern:/^\s*$|^[a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@(?!gmail.com)(?!gmail.co.in)(?!yahoo.com)(?!yahoo.co.in)[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,

                // pattern:  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ],
          })(<Input onChange={this.handleChange} disabled={this.state.displayEmail1} id="email" name="email" />)}
        </Form.Item>
       
        </div>
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
        <Form.Item label={<span>E-mail&nbsp;
    <Tooltip title="As per registration">
        <Icon type="question-circle-o" />
    </Tooltip>
    </span>
    }
    >
          {getFieldDecorator('email2', {
            rules: [
              {
                         //shivani - 3599 - changed RegEx as per validation requirement for hyphen .
                          //saurabh - 3745- changed RegEx, user should not be able to add social domain.
                pattern:/^\s*$|^[a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@(?!gmail.com)(?!gmail.co.in)(?!yahoo.com)(?!yahoo.co.in)[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,

                // pattern:  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/,
                message: 'The input is not valid E-mail!',
                
                           },
             

            ],
          })(<Input onChange={this.handleChange} disabled={this.state.displayEmail1} id="email" name="email" />)}
        </Form.Item>
        </div>
 </div>
{/* End of 2nd Row */}
       

       <div class="row">
       <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
      
       
       <Form.Item label={<span>Phone Number&nbsp;
        <Tooltip title="As per registration">
          <Icon type="question-circle-o" />
      </Tooltip>
   </span>
   }>
         {getFieldDecorator('phone', {
           rules: [{pattern:/^[0-9][0-9]{2,12}$/, required: true, message: 'Please enter a valid phone number!' }],
         })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} disabled={this.state.displayPhn1} onChange={this.handleChange} id="phone" name="phone" />)}
       </Form.Item>
       {/* Rutuja task 5154 showing error message for phone code */}
       <span class="errorMessage" style={{paddingLeft:'205px',color:'#f5222d'}}>{this.state.errors.PhoneCode}</span>

       </div>

       <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">  
       
       <Form.Item label={<span>Phone Number&nbsp;
       <Tooltip title="As per registration">
          <Icon type="question-circle-o" />
      </Tooltip>
      </span>
       }>
         {getFieldDecorator('phone2', {
           rules: [{pattern:/^[0-9][0-9]{2,12}$/}],
         })(<Input addonBefore={prefixSelector2} style={{ width: '100%' }} disabled={this.state.displayPhn2}  onChange={this.handleChange} id="phone2" name="phone2" />)}
       </Form.Item>
       </div>

 </div> 

 {/* End of 4th row */}
<div class="row" > 
<div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
           
          </div>
        <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6">
          <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit"  className={this.state.buttonDisplay}>
            Save 
          </Button>
        </Form.Item> 
          </div>
        </div>

        {/* End of 6th row */}

 

 <div class="row">
        <div class="col-xs-4  col-sm-4 col-md-4 col-lg-4">
          </div>
         
          <div class="col-xs-4  col-sm-4 col-md-4 col-lg-4">
          </div>
     </div>





      </Form>  
</div>
<Footer/>
</div> 
// Final Div
     
    );
  }
}
const AdvertiserRegistrationForm = Form.create({ name: 'register' })(AdvertiserContactInformation);

export default AdvertiserRegistrationForm;
//ReactDOM.render(<WrappedRegistrationForm />, document.getElementById('container'));
          