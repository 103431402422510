// import { rootCertificates } from "tls";   //Nilesh-2897-Removing console warnings

/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Nikita Sheth
@Description:UI for Change Password under Publisher View
Date:09-12-2019
*/
import React, { Component } from "react";
import { Form, Input, button, Card, Icon, Tooltip } from "antd";
import { connect } from "react-redux";
import "./userLogin.css";
import "./changPassword.css"
import Navigation from "../layouts/publisherNavPage";
// import PropTypes from "prop-types"; //Nilesh-2897-Removing console warnings
import Footer from "../layouts/footer";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params

const Swal = require("sweetalert2");

export class ChangePassword extends Component {
	constructor() {
		super();
		this.state = {
			password: "",
			oldPassword: "",
			confirmPassword: "",
			confirmDirty: false,
			fields: {},
			errors: {},
			orgID: "",
			userDetails: [],
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			
			if (!err) {
				console.log("Received values of form: ", values);
				//  if(this.state.oldPassword === values.oldPassword){
				//alert("oldPassword: "+values.oldPassword+", New password: "+values.password+", confirmPassword: "+values.confirmPassword);
				// const { isAuthenticated, user } = this.props.auth; //Nilesh-2897-Removing console warnings
				let data = {
					password: values.password,
				}; // karan-task-3723-vapt header and query params

				fetch("/users/setNewPassword", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((response) => {
						if (response.success === true) {
							
							Swal.fire({
								text: "Password Changed Successfully..",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									
									// const { isAuthenticated, user } = this.props.auth; //Nilesh-2897-Removing console warnings
									this.props.history.push("/newPublisherDashboard"); //karan-task-3717-replace query params
								},
							});
							
						}
					});
				
			}
		});
	};

	handleConfirmBlur = (e) => {
		const { value } = e.target;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value });
	};

	compareToFirstPassword = (rule, value, callback) => {
		const { form } = this.props;
		if (value && value !== form.getFieldValue("password")) {
			callback("Two passwords that you enter is inconsistent!");
		} else {
			callback();
		}
	};

	validateToNextPassword = (rule, value, callback) => {
		const { form } = this.props;
		if (value && this.state.confirmDirty) {
			form.validateFields(["confirm"], { force: true });
		}
		callback();
	};

	validateToOldPassword = (rule, value, callback) => {
		const { form } = this.props;
		if (value && this.state.confirmDirty) {
			form.validateFields(["confirm"], { force: true });
		}
		if (value) {
			if (this.state.oldPassword !== form.getFieldValue("oldPassword")) {
				callback("Old password not matching!");
			}
		}
		callback();
	};

	componentDidMount() {
		
		// const { isAuthenticated, user } = this.props.auth;  
		const { user } = this.props.auth; //Nilesh-2897-Removing console warnings
		var orgID = user.id;
		this.setState({ orgID: orgID });

		fetch("/users/getUserInfo", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
		}) // karan-task-3723-vapt header and query params
			.then((res) => res.json())
			.then((userDetails) => {
				this.setState({
					userDetails: userDetails,
					oldPassword: userDetails[0].password,
				});

				
			});
		
	}
	render() {
		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 },
			},
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0,
				},
				sm: {
					span: 16,
					offset: 8,
				},
			},
		};

		
		return (
			<div
			 // 4251 - Umesh Ahire - R28.8-Publisher login-Setting option-change password page issue
			  style={{
				backgroundColor: "rgb(216, 221, 230)",
				textAlign: "center",
				paddingTop: "111px",
			  }}
			>
			<Navigation />
			<div	// 4251 - Umesh Ahire - added position : fixed
					className="container-fluid bg-custom-light" 
					style={{position: "fixed", width: "100%", height: "100%" ,backgroundColor:"rgb(216, 221, 230);"}}> 
         	 <div
						className="row"
						style={{ position: "absolute", width: "100%", height: "100%" }}>

 	 					 {/* // 4251 - Umesh Ahire - R28.8-Publisher login-Setting option-change password page issue */ }
						   <div className="col-xs-12  col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center cp-container">	
						
							<Card
								style={{
									width: 550,
									height: 400,
									margin: "auto",
									display: "block",
									border: "2px solid #0000001c",
									borderRadius: "8px",
									backgroundColor: "rgba(20,72,152,0.0)",
								}}>
								<h3 style={{ textAlign: "center", color: "rgb(16, 31, 101)" }}>
									Change Password
								</h3>
								<Form onSubmit={this.handleSubmit}>
									<br />
									<br />
									<Form.Item
										label={<span>Old Password&nbsp;&nbsp;&nbsp;</span>}
										hasFeedback
										{...formItemLayout}>
										{getFieldDecorator("oldPassword", {
											rules: [
												{
													required: true,
													message: "Please enter old password!",
												},
												{
													validator: this.validateToOldPassword,
												},
											],
										})(
											<Input.Password
												prefix={
													<Icon
														type="lock"
														style={{ color: "rgba(0,0,0,.25)" }}
													/>
												}
												type="password"
												placeholder="Old Password"
												id="oldPassword"
												name="oldPassword"
											/>
										)}
									</Form.Item>

									<Form.Item
										label={
											<span>
												New Password&nbsp;
												<Tooltip title="Password with 6 characters containing a-z,A-Z,0-9,@#$%&...">
													<Icon
														type="question-circle-o"
														className="iconbackground1"
													/>
													{/*sunita-task-3818-added classname as per requirement for removing white patches */}
												</Tooltip>
												&nbsp;&nbsp;
											</span>
										}
										hasFeedback
										{...formItemLayout}>
										{getFieldDecorator("password", {
											rules: [
												{
													required: true,
													message: "Please enter New password!",
												},
												{
													min: 6,
													message: "Password can only be of 6 characters",
												},
												{
													pattern:
														"(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]){6}",
													message: "Please enter valid password!",
												},
												{
													validator: this.validateToNextPassword,
												},
											],
										})(
											<Input.Password
												prefix={
													<Icon
														type="lock"
														style={{ color: "rgba(0,0,0,.25)" }}
													/>
												}
												type="password"
												placeholder="Password"
												id="password"
												name="password"
												title="Password with 6 characters containing a-z,A-Z,0-9,@#$%&..."
											/>
										)}
									</Form.Item>

									<Form.Item
										label={<span>Confirm Password&nbsp;&nbsp;&nbsp;</span>}
										hasFeedback
										{...formItemLayout}>
										{getFieldDecorator("confirmPassword", {
											rules: [
												{
													required: true,
													message: "Please confirm your password!",
												},
												{
													validator: this.compareToFirstPassword,
												},
											],
										})(
											<Input.Password
												prefix={
													<Icon
														type="lock"
														style={{ color: "rgba(0,0,0,.25)" }}
													/>
												}
												type="password"
												placeholder="Confirm Password"
												id="confirmPassword"
												name="confirmPassword"
											/>
										)}
									</Form.Item>

									<br />
									<Form.Item {...tailFormItemLayout}>
										<button
											type="primary"
											htmltype="submit"
											style={{
												borderRadius: "40px",
												maxWidth: "240px",
												width: "100%",
												fontSize: "18px",
												backgroundImage:
													"linear-gradient(#1f143a, #101f65, #093394)",
												outline: "none",
												color: "white",
											}}>
											Submit
										</button>
									</Form.Item>
								</Form>
							</Card>
					</div>
				<Footer />
				
				</div>
				</div>
				</div>
		
		);
	}
}
const mapStateToProps = (state) => ({
	auth: state.auth,
	errors: state.errors,
});

const WrappedChangedPassword = Form.create({ name: "change-password" })(
	ChangePassword
);
export default connect(mapStateToProps, { ChangePassword })(
	WrappedChangedPassword
);
// export default ChangePassword
