/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/**
* @file linkPublisher 
* @author Sanobar Golandaj
* @version 1.0
* @section publisher linking with agency
*/
import React, { Component } from 'react';
//import {Table} from 'mdbreact'; //Rutuja 4753 console warrning
import AdminNavpage from '../layouts/adminNavpage.js';
import './admin.css';
import Footer from '../layouts/footer';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Somnath Task-3930-added default header
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
import { FaArrowAltCircleLeft } from "react-icons/fa";
//import Picky from "react-picky"; //Rutuja 4753 console warrning
const Swal = require('sweetalert2');


// var list; //Rutuja 4753 console warrning
// var PubList; //Rutuja 4753 console warrning
var ndaValidate=false;
var ioValidate=false;
var rmdValidate=false;


const PublisherNameArray=[];
  
class LinkPublisher extends Component
{
    constructor(){
        super();
        this.state = {
            errors:{},
            agencyArray:[],
            publisherArray:[],
            agencyPubArray:[],
            revenueArray:[],
            pubLinkArray:[],
            pIDLinkArray:[],
            agencyID:'',
            PublisherNameList:[],
            selectedOption4:"",
            PublisherName:PublisherNameArray,
            pubArray:[],
            alertDisplay: 'none',
            alertBorder: '',
            //msaValue:'', //Rutuja 4753 console warrning
            msaValue:'No',
            ndaValue:'No',
            rmdValue:'No',
            ioValue:'No',
            revenueValue:'',
            paymentValue:'',
            currencyValue:'USD',
            taxValue:'',
            interstValue:'',
           // pubArray:[], //Rutuja 4753 console warrning
            agencyByPass:"ON",
            agencyQaRole:"OFF",
            visible:"drpdisable",
            buttonDisplay: "btn add-button", //rutuja-task-4325 disabled button still popup shows 
        }
        

        this.handleChangeAgency = this.handleChangeAgency.bind(this);
        this.handleChangePublisher = this.handleChangePublisher.bind(this);
        this.submitLink=this.submitLink.bind(this);
        this.handleChangeMsa=this.handleChangeMsa.bind(this);
        this.handleChangeNda=this.handleChangeNda.bind(this);
        this.handleChangeIo=this.handleChangeIo.bind(this);
        this.handleChangeRmda=this.handleChangeRmda.bind(this);
        this.handleChangeRevenue=this.handleChangeRevenue.bind(this);
        this.handleChangePayment=this.handleChangePayment.bind(this);
        this.handleRemoveLinkage=this.handleRemoveLinkage.bind(this);
        this.handleChangeCurrency=this.handleChangeCurrency.bind(this);
        this.handleChangeTax=this.handleChangeTax.bind(this);
        this.handleChangeInterest=this.handleChangeInterest.bind(this);
        this.qASwitchhandleChange=this.qASwitchhandleChange.bind(this);

        
    }//end of constructor
    qASwitchhandleChange(e){
const{name}=e.target
if(name === "agencyQaRole"){ //Rutuja 4753 changed == to ===
    if(this.state.agencyQaRole ==="OFF"){this.setState({agencyQaRole:"ON",visible:""})} //Rutuja 4753 changed == to ===
    if(this.state.agencyQaRole==="ON"){this.setState({agencyQaRole:"OFF",visible:"drpdisable"})} //Rutuja 4753 changed == to ===
}
else{    
if(name ==="agencyByPass"){ //Rutuja 4753 changed == to ===
    if(this.state.agencyByPass==="OFF"){this.setState({agencyByPass:"ON"})} //Rutuja 4753 changed == to ===
    if(this.state.agencyByPass==="ON"){this.setState({agencyByPass:"OFF"})}//Rutuja 4753 changed == to ===
}
}
    }
    
    
    submitLink(e)
     {
        e.preventDefault();
       
        if(this.validateForm()===false) //Rutuja 4753 changed == to ===
        {  
           return;
        }   
        else{
            // const {user} = this.props.auth;
        let data={
            pubLinkArray:this.state.pIDLinkArray,
            agencyID:this.state.agencyID,
            // user:user,
            msa:this.state.msaValue,
            nda:this.state.ndaValue,
            rmd:this.state.rmdValue,
            io:this.state.ioValue,
            revenueModelID:this.state.revenueValue,
            paymentTerms:this.state.paymentValue,
            currency:this.state.currencyValue,
            tax:this.state.taxValue,
            interest:this.state.interestValue,
            agencyQaRole:this.state.agencyQaRole,
            agencyByPass:this.state.agencyByPass
            }

            this.setState({
                buttonDisplay: "disabled btn add-button", //rutuja-task-4325 disabled button still popup shows 
              });
      fetch("/admin/agencyPublisherLinking", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json()).then(response=> { 

         if(response.success===true)
         {
          Swal.fire({
              
            text: ("Agency Linking with publisher Successfully"),
            type: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
           preConfirm:()=>
           {
            window.location.href = '/linkPublisher';
           }
          })
         }else{
          Swal.fire({
              
            text: ("Agency Linking with publisher Failed"),
            type: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            preConfirm:()=>
           {
            window.location.href = '/linkPublisher';
           }
          })
         }
        
          
            }).catch(function (err) {
              console.log(err)
            });

     
    }
    }

   

     handleChangeMsa(e)
     {
        var msaValue=e.target.value;
        this.setState({msaValue:msaValue});
     }
     handleChangeNda(e)
     {
      var ndaValue=e.target.value;
      this.setState({ndaValue:ndaValue});
            let fields = this.state;
            fields[e.target.name] = e.target.value;
            if(ndaValue==='Yes') //Rutuja 4753 changed == to ===
            {
                ndaValidate=true;
            }
            else
            {
                ndaValidate=false;
            }
     }

     handleChangeRmda(e)
     {
      var rmdValue=e.target.value;
      this.setState({rmdValue:rmdValue});
        let fields = this.state;
            fields[e.target.name] = e.target.value;
            if(rmdValue==='Yes') //Rutuja 4753 changed == to ===
            {
                rmdValidate=true;
            }
            else
            {
                rmdValidate=false;
            }
     }

     handleChangeIo(e)
     {
      var ioValue=e.target.value;
      this.setState({ioValue:ioValue});
      let fields = this.state;
      fields[e.target.name] = e.target.value;
      if(ioValue==='Yes') //Rutuja 4753 changed == to ===
      {
        ioValidate=true;
      }
      else
      {
          ioValidate=false;
      }
     }


    handleChangeRevenue(e){
        var revenueValue=e.target.value;
        this.setState({revenueValue:revenueValue});
    }

    handleChangeTax(e){
        var taxValue=e.target.value;
        this.setState({taxValue:taxValue});
    }

    handleChangeInterest(e){
        var interestValue=e.target.value;
        this.setState({interestValue:interestValue});
    }

    handleChangePayment(e){
        var paymentValue=e.target.value;
        this.setState({paymentValue:paymentValue});
    }

    handleChangeCurrency(e){
        var currencyValue=e.target.value;
        this.setState({currencyValue:currencyValue});
    }

    handleChangeAgency(e)
    {
    
        var agencyID= e.target.value;

        this.setState({agencyID:agencyID});
        fetch("admin/agencyPublisherLinkedDetails?agencyID='"+agencyID+"'")
        .then(res => res.json())
        .then(agencyPubArray =>{
            
          this.setState({ agencyPubArray: agencyPubArray})
          
          }).catch(function (err) {
          console.log(err)
        });

        let data={
            agencyID:agencyID,
            }
           
    
      fetch("/admin/reviewedPublisherLinking", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json()).then(publisherArray=> { 
        this.setState({publisherArray:publisherArray})
        if(publisherArray.length>0)
        {
            var newPublisherName=[];
            for(var j=0;j<publisherArray.length;j++)
            {
            newPublisherName.push({
                id: publisherArray[j].pID,
                name:publisherArray[j].publisherName,
             })
             }
             this.setState({PublisherName:newPublisherName})
        }
          
            }).catch(function (err) {
              console.log(err)
            });
      

    }
    

    handleChangePublisher(e) {
        
        var pubLinkArray=[];
        var pIDLinkArray=[];
        var pID= e.target.value;
        this.setState({pID:pID});
        fetch("admin/agencyPublisherLinkedDetailsPublisher?pID='"+pID+"'")  // rutuja - 4363 changed the route name (Agency linking with publisher--routes issue)
        .then(res => res.json())
        .then(pubLinkArray =>{
           
          this.setState({ pubLinkArray: pubLinkArray})
          
          }).catch(function (err) {
          console.log(err)
        });
        pIDLinkArray.push({'pID':pID});
        this.setState({pubLinkArray:pubLinkArray,pIDLinkArray:pIDLinkArray});

        // console.log(`Option selected:`, value);
       
        // const name="publisher";
        // var pubLinkArray=[];
        // var pIDLinkArray=[];
        // for(var j=0;j<value.length;j++)
        // {
        //  pubLinkArray.push(value[j].name+"|");  
        //  var pID=value[j].id;
        //  pIDLinkArray.push({'pID':pID});
        //  }
        //  //this.setState({pubLinkArray:pubLinkArray});
        // this.setState({pubLinkArray:pubLinkArray,selectedOption4:value,pubArray:value,pIDLinkArray:pIDLinkArray});

    }

    validateForm()
    {
           let errors={};
           let formIsValid=true;
           let agencyValidate=true;
           let pubValidate=true;
           let ndaValidateValue=true;
           let rmdValidateValue=true;
           let ioValidateValue=true;
           let revenueValidateValue=true;
           let paymentValidateValue=true;
           let taxValidateValue=true;
           let interestValidateValue=true;
           let invoiceCurrencyValidateValue=true;
          
           if(this.state.agencyID===undefined||this.state.agencyID===""||this.state.agencyID==null)//Rutuja 4753 changed == to ===
           {
               agencyValidate=false;
               this.setState({
                   inputclassAgency:'invalid'
               })
               
           }
           else
           {
               agencyValidate=true;
               this.setState({
                inputclassAgency:'valid'
            })
           }


           if(this.state.pID===undefined||this.state.pID===""||this.state.pID==null) //Rutuja 4753 changed == to ===
           {
                pubValidate=false;
                this.setState({
                    //inputclassPub:'invalid'
                })
           }
           else
           {
            pubValidate=true;
            this.setState({
                inputclassPub:'valid'
            })
           }


           
           
           if(ndaValidate===false)//Rutuja 4753 changed == to ===
           {
              ndaValidateValue=false;
              this.setState({
                //inputclassnda:'invalid'
            })
           }
           else
           {
             ndaValidateValue=true;
             this.setState({
                inputclassnda:'valid'
            })
           }


           if(ioValidate===false) //Rutuja 4753 changed == to ===
           {
              ioValidateValue=false;
              this.setState({
                //inputclassio:'invalid'
            })
           }
           else
           {
             ioValidateValue=true;
             this.setState({
                inputclassio:'valid'
            })
           }



           if(rmdValidate===false) //Rutuja 4753 changed == to ===
           {
              rmdValidateValue=false;
              this.setState({
               // inputclassrmd:'invalid'
            })
           }
           else
           {
             rmdValidateValue=true;
             this.setState({
                inputclassrmd:'valid'
            })
           }
           
           
           if(this.state.revenueValue===""||this.state.revenueValue==null||this.state.revenueValue===undefined||this.state.revenueValue==="none")//Rutuja 4753 changed == to ===
           {
               revenueValidateValue=false;
               this.setState({
               // inputclassrevenue:'invalid'
            })
           }
           else
           {
               revenueValidateValue=true;
               this.setState({
                inputclassrevenue:'valid'
            })
           }

           if(this.state.paymentValue===""||this.state.paymentValue==null||this.state.paymentValue===undefined)//Rutuja 4753 changed == to ===
           {
               paymentValidateValue=false;
               this.setState({
                //inputclasspayment:'invalid'
            })
           }else
           {
                paymentValidateValue=true;
                this.setState({
                    inputclasspayment:'valid'
                })
           }




           if(this.state.taxValue===""||this.state.taxValue==null||this.state.taxValue===undefined) //Rutuja 4753 changed == to ===
           {
               taxValidateValue=false;
               this.setState({
                inputclasstax:'invalid'
            })
           }else
           {


            var pattern = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/);
            if(!pattern.test(this.state.taxValue))
            {
                taxValidateValue=false;
                errors["tax"]=<li>Please Enter Numeric Value For Tax</li>
                this.setState({errors:errors,
              alertBorder:'1px solid red',
               alertDisplay:'block'})
            }
            else
            {
                if(this.state.taxValue<=99.99)
                {
                taxValidateValue=true;
                errors["tax"]=""
                this.setState({errors:errors,
              alertBorder:'0px solid red',
               alertDisplay:'block',
               inputclasstax:'valid'
            
            })

        }   
        else
        {
            taxValidateValue=false;
            errors["tax"]=<li>Please Enter Tax Value Smaller than 100%</li>
            this.setState({errors:errors,
          alertBorder:'1px solid red',
           alertDisplay:'block'})
        }


            }


        }

           if(this.state.currencyValue===""||this.state.currencyValue==null||this.state.currencyValue===undefined)//Rutuja 4753 changed == to ===
           {
            invoiceCurrencyValidateValue=false;
               this.setState({
                inputclassinvoice:'invalid'
            })
           }else
           {
            invoiceCurrencyValidateValue=true;
                this.setState({
                    inputclassinvoice:'valid'
                })
           }


           if(this.state.interestValue===""||this.state.interestValue==null||this.state.interestValue===undefined) //Rutuja 4753 changed == to ===
           {
            interestValidateValue=false;
               this.setState({
                inputclassinterest:'invalid'
            })
           }else
           {


            let pattern = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/); //Rutuja 4753 changed var to let
            if(!pattern.test(this.state.interestValue))
            {
                interestValidateValue=false;
                errors["interest"]=<li>Please Enter Numeric Value For Interest</li>
                this.setState({errors:errors,
              alertBorder:'1px solid red',
               alertDisplay:'block',
               inputclassinterest:'inValid'
            })
            }
            else
            {
                interestValidateValue=true;
                errors["interest"]=""
                this.setState({errors:errors,
              alertBorder:'0px solid red',
               alertDisplay:'block',
               inputclassinterest:'valid'
            
            })
            }






            
                      }

          

          // alert("Values===>"+this.state.taxValue+"===="+this.state.interestValue+"==="+this.state.currencyValue);
           if(agencyValidate===false||pubValidate===false||ndaValidateValue===false||rmdValidateValue===false||ioValidateValue===false
            ||revenueValidateValue===false||paymentValidateValue===false||taxValidateValue===false||interestValidateValue===false||invoiceCurrencyValidateValue===false) //Rutuja 4753 changed == to ===
           {
            formIsValid=false;
               errors["common"]=<li>Please fill below required fields</li>
               this.setState({errors:errors,
             alertBorder:'1px solid red',
              alertDisplay:'block'})
         
           }
           else
           {
            formIsValid=true
            errors["common"]="";
               this.setState({errors:errors,
             alertBorder:'0px solid white',
              alertDisplay:'none'})
         
           }

           return formIsValid;
    }

   
    handleRemoveLinkage(e)
    {
        e.preventDefault(); //Rutuja Task 4753 console warrning
        var agencyID=e.target.id;
        var pID=e.target.getAttribute('name');

        
        let data={
            agencyID:agencyID,
            pID:pID
        }
        
        fetch("/admin/removeAgencyPublisherLinkage", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
          }).then(res => res.json())
          .then(response=> { 

            Swal.fire({
              
                text: (" Removed Agency Linking with publisher"),
                type: 'success',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
               preConfirm:()=>
               {
                window.location.href = '/linkPublisher';
               }
              })

          })
    }
    

    componentDidMount()
    {
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
          }
          else{          
                fetch("admin/reviewedPublisherDetails")
                  .then(res => res.json())
                  .then(publisherArray =>{
                    this.setState({ publisherArray: publisherArray})
                for(var i=0;i<publisherArray.length;i++)
                {
                    this.state.PublisherName.push({
                    id: publisherArray[i].pID,
                    name:publisherArray[i].publisherName,
                 })
                }
                 }).catch(function (err) {
                    console.log(err)
                  });

                  fetch("admin/reviewedAgencyDetails")
                  .then(res => res.json())
                  .then(agencyArray =>{
                    this.setState({ agencyArray: agencyArray})
                    }).catch(function (err) {
                    console.log(err)
                    
                  });


                  fetch("admin/revenueModelDetails")
                  .then(res => res.json())
                  .then(revenueArray =>{
                    this.setState({ revenueArray: revenueArray})
                     
                    }).catch(function (err) {
                    console.log(err)
                    
                  });
                
                }
            }

    /*@author Sonali Kalke
   * Desc getting Records of publishers having status verification pending in table format
  */

   
    render()
    {
        return(
            <div>
                <AdminNavpage/> 
                    <div  class="container-fluid" style={{paddingTop:'90px'}}>
                       <div class="row">
                       <div class="col-lg-4" style={{paddingLeft:'20px'}}>
                       <a href="./adminDashboard" onClick={this.handleBackButtonTodashBoard}  style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Dashboard"/></a>
                       </div>

                       <div class="col-lg-8">
                           {/* rutuja task -4325 made the header responsive */}
                        <label style={{fontSize:"20px",fontWeight:"bold",color:"#056eb8" ,marginLeft:"50px"}}> Publisher linking with Agency</label>
                       </div>

                       <div class=" col-md-4 col-sm-4">
                           
                       </div>
                       </div>
                    <br/>

                    <div>
                        <div class="col-lg-12"  style={{ border: this.state.alertBorder, display: this.state.alertDisplay }}>
                            <div class="errorMessage">{this.state.errors.common}
                            {this.state.errors.tax}
                            {this.state.errors.interest}
                            
                            </div>
                        </div>
                    </div>
                    <br/>
                    

                        <div class="row" style={{paddingTop:'10px'}}>
                            <div class="col-lg-3  col-md-4">
                                <div>
                                <label id="label">Agency <span style={{color:'red'}}>*</span></label>
                                </div>
                                <div>
                                <select id="agency"  style={{border:'1px solid #ccc',
                                boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'230px',height:'calc(2.25rem + 12px)', borderRadius:'3px'}}name="agency" 
                                value={this.state.agency}
                                onChange={this.handleChangeAgency} 
                                class="form-control"  className={this.state.inputclassAgency}//rutuja 4753 changed className to class
                                 
                                >
                                    <option selected>Select One</option>
                                  {this.state.agencyArray.map(agencyArray => (
                                <option value={agencyArray.agencyID}>{agencyArray.agencyName}</option>
                                  ))}
                                </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4">
                                    <label id="label">Publisher <span style={{color:'red'}}>*</span></label>
                                    <select id="publisher"  style={{border:'1px solid #ccc',
                                    boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)', height:'calc(2.25rem + 12px)'}}name="publisher" 
                                    value={this.state.publisher}
                                    onChange={this.handleChangePublisher} class="form-control">
                                        <option selected>Select One</option>
                                  {this.state.publisherArray.map(publisherArray => (
                                    <option value={publisherArray.pID}>{publisherArray.publisherName}</option>
                                  ))}
                                    </select>
                                     {/* <Picky
                                         value={this.state.pubArray}
                                        options={this.state.PublisherName}
                                          onChange={this.handleChangePublisher}
                                          className={this.state.inputclassPub}
                                        open={false}
                                        valueKey="id"
                                        labelKey="name"
                                        multiple={true}
                                        includeSelectAll={true}
                                        includeFilter={true}
                                        dropdownHeight={200}
                                        id="publisher" name="publisher"
                                        /> */}

                            </div>
                           
                            </div>{/* //close of row */}
                            <br/>

                    <div class="row">
                        <div class="col-lg-3">
                            <label id="label" style={{overflow:'visible'}}>Master service agreement signed :</label>
                        </div>
                        <div class="col-lg-3">
                            <select id="msa" name="msa" value={this.state.msa}  
                            style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'90px', height:'calc(2.25rem + 12px)'}}
                                onChange={this.handleChangeMsa} class="form-control"
                                >
                                <option value="No" selected>No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>

                        <div class="col-lg-3">
                            <label id="label" style={{overflow:'visible'}}>Non-Disclosure agreement signed :<span style={{color:'red'}}>*</span></label>
                        </div>
                        <div class="col-lg-3">
                            <select id="nda" name="nda" value={this.state.nda} 
                             className={this.state.inputclassnda}
                            style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'90px', height:'calc(2.25rem + 12px)'}}
                                onChange={this.handleChangeNda} class="form-control">
                                <option value="No" selected>No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                        </div>
<br/>
                    <div class="row">
                        <div class="col-lg-3">
                            <label id="label" style={{overflow:'visible'}}>Revenue model defined/agreed :<span style={{color:'red'}}>*</span></label>
                        </div>
                        <div class="col-lg-3">
                            <select id="rmda" name="rmda" value={this.state.rmda}
                            className={this.state.inputclassrmd}
                            style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'90px', height:'calc(2.25rem + 12px)'}}
                                onChange={this.handleChangeRmda} class="form-control">
                                <option value="No" selected>No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                        

                        <div class="col-lg-3">
                            <label id="label" >Insertion order required for each campaign:<span style={{color:'red'}}>*</span></label>
                        </div>
                        <div class="col-lg-3">
                            <select id="io" name="io" value={this.state.io}  className={this.state.inputclassio}
                            style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'90px', height:'calc(2.25rem + 12px)'}}
                                onChange={this.handleChangeIo} class="form-control">
                                <option value="No" selected>No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                        

                        
                    </div>
                    <br/>

                    <div class="row">
                        <div class="col-lg-3">
                             <label id="label">Revenue model <span style={{color:'red'}}>*</span></label>
                        </div>
                        <div class="col-lg-3">
                            <select id="revenue" name="revenue" class="form-control" value={this.state.revenue}
                            className={this.state.inputclassrevenue}
                             onChange={this.handleChangeRevenue} style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'90px', height:'calc(2.25rem + 12px)'}}>
                                <option value="none">None</option>
                                {this.state.revenueArray.map(revenueArray => (
                                    <option value={revenueArray.revenueModelID}>{revenueArray.revenue_model}</option>
                                  ))}
                            </select>
                        </div>


                        <div class="col-lg-3">
                             <label id="label" >Payment terms (Payment schedule terms after invoice generation)<span style={{color:'red'}}>*</span></label>
                        </div>
                        <div class="col-lg-3">
                            <select id="payment" name="payment" class="form-control" value={this.state.payment}
                             className={this.state.inputclasspayment}
                             onChange={this.handleChangePayment} style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'90px', height:'calc(2.25rem + 12px)'}}>
                                <option value="none">None</option>
                                <option value="30">30 days</option>
                                <option value="45">45 days</option>
                                <option value="60">60 days</option>
                                <option value="90">90 days</option>
                            </select>
                        </div>

                        
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col-lg-3">
                             <label id="label">Tax(%) <span style={{color:'red'}}>*</span></label>
                        </div>
                        <div class="col-lg-3">
                            {/* //className={this.state.inputclassrevenue} */}
                             
                               <input type="text" id="tax" name="tax"   className={this.state.inputclasstax} 
                               style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'90px', height:'calc(2.25rem + 12px)'}} onChange={this.handleChangeTax} ></input>
                        </div>

                      

                        <div class="col-lg-3">
                             <label id="label">Invoice Currency<span style={{color:'red'}}>*</span></label>
                        </div>
                        <div class="col-lg-3">
                            <select id="currency" name="currency" class="form-control" value={this.state.currency}
                             className={this.state.inputclassinvoice}
                             onChange={this.handleChangeCurrency} style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'210px', height:'calc(2.25rem + 12px)'}}>
                                <option value="USD">USD - US Dollar</option>
                                <option value="EUR">EUR - Euro</option>
                                <option value="GBP">GBP - British Pound</option>
                                <option value="INR">INR - Indian Rupee</option>
                                <option value="AUD">AUD - Australian Dollar</option>
                                <option value="CAD">CAD - Canadian Dollar</option>
                                <option value="SGD">SGD - Singapore Dollar</option>
                                <option value="CHF">CHF - Swiss Franc</option>
                                <option value="MYR">MYR - Malaysian Ringgit</option>
                                <option value="JPY">JPY - Japanese Yen</option>
                                <option value="CNY">CNY - Chinese Yuan Renminbi</option>


                            </select>
                        </div>

                      
                        </div>
                        <br/>  <br/>
                        <div class="row">
                        <div class="col-lg-3">
                             <label id="label">DI QA Role</label>
                        </div>
                        <div class="col-lg-3">
                            <select class="form-control" style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'90px', height:'calc(2.25rem + 12px)'}} value={this.state.agencyQaRole} name="agencyQaRole" onChange={this.qASwitchhandleChange}> 
                                <option>ON</option>
                                <option selected="true">OFF</option>
                            </select>
                        </div>


                        <div class="col-lg-3">
                             <label id="label" className={" "+this.state.visible}>Agency QA bypass</label>
                        </div>
                        <div class="col-lg-3">
                             {/* //Rutuja 4753 console warrning changed "form-control"+"" + this.state.visible and used below*/}
                            <select class={"form-control" + this.state.visible} style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'90px', height:'calc(2.25rem + 12px)'}} value={this.state.agencyByPass} name="agencyByPass" onChange={this.qASwitchhandleChange}>
                                <option selected="true">ON</option>
                                <option>OFF</option>
                            </select>
                        </div>
                        </div>
                        <br/>
                        <br/>
                        <div class="row">
                        <div class="col-lg-3">
                             <label id="label">Interest <span style={{color:'red'}}>*</span></label>
                        </div>
                        <div class="col-lg-3">
                           
                             
                               <input type="text" id="interest" name="Interest"   className={this.state.inputclassinterest} style={{border:'1px solid #ccc',borderRadius:'2px',borderColor:'#eee',
                            boxShadow:'inset 0 1px 1px rgba(0,0,0,.075)',width:'90px', height:'calc(2.25rem + 12px)'}} onChange={this.handleChangeInterest} ></input>
                        </div>
                        <div class="col-lg-3">
                                <label id="label"></label>
                                <br/>
                                {/* rutuja-task-4325 disabled button still popup shows */}
                                    <button type="button" onClick={this.submitLink}  style={{marginTop:"-10px",marginBottom:"70px"}} class="btn add-button"  className={this.state.buttonDisplay} >Submit</button>  
                                    
                            </div>
                            </div>
                    {/* // end of row */}

                    <div class="" style={{textAlign:'center',fontSize:"20px"}}>
                       <label  id="labelheading">List of linked Publisher</label> 
                    </div>

                     <div class="container-fluid" style={{paddingBottom:'60px'}}>
                        <div class="row">
                            {/* rutuja task - 4325 made changes to make table responsive */}
                        <div class="table-responsive">
                        <table className=" table table-bordered table-striped ">
                        <thead>
                          <tr>
                              <th class="table-header">Agency Name</th>
                              <th class="table-header">Publisher Name</th>
                              <th class="table-header">Status</th>
                              <th class="table-header">Action</th>
                              
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.agencyPubArray.map(agencyPubArray => (
                        <tr className="tr1">
                        
                       
                                <td style={{textAlign:'left'}}>{agencyPubArray.agencyName}</td>
                                <td style={{textAlign:'left'}}>{agencyPubArray.publisherName}</td>
                                <td style={{textAlign:'left'}}>
                                {agencyPubArray.agencyStatus==='Pending' && agencyPubArray.publisherStatus==='Pending'?'agencyApprovalPending/publisherApprovalPending':
                                agencyPubArray.agencyStatus==='Pending' && agencyPubArray.publisherStatus==='Approved'?'agencyApprovalPending/publisherApproved':
                                agencyPubArray.publisherStatus==='Pending' && agencyPubArray.agencyStatus==='Approved'?'agencyApproved/publisherApprovalPending':
                                agencyPubArray.publisherStatus==='Remove' && agencyPubArray.agencyStatus==='Remove'?'':

                                'agencyApproved/publisherApproved'
                            }
                                </td>
                                <td><div class="tip">
                                 <a
                                 href="#/" //Rutuja Task 4753 console warrning 
                                    
                                 id={agencyPubArray.agencyID}
                                 name={agencyPubArray.pID}
                                 onClick={this.handleRemoveLinkage}
                                 style={{color:'#2196f3'}}>
                                Remove Linkage  
                                 </a>
                                 
                                  </div>
                                  </td>
                            </tr>
                             ))}
                        </tbody>
                        </table>
                           </div> {/* //close of table row */}
                           </div>
                           
                            </div>
                        
                     </div>{/* //close of conatiner */}
                <Footer/>
            </div>
            
        )
    }

}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
     LinkPublisher.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
 export default connect(mapStateToProps, { logoutUser })(withRouter(LinkPublisher)); 