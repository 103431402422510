/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Achal Somani
 *@fileName :AddUserAdmin.js
 *Desc: Add User
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import AdminNavpage from "../layouts/adminNavpage";
import { Form, Input, Tooltip, Icon } from "antd";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
const Swal = require("sweetalert2");

class AddUserAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inputClass11: "btn add-button",
			fields: {},
			errors: "",
			alertBorder: "",
			alertDisplay: "none",
			userDomain: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.submitUser = this.submitUser.bind(this);
	} // end of constructor
	componentDidMount() {
		const { isAuthenticated, user } = this.props.auth;
		var userName = user.name;
		userName = userName.split("@");
		var userName1 = "@" + userName[1];
		this.setState({ userDomain: userName1 });
		//alert(userName[1]);
	}

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
		let spanDisplay = "none";
		let requiredFeildValidate = "true";
		let userNameValidate = "true";
		let firstNameValidate = "true";
		let lastNameValidate = "true";
		let roleValidate = "true";
		//alert(fields["userName"]);
		fields["userName"] = fields["userName"] + this.state.userDomain;
		this.setState({ fields });
		//alert("after"+fields["userName"])
		if (
			!fields["userName"] &&
			!fields["role"] &&
			!fields["firstName"] &&
			!fields["lastName"]
		) {
			//alert("Feild unavailable");
			requiredFeildValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({ errors: errors });
		} else {
			if (
				this.state.userName == "" ||
				this.state.userName == null ||
				this.state.userName == undefined
			) {
				userNameValidate = false;
				//errors["userName"] = <li>Please enter user name</li>;
				errors["userName"] = <li>Please enter user name</li>;
				this.setState({ errors: errors });
			} else {
				var pattern = new RegExp(
					/^([a-zA-Z]+[\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$/
				);
				//var pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/s);
				//var pattern = new RegExp(/^\s*$|^[a-zA-Z]+[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/);
				if (!pattern.test(fields["userName"])) {
					userNameValidate = false;
					errors["userName"] = <li>Please enter valid user name</li>;
					this.setState({ errors: errors });
				} else {
					errors["userName"] = "";
					this.setState({ errors: errors });
				}
			}
			if (
				this.state.firstName == "" ||
				this.state.firstName == null ||
				this.state.firstName == undefined
			) {
				firstNameValidate = false;
				errors["firstName"] = <li>Please enter first name</li>;
				this.setState({ errors: errors });
			} else {
				var pattern = new RegExp(/^[a-zA-Z]+$/);
				if (!pattern.test(fields["firstName"])) {
					userNameValidate = false;
					errors["firstName"] = <li>please enter valid first name</li>;
					this.setState({ errors: errors });
				} else {
					errors["firstName"] = "";
					this.setState({ errors: errors });
				}
			}
			if (
				this.state.lastName == "" ||
				this.state.lastName == null ||
				this.state.lastName == undefined
			) {
				lastNameValidate = false;
				errors["lastName"] = <li>Please enter last name</li>;
				this.setState({ errors: errors });
			} else {
				var pattern = new RegExp(/^[a-zA-Z]+$/);
				if (!pattern.test(fields["lastName"])) {
					userNameValidate = false;
					errors["lastName"] = <li>please enter valid last name</li>;
					this.setState({ errors: errors });
				} else {
					errors["lastName"] = "";
					this.setState({ errors: errors });
				}
			}
			if (
				this.state.role == "" ||
				this.state.role == null ||
				this.state.role == undefined
			) {
				roleValidate = false;
				errors["role"] = <li>Please enter role</li>;
				this.setState({ errors: errors });
			} else {
				errors["role"] = "";
				this.setState({ errors: errors });
			}
		}
		//alert(requiredFeildValidate+""+userNameValidate+""+firstNameValidate+""+lastNameValidate+""+roleValidate);
		if (
			requiredFeildValidate == false ||
			userNameValidate == false ||
			firstNameValidate == false ||
			lastNameValidate == false ||
			roleValidate == false
		) {
			formIsValid = false;
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		} else {
			formIsValid = true;
		}
		return formIsValid;
	}

	handleChange(e) {
		let fields = this.state;

		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	}

	submitUser(e) {
		const { isAuthenticated, user } = this.props.auth;
		let data = {
			userName: this.state.userName + this.state.userDomain,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			role: this.state.role,
		}; // karan-task-3723-vapt header and query params
		//alert(JSON.stringify(this.state.userName));
		if (this.validateForm() === false) {
			//alert("validateForm error");
			return;
		} else {
			let errors = {};
			fetch("/users/addUserDetails", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((userDetails) => {
					if (userDetails.length > 0) {
						// alert("User already exists");
						errors["sameUser"] = <li>User Already Exists</li>;
						this.setState({ errors: errors });
					} else {
						fetch("/users/addUser", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						})
							.then(function (response) {
								if (response.status >= 400) {
									throw new Error("Bad response from server");
								}
								return response.json();
							})
							.then(function (response) {
								// success message with response
								if (response.success == true) {
									Swal.fire({
										text: "User Added Successfully",
										type: "success",
										confirmButtonText: "Ok",
										allowOutsideClick: false,
										preConfirm: () => {
											window.location.href =
												"/adminDashboard?adminID=" + user.id;
										},
									});
								} else {
								}
							});
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	render() {
		const asterisk = {
			color: "red",
		};
		return (
			<div>
				<AdminNavpage />
				<div class="container-fluid" style={{ paddingTop: "100px" }}>
					<div class="card-body">
						<legend className="legend labelsubheading">User Details</legend>

						<div style={{ fontSize: "14px", color: "red" }}>
							{this.state.errors.commonError}
						</div>
						<div
							className="errorMsg"
							style={{ fontSize: "14px", color: "red" }}>
							{this.state.errors.userName}
							{this.state.errors.role}
							{this.state.errors.firstName}
							{this.state.errors.lastName}
							{this.state.errors.sameUser}
						</div>

						<div class="row">
							{/* <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4" >
                                <label id="label">User Name <span style={asterisk}>*</span></label>
                                <input
                                    value={this.state.userName}
                                    onChange={this.handleChange}
                                    type="text"
                                    id="userName"
                                    className="form-control"
                                    name="userName"
                                    style={{ width: "60%", height: "60%",padding:"1px" }}
                                    required />
                            </div> */}
							<div class="col-xs-12 col-sm-12 col-md-2 col-lg-4">
								<label id="label">
									User Name{" "}
									<span style={asterisk}>
										*&nbsp;
										<Tooltip title=" User name should be Valid Email Id.">
											<Icon type="question-circle-o" />
										</Tooltip>
									</span>
								</label>
								<br />
								<Input
									addonAfter={this.state.userDomain}
									value={this.state.userName}
									onChange={this.handleChange}
									type="text"
									id="userName"
									className="form-control"
									name="userName"
									style={{ width: "60%", height: "60%", padding: "1px" }}
									required></Input>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-2 col-lg-4">
								<label id="label">
									Role <span style={asterisk}>*</span>
								</label>
								<select
									value={this.state.role}
									onChange={this.handleChange}
									type="text"
									id="role"
									className="form-control"
									name="role"
									style={{ width: "60%", height: "60%" }}
									required>
									<option value="None Selected" disabled selected>
										None Selected
									</option>
									<option value="Admin">Admin</option>
								</select>
							</div>
						</div>
						<br />
						{/* end of 1st row */}
						<div class="row">
							<div class="col-xs-12 col-sm-12 col-md-2 col-lg-4">
								<label id="label">
									First Name <span style={asterisk}>*</span>
								</label>
								<input
									value={this.state.firstName}
									onChange={this.handleChange}
									type="text"
									id="firstName"
									className="form-control"
									name="firstName"
									style={{ width: "60%", height: "60%" }}
									required
								/>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-2 col-lg-4">
								<label id="label">
									Last Name <span style={asterisk}>*</span>
								</label>
								<input
									value={this.state.lastName}
									onChange={this.handleChange}
									type="text"
									id="lastName"
									className="form-control"
									name="lastName"
									style={{ width: "60%", height: "60%" }}
									required
								/>
							</div>
						</div>
						<br />
						{/* end of 2nd row */}
						<button
							class="btn add-button"
							style={{ float: "left" }}
							className={this.state.inputClass11}
							onClick={this.submitUser}
							type="submit">
							Save{" "}
						</button>
					</div>
					{/* end of card body */}
				</div>
				{/* end of container-fluid */}

				<Footer />
			</div>
		);
	} // end of render
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AddUserAdmin.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(AddUserAdmin)
);
