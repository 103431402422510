/* Copyright(c) 2022 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:kiran-4173
@Creation Date:26-06-2022
@Description:UI for Creative review
*/

import React, { Component } from "react";
import * as $ from "jquery";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
// import { Button, Icon, Tooltip, Input } from "antd"; //Nilesh-4242-Removing console warnings
// import { Input } from "antd"; //Nilesh-4242-Removing console warnings
import PublisherNavigation from "../layouts/publisherNavPage";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //sonali-task-3945-added default header
import "./publisherCreativesReview.css";	//chaitanya-task-4107-Publisherside- Review - Creatives review
// const queryString = require("query-string"); //Nilesh-4242-Removing console warnings

var Filecs = false;
var Filepoc = false;
var result = [];
var displayresult = [];
var uploadCS = false;
var uploadCSBE = true;
var uploadPOC = false;
var uploadPOCBE = true;
var lpsubmitLink = false;
const Swal = require("sweetalert2");
class PublisherCreativesReview extends Component {
	constructor() {
		super();
		this.state = {
			pID: "",
			tableStatus: "true",
			alertDisplay: "none",
			pid: "",
			PocFilename: "",
			CallscriptFilename: "",
			POCSubmissionPopUP: [],
			lpSubmissionData: [],
			LPLInk: "",
			LP_POC_CS_SubmissionPopUP: [],
			newDynamicArray: [],
			pocArray: [],
			csArray: [],
			fields: {},
			minerror: {},
			newsuccess: "",
			cserror: "",
			pocerror: "",
			campaignDetails: [],
			marketingChannel: "",
			failure: "",
			parentCampID: "",
			reallocationID: "",
			lpExt: "",
			lpFilename: "",
			pocExt: "",
			pocFileFeedbackName: "",
			csExt: "",
			csFileFeedbackName: "",
			downloadLPFiles: [],
			downloadPOCFeedbackFiles: [],
			downloadCSFeedbackFiles: [],
			alertBorder: "",
			Step: "", //if visits the page from dashboard then will be back redirected to dashboard
			creativeCampList: [], 
			searchData: ``, 
			listCampID: [], 
			ListForLPReview: [], 
			lpReviewPendingListCampIDOnNextPage: [],
			lpReviewPendingList:[] 
		
		};

		this.handleChangeDynamicLpInsert =
		this.handleChangeDynamicLpInsert.bind(this);
		this.submitLandingPage1 = this.submitLandingPage1.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.deletePOCFile = this.deletePOCFile.bind(this);
		this.deleteCSFile = this.deleteCSFile.bind(this);
		this.downloadLPFeedbackFile = this.downloadLPFeedbackFile.bind(this);
		this.downloadPOCFeedbackFile = this.downloadPOCFeedbackFile.bind(this);
		this.downloadCSFeedbackFile = this.downloadCSFeedbackFile.bind(this);
		this.apiCall = this.apiCall.bind(this);
		this.handleCampID = this.handleCampID.bind(this);
	}

	handleChangeUploadPOCFile(i, e) {
		e.preventDefault();
			// var campID=this.state.campID; //Nilesh-4242-Removing console warnings
			// var pID=this.state.pID;
		
		// var pID = this.props.location.state.pID; //Nilesh-4242-Removing console warnings
		var campID = this.props.location.state.campID;
		var supportDocID = e.target.id;
		if (
			document.getElementById("fileName" + supportDocID) != null ||
			document.getElementById("fileName" + supportDocID) !== undefined //Nilesh-4242-Removing console warnings
		) {
			document.getElementById("fileName" + supportDocID).innerHTML =
				""; /**** this is making empty for hide the message No File Chosen because of in dynamic delete function it assign value No File Chosen */
		}
		const { name, value } = e.target;
		let pocArray = [...this.state.pocArray];
		pocArray[i] = { ...pocArray[i], [name]: value };
		this.setState({ pocArray });
		var names = [];
		let filesData = e.target.files;
		if (filesData.length > 0) {
			var filePoc = filesData[0].name;
			this.setState({ filePoc: filePoc });
			let file = e.target.files[0];
			console.warn("data files", filesData);
			var FileExt;
			for (let i = 0, l = filesData.length; i < l; i++) { //Nilesh-4242-Removing console warnings
				names[i] = filesData[i].name;
				FileExt = names[i].split(".").pop();
			}

			
			let pocerror = {};
			var link;
			var filename = "";
			var arrayLength = names.length;
			var nooutput = document.getElementById("pocList" + supportDocID);

			if (arrayLength > 0) {
				var allowedFiles = [".ppt", ".doc", ".pdf", ".docx", ".pptx"];
				//Nilesh-4242-Removing console warnings
				if (
					FileExt === "doc" ||
					FileExt === "ppt" ||
					FileExt === "pdf" ||
					FileExt === "docx" ||
					FileExt === "pptx" ||
					FileExt === "DOC" ||
					FileExt === "PPT" ||
					FileExt === "PDF" ||
					FileExt === "DOCX" ||
					FileExt === "PPTX"
				) {
					Filepoc = true;
					nooutput.innerHTML = arrayLength + "  File Selected";
					var HTML = "<table>";

					for (var j = 0; j < arrayLength; j++) {
						link =
							'<a class="removeFilePOC" href="#" data-fileid=' +
							'"' +
							names[j] +
							'"' +
							'><i class="fa fa-times"></i></a>';

						HTML +=
							"<tr><td>" +
							link +
							"&nbsp;&nbsp;&nbsp;&nbsp;" +
							names[j] +
							"</td></tr>";
					}
					HTML += "</table>";
					document.getElementById("abmList" + supportDocID).innerHTML = HTML;
					$(document).on("click", ".removeFilePOC", function (e) {
						e.preventDefault();
						var clickedFile = $(this).parent().children("a").data("fileid");
						var j = 0;
						for (j; j < names.length; j++) {
							var temp = j;

							if (names[temp] === clickedFile) {
								filename = names[temp];
								names.splice(j, 1);
								$(this).parent().remove();
								arrayLength = names.length;

								/**
								 * @author Narendra Phadke
								 * @param  Delete File From Database
								 */
								// var typeOfSuppDoc = "ABM";  //Nilesh-4242-Removing console warnings
								let data = {
									campID: this.props.location.state.campID,
									supportDocID: supportDocID,
									//pID: pID, //sonali-3945-removing pID 
								};

								document.getElementById(supportDocID).value = "";
								fetch("publisher/pocDelete", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								}).catch(function (err) {
									console.log(err);
								});

								if (arrayLength > 0) {
									nooutput.innerHTML = arrayLength + "  File Selected";
								} else {
									nooutput.innerHTML = " No File Chosen";
									$("#abm1").val("");
									var message = document.getElementById(
										"pocFileMessage" + supportDocID
									);
									message.innerHTML = "";
								}
							} // end of  if(names[temp] === clickedFile)
						} // end of for
					}); //$(document).on

					var data = new FormData();
					data.append("campID", campID);
					data.append("file", file);
					data.append("supportDocID", supportDocID);
					

					//Sonali-4032-adding this route 
					fetch("publisher/uploadPOCFILE", {
						method: "POST",
						body: data,
					})
						.then(function (response) {
							if (response.status >= 400) {
								throw new Error("Bad response from server");
							}
							return response.json();
						})
						.then(function (response) {
						
							var msg = "Newsletter File Uploaded Successfully";
							var message = document.getElementById(
								"pocFileMessage" + supportDocID
							);

							if (arrayLength > 0) {
								message.innerHTML = msg;
								uploadPOC = true;
							} else {
								message.innerHTML = "";
								uploadPOC = false;
								uploadPOCBE = false;
							}

						})
						.catch(function (err) {
							console.log(err);
						});

					this.setState({
						files: data,
					});
					var error = "pocerr:" + supportDocID;

					pocerror[error] = "";
					this.setState({
						pocerror: pocerror,
					});
				} else {
					 error = "pocerr:" + supportDocID; //Nilesh-4242-Removing console warnings
					pocerror[error] =
						"Please upload files having extensions: " +
						allowedFiles.join(", ") +
						" only";
					this.setState({
						pocerror: pocerror,
					});
				}
			} else {
				nooutput.innerHTML = " No File Chosen";
			}
		}
	}

	/**
	 * @author Supriya Gore
	 * @param  Description download LP files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	handleLPFileDownload(e) {
		e.preventDefault()
				//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
				var campID=this.state.campID;
				// var pID=this.state.pID; //Nilesh-4242-Removing console warnings
				
				

		var supportDocID = e.target.getAttribute("supportDocID");

		var lpFeedbackFileName = e.target.getAttribute("lpFeedbackFileName");
		var ext = lpFeedbackFileName.split(".").pop();
		this.setState({ lpExt: ext, lpFilename: lpFeedbackFileName });
		//sonali-3945-removing pID from following call
		fetch(
			"/publisher/lpFeedbackFileDownload?campID=" +
				campID +
				"&supportDocID=" +
				supportDocID
		)
			.then((r) => r.blob())
			.then((downloadLPFiles) => {
				this.setState({ downloadLPFiles: downloadLPFiles });
			})

			.then(this.downloadLPFeedbackFile);
	} /*** End of handleLPFileDownload */

	/**
	 * @author Supriya Gore
	 * @param  Description download LP files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	downloadLPFeedbackFile(blob) {
		/***** If POC File is PDF */
		//Nilesh-4242-Removing console warnings
		if (this.state.lpExt === "pdf") {
			var url = this.state.lpFilename;
			var fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pdfFileDownloadName = fileName;
			var newBlob = new Blob([this.state.downloadLPFiles], {
				type: "application/pdf",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = pdfFileDownloadName;
			link.click();
		}
		if (this.state.lpExt === "ppt" || this.state.lpExt === "pptx") {  //Nilesh-4242-Removing console warnings
		    url = this.state.lpFilename; //Nilesh-4242-Removing console warnings
		    fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4242-Removing console warnings
			let pptFileDownloadName = fileName;
		    newBlob = new Blob([this.state.downloadLPFiles], { //Nilesh-4242-Removing console warnings
				type: "application/vnd.ms-powerpoint",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a"); //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = pptFileDownloadName;
			link.click();
		}

		if (this.state.lpExt === "txt") {  //Nilesh-4242-Removing console warnings
			 url = this.state.lpFilename; //Nilesh-4242-Removing console warnings
			 fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4242-Removing console warnings
			let textFileDownloadName = fileName;
			 newBlob = new Blob([this.state.downloadLPFiles], { //Nilesh-4242-Removing console warnings
				type: "text/plain",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
		    link = document.createElement("a"); //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = textFileDownloadName;
			link.click();
		}

		if (this.state.lpExt === "docx") {   //Nilesh-4242-Removing console warnings
		    url = this.state.lpFilename; //Nilesh-4242-Removing console warnings
		    fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4242-Removing console warnings
			let docFileDownloadName = fileName;
		    newBlob = new Blob([this.state.downloadLPFiles], { //Nilesh-4242-Removing console warnings
				type: "application/msword",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
		    link = document.createElement("a"); //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
        //Nilesh-4242-Removing console warnings
		if (
			this.state.lpExt === "png" ||
			this.state.lpExt === "jpeg" ||
			this.state.lpExt === "jpg" ||
			this.state.lpExt === "PNG" ||
			this.state.lpExt === "JPEG" ||
			this.state.lpExt === "JPG"
		) {
		    url = this.state.lpFilename;  //Nilesh-4242-Removing console warnings
		    fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4242-Removing console warnings
			let pngFileDownloadName = fileName;
		    newBlob = new Blob([this.state.downloadLPFiles], { //Nilesh-4242-Removing console warnings
				type: "application/Image",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
		    link = document.createElement("a");  //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = pngFileDownloadName;
			link.click();
		}
	} /**** End of downloadLPFeedbackFile */

	/**
	 * @author Supriya Gore
	 * @param  Description download POC files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	handlePOCFeedbackFileDownload(e) {
		e.preventDefault()
		//Nilesh-4242-Removing console warnings
		// var pubID = this.props.location.state;//Somnath Task-3955, VAPT--publisher side--Home--URL links And Routes-FE
		var campID=this.state.campID;
		// var pID=this.state.pID; //Nilesh-4242-Removing console warnings
		var supportDocID = e.target.getAttribute("supportDocID");
		var pocFeedbackFileName = e.target.getAttribute("pocFeedbackFileName");
		var ext = pocFeedbackFileName.split(".").pop();
		this.setState({ pocExt: ext, pocFeedbackFilename: pocFeedbackFileName });
		//sonali-3945-removing pID from the following call
		fetch(
			"/publisher/pocFeedbackFileDownload?campID=" +
				campID +
				"&supportDocID=" +
				supportDocID
		)
			.then((r) => r.blob())
			.then((downloadPOCFeedbackFiles) => {
				this.setState({ downloadPOCFeedbackFiles: downloadPOCFeedbackFiles });
			})

			.then(this.downloadPOCFeedbackFile);
	} /*** End of handlePOCFileDownload */

	/**
	 * @author Supriya Gore
	 * @param  Description download POC files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	downloadPOCFeedbackFile(blob) {
		/***** If POC File is PDF */
		if (this.state.pocExt === "pdf") {  //Nilesh-4242-Removing console warnings
			var url = this.state.pocFeedbackFilename;
			var fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pdfFileDownloadName = fileName;
			var newBlob = new Blob([this.state.downloadPOCFeedbackFiles], {
				type: "application/pdf",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = pdfFileDownloadName;
			link.click();
		}
		if (this.state.pocExt === "ppt" || this.state.pocExt === "pptx") {  //Nilesh-4242-Removing console warnings
		    url = this.state.pocFeedbackFilename;  //Nilesh-4242-Removing console warnings
		    fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4242-Removing console warnings
			let pptFileDownloadName = fileName;
		    newBlob = new Blob([this.state.downloadPOCFeedbackFiles], { //Nilesh-4242-Removing console warnings
				type: "application/vnd.ms-powerpoint",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
		    link = document.createElement("a"); //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = pptFileDownloadName;
			link.click();
		}

		if (this.state.pocExt === "txt") {  //Nilesh-4242-Removing console warnings
		    url = this.state.pocFeedbackFilename;  //Nilesh-4242-Removing console warnings
		    fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4242-Removing console warnings
			let textFileDownloadName = fileName;
			 newBlob = new Blob([this.state.downloadPOCFeedbackFiles], { //Nilesh-4242-Removing console warnings
				type: "text/plain",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
		    link = document.createElement("a"); //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = textFileDownloadName;
			link.click();
		}

		if (this.state.pocExt === "docx") {  //Nilesh-4242-Removing console warnings
		    url = this.state.pocFeedbackFilename; //Nilesh-4242-Removing console warnings
		    fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4242-Removing console warnings
			let docFileDownloadName = fileName;
		    newBlob = new Blob([this.state.downloadPOCFeedbackFiles], { //Nilesh-4242-Removing console warnings
				type: "application/msword",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
		    link = document.createElement("a"); //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}

		//Nilesh-4242-Removing console warnings
		if (
			this.state.pocExt === "png" ||
			this.state.pocExt === "jpeg" ||
			this.state.pocExt === "jpg" ||
			this.state.pocExt === "PNG" ||
			this.state.pocExt === "JPEG" ||
			this.state.pocExt === "JPG"
		) {
		    url = this.state.pocFeedbackFilename; //Nilesh-4242-Removing console warnings
		    fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4242-Removing console warnings
			let pngFileDownloadName = fileName;
		    newBlob = new Blob([this.state.downloadPOCFeedbackFiles], { //Nilesh-4242-Removing console warnings
				type: "application/Image",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
		    link = document.createElement("a"); //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = pngFileDownloadName;
			link.click();
		}
	} /**** End of downloadPOCFeedbackFile */

	/**
	 * @author Supriya Gore
	 * @param  Description download CS files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	handleCSFeedbackFileDownload(e) {
		e.preventDefault();
		//Nilesh-4242-Removing console warnings
		// var pubID = this.props.location.state;//Somnath Task-3955, VAPT--publisher side--Home--URL links And Routes-FE
		var campID=this.state.campID;
		// var pID=this.state.pID;   //Nilesh-4242-Removing console warnings
		var supportDocID = e.target.getAttribute("supportDocID");
		var csFeedbackFileName = e.target.getAttribute("csFeedbackFileName");
		var ext = csFeedbackFileName.split(".").pop();
		this.setState({ csExt: ext, csFeedbackFilename: csFeedbackFileName });
		
//sonali-3945-removing pID from the following call	
		fetch(
			"/publisher/csFeedbackFileDownload?campID=" +
				campID +
				"&supportDocID=" +
				supportDocID
		)
			.then((r) => r.blob())
			.then((downloadCSFeedbackFiles) => {
				this.setState({ downloadCSFeedbackFiles: downloadCSFeedbackFiles });
			})

			.then(this.downloadCSFeedbackFile);
	} /*** End of handleCSFileDownload */
     
		apiCall(campID, pID) {
		let self = this;
		const { user } = this.props.auth;
		const agencyID = user.id;
		//karan-task-3715-replace query params
		let parentCampID, reallocationID, backKey;
		if (this.props.location.state !== undefined) {
			parentCampID = this.props.location.state.parentCampID;
			reallocationID = this.props.location.state.reallocationID;
			backKey = this.props.location.state.backKey;
		}
		var submitButtonAction;
		// let apiData = { campID, agencyID }; // karan-task-3714-vapt header and query params
		let apiData = { campID, pID };

		this.setState({ parentCampID, reallocationID, agencyID, campID, backKey, pID, });
		//Get list of campign Id
		fetch("publisher/lpPOCCSReviewNotifyDashPub")
			.then((res) => res.json())
			.then(creativeCampList => this.setState({ creativeCampList, listCampID: creativeCampList }))
			.catch(e => console.log(e));
	
//Get Campaign Info
fetch("publisher/lpReviewPendingForCampIDPub?campID=" + campID)
.then((res) => res.json())
.then((lpReviewPendingListCampIDOnNextPage) => {
  let marketingChannel =
	lpReviewPendingListCampIDOnNextPage[0].marketingChannel;
  let approvalFor =
	lpReviewPendingListCampIDOnNextPage[0].creativeApprovalRequiredFor;
  let campaignName = lpReviewPendingListCampIDOnNextPage[0].campaignName;
  self.setState({
	lpReviewPendingListCampIDOnNextPage,
	marketingChannel,
	approvalFor,
	campaignName,
  });
})
.catch((e) => console.log(e));

let data1 = {
	campID: this.props.location.state.campID,
};
fetch("/publisher/creativeUploadDetails", {
	method: "POST",
	headers: { "Content-Type": "application/json" },
	body: JSON.stringify(data1),
})
	.then((res) => res.json())
	.then((LP_POC_CS_SubmissionPopUP) => {
		this.setState(
			{ LP_POC_CS_SubmissionPopUP: LP_POC_CS_SubmissionPopUP },
			function () {
				console.log(
					"LP_POC_CS_SubmissionPopUP" +
						JSON.stringify(this.state.LP_POC_CS_SubmissionPopUP)
				);
			}
		);
	})
	.catch(function (err) {
		console.log(err);
	});

// getting List
fetch("/publisher/IdForCreativeReview", {
	method: "POST",
	headers: { "Content-Type": "application/json" },
	body: JSON.stringify(apiData),
  })
	.then((res) => res.json())
	.then((ListForLPReview) => self.setState({ListForLPReview }))
	.catch((e) => console.log(e));
			
			// get creative Data
            fetch("/publisher/getCreativeInfoPub", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(apiData),
			  })
				.then((res) => res.json())
				.then((lpReviewPendingList) => {
				  if (lpReviewPendingList.length === 0) {  //Nilesh-4242-Removing console warnings
				   
					let message = "Creatives Not Available";
					self.setState({
					  creativesMessage: message,
					  submitButton: "disabled",
					});
				  } else {
					let message = " ";
					self.setState({ creativesMessage: message });
				  }
		  
				  self.setState(
					{ lpReviewPendingList: lpReviewPendingList, tableStatus: "true" },
					() => {
					  if (lpReviewPendingList.length > 0) {
						for (var i = 0; i < lpReviewPendingList.length; i++) {
							//Nilesh-4242-Removing console warnings
						  if (
							lpReviewPendingList[i].lpStatus === "AgencyReviewPending" ||
							lpReviewPendingList[i].lpStatus === "Rejected" ||
							lpReviewPendingList[i].pocStatus === "AgencyReviewPending" ||
							lpReviewPendingList[i].pocStatus === "Rejected" ||
							lpReviewPendingList[i].csStatus === "AgencyReviewPending" ||
							lpReviewPendingList[i].csStatus === "Rejected"
						  ) {
							submitButtonAction = false;
						  }
						  if (lpReviewPendingList[i].hasOwnProperty("lpStatus")) {
							if (
								lpReviewPendingList[i].lpStatus === "Rejected" ||
								lpReviewPendingList[i].lpStatus === "ClientRejected"
							) {
							  var timeline = document.getElementById(
								"timeline" +
								lpReviewPendingList[i].supportDocID +
								lpReviewPendingList[i].pID +
								  "lpStatus"
							  );
							  if (
								timeline == null ||
								timeline === "null" ||
								timeline === undefined
							  ) {
							  } else {
								timeline.style.display = "block";
							  }
							}
						  }
						  if (lpReviewPendingList[i].hasOwnProperty("pocStatus")) {
							if (
								lpReviewPendingList[i].pocStatus === "Rejected" ||
								lpReviewPendingList[i].pocStatus === "ClientRejected"
							) {
							  var pocTimeline = document.getElementById(
								"timeline" +
								lpReviewPendingList[i].supportDocID +
								lpReviewPendingList[i].pID +
								  "pocStatus"
							  );
							  if (
								pocTimeline &&
								pocTimeline.style &&
								pocTimeline.style.display
							  ) {
								pocTimeline.style.display = "block";
							  }
							}
						  }
						  if (lpReviewPendingList[i].hasOwnProperty("csStatus")) {
							if (
								lpReviewPendingList[i].csStatus === "Rejected" ||
								lpReviewPendingList[i].csStatus === "ClientRejected"
							) {
							  var csTimeline = document.getElementById(
								"timeline" +
								lpReviewPendingList[i].supportDocID +
								lpReviewPendingList[i].pID +
								  "csStatus"
							  );
							  if (
								csTimeline &&
								csTimeline.style &&
								csTimeline.style.display
							  ) {
								csTimeline.style.display = "block";
							  }
							}
						  }
						}
						this.setState({ submitButtonAction: submitButtonAction });
					  }
					}
				  );
				})
				.catch((e) => console.log(e));
			// get Creative Data
	}
	/**
	 * @author Supriya Gore
	 * @param  Description download CS files in PDF.PPT,word format
	 * @return Description return pdf,ppt,word file
	 */
	downloadCSFeedbackFile(blob) {
		/***** If POC File is PDF */
		//Nilesh-4242-Removing console warnings
		if (this.state.csExt === "pdf") {
			var url = this.state.csFeedbackFilename;
			var fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pdfFileDownloadName = fileName;
			var newBlob = new Blob([this.state.downloadCSFeedbackFiles], {
				type: "application/pdf",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = pdfFileDownloadName;
			link.click();
		}
		//Nilesh-4242-Removing console warnings
		if (this.state.csExt === "ppt" || this.state.csExt === "pptx") {
		    url = this.state.csFeedbackFilename; //Nilesh-4242-Removing console warnings
			fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4242-Removing console warnings
			let pptFileDownloadName = fileName;
			newBlob = new Blob([this.state.downloadCSFeedbackFiles], { //Nilesh-4242-Removing console warnings
				type: "application/vnd.ms-powerpoint",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a"); //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = pptFileDownloadName;
			link.click();
		}

		if (this.state.csExt === "txt") {  //Nilesh-4242-Removing console warnings
			 url = this.state.csFeedbackFilename; //Nilesh-4242-Removing console warnings
			 fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4242-Removing console warnings
			let textFileDownloadName = fileName;
			 newBlob = new Blob([this.state.downloadCSFeedbackFiles], {  //Nilesh-4242-Removing console warnings
				type: "text/plain",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = textFileDownloadName;
			link.click();
		}

		if (this.state.csExt === "docx") {  //Nilesh-4242-Removing console warnings
			 url = this.state.csFeedbackFilename; //Nilesh-4242-Removing console warnings
			 fileName = url.substring(url.lastIndexOf("\\") + 1); //Nilesh-4242-Removing console warnings
			let docFileDownloadName = fileName;
			 newBlob = new Blob([this.state.downloadCSFeedbackFiles], { //Nilesh-4242-Removing console warnings
				type: "application/msword",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a"); //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
        //Nilesh-4242-Removing console warnings
		if (
			this.state.csExt === "png" ||
			this.state.csExt === "jpeg" ||
			this.state.csExt === "jpg" ||
			this.state.csExt === "PNG" ||
			this.state.csExt === "JPEG" ||
			this.state.csExt === "JPG"
		) {
			 url = this.state.csFeedbackFilename; //Nilesh-4242-Removing console warnings
			 fileName = url.substring(url.lastIndexOf("\\") + 1);  //Nilesh-4242-Removing console warnings
			let pngFileDownloadName = fileName;
			 newBlob = new Blob([this.state.downloadCSFeedbackFiles], { //Nilesh-4242-Removing console warnings
				type: "application/Image",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			link = document.createElement("a");  //Nilesh-4242-Removing console warnings
			link.href = data;
			link.download = pngFileDownloadName;
			link.click();
		}
	} /**** End of downloadPOCFie */

	handleChangeUploadCSFile(i, e) {
		e.preventDefault();
		//SOmnath Task-3955 get pid and campid from state
		var campID=this.state.campID;
			// var pID=this.state.pID;  //Nilesh-4242-Removing console warnings
		var supportDocID = e.target.id;

		if (
			document.getElementById("csFileName" + supportDocID) != null ||
			document.getElementById("csFileName" + supportDocID) !== undefined //Nilesh-4242-Removing console warnings
		) {
			document.getElementById("csFileName" + supportDocID).innerHTML =
				""; /**** this is making empty for hide the message No File Chosen because of in dynamic delete function it assign value No File Chosen */
		}

		const { name, value } = e.target;
		let csArray = [...this.state.csArray];
		csArray[i] = { ...csArray[i], [name]: value };
		this.setState({ csArray });
		var names = [];
		let filesData = e.target.files;
		if (filesData.length > 0) {
			var fileCs = filesData[0].name;
			this.setState({ fileCs: fileCs });
			let file = e.target.files[0];
			console.warn("data files", filesData);
			var FileExt;
			for (let i = 0, l = filesData.length; i < l; i++) { //Nilesh-4242-Removing console warnings
				names[i] = filesData[i].name;
				FileExt = names[i].split(".").pop();
			}

			/**
			 * @author Sanjana Godbole
			 * @param  A FileList object that lists every selected file
			 * @return List of uploded file along with remove link
			 */
			let cserror = {};
			var link;
			var filename = "";
			var arrayLength = names.length;
			var nooutput = document.getElementById("csList" + supportDocID);

			if (arrayLength > 0) {
				var allowedFiles = [".doc", ".docx"];
				//Nilesh-4242-Removing console warnings
				if (
					FileExt === "doc" ||
					FileExt === "docx" ||
					FileExt === "DOC" ||
					FileExt === "DOCX"
				) {
					nooutput.innerHTML = arrayLength + "  File Selected";
					Filecs = true;
					var HTML = "<table>";
					for (var j = 0; j < arrayLength; j++) {
						link =
							'<a class="removeFileCS" href="#" data-fileid=' +
							'"' +
							names[j] +
							'"' +
							'><i class="fa fa-times"></i></a>';
						HTML +=
							"<tr><td>" +
							link +
							"&nbsp;&nbsp;&nbsp;&nbsp;" +
							names[j] +
							"</td></tr>";
					}
					HTML += "</table>";
					document.getElementById("csAbmList" + supportDocID).innerHTML = HTML;
					$(document).on("click", ".removeFileCS", function (e) {
						e.preventDefault();
						var clickedFile = $(this).parent().children("a").data("fileid");
						var j = 0;
						for (j; j < names.length; j++) {
							var temp = j;

							if (names[temp] === clickedFile) {
								filename = names[temp];
								names.splice(j, 1);
								$(this).parent().remove();
								arrayLength = names.length;

								/**
								 * @author Narendra Phadke
								 * @param  Delete File From Database
								 */
								// var typeOfSuppDoc = "ABM";  //Nilesh-4242-Removing console warnings
								let data = {
									campID: this.props.location.state.campID,
									supportDocID: supportDocID,
									//pID: pID,
									//sonali-3945-removing pID 
								};

								document.getElementById(supportDocID).value = "";
								fetch("publisher/csDelete", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								}).catch(function (err) {
									console.log(err);
								});

								if (arrayLength > 0) {
									nooutput.innerHTML = arrayLength + "  File Selected";
								} else {
									nooutput.innerHTML = " No File Chosen";
									$("#abm1").val("");
									var message = document.getElementById(
										"csFileMessage" + supportDocID
									);
									message.innerHTML = "";
								}
							} // end of  if(names[temp] === clickedFile)
						} // end of for
					}); //$(document).on

					var data = new FormData();
					data.append("campID", campID);
					data.append("file", file);
					//sonali-3945-removing pID from the following 
					data.append("supportDocID", supportDocID);

					fetch("publisher/uploadCSFILE", {
						method: "POST",
						body: data,
					})
						.then(function (response) {
							if (response.status >= 400) {
								throw new Error("Bad response from server");
							}
							return response.json();
						})
						.then(function (response) {
						
							var msg = "CS File Uploaded Successfully";
							var message = document.getElementById(
								"csFileMessage" + supportDocID
							);

							if (arrayLength > 0) {
								message.innerHTML += msg;
								uploadCS = true;
							} else {
								message.innerHTML = "";
								uploadCS = false;
								uploadCSBE = false;
							}
						})
						.catch(function (err) {
							console.log(err);
						});

					this.setState({
						files: data,
					});
					var error = "cserr:" + supportDocID;

					cserror[error] = "";
					this.setState({
						cserror: cserror,
					});
				} else {
					 error = "cserr:" + supportDocID;  //Nilesh-4242-Removing console warnings
					cserror[error] =
						"Please upload files having extensions: " +
						allowedFiles.join(", ") +
						" only";
					this.setState({
						cserror: cserror,
					});
				}
			} else {
				nooutput.innerHTML = " No File Chosen";
			}
		}
	}

	handleChangeDynamicLpInsert(i, e) {
		document.getElementById("lpLink" + i).style.border = "1px solid lightgray";
		document.getElementById("feedback" + i).innerHTML = null;
		const { name, value } = e.target;
		let newDynamicArray = [...this.state.newDynamicArray];
		newDynamicArray[i] = { ...newDynamicArray[i], [name]: value };
		this.setState({ newDynamicArray });

		lpsubmitLink = true;
		// var lpArray = this.state.newDynamicArray;  //Nilesh-4242-Removing console warnings
	} // end of  handleChangeDynamicLpInsert
	handleChange(e) {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({
			fields,
		});

		var agencyID = e.target.value;
		let campID;
		if (this.props.location.state !== undefined) {
			campID = this.props.location.state.campID;
		}
		this.apiCall(campID, agencyID);
		
	}
    handleCampID(e) {
		e.preventDefault()
		const campID = e.target.id;
		const pID = "All";
		this.setState({ campID })
		this.apiCall(campID, pID);
	}
	// kiran-4173-added handlchange for search
	handleChangeSearch(e) {
		e.preventDefault();
		let currentList = [], campaignDetails = [];
		let searchData = e.target.value;
		// If the search bar isn't empty
		if (searchData === "") {
			campaignDetails = [...this.state.listCampID];
		} else {
			// Assign the original list to currentList
			currentList = [...this.state.listCampID];
			campaignDetails = currentList.filter((item) => {
				let obj = {};//Somnath Task-3972, Add specific Keys in search criteria
				obj[`campID`] = item.campID;
				// obj[`parentCampID`]=item.parentCampID;
				obj[`clientCampID`] = item.clientCampID;
				obj[`campaignName`] = item.campaignName;
				const filter = searchData.toLowerCase();
				var data = Object.values(obj);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
		}
		this.setState({ creativeCampList: [...campaignDetails], searchData });
	}//End of handleChangeSearch
	deletePOCFile(e) {
		e.preventDefault();
		//Nilesh-4242-Removing console warnings
		// var pubID = this.props.location.state;//Somnath Task-3955, VAPT--publisher side--Home--URL links And Routes-FE
		// var campID=this.state.campID; //Nilesh-4242-Removing console warnings
			// var pID=this.state.pID; //Nilesh-4242-Removing console warnings
		var supportDocID = e.target.id;

		document.getElementById("fileName" + supportDocID).innerHTML = "";
		let data = {
			campID: this.props.location.state.campID,
			supportDocID: supportDocID,
			//pID: pID,//sonali-3945-removing pID from the following call
		};

		fetch("publisher/pocDelete", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}).catch(function (err) {
			console.log(err);
		});
		this.forceUpdate();
		var nooutput = document.getElementById("pocList" + supportDocID);
		nooutput.innerHTML = "";
		var nooutput1 = document.getElementById("fileName" + supportDocID);
		nooutput1.innerHTML = "No File Chosen";
	}

	deleteCSFile(e) {
		e.preventDefault();
		//Nilesh-4242-Removing console warnings
		// var pubID = this.props.location.state;//Somnath Task-3955, VAPT--publisher side--Home--URL links And Routes-FE
		// var campID=this.state.campID; //Nilesh-4242-Removing console warnings
			// var pID=this.state.pID; //Nilesh-4242-Removing console warnings
		var supportDocID = e.target.id;

		document.getElementById("csFileName" + supportDocID).innerHTML = "";
		let data = {
			campID: this.props.location.state.campID,
			supportDocID: supportDocID,
			//pID: pID, //sonali-3945-removing pID from the following call
		};

		fetch("publisher/csDelete", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}).catch(function (err) {
			console.log(err);
		});
		this.forceUpdate();
		var nooutput = document.getElementById("csList" + supportDocID);
		nooutput.innerHTML = "";
		var nooutput1 = document.getElementById("csFileName" + supportDocID);
		nooutput1.innerHTML = "No File Chosen";
	}
	submitLandingPage1(e) {
		e.preventDefault();
		// let fields = this.state.fields;  //Nilesh-4242-Removing console warnings
		let errors = {};
		let minerror = {};
		// let success = {}; //Nilesh-4242-Removing console warnings
		let formIsValid = false;
		let spanDisplay = "none";
		let link = false;
		let LandingPageLink = false;
		let CallScriptValidate = false;
		let NewsletterValidate = false;

		/*
@author:Sanobar Golandaj
@Creation Date:04-02-2019
@Description:validation for selecting min one lp link
*/

		result = Array.from(
			new Set(this.state.newDynamicArray.map((s) => s.supportDocID))
		).map((supportDocID) => {
			return {
				supportDocID: supportDocID,
				suppDocName: this.state.newDynamicArray.find(
					(s) => s.supportDocID === supportDocID
				).suppDocName,
				status: this.state.newDynamicArray.find(
					(s) => s.supportDocID === supportDocID
				).status,
				lpLink: this.state.newDynamicArray.find(
					(s) => s.supportDocID === supportDocID
				).lpLink,
				feedback: this.state.newDynamicArray.find(
					(s) => s.supportDocID === supportDocID
				).feedback,
				assetStatus: this.state.newDynamicArray.find(
					(s) => s.supportDocID === supportDocID
				).assetStatus,
			};
		});

		var dynamiclength = result.length;
		for (var i = 0; i < dynamiclength; i++) {
			if (result[i].lpLink !== undefined && result[i].lpLink !== "") {
				LandingPageLink = true;
			} else {
			}
		}
		
        //Nilesh-4242-Removing console warnings
		if (Filecs === true) {
			CallScriptValidate = true;
		} else {
		}
        //Nilesh-4242-Removing console warnings
		if (Filepoc === true) {
			NewsletterValidate = true;
		} else {
		}
        
		//Nilesh-4242-Removing console warnings
		if (
			LandingPageLink === true ||
			CallScriptValidate === true ||
			NewsletterValidate === true
		) {
			formIsValid = true;
			this.setState({
				minerror: "",
				alertBorder: "0px solid white",
			});
		} else {
			// minerror["commonError1"] = "Please enter min one creative";
			minerror["commonError1"] = "Please enter LP/POC/Call Script details";//Nilesh-5020 - Add error message
			formIsValid = false;
			this.setState({
				minerror: minerror,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}

		 dynamiclength = result.length; //Nilesh-4242-Removing console warnings
		for (let i = 0; i < dynamiclength; i++) { //Nilesh-4242-Removing console warnings
			if (result[i].lpLink !== undefined && result[i].lpLink !== "") {
				link = true;
				displayresult.splice(i, 1);
			} else {
			}

			if (link === true) {
				formIsValid = true;
				this.setState({ alertBorder: "0px solid white" });
			} else {
				errors["message"] = "Please enter minimum one lp link";
				this.setState({
					newsuccess: "",
					errors: errors,
					alertBorder: "1px solid red",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
					spanSpaceDisplay: spanDisplay,
				});
			}

			// validation for disable submit button if all links are approved.
			if (
				result[i].status === "Approved" ||
				result[i].status === "ClientApproved" ||
				result[i].status === "ClientReviewPending"
			) {
				formIsValid = false;
				this.setState({
					alertDisplay: "none",
					alertBorder: "0px solid white",
					btnDisable: "disabled1",
				});
			} else {
				this.setState({
					btnDisable: "button",
				});
			}
		}

		if (formIsValid === true || uploadPOC === true || uploadCS === true) {
				//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
		// var campID,pID,parentCampID; //Nilesh-4242-Removing console warnings
		// 	var campID=this.state.campID; //Nilesh-4242-Removing console warnings
		// 	var pID = this.state.pID; //Nilesh-4242-Removing console warnings
		
			var supportDocID = document.getElementById("supportDocID").value;
			
			/**
			 * @author Narendra Phadke
			 * @param  Description handle the email functionality
			 * @return Description return All details of email
			 */
			// const { isAuthenticated, user } = this.props.auth;  //Nilesh-4242-Removing console warnings
			let data = {
				campID: this.props.location.state.campID,
				//pID: pID, //sonali-3945-removing pID from the following 
				lpLink: this.state.lpLink,
				feedback: this.state.feedback,
				supportDocID: supportDocID,
				agencyID: this.state.campaignDetails[0].agencyID,
				clientCampID: this.state.LP_POC_CS_SubmissionPopUP[0].clientCampID,
				dynamicArray: this.state.newDynamicArray,
				//user: user,//sonali-3945-removing user from the following 
				parentCampID: this.props.location.state.parentCampID,//Sonali-4138-getting paremntCampID from location.state				
				reallocationID: this.state.reallocationID,
			};
			this.setState({
				errors: "",
				alertBorder: "0px solid white",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});

			// var lpSuccess = false;  //Nilesh-4242-Removing console warnings
			// var lpSuccessBE = true; //Nilesh-4242-Removing console warnings
			// var pocSuccess = false;    //Nilesh-4242-Removing console warnings
			// var csSuccess = false;     //Nilesh-4242-Removing console warnings
			var length;
			if (lpsubmitLink === true && uploadCS === false && uploadPOC === false) {
				let urls = ["/publisher/submitLandingPage"];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
			
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								let data = {
									//pID: pID,//sonali-3945-removing pID from the following call
									campID: this.props.location.state.campID,
								};
								// Sandeep-task-3273- added code for Upload creatives LP link validation message issue
								fetch("/publisher/creativeUploadDetails", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								})
									.then((res) => res.json())
									.then((LP_POC_CS_SubmissionPopUP) => {
										var lpLinkData = LP_POC_CS_SubmissionPopUP;
										var lpLinkValue = lpLinkData.filter(function (s) {
											return s.sslFeedback != "";
										});
										length = lpLinkValue.length;
										if (length > 0) {
											Swal.fire({
												text: "LP did not submitted successfully",
												type: "error",
											});
										} else {
											Swal.fire({
												text: " LP submitted Successfully",
												type: "success",
											}).then((result) => {
												if (result.value) {
													window.location.reload();
												}
											});
										}

										this.setState(
											{ LP_POC_CS_SubmissionPopUP: LP_POC_CS_SubmissionPopUP },
											function () {
												
											}
										);
									})
									.catch(function (err) {
										console.log(err);
									});
							})

							.catch((err) => {
								Swal.fire({
									text: "LP did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (uploadPOC === true && lpsubmitLink === false && uploadCS === false) {
				let urls = ["/publisher/submitPOCRecord"];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusPOC", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
								Swal.fire({
									text: " POC submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "POC did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (uploadCS === true && lpsubmitLink === false && uploadPOC === false) {
				let urls = ["/publisher/submitCSRecord"];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusCS", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
								Swal.fire({
									text: " CS submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "CS did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (lpsubmitLink === true && uploadCS === true && uploadPOC === false) {
				let urls = [
					"/publisher/submitLandingPage",
					"/publisher/submitCSRecord",
				];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusCS", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
								Swal.fire({
									text: "LP and CS submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "LP and CS did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (lpsubmitLink === true && uploadPOC === true && uploadCS === false) {
				let urls = [
					"/publisher/submitLandingPage",
					"/publisher/submitPOCRecord",
				];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusPOC", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
								Swal.fire({
									text: "LP and POC submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "LP and POC did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (uploadCS === true && uploadPOC === true && lpsubmitLink === false) {
				let urls = ["/publisher/submitPOCRecord", "/publisher/submitCSRecord"];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusPOC", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
					
									fetch("/publisher/updateStatusCS", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data11),
									})
										.then((res) => res.json())
										.then(console.log("finally Submitted"))
										.catch(function (err) {
											console.log(err);
										});
								Swal.fire({
									text: "POC and CS submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "POC and CS did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
			if (lpsubmitLink === true && uploadCS === true && uploadPOC === true) {
				let urls = [
					"/publisher/submitPOCRecord",
					"/publisher/submitCSRecord",
					"/publisher/submitLandingPage",
				];
				let requests = urls.map((url) =>
					fetch(url, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
				);
				Swal.fire({
					onOpen: () => {
						Swal.showLoading();
						Promise.all(requests)
							.then((responses) => {
								// Chaitanya-task-4107-Added code for Publisherside-Review-Creatives review-status
								let data11 = {
									campID: this.props.location.state.campID,
								};
								fetch("/publisher/updateStatusPOC", {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data11),
								})
									.then((res) => res.json())
									.then(console.log("finally Submitted"))
									.catch(function (err) {
										console.log(err);
									});
					
									fetch("/publisher/updateStatusCS", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data11),
									})
										.then((res) => res.json())
										.then(console.log("finally Submitted"))
										.catch(function (err) {
											console.log(err);
										});
								Swal.fire({
									text: "LP,POC,CS submitted Successfully",
									type: "success",
								}).then((result) => {
									if (result.value) {
										window.location.reload();
									}
								});
							})
							.catch((err) => {
								Swal.fire({
									text: "LP,POC,CS did not submitted successfully",
									type: "error",
								});
							});
					},
				});
			}
					} else {
		}
	} // end for submit

	//Sonali-4138-did mount changes to will mount
	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		}
	//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
		var campID,pID,parentCampID,step,agencyID;
		
		if(this.props.location.state!==undefined)
		{
			var parsed = this.props.location.state;
			campID=parsed.campID;
			pID=parsed.pID
			parentCampID = parsed.parentCampID;
			step=parsed.step;
			this.setState({ pID: pID, parentCampID: parentCampID,campID:campID,step:step});
	


		
	//Sonali-3945-VAPT-accessing parameters from location.state


		this.setState({ pID: pID, parentCampID: parentCampID });
		let data = {
			//pID: pID,//sonali-3945-removing pID from the following call
			campID: this.props.location.state.campID,
		};
		

		fetch("/publisher/creativeUploadDetails", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((LP_POC_CS_SubmissionPopUP) => {
				this.setState(
					{ LP_POC_CS_SubmissionPopUP: LP_POC_CS_SubmissionPopUP },
					function () {
						console.log(
							"LP_POC_CS_SubmissionPopUP" +
								JSON.stringify(this.state.LP_POC_CS_SubmissionPopUP)
						);
					}
				);
			})
			.catch(function (err) {
				console.log(err);
			});

		fetch("/publisher/campaignDetailsForCreativeUploads", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((campaignDetails) => {
				this.setState({
					campaignDetails: campaignDetails,
					marketingChannel: campaignDetails[0].marketingChannel,
					parentCampID: campaignDetails[0].parentCampID,
					reallocationID: campaignDetails[0].reallocationID,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
		}
		this.setState({ campID })
		agencyID = agencyID || "All";
		this.apiCall(campID, agencyID);
	}
	render() {
		this.state.LP_POC_CS_SubmissionPopUP.forEach((itm, i) => {
			this.state.newDynamicArray.push(Object.assign({}, itm));
		});

		
		// const { TextArea } = Input;  //Nilesh-4242-Removing console warnings
		return (
			<div>
				<PublisherNavigation />
				<form method="POST" name="submitLP" onSubmit={this.submitLandingPage1}>
					<div
						style={{ paddingBottom: "60px", paddingTop: "90px" }}
						class="container-fluid">
						<div class="row">
							<div class=" col-md-4" style={{ paddingLeft: "20px" }}>
								<a
								    href="#/"  //Nilesh-4242-Removing console warnings
									//karan-task-3717-replace query params
									onClick={(e) =>{
										e.preventDefault();
										this.props.history.push("/newPublisherDashboard", {
											pID: this.state.pID,
										})
									}
									}
									style={{ color: "#056eb8" }}>
									<FaArrowAltCircleLeft
										size={32}
										style={{ float: "left" }}
										title="Back to Dashboard"
									/>
								</a>
							</div>
							
							{/* //shivani-3285-passed ID for DI label consistency */}
							<div
								class="col-md-6"
								align="right"
								style={{ textAlign: "center" }}>
								<label id="labelDI" className="CreativesReviewPublisher">Upload creatives</label>
							</div>
						
						</div>

						<div style={{ border: this.state.alertBorder }}>
							<div
								className="errorMessage"
								style={{ display: this.state.alertDisplay }}>
								{this.state.minerror.commonError1}
							</div>
						</div>
						

						{/* kiran-4173-added list of campaigns */}
						<div class="row">
						<div class="col-lg-3" style={{ paddingRight: '0px', borderRadius: '0px' }}>
							<div style={{ border: '1px solid gray', backgroundColor: '#ffff' }}>
								<div>
									<div class="card-body" style={{ borderRadius: '0px' }}>
									<div >
									
										<label id="label" style={{ fontWeight: "500", fontSize: '16px',width:'235px' }}>  
											Campaign for Creatives Review :
										</label>
										</div>
										
									<div class="row">
									<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style={{paddingLeft:"1px"}}>
										<input
											type="text"
											class="form-control"
											className="inputstylesearch"
											name="search"
											placeholder="Search Campaign"
											style={{
												backgroundImage: "url(searchIcon.png)",
												backgroundPosition: "15px 6px",
												backgroundSize: "15px 15px",
												backgroundRepeat: "no-repeat",
												width: "280px",
												height: "28px",
												fontSize: "12px",
												marginLeft: "10px",
												border: "1px solid #CCCCCC",
												padding: "12px 10px 15px 36px",
												backgroundColor: "#FFFFFF",
												fontFamily: "sans-serif",
												borderRadius: "2px",
											}}
											onChange={this.handleChangeSearch.bind(this)}
												onPressEnter={this.handleChangeSearch.bind(this)}></input>
										
									</div>
								</div>
									</div>
									<div style={{ height: '345px', overflowY: "scroll" }}>
										{this.state.creativeCampList.length > 0 ?
											<div>

												{
												 this.state.creativeCampList.map((creativeCampList) => (
													<table style={{ borderCollapse: "collapse", width: "100%" }}>
														<tbody>
															{
															this.state.campID == creativeCampList.campID ? // camparison string == number
																<tr style={{ borderBottom: "2px solid #ccc", borderTop: "1px solid #ccc", backgroundColor: '#F1F2F8' }}>
																	<td style={{ height: "39px", borderLeft: "3px solid blue" }}> 	<a
																		style={{ padding: "5px 0px" }}
																		href="getcampId"
																		className="getcampId"
																		id={creativeCampList.campID}
																		onClick={this.handleCampID.bind(this)}>
																		&nbsp;&nbsp;{creativeCampList.campID} -{" "}
																		{creativeCampList.campaignName}{" "}
																	</a></td>
																</tr>
																: 
																<tr style={{ borderBottom: "1px solid #ccc", borderTop: "1px solid #ccc" }}>
																	<td style={{ height: "39px" }}> 	<a
																		style={{ padding: "5px 0px" }}
																		href="getcampId"
																		className="getcampId"
																		id={creativeCampList.campID}
																		onClick={this.handleCampID.bind(this)}>
																		&nbsp;&nbsp;&nbsp;{creativeCampList.campID} -{" "}
																		{creativeCampList.campaignName}{" "}
																	</a></td>
																</tr>}
														</tbody>
													</table>
												
												 ))
												}
											</div>
											: (
												<center>
													{this.state.searchData.length > 0 ?
														<label
															style={{
																color: "red",
																fontSize: "medium",
																marginTop: "34px",
															}}>
															No Data Exist
															</label>
														:
														<label></label>}
												</center>
											)}
									</div>

								</div>
							</div>
						</div>
                        <div class="col-lg-9" style={{ paddingRight: "0px" }}>  

							<div style={{ border: '1px solid gray', backgroundColor: '#ffff' }}>
								<div class="card-body">
								<hr />
						{this.state.campaignDetails.map((campaignDetails, i) => {
							return (
								<div class="row">
									{this.state.lpReviewPendingListCampIDOnNextPage.map(
                        (lpReviewPendingListCampIDOnNextPage) => (
									<div class="col-lg-3">
										<label id="label" className="CampIDPub" style={{ fontWeight: "400" }}>	{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}
											Camp ID :
										</label>
										<label style={{ fontWeight: "400" }}>
											{" "}
											{lpReviewPendingListCampIDOnNextPage.campID}
										</label>
									</div>
						           ))}
						
									{this.state.lpReviewPendingListCampIDOnNextPage.map(
                                      (lpReviewPendingListCampIDOnNextPage) => (
							        <div class="col-lg-4">
										<label id="label" className="CampNamePub" style={{ fontWeight: "400" }}>	{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}
											Camp Name :
										</label>
										<label style={{ fontWeight: "400" }}>
											{" "}
											{lpReviewPendingListCampIDOnNextPage.campaignName}
										</label>
									</div>
						              ))}
						             
									{this.state.lpReviewPendingListCampIDOnNextPage.map(
                        (lpReviewPendingListCampIDOnNextPage) => (
									<div class="col-lg-3">
										<label id="label" className="StartDatePub" style={{ fontWeight: "400" }}>	{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}
											Start Date :
										</label>
										<label style={{ fontWeight: "400" }}>
										{" "}
											{lpReviewPendingListCampIDOnNextPage.startDate}
										</label>
									</div>
									)
									)}
									{this.state.lpReviewPendingListCampIDOnNextPage.map(
                        (lpReviewPendingListCampIDOnNextPage) => (
									<div class="col-lg-2">
										<label id="label" className="EndDatePub" style={{ fontWeight: "400" }}>		{/*chaitanya-task-4107-Publisherside- Review - Creatives review*/}
											End Date :
										</label>
										<label style={{ fontWeight: "400" }}>
											{lpReviewPendingListCampIDOnNextPage.endDate}
										</label>
									</div>
						)
									)}
								</div>
							);
						})}
						
						<div class="col-lg-12" style={{ display: this.state.alertDisplay }}>
							<div
								style={{
									fontSize: "18px",
									color: "green",
									textAlign: "center",
								}}>
								{this.state.newsuccess}
							</div>
							<div
								style={{ fontSize: "18px", color: "red", textAlign: "center" }}>
								{this.state.failure}
							</div>
						</div>
						<div class="table-responsive">
							{this.state.tableStatus == "true" ? (
								<table class="table table-bordered table-striped">
									<thead>
										<tr class="info">
											<th class="table-header" style={{ width: "23%" }}>
												Assets
											</th>
											{/* //Nilesh-4242-Removing console warnings */}
											{this.state.marketingChannel === "Email/Telemarketing" ||
											this.state.marketingChannel === "EmailTelemarketing" ||
											this.state.marketingChannel === "Email" ? (
												<th class="table-header" style={{ width: "25%" }}>
													Landing Page
												</th>
											) : (
												""
											)}
											
											{/* Nilesh-4242-Removing console warnings */}
											{this.state.marketingChannel === "Email/Telemarketing" ||
											this.state.marketingChannel === "EmailTelemarketing" ||
											this.state.marketingChannel === "Email" ? (
												<th class="table-header" style={{ width: "25%" }}>
													Newsletter
												</th>
											) : (
												""
											)}
										    {/* Nilesh-4242-Removing console warnings */}
											{this.state.marketingChannel === "Email/Telemarketing" ||
											this.state.marketingChannel === "EmailTelemarketing" ||
											this.state.marketingChannel === "TeleMarketing" ? (
												<th class="table-header" style={{ width: "30%" }}>
													Call Script
												</th>
											) : (
												""
											)}
										</tr>
									</thead>
                                   

									<tbody>
									 
											{this.state.lpReviewPendingList.map( 
											(lpReviewPendingList, i) => {
												return (
													<tr>
														<input
															type="hidden"
															id="supportDocID"
															value={lpReviewPendingList.supportDocID}
															onChange={this.handleChange}
															name="supportDocID"
														/>
																						  {this.state.LP_POC_CS_SubmissionPopUP.map(
																				(
																					LP_POC_CS_SubmissionPopUP
																				) => (
                                                                                           <input
																							type="hidden"
																							id="campID"
																							name="campID"
																							value={
																								LP_POC_CS_SubmissionPopUP.campID
																							}
																						/>
																						)
																						)}
																					
																				
														{lpReviewPendingList.assetStatus ===
														"Removed" ? (
															<span
																style={{
																	color: "red",
																	textDecoration: "line-through",
																}}>
																<td>{lpReviewPendingList.suppDocName}</td>
															</span>
														) : (
															<td>{lpReviewPendingList.suppDocName}</td>
														)}
														 {/* Nilesh-4242-Removing console warnings */}
														 {this.state.marketingChannel ===
															"Email/Telemarketing" ||
														this.state.marketingChannel ===
															"EmailTelemarketing" ||
														this.state.marketingChannel === "Email" ? (
															<td>
																{lpReviewPendingList.assetStatus ===
																"Removed" ? (
																	<span
																		style={{
																			color: "red",
																			textDecoration: "line-through",
																		}}>
																		<input
																			type="textbox"
																			style={{
																				width: "-webkit-fill-available",
																			}}
																			id="lpLink"
																			name="lpLink"
																			defaultValue={
																				lpReviewPendingList.lpLink
																			}
																			class="form-control"
																			onChange={this.handleChangeDynamicLpInsert.bind(
																				this,
																				i
																			)}
																			disabled></input>
																	</span>
																) : lpReviewPendingList.lpStatus ===
																		"Approved" ||
																		lpReviewPendingList.lpStatus ===
																		"ClientApproved" ||
																		lpReviewPendingList.lpStatus ===
																		"ClientReviewPending" ? (
																	<input
																		type="textbox"
																		style={{ width: "-webkit-fill-available" }}
																		id={"lpLink" + i}
																		name="lpLink"
																		defaultValue={
																			lpReviewPendingList.lpLink
																		}
																		onChange={this.handleChangeDynamicLpInsert.bind(
																			this,
																			i
																		)}
																		disabled></input>
																) 
																// Nilesh-4242-Removing console warnings
																: lpReviewPendingList.sslFeedback !== 
																		"" &&
																		lpReviewPendingList.lpLink !== "" &&
																		lpReviewPendingList.lpStatus ===
																
																		"pendingSubmission" ? (
																	<input
																		type="textbox"
																		style={{
																			width: "-webkit-fill-available",
																			border: "1px solid red",
																		}}
																		id={"lpLink" + i}
																		name="lpLink"
																		defaultValue={
																			lpReviewPendingList.lpLink
																		}
																		onChange={this.handleChangeDynamicLpInsert.bind(
																			this,
																			i
																		)}></input>
																) : (
																	<input
																		type="textbox"
																		style={{ width: "-webkit-fill-available" }}
																		id={"lpLink" + i}
																		name="lpLink"
																		defaultValue={
																			lpReviewPendingList.lpLink
																		}
																		onChange={this.handleChangeDynamicLpInsert.bind(
																			this,
																			i
																		)}></input>
																)}

																<br />
																
											                    {/* Nilesh-4242-Removing console warnings */}
																{lpReviewPendingList.sslFeedback !== "" &&
																lpReviewPendingList.lpLink !== "" &&
																lpReviewPendingList.lpStatus ===
																	"pendingSubmission" ? (
																	<label
																		id={"feedback" + i}
																		style={{ color: "red" }}>
																		{lpReviewPendingList.sslFeedback}
																	</label>
																) : (
																	<label id={"feedback" + i}></label>
																)}
																<br />
																<label id="label" className="LPStatusPub" style={{ fontWeight: "400" }}>												
																	Status:&nbsp;
																</label>
																{/* Nilesh-4242-Removing console warnings */}
																{
																	lpReviewPendingList.lpStatus === "" ||
																	lpReviewPendingList.lpStatus == null ? (
																		"pendingSubmission"
																	) : lpReviewPendingList.lpStatus ===
																			"AdvertiserReviewPending" ||
																			lpReviewPendingList.lpStatus ===
																			"AdvertiserReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AdvertiserReviewPending
																		</label>
																	) : lpReviewPendingList.lpStatus ===
																			"AdvertiserApproved" ||
																			lpReviewPendingList.lpStatus ===
																			"AdvertiserApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : lpReviewPendingList.lpStatus ===
																			"AgencyReviewPending" ||
																			lpReviewPendingList.lpStatus ===
																			"AgencyReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AgencyReviewPending
																		</label>
																	) : lpReviewPendingList.lpStatus ===
																			"AgencyApproved" ||
																			lpReviewPendingList.lpStatus ===
																			"AgencyApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : (
																		<label style={{ fontWeight: "400" }}>
																			Rejected
																		</label>
																	)
																}
																<br />
																{/* Nilesh-4242-Removing console warnings */}
																{lpReviewPendingList.lpFeedback === "" ||
																lpReviewPendingList.lpFeedback == null ? (
																	""
																) : (
																	<div>
																		<label
																			id="label"	className="LPStatusPub"	
																			style={{ fontWeight: "400" }}>
																			Feedback:
																		</label>
																		{lpReviewPendingList.lpFeedback}
																		<br />
																	</div>
																)}
																{/* {alert("====>" + JSON.stringify(lpReviewPendingList.lpStatus))} */}
																{/* Nilesh-4242-Removing console warnings */}
																{lpReviewPendingList.lpStatus ===
																	"Rejected" ||
																	lpReviewPendingList.lpStatus ===
																	"ClientRejected" ||
																	lpReviewPendingList.pocStatus ===
																	"AdvertiserRejected" 												
																	? (
																	<div>
																		<label
																			id="label" className="LPStatusPub"	
																			style={{ fontWeight: "400" }}>
																			Feedback File Name:&nbsp;
																		</label>
																		<a
																			href="#/"  //Nilesh-4242-Removing console warnings
																			supportDocID={
																				lpReviewPendingList.supportDocID
																			}
																			lpFeedbackFileName={
																				lpReviewPendingList.lpFeedbackFileName
																			}
																			onClick={this.handleLPFileDownload.bind(
																				this
																			)}>
																			{
																				lpReviewPendingList.lpFeedbackFileName
																			}
																		</a>
																	</div>
																) : (
																	""
																)}
															</td>
														) : (
															""
														)} 
												   {/* Nilesh-4242-Removing console warnings */}
													  {this.state.marketingChannel ===
															"Email/Telemarketing" ||
														this.state.marketingChannel ===
															"EmailTelemarketing" ||
														this.state.marketingChannel === "Email" ? (
															<td>
																{lpReviewPendingList.assetStatus ===
																"Removed" ? (
																	<span
																		style={{
																			color: "red",
																			textDecoration: "line-through",
																		}}>
																		{lpReviewPendingList.pocFileName}{" "}
																	</span>
																) : (
																	<div>
																		<label class="btn chooseFile">
																			Choose a File
																			{lpReviewPendingList.pocStatus ===
																				"Approved" ||
																				lpReviewPendingList.pocStatus ===
																				"ClientApproved" ||
																				lpReviewPendingList.pocStatus ===
																				"ClientReviewPending" ? (
																				<input
																					style={{ display: "none" }}
																					type="file"
																					id={
																						lpReviewPendingList.supportDocID
																					}
																					name="uploadPOC"
																					disabled
																				/>
																			) : (
																				<input
																					style={{ display: "none" }}
																					type="file"
																					id={
																						lpReviewPendingList.supportDocID
																					}
																					name="uploadPOC"
																					onChange={this.handleChangeUploadPOCFile.bind(
																						this,
																						i
																					)}
																				/>
																			)}
																		</label>{" "}
																		<br />
																		<label
																			id={
																				"pocList" +
																				lpReviewPendingList.supportDocID
																			}>
																			{lpReviewPendingList.pocFileName ? (
																				<label
																					id={
																						"fileName" +
																						lpReviewPendingList.supportDocID
																					}>
																					1 File Selected
																				</label>
																			) : (
																				"No File Chosen"
																			)}
																		</label>
																		<div style={{ color: "red" }}>
																			{
																				this.state.pocerror[
																					"pocerr:" +
																					lpReviewPendingList.supportDocID
																				]
																			}
																		</div>
																		<br />
																		<div
																			id={
																				"abmList" +
																				lpReviewPendingList.supportDocID
																			}
																			className="word-wrap"></div>
																		{lpReviewPendingList.pocFileName ? (
																			<div
																				id={
																					"fileName" +
																					lpReviewPendingList.supportDocID
																				}>                                                                                                                      
																				<a
																					href="#/"  //Nilesh-4242-Removing console warnings
																					data-fileid={
																						lpReviewPendingList.pocFileName
																					}
																					class="removeFilePOC">
																					{lpReviewPendingList.pocStatus ===
																						"Approved" ||
																						lpReviewPendingList.pocStatus ===
																						"ClientApproved" ||
																						lpReviewPendingList.pocStatus ===
																						"ClientReviewPending" ? (
																						""
																					) : (
																						<i
																							class="fa fa-times"
																							onClick={this.deletePOCFile}
																							id={
																								lpReviewPendingList.supportDocID
																							}
																							name={
																								lpReviewPendingList.pocFileName
																							}
																						/>
																					)}
																				</a>{" "}
																				{lpReviewPendingList.pocFileName
																					? lpReviewPendingList.pocFileName
																					: ""}
																			</div>
																		) : (
																			""
																		)}
																		<div
																			id={
																				"pocFileMessage" +
																				lpReviewPendingList.supportDocID
																			}
																			style={{ color: "green" }}></div>
																	</div>
																)}
																<br />
																<label id="label" className="NewsletterPub" style={{ fontWeight: "400" }}>	
																	Status:&nbsp;
																</label>
																
																{/* Nilesh-4242-Removing console warnings */}
																{
																	lpReviewPendingList.pocStatus === "" ||
																	lpReviewPendingList.pocStatus ==
																		null ? (
																		"pendingSubmission"
																	) : lpReviewPendingList.pocStatus ===
																			"AdvertiserReviewPending" ||
																			lpReviewPendingList.pocStatus ===
																			"AdvertiserReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AdvertiserReviewPending
																		</label>
																	) : lpReviewPendingList.pocStatus ===
																			"AdvertiserApproved" ||
																			lpReviewPendingList.pocStatus ===
																			"AdvertiserApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : lpReviewPendingList.pocStatus ===
																			"AgencyReviewPending" ||
																			lpReviewPendingList.pocStatus ===
																			"AgencyReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AgencyReviewPending
																		</label>
																	) : lpReviewPendingList.pocStatus ===
																			"AgencyApproved" ||
																			lpReviewPendingList.pocStatus ===
																			"AgencyApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : (
																		<label style={{ fontWeight: "400" }}>
																			Rejected 
																		</label>
																	)
																}

																<br />
																{/* Nilesh-4242-Removing console warnings */}
																{lpReviewPendingList.pocFeedback === "" ||
																lpReviewPendingList.pocFeedback ==
																	null ? (
																	""
																) : (
																	<div>
																		<label
																			id="label"	className="NewsletterPub"		
																			style={{ fontWeight: "400" }}>
																			Feedback:
																		</label>
																		{lpReviewPendingList.pocFeedback}
																	</div>
																)}
																<br />
																{/* Nilesh-4242-Removing console warnings */}
																{lpReviewPendingList.pocStatus ===
																	"Rejected" ||
																	lpReviewPendingList.pocStatus ===
																	"ClientRejected" ||
																	lpReviewPendingList.pocStatus ===
																	"AdvertiserRejected" 												
																	? (
																	<div>
																		<label
																			id="label"	className="NewsletterPub"		
																			style={{ fontWeight: "400" }}>
																			Feedback File Name:&nbsp;
																		</label>
																		<a
																			href="#/"  //Nilesh-4242-Removing console warnings
																			supportDocID={
																				lpReviewPendingList.supportDocID
																			}
																			pocFeedbackFileName={
																				lpReviewPendingList.pocFeedbackFileName
																			}
																			onClick={this.handlePOCFeedbackFileDownload.bind(
																				this
																			)}>
																			{
																				lpReviewPendingList.pocFeedbackFileName
																			}
																		</a>
																	</div>
																) : (
																	""
																)}
															</td>
														) : (
															""
														)}  
														 {/* Nilesh-4242-Removing console warnings */}
														  {this.state.marketingChannel ===
															"Email/Telemarketing" ||
														this.state.marketingChannel ===
															"EmailTelemarketing" ||
														this.state.marketingChannel === "TeleMarketing" ? (
															<td>
																{lpReviewPendingList.assetStatus ===
																"Removed" ? (
																	<span
																		style={{
																			color: "red",
																			textDecoration: "line-through",
																		}}>
																		{lpReviewPendingList.csFileName}{" "}
																	</span>
																) : (
																	<div>
																		<label class="btn chooseFile">
																			Choose a File
																			{lpReviewPendingList.csStatus ===
																				"Approved" ||
																				lpReviewPendingList.csStatus ===
																				"ClientApproved" ||
																				lpReviewPendingList.csStatus ===
																				"ClientReviewPending" ? (
																				<input
																					style={{ display: "none" }}
																					type="file"
																					id={
																						lpReviewPendingList.supportDocID
																					}
																					name="uploadCS"
																					disabled
																				/>
																			) : (
																				<input
																					style={{ display: "none" }}
																					type="file"
																					id={
																						lpReviewPendingList.supportDocID
																					}
																					name="uploadCS"
																					onChange={this.handleChangeUploadCSFile.bind(
																						this,
																						i
																					)}
																				/>
																			)}
																		</label>{" "}
																		<br />
																		<label
																			id={
																				"csList" +
																				lpReviewPendingList.supportDocID
																			}>
																			{lpReviewPendingList.csFileName ? (
																				<label
																					id={
																						"csFileName" +
																						lpReviewPendingList.supportDocID
																					}>
																					1 File Selected
																				</label>
																			) : (
																				"No File Chosen"
																			)}
																		</label>
																		<div style={{ color: "red" }}>
																			{
																				this.state.cserror[
																					"cserr:" +
																					lpReviewPendingList.supportDocID
																				]
																			}
																		</div>
																		<br />
																		<div
																			id={
																				"csAbmList" +
																				lpReviewPendingList.supportDocID
																			}
																			className="word-wrap"></div>
																		{lpReviewPendingList.csFileName ? (
																			<div
																				id={
																					"csFileName" +
																					lpReviewPendingList.supportDocID
																				}>
																				<a
																					href="#/"  //Nilesh-4242-Removing console warnings
																					data-fileid={
																						lpReviewPendingList.csFileName
																					}
																					class="removeFileCS">
																					{lpReviewPendingList.csStatus ===
																						"Approved" ||
																						lpReviewPendingList.csStatus ===
																						"ClientApproved" ||
																						lpReviewPendingList.csStatus ===
																						"ClientReviewPending" ? (
																						""
																					) : (
																						<i
																							class="fa fa-times"
																							onClick={this.deleteCSFile}
																							id={
																								lpReviewPendingList.supportDocID
																							}
																							name={
																								lpReviewPendingList.csFileName
																							}
																						/>
																					)}

																				</a>{" "}
																				{lpReviewPendingList.csFileName
																					? lpReviewPendingList.csFileName
																					: ""}
																			</div>
																		) : (
																			""
																		)}
																		<div
																			id={
																				"csFileMessage" +
																				lpReviewPendingList.supportDocID
																			}
																			style={{ color: "green" }}></div>
																	</div>
																)}
																<br />
																<label id="label" className="CSStatusPub" style={{ fontWeight: "400" }}>		
																	CSStatus:&nbsp;
																</label>
																{/* Nilesh-4242-Removing console warnings */}
																{
																	lpReviewPendingList.csStatus === "" ||
																	lpReviewPendingList.csStatus == null ? (
																		"pendingSubmission"
																	) : lpReviewPendingList.csStatus ===
																			"AdvertiserReviewPending" ||
																			lpReviewPendingList.csStatus ===
																			"AdvertiserReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AdvertiserReviewPending
																		</label>
																	) : lpReviewPendingList.csStatus ===
																			"AdvertiserApproved" ||
																			lpReviewPendingList.csStatus ===
																			"AdvertiserApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : lpReviewPendingList.csStatus ===
																			"AgencyReviewPending" ||
																			lpReviewPendingList.csStatus ===
																			"AgencyReviewPending" ? (
																		<label style={{ fontWeight: "400" }}>
																			AgencyReviewPending
																		</label>
																	) : lpReviewPendingList.csStatus ===
																			"AgencyApproved" ||
																			lpReviewPendingList.csStatus ===
																			"AgencyApproved" ? (
																		<label style={{ fontWeight: "400" }}>
																			Approved
																		</label>
																	) : (
																		<label style={{ fontWeight: "400" }}>
																			Rejected
																		</label>
																	)
																}
																<br />
																{/* Nilesh-4242-Removing console warnings */}
																{lpReviewPendingList.csFeedback === "" ||
																lpReviewPendingList.csFeedback == null ||
																lpReviewPendingList.csFeedback ===
																	undefined ? (
																	""
																) : (
																	<div>
																		<label
																			id="label"	className="CSStatusPub"		
																			style={{ fontWeight: "400" }}>
																			Feedback:
																		</label>
																		{lpReviewPendingList.csFeedback}
																	</div>
																)}
																<br />
																{/* Nilesh-4242-Removing console warnings */}
																{lpReviewPendingList.csStatus ===
																	"Rejected" ||
																	lpReviewPendingList.csStatus ===
																	"ClientRejected" ||
																	lpReviewPendingList.csStatus ===
																	"AdvertiserRejected"  ? (
																	<div>
																		<label
																			id="label"	className="CSStatusPub"		
																			style={{ fontWeight: "400" }}>
																			Feedback File Name:&nbsp;
																		</label>
																		<a
																			href="#/"  //Nilesh-4242-Removing console warnings
																			supportDocID={
																				lpReviewPendingList.supportDocID
																			}
																			csFeedbackFileName={
																				lpReviewPendingList.csFeedbackFileName
																			}
																			onClick={this.handleCSFeedbackFileDownload.bind(
																				this
																			)}>
																			{
																				lpReviewPendingList.csFeedbackFileName
																			}
																		</a>
																	</div>
																) : (
																	""
																)}
															</td>
														) : (
															""
														)}  
													</tr>
												);
											}
										)}
										 
									</tbody>
								</table>
							) : (
								""
							)}
						</div>
						<div style={{ textAlign: "right" }}>
							{" "}
							<button class="btn add-button" type="HTMLsubmit">
								Submit
							</button>
						</div>

								</div>
								</div>
								</div>
								</div>

						
					</div>
				</form>
				<Footer />
			</div>
		);
	}
}

PublisherCreativesReview.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(PublisherCreativesReview)
);
