/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Somnath Keswad
@Creation Date:14/01/2020
@Description:UI for  Publisher wise campaign report  under Agency view
*/
import React from "react";
import Navigation from "../layouts/navPage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import * as $ from "jquery";
import { DatePicker } from "antd";
import Footer from "../layouts/footer";
import { MDBRow } from "mdbreact";
import moment from "moment";
// import { css } from "emotion"; //Nilesh-2833-Removing console warnings
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import { Tooltip } from "antd";
import "./agencyInvoicesList.css";
const { MonthPicker } = DatePicker;
const Swal = require("sweetalert2");
// const tableCSS = css({  //Nilesh-2833-Removing console warnings
// 	backgroundColor: "white",
// 	"& thead > tr > th": {
// 		backgroundColor: "#3863A0",
// 		color: "white",
// 		fontWeight: "500",
// 		// wordWrap:"break-word",
// 		// width:'10%'
// 	},
// 	"& thead > tr": {
// 		borderWidth: "2px",
// 		borderStyle: "solid",
// 	},
// });
const queryString = require("query-string");
class AgencyInvoiceList extends React.Component {
	constructor() {
		super();
		this.state = {
			newsuccess: "",
			invoiceDetail: [],
			startDate: "",
			endDate: "",
			allPublisherList: [],
			finalInvoiceDetail: [],
			pubName: [],
			disableButton: true,
			fields: {
				startDate: "",
				endDate: "",
			},
			errors: {},
			dateerrors: {},
			pID: "",
			invoiceGenerationDate: "",
			btnGenerateDisable: true,
			generateInvoicepID: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.showInvoice = this.showInvoice.bind(this);
		this.submitInvoiceFeedback = this.submitInvoiceFeedback.bind(this);
		this.handleFileDownload = this.handleFileDownload.bind(this);
		this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
		this.reviewFeedbackFile = this.reviewFeedbackFile.bind(this);
	} // end of constrcutor
	publisherHandleChange(e) {
		e.preventDefault(); //Nilesh-2833-Removing console warning
		let fields = this.state;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
		let pID = e.target.value;
		this.setState({ publisherName: pID });
		let publisherName = this.state.publisherName;

		if (!fields["startDate"] || !fields["endDate"]) {
			this.setState({ disableButton: true });
		} else {
			if (
				publisherName === "" ||
				publisherName === undefined ||
				publisherName === "undefined"
			) {
				this.setState({ disableButton: true });
			} else {
				this.setState({ disableButton: false });
			}
		}
	}
	handleChange(e) {
		e.preventDefault(); //Nilesh-2833-Removing console warning
		let fields = this.state;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
		//let startDate = this.state.fields.startDate; //Nilesh-2833-Removing console warnings
		// let endDate = this.state.fields.endDate; //Nilesh-2833-Removing console warnings
		let publisherName = this.state.publisherName;
		if (!fields["startDate"] || !fields["endDate"]) {
			this.setState({ disableButton: true });
		} else {
			if (
				publisherName === "" ||
				publisherName === undefined ||
				publisherName === "undefined"
			) {
				this.setState({ disableButton: true });
			} else {
				this.setState({ disableButton: false });
			}
		}
	} // End of handleChange

	showInvoice(e) {
		e.preventDefault();
		let self = this;
		e.target.className += " was-validated";
		let startDate = self.state.fields.startDate;
		let endDate = self.state.fields.endDate;
		let publisherName = self.state.publisherName;
		let finalInvoiceDetail = [...self.state.finalInvoiceDetail];
		let final = finalInvoiceDetail.filter((a) => {
			return (
				a.createdDate >= startDate &&
				a.createdDate <= endDate &&
				a.pID.toString() === publisherName //Nilesh-2833-Removing console warnings		
			);
		});
		self.setState({ invoiceDetail: final });
		if (self.validateForm() === false) {
			return;
		} else {
			self.setState({ errors: "" });
		}
	} // End of showInvoice

	validateForm() {
		// let IsValid = true; //Nilesh-2833-Removing console warnings
		let fields = this.state.fields;
		let startDate = fields.startDate;
		let endDate = fields.endDate;
		let errors = {};
		let dateerrors = {};
		let formIsValid = true;
		if (!fields["startDate"] || !fields["endDate"]) {
			formIsValid = false;
			errors["commonError"] = <li>Please fill below required fields.</li>;
		}

		if (startDate !== undefined) {
			this.setState({ inputClassSdate: "valid" });
		}

		if (startDate === "") {
			this.setState({ inputClassSdate: "invalid" });
		}
		if (endDate !== undefined) {
			this.setState({ inputClassEdate: "valid" });
		}
		if (endDate === "") {
			this.setState({ inputClassEdate: "invalid" });
		}
		if (Date.parse(endDate) < Date.parse(startDate)) {
			formIsValid = false;
			dateerrors["endDate"] = <li>To date must be greater than from date</li>;
		}

		this.setState({
			errors: errors,
			dateerrors: dateerrors,
			newsuccess: "",
			alertBorder: "1px solid red",
			alertWidth: "1000px",
			alertAlign: "center",
			alertDisplay: "block",
		});

		return formIsValid;
	} // end of validate

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			// const { user } = this.props.auth; //Nilesh-2833-Removing console warnings
			// var agencyID = user.id; //Nilesh-2833-Removing console warnings
			fetch("agencyDashboard/agencyInvoiceInfo" )//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
				.then((res) => res.json())
				.then((invoiceDetail) => {
					this.setState(
						{ invoiceDetail: invoiceDetail, finalInvoiceDetail: invoiceDetail },
						function () {}
					);
				})
				.catch(function (err) {
					console.log(err);
				});
			// Get All Publisher List of current Agency
			fetch("agencyDashboard/publisherForInvoice")//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
				.then((res) => res.json())
				.then((allPublisherList) =>
					this.setState({
						allPublisherList: allPublisherList,
						pubName: allPublisherList,
					})
				)
				.catch(function (err) {
					console.log(err);
				});
		}
	} // end of componentDidMount()

	handleInvoiceFeedback(i, e) {
		e.preventDefault(); //Nilesh-2833-Removing console warning
		const { name, value } = e.target;
		let invoiceDetail = [...this.state.invoiceDetail];
		invoiceDetail[i] = { ...invoiceDetail[i], [name]: value };
		this.setState({ invoiceDetail, feedbackFlag: true });
	} // End of handleInvoiceFeedback

	downloadInvoiceDocument(e) {
		e.preventDefault(); //Nilesh-2833-Removing console warning
		var filename = e.target.id;
		var invoiceID = e.target.getAttribute("invoiceID");
		var ext = filename.split(".").pop();
		this.setState({ extension: ext, filename: filename });
		const { user } = this.props.auth;
		let data1 = { filename: filename, invoiceID: invoiceID, pID: user.id };
		fetch("/publisherDashboard/downloadInvoiceDocument", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data1),
		})
			.then((r) => r.blob())
			.then((downloadFiles) => {
				this.setState({ downloadFiles: downloadFiles });
			})
			.then(this.handleFileDownload);
	} // End of downloadInvoiceDocument

	handleFileDownload(blob) {
		const { user } = this.props.auth;
		let zipFileDownloadName = user.id + "_invoice_documents.zip";
		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/zip",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();
	} // End of handleFileDownload

	uploadFeedbackFile(e) {
		e.preventDefault();
		var invoiceID = e.target.id;
		//Nilesh-2833-Removing console warnings
		if (
			e.target.files[0] === null ||
			e.target.files[0] === undefined ||
			e.target.files[0] === ""
		) {
		} else {
			var feedbackfile = e.target.files[0];
			var fileName = feedbackfile.name;
			var ext = fileName.split(".").pop();
			if (ext !== undefined) {
				ext = ext.toLowerCase();
			}
			if (ext === "xls" || ext === "xlsx" || ext === "csv") {
				var data = new FormData();
				data.append("invoiceID", invoiceID);
				data.append("feedbackFile", feedbackfile);
				fetch("/publisherDashboard/uploadFeedbackFile", {
					method: "POST",
					body: data,
				})
					.then((res) => res.json())
					.then((res) => {
						if (res.success === true) {
							Swal.fire({
								text: "File Uploaded Successfully",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
							});
							var link =
								'<i class="fa fa-times removeFilePOC" style="color:red;" id=' +
								'"' +
								invoiceID +
								'"' +
								"name=" +
								'"' +
								fileName +
								'"' +
								"></i>";
							var HTML = "<table>";
							HTML +=
								"<tr><td>" +
								link +
								"&nbsp;&nbsp;&nbsp;&nbsp;" +
								fileName +
								"</td></tr>";
							HTML += "</table>";
							document.getElementById(
								"feedBackFileDiv2" + invoiceID
							).innerHTML = HTML;
						}
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				Swal.fire({
					type: "error",
					html: "Please upload file of <b>.xlsx/xls</b> format only",
				});
			}
			$(document)
				.off()
				.on("click", ".removeFilePOC", function (e) {
					var id = e.target.id;
					var fileName = e.target.getAttribute("name");
					let data = { id: id, fileName: fileName };
					fetch("publisherDashboard/deleteFeedBackFile", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((res) => {
							if (res.success === true) { //Nilesh-2833-Removing console warnings
								Swal.fire({
									text: "File Deleted Successfully",
									type: "success",
									confirmButtonText: "Ok",
									allowOutsideClick: false,
									preConfirm: () => {
										var nooutput = document.getElementById(
											"feedBackFileDiv2" + id
										);
										nooutput.innerHTML = "No File Choosen";
									},
								});
								return;
							}
						})
						.catch(function (err) {
							console.log(err);
						});
				});
		}
		var invoiceIdForFile = e.target.id;
		this.setState({
			invoiceIdForFile: invoiceIdForFile,
			feedbackfile: feedbackfile,
			feedbackFlag: true,
		});
	} // End of uploadFeedbackFile
	submitInvoiceFeedback(e) {
		e.preventDefault();
		if (this.state.feedbackFlag === false) {//Nilesh-2833-Removing console warnings
			Swal.fire({
				text: "Please Fill Feedback Or Upload a Feedback File ",
				type: "error",
				confirmButtonText: "Ok",
				allowOutsideClick: false,
			});
		} else {
			const { user } = this.props.auth;
			let data = { dynamicArray: this.state.invoiceDetail, user: user };
			fetch("/publisherDashboard/submitFeedback", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.success === true) {
						Swal.fire({
							text: "Feedback Submitted Successfully",
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								window.location.reload();
							},
						});
					}
				})
				.then(function (response) {
					console.log(response);
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}
	/**
	 * @author Somnath Keswad
	 * @param  Description  Get the invoice document from database
	 * @return Description return response in Blob
	 */
	getInvoiceDocument(e) {
		e.preventDefault();
		var invoiceID = e.target.id;
		// const { user } = this.props.auth;//Nilesh-2833-Removing console warnings
		// const agencyID = user//Nilesh-2833-Removing console warnings.id;
		// var month = e.target.getAttribute("month"); //Nilesh-2833-Removing console warnings
		// var year = e.target.getAttribute("year"); //Nilesh-2833-Removing console warnings
		// var type = e.target.getAttribute("type");//Nilesh-2833-Removing console warnings
		var invoiceDocumentName = e.target.getAttribute("invoiceDocumentName");
		var pID = e.target.getAttribute("pID");
		this.setState({ filename: invoiceDocumentName });
		fetch(
			"/agencyDashboard/getInvoiceDocument?invoiceID=" +
				invoiceID			
		)//akash-Bug--4036-4037--4039-4040
				.then((r) => r.blob())
			.then((downloadFiles) =>
				this.setState({ downloadFiles: downloadFiles, pID: pID })
			)
			.then(this.handleFileShowDownload);
	} // End of getInvoiceDocument

	handleFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		let zipFileDownloadName = this.state.pID + "_Invoice_Documents.zip";
		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/zip",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}
	downloadFeedbackFile(e) {
		e.preventDefault(); //Nilesh-4814
		// var invoiceID=e.target.id;
		// const { user } = this.props.auth; //Nilesh-2833-Removing console warnings
		// const agencyID = user.id; //Nilesh-2833-Removing console warning
		var month = e.target.getAttribute("month");
		var year = e.target.getAttribute("year");
		var pID = e.target.getAttribute("pID");
		var feedbackFIleName = e.target.getAttribute("fileName");
		var extension = feedbackFIleName.split(".").pop();
		this.setState({ extension: extension, filename: feedbackFIleName });
		fetch(
			"/agencyDashboard/feedbackFileDownload?pID=" +
				pID +
				"&month=" +
				month +
				"&year=" +
				year +
				"&fileName=" +
				feedbackFIleName
		)//akash-Bug--4036-4037--4039-4040
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.reviewFeedbackFile);
	} // End of downloadFeedbackFile
	/**
	 * @author Somnath Keswad
	 * @param  Description  Get the invoice document from array
	 * @return Description download the file as per extension
	 */
	reviewFeedbackFile(blob) {
		if (this.state.extension === "pdf") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pdfFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/pdf",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			window.open(data, "_blank");
			link.download = pdfFileDownloadName;
			link.click();
		}
		if (
			this.state.extension === "xlsx" ||
			this.state.extension === "xls" ||
			this.state.extension === "csv"
		) {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/vnd.ms-excel",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = fileName;
			link.click();
		}

		if (this.state.extension === "ppt" || this.state.extension === "pptx") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pptFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/vnd.ms-powerpoint",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = pptFileDownloadName;
			link.click();
		}

		if (this.state.extension === "docx") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/msword",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
		if (this.state.extension === "txt") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "text/plain",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
		// Nilesh-2833-Removing console warnings
		if ( 
			this.state.extension.toString() === "png" ||
			this.state.extension.toString() === "jpeg" ||
			this.state.extension.toString() === "jpg" ||
			this.state.extension.toString() === "PNG" ||
			this.state.extension.toString() === "JPEG" ||
			this.state.extension.toString() === "JPG"
		) {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/Image",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
	} /**** End of downloadPOCFie */

	/**
	 * @author Somnath Keswad
	 * @param  Description  update the invoice as per agency
	 * @return Description return agencyReviewed Status
	 */
	invoiceReviewed(e) {
		e.preventDefault(); //Nilesh-2833-Removing console warning
		var invoiceID = e.target.id;
		var pID = e.target.getAttribute("pID");
		var month = e.target.getAttribute("month");
		var year = e.target.getAttribute("year");
		// const { user } = this.props.auth;//Nilesh-2833-Removing console warnings
		Swal.fire({
			text: "This confirm your invoice review is done and ready to share. Do you want to send to Publisher",
			type: "question",
			confirmButtonText: "Yes",
			showCancelButton: true,
			cancelButtonColor: "#d33",
			confirmButtonColor: "#3085d6",
			allowOutsideClick: false,
		}).then((result) => {
			if (result.value === true) {
				// var userName = user.name;//Nilesh-2833-Removing console warnings
				let data = {
					invoiceID: invoiceID,
					pID: pID,
					month: month,
					year: year,
				};//akash-Bug--4036-4037--4039-4040
				fetch("agencyDashboard/agencyInvoiceReview", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						var message = "Invoice Reviewed Successfully !!!";
						Swal.fire({
							text: message,
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								window.location.reload();
							},
						});
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		});
	} // End of invoiceReviewed

	/**
	 * @author Somnath Keswad
	 * @param  Description  update the invoice as per agency
	 * @return Description return agencyReviewed Status
	 */
	paidInvoice(e) {
		e.preventDefault();
		let self = this;
		var invoiceID = e.target.id;
		var pID = e.target.getAttribute("pID");
		// const { user } = this.props.auth;//Nilesh-2833-Removing console warnings
		Swal.fire({
			text: "Are you sure you want to paid Invoice for this Publisher?",
			type: "question",
			confirmButtonText: "Yes",
			showCancelButton: true,
			cancelButtonColor: "#d33",
			confirmButtonColor: "#3085d6",
			allowOutsideClick: false,
		}).then((result) => {
			if (result.value === true) {
				let invoiceData = self.state.invoiceDetail.filter(
					(a) => a.invoiceID === Number(invoiceID) //Nilesh-2833-Removing console warnings // Nilesh-4815- add number method
				);
				let data = {
					invoiceID: invoiceID, 
					pID: pID,
					invoiceData: invoiceData,
				};//akash-Bug--4036-4037--4039-4040
				fetch("agencyDashboard/markAsInvoicePaid", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						var message = "Invoice Paid Successfully !!!";
						Swal.fire({
							text: message,
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								window.location.reload();
							},
						});
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		});
	} // End of paidInvoice
	/**
	 * @author Somnath Keswad
	 * @param  Description  Get the popup for review invoice by agency
	 * @return Description return status as AgencyReview as per user action
	 */
	displayInvoicePopup(e) {
		e.preventDefault(); //Nilesh-2833-Removing console warning
		var invoiceID = e.target.id;
		var pID = e.target.getAttribute("pID");
		this.setState({ invoiceID: invoiceID, pID: pID });
	} // End of displayInvoicePopup
	dynamicHandleChange(i, date, dateString) {
		const name = "invoicePaidDate";
		let invoiceDetail = [...this.state.invoiceDetail];
		invoiceDetail[i] = {
			...invoiceDetail[i],
			[name]: date,
			[name]: dateString,
		};
		this.setState({ invoiceDetail });
	} // End of dynamicHandleChange

	handleMonthYear(date, dateString) {
		this.setState({ invoiceGenerationDate: dateString });
		if (dateString !== "" && this.state.generateInvoicepID !== "") {
			this.setState({ btnGenerateDisable: false });
		}
	}
	generateInvoice() {
		var generateFlag = true;
		this.setState({ btnGenerateDisable: true });
		let invoiceGenerationDate = this.state.invoiceGenerationDate;
		// const { user } = this.props.auth;//Nilesh-2833-Removing console warnings
		let data = {
			invoiceGenerationDate: invoiceGenerationDate,
			
			pID: this.state.generateInvoicepID,
		};
         //akash-Bug--4036-4037--4039-4040 
		if (generateFlag === true) {
			Swal.fire({
				heightAuto: false,
				allowOutsideClick: false,
				onOpen: () => {
					Swal.showLoading(); //Sandeep-task-2863-added loader
					fetch("agencyDashboard/generateNewInvoice", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((res) => {
							var success = res.success;
							var message = res.msg;
							this.setState({ btnGenerateDisable: false });
							if (success === true) {
								Swal.fire({
									text: message,
									type: "success",
									confirmButtonText: "Ok",
									allowOutsideClick: false,
									preConfirm: () => {
										window.location.reload();
									},
								});
							} else {
								Swal.fire({
									text: message,
									type: "error",
									confirmButtonText: "Ok",
									allowOutsideClick: false,
								});
							}
						});
				},
			});
		}
	} // End of generateInvoice
	handleChangePubID(e) {
		e.preventDefault();
		let generateInvoicepID = e.target.value;
		this.setState({ generateInvoicepID });
		if (this.state.invoiceGenerationDate !== "" && generateInvoicepID !== "") {
			this.setState({ btnGenerateDisable: false });
		}
	}
	openInvoiceHistory(e) {
		e.preventDefault();  //Nilesh-2833-Removing console warning
		let pID = e.target.getAttribute("pid");
		let month = e.target.getAttribute("month");
		let year = e.target.getAttribute("year");
		let arr = [{ pID: pID, month: month, year: year }];
		localStorage.setItem("invoiceKey", JSON.stringify(arr));
		window.location.href = "invoiceHistory";
	} // End of openInvoiceHistory

	openInvoiceUpdate(e) {
		e.preventDefault();
		var parsed = queryString.parse(this.props.location.search);
		 let invoiceID = parsed.invoiceID; //Nilesh-2833-Removing console warning
		 invoiceID = e.target.getAttribute("invoiceID");
		
		
		this.props.history.push("/updateInvoice",{invoiceID:invoiceID})  //Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE

	} // End of openInvoiceUpdate
	render() {
		const asterisk = { color: "red" };
		function disabledDate(current) {
			// Can not select days before today and today
			return current && current > moment().endOf("day");
		}

		return (
			<div>
				<Navigation />
				<form
					method=""
					name=""
					onSubmit={this.submitInvoiceFeedback}
					className="needs-validation"
					encType="multipart/form-data"
					noValidate>
					<div
						class="container-fluid"
						style={{ paddingTop: "90px", marginBottom: "60px" }}>
						<div
							class="col"
							align="center"
							style={{ fontSize: "20px", fontWeight: "bold" }}>
							<label id="labelheading" style={{ color: "#056eb8" }}>
								Invoice Report
							</label>
						</div>
						{/* <div className="card-body"> */}
						<div
							style={{
								fontSize: "16px",
								color: "red",
								fontWeight: "600",
								textAlign: "center",
							}}>
							{this.state.errors.commonError}
						</div>
						<br />

						<MDBRow lg="12">
							<div
								class="col-sm-12 col-md-4 col-lg-3"
								style={{ marginBottom: "15px", marginLeft: "15px" }}>
								<MonthPicker
									onChange={this.handleMonthYear.bind(this)}
									disabledDate={disabledDate}
									placeholder="Select Month"
								/>
							</div>
							<div
								class=" col-sm-12 col-md-4 col-lg-3 dot-1"
								style={{ marginBottom: "15px" }}>
								<select
									//required //Nilesh-2833-Removing console warnings
									id="publisherName"
									name="publisherName"
									value={this.state.generateInvoicepID}
									className={this.state.inputClass5}
									onChange={this.handleChangePubID.bind(this)}
									class="form-control"
									required>
									<option value="Select Publisher ID" selected>
										Select Publisher
									</option>
									{this.state.allPublisherList.map((allPublisherList) => (
										<option value={allPublisherList.pID}>
											{allPublisherList.publisherName}
										</option>
									))}
								</select>
							</div>
							<div class="col-sm-12 col-md-4 col-lg-3">
								<button
									type="button"
									class="btn add-button button-1"
									style={{ backgroundColor: "#2196F3", color: "white" }}
									disabled={this.state.btnGenerateDisable}
									onClick={this.generateInvoice.bind(this)}>
									Generate
								</button>
							</div>
						</MDBRow>
						<p></p>
						<MDBRow lg="12">
							<div class="col-sm-12 col-md-4 col-lg-3">
								<div>
									<label
										id="label"
										style={{
											fontSize: "16px",
											fontWeight: "20",
											marginLeft: "15px",
										}}>
										From<span style={asterisk}>*</span>
									</label>
								</div>
								<div>
									<input
										type="date"
										id="startDate"
										style={{ width: "160px", marginLeft: "15px" }}
										name="startDate"
										//min=""  //Nilesh-2833-Removing console warning
										//required //Nilesh-2833-Removing console warnings
										pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
										value={this.state.startDate}
										className={this.state.inputClassSdate}
										onChange={this.handleChange}
										class="form-control"
										min=""
										required
									/>
								</div>
								<div>
									{/*<span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span>*/}
								</div>
								<span style={asterisk}> {this.state.errors.startDate} </span>
							</div>
							<div class="col-sm-12 col-md-5 col-lg-2">
								<div>
									<label
										id="label"
										style={{ fontSize: "16px", marginLeft: "15px" }}>
										To<span style={asterisk}>*</span>
									</label>
									<div>
										<input
											type="date"
											id="endDate"
											style={{ width: "160px", marginLeft: "15px" }}
											name="endDate"
											min=""
											//required // Nilesh-2833-Removing console warnings
											pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
											value={this.state.endDate}
											className={this.state.inputClassEdate}
											onChange={this.handleChange}
											class="form-control"
											required
										/>
									</div>
									{/*<span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span>*/}
								</div>
								<span style={asterisk}> {this.state.dateerrors.endDate} </span>
							</div>

							<div class=" col-lg-1">
								<label></label>
							</div>

							<div class="col-sm-12 col-md-3 col-lg-2">
								<div>
									<label
										id="label"
										style={{ fontSize: "16px", marginLeft: "15px" }}>
										Publisher Name <span style={asterisk}>*</span>
									</label>
								</div>
								<div>
									<select
										//required //Nilesh-2833-Removing console warnings
										id="publisherName"
										name="publisherName"
										style={{ marginLeft: "15px" }}
										className={this.state.inputClass5}
										onChange={this.publisherHandleChange.bind(this)}
										class="form-control"
										required>
										<option
											value="Select Publisher ID"
											disabled="disabled"
											selected>
											Select Publisher
										</option>
										{this.state.pubName.map((pubName) => (
											<option value={pubName.pID} name={pubName.publisherName}>
												{pubName.publisherName}
											</option>
										))}
									</select>
								</div>
								{/* <span style={asterisk}> {this.state.errors.publisherName} </span> */}
							</div>
							<div class=" col-sm-12 col-md-4 col-lg-4">
								<div class=" col-sm-12 col-md-4 col-lg-4">
									<label></label>
								</div>
								&nbsp;&nbsp;
								<button
									class="btn text-uppercase add-button button-2"
									style={{
										backgroundColor: "#2196F3",
										color: "white",
										marginTop: "25px",
									}}
									disabled={this.state.disableButton}
									onClick={this.showInvoice}
									type="submit">
									Search
								</button>
							</div>
						</MDBRow>

						<div
							class=" col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
							style={{ marginTop: "15px" }}>
							<div
								className="card card-signin my-1"
								style={{ overflowY: "scroll", height: "300px" }}>
								<table
									class="table table-bordered "
									style={{
										paddingTop: "80px",
										borderCollapse: "collapse",
										border: "1px solid #D3D3D3",
										overflowX: "hidden",
									}}>
									<thead>
										<tr style={{ height: "70px" }}>
											<th
												style={{
													width: "80px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
												}}>
												Invoice ID
											</th>
											<th
												style={{
													width: "80px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
												}}>
												Publisher ID
											</th>
											<th
												style={{
													width: "100px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
												}}>
												Publisher Name
											</th>
											<th
												style={{
													width: "110px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
												}}>
												Invoice Document(Manual)
											</th>
											<th
												style={{
													width: "110px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
												}}>
												Invoice Document(Automatic)
											</th>
											<th
												style={{
													width: "100px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
												}}>
												Month/year
											</th>
											<th
												style={{
													width: "120px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
												}}>
												Invoice Status
											</th>
											<th
												style={{
													width: "170px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
												}}>
												Feedback
											</th>
											<th
												class="dot-2"
												style={{
													width: "180px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
												}}>
												Invoice Paid Date
											</th>
											<th
												style={{
													width: "100px",
													color: "white",
													backgroundColor: "#124E9C",
													textAlign: "center",
												}}>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{this.state.invoiceDetail.length > 0 ? ( //sunita-task-2437-changed the place of condition as data not shown properly.
											this.state.invoiceDetail.map((invoiceDetail, i) => {
												//sunita-task-2437-before that on wrong place iterated the array .
												return (
													<tr style={{ textAlign: "center", height: "100px" }}>
														<td>{invoiceDetail.invoiceID}</td>
														<td>{invoiceDetail.pID}</td>
														<td>{invoiceDetail.publisherName}</td>

														<td>
															<a
																href="#/" //Nilesh-2833-Removing console warnings
																onClick={this.getInvoiceDocument.bind(this)}
																id={invoiceDetail.invoiceID}
																month={invoiceDetail.month}
																year={invoiceDetail.year}
																pID={invoiceDetail.pID}
																invoiceDocumentName={
																	invoiceDetail.invoiceDocumentName
																}
																type="Manual"
																// invoiceDocumentName={
																// 	invoiceDetail.invoiceDocumentName //Nilesh-2833-Removing console warnings
																// }
																style={{ fontSize: "12px" }}>
																{invoiceDetail.invoiceDocumentName}
															</a>
														</td>
														<td>
															<a
																href="#/" //Nilesh-2833-Removing console warnings
																onClick={this.getInvoiceDocument.bind(this)}
																id={invoiceDetail.invoiceID}
																month={invoiceDetail.month}
																year={invoiceDetail.year}
																pID={invoiceDetail.pID}
																invoiceDocumentName={
																	invoiceDetail.automaticInvoiceDocName
																}
																type="Automatic"
																// invoiceDocumentName={
																// 	invoiceDetail.automaticInvoiceDocName //Nilesh-2833-Removing console warnings
																// }
																style={{ fontSize: "12px" }}>
																{invoiceDetail.automaticInvoiceDocName}
															</a>
														</td>

														<td>
															{invoiceDetail.month}{" "}
															{invoiceDetail.year}
														</td>
														<td>{invoiceDetail.status}</td>
														<td>
															<div class="text-center">
																<div class="word-wrap">
																	<span
																		style={{
																			fontSize: "12px",
																			marginLeft: "-4px",
																			wordWrap: "break-word",
																			height: "5%",
																		}}>
																		{invoiceDetail.feedback}
																	</span>
																	<br />
																</div>
																<div
																	class="dot word-wrap"
																	style={{
																		paddingBottom: "20px",
																		width: "140px",
																	}}>
																	<span
																		style={{
																			fontSize: "12px",
																			textAlign: "center",
																			marginLeft: "40px",
																		}}>
																		<label id="label">Feedback File</label>:
																		<br />
																		<a
																			href="#/" //Nilesh-2833-Removing console warnings
																			onClick={this.downloadFeedbackFile.bind(
																				this
																			)}
																			invoiceid={invoiceDetail.invoiceID}
																			month={invoiceDetail.month}
																			year={invoiceDetail.year}
																			pID={invoiceDetail.pID}
																			fileName={invoiceDetail.feedbackFIleName}>
																			{invoiceDetail.feedbackFIleName}
																		</a>
																	</span>
																</div>
															</div>
														</td>
														<td>
															{/* defaultValue={moment(invoiceDetail.invoicePaidDate)} */}
															{/* onChange={this.dynamicHandleChange.bind(this)} */}
															<div>
																{/* Sandeep-task-2863-added code to change placeholder value from invalid date to select date */}

																{invoiceDetail.status === "InvoicePaid" ? (
																	<DatePicker
																		showTime
																		placeholder="Select Date"
																		id="invoicePaidDate"
																		name="invoicePaidDate"
																		defaultValue={
																			invoiceDetail.invoicePaidDate
																				? moment(invoiceDetail.invoicePaidDate)
																				: null
																		} //karan-task-3763-pat runtime error
																		className="form-control"
																		disabled
																	/>
																) : (
																	<DatePicker
																		showTime
																		placeholder="Default values"
																		id="invoicePaidDate"
																		name="invoicePaidDate"
																		defaultValue={
																			invoiceDetail.invoicePaidDate
																				? moment(invoiceDetail.invoicePaidDate)
																				: null
																		} //karan-task-3763-pat runtime error
																		onChange={(e, dateString) => {
																			this.dynamicHandleChange(
																				i,
																				e,
																				dateString
																			);
																		}}
																		className="form-control"
																	/>
																)}
															</div>
														</td>
														<td
															style={{ marginTop: "15px", marginLeft: "15px" }}>
															<div
																class="d-inline-block"
																style={{
																	width: "84px",
																	wordWrap: "break-word",
																	height: "5%",
																}}>
																<span>
																	<a href="#/" //Nilesh-2833-Removing console warnings
																	style={{ fontSize: "12px" }}>
																		<Tooltip
																			placement="top"
																			title="Invoice History">
																			<img
																				id="invoice-His"
																				style={{
																					padding: "2px",
																					marginTop: "29px",
																					marginRight: "24px",
																					marginLeft: "-25px",
																				}}
																				className="float-right"
																				src="invoiceHistory.png"
																				width="28"
																				height="28"
																				pid={invoiceDetail.pID}
																				month={invoiceDetail.month}
																				year={invoiceDetail.year}
																				onClick={this.openInvoiceHistory.bind(
																					this
																				)}
																				alt="" //Nilesh-2833-Removing console warning
																			/>
																		</Tooltip>
																	</a>
																	{invoiceDetail.status === "InvoicePaid" ? (
																		<a href="#/" //Nilesh-2833-Removing console warnings
																		style={{ fontSize: "12px" }}>
																			<Tooltip
																				placement="top"
																				title="Invoice Update"
																				overlayStyle={{
																					color: "white",
																					backgroundColor: "orange",
																				}}>
																				<img
																					style={{ padding: "2px" }}
																					className="float-right"
																					src="Update_Invoice_Disable.png"
																					width="28"
																					height="28"
																					invoiceid={invoiceDetail.invoiceID}
																					alt=""//Nilesh-2833-Removing console warnings
																				/>
																			</Tooltip>
																		</a>
																	) : (
																		<a href="#/" //Nilesh-2833-Removing console warnings 
																		style={{ fontSize: "12px" }}>
																			<Tooltip
																				placement="top"
																				title="Invoice Update">
																				<img
																					style={{ padding: "2px" }}
																					className="float-right"
																					src="Update_Invoice.png"
																					width="28"
																					height="28"
																					invoiceid={invoiceDetail.invoiceID}
																					onClick={this.openInvoiceUpdate.bind(
																						this
																					)}
																					alt="" //Nilesh-2833-Removing console warning
																				/>
																			</Tooltip>
																		</a>
																	)}
																	{invoiceDetail.date}

																	{invoiceDetail.status ===
																	"AgencyInvoicePending" ? (
																		<a
																			href="#/" //Nilesh-2833-Removing console warnings
																			data-toggle="modal"
																			data-target="#myModal"
																			data-backdrop="static"
																			data-keyboard="false"
																			onClick={this.displayInvoicePopup.bind(
																				this
																			)}
																			id={invoiceDetail.invoiceID}
																			month={invoiceDetail.month}
																			year={invoiceDetail.year}
																			pID={invoiceDetail.pID}
																			style={{ fontSize: "12px" }}>
																			<Tooltip
																				placement="top"
																				title="Confirm Invoice">
																				<img
																					style={{ padding: "1px" }}
																					className="float-left"
																					src="Review.png"
																					width="28"
																					height="28"
																					id={invoiceDetail.invoiceID}
																					month={invoiceDetail.month}
																					year={invoiceDetail.year}
																					pID={invoiceDetail.pID}
																					type="Automatic"
																					onClick={this.invoiceReviewed.bind(
																						this
																					)}
																					alt="" //Nilesh-2833-Removing console warning
																				/>
																			</Tooltip>
																		</a>
																	) : (
																		<Tooltip
																			placement="top"
																			title="Invoice confirmed"
																			overlayStyle={{
																				color: "white",
																				backgroundColor: "orange",
																			}}>
																			<img
																				style={{ padding: "1px" }}
																				className="float-left"
																				src="Reviewdisable.png"
																				width="28"
																				height="28"
																				alt="" //Nilesh-2833-Removing console warning
																			/>
																		</Tooltip>
																	)}
																	{invoiceDetail.status ===
																		"AgencyInvoicePending" ||
																	invoiceDetail.status === "InvoicePaid" ? (
																		<Tooltip
																			placement="top"
																			title="Invoice Paid"
																			overlayStyle={{
																				color: "white",
																				backgroundColor: "orange",
																			}}>
																			<img
																				style={{
																					padding: "1px",
																					marginLeft: "0px",
																				}}
																				class="float-left"
																				src="DisablePaidInvoice.png"
																				width="28"
																				height="28"
																				alt="" //Nilesh-2833-Removing console warning
																			/>
																		</Tooltip>
																	) : (invoiceDetail.invoicePaidDate !== null ||
																			invoiceDetail.invoicePaidDate !==
																				undefined) &&
																	  invoiceDetail.lastUpdated <=
																			invoiceDetail.invoicePaidDate ? (
																		<a
																			href="#/" //Nilesh-2833-Removing console warnings
																			data-toggle="modal"
																			data-target="#myModal"
																			data-backdrop="static"
																			data-keyboard="false"
																			style={{ fontSize: "12px" }}>
																			<Tooltip
																				placement="top"
																				title="Confirm Paid Invoice">
																				<img
																					style={{
																						padding: "1px",
																						marginRight: "28px",
																					}}
																					className="float-right"
																					src="paidInvoice.png"
																					width="28"
																					height="28"
																					id={invoiceDetail.invoiceID}
																					month={invoiceDetail.month}
																					pID={invoiceDetail.pID}
																					disabled={this.state.disableTooltip}
																					onClick={this.paidInvoice.bind(this)}
																					alt="" //Nilesh-2833-Removing console warning
																				/>
																			</Tooltip>
																		</a>
																	) : (
																		<Tooltip
																			placement="top"
																			title="Invoice Paid"
																			overlayStyle={{
																				color: "white",
																				backgroundColor: "orange",
																			}}>
																			<img
																				style={{
																					padding: "1px",
																					marginRight: "28px",
																				}}
																				className="float-right"
																				src="DisablePaidInvoice.png"
																				width="28"
																				height="28"
																				alt="" //Nilesh-2833-Removing console warning
																			/>
																		</Tooltip>
																	)}
																</span>
															</div>
														</td>
													</tr>
												);
											})
										) : (
											<tr>
												{/*sunita-task-2437-when data is not present then it will show no data exist*/}
												<td
													colSpan="10"
													style={{
														color: "gray",
														fontSize: "medium",
														textAlign: "center",
														height: "200px",
														paddingTop: "90px",
													}}>
													{/*sunita-2437-added css for aligement */}
													No Data Exist
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					{/*Container */}
				</form>
				<Footer />
			</div>
		);
	} // end of renders
} // end of class

AgencyInvoiceList.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(AgencyInvoiceList)
);
