/* Copyright(c) 2021 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @author : rutuja Jagtap
 * @description : task-4722-Add State screen
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import AdminNavpage from "../layouts/adminNavpage";
import Footer from "../layouts/footer";
import "./addCountry.css";
//import Loader from "../campaignUI/Loader";//Rutuja 4796 commented as never used
import { Empty } from "antd";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
const Swal = require("sweetalert2");

class AddState extends React.Component {

    constructor() {
		super();
		this.state = {
            searchedStates: [],
            isSearchDisabled: true,
            countryShortName: "", 
            country: "",
			state: "", 
			city: "", 
            invalidInput: false,
			invalidInput1: false, //4767
            searchedState: "",
            stateShortName:"",
            stateName:"",
            cityInputString:"",
            agencyArray:[],
            stateExists: false,
            cityInputArray: [],
            states:[],  
            isAddDisabled:true,
        
        };
        this.loadingRef = new React.createRef(); 
		this.closeLoadingRef = new React.createRef();
    }

    componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		}
	} // end of componentDidMount function

    /**
	 * @author Rutuja Jagtap
	 * @param  Description Task-4722 - handle <input> field changes
	 * @return Description – sets the state when user changes the input field data
	 */
    handleChangeText = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value }, () => {
			if (this.state.country.length > 0) {
				this.setState({ isSearchDisabled: false });
			} else {
				this.setState({ isSearchDisabled: true });
			}
			if (
                this.state.stateName.length > 0 &&
				this.state.stateShortName.length > 0 &&
				this.state.cityInputString.length > 0 &&
				this.state.country.length > 0 
			) {
				this.setState({ isAddDisabled: false });
			} else {
				this.setState({ isAddDisabled: true });
			}
            if (name === "searchedState") {
				this.setState({
					searchedStates: this.state.states.filter((obj) =>
						obj.toLowerCase().includes(this.state.searchedState.toLowerCase())
					),
				});
			}			
		});
	}; // end of handleChangeText function

/**
	 * @author Rutuja Jagtap
	 * @param  Description Task-4722 - fired on Search button clicked
	 * @return Description – searhes the states from database and module
	 */
    onSearchClick = (e) => {
		e.preventDefault();
		this.setState({ stateExists: false, invalidInput: false }); 
		let { country, state} = this.state; //Rutuja 4796 removed city as never used
        this.setState({stateName:"",
        stateShortName:"",
        cityInputString:[""]})
		country = country.trim();
		//const regx = new RegExp("^[a-zA-Z\\s]+(-[a-zA-Z\\s]+)*([(),_\\/0-9'\"]+[a-zA-Z0-9'\")\\s]+(-[a-zA-Z0-9'\")\\s]+)*)*$");
		const regx = new RegExp("^[0-9('\"]*[a-zA-Z\\s]+(-[a-zA-Z\\s]+)*([0-9(),_\\/&'\"]+[a-zA-Z0-9'\")\\s]+(0-9-[a-zA-Z'\")\\s]+)*)*[A-Za-z0-9'\"]");
		//Rutuja Task - 4767 changed the regx for allowing -_/'"&()0-9"
		if (country !== "") {
			if (regx.test(country)) {
				if (state === "") {
                    this.fetchStatesFromDB(country);
				}
                else if (regx.test(state)) {
					this.fetchStatesFromDB(country, state);
				}  else {
					this.setState({ invalidInput: true });
				}
			} else {
				this.setState({ invalidInput: true });
			}
		}
	}; // end of onSearchClick function

    
	/**
	 * @author Rutuja Jagtap
	 * @param  Description Task-4722 - route to fetch states data from database 
	 * @return Description – communicates with backend and retrieves states list for given country
	 */
    fetchStatesFromDB = (country, state="",city="") => {
		//const start = performance.now(); //Rutuja 4796 commented as never used
		const data = {
			country: country.toLowerCase(),
			state: state !== "" &&state.toLowerCase(),
            city: city !== "" &&city.toLowerCase(),
		};
		const { user } = this.props.auth;
		fetch("/admin/searchCountryStateDetails", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: user.token,
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				setTimeout(() => {
				}, 500);
				if (response.success) {
					if (response.message === "") {
						this.setState({
							states: response.stateArray,
							searchedStates: response.stateArray,
                            searchedState:"",
                            stateName:"",
                            stateShortName:"",
                            cityInputString:"",
							
						});
                        this.getCountry(data);
					}
				} else {
						
					if (
						response.message === "Country Not Found"
					) {
						Swal.fire({
							text: "Invalid Country",
						type: "error",
						allowOutsideClick: false,
					
						}).then((result) => {
							
							this.setState({
								states: [],
								
                                searchedState:"",
								
							});
							
						});
					}
					if (
						
						response.message === "State Not Found" 
					) {
						
						Swal.fire({
							text: "State/province/county not Found. Please Add",
						type: "error",
						allowOutsideClick: false,
					
						}).then((result) => {
							
							this.setState({
								states: [],
								searchedStates: [""],
                                searchedState:"",
                                stateName:"",
                                 stateShortName:"",
                                 cityInputString:"",
								
							});
                            this.getCountry(data);
							
						});
					} else {
						this.setState({
							states: [],
							searchedStates: [],
							invalidInput: false,
							invalidInput1: false, //4767
                            searchedState:"",
                            stateName:"",
                            stateShortName:"",
                            cityInputString:"",
						});
					}
					this.setState({
						states: [],
						invalidInput: false,
						invalidInput1: false, //4767
                        searchedState:"",
					});
				}
			})
			.catch((err) => {
				console.log(err);
				setTimeout(() => {
				}, 500);
			});
	};


    /**
	 * @author Rutujs Jagtap
	 * @param  Description Task-4722 - handles Add button click
	 * @return Description – adds new states and cities data into database
	 */
    onAddClick = (e) => {
		e.preventDefault();
		this.setState({ stateExists: false, invalidInput1: false }); 
	//	const regx = new RegExp("^[a-zA-Z\\s]+(-[a-zA-Z\\s]+)*([(),\\\/]+[a-zA-Z\\s]+(-[a-zA-Z\\s]+)*)*$");
	const regx = new RegExp("^[0-9('\"]*[a-zA-Z\\s]+(-[a-zA-Z\\s]+)*([0-9(),_\\/&'\"]+[a-zA-Z0-9'\")\\s]+(0-9-[a-zA-Z'\")\\s]+)*)*[A-Za-z0-9'\"]");
	//Rutuja Task - 4767 changed the regx for allowing -_/'"&()"

		let {
			country,
			//state, //Rutuja 4796 commented as never used
            //countryShortName,//Rutuja 4796 commented as never used
            stateName,
			stateShortName,
			countryPhoneCode,
			cityInputString,
			countryCode, 
			agencyArray, 
		} = this.state; 
		country = country.trim();
		countryCode = agencyArray[0].countryCode; 
		countryPhoneCode = agencyArray[0].countryPhoneCode;
		//let countryShortName = countryCode.trim();//Rutuja 4796 commented as never used
		 stateShortName = stateShortName.trim();
         stateName = stateName.trim();
		cityInputString = cityInputString.trim();
		let flag = false;
		if (!regx.test(countryCode)) {
			flag = true;
		}
		if (!regx.test(stateShortName)) {
			flag = true;
		}
		if (!regx.test(country)) {
			flag = true;
		}
		if (!regx.test(stateName)) {
			flag = true;
		}
		const regexPhone = new RegExp("^(\\+{0,1}[0-9]+)"); 
		if (!regexPhone.test(countryPhoneCode)) {
			flag = true;
		}
		let cityInputArray = cityInputString.split(/[,\|\n]+/); 
		cityInputArray = cityInputArray
			.map((obj) => obj.trim()) 
			.filter((obj) => obj !== "");
		cityInputArray.map((obj) => {
			if (!regx.test(obj)) {
				flag = true;
			}
		});
		if (flag) {
			this.setState({ invalidInput1: true });  //4767
		} else {
			this.setState({ invalidInput1: false, cityInputArray }, () => {
				this.addStatesToDB(); 
			});
		}
	}; // end of onAddClick function


    /**
	 * @author Rutuja Jagtap
	 * @param  Description Task-3453 - route to add new data to database
	 * @return Description – adds new cities to database
	 */
    addStatesToDB = () => {
        
		const data = {
			country: this.state.country,
			state: this.state.stateName,
			countryShortName: this.state.countryShortName,
			stateShortName: this.state.stateShortName,
			countryPhoneCode: this.state.countryPhoneCode,
			city: this.state.cityInputArray,
		};
		const { user } = this.props.auth;
		fetch("/admin/addCountryStateDetails", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: user.token,
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				if (response.success) {
					Swal.fire({
						text: "Data added successfully.",
						type: "success",
						icon: "success",
						allowOutsideClick: false,
					}).then((result) => {
						
						window.location.reload(); 
					});
				} else {
					if (response.mesg === "This country and State and City already Exists") {
						this.setState({ stateExists: true });
                        Swal.fire({
                            text: "State/province/county already Exists.", //4767
                            type: "error",
                            allowOutsideClick: false,
                        }).then((result) => {
                           
                            window.location.reload(); 
                        });
					} else {
						Swal.fire({
							text: "State/province/county already Exists.", //4767
							icon: "error",
							type: "error",
							allowOutsideClick: false,
						}).then((result) => {
                            this.setState({
                                    stateName:"",
                                    stateShortName:"",
                                    cityInputString:"",
                                
                            })
                        });
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}; // end of addStatesToDB function

    /**
	 * @author Rutuja Jagtap
	 * @param  Description Task-4722 - route for fetching data from database
	 * @return Description – communicates with backend and retrieves data from country_code_info 
	 */

    getCountry(data) {
		fetch("/admin/getCountry", {
		  method: "POST",
		  headers: { "Content-Type": "application/json" },
		  body: JSON.stringify(data),
		})
		  .then(res => res.json())
		  .then(agencyArray =>{
			this.setState({agencyArray:agencyArray})
				}).catch(function (err) {
			console.log(err)
			
		  });
	
	  }

      /**
	 * @author Rutuja Jagtap
	 * @param  Description Task-4722 - react lifecycle function
	 * @return Description – renders the jsx for current component
	 */

    render() {
		return (
			<div>
                <AdminNavpage />
                <div
					style={{
						marginTop: 90,
						marginLeft: 40,
						marginRight: 40,
					}}>
                <div
						style={{
							display: "flex",
							justifyContent: "center",
							marginBottom: 20,
						}}>
						<span className="custom-page-header">Add State/Province/County</span> 
				</div>
                <div>
					<p><span style={{ color: "red" }}>*</span> Please enter Country to Search State </p>
			    </div>
                
                {(this.state.invalidInput) && (
						<div style={{ border: "1px solid red", marginBottom: 20 }}>
							<div
								className="errorMessage"
								style={{
									padding: "8px 15px",
								}}>
								{this.state.invalidInput && (
									<li className="error-message">
										<b>Please Add valid Country, State or City Name Only.</b> 
									</li>
								)}
                                {this.state.stateExists && (
									<li className="error-message">
										<b>Entered cities already exists in DI.</b>
									</li>
								)}
								
							</div>
						</div>
					)}
                <form>
						<div className="row align-items-end">
							<div className="col-lg-3 mb-3 mb-lg-0">
								<div
									style={{
										display: "flex",
										alignItems: "flex-end",
									}}>
									<label className="form-field-label">
										Country :&nbsp;<span style={{ color: "red" }}>*</span>
									</label>
									<input
										className="form-control form-field"
										type="text"
										name="country"
										value={this.state.country}
										onChange={this.handleChangeText}
										placeholder="Enter country"
									/>
								</div>
							</div>
							{/* End - Country field */}

							{/* Start - State field */}
							<div className="col-lg-4 mb-4 mb-lg-0">
								<div
									style={{
										display: "flex",
										alignItems: "flex-end",
									}}>
									<label className="form-field-label">
										State/Province/County :
									</label>
									<input
										className="form-control form-field align-bottom"
										type="text"
										name="state"
										value={this.state.state}
										onChange={this.handleChangeText}
										placeholder="Enter state"
									/>
								</div>
							</div>
							
							<div className="col-lg-2 offset-1">
								<button
									className="primary-button"
									style={{ float: "right" }}
									onClick={this.onSearchClick}
									disabled={this.state.isSearchDisabled}>
									Search
								</button>
							</div>
						</div>
					</form>
                    <hr className="divider" />
                    <div className="row" style={{ marginTop: 20, marginBottom: 20 }}>
                    <div
							className="col-lg-9"
							style={{ borderRight: "0.5px solid #ccc" }}>
							<div
								className="row justify-content-between"
								style={{ marginBottom: 10 }}>
								<div className="col-lg-6">
									<span className="page-label-addCountry">
										States that already added :
									</span>
								</div>
								
								<div className="col-lg-6 d-flex justify-content-end">
									<input
										style={{
											backgroundImage: "url(searchIcon.png)",
											backgroundPosition: "8px 6px",
											backgroundSize: "12px 12px",
											backgroundRepeat: "no-repeat",
											height: "20px",
											fontSize: "10px",
											border: "1px solid #ddd",
											padding: "12px 20px 12px 34px",
											backgroundColor: "#ffffff",
											borderRadius: 4,
										}}
										type="text"
										placeholder="Search State"
										name="searchedState"
										value={this.state.searchedState}
										onChange={this.handleChangeText}
									/>
								</div>
							</div>
							
							<div
								className="card custom-border-addCountry"
								style={{
									padding: "15px 0px",
									margin: 0,
								}}>
								{this.state.searchedStates.length > 0  ? (
									<div
										style={{
											marginLeft: 15,
											display: "grid",
											gridTemplateColumns: "repeat(5, 1fr)",
											columnGap: 10,
											rowGap: 6,
											overflowY: "auto",
											maxHeight: 155,
										}}>
										{this.state.searchedStates.map((states, i) => (
											<label
												key={i}
												style={{
													margin: 0,
													fontWeight: 400,
													textTransform: "capitalize",
												}}>
												{states}
											</label>
										))}
									</div>
								) : (
									<div className="d-flex justify-content-center">
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									</div>
								)}
							</div>
						</div> 
                        <div
							className="col-lg-3"
							style={{
								display: "flex",
								flexDirection: "column",
							}}>
							<span
								className="page-label-addCountry"
								style={{ marginBottom: 10 }}>
								Enter State and city you want to add :
							</span>
							{/* Rutuja task -4767 Showing the error if enter special charactrs in State stateshort name and cities */}
							{(this.state.invalidInput1) && (
						<div style={{ border: "1px solid red", marginBottom: 20 }}>
							<div
								className="errorMessage"
								style={{
									padding: "8px 15px",
								}}>
								{this.state.invalidInput1 && (
									<li className="error-message">
										<b>Please Add valid State or City Name .</b>
									</li>
								)}
                               								
							</div>
						</div>
					)}	
							<table>
								<tr>
									<td>
										<span
											className="form-field-label"
											style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
											Country short name :&nbsp;
											<span style={{ color: "red" }}>*</span>
										</span>
									</td>
									<td>
										

									{this.state.searchedStates.length === 0  ? ( //Rutuja 4796 changed == to ===
									<input
									className="form-control form-field align-bottom"
									type="text"
									name="countryCode"
									disabled
									></input>
											):(
												this.state.agencyArray.map((agencyArray) =>  (	
													<input
											className="form-control form-field align-bottom"
											type="text"
											name="countryCode"
											value={agencyArray.countryCode}
											defaultValue="IN"
											disabled
											></input>
											))
											)}

									</td>
								</tr>
                                <tr>
									<td>
										<span
											className="form-field-label"
											style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
											State name :&nbsp;
											<span style={{ color: "red" }}>*</span>
										</span>
									</td>
									<td>
										<input
											className="form-control form-field"
											type="text"
											name="stateName"
											value={this.state.stateName}
											onChange={this.handleChangeText}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<span
											className="form-field-label"
											style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
											State short name :&nbsp;
											<span style={{ color: "red" }}>*</span>
										</span>
									</td>
									<td>
										<input
											className="form-control form-field"
											type="text"
											name="stateShortName"
											value={this.state.stateShortName}
											onChange={this.handleChangeText}
										/>
									</td>
								</tr>
								<tr>
									<td>
										<span
											className="form-field-label"
											style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
											Country phone code :&nbsp;
											<span style={{ color: "red" }}>*</span>
										</span>
									</td>
									<td>
										
									{this.state.searchedStates.length === 0 ? ( //Rutuja 4796 changed == to ===
									<input
									className="form-control form-field align-bottom"
									type="number"
									name="countryPhoneCode"
									disabled>
										</input>
									):(
										this.state.agencyArray.map((agencyArray) =>  (	
											<input
											className="form-control form-field align-bottom"
											type="number"
											name="countryPhoneCode"
											value={agencyArray.countryPhoneCode}
											onChange={this.handleChangeText}
											defaultValue="91"
											disabled>
												</input>
											))
											)}

									</td>
								</tr>
							</table>

							<span
								className="form-field-label"
								style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
								Cities :&nbsp;<span style={{ color: "red" }}>*</span>
							</span>
							<div
								className="card custom-border-addCountry"
								style={{
									padding: "15px 0px",
									margin: 0,
									display: "flex",
									flexGrow: 1,
								}}>
								<textarea
									className="input-field-addition"
									style={{ maxHeight: 155, flexGrow: 1 }}
									placeholder="Enter cities"
									name="cityInputString"
									onChange={this.handleChangeText}
									value={this.state.cityInputString}
								/>
							</div>
							
						</div>
                        </div>   
                        <button
						className="primary-button"
						style={{ float: "right", marginBottom: 60 }}
						onClick={this.onAddClick}
						disabled={this.state.isAddDisabled}>
						Add
					</button>            
                </div>                    
                <Footer />
            </div>
        );
}


}

AddState.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(withRouter(AddState));
