/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers1';

const inititalState = {};

const store = createStore(
        rootReducer, 
        inititalState, 
        compose(applyMiddleware(thunk),
      window.devToolsExtension ? window.devToolsExtension() : f => f));
            //    window.__REDUX_DEVTOOLS_EXTENSION__&& window.__REDUX_DEVTOOLS_EXTENSION__()));

export default store;