/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Sanjana Godbole
@Creation Date:21-09-2019
@Description:UI for PublisherFrontPage(Dashboard)
*/

import React from "react";
import { Table, Tooltip, Progress } from "antd";//4241-removing Button,Badge,Divider to remove comsole warnings
import PublisherNavigation from "../layouts/publisherNavPage";
import Footer from "../layouts/footer";
import "./dashboardPublisher.css";
import { css } from "emotion";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import "./campaignList.css"; //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
import "react-chat-widget/lib/styles.css";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header
const xlsx = require("xlsx");
const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",

		color: "white",
		fontSize: "12px",
		fontWeight: "500",
	},
	"& thead > tr >th": {
		border: "1px solid black",
		height: "20%",
		color: "white",
	},
});

class publisherCampaignList extends React.PureComponent { // 4568 -(replace - Component with PureComponent to avoid rendering api) Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
	constructor(props) {
		super(props);
		this.state = {
			campaignDetails: [],
			dashboardCampaignCount: [],
			campaignDetailsSearch: [],
			columnUnreadMsgSearch: [],
			columnUnreadAlertsSearch: [],
			tableSearchText: "",
			tableSearchText1: "",
			tableSearchText2: "",
			biddingArray: [],
			reviewArray: [],
			pacing: "All",
			linkActive: "active",
			linkActive1: "",
			linkActive2: "",
			linkActive3: "",
			linkActive4: "",
			loader_progress: "loader_campaign_list", //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
			loading1: true,
			loading2: true,
			monthCount: "",
			headExampleNewsLetter: "NewsLetter",
			headExampleCallScript: "CallScriptG",
			headExampleLandingPage: "LandingPageG",
			headExampleNewCampaign: "NewCampaign",
			headExampleCounter: "CounterG",
			newCampaignDetailID: [],
			errors: {},
			success: {},
			fields: {},
			alertDisplay: "none",
			alertBorder: "",
			alertWidth: "",
			alertAlign: "",
			spanSpaceDisplay: "none",
			tab1: "",
			tabName: "Active",
			step: 1,
			pID: "",
			feedBackLength: "",
			leadsLength: "",
			invoiceDetailsCount: "",
			newCampaignLength: "",
			counterLength: "",
			btnDisable: "btn add-button",
			userlogger: "",
			ReviewTab: "",
			headExample2: "head-example2",
			headExample3: "head-exampleGeneral3",
			headExample4: "head-exampleGeneral4",
			headExample5: "head-exampleGeneral5",
			inputClass: "btn add-button",
			reviewTabl1: "reviewCreatives",
			tabReview: "newsLetter",
			agencyLinkReview: [],
			othersLength: "",
			visible: false,
			visible1: false,
			show: "none",
			unreadMessages: [],
			unreadMessagesLength: 0,
			unreadAlerts: [],
			unreadAlertsLength: 0,
			panelBlock: "",
			pacingCampaignDetails: [],
			ext: "",
			fileName: "",
			downloadFiles: [],
			showRFPTBL: false,
			RFPLength: "",
			pacingUnit: "",
			cancelTab: "",
			pageSize: "100",
			token: "", // shivani-task 2993-declare token to set token for API authentication to pass in every fetch header.
			label: "All", //sunita-task-3008- using this varibale we are displaying  on front end side the pacing value.
			campstatus: "", //sunita-task-3008- this is used when we are coming for dashboard finding the status of campaign
			fromDate: "", //sunita-task-3008- this varible holding the value of from date when we are coming from dashboard
			toDate: "", //sunita-task-3008- this varible holding the value of to date when we are coming from dashboard
			noChangeCampaignCount: [], //sunita-task-2996- this array used for storing the actual count of tab.
			fromDashboard: false, //sunita-task-3008-this varible used as per backend requirement.
			tabNameKey: "", //sunita-task-3008- when we are changing the filter value for particular tab  it will store that tab name only.
			isprogressBtnDisable: false, // sunita-task-3008-added this variable for disabling the progressreport button as per data .
			fromDownloadExcel: false, //Sonali-Task 2988-fromDownloadExcel is added for BE to identify that we are coming from download excel function
			forCompleteCampaign: false, //sunita-task-3008-this variable used as per BE requirement.
			dashActiveCount: "", //Sonali-3232-added this to show loader
			actionPopupVisible: false,
			x: 0,
			y: 0,
			actionPopupSelectedRecord: -1,
			actionIndex: "",
			actionText: "",
		}; // end of state
		this.campaignLink = this.campaignLink.bind(this);
		this.handleChangeSearch = this.handleChangeSearch.bind(this);
		this.uploadLeds = this.uploadLeds.bind(this);
		this.displayCampaignSpecification =
			this.displayCampaignSpecification.bind(this);
		this.handleChangeFilter = this.handleChangeFilter.bind(this);
		this.uploadVoiceLog = this.uploadVoiceLog.bind(this);
		this.downloadIOFile = this.downloadIOFile.bind(this);
		this.handleChangePageSize = this.handleChangePageSize.bind(this);
		this.downloadCampaignExcel = this.downloadCampaignExcel.bind(this);
		this.downloadExcel = this.downloadExcel.bind(this); // sunita-task-3008-created seperate function for download excel
	}

	/**
	 * @author Shivani Pathak
	 * @description Task-3611 handles when clicked on Action dots (3-dots)
	 */
	onActionClick = (e, record, index, text) => {
		e.preventDefault();

		if (!this.state.actionPopupVisible) {
			const self = this;
			document.addEventListener(`click`, function onClickOutside() {
				self.setState({
					actionPopupVisible: false,
					actionPopupSelectedRecord: -1,
					actionIndex: "",
					actionText: "",
				});
				document.removeEventListener(`click`, onClickOutside);
			});
		}

		this.setState({
			x: e.clientX,
			y: e.clientY + window.pageYOffset,
			actionPopupVisible: true,
			actionPopupSelectedRecord: record,
			actionIndex: index,
			actionText: text,
		});
	};
	/**
  @author Sunita
  * This seperate function is created and called below to download excel file from active tab and completed tab depending on the filter on FE
  * Dowloaded file will contain campaign details 
 */

	downloadExcel() {
		let downloadFiles = this.state.downloadFiles;
		const { user } = this.props.auth;
		const pID = user.id;

		//       /* make the worksheet */
		if (downloadFiles.length > 0) {
			var ws = xlsx.utils.json_to_sheet(downloadFiles);
			/* add to workbook */
			var wb = xlsx.utils.book_new();
			xlsx.utils.book_append_sheet(wb, ws, "Lead");

			// /* write workbook */
			var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
			/* generate a download */
			function s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
				return buf;
			}
			var fileName;//Sonali-4241-declaring filename to remove warning,previously declared separatly in if as well as else
			if (this.state.campstatus === "Completed") {
				//sunita-task-3008- as per campaign status it will download the report for active and completed tab
				fileName = pID + "_Completed_campaign_Progress_Report.xlsx";
			} else {
				fileName = pID + "_Active_campaign_Progress_Report.xlsx";
			}

			saveAs(
				new Blob([s2ab(buf)], { type: "application/octet-stream" }),
				fileName
			);
		} else {
		}
	}

	/**
	 * @author Sonali
	 * Task 2988-This function is added to download excel file from active tab and completed tab depending on the filter on FE
	 * Dowloaded file will contain campaign details
	 */
	downloadCampaignExcel(e) {
		e.preventDefault();//Sonali-3945-VAPT-remove #/ from url
		//sunita-task-3008- these all varibles are used in willmount am accessing here as per report requirement for downloading the report and for sending the data to backend
		var tabName = this.state.tabName;
		var parsed = this.props.location.state; //karan-task-3717-replace query params
		//karan-task-3717-this is to avoid run time errors
		var agencyID = undefined;
		//var from = undefined;Sonali-4241-commenting this as this is giving warning
		if (parsed) {
			agencyID = parsed.agencyID;
		}
		var fromDate = this.state.fromDate;
		var toDate = this.state.toDate;
		var campstatus = this.state.campstatus;
		var isprogressBtnDisable = this.state.isprogressBtnDisable; // sunita-task-3008-added this variable for disabling the progressreport button as per data .

		let data;
		let campaignDetails = [...this.state.campaignDetails];
		if (campaignDetails.length === 0) {
			isprogressBtnDisable = true; //sunita-task-3008-added this code when data is not present in table as per condition it will disbal or enable the download button
		} else {
			isprogressBtnDisable = false; //sunita-task-3008-added this code when data is not present in table as per condition it will disbal or enable the download button
		}
		this.setState({ isprogressBtnDisable }); //sunita-task-3008- gives the updated value isprogressBtnDisable.
		if (tabName === "Completed") {
			if (this.state.label === "Ending this month") {
				data = {
					fromDate: fromDate,
					tab: "All",
					toDate: toDate,
					pacing: "Monthly",
					byPacing: true,
					//userRole: user.role,//Sonali-3945-removing role and accessing from token at BE
					fromDownloadExcel: true,
				}; //sunita-task-3008- this data is used when tabname is completed.
				// this.state.label = "Ending this month";
				this.setState({label:"Ending this month"})//kiran-4746-removing console warning-use setState
			} else {
				data = {
					fromDate: fromDate,
					tab: "All",
					toDate: toDate,
					pacing: this.state.pacing,
					byPacing: true,
					//userRole: user.role,//Sonali-3945-removing role and accessing from token at BE
					fromDownloadExcel: true,
				}; //sunita-task-3008- this data is used when we are changing filter in  completed tab only.
			}
		} else {
			data = {
				fromDate: fromDate,
				tab: "All",
				toDate: toDate,
				pacing: this.state.pacing,
				byPacing: true,
				//userRole: user.role,//Sonali-3945-removing role and accessing from token at BE
				campstatus: tabName,
				fromDownloadExcel: true,
			}; //sunita-task-3008-this data is used when other tabname will come i.e except completed
		}

		let dashboardData = {
			agencyID: agencyID,
			fromDate: fromDate,
			toDate: toDate,
			//userRole: userRole,//Sonali-3945 removing role accessing on BE from token
			fromDownloadExcel: true, //Sonali-3045-This is added to identify that routes is called from download excel function
		};

		if (fromDate === "" && toDate === "") {
			//sunita-task-3008-these all conditions and data is used when coming from dashboard or on campaign list page according to fromdate and todate and tabname..
			if (tabName === "Completed") {
				if (this.state.label === "Ending this month") {
					dashboardData = {
						agencyID: agencyID,
						fromDate: fromDate,
						toDate: toDate,
						//userRole: user.role,//Sonali-3945-removing role and accessing from token at BE
						byPacing: true,
						pacing: "Monthly",
						campstatus: tabName,
						fromDownloadExcel: true, //Sonali-3045-This is added to identify that routes is called from download excel function
					};
				} else {
					dashboardData = {
						agencyID: agencyID,
						fromDate: fromDate,
						toDate: toDate,
						//userRole: user.role,//Sonali-3945-removing role and accessing from token at BE
						byPacing: true,
						pacing: this.state.pacing,
						campstatus: tabName,
						fromDownloadExcel: true, //Sonali-3045-This is added to identify that routes is called from download excel function
					};
				}
			} else {
				dashboardData = {
					agencyID: agencyID,
					fromDate: fromDate,
					toDate: toDate,
					//userRole: user.role,//Sonali-3945-removing role and accessing from token at BE
					byPacing: true,
					pacing: this.state.pacing,
					campstatus: tabName,
					fromDownloadExcel: true, //Sonali-3045-This is added to identify that routes is called from download excel function
				};
			}
		} else {
			dashboardData = {
				agencyID: agencyID,
				fromDate: fromDate,
				toDate: toDate,
				//userRole: user.role,//Sonali-3945-removing role and accessing from token at BE
				fromDownloadExcel: true, //Sonali-3045-This is added to identify that routes is called from download excel function
			};
		}

		if (campstatus !== undefined) {
			//sunita-task-3008-this condition is used when we are  coming from dashboard.
			if (campstatus === "Completed") {
				//sunita-task-3008- thses below all conditions are used when coming from dashboard as per tabname and as per fromdate and to date (completed or active).
				if (fromDate === "" && toDate === "") {
					//Sonali-3045-download campaign progress report from completed tab
					fetch("publisherDashboard/publisherCompleteCampaign", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(dashboardData),
					})
						.then((res) => res.json())
						.then((downloadFiles) => {
							this.setState({ downloadFiles: downloadFiles });
							this.downloadExcel();
							this.setState({ campaignDetails });
						})
						.catch(function (err) {
							console.log(err);
						});
				} else {
					//Sonali-3045-download campaign progress report from completed tab
					fetch("publisherDashboard/publisherCompleteCampaign", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(dashboardData),
					})
						.then((res) => res.json())
						.then((downloadFiles) => {
							this.setState({ downloadFiles: downloadFiles });
							this.downloadExcel();
							this.setState({ campaignDetails });
						})
						.catch(function (err) {
							console.log(err);
						});
				}
			}
			if (campstatus === "Active") {
				if (fromDate === "" && toDate === "") {
					//Sonali-3045-download campaign progress report from active tab
					fetch("publisherDashboard/publisherActiveCampaign", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((downloadFiles) => {
							this.setState({ downloadFiles: downloadFiles });
							this.downloadExcel();
							this.setState({ campaignDetails });
						})
						.catch(function (err) {
							console.log(err);
						});
				} else {
					//Sonali-3045-download campaign progress report from active tab
					fetch("publisherDashboard/publisherActiveCampaign", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(dashboardData),
					})
						.then((res) => res.json())
						.then((downloadFiles) => {
							this.setState({ downloadFiles: downloadFiles });
							this.downloadExcel();
							this.setState({ campaignDetails });
						})
						.catch(function (err) {
							console.log(err);
						});
				}
			}
		} else {
			if (tabName === "Active") {
				//sunita-task-3008-these conditions are given when we are on campaignlist page and doing actions like tab change or filter change.
				//Sonali-3045-download campaign progress report from active tab
				fetch("publisherDashboard/publisherActiveCampaign", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((downloadFiles) => {
						this.setState({ downloadFiles: downloadFiles });
						this.downloadExcel();
						this.setState({ campaignDetails });
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				//Sonali-3045-download campaign progress report from completed tab
				fetch("publisherDashboard/publisherCompleteCampaign", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((downloadFiles) => {
						this.setState({ downloadFiles: downloadFiles });
						this.downloadExcel();
						this.setState({ campaignDetails });
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		}
	}

	handleChangePageSize(e) {
		var pageSize = e.target.value;
		this.setState({ pageSize: pageSize });
	}
	//Sonali-4241-There was recordOpen() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was fadeInOut() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was closeChat() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was messageAgency() which I removed because it is not called from anywhere in this page

	uploadVoiceLog(e) {
		e.preventDefault();
		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentcampid"); //sunita-task-3184-as per requirement i have taken this id as it's getting undefined.
		var reallocationID = e.target.getAttribute("reallocationid");
		//Sonali-3945-redirect to anothet page with hidden parameters
		this.props.history.push("/voiceLogsUpload", { campID: campID, parentCampID: parentCampID, reallocationID: reallocationID })

	}
	//Sonali-4241-There was getagencyLink() which I removed because it is not called from anywhere in this page

	handleChangeFilter(e) {
		e.preventDefault();//Sonali-3945-VAPT-remove #/ from url
		var pacing = e.target.id;
		var label = e.target.getAttribute("label"); //sunita-task-3008-this variable added for displaying the pacing value on FE and as per requirement i have setstate the variables
		this.setState({
			pacing: pacing,
			label: label,
			fromDate: "",
			toDate: "",
			//loading: true, // User Story : 3193 : Karan Jagtap : Shows loading symbol in table
			loader_progress: "loader_campaign_list", //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
		}); //sunita-task-3008- as per requirement i have setstate the fromdate todate varibles.
		document.getElementById("myText").innerHTML = label; //sunita-task-3132-code change done bcz label value not getting properly.
		var tabName = this.state.tabName;
		var parsed = this.props.location.state; //karan-task-3717-replace query params
		var fromDate = undefined;
		var toDate = undefined;
		if (parsed) {
			fromDate = parsed.fromDate;
			toDate = parsed.toDate;
		}
		var dashActiveCount; //Sonali-3232-added this to show loader

		var tabNameKey = tabName; //sunita-task-3008-tabnamekey is added for BE when we are changing the filter value for particular tab  it will store that tab name.

		let data = {
			tab: "All",
			fromDate: fromDate,
			toDate: toDate,
			pacing: pacing,
			byPacing: true,
			//userRole: user.role,//Sonali-3945-removing role and accessing from token at BE

			noChangeCampaignCount: this.state.noChangeCampaignCount,
			tabNameKey,
		}; //sunita-task-3008-noChangeCampaignCount,tabNameKey-sent to BE data as per requirement

		fetch("/publisherDashboard/publisherTabwiseCount", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((pubCampCount) => {
				//Sonali-3232-added this to show loader and set dashActiveCount as per the tabName
				//Sonali-4241-replacing == by === to remove warnings
				if (tabName === "Active") {
					dashActiveCount = pubCampCount[0].activeCount;
				}
				if (tabName === "Paused") {
					dashActiveCount = pubCampCount[0].pausedCount;
				}
				if (tabName === "Completed") {
					dashActiveCount = pubCampCount[0].completeCount;
				}
				if (tabName === "Cancel") {
					dashActiveCount = pubCampCount[0].cancelCount;
				}

				this.setState(
					{
						dashboardCampaignCount: pubCampCount,
						tabNameKey,
						dashActiveCount: dashActiveCount, //Sonali-3232-added this to show loader
					},
					function () { }
				);
			})
			.catch(function (err) {
				console.log(err);
			}); //sunita-task-3008-values are setstate or getting updated values.
		if (tabName === "Active") {
			fetch("/publisherDashboard/publisherActiveCampaign", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({
						campaignDetails: campaignDetails,
						loading: false,
						tabNameKey,
						isprogressBtnDisable: false,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
					//sunita- task-3008-isprogressBtnDisable its value  will change as per data present in table for disabling the download button.
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (tabName === "Cancel") {
			fetch("/publisherDashboard/publisherCancelCampaign", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((cancelCampaign) => {
					this.setState({
						campaignDetails: cancelCampaign,
						tabNameKey,
						loading: false,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (tabName === "Paused") {
			// Getting Publisher Paused Campaign Details
			fetch("/publisherDashboard/publisherPausedCampaign", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((pausedCampaign) => {
					this.setState({
						campaignDetails: pausedCampaign,
						tabNameKey,
						loading: false,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (tabName === "Completed") {
			// Getting Publisher Complete Campaign Details
			fetch("/publisherDashboard/publisherCompleteCampaign", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((completeCampaign) => {
					this.setState({
						campaignDetails: completeCampaign,
						tabNameKey,
						isprogressBtnDisable: false,
						loading: false,
						loader_progress: "",
					}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
					//sunita- task-3008-isprogressBtnDisable its value  will change as per data present in table for disabling the download button.
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}
	//Sonali-4241-There was rejectCampaign() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was handleCampaignIDOnClickAccept() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was acceptCampaign() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was handleRefreshPage() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was empty creativeFeedBack() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was empty handleChange() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was submitCounterProposal() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was validateForm() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was counter() which I removed because it is not called from anywhere in this page
	displayCampaignSpecification(e) {
		e.preventDefault();//Sonali-3945-VAPT-remove # from url
		var campID = e.target.getAttribute("campid");
		var cancelTab = e.target.getAttribute("canceltab");
		var parentCampID = e.target.getAttribute("parentcampid");
		var reallocationID = e.target.getAttribute("reallocationid");
		var status = e.target.getAttribute("status");
		var tab = "Active"; //sandeep-task-3064-added tab in url to access in campaignSpecification page

		if (cancelTab === "Cancel") {
			this.props.history.push("/campaignSpecificationPublisherDashboard", { campID: campID, parentCampID: parentCampID, reallocationID: reallocationID, key: status, cancelKey: cancelTab })//Sonali-task-3945-vAPT URL changes

		} //sandeep-task-3064-added cancelTab==="Cancel" in url to access in campaignSpecification page
		else if (cancelTab === "Completed") {
			this.props.history.push("/campaignSpecificationPublisherDashboard", { campID: campID, parentCampID: parentCampID, reallocationID: reallocationID, key: status, cancelKey: cancelTab })//Sonali-task-3945-vAPT URL changes

		} //sandeep-task-3064-added cancelTab==="Completed" in url to access in campaignSpecification page
		else if (cancelTab === "Paused") {
			this.props.history.push("/campaignSpecificationPublisherDashboard", { campID: campID, parentCampID: parentCampID, reallocationID: reallocationID, key: status, cancelKey: cancelTab })//Sonali-task-3945-vAPT URL changes

		} //sandeep-task-3064-added cancelTab==="Puased" in url to access in campaignSpecification page
		else {
			this.props.history.push("/campaignSpecificationPublisherDashboard", { campID: campID, parentCampID: parentCampID, reallocationID: reallocationID, key: status, tab: tab })//Sonali-task-3945-vAPT URL changes
		} //sandeep-task-3064-added tab="Active" in url to access in campaignSpecification page
	}
	//Sonali-4241-There was displayCampaignSpecificationMsg() which I removed because it is not called from anywhere in this page


	uploadLeds(e) {
		e.preventDefault();
		var campID = e.target.getAttribute("id");
		var parentCampID = e.target.getAttribute("parentcampid");
		var reallocationID = e.target.getAttribute("reallocationid");
		//sonali-task-3945-replace query params
		this.props.history.push("/publisherDeliverLead", { campID: campID, parentCampID: parentCampID, reallocationID: reallocationID })

	} // end of uploadLeds
	//Sonali-4241-There was leadLink() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was linkOthers() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was invoiceLink() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was creativeLink() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was biddingLink() which I removed because it is not called from anywhere in this page

	async campaignLink(e) { // 4568 -(added async) - Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
		e.preventDefault();
		//Sandeep-task-3729
		var dashActiveCount; //Sonali-3232-added this to show loader
		var data;
		if (e.target.id === "Completed") {
			data = {
				tab: "All",
				pacing: "Monthly",
				byPacing: true,
				//userRole: user.role,//Sonali-3945-removing role and accessing from BE
			}; //sunita-task-3008-used this data as per requirement.
			this.state.label = "Ending this month";
		} else {
			data = {
				tab: "All",
				pacing: "All",
				byPacing: true,
				//userRole: user.role,//Sonali-3945-removing role and accessing from BE
			}; //sunita-task-3008- data is sent as per changing the tabName.
			this.state.label = "All";
		}

		this.setState({
			tabName: e.target.id,
			label: this.state.label,
			pacing: "All",
			fromDate: "",
			toDate: "",
			dashboardCampaignCount: this.state.noChangeCampaignCount,
			//loading: true, // User Story : 3193 : Karan Jagtap : Shows loading symbol in table
			loader_progress: "loader_campaign_list",
			tableSearchText: "", //kiran-bug 4255- added code to blank tableSearchText on tab
		}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
		//sunita-task-3008-values are setstate for getting updated value.
		fetch("/publisherDashboard/publisherTabwiseCount", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((pubCampCount) => {
				this.setState({ dashboardCampaignCount: pubCampCount }, function () { });
			})
			.catch(function (err) {
				console.log(err);
			});
		//Sonali-4241-replacing == with === to remove warnings
		if (e.target.id === "Active") {
			// var tab = "Active";//kiran-4746-removing console warning-no used
			dashActiveCount = this.state.dashboardCampaignCount[0].activeCount; //Sonali-3232-added this to show loader

			// Getting Publisher Active Campaign Details
			await fetch("/publisherDashboard/publisherActiveCampaign", { // 4568 -(added await) - Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
				//insert data in publisher_assignment table
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					var pacing = this.state.pacing;
					let pacingData = { pacing: pacing, tabKey: "Active" };
					fetch("/publisherDashboard/pacingCampaignDetailsPublisher", {
						//insert data in publisher_assignment table
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(pacingData),
					})
						.then((res) => res.json())
						.then((pacingCampaignDetails) => {
							const pacingResult = Array.from(
								new Set(pacingCampaignDetails.map((p) => p.campID))
							).map((campID) => {
								return {
									campID: campID,
									pacingUnit: pacingCampaignDetails.find(
										(p) => p.campID === campID
									).pacingUnit,
								};
							});
							campaignDetails = campaignDetails.map((e) =>
								Object.assign(
									{},
									e,
									pacingResult.find((e1) => e1.campID.toString() === e.campID) || {}
								)
							);
							//kiran-4746-added toString() and changed == to ===
							this.setState(
								{
									campaignDetails: campaignDetails,
									loading: false,
									pacingCampaignDetails: pacingCampaignDetails,
									linkActive: "active",
									linkActive1: "",
									linkActive2: "",
									linkActive3: "",
									linkActive4: "",
									campstatus: "Active",
									isprogressBtnDisable: false,
									loader_progress: "",
									tableSearchText: "", //kiran-bug 4255- added code to blank tableSearchText on tab
								},
								function () { }
							); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							//sunita-task-3008-progressBtnDisable its value  will change as per data present in table for disabling the download button.
							// let campaignDetails = this.state.campaignDetails.map((item, i) => Object.assign({}, item, this.state.pacingCampaignDetails[i]));
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
		//sunita-task-3008- code removed as pending tab is not present.
		else if (e.target.id === "Paused") {
			// var tab = "Paused";//kiran-4746-removing console warning-no used
			dashActiveCount = this.state.dashboardCampaignCount[0].pausedCount; //Sonali-3232-added this to show loader

			var cancelTab = "Paused"; //sandeep-task-3064-added cancelTab="Paused" to access in displayCampaignSpecification function
			// Getting Publisher Paused Campaign Details
			await fetch("/publisherDashboard/publisherPausedCampaign", { // 4568 -(added await) - Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
				//insert data in publisher_assignment table
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					var pacing = this.state.pacing;
					let pacingData = { pacing: pacing, tabKey: "Paused" }; //karan-task-3717-removed body param and added user token
					fetch("/publisherDashboard/pacingCampaignDetailsPublisher", {
						//insert data in publisher_assignment table
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(pacingData),
					})
						.then((res) => res.json())
						.then((pacingCampaignDetails) => {
							this.setState({
								pacingCampaignDetails: pacingCampaignDetails,
								campaignDetails: campaignDetails,
								loading: false,
								linkActive: "",
								linkActive1: "",
								linkActive2: "active",
								linkActive3: "",
								linkActive4: "",
								cancelTab: cancelTab,
								loader_progress: "",
								tableSearchText: "", //kiran-bug 4255- added code to blank tableSearchText on tab
							}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "Completed") {
			// var tab = "Completed";//kiran-4746-removing console warning-no used
			dashActiveCount = this.state.dashboardCampaignCount[0].completedCount; //Sonali-3232-added this to show loader
			//kiran-4746-removing console warning-changed var to let
			let cancelTab = "Completed"; //sandeep-task-3064-added cancelTab="Completed" to access in displayCampaignSpecification function
			
			await fetch("/publisherDashboard/publisherCompleteCampaign", { // 4568 -(added await) - Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					var pacing = this.state.pacing;
					let pacingData = { pacing: pacing, tabKey: "Completed" }; //karan-task-3717-removed body param and added user token
					fetch("/publisherDashboard/pacingCampaignDetailsPublisher", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(pacingData),
					})
						.then((res) => res.json())
						.then((pacingCampaignDetails) => {

							this.setState({ pacingCampaignDetails: pacingCampaignDetails });
							this.setState({
								campaignDetails: campaignDetails,
								loading: false,
								linkActive: "",
								linkActive1: "",
								linkActive2: "",
								linkActive3: "active",
								linkActive4: "",
								cancelTab: cancelTab,
								campstatus: "Completed",
								isprogressBtnDisable: false,
								loader_progress: "",
								tableSearchText: "", //kiran-bug 4255- added code to blank tableSearchText on tab
							}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							//sunita-task-3008-progressBtnDisable its value  will change as per data present in table for disabling the download button.
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (e.target.id === "Cancel") {
			dashActiveCount = this.state.dashboardCampaignCount[0].cancelCount; //Sonali-3232-added this to show loader

			// var tab = "Cancel"; //kiran-4746-removing console warning-no used and changed var to let
			let cancelTab = "Cancel"; //sandeep-task-3064-added cancelTab="Cancel" to access in displayCampaignSpecification function
			// Getting Publisher Cancel Campaign Details
			await fetch("/publisherDashboard/publisherCancelCampaign", { // 4568 -(added await) - Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
				//insert data in publisher_assignment table
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
					var pacing = this.state.pacing;
					let pacingData = { pacing: pacing, tabKey: "Cancel" }; //karan-task-3717-removed body param and added user token
					fetch("/publisherDashboard/pacingCampaignDetailsPublisher", {
						//insert data in publisher_assignment table
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(pacingData),
					})
						.then((res) => res.json())
						.then((pacingCampaignDetails) => {
							this.setState({ pacingCampaignDetails: pacingCampaignDetails });
							this.setState({
								campaignDetails: campaignDetails,
								loading: false,
								linkActive: "",
								linkActive1: "",
								linkActive2: "",
								linkActive3: "",
								linkActive4: "active",
								cancelTab: cancelTab,
								loader_progress: "",
								tableSearchText: "", //kiran-bug 4255- added code to blank tableSearchText on tab
							}); //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
		this.setState({ dashActiveCount: dashActiveCount }); //Sonali-3232-added this to show loader
	}

	/**
	 * @author Narendra
	 * @param  Description  Searching table information
	 */
	async handleChangeSearch(e) { // 4568 -(added async) - Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
		e.preventDefault();
		let currentList = [];
		// Variable to hold the filtered list before putting into state
		let newList = [];
		let filterData = e.target.value;
		// If the search bar isn't empty
		if (e.target.value === "") {
			newList = this.state.campaignDetails;
		} else {
			// Assign the original list to currentList
			currentList = this.state.campaignDetails;
			newList = await currentList.filter((item) => {  // 4568 -(added await) - Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
				let obj = {};//Somnath Task-3972, Add specific Keys in search criteria
				obj[`campID`] = item.campID;
				obj[`campaignName`] = item.campaignName;
				obj[`startDate`] = item.startDate;
				obj[`endDate`] = item.endDate;

				obj[`allocatedLead`] = item.allocatedLead;
				obj[`clientCampID`] = item.clientCampID;
				obj[`pendingLead`] = item.pendingLead;
				obj[`deliveredLead`] = item.deliveredLead;
				obj[`qaReviewLead`] = item.qaReviewLead;
				obj[`acceptedLead`] = item.acceptedLead;
				obj[`rejectedLead`] = item.rejectedLead;
				obj[`balancedLead`] = item.balancedLead;
				// change search term to lowercase
				const filter = e.target.value.toLowerCase();
				//const filter = e.target.value.toLowerCase();
				// check to see if the current list item includes the search term
				// If it does, it will be added to newList. Using lowercase eliminates
				// issues with capitalization in search terms and search content
				var data = Object.values(obj);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
		}
		// Set the filtered state based on what our rules added to newList
		this.setState({
			campaignDetailsSearch: [...newList],
			tableSearchText: filterData,
		});
	}

	//Sonali-4241-There was handleChangeSearch1() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was handleChangeSearch2() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was handleRejectedDownload() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was handleQALeadDownload() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was biddingLink() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was leadReview() which I removed because it is not called from anywhere in this page
	//Sonali-4241-There was handleDeliveredDownload() which I removed because it is not called from anywhere in this page

	/**
	 * @author Supriya Gore
	 * @param  Description handle the download lead by common API using lead Type
	 * @return Description return download lead excel file
	 */
	handledownloadLead(e) {
		e.preventDefault();
		let campID = e.target.id;
		let parentCampID = e.target.getAttribute("parentcampid");
		let leadType = e.target.getAttribute("leadtype");
		let data = { campID, leadType }; //karan-task-3717-removed body param and added user token

		let campaignDetails = this.state.campaignDetails; //4587-Nilesh File Naming convention change
		let campDetails = campaignDetails.filter((el) => {
			return el.campID.toString() === campID;//kiran-4746-added toString() and changed == to ===
		});
		fetch("publisher/publisherDownloadLead", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((downloadFiles) => {
				if (downloadFiles.length > 0) {
					var ws = xlsx.utils.json_to_sheet(downloadFiles);
					var wb = xlsx.utils.book_new();
					xlsx.utils.book_append_sheet(wb, ws, "Lead");
					var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
					function s2ab(s) {
						var buf = new ArrayBuffer(s.length);
						var view = new Uint8Array(buf);
						for (let i = 0; i !== s.length; ++i)
							view[i] = s.charCodeAt(i) & 0xff;
						return buf;
					}
					//var fileName = leadType + "_" + parentCampID + ".xlsx";

					var fileName = leadType + "_" + campDetails[0].clientCampID + "_" + parentCampID + ".xlsx";  //4587-Nilesh File Naming convention change
					saveAs(
						new Blob([s2ab(buf)], { type: "application/octet-stream" }),
						fileName
					);
				}
				this.setState({ campaignDetails }); // when user click on lead number table data is empty so i store this data in temperary array and again set back this array
			});
	}
	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			var parsed = this.props.location.state; //karan-task-3717-replace query params
			const { user } = this.props.auth;
			const pID = user.id;
			this.setState({ pID: pID });//sonali-4241-declaring this in componentwill mount to be used all over file
			//Sandeep-task-3729
			const token = user.token; //shivani-task 2993- added code to declare token and using this token to authenticate the api.
			//karan-task-3717-this is to avoid run time errors
			var agencyID = undefined;
			var fromDate = undefined;
			var toDate = undefined;
			var campstatus = undefined;
			if (parsed) {
				agencyID = parsed.agencyID;
				fromDate = parsed.fromDate;
				toDate = parsed.toDate;
				campstatus = parsed.status;
			}
			var userRole = user.role; //karan-task-3717-replace query params
			var pacing = this.state.pacing;
			var dashActiveCount; //Sonali-3232-added this to show loader

			if (campstatus !== undefined) {
				//sunita-task-3008-these conditions added when coming from dashboard as per campstatus giving pacing value.
				if (campstatus === "Completed") {
					pacing = "Monthly";
					// this.state.label = "Ending this month";
					this.setState({label:"Ending this month"}) //kiran-4746-removing console warning-use setState
				} else {
					pacing = "All";
				}
			}
			this.setState({
				userlogger: user.role,
				token,
				fromDate,
				toDate,
				campstatus,
				pacing: pacing,
			}); //shivani-task 2993- added code to setState the token to increase api security.
			// Getting Publisher Campaign Count of All Tab
			fetch("/publisherDashboard/getMonthList", {
				//insert data in publisher_assignment table
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((monthArray) => {
					this.setState({ monthCount: monthArray.length });
				})
				.catch(function (err) {
					console.log(err);
				});

			let dashboardData = {
				agencyID: agencyID,
				fromDate: fromDate,
				toDate: toDate,
				userRole: userRole,
			};

			if (campstatus !== undefined) {
				let dashboardCountData = {
					agencyID,
					fromDate,
					toDate,
					campstatus,
					pacing: pacing,
					tabNameKey: "All",
				}; //sunita-task-3008-tabNameKey sent as per backend requirement.
				fetch("/publisherDashboard/publisherTabwiseCount", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(dashboardCountData),
				})
					.then((res) => res.json())
					.then((pubCampCount) => {
						this.setState(
							{
								dashboardCampaignCount: pubCampCount,
								tabName: campstatus,
								tabNameKey: "All",
							},
							function () { }
						); //sunita-task-3008-values are setstate for getting updated value.
					})
					.catch(function (err) {
						console.log(err);
					});

				let dashboardData1 = {
					agencyID,
					campstatus,
					pacing: "All",
					tabNameKey: "All",
					forCompleteCampaign: true,
				}; //sunita-task-3008-default tab count set in array i.e noChangeCampaignCount array.and this tabNamekey given as per BE requirement for count
				fetch("/publisherDashboard/publisherTabwiseCount", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(dashboardData1),
				})
					.then((res) => res.json())
					.then((pubCampCount) => {
						//Sonali-3232-added this to show loader
						if (campstatus === "Active") {
							dashActiveCount = pubCampCount[0].activeCount;
						}
						if (campstatus === "Completed") {
							dashActiveCount = pubCampCount[0].completeCount;
						}
						if (campstatus === "Paused") {
							dashActiveCount = pubCampCount[0].pausedCount;
						}
						if (campstatus === "Cancel") {
							dashActiveCount = pubCampCount[0].cancelCount;
						}
						this.setState(
							{
								noChangeCampaignCount: pubCampCount,
								dashActiveCount: dashActiveCount,
							}, //Sonali-3232-added this to show loader
							function () { }
						);
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				let dashboardCountData = {
					agencyID: agencyID,
					tab: "All",
					pacing: this.state.pacing,
					byPacing: true,
					//userRole: userRole,//sonali-3945-removing role accessing from FE
					tabNameKey: "All",
					fromDashboard: true,
				}; //sunita-task-3008-fromdashboard varible is added as per BE requirement.and from this data removed fromdate and todate bcz of dates progress report is not downloaded properly.
				fetch("/publisherDashboard/publisherTabwiseCount", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(dashboardCountData),
				})
					.then((res) => res.json())
					.then((pubCampCount) => {
						//Sonali-3232-added this to show loader
						if (this.state.tabName === "Active") {
							dashActiveCount = pubCampCount[0].activeCount;
						} else {
						}
						this.setState(
							{
								dashboardCampaignCount: pubCampCount,
								noChangeCampaignCount: pubCampCount,
								dashActiveCount: dashActiveCount, //Sonali-3232-added this to show loader
							},
							function () { }
						); //sunita-task-3008-setstate the array nochangecampaigncount for getting the updated value.
					})
					.catch(function (err) {
						console.log(err);
					});

				var tab = "Active";
				fetch("/publisherDashboard/publisherActiveCampaign", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(dashboardCountData),
				})
					.then((res) => res.json())
					.then((campaignDetails) => {
						this.setState(
							{
								campaignDetails: campaignDetails,
								tab: tab,
								loading: false,
								linkActive: "active",
								linkActive1: "",
								linkActive2: "",
								linkActive3: "",
								linkActive4: "",
								loader_progress: "",
							}, //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							function () { }
						);
					})
					.catch(function (err) {
						console.log(err);
					});
			}
			if (campstatus === "Active") { //kiran-4746-removing console warning-changed == to === and changed var to let
				// Getting Publisher Active Campaign Details
				let tab = "Active";
				fetch("/publisherDashboard/publisherActiveCampaign", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(dashboardData),
				})
					.then((res) => res.json())
					.then((campaignDetails) => {
						this.setState(
							{
								campaignDetails: campaignDetails,
								tab: tab,
								loading: false,
								linkActive: "active",
								linkActive1: "",
								linkActive2: "",
								linkActive3: "",
								linkActive4: "",
								loader_progress: "",
							}, //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							function () { }
						);
					})
					.catch(function (err) {
						console.log(err);
					});
			} else if (campstatus === "Completed") { //kiran-4746-removing console warning-changed == to === and changed var to let
				// Getting Publisher Completed Campaign Details
				let tab = "Completed";
				fetch("/publisherDashboard/publisherCompleteCampaign", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(dashboardData),
				})
					.then((res) => res.json())
					.then((campaignDetails) => {
						this.setState(
							{
								campaignDetails: campaignDetails,
								tab: tab,
								loading: false,
								linkActive: "",
								linkActive1: "",
								linkActive2: "",
								linkActive3: "active",
								linkActive4: "",
								loader_progress: "",
							}, //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							function () { }
						);
					})
					.catch(function (err) {
						console.log(err);
					});
			} else if (campstatus === "Paused") { //kiran-4746-removing console warning-changed == to === and changed var to let

				let tab = "Paused";
				fetch("/publisherDashboard/publisherPausedCampaign", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(dashboardData),
				})
					.then((res) => res.json())
					.then((campaignDetails) => {
						this.setState(
							{
								campaignDetails: campaignDetails,
								tab: tab,
								loading: false,
								linkActive: "",
								linkActive1: "",
								linkActive2: "active",
								linkActive3: "",
								linkActive4: "",
								loader_progress: "",
							}, //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							function () { }
						);
					})
					.catch(function (err) {
						console.log(err);
					});
			} else if (campstatus === "Cancel") {
				// Getting Publisher Cancel Campaign Details

				let tab = "Cancel";
				fetch("/publisherDashboard/publisherCancelCampaign", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(dashboardData),
				})
					.then((res) => res.json())
					.then((campaignDetails) => {
						this.setState(
							{
								campaignDetails: campaignDetails,
								tab: tab,
								loading: false,
								linkActive: "",
								linkActive1: "",
								linkActive2: "",
								linkActive3: "",
								linkActive4: "active",
								loader_progress: "",
							}, //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page
							function () { }
						);
					})
					.catch(function (err) {
						console.log(err);
					});
			}

		}

		// 4568 -(which I commented because it is not called from anywhere in this page) Prod Issue- AC/PC/PNC - Camp Progress - Page Unresponsive popup comes
		/* @author Narendra Phadke
		 * @param  Description Unread message on dashboard
		 * @return Description get all unread message
		 */
		//Sandeep-task-3729
		// fetch("/notification/publisherDashboardUnreadMessages", {
		// 	method: "POST",
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 	},
		// })
		// 	.then((res) => res.json())
		// 	.then((unreadMessages) => {
		// 		this.setState({
		// 			unreadMessages: unreadMessages,
		// 			unreadMessagesLength: unreadMessages.length,
		// 		});
		// 	})
		// 	.catch(function (err) {
		// 		console.log(err);
		// 	});

		// fetch("/notification/publisherDashboardUnreadAlerts", {
		// 	method: "POST",
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 	},
		// })
		// 	.then((res) => res.json())
		// 	.then((unreadAlerts) => {
		// 		this.setState({
		// 			unreadAlerts: unreadAlerts,
		// 			unreadAlertsLength: unreadAlerts.length,
		// 		});

		// 		if (this.state.unreadAlerts && this.state.unreadAlerts.length > 0) {
		// 			var name = "key";
		// 			var value;
		// 			for (var i = 0; i < this.state.unreadAlerts.length; i++) {
		// 				value = this.state.unreadAlerts[i].alertID;
		// 				let unreadAlerts = [...this.state.unreadAlerts];
		// 				unreadAlerts[i] = { ...unreadAlerts[i], [name]: value };
		// 				this.setState({ unreadAlerts: unreadAlerts });
		// 			}
		// 		}
		// 	})
		// 	.catch(function (err) {
		// 		console.log(err);
		// 	});
		//} 
		//componentDidMount() {
		fetch("/publisherDashboard/getMonthList", {
			//insert data in publisher_assignment table
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((monthArray) => {
				this.setState({ monthCount: monthArray.length });
			})
			.catch(function (err) {
				console.log(err);
			});

		fetch("/publisher/updateLeadsPermissionTimeFlag", {
			//insert data in publisher_assignment table
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((updateLeadsPermissionTimeFlag) => { })
			.catch(function (err) {
				console.log(err);
			});
	}

	uploadCreative(e) {
		e.preventDefault();
		var parentCampID = e.target.getAttribute("parentcampid")//Sonali-4138-getting parentCampID
		var campID = e.target.id;
		//Sonali-4138-adding parentCampaign ID into following 
		this.props.history.push("/publisherCreativeUploads", { pID: this.props.auth.user.id, campID: campID, parentCampID: parentCampID })//Sonali-task-3945-vAPT URL changes
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description download Insertion Order Pdf Document
	 * @return Description return pdf blob
	 */
	downloadIODocument(e) {
		e.preventDefault();
		var campID = e.target.id;
		//sandeep-task-3729
		var documentName = "Insertion_Order_Document_" + campID + ".pdf";
		var ext = documentName.split(".").pop();
		this.setState({ ext: ext, filename: documentName });

		fetch("/publisherDashboard/getIODocument?campID=" + campID, {
			method: "GET",
		}) // karan-task-3714-vapt header and query params
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.downloadIOFile);
	} /*** End of downloadIODocument */

	/**
	 * @author Somnath Keswad
	 * @param  Description download Insertion Order Document
	 * @return
	 */
	downloadIOFile(blob) {
		let url = this.state.filename;
		let fileName = url.substring(url.lastIndexOf("\\") + 1);
		let pdfFileDownloadName = fileName;
		let newBlob = new Blob([this.state.downloadFiles], {
			type: "application/pdf",
		});
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}
		const data = window.URL.createObjectURL(newBlob);
		let link = document.createElement("a");
		link.href = data;
		link.download = pdfFileDownloadName;
		link.click();
	}

	render() {

		const { user } = this.props.auth;

		const columns = [
			{
				title: "Camp ID",
				dataIndex: "campID",
				key: "campID",
				width: "8%",
				align: "center",
				sorter: (a, b) => a.campID - b.campID,
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>
							{record.parentCampID ? record.parentCampID : text}
						</span>
					</div>
				),
			},
			//Sonali 3360-added below missing code
			// Sandeep-task-3275-added new column for agency camp ID
			{
				title: "Agency Camp ID",
				dataIndex: "agencycampID",
				key: "agencycampID",
				width: "7.5%",

				align: "center",
				sorter: (a, b) => a.agencycampID - b.agencycampID,
				render: (text, record) => (
					<div style={{ width: "75px" }}>
						<span style={{ fontSize: "12px" }}>
							{record.clientCampID ? record.clientCampID : text}
						</span>
						<br />
					</div>
				),
			},
			{
				title: "Camp Name",
				dataIndex: "campaignName",
				key: "campaignName",
				width: "18%",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";
					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div style={{ float: "left" }}>
						{/*sunita-task-3008-added css for proper alignment */}
						<div class="dot1" style={{ width: "175px" }}>
							{/*sunita-task-3008-added css for proper alignment*/}
							{/* Sonali-4241-adding displayCampaignSpecification instead of # in href for removing warning */}

							<a
								href="displayCampaignSpecification"
								onClick={this.displayCampaignSpecification}
								campid={record.campID}
								campaignname={record.campaignName}
								status="mainTab"
								parentcampid={record.parentCampID}
								reallocationid={record.reallocationID}
								pid={record.pID}
								canceltab={this.state.cancelTab}
								style={{ fontSize: "11px" }}>
								{record.campaignName}
							</a>
							{/*sunita-task-3008-removed the css as it's affecting */}
						</div>

						<span style={{ float: "left" }}>
							<span style={{ fontSize: "11px" }}>
								Days Left:&nbsp;
								{record.DateDiff <= 2 ? (
									<span style={{ color: "red", fontWeight: "700" }}>
										{record.DateDiff}
									</span>
								) : record.DateDiff <= 5 && record.DateDiff > 2 ? (
									<span style={{ color: "orange", fontWeight: "1000" }}>
										{record.DateDiff}
									</span>
								) : (
									<span style={{ color: "green", fontWeight: "1000" }}>
										{record.DateDiff}
									</span>
								)}
							</span>

							<br />
							{/* Sonali-4241-replacing == by === to remove warnings */}

							{this.state.userlogger === "PC" ? (
								<span style={{ fontSize: "11px" }}>
									CPL:{" "}
									<span style={{ fontWeight: "700" }}>
										{record.CPL}
										{record.currency}
									</span>{" "}
								</span>
							) : (
								""
							)}
						</span>

						{/* Sonali-4241-replacing == by === to remove warnings */}

						{this.state.tabName === "Paused" ? (
							<div>
								<span
									style={{
										color: "red",
										float: "left",
										fontSize: "11px",
										fontWeight: "400",
									}}>
									<b>Reason For Pause:</b>&nbsp;&nbsp;
									<label style={{ color: "black", fontWeight: "400" }}>
										{record.reason}
									</label>
								</span>
							</div>
						) : (
							""
						)}
						<br />
					</div>
				),
			},
			{
				title: "Progress Status",

				children: [
					{
						title: (
							<Tooltip placement="top" title="Creatives Status">
								Creative Approval
							</Tooltip>
						),
						dataIndex: "creativeApproval",
						key: "creativeApproval",
						align: "center",
						width: "6%",
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>
										{/* //shivani-task 2902- added this to show creative Approval in percentage . */}

										{/* Sonali-Task 2902-added below undefined conditions(if undefined i.e creative approval is not required so we are displyig the percentage as 100) */}
										<Progress
											type="circle"
											strokeColor="rgb(135, 208, 104)"
											percent={record.creativesPercentage}
											width={30}
										></Progress>
									</div>
								),
							};
						},
					},
					{
						title: (
							<Tooltip placement="top" title="Delivery Status">
								Delivery Status
							</Tooltip>
						),
						dataIndex: "deliveryStatus",
						key: "deliveryStatus",
						align: "center",
						width: "6%",
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>
										{record.requiredLeadPerAsset === "Yes" ? (
											<Progress
												type="circle"
												percent={Math.round(
													(record.totAcceptedLead /
														(record.totAcceptedLead +
															(record.allocatedLead -
																record.totAcceptedLead))) *
													100
												)}
												width={30}
											/>
										) : (
											<Progress
												type="circle"
												percent={Math.round(
													((record.qaReviewLead + record.acceptedLead) /
														record.allocatedLead) *
													100
												)}
												width={30}
											/>
										)}


									</div>
								),
							};
						},
					},
				],
			},

			{
				title: "Start Date",
				dataIndex: "startDate",
				key: "ABM",
				width: "7%",
				align: "center",
				render: (text, record) => <div>{text}</div>,
			},

			{
				title: "End Date",
				dataIndex: "endDate",
				key: "endDate",
				width: "7%",
				align: "center",
				render: (text, record) => <div>{text}</div>,
			},
			{
				title: "Total Leads",
				dataIndex: "allocatedLead",
				key: "allocatedLead",
				width: "5%",
				align: "center",
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.allocatedLead}</span>
					</div>
				),
			},


			{
				title: "Delivery Status",

				children: [
					{
						title: "Delivered",
						dataIndex: "deliveredLead",
						key: "deliveredLead",
						align: "center",
						width: "6%",
						render: (text, record) => (
							<div>
								{record.status === "Cancel" ||
									record.campaignStatus === "Cancel Campaign" ? (
									0
								) : (
									<div style={{ fontSize: "12px" }}>
										{(text ? text : 0) +
											(record.totalReject ? record.totalReject : 0) >
											0 ? (
											/* Sonali-4241-adding handledownloadLead instead of # in href for removing warning */

											<a
												href="handledownloadLead"
												onClick={this.handledownloadLead.bind(this)}
												id={record.campID}
												leadtype={"Delivered"}
												reallocationID={record.reallocationID}
												parentCampID={
													record.parentCampID
														? record.parentCampID
														: record.campID
												}>
												{(text ? text : 0) +
													(record.totalReject ? record.totalReject : 0)}
											</a>
										) : (
											0
										)}
									</div>
								)}
							</div>
						),
					},
					{
						title: "Review Pending",
						dataIndex: "qaReviewLead",
						key: "qaReviewLead",
						align: "center",
						width: "6%",
						render: (text, record) => (
							<div>
								{record.status === "Cancel" ||
									record.campaignStatus === "Cancel Campaign" ? (
									0
								) : (
									<div style={{ fontSize: "12px", color: "green" }}>
										{text > 0 ? (
											//  Sonali-4241-adding handledownloadLead instead of # in href for removing warning

											<a
												href="handledownloadLead"
												style={{ fontSize: "12px", color: "#37eb34" }}
												onClick={this.handledownloadLead.bind(this)}
												id={record.campID}
												leadtype={"Review-Pending"}
												reallocationID={record.reallocationID}
												parentCampID={
													record.parentCampID
														? record.parentCampID
														: record.campID
												}>
												{text}
											</a>
										) : (
											<span style={{ fontSize: "12px" }}>0</span>
										)}
									</div>
								)}
							</div>
						),
					},
					{
						title: "Accepted",
						dataIndex: "acceptedLead",
						key: "acceptedLead",
						align: "center",
						width: "6%",
						render: (text, record) => (
							<div>
								{record.status === "Cancel" ||
									record.campaignStatus === "Cancel Campaign" ? (
									0
								) : (
									<div style={{ fontSize: "12px", color: "green" }}>
										{text > 0 ? (
											//  Sonali-4241-adding handledownloadLead instead of # in href for removing warning

											<a
												href="handledownloadLead"
												onClick={this.handledownloadLead.bind(this)}
												style={{ fontSize: "12px", color: "#37eb34" }}
												id={record.campID}
												leadtype={"Accepted"}
												reallocationID={record.reallocationID}
												parentCampID={
													record.parentCampID
														? record.parentCampID
														: record.campID
												}>
												{text}
											</a>
										) : (
											<span style={{ fontSize: "12px" }}>0</span>
										)}
									</div>
								)}
							</div>
						),
					},
					{
						title: "Rejected",
						dataIndex: "rejectedLead",
						key: "rejectedLead",
						align: "center",
						width: "6%",
						render: (text, record) => (
							<div>
								{record.status === "Cancel" ||
									record.campaignStatus === "Cancel Campaign" ? (
									0
								) : (
									<div style={{ fontSize: "12px" }}>
										{text > 0 ? (
											//  Sonali-4241-adding handledownloadLead instead of # in href for removing warning

											<a
												href="handledownloadLead"
												onClick={this.handledownloadLead.bind(this)}
												style={{ fontSize: "12px", color: "red" }}
												id={record.campID}
												leadtype={"Rejected"}
												reallocationID={record.reallocationID}
												parentCampID={
													record.parentCampID
														? record.parentCampID
														: record.campID
												}>
												{text}
											</a>
										) : (
											0
										)}
									</div>
								)}
							</div>
						),
					},
					{
						title: "Balance",
						dataIndex: "totAcceptedLead",
						key: "totAcceptedLead",
						align: "center",
						width: "6%",
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>
										{record.status === "Cancel" ||
											record.campaignStatus === "Cancel Campaign" ? (
											0
										) : (
											<div style={{ fontSize: "12px" }}>
												{record.balancedLead}
											</div>
										)}
									</div>
								),
							};
						},
					},
				],
			},
			{
				title: "Action",
				dataIndex: "allocatedLead",
				key: "ActionFirst",
				width: "80px",
				align: "center",
				//shivani-3611- call onActionClick function to show custom div with option.
				render: (text, record, index) => (
					<div key={index}>
						<div
							className="action_context_menu_icon"
							onClick={(e) => this.onActionClick(e, record, index, text)}>
							<span
								style={{
									padding: 5,
									position: "relative",
									top: 15,
									left: 0,
								}}>
								...
							</span>
						</div>
					</div>
				),
			},
		];

		const columnsPQA = [
			{
				title: "Camp ID",
				dataIndex: "campID",
				key: "campID",
				width: "8%",
				align: "center",
				sorter: (a, b) => a.campID - b.campID,
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>
							{record.parentCampID ? record.parentCampID : text}
						</span>
					</div>
				),
			},
			//Sonali 3360-added below missing code

			// Sandeep-task-3275-added new column for agency camp ID
			{
				title: "Agency Camp ID",
				dataIndex: "agencycampID",
				key: "agencycampID",
				width: "7.5%",

				align: "center",
				sorter: (a, b) => a.agencycampID - b.agencycampID,
				render: (text, record) => (
					<div style={{ width: "75px" }}>
						<span style={{ fontSize: "12px" }}>
							{record.clientCampID ? record.clientCampID : text}
						</span>
						<br />
					</div>
				),
			},
			{
				title: "Camp Name",
				dataIndex: "campaignName",
				key: "campaignName",
				width: "18%",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";
					if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.campaignName.toUpperCase() > b.campaignName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div style={{ float: "left" }}>
						{/*sunita-task-3008-added css for proper alignment */}
						<div class="dot1" style={{ width: "175px" }}>
							{/*sunita-task-3008-added css for proper alignment*/}
							{/* Sonali-4241-adding displayCampaignSpecification instead of # in href for removing warning */}

							<a
								href="displayCampaignSpecification"
								onClick={this.displayCampaignSpecification}
								campid={record.campID}
								campaignname={record.campaignName}
								status="mainTab"
								parentcampid={record.parentCampID}
								reallocationid={record.reallocationID}
								pid={record.pID}
								canceltab={this.state.cancelTab}
								style={{ fontSize: "11px" }}>
								{record.campaignName}
							</a>
							{/*sunita-task-3008-removed the css as it's affecting */}
						</div>

						<span style={{ float: "left" }}>
							<span style={{ fontSize: "11px" }}>
								Days Left:&nbsp;
								{record.DateDiff <= 2 ? (
									<span style={{ color: "red", fontWeight: "700" }}>
										{record.DateDiff}
									</span>
								) : record.DateDiff <= 5 && record.DateDiff > 2 ? (
									<span style={{ color: "orange", fontWeight: "1000" }}>
										{record.DateDiff}
									</span>
								) : (
									<span style={{ color: "green", fontWeight: "1000" }}>
										{record.DateDiff}
									</span>
								)}
							</span>

							<br />
							{this.state.userlogger === "PC" ? (
								<span style={{ fontSize: "11px" }}>
									CPL:{" "}
									<span style={{ fontWeight: "700" }}>
										{record.CPL}
										{record.currency}
									</span>{" "}
								</span>
							) : (
								""
							)}
						</span>

						{this.state.tabName === "Paused" ? (
							<div>
								<span
									style={{
										color: "red",
										float: "left",
										fontSize: "11px",
										fontWeight: "400",
									}}>
									<b>Reason For Pause:</b>&nbsp;&nbsp;
									<label style={{ color: "black", fontWeight: "400" }}>
										{record.reason}
									</label>
								</span>
							</div>
						) : (
							""
						)}
						<br />
					</div>
				),
			},
			{
				title: "Progress Status",

				children: [
					{
						title: (
							<Tooltip placement="top" title="Creatives Status">
								Creative Approval
							</Tooltip>
						),
						dataIndex: "creativeApproval",
						key: "creativeApproval",
						align: "center",
						width: "6%",
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>

										{/* Sonali-Task 2902-added below undefined conditions(if undefined i.e creative approval is not required so we are displyig the percentage as 100) */}
										<Progress
											type="circle"
											strokeColor="rgb(135, 208, 104)"
											percent={record.creativesPercentage}
											width={30}
										></Progress>
									</div>
								),
							};
						},
					},
					{
						title: (
							<Tooltip placement="top" title="Delivery Status">
								Delivery Status
							</Tooltip>
						),
						dataIndex: "deliveryStatus",
						key: "deliveryStatus",
						align: "center",
						width: "6%",
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>
										{record.requiredLeadPerAsset === "Yes" ? (
											<Progress
												type="circle"
												percent={Math.round(
													(record.totAcceptedLead /
														(record.totAcceptedLead +
															(record.allocatedLead -
																record.totAcceptedLead))) *
													100
												)}
												width={30}
											/>
										) : (
											<Progress
												type="circle"
												percent={Math.round(
													((record.qaReviewLead + record.acceptedLead) /
														record.allocatedLead) *
													100
												)}
												width={30}
											/>
										)}

									</div>
								),
							};
						},
					},
				],
			},


			{
				title: "Start Date",
				dataIndex: "startDate",
				key: "ABM",
				width: "7%",
				align: "center",
				render: (text, record) => <div>{text}</div>,
			},

			{
				title: "End Date",
				dataIndex: "endDate",
				key: "endDate",
				width: "7%",
				align: "center",
				render: (text, record) => <div>{text}</div>,
			},
			{
				title: "Total Leads",
				dataIndex: "allocatedLead",
				key: "allocatedLead",
				width: "5%",
				align: "center",
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.allocatedLead}</span>
					</div>
				),
			},

			{
				title: "Delivery Status",

				children: [
					{
						title: "Delivered",
						dataIndex: "deliveredLead",
						key: "deliveredLead",
						align: "center",
						width: "6%",
						render: (text, record) => (
							<div>
								{record.status === "Cancel" ||
									record.campaignStatus === "Cancel Campaign" ? (
									0
								) : (
									<div style={{ fontSize: "12px" }}>
										{(text ? text : 0) +
											(record.totalReject ? record.totalReject : 0) >
											0 ? (
											// Sonali-4241-adding handledownloadLead instead of # in href for removing warning */}

											<a
												href="handledownloadLead"
												onClick={this.handledownloadLead.bind(this)}
												id={record.campID}
												leadtype={"Delivered"}
												reallocationID={record.reallocationID}
												parentCampID={
													record.parentCampID
														? record.parentCampID
														: record.campID
												}>
												{(text ? text : 0) +
													(record.totalReject ? record.totalReject : 0)}
											</a>
										) : (
											0
										)}
									</div>
								)}
							</div>
						),
					},
					{
						title: "Review Pending",
						dataIndex: "qaReviewLead",
						key: "qaReviewLead",
						align: "center",
						width: "6%",
						render: (text, record) => (
							<div>
								{record.status === "Cancel" ||
									record.campaignStatus === "Cancel Campaign" ? (
									0
								) : (
									<div style={{ fontSize: "12px", color: "green" }}>
										{text > 0 ? (
											// Sonali-4241-adding handledownloadLead instead of # in href for removing warning */}

											<a
												href="handledownloadLead"
												style={{ fontSize: "12px", color: "#37eb34" }}
												onClick={this.handledownloadLead.bind(this)}
												id={record.campID}
												leadtype={"Review-Pending"}
												reallocationID={record.reallocationID}
												parentCampID={
													record.parentCampID
														? record.parentCampID
														: record.campID
												}>
												{text}
											</a>
										) : (
											<span style={{ fontSize: "12px" }}>0</span>
										)}
									</div>
								)}
							</div>
						),
					},
					{
						title: "Accepted",
						dataIndex: "acceptedLead",
						key: "acceptedLead",
						align: "center",
						width: "6%",
						render: (text, record) => (
							<div>
								{record.status === "Cancel" ||
									record.campaignStatus === "Cancel Campaign" ? (
									0
								) : (
									<div style={{ fontSize: "12px", color: "green" }}>
										{text > 0 ? (
											// Sonali-4241-adding handledownloadLead instead of # in href for removing warning */}

											<a
												href="handledownloadLead"
												onClick={this.handledownloadLead.bind(this)}
												style={{ fontSize: "12px", color: "#37eb34" }}
												id={record.campID}
												leadtype={"Accepted"}
												reallocationID={record.reallocationID}
												parentCampID={
													record.parentCampID
														? record.parentCampID
														: record.campID
												}>
												{text}
											</a>
										) : (
											<span style={{ fontSize: "12px" }}>0</span>
										)}
									</div>
								)}
							</div>
						),
					},
					{
						title: "Rejected",
						dataIndex: "rejectedLead",
						key: "rejectedLead",
						align: "center",
						width: "6%",
						render: (text, record) => (
							<div>
								{record.status === "Cancel" ||
									record.campaignStatus === "Cancel Campaign" ? (
									0
								) : (
									<div style={{ fontSize: "12px" }}>
										{text > 0 ? (
											// Sonali-4241-adding handledownloadLead instead of # in href for removing warning */}

											<a
												href="handledownloadLead"
												onClick={this.handledownloadLead.bind(this)}
												style={{ fontSize: "12px", color: "red" }}
												id={record.campID}
												leadtype={"Rejected"}
												reallocationID={record.reallocationID}
												parentCampID={
													record.parentCampID
														? record.parentCampID
														: record.campID
												}>
												{text}
											</a>
										) : (
											0
										)}
									</div>
								)}
							</div>
						),
					},
					{
						title: "Balance",
						dataIndex: "totAcceptedLead",
						key: "totAcceptedLead",
						align: "center",
						width: "6%",
						render(text, record) {
							return {
								props: {
									style: { wordWrap: "break-word", height: "5%" },
								},
								children: (
									<div>
										{record.status === "Cancel" ||
											record.campaignStatus === "Cancel Campaign" ? (
											0
										) : (
											<div style={{ fontSize: "12px" }}>
												{record.balancedLead}
											</div>
										)}
									</div>
								),
							};
						},
					},
				],
			},
		];

		return (
			<div>
				<PublisherNavigation />
				<div
					class="container-fluid"
					style={{ paddingTop: "85px", paddingBottom: "40px" }}>
					<div
						style={{
							backgroundColor: "rgb(241, 241, 241)",
							paddingTop: "22px",
							borderBottom: "1px solid #a9a9a97d",
						}}>
						{/*sunita-task-3008-added css for proper alignment*/}
						<div class="row">
							<div class="head-one col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								{/*sunita-task-3008-removed css alredy above is given no need to add here so removed and as per device added col*/}
								<p
									style={{
										fontFamily: "roboto",
										color: "black",
										fontSize: "26px",
										fontWeight: "500",
										marginBottom: "12px",
									}}>
									Campaign Progress
								</p>
								{/*sunita-task-3008-added css for campaign overview */}
								{/* Sandeep-task-3217-label change from campaign overview to campaign Progress */}
							</div>
							{/*sunita-task-3008 removed the code of campaignprogressreport and filter as per newUI screen and added below */}
						</div>

						<div class="row">
							<div class="col-xs-7 col-sm-12 col-md-8 col-lg-9 col-xl-10">
								{/*sunita-task-3008-changed col as per device wise */}
								{this.state.dashboardCampaignCount.map(
									(dashboardCampaignCount, index) => (
										<div
											class="float-center topnav tabDiv"
											key={index}
											style={{ height: "37px" }}>
											{" "}
											{/*sunita-task-3008-added classname to apply css */}
											{/* Sonali-4241-adding campaignLink instead of # in href for removing warning */}

											<a
												href="campaignLink"
												class={this.state.linkActive + " countClass"}
												onClick={this.campaignLink}
												id="Active"
												style={{ color: "black" }}>
												Active&nbsp;
												<span
													style={{
														height: "22px",
														paddingLeft: "5px",
														paddingRight: "5px",
														backgroundColor: "green",
														borderRadius: "18%",
														fontSize: "16px",
														color: "white",
														textAlign: "center",
														display: "inline-block",
													}}>
													{dashboardCampaignCount.activeCount}
												</span>
											</a>
											{/*sunita-task-3008-removed code of pending tab as it's not present */}
											&nbsp;&nbsp;&nbsp;&nbsp;
											{/* Sonali-4241-adding campaignLink instead of # in href for removing warning */}

											<a
												href="campaignLink"
												class={
													this.state.linkActive2 + " countClass tabPause"
												}
												onClick={this.campaignLink}
												id="Paused"
												style={{ color: "black", paddingLeft: "10px" }}>
												Paused&nbsp;
												<span
													style={{
														height: "22px",
														paddingLeft: "5px",
														paddingRight: "5px",
														backgroundColor: "blue",
														borderRadius: "18%",
														fontSize: "16px",
														color: "white",
														textAlign: "center",
														display: "inline-block",
													}}>
													{dashboardCampaignCount.pausedCount}
												</span>
											</a>
											{/*sunita-task-3008-added tabpause classname as per requirement for setting the alignement */}
											&nbsp;&nbsp;
											{/* Sonali-4241-adding campaignLink instead of # in href for removing warning */}

											<a
												href="campaignLink"
												class={
													this.state.linkActive3 +
													" countClass" +
													" tabComplete"
												}
												onClick={this.campaignLink}
												id="Completed"
												style={{
													color: "black",
													paddingLeft: "10px",
													height: "36px",
												}}>
												Completed&nbsp;
												<span
													style={{
														height: "22px",
														paddingLeft: "5px",
														paddingRight: "5px",
														backgroundColor: "#484141",
														borderRadius: "18%",
														fontSize: "16px",
														color: "white",
														textAlign: "center",
														display: "inline-block",
													}}>
													{dashboardCampaignCount.completeCount}
												</span>
											</a>
											{/*sunita-task-3008-added tabComplete classname as per requirement for setting the alignement */}
											&nbsp;&nbsp;&nbsp;&nbsp;
											{/* Sonali-4241-adding campaignLink instead of # in href for removing warning */}

											<a
												href="campaignLink"
												class={
													this.state.linkActive4 + " countClass tabCancel"
												}
												onClick={this.campaignLink}
												id="Cancel"
												style={{
													color: "black",
													paddingLeft: "10px",
													height: "36px",
												}}>
												Cancelled&nbsp;
												<span
													style={{
														height: "22px",
														paddingLeft: "5px",
														paddingRight: "5px",
														backgroundColor: "#FF0000",
														borderRadius: "18%",
														fontSize: "16px",
														color: "white",
														textAlign: "center",
														display: "inline-block",
													}}>
													{dashboardCampaignCount.cancelCount}
												</span>
											</a>
											{/*sunita-task-3008-changed as per newUI cancel as Cancelled */}
											&nbsp;&nbsp;&nbsp;&nbsp;
										</div>
									)
								)}
							</div>

							<div class="col-xs-12 col-sm-12  col-md-4 col-lg-3 col-xl-2">
								{/*sunita-task-3008-changed col as per device wise */}
								<span class="float-right">
									{/*sunita-task-3008-reduced margin to align properly*/}

									<input
										id="search-bar2"
										type="text"
										style={{
											backgroundImage: "url(Group_294.png)",
											backgroundPosition: "15px 6px",
											backgroundSize: "15px 15px",
											backgroundRepeat: "no-repeat",
											width: "100%",
											height: "20px",
											fontSize: "10px",
											border: "1px solid #ddd",
											padding: "12px 20px 12px 34px",
											borderRadius: "25px",
											backgroundColor: "rgb(221, 221, 221)",
										}}
										onChange={this.handleChangeSearch}
										placeholder="Search..."
										title="Search"
									/>
								</span>
							</div>
						</div>
					</div>
					<div class="row" style={{ marginTop: "10px" }}>
						{/*sunita-task-3008-added new row in that above campaignprogressreport and filter code added */}

						<div class="col-xs-12 col-sm-12 col-md-5 col-lg-7 col-xl-8">
							<label
								style={{
									color: "#71717199",
									fontFamily: "Roboto",
									marginLeft: "15px",
								}}>
								Showing results for &nbsp;{" "}
							</label>{" "}
							<span
								style={{
									color: "#000",
									fontFamily: "Roboto",
									fontWeight: "500",
								}}>
								{" "}
								"{this.state.label}"
							</span>{" "}
							{/*sunita-task-3008-added as per newUI screen changes */}
						</div>

						<div class="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-4">

							{this.state.tabName === "Active" ||
								this.state.tabName === "Completed" ? ( //sunita-task-3008-as per tabname campaignprogress report will be downloaded.
								<div className="col-xs-10 col-sm-10 col-md-4 col-lg-4 col-xl-4 filterDiv">
									<span>
										{/* kiran-bug 4255-change classname to dropdownPub for responsiveness issue */}
										<div
											className="float-right dropdownPub"
											style={{
												border: "1px solid #d9d9d9",
												backgroundColor: "white",
												padding: "2px 7px",
											}}>
											{/*sunita-task-3008-added css as per requirement */}

											<div
												className="float-left"
												style={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													width: "82px",
												}}>
												{" "}
												<span id="myText">{this.state.label}</span>
											</div>
											{/*sunita-task-3008-instead of pacing given label value for printing filter value */}
											<img
												alt=""
												className="float-right filterImg"
												src="Filter.png"
												height="14px"
												width="14px"></img>
											{/*sunita-task-3008-changes ths size of filter image */}
											{/* Sandeep-task-2911 to handle filter size increment issue  */}
											{/* kiran-bug 4255-change classname to dropdown-contentPub for responsiveness issue */}
											<div class="dropdown-contentPub">
												<a
													href="#/"
													value="All"
													onClick={this.handleChangeFilter}
													name="All"
													id="All"
													label="All">
													All
												</a>
												{/*sunita-task-3008-in each field i have added label in this line as well as below also on FE for showing pacing value */}
												<a
													href="#/"
													value="Weekly"
													onClick={this.handleChangeFilter}
													name="Weekly"
													id="Weekly"
													label="Ending This Week">
													Ending This Week
												</a>
												<a
													href="#/"
													value="Monthly"
													onClick={this.handleChangeFilter}
													name="Monthly"
													id="Monthly"
													label="Ending This Month">
													Ending This Month
												</a>
												<a
													href="#/"
													value="Quarterly"
													onClick={this.handleChangeFilter}
													name="Quarterly"
													id="Quarterly"
													label="Ending This Quarter">
													Ending This Quarter
												</a>
												<a
													href="#/"
													value="Yearly"
													onClick={this.handleChangeFilter}
													name="Yearly"
													id="Yearly"
													label="Ending This Year">
													Ending This Year
												</a>
												<a
													href="#/"
													value="PreMonth"
													onClick={this.handleChangeFilter}
													name="PreMonth"
													id="PreMonth"
													label="Ending Previous Month">
													Ending Previous Month
												</a>
												<a
													href="#/"
													value="PreQuarter"
													onClick={this.handleChangeFilter}
													name="PreQuarter"
													id="PreQuarter"
													label="Ending Previous Quarter">
													Ending Previous Quarter
												</a>
												<a
													href="#/"
													value="PreYear"
													onClick={this.handleChangeFilter}
													name="PreYear"
													id="PreYear"
													label="Ending Previous Year">
													Ending Previous Year
												</a>
											</div>
										</div>
										{/* } */}
									</span>
								</div>
							) : (
								<div
									className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 filterDiv "
									style={{ marginRight: "3px" }}>
									<span>
										<div
											className="float-right dropdownPub"
											style={{
												border: "1px solid #d9d9d9",
												backgroundColor: "white",
												padding: "2px 7px",
											}}>
											{/*sunita-task-3008-added css */}

											<div
												className="float-left"
												style={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													width: "82px",
												}}>
												{" "}
												<span id="myText">{this.state.label}</span>
											</div>
											{/*sunita-task-3008-instead of pacing given label value for printing filter value */}
											<img
												alt=""
												className="float-right filterImg"
												src="Filter.png"
												height="14px"
												width="14px"></img>
											{/*sunita-task-3008-changes ths size of filter image */}
											{/* Sandeep-task-2911 to handle filter size increment issue  */}
											{/* kiran-bug 4255-change classname to dropdown-contentPub for responsiveness issue */}
											<div class="dropdown-contentPub">
												<a
													href="#/"
													value="All"
													onClick={this.handleChangeFilter}
													name="All"
													id="All"
													label="All">
													All
												</a>
												{/*sunita-task-3008-in each field i have added label in this line as well as below also on FE for showing pacing value */}
												<a
													href="#/"
													value="Weekly"
													onClick={this.handleChangeFilter}
													name="Weekly"
													id="Weekly"
													label="Ending This Week">
													Ending This Week
												</a>
												<a
													href="#/"
													value="Monthly"
													onClick={this.handleChangeFilter}
													name="Monthly"
													id="Monthly"
													label="Ending This Month">
													Ending This Month
												</a>
												<a
													href="#/"
													value="Quarterly"
													onClick={this.handleChangeFilter}
													name="Quarterly"
													id="Quarterly"
													label="Ending This Quarter">
													Ending This Quarter
												</a>
												<a
													href="#/"
													value="Yearly"
													onClick={this.handleChangeFilter}
													name="Yearly"
													id="Yearly"
													label="Ending This Year">
													Ending This Year
												</a>
												<a
													href="#/"
													value="PreMonth"
													onClick={this.handleChangeFilter}
													name="PreMonth"
													id="PreMonth"
													label="Ending Previous Month">
													Ending Previous Month
												</a>
												<a
													href="#/"
													value="PreQuarter"
													onClick={this.handleChangeFilter}
													name="PreQuarter"
													id="PreQuarter"
													label="Ending Previous Quarter">
													Ending Previous Quarter
												</a>
												<a
													href="#/"
													value="PreYear"
													onClick={this.handleChangeFilter}
													name="PreYear"
													id="PreYear"
													label="Ending Previous Year">
													Ending Previous Year
												</a>
											</div>
										</div>
										{/* } */}
									</span>
								</div>
							)}

							{this.state.tabName === "Active" ||
								this.state.tabName === "Completed" ? ( //sunita-task-3008-as per tabname campaignprogress report will be downloaded.
								<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 progressRportDiv">
									<label style={{ color: "#1890ff" }} className="progressRport">
										Campaign Progress Report{" "}
										{/*sunita-task-3008-added label campaign progress report */}
										<span
											style={{
												float: "right",
												marginTop: "0px",
												marginLeft: "4px",
											}}>
											{/*sunita-task-3008-added css for report*/}

											{this.state.isprogressBtnDisable === false ? ( // sunita-task-3008-as per value get in isprogressBtnDisable it will disable or enable the download button
												//Sonali-4241-adding download instead of # in href for removing warning
												<a href="download">
													<i
														class="fa fa-download"
														onClick={this.downloadCampaignExcel}></i>
												</a>
											) : (

												<a href="download">
													<i
														class="fa fa-download"
														disabled={this.state.isprogressBtnDisable}></i>
												</a>
											)}
										</span>
									</label>
								</div>
							) : (
								""
							)}
						</div>
					</div>
					{/* //snehal-task-3640-DI--Campaign Progress page--add DI loader in camp progress page */}
					<div align="center" id={this.state.loader_progress}></div>
					<div class="row">
						{/*sunita-task-3008-added row  */}
						<div
							class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
							style={{ paddingTop: "5px" }}>
							{this.state.tableSearchText === "" ? (
								//Sonali-3232-added this to show loader other wise if data is not present show table in else part which do not have loader

								this.state.dashActiveCount > 0 ? (
									<Table
										{...this.state.tableState}
										id="myTable"
										bordered
										dataSource={this.state.campaignDetails}
										// Sandeep-task-3228-added code to hide Action column from PQA
										//Sonali-4241-replacing == by === to remove warnings

										columns={user.role !== "PQA" ? columns : columnsPQA}
										//locale={locale}//Sonali-3232-loader changed for loaded and no data image issue
										//loading={{ indicator:loaderIcon,spinning: this.state.loading }}//Sonali-3232-loader changed for loaded and no data image issue
										loading={this.state.loading}
										size="small"
										className={tableCSS}
										scroll={{ x: 1250, y: 4000 }}
										expandedRowRender={(record) => (
											<div class="container-fluid">
												<div class="col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
												{/* kiran-4746-removing console warning-changed == to === */}

													{record.pacingUnit === "" ||
														record.pacingUnit === undefined ||
														record.pacingUnit == null ? (
														""
													) : (
														<div>
															<label style={{ fontSize: "12px" }}>
																Unit : {record.pacingUnit}
															</label>
															<table class="table table-bordered">
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Pacing Month
																	</th>
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{pacingCampaignDetails.pacingMonth}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>

																{record.pacingUnit === "Percentage" ? (
																	<tr>
																		<th style={{ fontSize: "12px" }}>
																			Pacing (%)
																		</th>
																		{this.state.pacingCampaignDetails.map(
																			(pacingCampaignDetails, i) => {
																				return record.campID ===
																					pacingCampaignDetails.campID ? (
																					<td style={{ fontSize: "12px" }}>
																						{
																							pacingCampaignDetails.pacingPercentage
																						}
																						%
																					</td>
																				) : (
																					""
																				);
																			}
																		)}
																	</tr>
																) : (
																	<tr>
																		<th style={{ fontSize: "12px" }}>
																			Pacing Lead Count
																		</th>
																		{/* kiran-4746-removing console warning-changed == to === */}

																		{this.state.pacingCampaignDetails.map(
																			(pacingCampaignDetails, i) => {
																				return record.campID ===
																					pacingCampaignDetails.campID ? (
																					<td style={{ fontSize: "12px" }}>
																						{
																							pacingCampaignDetails.pacingLeadCount
																						}
																					</td>
																				) : (
																					""
																				);
																			}
																		)}
																	</tr>
																)}
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Pacing End Date
																	</th>
																	{/* kiran-4746-removing console warning-changed == to === */}

																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{pacingCampaignDetails.pacingEndDate}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Pacing Carry Forward
																	</th>
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{
																						pacingCampaignDetails.pacingCarryForward
																					}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Carry Forward Leads (Yes)
																	</th>
																	{/* kiran-4746-removing console warning-changed == to === */}
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{pacingCampaignDetails.carryLeadCount}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Carry Forward Leads (No)
																	</th>
																	{/* kiran-4746-removing console warning-changed == to === */}
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{
																						pacingCampaignDetails.carryLeadCountNo
																					}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Extra Leads Delivered
																	</th>
																	{/* kiran-4746-removing console warning-changed == to === */}
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{
																						pacingCampaignDetails.leadCountAllocation
																					}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>

															</table>
														</div>
													)}
												</div>

												<div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
													{/* Sandeep-task-3228-added code for PQA */}
													{/* Sonali-4241-replacing == by === to remove warnings */}

													{user.role !== "PNC" && user.role !== "PQA" ? (
														<Tooltip placement="bottom" title="IO Download">
															{/* Sonali-4241-adding download instead of # in href for removing warning */}

															<a
																href="downloadIODocument"
																id={record.campID}
																onClick={this.downloadIODocument.bind(this)}>
																<img
																	src="download_io.png"
																	alt=""//sonali-4241-added to remove warnings
																	height="30px"
																	width="30px"
																	id={record.campID}></img>
															</a>
														</Tooltip>
													) : (
														""
													)}
												</div>
											</div>
										)}
										pagination={{
											pageSize: this.state.pageSize,
											position: "bottom",
										}}></Table>
								) : (
									<Table
										{...this.state.tableState}
										id="myTable"
										bordered
										dataSource={this.state.campaignDetails}
										// Sandeep-task-3228-added code to hide Action column from PQA
										//Sonali-4241-replacing == by === to remove warnings

										columns={user.role !== "PQA" ? columns : columnsPQA}
										size="small"
										className={tableCSS}
										scroll={{ x: 1250, y: 4000 }}
										expandedRowRender={(record) => (
											<div class="container-fluid">
												{/* kiran-4746-removing console warning-changed == to === */}
												<div class="col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
													{record.pacingUnit === "" ||
														record.pacingUnit === undefined ||
														record.pacingUnit == null ? (
														""
													) : (
														<div>
															<label style={{ fontSize: "12px" }}>
																Unit : {record.pacingUnit}
															</label>
															<table class="table table-bordered">
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Pacing Month
																	</th>
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{pacingCampaignDetails.pacingMonth}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
																{/* kiran-4746-removing console warning-changed == to === */}
																{record.pacingUnit === "Percentage" ? (
																	<tr>
																		<th style={{ fontSize: "12px" }}>
																			Pacing (%)
																		</th>
																		{this.state.pacingCampaignDetails.map(
																			(pacingCampaignDetails, i) => {
																				return record.campID ===
																					pacingCampaignDetails.campID ? (
																					<td style={{ fontSize: "12px" }}>
																						{
																							pacingCampaignDetails.pacingPercentage
																						}
																						%
																					</td>
																				) : (
																					""
																				);
																			}
																		)}
																	</tr>
																) : (
																	<tr>
																		<th style={{ fontSize: "12px" }}>
																			Pacing Lead Count
																		</th>
																		{this.state.pacingCampaignDetails.map(
																			(pacingCampaignDetails, i) => {
																				return record.campID ===
																					pacingCampaignDetails.campID ? (
																					<td style={{ fontSize: "12px" }}>
																						{
																							pacingCampaignDetails.pacingLeadCount
																						}
																					</td>
																				) : (
																					""
																				);
																			}
																		)}
																	</tr>
																)}
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Pacing End Date
																	</th>
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{pacingCampaignDetails.pacingEndDate}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Pacing Carry Forward
																	</th>
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{
																						pacingCampaignDetails.pacingCarryForward
																					}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Carry Forward Leads (Yes)
																	</th>
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{pacingCampaignDetails.carryLeadCount}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Carry Forward Leads (No)
																	</th>
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{
																						pacingCampaignDetails.carryLeadCountNo
																					}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Extra Leads Delivered
																	</th>
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{
																						pacingCampaignDetails.leadCountAllocation
																					}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>

															</table>
														</div>
													)}
												</div>

												<div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
													{/* Sandeep-task-3228-added code for PQA */}
													{/* Sonali-4241-replacing == by === to remove warnings */}

													{user.role !== "PNC" && user.role !== "PQA" ? (
														<Tooltip placement="bottom" title="IO Download">
															{/* Sonali-4241-adding download instead of # in href for removing warning */}

															<a
																href="downloadIODocument"
																id={record.campID}
																onClick={this.downloadIODocument.bind(this)}>
																<img
																	src="download_io.png"
																	alt=""//sonali-4241-added to remove warnings
																	height="30px"
																	width="30px"
																	id={record.campID}></img>
															</a>
														</Tooltip>
													) : (
														""
													)}
												</div>
											</div>
										)}
										pagination={{
											pageSize: this.state.pageSize,
											position: "bottom",
										}}></Table>
								)
							) : (
								<Table
									{...this.state.tableState}
									id="myTable"
									bordered
									dataSource={this.state.campaignDetailsSearch}
									columns={columns}
									loading={this.state.loading}
									size="small"
									className={tableCSS}
									scroll={{ x: 1250, y: 4000 }}
									expandedRowRender={(record, key) => (
										<div class="container-fluid">
											{/* kiran-4746-removing console warning-changed == to === */}
											<div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
												{record.pacingUnit === "" ||
													record.pacingUnit === undefined ||
													record.pacingUnit == null ? (
													""
												) : (
													<div>
														<label style={{ fontSize: "12px" }}>
															Unit : {record.pacingUnit}
														</label>
														<table class="table table-bordered">
															<tr>
																<th style={{ fontSize: "12px" }}>
																	Pacing Month
																</th>
																{this.state.pacingCampaignDetails.map(
																	(pacingCampaignDetails, i) => {
																		return record.campID ===
																			pacingCampaignDetails.campID ? (
																			<td style={{ fontSize: "12px" }}>
																				{pacingCampaignDetails.pacingMonth}
																			</td>
																		) : (
																			""
																		);
																	}
																)}
															</tr>

															{record.pacingUnit === "Percentage" ? (
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Pacing (%)
																	</th>
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{
																						pacingCampaignDetails.pacingPercentage
																					}
																					%
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
															) : (
																<tr>
																	<th style={{ fontSize: "12px" }}>
																		Pacing Lead Count
																	</th>
																	{/* kiran-4746-removing console warning-changed == to === */}
																	{this.state.pacingCampaignDetails.map(
																		(pacingCampaignDetails, i) => {
																			return record.campID ===
																				pacingCampaignDetails.campID ? (
																				<td style={{ fontSize: "12px" }}>
																					{
																						pacingCampaignDetails.pacingLeadCount
																					}
																				</td>
																			) : (
																				""
																			);
																		}
																	)}
																</tr>
															)}
															<tr>
																<th style={{ fontSize: "12px" }}>
																	Pacing End Date
																</th>
																{/* kiran-4746-removing console warning-changed == to === */}
																{this.state.pacingCampaignDetails.map(
																	(pacingCampaignDetails, i) => {
																		return record.campID ===
																			pacingCampaignDetails.campID ? (
																			<td style={{ fontSize: "12px" }}>
																				{pacingCampaignDetails.pacingEndDate}
																			</td>
																		) : (
																			""
																		);
																	}
																)}
															</tr>
															<tr>
																<th style={{ fontSize: "12px" }}>
																	Pacing Carry Forward
																</th>
																{this.state.pacingCampaignDetails.map(
																	(pacingCampaignDetails, i) => {
																		return record.campID ===
																			pacingCampaignDetails.campID ? (
																			<td style={{ fontSize: "12px" }}>
																				{
																					pacingCampaignDetails.pacingCarryForward
																				}
																			</td>
																		) : (
																			""
																		);
																	}
																)}
															</tr>
															<tr>
																<th style={{ fontSize: "12px" }}>
																	Carry Forward Leads (Yes)
																</th>
																{this.state.pacingCampaignDetails.map(
																	(pacingCampaignDetails, i) => {
																		return record.campID ===
																			pacingCampaignDetails.campID ? (
																			<td style={{ fontSize: "12px" }}>
																				{pacingCampaignDetails.carryLeadCount}
																			</td>
																		) : (
																			""
																		);
																	}
																)}
															</tr>
															<tr>
																<th style={{ fontSize: "12px" }}>
																	Carry Forward Leads (No)
																</th>
																{/* kiran-4746-removing console warning-changed == to === */}
																{this.state.pacingCampaignDetails.map(
																	(pacingCampaignDetails, i) => {
																		return record.campID ===
																			pacingCampaignDetails.campID ? (
																			<td style={{ fontSize: "12px" }}>
																				{pacingCampaignDetails.carryLeadCountNo}
																			</td>
																		) : (
																			""
																		);
																	}
																)}
															</tr>
															<tr>
																<th style={{ fontSize: "12px" }}>
																	Extra Leads Delivered
																</th>
																{/* kiran-4746-removing console warning-changed == to === */}
																{this.state.pacingCampaignDetails.map(
																	(pacingCampaignDetails, i) => {
																		return record.campID ===
																			pacingCampaignDetails.campID ? (
																			<td style={{ fontSize: "12px" }}>
																				{
																					pacingCampaignDetails.leadCountAllocation
																				}
																			</td>
																		) : (
																			""
																		);
																	}
																)}
															</tr>

														</table>
													</div>
												)}
											</div>

											<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
												{/* Sonali-4241-replacing == by === to remove warnings */}

												{user.role !== "PNC" ? (
													<Tooltip placement="bottom" title="IO Download">
														{/* Sonali-4241-adding downloadIODocument instead of # in href for removing warning  */}

														<a
															href="downloadIODocument"
															id={record.campID}
															onClick={this.downloadIODocument.bind(this)}>
															<img
																src="download_io.png"
																alt=""//sonali-4241-added to remove warnings
																height="30px"
																width="30px"
																id={record.campID}
																style={{ float: "right" }}></img>
														</a>
													</Tooltip>
												) : (
													""
												)}
											</div>
										</div>
									)}
									pagination={{
										pageSize: this.state.pageSize,
										position: "bottom",
									}}></Table>
							)}
						</div>
					</div>

					<div class="row">
						<div
							class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
							style={{ marginTop: "-53px", marginLeft: "15px" }}>
							{/*sunita-task-3008-little bit margin changed to aligned properly */}
							&nbsp;Records per page:&nbsp;
							<select
								defaultValue={this.state.pageSize}
								onChange={this.handleChangePageSize}
								id="pacing1"
								class="input-small"
								className="form-control"
								name="pacing"
								style={{
									width: "70px",
									height: "30px",
									display: "initial",
									marginLeft: "5px",
								}}>
								<option value="100" selected>
									100
								</option>
								<option value="200">200</option>
								<option value="300">300</option>
							</select>
						</div>
					</div>
					{/* Shivani- task 3611 - for custome div */}
					{this.state.actionPopupVisible && (
						<div
							className="popup-campaign-progress"
							style={{
								right: `${window.innerWidth - this.state.x}px`,
								top: `${this.state.y}px`,
								padding: 7,
								width: "170px",
							}}>
							<div key={this.state.index}>
								{/* Sonali-4241-replacing == by === to remove warnings */}

								{this.state.actionPopupSelectedRecord.status === "Accept" &&
									this.state.actionPopupSelectedRecord.isApproveCreative ===
									"No" &&
									this.state.actionPopupSelectedRecord.campaignStatus !==
									"Cancel Campaign" ? (
									<span>
										{this.state.userlogger === "PQA" ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Upload Creatives">
													<img
														src="Upload_Creatives_Disabled.png"
														alt=""//sonali-4241-added to remove warnings
														disabled
														width="30"
														height="30"
														style={{ marginRight: "150px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "40px", marginTop: "-30px" }}>
														Upload Creatives
													</span>
												</div>
											</span>
										) : (
											<a
												href="upload"
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Upload Creatives">
													<img
														src="Upload_Creatives.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.uploadCreative.bind(this)}
														parentcampid={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationid={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="30"
														height="30"
														style={{ marginRight: "150px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "40px", marginTop: "-30px" }}
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.uploadCreative.bind(this)}
														parentcampid={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationid={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}>
														Upload Creatives
													</span>
												</div>
											</a>
										)}
										&nbsp;&nbsp;&nbsp;
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topLeft"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}
												title="Please Upload Creatives First">
												<img
													src="Upload_Leads_Disabled.png"
													alt=""//sonali-4241-added to remove warnings
													width="30"
													height="30"
													style={{ marginRight: "160px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "40px", marginTop: "-30px" }}>
													Upload Leads
												</span>
											</div>
										</span>
										&nbsp;&nbsp;&nbsp;
										<span>
											{this.state.userlogger === "PQA" ? (
												<span style={{ float: "left", paddingBottom: "10px" }}>
													<img
														src="voiceLog_disable.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														width="30"
														height="30"
														style={{ marginRight: "160px" }}></img>
													<div>
														<span
															style={{
																marginLeft: "40px",
																marginTop: "-30px",
															}}>
															Voice Log
														</span>
													</div>
												</span>
											) : (
												<span>
													{
														this.state.actionPopupSelectedRecord.status ===
															"Accept" &&
															this.state.actionPopupSelectedRecord
																.isApproveCreative === "No" &&
															this.state.actionPopupSelectedRecord
																.campaignStatus !== "Cancel Campaign" &&
															(this.state.actionPopupSelectedRecord
																.marketingChannel === "TeleMarketing" ||
																this.state.actionPopupSelectedRecord
																	.marketingChannel === "Email/Telemarketing") ? (
															//sunita-task-3358-added below condition for normal campaign created without selecting channel,call audit and creative.
															<span
																style={{
																	float: "left",
																	paddingBottom: "10px",
																}}>
																<Tooltip
																	placement="topLeft"
																	title="You can not upload voice logs as the campaign's marketing channel is not telemarketing"
																	overlayStyle={{
																		color: "white",
																		backgroundColor: "orange",
																	}}>
																	<img
																		src="voiceLog_disable.png"
																		alt=""//sonali-4241-added to remove warnings
																		id={
																			this.state.actionPopupSelectedRecord
																				.campID
																		}
																		width="30"
																		height="30"
																		style={{ marginRight: "160px" }}></img>
																</Tooltip>
																<div>
																	<span
																		style={{
																			marginLeft: "40px",
																			marginTop: "-30px",
																		}}>
																		Voice Log
																	</span>
																</div>
															</span>
														) : (
															//sunita-task-3358-added below condition for normal campaign created without selecting channel,call audit and creative.
															<span
																style={{
																	float: "left",
																	paddingBottom: "10px",
																}}>
																<Tooltip
																	placement="top"
																	title="Upload Voice Logs">
																	<img
																		src="voice_log.png"
																		alt=""//sonali-4241-added to remove warnings
																		id={
																			this.state.actionPopupSelectedRecord
																				.campID
																		}
																		onClick={this.uploadVoiceLog.bind(this)}
																		parentcampid={
																			this.state.actionPopupSelectedRecord
																				.parentCampID
																		}
																		reallocationid={
																			this.state.actionPopupSelectedRecord
																				.reallocationID
																		}
																		width="30"
																		height="30"
																		style={{ marginRight: "160px" }}></img>
																</Tooltip>
																<div>
																	<span
																		style={{
																			marginLeft: "40px",
																			marginTop: "-30px",
																		}}
																		id={
																			this.state.actionPopupSelectedRecord
																				.campID
																		}
																		onClick={this.uploadVoiceLog.bind(this)}
																		parentcampid={
																			this.state.actionPopupSelectedRecord
																				.parentCampID
																		}
																		reallocationid={
																			this.state.actionPopupSelectedRecord
																				.reallocationID
																		}>
																		Voice Log
																	</span>
																</div>{" "}
															</span>
														)
													}
												</span>
											)}
										</span>
									</span>
								) : this.state.actionPopupSelectedRecord.status === "Accept" &&
									this.state.actionPopupSelectedRecord.isApproveCreative ===
									"Yes" &&
									this.state.actionPopupSelectedRecord.campaignStatus !==
									"Cancel Campaign" ? (
									<span>
										{this.state.userlogger === "PQA" ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Upload Creatives">
													<img
														src="Upload_Creatives_Disabled.png"
														alt=""//sonali-4241-added to remove warnings
														disabled
														width="30"
														height="30"
														style={{ marginRight: "150px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "40px", marginTop: "-30px" }}>
														Upload Creatives
													</span>
												</div>
											</span>
										) : (
											<a
												href="upload"
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Upload Creatives">
													<img
														src="Upload_Creatives.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.uploadCreative.bind(this)}
														parentcampid={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationid={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="30"
														height="30"
														style={{ marginRight: "150px" }}></img>
													<div>
														<span
															style={{ marginLeft: "40px", marginTop: "-30px" }}
															id={this.state.actionPopupSelectedRecord.campID}
															onClick={this.uploadCreative.bind(this)}
															parentcampid={
																this.state.actionPopupSelectedRecord
																	.parentCampID
															}
															reallocationid={
																this.state.actionPopupSelectedRecord
																	.reallocationID
															}>
															Upload Creatives
														</span>
													</div>
												</Tooltip>
											</a>
										)}
										&nbsp;&nbsp;&nbsp;
										{
											this.state.actionPopupSelectedRecord.status ===
												"LP_Pending" &&
												this.state.actionPopupSelectedRecord.isApproveCreative ===
												"Yes" ? (
												<span style={{ float: "left", paddingBottom: "10px" }}>
													<Tooltip
														placement="topLeft"
														overlayStyle={{
															color: "white",
															backgroundColor: "orange",
														}}
														title="Please Upload Creatives First">
														<img
															src="Upload_Leads_Disabled.png"
															alt=""//sonali-4241-added to remove warnings
															width="30"
															height="30"
															style={{ marginRight: "160px" }}></img>
													</Tooltip>
													<div>
														<span
															style={{
																marginLeft: "40px",
																marginTop: "-30px",
															}}>
															Upload Leads
														</span>
													</div>
												</span>
											) : (
												<span style={{ float: "left", paddingBottom: "10px" }}>
													{this.state.userlogger === "PQA" ? (
														<img
															src="Upload_Leads_Disabled.png"
															alt=""//sonali-4241-added to remove warnings
															width="30"
															height="30"
															style={{ marginRight: "160px" }}></img>
													) : (
														<span style={{ float: "left" }}>
															<a
																href="upload"
																id={
																	this.state.actionPopupSelectedRecord.campID
																}>
																<Tooltip placement="top" title="Upload Leads">
																	<img
																		src="Upload_Leads.png"
																		alt=""//sonali-4241-added to remove warnings
																		id={
																			this.state.actionPopupSelectedRecord
																				.campID
																		}
																		parentcampid={
																			this.state.actionPopupSelectedRecord
																				.parentCampID
																				? this.state.actionPopupSelectedRecord
																					.parentCampID
																				: this.state.actionPopupSelectedRecord
																					.campID
																		}
																		reallocationid={
																			this.state.actionPopupSelectedRecord
																				.reallocationID
																		}
																		onClick={this.uploadLeds}
																		width="30"
																		height="30"
																		style={{ marginRight: "160px" }}></img>
																</Tooltip>
																<div>
																	<span
																		style={{
																			marginLeft: "40px",
																			marginTop: "-30px",
																		}}
																		id={
																			this.state.actionPopupSelectedRecord
																				.campID
																		}
																		parentcampid={
																			this.state.actionPopupSelectedRecord
																				.parentCampID
																				? this.state.actionPopupSelectedRecord
																					.parentCampID
																				: this.state.actionPopupSelectedRecord
																					.campID
																		}
																		reallocationid={
																			this.state.actionPopupSelectedRecord
																				.reallocationID
																		}
																		onClick={this.uploadLeds}>
																		Upload Leads
																	</span>
																</div>
															</a>
														</span>
													)}
												</span>
											)

										}
										&nbsp;&nbsp;&nbsp;
										<span>
											{this.state.userlogger === "PQA" ? (
												<span style={{ float: "left", paddingBottom: "10px" }}>
													<img
														src="voiceLog_disable.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														width="30"
														height="30"
														style={{ marginRight: "160px" }}></img>{" "}
													<div>
														<span
															style={{
																marginLeft: "40px",
																marginTop: "-30px",
															}}>
															Voice Log
														</span>
													</div>
												</span>
											) : (
												<span>
													{
														this.state.actionPopupSelectedRecord
															.internalReviewLead > 0 ? (
															this.state.actionPopupSelectedRecord.status ===
																"Accept" &&
																this.state.actionPopupSelectedRecord
																	.isApproveCreative === "Yes" &&
																this.state.actionPopupSelectedRecord
																	.campaignStatus !== "Cancel Campaign" &&
																(this.state.actionPopupSelectedRecord
																	.marketingChannel === "TeleMarketing" ||
																	this.state.actionPopupSelectedRecord
																		.marketingChannel ===
																	"Email/Telemarketing") ? (
																<span
																	style={{
																		float: "left",
																		paddingBottom: "10px",
																	}}>
																	<Tooltip
																		placement="top"
																		title="Upload Voice Logs">
																		<img
																			src="voice_log.png"
																			alt=""//sonali-4241-added to remove warnings
																			id={
																				this.state.actionPopupSelectedRecord
																					.campID
																			}
																			onClick={this.uploadVoiceLog.bind(this)}
																			parentcampid={
																				this.state.actionPopupSelectedRecord
																					.parentCampID
																			}
																			reallocationid={
																				this.state.actionPopupSelectedRecord
																					.reallocationID
																			}
																			width="30"
																			height="30"
																			style={{ marginRight: "160px" }}></img>
																	</Tooltip>
																	<div>
																		<span
																			style={{
																				marginLeft: "40px",
																				marginTop: "-30px",
																			}}
																			id={
																				this.state.actionPopupSelectedRecord
																					.campID
																			}
																			onClick={this.uploadVoiceLog.bind(this)}
																			parentcampid={
																				this.state.actionPopupSelectedRecord
																					.parentCampID
																			}
																			reallocationid={
																				this.state.actionPopupSelectedRecord
																					.reallocationID
																			}>
																			Voice Log
																		</span>
																	</div>
																</span>
															) : (
																<span
																	style={{
																		float: "left",
																		paddingBottom: "10px",
																	}}>
																	<Tooltip
																		placement="topLeft"
																		title="You can not upload voice logs as the campaign's marketing channel is not telemarketing"
																		overlayStyle={{
																			color: "white",
																			backgroundColor: "orange",
																		}}>
																		<img
																			src="voiceLog_disable.png"
																			alt=""//sonali-4241-added to remove warnings
																			id={
																				this.state.actionPopupSelectedRecord
																					.campID
																			}
																			width="30"
																			height="30"
																			style={{ marginRight: "160px" }}></img>
																	</Tooltip>{" "}
																	<div>
																		<span
																			style={{
																				marginLeft: "40px",
																				marginTop: "-30px",
																			}}>
																			Voice Log
																		</span>
																	</div>{" "}
																</span>
															)
														) : // sunita-task-3184-added this condition as when leads are rejected due to voice log pending and we came back to publisher campaign list page then voice log button will be enable.
															this.state.actionPopupSelectedRecord.status ===
																"Accept" &&
																this.state.actionPopupSelectedRecord
																	.isApproveCreative === "Yes" &&
																this.state.actionPopupSelectedRecord
																	.campaignStatus !== "Cancel Campaign" &&
																(this.state.actionPopupSelectedRecord
																	.marketingChannel === "TeleMarketing" ||
																	this.state.actionPopupSelectedRecord
																		.marketingChannel ===
																	"Email/Telemarketing") ? (
																<span
																	style={{
																		float: "left",
																		paddingBottom: "10px",
																	}}>
																	<Tooltip
																		placement="top"
																		title="Upload Voice Logs">
																		<img
																			src="voice_log.png"
																			alt=""//sonali-4241-added to remove warnings
																			id={
																				this.state.actionPopupSelectedRecord
																					.campID
																			}
																			onClick={this.uploadVoiceLog.bind(this)}
																			parentcampid={
																				this.state.actionPopupSelectedRecord
																					.parentCampID
																			}
																			reallocationid={
																				this.state.actionPopupSelectedRecord
																					.reallocationID
																			}
																			width="30"
																			height="30"
																			style={{ marginRight: "160px" }}></img>
																	</Tooltip>
																	<div>
																		<span
																			style={{
																				marginLeft: "40px",
																				marginTop: "-30px",
																			}}
																			id={
																				this.state.actionPopupSelectedRecord
																					.campID
																			}
																			onClick={this.uploadVoiceLog.bind(this)}
																			parentcampid={
																				this.state.actionPopupSelectedRecord
																					.parentCampID
																			}
																			reallocationid={
																				this.state.actionPopupSelectedRecord
																					.reallocationID
																			}>
																			Voice Log
																		</span>
																	</div>{" "}
																</span>
															) : (
																//sunita-task-3184-added below shivani's task code 3011 here..and commented below code.
																<span
																	style={{
																		float: "left",
																		paddingBottom: "10px",
																	}}>
																	<Tooltip
																		placement="topLeft"
																		title="You can not upload voice logs as the campaign's marketing channel is not telemarketing"
																		overlayStyle={{
																			color: "white",
																			backgroundColor: "orange",
																		}}>
																		<img
																			src="voiceLog_disable.png"
																			alt=""//sonali-4241-added to remove warnings
																			id={
																				this.state.actionPopupSelectedRecord
																					.campID
																			}
																			width="30"
																			height="30"
																			style={{ marginRight: "160px" }}></img>
																	</Tooltip>{" "}
																	<div>
																		<span
																			style={{
																				marginLeft: "40px",
																				marginTop: "-30px",
																			}}>
																			Voice Log
																		</span>
																	</div>
																</span>
															)

													}
												</span>
											)}
										</span>
									</span>
								) : this.state.actionPopupSelectedRecord.status ===
									"LP_Pending" &&
									this.state.actionPopupSelectedRecord.isApproveCreative ===
									"No" &&
									this.state.actionPopupSelectedRecord.campaignStatus !==
									"Cancel Campaign" ? (
									<span>
										{this.state.userlogger === "PQA" ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Upload Creatives">
													<img
														src="Upload_Creatives_Disabled.png"
														alt=""//sonali-4241-added to remove warnings
														disabled
														width="30"
														height="30"
														style={{ marginRight: "150px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "40px", marginTop: "-30px" }}>
														Upload Creatives
													</span>
												</div>
											</span>
										) : (
											<a
												href="upload"
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Upload Creatives">
													<img
														src="Upload_Creatives.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.uploadCreative.bind(this)}
														parentcampid={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationid={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="30"
														height="30"
														style={{ marginRight: "150px" }}></img>
													<div>
														<span
															style={{ marginLeft: "40px", marginTop: "-30px" }}
															id={this.state.actionPopupSelectedRecord.campID}
															onClick={this.uploadCreative.bind(this)}
															parentcampid={
																this.state.actionPopupSelectedRecord
																	.parentCampID
															}
															reallocationid={
																this.state.actionPopupSelectedRecord
																	.reallocationID
															}>
															Upload Creatives
														</span>
													</div>
												</Tooltip>
											</a>
										)}
										&nbsp;&nbsp;&nbsp;
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topLeft"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}
												title="Please Upload Creatives First">
												<img
													src="Upload_Leads_Disabled.png"
													alt=""//sonali-4241-added to remove warnings
													width="30"
													height="30"
													style={{ marginRight: "160px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "40px", marginTop: "-30px" }}>
													Upload Leads
												</span>
											</div>
										</span>
										&nbsp;&nbsp;&nbsp;
										<span>
											{this.state.userlogger === "PQA" ? (
												<span style={{ float: "left", paddingBottom: "10px" }}>
													<img
														src="voiceLog_disable.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														width="30"
														height="30"
														style={{ marginRight: "160px" }}></img>{" "}
													<div>
														<span
															style={{
																marginLeft: "40px",
																marginTop: "-30px",
															}}>
															Voice Log
														</span>
													</div>
												</span>
											) : (
												<div>
													{this.state.actionPopupSelectedRecord
														.internalReviewLead > 0 ? (
														this.state.actionPopupSelectedRecord.status ===
															"LP_Pending" &&
															this.state.actionPopupSelectedRecord
																.isApproveCreative === "No" &&
															this.state.actionPopupSelectedRecord
																.campaignStatus !== "Cancel Campaign" &&
															(this.state.actionPopupSelectedRecord
																.marketingChannel === "TeleMarketing" ||
																this.state.actionPopupSelectedRecord
																	.marketingChannel === "Email/Telemarketing") ? (
															<span
																style={{
																	float: "left",
																	paddingBottom: "10px",
																}}>
																<Tooltip
																	placement="top"
																	title="Upload Voice Logs">
																	<img
																		src="voice_log.png"
																		alt=""//sonali-4241-added to remove warnings
																		id={
																			this.state.actionPopupSelectedRecord
																				.campID
																		}
																		onClick={this.uploadVoiceLog.bind(this)}
																		parentcampid={
																			this.state.actionPopupSelectedRecord
																				.parentCampID
																		}
																		reallocationid={
																			this.state.actionPopupSelectedRecord
																				.reallocationID
																		}
																		width="30"
																		height="30"
																		style={{ marginRight: "160px" }}></img>
																</Tooltip>{" "}
																<div>
																	<span
																		style={{
																			marginLeft: "40px",
																			marginTop: "-30px",
																		}}
																		id={
																			this.state.actionPopupSelectedRecord
																				.campID
																		}
																		onClick={this.uploadVoiceLog.bind(this)}
																		parentcampid={
																			this.state.actionPopupSelectedRecord
																				.parentCampID
																		}
																		reallocationid={
																			this.state.actionPopupSelectedRecord
																				.reallocationID
																		}>
																		Voice Log
																	</span>
																</div>
															</span>
														) : (
															<span
																style={{
																	float: "left",
																	paddingBottom: "10px",
																}}>
																<Tooltip
																	placement="topLeft"
																	title="You can not upload voice logs as the campaign's marketing channel is not telemarketing"
																	overlayStyle={{
																		color: "white",
																		backgroundColor: "orange",
																	}}>
																	<img
																		src="voiceLog_disable.png"
																		alt=""//sonali-4241-added to remove warnings
																		id={
																			this.state.actionPopupSelectedRecord
																				.campID
																		}
																		width="30"
																		height="30"
																		style={{ marginRight: "160px" }}></img>
																</Tooltip>{" "}
																<div>
																	<span
																		style={{
																			marginLeft: "40px",
																			marginTop: "-30px",
																		}}>
																		Voice Log
																	</span>
																</div>
															</span>
														)
													) : (
														<span
															style={{ float: "left", paddingBottom: "10px" }}>
															<Tooltip
																placement="topLeft"
																title="Please upload leads "
																overlayStyle={{
																	color: "white",
																	backgroundColor: "orange",
																}}>
																<img
																	src="voiceLog_disable.png"
																	alt=""//sonali-4241-added to remove warnings
																	id={
																		this.state.actionPopupSelectedRecord.campID
																	}
																	width="30"
																	height="30"
																	style={{ marginRight: "160px" }}></img>
															</Tooltip>{" "}
															<div>
																<span
																	style={{
																		marginLeft: "40px",
																		marginTop: "-30px",
																	}}>
																	Voice Log
																</span>
															</div>
														</span>
													)}
												</div>
											)}
										</span>
									</span>
								) : this.state.actionPopupSelectedRecord.status ===
									"LP_Pending" &&
									this.state.actionPopupSelectedRecord.isApproveCreative ===
									"Yes" &&
									this.state.actionPopupSelectedRecord.campaignStatus !==
									"Cancel Campaign" ? (
									<span>
										{this.state.userlogger === "PQA" ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Upload Creatives">
													<img
														src="Upload_Creatives_Disabled.png"
														alt=""//sonali-4241-added to remove warnings
														disabled
														width="30"
														height="30"
														style={{ marginRight: "150px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "40px", marginTop: "-30px" }}>
														Upload Creatives
													</span>
												</div>
											</span>
										) : (
											<a
												href="upload"
												id={this.state.actionPopupSelectedRecord.campID}
												style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip placement="top" title="Upload Creatives">
													<img
														src="Upload_Creatives.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														onClick={this.uploadCreative.bind(this)}
														parentcampid={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationid={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="30"
														height="30"
														style={{ marginRight: "150px" }}></img>
													<div>
														<span
															style={{ marginLeft: "40px", marginTop: "-30px" }}
															id={this.state.actionPopupSelectedRecord.campID}
															onClick={this.uploadCreative.bind(this)}
															parentcampid={
																this.state.actionPopupSelectedRecord
																	.parentCampID
															}
															reallocationid={
																this.state.actionPopupSelectedRecord
																	.reallocationID
															}>
															Upload Creatives
														</span>
													</div>
												</Tooltip>
											</a>
										)}
										&nbsp;&nbsp;&nbsp;
										{this.state.actionPopupSelectedRecord.status ===
											"LP_Pending" &&
											this.state.actionPopupSelectedRecord.isApproveCreative ===
											"Yes" &&
											this.state.actionPopupSelectedRecord.campaignStatus !==
											"Cancel Campaign" ? (
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip
													placement="topLeft"
													overlayStyle={{
														color: "white",
														backgroundColor: "orange",
													}}
													title="Please Upload Creatives First">
													<img
														src="Upload_Leads_Disabled.png"
														alt=""//sonali-4241-added to remove warnings
														width="30"
														height="30"
														style={{ marginRight: "160px" }}></img>
												</Tooltip>
												<div>
													<span
														style={{ marginLeft: "40px", marginTop: "-30px" }}>
														Upload Leads
													</span>
												</div>
											</span>
										) : (
											<div style={{ float: "left", paddingBottom: "10px" }}>
												{/* Sonali-4241-replacing == by === to remove warnings */}

												{this.state.userlogger === "PQA" ? (
													<img
														src="Upload_Leads_Disabled.png"
														alt=""//sonali-4241-added to remove warnings
														width="30"
														height="30"
														style={{ marginRight: "160px" }}></img>
												) : (
													<span style={{ float: "left" }}>
														{/* Sonali-4241-adding download instead of # in href for removing warning */}
														<a
															href="upload"
															id={this.state.actionPopupSelectedRecord.campID}>
															<Tooltip placement="top" title="Upload Leads">
																<img
																	src="Upload_Leads.png"
																	alt=""//sonali-4241-added to remove warnings
																	id={
																		this.state.actionPopupSelectedRecord.campID
																	}
																	parentcampid={
																		this.state.actionPopupSelectedRecord
																			.parentCampID
																			? this.state.actionPopupSelectedRecord
																				.parentCampID
																			: this.state.actionPopupSelectedRecord
																				.campID
																	}
																	reallocationid={
																		this.state.actionPopupSelectedRecord
																			.reallocationID
																	}
																	onClick={this.uploadLeds}
																	width="30"
																	height="30"
																	style={{ marginRight: "160px" }}></img>
															</Tooltip>
															<div>
																<span
																	style={{
																		marginLeft: "40px",
																		marginTop: "-30px",
																	}}
																	id={
																		this.state.actionPopupSelectedRecord.campID
																	}
																	parentcampid={
																		this.state.actionPopupSelectedRecord
																			.parentCampID
																			? this.state.actionPopupSelectedRecord
																				.parentCampID
																			: this.state.actionPopupSelectedRecord
																				.campID
																	}
																	reallocationid={
																		this.state.actionPopupSelectedRecord
																			.reallocationID
																	}
																	onClick={this.uploadLeds}>
																	Upload Leads
																</span>
															</div>
														</a>
													</span>
												)}
											</div>
										)}
										&nbsp;&nbsp;&nbsp;
										<span>
											{this.state.actionPopupSelectedRecord.internalReviewLead >
												0 || this.state.userlogger !== "PQA" ? (
												((this.state.actionPopupSelectedRecord.status ===
													"LP_Pending" &&
													this.state.actionPopupSelectedRecord
														.isApproveCreative === "Yes") ||
													this.state.actionPopupSelectedRecord
														.campaignStatus !== "Cancel Campaign") &&
													(this.state.actionPopupSelectedRecord
														.marketingChannel === "TeleMarketing" ||
														this.state.actionPopupSelectedRecord
															.marketingChannel === "Email/Telemarketing") ? (
													<span
														style={{ float: "left", paddingBottom: "10px" }}>
														<Tooltip placement="top" title="Upload Voice Logs">
															<img
																src="voice_log.png"
																alt=""//sonali-4241-added to remove warnings
																id={this.state.actionPopupSelectedRecord.campID}
																onClick={this.uploadVoiceLog.bind(this)}
																parentcampid={
																	this.state.actionPopupSelectedRecord
																		.parentCampID
																}
																reallocationid={
																	this.state.actionPopupSelectedRecord
																		.reallocationID
																}
																width="30"
																height="30"
																style={{ marginRight: "160px" }}></img>
														</Tooltip>
														<div>
															<span
																style={{
																	marginLeft: "40px",
																	marginTop: "-30px",
																}}
																id={this.state.actionPopupSelectedRecord.campID}
																onClick={this.uploadVoiceLog.bind(this)}
																parentcampid={
																	this.state.actionPopupSelectedRecord
																		.parentCampID
																}
																reallocationid={
																	this.state.actionPopupSelectedRecord
																		.reallocationID
																}>
																Voice Log
															</span>
														</div>
													</span>
												) : (
													<span
														style={{ float: "left", paddingBottom: "10px" }}>
														<Tooltip
															placement="topLeft"
															title="You can not upload voice logs as the campaign's marketing channel is not telemarketing"
															overlayStyle={{
																color: "white",
																backgroundColor: "orange",
															}}>
															<img
																src="voiceLog_disable.png"
																alt=""//sonali-4241-added to remove warnings
																id={this.state.actionPopupSelectedRecord.campID}
																parentCampID={
																	this.state.actionPopupSelectedRecord
																		.parentCampID
																}
																reallocationID={
																	this.state.actionPopupSelectedRecord
																		.reallocationID
																}
																width="30"
																height="30"
																style={{ marginRight: "160px" }}></img>
														</Tooltip>{" "}
														<div>
															<span
																style={{
																	marginLeft: "40px",
																	marginTop: "-30px",
																}}>
																Voice Log
															</span>
														</div>
													</span>
												)
											) : (
												<span style={{ float: "left", paddingBottom: "10px" }}>
													<Tooltip
														placement="topLeft"
														title="Please upload leads"
														overlayStyle={{
															color: "white",
															backgroundColor: "orange",
														}}>
														<img
															src="voiceLog_disable.png"
															alt=""//sonali-4241-added to remove warnings
															id={this.state.actionPopupSelectedRecord.campID}
															parentCampID={
																this.state.actionPopupSelectedRecord
																	.parentCampID
															}
															reallocationID={
																this.state.actionPopupSelectedRecord
																	.reallocationID
															}
															width="30"
															height="30"
															style={{ marginRight: "160px" }}></img>
													</Tooltip>{" "}
													<div>
														<span
															style={{
																marginLeft: "40px",
																marginTop: "-30px",
															}}>
															Voice Log
														</span>
													</div>
												</span>
											)}
										</span>
									</span>
								) : this.state.actionPopupSelectedRecord.status === "Paused" ? (
									<span>
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topRight"
												title="You can not upload creatives as the campaign is paused"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}>
												<img
													src="Upload_Creatives_Disabled.png"
													alt=""//sonali-4241-added to remove warnings
													width="30"
													height="30"
													style={{ marginRight: "150px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "40px", marginTop: "-30px" }}>
													Upload Creatives
												</span>
											</div>
										</span>
										&nbsp;&nbsp;&nbsp;
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topLeft"
												title="You can not upload leads as the campaign is paused"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}>
												<img
													src="Upload_Leads_Disabled.png"
													alt=""//sonali-4241-added to remove warnings
													width="30"
													height="30"
													style={{ marginRight: "160px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "40px", marginTop: "-30px" }}>
													Upload Leads
												</span>
											</div>
										</span>
										&nbsp;&nbsp;&nbsp;
										<span>
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip
													placement="topLeft"
													title="You can not upload voice logs as the campaign's marketing channel is not telemarketing"
													overlayStyle={{
														color: "white",
														backgroundColor: "orange",
													}}>
													<img
														src="voiceLog_disable.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="30"
														height="30"
														style={{ marginRight: "160px" }}></img>
												</Tooltip>{" "}
												<div>
													<span
														style={{ marginLeft: "40px", marginTop: "-30px" }}>
														Voice Log
													</span>
												</div>
											</span>
										</span>
									</span>
								) : this.state.actionPopupSelectedRecord.status ===
									"Completed" ? (
									<span>
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topRight"
												title="You can not upload creatives as the campaign is completed"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}>
												<img
													src="Upload_Creatives_Disabled.png"
													alt=""//sonali-4241-added to remove warnings
													width="30"
													height="30"
													style={{ marginRight: "150px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "40px", marginTop: "-30px" }}>
													Upload Creatives
												</span>
											</div>
										</span>
										&nbsp;&nbsp;&nbsp;
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topLeft"
												title="You can not upload leads as the campaign is completed"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}>
												<img
													src="Upload_Leads_Disabled.png"
													alt=""//sonali-4241-added to remove warnings
													width="30"
													height="30"
													style={{ marginRight: "160px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "40px", marginTop: "-30px" }}>
													Upload Leads
												</span>
											</div>
										</span>
										&nbsp;&nbsp;&nbsp;
										<span>
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip
													placement="topLeft"
													title="You can not upload voice logs as the campaign's marketing channel is not telemarketing"
													overlayStyle={{
														color: "white",
														backgroundColor: "orange",
													}}>
													<img
														src="voiceLog_disable.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="30"
														height="30"
														style={{ marginRight: "160px" }}></img>
												</Tooltip>{" "}
												<div>
													<span
														style={{ marginLeft: "40px", marginTop: "-30px" }}>
														Voice Log
													</span>
												</div>
											</span>
										</span>
									</span>
								) : (this.state.actionPopupSelectedRecord.status ===
									"Live_Incomplete" &&
									this.state.actionPopupSelectedRecord.campaignStatus !==
									"Cancel Campaign") ||
									(this.state.actionPopupSelectedRecord.status ===
										"PausedIncomplete" &&
										this.state.actionPopupSelectedRecord.campaignStatus !==
										"Cancel Campaign") ||
									(this.state.actionPopupSelectedRecord.status ===
										"Cancel ALU" &&
										this.state.actionPopupSelectedRecord.campaignStatus !==
										"Cancel Campaign") ? (
									<span>
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topRight"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}
												title="You can not upload creatives as the campaign is completed">
												<img
													src="Upload_Creatives_Disabled.png"
													alt=""//sonali-4241-added to remove warnings
													width="30"
													height="30"
													style={{ marginRight: "150px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "40px", marginTop: "-30px" }}>
													Upload Creatives
												</span>
											</div>
										</span>
										&nbsp;&nbsp;&nbsp;
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topLeft"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}
												title="You can not upload leads as the campaign is completed">
												<img
													src="Upload_Leads_Disabled.png"
													alt=""//sonali-4241-added to remove warnings
													width="30"
													height="30"
													style={{ marginRight: "160px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "40px", marginTop: "-30px" }}>
													Upload Leads
												</span>
											</div>
										</span>
										&nbsp;&nbsp;&nbsp;
										<span>
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip
													placement="topLeft"
													title="You can not upload voice logs as the campaign's marketing channel is not telemarketing"
													overlayStyle={{
														color: "white",
														backgroundColor: "orange",
													}}>
													<img
														src="voiceLog_disable.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="30"
														height="30"
														style={{ marginRight: "160px" }}></img>
												</Tooltip>{" "}
												<div>
													<span
														style={{ marginLeft: "40px", marginTop: "-30px" }}>
														Voice Log
													</span>
												</div>
											</span>
										</span>
									</span>
								) : this.state.actionPopupSelectedRecord.status === "Cancel" ||
									this.state.actionPopupSelectedRecord.campaignStatus ===
									"Cancel Campaign" ? (
									<span>
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topRight"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}
												title="You can not upload creatives as the campaign\allocation is cancelled">
												<img
													src="Upload_Creatives_Disabled.png"
													alt=""//sonali-4241-added to remove warnings
													width="30"
													height="30"
													style={{ marginRight: "150px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "40px", marginTop: "-30px" }}>
													Upload Creatives
												</span>
											</div>
										</span>
										&nbsp;&nbsp;&nbsp;
										<span style={{ float: "left", paddingBottom: "10px" }}>
											<Tooltip
												placement="topLeft"
												overlayStyle={{
													color: "white",
													backgroundColor: "orange",
												}}
												title="You can not upload leads as the campaign\allocation is cancelled">
												<img
													src="Upload_Leads_Disabled.png"
													alt=""//sonali-4241-added to remove warnings
													width="30"
													height="30"
													style={{ marginRight: "160px" }}></img>
											</Tooltip>
											<div>
												<span
													style={{ marginLeft: "40px", marginTop: "-30px" }}>
													Upload Leads
												</span>
											</div>
										</span>
										&nbsp;&nbsp;&nbsp;
										<span>
											<span style={{ float: "left", paddingBottom: "10px" }}>
												<Tooltip
													placement="topLeft"
													title="You can not upload voice logs as the campaign\allocation is cancelled"
													overlayStyle={{
														color: "white",
														backgroundColor: "orange",
													}}>
													<img
														src="voiceLog_disable.png"
														alt=""//sonali-4241-added to remove warnings
														id={this.state.actionPopupSelectedRecord.campID}
														parentCampID={
															this.state.actionPopupSelectedRecord.parentCampID
														}
														reallocationID={
															this.state.actionPopupSelectedRecord
																.reallocationID
														}
														width="30"
														height="30"
														style={{ marginRight: "160px" }}></img>
												</Tooltip>{" "}
												<div>
													<span
														style={{ marginLeft: "40px", marginTop: "-30px" }}>
														Voice Log
													</span>
												</div>
											</span>
										</span>
									</span>
								) : (
									""
								)}
							</div>
						</div>
					)}
					<br />
				</div>
				{<Footer />}
			</div>
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
publisherCampaignList.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(publisherCampaignList)
);
