/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/* eslint-disable no-unused-expressions */

/*
@author:SanjanaGodbile,Raunak Thakkar,Snehal More
@Description:UI for supporting document under create campaign
*/
import React from 'react';
import * as $ from "jquery";
import { form } from 'mdbreact';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import Footer from '../layouts/footer';
import Navigation from '../layouts/navPage';
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { Tooltip, Icon, Input, Form, Row, Col, Button, Tag, Switch } from 'antd';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import "./supportingDocumentNew.css";
import { format } from 'morgan';
const Swal = require('sweetalert2');


//use for fetch url value
const queryString = require('query-string');
var result = [];
var abmresult = [];
// var errorFile=false;
var allassets = true;
var allabms = true;
var allassetLength = false;
var allabmLength = false;
var allLinkLength = false;
var linkflag = false;
var assetflag = false;
var linkflagBack = false;
var assetflagBack = false;
var assetLength;
var assetLinkLength;
var leadperassetCount = 0;
var leadperassetlinkCount = 0;
//var names=[];
var abmnames = [];
var abmCounts = [];
var newabmfilenames = [];
var newabmFileCount = [];
var suppressionnames = [];
var newsuppressionfilenames = [];
var execlusionnames = [];
var newexclusionfilenames = [];
var othernames = [];
var newotherfilenames = [];
var assetnames = [];
var newassetfilenames = [];
var inputType = [];
var a = [];
var key = 0;
var key1 = 0;
let chkABMExist = [], duplicateABMFile = [];// this is used for check duplicate file name
let chkExclusionExist = [], duplicateExclusionFile = [];
let chkSuppressionExist = [], duplicateSuppressionFile = [];
let chkOtherExist = [], duplicateOtherFile = [];
var count = 0;
var linkSuppCount = 0;
var oldABMFileArray = [];
var assetValidationArray = [];// store the rejected asset name in this array which come from Backend for validte true or false
var exclusionValidationArray = [];// store the rejected Exclusion name in this array which come from Backend for validte true or false
var otherValidationArray = []; var successOtherCount = [], rejectOtherCount = [];
var suppValidationArray = [];
var successAssetCount = [];
var rejectAssetCount = [];
var successExclusionCount = [];
var rejectExclusionCount = [];
var successAbmCount = [];
var rejectAbmCount = [];
var successSuppressionCount = [];
var rejectSuppressionCount = [];
var rfpChange = 0;
var abmCountArray = [];
var backabmCount = [];
var newbackabmfileCount = [];
var asset1 = false;
var asset2 = false;
// var abmBackArray=[];
class step2supportingdocument extends React.Component {
  /**
 * @author Narendra Phadke
 * @param  Description handle the multiple file uploads
 */
  constructor() {
    super();

    this.state = {
      fields: {},
      count: '',
      expand: false,
      campID: '',
      leadPerAssetChk: 'No',
      multiTouchChk: 'No',
      multiTouchChkBack: 'No',
      checkedValue: false,
      errors: {},
      linknameerror: {},
      abmerror: {},
      backerror: {},
      backerrorasset: {},
      backabmerror: {},
      exclusionerror: {},
      fileerrors: {},
      suppressionerror: {},
      leaderrors: {},
      leadAsseterrors: {},
      linkleadserrors: {},
      asseterrors: {},
      abmerrors: {},
      sdEditDetail: [],
      abmBackArray: [],
      sdEditDetail1: [],
      sdEditDetailABM: [],
      sdEditDetailSuppression: [],
      sdEditDetailExclusion: [],
      sdEditDetailAsset: [],
      sdEditDetailOther: [],
      // dynamicAssetName:[],
      deleteAssetLink: [],
      // sdBudget:[],
      inputType: [],
      inputType1: [],
      result: [],
      assetsFile: [],
      assetFileNameArray: [],
      assetLeadArray: [],
      assetTotalLead: 0,
      assetLinkArray: [{ linkAssetFileName: "", assetLink: "" }],
      assetLinkLeadArray: [],
      dynamicAssetName: [],
      dynamicAssetLead: [],
      result: [],
      dynamicSuppDocName: [],
      suppressionLinkArray: [],
      suppressionLink: [],
      selectedFile: '',
      files: {},
      alertDisplay: 'none',
      spanSpaceDisplay: 'none',
      requiredLPApproval: 'No',
      currency: "USD",
      lpTimeline: "12",
      displayTable: 'none',
      displayTableabm: 'none',
      displayTableexclusion: 'none',
      displayTablesuppression: 'none',
      displayTableother: 'none',
      suppstatus: 'false',
      dynamicInput: [],
      dynamicSuppressionInput: [{ count: 0, suppressionLink: '', suppressionFileName: '' }],
      backDynamicInput: [],
      backDynamicSuppressionInput: [],
      editDeliveryFormat: [],
      companyName: 'Yes',
      linkedInCompanyName: 'Yes',
      campaignID: 'Yes',
      campaignDetail: [],
      assetName: 'Yes',
      assetName_touch1: 'No',
      assetTimestamp_touch1: '1970-01-01 00:00:01',
      assetName_touch2: 'No',
      assetTimestamp_touch2: '1970-01-01 00:00:01',
      assetName_touch3: 'No',
      assetTimestamp_touch3: '1970-01-01 00:00:01',
      pID: 'Yes',
      assetId: 'No',
      leadInteractionDate: 'Yes',
      firstName: 'Yes',
      lastName: 'Yes',
      jobTitleDelivery: 'Yes',
      linkedInJobTitle: 'Yes',
      jobTitleValidation: '',
      jobLevelDelivery: '',
      jobFunctionDelivery: '',
      assetName: 'Yes',
      companyRevenue: '',
      address: 'Yes',
      email: 'Yes',
      workPhone: 'Yes',
      street: 'No',
      city: 'Yes',
      state: 'Yes',
      countryDelivery: 'Yes',
      zipCode: 'Yes',
      companyEmployeeSize: '',
      companyRevenue: 'No',
      industry: '',
      ip: 'No',
      optInDate: 'Yes',
      optInTime: 'Yes',
      optInTimeZone: 'Yes',
      linkedIn: 'No',
      extra1: 'No',
      extra2: 'No',
      extra3: 'No',
      extra4: 'No',
      extra5: 'No',
      extra6: 'No',
      extra7: 'No',
      extra8: 'No',
      extra9: 'No',
      extra10: 'No',
      extra11: 'No',
      extra12: 'No',
      extra13: 'No',
      extra14: 'No',
      extra15: 'No',
      extra16: 'No',
      extra17: 'No',
      extra18: 'No',
      extra19: 'No',
      extra20: 'No',//Sandeep-task-3158-added code for extra 15 fields

      reAllocationID: 'No',
      campaignName:'No',
      domain: 'No',
      comments: 'No',
      alternatePhoneNo: 'No',
      totalLeadAllocation: '',
      requiredLeadPerAsset: '',
      campaignType: '',
      assetWiseLead: '',
      channel: '',
      agencyCustomizeMapping: [],
      customizeMapping: '',
      customizeMappingFormat: "Format 1",
      rfpCampaignID: '',
      abmFileCount: [],
      switchValue: "ON",
      assetJson: [],// store all file which get from response
      abmJson: [],
      suppressionJson: [],
      exclusionJson: [],
      otherJson: [],
      isAbmFileValid: false,
      mappingFormat: []
      // isChecked:true,
    }
    this.dynamicABMFile = this.dynamicABMFile.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.submitSupportingDocument = this.submitSupportingDocument.bind(this);
    this.handleChangeMultipleFile = this.handleChangeMultipleFile.bind(this);
    //   this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleChangeMultipleFileOfOther = this.handleChangeMultipleFileOfOther.bind(this);
    this.handleChangeMultipleFileOfABM = this.handleChangeMultipleFileOfABM.bind(this);
    this.handleChangeMultipleFileOfExclusion = this.handleChangeMultipleFileOfExclusion.bind(this);
    this.handleChangeMultipleFileOfSuppression = this.handleChangeMultipleFileOfSuppression.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.leadPerAssethandleChange = this.leadPerAssethandleChange.bind(this);
    this.leadPerAssethandleChangeBack = this.leadPerAssethandleChangeBack.bind(this);
    this.multiTouchHandleChange = this.multiTouchHandleChange.bind(this)
    this.multiTouchHandleChangeBack = this.multiTouchHandleChangeBack.bind(this)
    this.switchHandlechange = this.switchHandlechange.bind(this)
    this.handleFormatChange = this.handleFormatChange.bind(this);
    this.customizeMappingFormatHandleChange = this.customizeMappingFormatHandleChange.bind(this);
    this.customizeMappingCancleHandleChange = this.customizeMappingCancleHandleChange.bind(this);
    this.setcustomizeMapping=this.setcustomizeMapping.bind(this);// raunak-2951-binding this function to access setstate for customize mapping

    // this.getFields=this.getFields.bind(this);
    //this.topScroll=this.topScroll.bind(this);

  };
  /**
     * @author Raunak Thakkar
     * @param  Description Handle change of switch*/
  switchHandlechange(value) {
    value == true ?
      this.setState({ switchValue: "ON" }) : this.setState({ switchValue: "OFF" });
  }
  /**
     * @author Sanobar Golandaj
     * @param  Description handle add  and remove the dynamic text fields*/
  addSuppressionLink() {
    linkSuppCount = linkSuppCount + 1;
    this.setState({ dynamicSuppressionInput: [...this.state.dynamicSuppressionInput, { count: linkSuppCount, suppressionLink: '', suppressionFileName: '' }] })
  }
  /**
          * @author Somnath Keswad
          * @param  Description handle Remove the dynamic text fields of Suppression 
          * @return Description return the remaining text field in Suppression Link
        */
  removeSuppressionLink(i) {
    this.state.dynamicSuppressionInput.splice(i, 1); // Iterate .map
    this.state.suppressionLinkArray.splice(i, 1);// for Backend insert
    this.setState({ dynamicSuppressionInput: this.state.dynamicSuppressionInput });
  }

  /**
  * @author Somnath Keswad
  * @param  Description handle backButton Add the dynamic text fields of Suppression in Back Button
  * @return Description return the 1 more text field in Suppression Link
*/
  addBackSuppressionLink() {
    count = this.state.suppressionLink.length;
    count = count + 1;
    var tempSuppArray = [{ suppressionFileName: '', suppressionLink: '', count: count }];
    let finalData = this.state.suppressionLink.concat(tempSuppArray);
    this.setState({ suppressionLink: finalData })
  }
  /**
          * @author Somnath Keswad
          * @param  Description handle backButton remove the dynamic text fields of Suppression
          * @return Description return remaining text field
        */
  removeBackSuppressionLink(e, i) {
    var supportDocID = e.target.getAttribute('name');
    this.state.suppressionLink.splice(i, 1);
    this.setState({ suppressionLink: this.state.suppressionLink });
    if (supportDocID !== undefined || supportDocID != '') {
      fetch("campaign/deleteSuppressionLink?supportDocID=" + supportDocID)
        .then(res => res.json())
        .then(deleteAssetLink => {
          this.setState({ deleteAssetLink: deleteAssetLink })

        }).catch(function (err) { console.log(err) });
    }

  }

  /**
   * @author Sanobar Golandaj
   * @param  Description handle the Dynamic TextBox for AssetLink
   */
  handleSuppressionLink(i, e) {
    const { name, value } = e.target;
    var step2 = window.location.href.split("&")[window.location.href.split("?").length - 1];
    if (step2 === 'undefined' || step2 === null || step2 === "" || step2 === undefined) {
      let suppressionLinkArray = [...this.state.suppressionLinkArray];
      suppressionLinkArray[i] = { ...suppressionLinkArray[i], [name]: value };
      this.setState({ suppressionLinkArray: suppressionLinkArray });
    } else {
      let suppressionLink = [...this.state.suppressionLink];
      suppressionLink[i] = { ...suppressionLink[i], [name]: value };
      this.setState({ suppressionLink: suppressionLink });
    }
  }


  /**
     * @author Somnath Keswad
     * @param  Description handle add the dynamic text fields
     * @return Description return all text field
   */
  addLink(e) {
    count = count + 1;
    var assetLinkArray = [];
    // count=assetLinkArray.length;
    if (this.state.multiTouchChk === 'Yes') {
      assetLinkArray = [{ linkAssetFileName: '', assetLink: '', multiTouch: '1st Touch', count: count }];
      let finalData = this.state.assetLinkArray.concat(assetLinkArray);
      this.setState({ dynamicInput: [...this.state.dynamicInput, { count: count, assetLink: '', linkAssetFileName: '', multiTouch: '1st Touch' }], assetLinkArray: finalData })
    } else {
      assetLinkArray = [{ linkAssetFileName: '', assetLink: '', count: count }];
      let finalData = this.state.assetLinkArray.concat(assetLinkArray);
      this.setState({ dynamicInput: [...this.state.dynamicInput, { count: count, assetLink: '', linkAssetFileName: '', }], assetLinkArray: finalData })
    }
  }
  /**
          * @author Somnath Keswad
          * @param  Description handle add Back Button the dynamic text fields
          * @return Description return all text field
        */
  backAddLink(e) {
    var count = this.state.backDynamicInput.length;
    count = count + 1;
    var assetLinkArray;
    if (this.state.leadPerAssetChk === 'Yes') {
      assetLinkArray = [{ linkAssetFileName: '', assetLink: '', leadPerAsset: '', leadPercentage: '', count: count }];
    }
    //Sanjana – Task -2979- Adding bydefault 1st Touch multiTouch if user first clicks on check box and then try to add new link
    if (this.state.multiTouchChkBack === 'Yes') {
      assetLinkArray = [{ linkAssetFileName: '', assetLink: '', multiTouch: '1st Touch', count: count }];
    }
    else {
      assetLinkArray = [{ linkAssetFileName: '', assetLink: '', count: count }];
    }

    let finalData = this.state.backDynamicInput.concat(assetLinkArray);
    this.setState({ backDynamicInput: finalData })

  }
  // end of backAddLink

  /**
      * @author Somnath Keswad
      * @param  Description handle remove the dynamic text fields
      * @return Description return remaining text field
    */

  removeLink(i) {
    this.state.dynamicInput.splice(i, 1);
    this.state.assetLinkArray.splice(i, 1);
    this.setState({ backDynamicInput: this.state.assetLinkArray });
  }
  /**
         * @author Somnath Keswad
         * @param  Description handle backButton remove the dynamic text fields
         * @return Description return remaining text field
       */
  backRemoveLink(e, i) {
    var supportDocID = e.target.getAttribute('name');

    this.state.backDynamicInput.splice(i, 1);
    this.setState({ backDynamicInput: this.state.backDynamicInput });
    if (supportDocID !== undefined || supportDocID != '') {
      fetch("campaign/deleteAssetLink?supportDocID=" + supportDocID)
        .then(res => res.json())
        .then(deleteAssetLink => {
          this.setState({ deleteAssetLink: deleteAssetLink })

        }).catch(function (err) { console.log(err) });
    }
  }

  backTo() {
    var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
    this.setState({ campID: campID });
  }



  componentDidMount() {

  }

  /**
    * @author Narendra Phadke
    * @param  Description handle the Back Button and login
    * @return Description return All details of authentication
  */
  componentWillMount() {

    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    } else {
      //var campID = window.location.href.split("?")[window.location.href.split("/").length - 1];
      let self = this;
      var parsed = queryString.parse(this.props.location.search);
      const { isAuthenticated, user } = this.props.auth
      var userID = user.id;
      var campID = parsed.campID;
      var rfpCampaignID = parsed.rfpCampaignID;

      //self.setState({rfpCampaignID:rfpCampaignID})
      this.setState({ rfpCampaignID: rfpCampaignID }, function () {
      })

      fetch("/diQARole/getSwitchValue?agencyID=" + userID + "")
        .then(res => res.json())
        .then((switchDetails => {
          if (switchDetails.length > 0) {
            if (switchDetails[0].DI_QA_Switch == "") {
              switchDetails[0].DI_QA_Switch = "OF"
            }
            this.setState({ switchValue: switchDetails[0].DI_QA_Switch })
          }
        }))

      // var isBack = window.location.href.split("=")[window.location.href.split("=").length - 1];

      var step2 = window.location.href.split("&")[window.location.href.split("?").length - 1];

      if (step2 === undefined || step2 === null || step2 === "" || step2 === 'undefined') {

        fetch("campaign/getMappingFormat?campID=" + campID + "")
          .then(res => res.json())
          .then(mappingFormat => {

            this.setState({ mappingFormat: mappingFormat })

          })

        fetch("campaign/getAgencyCustomizeMapping?campID=" + campID + "")
          .then(res => res.json())
          .then(agencyCustomizeMapping => {

            this.setState({ agencyCustomizeMapping: agencyCustomizeMapping, customizeMapping: 'No' })
          });

      }
      else {
        let self = this;
        fetch("/campaign/supportingDocumentForBack?campID=" + campID + "")
          .then(res => res.json())
          .then(sdEditDetail => {


            this.setState({ sdEditDetail: sdEditDetail })

            if (this.state.sdEditDetail != undefined || this.state.sdEditDetail != '' || this.state.sdEditDetail != null) {
              for (let i = 0; i < this.state.sdEditDetail.length; i++) {

                if (this.state.sdEditDetail[i].typeOfSuppDoc == 'Asset') {
                  key++;
                  this.state.inputType.push({ suppDocName: this.state.sdEditDetail[i].suppDocName, leadPerAsset: this.state.sdEditDetail[i].leadPerAsset, key: key, leadPercentage: this.state.sdEditDetail[i].leadPercentage, multiTouch: this.state.sdEditDetail[i].multiTouch });


                }
              } this.setState({ inputType: this.state.inputType });
              if (this.state.inputType.length > 0) {
                this.setState({ displayTable: 'block' });
              }


            }

          });

        fetch("campaign/getAssetLink?campID=" + campID)
          .then(res => res.json())
          .then(backDynamicInput => {

            self.setState({ backDynamicInput: backDynamicInput })


          }).catch(function (err) { console.log(err) });

        fetch("campaign/getSuppressionLink?campID=" + campID)
          .then(res => res.json())
          .then(suppressionLink => {
            self.setState({ suppressionLink: suppressionLink, displayTablesuppression: 'block' })
          }).catch(function (err) { console.log(err) });

        fetch("campaign/supportingDocumentForEditABM?campID=" + campID + "")
          .then(res => res.json())
          .then(sdEditDetailABM => {


            self.setState({ sdEditDetailABM: sdEditDetailABM, displayTableabm: 'block' })

            for (let i = 0; i < sdEditDetailABM.length; i++) {
              successAbmCount.push(sdEditDetailABM[i].suppDocName);

              chkABMExist.push(sdEditDetailABM[i].suppDocName)



            }



          });


        fetch("campaign/supportingDocumentForEditAsset?campID=" + campID + "")
          .then(res => res.json())
          .then(sdEditDetailAsset => {

            self.setState({ sdEditDetailAsset: sdEditDetailAsset })
            for (let i = 0; i < sdEditDetailAsset.length; i++) {
              successAssetCount.push(unescape(sdEditDetailAsset[i].suppDocName));
              this.state.assetFileNameArray.push(unescape(sdEditDetailAsset[i].suppDocName));


            }




          });



        fetch("campaign/supportingDocumentForEditOther?campID=" + campID + "")
          .then(res => res.json())
          .then(sdEditDetailOther => {
            self.setState({ sdEditDetailOther: sdEditDetailOther, displayTableother: 'block' })
            for (let i = 0; i < sdEditDetailOther.length; i++) {
              successOtherCount.push(sdEditDetailOther[i].suppDocName);
              chkOtherExist.push(sdEditDetailOther[i].suppDocName);
            }

          });

        fetch("campaign/supportingDocumentForEditSuppression?campID=" + campID + "")
          .then(res => res.json())
          .then(sdEditDetailSuppression => {
            self.setState({ sdEditDetailSuppression: sdEditDetailSuppression, displayTablesuppression: 'block' })
            for (let i = 0; i < sdEditDetailSuppression.length; i++) {
              successSuppressionCount.push(sdEditDetailSuppression[i].suppDocName);
              chkSuppressionExist.push(sdEditDetailSuppression[i].suppDocName)
            }

          });

        fetch("campaign/supportingDocumentForEditExclusion?campID=" + campID + "")
          .then(res => res.json())
          .then(sdEditDetailExclusion => {
            self.setState({ sdEditDetailExclusion: sdEditDetailExclusion, displayTableexclusion: 'block' })
            for (var i = 0; i < sdEditDetailExclusion.length; i++) {
              successExclusionCount.push(sdEditDetailExclusion[i].suppDocName);
              chkExclusionExist.push(sdEditDetailExclusion[i].suppDocName)
            }


          });

        fetch("campaign/getAgencyCustomizeMapping?campID=" + campID + "")
          .then(res => res.json())
          .then(agencyCustomizeMapping => {

            this.setState({ agencyCustomizeMapping: agencyCustomizeMapping, customizeMapping: 'No' })
          });
        if (this.state.agencyCustomizeMapping.length > 0) {
          let customMapping = this.state.agencyCustomizeMapping.filter((a) => a.customizeMappingFormat == this.state.customizeMappingFormat)
          this.setState({ agencyCustomizeMapping: customMapping })
        }
        fetch("campaign/deliveryFormatEdit?campID=" + campID + "")
          .then(res => res.json())
          .then(editDeliveryFormat => {


            me.setState({
              editDeliveryFormat: editDeliveryFormat//[{'city':true}]
            })

          });

      }


      this.setState({ campID: campID });
      let me = this;
      if (!me.props.auth.isAuthenticated) {
        me.props.history.push('/userLogin');

      }
    }


    var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
    fetch("campaign/CampaignDetails?campID=" + campID + "")
      .then(res => res.json())
      .then(detailsOfCampaign => {


        this.setState({
          detailsOfCampaign: detailsOfCampaign,
          totalLeadAllocation: detailsOfCampaign[0].leadAllocation,
          requiredLeadPerAsset: detailsOfCampaign[0].requiredLeadPerAsset,
          campaignType: detailsOfCampaign[0].ABM,
        })

        var leadPerAsset = document.getElementById("leadPerAssetBack");





        var defaultChecked = false;


        var leadPerAssetChk = '';
        if (this.state.requiredLeadPerAsset === 'Yes') {
          defaultChecked = true;

          leadPerAssetChk = 'Yes';
        }
        else {

          defaultChecked = false;
          leadPerAssetChk = 'No';
        }

        this.setState({ leadPerAssetChk: leadPerAssetChk, checkedValue: defaultChecked })
        if (detailsOfCampaign[0].jobLevel === "" && detailsOfCampaign[0].customJobLevel === "") {
          this.setState({
            detailsOfCampaign: detailsOfCampaign,
            jobLevelDelivery: "No"
          })
        } else {
          this.setState({
            jobLevelDelivery: "Yes"
          })
        }

        if (detailsOfCampaign[0].marketingChannel === "Email/Telemarketing") {
          this.setState({
            detailsOfCampaign: detailsOfCampaign,
            channel: "Yes"
          })
        } else {
          this.setState({
            channel: "No"
          })
        }

        if (detailsOfCampaign[0].jobTitleValidation === "No") {
          this.setState({

            jobTitleValidation: "No"
          })
        } else {
          this.setState({
            jobTitleValidation: "Yes"
          })
        }

        if (detailsOfCampaign[0].jobFunction === "" && detailsOfCampaign[0].customJobFunction === "") {
          this.setState({
            jobFunctionDelivery: "No"
          })
        }
        else {
          this.setState({
            jobFunctionDelivery: "Yes"
          })
        }
        if (detailsOfCampaign[0].companyRevenue === "" && detailsOfCampaign[0].customCompRevenue === "") {
          this.setState({ companyRevenue: "No" });
        }
        else {
          this.setState({ companyRevenue: "Yes" });
        }

        if (detailsOfCampaign[0].employeeSize === "" && detailsOfCampaign[0].customEmpSize === "") {
          this.setState({
            detailsOfCampaign: detailsOfCampaign,
            companyEmployeeSize: "No"
          })
        } else {
          this.setState({
            companyEmployeeSize: "Yes"
          })
        }

        if (detailsOfCampaign[0].industry === "" && detailsOfCampaign[0].customIndustry === "") {
          this.setState({
            industry: "No"
          })
        } else {
          this.setState({
            industry: "Yes"
          })
        }
      });



  }



  /**
    * @author Narendra Phadke
    * @param  A supportDoID sending to BE for delete file
    * @return List of uploded file along with remove link
    */

  dynamicABMFile(e) {
    e.preventDefault();
    let self = this;

    var suppDocName = e.target.getAttribute('name');

    e.preventDefault();

    let supportDocID;
    let supportDocType;


    var resValue = new Array();
    resValue = e.target.id.split(" ");
    for (i = 0; i < resValue.length; i++) {
      supportDocType = (resValue[0]);
      supportDocID = (resValue[1]);
    }
    if (supportDocType == 'Exclusion') {
      var deleteExclusionCounter = 0;
      var nooutputexeclusion = document.getElementById('noexclusionList');
      var valuenooutputexeclusion = document.getElementById('noexclusionList').innerHTML;
      var resexeclusion = new Array();
      resexeclusion = valuenooutputexeclusion.split(" ");
      var backecelusionarraylength;
      for (var i = 0; i < resexeclusion.length; i++) { backecelusionarraylength = (resexeclusion[0]); }
      deleteExclusionCounter++;
      $(this).parent().remove();
      if (deleteExclusionCounter > 0) {
        backecelusionarraylength = (backecelusionarraylength - deleteExclusionCounter);
        nooutputexeclusion.innerHTML = backecelusionarraylength + " Exclusion  Files Selected";
      }
      else {
        nooutputexeclusion.innerHTML = "0 Exclusion File Selected";
      }
      for (var k = 0; k < successExclusionCount.length; k++) {
        if (successExclusionCount[k] === suppDocName) {
          successExclusionCount.splice(k, 1);
        }
      } var rejectedCount = newexclusionfilenames.length - successExclusionCount.length;
      if (rejectedCount < 0) { rejectedCount = 0; }
      var msg1 = "";
      // "Successfully Uploads Exclusion Files (Uploaded Files :"+successExclusionCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
      var message1 = document.getElementById('exclusionMessage');
      if ($("#exclusionMessage").text() === "") {
        message1.innerHTML += msg1;
      } else { message1.innerHTML = msg1; }
      for (let k = 0; k < chkExclusionExist.length; k++) {
        if (chkExclusionExist[k] == suppDocName) {
          chkExclusionExist.splice(k, 1);
        }
      }
    } else if (supportDocType == 'ABM') {
      var deleteABMCounter = 0;
      var nooutput = document.getElementById('noabmList');
      var valuenooutput = document.getElementById('noabmList').innerHTML;
      var res = new Array();
      res = valuenooutput.split(" ");
      var backarraylength;
      for (i = 0; i < res.length; i++) { backarraylength = (res[0]); }
      deleteABMCounter++;
      $(this).parent().remove();

      if (deleteABMCounter > 0) {

        backarraylength = parseInt(backarraylength);
        backarraylength = (backarraylength - deleteABMCounter);
        nooutput.innerHTML = backarraylength;
      }
      else {
        nooutput.innerHTML = "0 ABM File Selected";
      }
      for (var k = 0; k < successAbmCount.length; k++) {
        if (successAbmCount[k] == suppDocName) {
          successAbmCount.splice(k, 1);
        }
      }
      var rejectedCount = newabmfilenames.length - successAbmCount.length;
      if (rejectedCount < 0) { rejectedCount = 0; }
      var msg1 = "";
      // "Successfully Uploads ABM Files (Uploaded Files :"+successAbmCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
      var message1 = document.getElementById('abmMessage');
      if ($("#abmMessage").text() === "") {
        message1.innerHTML += msg1;
      } else { message1.innerHTML = msg1; }
      for (let k = 0; k < chkABMExist.length; k++) {
        if (chkABMExist[k] == suppDocName) {
          chkABMExist.splice(k, 1);
        }
      }

    } else if (supportDocType == 'Suppression') {
      var deleteSuppressionCounter = 0;
      var nosuppressionoutput = document.getElementById('nosuppressionList');
      var valuenosuppressionoutput = document.getElementById('nosuppressionList').innerHTML;
      var suppressionres = new Array();
      suppressionres = valuenosuppressionoutput.split(" ");

      var backsuppressionarraylength;
      for (i = 0; i < suppressionres.length; i++) { backsuppressionarraylength = (suppressionres[0]); }
      deleteSuppressionCounter++;
      $(this).parent().remove();

      if (deleteSuppressionCounter > 0) {
        backsuppressionarraylength = (backsuppressionarraylength - deleteSuppressionCounter);
        nosuppressionoutput.innerHTML = backsuppressionarraylength;
      }
      else {
        nosuppressionoutput.innerHTML = "";
      }
      for (var k = 0; k < successSuppressionCount.length; k++) {
        if (successSuppressionCount[k] === suppDocName) {
          successSuppressionCount.splice(k, 1);
        }
      }
      let rejectedCount = newsuppressionfilenames.length - successSuppressionCount.length;
      if (rejectedCount < 0) { rejectedCount = 0; }
      let msg1 = "";
      // "Successfully Uploads Suppression Files (Uploaded Files :"+successSuppressionCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
      let message1 = document.getElementById('suppresionMessage');
      if ($("#suppresionMessage").text() === "") {
        message1.innerHTML += msg1;
      } else { message1.innerHTML = msg1; }

      for (let k = 0; k < chkSuppressionExist.length; k++) {
        if (chkSuppressionExist[k] == suppDocName) {
          chkSuppressionExist.splice(k, 1);
        }
      }

    } else if (supportDocType == 'Other') {
      var deleteOtherCounter = 0;
      var nootheroutput = document.getElementById('nootherList');
      var valuenootheroutput = document.getElementById('nootherList').innerHTML;
      var otherres = new Array();
      otherres = valuenootheroutput.split(" ");

      var backotherarraylength;
      for (i = 0; i < otherres.length; i++) {
        backotherarraylength = (otherres[0]);
      }
      deleteOtherCounter++;
      $(this).parent().remove();

      if (deleteOtherCounter > 0) {
        backotherarraylength = (backotherarraylength - deleteOtherCounter);
        nootheroutput.innerHTML = backotherarraylength;
      }
      else {
        nosuppressionoutput.innerHTML = "0 Other File Selected";
      } for (var k = 0; k < successOtherCount.length; k++) {
        if (successOtherCount[k] === suppDocName) {
          successOtherCount.splice(k, 1);
        }
      }
      let rejectedCount = newotherfilenames.length - successOtherCount.length;
      if (rejectedCount < 0) { rejectedCount = 0; }
      let msg1 = "";
      // "Successfully Uploads Other Files (Uploaded Files :"+successOtherCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
      let message1 = document.getElementById('otherMessage');
      if ($("#otherMessage").text() === "") {
        message1.innerHTML += msg1;
      } else { message1.innerHTML = msg1; }
      for (let k = 0; k < chkOtherExist.length; k++) {
        if (chkOtherExist[k] == suppDocName) {
          chkOtherExist.splice(k, 1);
        }
      }
    } else if (supportDocType == 'Asset') {
      var deleteAssetCounter = 0;
      var noassetoutput = document.getElementById('noassetList');
      var valuenoassetoutput = document.getElementById('noassetList').innerHTML;
      var assetres = new Array();
      assetres = valuenoassetoutput.split(" ");
      var backassetarraylength;
      for (i = 0; i < assetres.length; i++) {
        backassetarraylength = (assetres[0]);
      }

      deleteAssetCounter++;
      $(this).parent().remove();

      if (deleteAssetCounter > 0) {
        backassetarraylength = (backassetarraylength - deleteAssetCounter);
        noassetoutput.innerHTML = backassetarraylength;
      }
      else {
        noassetoutput.innerHTML = "0 Other File Selected";
      }
      for (var k = 0; k < successAssetCount.length; k++) {
        if (successAssetCount[k] === suppDocName) {
          successAssetCount.splice(k, 1);
        }
      }
      var rejectedCount = newassetfilenames.length - successAssetCount.length;
      if (rejectedCount < 0) { rejectedCount = 0 }
      var succcount = successAssetCount.length;
      var rejeccount = rejectAssetCount.length;
      var totalcount = succcount + rejeccount;
      var msg1 = "<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" + totalcount + "</span>&nbsp;(Successfully Uploaded :" + successAssetCount.length + ", <font color='red'> Upload Failed :" + rejectedCount + "</font>)";
      var message1 = document.getElementById('assetMessage');
      if ($("#assetMessage").text() === "") {
        message1.innerHTML += msg1;
      } else { message1.innerHTML = msg1; }
    }
    let campaignDetail = this.state.sdEditDetail;

    campaignDetail = campaignDetail.filter(el => el.supportDocID != supportDocID);
    var index = campaignDetail.findIndex(v => v.supportDocID === supportDocID);

    self.setState({ sdEditDetail: campaignDetail });

    var result = [];
    result = Array.from(new Set(self.state.dynamicAssetName.map(p => p.key))).map(key => {
      return {
        key: key,
        suppDocName: self.state.dynamicAssetName.find(p => p.key === key).suppDocName,
        leadPerAsset: this.state.dynamicAssetName.find(p => p.key === key).leadPerAsset,
        leadPercentage: this.state.dynamicAssetName.find(p => p.key === key).leadPercentage

      };
    });


    /**
     * @author Narendra Phadke
     * @param  Delete File From Database
     */

    for (var i = 0; i < self.state.inputType.length; i++) {
      if (suppDocName === self.state.inputType[i].suppDocName) {
        self.state.inputType.splice(i, 1);
        result.splice(i, 1);
      }
    }
    self.setState({ inputType: self.state.inputType, dynamicAssetName: result });

    if (self.state.inputType.length == 0) {
      self.setState({ displayTable: 'none' });
    }
    let data = {
      supportDocID: supportDocID,

    }

    //campaign / deleteSupportingDocFile
    fetch("campaign/deleteSupportingDocFile", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });
    // window.location.reload();
  }




  /**
     * @author Supriya Gore
     * @param  Description handle the leads per asset
     * @return Description return successfully leads per asset
     */
  leadPerAssethandleChange(e) {
    let leadPerAssetChk = e.target.value;


    if (e.target.checked === true) {

      leadPerAssetChk = 'Yes';

    }
    else {

      leadPerAssetChk = 'No';
    }


    this.setState({ leadPerAssetChk: leadPerAssetChk });
  }


  /**
   * @author Supriya Gore
   * @param  Description handle the leads per asset
   * @return Description return successfully leads per asset
   */
  leadPerAssethandleChangeBack(e) {
    let leadPerAssetChk = e.target.value;
    var defaultChecked = false;
    if (e.target.checked === true) {
      leadPerAssetChk = 'Yes';
      defaultChecked = true;
    }
    else {
      leadPerAssetChk = 'No';
      defaultChecked = false;
    }
    this.setState({ leadPerAssetChk: leadPerAssetChk, checkedValue: defaultChecked });
  }
  // end of leadPerAssethandleChangeBack
  /**
 * @author Raunak Thakkar
 * @param  Description handle the multiple Touch for assets
 * @return shows a check box if more than 1 asset is present
 */

  multiTouchHandleChange(e) {
    let multiTouchChk = e.target.value;
    var assetName_touch1 = this.state.assetName_touch1;
    var assetTimestamp_touch1 = this.state.assetTimestamp_touch1;
    let tempInputType = [];// created by Raunak
    let tempDynamicInput = []; //created by Raunak
    var assetLinkFile = [];
    var multiTouchUnCheck = 'No';
    if (e.target.checked === true) {
      multiTouchUnCheck = 'Yes';
      multiTouchChk = 'Yes';
      assetName_touch1 = 'Yes';
      assetTimestamp_touch1 = '1970-01-01 00:00:01';
      for (var i = 0; i < this.state.inputType.length; i++) {
        if (this.state.inputType[i] === undefined) { } else {
          if (this.state.inputType[i].hasOwnProperty('multiTouch')) {
            tempInputType.push({ ...this.state.inputType[i] })
          } else {
            tempInputType.push({ ...this.state.inputType[i], multiTouch: '1st Touch' })

          }
        }
      }

      for (var i = 0; i < this.state.dynamicInput.length; i++) {
        if (this.state.dynamicInput[i] === undefined) { } else {
          if (this.state.dynamicInput[i].hasOwnProperty('multiTouch')) {
            tempDynamicInput.push(this.state.dynamicInput[i]);
          } else {
            tempDynamicInput.push({ ...this.state.dynamicInput[i], multiTouch: '1st Touch' })

          }
        }
      }
    }
    else {

      multiTouchChk = 'No';

    }
    if (multiTouchUnCheck === 'Yes') {

      this.setState({ multiTouchChk: multiTouchChk, assetName_touch1: assetName_touch1, assetTimestamp_touch1: assetTimestamp_touch1, inputType: tempInputType, dynamicInput: tempDynamicInput });
    } else {
      this.setState({ multiTouchChk: multiTouchChk, assetName_touch1: assetName_touch1, assetTimestamp_touch1: assetTimestamp_touch1 });

    }
  }


  /**
* @author Sanjana Godbole
* @param  Description Task 2979- handle the multiple Touch for assets for back flow
* @return Check box check uncheck functionality 
*/
  multiTouchHandleChangeBack(e) {
    let multiTouchChkBack = e.target.value;
    var assetName_touch1 = this.state.assetName_touch1;
    var assetTimestamp_touch1 = this.state.assetTimestamp_touch1;
    var assetLink = [];
    var assetLinkFile = [];
    var multiTouchUnCheck = 'No';
    if (e.target.checked === true) {
      multiTouchChkBack = 'Yes';
      assetName_touch1 = 'Yes';
      assetTimestamp_touch1 = '1970-01-01 00:00:01';
      multiTouchUnCheck = 'Yes';

      //For Asset File
      for (var i = 0; i < this.state.inputType.length; i++) {
        if (this.state.inputType[i] === undefined) { } else {
          if (this.state.inputType[i].hasOwnProperty('multiTouch')) {
            assetLinkFile.push({ ...this.state.inputType[i] })
          } else {
            assetLinkFile.push({ ...this.state.inputType[i], multiTouch: '1st Touch' })

          }
        }
      }

      //For Asset Link
      for (var i = 0; i < this.state.backDynamicInput.length; i++) {
        if (this.state.backDynamicInput[i] === undefined) { } else {
          if (this.state.backDynamicInput[i].hasOwnProperty('multiTouch')) {
            assetLink.push({ ...this.state.backDynamicInput[i] })
          }
          else {
            assetLink.push({ ...this.state.backDynamicInput[i], multiTouch: '1st Touch' })

          }
        }
      }

    }
    else {
      multiTouchChkBack = 'No';

    }
    if (multiTouchUnCheck === 'Yes') {
      this.setState({
        multiTouchChkBack: multiTouchChkBack, assetName_touch1: assetName_touch1, assetTimestamp_touch1: assetTimestamp_touch1,
        backDynamicInput: assetLink, inputType: assetLinkFile
      });
    } else {
      this.setState({ multiTouchChkBack: multiTouchChkBack, assetName_touch1: assetName_touch1, assetTimestamp_touch1: assetTimestamp_touch1 });

    }


  }
  // end of multiTouchHandleChangeBack

  /**
   * @author Narendra Phadke
   * @param  Description handle the multiple asset uploads
   * @return Description return successfully files upload message
   */

  handleChangeMultipleFile(e) {
    e.preventDefault();
    var MultipleFileCounter = 0;
    let self = this;
    var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
    assetnames = [];
    self.setState({ displayTable: 'block' });
    let assetfilesData = e.target.files;
    var step2 = window.location.href.split("&")[window.location.href.split("?").length - 1];
    if (step2 === 'undefined' || step2 === null || step2 === "" || step2 === undefined) {

      if (self.state.inputType === 0) {
        var num = Math.floor(Math.random() * 163);
        var size = (self.state.inputType.length) + num;
      } else {
        var len = (self.state.inputType.length) - 1;

        if (len > 0) {
          var num = Math.floor(Math.random() * 137);
          var size1 = self.state.inputType[len].key;
          var size = size1 + (self.state.inputType.length) + num;// hardcoded because incremental kay

        } else {
          var num = Math.floor(Math.random() * 100);
          var size = (self.state.inputType.length) + num;

        }
      }
    }
    else {

      if (self.state.dynamicAssetName === 0) {
        var size = (self.state.inputType.length) + 13;
        size = size + 13;
      } else {
        var len = (self.state.dynamicAssetName.length) - 1;
        if (len > 0) {
          var num = Math.floor(Math.random() * 137);
          var size1 = self.state.dynamicAssetName[len].key;
          var size = size1 + (self.state.inputType.length) + num;// hardcoded because incremental kay

        } else {
          var num = Math.floor(Math.random() * 100);
          var size = (self.state.inputType.length) + num;

        }
      }
    }
    key = size;
    var assetNameDuplicateFileCheck = [];
    for (var j = 0; j < self.state.inputType.length; j++) {
      assetNameDuplicateFileCheck.push(self.state.inputType[j].suppDocName);
    }
    let fileerrors = {}

    if ($.isEmptyObject(assetnames)) {
      for (var i = 0, l = assetfilesData.length; i < l; i++) {

        let assetNameTemp = assetfilesData[i].name;
        let assetCheckDuplicateLength = 0;
        let assetCheckDuplicate = assetNameDuplicateFileCheck.filter(function (word) {
          return assetNameTemp.includes(word);
        });
        assetCheckDuplicateLength = assetCheckDuplicate.length;

        if (assetCheckDuplicateLength > 0) {
          fileerrors["FileError"] = "Please select file with another name   " + assetNameTemp + "  already exists";
          this.setState({ fileerrors: fileerrors });

        }
        else {
          this.setState({ fileerrors: '' });
          assetnames.push(assetfilesData[i].name);
          var assetFileCheck = assetnames.
            MultipleFileCounter++;
          key = key + 3;

          self.state.inputType.push({ suppDocName: assetfilesData[i].name, key: key });
        }
      }
      self.setState({ inputType: self.state.inputType, assetsFile: assetnames }, function () {
        //Sanjana – Task -2979- by default leadPerAsset and multi-touch fields added in array while uploading asset files
        let inputTypeTemp = [...this.state.inputType]
        if (document.getElementById('leadPerAssetChk').checked == true) {
          for (let i = 0; i < this.state.inputType.length; i++) {
            if (!inputTypeTemp[i].hasOwnProperty("leadPerAsset")) {
              inputTypeTemp[i] = { ...inputTypeTemp[i], "leadPerAsset": "" }
            }
            if (!inputTypeTemp[i].hasOwnProperty("leadPercentage")) {
              inputTypeTemp[i] = { ...inputTypeTemp[i], "leadPercentage": "" }
            }
          }
        }
        if (document.getElementById('multitouchChk') == null ? document.getElementById('multitouchChkBack').checked : document.getElementById('multitouchChk').checked) {
          let inputTypeTemp = [...this.state.inputType]
          for (let i = 0; i < this.state.inputType.length; i++) {
            if (!inputTypeTemp[i].hasOwnProperty("multiTouch")) {

              inputTypeTemp[i] = { ...inputTypeTemp[i], "multiTouch": "1st Touch", "assetTimestamp_touch": "Yes" }
            }
          }
          this.setState({ inputType: inputTypeTemp }, function () {

          })
        }
      });

    }
    else {

      for (var j = 0, m = assetfilesData.length; j < m; j++) {
        key = key + 3;

        self.state.inputType.push({ suppDocName: assetfilesData[j].name, key: key });

        assetnames.push(assetfilesData[j].name);
        MultipleFileCounter++;
      }
      self.setState({ inputType: self.state.inputType, assetsFile: assetnames });
    }

    assetLength = assetnames.length;

    if (assetLength > 0) {

      //   this.setState({
      //     asseterrors: ''
      // })
    }


    /**
    * @author Sanjana Godbole
    * @param  A FileList object that lists every selected file
    * @return List of uploded file along with remove link
    */

    var link;
    var filename = '';
    var arrayLength = assetnames.length;
    var nooutput = document.getElementById('noassetList');

    var assetarrayLength = assetnames.length;
    var noassetoutput = document.getElementById('noassetList');
    var valuenoassetoutput = document.getElementById('noassetList').innerHTML;

    var assetres = new Array();
    assetres = valuenoassetoutput.split(" ");

    var backassetarraylength;
    for (i = 0; i < assetres.length; i++) {
      backassetarraylength = (assetres[0]);


    }


    assetarrayLength = parseInt(assetarrayLength);
    backassetarraylength = parseInt(backassetarraylength);

    MultipleFileCounter = parseInt(MultipleFileCounter);
    var assetarrayLength1;
    assetarrayLength1 = MultipleFileCounter + backassetarraylength + assetLength;


    if (assetarrayLength1 > 0) {
      noassetoutput.innerHTML = assetarrayLength1 + "Asset  Files Selected";
    }
    else {
      noassetoutput.innerHTML = "0 Asset File Selected";
    }

    for (var j = 0; j < assetarrayLength; j++) {
      newassetfilenames.push(assetnames[j]);
    }

    var HTML = "<table style='width:100%'>";
    var imgsuccess;
    for (var j = 0; j < newassetfilenames.length; j++) {
      var isExist = assetValidationArray.includes(newassetfilenames[j]);

      if (isExist === true) {
        imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>&nbsp;'
        link = '<a class="removeAssetFile" href="#" data-fileid=' + '"' + newassetfilenames[j] + '"' + '><span  style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
        HTML += "<tr style='background-color:#fbd5db'><td style='color:red;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span class=asset1 style='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 215px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" + newassetfilenames[j] + "</span>" + link + "<br><br></td></tr>";

      } else {
        imgsuccess = '<img src="success.png" alt="sucess" height="18" width="18" style=:"float:left"/>&nbsp;'
        link = '<a class="removeAssetFile" href="#" data-fileid=' + '"' + newassetfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500;float:right">Remove</span></a>';
        HTML += "<tr style='background-color:#daecda'><td style='color:green;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span class=asset1 style='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 215px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" + newassetfilenames[j] + "</span>" + link + "<br><br></td></tr>";

      }

    }
    HTML += "</table>";

    document.getElementById("assetList").innerHTML = HTML;
    $(document).on("click", ".removeAssetFile", function (e) {

      e.preventDefault();


      var clickedFile = $(this).closest("a").data("fileid");
      var supportDocID = $(this).attr('supportDocID');
      var j = 0

      for (j; j < newassetfilenames.length; j++) {
        var temp = j;
        if (newassetfilenames[temp] === clickedFile) {
          // errorFile=false;
          var isExist = assetValidationArray.includes(newassetfilenames[j]);
          if (isExist === true) {
            var index = assetValidationArray.indexOf(newassetfilenames[j]);
            assetValidationArray.splice(index, 1);
          }
          filename = newassetfilenames[temp];
          newassetfilenames.splice(j, 1);
          // filesData.splice(j,1);
          $(this).parent().remove();
          assetarrayLength = newassetfilenames.length;
          if (newassetfilenames.length == 0) {
            assetValidationArray = [];
            rejectAssetCount = [];
            // successAssetCount=[];
          }
          for (var k = 0; k < rejectAssetCount.length; k++) {

            if (rejectAssetCount[k] == clickedFile) {
              rejectAssetCount.splice(k, 1);
              var rejectedCount = newassetfilenames.length - successAssetCount.length;
            }
          }
          for (var k = 0; k < successAssetCount.length; k++) {
            if (successAssetCount[k] == clickedFile) {
              successAssetCount.splice(k, 1);
            }
          }
          var rejectedCount = newassetfilenames.length - successAssetCount.length;
          if (rejectedCount < 0) { rejectedCount = 0 }
          var succcount = successAssetCount.length;
          var rejeccount = rejectAssetCount.length;
          var totalcount = succcount + rejeccount;
          var msg1 = "<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" + totalcount + "</span>&nbsp;(Successfully Uploaded :" + successAssetCount.length + ", <font color='red'> Upload Failed :" + rejectedCount + "</font>)";
          var message1 = document.getElementById('assetMessage');
          if ($("#assetMessage").text() === "") {
            message1.innerHTML += msg1;
          } else { message1.innerHTML = msg1; }

          /**
          * @author Narendra Phadke
          * @param  Delete File From Database
          */
          for (var i = 0; i < self.state.inputType.length; i++) {
            if (filename === self.state.inputType[i].suppDocName) {
              self.state.inputType.splice(i, 1);
              self.state.assetFileNameArray.splice(i, 1);
              if (step2 === 'undefined' || step2 === null || step2 === "" || step2 === undefined) { }
              else {
                self.state.dynamicAssetName.splice(i, 1);
                self.setState({ dynamicAssetName: self.state.dynamicAssetName });
              }
            }
          }

          self.setState({ inputType: self.state.inputType, assetFileNameArray: self.state.assetFileNameArray });

          if (self.state.inputType.length == 0) {

            self.setState({ displayTable: 'none' });
          }

          var typeOfSuppDoc = "Asset";
          let data = {
            campID: campID,
            names: filename,
            typeOfSuppDoc: typeOfSuppDoc,
            supportDocID, step: 'Create'
          }

          fetch("campaign/supportingDocumentDeleteFile", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            //mode:'no-cors',
            //body: data
            body: JSON.stringify(data)
          });

          assetarrayLength = parseInt(assetarrayLength);
          backassetarraylength = parseInt(backassetarraylength);
          assetarrayLength1 = assetarrayLength + backassetarraylength;


          if (assetarrayLength1 > 0) {
            noassetoutput.innerHTML = assetarrayLength1;
          }
          else {
            noassetoutput.innerHTML = "0 Asset File Selected";
            $("#asset").val('');
            var message = document.getElementById('assetMessage');
            message.innerHTML = "";
          }
        }// end of  if(names[temp] === clickedFile)

      }// end of for
      let assetJson1 = [...self.state.assetJson];
      let assetJson = assetJson1.filter((a) => a.supportDocID != supportDocID);
      self.setState({ assetJson });
    });//$(document).on


    var data = new FormData();
    data.append("campID", campID);
    //data.append("names",names);
    var assetNameFlag;
    for (var i = 0, l = assetfilesData.length; i < l; i++) {

      var assetNameTemp = assetfilesData[i].name;
      var assetCheckDuplicateLength = 0;
      var assetCheckDuplicate = assetNameDuplicateFileCheck.filter(function (word) {
        return assetNameTemp.includes(word);
      });
      assetCheckDuplicateLength = assetCheckDuplicate.length;
      if (assetCheckDuplicateLength > 0) {


      }
      else {
        data.append("file", assetfilesData[i]);
        this.setState({ fileerrors: '' });
        assetNameFlag = true;
        this.state.assetFileNameArray.push({ 'assetFileName': assetfilesData[i].name });
      }
    }
    //campaign/supportingDocument
    if (assetNameFlag === true) {
      Swal.fire({
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading()
          fetch("campaign/supportingDocumentAsset", {
            method: "POST",
            // headers: {'Content-Type': 'application/json'},
            //mode:'no-cors',
            // body: data
            body: data
          }).then(res => res.json())
            .then(assetList => {


              Swal.fire({ text: "File Upload Complete ", type: "success" })
              let assetJson1 = [...self.state.assetJson]
              let assetJson = [...assetJson1, ...assetList];
              self.setState({ assetJson });
              if (assetList[0].success === true) {
                var suppDocName = [];
                for (let i = 0; i < assetList.length; i++) {
                  suppDocName.push(assetList[i].fileName);
                  successAssetCount.push(assetList[i].fileName);
                }
                for (let i = 0; i < suppDocName.length; i++) {
                  var count = 0;
                  for (var j = 0; j < newassetfilenames.length; j++) {
                    if (suppDocName[i] == newassetfilenames[j]) {
                      count = count + 1;
                    }
                  }
                }
                if (count > 1) {

                  newassetfilenames.pop();
                  assetValidationArray.pop();
                }
                var rejectCount = 0;
                var HTML2 = "<table style='width:100%'>";
                for (let j = 0; j < newassetfilenames.length; j++) {
                  let singleAsset = assetJson.filter((a) => a.fileName == newassetfilenames[j])
                  let supportDocID = singleAsset[0].supportDocID;
                  var isExist = assetValidationArray.includes(newassetfilenames[j]);
                  if (isExist === true) {/**** If uploaded file is wrong then it displays as in Red color */
                    rejectCount = rejectCount + 1;

                    imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>&nbsp;'
                    link = '<a class="removeAssetFile" href="#"supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newassetfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                    HTML2 += "<tr style='background-color:#fbd5db'><td style='color:red;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span class=asset1  style ='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 215px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" + newassetfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                  } else {

                    imgsuccess = '<img src="success.png" alt="success" height="18" width="18" style="float:left"/>&nbsp;'
                    link = '<a class="removeAssetFile" href="#" supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newassetfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500;float:right">Remove</span></a>';
                    HTML2 += "<tr style='background-color:#daecda'><td style='color:green;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span class=asset1 style='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 215px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" + newassetfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                  }
                }
                HTML2 += "</table>";
                document.getElementById("assetList").innerHTML = HTML2;
                var succcount = successAssetCount.length;
                var rejeccount = rejectAssetCount.length;
                var totalcount = succcount + rejeccount;
                var msg = "<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" + totalcount + "</span>&nbsp;(Successfully Uploaded :" + successAssetCount.length + ", <font color='red'> Upload Failed :" + rejectCount + "</font>)";
                var message = document.getElementById('assetMessage');
                if ($("#assetMessage").text() === "") {
                  message.innerHTML += msg;
                }
                else {
                  message.innerHTML = msg;
                }
              } else {

                for (let i = 0; i < assetList.length; i++) {
                  assetValidationArray.push(assetList[i].fileName);
                }
                var HTML = "<table style='width:100%'>";
                for (let j = 0; j < newassetfilenames.length; j++) {
                  let singleAsset = assetJson.filter((a) => a.fileName == newassetfilenames[j])
                  let supportDocID = singleAsset[0].supportDocID;
                  let isValid = assetValidationArray.includes(newassetfilenames[j]);
                  if (isValid === true) {
                    imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>&nbsp;'

                    link = '<a class="removeAssetFile" href="#"supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newassetfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                    HTML += "<tr style='background-color:#fbd5db'><td style='color:red;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span class=asset1 style='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 215px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" + newassetfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                  } else {
                    let isTrue = successAssetCount.includes(newassetfilenames[j]);
                    if (isTrue === false) { successAssetCount.push(newassetfilenames[j]); }
                    imgsuccess = '<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>&nbsp;'
                    link = '<a class="removeAssetFile" href="#"supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newassetfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500;float:right">Remove</span></a>';
                    HTML += "<tr style='background-color:#daecda'><td style='color:green;width:576px;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span class=asset1 style='text-align:left;font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 215px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;float:left'>" + newassetfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                  }
                }
                HTML += "</table>";
                document.getElementById("assetList").innerHTML = HTML;
                var rejectedCount = newassetfilenames.length - successAssetCount.length;
                var succcount = successAssetCount.length;
                var rejeccount = rejectAssetCount.length;
                var totalcount = succcount + rejeccount;
                var msg1 = "<span style='color:#777;font-family:roboto;'>Total Uploaded Asset Files :" + totalcount + "</span>&nbsp;(Successfully Uploaded :" + (successAssetCount.length) + ", <font color='red'> Upload Failed :" + rejectedCount + "</font>)";
                var message1 = document.getElementById('assetMessage');
                if ($("#assetMessage").text() === "") {
                  message1.innerHTML += msg1;
                }
                else {
                  message1.innerHTML = msg1;
                }
              }
            }).catch(function (err) {
              Swal.fire({ text: "Error Occured Please Try again", type: "error", })
              console.log(err)
            })
        }
      });
      this.setState({ files: data });
    }
  }
  /**
   * @author Narendra Phadke
   * @param  Description handle the multiple other files uploads
   * @return Description return successfully files upload message
   */
  handleChangeMultipleFileOfOther(e) {

    let othernames = [];
    let self = this;
    var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
    e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
    e.setAttribute("type", "file");//setting a attribute for type file added by Raunak
    e.setAttribute("multiple", true);//setting a attribute for multiple added by Raunak
    e.click();//to open file dialougeadded by Raunak
    e.addEventListener("change", function () { //calling an eventListener at change event added by Raunak
      self.setState({ displayTableother: 'block' });


      let otherfilesData = e.files;
      console.warn("data files", otherfilesData);


      for (var i = 0, l = otherfilesData.length; i < l; i++) {
        let fileName = otherfilesData[i].name;
        if (chkOtherExist.includes(fileName)) {
          duplicateOtherFile.push(fileName)
        } else {
          othernames.push(otherfilesData[i].name);
        }
      }
      if (chkOtherExist != othernames) {

        rfpChange++;
      }


      /**
   * @author Sanjana Godbole
   * @param  A FileList object that lists every selected file
   * @return List of uploded file along with remove link
   */

      var link;
      var filename = '';
      var arrayLength = othernames.length;
      //var nooutput = document.getElementById('nootherList');
      var otherarrayLength = othernames.length;
      var nootheroutput = document.getElementById('nootherList');
      var valuenootheroutput = document.getElementById('nootherList').innerHTML;


      var otherres = new Array();
      otherres = valuenootheroutput.split(" ");

      var backotherarraylength;
      for (let i = 0; i < otherres.length; i++) {
        backotherarraylength = (otherres[0]);

      }



      otherarrayLength = parseInt(otherarrayLength);
      backotherarraylength = parseInt(backotherarraylength);

      var otherarrayLength1;
      otherarrayLength1 = otherarrayLength + backotherarraylength;

      if (otherarrayLength1 > 0) {
        nootheroutput.innerHTML = otherarrayLength;
      }
      else {
        nootheroutput.innerHTML = "0 Other File Selected";
      }
      for (var j = 0; j < otherarrayLength; j++) {
        newotherfilenames.push(othernames[j]);
        chkOtherExist.push(othernames[j]);
      }


      var HTML = "<table>";
      var imgsuccess;
      for (var j = 0; j < newotherfilenames.length; j++) {
        var isExist = otherValidationArray.includes(newotherfilenames[j]);
        if (isExist === true) {

          imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'
          link = '<a class="removeOtherFile" href="#" data-fileid=' + '"' + newotherfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
          HTML += "<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newotherfilenames[j] + "</span>" + link + "<br><br></td></tr>";

        } else {
          imgsuccess = '<img src="success.png" alt="success" height="18" width="18" style="float:left"/>'
          link = '<a class="removeOtherFile" href="#" data-fileid=' + '"' + newotherfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
          HTML += "<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newotherfilenames[j] + "</span>" + link + "<br><br></td></tr>";

        }

      }
      HTML += "</table>";




      document.getElementById("otherList").innerHTML = HTML;
      $(document).on("click", ".removeOtherFile", function (e) {
        e.preventDefault();
        var clickedFile = $(this).parent().children("a").data("fileid");
        let supportDocID = $(this).attr('supportDocID');
        var j = 0
        for (j; j < newotherfilenames.length; j++) {
          var temp = j;

          if (newotherfilenames[temp] === clickedFile) {

            var isExist = otherValidationArray.includes(newotherfilenames[j]);

            if (isExist === true) {

              var index = otherValidationArray.indexOf(newotherfilenames[j]);
              otherValidationArray.splice(index, 1);
            }
            filename = newotherfilenames[temp];

            newotherfilenames.splice(j, 1);
            chkOtherExist.splice(j, 1);
            $(this).parent().remove();
            otherarrayLength = newotherfilenames.length;
            if (newotherfilenames.length == 0) {
              otherValidationArray = [];
              rejectOtherCount = [];
              var message1 = document.getElementById('otherMessage');
              message1.innerHTML = '';
            }
            for (var k = 0; k < rejectOtherCount.length; k++) {
              if (rejectOtherCount[k] == clickedFile) {
                rejectOtherCount.splice(k, 1);
              }
            }
            for (var k = 0; k < successOtherCount.length; k++) {
              if (successOtherCount[k] == clickedFile) {
                successOtherCount.splice(k, 1);
              }
            }
            var rejectedCount = newotherfilenames.length - successOtherCount.length;
            if (rejectedCount < 0) { rejectedCount = 0; }
            var msg1 = "";
            // "Successfully Uploads Other Files (Uploaded Files :"+successOtherCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
            var message1 = document.getElementById('otherMessage');

            if (otherValidationArray.length > 0) {

            }
            else {
              message1.innerHTML = msg1;
            }


            if (newotherfilenames.length == 0) {
              var message1 = document.getElementById('otherMessage');
              message1.innerHTML = '';
            }
            /**
             * @author Narendra Phadke
             * @param  Delete File From Database
             */
            if (newotherfilenames.length == 0) {
              self.setState({ displayTableother: 'none' });
            }
            var typeOfSuppDoc = "Other";
            let data = {
              campID: campID,
              names: filename,
              typeOfSuppDoc: typeOfSuppDoc,
              supportDocID, step: 'Create'
            }

            fetch("campaign/supportingDocumentDeleteFile", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              //mode:'no-cors',
              //body: data
              body: JSON.stringify(data)
            });

            otherarrayLength = parseInt(otherarrayLength);
            backotherarraylength = parseInt(backotherarraylength);
            otherarrayLength1 = otherarrayLength + backotherarraylength;


            if (otherarrayLength1 > 0) {

              nootheroutput.innerHTML = otherarrayLength1;
            }
            else {

              nootheroutput.innerHTML = "0 Other File Selected";

              var message = document.getElementById('otherMessage');
              message = '';
              //  var number=document.getElementById("otherMessage").innerHTML;  
              //  if(number=="")
              //  {
              //    message.innerHTML += msg;
              //  }
            }
          }// end of  if(names[temp] === clickedFile)

        }// end of for
        let otherJson1 = [...self.state.otherJson];
        let otherJson = otherJson1.filter((a) => a.supportDocID != supportDocID);
        self.setState({ otherJson });
      });//$(document).on

      var data = new FormData();
      data.append("campID", campID);

      for (var i = 0, l = otherfilesData.length; i < l; i++) {
        let fileName = otherfilesData[i].name;
        if (duplicateOtherFile.includes(fileName)) { }
        else {
          data.append("file", otherfilesData[i]);
        }
      }
      //campaign/supportingDocument

      Swal.fire({
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading()
          fetch("campaign/supportingDocumentOther", {
            method: 'POST',
            //mode:'no-cors',
            body: data

          }).then(res => res.json())
            .then(otherList => {
              if (otherList[0].success === true) {
                Swal.fire({ text: "File Upload Complete", type: "success" })
              } else {
                Swal.fire({ text: "Invalid format of the file", type: "error" })
              }
              let otherJson1 = [...self.state.otherJson]
              let otherJson = [...otherJson1, ...otherList];
              self.setState({ otherJson });
              if (otherList[0].success === true) {
                var suppDocName = [];
                for (let i = 0; i < otherList.length; i++) {
                  suppDocName.push(otherList[i].fileName);
                  successOtherCount.push(otherList[i].fileName);//only for count
                }
                for (let i = 0; i < suppDocName.length; i++) {
                  var count = 0;
                  for (let j = 0; j < newotherfilenames.length; j++) {
                    if (suppDocName[i] == newotherfilenames[j]) {
                      count = count + 1;
                    }
                  }
                }

                if (count > 1) {
                  newotherfilenames.pop();
                  otherValidationArray.pop();
                }
                var RejectedCount = 0;
                var imgsuccess;
                var HTML2 = "<table>";
                for (let j = 0; j < newotherfilenames.length; j++) {
                  let singleFile = otherJson.filter((a) => a.fileName == newotherfilenames[j])
                  let supportDocID = singleFile[0].supportDocID;
                  let isExist = otherValidationArray.includes(newotherfilenames[j]);
                  if (isExist === true) {/**** If uploaded file is wrong then it displays as in Red color */
                    RejectedCount = RejectedCount + 1;
                    imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'

                    link = '<a class="removeOtherFile" href="#" supportDocID=' + '"' + supportDocID + '"' + '  data-fileid=' + '"' + newotherfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                    HTML2 += "<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newotherfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                  } else {
                    imgsuccess = '<img src="success.png" alt="success" height="18" width="18" style="float:left"/>'

                    link = '<a class="removeOtherFile" href="#" supportDocID=' + '"' + supportDocID + '"' + '  data-fileid=' + '"' + newotherfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                    HTML2 += "<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newotherfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                  }
                }
                HTML2 += "</table>";
                document.getElementById("otherList").innerHTML = HTML2;
                var msg = "";
                // "Successfully Uploads Other Files (Uploaded Files :"+(successOtherCount.length)+", <font color='red'> Failed Files :"+RejectedCount+"</font>)";
                var message = document.getElementById('otherMessage');
                if ($("#otherMessage").text() === "") {
                  message.innerHTML += msg;
                }
                else {
                  message.innerHTML = msg;
                }
              } else {

                for (let i = 0; i < otherList.length; i++) {
                  otherValidationArray.push(otherList[i].fileName)
                }

                var HTML = "<table>";
                var imgsuccess;
                for (let j = 0; j < newotherfilenames.length; j++) {
                  let singleFile = otherJson.filter((a) => a.fileName == newotherfilenames[j])
                  let supportDocID = singleFile[0].supportDocID;
                  let isValid = otherValidationArray.includes(newotherfilenames[j]);
                  if (isValid === true) {
                    imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'
                    link = '<a class="removeOtherFile" href="#"supportDocID=' + '"' + supportDocID + '"' + '  data-fileid=' + '"' + newotherfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                    HTML += "<tr style='background-color:#fbd5db'><td style='color:red;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 220px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newotherfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                  } else {
                    let isTrue = successOtherCount.includes(newotherfilenames[j]);
                    if (isTrue === false) { successOtherCount.push(newotherfilenames[j]); }
                    imgsuccess = '<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>'

                    link = '<a class="removeOtherFile" href="#"supportDocID=' + '"' + supportDocID + '"' + '  data-fileid=' + '"' + newotherfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                    HTML += "<tr style='background-color:#daecda'><td style='color:green;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 220px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newotherfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                  }
                }
                HTML += "</table>";
                document.getElementById("otherList").innerHTML = HTML;
                var rejectedCount = newotherfilenames.length - successOtherCount.length;
                var msg1 = "";
                //  "Successfully Uploads Other Files (Uploaded Files :"+(successOtherCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";

                var message1 = document.getElementById('otherMessage');
                if (otherValidationArray.length > 0) {

                }
                else {
                  message1.innerHTML = msg1;
                }

                //  if($("#otherMessage").text()==="")
                //   {
                //     message1.innerHTML += msg1;
                //   }
                //   else
                //   {
                //     message1.innerHTML= msg1;
                //   }
              }
            }).catch(function (err) {
              Swal.fire({
                text: "Error Occured Try Again",
                type: "error"
              })
              console.log(err)
            });
        }
      })
      this.setState({
        files: data
      });
    }.bind(this))
  }
  /**
   * @author Somnath Keswad
   * @param  Description handle the Dynamic TextBox for assetUpload filename
   * @return Description return Number of text field
   */
  handleChangeAssetFileName(i, e) {


    const { name, value } = e.target;
    var step2 = window.location.href.split("&")[window.location.href.split("?").length - 1];

    if (step2 === 'undefined' || step2 === null || step2 === "" || step2 === undefined) {
      if (name === 'leadPercentage') {
        assetflag = true;
        var leadAllocation = this.state.totalLeadAllocation;
        var assetLead1 = value * leadAllocation / 100;
        assetLead1 = Math.round(assetLead1);
        var assetWiseLead = 0;
        var name1 = 'leadPerAsset';
        var assetLength = newassetfilenames.length;
        let assetFileNameArray = [...this.state.assetFileNameArray];
        assetFileNameArray[i] = { ...assetFileNameArray[i], [name1]: assetLead1 };
        this.state.assetFileNameArray = [...assetFileNameArray];
        let inputType = [...this.state.inputType];
        inputType[i] = { ...inputType[i], [name1]: assetLead1 };
        inputType[i] = { ...inputType[i], [name]: value };
        this.state.inputType = [...inputType];
      }

      let inputType = [...this.state.inputType];
      inputType[i] = { ...inputType[i], [name]: value };
      this.setState({ inputType });



      if (this.state.multiTouchChk === 'Yes') {
        assetflag = true
        var assetName_touch1 = '';
        var assetName_touch2 = '';
        var assetName_touch3 = '';
        var assetTimestamp_touch1 = '';
        var assetTimestamp_touch2 = '';
        var assetTimestamp_touch3 = '';
        //inputType= Asset File
        //dynamicInput-Asset Link

        for (var i = 0; i < inputType.length; i++) {

          if (inputType[i] == null) {

            assetName_touch1 = '1st Touch';
            assetTimestamp_touch1 = 'Yes';
            inputType[i] = { ...inputType[i], ['assetName_touch']: assetName_touch1, ['assetTimestamp_touch']: assetTimestamp_touch1 };

          }
          else {
            if (inputType[i].multiTouch == '1st Touch') {
              assetName_touch1 = '1st Touch';
              assetTimestamp_touch1 = 'Yes';

              inputType[i] = { ...inputType[i], ['assetName_touch']: assetName_touch1, ['assetTimestamp_touch']: assetTimestamp_touch1 };

            }
            else {
              if (inputType[i].multiTouch == '1st Touch') {
                assetName_touch1 = '1st Touch';
                assetTimestamp_touch1 = 'Yes';

                inputType[i] = { ...inputType[i], ['assetName_touch']: assetName_touch1, ['assetTimestamp_touch']: assetTimestamp_touch1 };

              }
              if (inputType[i].multiTouch == '2nd Touch') {

                assetName_touch2 = '2nd Touch';
                assetTimestamp_touch2 = 'Yes';

                inputType[i] = { ...inputType[i], ['assetName_touch']: assetName_touch2, ['assetTimestamp_touch']: assetTimestamp_touch2 };

              }
              if (inputType[i].multiTouch == '3rd Touch') {

                assetName_touch3 = '3rd Touch';
                assetTimestamp_touch3 = 'Yes';

                inputType[i] = { ...inputType[i], ['assetName_touch']: assetName_touch3, ['assetTimestamp_touch']: assetTimestamp_touch3 };

              }
            }

          }

        }


        this.setState({ inputType });
      }


    } else {
      // For Back Button

      if (name === 'leadPercentage') {
        assetflagBack = true;
        var leadAllocation = this.state.totalLeadAllocation;
        var assetLead1 = value * leadAllocation / 100;
        assetLead1 = Math.round(assetLead1);
        var assetWiseLead = 0;
        var name1 = 'leadPerAsset';
        let dynamicAssetName = [...this.state.dynamicAssetName];
        dynamicAssetName[i] = { ...dynamicAssetName[i], [name]: value };
        dynamicAssetName[i] = { ...dynamicAssetName[i], [name1]: assetLead1 };
        this.setState({ dynamicAssetName });
        // this.state.dynamicAssetName=[...dynamicAssetName];
        let inputType = [...this.state.inputType];
        inputType[i] = { ...inputType[i], [name1]: assetLead1 };
        inputType[i] = { ...inputType[i], [name]: value };
        this.state.inputType = [...inputType];
      } else {

        let dynamicAssetName = [...this.state.dynamicAssetName];
        dynamicAssetName[i] = { ...dynamicAssetName[i], [name]: value };
        var resultBack = [];
        resultBack = Array.from(new Set(dynamicAssetName.map(p => p.key))).map(key => {
          return {
            key: key,
            suppDocName: dynamicAssetName.find(p => p.key === key).suppDocName,
            leadPerAsset: dynamicAssetName.find(p => p.key === key).leadPerAsset,
            leadPercentage: dynamicAssetName.find(p => p.key === key).leadPercentage,
            multiTouch: dynamicAssetName.find(p => p.key === key).multiTouch,
            assetFileName:dynamicAssetName.find(p => p.key === key).assetFileName //3061-Raunak extracting the assetFilename key 


          };
        });

        var assetName_touch1 = '';
        var assetName_touch2 = '';
        var assetName_touch3 = '';
        var assetTimestamp_touch1 = '';
        var assetTimestamp_touch2 = '';
        var assetTimestamp_touch3 = '';
        //Asset File
        for (var i = 0; i < resultBack.length; i++) {
          if (resultBack[i].multiTouch == '1st Touch') {
            assetName_touch1 = '1st Touch';
            assetTimestamp_touch1 = 'Yes';
            resultBack[i] = { ...resultBack[i], ['assetName_touch']: assetName_touch1, ['assetTimestamp_touch']: assetTimestamp_touch1 };

          }
          if (resultBack[i].multiTouch == '2nd Touch') {

            assetName_touch2 = '2nd Touch';
            assetTimestamp_touch2 = 'Yes';
            resultBack[i] = { ...resultBack[i], ['assetName_touch']: assetName_touch2, ['assetTimestamp_touch']: assetTimestamp_touch2 };
          }
          if (resultBack[i].multiTouch == '3rd Touch') {

            assetName_touch3 = '3rd Touch';
            assetTimestamp_touch3 = 'Yes';
            resultBack[i] = { ...resultBack[i], ['assetName_touch']: assetName_touch3, ['assetTimestamp_touch']: assetTimestamp_touch3 };

          }

        }



        //Sanjana – Task -2979- resultBack set to inputType to update touch after handleChange
        this.setState({ dynamicAssetName: resultBack, inputType: resultBack });
      }


    }

  }
  // end of handleChangeAssetFileName


  /**
   * @author Somnath Keswad
   * @param  Description handle the Dynamic TextBox for AssetLink
   * @return Description return Number of text field
   */
  handleAssetLink(i, e) {
    const { name, value } = e.target;
    var step2 = window.location.href.split("&")[window.location.href.split("?").length - 1];
    if (step2 === 'undefined' || step2 === null || step2 === "" || step2 === undefined) {

      if (name === 'leadPercentage') {
        linkflag = true;
        var leadAllocation = this.state.totalLeadAllocation;
        var assetLead1 = value * leadAllocation / 100;
        assetLead1 = Math.round(assetLead1);
        var assetWiseLead = 0;
        var name1 = 'leadPerAsset';
        var assetLength = newassetfilenames.length;

        let assetLinkArray = [...this.state.assetLinkArray];
        assetLinkArray[i] = { ...assetLinkArray[i], [name1]: assetLead1 };
        assetLinkArray[i] = { ...assetLinkArray[i], [name]: value };
        this.setState({ assetLinkArray: assetLinkArray });// for Calculating Percentage wise lead
        assetLinkLength = assetLinkArray.length;

        let dynamicInput = [...this.state.dynamicInput];// for showing Column for leadPerAsset Name & Value
        dynamicInput[i] = { ...dynamicInput[i], [name1]: assetLead1 };
        dynamicInput[i] = { ...dynamicInput[i], [name]: value };//Sanjana-Task 2881- value on handle change added in dynamicInput array
        this.state.dynamicInput = [...dynamicInput];
      }
      else {

        let assetLinkArray = [...this.state.assetLinkArray]; // For Sending database
        assetLinkArray[i] = { ...assetLinkArray[i], [name]: value };
        this.state.assetLinkArray = [...assetLinkArray];
        assetLinkLength = assetLinkArray.length;

        if (this.state.multiTouchChk === "Yes") {
          assetflag = true;
          var assetName_touch1 = '';
          var assetName_touch2 = '';
          var assetName_touch3 = '';
          var assetTimestamp_touch1 = '';
          var assetTimestamp_touch2 = '';
          var assetTimestamp_touch3 = '';
          for (var j = 0; j < assetLinkArray.length; j++) {

            if (assetLinkArray[j].hasOwnProperty('multiTouch')) {
              if (assetLinkArray[j].multiTouch == '1st Touch') {
                assetName_touch1 = '1st Touch';
                assetTimestamp_touch1 = 'Yes';
                //if(assetFileNameArray[i].hasOwnProperty('assetFileName')){
                assetLinkArray[j] = { ...assetLinkArray[j], ['assetName_touch']: assetName_touch1, ['assetTimestamp_touch']: assetTimestamp_touch1 };
                // }
              }
            } else {
              assetLinkArray[j] = {
                ...assetLinkArray[j], multiTouch: '1st Touch',
                ['assetName_touch']: assetName_touch1, ['assetTimestamp_touch']: assetTimestamp_touch1
              };
            }
            if (assetLinkArray[j].multiTouch == '2nd Touch') {

              assetName_touch2 = '2nd Touch';
              assetTimestamp_touch2 = 'Yes';
              assetLinkArray[j] = { ...assetLinkArray[j], ['assetName_touch']: assetName_touch2, ['assetTimestamp_touch']: assetTimestamp_touch2 };
            }
            if (assetLinkArray[j].multiTouch == '3rd Touch') {

              assetName_touch3 = '3rd Touch';
              assetTimestamp_touch3 = 'Yes';
              assetLinkArray[j] = { ...assetLinkArray[j], ['assetName_touch']: assetName_touch3, ['assetTimestamp_touch']: assetTimestamp_touch3 };

            }
          }
          this.setState({ assetLinkArray: assetLinkArray });
        }





        let dynamicInput = [...this.state.dynamicInput];// for showing Column for leadPerAsset Name & Value
        dynamicInput[i] = { ...dynamicInput[i], [name]: value };
        this.state.dynamicInput = [...dynamicInput];

      }
    }
    else {
      // For back flow
      const { name, value } = e.target;
      if (name === 'leadPercentage') {
        linkflagBack = true;
        var leadAllocation = this.state.totalLeadAllocation;
        var assetLead1 = value * leadAllocation / 100;
        assetLead1 = Math.round(assetLead1);
        var assetWiseLead = 0;
        var name1 = 'leadPerAsset';
        var assetLength = newassetfilenames.length;
        let backDynamicInput = [...this.state.backDynamicInput];
        backDynamicInput[i] = { ...backDynamicInput[i], [name1]: assetLead1 };// for lead per asset
        backDynamicInput[i] = { ...backDynamicInput[i], [name]: value }; // for leadPercentage
        this.setState({ backDynamicInput });
        // this.state.backDynamicInput=[...backDynamicInput];
        assetLinkLength = backDynamicInput.length;

      } else {
        let backDynamicInput = [...this.state.backDynamicInput];
        backDynamicInput[i] = { ...backDynamicInput[i], [name]: value };


        var assetName_touch1 = '';
        var assetName_touch2 = '';
        var assetName_touch3 = '';
        var assetTimestamp_touch1 = '';
        var assetTimestamp_touch2 = '';
        var assetTimestamp_touch3 = '';
        for (var i = 0; i < backDynamicInput.length; i++) {
          if (backDynamicInput[i].multiTouch == '1st Touch') {
            assetName_touch1 = '1st Touch';
            assetTimestamp_touch1 = 'Yes';
            backDynamicInput[i] = { ...backDynamicInput[i], ['assetName_touch']: assetName_touch1, ['assetTimestamp_touch']: assetTimestamp_touch1 };

          }
          if (backDynamicInput[i].multiTouch == '2nd Touch') {

            assetName_touch2 = '2nd Touch';
            assetTimestamp_touch2 = 'Yes';
            backDynamicInput[i] = { ...backDynamicInput[i], ['assetName_touch']: assetName_touch2, ['assetTimestamp_touch']: assetTimestamp_touch2 };
          }
          if (backDynamicInput[i].multiTouch == '3rd Touch') {

            assetName_touch3 = '3rd Touch';
            assetTimestamp_touch3 = 'Yes';
            backDynamicInput[i] = { ...backDynamicInput[i], ['assetName_touch']: assetName_touch3, ['assetTimestamp_touch']: assetTimestamp_touch3 };

          }
        }


        this.setState({ backDynamicInput });

        assetLinkLength = backDynamicInput.length;
      }

    }

  }



  /**
   * @author Narendra Phadke
   * @param  Description handle the multiple ABM files uploads
   * @return Description return successfully files upload message
   */
  handleChangeMultipleFileOfABM(e) {


    let self = this;
    abmnames = [];
    let abmerror = {};

    var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
    e = document.createElement("input"); //creating a input element but not binding it to body added by Raunak
    e.setAttribute("type", "file");//setting a attribute for type file added by Raunak
    e.setAttribute("multiple", true);//setting a attribute for multiple added by Raunak
    e.click();//to open file dialougeadded by Raunak
    e.addEventListener("change", function () { //calling an eventListener at change event added by Raunak

      self.setState({ displayTableabm: 'block' });
      let abmfilesData = e.files;
      for (let i = 0, l = abmfilesData.length; i < l; i++) {

        let fileName = abmfilesData[i].name;
        if (chkABMExist.includes(fileName)) {
          duplicateABMFile.push(fileName);
        } else {
          abmnames.push(abmfilesData[i].name);
        }
      }

      if (chkABMExist != abmnames) {

        rfpChange++;
      }

      /**
       * @author Sanjana Godbole
       * @param  A FileList object that lists every selected file
       * @return List of uploded file along with remove link
       */

      var link;
      var filename = '';
      var abmarrayLength = abmnames.length;

      var nooutput = document.getElementById('noabmList');
      var valuenooutput = document.getElementById('noabmList').innerHTML;



      var res = new Array();
      res = valuenooutput.split(" ");

      var backarraylength;
      for (let i = 0; i < res.length; i++) {

        backarraylength = (res[0]);
      }

      abmarrayLength = parseInt(abmarrayLength);
      backarraylength = parseInt(backarraylength);

      var arrayabmLength1;
      arrayabmLength1 = abmarrayLength + backarraylength;

      if (arrayabmLength1 > 0) {

        var allowedFiles = [".xls", ".xlsx", ".csv"];
        var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");
        for (let i = 0; i < abmnames.length; i++) //iterating through abmnames array added by Raunak
        {
          if (!regex.test(abmnames[i].toLowerCase()))//check each value and return if even one is wrong added by Raunak
          {

            abmerror["abmerr1"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";


            this.setState({
              abmerror: abmerror
            });
            return
          }
        }
        for (var j = 0; j < abmarrayLength; j++) {
          newabmfilenames.push(abmnames[j]);


          chkABMExist.push(abmnames[j]);

        }
        var HTML = "<table>";
        var FileExt;
        var imgsuccess;
        var countABM = 0;
        for (var j = 0; j < newabmfilenames.length; j++) {

          if (document.getElementById("abmError") !== null) {
            document.getElementById("abmError").innerHTML = "";
          }
          FileExt = newabmfilenames[j].split('.').pop();

          var allowedFiles = [".xls", ".xlsx", ".csv"];
          if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {

          }
          else {


            countABM = countABM + 1

          }
          var isExist = oldABMFileArray.includes(newabmfilenames[j]);

          if (isExist === true) {
            imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'

            link = '<a class="removeFile" href="#" data-fileid=' + '"' + newabmfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
            HTML += "<tr  style='height:'20px'><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newabmfilenames[j] + "</span>" + link + "<br><br></td></tr>";

          } else {
            imgsuccess = '<img src="success.png" alt="success" height="18" width="18" style="float:left"/>'

            link = '<a class="removeFile" href="#" data-fileid=' + '"' + newabmfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
            HTML += "<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newabmfilenames[j] + "</span>" + link + "<br><br></td></tr>";

          }

        }
        HTML += "</table>";


        document.getElementById("abmList").innerHTML = HTML;

        if (countABM > 0) {
          var msg2 = "Please upload files having extensions: " + allowedFiles.join(', ') + " only"
          var abmErrorFile = document.getElementById("abmError");

          abmErrorFile.innerHTML += msg2;
        }

        $(document).on("click", ".removeFile", function (e) {

          var clickedFile = $(this).parent().children("a").data("fileid");
          console.log("Clicked File===>" + clickedFile);
          e.preventDefault();
          var supportDocID = $(this).attr('supportDocID');
          console.log(supportDocID);
          var j = 0
          for (j; j < newabmfilenames.length; j++) {
            var temp = j;

            if (newabmfilenames[temp] === clickedFile) {

              console.log(oldABMFileArray);

              var isExist = oldABMFileArray.includes(newabmfilenames[j]);


              if (isExist === true) {

                var index = oldABMFileArray.indexOf(newabmfilenames[j]);

                oldABMFileArray.splice(index, 1);

                console.log(oldABMFileArray);

              }

              filename = newabmfilenames[temp];

              newabmfilenames.splice(j, 1);



              chkABMExist.splice(j, 1);



              abmCountArray.splice(j, 1);
              $(this).parent().remove();
              abmarrayLength = newabmfilenames.length;

              if (newabmfilenames.length == 0) {
                oldABMFileArray = [];
                rejectAbmCount = [];
              }
              for (var k = 0; k < rejectAbmCount.length; k++) {
                if (rejectAbmCount[k] == clickedFile) {
                  rejectAbmCount.splice(k, 1);
                }
              }
              for (var k = 0; k < successAbmCount.length; k++) {
                if (successAbmCount[k] == clickedFile) {
                  successAbmCount.splice(k, 1);
                }
              }
              var rejectedCount = newabmfilenames.length - successAbmCount.length;
              if (rejectedCount < 0) { rejectedCount = 0 }
              var msg1 = "";
              // "Successfully Uploads ABM Files (Uploaded Files :"+successAbmCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
              var message1 = document.getElementById('abmMessage');

              if (oldABMFileArray.length > 0) {

              }
              else {
                message1.innerHTML = msg1;
              }



              //  if($("#abmMessage").text()==="")
              //   {message1.innerHTML += msg1;
              //   }else{message1.innerHTML= msg1;}

              /**
               * @author Narendra Phadke
               * @param  Delete File From Database
               */
              if (newabmfilenames.length == 0) {
                self.setState({ displayTableabm: 'none' });
              }
              var typeOfSuppDoc = "ABM";
              let data = {

                campID: campID,
                names: filename,
                typeOfSuppDoc: typeOfSuppDoc,
                supportDocID, step: 'Create'

              }


              fetch("campaign/supportingDocumentDeleteFile", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                //mode:'no-cors',
                //body: data
                body: JSON.stringify(data)
              });

              abmarrayLength = parseInt(abmarrayLength);

              backarraylength = parseInt(backarraylength);
              arrayabmLength1 = abmarrayLength + backarraylength;
              if ((arrayabmLength1) > 0) {

                nooutput.innerHTML = arrayabmLength1 + "ABM  Files Selected";
              }
              else {

                nooutput.innerHTML = "0 ABM File Selected";
                // $("#abm1").val('');
                var message = document.getElementById('abmMessage');
                message.innerHTML = "";
              }
            }// end of  if(names[temp] === clickedFile)

          }// end of for
          let abmJson1 = [...self.state.abmJson];

          let abmJson = abmJson1.filter((a) => a.fileName != clickedFile)



          self.setState({ abmJson });
        });//$(document).on


        var data = new FormData();
        data.append("campID", campID);
        //data.append("names",names);
        console.warn("Files Data==>", JSON.stringify(abmfilesData.length));
        for (var i = 0, l = abmfilesData.length; i < l; i++) {
          let fileName = abmfilesData[i].name;
          if (duplicateABMFile.includes(fileName)) { }
          else {
            data.append("file", abmfilesData[i]);
          }
        }
        console.warn("newData", JSON.stringify(abmfilesData));
        //campaign/supportingDocument
        Swal.fire({
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading()
            fetch("campaign/supportingDocumentABM", {
              method: 'POST',
              body: data
            }).then(function (response) {
              if (response.status >= 400) {
                throw new Error("Bad response from server");
              }
              return response.json();
            }).then(function (response) {

              console.log(response);
              if (response[0].success === true) {//added by raunak message will be shown based on type of response
                Swal.fire({ text: "File Upload Complete", type: "success" })
              } else {
                Swal.fire({ text: "Invalid format of the file", type: "error" })
              }
              let abmJson1 = [...self.state.abmJson]

              let abmJson = [...abmJson1, ...response];

              self.setState({ abmJson });
              if (response[0].success == true) {

                var suppDocName = [];
                var abmFileCount = [];
                // var successAbmCount=[];
                //  var suppDocName=response.seccessFileList;
                for (let i = 0; i < response.length; i++) {
                  let { fileName, success } = response[i];
                  if (success == true) {
                    suppDocName.push(fileName);
                    successAbmCount.push(fileName);
                  }

                  abmCountArray.push(response[i]);

                  abmFileCount.push(response[i].abmCount);


                }
                for (let i = 0; i < suppDocName.length; i++) {

                  var count = 0;
                  for (let j = 0; j < newabmfilenames.length; j++) {
                    if (suppDocName[i] == newabmfilenames[j]) {

                      count = count + 1;


                    }
                  }
                }
                if (count > 1) {

                  newabmfilenames.pop();
                  oldABMFileArray.pop();
                }
                var RejectedCount = 0;
                var HTML2 = "<table>";
                var imgsuccess;
                for (let j = 0; j < newabmfilenames.length; j++) {
                  let singleAbm = abmJson.filter((a) => a.fileName == newabmfilenames[j])

                  let supportDocID = singleAbm[0].supportDocID;


                  var isExist = oldABMFileArray.includes(newabmfilenames[j]);
                  var abmCount = abmCountArray.filter((a) => a.fileName == newabmfilenames[j]);

                  var cnt = abmCount[0].abmCount;


                  if (isExist === true) {/**** If uploaded file is wrong then it displays as in Red color */

                    RejectedCount = RejectedCount + 1;
                    imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'

                    link = '<a class="removeFile"  href="#" supportDocID=' + '"' + supportDocID + '"' + '  data-fileid=' + '"' + newabmfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                    HTML2 += "<tr style='height:'20px'><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newabmfilenames[j] + "</span><span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>ABM Count :" + cnt + " </span>" + link + "<br><br></td></tr>";

                  } else {

                    imgsuccess = '<img src="success.png" alt="failed" height="18" width="18" style="float:left"/>'

                    link = '<a class="removeFile"  href="#" supportDocID=' + '"' + supportDocID + '"' + '  data-fileid=' + '"' + newabmfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                    HTML2 += "<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newabmfilenames[j] + "</span><span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>ABM Count :" + cnt + " </span>" + link + "<br><br></td></tr>";

                  }
                }
                HTML2 += "</table>";
                document.getElementById("abmList").innerHTML = HTML2;
                var msg = "";
                //  "Successfully Uploads ABM Files ( Uploaded File :"+successAbmCount.length+", <font color='red'> Failed Files :"+RejectedCount+"</font>)";

                var message = document.getElementById('abmMessage');

                // document.getElementById("abmMessage").style.color="green";
                var number = document.getElementById("abmMessage").innerHTML;

                message.innerHTML = "";
                message.innerHTML += msg;
                nooutput.innerHTML = arrayabmLength1;
              }
              else {



                var isError = response[0].Error;
                for (let i = 0; i < response.length; i++) {
                  let { success, fileName } = response[i];
                  if (success == false) {
                    oldABMFileArray.push(fileName);
                  }
                  abmCountArray.push(response[i]);
                }
                var HTML1 = "<table>";
                var imgsuccess;
                for (let j = 0; j < newabmfilenames.length; j++) {

                  let singleAbm = abmJson.filter((a) => a.fileName == newabmfilenames[j])

                  let supportDocID = singleAbm[0].supportDocID;



                  let isExist = oldABMFileArray.includes(newabmfilenames[j]);
                  var abmCount = abmCountArray.filter((a) => a.fileName == newabmfilenames[j]);

                  var cnt = abmCount[0].abmCount;

                  if (isExist === true) {/**** If uploaded file is wrong then it displays as in Red color */


                    imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'

                    link = '<a class="removeFile"  href="#" supportDocID=' + '"' + supportDocID + '"' + '  data-fileid=' + '"' + newabmfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                    HTML1 += "<tr style='height:'20px'><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newabmfilenames[j] + "</span><span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>ABM Count :" + cnt + "</span>" + link + "<br><br></td></tr>";
                  }
                  else {
                    let isTrue = successAbmCount.includes(newabmfilenames[j]);
                    if (isTrue === false) {

                      successAbmCount.push(newabmfilenames[j]);
                    }
                    imgsuccess = '<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>'

                    link = '<a class="removeFile"  href="#"  supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newabmfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                    HTML1 += "<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width:260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newabmfilenames[j] + "</span><span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>ABM Count :" + cnt + " </span>" + link + "<br><br></td></tr>";
                  }
                }
                HTML1 += "</table>";
                document.getElementById("abmList").innerHTML = HTML1;
                if (isError === 'Yes') {

                  var rejectedCount = newabmfilenames.length - successAbmCount.length;
                  var msg1 = "";


                  // "<font color='green'>Successfully Uploads Asset Files </font>(Uploaded Files :"+(successAbmCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
                } else {


                  var msg1 = "<font color='red'>Please Upload Valid Header File </font>";



                }
                var message1 = document.getElementById('abmMessage').innerHTML;


                if (message1 == "") {

                  document.getElementById("abmMessage").innerHTML = msg1;

                }
                else {

                  document.getElementById("abmMessage").innerHTML = msg1;

                }
              }
            }).catch(function (err) {
              Swal.fire({ text: "Error Occured Try Again", type: "error" })
              console.log(err)
            });
          }
        })
        this.setState({ files: data, abmerror: '' });
        nooutput.innerHTML = arrayabmLength1;
      }

      else {
        nooutput.innerHTML = "Please select file in excel format";
      }
    }.bind(this))//binding this function to use setstate added by raunak
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the multiple exclusion files uploads
   * @return Description return successfully files upload message
   */
  handleChangeMultipleFileOfExclusion(e) {

    execlusionnames = [];
    let exclusionerror = {};
    let self = this;

    e = document.createElement("input");//creating a input element but not binding it to body added by Raunak
    e.setAttribute("type", "file");//setting a attribute for type file added by Raunak
    e.setAttribute("multiple", true);//setting a attribute for multiple added by Raunak
    e.click();//to open file dialougeadded by Raunak
    e.addEventListener("change", function () {//calling an eventListener at change event added by Raunak




      var parsed = queryString.parse(this.props.location.search);
      var campID = parsed.campID;
      self.setState({ displayTableexclusion: 'block' });
      let execlusionfilesData = e.files;
      for (let i = 0, l = execlusionfilesData.length; i < l; i++) {
        let fileName = execlusionfilesData[i].name;

        if (chkExclusionExist.includes(fileName)) {
          duplicateExclusionFile.push(fileName);
        } else {
          execlusionnames.push(fileName);
        }
      }


      if (chkExclusionExist != execlusionnames) {

        rfpChange++;
      }


      /**
    * @author Sanjana Godbole
    * @param  A FileList object that lists every selected file
    * @return List of uploded file along with remove link
    */
      var link;
      var filename = '';
      var execlusionarrayLength = execlusionnames.length;
      var nooutputexeclusion = document.getElementById('noexclusionList');
      var valuenooutputexeclusion = document.getElementById('noexclusionList').innerHTML;



      var resexeclusion = new Array();
      resexeclusion = valuenooutputexeclusion.split(" ");


      var backecelusionarraylength;
      for (let i = 0; i < resexeclusion.length; i++) {
        backecelusionarraylength = (resexeclusion[0]);
      }


      execlusionarrayLength = parseInt(execlusionarrayLength);
      backecelusionarraylength = parseInt(backecelusionarraylength);

      var arrayexeclusionLength1 = execlusionarrayLength + backecelusionarraylength;
      if (arrayexeclusionLength1 > 0) {
        var allowedFiles = [".xls", ".xlsx", ".csv"];
        var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");
        for (let i = 0; i < execlusionnames.length; i++) {
          if (!regex.test(execlusionnames[i].toLowerCase())) {
            exclusionerror["exclusionerr"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
            this.setState({
              exclusionerror: exclusionerror
            });
            return
          }
        }

        for (var j = 0; j < execlusionarrayLength; j++) {
          newexclusionfilenames.push(execlusionnames[j]);
          chkExclusionExist.push(execlusionnames[j]);
        }


        var HTML = "<table>";
        var FileExt;
        var countExclusion = 0;
        var imgsuccess;
        for (var j = 0; j < newexclusionfilenames.length; j++) {

          if (document.getElementById("exclusionError") !== null) {
            document.getElementById("exclusionError").innerHTML = "";
          }

          FileExt = newexclusionfilenames[j].split('.').pop();

          var allowedFiles = [".xls", ".xlsx", ".csv"];
          if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {

          }
          else {


            countExclusion = countExclusion + 1

          }

          var isExist = exclusionValidationArray.includes(newexclusionfilenames[j]);
          if (isExist === true) {
            imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'
            link = '<a class="removeExclusionFile" href="#" data-fileid=' + '"' + newexclusionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
            // HTML += "<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>"+imgsuccess+"&nbsp;"+"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>"+newexclusionfilenames[j]+"</span>"+link+"<br><br></td></tr>";
            HTML += "<tr  style='height:'20px'><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newexclusionfilenames[j] + "</span>" + link + "<br><br></td></tr>";

          } else {
            imgsuccess = '<img src="success.png" alt="success" height="18" width="18" style="float:left"/>'
            link = '<a class="removeExclusionFile" href="#" data-fileid=' + '"' + newexclusionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
            // HTML += "<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>"+imgsuccess+"&nbsp;"+"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>"+newexclusionfilenames[j]+"</span>"+link+"<br><br></td></tr>";
            HTML += "<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newexclusionfilenames[j] + "</span>" + link + "<br><br></td></tr>";

          }
        }
        HTML += "</table>";


        document.getElementById("exclusionList").innerHTML = HTML;
        if (countExclusion > 0) {
          var msg2 = "Please upload files having extensions: " + allowedFiles.join(', ') + " only"
          var exclusionErrorFile = document.getElementById("exclusionError");

          exclusionErrorFile.innerHTML += msg2;
        }

        $(document).on("click", ".removeExclusionFile", function (e) {

          e.preventDefault();
          var clickedFile = $(this).closest("a").data("fileid");
          var supportDocID = $(this).attr('supportDocID');
          var j = 0
          for (j = 0; j < newexclusionfilenames.length; j++) {
            var temp = j;

            if (newexclusionfilenames[temp] === clickedFile) {
              var isExist = exclusionValidationArray.includes(newexclusionfilenames[j]);

              if (isExist === true) {

                var index = exclusionValidationArray.indexOf(newexclusionfilenames[j]);
                exclusionValidationArray.splice(index, 1);
              }

              filename = newexclusionfilenames[temp];

              newexclusionfilenames.splice(j, 1);
              chkExclusionExist.splice(j, 1);
              $(this).parent().remove();
              execlusionarrayLength = newexclusionfilenames.length;
              if (newexclusionfilenames.length == 0) {
                exclusionValidationArray = [];
                rejectExclusionCount = [];
                // successExclusionCount=[];
              }
              for (var k = 0; k < rejectExclusionCount.length; k++) {

                if (rejectExclusionCount[k] == clickedFile) {
                  rejectExclusionCount.splice(k, 1);

                }
              }
              for (var k = 0; k < successExclusionCount.length; k++) {
                if (successExclusionCount[k] == clickedFile) {
                  successExclusionCount.splice(k, 1);
                }
              } var rejectedCount = newexclusionfilenames.length - successExclusionCount.length;
              if (rejectedCount < 0) { rejectedCount = 0; }

              var msg1 = "";
              // "Successfully Uploads Exclusion Files (Uploaded Files :"+successExclusionCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
              var message1 = document.getElementById('exclusionMessage');


              if (exclusionValidationArray.length > 0) {

              }
              else {
                message1.innerHTML = msg1;
              }

              /**
                  * @author Narendra Phadke
                  * @param  Delete File From Database
                  */
              if (newexclusionfilenames.length == 0) {
                self.setState({ displayTableexclusion: 'none' });
              }
              var typeOfSuppDoc = "Exclusion";
              let data = {
                campID: campID,
                names: filename,
                typeOfSuppDoc: typeOfSuppDoc,
                supportDocID, step: 'Create'
              }

              fetch("campaign/supportingDocumentDeleteFile", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                //mode:'no-cors',
                //body: data
                body: JSON.stringify(data)
              });
              execlusionarrayLength = parseInt(execlusionarrayLength);
              backecelusionarraylength = parseInt(backecelusionarraylength);


              arrayexeclusionLength1 = execlusionarrayLength + backecelusionarraylength;


              if (arrayexeclusionLength1 > 0) {

                nooutputexeclusion.innerHTML = arrayexeclusionLength1;
              }
              else {

                nooutputexeclusion.innerHTML = "0 Exclusion File Selected";

                var message = document.getElementById('exclusionMessage');
                message.innerHTML = "";
              }
            }// end of  if(names[temp] === clickedFile)

          }// end of for
          let exclusionJson1 = [...self.state.exclusionJson];
          let exclusionJson = exclusionJson1.filter((a) => a.supportDocID != supportDocID);
          self.setState({ exclusionJson });
        });//$(document).on

        var data = new FormData();
        data.append("campID", campID);
        //data.append("names",names);

        for (var i = 0, l = execlusionfilesData.length; i < l; i++) {
          let fileName = execlusionfilesData[i].name;
          if (duplicateExclusionFile.includes(fileName)) { }
          else {
            data.append("file", execlusionfilesData[i]);
          }
        }

        //campaign/supportingDocument
        Swal.fire({
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading()
            fetch("campaign/supportingDocumentExclusion", {
              method: 'POST',
              body: data
            }).then(res => res.json())
              .then(exclusionList => {
                if (exclusionList[0].success === true) {//added by raunak message will be shown based on type of response
                  Swal.fire({ text: "File Upload Complete", type: "success" })
                } else {
                  Swal.fire({ text: "Invalid format of the file", type: "error" })
                }
                let exclusionJson1 = [...self.state.exclusionJson]
                let exclusionJson = [...exclusionJson1, ...exclusionList];
                self.setState({ exclusionJson });
                if (exclusionList[0].success == true) {

                  var suppDocName = [];
                  for (let i = 0; i < exclusionList.length; i++) {
                    let { success, fileName } = exclusionList[i];
                    if (success === true) {
                      suppDocName.push(fileName)
                      successExclusionCount.push(fileName)
                    }
                  }
                  for (let i = 0; i < suppDocName.length; i++) {
                    var count = 0;
                    for (let j = 0; j < newexclusionfilenames.length; j++) {
                      if (suppDocName[i] == newexclusionfilenames[j]) {
                        count = count + 1;
                      }
                    }
                  }

                  if (count > 1) {
                    newexclusionfilenames.pop();
                    exclusionValidationArray.pop();
                  }
                  var RejectCount = 0;
                  var HTML2 = "<table>";
                  var imgsuccess;
                  for (let j = 0; j < newexclusionfilenames.length; j++) {
                    let singleFile = exclusionJson.filter((a) => a.fileName == newexclusionfilenames[j])
                    let supportDocID = singleFile[0].supportDocID;
                    var isExist = exclusionValidationArray.includes(newexclusionfilenames[j]);
                    if (isExist === true) {/**** If uploaded file is wrong then it displays as in Red color */
                      RejectCount = RejectCount + 1;
                      imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'
                      link = '<a class="removeExclusionFile" href="#" supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newexclusionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                      HTML2 += "<tr  style='height:'20px'><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newexclusionfilenames[j] + "</span>" + link + "<br><br></td></tr>";


                    } else {

                      imgsuccess = '<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>'

                      link = '<a class="removeExclusionFile" href="#" supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newexclusionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';

                      HTML2 += "<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newexclusionfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                    }
                  }
                  HTML2 += "</table>";
                  document.getElementById("exclusionList").innerHTML = HTML2;
                  var msg = "";
                  //  "Successfully Uploads Exclusion Files (Uploaded Files :"+(successExclusionCount.length)+", <font color='red'> Failed Files :"+RejectCount+"</font>)";

                  var message = document.getElementById('exclusionMessage');
                  //  document.getElementById("exclusionMessage").style.color="green";
                  var number = document.getElementById("exclusionMessage").innerHTML;

                  message.innerHTML = "";
                  message.innerHTML += msg;
                  nooutputexeclusion.innerHTML = arrayexeclusionLength1;
                } else {
                  var isError = exclusionList[0].Error;
                  for (let i = 0; i < exclusionList.length; i++) {
                    let { success, fileName } = exclusionList[i];
                    if (success === false) {
                      exclusionValidationArray.push(fileName)
                    }
                  }
                  var HTML = "<table>";
                  var imgsuccess;
                  for (let j = 0; j < newexclusionfilenames.length; j++) {
                    let singleFile = exclusionJson.filter((a) => a.fileName == newexclusionfilenames[j])
                    let supportDocID = singleFile[0].supportDocID;
                    var isValid = exclusionValidationArray.includes(newexclusionfilenames[j]);

                    if (isValid === true) {

                      imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'

                      link = '<a class="removeExclusionFile" href="#" supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newexclusionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                      HTML += "<tr  style='height:'20px'><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style=' font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newexclusionfilenames[j] + "</span>" + link + "<br><br></td></tr>";
                    } else {
                      let isTrue = successExclusionCount.includes(newexclusionfilenames[j]);
                      if (isTrue === false) { successExclusionCount.push(newexclusionfilenames[j]); }
                      imgsuccess = '<img src="success.png" alt="success" height="18" width="18" style="float:left"/>'
                      link = '<a class="removeExclusionFile" href="#"supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newexclusionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                      HTML += "<tr style='height:'20px'><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newexclusionfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                    }
                  }
                  HTML += "</table>";
                  var countReject = newexclusionfilenames.length - successExclusionCount.length;

                  document.getElementById("exclusionList").innerHTML = HTML;
                  if (isError == 'Yes') {
                    var msg1 = "";
                  } else {
                    var msg1 = "<font color='Red'>Please Upload Valid Header File </font>";
                  }
                  var message1 = document.getElementById('exclusionMessage');
                  if (message1 == "") {
                    document.getElementById("exclusionMessage").innerHTML = msg1;
                  }
                  else {
                    document.getElementById("exclusionMessage").innerHTML = msg1;
                  }
                }
              }).catch(function (err) {
                console.log(err)
                Swal.fire({
                  text: "Error Occured Try Again",
                  type: "error"
                })
              });
          }
        })
        this.setState({
          files: data,
          exclusionerror: ''
        });
        nooutputexeclusion.innerHTML = arrayexeclusionLength1;
      }


      else {
        nooutputexeclusion.innerHTML = " No Exclusion File Selected";
      }
    }.bind(this))




  }


  /**
   * @author Narendra Phadke
   * @param  Description handle the multiple supperssion files uploads
   * @return Description return successfully files upload message
   */
  handleChangeMultipleFileOfSuppression(e) {
    let suppressionerror = {};
    let self = this;
    var parsed = queryString.parse(this.props.location.search);
    var campID = parsed.campID;
    e = document.createElement("input");//creating a input element but not binding it to body added by Raunak
    e.setAttribute("type", "file");//setting a attribute for type file added by Raunak
    e.setAttribute("multiple", true);//setting a attribute for multiple added by Raunak
    e.click();//to open file dialougeadded by Raunak
    e.addEventListener("change", function () {//calling an eventListener at change event added by Raunak
      suppressionnames = [];



      self.setState({ displayTablesuppression: 'block' });



      let suppressionfilesData = e.files;
      for (let i = 0, l = suppressionfilesData.length; i < l; i++) {
        let fileName = suppressionfilesData[i].name;
        if (chkSuppressionExist.includes(fileName)) {
          duplicateSuppressionFile.push(fileName);
        } else {
          suppressionnames.push(fileName);
        }
      }


      if (chkSuppressionExist != suppressionnames) {

        rfpChange++;
      }

      /**
* @author Sanjana Godbole
* @param  A FileList object that lists every selected file
* @return List of uploded file along with remove link
*/

      var link;
      var filename = '';
      var suppressionarrayLength = suppressionnames.length;

      var nosuppressionoutput = document.getElementById('nosuppressionList');
      var valuenosuppressionoutput = document.getElementById('nosuppressionList').innerHTML;


      var suppressionres = new Array();
      suppressionres = valuenosuppressionoutput.split(" ");


      var backsuppressionarraylength;
      for (let i = 0; i < suppressionres.length; i++) {
        if (suppressionres[0] === " " || suppressionres[0] == "") {
          backsuppressionarraylength = (suppressionres[1]);

        }
        else {
          backsuppressionarraylength = (suppressionres[0]);

        }
      }


      suppressionarrayLength = parseInt(suppressionarrayLength);
      backsuppressionarraylength = parseInt(backsuppressionarraylength);

      var suppressionarrayLength1;

      backsuppressionarraylength = backsuppressionarraylength || 0;
      suppressionarrayLength1 = suppressionarrayLength + backsuppressionarraylength

      if (suppressionarrayLength1 >= 1) {

        var allowedFiles = [".xls", ".xlsx", ".csv"];
        var regex = new RegExp("([a-zA-Z0-9\s_\\.()\-:])+(" + allowedFiles.join('|') + ")$");
        for (let i = 0; i < suppressionnames.length; i++) {
          if (!regex.test(suppressionnames[i].toLowerCase())) {

            suppressionerror["suppressionerr"] = "Please upload files having extensions: " + allowedFiles.join(', ') + " only";
            this.setState({
              suppressionerror: suppressionerror
            });
            return
          }
        }

        for (var j = 0; j < suppressionarrayLength; j++) {
          newsuppressionfilenames.push(suppressionnames[j]);
          chkSuppressionExist.push(suppressionnames[j]);
        }



        var FileExt;
        var HTML = "<table>";
        var imgsuccess;
        var countSuppressison = 0;

        for (var j = 0; j < newsuppressionfilenames.length; j++) {
          if (document.getElementById("suppressionError") !== null) {
            document.getElementById("suppressionError").innerHTML = "";
          }
          FileExt = newsuppressionfilenames[j].split('.').pop();

          var allowedFiles = [".xls", ".xlsx", ".csv"];
          if (FileExt == "csv" || FileExt == "xlsx" || FileExt == "xls") {

          }
          else {



            countSuppressison = countSuppressison + 1

          }
          var isExist = suppValidationArray.includes(newsuppressionfilenames[j]);

          if (isExist === true) {/**** If uploaded file is wrong then it displays as in Red color */
            imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'

            link = '<a class="removeSuppressionFile" href="#" data-fileid=' + '"' + newsuppressionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
            HTML += "<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newsuppressionfilenames[j] + "</span>" + link + "<br><br></td></tr>";

          }
          else {
            imgsuccess = '<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>'
            link = '<a class="removeSuppressionFile" href="#" data-fileid=' + '"' + newsuppressionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
            HTML += "<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newsuppressionfilenames[j] + "</span>" + link + "<br><br></td></tr>";

          }


        }
        HTML += "</table>";




        document.getElementById("suppressionList").innerHTML = HTML;
        if (countSuppressison > 0) {
          var msg3 = "Please upload files having extensions: " + allowedFiles.join(', ') + " only"
          var suppressionErrorFile = document.getElementById("suppressionError");

          suppressionErrorFile.innerHTML += msg3;
        }

        $(document).on("click", ".removeSuppressionFile", function (e) {
          e.preventDefault();
          var supportDocID = $(this).attr('supportDocID');
          var clickedFile = $(this).parent().children("a").data("fileid");

          let j = 0
          for (j; j < newsuppressionfilenames.length; j++) {

            var temp = j;

            if (newsuppressionfilenames[temp] === clickedFile) {
              var isExist = suppValidationArray.includes(newsuppressionfilenames[j]);
              if (isExist === true) {
                var index = suppValidationArray.indexOf(newsuppressionfilenames[j]);
                suppValidationArray.splice(index, 1);
              }

              filename = newsuppressionfilenames[temp];

              newsuppressionfilenames.splice(j, 1);
              chkSuppressionExist.splice(j, 1);
              $(this).parent().remove();
              suppressionarrayLength = newsuppressionfilenames.length;

              if (newsuppressionfilenames.length === 0) {
                suppValidationArray = [];
                rejectSuppressionCount = [];
                // successSuppressionCount=[];
              }
              for (var k = 0; k < rejectSuppressionCount.length; k++) {
                if (rejectSuppressionCount[k] == clickedFile) {
                  rejectSuppressionCount.splice(k, 1);
                }
              }
              for (var k = 0; k < successSuppressionCount.length; k++) {
                if (successSuppressionCount[k] === clickedFile) {
                  successSuppressionCount.splice(k, 1);
                }
              }
              var rejectedCount = newsuppressionfilenames.length - successSuppressionCount.length;
              if (rejectedCount < 0) { rejectedCount = 0; }
              var msg1 = "";
              // "Successfully Uploads Suppression Files (Uploaded Files :"+successSuppressionCount.length+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
              var message1 = document.getElementById('suppresionMessage');

              if (suppValidationArray.length > 0) {

              }
              else {
                message1.innerHTML = msg1;
              }



              /**
               * @author Narendra Phadke
               * @param  Delete File From Database
               */
              if (newsuppressionfilenames.length == 0) {
                self.setState({ displayTablesuppression: 'none' });
              }

              var typeOfSuppDoc = "Suppression";
              let data = {
                campID: campID,
                names: filename,
                typeOfSuppDoc: typeOfSuppDoc,
                supportDocID, step: 'Create'
              }

              fetch("campaign/supportingDocumentDeleteFile", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                //mode:'no-cors',
                //body: data
                body: JSON.stringify(data)
              });
              suppressionarrayLength = parseInt(suppressionarrayLength);
              backsuppressionarraylength = parseInt(backsuppressionarraylength);
              suppressionarrayLength1 = suppressionarrayLength + backsuppressionarraylength



              if ((suppressionarrayLength1) > 0) {

                nosuppressionoutput.innerHTML = suppressionarrayLength1 + " Suppression  Files Selected";
              }
              else {

                nosuppressionoutput.innerHTML = "";
                //  $("#suppression").val('');
                var message = document.getElementById('suppresionMessage');
                message.innerHTML = "";
              }
            }// end of  if(names[temp] === clickedFile)

          }// end of for
          let suppressionJson1 = [...self.state.suppressionJson];
          let suppressionJson = suppressionJson1.filter((a) => a.supportDocID != supportDocID);
          self.setState({ suppressionJson });
        });//$(document).on

        var data = new FormData();
        data.append("campID", campID);
        //data.append("names",names);

        for (let i = 0, l = suppressionfilesData.length; i < l; i++) {
          let fileName = suppressionfilesData[i].name;
          if (duplicateSuppressionFile.includes(fileName)) { }
          else {
            data.append("file", suppressionfilesData[i]);
          }
        }

        //campaign/supportingDocument
        Swal.fire({
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading()
            fetch("campaign/supportingDocumentSuppression", {
              method: 'POST',
              //mode:'no-cors',
              body: data
            }).then(res => res.json())
              .then(suppList => {
                if (suppList[0].success === true) {//added by raunak message will be shown based on type of response
                  Swal.fire({ text: "File Upload Complete", type: "success" })
                } else {
                  Swal.fire({ text: "Invalid format of the file", type: "error" })
                }

                let suppressionJson1 = [...self.state.suppressionJson]
                let suppressionJson = [...suppressionJson1, ...suppList];
                self.setState({ suppressionJson });
                if (suppList[0].success === true) {
                  var suppDocName = [];
                  for (let i = 0; i < suppList.length; i++) {
                    let { success, fileName } = suppList[i];
                    if (success === true) {
                      suppDocName.push(fileName);
                      successSuppressionCount.push(fileName);
                    }
                  }
                  for (let i = 0; i < suppDocName.length; i++) {
                    var count = 0;
                    for (var j = 0; j < newsuppressionfilenames.length; j++) {
                      if (suppDocName[i] == newsuppressionfilenames[j]) {
                        count = count + 1;
                      }
                    }
                  }

                  if (count > 1) {
                    newsuppressionfilenames.pop();
                    suppValidationArray.pop();
                  }
                  var RejectedCount = 0;
                  var HTML2 = "<table>";
                  var imgsuccess;
                  for (let j = 0; j < newsuppressionfilenames.length; j++) {
                    let singleFile = suppressionJson.filter((a) => a.fileName == newsuppressionfilenames[j])
                    let supportDocID = singleFile[0].supportDocID;
                    var isExist = suppValidationArray.includes(newsuppressionfilenames[j]);
                    if (isExist === true) {/**** If uploaded file is wrong then it displays as in Red color */
                      RejectedCount = RejectedCount + 1;
                      imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'

                      link = '<a class="removeSuppressionFile" href="#"supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newsuppressionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                      HTML2 += "<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newsuppressionfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                    } else {

                      imgsuccess = '<img src="success.png" alt="success" height="18" width="18" style="float:left"/>'

                      link = '<a class="removeSuppressionFile" href="#" supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newsuppressionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                      HTML2 += "<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newsuppressionfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                    }
                  }
                  HTML2 += "</table>";
                  document.getElementById("suppressionList").innerHTML = HTML2;
                  var msg = "";
                  // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+",<font color='red'>  Failed Files :"+RejectedCount+"</font>)";
                  var message = document.getElementById('suppresionMessage');
                  document.getElementById("suppresionMessage").style.color = "green";
                  var number = document.getElementById("suppresionMessage").innerHTML;
                  message.innerHTML = "";
                  message.innerHTML += msg;
                  nosuppressionoutput.innerHTML = suppressionarrayLength1;
                } else {
                  var isError = suppList[0].Error;
                  for (let i = 0; i < suppList.length; i++) {
                    let { success, fileName } = suppList[i];
                    if (success === false) {
                      suppValidationArray.push(fileName)
                    }
                  }

                  var HTML = "<table>";
                  var imgsuccess;
                  for (var j = 0; j < newsuppressionfilenames.length; j++) {
                    let singleFile = suppressionJson.filter((a) => a.fileName == newsuppressionfilenames[j])
                    let supportDocID = singleFile[0].supportDocID;
                    var isValid = suppValidationArray.includes(newsuppressionfilenames[j]);
                    if (isValid === true) {

                      imgsuccess = '<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>'
                      link = '<a class="removeSuppressionFile" href="#" supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newsuppressionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                      HTML += "<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newsuppressionfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                    } else {
                      let isTrue = successSuppressionCount.includes(newsuppressionfilenames[j]);
                      if (isTrue === false) { successSuppressionCount.push(newsuppressionfilenames[j]); }
                      imgsuccess = '<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>'
                      link = '<a class="removeSuppressionFile" href="#" supportDocID=' + '"' + supportDocID + '"' + ' data-fileid=' + '"' + newsuppressionfilenames[j] + '"' + '><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
                      HTML += "<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" + imgsuccess + "&nbsp;" + "<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" + newsuppressionfilenames[j] + "</span>" + link + "<br><br></td></tr>";

                    }
                  }
                  HTML += "</table>";
                  let rejectedCount = newsuppressionfilenames.length - successSuppressionCount.length;
                  document.getElementById("suppressionList").innerHTML = HTML;
                  if (isError === 'Yes') {
                    var msg1 = "";
                    // "Successfully Uploads Suppression Files (Uploaded Files :"+(successSuppressionCount.length)+", <font color='red'> Failed Files :"+rejectedCount+"</font>)";
                  } else {
                    var msg1 = "<font color='Red'>Please Upload Valid Header File</font>";
                  }


                  var message1 = document.getElementById('suppresionMessage');
                  if (message1 === "") {
                    message1.innerHTML += msg1;
                  }
                  else {
                    message1.innerHTML = msg1;
                    // document.getElementById("suppresionMessage").style.color="red";
                    // document.getElementById("suppresionMessage").innerHTML= msg1;
                  }
                }
              }).catch(function (err) {
                console.log(err)
                Swal.fire({ text: "Error Occured Please Try Again", type: "error" })
              });
          }
        })
        this.setState({
          files: data,
          suppressionerror: '',

        });

        //nosuppressionoutput.innerHTML = suppressionarrayLength1+" Suppression Files";

      }
      else {

        nosuppressionoutput.innerHTML = "";
      }
    }.bind(this))
  }
  /**
        * @auhthor Narendra Phadke
        * @param  Description handle the for checkbox selected dynamic
        * @return Description return All details of delivery format
        */
  handleChecked(e) {
    //New code
    let fields = this.state;
    const { name, value } = e.target;
    fields[e.target.name] = e.target.value;
    if (e.target.checked === true) {
      fields[e.target.name] = 'Yes'
    } else {
      fields[e.target.name] = 'No'
    }
    // this.setState({ isChecked: !this.state.isChecked }); raunak-2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue- disabling because this particular key-value is disabled in state
  }
  /**
        * @auhthor Raunak Thakkar
        * @param  Description handle the checkbox for customize mapping
        * @return Description returns the updated value of the checkbox
        */
setcustomizeMapping(e){
this.setState({customizeMapping:e.target.checked===true?"Yes":"No"})
}
  handleChangeCheck(e) {

    let fields = this.state;
    const { name, value } = e.target;
    fields[e.target.name] = e.target.value;
    if (e.target.checked === true) {
      fields[e.target.name] = 'Yes'
    } else {
      fields[e.target.name] = 'No'
    }
    // this.setState({ isChecked: !this.state.isChecked }); raunak-2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue- disabling because this particular key-value is disabled in state

  }
  /**
      * @author Snehal More
      * @param  Description handle the for format of customize mapping
      * @return Description format option for customize mapping
      */
  handleFormatChange(e) {
    e.preventDefault()
    const { name, value } = e.target
    this.setState({ customizeMappingFormat: value, sdEditDetail: this.state.sdEditDetail })
  }
  /**
      * @author Snehal More
      * @param  Description onclick for format of customize mapping
      * @return Description onclick function for poup of customize mapping
      */
  customizeMappingFormatHandleChange(e) {

    if (this.state.agencyCustomizeMapping.length > 0) {
      let customMapping = this.state.agencyCustomizeMapping.filter((a) => a.format == this.state.customizeMappingFormat)
      this.state.agencyCustomizeMapping.length = 0;
      this.setState({ agencyCustomizeMapping: customMapping })
    }
  }
  /**
      * @author Snehal More
      * @param  Description onclick for format of customize mapping
      * @return Description onclick function for cancel poup of customize mapping
      */
  customizeMappingCancleHandleChange(e) {
    let self = this;
    var parsed = queryString.parse(this.props.location.search);
    const { isAuthenticated, user } = this.props.auth
    var userID = user.id;
    var campID = parsed.campID;
    var rfpCampaignID = parsed.rfpCampaignID;
    fetch("campaign/getAgencyCustomizeMapping?campID=" + campID + "")
      .then(res => res.json())
      .then(agencyCustomizeMapping => {

        this.setState({ agencyCustomizeMapping: agencyCustomizeMapping, customizeMapping: 'No' })
      });
  }
  /**
   * @author Narendra Phadke
   * @param  Description handle the for Back Button Call
   * @return Description return Back Button
   */
  handleBackButton(e) {

    var campID = this.state.campID
    var rfpCampaignID = this.state.rfpCampaignID
    if (rfpCampaignID) {
      window.location.href = '/welcomePage?campID=' + campID + '&step1&fromBack=true&rfpCampaign&rfpCampaignID=' + rfpCampaignID;

    }
    else {
      window.location.href = '/welcomePage?campID=' + campID + '&step1&fromBack=true';

    }

  }


    /**
   * @author Narendra Phadke
   * @param  Description handle the submit form event
   * @return Description return confirm to go next page
   */
  submitSupportingDocument(e) {

    e.preventDefault();



    e.target.className += " was-validated";


    var step2 = window.location.href.split("&")[window.location.href.split("?").length - 1];


    if (step2 == undefined || step2 == null || step2 == "") { }
    else {
      // var result=[];
      result = Array.from(new Set(this.state.dynamicAssetName.map(p => p.key))).map(key => {
        return {
          key: key,
          suppDocName: this.state.dynamicAssetName.find(p => p.key === key).suppDocName,
          leadPerAsset: this.state.dynamicAssetName.find(p => p.key === key).leadPerAsset,
          leadPercentage: this.state.dynamicAssetName.find(p => p.key === key).leadPercentage,
          multiTouch: this.state.dynamicAssetName.find(p => p.key === key).multiTouch
        };
      });
      this.setState({ result: result }, function () {
      })






    }

    if (this.validateForm() == false) {

      return;
    }
    else {
      /*@author Narendra phadke
    *Desc Fetch campID from Url
    * dependencies query-string
    */

      var parsed = queryString.parse(this.props.location.search);
      var campID = parsed.campID;


      this.setState({

        alertDisplay: 'none'

      });


      if (step2 == undefined || step2 == null || step2 == "") {


        let data11 = {
          campID: campID,
          switchValue: this.state.switchValue
        }

        fetch("campaign/updateSwitchValue", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          //mode:'no-cors',
          body: JSON.stringify(data11)
        }).catch(function (err) {
          console.log(err)
        });
        //Raunak-2881 following setState done to send asset file and asset link data to backend
        this.state.assetLinkArray = [...this.state.dynamicInput]
        this.state.assetFileNameArray = [...this.state.inputType]
        let data = {
          campID: campID,
          //  budget:this.state.campaignBudget,
          //  currency:this.state.currency,
          suppressionLinkArray: this.state.suppressionLinkArray,
          assetLinkArray: this.state.assetLinkArray,
          // assetLinkLeadArray:this.state.assetLinkLeadArray,
          assetFileName: this.state.assetFileNameArray,
          //assetLeadArray:this.state.assetLeadArray,
          assetsFile: this.state.inputType,
          requiredLeadPerAsset: this.state.leadPerAssetChk,
          multiTouch: this.state.multiTouchChk,
          assetJson: this.state.assetJson,
          abmJson: this.state.abmJson,
          exclusionJson: this.state.exclusionJson,
          suppressionJson: this.state.suppressionJson,
          otherJson: this.state.otherJson,
        }
        //     let formData = new FormData();
        // var fileList=this.state.files;

        // console.warn("new File list=",fileList);
        //      formData.append('campID', campID);
        //      formData.append('file', fileList);

       // alert("data send"+JSON.stringify(data));
        fetch("campaign/supportingDocument", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          //mode:'no-cors',
          body: JSON.stringify(data)
        }).then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        }).then(function (response) {

          //fetch insertId because we AutoIncrement ID so first fetch insertId is a our autocreated ID
          var campID = response.campID;
          console.log(response);

          // window.location.href = '/finishCampaign?campID='+campID+'';

        }).catch(function (err) {
          console.log(err)
        });

        //Narendra add this code here because some time it gives error and go into incomplete campaign

        const { user } = this.props.auth;
       let deliveryData = {
          campID: campID,
          user: user,
          companyName: this.state.companyName,
          linkedInCompanyName: this.state.linkedInCompanyName,
          pID: this.state.pID,
          assetId: this.state.assetId,
          campaignID: this.state.campaignID,
          leadInteractionDate: this.state.leadInteractionDate,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          jobTitleDelivery: this.state.jobTitleDelivery,
          linkedInJobTitle: this.state.linkedInJobTitle,
          jobTitleValidation: this.state.jobTitleValidation,
          jobFunctionDelivery: this.state.jobFunctionDelivery,
          address: this.state.address,
          jobLevelDelivery: this.state.jobLevelDelivery,
          assetName: this.state.assetName,

          email: this.state.email,
          workPhone: this.state.workPhone,
          street: this.state.street,
          city: this.state.city,
          state: this.state.state,
          countryDelivery: this.state.countryDelivery,
          zipCode: this.state.zipCode,
          companyEmployeeSize: this.state.companyEmployeeSize,
          companyRevenue: this.state.companyRevenue,
          industry: this.state.industry,
          ip: this.state.ip,
          extra1: this.state.extra1,
          extra2: this.state.extra2,
          extra3: this.state.extra3,
          extra4: this.state.extra4,
          extra5: this.state.extra5,
         
          extra6: this.state.extra6,//Sandeep-task-3158-added code for extra 15 fields from 6-20
          extra7: this.state.extra7,
          extra8: this.state.extra8,
          extra9: this.state.extra9,
          extra10: this.state.extra10,

          extra11: this.state.extra11,
          extra12: this.state.extra12,
          extra13: this.state.extra13,
          extra14: this.state.extra14,
          extra15: this.state.extra15,

          extra16: this.state.extra16,
          extra17: this.state.extra17,
          extra18: this.state.extra18,
          extra19: this.state.extra19,
          extra20: this.state.extra20,

          reAllocationID: this.state.reAllocationID,
          campaignName:this.state.campaignName,
          domain: this.state.domain,
          alternatePhoneNo: this.state.alternatePhoneNo,
          comments: this.state.comments,
          linkedIn: this.state.linkedIn,
          channel: this.state.channel,
          assetFileNameArray: this.state.assetFileNameArray,
          assetLinkArray: this.state.assetLinkArray,
          customizeMapping: this.state.customizeMapping,
          customizeMappingFormat: this.state.customizeMappingFormat,
          multiTouch: this.state.multiTouchChk,
          rfpCamp: this.state.rfpCampaignID,
          format: this.state.format
          // customQuestion1:this.state.customQuestion1,
          // customQuestion2:this.state.customQuestion2,
          // customQuestion3:this.state.customQuestion3,
          // customQuestion4:this.state.customQuestion4,
          // customQuestion5:this.state.customQuestion5,
          // customQuestion6:this.state.customQuestion6,
          // additionalComments:this.state.additionalComments,
          // answer1:this.state.answer1,
          // answer2:this.state.answer2,
          // answer3:this.state.answer3,
          // answer4:this.state.answer4,
          // answer5:this.state.answer5,
          // answer6:this.state.answer6,
        }
        fetch("/campaign/deliveryFormat", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          //mode:'no-cors',
          body: JSON.stringify(deliveryData)
        }).then(res => res.json())
          .then(campaignDetail => {
            console.log("Return with response");
            //var campID=response.body.campID;
            this.setState({ campaignDetail: campaignDetail });
            //console.log(JSON.stringify(campaignDetail));
            var campID = campaignDetail[0].campID;
            var parentCampID = campaignDetail[0].parentCampID;
            console.log(campID);

            //send campID to new page


            if (this.state.rfpCampaignID) {
              window.location.href = '/successPage?campID=' + campID + '&parentCampID=' + parentCampID + '&rfpCampaignID=' + this.state.rfpCampaignID;

            }
            else {
              window.location.href = '/successPage?campID=' + campID + '&parentCampID=' + parentCampID + '';
            }
            if (deliveryData === "success") {
              console.log("Inside if loop");
              this.refs.msg.show('Some text or component', {
                time: 2000,
                type: 'success',
                icon: <img src="path/to/some/img/32x32.png" />
              })
            }
          }).catch(function (err) {
            console.log(err)
          });

      }

      else {

        //  In else Block for edit

        let data11 = {
          campID: campID,
          switchValue: this.state.switchValue
        }

        fetch("campaign/updateSwitchValue", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          //mode:'no-cors',
          body: JSON.stringify(data11)
        }).catch(function (err) {
          console.log(err)
        });// route for switchvalue at back flow
        var inputType = [...this.state.inputType];

        var backDynamicInput = [...this.state.backDynamicInput];

        var assetTotalLead = 0;
        for (var i = 0; i < inputType.length; i++) {
          assetTotalLead = assetTotalLead + parseInt(inputType[i].leadPerAsset);

        }
        for (var i = 0; i < backDynamicInput.length; i++) {
          assetTotalLead = assetTotalLead + parseInt(backDynamicInput[i].leadPerAsset);

        }



 //Sanjana-2979 following setState done to send asset file and asset link data to backend
          this.state.dynamicAssetName = [...this.state.inputType]
        let data = {
          assetFileName: this.state.dynamicAssetName,
          dynamicInput: this.state.backDynamicInput,
          assetsFile: this.state.inputType,
          requiredLeadPerAsset: this.state.leadPerAssetChk,
          campID: campID,
          suppressionLink: this.state.suppressionLink,
          multiTouchEdit: this.state.multiTouchChkBack,
          assetJson: this.state.assetJson,
          abmJson: this.state.abmJson,
          exclusionJson: this.state.exclusionJson,
          suppressionJson: this.state.suppressionJson,
          otherJson: this.state.otherJson,
        }

        //alert("editSupportingDocument"+JSON.stringify(data));
        fetch("campaign/editSupportingDocument", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          //mode:'no-cors',
          body: JSON.stringify(data)
        })

          .then(function (response) {
            if (response.status >= 400) {
              throw new Error("Bad response from server");
            }
            return response.json();
          }).then(function (response) {
            // console.log("Return with response");
            var campID = response.campID;
            // console.log(response);
            //window.location.href = '/finishCampaign?campID=' + campID + '&step3';

          }).catch(function (err) {
            console.log(err)
          });

        //delivery backend
        const { user } = this.props.auth;
        let deliveryData = {
          campID: campID,
          user: user,
          companyName: this.state.companyName,
          linkedInCompanyName: this.state.linkedInCompanyName,
          pID: this.state.pID,
          assetId: this.state.assetId,
          campaignID: this.state.campaignID,
          leadInteractionDate: this.state.leadInteractionDate,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          jobTitleDelivery: this.state.jobTitleDelivery,
          linkedInJobTitle: this.state.linkedInJobTitle,
          jobTitleValidation: this.state.jobTitleValidation,
          jobLevelDelivery: this.state.jobLevelDelivery,
          jobFunctionDelivery: this.state.jobFunctionDelivery,
          address: this.state.address,

          assetName: this.state.assetName,

          email: this.state.email,
          workPhone: this.state.workPhone,
          street: this.state.street,
          city: this.state.city,
          state: this.state.state,
          countryDelivery: this.state.countryDelivery,
          zipCode: this.state.zipCode,
          companyEmployeeSize: this.state.companyEmployeeSize,
          companyRevenue: this.state.companyRevenue,
          industry: this.state.industry,
          ip: this.state.ip,
          extra1: this.state.extra1,
          extra2: this.state.extra2,
          extra3: this.state.extra3,
          extra4: this.state.extra4,
          extra5: this.state.extra5,

          extra6: this.state.extra6,//Sandeep-task-3158-added code for extra 15 fields from 6to20
          extra7: this.state.extra7,
          extra8: this.state.extra8,
          extra9: this.state.extra9,
          extra10: this.state.extra10,

          extra11: this.state.extra11,
          extra12: this.state.extra12,
          extra13: this.state.extra13,
          extra14: this.state.extra14,
          extra15: this.state.extra15,

          extra16: this.state.extra16,
          extra17: this.state.extra17,
          extra18: this.state.extra18,
          extra19: this.state.extra19,
          extra20: this.state.extra20,

          reAllocationID: this.state.reAllocationID,
          campaignName:this.state.campaignName,
          domain: this.state.domain,
          alternatePhoneNo: this.state.alternatePhoneNo,
          comments: this.state.comments,
          linkedIn: this.state.linkedIn,
          channel: this.state.channel,
          dynamicAssetName: this.state.dynamicAssetName,
          backDynamicInput: this.state.backDynamicInput,
          customizeMapping: this.state.customizeMapping,
          customizeMappingFormat: this.state.customizeMappingFormat,  /*Raunak 2915-Customize mapping missing issue- customizeMappingFormat's value is passed */
          rfpCamp: this.state.rfpCampaignID,
          format: this.state.format

        }
        var rfpCampaignID = this.state.rfpCampaignID
        if (rfpCampaignID) {
          if (rfpChange > 0) {
            //Changes in rfp specifications
            Swal.fire({
              text: "Please note that updated specification not matching with RFP details, Are you sure you want to change the specification?",
              type: 'question',
              title: 'Are you sure you want to change the specification?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#193D8F',
              cancelButtonColor: '#193D8F',
              confirmButtonText: 'Yes, go ahead !',
              cancelButtonText: 'Cancel',
              allowOutsideClick: false,
              preConfirm: () => {
                fetch("/campaign/deliveryFormat", {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },

                  body: JSON.stringify(deliveryData)
                }).then(res => res.json())
                  .then(campaignDetail => {
                    console.log("Return with response");
                    //var campID=response.body.campID;
                    this.setState({ campaignDetail: campaignDetail });
                    //console.log(JSON.stringify(campaignDetail));
                    var campID = campaignDetail[0].campID;
                    var parentCampID = campaignDetail[0].parentCampID;
                    console.log(campID);

                    //send campID to new page
                    if (this.state.rfpCampaignID) {

                      window.location.href = '/successPage?campID=' + campID + '&parentCampID=' + parentCampID + '&rfpCampaignID=' + this.state.rfpCampaignID;

                    }
                    else {
                      window.location.href = '/successPage?campID=' + campID + '&parentCampID=' + parentCampID + '';
                    }
                    if (deliveryData === "success") {
                      console.log("Inside if loop");
                      this.refs.msg.show('Some text or component', {
                        time: 2000,
                        type: 'success',
                        icon: <img src="path/to/some/img/32x32.png" />
                      })
                    }
                  }).catch(function (err) {
                    console.log(err)
                  });

              }// end of preConfirm
            })


          }
          else if (rfpChange == 0) {
            //no changes in rfp specifications
            //delivery backend
            //alert("rfpChange == 0"+JSON.stringify(deliveryData))

            fetch("/campaign/deliveryFormat", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },

              body: JSON.stringify(deliveryData)
            }).then(res => res.json())
              .then(campaignDetail => {
                console.log("Return with response");
                //var campID=response.body.campID;
                this.setState({ campaignDetail: campaignDetail });
                //console.log(JSON.stringify(campaignDetail));
                var campID = campaignDetail[0].campID;
                var parentCampID = campaignDetail[0].parentCampID;
                console.log(campID);

                //send campID to new page
                if (this.state.rfpCampaignID) {
                  window.location.href = '/successPage?campID=' + campID + '&parentCampID=' + parentCampID + '&rfpCampaignID=' + this.state.rfpCampaignID;

                }
                else {
                  window.location.href = '/successPage?campID=' + campID + '&parentCampID=' + parentCampID + '';
                }
                if (deliveryData === "success") {
                  console.log("Inside if loop");
                  this.refs.msg.show('Some text or component', {
                    time: 2000,
                    type: 'success',
                    icon: <img src="path/to/some/img/32x32.png" />
                  })
                }
              }).catch(function (err) {
                console.log(err)
              });

          } // end of else of no changes in rfp specifications
        }//  end of   if(rfpCampaignID)

        else {
          fetch("/campaign/deliveryFormat", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },

            body: JSON.stringify(deliveryData)
          }).then(res => res.json())
            .then(campaignDetail => {
              console.log("Return with response");
              //var campID=response.body.campID;
              this.setState({ campaignDetail: campaignDetail });

              var campID = campaignDetail[0].campID;
              var parentCampID = campaignDetail[0].parentCampID;
              console.log(campID);

              //send campID to new page
              if (this.state.rfpCampaignID) {
                window.location.href = '/successPage?campID=' + campID + '&parentCampID=' + parentCampID + '&rfpCampaignID=' + this.state.rfpCampaignID;

              }
              else {
                window.location.href = '/successPage?campID=' + campID + '&parentCampID=' + parentCampID + '';
              }
              if (deliveryData === "success") {
                console.log("Inside if loop");
                this.refs.msg.show('Some text or component', {
                  time: 2000,
                  type: 'success',
                  icon: <img src="path/to/some/img/32x32.png" />
                })
              }
            }).catch(function (err) {
              console.log(err)
            });
        }

      }/// end of else
    } // ens of validateForm true
  }// end of fn submitsupporting document

  
  
  
  validateForm() {


    var step2 = window.location.href.split("&")[window.location.href.split("?").length - 1];

    if (step2 === null || step2 === undefined) {
      let fields = this.state.fields;
      let errors = {};
      let leaderrors = {};
      let asseterrors = {};
      let abmerrors = {};
      let spanDisplay = 'none';
      let formIsValid = true;// main flag
      let assest1 = true;// used for asset files and asset link
      let assestinput = true;//used for asset file name and asset link name
      let assetvalidate = true;//used for lead per asset 
      let multiTouchChkFlag = true;// used for multitouch
      let abmFileType = true;// used for abm file upload



      if (this.state.inputType.length === 0 && this.state.dynamicInput.length === 0) {// no asset and no asset link is uploaded 

        asseterrors["assetFiles2"] = <li><b>Please upload either asset link or asset file</b></li>;
        assest1 = false
      }
      if (this.state.campaignType == 'ABM') {
        var abmarrayLength = newabmfilenames.length
        let abmJson = [...this.state.abmJson];
        if (abmJson == 0) {
          abmFileType = false;
        }
        else {
          abmFileType = true;
        }
      }
      if (this.state.campaignType == 'ABM') {

        let abmJson = [...this.state.abmJson];

        if (abmJson.length > 0) {
          let checkData = abmJson.filter((a) => a.success == false)
          if (checkData.length > 0) {


            abmerrors["properabmFiles"] = <li><b>Please upload valid ABM file</b></li>;
            asseterrors["assetFiles"] = "";
            this.setState({ alertBorder: '1px solid red', abmerrors: abmerrors, asseterrors: asseterrors })
            return false;
          }
          else {
            allabms = true;
            abmerrors["abmFiles"] = "";
            this.setState({ alertBorder: '0px solid white' })
          }
        }
        else {
          allabms = false;
          abmerrors["abmFiles"] = <li><b>Please upload ABM file</b></li>;
          formIsValid = false;

        }
      }
      //end of the campaign type abm validation
      /* ******************************************************************************************************************************************************* */
      //start of no of lead number per domain

      // if (this.state.detailsOfCampaign[0].noOfLeadPerDomain = !"" && this.state.detailsOfCampaign[0].noOfLeadPerDomain > 0) {

      //   let abmJson = [...this.state.abmJson];


      //   if (abmJson.length > 0) {
      //     let checkData = abmJson.filter((a) => a.success == false)
      //     if (checkData.length > 0) {
      //       abmerrors["properabmFiles"] = <li><b>Please upload valid ABM file</b></li>;
      //       asseterrors["assetFiles"] = "";
      //       this.setState({ alertBorder: '1px solid red', abmerrors: abmerrors, asseterrors: asseterrors })
      //       return false;
      //     }
      //     else {
      //       allabms = true;
      //       abmerrors["abmFiles"] = "";
      //       this.setState({ alertBorder: '0px solid white' })



      //     }

      //   }
      //   else {



      //     allabms = false;
      //     abmerrors["abmFiles"] = <li><b>Please upload ABM file</b></li>;
      //     // this.setState({alertBorder:'1px solid red'})
      //     formIsValid = false;


      //     // }

      //   }
      // }
      //end validation for number of lead per domain
      /* ********************************************************************************************************************************************** */
      //start validation for asset file name

      for (var i = 0; i < this.state.inputType.length; i++) {
        if (this.state.inputType[i].assetFileName == "") {
          assestinput = false;
          formIsValid = false;
          errors["assetFileName"] = <li><b>Please enter asset name for uploaded asset file</b></li>;
          this.setState({ errors: errors })
          break;
        }
        else {
          assestinput = true;
        }
      }
      //end validation for asset file name 
      /* ********************************************************************************************************************************************* */
      // start validation for asset link and asset link Name
      for (var i = 0; i < this.state.dynamicInput.length; i++) {
        if (this.state.dynamicInput[i].assetLink != "" && this.state.dynamicInput[i].linkAssetFileName != "") {
          assestinput = true;
        }
        else {
          if (this.state.dynamicInput[i].assetLink == "" && this.state.dynamicInput[i].linkAssetFileName == "") {
            assestinput = false;
            formIsValid = false;
            errors["assetLinkName"] = <li><b>Please enter asset link and asset link name</b></li>;
            this.setState({ errors: errors })
            break;
          }

          if (this.state.dynamicInput[i].assetLink == "") {
            assestinput = false;
            formIsValid = false;
            errors["assetLinkName"] = <li><b>Please enter asset link for uploaded asset name</b></li>;
            this.setState({ errors: errors })
            break;
          }
          if (this.state.dynamicInput[i].linkAssetFileName == "") {
            assestinput = false;
            formIsValid = false;
            errors["assetLinkName"] = <li><b>Please enter asset link name for uploaded asset link</b></li>;
            this.setState({ errors: errors })
            break;
          }
        }
      }
      //end validation for asset link and asset link name 
      /* *********************************************************************************************************************************************** */
      //start validation for lead per asset 
      if (this.state.leadPerAssetChk == 'Yes') {
        let tempArray = [...this.state.inputType, ...this.state.dynamicInput]
        let leadPercentageTotal = 0 //to check the total of leadPercentage (asset+ assetLink)

        for (let i = 0; i < tempArray.length; i++) {


          if (tempArray[i].leadPercentage === undefined || tempArray[i].leadPercentage === "" || tempArray[i].leadPercentage === null) {

            leaderrors["leadPercentage"] = <li><b>Please enter lead percentage for individual uploaded asset </b></li>;
            assetvalidate = false;
            break;

          }
          else {
            if (isNaN(tempArray[i].leadPercentage) === false && parseFloat(tempArray[i].leadPercentage) === parseInt(tempArray[i].leadPercentage)) {
              leadPercentageTotal += parseInt(tempArray[i].leadPercentage);
              assetvalidate = true;
            }
            else {
              leaderrors["AssetValidate"] = <li><b>Please enter only numerical (0-9) values  for lead percentage</b></li>;
              assetvalidate = false;
              break;

            }
          }

        }
        if (assetvalidate === true) {
           //Sanjana-2852-validation condition (leadPercentageTotal < 100 || leadPercentageTotal > 100)  addeded
          if (leadPercentageTotal < 100 || leadPercentageTotal > 100) {
            leaderrors["assetCount"] = <li><b>Leads percentage per asset can't be greater or less than hundred percentage</b></li>;
            assetvalidate = false;
          } else {
            assetvalidate = true;
          }
        }

      }
      //end validation for asset link and asset link name
      /* ****************************************************************************************************************************************** */
      //start validation or multitouch
      if (this.state.multiTouchChk === 'Yes') {
        let commonTouchArray = []
        let multiTouch1stCount = 0
        let multiTouch2ndCount = 0
        let multiTouch3rdCount = 0
        //following the top-down approach
        for (let i = 0; i < this.state.inputType.length; i++) {
          commonTouchArray.push(this.state.inputType[i].multiTouch)
          if (this.state.inputType[i].multiTouch == "1st Touch") {
            multiTouch1stCount += 1
          }
          if (this.state.inputType[i].multiTouch == "2nd Touch") {
            multiTouch2ndCount += 1
          }
          if (this.state.inputType[i].multiTouch == "3rd Touch") {
            multiTouch3rdCount += 1
          }
        }

        for (let i = 0; i < this.state.dynamicInput.length; i++) {
          commonTouchArray.push(this.state.dynamicInput[i].multiTouch)
          if (this.state.dynamicInput[i].multiTouch == "1st Touch") {
            multiTouch1stCount += 1
          }
          if (this.state.dynamicInput[i].multiTouch == "2nd Touch") {
            multiTouch2ndCount += 1
          }
          if (this.state.dynamicInput[i].multiTouch == "3rd Touch") {
            multiTouch3rdCount += 1
          }
        }
        if (commonTouchArray.length == 1) {
          //false
          errors['OneAsset'] = <li><b>Please add more assets</b></li>
          multiTouchChkFlag = false
        }

        if (commonTouchArray.length == 2) {

          if (commonTouchArray.includes("1st Touch") && commonTouchArray.includes("2nd Touch")) {

            multiTouchChkFlag = true // true because there are only 2 assets are both the touch are present
          } else {

            //if condn for all same touch
            errors['OneAsset'] = <li><b>All assets cannot be of same touch</b></li>
            multiTouchChkFlag = false
            //false when all the touch are same or 3rd touch is present
            if (commonTouchArray.includes('3rd Touch')) {
              //if condition for 3rd touch
              errors['OneAsset'] = <li><b>Assets are not enough for  3rd touch</b></li>
              multiTouchChkFlag = false
            }
          }
        }
        if (commonTouchArray.length >= 3) {
          if (multiTouch3rdCount === commonTouchArray.length || multiTouch1stCount === commonTouchArray.length || multiTouch2ndCount === commonTouchArray.length) {
            errors['OneAsset'] = <li><b>All assets cannot be of same touch </b></li>
            multiTouchChkFlag = false
          }
          else {

            if (!commonTouchArray.includes("1st Touch") || !commonTouchArray.includes("2nd Touch")) { //checking for 1st and 2nd touch
              errors['OneAsset'] = <li><b>Either of 1st touch or 2nd touch not present</b></li>
              multiTouchChkFlag = false
            }
          }
        }
      }
      /* ******************************************************************************************************************************************** */
      //final condition for validating the form flag
    
      if (assest1 === true && allabms === true && assestinput === true && assetvalidate === true && multiTouchChkFlag === true) {
        formIsValid = true;
        this.setState({
          errors: '',
          leaderrors: '',
          asseterrors: '',
          abmerrors: '',
          alertBorder: '0px',
          alertWidth: '0 px',
          alertAlign: 'center',
          alertDisplay: 'none',
          spanSpaceDisplay: spanDisplay
        });
      }

      else {
        formIsValid = false;
        this.setState({
          errors: errors,
          leaderrors: leaderrors,
          asseterrors: asseterrors,
          abmerrors: abmerrors,
          alertBorder: '1px solid red',
          alertWidth: '1000px',
          alertAlign: 'center',
          alertDisplay: 'block',
          spanSpaceDisplay: spanDisplay
        });

      }
      document.body.scrollTop = (0, 0);
      document.documentElement.scrollTop = (0, 0);
      return formIsValid;

    }
    // end of if (step2 == undefined || step2 == null || step2 == "")

    else {
      //Sanjana – Task -2979- revised validation
      let leaderrors = {};
      let errors = {};
      let linknameerror = {};
      let backerror = {};
      let backabmerror = {};
      let formIsValid = true;// main flag
      let spanDisplay = 'none';
      let assetUpload = true;// used for asset files and asset link
      let assestinput = true;//used for asset file name and asset link name
      let abmBackLength = chkABMExist.length;
      let inputTypeLength = this.state.inputType.length;
      let assetvalidate = true;//used for lead per asset 
      let multiTouchChkFlag = true;// used for multitouch
      let allabms = true;// used for abm file upload




      if (this.state.inputType.length === 0 && this.state.backDynamicInput.length === 0) {
        backerror["assetUploadError"] = <li><b>Please upload either asset file or asset link</b></li>
        assetUpload = false;
        this.setState({ backerror: backerror })
        
      }
      
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Start of Validation for campaignType === 'ABM', where ABM upload is mandatory -Sunita


      var abmJson = [...this.state.abmJson];
      if (this.state.campaignType === 'ABM') {
        if (abmJson.length > 0 || abmBackLength > 0) {
          let checkData1 = abmJson.filter((a) => a.success == false)
          if (checkData1.length > 0) {
            backabmerror["properabmFiles1"] = <li><b>Please upload valid ABM file</b></li>
            backerror["assetFiles1"] = "";
            this.setState({ alertBorder: '1px solid red', backabmerror: backabmerror, backerror: backerror })
            return false;
          }
          else {
            allabms = true;
            backabmerror["abmFiles1"] = "";
            this.setState({ alertBorder: '0px solid white' })
          }

        }
        else {

          allabms = false;
          backabmerror["abmFiles1"] = <li><b>Please upload ABM file</b></li>
          backerror["assetFiles1"] = "";
          formIsValid = false;

        }


      }

      // Start of Validation for campaignType === 'ABM', where ABM upload is mandatory -Sunita
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //start of no of lead number per domain-Snehal
      // if (this.state.detailsOfCampaign[0].noOfLeadPerDomain = !"" && this.state.detailsOfCampaign[0].noOfLeadPerDomain > 0) {

      //   let abmJson = [...this.state.abmJson];
      //   if (abmJson.length > 0 || abmBackLength > 0) {
      //     let checkData = abmJson.filter((a) => a.success == false)
      //     if (checkData.length > 0) {
      //       backabmerror["properabmFiles1"] = <li><b>Please upload valid ABM file</b></li>
      //       this.setState({ alertBorder: '1px solid red', backabmerror: backabmerror })
      //       return false;
      //     }
      //     else {
      //       allabms = true;
      //       backabmerror["abmFiles1"] = '';
      //       this.setState({ alertBorder: '0px solid white', backabmerror: '' })
      //     }

      //   }
      //   else {
      //     allabms = false;
      //     backabmerror["abmFiles1"] = <li><b>Please upload ABM file</b></li>
      //     this.setState({ backabmerror: backabmerror, alertBorder: '1px solid red' });
      //   }
      // }
      //End of no of lead number per domain validation -Snehal
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //Asset File name Validation
      //let inputTypeLength = this.state.inputType.length; length of Assset Files uploaded
      for (var i = 0; i < inputTypeLength; i++) {
     
        if (this.state.inputType[i].suppDocName === undefined || this.state.inputType[i].suppDocName === "" || this.state.inputType[i].suppDocName === null) {
          assestinput = false;
          formIsValid = false;
          errors["suppDocName"] = <li><b>Please enter asset name for uploaded asset file</b></li>;
          this.setState({
            errors: errors,
          });
          break;
        }
        else {
          assestinput = true;
        }
      }

      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // start validation for asset link and asset link Name
      for (var i = 0; i < this.state.backDynamicInput.length; i++) {
        if (this.state.backDynamicInput[i].assetLink != "" && this.state.backDynamicInput[i].linkAssetFileName != "") {
          assestinput = true;
        }
        else {
          if (this.state.backDynamicInput[i].assetLink == "" && this.state.backDynamicInput[i].linkAssetFileName == "") {
            assestinput = false;
            formIsValid = false;
            linknameerror["linkname"] = <li><b>Please enter asset link and asset link name</b></li>;
            break;
          }
          if (this.state.backDynamicInput[i].assetLink == "") {
            assestinput = false;
            formIsValid = false;
            linknameerror["linkname"] = <li><b>Please enter asset link for uploaded asset name</b></li>;
            break;
          }
          if (this.state.backDynamicInput[i].linkAssetFileName == "") {
            assestinput = false;
            formIsValid = false;
            linknameerror["linkname"] = <li><b>Please enter asset link name for uploaded asset link</b></li>;
            break;
          }
        }
      }
      //end validation for asset link and asset link name 
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //start validation for lead per asset 
      if (this.state.leadPerAssetChk == 'Yes') {
        let tempArray = [...this.state.inputType, ...this.state.backDynamicInput]
        let leadPercentageTotal = 0 //to check the total of leadPercentage (asset+ assetLink)
        for (let i = 0; i < tempArray.length; i++) {

          if (tempArray[i].leadPercentage === undefined || tempArray[i].leadPercentage === "" || tempArray[i].leadPercentage === null) {

            leaderrors["leadPercentage"] = <li><b>Please enter lead percentage for individual uploaded asset </b></li>;
            assetvalidate = false;
            break;

          }
          else {
            if (isNaN(tempArray[i].leadPercentage) == false && parseFloat(tempArray[i].leadPercentage) == parseInt(tempArray[i].leadPercentage)) {
              leadPercentageTotal += parseInt(tempArray[i].leadPercentage);
              assetvalidate = true;
            }
            else {
              leaderrors["AssetValidate"] = <li><b>Please enter only numerical (0-9) values  for lead percentage</b></li>;
              assetvalidate = false;
              break;

            }
          }

        }
        if (assetvalidate == true) {
          //Sanjana-2852-validation condition (leadPercentageTotal < 100 || leadPercentageTotal > 100)  addeded
          if (leadPercentageTotal < 100 || leadPercentageTotal > 100) {
            leaderrors["assetCount"] = <li><b>Leads percentage per asset can't be greater or less than hundred percentage</b></li>;
            assetvalidate = false;
          } else {
            assetvalidate = true;
          }
        }

      }
      //end validation for asset link and asset link name
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //start validation or multitouch
      if (this.state.multiTouchChkBack === 'Yes') {
        let commonTouchArray = []
        let multiTouch1stCount = 0
        let multiTouch2ndCount = 0
        let multiTouch3rdCount = 0
        //following the top-down approach
        for (let i = 0; i < this.state.inputType.length; i++) {
          commonTouchArray.push(this.state.inputType[i].multiTouch)
          if (this.state.inputType[i].multiTouch == "1st Touch") {
            multiTouch1stCount += 1
          }
          if (this.state.inputType[i].multiTouch == "2nd Touch") {
            multiTouch2ndCount += 1
          }
          if (this.state.inputType[i].multiTouch == "3rd Touch") {
            multiTouch3rdCount += 1
          }
        }

        for (let i = 0; i < this.state.backDynamicInput.length; i++) {
          commonTouchArray.push(this.state.backDynamicInput[i].multiTouch)
          if (this.state.backDynamicInput[i].multiTouch == "1st Touch") {
            multiTouch1stCount += 1
          }
          if (this.state.backDynamicInput[i].multiTouch == "2nd Touch") {
            multiTouch2ndCount += 1
          }
          if (this.state.backDynamicInput[i].multiTouch == "3rd Touch") {
            multiTouch3rdCount += 1
          }
        }

        if (commonTouchArray.length == 1) {
          backerror['OneAsset'] = <li><b>Please add more assets</b></li>
          multiTouchChkFlag = false
        }

        if (commonTouchArray.length == 2) {

          if (commonTouchArray.includes("1st Touch") && commonTouchArray.includes("2nd Touch")) {

            multiTouchChkFlag = true // true because there are only 2 assets are both the touch are present
          } else {

            //if condn for all same touch
            backerror['OneAsset'] = <li><b>All assets cannot be of same touch</b></li>
            multiTouchChkFlag = false
            //false when all the touch are same or 3rd touch is present
            if (commonTouchArray.includes('3rd Touch')) {
              //if condition for 3rd touch
              backerror['OneAsset'] = <li><b>Assets are not enough for  3rd touch</b></li>
              multiTouchChkFlag = false
            }
          }
        }
        if (commonTouchArray.length >= 3) {
          if (multiTouch3rdCount === commonTouchArray.length || multiTouch1stCount === commonTouchArray.length || multiTouch2ndCount === commonTouchArray.length) {
            backerror['OneAsset'] = <li><b>All assets cannot be of same touch </b></li>
            multiTouchChkFlag = false
          }
          else {

            if (!commonTouchArray.includes("1st Touch") || !commonTouchArray.includes("2nd Touch")) { //checking for 1st and 2nd touch
              backerror['OneAsset'] = <li><b>Either of 1st touch or 2nd touch not present</b></li>
              multiTouchChkFlag = false
            }
          }
        }
      }
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //final condition for validating the form flag
      //alert(assetUpload + "==" + allabms + "==" + assestinput + "==" + assetvalidate + "==" + multiTouchChkFlag)
      if (assetUpload == true && allabms === true && assestinput === true && assetvalidate === true && multiTouchChkFlag === true) {
        formIsValid = true;
        this.setState({
          backerror: '',
          backabmerror: '',
          linknameerror: '',
          errors:"",
          leaderrors: '',
          alertBorder: '0px',
          alertWidth: '0 px',
          alertAlign: 'center',
          alertDisplay: 'none',
          spanSpaceDisplay: spanDisplay
        });
      }
     
      else {
       
        formIsValid = false;
        this.setState({
          backerror: backerror,
          backabmerror: backabmerror,
          linknameerror: linknameerror,
          leaderrors: leaderrors,
          alertBorder: '1px solid red',
          alertWidth: '1000px',
          alertAlign: 'center',
          alertDisplay: 'block',
          spanSpaceDisplay: spanDisplay
        });

      }
      document.body.scrollTop = (0, 0);
      document.documentElement.scrollTop = (0, 0);
      return formIsValid;


    } // end of else validation
  }
  render() {

    /**
        * @author Narendra Phadke
        * @param  Description handle the login authentication
        * @return Description return All details of authentication
        */
    const { isAuthenticated, user } = this.props.auth;
    const asterisk =
    {
      color: 'red',

    }



    //  console.warn("Data of backend=="+JSON.stringify(this.state.sdEditDetail));
    /**
     * @author Narendra Phadke
     * @param  Description Fetch campID from Url
     * @return Dependencies query-string
     */
    var parsed = queryString.parse(this.props.location.search);

    var campID = parsed.campID;
    console.log("Control on react page");
    console.log(campID);
    var step2 = window.location.href.split("&")[window.location.href.split("?").length - 1];
    if (step2 != 'undefined' || step2 != null || step2 != "" || step2 != undefined) {
      if (step2 == 'rfpCampaign') {
        this.state.dynamicAssetName = this.state.inputType.map((e) => Object.assign({}, e, this.state.dynamicAssetName.find((e1) => e1.key === e.key) || {}));
      }
      else {
        //  this.state.inputType.forEach((itm, i) => {
        //   this.state.dynamicAssetName.push(Object.assign({}, itm));
        this.state.dynamicAssetName = this.state.inputType.map((e) => Object.assign({}, e, this.state.dynamicAssetName.find((e1) => e1.key === e.key) || {}));
        // });
      }

    }
    //Added this condition because when it is added in the below if condition it won't work so to make it work assigning step2 to 'step2'-Sonali
    if (step2 === 'rfpCampaign') {
      step2 = 'step2'
    }

    if (step2 === 'undefined' || step2 === null || step2 === "" || step2 === undefined || step2 !== 'step2') {
      // if (step2 === 'undefined' || step2 === null || step2 === "" || step2 === undefined ||step2!=='rfpCampaign' || step2!=='step2'){


      return (
        /*@author Sanobar Golandaj
        supporting document fields */
        <div style={{ scrollBehavior: 'smooth' }}>
          <Navigation />
          {/* validation error message */}
          {/* validation error message */}
          {/* <div className="col" style={{paddingTop:"90px"}} align="center"><label id="labelheading" >STEP 2 : Supporting Documents(Campaign ID: {parsed.campID})</label></div> */}
          {/* {'welcomePage?campID='+campID+'&step1&fromBack=true'} */}
          <div class="row" style={{ paddingTop: "90px", paddingBottom: '6px', backgroundColor: 'white', border: '1px solid', borderColor: '#bfc7cc', width: '110%', position: 'fixed', zIndex: '1' }}>
            <div class=" col-lg-3" style={{ paddingLeft: '40px', color: "#164495" }}><a href="#"><FaArrowAltCircleLeft size={32} style={{ float: 'left', color: "#164495" }} title="Back to Welcome Page" onClick={this.handleBackButton} /></a></div>
            <div class="col-lg-5" ><label class="labelfont" style={{ color: "#164495", fontSize: '18px', paddingLeft: '55px' }}>STEP 2 : Supporting Documents (Campaign ID:{parsed.campID})</label></div>{/*sunita-task-3113-added campaign ID as per requirement */}
            <div class=" col-lg-4" class="createbutton"><button class="btn add-button" type="submit" form="supportingdocument">Save & Next</button>
            </div>

          </div>
          <div style={{ overflowX: "hidden" }}>
            <div class="col-lg-12" className="displayerrorsupp" style={{ display: this.state.alertDisplay }}>
              <div class="container-fluid">
                {/* <div c
                lass="card card-signin my-1"> */}
                <div class="card-body" >
                  {/* <div class="row"> */}
                  <div class="col-lg-12"
                    style={{ border: this.state.alertBorder }}>

                    {/* <li style={{fontSize:'16px',color:'red'}}>Please fill all below required fields</li> */}
                    <div className="errorMessage" style={{ fontSize: '17px' }}>

                      {this.state.errors.campaignBudget ? this.state.errors.campaignBudget : ''}
                      {this.state.errors.assetFileName ? this.state.errors.assetFileName : ''}
                      {this.state.errors.assetLinkName ? this.state.errors.assetLinkName : ''}
                      {this.state.asseterrors.AssetName ? this.state.asseterrors.AssetName : ''}
                      {this.state.errors.assetlinkcount ? this.state.errors.assetlinkcount : ''}
                      {this.state.asseterrors.assetlinkcountLink ? this.state.asseterrors.assetlinkcountLink : ''}
                      {this.state.asseterrors.singleAsset ? this.state.asseterrors.singleAsset : ''}
                      {this.state.asseterrors.TouchError ? this.state.asseterrors.TouchError : ''}
                      {this.state.asseterrors.assetFiles ? this.state.asseterrors.assetFiles : ''}
                      {this.state.abmerrors.abmFiles ? this.state.abmerrors.abmFiles : ''}
                      {this.state.backerror.assetUploadError ? this.state.backerror.assetUploadError : ''}
                      {/* Sanjana behalf of raunak-Task 3032-Display error if asset link or asset is not uploaded */}
                      {this.state.abmerrors.properabmFiles ? this.state.abmerrors.properabmFiles : ''}
                      {this.state.asseterrors.assetLinkFiles ? this.state.asseterrors.assetLinkFiles : ''}
                      {this.state.backerror.assetFiles1 ? this.state.backerror.assetFiles1 : ''}
                      {this.state.backabmerror.abmFiles1 ? this.state.backabmerror.abmFiles1 : ''}
                      {/* {this.state.backerrorasset.assetFiles1?this.state.backerrorasset.assetFiles1:''} */}
                      {this.state.asseterrors.assetFiles2 ? this.state.asseterrors.assetFiles2 : ''}
                      {this.state.asseterrors.assetLinkFilename ? this.state.asseterrors.assetLinkFilename : ''}
                      {this.state.errors.suppDocName ? this.state.errors.suppDocName : ''}
                      {this.state.errors.assetFiles ? this.state.errors.assetFiles : ''}
                      {this.state.errors.abmFiles ? this.state.errors.abmFiles : ''}
                      {this.state.leaderrors.assetCount ? this.state.leaderrors.assetCount : ''}
                      {this.state.leaderrors.AssetValidate ? this.state.leaderrors.AssetValidate : ''}
                      {this.state.leaderrors.leadPercentage ? this.state.leaderrors.leadPercentage : ''}

                      {this.state.errors.AssetWrongFile ? this.state.errors.AssetWrongFile : ''}
                      {this.state.errors.suppFileNameError ? this.state.errors.suppFileNameError : ''}
                      {this.state.errors.suppLinkError ? this.state.errors.suppLinkError : ''}
                      {this.state.backabmerror.properabmFiles1 ? this.state.backabmerror.properabmFiles1 : ''}
                      {this.state.errors.OneAsset ? this.state.errors.OneAsset : ''}

                      {/* {this.state.asseterrors.assetFiles2?this.state.asseterrors.assetFiles2:''} */}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                {/* </div> */}
              </div>
            </div>
            {/*  close error message*/}
            <br />
            <div className="container-fluid" style={{ paddingBottom: '60px' }}>
              <div className="">
                <form method="POST" name="SupportingDocument" id="supportingdocument" className="needs-validation" onSubmit={this.submitSupportingDocument} encType="multipart/form-data" noValidate>
                  {/* {/* <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> */}

                  <div class="card" >
                    <div className="card-body">
                    <div className="row">
                    <div class=" legend labelsubheading col-xs-12 col-sm-12 col-md-12 col-lg-4">
                          <h4 id="deliveryfor" style={{ color: "orange" }}>Delivery Format:</h4>
                        </div>

                        {/* style={{marginLeft:"555px"}} */}
                         <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 customMapFormatDiv" style={{float:'right'}}>   {/* snehal-changes for format and customize mapping */}
                        {/* //shivani-task 3085-reduced marginLeft from 650 to 555 to display Format label properly. */}
                          {(this.state.agencyCustomizeMapping).length > 0 ?
                            <input type="checkbox" id="" name="customizeMapping"
                              value={this.state.customizeMapping} onChange={(e)=>{this.handleChangeCheck(e),this.setcustomizeMapping(e)}} //Raunak-2951-customize mapping issue- created a new function for updating the value of customize mapping
                            /> : <input type="checkbox" id="" name="customizeMapping"
                              value={this.state.customizeMapping} onChange={this.handleChangeCheck} disabled
                            />

                          }
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Customize Mapping:&nbsp;&nbsp; <Tooltip title=" To Add Customize Mapping :  
                        Go to Campaign Menu and select Customize Mapping Option">
                            <Icon type="question-circle-o" style={{ marginLeft: '-4px', marginBottom: '-2px', padding: '1px', marginTop: '4px' }} />
                          </Tooltip></label>
                        </div>
                        {(this.state.agencyCustomizeMapping).length>0 ? <label id="label" class="formatCss">Format :</label>:""}&nbsp; {/*added by raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue   seprating it from complex part of code*/}  {/* snehal-changes for format and customize mapping */}
                        <span> {/*  class="col-xs-1 col-sm-1 col-md-1 col-lg-1" id="toolpop" style={{ marginLeft: '1125px', marginTop: '-30px' }}  changed by raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue- managing all the elements within the row  */}
                          {(this.state.agencyCustomizeMapping).length>0 ?
                            <div>
                              <Tooltip placement="right" title="Customize Mapping">
                                <Icon type="plus-circle" style={{ height: "15px", width: "15px",marginRight:'5px' }} data-toggle="modal" data-target="#customizeMappingAdd" onClick={this.customizeMappingFormatHandleChange} />
                              </Tooltip>
                              <div class="modal fade" id="customizeMappingAdd" role="dialog" data-backdrop="static" data-keyboard="false">
                                <div class="modal-dialog modal-md" >
                                  <div class="modal-content">
                                    <div class="modal-header custom-classPopup">
                                      <h4 style={{ color: '#ffffff' }}>Customize mapping
                <button type="button" class="cancel-button" data-dismiss="modal" style={{ fontSize: 15, color: '#ffffff', float: "right", position: ' relative' }} onClick={this.customizeMappingCancleHandleChange} >&times;</button>
                                      </h4>  
                                    </div><br /><br />
                                    <div align="center" style={{ overflowY: 'scroll', height: '350px', position: ' relative' }}>
                                      <table class='table table-bordered' id="myTable31" style={{ width: '85%', position: ' relative' }} >
                                        <thead>
                                          <tr>
                                            <th style={{ position: 'sticky', color: '#707070', height: '35px', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;System Field</th>
                                            <th style={{ position: 'sticky', color: '#707070', height: '35px', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Mapping Alias Field </th>
                                          </tr>
                                        </thead>
                                        {this.state.agencyCustomizeMapping.map(agencyCustomizeMapping => (
                                          <tbody>
                                            <tr>
                                              <td >&nbsp;Publisher ID :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.pID}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Campaign ID :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.campID}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Campaign Name :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.campaignName}
                                              </td>
                                            </tr>


                                            <tr>
                                              <td>&nbsp;Lead Interaction Date :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.leadInteractionDate}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;First Name :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.firstName}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Last Name :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias :{agencyCustomizeMapping.lastName}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Company Name :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.companyName}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;LinkedIn Company Name :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.linkedInCompanyName}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Email :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.email}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Work Phone :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.workPhone}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Work Phone Format :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.workPhoneFormat}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Job Title :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.jobTitle}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;LinkedIn Job Title :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.linkedInJobTitle}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Address :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.address}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Country :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.country}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;City :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.city}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;State :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.state}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Zip Code :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.zipCode}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Company Employee Size :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.companyEmployeeSize}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Company Revenue :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.companyRevenue}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Industry :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.industry}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Asset Name :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetName}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Asset Name Touch 1 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetNameTouch1}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Asset Timestamp Touch 1 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias :{agencyCustomizeMapping.assetTimestampTouch1}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Asset Name Touch 2 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetNameTouch2}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Asset Timestamp Touch 2 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetTimestampTouch2}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Asset Name Touch 3 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetNameTouch3}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Asset Timestamp Touch 3 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetTimestampTouch3}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Street :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.street}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;IP :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.ip}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Asset ID :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.supportDocID}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Job Level :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.jobLevel}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Job Function :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.jobFunction}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Channel :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.channel}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 1 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra1}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 2 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra2}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 3 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra3}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 4 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra4}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 5 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra5}
                                              </td>
                                            </tr>
                                            {/* Sandeep-task-3158-added code for extra 15 fields from 6 to 20*/}
                                            <tr>
                                              <td>&nbsp;Extra 6 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra6}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>&nbsp;Extra 7 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra7}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 8 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra8}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>&nbsp;Extra 9 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra9}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>&nbsp;Extra 10 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra10}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 11 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra11}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 12 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra12}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 13 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra13}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 14 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra14}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 15 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra15}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 16 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra16}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 17 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra17}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 18 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra18}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 19 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra19}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Extra 20 :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra20}
                                              </td>
                                            </tr>
                                            


                                            <tr>
                                              <td>&nbsp;Domain :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.domain}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Alternate Phone No :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.alternatePhoneNo}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Comments :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.comments}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;LinkedIn :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.linkedIn}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;Channel :</td>
                                              <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.channel}
                                              </td>
                                            </tr>
                                          </tbody>
                                        ))}
                                      </table>
                                    </div><br /><br />
                                  </div>
                                </div>
                              </div>
                            </div>
                            : " "}
                        </span> {/*changed by raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue*/}
                        {(this.state.agencyCustomizeMapping).length > 0 ?
                        <>
                          {/* // <div class="col-xs-6 col-sm-6 col-md-6 col-lg-2 format-cust" style={{ float:'right' }}> */}
                            {/* marginLeft: '1059px',marginTop: '-26px' */}
                          <select id="format" name="format" class="form-control inputStyle frmt" style={{ height: '34px', width: '104px', borderColor: 'lightgray'}} value={this.state.customizeMappingFormat} onChange={this.handleFormatChange}>
                              {this.state.agencyCustomizeMapping.map(agencyCustomizeMapping => {
                                return (
                                  <option value={this.state.sdEditDetail.customizeMappingFormat} >{agencyCustomizeMapping.format}</option>
                                )
                              })}
                            </select>
                          </> : ""}
                      </div>
                      {/* end of first row */}
                      <hr />
                      {/* Sandeep-task-3195-added col-md-4 for medium device */}
                      <div class="row">
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="pID" name="pID"
                            value={this.state.pID} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Publisher ID</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="campaignID" name="campaignID"
                            value={this.state.campaignID} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Campaign ID</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="reAllocationID"
                            value={this.state.reAllocationID} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Allocation ID</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="campaignName"
                            value={this.state.campaignName} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Campaign Name</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="leadInteractionDate" name="leadInteractionDate"
                            value={this.state.leadInteractionDate} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Lead interaction date</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="countryDelivery" name="countryDelivery"
                            value={this.state.countryDelivery} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Country</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="companyName" name="companyName"
                            value={this.state.companyName} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Company name</label>
                        </div>


                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="linkedIn"
                            value={this.state.linkedIn} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">LinkedIn</label>
                        </div>


                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="email" name="email"
                            value={this.state.email} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Email</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="ip" name="ip"
                            value={this.state.ip} onChange={this.handleChangeCheck}
                          />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">IP</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="workPhone" name="workPhone"
                            value={this.state.workPhone} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Work phone</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="state"
                            value={this.state.state} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">State</label>
                        </div>

                         {/* Sandeep-task-3185----change in position for street  */}
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="street"
                            value={this.state.street} onChange={this.handleChangeCheck} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Street</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          {this.state.jobTitleValidation === 'Yes' ?
                            <input type="checkbox" id="jobTitleValidation" name="jobTitleValidation"
                              onChange={this.handleChangeCheck}
                              defaultChecked disabled /> :
                            <input type="checkbox" id="jobTitleValidation" name="jobTitleValidation"
                              onChange={this.handleChangeCheck} disabled
                            />
                          }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Job title validation</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="linkedInCompanyName" name="linkedInCompanyName"
                            value={this.state.linkedInCompanyName} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">LinkedIn company name</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="assetId" name="assetId"
                            value={this.state.assetId} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Asset ID</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="domain"
                            value={this.state.domain} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Domain</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="alternatePhoneNo"
                            value={this.state.alternatePhoneNo} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Alternate phone no</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="zipCode"
                            value={this.state.zipCode} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Zip code</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          {this.state.companyEmployeeSize === 'Yes' ?
                            <input type="checkbox" id="companyEmployeeSize" name="companyEmployeeSize"
                              value={this.state.companyEmployeeSize} onChange={this.handleChangeCheck}
                              defaultChecked disabled /> :
                            <input type="checkbox" id="companyEmployeeSize" name="companyEmployeeSize"
                              value={this.state.companyEmployeeSize} onChange={this.handleChangeCheck} disabled
                            />
                          }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Company employee size</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="linkedInJobTitle" name="linkedInJobTitle"
                            value={this.state.linkedInJobTitle} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">LinkedIn job title </label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="assetName"
                            value={this.state.assetName} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Asset name</label>
                        </div>


                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="firstName" name="firstName"
                            value={this.state.firstName} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">First name</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="jobTitleDelivery" name="jobTitleDelivery"
                            value={this.state.jobTitleDelivery} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Job title </label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="address" name="address"
                            value={this.state.address} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Address</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          {this.state.companyRevenue === 'Yes' ?
                            <input type="checkbox" id="companyRevenue" name="companyRevenue"
                              onChange={this.handleChangeCheck}
                              defaultChecked disabled /> : <input type="checkbox" id="companyRevenue" name="companyRevenue"
                                onChange={this.handleChangeCheck} disabled
                            />
                          }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Company revenue</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          {this.state.industry === 'Yes' ?
                            <input type="checkbox" id="" name="industry"
                              value={this.state.industry} onChange={this.handleChangeCheck}
                              defaultChecked disabled /> :
                            <input type="checkbox" id="" name="industry"
                              value={this.state.industry} onChange={this.handleChangeCheck} disabled
                            />
                          }
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Industry</label>
                        </div>


                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="comments"
                            value={this.state.comments} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Comments</label>
                        </div>



                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="lastName" name="lastName"
                            value={this.state.lastName} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Last name</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          {this.state.jobLevelDelivery === 'Yes' ?
                            <input type="checkbox" id="jobLevelDelivery" name="jobLevelDelivery"
                              onChange={this.handleChangeCheck}
                              defaultChecked disabled /> : <input type="checkbox" id="jobLevelDelivery" name="jobLevelDelivery"
                                onChange={this.handleChangeCheck} disabled
                            />
                          }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Job level</label>

                        </div>
                          {/* Sandeep-task-3185----change in position for job function  */}
                        <div class="col-md-4 col-lg-2">
                          {this.state.jobFunctionDelivery === 'Yes' ?
                            <input type="checkbox" id="jobFunctionDelivery" name="jobFunctionDelivery"
                              onChange={this.handleChangeCheck}
                              defaultChecked disabled /> :
                            <input type="checkbox" id="jobFunctionDelivery" name="jobFunctionDelivery"
                              onChange={this.handleChangeCheck} disabled
                            />
                          }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Job function</label>
                        </div>
                      
                     
                        
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="city"
                            value={this.state.city} onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">City</label>
                        </div>
                        
                     
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra1"
                            value={this.state.extra1} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 1</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra2"
                            value={this.state.extra2} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 2</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra3"
                            value={this.state.extra3} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 3</label>
                        </div>


                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra4"
                            value={this.state.extra4} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 4</label>
                        </div>

                      

                      




                      

                     
                    
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra5"
                            value={this.state.extra5} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 5</label>
                        </div>
                        {/* Sandeep-task-3158-added code for extra 15 fields from 6 to 20*/}
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra6"
                            value={this.state.extra6} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 6</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra7"
                            value={this.state.extra7} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 7</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra8"
                            value={this.state.extra8} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 8</label>
                        </div>
            
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra9"
                            value={this.state.extra9} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 9</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra10"
                            value={this.state.extra10} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 10</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra11"
                            value={this.state.extra11} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 11</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra12"
                            value={this.state.extra12} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 12</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra13"
                            value={this.state.extra13} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 13</label>

                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra14"
                            value={this.state.extra14} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 14</label>
                        </div>

                        <div class=" col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra15"
                            value={this.state.extra15} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 15</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra16"
                            value={this.state.extra16} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 16</label>
                        </div>



                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra17"
                            value={this.state.extra17} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 17</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra18"
                            value={this.state.extra18} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 18</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra19"
                            value={this.state.extra19} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 19</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra20"
                            value={this.state.extra20} onChange={this.handleChangeCheck}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 20</label>
                        </div>

                      



                     

                     


                        {/* <div class="col-lg-2">
                {(this.state.agencyCustomizeMapping).length>0?
              <input type="checkbox" id="" name="customizeMapping"
                value={this.state.customizeMapping} onChange={this.handleChangeCheck}
                />:<input type="checkbox" id="" name="customizeMapping"
                value={this.state.customizeMapping} onChange={this.handleChangeCheck} disabled
                />
                }
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Customize Mapping</label>
              </div> */}

                      </div>

                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card" style={{ backgroundColor: 'rgb(251, 249, 249)', border: '0px solid white' }}>
                        <div className="card-body">
                          <div className="legend labelsubheading col-xs-6 col-sm-6 col-md-2 col-lg-2" style={{ padding: 0 }}>
                            {/* Sandeep-task-3195-added id to add css */}
                            <h4 id="quality" style={{ color: 'orange', fontFamily: 'roboto' }}>Quality Assurance </h4><hr />
                          </div>

                          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <label id="label">DI QA</label>&nbsp;&nbsp;
                             {/* sandeep-task-2933 added code to handle DI QA help Option */}
                             <Tooltip placement="right" title="This option will divert all the leads to DI-QA for review after submission from Publisher. All the leads will be visible to Agency only after DI-QA review completion."><Icon type="question-circle" />
                                    </Tooltip>
  <Switch size="small" onChange={this.switchHandlechange} checked={this.state.switchValue == "ON" ? true : false} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card" style={{ backgroundColor: 'rgb(251, 249, 249)', border: '0px solid white' }}>
                        <div className="card-body">
                          <div className="legend labelsubheading">
                            <h4 style={{ color: 'orange', fontFamily: 'roboto' }}>Upload Supporting Documents </h4><hr />
                          </div>
                          <div class="card col-lg-3">
                            <div className="card-body">

                              {this.state.campaignType == 'ABM' ?
                                <div class="row">
                                  <div class="col-lg-5">
                                    {/* <label><span style={asterisk}>*</span> &nbsp;ABM:</label> */}
                                    <label>ABM:<span style={asterisk}>* </span></label> &nbsp;
                        <Tooltip placement="right" title="The ABM file must have first 2 columns with the column heading as “Account Name” & “Domain Name” "><Icon type="question-circle" />
                                    </Tooltip>
                                  </div>
                                  <div class="col-lg-7">
                                    <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                                      <img src="upload.png" alt="upload" style={{ height: '25px', width: '25px' }} onClick={(e) => { this.handleChangeMultipleFileOfABM() }} />
                                      {/*removed input type file -raunak */}

                                    </div>
                                  </div>
                                </div>

                                : <div class="row">
                                  <div class="col-lg-5">
                                    <label>ABM:</label>
                                    {/* sandeep-task-2933-tooltip title changed. */}
                                    <Tooltip placement="right" title="The ABM file must have one column with header name as “Account Name” OR “Domain Name” "><Icon type="question-circle" />
                                    </Tooltip>
                                  </div>


                                  <div class="col-lg-7">
                                    <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                                      <img src="upload.png" alt="upload" style={{ height: '25px', width: '25px' }} onClick={(e) => { this.handleChangeMultipleFileOfABM() }} />
                                      {/*removed input type file -raunak */}

                                    </div>
                                  </div>
                                </div>
                              }



                              <div id="noabmList" style={{ display: "none" }}>0 ABM Files Selected</div>
                              <div class="table-responsive-lg" style={{ width: '100%' }}
                                style={{ display: this.state.displayTableabm }}
                              >
                                <br />
                                <div id="abmMessage" style={{ color: 'green' }}>
                                </div>
                                <div style={{ color: 'red' }}>{this.state.abmerror.abmerr1}</div>
                                <div id={"abmError"} style={{ color: 'red' }}></div>
                                <table
                                  id="myTable"
                                  className=" table table-bordered table-striped "
                                  style={{ width: '100%', border: '0px solid white' }}
                                >



                                  <tbody class="tbody" style={{ height: 'auto' }}>
                                    <div id="abmList" className="word-wrap">
                                    </div>

                                    <div id="files">
                                    </div>


                                  </tbody>
                                </table>

                              </div>
                              {/* from here */}

                            </div>
                          </div>

                          <div class="card col-lg-3">
                            <div className="card-body">
                              <div class="row">
                                <div class="col-lg-6">
                                  <label>Exclusion:</label>
                                  <Tooltip placement="right" title="Exclusion file can contain 1 column Domain"><Icon type="question-circle" />
                                  </Tooltip>
                                </div>

                                <div class="col-lg-6">
                                  <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                                    <img src="upload.png" alt="upload" style={{ height: '25px', width: '25px' }} onClick={(e) => { this.handleChangeMultipleFileOfExclusion() }} />
                                    {/*removed input type file -raunak */}

                                  </div>
                                </div>
                              </div>
                              <div id="noexclusionList" style={{ display: 'none' }}>0 Exclusion file Selected</div>
                              <div class="table-responsive-lg" style={{ width: '100%' }} style={{ display: this.state.displayTableexclusion }}>
                                <br />
                                <div id="exclusionMessage" style={{ color: 'green' }}></div>
                                <div id="exclusionMessage"></div>
                                <div style={{ color: 'red' }}>{this.state.exclusionerror.exclusionerr}</div>
                                <div id={"exclusionError"} style={{ color: 'red' }}></div>
                                <table
                                  id="myTable"
                                  className=" table table-bordered table-striped "
                                  style={{ width: '100%', border: '0px solid white' }}
                                >
                                  <tbody class="tbody" style={{ height: 'auto' }}>

                                    <div id="exclusionList" className="word-wrap"></div>


                                  </tbody></table>

                              </div>
                            </div>
                          </div>



                          <div class="card col-lg-3">
                            <div className="card-body">
                              <div class="row">
                                <div class="col-lg-6">
                                  <label>Suppression:</label>
                                  <Tooltip placement="right" title="Suppersion file can contain 1 column Email"><Icon type="question-circle" />
                                  </Tooltip>
                                </div>

                                <div class="col-lg-6">
                                  <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                                    <img src="upload.png" alt="upload" style={{ height: '25px', width: '25px' }} onClick={this.handleChangeMultipleFileOfSuppression} />
                                    {/*removed input type file -raunak */}
                                  </div>
                                </div>
                              </div>

                              <div id="nosuppressionList" style={{ display: "none" }}>0 Suppression Files Selected</div>
                              <div class="table-responsive-lg" style={{ width: '100%' }} style={{ display: this.state.displayTablesuppression }}>
                                <br />
                                < div id="suppresionMessage" style={{ color: 'green' }}></div>
                                <div style={{ color: 'red' }}>{this.state.suppressionerror.suppressionerr}</div>
                                <div id={"suppressionError"} style={{ color: 'red' }}></div>
                                <table
                                  id="myTable"
                                  className=" table table-bordered table-striped "
                                  style={{ width: '100%', border: '0px solid white' }}
                                >
                                  <tbody class="tbody" style={{ height: 'auto' }}>
                                    <div id="suppressionList" className="word-wrap"></div>

                                  </tbody></table>

                              </div>
                            </div>
                          </div>


                          <div class="card col-lg-3">
                            <div className="card-body">
                              <div class="row">
                                <div class="col-lg-6">
                                  <label>Other:</label>
                                </div>


                                <div class="col-lg-6">
                                  <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                                    <img src="upload.png" alt="upload" style={{ height: '25px', width: '25px' }} onClick={this.handleChangeMultipleFileOfOther} />
                                    {/*removed input type file -raunak */}
                                  </div>
                                </div>
                              </div>
                              <div id="nootherList" style={{ display: "none" }}>0 Other Files Selected</div>
                              <div class="table-responsive-lg" style={{ width: '100%' }} style={{ display: this.state.displayTableother }}>
                                <br />
                                <div id="otherMessage" style={{ color: 'green' }}></div>
                                <div id="otherMessage"></div>
                                <table
                                  id="myTable"
                                  className=" table table-bordered table-striped "
                                  style={{ width: '100%', border: '0px solid white' }}
                                >
                                  <tbody class="tbody" style={{ height: 'auto' }}>
                                    <div id="otherList" className="word-wrap"></div>

                                  </tbody> </table>

                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="card" style={{ backgroundColor: 'rgb(251, 249, 249)', border: '0px solid white' }}>
                        <div className="card-body">
                          <div class="row">
                            <div className=" col-lg-6 legend labelsubheading">
                              <h4 style={{ color: 'orange', fontFamily: 'roboto' }}>Upload Campaign Asset &nbsp;<span style={asterisk}>*</span> &nbsp;</h4>
                            </div>
                            <div class="col-lg-6">
                              <div style={{ float: 'right', color: 'black', fontSize: '13px', fontWeight: '400' }}>
                              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                <input type="checkbox" id="multitouchChk" name="multitouchChk"
                                  value={this.state.multiTouchChk} onChange={this.multiTouchHandleChange} /><span id="multi" style={{marginRight:"75px"}}>Require Multitouch Campaign</span>
                           <input type="checkbox" id="leadPerAssetChk" name="leadPerAssetChk"
                                  value={this.state.leadPerAssetChk} onChange={this.leadPerAssethandleChange} /> <span> Require Asset viz leads</span></div>
                            </div>
                          </div>
                          <hr />
                          <div>

                            <div class="row">
                              <div class="col-lg-6">
                                <label style={{ color: 'black', fontWeight: '500', fontFamily: 'roboto' }}>Asset:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                              </div>
                              <div class="col-lg-6">
                                <div style={{ color: 'black', float: 'right', fontWeight: '500', fontFamily: 'roboto' }} >&nbsp;Total leads :{this.state.totalLeadAllocation}

                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12">

                                <div class="form-group files">
                                  <input type="file" class="pointer" style={{ color: 'transparent', paddingLeft: '46.5%' }} id="asset" name="assetFiles" multiple
                                    onChange={this.handleChangeMultipleFile}
                                    required />

                                </div>
                                <div id="noassetList" style={{ display: 'none' }}>
                                  0 Asset Files Selected
                                 </div>
                              </div>

                            </div>



                            <div class="table-responsive-lg" style={{ width: '100%' }} style={{ display: this.state.displayTable }}>
                              <table
                                id="myTable"
                                className=" table table-bordered table-striped "
                                style={{ width: '100%' }}
                              >
                                <thead class="thead">
                                  <tr class="info tr">

                                    <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto' }}>
                                      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                        Selected Files</div>
                                    </th>

                                    <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto' }}>
                                      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Asset Name</div>

                                    </th>
                                    {this.state.leadPerAssetChk === 'Yes' ?
                                      <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '100px', fontFamily: 'roboto' }}>
                                        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead %</div>

                                      </th> : ""}
                                    {this.state.leadPerAssetChk === 'Yes' ?
                                      <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '100px', fontFamily: 'roboto' }}>
                                        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead/Asset</div>

                                      </th> : ""}
                                    {this.state.multiTouchChk === 'Yes' ?
                                      <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '138px', fontFamily: 'roboto' }}>
                                        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Multitouch</div>

                                      </th> : ""}
                                  </tr>
                                </thead>
                                <tbody class="tbody" style={{ height: 'auto' }}>
                                  <tr class="tr">
                                    <td class="campId">
                                      {this.state.sdEditDetail.map(sdEditDetail => {
                                        if (sdEditDetail.typeOfSuppDoc == 'Asset') {
                                          return (

                                            <table style={{ width: '100%' }}>
                                              <tr >


                                                <td style={{ backgroundColor: "#daecda", border: '1px solid #ffffff', paddingBottom: '0px' }}>
                                                  <img src="success.png" alt="sucess" style={{ height: '18px', width: '18px', float: 'left' }} />                                                                                      &nbsp;
  <div  id="fileName" style={{
                                                    textAlign: "left", fontWeight: "500", fontSize: "12px !important", fontFamily: "roboto", display: "inline-block", width: "215px",
                                                    whiteSpace: "nowrap", overFlow: "hidden", textOverflow: "ellipsis", float: "left", color: "green",
                                                  }}>
                                                 {sdEditDetail.suppDocName}<br /><br />
                                                  </div>


                                                  <a href="#" style={{ float: 'right', fontFamily: 'roboto', fontSize: '12px', fontWeight: '500' }} class="" onClick={this.dynamicABMFile}
                                                    id={sdEditDetail.typeOfSuppDoc + " " + sdEditDetail.supportDocID}
                                                    name={sdEditDetail.suppDocName} status={sdEditDetail.status} >
                                                    Remove</a>

                                                </td>

                                              </tr>


                                            </table>)
                                        }
                                      })}
                                      <span id="assetList" class="word-wrap" style={{ fontSize: '13px' }}></span>
                                    </td>
                                    <td class="campId" >
                                      {this.state.inputType.map((inputType, i) => {
                                        return (
                                          <span key={inputType.suppDocName} >

                                            <input value={this.state.assetFileName} defaultValue={this.state.inputType[i].suppDocName} onChange={this.handleChangeAssetFileName.bind(this, i)}
                                              type="text" id={inputType.key} class="form-control" placeholder="Asset Name" name="assetFileName" style={{ borderRadius: '1px', height: 42, margin: '1.5px' }} required />

                                          </span>)
                                      })}
                                    </td>
                                    {this.state.leadPerAssetChk === 'Yes' ?
                                      <td class="campId" style={{ width: '100px' }}>{this.state.inputType.map((inputType, i) => {
                                        return (
                                          <span key={inputType.suppDocName} >
                                            <input value={this.state.leadPercentage} placeholder="Lead %" onChange={this.handleChangeAssetFileName.bind(this, i)}
                                              type="text" id={inputType.key} class="form-control" name="leadPercentage" style={{ borderRadius: '1px', margin: '1.5px', height: 42 }} required />
                                          </span>)
                                      })}
                                      </td> : ''}

                                    {this.state.leadPerAssetChk === 'Yes' ?
                                      <td class="campId" style={{ width: '100px' }}>
                                        {this.state.inputType.map((inputType, i) => {
                                          return (
                                            <span key={inputType.suppDocName} >
                                              <input value={inputType.leadPerAsset}
                                                type="text" id={inputType.key} class="form-control" name="leadPerAsset" style={{ borderRadius: '1px', margin: '1.5px', height: 42 }} required disabled />
                                            </span>)
                                        })}
                                      </td> : ''}
                                    {this.state.multiTouchChk === "Yes" ? <td style={{ width: '138px' }}>
                                      {this.state.inputType.map((inputType, i) => {
                                        return (
                                          <span style={{ color: '#999999' }}> <select id={inputType.key} value={inputType.multiTouch} name="multiTouch" onChange={this.handleChangeAssetFileName.bind(this, i)} style={{ borderRadius: '1px', margin: '1.5px', height: 42 }}>

                                            <option value="1st Touch" selected="selected">1st Touch</option>
                                            <option value="2nd Touch" >2nd Touch</option>
                                            <option value="3rd Touch" >3rd Touch</option>
                                          </select></span>)
                                      })}</td> : ''}
                                  </tr>
                                </tbody>

                              </table>
                              <div style={{ color: 'red' }}>{this.state.fileerrors.FileError}</div>
                              <div id="assetMessage" style={{ color: 'green', fontSize: '13px' }}></div>
                            </div>

                            <div><hr /></div>

                            <div>
                              <img src="attached.png" alt="attached" height="23px" width="24px" style={{ float: 'left' }} />&nbsp;&nbsp;
                            <label style={{ color: 'black', fontWeight: '500', fontFamily: 'roboto' }}>Asset Link:&nbsp;
                            <a data-toggle="tooltip" title="Add one more link"><span class="glyphicon glyphicon-plus-sign"
                                  onClick={(e) => this.addLink(e)} style={{ color: "rgb(5, 110, 184)", fontWeight: 'bold', fontSize: 25 }}></span></a>
                              </label>
                            </div>

                            <div class="card">
                              <div class="card" style={{maxHeight: "300px",overflowY: "auto"}}>
                                <div class="table-responsive-lg" style={{ width: '100%' }} >
                                  {/* <label id="leadlabel" style={{display:'hide',fontSize:'0px'}}>Lead percentage(%)</label>
                              
                              
                              <label id="leadlabel" style={{display:'none',fontSize:'4px'}}>Lead/Asset</label> */}
                                  {/* leadAssetchk    {this.state.leadPerAssetChk}
                            MultiTouch   {this.state.multiTouchChk} */}
                                  <table
                                    id="myTable"
                                    className=" table table-bordered table-striped "
                                    style={{ width: '100%', border: '0px solid white' }}
                                  >
                                    {/* class="thead" */}
                                    <thead >
                                      <tr class="info tr">
                                        {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto', width: "30%" }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                              Asset Link Name</div>
                                          </th>
                                          :
                                          this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '20%', fontFamily: 'roboto' }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                Asset Link Name</div>
                                            </th>
                                            :
                                            this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                              <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto', width: "10%" }}>
                                                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                  Asset Link Name </div>
                                              </th> :
                                              // no one is checked
                                              <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto' }}>
                                                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                  Asset Link Name </div>
                                              </th>
                                        }
                                        {/* end of Selected Files */}

                                        {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "30%", fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>  Asset Link</div>
                                          </th>
                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '20%', fontFamily: 'roboto' }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Asset Link</div>
                                            </th>
                                            : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                              <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto', width: "10%" }}>
                                                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Asset Link</div>  </th>
                                              :
                                              //no one is checked
                                              <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto' }}>
                                                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Asset Link</div>  </th>
                                        }

                                        {/* end of Asset Name */}

                                        {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "30%", fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead %</div>

                                          </th>
                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "10%", fontFamily: 'roboto' }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead %</div>
                                            </th>
                                            : ""}
                                        {/* end of Lead % */}

                                        {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "30%", fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead/Asset</div></th>
                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "10%", fontFamily: 'roboto' }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead/Asset</div>
                                            </th> : ""}

                                        {/* end of Lead/Asset */}


                                        {this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '20%', fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Multitouch</div></th>
                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "10%", fontFamily: 'roboto' }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Multitouch</div>
                                            </th> : ""}
                                        {/* end of Multitouch */}


                                        {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '138px', fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Remove </div>

                                          </th>
                                          :
                                          this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '20%', fontFamily: 'roboto' }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Remove</div>

                                            </th> :
                                            this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                              <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '10%', fontFamily: 'roboto' }}>
                                                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Remove </div>

                                              </th> :
                                              //no one is clicked
                                              <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '138px', fontFamily: 'roboto' }}>
                                                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Remove</div>

                                              </th>

                                        }
                                      </tr>
                                    </thead>




                                    {/* style={{ maxHeight: "200px", display: "block", tableLayout: "fixed" }} */}
                                    <tbody class="tbody" style={{ height:'auto' }} >

                                      {/* <tr class="tr" style={{ textAlign: "center" }}> */}
                                        {/* Sandeep-task-3195-removed extra tr for responsiveness */}
                                        {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                          <td style={{ width: "280px" }}>
                                            {this.state.dynamicInput.map((dynamicInput, i) => {
                                              return (
                                                <div key={dynamicInput.count} style={{textAlign:'cenetr'}}>
                                                  <Input id="linkAssetFileName" name="linkAssetFileName" className={{}} placeholder="Enter Asset Link Name" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                                </div>
                                              )
                                            })}
                                          </td>
                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                            <td style={{ width: "317px" }}>

                                              {this.state.dynamicInput.map((dynamicInput, i) => {
                                                return (
                                                  <div key={dynamicInput.count}>
                                                    <Input id="linkAssetFileName" name="linkAssetFileName" className={{}} placeholder="Enter Asset Link Name" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                                  </div>
                                                )
                                              })}
                                            </td>

                                            : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                              <td style={{ width: "210px" }}>
                                                {this.state.dynamicInput.map((dynamicInput, i) => {
                                                  return (
                                                    <div key={dynamicInput.count}>
                                                      <Input id="linkAssetFileName" name="linkAssetFileName" className={{}} placeholder="Enter Asset Link Name" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '180px', borderRadius: '1px' }} />
                                                    </div>
                                                  )
                                                })}
                                              </td>
                                              :
                                              //no one is checked
                                              <td id="assetLink1" style={{ width: "575px" }}>
                                                {this.state.dynamicInput.map((dynamicInput, i) => {
                                                  return (
                                                    <div key={dynamicInput.count} style={{textAlign:'cenetr'}}>
                                                      <Input id="linkAssetFileName" name="linkAssetFileName"
                                                        className={{}} placeholder="Enter Asset Link Name"
                                                        onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                                    </div>
                                                  )
                                                })}
                                              </td>
                                        }


                                        {/* end of asset File */}

                                        {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                          <td style={{ width: "280px" }}>
                                            {this.state.dynamicInput.map((dynamicInput, i) => {
                                              return (
                                                <div key={dynamicInput.count} style={{textAlign:'cenetr'}}>
                                                  <Input id="assetLink" name="assetLink" placeholder="Enter Asset Link" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                                </div>
                                              )
                                            })}
                                          </td>

                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                            <td style={{ width: "317px" }}>
                                              {this.state.dynamicInput.map((dynamicInput, i) => {
                                                return (
                                                  <div key={dynamicInput.count}>
                                                    <Input id="assetLink" name="assetLink" placeholder="Enter Asset Link" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                                  </div>
                                                )
                                              })}
                                            </td> : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                              <td style={{ width: "210px" }}>
                                                {this.state.dynamicInput.map((dynamicInput, i) => {
                                                  return (
                                                    <div key={dynamicInput.count} style={{textAlign:'cenetr'}}>
                                                      <Input id="assetLink" name="assetLink" placeholder="Enter Asset Link" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                                    </div>
                                                  )
                                                })}
                                              </td> :
                                              //no one is checked
                                              <td id="assetLink1" style={{ width: "575px" }}>
                                                {this.state.dynamicInput.map((dynamicInput, i) => {
                                                  return (
                                                    <div key={dynamicInput.count}>
                                                      <Input id="assetLink" name="assetLink" placeholder="Enter Asset Link" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                                    </div>
                                                  )
                                                })}
                                              </td>
                                        }


                                        {/* end of Asset Link */}

                                        {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                          <td style={{ width: "280px" }}>
                                            {this.state.dynamicInput.map((dynamicInput, i) => {
                                              return (
                                                <div key={dynamicInput.count}>
                                                  <Input id="leadPercentage" name="leadPercentage" defaultValue={dynamicInput.leadPercentage} placeholder="Enter Lead %" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '120px', borderRadius: '1px' }} />
                                                </div>
                                              )
                                            })}
                                          </td>
                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                            <td style={{ width: "210px" }}>
                                              {this.state.dynamicInput.map((dynamicInput, i) => {
                                                return (
                                                  <div key={dynamicInput.count}>
                                                    <Input id="leadPercentage" name="leadPercentage" defaultValue={dynamicInput.leadPercentage} placeholder="Enter Lead %" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '180px', borderRadius: '1px' }} />
                                                  </div>
                                                )
                                              })}
                                            </td> :
                                            //no one checked
                                            ""}

                                        {/* end of leadPercentage */}

                                        {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                          <td style={{ width: "280px" }}>
                                            {this.state.dynamicInput.map((dynamicInput, i) => {
                                              return (
                                                <div key={dynamicInput.count}>
                                                  <Input id="leadPerAsset" name="leadPerAsset" value={dynamicInput.leadPerAsset} disabled style={{ width: '100px', borderRadius: '1px' }} />
                                                </div>
                                              )
                                            })}
                                          </td>
                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                            <td style={{ width: "210px" }}>
                                              {this.state.dynamicInput.map((dynamicInput, i) => {
                                                return (
                                                  <div key={dynamicInput.count}>
                                                    <Input id="leadPerAsset" name="leadPerAsset" value={dynamicInput.leadPerAsset} disabled style={{ width: '180px', borderRadius: '1px' }} />
                                                  </div>
                                                )
                                              })}
                                            </td>

                                            : ""}

                                        {/* end of leadPerAsset */}


                                        {this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                          <td style={{ width: "317px" }}>
                                            {this.state.dynamicInput.map((dynamicInput, i) => {
                                              return (
                                                <div key={dynamicInput.count}>
                                                  <select value={dynamicInput.multiTouch} style={{ borderRadius: '1px', margin: '1.5px', height: 32, border: '1px solid #d9d9d9', width: '120px' }} name="multiTouch" onChange={this.handleAssetLink.bind(this, i)}>
                                                    <option value="1st Touch" >1st Touch</option>
                                                    <option value="2nd Touch" >2nd Touch</option>
                                                    <option value="3rd Touch" >3rd Touch</option>
                                                  </select>
                                                </div>
                                              )
                                            })}
                                          </td>
                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                            <td style={{ width: "210px" }}>
                                              {this.state.dynamicInput.map((dynamicInput, i) => {
                                                return (
                                                  <div key={dynamicInput.count}>
                                                    <select value={dynamicInput.multiTouch} style={{ borderRadius: '1px', margin: '1.5px', height: 32, border: '1px solid #d9d9d9', width: '120px' }} name="multiTouch" onChange={this.handleAssetLink.bind(this, i)}>
                                                      <option value="1st Touch" >1st Touch</option>
                                                      <option value="2nd Touch" >2nd Touch</option>
                                                      <option value="3rd Touch" >3rd Touch</option>
                                                    </select>
                                                  </div>
                                                )
                                              })}
                                            </td>
                                            : ""}

                                        {/* end of multiTouch */}
                                        {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                          <td>
                                            {this.state.dynamicInput.map((dynamicInput, i) => {
                                              return (
                                                <div key={dynamicInput.count} style={{textAlign:'center'}}>
                                                  &nbsp;&nbsp; <a ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={() => this.removeLink(i)} style={{ color: "Red", fontWeight: 'bold', fontSize: '22px', border: "5px solid white" }}></span></a>
                                                </div>
                                              )
                                            })}
                                          </td>

                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                            <td >
                                              {this.state.dynamicInput.map((dynamicInput, i) => {
                                                return (
                                                  <div key={dynamicInput.count} style={{textAlign:'center'}}>
                                                    &nbsp;&nbsp; <a ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={() => this.removeLink(i)} style={{ color: "Red", fontWeight: 'bold', fontSize: '22px', border: "5px solid white" }}></span></a>
                                                  </div>
                                                )
                                              })}
                                            </td>
                                            : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                              <td style={{ width: "210px" }}>
                                                {this.state.dynamicInput.map((dynamicInput, i) => {
                                                  return (
                                                    <div key={dynamicInput.count} style={{textAlign:'center'}}>
                                                      &nbsp;&nbsp; <a ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={() => this.removeLink(i)} style={{ color: "Red", fontWeight: 'bold', fontSize: '22px', border: "5px solid white" }}></span></a>
                                                    </div>
                                                  )
                                                })}
                                              </td> :
                                              <td>
                                                {this.state.dynamicInput.map((dynamicInput, i) => {
                                                  return (
                                                    <div key={dynamicInput.count} style={{textAlign:'center'}}>
                                                      &nbsp;&nbsp; <a ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={() => this.removeLink(i)} style={{ color: "Red", fontWeight: 'bold', fontSize: '22px', border: "5px solid white" }}></span></a>
                                                    </div>
                                                  )
                                                })}
                                              </td>
                                        }
                                        {/* end of remove */}
                                      {/* </tr> */}



                                      {/* {this.state.dynamicInput.map((dynamicInput,i)=>{return(
                              <div key={dynamicInput.count}>
                               <p></p>
                              
                               <Input  id="linkAssetFileName" name="linkAssetFileName"  className={{}}  placeholder="Enter Asset File Name" onChange={this.handleAssetLink.bind(this,i)} style={{width:'240px',borderRadius:'1px'}}/>&nbsp;&nbsp;

                              <Input  id="assetLink" name="assetLink" placeholder="Enter Asset Link"   onChange={this.handleAssetLink.bind(this,i)} style={{width:'250px',borderRadius:'1px'}}/>&nbsp;&nbsp;
                              
                              {this.state.leadPerAssetChk==='Yes'?
                              <Input  id="leadPercentage"   name="leadPercentage" defaultValue={dynamicInput.leadPercentage}  placeholder="Enter Lead %"   onChange={this.handleAssetLink.bind(this,i)} style={{width:'120px',borderRadius:'1px'}}/>
                              :''}
                              &nbsp;&nbsp;
                              {this.state.leadPerAssetChk==='Yes'?
                              <Input  id="leadPerAsset"   name="leadPerAsset" value={dynamicInput.leadPerAsset} disabled style={{width:'100px',borderRadius:'1px'}}/>
                              :''}&nbsp;&nbsp;&nbsp;
 {this.state.multiTouchChk==='Yes'?<span style={{color:'#d9d9d9'}}> 
                             <select value={dynamicInput.multiTouch} style={{borderRadius:'1px',margin:'1.5px',height:32,border:'1px solid #d9d9d9',width:'120px'}} name="multiTouch" onChange={this.handleAssetLink.bind(this,i)}>
                            <option value="1st Touch" >1st Touch</option>
                            <option value="2nd Touch" >2nd Touch</option>
                            <option value="3rd Touch" >3rd Touch</option>
                            </select>
                              </span>:''}

                              &nbsp;&nbsp; <a ><span class="glyphicon glyphicon-minus-sign" id={i} onClick={()=>this.removeLink(i)} style={{color:"Red",fontWeight: 'bold',fontSize: '22px',borderRadius:'1px'}}></span></a>
                              
                             
                              </div>
                              )})} */}
                                    </tbody>
                                  </table>
                                </div>
                              </div>




                            </div>

                          </div>
                        </div>

                      </div>

                    </div>

                  </div>




































                  {/* </div> */}






                </form>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }//end of if Block
    else {

      return (
        /*@author Sanobar Golandaj
        supporting document fields */
        <div style={{ scrollBehavior: 'smooth' }}>
          <Navigation />
          <div class="row" style={{ paddingTop: "90px", paddingBottom: '6px', backgroundColor: 'white', border: '1px solid', borderColor: '#bfc7cc', width: '110%', position: 'fixed', zIndex: '1' }}>

            {/* {'welcomePage?campID='+campID+'&step1&fromBack=true'}                                     */}
            <div class=" col-lg-3" style={{ paddingLeft: '40px' }}><a href="#"><FaArrowAltCircleLeft size={32} style={{ float: 'left', color: "#164495" }} title="Back to Welcome Page" onClick={this.handleBackButton} /></a></div>
            <div class="col-lg-5" style={{ paddingLeft: '40px', color: "#164495" }}><label class="labelfont" style={{ color: "#164495", fontSize: '18px' }} >STEP 2 : Supporting Documents (Campaign ID:{parsed.campID})</label></div>{/*sunita-task-3113-added campaign ID as per requirement */}
            <div class=" col-lg-4" class="createbutton"><button class="btn add-button" title="scroll top" type="submit" form="supportingdocument" >Save & Next</button>
            </div>
          </div>
          <div style={{ overflowX: "hidden" }}>
            <div class="col-lg-12" className="displayerrorsupp" style={{ display: this.state.alertDisplay, scrollBehavior: 'smooth' }}>
              <div class="container-fluid" style={{ paddingBottom: '20px' }}>
                {/* <div class="card card-signin my-1"> */}
                <div class="card-body" >
                  {/* <div class="row"> */}
                  <div class="col-lg-12" style={{ border: this.state.alertBorder }}>


                    {/* <li style={{fontSize:'16px',color:'red'}}>Please fill all below required fields</li> */}
                    <div className="errorMessage" style={{ fontSize: '17px' }}>
                      {this.state.errors.budget ? this.state.errors.budget : ''}
                      {this.state.errors.suppDocName ? this.state.errors.suppDocName : ''}
                      {this.state.asseterrors.assetFiles ? this.state.asseterrors.assetFiles : ''}
                      {this.state.abmerrors.abmFiles ? this.state.abmerrors.abmFiles : ''}
                      {this.state.abmerrors.properabmFiles ? this.state.abmerrors.properabmFiles : ''}
                      {this.state.errors.assetCount ? this.state.errors.assetCount : ''}
                      {this.state.errors.AssetValidate ? this.state.errors.AssetValidate : ''}
                      {this.state.leaderrors.assetCount ? this.state.leaderrors.assetCount : ''}
                      {this.state.leaderrors.leadPercentage ? this.state.leaderrors.leadPercentage : ''}
                      {this.state.leaderrors.AssetValidate ? this.state.leaderrors.AssetValidate : ''}
                      {this.state.leadAsseterrors.assetsleadCount ? this.state.leadAsseterrors.assetsleadCount : ''}
                      {this.state.linkleadserrors.assetLinkerror ? this.state.linkleadserrors.assetLinkerror : ''}
                      {this.state.backerror.assetFiles1 ? this.state.backerror.assetFiles1 : ''}
                      {this.state.backabmerror.abmFiles1 ? this.state.backabmerror.abmFiles1 : ''}
                      {this.state.backerrorasset.assetFiles2 ? this.state.backerrorasset.assetFiles2 : ''}
                      {this.state.backerror.assetUploadError ? this.state.backerror.assetUploadError : ''}
                      {/* {this.state.backerrorasset.assetFiles1?this.state.backerrorasset.assetFiles1:''} */}
                      {this.state.errors.TouchErrorBack ? this.state.errors.TouchErrorBack : ''}
                      {this.state.backerror.OneAsset ? this.state.backerror.OneAsset : ''}
                      {this.state.errors.sameTouchBack ? this.state.errors.sameTouchBack : ''}
                      {this.state.linknameerror.linkname ? this.state.linknameerror.linkname : ''}
                      {this.state.errors.AssetWrongFile ? this.state.errors.AssetWrongFile : ''}
                      {this.state.errors.assetFiles ? this.state.errors.assetFiles : ''}
                      {this.state.errors.abmFiles ? this.state.errors.abmFiles : ''}
                      {this.state.errors.suppFileNameError ? this.state.errors.suppFileNameError : ''}
                      {this.state.errors.suppLinkError ? this.state.errors.suppLinkError : ''}
                      {this.state.backabmerror.properabmFiles1 ? this.state.backabmerror.properabmFiles1 : ''}
                    
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
              {/* </div> */}
            </div>

            <div className="container-fluid" style={{ paddingBottom: '60px' }}>
              <form method="POST" name="SupportingDocument" id="supportingdocument" className="needs-validation" onSubmit={this.submitSupportingDocument} encType="multipart/form-data" noValidate>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div class=" legend labelsubheading col-xs-12 col-sm-12 col-md-12 col-lg-4"> {/*changed by raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue- changed it for proper arrangment of elements*/}
                        <h4 id="deliveryfor" style={{ color: "orange" }}>Delivery Format:</h4>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 customMapFormatDiv"style={{ float:'right'}}>  {/*   added by raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue- changed it for proper arrangment of elements*/} {/* snehal-changes for format and customize mapping */}
                        {(this.state.agencyCustomizeMapping).length > 0 ?
                          <input type="checkbox" id="" name="customizeMapping"
                            value={this.state.customizeMapping} onChange={(e)=>{this.handleChecked(e),this.setcustomizeMapping(e)}}//Raunak-2951-customize mapping issue- created a new function for updating the value of customize mapping
                          /> : <input type="checkbox" id="" name="customizeMapping"
                            value={this.state.customizeMapping} onChange={this.handleChecked} disabled
                          />
                        }
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Customize Mapping:&nbsp;&nbsp;<Tooltip title=" To Add Customize Mapping :  
                        Go to Campaign Menu and select Customize Mapping Option">
                          <Icon type="question-circle-o" style={{ marginLeft: '-4px', marginBottom: '-2px', padding: '1px', marginTop: '4px' }} />
                        </Tooltip></label>

                      </div>
                      {(this.state.agencyCustomizeMapping).length>0 ?<label  id="label" class="formatCss">Format :</label>:""} &nbsp;  {/* snehal-changes for format and customize mapping */}
                      <span> {/* class="col-xs-1 col-sm-1 col-md-1 col-lg-1" id="toolpop" style={{ marginLeft: '1125px', marginTop: '-30px' }} added by raunak 2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue- changed it for proper arrangment of elements*/}
                        {(this.state.agencyCustomizeMapping).length>0 ?
                          <div>
                            <Tooltip placement="right" title="Customize Mapping">
                              <Icon type="plus-circle" style={{ height: "15px", width: "15px" ,marginRight:'5px'}} data-toggle="modal" data-target="#customizeMappingAdd" onClick={this.customizeMappingFormatHandleChange} />
                            </Tooltip>
                            <div class="modal fade" id="customizeMappingAdd" role="dialog" data-backdrop="static" data-keyboard="false">
                              <div class="modal-dialog modal-md" >
                                <div class="modal-content">
                                  <div class="modal-header custom-classPopup">
                                    <h4 style={{ color: '#ffffff' }}>Customize mapping
                <button type="button" class="cancel-button" data-dismiss="modal" style={{ fontSize: 15, color: '#ffffff', float: "right", position: ' relative' }}>&times;</button>
                                    </h4>
                                  </div><br /><br />
                                  <div align="center" style={{ overflowY: 'scroll', height: '350px', position: ' relative' }}>
                                    <table class='table table-bordered' id="myTable31" style={{ width: '85%', position: ' relative' }} >
                                      <thead>
                                        <tr>
                                          <th style={{ position: 'sticky', color: '#707070', height: '35px', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;System Field</th>
                                          <th style={{ position: 'sticky', color: '#707070', height: '35px', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Mapping Alias Field </th>
                                        </tr>
                                      </thead>
                                      {this.state.agencyCustomizeMapping.map(agencyCustomizeMapping => (
                                        <tbody>
                                          <tr>
                                            <td >&nbsp;Publisher ID :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.pID}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Campaign ID :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.campID}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Campaign Name :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.campaignName}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>&nbsp;Lead Interaction Date :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.leadInteractionDate}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;First Name :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.firstName}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Last Name :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias :{agencyCustomizeMapping.lastName}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Company Name :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.companyName}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;LinkedIn Company Name :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.linkedInCompanyName}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Email :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.email}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Work Phone :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.workPhone}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Work Phone Format :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.workPhoneFormat}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Job Title :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.jobTitle}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;LinkedIn Job Title  :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.linkedInJobTitle}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Address :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.address}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Country :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.country}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;City :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.city}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;State :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.state}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Zip Code :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.zipCode}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Company Employee Size :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.companyEmployeeSize}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Company Revenue :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.companyRevenue}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Industry :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.industry}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Asset Name :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetName}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Asset Name Touch 1 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetNameTouch1}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Asset Timestamp Touch 1 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias :{agencyCustomizeMapping.assetTimestampTouch1}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Asset Name Touch 2 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetNameTouch2}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Asset Timestamp Touch 2 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetTimestampTouch2}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Asset Name Touch 3 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetNameTouch3}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Asset Timestamp Touch 3 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.assetTimestampTouch3}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Street :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.street}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;IP :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.ip}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Asset ID :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.supportDocID}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Job Level :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.jobLevel}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Job Function :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.jobFunction}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Channel :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.channel}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 1 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra1}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 2 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra2}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 3 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra3}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 4 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra4}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 5 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra5}
                                            </td>
                                          </tr>
                                          {/* Sandeep-task-3158-added code for extra 15 fields from 6 to 20 */}
                                          <tr>
                                            <td>&nbsp;Extra 6 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra6}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 7 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra7}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>&nbsp;Extra 8 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra8}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 9 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra9}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 10 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra10}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 11 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra11}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 12 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra12}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>&nbsp;Extra 13 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra13}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>&nbsp;Extra 14 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra14}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 15 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra15}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 16 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra16}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 17 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra17}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 18 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra18}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 19 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra19}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Extra 20 :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.extra20}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>&nbsp;Domain :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.domain}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Alternate Phone No :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.alternatePhoneNo}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Comments :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.comments}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;LinkedIn :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.linkedIn}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;Channel :</td>
                                            <td style={{ paddingBottom: "10px" }}>&nbsp;Alias : {agencyCustomizeMapping.channel}
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </table>
                                  </div><br /><br />
                                </div>
                              </div>
                            </div>
                          </div>
                          : " "}
                      </span>{/*raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue  managing all the elements within the row */}
                      {(this.state.agencyCustomizeMapping).length>0 ?
                        <> {/*style={{ marginLeft: '1059px', marginTop: '-26px' }} added by raunak thakkar -2951-Prod Issue-Internal-Supporting Document Page-Custom mapping Format Dropdown issue  managing all the elements within the row  using react fragments*/}
                          
                       <select id="format" name="format" class="form-control inputStyle frmt" style={{ height: '34px', width: '104px', borderColor: 'lightgray', marginLeft: '11px'}} value={this.state.customizeMappingFormat} onChange={this.handleFormatChange}>
                             {this.state.agencyCustomizeMapping.map(agencyCustomizeMapping => {
                              return (
                                <option value={this.state.sdEditDetail.customizeMappingFormat} >{agencyCustomizeMapping.format}</option>
                              )
                            })} 
                          </select>
                        </> : ""}
                    </div>
              (Following Checked Fields Will Be Mandatory For Lead Validation)&nbsp;&nbsp;
              <hr />

        {/* Sandeep-task-3195-added col-md-4 for medium device */}
                    <div class="row">

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="pID" name="pID"
                          value={this.state.pID} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Publisher ID</label>
                      </div>
                      <div class="col-md-4  col-lg-2">
                        <input type="checkbox" id="campaignID" name="campaignID"
                          value={this.state.campaignID} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Campaign ID</label>
                      </div>

                      <div class="col-md-4 col-lg-2">

                        {this.state.reAllocationID === 'Yes' ?
                          <input type="checkbox" id="" name="reAllocationID"
                            value={this.state.reAllocationID} onChange={this.handleChecked}
                          /> :
                          <input type="checkbox" id="" name="reAllocationID"
                            value={this.state.reAllocationID} onChange={this.handleChecked}
                          />
                        }
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Allocation ID</label>
                      </div>

                      <div class="col-md-4 col-lg-2">

{this.state.CampaignName === 'Yes' ?
  <input type="checkbox" id="" name="campaignName"
    value={this.state.campaignName} onChange={this.handleChecked}
  /> :
  <input type="checkbox" id="" name="campaignName"
    value={this.state.campaignName} onChange={this.handleChecked}
  />
}
&nbsp;&nbsp;&nbsp;&nbsp;
<label id="label">campaign Name</label>
</div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="leadInteractionDate" name="leadInteractionDate"
                          value={this.state.leadInteractionDate} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Lead interaction date</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="countryDelivery" name="countryDelivery"
                          value={this.state.countryDelivery} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Country</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="companyName" name="companyName"
                          value={this.state.companyName} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Company name</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="linkedIn"
                          value={this.state.linkedIn} onChange={this.handleChecked}
                        />

                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">LinkedIn</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="email" name="email"
                          value={this.state.email} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Email</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="ip" name="ip"
                          value={this.state.ip} onChange={this.handleChecked}
                        />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">IP</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="workPhone" name="workPhone"
                          value={this.state.workPhone} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Work phone</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="state"
                          value={this.state.state} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">State</label>
                      </div>
                    {/* Sandeep-task-3185---change in position for street */}
                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="street"
                          value={this.state.street} onChange={this.handleChecked} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Street</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        {this.state.jobTitleValidation === 'Yes' ?
                          <input type="checkbox" id="jobTitleValidation" name="jobTitleValidation"
                            onChange={this.handleChangeCheck}
                            defaultChecked disabled /> :
                          <input type="checkbox" id="jobTitleValidation" name="jobTitleValidation"
                            onChange={this.handleChangeCheck} disabled
                          />
                        }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Job title validation</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="linkedInCompanyName" name="linkedInCompanyName"
                          value={this.state.linkedInCompanyName} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">LinkedIn company name</label>
                      </div>

                      {/* <div class="col-lg-2">
                        <input type="checkbox" id="supportDocID" name="supportDocID"
                          value={this.state.assetId} onChange={this.handleChecked}
                        />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Asset ID</label>
                      </div> */}

                      {/* Sandeep-task-3194-added code for AssetId checkbox issue */}
                      <div class="col-md-4 col-lg-2">
                         {/* snehal-task-3172-pat/local--Agency side--Edit campaign-Step2--delivery format--checkbox issue */}
                        <input type="checkbox" id="assetId" name="assetId"
                          value={this.state.assetId} onChange={this.handleChecked}
                        />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Asset ID</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="domain"
                          value={this.state.domain} onChange={this.handleChecked}
                        />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Domain</label>
                      </div>

                      
                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="alternatePhoneNo"
                          value={this.state.alternatePhoneNo} onChange={this.handleChecked}
                        />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Alternate phone no</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="zipCode"
                          value={this.state.zipCode} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Zip code</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        {this.state.companyEmployeeSize === 'Yes' ?
                          <input type="checkbox" id="companyEmployeeSize" name="companyEmployeeSize"
                            value={this.state.companyEmployeeSize} onChange={this.handleChangeCheck}
                            defaultChecked disabled /> :
                          <input type="checkbox" id="companyEmployeeSize" name="companyEmployeeSize"
                            value={this.state.companyEmployeeSize} onChange={this.handleChangeCheck} disabled
                          />
                        }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Company employee size</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="linkedInJobTitle" name="linkedInJobTitle"
                          value={this.state.linkedInJobTitle} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">LinkedIn job title </label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="assetName"
                          value={this.state.assetName} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Asset name</label>
                      </div>



                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="firstName" name="firstName"
                          value={this.state.firstName} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">First name</label>
                      </div>

                      <div class=" col-md-4 col-lg-2">
                        <input type="checkbox" id="jobTitleDelivery" name="jobTitleDelivery"
                          value={this.state.jobTitleDelivery} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Job title</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="address" name="address"
                          value={this.state.address} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Address</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        {this.state.companyRevenue === 'Yes' ?
                          <input type="checkbox" id="companyRevenue" name="companyRevenue"
                            onChange={this.handleChangeCheck}
                            defaultChecked disabled />
                          : <input type="checkbox" id="companyRevenue" name="companyRevenue"
                            onChange={this.handleChangeCheck} disabled
                          />
                        }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Company revenue</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        {this.state.industry === 'Yes' ?
                          <input type="checkbox" id="" name="industry"
                            value={this.state.industry} onChange={this.handleChangeCheck}
                            defaultChecked disabled /> :
                          <input type="checkbox" id="" name="industry"
                            value={this.state.industry} onChange={this.handleChangeCheck} disabled
                          />
                        }
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Industry</label>
                      </div>


                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="comments"
                          value={this.state.comments} onChange={this.handleChecked}
                        />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Comments</label>
                      </div>



                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="lastName" name="lastName"
                          value={this.state.lastName} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <label id="label">Last name</label>
                      </div>
                    
                     
                      <div class="col-md-4 col-lg-2">
                        {this.state.jobLevelDelivery == 'Yes' ?
                          <input type="checkbox" id="jobLevelDelivery" name="jobLevelDelivery"
                            onChange={this.handleChecked}
                            defaultChecked disabled />
                          : <input type="checkbox" id="jobLevelDelivery" name="jobLevelDelivery"
                            onChange={this.handleChecked} disabled
                          />
                        }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Job level</label>
                      </div>
                      {/* Sandeep-task-3185----change in position for job function  */}
                      <div class="col-md-4 col-lg-2">
                        {this.state.jobFunctionDelivery == 'Yes' ?
                          <input type="checkbox" id="jobFunctionDelivery" name="jobFunctionDelivery"
                            onChange={this.handleChecked}
                            defaultChecked disabled /> :
                          <input type="checkbox" id="jobFunctionDelivery" name="jobFunctionDelivery"
                            onChange={this.handleChecked} disabled
                          />
                        }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">Job function</label>
                      </div>
                
                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="city"
                          value={this.state.city} onChange={this.handleChangeCheck}
                          defaultChecked disabled />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label id="label">City</label>
                      </div>
                  
                   
                    
                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="extra1"
                          value={this.state.extra1} onChange={this.handleChecked}
                        />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 1</label>
                      </div>
                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="extra2"
                          value={this.state.extra2} onChange={this.handleChecked}
                        />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 2</label>
                      </div>

                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="extra3"
                          value={this.state.extra3} onChange={this.handleChecked}
                        />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 3</label>
                      </div>


                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="extra4"
                          value={this.state.extra4} onChange={this.handleChecked}
                        />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 4</label>
                      </div>



               
                    


                    

                 
                      <div class="col-md-4 col-lg-2">
                        <input type="checkbox" id="" name="extra5"
                          value={this.state.extra5} onChange={this.handleChecked}
                        />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 5</label>
                      </div>

                      {/* Sandeep-task-3158-added code for extra 15 fields from 6 to 20*/}
                      <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra6"
                            value={this.state.extra6} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 6</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra7"
                            value={this.state.extra7} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 7</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra8"
                            value={this.state.extra8} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 8</label>
                        </div>
            
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra9"
                            value={this.state.extra9} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 9</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra10"
                            value={this.state.extra10} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 10</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra11"
                            value={this.state.extra11} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 11</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra12"
                            value={this.state.extra12} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 12</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra13"
                            value={this.state.extra13} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 13</label>

                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra14"
                            value={this.state.extra14} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 14</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra15"
                            value={this.state.extra15} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 15</label>
                        </div>
                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra16"
                            value={this.state.extra16} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 16</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra17"
                            value={this.state.extra17} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 17</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra18"
                            value={this.state.extra18} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 18</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra19"
                            value={this.state.extra19} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 19</label>
                        </div>

                        <div class="col-md-4 col-lg-2">
                          <input type="checkbox" id="" name="extra20"
                            value={this.state.extra20} onChange={this.handleChecked}
                          />
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <label id="label">Extra 20</label>
                        </div>

                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card" style={{ backgroundColor: 'rgb(251, 249, 249)', border: '0px solid white' }}>
                      <div className="card-body">
                        <div className="legend labelsubheading col-xs-6 col-sm-6 col-md-2 col-lg-2" style={{ padding: 0 }}>
                          <h4 style={{ color: 'orange', fontFamily: 'roboto' }}>Quality Assurance </h4><hr />
                        </div>

                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <label id="label">DI QA</label>&nbsp;&nbsp;
                           {/* sandeep-task-2933 added code to handle DI QA help Option */}
                           <Tooltip placement="right" title="This option will divert all the leads to DI-QA for review after submission from Publisher. All the leads will be visible to Agency only after DI-QA review completion."><Icon type="question-circle" />
                                    </Tooltip>
  <Switch size="small" onChange={this.switchHandlechange} checked={this.state.switchValue == "ON" ? true : false} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col-lg-12">
                    <div class="card" style={{ backgroundColor: 'rgb(251, 249, 249)', border: '0px solid white' }}>
                      <div className="card-body">
                        <div className="legend labelsubheading">
                          <h4 style={{ color: 'orange', fontFamily: 'roboto' }}>Upload Supporting Documents</h4><hr />
                        </div>
                        <div class="card col-lg-3">
                          <div className="card-body">

                            {this.state.campaignType == 'ABM' ?
                              <div class="row">
                                <div class="col-lg-6">
                                  {/* <label>&nbsp;ABM:<span style={asterisk}>*</span></label> */}
                                  <label>ABM:<span style={asterisk}>* </span></label> &nbsp;



                         {/* sandeep-task-2933-tooltip title changed. */}
                         <Tooltip placement="right" title="The ABM file must have one column with header name as “Account Name” OR “Domain Name” "><Icon type="question-circle" />
                                  </Tooltip>
                                </div>
                                <div class="col-lg-6">
                                  <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                                    <img src="upload.png" alt="upload" style={{ height: '25px', width: '25px' }} onClick={(e) => { this.handleChangeMultipleFileOfABM() }} />
                                    {/*removed input type file -raunak */}

                                  </div>
                                </div>
                              </div> :
                              <div class="row">
                                <div class="col-lg-6">
                                  <label>ABM:</label>
                                  {/* sandeep-task-2933-tooltip title changed. */}
                                  <Tooltip placement="right" title="The ABM file must have one column with header name as “Account Name” OR “Domain Name” "><Icon type="question-circle" />
                                    </Tooltip>
                                </div>
                                <div class="col-lg-6">
                                  <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                                    <img src="upload.png" alt="upload" style={{ height: '25px', width: '25px' }} onClick={(e) => { this.handleChangeMultipleFileOfABM() }} />
                                    {/*removed input type file -raunak */}

                                  </div>
                                </div>
                              </div>}

                            {(this.state.sdEditDetailABM.length >= 1)
                              ? <div id="noabmList" style={{ display: "none" }}>{this.state.sdEditDetailABM.length} ABM Files Selected</div>
                              : <div id="noabmList" style={{ display: "none" }}>0 ABM Files Selected</div>}

                            <div class="table-responsive-lg" style={{ width: '100%' }}
                              style={{ display: this.state.displayTableabm }}
                            >
                              {/* <br/> */}
                              <div id="abmMessage" style={{ color: 'green' }}>
                              </div>
                              <div style={{ color: 'red' }}>{this.state.abmerror.abmerr1}</div>
                              <div id={"abmError"} style={{ color: 'red' }}></div>
                              <table
                                id="myTable"
                                className=" table table-bordered table-striped "
                                style={{ width: '100%', border: '0px solid white' }}
                              >



                                <tbody class="tbody" style={{ height: 'auto' }}>
                                  <div id="abmList" className="word-wrap">
                                  </div>
                                  <div id="files">
                                  </div>
                                  {this.state.sdEditDetail.map(sdEditDetail => {
                                    if (sdEditDetail.typeOfSuppDoc == 'ABM') {
                                      return (

                                        <table>
                                          <tr>
                                            <td style={{ backgroundColor: "#daecda", border: '1px solid #ffffff', paddingBottom: '0px' }}>
                                              <img src="success.png" alt="sucess" style={{ height: '18px', width: '18px', float: 'left' }} />
                                           &nbsp;

                                        <div id="fileName" style={{ color: 'green', display: 'inline-block', width: '260px', fontSize: '13px', whiteSpace: ' nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'roboto', fontWeight: '500' }}>
                                                {sdEditDetail.suppDocName}

                                                {/* <br/><br/> */}
                                              </div>

                                              <div id="fileName" style={{ color: 'green', display: 'inline-block', width: '260px', fontSize: '13px', whiteSpace: ' nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'roboto', fontWeight: '500' }}>
                                                <span>ABM Count:</span>{sdEditDetail.fileRecordCount}
                                              </div>


                                              <a href="#" style={{ float: 'right', fontFamily: 'roboto', fontSize: '13px', fontWeight: '500' }}
                                                class="" onClick={this.dynamicABMFile} id={sdEditDetail.typeOfSuppDoc + " " + sdEditDetail.supportDocID} name={sdEditDetail.suppDocName}>Remove</a></td>

                                          </tr></table>)
                                    }
                                  })}




                                </tbody>

                              </table>

                            </div>


                          </div>
                        </div>

                        <div class="card col-lg-3">
                          <div className="card-body">
                            <div class="row">
                              <div class="col-lg-6">
                                <label>Exclusion:</label>
                                <Tooltip placement="right" title="Exclusion file can contain 1 column Domain"><Icon type="question-circle" />
                                </Tooltip>
                              </div>

                              <div class="col-lg-6">
                                <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                                  <img src="upload.png" alt="upload" style={{ height: '25px', width: '25px' }} onClick={(e) => { this.handleChangeMultipleFileOfExclusion() }} />
                                  {/*removed input type file -raunak */}
                                </div>
                              </div>
                            </div>
                            {(this.state.sdEditDetailExclusion.length >= 1)
                              ? <div id="noexclusionList" style={{ display: "none" }}>{this.state.sdEditDetailExclusion.length} Exclusion Files Selected</div>
                              : <div id="noexclusionList" style={{ display: "none" }}>0 Exclusion Files Selected</div>
                            }


                            {/* <div id="exclusionList"></div> */}

                            <div class="table-responsive-lg" style={{ width: '100%' }}
                              style={{ display: this.state.displayTableexclusion }}
                            >
                              {/* <br/> */}
                              <div id="exclusionMessage" style={{ color: 'green' }}></div>
                              {/* <div id="exclusionMessage"></div>  */}
                              <div style={{ color: 'red' }}>{this.state.exclusionerror.exclusionerr}</div>
                              <div id={"exclusionError"} style={{ color: 'red' }}></div>
                              <table
                                id="myTable"
                                className=" table table-bordered table-striped "
                                style={{ width: '100%', border: '0px solid white' }}
                              >
                                <tbody class="tbody" style={{ height: 'auto' }}>

                                  <div id="exclusionList" className="word-wrap"></div>
                                  {this.state.sdEditDetail.map(sdEditDetail => {
                                    if (sdEditDetail.typeOfSuppDoc == 'Exclusion') {
                                      return (
                                        <table>
                                          <tr>
                                            <td style={{ backgroundColor: "#daecda", border: '1px solid #ffffff', paddingBottom: '0px' }}>
                                              <img src="success.png" alt="sucess" style={{ height: '18px', width: '18px', float: 'left' }} />
                                                   &nbsp;

                                                <div id="fileName" style={{ color: 'green', display: 'inline-block', width: '260px', fontSize: '13px', whiteSpace: ' nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'roboto', fontWeight: '500' }}>
                                                {sdEditDetail.suppDocName}

                                                <br /><br />
                                              </div>
                                              <a href="#" style={{ float: 'right', fontFamily: 'roboto', fontSize: '13px', fontWeight: '500' }}
                                                class="" onClick={this.dynamicABMFile} id={sdEditDetail.typeOfSuppDoc + " " + sdEditDetail.supportDocID} name={sdEditDetail.suppDocName} >Remove</a></td>

                                          </tr></table>)
                                    }
                                  })}

                                  {/* // <div id="fileName"><a href="#"  */}
                                  {/* // ><i class="fa fa-times" onClick={this.dynamicABMFile} id={sdEditDetail.typeOfSuppDoc+" "+sdEditDetail.supportDocID} name={sdEditDetail.suppDocName}/></a>{sdEditDetail.suppDocName}
                                                  // </div>)
                                            //   }
                                            // })}
                                                 */}

                                  {/* <div id={"exclusionError"} style={{color:'red'}}></div> */}

                                </tbody></table></div>
                          </div>
                        </div>



                        <div class="card col-lg-3">
                          <div className="card-body">
                            <div class="row">
                              <div class="col-lg-6">
                                <label>Suppression:</label>
                                <Tooltip placement="right" title="Suppersion file can contain 1 column Email"><Icon type="question-circle" />
                                </Tooltip>
                              </div>

                              <div class="col-lg-6">
                                <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                                  <img src="upload.png" alt="upload" style={{ height: '25px', width: '25px' }} onClick={this.handleChangeMultipleFileOfSuppression} />
                                  {/*removed input type file -raunak */}
                                </div>
                              </div>
                            </div>

                            {(this.state.sdEditDetailSuppression.length >= 1)
                              ? <div id="nosuppressionList" style={{ display: "none" }}> {this.state.sdEditDetailSuppression.length} Suppression Files Selected</div>
                              : <div id="nosuppressionList" style={{ display: "none" }}>0 Suppression Files Selected</div>
                            }
                            {/* <div id="nosuppressionList">0 Suppression Files Selected</div> */}
                            <div class="table-responsive-lg" style={{ width: '100%' }}
                              style={{ display: this.state.displayTablesuppression }}
                            >

                              <div id="suppresionMessage" style={{ color: 'green' }}></div>
                              <div style={{ color: 'red' }}>{this.state.suppressionerror.suppressionerr}</div>
                              <div id={"suppressionError"} style={{ color: 'red' }}></div>
                              <table
                                id="myTable"
                                className=" table table-bordered table-striped "
                                style={{ width: '100%', border: '0px solid white' }}
                              >
                                <tbody class="tbody" style={{ height: 'auto' }}>
                                  <div id="suppressionList" className="word-wrap"></div>

                                  {this.state.sdEditDetail.map(sdEditDetail => {
                                    if (sdEditDetail.typeOfSuppDoc == 'Suppression') {
                                      return (

                                        <table>
                                          <tr>
                                            <td style={{ backgroundColor: "#daecda", border: '1px solid #ffffff', paddingBottom: '0px' }}>
                                              <img src="success.png" alt="sucess" style={{ height: '18px', width: '18px', float: 'left' }} />
                                             &nbsp;

                                          <div id="fileName" style={{ color: 'green', display: 'inline-block', width: '260px', fontSize: '13px', whiteSpace: ' nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'roboto', fontWeight: '500' }}>
                                                {sdEditDetail.suppDocName}

                                                <br /><br />
                                              </div>
                                              <a href="#" style={{ float: 'right', fontFamily: 'roboto', fontSize: '13px', fontWeight: '500' }}
                                                class="" onClick={this.dynamicABMFile} id={sdEditDetail.typeOfSuppDoc + " " + sdEditDetail.supportDocID} name={sdEditDetail.suppDocName}>Remove</a></td>

                                          </tr></table>)
                                    }
                                  })}


                                </tbody></table></div>
                          </div>
                        </div>


                        <div class="card col-lg-3">
                          <div className="card-body">
                            <div class="row">
                              <div class="col-lg-6">
                                <label>Other:</label>
                              </div>


                              <div class="col-lg-6">
                                <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                                  <img src="upload.png" alt="upload" style={{ height: '25px', width: '25px' }} onClick={this.handleChangeMultipleFileOfOther} />
                                  {/*removed input type file -raunak */}
                                </div>
                              </div>
                            </div>

                            {(this.state.sdEditDetailOther.length >= 1)
                              ? <div id="nootherList" style={{ display: "none" }}>{this.state.sdEditDetailOther.length} Other Files Selected</div>
                              : <div id="nootherList" style={{ display: "none" }}>0 Other Files Selected</div>
                            }
                            {/* <div id="otherList">0 Other Files Selected</div> */}
                            <div class="table-responsive-lg" style={{ width: '100%' }}
                              style={{ display: this.state.displayTableother }}
                            >
                              {/* <br/> */}
                              <table
                                id="myTable"
                                className=" table table-bordered table-striped "
                                style={{ width: '100%', border: '0px solid white' }}
                              >
                                <tbody class="tbody" style={{ height: 'auto' }}>
                                  <div id="otherList" className="word-wrap"></div>
                                  {this.state.sdEditDetail.map(sdEditDetail => {
                                    if (sdEditDetail.typeOfSuppDoc == 'Other') {
                                      return (
                                        <table>
                                          <tr>
                                            <td style={{ backgroundColor: "#daecda", border: '1px solid #ffffff', paddingBottom: '0px' }}>
                                              <img src="success.png" alt="sucess" style={{ height: '18px', width: '18px', float: 'left' }} />
                                           &nbsp;

                                        <div id="fileName" style={{ color: 'green', display: 'inline-block', width: '260px', fontSize: '13px', whiteSpace: ' nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'roboto', fontWeight: '500' }}>
                                                {sdEditDetail.suppDocName}

                                                <br /><br />
                                              </div>
                                              <a href="#" style={{ float: 'right', fontFamily: 'roboto', fontSize: '13px', fontWeight: '500' }}
                                                class="" onClick={this.dynamicABMFile} id={sdEditDetail.typeOfSuppDoc + " " + sdEditDetail.supportDocID} name={sdEditDetail.suppDocName}>Remove</a></td>

                                          </tr></table>)
                                    }
                                  })}



                                  <div id="otherMessage" style={{ color: 'green' }}></div>
                                  <div id="otherMessage"></div>
                                </tbody> </table></div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="card" style={{ backgroundColor: 'rgb(251, 249, 249)', border: '0px solid white' }}>
                      <div className="card-body">
                        <div class="row">
                        <div className="col-lg-6 legend labelsubheading">
                          <h4 style={{ color: 'orange', fontFamily: 'roboto' }}>Upload Campaign Asset &nbsp;<span style={asterisk}>*</span> &nbsp;</h4>
                          </div>
                          <div  class="col-lg-6">
                           <div style={{ float: 'right', color: 'black', fontSize: '13px', fontWeight: '400' }}>
                              <input type="checkbox" id="multitouchChkBack" name="multitouchChkBack"
                                value={this.state.multiTouchChkBack} onChange={this.multiTouchHandleChangeBack} /><span id="multi" style={{marginRight:"75px"}}>Require Multitouch Campaign</span>
                              <input type="checkbox" id="leadPerAssetChk" name="leadPerAssetChk"
                                checked={this.state.checkedValue}
                                value={this.state.leadPerAssetChk} onChange={this.leadPerAssethandleChangeBack} />

                                 <span> Require Asset viz leads</span></div>
                                 </div>
                                 </div>

                          <hr />
                          <div class="row">
                            <div class="col-lg-6">
                              <label style={{ color: 'black', fontWeight: '500', fontFamily: 'roboto' }}>Asset:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            </div>
                            <div class="col-lg-6">
                              <div style={{ color: 'black', float: 'right', fontWeight: '500', fontFamily: 'roboto' }} >&nbsp;Total leads :{this.state.totalLeadAllocation}
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group files">
                                <input type="file" class="pointer" style={{ color: 'transparent' }} id="asset" name="assetFiles" multiple
                                  onChange={this.handleChangeMultipleFile}
                                  required />
                              </div>
                              {(this.state.sdEditDetailAsset.length >= 1)
                                ? <div id="noassetList" style={{ display: 'none' }}>{this.state.sdEditDetailAsset.length} Asset Files Selected</div>
                                : <div id="noassetList" style={{ display: 'none' }}>0 Asset Files Selected</div>
                              }
                            </div>
                          </div>



                          <div class="table-responsive-lg" style={{ width: '100%' }} style={{ display: this.state.displayTable }}>
                            <table
                              id="myTable"
                              className=" table table-bordered table-striped "
                              style={{ width: '100%' }}
                            >
                              <thead class="thead">
                                <tr class="info tr">

                                  <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto' }}>
                                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                      Selected Files</div>
                                  </th>

                                  <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto' }}>
                                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Asset Name</div>

                                  </th>
                                  {this.state.leadPerAssetChk === 'Yes' ?
                                    <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '100px', fontFamily: 'roboto' }}>
                                      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead %</div>

                                    </th> : ""}
                                  {this.state.leadPerAssetChk === 'Yes' ?
                                    <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '100px', fontFamily: 'roboto' }}>
                                      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead/Asset</div>

                                    </th> : ""}
                                  {this.state.multiTouchChkBack === 'Yes' ?
                                    <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '115px', fontFamily: 'roboto' }}>
                                      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Multi Touch</div>

                                    </th> : ""}
                                </tr>
                              </thead>
                              <tbody class="tbody" style={{ height: 'auto' }}>
                                <tr class="tr" >
                                  <td class="campId">
                                    {this.state.sdEditDetail.map(sdEditDetail => {
                                      if (sdEditDetail.typeOfSuppDoc == 'Asset') {
                                        return (
                                          <table style={{ width: '100%' }}>
                                            <tr>
                                              <td style={{ backgroundColor: "#daecda", border: '1px solid #ffff', paddingBottom: '0px' }}>
                                                <img src="success.png" alt="success" style={{ height: '18px', width: '18px', float: 'left' }} />
                                                         &nbsp;

                                                      <span id="fileName" style={{ textAlign: 'left', color: 'green', display: 'inline-block', width: '215px', fontSize: '17px', whiteSpace: ' nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'roboto', fontWeight: '500', float: 'left', height: '36px' }}>
                                                  {sdEditDetail.suppDocName}

                                                  <br /><br />
                                                </span>
                                                <a href="#" style={{ float: 'right', fontFamily: 'roboto', fontSize: '17px', fontWeight: '500' }}
                                                  class="" onClick={this.dynamicABMFile}
                                                  id={sdEditDetail.typeOfSuppDoc + " " + sdEditDetail.supportDocID}
                                                  name={sdEditDetail.suppDocName} >Remove</a></td>

                                            </tr></table>)
                                      }
                                    })}
                                    <span id="assetList" className="word-wrap" style={{ fontSize: '13px' }} />
                                  </td>
                                  <td class="campId" >

                                    {this.state.inputType.map((inputType, i) => {
                                      return (
                                        <span key={inputType.suppDocName} >
                                          <input defaultValue={inputType.suppDocName} placeholder="Asset Name" onChange={this.handleChangeAssetFileName.bind(this, i)}
                                            type="text" id={inputType.key} class="form-control" name="assetFileName" required style={{ width: '200px,', borderRadius: '1px', height: 42, margin: '1.5px' }} />{/*3061-Raunak changing the name and id from "suppDocname" to "assetFileName" */}
                                        </span>)
                                    })}
                                  </td>
                                  {this.state.leadPerAssetChk === 'Yes' ?
                                    <td class="campId" style={{ width: '100px' }}>
                                      {this.state.inputType.map((inputType, i) => {
                                        return (
                                          <span key={inputType.suppDocName} >
                                            <input defaultValue={inputType.leadPercentage} placeholder="Lead %" onChange={this.handleChangeAssetFileName.bind(this, i)}
                                              type="text" id="leadPercentage" class="form-control" name="leadPercentage" style={{ borderRadius: '1px', height: 42, margin: '1.5px' }} required />
                                          </span>)
                                      })}
                                    </td> : ''}

                                  {this.state.leadPerAssetChk === 'Yes' ?
                                    <td class="campId" style={{ width: '100px' }}>
                                      {this.state.inputType.map((inputType, i) => {
                                        return (
                                          <span key={inputType.suppDocName} >
                                            <input defaultValue={inputType.leadPerAsset}
                                              type="text" id="leadPerAsset" class="form-control" name="leadPerAsset" style={{ borderRadius: '1px', height: 42, margin: '1.5px' }} onChange={this.handleChangeAssetFileName.bind(this, i)} disabled />
                                          </span>)
                                      })}
                                    </td> : ''}
                                  {this.state.multiTouchChkBack === "Yes" ? <td style={{ width: '115px' }}>
                                    {this.state.inputType.map((inputType, i) => {
                                      return (
                                        <span style={{ color: '#999999' }}> <select id={inputType.key} defaultValue={inputType.multiTouch}
                                          style={{ borderRadius: '1px', margin: '1.5px', height: 42 }} onChange={this.handleChangeAssetFileName.bind(this, i)} name="multiTouch">
                                          <option value="1st Touch" >1st Touch</option>
                                          <option value="2nd Touch" >2nd Touch</option>
                                          <option value="3rd Touch" >3rd Touch</option>
                                        </select></span>)
                                    })}</td> : ''}
                                </tr>
                              </tbody>

                            </table>
                            <div style={{ color: 'red' }}>{this.state.fileerrors.FileError}</div>
                            <div id="assetMessage" style={{ color: 'green', fontSize: '13px' }}></div>
                          </div>


                          <div><hr /></div>

                          <div>
                            <img src="attached.png" alt="attached" height="23px" width="24px" style={{ float: 'left' }} />&nbsp;&nbsp;
                            <label style={{ color: 'black', fontWeight: '500', fontFamily: 'roboto' }}> Asset Link:&nbsp;
                            <a data-toggle="tooltip" title="Add one more link"><span class="glyphicon glyphicon-plus-sign" onClick={(e) => this.backAddLink(e)} style={{ color: "rgb(5, 110, 184)", fontWeight: 'bold', fontSize: 25 }}></span></a>
                            </label>
                          </div>

                          <div class="card">
                            <div class="card" style={{maxHeight: "300px",overflowY: "auto"}}>
                              <div class="table-responsive-lg" style={{ width: '100%' }} >
                                {/* {this.state.multiTouchChkBack} */}
                                {/* <label id="leadlabel" style={{display:'none',fontSize:'0px'}}>Lead percentage(%)</label>
                               
                               {this.state.leadPerAssetChk==='Yes'?
                               <label id="leadlabel" style={{display:'none',fontSize:'4px'}}>Lead/Asset</label>:''} */}
                                <table
                                  id="myTable"
                                  className=" table table-bordered table-striped "
                                  style={{ width: '100%', border: '0px solid white' }}
                                >
                                  <thead>
                                    <tr class="info tr">
                                      {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                        <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto', width: "30%" }}>
                                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                            Asset Link Name</div>
                                        </th>
                                        :
                                        this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '20%', fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                              Asset Link Name</div>
                                          </th>
                                          :
                                          this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto', width: "10%" }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                Asset Link Name </div>
                                            </th> :
                                            // no one is checked
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto' }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                Asset Link Name </div>
                                            </th>
                                      }
                                      {/* end of Selected Files */}

                                      {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                        <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "30%", fontFamily: 'roboto' }}>
                                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>  Asset Link</div>
                                        </th>
                                        : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '20%', fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Asset Link</div>
                                          </th>
                                          : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto', width: "10%" }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Asset Link</div>  </th>
                                            :
                                            //no one is checked
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', fontFamily: 'roboto' }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Asset Link</div>  </th>
                                      }

                                      {/* end of Asset Name */}

                                      {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                        <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "30%", fontFamily: 'roboto' }}>
                                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead %</div>

                                        </th>
                                        : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "10%", fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead %</div>
                                          </th>
                                          : ""}
                                      {/* end of Lead % */}

                                      {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                        <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "30%", fontFamily: 'roboto' }}>
                                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead/Asset</div></th>
                                        : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "10%", fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Lead/Asset</div>
                                          </th> : ""}

                                      {/* end of Lead/Asset */}


                                      {this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                        <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '20%', fontFamily: 'roboto' }}>
                                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Multitouch</div></th>
                                        : this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: "10%", fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Multitouch</div>
                                          </th> : ""}
                                      {/* end of Multitouch */}


                                      {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChk === 'No' ?
                                        <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '138px', fontFamily: 'roboto' }}>
                                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Remove </div>

                                        </th>
                                        :
                                        this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                          <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '20%', fontFamily: 'roboto' }}>
                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Remove</div>

                                          </th> :
                                          this.state.multiTouchChk === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '10%', fontFamily: 'roboto' }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Remove </div>

                                            </th> :
                                            //no one is clicked
                                            <th style={{ backgroundColor: 'white', color: '#777', fontWeight: '400', width: '138px', fontFamily: 'roboto' }}>
                                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Remove</div>

                                            </th>

                                      }
                                    </tr>
                                  </thead>





                                  <tbody class="tbody" style={{ Height: "auto"}}>



                                    {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChkBack === 'No' ?
                                      <td style={{ width: "282px" }}>
                                        {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                          return (
                                            <div key={backDynamicInput.count} style={{textAlign:'cenetr'}}>
                                              &nbsp;&nbsp;<Input id="linkAssetFileName" name="linkAssetFileName" defaultValue={backDynamicInput.linkAssetFileName} className={{}} placeholder="Enter Asset Link Name" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                            </div>
                                          )
                                        })}
                                      </td>
                                      : this.state.multiTouchChkBack === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                        <td style={{ width: "317px" }}>

                                          {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                            return (
                                              <div key={backDynamicInput.count}>
                                                &nbsp;<Input id="linkAssetFileName" name="linkAssetFileName" defaultValue={backDynamicInput.linkAssetFileName} className={{}} placeholder="Enter Asset Link Name" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                              </div>
                                            )
                                          })}
                                        </td>

                                        : this.state.multiTouchChkBack === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                          <td style={{ width: "210px" }}>
                                            {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                              return (
                                                <div key={backDynamicInput.count}>
                                                  &nbsp;<Input id="linkAssetFileName" name="linkAssetFileName" defaultValue={backDynamicInput.linkAssetFileName} className={{}} placeholder="Enter Asset Link Name" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '180px', borderRadius: '1px' }} />
                                                </div>
                                              )
                                            })}
                                          </td>
                                          :
                                          //no one is checked
                                          <td id="assetLink1" style={{ width: "575px" }}>
                                            {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                              return (
                                                <div key={backDynamicInput.count} style={{textAlign:'cenetr'}}>
                                                  &nbsp; <Input id="linkAssetFileName" name="linkAssetFileName" defaultValue={backDynamicInput.linkAssetFileName} className={{}} placeholder="Enter Asset Link Name" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                                </div>
                                              )
                                            })}
                                          </td>
                                    }


                                    {/* end of asset File */}

                                    {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChkBack === 'No' ?
                                      <td style={{ width: "281px" }}>
                                        {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                          return (
                                            <div key={backDynamicInput.count} style={{textAlign:'cenetr'}}>
                                              &nbsp; <Input id="assetLink" name="assetLink" placeholder="Enter Asset Link" defaultValue={backDynamicInput.assetLink} onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                            </div>
                                          )
                                        })}
                                      </td>

                                      : this.state.multiTouchChkBack === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                        <td style={{ width: "317px" }}>
                                          {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                            return (
                                              <div key={backDynamicInput.count}>
                                                &nbsp;<Input id="assetLink" name="assetLink" placeholder="Enter Asset Link" defaultValue={backDynamicInput.assetLink} onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px', borderRadius: '1px' }} />
                                              </div>
                                            )
                                          })}
                                        </td> : this.state.multiTouchChkBack === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                          <td style={{ width: "211px" }}>
                                            {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                              return (
                                                <div key={backDynamicInput.count}>
                                                  &nbsp; <Input id="assetLink" name="assetLink" placeholder="Enter Asset Link" defaultValue={backDynamicInput.assetLink} onChange={this.handleAssetLink.bind(this, i)} style={{ width: '180px', borderRadius: '1px' }} />
                                                </div>
                                              )
                                            })}
                                          </td> :
                                          //no one is checked
                                          <td id="assetLink1" style={{ width: "575px" }}>
                                            {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                              return (
                                                <div key={backDynamicInput.count} style={{textAlign:'cenetr'}}>
                                                  &nbsp;<Input id="assetLink" name="assetLink" placeholder="Enter Asset Link" defaultValue={backDynamicInput.assetLink} onChange={this.handleAssetLink.bind(this, i)} style={{ width: '520px ', borderRadius: '1px' }} />
                                                </div>
                                              )
                                            })}
                                          </td>
                                    }


                                    {/* end of Asset Link */}

                                    {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChkBack === 'No' ?
                                      <td style={{ width: "283px" }}>
                                        {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                          return (
                                            <div key={backDynamicInput.count}>
                                              &nbsp; <Input id="leadPercentage" class="leadPerAssetBack" name="leadPercentage" placeholder="Enter Lead%" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '240px', borderRadius: '1px' }} />
                                            </div>
                                          )
                                        })}
                                      </td>
                                      : this.state.multiTouchChkBack === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                        <td style={{ width: "212px" }}>
                                          {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                            return (
                                              <div key={backDynamicInput.count}>
                                                &nbsp;<Input id="leadPercentage" class="leadPerAssetBack" name="leadPercentage" placeholder="Enter Lead%" onChange={this.handleAssetLink.bind(this, i)} style={{ width: '180px', borderRadius: '1px' }} />
                                              </div>
                                            )
                                          })}
                                        </td> :
                                        //no one checked
                                        ""}

                                    {/* end of leadPercentage */}

                                    {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChkBack === 'No' ?
                                      <td style={{ width: "281px" }}>
                                        {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                          return (
                                            <div key={backDynamicInput.count}>
                                              &nbsp; <Input id={backDynamicInput.count} class="leadPerAssetBack" ref="leadPerAssetBack" name="leadPerAsset" value={backDynamicInput.leadPerAsset} style={{ width: '240px', borderRadius: '1px' }} disabled />
                                            </div>
                                          )
                                        })}
                                      </td>
                                      : this.state.multiTouchChkBack === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                        <td style={{ width: "210px" }}>
                                          {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                            return (
                                              <div key={backDynamicInput.count}>
                                                &nbsp;<Input id={backDynamicInput.count} class="leadPerAssetBack" ref="leadPerAssetBack" name="leadPerAsset" value={backDynamicInput.leadPerAsset} style={{ width: '180px', borderRadius: '1px' }} disabled />
                                              </div>
                                            )
                                          })}
                                        </td>

                                        : ""}

                                    {/* end of leadPerAsset */}


                                    {this.state.multiTouchChkBack === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                      <td style={{ width: "317px" }}>
                                        {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                          return (
                                            <div key={backDynamicInput.count}>
                                              &nbsp; <select style={{ borderRadius: '1px', margin: '1.5px', height: 32, border: '1px solid #d9d9d9', width: '280px' }} defaultValue={backDynamicInput.multiTouch} name="multiTouch" onChange={this.handleAssetLink.bind(this, i)}>
                                                <option value="1st Touch" >1st Touch</option>
                                                <option value="2nd Touch" >2nd Touch</option>
                                                <option value="3rd Touch" >3rd Touch</option>
                                              </select>
                                            </div>
                                          )
                                        })}
                                      </td>
                                      : this.state.multiTouchChkBack === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                        <td style={{ width: "211px" }}>
                                          {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                            return (
                                              <div key={backDynamicInput.count}>
                                                &nbsp; <select style={{ borderRadius: '1px', margin: '1.5px', height: 32, border: '1px solid #d9d9d9', width: '180px' }} defaultValue={backDynamicInput.multiTouch} name="multiTouch" onChange={this.handleAssetLink.bind(this, i)}>
                                                  <option value="1st Touch" >1st Touch</option>
                                                  <option value="2nd Touch" >2nd Touch</option>
                                                  <option value="3rd Touch" >3rd Touch</option>
                                                </select>
                                              </div>
                                            )
                                          })}
                                        </td>
                                        : ""}

                                    {/* end of multiTouch */}
                                    {this.state.leadPerAssetChk === 'Yes' && this.state.multiTouchChkBack === 'No' ?
                                      <td style={{ width: "135px" }}>
                                        {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                          return (
                                            <div key={backDynamicInput.count} style={{textAlign:'center'}}>
                                               <a ><span class="glyphicon glyphicon-minus-sign" id={i} name={backDynamicInput.supportDocID} onClick={(e) => this.backRemoveLink(e, i)} style={{ color: "Red", fontWeight: 'bold', fontSize: '22px', border: "5px solid white" }}></span></a>
                                            </div>
                                          )
                                        })}
                                      </td>

                                      : this.state.multiTouchChkBack === 'Yes' && this.state.leadPerAssetChk === 'No' ?
                                        <td style={{ width: "317px" }} >
                                          {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                            return (
                                              <div key={backDynamicInput.count} style={{textAlign:'center'}}>
                                                
                                                <a ><span class="glyphicon glyphicon-minus-sign" id={i} name={backDynamicInput.supportDocID} onClick={(e) => this.backRemoveLink(e, i)} style={{ color: "Red", fontWeight: 'bold', fontSize: '22px', border: "5px solid white" }}></span></a>
                                              </div>
                                            )
                                          })}
                                        </td>
                                        : this.state.multiTouchChkBack === 'Yes' && this.state.leadPerAssetChk === 'Yes' ?
                                          <td style={{ width: "210px" }}>
                                            {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                              return (
                                                <div key={backDynamicInput.count} style={{textAlign:'center'}}>
                                                     <a ><span class="glyphicon glyphicon-minus-sign" id={i} name={backDynamicInput.supportDocID} onClick={(e) => this.backRemoveLink(e, i)} style={{ color: "Red", fontWeight: 'bold', fontSize: '22px', border: "5px solid white" }}></span></a>
                                                </div>
                                              )
                                            })}
                                          </td> :
                                          <td style={{ width: "135px" }}>
                                            {this.state.backDynamicInput.map((backDynamicInput, i) => {
                                              return (
                                                <div key={backDynamicInput.count} style={{textAlign:'center'}}>
                                                 <a ><span class="glyphicon glyphicon-minus-sign" id={i} name={backDynamicInput.supportDocID} onClick={(e) => this.backRemoveLink(e, i)} style={{ color: "Red", fontWeight: 'bold', fontSize: '22px', border: "5px solid white" }}></span></a>
                                                </div>
                                              )
                                            })}
                                          </td>
                                    }
                                    {/* end of remove */}

                                  </tbody>
                                </table>
                              </div>
                            </div>




                          </div>

                        {/* </div> */}
                      </div>

                    </div>

                  </div>

                </div>


              </form>
            </div>
          </div>
          <Footer />
        </div>




      );
    }
  }

}
/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
step2supportingdocument.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(step2supportingdocument));
