/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Somnath Keswad
@Creation Date:28-03-2019
@Description:UI for  GDPR Compliance report  under Agency view
*/
import React from "react";
import Navigation from "../layouts/navPage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { Table } from "mdbreact";
import Footer from "../layouts/footer";
import { FaArrowAltCircleLeft } from "react-icons/fa"; //Nilesh-4418-Back to home page arrow add
class ListGDPRPublisher extends React.Component {
	constructor() {
		super();
		this.state = {
			gdprList: [],
		};
		//     this.handleChange = this.handleChange.bind(this);
		this.gdprDetails = this.gdprDetails.bind(this);
	} // end of constrcutor

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			fetch("reports/gdprPublisher")
				.then((res) => res.json())
				.then((gdprList) =>
					this.setState({
						gdprList: gdprList,
					})
				);			
		}
	} // end of componentDidMount()

    //Nilesh-4418-Go to new page 
	gdprDetails(e) {
		e.preventDefault();
		var pID = e.target.getAttribute("pID");
		var publisherName = e.target.getAttribute("publisherName");
	  //	var reallocationID = e.target.getAttribute("reallocationId");
		this.props.history.push("/GDPRpublisherQueAns", {
			pID,
			publisherName,
		}); //
		// var pID = e.target.id;
		// const { user } = this.props.auth;
		//  window.location.href = '/GDPRpublisherQueAns?pID='+pID+'';

		//this.props.history.push("/GDPRpublisherQueAns", { agencyID: user.id }); //karan-task-3684-replace query params
	}

	 //Nilesh-4418-Back to home page
	 myReportBackButton(e) 
	 {
	 e.preventDefault();
	 this.props.history.push("/newdashboard")
	 }

	render() {
		// const { isAuthenticated, user } = this.props.auth;//kiran-2834-removing console warning-no used

		return (
			<div>
				<Navigation />
					{/* Nilesh-4418-Back to home page arrow and title add */}
                <div style={{ paddingBottom: '60px', paddingTop: '100px' }} class="container-fluid ">
				
				<div class= "row">
				<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
				{/* kiran-2834-removing console warning-added "#/" */}
					< a class="col" href='#/' style={{ color: '#056eb8', float: 'left', marginLeft: "-15px", marginBottom: "-38px" }} onClick={this.myReportBackButton.bind(this)} >
						<FaArrowAltCircleLeft size={32} title="Back to Dashboard" className="backFrom_myReport" />
					</a>
				</div>

				<div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" align="center">
					<center><label id="labelDI" align="center">List of GDPR Publishers</label></center>
				</div>
				</div>
{/* 
				<div
					class="container-fluid"
					style={{ paddingTop: "90px", marginBottom: "60px" }}> */}
					{/* <div> */}
						<div class="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div class="table-responsive">
									<Table
										className="table table-bordered"
										id="myTable">
										<thead>
											<tr class="custom-class">
												<th>Publisher ID</th>
												<th>Publisher Name</th>
												<th>Country</th>
												<th>Rating</th>
												<th>GDPR Compliance</th>
												<th>GDPR Details</th>
											</tr>
										</thead>
										<tbody>
											{this.state.gdprList.map((gdprList) => (
												<tr>
													<td>{gdprList.pID}</td>
													<td>{gdprList.publisherName}</td>
													<td> {gdprList.country}</td>
													<td> {gdprList.rating}</td>
													<td>{gdprList.gdprCompliance}</td>
													<td>
													{/* kiran-2834-removing console warning-added "#/" */}
														<a id={gdprList.pID} onClick={this.gdprDetails}
														href="#/" //karan-task-3895-link issue
														//onClick={this.displayCampaignSpecification}
														pID={gdprList.pID}
														publisherName={gdprList.publisherName}
														style={{ fontSize: "12px", width: "100px" }}
														>
															GDPR Details
														</a>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
								{/*end of row*/}
							</div>
							{/*end of card-body*/}
						</div>
						{/*Card-body */}
					{/* </div> */}
					{/*Card */}
				</div>
				{/* </div> */}
				{/*Container */}

				<Footer />
			</div>
		);
	} // end of renders
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
ListGDPRPublisher.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(ListGDPRPublisher)
);
