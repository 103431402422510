/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

import React from "react";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import "antd/dist/antd.css";

import { Comment, Avatar, Form, Button, List, Input } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3714-vapt header and query params
const { TextArea } = Input;
const queryString = require("query-string");
const CommentList = ({ comments }) => (
	<List
		dataSource={comments}
		itemLayout="horizontal"
		renderItem={(props) => <Comment {...props} />}
	/>
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
	<div>
		<Form.Item>
			<TextArea rows={4} onChange={onChange} value={value} />
		</Form.Item>
		<Form.Item>
			<Button
				htmlType="submit"
				loading={submitting}
				onClick={onSubmit}
				type="primary">
				Add Comment
			</Button>
		</Form.Item>
	</div>
);

class contactPublisher extends React.Component {
	constructor() {
		super();
		this.state = {
			// state = {
			pID: "",
			publisherList: [],
			// comments: ["Welcome to the chat"],
			comments: [
				{
					author: "Sanjana Godbole",
					avatar: "DI.png",
					content: <p>Welcome to chat</p>,
					datetime: moment().calendar(),
				},
			],
			submitting: false,
			value: "",
		};
		this.handleChangePublisherMessages =
			this.handleChangePublisherMessages.bind(this);
	}
	/* @author Narendra Phadke
	 * @param  Description get all messages of publisher wise
	 * @return Description return message
	 */
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			var parsed = queryString.parse(this.props.location.search);
			var campID = parsed.campID;

			const { user } = this.props.auth;
			let data = {
				campID: campID,
				user: user,
			};

			//This API is for publiher list
			fetch("/notification/agencyCampaignDetailsAndPublisherList", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((publisherList) => {
					//alert("Message history=="+JSON.stringify(publisherList));
					this.setState({
						publisherList: publisherList,
						visible: true,
						campID: campID,
					});
				})
				.catch(function (err) {
					console.log(err);
				});

			//This API is for publiher message history
			//  fetch("/notification/campaignSpecificationPublisherWiseMessageToAgency", {
			//    method: 'POST',
			//    headers: {'Content-Type': 'application/json'},
			//    body: JSON.stringify(data)
			//  }).then(res => res.json())
			//    .then(comments =>{
			//     // alert("Message history=="+JSON.stringify(comments));
			//      this.setState({ comments: comments,visible: true,campID: campID  })
			//    }).catch(function(err) {
			//      console.log(err)
			//  });
		}
	}

	handleSubmit = () => {
		if (!this.state.value) {
			return;
		}
		const { user } = this.props.auth;
		var campID = this.state.campID;
		var pID = this.state.pID;

		let data = {
			campID: campID,
			pID: pID,
			message: this.state.value,
		}; // karan-task-3714-vapt header and query params
		fetch("notification/agencySendMessageToPerticulaterPublisher", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((message) => {
				this.setState({ message: message, submitting: true });
			})
			.catch(function (err) {
				console.log(err);
			});

		//  this.setState({
		//    submitting: true
		//  });

		// alert("this.state.value==="+JSON.stringify(this.state.comments));
		setTimeout(() => {
			this.setState({
				submitting: false,
				value: "",
				value1: "",
				comments: [
					...this.state.comments,
					{
						author: <p>{user.firstName}</p>,
						avatar:
							"https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
						content: <p>{this.state.value}</p>,
						datetime: moment().calendar(),
					},
				],
			});
		}, 1000);
		// alert("this.state.value==="+JSON.stringify(this.state.comments));
	};

	handleChange = (e) => {
		this.setState({
			value: e.target.value,
		});
	};

	/**
	 * @author Narendra Phadke
	 * @param  Description handle Publisher Messages
	 * @return Description return Get perticular publisher message
	 */
	handleChangePublisherMessages(e) {
		var campID = e.target.id;
		var pID = e.target.name;
		const { user } = this.props.auth;

		let data = {
			campID: campID,
			pID: pID,
		}; // karan-task-3714-vapt header and query params
		// var parentCampID=e.target.getAttribute('parentCampID');
		// var reallocationID=e.target.getAttribute('reallocationID');
		//This API is for publiher message history
		fetch("/notification/agencyCampaignSpecificationOnePublisherMessages", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((comments) => {
				// alert("Message history=="+JSON.stringify(comments));
				this.setState({
					comments: comments,
					visible: true,
					campID: campID,
					pID: pID,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	render() {
		const verticalLine = {
			borderLeft: "1px solid black",
			height: "500px",
		};
		const { comments, submitting, value } = this.state;

		return (
			<div>
				<Navigation />
				<div class="container-fluid" style={{ paddingTop: "110px" }}>
					<div class="row">
						{this.state.publisherList.map((publisherList) => (
							<div>
								<label style={{ fontWeight: "500" }}>Campaign ID</label>&nbsp;
								{publisherList.campID}
								<label style={{ fontWeight: "500" }}>Campaign Name</label>&nbsp;
								{publisherList.campaignName}
								<label style={{ fontWeight: "500" }}>Campaign Start Date</label>
								&nbsp;{publisherList.startDate}
								<label style={{ fontWeight: "500" }}>Campaign End Date</label>
								&nbsp;{publisherList.endDate}
							</div>
						))}
						<div class="col-xs-3 col-sm-3 col-lg-3">
							<h3>Messenger</h3>
							{this.state.publisherList.map((publisherList) => (
								<div>
									{/* <div class=" col-md-6 col-lg-4"> */}
									{/* <label id="label"> <b> {publisherList.publisherName}</b></label>    */}
									<a
										href="#"
										id={publisherList.campID}
										name={publisherList.pID}
										// parentCampID={publisherList.}
										// reallocationID={liveCampaignList.reallocationID}
										// data-toggle="modal"
										// data-target="#myModal3"
										onClick={this.handleChangePublisherMessages}>
										{publisherList.publisherName}
									</a>
									{/* </div> */}
								</div>
							))}
						</div>
						<div class="col-xs-1 col-sm-1 col-lg-1">
							<div style={verticalLine}></div>
						</div>
						<div class="col-xs-8 col-sm-8 col-lg-8">
							<h3>Conversation Title</h3>
							{comments.length > 0 && <CommentList comments={comments} />}
							<Comment
								avatar={
									<Avatar
										src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
										alt="Han Solo"
									/>
								}
								content={
									<Editor
										onChange={this.handleChange}
										onSubmit={this.handleSubmit}
										submitting={submitting}
										value={value}
									/>
								}
							/>
						</div>
					</div>
				</div>
				{/* end of container */}

				<Footer />
			</div>
		);
	}
}
// export default contactPublisher;

/* @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
contactPublisher.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(contactPublisher)
);
