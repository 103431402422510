/* Copyright(c) 2021 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @author : Karan Jagtap
 * @description : A loader component for campaign specification pages
 */
import React from "react";

// loadingRef = call this when loading needs to start by using below line of code
// -- this.loadingRef.current.click();
// closeLoadingRef = call this when loading needs to stop by using below line of code
// -- this.closeLoadingRef.current.click();
function CampaignSpecificationLoader({ loadingRef, closeLoadingRef, message }) {
	return (
		<div>
			<button
				ref={loadingRef}
				style={{ display: "none" }}
				type="button"
				class="btn btn-primary"
				data-toggle="modal"
				data-target="#loadingModal"></button>
			<div
				id="loadingModal"
				class="modal fade"
				style={{ backgroundColor: "rgba(0,0,0,.4)" }}
				role="dialog"
				data-backdrop="static"
				data-keyboard="false"
				aria-hidden="true">
				<div
					class="modal-dialog modal-sm modal-dialog-centered"
					style={{ marginTop: 0, marginBottom: 0 }}>
					<div class="modal-content">
						<button
							type="button"
							style={{ display: "none" }}
							ref={closeLoadingRef}
							data-dismiss="modal"
							aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								paddingTop: 5,
								paddingBottom: 10,
								paddingLeft: 10,
								paddingRight: 10,
							}}>
							<img src="DI.gif" style={{ height: 80, width: 80 }} />
							<span
								style={{
									color: "#14254A",
									fontSize: 16,
									textAlign: "center",
								}}>
								{message}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export { CampaignSpecificationLoader };
