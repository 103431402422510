/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Snehal More
@file Name:clientSetupHelp.js
@Description: UI for clientSetupHelp
*/


import React from 'react';
import {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../login/authentication';
import AdminNavpage from  '../layouts/adminNavpage';
import Footer from '../layouts/footer'; 
import { Button, Badge } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import {Modal } from 'react-bootstrap';

// const Swal = require('sweetalert2');
// const queryString = require('query-string');
// var region;
class ClientSetupHelp extends Component{
    constructor(props){
        super(props);
        this.state={
        }
        }//end of state
        componentDidMount(){
            if(!this.props.auth.isAuthenticated) {
                this.props.history.push('/userLogin');
            }
            else{
                console.log("Log");
               
    
            }
        }
        ClientsetupBackButton(){
             
              window.location.href="clientSetup"
                
        }
        render(){

     const {isAuthenticated, user} = this.props.auth;
    return(
            
     <div>
            <AdminNavpage/>  
            <div class="container-fluid" style={{ paddingTop: '110px', marginBottom:'50px'}}>
                     <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1">
                        < a class="col" href='#'  style={{color: '#056eb8'}}onClick={this.ClientsetupBackButton.bind(this)} >
                        <FaArrowAltCircleLeft size={32} title="" className="backFrom_salesforce"/>
                        </a>
                        </div>
                    </div>
                        <h3 style={{color:' #14254A',marginLeft:'104px'}}> Salesforce integration</h3>
                        <div class="container">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe style={{border:'1px solid lightgrey'}}class="embed-responsive-item" src="API_salesforce.mp4" ></iframe>
                            </div>
                        </div>
                        <div class="container"><br/>
                            <h4>We need below details from client to setup in DI </h4>
                            <ul style={{color:' #14254A'}}><b>Simple</b>
                                <li>1.	Client name</li>
                                <li>2.	Agency name</li>
                                <li>3.	Email</li>
                                <li>4.	Login URL</li>
                                <li>5.	API version</li>
                                <li>6.	Mapping file</li>
                                <li>7.	User name</li>
                                <li>8.	Password</li>
                                <li>9.	Token</li>                       
                            </ul>
                            <ul style={{color:' #14254A'}}><b>Application Through</b>
                                <li>1.	Client name</li>
                                <li>2.	Agency name</li>
                                <li>3.	Email</li>
                                <li>4.	Login URL</li>
                                <li>5.	API version</li>
                                <li>6.	Mapping file</li>
                                <li>7.	User name</li>  
                                <li>8.	JWT consumer key</li>
                                <li>9.	Consumer Secret</li>
                                <li>10.	JWT AUD</li>
                                <li>11.	Token URL</li>
                                <li>12.	Private key path</li>
                                <li>13.	Public certificate path</li>
                            </ul>
                        </div>
                        <div class="container">
                        <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>Client Setup: -  </span> First Admin setup the client and agency</p>
                        <img className="img-responsive" style={{marginTop:'10px',border:'1px solid grey'}} src="clientSetupHelp1.png" width="80%" height="500px"></img>
                        <img className="img-responsive" style={{marginTop:'10px',border:'1px solid grey'}} src="clientSetupHelp2.png" width="80%" height="500px"></img>
                        <br />
                        <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>1.	Client name: -  </span><br/>
                        Where we have to select client from this field.</p>

                        <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>2.	Agency name: -  </span><br/>
                        Where we have to select respective agency from this field. After select client and Agency one popup window will
                         display Client salesforce setup is exists, if client salesforce setup is already present then popup window will 
                         display Client salesforce setup is already exists.</p>

                         <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>3.	Email: -  </span><br/>
                         In this field we have to mention email id where it will receives mail from system after upload the lead to salesforce.</p>

                         <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>4.	Login URL: -  </span><br/>
                         We can use this login URL as default</p>
                                <p>https://login.salesforce.com/services/Soap/u/
                        </p>

                        <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>5.	API version: -  </span><br/>
                        API version is 48.0 </p>

                        <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>6.	Mapping file: -  </span><br/>
                        Where we have to select client from this field.</p>

                        <p style={{marginTop:'10px',color:'#14254A',color:'#14254A'}}>
                        <span style={{fontWeight:'bold',marginLeft:'100px'}}>A.    Design Metadata File: -  </span><br/>
                        <p>For every client, we need to first design metadata file between salesforce and DI application data. 
                            Initially, DI team needs to finalize which data they want to send Salesforce based on Client. 
                            Once finalized the data, they need to share metadata file about table name, columns name and data
                            type with Salesforce team.</p>
                            <p>Now, Salesforce team should prepare or create standard or custom fields based on provided 
                                metadata file. Once both team prepared respected data then they can map those fields.
                            </p>
                            <img className="img-responsive" style={{marginTop:'10px',marginLeft:'100px'}} src="clientSetuptHelp3.png" width="100px" height="100px"></img>
                            <span style={{fontWeight:'bold',marginLeft:'100px'}}>B.	Prepare Field Mapping File: -  </span><br/>
                            <p>Once metadata file has been completed for client. Then DI team needs to create DI Client –
                                 Salesforce Mapping file based on metadata file. This file contents Client DI database 
                                 lead table column mapping against Salesforce lead object field. This file is used when 
                                 DI Salesforce middleware is creating lead record file for importing into Salesforce.                                 
                            </p> 
                            <img className="img-responsive" style={{marginTop:'10px',marginLeft:'100px'}} src="clientSetuptHelp4.png" width="100px" height="100px"></img>                       
                        </p>

                        <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>7.	User name: -  </span><br/>
                        DI team needs to create either file or database entry for every client and its salesforce org information. 
                        Salesforce team needs to provide following information to DI team. Salesforce used OAuth 2.0 for authorization. 
                        Salesforce has multiple authorization process to connect to salesforce. We are using 2 flows to connect to Salesforce. 
                        We can choose any flow based on client requirement</p>


                        <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>8.	Username Password Flow: -  </span><br/>
                        <span style={{fontWeight:'bold',marginLeft:'100px',color:'#14254A'}}>i)	Generate Security Token : -  </span><br/>
                        <p>
                        When you create a Salesforce account, Salesforce sends an email message from support@salesforce.com with subject: 
                        salesforce.com security token confirmation to the email address associated with the account. This email message 
                        contains the Security Token for the account and is the only place where you can find the Security Token value. 
                        When you change the account password, the security token is also regenerated (so the previous one expires) and a 
                        similar email is sent.
                        </p>     
                        <span style={{fontWeight:'bold',marginLeft:'100px',color:'#14254A'}}>ii)	To get the security token for your Salesforce account: -  </span><br/>
                        <p>Log in to Salesforce using the Salesforce account. In the User Menu, select Setup. In the menu on the left,
                             under Personal Setup, expand My Personal Information, and then click Reset My Security Token. A new email 
                             message will be sent to user which contains Security Token. </p>                   
                        </p>

                        <p style={{marginTop:'10px',color:'#14254A'}}><span style={{fontWeight:'bold'}}>9.	JSON Web Token (JWT) Flow: -  </span><br/>
                        You want to authorize servers to access data without interactively logging in each time the servers exchange information. 
                        For these cases, you can use the OAuth 2.0 JSON Web Token (JWT) bearer flow. This flow uses a certificate to sign the JWT
                         request and doesn’t require explicit user interaction. However, this flow does require prior approval of the client app.</p>
                         <span style={{fontWeight:'bold',marginLeft:'100px',color:'#14254A'}}>i.	Create X509 Certificate using OpenSSL: -  </span><br/>
                        <p>Download OpenSSL from here and run below commands to create self-signed certificate.</p>
                        <p>1.	openssl req -newkey rsa:2048 -new -nodes –k meyout key.pem -out csr.pem</p>
                        <p>2.	openssl x509 -req -days 365 -in csr.pem -signkey key.pem -out server  </p>
                        <p>Above command will create two files – server.crt and key.pem which needs to be
                             used in Node.js application. Keep separate private key and public certificate file for every client.</p>
                             <p>
                             <img className="img-responsive" style={{marginTop:'10px',marginLeft:'100px'}} src="clientSetuptHelp5.png" width="100px" height="100px"></img> 
                             <img className="img-responsive" style={{marginTop:'10px',marginLeft:'250px', marginTop:'-80px'}} src="clientSetuptHelp6.png" width="100px" height="100px"></img> 
                             </p><br/>
                             <span style={{fontWeight:'bold',marginLeft:'100px',color:'#14254A'}}>ii.	Salesforce Connected App: -  </span><br/>
                             <p>A connected app is a framework that enables an external application to integrate
                                  with Salesforce using APIs and standard protocols, such as SAML, OAuth, and OpenID Connect. 
                                  Connected apps use these protocols to authenticate, authorize, and provide single sign-on (SSO) for 
                                  external apps. The external apps that are integrated with Salesforce can run on the customer success platform, 
                                  other platforms, devices, or SaaS subscriptions. Once Salesforce team set up connected app then you can provide 
                                  them public certificate file (server.crt) to upload against digital signature in Connected App. Salesforce team needs 
                                  to provide Consumer Key and Client Secret key based on created Connected App.</p>
                        </div>
            </div>

            <Footer/>
    </div>          
         
               
            )}
            };//end of class
       /**
       * @author Snehal More
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      ClientSetupHelp.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(ClientSetupHelp));










