/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanjana Godbole
@Creation Date:26-02-2019
@fileName : agencyAllocatedLead.js
@Description:UI for  Live campaigns under Agency view
*/

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import $ from "jquery"; //Sandeep-task-3227-added code to import jquery
import "bootstrap"; //Sandeep-task-3227-added code to import bootstrap

import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";

import "antd/dist/antd.css";

import { Input, Checkbox, Icon, Progress, Radio, Tooltip } from "antd"; //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header

var dateTime = require("node-datetime");

const { TextArea } = Input;

var publisherCount = 0;
var result = [];
var displayresult = [];
var displaycpl = [];
var displayallocatedLead = [];
var monthArray = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
]; //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
const Swal = require("sweetalert2");

class AgencyAllocatedLeads extends React.Component {
	constructor() {
		super();
		this.state = {
			onClickAllocatedLead: [],
			onClickAllocatedLeadPubList: [],
			onClickAllocatedLeadPublisher: [],
			onClickAllocatedLeadPublisherDecrement: [],
			assignDeliveredLead: [],
			decrementLeads: "",
			disabled: "true",
			errors: {},
			successMsg: "",
			publisherName: "",
			endDate: "",
			leadIncDecStatus: "",
			leadsToIncDecValue: 0,
			campaignStatus: "",
			passCampID: "",
			passPID: "",
			parentCampID: "",
			reallocationID: "",
			cancelPubMsg: "",
			cancelPubNotMsg: "",
			cancelResponse: false,
			campaignDetailAllocates: [],
			sumOfAllocatedLeads: [],
			campaignDetailID: [],
			btnStatus: "",
			mergedCampaignDetailAssign: [],
			alertDisplay: "none",
			agencyID: "",
			allocatedLead: "",
			cpl: "",
			campCPL: "",
			editAllocationStatus: [],
			newDynamicArray: [],
			alertBorder: "0px solid white",
			alertWidth: "",
			alertAlign: "",
			spanSpaceDisplay: "none",
			success: "",
			puberror: "",
			allocerrors: "",
			reerrors: "",
			dateerrors: "",
			poerror: "",
			requiredLeadPerAsset: "",
			assetwiseLead: [],
			campaignEndDate: "",
			decrementalLeads: 0,
			Step: "", //if visits the page from dashboard then will be back redirected to dashboard
			inputClass: "btn add-button",
			updateLeadsTime: "",
			updateLeadsPID: "",
			downloadFiles: [],
			filename: "",
			ext: "",
			open: false,
			checkCLAcceptStatus: false,
			checkAcceptStatus: false,
			acceptStatusCount: 0,
			clientAcceptCount: 0,
			pID: "",
			EndDateDisplay: "none", //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
			pacingTableDisplay: "none", //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
			publisherPacingDetails: [], //Sonali :task-3176-Array is added to get pacing details publisher wise
			//campEndDate:'', //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
			// Sandeep-task-3220-commented this code bcz getting blank value for campEndDate on Edit allocation
			campID: "", //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
			//agencyID: "", //Rutuja 2978 commented as duplicate agencyID //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
			differenceInMonths: 0, //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
			fields: {
				Accepted: false,
				ClientAccepted: false,
				Reason: "",
			},
		};

		this.showLeadAssignTOPublisher = this.showLeadAssignTOPublisher.bind(this);
		this.cancelTOPublisher = this.cancelTOPublisher.bind(this);
		this.handleBackButtonToLive = this.handleBackButtonToLive.bind(this);
		this.handleBackButtonTodashBoard =
			this.handleBackButtonTodashBoard.bind(this);
		this.handleAllocatedLeads = this.handleAllocatedLeads.bind(this);
		this.handleDecrementLeads = this.handleDecrementLeads.bind(this);
		this.handleRefreshPage = this.handleRefreshPage.bind(this);
		this.handleDecPublisherLeads = this.handleDecPublisherLeads.bind(this);
		this.passValue = this.passValue.bind(this);
		this.passValue1 = this.passValue1.bind(this);
		this.downloadIOFile = this.downloadIOFile.bind(this);
		this.handleBackButtonTocampaignList =
			this.handleBackButtonTocampaignList.bind(this);
		this.handleChangeEndDatePublisher =
			this.handleChangeEndDatePublisher.bind(this);
		this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
		this.handleChangeCampEndDate = this.handleChangeCampEndDate.bind(this); //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
		this.pauseTOPublisher = this.pauseTOPublisher.bind(this);
		this.resumeTOPublisher = this.resumeTOPublisher.bind(this);
		this.handleCampaignID = this.handleCampaignID.bind(this);
		this.isGDPRPUB = this.isGDPRPUB.bind(this);
		this.handleChangeDynamicPublisher =
			this.handleChangeDynamicPublisher.bind(this);
		this.handlecreatives = this.handlecreatives.bind(this);
		this.submitPublisher = this.submitPublisher.bind(this);
		this.handleUpdateLeadPermission =
			this.handleUpdateLeadPermission.bind(this);
		this.handleChangeUpdateLeadsPermission =
			this.handleChangeUpdateLeadsPermission.bind(this);
		this.submitUpdateLeadsPermission =
			this.submitUpdateLeadsPermission.bind(this);
		this.openPopup = this.openPopup.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.NewPacingTable = this.NewPacingTable.bind(this); //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
	} // end of constructor

	openPopup(campID, pID, allocatedLead) {
		this.setState({
			open: true,
			campID: campID,
			pID: pID,
			allocatedLead: allocatedLead,
		});
	} //end of openPopup

	onCloseModal() {
		this.setState({ open: false });
	} //end of onCloseModel

	handleLeadCheck(e) {
		let fields = this.state.fields;
		if (e.target.name === "Reason") {
			fields[e.target.name] = e.target.value;
		} else {
			fields[e.target.name] = e.target.checked;
		}
		this.setState({ fields });
	} //end of handleLeadCheck

	CancelLeadUploadedPublisher(e) {
		var accepted = this.state.fields.Accepted;
		var ClientAccepted = this.state.fields.ClientAccepted;
		var reason = this.state.fields.Reason;
		let data = {
			campID: this.state.campID,
			pID: this.state.pID,
			allocatedLeads: this.state.allocatedLead,
			accepted: accepted,
			ClientAccepted: ClientAccepted,
			reason: reason,
			acceptedLeads: this.state.acceptStatusCount, //shivani-task 2993- added code to pass acceptedLeads through data to BE.
			clientAcceptedLeads: this.state.clientAcceptCount, //shivani-task 2993-added code to pass clientAcceptedLeads through data to BE.
		}; // karan-task-3714-vapt header and query params

		fetch("/publisher/cancelAfterLeadSubmission", {
			method: "POST",
			headers: { "Content-Type": "application/json" }, // karan-task-3714-vapt header and query params
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				if (response.length > 0) {
					var message = "Allocation cancel successfully";
					Swal.fire({
						text: message,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
				}
			})
			.catch(function (err) {
				console.log(err);
			});
	} //end of CancelLeadUploadedPublisher

	/**
	 * @author Raunak Thakkar
	 * @param  Description  creatives button on accept status only
	 * @return redirects to creatives review page with campId publisherId and agency Id
	 */
	handlecreatives(e) {
		e.preventDefault();//Somnath Task-3832, Add preventDefault Method
		var campId = e.target.getAttribute("id");
		var publisherID = e.target.getAttribute("name");
		var parentCampID = e.target.getAttribute("parentCampID");
		var reallocationID = e.target.getAttribute("reallocationID");
		var backKey = "Allocate";
		this.props.history.push("/agencyCreativesReview", {
			campID: campId,
			parentCampID,
			reallocationID,
			pID: publisherID,
			backKey,
		}); //karan-task-3715-replace query params
	} //end of handleCreatives

	submitPublisher(e) {
		e.preventDefault();
		let errors = {};
		let spanDisplay = "none";
		let formIsValid = false;
		let date = false;
		let cpl = false;
		let lead = false;
		let puberror = {};
		let valid = false;
		let rebudget = false;
		let allocerrors = {};
		let reerrors = {};
		let dateerrors = {};
		let ponumber = false;
		let poerror = {};

		result = Array.from(
			new Set(this.state.newDynamicArray.map((p) => p.pID))
		).map((pID) => {
			return {
				pID: pID,
				publisherName: this.state.newDynamicArray.find((p) => p.pID === pID)
					.publisherName,
				startDate: this.state.newDynamicArray.find((p) => p.pID === pID)
					.startDate,
				// Saurabh-task-3792-added code for poNumber
				PO_No: this.state.newDynamicArray.find((p) => p.pID === pID).PO_No,
				endDate: this.state.newDynamicArray.find((p) => p.pID === pID).endDate,
				firstLeadDeliveryDate: this.state.newDynamicArray.find(
					(p) => p.pID === pID
				).firstLeadDeliveryDate,
				allocatedLead: this.state.newDynamicArray.find((p) => p.pID === pID)
					.allocatedLead,
				cpl:
					this.state.newDynamicArray.find((p) => p.pID === pID).cpl != null
						? this.state.newDynamicArray.find((p) => p.pID === pID).cpl
						: this.state.newDynamicArray.find((p) => p.pID === pID).CPL,
			};
		});

		var totalLead = document.getElementById("totalLead").value;
		var totalAllocate = parseInt(
			document.getElementById("totalAllocate").value
		);
		var totalBudget = document.getElementById("totalBudget").value;
		var totalRemainingBudgetValidation = parseInt(
			document.getElementById("totalRemainingBudget").value
		);
		//Narendra- Take this allocated budget because of validation need previous allocated budget + pending budget
		var publisherAllocatedBudgetValidation =
			parseInt(this.state.publisherCampCPL[0].allocatedLead) *
			parseInt(this.state.publisherCampCPL[0].CPL);
		var totalRemainingBudget =
			totalRemainingBudgetValidation + publisherAllocatedBudgetValidation;
		var totalstartDate = document.getElementById("totalstartDate").value;
		var totalendDate = document.getElementById("totalendDate").value;
		var totalPendinglead = parseInt(
			document.getElementById("totalPending").value
		);
		//Narendra- Take this allocated lead because of validation need previous allocated lead + pending lead
		var publisherAllocatedLeadValidation = parseInt(
			this.state.publisherCampCPL[0].allocatedLead
		);
		var totalPending = totalPendinglead + publisherAllocatedLeadValidation;
		var campaignCPL = document.getElementById("campaignCPL").value;
		var sum = 0;
		var num;
		var multiply = 0;
		var num1;
		var num2;
		var addmulti = 0;
		var allocatedLeadSubPublisher =
			totalAllocate - publisherAllocatedLeadValidation;
		var add = totalLead - allocatedLeadSubPublisher;
		var dynamiclength = result.length;

		for (var i = 0; i < dynamiclength; i++) {
			if (
				result[i].allocatedLead !== undefined &&
				result[i].cpl !== undefined
			) {
				publisherCount++;
				if (result[i].startDate <= result[i].endDate) {
					date = true;
					displayresult.splice(i, 1);
				} else {
					date = false;
					displayresult[i] = result[i].publisherName;
					errors["endDate"] =
						"Please enter start date less than end date " + displayresult;
					this.setState({
						newsuccess: "",
						puberror: "",
						dateerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				}

				if (result[i].endDate > totalendDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"Allocation end date cannot be greater than campaign's end date " +
						displayresult;
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				if (result[i].startDate < totalstartDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"Allocation start date cannot be less than campaign start date for " +
						displayresult;
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}
				// Saurabh-task-3792-added Validation for First Lead delivery date
				var today = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
				if (result[i].firstLeadDeliveryDate < today) {
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"First lead delivery date must be greter than or equal to Current Date " +
						displayresult;
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}
				// Saurabh-task-3792-added Validation for PO Number
				if (
					result[i].PO_No !== "" && //Rutuja 2978 chnaged = to ==
					result[i].PO_No != null &&
					result[i].PO_No !== undefined//Rutuja 2978 chnaged = to ==
				) {
					var pattern2 = new RegExp(/^[a-zA-Z\d*]{8,}$/);
					if (!pattern2.test(result[i].PO_No)) {
						ponumber = false;
						displayresult[i] = result[i].publisherName;
						poerror["poerror"] =
							"Please enter minimum eight digit valid PO Number " +
							displayresult;
						this.setState({
							poerror: poerror,
							dateerrors: "",
							newsuccess: "",
							puberror: "",
							errors: "",
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						ponumber = true;
					}
				} else {
					ponumber = true;
				}

				if (result[i].firstLeadDeliveryDate > result[i].endDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"First lead delivery date can't be greater than end date " +
						displayresult;
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}
				if (result[i].firstLeadDeliveryDate < result[i].startDate) {
					date = false;
					displayresult[i] = result[i].publisherName;
					dateerrors["date"] =
						"Lead delivery date can't be less than start date " + displayresult;
					this.setState({
						dateerrors: dateerrors,
						newsuccess: "",
						puberror: "",
						errors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else {
					date = true;
				}

				var pattern = new RegExp(/^[1-9]+[0-9]*$/);
				if (
					result[i].allocatedLead === "" ||
					result[i].allocatedLead === null ||
					result[i].allocatedLead === undefined
				) {
					lead = false;
					displayallocatedLead[i] = result[i].publisherName;
					errors["allocatedLead"] =
						"Please enter allocation for  " + displayallocatedLead;
					this.setState({
						newsuccess: "",
						puberror: "",
						errors: errors,
						dateerrors: "",
						allocerrors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} else if (!pattern.test(result[i].allocatedLead)) {
					lead = false;
					displayallocatedLead[i] = result[i].publisherName;
					errors["allocatedLead"] =
						"Please enter allocation in number " + displayallocatedLead;
					displayallocatedLead = [displayallocatedLead[i]];
					this.setState({
						newsuccess: "",
						puberror: "",
						errors: errors,
						dateerrors: "",
						allocerrors: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});

					return;
				} else {
					if (result[i].allocatedLead > totalPending) {
						lead = false;
						errors["allocatedLead"] =
							"Please enter less lead allocation than pending allocation " +
							displayallocatedLead;
						this.setState({
							newsuccess: "",
							puberror: "",
							errors: errors,
							dateerrors: "",
							allocerrors: "",
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						lead = true;
						num = parseInt(result[i].allocatedLead);
						sum += num;
					}
				}

				/**validations for cpl */

				var patterndecimal = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/); //decimal
				var patternnonzero = new RegExp(/^[1-9]+[0-9]*$/); //numbers except zero
				var testval1 = result[i].cpl;
				var testval = parseInt(testval1);
				if (testval === undefined) {
					cpl = false;
					displaycpl[i] = result[i].publisherName;
					errors["cpl"] =
						"Please check CPL value. CPL should follow below criteria" +
						displaycpl;
					errors["cpl1"] = <li>CPL should be only number.</li>;
					errors["cpl2"] = <li>CPL should be greater than zero.</li>;
					errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;
					displaycpl = [displaycpl[i]];
					this.setState({
						dateerrors: "",
						newsuccess: "",
						puberror: "",
						allocerrors: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					return;
				} // cpl undefined
				else {
					if (testval === "") {
						errors["commonError"] = <li>Please fill below required fields</li>;
						cpl = false;
					} //removed by backspace
					else if (patternnonzero.test(testval1)) {
						cpl = true;
						displaycpl.splice(i, 1);
						campaignCPL = parseInt(campaignCPL);
						if (testval > campaignCPL) {
							lead = false;
							errors["cpl"] = (
								<li>Please enter less or equal CPL to campaign CPL</li>
							);
							this.setState({
								newsuccess: "",
								puberror: "",
								errors: errors,
								dateerrors: "",
								allocerrors: "",
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});

							return;
						}
					} else if (patterndecimal.test(testval1) && result[i].cpl !== "0") {
						cpl = true;
						displaycpl.splice(i, 1);
						campaignCPL = parseInt(campaignCPL);
						if (result[i].testval > campaignCPL) {
							lead = false;
							errors["cpl"] = (
								<li>Please enter less or equal CPL than campaign CPL</li>
							);
							this.setState({
								newsuccess: "",
								puberror: "",
								errors: errors,
								dateerrors: "",
								allocerrors: "",
								alertBorder: "1px solid red",
								alertWidth: "1000px",
								alertAlign: "center",
								alertDisplay: "block",
								spanSpaceDisplay: spanDisplay,
							});

							return;
						}
					} else {
						cpl = false;
						displaycpl[i] = result[i].publisherName;
						errors["cpl"] =
							"Please check CPL value. CPL should follow below criteria for" +
							displaycpl;
						errors["cpl1"] = <li>CPL should be only number.</li>;
						errors["cpl2"] = <li>CPL should be greater than zero.</li>;
						errors["cpl3"] = <li>CPL value allowed only 2 decimal places.</li>;
						displaycpl = [displaycpl[i]];
						this.setState({
							dateerrors: "",
							newsuccess: "",
							puberror: "",
							allocerrors: "",
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					}
				} // cpl ""

				//validation for budget
				num1 = parseInt(result[i].allocatedLead);
				num2 = parseFloat(result[i].cpl);
				multiply = num1 * num2;

				addmulti += multiply;

				if (totalRemainingBudget === "") {
					if (addmulti > totalBudget) {
						rebudget = false;
						reerrors["remaining"] = (
							<li>Allocated budget cannot be greater than total budget</li>
						);
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							newsuccess: "",
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
					}
				} else {
					if (parseInt(addmulti) > totalRemainingBudget) {
						rebudget = false;

						reerrors["remaining"] = (
							<li>Allocated budget cannot be greater than total budget</li>
						);
						this.setState({
							puberror: "",
							allocerrors: "",
							reerrors: reerrors,
							errors: errors,
							alertBorder: "1px solid red",
							alertWidth: "1000px",
							alertAlign: "center",
							alertDisplay: "block",
							spanSpaceDisplay: spanDisplay,
						});
						return;
					} else {
						rebudget = true;
					}
				}
				add = totalLead - allocatedLeadSubPublisher;
				if (sum > add) {
					valid = false;
					allocerrors["allocate"] = (
						<li>Allocated leads cannot be greater than total leads</li>
					);
					this.setState({
						puberror: "",
						allocerrors: allocerrors,
						reerrors: "",
						newsuccess: "",
						errors: errors,
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
				} else {
					valid = true;
				}
			} //main allocated leads and cpl
			else {
				if (publisherCount === 0) {
					puberror["one"] = (
						<li>Please enter valid lead allocationfor atleast one publisher</li>
					);

					this.setState({
						puberror: puberror,
						allocerrors: "",
						errors: errors,
						newsuccess: "",
						alertBorder: "1px solid red",
						alertWidth: "1000px",
						alertAlign: "center",
						alertDisplay: "block",
						spanSpaceDisplay: spanDisplay,
					});
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
				}
			}
		} // end of for
		if (
			date === true &&
			cpl === true &&
			lead === true &&
			valid === true &&
			rebudget === true &&
			ponumber === true
		) {
			formIsValid = true;
		}
		if (formIsValid === true) {
			this.setState({ alertDisplay: "none" });
			var campID = document.getElementById("campID").value;
			var leadAllocation = document.getElementById("leadAllocation").value;

			/**
			 * @author Narendra Phadke
			 * @param  Description handle the publisher Login Details
			 * @return Description return All publisher Details
			 */
			let data = {
				campID: campID,
				clientCampID: this.state.campaignDetailID[0].clientCampID,
				leadAllocation: leadAllocation,
				dynamicArray: this.state.newDynamicArray,
			}; // karan-task-3714-vapt header and query params

			//publisher/assignCampaign
			$("#editAllocationModal").modal("hide");
			//  Sandeep-task-3227-added code to hide modal
			fetch("publisher/editAllocation", {
				//insert data in publisher_assignment table
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				}, // karan-task-3714-vapt header and query params
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.success === true) {
						this.setState({ inputClass: "disabled" });
						Swal.fire({
							text: res.message,
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								window.location.reload();
							},
						});
					} else {
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
		}
	} // end of submitAllocatingCampaign

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the multiple allocatation of campaign
	 * @return Description return successfully allocate campaign message
	 */
	handleChangeDynamicPublisher(i, e) {
		const { name, value } = e.target;
		let newDynamicArray = [...this.state.newDynamicArray];
		newDynamicArray[i] = { ...newDynamicArray[i], [name]: value };
		//this.state.newDynamicArray = [...newDynamicArray];
		this.setState({newDynamicArray: [...newDynamicArray]}) //Rutuja 2978 commented this.state and used this.setState
	} // end of  handleChangeDynamicPublisher
	isGDPRPUB(e) {
		var status = e.target.value;
		var campID = e.target.id;
		let data = { campID: campID }; // karan-task-3714-vapt header and query params
		var emptyArray = [];
		this.setState({
			publisherCampCPL: emptyArray,
			gdprPublisher: emptyArray,
			mergedGdprPublisher: emptyArray,
		});

		/**
		 * @author Supriya Gore
		 * @param  Description  use in allocating leads to publisher for checking status and view CPL edditable or not
		 * @return Description return CPL and publisher ID and status
		 */

		if (status === "Yes") {
			this.setState({ btnStatus: status, gdprStatus: "Yes" });

			fetch("agency/assignGDPRPublisher", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				}, // karan-task-3714-vapt header and query params
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((gdprPublisher) => {
					this.setState({ gdprPublisher: gdprPublisher });

					/**
					 * @author Supriya Gore
					 * @param  Description Merge two array
					 * @return Description one campaignDetailAssign and gdprPublisher array
					 */

					fetch("agency/publisherCampaignCPL", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						}, // karan-task-3714-vapt header and query params
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((publisherCampCPL) => {
							 this.setState({ publisherCampCPL: publisherCampCPL });
							if (this.state.publisherCampCPL.length > 0) {
								//Rutuja Task -2978 declared temp var and then used this.setState
								let Temp = this.state.gdprPublisher.map((e) => 
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
								);
								this.setState({newGDPRPubArray: Temp})
							} else {
								//Rutuja Task -2978 declared temp var and then used this.setState
								 let Temp = this.state.gdprPublisher.map((e) =>
									Object.assign(
										{},
										e,
										this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}
									)
								);
								this.setState({newGDPRPubArray:Temp})
								
							}

							const resultGDPR = Array.from(
								new Set(this.state.gdprPublisher.map((p) => p.pID))
							).map((pID) => {
								return {
									pID: pID,
									publisherName: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).publisherName,
									startDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.startDate,
									endDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.endDate,
									allocatedLead: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).allocatedLead,
									cpl:
										this.state.gdprPublisher.find((p) => p.pID === pID).cpl !=
										null
											? this.state.gdprPublisher.find((p) => p.pID === pID).cpl
											: this.state.gdprPublisher.find((p) => p.pID === pID)
													.campCPL,
									firstLeadDeliveryDate: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).firstLeadDeliveryDate,
									rating: this.state.gdprPublisher.find((p) => p.pID === pID)
										.rating,
									gdprCompliance: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).gdprCompliance,
								};
							});

							let mergedGdprPublisher = [];
							mergedGdprPublisher = resultGDPR.map((e) =>
								Object.assign(
									{},
									e,
									this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) ||
										{}
								)
							);
							this.setState({ mergedGdprPublisher: mergedGdprPublisher });
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		}

		if (status === "No") {
			this.setState({ btnStatus: status, gdprStatus: "No" });

			fetch("publisher/assignNoGDPRPublisher", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				}, // karan-task-3714-vapt header and query params
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((gdprPublisher) => {
					this.setState({ gdprPublisher: gdprPublisher });

					/**
					 * @author Supriya Gore
					 * @param  Description Merge two array
					 * @return Description one campaignDetailAssign and gdprPublisher array
					 */

					fetch("agency/publisherCampaignCPL", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						}, // karan-task-3714-vapt header and query params
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((publisherCampCPL) => {
							this.setState({ publisherCampCPL: publisherCampCPL });
							if (this.state.publisherCampCPL.length > 0) {
								//Rutuja Task -2978 declared temp var and then used this.setState
								let Temp = this.state.gdprPublisher.map((e) =>  
									Object.assign(
										{},
										e,
										this.state.publisherCampCPL.find(
											(e1) => e1.pID === e.pID
										) || {}
									)
								);
								this.setState({newGDPRPubArray :Temp})
								
							} else {
								 let Temp = this.state.gdprPublisher.map((e) => 
									Object.assign(
										{},
										e,
										this.state.stateCopy.find((e1) => e1.pID === e.pID) || {}
									)
								);
								this.setState ({newGDPRPubArray:Temp})
							}

							const resultGDPR = Array.from(
								new Set(this.state.gdprPublisher.map((p) => p.pID))
							).map((pID) => {
								return {
									pID: pID,
									publisherName: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).publisherName,
									startDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.startDate,
									endDate: this.state.gdprPublisher.find((p) => p.pID === pID)
										.endDate,
									allocatedLead: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).allocatedLead,
									cpl:
										this.state.gdprPublisher.find((p) => p.pID === pID).cpl !=
										null
											? this.state.gdprPublisher.find((p) => p.pID === pID).cpl
											: this.state.gdprPublisher.find((p) => p.pID === pID)
													.campCPL,
									firstLeadDeliveryDate: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).firstLeadDeliveryDate,
									rating: this.state.gdprPublisher.find((p) => p.pID === pID)
										.rating,
									gdprCompliance: this.state.gdprPublisher.find(
										(p) => p.pID === pID
									).gdprCompliance,
								};
							});

							let mergedGdprPublisher = [];
							mergedGdprPublisher = resultGDPR.map((e) =>
								Object.assign(
									{},
									e,
									this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) ||
										{}
								)
							);

							this.setState({ mergedGdprPublisher: mergedGdprPublisher });
						})
						.catch(function (err) {
							console.log(err);
						});
				})
				.catch(function (err) {
					console.log(err);
				});
		} else if (status === "false") {
			this.setState({ gdprStatus: status, btnStatus: "No" });
		}
	} // end of isGDPRPUB

	handleCampaignID(e) {
		var campID = e.target.id;
		var parentCampID = e.target.getAttribute("parentCampID");
		var reallocationID = e.target.getAttribute("reallocationID");
		var publisherID = e.target.getAttribute("publisherId");

		this.setState({ parentCampID, reallocationID });
		let self = this;

		fetch(
			"/publisher/allocatingCampaignIDPublisherSpecific?campID=" +
				campID +
				"&pID=" +
				publisherID,
			{
				method: "GET", //shivani-task 2993-added method for this api.
			}
		)
			.then((res2) => res2.json())
			.then((campaignDetailID) => {
				this.setState({ campaignDetailID: [...campaignDetailID] });
			});

		/**
		 * @author Supriya Gore
		 * @param  Description  use in allocating leads to publisher for checking status and view CPL edditable or not
		 * @return Description return CPL and publisher ID and status
		 */

		let data1 = {
			campID: campID,
			pID: publisherID,
		};

		const { user } = this.props.auth;
		var agencyID = user.id;
		this.setState({ agencyID: agencyID });

		fetch("agency/assignPublisherEdit", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			}, // karan-task-3714-vapt header and query params
			body: JSON.stringify(data1),
		})
			.then((res1) => res1.json())
			.then((mergedCampaignDetailAssign) => {
				self.setState({
					mergedCampaignDetailAssign: mergedCampaignDetailAssign,
				});
				fetch("agency/editAllocationPublisherCampaignCPL", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					}, // karan-task-3714-vapt header and query params
					body: JSON.stringify(data1),
				})
					.then((res) => res.json())
					.then((publisherCampCPL) => {
						this.setState({ publisherCampCPL: publisherCampCPL });
						//Rutuja Task -2978 declared temp var and then used this.setState
						let Temp =  
							this.state.mergedCampaignDetailAssign.map((e) =>
								Object.assign(
									{},
									e,
									this.state.publisherCampCPL.find((e1) => e1.pID === e.pID) ||
										{}
								)
							);
							this.setState({newDynamicArray:Temp})
						
					})
					.catch(function (err) {
						console.log(err);
					});
			})
			.catch(function (err) {
				console.log(err);
			});
	} // end of handleCampaignID()

	passValue1(e) {
		var campID = e.target.getAttribute("campID");
		var pID = e.target.getAttribute("pID");
		this.setState({ pCampID: campID, pPID: pID });
	} //end of passValue1

	passValue(e) {
		var campID = e.target.id;
		var pID = e.target.getAttribute("pid");
		this.setState({ passCampID: campID, passPID: pID, pID });
	} // end of passValue

	// refreshing parent page
	handleRefreshPage(e) {
		// karan-task-3714-vapt header and query params
		window.location.reload();//4582- Nilesh page refresh for camp ID
		var parsed = this.props.location.state;
		var campID, parentCampID, reallocationID;
		if (parsed !== undefined) {
			campID = parsed.campID;
			parentCampID = parsed.parentCampID;
			reallocationID = parsed.reallocationID;
			if (Number(this.state.Step) === 1) {
				this.props.history.replace("/agencyAllocatedLeads", {
					campID,
					parentCampID,
					reallocationID,
					step: 1,
				}); // karan-task-3714-vapt header and query params
			}
			if (Number(this.state.Step) === 2) {
				this.props.history.replace("/agencyAllocatedLeads", {
					campID,
					parentCampID,
					reallocationID,
					step: 2,
				}); // karan-task-3714-vapt header and query params
			} else {
				this.props.history.replace("/agencyAllocatedLeads", {
					campID,
					parentCampID,
					reallocationID,
				}); // karan-task-3714-vapt header and query params
			}
		}
	} //end of handleRefreshingPage

	showLeadAssignTOPublisher() {
		// karan-task-3714-vapt header and query params
		var parsed = this.props.location.state;
		var campID;
		if (parsed !== undefined) {
			campID = parsed.campID;
			//this.state.campIDTemp = campID;  
			this.setState({campIDTemp: campID});//Rutuja 2978 commented this.state and used this.setState

			fetch("/publisher/viewPubAssignLead?campID=" + campID + "", {
				method: "GET", //shivani-task 2993-added method for this api.
			}) //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((onClickAllocatedLead) => {
					var campaignStatus = onClickAllocatedLead[0].campaignStatus;
					let requiredLeadPerAsset =
						onClickAllocatedLead[0].requiredLeadPerAsse;
					var agencyID = onClickAllocatedLead[0].agencyID;
					this.setState({
						onClickAllocatedLead: onClickAllocatedLead,
						campaignStatus: campaignStatus,
						requiredLeadPerAsset: requiredLeadPerAsset,
						agencyID: agencyID,
					});
				});
			//snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
			fetch("/publisher/liveCampaignDetails?campID=" + campID + "", {
				method: "GET", //shivani-task 2993-added method for this api.
			}) //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((onClickAllocatedLeadPubList) => {
					this.setState({
						onClickAllocatedLeadPubList: onClickAllocatedLeadPubList,
						passCampID: campID,
					});
				});

			/**
			 * @author Ram Chander
			 * @param  Description handle the assign delivered lead
			 * @return Description return delivered lead array
			 */

			fetch("/publisher/viewPubAssignDeliveredLead?campID=" + campID + "", {
				method: "GET", //shivani-task 2993-added method for this api.
			}) //karan-task-3796-vapt remove ids
				.then((res) => res.json())
				.then((assignDeliveredLead) => {
					this.setState({
						assignDeliveredLead: assignDeliveredLead,
					});
				});
		} // karan-task-3714-vapt header and query params
	} // end of showLeadAssignTOPublisher

	/**
	 * @author Sonali Kalke
	 * @param  Description handle change to pause the publisher
	 * @return
	 */
	//rutuja -task- 4328- pause campaign 
	pauseTOPublisher(e) {

		e.preventDefault();
		const { user } = this.props.auth;
		var agencyID = user.id;
		this.setState({ agencyID: agencyID });

		var campID = e.target.id;

		Swal.fire({
			title: "Are you sure you want to pause the campaign?",
			html:
				"This will pause the campaign for all allocated publishers.<br/>" +
				"<br/><span style='color:red;'><b>* Reason For Pause</b></span>" +
				"<div><textarea id='pauseReason' rows='2' cols='40'></textarea></div>",
			type: "question",
			showCancelButton: true,
			cancelButtonColor: "#3085d6",
			confirmButtonColor: "#d33",
			allowOutsideClick: false,
			confirmButtonText: "Yes, pause it!",
			
		}).then((result) => {
			if (result.value && document.getElementById("pauseReason").value !== "") {
				var reason = document.getElementById("pauseReason").value;
				let data = {
					campID: campID,
					reason: reason,
				}; 

				fetch("/agency/agencyPausedCampaign", {
					//insert data in publisher_assignment table
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				});

				Swal.fire({
					text: "Campaign paused !",
					type: "success",
					
					
					preConfirm: () => {
						var data = {
							pacing: this.state.pacing,
							byPacing: true,
							tabNameKey: "All",
							tab: "All",
						}; 
						fetch("/agencyDashboard/agencyDashboardCount", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						})
							.then((res) => res.json())
							.then((dashboardCampaignCount) => {
								if (this.state.key === "active") {
									this.setState({
										partialCount: dashboardCampaignCount[0].activeCount,
									});
								}
								this.setState({
									dashboardCampaignCount: dashboardCampaignCount,
									noChangeDashbordCampaignCount: dashboardCampaignCount,
								});
							})
							.catch(function (err) {
								console.log(err);
							});
						if (this.state.tab === "Active") {
							fetch("/agencyDashboard/dashboardActiveCampaign", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							})
								.then((res) => res.json())
								.then((campaignDetails) => {
									this.setState({ campaignDetails: campaignDetails });
								})
								.catch(function (err) {
									console.log(err);
								});
						} else if (this.state.tab === "All") {
							var data1 = { pacing: this.state.pacing };
							fetch("/agencyDashboard/dashboardAllCampaign", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data1),
							})
								.then((res) => res.json())
								.then((campaignDetails) => {
									this.setState({ campaignDetails: campaignDetails });
								})
								.catch(function (err) {
									console.log(err);
								});

							fetch("/agencyDashboard/agencyDashboardCount", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							})
								.then((res) => res.json())
								.then((dashboardCampaignCount) => {
									this.setState({
										dashboardCampaignCount: dashboardCampaignCount,
									});
								})
								.catch(function (err) {
									console.log(err);
								});
						} else if (this.state.tab === "Pause") {
							fetch("/agencyDashboard/dashboardPausedCampaignNew", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data1),
							})
								.then((res) => res.json())
								.then((campaignDetails) => {
									this.setState({ campaignDetails: campaignDetails });
								})
								.catch(function (err) {
									console.log(err);
								});

							fetch("/agencyDashboard/agencyDashboardCount", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							})
								.then((res) => res.json())
								.then((dashboardCampaignCount) => {
									this.setState({
										dashboardCampaignCount: dashboardCampaignCount,
									});
								})
								.catch(function (err) {
									console.log(err);
								});
						} else if (this.state.tab === "Completed") {
							fetch("/agencyDashboard/dashboardArchiveCampaign", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data1),
							})
								.then((res) => res.json())
								.then((campaignDetails) => {
									this.setState({ campaignDetails: campaignDetails });
								})
								.catch(function (err) {
									console.log(err);
								});

							fetch("/agencyDashboard/agencyDashboardCount", {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							})
								.then((res) => res.json())
								.then((dashboardCampaignCount) => {
									this.setState({
										dashboardCampaignCount: dashboardCampaignCount,
									});
								})
								.catch(function (err) {
									console.log(err);
								});
						}
					},
					confirmButtonText: "Ok",
					//Rutuja Task-2978 commented as already used above
					// preConfirm:()=>
					// 	{
					// 		window.location.href = '/agencyAllocatedLeads';
					// 	}

				} );
			} else {
				if (
					(document.getElementById("pauseReason").value === "" ||
						document.getElementById("pauseReason").value === undefined ||
						document.getElementById("pauseReason").value === null) &&
					result.value === true
				) {
					Swal.fire({
						text: "Please Enter Reason For Pausing the Campaign !",
						type: "error",
						timer: 8000,
						showConfirmButton: false,
					});
					
				}
				
			}
			
		});
	} 

	// 	var campID = e.target.id;
	// 	var pID = e.target.name;

	// 	let data = {
	// 		campID: campID,
	// 		pID: pID,
	// 	}; // karan-task-3714-vapt header and query params
	// 	fetch("/publisher/pausePublisher", {
	// 		method: "POST",
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 		}, // karan-task-3714-vapt header and query params
	// 		body: JSON.stringify(data),
	// 	})
	// 		.then((res) => res.json())
	// 		.then((response) => {
	// 			console.log("Return with response in publisher pause");
	// 		});
	// 	// Sandeep-task-3258-added code to display sweetAlert
	// 	Swal.fire({
	// 		text: "Campaign paused !",
	// 		type: "success",
	// 		confirmButtonText: "Ok",
	// 	});
	// 	//window.location.reload();
	// } // end of pauseTOPublisher

	/**
	 * @author Sonali Kalke
	 * @param  Description handle change to resume the publisher
	 * @return
	 */
	resumeTOPublisher(e) {
		var campID = e.target.id;
		var pID = e.target.name;

		let data = {
			campID: campID,
			pID: pID,
		}; // karan-task-3714-vapt header and query params
		fetch("/publisher/resumePublisher", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			}, // karan-task-3714-vapt header and query params
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				console.log("Return with response in publisher pause");
			});
		// Sandeep-task-3258-added code to display sweetAlert
		Swal.fire({
			text: "Campaign resumed !",
			type: "success",
			confirmButtonText: "Ok",
			preConfirm:()=>
						{
							window.location.href = '/agencyAllocatedLeads';
						}
		});
		//window.location.reload();
	} // end of resumeTOPublisher

	/**
	 * @author Supriya Gore
	 * @param  Description handle the cancellation of allocated leads
	 * @return Description return All cancelled allocated leads
	 */
	cancelTOPublisher(e) {
		// karan-task-3714-vapt header and query params
		var parsed = this.props.location.state;
		let campID;
		if (parsed !== undefined) {
			campID = parsed.campID;
			var pIDData = e.target.name;
			pIDData = pIDData.split("#");
			var pID = pIDData[0];
			var allocatedLeads = pIDData[1];

			let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			let timestamp = new Date();
			let data = {
				campID: campID,
				pID: pID,
				allocatedLeads: allocatedLeads,
				timezone,
				timestamp,
			}; // karan-task-3714-vapt header and query params

			//If lead delivered and the status of lead in QA status it can not canceled
			fetch("/publisher/getStatusForCancelPublisher", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				}, // karan-task-3714-vapt header and query params
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((response) => {
					if (response[0].booleanResult === true) {
						this.setState({
							cancelPubNotMsg: "",
							checkAcceptStatus: response[0].checkAcceptStatus,
							checkCLAcceptStatus: response[0].checkCLAcceptStatus,
							acceptStatusCount: response[0].acceptStatusCount,
							clientAcceptCount: response[0].clientAcceptCount,
						});
						this.openPopup(campID, pID, allocatedLeads);
					} else {
						fetch("/publisher/getAccpetedForCancelPublisher", {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							}, // karan-task-3714-vapt header and query params
							body: JSON.stringify(data),
						})
							.then((res) => res.json())
							.then((response) => {
								console.log("Return with response");
								if (response[0].booleanAccept === true) {
									Swal.fire({
										text: "Allocation is Cancelled Successfully and Insertion Order document generated.",
										type: "success",
										confirmButtonText: "Ok",
										allowOutsideClick: false,
										preConfirm: () => {
											window.location.reload();
										},
									});
								} else {
									fetch("/publisher/cancelToPublisher", {
										method: "POST",
										headers: {
											"Content-Type": "application/json",
										}, // karan-task-3714-vapt header and query params
										body: JSON.stringify(data),
									})
										.then((res) => res.json())
										.then((response) => {
											Swal.fire({
												text: "Allocation is Cancelled Successfully and Insertion Order document generated.",
												type: "success",
												confirmButtonText: "Ok",
												allowOutsideClick: false,
												preConfirm: () => {
													window.location.reload();
												},
											});
										});
								}
							});
					}
				});
		} // karan-task-3714-vapt header and query params
	} // end of cancelTOPublisher

	/**
	 * @author Supriya Gore
	 * @param  Description handle the increament decrement allocated leads
	 * @return Description return All increament decrement allocated leads
	 */
	handleAllocatedLeads(e) {
		var campID = e.target.id;
		var pID = e.target.getAttribute("pID");

		let data = {
			campID: campID,
			pID: pID,
		};

		//update the status in publisher_allocation table
		fetch("/publisher/allocatedLeadsToPublisher", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		}) //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((onClickAllocatedLeadPublisher) => {
				this.setState(
					{ onClickAllocatedLeadPublisher: onClickAllocatedLeadPublisher },
					function () {
						this.setState({
							campEndDate: onClickAllocatedLeadPublisher[0].campEndDate,
						});
					}
				);
				var endDate1 = onClickAllocatedLeadPublisher[0].endDate;
				var startDate1 = onClickAllocatedLeadPublisher[0].startDate; //sandeep-task-2862-added code get startDate onClickAllocatedLeadPublisher array
				var campaignEndDate1 = onClickAllocatedLeadPublisher[0].campEndDate;
				var firstLeadDeliveryDate1 =
					onClickAllocatedLeadPublisher[0].firstLeadDeliveryDate; //Sandeep-task-2912-added code for to get firstLeadDeliveryDate
				this.setState(
					{
						endDate: endDate1,
						campEndDate: campaignEndDate1,
						startDate: startDate1,
						firstLeadDeliveryDate1: firstLeadDeliveryDate1,
					},
					function () {
						this.setState({
							campEndDate: onClickAllocatedLeadPublisher[0].campEndDate,
						});
					}
				); //Sandeep-task-2862-added code to setstate startdate
			}); //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date

		//update the status in publisher_allocation table for decrement
		fetch("/publisher/allocatedLeadsToPublisherForDecrement", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		}) //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((onClickAllocatedLeadPublisherDecrement) => {
				this.setState({
					onClickAllocatedLeadPublisherDecrement:
						onClickAllocatedLeadPublisherDecrement,
					publisherName:
						onClickAllocatedLeadPublisherDecrement[0].publisherName,
				});
			});
		//Sonali-Task 3176-get pacing details publisher wise on edit end date popup
		fetch("/publisher/getPacingDetailsForPublisher", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((publisherPacingDetails) => {
				if (publisherPacingDetails.length > 0) {
					this.setState(
						{ publisherPacingDetails: publisherPacingDetails },
						function () {
							this.setState({
								pacingUnit: this.state.publisherPacingDetails[0].pacingUnit,
							});
						}
					);
				} else {
				}
			});
	} // end of handleAllocatedLeads

	/**
	 * @author Supriya Gore
	 * @param  Description handle the date allocated leads
	 * @return Description return All date allocated leads
	 */
	handleChangeEndDate(e) {
		var endDate = e.target.value;
		this.setState({
			endDate: endDate,
			alertBorder: "0px solid white",
			alertDisplay: "none",
		});
	} //end of handleChangeEndDate
	//snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date(added fun handlechangecampenddate)
	handleChangeCampEndDate(e) {
		var campEndDate = e.target.value;
		this.setState(
			{
				campEndDate: campEndDate,
				alertBorder: "0px solid white",
				alertDisplay: "none",
			},
			function () {
				//this.NewPacingTable();
			}
		);
		if (this.state.pacingTableDisplay !== "none") { //Rutuja 2978 changed = to ==
			this.setState({ pacingTableDisplay: "none" });
		}
	}
	//snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date(added fun for new pacing table)
	NewPacingTable(i, e) {
		if (this.state.publisherPacingDetails.length > 0) {
			if (this.state.pacingTableDisplay.toString() === "none") { //Rutuja 2978 == to === added tostring
				this.setState({ pacingTableDisplay: "block" });
			}
			var d1,
				d2,
				d3 = "";
			d1 = new Date(this.state.onClickAllocatedLeadPublisher[0].startDate);
			d2 = new Date(this.state.campEndDate);
			d3 = new Date(this.state.onClickAllocatedLeadPublisher[0].campEndDate);
			let months;
			months = (d2.getFullYear() - d1.getFullYear()) * 12;
			months -= d1.getMonth() + 1;
			months += d2.getMonth();
			months = months + 2;

			//let m=this.state.publisherPacingDetails.length+
			if (this.state.publisherPacingDetails.length === months) {
			} else {
				let diff = months - this.state.publisherPacingDetails.length;

				for (let i = 0; i < diff; i++) {
					var indexpreviousMonth = d3.getMonth() + 1;
					let currentmonth = monthArray[indexpreviousMonth + i];

					var year = d2.getFullYear();
					var currentDate;//Rutuja 2978 declare current date outside ifelse
					var currentDate1 = new Date(year, indexpreviousMonth + i + 1, 0);
					if (indexpreviousMonth + i + 1 <= 9) {
						 currentDate =
							currentDate1.getFullYear() +
							"-" +
							"0" +
							(indexpreviousMonth + i + 1) +
							"-" +
							currentDate1.getDate();
					} else {
						 currentDate =
							currentDate1.getFullYear() +
							"-" +
							(indexpreviousMonth + i + 1) +
							"-" +
							currentDate1.getDate();
					}
					var pacingCarryForward = "yes";
					if (i === diff - 1) { //Rutuja 2978 changed == to ===
						currentDate = this.state.campEndDate;
						pacingCarryForward = "no";
					} else {
						pacingCarryForward = "yes";
					}
					let pacingTempObject = {
						rowId: this.state.publisherPacingDetails.length + 1,
						pacingMonth: currentmonth,
						pacingLeadCount: "0",
						pacingPercentage: "0",
						pacingEndDate: currentDate,
						pacingCarryForward: pacingCarryForward,
						pacingUnit: this.state.publisherPacingDetails[0].pacingUnit,
					};
					this.state.publisherPacingDetails.push({ ...pacingTempObject });
				}
				this.setState({
					publisherPacingDetails: this.state.publisherPacingDetails,
				});
			}
		}
	}
	/**
	 * @author Supriya Gore
	 * @param  Description handle the update lead permission by publisher
	 * @return Description return successfully update permission
	 */
	handleUpdateLeadPermission(e) {
		var publisherID = e.target.getAttribute("name");
		this.setState({ updateLeadsPID: publisherID });
	} //end of handleUpdateLeadPermission
	/**
	 * @author Supriya Gore
	 * @param  Description handle the update leads permission
	 * @return Description return All update leads permission
	 */
	handleChangeUpdateLeadsPermission(e) {
		var updateLeadsTime = e.target.value;
		this.setState({ updateLeadsTime: updateLeadsTime });
	} //end of handleChangeUpdateLeadsPermission

	/**
	 * @author Supriya Gore
	 * @param  Description handle the date allocated leads
	 * @return Description return All date allocated leads
	 */
	handleChangeEndDatePublisher(e) {
		e.preventDefault();
		e.target.className += " was-validated";
		if (this.validateForm1() === false) {
			$("#endDateModal").modal("show");
			//Sandeep-task-3227-added code to show modal
			return;
		} else {
			$("#endDateModal").modal("hide");
			//Sandeep-task-3227-added code to hide modal
			this.setState({
				alertBorder: "0px solid white",
			});
		}
		this.NewPacingTable(); //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date(call for newpacingtable)
		var endDate = this.state.endDate;
		let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		let timestamp = new Date();

		let data = {
			endDate: endDate,
			publisherDetail: this.state.onClickAllocatedLeadPublisher,
			// timezone,//Rutuja 2978 commented as timezone is used below 
			// timestamp,//Rutuja 2978 commented as timestamp is used below 
			campEndDate: this.state.campEndDate,
			publisherPacingDetails: this.state.publisherPacingDetails,
			campID: this.state.campID,
			timezone: timezone, //Sonali-Bug-3239-for generating IO at BE
			timestamp: timestamp, //Sonali-Bug-3239-for generating IO at BE
		}; // karan-task-3714-vapt header and query params
		//snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
		if ( //Rutuja task 2978 changed == to === and != to !==
			Date.parse(endDate) !==
				Date.parse(this.state.onClickAllocatedLeadPublisher[0].endDate) &&
			Date.parse(this.state.campEndDate) ===
				Date.parse(this.state.onClickAllocatedLeadPublisher[0].campEndDate)
		) {
			fetch("/publisher/editEndDatePublisher", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				}, // karan-task-3714-vapt header and query params
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((response) => {
					console.log("Return with response");
					Swal.fire({
						text:
							"Publisher " +
							this.state.publisherName +
							"'s End Date has been Changed and Insertion Order document generated",
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
					this.setState({
						inputClass: "disabled",
						alertBorder: "0px solid white",
					});
				});
		} //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
		else if ( //Rutuja task 2978 changed == to === and != to !==
			Date.parse(endDate) ===
				Date.parse(this.state.onClickAllocatedLeadPublisher[0].endDate) &&
			Date.parse(this.state.campEndDate) !==
				Date.parse(this.state.onClickAllocatedLeadPublisher[0].campEndDate)
		) {
			fetch("/publisher/editEndDatePublisher", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				}, // karan-task-3714-vapt header and query params
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((response) => {
					console.log("Return with response");
					Swal.fire({
						text: "Campaign's End Date has been Changed and Insertion Order document generated",
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
					this.setState({
						inputClass: "disabled",
						alertBorder: "0px solid white",
					});
				});
		} //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date
		else if ( //Rutuja task 2978 changed == to === and != to !==
			Date.parse(endDate) !==
				Date.parse(this.state.onClickAllocatedLeadPublisher[0].endDate) &&
			Date.parse(this.state.campEndDate) !==
				Date.parse(this.state.onClickAllocatedLeadPublisher[0].campEndDate)
		) {
			fetch("/publisher/editEndDatePublisher", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				}, // karan-task-3714-vapt header and query params
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((response) => {
					console.log("Return with response");
					Swal.fire({
						text:
							"Publisher " +
							this.state.publisherName +
							"'s End Date, Campaign's End Date has been Changed and Insertion Order document generated",
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
					this.setState({
						inputClass: "disabled",
						alertBorder: "0px solid white",
					});
				});
		} else {
		}
	} // end of handleChangeEndDatePublisher

	/**
	 * @author Supriya Gore
	 * @param  Description handle the date allocated leads
	 * @return Description return All date allocated leads
	 */
	submitUpdateLeadsPermission(e) {
		e.preventDefault();
		let errors = {};
		// karan-task-3714-vapt header and query params
		var parsed = this.props.location.state;
		var campID;
		if (parsed !== undefined) {
			campID = parsed.campID;
			var updateLeadsTime = this.state.updateLeadsTime;
			var updateLeadsPID = this.state.updateLeadsPID;
			var pattern = new RegExp(/^[^0|\D]\d{0,9}(\.\d{1,2})?$/);

			if (pattern.test(updateLeadsTime)) {
				var updateLeadsTimeMins = updateLeadsTime.split(".");
				if (
					updateLeadsTimeMins[1] === undefined ||
					updateLeadsTimeMins[1] === ""
				) {
					updateLeadsTimeMins[1] = 0;
				}
				if (parseInt(updateLeadsTimeMins[1]) < 60) {
					errors["valueError"] = "";
					errors["minsError"] = "";
					this.setState({ errors: errors });

					let data = {
						updateLeadsTime: updateLeadsTime,
						updateLeadsPID: updateLeadsPID,
						campID: campID,
					}; // karan-task-3714-vapt header and query params

					fetch("/publisher/updateLeadsPermission", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						}, // karan-task-3714-vapt header and query params
						body: JSON.stringify(data),
					})
						.then((res) => res.json())
						.then((response) => {
							console.log("Return with response");
							Swal.fire({
								text: "Publisher Update Lead Permission Accepted",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								preConfirm: () => {
									window.location.reload();
								},
							});
							this.setState({
								inputClass: "disabled",
								alertBorder: "0px solid white",
							});
						});
				} else {
					errors["minsError"] = <li>Please Enter Proper Minutes</li>;
					this.setState({ errors: errors });
				}
			} else {
				errors["minsError"] = "";
				errors["valueError"] = <li>Please Enter Proper Time</li>;
				this.setState({ errors: errors });
			} // karan-task-3714-vapt header and query params
		}
	} // end of submitUpdateLeadsPermission

	/**
	 * @author Supriya Gore
	 * @param  Description handle the increament decrement allocated leads
	 * @return Description return All increament decrement allocated leads
	 */
	handleDecrementLeads(e) {
		var name = e.target.name;
		var value = e.target.value;
		var value1 = e.target.value;
		let errors = {};
		var allocatedLead = e.target.id;
		value = parseInt(value);
		var decrementalLeads = value; // decrement Leads

		if (Number.isNaN(decrementalLeads) === true) {
			decrementalLeads = 0;
		}
		if (decrementalLeads < 0) {
			decrementalLeads = 0;
		}
		this.setState({ decrementalLeads: decrementalLeads });
		allocatedLead = parseInt(allocatedLead);
		var pattern = new RegExp(/^(|[1-9]\d*)$/);
		if (!pattern.test(value)) {
			errors["wholenumber"] = (
				<li>Please enter a whole number for lead decremental</li>
			);
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
		} else {
			errors["wholenumber"] = "";

			this.setState({
				errors: errors,
				alertBorder: "0px solid white",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
		}

		if (value > allocatedLead) {
			errors["commonError"] = <li>Please enter smaller value</li>;
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				inputClass: "disabled",
			});
		} else if (value === allocatedLead) {
			errors["commonError"] = (
				<li>Please use cancel operation to decrement all the leads</li>
			);
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				inputClass: "disabled",
			});
		} else {
			errors["commonError"] = "";
			this.setState({
				errors: errors,
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				inputClass: "btn add-button",
			});
		}
		this.setState({
			leadIncDecStatus: name,
			leadsToIncDecValue: value1,
		});
	} // end of handleDecrementLeads

	/**
	 * @author Supriya Gore
	 * @param  Description handle the increament decrement allocated leads
	 * @return Description return All increament decrement allocated leads
	 */
	validateForm() {
		let errors = {};
		let incdecleads = true;
		let formIsValid = true;
		if (
			this.state.leadsToIncDecValue === undefined ||
			this.state.leadsToIncDecValue === "" ||
			this.state.leadsToIncDecValue === null
		) {
			incdecleads = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
		} else {
			var pattern = new RegExp(/^(|[1-9]\d*)$/);
			if (!pattern.test(this.state.leadsToIncDecValue)) {
				errors["commonError"] = (
					<li>Please enter a whole number for lead decremental</li>
				);
				incdecleads = false;
				this.setState({
					alertBorder: "1px solid red",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
				});
			} else {
			}
		}
		if (incdecleads === false) {
			formIsValid = false;
			this.setState({
				alertBorder: "1px solid red",
			});
		} else {
			formIsValid = true;
		}

		this.setState({
			errors: errors,
			alertBorder: "1px solid red",
			alertWidth: "1000px",
			alertAlign: "center",
			alertDisplay: "block",
		});

		return formIsValid;
	} //end of validform
	validateForm1() {
		let errors = {};
		let formIsValid = true;
		let newDecdate = true;
		var dt = dateTime.create();
		var formatted = dt.format("Y-m-d");
		var firstLeadDeliveryDate1 = this.state.firstLeadDeliveryDate1;
		var startDate1 = this.state.startDate;
		//Sandeep-task-2912-added validation for firstLeadDelivery date with end date
		if (this.state.endDate < firstLeadDeliveryDate1) {
			errors["deliveryDateErr"] =
				"Please enter end date greater than firstLeadDelivery date";
			newDecdate = false;
			this.setState({
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
		}
		//sandeep-task-2862-added validation for end date
		if (this.state.endDate < startDate1) {
			errors["commonError"] = "Please enter end date greater than start date";
			newDecdate = false;
			this.setState({
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
		}

		if (this.state.endDate < formatted) {
			//sandeep-task-2714-added validation message for end date
			errors["commonError"] =
				"Please enter end date as current date or greater than current date";
			newDecdate = false;
			this.setState({
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
		}

		if (this.state.endDate > this.state.campEndDate) {
			errors["commonError"] =
				"Please enter end date smaller than campaign's end date";
			newDecdate = false;
			this.setState({
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				EndDateDisplay: "block",
			});
		} //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date

		if (newDecdate === false) {
			formIsValid = false;
		} else {
			formIsValid = true;
		}

		this.setState({
			errors: errors,
			alertWidth: "1000px",
			alertAlign: "center",
			alertDisplay: "block",
		});

		return formIsValid;
	} //end of validform1

	handleDecPublisherLeads(e) {
		e.preventDefault();
		e.target.className += " was-validated";
		if (this.validateForm() === false) {
			$("#decrementalModal").modal("show");
			//Sandeep-task-3227-added code to show modal
			return;
		} else {
			this.setState({
				alertBorder: "0px solid white",
			});
		}
		$("#decrementalModal").modal("hide");
		//Sandeep-task-3227-added code to hide modal

		let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		let timestamp = new Date();
		let data = {
			decrementLeads: this.state.leadsToIncDecValue,
			publisherDetail: this.state.onClickAllocatedLeadPublisher,
			publisherDec: this.state.onClickAllocatedLeadPublisherDecrement,
			timezone,
			timestamp,
		}; //karan-task-3796-vapt remove ids
		fetch("/publisher/decrementPublisherLeads", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		}) //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((response) => {
				if (this.state.leadIncDecStatus === "DecrementalLead") {
					var mesg =
						"Publisher " +
						this.state.publisherName +
						"'s Leads has been decremented and Insertion Order document generated.";
					Swal.fire({
						text: mesg,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
					this.setState({
						successMsg: mesg,
						inputClass: "disabled",
						alertBorder: "0px solid white",
					});
				} else {
				}
			});
	} //enf of handleDecPublisherLeads

	/**
	 * @author Supriya Gore
	 * @param  Description handle the for Back Button Call to agency Live campaign
	 * @return Description return Back Button
	 */
	handleBackButtonToLive(e) {
		window.location.href = "/allocatingCampaign";
	} //end of  handleBackButtonToLive

	handleBackButtonTodashBoard(e) {
		e.preventDefault(); // karan-task-3921-back button issue
		const { user } = this.props.auth;
		this.props.history.push("/campaignList", { agencyID: user.id }); //karan-task-3684-replace query params
	} //end of handleBackButtonTodashBoard

	handleBackButtonTocampaignList(e) {
		e.preventDefault(); // karan-task-3921-back button issue
		const { user } = this.props.auth;
		this.props.history.push("/campaignList", { agencyID: user.id }); //karan-task-3684-replace query params
	} //enf of handleBackButtonTocampaignList

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			// karan-task-3714-vapt header and query params
			var parsed = this.props.location.state;
			var parentCampID, reallocationID, campID;
			if (parsed !== undefined) {
				parentCampID = parsed.parentCampID;
				reallocationID = parsed.reallocationID;
				campID = parsed.campID;
				//this.state.Step = parsed.step;
				this.setState({Step: parsed.step}) //Rutuja Task 2978 commented this.state and used this.setState
				this.setState({ parentCampID, reallocationID, campID }); // karan-task-3714-vapt header and query params
				this.showLeadAssignTOPublisher();

				fetch("agency/newAllocation?campID=" + campID, {
					method: "GET", //shivani-task 2993-added method for this api.
				}) // karan-task-3714-vapt header and query params
					.then((res) => res.json())
					.then((campaignDetailAllocates) => {
						this.setState({ campaignDetailAllocates: campaignDetailAllocates });
					})
					.catch(function (err) {
						console.log(err);
					});
				fetch("agency/newAllocationSum?campID=" + campID, {
					method: "GET", //shivani-task 2993-added method for this api.
				}) // karan-task-3714-vapt header and query params
					.then((res) => res.json())
					.then((sumOfAllocatedLeads) => {
						this.setState({ sumOfAllocatedLeads: sumOfAllocatedLeads });
					})
					.catch(function (err) {
						console.log(err);
					});

				fetch("agency/editAllocationStatus?campID=" + campID, {
					method: "GET", //shivani-task 2993-added method for this api.
				}) // karan-task-3714-vapt header and query params
					.then((res) => res.json())
					.then((editAllocationStatus) => {
						this.setState({ editAllocationStatus: editAllocationStatus });
					})
					.catch(function (err) {
						console.log(err);
					});
			} else {
				this.props.history.push("/newDashboard");
			} // karan-task-3714-vapt header and query params
		}
	} // End Of componentDidMount

	showAssetwiseLeads(e) {
		var campID = e.target.id;
		var pID = e.target.getAttribute("pID");

		fetch("agency/getAssetWiseLeads?campID=" + campID + "&pID=" + pID, {
			method: "GET", //shivani-task 2993-added method for this api.
		}) // karan-task-3714-vapt header and query params
			.then((res) => res.json())
			.then((assetwiseLead) => this.setState({ assetwiseLead: assetwiseLead }))
			.catch(function (err) {
				console.log(err);
			});
	} // end of showAssetwiseLeads

	/**
	 * @author Somnath Keswad
	 * @param  Description download Insertion Order Pdf Document
	 * @return Description return pdf blob
	 */
	downloadIODocument(e) {
		var campID = e.target.getAttribute("campID");
		var pID = e.target.getAttribute("pID");
		var documentName = "Insertion_Order_Document_" + campID + ".pdf";
		var ext = documentName.split(".").pop();
		this.setState({ ext: ext, filename: documentName });
		//Somnath Task-3909, Add pID & from keys
		fetch("/publisherDashboard/getIODocument?campID=" + campID+"&pID="+pID+"&from=Agency", {
			method: "GET", //shivani-task 2993-added method for this api.
		}) //karan-task-3717-removed id
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.downloadIOFile);
	} /*** End of downloadIODocument */

	/**
	 * @author Somnath Keswad
	 * @param  Description download Insertion Order Document
	 * @return
	 */
	downloadIOFile(blob) {
		let url = this.state.filename;
		let fileName = url.substring(url.lastIndexOf("\\") + 1);
		let pdfFileDownloadName = fileName;
		let newBlob = new Blob([this.state.downloadFiles], {
			type: "application/pdf",
		});
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}
		const data = window.URL.createObjectURL(newBlob);
		let link = document.createElement("a");
		link.href = data;
		link.download = pdfFileDownloadName;
		link.click();
	} /**** End of downloadIOFile */

	render() {
		const { user } = this.props.auth;
		// let mergedPendingCampaignList = [];
		// mergedPendingCampaignList = this.state.campaignDetailAllocates.map((e) =>
		// Object.assign({}, e, this.state.sumOfAllocatedLeads.find((e1) => e1.campID === e.campID) || {})
		// );
		this.state.onClickAllocatedLeadPubList =
			this.state.onClickAllocatedLeadPubList.map((e) =>
				Object.assign(
					{},
					e,
					this.state.editAllocationStatus.find((e1) => e1.pID === e.pID) || {}
				)
			);
		this.state.onClickAllocatedLeadPubList =
			this.state.onClickAllocatedLeadPubList.map((e) =>
				Object.assign(
					{},
					e,
					this.state.assignDeliveredLead.find((e1) => e1.pID === e.pID) || {}
				)
			);
		return (
			<div>
				<Navigation />
				<div style={{ paddingTop: "90px", paddingBottom: "60px" }}>
					{this.state.Step === "1" ? (
						<div class=" col-lg-4" style={{ paddingLeft: "20px" }}>
							<a
								href="#/"
								onClick={this.handleBackButtonTodashBoard}
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back to Dashboard"
								/>
							</a>
						</div>
					) : this.state.Step === "2" ? (
						<div class=" col-lg-4" style={{ paddingLeft: "20px" }}>
							<a
								href="#/"
								onClick={this.handleBackButtonTocampaignList}
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back to Campaign List"
								/>
							</a>
						</div>
					) : (
						<div class=" col-lg-4" style={{ paddingLeft: "20px" }}>
							<a
								href="#/"
								onClick={this.handleBackButtonTodashBoard}
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back To Campaign"
								/>
							</a>
						</div>
					)}
					{/* rutuja-task-4319-Responsiveness issue  */}
					<div style={{ paddingTop: "40px" }}></div>
					<div
						class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
						style={{ paddingBottom: "60px" }}>
							<h2
					
						style={{
							fontSize: "20px",
							fontWeight: "bold",
							color: "#056eb8",
							textAlign:"center",
							paddingBottom: "20px",
						}}>
						Campaign ID:{this.state.parentCampID}
					
					</h2>
						<div style={{ border: "1px solid #cdcdcd" }}>
							{this.state.onClickAllocatedLead.map((onClickAllocatedLead) => (
								<div class="row">
									<div class="col-sm-4">
										<label style={{ width: "110px" ,  fontWeight: "normal", color: "#000080", }}>
											Campaign Type
										</label>
										:&nbsp;{onClickAllocatedLead.ABM}
									</div>
									<div class="col-sm-4">
									<label style={{ width: "110px" ,  fontWeight: "normal", color: "#000080", }}>
											Start Date
										</label>
										:&nbsp;{onClickAllocatedLead.campStartDate}
									</div>
									<div class="col-sm-4">
									<label style={{ width: "110px" ,  fontWeight: "normal", color: "#000080", }}>
											End Date
										</label>
										:&nbsp;{onClickAllocatedLead.campEndDate}
									</div>
									<div class="col-sm-4">
									<label style={{ width: "110px" ,  fontWeight: "normal", color: "#000080", }}>
											Total Leads
										</label>
										:&nbsp;{onClickAllocatedLead.leadAllocation}
									</div>
									<div class="col-sm-4">
									<label style={{ width: "110px" ,  fontWeight: "normal", color: "#000080", }}>
											Allocated Leads
										</label>
										:&nbsp;
										{onClickAllocatedLead.allocatedLead == null
											? "0"
											: onClickAllocatedLead.allocatedLead}
									</div>
									<div class="col-sm-4">
									<label style={{ width: "110px" ,  fontWeight: "normal", color: "#000080", }}>
											Remaining Leads
										</label>
										:&nbsp;
										{onClickAllocatedLead.leadAllocation -
											onClickAllocatedLead.allocatedLead}
									</div>
									{user.role === "AC" ? (
										<div class="col-sm-4">
												<label style={{ width: "110px" ,  fontWeight: "normal", color: "#000080", }}>
												Total Budget
											</label>
											:&nbsp;{onClickAllocatedLead.budget}&nbsp;
											{onClickAllocatedLead.currency}
										</div>
									) : (
										""
									)}

									{user.role === "AC" ? (
										<div class="col-sm-4">
												<label style={{ width: "110px" ,  fontWeight: "normal", color: "#000080", }}>
												Allocated Budget
											</label>
											:&nbsp;
											{onClickAllocatedLead.allocatedBudget == null
												? "0"
												: onClickAllocatedLead.allocatedBudget}
											&nbsp;{onClickAllocatedLead.currency}
										</div>
									) : (
										""
									)}

									{user.role === "AC" ? (
										<div class="col-sm-4 ">
												<label style={{  fontWeight: "normal", color: "#000080", }}>
												Remaining Budget
											</label>
											:&nbsp;
											{onClickAllocatedLead.remainingBudget == null
												? "0"
												: onClickAllocatedLead.remainingBudget}
											&nbsp;{onClickAllocatedLead.currency}
										</div>
									) : (
										""
									)}
								</div>
							))}
						</div>

						<br />
						<div class="table-responsive-lg">
							<table class=" table table-striped  table-bordered">
								<thead>
									<tr class="info">
										<th class="table-header" style={{ width: "18%" }}>
											Publisher Name
										</th>
										<th class="table-header" style={{ width: "32%" }}>
											Progress Status
										</th>
										{user.role === "AC" ||
										user.role === "ANC" ||
										user.role === "AQA" ? (
											<th style={{ width: "18%" }} class="table-header">
												Status
											</th>
										) : (
											""
										)}

										{this.state.campaignStatus === "Completed" ||
										this.state.campaignStatus === "Live_Incomplete" ||
										this.state.campaignStatus === "PausedIncomplete" ||
										this.state.campaignStatus === "Paused" ? (
											<th style={{ width: "13%" }} class="table-header">
												Actions
											</th>
										) : user.role === "AC" ||
										  user.role === "ANC" ||
										  user.role === "AQA" ? (
											<th style={{ width: "13%" }} class="table-header">
												Actions
											</th>
										) : (
											""
										)}
										{/*change*/}
									</tr>
								</thead>
								<tbody>
									{this.state.onClickAllocatedLeadPubList.map(
										(onClickAllocatedLeadPubList) => (
											<tr>
												<td style={{ textAlign: "center", width: "12%" }}>
													<label id="label">
														<img
															src={onClickAllocatedLeadPubList.pID + ".png"}
															alt=" "
															style={{ width: "32%" }}></img>
														{onClickAllocatedLeadPubList.publisherName}
													</label>
													<hr
														style={{
															marginTop: "1px",
															marginBottom: "1px",
														}}></hr>
													<br />
													<div class="col-lg-2" style={{ display: "contents" }}>
														<table
															style={{
																width: "100%",
																borderCollapse: "collapse",
																borderStyle: "hidden",
															}}>
															<tr
																style={{
																	backgroundColor: "#fff",
																	borderCollapse: "collapse",
																	borderStyle: "hidden",
																}}>
																<td style={{ border: "none" }}>
																	<label
																		id="label"
																		style={{
																			float: "left",
																			borderCollapse: "collapse",
																			border: "hidden",
																		}}>
																		Start Date
																	</label>
																</td>
																<td>
																	<label
																		style={{
																			float: "left",
																			fontWeight: "400",
																		}}>
																		{onClickAllocatedLeadPubList.startDate}
																	</label>
																</td>
															</tr>

															<tr>
																<td>
																	<label id="label" style={{ float: "left" }}>
																		End Date
																	</label>
																</td>
																<td>
																	<label
																		style={{
																			float: "left",
																			fontWeight: "400",
																		}}>
																		{" "}
																		{onClickAllocatedLeadPubList.endDate}
																	</label>{" "}
																</td>
															</tr>

															<tr
																style={{
																	backgroundColor: "#fff",
																	borderCollapse: "collapse",
																	borderStyle: "hidden",
																}}>
																<td>
																	<label id="label" style={{ float: "left" }}>
																		Days Left
																	</label>
																</td>
																<td>
																	{" "}
																	<label
																		style={{
																			float: "left",
																			fontWeight: "400",
																		}}>
																		{onClickAllocatedLeadPubList.daysLeft <
																		0 ? (
																			<span style={{ color: "red" }}>0</span>
																		) : onClickAllocatedLeadPubList.daysLeft <=
																		  2 ? (
																			<span style={{ color: "red" }}>
																				{onClickAllocatedLeadPubList.daysLeft}
																			</span>
																		) : onClickAllocatedLeadPubList.daysLeft <=
																				5 &&
																		  onClickAllocatedLeadPubList.daysLeft >
																				2 ? (
																			<span style={{ color: "orange" }}>
																				{onClickAllocatedLeadPubList.daysLeft}
																			</span>
																		) : (
																			<span style={{ color: "green" }}>
																				{onClickAllocatedLeadPubList.daysLeft}
																			</span>
																		)}
																	</label>
																	<br />
																</td>
															</tr>

															<tr>
																<td>
																	{user.role === "AC" ? (
																		<label
																			id="label"
																			style={{
																				float: "left",
																				fontWeight: "400",
																			}}>
																			CPL
																		</label>
																	) : (
																		""
																	)}
																</td>
																<td>
																	{user.role === "AC" ? (
																		<label
																			style={{
																				float: "left",
																				fontWeight: "400",
																			}}>
																			{onClickAllocatedLeadPubList.cpl}&nbsp;
																			{onClickAllocatedLeadPubList.currency}
																		</label>
																	) : (
																		""
																	)}
																</td>
															</tr>

															<tr
																style={{
																	backgroundColor: "#fff",
																	borderCollapse: "collapse",
																	borderStyle: "hidden",
																}}>
																<td>
																	{user.role === "AC" ? (
																		<label
																			id="label"
																			style={{
																				float: "left",
																				fontWeight: "400",
																			}}>
																			Budget
																		</label>
																	) : (
																		""
																	)}
																</td>
																<td>
																	{user.role === "AC" ? (
																		<label
																			style={{
																				float: "left",
																				fontWeight: "400",
																			}}>
																			{
																				onClickAllocatedLeadPubList.allocatedBudget
																			}
																			&nbsp;
																			{onClickAllocatedLeadPubList.currency}
																		</label>
																	) : (
																		""
																	)}
																</td>
															</tr>
														</table>
													</div>
												</td>

												<td>
													<table style={{ width: "100%" }}>
														<tr>
															<th colSpan="1">
																<label
																	id="label"
																	style={{
																		color: "orange",
																		fontSize: "14px",
																		fontWeight: "bold",
																	}}>
																	Allocation Status
																</label>
															</th>
															<th>
																{" "}
																{onClickAllocatedLeadPubList.allocatedLead}
															</th>
															<th colSpan="2">
																<label
																	id="label"
																	style={{
																		color: "orange",
																		fontSize: "14px",
																		fontWeight: "bold",
																	}}>
																	Delivery Status
																</label>{" "}
															</th>
														</tr>

														<tr>
															{/* Sandeep-task-3217-label change from Accept to acceptted */}
															<td>
																{" "}
																<label id="label">Accepted</label>
															</td>
															<td>
																{onClickAllocatedLeadPubList.Accept}
																<br />
															</td>
															<td>
																{" "}
																<label id="label">Delivered</label>
															</td>
															<td>
																{onClickAllocatedLeadPubList.deliveredLead
																	? onClickAllocatedLeadPubList.deliveredLead
																	: "0"}{" "}
																<br />
															</td>
														</tr>

														<tr style={{ backgroundColor: "#fff" }}>
															{/* Sandeep-task-3217-label change from Allocate to Allocated */}
															<td>
																<label id="label">Allocated</label>
															</td>{" "}
															<td>
																{onClickAllocatedLeadPubList.Assign} <br />
															</td>
															<td>
																<label id="label">Balance</label>
															</td>
															<td>
																{onClickAllocatedLeadPubList.Accept +
																	onClickAllocatedLeadPubList.Assign +
																	onClickAllocatedLeadPubList.Counter +
																	onClickAllocatedLeadPubList.AcceptedCounter -
																	(onClickAllocatedLeadPubList.acceptedLead +
																		onClickAllocatedLeadPubList.agencyInternalReviewLead +
																		onClickAllocatedLeadPubList.qaReviewLead) >
																0
																	? onClickAllocatedLeadPubList.Accept +
																	  onClickAllocatedLeadPubList.Assign +
																	  onClickAllocatedLeadPubList.Counter +
																	  onClickAllocatedLeadPubList.AcceptedCounter -
																	  (onClickAllocatedLeadPubList.acceptedLead +
																			onClickAllocatedLeadPubList.agencyInternalReviewLead +
																			onClickAllocatedLeadPubList.qaReviewLead)
																	: 0}
																<br />
															</td>
														</tr>

														<tr>
															{/* Sandeep-task-3217-label change from Counter to Countered */}
															<td>
																{" "}
																<label id="label">Countered</label>
															</td>{" "}
															<td>
																{onClickAllocatedLeadPubList.Counter}
																<br />
															</td>
															<td>
																{" "}
																<label id="label">Accepted</label>
															</td>{" "}
															<td>
																<span
																	style={{
																		color: "#20EB10",
																		fontWeight: "600",
																	}}>
																	{onClickAllocatedLeadPubList.acceptedLead ? (
																		this.state.requiredLeadPerAsset ===
																		"Yes" ? (
																			<a
																				href="#/"
																				id={onClickAllocatedLeadPubList.campID}
																				parentCampID={
																					onClickAllocatedLeadPubList.parentCampID
																						? onClickAllocatedLeadPubList.parentCampID
																						: onClickAllocatedLeadPubList.campID
																				}
																				reallocationID={
																					onClickAllocatedLeadPubList.reallocationID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				data-toggle="modal"
																				data-target="#showLeadPerAsset"
																				onClick={this.showAssetwiseLeads.bind(
																					this
																				)}>
																				{onClickAllocatedLeadPubList.acceptedLead
																					? onClickAllocatedLeadPubList.acceptedLead
																					: 0}
																			</a>
																		) : onClickAllocatedLeadPubList.acceptedLead ? (
																			onClickAllocatedLeadPubList.acceptedLead
																		) : (
																			0
																		)
																	) : (
																		0
																	)}
																</span>
																<br />
															</td>
														</tr>

														<tr style={{ backgroundColor: "#fff" }}>
															<td>
																<label id="label">Accepted Counter</label>
															</td>{" "}
															<td>
																{onClickAllocatedLeadPubList.AcceptedCounter}
																<br />
															</td>
															<td>
																{" "}
																<label id="label">Rejected</label>
															</td>{" "}
															<td>
																<span
																	style={{ color: "red", fontWeight: "600" }}>
																	{onClickAllocatedLeadPubList.rejectedLead
																		? onClickAllocatedLeadPubList.rejectedLead
																		: "0"}{" "}
																</span>
																<br />
															</td>
														</tr>

														<tr>
															{/* Sandeep-task-3217-label change from reject to rejected */}
															<td>
																{" "}
																<label id="label">Rejected</label>
															</td>{" "}
															<td>
																{onClickAllocatedLeadPubList.Reject} <br />{" "}
															</td>
															<td>
																{" "}
																<label id="label">Agency Internal Review</label>
															</td>{" "}
															<td>
																{onClickAllocatedLeadPubList.agencyInternalReviewLead >
																0
																	? onClickAllocatedLeadPubList.agencyInternalReviewLead
																	: 0}{" "}
																<br />{" "}
															</td>
														</tr>

														<tr style={{ backgroundColor: "#fff" }}>
															<td>
																{" "}
																<label id="label">Rejected Counter</label>
															</td>{" "}
															<td>
																{onClickAllocatedLeadPubList.RejectedCounter}
																<br />
															</td>
														</tr>
														<tr style={{ backgroundColor: "#fff" }}>
															<td>
																{" "}
																<label id="label">
																	Cancel After Lead Upload
																</label>
															</td>{" "}
															<td>
																{onClickAllocatedLeadPubList.cancelALU}
																<br />
															</td>
															{/* sunita- task- 2941- changed the label from cancel ALU- Cancel After Lead Upload */}
														</tr>
													</table>
												</td>
												<td style={{ textAlign: "center" }}>
													{/* Sandeep-task-3217-added code for display Lead Delivery Status and Publisher Campaign Status */}
													<span style={{ textAlign: "left" }}>
														<label>Publisher Campaign Status -</label> &nbsp;
														<label
															id="label"
															style={{ color: "#1BBA0F", fontWeight: "bold" }}>
															{onClickAllocatedLeadPubList.status ===
															"Cancel ALU"
																? "Allocation Cancel"
																: onClickAllocatedLeadPubList.status ===
																  "Assign"
																? "Allocated"
																: onClickAllocatedLeadPubList.status ===
																  "Accept"
																? "Accepted"
																: onClickAllocatedLeadPubList.status ===
																  "Counter"
																? "Countered"
																: onClickAllocatedLeadPubList.status}
															{/**/}
														</label>
													</span>
													<hr></hr>
													<label>Lead Delivery Status</label>
													<br></br>
													<br></br>
													{this.state.requiredLeadPerAsset === "Yes" ? (
														<Progress
															strokeWidth="15"
															type="circle"
															strokeColor={{
																"0%": "#108ee9",
																"100%": "#87d068",
															}}
															percent={
																(onClickAllocatedLeadPubList.totAcceptedLead /
																	(onClickAllocatedLeadPubList.totAcceptedLead +
																		(onClickAllocatedLeadPubList.allocatedLead -
																			onClickAllocatedLeadPubList.totAcceptedLead))) *
																100
																	? Math.round(
																			(onClickAllocatedLeadPubList.totAcceptedLead /
																				(onClickAllocatedLeadPubList.totAcceptedLead +
																					(onClickAllocatedLeadPubList.allocatedLead -
																						onClickAllocatedLeadPubList.totAcceptedLead))) *
																				100
																	  )
																	: 0
															}
														/>
													) : (
														<Progress
															strokeWidth="15"
															type="circle"
															strokeColor={{
																"0%": "#108ee9",
																"100%": "#87d068",
															}}
															percent={
																(onClickAllocatedLeadPubList.acceptedLead /
																	(onClickAllocatedLeadPubList.acceptedLead +
																		(onClickAllocatedLeadPubList.allocatedLead -
																			onClickAllocatedLeadPubList.deliveredLead))) *
																100
																	? Math.round(
																			(onClickAllocatedLeadPubList.acceptedLead /
																				(onClickAllocatedLeadPubList.acceptedLead +
																					(onClickAllocatedLeadPubList.allocatedLead -
																						onClickAllocatedLeadPubList.deliveredLead))) *
																				100
																	  )
																	: 0
															}
														/>
													)}
												</td>
												{user.role === "AQA" ? (
													<td>
														{onClickAllocatedLeadPubList.status === "Accept" ||
														onClickAllocatedLeadPubList.status ===
															"LP_Pending" ? (
															<a
																href="#/" //Rutuja 2978 
																id={onClickAllocatedLeadPubList.campID}
																name={onClickAllocatedLeadPubList.pID}
																parentCampID={
																	onClickAllocatedLeadPubList.parentCampID
																}
																reallocationID={
																	onClickAllocatedLeadPubList.reallocationID
																}
																onClick={this.handlecreatives}>
																<i
																	class="fa fa-align-justify"
																	style={{ color: "#4d4da6" }}
																	id={onClickAllocatedLeadPubList.campID}
																	name={onClickAllocatedLeadPubList.pID}
																	parentCampID={
																		onClickAllocatedLeadPubList.parentCampID
																	}
																	reallocationID={
																		onClickAllocatedLeadPubList.reallocationID
																	}
																	onClick={this.handlecreatives}
																/>
																&nbsp;{"Creatives"}
																<hr
																	style={{
																		marginTop: "1px",
																		marginBottom: "1px",
																	}}></hr>
															</a>
														) : (
															<a href="#/" disabled="disabled">
																<i
																	class="fa fa-align-justify"
																	style={{ color: "gray" }}
																/>
																&nbsp;{"Creatives"}
																<hr
																	style={{
																		marginTop: "1px",
																		marginBottom: "1px",
																	}}></hr>
															</a>
														)}
													</td>
												) : (
													<span>
														{onClickAllocatedLeadPubList.campaignStatus ===
															"Completed" ||
														onClickAllocatedLeadPubList.campaignStatus ===
															"Live_Incomplete" ||
														onClickAllocatedLeadPubList.campaignStatus ===
															"PausedIncomplete" ||
														this.state.campaignStatus === "Paused" ||
														this.state.campaignStatus === "Cancel Campaign" ||
														onClickAllocatedLeadPubList.status ===
															"Cancel ALU" ||
														onClickAllocatedLeadPubList.status === "Reject" ? (
															//new code start
															<td style={{ width: "170px", height: "284px" }}>
																{user.role === "ANC" ? (
																	<a
																		href="#/"
																		disabled
																		id={onClickAllocatedLeadPubList.campID}
																		pID={onClickAllocatedLeadPubList.pID}
																		data-toggle="modal"
																		data-target="#endDateModal"
																		onClick={this.handleAllocatedLeads}
																		data-backdrop="static"
																		data-keyboard="false">
																		<i
																			disabled
																			class="fa fa-calendar"
																			style={{ color: "#4d4da6" }}
																			id={onClickAllocatedLeadPubList.campID}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.handleAllocatedLeads}
																		/>
																		&nbsp;{"Edit End Date"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																) : (
																	<a
																		href="#/"
																		id={onClickAllocatedLeadPubList.campID}
																		pID={onClickAllocatedLeadPubList.pID}
																		data-toggle="modal"
																		data-target="#endDateModal"
																		onClick={this.handleAllocatedLeads}
																		data-backdrop="static"
																		data-keyboard="false">
																		<i
																			class="fa fa-calendar"
																			style={{ color: "#4d4da6" }}
																			id={onClickAllocatedLeadPubList.campID}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.handleAllocatedLeads}
																		/>
																		&nbsp;{"Edit End Date"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																)}
																<br />

																{user.role === "AC" || user.role !== "AQA" ? (
																	onClickAllocatedLeadPubList.status ===
																		"Assign" ||
																	onClickAllocatedLeadPubList.status ===
																		"ReAssign" ||
																	onClickAllocatedLeadPubList.status ===
																		"AcceptedCounter" ? (
																		user.role === "ANC" ? (
																			<div>
																				<a
																					href="#/"
																					disabled
																					id={
																						onClickAllocatedLeadPubList.campID
																					}
																					publisherId={
																						onClickAllocatedLeadPubList.pID
																					}
																					data-toggle="modal"
																					data-target="#editAllocationModal"
																					onClick={this.handleCampaignID}
																					data-backdrop="static"
																					data-keyboard="false">
																					<i
																						disabled
																						class="fa fa-edit"
																						style={{ color: "#4d4da6" }}
																						id={
																							onClickAllocatedLeadPubList.campID
																						}
																						publisherId={
																							onClickAllocatedLeadPubList.pID
																						}
																					/>
																					&nbsp;{"Edit Allocation"}
																					<hr
																						style={{
																							marginTop: "1px",
																							marginBottom: "1px",
																						}}></hr>
																				</a>{" "}
																				<br />
																			</div>
																		) : (
																			<div>
																				<a
																					href="#/"
																					id={
																						onClickAllocatedLeadPubList.campID
																					}
																					publisherId={
																						onClickAllocatedLeadPubList.pID
																					}
																					data-toggle="modal"
																					data-target="#editAllocationModal"
																					onClick={this.handleCampaignID}
																					data-backdrop="static"
																					data-keyboard="false">
																					<i
																						class="fa fa-edit"
																						style={{ color: "#4d4da6" }}
																						id={
																							onClickAllocatedLeadPubList.campID
																						}
																						publisherId={
																							onClickAllocatedLeadPubList.pID
																						}
																					/>
																					&nbsp;{"Edit Allocation"}
																					<hr
																						style={{
																							marginTop: "1px",
																							marginBottom: "1px",
																						}}></hr>
																				</a>{" "}
																				<br />
																			</div>
																		)
																	) : (
																		""
																	)
																) : (
																	""
																)}

																{onClickAllocatedLeadPubList.status ===
																	"Cancel ALU" ||
																onClickAllocatedLeadPubList.status ===
																	"Reject" ? (
																	""
																) : user.role === "ANC" ? (
																	<a
																		href="#/"
																		disabled
																		id={onClickAllocatedLeadPubList.campID}
																		pid={onClickAllocatedLeadPubList.pID}
																		name={
																			onClickAllocatedLeadPubList.pID +
																			"#" +
																			onClickAllocatedLeadPubList.allocatedLead
																		}
																		data-toggle="modal"
																		data-target="#myModal"
																		data-backdrop="static"
																		data-keyboard="false"
																		onClick={this.passValue}>
																		<i
																			disabled
																			class="fa fa-times"
																			style={{ color: "#4d4da6" }}
																		/>
																		&nbsp;{"Cancel"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																) : (
																	<a
																		href="#/"
																		id={onClickAllocatedLeadPubList.campID}
																		pid={onClickAllocatedLeadPubList.pID}
																		name={
																			onClickAllocatedLeadPubList.pID +
																			"#" +
																			onClickAllocatedLeadPubList.allocatedLead
																		}
																		data-toggle="modal"
																		data-target="#myModal"
																		data-backdrop="static"
																		data-keyboard="false"
																		onClick={this.passValue}>
																		<i
																			class="fa fa-times"
																			style={{ color: "#4d4da6" }}
																		/>
																		&nbsp;{"Cancel"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																)}
																<br />
																{onClickAllocatedLeadPubList.status ===
																"Accept" ? (
																	user.role === "ANC" ? (
																		<a
																			href="#/"
																			disabled
																			data-toggle="modal"
																			data-target="#myModal11"
																			data-backdrop="static"
																			data-keyboard="false"
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}>
																			<i
																				disabled
																				class="fa fa-pause"
																				style={{ color: "#4d4da6" }}
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.passValue1}
																			/>
																			&nbsp;{"Pause"}
																			<hr
																				style={{
																					marginTop: "1px",
																					marginBottom: "1px",
																				}}></hr>
																		</a>
																	) : (
																		<a
																			href="#/"
																			data-toggle="modal"
																			data-target="#myModal11"
																			data-backdrop="static"
																			data-keyboard="false"
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}>
																			<i
																				class="fa fa-pause"
																				style={{ color: "#4d4da6" }}
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.passValue1}
																			/>
																			&nbsp;{"Pause"}
																			<hr
																				style={{
																					marginTop: "1px",
																					marginBottom: "1px",
																				}}></hr>
																		</a>
																	)
																) : onClickAllocatedLeadPubList.status ===
																  "Paused" ? (
																	user.role === "ANC" ? (
																		<a
																			href="#/"
																			disabled
																			data-toggle="modal"
																			data-target="#myModal12"
																			data-backdrop="static"
																			data-keyboard="false"
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}>
																			<i
																				disabled
																				class="fa fa-play"
																				style={{ color: "#4d4da6" }}
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.passValue1}
																			/>
																			&nbsp;
																			{"Resume"}
																			<hr
																				style={{
																					marginTop: "1px",
																					marginBottom: "1px",
																				}}></hr>
																		</a>
																	) : (
																		<a
																			href="#/"
																			data-toggle="modal"
																			data-target="#myModal12"
																			data-backdrop="static"
																			data-keyboard="false"
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}>
																			<i
																				class="fa fa-play"
																				style={{ color: "#4d4da6" }}
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.passValue1}
																			/>
																			&nbsp;
																			{"Resume"}
																			<hr
																				style={{
																					marginTop: "1px",
																					marginBottom: "1px",
																				}}></hr>
																		</a>
																	)
																) : onClickAllocatedLeadPubList.status ===
																  "LP_Pending" ? (
																	user.role === "ANC" ? (
																		<a
																			href="#/"
																			disabled
																			data-toggle="modal"
																			data-target="#myModal11"
																			data-backdrop="static"
																			data-keyboard="false"
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}>
																			<i
																				disabled
																				class="fa fa-pause"
																				style={{ color: "#4d4da6" }}
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.passValue1}
																			/>
																			&nbsp;
																			{"Pause"}
																			<hr
																				style={{
																					marginTop: "1px",
																					marginBottom: "1px",
																				}}></hr>
																		</a>
																	) : (
																		<a
																			href="#/"
																			data-toggle="modal"
																			data-target="#myModal11"
																			data-backdrop="static"
																			data-keyboard="false"
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}>
																			<i
																				class="fa fa-pause"
																				style={{ color: "#4d4da6" }}
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.passValue1}
																			/>
																			&nbsp;
																			{"Pause"}
																			<hr
																				style={{
																					marginTop: "1px",
																					marginBottom: "1px",
																				}}></hr>
																		</a>
																	)
																) : (
																	""
																)}
																<br />
																{onClickAllocatedLeadPubList.status ===
																	"Assign" ||
																onClickAllocatedLeadPubList.status ===
																	"Counter" ||
																onClickAllocatedLeadPubList.status ===
																	"ReAssign" ||
																onClickAllocatedLeadPubList.status ===
																	"Reject" ||
																onClickAllocatedLeadPubList.status ===
																	"AcceptedCounter" ||
																onClickAllocatedLeadPubList.status ===
																	"RejectedCounter" ? (
																	""
																) : user.role === "ANC" ? (
																	<a
																		href="#/"
																		disabled
																		id={onClickAllocatedLeadPubList.campID}
																		pID={onClickAllocatedLeadPubList.pID}
																		data-toggle="modal"
																		data-target="#decrementalModal"
																		onClick={this.handleAllocatedLeads}>
																		<i
																			disabled
																			class="fa fa-minus-square-o"
																			style={{ color: "#4d4da6" }}
																			id={onClickAllocatedLeadPubList.campID}
																			pID={onClickAllocatedLeadPubList.pID}
																		/>
																		&nbsp;{"Decremental"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																) : (
																	<a
																		href="#/"
																		id={onClickAllocatedLeadPubList.campID}
																		pID={onClickAllocatedLeadPubList.pID}
																		data-toggle="modal"
																		data-target="#decrementalModal"
																		onClick={this.handleAllocatedLeads}>
																		<i
																			class="fa fa-minus-square-o"
																			style={{ color: "#4d4da6" }}
																			id={onClickAllocatedLeadPubList.campID}
																			pID={onClickAllocatedLeadPubList.pID}
																		/>
																		&nbsp;{"Decremental"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																)}
																<br />
																{onClickAllocatedLeadPubList.status ===
																	"Accept" ||
																onClickAllocatedLeadPubList.status ===
																	"LP_Pending" ? (
																	<a
																		href="#/"//Rutuja 2978 
																		id={onClickAllocatedLeadPubList.campID}
																		name={onClickAllocatedLeadPubList.pID}
																		parentCampID={
																			onClickAllocatedLeadPubList.parentCampID
																		}
																		reallocationID={
																			onClickAllocatedLeadPubList.reallocationID
																		}
																		onClick={this.handlecreatives}>
																		<i
																			class="fa fa-align-justify"
																			style={{ color: "#4d4da6" }}
																			id={onClickAllocatedLeadPubList.campID}
																			name={onClickAllocatedLeadPubList.pID}
																			parentCampID={
																				onClickAllocatedLeadPubList.parentCampID
																			}
																			reallocationID={
																				onClickAllocatedLeadPubList.reallocationID
																			}
																			onClick={this.handlecreatives}
																		/>
																		&nbsp;{"Creatives"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																) : (
																	""
																)}
																{onClickAllocatedLeadPubList.status ===
																	"Accept" ||
																onClickAllocatedLeadPubList.status ===
																	"LP_Pending" ? (
																	<br />
																) : (
																	""
																)}
																{onClickAllocatedLeadPubList.status ===
																	"Accept" ||
																onClickAllocatedLeadPubList.status ===
																	"LP_Pending" ? (
																	onClickAllocatedLeadPubList.qaReviewLead >
																		0 ||
																	onClickAllocatedLeadPubList.agencyInternalReviewLead >
																		0 ? (
																		user.role === "ANC" ? (
																			<a
																				href="#/"
																				disabled
																				id={onClickAllocatedLeadPubList.campID}
																				name={onClickAllocatedLeadPubList.pID}
																				parentCampID={
																					onClickAllocatedLeadPubList.parentCampID
																				}
																				reallocationID={
																					onClickAllocatedLeadPubList.reallocationID
																				}
																				data-toggle="modal"
																				data-target="#updateLeadPermission"
																				onClick={
																					this.handleUpdateLeadPermission
																				}>
																				<Icon
																					disabled
																					type="file-sync"
																					style={{ color: "#40a9ff" }}
																				/>
																				&nbsp;{"Update Lead"}
																				<hr
																					style={{
																						marginTop: "1px",
																						marginBottom: "1px",
																					}}></hr>
																			</a>
																		) : (
																			<a
																				href="#/"
																				id={onClickAllocatedLeadPubList.campID}
																				name={onClickAllocatedLeadPubList.pID}
																				parentCampID={
																					onClickAllocatedLeadPubList.parentCampID
																				}
																				reallocationID={
																					onClickAllocatedLeadPubList.reallocationID
																				}
																				data-toggle="modal"
																				data-target="#updateLeadPermission"
																				onClick={
																					this.handleUpdateLeadPermission
																				}>
																				<Icon
																					type="file-sync"
																					style={{ color: "#40a9ff" }}
																				/>
																				&nbsp;{"Update Lead"}
																				<hr
																					style={{
																						marginTop: "1px",
																						marginBottom: "1px",
																					}}></hr>
																			</a>
																		)
																	) : (
																		""
																	)
																) : (
																	""
																)}
																{onClickAllocatedLeadPubList.status ===
																	"Accept" ||
																onClickAllocatedLeadPubList.status ===
																	"LP_Pending" ? (
																	onClickAllocatedLeadPubList.qaReviewLead >
																		0 ||
																	onClickAllocatedLeadPubList.agencyInternalReviewLead >
																		0 ? (
																		<br />
																	) : (
																		""
																	)
																) : (
																	""
																)}
																{user.role !== "PNC" && user.role !== "ANC" ? (
																	<span>
																		{onClickAllocatedLeadPubList.status ===
																			"Accept" ||
																		onClickAllocatedLeadPubList.status ===
																			"LP_Pending" ? (
																			<a
																				href="#/"
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.downloadIODocument.bind(
																					this
																				)}>
																				<i
																					class="fa fa-cloud-download"
																					style={{ color: "#4d4da6" }}
																					campID={
																						onClickAllocatedLeadPubList.campID
																					}
																					pID={
																						onClickAllocatedLeadPubList.pID
																					}></i>
																				&nbsp;{"Download IO Document"}
																				<hr
																					style={{
																						marginTop: "0px",
																						marginBottom: "1px",
																					}}></hr>
																			</a>
																		) : (
																			""
																		)}
																	</span>
																) : (
																	""
																)}
															</td>
														) : (
															//new code end

															<td style={{ width: "170px", height: "284px" }}>
																<a
																	href="#/"
																	id={onClickAllocatedLeadPubList.campID}
																	pID={onClickAllocatedLeadPubList.pID}
																	data-toggle="modal"
																	data-target="#endDateModal"
																	onClick={this.handleAllocatedLeads}
																	data-backdrop="static"
																	data-keyboard="false">
																	<i
																		class="fa fa-calendar"
																		style={{ color: "#4d4da6" }}
																		id={onClickAllocatedLeadPubList.campID}
																		pID={onClickAllocatedLeadPubList.pID}
																		onClick={this.handleAllocatedLeads}
																	/>
																	&nbsp;{"Edit End Date"}
																	<hr
																		style={{
																			marginTop: "1px",
																			marginBottom: "1px",
																		}}></hr>
																</a>
																<br />

																{user.role === "AC" || user.role !== "AQA" ? (
																	onClickAllocatedLeadPubList.status ===
																		"Assign" ||
																	onClickAllocatedLeadPubList.status ===
																		"ReAssign" ||
																	onClickAllocatedLeadPubList.status ===
																		"AcceptedCounter" ? (
																		user.role === "ANC" ? (
																			<div>
																				<a
																					href="#/"
																					disabled
																					id={
																						onClickAllocatedLeadPubList.campID
																					}
																					publisherId={
																						onClickAllocatedLeadPubList.pID
																					}
																					data-toggle="modal"
																					data-target="#editAllocationModal"
																					onClick={this.handleCampaignID}
																					data-backdrop="static"
																					data-keyboard="false">
																					<i
																						disabled
																						class="fa fa-edit"
																						style={{ color: "#4d4da6" }}
																						id={
																							onClickAllocatedLeadPubList.campID
																						}
																						publisherId={
																							onClickAllocatedLeadPubList.pID
																						}
																					/>
																					&nbsp;{"Edit Allocation"}
																					<hr
																						style={{
																							marginTop: "1px",
																							marginBottom: "1px",
																						}}></hr>
																				</a>{" "}
																				<br />
																			</div>
																		) : (
																			<div>
																				<a
																					href="#/"
																					id={
																						onClickAllocatedLeadPubList.campID
																					}
																					publisherId={
																						onClickAllocatedLeadPubList.pID
																					}
																					data-toggle="modal"
																					data-target="#editAllocationModal"
																					onClick={this.handleCampaignID}
																					data-backdrop="static"
																					data-keyboard="false">
																					<i
																						class="fa fa-edit"
																						style={{ color: "#4d4da6" }}
																						id={
																							onClickAllocatedLeadPubList.campID
																						}
																						publisherId={
																							onClickAllocatedLeadPubList.pID
																						}
																					/>
																					&nbsp;{"Edit Allocation"}
																					<hr
																						style={{
																							marginTop: "1px",
																							marginBottom: "1px",
																						}}></hr>
																				</a>{" "}
																				<br />
																			</div>
																		)
																	) : (
																		""
																	)
																) : (
																	""
																)}

																{onClickAllocatedLeadPubList.status ===
																	"Cancel ALU" ||
																onClickAllocatedLeadPubList.status ===
																	"Reject" ? (
																	""
																) : user.role === "ANC" ? (
																	<a
																		href="#/"
																		disabled
																		id={onClickAllocatedLeadPubList.campID}
																		pid={onClickAllocatedLeadPubList.pID}
																		name={
																			onClickAllocatedLeadPubList.pID +
																			"#" +
																			onClickAllocatedLeadPubList.allocatedLead
																		}
																		data-toggle="modal"
																		data-target="#myModal"
																		data-backdrop="static"
																		data-keyboard="false"
																		onClick={this.passValue}>
																		<i
																			disabled
																			class="fa fa-times"
																			style={{ color: "#4d4da6" }}
																		/>
																		&nbsp;{"Cancel"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																) : (
																	<a
																		href="#/"
																		id={onClickAllocatedLeadPubList.campID}
																		pid={onClickAllocatedLeadPubList.pID}
																		name={
																			onClickAllocatedLeadPubList.pID +
																			"#" +
																			onClickAllocatedLeadPubList.allocatedLead
																		}
																		data-toggle="modal"
																		data-target="#myModal"
																		data-backdrop="static"
																		data-keyboard="false"
																		onClick={this.passValue}>
																		<i
																			class="fa fa-times"
																			style={{ color: "#4d4da6" }}
																		/>
																		&nbsp;{"Cancel"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																)}
																<br />
																{onClickAllocatedLeadPubList.status ===
																"Accept" ? (
																	user.role === "ANC" ? (
																		<a
																			href="#/"
																			disabled
																			data-toggle="modal"
																			data-target="#myModal11"
																			data-backdrop="static"
																			data-keyboard="false"
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}>
																			<i
																				disabled
																				class="fa fa-pause"
																				style={{ color: "#4d4da6" }}
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.passValue1}
																			/>
																			&nbsp;{"Pause"}
																			<hr
																				style={{
																					marginTop: "1px",
																					marginBottom: "1px",
																				}}></hr>
																		</a>
																	) : (
																		<a
																			href="#/"
																			data-toggle="modal"
																			data-target="#myModal11"
																			data-backdrop="static"
																			data-keyboard="false"
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}>
																			<i
																				class="fa fa-pause"
																				style={{ color: "#4d4da6" }}
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.passValue1}
																			/>
																			&nbsp;{"Pause"}
																			<hr
																				style={{
																					marginTop: "1px",
																					marginBottom: "1px",
																				}}></hr>
																		</a>
																	)
																) : onClickAllocatedLeadPubList.status ===
																  "Paused" ? (
																	user.role === "ANC" ? (
																		<a
																			href="#/"
																			disabled
																			data-toggle="modal"
																			data-target="#myModal12"
																			data-backdrop="static"
																			data-keyboard="false"
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}>
																			<i
																				disabled
																				class="fa fa-play"
																				style={{ color: "#4d4da6" }}
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.passValue1}
																			/>
																			&nbsp;
																			{"Resume"}
																			<hr
																				style={{
																					marginTop: "1px",
																					marginBottom: "1px",
																				}}></hr>
																		</a>
																	) : (
																		<a
																			href="#/"
																			data-toggle="modal"
																			data-target="#myModal12"
																			data-backdrop="static"
																			data-keyboard="false"
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}>
																			<i
																				class="fa fa-play"
																				style={{ color: "#4d4da6" }}
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.passValue1}
																			/>
																			&nbsp;
																			{"Resume"}
																			<hr
																				style={{
																					marginTop: "1px",
																					marginBottom: "1px",
																				}}></hr>
																		</a>
																	)
																) : onClickAllocatedLeadPubList.status ===
																  "LP_Pending" ? (
																	<a
																		href="#/"
																		data-toggle="modal"
																		data-target="#myModal11"
																		data-backdrop="static"
																		data-keyboard="false"
																		campID={onClickAllocatedLeadPubList.campID}
																		pID={onClickAllocatedLeadPubList.pID}
																		onClick={this.passValue1}>
																		<i
																			class="fa fa-pause"
																			style={{ color: "#4d4da6" }}
																			campID={
																				onClickAllocatedLeadPubList.campID
																			}
																			pID={onClickAllocatedLeadPubList.pID}
																			onClick={this.passValue1}
																		/>
																		&nbsp;
																		{"Pause"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																) : (
																	""
																)}
																<br />
																{onClickAllocatedLeadPubList.status ===
																	"Assign" ||
																onClickAllocatedLeadPubList.status ===
																	"Counter" ||
																onClickAllocatedLeadPubList.status ===
																	"ReAssign" ||
																onClickAllocatedLeadPubList.status ===
																	"Reject" ||
																onClickAllocatedLeadPubList.status ===
																	"AcceptedCounter" ||
																onClickAllocatedLeadPubList.status ===
																	"RejectedCounter" ? (
																	""
																) : (
																	<a
																		href="#/"
																		id={onClickAllocatedLeadPubList.campID}
																		pID={onClickAllocatedLeadPubList.pID}
																		data-toggle="modal"
																		data-target="#decrementalModal"
																		onClick={this.handleAllocatedLeads}>
																		<i
																			class="fa fa-minus-square-o"
																			style={{ color: "#4d4da6" }}
																			id={onClickAllocatedLeadPubList.campID}
																			pID={onClickAllocatedLeadPubList.pID}
																		/>
																		&nbsp;{"Decremental"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																)}
																<br />
																{onClickAllocatedLeadPubList.status ===
																	"Accept" ||
																onClickAllocatedLeadPubList.status ===
																	"LP_Pending" ? (
																	<a
																		href="#/"//Rutuja 2978 
																		id={onClickAllocatedLeadPubList.campID}
																		name={onClickAllocatedLeadPubList.pID}
																		parentCampID={
																			onClickAllocatedLeadPubList.parentCampID
																		}
																		reallocationID={
																			onClickAllocatedLeadPubList.reallocationID
																		}
																		onClick={this.handlecreatives}>
																		<i
																			class="fa fa-align-justify"
																			style={{ color: "#4d4da6" }}
																			id={onClickAllocatedLeadPubList.campID}
																			name={onClickAllocatedLeadPubList.pID}
																			parentCampID={
																				onClickAllocatedLeadPubList.parentCampID
																			}
																			reallocationID={
																				onClickAllocatedLeadPubList.reallocationID
																			}
																			onClick={this.handlecreatives}
																		/>
																		&nbsp;{"Creatives"}
																		<hr
																			style={{
																				marginTop: "1px",
																				marginBottom: "1px",
																			}}></hr>
																	</a>
																) : (
																	""
																)}
																{onClickAllocatedLeadPubList.status ===
																	"Accept" ||
																onClickAllocatedLeadPubList.status ===
																	"LP_Pending" ? (
																	<br />
																) : (
																	""
																)}
																{onClickAllocatedLeadPubList.status ===
																	"Accept" ||
																onClickAllocatedLeadPubList.status ===
																	"LP_Pending" ? (
																	onClickAllocatedLeadPubList.qaReviewLead >
																		0 ||
																	onClickAllocatedLeadPubList.agencyInternalReviewLead >
																		0 ? (
																		user.role === "ANC" ? (
																			<a
																				href="#/"
																				disabled
																				id={onClickAllocatedLeadPubList.campID}
																				name={onClickAllocatedLeadPubList.pID}
																				parentCampID={
																					onClickAllocatedLeadPubList.parentCampID
																				}
																				reallocationID={
																					onClickAllocatedLeadPubList.reallocationID
																				}
																				data-toggle="modal"
																				data-target="#updateLeadPermission"
																				onClick={
																					this.handleUpdateLeadPermission
																				}>
																				<Icon
																					disabled
																					type="file-sync"
																					style={{ color: "#40a9ff" }}
																				/>
																				&nbsp;{"Update Lead"}
																				<hr
																					style={{
																						marginTop: "1px",
																						marginBottom: "1px",
																					}}></hr>
																			</a>
																		) : (
																			<a
																				href="#/"
																				id={onClickAllocatedLeadPubList.campID}
																				name={onClickAllocatedLeadPubList.pID}
																				parentCampID={
																					onClickAllocatedLeadPubList.parentCampID
																				}
																				reallocationID={
																					onClickAllocatedLeadPubList.reallocationID
																				}
																				data-toggle="modal"
																				data-target="#updateLeadPermission"
																				onClick={
																					this.handleUpdateLeadPermission
																				}>
																				<Icon
																					type="file-sync"
																					style={{ color: "#40a9ff" }}
																				/>
																				&nbsp;{"Update Lead"}
																				<hr
																					style={{
																						marginTop: "1px",
																						marginBottom: "1px",
																					}}></hr>
																			</a>
																		)
																	) : (
																		""
																	)
																) : (
																	""
																)}
																{onClickAllocatedLeadPubList.status ===
																	"Accept" ||
																onClickAllocatedLeadPubList.status ===
																	"LP_Pending" ? (
																	onClickAllocatedLeadPubList.qaReviewLead >
																		0 ||
																	onClickAllocatedLeadPubList.agencyInternalReviewLead >
																		0 ? (
																		<br />
																	) : (
																		""
																	)
																) : (
																	""
																)}
																{user.role !== "PNC" && user.role !== "ANC" ? (
																	<span>
																		{onClickAllocatedLeadPubList.status ===
																			"Accept" ||
																		onClickAllocatedLeadPubList.status ===
																			"LP_Pending" ? (
																			<a
																				href="#/"
																				campID={
																					onClickAllocatedLeadPubList.campID
																				}
																				pID={onClickAllocatedLeadPubList.pID}
																				onClick={this.downloadIODocument.bind(
																					this
																				)}>
																				<i
																					class="fa fa-cloud-download"
																					style={{ color: "#4d4da6" }}
																					campID={
																						onClickAllocatedLeadPubList.campID
																					}
																					pID={
																						onClickAllocatedLeadPubList.pID
																					}></i>
																				&nbsp;{"Download IO Document"}
																				<hr
																					style={{
																						marginTop: "0px",
																						marginBottom: "1px",
																					}}></hr>
																			</a>
																		) : (
																			""
																		)}
																	</span>
																) : (
																	""
																)}
															</td>
														)}
													</span>
												)}
											</tr>
										)
									)}
								</tbody>
							</table>
						</div>
					</div>
					{/**End of col-xs-12 col-sm-12 col-md-12 col-lg-12*/}

					<div class="modal" id="myModal">
						<div class="modal-dialog">
							<div class="modal-content">
								<div
									class="modal-header custom-class"
									style={{ backgroundColor: "#0000b3" }}>
									<h4
										class="modal-title"
										style={{ color: "white", fontSize: "20px" }}>
										Confirm (Campaign ID:{this.state.parentCampID})
									</h4>
									{/* rutuja-task-4319-allocated leads-not showing proper button position  */}
									<button
										type="button"
										style={{
											float: "right",
											color: " white",
											backgroundColor: "rgb(20,73,153)",
											border: "rgb(0, 0, 179)",
										}}
										data-dismiss="modal"
										onClick={this.handleRefreshPage}>
										&times;
									</button>
								</div>
								<div style={{ fontSize: "16px", color: "green" }}>
									<center>{this.state.cancelPubMsg}</center>{" "}
								</div>
								<div style={{ fontSize: "16px", color: "red" }}>
									<center> {this.state.cancelPubNotMsg}</center>{" "}
								</div>
								<div class="modal-body">	{" "}
										Are you sure you want to cancel allocation for this
										Publisher?
								</div>
								<div class="modal-footer">
									{this.state.cancelResponse === true ? (
										<button
											type="button"
											class="btn add-button"
											onClick={this.handleRefreshPage}>
											Close
										</button>
									) : (
										<div>
											{/* //Sandeep-task-3227-added code to dismiss modal */}
											<button
												type="button"
												class="btn add-button"
												id={this.state.passCampID}
												name={this.state.passPID}
												onClick={this.cancelTOPublisher}
												data-dismiss="modal">
												Yes
											</button>
												{/* rutuja-task-4319-allocated leads-not showing proper button position  */}
											<button
												type="button"
												class="btn add-button"
												data-dismiss="modal"
													
												style={{
													marginTop: "-30px",
													marginLeft:"15px",
												}} >
												No
											</button>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>

					{/*///////////////modal for pausing the publisher  */}

					<div class="modal" id="myModal11">
						<div class="modal-dialog">
							<div class="modal-content">
								<div class="modal-header custom-class">
									<h4
										class="modal-title"
										style={{ color: "white", fontSize: "20px" }}>
										Confirm (Campaign ID:{this.state.parentCampID})
									</h4>
										{/* rutuja-task-4319-allocated leads-not showing proper button position  */}
									<button
										type="button"
										style={{
											float : "right",
											color: " white",
											backgroundColor: "rgb(20,73,153)",
											border: "rgb(0, 0, 179)",
										}}
										data-dismiss="modal"
										onClick={this.handleRefreshPage}>
										&times;
									</button>
								</div>
								<div class="modal-body">
										{" "}
										Do you want to pause the allocation for Publisher?
								</div>
								<div class="modal-footer">
									<button
										type="button"
										class="btn add-button"
										id={this.state.pCampID}
										name={this.state.pPID}
										onClick={this.pauseTOPublisher}
										data-dismiss="modal">
										Yes
									</button>
									<button
										type="button"
										class="btn add-button"
										data-dismiss="modal"
										
										style={{
											marginTop: "-30px",
											marginLeft:"15px",
										}}>
										No
									</button>
								</div>
							</div>
						</div>
					</div>

					{/*modal for resuming the publisher*/}
					<div class="modal" id="myModal12">
						<div class="modal-dialog">
							<div class="modal-content">
								<div class="modal-header custom-class">
									<h4
										class="modal-title"
										style={{ color: "white", fontSize: "20px" }}>
										Confirm (Campaign ID:{this.state.parentCampID})
									</h4>
									{/* rutuja-task-4319-allocated leads-not showing proper button position  */}
									<button
										type="button"
										style={{
											float : "right",
											color: " white",
											backgroundColor: "rgb(20,73,153)",
											border: "rgb(0, 0, 179)",
										}}
										data-dismiss="modal"
										onClick={this.handleRefreshPage}>
										&times;
									</button>
								</div>
								<div class="modal-body">
										{" "}
										Do you want to resume the allocation for Publisher?
								</div>
								<div class="modal-footer">
									<button
										type="button"
										class="btn btn-info"
										id={this.state.pCampID}
										style={{
											backgroundColor: "#144999",
											color: "white",
											width: "min-content",
											height: "31px",
											fontSize: "14px",
										}}
										name={this.state.pPID}
										onClick={this.resumeTOPublisher}
										data-dismiss="modal">
										Yes
									</button>
									<button
										type="button"
										class="btn"
										style={{
											backgroundColor: "#144999",
											color: "white",
											width: "min-content",
											height: "31px",
											fontSize: "14px",
										}}
										data-dismiss="modal">
										No
									</button>
								</div>
							</div>
						</div>
					</div>

					{/* //////////////////////////////////////////////// */}

					<div class="modal fade" id="decrementalModal" role="dialog">
						<div class="modal-dialog">
							<div class="modal-content">
								<div
									class="modal-header"
									style={{ backgroundColor: "rgb(20,73,153)" }}>
									<h4
										class="modal-title"
										style={{ color: "white", fontSize: "18px" }}>
										Leads Decrement &nbsp;(Campaign ID:{this.state.parentCampID}
										)
									</h4>
										{/* rutuja-task-4319-allocated leads-not showing proper button position  */}
									<button
										type="button"
										style={{
											float: "right",
											color: " white",
											backgroundColor: "rgb(20,73,153)",
											border: "rgb(0, 0, 179)",
										}}
										data-dismiss="modal"
										onClick={this.handleRefreshPage}>
									&times;
									</button>
								</div>
								<div class="modal-body">
									<div class="successMessage">{this.state.successMsg}</div>
									<div
										style={{
											display: this.state.alertDisplay,
											border: this.state.alertBorder,
										}}>
										<div class="errorMessage">
											{this.state.errors.commonError}
											{this.state.errors.wholenumber}
										</div>
									</div>
									<br />
									{this.state.onClickAllocatedLeadPublisher.map(
										(onClickAllocatedLeadPublisher) => (
											<div >
												<div id="label">
												Publisher Allocated Leads :
												&nbsp;{onClickAllocatedLeadPublisher.allocatedLead}
												</div>
												<p>
													<div class="row">
														<div class="col-sm-5">
															<label id="label"> Decremental Leads By: </label>
															{" "}
															<input
																style={{ width: "200px", fontWeight: "500" }}
																type="text"
																className="form-control"
																name="DecrementalLead"
																onChange={this.handleDecrementLeads}
																id={onClickAllocatedLeadPublisher.allocatedLead}
															/>
														</div>
														<div class="col-sm-7">
															<label id="label"> New Allocated Leads  
															: &nbsp;{parseInt(
																onClickAllocatedLeadPublisher.allocatedLead
															) -
																this.state.decrementalLeads >=
															0
																? parseInt(
																		onClickAllocatedLeadPublisher.allocatedLead
																  ) - this.state.decrementalLeads
																: onClickAllocatedLeadPublisher.allocatedLead}</label>
														</div>
													</div>
												
													<br />
													<div class="modal-footer">
														<button
															type="button"
															class="btn btn-default "
															className={this.state.inputClass}
															onClick={this.handleDecPublisherLeads}
															style={{
																backgroundColor: "#144999",
																color: "white",
																width: "min-content",
																height: "31px",
																fontSize: "12px",
															}}>
															Decrement
														</button>
													</div>
												</p>
											</div>
										)
									)}
								</div>
							</div>
						</div>
					</div>

					{/* #################################################### */}
					<Modal show={this.state.open} onHide={this.onCloseModal}>
						<div class="modal-header" style={{ backgroundColor: "#144999" }}>
							<h5 class="modal-title" style={{ color: "white" }}>
								Confirm (Campaign ID:{this.state.parentCampID})
							</h5>
							<button
								type="button"
								onClick={this.onCloseModal}
								style={{ color: "White" }}
								class="close"
								data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<Modal.Body>
							<p>
								There are leads available for this allocation with below status.
								After selection, those leads will get rejected and will not be
								considered in invoicing,
							</p>
							<div class="row">
								{this.state.checkCLAcceptStatus === true ? (
									<div class="col-sm-4">
										<label>Count : {this.state.clientAcceptCount}</label>
										<Checkbox
											id="ClientAccepted"
											name="ClientAccepted"
											onChange={this.handleLeadCheck.bind(this)}>
											Advertiser Accepted
										</Checkbox>
									</div>
								) : (
									""
								)}
								{this.state.checkAcceptStatus === true ? (
									<div class="col-sm-4">
										<label>Count : {this.state.acceptStatusCount}</label>
										<Checkbox
											id="Accepted"
											name="Accepted"
											onChange={this.handleLeadCheck.bind(this)}>
											Accepted
										</Checkbox>
									</div>
								) : (
									""
								)}
							</div>
							<br />
							<label>Reason :</label>
							<TextArea
								rows={2}
								id="Reason"
								name="Reason"
								onChange={this.handleLeadCheck.bind(this)}
							/>
						</Modal.Body>
						<Modal.Footer>
							<button
								type="button"
								class="btn"
								style={{ color: "white", backgroundColor: "#144999" }}
								onClick={this.CancelLeadUploadedPublisher.bind(this)}>
								Yes
							</button>
							<Button
								variant="secondary"
								style={{ color: "white", backgroundColor: "#144999" }}
								onClick={this.onCloseModal}>
								No
							</Button>
						</Modal.Footer>
					</Modal>
					{/* //snehal-task-3003-Add Edit Campaign End date option on allocated lead page so user can edit campaign end date(changes for new UI all changes are in popup only) */}
					<div class="modal fade" id="endDateModal" role="dialog">
						<div class="modal-dialog model-md">
							<div class="modal-content">
								<div
									class="modal-header"
									style={{ backgroundColor: "rgb(20,73,153)" }}>
									<h4
										class="modal-title"
										style={{ color: "white", fontSize: "18px" }}>
										Edit End Date(Campaign ID:{this.state.parentCampID})
									</h4>
									<button
										type="button"
										style={{
											marginTop: "-2px",
											padding: "1rem",
											margin: "-1rem -1rem -1rem auto",
											float: "right",
											color: " white",
											backgroundColor: "rgb(20,73,153)",
											border: "rgb(0, 0, 179)",
										}}
										data-dismiss="modal"
										onClick={this.handleRefreshPage}>
										&times;
									</button>
								</div>
								<div class="modal-body">
									<div class="successMessage">{this.state.successMsg}</div>
									<div
										style={{
											display: this.state.alertDisplay,
											border: this.state.alertBorder,
										}}>
										<div class="errorMessage">
											{this.state.errors.commonError}
											<br />
											{/* Sandeep-task-2912-added code to display error message */}
											{this.state.errors.deliveryDateErr}
										</div>
									</div>
									<p>
										{this.state.onClickAllocatedLeadPublisher.map(
											(onClickAllocatedLeadPublisher) => (
												<div>
													<div class="row">
														<div class="col-sm-6">
															<label id="label" style={{ width: "110px" }}>
																Publisher Start Date
															</label>
															<br />
															{onClickAllocatedLeadPublisher.startDate}
														</div>
														<div class="col-sm-4">
															<label id="label" style={{ width: "110px" }}>
																Publisher End Date
															</label>
															<input
																type="date"
																id="endDate"
																name="endDate"
																min=""
																required
																pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
																defaultValue={
																	onClickAllocatedLeadPublisher.endDate
																}
																onChange={this.handleChangeEndDate}
																className="form-control"
															/>
														</div>
													</div>
												</div>
											)
										)}
										<div style={{ display: this.state.EndDateDisplay }}>
											<br />
											<p
												style={{
													width: "100%",
													textAlign: "center",
													borderBottom: "1px solid #E8E8E8",
													lineHeight: "0.1em",
													margin: "10px 0 20px",
												}}>
												<span style={{ background: "#fff", padding: "0 10px" }}>
													OR
												</span>
											</p>
											<div class="row">
												{this.state.onClickAllocatedLeadPublisher.map(
													(onClickAllocatedLeadPublisher) => (
														<div class="col-4" style={{ marginRight: "80px" }}>
															<label id="label" style={{ width: "113px" }}>
																Campaign's End Date
															</label>
															<input
																type="date"
																id="endDate"
																name="campEndDate"
																min=""
																required
																pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
																defaultValue={
																	onClickAllocatedLeadPublisher.campEndDate
																}
																onChange={this.handleChangeCampEndDate}
																className="form-control"
															/>
														</div>
													)
												)}
												{this.state.publisherPacingDetails.length > 0 ? (
													<div class="col-6">
														{" "}
														<br />
														<a
															href="#/"
															data-toggle="collapse"
															data-target="#pacingTableModal"
															onClick={this.NewPacingTable}
															style={{
																folat: "right",
																textDecoration: "none",
																fontSize: "14px",
															}}>
															Display pacing table
														</a>
													</div>
												) : (
													""
												)}
											</div>{" "}
										</div>{" "}
									</p>
									<div style={{ display: this.state.pacingTableDisplay }}>
										<div>
											<div>
												<br />
												<p
													style={{
														width: "100%",
														textAlign: "center",
														borderBottom: "1px solid #E8E8E8",
														lineHeight: "0.1em",
														margin: "10px 0 20px",
													}}></p>
											</div>

											<div class="modal-body" style={{ marginTop: "-30px" }}>
												<div class="row">
													<div class="col-lg-8 col-md-12 col-sm-12">
														{/*snehal more-task-2937-Css changes */}
														{this.state.onClickAllocatedLead.map(
															(onClickAllocatedLead) => (
																<label
																	id="label"
																	style={{
																		color: "#F28C0F",
																		fontWeight: "700",
																		fontSize: "17px",
																	}}>
																	&nbsp;Total Leads:{" "}
																	{onClickAllocatedLead.leadAllocation}
																	&nbsp;&nbsp;&nbsp;
																</label>
															)
														)}
													</div>
													<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
														<label id="label">Unit&nbsp;:&nbsp;&nbsp;</label>
														<select
															value={this.state.pacingUnit}
															onChange={this.pacingHandleChange}
															id="unit"
															disabled
															name="pacingUnit"
															style={{
																height: "30px",
																width: "73px",
																borderRadius: "5px",
															}}>
															<option value="Number" selected>
																Num
															</option>
															<option value="Percentage">%</option>
														</select>
													</div>
												</div>
												<div
													class="table-responsive-lg"
													style={{
														width: "447px",
														height: "200px",
														overflow: "scroll",
													}}>
													<table class=" table table-striped">
														<thead>
															<tr
																class="info"
																style={{ backgroundColor: "#808080" }}>
																<th
																	style={{
																		width: "220px",
																		backgroundColor: "#808080",
																	}}>
																	Month
																</th>
																<th
																	style={{
																		width: "220px",
																		backgroundColor: "#808080",
																	}}>
																	Lead count
																</th>
																<th
																	style={{
																		width: "220px",
																		backgroundColor: "#808080",
																	}}>
																	Lead %
																</th>
																<th
																	style={{
																		width: "220px",
																		backgroundColor: "#808080",
																	}}>
																	End date
																</th>
																<th
																	style={{
																		width: "220px",
																		backgroundColor: "#808080",
																	}}>
																	Carry forward
																</th>
																<th style={{ backgroundColor: "#808080" }}></th>
															</tr>
														</thead>
														<tbody style={{ backgroundColor: "white" }}>
															{this.state.publisherPacingDetails.map(
																(publisherPacingDetails, i) => {
																	return (
																		<tr style={{ backgroundColor: "white" }}>
																			<td>
																				<input
																					type="text"
																					id="pacingMonth"
																					name="pacingMonth"
																					value={
																						publisherPacingDetails.pacingMonth
																					}
																					disabled={true}
																					class="form-control"
																					style={{
																						width: "200px",
																						borderRadius: "0px",
																					}}
																				/>
																			</td>
																			<td>
																				{/* Rutuja- task 2978 added to string and changed == to ===  */}
																				{this.state.pacingUnit.toString() ===
																				"Percentage" ? (
																					<input
																						type="text"
																						value={
																							publisherPacingDetails.pacingLeadCount
																						}
																						key=""
																						class="form-control "
																						style={{
																							width: "150px",
																							borderRadius: "0px",
																						}}
																						id={publisherPacingDetails.rowId}
																						name="pacingLeadCount"
																						disabled={true}
																					/>
																				) : (
																					<input
																						type="text"
																						disabled={true}
																						defaultValue={
																							publisherPacingDetails.pacingLeadCount
																						}
																						key=""
																						class="form-control "
																						style={{
																							width: "150px",
																							borderRadius: "0px",
																							backgroundColor: "white",
																						}}
																						id={publisherPacingDetails.rowId}
																						name="pacingLeadCount"
																					/>
																				)}
																			</td>

																			<td>
																			{/* Rutuja- task 2978 added to string and changed == to ===  */}
																				{this.state.pacingUnit.toString() === "Number" ? (
																					<input
																						type="text"
																						value={
																							publisherPacingDetails.pacingPercentage
																						}
																						key=""
																						name="pacingPercentage"
																						class="form-control"
																						style={{
																							width: "120px",
																							borderRadius: "0px",
																						}}
																						id={publisherPacingDetails.rowId}
																						disabled={true}
																					/>
																				) : (
																					<input
																						type="text"
																						value={
																							publisherPacingDetails.pacingPercentage
																						}
																						key=""
																						disabled={true}
																						name="pacingPercentage"
																						class="form-control"
																						style={{
																							width: "120px",
																							borderRadius: "0px",
																							backgroundColor: "white",
																						}}
																						id={publisherPacingDetails.rowId}
																					/>
																				)}
																			</td>

																			<td>
																				<input
																					type="date"
																					class="form-control"
																					id={publisherPacingDetails.rowId}
																					value={
																						publisherPacingDetails.pacingEndDate
																					}
																					name="pacingEndDate"
																					min=""
																					disabled={true}
																					style={{
																						width: "150px",
																						borderRadius: "0px",
																					}}
																				/>
																			</td>
																			<td>
																				{/*Rutuja Task- added number and changed == to ===  */}
																				{Number(this.state.publisherPacingDetails
																					.length) ===
																				i + 1 ? (
																					<Radio.Group
																						name="pacingCarryForward"
																						defaultValue={"no"}
																						disabled={true}>
																						<Radio
																							value="yes"
																							id={publisherPacingDetails.rowId}
																							checked=""
																							onChange=""
																							name="Yes">
																							Yes
																						</Radio>
																						{/* Snehal- task-2937 value spelling change */}
																						<Radio
																							value="no"
																							id={publisherPacingDetails.rowId}
																							checked=""
																							onChange=""
																							name="No">
																							No
																						</Radio>
																						{/* Snehal- task-2937 value spelling change */}
																					</Radio.Group>
																				) : (
																					<Radio.Group
																						name="pacingCarryForward"
																						value={
																							publisherPacingDetails.pacingCarryForward
																						}
																						id={publisherPacingDetails.rowId}
																						disabled={true}>
																						{" "}
																						{/* Snehal- task-2937 value of carry forwaed */}
																						<Radio
																							value="yes"
																							id={publisherPacingDetails.rowId}
																							checked=""
																							onChange=""
																							name="Yes">
																							Yes
																						</Radio>{" "}
																						{/* Snehal- task-2937 value spelling change */}
																						<Radio
																							value="no"
																							id={publisherPacingDetails.rowId}
																							checked=""
																							onChange=""
																							name="No">
																							No
																						</Radio>{" "}
																						{/* Snehal- task-2937 value spelling change */}
																					</Radio.Group>
																				)}
																			</td>
																			<td>
																				<Tooltip
																					title="Please enter 0 if not required "
																					placement="right">
																					<Icon type="question-circle" />
																				</Tooltip>
																				{/* <span class="glyphicon glyphicon-minus-sign" id={i} name={pacingCampaignDetailsData.pacingID} onClick={(e)=>this.removePacingBack(e,i)} style={{color:"Red",fontWeight: 'bold',fontSize: 14}}></span> */}
																			</td>
																		</tr>
																	);
																}
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>{" "}
									</div>
								</div>
								{/* end of body */}
								<div class="modal-footer">
									<button
										type="button"
										class="btn btn-default "
										className={this.state.inputClass}
										onClick={this.handleChangeEndDatePublisher}
										style={{
											backgroundColor: "#144999",
											color: "white",
											width: "min-content",
											height: "31px",
											fontSize: "12px",
										}}>
										Edit
									</button>
								</div>
							</div>
							{/* end of content */}
						</div>
					</div>
					{/* end od endDate modal */}

					{/* //////////////////////////////////////////////////////////////////////////////////////////////////// */}

					{/* Edit Allocation Modal */}

					<div class="modal fade" id="editAllocationModal" role="dialog">
						<div class="modal-dialog modal-xl">
							<div class="modal-content">
								<div class="modal-header custom-class">
									{this.state.campaignDetailID.map((campaignDetailID) => (
										<h4
											class="modal-title"
											style={{ color: "white", fontSize: "18px" }}>
											<label>
												Edit Campaign Allocation (Campaign ID:{" "}
												{campaignDetailID.campID})&nbsp;
											</label>
											<label
												id="label"
												style={{ color: "white", fontSize: "18px" }}>
												{" "}
											</label>
										</h4>
									))}
									<button
										type="button"
										class="cancel-button"
										style={{
											fontSize: 18,
											// // Saurabh-task-3792-Changed padding from 600 to 800
											paddingLeft: "800px",
											outline: "none",
										}}
										data-dismiss="modal"
										onClick={this.handleRefreshPage}>
										&times;
									</button>
								</div>

								{/* message */}
								<div className="" style={{ display: this.state.alertDisplay }}>
									<div class="container-fluid">
										<div class="card-body">
											<div class="row">
												<div
													class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
													style={{ textAlign: "center" }}>
													<div
														class="col-lg-12"
														style={{ border: this.state.alertBorder }}>
														<div class="errorMessage">
															{this.state.errors.lead}
															{this.state.reerrors.remaining}
															{this.state.dateerrors.date}
															{this.state.errors.allocatedLead}
															{this.state.allocerrors.allocate}
															{this.state.puberror.one}
															{this.state.errors.endDate}
															{this.state.errors.cpl}
															{this.state.errors.cpl1}
															{this.state.errors.cpl2}
															{this.state.errors.cpl3}
															{this.state.poerror.poerror}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/*end of  message */}

								<div class="modal-body">
									<div class="container-fluid">
										<div style={{ border: "1px solid #cdcdcd" }}>
											{this.state.campaignDetailID.map((campaignDetailID) => (
												<div class="row">
													<div class="col-sm-4">
														{" "}
														<label id="label">Campaign Region:</label>
														&nbsp; {campaignDetailID.region}
													</div>
													<div class="col-sm-4">
														<label id="label">Campaign Type:</label>
														&nbsp; {campaignDetailID.ABM}
													</div>
													<div class="col-sm-4">
														<label id="label">Pending Leads:</label>
														&nbsp;
														{campaignDetailID.pendingLead
															? campaignDetailID.pendingLead
															: 0}
													</div>
													<div class="col-sm-4">
														<label id="label">Start Date</label>
														&nbsp; {campaignDetailID.startDate}
													</div>
													<div class="col-sm-4">
														<label id="label">End Date</label>
														&nbsp; {campaignDetailID.endDate}
													</div>
													<div class="col-sm-4">
														<label id="label">First Lead Delivery Date</label>
														&nbsp; {campaignDetailID.firstLeadDeliveryDate}
													</div>
													{user.role === "AC" ? (
														<div class="col-sm-4">
															<label id="label">Total Budget:</label>
															&nbsp; {campaignDetailID.budget}
															{campaignDetailID.currency}
														</div>
													) : (
														""
													)}
													{user.role === "AC" ? (
														<div class="col-sm-4">
															<label id="label">Remaining Budget:</label>
															&nbsp;{" "}
															{campaignDetailID.remainingBudget
																? campaignDetailID.remainingBudget
																: 0}
															{campaignDetailID.currency}
														</div>
													) : (
														""
													)}

													<div class="col-sm-4">
														<label id="label">Total Leads:</label>
														&nbsp; {campaignDetailID.leadAllocation}
													</div>
													<div class="col-sm-4">
														<label id="label">Allocated Leads:</label>
														&nbsp;
														{campaignDetailID.allocatedLead
															? campaignDetailID.allocatedLead
															: "0"}
													</div>

													<div class="col-sm-4">
														<label id="label">Campaign CPL:</label>
														&nbsp;
														{campaignDetailID.CPL ? campaignDetailID.CPL : "0"}
													</div>
												</div>
											))}
										</div>
										<br />

										<br />
										<br />

										<div class="row">
											<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
												<form
													method="POST"
													name="Publisher"
													onSubmit={this.submitPublisher}>
													<div class="table-responsive-lg">
														<table class=" table table-striped">
															<thead>
																<tr class="custom-class">
																	<th>Rating</th>
																	<th>GDPR</th>
																	<th style={{ textAlign: "center" }}>
																		Publisher Name
																	</th>
																	<th>Start Date</th>
																	<th>End Date</th>
																	<th>First Lead Delivery Date</th>
																	<th>Allocated Leads</th>
																	{user.role === "AC" ? (
																		<th style={{ width: "10%" }}>CPL</th>
																	) : (
																		""
																	)}
																	{/* Saurabh-task-3792-added PO Number */}
																	<th>PO Number</th>
																</tr>
															</thead>
															<tbody>
																{/*
																 * @author Narendra Phadke
																 * @param  Description handle the multiple allocate campaign
																 * @return Description return successfully allocated message
																 */}
																{/* {alert("Data:====="+JSON.stringify(this.state.campaignDetailID))} */}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="campID"
																			name="campID"
																			value={campaignDetailID.campID}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}

																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalLead"
																			name="totalLead"
																			value={campaignDetailID.leadAllocation}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}

																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalAllocate"
																			name="totalAllocate"
																			value={campaignDetailID.allocatedLead}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}

																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="leadAllocation"
																			name="leadAllocation"
																			value={campaignDetailID.leadAllocation}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}

																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalBudget"
																			name="totalBudget"
																			value={campaignDetailID.budget}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalRemainingBudget"
																			name="totalRemainingBudget"
																			value={campaignDetailID.remainingBudget}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalendDate"
																			name="totalendDate"
																			value={campaignDetailID.endDate}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalstartDate"
																			name="totalstartDate"
																			value={campaignDetailID.startDate}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}
																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="totalPending"
																			name="totalPending"
																			value={
																				campaignDetailID.allocatedLead
																					? campaignDetailID.leadAllocation -
																					  campaignDetailID.allocatedLead
																					: "0"
																			}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}

																{this.state.campaignDetailID.map(
																	(campaignDetailID) => (
																		<input
																			type="hidden"
																			id="campaignCPL"
																			name="campaignCPL"
																			value={
																				campaignDetailID.CPL
																					? campaignDetailID.CPL
																					: "0"
																			}
																			style={{
																				width: "87%",
																				height: "100%",
																			}}
																			class="form-control"
																		/>
																	)
																)}

																{this.state.mergedCampaignDetailAssign.map(
																	(campaignDetailAssign, i) => {
																		return (
																			<tr>
																				<td>
																					<label id="label">
																						{campaignDetailAssign.rating}
																					</label>
																				</td>
																				<td style={{ textAlign: "center" }}>
																					<label
																						id="label"
																						style={{ align: "center" }}>
																						{
																							campaignDetailAssign.gdprCompliance
																						}
																					</label>{" "}
																				</td>
																				<td style={{ textAlign: "center" }}>
																					<label
																						id="label"
																						style={{
																							align: "center",
																							width: "127px",
																						}}>
																						{campaignDetailAssign.publisherName}
																					</label>{" "}
																				</td>
																				<td>
																					<input
																						type="date"
																						id="startDate"
																						name="startDate"
																						value={this.state.startDate}
																						onChange={this.handleChangeDynamicPublisher.bind(
																							this,
																							i
																						)}
																						style={{ width: "87%" }}
																						defaultValue={
																							campaignDetailAssign.startDate
																						}
																						class="form-control"
																					/>{" "}
																				</td>
																				<td>
																					<input
																						type="date"
																						id="endDate"
																						name="endDate"
																						value={this.state.campEndDate}
																						onChange={this.handleChangeDynamicPublisher.bind(
																							this,
																							i
																						)}
																						style={{ width: "87%" }}
																						defaultValue={
																							campaignDetailAssign.endDate
																						}
																						class="form-control"
																					/>{" "}
																				</td>
																				<td>
																					<input
																						type="date"
																						id="firstLeadDeliveryDate"
																						name="firstLeadDeliveryDate"
																						value={
																							this.state.firstLeadDeliveryDate
																						}
																						onChange={this.handleChangeDynamicPublisher.bind(
																							this,
																							i
																						)}
																						style={{ width: "87%" }}
																						defaultValue={
																							campaignDetailAssign.firstLeadDeliveryDate
																						}
																						class="form-control"
																					/>{" "}
																				</td>
																				<td>
																					<input
																						type="textbox"
																						class="form-control"
																						id="allocatedLead"
																						name="allocatedLead"
																						value={this.state.campallocatedLead}
																						defaultValue={
																							campaignDetailAssign.allocatedLead
																						}
																						onChange={this.handleChangeDynamicPublisher.bind(
																							this,
																							i
																						)}
																						style={{
																							width: "80%",
																							height: "100%",
																						}}
																					/>
																				</td>
																				<td>
																					<input
																						type="textbox"
																						class="form-control"
																						id="cpl"
																						name="cpl"
																						value={this.state.cmpcpl}
																						defaultValue={
																							campaignDetailAssign.CPL
																						}
																						onChange={this.handleChangeDynamicPublisher.bind(
																							this,
																							i
																						)}
																						style={{
																							width: "100%",
																							height: "100%",
																						}}
																					/>
																				</td>
																				<td>
																					{/* Saurabh-task-3792-added code for poNumber */}
																					{campaignDetailAssign.PO_No ? (
																						<input
																							type="textbox"
																							class="form-control"
																							id="PO_No"
																							name="PO_No"
																							defaultValue={
																								campaignDetailAssign.PO_No
																							}
																							onChange={this.handleChangeDynamicPublisher.bind(
																								this,
																								i
																							)}
																							disabled
																							style={{
																								width: "100%",
																								height: "100%",
																							}}
																						/>
																					) : (
																						<input
																							type="textbox"
																							class="form-control"
																							id="PO_No"
																							name="PO_No"
																							defaultValue={
																								campaignDetailAssign.PO_No
																							}
																							onChange={this.handleChangeDynamicPublisher.bind(
																								this,
																								i
																							)}
																							style={{
																								width: "100%",
																								height: "100%",
																							}}
																						/>
																					)}
																				</td>
																			</tr>
																		);
																	}
																)}
															</tbody>
														</table>
													</div>
													<div class="modal-footer">
														<button
															type="button"
															className={this.state.inputClass}
															onClick={this.submitPublisher}
															class="btn add-button">
															Allocate
														</button>
													</div>
												</form>
											</div>

											{/**End of col */}
										</div>
									</div>
									{/**End of container */}

									<br />
								</div>
								{/* /*If Region is not Europe Then By Default All Publisher will listed*/}
								{/**End of model body */}
							</div>
						</div>
					</div>

					{/* End of Edit Allocation Modal */}
					<div class="modal fade" id="showLeadPerAsset" role="dialog">
						<div class="modal-dialog">
							{/* <!-- Modal content--> */}
							<div class="modal-content">
								<form
									method="POST"
									name="showLeads"
									className="needs-validation"
									noValidate>
									<div class="modal-header custom-class">
										<h4
											class="modal-title"
											style={{
												color: "white",
												fontSize: "20px",
												paddingRight: 200,
											}}>
											Assetwise Leads
										</h4>

										<button
											type="button"
											class="cancel-button"
											style={{ fontSize: 15 }}
											onClick={this.handleRefreshPage}>
											&times;
										</button>
									</div>
									<div class="modal-body">
										<div class="row">
											<div class="col-sm-12">
												<table class=" table table-striped">
													<thead>
														<tr class="" style={{ backgroundColor: "#f5f5f0" }}>
															<th class="table-header">Asset ID</th>
															<th class="table-header">Asset Name</th>
															<th class="table-header">Lead Per Asset</th>
															<th class="table-header">
																Accepted Lead Per Asset
															</th>
														</tr>
													</thead>
													<tbody>
														{this.state.assetwiseLead.map((assetwiseLead) => (
															<tr>
																<td>
																	{assetwiseLead.assetStatus === "Removed" ? (
																		<span
																			style={{
																				color: "red",
																				textDecoration: "line-through",
																			}}>
																			{assetwiseLead.supportDocID}
																		</span>
																	) : (
																		assetwiseLead.supportDocID
																	)}
																</td>
																<td>
																	{assetwiseLead.assetStatus === "Removed" ? (
																		<span
																			style={{
																				color: "red",
																				textDecoration: "line-through",
																			}}>
																			{assetwiseLead.assetName}
																		</span>
																	) : (
																		assetwiseLead.assetName
																	)}
																</td>
																<td>
																	{assetwiseLead.assetStatus === "Removed" ? (
																		<span
																			style={{
																				color: "red",
																				textDecoration: "line-through",
																			}}>
																			{assetwiseLead.leadCountPerAsset}
																		</span>
																	) : (
																		assetwiseLead.leadCountPerAsset
																	)}
																</td>
																<td>
																	{assetwiseLead.assetStatus === "Removed" ? (
																		<span
																			style={{
																				color: "red",
																				textDecoration: "line-through",
																			}}>
																			{assetwiseLead.leadInfoCount
																				? assetwiseLead.leadInfoCount
																				: 0}
																		</span>
																	) : assetwiseLead.leadInfoCount ? (
																		assetwiseLead.leadInfoCount
																	) : (
																		0
																	)}
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div class="modal-footer">
										<button
											type="button"
											class="btn add-button"
											data-dismiss="modal">
											Close
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div class="modal fade" id="updateLeadPermission" role="dialog">
						<div class="modal-dialog">
							<div class="modal-content">
								<div
									class="modal-header"
									style={{ backgroundColor: "#0000b3" }}>
									<h4
										class="modal-title"
										style={{ color: "white", fontSize: "18px" }}>
										Update Lead Permission(Campaign ID:{this.state.parentCampID}
										)
									</h4>
									<button
										type="button"
										style={{
											marginTop: "-2px",
											padding: "1rem",
											margin: "-1rem -1rem -1rem auto",
											color: " white",
											backgroundColor: "rgb(0, 0, 179)",
											border: "rgb(0, 0, 179)",
										}}
										data-dismiss="modal"
										onClick={this.handleRefreshPage}>
										&times;
									</button>
								</div>
								<div class="modal-body">
									<span class="errorMessage">
										{this.state.errors.minsError}
									</span>
									<span class="errorMessage">
										{this.state.errors.valueError}
									</span>
									<div
										style={{
											display: this.state.alertDisplay,
											border: this.state.alertBorder,
										}}>
										<div class="errorMessage">
											{this.state.errors.commonError}
										</div>
									</div>
									<p>
										<div>
											<div class="row">
												<div class="col-4">
													<label id="label" style={{ width: "180px" }}>
														Update Lead Time (Hrs)
													</label>
													<input
														type="text"
														id="time"
														name="time"
														min=""
														onChange={this.handleChangeUpdateLeadsPermission}
														className="form-control"
													/>
												</div>
											</div>
										</div>
									</p>
								</div>
								{/* end of body */}
								<div class="modal-footer">
									<button
										type="button"
										class="btn btn-default "
										className={this.state.inputClass}
										onClick={this.submitUpdateLeadsPermission}
										style={{
											backgroundColor: "#144999",
											color: "white",
											width: "min-content",
											height: "31px",
											fontSize: "12px",
										}}>
										Update
									</button>
								</div>
							</div>
							{/* end of content */}
						</div>
					</div>
					{/* end od endDate modal */}

					{/* ####################################################### */}
				</div>
				<Footer />
			</div>
		);
		//end of return
	} // end of render
} // end of class AgencyAllocatedLeads

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AgencyAllocatedLeads.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(AgencyAllocatedLeads)
);
