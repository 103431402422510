/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Sanobar Golandaj
*@fileName :
 *Desc: 
*/

/*
@author:Raunak Thakkar
@Creation Date:01-08-2019
*Desc:The new UI for the review Leads with the Client Response tab 
*/


import React from "react";
import { Tabs, Tab } from 'react-bootstrap';
import Footer from '../layouts/footer'; 
import PublisherNavigation from '../layouts/navPage';
import { Table} from 'mdbreact';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import {Upload, Button,Tooltip,Icon} from 'antd';
import { FaArrowAltCircleLeft } from "react-icons/fa";
const queryString = require('query-string');
const xlsx =require('xlsx');
const Swal = require('sweetalert2')

class Reviewlead extends React.Component{
    
  constructor() {
    super();
    this.state = {
      fields: {},
      key:1,
      errors: {},
      reerrors:{},
      campID:'',
      btnDisable:'disabled1',
      reviewLeadFlag:false,
      reviewlead: [],
      campaignLeadDetails:[],
      internalLeadDetails:[],
      uploadLeadFile:[],
      clientDownloadLead:[],
      internalstatus:"AgencyInternalReview",
      status:"Accepted",
      parentCampID:'',
      reallocationID:'',
      userRole:'',
      agencyID:''
    };
   
    this.submitInternalReviewLead= this.submitInternalReviewLead.bind(this);
    this.submitreviewlead = this.submitreviewlead.bind(this);
    this.handleDownloadLeadDetails=this.handleDownloadLeadDetails.bind(this);
    this.handleFileShowDownload=this.handleFileShowDownload.bind(this);
    this.handleClientDownloadLeadDetails=this.handleClientDownloadLeadDetails.bind(this);
    this.handleUploadLeadFile=this.handleUploadLeadFile.bind(this);
    this.handleChangeFileUpload = this.handleChangeFileUpload.bind(this);
    this.handleChangeClientResponseFileUpload=this.handleChangeClientResponseFileUpload.bind(this);
    this.downloadInternalLeadDetails=this.downloadInternalLeadDetails.bind(this); 
    this.handleSelect=this.handleSelect.bind(this);
    this.uploadInternalLeadFile=this.uploadInternalLeadFile.bind(this);
    this.handleChangeInternalLead=this.handleChangeInternalLead.bind(this);
  } 
  handleSelect(key)
  {
//alert("here"+key);
this.setState({
  key:key
})

  }
 /**
       * @author Somnath Keswad
       * @param  Description Upload internal lead file  
       * @return Description return updated leads
       */
      uploadInternalLeadFile(e) {
        e.preventDefault();
        let errors = {};
        let reerrors={};
        var parsed = queryString.parse(this.props.location.search);
        var campID=parsed.campID;
       var names=[];
          let filesData=e.target.files;
          let filesData1=e.target.files[0];
          
          var ext
          if(filesData1==null)
          {
ext="";
var label=document.getElementById('fileName');
          label.innerHTML="No File Choosen"
          }
          else
          {
            ext=filesData1.name.split('.').pop();
            var label=document.getElementById('fileName');
            label.innerHTML=filesData1.name
  
          }
         
          console.warn("data files", filesData1);
           
        let message="";
        // alert("File Name====>"+JSON.stringify(filesData)+JSON.stringify(filesData1));
        const {user} = this.props.auth;
          var userID=user.id;
         var userName=user.name;
         //alert("userName="+JSON.stringify(user));
         var firstName=user.firstName;
         //alert("userName="+firstName);
         var lastName=user.lastName;
            var data = new FormData();
        if(ext=="xlsx"||ext=="XLSX")
        {
          data.append("campID",this.state.campID);
          data.append("message",message);
          data.append("file", filesData1);
          data.append("userID",userID);
          data.append("userName",userName);
          data.append("firstName",firstName);
          data.append("lastName",lastName);
            fetch("/lead/uploadInternalReviewLead", {
              method: 'POST',
              //mode:'no-cors',
              body: data
            }).then(res => res.json())
            .then(internalLeadDetails =>
             this.setState({ internalLeadDetails: internalLeadDetails, reviewLeadFlag:true})
            ).catch(function(err) {
             console.log(err)
          });
          errors['uploadsuccess']="File Uploaded Successfully"
          reerrors["fileError"]=""
          
          //alert("No Data Exists here");
            this.setState({errors:errors,reerrors:reerrors});
        }
        else
        {
          reerrors['fileError']="File Invalid Please Upload file with .xlsx format"
          errors['uploadsuccess']=""
          //alert("No Data Exists here");
            this.setState({reerrors:reerrors,errors:errors});
        }
      }

  /**
 * @author Somnath Keswad
 * @param  Description Download QA Lead Status Lead File
 * @return Description return leads
 */
 downloadInternalLeadDetails(e) {
  // alert("in file download..");
    let errors = {};
    var parsed = queryString.parse(this.props.location.search);
    var campID=parsed.campID;
    var parentCampID=parsed.parentCampID;
    var reallocationID=parsed.reallocationID;
    let data={
      campID:campID,
      leadDetails: this.state.campaignLeadDetails
    }
    fetch("lead/downloadQALeadDetails?campID=" + campID + "")
    .then(res => res.json())
    .then(downloadFiles =>{
        this.setState({ downloadFiles: downloadFiles })
      //    (JSON.stringify(downloadFiles));
        if(downloadFiles.success === true){
          //errors['failureMsg']="No Data Exists"
          Swal.fire({
              type: 'warning',
              
              text: 'No Data Exists!',
             
            })
         // alert("No Data Exists here");
            this.setState({errors:errors});
          }else{
  //console.log("Array Response from backend="+JSON.stringify(downloadFiles));
     //   var xlsx = json2xlsx(downloadFiles);
  //       console.log("Data from json="+JSON.stringify(downloadFiles));
  //       /* make the worksheet */
  var ws = xlsx.utils.json_to_sheet(downloadFiles);
  /* add to workbook */
  var wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, "Lead");
  
  // /* write workbook */
  var buf = xlsx.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
  var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});
  /* generate a download */
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  var fileName=parentCampID+'-'+reallocationID+"_QA_Leads"+".xlsx";
  saveAs(new Blob([s2ab(buf)],{type:"application/octet-stream"}), fileName);
  }  
    }
      )
     }

  /**
       * @author Somnath Keswad
       * @param  Description handle the upload lead from submit button on portal
       * @return Description return All details of lead
       */
      submitInternalReviewLead(e){
        e.preventDefault();
        var parsed = queryString.parse(this.props.location.search);
        var campID=parsed.campID;
        const { user} = this.props.auth;
        let errors = {};
        let data={
          campID:campID,
          campaignLeadDetails:this.state.internalLeadDetails,
          user:user
       }
          fetch("/lead/internalReviewLeadSubmit", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
          }).then(res => res.json())
          .then(internalLeadDetails =>{
           this.setState({ internalLeadDetails: internalLeadDetails, reviewLeadFlag:true})
              if(internalLeadDetails.length>0){
                // alert("lead present");
               // errors["leaderror"]="Lead reviewed successfully";
               Swal.fire({
                text: 'Lead reviewed successfully',
                type: 'success',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                // preConfirm: () => {
                //   window .location.reload();
                // }
              })
              this.setState({btnDisable:'disabled1'});
              }else
              {
                this.setState({btnDisable:'button'});
              }
          }).catch(function(err) {console.log(err)});
    } // end of submitInternalReviewLead  

  componentWillMount(){
    const {user} = this.props.auth;
    this.setState({userRole:user.role,agencyID:user.id})
  }
  componentDidMount() {
    if(!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
   
    }
  
    else{
    // alert('Inside componentDidMount');
    var parsed = queryString.parse(this.props.location.search);
  var campID=parsed.campID;
  var parentCampID=parsed.parentCampID;
 
  var reallocationID=parsed.reallocationID;
           this.setState({parentCampID,reallocationID})
        
        //This API is for campaign details from campaign table
        fetch("/publisher/reviewlead?campID=" + campID + "")
          .then(res => res.json())
          .then(reviewlead =>
            this.setState({ reviewlead: reviewlead ,campID: campID })
          ).catch(function(err) {
            console.log(err)
        });
     
      /**
       * @author Narendra Phadke
       * @param  Description fetch all campaign lead 
       * @return Description return leads
       */
            let data={
              campID:campID
            }
            fetch("/lead/campaignLeadDetails",{
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(data)
            }).then(res => res.json())
             .then(campaignLeadDetails =>{
              this.setState({ campaignLeadDetails: campaignLeadDetails})
              
              if(campaignLeadDetails.length>0){
                             //  this.setstate({btnDisable:"button"})
                             this.state.btnDisable='button'; this.forceUpdate()
                              //  alert("button disable")

                              //  alert(JSON.stringify(this.state.btnDisable))
               
                
              }
            }
            
             ).catch(function(err) {
              console.log(err)
          });
          // Get internal lead details
          fetch("/lead/internalLeadDetails",{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
          }).then(res => res.json())
          .then(internalLeadDetails =>{
            this.setState({ internalLeadDetails: internalLeadDetails})
            if(internalLeadDetails.length>0){
              this.state.btnDisable='button'; this.forceUpdate()
            }
          }).catch(function(err) {console.log(err)});
          
        }//alert(JSON.stringify(this.state.campaignLeadDetails));
  }

  /* @author Narendra Phadke
  * @param  Description hanlde change status of the lead details
  * @return Description return accepted or rejected lead
  */
  handleChange(i,e)
  {
       const { name, value } = e.target;
    
       let campaignLeadDetails = [...this.state.campaignLeadDetails];
       
      // alert(JSON.stringify(this.state.campaignLeadDetails));

       campaignLeadDetails[i] = { ...campaignLeadDetails[i], [name]: value };
       this.setState({ campaignLeadDetails });

 }
 /**
 * @author Somnath Keswad
 * @param  Description Change Status of lead
 * @return Description return status in array
 */
handleChangeInternalLead(i,e)
{
   const { name, value } = e.target;
   let internalLeadDetails = [...this.state.internalLeadDetails];
  // alert(JSON.stringify(this.state.internalLeadDetails));
   internalLeadDetails[i] = { ...internalLeadDetails[i], [name]: value };
   this.setState({ internalLeadDetails });
}
  /* @author Narendra Phadke
  * @param  Description Download the lead details
  * @return Description return excel file for download
  */
 handleDownloadLeadDetails(e) {
// alert("in file download..");
  let errors = {};
  var parsed = queryString.parse(this.props.location.search);
  var campID=parsed.campID;
  var parentCampID=parsed.parentCampID;
  var reallocationID=parsed.reallocationID;
  let data={
    campID:campID,
    leadDetails: this.state.campaignLeadDetails
  }
  fetch("lead/downloadLeadDetails?campID=" + campID + "")
  .then(res => res.json())
  .then(downloadFiles =>{
      this.setState({ downloadFiles: downloadFiles })
    //    (JSON.stringify(downloadFiles));
      if(downloadFiles.success === true){
        errors['failureMsg']="No Data Exists"
       // alert("No Data Exists here");
          this.setState({errors:errors});
        }else{
//console.log("Array Response from backend="+JSON.stringify(downloadFiles));
   //   var xlsx = json2xlsx(downloadFiles);
//       console.log("Data from json="+JSON.stringify(downloadFiles));
//       /* make the worksheet */
var ws = xlsx.utils.json_to_sheet(downloadFiles);
/* add to workbook */
var wb = xlsx.utils.book_new();
xlsx.utils.book_append_sheet(wb, ws, "Lead");

// /* write workbook */
var buf = xlsx.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});
/* generate a download */
function s2ab(s) {
	var buf = new ArrayBuffer(s.length);
	var view = new Uint8Array(buf);
	for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
	return buf;
}
var fileName=parentCampID+'-'+reallocationID+"_Internal_Review_Lead"+".xlsx";
saveAs(new Blob([s2ab(buf)],{type:"application/octet-stream"}), fileName);
}  
  }
    )
   }


   /* @author Narendra Phadke
  * @param  Description Download the lead details download only agency accepted for send to client
  * @return Description return excel file for download
  */
 handleClientDownloadLeadDetails(e) {
  let errors = {};
//alert(" in client response");
  var parsed = queryString.parse(this.props.location.search);
  var campID=parsed.campID;
  var parentCampID=parsed.parentCampID;
  var reallocationID=parsed.reallocationID;
  let data={
    campID:campID,
    leadDetails: this.state.campaignLeadDetails
  }
  fetch("lead/clientDownloadLeadDetails?campID=" + campID + "")
  .then(res => res.json())
  .then(clientDownloadLead =>{
      this.setState({ clientDownloadLead: clientDownloadLead })
//console.log("Array Response from backend="+JSON.stringify(downloadFiles));
   //   var xlsx = json2xlsx(downloadFiles);
//       console.log("Data from json="+JSON.stringify(downloadFiles));

if(clientDownloadLead.success === true){
  errors['failureMsg2']="No Data Exists"
  //alert("No Data Exists here");
    this.setState({errors:errors});
 }else{
  

//       /* make the worksheet */
var ws = xlsx.utils.json_to_sheet(clientDownloadLead);
/* add to workbook */
var wb = xlsx.utils.book_new();
xlsx.utils.book_append_sheet(wb, ws, "Lead");

// /* write workbook */
var buf = xlsx.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
var str = xlsx.write(wb, {bookType:'xlsx', type:'binary'});
/* generate a download */
function s2ab(s) {
	var buf = new ArrayBuffer(s.length);
	var view = new Uint8Array(buf);
	for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
	return buf;
}
var fileName=parentCampID+'-'+reallocationID+"Agency Accepted.xlsx";
saveAs(new Blob([s2ab(buf)],{type:"application/octet-stream"}), fileName);
} 
  }
    )
   }




 /* @author Narendra Phadke
  * @param  Description Download the lead details
  * @return Description return excel file for download
  */
 handleFileShowDownload(blob){
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
let excelFileDownloadName="ABC.xlsx";
console.warn("Blob Size in function===>"+JSON.stringify(blob));
  //console.warn("Blob Size===>"+JSON.stringify(this.state.downloadFiles));
  var newBlob = new Blob([this.state.downloadFiles], {type: "application/zip"})
// console.warn("New Blob Data===>"+JSON.stringify(newBlob));
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  } 
 
  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download=excelFileDownloadName;
  link.click();
}

      /**
       * @author Narendra Phadke
       * @param  Description handle the upload lead
       * @return Description return All details of lead
       */
      // showfilename()
      // {
        
      // alert(file name+file)
      // }
     
       handleChangeFileUpload(e) {
        e.preventDefault();

      let errors={};
      let reerrors={};
        //("ABM handle Changed");
        var parsed = queryString.parse(this.props.location.search);
        var campID=parsed.campID;
       var names=[];
          let filesData=e.target.files;
          let filesData1=e.target.files[0];
         // alert("this is name "+filesData1);
          console.warn("data files", filesData1);
          var ext
       if(filesData1==null)
       {
ext="";
var label=document.getElementById('fileName');
       label.innerHTML="No File Choosen"
       }
       else
       {
         ext=filesData1.name.split('.').pop();
         var label=document.getElementById('fileName');
         label.innerHTML=filesData1.name

       }
        let message="";
        
       

        // alert("File Name====>"+filesData);
        const {isAuthenticated, user} = this.props.auth;
        var userID=user.id;
       var userName=user.name;
       //alert("userName="+JSON.stringify(user));
       var firstName=user.firstName;
       //alert("userName="+firstName);
       var lastName=user.lastName;
          var data = new FormData();
          if(ext=="xlsx"||ext=="XLSX")
          {          
       data.append("campID",this.state.campID);
      
       data.append("message",message);
       data.append("file", filesData1);
       data.append("userID",userID);
        data.append("userName",userName);
        data.append("firstName",firstName);
        data.append("lastName",lastName);
          fetch("/lead/uploadReviewLead", {
            method: 'POST',
            //mode:'no-cors',
            body: data
          }).then(res => res.json())
        
          .then(campaignLeadDetails =>
           this.setState({ campaignLeadDetails: campaignLeadDetails, reviewLeadFlag:true})
          ).catch(function(err) {
           console.log(err)
        });
        errors['uploadsuccess']="File Uploaded Successfully"
        reerrors["fileError"]=""
          this.setState({errors:errors,reerrors:reerrors});
      }
      else
      {
        errors['uploadsuccess']=""
        reerrors["fileError"]="Please Upload File Of .xlsx Format "
          this.setState({errors:errors,reerrors:reerrors});
      }
      }

   /**
       * @author Narendra Phadke
       * @param  Description handle the upload lead
       * @return Description return All details of lead
       */
      handleUploadLeadFile(e)
      {
       e.preventDefault();
       const {isAuthenticated, user} = this.props.auth;
       var userID=user.id;
       var userName=user.name;
       var firstName=user.firstName;
       var lastName=user.lastName;
       var data = new FormData();
       data.append("campID",this.state.campID);
       data.append("file", this.state.uploadLeadFile);
        data.append("userID",userID);
        data.append("userName",userName);
        data.append("firstName",firstName);
        data.append("lastName",lastName);
       fetch("/lead/uploadReviewLead", {
        method: 'POST',
        //mode:'no-cors',
        body: data
      }).then(function (response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.json();   
        }).then(function(response) { 
          console.log("Return with response");
          //var campID=response.body.campID;
            console.log(response);
            }).catch(function (err) {
              console.log(err)
            });
      
       }

  /**
       * @author Narendra Phadke
       * @param  Description handle the View lead 
       * @return Description return All details of lead
       */
      handleViewReviewLead(e) {
        e.preventDefault();
       // alert("body appeared..");
        var parsed = queryString.parse(this.props.location.search);
        var campID=parsed.campID;
        const {isAuthenticated, user} = this.props.auth;
       let data={
          campID:campID,
          campaignLeadDetails:this.state.campaignLeadDetails,
          user:user
       }
          fetch("/lead/displayLeadReview", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
          }).then(res => res.json())
          .then(campaignLeadDetails =>
           this.setState({ campaignLeadDetails: campaignLeadDetails, reviewLeadFlag:true})
          ).catch(function(err) {
           console.log(err)
        });
    
      }  


       /**
       * @author Narendra Phadke
       * @param  Description handle the upload client response lead
       * @return Description return All details of lead
       */
      handleChangeClientResponseFileUpload(e)
      {
       e.preventDefault();
       let errors={};
       let reerrors={};
       const {isAuthenticated, user} = this.props.auth;
       var parsed = queryString.parse(this.props.location.search);
       var campID=parsed.campID;
       var reAllocationID=parsed.reallocationID;
       var userID=user.id;
       var userName=user.name;
       var firstName=user.firstName;
       var lastName=user.lastName;
       let filesData1=e.target.files[0];
       var ext
       if(filesData1==null)
       {
ext="";
var label=document.getElementById('fileName2');
       label.innerHTML="No File Choosen"
       }
       else
       {
         ext=filesData1.name.split('.').pop();
         var label=document.getElementById('fileName2');
         label.innerHTML=filesData1.name

       }       
       var data = new FormData();
       if(ext=="xlsx"||ext=="XLSX")
          {     
       data.append("campID",campID);
       data.append("reAllocationID", reAllocationID);
       data.append("file", filesData1);
        data.append("userID",userID);
        data.append("userName",userName);
        data.append("firstName",firstName);
        data.append("lastName",lastName);
       fetch("/lead/uploadClientReviewLead", {
        method: 'POST',
        //mode:'no-cors',
        body: data
      }).then(function (response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.json();   
        }).then(function(response) { 
          console.log("Return with response");
          //var campID=response.body.campID;
          //alert("Response=="+JSON.stringify(response.message));
            console.log(response);
            }).catch(function (err) {
              console.log(err)
            });
            errors['uploadsuccess2']="File Uploaded Successfully"
            reerrors["fileError2"]=""
              this.setState({errors:errors,reerrors:reerrors});
          }
          else
          {
            errors['uploadsuccess2']=""
            reerrors["fileError2"]="Please Upload File Of .xlsx Format "
              this.setState({errors:errors,reerrors:reerrors});
          }
       }

       /**
       * @author Narendra Phadke
       * @param  Description handle the upload lead from submit button on portal
       * @return Description return All details of lead
       */
       submitreviewlead(e) {
        e.preventDefault();
      
      
    

        var parsed = queryString.parse(this.props.location.search);
        var campID=parsed.campID;
        
        const {isAuthenticated, user} = this.props.auth;
// alert("submit is called")
    
        let errors = {};
        let data={
          campID:campID,
          campaignLeadDetails:this.state.campaignLeadDetails,
          user:user
       }
          fetch("/lead/uploadReviewLeadSubmit", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
          }).then(res => res.json())
          .then(campaignLeadDetails =>{
           this.setState({ campaignLeadDetails: campaignLeadDetails, reviewLeadFlag:true})
              if(campaignLeadDetails.length>0){
                // alert("lead present");
                errors["leaderror"]="Lead reviewed successfully";
                this.setState({
       
                  errors:errors,
                  btnDisable:'disabled1',
                 });
              }else
              {
                // alert("no lead");
                this.setState({
                btnDisable:'button',
              });
              }
          }).catch(function(err) {
           console.log(err)
        });
    
      }   

render(){
  // console.warn("Download file Data="+JSON.stringify(this.state.downloadFiles));
    return(
    <div>
        <PublisherNavigation/>
        
    
    <div class="container-fluid" style={{paddingTop:'85px'}}> 
   
          <Tabs activeKey={this.state.key} onSelect={this.handleSelect}>  
          <Tab eventKey={1} title="Internal Leads Review By Agency">
          <form name="InternalReviewlead"  onSubmit={this.submitInternalReviewLead} encType="multipart/form-data" >
          <div class="card card-signin my-1">
                          <div class="card-body" >
                           <div class="row">
                        <div class="col-md-1">
                              {/* IoIosArrowDropleftCircle */}
                              <a href={"allocatingCampaign#5"}  style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Active Tab"/></a>
                                </div>
                                <div class="col-md-11">
                                 
                                <center><label id="label" style={{fontSize:"20px",fontWeight:"bold",color:"#056eb8"}}>Internal Lead Review</label></center>
                              <br/>
                                    </div>
                                   
                        </div>
                        {/* <hr/> */}
                        <div style={{  MozBoxShadow:'3px 3px 5px 0px #e6e6ff',WebkitBoxShadow:'3px 3px 5px 1px #e6e6ff',boxShadow:'3px 3px 5px 0px #e6e6ff'}}>
                        <div style={{paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px"}}>


                        {this.state.reviewlead.map(
                        reviewlead => (    
                        <div class="row">
                            <div class="col-sm-2">
                              <label id="label">
                                Campaign ID: 
                              </label>
                                {this.state.parentCampID}
                          </div>
                          <div class="col-sm-3" >
                              <label id="label" >
                                Campaign Name: 
                              </label>
                              {reviewlead.campaignName}
                             
                            </div>
                           
                            <div class="col-sm-2">
                              <label id="label" >Campaign Status: </label>
                              {reviewlead.campaignStatus}
                            </div>
                            <div class="col-sm-3">
                              <label id="label" style={{marginLeft:'2px'}}>Start Date: </label>
                              {reviewlead.startDate}
                            </div>
                          
                            <div class="col-sm-2">
                              <label id="label" >End Date: </label>
                              {reviewlead.endDate}
                            </div>
                            
                                  </div>
                                  )
                                  )}
                                
                                 

          {/* <hr/> */}
          </div>
          </div>
          <br/>
                             
          <div class="row">       
{/* className=" table table-bordered table-striped "  */}
<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">                       
<p>Instruction :-Click on "Download QA Leads" to get Lead File</p>
<button class="btn  btn-primary " type="button" onClick={this.downloadInternalLeadDetails} style={{backgroundColor:"#056eb8",color:"white"}}>
Download QA Leads
  </button>  
</div>

<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
<p>Instruction :- Click below to upload reviewed file.</p> 
<label class="chooseFile btn btn-default">Choose File                                   
<input style={{display:'none'}} type="file"  id="abm1"  name="abmFiles" 
                            onChange={this.uploadInternalLeadFile}/></label><label id="fileName">No File Choosen</label>
                             <div className="successMessage">{this.state.errors.uploadsuccess}</div>
                            <div className="errorMessage">{this.state.reerrors.fileError}</div>
 {/* <Upload  onChange={this.handleupload}>
                        <Button>
                            <Icon type="upload" /> Click to Upload
                        </Button>
                    </Upload> */}
 </div>
</div><br/>

<div style={{fontSize:'18px',color:'green',textAlign:'center'}}>
          {this.state.errors.leaderror}
          
          </div>
          <div style={{fontSize:'20px',color:'red',textAlign:'center'}}>
          {this.state.errors.failureMsg}
          </div>
          
         

{/* <div class="row" style={{paddingTop:'20px'}}> */}
<div class="table-responsive-lg">
<table id="myTable" class="table" className=" table table-bordered table-striped ">
                                        <thead class="thead1">
                                          <tr class="info tr1">
                                          <th  class="table-header">Lead ID</th>
                        {/* <th>Assest ID</th> */}
                        <th   class="table-header">Email</th>
                        <th   class="table-header">Job title</th>
                        <th  class="table-header" >Status</th>
                        <th  class="table-header">Reason for rejection</th>
                                          </tr>
                                        </thead>
                        
                                        <tbody style={{height:'260px'}} className="tbody1">
                                        {this.state.internalLeadDetails.map((internalLeadDetails,i) => (
                                        <tr className="tr1" >
                      <td>
                        {internalLeadDetails.leadInfoID}
                      </td>
                      {/* <td>
                      {internalLeadDetails.supportDocID}
                        </td> */}
                        <td>
                        {internalLeadDetails.email}
                        </td>
                         <td>
                        {internalLeadDetails.jobTitle}
                        </td>
                        {this.state.reviewLeadFlag===true ? 
                         <td><label>{internalLeadDetails.status}</label> </td>
                        : <td>
                        <select style={{ height: '60%' }} 
                                    id="status" name="status"
                                    Value={internalLeadDetails.status}
                                    defaultValue={this.state.internalstatus} 
                                   onChange={this.handleChangeInternalLead.bind(this,i)}
                                  className="form-control">
                                  <option value="AgencyInternalReview">AgencyInternalReview</option>
                                  {/* <option value="Approved">Approved</option> */}
                                  <option value="Rejected">Rejected</option>
                                  </select>
                        </td>}

                        {this.state.reviewLeadFlag===true ? 
                        <td>
                          <input type="textbox" id="reason" name="reason"
                          defaultValue={internalLeadDetails.reason} onChange={this.handleChange.bind(this,i)}
                          class="form-control"></input>
                        </td>:
                        <td>
                          <input type="textbox" id="reason" name="reason" value={this.state.reason}
                           onChange={this.handleChange.bind(this,i)}
                          class="form-control"></input>
                        </td>}
                        </tr>
                        ))}
                        </tbody>
                        
                                       
                                       </table>
                                         </div> 
                                        {/* end of col */}

                                        <div class="col" align="right" style={{paddingBottom:'20px'}}>
                                          <button class="btn  btn-primary  text-uppercase" 
                                          className={this.state.btnDisable} type="submit"
                                        style={{backgroundColor:"#056eb8",color:"white"}}
                                          >Submit</button>
                                      </div>
                                      
</div>
</div>
</form>
    </Tab> 
    {this.state.userRole==='AC'?
       <Tab eventKey={2} title="Lead QA review by Agency">
       <form name="reviewlead"  onSubmit={this.submitreviewlead} encType="multipart/form-data" >
          <div class="card card-signin my-1">
                          <div class="card-body" >
                           <div class="row">
                        <div  align="right" className="col-xs-12 col-sm-12 col-md-6 col-lg-6 "> 
                        <label id="">Internal Lead Review </label>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                        <button style={{float:'right' ,backgroundColor:"#056eb8",color:"white",height:'31px',fontSize:'14px',paddingTop:'2px'}}class="btn  btn-primary ">
                        <a style={{float:'right' ,color:'#ffffff'}} href="allocatingCampaign#5">Back to Active Tab</a>
                        </button>
                        </div>
                        </div>
                        <hr/>

                        {this.state.reviewlead.map(
                        reviewlead => (    
                        <div class="row">
                            <div class="col-sm-2">
                              <label id="label">
                                Campaign ID: 
                              </label>
                                {this.state.parentCampID}
                          </div>
                          <div class="col-sm-3" >
                              <label id="label" >
                                Campaign Name: 
                              </label>
                              {reviewlead.campaignName}
                             
                            </div>
                            <div class="col-sm-2">
                              <label id="label" >Start Date: </label>
                              {reviewlead.startDate}
                            </div>
                            <div class="col-sm-3">
                              <label id="label" >Campaign Status: </label>
                              {reviewlead.campaignStatus}
                            </div>
                          
                            <div class="col-sm-2">
                              <label id="label" >End Date: </label>
                              {reviewlead.endDate}
                            </div>
                                  </div>
                                  )
                                  )}
                                
                                 

          <hr/>
                             
          <div class="row">       
{/* className=" table table-bordered table-striped "  */}
<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">                       
<p>Instruction :-Review Leads by clicking on "Download Internal Review Leads" or by looking at the table</p>
<button class="btn  btn-primary " type="button" onClick={this.handleDownloadLeadDetails} style={{backgroundColor:"#056eb8",color:"white",height:'31px',fontSize:'14px'}}>
Download Internal Review Leads
  </button>  
</div>

<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
<p>Instruction :- After Reviewing leads Please click below and select respected file and upload it</p>                                    
<label class="chooseFile btn btn-default">Choose File
<input style={{display:'none'}} type="file"  id="abm1"  name="abmFiles" multiple="multiple"
                            onChange={this.handleChangeFileUpload}/></label><label id="fileName">No File Choosen</label>
                            <div class="successMessage">{this.state.errors.uploadsuccess}</div>
                            <div class="errorMessage">{this.state.reerrors.fileError}</div>
 {/* <Upload  onChange={this.handleupload}>
                        <Button>
                            <Icon type="upload" /> Click to Upload
                        </Button>
                    </Upload> */}
 </div>
</div><br/>

<div style={{fontSize:'18px',color:'green',textAlign:'center'}}>
          {this.state.errors.leaderror}
          
          </div>
          <div style={{fontSize:'20px',color:'red',textAlign:'center'}}>
          {this.state.errors.failureMsg}
          </div>
          
         

{/* <div class="row" style={{paddingTop:'20px'}}> */}
<div class="table-responsive-lg">
<table id="myTable" class="table" className=" table table-bordered table-striped ">
                                        <thead class="thead1">
                                          <tr class="info tr1">
                                          <th class="table-header">Lead ID</th>
                        {/* <th>Assest ID</th> */}
                        <th  class="table-header" >Email</th>
                        <th  class="table-header" >Job title</th>
                        <th  class="table-header">Status</th>
                        <th  class="table-header">Reason for rejection</th>
                                          </tr>
                                        </thead>
                        
                                        <tbody style={{height:'260px'}} className="tbody1">
                                        {this.state.campaignLeadDetails.map((campaignLeadDetails,i) => (
                                        <tr className="tr1" >
                      <td>
                        {campaignLeadDetails.leadInfoID}
                      </td>
                      {/* <td>
                      {campaignLeadDetails.supportDocID}
                        </td> */}
                        <td>
                        {campaignLeadDetails.email}
                        </td>
                         <td>
                        {campaignLeadDetails.jobTitle}
                        </td>
                        {this.state.reviewLeadFlag===true ? 
                         <td><label>{campaignLeadDetails.status}</label> </td>
                        : <td>
                        <select style={{ height: '60%' }} 
                                    id="status" name="status"
                                    Value={campaignLeadDetails.status}
                                    defaultValue={this.state.status} 
                                   onChange={this.handleChange.bind(this,i)}
                                  className="form-control">
                                  <option value="Accepted">Accepted</option>
                                  {/* <option value="Approved">Approved</option> */}
                                  <option value="Rejected">Rejected</option>
                                  </select>
                        </td>}

                        {this.state.reviewLeadFlag===true ? 
                        <td>
                          <input type="textbox" id="reason" name="reason"
                          defaultValue={campaignLeadDetails.reason} onChange={this.handleChange.bind(this,i)}
                          class="form-control"></input>
                        </td>:
                        <td>
                          <input type="textbox" id="reason" name="reason" value={this.state.reason}
                           onChange={this.handleChange.bind(this,i)}
                          class="form-control"></input>
                        </td>}
                        </tr>
                        ))}
                        </tbody>
                        
                                       
                                       </table>
                                         </div> 
                                        {/* end of col */}

                                        <div class="col" align="right" style={{paddingBottom:'20px'}}>
                                          <button class="btn  btn-primary  text-uppercase" 
                                          className={this.state.btnDisable} type="submit"
                                        style={{backgroundColor:"#056eb8",color:"white",height:'31px',fontSize:'14px'}}
                                          >Submit</button>
                                      </div>
                                      
</div>
</div>
</form> 
    </Tab>:''} 
              
              <Tab eventKey={3} title="Lead QA review by Client"> 
              <div class="card card-signin my-1">
                          <div class="card-body" >
                           <div class="row">
                        <div  align="right" className="col-xs-12 col-sm-12 col-md-6 col-lg-6 "> 
                        <label id="">Client Response </label>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                        <button style={{float:'right',backgroundColor:"#056eb8",color:"white",height:'31px',fontSize:'14px',paddingTop:'2px'}}class="btn  btn-primary ">
                        <a style={{float:'right' ,color:'#ffffff'}} href="allocatingCampaign#5">Back to Live Campaign</a>
                        </button>
                        </div>
                        </div>
                        <hr/>

                        {this.state.reviewlead.map(
                        reviewlead => (    
                        <div class="row">
                            <div class="col-sm-2">
                              <label id="label">
                                Campaign ID: 
                              </label>
                                {this.state.parentCampID}
                          </div>
                          <div class="col-sm-4" >
                              <label id="label" >
                                Campaign Name: 
                              </label>
                              {reviewlead.campaignName}
                             
                            </div>
                            <div class="col-sm-2">
                              <label id="label" >Start Date: </label>
                              {reviewlead.startDate}
                            </div>
                            <div class="col-sm-2">
                              <label id="label" >Campaign Status: </label>
                              {reviewlead.campaignStatus}
                            </div>
                          
                            <div class="col-sm-2">
                              <label id="label" >End Date: </label>
                              {reviewlead.endDate}
                            </div>
                                  </div>
                                  )
                                  )}
                                
                                 

          <hr/>
                             
          <div class="row">       
{/* className=" table table-bordered table-striped "  */}
<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">                       
<p>Instruction :-Click on "Download Client Response" to get Client Review fileW</p>
<button class="btn  btn-primary " type="button" onClick={this.handleClientDownloadLeadDetails} style={{backgroundColor:"#056eb8",color:"white",height:'31px',fontSize:'14px',paddingTop:'2px'}}>
Download Client Response
  </button>  
</div>

<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
<p>Instruction :- After Reviewing leads Please click below and select respected file and upload it</p>   
<br/>
<label class="chooseFile btn btn-default">Choose File                                   
<input  style={{display:'none'}}type="file"  id="abm1"  name="abmFiles" multiple="multiple"
                            onChange={this.handleChangeClientResponseFileUpload}/></label><label id="fileName2">No File Choosen</label>
                            <div class="successMessage">{this.state.errors.uploadsuccess2}</div>
                            <div class="errorMessage">{this.state.reerrors.fileError2}</div>

 </div>
</div>

<div style={{fontSize:'18px',color:'red',textAlign:'center'}}>
          {this.state.errors.failureMsg2}
          </div>
</div>
</div>
              </Tab>
          </Tabs>   
          
                      
   </div>                   
                      
  



            
        
        <Footer/>
    </div>
     )
}

}
 /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      Reviewlead.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
  
    const mapStateToProps = (state) => ({
        auth: state.auth
    })

export default connect(mapStateToProps, { logoutUser })(withRouter(Reviewlead));
