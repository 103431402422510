/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
import React from "react";
//import Footer from "../layouts/footer";	//Chaitanya-4751-Removed Console Warning
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
//import { DropdownMenu, MenuItem, Card } from "react-bootstrap-dropdown-menu";	//Chaitanya-4751-Removed Console Warning
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Akash-bug-4139
import { Tooltip } from "antd"; // Aman -Task-4619- (added this Tooltip CLIENT SWITCH in nav bar)- PC/PNC-Login - Client Switch option
class PublisherNavigation extends React.Component {
  //Aman Task - 4619 -(created to get/set the value ) PC/PNC-Login - Client Switch option
  constructor() {
    super();
    this.state = {
      data: [],
      oldvalue: this.filterData,
    };
  }
  /**
   * @author Narendra Phadke
   * @param  Description handle the logout authentication
   * @return Description return All details of authentication
   */
  onLogout(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;	//Chaitanya-4751-Removed Console Warning
    let data = {
      //user: user, //Akash-bug-4139 removed user
    };
    fetch("users/userLogout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {})
      .catch((e) => console.log(e)); //Somnath Task-3943, Handle error in catch block

    this.props.logoutUser(this.props.history);
  }

  onPublisherView() {
    const { user } = this.props.auth;

    const pID = user.id;
    window.location.href = "/publisherView?pID=" + pID;
  }

  updateExistLead(e) {
    e.preventDefault();
    const { user } = this.props.auth;

    const pID = user.id;
    // window.location.href = "/updateExistingLead?pID=" + pID;
    this.props.history.push("/updateExistingLead", { pID }); //Sandeep-task-3729-VAPT-Publisher side--campaign--all sub menues--URL links-BE
  }

  onHome(e) {
    e.preventDefault();

    this.props.history.push("/newPublisherDashboard"); //karan-task-3717-replace query params
  }

  //Aman-Task-4894 -Added for Suppression Comparison
  suppressionCompare(e) {
    e.preventDefault();
    window.location.href = "/suppressionCompare";
  }

  //Aman-Task-4998 -Added for domain Exclusion Comparison
  domainExclusionComparison(e) {
    e.preventDefault();
    window.location.href = "/domainExclusionComparison";
  }

  //Aman-Task-5060 -Added for Email EmailStatusChecker
  EmailStatusChecker(e) {
    e.preventDefault();
    window.location.href = "/EmailStatusChecker"; //Aman-5108 chnaged BriteStatus Verifier to EmailStatusChecker
  }

  //Aman-task-4619-(Added this function to navgate one to other page)-PC/PNC-Login - Client Switch option
  onClientSwitch(e) {
    e.preventDefault();
    this.props.history.push("/SwitchClient");
  }

  onHome1(e) {
    e.preventDefault();

    this.props.history.push("/newPublisherDashboard"); //karan-task-3717-replace query params
  }

  onChangePassword(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;	//Chaitanya-4751-Removed Console Warning
    this.props.history.push("/changePassword"); //Priyanka--3944--removed params
    //localStorage.removeItem("activeKey");
  }
  leadDeliveryReport(e) {
    e.preventDefault();
    //const { user } = this.props.auth;	//Chaitanya-4751-Removed Console Warning
    this.props.history.push(`/publisherLeadDeliveryReport`); //Somnath Task-3943, remove pID
    //window.location.href = "/publisherLeadDeliveryReport?pID=" + user.id;
    //  localStorage.removeItem('activeKey');
  }
  myReport(e) {
    e.preventDefault();
    //const { user } = this.props.auth;	//Chaitanya-4751-Removed Console Warning
    window.location.href = "/publisherMyReport";
  } //snehal-task-3357-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report
  campaignListPage(e) {
    e.preventDefault();
    //const { user } = this.props.auth;	//Chaitanya-4751-Removed Console Warning
    this.props.history.push("/publisherCampaignList"); //karan-task-3717-replace query params
    //  localStorage.removeItem('activeKey');
  }
  publisherRFPAnalysisPage(e) {
    e.preventDefault();
    const { user } = this.props.auth;
    let pID = user.id;
    // window.location.href = "/publisherRFPAnalysis?pID=" + user.id;
    this.props.history.push("/publisherRFPAnalysis", { pID }); //Sandeep-task-3729-VAPT-Publisher side--campaign--all sub menues--URL links-BE
    //  localStorage.removeItem('activeKey');
  }

  onEmailConfigPublisher(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;	//Chaitanya-4751-Removed Console Warning
    this.props.history.push("/publisherEmailConfig"); //Priyanka--3944-replace query params
    //localStorage.removeItem("activeKey");
  }

  onAddUser(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;	//Chaitanya-4751-Removed Console Warning
    this.props.history.push("/addUserPublisher"); //Priyanka--3944--removed params
    //localStorage.removeItem("activeKey");
  }
  pubInfo(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;	//Chaitanya-4751-Removed Console Warning
    this.props.history.push("/publisherInformation"); //Priyanka--3944--removed params
    //localStorage.removeItem("activeKey");
  }
  getInvoice(e) {
    e.preventDefault();
    //const { user } = this.props.auth;	//Chaitanya-4751-Removed Console Warning
    // window.location.href = "/displayPublisherInvoice?pID=" + user.id;
    this.props.history.push("/displayPublisherInvoice"); //Kiran- task 3732 - publisher side--finance --Invoice-URL links & API-FE
  }
  /**
   * @author Narendra Phadke
   * @param  Description handle the ABM upgrade
   * @return Description return All details of ABM upgrade
   */
  abmUpgrade(e) {
    e.preventDefault();
    window.location.reload();
    window.location.href = "/publisherDomainName";
    localStorage.removeItem("activeKey");
  }
  //rutuja tas-4654 PC- Reports - Lead Report By Campaign
  leadReportByCampaign(e) {
    e.preventDefault();
    //const { user } = this.props.auth;	//Chaitanya-4751-Removed Console Warning
    window.location.href = "/publisherLeadReportByCampaign";
  }
  //Aman Task - 4619 -(for getting the user info from the Token) PC/PNC-Login - Client Switch option
  componentWillMount() {
    var x = localStorage.getItem("jwtToken");
    var base64Url = x.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    var oldData = JSON.parse(jsonPayload);
    var old_info = oldData.userDetails;
    const filterData = old_info;
    var data = filterData;
    this.setState({ data: data });
  }
  render() {
    /**
     * @author Narendra Phadke
     * @param  Description handle the login authentication
     * @return Description return All details of authentication
     */
    const { isAuthenticated, user } = this.props.auth;
    // console.warn("In publisher navPage login"+JSON.stringify(this.props.auth));
    //const pID = this.props.auth.id;	//Chaitanya-4751-Removed Console Warning

    const authLinks = (
      <ul class="navbar-nav navbar-right">
        {user.role === "PC-ACCOUNT" ? (
          ""
        ) : user.role === "PC" || user.role === "PQA" ? (
          <li class="nav-item">
            <a
              className="abc nav-link"
              style={{ color: "aliceblue" }}
              href="#/" //Chaitanya-4751-Removed Console Warning
              onClick={this.onHome.bind(this)}
            >
              {" "}
              <span
                className="glyphicon glyphicon-home"
                style={{ color: "aliceblue" }}
              ></span>
              &nbsp;HOME
            </a>
          </li>
        ) : (
          <li class="nav-item">
            <a
              className="abc nav-link"
              style={{ color: "aliceblue" }}
              href="#/" //Chaitanya-4751-Removed Console Warning
              onClick={this.onHome1.bind(this)}
            >
              {" "}
              <span
                className="glyphicon glyphicon-home"
                style={{ color: "aliceblue" }}
              ></span>
              &nbsp;HOME
            </a>
          </li>
        )}
        &nbsp;&nbsp;
        {user.role === "PC" || user.role === "PNC" || user.role === "PQA" ? (
          <li class="nav-item dropdown">
            <a
              href="#/" //Chaitanya-4751-Removed Console Warning
              class="abc nav-link dropdown-toggle dropbtn"
              style={{ color: "aliceblue" }}
              id="navbardrop"
              data-toggle="dropdown"
            >
              CAMPAIGNS
            </a>
            <div class="dropdown-content dropdown-menu">
              <a
                className="dropdown-item"
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.campaignListPage.bind(this)}
              >
                Campaign Progress
              </a>

              {user.role === "PC" ? (
                <a
                  className="dropdown-item"
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.publisherRFPAnalysisPage.bind(this)}
                >
                  Submitted RFP
                </a>
              ) : (
                ""
              )}
              {user.role === "PC" ||
              user.role === "PNC" ||
              user.role === "PQA" ? (
                <a
                  className="dropdown-item"
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.updateExistLead.bind(this)}
                >
                  Update Existing Leads
                </a>
              ) : (
                ""
              )}
              {/* //shivani-3245-added sub menu as "ABM" Upgrade */}
              <a
                className="dropdown-item "
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.abmUpgrade.bind(this)}
              >
                ABM Upgrade
              </a>
            </div>
          </li>
        ) : (
          ""
        )}
        {/* &nbsp;&nbsp;
          &nbsp;&nbsp;  
    {user.role==="PC" || user.role==="PNC" ||user.role==="PQA"?
                 <li class="nav-item"><a href="#"  onClick={this.updateExistLead.bind(this)} className="abc nav-link" style={{color:'aliceblue'}} >UPDATE EXIST LEAD</a></li>:''} */}
        &nbsp;&nbsp;
        {user.role === "PC" || user.role === "PNC" || user.role === "PQA" ? (
          <li class="nav-item dropdown">
            <a
              href="#/" //Chaitanya-4751-Removed Console Warning
              class="abc nav-link dropdown-toggle dropbtn"
              style={{ color: "aliceblue" }}
              id="navbardrop"
              data-toggle="dropdown"
            >
              REPORT
            </a>
            <div class="dropdown-content dropdown-menu">
              {user.role === "PC" ? (
                <a
                  className="dropdown-item"
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.myReport.bind(this)}
                >
                  My Reports
                </a>
              ) : (
                ""
              )}
              <a
                className="dropdown-item"
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.leadDeliveryReport.bind(this)}
              >
                Lead Delivery
              </a>

              {/* rutuja tas-4654 PC- Reports - Lead Report By Campaign */}

              {user.role === "PC" ? (
                <a
                  className="dropdown-item"
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.leadReportByCampaign.bind(this)}
                >
                  Lead Report by campaign
                </a>
              ) : (
                ""
              )}

              {/* Nilesh-4653-Added Lead Report By Client */}
              {user.role === "PC" ? (
                <a className="dropdown-item" href="./leadReportClientByPC">
                  Lead Report By Client
                </a>
              ) : (
                ""
              )}
            </div>
          </li>
        ) : (
          ""
        )}
        {user.role === "PC-ACCOUNT" || user.role === "PC" ? (
          <li class="nav-item dropdown">
            <a
              href="#/" //Chaitanya-4751-Removed Console Warning
              className="abc nav-link dropdown-toggle dropbtn"
              style={{ color: "aliceblue" }}
              id="navbardrop"
              data-toggle="dropdown"
            >
              FINANCE
            </a>
            <div class="dropdown-content dropdown-menu">
              <a
                className="dropdown-item"
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.getInvoice.bind(this)}
              >
                Invoice
              </a>
            </div>
          </li>
        ) : (
          ""
        )}
        &nbsp;&nbsp;
        {/* Aman -Task-4619- (added this new CLIENT SWITCH button in nav bar)- PC/PNC-Login - Client Switch option */}
        {this.state.data.length > 1 ? (
          <>
            {user.role === "PC" || user.role === "PNC" ? (
              <li class="nav-item">
                <a
                  className="abc nav-link"
                  style={{ color: "aliceblue" }}
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.onClientSwitch.bind(this)}
                >
                  {" "}
                  <Tooltip placement="bottom" title="Switch Login">
                    {" "}
                    {/* Task-4667- ref(4619)--(added this for Additional requirement )Showing "," in Linked Client list for particular client */}
                    <span
                      className="glyphicon glyphicon-transfer"
                      style={{ color: "aliceblue" }}
                    ></span>
                  </Tooltip>
                </a>
              </li>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        &nbsp;&nbsp;
        <li class="nav-item dropdown">
          <a
            href="#/" //Chaitanya-4751-Removed Console Warning
            className="abc nav-link dropdown-toggle dropbtn"
            style={{ color: "aliceblue" }}
            id="navbardrop"
            data-toggle="dropdown"
          >
            <span
              class="	glyphicon glyphicon-cog"
              style={{ color: "aliceblue" }}
            ></span>
            {/* MY ACCOUNT */}
          </a>
          <div class="dropdown-content dropdown-menu">
            <a
              className="dropdown-item"
              href="#/" //Chaitanya-4751-Removed Console Warning
              onClick={this.onChangePassword.bind(this)}
            >
              Change Password
            </a>
            {user.role === "PC" ||
            user.role === "PNC" ||
            user.role === "PQA" ? (
              <a
                className="dropdown-item"
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.onEmailConfigPublisher.bind(this)}
              >
                Email Notification
              </a>
            ) : (
              ""
            )}
            {user.role === "PC" ? (
              <a
                className="dropdown-item"
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.onAddUser.bind(this)}
              >
                Add User
              </a>
            ) : (
              ""
            )}
            {user.role === "PC" || user.role === "PNC" ? (
              <a
                className="dropdown-item"
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.pubInfo.bind(this)}
              >
                Publisher Information
              </a>
            ) : (
              ""
            )}

            {/*Aman-Task-4894 -Added for Suppression Comparison only for PC/PNC*/}
            {user.role === "PC" || user.role === "PNC" ? (
              <a
                className="dropdown-item"
                href="#/"
                onClick={this.suppressionCompare.bind(this)}
              >
                Email Suppression Compare
              </a>
            ) : (
              ""
            )}

            {/*Aman-Task-4998 -Added for domain Exclusion Comparison only for PC/PNC*/}
            {user.role === "PC" || user.role === "PNC" ? (
              <a
                className="dropdown-item"
                href="#/"
                onClick={this.domainExclusionComparison.bind(this)}
              >
                Domain Exclusion Compare
              </a>
            ) : (
              ""
            )}

            {/*Aman-Task-5060 -Added for Email BriteStatus Verifier only for PC/PNC*/} 
            {/* Aman-5108 chnaged BriteStatus Verifier to EmailStatusChecker */}
            {user.role === "PC" || user.role === "PNC" ? (
              <a
                className="dropdown-item"
                href="#/"
                onClick={this.EmailStatusChecker.bind(this)}
              >
                Email Status Checker
              </a>
            ) : (
              ""
            )}
          </div>
        </li>
        &nbsp;&nbsp;
        <li className="nav-item">
          <a
            href="#/" //Chaitanya-4751-Removed Console Warning
            className="abc nav-link"
            style={{ color: "aliceblue" }}
            onClick={this.onLogout.bind(this)}
          >
            <span
              className="glyphicon glyphicon-log-out"
              style={{ color: "aliceblue" }}
            ></span>
          </a>
        </li>
      </ul>
    );
    const guestLinks = (
      <ul className="navbar-nav navbar-right">
        <li className="nav-item">
          <Link className="nav-link" to="/userLogin">
            Sign Up
          </Link>
        </li>
      </ul>
    );
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-dark navbar-fixed-top">
          <img
            src="DemandIntegrateLogo_White.png"
            alt="Demand Integrate"
            height="50px"
            width="200px"
          ></img>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {user.role === "PC" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;
                <b style={{ color: "#00FF00" }}>
                  {user.firstName}&nbsp;{user.lastName}
                </b>{" "}
                - Publisher Commercial
              </h5>
            ) : (
              ""
            )}
            {user.role === "PNC" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;
                <b style={{ color: "#00FF00" }}>
                  {user.firstName} &nbsp;{user.lastName}
                </b>{" "}
                - Publisher Non-Commercial
              </h5>
            ) : (
              ""
            )}
            {user.role === "PQA" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;
                <b style={{ color: "#00FF00" }}>
                  {user.firstName}&nbsp;{user.lastName}
                </b>{" "}
                - Publisher Quality Assurance
              </h5>
            ) : (
              ""
            )}

            {/* kiran - bug 4087- added user and role for PAC */}

            {user.role === "PC-ACCOUNT" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;
                <b style={{ color: "#00FF00" }}>
                  {user.firstName}&nbsp;{user.lastName}
                </b>{" "}
                - Publisher Accounting
              </h5>
            ) : (
              ""
            )}
            {/* kiran - bug 4087- added user and role for PAC */}

            {isAuthenticated ? authLinks : guestLinks}
          </div>
        </nav>

        {/* <br></br>  <br></br><br></br><br></br><br></br>
     <Footer/> */}
      </div>
    );
  }
}
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
PublisherNavigation.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(PublisherNavigation)
);
