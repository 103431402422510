/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Sanjana Godbole,Somnath,
@Creation Date:22-10-2019
@Description:UI for AgencyInvoice(Dashboard)
*/

import React from "react";
import Navigation from "../layouts/navPage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import { Table } from "antd";
import { css } from "emotion";
import { Tooltip } from "antd";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
// const queryString = require("query-string");//kiran-4745-removing console warning-no used
const Swal = require("sweetalert2");
const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",
		color: "white",
	},
	"& thead > tr >th": {
		border: "1px solid black",
		// borderWidth: '2px',
		// borderColor: 'yellow',
		// borderStyle: 'solid'
		color: "white",
	},
});
class AgencyInvoice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			invoiceDetail: [],
			tableState: { size: "small" }, //Array for table properties
			agencyID: "",
			pID: "",
			year: "",
			month: "",
			extension: "",
			filename: "",
			downloadFiles: [],
			// pID: "",//kiran-4745-removing console warning-removed duplicate
		};
		this.reviewInvoice = this.reviewInvoice.bind(this);
		this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
	} //end of constructor

	/**
	 * @author Somnath Keswad
	 * @param  Description  Get the invoice details from database
	 * @return Description return response
	 */
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			const { user } = this.props.auth; //kiran-4745-removing console warning-no used
			// karan-task-3715-replace query params
			var month, year;
			if (this.props.location.state !== undefined) {
				month = this.props.location.state.month;
				year = this.props.location.state.Year;
			}

			this.setState({ agencyID: user.id, year: year, month: month });

			fetch(
				"agencyDashboard/invoiceDetail?agencyID=" +
					user.id +
					"&month=" +
					month +
					"&year=" +
					year
			)
				.then((res) => res.json())
				.then((invoiceDetail) => {
					this.setState({ invoiceDetail: invoiceDetail });
				})
				.catch(function (err) {
					console.log(err);
				});
		} // End of else of Authontication
	} // End of componentDidMount

	/**
	 * @author Somnath Keswad
	 * @param  Description  Get the invoice document from database
	 * @return Description return response in Blob
	 */
	getInvoiceDocument(e) {
		e.preventDefault();
		var invoiceID = e.target.id;
		const { user } = this.props.auth;
		const agencyID = user.id;
		// var month = e.target.getAttribute("month");
		// var year = e.target.getAttribute("year");
		// var type = e.target.getAttribute("type");//kiran-4745-removing console warning-no used
		var invoiceDocumentName = e.target.getAttribute("invoiceDocumentName");
		var pID = e.target.getAttribute("pID");
		//var extension=invoiceDocumentName.split('.').pop();
		//extension:extension,
		this.setState({ filename: invoiceDocumentName });
		fetch(
			"/agencyDashboard/getInvoiceDocument?agencyID=" +
				agencyID +
				"&invoiceID=" +
				invoiceID
		)
			.then((r) => r.blob())
			.then((downloadFiles) =>
				this.setState({ downloadFiles: downloadFiles, pID: pID })
			)
			.then(this.handleFileShowDownload);
	} //nd of getInvoiceDocument

	handleFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		let zipFileDownloadName = this.state.pID + "_Invoice_Documents.zip";

		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/zip",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}

	getInvoicePDF(e) {
		var invoiceID = e.target.id;
		var invoiceDocumentName = e.target.getAttribute("invoicePdfName");
		var pID = e.target.getAttribute("pID");
		var extension = invoiceDocumentName.split(".").pop();
		this.setState({ extension: extension, filename: invoiceDocumentName });
		fetch(
			"/agencyDashboard/getInvoicePDF?invoiceID=" + invoiceID + "&pID=" + pID
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.reviewInvoice);
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description  Get the popup for review invoice by agency
	 * @return Description return status as AgencyReview as per user action
	 */
	displayInvoicePopup(e) {
		e.preventDefault();
		var invoiceID = e.target.id;
		var pID = e.target.getAttribute("pID");
		this.setState({ invoiceID: invoiceID, pID: pID });
	} // End of displayInvoicePopup

	/**
	 * @author Somnath Keswad
	 * @param  Description  Get the invoice document from array
	 * @return Description download the file as per extension
	 */
	reviewInvoice(blob) {
		if (this.state.extension === "pdf") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pdfFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/pdf",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			window.open(data, "_blank");
			link.download = pdfFileDownloadName;
			link.click();
		}
		if (
			this.state.extension === "xlsx" ||
			this.state.extension === "xls" ||
			this.state.extension === "csv"
		) {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/vnd.ms-excel",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = fileName;
			link.click();
		}

		if (this.state.extension === "ppt" || this.state.extension === "pptx") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let pptFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/vnd.ms-powerpoint",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = pptFileDownloadName;
			link.click();
		}

		if (this.state.extension === "docx") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/msword",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
		if (this.state.extension === "txt") {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "text/plain",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
		//kiran-4745-removing console warning-changed == to ===
		if (
			this.state.extension === "png" ||
			this.state.extension === "jpeg" ||
			this.state.extension === "jpg" ||
			this.state.extension === "PNG" ||
			this.state.extension === "JPEG" ||
			this.state.extension === "JPG"
		) {
			let url = this.state.filename;
			let fileName = url.substring(url.lastIndexOf("\\") + 1);
			let docFileDownloadName = fileName;
			let newBlob = new Blob([this.state.downloadFiles], {
				type: "application/Image",
			});
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}
			const data = window.URL.createObjectURL(newBlob);
			let link = document.createElement("a");
			link.href = data;
			link.download = docFileDownloadName;
			link.click();
		}
	} /**** End of downloadPOCFie */

	downloadFeedbackFile(e) {
		e.preventDefault();
		// var invoiceID=e.target.id;
		const { user } = this.props.auth;
		const agencyID = user.id;
		var month = e.target.getAttribute("month");
		var year = e.target.getAttribute("year");
		var pID = e.target.getAttribute("pID");
		var feedbackFIleName = e.target.getAttribute("fileName");
		var extension = feedbackFIleName.split(".").pop();
		this.setState({ extension: extension, filename: feedbackFIleName });
		fetch(
			"/agencyDashboard/feedbackFileDownload?agencyID=" +
				agencyID +
				"&pID=" +
				pID +
				"&month=" +
				month +
				"&year=" +
				year +
				"&fileName=" +
				feedbackFIleName
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.reviewInvoice);
	} // End of downloadFeedbackFile

	/**
	 * @author Somnath Keswad
	 * @param  Description  update the invoice as per agency
	 * @return Description return agencyReviewed Status
	 */
	invoiceReviewed(e) {
		var invoiceID = e.target.id;
		var pID = e.target.getAttribute("pID");
		var month = e.target.getAttribute("month");
		var year = e.target.getAttribute("year");
		const { user } = this.props.auth;
		Swal.fire({
			text: "This confirm your invoice review is done and ready to share. Do you want to send to Publisher",
			type: "question",
			confirmButtonText: "Yes",
			showCancelButton: true,
			cancelButtonColor: "#d33",
			confirmButtonColor: "#3085d6",
			allowOutsideClick: false,
		}).then((result) => {
			if (result.value === true) {
				// var parsed = queryString.parse(this.props.location.search);
				// var month=parsed.month;
				// var userName = user.name; //kiran-4745-removing console warning-no used
				let data = {
					invoiceID: invoiceID,
					pID: pID,
					user: user,
					month: month,
					year: year,
				};
				fetch("agencyDashboard/agencyInvoiceReview", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((res) => {
						var message = "Invoice Reviewed Successfully !!!";
						Swal.fire({
							text: message,
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								window.location.reload();
							},
						});
					})
					.catch(function (err) {
						console.log(err);
					});
			}
		});
	} // End of invoiceReviewed

	render() {
		const columns1 = [
			{
				title: "Invoice ID",
				dataIndex: "invoiceID",
				key: "invoiceID",
				width: "5%",
				align: "center",
				sorter: (a, b) => a.invoiceID - b.invoiceID,
				render: (text, record) => (
					<div
					//  style={{width:'14%'}}
					>
						<span>{record.invoiceID}</span>
					</div>
				),
			},

			{
				title: "Publisher ID",
				dataIndex: "pID",
				key: "pID",
				width: "5%",
				align: "center",
				sorter: (a, b) => a.pID - b.pID,
				render: (text, record) => (
					<div style={{ width: "59px" }}>
						<span>{record.pID}</span>
					</div>
				),
			},
			{
				title: "Publisher Name",
				dataIndex: "publisherName",
				key: "publisherName",
				width: "10%",
				align: "center",
				sorter: (a, b) => {
					const asc = "asc";
					if (a.publisherName.toUpperCase() < b.publisherName.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (
						a.publisherName.toUpperCase() > b.publisherName.toUpperCase()
					) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>{record.publisherName}</span>
					</div>
				),
			},

			//         {
			//             title: 'Invoice Documents',
			//             dataIndex: 'invoiceDocumentName',
			//             key: 'invoiceDocumentName',
			//              align:'center',
			//              width:'15%',
			//              sorter: (a, b) => {
			//               const asc = 'asc';
			//               if ((a.invoiceDocumentName).toUpperCase() < (b.invoiceDocumentName).toUpperCase()) {
			//                   return asc ? -1 : 1;
			//               } else if ((a.invoiceDocumentName).toUpperCase() > (b.invoiceDocumentName).toUpperCase()) {
			//                   return asc ? 1 : -1;
			//               } else {
			//                   return 0;
			//               }
			//             },
			//              render: (text,record) =>
			//             <div class="dot" >
			//                 <span style={{fontSize:'12px'}}>
			//                 <a href="#"
			//   onClick={this.getInvoiceDocument.bind(this)} id={record.invoiceID} month={record.month} year={record.year}  pID={record.pID} type="Manual"
			//   invoiceDocumentName={record.invoiceDocumentName}  style={{fontSize:"12px"}}>{record.invoiceDocumentName}</a>
			//               </span>
			//             </div>
			//         },
			//         {
			//           title: 'Invoice Documents',
			//           dataIndex: 'invoiceDocumentName',
			//           key: 'invoiceDocumentName',
			//            align:'center',
			//            width:'15%',
			//            sorter: (a, b) => {
			//             const asc = 'asc';
			//             if ((a.invoiceDocumentName).toUpperCase() < (b.invoiceDocumentName).toUpperCase()) {
			//                 return asc ? -1 : 1;
			//             } else if ((a.invoiceDocumentName).toUpperCase() > (b.invoiceDocumentName).toUpperCase()) {
			//                 return asc ? 1 : -1;
			//             } else {
			//                 return 0;
			//             }
			//           },
			//            render: (text,record) =>
			//           <div class="dot" >
			//               <span style={{fontSize:'12px'}}>
			//               <a href="#"
			// onClick={this.getInvoiceDocument.bind(this)} id={record.invoiceID} month={record.month} year={record.year}  pID={record.pID}type="Automatic"
			// invoiceDocumentName={record.automaticInvoiceDocName}   style={{fontSize:"12px"}}>{record.automaticInvoiceDocName}</a>
			//             </span>
			//           </div>
			//       },

			{
				title: "Invoice Document",
				dataIndex: "invoiceDocumentName",
				key: "invoiceDocumentName",
				children: [
					{
						title: "Mannual",
						width: "15%",
						align: "center",
						sorter: (a, b) => {
							const asc = "asc";
							if (
								a.automaticInvoiceDocName.toUpperCase() <
								b.automaticInvoiceDocName.toUpperCase()
							) {
								return asc ? -1 : 1;
							} else if (
								a.automaticInvoiceDocName.toUpperCase() >
								b.automaticInvoiceDocName.toUpperCase()
							) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div style={{ width: "111px" }} class="dot">
								<span style={{ fontSize: "12px" }}>
								{/* kiran-4745-removing console warning-added "#/" */}
									<a
										href="#/"
										onClick={this.getInvoiceDocument.bind(this)}
										id={record.invoiceID}
										month={record.month}
										year={record.year}
										pID={record.pID}
										type="Manual"
										invoiceDocumentName={record.invoiceDocumentName}
										style={{ fontSize: "12px" }}>
										{record.invoiceDocumentName}
									</a>
								</span>
							</div>
						),
					},
					{
						title: "Automatic",
						width: "15%",
						align: "center",
						sorter: (a, b) => {
							const asc = "asc";
							if (
								a.invoiceDocumentName.toUpperCase() <
								b.invoiceDocumentName.toUpperCase()
							) {
								return asc ? -1 : 1;
							} else if (
								a.invoiceDocumentName.toUpperCase() >
								b.invoiceDocumentName.toUpperCase()
							) {
								return asc ? 1 : -1;
							} else {
								return 0;
							}
						},
						render: (text, record) => (
							<div style={{ width: "99px" }} class="dot">
								{/* kiran-4745-removing console warning-added "#/" */}
								<span style={{ fontSize: "12px" }}>
									<a
										href="#/"
										onClick={this.getInvoiceDocument.bind(this)}
										id={record.invoiceID}
										month={record.month}
										year={record.year}
										pID={record.pID}
										type="Automatic"
										invoiceDocumentName={record.automaticInvoiceDocName}
										style={{ fontSize: "12px" }}>
										{record.automaticInvoiceDocName}
									</a>
								</span>
							</div>
						),
					},
				],
			},

			{
				title: "Month",
				dataIndex: "month",
				key: "month/Year",
				width: "14%",
				align: "center",
				sorter: (a, b) => a.month - b.month,
				render: (text, record) => (
					<div style={{ width: "135px" }}>
						<span>
							{record.month}-{record.year}
						</span>
					</div>
				),
			},
			// {
			//   title: 'Year',
			//   dataIndex: 'year',
			//   key: 'year',
			//   width: '7%',
			//   align:'center',
			// sorter: (a, b) => a.year - b.year,
			// render: (text,record) =>
			// <div><span>{record.year}</span></div>
			// },
			{
				title: "Invoice Status",
				dataIndex: "status",
				key: "status",
				align: "center",
				width: "12%",
				sorter: (a, b) => {
					const asc = "asc";
					if (a.status.toUpperCase() < b.status.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (a.status.toUpperCase() > b.status.toUpperCase()) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div>
						<span style={{ fontSize: "12px" }}>
							{record.status === "AgencyInvoicePending"
								? record.automaticStatus === "AgencyReviewed"
									? record.automaticStatus
									: record.status
								: record.status}
						</span>
					</div>
				),
			},
			{
				title: "Feedback",
				dataIndex: "feedback",
				key: "feedback",
				width: "18%",
				//align:'center',
				sorter: (a, b) => {
					const asc = "asc";
					if (a.feedback.toUpperCase() < b.feedback.toUpperCase()) {
						return asc ? -1 : 1;
					} else if (a.feedback.toUpperCase() > b.feedback.toUpperCase()) {
						return asc ? 1 : -1;
					} else {
						return 0;
					}
				},
				render: (text, record) => (
					<div class="text-center">
						<div class="word-wrap">
							<span style={{ fontSize: "12px" }}>{record.feedback}</span>
							<br />
						</div>
						<span style={{ fontSize: "12px" }}>
							<label id="label">Feedback File</label>:<br />
							<div 
							// class="dot" 
							// class="word-wrap"
							className="dot word-wrap"
							>
								{/* kiran-4745-removing console warning-added "#/"  and changed classname*/}
								<a
									href="#/"
									onClick={this.downloadFeedbackFile.bind(this)}
									id={record.invoiceID}
									month={record.month}
									year={record.year}
									pID={record.pID}
									fileName={record.feedbackFIleName}>
									{record.feedbackFIleName}
								</a>
							</div>
						</span>
					</div>
				),
			},
			{
				title: "Action",
				dataIndex: "Action",
				key: "Action",
				//  width: '10%',
				align: "center",
				sorter: (a, b) => a.Action - b.Action,
				render: (text, record) => (
					<div>
						<span>
						{/* kiran-4745-removing console warning-added "#/" */}
							{record.status === "AgencyInvoicePending" &&
							record.automaticStatus === "AgencyInvoicePending" ? (
								<a
									href="#/"
									data-toggle="modal"
									data-target="#myModal"
									data-backdrop="static"
									data-keyboard="false"
									onClick={this.displayInvoicePopup.bind(this)}
									id={record.invoiceID}
									pID={record.pID}
									style={{ fontSize: "12px" }}>
									<Tooltip placement="top" title="Confirm Invoice">
									{/* kiran-4745-removing console warning-added alt="" */}
										<img
										   alt=""
											src="Review.png"
											width="28"
											height="28"
											id={record.invoiceID}
											pID={record.pID}
											month={record.month}
											year={record.year}
											onClick={this.invoiceReviewed.bind(this)}
										/>
									</Tooltip>
								</a>
							) : (
								<Tooltip
									placement="top"
									title="Invoice confirmed"
									overlayStyle={{ color: "white", backgroundColor: "orange" }}>
									<img src="Reviewdisable.png" width="28" height="28" alt="" />
								</Tooltip>
							)}
						</span>
					</div>
				),
			},
		];
		function onChange(pagination, filters, sorter) {
			console.log("params", pagination, filters, sorter);
		}

		return (
			<div>
				<Navigation />
				<div
					class="container-fluid"
					style={{ paddingTop: "85px", paddingBottom: "80px" }}>
					<div class="row">
						{/* { this.state.Step=='1'? */}
						{/* Sandeep-task-3315-added marginTop property */}
						<div
							class=" col-lg-4"
							style={{ paddingLeft: "20px", marginTop: "20px" }}>
								{/* kiran-4745-removing console warning-added "#/" */}
							<a
							    href="#/"
								onClick={(e) =>{
									e.preventDefault()
									this.props.history.push("/newdashboard", {
										agencyID: this.state.agencyID,
									})
								}} //karan-task-3684-replace query params
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back to Dashboard"
								/>
							</a>
						</div>
						{/* //shivani-3285-passed ID for DI label consistency */}
						<div
							class="col-lg-4"
							style={{ textAlign: "center", marginTop: "12px" }}>
							<label id="labelDI">Invoice Details</label>
						</div>
						<div class="col-lg-4"></div>
					</div>

					<br />
					<div
						style={{
							MozBoxShadow: "3px 3px 5px 0px #e6e6ff",
							WebkitBoxShadow: "3px 3px 5px 1px #e6e6ff",
							boxShadow: "3px 3px 5px 0px #e6e6ff",
						}}>
						<div
							style={{
								paddingLeft: "10px",
								paddingTop: "10px",
								paddingBottom: "10px",
							}}>
							<label id="label">Invoice For : </label>&nbsp;{this.state.month}
							&nbsp;{this.state.year}
						</div>
					</div>

					<br />
					<p>
						{" "}
						<Table
							{...this.state.tableState}
							id="myTable"
							bordered
							dataSource={this.state.invoiceDetail}
							loading={this.state.loading1}
							onChange={onChange}
							columns={columns1}
							// className="ant-table-thead"
							// className={tableCSS}
							className={`${tableCSS} "ant-table-thead"`}
							scroll={{ y: 800 }}
							pagination={{ pageSize: this.state.pageSize1 }}></Table>
					</p>
					{/* kiran-4745-removing console warning-changed classname */}
				</div>

				{/* <div class="modal" id="myModal" style={{paddingTop:'3%'}}>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header custom-class">
          <h4 class="modal-title"  style={{color:'white',fontSize:'20px',paddingRight:216}}>Confirm (Invoice ID:{this.state.invoiceID})</h4>
          <button type="button" class="cancel-button" data-dismiss="modal" style={{fontSize:15}} onClick={this.handleRefreshPage}>&times;</button>
        </div>
        <div class="modal-body" style={{color:"#2196f3"}}>
        <label id="label">This confirm your invoice review is done and ready to send invoice to Publisher. Are you sure to send invoice to Publisher?</label>
        </div>
       <div class="modal-footer">
       <div>
      <button type="button" class="btn add-button" id={this.state.invoiceID} name={this.state.pID} onClick={this.invoiceReviewed.bind(this)}>Yes</button>
      <button type="button" class="btn add-button"  data-dismiss="modal">No</button></div>
      

        
        
        </div>
        
      </div>
    </div>
  </div> */}

				<Footer />
			</div>
		);
	}
} //end of class AgencyInvoice
AgencyInvoice.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(AgencyInvoice)
);
