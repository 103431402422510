/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*@author Shivani Pathak
*@fileName : agencyMyReports.js
@Creation Date:06-04-2021
 *Desc: 
*/
import React from "react";
import { Tabs, Tab } from 'react-bootstrap';
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import './agencyMyReports.css';
import { css } from "emotion";
//import $ from 'jquery';//sunita-task-3406-added for jquery //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import { Table, Tooltip } from 'antd';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";        //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
import { saveAs } from "file-saver"; //rutuja -task 4497 added for downloading report
const xlsx = require("xlsx"); //rutuja -task 4497 added for downloading report
var dateTime = require("node-datetime"); //rutuja -task 4497 added for taking current date
const Swal = require("sweetalert2");

const tableCSS = css({
  backgroundColor: "white",
  fontFamily: "sans-serif",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
    color: "white",
    fontSize: "13px",
    textAlign: "center !important",
    fontWeight: "500",
  },
  "& thead > tr >th": {
    textAlign: "center !important",
    color: "white",
    fontSize: "12px",
  },
});

//const queryString = require('query-string'); //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
/**
* @author : Shivani Pathak-task 3354
* @description : created class component named as AgencyMyReports .
*/
class AgencyMyReports extends React.Component {
  constructor() {
    super();
    this.state = {
      key: "1", //shivani-3354- used for first tab
      errors: {}, // shivani-3354-used for validations
      tableState: { size: "small" }, //shivani-3354-used for create -edit popUP design
      agencyMyReportsData: [], // shivani-3354-this array used for table data.
      agencyID: '', // shivani-3354-declare to used from userLogin
      tableSearchText: '',//shivani-3354-parameters used for table search .
      agencyMyReportSearch: [], //shivani-3354- array used for table data search
      pageSize: 100, //shivani-3354- used for pagination.
      filteragencyMyReportsData: [], //shivani-3354-used for filter data.
      agencyMyReportsDataNew1: [],///shivani-3354-used for filter data.
      // type: "Publisher Campaign Delivery Tracking",//shivani-3354-used for by default type in popUP.
      type: "Publisher Campaign Delivery Tracking",//shivani-3354-used for by default type in popUP.
      advtype: 'Advertiser Campaign Delivery Tracking',//sunita-task-3406-used for by default type in popUP for advertiser.
      // type :"",//shivani-3354-used for by default type in popUP.
      // advtype:"",
      myReportKey: "report", // shivani-3354- used for unique-ness passed this key from url
      pID: "", //shivani-3354-declare this to access pID
      reportNameValue: "",//shivani-3354- to target create report name value.
      reportNameValueEdit: "",//shivani-3354- to target edit report name value.
      reportID: "", //shivani-3354-passed this ID to api data.
      reportKey: "",//shivani-3354-passed from url.
      reportName: "",//shivani-3354-passed this ID to api data.
      label: "Publisher Campaign Delivery Tracking",//shivani-3354-to filter data as per label.
      label1: "Advertiser Campaign Delivery Tracking",//sunita-task-3406-to filter data as per label.
      reportid: "",//shivani-3354-passed this ID to api data.
      token: "",//shivani-3354-passed this token for authentication.
      // userType:"Publisher",
      userType: "", //sunita-task-3406-added for user type.
      loader2: "",//shivani-3354-used for loader.
      loaderadv: "",//sunita-task-3406-used for advertiser loader.
      reportadvid: "",//sunita-task-3406-passed this ID to api data.
      advertiserID: '',//sunita-task-3406-used for advertiserid.
      reportadvname: '',//sunita-task-3406-passed this ID to api data.
      // userTypeAdv:"Advertiser",

    }
    /**
* @author : Shivani Pathak-task 3354
* @description : binded all handlechange here .
*/
    this.createReport = this.createReport.bind(this);
    this.editReport = this.editReport.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);

    this.handleChangePageSize = this.handleChangePageSize.bind(this);
    this.reportType = this.reportType.bind(this);
    this.reportNameHandleChange = this.reportNameHandleChange.bind(this);
    this.reportNameHandleChangeEdit = this.reportNameHandleChangeEdit.bind(this);
    this.handleChangeForTableData = this.handleChangeForTableData.bind(this);
    this.handleSelect = this.handleSelect.bind(this);//sunita-task-3406-added handle change for changing the tab.
    this.handleTracking = this.handleTracking.bind(this);//sunita-task-3406-added handle change for handling the report type
    this.handleRefreshPage = this.handleRefreshPage.bind(this);
    //shivani -tasl 3415 - added handlechange to change report type ...
    this.reportTypeForLead = this.reportTypeForLead.bind(this);
    this.buttonChange = this.buttonChange.bind(this);
    this.closePopup = this.closePopup.bind(this);//shivani -task 3413 - added handlechange to change report type ..
    this.handleOnClickReport=this.handleOnClickReport.bind(this); //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
    this.deleteReport = this.deleteReport.bind(this); //rutuja task-4496 added handlechange to delete report
    this.downloadReport = this.downloadReport.bind(this); //rutuja task-4497 added handlechange to download report

  }
  //shivani -task 3413 - added handlechange to change report type ..
  closePopup() {
    this.setState({ reportNameValue: '' })

  }

  /**
 * @author : Sunita Landge-task 3406
 * @description : handlechange for changing the report type .
 */

  handleTracking(e) {
    e.preventDefault();
    let select_Ag = e.target.value;

    let index = e.nativeEvent.target.selectedIndex;
   // let type = e.nativeEvent.target[index].text; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    this.setState({ select_Ag: select_Ag, type: e.nativeEvent.target[index].text });
  }
  /**
* @author : shivani pathak task 3415
* @description : handlechange for changing the report type .
*/
  buttonChange(e) {
    e.preventDefault();
    //let reportadvname; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2

    // sunita-task-3413-added below code as per key condition
    if (this.state.key === "1" || this.state.key === 1) {

      this.setState({ reportName: '' })

    }
    else {
      this.setState({ reportadvname: '', advtype: '' })
    }
  }

  /**
   * @author : Sunita Landge-task 3406
   * @description : handle change for changing the tabs.
   */
  //   handleSelect(key) {  
  //    this.setState({ key ,tableSearchText:"" ,label : "Publisher Campaign Delivery Tracking",label1:"Advertiser Campaign Delivery Tracking"});
  //   // alert("key===>"+key)
  //     const { user } = this.props.auth;
  //     var parsed = queryString.parse(this.props.location.search);
  //     var agencyID = user.id;
  //     const token = user.token;
  //   //  alert("type==>"+typeof(key))
  //     let data ;
  //       if(key === "1" || key === 1 && key!=="undefined" ||key !== undefined){ //sunita-task-3459-added code for handling the tab which u want to open as per key.
  //       data = {
  //         agencyID: agencyID,
  //         userType: "Publisher",
  //         key: key
  //       }
  //     }
  //     else {
  //       data= {
  //         agencyID: agencyID,
  //         userType: "Advertiser",
  //         key: key
  //       }
  //     }
  // // alert("data===>"+JSON.stringify(data))   
  //     if (key == 1 ) {
  //       // alert("key 1===>"+typeof(key))
  //       fetch("/reportEngine/getMyReport", {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           authorization: token,
  //         },
  //         body: JSON.stringify(data)
  //       }).then(res => res.json())
  //         .then(agencyMyReportsData => {     
  //           this.setState({ agencyMyReportsData, agencyMyReportsDataNew1: agencyMyReportsData }
  //             ,function(){
  //               let type = this.state.type;
  //               // alert("publ type==>"+type)
  //               // alert("FFF key ===>"+typeof(key)+"==="+key)

  //               if(type === undefined || type === "undefined" ){
  //                 // alert("type in if==>"+type)
  //                type = "Publisher Campaign Delivery Tracking"
  //               }

  //               let agencyMyReportsData = [...this.state.agencyMyReportsDataNew1]
  //               if (this.state.key === 1 || this.state.key ==="1") {
  //               // alert("FFF key ===>"+typeof(key)+"==="+key)

  //                 agencyMyReportsData = agencyMyReportsData.filter((a) => {
  //                   return a.type == type
  //                 })
  //                 // alert("before filter setState tableSearchText===>"+this.state.tableSearchText)


  //                 this.setState({agencyMyReportsData})
  //               }
  // //shivani - 3565 - filtered table report type ..  
  //             })
  //         }
  //         ).catch(function (err) {
  //           console.log(err)
  //         });
  //     }
  //     else { 
  //       // alert("in else adv")  
  //       this.setState({ loaderadv: "loader_reportadv" });
  //       fetch("/reportEngine/getMyReport", {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           authorization: token,
  //         },
  //         body: JSON.stringify(data)
  //       }).then(res => res.json())
  //         .then(agencyMyReportsData => {
  //           // alert("for adv data ===>"+JSON.stringify(agencyMyReportsData))
  //           this.setState({ agencyMyReportsData, agencyMyReportsDataNew1: agencyMyReportsData, loaderadv: ""}
  //           ,function(){
  //              //sunita-task-3571- added code for report type according to filter.
  //             let advtype = this.state.advtype;
  //             // alert("advertiser type==>"+advtype)
  //             if(advtype === undefined || advtype === "undefined" ){
  //              advtype = "Advertiser Campaign Delivery Tracking"
  //             }
  //             let agencyMyReportsData = [...this.state.agencyMyReportsDataNew1]
  //               // if (this.state.key === 1) {
  //                 agencyMyReportsData = agencyMyReportsData.filter((a) => {
  //                   return a.type == advtype
  //                 })

  //                 // alert("before filter setState tableSearchText===>"+this.state.tableSearchText)
  //                 this.setState({agencyMyReportsData })
  //               // }
  //           }
  //          )
  //         }
  //         ).catch(function (err) {
  //           console.log(err)
  //         });

  //     }

  //   }
    /**
* @author : Shivani Pathak-task 3583 ( tab change issue pub and adv tab (for filter data , report type,serach box , label issue) )
* @description : handlechange to for report type .
*/
  handleSelect(key) {
    
    this.setState({ key, tableSearchText: "", agencyMyReportSearch: [], label: "Publisher Campaign Delivery Tracking", label1: "Advertiser Campaign Delivery Tracking" });
    //const { user } = this.props.auth; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    // var parsed = queryString.parse(this.props.location.search);
    //var agencyID = user.id; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    //const token = user.token;             //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
    let data;
    if ((key === "1" || key === 1) && (key !== "undefined" || key !== undefined)) { //sunita-task-3459-added code for handling the tab which u want to open as per key. //Aman-4797-(Unexpected mix of '||' and '&&')-Console Warnings-Agency-Reports Part 2
      data = {
        //agencyID: agencyID,                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        userType: "Publisher",
        key: key
      }
    }
    else {
      data = {
        //agencyID: agencyID,                  //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        userType: "Advertiser",
        key: key
      }
    }

    if (key === 1 || key === "1") {

      fetch("/reportEngine/getMyReport", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(agencyMyReportsData => {

          this.setState({ agencyMyReportsData, agencyMyReportsDataNew1: agencyMyReportsData }
            , function () {

              var type = "Publisher Campaign Delivery Tracking"

              agencyMyReportsData = agencyMyReportsData.filter((a) => {
                return a.type === type
              })
              this.setState({ agencyMyReportsData })

              // }
              //shivani - 3565 - filtered table report type ..  
            })
        }
        ).catch(function (err) {
          console.log(err)
        });
    }
    else {

      this.setState({ loaderadv: "loader_reportadv" });
      fetch("/reportEngine/getMyReport", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
         
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(agencyMyReportsData => {
                     
          this.setState({ agencyMyReportsData, agencyMyReportsDataNew1: agencyMyReportsData, loaderadv: "" }
            , function () {
              //sunita-task-3571- added code for report type according to filter.

              var advtype = "Advertiser Campaign Delivery Tracking"

              // if (this.state.key === 1) {
              agencyMyReportsData = agencyMyReportsData.filter((a) => {
                return a.type === advtype //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
              })

              this.setState({ agencyMyReportsData })
              // }
            }
          )
        }
        ).catch(function (err) {
          console.log(err)
        });

    }

  }


  /**
 * @author : Shivani Pathak-task 3354
 * @description : handlechange to enter reportName .
 */
  reportNameHandleChangeEdit(e) {
    e.preventDefault();
    let reportNameValueEdit = e.target.value;
    this.setState({ reportNameValueEdit, reportadvname: reportNameValueEdit, reportNameEdit : ""})//sunita-task-3406-setstate the value as per requirement.//this.state.errors.reportNameEdit = "";//shivani 3434- to make error message blank //Aman-4797-(Used setState())-Console Warnings-Agency-Reports Part 2
  }
  reportNameHandleChange(e) {
    e.preventDefault();
    //const { name, value } = e.target //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2 
    let reportNameValue = e.target.value;
    this.setState({ reportNameValue, reportName : ""})//this.state.errors.reportName = ""; //shivani 3434- to make error message blank //Aman-4797-(Used setState())-Console Warnings-Agency-Reports Part 2
  }

  /**
 * @author : shivani Pathak 3415
 * @description : handlechange for changing the report type .
 */

  reportTypeForLead(e) {
    e.preventDefault();
    //const { value } = e.target; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2

    var type, advtype;
    // sunita-task-3413-added below code as per key condition
    if (this.state.key === 1 || this.state.key === "1") {
      type = e.target.value;
    }
    else {
      type = e.target.value;                            //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
    }
    this.setState({ type, advtype });
    
  }

  /**
* @author : Shivani Pathak-task 3354
* @description : handlechange to refresh page on cancel button .
*/
  handleRefreshPage(e) {
    //window.location.href = "agencyMyReports";
    this.props.history.push("/agencyMyReports")         //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
  } //end of handleRefreshPage


  /**
  * @author : Shivani Pathak-task 3354
  * @description : handlechange to change report type filter .
  */
  // reportType(e) { 
  //  alert("in report type")
  //   //sunita-task-3406-added below conditions as per tab key it will show the type and the label.

  //   var type, advtype;
  //   if (this.state.key === 1 || this.state.key==="1") {
  //     type = e.target.value;
  //   }
  //   else {
  //     advtype = e.target.value;
  //   }  

  //   let index = e.nativeEvent.target.selectedIndex;
  //   let label, label1;
  //   if (this.state.key === 1 || this.state.key === "1") {
  //     label = e.nativeEvent.target[index].text;

  //   }
  //   else {
  //     label1 = e.nativeEvent.target[index].text;
  //   }

  //   let agencyMyReportsData = [...this.state.agencyMyReportsDataNew1]
  //   alert("type===>"+type)
  //   // if(type === undefined || type === "undefined" ){
  //   //   alert("type in if==>"+type)
  //   //  type = "Publisher Campaign Delivery Tracking"
  //   // }
  //   if (this.state.key === 1 || this.state.key === "1") {
  //     alert("in if")
  //     alert("type===>"+type)

  //     agencyMyReportsData = agencyMyReportsData.filter((a) => {
  //       return a.type == type
  //     })
  //    alert("data===>"+JSON.stringify(agencyMyReportsData))
  //   }

  //   else {     
  //     alert("in else")
  //     // if(advtype === undefined || advtype === "undefined" ){
  //     //   alert("advtype in if==>"+advtype)
  //     //  advtype = "Advertiser Campaign Delivery Tracking"
  //     // }
  //     agencyMyReportsData = agencyMyReportsData.filter((a) => {
  //       return a.type == advtype  // sunita-task-3413-added code fpr showing report type
  //     })
  //    alert("data===>"+JSON.stringify(agencyMyReportsData))

  //     // this.setState({agencyMyReportsData})
  //     alert("type===>"+advtype)

  //   }
  //   document.getElementById("searchID4").value='';
  //   document.getElementById("searchID42").value='';


  //   this.setState({ agencyMyReportsData,type,advtype,label:e.nativeEvent.target[index].text,label1: e.nativeEvent.target[index].text}


  //     )//sunita-task-3406-setstate the data as per requirement
  //     console.log("data===>"+JSON.stringify(this.state.agencyMyReportsData))
  // }
   /**
* @author : Shivani Pathak-task 3576 ( for serach box and table report type issue)
* @description : handlechange to for report type .
*/
  reportType(e) {
    e.preventDefault();
    //sunita-task-3406-added below conditions as per tab key it will show the type and the label.

    var type;
    let index = e.nativeEvent.target.selectedIndex;
    let label, label1;
    let agencyMyReportsData1 = [];
    let agencyMyReportsData = [...this.state.agencyMyReportsDataNew1]
    type = e.target.value;

    if (this.state.key === 1 || this.state.key === "1") {


      label = e.nativeEvent.target[index].text;

      if (type === undefined || type === "undefined") {
        type = "Publisher Campaign Delivery Tracking"
      }

    }
    else {


      label1 = e.nativeEvent.target[index].text;

      if (type === undefined || type === "undefined") {
        type = "Advertiser Campaign Delivery Tracking"
      }

    }

    agencyMyReportsData1 = agencyMyReportsData.filter((a) => {
      return a.type === type
    })


    document.getElementById("searchID4").value = '';
    document.getElementById("searchID42").value = '';


    this.setState({ agencyMyReportsData: agencyMyReportsData1, agencyMyReportSearch: agencyMyReportsData1, type, advtype: type, label: label, label1: label1} //Aman-4797-(replace this e.nativeEvent.target[index].text with label/label1)-Console Warnings-Agency-Reports Part 2
    )//sunita-task-3406-setstate the data as per requirement
  }
  /**
* @author : Shivani Pathak-task 3354
* @description : handlechange to create report .
*/
 //Chaitanya-4099-Back button required on My reports screen - Agency //
  myReportBackButton(e) 
  {
  e.preventDefault();
  this.props.history.push("/newdashboard")
  }//handel change for back button

  createReport(e) {
    e.preventDefault();                          //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
    //  debugger
    const { user } = this.props.auth;
    const agencyID = user.id;
    var pID = this.state.pID;
    var advertiserID = this.state.advertiserID;//sunita-task-3406-added for advertiser id
    //const { name, value } = e.target //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    let formIsValid = true;
    let reportNameValidate = true;
    let errors = {};
    let NameR = this.state.reportNameValue;
   // let key = this.state.key;//sunita-task-3406-added for tab key //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    let type = this.state.type;
    let advtype = this.state.advtype;
    this.setState({ agencyID, pID, NameR, advertiserID, type, advtype })


    //sunita-task-3406- below validations are handled as per tab key
    // if (key === 1) {

    //shivani - 3581 - handled create popUp validation and links
    if (NameR === "" || NameR === undefined || NameR == null) {//Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
      errors["reportName"] = <li>Please enter report name</li>;
      reportNameValidate = false;
      // formIsValid = false ;
      // return 
      this.setState({ errors: errors });


    }
    else {

      // shivani-task-3434-Replace RegEx for new requirement (should reject only numeric value,should reject only symbolic value and report name have at least one alphabets)
      var pattern = RegExp(/^(?=.*?[A-Za-z]).+/)
      // eslint-disable-next-line no-useless-escape
      var pattern2 = new RegExp(/^[_()\/+&@\-\. ]$/);
      if (!pattern.test(NameR) || pattern2.test(NameR)) {
        errors["reportName"] = <li>Please enter valid report name</li>;
        reportNameValidate = false;
        // formIsValid = false ;
        // return 
        this.setState({ errors: errors });
      }
      else {

        errors["reportName"] = "";
        reportNameValidate = true;
        this.setState({ errors: errors });

      }

    }
    if (reportNameValidate === false) { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2

      formIsValid = false;
      this.setState({
        errors: errors,
      });

    }
    else {

      formIsValid = true;
      //shivani - 3565 - Added all linkes for report type from create popUP and for both the tabs  ..
      
      let type=this.state.type;
      // 3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
      if (type === "Publisher Lead Tracking") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
        this.props.history.push("/agencyPublisherLeadtracking", {
          type,
          reportName:this.state.reportNameValue,
        }); 
       }
      else if (type === "Publisher Campaign Delivery Tracking") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
         this.props.history.push("/agencyPublisherDeliverytracking", {
          type,
          reportName:this.state.reportNameValue,
        });
      }

      else if (type === "Advertiser Lead Tracking") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
        this.props.history.push("/agencyAdvertiserLeadTracking", {
          type,
          reportName:this.state.reportNameValue,
        });
       }
      else if (type === "Advertiser Campaign Delivery Tracking") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
        this.props.history.push("/agencyAdvertiserDeliverytracking", {
          type,
          reportName:this.state.reportNameValue,
        });
       }

      // 3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE

      else {
        if (advtype === "Publisher Lead Tracking") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
          this.props.history.push("/agencyPublisherLeadtracking", {
            advtype,
            reportName:this.state.reportNameValue,
          });
         }
        else if (advtype === "Publisher Campaign Delivery Tracking") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
          this.props.history.push("/agencyPublisherDeliverytracking", {
            advtype,
            reportName:this.state.reportNameValue,
          });
        }

        else if (advtype === "Advertiser Lead Tracking") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
          this.props.history.push("/agencyAdvertiserLeadtracking", {
            advtype,
            reportName:this.state.reportNameValue,
          });
         }
        else if (advtype === "Advertiser Campaign Delivery Tracking") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
          this.props.history.push("/agencyAdvertiserDeliverytracking", {
            advtype,
            reportName:this.state.reportNameValue,
          });
         }
      }
    }
    return formIsValid;


    //sunita-task-3406-added below validations for advertiser tab
    // else {


    // if (NameR == "" || NameR == undefined || NameR == null) {

    //   errors["reportName"] = <li>Please enter report name</li>;
    //   reportNameValidate = false;
    //   this.setState({ errors: errors });

    // }
    // else {

    //   var pattern = RegExp(/^(?=.*?[A-Za-z]).+/)
    //   var pattern2 = new RegExp(/^[_()\/+&@\-\. ]$/);
    //   if (!pattern.test(NameR) || pattern2.test(NameR)) {
    //     errors["reportName"] = <li>Please enter valid report name</li>;
    //     reportNameValidate = false;
    //     this.setState({ errors: errors });
    //   }
    //   else {

    //     errors["reportName"] = "";
    //     reportNameValidate = true;
    //     this.setState({ errors: errors });

    //   }

    // }
    // if (reportNameValidate == false) {
    //   formIsValid = false;
    //   this.setState({
    //     errors: errors,
    //   });
    // }
    // else {
    //   formIsValid = true;

    // }
    // return formIsValid;
    // }
  }
  /**
  * @author : Shivani Pathak-task 3354
  * @description : handlechange to edit report.
  */
  editReport(e) {
    e.preventDefault();
    //shivani - 3581 - handled edit popUp validations . 
    const { user } = this.props.auth;
    let formIsValid = true;
    let EditreportNameValidate = true;
    let errors = {};
    // let NameReEdit = this.state.reportNameValueEdit;
    //sunita-task-3406-added below condition as per tab key.
    let NameReEdit;
    if (this.state.key === 1 || this.state.key === "1") {
      NameReEdit = this.state.reportNameValueEdit;
    } else {
      NameReEdit = this.state.reportadvname;
    }
    let reportID = this.state.reportID;
    const agencyID = user.id;
    var reportName = NameReEdit;
    var type = this.state.type;
    var advtype = this.state.advtype; //sunita-task-3406-added for advertiser type
    let data1;
    //sunita-task-3406-passed below data to BE as per tab key.

    if (this.state.key === 1 || this.state.key === "1") {
      data1 = {
        //agencyID: agencyID,                     // 3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        reportID: reportID,
        reportName: reportName,
        type: type,
      }

    }
    else {
      data1 = {
        //agencyID: agencyID,                       // 3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        reportID: reportID,
        reportName: reportName,
        type: advtype
      }
    }

    this.setState({ reportName, agencyID, type, advtype, reportID })

    //sunita-task-3406- below validations are handled as per tab key
    if (this.state.key === 1 || this.state.key === "1") {
      if (NameReEdit === "" || NameReEdit === undefined || NameReEdit == null) { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
        errors["reportNameEdit"] = <li>Please enter report name</li>;
        EditreportNameValidate = false;
        this.setState({ errors: errors });
      }
      else {
        // shivani-task-3434-Replace RegEx for new requirement (should reject only numeric value,should reject only symbolic value and report name have at least one alphabets)   
        var pattern = RegExp(/^(?=.*?[A-Za-z]).+/)
        // eslint-disable-next-line no-useless-escape
        let pattern2 = new RegExp(/^[_()\/+&@\-\. ]$/); //Aman-4797-(change var to let to resolve duplicate var issue)-Console Warnings-Agency-Reports Part 2
        if (!pattern.test(NameReEdit) || pattern2.test(NameReEdit)) {
          errors["reportNameEdit"] = <li>Please enter valid report name</li>;
          EditreportNameValidate = false;
          this.setState({ errors: errors });
        }
        else {
          fetch("/reportEngine/updateReportName", {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data1)
          }).then(res => res.json())
            .then(res => {
              if (res.success === true) {
       
                
                Swal.fire({
                  text: "Report name edited successfully.",
                  type: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: true,
                  preConfirm: () => {
                    this.handleChangeForTableData();
                    window.location.reload();
                  },
                });
              }
            }
            ).catch(function (err) {
              console.log(err)
            });
        }
      }
      if (EditreportNameValidate === false) { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
        formIsValid = false;
        this.setState({
          errors: errors,
        });

      }
      else {
        formIsValid = true;
      }
      return formIsValid;
    }
    //sunita-task-3406-added below validations for advertiser tab
    else {
      if (NameReEdit === "" || NameReEdit === undefined || NameReEdit == null) { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
        errors["reportNameEdit"] = <li>Please enter report name</li>;
        EditreportNameValidate = false;
        this.setState({ errors: errors });
      }
      else {
        let pattern = RegExp(/^(?=.*?[A-Za-z]).+/) //Aman-4797-(change var to let to resolve duplicate var issue)-Console Warnings-Agency-Reports Part 2
        // eslint-disable-next-line no-useless-escape
        var pattern2 = new RegExp(/^[_()\/+&@\-\. ]$/);
        if (!pattern.test(NameReEdit) || pattern2.test(NameReEdit)) {
          errors["reportNameEdit"] = <li>Please enter valid report name</li>;
          EditreportNameValidate = false;
          this.setState({ errors: errors });
        }
        else {
          fetch("/reportEngine/updateReportName", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data1)
          }).then(res => res.json())
            .then(res => {
              if (res.success === true) {
                Swal.fire({
                  text: "Report name edited successfully.",
                  type: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: true,

                  preConfirm: () => {
                    // $("#edit_report").modal('hide');//as removed this used jquery giving once we edit then after that it will freeze the page.
                    this.handleChangeForTableData();
                    window.location.reload();
                    // $("#edit_report").modal('hide');

                    // $("#edit_report").modal('show');
                    // window.location.href="#";

                    // window.location.href="#?handleOnkey=2";


                  },
                });


              }

            }
            ).catch(function (err) {
              console.log(err)
            });

        }
      }
      if (EditreportNameValidate === false) { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
        formIsValid = false;
        this.setState({
          errors: errors,
        });

      }
      else {
        formIsValid = true;
      }
      return formIsValid;
    }
  }
  /**
  * @author : Shivani Pathak-task 3354
  * @description : handlechange for pageSize .
  */
  handleChangePageSize(e) {
    var pageSize = e.target.value;
    this.setState({ pageSize: pageSize });
  }
  editReportName(e) {
    var reportID, reportadvname, reportNameValueEdit, type, advtype;//sunita-task-3406 and 3413-added reportadvname,advtype for advertiserreport name
    //  var reportID=e.target.getAttribute('reportid');
    //sunita-task-3406-added below code for showing data as per tab key.
    if (this.state.key === 1 || this.state.key === "1") {
      reportID = e.target.getAttribute('reportid');
      // 3415 -added type and report name value on edit popUp 
      reportNameValueEdit = e.target.getAttribute('reportNameValueEdit');
      type = e.target.getAttribute('type')

    }
    else {
      //sunita-task-3413-added as per report type
      reportID = e.target.getAttribute('reportadvid');
      reportadvname = e.target.getAttribute('reportadvname');
      advtype = e.target.getAttribute('advtype');

    }

    this.setState({ reportID, reportadvname, reportNameValueEdit, type, advtype })


  }
  /**
  * @author : Shivani Pathak-task 3354
  * @description : handlechange to search data in table .
  */
  handleChangeSearch(e) {
    e.preventDefault();

    let currentList = [];
    let newList = [];
    let filterData = e.target.value;
    if (e.target.value === "") {
      newList = this.state.agencyMyReportsData;
    }
    else {
      currentList = this.state.agencyMyReportsData;
      newList = currentList.filter(item => {
        let obj={};//Somnath Task-3972, Add required comumn in search fields
        obj[`reportID`]=item.reportID;
        obj[`reportName`]=item.reportName;
        obj[`createdBy`]=item.createdBy;
        obj[`created`]=item.created;
        obj[`lastUpdated`]=item.lastUpdated;
        const lc = obj;
        const filter = e.target.value.toLowerCase();
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });

    }
    this.setState({
      agencyMyReportSearch: [...newList], tableSearchText: filterData
    });

  }
  
  handleChangeForTableData() {

    const { user } = this.props.auth;
    //var parsed = queryString.parse(this.props.location.search); //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    const agencyID = user.id;
    
    var reportKey = this.state.myReportKey;
    let userType = this.state.userType;//sunita-task-3406-aded code for usertype


    //let key = parsed.handleOnkey;//sunita-task-3459-added this key for coming back to my report page from tracking page
    let key;
    if (this.props.location.state !== undefined)
    {
      key = this.props.location.state.handleOnkey;                    //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
    }
  

    //sunita-task-3459-added code for handling the tab which u want to open as per key.
    if (key === undefined || key === "undefined") { //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2


      key = this.state.key;

    }
   

    this.setState({ reportKey, agencyID, userType, loader2: "loader_report1", key, loaderadv: "loader_reportadv" })//sunita-task-3406-setstate the values as per requirement

    //sunita-task-3406-added below code conditions as per tab key
    let data;
    //sunita-task-3459-added code for handling the tab which u want to open as per key.
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    if (key === 2 || key === "2")
    //sunita-task-3459-sent the data to BE as per key 
    {
     
      data = {
        //agencyID: agencyID,                                 //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        userType: "Advertiser",
        key: key
      }

    }
    else {

      data = {
        //agencyID: agencyID,                                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        userType: "Publisher",
        key: key
      }

    }

    fetch("/reportEngine/getMyReport", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

      },
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(agencyMyReportsData => {
        

        // let pID = agencyMyReportsData[0].pID;
        // let reportID = agencyMyReportsData[0].reportID;
        // let advertiserID = agencyMyReportsData[0].advertiserID;
        // this.setState({ agencyMyReportsData, agencyMyReportsDataNew1: agencyMyReportsData, pID, reportID, advertiserID,loader2:"",loaderadv:""})
        // let type = agencyMyReportsData[0].type;


        this.setState({ agencyMyReportsData, agencyMyReportsDataNew1: agencyMyReportsData, loader2: "", loaderadv: "", key }
          , function () {
            let type = this.state.type;

            let agencyMyReportsData = [...this.state.agencyMyReportsDataNew1]
            if (this.state.key === 1 || this.state.key === "1") {

              agencyMyReportsData = agencyMyReportsData.filter((a) => {
                return a.type === type //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
              })

              this.setState({ agencyMyReportsData })
            }

            //shivani - 3565 - filtered table report type ..



            //sunita-task-3571- added code for report type according to filter.

            else {

              let advtype = this.state.advtype;
              

              agencyMyReportsData = agencyMyReportsData.filter((a) => {
                return a.type === advtype //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
              })
             
              this.setState({ agencyMyReportsData })
            }
          })
      }

      ).catch(function (err) {
        console.log(err)
      });

  }
 /**
* @author : Rutuja Jagtap-task 4496
* @description :  Added api in this function for deleting Report.
*/

deleteReport(e) {
 
  //const { user } = this.props.auth; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
  var reportID = e.target.getAttribute('reportID')
  var type = this.state.type;
  var advtype = this.state.advtype; 
   let data;

    if (this.state.key === 1 || this.state.key === "1") {
      data = {
        reportID: reportID,
        type: type,
      }

    }
    else {
      data = {
         reportID: reportID,
        type: advtype
      }
    }
  
   fetch("/reportEngine/deleteReport", {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
    .then(res => {
      if (res.success === true) {

        
        Swal.fire({
          text: "Report Deleted successfully.",
          type: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: true,
          preConfirm: () => {
           
            window.location.reload();
          },
        });
      }
      else{
        Swal.fire({
          text: "Something went wrong while deleting report",
          type: "Failure",
          confirmButtonText: "Ok",
          allowOutsideClick: true,
          preConfirm: () => {
           
            window.location.reload();
          },
        });

      }
    }
    ).catch(function (err) {
      console.log(err)
    });

}

/**
* @author : Rutuja Jagtap-task 4497
* @description :  Added api in this function for downoading Report.
*/
downloadReport(e) {
   var reportID = e.target.getAttribute('reportID')
  let reportName = e.target.getAttribute('reportName')
 
  var dt = dateTime.create();
    var curdate = dt.format("Y-m-d_H:M:S");
  let data = {
    reportID: reportID,
  }
  fetch("/reportEngine/reportDetail", {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
  .then(result => {
    
    this.setState({ agencyMyReportCampaignInfo: result.reportDetail })
var agencyMyReportCampaignInfo = this.state.agencyMyReportCampaignInfo;
    if (agencyMyReportCampaignInfo.length > 0) {
      var ws = xlsx.utils.json_to_sheet(agencyMyReportCampaignInfo);
      
      var wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "Lead");
      var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
      function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (let i = 0; i !== s.length; ++i)
              view[i] = s.charCodeAt(i) & 0xff;
          return buf;
      }
 
     
      var fileName = reportID  + "_" + reportName  + "_" + curdate +".xlsx";
      saveAs(
          new Blob([s2ab(buf)], { type: "application/octet-stream" }),
          fileName
      );
      Swal.fire({
        html: "Your Report has been successfully Downloaded!",
        type: "success",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
    });
            } else {
              Swal.fire({
                html: "This report data Does not exists!",
                type: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
            });
            }


          }).catch(function (err) { console.log(err) });
          }

 
  /**
* @author : Shivani Pathak-task 3354
* @description :  Added api in this function for table data.
*/
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
      
      this.handleChangeForTableData();//sunita-task-3406-function called for showing table data.
    }

  }

  handleOnClickReport(e){                              //3721-Rutuja Kale-VAPT-new function for read only report-Agency side--Reports menu--URL Links & API-FE
        e.preventDefault();
        var reportType=e.target.getAttribute("reportType");
        var reportID=e.target.getAttribute("reportID");
        var reportKey=e.target.getAttribute("reportKey");
        var pID=e.target.getAttribute("pID");
        var advID=e.target.getAttribute("advID");
        var reportName = e.target.getAttribute("reportName");
   
   


        if(reportType==="Publisher Lead Tracking"){
          this.props.history.push('/agencyPublisherLeadtracking',{pID:pID,reportID:reportID,reportKey:reportKey,reportName:reportName});
        }
        else if(reportType==="Advertiser Lead Tracking"){
          this.props.history.push('/agencyAdvertiserLeadTracking',{advID:advID,reportID:reportID,reportKey:reportKey,reportName:reportName});
        }
        else if(reportType==="Publisher Campaign Delivery Tracking"){
          this.props.history.push('/agencyPublisherDeliverytracking',{pID:pID,reportID:reportID,reportKey:reportKey,reportName:reportName});
        }
        else if(reportType==="Advertiser Campaign Delivery Tracking"){
          this.props.history.push('/agencyAdvertiserDeliverytracking',{advID:advID,reportID:reportID,reportKey:reportKey,reportName:reportName});
        }



  }
  /**
  * @author : Shivani Pathak-task 3354
  * @description :Render part to display complete screen with functionality as per given screen .
  */
  render() {
    const asterisk =
    {
      color: 'red',
    }

    const columns1 = [
      {

        title: <span>Report ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'reportID',
        key: 'reportID',
        width: 50,
        align: 'left',
        // class:"fas fa-sort",
        // <i class="fas fa-sort"></i>
        sorter: (a, b) => a.reportID - b.reportID,
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.reportID}
            </span>

          </div>
      },
      {
        title: <span>Report Name&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'reportName',
        key: 'reportName',
        width: 90,
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.reportName.toUpperCase() < b.reportName.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.reportName.toUpperCase() > b.reportName.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }

        },
        render: (text, record) =>
          //sunita-task-3406-added below code as per tab key for rendering to tracking page
          this.state.key === 1 || this.state.key === "1" ?
            <div id="to_truncate_new1" title={record.reportName} > {/*chaitanya-4187-Agency-My reports- Report title not showing full name on report list page*/}
            {/*shivani - 3415- added agencyPublisherLeadtracking link */}
            {/*3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE*/}
              {record.type === "Publisher Lead Tracking" ?
                <a style={{color:"#1B81D8"}} //Somnath Task-4080 Add report color blue.
                href={"#/"}//Aman-4797-(added void(0) to "#/")-Console Warnings-Agency-Reports Part 2
                onClick= {this.handleOnClickReport} pID={record.pID} reportID={record.reportID} reportKey={this.state.reportKey} reportType={record.type} reportName={record.reportName}
                >{record.reportName}</a>
                :
                <a 
                href={"#/"} style={{color:"#1B81D8"}}//Aman-4797-(added void(0) to "#/")-Console Warnings-Agency-Reports Part 2
                onClick= {this.handleOnClickReport} pID={record.pID} reportID={record.reportID} reportKey={this.state.reportKey} reportType={record.type} reportName={record.reportName}
                >{record.reportName}</a>
            }
            </div>
            :
            <div id="to_truncate_new1" style={{ fontSize: '13px', color: '#4F4F4F' }} title={record.reportName}>  {/*chaitanya-4187-Agency-My reports- Report title not showing full name on report list page*/}
              {/*sunita-task-3484- added css as per DI standard*/}
              {/*sunita - 3413- added agencyAdvertiserLeadTracking link */}
              {/*3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE*/}
              {/*Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {record.type === "Advertiser Lead Tracking" ?
                <a 
                href={"#/"} style={{color:"#1B81D8"}}//Aman-4797-(added void(0) to "#/")-Console Warnings-Agency-Reports Part 2
                onClick= {this.handleOnClickReport} advID={record.advertiserID} reportID={record.reportID} reportKey={this.state.reportKey} reportType={record.type} reportName={record.reportName} 
                >{record.reportName}</a>
                :
                <a 
                href={"#/"} style={{color:"#1B81D8"}}//Aman-4797-(added void(0) to "#/")-Console Warnings-Agency-Reports Part 2
                onClick= {this.handleOnClickReport} advID={record.advertiserID} reportID={record.reportID} reportKey={this.state.reportKey} reportType={record.type} reportName={record.reportName}  
                >{record.reportName}</a>
              }
            </div>
      },


      {
        title: <span>Report Type&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'type',
        key: 'type',
        width: 100,//sunita-task-3406-changed the width as per requirement
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.type.toUpperCase() < b.type.toUpperCase()) {
            return asc ? -1 : 1;
          } else if (
            a.type.toUpperCase() > b.type.toUpperCase()
          ) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) =>
          <div >
            <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
              {record.type}
            </span>
          </div>
      },
      {
        title: <span>Created On&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'created',
        key: 'created',
        width: 60,//sunita-task-3406-changed the width as per requirement
        align: 'left',

        sorter: (a, b) => {
          const asc = "asc";

          if (a.created < b.created) {
            return asc ? -1 : 1;
          } else if (a.created > b.created) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record, i) =>
          <div style={{ fontSize: '13px', color: '#4F4F4F' }} > {/*sunita-task-3484- added css as per DI standard*/}
            {record.created}
          </div>
      },

      {
        title: <span>Created By&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 90,
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.createdBy < b.createdBy) {
            return asc ? -1 : 1;
          } else if (a.createdBy > b.createdBy) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record, i) =>
          <div id="to_truncate" style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3406-added id as per requirement*/}
            {record.createdBy}

          </div>
      },
      {
        title: <span>Edited On&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
        dataIndex: 'lastUpdated',
        key: 'lastUpdated',
        width: 60,//sunita-task-3406-changed the width as per requirement
        align: 'left',
        sorter: (a, b) => {
          const asc = "asc";

          if (a.lastUpdated < b.lastUpdated) {
            return asc ? -1 : 1;
          } else if (a.lastUpdated > b.lastUpdated) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record, i) =>
          <div style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3484- added css as per DI standard*/}
            {record.lastUpdated}
          </div>
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 60,
        align: 'left',
        render: (text, record, i) =>
          //sunita-task-3406-handled below code using tab key
          this.state.key === 1 || this.state.key === "1" ?
            <div >
              <span style={{ display: "inline-flex" }}>
              {/*Aman-4797-(href attribute is required added "/")-Console Warnings-Agency-Reports Part 2 */}
                <a 
                href="#/"
                  reportid={record.reportID}
                  reportNameValueEdit={record.reportName}
                  onClick={this.editReportName.bind(this)}>
                  <Tooltip placement="top" title="Edit">
                    <img
                      src="edit_report.png"
                      data-toggle="modal"
                      data-target="#edit_report"
                      width="20"
                      height="20"
                      reportid={record.reportID}
                      reportNameValueEdit={record.reportName}
                      type={record.type}
                      alt=""
                    ></img>
                  </Tooltip>
                  {/* //shivani-3415-have passed reportName and type for edit popup. */}

                </a>
                {/* rutuja task-4497 added download report functionality  */}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {/*Aman-4797-(href attribute is required added "/")-Console Warnings-Agency-Reports Part 2 */}
                   <a 
                   href="#/"
                  reportID={record.reportID}
                  reportName={record.reportName}
                  type={record.type}
                    onClick={this.downloadReport.bind(this)}>
                  <Tooltip placement="top" title="Download">
                    <img
                     reportid={record.reportID}
                     reportName={record.reportName}
                     type={record.type}
                      src="downloadreport.png"
                      data-toggle="modal"
                      data-target="#delete_report"
                      width="20"
                      height="20"
                      alt=""></img>
                  </Tooltip>
                </a>
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   {/* rutuja -jagtap task-4496 enabled delete button  */}
                   {/*Aman-4797-(href attribute is required added "/")-Console Warnings-Agency-Reports Part 2 */}
									<a 
                  href="#/"
                  reportID={record.reportID}
                  type={record.type}
                    onClick={this.deleteReport.bind(this)}>
                  <Tooltip placement="top" title="Delete">
                    <img
                     reportid={record.reportID}
                     type={record.type}
                      src="file_upload_2_blue.png"
                      data-toggle="modal"
                      data-target="#delete_report"
                      width="20"
                      height="20"
                      alt=""></img>
                  </Tooltip>
                </a>
              </span>

            </div>
            :
            <div style={{ fontSize: '13px', color: '#4F4F4F' }}>{/*sunita-task-3484- added css as per DI standard*/}

              <span style={{ display: "inline-flex" }}>
                {this.state.reportadvid}
                {/*Aman-4797-(href attribute is required added "/")-Console Warnings-Agency-Reports Part 2 */}
                <a 
                href="#/"
                 reportadvid={record.reportID}
                  onClick={this.editReportName.bind(this)}>
                  <Tooltip placement="top" title="Edit">
                    <img
                      src="edit_report.png"
                      data-toggle="modal"
                      data-target="#edit_report"
                      width="20"
                      height="20"
                      reportadvid={record.reportID}
                      reportadvname={record.reportName}
                      advtype={record.type}
                      alt=""
                    // {/* //sunita-3413-have passed  type for edit popup. */}
                    ></img>
                  </Tooltip>
                </a>
                  {/* rutuja task-4497 added download report functionality  */}

                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   {/*Aman-4797-(href attribute is required added "/")-Console Warnings-Agency-Reports Part 2 */}
                   <a 
                   href="#/" 
                  reportID={record.reportID}
                   reportName={record.reportName}
                  type={record.type}
                    onClick={this.downloadReport.bind(this)}>
                  <Tooltip placement="top" title="Download">
                    <img
                     reportid={record.reportID}
                     type={record.type}
                     reportName={record.reportName}
                      src="downloadreport.png"
                      data-toggle="modal"
                      data-target="#delete_report"
                      width="20"
                      height="20"
                      alt=""></img>
                  </Tooltip>
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {/* rutuja -jagtap task-4496 enabled delete button  */}
                {/*Aman-4797-(href attribute is required added "/")-Console Warnings-Agency-Reports Part 2 */}
                   <a 
                   href="#/"
                  reportID={record.reportID}
                    onClick={this.deleteReport.bind(this)}>
                  <Tooltip placement="top" title="Delete">
                    <img
                      reportID={record.reportID}
                      advtype={record.type}
                      src="file_upload_2_blue.png"
                      data-toggle="modal"
                      data-target="#delete_report"
                      width="20"
                      height="20"
                      alt=""></img>
                  </Tooltip>
                </a>
              </span>

            </div>

      },
    ]

    function onChange(pagination, filters, sorter) {
      console.log("params", pagination, filters, sorter);
    }
    return (
      <div>
        <Navigation />
        <div style={{ paddingBottom: '60px', paddingTop: '100px' }} class="container-fluid ">

          {this.state.key === 1 || this.state.key === "1" ?//sunita-task-3406-added  key for tabs
            <div class="row">

              <div class="col-sm-1 col-md-1 col-lg-3">
                 {/*Chaitanya-4099-Back button required on My reports screen - Agency*/}
                 {/*Aman-4797-(href attribute is required added "/")-Console Warnings-Agency-Reports Part 2 */}
                <a class="col" href='#/' style={{ color: '#056eb8', float: 'left', marginLeft: "-15px" , marginBottom: "-38px"}} onClick={this.myReportBackButton.bind(this)} >
                  <FaArrowAltCircleLeft size={32} title="Back to Dashboard" className="backFrom_myReport" />
                </a>
              </div>

              <div class="col-sm-10 col-md-9 offset-lg-2 col-lg-8" align="center" >
                <center><label id="labelDI" align="center">My Reports</label></center>
              </div>
              <div class="col-sm-2 col-md-2.5 col-lg-2">
              {/*3721-Rutuja Kale-VAPT--Agency side--Reports menu--URL Links & API-FE*/}
                <button class="btn add-button" type="button" id="create_btn_report" data-toggle="modal"
                  style={{ marginLeft: "30px", float:"right" }} data-target="#createReport" data-backdrop="false" data-keyboard="false"  onClick={this.buttonChange}>
                  <i class="fa fa-plus"></i>&nbsp;Create New Report</button>
                  
                <div class="modal fade" id="createReport" role="dialog" data-backdrop="false"
                  data-keyboard="false" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                  <div class="modal-dialog modal-sm">
                    <div class="modal-content" className="modal-content CreateReportAgency" style={{ marginTop: '152px' }}>             {/*Chaitanya-task-4187-Agency side-My reports-Responsiveness*/}
                      <div class="modal-header custom-class" style={{ backgroundColor: "#144999", height: '50px' }} >
                        <div class="col-sm-12 head_crediv" style={{ color: '#FFFFFF', fontSize: "17px", fontWeight: '400 ', fontFamily: 'Roboto' }}>
                          Create New Report
                             <button type="button" class="cancel-button" id="cancel-btnMr" onClick={this.closePopup} style={{ fontColor: '#FFFFFF', float: 'right', fontSize: '18px', marginTop: '-5px', fontWeight: '400' }} data-dismiss="modal">&times;</button>
                        </div>
                      </div>
                      <div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
                        <label style={{ fontSize: '14px', color: "#193D8F" }}>Report Name :&nbsp;<span style={asterisk}>*</span></label><br />
                        <input type="text" id="input_Ag" name="reportName" class="form-control" style={{    /*chaitanya-4187-Agency-My reports-Responsiveness*/
                          height: '30px', fontSize: "14px", width: "255px", border: '1px solid #CCCCCC', backgroundColor: "#FFFFFF"

                        }}
                          value={this.state.reportNameValue} onChange={this.reportNameHandleChange}></input>
                        <div style={{ color: 'red' }}>{this.state.errors.reportName}</div>
                      </div>

                      <div style={{ paddingTop: '10px', paddingLeft: '20px' }}>

                        <label style={{ fontSize: '14px', color: "#193D8F" }}>Report Type :</label><br />
                        <select id="select_Ag" name="reportName" class="form-control" style={{ height: '30px', width: "255px", fontSize: "14px", border: "1px solid #CCCCCC", backgroundColor: "#FFFFFF" }} onChange={this.reportTypeForLead} value={this.state.type} >
                          {/*chaitanya-4187-Agency-My reports-Responsiveness*/}
                          {/* //shivani-3432-passed value for same report type  */}

                          <option id="dropMr" value="Publisher Campaign Delivery Tracking" selected
                          >Publisher Campaign Delivery Tracking</option>

                          {/* 
                          sunita-task-3406-uncommented the advertiser related code as per requirement */}

                          <option id="dropMrpub" value="Advertiser Campaign Delivery Tracking" >Advertiser Campaign Delivery Tracking</option>

                          <option id="dropMr" value="Publisher Lead Tracking" >Publisher Lead Tracking</option>

                          <option id="dropMr" value="Advertiser Lead Tracking" >Advertiser Lead Tracking</option>

                          <option id="dropMr" value="Financial" disabled>Financial</option>
                        </select>
                      </div>
                      <div style={{ paddingTop: '20px', paddingLeft: '20px', paddingBottom: '30px' }}>
                        <button type="button" data-dismiss="modal" class="btnMr infoMr" style={{ width: '116px', height: '34px', borderRadius: '2px' }} onClick={this.handleRefreshPage}>Cancel</button>
                        <span style={{ paddingLeft: '25px' }}>
                          <button type="button" value={this.state.type} class="btn" style={{    //4314-R-29-Prod issue-My Report-create new report-cancel button-Agency-(Removed add.button)
                            width: "116px", height: "34px", borderRadius: '2px', backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"
                            , color: " white"
                          }} id="create_btnAgency" onClick={this.createReport}>Create</button></span>   {/*chaitanya-4187-Agency-My reports-Responsiveness*/}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-2">

                <div class="modal fade" id="edit_report" role="dialog" data-backdrop="static"
                  data-keyboard="false" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                  <div class="modal-dialog modal-sm">
                    <div class="modal-content" className="modal-content EditReportAgency" style={{ marginTop: '152px' }}>              {/*Chaitanya-task-4187-Agency side-My reports-Responsiveness*/}
                      <div class="modal-header custom-class" style={{ backgroundColor: "#144999", height: '50px' }} >
                        <div class="col-sm-12 head_crediv" style={{ color: '#FFFFFF', fontSize: '17px', fontWeight: '400 ', fontFamily: 'Roboto' }}>
                          Edit Report Name
                             <button type="button" class="cancel-button" id="cancel-btnMr" style={{ fontColor: '#FFFFFF', float: 'right', fontSize: '18px', marginTop: '-5px', fontWeight: '400' }} data-dismiss="modal">&times;</button>
                        </div>
                      </div>
                      <div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
                        <label style={{ fontSize: '14px', color: "#193D8F" }}>Report Name :&nbsp;<span style={asterisk}>*</span></label><br />
                        <input type="text" id="input_Ag" name="reportNameEdit" class="form-control" style={{    /*chaitanya-4187-Agency-My reports-Responsiveness*/
                          height: '30px', width: "255px", fontSize: "14px", border: '1px solid #CCCCCC', backgroundColor: "#FFFFFF"

                        }}
                          value={this.state.reportNameValueEdit} onChange={this.reportNameHandleChangeEdit}></input>

                        <div style={{ color: 'red' }}>{this.state.errors.reportNameEdit}</div>
                      </div>
                      <div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
                        <label style={{ fontSize: '14px', color: "#193D8F" }}>Report Type :</label><br />
                        <select id="select_Ag" name="reportNameEdit" class="form-control" disabled style={{ height: '30px', width: "255px", fontSize: "14px", opacity: "0.5", border: "1px solid #CCCCCC", backgroundColor: "#FFFFFF" }}
                          value={this.state.type}>
                            {/*chaitanya-4187-Agency-My reports-Responsiveness*/}
                          {/* //shivani-3432-passed value for same report type  */}
                          <option id="dropMr" value="Publisher Campaign Delivery Tracking">Publisher Campaign Delivery Tracking</option>
                          {/* <option id="dropMr" value="Advertiser Campaign Delivery Tracking">Advertiser Campaign Delivery Tracking</option>sunita-task-3406-uncommented the advertiser related code as per requirement */}
                          <option id="dropMr" value="Publisher Lead Tracking" >Publisher Lead Tracking</option>
                          {/* <option id="dropMr" value="Advertiser Lead Tracking">Advertiser Lead Tracking</option> */}
                          <option id="dropMr" value="Financial" disabled>Financial</option>
                        </select>
                      </div>
                      <div style={{ paddingTop: '20px', paddingLeft: '20px', paddingBottom: '30px' }}>
                        <button type="button" data-dismiss="modal" class="btnMr infoMr" style={{ width: '116px', height: '34px', borderRadius: '2px' }} onClick={this.handleRefreshPage} >Cancel</button>
                        <span style={{ paddingLeft: '25px' }}><button 
                        //type="button" ////Aman-4797-(commented type multiple attributes issue)-Console Warnings-Agency-Reports Part 2
                         class="btn" style={{  //4314-R-29-Prod issue-My Report-create new report-cancel button-Agency-(Removed add.button)
                          width: "116px", height: "34px", borderRadius: '2px', backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)"
                          , color: " white"
                        }}
                          id="Edit_btnAgency" //shivani-3547-to close edit modal.
                          type="cancel" value="cancel" data-dismiss="modal"  //Chaitanya-4099-regarding design(popup will close on click on edit)
                          onClick={this.editReport}>Edit</button></span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            :
            //sunita-task-3406-below all code added for advertiser tab 
            <div class="row">
              <div class="col-sm-10 col-md-9 offset-lg-2 col-lg-8">
                <center><label id="labelDI" className="MyReportsAgencyAdv" align="center">My Reports</label></center>
              </div>
              <div class="col-sm-2 col-md-2.5 col-lg-2">
                {/* //sunita-3413-below all css changes done as per DI standard format */}
                 {/*3721-Rutuja Kale-VAPT--Agency side--Reports menu--URL Links & API-FE*/}
                {/* kiran- task 3873- aligned create report button */}
             
                  <button class="btn add-button createReportAgencyAdv" type="button" id="create_btn_report" data-toggle="modal"
                  style={{ marginLeft: "30px" , float:"right" }} data-target="#createReport" data-backdrop="false" data-keyboard="false" onClick={this.buttonChange}>
                  <i class="fa fa-plus"></i>&nbsp;Create New Report</button>
                <div class="modal fade" id="createReport" role="dialog" data-backdrop="false" 
                style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                  <div class="modal-dialog modal-sm">
                    <div class="modal-content" className="modal-content CreateReportAgency" style={{ marginTop: '152px' }}>                {/*Chaitanya-task-4187-Agency side-My reports- Responsiveness*/}
                      <div class="modal-header " style={{ backgroundColor: "#144999", height: '50px' }}>{/*sunita-task-3484- added css as per DI standard*/}
                        <div class="col-sm-12 head_crediv" style={{ color: '#FFFFFF', fontSize: "17px", fontWeight: "400", fontFamily: 'Roboto', marginLeft: "-10px", }}>{/*sunita-task-3484- added css as per DI standard*/}
                          Create New Report
                             <button type="button" class="cancel-button" id="cancel-btnMr" style={{ fontSize: '18px', color: '#FFFFFF', marginTop: '-5px', fontWeight: '400', float: 'right' }} onClick={this.closePopup} data-dismiss="modal">&times;</button>{/*sunita-task-3484- added css as per DI standard*/}
                        </div>
                      </div>
                      <div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
                        <label style={{ fontSize: '14px', color: '#193D8F' }}>Report Name :&nbsp;<span style={asterisk}>*</span></label><br />
                        <input type="text" id="input_Ag" name="reportName" class="form-control" style={{    //Chaitanya-task-4187-Agency side-My reports- Responsiveness
                          height: '30px', width: "255px", border: '1px solid #CCCCCC', fontSize: "14px", 
                          //border: "1px solid #CCCCCC", //Aman-4797-(commented border multiple attributes issue)-Console Warnings-Agency-Reports Part 2
                          backgroundColor: "#FFFFFF",
                          borderRadius: '2px'
                        }}
                          value={this.state.reportNameValue} onChange={this.reportNameHandleChange}></input>{/*sunita-task-3484- added css as per DI standard*/}
                        <div style={{ color: 'red' }}>{this.state.errors.reportName}</div>
                      </div>

                      <div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
                        <label style={{ fontSize: '14px', color: '#193D8F' }}>Report Type :</label><br />
                        <select id="select_Ag" name="reportName" class="form-control" style={{ height: '30px', width: "255px", fontSize: "14px", border: '1px solid #CCCCCC' }} value={this.state.advtype} onChange={this.reportTypeForLead}>{/*sunita-task-3484- added css as per DI standard*/}
                          {/*Chaitanya-task-4187-Agency side-My reports- Responsiveness*/}
                          {/* <option id="dropMr" value="Publisher Campaign Delivery Tracking"  >Publisher Campaign Delivery Tracking</option> */}

                          <option id="dropMr" value="Publisher Campaign Delivery Tracking" >Publisher Campaign Delivery Tracking</option>

                          <option id="dropMr" value="Advertiser Campaign Delivery Tracking">Advertiser Campaign Delivery Tracking</option>

                          {/* <option id="dropMr" value="Publisher Lead Tracking" >Publisher Lead Tracking</option> */}

                          <option id="dropMr" value="Publisher Lead Tracking">Publisher Lead Tracking</option>

                          <option id="dropMr" value="Advertiser Lead Tracking" >Advertiser Lead Tracking</option>
                          <option id="dropMr" value="Financial" disabled >Financial</option>
                        </select>
                      </div>
                      <div style={{ paddingTop: '20px', paddingLeft: '20px', paddingBottom: '30px' }}>
                        <button type="button" data-dismiss="modal" class="btnMr infoMr" style={{ width: '116px', height: '34px', borderRadius: '2px' }} onClick={this.handleRefreshPage} >Cancel</button>
                        <span style={{ paddingLeft: '25px' }}><button type="button" class="btn" style={{ width: "116px", height: "34px", borderRadius: '2px', backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)", color: " white" }} id="create_btnAgency" onClick={this.createReport}>Create</button></span>{/*sunita-task-3484- added css as per DI standard*/} 
                        {/*4314-R-29-Prod issue-My Report-create new report-cancel button-Agency-(Removed add.button)*/}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-2">

                <div class="modal fade" id="edit_report" role="dialog" data-backdrop="static" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                  <div class="modal-dialog modal-sm">
                    <div class="modal-content" className="modal-content EditReportAgency" style={{ marginTop: '152px' }}>              {/*Chaitanya-task-4187-Agency side-My reports- Responsiveness*/}
                      <div class="modal-header " style={{ backgroundColor: "#144999", height: '50px' }} >
                        <div class="col-sm-12 head_crediv" style={{ color: '#FFFFFF', fontSize: "17px", marginLeft: "-10px", fontFamily: 'Roboto', fontWeight: '400 ' }}>{/*sunita-task-3484- added css as per DI standard*/}
                          Edit Report Name
                             <button type="button" class="cancel-button" id="cancel-btnMr" style={{ fontSize: '18px', color: '#FFFFFF', marginTop: '-5px', float: 'right', fontWeight: '400' }} data-dismiss="modal">&times;</button>{/*sunita-task-3484- added css as per DI standard*/}
                        </div>
                      </div>
                      <div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
                        <label style={{ fontSize: '14px', color: '#193D8F' }}>Report Name :&nbsp;<span style={asterisk}>*</span></label><br />
                        {/*sunita-task-3484- added css as per DI standard*/}
                        <input type="text" id="input_Ag" name="reportNameEdit" class="form-control" style={{    //Chaitanya-task-4187-Agency side-My reports- Responsiveness
                          height: '30px', width: "255px", border: '1px solid #CCCCCC', backgroundColor: "#FFFFFF",
                        }}
                          value={this.state.reportadvname} onChange={this.reportNameHandleChangeEdit}></input>

                        <div style={{ color: 'red' }}>{this.state.errors.reportNameEdit}</div>
                      </div>
                      <div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
                        <label style={{ fontSize: '14px', color: '#193D8F' }}>Report Type :</label><br />
                        <select id="select_Ag" name="reportNameEdit" class="form-control " style={{ height: '30px', width: "255px", fontSize: "14px", border: '1px solid #CCCCCC', backgroundColor: "#FFFFFF",opacity: "0.5" }}
                          disabled value={this.state.advtype}>{/*Chaitanya-task-4187-Agency side-My reports- Responsiveness*/}{/*sunita-task-3579-added css for showing blur effect to disable field */}
                          <option id="dropMr" value="Publisher Campaign Delivery Tracking">Publisher Campaign Delivery Tracking</option>
                          <option id="dropMr" value="Advertiser Campaign Delivery Tracking">Advertiser Campaign Delivery Tracking</option>
                          <option id="dropMr" value="Publisher Lead Tracking" >Publisher Lead Tracking</option>
                          <option id="dropMr" value="Advertiser Lead Tracking">Advertiser Lead Tracking</option>
                          <option id="dropMr" value="Financial" disabled>Financial</option>
                        </select>
                      </div>
                      <div style={{ paddingTop: '20px', paddingLeft: '20px', paddingBottom: '30px' }}>

                        <button type="button" data-dismiss="modal" class="btnMr infoMr" style={{ width: '116px', height: '32px', borderRadius: '2px' }}  >Cancel</button>{/*sunita-task-3475-removed handle refresh page handle change as it's giving pblm and added data dismiss modal */}
                        {/*sunita-task-3484- added css as per DI standard*/}
                        <span style={{ paddingLeft: '25px' }}><button 
                        //type="button" //Aman-4797-(commented type multiple attributes issue)-Console Warnings-Agency-Reports Part 2
                          class="btn" style={{ width: "116px", height: "34px", borderRadius: '2px', backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)", color: " white" }}
                          id="Edit_btnAgency"//sunita-task-3459-added for popup 
                          type="cancel" value="cancel" data-dismiss="modal"//4314-R-29-Prod issue-My Report-create new report-cancel button-Agency-(Removed add.button)
                          onClick={this.editReport}>Edit</button></span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          }


          <Tabs activeKey={this.state.key} onSelect={this.handleSelect}>{/*sunita-task-3406-added handle change for changing tab */}
            <Tab eventKey="1" title="Publisher">
              {/* <form name=" MyreportPublisher" encType="multipart/form-data"> */}
              {/** Somnath Task-4130, Comment Form Tag to avoid search URL append data on enter**/}
                <div class="row" style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <div class="col-sm-12 col-md-4 col-lg-5">
                    <span id="myText" style={{ color: '#717171' }}>Showing results for <b style={{ color: '#414141' }}>"{this.state.label}"</b></span>{/*sunita-task-3484- added css as per DI standard*/}
                  </div>
                  {/* kiran- task 3873- added "" to showing result */}
                  {/* } */}
                  <div class="col-sm-12 offeset-md-1 col-md-3 offset-lg-3 col-lg-2" >
                    <select align="right" id="selectreport" class="form-control selectreportAgencyPub" style={{  //Chaitanya-task-4187-Agency side-My reports- Responsiveness
                      width: '255px', height: '31px', marginLeft: "-55px", border: '1px solid #CCCCCC',
                      fontFamily: 'sans-serif',
                      borderRadius: '2px'
                    }}
                      value={this.state.type} required onChange={this.reportType}>
                      <option value="Publisher Campaign Delivery Tracking" label="Publisher Campaign Delivery Tracking" selected>Publisher Campaign Delivery Tracking</option>
                      <option value="Publisher Lead Tracking" label="Publisher Lead Tracking" >Publisher Lead Tracking</option>
                      <option value="Financial" label="Financial" disabled>Financial</option>
                    </select>
                  </div>


                  <div id="searchID4th" class="col-sm-12 offeset-md-3 col-md-3 offset-lg-9 col-lg-3" style={{ marginTop: '-30px' }}>
                    <span >
                      <input type="text" id="searchID4" name="search" placeholder="Search"
                        class="form-control searchbarAgency"       //Chaitanya-task-4187-Agency side-My reports- Responsiveness
                        style={{
                          backgroundImage: 'url(searchIcon.png)',
                          backgroundPosition: '15px 6px',
                          backgroundSize: '15px 15px',
                          backgroundRepeat: "no-repeat",
                          width: '196px',
                          height: '28px',
                          fontSize: '12px',
                          marginLeft: '105px',
                          border: '1px solid #CCCCCC',
                          padding: '12px 20px 15px 36px',
                          backgroundColor: "#FFFFFF",
                          fontFamily: 'sans-serif',
                          borderRadius: '2px',
                        }}
                        onChange={this.handleChangeSearch} onPressEnter={this.handleChangeSearch}
                      >
                      </input>
                    </span>

                  </div>
                </div>
                <div id={this.state.loader2}></div>
                {/* rutuja -4496 */}
                <div class="table-responsive" style={{ marginBottom: '120px' }}>
                  <Table {...this.state.tableState}
                    id="myTable"
                    bordered
                    dataSource=
                    {this.state.tableSearchText === "" ?
                      this.state.agencyMyReportsData
                      :
                      this.state.agencyMyReportSearch}
                    columns={columns1}
                    // rowSelection={rowSelection}
                    onChange={onChange}
                    className={`${tableCSS} "ant-table-thead"`} //Aman-4797-(changed 2 className in One)-Console Warnings-Agency-Reports Part 2
                    //className="ant-table-thead" 
                    //className={tableCSS}
                    scroll={{ x: 1200, y: 1333 }}
                    pagination={{
                      pageSize: this.state.pageSize,
                      position: "bottom",

                    }}>

                  </Table>
                  <div class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6"   
                    style={{ marginTop: "-53px", marginLeft: "5px", fontSize: '13px', color: '#4F4F4F', fontFamily: 'sans-serif' }}
                  >
                    Records per page:&nbsp;
												<select
                      defaultValue={this.state.pageSize}
                      onChange={this.handleChangePageSize}
                      id="pacing11"
                      class="input-small"
                      className="form-control pacingAgency"   //Chaitanya-task-4187-Agency side-My reports- Responsiveness
                      name="pacing"
                      style={{ width: "71px", height: "30px", display: "initial", fontSize: '13px', fontFamily: 'sans-serif', color: '#4F4F4F' }}>
                      <option value="100" selected>
                        100
													</option>
                      <option value="200">200</option>
                      <option value="300">300</option>

                    </select>

                  </div>
                </div>
              {/* </form> */}
            </Tab>

            {/* sunita-task-3406-below all code added for designing the UI and functionality of advertiser tab  */}
              {/*3721-Rutuja Kale-VAPT--Agency side--Reports menu--URL Links & API-FE*/}

            <Tab eventKey={2} title="Advertiser"> {/*sunita-task- 3459-added value as per key*/}{/*Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE*/}
              {/* <form name=" MyreportAdvertiser" encType="multipart/form-data" > */}
              {/** Somnath Task-4130, Comment Form Tag to avoid search URL append data on enter**/}
                <div class="row" style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <div class="col-sm-12 col-md-4 col-lg-5">
                    {/* //sunita-3413-below all css changes done as per DI standard format */}
                    <span id="myText" style={{ color: '#717171' }}>Showing results for <b style={{ color: '#414141' }}>"{this.state.label1}"</b></span> {/*sunita-task-3484- added css as per DI standard*/}
                  </div>
                  {/* <div class="col-sm-12 offset-md-1 col-md-3 offset-lg-3 col-lg-2" > */}
                  <div class="col-sm-12 offset-md-1 col-md-2 offset-lg-2 col-lg-2" >
                    <select align="right" class="form-control selectreportAgencyAdv" style={{     //Chaitanya-task-4187-Agency side-My reports- Responsiveness
                      width: '128%', height: '31px', marginLeft: "0px", border: '1px solid #CCCCCC',
                      fontFamily: 'sans-serif',
                      borderRadius: '2px'
                    }}
                      value={this.state.advtype} required onChange={this.reportType}>
                      <option value="Advertiser Campaign Delivery Tracking" label1="Advertiser Campaign Delivery Tracking" selected>Advertiser Campaign Delivery Tracking</option>
                      <option value="Advertiser Lead Tracking" label1="Advertiser Lead Tracking">Advertiser Lead Tracking</option>
                      <option value="Financial" label1="Financial" disabled>Financial</option>
                    </select>
                  </div>
                  {/* <div id="searchID4th" class="col-sm-12 offset-md-3 col-md-3 offset-lg-9 col-lg-3" style={{ marginTop: '-30px' }}> */}
                  <div id="searchID4thB" class="col-sm-12 col-md-3 offset-lg-9 col-lg-3" style={{ marginTop: '0px',marginLeft: "-74px" }}>
                    <span >
                      <input type="text" id="searchID42" name="search" placeholder="Search" class="form-control searchbarAgencyAdv"  //Chaitanya-task-4187-Agency side-My reports- Responsiveness
                        style={{
                          backgroundImage: 'url(searchIcon.png)',
                          backgroundPosition: '15px 6px',
                          backgroundSize: '15px 15px',
                          backgroundRepeat: "no-repeat",
                          // width: '285px',
                          // width: '249px',
                          width: '196px',
                          height: '28px',
                          fontSize: '12px',
                          marginLeft: '165px',
                          border: '1px solid #CCCCCC',
                          padding: '12px 20px 15px 36px',
                          backgroundColor: "#FFFFFF",
                          fontFamily: 'sans-serif',
                          borderRadius: '2px',
                        }}
                        onChange={this.handleChangeSearch} onPressEnter={this.handleChangeSearch}
                      >
                      </input>
                    </span>

                  </div>
                </div>
                <div id={this.state.loaderadv}></div>
                 {/* rutuja -4496 */}
                <div class="table-responsive" style={{ marginBottom: '120px' }}>
                  {this.state.key === 1 || this.state.key === "1" ?
                    <Table {...this.state.tableState}
                      id="myTable"
                      bordered
                      dataSource=
                      {this.state.tableSearchText === "" ?
                        this.state.agencyMyReportsData
                        :
                        this.state.agencyMyReportSearch}
                      columns={columns1}
                      // rowSelection={rowSelection}
                      onChange={onChange}
                      className={`${tableCSS} "ant-table-thead"`} //Aman-4797-(changed 2 className in One)-Console Warnings-Agency-Reports Part 2
                      //className="ant-table-thead"
                      //className={tableCSS}
                      // scroll={{ x: 1200, y: 2000 }}
                      scroll={{ x: 1200, y: 1333 }}
                      pagination={{
                        pageSize: this.state.pageSize,
                        position: "bottom",

                      }}>

                    </Table>
                    :
                    <Table {...this.state.tableState}
                      id="myTable"
                      bordered
                      dataSource=
                      {this.state.tableSearchText === "" ?
                        this.state.agencyMyReportsData
                        :
                        this.state.agencyMyReportSearch}
                      columns={columns1}
                      // rowSelection={rowSelection}
                      onChange={onChange}
                      className={`${tableCSS} "ant-table-thead"`} //Aman-4797-(changed 2 className in One)-Console Warnings-Agency-Reports Part 2
                      //className="ant-table-thead"
                      //className={tableCSS}
                      // scroll={{ x: 1200, y: 2000 }}
                      scroll={{ x: 1200, y: 1333 }}
                      pagination={{
                        pageSize: this.state.pageSize,
                        position: "bottom",

                      }}>

                    </Table>
                  }
                  <div class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6"
                    style={{ marginTop: "-53px", marginLeft: "-5px", fontSize: '13px', color: '#4F4F4F', fontFamily: 'sans-serif' }}
                  >{/*sunita-task-3484- added css as per DI standard*/}
                    Records per page:&nbsp;
												<select
                      defaultValue={this.state.pageSize}
                      onChange={this.handleChangePageSize}
                      id="pacing11"
                      class="input-small"
                      className="form-control pacingAgency"   //Chaitanya-task-4187-Agency side-My reports- Responsiveness
                      name="pacing"
                      style={{ width: "71px", height: "30px", display: "initial", fontSize: '13px', fontFamily: 'sans-serif', color: '#4F4F4F' }}>
                      <option value="100" selected>
                        100
													</option>
                      <option value="200">200</option>
                      <option value="300">300</option>

                    </select>

                  </div>
                </div>
              {/* </form> */}

            </Tab>
          </Tabs>
        </div>
        <Footer />
      </div>
    )
  }
}
/**
       * @author Shivani Pathak-task 3354
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */

AgencyMyReports.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(AgencyMyReports));
