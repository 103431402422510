/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/


/*@author Sanobar Golandaj
*@fileName :deliverlead.js
 *Desc: Lead delivery
  Date:11/01/19 
*/

import React from 'react';
import { Table, TableBody, TableHead  } from 'mdbreact';

const LeadTable = (props) => {
 return(
    <div>
         <h5  class="labelsubheading">Lead Details</h5>
        <Table scrollY  className=" table table-bordered table-striped "  >
        <TableHead color="primary-color#4285F4" class="table-fixed">
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email Id</th>
          <th style={{width:'10%'}}>Status</th>
          <th>Reason</th>
          </tr>
      </TableHead>
      <TableBody>
        <tr  style={{color:'green'}}>
          <td>Mark</td>
          <td>Mark</td>
          <td>mark@gmail.com</td>
          <td>Accepted</td>
          <td></td>
        </tr>
        <tr>
        <td>Mark</td>
          <td>Mark</td>
          <td>mark@gmail.com</td>
          <td>Accepted</td>
          <td></td>
        </tr>
        <tr>
        <td>Mark</td>
          <td>Mark</td>
          <td>mark@gmail.com</td>
          <td>Accepted</td>
          <td></td>
        </tr>
        <tr  style={{color:'red'}}>
        <td>Mark</td>
          <td>Mark</td>
          <td>mark@gmail.com</td>
          <td>Rejected</td>
          <td>Wrong format</td>
        </tr>
        <tr>
        <td>Mark</td>
          <td>Mark</td>
          <td>mark@gmail.com</td>
          <td>Accepted</td>
          <td></td>
        </tr>
        <tr>
        <td>Mark</td>
          <td>Mark</td>
          <td>mark@gmail.com</td>
          <td>Accepted</td>
          <td></td>
        </tr>
   
      </TableBody>
    </Table>
   
    </div>


  );
}

      

export default LeadTable;
