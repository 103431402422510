/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Somnath Keswad
@Creation Date:14/01/2020
@Description:UI for  Publisher wise campaign report  under Agency view
*/
import React from "react";
import PublisherNavigation from "../layouts/publisherNavPage";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";// //Sanjana-3925-VAPT--publisher side-finance-Invoice- API-FE
import { withRouter } from 'react-router-dom';
import * as $ from "jquery";
import Footer from '../layouts/footer';
import { MDBRow,} from "mdbreact";//kiran-2836-removing console warning-no use
const Swal = require('sweetalert2');
class DisplayPublisherInvoice extends React.Component {
  constructor() {
    super();
    this.state = {
      newsuccess: "",
      invoiceDetail: [],
      startDate: '',
      endDate: '',
      finalInvoiceDetail: [],
      disableButton: 'true',
      fields: {
        startDate: '', endDate: ''
      },
      feedbackFlag: false,
      errors: {},
      dateerrors: {},


    }
    this.handleChange = this.handleChange.bind(this);
    this.showInvoice = this.showInvoice.bind(this);
    this.submitInvoiceFeedback = this.submitInvoiceFeedback.bind(this);
    this.handleFileDownload = this.handleFileDownload.bind(this);
  }// end of constrcutor

  deleteFeedbackFile(i, e) {
    e.preventDefault();
    var id = e.target.id;
    var fileName = e.target.getAttribute('name');
    var status = e.target.getAttribute('status');
    if (status === 'InvoiceAccepted' || status === 'InvoicePaid') { }
    else {
      let data = { id: id, fileName: fileName }
      fetch("publisherDashboard/deleteFeedBackFile", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json())
        .then(res => {
          if (res.success === true) { //kiran-2836-removing console warning
            Swal.fire({
                text: ("File Deleted Successfully"),
                type: 'success',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                preConfirm: () => {
                  this.forceUpdate();
                  var nooutput = document.getElementById("feedBackFileDiv2" + id);
                  nooutput.innerHTML = "No File Choosen";
                }
              })
            document.getElementById('invoiceFeedbackFile' + i).value = "";
          }
        }).catch(function (err) { console.log(err) });
    }
  }
  handleChange(e) {
    let fields = this.state;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    let startDate = this.state.fields.startDate;
    let endDate = this.state.fields.endDate;


    if ((startDate === "" || startDate === undefined) && (endDate === "" || endDate === undefined)) { //kiran-2836-removing console warning
      this.setState({ disableButton: true });
    } else {
      this.setState({ disableButton: false });
    }
  }// End of handleChange

  showInvoice(e) {
    e.preventDefault();
    let self = this;
    e.target.className += " was-validated";
    let startDate = self.state.fields.startDate;
    let endDate = self.state.fields.endDate;
    let finalInvoiceDetail = [...self.state.finalInvoiceDetail];
    let final = finalInvoiceDetail.filter((a) => {
      return a.createdDate >= startDate && a.createdDate <= endDate;
    });
    self.setState({ invoiceDetail: final });
    if (self.validateForm() === false) {
      return;
    }
    else {
      self.setState({ errors: '' });
    }
  }// End of showInvoice


  validateForm() {
    // let IsValid = true; //kiran-2836-no use
    let fields = this.state.fields;
    let startDate = fields.startDate;
    let endDate = fields.endDate;
    let errors = {};
    let dateerrors = {};
    let formIsValid = true;
    if ((!fields["startDate"]) || (!fields["endDate"])) {
      formIsValid = false;
      errors["commonError"] = <li>Please fill below required fields.</li>;
    }
    if (startDate !== undefined) {
      this.setState({ inputClassSdate: "valid" })
    }


    if (startDate === "") {
      this.setState({ inputClassSdate: "invalid" })
    }
    if (endDate !== undefined) {
      this.setState({ inputClassEdate: "valid" })
    }
    if (endDate === "") {
      this.setState({ inputClassEdate: "invalid" })
    }
    if ((Date.parse(endDate) < Date.parse(startDate))) {
      formIsValid = false;
      dateerrors["endDate"] = <li>To date must be greater than from date</li>;
    }

    this.setState({
      errors: errors, dateerrors: dateerrors, newsuccess: '',
      alertBorder: '1px solid red', alertWidth: '1000px',
      alertAlign: 'center', alertDisplay: 'block'
    });

    return formIsValid;
  }// end of validate

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
    }
    else {
      // const { user } = this.props.auth;
      // var pID = user.id;//kiran-2836-removing console warning-no use
      //Sanjana-3925-VAPT--publisher side-finance-Invoice- API-FE----pID removed
      fetch("publisherDashboard/invoiceInfo")
        .then(res => res.json())
        .then(invoiceDetail =>
          this.setState({ invoiceDetail: invoiceDetail, finalInvoiceDetail: invoiceDetail })
        ).catch(function (err) { console.log(err) });
    }
  } // end of componentDidMount()

  handleInvoiceFeedback(i, e) {
    const { name, value } = e.target;
    let invoiceDetail = [...this.state.invoiceDetail];
    invoiceDetail[i] = { ...invoiceDetail[i], [name]: value };
    this.setState({ invoiceDetail, feedbackFlag: true });
  }// End of handleInvoiceFeedback

  downloadInvoiceDocument(e) {
    e.preventDefault();
    var filename = e.target.id;
    var invoiceID = e.target.getAttribute('invoiceID');
    var ext = filename.split('.').pop();
    this.setState({ extension: ext, filename: filename })
    //const { user } = this.props.auth;//kiran-2836-removing console warning-no use
   //Sanjana-3925-VAPT--publisher side-finance-Invoice- API-FE----pID removed
   let data1 = { filename: filename, invoiceID: invoiceID }
    fetch("/publisherDashboard/downloadInvoiceDocument", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data1)
    }).then(r => r.blob())
      .then(downloadFiles => {
        this.setState({ downloadFiles: downloadFiles })
      }).then(this.handleFileDownload)
  }// End of downloadInvoiceDocument

  handleFileDownload(blob) {
    const { user } = this.props.auth;
    let zipFileDownloadName = user.id + "_invoice_documents.zip";
    var newBlob = new Blob([this.state.downloadFiles], { type: "application/zip" })
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = zipFileDownloadName;
    link.click();
  }// End of handleFileDownload

  uploadFeedbackFile(i, e) {
    e.preventDefault();
    // let self = this; //kiran-2836-removing console warning-no use
    var invoiceID = e.target.getAttribute('invoiceID');
    if (e.target.files[0] == null || e.target.files[0] === undefined || e.target.files[0] === "") {
    }
    else {
      var feedbackfile = e.target.files[0];
      var fileName = feedbackfile.name;
      var ext = fileName.split('.').pop()
      if (ext !== undefined) {
        ext = (ext).toLowerCase()
      }
      if (ext === "xls" || ext === "xlsx" || ext === "csv") {
        this.setState({ invoiceID: invoiceID, feedbackfile: feedbackfile, fileName: fileName })

        // var data = new FormData();  
        // data.append("invoiceID",invoiceID);
        // data.append("feedbackFile",feedbackfile);
        // fetch("/publisherDashboard/uploadFeedbackFile",{
        // method:'POST',
        // body: data
        // }).then(res => res.json())
        // .then(res => {
        // if(res.success===true){
        // var link = '<i class="fa fa-times removeFeedbackFile" style="color:red;" id=' + '"' + invoiceID + '"' + 'name=' + '"' + fileName + '"' + '></i>'
          var link = `<i class="fa fa-times removeFeedbackFile" style="color:red;" id="${invoiceID}" name="${fileName}"></i>`; //kiran-2836-used backtick instead of ' and used ${}
        var HTML = "<table>";
        HTML += "<tr><td>" + link + "&nbsp;&nbsp;&nbsp;&nbsp;" + fileName + "</td></tr>";
        HTML += "</table>";
        document.getElementById("feedBackFileDiv2" + invoiceID).innerHTML = HTML

        //   Swal.fire({
        //     text:'File Uploaded Successfully',
        //     type: 'success',
        //     confirmButtonText: 'Ok',
        //     allowOutsideClick: false,
        //   })
        //   self.setState({feedbackFlag:true});
        // }
        // })
        // .catch(
        // function (err) {console.log(err)}
        // );
      }
      else {
        Swal.fire({
          type: 'error',
          html: "Please upload file of <b>.xlsx/xls</b> format only"
        }
        )
      }
      $(document).off().on("click", ".removeFeedbackFile", function (e) {
        var id = e.target.id;
        var fileName = e.target.getAttribute('name');
        let data = { id: id, fileName: fileName }
        fetch("publisherDashboard/deleteFeedBackFile", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(res => {
            if (res.success === true) { //kiran-2836-removing console warning
              Swal.fire({
                  text: ("File Deleted Successfully"),
                  type: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  preConfirm: () => {
                    var nooutput = document.getElementById("feedBackFileDiv2" + id);
                    nooutput.innerHTML = "No File Choosen";
                  }
                })
              document.getElementById('invoiceFeedbackFile' + i).value = "";
              return
            }
          })
          .catch(
            function (err) { console.log(err) }
          );
      })
    }

  }
  submitInvoiceFeedback(e) {
    e.preventDefault();



    // let self = this;//kiran-2836-no use

    var data = new FormData();
    data.append("invoiceID", this.state.invoiceID);
    data.append("feedbackFile", this.state.feedbackfile);
    fetch("/publisherDashboard/uploadFeedbackFile", {
      method: 'POST',
      body: data
    }).then(res => res.json())
      .then(res => {
        if (res.success === true) {
          //  var link= '<i class="fa fa-times removeFeedbackFile" style="color:red;" id='+'"'+this.state.invoiceID+'"'+'name='+'"'+this.state.fileName+'"'+'></i>'
          //  var HTML = "<table>";
          //  HTML += "<tr><td>"+link+"&nbsp;&nbsp;&nbsp;&nbsp;"+this.state.fileName+"</td></tr>";
          //  HTML += "</table>";
          //  document.getElementById("feedBackFileDiv2"+this.state.invoiceID).innerHTML=HTML

          //  Swal.fire({
          //    text:'File Uploaded Successfully',
          //    type: 'success',
          //    confirmButtonText: 'Ok',
          //    allowOutsideClick: false,
          //  })
          this.setState({ feedbackFlag: true });



        }
      })
      .catch(
        function (err) { console.log(err) }
      );

    setTimeout(() => {
      if (this.state.feedbackFlag === false) { //kiran-2836-changed == to ===
        Swal.fire({
          text: "Please Fill Feedback Or Upload a Feedback File ",
          type: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        });
      }
      else {
        // const { user } = this.props.auth; //kiran-2836-no use
      //Sanjana-3925-VAPT--publisher side-finance-Invoice- API-FE----user removed
      let data = { dynamicArray: this.state.invoiceDetail}
        fetch("/publisherDashboard/submitFeedback", {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }).then(res => res.json()).then(res => {
          if (res.success === true) {
            Swal.fire({
              text: ("Feedback Submitted Successfully"),
              type: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              }
            })
          }
        }).then(function (response) {
          console.log(response);
        }).catch(function (err) { console.log(err); });
      }
    }, 800);//Sonali-changing timeout-4043

  }
  render() {
    const asterisk = { color: 'red', }
    return (
      <div>
        <PublisherNavigation />
        <form method="" name="" onSubmit={this.submitInvoiceFeedback} className="needs-validation" encType="multipart/form-data" noValidate>
          <div class="container-fluid" style={{ paddingTop: '90px', marginBottom: '60px' }}>
            <div class="col" align="center" style={{ fontSize: "20px", fontWeight: "bold" }}><label id="labelheading" style={{ color: '#056eb8' }} >Invoice Report</label></div>
            <div style={{ fontSize: '16px', color: 'red', fontWeight: '600', textAlign: 'center' }}>
              {this.state.errors.commonError}
            </div>
            <br />
            <MDBRow lg="12">
              <div class="col-lg-3">
                <div>
                  <label id="label" style={{ fontSize: "16px", fontWeight: '20' }}>From<span style={asterisk}>*</span></label>
                </div>
                <div>
                  <input type="date" id="startDate" style={{ width: '160px' }} name="startDate"  pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                    value={this.state.startDate} className={this.state.inputClassSdate}
                    onChange={this.handleChange} class="form-control" min="" required />
               {/* kiran-2836-removed duplicate min=""*/}
                </div>
                <div>
                </div>
                <span style={asterisk}> {this.state.errors.startDate} </span>
              </div>
              <div class="col-lg-2">
                <div>
                  <label id="label" style={{ fontSize: "16px" }} >To<span style={asterisk}>*</span></label>
                  <div>
                    <input type="date" id="endDate" style={{ width: '160px' }} name="endDate" min=""pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                      value={this.state.endDate} className={this.state.inputClassEdate}
                      onChange={this.handleChange} class="form-control" required />
                  {/* kiran-2836-removed duplicate required */}
                  </div>
                </div>
                <span style={asterisk}> {this.state.dateerrors.endDate} </span>
              </div>
              <div class="col-lg-1" >
                <label>
                </label>
              </div>
              <div class="col-lg-2">
                <div class="col" style={{ fontSize: "18px" }}>
                  <label></label>
                </div>
            &nbsp;&nbsp;
            <button class="btn text-uppercase add-button" style={{ float: 'left', backgroundColor: "#2196F3", color: "white" }} disabled={this.state.disableButton} onClick={this.showInvoice} type="submit" >
                  Search</button>
              </div>
            </MDBRow>
            <div style={{ fontSize: '16px', color: 'red', fontWeight: '600', textAlign: 'center' }}>
              {this.state.newsuccess.records}
            </div>
            <div id="campReport" class="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                <div className="card card-signin my-1" >
                  <div className="card-body">
                    <div class="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="table-responsive" >
                          <table class="table table-bordered table-striped">
                            <thead>
                              <tr class="info">
                                <th class="table-header" style={{ width: '8%' }}>Invoice ID</th>
                                <th class="table-header" style={{ width: '8%' }}>Agency ID</th>
                                <th class="table-header" style={{ width: '20%' }}>
                                  Invoice Document</th>
                                <th class="table-header" style={{ width: '7%' }}>Month</th>
                                <th class="table-header" style={{ width: '5%' }}>Year</th>
                                <th class="table-header" style={{ width: '10%' }}>Invoice Status</th>
                                <th class="table-header" style={{ width: '25%' }}>Feedback</th>
                                <th class="table-header" style={{ width: '30%' }}>Upload Feedback File</th>
                                <th class="table-header" style={{ width: '30%' }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.invoiceDetail.length > 0 ? //sunita-task-3110-as per condition shown the data is present or not.
                                this.state.invoiceDetail.map((invoiceDetail, i) => {
                                  return (
                                    <tr>
                                      <td>{invoiceDetail.invoiceID}</td>
                                      <td>{invoiceDetail.agencyID}</td>
                                      <td>
                                        <a href="#/" id={invoiceDetail.invoiceDocumentName}
                                          invoiceID={invoiceDetail.invoiceID} onClick={this.downloadInvoiceDocument.bind(this)}>{invoiceDetail.invoiceDocumentName}</a>
                                      </td>
                                      <td>{invoiceDetail.month}</td>
                                      <td>{invoiceDetail.year}</td>
                                      <td>{invoiceDetail.status}</td>
                                      <td>
                                        Enter Feedback:<textarea id="invoiceFeedback" name='feedback' defaultValue={invoiceDetail.feedback}
                                          onChange={this.handleInvoiceFeedback.bind(this, i)} class="form-control" />
                                      </td>
                                      <td>
                                        {invoiceDetail.status === 'InvoiceAccepted' || invoiceDetail.status === 'InvoicePaid' ?
                                          <label id={invoiceDetail.invoiceID} disabled class="btn chooseFile">Choose file
             <input type="file" style={{ display: 'none' }} id={'invoiceFeedbackFile' + i} invoiceID={invoiceDetail.invoiceID} disabled name="invoiceFeedbackFile" onChange={this.uploadFeedbackFile.bind(this, i)}></input>
                                          </label>
                                          :
                                          <label id={invoiceDetail.invoiceID} class="btn chooseFile">Choose file
              <input type="file" style={{ display: 'none' }} id={'invoiceFeedbackFile' + i} invoiceID={invoiceDetail.invoiceID} name="invoiceFeedbackFile" onChange={this.uploadFeedbackFile.bind(this, i)}></input>
                                          </label>
                                        }
                                        <br />
                                        <div id={"feedBackFileDiv2" + invoiceDetail.invoiceID} name={invoiceDetail.feedbackFIleName}>
                                          <span class="word-wrap">{invoiceDetail.feedbackFIleName ?
                                            <i class="fa fa-times" onClick={this.deleteFeedbackFile.bind(this, i)} id={invoiceDetail.invoiceID} name={invoiceDetail.feedbackFIleName} status={invoiceDetail.status} > &nbsp;</i> : "No File Choosen"}
                                            {invoiceDetail.feedbackFIleName ? invoiceDetail.feedbackFIleName : ''}</span>
                                        </div>
                                        <div id={"otherMessage" + invoiceDetail.invoiceID} style={{ color: 'green' }}></div>
                                        <div id={"otherMessage" + invoiceDetail.invoiceID}></div>
                                      </td>
                                      <td>
                                        <select style={{ height: '60%' }} id="status" name="status"
                                          invoiceID={invoiceDetail.invoiceID} defaultValue={invoiceDetail.status}
                                          onChange={this.handleInvoiceFeedback.bind(this, i)}>

                                          <option value="InvoiceAccepted">Accept</option>

                                        </select>
                                      </td>
                                    </tr>
                                  );
                                }
                                )
                                :
                                <tr>
                                  <td colSpan="10" style={{ color: 'gray', fontSize: 'medium', textAlign: "center", height: '200px', paddingTop: '90px' }}>{/*sunita-task-3110-if data is not present */}
                           No Data Exist
                        </td>
                                </tr>
                              }
                            </tbody>

                          </table>

                        </div>
                        {this.state.invoiceDetail.length > 0 ?//sunita-task-3110-as per condition below button will work.
                          <div style={{ textAlign: 'right' }}> <button class="btn add-button" type="HTMLsubmit">Submit</button></div>
                          :
                          <div style={{ textAlign: 'right' }}> <button class="btn add-button" type="HTMLsubmit" disabled>Submit</button></div>
                        }
                        {/*/ enf og lg-12*/}
                      </div>{/*end of row*/}
                    </div>
                  </div>{/*end of card-body*/}
                </div>{/*end of card card-signin my-1*/}
              </div>
            </div>


            {/* </div>Card-body */}

          </div>{/*Container */}
        </form>
        <Footer />
      </div>
    );
  }// end of renders
}// end of class



DisplayPublisherInvoice.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(DisplayPublisherInvoice));
