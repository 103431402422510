/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Sanjana Godbole
@Creation Date:26-02-2019
@Description:UI for  Publisher wise campaign report  under Agency view
*/
import React from "react";
import * as $ from "jquery";
import LeadReviewTable from "../publisher/leadReviewTable";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";

import { Modal, Button } from 'react-bootstrap';
import { Progress } from "mdbreact";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Table} from 'mdbreact';


class ReportByPublisher extends React.Component {
    constructor() {
        super();
        this.state = {
            pubName:[],
            newsuccess:"",
            showPubReport:[],
            liveCampaignList:[],
            assignDeliveredLead:[],
            currentDate:'',
            pID:'',
            tableStatus: '',
            pName:'',
            btnDisable:'true',
            publisher:'',
            showTable:'false',
            pub1:'true',
            tableStatus:'',
            disableButton:'',
            fields: {},
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this);
       //  this.dropDownHandleChange = this.dropDownHandleChange.bind(this);
        this.handleFileDownload = this.handleFileDownload.bind(this);
      this.showPubDetails = this.showPubDetails.bind(this);
    
    }// end of constrcutor

    handleChange(e) {

        let fields = this.state;
        fields[e.target.name] = e.target.value;
        const tableStatus=this.state.showTable;
        const doesShow=this.state.showTable;

        if(this.state.pub1 ==='false')
        {
          this.setState({
            inputClassPublisher: "valid",
          
        });
        }
       
      this.setState({ fields, pID: e.target.value, tableStatus: true});
    

        
    
      }// handleChange(e)
  handleFileDownload(e) {
    
    const { isAuthenticated, user } = this.props.auth;
    var pID = this.state.pID;
    var date=this.state.currentDate;
    var publisherName=this.state.publisher;
    
    const input = document.getElementById('pubReport');
    html2canvas(input)
      .then((canvas) => {


    var imgData = canvas.toDataURL('image/png');
    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;

    var document = new jsPDF('p', 'mm');
    var position = 0;

    document.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      document.addPage();
      document.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    console.log("document : "+JSON.stringify(document));
    
    document.save(publisherName+'_'+pID+'_'+date+'.pdf');
      });

    var formData = new FormData();
    //alert(JSON.stringify(user));
    formData.append("pID", pID);
    formData.append("pubFile",document);
    formData.append("user", user);
    console.warn(formData);
   
    // fetch("publisher/sendPubFile", {
    //   method: 'POST',
    //   body: formData
    // });
  }
  showPubDetails(e) {
    e.preventDefault();   
        let doesShow=this.state.showTable;
        //alert("Table:"+this.state.tableStatus);
     

    /*
    @author:Somnath Keswad
    @Creation Date:28-02-2019
    @Description:return the selected publisher data from database
    */
   const { isAuthenticated, user } = this.props.auth;
   var agencyID=user.id;
      var pID=this.state.pID;
      let newsuccess={};
      var data={
        pID:pID,agencyID:agencyID
      }
      this.setState({btnDisable:false});
      fetch("reports/showPubReport",{
         method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }).then(res => res.json())
        .then(showPubReport =>{
          this.setState({
            showPubReport: showPubReport
          })
           if (this.state.showPubReport==undefined ||this.state.showPubReport=='')
          {
          // alert("no data");
          newsuccess["records"]="No data exists";
          this.setState({ newsuccess:newsuccess,
            btnDisable:true,
          });
          }
      }).catch(function (err) {
        console.log(err)
      });

   
    fetch("reports/publisherName?pID=" + pID + "")
      .then(res => res.json())
      .then(pName =>{
        this.setState({
          pName: pName
        });
    if (this.state.pName && this.state.pName.length) {
      var str = this.state.pName[0].publisherName;
      this.setState({ publisher: str });
    }
   } );
    if (this.validateForm() === false) { // alert("Form Not Valid");
    
      return;
    }
    else {
      // alert("Form is Valid");
      // alert("Display Table");
      if (this.state.tableStatus === true) {
        this.setState({
          showTable: true,
          // disableButton:'',
          errors: ''

        });
      }

    } // else

        
       
  }// end of showPubDetails


 validateForm()
 {
    let IsValid = true;
    let fields = this.state.fields;
    let errors = {};
   if  ((!fields["publisherName"]))
    {
       
        IsValid = false;
        errors["publisherName"]= <li>Please Select publisher</li>;
        
        this.state.pub1='false';
        this.setState({
          inputClassPublisher: "invalid"
        })
    }
    

    if  ((fields["publisherName"]))
    {
     
      this.setState({
        inputClassPublisher: "valid"
      })
    }

    

 // Validation for End Date(Start Date less than End Date)
 if ((Date.parse(this.state.endDate) < Date.parse(this.state.startDate))) {
   // alert("Inside if Date Validation");  
   
    IsValid = false;
    errors["endDate"]= <li>End date must be greater than start date</li>; 
    
  }
 
  this.setState(
    { errors: errors,
      newsuccess:'',
        showTable:false});

        
  return IsValid;
 }// end of validate

 componentDidMount(){

   if (!this.props.auth.isAuthenticated) {
     this.props.history.push('/userLogin');
   }
else{

 /*
 @author:Somnath Keswad
 @Creation Date:28-02-2019
 @Description:return the Publisher Name from in select box for getting data
 */
   let self = this;
   const { isAuthenticated, user } = this.props.auth;
   var agencyID=user.id;
   fetch("reports/selectPublisher?agencyID="+agencyID)
     .then(res => res.json())
     .then(pubName =>
       self.setState({
         pubName: pubName
       })
     );
  

     //getting current Date and Time
 var date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    var hours = new Date().getHours(); //Current Hours
    var min = new Date().getMinutes(); //Current Minutes
    var sec = new Date().getSeconds(); //Current Seconds
    self.setState({
      //Setting the value of the date time
      currentDate:
        date + '/' + month + '/' + year + ' ' + hours + ':' + min + ':' + sec,
    });

  }

} // end of componentDidMount()
    render()
    {
//moment(<your Date goes here>).format("DD-MMM-YYYY")

      const { isAuthenticated, user } = this.props.auth;
      console.log("PID on rendder==>"+this.state.pID);

      const progress=
      {

         marginBottom:'0px'
    }
      
        const asterisk =
        {
       
          color: 'red',
      
        }
      //var pubdate = this.state.showPubReport.map(showPubReport => {return({showPubReport.created})});






            return(
                    <div>
                     <Navigation />
                <form method="" name="" onSubmit={this.showPubDetails}> 
                  <div class="container-fluid" style={{ paddingTop: '90px', marginBottom: '60px' }}>
                    <div className="card card-signin my-1">
                    <div className="card-body">
                    <div style={{fontSize:'14px',color:'red'}}>{this.state.errors.commonError}</div> 
                       <div class="row">
                            {/* <div class="col-lg-4"> */}
                            <div class="col span_1_of_5">
                            <div>
                            <label id="label">Publisher Name <span style={asterisk}>*</span></label>
                            </div>
                            <div>
                            <select required id="publisherName" name="publisherName" 
                              value={this.state.publisherName}
                                                  onChange={this.handleChange} 
                                                  class="form-control" className={this.state.inputClassPublisher}
                                                  >
                                                 <option value="select Publisher ID"  disabled="disabled" selected>Select Publisher Name</option>
                              {this.state.pubName.map(pubName => (
                                <option value={pubName.pID} id="pubID" name={pubName.publisherName} >{pubName.publisherName}</option>
                                // <option hidden value={pubName.publisherName}></option>
                                ))}
                                                </select>
                                                </div>
                            <span style={asterisk}> {this.state.errors.publisherName} </span>
                            </div>
                            {/* <div class="col-lg-6" class="float-right"> */}
                            <div class="col span_1_of_5">
                            <div class="col" style={{ fontSize: "16px" }}>
                         <label></label>
                           </div>
                            <button class="btn   text-uppercase add-button"  
 disabled={this.state.disableButton}type="submit" >
                     Show Progress
                    </button>                    
                            </div>
                            {/* <div class="col-lg-4"> */}
                            <div class="col span_1_of_5">
                            <div class="col" style={{ fontSize: "16px" }}>
                         <label></label>
                           </div>
                            <button type="button" onClick={this.handleFileDownload} 
class="btn  text-uppercase add-button" id="cmd" disabled={this.state.btnDisable}  data-dismiss="modal">
                              Download
                  </button>
                  
                            </div>
                            <div class="col span_1_of_5">
                            
                            </div>
                            <div class="col span_1_of_5">
                            
                            </div>
                            <div class="col span_1_of_5">
                            
                            </div>
                      </div>
                    
                        
                         {this.state.showTable === true?
                         
                         <div id="pubReport" class="row">
                           
                         
                    
                              
                           
                         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                              <center>

                              <div style={{fontSize:'16px',color:'red',fontWeight:'600',textAlign:'center'}}>
                          {this.state.newsuccess.records}
                        </div>
                        <br/>
                                <label style={{  float: 'center',color:'#056eb8',fontSize:'20px' }}> {this.state.publisher}</label>
                              </center>
                              <div className="card card-signin my-1">
                                <div className="card-body">
                                  <div class="row ">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="table-responsive">
                                      <Table  
                                        id="myTable"
                                        className=" table table-bordered table-striped "
                                      >
                                        <thead >
                                          <tr class="custom-class">
                                            <th>
                                              Campaign<br/> Id</th>
                                              <th>
                                              Allocation<br/> Id</th>
                                            <th>
                                              Campaign
                              <br />
                                              Name
                            </th>
                                            <th>
                                              Allocation
                              <br />
                                              Date
                            </th>
                                            <th>
                                             Progess Status
                            </th>
                                     
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.showPubReport.map(showPubReport =>{
                                            
                                            if (showPubReport.daysLeft>3)
                                            {
                                                 return (
                                            <tr>
                                              
                                                     <td class="campId">{showPubReport.parentCampID?showPubReport.parentCampID:showPubReport.campID}</td>
                                                     <td class="campId">{showPubReport.reallocationID}</td>
                                              <td>

                                                
                                                  {showPubReport.campaignName}
                                                
                                              </td><td>{showPubReport.created}</td>
                                              <td>
                                {/* <label id="label">
                                  {onClickAllocatedLeadPubList.allocatedLead}
                                </label> */}




                 <label id="label">Allocated</label>:{showPubReport.allocatedLead} &nbsp;&nbsp;


                                
<label id="label">Delivered</label>:{showPubReport.deliveredLead} &nbsp;&nbsp;
<label id="label">Balance</label>:{showPubReport.balance}
                                    
                                    &nbsp;&nbsp;
                                    <label id="label">Accepted</label>:
                                    <span style={{color: "#20EB10",fontWeight:"600"}}>
                                                  {showPubReport.acceptedLead ? showPubReport.acceptedLead : "0"}</span> 
                                    &nbsp;&nbsp;
                                    <label id="label">Rejected</label>:
                                    <span style={{ color: "red", fontWeight: "600" }}>{showPubReport.rejectedLead ? showPubReport.rejectedLead : "0"}</span>
                                    &nbsp;&nbsp;
                             {/*{onClickAllocatedLeadPubList.allocatedLead -onClickAllocatedLeadPubList.count
                                    ? onClickAllocatedLeadPubList.allocatedLead -onClickAllocatedLeadPubList.count : onClickAllocatedLeadPubList.allocatedLead} &nbsp;&nbsp; */}
                            <br/> 
                          
                          

<Progress material value={Math.round(showPubReport.percent)} height="20px" className={progress} >
{Math.round(showPubReport.percent)} % </Progress>                            
                                                <label id="label">Start Date:</label>{showPubReport.startDate} &nbsp;&nbsp;
                            <label id="label">End Date:</label> {showPubReport.endDate} &nbsp;&nbsp;
                           
                           <label id="label">Days Left:</label> {showPubReport.daysLeft>0?showPubReport.daysLeft:0} &nbsp;&nbsp;


                         
                              </td>
                                             
                                            </tr>
                                          )}
                                            else
                                            {
   return (
                                            <tr>
                                              <td class="campId">{showPubReport.parentCampID?showPubReport.parentCampID:showPubReport.campID}</td>
                                                     <td class="campId">{showPubReport.reallocationID}</td>
                                              <td>

                                                
                                                  {showPubReport.campaignName}
                                                
                                              </td><td>{showPubReport.created}</td>
                                              <td>
                                {/* <label id="label">
                                  {onClickAllocatedLeadPubList.allocatedLead}
                                </label> */}




                                                <label id="label">Allocated</label>:{showPubReport.allocatedLead} &nbsp;&nbsp;


                                
                            <label id="label">Delivered</label>:{showPubReport.deliveredLead} &nbsp;&nbsp;
                             <label id="label">Balance</label>:{showPubReport.balance}
                                                   {/* ? showPubReport.balance : showPubReport.allocatedLead}  */}
                                    
                                    &nbsp;&nbsp;
                                    <label id="label">Accepted</label>:
                                    <span style={{color: "#20EB10",fontWeight:"600"}}>
                                                  {showPubReport.acceptedLead ? showPubReport.acceptedLead : "0"}</span> 
                                    &nbsp;&nbsp;
                                    <label id="label">Rejected</label>:
                                    <span style={{ color: "red", fontWeight: "600" }}>{showPubReport.rejectedLead ? showPubReport.rejectedLead : "0"}</span>
                                    &nbsp;&nbsp;
                             {/*{onClickAllocatedLeadPubList.allocatedLead -onClickAllocatedLeadPubList.count
                                    ? onClickAllocatedLeadPubList.allocatedLead -onClickAllocatedLeadPubList.count : onClickAllocatedLeadPubList.allocatedLead} &nbsp;&nbsp; */}
                            <br/> 
                          
                          

                                                <Progress material value={Math.round(showPubReport.percent)} height="20px" color="danger">
                                                {Math.round(showPubReport.percent)}%
                            </Progress>
                            
                                                <label id="label">Start Date:</label>{showPubReport.startDate} &nbsp;&nbsp;
                            <label id="label">End Date:</label> {showPubReport.endDate} &nbsp;&nbsp;
        
                           <label id="label" style={{ color:'#F31431'}}>Days Left:</label> 
                           <span style={{ color: '#F31431' }}> {showPubReport.daysLeft>0?showPubReport.daysLeft:0} </span>
                          
                           &nbsp;&nbsp;


                         
                              </td>
                                             
                                            </tr>
                                          )
                                            }// end of else


                                         
                                          })}
                                        </tbody>
                                      </Table >
                                    </div>{" "}
                                    {/*/ enf og lg-12*/}
                                  </div>{/*end of row*/}
                                  </div>
                                </div>{/*end of card-body*/}
                              </div>{/*end of card card-signin my-1*/}
                         </div>
                         </div>:null}

                     
                  </div>{/*Card-body */}
</div>{/*Card */}
</div>{/*Container */}
 </form> 
 <Footer />
</div>
            );
    }// end of renders
}// end of class


/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
ReportByPublisher.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(ReportByPublisher));

