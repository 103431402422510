/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Nikita
@Description:Email Config
date: 18-12-2019 
*/

// import React, { Component } from "react"; //Nilesh-2897-Removing console warnings
import React from "react";
import {
  // Form, //Nilesh-2897-Removing console warnings
  // Input, //Nilesh-2897-Removing console warnings
  // button, //Nilesh-2897-Removing console warnings
  // Card, //Nilesh-2897-Removing console warnings
  // Icon, //Nilesh-2897-Removing console warnings
  // Tooltip,  //Nilesh-2897-Removing console warnings
  // Table, //Nilesh-2897-Removing console warnings
  Checkbox,
  // Row, //Nilesh-2897-Removing console warnings
  // Col, //Nilesh-2897-Removing console warnings
} from "antd";
import { connect } from "react-redux";
import Navigation from "../layouts/publisherNavPage";
import PropTypes from "prop-types";
import Footer from "../layouts/footer";
// import { css } from "emotion"; //Nilesh-2897-Removing console warnings
// import "./agencyEmailConfig.css";
import "./publisherEmailConfig.css"; //mufiz 4121
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
const Swal = require("sweetalert2");

const campActivity = [
  { id: "Campaign Allocation", name: "campaignAllocation", value: "No" },
  { id: "Edit Campaign", name: "editCampaign", value: "No" },
  { id: "Accept Campaign", name: "acceptCampaign", value: "No" },
  { id: "Pause Campaign", name: "pauseCampaign", value: "No" },
  { id: "Resume Campaign", name: "resumeCampaign", value: "No" },
  { id: "Complete Campaign", name: "completeCampaign", value: "No" },
  { id: "Reject Campaign", name: "rejectCampaign", value: "No" },
  { id: "Counter Campaign", name: "counterCampaign", value: "No" },
  { id: "Counter Accept Campaign", name: "counterAcceptCampaign", value: "No" },
  { id: "Counter Reject Campaign", name: "counterRejectCampaign", value: "No" },
  // { id: 'Active Campaign', name: 'activeCampaign', value: 'No' },
  { id: "Bidding Allocation", name: "biddingAllocation", value: "No" },
  { id: "Bidding Submission", name: "biddingSubmission", value: "No" },
  { id: "Bidding Review", name: "biddingReview", value: "No" },
  { id: "Pacing Alert", name: "pacingAlert", value: "No" },
];

const leadActivity = [
  { id: "Lead Upload", name: "leadUpload", value: "No" },
  { id: "Lead Review", name: "leadReview", value: "No" },
  { id: "Leads Decrement", name: "leadsDecrement", value: "No" },
  { id: "Update Leads Permission", name: "updateLeadsPermission", value: "No" },
  // { id: 'Voice Link Upload', name: 'voiceLinkUpload', value: 'No' },
  // { id: 'TDR Report', name: 'tdrReport', value: 'No' },
];

const pubActivity = [
  // { id: 'Cancel Publisher', name: 'cancelPublisher', value: 'No' },
  { id: "End Date Publisher", name: "endDatePublisher", value: "No" },
  // { id: 'Re-Allocation Campaign', name: 'reAllocationCampaign', value: 'No' },
  { id: "Landing Page Submit", name: "landingPageSubmit", value: "No" },
  { id: "CS Submit", name: "csSubmit", value: "No" },
  { id: "POC Submit", name: "pocSubmit", value: "No" },
  { id: "Creative Review", name: "creativeReview", value: "No" },
];

const deadline = [
  {
    id: "First Delivery Date Warn",
    name: "firstDeliveryDateWarn",
    value: "No",
  },
  {
    id: "First Delivery Date Cross",
    name: "firstDeliveryDateCross",
    value: "No",
  },
  { id: "Accept Campaign Warn", name: "acceptCampaignWarn", value: "No" },
  { id: "Creatives Upload Warn", name: "creativesUploadWarn", value: "No" },
  { id: "TDR Report", name: "tdrReport", value: "No" },
  // { id: 'Pacing Alert', name: 'pacingAlert', value: 'No' },
  // { id: 'RFP Acknowledgement', name: 'rfpAcknowledgement', value: 'No' },
];
const others = [
  { id: "Daily Update", name: "dailyUpdate", value: "No" },
  { id: "Link Agency Publisher", name: "linkAgencyPublisher", value: "No" },
  { id: "New Message", name: "message", value: "No" },
  // { id: 'Add User', name: 'addUser', value: 'No' },
  { id: "Invoice Reviewed", name: "invoiceReviewed", value: "No" },
  {
    id: "Invoice Reviewed Deadline Alert",
    name: "invoiceReviewAlert",
    value: "No",
  },
  {id: 'RFP Follow up',name:'rfpFollowupEmail',value:'No'}, //kiran-task-4293-added for follow up check box

];

const campPlain = campActivity.map(function(a) {
  return a.name;
});
const pubPlain = pubActivity.map(function(a) {
  return a.name;
});
const leadPlain = leadActivity.map(function(a) {
  return a.name;
});
const deadlinePlain = deadline.map(function(a) {
  return a.name;
});
const otherPlain = others.map(function(a) {
  return a.name;
});

const campActivityForNC = [
  { id: "Edit Campaign", name: "editCampaign", value: "No" },
  { id: "Pause Campaign", name: "pauseCampaign", value: "No" },
  { id: "Resume Campaign", name: "resumeCampaign", value: "No" },
  { id: "Complete Campaign", name: "completeCampaign", value: "No" },
];

// const leadActivityForNC = [
//   { id: 'Lead Review', name: 'leadReview', value: 'No' },
//   { id: 'Update Leads Permission', name: 'updateLeadsPermission', value: 'No' },
//   { id: 'Voice Link Upload', name: 'voiceLinkUpload', value: 'No' },
//   { id: 'TDR Report', name: 'tdrReport', value: 'No' },

// ];

const pubActivityForNC = [
  // { id: 'End Date Publisher', name: 'endDatePublisher', value: 'No' },
  { id: "Creative Review", name: "creativeReview", value: "No" },
];

const deadlineForNC = [
  {
    id: "First Delivery Date Cross",
    name: "firstDeliveryDateCross",
    value: "No",
  },
  {
    id: "First Delivery Date Warn",
    name: "firstDeliveryDateWarn",
    value: "No",
  },
  // { id: 'Accept Campaign Warn', name: 'acceptCampaignWarn', value: 'No' },
  { id: "Creatives Upload Warn", name: "creativesUploadWarn", value: "No" },
  // { id: 'Pacing Alert', name: 'pacingAlert', value: 'No' },
  // { id: 'RFP Acknowledgement', name: 'rfpAcknowledgement', value: 'No' },
];
const othersForNC = [
  // { id: 'Daily Update', name: 'dailyUpdate', value: 'No' },
  { id: "Link Agency Publisher", name: "linkAgencyPublisher", value: "No" },
  { id: "New Message", name: "message", value: "No" },
  // { id: 'Add User', name: 'addUser', value: 'No' },
  // { id: 'Invoice Reviewed', name: 'invoiceReviewed', value: 'No' },
];

const leadActivityForQA = [
  // { id: 'Lead Upload', name: 'leadUpload', value: 'No' },
  { id: "Lead Review", name: "leadReview", value: "No" },
  // { id: 'Leads Decrement', name: 'leadsDecrement', value: 'No' },
  { id: "Update Leads Permission", name: "updateLeadsPermission", value: "No" },
  // { id: 'Voice Link Upload', name: 'voiceLinkUpload', value: 'No' },
  // { id: 'TDR Report', name: 'tdrReport', value: 'No' },
];

const pubActivityForQA = [
  // { id: 'Cancel Publisher', name: 'cancelPublisher', value: 'No' },
  // { id: 'End Date Publisher', name: 'endDatePublisher', value: 'No' },
  // { id: 'Re-Allocation Campaign', name: 'reAllocationCampaign', value: 'No' },
  // { id: 'Landing Page Submit', name: 'landingPageSubmit', value: 'No' },
  // { id: 'CS Submit', name: 'csSubmit', value: 'No' },
  // { id: 'POC Submit', name: 'pocSubmit', value: 'No' },
  { id: "Creative Review", name: "creativeReview", value: "No" },
];
const otherPlainNC = othersForNC.map(function(a) {
  return a.name;
});

const campPlainForNC = campActivityForNC.map(function(a) {
  return a.name;
});
const deadlinePlainNC = deadlineForNC.map(function(a) {
  return a.name;
});

// const campData = resultCamp.map(function (a) { return a.name });
// const pubData = resultPub.map(function (a) { return a.name });
// const leadData = resultLead.map(function (a) { return a.name });
// const deadlineData = resultDeadline.map(function (a) { return a.name });
// const otherData = resultOther.map(function (a) { return a.name });
//var data1=JSON.stringify(data)

// const defaultCampChecked = campData;
// const defaultPubChecked = pubData;
// const defaultLeadChecked = leadData;
// const defaultDeadlineChecked = deadlineData;
// const defaultOthersChecked = otherData;

class PublisherEmailConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // campCheckedList: defaultCampChecked,
      // pubCheckedList: defaultPubChecked,
      // leadCheckedList: defaultLeadChecked,
      // deadlineCheckedList: defaultDeadlineChecked,
      // othersCheckedList: defaultOthersChecked,

      // campCheckedList,
      // pubCheckedList,
      // leadCheckedList,
      // deadlineCheckedList,
      // othersCheckedList,

      campIndeterminate: true,
      pubIndeterminate: true,
      leadIndeterminate: true,
      deadlineIndeterminate: true,
      othersIndeterminate: true,

      campIndeterminateForNC: true,
      // campIndeterminateForNC: true, //Nilesh-2897-Removing console warnings
      pubIndeterminateForNC: true,
      leadIndeterminateForNC: true,
      deadlineIndeterminateForNC: true,
      othersIndeterminateForNC: true,

      pubIndeterminateForQA: true,
      leadIndeterminateForQA: true,

      campCheckAll: false,
      pubCheckAll: false,
      leadCheckAll: false,
      deadlineCheckAll: false,
      othersCheckAll: false,
      emailConfigData: [],
      role: "",
    };
  }

  // campCheckedList
  campOnChange = (campCheckedList) => {
    this.setState({
      campCheckedList,
      campIndeterminate:
        !!campCheckedList.length &&
        campCheckedList.length < campActivity.length,
      campCheckAll: campCheckedList.length === campActivity.length,
    });
  };

  campOnChangeForNC = (campCheckedListForNC) => {
    this.setState({
      campCheckedListForNC,
      campIndeterminateForNC:
        !!campCheckedListForNC.length &&
        campCheckedListForNC.length < campActivityForNC.length,
      campCheckAll: campCheckedListForNC.length === campActivityForNC.length,
    });
  };

  campOnCheckAllChange = (e) => {
    this.setState({
      campCheckedList: e.target.checked ? campPlain : [],
      campCheckedListForNC: e.target.checked ? campPlainForNC : [],

      campIndeterminate: false,
      campIndeterminateForNC: false,
      campCheckAll: e.target.checked,
    });
  };

  // pubCheckedList
  pubOnChange = (pubCheckedList) => {
    this.setState({
      pubCheckedList,
      pubIndeterminate:
        !!pubCheckedList.length && pubCheckedList.length < pubActivity.length,
      pubCheckAll: pubCheckedList.length === pubActivity.length,
    });
  };

  pubOnCheckAllChange = (e) => {
    this.setState({
      pubCheckedList: e.target.checked ? pubPlain : [],
      pubIndeterminate: false,
      // pubCheckedListForNC: e.target.checked ? pubPlainNC : [],
      // pubIndeterminateForNC: false,
      pubCheckAll: e.target.checked,
    });
  };

  pubOnChangeForNC = (pubCheckedListForNC) => {
    this.setState({
      pubCheckedListForNC,
      pubIndeterminateForNC:
        !!pubCheckedListForNC.length &&
        pubCheckedListForNC.length < pubActivityForNC.length,
      pubCheckAll: pubCheckedListForNC.length === pubActivityForNC.length,
    });
  };

  pubOnChangeForQA = (pubCheckedListForQA) => {
    this.setState({
      pubCheckedListForQA,
      pubIndeterminateForQA:
        !!pubCheckedListForQA.length &&
        pubCheckedListForQA.length < pubActivityForQA.length,
      pubCheckAll: pubCheckedListForQA.length === pubActivityForQA.length,
    });
  };

  // leadCheckedList
  leadOnChange = (leadCheckedList) => {
    this.setState({
      leadCheckedList,
      leadIndeterminate:
        !!leadCheckedList.length &&
        leadCheckedList.length < leadActivity.length,
      leadCheckAll: leadCheckedList.length === leadActivity.length,
    });
  };

  leadOnCheckAllChange = (e) => {
    this.setState({
      leadCheckedList: e.target.checked ? leadPlain : [],
      leadCheckedListForQA: e.target.checked ? leadPlain : [],
      leadIndeterminate: false,
      leadIndeterminateForQA: false,
      leadCheckAll: e.target.checked,
    });
  };

  leadOnChangeForQA = (leadCheckedListForQA) => {
    this.setState({
      leadCheckedListForQA,
      leadIndeterminateForQA:
        !!leadCheckedListForQA.length &&
        leadCheckedListForQA.length < leadActivityForQA.length,
      leadCheckAll: leadCheckedListForQA.length === leadActivityForQA.length,
    });
  };

  // leadOnCheckAllChange = e => {
  //   this.setState({
  //     leadCheckedList: e.target.checked ? leadPlain : [],
  //     leadIndeterminate: false,
  //     leadCheckAll: e.target.checked,
  //   });
  // };

  // deadlineCheckedList
  deadlineOnChange = (deadlineCheckedList) => {
    this.setState({
      deadlineCheckedList,
      deadlineIndeterminate:
        !!deadlineCheckedList.length &&
        deadlineCheckedList.length < deadline.length,
      deadlineCheckAll: deadlineCheckedList.length === deadline.length,
    });
  };

  deadlineOnCheckAllChange = (e) => {
    this.setState({
      deadlineCheckedList: e.target.checked ? deadlinePlain : [],
      deadlineIndeterminate: false,
      deadlineCheckedListForNC: e.target.checked ? deadlinePlainNC : [],
      deadlineIndeterminateForNC: false,
      deadlineCheckAll: e.target.checked,
    });
  };

  deadlineOnChangeForNC = (deadlineCheckedListForNC) => {
    this.setState({
      deadlineCheckedListForNC,
      deadlineIndeterminateForNC:
        !!deadlineCheckedListForNC.length &&
        deadlineCheckedListForNC.length < deadlineForNC.length,
      deadlineCheckAll:
        deadlineCheckedListForNC.length === deadlineForNC.length,
    });
  };

  // othersCheckedList
  othersOnChange = (othersCheckedList) => {
    this.setState({
      othersCheckedList,
      othersIndeterminate:
        !!othersCheckedList.length && othersCheckedList.length < others.length,
      othersCheckAll: othersCheckedList.length === others.length,
    });
  };

  othersOnCheckAllChange = (e) => {
    this.setState({
      othersCheckedList: e.target.checked ? otherPlain : [],
      othersIndeterminate: false,
      othersCheckedListForNC: e.target.checked ? otherPlainNC : [],
      othersIndeterminateForNC: false,
      othersCheckAll: e.target.checked,
    });
  };

  othersOnChangeForNC = (othersCheckedListForNC) => {
    this.setState({
      othersCheckedListForNC,
      othersIndeterminateForNC:
        !!othersCheckedListForNC.length &&
        othersCheckedListForNC.length < othersForNC.length,
      othersCheckAll: othersCheckedListForNC.length === othersForNC.length,
    });
  };

  /**
   * @author Priyanka Patil
   * @param  Description Adeed function to Refresh the page under task 3874
   */
  handleRefreshPage(e) {
    Swal.fire({
      title: "Are you sure you want to discard the changes?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      allowOutsideClick: false,
      confirmButtonText: "Discard",
    }).then((result) => {
      if (result.value) {
        window.location.href = "/newPublisherDashboard";        //Chaitanya-4291-diverted path of Discard button to Publisher dashboard
      } else if (result.dismiss === "close") {
        window.location.href = "#";
      } else {
        window.location.href = "#";
      }
    });
  }

  handleSubmit = (e) => {
    //camp
    e.preventDefault();

    var finalCampCheckedList = [];
    var data = {};
    var dataForNC = {};
    var dataForQA = {};
    var campCheckedList = this.state.campCheckedList;
    var result2 = campActivity.filter(function(b) {
      var name = b.name.toString();
      return !campCheckedList.includes(name);
    });

    for (var i = 0; i < this.state.campCheckedList.length; i++) {
      let temp = this.state.campCheckedList[i]; 
      data[temp] = "Yes";
    }

    for (let i = 0; i < result2.length; i++) { //Nilesh-2897-Removing console warnings
      var temp = result2[i].name;
      data[temp] = "No";
    }

    //Added by Sonali for PC
    var campCheckedListForNC = this.state.campCheckedListForNC;
    var result2ForNC = campActivityForNC.filter(function(b) {
      var name = b.name.toString();
      return !campCheckedListForNC.includes(name);
    });

    for (let i = 0; i < this.state.campCheckedListForNC.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = this.state.campCheckedListForNC[i]; //Nilesh-2897-Removing console warnings
      dataForNC[temp] = "Yes";
    }

    for (let i = 0; i < result2ForNC.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = result2ForNC[i].name; //Nilesh-2897-Removing console warnings
      dataForNC[temp] = "No";
    }

    //pub
    var pubCheckedList = this.state.pubCheckedList;
     result2 = pubActivity.filter(function(b) { //Nilesh-2897-Removing console warnings
      var name = b.name.toString();
      return !pubCheckedList.includes(name);
    });

    for (let i = 0; i < this.state.pubCheckedList.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = this.state.pubCheckedList[i]; //Nilesh-2897-Removing console warnings
      data[temp] = "Yes";
    }

    for (let i = 0; i < result2.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = result2[i].name; //Nilesh-2897-Removing console warnings
      data[temp] = "No";
    }

    //Added by Sonali for PC
    var pubCheckedListForNC = this.state.pubCheckedListForNC;
     result2ForNC = pubActivityForNC.filter(function(b) { //Nilesh-2897-Removing console warnings
      var name = b.name.toString();
      return !pubCheckedListForNC.includes(name);
    });

    for (let i = 0; i < this.state.pubCheckedListForNC.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = this.state.pubCheckedListForNC[i]; //Nilesh-2897-Removing console warnings
      dataForNC[temp] = "Yes";
    }

    for (let i = 0; i < result2ForNC.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = result2ForNC[i].name; //Nilesh-2897-Removing console warnings
      dataForNC[temp] = "No";
    }

    var pubCheckedListForQA = this.state.pubCheckedListForQA;
    var result2ForQA = pubActivityForQA.filter(function(b) {
      var name = b.name.toString();
      return !pubCheckedListForQA.includes(name);
    });

    for (let i = 0; i < this.state.pubCheckedListForQA.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = this.state.pubCheckedListForQA[i]; //Nilesh-2897-Removing console warnings
      dataForQA[temp] = "Yes";
    }
 
    for (let i = 0; i < result2ForQA.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = result2ForQA[i].name; //Nilesh-2897-Removing console warnings
      dataForQA[temp] = "No";
    }

    var leadCheckedList = this.state.leadCheckedList;
     result2 = leadActivity.filter(function(b) { //Nilesh-2897-Removing console warnings
      var name = b.name.toString();
      return !leadCheckedList.includes(name);
    });

    for (let i = 0; i < this.state.leadCheckedList.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = this.state.leadCheckedList[i]; //Nilesh-2897-Removing console warnings
      data[temp] = "Yes";
    }

    for (let i = 0; i < result2.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = result2[i].name; //Nilesh-2897-Removing console warnings
      data[temp] = "No";
    }

    //Added by Sonali for PC

    var leadCheckedListForQA = this.state.leadCheckedListForQA;
     result2ForQA = leadActivityForQA.filter(function(b) { //Nilesh-2897-Removing console warnings
      var name = b.name.toString();
      return !leadCheckedListForQA.includes(name);
    });

    for (let i = 0; i < this.state.leadCheckedListForQA.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = this.state.leadCheckedListForQA[i]; //Nilesh-2897-Removing console warnings
      dataForQA[temp] = "Yes";
    }

    for (let i = 0; i < result2ForQA.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = result2ForQA[i].name; //Nilesh-2897-Removing console warnings
      dataForQA[temp] = "No";
    }

    //deadline
    var deadlineCheckedList = this.state.deadlineCheckedList;
     result2 = deadline.filter(function(b) { //Nilesh-2897-Removing console warnings
      var name = b.name.toString();
      return !deadlineCheckedList.includes(name);
    });

    for (let i = 0; i < this.state.deadlineCheckedList.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = this.state.deadlineCheckedList[i]; //Nilesh-2897-Removing console warnings
      data[temp] = "Yes";
    }

    for (let i = 0; i < result2.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = result2[i].name; //Nilesh-2897-Removing console warnings
      data[temp] = "No";
    }

    //Added by Sonali for PC

    var deadlineCheckedListForNC = this.state.deadlineCheckedListForNC;
     result2ForNC = deadlineForNC.filter(function(b) { //Nilesh-2897-Removing console warnings
      var name = b.name.toString();
      return !deadlineCheckedListForNC.includes(name);
    });

    for (let i = 0; i < this.state.deadlineCheckedListForNC.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = this.state.deadlineCheckedListForNC[i]; //Nilesh-2897-Removing console warnings
      dataForNC[temp] = "Yes";
    }

    for (let i = 0; i < result2ForNC.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = result2ForNC[i].name; //Nilesh-2897-Removing console warnings
      dataForNC[temp] = "No";
    }

    //other
    var othersCheckedList = this.state.othersCheckedList;
      result2 = others.filter(function(b) {  //Nilesh-2897-Removing console warnings
      var name = b.name.toString();
      return !othersCheckedList.includes(name);
    });

    for (let i = 0; i < this.state.othersCheckedList.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = this.state.othersCheckedList[i]; //Nilesh-2897-Removing console warnings
      data[temp] = "Yes";
    }

    for (let i = 0; i < result2.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = result2[i].name; //Nilesh-2897-Removing console warnings
      data[temp] = "No";
    }

    //Added by sonali for PC
    var othersCheckedListForNC = this.state.othersCheckedListForNC;
      result2ForNC = othersForNC.filter(function(b) {  //Nilesh-2897-Removing console warnings
      var name = b.name.toString();
      return !othersCheckedListForNC.includes(name);
    });

    for (let i = 0; i < this.state.othersCheckedListForNC.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = this.state.othersCheckedListForNC[i]; //Nilesh-2897-Removing console warnings
      dataForNC[temp] = "Yes";
    }

    for (let i = 0; i < result2ForNC.length; i++) { //Nilesh-2897-Removing console warnings
      let temp = result2ForNC[i].name; //Nilesh-2897-Removing console warnings
      dataForNC[temp] = "No";
    }

    if (this.state.role === "PC") {
      finalCampCheckedList.push(data);
    } else if (this.state.role === "PNC") {
      finalCampCheckedList.push(dataForNC);
    } else {
      finalCampCheckedList.push(dataForQA);
    }

    // alert("finalCampCheckedList===" + JSON.stringify(finalCampCheckedList))
    // const { user } = this.props.auth; //Nilesh-2897-Removing console warnings

    let configData = {
      activity: finalCampCheckedList,
    }; // karan-task-3723-vapt header and query params
    fetch("/userConfiguration/emailConfigurationInfo", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(configData),
    })
      .then((res) => res.json())
      .then((response) => {
        Swal.fire({
          text: "Email Configuration Done Successfully",
          type: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          preConfirm: () => {
            window.location.reload();
          },
        });
      });
  };

  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth;
      this.setState({ role: user.role });

      fetch("/userConfiguration/getEmailConfigurationInfo", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }) // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((emailConfigData) => {
          this.setState({ emailConfigData: emailConfigData });

          var tempArray = [];
          // var temp = this.state.emailConfigData.filter(function(obj) {
          //   let keys = Object.keys(obj);
          //   let values = keys.map(function(key) {
          //     var a = obj[key];
          //     if (a == "Yes") {
          //       tempArray.push(key);
          //     }
          //   });
          // });

            //Nilesh-2833-Removing console warnings- in below code add forEach instead of filter
            this.state.emailConfigData.forEach(function(obj) {
              let keys = Object.keys(obj);
              keys.forEach(function(key) {
                var a = obj[key];
                if (a === "Yes") { 
                  tempArray.push(key);
                }
              });
            });
          var resultCamp = campActivity.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultPub = pubActivity.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultLead = leadActivity.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultDeadline = deadline.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultOther = others.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultCampForNC = campActivityForNC.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultPubForNC = pubActivityForNC.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultPubForQA = pubActivityForQA.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultLeadForQA = leadActivityForQA.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultDeadlineForNC = deadlineForNC.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          var resultOtherForNC = othersForNC.filter(function(b) {
            var name = b.name.toString();
            return tempArray.includes(name);
          });

          const campData = resultCamp.map(function(a) {
            return a.name;
          });
          const pubData = resultPub.map(function(a) {
            return a.name;
          });
          const leadData = resultLead.map(function(a) {
            return a.name;
          });
          const deadlineData = resultDeadline.map(function(a) {
            return a.name;
          });
          const otherData = resultOther.map(function(a) {
            return a.name;
          });

          const campDataForNC = resultCampForNC.map(function(a) {
            return a.name;
          });
          const pubDataForNC = resultPubForNC.map(function(a) {
            return a.name;
          });
          // const leadDataForNC = resultLeadForNC.map(function (a) { return a.name });
          const deadlineDataForNC = resultDeadlineForNC.map(function(a) {
            return a.name;
          });
          const otherDataForNC = resultOtherForNC.map(function(a) {
            return a.name;
          });

          const pubDataForQA = resultPubForQA.map(function(a) {
            return a.name;
          });
          const leadDataForQA = resultLeadForQA.map(function(a) {
            return a.name;
          });

          this.setState({
            campCheckedList: campData,
            pubCheckedList: pubData,
            leadCheckedList: leadData,
            deadlineCheckedList: deadlineData,
            othersCheckedList: otherData,

            campCheckedListForNC: campDataForNC,
            pubCheckedListForNC: pubDataForNC,
            // leadCheckedListForNC: leadDataForNC,
            deadlineCheckedListForNC: deadlineDataForNC,
            othersCheckedListForNC: otherDataForNC,

            pubCheckedListForQA: pubDataForQA,
            leadCheckedListForQA: leadDataForQA,
          });

          if (
            this.state.emailConfigData &&
            this.state.emailConfigData.length > 0
          ) {
            for (var i = 0; i < this.state.emailConfigData.length; i++) {
              if (this.state.role === "PC") {
                if (
                  this.state.emailConfigData[i].campaignAllocation === "Yes" &&
                  this.state.emailConfigData[i].editCampaign === "Yes" &&
                  this.state.emailConfigData[i].acceptCampaign === "Yes" &&
                  this.state.emailConfigData[i].pauseCampaign === "Yes" &&
                  this.state.emailConfigData[i].resumeCampaign === "Yes" &&
                  this.state.emailConfigData[i].completeCampaign === "Yes" &&
                  this.state.emailConfigData[i].rejectCampaign === "Yes" &&
                  this.state.emailConfigData[i].counterCampaign === "Yes" &&
                  this.state.emailConfigData[i].counterAcceptCampaign ===
                    "Yes" &&
                  this.state.emailConfigData[i].counterRejectCampaign ===
                    "Yes" &&
                  this.state.emailConfigData[i].biddingAllocation === "Yes" &&
                  this.state.emailConfigData[i].biddingSubmission === "Yes" &&
                  this.state.emailConfigData[i].biddingReview === "Yes"
                ) {
                  this.setState({ campCheckAll: true });
                }
              } else {
                if (
                  this.state.emailConfigData[i].editCampaign === "Yes" &&
                  this.state.emailConfigData[i].pauseCampaign === "Yes" &&
                  this.state.emailConfigData[i].resumeCampaign === "Yes" &&
                  this.state.emailConfigData[i].completeCampaign === "Yes"
                ) {
                  this.setState({ campCheckAll: true });
                }
              }

              if (this.state.role === "PC") {
                if (
                  this.state.emailConfigData[i].endDatePublisher === "Yes" &&
                  this.state.emailConfigData[i].landingPageSubmit === "Yes" &&
                  this.state.emailConfigData[i].csSubmit === "Yes" &&
                  this.state.emailConfigData[i].pocSubmit === "Yes" &&
                  this.state.emailConfigData[i].creativeReview === "Yes"
                ) {
                  this.setState({ pubCheckAll: true });
                }
              } else {
                if (this.state.emailConfigData[i].creativeReview === "Yes") {
                  this.setState({ pubCheckAll: true });
                }
              }

              if (this.state.role === "PC") {
                if (
                  this.state.emailConfigData[i].leadUpload === "Yes" &&
                  this.state.emailConfigData[i].leadReview === "Yes" &&
                  this.state.emailConfigData[i].leadsDecrement === "Yes" &&
                  this.state.emailConfigData[i].updateLeadsPermission === "Yes"
                ) {
                  this.setState({ leadCheckAll: true });
                }
              } else if (this.state.role === "PQA") {
                if (
                  this.state.emailConfigData[i].leadReview === "Yes" &&
                  this.state.emailConfigData[i].updateLeadsPermission === "Yes"
                ) {
                  this.setState({ leadCheckAll: true });
                }
              } else {
                // if(
                //   this.state.emailConfigData[i].leadUpload==="Yes"&&
                //   this.state.emailConfigData[i].leadReview==="Yes"&&
                //   this.state.emailConfigData[i].leadsDecrement==="Yes"&&
                //   this.state.emailConfigData[i].updateLeadsPermission==="Yes"
                //   ){this.setState({leadCheckAll:true})}
              }

              if (this.state.role === "PC") {
                if (
                  this.state.emailConfigData[i].firstDeliveryDateWarn ===
                    "Yes" &&
                  this.state.emailConfigData[i].firstDeliveryDateCross ===
                    "Yes" &&
                  this.state.emailConfigData[i].tdrReport === "Yes" &&
                  this.state.emailConfigData[i].acceptCampaignWarn === "Yes" &&
                  this.state.emailConfigData[i].creativesUploadWarn === "Yes"
                ) {
                  this.setState({
                    deadlineCheckAll: true,
                  });
                }
              } else {
                if (
                  this.state.emailConfigData[i].firstDeliveryDateWarn ===
                    "Yes" &&
                  this.state.emailConfigData[i].firstDeliveryDateCross ===
                    "Yes" &&
                  this.state.emailConfigData[i].creativesUploadWarn === "Yes"
                ) {
                  this.setState({
                    deadlineCheckAll: true,
                  });
                }
              }

              if (this.state.role === "PC") {
                if (
                  this.state.emailConfigData[i].dailyUpdate === "Yes" &&
                  this.state.emailConfigData[i].linkAgencyPublisher === "Yes" &&
                  this.state.emailConfigData[i].message === "Yes" &&
                  this.state.emailConfigData[i].invoiceReviewed === "Yes"
                ) {
                  this.setState({ othersCheckAll: true });
                }
              } else {
                if (
                  this.state.emailConfigData[i].linkAgencyPublisher === "Yes" &&
                  this.state.emailConfigData[i].message === "Yes"
                ) {
                  this.setState({ othersCheckAll: true });
                }
              }
            }
          }
        });
    }
  }

  render() {
    return (
      <div /*style={{ backgroundColor: "rgb(241, 241, 241)" }}*/>   {/*Chaitanya-4291-R29-Prod issue-Publisher-Setting option-Email Notifications-page horizontal line issue*/}
        <Navigation />
        <div
          className="container-fluid"
          id="Container-PUB"
          style={{ paddingTop: "85px", paddingBottom: "60px" }}
        >
          <p style={{ paddingTop: "20px" }}>
            {/* //shivani-3285-passed ID for DI label consistency */}
            <h3 align="center" id="labelDIPUB">
              Email Notification
            </h3>
            <hr style={{ border: "1px solid #e0e0e0" }} />
          </p>
          {/*Priyanka--3874--Changed design of the Page as per new Design */}
          <div class="container-PUB">
            <form className="emailformPUB" onSubmit={this.handleSubmit}>
              <div className="row">
                {this.state.role === "PC" || this.state.role === "PNC" ? (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                    <p>
                      <h4 class="hFourP" style={{ fontFamily: "roboto" }}>
                        Campaign Related Activity Notification
                        <div
                          className="selectAllPub"
                          style={{ marginright: "50px" }}
                        >
                          <Checkbox
                            // indeterminate={this.state.campIndeterminate}
                            onChange={this.campOnCheckAllChange}
                            checked={this.state.campCheckAll}
                          >
                            Select All
                          </Checkbox>
                        </div>
                      </h4>
                    </p>
                    {this.state.role === "PC" ? ( //Nilesh-2897-Removing console warnings
                      <div className="background" id="camp_pc_back">
                        <Checkbox.Group
                          className="camp_pc"
                          id="non_single"
                          style={{
                            height: "110px",
                          }}
                          options={campActivity.map((campActivity) => ({
                            label: campActivity.id,
                            value: campActivity.name,
                          }))}
                          value={this.state.campCheckedList}
                          onChange={this.campOnChange}
                        />
                      </div>
                    ) : (
                      <div className="background" id="camp_pnc_back">
                        <Checkbox.Group
                          className="camp_pnc"
                          id="single"
                          style={{
                            height: "50px",
                          }}
                          options={campActivityForNC.map(
                            (campActivityForNC) => ({
                              label: campActivityForNC.id,
                              value: campActivityForNC.name,
                            })
                          )}
                          value={this.state.campCheckedListForNC}
                          onChange={this.campOnChangeForNC}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/*End of 1st Row*/}

              <br />
              <br />

              <div class="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                  <p>
                    <h4 class="hFourP" style={{ fontFamily: "roboto" }}>
                      Publisher Related Activity Notification
                      <div className="selectAllPub">
                        {this.state.role === "PQA" ||
                        this.state.role === "PNC" ? (
                          ""
                        ) : (
                          <Checkbox
                            // indeterminate={this.state.pubIndeterminate}
                            onChange={this.pubOnCheckAllChange}
                            checked={this.state.pubCheckAll}
                          >
                            Select All
                          </Checkbox>
                        )}
                      </div>
                    </h4>
                  </p>

                  {this.state.role === "PC" ? (
                    <div className="background" id="pub_pc_back">
                      <Checkbox.Group
                        className="pub_pc"
                        id="non_single"
                        style={{
                          height: "50px",
                        }}
                        options={pubActivity.map((pubActivity) => ({
                          label: pubActivity.id,
                          value: pubActivity.name,
                        }))}
                        value={this.state.pubCheckedList}
                        onChange={this.pubOnChange}
                      />
                    </div>
                  ) : this.state.role === "PNC" ? (
                    <div className="background" id="pub_pnc_back">
                      <Checkbox.Group
                        className="pub_pnc"
                        id="single"
                        style={{
                          height: "25px",
                        }}
                        options={pubActivityForNC.map((pubActivityForNC) => ({
                          label: pubActivityForNC.id,
                          value: pubActivityForNC.name,
                        }))}
                        value={this.state.pubCheckedListForNC}
                        onChange={this.pubOnChangeForNC}
                      />
                    </div>
                  ) : (
                    <div className="background" id="pub_pqa_back">
                      <Checkbox.Group
                        className="pub_pqa"
                        id="single"
                        style={{
                          height: "25px",
                        }}
                        options={pubActivityForQA.map((pubActivityForQA) => ({
                          label: pubActivityForQA.id,
                          value: pubActivityForQA.name,
                        }))}
                        value={this.state.pubCheckedListForQA}
                        onChange={this.pubOnChangeForQA}
                      />
                    </div>
                  )}

                  {/* <Checkbox.Group style={{ width: '100%' , lineHeight: '30px'}} onChange={this.pubOnChange}>
												<Row>
												<Col span={12}>
													<Checkbox value="cancelPublisher">Cancel Publisher</Checkbox>
												</Col>
												
												<Col span={12}>
													<Checkbox value="endDatePublisher">End Date Publisher</Checkbox>
												</Col>
												<Col span={12}>
													<Checkbox value="cancelPublisherCross">Cancel Publisher Cross</Checkbox>
												</Col>
												<Col span={12}>
													<Checkbox value="pocSubmit">POC Submit</Checkbox>
												</Col>
												<Col span={12}>
													<Checkbox value="csSubmit">CS Submit</Checkbox>
												</Col>
												<Col span={12}>
													<Checkbox value="pocReview">POC Review</Checkbox>
												</Col>
												<Col span={12}>
													<Checkbox value="lpSubmit">Landing Page Submit</Checkbox>
												</Col>
												<Col span={12}>
													<Checkbox value="creativeReview">Creative Review</Checkbox>
												</Col>
												<Col span={12}>
													<Checkbox value="reportPublisher">Report Publisher</Checkbox>
												</Col>
												<br/>
												</Row>
											</Checkbox.Group> */}
                </div>
              </div>
              {/* end of 2nd Row */}

              <br />
              <br />

              <div className="row">
                {this.state.role === "PC" || this.state.role === "PQA" ? (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                    <p>
                      <h4 class="hFourP" style={{ fontFamily: "roboto" }}>
                        Lead Related Activity Notification
                        <div className="selectAllPub">
                          {this.state.role === "PQA" ? (
                            ""
                          ) : (
                            <Checkbox
                              // indeterminate={this.state.leadIndeterminate}
                              onChange={this.leadOnCheckAllChange}
                              checked={this.state.leadCheckAll}
                            >
                              Select All
                            </Checkbox>
                          )}
                        </div>
                      </h4>
                    </p>

                    {this.state.role === "PC" ? (
                      <div className="background" id="lead_pc_back">
                        <Checkbox.Group
                          className="lead_pc"
                          id="single"
                          style={{
                            height: "100%",
                          }} //mufiz
                          options={leadActivity.map((leadActivity) => ({
                            label: leadActivity.id,
                            value: leadActivity.name,
                          }))}
                          value={this.state.leadCheckedList}
                          onChange={this.leadOnChange}
                        />
                      </div>
                    ) : this.state.role === "PQA" ? (
                      <div className="background" id="lead_pqa_back">
                        <Checkbox.Group
                          className="lead_pqa"
                          id="single"
                          style={{
                            height: "25px",
                          }}
                          options={leadActivityForQA.map(
                            (leadActivityForQA) => ({
                              label: leadActivityForQA.id,
                              value: leadActivityForQA.name,
                            })
                          )}
                          value={this.state.leadCheckedListForQA}
                          onChange={this.leadOnChangeForQA}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <Checkbox.Group style={{ width: '100%', lineHeight: '30px'}}
														options={leadActivity}
														value={this.state.leadCheckedList}
														onChange={this.leadOnChange}
																			/> */}
                    {/* <Checkbox.Group style={{ width: '100%', lineHeight: '30px' }} onChange={this.leadOnChange}>
													<Row>
														<Col span={24}>
														<Checkbox value="leadUpload">Lead Upload</Checkbox>
														</Col>
														<Col span={24}>
														<Checkbox value="leadReview">Lead Review</Checkbox>
														</Col>
														<Col span={24}>
														<Checkbox value="leadsDecrement">Leads Decrement</Checkbox>
														</Col>
													
														<Col span={24}>
														<Checkbox value="updateLeadsPermission">Update Leads Permission</Checkbox>
														</Col>
													</Row>
													</Checkbox.Group>  */}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/*End of 3rd row*/}

              <br />
              <br />

              <div class="row">
                {this.state.role === "PQA" ? (
                  ""
                ) : (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                    <p>
                      <h4 class="hFourP" style={{ fontFamily: "roboto" }}>
                        Deadline Notification
                        <div className="selectAllPub">
                          <Checkbox
                            // indeterminate={this.state.deadlineIndeterminate}
                            onChange={this.deadlineOnCheckAllChange}
                            checked={this.state.deadlineCheckAll}
                          >
                            Select All
                          </Checkbox>
                        </div>
                      </h4>
                    </p>

                    {this.state.role === "PC" ? (
                      <div className="background" id="dead_pc_back">
                        <Checkbox.Group
                          className="dead_pc"
                          id="non_single"
                          style={{
                            height: "50px",
                          }}
                          options={deadline.map((deadline) => ({
                            label: deadline.id,
                            value: deadline.name,
                          }))}
                          value={this.state.deadlineCheckedList}
                          onChange={this.deadlineOnChange}
                        />
                      </div>
                    ) : (
                      <div className="background" id="dead_pnc_back">
                        <Checkbox.Group
                          className="dead_pnc"
                          id="single"
                          style={{
                            height: "25px",
                          }}
                          options={deadlineForNC.map((deadlineForNC) => ({
                            label: deadlineForNC.id,
                            value: deadlineForNC.name,
                          }))}
                          value={this.state.deadlineCheckedListForNC}
                          onChange={this.deadlineOnChangeForNC}
                        />
                      </div>
                    )}
                    {/* <Checkbox.Group style={{ width: '100%', lineHeight: '30px' }}
													options={deadline}
													value={this.state.deadlineCheckedList}
													onChange={this.deadlineOnChange}
												/> */}

                    {/* <Checkbox.Group style={{ width: '100%',height: '100%', lineHeight: '30px' }} onChange={this.deadlineOnChange}>
												<Row>
													<Col span={24}>
													<Checkbox value="firstDeliveryWarn">First Delivery Warn</Checkbox>
													</Col>
													<Col span={24}>
													<Checkbox value="firstDeliveryDate">First Delivery Date Cross</Checkbox>
													</Col>
													<Col span={24}>
													<Checkbox value="acceptCampaignWarn">Accept Campaign Warn</Checkbox>
													</Col>
													
													<Col span={24}>
													<Checkbox value="creativesUploadWarn">Creatives Upload Warn</Checkbox>
													</Col>
												</Row>
												</Checkbox.Group> */}
                  </div>
                )}
              </div>
              {/*End of 4th row*/}
              <br />
              <br />

              <div class="row">
                {this.state.role === "PQA" ? (
                  ""
                ) : (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                    <p>
                      <h4 class="hFourP" style={{ fontFamily: "roboto" }}>
                        Other Notification
                        <div className="selectAllPub">
                          <Checkbox
                            // indeterminate={this.state.othersIndeterminate}
                            onChange={this.othersOnCheckAllChange}
                            checked={this.state.othersCheckAll}
                          >
                            Select All
                          </Checkbox>
                        </div>
                      </h4>
                    </p>

                    {this.state.role === "PC" ? (
                      <div className="background" id="other_pc_back">
                        <Checkbox.Group
                          className="other_pc"
                          id="non_single"
                          style={{
                            height: "50px",
                          }}
                          options={others.map((others) => ({
                            label: others.id,
                            value: others.name,
                          }))}
                          value={this.state.othersCheckedList}
                          onChange={this.othersOnChange}
                        />
                      </div>
                    ) : (
                      <div className="background" id="other_pnc_back">
                        <Checkbox.Group
                          className="other_pnc"
                          id="single"
                          style={{
                            height: "25px",
                          }}
                          options={othersForNC.map((othersForNC) => ({
                            label: othersForNC.id,
                            value: othersForNC.name,
                          }))}
                          value={this.state.othersCheckedListForNC}
                          onChange={this.othersOnChangeForNC}
                        />
                      </div>
                    )}
                    {/* <Checkbox.Group style={{ width: '100%', lineHeight: '30px' }}
											options={others}
											value={this.state.othersCheckedList}
											onChange={this.othersOnChange}
										/> */}
                    {/* <Checkbox.Group style={{ width: '100%', lineHeight: '30px' }} onChange={this.otherOnChange}>
										<Row>
											<Col span={24}>
											<Checkbox value="dailyUpdate">Daily Update</Checkbox>
											</Col>
											<Col span={24}>
											<Checkbox value="linkAgencyPublisher">Link Agency Publisher</Checkbox>
											</Col>
											<Col span={24}>
											<Checkbox value="voiceLinkUpload">Voice Link Upload</Checkbox>
											</Col>
											<Col span={24}>		
											<Checkbox value="newMessage">New Message</Checkbox>
											</Col>
										</Row>
										</Checkbox.Group> */}
                  </div>
                )}
              </div>
              {/* end od 5th row */}
              <br />
              <div class="row">
                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-12 col-xl-10">
                  <button
                    type="primary"
                    htmltype="submit"
                    id="submitBtn-1"
                    class="btn add-button"
                    style={{ float: "right" }}
                  >
                    Submit
                  </button>

                  <button
                    type="button"
                    data-dismiss="modal"
                    class="can-btn-email-1"
                    style={{ float: "right", border: "solid #4893d4" }}
                    onClick={this.handleRefreshPage}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* end of container */}
        <Footer />
      </div>
    );
  }
}

PublisherEmailConfig.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(PublisherEmailConfig));

//export default PublisherEmailConfig
