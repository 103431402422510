
/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
under story-3417Report Engine-Publisher View-Lead Tracking Report-FE
*/
/**
* @file leadStatus
* @author   Snehal More
* @section DESCRIPTION Maintain all status for reports page
*
*/
export const properties = {
accepted : "Accepted",
clientAccepted : "Advertiser Accepted",
rejectedDB : "REJECTED",
clientRejected : "Advertiser Rejected",
agencyInternalReview:"AgencyInternalReview",
agencyQARejected:"agencyQARejected",
acceptedDI : "DI QA ACCEPTED",
rejectedDI : "DI QA REJECTED",
QA_Review :"QA Review",
DI_Review : "DI QA Review",
};