/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Sanjana Godbole
@Creation Date:26-02-2019
@Description:UI for  Publisher wise campaign report  under Agency view
*/
import React from "react";
import Navigation from "../layouts/navPage";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import ReactToExcel from 'react-html-table-to-excel';
 import { Table} from 'mdbreact';
import Footer from '../layouts/footer';
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";

class ByCampaignEndDate extends React.Component {
    constructor() {
        super();
        this.state = {
          newsuccess:"",
          pubName:[],
          currentDate:'',
          date:'',
          fileName:'CampaignDetails',
          startDate:'',
          endDate:'',
          selectedpublisherID:'',
          campEndDate:[],
          campEndDate1: [],
          showTable:'false',
          disableButton:'',
          tableStatus: '',
          fields: {},
          errors: {},
          dateerrors:{},
          puberror:{},
          publisherName1:'true',
          startDate1:'true',
          endDate1:'true',
          btnDisable:'disabled1 add-button',
        
          
        }
        this.handleChange = this.handleChange.bind(this);
        this.showCampDetails = this.showCampDetails.bind(this);
      // this.downloadFile = this.downloadFile.bind(this);
      this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
      this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    }// end of constrcutor


    handleChangeStartDate(e) 
    {
  
              let fields = this.state;
              fields[e.target.name] = e.target.value;

              
              const tableStatus=this.state.showTable;
              const doesShow=this.state.showTable;
    
      if(this.state.startDate1 ==='false')
      {
        this.setState({
          inputClassSdate: "valid", fields,inputClassEdate: "valid", fields
        
      });
      }
      else{
        this.setState({
          fields
      });    
    }
      
            
            var todate=document.getElementById('startDate').value;
            // alert(todate);
            // document.getElementById("endDate").value=todate;
            //this.setState({ endDate:todate});
          // alert("end date=="+this.state.endDate);

            if(this.state.endDate=="" || this.state.endDate!=="")
            {
              
              this.setState({ endDate:todate});
              
                           
            }
        
      }// handleChange(e)


      handleChangeEndDate(e) 
      {
    
                let fields = this.state;
                fields[e.target.name] = e.target.value;
                
                const tableStatus=this.state.showTable;
                const doesShow=this.state.showTable;
        
        
                if(this.state.endDate1 ==='false')
                {
                  this.setState({
                    inputClassEdate: "valid", fields
                  
                });
                }
                else{
                  this.setState({
                    fields
                });    
              }
              
              var todate=document.getElementById('startDate').value;
              // alert(todate);
              // document.getElementById("endDate").value=todate;
              //this.setState({ endDate:todate});
            // alert("end date=="+this.state.endDate);
  
              if(this.state.endDate!=="")
              {
                this.setState({ endDate:this.state.endDate});
              }
          
        }// handleChange(e)


    handleChange(e) 
    {
  
              let fields = this.state;
              fields[e.target.name] = e.target.value;
              // alert(fields[e.target.name])
              const tableStatus=this.state.showTable;
              const doesShow=this.state.showTable;
      
      
              if(this.state.publisherName1 ==='false')
              {
                this.setState({
                  inputClass5: "valid",
                 
                
              });
              }
              else{
                this.setState({
                  fields
              });    
            }
            this.setState({fields});
            this.setState({ fields, tableStatus: true });
            
           
            
      }// handleChange(e)


      // downloadFile(){
      //   let data = {
      //     startDate: this.state.fields.startDate,
      //     endDate: this.state.fields.endDate
      //   };
      //   fetch("/publisher/excelData", {
      //     method: "POST",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify(data)
      //   }).then(res => res.json())
      //     .then(campEndDate1 =>
      //       this.setState({ campEndDate1: campEndDate1 })
      //     );
       

      // }
  showCampDetails(e) {
    // alert(e);
    const doesShow=this.state.showTable;
    e.preventDefault();
    e.target.className += " was-validated";
    const { isAuthenticated, user } = this.props.auth;

// alert(this.state.fields.publisherName);
    /*
    @author:Somnath Keswad
    @Creation Date:05-03-2019
    @Description:return the selected publisher data from database
    */
      let data = {
      startDate: this.state.fields.startDate,
      endDate: this.state.fields.endDate,
      agencyID:user.id,
      selectedpublisherID:this.state.fields.publisherName
    };
    let newsuccess={};
   
    fetch("/reports/showCampEndDate",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
      }).then(res => res.json())
      .then(campEndDate =>{
        this.setState({ campEndDate: campEndDate })
        // alert("publissher===>"+this.state.publisherName);
        // alert("start date===>"+this.state.startDate);
        // alert("end date==>"+this.state.endDate);
          if((this.state.publisherName ==undefined) || (this.state.startDate=="") || (this.state.endDate=="") || (this.state.endDate<this.state.startDate))
        {
            this.setState({
            newsuccess:'',
            
           
          })
        }
         else if (this.state.campEndDate==undefined ||this.state.campEndDate=='')
        {
          //  alert("no data");
          newsuccess["records"]="No data exists";
            this.setState(
              { newsuccess:newsuccess,
                btnDisable:'disabled1 add-button',
               errors:'',
            
              
              });
        }
       
            else
          {
            this.setState(
              {  
                btnDisable:'btn add-button',
              
                
              });
          }
      }).catch(function (err) {
        console.log(err)
      });



   

    if (this.validateForm() === false) { 
    //  alert("Form Not Valid");
     
      

      return;
    }
    else {

      if (this.state.tableStatus === true) {
       
        this.setState({
          showTable: true,
          // disableButton:'',
          errors: '',
     
      
        });
      }
    } // else


  }// end of showCampDetails

  // @author:Sanobar Golandaj
  // @Description:Validations for required fields
  // */
 validateForm()
 {
    let IsValid = true;
    let fields = this.state.fields;
    let errors = {};
   let dateerrors={};
    // let newsuccess={};
    let puberror ={};
    let formIsValid = true;
    if ((!fields["startDate"]) || (!fields["endDate"]) || (!fields["publisherName"]))
       {
      formIsValid = false;
      errors["commonError"] = <li>Please fill below required fields.</li>;
    }
 

    if (this.state.startDate !== undefined)
    {
      // alert("green");
      this.setState({
        inputClassSdate: "valid"
      })
    }
 
   
    if (this.state.startDate == "")
    {
      this.state.startDate1 ='false';
      // alert("red");
      this.setState({
        inputClassSdate: "invalid"
      })
    }
   


    if (this.state.endDate!== undefined)
    {
     
      // alert("green");
      this.setState({
        inputClassEdate: "valid"
      })
    }
 
   
    if (this.state.endDate== "")
    {
      this.state.endDate1 ='false';
      // alert("red");
      this.setState({
        inputClassEdate: "invalid"
      })
    }
   

    if(typeof fields["publisherName"] == "undefined")
    {
      this.state.publisherName1 ='false';
      // alert("enter pub");
      this.setState({
        inputClass5: "invalid"
      })
    }

    if(typeof fields["publisherName"] !== "undefined")
    {
      // alert("present pub");
      this.setState({
        inputClass5: "valid"
      })
    }

    

        // Validation for End Date(Start Date less than End Date)
        if ((Date.parse(this.state.endDate) < Date.parse(this.state.startDate))) {
          // alert("Inside if Date Validation");  
          
          formIsValid = false;
          dateerrors["endDate"]= <li>End date must be greater than start date</li>; 
           
         // alert("end date greater than start");

         
         }
 
        
        
 
  this.setState(
    
    { errors: errors,
      dateerrors:dateerrors,
      newsuccess:'',
      showTable:false,
      alertBorder: '1px solid red',
      alertWidth: '1000px',
      alertAlign: 'center',
      alertDisplay: 'block'
    });


        
  return formIsValid;
 }// end of validate

 componentDidMount(){

   if (!this.props.auth.isAuthenticated) {
     this.props.history.push('/userLogin');
   }
   else{
   const { isAuthenticated, user } = this.props.auth;
   var agencyID=user.id;
   let self = this;
   fetch("reports/selectPublisher?agencyID="+agencyID)
     .then(res => res.json())
     .then(pubName =>
       self.setState({pubName:pubName})
     ).catch(function (err) {
      console.log(err)
    });

  }
} // end of componentDidMount()
    render()
    {
//moment(<your Date goes here>).format("DD-MMM-YYYY")

      const { isAuthenticated, user } = this.props.auth;
      

      const progress=
      {

         marginBottom:'0px'
    }

        const asterisk =
        {

          color: 'red',

        }

       

     
    /*
    @author:Somnath Keswad
    @Creation Date:05-03-2019
    @Description:return the current Date and Time
    */
      var date = new Date().getDate(); //Current Date
      var month = new Date().getMonth() + 1; //Current Month
      var year = new Date().getFullYear(); //Current Year
      var hours = new Date().getHours(); //Current Hours
      var min = new Date().getMinutes(); //Current Minutes
      var sec = new Date().getSeconds(); //Current Seconds
      var currentDate=  date + '/' + month + '/' + year + ' ' + hours + ':' + min + ':' + sec;

     
            return(
                    <div>
                     <Navigation />
                <form method="" name="" onSubmit={this.showCampDetails}  className="needs-validation" encType="multipart/form-data"  noValidate> 
                  <div class="container-fluid" style={{ paddingTop: '90px', marginBottom: '60px' }}>
                    {/* <div className="card card-signin my-1"> */}
                    {/* <div className="card-body"> */}
                    <div style={{fontSize:'16px',color:'red',fontWeight:'600',textAlign:'center'}}>
                    {this.state.errors.commonError}
                    
                   
                    </div> 
                    <br/>
                    
                  



                    {/* start */}
                    <MDBRow lg="12">
                  

            
                    <div class="col-lg-3">
                                <div>
                                  <label id="label" style={{ fontSize: "16px",fontWeight:'20' }}>Campaign Ending From<span style={asterisk}>*</span></label>   
                                 </div> 
                                  <div>
                                  <input type="date" id="startDate"  style={{width:'160px'}}name="startDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                    value={this.state.startDate} className={this.state.inputClassSdate}
                                    onChange={this.handleChangeStartDate}
                                    class="form-control" min=""
                                    required/>
                                    </div>
                                    <div>
                                  <span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span>
                                  </div>
                                  <span style={asterisk}> {this.state.errors.startDate} </span>
                            </div>
                         
                 
                
                            <div class="col-lg-2">
                                <div>
                                  <label id="label" style={{ fontSize: "16px" }} >To<span style={asterisk}>*</span></label>
                                  
                                  <div>
                                  <input type="date" id="endDate" style={{ width: '160px' }} name="endDate" min="" required pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                    value={this.state.endDate} className={this.state.inputClassEdate}
                                    onChange={this.handleChangeEndDate}
                                    class="form-control"
                                    required />
                                  </div>
                                   
                                <span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span>
                                </div>
                                  <span style={asterisk}> {this.state.dateerrors.endDate} </span>
                                </div>

                                
                            <div class="col-lg-2">
                            <div>
                                        <label id="label"  style={{ fontSize: "16px" }}>Publisher Name <span style={asterisk}>*</span></label></div>
                                        <div>
                                        <select required id="publisherName" name="publisherName" 
                                      value={this.state.publisherName} className={this.state.inputClass5}
                                                          onChange={this.handleChange} 
                                                          class="form-control" required
                                                          >
                                                        <option value="select Publisher ID"  disabled="disabled" selected>Select Publisher Name</option>
                                                         {this.state.pubName.map(pubName => (
                                <option value={pubName.pID} id="pubID" name={pubName.publisherName} >{pubName.publisherName}</option>
                                // <option hidden value={pubName.publisherName}></option>
                                ))} 
                                                        </select>
                                                        </div>
                                                        {/* <span style={asterisk}> {this.state.errors.publisherName} </span> */}
                                                        </div>
                              <div class="col-lg-1" > 
                                <label>
                                  
                                </label>
                                </div>
                          
                                  <div class="col-lg-2">
                            <div class="col" style={{ fontSize: "18px" }}>
                              <label></label>
                            </div>
                                
                          
                            &nbsp;&nbsp;
                          
                            <button class="btn text-uppercase add-button"style={{float:'left',backgroundColor:"#2196F3",color:"white"}} disabled={this.state.disableButton} onClick={this.showCampDetails} type="submit" >
                              Search
                           </button>
                            </div>
                           
                            <div class="col">
                            <div class="col" style={{ fontSize: "18px" }}>
                         <label></label>
                           </div>
                            <div class="col" style={{ fontSize: "18px" }} class="float-right">
                         <label></label>
                           </div>
                           {/* <ReactToExcel className={this.state.btnDisable} class={this.state.btndownload} */}
                            <ReactToExcel  className={this.state.btnDisable} 
                          
                             table="myTable" filename={this.state.fileName + '_' 
                            + this.state.fields.startDate + '_'+this.state.fields.endDate}
                             sheet='sheet1' buttonText="Download"></ReactToExcel>

                            </div>
                            

                        
                    </MDBRow>

                   

                    
                   
                        <div style={{fontSize:'16px',color:'red',fontWeight:'600',textAlign:'center'}}>
                          {this.state.newsuccess.records}
                        </div>


                         {this.state.showTable === true?
                         <div id="campReport" class="row">

                         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                              <center>
                                <label style={{  float: 'center' }}> {this.state.publisher}</label>
                              </center>
                              <div className="card card-signin my-1" >
                                <div className="card-body">
                             
                                  <div class="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="table-responsive" >
                                      <Table scrollY  className=" table table-bordered table-striped "
                                        id="myTable"
                                      >
                                        <thead>
                                          <tr class="custom-class">
                                            <th>Publisher Name</th>
                                            <th>Campaign ID</th>
                                            <th>Allocation ID</th>
                                            <th>Agency Camp ID</th>
                                            <th>Campaign Name</th>
                                            <th style={{ width: "8%" }}>Start  Date</th>
                                            <th style={{ width:"8%"}}>End   Date</th>
                                            <th >Lead Allocation</th>
                                            <th>Allocated Leads</th>
                                            <th>Delivered Leads</th>
                                            <th>Accepted Leads</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.campEndDate.map(campEndDate => (
                                            <tr>
                                            <td>{campEndDate.publisherName}</td>
                                            <td class="campId">{campEndDate.parentCampID?campEndDate.parentCampID:campEndDate.campID}</td>
                                            <td> {campEndDate.reallocationID}</td>
                                            <td> {campEndDate.clientCampID}</td>
                                            <td> {campEndDate.campaignName}</td>
                                            <td>{campEndDate.startDate}</td>
                                            <td>{campEndDate.endDate}</td>
                                            <td>{campEndDate.leadAllocation}</td>
                                            <td>{campEndDate.allocatedLead ? campEndDate.allocatedLead:'0'}</td>
                                            <td>{((campEndDate.qaReviewLead?campEndDate.qaReviewLead:0)+(campEndDate.acceptedLead?campEndDate.acceptedLead:0)+
                                             (campEndDate.rejectedLead?campEndDate.rejectedLead:0) )}</td>
                                            <td>{campEndDate.acceptedLead ? campEndDate.acceptedLead:'0'}</td>
                                            
                                            </tr>
                                               ))}
                                        </tbody>
                                        </Table>
                                      
                                    </div>
                                    {/*/ enf og lg-12*/}
                                  </div>{/*end of row*/}
                                  </div>
                                </div>{/*end of card-body*/}
                              </div>{/*end of card card-signin my-1*/}
                         </div>
                         </div>:null}

                     
                  {/* </div>Card-body */}
{/* // </div>Card */}
</div>{/*Container */}
 </form> 
 <Footer />
</div>
            );
    }// end of renders
}// end of class


/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
ByCampaignEndDate.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps, { logoutUser })(withRouter(ByCampaignEndDate));

