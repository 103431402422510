/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Shende
 *@fileName :requestForProposal.js
 *Desc: Digital signature for accepting campaign.
 */

import React from "react";
import * as $ from "jquery"; 
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { MDBRow } from "mdbreact"; // rutuja 2832 removed MDBCol as never used in the file
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
//import Picky from "react-picky"; // rutuja 2832 commented picky as never used in the file
import "react-picky/dist/picky.css";
import { Checkbox, Tooltip, Table, Button, Input } from "antd";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Sonali-task-3716-added default header
import Highlighter from "react-highlight-words";
import { css } from "emotion";
import { FaArrowAltCircleLeft } from "react-icons/fa";
const Swal = require("sweetalert2");
const queryString = require("query-string");

const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "12px",
		fontWeight: "500",
		height: "80px",
	},
	"& thead > tr >th": {
		border: "1px solid black",
		color: "white",
	},
});

var quickFileLength = 0;
var suppressionnames = [];
var newsuppressionfilenames = [];
var othernames = [];
var otherValidationArray = [];
var successOtherCount = [],
	rejectOtherCount = [];
var newotherfilenames = [];
var count = 0;
var btnShow;
	
	/*  rutuja 2832 commented below var as never used in this file
let leadAllocationValidate = true;
var allassets = true;
var allassetLength = false;
var allLinkLength = false;
var linkflag = false;
var assetflag = false;
var linkflagBack = false;
var assetflagBack = false;
var assetLength;
var assetLinkLength;
var leadperassetCount = 0;
var leadperassetlinkCount = 0;
var abmnames = [];
var newabmfilenames = [];
var a = [];
var execlusionnames = [];
var newexclusionfilenames = [];
var assetnames = [];
var newassetfilenames = [];
var inputType = [];
var key = 0;
var result = [];
var linkSuppCount = 0;
var oldABMFileArray = [];
var assetValidationArray = []; // store the rejected asset name in this array which come from Backend for validte true or false
var exclusionValidationArray = []; // store the rejected Exclusion name in this array which come from Backend for validte true or false
var suppValidationArray = [];
var successAssetCount = [];
var rejectAssetCount = [];
var successExclusionCount = [];
var rejectExclusionCount = [];
var successAbmCount = [];
var rejectAbmCount = [];
var successSuppressionCount = [];
var rejectSuppressionCount = [];

const CountriesArray1 = [
	{ id: "Afghanistan", name: "Afghanistan" },
	{ id: "Albania", name: "Albania" },
	{ id: "Algeria", name: "Algeria" },
	{ id: "American Samoa", name: "American Samoa" },
	{ id: "Andorra", name: "Andorra" },
	{ id: "Angola", name: "Angola" },
	{ id: "Anguilla", name: "Anguilla" },
	{ id: "Antigua and Barbuda", name: "Antigua and Barbuda" },
	{ id: "Argentina", name: "Argentina" },
	{ id: "Armenia", name: "Armenia" },
	{ id: "Aruba", name: "Aruba" },
	{ id: "Australia", name: "Australia" },
	{ id: "Austria", name: "Austria" },
	{ id: "Azerbaijan", name: "Azerbaijan" },
	{ id: "Bahamas", name: "Bahamas" },
	{ id: "Bahrain", name: "Bahrain" },
	{ id: "Bangladesh", name: "Bangladesh" },
	{ id: "Barbados", name: "Barbados" },
	{ id: "Belarus", name: "Belarus" },
	{ id: "Belgium", name: "Belgium" },
	{ id: "Belize", name: "Belize" },
	{ id: "Benin", name: "Benin" },
	{ id: "Bermuda", name: "Bermuda" },
	{ id: "Bhutan", name: "Bhutan" },
	{ id: "Bolivia", name: "Bolivia" },
	{ id: "Bosnia and Herzegovina", name: "Bosnia and Herzegovina" },
	{ id: "Botswana", name: "Botswana" },
	{ id: "Brazil", name: "Brazil" },
	{ id: "British Virgin Islands", name: "British Virgin Islands" },
	{ id: "Brunei", name: "Brunei" },
	{ id: "Bulgaria", name: "Bulgaria" },
	{ id: "Burkina Faso", name: "Burkina Faso" },
	{ id: "Burundi", name: "Burundi" },
	{ id: "CaboVerde", name: "CaboVerde" },
	{ id: "Cambodia", name: "Cambodia" },
	{ id: "Cameroon", name: "Cameroon" },
	{ id: "Canada", name: "Canada" },
	{ id: "Canary Islands", name: "Canary Islands" },
	{ id: "Cayman Islands", name: "Cayman Islands" },
	{ id: "Central African Republic", name: "Central African Republic" },
	{ id: "Chad", name: "Chad" },
	{ id: "Chile", name: "Chile" },
	{ id: "China", name: "China" },
	{ id: "Colombia", name: "Colombia" },
	{ id: "Comoros", name: "Comoros" },
	{ id: "Congo, Dem Rep of the", name: "Congo, Dem Rep of the" },
	{ id: "Congo", name: "Congo" },
	{ id: "Cook Islands", name: "Cook Islands" },
	{ id: "Costa Rica", name: "Costa Rica" },
	{ id: "Cote d Ivoire", name: "Cote d Ivoire" },
	{ id: "Croatia", name: "Croatia" },
	{ id: "Cuba", name: "Cuba" },
	{ id: "Cyprus", name: "Cyprus" },
	{ id: "Czech Republic", name: "Czech Republic" },
	{ id: "Denmark", name: "Denmark" },
	{ id: "Djibouti", name: "Djibouti" },
	{ id: "Dominica", name: "Dominica" },
	{ id: "Dominican Republic", name: "Dominican Republic" },
	{ id: "Ecuador", name: "Ecuador" },
	{ id: "Egypt", name: "Egypt" },
	{ id: "El Salvador", name: "El Salvador" },
	{ id: "Equatorial Guinea", name: "Equatorial Guinea" },
	{ id: "Eritrea", name: "Eritrea" },
	{ id: "Estonia", name: "Estonia" },
	{ id: "Eswatini", name: "Eswatini" },
	{ id: "Ethiopia", name: "Ethiopia" },
	{ id: "Fiji", name: "Fiji" },
	{ id: "Finland", name: "Finland" },
	{ id: "France", name: "France" },
	{ id: "French Guiana", name: "French Guiana" },
	{ id: "French Polynesia", name: "French Polynesia" },
	{ id: "Gabon", name: "Gabon" },
	{ id: "Gambia", name: "Gambia" },
	{ id: "Georgia", name: "Georgia" },
	{ id: "Germany", name: "Germany" },
	{ id: "Ghana", name: "Ghana" },
	{ id: "Gibraltar", name: "Gibraltar" },
	{ id: "Greece", name: "Greece" },
	{ id: "Greenland", name: "Greenland" },
	{ id: "Grenada", name: "Grenada" },
	{ id: "Guadeloupe", name: "Guadeloupe" },
	{ id: "Guam", name: "Guam" },
	{ id: "Guatemala", name: "Guatemala" },
	{ id: "Guinea", name: "Guinea" },
	{ id: "Guinea-BissauGuyana", name: "Guinea-BissauGuyana" },
	{ id: "Haiti", name: "Haiti" },
	{ id: "HolySee", name: "HolySee" },
	{ id: "Honduras", name: "Honduras" },
	{ id: "Hong Kong", name: "Hong Kong" },
	{ id: "Hungary", name: "Hungary" },
	{ id: "Iceland", name: "Iceland" },
	{ id: "India", name: "India" },
	{ id: "Indonesia", name: "Indonesia" },
	{ id: "Iran", name: "Iran" },
	{ id: "Ireland", name: "Ireland" },
	{ id: "Israel", name: "Israel" },
	{ id: "Italy", name: "Italy" },
	{ id: "Jamaica", name: "Jamaica" },
	{ id: " Japan", name: " Japan" },
	{ id: "Jordan", name: "Jordan" },
	{ id: "Kazakhstan", name: "Kazakhstan" },
	{ id: "Kenya", name: "Kenya" },
	{ id: "Kiribati", name: " Kiribati" },
	{ id: "South Korea", name: "South Korea" },
	{ id: "North Korea", name: " North Korea" },
	{ id: "Kuwait", name: "Kuwait" },
	{ id: "Kyrgyzstan", name: "Kyrgyzstan" },
	{
		id: "Lao People's Democratic Republic",
		name: "Lao People's Democratic Republic",
	},
	{ id: "Latvia", name: "Latvia" },
	{ id: "Lebanon", name: "Lebanon" },
	{ id: "Lesotho", name: "Lesotho" },
	{ id: "Liberia", name: "Liberia" },
	{ id: "Libya", name: "Libya" },
	{ id: "Luxembourg", name: "Luxembourg" },
	{ id: "Liechtenstein", name: "Liechtenstein" },
	{ id: "Lithuania", name: "Lithuania" },
	{ id: "Madagascar", name: "Madagascar" },
	{ id: "Malawi", name: "Malawi" },
	{ id: "Malaysia", name: "Malaysia" },
	{ id: "Maldives", name: "Maldives" },
	{ id: "Mali", name: "Mali" },
	{ id: "Malta", name: "Malta" },
	{ id: "MarshallIslands", name: "MarshallIslands" },
	{ id: "Martinique", name: "Martinique" },
	{ id: "Mauritania", name: "Mauritania" },
	{ id: "Mauritius", name: "Mauritius" },
	{ id: "Mayotte", name: "Mayotte" },
	{ id: "Mexico", name: "Mexico" },
	{ id: "Micronesia", name: "Micronesia" },
	{ id: "Moldova", name: "Moldova" },
	{ id: "Monaco", name: "Monaco" },
	{ id: "Mongolia", name: "Mongolia" },
	{ id: "Montenegro", name: "Montenegro" },
	{ id: "Montserrat", name: "Montserrat" },
	{ id: "Morocco", name: "Morocco" },
	{ id: "Mozambique", name: "Mozambique" },
	{ id: "Myanmar", name: "Myanmar" },
	{ id: "Namibia", name: "Namibia" },
	{ id: "Nauru", name: "Nauru" },
	{ id: "Nepal", name: "Nepal" },
	{ id: "Netherlands Antilles", name: "Netherlands Antilles" },
	{ id: " Netherlands", name: " Netherlands" },
	{ id: "NewCaledonia", name: "NewCaledonia" },
	{ id: " New Zealand", name: "New Zealand" },
	{ id: " Nicaragua", name: " Nicaragua" },
	{ id: " Niger", name: " Niger" },
	{ id: " Nigeria", name: " Nigeria" },
	{ id: "Niue", name: "Niue" },
	{ id: "Northern Mariana Islands", name: "Northern Mariana Islands" },
	{ id: "Norway", name: "Norway" },
	{ id: "Oman", name: "Oman" },
	{ id: "Pakistan", name: "Pakistan" },
	{ id: "Palau", name: "Palau" },
	{ id: "Palestine", name: "Palestine" },
	{ id: "Panama", name: "Panama" },
	{ id: "Papua", name: "Papua" },
	{ id: "Paraguay", name: "Paraguay" },
	{ id: "Peru", name: "Peru" },
	{ id: "Philippines", name: "Philippines" },
	{ id: "Poland", name: "Poland" },
	{ id: "Portugal", name: "Portugal" },
	{ id: "Puerto Rico", name: "Puerto Rico" },
	{ id: "Qatar", name: "Qatar" },
	{ id: "Reunion", name: "Reunion" },
	{ id: "Romania", name: "Romania" },
	{ id: "Russian Federation", name: "Russian Federation" },
	{ id: "Rwanda", name: "Rwanda" },
	{ id: "Saint Barthélemy", name: "Saint Barthélemy" },
	{ id: "Saint Kitts and Nevis", name: "Saint Kitts and Nevis" },
	{ id: "Saint Lucia", name: "Saint Lucia" },
	{ id: "Saint Pierre", name: "Saint Pierre" },
	{
		id: "SaintVincent and the Grenadines",
		name: "SaintVincent and the Grenadines",
	},
	{ id: "Samoa", name: "Samoa" },
	{ id: "San Marino", name: "San Marino" },
	{ id: "Sao Tome", name: "Sao Tome" },
	{ id: "Saudi Arabia", name: "Saudi Arabia" },
	{ id: "Senegal", name: "Senegal" },
	{ id: "Serbia", name: "Serbia" },
	{ id: "Seychelles", name: "Seychelles" },
	{ id: "Sierra Leone", name: "Sierra Leone" },
	{ id: "Singapore", name: "Singapore" },
	{ id: "Slovakia", name: "Slovakia" },
	{ id: "Slovenia", name: "Slovenia" },
	{ id: "Solomon Islands", name: "Solomon Islands" },
	{ id: "Somalia", name: "Somalia" },
	{ id: "South Africa", name: "South Africa" },
	{ id: "South Sudan", name: "South Sudan" },
	{ id: "Spain", name: "Spain" },
	{ id: "Sri Lanka", name: "Sri Lanka" },
	{ id: "Sudan", name: "Sudan" },
	{ id: "Suriname", name: "Suriname" },
	{ id: "Sweden", name: "Sweden" },
	{ id: "Switzerland", name: "Switzerland" },
	{ id: "Syria", name: "Syria" },
	{ id: " Taiwan", name: " Taiwan" },
	{ id: "Tajikistan", name: "Tajikistan" },
	{ id: "Tanzania", name: "Tanzania" },
	{ id: "Thailand", name: "Thailand" },
	{ id: "Timor-Leste", name: "Timor-Leste" },
	{ id: "TogoToke", name: "TogoTokeTimor-Leste" },
	{ id: "Tonga", name: "Tonga" },
	{ id: "Trinidad and Tobago", name: "Trinidad and Tobago" },
	{ id: "Tunisia", name: "Tunisia" },
	{ id: "Turkey", name: "Turkey" },
	{ id: "Turkmenistan", name: "Turkmenistan" },
	{ id: "Turks and Caicos Islands", name: " Turks and Caicos Islands" },
	{ id: "Tuvalu", name: "Tuvalu" },
	{ id: "Uganda", name: "Uganda" },
	{ id: "Ukraine", name: "Ukraine" },
	{ id: "United Arab Emirates", name: "United Arab Emirates" },
	{ id: "United Kingdom", name: "United Kingdom" },
	{ id: "United States", name: "United States" },//Somnath Task-4016, Update Country from United States of America to United States
	{ id: "United States Virgin Islands", name: "United States Virgin Islands" },
	{ id: "Uruguay", name: "Uruguay" },
	{ id: "Uzbekistan", name: "Uzbekistan" },
	{ id: "Vanuatu", name: "Vanuatu" },
	{ id: " Venezuela", name: " Venezuela" },
	{ id: "Vietnam", name: "Vietnam" },
	{ id: " Yemen", name: " Yemen" },
	{ id: "Zambia", name: "Zambia" },
	{ id: "Zimbabwe", name: "Zimbabwe" },
];

const channel = [
	{ id: "TeleMarketing", name: "TeleMarketing" },
	{ id: "Email", name: "Email" },
	{ id: "Email/Telemarketing", name: "Email/Telemarketing" },
	{ id: "Display", name: "Display" },
	{ id: "Programmatic", name: "Programmatic" },
	{ id: "Social", name: "Social" },
	{ id: "Hybrid", name: "Hybrid" },
	{ id: "Others", name: "Others" },
]; */

class RequestForProposalExsisting extends React.Component {
	constructor() {
		super();
		this.state = {
			fromDashBoard: false,
			value: null,
			timeZone2: "",
			arrayValue: [],
			campID: "",
			fields: {},
			multi: {},
			errors: {},
			createdBy: "",
			alertDisplay: "none",
			alertBorder: "",
			alertWidth: "",
			alertAlign: "",
			quickFileLength: "",
			spanSpaceDisplay: "none",
			industryFileData: [],
			uploadFormatFile: [],
			country: [],
			getRFPDetail: [],
			editQuickFile: [],
			campaignDetailsSearch: [],
			tableSearchText: "",
			loading: " ",
			showBtn: "false",
			displayLink: "false",
			msg: "",
			clientName: "",
			currency: "USD",
			abm: "Open",
			inputClass11: "btn add-button",
			marketingChannel: "Email/Telemarketing",
			publisherList: [],
			selectedOption1: "",
			GetBiddingDetail: [],
			campaignDetails: [],
			msgForNoData: "",
			//errors: "", //rutuja 2832 commented errors for removing  dulpicate key warning  
			displayTableother: "none",
			btnSubmitPublisher: false,
			searchText: "",
			pageSize2: 100,
			tableState: { size: "small" },
			searchedColumn: "",
		};
		this.nameInput = React.createRef();
		this.CountryHandleChange = this.CountryHandleChange.bind(this);
		this.CountryHandleChangeBack = this.CountryHandleChangeBack.bind(this);
		this.home = this.home.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.submitQuickUpload = this.submitQuickUpload.bind(this);
		this.handleChangetimezone = this.handleChangetimezone.bind(this);
		this.handleQuickFileUpload = this.handleQuickFileUpload.bind(this);
		this.dynamicABMFile = this.dynamicABMFile.bind(this);
		this.displayCampaign = this.displayCampaign.bind(this);
		this.handleChangeMultipleFileOfOther =
			this.handleChangeMultipleFileOfOther.bind(this);
		this.editCampaign = this.editCampaign.bind(this);
		this.campaignDetails = this.campaignDetails.bind(this);
		this.createRfpCampaign = this.createRfpCampaign.bind(this);
		this.showPublisher = this.showPublisher.bind(this);
		this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
		this.handleChangeSearch = this.handleChangeSearch.bind(this);
	}
	handleChangeSearch(e) {
		e.preventDefault();
		let currentList = [];
		let newList = [];
		let filterData = e.target.value;
		if (e.target.value === "") {
			newList = this.state.GetBiddingDetail;
		} else {
			currentList = this.state.GetBiddingDetail;
			newList = currentList.filter((item) => {
				// change current item to lowercase
				const lc = item;
				//const lc = item.toLowerCase();
				// change search term to lowercase
				const filter = e.target.value.toLowerCase();
				//const filter = e.target.value.toLowerCase();
				// check to see if the current list item includes the search term
				// If it does, it will be added to newList. Using lowercase eliminates
				// issues with capitalization in search terms and search content
				var data = Object.values(lc);
				var dataFinal = data.toString();
				dataFinal = dataFinal.replace(",", " ");
				var dataToLower = dataFinal.toLowerCase();
				return dataToLower.includes(filter);
			});
		}
		// Set the filtered state based on what our rules added to newList
		this.setState({
			campaignDetailsSearch: [...newList],
			tableSearchText: filterData,
		});
	}
	createRfpCampaign(e) {
		var rfpCampID = e;

		this.props.history.push("/createcampaign2", {
			rfpCampaignID: rfpCampID,
		}); // karan-task-3767-replace query params
	}

	campaignDetails(e) {
		e.preventDefault();
		var campID = e.target.id;
		var step = "2";
		this.setState({ campID: campID });
		this.props.history.push("/rfpCampaignSpecification", {
			proposalID: campID,step:step
		}); // Sonali-task-3716-replace query params
		// window.location.href =
		// 	"/rfpCampaignSpecification?proposalID=" + campID + "&step=" + step;

		fetch("agencyDashboard/getRfpCampaignDetails?campID=" + campID)
			.then((res) => res.json())
			.then((campaignDetails) => {
				this.setState({ campaignDetails: campaignDetails });
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	editCampaign(e) {
		var campID = e;//Somnath Task-3936, Add URL in history Object
		this.props.history.push(`/requestForProposal2`,{rfpCampaignID:campID})
	}
	displayCampaign() {
		//const { user } = this.props.auth; //rutuja 2832 commented user as never used in the file  
		// Get RFP Campaign Details
		//fetch("agencyDashboard/GetBiddingDetail?agencyID=" + user.id)  Sonali-removing user.is-3716-VAPT
		fetch("agencyDashboard/GetBiddingDetail")
			.then((res) => res.json())
			.then((GetBiddingDetail) => {
				if (
					GetBiddingDetail.length == 0 ||
					GetBiddingDetail.length === "undefined" ||
					GetBiddingDetail.length === undefined
				) {
					var msgForNoData = "No Data Available";
				}
				this.setState({
					GetBiddingDetail: GetBiddingDetail,
					msgForNoData: msgForNoData,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	CountryHandleChange(value) {
		var countryvalue = [];
		console.log("countries==" + JSON.stringify(value));
		//const name = "country"; //rutuja 2832 commented name as never used in the file  

		for (var j = 0; j < value.length; j++) {
			countryvalue.push(value[j].name);
		}

		this.setState({
			country: value,
			country1: countryvalue,
			selectedOption1: value,
		});
	}

	CountryHandleChangeBack(value) {
		const name = "country";
		var countryvalue = [];
		for (var j = 0; j < value.length; j++) {
			countryvalue.push(value[j].name);
		}
		var getRFPDetail = [...this.state.getRFPDetail];
		getRFPDetail[0] = { ...getRFPDetail[0], [name]: countryvalue };
		this.setState({ country: value, getRFPDetail: getRFPDetail });
	}

	dynamicABMFile(e) {
		var supportDocID = e.target.id;

		let campaignDetail = this.state.editQuickFile;

		campaignDetail = campaignDetail.filter(
			(el) => el.supportDocID != supportDocID
		);
		/*var index = campaignDetail.findIndex(
			(v) => v.supportDocID === supportDocID
		);*/ //rutuja 2832 commented index as never used in the file  
		this.setState({ editQuickFile: campaignDetail });

		let data = {
			supportDocID: supportDocID,
		};
		fetch("agencyDashboard/deleteRFPDocument", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		});
	}
	customHandleChange(e) {
		let fields = this.state;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	}

	handleChange(e) {
		let fields = this.state;
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		if (campID === undefined || campID === "" || campID === null) {
			fields[e.target.name] = e.target.value;
			this.setState({ fields });
		} else {
			const { name, value } = e.target;

			let getRFPDetail = [...this.state.getRFPDetail];
			getRFPDetail[0] = { ...getRFPDetail[0], [name]: value };
			this.setState({ getRFPDetail });
		}
	} /** End of HandleChange */

	handleChangetimezone = (value) => {
		console.log(`Option selected:`, value);
		var timeZonevalue = [];
		timeZonevalue.push(value.name);

		this.setState({ timeZone: value, timeZone1: timeZonevalue });
	};

	handleChangetimezoneBack = (value) => {
		console.log(`Option selected:`, value);
		var timeZonevalue = [];
		const name = "advTimezone";

		timeZonevalue.push(value.name);

		//create dynamic array
		let getRFPDetail = [...this.state.getRFPDetail];
		getRFPDetail[0] = { ...getRFPDetail[0], [name]: timeZonevalue };

		this.setState({ timeZone2: value, getRFPDetail: getRFPDetail });
	};

	submitQuickUpload(e) {
		e.preventDefault();
		e.target.className += " was-validated";

		if (this.validateForm() === false) {
			return;
		} else {
			this.setState({
				alertDisplay: "none",
				loading: "loader",
			});
		}

		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;

		if (campID === undefined || campID === "" || campID === null) {
			//const { user } = this.props.auth; //rutuja 2832 commented user as never used in the file  
			let data = {
				campaignName: this.state.campaignName,
				marketingChannel: this.state.marketingChannel,
				country: this.state.country1,
				startDate: this.state.startDate,
				endDate: this.state.endDate,
				biddingEndDate: this.state.bid,
				campaignType: this.state.abm,
				conversionCriteria: this.state.advAdditionalComment,
				//agencyID: user.id,  //Sonali-3716-VAPT
			};

			fetch("/agencyDashboard/RFPQuickUpload", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((response) => {
					console.log("Return with response");
					var campID = response.insertId;

					this.setState({ response: response, campID: campID, loading: "" });

					window.location.href = "/requestForProposal?campID=" + campID + "";

					if (data === "success") {
						console.log("Inside if loop");

						this.refs.msg.show("Some text or component", {
							time: 2000,
							type: "success",
							icon: <img src="path/to/some/img/32x32.png" alt="" />, //rutuja 2832 added alt tag for removing console warning 
						});
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		} else {
			//const { user } = this.props.auth; //rutuja 2832 commented user as never used in the file  
			let description = "Request For Proposal Campaign Created";
			let data = {
				campID: campID,
				description: description,
				//user: user,-sonali-3716-VAPT
				campaignDetail: this.state.getRFPDetail,
			};

			fetch("/agencyDashboard/editRFPQuickCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((response) => {
					console.log("Return with response");
					this.displayCampaign(); //this function to Display Campaign List for bidding Publisher.
					var campID = response.campID;
					this.setState({ response: response, campID: campID, loading: "" });

					if (campID !== undefined || campID !== "") {
						// success message with response

						Swal.fire({
							text: "Request For Proposal Saved Successfully",
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								window.location.reload();
							},
						});
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} /**End of Quick Upload */

	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
		let spanDisplay = "none";
		let requiredFeildValidate = true;
		let clientNameValidate = true;
		let dateValidate = true;
		let biddingDateValidate = true;
		let countryValidate = true;

		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		if (campID === undefined || campID === "" || campID === null) {
			//Validation for   Create Flow
			//  Validation for required text feild.

			// Country Validation
			if (this.state.selectedOption1.length === 0) {
				countryValidate = false;

				this.setState({
					inputClassDropDownCountry: "invalid",
				});
				errors["commonError"] = <li>Please fill below required fields</li>;
			} else {
				countryValidate = true;
				// formIsValid = true;

				this.setState({
					inputClassDropDownCountry: "valid",
				});
			}

			if (
				!fields["campaignName"] ||
				!fields["startDate"] ||
				!fields["endDate"] ||
				!fields["bid"]
			) {
				requiredFeildValidate = false;
				errors["commonError"] = <li>Please fill below required fields</li>;
			}

			//  end date validation
			if (Date.parse(this.state.endDate) < Date.parse(this.state.startDate)) {
				dateValidate = false;
				errors["endDate"] = <li>End date must be greater than start date</li>;
			}
			var currentDate = new Date(
				new Date().getTime() - new Date().getTimezoneOffset() * 60000
			)
				.toISOString()
				.split("T")[0];

			var dt = new Date(currentDate);
			dt.setMonth(dt.getMonth() - 1);

			var month, day, year;
			month = "" + (dt.getMonth() + 1);
			day = "" + dt.getDate();
			year = dt.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;
			var updatedDate = [year, month, day].join("-");

			if (Date.parse(this.state.startDate) < Date.parse(updatedDate)) {
				dateValidate = false;
				errors["startDate"] = (
					<li>Start date can not be older than a month time period</li>
				);
			}

			if (this.state.endDate < currentDate) {
				dateValidate = false;
				errors["endDate"] = <li>End date must be greater than current date</li>;
			}

			if (Date.parse(this.state.bid) > Date.parse(this.state.endDate)) {
				biddingDateValidate = false;
				errors["biddingDateValidate"] = (
					<li>First lead delivery date can't be greater than end date</li>
				);
			}

			if (Date.parse(this.state.bid) < Date.parse(this.state.startDate)) {
				biddingDateValidate = false;
				errors["biddingDateValidate"] = (
					<li>First lead delivery date can't be less than start date</li>
				);
			}

			if (Date.parse(this.state.bid) < Date.parse(currentDate)) {
				biddingDateValidate = false;
				errors["biddingDateValidate"] = (
					<li>First lead delivery date must be greater than current date</li>
				);
			}

			if (
				requiredFeildValidate == false ||
				clientNameValidate == false ||
				dateValidate == false ||
				biddingDateValidate == false ||
				countryValidate == false
			) {
				formIsValid = false;
				this.setState({
					errors: errors,
					alertBorder: "1px solid red",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
					spanSpaceDisplay: spanDisplay,
				});
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			}

			return formIsValid;
		} else {
			//Validation For Back Flow

			let campData = this.state.getRFPDetail;
			let requiredFeildValidate = true;
			let clientNameValidate = true;
			let dateValidate = true;
			let biddingDateValidate = true;
			//let geoValidate = true; //rutuja 2832 commented geovalidate as never used in the file  
			let countryValidate = true;

			if (campData[0].country.length === 0 || this.state.country == "") {
				countryValidate = false;

				this.setState({
					inputClassregion: "invalid",
				});
				errors["commonError"] = <li>Please fill below required fields</li>;
			} else {
				countryValidate = true;

				this.setState({
					inputClassregion: "Valid",
				});
			}

			if (
				!campData[0].campaignName ||
				!campData[0].startDate ||
				!campData[0].endDate ||
				!campData[0].biddingEndDate
			) {
				requiredFeildValidate = false;
				errors["commonError"] = <li>Please fill below required fields</li>;
			} else {
				requiredFeildValidate = true;
				errors["commonError"] = "";
			}

			//  end date validation
			if (Date.parse(this.state.endDate) < Date.parse(this.state.startDate)) {
				dateValidate = false;
				errors["endDate"] = <li>End date must be greater than start date</li>;
			}
		/*	var currentDate = new Date(
				new Date().getTime() - new Date().getTimezoneOffset() * 60000
			)
				.toISOString()
				.split("T")[0];*/ //rutuja 2832 commented currentDate as never used in the file  

			let dt = new Date(currentDate); //rutuja 2832 replace var declaration with let
			dt.setMonth(dt.getMonth() - 1);

			//var month,day, year; //rutuja 2832 commented all declaration as already declared 
			month = "" + (dt.getMonth() + 1);
			day = "" + dt.getDate();
			year = dt.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;
			 updatedDate = [year, month, day].join("-"); //rutuja 2832 removed updateDate declaration as already declared 

			if (Date.parse(campData[0].startDate) < Date.parse(updatedDate)) {
				dateValidate = false;
				errors["startDate"] = (
					<li>Start date can not be older than a month time period</li>
				);
			}

			if (campData[0].endDate < currentDate) {
				dateValidate = false;
				errors["endDate"] = <li>End date must be greater than current date</li>;
			}

			if (
				Date.parse(campData[0].biddingEndDate) > Date.parse(campData[0].endDate)
			) {
				biddingDateValidate = false;

				errors["biddingDateValidate"] = (
					<li>First lead delivery date can't be greater than end date</li>
				);
			}

			if (
				Date.parse(campData[0].biddingEndDate) <
				Date.parse(campData[0].startDate)
			) {
				biddingDateValidate = false;
				errors["biddingDateValidate"] = (
					<li>First lead delivery date can't be less than start date</li>
				);
			}

			if (Date.parse(campData[0].biddingEndDate) < Date.parse(currentDate)) {
				biddingDateValidate = false;
				errors["biddingDateValidate"] = (
					<li>First lead delivery date must be greater than current date</li>
				);
			}

			if (
				requiredFeildValidate == false ||
				clientNameValidate == false ||
				dateValidate == false ||
				biddingDateValidate == false ||
				countryValidate == false
			) {
				formIsValid = false;
				this.setState({
					errors: errors,
					alertBorder: "1px solid red",
					alertWidth: "1000px",
					alertAlign: "center",
					alertDisplay: "block",
					spanSpaceDisplay: spanDisplay,
				});
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			} else {
				formIsValid = true;
			}

			return formIsValid;
		}
	} // end of validateForm()

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			//Sonali-3716-VAPT
			
			if (this.props.location.state !== undefined) {
				const {
				agencyID,fromDashBoard,campID
				} = this.props.location.state;
				this.setState({agencyID,fromDashBoard,campID})
			}
		
			if (this.props.location.state.fromDashBoard == "true"||this.props.location.state.fromDashBoard == true) {
				
				this.setState({ fromDashBoard: true });
			}
			this.displayCampaign();
			if (this.props.location.state.campID === undefined || this.props.location.state.campID === "" || this.props.location.state.campID === null) {
				this.setState({ showQuickFileUpload: "false" });
			} else {
				this.setState({ showQuickFileUpload: "true" }); // for Upload File
				// Get RFP Campaign Details
				fetch("agencyDashboard/getRFPQuickDetail?campID=" + this.props.location.state.campID)
					.then((res) => res.json())
					.then((getRFPDetail) => {
						this.setState({
							getRFPDetail: getRFPDetail,
							inputClass: "disabled",
						});
						if (getRFPDetail.length > 0) {
							var countryData = [];
							var str = this.state.getRFPDetail[0].country;
							var strData = str.split(",");
							for (var i = 0; i < strData.length; i++) {
								countryData.push({ id: strData[i], name: strData[i] });
							}
							this.setState({ country: countryData });
						}
					})
					.catch(function (err) {
						console.log(err);
					});

				// Get RFP Document Details
				fetch("agencyDashboard/editRFPDocument?campID=" + this.props.location.state.campID)
					.then((res) => res.json())
					.then((editQuickFile) => {
						this.setState({
							editQuickFile: editQuickFile,
							displayTableother: "block",
						});
					})
					.catch(function (err) {
						console.log(err);
					});

				// Get Campaign Detail for Publisher Bidding
				this.displayCampaign();
			} // End of Else of CampID Undefined
		} // End of else of Authontication
	} // end of componentDidMount()

	handleQuickFileUpload(e) {
		e.preventDefault();

		suppressionnames = [];
		let self = this;

		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;

		let suppressionfilesData = e.target.files;

		for (var i = 0, l = suppressionfilesData.length; i < l; i++) {
			suppressionnames[i] = suppressionfilesData[i].name;
			console.log("Name of Suppression files=" + suppressionnames[i]);
			count++;
		}

		var link;
		var filename = "";
		var suppressionarrayLength = suppressionnames.length;

		var nosuppressionoutput = document.getElementById("nosuppressionList");
		var valuenosuppressionoutput =
			document.getElementById("nosuppressionList").innerHTML;

		var suppressionres = []; //rutuja 2832
		suppressionres = valuenosuppressionoutput.split(" ");
		var backsuppressionarraylength;
		for (i = 0; i < suppressionres.length; i++) {
			backsuppressionarraylength = suppressionres[0];
		}

		suppressionarrayLength = parseInt(suppressionarrayLength);
		backsuppressionarraylength = parseInt(backsuppressionarraylength);

		var suppressionarrayLength1;

		suppressionarrayLength1 =
			suppressionarrayLength + backsuppressionarraylength;

		if (suppressionarrayLength1 >= 1) {
			nosuppressionoutput.innerHTML =
				suppressionarrayLength1 + "Files Selected";
		} else {
			nosuppressionoutput.innerHTML = "0 File Selected";
		}

		for (var j = 0; j < suppressionarrayLength; j++) {
			newsuppressionfilenames.push(suppressionnames[j]);
		}
		var HTML = "<table>";
		//rutuja 2832 replaced var j with let j for removing console warning 
		for (let j = 0; j < newsuppressionfilenames.length; j++) {
			link =
				'<a class="removeSuppressionFile" href="#" data-fileid=' +
				'"' +
				newsuppressionfilenames[j] +
				'"' +
				'><i class="fa fa-times"></i></a>';
			HTML += "<tr><td>" + link + newsuppressionfilenames[j] + "</td></tr>";
		}
		HTML += "</table>";

		document.getElementById("suppressionList").innerHTML = HTML;
		$(document).on("click", ".removeSuppressionFile", function (e) {
			e.preventDefault();
			var clickedFile = $(this).parent().children("a").data("fileid");
			var j = 0;
			for (j; j < newsuppressionfilenames.length; j++) {
				var temp = j;
				if (newsuppressionfilenames[temp] === clickedFile) {
					filename = newsuppressionfilenames[temp];
					newsuppressionfilenames.splice(j, 1);
					$(this).parent().remove();
					suppressionarrayLength = newsuppressionfilenames.length;
					count--;

					let data = {
						campID: campID,
						names: filename,
					};

					fetch("agencyDashboard/deleteRFPDocument", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					});
					if (count == 0) {
						self.setState({ showBtn: "false" });
					}
					suppressionarrayLength = parseInt(suppressionarrayLength);
					backsuppressionarraylength = parseInt(backsuppressionarraylength);
					suppressionarrayLength1 =
						suppressionarrayLength + backsuppressionarraylength;

					if (suppressionarrayLength1 > 0) {
						nosuppressionoutput.innerHTML =
							suppressionarrayLength1 + "Files Selected";
					} else {
						nosuppressionoutput.innerHTML = "0 File Selected";
						$("#suppression").val("");
						var message = document.getElementById("suppresionMessage");
						message.innerHTML = "";
					}
				} // end of  if(names[temp] === clickedFile)
			} // end of for
		});

		var data = new FormData();
		data.append("campID", campID);
		//rutuja 2832 replaced var i with let i for removing console warning 
		for (let i = 0, l = suppressionfilesData.length; i < l; i++) {
			data.append("file", suppressionfilesData[i]);
		}
		console.warn("newData", data);
		if (count > 0) {
			self.setState({ showBtn: "true" });
		}
		fetch("agencyDashboard/RFPUploadFile", {
			method: "POST",
			body: data,
		})
			.then(function (response) {
				if (response.status >= 400) {
					throw new Error("Bad response from server");
				}
				return response.json();
			})
			.then(function (response) {})
			.catch(function (err) {
				console.log(err);
			});
		this.setState({ files: data });
		var msg = "Successfully Uploads Files";
		var message = document.getElementById("suppresionMessage");
		var number = document.getElementById("suppresionMessage").innerHTML;
		if (number == "") {
			message.innerHTML += msg;
		}
	}
	home(e) {
		const { user } = this.props.auth; //rutuja 2832 removed isAuthenticated as never used in the page
		window.location.href = "/advertiserFrontPage?advertiserID=" + user.id;
	}
	showPublisher(e) {
		//const { user } = this.props.auth; //rutuja 2832 commented user as never used in the page
		var campID = e;
		var publisherList = [];
		this.setState({ publisherList: publisherList });
		// Get RFP Publisher Details
		//sonali-3716-removing user id -VAPT
		fetch(
			"agencyDashboard/GetRFPPublisher?campID=" +
				campID 
		)
			.then((res) => res.json())
			.then((publisherList) => {
				let temp = publisherList.filter((a) => {
					if (
						a.status == "" ||
						a.status == "null" ||
						a.status == null ||
						a.status == undefined
					) {
					} else {
						return a;
					}
				});
				if (temp.length == publisherList.length) {
					this.setState({ btnSubmitPublisher: true });
				} else {
					this.setState({ btnSubmitPublisher: false });
				}
				this.setState({ publisherList: publisherList });
			})
			.catch(function (err) {
				console.log(err);
			});
	} // End of showPublisher

	handleCheckPublisher(i, e) {
		const { name, checked } = e.target;
		let publisherList = [...this.state.publisherList];
		publisherList[i] = { ...publisherList[i], [name]: checked };
		this.state.publisherList = [...publisherList];
	} // End of handleCheckPublisher

	submitPublisherList() {
		/*var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		const { user } = this.props.auth;*/ //rutuja commented all as never used in the file
		
		
		let errors = {};
		var pubList = this.state.publisherList.filter(function (a) {
			return a.Checked == true && a.status !== "Assign";
		});

		if (pubList.length == 0) {
			errors["unchecked"] = "Please Select Minimum One Publisher";
			this.setState({ errors: errors });
			//let publisherValidate = false; //rutuja 2832 commented publisherValidate as never used in the file
			$("#myModal").modal("show");
			//Sandeep-task-3227-added code to show modal when error
		} else {
			errors["unchecked"] = "";
			this.setState({ errors: errors });
			//publisherValidate = true; //rutuja 2832 commented publisherValidate as never used in the file
		}

		$("#myModal").modal("hide");
		//Sandeep-task-3227-added code to hide modal when no error
		var data = {
		//	user: user,----sonali-3716---VAPT
			pubList: this.state.publisherList,
		};

		fetch("agencyDashboard/submitRFPPubList", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then(function (response) {
				if (response.status >= 400) {
					throw new Error("Bad response from server");
				} else {
					Swal.fire({
						text: "Request For Proposal Sent To Publisher Successfully",
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							window.location.reload();
						},
					});
				}
			})
			.catch(function (err) {
				console.log(err);
			});
	} // End of submitPublisherList

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the multiple asset uploads
	 * @return Description return successfully files upload message
	 */

	handleChangeMultipleFileOfOther(e) {
		e.preventDefault();
		othernames = [];
		let self = this;
		var parsed = queryString.parse(this.props.location.search);
		var campID = parsed.campID;
		self.setState({ displayTableother: "block" });

		let otherfilesData = e.target.files;
		console.warn("data files", otherfilesData);

		for (var i = 0, l = otherfilesData.length; i < l; i++) {
			othernames.push(otherfilesData[i].name);
			console.log("Name of files=" + othernames);
		}

		/**
		 * @author Sanjana Godbole
		 * @param  A FileList object that lists every selected file
		 * @return List of uploded file along with remove link
		 */

		var link;
		var filename = "";
		// var arrayLength = othernames.length; //rutuja 2832 commented arraylength as never used in the file
		var otherarrayLength = othernames.length;
		var nootheroutput = document.getElementById("nootherList");
		var valuenootheroutput = document.getElementById("nootherList").innerHTML;

		var otherres = [];//rutuja 2832
		otherres = valuenootheroutput.split(" ");

		var backotherarraylength;
		for (i = 0; i < otherres.length; i++) {
			backotherarraylength = otherres[0];
		}

		otherarrayLength = parseInt(otherarrayLength);
		backotherarraylength = parseInt(backotherarraylength);

		var otherarrayLength1;
		otherarrayLength1 = otherarrayLength + backotherarraylength;
		if (otherarrayLength1 > 0) {
			nootheroutput.innerHTML = otherarrayLength;
		} else {
			nootheroutput.innerHTML = "0 Other File Selected";
		}
		for (var j = 0; j < otherarrayLength; j++) {
			newotherfilenames.push(othernames[j]);
		}

		var HTML = "<table>";
		var imgsuccess;
		//rutuja 2832 replaced var j with let j for removing console warning 
		for (let j = 0; j < newotherfilenames.length; j++) {
			var isExist = otherValidationArray.includes(newotherfilenames[j]);
			if (isExist === true) {
				imgsuccess =
					'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';
				link =
					'<a class="removeOtherFile" href="#" data-fileid=' +
					'"' +
					newotherfilenames[j] +
					'"' +
					'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
				HTML +=
					"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" +
					imgsuccess +
					"&nbsp;" +
					"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
					newotherfilenames[j] +
					"</span>" +
					link +
					"<br><br></td></tr>";
			} else {
				imgsuccess =
					'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';
				link =
					'<a class="removeOtherFile" href="#" data-fileid=' +
					'"' +
					newotherfilenames[j] +
					'"' +
					'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
				HTML +=
					"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" +
					imgsuccess +
					"&nbsp;" +
					"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
					newotherfilenames[j] +
					"</span>" +
					link +
					"<br><br></td></tr>";
			}
		}
		HTML += "</table>";

		document.getElementById("otherList").innerHTML = HTML;
		$(document).on("click", ".removeOtherFile", function (e) {
			e.preventDefault();
			var clickedFile = $(this).parent().children("a").data("fileid");
			var j = 0;
			for (j; j < newotherfilenames.length; j++) {
				var temp = j;
				if (newotherfilenames[temp] === clickedFile) {
					var isExist = otherValidationArray.includes(newotherfilenames[j]);
					if (isExist === true) {
						var index = otherValidationArray.indexOf(newotherfilenames[j]);
						otherValidationArray.splice(index, 1);
					}
					filename = newotherfilenames[temp];
					newotherfilenames.splice(j, 1);
					$(this).parent().remove();
					otherarrayLength = newotherfilenames.length;
					if (newotherfilenames.length == 0) {
						otherValidationArray = [];
						rejectOtherCount = [];
						var message1 = document.getElementById("otherMessage");
						message1.innerHTML = "";
					}
					for (var k = 0; k < rejectOtherCount.length; k++) {
						if (rejectOtherCount[k] == clickedFile) {
							rejectOtherCount.splice(k, 1);
						}
					}
					//rutuja 2832 replaced var k with let k for removing console warning 
					for (let k = 0; k < successOtherCount.length; k++) {
						if (successOtherCount[k] == clickedFile) {
							successOtherCount.splice(k, 1);
						}
					}
					var rejectedCount =
						newotherfilenames.length - successOtherCount.length;
					if (rejectedCount < 0) {
						rejectedCount = 0;
					}
					var msg1 = "";

					 message1 = document.getElementById("otherMessage"); // rutuja 2832 removed var declaration od message1 as already declared
					if ($("#otherMessage").text() === "") {
						message1.innerHTML += msg1;
					} else {
						message1.innerHTML = msg1;
					}
					if (newotherfilenames.length == 0) {
						 message1 = document.getElementById("otherMessage"); // rutuja 2832 removed var declaration od message1 as already declared
						message1.innerHTML = "";
					}

					if (newotherfilenames.length == 0) {
						self.setState({ displayTableother: "none" });
					}
					//var typeOfSuppDoc = "Other"; //rutuja 2832 commented typeOfSuppDoc as never used in the file 
					let data = {
						campID: campID,
						names: filename,
					};

					fetch("agencyDashboard/deleteRFPDocument", {
						method: "POST",
						headers: { "Content-Type": "application/json" },

						body: JSON.stringify(data),
					});
					otherarrayLength = parseInt(otherarrayLength);
					backotherarraylength = parseInt(backotherarraylength);
					otherarrayLength1 = otherarrayLength + backotherarraylength;

					if (otherarrayLength1 > 0) {
						nootheroutput.innerHTML = otherarrayLength1;
					} else {
						nootheroutput.innerHTML = "0 Other File Selected";

						/*var message = document.getElementById("otherMessage");
						message = "";*/ //rutuja commented message as never used in the file
					}
				} // end of  if(names[temp] === clickedFile)
			} // end of for
		}); //$(document).on

		var data = new FormData();
		data.append("campID", campID);
			//rutuja 2832 replaced var i with let i for removing console warning 
		for (let i = 0, l = otherfilesData.length; i < l; i++) {
			data.append("file", otherfilesData[i]);
		}

		fetch("agencyDashboard/RFPUploadFile", {
			method: "POST",

			body: data,
		})
			.then((res) => res.json())
			.then((otherList) => {
				if (otherList[0].success === true) {
					var suppDocName = [];
					for (var i = 0; i < otherList.length; i++) {
						suppDocName.push(otherList[i].successList);
						successOtherCount.push(otherList[i].successList); //only for count
					}
					//rutuja 2832 replaced var i with let i for removing console warning 
					for (let i = 0; i < suppDocName.length; i++) {
						var count = 0;
						for (var j = 0; j < newotherfilenames.length; j++) {
							if (suppDocName[i] == newotherfilenames[j]) {
								count = count + 1;
							}
						}
					}

					if (count > 1) {
						newotherfilenames.pop();
						otherValidationArray.pop();
					}
					var RejectedCount = 0;
					var imgsuccess;
					var HTML2 = "<table>";
					//rutuja 2832 replaced var j with let j for removing console warning 
					for (let j = 0; j < newotherfilenames.length; j++) {
						var isExist = otherValidationArray.includes(newotherfilenames[j]);
						if (isExist === true) {
							/**** If uploaded file is wrong then it displays as in Red color */
							RejectedCount = RejectedCount + 1;
							imgsuccess =
								'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';

							link =
								'<a class="removeOtherFile" href="#" data-fileid=' +
								'"' +
								newotherfilenames[j] +
								'"' +
								'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
							HTML2 +=
								"<tr><td style='color:red;background-color:#fbd5db;border: 1px solid #ffffff;padding-bottom:0px;'>" +
								imgsuccess +
								"&nbsp;" +
								"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
								newotherfilenames[j] +
								"</span>" +
								link +
								"<br><br></td></tr>";
						} else {
							imgsuccess =
								'<img src="success.png" alt="success" height="18" width="18" style="float:left"/>';

							link =
								'<a class="removeOtherFile" href="#" data-fileid=' +
								'"' +
								newotherfilenames[j] +
								'"' +
								'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
							HTML2 +=
								"<tr><td style='color:green;background-color:#daecda;border: 1px solid #ffffff;padding-bottom:0px;'>" +
								imgsuccess +
								"&nbsp;" +
								"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 260px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
								newotherfilenames[j] +
								"</span>" +
								link +
								"<br><br></td></tr>";
						}
					}
					HTML2 += "</table>";
					document.getElementById("otherList").innerHTML = HTML2;
					var msg = "";
					// "Successfully Uploads Other Files (Uploaded Files :"+(successOtherCount.length)+", <font color='red'> Failed Files :"+RejectedCount+"</font>)";
					var message = document.getElementById("otherMessage");
					if ($("#otherMessage").text() === "") {
						message.innerHTML += msg;
					} else {
						message.innerHTML = msg;
					}
				} else {
					//rutuja 2832 replaced var i with let i for removing console warning 
					for (let i = 0; i < otherList.length; i++) {
						otherValidationArray.push(otherList[i].rejectedFile);
					}

					var HTML = "<table>";
					// var imgsuccess; //rutuja 2832 commented imgsuccess as already declared
					//rutuja 2832 replaced var j with let j for removing console warning 
					for (let j = 0; j < newotherfilenames.length; j++) {
						var isValid = otherValidationArray.includes(newotherfilenames[j]);
						if (isValid === true) {
							imgsuccess =
								'<img src="failed.png" alt="failed" height="18" width="18" style="float:left"/>';

							link =
								'<a class="removeOtherFile" href="#" data-fileid=' +
								'"' +
								newotherfilenames[j] +
								'"' +
								'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
							HTML +=
								"<tr style='background-color:#fbd5db'><td style='color:red;border: 1px solid #ffffff;padding-bottom:0px;'>" +
								imgsuccess +
								"&nbsp;" +
								"<span  style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 220px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
								newotherfilenames[j] +
								"</span>" +
								link +
								"<br><br></td></tr>";
						} else {
							let isTrue = successOtherCount.includes(newotherfilenames[j]);
							if (isTrue === false) {
								successOtherCount.push(newotherfilenames[j]);
							}
							imgsuccess =
								'<img src="success.png" alt="sucess" height="18" width="18" style="float:left"/>';

							link =
								'<a class="removeOtherFile" href="#" data-fileid=' +
								'"' +
								newotherfilenames[j] +
								'"' +
								'><span style="font-family:roboto;font-size:13px;font-weight:500">Remove</span></a>';
							HTML +=
								"<tr style='background-color:#daecda'><td style='color:green;border: 1px solid #ffffff;padding-bottom:0px;'>" +
								imgsuccess +
								"&nbsp;" +
								"<span style='font-weight:500;font-size:13px;font-family:roboto;display: inline-block;width: 220px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>" +
								newotherfilenames[j] +
								"</span>" +
								link +
								"<br><br></td></tr>";
						}
					}
					HTML += "</table>";
					document.getElementById("otherList").innerHTML = HTML;
					/*var rejectedCount =
						newotherfilenames.length - successOtherCount.length;*/ //rutuja commented rejectedCount as never used in the file
					var msg1 = "";

					var message1 = document.getElementById("otherMessage");
					if ($("#otherMessage").text() === "") {
						message1.innerHTML += msg1;
					} else {
						message1.innerHTML = msg1;
					}
				}
			})
			.catch(function (err) {
				console.log(err);
			});
		this.setState({
			files: data,
		});
	}
	//end of handleChangeMultipleFileOfOther
	handleChangePageSize2(e) {
		var pageSize2 = e.target.value;
		this.setState({ pageSize2: pageSize2 });
	}
	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						this.handleSearch(selectedKeys, confirm, dataIndex)
					}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				{/* <Space> */}
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					size="small"
					style={{ width: 90 }}>
					Search
				</Button>
				<Button
					onClick={() => this.handleReset(clearFilters)}
					size="small"
					style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),

		filterIcon: (filtered) => (
			<svg
				style={{ color: filtered ? "#1890ff" : undefined }}
				class="bi bi-search"
				width="1em"
				height="1em"
				viewBox="0 0 16 16"
				fill="currentColor"
				xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
					clip-rule="evenodd"
				/>
				<path
					fill-rule="evenodd"
					d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
					clip-rule="evenodd"
				/>
			</svg>
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				text
			),
	});
	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};
	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: "" });
	};
	render() {
		const { user } = this.props.auth; //rutuja 2832 removed isAuthenticated  as never used in the file
		const columns = [
			{
				title: "Proposal ID",
				dataIndex: "campID",
				key: "campID",
				width: "100px",
			},
			{
				title: "Campaign Name",
				key: "campaignName",
				width: "200px",

				render: (record) => (
					<div>
						<a
							href="#"
							id={record.campID}
							onClick={this.campaignDetails}>
							{/* onClick={(e) => {
								this.campaignDetails(e);
							}}> */}
							{record.campaignName}
						</a>
					</div>
				),
			},
			{
				title: "Start Date",
				dataIndex: "startDate",
				key: "startDate",
				width: "100px",
			},
			{
				title: "End Date",
				dataIndex: "endDate",
				key: "endDate",
				width: "100px",
			},
			{
				title: "Bidding Closes",
				dataIndex: "biddingEndDate",
				key: "biddingEndDate",
				width: "150px",
			},
			{
				title: "Marketing Channel",
				dataIndex: "marketingChannel",
				key: "marketingChannel",
				width: "200px",
			},
			{
				title: "Campaign Type",
				dataIndex: "campaignType",
				key: "campaignType",
				width: "100px",
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				width: "150px",
			},
			{
				title: "Action",
				width: "200px",
				render: (record) => (
					<div>
						{record.status === "BiddingDateCrossed" ? (
							<Tooltip placement="left" title="Bidding date crossed">
								<a href="#" style={{ color: "#00000040" }}>
									Send To Publisher
								</a>
							</Tooltip>
						) : (
							<a
								href="#"
								data-toggle="modal"
								data-target="#myModal"
								id={record.campID}
								onClick={(e) => {
									this.showPublisher(record.campID);
								}}>
								Send To Publisher
							</a>
						)}
						<hr style={{ margin: "6px" }} />
						{record.status == "BiddingDateCrossed" ? (
							<Tooltip placement="left" title="Bidding date crossed">
								<a href="#" style={{ color: "#00000040" }}>
									Edit RFP
								</a>
							</Tooltip>
						) : (
							<a
								href={void(0)}//sonali-4030-for removing #
								style={{color:"#1890FF"}}
								onClick={(e) => {
									this.editCampaign(record.campID);
								}}>
								Edit RFP
							</a>
						)}
						<hr style={{ margin: "6px" }} />
						{record.campExists === "Yes" ? (
							<Tooltip placement="left" title="Campaign Already Exists">
								<a href="#" style={{ color: "#00000040" }}>
									Create RFP Campaign
								</a>
							</Tooltip>
						) : (
							<a
								href="#"
								onClick={(e) => {
									this.createRfpCampaign(record.campID);
								}}>
								Create Campaign
							</a>
						)}
					</div>
				),
			},
		];

		function onChange(pagination, filters, sorter) {
			console.log("params", pagination, filters, sorter);
		}

		this.state.createdBy = user.name;
		//const asterisk = { color: "red" }; //rutuja 2832 commented asterisk as never used in the file
		if (this.state.editQuickFile != undefined) {
			quickFileLength = this.state.editQuickFile.length;
		}
		if (quickFileLength > 0) {
			 btnShow = "true";
		} else {
			btnShow = "false";
		}
		// var parsed = queryString.parse(this.props.location.search); //rutuja 2832 commented parsed as never used in the file
		//var campID = parsed.campID; //rutuja 2832 commented campID as never used in the file

		return (
			<div
			// style={{ overflowX: "hidden" }} karan-task-3371-after click on submit button bg is no more blank
			>
				<Navigation />

				<div className="col-lg-12">
					<div class="container-fluid" style={{ paddingTop: "100px" }}>
						{/* //shivani-3284-passed ID for DI label consistency */}
						<div class="col" id="labelDI">
							{this.state.fromDashBoard == false ? (
								<a href="#">
									<FaArrowAltCircleLeft
										size={32}
										style={{ float: "left", color: "#164495" }}
										title="Back to Request For Proposal"
										onClick={(e) => {
											window.location.href = "./requestForProposal2";
										}}
									/>
								</a>
							) : (
								<a href="#">
									<FaArrowAltCircleLeft
										size={32}
										style={{ float: "left", color: "#164495" }}
										title="Back to Dashboard"
										onClick={(e) => {
											e.preventDefault();
											this.props.history.push("/newdashboard", {
												agencyID: user.id,
											}); //karan-task-3684-replace query params
										}}
									/>
								</a>
							)}
							Existing Request For Proposal
						</div>

						<div class="col-xs-12 col-sm-12 col-md-12  col-lg-12 col-xl-12">
							<input
								id="search-bar1"
								type="text"
								onkeyup="myFunction()"
								style={{
									backgroundImage: "url(Group_294.png)",
									backgroundPosition: "15px 6px",
									backgroundSize: "15px 15px",
									backgroundRepeat: "no-repeat",
									width: "16%",
									float: "right",
									height: "20px",
									fontSize: "10px",
									border: "1px solid #ddd",
									padding: "12px 20px 12px 34px",
									borderRadius: "25px",
									backgroundColor: "rgb(221, 221, 221)",
								}}
								onChange={this.handleChangeSearch}
								onPressEnter={this.handleChangeSearch}
								placeholder="Search..."
								title="Type in a name"
							/>
						</div>
					</div>
				</div>

				<div class="container-fluid" style={{ paddingBottom: "60px" }}>
					{this.state.displayLink == "true" ? (
						<a
							href="#"
							style={{ paddingLeft: "1215px" }}
							onClick={this.home}></a>
					) : (
						""
					)}
					<div class="card-body">
						<MDBRow>
							<legend
								className="legend labelsubheading"
								id="legend-label"
								style={{ marginTop: "-15px" }}>
								List of Existing RFP
							</legend>

							{this.state.msgForNoData ? (
								<label
									style={{
										color: "red",
										fontSize: "25px",
										marginLeft: "563px",
									}}>
									{this.state.msgForNoData}
								</label>
							) : (
								
								<div style={{ overflowX: "scroll" }}>
									{/* rutuja 2832 className="ant-table-thead" to class="ant-table-thead" for removing console warning */}
									{this.state.tableSearchText === "" ? (
										<Table
											{...this.state.tableState}
											id="myTable"
											bordered
											dataSource={this.state.GetBiddingDetail}
											onChange={onChange}
											columns={columns}
											class="ant-table-thead"
											className={tableCSS}
											scroll={{ y: 400, x: 1300 }}
											pagination={{ pageSize: this.state.pageSize2 }}></Table>
									) : (
										<Table
											{...this.state.tableState}
											id="myTable"
											bordered
											dataSource={this.state.campaignDetailsSearch}
											onChange={onChange}
											columns={columns}
											class="ant-table-thead" 
											className={tableCSS}
											scroll={{ y: 400, x: 1300 }}
											pagination={{ pageSize: this.state.pageSize2 }}></Table>
									)}
									<p>
										<div class="row" style={{ marginBottom: "30px" }}>
											<div
												class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
												style={{ marginTop: "-54px" }}>
												&nbsp;&nbsp;&nbsp;Records per page:&nbsp;
												<select
													defaultValue={this.state.pageSize2}
													onChange={this.handleChangePageSize2}
													id="pacing1"
													class="input-small"
													className="form-control"
													name="pacing"
													style={{
														width: "70px",
														height: "30px",
														display: "initial",
														marginLeft: "10px",
													}}>
													<option value="100" selected>
														100
													</option>
													<option value="150">150</option>
													<option value="200">200</option>
													<option value="250">250</option>
												</select>
											</div>
										</div>
									</p>
								</div>
							)}
						</MDBRow>

						<br />
					</div>
				</div>

				<div id="campDetails" class="modal fade" role="dialog">
					<div class="modal-dialog modal-lg ">
						<div class="modal-content">
							<div class="modal-header custom-class">
								<h4
									class="modal-title"
									style={{
										color: "white",
										fontSize: "20px",
										paddingRight: "593px",
									}}>
									Campaign Details
								</h4>
								<button
									type="button"
									class="cancel-button pull-right"
									style={{ fontSize: 15 }}
									data-dismiss="modal">
									&times;
								</button>
							</div>
							<div class="modal-body">
								<p>
									{this.state.campaignDetails.map((campaignDetails) => (
										<div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Campaign Name{" "}
													</label>
													:&nbsp;&nbsp;
													{campaignDetails.campaignName}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>
														Campaign Type{" "}
													</label>
													:&nbsp;
													{campaignDetails.campaignType}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4"></div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>Start Date </label>
													:&nbsp;&nbsp;
													{campaignDetails.startDate}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>End Date </label>
													:&nbsp;
													{campaignDetails.endDate}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label>Bidding Closes &nbsp;&nbsp;:</label>
													{campaignDetails.biddingEndDate}&nbsp;&nbsp;&nbsp;
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Bidding Type{" "}
													</label>
													:&nbsp;&nbsp;
													{campaignDetails.biddingType}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>
														Type Of Duration{" "}
													</label>
													:&nbsp;
													{campaignDetails.durationType}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "93px" }}>Duration </label>
													:&nbsp;
													{campaignDetails.duration}&nbsp;&nbsp;&nbsp;
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Marketing Channel{" "}
													</label>
													:&nbsp;&nbsp;
													{campaignDetails.marketingChannel}&nbsp;&nbsp;&nbsp;
												</div>
											</div>
											<div class="row">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>Region </label>
													:&nbsp;&nbsp;
													{campaignDetails.region}
												</div>
											</div>
											<div class="row word-wrap">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>Country </label>
													:&nbsp;&nbsp;
													{campaignDetails.country}
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>Industry </label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.industry}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>Job Title </label>
													:&nbsp;&nbsp;
													{campaignDetails.jobTitle}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label>No. Of Custom Questions :</label>
													&nbsp;&nbsp;&nbsp;
													{campaignDetails.noOfCustomQuestions}
												</div>
											</div>
											<div class="row">
												<label style={{ paddingLeft: "15px", width: "125px" }}>
													Other{" "}
												</label>
												:&nbsp;&nbsp;&nbsp;
												{campaignDetails.conversionCriteria}
											</div>
											<div class="row w-100%">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>
														Job Function{" "}
													</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.jobFunction}
												</div>
											</div>
											<div class="row w-100%">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>
														company Revenue{" "}
													</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.companyRevenue}
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>Job Level </label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.jobLevel}
												</div>
											</div>
											<div class="row">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label>Employee Size </label>:&nbsp;&nbsp;&nbsp;
													{campaignDetails.empSize}
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Max. lead available{" "}
													</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.leadAllocation}
												</div>

												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>Target CPL </label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.CPL}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "148px" }}>currency </label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.currency}
												</div>
											</div>
										</div>
									))}
								</p>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									class="btn add-button"
									data-dismiss="modal">
									Close
								</button>
							</div>
						</div>
					</div>
				</div>

				{/* sandeep-task-3046-added code data-keyboard="false" data-backdrop="static" to handle popup box closing from outside click */}
				<div
					class="modal"
					id="myModal"
					aria-hidden="true"
					data-keyboard="false"
					style={{ backgroundColor: "rgba(0,0,0,.4)" }} //karan-task-3371-background defocused
					data-backdrop="static">
					<div class="modal-dialog modal-lg">
						<div class="modal-content">
							<div class="modal-header custom-classPopup">
								<h4
									class="modal-title"
									style={{ color: "#144999", paddingRight: "320px" }}>
									<font color="white">RFP Submit</font>
								</h4>
								<button
									type="button"
									className={this.state.buttonDisplayIndustry}
									class="cancel-button"
									style={{
										fontSize: 15,
										color: "#ffffff",
										paddingLeft: "338px",
									}}
									data-dismiss="modal">
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div class="table-responsive-lg">
									<span style={{ color: "red" }}>
										{" "}
										{this.state.errors.unchecked
											? this.state.errors.unchecked
											: ""}
									</span>
									<table class=" table table-striped table-bordered">
										<thead>
											<tr>
												<th>Publisher Name</th>
												<th>Allocate</th>
												<th>Acknowledge Status</th>
												<th>Timestamp</th>
											</tr>

											{this.state.publisherList.map((publisherList, i) => (
												<tr>
													<td>{publisherList.publisherName}</td>
													{publisherList.status == "Assign" ||
													publisherList.status == "submittedByPublisher" ? (
														<td>
															<Checkbox defaultChecked disabled></Checkbox>
														</td>
													) : (
														<td>
															<Checkbox
																id="Checked"
																name="Checked"
																onChange={this.handleCheckPublisher.bind(
																	this,
																	i
																)}></Checkbox>
														</td>
													)}
													{publisherList.acknowledgeStatus === "Yes" ? (
														<td>{"Acknowledged"}</td>
													) : publisherList.acknowledgeStatus === "No" ? (
														<td>{"No Response"}</td>
													) : (
														<td>
															{publisherList.acknowledgeStatus
																? publisherList.acknowledgeStatus
																: "NA"}
														</td>
													)}

													{publisherList.acknowledgeStatus === "Yes" ? (
														<td>{publisherList.timestamp}</td>
													) : (
														<td>{"NA"}</td>
													)}
												</tr>
											))}
										</thead>
									</table>
								</div>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-info "
									disabled={this.state.btnSubmitPublisher}
									onClick={this.submitPublisherList.bind(this)}
									style={{
										backgroundColor: "rgb(20, 73, 153)",
										color: "white",
										width: "min-content",
										height: "31px",
										fontSize: "14px",
									}}>
									Submit
								</button>
								&nbsp;
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}

RequestForProposalExsisting.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(RequestForProposalExsisting)
);
