/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Sanjana Godbole
@Creation Date:24-08-2020
@Description: New UI  for Request For Proposal For Publisher 

*/

import React from "react";
import PropTypes from "prop-types";
import { Icon, Input, Tooltip} from "antd"; //rutuja 2832 removing checkbox and button as never used in the file
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import "./requestForProposalPublisher.css";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import PublisherNavigation from "../layouts/publisherNavPage";
import Loader from "../campaignUI/Loader";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3767-added default header
const Swal = require("sweetalert2");
// import { publisherBidding } from '../../../../routes/emailSend';
// import { publisherBidding } from '../../../routes/emailSend';
/* rutuja 2832 commenting  below var as never used in the file
const queryString = require("query-string");

var biddingCount = 0;
var countryName = [];
var marketingChannel = [];

const channel = [
	
	{ id: "TeleMarketing", name: "TeleMarketing" },
	{ id: "Email", name: "Email" },
	{ id: "Email/Telemarketing", name: "Email/Telemarketing" },
	{ id: "Display", name: "Display" },
	{ id: "Programmatic", name: "Programmatic" },
	{ id: "Social", name: "Social" },
	{ id: "Hybrid", name: "Hybrid" },
	{ id: "Others", name: "Others" },
]; */

class RequestForProposalForPublisher extends React.Component {
	constructor() {
		super();
		this.state = {
			GetBiddingDetail: [],
			biddingDocument: [],
			downloadFiles: [],
			campID: "",
			value: null,
			campaignDetails: [],
			arrayValue: [],
			publisherBidding: [],
			errors: {},
			reerrors: {},
			biddingerror: {},
			alertBorder: "",
			disable: "",
			acknowledgeBtnStatus: false,
			unique: [],
			docType: "",
			biddingType: "",
			isNetCPL: false,
			netCPL: "",
			tempCountryAllocation: [],
			countryWiseAllocationArray: [],
			geoLeadChk: "No",
			countrywiseLeadAllocation: "No",
			countrywiseLeadDisplay: "none",
			countrywiseLeadDisplay2: "none",
			countrywiseLeadDisplay3: "none",
			countrywiseLeadDisplay4: "none",
			files: [],
			audienceReachFile: "",
			audienceReachValidate: false,
			audienceReachError: {},
			// leadCount:"", //task-3645 temprory commented
			// isCheckedTele:false,
			// isCheckedDisplay: false,
			// isCheckedHybrid: false,isCheckedSocial: false,isCheckedEmail: false,isCheckedOther: false,isCheckedProgramming: false,isCheckedEmailTele: false,
			//  marketingChannelNew:[{'Telemarketing':'No','Display':'No','Hybrid':'No','Social':'No','Email':'No','Other':'No','Programming':'No','EmailTelemarketing':'No'}]
		};
		this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.campaignDetails = this.campaignDetails.bind(this);
		this.RFPdocumentDownloadABM = this.RFPdocumentDownloadABM.bind(this);
		this.RFPdocumentDownloadSuppression =
			this.RFPdocumentDownloadSuppression.bind(this);
		this.RFPdocumentDownloadExclusion =
			this.RFPdocumentDownloadExclusion.bind(this);
		this.RFPdocumentDownloadOther = this.RFPdocumentDownloadOther.bind(this);
		this.handlebackButton = this.handlebackButton.bind(this);
		this.toggleChange = this.toggleChange.bind(this);
		this.geoleadChange = this.geoleadChange.bind(this); //sunita-task- 3645- added handle change for geoleadchange
		// this.submitLead=this.submitLead.bind(this);
		this.handleChangeAudienceReachFileUpload =
			this.handleChangeAudienceReachFileUpload.bind(this); //Sandeep -task-3808-Demo-RFP- Create-Audience Match & Audience Reach
		this.loadingRef = React.createRef(); // shows the Loader
		this.closeLoadingRef = React.createRef(); // hides the Loader
	}

	/**
	 * @author Sandeep Dhawale
	 * @param  Description handleChangeAudienceReachFileUpload
	 * @return Description handle change for AudienceReach File
	 */

	handleChangeAudienceReachFileUpload(e) {
		// e.preventDefault();
		// const { name, value } = e.target; rutuja 2832 commenting name and value as never used in the file
		let errors = {};
		let reerrors = {};
		let files = e.target.files;
		//this.state.files = files[0];	//Chaitanya-2898-Removed Console warning
		this.setState(({files:files[0]}))
		let filesData1 = e.target.files[0];
		let audienceReachError = {};
		var ext;
		if (filesData1 == null) {
			ext = "";
			var label = document.getElementById("clientFilename");
			label.innerHTML = "No File Choosen";
		} else {
			ext = filesData1.name.split(".").pop();
			let label = document.getElementById("clientFilename"); //rutuja 2832
			label.innerHTML =
				'<li id="uploadNameTruncate">' + filesData1.name + "</li>";
		}

		if (ext.toString() === "xlsx" || ext.toString() === "XLSX") {		//Chaitanya-2898-Removed Console warning
			errors["clientuploadsuccess"] = "File Uploaded Successfully";
			reerrors["clientfileError"] = "";
			audienceReachError["errors"] = "";
			this.setState({
				errors,
				reerrors,
				audienceReachValidate: true,
				alertBorder: "0px solid red",
				audienceReachError: audienceReachError,
			});
		} else {
			reerrors["clientfileError"] =
				"File Invalid Please Upload file with .xlsx format";
			errors["clientuploadsuccess"] = "";
			this.setState({
				reerrors: reerrors,
				errors: errors,
				audienceReachValidate: false,
			});
		}
		e.target.files = null;
	}

	toggleChange(i, e) {
		const { name} = e.target; //rutuja 2832 removing id and value as never used in the file
		let publisherBidding = [...this.state.publisherBidding];
		if (e.target.checked == true) {
			// e.target.name='Yes'
			publisherBidding[i] = { ...publisherBidding[i], [name]: "Yes" };
		} else {
			//   e.target.name='No'
			publisherBidding[i] = { ...publisherBidding[i], [name]: "No" };
		}
		this.setState({ publisherBidding: publisherBidding }, function () {
			console.log(this.state.publisherBidding);
		});
	}

	//sunita-task-3645-added handle change for checking the checkbox
	geoleadChange = () => {
		//   alert("inside geoleadChange");
		this.setState(
			{
				isCheckedGeoLead: !this.state.isCheckedGeoLead,
			},
			function () {
				if (this.state.isCheckedGeoLead == false) {
					//  alert("inside if");
					this.setState({
						geoLeadChk: "No",
						geoLeadDisplay: "none",
					});
				} else {
					//  alert("inside else");
					this.setState({
						geoLeadChk: "Yes",
						geoLeadDisplay: "block",
					});
				}
			}
		);
	}; //end of geoleadchange

	openCountryWiseLeadAllocation() {
		// alert("inside opencountrywiseleadallocation");
		var parsed= this.props.location.state;//Somnath Task-3935, get data from history

		//sunita-task-3645- api added here for getting country wise lead allocation data
		let proposalID = parsed.campID;
		//  alert("proposalID=>"+proposalID);
		fetch(
			"requestForProposal/getCountryWiseLeadForRFP?proposalID=" + proposalID
		)
			.then((res) => res.json())
			.then((tempCountryAllocation) => {
				//  alert("countrywiseleadallocation=>"+JSON.stringify(tempCountryAllocation));

				this.setState({
					tempCountryAllocation: tempCountryAllocation,
					countryWiseAllocationArray: tempCountryAllocation,
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	handlebackButton(e) {
		e.preventDefault();
		//const { user } = this.props.auth; rutuja 2832 commenting as never used in the file
		this.props.history.push("/newPublisherDashboard"); //karan-task-3717-replace query params
	}
	RFPdocumentDownloadABM(e) {
		var campID = e.target.id;
		fetch("publisherDashboard/downloadRFPDocumentABM?campID=" + campID)
			.then((r) => r.blob())
			.then((downloadFiles) => {
				this.setState({ downloadFiles: downloadFiles, docType: "ABM" });
			})
			.then(this.handleFileShowDownload);
	}

	RFPdocumentDownloadSuppression(e) {
		var campID = e.target.id;
		fetch("publisherDashboard/downloadRFPDocumentSuppression?campID=" + campID)
			.then((r) => r.blob())
			.then((downloadFiles) =>
				this.setState({
					downloadFiles: downloadFiles,
					campID: campID,
					docType: "Suppression",
				})
			)
			.then(this.handleFileShowDownload);
	}

	RFPdocumentDownloadExclusion(e) {
		var campID = e.target.id;
		fetch("publisherDashboard/downloadRFPDocumentExclusion?campID=" + campID)
			.then((r) => r.blob())
			.then((downloadFiles) =>
				this.setState({
					downloadFiles: downloadFiles,
					campID: campID,
					docType: "Exclusion",
				})
			)
			.then(this.handleFileShowDownload);
	}

	RFPdocumentDownloadOther(e) {
		var campID = e.target.id;
		fetch("publisherDashboard/downloadRFPDocumentOther?campID=" + campID)
			.then((r) => r.blob())
			.then((downloadFiles) =>
				this.setState({
					downloadFiles: downloadFiles,
					campID: campID,
					docType: "Other",
				})
			)
			.then(this.handleFileShowDownload);
	}

	campaignDetails(e) {
		e.preventDefault(); //Supriya Gore, Task: 4100 - after refreshing page not displaying specification and added # in url so did that change
		var campID = e.target.id;

		this.setState({ campID: campID });
		//alert("page3")//Somnath Task-3935, Redirect URL
		this.props.history.push("/publisherRfpCampaignSpecification",{proposalID:campID})
		// window.location.href =
		// 	"/publisherRfpCampaignSpecification?proposalID=" + campID;
		// fetch("agencyDashboard/getRfpCampaignDetails?campID="+campID)
		// .then(res=>res.json())
		// .then(campaignDetails=>{
		//   this.setState({campaignDetails:campaignDetails})
		// }).catch(function(err){console.log(err)});
	}

	hideAlert() {
		//alert("Inside hide");
		this.setState({ disable: "drpdisable" });
		return;
	}

	handleChange(i, e) {
		const { name, value } = e.target;
		if (name.toString() === "CPL") {		//Chaitanya-2898-Removed Console warning
			let publisherBidding = [...this.state.publisherBidding];
			publisherBidding[i] = {
				...publisherBidding[i],
				[name]: value,
				tempCPL: value,
			};
			//this.state.publisherBidding = [...publisherBidding];	//Chaitanya-2898-Removed Console warning
			publisherBidding = [...publisherBidding];
			this.setState({publisherBidding : publisherBidding});
		}
		let publisherBidding = [...this.state.publisherBidding];
		publisherBidding[i] = { ...publisherBidding[i], [name]: value };
		//this.state.publisherBidding = [...publisherBidding];	//Chaitanya-2898-Removed Console warning
		publisherBidding = [...publisherBidding];
		this.setState({publisherBidding : publisherBidding});
		//  alert(JSON.stringify(this.state.publisherBidding))
	}

	componentWillMount() {
		
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			//const { user } = this.props.auth; //rutuja 2832 commenting as never used int he file
			var parsed,campID;
			
			//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
 if(this.props.location.state!==undefined){
	parsed= this.props.location.state;
	campID=parsed.campID;
	
  }
//Somnath Task-3935, Comment Below code
//   else
//   {
// 	parsed = queryString.parse(this.props.location.search);
// 	campID = parsed.campID; 
//   }



			// Get RFP Campaign Details
			fetch("publisherDashboard/rfpBiddingDetail?campID=" + campID)
				.then((res) => res.json())
				.then((GetBiddingDetail) => {
					// alert("GetBiddingDetail=>"+JSON.stringify(GetBiddingDetail));
					this.setState(
						{
							GetBiddingDetail: GetBiddingDetail,
							biddingType: GetBiddingDetail[0].biddingType,
							audienceReach: GetBiddingDetail[0].audienceReach,
						},
						function () {
							//  alert("biddingtype=>"+this.state.biddingType);
							this.setState({
								netCPL:
									this.state.GetBiddingDetail[0].netCPL != ""
										? this.state.GetBiddingDetail[0].netCPL
										: "",
							});
						}
					);
				})
				.catch(function (err) {
					console.log(err);
				});

			// Get RFP Bidding Details Either country wise or Combine.
			//Sonali-4072-removing user id from the below call
			fetch(
				"requestForProposal/biddingBasedOnType?campID=" +
					campID 
			)
				.then((res) => res.json())
				.then((publisherBidding) => {
					//  alert("publisherBiddingwillmount"+JSON.stringify(publisherBidding))
					// var  publisherBiddingDetails=[]
					if (publisherBidding.length > 0) {
						let acknowledgeBtnStatus = false;
						let acknowledgeStatus = publisherBidding[0].acknowledgeStatus;
						if (acknowledgeStatus === "Yes") {
							acknowledgeBtnStatus = true;
						}
						// publisherBiddingDetails= publisherBidding.map((e) => Object.assign({}, e, this.state.marketingChannelNew));
						// for(let i=0;i<publisherBidding.length;i++)
						// {
						//   publisherBiddingDetails.push(publisherBidding[i],this.state.marketingChannelNew[0])
						// }
						// let leadCount = publisherBidding[0].leadCount; //task-3645 temprory commented
						// alert("leadcount=>"+leadCount); //task-3645 temprory commented
						// this.setState({acknowledgeBtnStatus:acknowledgeBtnStatus,leadCount});//task-3645 temprory commented
						this.setState({ acknowledgeBtnStatus: acknowledgeBtnStatus });
					}
					this.setState({ publisherBidding: publisherBidding }, function () {
						this.setState({
							isNetCPL:
								this.state.publisherBidding !== undefined
									? this.state.publisherBidding[0].netCPL > 0
										? true
										: false
									: false,
						});
					});
				})
				.catch(function (err) {
					console.log(err);
				});

			// Get RFP Bidding document Details
			let typeArray = [];
			fetch("publisherDashboard/rfpDocumentDetail?campID=" + campID)
				.then((res) => res.json())
				.then((biddingDocument) => {
					this.setState({ biddingDocument: biddingDocument });
					for (var i = 0; i < biddingDocument.length; i++) {
						typeArray.push(biddingDocument[i].type);
					}
					function onlyUnique(value, index, self) {
						return self.indexOf(value) === index;
					}
					var unique = typeArray.filter(onlyUnique);
					this.setState({ unique: unique });
				})
				.catch(function (err) {
					console.log(err);
				});

			//sunita-task-3645- api added here for getting country wise lead allocation data
			let proposalID = parsed.campID;
			//  alert("proposalID=>"+proposalID);
			fetch(
				"requestForProposal/getCountryWiseLeadForRFP?proposalID=" + proposalID
			)
				.then((res) => res.json())
				.then((tempCountryAllocation) => {
					// alert("countryAllocationwillmount=>"+JSON.stringify(tempCountryAllocation));
					//   let publisherBidding = [...this.state.publisherBidding];
					//  if(publisherBidding.length>0) {
					//   let acknowledgeBtnStatus=false;
					//   let acknowledgeStatus=publisherBidding[0].acknowledgeStatus;
					//   if(acknowledgeStatus==='Yes'){
					//     acknowledgeBtnStatus=true;
					//   }
					//   this.setState({acknowledgeBtnStatus:acknowledgeBtnStatus});

					//  }

					// let acknowledgeBtnStatus=false;
					//   let acknowledgeStatus= [...this.state.publisherBidding[0].acknowledgeStatus];
					//   if(acknowledgeStatus==='Yes'){
					//     acknowledgeBtnStatus=true;
					//   }

					// if((tempCountryAllocation.length >0 && this.state.biddingType == "Combine"))

					// if((tempCountryAllocation.length>0 && this.state.biddingType == "Combine" && this.state.acknowledgeBtnStatus == true))
					if (
						tempCountryAllocation.length > 0 &&
						this.state.biddingType.toString() === "Combine"		//Chaitanya-2898-Removed Console warning
					) {
						// if(acknowledgeBtnStatus == 'Yes'){
						// alert("inside yes geoleadchk");
						//   acknowledgeBtnStatus = true;
						this.setState({ geoLeadDisplay: "block", geoLeadChk: "Yes" });
						// }
					} else {
						//  alert("inside no geoleadchk");
						this.setState({ geoLeadDisplay: "none", geoLeadChk: "No" });
					}

					this.setState({
						tempCountryAllocation: tempCountryAllocation,
						countryWiseAllocationArray: tempCountryAllocation,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} // componentDidmount

	validateForm() {
		//let fields = this.state.fields; rutuja 2832 never used in the file
		//let errors = {}; rutuja 2832 never used in the file
		let biddingerror = {};
		var monthlyLeadValidate = true;
		var dailyLeadValidate = true;
		var totalLeadValidate = true;
		var cplValidate = true;
		var marketingChannelValidate = true;
		let formIsValid = false;
		//let countryName = []; rutuja 2832 never used in the file
		let countryNameMonthly = [];
		let countryNameDaily = [];
		let countryNameTotal = [];
		let countryNameCPL = [];
		var biddingValCheck = 0;
		var commonValidate = true;
		let chkAllValid = false;
		let biddingCount = 0;
		let biddingType = this.state.biddingType;
		let audienceReachError = {};
		// alert(biddingType);
		//var pattern = new RegExp(/^[1-9]+[0-9]*$/);
		// alert("publisherBidding"+JSON.stringify(this.state.publisherBidding))

		// alert("publisherBiddinglength="+publisherBiddinglength);
		var publisherBiddingArray = this.state.publisherBidding;
		// alert("publisherBiddingArray"+JSON.stringify(publisherBiddingArray))
		var publisherBiddinglength = publisherBiddingArray.length;
		for (let i = 0; i < publisherBiddinglength; i++) {
			let monthlyLead = publisherBiddingArray[i].monthlyLead;
			let dailyLead = publisherBiddingArray[i].dailyLead;
			let totalLead = publisherBiddingArray[i].totalLead;
			let CPL = publisherBiddingArray[i].CPL || this.state.netCPL;
			// let marketingChannel=publisherBiddingArray[i].marketingChannel;
			/* rutuja 2832 commented below var as never used in the fil
			let Email = publisherBiddingArray[i].Email;
			let EmailTelemarketing = publisherBiddingArray[i].EmailAndTelemarketing;
			let Programming = publisherBiddingArray[i].Programming;
			let Hybrid = publisherBiddingArray[i].Hybrid;*/

			// let marketingChannel=publisherBiddingArray[i].marketingChannel;
			// alert("marketingChannel"+marketingChannel)
			let country = publisherBiddingArray[i].country;
			if (
				monthlyLead == "" ||
				monthlyLead == undefined ||
				monthlyLead == null ||
				Number(monthlyLead) === "0"		//Chaitanya-2898-Removed Console warning
			) {
				if (biddingCount === 0) {
					chkAllValid = false;
					biddingValCheck++;
					biddingerror["one"] =
						"Please enter monthly lead allocation for atleast one country";
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
				} else {
					if (dailyLead == "" || dailyLead == undefined || dailyLead == null) {
					} else {
						var pattern = new RegExp(/^[0-9]*$/);
						if (!pattern.test(dailyLead)) {
							dailyLeadValidate = false;
							if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
								countryNameDaily.push(country);
								biddingerror["dailyLead"] =
									"Please enter daily lead only in numeric for  " +
									countryNameDaily.toString().toLowerCase();
							} else {
								biddingerror["dailyLead"] =
									"Please enter daily lead only in numeric for region";
							}
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "1px solid red",
							});
							return dailyLeadValidate;
						} else {
							dailyLeadValidate = true;
							biddingerror["dailyLead"] = "";
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "0px solid red",
							});
						}
					}
					if (totalLead == "" || totalLead == undefined || totalLead == null) {
					} else {
						let pattern = new RegExp(/^[0-9]*$/); // rutuja 2832 changed to let
						if (!pattern.test(totalLead)) {
							totalLeadValidate = false;
							if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
								countryNameTotal.push(country);
								biddingerror["totalLead"] =
									"Please enter max. lead available only in numeric for  " +
									countryNameTotal.toString().toLowerCase();
							} else {
								biddingerror["totalLead"] =
									"Please enter max. lead available only in numeric for region";
							}
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "1px solid red",
							});
							return totalLeadValidate;
						} else {
							totalLeadValidate = true;
							biddingerror["totalLead"] = "";
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "0px solid red",
							});
						}
					}
					if (CPL == "" || CPL == undefined || CPL == null) {
					} else {
						let pattern = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/);
						if (!pattern.test(CPL)) {
							cplValidate = false;
							if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
								countryNameCPL.push(country);
								biddingerror["cpl"] =
									"Please enter target cpl only in numeric for  " +
									countryNameCPL.toString().toLowerCase();
							} else {
								biddingerror["cpl"] =
									"Please enter target cpl only in numeric for region ";
							}
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "1px solid red",
							});
							return cplValidate;
						} else {
							cplValidate = true;
							biddingerror["cpl"] = "";
							this.setState({
								biddingerror: biddingerror,
								alertBorder: "0px solid red",
							});
						}
					}
				}
			} //End of if check undefined
			else {
				biddingCount++;
				chkAllValid = true;
				if (
					monthlyLead == "" ||
					monthlyLead == null ||
					monthlyLead == undefined
				) {
					monthlyLeadValidate = false;
					if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
						countryNameMonthly.push(country);
						biddingerror["monthlyLead"] =
							"Please enter only numeric values for  " +
							countryNameMonthly.toString().toLowerCase();
					} else {
						biddingerror["monthlyLead"] =
							"Please enter only numeric values for region";
					}
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
					return monthlyLeadValidate;
				} else {
					let pattern = new RegExp(/^[1-9]+[0-9]*$/); //rutuja 2832 changed to let
					if (!pattern.test(monthlyLead)) {
						monthlyLeadValidate = false;
						if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
							countryNameMonthly.push(country);
							biddingerror["monthlyLead"] =
								"Please enter monthly lead only in numeric for  " +
								countryNameMonthly.toString().toLowerCase();
						} else {
							biddingerror["monthlyLead"] =
								"Please enter monthly lead only in numeric for region";
						}
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "1px solid red",
						});
						return monthlyLeadValidate;
					} else {
						monthlyLeadValidate = true;
						biddingerror["monthlyLead"] = "";
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "0px solid red",
						});
					}
				}

				if (dailyLead == "" || dailyLead == undefined || dailyLead == null) {
					dailyLeadValidate = false;
					if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
						countryNameDaily.push(country);
						biddingerror["dailyLead"] =
							"Please enter daily leads for  " +
							countryNameDaily.toString().toLowerCase();
					} else {
						biddingerror["dailyLead"] = "Please enter daily leads for region";
					}
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
					return dailyLeadValidate;
				} else {
					let pattern = new RegExp(/^[1-9]+[0-9]*$/); // rutuja 2832 changed varto let
					if (!pattern.test(dailyLead)) {
						dailyLeadValidate = false;
						if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
							countryNameDaily.push(country);
							biddingerror["dailyLead"] =
								"Please enter daily lead only in numeric for  " +
								countryNameDaily.toString().toLowerCase();
						} else {
							biddingerror["dailyLead"] =
								"Please enter daily lead only in numeric for region";
						}
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "1px solid red",
						});
						return dailyLeadValidate;
					} else {
						dailyLeadValidate = true;
						biddingerror["dailyLead"] = "";
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "0px solid red",
						});
					}
				}
				if (totalLead == "" || totalLead == undefined || totalLead == null) {
					totalLeadValidate = false;
					if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
						countryNameTotal.push(country);
						biddingerror["totalLead"] =
							"Please enter  max. leads available for  " +
							countryNameTotal.toString().toLowerCase();
					} else {
						biddingerror["totalLead"] =
							"Please enter  max. leads available for region";
					}
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
					return totalLeadValidate;
				} else {
					let pattern = new RegExp(/^[1-9]+[0-9]*$/); //rutuja 2832 changed to let
					if (!pattern.test(totalLead)) {
						totalLeadValidate = false;
						if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
							countryNameTotal.push(country);
							biddingerror["totalLead"] =
								"Please enter max. lead available only in numeric for  " +
								countryNameTotal.toString().toLowerCase();
						} else {
							biddingerror["totalLead"] =
								"Please enter max. lead available only in numeric for region";
						}
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "1px solid red",
						});
						return totalLeadValidate;
					} else {
						totalLeadValidate = true;
						biddingerror["totalLead"] = "";
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "0px solid red",
						});
					}
				}
				if (CPL == "" || CPL == undefined || CPL == null) {
					cplValidate = false;
					if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
						countryNameCPL.push(country);
						biddingerror["cpl"] =
							"Please enter cpl for  " +
							countryNameCPL.toString().toLowerCase();
					} else {
						biddingerror["cpl"] = "Please enter cpl for region";
					}
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "1px solid red",
					});
					return cplValidate;
				} else {
					let pattern = new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/);
					if (!pattern.test(CPL)) {
						cplValidate = false;
						if (biddingType.toString() === "Geowise") {		//Chaitanya-2898-Removed Console warning
							countryNameCPL.push(country);
							biddingerror["cpl"] =
								"Please enter target cpl only in numeric for  " +
								countryNameCPL.toString().toLowerCase();
						} else {
							biddingerror["cpl"] =
								"Please enter target cpl only in numeric for region";
						}
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "1px solid red",
						});
						return cplValidate;
					} else {
						// alert(CPL)
						cplValidate = true;
						biddingerror["cpl"] = "";
						this.setState({
							biddingerror: biddingerror,
							alertBorder: "0px solid red",
						});
					}
				}
				//
				// if (Email=="Yes"||){

				// }
			} // End of Else Block of check undefined

			if (Number(biddingCount) === 0) {		//Chaitanya-2898-Removed Console warning
				if (publisherBiddinglength == biddingValCheck) {
				} else {
					chkAllValid = true;
					biddingerror["one"] = "";
					this.setState({
						biddingerror: biddingerror,
						alertBorder: "0px solid red",
					});
				}
			}
			//Sandeep -task-3808-Demo-RFP- Create-Audience Match & Audience Reach
			if (
				this.state.audienceReach.toString() === "Yes" &&		//Chaitanya-2898-Removed Console warning
				this.state.audienceReachValidate == false
			) {
				commonValidate = false;
			}
			//Sandeep -task-3808-Demo-RFP- Create-Audience Match & Audience Reach
			if (this.state.audienceReach.toString() === "Yes" && this.state.files == "") {		//Chaitanya-2898-Removed Console warning
				commonValidate = false;
				audienceReachError["errors"] =
					"Please Upload Appropriate File for Proceed";
				this.setState({
					audienceReachError: audienceReachError,
					alertBorder: "1px solid red",
				});
			}
			if (
				monthlyLeadValidate === false ||
				dailyLeadValidate === false ||
				totalLeadValidate === false ||
				cplValidate === false ||
				marketingChannelValidate === false ||
				chkAllValid === false
			) {
				commonValidate = false;
			}
		} // End of For Loop
		if (commonValidate === true) {
			formIsValid = true;
		} else {
			formIsValid = false;
		}
		// alert("Form is Valid "+formIsValid);
		return formIsValid;
	} //End of validateForm()

	submitBidding() {
		if (this.validateForm() === false) {
			//  alert("validate")

			return;
		} else {
			// alert("In Submit");
			// this.setState({disable:"drpdisable"});
			this.loadingRef.current.click();
			const { user } = this.props.auth;
			var parsed= this.props.location.state;//Somnath Task-3935, get data from history
			var campID = parsed.campID;
			//var pID = user.id; rutuja 2832 commented as never used in the file
			let self = this;
			// var data = new FormData();
			// data.append("isCheckedDisplay",isCheckedDisplay);
			// let audienceReachFile=this.state.filesData1;
			// let audienceReachFile=this.state.filesData1.nam
			//Sandeep -task-3808-Demo-RFP- Create-Audience Match & Audience Reach
			var data = new FormData();
			data.append("audienceReachFile", this.state.files);
			//sonali-4072-removing user 
			//data.append("user", JSON.stringify(user));
			data.append(
				"publisherBidding",
				JSON.stringify(this.state.publisherBidding)
			);
			data.append("audienceReach", this.state.audienceReach);
			data.append("campID", campID);
			data.append("audienceReachValidate", this.state.audienceReachValidate);
			fetch("/publisherDashboard/submitPublisherBidding", {
				method: "POST",
				headers: {},
				body: data,
			})
				.then((response) => response.json())
				.then(function (response) {
					//  alert("selected values====="+JSON.stringify(response))
					// success message with response
					if (response.success == true) {
						// alert("befor hide")
						//Chaitanya-2898-Removed Console warning
							self.hideAlert();
						//alert("afterhide");
						Swal.fire({
							text: "Publisher Bidding Submitted Successfully",
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							//SOnali-3369-redirect to publisherRFPAnalysis after submitting RFP details
						
							preConfirm: () => {
								// window.location.href =
								// 	"/publisherRFPAnalysis?pID=" + user.id + "&campID=" + campID;
								  //sonali-3978-VAPT
								  self.props.history.push(`/publisherRFPAnalysis`,{pID:user.id,campID:campID})
								  window.location.reload(); //rutuja -4393 page refresh issue
								
							},
						});
					}
				})
				.catch(function (err) {
					console.log(err);
				});
			// this.setState({disable:"drpdisable"});
		}
	} // End of submitBidding

	Biddingview(e) {
		var parsed= this.props.location.state;//Somnath Task-3935, get data from history
		var campID = parsed.campID;
		window.location.href = "/requestForProposalBidding?campID=" + campID;
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description download the RFP Document
	 * @return Description return All The File Download in Zip
	 */
	RFPdocumentDownload(e) {
		var campID = e.target.id;
		fetch("publisherDashboard/downloadRFPDocument?campID=" + campID)
			.then((r) => r.blob())
			.then((downloadFiles) =>
				this.setState({ downloadFiles: downloadFiles, campID: campID })
			)
			.then(this.handleFileShowDownload);
	}
	handleFileShowDownload(blob) {
		let zipFileName;
		if (this.state.docType === "ABM") {
			zipFileName = this.state.campID + "RFP_ABM_Document.zip";
		}
		if (this.state.docType === "Suppression") {
			zipFileName = this.state.campID + "RFP_Suppression_Document.zip";
		}
		if (this.state.docType === "Exclusion") {
			zipFileName = this.state.campID + "RFP_Exclusion_Document.zip";
		}
		if (this.state.docType === "Other") {
			zipFileName = this.state.campID + "RFP_Other_Document.zip";
		}

		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/zip",
		});
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}
		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileName;
		link.click();
	}
	acknowledgeStatus(e) {
		// alert("inside ack button");
		var parsed= this.props.location.state;//Somnath Task-3935, get data from history
		var campID = parsed.campID;
		//const { user } = this.props.auth; rutuja 2832 commented as never used in the file
			//Sonali-4072-removing user id from the below call
		fetch(
			"requestForProposal/RFPAcknowledge?biddingCampID=" +
				campID 
			
		)
			.then((res) => res.json())
			.then((publisherBidding) => {
				// alert("ackstatuspublisherbidding=>"+JSON.stringify(publisherBidding));
				this.setState({ publisherBidding: publisherBidding });
				if (publisherBidding.length > 0) {
					let acknowledgeBtnStatus = false;
					let acknowledgeStatus = publisherBidding[0].acknowledgeStatus;
					if (acknowledgeStatus === "Yes") {
						acknowledgeBtnStatus = true;
					}

					//sunita-task-3645-added code for showing countrywise lead allocation popup and it's msg as per condition.
					if (
						this.state.biddingType.toString() === "Combine" &&		//Chaitanya-2898-Removed Console warning
						acknowledgeStatus.toString() === "Yes"					//Chaitanya-2898-Removed Console warning
					) {
						// alert("inside if");
						acknowledgeBtnStatus = true;
						// geoLeadDisplay = "block";
						// geoLeadChk = "Yes";
						this.setState({
							acknowledgeBtnStatus: acknowledgeBtnStatus,
							geoLeadDisplay: "block",
							geoLeadChk: "Yes",
						});
					} else {
						this.setState({
							acknowledgeBtnStatus: acknowledgeBtnStatus,
							geoLeadDisplay: "none",
							geoLeadChk: "No",
						});
					}
				}
			})
			.catch(function (err) {
				console.log(err);
			});
	} // End of acknowledgeStatus
	render() {
		const asterisk = { color: "red" };
		return (
			<div>
				<Loader
					loadingRef={this.loadingRef}
					closeLoadingRef={this.closeLoadingRef}
					message="Please wait. Saving the data...."
				/>
				<PublisherNavigation />
				<div
					class="container-fluid"
					style={{ paddingTop: "90px", marginBottom: "60px" }}>
					<div>
						<a href="#/" 	//Chaitanya-2898-Removed Console warning
						style={{ color: "#056eb8" }}>
							<FaArrowAltCircleLeft onClick={this.handlebackButton} size={32} />
						</a>{" "}
					</div>
					{/* //shivani-3308-passed ID for DI label consistency */}
					<div class="col rfpTitle" id="labelDI">
						Request For Proposal
					</div>
					<br />

					<legend
						className="legend labelsubheading"
						style={{ fontFamily: "roboto" }}>
						RFP Campaigns Details :
					</legend>

					<table class="table table-bordered">
						<thead style={{ backgroundColor: "#193D8F" }}>
							<tr>
								{/*sunita-task-3373-added css as per requirement for all below table headings(proposal ID,campname,startdate,endate,bidding closes,marketing channel,campaign type,document,download) */}
								<th
									align="center"
									style={{
										verticalAlign: "top",
										color: "white",
										textAlign: "center",
										fontWeight: "500",
									}}>
									Proposal ID
								</th>
								<th
									style={{
										verticalAlign: "top",
										color: "white",
										textAlign: "center",
										fontWeight: "500",
									}}>
									Campaign Name
								</th>
								<th
									style={{
										width: "7%",
										verticalAlign: "top",
										color: "white",
										textAlign: "center",
										fontWeight: "500",
									}}>
									Start Date
								</th>
								<th
									style={{
										width: "7%",
										verticalAlign: "top",
										color: "white",
										textAlign: "center",
										fontWeight: "500",
									}}>
									End Date
								</th>
								<th
									style={{
										width: "7%",
										verticalAlign: "top",
										color: "white",
										textAlign: "center",
										fontWeight: "500",
									}}>
									Bidding Closes
								</th>
								<th
									style={{
										verticalAlign: "top",
										color: "white",
										textAlign: "center",
										fontWeight: "500",
									}}>
									Marketing Channel
								</th>
								<th
									style={{
										verticalAlign: "top",
										color: "white",
										textAlign: "center",
										fontWeight: "500",
									}}>
									Campaign Type
								</th>
								<th
									style={{
										verticalAlign: "top",
										color: "white",
										textAlign: "center",
										fontWeight: "500",
									}}>
									Document
								</th>
								<th
									style={{
										verticalAlign: "top",
										color: "white",
										textAlign: "center",
										fontWeight: "500",
									}}>
									Download
								</th>
							</tr>
						</thead>
						<tbody>
							{this.state.GetBiddingDetail.map((item) => (
								<tr>
									<td style={{ textAlign: "center" }}>{item.campID}</td>
									<td style={{ textAlign: "center" }}>
										<a href="#/" //Chaitanya-2898-Removed Console warning
										onClick={this.campaignDetails} id={item.campID}>
											{item.campaignName}
										</a>
									</td>
									<td style={{ textAlign: "center" }}>{item.startDate}</td>
									<td style={{ textAlign: "center" }}>{item.endDate}</td>
									<td style={{ textAlign: "center" }}>{item.biddingEndDate}</td>
									<td style={{ textAlign: "center" }}>
										{item.marketingChannel}
									</td>
									<td style={{ textAlign: "center" }}>{item.campaignType}</td>
									<td style={{ textAlign: "center" }}>
										{this.state.biddingDocument.map((doc) => {
											if (Number(doc.campID) === item.campID) {	//Chaitanya-2898-Removed Console warning
												return (
													<p>
														{doc.suppDocName}
														<br />
													</p>
												);
											}
										})}
									</td>
									<td style={{ textAlign: "center" }}>
										{/* <a href="#"  id={item.campID}onClick={this.RFPdocumentDownload.bind(this)}>
                   Download Document
          </a> */}
										{this.state.unique.map((item1) => (
											<div style={{ textAlign: "center" }}>
												{item1 === "ABM" ? (
													<a
														href="#/"	//Chaitanya-2898-Removed Console warning
														id={item.campID}
														onClick={this.RFPdocumentDownloadABM.bind(this)}>
														ABM
													</a>
												) : item1 === "Suppression" ? (
													<a
														href="#/"	//Chaitanya-2898-Removed Console warning
														id={item.campID}
														onClick={this.RFPdocumentDownloadSuppression.bind(
															this
														)}>
														Suppression
													</a>
												) : item1 === "Exclusion" ? (
													<a
														href="#/"	//Chaitanya-2898-Removed Console warning
														id={item.campID}
														onClick={this.RFPdocumentDownloadExclusion.bind(
															this
														)}>
														Exclusion
													</a>
												) : item1 === "Other" ? (
													<a
														href="#/"	//Chaitanya-2898-Removed Console warning
														id={item.campID}
														onClick={this.RFPdocumentDownloadOther.bind(this)}>
														Other
													</a>
												) : (
													""
												)}
											</div>
										))}
									</td>
								</tr>
							))}
						</tbody>
					</table>

					<div class="float-right ack">
						{" "}
						<button
							type="button"
							disabled={this.state.acknowledgeBtnStatus}
							className={this.state.disable + " btn btn-info"}	//Chaitanya-2898-Removed Console warning
							onClick={this.acknowledgeStatus.bind(this)}
							style={{
								backgroundImage: "linear-gradient(to right,#28166f,#007cc3)",
								color: "white",
								width: "min-content",
								height: "31px",
								fontSize: "14px",
							}}>
							Acknowledge
						</button>
					</div>

					<br />
					<br />
					{/*sunita-task-3374-added this bcz button is not showing properly its getting hide*/}

					<legend
						className="legend labelsubheading"
						style={{ fontFamily: "roboto" }}>
						Bidding :
					</legend>

					{/* ======================new code added by sunita */}

					<div class="col-xl-12 col-lg-12 col-md-12  col-sm-12 ">
						{/* //shivani-task 3197-changed label format */}
						{this.state.acknowledgeBtnStatus == true &&
						this.state.biddingType.toString() === "Combine" ? (		//Chaitanya-2898-Removed Console warning
							<div>
								<label className="labelStyle">
									Countryviz lead allocation:
									<Tooltip title=" Please select region and countries.">
										<Icon
											type="question-circle-o"
											style={{
												marginLeft: "-4px",
												marginBottom: "-2px",
												padding: "1px",
												marginTop: "4px",
											}}
										/>
									</Tooltip>
								</label>
								&nbsp;&nbsp;
								{/* {this.state.region.length > 0 ? ( */}
								<input
									type="checkbox"
									checked={this.state.geoLeadChk.toString() === "Yes" ? true : false}		//Chaitanya-2898-Removed Console warning
									value={this.state.geoLeadChk}
									onChange={this.geoleadChange}
								/>
							</div>
						) : (
							""
						)}

						{/* ) 
                  : (
										<input
											type="checkbox"
											checked={this.state.geoLeadChk == "Yes" ? true : false}
											value={this.state.geoLeadChk}
											disabled
											onChange={this.geoleadChange}
										/>
									)}
									 */}

						{this.state.acknowledgeBtnStatus == true &&
						this.state.biddingType.toString() === "Combine" &&		//Chaitanya-2898-Removed Console warning
						this.state.geoLeadChk.toString() === "Yes" ? (			//Chaitanya-2898-Removed Console warning
							//Snehal-Task-3027-Country wise lead allocation display plus circle only when user check checkbox
							<span style={{ display: this.state.geoLeadDisplay }}>
								Please click here to view CountryViz Lead Allocation&nbsp;
								<Icon
									type="plus-circle"
									onClick={this.openCountryWiseLeadAllocation.bind(this)}
									style={{
										height: "15px",
										width: "15px",
										marginLeft: "5px",
									}}
									data-toggle="modal"
									data-target="#geoWiseLead"
								/>
								<p>
									<span
										style={{ display: this.state.countrywiseLeadDisplay }}
										className="errorMessage">
										{" "}
										Please enter countryviz lead allocation{" "}
										{/* //Sandeep-task-3397-error msg issue */}
									</span>
								</p>
								<p>
									<span
										style={{
											display: this.state.countrywiseLeadDisplay2,
										}}
										className="errorMessage">
										{" "}
										Please enter countryviz lead allocation equal to total lead
										allocation {/* //Sandeep-task-3397-error msg issue */}
									</span>
								</p>
								<p>
									<span
										style={{
											display: this.state.countrywiseLeadDisplay3,
										}}
										className="errorMessage">
										Please enter positive numeric value for lead allocation{" "}
									</span>
								</p>
								<p>
									<span
										style={{
											display: this.state.countrywiseLeadDisplay4,
										}}
										className="errorMessage">
										Please enter positive numeric and non-zero value for lead
										allocation
									</span>
								</p>
							</span>
						) : (
							" "
						)}

						<div class="modal fade" id="geoWiseLead" role="dialog">
							<div class="modal-dialog modal-md CountryVizLeadPub">	 {/*Chaitanya-4337-CountryViz Lead Allocation Popup-Rename button & msg needed if blank.*/}
								<div class="modal-content">
									<div
										class="modal-header"
										style={{
											backgroundColor: "#144999",
											color: "white",
											fontSize: "17px",
										}}>
										{" "}
										{/*sunita-task-3744-added css as per requirement */}
										<button
											type="button"
											class="close"
											data-dismiss="modal"
											style={{
												color: "white",
												fontSize: "17px",
												marginTop: "-6px",
											}}>
											{/*sunita-task-3744-added css as per requirement */}
											&times;
										</button>
										<h4
											class="modal-title countryLead1"
											style={{
												fontFamily: "roboto",
												fontSize: "17px !important",
												// color: "rgba(25,61,143,1)",
												color: "white",
												fontWeight: "none !important",
											}}>
											{" "}
											{/*sunita-task-3744-added css as per requirement */}
											CountryViz Lead Allocation &nbsp; ({" "}
											{this.state.countryWiseAllocationArray.length}
											&nbsp;Countries selected)
										</h4>
										<br />
									</div>
									<div class="modal-body">
										
										{
											this.state.countryWiseAllocationArray.length <= 0 ?
											<div >
												<div class="row">
													<div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 ">
														<h5 style={{color: "red"}}>
												 			CountryViz Leads Are Not Allocated
														</h5>
													</div>
												</div>
											</div>	
											:
										<div
											style={{ overflowY: "scroll" }}
											className="countryWiseAllocation_popup">
											{this.state.countryWiseAllocationArray.map(
												(countryWiseAllocationArray, i) => {
													return (
														<span key={i}>
															<div class="row">
																<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
																	&nbsp; &nbsp; &nbsp;
																	{countryWiseAllocationArray.country}
																</div>
																<div
																	class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-4"
																	style={{ marginBottom: "15px" }}>
																	<Input
																		id="leadCount"
																		name="leadCount"
																		disabled="true"
																		defaultValue={
																			// countryWiseAllocationArray.lead
																			countryWiseAllocationArray.leadCount
																		}
																		value={countryWiseAllocationArray.leadCount}
																		// onChange={this.leadAllocationChange.bind(
																		// 	this,
																		// 	i
																		// )}
																	/>
																</div>
															</div>
														</span>
													);
												}
											)}
										</div>
										}
										{/* end of scroll */}
										{/* </p> */}
									</div>
									<div class="modal-footer">
										<div class="float-left">
											<button
												type="button"
												className="btn mr-auto"
												data-dismiss="modal"
												style={{
													fontWeight: "400",
													color: "white",
													fontSize: "15px",
												 borderRadius: "15px", width: "80px",backgroundImage:"linear-gradient(to left, #007CC3,#193D8F)"}}>
													 {/* //rutuja 2832 deleting one style and adding properties in the above style */}
												Close	{/*Chaitanya-4337-CountryViz Lead Allocation Popup-Rename button & msg needed if blank.*/}
											</button>
											{/* <button
															type="button"
															class="btn btn-default"
															data-dismiss="modal"
															style={{ borderRadius: "15px", width: "80px" }}>
															Cancel
														 </button> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* end of geoWise Modal */}
					</div>
					{/* End of Geo wise lead allocation */}

					{/* ========================= */}

					<div class="row">
						<div
							class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mobileLProblem"
							style={{ border: this.state.alertBorder }}>
							<div class="errorMessage">
								{this.state.audienceReachError.errors ? (
									<span>
										{this.state.audienceReachError.errors}
										<br />
									</span>
								) : (
									""
								)}
								{this.state.biddingerror.one ? (
									<span>
										{this.state.biddingerror.one}
										<br />
									</span>
								) : (
									""
								)}

								{this.state.biddingerror.monthlyLeadBlank ? (
									<span>
										{this.state.biddingerror.monthlyLeadBlank}
										<br />
									</span>
								) : this.state.biddingerror.monthlyLead ? (
									<span>
										{this.state.biddingerror.monthlyLead}
										<br />
									</span>
								) : (
									""
								)}

								{this.state.biddingerror.dailyLeadBlank ? (
									<span>
										{this.state.biddingerror.dailyLeadBlank}
										<br />
									</span>
								) : this.state.biddingerror.dailyLead ? (
									<span>
										{this.state.biddingerror.dailyLead}
										<br />
									</span>
								) : (
									""
								)}

								{this.state.biddingerror.totalLeadBlank ? (
									<span>
										{this.state.biddingerror.totalLeadBlank}
										<br />
									</span>
								) : this.state.biddingerror.totalLead ? (
									<span>
										{this.state.biddingerror.totalLead}
										<br />
									</span>
								) : (
									""
								)}

								{this.state.biddingerror.cplBlank ? (
									<span>
										{this.state.biddingerror.cplBlank}
										<br />
									</span>
								) : this.state.biddingerror.cpl ? (
									<span>
										{this.state.biddingerror.cpl}
										<br />
									</span>
								) : (
									""
								)}

								{this.state.biddingerror.marketingChannelBlank ? (
									<span>{this.state.biddingerror.marketingChannelBlank}</span>
								) : this.state.biddingerror.marketingChannel ? (
									<span>{this.state.biddingerror.marketingChannel}</span>
								) : (
									""
								)}
							</div>
						</div>
					</div>
					{this.state.acknowledgeBtnStatus == true ? (
						<span>
							<div>
								<label>
									Audience Reach
									{this.state.audienceReach.toString() === "Yes" ? (		//Chaitanya-2898-Removed Console warning
										<span style={asterisk}>*</span>
									) : (
										""
									)}
								</label>
							</div>
							<span>
								<label
									class=" btn btn-defaulttab  "
									style={{
										height: "32px",
										fontFamily: "sans-serif",
										fontSize: "14px",
										fontWeight: "400",
										borderRadius: "4px",
										backgroundColor: "transparent",
										color: "#193D8F",
										border: "1px solid #193D8F",
										marginRight: "5px",
									}}>
									Choose File
									<input
										style={{ display: "none" }}
										type="file"
										id="abm1"
										name="filesData"
										key={this.state.filesData1}
										onChange={this.handleChangeAudienceReachFileUpload}
										//  onChange={e => this.handleChangeAudienceReachFileUpload(e)}
									/>
								</label>
								<span id="clientFilename">&nbsp;No File Choosen</span>
							</span>

							<div className="successMessage">
								{this.state.errors.clientuploadsuccess}
							</div>
							<div className="errorMessage">
								{this.state.reerrors.clientfileError}
							</div>
							{/* <div className="errorMessage">
												 {this.state.reerrors.clientfileError1}
											 </div> */}

							<div
								style={{
									fontSize: "18px",
									color: "red",
									textAlign: "center",
								}}>
								{this.state.errors.failureMsg2}
							</div>

							<br />
							<br />

							<table class="table table-bordered tableSize">
								<thead style={{ backgroundColor: "#193D8F" }}>
									<tr>
										{/* <th style={{width:"10%"}}>Region</th> */}
										{/*sunita-task-3373-added css as per requirement for all below table headings(country,monthly lead,daily lead,max lead available,target cpl,netcpl,channel and notes) */}
										<th
											style={{
												verticalAlign: "top",
												color: "white",
												textAlign: "center",
												fontWeight: "500",
											}}
											className="columnSize">
											Country
										</th>
										{this.state.biddingType.toString() === "Geowise" &&		//Chaitanya-2898-Removed Console warning
										this.state.tempCountryAllocation.length > 0 ? (
											<th
												style={{
													verticalAlign: "top",
													color: "white",
													width: "9%",
													textAlign: "center",
													fontWeight: "500",
												}}>
												Lead Count
											</th>
										) : (
											""
										)}{" "}
										{/*sunita-task-3645-added column for lead count */}
										<th
											style={{
												verticalAlign: "top",
												width: "9%",
												color: "white",
												textAlign: "center",
												fontWeight: "500",
											}}>
											Monthly Lead
										</th>
										<th
											style={{
												verticalAlign: "top",
												width: "9%",
												color: "white",
												textAlign: "center",
												fontWeight: "500",
											}}>
											Daily Lead
										</th>
										<th
											style={{
												verticalAlign: "top",
												width: "9%",
												color: "white",
												textAlign: "center",
												fontWeight: "500",
											}}>
											Max. Lead Available
										</th>
										{this.state.isNetCPL == false ? (
											<th
												style={{
													width: "9%",
													color: "white",
													textAlign: "center",
													fontWeight: "500",
													verticalAlign: "top",
												}}>
												Target CPL
											</th>
										) : (
											<th
												style={{
													width: "9%",
													color: "white",
													textAlign: "center",
													fontWeight: "500",
													verticalAlign: "top",
												}}>
												Net CPL
											</th>
										)}
										{/* <th style={{width:"10%",color:"white",textAlign: "center"}}>Currency<span style={asterisk}>*</span></th> */}
										<th
											style={{
												verticalAlign: "top",
												color: "white",
												textAlign: "center",
												fontWeight: "500",
											}}>
											Marketing Channel
										</th>
										<th
											style={{
												verticalAlign: "top",
												width: "12%",
												color: "white",
												textAlign: "center",
												fontWeight: "500",
											}}>
											Notes
										</th>
									</tr>
								</thead>
								<tbody>
									{this.state.acknowledgeBtnStatus === true
										? this.state.publisherBidding.map((item, i) => (
												<tr>
													{/* <td>{item.region}</td> */}
													<td
														style={{
															wordBreak: "break-all",
															textAlign: "center",
														}}>
														{item.country}
													</td>

													{/*sunita-task-3645-added column for lead count */}
													{this.state.biddingType.toString() === "Geowise" &&		//Chaitanya-2898-Removed Console warning
													this.state.tempCountryAllocation.length > 0 ? (
														<td style={{ textAlign: "center" }}>
															<input
																type="text"
																id="leadCount"
																name="leadCount"
																defaultValue={item.leadCount}
																onChange={this.handleChange.bind(this, i)}
																className="form-control"
																required
															/>
														</td>
													) : (
														""
													)}
													<td style={{ textAlign: "center" }}>
														{" "}
														<input
															type="text"
															id="monthlyLead"
															name="monthlyLead"
															defaultValue={item.monthlyLead}
															onChange={this.handleChange.bind(this, i)}
															className="form-control"
															required
														/>
													</td>
													<td style={{ textAlign: "center" }}>
														{" "}
														<input
															type="text"
															id="dailyLead"
															name="dailyLead"
															defaultValue={item.dailyLead}
															onChange={this.handleChange.bind(this, i)}
															className="form-control"
															required
														/>
													</td>
													<td style={{ textAlign: "center" }}>
														<input
															type="text"
															id="totalLead"
															name="totalLead"
															defaultValue={item.totalLead}
															onChange={this.handleChange.bind(this, i)}
															className="form-control"
															required
														/>
													</td>
													<td style={{ textAlign: "center" }}>
														{this.state.isNetCPL == true ? (
															<input
																type="text"
																id="CPL"
																name="CPL"
																value={this.state.netCPL}
																onChange={this.handleChange.bind(this, i)}
																className="form-control"
																required
																disabled={true}
															/>
														) : (
															<input
																type="text"
																id="CPL"
																name="CPL"
																defaultValue={item.grossCPL}
																onChange={this.handleChange.bind(this, i)}
																className="form-control"
																required
															/>
														)}
													</td>

													{/* <td  style={{textAlign: "center"}}>  <select type="text" id="currency" name="currency" 
                               style={{height:'35px',border:"1px solid #ddd",
                               backgroundColor: "white"}}
                                  defaultValue={item.currency}
                                  onChange={this.handleChange.bind(this,i)}>

                  <option value="USD" selected>USD - US Dollar</option>
                  <option value="EUR" >EUR - Euro</option>
                  <option value="GBP">GBP - British Pound</option>
                  <option value="INR">INR - Indian Rupee</option>
                  <option value="AUD">AUD - Australian Dollar</option>
                  <option value="CAD">CAD - Canadian Dollar</option>
                  <option value="SGD">SGD - Singapore Dollar</option>
                  <option value="CHF">CHF - Swiss Franc</option>
                  <option value="MYR">MYR - Malaysian Ringgit</option>
                  <option value="JPY">JPY - Japanese Yen</option>
                  <option value="CNY">CNY - Chinese Yuan Renminbi</option>

                  </select>        </td> */}

													<td style={{ textAlign: "left" }}>
														<div class="row">
															<div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
																<input
																	id={"TeleMarketing " + item.country}	//Chaitanya-2898-Removed Console warning
																	type="checkbox"
																	name="Telemarketing"
																	onChange={this.toggleChange.bind(this, i)}
																	defaultChecked={
																		item.Telemarketing == true ? true : false
																	}
																/>
																<label
																	style={{ fontWeight: "500" }}
																	className="tabFontSize">
																	TeleMarketing
																</label>
															</div>
															<div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
																<input
																	id={"display " + item.country}		//Chaitanya-2898-Removed Console warning
																	type="checkbox"
																	name="Display"
																	onChange={this.toggleChange.bind(this, i)}
																	defaultChecked={
																		item.Display == true ? true : false
																	}
																/>

																<label
																	style={{ fontWeight: "500" }}
																	className="tabFontSize">
																	Display
																</label>
															</div>
															<div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
																<input
																	id={"hybrid " + item.country}	//Chaitanya-2898-Removed Console warning
																	type="checkbox"
																	name="Hybrid"
																	onChange={this.toggleChange.bind(this, i)}
																	defaultChecked={
																		item.Hybrid == true ? true : false
																	}
																/>
																<label
																	style={{ fontWeight: "500" }}
																	className="tabFontSize">
																	Hybrid
																</label>
															</div>
														</div>
														<div class="row">
															<div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
																<input
																	id={"social " + item.country}	//Chaitanya-2898-Removed Console warning
																	type="checkbox"
																	name="Social"
																	onChange={this.toggleChange.bind(this, i)}
																	defaultChecked={
																		item.Social == true ? true : false
																	}
																/>
																<label
																	style={{ fontWeight: "500" }}
																	className="tabFontSize">
																	Social
																</label>
															</div>
															<div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
																<input
																	id={"email " + item.country}	//Chaitanya-2898-Removed Console warning
																	type="checkbox"
																	name="Email"
																	onChange={this.toggleChange.bind(this, i)}
																	defaultChecked={
																		item.Email == true ? true : false
																	}
																/>

																<label
																	style={{ fontWeight: "500" }}
																	className="tabFontSize">
																	Email
																</label>
															</div>
															<div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
																<input
																	id={"others " + item.country}	//Chaitanya-2898-Removed Console warning
																	type="checkbox"
																	name="Others"
																	onChange={this.toggleChange.bind(this, i)}
																	defaultChecked={
																		item.Other == true ? true : false
																	}
																/>
																<label
																	style={{ fontWeight: "500" }}
																	className="tabFontSize">
																	Others
																</label>
															</div>
														</div>

														<div class="row">
															<div class="col-xs-12  col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
																<input
																	id={"programming " + item.country}	//Chaitanya-2898-Removed Console warning
																	type="checkbox"
																	name="Programming"
																	onChange={this.toggleChange.bind(this, i)}
																	defaultChecked={
																		item.Programming == true ? true : false
																	}
																/>
																<label
																	style={{ fontWeight: "500" }}
																	className="tabFontSize">
																	Programming
																</label>
															</div>
															<div class="col-xs-12  col-sm-12 col-md-5 col-lg-6 col-xl-6 ">
																<input
																	id={
																		"Email/Telemarketing " + item.country	//Chaitanya-2898-Removed Console warning
																	}
																	type="checkbox"
																	name="EmailAndTelemarketing"
																	onChange={this.toggleChange.bind(this, i)}
																	defaultChecked={
																		item.EmailAndTelemarketing == true
																			? true
																			: false
																	}
																/>
																<label
																	style={{ fontWeight: "500" }}
																	className="tabFontSize">
																	Email/Telemarketing
																</label>
															</div>
														</div>
													</td>
													<td style={{ textAlign: "center" }}>
														<textarea
															style={{
																width: "100%",
																height: "40%",
																resize: "none",
															}}
															value={this.state.campaignCollatral}
															rows="4"
															cols="30"
															id="note"
															name="note"
															onChange={this.handleChange.bind(this, i)}
														/>
													</td>
												</tr>
										  ))
										: ""}
								</tbody>
							</table>
						</span>
					) : (
						""
					)}
					{this.state.acknowledgeBtnStatus == true ? (
						<div align="right">
							{/* Sandeep-task-3196-added margin-bottom for submit button */}
							<button
								type="button"
								className={this.state.disable + " btn btn-info"}	//Chaitanya-2898-Removed Console warning
								onClick={this.submitBidding.bind(this)}
								style={{
									backgroundImage: "linear-gradient(to right,#28166f,#007cc3)",
									color: "white",
									width: "min-content",
									height: "31px",
									fontSize: "14px",
									marginBottom: "75px",
								}}>
								Submit
							</button>
						</div>
					) : (
						""
					)}
				</div>

				{/* end of container */}
				<div class="modal" id="myModal">
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<h4 class="modal-title">RFP Allocation</h4>
								<button type="button" class="close" data-dismiss="modal">
									&times;
								</button>
							</div>
							<div class="modal-body">
								<div class="table-responsive-lg">
									<table class=" table table-striped">
										<thead>
											<tr class="info">
												<th class="table-header">Publisher Name</th>
												<th class="table-header">Allocate</th>
											</tr>
										</thead>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="campDetails" class="modal fade" role="dialog">
					<div class="modal-dialog modal-lg">
						<div class="modal-content">
							<div class="modal-header custom-class">
								<h4
									class="modal-title"
									style={{
										color: "white",
										fontSize: "20px",
										paddingRight: "593px",
									}}>
									Campaign Details
								</h4>
								<button
									type="button"
									class="cancel-button pull-right"
									style={{ fontSize: 15 }}
									data-dismiss="modal">
									&times;
								</button>
							</div>
							<div class="modal-body">
								<p>
									{this.state.campaignDetails.map((campaignDetails) => (
										<div>
											{" "}
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Campaign Name{" "}
													</label>
													:&nbsp;&nbsp;
													{campaignDetails.campaignName}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>
														Campaign Type{" "}
													</label>
													:&nbsp;
													{campaignDetails.campaignType}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4"></div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>Start Date </label>
													:&nbsp;&nbsp;
													{campaignDetails.startDate}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>End Date </label>
													:&nbsp;
													{campaignDetails.endDate}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label>Bidding Closes &nbsp;&nbsp;:</label>
													{campaignDetails.biddingEndDate}&nbsp;&nbsp;&nbsp;
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Bidding Type{" "}
													</label>
													:&nbsp;&nbsp;
													{campaignDetails.biddingType}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>
														Type Of Duation{" "}
													</label>
													:&nbsp;
													{campaignDetails.durationType}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "93px" }}>Duration </label>
													:&nbsp;
													{campaignDetails.duration}&nbsp;&nbsp;&nbsp;
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Marketing Channel{" "}
													</label>
													:&nbsp;&nbsp;
													{campaignDetails.marketingChannel}&nbsp;&nbsp;&nbsp;
												</div>
											</div>
											<div class="row">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>Region </label>
													:&nbsp;&nbsp;
													{campaignDetails.region}
												</div>
											</div>
											<div class="row word-wrap">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>Country </label>
													:&nbsp;&nbsp;
													{campaignDetails.country}
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>Industry </label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.industry}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>Job Title </label>
													:&nbsp;&nbsp;
													{campaignDetails.jobTitle}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label>No. Of Custom Questions :</label>
													&nbsp;&nbsp;&nbsp;
													{campaignDetails.noOfCustomQuestions}
												</div>
											</div>
											<div class="row">
												<label style={{ paddingLeft: "15px", width: "125px" }}>
													Other{" "}
												</label>
												:&nbsp;&nbsp;&nbsp;
												{campaignDetails.conversionCriteria}
											</div>
											<div class="row w-100%">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>
														Job Function{" "}
													</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.jobFunction}
												</div>
											</div>
											<div class="row w-100%">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label style={{ width: "110px" }}>
														company Revenue{" "}
													</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.companyRevenue}
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>Job Level </label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.jobLevel}
												</div>
											</div>
											<div class="row">
												<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
													<label>Employee Size </label>:&nbsp;&nbsp;&nbsp;
													{campaignDetails.empSize}
												</div>
											</div>
											<div class="row">
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "110px" }}>
														Max Lead Available{" "}
													</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.leadAllocation}
												</div>

												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "100px" }}>Target CPL</label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.CPL}
												</div>
												<div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4">
													<label style={{ width: "148px" }}>currency </label>
													:&nbsp;&nbsp;&nbsp;
													{campaignDetails.currency}
												</div>
											</div>
										</div>
									))}
								</p>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									class="btn add-button"
									data-dismiss="modal">
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
} // end of class

RequestForProposalForPublisher.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(RequestForProposalForPublisher)
);
