/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file Publisher details review
 * @author Sanobar Golandaj
 * @version 1.0
 * @section publisher details review
 */
import React, { Component } from "react";
// import { Table } from "mdbreact";//kiran-4745-removing console warning-no used
import Navigation from "../layouts/navPage";

import Footer from "../layouts/footer";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Somnath Task-4071-added default header
const Swal = require("sweetalert2");

class ReviewLinkPublisher extends Component {
	constructor() {
		super();
		this.state = {
			agencyID: "",
			publisherReviewDetails: [],
		};
		this.handleBackButtonToDashboard =
			this.handleBackButtonToDashboard.bind(this);
		this.submitLink = this.submitLink.bind(this);
	} //end of constructor
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			const {  user } = this.props.auth;//kiran-4745-removing console warning-no used
			var agencyID = user.id;
			this.setState({ agencyID: agencyID });

			// karan-task-3715-replace query params
			var pID;
			if (this.props.location.state !== undefined) {
				pID = this.props.location.state.pID;
			}

			let data = {
				// agencyID: agencyID,
				pID: pID,
			};
			fetch("/campaign/publisherReviewDetails", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((publisherReviewDetails) => {
					this.setState({ publisherReviewDetails: publisherReviewDetails });
				});
		}
	}
	handleBackButtonToDashboard(e) {
		e.preventDefault();
		this.props.history.push("/newdashboard", { agencyID: this.state.agencyID }); //karan-task-3684-replace query params
	}

	submitLink(e) {
		// const { user } = this.props.auth;
		// var agencyID = user.id; //kiran-4745-removing console warning-no used
		// karan-task-3715-replace query params
		var pID;
		if (this.props.location.state !== undefined) {
			pID = this.props.location.state.pID;
		}
		let data = {
			// agencyID: agencyID,
			pID: pID,
		};

		fetch("/campaign/publisherLinkApprove", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				// console.log("Return with response");
				Swal.fire({
					text: "Publisher Approved Successfully.",
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
					preConfirm: () => {
						this.props.history.push("/newdashboard", {
							agencyID: this.state.agencyID,
						}); //karan-task-3684-replace query params
					},
				});
			});
	}

	render() {
		return (
			<div>
				<Navigation />
				<div class="container-fluid" style={{ paddingTop: "90px" }}>
					<div class="row">
						<div class="col-lg-4 col-md-4 col-sm-4" style={{ paddingLeft: "20px" }}>
						{/* kiran-4745-removing console warning-added "#/" */}
							<a
								href="#/"
								onClick={this.handleBackButtonToDashboard}
								style={{ color: "#056eb8" }}>
								<FaArrowAltCircleLeft
									size={32}
									style={{ float: "left" }}
									title="Back to Dashboard"
								/>
							</a>
						</div>

						<div class="col-lg-4 col-md-4 col-sm-4">
							{/* //shivani-3285-passed ID for DI label consistency */}
							<label id="labelDI"> Publisher details</label>
						</div>

						<div class="col-lg-4 col-md-4 col-sm-4">
							<button
								type="button"
								class="btn add-button"
								style={{ float: "right" }}
								onClick={this.submitLink}>
								Approve
							</button>
						</div>
					</div>
					<br />
					   {/* rutuja task - 4325 made changes to make table responsive */}
					<div style={{ border: "1px solid #cdcdcd" }} class="table-responsive">
						
						{this.state.publisherReviewDetails.map((publisherReviewDetails) => (
							<div class="row" >
								<div class="col-lg-6">
									<table>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Publisher Name:</label>
											</td>
											<td>
												<span> {publisherReviewDetails.publisherName}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Country:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.country}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;City:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.city}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Country Code:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.countryCode}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Website:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.website}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">
													&nbsp;Non-disclosure agreement signed:
												</label>
											</td>
											<td>
												<span>{publisherReviewDetails.NDA}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">
													&nbsp;Insertion order required for each campaign:
												</label>
											</td>
											<td>
												<span>{publisherReviewDetails.IO}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Payment terms:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.payment_terms}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Telemarketing:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.dcTelemarketing}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Programmatic:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.dcProgrammatic}</span>
											</td>
										</tr>
									</table>
								</div>

								<div class="col-lg-6">
									<table>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Email:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.email}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;State:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.state}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Zip code:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.zipcode}</span>
											</td>
										</tr>

										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Phone no:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.phone}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">
													&nbsp;Master service agreement signed:
												</label>
											</td>
											<td>
												<span>{publisherReviewDetails.MSA}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">
													&nbsp;Revenue model defined/agreed:
												</label>
											</td>
											<td>
												<span>{publisherReviewDetails.RMD}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Revenue model:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.revenue_model}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Email:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.dcEmail}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Display:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.dcDisplay}</span>
											</td>
										</tr>
										<tr>
											<td style={{ width: "290px" }}>
												<label id="label">&nbsp;Social:</label>
											</td>
											<td>
												<span>{publisherReviewDetails.dcSocial}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
						))}
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
ReviewLinkPublisher.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(ReviewLinkPublisher)
);
