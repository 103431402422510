/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Sanjana Godbole
@Creation Date:12-12-2018
@Description:UI for  allocated campaigns under Agency view
*/
import React from "react";
import { Tabs, Tab } from 'react-bootstrap';
// import { Progress } from "mdbreact";
// import * as $ from "jquery";//kiran-4748-removing console warning-no used
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";//kiran-4748-removing console warning-no used
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import NewCampaign from "./newCampaign";
// import QuickCampaign from "./quickCampaign";//kiran-4748-removing console warning-no used
import "./newCampaign.css";


//var pid = [];
//var campID;
class AdvertiserTab extends React.Component {
  constructor() {
    super();
    this.state = {
      key: 1,
      fields: {},
      dynamic: [],
      campID: " ",
      id: "",
      leadAllocation: 0,
      allocatedLead: "",
      pendingLead: 0,
      campaignVisibleTime: "",
      campAcceptReject: "",
      campaignDetailAllocates: [],
      campaignDetailAssign: [],
      campaignDetailID: [],
      liveCampaignList: [],
      onClickAllocatedLead: [],
      onClickAllocatedLeadPubList: [],
      errors: {}
    };
    this.count1 = 0;
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    // this.handleCampaignID = this.handleCampaignID.bind(this);
    // this.showLeadAssignTOPublisher = this.showLeadAssignTOPublisher.bind(this);
    this.logChange = this.logChange.bind(this);
    // this.submitPublisher = this.submitPublisher.bind(this);
    // this.handleChangeDynamicPublisher = this.handleChangeDynamicPublisher.bind(this);

  } // end of constructor


   handleSelect(key) 
  {
  // alert('selected ' + key);
  // var url=window.location.href.split('#')[0];//kiran-4748-removing console warning-no used
  // alert(url);
  //this.props.history.push('/newCampaign');
  
    localStorage.setItem('activeKey',key)

    this.setState({key,
      showTable:true
  });
  window.location.reload();
  }
  
  componentDidMount() {   
    if(!this.props.auth.isAuthenticated) {
      this.props.history.push('/userLogin');
  }
  
  var hash = window.location.hash;
//kiran-4748-removing console warning-changed != to !==
  if(hash!==undefined || hash!=="" || hash!=null)
  {
    if(hash==='#1'){ //kiran-4748-removing console warning-changed == to ===
      this.setState({key:1});
    }
    else if(hash==='#2'){
      this.setState({key:2});
    }
   
  }
  
  let value
  if(hash==='#2'){
     value  =2;
  }
    else {
     value  = localStorage.getItem('activeKey');
  }
  //  alert("ComponentDidmount"+value);
   var intValue=parseInt(value,10);
  
   if(value==null || value===undefined) //kiran-4748-removing console warning-changed == to ===
     {
      //  alert("value is zero");
       this.setState({
         key:1,
      });
      // alert("key"+this.state.key);
     }
   else
     {
    //  alert("not zero");
     this.setState(()=>({
       key:intValue
    })
   );
     }
     

  }// componentDidMount()
  logChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChange(e) {
    // alert("in handle change");
    let fields = this.state;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  } // end of  handleChange(e)



 

  render() {
   /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
     
      // const {isAuthenticated, user} = this.props.auth;//kiran-4748-removing console warning-no used
      
     
    return (
      <div>
        <Navigation />
       
        <div class="container-fluid" id="containerAdvTab" style={{paddingTop:'90px',marginBottom:'60px'}}>
          {/*  //mufiz-khan-Bug-4360-title:-Prod Issue-Agency/Advertiser Side-View Campaign-Campaign name --Camp Setup Spec PDF -- Additional Long Comments not showing in PDF File */}
       {/* <div className="col" align="center" style={{paddingTop:'90px',marginBottom:'60px'}}> */}
       {/* shivani-3310-passed ID "labelDI" for label consistency */}
       <div class="col" align="center" id="labelDI">New Campaign
        </div>
        <Tabs activeKey={this.state.key} onSelect={this.handleSelect} 
          id="controlled-tab-example">
            <Tab eventKey={1}> 
              <div class="advertiseTab" className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
              <NewCampaign />
              </div>
               </Tab>
              {/* <Tab eventKey={2} title=" Quick Upload"> 
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 bgColor">
              <QuickCampaign/>
              </div>
               </Tab> */}
        
      </Tabs>
        {/* </div>  */}
         </div>
        <Footer />
        
        
      </div>// first div
    ); // end of return
  } // end of render
} // end of  class AllocatingCampaign
/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      AdvertiserTab.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
export default  connect(mapStateToProps, { logoutUser })(withRouter(AdvertiserTab)); 

