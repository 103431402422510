/* Copyright(c) 2023 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file LeadRejection Page
 * @author Aman Shaikh,kiran mate 4623
 * @version 1.0
 * @section use for admin (internal use only)
 */
import React from "react";
// import Header from "../layouts/header1";  //Nilesh-4754-Removing console warnings
import Footer from "../layouts/footer";
import AdminNavpage from "../layouts/adminNavpage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { css } from "emotion";
import styled from "styled-components";
// import { CardBody } from "mdbreact";  //Nilesh-4754-Removing console warnings
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Icon, Table, Tooltip } from "antd";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";
import "datatables.net";
import "antd/dist/antd.css";
import { LeadReviewLoader } from "../loaders/LeadReviewLoader";

//kiran-4775-added for downloading excel file
import { saveAs } from "file-saver";
const xlsx = require("xlsx");
const Swal = require("sweetalert2");
const tableCSS = css({
  backgroundColor: "white",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",
    color: "white",
  },
  "& thead > tr >th": {
    border: "1px solid black",
    color: "white",
  },
});
const HtmlTooltip = styled(Tooltip)`
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: '12px',
		border: '1px solid #dadde9',
		`;

class LeadRejection extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      campID: "",
      selectedDate: "",
      status: "Accepted",
      result: [],
      selectedLeadDetails: [],
      loader_progress: "",
      tableState: { size: "small" },
      loaderMessage: "",
      buttonDisplay: "disabled btn add-button",
      selectedCampaign: [],
      pageSize2: 100,
    };

    this.campIdHandleChange = this.campIdHandleChange.bind(this);
    this.dateHandleChange = this.dateHandleChange.bind(this);
    this.searchHandleChange = this.searchHandleChange.bind(this);
    this.rejectLeads = this.rejectLeads.bind(this);
    this.loadingRef = React.createRef();
    this.closeLoadingRef = React.createRef();
    this.handleChangePageSize2 = this.handleChangePageSize2.bind(this);
    this.nameHandleChange = this.nameHandleChange.bind(this);
    this.handledownload = this.handledownload.bind(this); 
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  campIdHandleChange(e) {
    e.preventDefault();
    let campID = e.target.value;
    this.setState({ campID: campID });
  }
  //kiran-4775-handlechange for name,function for downloading file
  nameHandleChange(e) {
    e.preventDefault();
    let name = e.target.value;
    name = name.replace(/\s+/g, ' ');
    this.setState({ name: name });
  }
  handleKeyPress(e) {
    const charCode = e.which ? e.which : e.keyCode;
    if(
      (charCode >= 33 && charCode <= 47) || // ! to /
       (charCode >= 48 && charCode <= 57) || // for numbers
       (charCode >= 58 && charCode <= 64) || // : to @
       (charCode >= 91 && charCode <= 96) || // [ to `
       (charCode >= 123 && charCode <= 126) // { to ~
       ) {
      e.preventDefault();
    }
  }
  handledownload(e) {
    let result = this.state.result;
    let campID=this.state.campID;
    function removeUnwantedFields(arr) {
      return arr.map(item => {
        const { key, ...rest } = item; // excluding the "key" property
        return rest; 
      });
    }
    const filteredResult = removeUnwantedFields(result);

    if (result.length > 0) {
      var ws = xlsx.utils.json_to_sheet(filteredResult);
      var wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "Lead");
      var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" });

      function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (let i = 0; i !== s.length; ++i)
              view[i] = s.charCodeAt(i) & 0xff;
          return buf;
      }
      this.setState({ loader_progress: "" }); 
      var fileName = campID + "-" + result.length +"-Admin Rejected Leads.xlsx"; //Nilesh-4754-Removing console warnings
      saveAs(
          new Blob([s2ab(buf)], { type: "application/octet-stream" }),
          fileName
      );
    }
    else{
      Swal.fire({
        type: "warning",
        title: "No Leads to Download",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        preConfirm: () => {
          window.location.reload();
        },
      });
    }
  }
  dateHandleChange(e) {
    e.preventDefault();
    let selectedDate = e.target.value;
    this.setState({ selectedDate: selectedDate });
  }
  // kiran-4623-function for rejecting leads
  rejectLeads(e) {
    e.preventDefault();
    let selectedCampaign = this.state.selectedCampaign;
    let campID=this.state.campID;
    let name=this.state.name;
    if (typeof name === "undefined" || name==="") {
      Swal.fire({
        text: "Please enter name while rejecting leads",
        type: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
      return; 
    } //kiran-4775-added condition if name is not defined
    if (selectedCampaign.length > 0) {
      Swal.fire({
        title:
          "All Selected leads will gets Rejected. Do you want to continue?",
        type: "question",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        allowOutsideClick: false,
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          // let loaderMessage = "Please Wait...";
          // this.setState({ loaderMessage: loaderMessage });
          // this.loadingRef.current.click();
          let data = {
            selectedCampaign: selectedCampaign,
            campID:campID,
            name:name
          };
         
          fetch("/admin/updateStatus", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((response) => {
              // this.closeLoadingRef.current.click();
              if (response.success === true) {
                Swal.fire({
                  text: selectedCampaign.length +" Leads rejected Successfully",
                  type: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  preConfirm: () => {
                  //kiran-4731-calling function after updating status
                  //  this.searchHandleChange(e)
                  //  this.setState({ selectedCampaign:""})
                  window.location.reload();//kiran-4775-page refresh
                  },
                });
              }
            })
            .catch(function (err) {
              console.log(err);
            });
        } else {
          // window.location.reload();
        }
      });
    } else {
      Swal.fire({
        type: "warning",
        title: "Please select at least one lead",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        // preConfirm: () => {
        //   window.location.reload();
        // },
      });
  }
  
}

  searchHandleChange(e) {
    let campID = this.state.campID;
    let status = this.state.status;
    let selectedDate = this.state.selectedDate;
    //kiran-4732-added condition in if
    if (
      // (campID !== "undefined" || undefined || null || "null" || "") &&
      // (status !== "undefined" || undefined || null || "null" || "") &&
      // (selectedDate !== "undefined" || undefined || null || "null" || "")
       (campID!== '' && selectedDate !=='')
    ) {
      // let finalData = this.state.allUserNames.filter((element) => {
      //   return element.firstName == selectedUser.firstName && selectedUser.lastName == selectedUser.lastName;
      // });

      this.setState({ loader_progress: "loader_campaign_list" });
      let data = {
        campID: campID,
        status: status,
        selectedDate: selectedDate,
      };
      fetch("/admin/LeadRejectionInfo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({ loader_progress: "" });
          this.setState({
            buttonDisplay: "btn add-button",
          });
          if (result.length > 0) {
            this.setState({ result: result });
            if (this.state.result && this.state.result.length > 0) {
              var name = "key";
              var value;
              for (var i = 0; i < this.state.result.length; i++) {
                value = this.state.result[i].leadinfoID;
                let result = [...this.state.result];
                result[i] = { ...result[i], [name]: value };
                this.setState({ result: result });
              }
            }
          } else {
            Swal.fire({
              type: "warning",
              title: "No records Found",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      Swal.fire({
        type: "warning",
        title: "Please Select All Mandatory Fields",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
      this.setState({ loader_progress: "" });
    }
  }
  handleChangePageSize2(e) {
    var pageSize2 = e.target.value;
    this.setState({ pageSize2: pageSize2 });
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    }
  }

  render() {
    const asterisk = { color: "red" };

    //  kiran-4623-added table on search button
    const columns = [
      {
        title: (
          <span>
            {" "}
            Date (YYYY-MM-DD HH:MM:SS) &nbsp;&nbsp;&nbsp;
            <span>
              <i class="fas fa-sort"></i>
            </span>
          </span>
        ),
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        width: "13%",
        fontSize: "12px",
        align: "center",
        sorter: (a, b) => {
          const asc = "asc";

          if (a.lastUpdated < b.lastUpdated) {
            return asc ? -1 : 1;
          } else if (a.lastUpdated > b.lastUpdated) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        },
        render: (text, record) => (
          <div>
            <span>{record.lastUpdated}</span>
          </div>
        ),
      },
      {
        title: <span> lead Info ID &nbsp;&nbsp;&nbsp;</span>,
        // leadinfoID

        dataIndex: "leadinfoID",
        key: "leadinfoID",
        width: "13%",
        fontSize: "12px",
        align: "center",

        render: (text, record) => (
          <div>
            <span>{record.leadinfoID}</span>
          </div>
        ),
      },
      {
        title: <span> Email &nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "email",
        key: "email",
        width: "13%",
        fontSize: "12px",
        align: "center",
        render: (text, record) => (
          <div>
            <span>{record.email}</span>
          </div>
        ),
      },
      {
        title: <span> First Name &nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "firstName",
        key: "firstName",
        width: "13%",
        fontSize: "12px",
        align: "center",
        render: (text, record) => (
          <div>
            <span>{record.firstName}</span>
          </div>
        ),
      },
      {
        title: <span> Last Name &nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "lastName",
        key: "lastName",
        width: "13%",
        fontSize: "12px",
        align: "center",
        render: (text, record) => (
          <div>
            <span>{record.lastName}</span>
          </div>
        ),
      },
      {
        title: <span> Status&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "status",
        key: "status",
        width: "13%",
        fontSize: "12px",
        align: "center",
        render: (text, record) => (
          <div>
            <span>{record.status}</span>
          </div>
        ),
      },
      {
        title: <span> Job Title&nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "jobTitle",
        key: "jobTitle",
        width: "13%",
        fontSize: "12px",
        align: "center",
        render: (text, record) => (
          <div>
            <span>{unescape(record.jobTitle)}</span>
          </div>
        ),
      },
      {
        title: <span> Company &nbsp;&nbsp;&nbsp;</span>,
        dataIndex: "companyName",
        key: "companyName",
        width: "13%",
        fontSize: "12px",
        align: "center",
        render: (text, record) => (
          <div>
            <span>{unescape(record.companyName)}</span>
          </div>
        ),
      },
    ];

    const rowSelection = {
      onChange: (key, selectedRows) => {
        this.setState({ selectedCampaign: selectedRows });
        this.setState({
          buttonDisplay: "btn add-button",
        });
      },
      onSelection: this.onSelection,
      hideDefaultSelections: true,

      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };
    function onChange(pagination, filters, sorter) {
      console.log("params", pagination, filters, sorter);
    }

    return (
      <div>
        <LeadReviewLoader
          loadingRef={this.loadingRef}
          closeLoadingRef={this.closeLoadingRef}
          message={this.state.loaderMessage}
        />
        <AdminNavpage />

        <div className="container-fluid" style={{ paddingTop: "100px" }}>
          {/* heading section */}
          <div className="row">
            <div className="col-lg-12 col-md-3 col-sm-3">
              <a
                href="#/" //Nilesh-4754-Removing console warnings
                onClick={(e) =>{
                  e.preventDefault(); //Nilesh-4831-Add e.preventDefault();
                  this.props.history.push("/adminDashboard", {
                    agencyID: this.state.agencyID,
                  })
                }}
                style={{ color: "#056eb8" }}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  style={{ float: "left" }}
                  title="Back to Dashboard"
                />
              </a>
            </div>

            <div
              className="offset-lg-2 offset-sm-2 col-lg-8 col-md-8 col-sm-8"
              align="center"
            >
              <center>
                <label
                  id="labelDI"
                  style={{
                    fontSize: "28px",
                    fontStyle: "roboto",
                    fontWeight: "500",
                    color: "#193D8F",
                  }}
                  align="center"
                >
                  Lead Rejection
                </label>
              </center>
            </div>
          </div>
          {/* Filter section */}
          <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3" id="AD_ALTD">
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Campaign ID&nbsp;<span style={asterisk}>*</span>
                &nbsp;&nbsp;&nbsp;
              </label>
              <br />
              <input
                style={{
                  width: "220px",
                  height: "40px",
                  border: "1px solid #cccccc",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "3px",
                  fontFamily: "sans-serif",
                  color: "#555555",
                }}
                type="text"
                id="CampaignID"
                className="form-control inputStyle"
                name="CampaignID"
                required
                onChange={this.campIdHandleChange}
              ></input>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3 cl-LeadTracking" id="AD_ALTD">
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Select Date&nbsp;<span style={asterisk}>*</span>&nbsp;
              </label>
              <br />
              <input
                style={{
                  width: "220px",
                  border: "1px solid #CCCCCC",
                  borderRadius: "3px",
                  backgroundColor: "#FFFFFF",
                  fontFamily: "sans-serif",
                  color: "#555555",
                  lineHeight: "2",
                }}
                type="date"
                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                value={this.state.selectedDate}
                // className={this.state.inputClassSdate}
                className="form-control inputStyle "
                onChange={this.dateHandleChange}
                required
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3" id="AD_ALTD">
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Status&nbsp;<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;
              </label>
              <br />
              <input
                style={{
                  width: "220px",
                  height: "40px",
                  border: "1px solid #cccccc",
                  backgroundColor: "#eeeeee",
                  borderRadius: "3px",
                  fontFamily: "sans-serif",
                  color: "#555555",
                }}
                type="text"
                id="Status"
                className="form-control inputStyle"
                name="Status"
                value="Accepted"
                required
                disabled
              ></input>
            </div>
            {/* kiran-4775-added name input box */}
            
            <div className="col-12 col-sm-6 col-md-6 col-lg-3" id="AD_ALTD">
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Name &nbsp;<span style={asterisk}>*</span>
                &nbsp;&nbsp;&nbsp;
              </label>
              <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment> 
                               
                              <li>
                                {
                                  "Enter Firstname and Lastname seperated by space"
                                }
                              </li>
                          </React.Fragment>
                        }
                      >
                        <Icon type="question-circle" />
                      </HtmlTooltip>
              <br />
              <input
                style={{
                  width: "220px",
                  height: "40px",
                  border: "1px solid #cccccc",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "3px",
                  fontFamily: "sans-serif",
                  color: "#555555",
                }}
                type="text"
                id="name"
                className="form-control inputStyle"
                name="name"
                required
                onChange={this.nameHandleChange}
                onKeyPress={this.handleKeyPress}
                >
                </input>
            </div>
            <div className="col-lg-3" id="AD_ALTD">
              <br />
              <button
                type="button"
                class="btn add-button"
                onClick={this.searchHandleChange}
                style={{marginTop: "5px"}}
              >
                Search
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
                type="button"
                // class="btn add-button"
                className={this.state.buttonDisplay}
                onClick={this.rejectLeads}
                // disabled
                style={{marginTop: "5px"}}
              >
                Reject Leads
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
                type="button"
                class="btn add-button"
                onClick={this.handledownload}
                style={{marginTop: "5px"}}
              >
                Download
              </button>
              {/* kiran-4775-download button added */}
            </div>
          </div>
          <div align="center" id={this.state.loader_progress}></div>
          <div className="card card-signin my-1">
            <div class="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="table-responsive">
                  {/* kiran-4775-showing total count of leads */}
                        <span  style={{margin: "20px",color: 'Red',fontWeight: "bold" }}>
                          Total Leads :{this.state.result.length}
                        </span>
                  <p id="myTable" style={{ marginBottom: "30px " }}>
                    <br />
                    <Table
                      {...this.state.tableState}
                      rowSelection={rowSelection}
                      id="myTable"
                      bordered
                      dataSource={this.state.result}
                      loading={this.state.loading1}
                      onChange={onChange}
                      columns={columns}
                      class="ant-table-thead"
                      className={tableCSS}
                      scroll={{ x: 1200, y: 1333 }}
                      pagination={{ pageSize: this.state.pageSize2 }}
                    ></Table>
                    <p>
                      <div class="row" style={{ marginBottom: "30px" }}>
                        <div
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                          style={{ marginTop: "-50px" }}
                        >
                          &nbsp;&nbsp;&nbsp;Records per page:&nbsp;
                          <select
                            defaultValue={this.state.pageSize2}
                            onChange={this.handleChangePageSize2}
                            id="pacing1"
                            class="input-small"
                            className="form-control"
                            name="pacing"
                            style={{
                              width: "80px",
                              height: "30px",
                              display: "initial",
                            }}
                          >
                            <option value="100" selected>
                              100
                            </option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                            <option value="250">250</option>
                          </select>
                        </div>
                      </div>
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
LeadRejection.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(LeadRejection)
);
