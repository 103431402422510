/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Shivani Pathak
@file Name:helpOption.js
@Description: UI for editCampaignHelp
*/

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../login/authentication";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import { Button, Badge } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Modal } from "react-bootstrap"; 
import "./publisherAllocationHelp.css"; //Add css File By mufiz-Bug 3910

const Swal = require("sweetalert2");
const queryString = require("query-string");
// var region;
class editCampaignHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  } //end of state

  render() {
    const { isAuthenticated, user } = this.props.auth;
    return (
      <div>
        <Navigation />

        <div class="container-fluid" style={{ paddingTop: "115px" }}>
          <div className="text-right">
            {" "}
            <button
              style={{
                border: "2px solid #14254A",
                borderRadius: "40px",
                width: "13rem",
                height: "4rem",
                backgroundColor: "white",
              }}
              type="button"
              class="btn-btn-default"
            >
              <span style={{ color: "#14254A", fontWeight: "bold" }}>
                <a href="helpOption" style={{ color: "#000000" }}>
                  Help Main Option
                </a>
              </span>
            </button>
          </div>
          <div>
            <h4
            class="createCampvdo"
              style={{
                marginTop: "5px",
             
                color: "#14254A",
              }}
            >
              Allocation---<i class="bi bi-chevron-right"></i>Campaign Progress
            </h4>
          </div>
          <hr />

          <h3 class="createCampvdo"  style={{ color: "#14254A", opacity: "75%"}}>
            Edit Campaign
          </h3>
          <p class="createCampvdo" style={{ color: "#000000" }}>
            {" "}
            If there are any changes in campaign, then you can use Edit option
          </p>
          <div class="container">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                style={{ border: "1px solid grey" }}
                class="embed-responsive-item"
                src="edit.mp4"
              />
            </div>
          </div>

          <h2 class="createCamp"
            style={{
              color: "#14254A",
              paddingTop: "50px",
              
            }}
          >
            Edit Campaign Step by Step :
          </h2>
          <h4 class="createCamp" style={{ color: "#14254A", opacity: "75%" }}>
            Step 1 :
          </h4>
          <p class="createCamp" class="createCamp" style={{ marginTop: "5px", }}>
            To Edit a Campaign,we need to go
            <span style={{ color: "#000000"}}>
              Allocation--->Campaign Progress
            </span>
            .
          </p>
          <p class="createCamp"
            style={{ marginTop: "5px", color: "#000000", }}
          >
            Allocation--->Campaign Progress
          </p>
          <p class="createCamp" style={{ color: "#808080" }}>
            Then click on<span style={{ color: "black" }}>"Edit"&nbsp;</span>{" "}
            icon from Action column.
          </p>
          <img
            className="img-responsive"
            style={{ marginTop: "10px", border: "1px solid grey" }}
            src="dashboard_edit_option.png"
            width="80%"
            height="500px"
          />
          <br />
          <h4 class="createCamp" style={{ color: "#14254A", opacity: "75%",}}>
            Step 2 :
          </h4>
          <p class="createCamp" style={{ marginTop: "5px", color: "black" }}>
            Then you see Edit Campaign Page as shown below. It is two step form.
          </p>
          <p class="createCamp" style={{  color: "black" }}>
            {" "}
            Now Click on Step 1 or step which you want to edit
          </p>
          <img
            className="img-responsive"
            style={{ marginTop: "10px", border: "1px solid grey" }}
            src="edit_camp.png"
            width="80%"
            height="500px"
          />

          <br />
          <p class="createCamp" style={{ marginTop: "5px", color: "black" }}>
            If you select Step 1 i.e Campaign Details then basic details page of
            that particular campaign display on screen.
            <br />
            You can make changes whatever you want and move forward by clicking
            button <span style={{ fontWeight: "bold" }}>"Save and Next"</span>
          </p>
          <img
            className="img-responsive"
            style={{ marginTop: "10px", border: "1px solid grey" }}
            src="basic_details.png"
            width="80%"
            height="500px"
          />
          <br />
          <img
            className="img-responsive"
            style={{ marginTop: "10px", border: "1px solid grey" }}
            src="delivery_option.png"
            width="80%"
            height="500px"
          />
          <br />
          <img
            className="img-responsive"
            style={{ marginTop: "10px", border: "1px solid grey" }}
            src="camp_specif.png"
            width="80%"
            height="500px"
          />
          <br />
          <img
            className="img-responsive"
            style={{ marginTop: "10px", border: "1px solid grey" }}
            src="cust_question.png"
            width="80%"
            height="500px"
          />
          <br />
          <p class="createCamp" style={{ marginTop: "5px",  color: "black" }}>
            If you want to edit only Step 2 i.e Campaign Specification you can
            directly select step 2 on Edit Campaign Page
            <br />
            You can also go through step1 by clicking button{" "}
            <span style={{ fontWeight: "bold" }}>"Save and Next"</span>
          </p>
          <p class="createCamp" style={{ color: "#808080" }}>
            You can also make changes in Delivery format by{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>
              'Check / Uncheck'&nbsp;
            </span>
            particular checkbox option.
            <br />
            you can{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>
              'Remove'
            </span>{" "}
            already uploaded files or link and{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>
              'Upload'&nbsp;
            </span>
            new files.
          </p>
          <img
            className="img-responsive"
            style={{ marginTop: "10px", border: "1px solid grey" }}
            src="supp_doc1.png"
            width="80%"
            height="500px"
          />
          <br />
          <img
            className="img-responsive"
            style={{ marginTop: "10px", border: "1px solid grey" }}
            src="supp_doc2.png"
            width="80%"
            height="500px"
          />
          <br />
          <h4 class="createCamp" style={{ color: "#14254A", opacity: "75%",}}>
            Step 3 :
          </h4>
          <p class="createCamp" style={{ marginTop: "5px", color: "black" }}>
            Campaign Edited Successfully :
          </p>
          <p class="createCamp" style={{ color: "#808080"}}>
            When you done with all changes after clicking{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>
              "SAVE"&nbsp;
            </span>
            all changes get saved success message.
          </p>
          <p class="createCamp" style={{ color: "black"}}>
            "Campaign Edited Successfully"
          </p>
          <img
            className="img-responsive"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              border: "1px solid grey",
            }}
            src="edit_success.png"
            width="80%"
            height="500px"
          />
          <br />
          <hr style={{ border: "1px solid grey" }} />
          <div class="row" id="roww">
            <h3 
              style={{
                marginTop: "5px",
                color: " #14254A",
              }}
            >
              Was this artical helpful ?
            </h3>{" "}
            <button
              style={{
                border: "2px solid  #14254A",
                borderRadius: "40px",
                width: "75px",
                marginLeft: "74px",
                color: " #14254A",
                height: "35px",
                backgroundColor: "white",
              }}
              type="button"
              class="btn-btn-default"
            >
              YES
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              style={{
                border: "2px solid  #14254A",
                borderRadius: "40px",
                width: "75px",
                color: " #14254A",
                height: "35px",
                backgroundColor: "white",
              }}
              type="button"
              className="btn-btn-default"
            >
              NO
            </button>
          </div>
          <hr style={{ border: "1px solid grey" }} />
         
          <div className="text-center">
            {" "}
            <button
              style={{
                border: "2px solid #14254A ",
                borderRadius: "40px",
                width: "14rem",
                height: "5rem",
                backgroundColor: "white",
              }}
              type="button"
              class="btn-btn-default"
            >
              <span style={{ color: "#14254A", fontWeight: "bold" }}>
                <a href="helpOption" style={{ color: "#000000" }}>
                  Help Main Option
                </a>
              </span>
            </button>
          </div>
        </div>
        <br />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
} //end of class
/**
 * @author Shivani Pathak
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
editCampaignHelp.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(editCampaignHelp));
