
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Rutuja Jagtap
@Description:UI for change User Status
*/

import React  from 'react'; //Rutuja 4796 removed { Component } as never used
import './admin.css';
import Footer from '../layouts/footer';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
import Navigation from "../layouts/adminNavpage";
import { Card  } from 'antd'; //Rutuja 4796 Removed  Form, Icon, Input, Button,, Select Checkbox as never used
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3927-VAPT URL changes
const Swal = require('sweetalert2');


class changeUserStatus extends React.Component {  
    constructor(props) {  
        super(props);  
        this.state=
        {
            inputClass11:'btn add-button',
            email:'', 
            errors: {},
            userEmailExist:[]
           
        }
        this.handleChange = this.handleChange.bind(this);
        this.changeUserStatusActive = this.changeUserStatusActive.bind(this);
		this.changeUserStatusDeactive = this.changeUserStatusDeactive.bind(this);
    }// end of constructor
    
         
    handleChange(e)
    {
       
        var email=e.target.value;
        let errors={}
        errors['doesntExits']=""
        this.setState({errors:errors})
        this.setState({email:email});
        
    }

changeUserStatusActive(e)
{ 
    var email=this.state.email;
   
    let data={
        email:email,
       
    }
    fetch("/users/setUserStatusActive", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((response) => {
            if (response.success === true) {
                Swal.fire({
                    text: " User activated successfully",
                    type: "success",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    preConfirm: () => {
                         window.location.reload();
                        
                    },
                });
            }
        });
 }


 changeUserStatusDeactive(e)
{
    
    var email=this.state.email;
   
    let data={
        email:email,
       
    }

    fetch("/users/setUserStatusDeactive", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((response) => {
            if (response.success === true) {
                //window.location.reload();
                Swal.fire({
                    text: "User deactivated Successfully",
                    type: "success",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    preConfirm: () => {
                         window.location.reload();
                        
                    },
                });
            }
        });
 }


    render() { 
     // const {user}=this.props.auth; //RUtuja 4796 commented as never used
      //  const { Option } = Select;//RUtuja 4796 commented as never used
       
        const asterisk =
        {  color: 'red',
         } 
        return ( 

			<div style={{ backgroundColor: "rgb(216, 221, 230)" }}>
				<Navigation />

				<div
					className="container-fluid  d-flex justify-content-center"
					style={{ width: "100%", height: "100%" }}>
					<div
						className="row d-flex justify-content-center "
						style={{ position: "absolute", width: "100%", height: "100%" }}>
						<div className="col-xs-12  col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
							<Card
								style={{
									width: 550,
									height: 300,
									margin: "auto",
									display: "block",
									border: "2px solid #0000001c",
									borderRadius: "8px",
									backgroundColor: "rgba(20,72,152,0.0)",
									Align: "center",
								}}>
								<h3 style={{ textAlign: "center", color: "rgb(16, 31, 101)" }}>
									Change User Status
								</h3>
								<p style={{ textAlign: "center", }}>Email <span style={asterisk}>*</span></p> 
								<span class="errorMessage" style={{color:'red'}}>{this.state.errors.wrongemailId}</span>
                                 <div  class="d-flex justify-content-center"> 
								 <input
                                  value={this.state.userName}
                                  defaultValue={this.state.userName}
                                  onChange={this.handleChange}
                                  type="text"
                                  id="userName"
                                  className="form-control "
                                  name="userName"
                                  style={{width:"60%",height: "60%"}}
                                  required /> </div> 
							<br/><br/>
							
								<div class="row d-flex justify-content-center">
									<div>
										<button class="btn add-button" className={this.state.inputClass11}
										onClick={this.changeUserStatusActive} type="submit">Activate</button>
									</div>
									&nbsp;	&nbsp;	&nbsp;
									<div style={{paddingleft:"-1000px;"}} >
										<button class="btn add-button" className={this.state.inputClass11}
										onClick={this.changeUserStatusDeactive} type="submit" >Deactivate </button>
									</div>
								</div>
							</Card>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}


/**
       * @author Rutuja Jagtap
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      changeUserStatus.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
 export default connect(mapStateToProps, { logoutUser })(withRouter(changeUserStatus)); 