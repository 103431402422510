/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Shende
 *@fileName :AddUserPublisher.js
 *Desc: Add User
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import PublisherNavigation from "../layouts/publisherNavPage";
import { Tooltip, Icon, Input, Table } from "antd";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { css } from "emotion"; //Sandeep-task-3447-added css file
import "./addUserPublisher.css"; //Sandeep-task-3447-added css file
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
//import { margins } from "pdfkit/js/page";
const Swal = require("sweetalert2");

const tableCSS = css({
  backgroundColor: "white",
  "& thead > tr > th": {
    backgroundColor: "#144999", //rutuja task -4524 changed background color
    color: "white",
    fontWeight: "500",
    // wordWrap:"break-word",
    // width:'10%'
  },
  "& thead > tr": {
    borderWidth: "2px",
    borderStyle: "solid",
  },
}); //Sandeep-task-3447-added Tablecss file

class AddUserPublisher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputClass11: "btn add-button",
      fields: {},
      errors: "",
      loader2: "",
      userDomain: "",
      selectedUserDomain: "@machbizz.com", //Sanjana-3192-For default domain in select dropdown in case user login with "machbizz.com"
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.dropDownChange = this.dropDownChange.bind(this); //Sanjana-3192-handleChange function for dropdown in case user login with "machbizz.com"
  } // end of constructor
  componentDidMount() {
    //  const { isAuthenticated, user } = this.props.auth;
    const { user } = this.props.auth; //Nilesh-2897-Removing console warnings
    //  let orgID = user.id; //Nilesh-2897-Removing console warnings
    var userName = user.name;
    userName = userName.split("@");
    var userName1 = "@" + userName[1];

    /* @author Sandeep Dhawale
     * @param  Description get user details
     * @return Description get user details
     */
    this.setState({ loader2: "loader_report1" });
    fetch("/users/getUserList", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }) // karan-task-3723-vapt header and query params
      .then((res) => res.json())
      .then((getUserList) => {
        this.setState({ getUserList: getUserList, loader2: "" });
      })
      .catch(function (err) {
        console.log(err);
      });

    //Sanjana-3192-condition to display single/multiple domain suggestion in case user login with "machbizz.com"
    if (userName[1] === "machbizz.com" || userName[1] === "machbizz.co.in") {
      const selectAfter = (
        <select
          onChange={this.dropDownChange}
          style={{ backgroundColor: "#fafafa", border: "0px solid #d9d9d9" }}
        >
          <option selected name=".machbizz.com" value="@machbizz.com">
            @machbizz.com
          </option>
          <option name=".machbizz.co.in" value="@machbizz.co.in">
            @machbizz.co.in
          </option>
        </select>
      );
      this.setState({ userDomain: selectAfter });
    } else {
      this.setState({ userDomain: userName1 });
    }
    // end of componentDidMount
  }
  //Sanjana-3192-handleChange function for dropdown in case user login with "machbizz.com"
  dropDownChange(e) {
    //  const { name, value } = e.target;
    const { value } = e.target; //Nilesh-2897-Removing console warnings

    this.setState({ selectedUserDomain: value });
  }
  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    let spanDisplay = "none";
    //  let requiredFeildValidate = "true";  //Nilesh-2897-Removing console warnings
    let requiredFeildValidate = true;
    //  let userNameValidate = "true"; //Nilesh-2897-Removing console warnings
    let userNameValidate = true;
    //  let firstNameValidate = "true";
    let firstNameValidate = true; //Nilesh-2897-Removing console warnings
    //  let lastNameValidate = "true"; //Nilesh-2897-Removing console warnings
    let lastNameValidate = true;
    //  let roleValidate = "true";  //Nilesh-2897-Removing console warnings
    let roleValidate = true;

    //Sanjana-3192-condition added in validation when user in case user login with "machbizz.com"
    if (
      this.state.selectedUserDomain === "" ||
      this.state.selectedUserDomain === "null" ||
      this.state.selectedUserDomain == null
    ) {
      fields["userName"] = fields["userName"] + this.state.userDomain;
    } else {
      fields["userName"] = fields["userName"] + this.state.selectedUserDomain;
    }
    this.setState({ fields });

    if (
      !fields["userName"] &&
      !fields["role"] &&
      !fields["firstName"] &&
      !fields["lastName"]
    ) {
      requiredFeildValidate = false;
      errors["commonError"] = <li>Please fill below required fields</li>;
      this.setState({ errors: errors });
    } else {
      if (
        this.state.userName === "" || //Nilesh-2897-Removing console warnings
        this.state.userName == null ||
        this.state.userName === undefined //Nilesh-2897-Removing console warnings
      ) {
        userNameValidate = false;
        //errors["userName"] = <li>Please enter user name</li>;
        errors["userName"] = <li>Please enter user name</li>;
        this.setState({ errors: errors });
      } else {
        //shivani - 3599 - changed RegEx as per validation requirement for hyphen .
        //  var pattern = new RegExp(/^([a-zA-Z]+[\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$/)
        // var pattern = new RegExp(/^\s*$|^[a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/)
        //sunita-task-3782-below regex accept only business domains.
        //Sandeep-task-3893-Production issue-Email format issue--All roles
        var pattern = new RegExp(
          // /^\s*$|^[a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/
          // eslint-disable-next-line no-useless-escape
          /^\s*$|^[\.a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@(?!gmail.com)(?!gmail.co.in)(?!yahoo.com)(?!yahoo.co.in)(?!facebook.com)(?!facebook.co.in)[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/
        ); //mufiz 4091

        //var pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        //var pattern = new RegExp(/^[a-zA-Z0-9]+.+[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/);
        // var pattern = new RegExp(/^\s*$|^[a-zA-Z]+[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/);
        // 	if (!pattern.test(fields["userName"])) {
        // 		userNameValidate = false;
        // 		errors["userName"] = <li>Please enter valid user name</li>;
        // 		this.setState({ errors: errors });
        // 	} else {
        // 		errors["userName"] = "";
        // 		this.setState({ errors: errors });
        // 	}
        // }
        if (this.state.userName.match(pattern)) {
          // mufiz Task-3984 Settings - Add User - Username should accept EmailID as per tooltip
          userNameValidate = true;
          // errors["userName"] =
          // this.setState({ errors: errors });
        } else {
          userNameValidate = false;
          errors["userName"] = <li>Please enter valid user name</li>;
          this.setState({ errors: errors });
        } // mufiz Task-3984 Settings - Add User - Username should accept EmailID as per tooltip
      }
      if (
        this.state.firstName === "" || //Nilesh-2897-Removing console warnings
        this.state.firstName == null ||
        this.state.firstName === undefined //Nilesh-2897-Removing console warnings
      ) {
        firstNameValidate = false;
        errors["firstName"] = <li>Please enter first name</li>;
        this.setState({ errors: errors });
      } else {
        let pattern = new RegExp(/^[a-zA-Z]+$/); //Nilesh-2897-Removing console warnings
        if (!pattern.test(fields["firstName"])) {
          userNameValidate = false;
          errors["firstName"] = <li>please enter valid first name</li>;
          this.setState({ errors: errors });
        } else {
          errors["firstName"] = "";
          this.setState({ errors: errors });
        }
      }
      if (
        this.state.lastName === "" || //Nilesh-2897-Removing console warnings
        this.state.lastName == null ||
        this.state.lastName === undefined //Nilesh-2897-Removing console warnings
      ) {
        lastNameValidate = false;
        errors["lastName"] = <li>Please enter last name</li>;
        this.setState({ errors: errors });
      } else {
        let pattern = new RegExp(/^[a-zA-Z]+$/); //Nilesh-2897-Removing console warnings
        if (!pattern.test(fields["lastName"])) {
          userNameValidate = false;
          errors["lastName"] = <li>please enter valid last name</li>;
          this.setState({ errors: errors });
        } else {
          errors["lastName"] = "";
          this.setState({ errors: errors });
        }
      }
      if (
        this.state.role === "" || //Nilesh-2897-Removing console warnings
        this.state.role == null ||
        this.state.role === undefined //Nilesh-2897-Removing console warnings
      ) {
        roleValidate = false;
        errors["role"] = <li>Please enter role</li>;
        this.setState({ errors: errors });
      } else {
        errors["role"] = "";
        this.setState({ errors: errors });
      }
    }

    if (
      //Nilesh-2897-Removing console warnings
      requiredFeildValidate === false ||
      userNameValidate === false ||
      firstNameValidate === false ||
      lastNameValidate === false ||
      roleValidate === false
    ) {
      formIsValid = false;
      this.setState({
        errors: errors,
        alertBorder: "1px solid red",
        alertWidth: "1000px",
        alertAlign: "center",
        alertDisplay: "block",
        spanSpaceDisplay: spanDisplay,
      });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else if (
      //Nilesh-2897-Removing console warnings
      requiredFeildValidate === true || //// mufiz Task-3984 Settings - Add User - Username should accept EmailID as per tooltip
      userNameValidate === true ||
      firstNameValidate === true ||
      lastNameValidate === true ||
      roleValidate === true
    ) {
      formIsValid = true;
      // alert("success");
    }
    return formIsValid;
  }
  //Rutuja-5065-function for Active status route call
  changeUserStatusActive(e, record) {
    var orgID = record.userID;
    let data = {
      orgID: orgID,
    };
    fetch("/users/setUserStatusActiveDIQA", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: "User Activated",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        }
        this.setState({ isDisabled: true });
      });
  }

  handleChange(e) {
    let fields = this.state;

    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  //Supriya: Submit User
  submitUser(e) {
    //  const self = this; //karan-task-3717-replace query params //Nilesh-2897-Removing console warnings
    //  const { isAuthenticated, user } = this.props.auth; //Nilesh-2897-Removing console warnings
    //Sanjana-3192-condition added to generate final data  when user in case user login with "machbizz.com"/ with "apssmedia.com"
    let data;
    // if(this.state.userDomain==="@apssmedia.com")
    //sunita-task-3782-changed below condition as per requirement
    if (this.state.userDomain) {
      data = {
        // userName: this.state.userName+this.state.userDomain,
        userName: this.state.userName,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        role: this.state.role,
      }; // karan-task-3723-vapt header and query params
    } else {
      data = {
        userName: this.state.userName + this.state.selectedUserDomain,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        role: this.state.role,
      }; // karan-task-3723-vapt header and query params
    }

    if (this.validateForm() === false) {
      return;
    } else {
      let errors = {};
      fetch("/users/addUserDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((userDetails) => {
          if (userDetails.length > 0) {
            errors["sameUser"] = <li>User Already Exists</li>;
            this.setState({ errors: errors });
          } else {
            fetch("/users/addUser", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            })
              .then(function (response) {
                if (response.status >= 400) {
                  throw new Error("Bad response from server");
                }
                return response.json();
              })
              .then(function (response) {
                // success message with response
                if (response.success === true) {
                  //Nilesh-2897-Removing console warnings
                  Swal.fire({
                    text: "User Added Successfully",
                    type: "success",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    preConfirm: () => {
                      // self.props.history.push("/newPublisherDashboard"); //karan-task-3717-replace query params
                      window.location.reload(); //Mufiz-3975-added code for refreshing the page.
                    },
                  });
                } else {
                }
              });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  //kiran-4613-function call for update status
  changeUserStatusInactive(e, record) {
    // const {name, value } = e.target; //Nilesh-2897-Removing console warnings
    // var orgID = record.userID
    var userID = record.userID;
    let data = {
      userID: userID,
    };
    fetch("/users/setUserStatusInactivePub", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: "User Deactivated",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.reload();
            },
          });
        }
        this.setState({ isDisabled: true });
      });
  }

  render() {
    const asterisk = {
      color: "red",
    };
    //Sandeep-task-3447-added table to get user details
    const addUserPublisher = [
      {
        title: "ID",
        dataIndex: "ID",
        key: "ID",
        width: 50,
        align: "left",
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "14px" }}>{record.userID}</span>
          </div>
        ),
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
        width: 100,
        align: "left",
        render: (text, record) => (
          <div>
            <div style={{ fontSize: "14px", width: "110px" }}>
              {record.firstName}
            </div>
          </div>
        ),
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
        width: 100,
        align: "left",
        render: (text, record) => (
          <div>
            <div class="word-wrap" style={{ fontSize: "14px" }}>
              {record.lastName}
            </div>
          </div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 160,
        align: "left",
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "14px" }}>{record.userName}</span>
          </div>
        ),
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        width: 50,
        align: "Center",
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "14px" }}>{record.role}</span>
          </div>
        ),
      },
      //kiran-4613- added status and action column
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 40,
        align: "Center",
        render: (text, record) => (
          <div>
            {record.status === "N" ? (
              <span style={{ fontSize: "14px" }}>Deactivated</span>
            ) : (
              <span style={{ fontSize: "14px" }}>Active</span>
            )}
          </div>
        ),
      },

      {
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        width: 40,
        align: "Center",
        render: (text, record) => (
          <div>
            {/* Aman-4961-added record.status === "" to PC  user with empty user status can Deactive user */}
            {record.status === "Y" ||
            record.status === null ||
            record.status === "" ? (
              // Chaitanya-5088-change deactivate button alignment
              <button
                style={{ padding: "7px 7px", margin: "auto" }}
                className={this.state.inputClass11}
                onClick={(e) => this.changeUserStatusInactive(e, record)}
                // disabled={this.state.isDisabled}
                type="submit"
              >
                Deactive{" "}
              </button>
            ) : (
              //Rutuja-5065-change button to activate login
              <button
                style={{ padding: "7px 7px", margin: "auto" }}
                className={this.state.inputClass11}
                onClick={(e) => this.changeUserStatusActive(e, record)}
                //disabled='disabled'
                type="submit"
              >
                Activate{" "}
              </button>
            )}
          </div>
        ),
      },
    ];

    return (
      <div>
        <PublisherNavigation />
        <div class="container-fluid" style={{ paddingTop: "100px" }}>
          <div class="row">
            {/*mufiz-task-397 add new back button*/}
            <div class="col-lg-4" style={{ paddingLeft: "20px" }}>
              <a href="./newPublisherDashboard" style={{ color: "#056eb8" }}>
                <FaArrowAltCircleLeft size={32} style={{ float: "left" }} />
              </a>
            </div>
          </div>

          {/* /*Sanjana-task-4118-added below css as per requirement*/}
          <h2 align="center" id="labelDI" style={{ marginTop: "-31px" }}>
            Add User Details
          </h2>
          <div
            class="card-body"
            className="cardBodyClass pubcardBodyClass"
            style={{
              margin: "10px",
              backgroundColor: "#fff",
              borderRadius: "4px",
              boxShadow: "grey 1px 1px 6px 0px", //mufiz-task-3975 styling a box
            }}
          >
            {/* <legend className="legend labelsubheading">User Details</legend> */}
            {/* //shivani-3284-passed ID for DI label consistency */}
            <div class="col-12" style={{ textAlign: "center" }}></div>

            <div style={{ fontSize: "14px", color: "red" }}>
              {this.state.errors.commonError}
            </div>
            <div
              className="errorMsg"
              style={{ fontSize: "14px", color: "red" }}
            >
              {this.state.errors.userName}
              {this.state.errors.role}
              {this.state.errors.firstName}
              {this.state.errors.lastName}
              {this.state.errors.sameUser}
            </div>

            <div class="row">
              {/* <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4" >
				  <label id="label">User Name <span style={asterisk}>*&nbsp; 
				  <Tooltip title=" User name should be Valid Email Id.">
				  <Icon type="question-circle-o" />
				</Tooltip></span></label>
				  <input
					value={this.state.userName}
					onChange={this.handleChange}
					type="text"
					id="userName"
					className="form-control"
					name="userName"
					style={{ width: "60%", height: "60%" }}
					required />
				</div> */}
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <label id="label">
                  User Name{" "}
                  <span style={asterisk}>
                    *&nbsp;
                    <Tooltip title=" User name should be Valid Email Id.">
                      <Icon
                        type="question-circle-o"
                        className="iconbackground"
                      />
                      {/*sunita-task-3818-added classname as per requirement for removing white patches */}
                    </Tooltip>
                  </span>
                </label>
                <br />

                <Input
                  // addonAfter={this.state.userDomain} //sunita-task-3782-commented this attribute as want to enable the domain field.
                  value={this.state.userName}
                  onChange={this.handleChange}
                  type="text"
                  id="PubuserName" //Chaitanya-4286-R-28.9- Prod issue-Publisher side-setting-add user
                  className="form-control"
                  name="userName"
                  style={{ width: "60%", height: "60%", padding: "1px" }}
                  required
                ></Input>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 Proleinputbox">
                {/*sunita-task-3782-added classname as per requirement */}
                <label id="label">
                  Role <span style={asterisk}>*</span>
                </label>
                <select
                  value={this.state.role}
                  onChange={this.handleChange}
                  type="text"
                  id="role"
                  className="form-control"
                  name="role"
                  style={{ width: "60%", height: "60%" }}
                  required
                >
                  <option value="None Selected" disabled selected>
                    None Selected
                  </option>
                  <option value="PC">Publisher Commercial</option>
                  <option value="PNC">Publisher Non-Commercial</option>
                  <option value="PC-ACCOUNT">Accounting</option>
                  <option value="PQA">Quality Assurance</option>
                </select>
              </div>
            </div>
            <br />
            {/* end of 1st row */}
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <label id="label">
                  First Name <span style={asterisk}>*</span>
                </label>
                <input
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  type="text"
                  id="firstName"
                  className="form-control"
                  name="firstName"
                  style={{ width: "60%", height: "60%" }}
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 Proleinputbox">
                <label id="label">
                  Last Name <span style={asterisk}>*</span>
                </label>
                <input
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  type="text"
                  id="lastName"
                  className="form-control"
                  name="lastName"
                  style={{ width: "60%", height: "60%" }}
                  required
                />
              </div>
            </div>
            {/* end of 2nd row */}
            <br />
            <button
              style={{ float: "right", marginTop: "-44px" }} //mufiz-task-3975 chang location from left to right
              class="btn add-button buttonSave"
              onClick={this.submitUser}
              type="submit"
            >
              Save{" "}
            </button>

            {/* locale={this.state.loading1?locale:"No Data"} */}
            {/* pagination={{ pageSize: this.state.pageSize1 }} */}
          </div>
          {/* end of card body */}
        </div>
        {/* end of container-fluid */}
        <br></br>
        <p>
          {" "}
          <Table
            {...this.state.tableState}
            //id="myTable"  //rutuja task-4524 table responsive issue
            bordered
            dataSource={this.state.getUserList}
            rowKey="campID"
            columns={addUserPublisher}
            // className="ant-table-thead table-responsive"
            //Nilesh-2897-Removing console warnings
            className={`${tableCSS} "ant-table-thead table-responsive"`}
            scroll={{ x: 1200, y: 1333 }}
          ></Table>
        </p>

        <br></br>
        <Footer />
      </div>
    );
  } // end of render
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AddUserPublisher.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(AddUserPublisher)
);
