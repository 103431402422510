/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file RFP Reminder Setup Page
 * @author  Karan Jagtap
 * @version 1.0
 * @section RFP Reminder Setup
 * */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Table, DatePicker } from "antd";
import moment from "moment";
import { css } from "emotion";

import { logoutUser } from "../login/authentication";
import Navigation from "../layouts/navPage";
import Footer from "../layouts/footer";
import Loader from "../campaignUI/Loader";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3796-vapt files auth header

import "./rfpReminderSetup.css";
const Swal = require("sweetalert2");
/**
 * @author : Karan Jagtap
 * @description : Task-3515- css for table
 */
const TABLE_CSS = css({
	borderStyle: "bold !important",
	backgroundColor: "white !important",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
		color: "white",
		fontSize: "12px",
		textAlign: "center",
		fontWeight: "500",
	},
	"& thead > tr > th": {
		textAlign: "center",
		color: "white",
		//padding: "8px 10px",
		backgroundColor: "transparent",
	},
	"& tbody > tr > td": {
		fontSize: "12px",
	},
	"& .ant-table-scroll .ant-table-body": {
		overflowX: "auto !important",
	},
	"& thead > tr > th .ant-table-header-column .ant-table-column-sorters .ant-table-column-sorter":
		{ display: "table-cell !important" },
	"& .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full":
		{
			marginTop: "-0.5em",
		},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-down.ant-table-column-sorter-down.on > svg":
		{
			backgroundColor: "transparent !important",
			color: "white !important",
		},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-up.ant-table-column-sorter-up.on > svg":
		{
			backgroundColor: "transparent !important",
			color: "white !important",
		},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-up.ant-table-column-sorter-up.off > svg":
		{
			backgroundColor: "transparent !important",
			color: "#6a88bb !important",
		},
	"& thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-row-cell-break-word > span > div > span.ant-table-column-sorter > div > i.anticon.anticon-caret-down.ant-table-column-sorter-down.off > svg":
		{
			backgroundColor: "transparent !important",
			color: "#6a88bb !important",
		},
	"& .ant-table-body": {
		marginLeft: "0px !important",
		marginRight: "0px !important",
	},
});

/**
 * @author : Karan Jagtap
 * @description : Task-3515-css for antd components
 */
const DISABLE_CSS = css({
	backgroundColor: "white !important",
	"& .ant-input[disabled]": {
		backgroundColor: "white !important",
	},
});

/**
 * @author : Karan Jagtap
 * @description : Task-3515-css for border
 */
const BORDER_COLOR_ERROR_CSS = css({
	"& .ant-calendar-picker-input.ant-input": {
		borderColor: "red",
	},
});

/**
 * @author : Karan Jagtap
 * @description : Task-3515-static months array
 */
const MONTHS = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

/**
 * @author : Karan Jagtap
 * @description : Task-3515-static quarters array
 */
const MONTHS_QUARTER = {
	Jan: ["Jan,Feb,Mar", "Apr,May,Jun", "Jul,Aug,Sep", "Oct,Nov,Dec"],
	Feb: ["Feb,Mar,Apr", "May,Jun,Jul", "Aug,Sep,Oct", "Nov,Dec,Jan"],
	Mar: ["Mar,Apr,May", "Jun,Jul,Aug", "Sep,Oct,Nov", "Dec,Jan,Feb"],
	Apr: ["Apr,May,Jun", "Jul,Aug,Sep", "Oct,Nov,Dec", "Jan,Feb,Mar"],
	May: ["May,Jun,Jul", "Aug,Sep,Oct", "Nov,Dec,Jan", "Feb,Mar,Apr"],
	Jun: ["Jun,Jul,Aug", "Sep,Oct,Nov", "Dec,Jan,Feb", "Mar,Apr,May"],
	Jul: ["Jul,Aug,Sep", "Oct,Nov,Dec", "Jan,Feb,Mar", "Apr,May,Jun"],
	Aug: ["Aug,Sep,Oct", "Nov,Dec,Jan", "Feb,Mar,Apr", "May,Jun,Jul"],
	Sep: ["Sep,Oct,Nov", "Dec,Jan,Feb", "Mar,Apr,May", "Jun,Jul,Aug"],
	Oct: ["Oct,Nov,Dec", "Jan,Feb,Mar", "Apr,May,Jun", "Jul,Aug,Sep"],
	Nov: ["Nov,Dec,Jan", "Feb,Mar,Apr", "May,Jun,Jul", "Aug,Sep,Oct"],
	Dec: ["Dec,Jan,Feb", "Mar,Apr,May", "Jun,Jul,Aug", "Sep,Oct,Nov"],
};

const CURRENT_DATE = new Date();

/**
 * @author : Karan Jagtap
 * @description : Task-3515-current year plus 5 years ahead array
 */
const YEARS = [
	CURRENT_DATE.getFullYear(),
	CURRENT_DATE.getFullYear() + 1,
	CURRENT_DATE.getFullYear() + 2,
	CURRENT_DATE.getFullYear() + 3,
	CURRENT_DATE.getFullYear() + 4,
];

/**
 * @author : Karan Jagtap
 * @description : Task-3515-all regular expressions
 */
const NAME_REGEX = /^[a-zA-z|\s]+$/;
const EMAIL_REGEX =
	/^\s*$|^[a-zA-Z0-9_%+]+[\w-]+[a-zA-Z0-9_%+]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+[\w-]+[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,5}\.[a-zA-Z]{2,5})/;

/**
 * @author : Karan Jagtap
 * @description : Task-3515-main class for rfpReminderSetup.js file
 */
class RfpReminderSetup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			advertiserNames: [],
			advertiserFilter: "select-advertiser",
			monthFilter: "select-month",
			yearFilter: "select-year",
			isFilterComplete: false,
			columnQ1: "Q1 - JAN,FEB,MAR",
			columnQ2: "Q2 - APR,MAY,JUN",
			columnQ3: "Q3 - JUL,AUG,SEP",
			columnQ4: "Q4 - OCT,NOV,DEC",
			tableData: [],
			originalData: [],
			actionPopupVisible: false,
			actionRecord: {},
			recordsPerPage: 100,
			x: 0,
			y: 0,
			loadingTable: false,
			currentEditId: null,
			// error handling variables
			showErrorBlock: false,
			rfpNameError: false,
			Q1_ExpectedWeekError: false,
			Q1_ReminderWeekError: false,
			Q2_ExpectedWeekError: false,
			Q2_ReminderWeekError: false,
			Q3_ExpectedWeekError: false,
			Q3_ReminderWeekError: false,
			Q4_ExpectedWeekError: false,
			Q4_ReminderWeekError: false,
			contactEmailError: false,
			contactNameError: false,
		};

		this.addRFPBtnRef = React.createRef();
		this.loadingRef = React.createRef(); //open loader
		this.closeLoadingRef = React.createRef(); //close loader
	}
	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-called before first render
	 */
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			this.fetchAdvertisers();
		}
	}

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-fetches list of advertisers
	 * @route : /agencyDashboard/selectClient
	 */
	fetchAdvertisers = () => {
		fetch("agencyDashboard/selectClient") //karan-task-3796-vapt remove ids
			.then((res) => res.json())
			.then((advertiserNames) => {
				this.setState({ advertiserNames });
			})
			.catch(function (err) {
				console.log(err);
			});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-fetches month, year of advertiser
	 * @route : /requestForProposal/getRFPFinYear
	 */
	fetchAdvertiserRFPReminder = () => {
		const data = {
			type: "Advertiser",
			advertiserID: this.extractAdvertiserID(this.state.advertiserFilter),
		};
		fetch("/requestForProposal/getRFPFinYear", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: this.props.auth.user.token,
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.length > 0) {
					this.changeColumnNames(res[0].fin_month);
					this.setState(
						{
							monthFilter: res[0].fin_month,
							yearFilter: res[0].fin_year,
							isFilterComplete: true,
						},
						() => {
							this.fetchAlreadyPresentRFPReminder();
						}
					);
				} else {
					setTimeout(() => {
						this.closeLoadingRef.current.click();
					}, 500);
				}
			})
			.catch((err) => {
				console.log(err);
				setTimeout(() => {
					this.closeLoadingRef.current.click();
				}, 500);
			});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-fetches already present rfp reminders
	 * @route : /requestForProposal/getRFPSetupInfo
	 */
	fetchAlreadyPresentRFPReminder = (from = "normal", success = false) => {
		const data = {
			advertiserID: this.extractAdvertiserID(this.state.advertiserFilter),
		};
		fetch("/requestForProposal/getRFPSetupInfo", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: this.props.auth.user.token,
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				this.setState({
					tableData: res.map((item) => {
						return {
							...item,
							rfpNameError: false,
							Q1_ExpectedWeekError: false,
							Q1_ReminderWeekError: false,
							Q2_ExpectedWeekError: false,
							Q2_ReminderWeekError: false,
							Q3_ExpectedWeekError: false,
							Q3_ReminderWeekError: false,
							Q4_ExpectedWeekError: false,
							Q4_ReminderWeekError: false,
							contactEmailError: false,
							contactNameError: false,
							updated: false,
						};
					}),
					currentEditId: null, //karan-task-3790-disable editing by default
				});
				setTimeout(() => {
					this.closeLoadingRef.current.click();
					if (from === "confirmAndSave") {
						if (success) {
							Swal.fire({
								text: "Advertiser RFP Reminder saved successfully.",
								type: "success",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								heightAuto: false,
							});
						} else {
							Swal.fire({
								text: "Something went wrong. Please try again later.",
								type: "error",
								confirmButtonText: "Ok",
								allowOutsideClick: false,
								heightAuto: false,
							});
						}
					}
				}, 500);
			})
			.catch((err) => {
				console.log(err);
				setTimeout(() => {
					this.closeLoadingRef.current.click();
				}, 500);
			});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-saves new data and updates old one
	 * @route : /requestForProposal/advRFPSetupInsert
	 */
	fetchConfirmAndSave = (data) => {
		this.loadingRef.current.click();
		fetch("/requestForProposal/advRFPSetupInsert", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: this.props.auth.user.token,
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				this.fetchAlreadyPresentRFPReminder("confirmAndSave", res.success);
			})
			.catch((err) => {
				setTimeout(() => {
					this.closeLoadingRef.current.click();
					Swal.fire({
						text: "Something went wrong. Please try again later.",
						type: "error",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						heightAuto: false,
					});
				}, 500);

				console.log(err);
			});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-deletes rfp reminder from db
	 * @route : /requestForProposal/cancelRFPReminder
	 */
	fetchCancelRFPReminder = () => {
		this.loadingRef.current.click();
		const data = {
			advertiserID: this.extractAdvertiserID(this.state.advertiserFilter),
			Id: this.state.actionRecord.Id,
		};
		fetch("/requestForProposal/cancelRFPReminder", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: this.props.auth.user.token,
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				this.setState(
					{
						tableData: [
							...this.state.tableData.filter(
								(item) => item.Id !== this.state.actionRecord.Id
							),
						],
					},
					() => {
						if (this.state.tableData.length === 0) {
							this.addRFPBtnRef.current.click();
						}
					}
				);
				setTimeout(() => {
					this.closeLoadingRef.current.click();
					Swal.fire({
						text: "RFP Reminder cancelled successfully.",
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						heightAuto: false,
					});
				}, 500);
			})
			.catch((err) => {
				setTimeout(() => {
					this.closeLoadingRef.current.click();
					Swal.fire({
						text: "Something went wrong. Please try again later.",
						type: "error",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						heightAuto: false,
					});
				}, 500);
				console.log(err);
			});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-extracts id from string
	 */
	extractAdvertiserID = (advertiserName) => {
		return advertiserName.split("-")[0].trim();
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-changes Quarter column names dynamically
	 */
	changeColumnNames = (value) => {
		this.setState({
			columnQ1: `Q1 - ${MONTHS_QUARTER[value][0].toUpperCase()}`,
			columnQ2: `Q2 - ${MONTHS_QUARTER[value][1].toUpperCase()}`,
			columnQ3: `Q3 - ${MONTHS_QUARTER[value][2].toUpperCase()}`,
			columnQ4: `Q4 - ${MONTHS_QUARTER[value][3].toUpperCase()}`,
		});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-handle select component
	 */
	handleFilterChange = (e) => {
		e.preventDefault();
		const { id, value } = e.target;
		if (id === "advertiser-filter" && value !== "select-advertiser") {
			this.setState(
				{
					advertiserFilter: value,
					monthFilter: "select-month",
					yearFilter: "select-year",
					isFilterComplete: false,
					tableData: [],
					originalData: [],
					showErrorBlock: false,
				},
				() => {
					this.loadingRef.current.click();
					this.fetchAdvertiserRFPReminder();
				}
			);
		} else if (id === "month-filter" && value !== "select-month") {
			let flag = false;
			if (
				this.state.advertiserFilter !== "select-advertiser" &&
				this.state.yearFilter !== "select-year"
			) {
				flag = true;
			}
			this.changeColumnNames(value);
			this.setState({ monthFilter: value, isFilterComplete: flag }, () => {
				if (this.state.isFilterComplete) {
					this.addRFPBtnRef.current.click();
				}
			});
		} else if (id === "year-filter" && value !== "select-year") {
			let flag = false;
			if (
				this.state.monthFilter !== "select-month" &&
				this.state.advertiserFilter !== "select-advertiser"
			) {
				flag = true;
			}
			this.setState({ yearFilter: value, isFilterComplete: flag }, () => {
				if (this.state.isFilterComplete) {
					this.addRFPBtnRef.current.click();
				}
			});
		}
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-handle select component
	 */
	handleRecordsPerPage = (e) => {
		this.setState({ recordsPerPage: e.target.value });
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-on change handler
	 */
	onHandleRFPNameEmailNameChange = (e, record) => {
		e.preventDefault();
		const { id, value } = e.target;
		let tempTableData = [...this.state.tableData];
		this.state.tableData.forEach((item, i) => {
			if (item.Id === record.Id) {
				tempTableData[i] = { ...tempTableData[i], [id]: value, updated: true };
			}
		});
		this.setState({ tableData: tempTableData });
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-on change handler
	 */
	onHandleDateChange = (date, record, from) => {
		const index = this.state.tableData.findIndex(
			(item) => item.Id === record.Id
		);
		let tempTableData = [...this.state.tableData];
		let reminderLabel = "";
		if (from.includes("Expected")) {
			if (from.includes("1")) {
				reminderLabel = "Q1_ReminderWeek";
			} else if (from.includes("2")) {
				reminderLabel = "Q2_ReminderWeek";
			} else if (from.includes("3")) {
				reminderLabel = "Q3_ReminderWeek";
			} else if (from.includes("4")) {
				reminderLabel = "Q4_ReminderWeek";
			}
			tempTableData.splice(index, 1, {
				...record,
				[from]: date,
				[reminderLabel]: "",
				updated: true,
			});
		} else {
			tempTableData.splice(index, 1, {
				...record,
				[from]: date,
				updated: true,
			});
		}
		this.setState({ tableData: [...tempTableData] });
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-disables date which cannot be selected for expected week
	 */
	disabledDate = (current, quarter) => {
		let timeSpan = quarter - 2;
		if (quarter === 1) {
			timeSpan = 3;
		}
		let period = MONTHS_QUARTER[this.state.monthFilter][timeSpan].split(",");

		let fromYear = Number(this.state.yearFilter);
		let toYear = Number(this.state.yearFilter);
		let currentYear = Number(this.state.yearFilter);
		if (MONTHS.indexOf(period[0]) > MONTHS.indexOf(period[2])) {
			if (quarter === 1) {
				fromYear = currentYear - 1;
			} else {
				toYear = currentYear + 1;
			}
		} else if (MONTHS.indexOf(this.state.monthFilter) < 3 && quarter === 1) {
			fromYear = currentYear - 1;
			if (MONTHS.indexOf(this.state.monthFilter) === 0) {
				toYear = currentYear - 1;
			}
		} else if (MONTHS.indexOf(this.state.monthFilter) > 8 && quarter > 2) {
			toYear = currentYear + 1;
			fromYear = currentYear + 1;
		}

		const a = moment([fromYear, MONTHS.indexOf(period[0])]);
		const b = moment([toYear, MONTHS.indexOf(period[2])]).endOf("month");
		return current && !current.isBetween(a, b);
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-disables date which cannot be selected for reminder week
	 */
	disabledDateReminderWeek = (current, quarter, record) => {
		let name = "";
		let expectedWeekDate = "";
		if (quarter === 1) {
			name = "Q1_ReminderWeek";
			expectedWeekDate = record.Q1_ExpectedWeek;
		} else if (quarter === 2) {
			name = "Q2_ReminderWeek";
			expectedWeekDate = record.Q2_ExpectedWeek;
		} else if (quarter === 3) {
			name = "Q3_ReminderWeek";
			expectedWeekDate = record.Q3_ExpectedWeek;
		} else if (quarter === 4) {
			name = "Q4_ReminderWeek";
			expectedWeekDate = record.Q4_ExpectedWeek;
		}

		const a = moment(expectedWeekDate);
		const day = a.day();
		let add = 0;
		let sub = 0;

		if (day === 0) {
			//sunday
			add = 5;
			sub = 1;
		} else if (day === 1) {
			//monday
			add = 4;
			sub = 2;
		} else if (day === 2) {
			//tuesday
			add = 3;
			sub = 3;
		} else if (day === 3) {
			//wednesday
			add = 2;
			sub = 4;
		} else if (day === 4) {
			//thursday
			add = 1;
			sub = 5;
		} else if (day === 5) {
			//friday
			add = 0;
			sub = 6;
		} else if (day === 6) {
			//saturday
			add = 6;
			sub = 0;
		}
		if (record[name] !== "") {
			sub = sub + 1;
		}
		const fromDate = moment(a).subtract(sub, "days");
		const toDate = moment(a).add(add + 1, "days");
		return current && !current.isBetween(fromDate, toDate);
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-validation of all table data
	 */
	validate = () => {
		let flag = true;
		let rfpNameErrorFlag = true;
		let Q1_ExpectedWeekErrorFlag = true;
		let Q2_ExpectedWeekErrorFlag = true;
		let Q3_ExpectedWeekErrorFlag = true;
		let Q4_ExpectedWeekErrorFlag = true;
		let Q1_ReminderWeekErrorFlag = true;
		let Q2_ReminderWeekErrorFlag = true;
		let Q3_ReminderWeekErrorFlag = true;
		let Q4_ReminderWeekErrorFlag = true;
		let contactNameErrorFlag = true;
		let contactEmailErrorFlag = true;

		let tempTableData = [...this.state.tableData];
		for (let i = 0; i < this.state.tableData.length; i++) {
			const item = this.state.tableData[i];

			if (item.updated) {
				// check only empty or not
				if (item.rfpName === "") {
					flag = false;
					rfpNameErrorFlag = false;
				}
				tempTableData[i] = {
					...tempTableData[i],
					rfpNameError: !rfpNameErrorFlag && item.rfpName === "",
				};

				// check only empty or not, because validation is already done in disabledDate function
				if (item.Q1_ExpectedWeek === "") {
					flag = false;
					Q1_ExpectedWeekErrorFlag = false;
				}
				tempTableData[i] = {
					...tempTableData[i],
					Q1_ExpectedWeekError:
						!Q1_ExpectedWeekErrorFlag && item.Q1_ExpectedWeek === "",
				};

				// check only empty or not, because validation is already done in disabledDate function
				if (item.Q2_ExpectedWeek === "") {
					flag = false;
					Q2_ExpectedWeekErrorFlag = false;
				}
				tempTableData[i] = {
					...tempTableData[i],
					Q2_ExpectedWeekError:
						!Q2_ExpectedWeekErrorFlag && item.Q2_ExpectedWeek === "",
				};

				// check only empty or not, because validation is already done in disabledDate function
				if (item.Q3_ExpectedWeek === "") {
					flag = false;
					Q3_ExpectedWeekErrorFlag = false;
				}
				tempTableData[i] = {
					...tempTableData[i],
					Q3_ExpectedWeekError:
						!Q3_ExpectedWeekErrorFlag && item.Q3_ExpectedWeek === "",
				};

				// check only empty or not, because validation is already done in disabledDate function
				if (item.Q4_ExpectedWeek === "") {
					flag = false;
					Q4_ExpectedWeekErrorFlag = false;
				}
				tempTableData[i] = {
					...tempTableData[i],
					Q4_ExpectedWeekError:
						!Q4_ExpectedWeekErrorFlag && item.Q4_ExpectedWeek === "",
				};

				// check only empty or not, because validation is already done in disabledDateReminderWeek function
				if (item.Q1_ReminderWeek === "") {
					flag = false;
					Q1_ReminderWeekErrorFlag = false;
				}
				tempTableData[i] = {
					...tempTableData[i],
					Q1_ReminderWeekError:
						!Q1_ReminderWeekErrorFlag && item.Q1_ReminderWeek === "",
				};

				// check only empty or not, because validation is already done in disabledDateReminderWeek function
				if (item.Q2_ReminderWeek === "") {
					flag = false;
					Q2_ReminderWeekErrorFlag = false;
				}
				tempTableData[i] = {
					...tempTableData[i],
					Q2_ReminderWeekError:
						!Q2_ReminderWeekErrorFlag && item.Q2_ReminderWeek === "",
				};

				// check only empty or not, because validation is already done in disabledDateReminderWeek function
				if (item.Q3_ReminderWeek === "") {
					flag = false;
					Q3_ReminderWeekErrorFlag = false;
				}
				tempTableData[i] = {
					...tempTableData[i],
					Q3_ReminderWeekError:
						!Q3_ReminderWeekErrorFlag && item.Q3_ReminderWeek === "",
				};

				// check only empty or not, because validation is already done in disabledDateReminderWeek function
				if (item.Q4_ReminderWeek === "") {
					flag = false;
					Q4_ReminderWeekErrorFlag = false;
				}
				tempTableData[i] = {
					...tempTableData[i],
					Q4_ReminderWeekError:
						!Q4_ReminderWeekErrorFlag && item.Q4_ReminderWeek === "",
				};

				// check only empty or not
				if (item.contactEmail === "") {
					flag = false;
					contactEmailErrorFlag = false;
				}

				// check only empty or not
				if (item.contactName === "") {
					flag = false;
					contactNameErrorFlag = false;
				}

				// checks contact name
				if (!NAME_REGEX.test(item.contactName)) {
					flag = false;
					contactNameErrorFlag = false;
				}

				// checks contact email
				if (!EMAIL_REGEX.test(item.contactEmail)) {
					flag = false;
					contactEmailErrorFlag = false;
				}

				// checks for yahoo and google domain
				if (
					item.contactEmail
						.substring(
							item.contactEmail.indexOf("@") + 1,
							item.contactEmail.lastIndexOf(".")
						)
						.includes("gmail") ||
					item.contactEmail
						.substring(
							item.contactEmail.indexOf("@") + 1,
							item.contactEmail.lastIndexOf(".")
						)
						.includes("yahoo")
				) {
					flag = false;
					contactEmailErrorFlag = false;
				}

				// checks if multiple .com/ending domain are present or not
				const tempEmail = item.contactEmail.split(".");
				let count = 0;
				tempEmail.forEach((item) => {
					if (item === tempEmail[tempEmail.length - 1]) {
						count = count + 1;
					}
				});
				if (count > 1) {
					flag = false;
					contactEmailErrorFlag = false;
				}

				tempTableData[i] = {
					...tempTableData[i],
					contactEmailError:
						!contactEmailErrorFlag &&
						(item.contactEmail === "" ||
							!EMAIL_REGEX.test(item.contactEmail) ||
							item.contactEmail
								.substring(
									item.contactEmail.indexOf("@") + 1,
									item.contactEmail.lastIndexOf(".")
								)
								.includes("gmail") ||
							item.contactEmail
								.substring(
									item.contactEmail.indexOf("@") + 1,
									item.contactEmail.lastIndexOf(".")
								)
								.includes("yahoo") ||
							count > 0),
				};
				tempTableData[i] = {
					...tempTableData[i],
					contactNameError:
						!contactNameErrorFlag &&
						(item.contactName === "" || !NAME_REGEX.test(item.contactName)),
				};
			}
			// uncomment this if row by row at a time error needs to be displayed
			// if (!flag) break;
		}
		this.setState({
			tableData: tempTableData,
			rfpNameError: !rfpNameErrorFlag,
			Q1_ExpectedWeekError: !Q1_ExpectedWeekErrorFlag,
			Q2_ExpectedWeekError: !Q2_ExpectedWeekErrorFlag,
			Q3_ExpectedWeekError: !Q3_ExpectedWeekErrorFlag,
			Q4_ExpectedWeekError: !Q4_ExpectedWeekErrorFlag,
			Q1_ReminderWeekError: !Q1_ReminderWeekErrorFlag,
			Q2_ReminderWeekError: !Q2_ReminderWeekErrorFlag,
			Q3_ReminderWeekError: !Q3_ReminderWeekErrorFlag,
			Q4_ReminderWeekError: !Q4_ReminderWeekErrorFlag,
			contactEmailError: !contactEmailErrorFlag,
			contactNameError: !contactNameErrorFlag,
			showErrorBlock: !flag,
		});
		return flag;
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-format data as needed by BE
	 */
	formatDataForAPICall = () => {
		let tempData = this.state.tableData.map((item) => {
			let temp = { ...item };
			if (temp.hasOwnProperty("new")) {
				delete temp.Id;
				delete temp.new;
			}
			return temp;
		});
		return {
			advertiserID: this.extractAdvertiserID(this.state.advertiserFilter),
			type: "Advertiser",
			fin_month: this.state.monthFilter,
			fin_year: this.state.yearFilter,
			setupDetail: tempData,
		};
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-context menu click listener
	 */
	onActionClick = (e, record) => {
		e.preventDefault();
		if (!this.state.actionPopupVisible) {
			const self = this;
			document.addEventListener(`click`, function onClickOutside() {
				self.setState({ actionPopupVisible: false, actionCampID: -1 });
				document.removeEventListener(`click`, onClickOutside);
			});
		}
		this.setState({
			x: e.clientX,
			y: e.clientY + window.pageYOffset,
			actionPopupVisible: true,
			actionRecord: record,
		});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-context menu item click handler
	 */
	handeContextMenuItemClick = (e) => {
		const { id } = e.currentTarget;
		if (id === "edit_rfp") {
			this.setState({ currentEditId: this.state.actionRecord.Id });
		} else if (id === "cancel_rfp") {
			Swal.fire({
				title: "Are you sure you want to cancel this RFP reminder?",
				type: "question",
				showCancelButton: true,
				cancelButtonColor: "#d33",
				confirmButtonColor: "#3085d6",
				allowOutsideClick: false,
				confirmButtonText: "Yes",
			}).then((result) => {
				if (result.value) {
					this.fetchCancelRFPReminder();
				}
			});
		}
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-add rfp button handler
	 */
	onAddRFPClick = (e) => {
		let Id = 0;
		let found = true;
		do {
			Id = Math.random();
			const presentObj = this.state.tableData.filter((item) => item.Id === Id);
			if (presentObj.length === 0) {
				found = false;
			}
		} while (found);
		this.setState({
			tableData: [
				...this.state.tableData,
				{
					Id: Id,
					rfpName: "",
					Q1_ExpectedWeek: "",
					Q1_ReminderWeek: "",
					Q2_ExpectedWeek: "",
					Q2_ReminderWeek: "",
					Q3_ExpectedWeek: "",
					Q3_ReminderWeek: "",
					Q4_ExpectedWeek: "",
					Q4_ReminderWeek: "",
					contactEmail: "",
					contactName: "",
					new: true,
					updated: true,
					// for all errors
					rfpNameError: false,
					Q1_ExpectedWeekError: false,
					Q1_ReminderWeekError: false,
					Q2_ExpectedWeekError: false,
					Q2_ReminderWeekError: false,
					Q3_ExpectedWeekError: false,
					Q3_ReminderWeekError: false,
					Q4_ExpectedWeekError: false,
					Q4_ReminderWeekError: false,
					contactEmailError: false,
					contactNameError: false,
				},
			],
			currentEditId: Id,
		});
	};

	/**
	 * @author : Karan Jagtap
	 * @description : Task-3515-confirm and save button click handler
	 */
	onConfirmAndSaveClick = (e) => {
		e.preventDefault();
		if (this.validate()) {
			const data = this.formatDataForAPICall();
			this.fetchConfirmAndSave(data);
		}
	};

	render() {
		const TABLE_COLUMNS = [
			{
				title: "RFP Name",
				dataIndex: "rfpName",
				width: "160px",
				render: (text, record) => {
					return (
						<Input
							style={
								record.rfpNameError
									? { borderColor: "red" }
									: { borderColor: "" }
							}
							id="rfpName"
							size="middle"
							disabled={this.state.currentEditId !== record.Id}
							onChange={(e) => {
								if (this.state.currentEditId === record.Id) {
									this.onHandleRFPNameEmailNameChange(e, record);
								}
							}}
							value={text}
						/>
					);
				},
			},
			{
				title: this.state.columnQ1,
				children: [
					{
						title: "Expected Week",
						dataIndex: "Q1_ExpectedWeek",
						width: "150px",
						render: (text, record) => {
							return (
								<DatePicker
									className={
										record.Q1_ExpectedWeekError && BORDER_COLOR_ERROR_CSS
									}
									value={
										record.Q1_ExpectedWeek !== ""
											? moment(record.Q1_ExpectedWeek)
											: null
									}
									disabledDate={(current) => this.disabledDate(current, 1)}
									disabled={this.state.currentEditId !== record.Id}
									onChange={(_, dateString) =>
										this.onHandleDateChange(
											dateString,
											record,
											"Q1_ExpectedWeek"
										)
									}
								/>
							);
						},
					},
					{
						title: "Reminder Week",
						dataIndex: "Q1_ReminderWeek",
						width: "150px",
						render: (text, record) => {
							return (
								<DatePicker
									className={
										record.Q1_ReminderWeekError && BORDER_COLOR_ERROR_CSS
									}
									value={
										record.Q1_ReminderWeek !== ""
											? moment(record.Q1_ReminderWeek)
											: null
									}
									disabledDate={(current) =>
										this.disabledDateReminderWeek(current, 1, record)
									}
									disabled={
										this.state.currentEditId !== record.Id ||
										record.Q1_ExpectedWeek === ""
									}
									onChange={(_, dateString) =>
										this.onHandleDateChange(
											dateString,
											record,
											"Q1_ReminderWeek"
										)
									}
								/>
							);
						},
					},
				],
			},
			{
				title: this.state.columnQ2,
				children: [
					{
						title: "Expected Week",
						dataIndex: "Q2_ExpectedWeek",
						width: "150px",
						render: (text, record) => {
							return (
								<DatePicker
									className={
										record.Q2_ExpectedWeekError && BORDER_COLOR_ERROR_CSS
									}
									value={
										record.Q2_ExpectedWeek !== ""
											? moment(record.Q2_ExpectedWeek)
											: null
									}
									disabledDate={(current) => this.disabledDate(current, 2)}
									disabled={this.state.currentEditId !== record.Id}
									onChange={(_, dateString) =>
										this.onHandleDateChange(
											dateString,
											record,
											"Q2_ExpectedWeek"
										)
									}
								/>
							);
						},
					},
					{
						title: "Reminder Week",
						dataIndex: "Q2_ReminderWeek",
						width: "150px",
						render: (text, record) => {
							return (
								<DatePicker
									className={
										record.Q2_ReminderWeekError && BORDER_COLOR_ERROR_CSS
									}
									value={
										record.Q2_ReminderWeek !== ""
											? moment(record.Q2_ReminderWeek)
											: null
									}
									disabledDate={(current) =>
										this.disabledDateReminderWeek(current, 2, record)
									}
									disabled={
										this.state.currentEditId !== record.Id ||
										record.Q2_ExpectedWeek === ""
									}
									onChange={(_, dateString) =>
										this.onHandleDateChange(
											dateString,
											record,
											"Q2_ReminderWeek"
										)
									}
								/>
							);
						},
					},
				],
			},
			{
				title: this.state.columnQ3,
				children: [
					{
						title: "Expected Week",
						dataIndex: "Q3_ExpectedWeek",
						width: "150px",
						render: (text, record) => {
							return (
								<DatePicker
									className={
										record.Q3_ExpectedWeekError && BORDER_COLOR_ERROR_CSS
									}
									value={
										record.Q3_ExpectedWeek !== ""
											? moment(record.Q3_ExpectedWeek)
											: null
									}
									disabledDate={(current) => this.disabledDate(current, 3)}
									disabled={this.state.currentEditId !== record.Id}
									onChange={(_, dateString) =>
										this.onHandleDateChange(
											dateString,
											record,
											"Q3_ExpectedWeek"
										)
									}
								/>
							);
						},
					},
					{
						title: "Reminder Week",
						dataIndex: "Q3_ReminderWeek",
						width: "150px",
						render: (text, record) => {
							return (
								<DatePicker
									className={
										record.Q3_ReminderWeekError && BORDER_COLOR_ERROR_CSS
									}
									value={
										record.Q3_ReminderWeek !== ""
											? moment(record.Q3_ReminderWeek)
											: null
									}
									disabledDate={(current) =>
										this.disabledDateReminderWeek(current, 3, record)
									}
									disabled={
										this.state.currentEditId !== record.Id ||
										record.Q3_ExpectedWeek === ""
									}
									onChange={(_, dateString) =>
										this.onHandleDateChange(
											dateString,
											record,
											"Q3_ReminderWeek"
										)
									}
								/>
							);
						},
					},
				],
			},
			{
				title: this.state.columnQ4,
				children: [
					{
						title: "Expected Week",
						dataIndex: "Q4_ExpectedWeek",
						width: "150px",
						render: (text, record) => {
							return (
								<DatePicker
									className={
										record.Q4_ExpectedWeekError && BORDER_COLOR_ERROR_CSS
									}
									value={
										record.Q4_ExpectedWeek !== ""
											? moment(record.Q4_ExpectedWeek)
											: null
									}
									disabledDate={(current) => this.disabledDate(current, 4)}
									disabled={this.state.currentEditId !== record.Id}
									onChange={(_, dateString) =>
										this.onHandleDateChange(
											dateString,
											record,
											"Q4_ExpectedWeek"
										)
									}
								/>
							);
						},
					},
					{
						title: "Reminder Week",
						dataIndex: "Q4_ReminderWeek",
						width: "150px",
						render: (text, record) => {
							return (
								<DatePicker
									className={
										record.Q4_ReminderWeekError && BORDER_COLOR_ERROR_CSS
									}
									value={
										record.Q4_ReminderWeek !== ""
											? moment(record.Q4_ReminderWeek)
											: null
									}
									disabledDate={(current) =>
										this.disabledDateReminderWeek(current, 4, record)
									}
									disabled={
										this.state.currentEditId !== record.Id ||
										record.Q4_ExpectedWeek === ""
									}
									onChange={(_, dateString) =>
										this.onHandleDateChange(
											dateString,
											record,
											"Q4_ReminderWeek"
										)
									}
								/>
							);
						},
					},
				],
			},
			{
				title: "Contact Person Information",
				children: [
					{
						title: "Email ID",
						dataIndex: "contactEmail",
						width: "150px",
						render: (text, record) => {
							return (
								<Input
									style={
										record.contactEmailError
											? { borderColor: "red" }
											: { borderColor: "" }
									}
									id="contactEmail"
									size="middle"
									disabled={this.state.currentEditId !== record.Id}
									onChange={(e) => {
										if (this.state.currentEditId === record.Id) {
											this.onHandleRFPNameEmailNameChange(e, record);
										}
									}}
									value={text}
								/>
							);
						},
					},
					{
						title: "Name",
						dataIndex: "contactName",
						width: "150px",
						render: (text, record) => {
							return (
								<Input
									style={
										record.contactNameError
											? { borderColor: "red" }
											: { borderColor: "" }
									}
									id="contactName"
									size="middle"
									disabled={this.state.currentEditId !== record.Id}
									onChange={(e) => {
										if (this.state.currentEditId === record.Id) {
											this.onHandleRFPNameEmailNameChange(e, record);
										}
									}}
									value={text}
								/>
							);
						},
					},
				],
			},
			{
				title: "Action",
				dataIndex: "action",
				width: "80px",
				render: (text, record) => (
					<div
						className="action_context_menu_icon"
						onClick={(e) => this.onActionClick(e, record)}>
						<span
							style={{
								padding: 5,
								position: "relative",
								top: -4,
								left: 0,
							}}>
							...
						</span>
					</div>
				),
			},
		];
		return (
			<div id="rfpReminderSetup" className={DISABLE_CSS}>
				<Navigation />
				<div
					style={{
						paddingTop: 90,
						paddingBottom: 80,
						paddingLeft: 20,
						paddingRight: 20,
					}}>
					{/* Start - DI Page Loader */}
					<Loader
						loadingRef={this.loadingRef}
						closeLoadingRef={this.closeLoadingRef}
						message="Please wait. Saving the data...."
					/>
					{/* End - DI Page Loader */}

					{/* Start - Page Heading */}
					<div className="heading-container">
						<span className="heading-text">Advertiser RFP Reminder Setup</span>
					</div>
					{/* End - Page Heading */}

					{/* Start - Sub Heading - Basic Details */}
					<div className="row" style={{ marginBottom: 15 }}>
						<div className="col">
							<span className="sub-heading">Advertiser Financial Year :</span>
						</div>
					</div>
					{/* End - Sub Heading - Basic Details */}

					{/* Start - Filters */}
					<div className="row">
						<div className="col-12 col-md-auto">
							<div className="dropdown-heading">Advertiser</div>
							<select
								id="advertiser-filter"
								className="form-control dropdown-parent"
								value={this.state.advertiserFilter}
								onChange={this.handleFilterChange}>
								<option value="select-advertiser">Select Advertiser</option>
								{this.state.advertiserNames.length > 0 &&
									this.state.advertiserNames.map((item, i) => (
										<option key={i} value={item}>
											{item}
										</option>
									))}
							</select>
						</div>
						<div className="col-12 col-md-auto mt-3 mt-md-0">
							<div className="dropdown-heading">Financial Starting Month</div>
							<select
								id="month-filter"
								className="form-control dropdown-parent"
								disabled={this.state.isFilterComplete}
								value={this.state.monthFilter}
								onChange={this.handleFilterChange}>
								<option value="select-month">Select Financial Month</option>
								{MONTHS.map((item, i) => (
									<option key={i} value={item}>
										{item}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-md-auto mt-3 mt-md-0">
							<div className="dropdown-heading">Financial Year</div>
							<select
								id="year-filter"
								className="form-control dropdown-parent"
								disabled={this.state.isFilterComplete}
								value={this.state.yearFilter}
								onChange={this.handleFilterChange}>
								<option value="select-year">Select Financial Year</option>
								{YEARS.map((item, i) => (
									<option key={i} value={item}>
										{item}
									</option>
								))}
							</select>
						</div>
					</div>
					{/* End - Filters */}

					{/* Start - Table DI Loader - Incase there are any future changes, please don't move the sequence of this loader div which is before main table */}
					{this.state.loadingTable && (
						<div align="center" id="loader_reminder_table"></div>
					)}
					{/* End - Table DI Loader */}

					{/* Start - Error Block */}
					{this.state.showErrorBlock && (
						<div
							class="row my-3"
							style={{
								border: "1px solid red",
								marginLeft: 0,
								marginRight: 0,
							}}>
							<div
								id="error-list"
								className="errorMessage"
								style={{
									padding: "8px 15px",
								}}>
								{this.state.rfpNameError && (
									<li>
										<b>Please enter RFP name</b>
									</li>
								)}
								{this.state.Q1_ExpectedWeekError && (
									<li>
										<b>Please enter Q1 expected week date</b>
									</li>
								)}
								{this.state.Q1_ReminderWeekError && (
									<li>
										<b>Please enter Q1 reminder week date</b>
									</li>
								)}
								{this.state.Q2_ExpectedWeekError && (
									<li>
										<b>Please enter Q2 expected week date</b>
									</li>
								)}
								{this.state.Q2_ReminderWeekError && (
									<li>
										<b>Please enter Q2 reminder week date</b>
									</li>
								)}
								{this.state.Q3_ExpectedWeekError && (
									<li>
										<b>Please enter Q3 expected week date</b>
									</li>
								)}
								{this.state.Q3_ReminderWeekError && (
									<li>
										<b>Please enter Q3 reminder week date</b>
									</li>
								)}
								{this.state.Q4_ExpectedWeekError && (
									<li>
										<b>Please enter Q4 expected week date</b>
									</li>
								)}
								{this.state.Q4_ReminderWeekError && (
									<li>
										<b>Please enter Q4 reminder week date</b>
									</li>
								)}
								{this.state.contactEmailError && (
									<li>
										<b>Please enter valid contact email</b>
									</li>
								)}
								{this.state.contactNameError && (
									<li>
										<b>Please enter valid contact name</b>
									</li>
								)}
							</div>
						</div>
					)}
					{/* End - Error Block */}

					{/* Start - Main Table */}
					<div style={{ marginTop: 10, marginBottom: 10 }}>
						<Table
							className={TABLE_CSS}
							bordered
							size="small"
							columns={TABLE_COLUMNS}
							rowClassName="editable-row"
							dataSource={
								this.state.tableData.length > 0 ? this.state.tableData : null
							}
							scroll={{
								x: this.state.tableData.length > 0 ? "auto" : "hidden",
							}}
							pagination={{
								pageSize: this.state.recordsPerPage,
								position: "bottom",
							}}
						/>
					</div>
					{/* End - Main Table */}

					{/* Start - Table footer */}
					<div className="row">
						<div
							className="col-6 d-flex align-items-center"
							id="colForRfp"
							style={{   marginLeft: "1rem", height: 56 }}>
							<div style={{ height: 24 }}>
								<span style={{ marginRight: 5, marginLeft: 8 }}>
									Records per page :&nbsp;
								</span>
								<span>
									<select
										className="form-control"
										style={{
											width: 70,
											display: "initial",
											height: "initial",
											padding: 2,
										}}
										value={this.state.recordsPerPage}
										onChange={this.handleRecordsPerPage}>
										<option value={100}>100</option>
										<option value={200}>200</option>
										<option value={300}>300</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					{/* End - Table footer */}

					{/* Start - Action Context Menu */}
					{this.state.actionPopupVisible && (
						<div
							className="popup-rfp-reminder-action"
							style={{
								right: `${window.innerWidth - this.state.x}px`,
								top: `${this.state.y}px`,
								padding: 7,
							}}>
							<div id="edit_rfp" onClick={this.handeContextMenuItemClick}>
								<img
									src="Edit.png"
									style={{ height: 15, width: 15, marginLeft: "inherit" }}
									alt="" //Nilesh-2833-Removing console warnings
								/>
								<span style={{ fontSize: 12, lineHeight: 0 }}>Edit RFP</span>
							</div>
							<div
								style={{
									height: 0.5,
									backgroundColor: "#eee",
									marginLeft: 5,
									marginRight: 5,
									padding: 0,
									marginTop: 2,
									marginBottom: 2,
								}}></div>
							<div id="cancel_rfp" onClick={this.handeContextMenuItemClick}>
								<img
									src="Reject.png"
									style={{ height: 15, width: 15, marginLeft: "inherit" }}
									alt="" //Nilesh-2833-Removing console warnings
								/>
								<span style={{ fontSize: 12, lineHeight: 0 }}>Cancel RFP</span>
							</div>
						</div>
					)}
					{/* End - Action Context Menu */}

					{/* Start - Add RFP Button */}
					<button
						className="btn-add-rfp"
						onClick={this.onAddRFPClick}
						disabled={!this.state.isFilterComplete}
						ref={this.addRFPBtnRef}>
						<img
							style={{ height: 16, width: 16, marginLeft: 0, marginRight: 0 }}
							src="add_black_icon.png"
							alt="" //Nilesh-2833-Removing console warnings
						/>
						<span className="btn-add-rfp-text">Add RFP</span>
					</button>
					{/* Start - Add RFP Button */}

					{/* Start - Confirm & Save Button */}
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<button
							className="btn-confirm-save"
							disabled={!this.state.isFilterComplete}
							onClick={this.onConfirmAndSaveClick}>
							Confirm & Save
						</button>
					</div>
					{/* End - Confirm & Save Button */}
				</div>
				<Footer />
			</div>
		);
	}
}

/**
 * @author Karan Jagtap
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
RfpReminderSetup.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(RfpReminderSetup)
);
