/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Somnath Keswad
@Creation Date:19-06-2019
@Description:UI for  Completed Quick Upload under Advertiser view
*/

import React from "react";

import * as $ from "jquery";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { MDBRow, MDBCol } from "mdbreact";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
// import Picky from "react-picky"; //kiran-4748-removing console warning-no used
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3850-vAPT URL changes

// const queryString = require("query-string");//kiran-4748-removing console warning-no used
const Swal = require("sweetalert2");
var newsuppressionfilenames = [];
var suppressionnames = [];
var count = 0;
var quickFileLength = 0;
var btnShow = "";
var leadAllocationValidate = true;
let chkQuickFile = [],
	duplicateQuickFile = [];

/*
@author:Sanobar golandaj
@Creation Date:28-06-19
@Description:Array for timezone
*/
//kiran-4748-removing console warning-no used
// const TimeZoneOption = [
// 	{
// 		id: "(GMT-12:00) International Date Line West",
// 		name: "(GMT-12:00) International Date Line West",
// 	},
// 	{
// 		id: "(GMT-11:00) Midway Island, Samoa",
// 		name: "(GMT-11:00) Midway Island, Samoa",
// 	},
// 	{ id: "(GMT-10:00) Hawaii", name: "(GMT-10:00) Hawaii" },
// 	{ id: "(GMT-09:00) Alaska", name: "Supp(GMT-09:00) Alaskaort" },
// 	{
// 		id: "(GMT-08:00) Pacific Time (US & Canada)",
// 		name: "(GMT-08:00) Pacific Time (US & Canada)",
// 	},
// 	{
// 		id: "(GMT-08:00) Tijuana, Baja California",
// 		name: "(GMT-08:00) Tijuana, Baja California",
// 	},
// 	{ id: "(GMT-07:00) Arizona", name: "(GMT-07:00) Arizona" },
// 	{
// 		id: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
// 		name: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
// 	},
// 	{
// 		id: "(GMT-07:00) Mountain Time (US & Canada)pport",
// 		name: "(GMT-07:00) Mountain Time (US & Canada)",
// 	},
// 	{ id: "(GMT-06:00) Central America", name: "(GMT-06:00) Central America" },
// 	{
// 		id: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
// 		name: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
// 	},
// 	{
// 		id:
// 			"(GMT-06:00) Central Time (US & Canada)(GMT-06:00) Central Time (US & Canada)",
// 		name: "(GMT-06:00) Central Time (US & Canada)",
// 	},
// 	{ id: "(GMT-06:00) Saskatchewan", name: "(GMT-06:00) Saskatchewan" },
// 	{
// 		id: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
// 		name: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
// 	},
// 	{
// 		id: "(GMT-05:00) Eastern Time (US & Canada)",
// 		name: "(GMT-05:00) Eastern Time (US & Canada)",
// 	},
// 	{ id: "(GMT-05:00) Indiana (East)", name: "(GMT-05:00) Indiana (East)" },
// 	{
// 		id: "(GMT-04:00) Atlantic Time (Canada)",
// 		name: "(GMT-04:00) Atlantic Time (Canada)",
// 	},
// 	{ id: "(GMT-04:00) Caracas, La Paz", name: "(GMT-04:00) Caracas, La Paz" },
// 	{ id: "(GMT-04:00) Manaus", name: "(GMT-04:00) Manaus" },
// 	{ id: "(GMT-04:00) Santiago", name: "(GMT-04:00) Santiago" },
// 	{ id: "(GMT-03:30) Newfoundland", name: "(GMT-03:30) Newfoundland" },
// 	{ id: "(GMT-03:00) Brasilia", name: "(GMT-03:00) Brasilia" },
// 	{
// 		id: "(GMT-03:00) Buenos Aires, Georgetown",
// 		name: "(GMT-03:00) Buenos Aires, Georgetown",
// 	},
// 	{ id: "(GMT-03:00) Greenland", name: "(GMT-03:00) Greenland" },
// 	{ id: "(GMT-03:00) Montevideo", name: "(GMT-03:00) Montevideo" },
// 	{ id: "(GMT-02:00) Mid-Atlantic", name: "(GMT-02:00) Mid-Atlantic" },
// 	{ id: "(GMT-01:00) Cape Verde Is", name: "(GMT-01:00) Cape Verde Is" },
// 	{ id: "(GMT-01:00) Azores", name: "(GMT-01:00) Azores" },
// 	{
// 		id: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
// 		name: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
// 	},
// 	{
// 		id: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
// 		name: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
// 	},
// 	{
// 		id: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
// 		name: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
// 	},
// 	{
// 		id: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
// 		name: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
// 	},
// 	{
// 		id: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
// 		name: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
// 	},
// 	{
// 		id: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
// 		name: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
// 	},
// 	{
// 		id: "(GMT+01:00) West Central Africa",
// 		name: "(GMT+01:00) West Central Africa",
// 	},
// 	{ id: "(GMT+02:00) Amman", name: "(GMT+02:00) Amman" },
// 	{
// 		id: "(GMT+02:00) Athens, Bucharest, Istanbul",
// 		name: "(GMT+02:00) Athens, Bucharest, Istanbul",
// 	},
// 	{ id: "(GMT+02:00) Beirut", name: "(GMT+02:00) Beirut" },
// 	{ id: "(GMT+02:00) Cairo", name: "(GMT+02:00) Cairo" },
// 	{ id: "(GMT+02:00) Harare, Pretoria", name: "(GMT+02:00) Harare, Pretoria" },
// 	{
// 		id: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
// 		name: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
// 	},
// 	{ id: "(GMT+02:00) Jerusalem", name: "(GMT+02:00) Jerusalem" },
// 	{ id: "(GMT+02:00) Minsk", name: "(GMT+02:00) Minsk" },
// 	{ id: "(GMT+02:00) Windhoek", name: "(GMT+02:00) Windhoek" },
// 	{
// 		id: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
// 		name: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
// 	},
// 	{
// 		id: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
// 		name: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
// 	},
// 	{ id: "(GMT+03:00) Nairobi", name: "(GMT+03:00) Nairobi" },
// 	{ id: "(GMT+03:00) Tbilisi", name: "(GMT+03:00) Tbilisi" },
// 	{ id: "(GMT+03:30) Tehran", name: "(GMT+03:30) Tehran" },
// 	{
// 		id: "(GMT+04:00) Abu Dhabi, Muscat",
// 		name: "(GMT+04:00) Abu Dhabi, Muscat",
// 	},
// 	{ id: "(GMT+04:00) Baku", name: "(GMT+04:00) Baku" },
// 	{ id: "(GMT+04:00) Yerevan", name: "(GMT+04:00) Yerevan" },
// 	{ id: "(GMT+04:30) Kabul", name: "(GMT+04:30) Kabul" },
// 	{ id: "(GMT+05:00) Yekaterinburg", name: "(GMT+05:00) Yekaterinburg" },
// 	{
// 		id: "(GMT+05:00) Islamabad, Karachi, Tashkent",
// 		name: "(GMT+05:00) Islamabad, Karachi, Tashkent",
// 	},
// 	{
// 		id: "(GMT+05:30) Sri Jayawardenapura",
// 		name: "(GMT+05:30) Sri Jayawardenapura",
// 	},
// 	{
// 		id: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
// 		name: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
// 	},
// 	{ id: "(GMT+05:45) Kathmandu", name: "(GMT+05:45) Kathmandu" },
// 	{
// 		id: "(GMT+06:00) Almaty, Novosibirsk",
// 		name: "(GMT+06:00) Almaty, Novosibirsk",
// 	},
// 	{ id: "(GMT+06:00) Astana, Dhaka", name: "(GMT+06:00) Astana, Dhaka" },
// 	{ id: "(GMT+06:30) Yangon (Rangoon)", name: "(GMT+06:30) Yangon (Rangoon)" },
// 	{
// 		id: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
// 		name: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
// 	},
// 	{ id: "(GMT+07:00) Krasnoyarsk", name: "(GMT+07:00) Krasnoyarsk" },
// 	{
// 		id: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
// 		name: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
// 	},
// 	{
// 		id: "(GMT+08:00) Kuala Lumpur, Singapore",
// 		name: "(GMT+08:00) Kuala Lumpur, Singapore",
// 	},
// 	{
// 		id: "(GMT+08:00) Irkutsk, Ulaan Bataar",
// 		name: "(GMT+08:00) Irkutsk, Ulaan Bataar",
// 	},
// 	{ id: "(GMT+08:00) Perth", name: "(GMT+08:00) Perth" },
// 	{ id: "(GMT+08:00) Taipei", name: "(GMT+08:00) Taipei" },
// 	{
// 		id: "(GMT+09:00) Osaka, Sapporo, Tokyo",
// 		name: "(GMT+09:00) Osaka, Sapporo, Tokyo",
// 	},
// 	{ id: "(GMT+09:00) Seoul", name: "(GMT+09:00) Seoul" },
// 	{ id: "(GMT+09:00) Yakutsk", name: "(GMT+09:00) Yakutsk" },
// 	{ id: "(GMT+09:30) Adelaide", name: "(GMT+09:30) Adelaide" },
// 	{ id: "(GMT+09:30) Darwin", name: "(GMT+09:30) Darwin" },
// 	{ id: "(GMT+10:00) Brisbane", name: "(GMT+10:00) Brisbane" },
// 	{
// 		id: "(GMT+10:00) Canberra, Melbourne, Sydney",
// 		name: "(GMT+10:00) Canberra, Melbourne, Sydney",
// 	},
// 	{ id: "(GMT+10:00) Hobart", name: "(GMT+10:00) Hobart" },
// 	{
// 		id: "(GMT+10:00) Guam, Port Moresby",
// 		name: "(GMT+10:00) Guam, Port Moresby",
// 	},
// 	{ id: "(GMT+10:00) Vladivostok", name: "(GMT+10:00) Vladivostok" },
// 	{
// 		id: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
// 		name: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
// 	},
// 	{
// 		id: "(GMT+12:00) Auckland, Wellington",
// 		name: "(GMT+12:00) Auckland, Wellington",
// 	},
// 	{
// 		id: "(GMT+12:00) Fiji, Kamchatka, Marshall Is",
// 		name: "(GMT+12:00) Fiji, Kamchatka, Marshall Is",
// 	},
// 	{ id: "(GMT+13:00) Nuku alofa", name: "Supp(GMT+13:00) Nuku alofaort" },
// ];

var timeZoneArray = [];
var dupfileName = [];
class EditQuickUpload extends React.Component {
	constructor() {
		super();
		this.state = {
			advCampID: "",
			fields: {},
			multi: {},
			errors: {},
			timeZone2: "",
			createdBy: "",
			alertDisplay: "none",
			alertBorder: "",
			alertWidth: "",
			alertAlign: "",
			quickFileLength: "",
			spanSpaceDisplay: "none",
			timeZone1: "true",
			industryFileData: [],
			uploadFormatFile: [],
			clientNameDetails: [],
			getQuickCampDetails: [],
			editQuickFile: [],
			loading: " ",
			showBtn: "false",
			displayLink: "false",
			msg: "",
			clientName: "",
			currency: "USD",
			abm: "Open",
		};
		this.nameInput = React.createRef();
		this.home = this.home.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.submitQuickUpload = this.submitQuickUpload.bind(this);
		this.dynamicABMFile = this.dynamicABMFile.bind(this);
		this.handleQuickFileUpload = this.handleQuickFileUpload.bind(this);
		this.handleChangetimezoneBack = this.handleChangetimezoneBack.bind(this);

		this.leadAllocationHandleChangeBack = this.leadAllocationHandleChangeBack.bind(
			this
		);
		this.budgetHandleChangeBack = this.budgetHandleChangeBack.bind(this);
		this.cplHandleChangeBack = this.cplHandleChangeBack.bind(this);
	}
	cplHandleChangeBack(e) {
		let fields = this.state;
		var name = e.target.name;
		var value = e.target.value;

		fields[e.target.name] = e.target.value;

		var value1 = this.state.getQuickCampDetails[0].advLeadAllocation;

		var name1 = "advBudget";
		var newbudget = value * value1;
		fields[name1] = newbudget;

		let getQuickCampDetails = [...this.state.getQuickCampDetails];
		getQuickCampDetails[0] = { ...getQuickCampDetails[0], [name1]: newbudget };
		getQuickCampDetails[0] = {
			...getQuickCampDetails[0],
			[name]: e.target.value,
		};

		this.setState({ getQuickCampDetails: getQuickCampDetails });
	}

	budgetHandleChangeBack(e) {
		let fields = this.state;
		var name = e.target.name;
		var value = e.target.value;
		fields[e.target.name] = e.target.value;

		var value1 = this.state.getQuickCampDetails[0].advLeadAllocation;
		var name1 = "advCPL";
		var newCPL = value / value1;

		var n = newCPL.toFixed(2);
		fields[name1] = n;
		let getQuickCampDetails = [...this.state.getQuickCampDetails];
		getQuickCampDetails[0] = { ...getQuickCampDetails[0], [name]: value };
		getQuickCampDetails[0] = { ...getQuickCampDetails[0], [name1]: n };
		this.setState({ getQuickCampDetails });
	}

	leadAllocationHandleChangeBack(e) {
		let fields = this.state;
		var name = e.target.name;
		var value = e.target.value;
		fields[e.target.name] = e.target.value;

		let getQuickCampDetails = [...this.state.getQuickCampDetails];
		getQuickCampDetails[0] = { ...getQuickCampDetails[0], [name]: value };

		// this.state.getQuickCampDetails = [...getQuickCampDetails];
		this.setState({ getQuickCampDetails: [...getQuickCampDetails] });//kiran-4748-removing console warning-use setState

		let fields1 = this.state.getQuickCampDetails[0];
		let errors = {};

		//  //LeadAllocation Validation
//kiran-4748-removing console warning-changed == to === and remove unnecessary newleadAllocation
		if (fields1["advLeadAllocation"] === undefined) {
			leadAllocationValidate = false;
			errors["advLeadAllocation"] = <li>Please fill below required fields</li>;
		} else {
			if (fields1["advLeadAllocation"] === "") {
				var pattern = new RegExp(/^(|[1-9]\d*)$/);
				// var newleadAllocation = e.target.value;
				if (!pattern.test(e.target.value)) {
					leadAllocationValidate = false;
					errors["leadAllocation"] = (
						<li>Please enter number for lead allocation</li>
					);
				} else {
					leadAllocationValidate = true;
					var cpl = this.state.getQuickCampDetails[0].advCPL;
					// var budget = this.state.getQuickCampDetails[0].advBudget;//kiran-4748-removing console warning-no used
					var value1 = e.target.value;
					var name1 = "advBudget";
					var newbudget = cpl * value1;
					fields[name1] = newbudget;

					let getQuickCampDetails = [...this.state.getQuickCampDetails];
					getQuickCampDetails[0] = {
						...getQuickCampDetails[0],
						[name1]: newbudget,
					};
					this.setState({ getQuickCampDetails });
				}
			} else {
				 pattern = new RegExp(/^(|[1-9]\d*)$/);
              //kiran-4748-removing console warning-changed == to ===
				if (
					!pattern.test(fields1["advLeadAllocation"]) ||
					e.target.value === "" ||
					e.target.value === null ||
					e.target.value === undefined
				) {
					leadAllocationValidate = false;
					errors["leadAllocation"] = (
						<li>Please enter number for lead allocation</li>
					);
				} else {
					leadAllocationValidate = true;
					//kiran-4748-removing console warning-changed var to let
					let cpl = this.state.getQuickCampDetails[0].advCPL;
					// var budget = this.state.getQuickCampDetails[0].advBudget; //kiran-4748-removing console warning-no used

					let value1 = e.target.value;
					let name1 = "advBudget";
					let newbudget = cpl * value1;
					fields[name1] = newbudget;

					let getQuickCampDetails = [...this.state.getQuickCampDetails];
					getQuickCampDetails[0] = {
						...getQuickCampDetails[0],
						[name1]: newbudget,
					};
					this.setState({ getQuickCampDetails });
				}
			}
		} // end of big else
		if (leadAllocationValidate === false) {
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		} else {
			this.setState({
				errors: "",
				alertBorder: "0px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}
	} // end of leadAllocationHandleBAck

	customHandleChange(e) {
		let fields = this.state;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	}

	handleChange(e) {
		let fields = this.state;
		 //snehal-task-3850-VAPT URL changes
		if(this.props.location.state!==undefined){
			var advCampID = this.props.location.state.advCampID;
			}
		if (advCampID === undefined || advCampID === "" || advCampID === null) {
			fields[e.target.name] = e.target.value;
			this.setState({ fields });
		} else {
			const { name, value } = e.target;

			let getQuickCampDetails = [...this.state.getQuickCampDetails];
			getQuickCampDetails[0] = { ...getQuickCampDetails[0], [name]: value };
			this.setState({ getQuickCampDetails });
		}
	} /** End of HandleChange */

	handleChangetimezoneBack = (value) => {
		var timeZonevalue = [];
		const name = "advTimezone";
		timeZonevalue.push(value.name);
		//create dynamic array
		let getQuickCampDetails = [...this.state.getQuickCampDetails];
		getQuickCampDetails[0] = {
			...getQuickCampDetails[0],
			[name]: timeZonevalue,
		};

		this.setState({
			timeZone2: value,
			getQuickCampDetails: getQuickCampDetails,
		});
	};

	submitQuickUpload(e) {
		e.preventDefault();
		e.target.className += " was-validated";

		if (this.validateForm() === false) {
			return;
		} else {
			this.setState({
				alertDisplay: "none",
				loading: "loader",
			});
		}
		// const { isAuthenticated, user } = this.props.auth;//kiran-4748-removing console warning-no used
 		//snehal-task-3850-VAPT URL changes
		if(this.props.location.state!==undefined){
			var advCampID = this.props.location.state.advCampID;
			}
		let description = "Quick Campaign Updated";

		let data = {
			advCampID: advCampID,
			description: description,
			quickData: this.state.getQuickCampDetails,
		};
		fetch("/advertiser/editQuickCampaign", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((response) => {
				console.log("Return with response");
				var advCampID = response.advCampID;
				this.setState({
					response: response,
					advCampID: advCampID,
					loading: "",
				});
				if (advCampID !== undefined || advCampID !== "") {
				}
				Swal.fire({
					text: "Campaign Edited Successfully",
					type: "success",
					confirmButtonText: "Ok",
					allowOutsideClick: false,
					onClose: () => {
						this.props.history.push('/editFinishQuickUpload',{advCampID:advCampID}) //snehal-task-3850-VAPT URL changes
					},
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	} /**End of Quick Upload */

	validateForm() {
		// let fields = this.state.fields;//kiran-4748-removing console warning-no used
		let errors = {};
		let formIsValid = true;
		let spanDisplay = "none";
		let requiredFeildValidate = true;
		let clientNameValidate = true;
		let dateValidate = true;
		let timeZoneValidate = true;
		let leadAllocationValidate = true;
		// let leadPerDomainValidate = true;
		// let CPLSize = true;
		// let budgetSize = true;
		// let cplBudget = true;//kiran-4748-removing console warning-no used
		let cplValidate = true;
		let budgetValidate = true;
		let idValidate = true;
		let fileValidate = true; //Sandeep-task-2473-added code for file validate true

		if(this.props.location.state!==undefined){
			// var advCampID = this.props.location.state.advCampID;//kiran-4748-removing console warning-no used
			} //snehal-task-3850-VAPT URL changes
		let campData = this.state.getQuickCampDetails;

		if (campData[0].advTimezone === undefined) {
			timeZoneValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
			this.setState({
				inputClass: "invalid",
			});
		}

		if (campData[0].advTimezone !== undefined) {
			timeZoneValidate = true;
			this.setState({
				inputClass: "valid",
			});
		}

		//sandeep-task-2473-added code for file validation
		//kiran-4748-removing console warning-changed == to ===
		if (newsuppressionfilenames === "") {
			fileValidate = false; //sandeep-task-2473-added code for file validate false
			errors["fileMessage"] = <li>Please Upload Supporting Document</li>; //sandeep-task-2473-added code to display error message
		} else {
			fileValidate = true;
		}

		/*
              @author:Sanobar golandaj
               @Description:validations for budget and cpl
              */
//kiran-4748-removing console warning-changed == to ===
		if (
			campData[0].advCustCampID === undefined ||
			campData[0].advCustCampID == null ||
			campData[0].advCustCampID === ""
		) {
			idValidate = true;
		} else {
			var pattern = new RegExp(/^[0-9]*$/);
			if (!pattern.test(campData[0].advCustCampID)) {
				idValidate = false;
				errors["idMessage"] = <li>Please enter number for Campaign id</li>;
			} else {
				idValidate = true;
			}
		}
		//kiran-4748-removing console warning-changed == to === and changed var to let
		if (campData[0].advCPL === undefined) {
			cplValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
		} else {
			if (campData[0].advCPL === "") {
				errors["commonError"] = <li>Please fill below required fields</li>;
				cplValidate = false;
			} else {
				let pattern = new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/);
				if (!pattern.test(campData[0].advCPL)) {
					cplValidate = false;
					errors["cpl"] = <li>Please enter valid number for cpl....</li>;
				}
			}
		}

		//Budget Validation
//kiran-4748-removing console warning-changed == to === and changed var to let
		if (campData[0].advBudget === undefined) {
		} else {
			if (campData[0].advBudget === "") {
				errors["commonError"] = <li>Please fill below required fields</li>;
				budgetValidate = false;
			} else {
				let pattern = new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/);
				if (!pattern.test(campData[0].advBudget)) {
					budgetValidate = false;
					errors["budget"] = (
						<li>Please enter valid number upto 2 decimal for budget</li>
					);
				}
			}
		}

		// end of budget cpl validation
		/*
              @author:Sanobar golandaj
               @Description:validations for LeadAllocation Validation
              */
			 //kiran-4748-removing console warning-changed == to === and changed var to let
		if (campData[0].advLeadAllocation === undefined) {
		} else {
			if (campData[0].advLeadAllocation === "") {
				errors["commonError"] = <li>Please fill below required fields</li>;
				leadAllocationValidate = false;
			} else {
				let pattern = new RegExp(/^[1-9]+[0-9]*$/);

				if (!pattern.test(campData[0].advLeadAllocation)) {
					leadAllocationValidate = false;
					errors["leadAllocation"] = (
						<li>Please enter number for Lead Allocation</li>
					);
				}
			}
		}

		//  Validation for required text feild.

		if (
			!campData[0].advCampaignName ||
			!campData[0].advStartDate ||
			!campData[0].advEndDate
		) {
			requiredFeildValidate = false;
			errors["commonError"] = <li>Please fill below required fields</li>;
		}

		//  end date validation
		if (
			Date.parse(campData[0].advEndDate) < Date.parse(campData[0].advStartDate)
		) {
			dateValidate = false;
			errors["endDate"] = <li>End date must be greater than start date</li>;
		}
		var currentDate = new Date(
			new Date().getTime() - new Date().getTimezoneOffset() * 60000
		)
			.toISOString()
			.split("T")[0];

		var dt = new Date(currentDate);
		dt.setMonth(dt.getMonth() - 1);

		var month, day, year;
		month = "" + (dt.getMonth() + 1);
		day = "" + dt.getDate();
		year = dt.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;
		var updatedDate = [year, month, day].join("-");
		if (Date.parse(campData[0].advStartDate) < Date.parse(updatedDate)) {
			dateValidate = false;
			errors["startDate"] = (
				<li>Start date can not be older than a month time period</li>
			);
		}

		if (campData[0].advEndDate < currentDate) {
			dateValidate = false;
			errors["endDate"] = <li>End date must be greater than current date</li>;
		}
//kiran-4748-removing console warning-changed == to ===
		if (
			requiredFeildValidate === false ||
			clientNameValidate === false ||
			dateValidate === false ||
			timeZoneValidate === false ||
			leadAllocationValidate === false ||
			cplValidate === false ||
			budgetValidate === false ||
			idValidate === false ||
			fileValidate === false
		) {
			//Sandeep-task-2473-added code for file validation

			formIsValid = false;
			this.setState({
				errors: errors,
				alertBorder: "1px solid red",
				alertWidth: "1000px",
				alertAlign: "center",
				alertDisplay: "block",
				spanSpaceDisplay: spanDisplay,
			});
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}
		return formIsValid;
	} // end of validateForm()

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} 
		//snehal-task-3850-VAPT URL changes
		if(this.props.location.state!==undefined){
		var advCampID = this.props.location.state.advCampID;
		}
		var currentDate = new Date(
			new Date().getTime() - new Date().getTimezoneOffset() * 60000
		)
			.toISOString()
			.split("T")[0];
		var dt = new Date(currentDate);
		dt.setMonth(dt.getMonth() - 1);

		var month, day;
		month = "" + (dt.getMonth() + 1);
		day = "" + dt.getDate();
		// year = dt.getFullYear();//kiran-4748-removing console warning-no used year,updatedDate

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;
		// var updatedDate = [year, month, day].join("-");
		if (advCampID === undefined || advCampID === "" || advCampID === null) {
			this.setState({ showQuickFileUpload: "false" });
		} else {
			this.setState({ showQuickFileUpload: "true" });
			fetch("advertiser/getQuickCampDetails?advCampID=" + advCampID)
				.then((res) => res.json())
				.then((getQuickCampDetails) => {
					this.setState({
						getQuickCampDetails: getQuickCampDetails,
						inputClass: "disabled",
					});
					if (
						this.state.getQuickCampDetails &&
						this.state.getQuickCampDetails.length
					) {
						var str = this.state.getQuickCampDetails[0].advTimezone;
						var str_array = str.split(",");
						for (var i = 0; i < str_array.length; i++) {
							timeZoneArray.push({ id: str_array[i], name: str_array[i] });
						}

						this.setState({ timeZone2: timeZoneArray });
					} // for timezone Array
				})
				.catch(function (err) {
					console.log(err);
				});
			fetch("advertiser/editQuickFile?advCampID=" + advCampID)
				.then((res) => res.json())
				.then((editQuickFile) => {
					this.setState({ editQuickFile: editQuickFile });
					if (editQuickFile.length > 0) {
						let self = this;
						var link;
						var HTML = "<table>";
						for (let i = 0; i < editQuickFile.length; i++) {
							// User Story - 3427 - Karan Jagtap - needed to change some layout to adjust the new remove icon
							// link= '<a class="removeSuppressionFile" href="#" data-fileid='+'"'+editQuickFile[i].advSuppDocName+'"'+'><i class="fa fa-times"></i></a>';
							link =
								'<a class="removeSuppressionFile" href="#" data-fileid=' +
								'"' +
								editQuickFile[i].advSuppDocName +
								'"' +
								'><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
							HTML +=
								"<tr><td style='display:flex;align-items:center;'>" +
								link +
								editQuickFile[i].advSuppDocName +
								"</td></tr>";
							newsuppressionfilenames.push(editQuickFile[i].advSuppDocName);
							dupfileName.push(editQuickFile[i].advSuppDocName);
						}
						HTML += "</table>";
						document.getElementById("suppressionList").innerHTML = HTML;
						var nosuppressionoutput = document.getElementById(
							"nosuppressionList"
						);
						var suppressionarrayLength1;
						var backsuppressionarraylength;
						var suppressionarrayLength;
						var filename = "";
						var temp2 = this.state.editQuickFile.length;
						$(document).on("click", ".removeSuppressionFile", function (e) {
							e.preventDefault();
							var clickedFile = $(this).parent().children("a").data("fileid");
							var indx = dupfileName.indexOf(clickedFile);

							if (indx > -1) {
								dupfileName.splice(indx, 1);
							}
							for (let j = 0; j < newsuppressionfilenames.length; j++) {
								var temp = j;
								if (newsuppressionfilenames[temp] === clickedFile) {
									filename = newsuppressionfilenames[temp];
									newsuppressionfilenames.splice(j, 1);
									$(this).parent().remove();
									suppressionarrayLength = newsuppressionfilenames.length;
									count--;

									let data = {
										advCampID: advCampID,
										names: filename,
									};

									fetch("advertiser/deleteQuickUpload", {
										method: "POST",
										headers: { "Content-Type": "application/json" },
										body: JSON.stringify(data),
									});

									if (count === 0) {
										self.setState({ showBtn: "false" });
									}
									suppressionarrayLength = parseInt(suppressionarrayLength);
									backsuppressionarraylength = parseInt(
										backsuppressionarraylength
									);
									suppressionarrayLength1 = temp2 - 1;
									temp2 = suppressionarrayLength1;

									if (suppressionarrayLength1 > 0) {
										nosuppressionoutput.innerHTML =
											suppressionarrayLength1 + "Files Selected";
									} else {
										nosuppressionoutput.innerHTML = "0 File Selected";
										$("#suppression").val("");
										var message = document.getElementById("suppresionMessage");
										message.innerHTML = "";
									}
								} // end of  if(names[temp] === clickedFile)
							} // end of for
						}); //$(document).on
					}
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	} // end of componentDidMount()

	handleQuickFileUpload(e) {
		e.preventDefault();

		suppressionnames = [];
		let self = this;
		if(this.props.location.state!==undefined!==undefined){
			var advCampID = this.props.location.state.advCampID;
			} //snehal-task-3850-VAPT URL changes

		let suppressionfilesData = e.target.files;
		for (let i = 0; i < suppressionfilesData.length; i++) {
			if (
				suppressionfilesData[i].name.substr(
					suppressionfilesData[i].name.lastIndexOf(".") + 1
				) in { csv: 1, xls: 2, xlsx: 3, pdf: 4, pptx: 5, ppt: 6, docx: 7 }
			) {
			} else {
				Swal.fire({
					text:
						"Please upload files having extensions: .csv, .xls, .xlsx, .pdf, .docx, .pptx, .ppt only",
					type: "error",
				});
				document.getElementById("suppression").value = null;
				return 0;
			}
		}

		for (let i = 0; i < dupfileName.length; i++) {
			for (let p = 0; p < suppressionfilesData.length; p++) {
				if (dupfileName[i] === suppressionfilesData[p].name) {
					Swal.fire({
						text: `File ${dupfileName[i]} already exists`, //kiran-4748-removing console warning - used template literals `` and ${}
						type: "error",
					});
					
					document.getElementById("suppression").value = null;
					return;
				}
			}
		}
		for (var i = 0, l = suppressionfilesData.length; i < l; i++) {
			let fileName = suppressionfilesData[i].name;
			dupfileName.push(fileName);
			if (chkQuickFile.includes(fileName)) {
				duplicateQuickFile.push(fileName);
			} else {
				suppressionnames.push(suppressionfilesData[i].name);
				count++;
			}
		}

		var link;
		var filename = "";
		var suppressionarrayLength = suppressionnames.length;

		var nosuppressionoutput = document.getElementById("nosuppressionList");
		var valuenosuppressionoutput = document.getElementById("nosuppressionList")
			.innerHTML;

		var suppressionres = [];//kiran-4748-removing console warning-added [] for array literal notation
		suppressionres = valuenosuppressionoutput.split(" ");

		var backsuppressionarraylength;
		for (i = 0; i < suppressionres.length; i++) {
			backsuppressionarraylength = suppressionres[0];
		}

		suppressionarrayLength = parseInt(suppressionarrayLength);
		backsuppressionarraylength = parseInt(backsuppressionarraylength);

		var suppressionarrayLength1;

		suppressionarrayLength1 =
			suppressionarrayLength + backsuppressionarraylength;
		if (suppressionarrayLength1 >= 1) {
			nosuppressionoutput.innerHTML =
				suppressionarrayLength1 + "Files Selected";
		} else {
			nosuppressionoutput.innerHTML = "0 File Selected";
		}

		for (var j = 0; j < suppressionarrayLength; j++) {
			newsuppressionfilenames.push(suppressionnames[j]);
		}

		var HTML = "<table>";
		//kiran-4748-removing console warning-changed var to let
		for (let j = 0; j < newsuppressionfilenames.length; j++) {
			// User Story - 3427 - Karan Jagtap - needed to change some layout to adjust the new remove icon
			// link= '<a class="removeSuppressionFile" href="#" data-fileid='+'"'+newsuppressionfilenames[j]+'"'+'><i class="fa fa-times"></i></a>';
			link =
				'<a class="removeSuppressionFile" href="#" data-fileid=' +
				'"' +
				newsuppressionfilenames[j] +
				'"' +
				'><img src="file_upload_2_blue.png" heigh="18" width="18" style="margin-right:10px"/></a>';
			HTML +=
				"<tr><td style='display:flex;align-items:center;'>" +
				link +
				newsuppressionfilenames[j] +
				"</td></tr>";
		}
		HTML += "</table>";

		document.getElementById("suppressionList").innerHTML = HTML;

		$(document).on("click", ".removeSuppressionFile", function (e) {
			e.preventDefault();
			var clickedFile = $(this).parent().children("a").data("fileid");
			var j = 0;
			var indx = dupfileName.indexOf(clickedFile);
			if (indx > -1) {
				dupfileName.splice(indx, 1);
			}
			for (j; j < newsuppressionfilenames.length; j++) {
				var temp = j;

				if (newsuppressionfilenames[temp] === clickedFile) {
					filename = newsuppressionfilenames[temp];

					newsuppressionfilenames.splice(j, 1);
					$(this).parent().remove();
					suppressionarrayLength = newsuppressionfilenames.length;
					count--;

					let data = {
						advCampID: advCampID,
						names: filename,
					};

					fetch("advertiser/deleteQuickUpload", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					});
					if (count === 0) {
						self.setState({ showBtn: "false" });
					}
					suppressionarrayLength = parseInt(suppressionarrayLength);
					backsuppressionarraylength = parseInt(backsuppressionarraylength);
					suppressionarrayLength1 =
						suppressionarrayLength + backsuppressionarraylength;

					if (suppressionarrayLength1 > 0) {
						nosuppressionoutput.innerHTML =
							suppressionarrayLength1 + "Files Selected";
					} else {
						nosuppressionoutput.innerHTML = "0 File Selected";
						$("#suppression").val("");
						var message = document.getElementById("suppresionMessage");
						message.innerHTML = "";
					}
				} // end of  if(names[temp] === clickedFile)
			} // end of for
		}); //$(document).on

		var data = new FormData();
		data.append("advCampID", advCampID);
		data.append("createdByName", this.state.createdBy);
//kiran-4748-removing console warning-changed var to let
		for (let i = 0, l = suppressionfilesData.length; i < l; i++) {
			let fileName = suppressionfilesData[i].name;
			if (duplicateQuickFile.includes(fileName)) {
			} else {
				data.append("file", suppressionfilesData[i]);
			}
		}

		if (count > 0) {
			self.setState({ showBtn: "true" });
		}
		fetch("advertiser/quickUploadFile", {
			method: "POST",

			body: data,
		})
			.then(function (response) {
				if (response.status >= 400) {
					throw new Error("Bad response from server");
				}
				return response.json();
			})
			.then(function (response) {})
			.catch(function (err) {
				console.log(err);
			});
		this.setState({ files: data });
		var msg = "Successfully Uploads Files";
		var message = document.getElementById("suppresionMessage");
		var number = document.getElementById("suppresionMessage").innerHTML;
		if (number === "") {
			message.innerHTML += msg;
		}
		document.getElementById("suppression").value = "";
	}

	dynamicABMFile(e) {
		// var suppDocName = e.target.getAttribute("name");//kiran-4748-removing console warning-no used
		var advSupportDocID = e.target.id;
		let campaignDetail = this.state.editQuickFile;

		campaignDetail = campaignDetail.filter(
			(el) => el.advSupportDocID !== advSupportDocID
		);
		// var index = campaignDetail.findIndex(
		// 	(v) => v.advSupportDocID === advSupportDocID
		// ); //kiran-4748-removing console warning-no used
		this.setState({ editQuickFile: campaignDetail });

		let data = {
			advSupportDocID: advSupportDocID,
		};

		fetch("advertiser/deleteQuickUpload", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		});
	}

	home(e) {
		e.preventDefault();
		const {  user } = this.props.auth;
		window.location.href = "/advertiserFrontPage?advertiserID=" + user.id;
	}

	render() {
		const { user } = this.props.auth;

		this.state.createdBy = user.name; //not resolved-giving issue

		const asterisk = { color: "red" };
		if(this.props.location.state!==undefined){
			// var advCampID = this.props.location.state.advCampID;//kiran-4748-removing console warning-no used
			} //snehal-task-3850-VAPT URL changes
		if (this.state.editQuickFile !== undefined) {
			quickFileLength = this.state.editQuickFile.length;
		}
		if (quickFileLength > 0) {
			btnShow = "true";
		} else {
			btnShow = "false";
		}

		return (
			<div>
				<Navigation />

				<div
					className="col-lg-12"
					class="displayerror"
					style={{ display: this.state.alertDisplay }}>
					<div class="container-fluid">
						{/* Sandeep-task-3133-Changes in label as Campaign Creation to Edit Campaign */}
						{/* shivani-3310-passed ID "labelDI" for label consistency */}
						<div class="col" style={{ textAlign: "center" }}>
							<label id="labelDI">Edit Campaign-Quick Upload</label>
						</div>

						<div class="card-body">
							<div class="row">
								<div
									style={{
										fontSize: "22px",
										color: "green",
										paddingLeft: "490px",
									}}>
									{this.state.msg}
								</div>
								<div
									class="col-lg-12"
									style={{
										border: this.state.alertBorder,
										display: this.state.alertDisplay,
									}}>
									<ul>
										<div style={{ fontSize: "14px", color: "red" }}>
											{this.state.errors.commonError}
										</div>
										<div
											className="errorMsg"
											style={{ fontSize: "14px", color: "red" }}>
											{this.state.errors.leadAllocation}
											{this.state.errors.noOfLeadPerDomain}
											{this.state.errors.endDate}
											{this.state.errors.startDate}
											{this.state.errors.clientName}
											{this.state.errors.cplBudget1}
											{this.state.errors.cpl}
											{this.state.errors.budget}
											{this.state.errors.idMessage}
											{/* Sandeep-task-2473-added code to display error message for file upload field*/}
											{this.state.errors.fileMessage}
										</div>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<form
					method="POST"
					id="createcampaign"
					name="Campaign"
					className="needs-validation"
					onSubmit={this.submitQuickUpload}
					noValidate>
						{/* kiran-4748-removing console warning-removed duplicate attribute and added #/, {''}*/}
					<div
						class="container-fluid"
						style={{ paddingBottom: "70px"}}>
						{this.state.displayLink === "true" ? (
							<a
								href="#/"
								style={{ paddingLeft: "1215px" }}
								onClick={this.home}>{''}</a>
						) : (
							""
						)}
						<div class="card-body" style={{ padding: "0rem" }}>
							<legend
								className="legend labelsubheading"
								style={{ paddingBottom: "10px" }}>
								Basic Details
								{btnShow === "true" || this.state.showBtn === "true" ? (
									<button
										class="btn  add-button"
										className={this.state.inputClass11}
										style={{
											width: "68px",
											height: "29px",
											fontSize: "15px",
											float: "right",
											margin: "auto",	//Chaitanya-4340-Prod issue-Advertiser Login-Edit-Quick upload-alignment issue
											paddingTop: "3px",
										}}
										form="createcampaign"
										type="submit">
										Save{" "}
									</button>
								) : (
									""
								)}
								<br />
							</legend>
							<MDBRow>
								{this.state.getQuickCampDetails.map((getQuickCampDetails) => (
									<MDBCol lg="3" md="3" sm="3" className="mb-3">
										<label id="label">Campaign ID</label>
										<input
											style={{ width: "180px" }}
											defaultValue={getQuickCampDetails.advCustCampID}
											onChange={this.handleChange}
											type="text"
											id="advCustCampID"
											className="form-control"
											name="advCustCampID"
										/>
									</MDBCol>
								))}

								{this.state.getQuickCampDetails.map((getQuickCampDetails) => (
									<MDBCol lg="3" md="3" sm="3" className="mb-3">
										<label id="label">
											Campaign Name <span style={asterisk}>*</span>
										</label>
										<input
											defaultValue={getQuickCampDetails.advCampaignName}
											onChange={this.handleChange}
											type="text"
											style={{ width: "180px" }}
											id="advCampaignName"
											className="form-control"
											name="advCampaignName"
											required
										/>
									</MDBCol>
								))}

								<MDBCol lg="3" md="3" sm="3" className="mb-3">
									{this.state.getQuickCampDetails.map((getQuickCampDetails) => {
										return (
											<div>
												<label id="label">
													Start Date <span style={asterisk}>*</span>
													<span style={{ color: "#337ab7", fontSize: "12px" }}>
														(dd-mm-yyyy){" "}
													</span>
												</label>
												<input
													type="date"
													id="startDate"
													name="advStartDate"
													min=""
													required
													pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
													defaultValue={getQuickCampDetails.advStartDate}
													onChange={this.handleChange}
													className="form-control"
												/>
												{/* kiran-4748-removing console warning-remove duplicate attribute */}
											</div>
										);
									})}
								</MDBCol>

								{this.state.getQuickCampDetails.map((getQuickCampDetails) => (
									<MDBCol lg="3" md="3" sm="3" className="mb-3">
										<label id="label">
											End Date <span style={asterisk}>*</span>
											<span style={{ color: "#337ab7", fontSize: "12px" }}>
												(dd-mm-yyyy){" "}
											</span>
										</label>
										<input
											type="date"
											id="endDate"
											name="advEndDate"
											min=""
											defaultValue={getQuickCampDetails.advEndDate}
											onChange={this.handleChange}
											className="form-control"
											required
										/>
									</MDBCol>
								))}
							</MDBRow>
							{this.state.getQuickCampDetails.map((getQuickCampDetails) => (
								<MDBRow>
									<MDBCol lg="3" md="3" sm="3" className="mb-3">
										<label id="label">
											Lead Allocation <span style={asterisk}>*</span>
										</label>
										<input
											type="textbox"
											class="form-control"
											style={{ width: "180px" }}
											id="advLeadAllocation"
											name="advLeadAllocation"
											defaultValue={getQuickCampDetails.advLeadAllocation}
											value={this.state.advLeadAllocation}
											onChange={this.leadAllocationHandleChangeBack}
											required
										/>
									</MDBCol>
									<MDBCol lg="3" md="3" sm="3" className="mb-3">
										<label id="label">
											Campaign Budget <span style={asterisk}>*</span>
										</label>
										<input
											defaultValue={getQuickCampDetails.advBudget}
											onChange={this.budgetHandleChangeBack}
											value={this.state.advBudget}
											type="text"
											id="advBudget"
											class="form-control"
											name="advBudget"
											required
											style={{ width: "180px" }}
										/>
									</MDBCol>

									<MDBCol lg="3" md="3" sm="3" className="mb-3">
										<label id="label">
											CPL <span style={asterisk}>*</span>
										</label>
										<input
											defaultValue={getQuickCampDetails.advCPL}
											value={this.state.advCPL}
											onChange={this.cplHandleChangeBack}
											type="text"
											id="advCPL"
											class="form-control"
											name="advCPL"
											required
											style={{ width: "180px" }}
										/>
										<br />
									</MDBCol>
									<MDBCol lg="3" md="3" sm="3" className="mb-3">
										<label id="label">
											Campaign Currency<span style={asterisk}>*</span>
										</label>
										<select
											id="currency"
											style={{
												border: "1px solid #ccc",
												width: "180px",
												boxShadow: "inset 0 1px 1px rgba(0,0,0,.075)",
												height: "calc(2.25rem + 12px)",
											}}
											name="advCurrency"
											defaultValue={getQuickCampDetails.advCurrency}
											onChange={this.handleChange}
											class="form-control"
											required>
											<option value="USD" selected>
												USD - US Dollar
											</option>
											<option value="EUR">EUR - Euro</option>
											<option value="GBP">GBP - British Pound</option>
											<option value="INR">INR - Indian Rupee</option>
											<option value="AUD">AUD - Australian Dollar</option>
											<option value="CAD">CAD - Canadian Dollar</option>
											<option value="SGD">SGD - Singapore Dollar</option>
											<option value="CHF">CHF - Swiss Franc</option>
											<option value="MYR">MYR - Malaysian Ringgit</option>
											<option value="JPY">JPY - Japanese Yen</option>
											<option value="CNY">CNY - Chinese Yuan Renminbi</option>
										</select>
										<br />
									</MDBCol>
								</MDBRow>
							))}{" "}
							{this.state.getQuickCampDetails.map((getQuickCampDetails) => (
								<MDBRow>
									<MDBCol lg="3" md="3" sm="3" className="mb-3">
										<label id="label">Campaign Type</label>
										<select
											defaultValue={getQuickCampDetails.advABM}
											onChange={this.handleChange}
											id="abm"
											style={{ width: "180px" }}
											className="form-control"
											name="advABM">
											<option value="Open" selected="Open">
												OPEN
											</option>
											<option value="Abm">ABM</option>
										</select>
									</MDBCol>

									<MDBCol lg="3" md="3" sm="3" className="mb-3">
										<label id="label">Created By</label>
										<input
											disabled
											class="form-control"
											id="createdBy"
											name="createdBy"
											defaultvalue={user.name}
											value={this.state.createdBy}
											onChange={this.handleChange}
											readonly
										/>
									</MDBCol>

									<MDBCol lg="3" md="3" sm="3" className="mb-3">
										<label id="label">Additional Comments</label>
										<textarea
											id="advAdditionalComment"
											name="advAdditionalComment"
											class="form-control"
											style={{ width: "240px", height: "80px", resize: "none" }}
											defaultValue={unescape(
												getQuickCampDetails.advAdditionalComment
											)}
											onChange={this.handleChange}
										/>
									</MDBCol>
									
								</MDBRow>
							))}
							<legend className="legend labelsubheading">
								Supporting Document
							</legend>
							<MDBRow>
							{/* kiran-4748-removing console warning-changed == to === */}
								{this.state.showQuickFileUpload === "true" ? (
									<MDBCol lg="6" className="mb-6">
										<label id="label">
											Upload File<span style={asterisk}>*</span>
										</label>
										<br />
										<label class="btn chooseFile">
											{" "}
											Choose File
											<input
												type="file"
												style={{ color: "transparent", display: "none" }}
												id="suppression"
												name="suppressionFiles"
												multiple="multiple"
												onChange={this.handleQuickFileUpload}
											/>
										</label>
										<div id="nosuppressionList">
											{quickFileLength} Files Selected
										</div>
										<div id="suppressionList" className="word-wrap"></div>

										<div
											id="suppresionMessage"
											style={{ color: "green" }}></div>
										<br />
									</MDBCol>
								) : (
									""
								)}
							</MDBRow>
							<br />
						</div>
					</div>
				</form>

				<Footer />
			</div>
		);
	}
}

EditQuickUpload.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(EditQuickUpload)
);
