/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*@author Chaitanya Shinde
*@fileName : leadReportClient.js
@Creation Date:12-10-2022
*Desc: UI for Agency View- By Advertiser - Advertiser Lead Tracking Report for story 3413
*/
import React from "react";
// import { Tabs, Tab } from 'react-bootstrap';
import Navigation from "../layouts/navPage";
// import * as $ from "jquery";
import Footer from "../layouts/footer";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import './agencyMyReports.css';
// import { css } from "emotion";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
// import styled from "styled-components";
// import { Checkbox } from 'antd';
// import { truncate } from "fs";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";      //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
import moment from 'moment';
import { saveAs } from "file-saver";
// import { LeadReviewLoader } from "../loaders/LeadReviewLoader";

const xlsx = require("xlsx");


const Swal = require('sweetalert2');
// const CheckboxGroup = Checkbox.Group;
var chunk1 = []; //4639-Nilesh- stored data in batches



// const tableCSS = css({
//     backgroundColor: "white",
//     fontFamily: "sans-serif",
//     borderStyle: "bold",
//     "& thead  > tr": {
//         backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
//         color: "white",
//         fontSize: "12px",
//         textAlign: "center !important",
//         fontWeight: "500",
//     },
//     "& thead > tr >th": {
//         textAlign: "center !important",
//         color: "white",
//     },
// });
// const queryString = require('query-string');

// const PickyStyle = styled(Picky)
//     `
	
// background-color: #bd141480 !important;
// border-radius: '4px !important'

// #-list.picky__dropdown{
//   overflow:'hidden !important';
// }
// .picky__placeholder {
//     color: #000000 !important;
// }
// .picky {
//     width:148px !important;
//     height:32px !important;
//   }
//  `
//     ;


//sunita-task-3579- added code to change the width
let z = 8000; //not resolve giving issue
let a = 60;
if (a === 60) {
    z = 120 * a
}

//Chaitanya - 4470 - added array  for lead status .
const leadStatusOptions =
    [
        { id: "Delivered", name: "Delivered" },
        { id: "Review-Pending", name: "Review-Pending" },
        { id: "Accepted", name: "Accepted" },
        { id: "Rejected", name: "Rejected" }
    ];

class leadReportClient extends React.Component {
    constructor() {
        super();
        this.state = {
            tableState: { size: "small" },//sunita-task-3413-used for table design
            agencyAdvertiserCampaignInfo: [],//sunita-task-3413-declared for table data
            agencyMyReportsDataNew1: [],//sunita-task-3413-declare to set table data in this new array while filtering the data
            agencyMyReportsData: [],//sunita-task-3413-array declared for table data
            advertiserList: [],//sunita-task-3413-array declared for advertiser name
            advertiserList1: [],//sunita-task-3413-array declared to filter advertiser name
            agencyID: "",// sunita-task-3413-declare to get the id from userLogin
            advertiserID: "Select Advertiser",//sunita-task-3413-used to display bydefault non-selected .
            campaignStatus: [],//sunita-task-3413-used to display bydefault non-selected .
            timeLine: "Select Timeline",//sunita-task-3413-used to display bydefault non-selected .
            label: "",//sunita-task-3413-used to filter deadline as per label .
            reportKey: "",//sunita-task-3413-used key to put conditions when user came by clicking on report name .
            advertiserName: "",//sunita-task-3413-to target advertiser name .
            type: "Advertiser Lead Tracking",//sunita-task-3413-given by default type
            reportName: "",//sunita-task-3413-used for report name .          
            loader: "",//sunita-task-3413-used for loader.
            campaignLoader: "",//sunita-3413-used for campaign loader.
            generateBtnFlag: false,//sunita-task-3413-used to enable - disable generate button.
            advID: '',//sunita-task-3413-used to access advid on this page came from myreport page.
            userType: "Advertiser",//sunita-task-3413-by default usertype
            pageSize: 100,//sunita-task-3413-added for page size.
            handleOnkey: 2,//sunita-task-3413-added for going back to my report page
            campID: "Select Campaign",//sunita-task-3413-declare campID for campaign dropdown .
            getCampaignListByADV: [],//sunita-task-3413-declare array  for getting the campaign list.
            advLeadDetails: [],//sunita-task-3413-declare array to iterate table data
            token: "",//sunita-task-3413-used token for authentication
            deliveryDetails: [],//sunita-task-3413-declare array to store the delivery format details
            leadStatus: [], //sunita-task-3413-array for leadstatus values
            campaignName: "",//sunita-task-3413-declare variable for campaign.
            campaignStatusArray: [],//sunita-task-3413-declare array for cmapaign status array .
            leadIndeterminate: true,//sunita-task-3413-used flag to setState checkbox value.
            leadCheckAll: false,//sunita-task-3413-used flag to check checkbox.
            campaignLeadStatusArray: [],//sunita-task-3413-declare array for  lead status array 
            checkedColumnKeys: [],//sunita-task-3413-declare array for checkbox value
            drpdisabled_report: "",//sunita-task-3413-declare for disable checkbox. 
            customQuestionCount: 0,//sunita-task-3413-declare variable for  customQuestionCount.
            multiTouch: "No",//sunita-task-3413-variable for multitouch.
            deadline: "", //sunita-task-3413-declare variable for timeline
            startDate: "",
            endDate: "",
            totalCount: 0,
            loader_progress: "", //4639-Nilesh Add loader
            splitedArray: [], //4639-Nielseh add array for batches data

        }//end of state

        //binded all below mentioned handle changes as per requirement
        this.onAdvertiserNameHandleChange = this.onAdvertiserNameHandleChange.bind(this);
        //this.onCampaignStatusHandleChange = this.onCampaignStatusHandleChange.bind(this);
        //this.ondeadlineHandleChange = this.ondeadlineHandleChange.bind(this);
        this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
        this.searchHandleChange = this.searchHandleChange.bind(this);   //4470-Search button
        this.handledownloadLead = this.handledownloadLead.bind(this);   //4470-download button
        this.onLeadStatusHandleChange = this.onLeadStatusHandleChange.bind(this);
        //this.onCampaignHandleChange = this.onCampaignHandleChange.bind(this);
        //this.onCampaignDetailsHandleChange = this.onCampaignDetailsHandleChange.bind(this);
        this.onCampaignLeadDetailsHandleChange = this.onCampaignLeadDetailsHandleChange.bind(this);
        //this.handleFileDownload = this.handleFileDownload.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.loadingRef = React.createRef();//4470-Loader
        this.closeLoadingRef = React.createRef();

    }// end of constrcutor


    /**
    * @author : Chaitanya Shinde - task 4470
    * @description : handlechange for Date range .
    */

    handleChange(e) {

        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let getCampaignDetailByADV = this.state.getCampaignDetailByADV;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;

        if ((getCampaignDetailByADV && getCampaignDetailByADV !== "Select Client")
            && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0)
            && (startDate !== "") && (endDate !== "")) {
            this.setState({ totalCount: 0 })
        }

        if (e.target.name.toString() === "startDate") {
            let date = e.target.value;
            date = moment().format('mm/dd/yyyy');
            let dateArray = date.split('/');
            if (dateArray.length === 0) {
                dateArray = date.split('-');
            }
            this.setState({
                startDate: e.target.value
            })
        }
        
        else if (e.target.name.toString() === "endDate") {
            this.setState({
                endDate: e.target.value
            })

        }
    }

    leadOnChange = checkedColumnKeys => {

        if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch1")) {
            checkedColumnKeys.push("assetTimestampTouch1");
        } else {
            let index = checkedColumnKeys.indexOf("assetTimestampTouch1");
            if (index > -1) {
                checkedColumnKeys.splice(index, 1);
            }
        }

        if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch2")) {
            checkedColumnKeys.push("assetTimestampTouch2");
        } else {
            let index = checkedColumnKeys.indexOf("assetTimestampTouch2");
            if (index > -1) {
                checkedColumnKeys.splice(index, 1);
            }
        }

        if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch3")) {
            checkedColumnKeys.push("assetTimestampTouch3");
        } else {
            let index = checkedColumnKeys.indexOf("assetTimestampTouch3");
            if (index > -1) {
                checkedColumnKeys.splice(index, 1);
            }
        }


        this.setState({
            checkedColumnKeys,
            leadIndeterminate: !!checkedColumnKeys.length && checkedColumnKeys.length < this.state.checkBoxObjArray.length,
            leadCheckAll: checkedColumnKeys.length === this.state.checkBoxObjArray.length,
        });

    };


    /**
    * @author : Sunita Landge - task 3413
    * @description : handlechange for backbutton to navigate on same page from where user came .
    */
    backButtonHandleChange(e) {
        e.preventDefault();
        this.props.history.push("/newdashboard")
    }//end of backButtonHandleChange


    /**
    * @author : Sunita Landge - task 3406
    * @description : handlechange to target selected advertiser .
    */

    onAdvertiserNameHandleChange(e) {
        var advertiserID = e.target.value;
        let campaignStatusArray = this.state.campaignStatusArray;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let getCampaignDetailByADV = this.state.getCampaignDetailByADV;

        if ((getCampaignDetailByADV && getCampaignDetailByADV !== "Select Client") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0)
            && (startDate !== "") && (endDate !== "")) {
            this.setState({ totalCount: 0 })
        }

        let campID = ""; //sunita-task-3582 -  added code for displaying the particular adv data in table.
        this.setState({ advertiserID, campID: "", advLeadDetails: [] }) //sunita-task-3582-added code for showing blank data if data is not present in table.

        //sunita-task-3575-added below condition for - if same campaign having by number of advertiser So data should load in table.
        if (campaignStatusArray.length === 0 || campaignStatusArray == []) {//not resolved giving issue
            this.setState({ campID: "Select Campaign", generateBtnFlag: false, advLeadDetails: [] })
        }
        else if ((advertiserID && advertiserID !== "Select Advertiser") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campID && campID !== "Select Campaign") && (campaignStatusArray && campaignStatusArray.length > 0)) {
            this.onCampaignLeadDetailsHandleChange(advertiserID, campaignLeadStatusArray, campID);

        }
        else {
            this.setState({ generateBtnFlag: false, advLeadDetails: [] })
        }

        if ((advertiserID && advertiserID !== "Select Advertiser")) {
            let data = {
                advertiserID: advertiserID,
            }
            //4470
            fetch("/advertiserReportEngine/getCampaignDetailsByClient", {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then(getCampaignDetailByADV => {
                    this.setState({ getCampaignDetailByADV: getCampaignDetailByADV, campaignLoader: "" })
                    //alert("getCampaignDetailByADV =>"+JSON.stringify(getCampaignDetailByADV))

                }).catch(function (err) { console.log(err) });
        }

    }//end of onAdvertiserNameHandleChange

    /**
    * @author : Sunita Landge - task 3413
    * @description : handlechange to target selected leadstatus .
    */

    onLeadStatusHandleChange(leadStatus) {
        let campaignLeadStatusArray = [];
        let checkAccepted = leadStatus.some(o => o.name === "Delivered")
        if (checkAccepted) { campaignLeadStatusArray.push("Delivered") }

        let checkRejected = leadStatus.some(o => o.name === "Review-Pending")
        if (checkRejected) { campaignLeadStatusArray.push("Review-Pending") }

        let checkInQA = leadStatus.some(o => o.name === "Accepted")
        if (checkInQA) { campaignLeadStatusArray.push("Accepted") }

        let checkInDiQA = leadStatus.some(o => o.name === "Rejected")
        if (checkInDiQA) { campaignLeadStatusArray.push("Rejected") }

        let advertiserID = this.state.advertiserID;
        var campID = this.state.campID;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let getCampaignDetailByADV = this.state.getCampaignDetailByADV;

        if ((getCampaignDetailByADV && getCampaignDetailByADV !== "Select Client") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0)
            && (startDate !== "") && (endDate !== "")) {
            this.setState({ totalCount: 0 })
        }

        this.setState({ leadStatus, campaignLeadStatusArray })
        if ((advertiserID && advertiserID !== "Select Advertiser") && (campID && campID !== "Select Campaign") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0)) {

            this.onCampaignLeadDetailsHandleChange(advertiserID, campaignLeadStatusArray, campID)//sunita-task-3413-function called to load the table data after selecting values from all filters .

        }
        else {
            this.setState({ generateBtnFlag: false, advLeadDetails: [] })
        }

    }


    /**
    * @author : Sunita Landge - task 3413
    * @description : handleChange for table data after selecting values from all filters .
    */

    onCampaignLeadDetailsHandleChange(advertiserID, campaignLeadStatusArray, campID, timeLine) {

        // let advcountDetails = [{ "advertiserID": advertiserID, "advertiserName": "", "deliveryLabel": "", "totalAllocation": 0, "totalAccepted": 0, "deliveredLead": 0, "rejectedLead": 0, "qaReviewLead": 0, "balanceLead": 0 }]
        this.setState({ loader: 'lead_loader', generateBtnFlag: false, advLeadDetails: [] });
        // alert("advcountdetails=>" + JSON.stringify(advcountDetails));

        const { user } = this.props.auth;
        const agencyID = user.id;
        let campaignStatusArray = this.state.campaignStatusArray;
        // let campID= this.state.campID;
        // alert("campID=>" + campID);
        this.setState({ agencyID })

        if (this.state.reportKey === "report") {

            if ((advertiserID && advertiserID !== "Select Advertiser") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campID && campID !== "Select Campaign") && (timeLine && timeLine !== "Select Timeline")) {

                let data = {
                    campID: campID,
                    //agencyID: agencyID,                                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
                    leadStatus: campaignLeadStatusArray,
                    timeLine: timeLine,
                    advertiserID: advertiserID,
                    campaignStatus: campaignStatusArray,

                }

                fetch("/advertiserReportEngine/advLeadDetails", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'

                    },
                    body: JSON.stringify(data)
                }).then(res => res.json())
                    .then(advLeadDetails => {
                        //  let advcountDetails = advLeadDetails.countDetails;
                        let deliveryDetails = advLeadDetails.deliveryDetails;
                        let checkedColumnKeys = [], multiTouch = "No";
                        if (deliveryDetails.length > 0) { multiTouch = deliveryDetails[0].multiTouch };
                        if (advLeadDetails.result === []) { //not resolve giving issue
                            this.setState({ loader: "" });
                        }

                        for (const [key, value] of Object.entries(deliveryDetails[0])) {
                            if (value === "Yes" || value === "yes") {
                                checkedColumnKeys.push(key)

                            }
                        }

                        let customQuestionCount = advLeadDetails.customQuestionCount;
                        if (customQuestionCount > 0) {
                            checkedColumnKeys.push("Custom Question");
                        }

                        this.setDataTableColumn(checkedColumnKeys, multiTouch, customQuestionCount)//sunita-task-3413-function called for table columns

                        this.setState({ advLeadDetails: advLeadDetails.result, deliveryDetails: advLeadDetails.deliveryDetails, loader: "", checkedColumnKeys, multiTouch, customQuestionCount })
                        if (this.state.advLeadDetails.length > 0) {
                            this.setState({ generateBtnFlag: true })


                        }
                        else {

                            this.setState({ generateBtnFlag: false })

                        }

                    }).catch(function (err) { console.log(err) });

            }
        }
        else
            if ((advertiserID && advertiserID !== "Select Advertiser") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0) && (campaignStatusArray && campaignStatusArray.length > 0) && (campID && campID !== "Select Campaign") && (timeLine && timeLine !== "Select Timeline")) {


                let data = {
                    campID: campID,                                   //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
                    leadStatus: campaignLeadStatusArray,
                    timeLine: timeLine,
                    advertiserID: advertiserID,
                    campaignStatus: campaignStatusArray,
                }

                fetch("/advertiserReportEngine/advLeadDetails", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'

                    },
                    body: JSON.stringify(data)
                }).then(res => res.json())
                    .then(advLeadDetails => {

                        this.setState({ loader: "" }); //sunita-task-3573-added code for displaying loader blank when data not available.
                        ///let advcountDetails = advLeadDetails.countDetails;
                        let deliveryDetails = advLeadDetails.deliveryDetails;


                        let checkedColumnKeys = [], multiTouch = "No";
                        if (deliveryDetails.length > 0) { multiTouch = deliveryDetails[0].multiTouch };

                        for (const [key, value] of Object.entries(deliveryDetails[0])) {
                            if (value === "Yes" || value === "yes") {
                                checkedColumnKeys.push(key)
                            }
                        }

                        let customQuestionCount = advLeadDetails.customQuestionCount;

                        if (customQuestionCount > 0) {
                            checkedColumnKeys.push("Custom Question");
                        }
                        this.setDataTableColumn(checkedColumnKeys, multiTouch, customQuestionCount)//sunita-task-3413-function called for table columns



                        this.setState({ advLeadDetails: advLeadDetails.result, deliveryDetails: advLeadDetails.deliveryDetails, checkedColumnKeys, multiTouch, customQuestionCount })


                        if (this.state.advLeadDetails.length > 0) {
                            this.setState({ generateBtnFlag: true })
                        }
                        else {
                            this.setState({ generateBtnFlag: false })

                        }



                    }).catch(function (err) { console.log(err) });

            }

    }

    /**
    * @author : Chaitanya Shinde - task 4470
    * @description : Search leads by client .
    */

    searchHandleChange(e) {

        // const { user } = this.props.auth;
        // const agencyID = user.id;
        let campID = this.state.getCampaignDetailByADV;
        // let campaignStatus = this.state.campaignStatusArray;
        let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
        let advertiserID = this.state.advertiserID;
        // let advLeadDetails = [...this.state.advLeadDetails];
        // let reportName = this.state.reportName;
        // let type = this.state.type;
        // let userType = this.state.userType;
        // var campaignName = this.state.campaignName;
        let leadStatus = this.state.campaignLeadStatusArray;
        let advertiserList = this.state.advertiserList;
        let advertiserName = "";
        for (let i = 0; i < advertiserList.length; i++) {
            if (advertiserList[i].advertiserID.toString() === advertiserID) { //not resolved giving issue
                advertiserName = advertiserList[i].advertiserName;
            }
        }

        let getCampaignDetailByADV = this.state.getCampaignDetailByADV;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;


        if (leadStatus) {
            leadStatus = leadStatus.toString();
        }


        if ((getCampaignDetailByADV && getCampaignDetailByADV !== "Select Client") && (campaignLeadStatusArray && campaignLeadStatusArray.length > 0)
            && (startDate !== "") && (endDate !== "")) {
            //this.loadingRef.current.click();
            this.setState({ loader_progress: "loader_campaign_list" }); // 4639-Nilesh
            let data = {
                advertiserID: advertiserID,
                advertiserName: advertiserName,
                startDate: startDate,
                endDate: endDate,
                leadStatus: leadStatus,
                campID: campID,
            }

            fetch("/advertiserReportEngine/clientLeadReportDetails", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
           
                .then((advLeadDetails) => {
                    this.setState({ loader_progress: "" }); //4639-Nilesh, stop loader
                    //this.closeLoadingRef.current.click();
                    if (advLeadDetails.result.length > 0) { 
                        this.setState({ clientLeadDetails: advLeadDetails.result }) 

                        let totalCount = this.state.clientLeadDetails.length; 
                        this.setState({ totalCount: totalCount });
                        let clientLeadDetails = this.state.clientLeadDetails;
                        const chunkSize = 30000; 
                        for (let i = 0; i < clientLeadDetails.length; i += chunkSize) {   //4639-Nilesh, splited 30k batches data 
                            var chunk = clientLeadDetails.slice(i, i + chunkSize);

                            chunk1.push(chunk)
                        }

                        this.setState({ splitedArray: chunk1 });

                        // this.setState({ clientLeadDetails: advLeadDetails.result})

                        // let totalCount = this.state.clientLeadDetails.length;
                        // this.setState({totalCount:totalCount});
                    }
                    else {
                        Swal.fire({
                            type: "warning",
                            title: "No records Found",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                            preConfirm: () => {  
                                window.location.reload()        //4639- Nilesh, add  preConfirm
                            }
                        });
                    }

                }).catch(function (err) { console.log(err) });
        }
        else {
            Swal.fire({
                type: "warning",
                title: "Please Select All Mandatory Fields",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                preConfirm: () => { 
                    window.location.reload()          //4639- Nilesh, add  preConfirm
                }
            });
        }
    }//end of searchHandleChange

    //Chaitanya - 4470
    handledownloadLead(e) {
        this.setState({ loader_progress: "loader_campaign_list" });// 4639-Nilesh, added loader
        let clientLeadDetails = this.state.clientLeadDetails;
        let advertiserID = this.state.advertiserID;
        let advertiserList = this.state.advertiserList;
        let advertiserName = this.state.advertiserName;

        for (let i = 0; i < advertiserList.length; i++) {
            if (advertiserList[i].advertiserID.toString()=== advertiserID) { //kiran-2834-added toString() and changed == to ===
                advertiserName = advertiserList[i].advertiserName;
            }
        }

        if (clientLeadDetails.length > 0) {
            var ws = xlsx.utils.json_to_sheet(clientLeadDetails);
            var wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, ws, "Lead");
            var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" });

            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (let i = 0; i !== s.length; ++i)
                    view[i] = s.charCodeAt(i) & 0xff;
                return buf;
            }
            this.setState({ loader_progress: "" }); // 4639-Nilesh, stop loader
            var fileName = advertiserID + "-" + advertiserName + "-Lead Report By Client.xlsx";
            saveAs(
                new Blob([s2ab(buf)], { type: "application/octet-stream" }),
                fileName
            );
        }

    }


    /**
* @author : Sunita Landge - task 3413
* @description : for disable screen if report key is present in url
*/
    componentWillMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push('/userLogin');
        }
        else {
            let reportKey, reportID, advertiserID, reportName, type;

            if (this.props.location.state !== undefined) {
                advertiserID = this.props.location.state.advID;
                reportKey = this.props.location.state.reportKey;
                reportID = this.props.location.state.reportID;
                // this.state.reportID = reportID;
                reportName = this.props.location.state.reportName;
                // this.state.reportName = reportName;
                this.setState({reportID:reportID , reportName:reportName})//kiran-2834-removing console warning-use setState
                type = this.props.location.state.type;
            }

            // const { isAuthenticated } = this.props.auth;//kiran-2834-removing console warning-no used
            const { user } = this.props.auth;
            const agencyID = user.id;
            this.setState({
                agencyID: agencyID,
                advertiserID: advertiserID,
                reportKey: reportKey,
                generateBtnFlag: false,
                reportName: reportName,
                type: type,
            })

            if (this.state.reportKey === "report") {
                this.setState({ drpdisabled_report: "drpdisable_report" })
            }
            else {
                this.setState({ drpdisabled_report: "" })
            }


            let data = {
                //agencyID: this.state.agencyID,                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
                advertiserID: advertiserID,
                reportID: reportID,
            }
            //Chaiaynya-task-4470-api fetched for getting Client list
            fetch("/advertiserReportEngine/clientList", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //authorization: token,
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then(advertiserList => {
                    this.setState({ advertiserList, advertiserList1: advertiserList })
                }
                ).catch(function (err) { console.log(err) });
        }

    }//end of componentWillMount

    /**
* @author : Sunita Landge - task 3413
* @description : handlechange for save and close button after check/uncheck checkbox .
*/

    handleSubmit = e => {

        this.setDataTableColumn(this.state.checkedColumnKeys, this.state.multiTouch, this.state.customQuestionCount)//sunita-task-3413-function called for table columns
    }

    /**
* @author : Chaitanya Shinde-task 4470
* @description :Render part to display complete screen with functionality as per given screen .
*/

    render() {
        const asterisk =
        {
            color: 'red',
        }

//kiran-2834-removing console warning-no used
        // function onChange(pagination, filters, sorter) {
        //     console.log("params", pagination, filters, sorter);
        // }

        return (
            <div>
                {/* 4470-Added Loader */}
                {/* <LeadReviewLoader
                    loadingRef={this.loadingRef}
                    closeLoadingRef={this.closeLoadingRef}
                    message="Please Wait. Report is generating..."
                    /> */}
                <Navigation />
                <div style={{ paddingBottom: '60px', paddingTop: '100px' }} class="container-fluid ">

                    <div class="row">

                        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        {/* kiran-2834-removing console warning-added "#/" */}
                            <a style={{ color: '#056eb8' }} href="#/" onClick={this.backButtonHandleChange} ><FaArrowAltCircleLeft size={32} title="Back to Dashboard" /></a>
                        </div>

                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" align="center">
                            <label id="labelDI">Lead Report By Client</label>
                        </div>

                    </div>
                    <div class="row" style={{ marginTop: "3%", marginLeft: "2%" }}>
                        {/* style={{ paddingTop: '15px',marginLeft: "1%", paddingLeft: '0px', paddingBottom: '11px' }} */}

                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6" id="AD_ALTD">
                            <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}>Client &nbsp;:<span style={asterisk}>*</span>&nbsp;&nbsp;&nbsp;</label><br />

                            <select value={this.state.advertiserID} name="AdvertiserName" style={{ width: '180px', height: '34px', border: '1px solid #cccccc', backgroundColor: "#FFFFFF", borderRadius: '3px', fontFamily: 'sans-serif', color: '#555555' }} onChange={this.onAdvertiserNameHandleChange}>
                                <option value={"Select Advertiser"} style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }}>Select Client</option>
                                {this.state.advertiserList.map((advertiserList, i) => (
                                    <option value={advertiserList.advertiserID} key={i} style={{ fontSize: "14px", fontFamily: "sans-serif", color: "#555555" }}>{advertiserList.advertiserID}-{advertiserList.advertiserName}</option>))}
                            </select>

                            {/* } */}
                        </div>


                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <label style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }} >Lead Status&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;</label><br />
                            {this.state.reportKey=== "report" ?
                                <div>
                                    <Picky
                                        value={this.state.leadStatus}
                                        options={leadStatusOptions}
                                        onChange={this.onLeadStatusHandleChange}
                                        open={false}
                                        valueKey="id"
                                        labelKey="name"
                                        // multiple={true}
                                        //includeSelectAll={true}
                                        //includeFilter={true}
                                        numberDisplayed={1}
                                        id="" readOnly
                                        isDisabled="true"
                                        //  name="leadStatus"
                                        className={"PickyStyle"}
                                        render={({ item, isSelected, labelKey }) => {
                                            return (
                                                <li>
                                                    <input
                                                        type="checkbox"
                                                        disabled
                                                        checked={isSelected} readOnly />
                                                    <span>{item[labelKey]}</span>
                                                </li>
                                            );
                                        }}
                                    />
                                </div>
                                :
                                <div
                                    style={{ width: '180px', border: " 1px solid #CCCCCC", borderRadius: " 3px", backgroundColor: "#FFFFFF", fontFamily: "sans-serif", color: " #555555" }}>
                                    <Picky
                                        value={this.state.leadStatus}
                                        options={leadStatusOptions}
                                        onChange={this.onLeadStatusHandleChange}
                                        open={false}
                                        valueKey="id"
                                        labelKey="name"
                                        multiple={true}
                                        //includeSelectAll={true}
                                        placeholder={'Select Status'}
                                        //includeFilter={true}
                                        dropdownHeight={200}
                                        numberDisplayed={0}
                                        id="leadStatus"
                                        name="leadStatus"
                                        className={"PickyStyle"}
                                    />
                                </div>
                            }
                        </div>

                    </div>
                    {/* 4639-Nilesh Add loader     */}
                    <div align="center" id={this.state.loader_progress}></div>
                    <div class="row" style={{ marginTop: "3%", marginLeft: "2%" }} >
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6" >

                            <label id="label" style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}><b>Start date </b> <span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>
                            <input type="date" id="startDate" name="startDate" ref={(input) => { this.nameInput = input; }} min="" pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"

                                value={this.state.startDate}
                                className={this.state.inputClassSdate}
                                onChange={this.handleChange}
                                class="form-control" required />
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6" >

                            <label id="label" style={{ fontSize: '14px', color: '#14254A', fontFamily: 'sans-serif' }}><b>End date </b> <span style={asterisk}>*</span><span style={{ color: "#337ab7", fontSize: "12px" }}>(dd-mm-yyyy) </span></label>

                            <input type="date" id="endDate" name="endDate" ref={(input) => { this.nameInput = input; }} min="" pattern="[0-9]{2}-[0-9]{}-[0-9]{4}"
                                value={this.state.endDate}
                                onChange={this.handleChange}
                                //onClick={this.onCampaignLeadDetailsHandleChange}
                                className="form-control"
                                required />
                        </div>
                    </div>

                    <div class="row" style={{ marginTop: "3%", marginLeft: "2%" }}>
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6" >

                            <button class="btn add-button"
                                style={{ backgroundImage: "linear-gradient(to left, #007CC3,#193D8F)", color: " white", marginTop: "22px" }}
                                type="button" data-toggle="modal" data-target="#generateReport"
                                onClick={this.searchHandleChange} >
                                Search
                            </button>

                        </div>
                    </div>
                    {/* 4439-Nilesh, This report will download till 30000 records at a time massage showing */}
                    <div style={{ marginLeft: "32px" }}> {(this.state.totalCount >= 30000) ? (<b style={{ color: "red" }}>This report will download till 30000 records at a time.</b>) : ""}</div>
                    <div class="col-sm-8 col-md-8 col-lg-6">
                        {this.state.totalCount > 0 ? (
                            <div style={{ backgroundColor: 'white', marginLeft: '3%', marginTop: '2%', marginBottom: '30px', border: '1px solid #cccccc', borderRadius: '3px' }}>
                                <div style={{ paddingLeft: "25px", paddingTop: "20px", paddingBottom: '10px' }}>

                                    <div class="row">

                                        <div class="col-sm-2 col-md-8 col-lg-8">

                                            <label style={{ color: "#14254A", paddingTop: "1%", paddingLeft: "2%" }}>
                                                <span>Total Leads Count&nbsp;:&nbsp;</span>
                                                <span style={{ color: "#14254A" }}>
                                                    &nbsp;{this.state.totalCount}
                                                </span>
                                            </label>

                                        </div>
                                        {/* 4439-Nilesh, buttoun disabled condition */}
                                        {(this.state.totalCount >= 30000) ? (<div class="col-sm-12 col-md-4 col-lg-4">
                                            <button
                                                type="button" disabled
                                                class="btn add-button"
                                                style={{ float: "Right", marginRight: "40%" }}
                                                value={this.state.clientLeadDetails}
                                            //onClick={this.handledownloadLead}
                                            >
                                                Download
                                            </button>
                                        </div>) :
                                            (<div class="col-sm-12 col-md-4 col-lg-4">
                                                <button
                                                    type="button"
                                                    class="btn add-button"
                                                    style={{ float: "Right", marginRight: "40%" }}
                                                    value={this.state.clientLeadDetails}
                                                    onClick={this.handledownloadLead}>
                                                    Download
                                                </button>
                                            </div>)
                                        }

                                    </div>
                                </div>
                            </div>
                        ) : ("")}
                    </div>


                </div>
                <Footer />
            </div>
        )
    }
}

leadReportClient.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(leadReportClient));
