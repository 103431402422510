/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sandeep Dhawale
*@fileName :rfpCampaignSpecificationDetails.js
 *Desc: UI for rfp campaign specification Details
// Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

import React from "react";
import { Launcher } from "react-chat-window";
import { Widget, addResponseMessage } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import {
	Container,
	Button,
	lightColors,
	darkColors,
	Link,
} from "react-floating-action-button";
import {
	Drawer,
	Comment,
	Icon,
	Tooltip,
	Avatar,
	Input,
	List,
	Form,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import Moment from "react-moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { MDBBtn, CardHeader, CardTitle, CardBody } from "mdbreact";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3714-added default header

const queryString = require("query-string");
// import logo from './DI.png';
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author Sanobar  Golandaj
 * Campaign Specification
 @version 1.0
 */


var abmFile = [];
var suppressionFile = [];
var execlusionFile = [];
var otherFile = [];
var assetFile = [];
var countryArray = [];
var jobLevelArray = [];
var companyRevenueArray = [];
var jobFunctionArray = [];
var employeeSizeArray = [];
var customJobFunctionArray = [];
var customJobLevelArray = [];
var regionArray = [];
var greeting = "";
const { TextArea } = Input;

const CommentList = ({ comments }) => (
	<List
		dataSource={comments}
		header={`${comments.length} ${
			comments.length > 1 ? "messages" : "message"
		}`}
		itemLayout="horizontal"
		renderItem={(props) => <Comment {...props} />}
	/>
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
	<div>
		<Form.Item>
			{" "}
			{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(shivani) */}
			<TextArea
				rows={4}
				onChange={onChange}
				value={value}
				style={{ resize: "none", marginTop: "-35px" }}
			/>
		</Form.Item>
		<Form.Item>
			<button
				type="submit"
				class="btn add-button"
				id="cmd"
				loading={submitting}
				onClick={onSubmit}>
				Send Message
			</button>
		</Form.Item>
	</div>
);
class CampaignSpecificationDashboardDetails extends React.Component {
	constructor() {
		super();
		this.state = {
			pubColor: "",
			Broadcastcolor: "orange",
			newsuccess: "",
			fields: {},
			dynamic: [],
			campCount: [],
			assetListArray: [],
			linkListArray: [],
			gdprPublisher: [],
			// gdprNoPublisher:[],
			otherDetails: [],
			abmDetails: [],
			pendingCampaignCount: "",
			suppresionDetails: [],
			exclusionDetails: [],
			region: "",
			sumOfAllocatedLeads: [],
			assetLinkArray: [],
			campID: " ",
			campIDTemp: " ",
			parentCampID: "",
			reallocationID: "",
			PublisherID: "",
			pID: "",
			id: "",
			leadAllocation: 0,
			stateCopy: [
				{
					allocatedLead: "",
					cpl: "",
					campCPL: "",
				},
			],
			newDynamicArray: [],
			newGDPRPubArray: [],
			gdprStatus: "false",
			campaignName: "",
			requiredLeadPerAsset: "",
			multiTouch: "",
			btnStatus: "",
			// allocatedLead: "",
			pendingLead: 0,
			campaignVisibleTime: "",
			campAcceptReject: "",
			campaignDetailAllocates: [],
			campaignDetailAssign: [],
			campaignDetailID: [],
			liveCampaignList: [],
			onClickAllocatedLead: [],
			onClickAllocatedLeadPubList: [],
			assignDeliveredLead: [],
			errors: {},
			alertDisplay: "none",
			alertBorder: "",
			alertWidth: "",
			alertAlign: "",
			spanSpaceDisplay: "none",
			success: "",
			puberror: "",
			allocerrors: "",
			reerrors: "",
			dateerrors: "",
			agencyID: "",
			show: false,
			campaignBriefDetails: [],
			campaignBriefDeliveryDetails: [],
			campaignBriefCustomQuestionDetails: [],
			campaignBriefSupportingDocumentAssetDetails: [],
			campaignBriefSupportingDocumentAbmDetails: [],
			campaignBriefSupportingDocumentSuppresionDetails: [],
			campaignBriefSupportingDocumentExclusionDetails: [],
			campaignBriefSupportingDocumentOtherDetails: [],
			publisherCampCPL: [],
			mergedCampaignDetailAssign: [],
			mergedGdprPublisher: [],
			isSuppLink: false,
			messageList: [],
			visible: false,
			likes: 0,
			dislikes: 0,
			action: null,
			comments: [],
			submitting: false,
			value: "",
			publisherList: [],
			customizedColumns: [],
			deliveryURL: "",
			customQuestionURL: "",
			pacingCampaignDetails: [],
			pacingUnit: "None",
			abmArray: [],
			suppressionArray: [],
			exclusionArray: [],
			otherArray: [],
			countryWiseAllocationArray: [], //sunita-task-3645- added array for  countrywise lead allocation
			countrywiseLeadAllocation: "No", // sunita-task-3645-added variable for countrylead allocation is given or not.
			proposalID: "", //sunita-task-3645-added varibale as per requirement.
		};

		this.handleCampaignBriefDetails =
			this.handleCampaignBriefDetails.bind(this);
		this.handleFileDownload = this.handleFileDownload.bind(this);
		this.handleChangePublisherMessages =
			this.handleChangePublisherMessages.bind(this);
		this.broadCastMessage = this.broadCastMessage.bind(this);
	} //end of constructor

	broadCastMessage() {
		/**
		 * @author Narendra Phadke
		 * @param  Description fetch brodcast message history
		 * @return Description return all history
		 */

		//var parsed = queryString.parse(this.props.location.search);
		var campID = this.state.campID;
		const { user } = this.props.auth;
		let data = {
			campID: campID,
		}; // karan-task-3714-added default header
		var pID = undefined;
		//This API is for brodcast message history
		fetch("/notification/campaignSpecificationBrodcastMessageHistory", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((comments) => {
				this.setState({
					comments: comments,
					pID: pID,
					Broadcastcolor: "orange",
					pubColor: "",
				});
			})
			.catch(function (err) {
				console.log(err);
			});

		let data1 = {
			campID: campID,
		}; // karan-task-3714-added default header
		//This API is for publiher list
		fetch("/notification/agencyCampaignSpecificationPublisherList", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data1),
		})
			.then((res) => res.json())
			.then((publisherList) => {
				this.setState({
					publisherList: publisherList,
					campID: campID,
					Broadcastcolor: "orange",
					pubColor: "",
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	}
	// end of broadCastMessage

	/**
	 * @author Narendra Phadke
	 * @param  Description handle Publisher Messages
	 * @return Description return Get perticular publisher message
	 */
	handleChangePublisherMessages(e) {
		var campID = e.target.id;
		var pID = e.target.name;
		const { user } = this.props.auth;

		let data = {
			campID: campID,
			// pID: pID, //Supriya Gore, Task: 4100 - removed pID for VAPT changes
		}; // karan-task-3714-added default header
		// var parentCampID=e.target.getAttribute('parentCampID');
		// var reallocationID=e.target.getAttribute('reallocationID');
		//This API is for publiher message history
		fetch("/notification/agencyCampaignSpecificationOnePublisherMessages", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((comments) => {
				this.setState({
					comments: comments,
					visible: true,
					campID: campID,
					pID: pID,
					Broadcastcolor: "",
					pubColor: "orange",
				});
			})
			.catch(function (err) {
				console.log(err);
			});

		fetch(
			"/notification/agencySendMessageToPerticulaterPublisherStatusChanged",
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			}
		)
			.then((res) => res.json())
			.then((comments) => {
				this.setState({
					comments: comments,
					visible: true,
					campID: campID,
					pID: pID,
					Broadcastcolor: "",
					pubColor: "orange",
				});
			})
			.catch(function (err) {
				console.log(err);
			});

		let data1 = {
			campID: campID,
		}; // karan-task-3714-added default header
		//This API is for publiher list
		fetch("/notification/agencyCampaignSpecificationPublisherList", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data1),
		})
			.then((res) => res.json())
			.then((publisherList) => {
				this.setState({
					publisherList: publisherList,
					campID: campID,
					Broadcastcolor: "",
					pubColor: "orange",
				});
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	// end of handleChangePublisherMessages

	handleSubmit = () => {
		if (!this.state.value) {
			return;
		} else {
			//{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(shivani) */}
			/* @author Narendra Phadke
			 * @param  Description private  message
			 * @return Description send private message
			 */
			const { user } = this.props.auth;
			var campID = this.state.campID;
			let data = {
				campID: campID,
				// user: user, //Supriya Gore, Task: 4100 - removed user for VAPT changes
				message: this.state.value,
				fromRFP: "true",
			};
			fetch("notification/campaignSpecificationPublisherPrivateMessage", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((message) => {
					this.setState({submitting: true });//Somnath Task-3935, Remove Message
				})
				.catch(function (err) {
					console.log(err);
				});

			var uname = user.firstName + " " + user.lastName;

			var fnamefirst = user.firstName.slice(0, 1);
			var lnamefirst = user.lastName.slice(0, 1);

			var uavatar = fnamefirst + lnamefirst;

			setTimeout(() => {
				this.setState({
					submitting: false,
					value: "",
					comments: [
						{
							author: uname,
							avatar: uavatar,
							content: <p>{this.state.value}</p>,
							datetime: moment().calendar(),
						},
						...this.state.comments,
					],
				});
			}, 1000);
		}
	};

	handleChange = (e) => {
		this.setState({
			value: e.target.value,
		});
	};

	showDrawer = () => {
		/**
		 * @author Narendra Phadke
		 * @param  Description fetch brodcast message history
		 * @return Description return all history
		 */

		//var parsed = queryString.parse(this.props.location.search);
		var campID = this.state.campID;
		const { user } = this.props.auth;
		let data = {
			campID: campID,
			// user: user, //Supriya Gore, Task: 4100 - removed user for VAPT changes
			fromRFP: true,
		};
		//This API is for brodcast message history
		//Sonali=putting this in comment
		//campaignSpecificationBrodcastMessageHistory
		fetch("/notification/campaignSpecificationBrodcastMessageForPublisher", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((comments) => {
				this.setState({ comments: comments, visible: true });
				if(this.state.comments.length>0){
					this.state.comments.map((comments) => (
						<div>
							comments: [
							{
								((this.state.author = comments.author),
								(this.state.avatar = comments.avatar),
								(this.state.content = <p>comments.content</p>),
								(this.state.datetime = comments.datetime))
							}
							]
						</div>
					));
				}
			})
			.catch(function (err) {
				console.log(err);
			});
		//  this.setState({
		//    visible: true,
		//  });

		//This API is for change status
		//{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(shivani) */}
		fetch("/notification/PublisherSendMessageToAgecncyStatusChanged", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((comments) => {
				this.setState({ visible: true });
			})
			.catch(function (err) {
				console.log(err);
			});

		//  this.setState({
		//    visible: true,
		//  });
			
		
	};

	contactPublisher = () => {
		var campID = this.state.campID;
 //Supriya Gore, Task: 4100 - added url in history for VAPT changes
		this.props.history.push("/contactPublisher", {
			campID: campID
		});
		// window.location.href = "/contactPublisher?campID=" + campID;
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	handleNewUserMessage = (newMessage) => {
		console.log(`New message incomig! ${newMessage}`);
		// Now send the message throught the backend API
		//addResponseMessage(newMessage);
	};

	_onMessageWasSent(message) {
		this.setState({
			messageList: [...this.state.messageList, message],
		});
	}

	_sendMessage(text) {
		this.setState({
			messageList: [
				...this.state.messageList,
				{
					author: "them",
					type: "text",
					data: { text },
				},
			],
		});
	}
	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			 //Supriya Gore, Task: 4100 - added some changes regarding VAPT
			const { user } = this.props.auth;

			var parsed,campID,key;
			if(this.props.location.state!==undefined)
			{
			parsed = this.props.location.state;
			campID = parsed.proposalID;
			key = parsed.key;
		
			}
			else
			{
			campID = this.props.greeting;
			}
			var pID = user.id;
			
			// var proposalID = parsed.proposalID; //sunita-task-3645- added varibal for proposalid.
			//  alert("proposalID=>"+proposalID);
			// var key = parsed.key;
			// alert("In did 2 "+key)
			this.setState({ pID: pID, campID });
			
			/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(added key dashboardMsg to shoe drawer by default) */
			// if (key == "dashboardMsg") {
			// 	this.setState({ visible: true });
			// } else {
			// 	this.setState({ visible: false });
			// }
			//this.handleCampaignBriefDetails(campID);

			
			let data = {
				campID: campID,
				// user: user, //Supriya Gore, Task: 4100 - removed pID and user for VAPT changes
				// pID: pID,
				fromRFP: "true",
			};
			//This API is for campaign details from campaign table
			fetch("agencyDashboard/getRfpCampaignDetails?campID=" + campID)
				.then((res) => res.json())
				.then((campaignDetails) => {
					//  alert("campaignDetails=>"+JSON.stringify(campaignDetails));
					// to check the data presence
					//sunita-task-3645-added below array for storing whether country allocation is done or not
					let countrywiseLeadAllocation =
						campaignDetails[0].countrywiseLeadAllocation;
					//snehal-task-3509-Project's(DI) - Create RFP through XLS - FE
					this.setState(
						{
							campaignBriefDetails: campaignDetails,
							countrywiseLeadAllocation,
						},
						function () {
							if (this.state.campaignBriefDetails[0].biddingType == "Geowise") {
								let biddingType = "Geo Viz";
								this.setState({ biddingType });
							} else {
								this.setState({
									biddingType: this.state.campaignBriefDetails[0].biddingType,
								});
							}
						}
					);
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("requestForProposal/biddingDocInSpec?campID=" + campID)
				.then((res) => res.json())
				.then((biddingDocumentDetail) => {
					let abmArray = biddingDocumentDetail.filter(
						(abm) => abm.type == "ABM"
					);
					let suppressionArray = biddingDocumentDetail.filter(
						(supp) => supp.type == "Suppression"
					);
					let exclusionArray = biddingDocumentDetail.filter(
						(e) => e.type == "Exclusion"
					);
					let otherArray = biddingDocumentDetail.filter(
						(other) => other.type == "Other"
					);
					this.setState({
						abmArray,
						suppressionArray,
						exclusionArray,
						otherArray,
					});
				})
				.catch(function (err) {
					console.log(err);
				});
			//{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(shivani) */}
			//This API is for change status
			fetch("/notification/PublisherSendMessageToAgecncyStatusChanged", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((comments) => {
					this.setState({ comments: comments, visible: false });
				})
				.catch(function (err) {
					console.log(err);
				});

			//This API is for brodcast message history
			fetch("/notification/campaignSpecificationBrodcastMessageForPublisher", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((comments) => {
					this.setState({ comments: comments });
				})
				.catch(function (err) {
					console.log(err);
				});

			//sunita-task-3645-added below route for countrywise lead allocation.

			fetch(
				"requestForProposal/getCountryWiseLeadForRFP?proposalID=" + campID
			)
				.then((res) => res.json())
				.then((countryWiseAllocationArray) => {
					// alert("countryWiseAllocationArray=>"+JSON.stringify(countryWiseAllocationArray));

					this.setState(
						{ countryWiseAllocationArray: countryWiseAllocationArray },
						function () {
							//  if(this.state.campaignBriefDetails[0].biddingType=="Geowise")
							//  {
							//      let biddingType='Geo Viz';
							//      this.setState({biddingType})
							//  }
							//  else{
							//    this.setState({biddingType:this.state.campaignBriefDetails[0].biddingType})
							//  }
						}
					);
				})
				.catch(function (err) {
					console.log(err);
				});
			
		}
	} // end of compoenetDidmount

	/**
	 * @author Somnath Keswad
	 * @param  Description handle the Campaign Modal Details PDF
	 * @return Description return All steps campaign Details PDF
	 */
	handleFileDownload(e) {
		var campID = this.state.campID;
		var parentCampID = this.state.parentCampID;
		/**
		 * @author Narendra Phadke
		 * @param  Description handle the Campaign related Files
		 * @return Description return All The File Download in Zip
		 */

		var formData = new FormData();
		formData.append("campID", campID);
		const { isAuthenticated, user } = this.props.auth;
		var userID = user.id;
		//This api is used for dynamic pdf genration
		//snehal-task-3766-VAPT api call tokan
		fetch(
			"agency/downloadDynamicPDFCampaignDetailsforAgency?campID=" +
				campID +
				"&campName=" +
				this.state.campaignName
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.handlePDFFileShowDownload);

		fetch(
			"agency/downloadCampaignDetailsforAgency?campID=" +
				campID +
				"&campName=" +
				this.state.campaignName
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.handleFileShowDownload);
	} /** End of handle File Download */
	handleFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		let zipFileDownloadName =
			this.state.parentCampID + "-" + this.state.campaignName + ".zip";

		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/zip",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the Campaign Specification Dynamic PDF Details
	 * @return Description return pdf file campaign details
	 */
	handlePDFFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		let zipFileDownloadName =
			this.state.parentCampID +
			"-" +
			this.state.campaignName +
			"-CampaignSpecification.pdf";
		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/pdf",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the Campaign Modal Details
	 * @return Description return All steps campaign Details
	 */
	handleCampaignBriefDetails(campID1) {
		var campID = campID1;

		//  var parentCampID=e.target.getAttribute('parentCampID');
		//  var reallocationID=e.target.getAttribute('reallocationID');
		// this.setState({parentCampID,reallocationID});
		this.setState({ campID: campID });

		//This API is for campaign details from campaign table
		fetch("agencyDashboard/getRfpCampaignDetails?campID=" + campID)
			.then((res) => res.json())
			.then((campaignDetails) => {
				this.setState({ campaignDetails: campaignDetails });
			})
			.catch(function (err) {
				console.log(err);
			});
	} // end of handleCampaignBriefDetails

	/**
	 * @author Somnath Keswad
	 * @param  Description  get multiple Link of Asset
	 * @return Description return open Multiple Link when i click on button in campSpec
	 */
	openAllLink() {
		var length = this.state.assetLinkArray.length;
		for (var i = 0; i < length; i++) {
			window.open(this.state.assetLinkArray[i].assetLink);
		}
	}
	/**
	 * @author Somnath Keswad
	 * @param  Description  get multiple Link of Suppression
	 * @return Description return open Multiple Link when i click on button in campSpec
	 */
	openAllSuppLink() {
		var supp = this.state.campaignBriefSupportingDocumentSuppresionDetails;
		var suppData = supp.filter(function (s) {
			return !s.assetLink == "";
		});
		for (var i = 0; i < suppData.length; i++) {
			window.open(suppData[i].assetLink);
		}
	}
	render() {
		const { comments, submitting, value } = this.state;

		return (
			<div>
				{/* Hello Campaign Specification {this.props.greeting} */}
				{/* <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> */}
				{/* <div style={{ border: "white" }}> */}
				{/* <div style={{ fontSize:"large", color :"#056eb8" ,fontWeight :"bold"}}>Basic Details : </div> */}
				{/* <div class="card" style={{backgroundColor:"#f8f8f8",     borderColor: "#707070", borderWidth: "0.3px"}}>
				 */}
				{/* <CardHeader >Basic Details</CardHeader> */}
				{/* /**
				 * @author Narendra Phadke
				 * @param  Description Display borderCampaign Details on Modal
				 * @return Description return Campaign Details
				 */}
				{/* <CardBody>
                      {this.state.campaignBriefDetails.map(
                        campaignBriefDetails => (
                         <div class="row">
                          <div class="col-lg-12">
                            <table>
                              <tr> 
                                <td style={{width:'170px'}}><label id="label" >&nbsp;Campaign Name</label></td>
                                <td >:&nbsp;{campaignBriefDetails.campaignName}</td>
                              </tr>
                            </table>
                          </div>
                          </div>
                              )
                              )}
                             
          {this.state.campaignBriefDetails.map(
                        campaignBriefDetails => (
                       
                          <div class="row">
                          <div class="col-lg-4">
                            <table>
                              <tr>
                                <td style={{width:'170px'}}><label id="label">&nbsp;Client Name</label></td>
                                <td><span>:&nbsp;{campaignBriefDetails.clientName}</span></td>
                                </tr>
                        
                                <tr>
                                <td style={{width:'170px'}}><label id="label">&nbsp;Lead Allocation</label></td>
                                <td><span>:&nbsp;{campaignBriefDetails.leadAllocation}</span></td>
                              </tr>
                               
                              <tr>
                                <td style={{width:'170px'}}><label id="label">&nbsp;Timezone</label></td>
                                <td><span>:&nbsp;{campaignBriefDetails.timezone}</span></td>
                              </tr>
                              <tr>
                                <td style={{width:'170px'}}><label id="label">&nbsp;Pacing Unit </label></td>
                                <td><span>:&nbsp;{this.state.pacingUnit}</span></td>
                              </tr>

                               
                            </table>
                          </div>

                          <div class="col-lg-4">
                            <table>
                              <tr>
                                <td style={{width:'160px'}}><label id="label">Agency Campaign ID</label></td>
                                <td><span>:&nbsp;{campaignBriefDetails.clientCampID}</span></td>
                              </tr>

                              <tr>
                                <td style={{width:'160px'}}><label id="label"> Start Date</label></td>
                                <td><span>:&nbsp;{campaignBriefDetails.startDate}</span></td>
                              </tr>

                              <tr>
                                <td style={{width:'160px'}}><label id="label">First Lead Delivery Date</label></td>
                                <td><span>:&nbsp;{campaignBriefDetails.firstLeadDeliveryDate}</span></td>
                              </tr>
                             
                              <tr>
                                <td style={{width:'160px'}}><label id="label">Lead Interaction Days</label></td>
                                <td><span>:&nbsp;{campaignBriefDetails.leadInteractionDays==""||campaignBriefDetails.leadInteractionDays==undefined?0:campaignBriefDetails.leadInteractionDays}</span></td>
                              </tr>

                            </table>
                          </div>

                          <div class="col-lg-4">
                            <table>
                             
                            <tr>
                                <td style={{width:'160px'}}><label id="label">Campaign Status</label></td>
                                <td><span>:&nbsp;{campaignBriefDetails.campaignStatus}</span></td>
                                </tr>

                                <tr>
                                <td style={{width:'160px'}}><label id="label">End Date</label></td>
                                <td><span>:&nbsp;{campaignBriefDetails.endDate}</span></td>
                                </tr>

                             
                                <tr>
                                <td style={{width:'160px'}}><label id="label">No Of Lead Per Domain</label></td>
                                <td><span>:&nbsp;{campaignBriefDetails.noOfLeadPerDomain}</span></td>
                                </tr>

                            </table>
                          </div>
                          </div>
                        )
                      )} */}
				{/* <div class='row'>
                      <div class="col-lg-12">
                       
                   
                      <label style={{color:'#000080',fontWeight:'400'}}>&nbsp;Pacing Unit
                      <span style={{color:"rgb(0, 0, 0, 0.65)"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{this.state.pacingUnit}</span></label>
                        
                      </div>
                      </div> */}
				{/* <br/>
{this.state.pacingCampaignDetails.length>0?
                                <div >
                                    <table class="table table-bordered">
                                      <thead >
                                        <tr class="info">
                                          <th  style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Pacing Month</th>
                                          <th  style={{color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Pacing Percentage</th>
                                          <th  style={{color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Pacing Lead Count</th>
                                          <th style={{color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Pacing End Date</th>
                                          <th style={{color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Pacing carry forward date</th>
                                          </tr></thead>

<tbody>
{this.state.pacingCampaignDetails.map((pacingCampaignDetails,i)=>{return(
  <tr>
     <td><span>{pacingCampaignDetails.pacingMonth}</span></td>
     <td><span>{pacingCampaignDetails.pacingPercentage}</span></td>
     <td><span>{pacingCampaignDetails.pacingLeadCount}</span></td>
     <td><span>{pacingCampaignDetails.pacingEndDate}</span></td>
     <td><span>{pacingCampaignDetails.pacingCarryForward}</span></td>
  </tr>
                         )})}
                         </tbody>
                         </table>
                         </div>
                         :''}
   
                           </CardBody> */}
				{/* </div> */}

				{/* //   <div class="row">
                        //   <div class="col-lg-4">
                        //     <table>
                        //       <tr>
                        // <td style={{width:'170px'}}><label id="label">&nbsp;Pacing Unit{i+1}</label></td>
                        //         <td><span>:&nbsp;{pacingCampaignDetails.pacingUnit}</span></td>
                        //         </tr>
                        
                        //         <tr>
                        //         <td style={{width:'170px'}}><label id="label">&nbsp;Pacing Month{i+1}</label></td>
                        //         <td><span>:&nbsp;{pacingCampaignDetails.pacingMonth}</span></td>
                        //       </tr>

                               
                        //     </table>
                        //   </div>

                        //   <div class="col-lg-4">
                        //     <table>
                        //     <tr>
                        //         <td style={{width:'170px'}}><label id="label">&nbsp;Pacing Percentage{i+1}</label></td>
                        //         <td><span>:&nbsp;{pacingCampaignDetails.pacingPercentage}</span></td>
                        //       </tr>

                        //       <tr>
                        //         <td style={{width:'160px'}}><label id="label">Pacing Lead Count{i+1}</label></td>
                        //         <td><span>:&nbsp;{pacingCampaignDetails.pacingLeadCount}</span></td>
                        //       </tr>

                         
                        //     </table>
                        //   </div>


                        //   <div class="col-lg-4">
                        //     <table>
                         

                        //       <tr>
                        //         <td style={{width:'160px'}}><label id="label"> Pacing End Date{i+1}</label></td>
                        //         <td><span>:&nbsp;{pacingCampaignDetails.pacingEndDate}</span></td>
                        //       </tr>

                        //       <tr>
                        //         <td style={{width:'160px'}}><label id="label">Pcing Carry Forward{i+1}</label></td>
                        //         <td><span>:&nbsp;{pacingCampaignDetails.pacingCarryForward}</span></td>
                        //       </tr>
                             
                        //     </table>
                        //   </div>

                          
                        //   </div>
 */}

				{/* /*row1 */}
				{/* <br />
                       <div style={{ fontSize:"large", color:'#124E9C' ,fontWeight :"bold"}}>Delivery Option :</div>
                      <div class="card" style={{backgroundColor:"#f8f8f8",     borderColor: "#707070", borderWidth: "0.3px"}}
                      > */}
				{/* <CardHeader >Delivery Option</CardHeader> */}
				{/* /**
				 * @author Narendra Phadke
				 * @param  Description Display Campaign Details on Modal
				 * @return Description return Campaign Details
				 */}

				{/* <CardBody>
                      {this.state.campaignBriefDetails.map(
                        campaignBriefDetails => (
                          <div class="row">
                            <div class="col-lg-4">
                              <table>
                                <tr>
                                  <td style={{width:'170px'}}>{" "}
                                    <label id="label">&nbsp;Pacing</label></td>
                                  <td>:&nbsp;{campaignBriefDetails.pacing}</td>
                                 </tr>

                                 <tr>
                                  <td style={{width:'170px'}}>
                                    <label id="label" >&nbsp;Campaign Reporting Day</label></td>
                                  <td>:&nbsp;{campaignBriefDetails.campaignReportingDay}</td>
                                 </tr>

                                
            
                                 <tr>
                                   <td  style={{width:'170px'}}><label id="label" >&nbsp;Creative Approval</label></td>
                                   {campaignBriefDetails.requiredLPApproval===''?
                                   <td>:&nbsp;No </td>:
                                   <td> :&nbsp;{campaignBriefDetails.requiredLPApproval}</td>}
                                 </tr>

                                 {campaignBriefDetails.requiredLPApproval==='' || campaignBriefDetails.requiredLPApproval===null || campaignBriefDetails.requiredLPApproval===undefined?''
                                 :<tr>
                                   <td  style={{width:'170px'}}><label id="label" >&nbsp;Creative Approval Required For</label></td>
                                   <td>:&nbsp;{campaignBriefDetails.creativeApprovalRequiredFor}</td>
                                 </tr>}
                                
                                   
                              </table>
                              </div>


                              <div class="col-lg-4">
                                <table>
                                <tr>
                                  <td style={{width:'160px'}}>
                                    <label id="label" >Pacing Lead Allocation</label></td>
                                  <td>:&nbsp;{campaignBriefDetails.pacingLeadAllocation}</td>
                                 </tr>


                                 <tr>
                                  <td style={{width:'160px'}}>
                                    <label id="label" >Lead Delivery Option</label></td>
                                  <td>:&nbsp;{campaignBriefDetails.leadDeliveryOption}</td>
                                 </tr>

                                 {campaignBriefDetails.requiredLPApproval===''?''
                                 :<tr>
                                    <td style={{width:'160px'}}>
                                    <label id="label">Creative Approval Timeline (Hours)</label>
                                    </td>
                                    <td>:&nbsp;{campaignBriefDetails.lpTimeline}</td>
                                 </tr>
                                 }
                                   <tr>
                                  <td style={{width:'160px'}}>
                                    <label id="label" >Call Audit</label></td>
                                  <td>:&nbsp;{campaignBriefDetails.callAudit==="undefined"||campaignBriefDetails.callAudit==="null"||campaignBriefDetails.callAudit===null || campaignBriefDetails.callAudit===undefined?'':campaignBriefDetails.callAudit}</td>
                                 </tr>
                                 
                                </table>
                              </div>


                              <div class="col-lg-4">
                              <table>
                                <tr>
                                  <td style={{width:'160px'}}>
                                    <label id="label" >Marketing Channel</label></td>
                                  <td>:&nbsp;{campaignBriefDetails.marketingChannel}</td>
                                 </tr>

                                 <tr>
                                  <td style={{width:'160px'}}>
                                    <label id="label" >Campaign Type</label></td>
                                  <td>:&nbsp;{campaignBriefDetails.ABM}</td>
                                 </tr>
                                </table>
                              </div>
                      

                              
                          </div>
                        )
                      )}
                      </CardBody>
                      <br />
                      </div> */}
				{/* /*row 2 */}
				<br />

				{/* <table id="myTable" className=" table table-bordered">
                        <thead>
                          <tr class="info"> */}
				{/* <div style={{ fontSize:"large", color:'#124E9C' ,fontWeight :"bold"}}>Campaign Specification :</div>
                          <div class="card" style={{backgroundColor:"#f8f8f8",     borderColor: "#707070", borderWidth: "0.3px"}}>
                            {/* <CardHeader >Campaign Specification</CardHeader> */}
				{/* </tr>
                        </thead>
                      </table>
                     */}
				{/* /**
				 * @author Narendra Phadke
				 * @param  Description Display Campaign Details on Modal
				 * @return Description return Campaign Details
				 */}
				<CardBody>
					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">RFP ID</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;{campaignBriefDetails.campID}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">RFP Name</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;{campaignBriefDetails.campaignName}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label" style={{ width: "60px" }}>
									Region
								</label>
							</div>
							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								:&nbsp;{campaignBriefDetails.region}
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Country</label>
							</div>
							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span style={{ wordBreak: "break-word" }} class="word-wrap">
									:&nbsp;{campaignBriefDetails.country}
								</span>
							</div>
						</div>
					))}

					{/* {this.state.campaignBriefDetails.map(
                        campaignBriefDetails => (
                          <div class="row">
                            <div class="col-sm-1">
                              <label id="label">&nbsp;State</label>&nbsp;
                             </div>
                             <div class="col-sm-3">
                              <span class="word-wrap">:&nbsp;{campaignBriefDetails.stateFileName
                                  }
                                  </span>
                            </div>
                       
                            <div class="col-sm-1">
                              <label id="label">&nbsp;City</label>&nbsp;
                              </div>
                             <div class="col-sm-3">
                              <span class="word-wrap">:&nbsp;{campaignBriefDetails.cityFileName }
                                  </span>
                            </div>
                            <div class="col-sm-1">
                              <label id="label">Zip Code</label>&nbsp;
                              </div>
                             <div class="col-sm-3">
                              <span class="word-wrap">:&nbsp;{campaignBriefDetails.zipCodeFileName}
                                  </span>
                            </div>
                          
                        </div>
                        ))} */}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Campaign Type</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;{campaignBriefDetails.campaignType}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Start Date</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;{campaignBriefDetails.startDate}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">End Date</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;{campaignBriefDetails.endDate}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Bidding Closes</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;{campaignBriefDetails.biddingEndDate}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Bidding Type</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								{/* //snehal-task-3509-Project's(DI) - Create RFP through XLS - FE */}
								<span class="word-wrap">:&nbsp;{this.state.biddingType}</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Type of Duration</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;{campaignBriefDetails.durationType}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Duration</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.duration === "undefined" ||
									campaignBriefDetails.duration === undefined ||
									campaignBriefDetails.duration === "null" ||
									campaignBriefDetails.duration === null ||
									campaignBriefDetails.duration === ""
										? ""
										: campaignBriefDetails.duration}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Marketing Channel</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;{campaignBriefDetails.marketingChannel}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">No.Of Custom Questions</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.noOfCustomQuestions === "undefined" ||
									campaignBriefDetails.noOfCustomQuestions === undefined ||
									campaignBriefDetails.noOfCustomQuestions === "null" ||
									campaignBriefDetails.noOfCustomQuestions === null ||
									campaignBriefDetails.noOfCustomQuestions === ""
										? ""
										: campaignBriefDetails.noOfCustomQuestions}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Target CPL</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">:&nbsp;{campaignBriefDetails.CPL}</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Max. lead available</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;{campaignBriefDetails.leadAllocation}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Currency</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;{campaignBriefDetails.currency}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Job Level</label>
							</div>
							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.jobLevel === "undefined" ||
									campaignBriefDetails.jobLevel === undefined ||
									campaignBriefDetails.jobLevel === "null" ||
									campaignBriefDetails.jobLevel === null ||
									campaignBriefDetails.jobLevel === ""
										? ""
										: campaignBriefDetails.jobLevel}
								</span>
							</div>
						</div>
					))}
					{/* Sandeep-task-2851-added code for custom Job Level */}
					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Custom Job Level</label>
							</div>
							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.customJobLevel === "undefined" ||
									campaignBriefDetails.customJobLevel === undefined ||
									campaignBriefDetails.customJobLevel === "null" ||
									campaignBriefDetails.customJobLevel === null ||
									campaignBriefDetails.customJobLevel === ""
										? ""
										: campaignBriefDetails.customJobLevel}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Job Function</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.jobFunction === "undefined" ||
									campaignBriefDetails.jobFunction === undefined ||
									campaignBriefDetails.jobFunction === "null" ||
									campaignBriefDetails.jobFunction === null ||
									campaignBriefDetails.jobFunction === ""
										? ""
										: campaignBriefDetails.jobFunction}
								</span>
							</div>
						</div>
					))}
					{/* Sandeep-task-2851-added code for custom Job Function */}
					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Custom Job Function</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.customJobFunction === "undefined" ||
									campaignBriefDetails.customJobFunction === undefined ||
									campaignBriefDetails.customJobFunction === "null" ||
									campaignBriefDetails.customJobFunction === null ||
									campaignBriefDetails.customJobFunction === ""
										? ""
										: campaignBriefDetails.customJobFunction}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Job Title</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.jobTitle === "undefined" ||
									campaignBriefDetails.jobTitle === undefined ||
									campaignBriefDetails.jobTitle === "null" ||
									campaignBriefDetails.jobTitle === null ||
									campaignBriefDetails.jobTitle === ""
										? ""
										: campaignBriefDetails.jobTitle}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Industry</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								:&nbsp;
								<span class="word-wrap">
									{" "}
									{campaignBriefDetails.industry +
										"|" +
										campaignBriefDetails.customIndustry ===
									"|"
										? ""
										: campaignBriefDetails.industry}
								</span>
							</div>
						</div>
					))}
					{/* Sandeep-task-2851-added code for custom Industry */}
					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Custom Industry</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								:&nbsp;
								<span class="word-wrap">
									{" "}
									{campaignBriefDetails.customIndustry +
										"|" +
										campaignBriefDetails.customIndustry ===
									"|"
										? ""
										: campaignBriefDetails.customIndustry}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Employee Size</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								:&nbsp;
								<span class="word-wrap">
									{campaignBriefDetails.empSize === "undefined" ||
									campaignBriefDetails.empSize === undefined ||
									campaignBriefDetails.empSize === "null" ||
									campaignBriefDetails.empSize === null ||
									campaignBriefDetails.empSize === ""
										? ""
										: campaignBriefDetails.empSize}
								</span>
							</div>
						</div>
					))}
					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Custom Employee Size</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								:&nbsp;
								<span class="word-wrap">
									{campaignBriefDetails.custEmpSize === "undefined" ||
									campaignBriefDetails.custEmpSize === undefined ||
									campaignBriefDetails.custEmpSize === "null" ||
									campaignBriefDetails.custEmpSize === null ||
									campaignBriefDetails.custEmpSize === ""
										? ""
										: campaignBriefDetails.custEmpSize}
								</span>
							</div>
						</div>
					))}
					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Company Revenue</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								:&nbsp;
								<span class="word-wrap">
									{campaignBriefDetails.companyRevenue === "undefined" ||
									campaignBriefDetails.companyRevenue === undefined ||
									campaignBriefDetails.companyRevenue === "null" ||
									campaignBriefDetails.companyRevenue === null ||
									campaignBriefDetails.companyRevenue === ""
										? ""
										: campaignBriefDetails.companyRevenue}
								</span>
							</div>
						</div>
					))}
					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Custom Company Revenue</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								:&nbsp;
								<span class="word-wrap">
									{campaignBriefDetails.customCompRevenue === "undefined" ||
									campaignBriefDetails.customCompRevenue === undefined ||
									campaignBriefDetails.customCompRevenue === "null" ||
									campaignBriefDetails.customCompRevenue === null ||
									campaignBriefDetails.customCompRevenue === ""
										? ""
										: campaignBriefDetails.customCompRevenue}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Conversion Criteria</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								{/* campaignBriefDetails.companyRevenue+','+campaignBriefDetails.customCompRevenue */}
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.conversionCriteria === "undefined" ||
									campaignBriefDetails.conversionCriteria === undefined ||
									campaignBriefDetails.conversionCriteria === "null" ||
									campaignBriefDetails.conversionCriteria === null ||
									campaignBriefDetails.conversionCriteria === ""
										? ""
										: campaignBriefDetails.conversionCriteria}
								</span>
							</div>
						</div>
					))}

					{/* Sandeep task---3595-Publisher side--RFP--RFP specification--Audience match, TAL, Publication missing issue */}
					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Account Match Percentage</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								{/* campaignBriefDetails.companyRevenue+','+campaignBriefDetails.customCompRevenue */}
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.audienceMatch === "undefined" ||
									campaignBriefDetails.audienceMatch === undefined ||
									campaignBriefDetails.audienceMatch === "null" ||
									campaignBriefDetails.audienceMatch === null ||
									campaignBriefDetails.audienceMatch === ""
										? ""
										: campaignBriefDetails.audienceMatch}
								</span>
							</div>
						</div>
					))}
					{/* Sandeep -task-3808-Demo-RFP- Create-Audience Match & Audience Reach */}
					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Audience Reach</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								{/* campaignBriefDetails.companyRevenue+','+campaignBriefDetails.customCompRevenue */}
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.audienceReach === "undefined" ||
									campaignBriefDetails.audienceReach === undefined ||
									campaignBriefDetails.audienceReach === "null" ||
									campaignBriefDetails.audienceReach === null ||
									campaignBriefDetails.audienceReach === ""
										? ""
										: campaignBriefDetails.audienceReach}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">TAL</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								{/* campaignBriefDetails.companyRevenue+','+campaignBriefDetails.customCompRevenue */}
								{/* Sandeep-task-3595-tal value issue */}
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.tal === "undefined" ||
									campaignBriefDetails.tal === undefined ||
									campaignBriefDetails.tal === "null" ||
									campaignBriefDetails.tal === null ||
									campaignBriefDetails.tal === ""
										? ""
										: campaignBriefDetails.tal}
								</span>
							</div>
						</div>
					))}

					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Publication</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								{/* campaignBriefDetails.companyRevenue+','+campaignBriefDetails.customCompRevenue */}
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.publication === "undefined" ||
									campaignBriefDetails.publication === undefined ||
									campaignBriefDetails.publication === "null" ||
									campaignBriefDetails.publication === null ||
									campaignBriefDetails.publication === ""
										? ""
										: campaignBriefDetails.publication}
								</span>
							</div>
						</div>
					))}

					{/*sunita-task-3645-added code for countrywise lead allocation */}
					{this.state.campaignBriefDetails.map((campaignBriefDetails) => (
						<div class="row">
							<div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
								<label id="label">Lead Allocation by Country</label>
							</div>

							<div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
								{/* campaignBriefDetails.companyRevenue+','+campaignBriefDetails.customCompRevenue */}
								<span class="word-wrap">
									:&nbsp;
									{campaignBriefDetails.countrywiseLeadAllocation ===
										"undefined" ||
									campaignBriefDetails.countrywiseLeadAllocation ===
										undefined ||
									campaignBriefDetails.countrywiseLeadAllocation === "null" ||
									campaignBriefDetails.countrywiseLeadAllocation === null ||
									campaignBriefDetails.countrywiseLeadAllocation === ""
										? ""
										: campaignBriefDetails.countrywiseLeadAllocation}
								</span>
							</div>
						</div>
					))}

					{/* {this.state.campaignBriefDetails.map(
                        campaignBriefDetails => (
                          <div class="row">
                             <div class="col-sm-1">
                              <label id="label">&nbsp;Other Specs</label>&nbsp;
                              </div>
                              
                              <div class="col-sm-11">
                              <span class="word-wrap">:&nbsp;{unescape(campaignBriefDetails.otherSpecs)}</span>
                            </div> 
                        </div>))} */}
				</CardBody>

				{/*sunita-task-3645-added below code for countrywise lead allocation */}
				{this.state.countrywiseLeadAllocation === "Yes" ? (
					<div>
						<div
							style={{
								fontFamily: "roboto",
								fontSize: "20px",
								color: "#193d8f",
								fontWeight: "600",
							}}>
							&nbsp;Lead Allocation by Country :
						</div>
						<div
							class="card"
							style={{
								backgroundColor: "#f8f8f8",
								borderColor: "#707070",
								borderWidth: "0.3px",
							}}>
							<CardBody>
								<table class="col-md-5 col-lg-5 ">
									<table class="table  table-bordered">
										<thead>
											<tr class="info">
												<th
													style={{
														color: "#707070",
														backgroundColor: " rgba(126, 127, 129, 0.22)",
													}}>
													Country Name
												</th>
												<th
													style={{
														color: "#707070",
														backgroundColor: " rgba(126, 127, 129, 0.22)",
													}}>
													Lead Allocation
												</th>
												<th
													style={{
														color: "#707070",
														backgroundColor: " rgba(126, 127, 129, 0.22)",
													}}>
													Status
												</th>
											</tr>
										</thead>
										<tbody>
											{this.state.countryWiseAllocationArray.map((item) => (
												<tr>
													{item.status === "Removed" ? (
														<td style={{ color: "red" }}>{item.country}</td>
													) : (
														<td>{item.country}</td>
													)}
													{item.status === "Removed" ? (
														<td style={{ color: "red" }}>{item.leadCount}</td>
													) : (
														<td>{item.leadCount}</td>
													)}
													{item.status === "Removed" ? (
														<td style={{ color: "red" }}>{item.status}</td>
													) : (
														<td>{item.status}</td>
													)}
												</tr>
											))}
										</tbody>
									</table>
								</table>
								<br />
							</CardBody>
						</div>
					</div>
				) : (
					""
				)}
				<br />

				<div
					style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}>
					{" "}
					&nbsp;Supporting Document :
				</div>
				<div
					class="card"
					style={{
						backgroundColor: "#f8f8f8",
						borderColor: "#707070",
						borderWidth: "0.3px",
					}}>
					<CardBody>
						<div class="row">
							<div class="col-sm-6">
								{" "}
								<label id="label" style={{ width: "80px" }}>
									&nbsp;ABM :
								</label>
								{this.state.abmArray.map((abmArray) => (
									<span className="word-wrap">{abmArray.suppDocName}|</span>
								))}
							</div>
							<div class="col-sm-6">
								<label id="label">&nbsp;Suppression :</label>

								{this.state.suppressionArray.map((suppressionArray) => (
									<span className="word-wrap">
										{suppressionArray.suppDocName}|
									</span>
								))}
							</div>
							<div class="col-sm-6">
								<label id="label" style={{ width: "80px" }}>
									&nbsp;Exclusion :
								</label>
								{this.state.exclusionArray.map((exclusionArray) => (
									<span className="word-wrap">
										{exclusionArray.suppDocName}|
									</span>
								))}
							</div>
							<div class="col-sm-6">
								<label id="label" style={{ width: "80px" }}>
									&nbsp;Other :
								</label>
								{this.state.otherArray.map((otherArray) => (
									<span className="word-wrap">{otherArray.suppDocName}|</span>
								))}
							</div>
						</div>
					</CardBody>
				</div>
				{/* </div> */}

				{/* <div style={{ fontSize:"large", color:'#124E9C' ,fontWeight :"bold"}}>Supporting Document :</div>
                         <div class="card" style={{backgroundColor:"#f8f8f8",     borderColor: "#707070", borderWidth: "0.3px"}}> */}
				{/* <CardHeader>Supporting Document</CardHeader> */}

				{/* /**
				 * @author Narendra Phadke
				 * @param  Description Display Campaign Supporting Document Asset Details on Modal
				 * @return Description return Campaign Details
				 */}
				{/* <CardBody>
                        <div class="row">
                          <div class="col-sm-6">
                            {" "}
                            <label id="label"style={{width:'80px'}}>&nbsp;ABM</label>
                            {this.state.campaignBriefSupportingDocumentAbmDetails.map(
                              campaignBriefSupportingDocumentAbmDetails => (
                                <span className="word-wrap">:&nbsp;
                                  
                                   {
                                    campaignBriefSupportingDocumentAbmDetails.suppDocName
                                  } 
                                   | 
                                   </span>
                              )
                            )}
                          </div>
                          <div class="col-sm-6">
                            <label id="label">Suppression</label> */}
				{/* {this.state.campaignBriefSupportingDocumentSuppresionDetails.length>0?
                                <div class="col-md-12 col-lg-12 ">
                                <table class="table table-responsive table-bordered">
                                  <thead>
                                    <tr class="info">
                                      <th class="table-header">Suppression ID</th>
                                      <th class="table-header">Suppression Name</th>
                                      {this.state.isSuppLink===true?
                                      <th  class="table-header">Suppression Link</th>:''}
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {this.state.campaignBriefSupportingDocumentSuppresionDetails.map(
                              suppressionList => (
                                    <tr>
                                      <td>{suppressionList.supportDocID}</td>
                                      <td>{suppressionList.suppDocName}</td>
                                      {this.state.isSuppLink===true?
                                      <td style={{wordBreak:'break-all'}}><a href= {suppressionList.assetLink} target="_blank" >
                                      {suppressionList.assetLink}
                                  </a></td>:''}
                                    </tr>
                                    ))}
                                  </tbody>
                                </table>
                               </div>
                             :''}{this.state.isSuppLink===true?
                              <div class="float-right"><button type="button" class="btn btn-primary btn-xs" onClick={this.openAllSuppLink.bind(this)}>
                             Open All
                           </button></div>:''} */}
				{/* &nbsp;:&nbsp;{this.state.campaignBriefSupportingDocumentSuppresionDetails.map(
                              campaignBriefSupportingDocumentSuppresionDetails => (
                                <span className="word-wrap">
                                  
                                   {
                                    campaignBriefSupportingDocumentSuppresionDetails.suppDocName
                                  } 
                                  | 
                                  </span>
                              )
                            )}
                          </div>
                          <div class="col-sm-6">
                            <label id="label" style={{width:'80px'}}>&nbsp;Exclusion</label>
                            {this.state.campaignBriefSupportingDocumentExclusionDetails.map(
                              campaignBriefSupportingDocumentExclusionDetails => (
                                <span className="word-wrap">:&nbsp;
                                  
                                   {
                                    campaignBriefSupportingDocumentExclusionDetails.suppDocName
                                  } 
                                  | 
                                  </span>
                              )
                            )}
                          </div> */}
				{/* <div class="col-sm-6">
                            <label id="label" style={{width:'80px'}}>Other</label>
                            {this.state.campaignBriefSupportingDocumentOtherDetails.map(
                              campaignBriefSupportingDocumentOtherDetails => (
                                <span className="word-wrap">:&nbsp;
                                   {
                                    campaignBriefSupportingDocumentOtherDetails.suppDocName
                                  } 
                                 | 
                                 </span>
                              )
                            )}
                          </div>
                        </div>
                        </CardBody>
                        </div> */}

				{/*row 4 */}

				{/* <br />
                         <div style={{ fontSize:"large", color:'#124E9C' ,fontWeight :"bold"}}>Campaign Asset :</div>
                         <div class="card" style={{backgroundColor:"#f8f8f8",     borderColor: "#707070", borderWidth: "0.3px"}}> */}
				{/*
                        <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info"> */}
				{/* <CardHeader>Campaign Asset</CardHeader> */}
				{/* </tr>
                          </thead>
                        </table> */}
				{/* /**
				 * @author Narendra Phadke
				 * @param  Description Display Campaign Supporting Document Asset Details on Modal
				 * @return Description return Campaign Details
				 */}
				{/* <CardBody>
                      <div class="row" > <div class="col-sm-12">
                        &nbsp;<label id="label">Assets File :</label>
                         {this.state.assetListArray.length>0?
                            <table class="table  table-bordered">
                              <thead>
                                <tr class="info">

                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Asset ID</th>
                                  {this.state.requiredLeadPerAsset==='Yes'?
                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Lead Percentage (%)</th>:''}
                                   {this.state.requiredLeadPerAsset==='Yes'?
                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Lead Per Asset</th>:''}
                                  {this.state.multiTouch==='Yes'?
                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Multi Touch</th>:''}
                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Asset Name</th>
                                  
                                 
                                </tr>
                              </thead>
                              <tbody>
{this.state.assetListArray.map(assetListArray=>(
  assetListArray.assetStatus==='Removed'?
  <tr>
    <td>
      <span style={{color:'red',textDecoration:'line-through'}}>
                      {assetListArray.supportDocID}</span>
    </td>
    {this.state.requiredLeadPerAsset==='Yes'?
    <td>
    <span style={{color:'red',textDecoration:'line-through'}}>
    {assetListArray.leadPercentage}</span>
     </td>:''}
    {this.state.requiredLeadPerAsset==='Yes'?
      <td>
    <span style={{color:'red',textDecoration:'line-through'}}>
                      {assetListArray.leadPerAsset} </span>
      </td>:''}
      {this.state.multiTouch==='Yes'?
    <td>
    <span style={{color:'red',textDecoration:'line-through'}}>
    {assetListArray.multiTouch}</span>
     </td>:''}
    <td>
    <span style={{color:'red',textDecoration:'line-through'}}>
                      {assetListArray.suppDocName}
    </span>
    </td>
   
    </tr>
:<tr>
<td>{assetListArray.supportDocID}</td>
{this.state.requiredLeadPerAsset==='Yes'?
<td>{assetListArray.leadPercentage}</td>:''}
{this.state.requiredLeadPerAsset==='Yes'?
<td>{assetListArray.leadPerAsset}</td>:''}
{this.state.multiTouch==='Yes'?
<td>{assetListArray.multiTouch}</td>:''}
<td>{assetListArray.suppDocName}</td>

</tr>

                   ))}
                              </tbody>
                            </table>
                           :''}
                           </div>

<div class="col-sm-12">

                           {this.state.assetLinkArray.length>0?<p>
                            <label id="label">&nbsp;Assets link :</label><br/>
                    
                            <div>
                            <table class="col-md-12 col-lg-12 ">
                            <table class="table  table-bordered">
                              <thead>
                                <tr class="info">
                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Asset ID</th>
                                  {this.state.campaignBriefDetails[0].requiredLeadPerAsset!==null && this.state.campaignBriefDetails[0].requiredLeadPerAsset==='Yes'?
                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Lead Percentage (%)</th>:''}
                                   {this.state.campaignBriefDetails[0].requiredLeadPerAsset!==null && this.state.campaignBriefDetails[0].requiredLeadPerAsset==='Yes'?
                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Lead Per Asset</th>:''}
                                  {this.state.campaignBriefDetails[0].multiTouch!==null && this.state.campaignBriefDetails[0].multiTouch==='Yes'?
                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Multi Touch</th>:''}
                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Asset Name</th>
                                  <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Asset Link</th>
                                </tr>
                              </thead>
                              <tbody>
{this.state.linkListArray.map(linkListArray=>(
  linkListArray.assetStatus==='Removed'?
  <tr>
      <td><span style={{color:'red',textDecoration:'line-through'}}>{linkListArray.supportDocID}</span></td>
      {this.state.campaignBriefDetails[0].requiredLeadPerAsset==='Yes'?
      <td><span style={{color:'red',textDecoration:'line-through'}}>{linkListArray.leadPercentage}</span></td>:''}
      {this.state.campaignBriefDetails[0].requiredLeadPerAsset==='Yes'?
      <td><span style={{color:'red',textDecoration:'line-through'}}>{linkListArray.leadPerAsset}</span></td>:''}
       {this.state.campaignBriefDetails[0].multiTouch==='Yes'?
      <td><span style={{color:'red',textDecoration:'line-through'}}>{linkListArray.multiTouch}</span></td>:''}
      <td><span style={{color:'red',textDecoration:'line-through'}}>{linkListArray.suppDocName}</span></td>
      <td style={{wordBreak:'break-all'}}><a href= {linkListArray.assetLink} target="_blank" ><span style={{color:'red',textDecoration:'line-through'}}>
      {linkListArray.assetLink}</span> </a></td>
</tr>
:<tr>
      <td>{linkListArray.supportDocID}</td>
      {this.state.campaignBriefDetails[0].requiredLeadPerAsset==='Yes'?
      <td>{linkListArray.leadPercentage}</td>:''}
      {this.state.campaignBriefDetails[0].requiredLeadPerAsset==='Yes'?
      <td>{linkListArray.leadPerAsset}</td>:''}
       {this.state.campaignBriefDetails[0].multiTouch==='Yes'?
      <td>{linkListArray.multiTouch}</td>:''}
      <td>{linkListArray.suppDocName}</td>
      <td style={{wordBreak:'break-all'}}><a href= {linkListArray.assetLink} target="_blank" >
      {linkListArray.assetLink} </a></td>
</tr>
                             ))}
                              </tbody>
                            </table>
                            <div class="float-right"><button type="button" class="btn btn-primary btn-xs" onClick={this.openAllLink.bind(this)}>
                            Open All
                          </button></div>
                         
                            </table>
  </div></p>
:''}
                          </div>
                        </div>
    </CardBody>
                        </div> */}

				{/*row 4 */}

				{/* <br />
                        <div style={{ fontSize:"large", color:'#124E9C' ,fontWeight :"bold"}}>Lead Delivery Through API :</div>
                        <div class="card" style={{backgroundColor:"#f8f8f8",     borderColor: "#707070", borderWidth: "0.3px"}}> */}
				{/*row 5 */}

				{/* <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info"> */}
				{/* <CardHeader >Lead Delivery Through API</CardHeader> */}
				{/* </tr>
                          </thead>
                        </table> */}
				{/* <CardBody>                   
<div class="word-wrap" style={{align:"centre"}}><label>URL</label>&nbsp;:&nbsp;{this.state.deliveryURL}{this.state.customQuestionURL}</div>
<br/>
</CardBody>
</div> */}
				<br></br>
				{/* <div style={{ fontSize:"large", color :"#124E9C" ,fontWeight :"bold"}}>Delivery Format :</div>
                        <div class="card" style={{backgroundColor:"#f8f8f8",     borderColor: "#707070", borderWidth: "0.3px"}}> */}
				{/* <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info"> */}
				{/* <CardHeader >Delivery Format</CardHeader> */}
				{/* </tr>

                          </thead>
                        </table> */}
				<CardBody>
					{/* /**
					 * @author Narendra Phadke
					 * @param  Description Display Campaign Delivery Details on Modal
					 * @return Description return Campaign Delivery Details
					 */}
					{/* {this.state.campaignBriefDeliveryDetails.map(
                          campaignBriefDeliveryDetails => (
                            <div class="row">
                            <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Publisher ID</label>
                                :&nbsp; {campaignBriefDeliveryDetails.pID}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'180px'}}>Campaign ID</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.campaignID}
                              </div>

                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'150px'}}>Allocation ID</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.reAllocationID}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Lead Interaction Date</label>:&nbsp;
                                {campaignBriefDeliveryDetails.leadInteractionDate}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'180px'}}>First Name</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.firstName}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'150px'}}>Last Name</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.lastName}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Company Name</label>:&nbsp;
                                {campaignBriefDeliveryDetails.companyName}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'180px'}}>Email</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.email}
                              </div>
                               
                              <div class="col-sm-4">
                                {" "}
                                <label id="label" style={{width:'150px'}}>Work Phone</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.workPhone}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Job Title</label>:&nbsp;
                                {campaignBriefDeliveryDetails.jobTitle}
                              </div>
                              <div class="col-sm-4">
                                    {" "}
                                    <label id="label"style={{width:'180px'}}>Job Title validation</label>&nbsp;:&nbsp;
                                    {campaignBriefDeliveryDetails.jobTitleValidation}
                                  </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'150px'}}>Job Level</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.jobLevel}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Job Function</label>:&nbsp;
                                {campaignBriefDeliveryDetails.jobFunction}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'180px'}}>Address</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.address}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'150px'}}>Country</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.country}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Street</label>:&nbsp;
                                {campaignBriefDeliveryDetails.street}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'180px'}}>City</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.city}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'150px'}}> State</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.state}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Zip Code</label>:&nbsp;
                                {campaignBriefDeliveryDetails.zipCode}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'185px'}}>Company Employee Size</label>:&nbsp;
                                {campaignBriefDeliveryDetails.companyEmployeeSize}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label" style={{width:'155px'}}>Company Revenue</label>:&nbsp;
                                
                                {campaignBriefDeliveryDetails.companyRevenue}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;IP</label>:&nbsp;
                                {campaignBriefDeliveryDetails.ip}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'180px'}}>Industry</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.industry}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'150px'}}>Asset ID</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.supportDocID}
                              </div>
                              {campaignBriefDeliveryDetails.assetNameTouch1==="Yes"?
                              <div class="col-sm-4">
                              {" "}
                              <label id="label"style={{width:'160px'}}>&nbsp;Asset Name</label>:&nbsp;
                             No
                            </div>
                              :
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Asset Name</label>:&nbsp;
                                {campaignBriefDeliveryDetails.assetName}
                              </div>
                              }
                              {campaignBriefDeliveryDetails.assetNameTouch1==="Yes"?
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Asset Name Touch 1</label>:&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch1}
                              </div>
                              :''}
                              {campaignBriefDeliveryDetails.assetNameTouch2==="Yes"?
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Asset Name Touch 2</label>:&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch2}
                              </div>
                              :''}
                              {campaignBriefDeliveryDetails.assetNameTouch3==="Yes"?
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Asset Name Touch 3</label>:&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch3}
                              </div>
                              :''} */}
					{/* <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Multi Touch</label>:&nbsp;
                                {campaignBriefDeliveryDetails.multiTouch}
                              </div> */}

					{/* <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'180px'}}>Extra 1</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.extra1}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'150px'}}>Extra 2</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.extra2}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Extra 3</label>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra3}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'180px'}}>Extra 4</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.extra4}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'150px'}}>Extra 5</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.extra5}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;Domain</label>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.domain}
                              </div>
                              
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'183px'}}>Alternate Phone No</label>:&nbsp;
                                {campaignBriefDeliveryDetails.alternatePhoneNo}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'150px'}}>Comments</label>&nbsp;:&nbsp;
                                {campaignBriefDeliveryDetails.comments}
                              </div>
                              <div class="col-sm-4">
                                {" "}
                                <label id="label"style={{width:'160px'}}>&nbsp;LinkedIn</label>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.linkedIn}
                              </div>
                              
                            <div class="col-sm-4">
                              {" "}
                              <label id="label"style={{width:'180px'}}>Customize Mapping</label>&nbsp;:&nbsp;
                              {campaignBriefDeliveryDetails.customizeMapping}
                            </div>
                             

                              {(this.state.campaignBriefDetails[0].marketingChannel==='Email/Telemarketing'||this.state.campaignBriefDetails[0].marketingChannel==='TeleMarketing')&&
                              (campaignBriefDeliveryDetails.channel=="Yes"||campaignBriefDeliveryDetails.channel=="yes")?
                              <div class="col-sm-4">
                              {" "}
                              <label id="label"style={{width:'155px'}}>Channel</label>
                              :&nbsp;Yes
                              </div>:''
                              }
                              

                            </div>
                          )
                        )} */}
				</CardBody>
				{/* </div> */}
				<br />

				{/*row 5 */}
				{/* /**
				 * @author Narendra Phadke
				 * @param  Description Display Campaign Custom Question Details on Modal
				 * @return Description return Campaign Delivery Details
				 */}

				{this.state.customizedColumns.length == 0 ? (
					""
				) : (
					// <table id="myTable" className=" table table-bordered">
					//   <thead>
					//     <tr class="info">
					//       <th class="table-header">Customized Column Mapping</th>
					//     </tr>
					//   </thead>
					// </table>

					<div
						style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}>
						Customized Column Mapping :
					</div>
				)}

				{/* {this.state.customizedColumns.length==0?'':
                         <div class="card" style={{backgroundColor:"#f8f8f8",     borderColor: "#707070", borderWidth: "0.3px"}}>
                         <CardBody>
                                                <div class="container-fluid" style={{paddingBottom:'11px'}}>
                        <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3" >

                        </div>
                        <table class='table table-bordered'  id="myTable3" style={{width:'60%'}}>
                          <thead >
                            <tr style={{height:'35px'}}>
                              <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>System Field </th>
                              <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Mapping Alias Field </th> 
                              <th style={{ color: '#707070',backgroundColor:' rgba(126, 127, 129, 0.22)'}}>Values</th>
                            </tr>
                          </thead>
                          
                          {
                          this.state.campaignBriefDeliveryDetails.map((
                          campaignBriefDeliveryDetails,i) =>{return (
                          <tbody>


                          {campaignBriefDeliveryDetails.pID=="yes"||campaignBriefDeliveryDetails.pID=="Yes"?
                          <tr>
                          <td>&nbsp;Publisher ID :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Publisher ID</b>:this.state.customizedColumns[0].pID}</b> <br/></td>
                          <td></td>    
                               
                               </tr>
                          :''}
     
                               {campaignBriefDeliveryDetails.campaignID=="Yes"||campaignBriefDeliveryDetails.campaignID=="yes"?
                            <tr>
                          <td>&nbsp;Campaign ID :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Campaign ID</b>:this.state.customizedColumns[0].campID}</b><br/></td>
                          <td></td>
                               </tr>
                          :''}
                      {campaignBriefDeliveryDetails.reAllocationID=="yes"||campaignBriefDeliveryDetails.reAllocationID=="Yes"?    
                               <tr>
                          <td>&nbsp;Allocation ID :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Allocation ID</b>:this.state.customizedColumns[0].reAllocationID}</b><br/></td>
                          <td></td> 
                               </tr>
                               :''}
                                {campaignBriefDeliveryDetails.leadInteractionDate=="Yes"|| campaignBriefDeliveryDetails.leadInteractionDate=="yes"?
                               <tr>
                          <td>&nbsp;Lead Interaction Date :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Lead Interaction Date</b>:this.state.customizedColumns[0].leadInteractionDate}</b><br/></td>
                          <td></td>
                               </tr>
                             :''}
                             {campaignBriefDeliveryDetails.firstName=="Yes"||campaignBriefDeliveryDetails.firstName=="yes"?
                               <tr>
                          <td>&nbsp;First Name :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>First Name</b>:this.state.customizedColumns[0].firstName}</b><br/></td>
                          <td></td>
                               </tr>
                               :''}
                                {campaignBriefDeliveryDetails.lastName=="Yes"||campaignBriefDeliveryDetails.lastName=="yes"?
                               <tr>
                          <td>&nbsp;Last Name :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Last Name</b>:this.state.customizedColumns[0].lastName}</b><br/></td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.companyName=="Yes"||campaignBriefDeliveryDetails.companyName=="yes"?
                               <tr>
                          <td>&nbsp;Company Name :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Company Name</b>:this.state.customizedColumns[0].companyName}</b><br/></td>
                          <td></td>
                               </tr>
                               :''}
                             {campaignBriefDeliveryDetails.email=="Yes"||campaignBriefDeliveryDetails.email=="yes" ?
                               <tr>
                          <td>&nbsp;Email :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Email</b>:this.state.customizedColumns[0].email}</b><br/></td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.workPhone=="Yes"||campaignBriefDeliveryDetails.workPhone=="yes"?
                               <tr>
                          <td>&nbsp;Work Phone :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Work Phone</b>:this.state.customizedColumns[0].workPhone}</b><br/></td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.workPhone=="Yes"||campaignBriefDeliveryDetails.workPhone=="yes"?
                               <tr>
                          <td>&nbsp;Work Phone Format:</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>91-XXXXXXXXXX</b>:this.state.customizedColumns[0].workPhoneFormat}</b><br/></td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.jobTitle=="Yes"||campaignBriefDeliveryDetails.jobTitle=="yes"?
                               <tr>
                          <td>&nbsp;Job Title :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Job Title</b>:this.state.customizedColumns[0].jobTitle}</b><br/> </td>
                          <td></td>
                               </tr>
                                   :''}
                                 {campaignBriefDeliveryDetails.address=="Yes"||campaignBriefDeliveryDetails.address=="yes"?
                              <tr>
                          <td>&nbsp;Address :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Address</b>:this.state.customizedColumns[0].address}</b><br/> </td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.country=="yes"||campaignBriefDeliveryDetails.country=="Yes"?
                               <tr>
                          <td>&nbsp;Country :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Country</b>:this.state.customizedColumns[0].country}</b><br/> </td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.city=="Yes"||campaignBriefDeliveryDetails.city=="yes"?
                               <tr>
                          <td>&nbsp;City :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>City</b>:this.state.customizedColumns[0].city}</b><br/> </td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.state=="Yes"||campaignBriefDeliveryDetails.state=="yes"?
                               <tr>
                          <td>&nbsp;State :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>State</b>:this.state.customizedColumns[0].state}</b><br/> </td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.zipCode=="Yes"||campaignBriefDeliveryDetails.zipCode=="Yes"?
                               <tr>
                          <td>&nbsp;Zip Code Mapping :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Zip Code</b>:this.state.customizedColumns[0].zipCode}</b><br/> </td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.companyEmployeeSize=="Yes"||campaignBriefDeliveryDetails.companyEmployeeSize=="yes"?
                               <tr>
                          <td>&nbsp;Company Employee Size :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Company Employee Size</b>:this.state.customizedColumns[0].companyEmployeeSize}</b><br/> </td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.companyRevenue=="Yes"||campaignBriefDeliveryDetails.companyRevenue=="yes"?
                               <tr>
                          <td>&nbsp;Company Revenue :</td>
                          <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Company Revenue</b>:this.state.customizedColumns[0].companyRevenue}</b><br/> </td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.industry=="Yes"||campaignBriefDeliveryDetails.industry=="yes"?
                               <tr>
                          <td>&nbsp;Industry :</td>
                          <td>&nbsp;Current :<b>&nbsp;{this.state.customizedColumns.length==0?<b>Industry</b>:this.state.customizedColumns[0].industry}</b><br/> </td>
                          <td></td>
                               </tr>
                               :''}
                               {campaignBriefDeliveryDetails.assetName=="yes"||campaignBriefDeliveryDetails.assetName=="Yes"?

campaignBriefDeliveryDetails.assetNameTouch1=="yes"||campaignBriefDeliveryDetails.assetNameTouch1=="Yes"?'':
                               <tr>
                          <td>&nbsp;Asset Name :</td>
                          <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Asset Name</b>:this.state.customizedColumns[0].assetName}</b><br/> </td>
                          <td></td>
                               </tr>
                           :''}
                           {campaignBriefDeliveryDetails.assetNameTouch1=="yes"||campaignBriefDeliveryDetails.assetNameTouch1=="Yes"?
                               <tr>
                          <td>&nbsp;Asset Name Touch 1 :</td>
                          <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Asset Name Touch 1</b>:this.state.customizedColumns[0].assetNameTouch1}</b><br/> </td>
                          <td></td>
                               </tr>
                           :''}
                            {campaignBriefDeliveryDetails.assetTimestampTouch1=="yes"||campaignBriefDeliveryDetails.assetTimestampTouch1=="Yes"?
                               <tr>
                          <td>&nbsp;Asset Timestamp Touch 1 :</td>
                          <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Asset Timestamp Touch 1</b>:this.state.customizedColumns[0].assetTimestampTouch1}</b><br/> </td>
                          <td></td>
                               </tr>
                           :''}

{campaignBriefDeliveryDetails.assetNameTouch2=="yes"||campaignBriefDeliveryDetails.assetNameTouch2=="Yes"?
                               <tr>
                          <td>&nbsp;Asset Name Touch 2 :</td>
                          <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Asset Name Touch 2</b>:this.state.customizedColumns[0].assetNameTouch2}</b><br/> </td>
                          <td></td>
                               </tr>
                           :''}

{campaignBriefDeliveryDetails.assetTimestampTouch2=="yes"||campaignBriefDeliveryDetails.assetTimestampTouch2=="Yes"?
                               <tr>
                          <td>&nbsp;Asset Timestamp Touch 2 :</td>
                          <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Asset Timestamp Touch 2</b>:this.state.customizedColumns[0].assetTimestampTouch2}</b><br/> </td>
                          <td></td>
                               </tr>
                           :''}

{campaignBriefDeliveryDetails.assetNameTouch3=="yes"||campaignBriefDeliveryDetails.assetNameTouch3=="Yes"?
                               <tr>
                          <td>&nbsp;Asset Name Touch 3 :</td>
                          <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Asset Name Touch 3</b>:this.state.customizedColumns[0].assetNameTouch3}</b><br/> </td>
                          <td></td>
                               </tr>
                           :''}

{campaignBriefDeliveryDetails.assetTimestampTouch3=="yes"||campaignBriefDeliveryDetails.assetTimestampTouch3=="Yes"?
                               <tr>
                          <td>&nbsp;Asset Timestamp Touch 3 :</td>
                          <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Asset Timestamp Touch 3</b>:this.state.customizedColumns[0].assetTimestampTouch3}</b><br/> </td>
                          <td></td>
                               </tr>
                           :''}




                              {campaignBriefDeliveryDetails.street=="Yes"||campaignBriefDeliveryDetails.street=="yes"?
                            <tr>
                            <td>&nbsp;Street :</td>
                            <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Street</b>:this.state.customizedColumns[0].street}</b><br/> </td>
                            <td></td>
                                 </tr>
                            :''}
                            {campaignBriefDeliveryDetails.ip=="Yes"||campaignBriefDeliveryDetails.ip=="yes"?
                            <tr>
                            <td>&nbsp;IP :</td>
                            <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>IP</b>:this.state.customizedColumns[0].ip}</b><br/> </td>
                            <td></td>
                                 </tr>
                            :''}
                            {campaignBriefDeliveryDetails.supportDocID=="Yes"||campaignBriefDeliveryDetails.supportDocID=="yes"?
                            <tr>
                            <td>&nbsp;Asset ID :</td>
                            <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Asset ID</b>:this.state.customizedColumns[0].supportDocID}</b><br/> </td>
                            <td></td>
                                 </tr>
                            :''}
                            {campaignBriefDeliveryDetails.jobLevel=="Yes"||campaignBriefDeliveryDetails.jobLevel=="yes"?
                     <tr>
                     <td>&nbsp;Job Level :</td>
                     <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Job Level</b>:this.state.customizedColumns[0].jobLevel}</b><br/></td>
                     <td></td>
                          </tr>
:''}                           
{campaignBriefDeliveryDetails.jobFunction=="Yes"||campaignBriefDeliveryDetails.jobFunction=="yes"? 
                 <tr>
                 <td>&nbsp;Job Function :</td>
                 <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Job Function</b>:this.state.customizedColumns[0].JobFunction}</b><br/></td>
                 <td></td>
                      </tr>

:''}
{this.state.campaignBriefDetails[0].marketingChannel=="Email/Telemarketing"||this.state.campaignBriefDetails[0].marketingChannel=="TeleMarketing"?
campaignBriefDeliveryDetails.channel=="Yes"||campaignBriefDeliveryDetails.channel=="yes"? 
<tr>
<td>&nbsp;Channel :</td>
<td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Channel</b>:this.state.customizedColumns[0].channel}</b><br/>
</td>
<td></td>
     </tr>

:''
:''}
{campaignBriefDeliveryDetails.extra1=="Yes"||campaignBriefDeliveryDetails.extra1=="yes"?
                         <tr>
                         <td>&nbsp;Extra 1 :</td>
                         <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Extra 1</b>:this.state.customizedColumns[0].extra1}</b><br/> </td>
                         <td></td>
                              </tr>

:''}
{campaignBriefDeliveryDetails.extra2=="Yes"||campaignBriefDeliveryDetails.extra2=="yes"?
                     <tr>
                     <td>&nbsp;Extra 2 :</td>
                     <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Extra 2</b>:this.state.customizedColumns[0].extra2}</b><br/> </td>
                     <td></td>
                          </tr>
:''}                           
{campaignBriefDeliveryDetails.extra3=="Yes"||campaignBriefDeliveryDetails.extra3=="yes"? 
                 <tr>
                 <td>&nbsp;Extra 3:</td>
                 <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Extra 3</b>:this.state.customizedColumns[0].extra3}</b><br/></td>
                 <td></td>
                      </tr>

:''}
{campaignBriefDeliveryDetails.extra4=="Yes"||campaignBriefDeliveryDetails.extra4=="yes"?
                         <tr>
                         <td>&nbsp;Extra 4 :</td>
                         <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Extra 4</b>:this.state.customizedColumns[0].extra4}</b><br/> </td>
                         <td></td>
                              </tr>

:''}
{campaignBriefDeliveryDetails.extra5=="Yes"||campaignBriefDeliveryDetails.extra5=="yes"?
                     <tr>
                     <td>&nbsp;Extra 5 :</td>
                     <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Extra 5</b>:this.state.customizedColumns[0].extra5}</b><br/></td>
                     <td></td>
                          </tr>
:''}                           
{campaignBriefDeliveryDetails.domain=="Yes"||campaignBriefDeliveryDetails.domain=="yes"? 
                 <tr>
                 <td>&nbsp;Domain :</td>
                 <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Domain</b>:this.state.customizedColumns[0].domain}</b><br/></td>
                 <td></td>
                      </tr>

:''}
{campaignBriefDeliveryDetails.alternatePhoneNo=="Yes"||campaignBriefDeliveryDetails.alternatePhoneNo=="yes"?
                         <tr>
                         <td>&nbsp;Alternate Phone No :</td>
                         <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Alternate Phone No</b>:this.state.customizedColumns[0].alternatePhoneNo}</b><br/></td>
                         <td></td>
                              </tr>

:''}
{campaignBriefDeliveryDetails.comments=="Yes"||campaignBriefDeliveryDetails.comments=="yes"?
                     <tr>
                     <td>&nbsp;Comments :</td>
                     <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>Comments</b>:this.state.customizedColumns[0].comments}</b><br/></td>
                     <td></td>
                          </tr>
:''}                           
{campaignBriefDeliveryDetails.linkedIn=="Yes"||campaignBriefDeliveryDetails.linkedIn=="yes"? 
                 <tr>
                 <td>&nbsp;LinkedIn :</td>
                 <td>&nbsp;Current: <b>&nbsp;{this.state.customizedColumns.length==0?<b>LinkedIn</b>:this.state.customizedColumns[0].linkedIn}</b><br/></td>
                 <td></td>
                      </tr>

:''}  
                     </tbody>
 )}
 )}
</table>    
                          </div>
                        </div>

                    
                     </CardBody>
                     </div>   
                      }  */}

				{/* <br/>{this.state.campaignBriefCustomQuestionDetails.length>0?

<div id="myTable2"  style={{display:this.state.display}}> */}
				{/* <thead>
                            <tr class="info" > */}
				{/* <div style={{ fontSize:"large", color :"#124E9C" ,fontWeight :"bold"}}>Custom Questions :</div> */}
				{/* </tr>
                          </thead> */}
				{/* <div class="card" style={{backgroundColor:"#f8f8f8",     borderColor: "#707070", borderWidth: "0.3px"}}> */}

				{/* <CardBody>
                         
                          {this.state.campaignBriefCustomQuestionDetails.map(
                          (campaignBriefCustomQuestionDetails,i) => (


                              <div class="row">
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">&nbsp;Custom Question {i+1}:</label>
                                &nbsp;
                                <textarea
                                  id="customQuestion1"
                                  class="form-control"
                                  name="customQuestion1"
                                  value={unescape(campaignBriefCustomQuestionDetails.customQuestion)
                                  }
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                               <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">Answer {i+1}:</label>
                                &nbsp;
                                <textarea
                                  id="answer1"
                                  class="form-control"
                                  name="answer1"
                                  value={unescape(campaignBriefCustomQuestionDetails.answer)
                                  }
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-4">
                                    {" "}
                                    <label id="label">Not Allowed Answer {i+1}:</label>
                                    &nbsp;
                                    <textarea
                                      id="nonallowedanswer"
                                      class="form-control"
                                      name="nonallowedanswer"
                                      value={unescape(campaignBriefCustomQuestionDetails.disAllowAnswer)
                                      }
                                      onChange={this.handleChangeCustomQuestion}
                                    />
                                  </div>
                            </div>
                          )
                        )}
                        </CardBody> */}
				{/* </div>
                        </div>:''}
                         */}
				{/* {this.state.campaignBriefCustomQuestionDetails.map(
                          (campaignBriefCustomQuestionDetails,i) => (


                              <div class="row">
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">&nbsp;Custom Question {i+1}:</label>
                                &nbsp;
                                <textarea
                                  id="customQuestion1"
                                  class="form-control"
                                  name="customQuestion1"
                                  value={unescape(campaignBriefCustomQuestionDetails.customQuestion)
                                  }
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                               <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">Answer {i+1}:</label>
                                &nbsp;
                                <textarea
                                  id="answer1"
                                  class="form-control"
                                  name="answer1"
                                  value={unescape(campaignBriefCustomQuestionDetails.answer)
                                  }
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-4">
                                    {" "}
                                    <label id="label">Not Allowed Answer {i+1}:</label>
                                    &nbsp;
                                    <textarea
                                      id="nonallowedanswer"
                                      class="form-control"
                                      name="nonallowedanswer"
                                      value={unescape(campaignBriefCustomQuestionDetails.disAllowAnswer)
                                      }
                                      onChange={this.handleChangeCustomQuestion}
                                    />
                                  </div>
                            </div>
                          )
                        )} */}
				<br />
				{/*row 6x */}
				{/* </div> */}
				{/* // border */}
				{/* </div> */}
				{/* // col-lg-12 */}

				<Drawer
					title="Basic Drawer"
					placement="right"
					closable={false}
					onClose={this.onClose}
					visible={this.state.visible}
					width={350}>
					<p style={{ fontSize: "20px", fontWeight: "500", marginLeft: "7px" }}>
						Messages
					</p>{" "}
					{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(shivani) */}
					<MDBBtn
						style={{ left: "270px", bottom: "43px" }}
						onClick={this.onClose}>
						&times;
					</MDBBtn>
					<p>
						{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(shivani) */}
						{/* <b><a href="#"
                    id="boradcastMsg"
                    name="boradcastMsg"
                    onClick={this.broadCastMessage} 
                    style={{color:this.state.Broadcastcolor,marginLeft:'7px'}}
                    >
                    BroadCast Message
          </a> </b> */}

						{this.state.publisherList.map((publisherList) => (
							<div>
								<a
									href="#"
									id={publisherList.campID}
									name={publisherList.pID}
									onClick={this.handleChangePublisherMessages}
									style={{ color: this.state.pubColor }}>
									{publisherList.publisherName}

									{/* {publisherList.pID==this.state.pID?<b>{publisherList.publisherName}</b>:<span>{publisherList.publisherName}</span>} */}
								</a>
								&nbsp;
								{publisherList.unreadCount === undefined ||
								publisherList.unreadCount === null ? (
									"(0)"
								) : publisherList.unreadCount > 0 ? (
									<span>({publisherList.unreadCount})</span>
								) : (
									""
								)}
								{/* </div> */}
							</div>
						))}
					</p>
					<p>
						{" "}
						<Comment
							// avatar={
							//   <Avatar
							//     src="DI.png"
							//     alt="Demand Integrate"
							//   />
							// }
							content={
								<Editor
									onChange={this.handleChange}
									onSubmit={this.handleSubmit}
									submitting={submitting}
									value={value}
								/>
							}
						/>
						<p
							style={{
								fontSize: "20px",
								fontWeight: "500",
								marginLeft: "7px",
							}}>
							History
						</p>{" "}
						{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(shivani) */}
						{/* <p style={{overflowY:"scroll",height:"200px"}}>{comments.length > 0 && <CommentList comments={comments} />}</p> */}
						<p style={{ overflowY: "scroll", height: "137px" }}>
							{this.state.comments && this.state.comments.length>0?
								this.state.comments.map((comments) => (
								<div>
									<Avatar
										style={{ color: "#ffffff", backgroundColor: "#144999" }}>
										{comments.avatar}
									</Avatar>
									&nbsp; <b>{comments.author}</b>&nbsp;&nbsp;
									<span style={{ fontSize: "10px" }}>{comments.datetime}</span>
									&nbsp;&nbsp;&nbsp;&nbsp;
									{comments.status == "Unread" ? (
										<b>
											<p style={{ textIndent: "50px", color: "#3a1e5f" }}>
												{comments.content}
											</p>
										</b>
									) : (
										<p style={{ textIndent: "50px" }}>{comments.content}</p>
									)}
								</div>
							)):""}
						</p>
					</p>
				</Drawer>

				<Container>
					{/* <Button
                tooltip="Contact Publisher"
                 styles={{backgroundColor: darkColors.lighterRed, color: lightColors.white}}
                icon="fa fa-user"
                onClick={this.contactPublisher}
                
                />
            <Button
                tooltip="BroadCast"
                 styles={{backgroundColor: darkColors.lighterRed, color: lightColors.white}}
                icon="fa fa-rss"
                onClick={this.showDrawer} 
                /> */}
					<Button
						tooltip="Chat With Us!"
						styles={{
							background: "linear-gradient(to right,#28166f,#007cc3)",
							color: lightColors.white,
							float: "right",
						}}
						icon="fa fa-comments-o fa-3x"
						//rotate={true}
						onClick={this.showDrawer}
					/>{" "}
					{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher(shivani) */}
				</Container>

				{/* <Widget 
                    handleNewUserMessage={this.handleNewUserMessage}
                     profileAvatar="DI.png"
                    title="Demant Integrate"
                    // subtitle="And my cool subtitle"
                    /> */}

				{/* <Launcher
        agentProfile={{
          teamName: 'Demand Integrate',
          imageUrl: 'DI.png',
          height:"40px",width:"50px"
        }}
        onMessageWasSent={this._onMessageWasSent.bind(this)}
        messageList={this.state.messageList}
        showEmoji
      /> */}
			</div>
		);
	}
} // end of class CampaignSpecificationDashboardDetails

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CampaignSpecificationDashboardDetails.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(CampaignSpecificationDashboardDetails)
);
// export default CampaignSpecificationDashboardDetails;
