/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Shivani Pathak
*@fileName :
 *Desc: contain array of job titles
*/

module.exports.jobTitleOptions =[
//     "VP Technology", 
//     "VP of Application", 
//     "IT Manager",
//     "Senior IT Manager", 
//    "IT Security Manager",
//    "Head of IT", 
//     "Head of Analytics", 
//     "Head of Infrastructure",
//     "Head-Cloud",
//     "IT Director", 
//     "IT Security Director", 
//     "Software Developer", 
//     "Graphics Designer", 
];
module.exports.jobTitleOptionsForVP =[
    "VP Technology", 
    "VP of Application", 
    
];

module.exports.jobTitleOptionsForManager =[
    "IT Manager",
    "Senior IT Manager", 
   "IT Security Manager",
];
module.exports.jobTitleOptionsForHead =[
    "Head of IT", 
    "Head of Analytics", 
    "Head of Infrastructure",
    "Head-Cloud",
    
];
module.exports.jobTitleOptionsForDirector =[
    "IT Director", 
    "IT Security Director", 
   
];
module.exports.jobTitleOptionsForStaff =[
    "Software Developer", 
    "Graphics Designer", 
   
];
module.exports.jobTitleOptionsForClevel =[
    "CEO","CFO","COO","CIO",
];