/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author :Sonali Kalke
*@fileName :advertiserInformation.js
 *Desc: Advertiser Info  
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom';
import Footer from '../layouts/footer';
import Navigation from '../layouts/navPage';
import { Tooltip } from 'antd'; //Aman-4749-(removed 'Card,Steps' not in use)-Console Warnings - Advertiser - Settings
//import { ThemeConsumer } from 'styled-components'; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
//import { MDBBtn, CardBody } from "mdbreact"; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //kiran- task 3867

//const Swal = require('sweetalert2'); //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings


class AdvertiserInformation extends React.Component {
    constructor() {
        super();
        this.state ={
            advertiserContactDetails:[],
            advertiserInfo:[],
            AdvertiserID:'',
            AdvertiserName:'',
            advertiserID:''
        }
        this.handleEdit=this.handleEdit.bind(this);
    }//end of constructor
    handleEdit(e){
        e.preventDefault();
       // const {isAuthenticated,user} = this.props.auth; //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
        //var advertiserID=user.id;//Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings
        //alert("campid==="+pID)
        // window.location.href='/advertiserInformationEdit?advertiserID='+user.id; 
        this.props.history.push("/advertiserInformationEdit") //kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE
      
    }
    componentDidMount()
{
    if(!this.props.auth.isAuthenticated) {
        this.props.history.push('/userLogin');
      }
      else{
   
      //var parsed=this.props.location.state;  //kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE //Aman-4749-(commented not in use)-Console Warnings - Advertiser - Settings

      const {user} = this.props.auth;
     
     var advertiserID=user.id;

      let data={
        // advertiserID:user.id
      };
      this.setState({advertiserID:advertiserID})

        fetch("/advertiserOnBoardDetails/advertiserDetails",{
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify(data)
        }).then(res=>res.json())
        .then(advertiserInfo=>{
            this.setState({advertiserInfo:advertiserInfo,AdvertiserName:advertiserInfo[0].advertiserName,AdvertiserID:advertiserInfo[0].advertiserID})
        }).catch(function (err) {console.log(err)});

      
  
      fetch("/advertiserOnBoardDetails/advertiserContactDetails", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      }).then(res => res.json())
      .then(response=> { 

        this.setState({advertiserContactDetails:response});
       // alert("advertiserContactDetails"+JSON.stringify(this.state.advertiserContactDetails));
        })
   
        }
}// end of componentDidMount


    render() {
        const {user} = this.props.auth;
        return (<div>
  <Navigation />


        <div class="container-fluid" style={{ paddingTop: '100px' ,paddingBottom:'100px'}}>

           
  <div class="row">
              <div class="col-md-3">
              {/*Aman-4749-(href attribute is required added "#/")-Console Warnings - Advertiser - Settings */}
              <a href={"#/"} onClick={(e) => {
                e.preventDefault(); //Aman-4821-(added preventDefault)-Advertiser dashboard shows in URL#/
                this.props.history.push("/advertiserDashboard", {
                    advertiserID: this.state.advertiserID,      //Priyanka--4038--removed data from url
                })}
            }  style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Dashboard"/></a>

                 </div>
              <div class="col-md-9">
                  {/* shivani-3310-passed ID "labelDI" for label consistency */}
        <label id="labelDI" style={{marginLeft:'100px'}}>{this.state.AdvertiserName}(Advertiser ID:{this.state.AdvertiserID})</label>
      </div>
      </div>
      <br/>
      
        <div style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}
        >Advertiser Information:
        {/*Aman-4749-(href attribute is required added "#/" and added alt="" in img)-Console Warnings - Advertiser - Settings */}
              {user.role==="ADV"?
        <a href={"#/"}><Tooltip title="Edit"><img class="float-right" src="NewEdit.png" style={{height:"20px",width:"20px",float:"right",marginRight:'15px'}} onClick={this.handleEdit} alt=""/></Tooltip></a>:''}
        </div>
        <br/>
        {this.state.advertiserInfo.map(advertiserInfo => {
                  return (
        <div class="row">
         
            <div class="col-xs-12  col-sm-12 col-md-12 col-lg-3">
            <label>Email</label>:&nbsp;&nbsp;{advertiserInfo.email}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-12 col-lg-3">
            <label>Website</label>:&nbsp;&nbsp;{advertiserInfo.website}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>Country Code</label>:&nbsp;&nbsp;{advertiserInfo.countryCode}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>Phone</label>:&nbsp;&nbsp;{advertiserInfo.phone}
            </div>
                
            
        </div>
                  )})}
         {/* end of 1st row */}
         <br/>

       {this.state.advertiserInfo.map(advertiserInfo => {
                  return (
        <div class="row">
            
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>Country</label>:&nbsp;&nbsp;{advertiserInfo.country}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>State</label>:&nbsp;&nbsp;{advertiserInfo.state}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>City</label>:&nbsp;&nbsp;{advertiserInfo.city}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>Zip Code</label>:&nbsp;&nbsp;{advertiserInfo.zipcode}
            </div>
                
            
        </div>
                  )})}
         {/* end of 2st row*/}
         <br/>  


         {this.state.advertiserInfo.map(advertiserInfo => {
                  return (
        <div class="row">
            
            <div class="col-xs-12  col-sm-12 col-md-9 col-lg-3">
            <label>Time Zone</label>:&nbsp;&nbsp;{advertiserInfo.timezone}
            </div>
           
            <div class="col-xs-12  col-sm-12 col-md-9 col-lg-9">
            <label>Address</label>:&nbsp;&nbsp;{advertiserInfo.address}
            </div>
                
            
        </div>
                  )})}
         {/* end of 3st row */}
         <br/>

         
         {this.state.advertiserInfo.map(advertiserInfo => {
                  return (
        <div class="row">
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>Rating</label>:&nbsp;&nbsp;{advertiserInfo.rating}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>GDPR Compliance</label>:&nbsp;&nbsp;{advertiserInfo.gdprCompliance}
            </div>
         
        </div>
                  )})}
         {/* end of 4th row */}
         <br/>
         <hr/>

         <div style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}
        >Delivery Channel:
        </div>

         <table class='table table-bordered' id="myTable3">
                                <thead >
                                    <tr style={{ height: '35px' }}>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Email</th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Telemarketing </th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Display</th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Programmatic</th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Socail </th>
                                       
                                    </tr>
                                </thead>

                         
                                <tbody>
                                {this.state.advertiserInfo.map(advertiserInfo => {
                  return (
                                <tr>
                                    <td>{advertiserInfo.dcEmail}</td>
                                    <td>{advertiserInfo.dcTelemarketing}</td>
                                    <td>{advertiserInfo.dcDisplay}</td>
                                    <td>{advertiserInfo.dcProgrammatic}</td>
                                    <td>{advertiserInfo.dcSocial}</td>
                                   
                                </tr>
                  )})}
                            </tbody>
                            </table>
       


         {/* {this.state.advertiserInfo.map(advertiserInfo => {
                  return (
        <div class="row">
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Delivery Channel Display</label>:&nbsp;&nbsp;{advertiserInfo.dcDisplay}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Delivery Channel Programmatic</label>:&nbsp;&nbsp;{advertiserInfo.dcProgrammatic}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Delivery Channel Social</label>:&nbsp;&nbsp;{advertiserInfo.dcSocial}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Logo</label>:&nbsp;&nbsp;{advertiserInfo.dcTelemarketing}
            </div>
                
            
        </div>
                  )})} */}


         {/* {this.state.advertiserInfo.map(advertiserInfo => {
                  return (
        <div class="row">
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Delivery Channel Display</label>:&nbsp;&nbsp;{advertiserInfo.dcDisplay}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Delivery Channel Programmatic</label>:&nbsp;&nbsp;{advertiserInfo.dcProgrammatic}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Delivery Channel Social</label>:&nbsp;&nbsp;{advertiserInfo.dcSocial}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Logo</label>:&nbsp;&nbsp;{advertiserInfo.logo}
            </div>
                
            
        </div>
                  )})}
         {/* end of 5th row */}
        
         {/* <div style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}
        >Advertiser Bank Information:
        </div>
        <br/> */}
         {/* {this.state.advertiserInfo.map(advertiserInfo => {
                  return (
        <div class="row">
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>Bank Number</label>:&nbsp;&nbsp;{advertiserInfo.bankName}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>Account Number</label>:&nbsp;&nbsp;{advertiserInfo.accountNumber}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>Account Holder Name</label>:&nbsp;&nbsp;{advertiserInfo.accountHolderName}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>IFSC Code</label>:&nbsp;&nbsp;{advertiserInfo.ifscCode}
            </div>
                
            
        </div>
                  )})} */}
         {/* end of 6th row */}
         {/* <br/>

         

         {this.state.advertiserInfo.map(advertiserInfo => {
                  return (
        <div class="row">
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>Pan Number</label>:&nbsp;&nbsp;{advertiserInfo.panNumber}
            </div>
            <div class="col-xs-12  col-sm-12 col-md-3 col-lg-3">
            <label>GST Number</label>:&nbsp;&nbsp;{advertiserInfo.gstNumber}
            </div>
          
            
        </div>
                  )})} */}
         {/* end of 6th row */}
         <br/>

<hr/>
         <div style={{ fontSize: "large", color: "#124E9C", fontWeight: "bold" }}
        >Advertiser Contact Information:
        </div>
        <br/>

        <table class='table table-bordered' id="myTable3">
                                <thead >
                                    <tr style={{ height: '35px' }}>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Contact Id</th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;First Name </th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;LastName</th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Designation</th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Email </th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Country Phone Code</th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Phone number</th>
                                        <th style={{ color: '#707070', backgroundColor: ' rgba(126, 127, 129, 0.22)' }}>&nbsp;Role</th>
                                    </tr>
                                </thead>

                         
                                <tbody>
                                {this.state.advertiserContactDetails.map(advertiserContactDetails => {
                  return (
                                <tr>
                                    <td>{advertiserContactDetails.contactID}</td>
                                    <td>{advertiserContactDetails.firstName}</td>
                                    <td>{advertiserContactDetails.lastName}</td>
                                    <td>{advertiserContactDetails.designation}</td>
                                    <td>{advertiserContactDetails.email}</td>
                                    <td>{advertiserContactDetails.countryPhoneCode}</td>
                                    <td>{advertiserContactDetails.phoneNo}</td>
                                    <td>{advertiserContactDetails.role}</td>
                                </tr>
                  )})}
                            </tbody>
                            </table>
                            <br/>
        {/* {this.state.advertiserContactDetails.map(advertiserContactDetails => {
                  return (
        <div class="row">
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Contact Id</label>:&nbsp;&nbsp;{advertiserContactDetails.contactID}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>First Name</label>:&nbsp;&nbsp;{advertiserContactDetails.firstName}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Last Name</label>:&nbsp;&nbsp;{advertiserContactDetails.lastName}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Designation</label>:&nbsp;&nbsp;{advertiserContactDetails.designation}
            </div>
                
            
        </div>
                  )})}
         {/* end of 7th row 
         <br/>

         {this.state.advertiserContactDetails.map(advertiserContactDetails => {
                  return (
        <div class="row">
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Email</label>:&nbsp;&nbsp;{advertiserContactDetails.email}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Country Phone Code</label>:&nbsp;&nbsp;{advertiserContactDetails.countryPhoneCode}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Phone Number</label>:&nbsp;&nbsp;{advertiserContactDetails.phoneNo}
            </div>
            <div class="col-xs-3  col-sm-3 col-md-3 col-lg-3">
            <label>Role</label>:&nbsp;&nbsp;{advertiserContactDetails.role}
            </div>
                
            
        </div>
                  )})}
         {/* end of 8th row 
         <br/> */}


        </div>
        {/* end of container */}

  <Footer />

        </div>)
        }
    }// end of class

    /**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      AdvertiserInformation.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})


export default connect(mapStateToProps, { logoutUser })(withRouter(AdvertiserInformation));