/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file ApproveOnBoaredAgency
 * @author Sanjana Godbole
 * @version 1.0
 * @section use for admin to approve Agency
 **/

import React from "react";
import AdminNavpage from "../layouts/adminNavpage.js";
import Footer from "../layouts/footer";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // Somnath Task-3930-added default header
// const queryString = require("query-string");  //Nilesh-4754-Removing console warnings
const Swal = require("sweetalert2");
// var str = []; //Nilesh-4754-Removing console warnings
// var newstr = []; //Nilesh-4754-Removing console warnings
let gdprnamesArray = [];

class ApproveOnBoaredAgency extends React.Component {
	constructor() {
		super();

		this.state = {
			basicInfo: [],
			contactInfo: [],
			bankInfo: [],
			gdprInfo: [],
			caslInfo: [],
			successMessage: "",
			newfailure: "",
			buttonDisplay: "btn add-button",
			Color: "#2196F3",
			bcolor: "#2196F3",
			gdprnames: [],
		};
		this.handleBackButton = this.handleBackButton.bind(this);
		this.handleApproveButton = this.handleApproveButton.bind(this);
		this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
	}

	handleBackButton(e) {
		window.location.href = "/reviewAgency";
	}

	handleDownload(e) {
		e.preventDefault();
		var parsed = this.props.location.state; //Somnath Task-3930-replace query params
		var orgID = parsed.orgID;
		fetch("/admin/handleGdprFiles?orgID=" + orgID)
			.then((r) => r.blob())
			.then((downloadFilesData) =>
				this.setState({ downloadFilesData: downloadFilesData })
			)
			.then(this.handleFileShowDownload);
	}
	handleDownloadCasl(e) {
		e.preventDefault();
		var parsed = this.props.location.state; //Somnath Task-3930-replace query params
		var orgID = parsed.orgID;

		fetch("/admin/handlecaslFiles?orgID=" + orgID)
			.then((r) => r.blob())
			.then((downloadFilesData) =>
				this.setState({ downloadFilesData: downloadFilesData, orgID: orgID })
			)
			.then(this.handleFileShowDownload);
	}
	handleFileShowDownload(blob) {
		let zipFileDownloadName =
			"Associated-Documents-" + this.state.orgID + ".zip";

		var newBlob = new Blob([this.state.downloadFilesData], {
			type: "application/zip",
		});

		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}
		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();

		// ));
	}

	handleApproveButton(e) {
		var orgID = e.target.id;
		//const {user} = this.props.auth;
		let data = {
			orgID: orgID,
			// user:user
		};
		this.setState({
			buttonDisplay: "disabled",
			color: "#827d7d",
			bcolor: "#827d7d",
		});

		fetch("/admin/approveAgency", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				console.warn("Response in res in==" + JSON.stringify(res));
				if (res.success === true) { //Nilesh-4754-Removing console warnings
					var successMessage = res.message;
					var email = res.email;
					this.setState({ successMessage: successMessage, email: email });
					Swal.fire({
						title: successMessage + "(Agency ID:" + this.state.orgID + ")",
						text: "Login Details are sent to " + email,
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							//window .location.reload();
							window.location.href = "/reviewAgency";
						},
					});
				}
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	componentDidMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			var parsed = this.props.location.state; //Somnath Task-3930-replace query params
			var orgID = parsed.orgID;
			this.setState({ orgID: orgID });

			fetch("admin/getAgencyInfo?orgID=" + orgID)
				.then((res) => res.json())
				.then((basicInfo) => {
					this.setState({ basicInfo: basicInfo });
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("/admin/getContactInfo?orgID=" + orgID)
				.then((res) => res.json())
				.then((contactInfo) => {
					this.setState({ contactInfo: contactInfo });
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("/admin/getBankInfo?orgID=" + orgID)
				.then((res) => res.json())
				.then((bankInfo) => {
					this.setState({ bankInfo: bankInfo });
				})
				.catch(function (err) {
					console.log(err);
				});

			fetch("/admin/getGDPRInfo?orgID=" + orgID)
				.then((res) => res.json())
				.then((gdprInfo) => {
					var caslInfo=[];//Somnath Task-3930, Get CASL info from GDPRInfo
					if(gdprInfo && gdprInfo.length>0){
						caslInfo=gdprInfo.filter((o)=>o.complianceName==="CASL")
					}
					this.setState({ gdprInfo,caslInfo});

					if (this.state.gdprInfo && this.state.gdprInfo.length) {
						for (var j = 0; j < this.state.gdprInfo.length; j++) {
							var str = this.state.gdprInfo[j].documentName;
							var qID = this.state.gdprInfo[j].qID;
							var str_array = str.split(",");
							for (var i = 0; i < str_array.length; i++) {
								gdprnamesArray.push({ qID: qID, documentName: str_array[i] });
							}
							this.setState({ gdprnames: gdprnamesArray });
						} // end of   for(var j=0;j<this.state.gdprInfo.length;j++)
					} // end of    if (this.state.gdprInfo && this.state.gdprInfo.length)					
				})
				.catch(function (err) {
					console.log(err);
				});

			// fetch("/admin/getCASLInfo?orgID=" + orgID)
			// 	.then((res) => res.json())
			// 	.then((caslInfo) => {
			// 		this.setState({ caslInfo: caslInfo });
			// 	})
			// 	.catch(function (err) {
			// 		console.log(err);
			// 	});
		}
	}

	render() {
		return (
			<div>
				<AdminNavpage />

				<div
					class="row"
					style={{
						paddingTop: "90px",
						paddingBottom: "6px",
						backgroundColor: "white",
						width: "100%",
						position: "fixed",
						zIndex: "1",
					}}>
					<div class=" col-lg-4">
						<div class="float-left">
							&nbsp;&nbsp;&nbsp;&nbsp;
							<FaArrowAltCircleLeft
								size={32}
								// style={{ float: "left" }}
								title="Back To Review Agency"
								onClick={this.handleBackButton}
								style={{ color: "#056eb8", float:'left !important'}} //Nilesh-4754-Removing console warnings
							/>
						</div>
					</div>

					<div class="col-lg-4">
						{" "}
						<center>
							<h4 style={{ color: "#056EB8", fontSize: "20px" }}>
								Review Agency Details
							</h4>
						</center>
					</div>
					<div class=" col-lg-4">
						<div class="float-right">
							<button
								class="btn add-button"
								type="submit"
								className={this.state.buttonDisplay}
								onClick={this.handleApproveButton}
								id={this.state.orgID}
								form="">
								Approve
							</button>
						</div>
					</div>
				</div>
				<br />
				<div
					className="container-fluid"
					style={{ paddingTop: "90px", paddingBottom: "60px" }}>
					<div class="row" style={{ paddingTop: "30px" }}>
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<div className="legend labelsubheading">
								<h4 style={{ color: "orange" }}>Agency Information</h4>
								<hr />
							</div>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<table class="table table-bordered table-striped">
								<thead>
									<tr class="custom-class">
										<th>Agency Name</th>
										<th>Website</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Country</th>
										<th>State</th>
										<th>City</th>
										<th>Zip/Postal Code</th>
										<th>Delivery Channel</th>
										<th>Sub-Contracting</th>
									</tr>
								</thead>
								<tbody>
									{this.state.basicInfo.map((basicInfo) => (
										<tr>
											<td> {basicInfo.agencyName} </td>
											<td> {basicInfo.website} </td>
											<td> {basicInfo.email} </td>
											<td> {basicInfo.phone} </td>
											<td> {basicInfo.country} </td>
											<td> {basicInfo.state} </td>
											<td> {basicInfo.city} </td>
											<td> {basicInfo.zipcode} </td>
											<td>
												{" "}
											    {basicInfo.dcTelemarketing === "Yes" ? ( //Nilesh-4754-Removing console warnings
													<span style={{ fontWeight: "bold" }}>
														Telemarketing &nbsp;
													</span>
												) : (
													""
												)}
												{basicInfo.dcEmail === "Yes" ? (   //Nilesh-4754-Removing console warnings
													<span style={{ fontWeight: "bold" }}>
														E-Mail &nbsp;
													</span>
												) : (
													""
												)}
												{basicInfo.dcSocial === "Yes" ? (  //Nilesh-4754-Removing console warnings
													<span style={{ fontWeight: "bold" }}>
														Social &nbsp;
													</span>
												) : (
													""
												)}
												{basicInfo.dcDisplay === "Yes" ? (  //Nilesh-4754-Removing console warnings
													<span style={{ fontWeight: "bold" }}>
														Display &nbsp;
													</span>
												) : (
													""
												)}
												{basicInfo.dcProgrammatic === "Yes" ? ( //Nilesh-4754-Removing console warnings
													<span style={{ fontWeight: "bold" }}>
														Programmatic &nbsp;
													</span>
												) : (
													""
												)}{" "}
											</td>
											{/* User Story : 3188 : Karan Jagtap : Sub-Contracting value shown */}
											<td>
												{basicInfo.subContracting === undefined ||
												basicInfo.subContracting === null ||
												basicInfo.subContracting === "undefined" ||
												basicInfo.subContracting === "null" ||
												basicInfo.subContracting === ""
													? ""
													: basicInfo.subContracting}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>

					{/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
					<div class="row" style={{ paddingTop: "30px" }}>
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<div className="legend labelsubheading">
								<h4 style={{ color: "orange" }}>Contact Information</h4>
								<hr />
							</div>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<table class="table table-bordered table-striped">
								<thead>
									<tr class="custom-class">
										<th>Contact Person</th>
										<th>Designation </th>
										<th>Email</th>
										<th>Phone Number</th>
									</tr>
								</thead>
								<tbody>
									{this.state.contactInfo.map((contactInfo) => (
										<tr>
											<td>
												{" "}
												{contactInfo.firstName} {contactInfo.lastName}{" "}
											</td>
											<td> {contactInfo.designation} </td>
											<td> {contactInfo.email} </td>
											<td> {contactInfo.phoneNo} </td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>

					{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

					{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

					<div className="legend labelsubheading">
						<h4 style={{ color: "orange" }}>Global Compliance</h4>
						<hr />
					</div>
					{/* <div class="row">
             <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <label id="label">GDPR:</label>
                   
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <label id="label">CASL:</label>
                  
              </div>
             
            </div>
            {/* end of 1st row 
        <br/> */}

					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<table class="table table-bordered table-striped">
								<thead>
									<tr class="custom-class">
										<th>GDPR Question ID</th>
										<th>GDPR Requirement Questionnaire:</th>
										<th>Details</th>
										<th>
											Associated Document&nbsp;&nbsp;
											<div class="tip">
												<span style={{ color: "Green", fontWeight: "bold" }}>
													{" "}
													<a 
													href="#/" //Nilesh-4754-Removing console warnings
													>
														<i
															class="fa fa-download fa-lg"
															style={{ color: "white" }}
															onClick={this.handleDownload.bind(this)}></i>
													</a>
												</span>
												<span class="tooltiptextright">Download</span>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{this.state.gdprInfo.map((gdprInfo) => (
										<tr>
											<td> {gdprInfo.qID} </td>
											<td> {gdprInfo.questions} </td>
											<td>{gdprInfo.answer} </td>

											<td>
												{this.state.gdprnames.map((gdprnames) => {
													if (gdprnames.qID === gdprInfo.qID) {
														return (
															<li style={{ listStyleType: "none" }}>
																{" "}
																{gdprnames.documentName}
															</li>
														);
													}else {
														return null;  //Nilesh-4754-Removing console warnings
													  }
												})}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>

					{/* end of 2st row */}
					<br />
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<table class="table table-bordered table-striped">
								<thead>
									<tr class="custom-class">
										<th>CASL Requirement Questionnaire:</th>
										<th>Details</th>
										<th>
											Associated Document &nbsp;&nbsp;
											<div class="tip">
												<span style={{ color: "Green", fontWeight: "bold" }}>
													{" "}
													<a href="#/" //Nilesh-4754-Removing console warnings
													>
														<i
															class="fa fa-download fa-lg"
															style={{ color: "white" }}
															onClick={this.handleDownloadCasl.bind(this)}></i>
													</a>
												</span>
												<span class="tooltiptextright">Download</span>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{this.state.caslInfo.map((caslInfo) => (
										<tr>
											<td> {caslInfo.questions} </td>
											<td>{caslInfo.answer} </td>
											<td>{caslInfo.documentName}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
					{/* end of 3rd row */}
					<br />
					{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

					<div class="row" style={{ paddingTop: "30px" }}>
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<div className="legend labelsubheading">
								<h4 style={{ color: "orange" }}>Bank Details</h4>
								<hr />
							</div>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<table class="table table-bordered table-striped">
								<thead>
									<tr class="custom-class">
										<th>Bank Name</th>
										<th>Account Number </th>
										<th>Accountholder Name</th>
										<th>IFSC Number</th>
										<th>PAN/TAN Number</th>
										<th>TAX/GST Number</th>
									</tr>
								</thead>
								<tbody>
									{this.state.bankInfo.map((bankInfo) => (
										<tr>
											<td> {bankInfo.bankName} </td>
											<td>{bankInfo.accountNumber} </td>
											<td>{bankInfo.accountHolderName}</td>
											<td> {bankInfo.ifscCode} </td>
											<td>{bankInfo.panNumber} </td>
											<td>{bankInfo.gstNumber} </td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>

					{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
				</div>
				{/* //end of container */}
				<Footer />
			</div>
			//end of main div
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
ApproveOnBoaredAgency.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(ApproveOnBoaredAgency)
);
