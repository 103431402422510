
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Sanobar Golandaj
@Description:UI for agency login
*/

import React from 'react'; //Rutuja 4796 removed {component} as never used
// import {Table} from 'mdbreact'; //Rutuja 4796 commented as never used
import AdminNavpage from '../layouts/adminNavpage.js';
import './admin.css';
import Footer from '../layouts/footer';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../login/authentication';
import { withRouter } from 'react-router-dom'; 
//import { FaArrowAltCircleLeft } from "react-icons/fa";//Rutuja 4796 commented as never used
//import Picky from "react-picky";//Rutuja 4796 commented as never used
import {  Select } from 'antd'; //Rutuja 4796 removed Form, Icon, Input, Button, Checkbox,Card , as never used
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3927-VAPT URL changes
//const Swal = require('sweetalert2');  //Rutuja 4796 commented as never used

var selectedRole;
class ViewUserPassword extends React.Component {  
    constructor(props) {  
        super(props);  
        this.state=
        {
            inputClass11:'btn add-button',
            email:'',
            roles:[],
            userInfo:[],
            password:'',
            displayDropdown:"none",
            displayPassword:"none",
            errors: {},
            userEmailExist:[]
           
        }
        this.handleChange = this.handleChange.bind(this);
        this.viewUserPassword = this.viewUserPassword.bind(this);
    }// end of constructor
    
    

    
         
    handleChange(e)
    {
         var role;
        selectedRole=role;
        var email=e.target.value;
        let errors={}
        errors['doesntExits']=""
        this.setState({errors:errors})
        this.setState({email:email,displayDropdown:"none",
        displayPassword:"none"});
        
    }

//Supriya: Submit User
viewUserPassword(e)
{
    
    var email=this.state.email;
    var role=selectedRole;
    let data={
        email:email,
        role:role
    }
    fetch("/admin/getUserEmailExist",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
      }).then(res => res.json())
      .then(userEmailExist=> { 
        if(userEmailExist.length>0)
        {
  fetch("/admin/getUserInfo",{
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
    }).then(res => res.json())
    .then(response=> { 
    //   if(response.length>1)
    //  {
    //   var result=Array.from(new Set(response.map(p=>p.role))).map(role=>{
    //     return{
    //       role:role,
         
    //       orgID:response.find(p=>p.role=== role).orgID,
    //       email:response.find(p=>p.role=== role).email,
         
    //     };
    //   });
    //    this.setState({roles:result});
    
    // }
     if(response.length>1)
     {
        let errors={}
        this.setState({displayDropdown:"block"})
                 
         errors['blankSubmit']="";
         errors['wrongemailId']="";
        
         if(selectedRole==null||selectedRole===undefined||selectedRole==="") //Rutuja 4796 changed == to ===
         {
          const result=Array.from(new Set(response.map(p=>p.role))).map(role=>{
            return{
              role:role,
             
              orgID:response.find(p=>p.role=== role).orgID,
              userName:response.find(p=>p.role=== role).userName,
             
            };
          });
          this.setState({roles:result});
          let errors={}
          errors['selectRole']="Please Select Role";
          this.setState({errors:errors})
         }
       
         else
         { 
            
            errors['selectRole']="";
            this.setState({errors:errors})
            this.setState({displayDropdown:"block",userEmailExist:response})
           }
         }
      
      
      else if(response.length===1)
      { 
       let errors={}
       errors['blankSubmit']=""
       errors['doesntExits']=""
       this.setState({errors:errors})
       this.setState({userInfo:response,password:response[0].password,displayPassword:"block",userEmailExist:response}) 
       }
    else if(response.length===0)  
     {
       if(this.state.email!=="")
       {
         let errors={}
         errors['blankSubmit']=""
         errors['wrongemailId']=""
         errors['doesntExits']=""
         
         this.setState({errors:errors})  
       }
       if(this.state.email==="")
       {
         let errors={}
         errors['blankSubmit']="Please Enter Email-Id."
         errors['wrongemailId']=""
         errors['doesntExits']=""
         this.setState({errors:errors})
       }
       
     }
    })
  }else{
    let errors={};
    errors['doesntExits']="Please enter valid registered Email-ID."
    errors['blankSubmit']=""
    errors['wrongemailId']=""

    this.setState({errors:errors})
  }
  })
  .catch(function (err) {
    console.log(err);
  });
      

}//handleRoleChange start
 handleRoleChange(value){
  selectedRole=value;
  if(value!=='Select Client'){
    let errors={}
    errors['selectRole']="";
    this.setState({errors:errors})
      }
  
 }//handleRoleChange End
    render() { 
      //const {user}=this.props.auth; //Rutuja 4796 commented as never used
        const { Option } = Select;
        // function onChange(value) {
        //   let errors={selectedRole:""}
        //   selectedRole=value;
        //   if(value!=='Select Client'){
        //   this.state.errors=errors; 
        //   }
       // }
        const asterisk =
        {  color: 'red',
         } 
        return ( 
            <div>
                   <AdminNavpage/> 
                   <div class="container-fluid" style={{paddingTop:'100px'}}>
      
      <div class="card-body">
        <legend className="legend labelsubheading">User Details</legend>
        <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" >
   
    <label id="label">User Name <span style={asterisk}>*</span></label>
    <br/>
    <span class="errorMessage" style={{color:'red'}}>{this.state.errors.blankSubmit}</span>
    <span class="errorMessage" style={{color:'red'}}>{this.state.errors.doesntExits}</span>
  <span class="errorMessage" style={{color:'red'}}>{this.state.errors.wrongemailId}</span>
                                  <input
                                  value={this.state.userName}
                                  defaultValue={this.state.userName}
                                  onChange={this.handleChange}
                                  type="text"
                                  id="userName"
                                  className="form-control"
                                  name="userName"
                                  style={{width:"60%",height: "60%"}}
                                  required /> 
    </div>
    {this.state.displayDropdown==="block"?
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4">
    {this.state.displayMsg} 



<span class="errorMessage" style={{color:'red'}}>{this.state.errors.selectRole}</span>
    <span style={{display:this.state.displayDropdown }}>
    <label id="label">Select Role <span style={asterisk}>*</span></label>
    <br/>
    <Select 
    showSearch
    style={{ width: 200 }}
    placeholder="Select a Role"
    optionFilterProp="children"
    onChange={this.handleRoleChange.bind(this)}
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >

<Option value="Select Client" selected disabled >Select Role</Option>
                   
                   {this.state.roles.map((roles) => {
                return ( 
                  roles.role==="ADV"?<Option value={roles.role}>Advertiser</Option>:
                  roles.role==="AC"?<Option value={roles.role}>Agency Commercial</Option>:
                  roles.role==="ANC"?<Option value={roles.role}>Agency Non-Commercial</Option>:
                  roles.role==="PC"?<Option value={roles.role}>Publisher Commercial</Option>:
                  roles.role==="PNC"?<Option value={roles.role}>Publisher Non-Commercial</Option>:
                  roles.role==="AC-ACCOUNT"?<Option value={roles.role}>Accounting</Option>:
                  roles.role==="PC-ACCOUNT"?<Option value={roles.role}>Accounting</Option>:
                  roles.role==="PQA"?<Option value={roles.role}>Publisher Quality Analyst</Option>:
                  roles.role==="AQA"?<Option value={roles.role}>Agency Quality Analyst </Option>:
                  roles.role==="Admin"?<Option value={roles.role}>Admin</Option>:''
                )})}
  </Select>
</span>
    </div>:""}
        
        {/* end of 1st row */}
        {/* <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4" >
        <span style={{display:this.state.displayPassword }}>
    <label id="label">User Password <span style={asterisk}>*</span></label>
                                  <input
                                  defaultValue={this.state.password}
                                  type="text"
                                  id="password"
                                  className="form-control"
                                  name="password"
                                  style={{width:"60%",height: "60%"}}
                                  
                                  disabled /> 
                                </span>
    </div> */}
    </div>
    <br/><br/><br/>
    <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-4" >
        <button class="btn add-button" className={this.state.inputClass11}
               onClick={this.viewUserPassword} type="submit">View Password </button>
        </div>
    </div>
    {/* end of 2nd row */}
        </div>
        
    {/*table to Display users details */}
      <table className="table table-bordered" border='2px'>
        <thead style={{backgroundColor:'#3863A0' }} >
         <tr>
        
          <th style={{width:'130px',textAlign:'center',color:'white'}}>UserID</th> 
         <th style={{width:'130px',textAlign:'center',color:'white'}}>orgID</th>
          <th style={{width:'130px',textAlign:'center',color:'white'}}>FirstName</th>
          <th style={{width:'130px',textAlign:'center',color:'white'}}>LastName</th>
          <th style={{width:'130px',textAlign:'center',color:'white'}}>UserRole</th>
           <th style={{width:'130px',textAlign:'center',color:'white'}}>Password</th> 
          </tr>
          </thead>
          <tbody>
          
         {this.state.userEmailExist.map(userEmailExist=>(
              //JSON.stringify(userEmailExist)

       <tr style={{textAlign:'center'}}>
       <td>{userEmailExist.userID}</td> 
      <td>{userEmailExist.orgID}</td>   
      <td>{userEmailExist.firstName}</td>
      <td>{userEmailExist.lastName}</td>
      <td>{userEmailExist.role}</td>
       <td>{userEmailExist.password}</td>
      
    </tr>
     ) ) }  
           </tbody>
 
           </table>
     

        {/* end of card body */}
        </div>
        {/* end of container-fluid */}
        
                   <Footer/>
            </div>
        )
    }// end of render


}

/**
       * @author Narendra Phadke
       * @param  Description handle the login authentication
       * @return Description return All details of authentication
       */
      ViewUserPassword.propTypes = {
        logoutUser: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired
    }
    
    const mapStateToProps = (state) => ({
        auth: state.auth
    })
 export default connect(mapStateToProps, { logoutUser })(withRouter(ViewUserPassword)); 