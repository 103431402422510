/* Copyright(c) 2021 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author Sunita Landge
*@fileName : agencyAdvertiserLeadtracking.js
@Creation Date:21-04-2021
 *Desc: UI for Agency View- By Advertiser - Advertiser Lead Tracking Report for story 3413
*/
import React from "react";
//import { Tabs, Tab } from 'react-bootstrap'; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import Navigation from "../layouts/navPage";
//import * as $ from "jquery"; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import "./agencyMyReports.css";
import { css } from "emotion";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
//import styled from "styled-components"; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import { Table, Checkbox } from "antd"; //Aman-4797-(remove Tooltip, Spin, not in use)-Console Warnings-Agency-Reports Part 2
//import { truncate } from "fs"; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE

const Swal = require("sweetalert2");
//const CheckboxGroup = Checkbox.Group; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2

const tableCSS = css({
  backgroundColor: "white",
  fontFamily: "sans-serif",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
    color: "white",
    fontSize: "12px",
    textAlign: "center !important",
    fontWeight: "500",
  },
  "& thead > tr >th": {
    textAlign: "center !important",
    color: "white",
  },
});
//const queryString = require('query-string'); //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2

//sunita-task-3579- added code to change the width
let z = 8000;
let a = 60;
if (a === 60) {
  //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
  z = 120 * a;
}

//sunita - 3413 - added columnReport array  for table .

//sunita-task-3579-added below class to_truncatecolumnname1 for adjusting the column and also width.
const columnsReport = [
  {
    title: (
      <span>
        PID&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "pID",
    key: "pID",
    width: "10%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.pID < b.pID) {
        return asc ? -1 : 1;
      } else if (a.pID > b.pID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname2" style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>{record.pID}</span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        CampID&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    // dataIndex: "campaignID",
    // key: "campaignID",
    dataIndex: "campID",
    key: "campID",
    // width: "15%",
    width: "12%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      // if (a.campaignID < b.campaignID) {
      //     return asc ? -1 : 1;
      // } else if (a.campaignID > b.campaignID) {
      //     return asc ? 1 : -1;
      // } else {
      //     return 0;
      // }

      if (a.campID < b.campID) {
        return asc ? -1 : 1;
      } else if (a.campID > b.campID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname2"
      // style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.campID}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Lead Interaction Date&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "leadInteractionDate",
    key: "leadInteractionDate",
    // width: "25%",
    width: "22%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.leadInteractionDate < b.leadInteractionDate) {
        return asc ? -1 : 1;
      } else if (a.leadInteractionDate > b.leadInteractionDate) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.leadInteractionDate}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        First Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "firstName",
    key: "firstName",
    // width: "28%",
    width: "15%",

    align: "left",
    sorter: (a, b) => a.firstName - b.firstName,
    render: (text, record) => (
      // <div id="to_truncatecolumnname1" style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.firstName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Last Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "lastName",
    key: "lastName",
    // width: "28%",
    width: "15%",

    align: "left",
    sorter: (a, b) => a.lastName - b.lastName,
    render: (text, record) => (
      // <div id="to_truncatecolumnname1" style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.lastName}
        </span>
        <br />
      </div>
    ),
  },
  // {
  // 	title: <span>Advertiser&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  // 	dataIndex: "clientName",
  // 	key: "clientName",
  // 	width: "8.5%",

  // 	align: "left",
  // 	sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.clientName.toUpperCase() < b.clientName.toUpperCase()) {
  //             return asc ? -1 : 1;
  //         } else if (
  //             a.clientName.toUpperCase() > b.clientName.toUpperCase()
  //         ) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  // 	render: (text, record) => (
  // 		<div id="to_truncate"style={{ width: "75px" }}>
  // 			<span style={{ fontSize: "12px" }}>
  // 			{record.clientName}
  // 			</span>
  // 			<br />
  // 		</div>
  // 	),
  // },

  {
    title: (
      <span>
        Campaign Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "campaignName",
    key: "campaignName",
    // width: "28%",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.campaignName < b.campaignName) {
        return asc ? -1 : 1;
      } else if (a.campaignName > b.campaignName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname2
      // " style={{ width: "75px", color: "#4F4F4F" }}>
      <div
        id="to_truncatecolumnname1
            "
      >
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.campaignName}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Email&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "email",
    key: "email",
    // width: "30%",
    width: "18%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.email.toUpperCase() < b.email.toUpperCase()) {
        return asc ? -1 : 1;
      } else if (a.email.toUpperCase() > b.email.toUpperCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname1"
      //  style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.email}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        LinkedIn Company Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "linkedInCompanyName",
    key: "linkedInCompanyName",
    // width: "28%",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.linkedInCompanyName < b.linkedInCompanyName) {
        return asc ? -1 : 1;
      } else if (a.linkedInCompanyName > b.linkedInCompanyName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.linkedInCompanyName === "" ||
      record.linkedInCompanyName === undefined ||
      record.linkedInCompanyName === null ||
      record.linkedInCompanyName === "undefined" ||
      record.linkedInCompanyName === "null" ? (
        ""
      ) : (
        // <div id="to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div id="to_truncatecolumnname1">
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.linkedInCompanyName}
          </span>
          <br />
        </div>
      ),
  },

  {
    title: (
      <span>
        Industry&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "industry",
    key: "industry",
    // width: "20%",
    width: "15%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.industry.toUpperCase() < b.industry.toUpperCase()) {
        return asc ? -1 : 1;
      } else if (a.industry.toUpperCase() > b.industry.toUpperCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncate1_industry"> {/*sunita-task-3579-added css for industry column merged data*/}
      //   <div id="to_truncatecolumnname2"> {/*sunita-task-3579-added css for industry column merged data*/}
      <div id="to_truncatecolumnname1">
        {" "}
        {/*sunita-task-3579-added css for industry column merged data*/}
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.industry}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Phone No.&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "workPhone",
    key: "workPhone",
    // width: "28%",
    width: "15%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.workPhone < b.workPhone) {
        return asc ? -1 : 1;
      } else if (a.workPhone > b.workPhone) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname1
      // " style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.workPhone}
        </span>
        <br />
      </div>
    ),
  },

  // {
  //     title: <span>Job Role&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "jobrole",
  //     key: "jobrole",
  //     width: "7%",

  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.jobrole < b.jobrole) {
  //             return asc ? -1 : 1;
  //         } else if (a.jobrole > b.jobrole) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div style={{ width: "75px" }}>
  //             <span style={{ fontSize: '13px', color: '#4F4F4F' }}>
  //                 {/* {record.leadAllocation} */}
  //                 {record.jobrole}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  {
    title: (
      <span>
        Job Title&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "jobTitle",
    key: "jobTitle",
    // width: "28%",
    width: "12%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobTitle < b.jobTitle) {
        return asc ? -1 : 1;
      } else if (a.jobTitle > b.jobTitle) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div style={{ width: "75px" }}>
      <div>
        <span
          id="to_truncatecolumnname1"
          style={{ fontSize: "13px", color: "#4F4F4F" }}
        >
          {/* {record.leadAllocation} */}
          {record.jobTitle}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        LinkedIn Job Title&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "linkedInJobTitle",
    key: "linkedInJobTitle",
    // width: "30%",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.linkedInJobTitle < b.linkedInJobTitle) {
        return asc ? -1 : 1;
      } else if (a.linkedInJobTitle > b.linkedInJobTitle) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.linkedInJobTitle === "" ||
      record.linkedInJobTitle === undefined ||
      record.linkedInJobTitle === null ||
      record.linkedInJobTitle === "undefined" ||
      record.linkedInJobTitle === "null" ? (
        ""
      ) : (
        // <div id="to_truncatecolumnname2" style={{ width: "75px" }}>
        <div id="to_truncatecolumnname1">
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.linkedInJobTitle}
          </span>
          <br />
        </div>
      ),
  },

  {
    title: (
      <span>
        Job Level&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "jobLevel",
    key: "jobLevel",
    // width: "28%",
    width: "14%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobLevel < b.jobLevel) {
        return asc ? -1 : 1;
      } else if (a.jobLevel > b.jobLevel) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="
      // to_truncatecolumnname1
      // " style={{ width: "75px" }}>
      <div
        id="
            to_truncatecolumnname1
            "
      >
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.jobLevel}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Job Function&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "jobFunction",
    key: "jobFunction",
    // width: "28%",
    width: "14%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobFunction < b.jobFunction) {
        return asc ? -1 : 1;
      } else if (a.jobFunction > b.jobFunction) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="
      // to_truncatecolumnname1
      // " style={{ width: "75px" }}>
      <div
        id="
            to_truncatecolumnname1
            "
      >
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.jobFunction}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Address&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "address",
    key: "address",
    // width: "30%",
    width: "12%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.address < b.address) {
        return asc ? -1 : 1;
      } else if (a.address > b.address) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div  id="to_truncatecolumnname2"style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {/* {record.leadAllocation} */}
          {record.address}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Country&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "country",
    key: "country",
    // width: "25%",
    width: "14%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.country < b.country) {
        return asc ? -1 : 1;
      } else if (a.country > b.country) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname2" style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {/* {record.leadAllocation} */}
          {record.country}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        City&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "city",
    key: "city",
    // width: "25%",
    width: "15%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.city < b.city) {
        return asc ? -1 : 1;
      } else if (a.city > b.city) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="
      // to_truncatecolumnname2
      // " style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {/* {record.leadAllocation} */}
          {record.city}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        State&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "state",
    key: "state",
    // width: "25%",
    width: "14%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.state < b.state) {
        return asc ? -1 : 1;
      } else if (a.state > b.state) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="
      // to_truncatecolumnname2
      // " style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {/* {record.leadAllocation} */}
          {record.state}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Street&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "street",
    key: "street",
    // width: "28%",
    width: "12%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.street < b.street) {
        return asc ? -1 : 1;
      } else if (a.street > b.street) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.street === "" ||
      record.street === undefined ||
      record.street === null ||
      record.street === "undefined" ||
      record.street === "null" ? (
        ""
      ) : (
        // <div style={{ width: "75px" }}>
        <div>
          <span
            id="
to_truncatecolumnname1
"
            style={{ fontSize: "13px", color: "#4F4F4F" }}
          >
            {record.street}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Zip Code&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "zipCode",
    key: "zipCode",
    // width: "25%",
    width: "12%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.zipCode < b.zipCode) {
        return asc ? -1 : 1;
      } else if (a.zipCode > b.zipCode) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="
      // to_truncatecolumnname1
      // " style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.zipCode}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Company Revenue&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "companyRevenue",
    key: "companyRevenue",
    // width: "20%",
    width: "16%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.companyRevenue < b.companyRevenue) {
        return asc ? -1 : 1;
      } else if (a.companyRevenue > b.companyRevenue) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname2" style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.companyRevenue}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Company Employee Size&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "companyEmployeeSize",
    key: "companyEmployeeSize",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.companyEmployeeSize < b.companyEmployeeSize) {
        return asc ? -1 : 1;
      } else if (a.companyEmployeeSize > b.companyEmployeeSize) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname2"style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.companyEmployeeSize}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        IP&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "ip",
    key: "ip",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.ip < b.ip) {
        return asc ? -1 : 1;
      } else if (a.ip > b.ip) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>{record.ip}</span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Asset ID&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "supportDocID",
    key: "supportDocID",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";
      if (a.supportDocID < b.supportDocID) {
        return asc ? -1 : 1;
      } else if (a.supportDocID > b.supportDocID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.supportDocID}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Custom Questions&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    // dataIndex: "customQuestionAliasName",
    // key: "customQuestionAliasName",

    dataIndex: "customQuestionAliasName",
    key: "customQuestionAliasName",

    // width: "30%",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.customQuestionAliasName < b.customQuestionAliasName) {
        return asc ? -1 : 1;
      } else if (a.customQuestionAliasName > b.customQuestionAliasName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="
      // to_truncatecolumnname2
      //  " style={{ width: "75px" }}>
      <div
        id="
            to_truncatecolumnname1
            "
      >
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.customQuestionAliasName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Extra 1&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra1",
    key: "extra1",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra1 < b.extra1) {
        return asc ? -1 : 1;
      } else if (a.extra1 > b.extra1) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra1 === "" ||
      record.extra1 === undefined ||
      record.extra1 === null ||
      record.extra1 === "undefined" ||
      record.extra1 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>

        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra1}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 2&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra2",
    key: "extra2",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra2 < b.extra2) {
        return asc ? -1 : 1;
      } else if (a.extra2 > b.extra2) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra2 === "" ||
      record.extra2 === undefined ||
      record.extra2 === null ||
      record.extra2 === "undefined" ||
      record.extra2 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra2}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 3&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra3",
    key: "extra3",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra3 < b.extra3) {
        return asc ? -1 : 1;
      } else if (a.extra3 > b.extra3) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra3 === "" ||
      record.extra3 === undefined ||
      record.extra3 === null ||
      record.extra3 === "undefined" ||
      record.extra3 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra3}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 4&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra4",
    key: "extra4",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra4 < b.extra4) {
        return asc ? -1 : 1;
      } else if (a.extra4 > b.extra4) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra4 === "" ||
      record.extra4 === undefined ||
      record.extra4 === null ||
      record.extra4 === "undefined" ||
      record.extra4 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra4}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 5&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra5",
    key: "extra5",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra5 < b.extra5) {
        return asc ? -1 : 1;
      } else if (a.extra5 > b.extra5) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra5 === "" ||
      record.extra5 === undefined ||
      record.extra5 === null ||
      record.extra5 === "undefined" ||
      record.extra5 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra5}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 6&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra6",
    key: "extra6",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra6 < b.extra6) {
        return asc ? -1 : 1;
      } else if (a.extra6 > b.extra6) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra6 === "" ||
      record.extra6 === undefined ||
      record.extra6 === null ||
      record.extra6 === "undefined" ||
      record.extra6 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra6}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 7&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra7",
    key: "extra7",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra7 < b.extra7) {
        return asc ? -1 : 1;
      } else if (a.extra7 > b.extra7) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra7 === "" ||
      record.extra7 === undefined ||
      record.extra7 === null ||
      record.extra7 === "undefined" ||
      record.extra7 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra7}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 8&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra8",
    key: "extra8",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra8 < b.extra8) {
        return asc ? -1 : 1;
      } else if (a.extra8 > b.extra8) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra8 === "" ||
      record.extra8 === undefined ||
      record.extra8 === null ||
      record.extra8 === "undefined" ||
      record.extra8 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra8}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 9&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra9",
    key: "extra9",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra9 < b.extra9) {
        return asc ? -1 : 1;
      } else if (a.extra9 > b.extra9) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra9 === "" ||
      record.extra9 === undefined ||
      record.extra9 === null ||
      record.extra9 === "undefined" ||
      record.extra9 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra9}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 10&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra10",
    key: "extra10",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra10 < b.extra10) {
        return asc ? -1 : 1;
      } else if (a.extra10 > b.extra10) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra10 === "" ||
      record.extra10 === undefined ||
      record.extra10 === null ||
      record.extra10 === "undefined" ||
      record.extra10 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra10}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 11&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra11",
    key: "extra11",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra11 < b.extra11) {
        return asc ? -1 : 1;
      } else if (a.extra11 > b.extra11) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra11 === "" ||
      record.extra11 === undefined ||
      record.extra11 === null ||
      record.extra11 === "undefined" ||
      record.extra11 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra11}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 12&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra12",
    key: "extra12",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra12 < b.extra12) {
        return asc ? -1 : 1;
      } else if (a.extra12 > b.extra12) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra12 === "" ||
      record.extra12 === undefined ||
      record.extra12 === null ||
      record.extra12 === "undefined" ||
      record.extra12 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra12}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 13&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra13",
    key: "extra13",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra13 < b.extra13) {
        return asc ? -1 : 1;
      } else if (a.extra13 > b.extra13) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra13 === "" ||
      record.extra13 === undefined ||
      record.extra13 === null ||
      record.extra13 === "undefined" ||
      record.extra13 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra13}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 14&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra14",
    key: "extra14",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra14 < b.extra14) {
        return asc ? -1 : 1;
      } else if (a.extra14 > b.extra14) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra14 === "" ||
      record.extra14 === undefined ||
      record.extra14 === null ||
      record.extra14 === "undefined" ||
      record.extra14 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra14}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 15&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra15",
    key: "extra15",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra15 < b.extra15) {
        return asc ? -1 : 1;
      } else if (a.extra15 > b.extra15) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra15 === "" ||
      record.extra15 === undefined ||
      record.extra15 === null ||
      record.extra15 === "undefined" ||
      record.extra15 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra15}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 16&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra16",
    key: "extra16",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra16 < b.extra16) {
        return asc ? -1 : 1;
      } else if (a.extra16 > b.extra16) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra16 === "" ||
      record.extra16 === undefined ||
      record.extra16 === null ||
      record.extra16 === "undefined" ||
      record.extra16 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra16}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 17&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra17",
    key: "extra17",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra17 < b.extra17) {
        return asc ? -1 : 1;
      } else if (a.extra17 > b.extra17) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra17 === "" ||
      record.extra17 === undefined ||
      record.extra17 === null ||
      record.extra17 === "undefined" ||
      record.extra17 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra17}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 18&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra18",
    key: "extra18",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra18 < b.extra18) {
        return asc ? -1 : 1;
      } else if (a.extra18 > b.extra18) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra18 === "" ||
      record.extra18 === undefined ||
      record.extra18 === null ||
      record.extra18 === "undefined" ||
      record.extra18 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra18}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 19&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra19",
    key: "extra19",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra19 < b.extra19) {
        return asc ? -1 : 1;
      } else if (a.extra19 > b.extra19) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra19 === "" ||
      record.extra19 === undefined ||
      record.extra19 === null ||
      record.extra19 === "undefined" ||
      record.extra19 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra19}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 20&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra20",
    key: "extra20",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra20 < b.extra20) {
        return asc ? -1 : 1;
      } else if (a.extra20 > b.extra20) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra20 === "" ||
      record.extra20 === undefined ||
      record.extra20 === null ||
      record.extra20 === "undefined" ||
      record.extra20 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra20}
          </span>
          <br />
        </div>
      ),
  },
  //Chaitanya-5194-Added 21-30 extra fields
  {
    title: (
      <span>
        Extra 21&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra21",
    key: "extra21",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra21 < b.extra21) {
        return asc ? -1 : 1;
      } else if (a.extra21 > b.extra21) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra21 === "" ||
      record.extra21 === undefined ||
      record.extra21 === null ||
      record.extra21 === "undefined" ||
      record.extra21 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra21}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 22&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra22",
    key: "extra22",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra22 < b.extra22) {
        return asc ? -1 : 1;
      } else if (a.extra22 > b.extra22) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra22 === "" ||
      record.extra22 === undefined ||
      record.extra22 === null ||
      record.extra22 === "undefined" ||
      record.extra22 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra22}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 23&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra23",
    key: "extra23",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra23 < b.extra23) {
        return asc ? -1 : 1;
      } else if (a.extra23 > b.extra23) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra23 === "" ||
      record.extra23 === undefined ||
      record.extra23 === null ||
      record.extra23 === "undefined" ||
      record.extra23 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra23}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 24&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra24",
    key: "extra24",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra24 < b.extra24) {
        return asc ? -1 : 1;
      } else if (a.extra24 > b.extra24) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra24 === "" ||
      record.extra24 === undefined ||
      record.extra24 === null ||
      record.extra24 === "undefined" ||
      record.extra24 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra24}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 25&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra25",
    key: "extra25",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra25 < b.extra25) {
        return asc ? -1 : 1;
      } else if (a.extra25 > b.extra25) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra25 === "" ||
      record.extra25 === undefined ||
      record.extra25 === null ||
      record.extra25 === "undefined" ||
      record.extra25 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra25}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 26&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra26",
    key: "extra26",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra26 < b.extra26) {
        return asc ? -1 : 1;
      } else if (a.extra26 > b.extra26) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra26 === "" ||
      record.extra26 === undefined ||
      record.extra26 === null ||
      record.extra26 === "undefined" ||
      record.extra26 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra26}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 27&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra27",
    key: "extra27",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra27 < b.extra27) {
        return asc ? -1 : 1;
      } else if (a.extra27 > b.extra27) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra27 === "" ||
      record.extra27 === undefined ||
      record.extra27 === null ||
      record.extra27 === "undefined" ||
      record.extra27 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra27}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 28&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra28",
    key: "extra28",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra28 < b.extra28) {
        return asc ? -1 : 1;
      } else if (a.extra28 > b.extra28) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra28 === "" ||
      record.extra28 === undefined ||
      record.extra28 === null ||
      record.extra28 === "undefined" ||
      record.extra28 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra28}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 29&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra29",
    key: "extra29",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra29 < b.extra29) {
        return asc ? -1 : 1;
      } else if (a.extra29 > b.extra29) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra29 === "" ||
      record.extra29 === undefined ||
      record.extra29 === null ||
      record.extra29 === "undefined" ||
      record.extra29 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra29}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Extra 30&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "extra30",
    key: "extra30",
    // width: "18%",
    width: "13%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.extra30 < b.extra30) {
        return asc ? -1 : 1;
      } else if (a.extra30 > b.extra30) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.extra30 === "" ||
      record.extra30 === undefined ||
      record.extra30 === null ||
      record.extra30 === "undefined" ||
      record.extra30 === "null" ? (
        ""
      ) : (
        // <div id="
        // to_truncatecolumnname2
        // " style={{ width: "75px" }}>
        <div
          id="
                to_truncatecolumnname1
                "
        >
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.extra30}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Reallocation ID&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "reAllocationID",
    key: "reAllocationID",
    width: "16%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.reAllocationID < b.reAllocationID) {
        return asc ? -1 : 1;
      } else if (a.reAllocationID > b.reAllocationID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.reAllocationID}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Domain&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "domain",
    key: "domain",
    // width: "25%",
    width: "15%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.domain < b.domain) {
        return asc ? -1 : 1;
      } else if (a.domain > b.domain) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="
      // to_truncatecolumnname2
      // " style={{ width: "75px" }}>
      <div
        id="
            to_truncatecolumnname1
            "
      >
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.domain}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Alternate Phone No&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "alternatePhoneNo",
    key: "alternatePhoneNo",
    // width: "30%",
    width: "22%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.alternatePhoneNo < b.alternatePhoneNo) {
        return asc ? -1 : 1;
      } else if (a.alternatePhoneNo > b.alternatePhoneNo) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="
      // to_truncatecolumnname1
      // "style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.alternatePhoneNo}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        LinkedIn&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "linkedIn",
    key: "linkedIn",
    // width: "25%",
    width: "15%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.linkedIn < b.linkedIn) {
        return asc ? -1 : 1;
      } else if (a.linkedIn > b.linkedIn) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname2" style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.linkedIn}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Comments&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "comments",
    key: "comments",
    // width: "28%",
    width: "18%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.comments < b.comments) {
        return asc ? -1 : 1;
      } else if (a.comments > b.comments) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname2" style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.comments}
        </span>
        <br />
      </div>
    ),
  },
  // {
  //     title: <span>Parent CampID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "parentCampID",
  //     key: "parentCampID",
  //     width: "13%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.parentCampID < b.parentCampID) {
  //             return asc ? -1 : 1;
  //         } else if (a.parentCampID > b.parentCampID) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div style={{ width: "75px" }}>
  //             <span style={{ fontSize: "13px",color:"#4F4F4F" }}>
  //                 {record.parentCampID}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },
  //   {
  //     title: <span>Lead Info ID&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "leadInfoID",
  //     key: "leadInfoID",
  //     width: "13%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.leadInfoID < b.leadInfoID) {
  //             return asc ? -1 : 1;
  //         } else if (a.leadInfoID > b.leadInfoID) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div style={{ width: "75px" }}>
  //             <span style={{ fontSize: "13px",color:"#4F4F4F" }}>
  //                 {record.leadInfoID}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  // {
  //     title: <span>Last Updated&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "lastUpdated",
  //     key: "lastUpdated",
  //     width: "15%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.lastUpdated < b.lastUpdated) {
  //             return asc ? -1 : 1;
  //         } else if (a.lastUpdated > b.lastUpdated) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div style={{ width: "75px" }}>
  //             <span style={{ fontSize: "13px",color:"#4F4F4F" }}>
  //                 {record.lastUpdated}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  {
    title: (
      <span>
        Status&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "status",
    key: "status",
    // width: "27%",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.status < b.status) {
        return asc ? -1 : 1;
      } else if (a.status > b.status) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div  style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.status}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Company Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "companyName",
    key: "companyName",
    // width: "25%",
    width: "20%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.companyName < b.companyName) {
        return asc ? -1 : 1;
      } else if (a.companyName > b.companyName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncate1_industry"style={{ width: "75px" }}> {/*sunita-task-3579-added code for company name */}
      //  <div id="to_truncatecolumnname2"style={{ width: "75px" }}> {/*sunita-task-3579-added code for company name */}
      <div id="to_truncatecolumnname1">
        {" "}
        {/*sunita-task-3579-added code for company name */}
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.companyName}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Asset Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetName",
    key: "assetName",
    // width: "27%",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetName < b.assetName) {
        return asc ? -1 : 1;
      } else if (a.assetName > b.assetName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname1" style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetName}
        </span>
        <br />
      </div>
    ),
  },

  // {
  //     title: <span>Voice Log Link&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "voiceLogLink",
  //     key: "voiceLogLink",
  //     width: "20%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.voiceLogLink < b.voiceLogLink) {
  //             return asc ? -1 : 1;
  //         } else if (a.voiceLogLink > b.voiceLogLink) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div id="to_truncate1"style={{ width: "75px" }}>
  //             <span style={{ fontSize: "13px",color:"#4F4F4F" }}>
  //                 {record.voiceLogLink}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  // {
  //     title: <span>Channel&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "channel",
  //     key: "channel",
  //     width: "18%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.channel < b.channel) {
  //             return asc ? -1 : 1;
  //         } else if (a.channel > b.channel) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div id="to_truncate1"style={{ width: "75px" }}>
  //             <span style={{ fontSize: "13px",color:"#4F4F4F" }}>
  //                 {record.channel}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  //sunita-task-3579- commented the custom question code as accordingly checkbox selection it will add the question accordingly
  // {
  //     // title: <span>CQ-1&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     title: <span>CQ-1&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "customQuestion1",
  //     key: "customQuestion1",
  //     width: "20%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.customQuestion1 - 1 < b.customQuestion1 - 1) {
  //             return asc ? -1 : 1;
  //         } else if (a.customQuestion1 - 1 > b.customQuestion1 - 1) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div id="to_truncate1" style={{ width: "75px" }}>
  //             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
  //                 {record.customQuestion11}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  // {
  //     title: <span>CQ-2&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "customQuestion2",
  //     key: "customQuestion2",
  //     width: "20%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.customQuestion2 < b.customQuestion2) {
  //             return asc ? -1 : 1;
  //         } else if (a.customQuestion2 > b.customQuestion2) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div id="to_truncate1" style={{ width: "75px" }}>
  //             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
  //                 {record.customQuestion2}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  // {
  //     title: <span>CQ-3&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "customQuestion3",
  //     key: "customQuestion3",
  //     width: "20%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.customQuestion3 < b.customQuestion3) {
  //             return asc ? -1 : 1;
  //         } else if (a.customQuestion3 > b.customQuestion3) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div id="to_truncate1" style={{ width: "75px" }}>
  //             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
  //                 {record.customQuestion3}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  // {
  //     title: <span>CQ-4&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "cutomQuestion4",
  //     key: "cutomQuestion4",
  //     width: "20%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.cutomQuestion4 < b.cutomQuestion4) {
  //             return asc ? -1 : 1;
  //         } else if (a.cutomQuestion4 > b.cutomQuestion4) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div id="to_truncate1" style={{ width: "75px" }}>
  //             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
  //                 {record.cutomQuestion4}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  // {
  //     title: <span>CQ-5&nbsp;&nbsp;&nbsp;<span><i class="fas fa-sort"></i></span></span>,
  //     dataIndex: "cutomQuestion5",
  //     key: "cutomQuestion5",
  //     width: "20%",
  //     align: "left",
  //     sorter: (a, b) => {
  //         const asc = "asc";

  //         if (a.cutomQuestion5 < b.cutomQuestion5) {
  //             return asc ? -1 : 1;
  //         } else if (a.cutomQuestion5 > b.cutomQuestion5) {
  //             return asc ? 1 : -1;
  //         } else {
  //             return 0;
  //         }
  //     },
  //     render: (text, record) => (
  //         <div id="to_truncate1" style={{ width: "75px" }}>
  //             <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
  //                 {record.cutomQuestion5}
  //             </span>
  //             <br />
  //         </div>
  //     ),
  // },

  {
    title: (
      <span>
        Asset Name Touch1&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetNameTouch1",
    key: "assetNameTouch1",
    width: "28%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetNameTouch1 < b.assetNameTouch1) {
        return asc ? -1 : 1;
      } else if (a.assetNameTouch1 > b.assetNameTouch1) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname1" style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetNameTouch1}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Asset Name Timestamp Touch1&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetTimestampTouch1",
    key: "assetTimestampTouch1",
    width: "27%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetTimestampTouch1 < b.assetTimestampTouch1) {
        return asc ? -1 : 1;
      } else if (a.assetTimestampTouch1 > b.assetTimestampTouch1) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetTimestampTouch1}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Asset Name Touch2&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetNameTouch2",
    key: "assetNameTouch2",
    // width: "28%",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetNameTouch2 < b.assetNameTouch2) {
        return asc ? -1 : 1;
      } else if (a.assetNameTouch2 > b.assetNameTouch2) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname1" style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetNameTouch2}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Asset Name Timestamp Touch2&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetTimestampTouch2",
    key: "assetTimestampTouch2",
    width: "27%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetTimestampTouch2 < b.assetTimestampTouch2) {
        return asc ? -1 : 1;
      } else if (a.assetTimestampTouch2 > b.assetTimestampTouch2) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetTimestampTouch2}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Asset Name Touch3&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetNameTouch3",
    key: "assetNameTouch3",
    // width: "28%",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetNameTouch3 < b.assetNameTouch3) {
        return asc ? -1 : 1;
      } else if (a.assetNameTouch3 > b.assetNameTouch3) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div id="to_truncatecolumnname1" style={{ width: "75px" }}>
      <div id="to_truncatecolumnname1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetNameTouch3}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Asset Name Timestamp Touch3&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetTimestampTouch3",
    key: "assetTimestampTouch3",
    width: "27%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetTimestampTouch3 < b.assetTimestampTouch3) {
        return asc ? -1 : 1;
      } else if (a.assetTimestampTouch3 > b.assetTimestampTouch3) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      // <div style={{ width: "75px" }}>
      <div>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetTimestampTouch3}
        </span>
        <br />
      </div>
    ),
  },
];

//sunita - 3413 - added columnsReportForblank array  for blank table .
const columnsReportForblank = [
  {
    title: (
      <span>
        PID&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "pID",
    key: "pID",
    width: "10%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.pID < b.pID) {
        return asc ? -1 : 1;
      } else if (a.pID > b.pID) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>{record.pID}</span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Lead Interaction Date&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "leadInteractionDate",
    key: "leadInteractionDate",
    width: "30%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.leadInteractionDate < b.leadInteractionDate) {
        return asc ? -1 : 1;
      } else if (a.leadInteractionDate > b.leadInteractionDate) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.leadInteractionDate}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Email&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "email",
    key: "email",
    width: "30%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.email.toUpperCase() < b.email.toUpperCase()) {
        return asc ? -1 : 1;
      } else if (a.email.toUpperCase() > b.email.toUpperCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.email}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        First Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "firstName",
    key: "firstName",
    width: "25%",
    align: "left",
    sorter: (a, b) => a.firstName - b.firstName,
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.firstName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Last Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "lastName",
    key: "lastName",
    width: "25%",
    align: "left",
    sorter: (a, b) => a.lastName - b.lastName,
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.lastName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        LinkedIn Company Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "linkedInCompanyName",
    key: "linkedInCompanyName",
    width: "30%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.linkedInCompanyName < b.linkedInCompanyName) {
        return asc ? -1 : 1;
      } else if (a.linkedInCompanyName > b.linkedInCompanyName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.linkedInCompanyName === "" ||
      record.linkedInCompanyName === undefined ||
      record.linkedInCompanyName === null ||
      record.linkedInCompanyName === "undefined" ||
      record.linkedInCompanyName === "null" ? (
        ""
      ) : (
        <div id="to_truncate1" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.linkedInCompanyName}
          </span>
          <br />
        </div>
      ),
  },
  {
    title: (
      <span>
        Job Title&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "jobTitle",
    key: "jobTitle",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.jobTitle < b.jobTitle) {
        return asc ? -1 : 1;
      } else if (a.jobTitle > b.jobTitle) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span id="to_truncate1" style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.jobTitle}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        LinkedIn Job Title&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "linkedInJobTitle",
    key: "linkedInJobTitle",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.linkedInJobTitle < b.linkedInJobTitle) {
        return asc ? -1 : 1;
      } else if (a.linkedInJobTitle > b.linkedInJobTitle) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    render: (text, record) =>
      record.linkedInJobTitle === "" ||
      record.linkedInJobTitle === undefined ||
      record.linkedInJobTitle === null ||
      record.linkedInJobTitle === "undefined" ||
      record.linkedInJobTitle === "null" ? (
        ""
      ) : (
        <div id="to_truncate1" style={{ width: "75px" }}>
          <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
            {record.linkedInJobTitle}
          </span>
          <br />
        </div>
      ),
  },

  {
    title: (
      <span>
        Address&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "address",
    key: "address",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.address < b.address) {
        return asc ? -1 : 1;
      } else if (a.address > b.address) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.address}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        City&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "city",
    key: "city",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.city < b.city) {
        return asc ? -1 : 1;
      } else if (a.city > b.city) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.city}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        State&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "state",
    key: "state",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.state < b.state) {
        return asc ? -1 : 1;
      } else if (a.state > b.state) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.state}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Country&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "country",
    key: "country",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.country < b.country) {
        return asc ? -1 : 1;
      } else if (a.country > b.country) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.country}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Zip Code&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "zipCode",
    key: "zipCode",
    width: "20%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.zipCode < b.zipCode) {
        return asc ? -1 : 1;
      } else if (a.zipCode > b.zipCode) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.zipCode}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Phone No.&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "workPhone",
    key: "workPhone",
    width: "25%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.workPhone < b.workPhone) {
        return asc ? -1 : 1;
      } else if (a.workPhone > b.workPhone) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.workPhone}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Company Employee Size&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "companyEmployeeSize",
    key: "companyEmployeeSize",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.companyEmployeeSize < b.companyEmployeeSize) {
        return asc ? -1 : 1;
      } else if (a.companyEmployeeSize > b.companyEmployeeSize) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.companyEmployeeSize}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Industry&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "industry",
    key: "industry",
    width: "25%",

    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.industry.toUpperCase() < b.industry.toUpperCase()) {
        return asc ? -1 : 1;
      } else if (a.industry.toUpperCase() > b.industry.toUpperCase()) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1">
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.industry}
        </span>
        <br />
      </div>
    ),
  },

  {
    title: (
      <span>
        Company Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "companyName",
    key: "companyName",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.companyName < b.companyName) {
        return asc ? -1 : 1;
      } else if (a.companyName > b.companyName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.companyName}
        </span>
        <br />
      </div>
    ),
  },
  {
    title: (
      <span>
        Asset Name&nbsp;&nbsp;&nbsp;
        <span>
          <i class="fas fa-sort"></i>
        </span>
      </span>
    ),
    dataIndex: "assetName",
    key: "assetName",
    width: "25%",
    align: "left",
    sorter: (a, b) => {
      const asc = "asc";

      if (a.assetName < b.assetName) {
        return asc ? -1 : 1;
      } else if (a.assetName > b.assetName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (text, record) => (
      <div id="to_truncate1" style={{ width: "75px" }}>
        <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
          {record.assetName}
        </span>
        <br />
      </div>
    ),
  },
];

//sunita - 3413 - added array  for lead status .
const leadStatusOptions = [
  { id: "Accepted", name: "Accepted" },
  { id: "Rejected", name: "Rejected" },
  { id: "In QA", name: "In QA" },
];

//sunita - 3413 - added array  for campaign status .
const campaignStatusOptions = [
  { id: "Active", name: "Active" },
  // { id: "Paused", name: "Paused" }, //sunita-task-3570-commented the paused camp status as per requirement.
  { id: "Completed", name: "Completed" },
];

//sunita - 3413 -  added checkbox for manage column .
const checkBoxArray = [
  //sunita-task-3572- changed the checkbox sequence as per requirement.
  { id: "Publisher ID", name: "pID", value: "No" },
  // { id: 'Campaign ID', name: 'campaignID', value: 'No' },
  { id: "Campaign ID", name: "campID", value: "No" },
  { id: "Campaign Name", name: "campaignName", value: "No" },
  { id: "Allocation ID", name: "reAllocationID", value: "No" },
  { id: "Lead Interaction Date", name: "leadInteractionDate", value: "No" },
  { id: "First Name", name: "firstName", value: "No" },
  { id: "Email", name: "email", value: "No" },
  { id: "Last Name", name: "lastName", value: "No" },
  { id: "Work phone", name: "workPhone", value: "No" },
  { id: "Alternate phone no", name: "alternatePhoneNo", value: "No" },
  { id: "Company Name", name: "companyName", value: "No" },
  { id: "LinkedIn company name", name: "linkedInCompanyName", value: "No" },
  { id: "Industry", name: "industry", value: "No" },
  { id: "Company employee size", name: "companyEmployeeSize", value: "No" },
  { id: "Company revenue", name: "companyRevenue", value: "No" },
  { id: "Job Title", name: "jobTitle", value: "No" },
  { id: "LinkedIn Job Title", name: "linkedInJobTitle", value: "No" },
  { id: "LinkedIn", name: "linkedIn", value: "No" },
  { id: "Job Level", name: "jobLevel", value: "No" },
  { id: "Job function", name: "jobFunction", value: "No" },
  { id: "Country", name: "country", value: "No" },
  { id: "State", name: "state", value: "No" },
  { id: "City", name: "city", value: "No" },
  { id: "Zip code", name: "zipCode", value: "No" },
  { id: "Address", name: "address", value: "No" },
  { id: "Street", name: "street", value: "No" },
  { id: "Asset Name", name: "assetName", value: "No" },
  { id: "Asset ID", name: "supportDocID", value: "No" },
  { id: "IP", name: "ip", value: "No" },
  { id: "Domain", name: "domain", value: "No" },
  { id: "Comments", name: "comments", value: "No" },
  // { id: 'Status', name: 'status', value: 'No' },//sunita-task-3585-commented the code as status is geeting checked..
  { id: "Custom Questions", name: "Custom Question", value: "No" },
  { id: "Extra1", name: "extra1", value: "No" },
  { id: "Extra2", name: "extra2", value: "No" },
  { id: "Extra3", name: "extra3", value: "No" },
  { id: "Extra4", name: "extra4", value: "No" },
  { id: "Extra5", name: "extra5", value: "No" },
  { id: "Extra6", name: "extra6", value: "No" },
  { id: "Extra7", name: "extra7", value: "No" },
  { id: "Extra8", name: "extra8", value: "No" },
  { id: "Extra9", name: "extra9", value: "No" },
  { id: "Extra10", name: "extra10", value: "No" },
  { id: "Extra11", name: "extra11", value: "No" },
  { id: "Extra12", name: "extra12", value: "No" },
  { id: "Extra13", name: "extra13", value: "No" },
  { id: "Extra14", name: "extra14", value: "No" },
  { id: "Extra15", name: "extra15", value: "No" },
  { id: "Extra16", name: "extra16", value: "No" },
  { id: "Extra17", name: "extra17", value: "No" },
  { id: "Extra18", name: "extra18", value: "No" },
  { id: "Extra19", name: "extra19", value: "No" },
  { id: "Extra20", name: "extra20", value: "No" },
  //Chaitanya-5194-Added 21-30 extra fields
  { id: "Extra21", name: "extra21", value: "No" },
  { id: "Extra22", name: "extra22", value: "No" },
  { id: "Extra23", name: "extra23", value: "No" },
  { id: "Extra24", name: "extra24", value: "No" },
  { id: "Extra25", name: "extra25", value: "No" },
  { id: "Extra26", name: "extra26", value: "No" },
  { id: "Extra27", name: "extra27", value: "No" },
  { id: "Extra28", name: "extra28", value: "No" },
  { id: "Extra29", name: "extra29", value: "No" },
  { id: "Extra30", name: "extra30", value: "No" },
  // { id: 'Voice Log Link', name: 'voiceLogLink', value: 'No' },
  { id: "Touch1", name: "assetNameTouch1", value: "No" },
  { id: "Touch2", name: "assetNameTouch2", value: "No" },
  { id: "Touch3", name: "assetNameTouch3", value: "No" },
  // { id: 'Job Role.', name: 'jobRole', value: 'No' },
];

//const leadPlain = checkBoxArray.map(function (a) { return a.name }); //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2

class AgencyAdvertiserLeadTracking extends React.Component {
  constructor() {
    super();
    this.state = {
      tableState: { size: "small" }, //sunita-task-3413-used for table design
      agencyAdvertiserCampaignInfo: [], //sunita-task-3413-declared for table data
      agencyMyReportsDataNew1: [], //sunita-task-3413-declare to set table data in this new array while filtering the data
      agencyMyReportsData: [], //sunita-task-3413-array declared for table data
      advertiserList: [], //sunita-task-3413-array declared for advertiser name
      advertiserList1: [], //sunita-task-3413-array declared to filter advertiser name
      agencyID: "", // sunita-task-3413-declare to get the id from userLogin
      advertiserID: "Select Advertiser", //sunita-task-3413-used to display bydefault non-selected .
      campaignStatus: [], //sunita-task-3413-used to display bydefault non-selected .
      timeLine: "Select Timeline", //sunita-task-3413-used to display bydefault non-selected .
      label: "", //sunita-task-3413-used to filter deadline as per label .
      reportKey: "", //sunita-task-3413-used key to put conditions when user came by clicking on report name .
      advertiserName: "", //sunita-task-3413-to target advertiser name .
      // type: "Advertiser Campaign Delivery Tracking",//sunita-task-3406-given by default type
      type: "Advertiser Lead Tracking", //sunita-task-3413-given by default type
      reportName: "", //sunita-task-3413-used for report name .
      loader: "", //sunita-task-3413-used for loader.
      loader1: "", //sunita-task-3413-used for generate loader.
      campaignLoader: "", //sunita-3413-used for campaign loader.
      generateBtnFlag: false, //sunita-task-3413-used to enable - disable generate button.
      advID: "", //sunita-task-3413-used to access advid on this page came from myreport page.
      userType: "Advertiser", //sunita-task-3413-by default usertype
      pageSize: 100, //sunita-task-3413-added for page size.
      handleOnkey: 2, //sunita-task-3413-added for going back to my report page
      campID: "Select Campaign", //sunita-task-3413-declare campID for campaign dropdown .
      getCampaignListByADV: [], //sunita-task-3413-declare array  for getting the campaign list.
      advLeadDetails: [], //sunita-task-3413-declare array to iterate table data
      token: "", //sunita-task-3413-used token for authentication
      deliveryDetails: [], //sunita-task-3413-declare array to store the delivery format details
      leadStatus: [], //sunita-task-3413-array for leadstatus values
      campaignName: "", //sunita-task-3413-declare variable for campaign.
      campaignStatusArray: [], //sunita-task-3413-declare array for cmapaign status array .
      leadIndeterminate: true, //sunita-task-3413-used flag to setState checkbox value.
      leadCheckAll: false, //sunita-task-3413-used flag to check checkbox.
      campaignLeadStatusArray: [], //sunita-task-3413-declare array for  lead status array
      dataTableColumns: columnsReport, //sunita-task-3413-declare array to set table column array .
      checkedColumnKeys: [], //sunita-task-3413-declare array for checkbox value
      drpdisabled_report: "", //sunita-task-3413-declare for disable checkbox.
      advcountDetails: [], //sunita-task-3413-array declared for report summary data
      customQuestionCount: 0, //sunita-task-3413-declare variable for  customQuestionCount.
      checkBoxObjArray: checkBoxArray, //sunita-task-3413-declare array for checkbox array.
      multiTouch: "No", //sunita-task-3413-variable for multitouch.
      deadline: "", //sunita-task-3413-declare variable for timeline
    }; //end of state

    //sunita-task-3413-binded all below mentioned handle changes as per requirement
    this.handleChangePageSize = this.handleChangePageSize.bind(this);
    this.onAdvertiserNameHandleChange =
      this.onAdvertiserNameHandleChange.bind(this);
    this.onCampaignStatusHandleChange =
      this.onCampaignStatusHandleChange.bind(this);
    this.ondeadlineHandleChange = this.ondeadlineHandleChange.bind(this);
    this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
    this.generateHandleChange = this.generateHandleChange.bind(this);
    this.onLeadStatusHandleChange = this.onLeadStatusHandleChange.bind(this);
    this.onCampaignHandleChange = this.onCampaignHandleChange.bind(this);
    this.onCampaignDetailsHandleChange =
      this.onCampaignDetailsHandleChange.bind(this);
    this.onCampaignLeadDetailsHandleChange =
      this.onCampaignLeadDetailsHandleChange.bind(this);
  } // end of constrcutor

  /**
   * @author : Sunita Landge - task 3413
   * @description : handlechange for checkbox in manage column .
   */

  leadOnChange = (checkedColumnKeys) => {
    if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch1")) {
      checkedColumnKeys.push("assetTimestampTouch1");
    } else {
      let index = checkedColumnKeys.indexOf("assetTimestampTouch1");
      if (index > -1) {
        checkedColumnKeys.splice(index, 1);
      }
    }

    if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch2")) {
      checkedColumnKeys.push("assetTimestampTouch2");
    } else {
      let index = checkedColumnKeys.indexOf("assetTimestampTouch2");
      if (index > -1) {
        checkedColumnKeys.splice(index, 1);
      }
    }

    if (checkedColumnKeys && checkedColumnKeys.includes("assetNameTouch3")) {
      checkedColumnKeys.push("assetTimestampTouch3");
    } else {
      let index = checkedColumnKeys.indexOf("assetTimestampTouch3");
      if (index > -1) {
        checkedColumnKeys.splice(index, 1);
      }
    }

    this.setState({
      checkedColumnKeys,
      leadIndeterminate:
        !!checkedColumnKeys.length &&
        checkedColumnKeys.length < this.state.checkBoxObjArray.length,
      leadCheckAll:
        checkedColumnKeys.length === this.state.checkBoxObjArray.length,
    });
  };

  /**
   * @author : Sunita Landge - task 3413
   * @description : handlechange for pageSize .
   */

  handleChangePageSize(e) {
    var pageSize = e.target.value;
    this.setState({ pageSize: pageSize });
  } //end of handleChangePageSize

  /**
   * @author : Sunita Landge - task 3413
   * @description : handlechange for backbutton to navigate on same page from where user came .
   */
  backButtonHandleChange() {
    if (this.state.reportKey !== "report") {
      Swal.fire({
        title: "Are you sure you want to discard this report?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonText: "Discard",
      }).then((result) => {
        //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        if (result.value) {
          this.props.history.push("/agencyMyReports", {
            handleOnkey: this.state.handleOnkey,
          });
        } else if (result.dismiss === "close") {
          window.location.href = "#";
        }
      });
    } else {
      this.props.history.push("/agencyMyReports", {
        handleOnkey: this.state.handleOnkey,
      });
    }
  } //end of backButtonHandleChange

  /**
   * @author : Sunita Landge - task 3413
   * @description :  handleChange to load the campaign after selecting advertiser and campaign status .
   */

  onCampaignDetailsHandleChange(advertiserID, campaignStatusArray) {
    const { user } = this.props.auth;
    const agencyID = user.id;
    let timeLine = this.state.timeLine;
    let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
    let campID = this.state.campID;
    this.setState({ agencyID });

    //sunita-task-3570-added below condition to close the loader when all filters are having values.
    if (
      advertiserID &&
      advertiserID !== "Select Advertiser" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0 &&
      campID &&
      campID !== "Select Campaign" &&
      timeLine &&
      timeLine !== "Select Timeline"
    ) {
      this.setState({ campaignLoader: "" });
    }
    let advcountDetails = [
      {
        advertiserID: advertiserID,
        advertiserName: "",
        deliveryLabel: "",
        totalAllocation: 0,
        totalAccepted: 0,
        deliveredLead: 0,
        rejectedLead: 0,
        qaReviewLead: 0,
        balanceLead: 0,
      },
    ];
    this.setState({
      agencyID,
      advcountDetails,
      advLeadDetails: [],
      campaignLoader: "campaignLoader",
    });

    if (
      advertiserID &&
      advertiserID !== "Select Advertiser" &&
      campaignStatusArray &&
      campaignStatusArray.length > 0
    ) {
      let data = {
        //agencyID: agencyID,                                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        advertiserID: advertiserID,
        campaignStatus: campaignStatusArray,
      };

      fetch("/advertiserReportEngine/getCampaignListByADV", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((getCampaignListByADV) => {
          this.setState({
            getCampaignListByADV: getCampaignListByADV,
            campaignLoader: "",
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  /**
   * @author : Sunita Landge - task 3406
   * @description : handlechange to target selected advertiser .
   */

  onAdvertiserNameHandleChange(e) {
    var advertiserID = e.target.value;
    let campaignStatusArray = this.state.campaignStatusArray;
    let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
    let timeLine = this.state.timeLine;
    let campID = ""; //sunita-task-3582 -  added code for displaying the particular adv data in table.
    this.setState({ advertiserID, campID: "", advLeadDetails: [] }); //sunita-task-3582-added code for showing blank data if data is not present in table.
    if (
      advertiserID &&
      advertiserID !== "Select Advertiser" &&
      campaignStatusArray &&
      campaignStatusArray.length > 0
    ) {
      this.onCampaignDetailsHandleChange(advertiserID, campaignStatusArray); //sunita-task-3413-function called to load the campaign after selecting advertiser and campaign status .
    }

    //sunita-task-3575-added below condition for - if same campaign having by number of advertiser So data should load in table.
    if (campaignStatusArray.length === 0 || campaignStatusArray === []) {
      //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
      this.setState({
        campID: "Select Campaign",
        generateBtnFlag: false,
        advLeadDetails: [],
      });
    } else if (
      advertiserID &&
      advertiserID !== "Select Advertiser" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0 &&
      campID &&
      campID !== "Select Campaign" &&
      timeLine &&
      timeLine !== "Select Timeline" &&
      campaignStatusArray &&
      campaignStatusArray.length > 0
    ) {
      this.onCampaignLeadDetailsHandleChange(
        advertiserID,
        campaignLeadStatusArray,
        campID,
        timeLine
      );
    } else {
      this.setState({ generateBtnFlag: false, advLeadDetails: [] });
    }
  } //end of onAdvertiserNameHandleChange

  /**
   * @author : Sunita Landge - task 3413
   * @description : handlechange to target selected campaignstatus .
   */

  onCampaignStatusHandleChange(campaignStatus) {
    let campaignStatusArray = [];
    let checkActive = campaignStatus.some((o) => o.name === "Active");
    if (checkActive) {
      campaignStatusArray.push("Active");
    }

    //sunita-task-3570-commented the paused checkbox as per requirement
    // let checkPaused = campaignStatus.some(o => o.name === "Paused")
    // if (checkPaused) { campaignStatusArray.push("Paused") }

    let checkCompleted = campaignStatus.some((o) => o.name === "Completed");
    if (checkCompleted) {
      campaignStatusArray.push("Completed");
    }

    let advertiserID = this.state.advertiserID;
    // let campID = this.state.campID;
    this.setState({ campaignStatus, campaignStatusArray });
    if (campaignStatusArray.length === 0 || campaignStatusArray === []) {
      //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
      this.setState({
        campID: "Select Campaign",
        generateBtnFlag: false,
        advLeadDetails: [],
      });
    } else if (
      advertiserID &&
      advertiserID !== "Select Advertiser" &&
      campaignStatusArray &&
      campaignStatusArray.length > 0
    ) {
      this.onCampaignDetailsHandleChange(advertiserID, campaignStatusArray); //sunita-task-3413-function called to load the campaign after selecting advertiser and campaign status .
    } else {
      this.setState({ generateBtnFlag: false, advLeadDetails: [] });
    }
  } //end of onCampaignStatusHandleChange

  /**
   * @author : Sunita Landge - task 3413
   * @description : handlechange to target selected leadstatus .
   */

  onLeadStatusHandleChange(leadStatus) {
    let campaignLeadStatusArray = [];
    let checkAccepted = leadStatus.some((o) => o.name === "Accepted");
    if (checkAccepted) {
      campaignLeadStatusArray.push("Accepted");
    }

    let checkRejected = leadStatus.some((o) => o.name === "Rejected");
    if (checkRejected) {
      campaignLeadStatusArray.push("Rejected");
    }

    let checkInQA = leadStatus.some((o) => o.name === "In QA");
    if (checkInQA) {
      campaignLeadStatusArray.push("In QA");
    }

    let advertiserID = this.state.advertiserID;
    var campID = this.state.campID;
    let timeLine = this.state.timeLine;

    this.setState({ leadStatus, campaignLeadStatusArray });
    if (
      advertiserID &&
      advertiserID !== "Select Advertiser" &&
      campID &&
      campID !== "Select Campaign" &&
      timeLine &&
      timeLine !== "Select Timeline" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0
    ) {
      this.onCampaignLeadDetailsHandleChange(
        advertiserID,
        campaignLeadStatusArray,
        campID,
        timeLine
      ); //sunita-task-3413-function called to load the table data after selecting values from all filters .
    } else {
      this.setState({ generateBtnFlag: false, advLeadDetails: [] });
    }
  }

  /**
   * @author : Sunita Landge - task 3413
   * @description : handleChange for table columns & (multiTouch / custome question) .
   */
  setDataTableColumn(checkedColumnKeys, multiTouch, customQuestionCount) {
    checkedColumnKeys.push("status"); //sunita-task- 3585-reverted the code as per requirement.
    let dataTableColumns = [...columnsReport];
    let checkBoxObjArray = [...this.state.checkBoxObjArray];

    if (
      customQuestionCount > 0 &&
      checkedColumnKeys &&
      checkedColumnKeys.includes("Custom Question")
    ) {
      // alert("inside settable==>"+customQuestionCount);
      let cqArray = [];

      for (let i = 1; i <= customQuestionCount; i++) {
        let CQkey = `CQ-${i}`,
          ansKey = `CQ-${i}-ANS`;
        let Q_obj = {
          title: (
            <span>
              {CQkey}&nbsp;&nbsp;&nbsp;
              <span>
                <i class="fas fa-sort"></i>
              </span>
            </span>
          ),
          dataIndex: CQkey,
          key: CQkey,
          width: "20%",
          align: "left",
          sorter: (a, b) => {
            const asc = "asc";

            if (a[CQkey] < b[CQkey]) {
              return asc ? -1 : 1;
            } else if (a[CQkey] > b[CQkey]) {
              return asc ? 1 : -1;
            } else {
              return 0;
            }
          },
        };
        let ans_obj = {
          title: (
            <span>
              {ansKey}&nbsp;&nbsp;&nbsp;
              <span>
                <i class="fas fa-sort"></i>
              </span>
            </span>
          ),
          dataIndex: ansKey,
          key: ansKey,
          width: "20%",
          align: "left",
          sorter: (a, b) => {
            const asc = "asc";

            if (a[ansKey] < b[ansKey]) {
              return asc ? -1 : 1;
            } else if (a[ansKey] > b[ansKey]) {
              return asc ? 1 : -1;
            } else {
              return 0;
            }
          },
        };
        cqArray.push(Q_obj);
        cqArray.push(ans_obj);
        // if(this.state.reportKey!=="report"){
        checkedColumnKeys.push(CQkey);
        // alert("CQkey=>"+checkedColumnKeys);
        checkedColumnKeys.push(ansKey);
        // alert("ansKey==>"+checkedColumnKeys);
      } //End of CQ for loop
      checkedColumnKeys = [...new Set(checkedColumnKeys)];
      dataTableColumns = dataTableColumns.concat(cqArray);
    } else {
      if (customQuestionCount === 0) {
        // alert("in else");
        checkBoxObjArray = checkBoxObjArray.filter(
          (a) => a.id !== "Custom Question"
        );
      }

      let keys = [];
      for (let i = 1; i <= customQuestionCount; i++) {
        let CQkey = `CQ-${i}`,
          ansKey = `CQ-${i}-ANS`;
        keys.push(CQkey);
        keys.push(ansKey);
      }
      dataTableColumns = dataTableColumns.filter((obj) => {
        return keys && !keys.includes(obj.key);
      });
    }
    if (multiTouch === "Yes") {
      checkBoxObjArray = checkBoxObjArray.filter((a) => a.name !== "assetName");
    }
    dataTableColumns = dataTableColumns.filter((obj) => {
      return checkedColumnKeys && checkedColumnKeys.includes(obj.key);
    });

    checkedColumnKeys = [...new Set(checkedColumnKeys)];
    this.setState({ dataTableColumns, checkBoxObjArray, checkedColumnKeys });
  }

  /**
   * @author : Sunita Landge - task 3413
   * @description : handleChange for table data after selecting values from all filters .
   */

  onCampaignLeadDetailsHandleChange(
    advertiserID,
    campaignLeadStatusArray,
    campID,
    timeLine
  ) {
    let advcountDetails = [
      {
        advertiserID: advertiserID,
        advertiserName: "",
        deliveryLabel: "",
        totalAllocation: 0,
        totalAccepted: 0,
        deliveredLead: 0,
        rejectedLead: 0,
        qaReviewLead: 0,
        balanceLead: 0,
      },
    ];
    this.setState({
      advcountDetails,
      loader: "lead_loader",
      generateBtnFlag: false,
      advLeadDetails: [],
    });
    // alert("advcountdetails=>" + JSON.stringify(advcountDetails));

    const { user } = this.props.auth;
    const agencyID = user.id;
    let campaignStatusArray = this.state.campaignStatusArray;
    // let campID= this.state.campID;
    // alert("campID=>" + campID);
    this.setState({ agencyID });

    if (this.state.reportKey === "report") {
      if (
        advertiserID &&
        advertiserID !== "Select Advertiser" &&
        campaignLeadStatusArray &&
        campaignLeadStatusArray.length > 0 &&
        campID &&
        campID !== "Select Campaign" &&
        timeLine &&
        timeLine !== "Select Timeline"
      ) {
        let data = {
          campID: campID,
          //agencyID: agencyID,                                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
          leadStatus: campaignLeadStatusArray,
          timeLine: timeLine,
          advertiserID: advertiserID,
          campaignStatus: campaignStatusArray,
        };

        fetch("/advertiserReportEngine/advLeadDetails", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((advLeadDetails) => {
            //let advcountDetails = advLeadDetails.countDetails; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
            let deliveryDetails = advLeadDetails.deliveryDetails;
            let checkedColumnKeys = [],
              multiTouch = "No";
            if (deliveryDetails.length > 0) {
              multiTouch = deliveryDetails[0].multiTouch;
            }

            if (advLeadDetails.result === []) {
              this.setState({ loader: "" });
            }

            for (const [key, value] of Object.entries(deliveryDetails[0])) {
              if (value === "Yes" || value === "yes") {
                checkedColumnKeys.push(key);
              }
            }

            let customQuestionCount = advLeadDetails.customQuestionCount;
            if (customQuestionCount > 0) {
              checkedColumnKeys.push("Custom Question");
            }

            this.setDataTableColumn(
              checkedColumnKeys,
              multiTouch,
              customQuestionCount
            ); //sunita-task-3413-function called for table columns

            this.setState({
              advLeadDetails: advLeadDetails.result,
              advcountDetails: advLeadDetails.countDetails,
              deliveryDetails: advLeadDetails.deliveryDetails,
              loader: "",
              checkedColumnKeys,
              multiTouch,
              customQuestionCount,
            });
            if (this.state.advLeadDetails.length > 0) {
              this.setState({ generateBtnFlag: true });
            } else {
              this.setState({ generateBtnFlag: false });
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    } else if (
      advertiserID &&
      advertiserID !== "Select Advertiser" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0 &&
      campaignStatusArray &&
      campaignStatusArray.length > 0 &&
      campID &&
      campID !== "Select Campaign" &&
      timeLine &&
      timeLine !== "Select Timeline"
    ) {
      let data = {
        campID: campID,
        //agencyID: agencyID,                                    //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        leadStatus: campaignLeadStatusArray,
        timeLine: timeLine,
        advertiserID: advertiserID,
        campaignStatus: campaignStatusArray,
      };

      // alert("data=>" + JSON.stringify(data));

      fetch("/advertiserReportEngine/advLeadDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((advLeadDetails) => {
          this.setState({ loader: "" }); //sunita-task-3573-added code for displaying loader blank when data not available.
          //let advcountDetails = advLeadDetails.countDetails; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
          let deliveryDetails = advLeadDetails.deliveryDetails;

          let checkedColumnKeys = [],
            multiTouch = "No";
          if (deliveryDetails.length > 0) {
            multiTouch = deliveryDetails[0].multiTouch;
          }

          for (const [key, value] of Object.entries(deliveryDetails[0])) {
            if (value === "Yes" || value === "yes") {
              checkedColumnKeys.push(key);
            }
          }

          let customQuestionCount = advLeadDetails.customQuestionCount;

          if (customQuestionCount > 0) {
            checkedColumnKeys.push("Custom Question");
          }
          this.setDataTableColumn(
            checkedColumnKeys,
            multiTouch,
            customQuestionCount
          ); //sunita-task-3413-function called for table columns

          this.setState({
            advLeadDetails: advLeadDetails.result,
            advcountDetails: advLeadDetails.countDetails,
            deliveryDetails: advLeadDetails.deliveryDetails,
            checkedColumnKeys,
            multiTouch,
            customQuestionCount,
          });

          if (this.state.advLeadDetails.length > 0) {
            this.setState({ generateBtnFlag: true });
          } else {
            this.setState({ generateBtnFlag: false });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  /**
   * @author : Sunita Landge - task 3413
   * @description : handleChange for campaign dropdown .
   */

  onCampaignHandleChange(e) {
    let campID = e.target.value;
    this.setState({ campID });
    let advertiserID = this.state.advertiserID;
    let campaignLeadStatusArray = this.state.campaignLeadStatusArray;

    let checkName = this.state.getCampaignListByADV.filter(
      (obj) => obj.campID.toString() === campID
    ); //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
    let campaignName = "";
    if (checkName && checkName.length > 0) {
      campaignName = checkName[0].campaignName;
    }
    this.setState({ campaignName });

    let timeLine = this.state.timeLine;

    if (
      advertiserID &&
      advertiserID !== "Select Advertiser" &&
      campID &&
      campID !== "Select Campaign" &&
      timeLine &&
      timeLine !== "Select Timeline" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0
    ) {
      this.onCampaignLeadDetailsHandleChange(
        advertiserID,
        campaignLeadStatusArray,
        campID,
        timeLine
      ); //sunita-task-3413-function called to load the table data after selecting values from all filters .
    } else {
      this.setState({ generateBtnFlag: false, advLeadDetails: [] });
    }
  }

  /**
   * @author : Sunita Landge - task 3413
   * @description : handlechange to target selected deadline .
   */

  ondeadlineHandleChange(e) {
    var timeLine = e.target.value;
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    this.setState({ timeLine, label: label }); //Aman-4797-(remove this label: this.state.label/e.nativeEvent.target[index].text dupliacte)-Console Warnings-Agency-Reports Part 2

    let { advertiserID, campaignLeadStatusArray, campID } = this.state;

    if (
      timeLine &&
      timeLine !== "Select Timeline" &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0 &&
      advertiserID &&
      advertiserID !== "Select Advertiser" &&
      campID &&
      campID !== "Select Campaign"
    ) {
      this.onCampaignLeadDetailsHandleChange(
        advertiserID,
        campaignLeadStatusArray,
        campID,
        timeLine
      ); //sunita-task-3413-function called to load the data after selecting the filters
    } else {
      this.setState({ generateBtnFlag: false, advLeadDetails: [] });
    }
  } //end of ondeadlineHandleChange

  /**
   * @author : Sunita Landge - task 3413
   * @description : handlechange to generate report successfully .
   */

  generateHandleChange(e) {
    //const { user } = this.props.auth; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    //const agencyID = user.id; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    let advertiserID = this.state.advertiserID;
    let timeLine = this.state.timeLine;
    let campID = this.state.campID;
    let campaignStatus = this.state.campaignStatusArray;
    let campaignLeadStatusArray = this.state.campaignLeadStatusArray;
    let advLeadDetails = [...this.state.advLeadDetails];
    let advcountDetails = [...this.state.advcountDetails];
    let reportName = this.state.reportName;
    let type = this.state.type;
    let userType = this.state.userType;
    //var campaignName = this.state.campaignName; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
    let leadStatus = this.state.campaignLeadStatusArray;

    if (leadStatus) {
      leadStatus = leadStatus.toString();
    }

    this.setState({ campaignStatus, advLeadDetails, loader1: "gen_loader" });

    if (
      advertiserID &&
      advertiserID !== "Select Advertiser" &&
      campaignStatus &&
      campaignStatus.length > 0 &&
      campaignLeadStatusArray &&
      campaignLeadStatusArray.length > 0 &&
      campID &&
      campID !== "Select Campaign" &&
      timeLine &&
      timeLine !== "Select Timeline"
    ) {
      if (advcountDetails && advcountDetails.length > 0) {
        advcountDetails[0].customQuestionCount = this.state.customQuestionCount;
        advcountDetails[0].leadStatus = leadStatus;
        advcountDetails[0].campID = this.state.campID;
        advcountDetails[0].campaignName = this.state.campaignName;
        advcountDetails[0].multiTouch = this.state.multiTouch;
      }

      let data = {
        //user: user,                                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        //agencyID: agencyID,
        advertiserID: advertiserID,
        deadline: timeLine,
        campaignStatus: campaignStatus,
        type: type,
        reportName: reportName,
        reportDetail: advLeadDetails,
        reportSummary: advcountDetails,
        userType: userType,
        checkedColumnKeys: this.state.checkedColumnKeys,
      };

      fetch("/advertiserReportEngine/generateLeadReportAdvertiser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ loader1: "" });
          if (res.success === true) {
            Swal.fire({
              //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE

              title: '<span id="success_popUp"></span>',
              //html: "<span id='for_popUp_generate'>Please <a href='/agencyMyReports'><span id='for_popUp_generate1'>click here</span></a> to check report.",
              html: "<h4><h4>Report generated successfully.</h4><h4><a href='#'>Please </a><a href='agencyMyReports'><span style='color:#F28C0F'>click here</span></a><a href='#'> to check report</a></h4></h4>",

              type: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                //this.props.history.push("/agencyMyReports",{handleOnkey:this.state.handleOnkey})
                window.location.href = "/agencyMyReports";
              },
            });
          }

          //sunita-task-3575-as this pop up don't want so commenting code
          // if (res.success === false) {
          //     Swal.fire({
          //         title: 'Report data empty.',
          //         type: "error",
          //         confirmButtonText: "Ok",
          //         allowOutsideClick: false,
          //         preConfirm: () => {
          //         },
          //     });
          // }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  } //end of generateHandleChange

  /**
   * @author : Sunita Landge - task 3413
   * @description : for disable screen if report key is present in url
   */
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
      let reportKey, reportID, advertiserID, reportName, type;

      if (this.props.location.state !== undefined) {
        advertiserID = this.props.location.state.advID;
        reportKey = this.props.location.state.reportKey;
        reportID = this.props.location.state.reportID;
        //this.state.reportID=reportID; //Aman-4797-(Used setState() for Do not mutate state directly. Use setState().)-Console Warnings-Agency-Reports Part 2
        reportName = this.props.location.state.reportName;
        this.setState({ reportName: reportName, reportID: reportID }); //this.state.reportName=reportName; //Aman-4797
        type = this.props.location.state.type;
      }

      //const { isAuthenticated } = this.props.auth; //Aman-4797-(commented not in use)-Console Warnings-Agency-Reports Part 2
      const { user } = this.props.auth;
      const agencyID = user.id;
      this.setState({
        agencyID: agencyID,
        advertiserID: advertiserID,
        reportKey: reportKey,
        generateBtnFlag: false,
        reportName: reportName,
        type: type,
      });

      if (this.state.reportKey === "report") {
        this.setState({ drpdisabled_report: "drpdisable_report" });
      } else {
        this.setState({ drpdisabled_report: "" });
      }

      let data = {
        //agencyID: this.state.agencyID,                //3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE
        advertiserID: advertiserID,
        reportID: reportID,
      };

      if (reportID) {
        this.setState({ loader: "lead_loader" });

        fetch("/advertiserReportEngine/reportDetailForLeadAdv", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((agencyAdvertiserCampaignInfo) => {
            let timeLine,
              campaignStatus,
              advertiserID,
              checkedColumnKeys,
              leadStatus,
              campaignName,
              advLeadDetails,
              deadline,
              advcountDetails,
              multiTouch,
              campID,
              customQuestionCount;

            if (agencyAdvertiserCampaignInfo) {
              advertiserID = agencyAdvertiserCampaignInfo.advertiserID;
              campaignStatus = agencyAdvertiserCampaignInfo.campaignStatus;

              timeLine = agencyAdvertiserCampaignInfo.deadline;

              checkedColumnKeys = agencyAdvertiserCampaignInfo.manageColumns;

              checkedColumnKeys = checkedColumnKeys.split(",");

              advcountDetails = agencyAdvertiserCampaignInfo.reportSummary;

              advLeadDetails = agencyAdvertiserCampaignInfo.reportDetail;

              multiTouch = advcountDetails[0].multiTouch;

              campaignName = advcountDetails[0].campaignName;

              customQuestionCount = advcountDetails[0].customQuestionCount;

              leadStatus = advcountDetails[0].leadStatus;

              campID = advcountDetails[0].campID;
            }

            var str = campaignStatus;
            var statusTempArray = [];
            var str_array = str.split(",");
            for (var i = 0; i < str_array.length; i++) {
              if (str_array[i] !== "") {
                //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
                statusTempArray.push({ id: str_array[i], name: str_array[i] });
              }
            }

            var str1 = leadStatus;
            var leadTempArray = [];
            var str_array1 = str1.split(",");
            for (let i = 0; i < str_array1.length; i++) {
              //Aman-4797-(change var to let in for loop)-Console Warnings-Agency-Reports Part 2
              if (str_array1[i] !== "") {
                //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
                leadTempArray.push({ id: str_array1[i], name: str_array1[i] });
              }
            }

            if (agencyAdvertiserCampaignInfo.deadline !== "") {
              if (agencyAdvertiserCampaignInfo.deadline === "All") {
                //Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2
                this.setState({ label: "All" }); //this.state.label = "All" //Aman-4797-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings-Agency-Reports Part 2
              } else if (agencyAdvertiserCampaignInfo.deadline === "Weekly") {
                this.setState({ label: "Ending This Week" }); //this.state.label = "Ending This Week"
              } else if (agencyAdvertiserCampaignInfo.deadline === "Monthly") {
                this.setState({ label: "Ending This Month" }); //this.state.label = "Ending This Month"
              } else if (
                agencyAdvertiserCampaignInfo.deadline === "Quarterly"
              ) {
                this.setState({ label: "Ending This Quarter" }); //this.state.label = "Ending This Quarter"
              } else if (agencyAdvertiserCampaignInfo.deadline === "Yearly") {
                this.setState({ label: "Ending This Year" }); //this.state.label = "Ending This Year"
              } else if (agencyAdvertiserCampaignInfo.deadline === "PreMonth") {
                this.setState({ label: "Ending Previous Month" }); //this.state.label = "Ending Previous Month"
              } else if (
                agencyAdvertiserCampaignInfo.deadline === "PreQuarter"
              ) {
                this.setState({ label: "Ending Previous Quarter" }); //this.state.label = "Ending Previous Quarter"
              } else if (agencyAdvertiserCampaignInfo.deadline === "PreYear") {
                this.setState({ label: "Ending Previous Year" }); //this.state.label = "Ending Previous Year"
              }
            }

            this.setState(
              {
                advLeadDetails,
                advcountDetails,
                advertiserID,
                deadline,
                timeLine,
                campaignStatus: statusTempArray,
                leadStatus: leadTempArray,
                campID,
                label: this.state.label,
                loader: "",
                campaignName,
                customQuestionCount,
                multiTouch,
                checkedColumnKeys,
                campaignLeadStatusArray: str_array1,
              },

              function () {
                this.setDataTableColumn(
                  checkedColumnKeys,
                  multiTouch,
                  customQuestionCount
                ); //sunita-task-3413-function called for table columns
              }
            );
          })
          .catch(function (err) {
            console.log(err);
          });
      }

      //sunita-task-3413-api fetched for getting advertiser list
      fetch("/advertiserReportEngine/getADVList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //authorization: token,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((advertiserList) => {
          this.setState({ advertiserList, advertiserList1: advertiserList });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  } //end of componentWillMount

  /**
   * @author : Sunita Landge - task 3413
   * @description : handlechange for save and close button after check/uncheck checkbox .
   */

  handleSubmit = (e) => {
    this.setDataTableColumn(
      this.state.checkedColumnKeys,
      this.state.multiTouch,
      this.state.customQuestionCount
    ); //sunita-task-3413-function called for table columns
  };

  /**
   * @author : Sunita Landge-task 3413
   * @description :Render part to display complete screen with functionality as per given screen .
   */

  render() {
    const asterisk = {
      color: "red",
    };

    function onChange(pagination, filters, sorter) {
      console.log("params", pagination, filters, sorter);
    }

    return (
      <div>
        <Navigation />
        <div
          style={{ paddingBottom: "60px", paddingTop: "100px" }}
          class="container-fluid "
        >
          <div class="row">
            <div class="col-sm-12 col-md-1 col-lg-2">
              {/*3721-Rutuja Kale-VAPT-removing # -Agency side--Reports menu--URL Links & API-FE*/}
              {/*Aman-4797-(added void(0) to "#/" and removed the duplicate style attribute)-Console Warnings-Agency-Reports Part 2 */}
              <a
                style={{ color: "#056eb8" }}
                href={"#/"}
                onClick={this.backButtonHandleChange}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  title="Back to agencyMyReport"
                />
              </a>
            </div>

            <div
              class=" col-xs-12 col-sm-12 col-md-8 offset-lg-2 col-lg-4"
              align="center"
            >
              <label id="labelDI">Advertiser Lead Tracking Report</label>
            </div>

            {/* <div class="col-lg-2 col-md-3 col-sm-12 generateButton2" style={{ textAlign: 'right' }} > */}
            <div
              class="col-sm-12 col-md-3 offset-lg-1 col-lg-3 generateButton2"
              style={{ textAlign: "right" }}
            >
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                ""
              ) : this.state.generateBtnFlag === true ? (
                <button
                  class="btn add-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(to left, #007CC3,#193D8F)",
                    color: " white",
                  }}
                  type="button"
                  data-toggle="modal"
                  data-target="#generateReport"
                  onClick={this.generateHandleChange}
                >
                  Generate Report
                </button>
              ) : (
                <button
                  disabled
                  class="btn add-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(to left, #007CC3,#193D8F)",
                    color: " white",
                  }}
                  type="button"
                  data-toggle="modal"
                  data-target="#generateReport"
                  onClick={this.generateHandleChange}
                >
                  Generate Report
                </button>
              )}
            </div>
          </div>
          <div
            class="row"
            style={{
              paddingTop: "15px",
              paddingLeft: "25px",
              paddingBottom: "11px",
            }}
          >
            <div class="col-sm-12 col-md-4 col-lg-2" id="AD_ALTD">
              {/* <div class="col-2.4"> */}
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Advertiser&nbsp;:<span style={asterisk}>*</span>
                &nbsp;&nbsp;&nbsp;
              </label>
              <br />
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                <select
                  disabled
                  value={this.state.advertiserID}
                  class="form-control"
                  name="AdvertiserName"
                  id="for_res"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: "1px solid #cccccc",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "3px",
                    fontFamily: "sans-serif",
                    color: "#555555",
                    opacity: "0.5",
                  }}
                  onChange={this.onAdvertiserNameHandleChange}
                >
                  <option
                    value={"Select Advertiser"}
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Select Advertiser
                  </option>
                  {/*Aman-4797-(added placeholder insted instead of value)-Console Warnings-Agency-Reports Part 2 */}
                  {this.state.advertiserList.map((advertiserList, i) => (
                    <option
                      placeholder={"Select Advertiser"}
                      style={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        color: "#555555",
                      }}
                      value={advertiserList.advertiserID}
                      key={i}
                    >
                      {advertiserList.advertiserName}{" "}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  value={this.state.advertiserID}
                  class="form-control"
                  name="AdvertiserName"
                  id="for_res"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: "1px solid #cccccc",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "3px",
                    fontFamily: "sans-serif",
                    color: "#555555",
                  }}
                  onChange={this.onAdvertiserNameHandleChange}
                >
                  <option
                    value={"Select Advertiser"}
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Select Advertiser
                  </option>
                  {this.state.advertiserList.map((advertiserList, i) => (
                    <option
                      value={advertiserList.advertiserID}
                      key={i}
                      style={{
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        color: "#555555",
                      }}
                    >
                      {advertiserList.advertiserName}
                    </option>
                  ))}
                </select>
              )}
            </div>
            &nbsp;&nbsp;
            <div class="col-sm-12 col-md-4 col-lg-2">
              {/* <div class="col-2.4"> */}
              {/*3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE*/}
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Campaign Status&nbsp;:<span style={asterisk}>*</span>&nbsp;
              </label>
              <br />
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                <div>
                  <Picky
                    value={this.state.campaignStatus}
                    options={campaignStatusOptions}
                    onChange={this.onCampaignStatusHandleChange}
                    open={false}
                    valueKey="id"
                    labelKey="name"
                    // disabled={true}
                    //multiple={true}
                    includeSelectAll={true}
                    includeFilter={false}
                    numberDisplayed={1}
                    id=""
                    readOnly
                    isDisabled="true"
                    name="CampaignStatus"
                    className={"PickyStyle"}
                    render={({ item, isSelected, labelKey }) => {
                      return (
                        <li>
                          {/* required to select item */}
                          <input
                            type="checkbox"
                            disabled
                            checked={isSelected}
                            readOnly
                          />
                          <span>{item[labelKey]}</span>
                        </li>
                      );
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "148px",
                    border: " 1px solid #CCCCCC",
                    borderRadius: " 3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: " #555555",
                  }}
                >
                  <Picky
                    value={this.state.campaignStatus}
                    options={campaignStatusOptions}
                    onChange={this.onCampaignStatusHandleChange}
                    open={false}
                    valueKey="id"
                    labelKey="name"
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={false}
                    placeholder="Select Status"
                    dropdownHeight={200}
                    numberDisplayed={0}
                    id="CampaignStatus"
                    name="CampaignStatus"
                    className={"PickyStyle"}
                  />
                </div>
              )}
            </div>
            &nbsp;&nbsp;
            <div class="col-sm-12 col-md-4 col-lg-2" id="CP_ALTD">
              {/* <div class="col-2.4"> */}
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Campaign&nbsp;:<span style={asterisk}>*</span>&nbsp;
              </label>
              <br />
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                <select
                  disabled
                  value={this.state.campaignName}
                  class="form-control"
                  id="for_res"
                  name="campID"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: "1px solid #cccccc",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "3px",
                    fontFamily: "sans-serif",
                    color: "#555555",
                    opacity: "0.5",
                  }}
                  onChange={this.onCampaignHandleChange}
                >
                  {/*Aman-4797-(added placeholder insted instead of value)-Console Warnings-Agency-Reports Part 2 */}
                  <option
                    placeholder={"Select Advertiser"}
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                    value={this.state.campaignName}
                    selected
                  >
                    {this.state.campID}-{this.state.campaignName}
                  </option>
                </select>
              ) : (
                // value={this.state.campID} required
                <select
                  value={this.state.campID}
                  class="form-control"
                  id="for_res"
                  name="campID"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: "1px solid #cccccc",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "3px",
                    fontFamily: "sans-serif",
                    color: "#555555",
                  }}
                  onChange={this.onCampaignHandleChange}
                >
                  <option
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Select Campaign
                  </option>
                  {this.state.getCampaignListByADV &&
                    this.state.getCampaignListByADV.map(
                      (getCampaignListByADV, i) => (
                        <option
                          value={getCampaignListByADV.campID}
                          key={i}
                          style={{
                            fontSize: "14px",
                            fontFamily: "sans-serif",
                            color: "#555555",
                          }}
                        >
                          {getCampaignListByADV.campID} -{" "}
                          {getCampaignListByADV.campaignName}
                        </option>
                      )
                    )}
                </select>
              )}
            </div>
            &nbsp;&nbsp;
            <div class=" col-sm-12 col-md-4 col-lg-2" id="TM_ALTD">
              {/* <div class="col-2.4"> */}
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Timeline&nbsp;:<span style={asterisk}>*</span>&nbsp;&nbsp;
              </label>
              <br />
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                <select
                  class="form-control"
                  disabled
                  name="Timeline"
                  id="for_res"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: "1px solid #cccccc",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "3px",
                    fontFamily: "sans-serif",
                    color: "#555555",
                    opacity: "0.5",
                    marginBottom: "10px",
                  }}
                  value={this.state.timeLine}
                  required
                  onChange={this.ondeadlineHandleChange}
                >
                  <option
                    value={"Select Timeline"}
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Select Timeline
                  </option>
                  <option
                    value="All"
                    lable="All"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    All
                  </option>
                  <option
                    value="Weekly"
                    label="Ending This Week"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Week
                  </option>
                  <option
                    value="Monthly"
                    label="Ending This Month"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Month
                  </option>
                  <option
                    value="Quarterly"
                    label="Ending This Quarter"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Quarter
                  </option>
                  <option
                    value="Yearly"
                    label="Ending This Year"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Year
                  </option>
                  <option
                    value="PreMonth"
                    label="Ending Previous Month"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Month
                  </option>
                  <option
                    value="PreQuarter"
                    label="Ending Previous Quarter"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Quarter
                  </option>
                  <option
                    value="PreYear"
                    label="Ending Previous Year"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Year
                  </option>
                </select>
              ) : (
                <select
                  class="form-control"
                  style={{
                    width: "148px",
                    height: "34px",
                    border: "1px solid #cccccc",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "3px",
                    fontFamily: "sans-serif",
                    color: "#555555",
                    marginBottom: "10px",
                  }}
                  name="TimeLine"
                  id="for_res"
                  value={this.state.timeLine}
                  required
                  onChange={this.ondeadlineHandleChange}
                >
                  <option
                    value={"Select Timeline"}
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Select Timeline
                  </option>
                  <option
                    value="All"
                    lable="All"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    All
                  </option>
                  <option
                    value="Weekly"
                    label="Ending This Week"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Week
                  </option>
                  <option
                    value="Monthly"
                    label="Ending This Month"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Month
                  </option>
                  <option
                    value="Quarterly"
                    label="Ending This Quarter"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Quarter
                  </option>
                  <option
                    value="Yearly"
                    label="Ending This Year"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending This Year
                  </option>
                  <option
                    value="PreMonth"
                    label="Ending Previous Month"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Month
                  </option>
                  <option
                    value="PreQuarter"
                    label="Ending Previous Quarter"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Quarter
                  </option>
                  <option
                    value="PreYear"
                    label="Ending Previous Year"
                    style={{
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                      color: "#555555",
                    }}
                  >
                    Ending Previous Year
                  </option>
                </select>
              )}
            </div>
            &nbsp;&nbsp;
            <div class="col-sm-12 col-md-4 col-lg-2">
              {/*3721-Rutuja Kale-VAPT-Agency side--Reports menu--URL Links & API-FE*/}

              {/* <div class="col-2.4"> */}
              <label
                style={{
                  fontSize: "14px",
                  color: "#14254A",
                  fontFamily: "sans-serif",
                }}
              >
                Lead Status&nbsp;:&nbsp;<span style={asterisk}>*</span>&nbsp;
              </label>
              <br />
              {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
              {this.state.reportKey === "report" ? (
                <div>
                  <Picky
                    value={this.state.leadStatus}
                    options={leadStatusOptions}
                    onChange={this.onLeadStatusHandleChange}
                    open={false}
                    valueKey="id"
                    labelKey="name"
                    // multiple={true}
                    //  disabled={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    //  dropdownHeight={200}
                    numberDisplayed={1}
                    id=""
                    readOnly
                    isDisabled="true"
                    //  name="leadStatus"
                    className={"PickyStyle"}
                    render={({ item, isSelected, labelKey }) => {
                      return (
                        <li>
                          {/* required to select item */}
                          <input
                            type="checkbox"
                            disabled
                            checked={isSelected}
                            readOnly
                          />
                          <span>{item[labelKey]}</span>
                        </li>
                      );
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "148px",
                    border: " 1px solid #CCCCCC",
                    borderRadius: " 3px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "sans-serif",
                    color: " #555555",
                  }}
                >
                  <Picky
                    value={this.state.leadStatus}
                    options={leadStatusOptions}
                    onChange={this.onLeadStatusHandleChange}
                    open={false}
                    valueKey="id"
                    labelKey="name"
                    multiple={true}
                    includeSelectAll={true}
                    placeholder={"Select Status"}
                    includeFilter={true}
                    dropdownHeight={200}
                    numberDisplayed={0}
                    id="leadStatus"
                    name="leadStatus"
                    className={"PickyStyle"}
                  />
                </div>
              )}
            </div>
          </div>
          {/* <br/> */}
          <hr />
          {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
          {this.state.reportKey === "report" ||
          this.state.generateBtnFlag === true ? (
            <div
              style={{
                backgroundColor: "white",
                marginBottom: "30px",
                border: "1px solid #cccccc",
                borderRadius: "3px",
              }}
            >
              <div
                style={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div>
                  <div>
                    <label
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "600px",
                        fontSize: "16px",
                        color: "#F28C0F",
                      }}
                    >
                      Delivery Summary :
                      {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                      {this.state.timeLine !== "Select Timeline" ||
                      this.state.reportKey === "report" ? (
                        <span style={{ color: "#414141", fontSize: "14px" }}>
                          &nbsp;&nbsp;&nbsp;{this.state.label}
                          {this.state.label === "All"
                            ? ""
                            : this.state.advcountDetails &&
                              this.state.advcountDetails.map(
                                (advcountDetails) => (
                                  <span
                                    style={{
                                      color: "#414141",
                                      fontSize: "14px",
                                    }}
                                  >
                                    ({advcountDetails.deliveryLabel})
                                  </span>
                                )
                              )}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                  <hr></hr>

                  <div>
                    {this.state.advcountDetails &&
                      this.state.advcountDetails.map((advcountDetails) => (
                        <div class="row">
                          <div
                            class="col-sm-2.5"
                            style={{ marginLeft: "16px" }}
                          >
                            <label style={{ color: "#14254A" }}>
                              {/* <b> */}
                              Advertiser ID &nbsp;:&nbsp;
                              {/* </b> */}
                            </label>
                            <span style={{ color: "#14254A" }}>
                              <b>{advcountDetails.advertiserID}&nbsp;</b>
                            </span>
                          </div>
                          <div
                            class="col-sm-3 col-lg-4 col-md-6"
                            style={{ marginBottom: "10px" }}
                          >
                            <label style={{ color: "#14254A" }}>
                              {/* <b> */}
                              Advertiser Name&nbsp;:&nbsp;
                              {/* </b> */}
                            </label>
                            <span style={{ color: "#14254A" }}>
                              <b>{advcountDetails.advertiserName}</b>
                            </span>
                          </div>
                          {/* */}
                          <div class="col-sm-2">
                            <label
                              style={{
                                color: "#14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Report ID&nbsp;:&nbsp;
                            </label>
                            <label
                              style={{
                                color: " #14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              {this.state.reportID}&nbsp;
                            </label>
                          </div>
                          <div
                            class="col-sm-3 col-lg-4"
                            style={{ marginBottom: "10px" }}
                          >
                            <label
                              style={{
                                color: "#14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Report Name&nbsp;:&nbsp;
                            </label>
                            <label
                              style={{
                                color: " #14254A",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              {this.state.reportName}
                            </label>
                          </div>
                          {/* Chaitanya-4099-Reportname and ReportID- not getting display once click on the report name link*/}

                          <div class="col-sm-7 col-lg-6"></div>

                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <hr></hr>
                            <label style={{ color: "#14254A" }}>
                              <span>Total count :</span>
                            </label>
                          </div>

                          <div class="col-sm-2">
                            <span
                              style={{
                                color: " #4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Allocated Lead&nbsp;:&nbsp;
                            </span>
                            {/*sunita-task-3579-added code as per DI standard */}

                            <span style={{ color: "#414141" }}>
                              &nbsp;{advcountDetails.totalAllocation}
                            </span>
                          </div>

                          <div class="col-sm-2">
                            <span
                              style={{
                                color: "#4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Delivered&nbsp;:&nbsp;
                            </span>
                            {/*sunita-task-3579-added code as per DI standard */}
                            <span style={{ color: "#193D8F" }}>
                              {advcountDetails.deliveredLead}
                            </span>
                          </div>
                          <div class="col-sm-2">
                            <span
                              style={{
                                color: "#4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Review Pending&nbsp;:&nbsp;
                            </span>
                            {/*sunita-task-3579-added code as per DI standard */}
                            {this.state.campaignLeadStatusArray.includes(
                              "In QA"
                            ) ? (
                              <b
                                style={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#F28C0F",
                                }}
                              >
                                {advcountDetails.qaReview}
                              </b>
                            ) : (
                              <span style={{ color: "#F28C0F" }}>
                                {advcountDetails.qaReview}
                              </span>
                            )}
                          </div>
                          <div class="col-sm-2">
                            <span
                              style={{
                                color: "#4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Accepted&nbsp;:&nbsp;
                            </span>
                            {/*sunita-task-3579-added code as per DI standard */}
                            {this.state.campaignLeadStatusArray.includes(
                              "Accepted"
                            ) ? (
                              <b
                                style={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#008000",
                                }}
                              >
                                {advcountDetails.acceptedLead}
                              </b>
                            ) : (
                              <span style={{ color: "#008000" }}>
                                {advcountDetails.acceptedLead}
                              </span>
                            )}
                          </div>
                          <div class="col-sm-2">
                            <span
                              style={{
                                color: "#4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Rejected&nbsp;:&nbsp;
                            </span>
                            {/*sunita-task-3579-added code as per DI standard */}
                            {this.state.campaignLeadStatusArray.includes(
                              "Rejected"
                            ) ? (
                              <b
                                style={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#FF0000",
                                }}
                              >
                                {advcountDetails.rejectedLeads}
                              </b>
                            ) : (
                              <span style={{ color: "#FF0000" }}>
                                {advcountDetails.rejectedLeads}
                              </span>
                            )}
                          </div>
                          <div class="col-sm-2">
                            <span
                              style={{
                                color: "#4F4F4F",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                              }}
                            >
                              Balance&nbsp;:&nbsp;
                            </span>
                            {/*sunita-task-3579-added code as per DI standard */}
                            <span style={{ color: "#4F4F4F" }}>
                              {advcountDetails.balanced}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div
                  class="modal fade "
                  id="manageColumn"
                  role="dialog"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                >
                  <div
                    class="modal-dialog modal-md float-right"
                    style={{ paddingTop: "120px", paddingRight: "36%" }}
                  >
                    <div
                      class="modal-content"
                      id="manage_popup_cont"
                      style={{ float: "right", width: "410px" }}
                    >
                      <div class="modal-header" style={{ height: "52px" }}>
                        <div>
                          <span
                            style={{
                              fontFamily: "Roboto",
                              fontSize: "20px",
                              fontWeight: "500",
                              color: "#414141",
                              cursor: "pointer",
                            }}
                          >
                            Manage Columns
                            <span
                              style={{
                                float: "right",
                                fontSize: "30px",
                                marginTop: "-8px",
                              }}
                              align="right"
                              data-dismiss="modal"
                            >
                              &times;
                            </span>
                          </span>
                        </div>
                      </div>

                      <div>
                        <div
                          class="modal-body "
                          style={{
                            overflowY: "scroll",
                            height: "330px",
                            overflowX: "hidden",
                          }}
                        >
                          <Checkbox.Group
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto auto",
                              width: "391px",
                              fontSize: "12px",
                              margin: "5px auto",
                            }}
                            options={this.state.checkBoxObjArray.map((obj) => ({
                              label: obj.id,
                              value: obj.name,
                            }))}
                            value={this.state.checkedColumnKeys}
                            onChange={this.leadOnChange}
                          />
                        </div>
                        <hr />
                        <div
                          style={{
                            paddingTop: "15px",
                            paddingLeft: "20px",
                            paddingBottom: "15px",
                          }}
                        >
                          <button
                            type="button"
                            class="btn11 info11"
                            data-dismiss="modal"
                            style={{
                              width: "150px",
                              height: "34px",
                              borderRadius: "2px",
                            }}
                          >
                            Cancel
                          </button>
                          <span style={{ paddingLeft: "25px" }}>
                            <button
                              type="button"
                              class="btn add-button"
                              style={{
                                width: "150px",
                                height: "34px",
                                marginTop: "-3px",
                                marginBottom: "0rem",
                              }}
                              data-dismiss="modal"
                              onClick={this.handleSubmit}
                            >
                              Save And Close
                            </button>
                          </span>{" "}
                          {/*sunita-task-3570-added code for closing the pop up */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    class="col-sm-12 col-md-4 col-lg-3"
                    style={{ marginTop: "16px", marginLeft: "-21px" }}
                  >
                    <span
                      style={{
                        color: "#14254A",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                      }}
                    >
                      <b>Lead Details :</b>
                    </span>
                  </div>
                  {/* Aman-4797-(added '===' and instead saw '==')-Console Warnings-Agency-Reports Part 2 */}
                  {this.state.reportKey === "report" ? (
                    ""
                  ) : (
                    <div
                      class="col-sm-12 offset-md-5 col-md-3 offset-lg-7 col-lg-2"
                      align="right"
                      style={{
                        marginTop: "16px",
                        fontFamily: "Roboto",
                        fontWeight: "600px",
                        fontSize: "14px",
                      }}
                    >
                      <span
                        data-toggle="modal"
                        data-target="#manageColumn"
                        data-backdrop="static"
                        data-keyboard="false"
                        style={{
                          color: "#1890FF",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }} //sunita-task-3579-added code for closing pop up
                      >
                        Manage Columns
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div align="center" id={this.state.loader1}></div>
          <div align="center" id={this.state.loader}></div>
          <div align="center" id={this.state.campaignLoader}></div>

          {this.state.advLeadDetails.length > 0 ? (
            <Table
              {...this.state.tableState}
              id="myTable tr"
              bordered
              dataSource={this.state.advLeadDetails}
              columns={this.state.dataTableColumns}
              onChange={onChange}
              className={`${tableCSS} "ant-table-thead"`} //Aman-4797-(changed 2 className in One)-Console Warnings-Agency-Reports Part 2
              //className="ant-table-thead"
              //className={tableCSS}
              scroll={
                this.state.multiTouch !== "No"
                  ? { x: z, y: 1333 }
                  : this.state.customQuestionCount !== 0
                  ? { x: 3500, y: 1333 }
                  : { x: 2500, y: 1333 }
              }
              pagination={{
                pageSize: this.state.pageSize,
                position: "bottom",
              }}
            ></Table>
          ) : (
            <Table
              {...this.state.tableState}
              id="myTable tr"
              bordered
              dataSource={""}
              columns={columnsReportForblank}
              onChange={onChange}
              className={`${tableCSS} "ant-table-thead"`} //Aman-4797-(changed 2 className in One)-Console Warnings-Agency-Reports Part 2
              //className="ant-table-thead"
              //className={tableCSS}
              scroll={{ x: 2500, y: 1333 }}
              pagination={{
                pageSize: this.state.pageSize,
                position: "bottom",
              }}
            ></Table>
          )}

          <div
            class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6"
            style={{
              marginTop: "-53px",
              marginLeft: "5px",
              fontSize: "13px",
              color: "#4F4F4F",
              fontFamily: "sans-serif",
            }}
          >
            Records per page:&nbsp;
            <select
              defaultValue={this.state.pageSize}
              onChange={this.handleChangePageSize}
              id="pacing11"
              class="input-small"
              className="form-control"
              name="pacing"
              style={{
                width: "71px",
                height: "30px",
                display: "initial",
                fontSize: "13px",
                fontFamily: "sans-serif",
                color: "#4F4F4F",
              }}
            >
              <option value="100" selected>
                100
              </option>
              <option value="200">200</option>
              <option value="300">300</option>
            </select>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

AgencyAdvertiserLeadTracking.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(AgencyAdvertiserLeadTracking)
);
