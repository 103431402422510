/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sanjana Godbole,Raunak Thakkar, Snehal More
@Description:UI Success Page under create campaign
*/
import React from "react";
import * as $ from "jquery";
import { form } from "mdbreact";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import "./successPage.css"; //Sandeep-task-3316-added new css file for responsiveness
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import {
	Tooltip,
	Icon,
	Input,
	Form,
	Row,
	Col,
	Button,
	Tag,
	message,
} from "antd";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3719-vapt header and query params

//use for fetch url value
const Swal = require("sweetalert2");
class SuccessPage extends React.Component {
	/**
	 * @author Narendra Phadke
	 * @param  Description handle the multiple file uploads
	 */
	constructor() {
		super();

		this.state = {
			campaignDetails: [],
			campID: "",
			linkingCampaign: [],
			linkCampaign: [],
			loader: "",
			rfpCampaigns: [],
			rfpCampaignID: "",
			updateLeadError: {},
			step: 2,
			campID: "",
			parentCampID: "",
			reallocationID: "",
			rfpProposalID: "",
		};
		//end of state
		this.pendingAllocation = this.pendingAllocation.bind(this);
		this.allocatePublisher = this.allocatePublisher.bind(this);
		this.saveLinkCampaign = this.saveLinkCampaign.bind(this);
		this.handleChangeCheck = this.handleChangeCheck.bind(this);
		this.logChange = this.logChange.bind(this);
		this.getRFPDetails = this.getRFPDetails.bind(this);
		this.updateLeads = this.updateLeads.bind(this);
		this.submitLeads = this.submitLeads.bind(this);
	}
	//end of constructor

	getRFPDetails() {
		if (
			this.state.rfpCampaignID == "undefined" ||
			this.state.rfpCampaignID == "null" ||
			this.state.rfpCampaignID == null ||
			this.state.rfpCampaignID == undefined ||
			this.state.rfpCampaignID == ""
		) {
		} else {
			let data1 = { rfpCampaignID: this.state.rfpCampaignID };
			// alert(rfpProposalID);
			fetch("campaign/rfpCampaignList", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data1),
			})
				.then((res) => res.json())
				.then((rfpCampaigns) => {
					this.setState({ rfpCampaigns: rfpCampaigns });
				});
		}
	}

	updateLeads(i, e) {
		var { name, value } = e.target;
		// var leads=e.target.value;
		// alert(value)
		var updateLeadError = {};

		let rfpCampaigns = [...this.state.rfpCampaigns];
		rfpCampaigns[i] = { ...rfpCampaigns[i], [name]: value };
		//  alert("data in==>"+JSON.stringify(rfpCampaigns));
		this.setState({ rfpCampaigns: rfpCampaigns });
		let temp = rfpCampaigns.filter((a) => {
			if (
				a.noOfLeads == "" ||
				a.noOfLeads == "null" ||
				a.noOfLeads == null ||
				a.noOfLeads == undefined
			) {
			} else {
				return a;
			}
		});
		if (temp.length == 0) {
			this.setState({ btnSubmitDisable: true });
			this.setState({
				updateLeadError: updateLeadError,
				alertBorder: "0px solid red",
			});
		} else {
			this.setState({ btnSubmitDisable: false });
		}
	}

	validateForm() {
		let updateLeadError = {};
		var updateLeadValidate = false;
		var rfpcampaignArray = this.state.rfpCampaigns;
		let lenCampArray = rfpcampaignArray.length;
		var formIsValid = false;
		var count = 0;
		// alert("lenCampArray lenghth==>"+lenCampArray);
		for (let i = 0; i < lenCampArray; i++) {
			var noOfLeads = rfpcampaignArray[i].noOfLeads;
			// alert("noOfLeads===>"+noOfLeads);
			if (noOfLeads == "" || noOfLeads == null || noOfLeads == undefined) {
				//  alert("Please Enter Leads===="+i);
				if (count > 0 || count == 0) {
				} else {
					updateLeadValidate = false;
					updateLeadError["one"] =
						"Please Enter Leads  " + rfpcampaignArray[i].country;
					this.setState({
						updateLeadError: updateLeadError,
						alertBorder: "1px solid red",
						//  btnSubmitDisable:true
					});
					// alert("Count=="+count);

					return updateLeadValidate;
				}
			} else {
				//  alert("Lead Present plz chk for regex")
				var pattern = new RegExp(/^[1-9][0-9]*$/);
				if (!pattern.test(noOfLeads)) {
					// alert("Please Enter Valid Leads");
					updateLeadValidate = false;
					//  alert("Please Enter Valid Leads");
					updateLeadError["noOfLead"] =
						" Please Enter Valid Leads For  " + rfpcampaignArray[i].country;
					this.setState({
						updateLeadError: updateLeadError,
						alertBorder: "1px solid red",
						//  btnSubmitDisable:false
					});
					return updateLeadValidate;
				} else {
					// alert("Lead in valid format");
					updateLeadValidate = true;
					updateLeadError["noOfLead"] = "";
					this.setState({
						updateLeadError: updateLeadError,
						alertBorder: "0px solid red",
					});
					count++;
					//  return updateLeadValidate
				}
			}
		} //end of for loop
		if (updateLeadValidate === false) {
			formIsValid = false;
			this.setState({
				updateLeadError: updateLeadError,
				alertBorder: "1px solid red",
			});
		} else {
			formIsValid = true;
		}

		// alert("formIsValid"+formIsValid);
		return formIsValid;
	}

	submitLeads(e) {
		if (this.validateForm() === false) {
			// alert("form is false");
		} else {
			// alert("Submit leads");
			let data = {
				rfpCampaigns: this.state.rfpCampaigns,
			};
			// alert(JSON.stringify(this.state.rfpCampaigns.noOfLead))
			fetch("/campaign/updateRFPCampaignLeads", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.success === "success") {
						let message = "Leads Updated Successfully";
						Swal.fire({
							text: message,
							type: "success",
							confirmButtonText: "Ok",
							allowOutsideClick: false,
							preConfirm: () => {
								const { isAuthenticated, user } = this.props.auth;
								var agencyID = user.id;
								this.setState({ btnSubmitDisable: true });
								//document.getElementsByName('noOfLeads').value = '';
								//window.location.href = '/dashboard?agencyID='+agencyID;
								this.getRFPDetails();
							},
						});
					}
				});
		}
	} //end of submitleads

	handleChangeCheck(e) {
		var campID = e.target.getAttribute("campID");
		var parentCampID = e.target.getAttribute("parentCampID");

		// var linkCampaign=[];
		const { name, value } = e.target;
		let linkCampaign = [...this.state.linkCampaign];
		if (e.target.checked === true) {
			linkCampaign.push({
				campID: campID,
				parentCampID: parentCampID,
				checked: "Yes",
			});
		} else {
			//linkCampaign[0] = { ...linkCampaign[0], [name]: 'No' };
		}

		this.setState({ linkCampaign });
	}

	componentDidMount() {}
	logChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
			/*@author Narendra phadke
			 *Desc Fetch campID from Url
			 * dependencies query-string
			 */
			var parsed = this.props.location.state;
			if (parsed !== undefined) {
				var campID, rfpCampaignID;
				if (parsed.hasOwnProperty("campID")) {
					campID = parsed.campID;
				}
				if (parsed.hasOwnProperty("rfpCampaignID")) {
					rfpCampaignID = parsed.rfpCampaignID;
				}
				// alert("willmountcampid==>"+campID);
				// var parentCampID=parsed.parentCampID;
				// alert("willmountparent==>"+parentCampID);
				// var reallocationID=parsed.reallocationID;
				// alert("willreallocation==>"+reallocationID);

				// alert("rfpCampaignID===>"+rfpCampaignID);
				//  var rfpProposalID=parsed.rfpProposalID;
				// alert("rfpProposalID==>"+rfpProposalID);
				this.setState({ campID, rfpCampaignID: rfpCampaignID });
				// var rfpCampaignID=parsed.rfpCampaignID;
				this.setState({ campID });
				// console.log("Control on react page");
				console.log(campID);

				let self = this;

				let data = {
					campID: campID,
				};

				fetch("campaign/finishCampaign?campID=" + campID + "")
					.then((res) => res.json())
					.then((campaignDetails) => {
						//  alert("campaignDetails===>"+JSON.stringify(campaignDetails));
						var rfpProposalID = campaignDetails[0].rfpProposalID;
						//  alert("rfpProposalID==>"+rfpProposalID);
						this.getRFPDetails();
						self.setState({
							campaignDetails: campaignDetails,
							rfpProposalID: rfpProposalID,
						});
					});
				// ,function(){this.setState({loader:"whirly-loader"})}
				fetch(
					"campaign/linkingCampaign",
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(data),
					},
					function () {
						alert("called");
					}
				)
					.then((res) => res.json())
					.then((linkingCampaign) => {
						// alert("linkingCampaign===>"+JSON.stringify(linkingCampaign));
						this.setState({ linkingCampaign: linkingCampaign });
					});
			} else {
				this.props.history.push("/newDashboard");
			}
		}

		//   saveLinkCampaign(e)
		//   {
		//     const {isAuthenticated, user} = this.props.auth;
		//     var parsed = queryString.parse(this.props.location.search);
		//     var campID=parsed.campID;
		//     var parentCampID=parsed.parentCampID;
		//     let data=
		//     {
		//       linkCampaign:this.state.linkCampaign,
		//       campID:campID,
		//       parentCampID:parentCampID,
		//       user:user

		//     }

		//     fetch("campaign/setLinkingCampaign",{
		//       method: "POST",
		//       headers: {"Content-Type": "application/json"},
		//       body: JSON.stringify(data)
		//     }).then(res => res.json())
		//     .then(setLinkCampaign =>{
		//          Swal.fire({

		//           text: ("Campaign Link Successfully"),
		//           type: 'success',
		//           confirmButtonText: 'Ok',
		//           allowOutsideClick: false,
		//           preConfirm: () => {
		//             const {isAuthenticated, user} = this.props.auth;
		//             var agencyID=user.id;
		//             window.location.href = '/dashboard?agencyID='+agencyID;
		//           }
		//         })

		//       })
		// }
	}
	//end of will Mount

	saveLinkCampaign(e) {
		const { isAuthenticated, user } = this.props.auth;
		var parsed = this.props.location.state;
		if (parsed !== undefined) {
			var campID, parentCampID;
			if (parsed.hasOwnProperty("campID")) {
				campID = parsed.campID;
			}
			if (parsed.hasOwnProperty("parentCampID")) {
				parentCampID = parsed.parentCampID;
			}
			let data = {
				linkCampaign: this.state.linkCampaign,
				campID: campID,
				parentCampID: parentCampID,
			}; // karan-task-3719-vapt header and query params

			fetch("campaign/setLinkingCampaign", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((setLinkCampaign) => {
					Swal.fire({
						text: "Campaign Link Successfully",
						type: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false,
						preConfirm: () => {
							const { isAuthenticated, user } = this.props.auth;
							var agencyID = user.id;
							this.props.history.push("/campaignList", { agencyID: user.id }); //karan-task-3684-replace query params
						},
					});
				});
		}
	}

	allocatePublisher(e) {
		e.preventDefault(); // karan-task-3714-vapt header and query params
		// alert("inside handlechange");
		var parsed = this.props.location.state;
		if (parsed !== undefined) {
			var campID, parentCampID;
			if (parsed.hasOwnProperty("campID")) {
				campID = parsed.campID;
			}
			if (parsed.hasOwnProperty("parentCampID")) {
				parentCampID = parsed.parentCampID;
			}
			// alert("parentCampID===>"+parentCampID);
			var rfpProposalID = this.state.rfpProposalID;
			// alert("rfpProposalID==>"+rfpProposalID);
			if (
				rfpProposalID === "null" ||
				rfpProposalID == null ||
				rfpProposalID === undefined ||
				rfpProposalID === "" ||
				rfpProposalID == 0 ||
				rfpProposalID == "0"
			) {
				// window.location.href ='/publisherAllocation?campID='+campID+'&parentCampID='+parentCampID+'&reallocationID='+reallocationID;
				this.props.history.push("/publisherAllocation", {
					campID,
					parentCampID,
				}); // karan-task-3714-vapt header and query params
			} else {
				this.props.history.push("/rfpCampaignAllocation", {
					campID,
					parentCampID,
					proposalID:rfpProposalID
				}); // sonali-task-3716-vapt header and query params
			}
		}
	}

	pendingAllocation(e) {
		const { isAuthenticated, user } = this.props.auth;
		var agencyID = user.id;
		var parsed = this.props.location.state;
		if (parsed !== undefined) {
			var campID, parentCampID, rfpCampaignID;
			if (parsed.hasOwnProperty("campID")) {
				campID = parsed.campID;
			}
			if (parsed.hasOwnProperty("parentCampID")) {
				parentCampID = parsed.parentCampID;
			}
			if (parsed.hasOwnProperty("rfpCampaignID")) {
				rfpCampaignID = parsed.rfpCampaignID;
			}
			// alert("rfpCampaignID===>"+rfpCampaignID);
			// var reallocationID=e.target.getAttribute('reallocationID');
			// alert("reallocationID===>"+reallocationID);
			// window.location.href = '/campaignList?agencyID='+agencyID;

			if (rfpCampaignID) {
				if (rfpCampaignID == 0) {
					// alert("helllo");
					this.props.history.push("/welcomeEdit", {
						campID,
						parentCampID,
						step: this.state.step,
					}); // karan-task-3714-vapt header and query params
				} else {
					this.props.history.push("/welcomeEdit", {
						campID,
						parentCampID,
						step: this.state.step,
						rfpCampaignID,
					}); // karan-task-3714-vapt header and query params
				}
			} else {
				this.props.history.push("/welcomeEdit", {
					campID,
					parentCampID,
					step: this.state.step,
				}); // karan-task-3714-vapt header and query params
			}
		}
	}

	render() {
		const { isAuthenticated, user } = this.props.auth;
		return (
			<div>
				<Navigation />
				<div
					class="container-fluid"
					style={{ paddingTop: "100px", paddingBottom: "60px" }}>
					<div class="row">
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<img
								src="successPage1.png"
								style={{ height: "160px" }}
								class="rounded mx-auto d-block"
								alt="Success"
							/>
						</div>
					</div>
					{/* end of 1st row */}

					<div class="row">
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="col" align="center">
								<label id="labelsuccessheading">
									Campaign created successfully Campaign ID :&nbsp;
									{this.state.campID}
								</label>
							</div>
							<div class="col" align="center">
								<label id="labelsuccessheading">
									Please{" "}
									<a href="#" onClick={this.allocatePublisher}>
										<span style={{ color: "orange" }}>click here</span>
									</a>{" "}
									to allocate.
								</label>
							</div>
						</div>
					</div>
					{/* end of 2nd row */}
					<div class="row">
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="card card-signin">
								<div class="card-body">
									{this.state.campaignDetails.map(
										(campaignDetail) => (
											<div class="row">
												<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
												{/* //rutuja task 4459 -responsive issue */}
													<div class="row"> 
													<div class="col-sm-8 col-lg-9">
														{/* Sandeep-task-3316-added marginTop property */}
														<label
															style={{ fontSize: "18px", marginTop: "8px" }}>
															Basic Details
														</label>
													</div>
													{/* //rutuja task 4459 -responsive issue */}
													<div class="col-sm-4 col-lg-3">
														<button
															class="btn add-button"
															onClick={this.pendingAllocation}>
															Edit Campaign
														</button>
													</div>
													<br />
													<br />
													</div>
													<hr className="legend labelsubheading" />
													{/* <button class="btn"onClick={this.pendingAllocation}>Edit Campaign</button> */}
													{/* Sandeep-task-3316-changes in table data because of responsiveness only */}
													<div class="row">
														<div class=" col-lg-4">
															<table >
																<tr>
																	<td>
																		<label id="label">Campaign ID&nbsp;</label>
																	</td>
																	<td>:&nbsp;{campaignDetail.campID}</td>
																</tr>

																<tr>
																	<td>
																		<label id="label">CPL&nbsp;</label>
																	</td>
																	<td>
																		:&nbsp;{campaignDetail.CPL}
																		{/* Saurabh-task-3696-changed value budget to CPL */}
																		&nbsp;{campaignDetail.currency}
																	</td>
																</tr>

																<tr>
																	<td>
																		<label id="label">End date&nbsp;</label>
																	</td>
																	<td>:&nbsp;{campaignDetail.endDate}</td>
																</tr>

																<tr>
																	<td>
																		<label id="label">
																			Lead allocation&nbsp;
																		</label>
																	</td>
																	<td>
																		:&nbsp;{campaignDetail.leadAllocation}
																	</td>
																</tr>
															</table>
														</div>

														<div class="col-lg-4">
															<table>
																<tr>
																	<td>
																		<label id="label">
																			Campaign name&nbsp;
																		</label>
																	</td>
																	<td>:&nbsp;{campaignDetail.campaignName}</td>
																</tr>

																<tr>
																	<td>
																		<label id="label">Budget&nbsp;</label>
																	</td>
																	<td>
																		:&nbsp;{campaignDetail.budget}
																		&nbsp;{campaignDetail.currency}
																	</td>
																</tr>

																<tr>
																	<td>
																		<label id="label">
																			First lead delivery date&nbsp;
																		</label>
																	</td>
																	<td>
																		:&nbsp;
																		{campaignDetail.firstLeadDeliveryDate}
																	</td>
																</tr>

																<tr>
																	<td>
																		<label id="label">Created by&nbsp;</label>
																	</td>
																	<td>
																		:&nbsp;{campaignDetail.createdByCompanyName}
																	</td>
																</tr>
															</table>
														</div>

														<div class="col-lg-4">
															<table>
																<tr>
																	<td>
																		<label id="label">Client name&nbsp;</label>
																	</td>
																	<td>:&nbsp;{campaignDetail.clientName}</td>
																</tr>

																<tr>
																	<td>
																		<label id="label">
																			Agency campaign ID&nbsp;
																		</label>
																	</td>
																	<td>:&nbsp;{campaignDetail.clientCampID}</td>
																</tr>

																<tr>
																	<td>
																		<label id="label">Start date&nbsp;</label>
																	</td>
																	<td>:&nbsp;{campaignDetail.startDate}</td>
																</tr>

																<tr>
																	<td>
																		<label id="label">
																			Campaign status&nbsp;
																		</label>
																	</td>
																	<td>
																		:&nbsp;{campaignDetail.campaignStatus}
																	</td>
																</tr>
															</table>
														</div>
													</div>

													{/* <div class=" col-md-6 col-lg-4">
                <label id="label">Campaign ID:&nbsp;</label>
                           {campaignDetail.campID}
                </div>
                

                <div class="col-md-6  col-lg-4">
                <label id="label">Campaign name:&nbsp;</label>
                    {campaignDetail.campaignName}
                </div>



                <div class="col-md-6 col-lg-4">

                <label id="label">Client name:&nbsp;</label>
                            {campaignDetail.clientName}
                </div>
            


                <div class="col-md-6 col-lg-4">

                <label id="label">Agency campaign ID:&nbsp;</label>
                            {campaignDetail.clientCampID}
                </div>
               
                

                 <div class="col-md-6 col-lg-4">
                <label id="label">CPL:&nbsp;</label>
                            {campaignDetail.CPL}
                            &nbsp;{campaignDetail.currency}
                </div> 
                 
                <div class="col-md-6 col-lg-4">
                <label id="label">Budget:&nbsp;</label>
                            {campaignDetail.budget}
                            &nbsp;{campaignDetail.currency}
                </div>

                <div class="col-md-6 col-lg-4">
                <label id="label">Start date:&nbsp;</label>
                            {campaignDetail.startDate}
                </div>


                <div class=" col-md-6 col-lg-4">
                <label id="label">End date:&nbsp;</label>
                            {campaignDetail.endDate}
                </div>



                <div class="col-md-6 col-lg-4">
                <label id="label">First lead delivery date&nbsp;</label>
                            {campaignDetail.firstLeadDeliveryDate}
                </div> 
                
                <div class="col-md-6 col-lg-4">
                <label id="label">Campaign status:&nbsp;</label>
                            {campaignDetail.campaignStatus}
                </div>

                <div class="col-md-6 col-lg-4">
                <label id="label">Lead allocation:&nbsp;</label>
                            {campaignDetail.leadAllocation}
                </div>


                <div class=" col-md-6 col-lg-4">

                <label id="label">Created by:&nbsp;</label>
                {campaignDetail.createdByCompanyName}
                </div> */}
												</div>
											</div>
										) //end of row
										// close of row
									)}
								</div>
							</div>
						</div>
					</div>
					{/* end of 3rd row */}
					{this.state.linkingCampaign.length > 0 ? (
						<div>
							<label style={{ fontSize: "16px" }}>Linking Campaigns:</label>
							<div class="table-responsive-lg">
								<table
									id="myTable"
									class="table"
									className=" table table-bordered table-striped ">
									<thead class="thead1" style={{ width: "1408px" }}>
										<tr class="custom-class">
											<th
												class="th table-header"
												style={{ textAlign: "center" }}>
												Campaign ID
											</th>

											<th class="table-header" style={{ textAlign: "center" }}>
												Campaign Name
											</th>

											<th class="table-header" style={{ textAlign: "center" }}>
												Start Date
											</th>

											<th class="table-header" style={{ textAlign: "center" }}>
												End Date
											</th>

											<th class="table-header" style={{ textAlign: "center" }}>
												Action
											</th>
										</tr>
									</thead>
									<tbody className="tbody1" style={{ height: "auto" }}>
										{this.state.linkingCampaign.map((linkingCampaign) => (
											<tr className="tr1">
												<td class="campId">{linkingCampaign.campID}</td>
												<td class="campaignName">
													{linkingCampaign.campaignName}
												</td>
												<td class="startDate">{linkingCampaign.startDate}</td>
												<td class="endDate">{linkingCampaign.endDate}</td>
												<td>
													<input
														type="checkbox"
														id="linkCheckbox"
														name="linkCheckbox"
														campID={linkingCampaign.campID}
														parentCampID={linkingCampaign.parentCampID}
														onChange={this.handleChangeCheck}></input>
													&nbsp;
													<label style={{ fontWeight: "400" }}>
														Select to Link
													</label>
												</td>
											</tr>
										))} 
										<br></br>										{/* <tr className="tr1" style={{ backgroundColor: "#ffffff" }}>
											<button
												class="btn add-button"
												style={{ float: "right" }}
												onClick={this.saveLinkCampaign}>
												Save
											</button>
										</tr> */}
										{/* //rutuja task 4459 -save button not showing for linking campaign  */}
										<div class="col-sm-4 col-lg-2" style={{float:'right'}}>
                  <button class="btn add-button" onClick={this.saveLinkCampaign}>Save</button>
                 </div>
									</tbody>
								</table>
							</div>
						</div>
					) : (
						""
					)}

					<div
						class={this.state.loader}
						style={{ top: "22px", left: "528px" }}></div>
					{this.state.rfpCampaigns.length > 0 ? (
						//this.state.rfpCampaigns[0].biddingType=='Geowise'?
						<div>
							<label>Below RFP Campaigns are created:</label>
							<br />
							{/* style={{border:this.state.alertBorder}} */}
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div class="errorMessage">
									{this.state.updateLeadError.one ? (
										<span>
											{this.state.updateLeadError.one}
											<br />
										</span>
									) : (
										""
									)}
									{this.state.updateLeadError.noOfLead ? (
										<span>
											{this.state.updateLeadError.noOfLead}
											<br />
										</span>
									) : (
										""
									)}
								</div>
							</div>

							<div class="table-responsive-lg">
								<table
									id="myTable"
									class="table"
									className=" table table-bordered table-striped "
									style={{ display: "inline-block" }}>
									<thead class="thead1">
										<tr class="custom-class">
											<th
												class="th table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "103px",
												}}>
												Campaign ID
											</th>

											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "134px",
												}}>
												Campaign Name
											</th>

											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "118px",
												}}>
												Start Date
											</th>

											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "119px",
												}}>
												End Date
											</th>

											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "122px",
												}}>
												Region
											</th>

											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "142px",
												}}>
												Country
											</th>
											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "61px",
												}}>
												CPL
											</th>
											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "66px",
												}}>
												Budget
											</th>
											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "81px",
												}}>
												Lead Allocation
											</th>
											<th
												class="table-header"
												style={{
													fontWeight: "400",
													wordWrap: "break-word",
													textAlign: "center",
													width: "100px",
												}}>
												Update Lead Allocation
											</th>
										</tr>
									</thead>
									<tbody className="tbody1" style={{ height: "auto" }}>
										{this.state.rfpCampaigns.map((rfpCampaigns, i) => {
											return (
												<tr>
													<td
														style={{
															wordWrap: "break-word",
															width: "130px",
															textAlign: "center",
														}}>
														{rfpCampaigns.campID}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "168px",
															textAlign: "center",
														}}>
														{rfpCampaigns.campaignName}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "150px",
															textAlign: "center",
														}}>
														{rfpCampaigns.startDate}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "149px",
															textAlign: "center",
														}}>
														{rfpCampaigns.endDate}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "156px",
															textAlign: "center",
														}}>
														{rfpCampaigns.region}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "178px",
															textAlign: "center",
														}}>
														{rfpCampaigns.country}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "76px",
															textAlign: "center",
														}}>
														{rfpCampaigns.CPL}
													</td>
													<td
														style={{
															wordWrap: "break-word",
															width: "83px",
															textAlign: "center",
														}}>
														{rfpCampaigns.budget}
													</td>

													<td
														style={{
															wordWrap: "break-word",
															width: "101px",
															textAlign: "center",
														}}>
														{rfpCampaigns.leadAllocation}
													</td>

													<td style={{ width: "125px" }}>
														<input
															type="text"
															style={{
																width: "88px",
																float: "right",
																marginRight: "8px",
															}}
															name="noOfLeads"
															id={rfpCampaigns.campID}
															onChange={this.updateLeads.bind(this, i)}
														/>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>

								<button
									class="btn add-button"
									style={{ float: "right" }}
									onClick={this.submitLeads}
									disabled={this.state.btnSubmitDisable}>
									Update Leads
								</button>
								<br />
							</div>
						</div>
					) : (
						//:''
						""
					)}
				</div>
				{/* end of container */}
				<Footer />
			</div>
		);
	}
}
//end of

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
SuccessPage.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
	withRouter(SuccessPage)
);
